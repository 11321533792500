<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/food-app-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						The Hunger-driven Food Delivery App Development Company
					</h2>
					<p>
						The online food delivery industry is outpacing the dine-in restaurant business by 300%.
						Let that sink in. People are placing more doorstep food delivery orders than visiting
						their favorite restaurants. Like one industry veteran said it right, “From the consumer
						perspective, there’s nothing more convenient than having the restaurant come to them.”
					</p>
					<p>
						If you are in the restaurant business or is someone who wants to crack the on-demand
						food delivery app market with an idea of your own, you have landed at the right place.
						GenixBit Labs being one among the top food delivery app development companies in the UK, We
						provide customised on-demand food delivery app development service that are perfected on
						all corners. In a way, we cook to order on-demand food delivery apps.
					</p>
					<p>
						Here is why you should count on us: we know what we are doing. We have already built and
						launched a handful on-demand apps for a global clientbase that are climbing the
						leaderboards of app stores. From thinking from the customer shoes’ to looking through
						the business owner’s eyes, we take a 360-degree approach in on-demand food delivery app
						development.
					</p>
					<p>
						Rest assured your idea of a food delivery app will be made reality in the best manner
						possible. We will be your partner in innovation to build and launch a feature-rich food
						delivery app.
					</p>
					<p>
						Wondering if it will burn a hole in your pocket? Not necessarily. Feel free to request
						for a quote.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
