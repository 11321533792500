<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/taxi-app-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						A White Label Taxi App, Build to Your Order
					</h2>
					<p>
						At GenixBit, we are of the belief that building a taxi booking mobile app should not
						be difficult. It should not be bank balance shattering either. Neither should it take
						ages to complete the app and launch it into the market.
					</p>
					<p>
						Our taxi app developers with special expertise in building on demand apps can help build
						a white label taxi booking app that you are looking for. The taxi app be bolted with all
						state-of-the-art on-demand booking features that users, drivers and admins will be
						looking for.
					</p>
					<p>
						Real-time GPS location tracking of passenger and the driver to begin with. One tap
						booking, integrated digital wallets for hassle-free payment, reviews and ratings,
						favorite routes, types of cars, hourly booking, and so many more features can be fitted
						into the mobile app. Consider your every feature request made possible.
					</p>
					<p>
						Would it look like a knock off of some other mobile app? As a white label mobile app, we
						will ensure that your taxi booking app looks original and a branded one at that. From
						color themes to user interface, we can customize every pixel of your taxi app.
					</p>
					<p>
						If you have any additional questions or require further clarification. Please do not
						hesitate, having a question is a sign of intelligence. You will have an expert from the
						best taxi app development company to guide you.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
