<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">Your Perfect VR Game Development Partner</h2>
				<p>
					We don’t claim to be the best Virtual Reality game development company, but we always
					deliver the best experience to our clients with our creativity and vast domain experience.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-xl-5 col-lg-6 col-12 col-left d-none d-lg-block p-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/perfect-vr-development.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 col-right p-0 bg-light">
					<div class="our-expert-build-content-wrap">
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M50,17c4.5,0,8.4-3.8,8.4-8.5C58.4,3.8,54.5,0,50,0c-4.5,0-8.4,3.8-8.4,8.5C41.6,13.2,45.5,17,50,17z M50,3
                    c2.8,0,5.4,2.5,5.4,5.5c0,3-2.5,5.5-5.4,5.5c-2.8,0-5.4-2.5-5.4-5.5C44.6,5.5,47.2,3,50,3z M16.4,46.5c4.5,0,8.4-3.8,8.4-8.5
                    c0-4.7-3.8-8.5-8.4-8.5C11.9,29.5,8,33.3,8,38C8,42.7,11.9,46.5,16.4,46.5z M16.4,32.5c2.8,0,5.3,2.5,5.3,5.5c0,3-2.5,5.3-5.3,5.3
                    c-2.8,0-5.4-2.5-5.4-5.5C11,34.8,13.6,32.5,16.4,32.5z M83.6,46.5c4.5,0,8.4-3.8,8.4-8.5c0-4.7-3.8-8.5-8.4-8.5
                    c-4.5,0-8.4,3.8-8.4,8.5C75.2,42.7,79.1,46.5,83.6,46.5z M83.6,32.5c2.8,0,5.3,2.5,5.3,5.5c0,3-2.5,5.3-5.3,5.3
                    c-2.8,0-5.4-2.5-5.4-5.5C78.3,34.8,80.8,32.5,83.6,32.5z M92,80v-2.8h3.5c0.8,0,1.5-0.7,1.5-1.5V55.3c0-4.7-3.3-8.7-7.5-8.7H77.8
                    c-4.3,0-7.7,4-7.7,8.7v20.3c0,0.8,0.7,1.5,1.5,1.5h3.5V80c-1.8,0.8-3.3,1.5-4.7,2.5L58,69c5-1.8,8.4-5.3,8.4-9.3
                    c0-4-3.2-7.5-8.2-9.2v-2.8h3.5c0.8,0,1.5-0.7,1.5-1.5V25.8c0-4.7-3.3-8.7-7.5-8.7H44c-4.2,0-7.5,4-7.5,8.7v20.3
                    c0,0.8,0.7,1.5,1.5,1.5h3.5v2.8c-5,1.8-8.2,5.3-8.2,9.2c0,3.8,2.8,7,7.2,9L29.3,82.5c-1.2-1-2.7-1.8-4.5-2.5v-2.8h3.5
                    c0.8,0,1.5-0.7,1.5-1.5V55.3c0-4.7-3.3-8.7-7.5-8.7H10.7c-4.2,0-7.5,4-7.5,8.7v20.3c0,0.8,0.7,1.5,1.5,1.5h3.5V80
                    c-5,2-8.2,5.3-8.2,9.2C-0.2,95.3,7.2,100,16.4,100c8.5,0,15.4-4,16.4-9.2h34.3c1,5.3,7.9,9.2,16.4,9.2c9.2,0,16.6-4.7,16.6-10.8
                    C100.2,85.3,97,82,92,80z M6,74V55.3c0-3,2-5.7,4.5-5.7h11.7c2.5,0,4.5,2.5,4.5,5.7v18.8h-1.8v-9.7c0-0.8-0.7-1.5-1.5-1.5
                    c-0.8,0-1.5,0.7-1.5,1.5v25c0,0.5-0.3,0.8-0.8,0.8h-3v-14c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v14h-3
                    c-0.5,0-0.8-0.3-0.8-0.8v-25c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.5,1.3V74H6z M16.4,97C9,97,2.9,93.5,2.9,89.3
                    c0-2.3,2-4.5,5.2-6v6.2c0,2.2,1.7,3.8,3.8,3.8h9.2c2.2,0,3.8-1.7,3.8-3.8v-6.2c3.2,1.5,5.2,3.7,5.2,6C29.9,93.5,23.7,97,16.4,97z
                    M39.6,44.7V25.8c0-3,2-5.7,4.5-5.7h11.7c2.5,0,4.5,2.5,4.5,5.7v18.8h-2v-9.8c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5V60
                    c0,0.5-0.3,0.8-0.8,0.8h-2.8v-14c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v14h-3c-0.5,0-0.8-0.3-0.8-0.8V34.8
                    c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v9.7L39.6,44.7z M36.5,59.8c0-2.3,2-4.5,5.2-6V60c0,2.2,1.7,3.8,3.8,3.8h9.2
                    c2.2,0,3.8-1.7,3.8-3.8v-6.2c3.2,1.5,5.2,3.7,5.2,6c0,4.2-6.2,7.7-13.5,7.7C42.8,67.5,36.5,64,36.5,59.8z M67.4,87.5H32.8
                    c-0.2-1-0.7-2.2-1.3-3l12.2-15c3.8,1.3,7.7,1.3,11.4,0.5l13.5,14.7C67.9,85.5,67.6,86.7,67.4,87.5z M73.1,74V55.3
                    c0-3,2-5.7,4.5-5.7h11.7c2.5,0,4.5,2.5,4.5,5.7v18.8h-2v-9.7c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v25
                    c0,0.5-0.3,0.8-0.8,0.8h-2.8v-14c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v14h-3c-0.5,0-0.8-0.3-0.8-0.8V64.3
                    c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5V74H73.1z M83.6,97c-7.4,0-13.5-3.5-13.5-7.7c0-2.3,2-4.5,5.2-6v6.2
                    c0,2.2,1.7,3.8,3.8,3.8h9.2c2.2,0,3.8-1.7,3.8-3.8v-6.2c3.2,1.5,5.2,3.7,5.2,6C97.1,93.5,91,97,83.6,97z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Experienced Team</h4>
								<p>
									At GenixBit Labs, we have a team of highly experienced professionals who have
									unmatched expertise in building best VR mobile games for you.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M88.4,10H70C65.4,4,58,0,49.8,0c-8.2,0-15.5,4-20.2,10H11.2c-4.2,0-7.5,3.3-7.5,7.5v49.3c0,4.2,3.3,7.5,7.5,7.5h7.7
                    c-1.3,3.5-2,7.5-2,11.5V100h66.2V85.7c0-4-0.7-7.8-2-11.5h7.7c4.2,0,7.5-3.3,7.5-7.5V17.5C95.9,13.3,92.6,10,88.4,10z M49.8,2.8
                    c12.4,0,22.6,10,22.6,22.5v10.3h2.8V25.3c0-1.5-0.2-3-0.3-4.3h10.2v42.3H74.2c-6-6.5-14.7-10.7-24.2-10.7
                    c-9.5,0-18.4,4.2-24.4,10.7h-11V21h10.2c-0.2,1.5-0.3,2.8-0.3,4.3v17.5h8.9c3.5,5.3,9.5,9,16.5,9c10.9,0,19.5-8.8,19.5-19.5v-7
                    c0-10.8-8.9-19.5-19.5-19.5c-10.7,0-19.5,8.7-19.5,19.5v7c0,2.7,0.5,5.3,1.5,7.7h-4.5V25.3C27.3,13,37.5,2.8,49.8,2.8z M50.8,20
                    c5.8,1,10,0.5,12.5-1.7c0.3-0.3,0.7-0.7,1-1.2c1.3,2.5,2.2,5.2,2.2,8.2v7C66.5,41.5,59,49,49.8,49c-5.2,0-9.9-2.3-12.9-6.2h15.2
                    V40H35c-1.2-2.3-1.8-5-1.8-7.7v-8.5C34.5,22.7,40.1,18,50.8,20z M34.1,19.7c2.3-6.5,8.5-11,15.7-11c5,0,9.5,2.3,12.7,5.8
                    c-0.2,0.5-0.3,1.2-1,1.7c-1.2,1-3.8,2-10,1C43.1,15.7,37.5,17.7,34.1,19.7z M11.2,71.3v0.2c-2.5,0-4.7-2-4.7-4.7V17.5
                    c0-2.5,2-4.7,4.7-4.7h16.5c-1,1.7-1.7,3.3-2.2,5.2H11.7v48.2h11.4c-1.2,1.7-2.2,3.3-3.2,5.2H11.2z M47.8,97.2H34V83.8h-2.8v13.3
                    H19.8l-0.2-11.5c0-12.2,7.2-22.7,17.7-27.3l10.5,16.5V97.2z M40.1,57.3c3-1,6.3-1.7,9.9-1.7c3.3,0,6.7,0.5,9.7,1.7L49.3,71.8
                    L40.1,57.3z M80.1,97.2H68.5V83.8h-2.8v13.3h-15V74.8l11.7-16.5C72.7,63,80.1,73.5,80.1,85.7V97.2z M93.1,66.8
                    c0,2.5-2,4.7-4.7,4.7h-8.9c-0.8-1.8-2-3.5-3.2-5.2h11.4V18H74.1c-0.5-1.8-1.3-3.5-2.2-5.2h16.5c2.5,0,4.7,2,4.7,4.7V66.8z
                    M47,37.2h6.5v-2.8H50V23H38.1v3H47V37.2z M60.7,23h-8.5v2.8h8.5V23z M80.9,32.5V26c0-1.7-1.3-2.8-2.8-2.8h-1.5V26h1.5v6.5h-1.5
                    v2.8h1.5C79.7,35.3,80.9,34.2,80.9,32.5z M18.4,26v6.5c0,1.7,1.3,2.8,2.8,2.8h1.5v-2.8h-1.5V26h1.5v-2.8h-1.3
                    C19.8,23,18.4,24.3,18.4,26z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Instant Support</h4>
								<p>
									Have aquery? Our team of support representatives are just a call/message away.
									Just let them know your concern and a solution will be with you shortly.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M34,40.7c0,8.6,7,15.6,15.7,15.6c8.7,0,15.7-7,15.7-15.6c0-8.6-7-15.6-15.7-15.6C41,25.2,34,32.3,34,40.7z M62.3,40.7
                    c0,7-5.7,12.7-12.8,12.7c-7,0-12.8-5.6-12.8-12.7c0-7,5.7-12.7,12.8-12.7C56.7,28,62.3,33.8,62.3,40.7z M9.3,32.8
                    c0,6.6,5.5,12.1,12.2,12.1s12.2-5.5,12.2-12.1c0-6.6-5.5-12.1-12.2-12.1S9.3,26.2,9.3,32.8z M30.8,32.8c0,5.1-4.2,9.3-9.3,9.3
                    c-5.2,0-9.3-4.1-9.3-9.3c0-5.1,4.2-9.3,9.3-9.3C26.7,23.5,30.8,27.7,30.8,32.8z M99.8,66.9c0-11.9-9.7-21.5-21.7-21.5
                    c-8.8,0-17,5.6-20.2,13.9c-2.7-0.8-5.5-1.3-8.3-1.3c-2.7,0-5.3,0.3-7.8,1.2c-3.2-8.3-11.2-13.7-20.2-13.7C9.7,45.4,0,55,0,66.9V77
                    h9.5v16.4h11.8V99H78v-5.6h12.3V77h9.7C99.8,77,99.8,66.9,99.8,66.9z M78.2,48.2c1,0,2,0,3,0.2l-3,7.5l-3-7.5
                    C76.2,48.2,77.2,48.2,78.2,48.2z M60.3,63.1v7.3h-4.5v9.8L51,85.8v-9.1l6-14.9C58.2,62.1,59.3,62.6,60.3,63.1z M21.5,48
                    c1,0.2,2,0.2,3,0.3l-3,7.5l-3-7.5C19.5,48.2,20.5,48,21.5,48z M9.5,74.2H2.7v-7.3c0-8.3,5.5-15.4,13-17.7L20,59.9v14.2h-7.7v-7.1
                    H9.5V74.2z M21.3,86.1v4.5h-9V77h10.5C21.8,79.8,21.3,82.9,21.3,86.1z M23,74V59.9l4.3-10.8c5.2,1.5,9.7,5.5,11.7,10.8
                    c-6.7,2.6-12,7.8-15,14.1H23z M48.2,96.2H36.8v-9.9H34v9.9h-9.8V86.1c0-8.9,4.8-16.9,11.8-21.4v8.4h4.5v8.1l7.7,8.9V96.2z
                    M48.2,85.8l-4.8-5.6v-9.8h-4.5v-7.3c1-0.5,2.2-1,3.3-1.3l6,14.9V85.8z M45.2,61.1c1.5-0.3,3-0.5,4.7-0.5c1.3,0.2,2.8,0.3,4.3,0.5
                    l-4.5,11.4L45.2,61.1z M75,96.2h-8.2v-9.9H64v9.9H51v-6l7.7-8.9v-8.1h4.5v-8.6C70.3,69,75,77,75,85.9V96.2z M60.5,59.9
                    c2.2-5.1,6.7-9.1,11.8-10.9l4.3,10.8V74h-1.5C72.2,67.7,67,62.7,60.5,59.9z M87.3,90.6h-9.5v-4.5c0-3.1-0.5-6.1-1.5-9.1h11V90.6z
                    M90.3,74.2v-7.1h-2.8v7.1h-7.8V59.9L84,49c7.5,2.5,13,9.4,13,17.7c0,0.2,0,7.4,0,7.4H90.3z M66,32.8c0,6.6,5.5,12.1,12.2,12.1
                    s12.2-5.5,12.2-12.1c0-6.6-5.5-12.1-12.2-12.1S66,26.2,66,32.8z M87.5,32.8c0,5.1-4.2,9.3-9.3,9.3c-5.2,0-9.3-4.1-9.3-9.3
                    c0-5.1,4.2-9.3,9.3-9.3C83.3,23.5,87.5,27.7,87.5,32.8z M1.7,18.5h96.5L93,12.8l5.5-5.5H79.7V1H20v6.5H1.3l5.5,5.3L1.7,18.5z
                    M79.7,10.3h11.8L89,12.8l2.8,3.1H79.7V10.3z M23,3.8h53.8v12.1H23V3.8z M8.2,10.3H20v5.6H7.8l2.8-3.1L8.2,10.3z M26.3,13.1h2.8
                    V8.4h43.2V5.6h-46V13.1z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Dedicated Team</h4>
								<p>
									Every VR mobile game development project we get earns a dedicated team. So, until
									the task is accomplished, you have your own team of experts who’ll be working on
									your project.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M87.1,89.2c0.3,0.3,0.7,0.5,1,0.5c0.3,0,0.8-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.2l-9.4-9.3c-0.7-0.7-1.7-0.7-2.2,0
                    c-0.7,0.7-0.7,1.7,0,2.2L87.1,89.2z M50.8,73.9l-2.5,0.5V83c0,0.5-0.7,1.2-1.7,1.2h-5c-1,0-1.7-0.7-1.7-1.2v-8.7l-2.5-0.5
                    c-3.5-0.8-7-2.2-10.1-4.2l-2.2-1.5L23.3,70L19,74.4c-0.2,0.2-0.3,0.2-0.7,0.2c-0.5,0-1-0.2-1.3-0.7l-3.5-3.5
                    c-0.7-0.7-0.8-1.7-0.3-2l4.4-4.3l1.8-1.8L18,60.1c-2-3.2-3.4-6.5-4.2-10l-0.5-2.5H4.4c-0.5,0-1.2-0.7-1.2-1.7v-4.8
                    c0-1,0.7-1.7,1.2-1.7h8.7l0.5-2.5c0.8-3.5,2.2-7,4.2-10l1.3-2.2L17.3,23l-4.2-4.2c-0.5-0.5-0.3-1.3,0.3-2.2l3.5-3.3
                    c0.3-0.3,0.8-0.7,1.3-0.7c0.3,0,0.5,0,0.7,0.2l4.4,4.3l1.8,1.8l2.2-1.3c3.2-2,6.5-3.3,10.1-4.2l2.5-0.5V4.3c0-0.5,0.7-1.2,1.7-1.2
                    h4.9c1,0,1.7,0.7,1.7,1.2V13l2.5,0.5c3.5,0.8,7,2.2,10.1,4.2l2.2,1.3l1.8-1.8l4.4-4.3c0.2-0.2,0.3-0.2,0.7-0.2
                    c0.5,0,1,0.2,1.3,0.7l3.5,3.5c0.7,0.7,0.8,1.7,0.3,2l-4.4,4.3L69,25l1.3,2.2c2,3.2,3.4,6.5,4.2,10l0.5,2.5h8.7
                    c0.5,0,1.2,0.7,1.2,1.7v4.8c0,1-0.7,1.7-1.2,1.7H75l-0.5,2.5c-0.5,2.2-1.2,4.3-2.2,6.3l2.3,2.2c1.2-2.5,2.2-5,2.9-7.8h6.2
                    c2.3,0,4.4-2.2,4.4-4.8v-4.8c0-2.7-2-4.8-4.4-4.8h-6.2c-0.8-4-2.5-7.7-4.5-11l4.4-4.3c1.7-1.7,1.5-4.7-0.3-6.5l-3.5-3.5
                    c-1-1-2.3-1.5-3.7-1.5c-1,0-2.2,0.3-2.9,1.2l-4.4,4.3c-3.4-2.2-7-3.7-11.1-4.5V4.3C51.5,2,49.3,0,46.7,0h-5c-2.7,0-4.9,2-4.9,4.3
                    v6.2c-4,0.8-7.7,2.5-11.1,4.5l-4.4-4.3c-0.8-0.8-1.8-1.2-2.9-1.2c-1.3,0-2.7,0.5-3.7,1.7l-3.5,3.3c-1.8,1.8-2,4.8-0.3,6.5l4.4,4.3
                    c-2.2,3.3-3.7,7-4.5,11H4.4C2,36.3,0,38.4,0,41.1v4.8c0,2.7,2,4.8,4.4,4.8h6.2c1,4.2,2.5,8,4.7,11.3l-4.4,4.3
                    c-1.7,1.7-1.5,4.7,0.3,6.5l3.5,3.3c1,1,2.3,1.5,3.7,1.5c1,0,2.2-0.3,2.9-1.2l4.4-4.3c3.4,2.2,7,3.7,11.1,4.5v6.2
                    c0,2.3,2.2,4.3,4.9,4.3h4.9c2.7,0,4.9-2,4.9-4.3v-6.2c2.3-0.5,4.7-1.3,6.9-2.3L56,71.9C54.4,72.9,52.7,73.4,50.8,73.9z M98.7,84.9
                    L62.3,51.7c1-2.3,1.7-5,1.7-7.8c0-10.8-8.7-19.5-19.6-19.5c-4.5,0-8.9,1.5-12.2,4.2l11.6,11.5c0.3,0.3,0.7,0.5,1.2,0.5
                    c0.3,0,0.8-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.2l-8.9-8.8c2.2-1,4.5-1.7,7.2-1.7c9.1,0,16.4,7.3,16.4,16.3c0,3.2-0.8,6-2.3,8.5
                    l2.3,2.2l35.7,32.4c0.5,0.5,0.5,1.3,0,2l-7.5,7.5c-0.3,0.3-0.7,0.3-1,0.3c-0.2,0-0.5,0-0.8-0.3L54.2,60.7L54,60.6l-1-1.2l-1.2-1.2
                    c-2.2,1.2-4.9,1.8-7.5,1.8c-9.1,0-16.4-7.3-16.4-16.3c0-2.2,0.5-4.2,1.2-6l8.6,8.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.8-0.2,1.2-0.5
                    c0.7-0.7,0.7-1.7,0-2.2L28.2,32.8c-2.2,3.2-3.5,7-3.5,11.1c0,10.8,8.7,19.5,19.6,19.5c2.3,0,4.7-0.5,6.9-1.2l33.6,36.4
                    c0.8,0.8,2,1.3,3.2,1.3c1.2,0,2.3-0.5,3.2-1.3l7.5-7.5C100.3,89.4,100.5,86.5,98.7,84.9z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Customized Solutions</h4>
								<p>
									We firmly believe that every game project has its own essence and expectations
									attached. This is why we thrive to serve you customized VR game development
									services.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.our-expert-build-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 12px 25px;
	position: relative;
}

.our-expert-build-box::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -15px;
	width: 0;
	height: 0;
	display: block;
	border: 15px solid transparent;
	border-right-color: #008dd2;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	opacity: 0;
	transform: translate(-50%, -50%);
}
.our-expert-build-box:hover::before {
	opacity: 1;
}
.our-expert-build-box:hover h4,
.our-expert-build-box:hover p {
	color: #fff !important;
}
.our-expert-build-box:hover {
	background: #008dd2;
}

.our-expert-build-box:hover .our-expert-build-icon svg circle,
.our-expert-build-box:hover .our-expert-build-icon svg ellipse,
.our-expert-build-box:hover .our-expert-build-icon svg path,
.our-expert-build-box:hover .our-expert-build-icon svg polygon,
.our-expert-build-box:hover .our-expert-build-icon svg rect {
	fill: #fff;
}

.our-expert-build-icon svg circle,
.our-expert-build-icon svg ellipse,
.our-expert-build-icon svg path,
.our-expert-build-icon svg polygon,
.our-expert-build-icon svg rect {
	fill: black;
}

.our-expert-build-icon svg {
	width: 50px;
	height: 50px;
	fill: black;
}
.our-expert-build-content-wrap {
	padding-bottom: 8px !important;
}
.our-expert-build-icon {
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 70px;
	min-height: 70px;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
