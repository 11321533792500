<template>
	<section class="padding-60 bg-light car-wash-feature-main">
		<div class="container">
			<div class="title">
				<span>Comprehensive Featureset</span>
				<h2 class="fxt-tech-use">Unique Features We Embed in Food Delivery App Development</h2>
			</div>
			<ul class="nav nav-tabs car-wash-feature-tab" id="technologies_tabs" role="tablist">
				<li>
					<a
						class="active"
						id="mobile-technologies-tab"
						data-toggle="tab"
						href="#mobile-technologies"
						role="tab"
						aria-controls="mobile-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M29.9,17.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C28.9,17.1,29.3,17.6,29.9,17.6z M20.1,15.6
                    c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C21.1,16.1,20.7,15.6,20.1,15.6z M28,23c-0.2-0.5-0.8-0.7-1.3-0.5
                    c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3c0.8,0.4,1.7,0.6,2.6,0.6
                    c0.9,0,1.8-0.2,2.6-0.6C28,24.1,28.3,23.5,28,23z M35.2,29.8l-2.7-2.4c3.3-2.4,5.2-6.2,5.2-10.3v-6.4C37.7,4.8,32.9,0,27,0h-3.9
                    c-5.9,0-10.7,4.8-10.7,10.7v6.4c0,4.1,1.9,7.9,5.2,10.2l-2.7,2.4C9.6,30,3.5,34.2,3.5,39.6V49c0,0.5,0.4,1,1,1h41.1
                    c0.5,0,1-0.4,1-1v-9.4C46.5,34.3,40.4,30,35.2,29.8z M33.6,31.1l-3.2,7.2l-4-2l4.7-7.5L33.6,31.1z M25,37.8l3,1.5l-0.8,2.3h-4.5
                    L22,39.3L25,37.8z M25,34.8l-3.4-5.4c1.1,0.3,2.2,0.5,3.4,0.5c1.1,0,2.3-0.2,3.4-0.5L25,34.8z M14.3,10.7c0-4.8,3.9-8.8,8.7-8.8
                    H27c4.9,0,8.8,3.9,8.8,8.8v0.8l-1.8-2.6c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3c-2,2-4.3,2.9-7.4,2.9
                    c-3.2,0-5.5-0.9-7.4-2.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.7,0.4l-1.7,2.6V10.7z M14.3,17.2v-2.3l2.7-4
                    c2.2,1.8,4.7,2.7,8,2.7c3.2,0,5.8-0.9,8-2.7l2.8,4v2.2C35.8,23,31,27.9,25,27.9C19,27.9,14.3,23.1,14.3,17.2z M18.9,28.8l4.7,7.5
                    l-4,2l-3.2-7.2L18.9,28.8z M19.8,48H5.4v-8.4c0-4,4.9-7.5,9.1-7.8l3.6,8.2c0.1,0.2,0.3,0.4,0.6,0.5s0.5,0.1,0.8,0l0.7-0.3l0.8,2.5
                    L19.8,48z M21.8,48l1.1-4.5h4.3l1,4.5H21.8z M44.5,48H30.2l-1.2-5.4l0.8-2.5l0.6,0.3c0.2,0.1,0.5,0.1,0.8,0
                    c0.3-0.1,0.5-0.3,0.6-0.5l3.6-8.2c4.3,0.3,9.1,3.8,9.1,7.8V48z M37.7,43.6h-3.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3.9
                    c0.5,0,1-0.4,1-1C38.6,44,38.2,43.6,37.7,43.6z M40.5,44.5c0,0.5,0.4,1,1,1s1-0.4,1-1c0-0.5-0.4-1-1-1S40.5,44,40.5,44.5z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>User</span>
					</a>
				</li>
				<li>
					<a
						id="web-technologies-tab"
						data-toggle="tab"
						href="#web-technologies"
						role="tab"
						aria-controls="web-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<path
										d="M9.7,33.5c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2S11.4,33.5,9.7,33.5z M11.6,36.7
                  c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9C10.7,34.8,11.6,35.6,11.6,36.7z"
									></path>
									<path
										d="M29.9,33.5c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2S31.7,33.5,29.9,33.5z M31.8,36.7c0,1-0.8,1.9-1.9,1.9
                  c-1,0-1.9-0.8-1.9-1.9c0-1,0.8-1.9,1.9-1.9C31,34.8,31.8,35.6,31.8,36.7z"
									></path>
									<path
										d="M35.9,28.1C35.8,28,35.6,28,35.4,28c-0.2,0-0.3,0.1-0.4,0.3c-0.2,0.3-0.1,0.7,0.1,0.9c1,0.7,1.6,1.9,1.6,3.2v4.3h-1.5
                  c0-2.9-2.4-5.2-5.3-5.2c-2.9,0-5.3,2.4-5.3,5.3c0,0.1,0,0.1,0,0.2H15c0-0.1,0-0.1,0-0.2c0-2.9-2.4-5.3-5.3-5.3s-5.3,2.4-5.3,5.3
                  c0,0.1,0,0.1,0,0.2H2.6c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h0.1c0.4,0,0.7-0.3,0.7-0.7v-0.8c0-1.4,1-2.6,2.4-2.8l6.5-1.1h15.1
                  c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-8.9v-6.7h5.4c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-6c-1.8,0-3.4,1.1-4,2.7l-2,5.3
                  l-6.2,1.1c-2,0.4-3.4,2.1-3.4,4.1v0.2c-1.3,0.2-2.2,1.4-2.2,2.7c0,1.5,1.2,2.8,2.8,2.8h1.9C5.3,40.4,7.3,42,9.7,42s4.4-1.6,5.1-3.8
                  h10.1c0.7,2.3,2.7,3.8,5.1,3.8c2.4,0,4.6-1.7,5.2-4.1h2.3c0.4,0,0.7-0.3,0.7-0.7v-4.9C38.1,30.7,37.3,29.1,35.9,28.1z M33.9,36.7
                  c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4S33.9,34.5,33.9,36.7z M13.7,36.7c0,2.2-1.8,4-4,4s-4-1.8-4-4s1.8-4,4-4S13.7,34.5,13.7,36.7
                  z M13.2,27.1l1.8-4.8c0.4-0.9,1.2-1.6,2.1-1.8v6.6H13.2z"
									></path>
									<path
										d="M23.8,13.2h0.6v0.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-0.6h0.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-0.6v-0.6
                  c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v0.6h-0.6c-0.4,0-0.7,0.3-0.7,0.7S23.5,13.2,23.8,13.2z"
									></path>
									<path
										d="M21.6,26.7c0.4,0,0.7-0.3,0.7-0.7v-1.6h1.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.6v-1.6c0-0.4-0.3-0.7-0.7-0.7
                  s-0.7,0.3-0.7,0.7v1.6h-1.6c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.6V26C20.9,26.4,21.2,26.7,21.6,26.7z"
									></path>
									<path
										d="M50,17l-5.9-8.9C44,8,43.9,7.9,43.7,7.8c-0.2,0-0.3,0-0.5,0.1L40,10.1c-0.2-0.1-0.4-0.2-0.5-0.2c-0.5-0.1-1,0-1.4,0.3
                  c-0.2,0.1-0.3,0.3-0.5,0.4L37.2,10c-0.3-0.4-0.7-0.7-1.2-0.8c-0.5-0.1-1,0-1.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4
                  c-0.6-0.8-1.7-1-2.6-0.4c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.5,0,1,0.3,1.4l0.4,0.6c-0.2,0.1-0.4,0.1-0.6,0.3
                  c-0.4,0.3-0.7,0.7-0.8,1.2c-0.1,0.5,0,1,0.3,1.4l0.4,0.7L25,20c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.2,0,0.3,0.1,0.5l5.9,8.9
                  c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1l3.7-2.5c1.3,1.2,2.9,1.8,4.7,1.8c0.6,0,1.2-0.1,1.7-0.2
                  c-0.1,0.1-0.1,0.2-0.1,0.3V31H40c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.6v1.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-1.6h1.6
                  c0.4,0,0.7-0.3,0.7-0.7S44.9,31,44.5,31h-1.6v-1.6c0-0.3-0.2-0.5-0.4-0.6c0.5-0.2,0.9-0.4,1.3-0.7c2.7-1.8,3.8-5.1,2.8-8.1l3.2-2.1
                  C50.1,17.7,50.2,17.3,50,17z M37,26.4c0.3-0.2,0.4-0.6,0.2-0.9c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2,0-0.3,0-0.5,0.1l-4.8,3.2l-5.2-7.8
                  l5.1-3.4l4.1,6.3c0.2,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.4-0.6,0.2-0.9l-4.5-6.8c0,0,0,0,0-0.1l-0.8-1.2c-0.2-0.3-0.1-0.7,0.2-0.9
                  c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.3l3.5,5.3c0.2,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.4-0.6,0.2-0.9L32,11.4
                  c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.3l4.9,7.4c0.2,0.3,0.6,0.4,0.9,0.2
                  c0.1-0.1,0.2-0.2,0.3-0.4c0-0.2,0-0.3-0.1-0.5l-4-6c-0.2-0.3-0.1-0.7,0.2-0.9c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3,0.1,0.4,0.3l4,6
                  c0.2,0.3,0.6,0.4,0.9,0.2c0.3-0.2,0.4-0.6,0.2-0.9l-2.5-3.9l0,0l0,0c-0.2-0.3-0.1-0.6,0.2-0.8c0.1-0.1,0.3-0.1,0.5-0.1
                  c0.2,0,0.3,0.1,0.4,0.3l5.1,7.8c0.8,1.3,1.1,2.8,0.8,4.3c-0.3,1.5-1.2,2.8-2.4,3.6c-2.1,1.4-4.7,1.2-6.7-0.3L37,26.4z M48.6,17.2
                  l-2.5,1.7c-0.1-0.1-0.1-0.3-0.2-0.4L40.9,11l2.5-1.6L48.6,17.2z"
									></path>
								</g>
							</svg>
						</div>
						<span>Washer</span>
					</a>
				</li>
				<li>
					<a
						id="cloud-tab"
						data-toggle="tab"
						href="#cloud"
						role="tab"
						aria-controls="cloud"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									d="M46.1,5.9H3.9C1.7,5.9,0,7.6,0,9.8v26.6c0,2.2,1.7,3.9,3.9,3.9h15.8v2.3h-2.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h16.4
                c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-2.9v-2.3h15.8c2.2,0,3.9-1.7,3.9-3.9V9.8C50,7.6,48.3,5.9,46.1,5.9z M28.7,42.6h-7.4v-2.3
                h7.4V42.6z M46.1,38.7H3.9c-1,0-1.9-0.6-2.2-1.6h46.6C48,38,47.1,38.7,46.1,38.7z M48.4,35.5H1.6V9.8c0-1.3,1-2.3,2.3-2.3h42.2
                c1.3,0,2.3,1,2.3,2.3V35.5z"
								></path>
								<path
									d="M37.1,27.7h-0.2c3.2-6.6,0.4-14.5-6.2-17.6s-14.5-0.4-17.6,6.2c-1.7,3.6-1.7,7.8,0,11.5h-0.2c-1.7,0-3.1,1.4-3.1,3.1
                s1.4,3.1,3.1,3.1h24.2c1.7,0,3.1-1.4,3.1-3.1S38.8,27.7,37.1,27.7z M25,10.4c6.4,0,11.7,5.2,11.7,11.7c0,2-0.5,4-1.5,5.7h-2.3v-2.1
                c0-1.7-0.5-3.3-1.4-4.7c-0.5-0.8-1.2-1.5-2-2c-0.3,0.5-0.6,0.9-1,1.2c0.7,0.4,1.3,1,1.7,1.7c0.8,1.1,1.1,2.4,1.1,3.8v2.1h-1.6v-2
                c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2h-6.3v-2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v2h-1.6v-2.1c0-1.3,0.4-2.7,1.1-3.8
                c0.5-0.7,1.1-1.2,1.7-1.7c-0.4-0.4-0.7-0.8-1-1.2c-0.8,0.5-1.5,1.2-2,2c-0.9,1.4-1.4,3-1.4,4.7v2.1h-2.3c-3.2-5.6-1.2-12.7,4.4-15.9
                C21,10.9,23,10.4,25,10.4z M37.1,32.4H12.9c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h24.2c0.9,0,1.6,0.7,1.6,1.6S38,32.4,37.1,32.4z"
								></path>
								<path
									d="M26.8,21.1c-0.5,0.2-1,0.3-1.5,0.3l0.4,1.4L25,23.5l-0.7-0.7l0.4-1.4c-0.5,0-1-0.1-1.5-0.3l-0.5,1.7c-0.1,0.3,0,0.6,0.2,0.8
                l1.6,1.6c0.3,0.3,0.8,0.3,1.1,0l1.6-1.6c0.2-0.2,0.3-0.5,0.2-0.8L26.8,21.1z"
								></path>
								<path
									d="M25,19.9c1.9,0,3.5-1.6,3.5-3.5s-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5C21.5,18.3,23.1,19.9,25,19.9z M25,14.5c1.1,0,2,0.9,2,2
                s-0.9,2-2,2c-1.1,0-2-0.9-2-2C23,15.3,23.9,14.5,25,14.5z"
								></path>
							</svg>
						</div>
						<span>Admin</span>
					</a>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade show active fxt-tab-pane"
					id="mobile-technologies"
					role="tabpanel"
					aria-labelledby="mobile-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items1"
						v-bind:className="'wash-user'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="web-technologies"
					role="tabpanel"
					aria-labelledby="web-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items2"
						v-bind:className="'wash-washer'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="cloud"
					role="tabpanel"
					aria-labelledby="cloud-tab"
				>
					<RoundedCardContainer
						v-bind:items="items3"
						v-bind:className="'wash-admin'"
					></RoundedCardContainer>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RoundedCardContainer from '../AssetComponents/RoundedCardContainer.vue';
export default {
	components: {
		RoundedCardContainer
	},
	data() {
		return {
			items1: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <path d="M73.1,64.9c-0.1,0-11.6,0.3-13.3-12c1.9-1.3,3.7-3.1,5.2-5.1c3-4.1,4.9-9.2,5.6-14.7c0.2-0.2,0.4-0.5,0.6-0.8
                    c1.2-3,1.9-6.1,1.9-9.4C73.1,10.3,63.6,0,51.9,0c-3.1,0-6,0.7-8.8,2.1C42,2.2,41,2.4,39.9,2.7c-4.8,1.4-8.7,5-11,10
                    c-2.2,4.9-2.7,10.7-1.2,16.4c0.3,1.2,0.7,2.3,1.1,3.5c0.1,0.3,0.3,0.6,0.5,0.8c1.1,8.6,5.2,15.7,10.8,19.6
                    c-1.7,12.4-13.2,12-13.3,12C18.6,65.2,12,72,12,80.4v17.4c0,1.2,1,2.3,2.2,2.3H34H66h19.8c1.2,0,2.3-1,2.3-2.3V80.4
                    C88,72,81.4,65.2,73.1,64.9z M41.2,7c0.8-0.2,1.7-0.4,2.6-0.4c0.3,0,0.7-0.1,0.9-0.3c2.3-1.2,4.7-1.8,7.2-1.8
                    c9.2,0,16.7,8.2,16.7,18.4c0,0.3,0,0.6,0,0.9c-1.8-1.8-4.3-2.9-7-2.9H46.2c-0.9,0-1.8-0.3-2.5-0.8c-0.6-0.5-1.1-1.1-1.4-1.8
                    c-0.4-1.1-1.5-1.8-2.7-1.7c-1.2,0.1-2.2,0.9-2.5,2.1c-0.9,3.4-2.7,6.6-5.1,9.1C29.8,18.4,33.8,9.1,41.2,7z M39.2,45.9
                    c-2.9-3.6-4.8-8.4-5.5-13.7c2.8-2.5,5.1-5.7,6.5-9.2c0.2,0.2,0.5,0.4,0.7,0.6c1.5,1.1,3.3,1.7,5.2,1.7h15.3c2.2,0,4,1.3,4.9,3.2
                    c0,0,0,0,0,0c0,12.7-7.4,23.1-16.4,23.1C46.1,51.6,42.2,49.6,39.2,45.9z M44.4,55.1c1.8,0.6,3.7,1,5.6,1c1.9,0,3.8-0.3,5.6-1
                    c0.8,4.3,3.2,8,6.5,10.6L50.3,82.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2-0.1-0.3-0.2L37.9,65.7C41.2,63.2,43.6,59.4,44.4,55.1z
                    M83.5,95.5H66H34H16.5V80.4c0-6,4.9-11,10.9-11c0.1,0,3.7-0.1,6.6-1.4l12,17.1c0.9,1.3,2.4,2.1,4,2.1c0,0,0,0,0,0
                    c1.6,0,3.1-0.8,4-2.1L66,68c3.3,1.4,6.5,1.4,6.6,1.4c6,0,10.9,4.9,10.9,11V95.5L83.5,95.5z"></path>
                  </svg>`,
					title: 'User Profile',
					text: 'Every user who downloads the app can create a user profile with a personalized name and personal details.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M30.4,11.5l-4.8-4.8c-0.3-0.3-0.8-0.3-1.1,0l-4.8,4.8c-0.2,0.2-0.2,0.4-0.2,0.6h0v8.1c0,0.4,0.4,0.8,0.8,0.8h9.7
                      c0.4,0,0.8-0.4,0.8-0.8v-8.1h0C30.6,11.9,30.6,11.7,30.4,11.5z M25,8.4l2.9,2.9h-5.8L25,8.4z M25.8,19.4h-1.6v-3.2h1.6V19.4z
                      M27.4,19.4v-4c0-0.4-0.4-0.8-0.8-0.8h-3.2c-0.4,0-0.8,0.4-0.8,0.8v4H21v-6.5H29v6.5H27.4z"></path>
                      <path d="M36.3,18.5h-0.5c1.9-3.9,1.8-8.5-0.4-12.4c-2.1-3.7-5.6-5.9-9.6-6.2c-0.5,0-1,0-1.6,0c-4,0.2-7.5,2.5-9.6,6.2
                      c-2.2,3.8-2.3,8.4-0.4,12.4h-0.5c-3.1,0-5.6,2.5-5.6,5.6V50h1.6V24.2c0-2.2,1.8-4,4-4h1.4l9.2,16.5c0.1,0.3,0.4,0.4,0.7,0.4
                      s0.6-0.2,0.7-0.4l9.2-16.5h1.4c2.2,0,4,1.8,4,4V50h1.6V24.2C41.9,21.1,39.4,18.5,36.3,18.5z M25,34.6l-9.1-16.4
                      C14,14.7,14.1,10.5,16,7c1.8-3.2,4.8-5.1,8.3-5.4c0.2,0,0.5,0,0.7,0s0.5,0,0.7,0C29.2,1.8,32.2,3.8,34,7c2,3.5,2,7.7,0.1,11.2
                      L25,34.6z"></path>
                      <path d="M37.9,26.6h-5.6v1.6h4.8V50h1.6V27.4C38.7,27,38.3,26.6,37.9,26.6z"></path>
                      <path d="M17.7,26.6h-5.6c-0.4,0-0.8,0.4-0.8,0.8V50h1.6V28.2h4.8V26.6z"></path>
                      <path d="M25,41.9c4,0,7.3-3.3,7.3-7.3h-1.6c0,3.1-2.5,5.6-5.6,5.6s-5.6-2.5-5.6-5.6h-1.6C17.7,38.7,21,41.9,25,41.9z"></path>
                      <path d="M25,43.5c-4.9,0-8.9-4-8.9-8.9h-1.6c0,5.8,4.7,10.5,10.5,10.5s10.5-4.7,10.5-10.5h-1.6C33.9,39.6,29.9,43.5,25,43.5z"></path>
                      <path d="M38.5,4.8c1.3,0.4,2.3,1.4,2.7,2.7c0.1,0.3,0.4,0.6,0.8,0.6c0.3,0,0.7-0.2,0.8-0.6c0.4-1.3,1.4-2.3,2.7-2.7
                      C45.7,4.7,46,4.4,46,4s-0.2-0.7-0.6-0.8c-1.3-0.4-2.3-1.4-2.7-2.7C42.6,0.2,42.3,0,41.9,0c-0.3,0-0.7,0.2-0.8,0.6
                      c-0.4,1.3-1.4,2.3-2.7,2.7c-0.3,0.1-0.6,0.4-0.6,0.8S38.1,4.7,38.5,4.8z M41.9,2.6c0.4,0.6,0.9,1.1,1.5,1.5
                      c-0.6,0.4-1.1,0.9-1.5,1.5C41.5,4.9,41,4.4,40.5,4C41,3.6,41.5,3.1,41.9,2.6z"></path>
                      <path d="M4.6,4.8c1.3,0.4,2.3,1.4,2.7,2.7c0.1,0.3,0.4,0.6,0.8,0.6c0.3,0,0.7-0.2,0.8-0.6c0.4-1.3,1.4-2.3,2.7-2.7
                      c0.3-0.1,0.6-0.4,0.6-0.8s-0.2-0.7-0.6-0.8c-1.3-0.4-2.3-1.4-2.7-2.7C8.7,0.2,8.4,0,8.1,0C7.7,0,7.4,0.2,7.3,0.6
                      C6.9,1.8,5.9,2.8,4.6,3.3C4.3,3.4,4,3.7,4,4S4.3,4.7,4.6,4.8z M8.1,2.6C8.5,3.1,9,3.6,9.5,4C9,4.4,8.5,4.9,8.1,5.5
                      C7.7,4.9,7.2,4.4,6.6,4C7.2,3.6,7.7,3.1,8.1,2.6z"></path>
                      <path d="M7.5,17.8c-1.3-0.4-2.3-1.4-2.7-2.7c-0.1-0.3-0.4-0.6-0.8-0.6s-0.7,0.2-0.8,0.6c-0.4,1.3-1.4,2.3-2.7,2.7
                      C0.2,17.9,0,18.2,0,18.5s0.2,0.7,0.6,0.8c1.3,0.4,2.3,1.4,2.7,2.7c0.1,0.3,0.4,0.6,0.8,0.6s0.7-0.2,0.8-0.6
                      c0.4-1.3,1.4-2.3,2.7-2.7c0.3-0.1,0.6-0.4,0.6-0.8S7.8,17.9,7.5,17.8z M4,20c-0.4-0.6-0.9-1.1-1.5-1.5c0.6-0.4,1.1-0.9,1.5-1.5
                      c0.4,0.6,0.9,1.1,1.5,1.5C4.9,18.9,4.4,19.4,4,20z"></path>
                      <path d="M49.4,17.8c-1.3-0.4-2.3-1.4-2.7-2.7c-0.1-0.3-0.4-0.6-0.8-0.6s-0.7,0.2-0.8,0.6c-0.4,1.3-1.4,2.3-2.7,2.7
                      c-0.3,0.1-0.6,0.4-0.6,0.8s0.2,0.7,0.6,0.8c1.3,0.4,2.3,1.4,2.7,2.7c0.1,0.3,0.4,0.6,0.8,0.6s0.7-0.2,0.8-0.6
                      c0.4-1.3,1.4-2.3,2.7-2.7c0.3-0.1,0.6-0.4,0.6-0.8S49.8,17.9,49.4,17.8z M46,20c-0.4-0.6-0.9-1.1-1.5-1.5c0.6-0.4,1.1-0.9,1.5-1.5
                      c0.4,0.6,0.9,1.1,1.5,1.5C46.9,18.9,46.4,19.4,46,20z"></path>
                    </g>
                  </svg>`,
					title: 'Address',
					text: 'Create custom addresses, favorite locations on the built-in map for easier finding of car wash services.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g transform="translate(-966.000000, -1245.000000)">
                        <g transform="translate(966.000000, 1245.000000)">
                          <path d="M19.4,1.7v5h11.6l0-5H19.4z M31.1,6.7v0.8V6.7L31.1,6.7z M31.1,8.3H19.4c-0.9,0-1.7-0.7-1.7-1.7v-5
                          c0-0.9,0.7-1.7,1.7-1.7h11.6c0.9,0,1.7,0.7,1.7,1.7v5C32.7,7.6,32,8.3,31.1,8.3L31.1,8.3z"></path>
                          <path d="M6.9,3.3c-0.5,0-0.8,0.4-0.8,0.8v43.3c0,0.5,0.4,0.8,0.8,0.8h36.6c0.5,0,0.8-0.4,0.8-0.8V4.2c0-0.5-0.4-0.8-0.8-0.8H31.9
                          c-0.5,0-0.8-0.4-0.8-0.8V1.7H19.4v0.8c0,0.5-0.4,0.8-0.8,0.8H6.9z M43.5,49.9H6.9c-1.4,0-2.5-1.1-2.5-2.5V4.2
                          c0-1.4,1.1-2.5,2.5-2.5h10.8c0-0.9,0.7-1.7,1.7-1.7h11.6c0.9,0,1.7,0.7,1.7,1.7h10.8c1.4,0,2.5,1.1,2.5,2.5v43.3
                          C46,48.8,44.9,49.9,43.5,49.9L43.5,49.9z"></path>
                          <path d="M9.4,6.7v38.3H41V6.7h-8.3c0,0.9-0.7,1.7-1.7,1.7H19.4c-0.9,0-1.7-0.7-1.7-1.7H9.4z M41,46.6H9.4c-0.9,0-1.7-0.7-1.7-1.7
                          V6.7C7.8,5.7,8.5,5,9.4,5h9.2c0.5,0,0.8,0.4,0.8,0.8v0.8h11.6V5.8c0-0.5,0.4-0.8,0.8-0.8H41c0.9,0,1.7,0.7,1.7,1.7v38.3
                          C42.7,45.8,42,46.6,41,46.6L41,46.6z"></path>
                          <path d="M33.5,39.9c-0.3,0-0.5-0.1-0.7-0.4l-1.7-2.5c-0.3-0.4-0.2-0.9,0.2-1.2c0.4-0.3,0.9-0.2,1.2,0.2l1.7,2.5
                          c0.3,0.4,0.2,0.9-0.2,1.2C33.9,39.9,33.7,39.9,33.5,39.9"></path>
                          <path d="M33.5,39.9c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.3-0.5-0.8-0.2-1.2l4.2-5.8c0.3-0.4,0.8-0.5,1.2-0.2c0.4,0.3,0.5,0.8,0.2,1.2
                          l-4.2,5.8C34.1,39.8,33.8,39.9,33.5,39.9"></path>
                          <path d="M17.7,17.5h15.1l-2.6-3.7c-0.2-0.3-0.5-0.5-0.9-0.5h-8c-0.4,0-0.7,0.2-0.9,0.5L17.7,17.5z M34.4,19.1H16.1
                          c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.3-0.1-0.6,0.1-0.9l3.6-5c0.5-0.7,1.4-1.2,2.2-1.2h8c0.9,0,1.7,0.4,2.2,1.2l3.6,5
                          c0.2,0.3,0.2,0.6,0.1,0.9C35,19,34.7,19.1,34.4,19.1L34.4,19.1z"></path>
                          <path d="M18.6,22.5c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7C20.2,23.2,19.5,22.5,18.6,22.5
                          M18.6,27.5c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3C21.9,26,20.4,27.5,18.6,27.5"></path>
                          <path d="M31.9,22.5c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7C33.5,23.2,32.8,22.5,31.9,22.5
                          M31.9,27.5c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3C35.2,26,33.7,27.5,31.9,27.5"></path>
                          <path d="M35.1,23.3h1.3c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H14c-0.3,0-0.5,0.2-0.5,0.5v3.2c0,0.3,0.2,0.5,0.5,0.5
                          h1.3c0.4-1.4,1.7-2.5,3.2-2.5c1.5,0,2.9,1.1,3.2,2.5h6.9c0.4-1.4,1.7-2.5,3.2-2.5S34.7,21.9,35.1,23.3 M36.4,25h-2
                          c-0.5,0-0.8-0.4-0.8-0.8c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.5-0.4,0.8-0.8,0.8h-8.3c-0.5,0-0.8-0.4-0.8-0.8
                          c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.5-0.4,0.8-0.8,0.8h-2c-1.2,0-2.1-1-2.1-2.1v-3.2c0-1.2,1-2.1,2.1-2.1h22.4
                          c1.2,0,2.1,1,2.1,2.1v3.2C38.5,24,37.6,25,36.4,25"></path>
                          <path d="M25.2,33.3H12.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h12.5c0.5,0,0.8,0.4,0.8,0.8S25.7,33.3,25.2,33.3"></path>
                          <path d="M25.2,37.4H12.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h12.5c0.5,0,0.8,0.4,0.8,0.8S25.7,37.4,25.2,37.4"></path>
                          <path d="M25.2,41.6H12.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h12.5c0.5,0,0.8,0.4,0.8,0.8S25.7,41.6,25.2,41.6"></path>
                          <path d="M25.2,19.1c-0.5,0-0.8-0.4-0.8-0.8v-5.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v5.8C26.1,18.8,25.7,19.1,25.2,19.1
                          "></path>
                        </g>
                      </g>
                    </g>
                  </svg>`,
					title: 'Car Details',
					text: 'Enter car details like make, model name, year, color and other details to pick the right car wash service.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M38.7,45.8V4.2c0-2.3-1.9-4.2-4.2-4.2H12.8c-2.3,0-4.2,1.9-4.2,4.2v41.7c0,2.3,1.9,4.2,4.2,4.2h21.7
                      C36.8,50,38.7,48.1,38.7,45.8z M10.3,45.8V4.2c0-1.4,1.1-2.5,2.5-2.5h21.7c1.4,0,2.5,1.1,2.5,2.5v41.7c0,1.4-1.1,2.5-2.5,2.5H12.8
                      C11.5,48.3,10.3,47.2,10.3,45.8z"></path>
                      <path d="M35.3,39.2V7.5c0-0.5-0.4-0.8-0.8-0.8H12.8c-0.5,0-0.8,0.4-0.8,0.8v31.7c0,0.5,0.4,0.8,0.8,0.8h21.7
                      C35,40,35.3,39.6,35.3,39.2z M33.7,38.3h-20v-30h20V38.3z"></path>
                      <path d="M20.3,5H27c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8C19.5,4.6,19.9,5,20.3,5z"></path>
                      <path d="M30.3,5H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-1.7c-0.5,0-0.8,0.4-0.8,0.8C29.5,4.6,29.9,5,30.3,5z"></path>
                      <path d="M15.3,5H17c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-1.7c-0.5,0-0.8,0.4-0.8,0.8C14.5,4.6,14.9,5,15.3,5z"></path>
                      <path d="M27,41.7h-6.7c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8H27c0.5,0,0.8-0.4,0.8-0.8v-3.3C27.8,42,27.5,41.7,27,41.7
                      z M26.2,45h-5v-1.7h5V45z"></path>
                    </g>
                    <path d="M29.8,22.1l-1.6-4.3c-0.1-0.2-0.2-0.3-0.4-0.3h-8.2c-0.2,0-0.3,0.1-0.4,0.3l-1.6,4.3c-1,0.1-1.7,1-1.7,2v3.3
                    c0,0.2,0.2,0.4,0.4,0.4h0.8v0.3c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-0.3h1.8h3.3h1.8v0.3c0,0.8,0.7,1.5,1.5,1.5
                    c0.8,0,1.5-0.7,1.5-1.5v-0.3h0.8c0.2,0,0.4-0.2,0.4-0.4v-3.3C31.5,23.1,30.8,22.2,29.8,22.1z M20,18.4h7.6l1.3,3.7h-1.6l-1.9-1.9
                    c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.4,0,0.6l1.3,1.3h-2.7l-1.9-1.9c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.4,0,0.6l1.3,1.3h-3.6
                    L20,18.4z M19.5,28.1c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-0.3h1.4V28.1z M22.5,27v-1.2H25V27H22.5z M29.5,28.1
                    c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-0.3h1.4V28.1z M30.7,27h-0.8h-2.2h-1.8v-1.6c0-0.2-0.2-0.4-0.4-0.4h-3.3
                    c-0.2,0-0.4,0.2-0.4,0.4V27h-1.8h-2.2h-0.8v-2.9c0-0.7,0.6-1.2,1.2-1.2h11.5c0.7,0,1.2,0.6,1.2,1.2V27z M19.3,23.7
                    c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2C20.5,24.2,19.9,23.7,19.3,23.7z M19.3,25.3c-0.2,0-0.4-0.2-0.4-0.4
                    c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C19.7,25.1,19.5,25.3,19.3,25.3z M28.3,23.7c-0.7,0-1.2,0.6-1.2,1.2c0,0.7,0.6,1.2,1.2,1.2
                    s1.2-0.6,1.2-1.2C29.5,24.2,28.9,23.7,28.3,23.7z M28.3,25.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4
                    C28.7,25.1,28.5,25.3,28.3,25.3z M15.2,11.6c0-0.2,0.2-0.4,0.4-0.4H32c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4H15.6
                    C15.4,12,15.2,11.9,15.2,11.6z M16,15.5c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-2.2c0-0.2,0.2-0.4,0.4-0.4S16,13,16,13.3V15.5z
                    M17.6,15.9v0.4c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4S17.6,15.7,17.6,15.9z M17.6,14.5
                    c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-1.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V14.5z M18.9,12.9c0.2,0,0.4,0.2,0.4,0.4v1.6
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-1.6C18.5,13,18.6,12.9,18.9,12.9z M20.5,12.9c0.2,0,0.4,0.2,0.4,0.4v2.2
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-2.2C20.1,13,20.3,12.9,20.5,12.9z M22.5,15.9v0.4c0,0.2-0.2,0.4-0.4,0.4
                    c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4C22.4,15.5,22.5,15.7,22.5,15.9z M22.5,14.5c0,0.2-0.2,0.4-0.4,0.4
                    c-0.2,0-0.4-0.2-0.4-0.4v-1.2c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V14.5z M23.8,12.9c0.2,0,0.4,0.2,0.4,0.4v1.6
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-1.6C23.4,13,23.5,12.9,23.8,12.9z M25.4,12.9c0.2,0,0.4,0.2,0.4,0.4v2.2
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-2.2C25,13,25.2,12.9,25.4,12.9z M27.5,15.9v0.4c0,0.2-0.2,0.4-0.4,0.4
                    c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4C27.3,15.5,27.5,15.7,27.5,15.9z M27.5,14.5c0,0.2-0.2,0.4-0.4,0.4
                    c-0.2,0-0.4-0.2-0.4-0.4v-1.2c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V14.5z M28.7,12.9c0.2,0,0.4,0.2,0.4,0.4v1.6
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-1.6C28.3,13,28.5,12.9,28.7,12.9z M30.3,12.9c0.2,0,0.4,0.2,0.4,0.4v2.2
                    c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-2.2C29.9,13,30.1,12.9,30.3,12.9z M32.4,15.9v0.4c0,0.2-0.2,0.4-0.4,0.4
                    s-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4S32.4,15.7,32.4,15.9z M32.4,13.3v1.2c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-1.2
                    c0-0.2,0.2-0.4,0.4-0.4S32.4,13,32.4,13.3z"></path>
                    <path d="M21.9,34.8c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.1,0-0.2,0v-0.5h0.1C21.8,34.5,21.9,34.6,21.9,34.8z"></path>
                    <path d="M21.5,34.2h-0.1v-0.4c0,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.3,0.2S21.8,34.2,21.5,34.2z"></path>
                    <path d="M23.2,34.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4C23.5,34.5,23.4,34.3,23.2,34.3z M24.8,34.3
                    c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4C25,34.5,25,34.3,24.8,34.3z M24.8,34.3
                    c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4C25,34.5,25,34.3,24.8,34.3z M23.2,34.3
                    c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4C23.5,34.5,23.4,34.3,23.2,34.3z M29.5,32.8H17.8
                    c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h11.6c1,0,1.8-0.8,1.8-1.8C31.2,33.6,30.5,32.8,29.5,32.8z M22.2,35.2
                    c-0.1,0.1-0.3,0.2-0.7,0.2c-0.2,0-0.3,0-0.4,0v-1.8c0.1,0,0.3,0,0.5,0c0.3,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.4
                    c0,0.2-0.1,0.3-0.3,0.4v0c0.2,0,0.4,0.2,0.4,0.4C22.3,34.9,22.3,35.1,22.2,35.2z M23.2,35.3c-0.4,0-0.7-0.3-0.7-0.7
                    c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C23.9,35.1,23.6,35.3,23.2,35.3z M24.8,35.3c-0.4,0-0.7-0.3-0.7-0.7
                    c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C25.5,35.1,25.1,35.3,24.8,35.3z M26.5,35.3l-0.3-0.5l-0.1,0.1v0.4h-0.4v-1.9h0.4v1.1
                    h0c0,0,0.1-0.1,0.1-0.2l0.3-0.4H27l-0.5,0.5l0.5,0.8H26.5z M24.8,34.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4
                    c0.2,0,0.3-0.2,0.3-0.4C25,34.5,25,34.3,24.8,34.3z M23.2,34.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4
                    C23.5,34.5,23.4,34.3,23.2,34.3z M23.2,34.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4
                    C23.5,34.5,23.4,34.3,23.2,34.3z M23.2,34.3c-0.2,0-0.3,0.2-0.3,0.4c0,0.2,0.1,0.4,0.3,0.4c0.2,0,0.3-0.2,0.3-0.4
                    C23.5,34.5,23.4,34.3,23.2,34.3z"></path>
                  </svg>`,
					title: 'Book a wash',
					text: 'Users can book a car wash from their real-time location. The booking can be made for immediate use or schedule it for later.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M19.9,33.8h-8c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-8C21.8,34.7,21,33.8,19.9,33.8z M17.9,41.8h-4v-4h4
                        V41.8z M35.8,33.8h-8c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-8C37.8,34.7,36.9,33.8,35.8,33.8z M33.8,41.8h-4v-4
                        h4V41.8z M45.7,45.8h8c1.1,0,2-0.9,2-2v-8c0-1.1-0.9-2-2-2h-8c-1.1,0-2,0.9-2,2v8C43.8,44.9,44.6,45.8,45.7,45.8z M47.7,37.8h4v4
                        h-4V37.8z M63.7,45.8h8c1.1,0,2-0.9,2-2v-8c0-1.1-0.9-2-2-2h-8c-1.1,0-2,0.9-2,2v8C61.7,44.9,62.6,45.8,63.7,45.8z M65.7,37.8h4v4
                        h-4V37.8z M19.9,53.7h-8c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-8C21.8,54.6,21,53.7,19.9,53.7z M17.9,61.7h-4v-4
                        h4V61.7z M35.8,53.7h-8c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-8C37.8,54.6,36.9,53.7,35.8,53.7z M33.8,61.7h-4
                        v-4h4V61.7z M83.6,48.1V4c0-1.1-0.9-2-2-2H1.9c-1.1,0-2,0.9-2,2v71.6c0,1.1,0.9,2,2,2h47C51.4,89.3,62,98,74.2,98
                        c14.3,0,25.9-11.6,25.9-25.9C100.1,61.4,93.5,51.9,83.6,48.1z M3.9,6h75.7v15.9H3.9V6z M48.3,72.1c0,0.5,0,1,0,1.5H3.9V25.9h75.7
                        v21c-0.1,0-0.3,0-0.4-0.1c-0.6-0.1-1.2-0.2-1.8-0.3c-0.2,0-0.5-0.1-0.7-0.1c-0.8-0.1-1.6-0.1-2.5-0.1c-7.3,0-14.1,3-18.9,8.2
                        c-0.4-0.5-0.9-0.8-1.6-0.8h-8c-1.1,0-2,0.9-2,2v8c0,1.1,0.9,2,2,2h3.4C48.6,67.8,48.3,69.9,48.3,72.1z M51.7,59.2
                        c-0.1,0.2-0.2,0.4-0.3,0.6c-0.3,0.5-0.5,1-0.7,1.5c-0.1,0.1-0.1,0.2-0.2,0.4h-2.8v-4h4V59.2z M74.2,94C63.3,94,54,86,52.5,75.3
                        c-0.2-1-0.2-2-0.2-3.1c0-2.7,0.5-5.3,1.5-7.8c0.2-0.6,0.5-1.2,0.8-1.8c0.3-0.6,0.6-1.2,0.9-1.7c4-6.6,11-10.5,18.8-10.5
                        c0.6,0,1.2,0,1.9,0.1c1.7,0.1,3.3,0.5,4.9,1c9,2.9,15.1,11.3,15.1,20.8C96.1,84.2,86.3,94,74.2,94z M85.6,71.6h-10V57.7
                        c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v15.9c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2C87.6,72.5,86.7,71.6,85.6,71.6z M71.6,11.9H11.9
                        c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h59.8c1.1,0,2-0.9,2-2C73.6,12.8,72.7,11.9,71.6,11.9z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Prefered Date and Time',
					text: 'Set preferred data and time in advance for car wash services. Helps plan for recurring services proactively.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M25.1,3.2h4.7V2.3h-4.7C25.1,2.3,25.1,3.2,25.1,3.2z M27.4,34.9c-0.6,0-1.2,0.5-1.2,1.2
                        c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2C28.5,35.4,28,34.9,27.4,34.9z M40.2,29.7L39.4,29l1.1-1.3c0.8-1,0.7-2.5-0.3-3.3
                        l-1-0.8c0,0,0,0-0.1-0.1l1.3-1.6c0.8-1,0.7-2.5-0.3-3.3l-1-0.8c-0.3-0.2-0.7-0.4-1.1-0.5v-15C38,1,37,0,35.8,0H19
                        c-1.3,0-2.2,1-2.2,2.3v10.6c-0.4-0.4-0.9-0.8-1.6-1c-1.2-0.5-2.7-0.1-3.7,0.8c-0.8,0.8-1,1.9-0.6,3c0.2,0.6,0.5,1.3,0.6,2.1
                        c0.8,3.2,0.9,6.8,0.4,9.4L11.8,28c-0.7,3.8-1.3,7.3-0.2,10c1,2.3,2.2,3.5,3.4,4.6l0.1,0.1c0.2,0.2,0.3,0.5,0.2,0.8l-1,2.7
                        l14.2,3.4l0.8-2c0.1-0.2,0.3-0.4,0.5-0.4c0.7-0.1,1.9-0.5,3.1-1.7c1.5-1.6,2.9-4.4,3.4-5.6c0.2,0.1,0.4,0.1,0.6,0.1
                        c0.7,0,1.3-0.3,1.8-0.8l1-1.2c0.4-0.5,0.6-1.1,0.5-1.7c0-0.6-0.3-1.2-0.8-1.6l-0.1-0.1l1.1-1.3C41.3,32.1,41.2,30.6,40.2,29.7z
                        M39.2,25.5c0.4,0.3,0.5,0.9,0.1,1.3L38,28.3L37.4,29c-0.1,0.1-0.2,0.1-0.3,0.2H37h-0.1l-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.1l-1-0.8
                        c-0.2-0.1-0.3-0.4-0.3-0.6s0.1-0.4,0.2-0.6l1.7-2c0.1,0,0.2,0,0.3,0h0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2
                        c0,0,0.1,0,0.2,0.1L39.2,25.5z M37.4,18.6L37.4,18.6c0.3,0,0.5,0.1,0.7,0.2l1.1,0.8c0.4,0.3,0.4,0.9,0.1,1.3l-1.9,2.2v0.1
                        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0.1l-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.2l-1-0.8c-0.2-0.1-0.3-0.4-0.3-0.6s0-0.4,0.2-0.6L37,19
                        C37,18.7,37.2,18.6,37.4,18.6z M18.1,2.3c0-0.4,0.4-0.8,0.9-0.8h16.8c0.5,0,0.8,0.4,0.8,0.8V4H18.1V2.3z M18.1,5.5h18.5v11.8
                        c-0.3,0.1-0.6,0.3-0.8,0.6l-1.3,1.6c-0.1-0.9-0.8-1.5-1.7-1.5h-0.3l-1.1-1.5c-0.4-0.5-1-0.8-1.6-0.8H27c-0.5,0-1,0.2-1.4,0.6
                        L23.9,18h-2c-0.9,0-1.7,0.8-1.7,1.7v1.5h1.6c0-0.1,0-0.1,0-0.1c0-0.9,0.7-1.6,1.6-1.6S25,20.1,25,21v0.1h4.8c0-0.1,0-0.1,0-0.1
                        c0-0.9,0.7-1.6,1.6-1.6S33,20.1,33,21v0.1h0.5c0,0.2,0,0.4,0,0.7c0,0.6,0.3,1.2,0.8,1.7l1,0.8l0,0L34.1,26
                        c-0.4,0.5-0.6,1.1-0.5,1.7c0,0,0,0,0,0.1c0,0.6,0.3,1.2,0.8,1.6l0.8,0.7l-1.1,1.2c-0.4,0.4-0.5,1-0.5,1.6H18.1V5.5z M31.8,44.5
                        c-0.9,0.9-1.7,1.2-2.2,1.3c-0.7,0.1-1.4,0.6-1.7,1.3L27.6,48l-11.5-2.8l0.4-1.2c0.3-0.8,0.1-1.8-0.5-2.4v-0.1c-1.1-1-2.1-2-3-4.1
                        c-1-2.3-0.3-5.7,0.3-9.2l0.1-0.8c0.5-2.9,0.3-6.7-0.4-10c-0.2-0.8-0.4-1.5-0.7-2.2c-0.2-0.6-0.1-1.1,0.3-1.5
                        c0.5-0.5,1.5-0.8,2.1-0.5c1.9,0.8,2.1,2.7,2.1,2.9v21.3c0,1.3,1,2.3,2.2,2.3h15.8C34.2,40.9,33,43.2,31.8,44.5z M34.8,35.8
                        c-0.4,0.5-0.6,1.1-0.5,1.7c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.1,0.6H19c-0.5,0-0.8-0.4-0.8-0.8v-3h15.7c0.1,0.1,0.2,0.3,0.4,0.4
                        l0.8,0.7L34.8,35.8z M38.6,36.9l-1,1.2c-0.2,0.2-0.5,0.3-0.8,0.3l-0.4-0.2c0-0.1-0.1-0.1-0.1-0.1L36,38c-0.2-0.1-0.3-0.4-0.3-0.6
                        c0-0.2,0.1-0.4,0.2-0.6l0.5-0.6h0.3l0,0h0.2c0.1,0,0.2-0.1,0.4-0.1h0.1c0.1,0,0.1-0.1,0.2-0.1h0.1l0.1-0.1c0.1,0,0.2-0.1,0.3-0.2
                        c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1l0.1,0.1c0.2,0.1,0.3,0.4,0.3,0.6C38.8,36.5,38.8,36.8,38.6,36.9z M39.3,32.2L38,33.7l-0.6,0.7
                        c0,0.1-0.1,0.1-0.3,0.2v0.1c0,0,0,0-0.1,0h-0.1l-0.4-0.2c-0.1-0.1-0.2-0.1-0.2-0.1l-1-0.8C35.1,33.5,35,33.2,35,33
                        c0-0.2,0-0.4,0.2-0.6l1.3-1.6h0.3l0,0h0.1H37c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0h0.1h0.1h0.1c0.1,0,0.2-0.1,0.3-0.2
                        c0,0,0,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1L39,31C39.6,31.2,39.7,31.8,39.3,32.2z M31.4,19.8c-0.7,0-1.2,0.6-1.2,1.2
                        c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2C32.6,20.3,32.1,19.8,31.4,19.8z M23.4,22.2c0.7,0,1.2-0.6,1.2-1.2
                        c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.6-1.2,1.2C22.2,21.7,22.7,22.2,23.4,22.2z M23,29c0.2,0,0.3,0,0.5,0.1l0.1-0.5
                        c-0.1-0.1-0.3-0.1-0.6-0.1c-0.7,0-1.3,0.5-1.3,1.4c0,0.7,0.4,1.3,1.3,1.3c0.3,0,0.5-0.1,0.6-0.1l-0.1-0.5c-0.1,0-0.3,0.1-0.5,0.1
                        c-0.5,0-0.8-0.3-0.8-0.8C22.3,29.3,22.6,29,23,29z M25.3,30.1c0-0.4-0.2-0.8-0.8-0.8c-0.3,0-0.6,0.1-0.7,0.2l0.1,0.4
                        c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.3,0.1,0.3,0.2l0,0c-0.6,0-1,0.2-1,0.7c0,0.3,0.2,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.2l0,0v0.2h0.5
                        c0-0.1,0-0.3,0-0.5V30.1z M24.8,30.5C24.8,30.6,24.8,30.6,24.8,30.5c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2
                        c0-0.2,0.2-0.3,0.5-0.3V30.5z M26.8,29.3c-0.3,0-0.5,0.2-0.5,0.3l0,0v-0.3h-0.5c0,0.2,0,0.4,0,0.6v1.3h0.5v-1.1c0-0.1,0-0.1,0-0.1
                        c0-0.1,0.1-0.2,0.3-0.2c0.2,0,0.3,0.2,0.3,0.4v1h0.5v-1.1C27.4,29.5,27.1,29.3,26.8,29.3z M28.8,29.7c0.1,0,0.2,0,0.3,0.1l0.1-0.4
                        c-0.1,0-0.3-0.1-0.4-0.1c-0.6,0-1,0.4-1,1s0.4,1,0.9,1c0.2,0,0.4,0,0.5-0.1l-0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1
                        c-0.3,0-0.5-0.2-0.5-0.5C28.3,29.9,28.5,29.7,28.8,29.7z M30.2,29.3c-0.6,0-0.9,0.5-0.9,1c0,0.6,0.3,1,0.9,1c0.2,0,0.5,0,0.6-0.1
                        l-0.1-0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.5-0.4h1.1c0,0,0-0.1,0-0.2C31,29.7,30.7,29.3,30.2,29.3z M29.8,30
                        c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.2,0.3,0.4H29.8z M31.3,31.2h0.5v-2.7h-0.5V31.2z M27,12.9l1.1-1.8l1,1.8h1.7L29.1,10l1.6-2.7
                        h-1.6l-1,1.7l-1-1.7h-1.7l1.7,2.7l-1.8,2.8L27,12.9L27,12.9z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Cancel Request',
					text: 'Users can cancel already placed car wash requests. The requests can be postponed to a later date or right away canceled.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M20.6,46.2h-3.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7C21.3,46.5,21,46.2,20.6,46.2z"></path>
                    <path d="M18.2,3.8h1.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7S17.8,3.8,18.2,3.8z"></path>
                    <path d="M41.7,11h-9.4V2.3C32.3,1,31.2,0,30,0H8.1C6.8,0,5.8,1,5.8,2.3v45.4c0,1.3,1,2.3,2.3,2.3H30c1.3,0,2.3-1,2.3-2.3V34.3h9.4
                    c1.3,0,2.3-1,2.3-2.3V13.3C44,12,43,11,41.7,11z M8.1,1.5H30c0.5,0,0.8,0.4,0.8,0.8v2.4H7.2V2.3C7.2,1.8,7.6,1.5,8.1,1.5z M30,48.5
                    H8.1c-0.5,0-0.8-0.4-0.8-0.8v-2.4h23.6v2.4C30.8,48.2,30.4,48.5,30,48.5z M42.5,32c0,0.5-0.4,0.8-0.8,0.8H22.9
                    c-0.2,0-0.3,0.1-0.4,0.1l-5.1,3.8V19.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v19.2c0,0.6,0.7,0.9,1.2,0.6l6.1-4.5h7.6v9.5
                    H7.2V6.2h23.6V11H18.2c-1.3,0-2.3,1-2.3,2.3v2.3c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-2.3c0-0.5,0.4-0.8,0.8-0.8h23.5
                    c0.5,0,0.8,0.4,0.8,0.8V32H42.5z"></path>
                    <path d="M36.2,27.4h-0.7c-0.1,0-0.9-0.4-0.9-3.2c0-5.4-2.9-6.2-4.6-6.2s-4.6,0.8-4.6,6.2c0,2.7-0.8,3.1-0.9,3.2h-0.7
                    c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4l0,0c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3l0,0h4c0.4,0,0.7-0.3,0.7-0.7S36.6,27.4,36.2,27.4
                    z M30.8,28.9c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8l0,0H30.8L30.8,28.9z M26.3,27.4c0.3-0.7,0.5-1.7,0.5-3.2
                    c0-4.7,2.4-4.7,3.2-4.7s3.2,0,3.2,4.7c0,1.5,0.2,2.5,0.5,3.2C33.7,27.4,26.3,27.4,26.3,27.4z"></path>
                    <path d="M34.7,18c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.9,0,1.6,0.7,1.6,1.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                    C37.7,19.4,36.4,18,34.7,18z"></path>
                    <path d="M34.7,14.9c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                    C40.9,17.7,38.1,14.9,34.7,14.9z"></path>
                    <path d="M23.7,21.1c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7-0.3,0.7-0.7S25.7,18,25.3,18c-1.7,0-3.1,1.4-3.1,3.1c0,0.4,0.3,0.7,0.7,0.7
                    C23.3,21.8,23.7,21.5,23.7,21.1z"></path>
                    <path d="M26,15.6c0-0.4-0.3-0.7-0.7-0.7c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-2.6,2.1-4.7,4.7-4.7
                    C25.7,16.3,26,16,26,15.6z"></path>
                  </svg>`,
					title: 'Notification',
					text: 'Every booking made, accepted or rejected will be intimated to the user through push notifications.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M43.2,0c-0.2,0-0.4,0.1-0.6,0.2l-3.3,2.5l-3.3-2.5c-0.4-0.3-0.8-0.3-1.2,0l-3.3,2.5l-3.3-2.5c-0.4-0.3-0.8-0.3-1.2,0
                    l-3.3,2.5l-3.3-2.5c-0.4-0.3-0.8-0.3-1.2,0l-3.3,2.5l-3.3-2.5C12,0,11.6,0,11.2,0.3L7.9,2.8L4.6,0.3C4.3,0.1,3.9,0,3.6,0.2
                    C3.2,0.3,3,0.6,3,1v46.1c0,0.3,0.1,0.5,0.3,0.7l2,2C5.5,49.9,5.6,50,5.9,50c0.2,0,0.4-0.1,0.6-0.2l3.3-2.5l3.3,2.5
                    c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5
                    c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5c0.3,0.2,0.7,0.3,1,0.1c0.3-0.2,0.6-0.5,0.6-0.9V3c0-0.3-0.1-0.5-0.3-0.7l-2-2
                    C43.7,0.1,43.5,0,43.2,0z M11.8,2.2l3.3,2.5c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5L23,4.6c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5
                    c0.4,0.3,0.8,0.3,1.2,0l3.3-2.5l3.3,2.5c0.4,0.3,0.8,0.3,1.2,0l3.2-2.5l1.1,1.2v33.9c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1v5.9
                    l-2.4-1.8c-0.4-0.3-0.8-0.3-1.2,0l-3.3,2.5L34,45.4c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.4,0.1-0.6,0.2l-3.3,2.5l-3.3-2.5
                    c-0.4-0.3-0.8-0.3-1.2,0l-3.3,2.5l-3.3-2.5c-0.4-0.3-0.8-0.3-1.2,0l-3.3,2.5l-3.3-2.5c-0.4-0.3-0.8-0.3-1.2,0L6,47.8L5,46.7v-5.5
                    c0.6,0,1-0.4,1-1v-2c0-0.6-0.4-1-1-1V3l2.4,1.8C7.7,5,8.1,5,8.5,4.7L11.8,2.2z M11.8,9.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h15.7
                    c0.6,0,1-0.4,1-1s-0.4-1-1-1H11.8z M31.4,9.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.9c0.6,0,1-0.4,1-1s-0.4-1-1-1H31.4z M11.8,13.7
                    c-0.6,0-1,0.4-1,1s0.4,1,1,1h10.8c0.6,0,1-0.4,1-1s-0.4-1-1-1H11.8z M34.4,13.7c-0.6,0-1,0.4-1,1v1c-1.7,0-2.9,1.3-2.9,2.9v2.9
                    c0,1.7,1.3,2.9,2.9,2.9h2c0.6,0,1,0.4,1,1v2.9c0,0.6-0.4,1-1,1h-2c-0.6,0-1-0.4-1-1v-1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1
                    c0,1.7,1.3,2.9,2.9,2.9v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1c1.7,0,2.9-1.3,2.9-2.9v-2.9c0-1.7-1.3-2.9-2.9-2.9h-2c-0.6,0-1-0.4-1-1v-2.9
                    c0-0.6,0.4-1,1-1h2c0.6,0,1,0.4,1,1v1c0,0.6,0.4,1,1,1s1-0.4,1-1v-1c0-1.7-1.3-2.9-2.9-2.9v-1C35.3,14.1,35,13.7,34.4,13.7z
                    M11.8,21.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h9.8c0.6,0,1-0.4,1-1s-0.4-1-1-1H11.8z M11.8,25.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h12.7
                    c0.6,0,1-0.4,1-1s-0.4-1-1-1H11.8z M11.8,29.4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.9c0.6,0,1-0.4,1-1s-0.4-1-1-1H11.8z M19.7,29.4
                    c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1-0.4,1-1s-0.4-1-1-1H19.7z M9.9,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-2
                    C10.8,37.7,10.4,37.3,9.9,37.3z M14.8,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C15.7,37.7,15.3,37.3,14.8,37.3z
                    M19.7,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C20.6,37.7,20.3,37.3,19.7,37.3z M24.6,37.3c-0.6,0-1,0.4-1,1v2
                    c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-2C25.5,37.7,25.2,37.3,24.6,37.3z M29.5,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2
                    C30.4,37.7,30.1,37.3,29.5,37.3z M34.4,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C35.3,37.7,35,37.3,34.4,37.3z
                    M39.3,37.3c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C40.2,37.7,39.9,37.3,39.3,37.3z"></path>
                  </svg>`,
					title: 'Payment Summary',
					text: 'Users can get immediate payment summary for the services received.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M46,36.9c-0.5,0-0.9,0.4-0.9,0.9v3.4c0,1.5-1.2,2.8-2.8,2.8H4.8c-1.5,0-2.8-1.2-2.8-2.8V11.7C2,10.2,3.3,9,4.8,9h9
                        c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-9c-2.5,0-4.6,2.1-4.6,4.6v29.4c0,2.5,2.1,4.6,4.6,4.6h37.5c2.5,0,4.6-2.1,4.6-4.6v-3.4
                        C46.9,37.3,46.5,36.9,46,36.9z"></path>
                        <path d="M47.1,21.5h-0.1v-9.8c0-2.5-2.1-4.6-4.6-4.6c-0.5,0-0.9,0.4-0.9,0.9S41.8,9,42.3,9c1.5,0,2.8,1.2,2.8,2.8v0.5h-1l-5.5-5.5
                        l-4-4c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2,0-0.5,0.1-0.7,0.3l-4.9,4.9l-4.9-4.9c-0.4-0.4-0.9-0.4-1.3,0l-9.5,9.5H9.4
                        c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h35.7v7.4h-5.4c-3.6,0-6.4,2.9-6.4,6.4v0.7c0,3.6,2.9,6.4,6.4,6.4h7.4
                        c1.5,0,2.8-1.2,2.8-2.8v-8C49.8,22.7,48.6,21.5,47.1,21.5z M33.9,4.7l2.7,2.7c-1.6,1.1-3.7,1.1-5.3,0L33.9,4.7z M29.9,8.6
                        c2.3,1.8,5.6,1.8,7.9,0l3.7,3.7H33l-3.4-3.4L29.9,8.6z M19.2,12.3l5.5-5.5L26,8.1l-4.2,4.2L19.2,12.3z M27.4,9.3l3.1,2.9h-6.1
                        L27.4,9.3z M22.8,4.7l0.7,0.7l-6.9,6.9h-1.4L22.8,4.7z M48,32.3c0,0.5-0.4,0.9-0.9,0.9h-7.4c-1.2,0-2.4-0.5-3.3-1.3
                        c-0.9-0.9-1.3-2-1.3-3.3v-0.7c0-2.5,2.1-4.6,4.6-4.6h7.4c0.5,0,0.9,0.4,0.9,0.9V32.3z"></path>
                        <circle cx="39.9" cy="28.3" r="1.8"></circle>
                      </g>
                    </g>
                  </svg>`,
					title: 'Wallet',
					text: 'Payment for services offered can be made from within the mobile app with integrated digital wallets.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M26,45.2h9.6v1.6H26V45.2z"></path>
                      <path d="M19.6,14.5h1.6v24.2h-1.6V14.5z"></path>
                      <path d="M48.4,16.1h-3.2V4c0-2.2-1.8-4-4-4H20.4c-2.2,0-4,1.8-4,4v4H3.5v8.9H0.3v24.2h16V46c0,2.2,1.8,4,4,4h20.8c2.2,0,4-1.8,4-4
                      V33.9h3.2c0.9,0,1.6-0.7,1.6-1.6V17.7C50,16.9,49.3,16.1,48.4,16.1z M48.4,19.4H26v-1.6h22.4V19.4z M26,21h22.4v1.6H26V21z
                      M20.4,1.6h20.8c1.3,0,2.4,1.1,2.4,2.4v2.4H18V4C18,2.7,19,1.6,20.4,1.6z M16.4,25.1C16.1,25,15.9,25,15.6,25v-1.6H14V25
                      c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4h1.6c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8H14c-0.4,0-0.8-0.4-0.8-0.8h-1.6
                      c0,1.3,1.1,2.4,2.4,2.4v1.6h1.6V33c0.3,0,0.5-0.1,0.8-0.1v3.2c-0.5,0.1-1.1,0.2-1.6,0.2c-4,0-7.2-3.3-7.2-7.3s3.2-7.3,7.2-7.3
                      c0.5,0,1.1,0.1,1.6,0.2V25.1z M16.4,28.4c-0.3-0.1-0.5-0.1-0.8-0.1H14c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h1.6
                      c0.4,0,0.8,0.4,0.8,0.8V28.4z M5.1,9.7h11.2V17H5.1V9.7z M1.9,39.5v-21h14.4v1.8c-0.5-0.1-1.1-0.2-1.6-0.2c-4.9,0-8.8,4-8.8,8.9
                      s4,8.9,8.8,8.9c0.5,0,1.1-0.1,1.6-0.2v1.8C16.3,39.5,1.9,39.5,1.9,39.5z M41.2,48.4H20.4C19,48.4,18,47.3,18,46v-2.4h25.6V46
                      C43.6,47.3,42.5,48.4,41.2,48.4z M43.6,41.9H18V8.1h25.6v8.1H26c-0.9,0-1.6,0.7-1.6,1.6v14.5c0,0.9,0.7,1.6,1.6,1.6h17.6V41.9z
                      M26,32.3v-8.1h22.4v8.1H26z"></path>
                      <path d="M27.6,29h4v1.6h-4V29z"></path>
                      <path d="M5.1,21.8h1.6v-1.6H3.5v3.2h1.6V21.8z"></path>
                      <path d="M6.7,11.3v3.2h1.6v-1.6H10v-1.6C10,11.3,6.7,11.3,6.7,11.3z"></path>
                      <path d="M5.1,34.7H3.5v3.2h3.2v-1.6H5.1V34.7z"></path>
                      <path d="M45.2,29h1.6v1.6h-1.6V29z"></path>
                      <path d="M42,29h1.6v1.6H42V29z"></path>
                      <path d="M19.6,11.3h1.6v1.6h-1.6V11.3z"></path>
                    </g>
                  </svg>`,
					title: 'Multiple Payment',
					text: 'Notification for every successful cab booking, the arrival of a cab, ride cancellation, ride completion, offer alerts and so on.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M39.5,6.7h-5.4C33,2.7,29.4,0,25.3,0s-7.7,2.7-8.8,6.7h-5.4C8.9,6.7,7,8.5,7,10.8v35c0,2.3,1.9,4.2,4.2,4.2h28.3
                    c2.3,0,4.2-1.9,4.2-4.2v-35C43.7,8.5,41.8,6.7,39.5,6.7z M25.3,18.3c4.1,0,7.7-2.7,8.8-6.7h4.5V45H12V11.7h4.5
                    C17.6,15.6,21.2,18.3,25.3,18.3z M25.3,1.7c4.1,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5C17.8,5,21.2,1.7,25.3,1.7z
                    M42,45.8c0,1.4-1.1,2.5-2.5,2.5H11.2c-1.4,0-2.5-1.1-2.5-2.5v-35c0-1.4,1.1-2.5,2.5-2.5h5c0,0.3,0,0.6,0,0.8c0,0.3,0,0.6,0,0.8H12
                    c-0.9,0-1.7,0.7-1.7,1.7V45c0,0.9,0.7,1.7,1.7,1.7h26.7c0.9,0,1.7-0.7,1.7-1.7V11.7c0-0.9-0.7-1.7-1.7-1.7h-4.2c0-0.3,0-0.6,0-0.8
                    c0-0.3,0-0.6,0-0.8h5c1.4,0,2.5,1.1,2.5,2.5V45.8z"></path>
                    <path d="M15.3,26.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8v-3.3h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7V25C13.7,25.9,14.4,26.7,15.3,26.7z"></path>
                    <path d="M18.4,23.6c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2c0.3-0.3,0.3-0.8,0-1.2
                    c-0.3-0.3-0.8-0.3-1.2,0l-3.6,3.6L18.4,23.6z"></path>
                    <path d="M35.3,22.5h0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-0.8c-0.5,0-0.8,0.4-0.8,0.8S34.9,22.5,35.3,22.5z"></path>
                    <path d="M27.8,22.5H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8S27.4,22.5,27.8,22.5z"></path>
                    <path d="M27.8,25.8h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8C27,25.5,27.4,25.8,27.8,25.8z"></path>
                    <path d="M15.3,35c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8V30h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7v3.3C13.7,34.3,14.4,35,15.3,35z"></path>
                    <path d="M23.1,29.4L19.5,33l-1.1-1.1c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2
                    c0.3-0.3,0.3-0.8,0-1.2S23.4,29.1,23.1,29.4z"></path>
                    <path d="M22.7,37.4L19,41L18,39.9c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2
                    c0.3-0.3,0.3-0.8,0-1.2S23,37.1,22.7,37.4z"></path>
                    <path d="M36.2,29.2h-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h0.8c0.5,0,0.8-0.4,0.8-0.8C37,29.5,36.6,29.2,36.2,29.2z"></path>
                    <path d="M27.8,30.8H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8C27,30.5,27.4,30.8,27.8,30.8z"></path>
                    <path d="M27.8,34.2h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8S27.4,34.2,27.8,34.2z"></path>
                    <path d="M15.3,43.3c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8v-3.3h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7v3.3C13.7,42.6,14.4,43.3,15.3,43.3z"></path>
                    <path d="M36.2,37.5h-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h0.8c0.5,0,0.8-0.4,0.8-0.8S36.6,37.5,36.2,37.5z"></path>
                    <path d="M27.8,39.2H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8S27.4,39.2,27.8,39.2z"></path>
                    <path d="M27.8,42.5h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8S27.4,42.5,27.8,42.5z"></path>
                    <g transform="translate(0,-288.53332)">
                      <path d="M24.1,296.2c-0.9,0-1.7,0.7-1.8,1.7l-0.2,0.2c-0.4,0.3-0.6,0.8-0.6,1.3v1.3c0,0.6,0.5,1.1,1.1,1.1h5.8
                      c0.6,0,1.1-0.5,1.1-1.1v-1.3c0-0.5-0.2-1-0.6-1.3l-0.2-0.2v0.1c0-1-0.8-1.8-1.8-1.8L24.1,296.2z M24.1,296.6h2.7
                      c0.7,0,1.3,0.6,1.3,1.3v0.1l0.4,0.3c0.3,0.2,0.5,0.6,0.5,0.9v1.3c0,0.4-0.3,0.7-0.7,0.7h-5.8c-0.4,0-0.7-0.3-0.7-0.7v-1.3
                      c0-0.4,0.2-0.7,0.5-0.9l0.4-0.3V298C22.8,297.2,23.4,296.6,24.1,296.6z"></path>
                      <path d="M22.4,297.9l-0.1,0.4l0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1h4.4c0.2,0,0.3,0,0.5-0.1l0.5-0.2l-0.1-0.4L28,298
                      c-0.1,0-0.2,0.1-0.3,0.1h-4.4c-0.1,0-0.2,0-0.3-0.1L22.4,297.9z"></path>
                      <path d="M21.4,300c0,0.6,0.5,1.1,1.1,1.1h5.8c0.6,0,1.1-0.5,1.1-1.1H29c0,0.4-0.3,0.7-0.7,0.7h-5.8c-0.4,0-0.7-0.3-0.7-0.7H21.4z"></path>
                      <path d="M22.3,301.5v0.9c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4v-0.9h-0.4v0.9h-0.4v-0.9H22.3z"></path>
                      <path d="M27.2,301.5v0.9c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4v-0.9h-0.4v0.9h-0.4v-0.9H27.2z"></path>
                      <path d="M23,299c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7C23.7,299.3,23.4,299,23,299z M23,299.4
                      c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2S22.9,299.4,23,299.4z"></path>
                      <path d="M27.9,299c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7C28.5,299.3,28.2,299,27.9,299z M27.9,299.4
                      c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2S27.7,299.4,27.9,299.4z"></path>
                      <path d="M24.3,299c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l0.2,0.9c0,0.1,0.1,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.2l0.2-0.9
                      c0-0.1,0-0.2-0.2-0.3c0,0,0,0-0.1,0H24.3z M24.6,299.4h1.6l-0.1,0.4h-1.4L24.6,299.4z"></path>
                      <path d="M21.9,297.3c-0.4,0-0.7,0.3-0.7,0.7v0.2c0,0.2,0.2,0.4,0.4,0.4h0.4v-0.4h-0.4V298c0-0.1,0.1-0.2,0.2-0.2h0.7v-0.4H21.9z"></path>
                      <path d="M28.3,297.3v0.4H29c0.1,0,0.2,0.1,0.2,0.2v0.2h-0.4v0.4h0.4c0.2,0,0.4-0.2,0.4-0.4V298c0-0.4-0.3-0.7-0.7-0.7H28.3z"></path>
                      <path d="M25.4,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C25.6,292.3,25.5,292.2,25.4,292.2z M25.4,292.9C25.4,292.9,25.4,292.9,25.4,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C25.3,293.1,25.3,293,25.4,292.9
                      C25.4,292.9,25.4,292.9,25.4,292.9L25.4,292.9z"></path>
                      <path d="M23.2,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3s-0.1,0.2-0.1,0.3c0,0.4,0.3,0.7,0.7,0.7
                      c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4C23.3,292.3,23.3,292.2,23.2,292.2z
                      M23.2,292.9C23.2,292.9,23.2,292.9,23.2,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2
                      c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C23.1,293.1,23.1,293,23.2,292.9C23.2,292.9,23.2,292.9,23.2,292.9L23.2,292.9z"></path>
                      <path d="M27.6,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C27.8,292.3,27.7,292.2,27.6,292.2z M27.6,292.9C27.6,292.9,27.6,292.9,27.6,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C27.5,293.1,27.6,293,27.6,292.9
                      C27.6,292.9,27.6,292.9,27.6,292.9L27.6,292.9z"></path>
                      <path d="M24.3,293.8c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C24.5,293.8,24.4,293.8,24.3,293.8z M24.3,294.4C24.3,294.4,24.3,294.4,24.3,294.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C24.2,294.6,24.2,294.5,24.3,294.4C24.3,294.4,24.3,294.4,24.3,294.4
                      L24.3,294.4z"></path>
                      <path d="M26.5,293.8c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C26.7,293.8,26.6,293.8,26.5,293.8z M26.5,294.4C26.5,294.4,26.5,294.4,26.5,294.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C26.4,294.6,26.4,294.5,26.5,294.4
                      C26.5,294.4,26.5,294.4,26.5,294.4L26.5,294.4z"></path>
                    </g>
                  </svg>`,
					title: 'My Washes',
					text: 'Users can view the timeline of all car washes that they have carried out since installing the app.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M39.9,0h-30C8.4,0,7.4,1.1,7.4,2.5v45c0,1.4,1.1,2.5,2.5,2.5h22.8l9.7-9.7V2.5C42.4,1.1,41.3,0,39.9,0z M40.7,39.7l-7.5,7.5
                    v-5.5c0-0.5,0.3-0.8,0.8-0.8h4.2v-1.7H34c-1.4,0-2.5,1.1-2.5,2.5v6.7H9.9C9.4,48.3,9,48,9,47.5v-45C9,2,9.4,1.7,9.9,1.7h30
                    c0.5,0,0.8,0.3,0.8,0.8V39.7z"></path>
                    <rect x="20.7" y="5" width="8.3" height="1.7"></rect>
                    <path d="M19.9,9.2h-7.5v5h7.5V9.2z M18.2,12.5H14v-1.7h4.2V12.5z"></path>
                    <rect x="21.5" y="10.8" width="7.5" height="1.7"></rect>
                    <path d="M19.9,15.8h-7.5v5h7.5V15.8z M18.2,19.2H14v-1.7h4.2V19.2z"></path>
                    <rect x="21.5" y="17.5" width="7.5" height="1.7"></rect>
                    <path d="M19.9,22.5h-7.5v5h7.5V22.5z M18.2,25.8H14v-1.7h4.2V25.8z"></path>
                    <rect x="21.5" y="24.2" width="7.5" height="1.7"></rect>
                    <path d="M19.9,29.2h-7.5v5h7.5V29.2z M18.2,32.5H14v-1.7h4.2V32.5z"></path>
                    <rect x="21.5" y="30.8" width="7.5" height="1.7"></rect>
                    <rect x="12.4" y="35.8" width="25" height="1.7"></rect>
                    <rect x="12.4" y="39.2" width="5.8" height="1.7"></rect>
                    <rect x="20.7" y="39.2" width="9.2" height="1.7"></rect>
                    <rect x="30.7" y="10.8" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="10.8" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="17.5" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="17.5" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="24.2" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="24.2" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="30.8" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="30.8" width="2.5" height="1.7"></rect>
                  </svg>`,
					title: 'Wash Status',
					text: 'Get a real-time update of the car’s wash status - done, in progress, in queue, etc. through the app.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M39.8,46.6h-34c-0.5,0-0.8-0.4-0.8-0.8V1c0-0.5,0.4-0.8,0.8-0.8h25.7c0.2,0,0.4,0.1,0.6,0.2l8.3,7.5
                      c0.2,0.2,0.3,0.4,0.3,0.6v37.3C40.7,46.2,40.3,46.6,39.8,46.6z M6.7,44.9H39V8.8l-7.8-7H6.7V44.9z"></path>
                      <path d="M39.8,9.2h-8.3c-0.5,0-0.8-0.4-0.8-0.8V1c0-0.3,0.2-0.6,0.5-0.8c0.3-0.1,0.6-0.1,0.9,0.1l8.3,7.5c0.3,0.2,0.3,0.6,0.2,0.9
                      C40.5,9,40.2,9.2,39.8,9.2z M32.4,7.6h5.3l-5.3-4.8V7.6z"></path>
                      <path d="M43.2,49.9h-34c-0.5,0-0.8-0.4-0.8-0.8v-3.3c0-0.5,0.4-0.8,0.8-0.8H39V8.8l-4.3-3.9c-0.3-0.2-0.3-0.6-0.2-0.9
                      s0.4-0.5,0.8-0.5h7.9c0.5,0,0.8,0.4,0.8,0.8V49C44,49.5,43.6,49.9,43.2,49.9z M10,48.2h32.3V5.1h-4.9l3,2.7
                      c0.2,0.2,0.3,0.4,0.3,0.6v37.3c0,0.5-0.4,0.8-0.8,0.8H10V48.2z"></path>
                      <path d="M23.9,30h-1.3c-0.4,0-0.8-0.3-0.8-0.7l0-0.3c-0.1-0.7-0.6-1.4-1.2-1.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-0.3-1.5-0.2-2.1,0.3
                      l-0.2,0.2c-0.3,0.3-0.8,0.2-1.1-0.1l-0.9-0.9c-0.3-0.3-0.3-0.8-0.1-1.1l0.2-0.2c0.5-0.6,0.6-1.4,0.3-2.1c-0.1-0.1-0.1-0.3-0.2-0.4
                      c-0.2-0.7-0.9-1.1-1.6-1.2l-0.3,0c-0.4-0.1-0.7-0.4-0.7-0.8v-1.3c0-0.4,0.3-0.8,0.7-0.8l0.3,0c0.7-0.1,1.4-0.6,1.6-1.2
                      c0.1-0.1,0.1-0.3,0.2-0.4c0.3-0.7,0.2-1.5-0.3-2.1l-0.2-0.2c-0.3-0.3-0.2-0.8,0.1-1.1l0.9-0.9c0.3-0.3,0.8-0.3,1.1-0.1l0.2,0.2
                      c0.6,0.5,1.4,0.6,2.1,0.3c0.1-0.1,0.3-0.1,0.4-0.2c0.7-0.2,1.1-0.9,1.2-1.6l0-0.3c0.1-0.4,0.4-0.7,0.8-0.7h1.3
                      c0.4,0,0.8,0.3,0.8,0.7l0,0.3c0.1,0.7,0.6,1.4,1.2,1.6c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.3,1.5,0.2,2.1-0.3l0.2-0.2
                      c0.3-0.3,0.8-0.2,1.1,0.1l0.9,0.9c0.3,0.3,0.3,0.8,0.1,1.1l-0.2,0.2c-0.5,0.6-0.6,1.4-0.3,2.1c0.1,0.1,0.1,0.3,0.2,0.4
                      c0.2,0.7,0.9,1.1,1.6,1.2l0.3,0c0.4,0.1,0.7,0.4,0.7,0.8v1.3c0,0.4-0.3,0.8-0.7,0.8l-0.3,0c-0.7,0.1-1.4,0.6-1.6,1.2
                      c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.7-0.2,1.5,0.3,2.1l0.2,0.2c0.3,0.3,0.2,0.8-0.1,1.1l-0.9,0.9c-0.3,0.3-0.8,0.3-1.1,0.1l-0.2-0.2
                      c-0.6-0.5-1.4-0.6-2.1-0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.7,0.2-1.1,0.9-1.2,1.6l0,0.3C24.7,29.7,24.3,30,23.9,30z M19.3,25.3
                      c0.5,0,1,0.1,1.5,0.3c0.1,0,0.2,0.1,0.3,0.1c1,0.4,1.8,1.2,2.1,2.3c0.3-1.1,1.1-1.9,2.1-2.3c0.1,0,0.2-0.1,0.3-0.1
                      c1-0.5,2.2-0.4,3.2,0.1c-0.5-1-0.6-2.2-0.1-3.2c0-0.1,0.1-0.2,0.1-0.3c0.4-1,1.2-1.8,2.3-2.1c-1.1-0.3-1.9-1.1-2.3-2.1
                      c0-0.1-0.1-0.2-0.1-0.3c-0.5-1-0.4-2.2,0.1-3.2c-1,0.5-2.2,0.6-3.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c-1-0.4-1.8-1.2-2.1-2.3
                      c-0.3,1.1-1.1,1.9-2.1,2.3c-0.1,0-0.2,0.1-0.3,0.1c-1,0.5-2.2,0.4-3.2-0.1c0.5,1,0.6,2.2,0.1,3.2c0,0.1-0.1,0.2-0.1,0.3
                      c-0.4,1-1.2,1.8-2.3,2.1c1.1,0.3,1.9,1.1,2.3,2.1c0,0.1,0.1,0.2,0.1,0.3c0.5,1,0.4,2.2-0.1,3.2C18.1,25.4,18.7,25.3,19.3,25.3z"></path>
                      <path d="M23.3,23.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3C26.6,21.9,25.1,23.3,23.3,23.3z
                      M23.3,18.4c-0.9,0-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7S24.2,18.4,23.3,18.4z"></path>
                      <path d="M23.3,34.1H10c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h13.2c0.5,0,0.8,0.4,0.8,0.8S23.7,34.1,23.3,34.1z"></path>
                      <path d="M35.7,34.1h-4.1c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h4.1c0.5,0,0.8,0.4,0.8,0.8S36.1,34.1,35.7,34.1z"></path>
                      <path d="M28.2,34.1h-1.7c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h1.7c0.5,0,0.8,0.4,0.8,0.8S28.7,34.1,28.2,34.1z"></path>
                      <path d="M23.3,42.4H10c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h13.2c0.5,0,0.8,0.4,0.8,0.8C24.1,42,23.7,42.4,23.3,42.4z"></path>
                      <path d="M35.7,42.4h-4.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h4.1c0.5,0,0.8,0.4,0.8,0.8C36.5,42,36.1,42.4,35.7,42.4z"></path>
                      <path d="M28.2,42.4h-1.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h1.7c0.5,0,0.8,0.4,0.8,0.8C29.1,42,28.7,42.4,28.2,42.4z"></path>
                      <path d="M35.7,38.3H22.5c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h13.2c0.5,0,0.8,0.4,0.8,0.8C36.5,37.9,36.1,38.3,35.7,38.3z
                      "></path>
                      <path d="M14.2,38.3H10c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h4.1c0.5,0,0.8,0.4,0.8,0.8C15,37.9,14.6,38.3,14.2,38.3z"></path>
                      <path d="M19.1,38.3h-1.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h1.7c0.5,0,0.8,0.4,0.8,0.8C20,37.9,19.6,38.3,19.1,38.3z"></path>
                    </g>
                  </svg>`,
					title: 'Special instruction',
					text: 'Users can instruct the washer to wash the car using specific washing materials, in specific routines and so on for the desired outcome.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M41.6,24c-0.1-0.3-0.4-0.5-0.7-0.6l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6
                      c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1l-0.7,4.4c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1
                      c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8c0,0,0-0.1,0-0.1L38.4,28l3-3.1C41.6,24.6,41.7,24.3,41.6,24z M36.9,27.1
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L36.9,27.1z"></path>
                      <path d="M32.5,40.7c-0.1-0.3-0.4-0.5-0.7-0.6l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6
                      c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1L20.1,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1
                      C28.8,50,29,50,29.1,50c0.5,0,0.8-0.4,0.8-0.8c0,0,0-0.1,0-0.1l-0.7-4.4l3-3.1C32.5,41.3,32.6,41,32.5,40.7z M27.7,43.8
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0L22,47.6l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L27.7,43.8z"></path>
                      <path d="M2.6,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8
                      c0,0,0-0.1,0-0.1l-0.7-4.4l3-3.1c0.3-0.3,0.3-0.9,0-1.2c-0.1-0.1-0.3-0.2-0.5-0.2l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4
                      c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1L2.6,49z M2.6,41.5l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8C8.9,40.8,9.2,41,9.4,41l3,0.5l-2.2,2.3c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4
                      c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4L5,44.5c0-0.3,0-0.5-0.2-0.7L2.6,41.5z"></path>
                      <path d="M49.3,40.1l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6c-0.5,0.1-0.8,0.5-0.7,0.9
                      c0,0.2,0.1,0.3,0.2,0.5l3,3.1L37.6,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1
                      c0.5,0,0.8-0.4,0.8-0.8c0,0,0-0.1,0-0.1l-0.7-4.4l3-3.1c0.3-0.3,0.3-0.9,0-1.2C49.6,40.2,49.5,40.1,49.3,40.1z M45.2,43.8
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L45.2,43.8z"></path>
                      <path d="M15.8,18.3c-0.3,0-0.6,0.2-0.8,0.5l-1.9,4L9,23.4c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1l-0.7,4.4
                      c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8c0,0,0-0.1,0-0.1
                      L20.1,28l3-3.1c0.3-0.3,0.3-0.9,0-1.2c-0.1-0.1-0.3-0.2-0.5-0.2l-4.2-0.6l-1.9-4C16.5,18.5,16.2,18.3,15.8,18.3z M17.8,24.4l3,0.5
                      l-2.2,2.3c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8C17.3,24.2,17.5,24.3,17.8,24.4z"></path>
                      <path d="M33.3,4.2h-2.5C30.8,1.9,29,0,26.7,0c-0.5,0-0.8,0.4-0.8,0.8v3.3h-7.5c-0.5,0-0.8,0.4-0.8,0.8v10.8c0,0.5,0.4,0.8,0.8,0.8
                      h13.3c0.3,0,0.5-0.1,0.7-0.4l1.7-2.5c0.1-0.1,0.1-0.3,0.1-0.5V5C34.2,4.5,33.8,4.2,33.3,4.2z M19.2,5.8h5V15h-5V5.8z M32.5,13.1
                      L31.2,15h-5.4V5.8h0.8c0.5,0,0.8-0.4,0.8-0.8V1.8c1,0.4,1.7,1.3,1.7,2.4V5c0,0.5,0.4,0.8,0.8,0.8h2.5V13.1z"></path>
                      <path d="M21.7,10.8c-0.5,0-0.8,0.4-0.8,0.8v1.7c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-1.7C22.5,11.2,22.1,10.8,21.7,10.8z"></path>
                    </g>
                  </svg>`,
					title: 'Ratings and reviews',
					text: 'All services provided by the washer can be rated and reviewed with the built-in rating and review system.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M43.9,7.7l-7.5-7.5C36.3,0.1,36.1,0,35.8,0H10C7.7,0,5.8,1.9,5.8,4.2v41.7c0,2.3,1.9,4.2,4.2,4.2h30
                        c2.3,0,4.2-1.9,4.2-4.2V8.3C44.2,8.1,44.1,7.9,43.9,7.7z M36.7,2.8l4.7,4.7h-4.7V2.8z M42.5,45.8c0,1.4-1.1,2.5-2.5,2.5H10
                        c-1.4,0-2.5-1.1-2.5-2.5V4.2c0-1.4,1.1-2.5,2.5-2.5h25v6.7c0,0.5,0.4,0.8,0.8,0.8h6.7V45.8z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M13.3,11.7c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3S15.2,11.7,13.3,11.7z M13.3,16.7
                        c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7S14.3,16.7,13.3,16.7z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M13.3,20.8c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C16.7,22.3,15.2,20.8,13.3,20.8z
                        M13.3,25.8c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7C15,25.1,14.3,25.8,13.3,25.8z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M13.3,30c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C16.7,31.5,15.2,30,13.3,30z M13.3,35
                        c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7S14.3,35,13.3,35z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M13.3,39.2c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3S15.2,39.2,13.3,39.2z M13.3,44.2
                        c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7S14.3,44.2,13.3,44.2z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="18.3" y="15.8" width="21.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="19.2" y="4.2" width="11.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="16.7" y="7.5" width="16.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="18.3" y="25" width="21.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="18.3" y="34.2" width="21.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="18.3" y="43.3" width="21.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="25" y="12.5" width="1.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="21.7" y="12.5" width="1.7" height="1.7"></rect>
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="18.3" y="12.5" width="1.7" height="1.7"></rect>
                      </g>
                    </g>
                  </svg>`,
					title: 'Wash history',
					text: 'The user gets a wash history of all of its cars. This gives customers to keep better track of car wash and book next wash.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M49.9,34.4c-0.2-0.3-0.5-0.5-0.9-0.5h-6.6v-2.1c0-0.6-0.6-1.1-1.1-1.1h-0.1c-0.4,0-0.7,0.3-0.8,0.6
                      c-0.1,0.2-0.2,0.4-0.2,0.5v2.1h-6.6c-0.3,0-0.6,0.1-0.8,0.4l0,0l0,0l0,0l0,0c-0.2,0.2-0.3,0.4-0.3,0.6l0,0l0,0
                      c0,0.2,0.1,0.4,0.2,0.6L32.4,36l0.3,0.2c0.1,0.1,0.4,0.2,0.7,0.2h3.8c0.1,0.3,0.2,0.6,0.3,0.9h-0.1l0.1,0.2
                      c0.3,0.8,0.6,1.6,1.1,2.5c0.3,0.6,0.6,1.1,1.1,1.7c-1.5,1.4-3.4,2.5-5.7,3.3c-0.3,0-0.5,0.3-0.6,0.4v0.1c-0.1,0.2-0.2,0.5-0.2,0.9
                      c0.1,0.5,0.6,0.9,1.1,0.9h0.2h0.1c2.7-0.8,5-2.1,6.8-3.9c1.9,1.8,4.2,3.1,6.8,3.9h0.3c0.2,0,0.4-0.1,0.6-0.2H50l-0.7-0.2
                      c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.4,0-0.7-0.1-0.9c-0.1-0.3-0.4-0.4-0.6-0.5c-2.4-0.6-4.3-1.7-5.8-3.3c0.2-0.2,0.4-0.5,0.6-0.8
                      c0.1-0.2,0.3-0.4,0.4-0.6l0-0.5h-0.1c0.4-0.8,0.8-1.5,1-2.3c0.2-0.5,0.3-0.9,0.4-1.2h3.8c0.3,0,0.7-0.2,0.9-0.4l3.2-3.2L49.9,34.4z
                      M39.8,36.4h2.9c-0.2,0.6-0.6,1.8-1.5,3.3C40.6,38.6,40,37.4,39.8,36.4z"></path>
                      <path class="st0" d="M11.7,23.4c0.4,0,0.7-0.3,0.8-0.4l3-5.5h7.7l2.9,5.2c0.2,0.4,0.6,0.6,1,0.6c0.2,0,0.3,0,0.6-0.1
                      c0.3-0.2,0.5-0.5,0.6-0.7v-0.1v-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.3-0.1-0.4L20.3,7.4V7.3l-0.2-0.2c0,0-0.1-0.1-0.3-0.2
                      l-0.1-0.1h-1.1l0.1,0.1l0,0h-0.1l0,0l-0.1-0.2L18.3,7c0,0-0.1,0.1-0.2,0.3l-7.7,14.1c-0.1,0.2-0.2,0.5-0.1,0.9v0.1
                      c0,0.1,0.1,0.2,0.2,0.3l-0.6,0.6L11.7,23.4L11.7,23.4z M15,16.9L15,16.9L15,16.9L15,16.9z M22.6,15.6L22.6,15.6L22.6,15.6
                      L22.6,15.6L22.6,15.6z M16.4,15.1l2.7-4.9l2.7,4.9H16.4z M27.5,21.7L27.5,21.7L27.5,21.7L27.5,21.7z M26.9,22.7L26.9,22.7
                      L26.9,22.7L26.9,22.7z"></path>
                      <path class="st0" d="M58.8,25.3c-0.7-0.7-1.7-1.1-2.7-1.1H37.8V4c0-2.1-1.7-3.7-3.7-3.7H4.2C2.1,0.2,0.4,1.9,0.4,4v21.7H0.2V26
                      c0,0.1,0,0.2,0,0.3c0.1,0.8,0.3,1.5,0.7,2L1,28.5l0.2-0.1c0.7,0.9,1.8,1.4,2.9,1.4h3.4v4.7c0,0.5,0.3,0.9,0.6,1l-0.7,0.6l1.3-0.5
                      c0.1,0,0.3-0.1,0.4-0.2c0.1,0,0.2-0.1,0.3-0.2l1.2-1l-0.4,0.1l4.8-4.6h7.2v20.1c0,2.1,1.7,3.7,3.7,3.7h0.2v-0.1h18.4l-0.1,0.1
                      l6,5.8c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1h0.3v-0.3c0.1,0,0.3,0,0.4-0.1c0.4-0.2,0.6-0.7,0.6-1v-4.7H56
                      c2.1,0,3.7-1.7,3.7-3.7V27.9C59.8,26.9,59.5,26,58.8,25.3z M50.4,52.2L50,52.5l0.4-0.1v3.2l-4.1-4c-0.2-0.2-0.4-0.3-0.8-0.3H26.3
                      c-0.8,0-1.5-0.6-1.5-1.4V28h0.1l0.1-0.2c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.2-0.3,0.4-0.4l0.2-0.1v-0.1
                      c0.1,0,0.2,0,0.3,0h29.9c0.1,0,0.3,0,0.4,0.1l0,0h0.2l0.2,0.1l0,0c0.2,0.1,0.3,0.2,0.3,0.3c0,0.1,0.1,0.1,0.1,0.3
                      c0.1,0.1,0.1,0.3,0.2,0.4l0.1,0.2l0.2-0.1v21.6h-0.1v0.3c0,0.8-0.6,1.4-1.5,1.4h-4.6C51,51.2,50.5,51.6,50.4,52.2z M8.7,27.4H4.2
                      c-0.8,0-1.5-0.7-1.5-1.5V4c0-0.8,0.6-1.4,1.5-1.4h29.9c0.8,0,1.5,0.6,1.5,1.4v20.1h-9.4c-0.5,0-0.9,0.1-1.4,0.2l0,0l0,0
                      c-1.3,0.5-2.2,1.6-2.3,2.9h-7.8c-0.5,0-0.7,0.2-0.8,0.4l-4.1,3.9v-3.1C9.8,27.9,9.2,27.4,8.7,27.4z M56.8,24.7L56.8,24.7L56.8,24.7
                      L56.8,24.7z"></path>
                    </g>
                  </svg>`,
					title: 'Multilingual',
					text: 'The app is a truly global app and can be used by any users, washers and admins from varying language backgrounds.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                  <g>
                        title:"ath class="st0" d="M15,35c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.",
                        text:".5,36,15.4,35.4,15,35L15,35z M13.7,14.2c-5,5-6,12.8-2.5,18.9c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4",},0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c2.5,1.4,5.2,2.1,7.9,2.1c4.1,0,8.1-1.6,11.1-4.6c6.1-6.1,6.1-16,0-22.2
                        C29.7,8.1,19.8,8.1,13.7,14.2z M49.5,21c0-0.5-0.3-0.9-0.8-1l-3.6-0.6l-1.9-4.6l2.1-2.9c0.3-0.4,0.2-0.9-0.1-1.3l-6-6
                        c-0.3-0.3-0.9-0.4-1.3-0.1L35,6.6l-4.6-1.9l-0.6-3.6c-0.1-0.5-0.5-0.8-1-0.8l0,0h-8.5c-0.5,0-0.9,0.3-1,0.8L19,4.9l-4.6,1.9
                        l-2.9-2.1c-0.4-0.3-0.9-0.2-1.3,0.1l-6,6c-0.3,0.3-0.4,0.9-0.1,1.3L6.2,15l-1.9,4.6l-3.6,0.6C0.3,20.1,0,20.5,0,21v8.5
                        c0,0.5,0.3,0.9,0.8,1L4.4,31l1.9,4.6l-2.1,2.9c-0.3,0.4-0.2,0.9,0.1,1.3l6,6c0.3,0.3,0.9,0.4,1.3,0.1l2.9-2.1l4.6,1.9l0.6,3.6
                        c0.1,0.5,0.5,0.8,1,0.8l0,0h8.5c0.5,0,0.9-0.3,1-0.8l0.6-3.6l4.6-1.9l2.9,2.1c0.4,0.3,0.9,0.2,1.3-0.1l6-6
                        c0.3-0.3,0.4-0.9,0.1-1.3l-2.1-2.9l1.9-4.6l3.6-0.6c0.5-0.1,0.8-0.5,0.8-1L49.5,21z M44.3,29.2c-0.3,0.1-0.6,0.3-0.7,0.6l-2.3,5.6
                        c-0.1,0.3-0.1,0.7,0.1,0.9l2,2.7l-4.8,4.8l-2.7-2c-0.3-0.2-0.6-0.2-0.9-0.1L29.3,44c-0.3,0.1-0.5,0.4-0.6,0.7L28.2,48h-6.8
                        l-0.5-3.3c-0.1-0.3-0.3-0.6-0.6-0.7l-5.6-2.3c-0.1,0-0.2-0.1-0.4-0.1s-0.4,0.1-0.6,0.2l-2.7,2L6.2,39l2-2.7
                        c0.2-0.3,0.2-0.6,0.1-0.9L6,29.8c-0.1-0.3-0.4-0.5-0.7-0.6L2,28.7v-6.8l3.3-0.5C5.6,21.3,5.8,21,6,20.7l2.3-5.6
                        c0.1-0.3,0.1-0.7-0.1-0.9l-2-2.7L11,6.7l2.7,2c0.3,0.2,0.6,0.2,0.9,0.1l5.6-2.3c0.3-0.1,0.5-0.4,0.6-0.7l0.5-3.3h6.8l0.5,3.3
                        c0.1,0.3,0.3,0.6,0.6,0.7l5.6,2.3c0.3,0.1,0.7,0.1,0.9-0.1l2.7-2l4.8,4.8l-2,2.7c-0.2,0.3-0.2,0.6-0.1,0.9l2.3,5.6
                        c0.1,0.3,0.4,0.5,0.7,0.6l3.3,0.5v6.8L44.3,29.2z M20.4,25.4l4.2,4.2c-0.3,1.7,0.3,3.4,1.5,4.6c1,1,2.4,1.6,3.8,1.6
                        c0.7,0,1.4-0.1,2-0.4c0.3-0.1,0.5-0.4,0.6-0.7s0-0.6-0.3-0.9l-1.4-1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l1.4,1.4
                        c0.2,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6c0.8-2.1,0.3-4.5-1.4-6c-1.2-1.1-2.8-1.6-4.4-1.3l-4.2-4.2
                        c0.3-1.7-0.3-3.4-1.5-4.6c-1.5-1.5-3.8-2-5.8-1.2c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.3,0.9l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
                        c-0.3,0.3-0.8,0.3-1.1,0l-1.4-1.4c-0.2-0.2-0.6-0.3-0.9-0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.8,2.1-0.3,4.5,1.4,6
                        C17.2,25.1,18.8,25.6,20.4,25.4z M17.2,22.6c-0.6-0.6-1-1.4-1.1-2.2l0.1,0.1c1.1,1.1,2.8,1.1,3.8,0c1.1-1.1,1.1-2.8,0-3.8l0,0
                        c0.8,0.1,1.5,0.4,2,1c0.9,0.9,1.2,2.1,0.9,3.3c-0.1,0.3,0,0.7,0.3,0.9l5,5c0.2,0.2,0.6,0.3,0.9,0.3c1.1-0.3,2.3,0,3.2,0.8
                        c0.6,0.6,1,1.3,1.1,2.2l0,0c-1.1-1.1-2.8-1.1-3.8,0c-1.1,1.1-1.1,2.8,0,3.8l0,0c-0.8-0.1-1.5-0.4-2.1-1c-0.9-0.9-1.2-2.1-0.9-3.3
                        c0.1-0.3,0-0.7-0.3-0.9l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.2,0-0.2,0C19.3,23.7,18.1,23.4,17.2,22.6z M27.2,14.3
                        c-0.5-0.1-1,0.2-1.2,0.7c-0.1,0.5,0.2,1,0.7,1.2c2.1,0.5,3.9,1.7,5.3,3.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
                        c0.4-0.3,0.5-0.9,0.2-1.4C32,16.3,29.7,14.9,27.2,14.3z M33.6,22.4c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
                        c0.5-0.2,0.8-0.7,0.6-1.2l0,0c-0.2-0.5-0.7-0.8-1.2-0.6C33.7,21.3,33.4,21.9,33.6,22.4L33.6,22.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Help & support',
					text: 'For all kinds of troubleshooting and issues while using the app, help and support can be accessed from within the app itself.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M50,25.2c0,13.8-11.2,25-25,25S0,39,0,25.2s11.2-25,25-25C38.8,0.2,50,11.4,50,25.2z M47.8,25.2
                    C47.8,12.5,37.6,2.3,25,2.3S2.2,12.5,2.2,25.2C2.2,37.8,12.4,48,25,48S47.8,37.8,47.8,25.2z M38.1,21.6h4.8V24h-4.8v4.8h-2.4V24H31
                    v-2.4h4.8v-4.8h2.4v4.8H38.1z M9,25.2c0.1,0.3,0.4,0.5,0.7,0.4l1.1-0.3l0.3,1.1c0.1,0.2,0.2,0.7,0.5,1.1c0.2,0.4,0.5,0.6,0.7,0.7
                    l0.7,0.3v0.7V31c0,0.3,0,1,0,2.2s-0.8,2.2-1.9,2.6l-6.6,2.1l-0.7-2.3l6.6-2.1c0.1,0,0.2-0.2,0.2-0.3c0-1.2,0-2,0-2.2v-1.1
                    c-0.4-0.3-0.7-0.7-1-1.2c-0.2-0.3-0.3-0.5-0.4-0.8c-1.2-0.1-2.2-1-2.5-2.2l-0.6-2.3c-0.3-1,0.2-2.1,1.2-2.5c0-0.3,0-0.5,0-0.7
                    c0-5.7,3.6-9.5,8.6-9.4c5,0,8.6,3.7,8.6,9.4c0,0.2,0,0.5,0,0.7c1,0.4,1.5,1.5,1.2,2.6L25,25.8c-0.3,1.2-1.4,2.1-2.6,2.2
                    c-0.1,0.2-0.3,0.5-0.4,0.7c-0.3,0.4-0.5,0.7-0.9,1v1.4c0,0.2,0,1,0,2.2c0,0.1,0.1,0.3,0.2,0.3L26,35c2.1,0.6,3.6,2.6,3.6,4.8V49
                    h-2.4v-9.2c0-1.2-0.8-2.2-1.9-2.5l-4.7-1.4c-1.2-0.3-2-1.4-2-2.6c0-1.2,0-1.9,0-2.1l-0.1-2.7l0.6-0.4c0.2-0.1,0.4-0.4,0.7-0.7
                    c0.3-0.4,0.5-0.8,0.5-1.1l0.4-1l1,0.3c0.3,0.1,0.6-0.1,0.7-0.4l0.5-2.1l-1.4-0.4l0.1-1c0.1-0.5,0.1-1,0.1-1.4c0-4.4-2.5-7-6.2-7
                    c-3.6,0-6.2,2.6-6.2,7c0,0.4,0,0.9,0.1,1.4l0.1,1l-1,0.3l-0.4,0.1L9,25.2z"></path>
                  </svg>
          `,
					title: 'Invite friends',
					text: 'Users can invite their friends to use the app and gain loyalty points or offers therein.'
				}
			],
			items2: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M36.6,32.5c0,0-5.8,0.2-6.7-6c1-0.7,1.8-1.5,2.6-2.6c1.5-2,2.5-4.6,2.8-7.3c0.1-0.1,0.2-0.2,0.3-0.4
                        c0.6-1.5,0.9-3.1,0.9-4.7C36.6,5.1,31.8,0,25.9,0c-1.5,0-3,0.4-4.4,1c-0.5,0-1.1,0.1-1.6,0.3c-2.4,0.7-4.4,2.5-5.5,5
                        s-1.3,5.4-0.6,8.2c0.2,0.6,0.3,1.2,0.6,1.7c0.1,0.2,0.2,0.3,0.3,0.4c0.6,4.3,2.6,7.9,5.4,9.8c-0.9,6.2-6.6,6-6.7,6
                        C9.3,32.6,6,36,6,40.2v8.7C6,49.5,6.5,50,7.1,50H17h16h9.9c0.6,0,1.1-0.5,1.1-1.1v-8.7C44,36,40.7,32.6,36.6,32.5z M20.6,3.5
                        c0.4-0.1,0.8-0.2,1.3-0.2c0.2,0,0.3-0.1,0.5-0.1c1.1-0.6,2.3-0.9,3.6-0.9c4.6,0,8.4,4.1,8.4,9.2c0,0.2,0,0.3,0,0.5
                        c-0.9-0.9-2.1-1.5-3.5-1.5h-7.7c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.2-0.6-0.5-0.7-0.9c-0.2-0.6-0.8-0.9-1.4-0.9s-1.1,0.5-1.3,1
                        c-0.4,1.7-1.3,3.3-2.5,4.6C14.9,9.2,16.9,4.6,20.6,3.5z M19.6,23c-1.5-1.8-2.4-4.2-2.7-6.9c1.4-1.3,2.5-2.8,3.3-4.6
                        c0.1,0.1,0.2,0.2,0.3,0.3c0.8,0.6,1.7,0.9,2.6,0.9h7.7c1.1,0,2,0.7,2.4,1.6l0,0c0,6.4-3.7,11.5-8.2,11.5
                        C23,25.8,21.1,24.8,19.6,23z M22.2,27.6c0.9,0.3,1.8,0.5,2.8,0.5s1.9-0.2,2.8-0.5c0.4,2.1,1.6,4,3.3,5.3l-5.9,8.4
                        c0,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.2-0.1l-5.9-8.4C20.6,31.6,21.8,29.7,22.2,27.6z M41.8,47.7H33H17H8.2v-7.6c0-3,2.5-5.5,5.5-5.5
                        c0,0,1.9,0,3.3-0.7l6,8.6c0.5,0.7,1.2,1,2,1l0,0c0.8,0,1.5-0.4,2-1l6-8.6c1.6,0.7,3.2,0.7,3.3,0.7c3,0,5.5,2.5,5.5,5.5V47.7
                        L41.8,47.7z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Washer Profile',
					text: 'Washers can  create their own profiles complete with range of services offered, timing, special services offered and so on.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g transform="translate(0,-952.36218)">
                      <path d="M18.9,961.4c-4,0-7.3,3.3-7.3,7.3c0,4,3.3,7.3,7.3,7.3c4,0,7.3-3.3,7.3-7.3C26.2,964.7,22.9,961.4,18.9,961.4z M18.9,963.6
                      c2.8,0,5,2.2,5,5s-2.2,5-5,5c-2.8,0-5-2.2-5-5S16.1,963.6,18.9,963.6z"></path>
                      <path d="M18.9,952.8c-9.5,0-17.3,7.8-17.3,17.4c0,4.5,2.9,10.7,6.4,16.7c3.5,6,7.5,11.7,10,14.7c0.4,0.5,1.1,0.5,1.6,0.2
                      c0.1,0,0.1-0.1,0.2-0.2c2.5-3,6.5-8.7,10-14.7s6.4-12.2,6.4-16.7C36.2,960.6,28.4,952.8,18.9,952.8L18.9,952.8z M18.9,955
                      c8.3,0,15,6.8,15,15.2c0,3.5-2.7,9.7-6.1,15.6c-3,5.2-6.5,10.3-8.9,13.3C16.5,996,13,991,10,985.8c-3.4-5.9-6.1-12.1-6.1-15.6
                      C3.9,961.8,10.6,955,18.9,955L18.9,955z"></path>
                      <path d="M38.5,982.9c-5,0-9.1,4.1-9.1,9.1s4.1,9.1,9.1,9.1c5,0,9.1-4.1,9.1-9.1C47.5,987,43.5,982.9,38.5,982.9z M38.5,985.1
                      c3.8,0,6.8,3.1,6.8,6.9c0,3.8-3,6.9-6.8,6.9c-3.8,0-6.8-3.1-6.8-6.9C31.6,988.2,34.7,985.1,38.5,985.1z"></path>
                      <path d="M38.4,985.7c-0.6,0-1.1,0.5-1.1,1.1v4h-4c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0h4v4
                      c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0,0,0,0,0,0v-4h4c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0h-4v-4
                      C39.6,986.2,39.1,985.7,38.4,985.7C38.5,985.7,38.5,985.7,38.4,985.7z"></path>
                    </g>
                  </svg>`,
					title: 'Add Location',
					text: 'Washers can add multiple locations to their services to help customers find on demand car wash services easily from their real-time location.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M9.2,0.3V50h32.1V0.3H9.2z M39.6,1.9v4.8H10.8V1.9H39.6z M39.6,8.3V42H10.8V8.3H39.6z M10.8,48.4v-4.8h28.9v4.8H10.8z"></path>
                      <rect x="22" y="3.5" width="6.4" height="1.6"></rect>
                      <rect x="21.2" y="45.2" width="8" height="1.6"></rect>
                      <rect x="18.8" y="3.5" width="1.6" height="1.6"></rect>
                      <rect x="15.6" y="3.5" width="1.6" height="1.6"></rect>
                      <path d="M17.6,40.4c2.9,0,5.2-2.3,5.2-5.2S20.5,30,17.6,30s-5.2,2.3-5.2,5.2C12.4,38,14.7,40.4,17.6,40.4z M17.6,31.6
                      c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6S14,37.2,14,35.2C14,33.2,15.6,31.6,17.6,31.6z"></path>
                      <polygon points="20.2,33.7 19,32.7 17.1,35.2 16.2,34.2 15,35.3 17.3,37.6  "></polygon>
                      <path d="M27.6,35.2c0,2.9,2.3,5.2,5.2,5.2S38,38,38,35.2S35.7,30,32.8,30S27.6,32.3,27.6,35.2z M36.4,35.2c0,2-1.6,3.6-3.6,3.6
                      s-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6S36.4,33.2,36.4,35.2z"></path>
                      <polygon points="31.4,32.6 30.2,33.7 31.7,35.2 30.2,36.6 31.4,37.7 32.8,36.3 34.2,37.7 35.4,36.6 33.9,35.2 35.4,33.7 34.2,32.6
                      32.8,34   "></polygon>
                      <g>
                        <path d="M21.3,21c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S22,21,21.3,21z M21.3,22c0.1,0,0.3,0.1,0.3,0.3
                        s-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3S21.2,22,21.3,22z"></path>
                        <path d="M29.6,21c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S30.3,21,29.6,21z M29.9,22.3
                        c0,0.1-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3S29.9,22.2,29.9,22.3z"></path>
                        <path d="M27.5,21.1c0,0-0.1,0-0.1,0h-3.8c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0.1,0,0.1l0.4,1.5c0.1,0.2,0.3,0.4,0.5,0.4h3
                        c0.2,0,0.4-0.2,0.5-0.4l0.4-1.5C27.9,21.4,27.7,21.1,27.5,21.1z M26.7,22l-0.1,0.5h-2.2L24.2,22H26.7z"></path>
                        <path d="M31.5,18.2h-0.9c-0.5-1.1-1.6-1.9-2.9-1.9h-4.5c-1.3,0-2.4,0.8-2.9,1.9h-0.9c-0.7,0-1.3,0.6-1.3,1.3v0.4
                        c0,0.4,0.3,0.7,0.6,0.8c-0.1,0.3-0.2,0.7-0.2,1.1v1v0.1V24c0,0.9,0.6,1.7,1.5,1.9V27c0,0.5,0.4,0.9,0.9,0.9h0.8
                        c0.5,0,0.9-0.4,0.9-0.9v-1h5.8v1c0,0.5,0.4,0.9,0.9,0.9H30c0.5,0,0.9-0.4,0.9-0.9v-1.1c0.9-0.2,1.5-1,1.5-1.9v-1.1v-0.1v-1
                        c0-0.4-0.1-0.8-0.2-1.1c0.4-0.1,0.6-0.4,0.6-0.8v-0.4C32.7,18.8,32.2,18.2,31.5,18.2z M31,20.7c0.2,0.3,0.3,0.7,0.3,1.1v1v0.1
                        c0,0.6-0.4,1-1,1h-9.8c-0.6,0-1-0.4-1-1v-0.1v-1c0-0.4,0.1-0.7,0.3-1.1h0v-0.1c0.1-0.1,0.2-0.3,0.4-0.4l0.1,0l0.5,0.2
                        c0.3,0.1,0.6,0.1,0.9,0.1h7.6c0.3,0,0.6,0,0.9-0.1l0.5-0.2l0.1,0C30.8,20.4,30.9,20.5,31,20.7L31,20.7L31,20.7z M20.6,24.9h9.8
                        c0.2,0,0.4,0,0.6-0.1c-0.2,0.1-0.4,0.2-0.6,0.2h-9.8c-0.2,0-0.5-0.1-0.6-0.2C20.1,24.8,20.3,24.9,20.6,24.9z M29.8,19.5
                        c-0.2,0.1-0.4,0.1-0.5,0.1h-7.6c-0.2,0-0.4,0-0.5-0.1l-0.1,0c0-1.2,1-2.1,2.1-2.1h4.5C28.9,17.3,29.8,18.3,29.8,19.5L29.8,19.5z
                        M19.7,19.5c-0.1,0.1-0.2,0.2-0.2,0.2h-0.3v-0.3c0-0.1,0.1-0.3,0.3-0.3h0.6L19.7,19.5z M31.2,19.5l-0.4-0.3h0.6
                        c0.1,0,0.3,0.1,0.3,0.3v0.3h-0.3C31.4,19.7,31.3,19.6,31.2,19.5z M21.1,26h0.5v0.9h-0.5V26z M29.4,26h0.5v0.9h-0.5V26z"></path>
                        <path d="M25.5,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5
                        c-0.1,0.2-0.2,0.4-0.2,0.6C24.2,12.3,24.8,12.8,25.5,12.8z M25.2,11.6c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3
                        c0.1,0.1,0.1,0.3,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C25.3,11.8,25.2,11.7,25.2,11.6z"></path>
                        <path d="M21.7,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.6
                        C20.4,12.3,21,12.8,21.7,12.8z M21.5,11.6c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2S21.5,11.7,21.5,11.6z"></path>
                        <path d="M29.2,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0,0,0h0c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5
                        c-0.1,0.2-0.2,0.4-0.2,0.6C28,12.3,28.5,12.8,29.2,12.8z M29,11.6c0,0,0-0.1,0.1-0.2s0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C29.1,11.8,29,11.7,29,11.6z"></path>
                        <path d="M22.5,13.6c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6
                        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6
                        C22.7,13.2,22.6,13.4,22.5,13.6z M23.3,14.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2S23.3,14.4,23.3,14.2z"></path>
                        <path d="M26.3,13.6c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6
                        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6
                        C26.4,13.2,26.3,13.4,26.3,13.6z M27.1,14.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.3,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C27.2,14.5,27.1,14.4,27.1,14.2z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Wash request',
					text: 'Admins sends washer new wash request in its region. The washer can either accept or decline the request.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M43.2,0H6.8C3.1,0,0,3.1,0,6.8v36.3C0,46.9,3.1,50,6.8,50h36.3c3.8,0,6.8-3.1,6.8-6.8V6.8C50,3.1,46.9,0,43.2,0z
                        M47.7,43.2c0,2.5-2,4.5-4.5,4.5H6.8c-2.5,0-4.5-2-4.5-4.5V6.8c0-2.5,2-4.5,4.5-4.5h36.3c2.5,0,4.5,2,4.5,4.5V43.2z"></path>
                        <path d="M11.9,7.9c-2.9,0-5.3,2.4-5.3,5.3c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3C17.2,10.3,14.8,7.9,11.9,7.9z M11.9,16.1
                        c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9c0,0,0,0,0,0C14.8,14.8,13.5,16.1,11.9,16.1z"></path>
                        <path d="M11.9,11.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S12.6,11.9,11.9,11.9L11.9,11.9z"></path>
                        <path d="M11.9,19.7c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3c0,0,0,0,0,0C17.2,22.1,14.8,19.7,11.9,19.7z
                        M11.9,27.9C10.3,27.9,9,26.6,9,25s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9l0,0C14.8,26.6,13.5,27.9,11.9,27.9z"></path>
                        <circle cx="11.9" cy="25" r="1.3"></circle>
                        <path d="M11.9,31.6c-2.9,0-5.3,2.4-5.3,5.3c0,2.9,2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3c0,0,0,0,0,0C17.2,33.9,14.8,31.6,11.9,31.6z
                        M11.9,39.7c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9C14.8,38.4,13.5,39.7,11.9,39.7z"></path>
                        <path d="M11.9,35.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3l0,0C13.2,36.1,12.6,35.6,11.9,35.6z"></path>
                        <path d="M39.7,12H21c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h18.8c0.6,0,1.2-0.5,1.2-1.2C40.9,12.5,40.4,12,39.7,12z"></path>
                        <path d="M39.7,23.8H21c-0.6,0-1.2,0.5-1.2,1.1c0,0.6,0.5,1.2,1.1,1.2c0,0,0.1,0,0.1,0h18.8c0.6,0,1.2-0.6,1.1-1.2
                        C40.8,24.3,40.4,23.9,39.7,23.8z"></path>
                        <path d="M39.7,35.6H21c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h18.8c0.6,0,1.2-0.5,1.2-1.2C40.9,36.2,40.4,35.6,39.7,35.6z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Upcoming washes',
					text: 'A bird’s eye view of upcoming washes, special requests from customers, estimated earnings from each wash and so on.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M39.9,0h-30C8.4,0,7.4,1.1,7.4,2.5v45c0,1.4,1.1,2.5,2.5,2.5h22.8l9.7-9.7V2.5C42.4,1.1,41.3,0,39.9,0z M40.7,39.7l-7.5,7.5
                    v-5.5c0-0.5,0.3-0.8,0.8-0.8h4.2v-1.7H34c-1.4,0-2.5,1.1-2.5,2.5v6.7H9.9C9.4,48.3,9,48,9,47.5v-45C9,2,9.4,1.7,9.9,1.7h30
                    c0.5,0,0.8,0.3,0.8,0.8V39.7z"></path>
                    <rect x="20.7" y="5" width="8.3" height="1.7"></rect>
                    <path d="M19.9,9.2h-7.5v5h7.5V9.2z M18.2,12.5H14v-1.7h4.2V12.5z"></path>
                    <rect x="21.5" y="10.8" width="7.5" height="1.7"></rect>
                    <path d="M19.9,15.8h-7.5v5h7.5V15.8z M18.2,19.2H14v-1.7h4.2V19.2z"></path>
                    <rect x="21.5" y="17.5" width="7.5" height="1.7"></rect>
                    <path d="M19.9,22.5h-7.5v5h7.5V22.5z M18.2,25.8H14v-1.7h4.2V25.8z"></path>
                    <rect x="21.5" y="24.2" width="7.5" height="1.7"></rect>
                    <path d="M19.9,29.2h-7.5v5h7.5V29.2z M18.2,32.5H14v-1.7h4.2V32.5z"></path>
                    <rect x="21.5" y="30.8" width="7.5" height="1.7"></rect>
                    <rect x="12.4" y="35.8" width="25" height="1.7"></rect>
                    <rect x="12.4" y="39.2" width="5.8" height="1.7"></rect>
                    <rect x="20.7" y="39.2" width="9.2" height="1.7"></rect>
                    <rect x="30.7" y="10.8" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="10.8" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="17.5" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="17.5" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="24.2" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="24.2" width="2.5" height="1.7"></rect>
                    <rect x="30.7" y="30.8" width="2.5" height="1.7"></rect>
                    <rect x="34.9" y="30.8" width="2.5" height="1.7"></rect>
                  </svg>`,
					title: 'Wash Status',
					text: 'Real-time status of the wash can be updated by the user so that the user stays in loop of the estimated time of delivery of the washed car.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M20.6,46.2h-3.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7C21.3,46.5,21,46.2,20.6,46.2z"></path>
                    <path d="M18.2,3.8h1.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7S17.8,3.8,18.2,3.8z"></path>
                    <path d="M41.7,11h-9.4V2.3C32.3,1,31.2,0,30,0H8.1C6.8,0,5.8,1,5.8,2.3v45.4c0,1.3,1,2.3,2.3,2.3H30c1.3,0,2.3-1,2.3-2.3V34.3h9.4
                    c1.3,0,2.3-1,2.3-2.3V13.3C44,12,43,11,41.7,11z M8.1,1.5H30c0.5,0,0.8,0.4,0.8,0.8v2.4H7.2V2.3C7.2,1.8,7.6,1.5,8.1,1.5z M30,48.5
                    H8.1c-0.5,0-0.8-0.4-0.8-0.8v-2.4h23.6v2.4C30.8,48.2,30.4,48.5,30,48.5z M42.5,32c0,0.5-0.4,0.8-0.8,0.8H22.9
                    c-0.2,0-0.3,0.1-0.4,0.1l-5.1,3.8V19.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v19.2c0,0.6,0.7,0.9,1.2,0.6l6.1-4.5h7.6v9.5
                    H7.2V6.2h23.6V11H18.2c-1.3,0-2.3,1-2.3,2.3v2.3c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-2.3c0-0.5,0.4-0.8,0.8-0.8h23.5
                    c0.5,0,0.8,0.4,0.8,0.8V32H42.5z"></path>
                    <path d="M36.2,27.4h-0.7c-0.1,0-0.9-0.4-0.9-3.2c0-5.4-2.9-6.2-4.6-6.2s-4.6,0.8-4.6,6.2c0,2.7-0.8,3.1-0.9,3.2h-0.7
                    c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4l0,0c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3l0,0h4c0.4,0,0.7-0.3,0.7-0.7S36.6,27.4,36.2,27.4
                    z M30.8,28.9c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8l0,0H30.8L30.8,28.9z M26.3,27.4c0.3-0.7,0.5-1.7,0.5-3.2
                    c0-4.7,2.4-4.7,3.2-4.7s3.2,0,3.2,4.7c0,1.5,0.2,2.5,0.5,3.2C33.7,27.4,26.3,27.4,26.3,27.4z"></path>
                    <path d="M34.7,18c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.9,0,1.6,0.7,1.6,1.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                    C37.7,19.4,36.4,18,34.7,18z"></path>
                    <path d="M34.7,14.9c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                    C40.9,17.7,38.1,14.9,34.7,14.9z"></path>
                    <path d="M23.7,21.1c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7-0.3,0.7-0.7S25.7,18,25.3,18c-1.7,0-3.1,1.4-3.1,3.1c0,0.4,0.3,0.7,0.7,0.7
                    C23.3,21.8,23.7,21.5,23.7,21.1z"></path>
                    <path d="M26,15.6c0-0.4-0.3-0.7-0.7-0.7c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-2.6,2.1-4.7,4.7-4.7
                    C25.7,16.3,26,16,26,15.6z"></path>
                  </svg>`,
					title: 'Notifications',
					text: 'The washer will receive a notification for the new car wash request sent by the admin.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M46.4,17.3l-14.4-5.1L29.5,1.8c-0.3-1.3-1.6-2-2.9-1.7L1.8,6.1C0.5,6.5-0.2,7.7,0.1,9l7.1,29c0.1,0.6,0.5,1.1,1.1,1.5
                      c0.3,0.2,0.7,0.3,1.1,0.3c0,0.1,0.1,0.2,0.1,0.3c0.3,0.6,0.7,1,1.3,1.2l24.1,8.6c0.3,0.1,0.5,0.1,0.8,0.1c1,0,1.9-0.6,2.2-1.6
                      l10-28.1C48.3,19.1,47.6,17.8,46.4,17.3z M8.8,37.6l-7.1-29C1.6,8.2,1.8,7.9,2.2,7.8L27,1.7c0.4-0.1,0.8,0.1,0.8,0.5l2.3,9.3
                      l-1.7-0.6l-1.7-6.8c-0.1-0.4-0.6-0.7-1-0.6l-2.6,0.6c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.4,0.6,0.7,1,0.6l1.8-0.4l1.2,4.9l-4.3-1.5
                      c-0.3-0.1-0.5-0.1-0.8-0.1c-1,0-1.9,0.6-2.2,1.6L16,19.5l-3.2-2.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                      c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
                      c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1l-2.9,6
                      c-0.5-2.1-1-4.2-1.5-6c-0.1-0.4-0.6-0.7-1-0.6c-0.4,0.1-0.7,0.6-0.6,1C7,24,9.2,33,9.2,33c0.1,0.2,0.2,0.4,0.4,0.5
                      c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0l0.8-0.2l-1.7,4.7c-0.1,0-0.2,0-0.3-0.1C8.9,37.9,8.8,37.8,8.8,37.6z M11.7,31.6
                      l-1.1,0.3c-0.3-1.1-0.8-3.2-1.4-5.6l3.4-6.9l2.7,1.9L11.7,31.6z M46.3,19.8l-10,28.1c-0.1,0.4-0.5,0.6-0.9,0.4l-24.1-8.6
                      c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.5l10-28.1c0.1-0.3,0.4-0.5,0.7-0.5c0.1,0,0.2,0,0.2,0l9.3,3.3c0,0,0,0,0,0
                      l14.8,5.3C46.2,19,46.4,19.4,46.3,19.8z"></path>
                      <path d="M36.8,39.6c-0.4-0.2-0.9,0.1-1.1,0.5L35,42l-3.3-1.2l0.5-1.5c0.1-0.3,0.2-0.5,0.2-0.8c0-0.1,0-0.1,0-0.2
                      c0-0.2,0.1-0.5,0.1-0.7c0,0,0,0,0,0c0-0.3,0-0.6,0-0.8c0,0,0,0,0,0c0-0.3-0.1-0.5-0.1-0.8c0,0,0-0.1,0-0.1
                      c-0.1-0.3-0.1-0.5-0.2-0.8c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.5
                      c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.3-0.4-0.5c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.3-0.3-0.5-0.5-0.8-0.7
                      c1.3-0.3,2.4-1.3,2.8-2.6c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.5-0.2-0.9-0.7-1c-0.5-0.1-0.9,0.2-1,0.7c0,0.1-0.1,0.2-0.1,0.4
                      c-0.5,1.3-1.9,2-3.2,1.5c-0.6-0.2-1.1-0.7-1.4-1.3c-0.3-0.6-0.3-1.3-0.1-1.9s0.7-1.1,1.3-1.4c0.6-0.3,1.3-0.3,1.9-0.1
                      c0.6,0.2,1,0.6,1.3,1.1c0.2,0.4,0.7,0.5,1.1,0.3c0.4-0.2,0.5-0.7,0.3-1.1c-0.5-0.9-1.3-1.6-2.2-1.9c-1-0.4-2.2-0.3-3.2,0.2
                      c-1,0.5-1.8,1.3-2.1,2.4c-0.4,1-0.3,2.2,0.2,3.2c0.1,0.2,0.2,0.4,0.4,0.6c-2.6-0.2-5.1,1.3-6.1,3.8c0,0,0,0,0,0.1l0,0.1
                      c0,0,0,0,0,0l-0.5,1.5l-3.3-1.2l4.9-13.7c0.2-0.4-0.1-0.9-0.5-1.1c-0.4-0.2-0.9,0.1-1.1,0.5l-5.2,14.5c-0.1,0.2-0.1,0.4,0,0.6
                      s0.3,0.4,0.5,0.4l4.9,1.7l11.3,4l4.9,1.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4-0.1c0.2-0.1,0.4-0.3,0.4-0.5l0.9-2.7
                      C37.4,40.2,37.2,39.8,36.8,39.6z M21,35.3C21,35.3,21,35.3,21,35.3c0.8-2.3,3.3-3.5,5.6-2.7l1.5,0.5c0.7,0.2,1.3,0.6,1.7,1.1
                      c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0.1,0.1,0.1
                      c0.1,0.2,0.2,0.5,0.3,0.7c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.5
                      c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.4-0.1,0.5l-0.5,1.5l-9.7-3.5L21,35.3z"></path>
                      <path d="M43.8,19.8l-21-7.5c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.4,0.3-0.4,0.5l-2,5.8c-0.2,0.4,0.1,0.9,0.5,1.1
                      c0.4,0.2,0.9-0.1,1.1-0.5l1.8-5L42.5,21l-6,16.8c-0.2,0.4,0.1,0.9,0.5,1.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.2,0.8-0.6l6.3-17.6
                      C44.5,20.4,44.2,19.9,43.8,19.8z"></path>
                      <path d="M34.9,44.9L29.6,43c-0.4-0.2-0.9,0.1-1.1,0.5c-0.2,0.4,0.1,0.9,0.5,1.1l5.2,1.9c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.2,0.8-0.6
                      C35.5,45.5,35.3,45,34.9,44.9z"></path>
                      <path d="M27.3,42.2l-2-0.7c-0.4-0.2-0.9,0.1-1.1,0.5c-0.2,0.4,0.1,0.9,0.5,1.1l2,0.7c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.2,0.8-0.6
                      C28,42.8,27.7,42.4,27.3,42.2z"></path>
                      <path d="M6,16.4c0.4-0.1,0.7-0.6,0.6-1l-1.3-5.1l16-3.9c0.4-0.1,0.7-0.6,0.6-1c-0.1-0.4-0.6-0.7-1-0.6L4.1,8.8
                      C3.9,8.9,3.7,9,3.6,9.2C3.5,9.4,3.5,9.6,3.5,9.8L5,15.8c0.1,0.4,0.4,0.6,0.8,0.6C5.9,16.4,5.9,16.4,6,16.4z"></path>
                      <path d="M14.6,9.1c-1.7,0-3,1.4-3,3s1.4,3,3,3s3-1.4,3-3S16.3,9.1,14.6,9.1z M14.6,13.5c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
                      s1.4,0.6,1.4,1.4S15.3,13.5,14.6,13.5z"></path>
                    </g>
                  </svg>`,
					title: 'Before and after photo',
					text: 'Washers can prove their efficiency in delivering wash services by adding the before and after wash photo of the cars.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g transform="translate(0,-952.36218)">
                      <path d="M1,957.5c-0.6,0.1-1,0.5-1,1.1v30.7c0,0.6,0.5,1.1,1.1,1.1h4.5v4.5c0,0.6,0.5,1.1,1.1,1.1c0.2,0,0.5-0.1,0.7-0.2l7.7-5.5
                      h33.7c0.6,0,1.1-0.5,1.1-1.1v-30.7c0-0.6-0.5-1.1-1.1-1.1H1.1C1.1,957.5,1.1,957.5,1,957.5z M2.3,959.8h45.5v28.4h-33
                      c-0.2,0-0.5,0.1-0.7,0.2L8,992.8v-3.5c0-0.6-0.5-1.1-1.1-1.1H2.3V959.8z M25,964.4c-3.1,0-5.7,2.6-5.7,5.7v3.4h-1.1
                      c0,0-0.1,0-0.1,0c-0.6,0.1-1,0.5-1,1.1v8c0,0.6,0.5,1.1,1.1,1.1h13.6c0.6,0,1.1-0.5,1.1-1.1v-8c0-0.6-0.5-1.1-1.1-1.1h-1.1V970
                      C30.7,966.9,28.1,964.4,25,964.4z M25,966.6c1.9,0,3.4,1.5,3.4,3.4v3.4h-6.8V970C21.6,968.1,23.1,966.6,25,966.6z M19.3,975.7h11.4
                      v5.7H19.3V975.7z"></path>
                    </g>
                  </svg>`,
					title: 'Key request',
					text: 'On the scheduled time, the washer can reach the car owners place and within the app, they can request a car key.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g id="Layer_2">
                    </g>
                    <g id="Layer_1">
                      <path d="M36.6,50c2.8,0,5.1-2.2,5.1-5V4.7c0-2.7-2.3-5-5.1-5H16c-2.8,0-5.1,2.2-5.1,5V45c0,2.7,2.3,5,5.1,5C16,50,36.6,50,36.6,50z
                      M12.6,45V4.7c0-1.8,1.5-3.3,3.4-3.3h20.5c1.9,0,3.4,1.5,3.4,3.3V45c0,1.8-1.5,3.3-3.4,3.3H16C14.2,48.4,12.6,46.9,12.6,45z
                      M38.6,41.3V8.4c0-0.5-0.4-0.8-0.8-0.8H14.9c-0.5,0-0.8,0.3-0.8,0.8v32.9c0,0.5,0.4,0.8,0.8,0.8h22.8
                      C38.2,42.2,38.6,41.8,38.6,41.3z M36.9,40.5H15.7V9.3h21.2L36.9,40.5L36.9,40.5z M23.1,4.9c0-0.5,0.4-0.8,0.8-0.8h4.5
                      c0.5,0,0.8,0.3,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8H24C23.6,5.8,23.1,5.4,23.1,4.9z M26.3,42.7c-1.5,0-2.7,1.2-2.7,2.6
                      c0,1.4,1.2,2.6,2.7,2.6c1.5,0,2.7-1.2,2.7-2.6C29,43.8,27.7,42.7,26.3,42.7z M26.3,46.3c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1
                      c0.6,0,1,0.5,1,1C27.3,45.9,26.8,46.3,26.3,46.3z M26.3,33.5c4.8,0,8.6-3.8,8.6-8.4s-3.9-8.4-8.6-8.4c-4.8,0-8.6,3.8-8.6,8.4
                      S21.6,33.5,26.3,33.5z M26.3,18.4c3.8,0,6.9,3,6.9,6.8s-3.1,6.8-6.9,6.8s-6.9-3-6.9-6.8S22.5,18.4,26.3,18.4z M23.1,23.6
                      c0-1.3,1-2.3,2.3-2.3h0.1v-0.7c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8v0.7h1c0.5,0,0.8,0.3,0.8,0.8s-0.4,0.8-0.8,0.8h-2.6
                      c-0.4,0-0.8,0.3-0.8,0.7s0.4,0.7,0.8,0.7H27c0.1,0,0.1,0,0.2,0c1.3,0.1,2.3,1.1,2.3,2.3c0,1.3-1.1,2.3-2.3,2.3v0.7
                      c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.3-0.8-0.8V29h-1.1c-0.5,0-0.8-0.3-0.8-0.8s0.4-0.8,0.8-0.8h2.6c0.4,0,0.8-0.3,0.8-0.7
                      s-0.4-0.7-0.8-0.7h-1.5c-0.1,0-0.1,0-0.2,0C24.1,25.9,23.1,24.8,23.1,23.6z"></path>
                    </g>
                  </svg>`,
					title: 'Payment request',
					text: 'Once the wash is completed, the washer can request for the payment to the admin. He can request payment for multiple services.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M41.6,24c-0.1-0.3-0.4-0.5-0.7-0.6l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6
                      c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1L29,32.3c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1
                      c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8v-0.1L38.4,28l3-3.1C41.6,24.6,41.7,24.3,41.6,24z M36.9,27.1
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L36.9,27.1z"></path>
                      <path d="M32.5,40.7c-0.1-0.3-0.4-0.5-0.7-0.6l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6
                      c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1L20.1,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1
                      C28.8,50,29,50,29.1,50c0.5,0,0.8-0.4,0.8-0.8v-0.1l-0.7-4.4l3-3.1C32.5,41.3,32.6,41,32.5,40.7z M27.7,43.8
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0L22,47.6l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L27.7,43.8z"></path>
                      <path d="M2.6,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8v-0.1
                      l-0.7-4.4l3-3.1c0.3-0.3,0.3-0.9,0-1.2c-0.1-0.1-0.3-0.2-0.5-0.2L10,39.6l-1.9-4C7.9,35.2,7.4,35,7,35.2c-0.2,0.1-0.3,0.2-0.4,0.4
                      l-1.9,4l-4.2,0.6c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1L2.6,49z M2.6,41.5l3-0.5c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8
                      l1.3,2.8C8.9,40.8,9.2,41,9.4,41l3,0.5l-2.2,2.3C10,44,9.9,44.3,10,44.5l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4L5,44.5
                      c0-0.3,0-0.5-0.2-0.7L2.6,41.5z"></path>
                      <path d="M49.3,40.1l-4.2-0.6l-1.9-4c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.9,4l-4.2,0.6c-0.5,0.1-0.8,0.5-0.7,0.9
                      c0,0.2,0.1,0.3,0.2,0.5l3,3.1L37.6,49c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1
                      c0.5,0,0.8-0.4,0.8-0.8v-0.1l-0.7-4.4l3-3.1c0.3-0.3,0.3-0.9,0-1.2C49.6,40.2,49.5,40.1,49.3,40.1z M45.2,43.8
                      C45,44,44.9,44.3,45,44.5l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0l-2.6,1.4l0.5-3.1c0-0.3,0-0.5-0.2-0.7l-2.2-2.3l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8c0.1,0.3,0.4,0.4,0.6,0.5l3,0.5L45.2,43.8z"></path>
                      <path d="M15.8,18.3c-0.3,0-0.6,0.2-0.8,0.5l-1.9,4L9,23.4c-0.5,0.1-0.8,0.5-0.7,0.9c0,0.2,0.1,0.3,0.2,0.5l3,3.1l-0.7,4.4
                      c-0.1,0.5,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l3.7-2.1l3.7,2.1c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.8-0.4,0.8-0.8v-0.1L20.1,28l3-3.1
                      c0.3-0.3,0.3-0.9,0-1.2c-0.1-0.1-0.3-0.2-0.5-0.2l-4.2-0.6l-1.9-4C16.5,18.5,16.2,18.3,15.8,18.3z M17.8,24.4l3,0.5l-2.2,2.3
                      c-0.2,0.2-0.3,0.5-0.2,0.7l0.5,3.1l-2.6-1.4c-0.3-0.1-0.6-0.1-0.8,0L12.9,31l0.5-3.1c0-0.3,0-0.5-0.2-0.7L11,24.9l3-0.5
                      c0.3,0,0.5-0.2,0.6-0.5l1.3-2.8l1.3,2.8C17.3,24.2,17.5,24.3,17.8,24.4z"></path>
                      <path d="M33.3,4.2h-2.5C30.8,1.9,29,0,26.7,0c-0.5,0-0.8,0.4-0.8,0.8v3.3h-7.5c-0.5,0-0.8,0.4-0.8,0.8v10.8c0,0.5,0.4,0.8,0.8,0.8
                      h13.3c0.3,0,0.5-0.1,0.7-0.4l1.7-2.5c0.1-0.1,0.1-0.3,0.1-0.5V5C34.2,4.5,33.8,4.2,33.3,4.2z M19.2,5.8h5V15h-5V5.8z M32.5,13.1
                      L31.2,15h-5.4V5.8h0.8c0.5,0,0.8-0.4,0.8-0.8V1.8c1,0.4,1.7,1.3,1.7,2.4V5c0,0.5,0.4,0.8,0.8,0.8h2.5L32.5,13.1L32.5,13.1z"></path>
                      <path d="M21.7,10.8c-0.5,0-0.8,0.4-0.8,0.8v1.7c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-1.7C22.5,11.2,22.1,10.8,21.7,10.8z"></path>
                    </g>
                  </svg>`,
					title: 'Ratings and reviews',
					text: 'Washers can leave reviews and ratings for the customers to point out best customers and the unruly lot.'
				}
			],
			items3: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M46.2,1.4c-2.1,0-3.8,1.7-3.8,3.8v37.7c0,0.3,0.1,0.5,0.2,0.8l2.4,3.8c0.3,0.4,0.7,0.7,1.3,0.7s1-0.3,1.3-0.7l2.4-3.8
                    c0.1-0.2,0.2-0.5,0.2-0.8V5.2C50,3.1,48.3,1.4,46.2,1.4z M44.1,9.1h4.1v31.3h-4.1V9.1z M46.2,3.2c1.1,0,2.1,0.9,2.1,2.1v2.1h-4.1
                    V5.2C44.1,4.1,45,3.2,46.2,3.2z M46.2,46.1l-2.1-3.3v-0.6h4.1v0.6L46.2,46.1z"></path>
                    <path d="M16.6,28.1h-5.3c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h5.3c0.5,0,0.9,0.4,0.9,0.8S17.1,28.1,16.6,28.1z"></path>
                    <path d="M30.8,28.1h-10c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h10c0.5,0,0.9,0.4,0.9,0.8S31.3,28.1,30.8,28.1z"></path>
                    <path d="M16.6,31.1h-5.3c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h5.3c0.5,0,0.9,0.4,0.9,0.8S17.1,31.1,16.6,31.1z"></path>
                    <path d="M30.8,31.1h-10c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h10c0.5,0,0.9,0.4,0.9,0.8S31.3,31.1,30.8,31.1z"></path>
                    <path d="M16.7,35.1h-5.3c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h5.3c0.5,0,0.9,0.4,0.9,0.8S17.2,35.1,16.7,35.1z"></path>
                    <path d="M30.9,35.1h-10c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h10c0.5,0,0.9,0.4,0.9,0.8S31.3,35.1,30.9,35.1z"></path>
                    <path d="M16.6,38h-5.3c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h5.3c0.5,0,0.9,0.4,0.9,0.8S17.1,38,16.6,38z"></path>
                    <path d="M30.8,38h-10c-0.5,0-0.9-0.4-0.9-0.8s0.4-0.8,0.9-0.8h10c0.5,0,0.9,0.4,0.9,0.8S31.3,38,30.8,38z"></path>
                    <path d="M35.5,1.4h-29c-0.5,0-0.9,0.4-0.9,0.9v5h-5c-0.5,0-0.9,0.4-0.9,0.9v39c0,0.5,0.4,0.9,0.9,0.9h29c0.5,0,0.9-0.4,0.9-0.9v-5h5
                    c0.5,0,0.9-0.4,0.9-0.9v-39C36.4,1.8,36,1.4,35.5,1.4z M28.7,46.3H1.5V9.1h4.1v32.2c0,0.5,0.4,0.9,0.9,0.9h22.2V46.3z M34.6,40.4
                    c-6.1,0-22,0-27.2,0c0-4.7,0-33.7,0-37.2h27.2V40.4z"></path>
                    <g>
                      <path d="M29.7,19h-0.2c-0.1,0-0.1,0-0.2-0.1l-0.6-1.2c-0.2-0.4-0.5-0.6-0.9-0.6h-0.2l-0.3-1.2h-1.6l-0.3,1.2h-0.5
                      c-0.4,0-0.8,0.2-0.9,0.6l-0.1,0.1c-0.2-0.1-0.4-0.1-0.5-0.1l-1.5-0.2v-0.2c1-0.6,1.8-1.6,2.1-2.8c0.8-0.1,1.4-0.8,1.4-1.6v-2.8
                      c0-3.1-2.6-5.7-5.7-5.7s-5.7,2.6-5.7,5.7v2.8c0,0.8,0.6,1.5,1.4,1.6c0.3,1.2,1,2.2,2.1,2.8v0.2l-1.5,0.2c-1.5,0.2-2.7,1.3-3.2,2.7
                      l-1.1,3.4h16.1l-0.2-0.7c0.2,0.4,0.7,0.7,1.2,0.7c0.6,0,1.1-0.4,1.3-0.9c0.6,0,1-0.5,1-1v-1.9C30.7,19.5,30.2,19,29.7,19z
                      M26.3,16.8h0.3l0.1,0.4h-0.5L26.3,16.8z M15,13.6c-0.2-0.1-0.4-0.4-0.4-0.7c0-0.3,0.2-0.5,0.4-0.7L15,13.6
                      C15,13.6,15,13.6,15,13.6z M23.9,13.5v-1.3c0.2,0.1,0.4,0.4,0.4,0.7C24.3,13.2,24.1,13.5,23.9,13.5C23.9,13.6,23.9,13.6,23.9,13.5z
                      M25.4,19.2h1.8l0.1,0.4h-2L25.4,19.2z M26.3,23h-1.6c0,0,0-0.1,0-0.1L26.3,23L26.3,23z M22,19c-0.5,0.1-0.8,0.5-0.8,1v0.6l-1.1-1
                      l1.4-1.4l0.6,0.1L22,19z M19.4,19.1l-1.4-1.4c0.9,0.3,1.8,0.3,2.7,0L19.4,19.1z M17.2,21.1l-0.4-2.7l0.6-0.1l1.4,1.4L17.2,21.1z
                      M16,18.6l0.7,4.2l2.3-2.1V22h0.9v-1.3l1.3,1.2v0.1c0,0.6,0.4,1,1,1c0,0,0,0.1,0,0.1h-2.4v-0.6H19V23h-3.4l0.3-2.1L15,20.8L14.7,23
                      h-2.1l0.7-2.3c0.3-1.1,1.3-2,2.5-2.1L16,18.6z M26.7,20.5h1.7l-0.5-2.1h-3.1l-0.5,2.1h1.7v1.6h-1.1c-0.2-0.6-0.7-0.9-1.3-0.9
                      c-0.6,0-1.1,0.4-1.3,0.9c-0.1,0-0.2-0.1-0.2-0.2v-1.9c0-0.1,0.1-0.2,0.2-0.2h0.9c0.4,0,0.8-0.2,0.9-0.6l0.6-1.2
                      c0-0.1,0.1-0.1,0.2-0.1h3c0.1,0,0.1,0,0.2,0.1l0.6,1.2c0.2,0.4,0.5,0.6,0.9,0.6h0.2c0.1,0,0.2,0.1,0.2,0.2v1.9
                      c0,0.1-0.1,0.2-0.2,0.2c-0.2-0.5-0.7-0.9-1.3-0.9c-0.6,0-1.1,0.4-1.3,0.9h-0.5V20.5z M23.3,18.9c0,0.1-0.1,0.1-0.2,0.1h-0.3
                      l0.1-0.4l0.2,0c0.1,0,0.2,0,0.3,0.1L23.3,18.9z M14.6,11.5v-1.3c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8v1.3
                      c-0.1-0.1-0.3-0.1-0.4-0.2V9.1h-0.4c-0.6,0-1.1-0.5-1.1-1.1V7.8h-0.9V8c0,0.6-0.5,1.1-1.1,1.1h-2.5c-1.3,0-2.5,0.9-2.8,2.2
                      C14.9,11.3,14.7,11.4,14.6,11.5z M15.8,12c0-1.1,0.9-2.1,2.1-2.1h2.5c0.6,0,1.2-0.3,1.6-0.8c0.3,0.4,0.7,0.6,1.1,0.7v3.7
                      c0,2-1.6,3.6-3.6,3.6s-3.6-1.6-3.6-3.6V12z M23.5,22c0.3,0,0.5,0.2,0.5,0.5S23.7,23,23.5,23S23,22.8,23,22.5S23.2,22,23.5,22z
                      M28.4,23c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S28.7,23,28.4,23z"></path>
                      <rect x="24.3" y="20.6" width="1.2" height="0.9"></rect>
                      <path d="M18.3,11.8c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9C17.9,12.7,18.3,12.3,18.3,11.8z M17.4,11.8
                      C17.4,11.8,17.4,11.8,17.4,11.8C17.4,11.8,17.5,11.8,17.4,11.8C17.5,11.9,17.4,11.9,17.4,11.8z"></path>
                      <path d="M21.5,12.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S21,12.7,21.5,12.7z M21.4,11.8
                      C21.4,11.8,21.4,11.8,21.4,11.8C21.5,11.8,21.5,11.8,21.4,11.8C21.5,11.9,21.4,11.9,21.4,11.8z"></path>
                      <path d="M17.8,14.4v0.4c0,0.6,0.5,1,1,1h1.2c0.6,0,1-0.5,1-1v-0.4h-0.9v0.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2
                      v-0.4H17.8z"></path>
                    </g>
                  </svg>`,
					title: 'Washer registration',
					text: 'Admin has the control to accept or reject a washer registration request in the app backend.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <rect x="11.4" y="35.2" width="7.4" height="1.6"></rect>
                      <rect x="11.4" y="38.5" width="12.3" height="1.6"></rect>
                      <rect x="11.4" y="32" width="11.5" height="1.6"></rect>
                      <rect x="14.6" y="28.7" width="8.2" height="1.6"></rect>
                      <rect x="11.4" y="25.4" width="11.5" height="1.6"></rect>
                      <rect x="11.4" y="22.1" width="18" height="1.6"></rect>
                      <rect x="14.6" y="18.8" width="22.1" height="1.6"></rect>
                      <path d="M42.6,34.8l-2.1-6.3l1.4-1.4c1-1,1-2.5,0-3.5c-1-1-2.5-1-3.5,0c0,0,0,0,0,0L37,25.1l-4.7-1.6c-0.2-0.1-0.4-0.1-0.6,0
                      l-7,3.5c-0.3,0.1-0.5,0.4-0.5,0.7v4.6c0,0.5,0.4,0.8,0.8,0.8l0,0h2.7v1.2l-3.2,3.2c-0.1,0.1-0.2,0.3-0.2,0.4l-0.6,2.9
                      c-0.1,0.4,0.2,0.9,0.6,1c0.1,0,0.2,0,0.3,0l2.9-0.6c0.2,0,0.3-0.1,0.4-0.2l2.3-2.3l4.1,4.1l7,7c0.3,0.3,0.8,0.3,1.2,0l7-7
                      c0.3-0.3,0.3-0.8,0-1.2L42.6,34.8z M40.9,35l-6,6l-5.6-5.6v-4.8l1.8-0.4l3.7,3.7c0.3,0.3,0.8,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2l0,0
                      l3.1-3.1L40.9,35z M39.5,24.8c0.3-0.3,0.8-0.3,1.2,0s0.3,0.8,0,1.2l0,0l-5.8,5.8l-1.2-1.2L39.5,24.8z M25.9,31.5v-3.3l6.2-3.1
                      l3.6,1.2l-3.1,3.1L32,28.9c-0.2-0.2-0.5-0.3-0.7-0.2l-2.9,0.6c-0.4,0.1-0.7,0.4-0.7,0.8v1.5H25.9z M25.7,38.9l0.9,0.9L25.5,40
                      L25.7,38.9z M27.9,38.7l-1.2-1.2l1.2-1.2l1.2,1.2L27.9,38.7z M41.9,48l-5.8-5.8l5.8-5.8l5.8,5.8L41.9,48z"></path>
                      <path d="M8.9,18H7.3v27.9c0,1.4-1.1,2.5-2.5,2.5c-0.5,0-0.8,0.4-0.8,0.8S4.3,50,4.8,50h31.2c1,0,2-0.4,2.7-1l-1.1-1.2
                      c-0.4,0.4-1,0.6-1.6,0.6H8.1c0.5-0.7,0.8-1.6,0.8-2.5V18z"></path>
                      <path d="M38.4,21.3H40V5.7h5.7c0.5,0,0.8-0.4,0.8-0.8c0-2.3-1.8-4.1-4.1-4.1H25.3v1.6h13.9c-0.5,0.7-0.8,1.6-0.8,2.5V21.3z
                      M42.5,2.4c1,0,2,0.7,2.3,1.6h-4.6C40.5,3.1,41.5,2.4,42.5,2.4z"></path>
                      <rect x="14.6" y="44.3" width="17.2" height="1.6"></rect>
                      <path d="M36.8,4.9c0-0.5-0.4-0.8-0.8-0.8h-8.2c-0.5,0-0.8,0.4-0.8,0.8v8.2c0,0.5,0.4,0.8,0.8,0.8h8.2c0.5,0,0.8-0.4,0.8-0.8V4.9z
                      M35.1,12.3h-6.6V5.7h6.6V12.3z"></path>
                    </g>
                    <path d="M15.5,0c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C24.5,4.1,20.5,0,15.5,0z M11.8,15.5V15c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7v0.5
                    C16.9,16.8,14.1,16.8,11.8,15.5z M13.3,7.5c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2s-1,2.2-2.2,2.2S13.3,8.7,13.3,7.5z M20.7,14.4
                    c-0.2-1.7-1.2-3.2-2.8-4c1.6-1.3,1.8-3.7,0.4-5.3s-3.7-1.8-5.3-0.4s-1.8,3.7-0.4,5.3c0.1,0.2,0.3,0.3,0.4,0.4
                    c-1.5,0.8-2.6,2.3-2.8,4c-3-2.9-3.1-7.6-0.2-10.6s7.6-3.1,10.6-0.2s3.1,7.6,0.2,10.6C20.8,14.2,20.8,14.3,20.7,14.4z"></path>
                  </svg>`,
					title: 'User registration',
					text: 'Admin can moderate users by checking their profiles, social media handles and so on.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M9.2,0.3V50h32.1V0.3H9.2z M39.6,1.9v4.8H10.8V1.9H39.6z M39.6,8.3V42H10.8V8.3H39.6z M10.8,48.4v-4.8h28.9v4.8H10.8z"></path>
                      <rect x="22" y="3.5" width="6.4" height="1.6"></rect>
                      <rect x="21.2" y="45.2" width="8" height="1.6"></rect>
                      <rect x="18.8" y="3.5" width="1.6" height="1.6"></rect>
                      <rect x="15.6" y="3.5" width="1.6" height="1.6"></rect>
                      <path d="M17.6,40.4c2.9,0,5.2-2.3,5.2-5.2S20.5,30,17.6,30s-5.2,2.3-5.2,5.2C12.4,38,14.7,40.4,17.6,40.4z M17.6,31.6
                      c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6S14,37.2,14,35.2S15.6,31.6,17.6,31.6z"></path>
                      <polygon points="20.2,33.7 19,32.7 17.1,35.2 16.2,34.2 15,35.3 17.3,37.6  "></polygon>
                      <path d="M27.6,35.2c0,2.9,2.3,5.2,5.2,5.2S38,38,38,35.2S35.7,30,32.8,30S27.6,32.3,27.6,35.2z M36.4,35.2c0,2-1.6,3.6-3.6,3.6
                      s-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6S36.4,33.2,36.4,35.2z"></path>
                      <polygon points="31.4,32.6 30.2,33.7 31.7,35.2 30.2,36.6 31.4,37.7 32.8,36.3 34.2,37.7 35.4,36.6 33.9,35.2 35.4,33.7 34.2,32.6
                      32.8,34   "></polygon>
                      <g>
                        <path d="M21.3,21c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S22,21,21.3,21z M21.3,22c0.1,0,0.3,0.1,0.3,0.3
                        s-0.1,0.3-0.3,0.3S21,22.5,21,22.3S21.2,22,21.3,22z"></path>
                        <path d="M29.6,21c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S30.3,21,29.6,21z M29.9,22.3
                        c0,0.1-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3S29.9,22.2,29.9,22.3z"></path>
                        <path d="M27.5,21.1h-0.1h-3.8c-0.3,0-0.5,0.2-0.5,0.5v0.1l0.4,1.5c0.1,0.2,0.3,0.4,0.5,0.4h3c0.2,0,0.4-0.2,0.5-0.4l0.4-1.5
                        C27.9,21.4,27.7,21.1,27.5,21.1z M26.7,22l-0.1,0.5h-2.2L24.2,22H26.7z"></path>
                        <path d="M31.5,18.2h-0.9c-0.5-1.1-1.6-1.9-2.9-1.9h-4.5c-1.3,0-2.4,0.8-2.9,1.9h-0.9c-0.7,0-1.3,0.6-1.3,1.3v0.4
                        c0,0.4,0.3,0.7,0.6,0.8c-0.1,0.3-0.2,0.7-0.2,1.1v1v0.1V24c0,0.9,0.6,1.7,1.5,1.9V27c0,0.5,0.4,0.9,0.9,0.9h0.8
                        c0.5,0,0.9-0.4,0.9-0.9v-1h5.8v1c0,0.5,0.4,0.9,0.9,0.9H30c0.5,0,0.9-0.4,0.9-0.9v-1.1c0.9-0.2,1.5-1,1.5-1.9v-1.1v-0.1v-1
                        c0-0.4-0.1-0.8-0.2-1.1c0.4-0.1,0.6-0.4,0.6-0.8v-0.4C32.7,18.8,32.2,18.2,31.5,18.2z M31,20.7c0.2,0.3,0.3,0.7,0.3,1.1v1v0.1
                        c0,0.6-0.4,1-1,1h-9.8c-0.6,0-1-0.4-1-1v-0.1v-1c0-0.4,0.1-0.7,0.3-1.1l0,0v-0.1c0.1-0.1,0.2-0.3,0.4-0.4h0.1l0.5,0.2
                        c0.3,0.1,0.6,0.1,0.9,0.1h7.6c0.3,0,0.6,0,0.9-0.1l0.5-0.2h0.1C30.8,20.4,30.9,20.5,31,20.7L31,20.7L31,20.7z M20.6,24.9h9.8
                        c0.2,0,0.4,0,0.6-0.1c-0.2,0.1-0.4,0.2-0.6,0.2h-9.8c-0.2,0-0.5-0.1-0.6-0.2C20.1,24.8,20.3,24.9,20.6,24.9z M29.8,19.5
                        c-0.2,0.1-0.4,0.1-0.5,0.1h-7.6c-0.2,0-0.4,0-0.5-0.1h-0.1c0-1.2,1-2.1,2.1-2.1h4.5C28.9,17.3,29.8,18.3,29.8,19.5L29.8,19.5z
                        M19.7,19.5c-0.1,0.1-0.2,0.2-0.2,0.2h-0.3v-0.3c0-0.1,0.1-0.3,0.3-0.3h0.6L19.7,19.5z M31.2,19.5l-0.4-0.3h0.6
                        c0.1,0,0.3,0.1,0.3,0.3v0.3h-0.3C31.4,19.7,31.3,19.6,31.2,19.5z M21.1,26h0.5v0.9h-0.5V26z M29.4,26h0.5v0.9h-0.5V26z"></path>
                        <path d="M25.5,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.3-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.2-0.2-0.4-0.2l0,0c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.6
                        C24.2,12.3,24.8,12.8,25.5,12.8z M25.2,11.6c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.3,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C25.3,11.8,25.2,11.7,25.2,11.6z"></path>
                        <path d="M21.7,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.6
                        C20.4,12.3,21,12.8,21.7,12.8z M21.5,11.6c0,0,0-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2S21.5,11.7,21.5,11.6z"></path>
                        <path d="M29.2,12.8c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6
                        c-0.1-0.1-0.2-0.2-0.4-0.2l0,0l0,0c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6c-0.1,0.2-0.2,0.4-0.3,0.5
                        c-0.1,0.2-0.2,0.4-0.2,0.6C28,12.3,28.5,12.8,29.2,12.8z M29,11.6c0,0,0-0.1,0.1-0.2s0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C29.1,11.8,29,11.7,29,11.6z"></path>
                        <path d="M22.5,13.6c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6
                        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6
                        C22.7,13.2,22.6,13.4,22.5,13.6z M23.3,14.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.2,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2S23.3,14.4,23.3,14.2z"></path>
                        <path d="M26.3,13.6c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.2-0.1-0.4-0.2-0.6
                        c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.6-0.4-0.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.4,0.6
                        C26.4,13.2,26.3,13.4,26.3,13.6z M27.1,14.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0.1,0.1,0.3,0.2,0.3
                        c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.3,0.2C27.2,14.5,27.1,14.4,27.1,14.2z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Car wash request',
					text: 'Manage car wash requests proactively by giving high priority to idle washers.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <rect x="21.9" y="3.6" width="6.1" height="2"></rect>
                          <path d="M44.4,17.9c-1.4,0-3.5,0.5-5.1,2V3.6c0-1.7-1.4-3.1-3.1-3.1H13.8c-1.7,0-3.1,1.4-3.1,3.1v12.2H7.7
                          c-1.7,0-3.1,1.4-3.1,3.1v2c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2
                          c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2c0,1.7,1.4,3.1,3.1,3.1h3.1v4.1c0,1.7,1.4,3.1,3.1,3.1h22.4c1.7,0,3.1-1.4,3.1-3.1
                          V24.3c0.2-2.2,1.8-4,4.1-4.3v28.5h2V18.9C45.4,18.3,44.9,17.9,44.4,17.9z M13.8,2.6h22.4c0.6,0,1,0.5,1,1v3.1H12.8V3.6
                          C12.8,3,13.2,2.6,13.8,2.6z M6.6,33.2v-2c0-0.6,0.5-1,1-1h3.1v4.1H7.7C7.1,34.2,6.6,33.7,6.6,33.2z M6.6,27v-2c0-0.6,0.5-1,1-1
                          h3.1v4.1H7.7C7.1,28.1,6.6,27.6,6.6,27z M6.6,18.9c0-0.6,0.5-1,1-1h3.1v4.1H7.7c-0.6,0-1-0.5-1-1V18.9z M7.7,40.3
                          c-0.6,0-1-0.5-1-1v-2c0-0.6,0.5-1,1-1h3.1v4.1H7.7z M36.2,47.4H13.8c-0.6,0-1-0.5-1-1v-3.1h24.5v3.1C37.2,47,36.8,47.4,36.2,47.4
                          z M37.2,41.3H12.8v-6.1v-6.1V23v-6.1V8.7h24.5V41.3z"></path>
                          <path d="M25,10.5c-5.6,0-10.2,4.6-10.2,10.2S19.4,30.9,25,30.9c5.6,0,10.2-4.6,10.2-10.2C35.2,15.1,30.6,10.5,25,10.5z M25,28.9
                          c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2C33.2,25.2,29.5,28.9,25,28.9z"></path>
                          <path d="M25,17.6c0.6,0,1,0.5,1,1h2c0-1.3-0.9-2.5-2-2.9v-1.2h-2v1.2c-1.2,0.4-2,1.6-2,2.9c0,1.7,1.4,3.1,3.1,3.1
                          c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1h-2c0,1.3,0.9,2.5,2,2.9v1.2h2v-1.2c1.2-0.4,2-1.6,2-2.9c0-1.7-1.4-3.1-3.1-3.1
                          c-0.6,0-1-0.5-1-1C24,18.1,24.4,17.6,25,17.6z"></path>
                          <path d="M28.5,31.7l-4.6,4.6l-1.8-1.8l-1.7,1.7l3.5,3.5l6.4-6.4L28.5,31.7z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>`,
					title: 'Washer approved payout',
					text: 'Admins can check reviews and ratings, terms of service and so on before approving payout to the washer.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <rect x="21.9" y="3.6" width="6.1" height="2"></rect>
                          <path d="M44.4,17.9c-1.4,0-3.5,0.5-5.1,2V3.6c0-1.7-1.4-3.1-3.1-3.1H13.8c-1.7,0-3.1,1.4-3.1,3.1v12.2H7.7
                          c-1.7,0-3.1,1.4-3.1,3.1v2c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2
                          c0,0.8,0.3,1.5,0.8,2c-0.5,0.5-0.8,1.3-0.8,2v2c0,1.7,1.4,3.1,3.1,3.1h3.1v4.1c0,1.7,1.4,3.1,3.1,3.1h22.4c1.7,0,3.1-1.4,3.1-3.1
                          V24.3c0.2-2.2,1.8-4,4.1-4.3v28.5h2V18.9C45.4,18.3,44.9,17.9,44.4,17.9z M13.8,2.6h22.4c0.6,0,1,0.5,1,1v3.1H12.8V3.6
                          C12.8,3,13.2,2.6,13.8,2.6z M6.6,33.2v-2c0-0.6,0.5-1,1-1h3.1v4.1H7.7C7.1,34.2,6.6,33.7,6.6,33.2z M6.6,27v-2c0-0.6,0.5-1,1-1
                          h3.1v4.1H7.7C7.1,28.1,6.6,27.6,6.6,27z M6.6,18.9c0-0.6,0.5-1,1-1h3.1v4.1H7.7c-0.6,0-1-0.5-1-1V18.9z M7.7,40.3
                          c-0.6,0-1-0.5-1-1v-2c0-0.6,0.5-1,1-1h3.1v4.1H7.7z M36.2,47.4H13.8c-0.6,0-1-0.5-1-1v-3.1h24.5v3.1C37.2,47,36.8,47.4,36.2,47.4
                          z M37.2,41.3H12.8v-6.1v-6.1V23v-6.1V8.7h24.5V41.3z"></path>
                          <path d="M25,10.5c-5.6,0-10.2,4.6-10.2,10.2S19.4,30.9,25,30.9c5.6,0,10.2-4.6,10.2-10.2C35.2,15.1,30.6,10.5,25,10.5z M25,28.9
                          c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2C33.2,25.2,29.5,28.9,25,28.9z"></path>
                          <path d="M25,17.6c0.6,0,1,0.5,1,1h2c0-1.3-0.9-2.5-2-2.9v-1.2h-2v1.2c-1.2,0.4-2,1.6-2,2.9c0,1.7,1.4,3.1,3.1,3.1
                          c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1h-2c0,1.3,0.9,2.5,2,2.9v1.2h2v-1.2c1.2-0.4,2-1.6,2-2.9c0-1.7-1.4-3.1-3.1-3.1
                          c-0.6,0-1-0.5-1-1C24,18.1,24.4,17.6,25,17.6z"></path>
                        </g>
                      </g>
                      <path d="M21.4,40h-0.8c-0.3,0-0.6-0.3-0.6-0.6v-0.8c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8C22,39.7,21.7,40,21.4,40z
                      "></path>
                      <path d="M25.4,40h-0.8c-0.3,0-0.6-0.3-0.6-0.6v-0.8c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8C26,39.7,25.7,40,25.4,40z
                      "></path>
                      <path d="M29.4,40h-0.8c-0.3,0-0.6-0.3-0.6-0.6v-0.8c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8C30,39.7,29.7,40,29.4,40z
                      "></path>
                    </g>
                  </svg>`,
					title: 'Washer pending payout',
					text: 'A quick view of the payments pending to washers, wash transactions and overdue duration can be seen.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M2,40.9l8-8h9.5c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5h-5.8v1.6h5.8c2,0,3.6-1.4,4-3.3h13.2c0.2,0,0.4-0.1,0.6-0.2
                    l7-7c0.8-0.8,2.1-0.8,3,0c0.8,0.8,0.8,2.1,0,3l-9.2,9.2H20.4c-0.2,0-0.4,0.1-0.6,0.2l-7.4,7.4l1.2,1.2l7.2-7.2h17.8
                    c0.2,0,0.4-0.1,0.6-0.2l9.5-9.5c0.7-0.7,1.1-1.6,1.1-2.6s-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-0.9,0-1.8,0.3-2.5,1
                    c-1.4-1.3-3.5-1.3-4.9,0c-1.4-1.3-3.5-1.3-4.9,0c-1.5-1.3-3.7-1.3-5.1,0.1L23,33.3c-0.7-1.2-2-1.9-3.5-1.9H9.7
                    c-0.2,0-0.4,0.1-0.6,0.2l-8.2,8.2L2,40.9z M42.2,28.9l-5.7,5.7h-2.6l5.6-5.6C40.2,28.3,41.4,28.2,42.2,28.9z M37.3,28.9l-5.7,5.7
                    h-2.6l5.6-5.6C35.3,28.3,36.5,28.2,37.3,28.9z M29.6,29c0.7-0.7,2-0.8,2.8-0.2l-5.7,5.7H24L29.6,29z"></path>
                    <path d="M33.5,0H18.7c-0.5,0-0.8,0.4-0.8,0.8v2.5H8.9C8.4,3.3,8,3.7,8,4.2v18.9c0,0.5,0.4,0.8,0.8,0.8h14.8c0.5,0,0.8-0.4,0.8-0.8
                    v-2.5h3.3v2.5c0,0.5,0.4,0.8,0.8,0.8h14.8c0.5,0,0.8-0.4,0.8-0.8V4.2c0-0.5-0.4-0.8-0.8-0.8h-9.1V0.9C34.4,0.4,34,0,33.5,0z
                    M19.5,1.7h13.2v2.5V19h-4.1h-4.9h-4.1V4.2V1.7z M22.8,22.3H9.7V5h8.2v2.1c-0.5-0.3-1-0.5-1.6-0.5c-1.8,0-3.3,1.5-3.3,3.3
                    s1.5,3.3,3.3,3.3c0.6,0,1.2-0.2,1.6-0.5v2.1h-5.8v1.6h5.8v1.6h-5.8v1.6h5.8c0,0.5,0.4,0.8,0.8,0.8h4.1V22.3z M17.9,9.9
                    c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6C17.2,8.3,17.9,9,17.9,9.9z M42.6,5v17.3H29.4v-1.6h4.1
                    c0.5,0,0.8-0.4,0.8-0.8h5.8v-1.6h-5.8v-1.6h5.8v-1.6h-5.8v-2.1c0.5,0.3,1,0.5,1.6,0.5c1.8,0,3.3-1.5,3.3-3.3S37.8,6.6,36,6.6
                    c-0.6,0-1.2,0.2-1.6,0.5V5H42.6z M34.4,9.9c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6
                    C35.1,11.6,34.4,10.8,34.4,9.9z"></path>
                    <path d="M26.1,9.9c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3S24.3,9.9,26.1,9.9z M26.1,5c0.9,0,1.6,0.7,1.6,1.6
                    S27,8.3,26.1,8.3s-1.6-0.7-1.6-1.6S25.2,5,26.1,5z"></path>
                    <rect x="22" y="11.6" width="8.2" height="1.6"></rect>
                    <rect x="22" y="14.9" width="8.2" height="1.6"></rect>
                  </svg>`,
					title: 'Create plans',
					text: 'Admins can create specific pricing plans based on the nature of services offered, duration of the plan, location and so on.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M29,29.9h-7.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H29c0.5,0,0.9-0.4,0.9-0.9S29.5,29.9,29,29.9z"></path>
                      <path d="M29,27.1h-7.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H29c0.5,0,0.9-0.4,0.9-0.9S29.5,27.1,29,27.1z"></path>
                      <path d="M37.4,14.4c-0.7-1.1-1.9-1.9-3.2-1.9H15.8c-1.3,0-2.5,0.7-3.2,1.9l-3.9,6.8c-0.2,0.3-0.2,0.6,0,0.9
                      c0.2,0.3,0.4,0.4,0.8,0.4h31c0.3,0,0.6-0.2,0.8-0.4c0.2-0.3,0.2-0.6,0-0.9L37.4,14.4z M11,20.8l3.1-5.5c0.3-0.6,1-1,1.7-1h18.4
                      c0.7,0,1.3,0.4,1.7,1l3.1,5.5H11z"></path>
                      <path d="M15.1,27.1h-4c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h4c1.3,0,2.4-1.1,2.4-2.4C17.5,28.2,16.4,27.1,15.1,27.1z
                      M15.1,30.1h-4c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6h4c0.4,0,0.6,0.3,0.6,0.6C15.7,29.8,15.4,30.1,15.1,30.1z"></path>
                      <path d="M38.9,27.1h-4c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h4c1.3,0,2.4-1.1,2.4-2.4C41.3,28.2,40.2,27.1,38.9,27.1z
                      M38.9,30.1h-4c-0.4,0-0.6-0.3-0.6-0.6c0-0.4,0.3-0.6,0.6-0.6h4c0.4,0,0.6,0.3,0.6,0.6C39.6,29.8,39.3,30.1,38.9,30.1z"></path>
                      <path d="M48,21.3h-2.4L40,11.7c-0.9-1.6-2.6-2.5-4.4-2.5H14.4c-1.8,0-3.5,1-4.4,2.5l-5.6,9.6H2.1c-1.1,0-2,0.9-2,2v2.1
                      c0,1.1,0.9,2,2,2h2.2v5.5c0,1.5,0.8,2.7,1.9,3.5v4.3c0,1.2,1,2.2,2.2,2.2H12c1.2,0,2.2-1,2.2-2.2v-3.6h21.5v3.6
                      c0,1.2,1,2.2,2.2,2.2h3.6c1.2,0,2.2-1,2.2-2.2v-4.3c1.1-0.7,1.9-2,1.9-3.5v-5.5H48c1.1,0,2-0.9,2-2v-2.1C50,22.2,49.1,21.3,48,21.3
                      z M2.1,25.6c-0.1,0-0.3-0.1-0.3-0.3v-2.1c0-0.1,0.1-0.3,0.3-0.3h2.2v2.6H2.1z M12.5,40.8c0,0.3-0.2,0.5-0.5,0.5H8.4
                      c-0.3,0-0.5-0.2-0.5-0.5v-3.6h0.5h4.1V40.8z M42.1,37.1v3.6c0,0.3-0.2,0.5-0.5,0.5H38c-0.3,0-0.5-0.2-0.5-0.5v-3.6h4.1H42.1z
                      M44,32.9c0,1.3-1.1,2.4-2.4,2.4h-5H13.4h-5c-1.3,0-2.4-1.1-2.4-2.4v-6.4v-4.3v-0.1l5.5-9.5c0.6-1,1.7-1.7,2.9-1.7h21.2
                      c1.2,0,2.3,0.6,2.9,1.7l5.5,9.5v0.1v4.3V32.9z M48.2,25.4c0,0.1-0.1,0.3-0.3,0.3h-2.2v-2.6H48c0.1,0,0.3,0.1,0.3,0.3V25.4z"></path>
                    </g>
                  </svg>`,
					title: 'Create a car category',
					text: 'Admins can create a template or readymade list of car categories that users and washers can pick from for services.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M39.5,6.7h-5.4C33,2.7,29.4,0,25.3,0s-7.7,2.7-8.8,6.7h-5.4C8.9,6.7,7,8.5,7,10.8v35c0,2.3,1.9,4.2,4.2,4.2h28.3
                    c2.3,0,4.2-1.9,4.2-4.2v-35C43.7,8.5,41.8,6.7,39.5,6.7z M25.3,18.3c4.1,0,7.7-2.7,8.8-6.7h4.5V45H12V11.7h4.5
                    C17.6,15.6,21.2,18.3,25.3,18.3z M25.3,1.7c4.1,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5C17.8,5,21.2,1.7,25.3,1.7z
                    M42,45.8c0,1.4-1.1,2.5-2.5,2.5H11.2c-1.4,0-2.5-1.1-2.5-2.5v-35c0-1.4,1.1-2.5,2.5-2.5h5c0,0.3,0,0.6,0,0.8c0,0.3,0,0.6,0,0.8H12
                    c-0.9,0-1.7,0.7-1.7,1.7V45c0,0.9,0.7,1.7,1.7,1.7h26.7c0.9,0,1.7-0.7,1.7-1.7V11.7c0-0.9-0.7-1.7-1.7-1.7h-4.2c0-0.3,0-0.6,0-0.8
                    c0-0.3,0-0.6,0-0.8h5c1.4,0,2.5,1.1,2.5,2.5V45.8z"></path>
                    <path d="M15.3,26.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8v-3.3h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7V25C13.7,25.9,14.4,26.7,15.3,26.7z"></path>
                    <path d="M18.4,23.6c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2c0.3-0.3,0.3-0.8,0-1.2
                    c-0.3-0.3-0.8-0.3-1.2,0l-3.6,3.6L18.4,23.6z"></path>
                    <path d="M35.3,22.5h0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-0.8c-0.5,0-0.8,0.4-0.8,0.8S34.9,22.5,35.3,22.5z"></path>
                    <path d="M27.8,22.5H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8S27.4,22.5,27.8,22.5z"></path>
                    <path d="M27.8,25.8h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8C27,25.5,27.4,25.8,27.8,25.8z"></path>
                    <path d="M15.3,35c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8V30h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7v3.3C13.7,34.3,14.4,35,15.3,35z"></path>
                    <path d="M23.1,29.4L19.5,33l-1.1-1.1c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2
                    c0.3-0.3,0.3-0.8,0-1.2S23.4,29.1,23.1,29.4z"></path>
                    <path d="M22.7,37.4L19,41L18,39.9c-0.3-0.3-0.8-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l1.7,1.7c0.3,0.3,0.9,0.3,1.2,0l4.2-4.2
                    c0.3-0.3,0.3-0.8,0-1.2S23,37.1,22.7,37.4z"></path>
                    <path d="M36.2,29.2h-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h0.8c0.5,0,0.8-0.4,0.8-0.8C37,29.5,36.6,29.2,36.2,29.2z"></path>
                    <path d="M27.8,30.8H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8C27,30.5,27.4,30.8,27.8,30.8z"></path>
                    <path d="M27.8,34.2h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8S27.4,34.2,27.8,34.2z"></path>
                    <path d="M15.3,43.3c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8v-3.3h4.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2
                    c-0.9,0-1.7,0.7-1.7,1.7v3.3C13.7,42.6,14.4,43.3,15.3,43.3z"></path>
                    <path d="M36.2,37.5h-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h0.8c0.5,0,0.8-0.4,0.8-0.8S36.6,37.5,36.2,37.5z"></path>
                    <path d="M27.8,39.2H32c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8S27.4,39.2,27.8,39.2z"></path>
                    <path d="M27.8,42.5h6.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.4-0.8,0.8S27.4,42.5,27.8,42.5z"></path>
                    <g transform="translate(0,-288.53332)">
                      <path d="M24.1,296.2c-0.9,0-1.7,0.7-1.8,1.7l-0.2,0.2c-0.4,0.3-0.6,0.8-0.6,1.3v1.3c0,0.6,0.5,1.1,1.1,1.1h5.8
                      c0.6,0,1.1-0.5,1.1-1.1v-1.3c0-0.5-0.2-1-0.6-1.3l-0.2-0.2v0.1c0-1-0.8-1.8-1.8-1.8L24.1,296.2z M24.1,296.6h2.7
                      c0.7,0,1.3,0.6,1.3,1.3v0.1l0.4,0.3c0.3,0.2,0.5,0.6,0.5,0.9v1.3c0,0.4-0.3,0.7-0.7,0.7h-5.8c-0.4,0-0.7-0.3-0.7-0.7v-1.3
                      c0-0.4,0.2-0.7,0.5-0.9l0.4-0.3V298C22.8,297.2,23.4,296.6,24.1,296.6z"></path>
                      <path d="M22.4,297.9l-0.1,0.4l0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1h4.4c0.2,0,0.3,0,0.5-0.1l0.5-0.2l-0.1-0.4L28,298
                      c-0.1,0-0.2,0.1-0.3,0.1h-4.4c-0.1,0-0.2,0-0.3-0.1L22.4,297.9z"></path>
                      <path d="M21.4,300c0,0.6,0.5,1.1,1.1,1.1h5.8c0.6,0,1.1-0.5,1.1-1.1H29c0,0.4-0.3,0.7-0.7,0.7h-5.8c-0.4,0-0.7-0.3-0.7-0.7H21.4z"></path>
                      <path d="M22.3,301.5v0.9c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4v-0.9h-0.4v0.9h-0.4v-0.9H22.3z"></path>
                      <path d="M27.2,301.5v0.9c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4v-0.9h-0.4v0.9h-0.4v-0.9H27.2z"></path>
                      <path d="M23,299c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7C23.7,299.3,23.4,299,23,299z M23,299.4
                      c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2S22.9,299.4,23,299.4z"></path>
                      <path d="M27.9,299c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7C28.5,299.3,28.2,299,27.9,299z M27.9,299.4
                      c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2S27.7,299.4,27.9,299.4z"></path>
                      <path d="M24.3,299c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0.1l0.2,0.9c0,0.1,0.1,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.2l0.2-0.9
                      c0-0.1,0-0.2-0.2-0.3c0,0,0,0-0.1,0H24.3z M24.6,299.4h1.6l-0.1,0.4h-1.4L24.6,299.4z"></path>
                      <path d="M21.9,297.3c-0.4,0-0.7,0.3-0.7,0.7v0.2c0,0.2,0.2,0.4,0.4,0.4h0.4v-0.4h-0.4V298c0-0.1,0.1-0.2,0.2-0.2h0.7v-0.4H21.9z"></path>
                      <path d="M28.3,297.3v0.4H29c0.1,0,0.2,0.1,0.2,0.2v0.2h-0.4v0.4h0.4c0.2,0,0.4-0.2,0.4-0.4V298c0-0.4-0.3-0.7-0.7-0.7H28.3z"></path>
                      <path d="M25.4,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C25.6,292.3,25.5,292.2,25.4,292.2z M25.4,292.9C25.4,292.9,25.4,292.9,25.4,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C25.3,293.1,25.3,293,25.4,292.9
                      C25.4,292.9,25.4,292.9,25.4,292.9L25.4,292.9z"></path>
                      <path d="M23.2,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3s-0.1,0.2-0.1,0.3c0,0.4,0.3,0.7,0.7,0.7
                      c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4C23.3,292.3,23.3,292.2,23.2,292.2z
                      M23.2,292.9C23.2,292.9,23.2,292.9,23.2,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.2-0.2,0.2
                      c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C23.1,293.1,23.1,293,23.2,292.9C23.2,292.9,23.2,292.9,23.2,292.9L23.2,292.9z"></path>
                      <path d="M27.6,292.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C27.8,292.3,27.7,292.2,27.6,292.2z M27.6,292.9C27.6,292.9,27.6,292.9,27.6,292.9c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C27.5,293.1,27.6,293,27.6,292.9
                      C27.6,292.9,27.6,292.9,27.6,292.9L27.6,292.9z"></path>
                      <path d="M24.3,293.8c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C24.5,293.8,24.4,293.8,24.3,293.8z M24.3,294.4C24.3,294.4,24.3,294.4,24.3,294.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C24.2,294.6,24.2,294.5,24.3,294.4C24.3,294.4,24.3,294.4,24.3,294.4
                      L24.3,294.4z"></path>
                      <path d="M26.5,293.8c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.2-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                      c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.4-0.2-0.4
                      C26.7,293.8,26.6,293.8,26.5,293.8z M26.5,294.4C26.5,294.4,26.5,294.4,26.5,294.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.2
                      c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0.1-0.2C26.4,294.6,26.4,294.5,26.5,294.4
                      C26.5,294.4,26.5,294.4,26.5,294.4L26.5,294.4z"></path>
                    </g>
                  </svg>`,
					title: 'Car wash history',
					text: 'For verifying payouts, service frequency and for your measuring efficiency, the admin can look up car wash history from the dashboard.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M18.3,44.5h-3.1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h3.1c0.6,0,1-0.4,1-1C19.4,45,18.9,44.5,18.3,44.5z"></path>
                      <path d="M49,9.8L49,9.8H33.4V1.2c0-0.6-0.4-1-1-1H1.2c-0.6,0-1,0.4-1,1V49c0,0.6,0.4,1,1,1h31.2c0.6,0,1-0.4,1-1V34.7H49l0,0
                      c0.6,0,0.9-0.4,1-1l0,0V10.8C50,10.2,49.7,9.9,49,9.8z M46.2,11.9L31.3,24.5L16.5,11.9H46.2z M12.6,33.7c0.1,0.6,0.4,1,1,1l0,0
                      h13.5v5.5H6.4V7h20.8v2.8H13.6l0,0c-0.6,0.1-1,0.4-1,1V33.7L12.6,33.7z M14.7,13l8.6,7.3l-8.6,10.4C14.7,30.7,14.7,13,14.7,13z
                      M31.3,47.9H2.2V2.2h29.1v7.6h-2.1V6c0-0.6-0.4-1-1-1H5.3c-0.6,0-1,0.4-1,1v35.3c0,0.6,0.4,1,1,1h22.8c0.6,0,1-0.4,1-1v-6.5h2.1
                      V47.9z M15.8,32.7l9-10.9l5.8,4.9c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.5-0.1,0.6-0.2l5.8-4.9l9,10.9H15.8z M47.9,30.8l-8.6-10.4
                      l8.6-7.3V30.8z"></path>
                    </g>
                  </svg>`,
					title: 'Email and SMS notification',
					text: 'Admins can configure email settings for firing email and SMS notification for every major action carried out using the app.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M17,38.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
                        c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
                        c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2
                        c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0
                        c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
                        c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2S17,38.1,17,38.1z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M44.6,38.3C44.6,38.3,44.6,38.3,44.6,38.3c-0.9-2.1-3-2.9-5.4-2l-6.7,1.9c-0.4-1.2-1.4-2.2-2.7-2.7c0,0,0,0,0,0L22.4,33
                        c-0.6-0.8-2.5-2.8-6.1-2.8c-2.5,0-4.7,1.7-6,3.6H1.4c-0.5,0-1,0.4-1,1v12.1c0,0.5,0.4,1,1,1h5.7c0.5,0,1-0.4,1-1V46h2.7
                        c0.5,0,1-0.4,1-1v-0.3c1.6,0.1,2.1,0.4,3.5,1.3c1.1,0.6,2.8,1.6,5.8,2.9c0,0,0,0,0,0c0.6,0.2,1.7,0.6,3.1,0.6c1,0,2.1-0.2,3.2-0.7
                        l14.8-5.3c0,0,0.1,0,0.1,0C43.6,42.9,45.7,41.1,44.6,38.3z M6.1,45.9H2.3V35.7h3.8V45.9z M9.8,44.1H8v-8.4h1.7V44.1z M41.4,41.8
                        l-14.8,5.3c0,0,0,0,0,0c0,0,0,0,0,0c-2.1,0.9-4,0.4-4.8,0.1c-2.9-1.3-4.4-2.2-5.6-2.8c-1.6-0.9-2.3-1.3-4.5-1.5v-7.7
                        c0.9-1.5,2.7-3,4.6-3c3.3,0,4.6,2.1,4.7,2.2c0.1,0.2,0.3,0.3,0.5,0.4l7.6,2.6c1.2,0.5,1.9,1.5,1.6,2.5c-0.1,0.5-0.5,0.9-0.9,1.1
                        c-0.4,0.2-0.9,0.3-1.4,0.2l-8.6-2.7c-0.5-0.2-1,0.1-1.2,0.6c-0.2,0.5,0.1,1,0.6,1.2l8.6,2.7c0,0,0,0,0,0c0.3,0.1,0.7,0.1,1,0.1
                        c0.6,0,1.3-0.2,1.8-0.5c0.9-0.5,1.5-1.3,1.8-2.3c0-0.1,0-0.2,0.1-0.2l7.2-2c1-0.4,2.4-0.5,3,0.9C43.4,40.7,41.8,41.6,41.4,41.8z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M34.9,13c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
                        c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
                        c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2
                        c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0
                        s0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
                        c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2C34.9,13.2,34.9,13.1,34.9,13z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M48.6,3.7h-5.7c-0.5,0-1,0.4-1,1v0.8h-2.7c-0.5,0-1,0.4-1,1v0.3c-1.6-0.1-2.1-0.4-3.5-1.3C33.7,4.8,32,3.8,29,2.5
                        c0,0,0,0,0,0c-1-0.4-3.5-1.1-6.3,0.1L18.9,4l-3.3-3.3c-0.4-0.4-1-0.4-1.4,0L3.6,11.4c-0.3,0.3-0.4,0.9,0,1.4L21.8,31
                        c0.3,0.3,1,0.4,1.4,0l9.8-9.8c0.3,0,0.5,0,0.8,0c2.5,0,4.7-1.7,6-3.6h8.9c0.5,0,1-0.4,1-1V4.6C49.6,4.1,49.2,3.7,48.6,3.7z
                        M14.9,2.7l2.2,2.2c-0.7,0.4-1.4,0.6-2.2,0.6c-0.8,0-1.5-0.2-2.2-0.6L14.9,2.7z M7.8,14.3l-2.2-2.2l2.2-2.2
                        c0.4,0.7,0.6,1.4,0.6,2.2S8.2,13.6,7.8,14.3z M22.5,29l-2.2-2.2c1.3-0.8,3-0.8,4.4,0L22.5,29z M26.1,25.4c-2.1-1.5-5-1.5-7.1,0
                        l-9.8-9.8c0.7-1,1.2-2.3,1.2-3.6c0-1.3-0.4-2.5-1.2-3.6l2.2-2.2c1,0.7,2.3,1.2,3.6,1.2c1.3,0,2.5-0.4,3.6-1.2l2.1,2.1
                        c-0.4,0.1-0.9,0.2-1.3,0.4c-0.7,0.4-1.2,1-1.6,1.7c-1.1,0.2-2.1,0.7-2.9,1.5c-2.1,2.1-2.1,5.6,0,7.7c1.1,1.1,2.5,1.6,3.9,1.6
                        c1.4,0,2.8-0.5,3.9-1.6c0.7-0.7,1.2-1.5,1.4-2.5l3.2,1.1c-0.4,1.7,0,3.5,1,4.9L26.1,25.4z M20.2,16C20.2,16,20.3,16,20.2,16
                        l1.9,0.7c-0.1,0.7-0.5,1.3-1,1.7c-1.4,1.4-3.6,1.4-5,0c-1.4-1.4-1.4-3.6,0-5c0.3-0.3,0.7-0.6,1.2-0.8C17.5,14.1,18.6,15.4,20.2,16
                        z M29.7,21.8c-0.4-0.6-0.6-1.3-0.6-2c0.5,0.3,1,0.7,1.7,0.9L29.7,21.8z M38.3,16.4c-0.9,1.5-2.7,3-4.6,3c-0.4,0-0.7,0-1-0.1
                        c0,0,0,0,0,0c-2.1-0.3-3.2-1.5-3.6-1.9c0,0-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.4l-7.6-2.6c-1.2-0.5-1.9-1.5-1.6-2.5
                        c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.5,0.5-0.9,0.9-1.1c0.4-0.2,0.9-0.3,1.4-0.2l8.6,2.7c0.5,0.2,1-0.1,1.2-0.6c0.2-0.5-0.1-1-0.6-1.2
                        L24,9.1L23.9,9c0,0,0,0,0,0l-3.5-3.5l3-1.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c2.1-0.9,4-0.4,4.8-0.1c2.9,1.3,4.4,2.2,5.6,2.8
                        c1.6,0.9,2.3,1.3,4.5,1.5V16.4z M42,15.8h-1.7V7.4H42V15.8z M47.7,15.8h-3.8V5.6h3.8V15.8z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Washer payout',
					text: 'Upon receiving the payment request from the washer, Admin verifies the services and releases payment to the washers bank account.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M6.7,26.7l6.2-6.2h14.7c1.4,0,2.5-1.1,2.5-2.5V4.7c0-1.4-1.1-2.5-2.5-2.5h-25C1.1,2.2,0,3.3,0,4.7v13.3
                    c0,1.4,1.1,2.5,2.5,2.5h4.2V26.7z M2.5,18.9c-0.5,0-0.8-0.3-0.8-0.8V4.7c0-0.5,0.3-0.8,0.8-0.8h25c0.5,0,0.8,0.3,0.8,0.8v13.3
                    c0,0.5-0.3,0.8-0.8,0.8H12.2l-3.8,3.8v-3.8H2.5z"></path>
                    <path d="M11.7,7.2h15v1.7h-15V7.2z"></path>
                    <path d="M11.7,10.6h15v1.7h-15V10.6z"></path>
                    <path d="M11.7,13.9h10.8v1.7H11.7V13.9z"></path>
                    <path d="M31.7,13.1h6.7v1.7h-6.7V13.1z"></path>
                    <path d="M31.7,16.4h6.7v1.7h-6.7V16.4z"></path>
                    <path d="M33.3,19.7h5v1.7h-5V19.7z"></path>
                    <path d="M47.5,8.1H31.7v1.7h15.8c0.5,0,0.8,0.3,0.8,0.8v13.3c0,0.5-0.3,0.8-0.8,0.8h-5.8v3.8l-3.8-3.8H22.5c-0.5,0-0.8-0.3-0.8-0.8
                    v-1.7H20v1.7c0,1.4,1.1,2.5,2.5,2.5h14.7l6.2,6.2v-6.2h4.2c1.4,0,2.5-1.1,2.5-2.5V10.6C50,9.1,48.9,8.1,47.5,8.1z"></path>
                    <path d="M45.8,13.9c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5C44.8,16.4,45.8,15.3,45.8,13.9z M42.5,13.9
                    c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8c0,0.5-0.3,0.8-0.8,0.8C42.8,14.7,42.5,14.4,42.5,13.9z"></path>
                    <path d="M11.7,28.9c-0.5,0-0.8-0.3-0.8-0.8v-1.7H9.2v1.7c0,1.4,1.1,2.5,2.5,2.5h4.2v6.2l6.2-6.2h15.5v-1.7H21.3l-3.8,3.8v-3.8H11.7z
                    "></path>
                    <path d="M45.8,39.3h-3.7l-1.4-3.2c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.4,3.2h-3.7c-0.5,0-0.8,0.3-0.8,0.8
                    c0,0.2,0.1,0.5,0.2,0.7l2.9,2.6l-1.1,3.7c-0.2,0.4,0.1,0.9,0.6,1c0.2,0.1,0.4,0,0.7-0.1l3.3-1.8l3.3,1.8c0.4,0.3,0.9,0.1,1.2-0.3
                    c0.1-0.2,0.2-0.4,0.1-0.7l-1.1-3.7l2.9-2.6c0.3-0.3,0.4-0.8,0.1-1.2C46.3,39.4,46.1,39.3,45.8,39.3z M41.9,42.4
                    c-0.2,0.2-0.3,0.6-0.2,0.8l0.7,2.2l-1.9-1.1c-0.2-0.2-0.6-0.2-0.8,0l-1.9,1.1l0.7-2.2c0.1-0.3,0-0.7-0.2-0.8L36.4,41h1.9
                    c0.3,0,0.7-0.2,0.7-0.5l0.9-2l0.9,2c0.2,0.3,0.4,0.5,0.7,0.5h1.9L41.9,42.4z"></path>
                    <path d="M15.8,39.3h-3.7l-1.4-3.2c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.4,3.2H4.2c-0.5,0-0.8,0.3-0.8,0.8
                    c0,0.2,0.1,0.5,0.2,0.7l2.9,2.6l-1.1,3.7c-0.2,0.4,0.1,0.9,0.6,1c0.2,0.1,0.4,0,0.7-0.1l3.3-1.8l3.3,1.8c0.4,0.3,0.9,0.1,1.2-0.3
                    c0.1-0.2,0.2-0.4,0.1-0.7l-1.1-3.7l2.9-2.6c0.3-0.3,0.4-0.8,0.1-1.2C16.3,39.4,16.1,39.3,15.8,39.3z M11.9,42.4
                    c-0.2,0.2-0.3,0.6-0.2,0.8l0.7,2.2l-1.9-1.1c-0.2-0.2-0.6-0.2-0.8,0l-1.9,1.1l0.7-2.2c0.1-0.3,0-0.7-0.2-0.8L6.4,41h1.9
                    c0.3,0,0.7-0.2,0.7-0.5l0.9-2l0.9,2c0.2,0.3,0.4,0.5,0.7,0.5h1.9L11.9,42.4z"></path>
                    <path d="M30.8,39.3h-3.7l-1.4-3.2c-0.2-0.4-0.7-0.6-1.1-0.4c-0.2,0.1-0.3,0.2-0.4,0.4l-1.4,3.2h-3.7c-0.5,0-0.8,0.3-0.8,0.8
                    c0,0.2,0.1,0.5,0.2,0.7l2.9,2.6l-1.1,3.7c-0.2,0.4,0.1,0.9,0.6,1c0.2,0.1,0.4,0,0.7-0.1l3.3-1.8l3.3,1.8c0.4,0.3,0.9,0.1,1.2-0.3
                    c0.1-0.2,0.2-0.4,0.1-0.7l-1.1-3.7l2.9-2.6c0.3-0.3,0.4-0.8,0.1-1.2C31.3,39.4,31.1,39.3,30.8,39.3z M26.9,42.4
                    c-0.2,0.2-0.3,0.6-0.2,0.8l0.7,2.2l-1.9-1.1c-0.2-0.2-0.6-0.2-0.8,0l-1.9,1.1l0.7-2.2c0.1-0.3,0-0.7-0.2-0.8L21.4,41h1.9
                    c0.3,0,0.7-0.2,0.7-0.5l0.9-2l0.9,2c0.2,0.3,0.4,0.5,0.7,0.5h1.9L26.9,42.4z"></path>
                    <path d="M40,20.6v2.5h6.7v-2.5c0-1.4-1.1-2.5-2.5-2.5h-1.7C41.1,18.1,40,19.1,40,20.6z M41.7,20.6c0-0.5,0.3-0.8,0.8-0.8h1.7
                    c0.5,0,0.8,0.3,0.8,0.8v0.8h-3.3V20.6z"></path>
                    <path d="M6.7,10.6c1.4,0,2.5-1.1,2.5-2.5S8.1,5.6,6.7,5.6S4.2,6.6,4.2,8.1S5.2,10.6,6.7,10.6z M6.7,7.2c0.5,0,0.8,0.3,0.8,0.8
                    S7.2,8.9,6.7,8.9S5.8,8.6,5.8,8.1S6.2,7.2,6.7,7.2z"></path>
                    <path d="M10,14.7c0-1.4-1.1-2.5-2.5-2.5H5.8c-1.4,0-2.5,1.1-2.5,2.5v2.5H10V14.7z M8.3,15.6H5v-0.8c0-0.5,0.3-0.8,0.8-0.8h1.7
                    c0.5,0,0.8,0.3,0.8,0.8V15.6z"></path>
                  </svg>`,
					title: 'Manage ratings & feedback',
					text: 'Admin can moderate the star rating and reviews given by users and control any malicious activity, if needed.'
				}
			]
		};
	}
};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a.active .car-wash-feature-tab-icon,
#technologies_tabs li a:hover .car-wash-feature-tab-icon {
	fill: #fff;
	background: #008dd2;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
.car-wash-feature-tab-icon {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin: 0 auto 15px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a span {
	font-size: 16px;
	color: #202122;
	display: block;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.car-wash-feature-tab li {
	min-width: 180px;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li:last-child a {
	border-radius: 0 10px 10px 0;
}

.car-wash-feature-tab li a {
	border: 1px solid transparent;
	background-color: #fff;
	padding: 17px 10px;
	display: block;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 2px solid #e8e8e8;
}
a:hover {
	color: #008dd2;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border: 1px solid #008dd2;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border-bottom: 2px solid #008dd2;
}
.feature-tab li a:hover .car-wash-feature-tab-icon {
	background-color: #008dd2;
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a.active span,
.car-wash-feature-tab li a:hover span {
	color: #008dd2;
}
.car-wash-feature-tab li {
	padding: 0 !important;
}
.car-wash-feature-tab {
	border-bottom: 0;
	justify-content: center;
	display: flex;
}
@media (max-width: 776px) {
	#technologies_tabs {
		flex-wrap: wrap;
		justify-content: center;
	}
	.car-wash-feature-tab li {
		min-width: 60px;
	}
	#technologies_tabs li {
		width: fit-content;
		padding: 0;
		border-right: 1px solid #e0e2e3;
	}
	#technologies_tabs li a {
		width: fit-content;
		padding: 10px;
	}
	.car-wash-feature-tab-icon {
		width: 50px;
		height: 50px;
	}
	#technologies_tabs li a.active .car-wash-feature-tab-icon,
	#technologies_tabs li a:hover .car-wash-feature-tab-icon {
		background-color: transparent;
		fill: #0082dd;
	}
}
</style>
