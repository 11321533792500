<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Expertise, experience & commitment</h2>
				<p>
					Genixbit is driven by a burning passion to help clients reach their goals in the most
					efficient manner. To serve that purpose we bring together our collective expertise,
					experience and professional commitment.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
                {
                    title:"PHP Solution Expertise",
                    text:"Expertise that has been honed to perfection with over a decade long experience of serving a global client base.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 56 56" style="enable-background:new 0 0 56 56;" xml:space="preserve">
                        <g>
                           <path class="st0" d="M51,11.7C51,11.7,51,11.7,51,11.7C51,11.6,51,11.6,51,11.7c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
                           c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1-0.1L40.5,1
                           c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0
                           c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0H16c-2.3,0-4.2,1.9-4.2,4.2v4.3H8.4C6.5,9.3,5,10.8,5,12.6
                           v8.5c0,1.9,1.5,3.4,3.4,3.4h3.5v26.5c0,2.3,1.9,4.2,4.2,4.2h30.7c2.3,0,4.2-1.9,4.2-4.2L51,11.7C51,11.8,51,11.7,51,11.7z
                           M40.8,3.5l7.5,7.5h-4.9c-1.4,0-2.6-1.2-2.6-2.6V3.5z M6.6,21.2v-8.5c0-1,0.8-1.8,1.8-1.8h24.8c1,0,1.8,0.8,1.8,1.8v8.5
                           c0,1-0.8,1.8-1.8,1.8H12.6c0,0,0,0,0,0s0,0,0,0H8.4C7.4,22.9,6.6,22.1,6.6,21.2z M46.8,53.7H16c-1.4,0-2.6-1.2-2.6-2.6V24.5h19.7
                           c1.9,0,3.4-1.5,3.4-3.4v-8.5c0-1.9-1.5-3.4-3.4-3.4H13.4V4.9c0-1.4,1.2-2.6,2.6-2.6h23.1v6c0,2.3,1.9,4.2,4.2,4.2h6v38.5
                           C49.4,52.5,48.2,53.7,46.8,53.7z"></path>
                           <path class="st0" d="M35.7,44.3c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2
                           s0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C36.1,44.4,35.9,44.3,35.7,44.3z"></path>
                           <path class="st0" d="M10.9,18.6h2.2c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9h-3c-0.4,0-0.8,0.4-0.8,0.8v6.8c0,0.4,0.4,0.8,0.8,0.8
                           c0.4,0,0.8-0.4,0.8-0.8V18.6z M14.4,15.6c0,0.7-0.6,1.3-1.3,1.3h-2.2v-2.7h2.2C13.8,14.3,14.4,14.9,14.4,15.6z"></path>
                           <path class="st0" d="M27.1,21.1c0.4,0,0.8-0.4,0.8-0.8v-1.8h2.2c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9h-3c-0.4,0-0.8,0.4-0.8,0.8
                           v6.8C26.3,20.8,26.7,21.1,27.1,21.1z M31.5,15.6c0,0.7-0.6,1.3-1.3,1.3h-2.2v-2.7h2.2C30.9,14.3,31.5,14.9,31.5,15.6z"></path>
                           <path class="st0" d="M17.8,21.1c0.4,0,0.8-0.4,0.8-0.8v-1.8h4.4v1.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-6.8
                           c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8V17h-4.4v-3.5c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v6.8
                           C17,20.8,17.3,21.1,17.8,21.1z"></path>
                           <path class="st0" d="M29.4,32.6c-0.3-0.3-0.8-0.3-1.1,0l-6,6c-0.3,0.3-0.3,0.8,0,1.1l6,6c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2
                           c0.3-0.3,0.3-0.8,0-1.1L24,39.1l5.4-5.4C29.7,33.4,29.7,32.9,29.4,32.6z"></path>
                           <path class="st0" d="M35.7,32.3c-2.8,0-5.1,2.3-5.1,5.1c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8c0-1.9,1.6-3.5,3.5-3.5
                           c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-0.4,0-0.8,0.4-0.8,0.8v0.9c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-0.1
                           c2.4-0.4,4.3-2.5,4.3-5C40.8,34.6,38.5,32.3,35.7,32.3z"></path>
                        </g>
                     </svg>
                    `
                },
                {
                    title:"Secure Development",
                    text:"PHP development initiatives that puts client data integrity and security at the help.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M91.7,12.5l-1.8,0.3c0,0-2.4,0.3-6,0.3c-7.9,0-22.7-1.5-32.7-11.9L50,0l-1.2,1.2C38.8,11.5,24,13.1,16.1,13.1
                              c-3.6,0-6-0.3-6-0.3l-1.8-0.3v43.8c0,10.6,7.1,21.5,20.7,31.5C39,95.2,48.9,99.5,49.4,99.7L50,100l0.6-0.3
                              c0.4-0.2,10.3-4.5,20.4-11.9c13.5-10,20.7-20.9,20.7-31.5L91.7,12.5z M88.5,56.3c0,21.8-33.4,38-38.5,40.3
                              c-5.1-2.3-38.5-18.5-38.5-40.3V16c1.1,0.1,2.7,0.2,4.6,0.2c8.1,0,23.1-1.6,33.9-11.7c10.8,10.1,25.7,11.7,33.9,11.7
                              c1.9,0,3.5-0.1,4.6-0.2V56.3z M22.2,50.7l19,8.6v-4.1l-14.4-6.2V49l14.4-6.2v-4.1l-19,8.6V50.7z M44.1,60.9h4l8.5-28.1h-4
                              L44.1,60.9z M58.8,42.8L73.5,49v0.1l-14.7,6.2v4.1l19-8.5v-3.6l-19-8.5V42.8z"></path>
                           </g>
                        </g>
                     </svg>
                    `
                },
                {
                    title:"Proficient Developers",
                    text:"We have assembled a world-class team of PHP developers who can accomplish any challenging requirement.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 56 56" style="enable-background:new 0 0 56 56;" xml:space="preserve">
                        <path class="st0" d="M43.8,37.5l0.7-4.6c0,0,0-0.1,0-0.1c0-1.4-1.1-2.5-2.5-2.5h-6.2c-1.7-0.6-2.6-2.4-2.2-4.1
                        c2.6-1.6,4.4-4.2,4.9-7.2c0.8-1.6,4-8.1,0.4-12.3c-0.3-0.3-4.5-5.2-10.4-5.5c-3.4-0.1-6.6,1.3-9.6,4.3c-1.6,1.1-2.8,2.7-3.2,4.6
                        c-0.5,2.1,0.1,4.4,1.7,6.9c0,3.7,1.9,7.1,5.1,9.1c0.5,1.7-0.5,3.5-2.2,4.1h-6.4c-1.4,0-2.5,1.1-2.5,2.5c0,0,0,0.1,0,0.1l0.7,4.8
                        c-1.9,2.7-3,5.9-2.9,9.2v6.3c0,1.5,1.2,2.7,2.7,2.7h32.4c1.5,0,2.7-1.2,2.7-2.7v-6.3C46.9,43.1,45.9,40.1,43.8,37.5z M17.4,10.6
                        C17.8,9.1,18.7,7.8,20,7c0.1,0,0.1-0.1,0.2-0.1c2.6-2.7,5.4-4,8.3-3.9c5.2,0.2,9.1,4.8,9.1,4.8l0,0c2.6,2.9,0.6,7.9-0.3,9.7
                        c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1l-0.1-0.3c0,0,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2L36.5,16
                        c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.2-0.1-0.3l-0.1-0.4l0-0.2c0-0.2-0.1-0.5-0.1-0.8c0-1-0.5-1.9-1.3-2.5
                        c-0.1-0.1-0.3-0.2-0.4-0.3l-0.1,0c-0.1-0.1-0.3-0.1-0.5-0.2h-0.1c-0.1,0-0.3-0.1-0.4-0.1h-0.1c-0.2,0-0.3,0-0.5,0h-0.1
                        c-0.2,0-0.3,0-0.5,0C28,11.3,21.1,10.8,21,10.8c-0.5,0-0.9,0.3-0.9,0.8c0,0,0,0.1,0,0.1c0.2,1.6-0.1,3.3-0.8,4.8l-0.2-0.3
                        C17.6,14.2,17.1,12.3,17.4,10.6z M19.4,19L19.4,19c0.1,0,0.2,0,0.3-0.1h0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,1.9-2.1,1.9-6.1
                        c2.1,0.1,7.1,0.2,10.6-0.4c0.4-0.1,0.9,0,1.2,0.3c0.4,0.3,0.6,0.7,0.6,1.2c0,0.2,0,0.3,0,0.5v0.1l0,0.3c0,0.1,0,0.1,0,0.2
                        s0,0.2,0,0.3c0,0.1,0,0.1,0,0.2l0.1,0.3c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.1,0.5v0c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1
                        c0,0,0.1,0.2,0.1,0.3l0.1,0.1l0.1,0.3c0,0,0,0.1,0.1,0.1l0.2,0.3l0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.1,0.1,0.1,0.2,0.2,0.3
                        l0.1,0.1C36,18.9,36,19,36.1,19l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1l0.1,0.1l0,0c-0.6,2.2-2.1,4.2-4.1,5.3c-0.1,0-0.2,0.1-0.3,0.2
                        c-4.3,2.3-9.7,0.7-12-3.7c-0.4-0.7-0.6-1.4-0.8-2.2L19.4,19z M24.4,27c2.4,0.9,5,0.9,7.4,0c0,1.1,0.4,2.3,1.1,3.2h-9.5
                        C24,29.3,24.4,28.1,24.4,27z M13.9,32H42c0.4,0,0.7,0.3,0.8,0.7l-2.6,17.1c0,0,0,0.1,0,0.1c0,0.4-0.3,0.8-0.8,0.8c0,0,0,0,0,0H16.5
                        c-0.4,0-0.8-0.3-0.8-0.8c0,0,0,0,0,0c0,0,0-0.1,0-0.1l-2.6-17.1C13.2,32.3,13.5,32,13.9,32z M10.9,53.1v-6.3c0-2.3,0.5-4.5,1.6-6.5
                        L14,50c0,0.2,0.1,0.5,0.1,0.7h-0.5c-0.9,0-1.7,0.7-1.7,1.6c0,0,0,0,0,0V54h-0.2C11.3,54,10.9,53.6,10.9,53.1
                        C10.9,53.1,10.9,53.1,10.9,53.1L10.9,53.1z M13.8,54v-1.6h27.9V54L13.8,54z M45.1,53.1c0,0.5-0.4,0.9-0.9,0.9h-0.7v-1.7
                        c0-0.9-0.7-1.6-1.6-1.7c0.1-0.2,0.1-0.5,0.1-0.7l1.5-9.9c1.1,2.1,1.7,4.4,1.6,6.7L45.1,53.1z"></path>
                        <circle class="st0" cx="25" cy="15.6" r="1.4"></circle>
                        <circle class="st0" cx="31.3" cy="15.6" r="1.4"></circle>
                        <path class="st0" d="M31.3,21c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                        c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9c0,1.9,1.5,3.4,3.4,3.4C29.8,24.4,31.3,22.8,31.3,21z"></path>
                        <path class="st0" d="M23.8,46.6c0.2,0.2,0.4,0.3,0.6,0.3c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.4-0.3-0.6l-4-4l4-4
                        c0.4-0.3,0.4-0.9,0-1.3s-0.9-0.4-1.3,0c0,0,0,0,0,0l-4.6,4.6c-0.3,0.4-0.3,0.9,0,1.3L23.8,46.6z"></path>
                        <path class="st0" d="M31.2,37.3l4,4l-4,4c-0.3,0.3-0.3,0.9,0,1.3c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3l4.6-4.6
                        c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6L32.4,36c-0.4-0.3-0.9-0.3-1.3,0C30.9,36.4,30.9,37,31.2,37.3L31.2,37.3z"></path>
                        <path class="st0" d="M25.8,47.9c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.2,0.8-0.6l3.9-11.6c0.2-0.5-0.1-1-0.5-1.1c-0.5-0.2-1,0.1-1.1,0.5
                        l-3.9,11.6C25.1,47.3,25.4,47.8,25.8,47.9z"></path>
                     </svg>
                    `
                },
                {
                    title:"Customer Satisfaction",
                    text:"An inspired team of professionals who have vowed to deliver nothing but the best service for our customers.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M50,65.9c6.7,0,12.3-5.5,12.3-12.1c0-6.7-5.4-12.1-12.3-12.1c-6.7,0-12.3,5.5-12.3,12.1C37.7,60.6,43.3,65.9,50,65.9z
                              M50,44.8c5,0,9.2,4.1,9.2,9.1c0,5-4.1,9.1-9.2,9.1c-5,0-9.2-4.1-9.2-9.1C40.8,48.9,45,44.8,50,44.8z M35.5,12.5l6.1,6.2L40,27.3
                              c-0.2,0.6,0.2,1.2,0.6,1.5c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1L58,29c0.5,0.3,1.1,0.2,1.7-0.2c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6
                              l6.4-6.2c0.5-0.5,0.6-1.1,0.5-1.5c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2l-3.9-7.9c-0.5-1.1-2.3-1.1-2.7,0L45,8.7l-8.8,1.2
                              C35.6,10,35.2,10.4,35,11C34.9,11.6,35,12.2,35.5,12.5z M45.9,11.6c0.5,0,0.9-0.5,1.2-0.8L50,4.7l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8
                              l6.5,1.1L55.9,17c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5l-5.8-3c-0.2-0.2-0.5-0.2-0.8-0.2c-0.3,0-0.5,0-0.8,0.2l-5.8,3l1.1-6.5
                              c0.2-0.5-0.2-1.1-0.5-1.4l-4.7-4.6L45.9,11.6z M24.7,37.4c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6l6.4-6.2c0.5-0.5,0.6-1.1,0.5-1.5
                              c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2l-3.9-8c-0.3-0.5-0.8-0.9-1.4-0.9c-0.6,0-1.1,0.3-1.4,0.9L10,17l-8.8,1.2
                              c-0.6,0.2-1.1,0.5-1.2,1.1c-0.2,0.6,0,1.2,0.5,1.5l6.4,6.4l-1.5,8.6C5.2,36.4,5.5,37,6,37.4c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1
                              l7.9,4.1C23.7,37.8,24.3,37.7,24.7,37.4z M16.1,30.2c-0.5-0.3-0.9-0.3-1.4,0l-5.8,3l1.1-6.5c0.2-0.5-0.2-1.1-0.5-1.4l-4.7-4.6
                              l6.5-0.9c0.5,0,0.9-0.5,1.2-0.8l2.9-5.9l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8l6.5,0.9l-4.7,4.6c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5
                              L16.1,30.2z M99.9,19.5c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2L86,9.3c-0.5-1.1-2.3-1.1-2.7,0l-3.9,7.9l-8.8,1.2
                              c-0.6,0.2-1.1,0.5-1.2,1.1c-0.2,0.6,0,1.2,0.5,1.5l6.4,6.2l-1.5,8.6c-0.2,0.6,0.2,1.2,0.6,1.5c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1
                              l7.9,4.1c0.5,0.3,1.1,0.2,1.7-0.2c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6l6.4-6.2C99.9,20.5,100.1,19.9,99.9,19.5z M90.4,25.5
                              c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5l-5.8-3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.3,0-0.5,0-0.8,0.1l-5.8,3l1.1-6.5c0.2-0.5-0.2-1.1-0.5-1.4
                              L73.9,21l6.5-0.9c0.5,0,0.9-0.5,1.2-0.8l2.9-5.9l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8l6.5,0.9L90.4,25.5z M78.4,64.4h-1.4
                              c0.3-1.7,0.5-3.5,0.5-5.3c0-15.2-12.4-27.6-27.5-27.6S22.5,43.7,22.5,59c0,15.2,12.3,27.6,27.5,27.6c3.8,0,7.4-0.8,10.9-2.3
                              C62,93.2,69.4,100,78.4,100c9.8,0,17.7-8,17.7-17.7C96.2,72.4,88.3,64.4,78.4,64.4z M25.5,59.3c0-13.7,11-24.7,24.5-24.7
                              s24.5,10.9,24.5,24.6c0,2-0.3,3.9-0.8,5.8c-3.3,0.9-6.4,2.9-8.6,5.5c-2.9-2.4-6.4-3.6-10.1-3.6H45c-5.8,0-11,3.2-13.8,8.2
                              C27.6,70.6,25.5,65.1,25.5,59.3z M60.7,81.2c-8.9,4.4-19.7,3-27.2-3.9c2.1-4.5,6.5-7.4,11.5-7.4h10c3.2,0,6.1,1.1,8.3,3
                              C61.8,75.3,60.9,78.2,60.7,81.2z M78.4,97c-8.2,0-14.7-6.7-14.7-14.7c0-8,6.7-14.7,14.7-14.7c8,0,14.7,6.7,14.7,14.7
                              C93.1,90.3,86.6,97,78.4,97z M86.9,75.6l-11,9.7l-4.2-5c-0.6-0.6-1.5-0.8-2.1-0.2c-0.6,0.6-0.8,1.5-0.2,2.1l5.1,6.2
                              c0.3,0.3,0.6,0.5,1.1,0.6c0.5,0,0.8-0.2,1.1-0.3L88.9,78c0.6-0.6,0.8-1.5,0.2-2.1C88.4,75.1,87.5,75.1,86.9,75.6z"></path>
                           </g>
                        </g>
                     </svg>
                    `
                },
                {
                    title:"On Time Delivery",
                    text:"A well-defined project management plan combined with efficient project managers ensure that your projects are delivered on time, everytime.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M71.7,40.5V38h6.8c0.9,0,1.5-0.6,1.5-1.5v-7.9c0-0.9-0.6-1.5-1.5-1.5h-8V1.5c0-0.9-0.6-1.5-1.5-1.5
                              C63.2,0.2,9.2,0.2,1.5,0.2C0.6,0.2,0,0.8,0,1.7v67.2c0,0.9,0.6,1.5,1.5,1.5h38.9c0,16.3,13.3,29.7,29.8,29.7
                              c16.4,0,29.7-13.3,29.7-29.8C100,54.3,87.4,41.5,71.7,40.5z M77.1,30.3v4.8H63.6v-4.8H77.1z M47.3,3.2h20.2v14.1H47.3V3.2z
                              M26.2,3.2h18v22.7l-8-7c-0.6-0.5-1.4-0.5-2,0l-8,7V3.2z M3,3.2h20.2v14.1H3V3.2z M3,67.5V20.3h20.2V29c0,0.6,0.3,1.1,0.9,1.4
                              c0.6,0.3,1.2,0.2,1.7-0.3l9.5-8.3l9.5,8.3c0.5,0.5,1.1,0.5,1.7,0.3c0.6-0.3,0.9-0.8,0.9-1.4v-8.8h20.2v7h-5.5
                              c-0.9,0-1.5,0.6-1.5,1.5v7.9c0,0.9,0.6,1.5,1.5,1.5h6.7v2.6c-14.7,0.8-26.7,12.3-28.2,26.8H3z M70.2,97c-14.7,0-26.8-12-26.8-26.8
                              c0-14.7,12-26.8,26.8-26.8c14.7,0,26.8,12,26.8,26.8C97,85,85,97,70.2,97z M71.7,65.2V54.8c0-0.9-0.6-1.5-1.5-1.5
                              c-0.9,0-1.5,0.6-1.5,1.5v10.4c-2.1,0.6-3.6,2.6-3.6,5c0,0.9,0.2,1.7,0.6,2.4l-7,6.8c-0.6,0.6-0.6,1.5,0,2.1c0.6,0.6,1.5,0.6,2.1,0
                              l7-6.8c0.8,0.5,1.7,0.8,2.6,0.8c2.9,0,5.2-2.4,5.2-5.3C75.3,67.9,73.8,66,71.7,65.2z M70.2,72.5c-1.2,0-2.1-1.1-2.1-2.3
                              c0-1.2,0.9-2.3,2.1-2.3s2.1,1.1,2.1,2.3C72.3,71.6,71.4,72.5,70.2,72.5z M70.2,51.6c0.9,0,1.5-0.6,1.5-1.5v-3.3
                              c0-0.9-0.6-1.5-1.5-1.5c-0.9,0-1.5,0.6-1.5,1.5v3.3C68.6,51,69.4,51.6,70.2,51.6z M70.2,89c-0.9,0-1.5,0.6-1.5,1.5v3.3
                              c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5v-3.3C71.7,89.6,71.1,89,70.2,89z M93.6,68.7h-3.3c-0.9,0-1.5,0.6-1.5,1.5
                              c0,0.9,0.6,1.5,1.5,1.5h3.3c0.9,0,1.5-0.6,1.5-1.5C95.1,69.4,94.5,68.7,93.6,68.7z M51.5,70.2c0-0.9-0.6-1.5-1.5-1.5h-3.3
                              c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5H50C50.9,71.7,51.5,71.1,51.5,70.2z M79.7,54.2c0.8,0.5,1.7,0.1,2.1-0.6l1.7-2.9
                              c0.5-0.8,0.2-1.7-0.6-2.1c-0.8-0.5-1.7-0.2-2.1,0.6L79.1,52C78.8,52.8,78.9,53.7,79.7,54.2z M60.8,86.4c-0.8-0.5-1.7-0.2-2.1,0.6
                              L57,89.9c-0.5,0.8-0.2,1.7,0.6,2.1c0.8,0.5,1.7,0.2,2.1-0.6l1.7-2.9C61.7,87.7,61.4,86.7,60.8,86.4z M91.2,80.9l-2.9-1.7
                              c-0.8-0.5-1.7-0.2-2.1,0.6c-0.5,0.8-0.2,1.7,0.6,2.1l2.9,1.7c0.8,0.5,1.7,0.1,2.1-0.6C92.1,82.3,92,81.4,91.2,80.9z M49.2,59.6
                              l2.9,1.7c0.8,0.5,1.7,0.2,2.1-0.6c0.5-0.8,0.2-1.7-0.6-2.1l-2.9-1.7c-0.8-0.5-1.7-0.2-2.1,0.6C48.3,58.2,48.5,59.2,49.2,59.6z
                              M88.3,61.1l2.9-1.7c0.8-0.5,0.9-1.4,0.5-2.1c-0.5-0.8-1.4-0.9-2.1-0.5l-2.9,1.7c-0.8,0.5-0.9,1.4-0.5,2.1
                              C86.7,61.3,87.6,61.4,88.3,61.1z M52.1,79.4l-2.9,1.7c-0.8,0.5-0.9,1.4-0.5,2.1s1.4,0.9,2.1,0.5l2.9-1.7c0.8-0.5,0.9-1.4,0.5-2.1
                              C53.8,79.3,52.9,79,52.1,79.4z M82,86.7c-0.5-0.8-1.4-0.9-2.1-0.5c-0.8,0.5-0.9,1.4-0.5,2.1l1.7,2.9c0.5,0.8,1.4,0.9,2.1,0.5
                              c0.8-0.5,0.9-1.4,0.5-2.1L82,86.7z M60.5,54.3c0.8-0.5,0.9-1.4,0.5-2.1l-1.7-2.9c-0.5-0.8-1.4-0.9-2.1-0.5
                              c-0.8,0.5-0.9,1.4-0.5,2.1l1.7,2.9C58.8,54.5,59.8,54.8,60.5,54.3z M7.9,47.1l2.4-2.6v10.9c0,0.9,0.6,1.5,1.5,1.5
                              c0.9,0,1.5-0.6,1.5-1.5V44.5l2.4,2.6c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-5-5.3c-0.3-0.3-0.6-0.5-1.1-0.5
                              c-0.5,0-0.8,0.1-1.1,0.5l-5,5.3c-0.6,0.6-0.6,1.5,0,2.1C6.4,47.7,7.3,47.7,7.9,47.1z M23.3,47.1l2.4-2.6v10.9
                              c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5V44.5l2.4,2.6c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1l-5-5.3
                              c-0.3-0.3-0.6-0.5-1.1-0.5s-0.8,0.1-1.1,0.5l-5,5.3c-0.6,0.6-0.6,1.5,0,2.1C21.8,47.7,22.9,47.7,23.3,47.1z M32.4,58.4H6.8
                              c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h25.6c0.9,0,1.5-0.6,1.5-1.5C33.9,59.2,33.3,58.4,32.4,58.4z"></path>
                           </g>
                        </g>
                     </svg>
                    `
                },
                {
                    title:"Maintenance and Support",
                    text:"Genixbit takes pride in providing continued maintenance and support to our customers at any time and in any mode that they want.",
                    svg:`
                        <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M97.2,24.9c0-1.1-0.5-2.2-1.3-3c-0.8-0.9-2-1.3-3.2-1.3c-4.3,0.1-7.7,3.8-7.5,8.2l0.2,7.3l-1.2,5c-1.5,0-2.9,0.5-4,1.7
                              l-8,8.1l-2.8,0.5c-1.8,0.3-3.5,1.2-4.8,2.5l-5.5,5.6c-1.6,1.6-2.5,3.8-2.6,6.2l-0.2,9.3c-2.6,0.9-4.4,3.3-4.5,6.2l-0.1,12.1
                              c0,1.8,0.6,3.4,1.9,4.7c1.2,1.3,2.8,2,4.6,2L78,100h0.1c1.7,0,3.3-0.7,4.5-1.9c1.2-1.2,1.9-2.9,2-4.6l0.1-12.1
                              c0-1.8-0.6-3.4-1.9-4.7c-0.7-0.7-1.5-1.2-2.4-1.6l0-0.3c0.3-2.1,1.2-3.9,2.7-5.4l9.3-9.4c2.4-2.4,3.9-5.6,4.2-9l1-9.5
                              c0.1-0.3,0.1-0.7,0.1-1.1l0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2L97.2,24.9z M80.5,79c0.6,0.6,0.9,1.4,0.9,2.3l-0.1,12.1
                              c0,0.9-0.4,1.7-1,2.3c-0.6,0.6-1.4,0.9-2.3,0.9l-19.8-0.2c-0.9,0-1.7-0.4-2.3-1c-0.6-0.6-0.9-1.4-0.9-2.3L55.2,81
                              c0-1.8,1.5-3.2,3.2-3.2h0L78.2,78C79.1,78,79.9,78.4,80.5,79z M94.3,41.2c0,0,0,0.1,0,0.1l-1,9.3c-0.3,2.6-1.4,5.1-3.3,7L80.8,67
                              c-1.9,2-3.2,4.5-3.6,7.3l0,0.3l-17.5-0.2l0.2-8.9c0-1.5,0.6-2.8,1.6-3.9l5.5-5.6c0.8-0.8,1.9-1.4,3-1.6l3.3-0.6
                              c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.5-0.4c0,0,0,0,0,0l8.3-8.5c0.9-1,2.5-1,3.4,0c0.9,1,0.9,2.5,0,3.5l-9.6,9.7
                              c-0.6,0.6-0.6,1.7,0,2.4c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.2,1.2-0.5l9.6-9.7c2.2-2.3,2.2-5.9,0-8.2c-0.3-0.3-0.6-0.5-0.8-0.7
                              l1.3-5.4c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1l-0.2-7.5c-0.1-2.5,1.9-4.7,4.4-4.7
                              c0.3,0,0.6,0.1,0.8,0.3c0.1,0.1,0.3,0.4,0.3,0.8l0.5,15.2L94.3,41.2z M74.7,82.8c0,1.6,1.3,2.9,2.8,2.9c1.5,0,2.8-1.3,2.8-2.9
                              c0-1.6-1.3-2.9-2.8-2.9C75.9,80,74.7,81.3,74.7,82.8z M43.6,74.8l-0.2-9.3c-0.1-2.3-1-4.5-2.6-6.2l-5.5-5.6
                              c-1.3-1.3-3-2.2-4.7-2.5l-2.8-0.5l-8-8.1c-1.1-1.1-2.6-1.7-4-1.7l-1.2-5l0.2-7.3c0.1-4.4-3.2-8-7.6-8.2c-1.2,0-2.4,0.4-3.2,1.3
                              c-0.8,0.8-1.2,1.9-1.3,3L2.3,40.2c0,0.1,0,0.1,0,0.2v0l0,0c0,0.4,0,0.7,0.1,1.1l1,9.5c0.3,3.4,1.8,6.6,4.2,9l9.3,9.4
                              c1.4,1.5,2.4,3.3,2.7,5.4l0,0.3c-0.9,0.3-1.7,0.9-2.4,1.6c-1.2,1.3-1.9,2.9-1.9,4.7l0.1,12.1c0,1.8,0.7,3.4,2,4.6
                              c1.2,1.2,2.8,1.9,4.5,1.9H22l19.8-0.2c1.7,0,3.3-0.7,4.6-2c1.2-1.3,1.9-2.9,1.9-4.7L48.1,81C48,78.1,46.2,75.7,43.6,74.8z
                              M19.2,67l-9.3-9.4c-1.8-1.9-3-4.3-3.3-7l-1-9.3c0,0,0-0.1,0-0.1l-0.1-1.1L6,25c0-0.4,0.2-0.6,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
                              c2.5,0.1,4.4,2.2,4.4,4.7l-0.2,7.5c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1l1.3,5.4
                              c-0.3,0.2-0.6,0.4-0.8,0.7c-2.2,2.3-2.2,5.9,0,8.2l9.6,9.7c0.3,0.3,0.7,0.5,1.2,0.5c0.4,0,0.8-0.2,1.2-0.5c0.6-0.7,0.6-1.7,0-2.4
                              l-9.6-9.7c-0.9-1-0.9-2.5,0-3.5c0.9-1,2.5-1,3.4,0l8.3,8.5c0,0,0,0,0,0c0.1,0.1,0.3,0.3,0.5,0.4c0,0,0,0,0,0
                              c0.1,0,0.2,0.1,0.3,0.1l3.3,0.6c1.1,0.2,2.2,0.7,3,1.6l5.5,5.6c1,1,1.6,2.4,1.6,3.9l0.2,8.9l-17.5,0.2l-0.1-0.4
                              C22.4,71.5,21.1,69,19.2,67z M44,95.5c-0.6,0.6-1.4,1-2.3,1L22,96.7c-0.9,0-1.7-0.3-2.3-0.9c-0.6-0.6-1-1.4-1-2.3l-0.1-12.1
                              c0-0.9,0.3-1.7,0.9-2.3c0.6-0.6,1.4-1,2.3-1l19.8-0.2h0c1.8,0,3.2,1.4,3.2,3.2l0.1,12.1C44.9,94,44.6,94.8,44,95.5z M22.5,80
                              c-1.5,0-2.8,1.3-2.8,2.9c0,1.6,1.3,2.9,2.8,2.9c1.5,0,2.8-1.3,2.8-2.9C25.3,81.3,24.1,80,22.5,80z M28,31.6H31
                              c0.4,1.4,0.9,2.7,1.6,3.9l-2.2,2.2c-1.3,1.4-1.3,3.6,0,5l3.1,3.1c0.7,0.7,1.6,1,2.4,1c0.9,0,1.8-0.3,2.4-1l2.2-2.2
                              c1.2,0.7,2.5,1.2,3.8,1.6v3.1c0,1.9,1.5,3.5,3.5,3.5h4.3c1.9,0,3.5-1.6,3.5-3.5v-3.1c1.3-0.4,2.6-0.9,3.8-1.6l2.2,2.2
                              c0.7,0.7,1.6,1,2.4,1c0.9,0,1.8-0.3,2.4-1l3.1-3.1c1.3-1.4,1.3-3.6,0-5l-2.2-2.2c0.7-1.2,1.2-2.5,1.6-3.9H72
                              c1.9,0,3.5-1.6,3.5-3.5v-4.4c0-1.9-1.5-3.5-3.5-3.5h-3.1c-0.4-1.4-0.9-2.7-1.6-3.9l2.2-2.2c1.3-1.4,1.3-3.6,0-5L66.5,6
                              c-1.3-1.3-3.6-1.3-4.9,0l-2.2,2.2c-1.2-0.7-2.5-1.2-3.8-1.6V3.5c0-1.9-1.5-3.5-3.5-3.5h-4.3c-1.9,0-3.5,1.6-3.5,3.5v3.1
                              c-1.3,0.4-2.6,0.9-3.8,1.6L38.4,6c-1.3-1.3-3.6-1.3-4.9,0l-3.1,3.1c-1.3,1.4-1.3,3.6,0,5l2.2,2.2c-0.7,1.2-1.2,2.5-1.6,3.9H28
                              c-1.9,0-3.5,1.6-3.5,3.5v4.4C24.5,30.1,26.1,31.6,28,31.6z M27.8,23.7L27.8,23.7c0-0.1,0.1-0.2,0.2-0.2h4.3c0,0,0,0,0,0
                              c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0,0,0,0
                              c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0,0,0,0c0.4-1.9,1.1-3.7,2.2-5.3c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0.1-0.2,0.1-0.4,0.1-0.6
                              c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.6c0,0,0,0,0,0c-0.1-0.2-0.2-0.4-0.3-0.5c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-3-3.1
                              c-0.1-0.1-0.1-0.2,0-0.3l3.1-3.1c0.1-0.1,0.2-0.1,0.2,0l3.1,3.1c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0
                              c0.2,0.1,0.4,0.1,0.5,0.1h0c0.2,0,0.4,0,0.5-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2-0.1c1.6-1,3.3-1.8,5.2-2.2c0,0,0.1,0,0.1,0
                              c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1
                              c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
                              c0,0,0-0.1,0-0.1V3.5c0-0.1,0.1-0.2,0.2-0.2h4.3c0.1,0,0.2,0.1,0.2,0.2v4.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1
                              c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1
                              c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c1.9,0.4,3.6,1.2,5.2,2.2
                              c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.1,0.5,0.1h0c0.2,0,0.4,0,0.5-0.1c0,0,0.1,0,0.1,0
                              c0.2-0.1,0.3-0.2,0.5-0.3c0,0,0,0,0,0l3.1-3.1c0.1-0.1,0.2-0.1,0.2,0l3.1,3.1c0.1,0.1,0.1,0.2,0,0.2l-3.1,3.1c0,0,0,0,0,0l0,0
                              c0,0,0,0,0,0c-0.2,0.2-0.3,0.3-0.3,0.5c0,0,0,0,0,0c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0c0,0.2,0,0.4,0.1,0.6c0,0,0,0,0,0
                              c0,0.1,0.1,0.2,0.1,0.3c1,1.6,1.7,3.4,2.2,5.3c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0c0.1,0.1,0.2,0.3,0.3,0.4
                              c0,0,0,0,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0.1,0,0.1,0c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0,0H72c0.1,0,0.2,0.1,0.2,0.2v4.4
                              c0,0.1-0.1,0.2-0.2,0.2h-4.3c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
                              c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
                              c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c-0.4,1.9-1.1,3.7-2.2,5.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
                              c-0.1,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0c0,0.2,0,0.4,0.1,0.6c0,0,0,0,0,0c0.1,0.2,0.2,0.4,0.3,0.5c0,0,0,0,0,0l0,0c0,0,0,0,0,0
                              l3.1,3.1c0.1,0.1,0.1,0.2,0,0.2l-3.1,3.1c-0.1,0.1-0.2,0.1-0.2,0l-3-3.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.2-0.2-0.3-0.3-0.5-0.4
                              c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.6-0.1c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.1
                              c-1.6,1-3.3,1.8-5.2,2.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1
                              c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
                              c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1v4.4c0,0.1-0.1,0.2-0.2,0.2h-4.3c-0.1,0-0.2-0.1-0.2-0.2v-4.4
                              c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
                              c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1
                              c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c-1.9-0.4-3.6-1.2-5.2-2.2C41.1,40.1,41,40,40.9,40c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.6-0.1
                              c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.5,0.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-3,3.1
                              c-0.1,0.1-0.2,0.1-0.2,0l-3.1-3.1c-0.1-0.1-0.1-0.2,0-0.2l3.1-3.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.2-0.2,0.3-0.3,0.3-0.5
                              c0,0,0,0,0,0c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.6c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c-1-1.6-1.7-3.4-2.2-5.3
                              c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
                              c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0
                              c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0H28c-0.1,0-0.2-0.1-0.2-0.2V23.7z M50,38.2c6.6,0,12.1-5.5,12.1-12.3
                              c0-6.8-5.4-12.3-12.1-12.3c-6.6,0-12.1,5.5-12.1,12.3C37.9,32.7,43.4,38.2,50,38.2z M50,17c4.8,0,8.8,4,8.8,8.9
                              c0,4.9-3.9,8.9-8.8,8.9c-4.8,0-8.8-4-8.8-8.9C41.2,21,45.2,17,50,17z"></path>
                           </g>
                        </g>
                     </svg>
                    `
                },
            ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
