<template>
	<div class="fxt-app-why-box">
		<div class="fxt-app-why-box-icon" v-html="obj.svg"></div>
		<h4>{{ obj.title }}</h4>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.fxt-app-why-box {
	width: 100%;
	text-align: center;
	transition: all 0.3s ease;
	min-height: 150px;
  padding-top:50px;
	position: relative;
  user-select:none;
  cursor:pointer;
}

.fxt-app-why-box-icon {
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 20px;
	transition: all 0.3s ease;
	position: absolute;
	left: 50%;
	top: -35px;
	transform: translateX(-50%);
}
.fxt-app-why-box h4 {
	font-size: 18px;
	margin: 15px 0 10px;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
	line-height: 1.3;
	justify-content: center;
	width: 100%;
  font-size:18px !important;
  font-weight:bold
}
.fxt-app-why-box-icon svg circle,
.fxt-app-why-box-icon svg ellipse,
.fxt-app-why-box-icon svg path,
.fxt-app-why-box-icon svg polygon,
.fxt-app-why-box-icon svg rect {
	transition: all 0.3s ease;
	fill: #6a6d6f;
}
.fxt-app-why-box:hover .fxt-app-why-box-icon {
	background: #1977cc;
}
.fxt-app-why-box:hover h4{
  color:#1977cc
}

.fxt-app-why-box:hover .fxt-app-why-box-icon svg circle,
.fxt-app-why-box:hover .fxt-app-why-box-icon svg ellipse,
.fxt-app-why-box:hover .fxt-app-why-box-icon svg path,
.fxt-app-why-box:hover .fxt-app-why-box-icon svg polygon,
.fxt-app-why-box:hover .fxt-app-why-box-icon svg rect {
	fill: #fff;
}
</style>
