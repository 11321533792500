<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/car-wash-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						A smart way of on demand car wash app development
					</h2>
					<p>
						Building any app is a difficult project. Building an on demand car wash app is even more
						complex since it requires high-end planning, integrating APIs and many other
						intricacies. Our car wash app development services will help build an on demand car wash
						without breaking into a sweat.
					</p>
					<p>
						GenixBit Labs is made up of web developers, creative professionals and project managers who
						know how to make things happen — especially building an on demand car wash app. We have
						developed and launched several types of on demand apps that connect on demand service
						providers with customers.
					</p>
					<p>
						With on demand services becoming an integral part of our daily lives, an on demand car
						service is nothing but an essential that most users look forward to. An app that can
						crowdsource car wash service providers by location, help customers find them through a
						quick mobile search and also pay for the services through the ap is increasing in
						demand.
					</p>
					<p>
						As top-ranked mobile app developers, we can help you build the on demand car wash app on
						Android and iOS platforms. Talk to our experts to set the ball rolling.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
