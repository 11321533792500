<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/mvp-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Start Small, Scale Fast with Our MVP Development Services
					</h2>
					<p>
						If you have an idea that has to be tested for market potential before you start
						investing resources, MVP is the idea way of doing it. MVP app development ensures that
						you address the right questions before taking off on your pilot development. It makes
						you implore on questions like “Should this product be built?" instead of “Can this
						product be built?". The answers to the questions help in figuring out what kind of
						features the product must have, who will be its users, what are their pain areas and how
						the final product should look like.
					</p>
					<p>
						As a MVP development company, we will help you pick the right tools for development,
						handpick the features that will delight users and set up engagement models or monetizing
						models that will help your business open a new source of income.
					</p>
					<p>
						What makes our MVP app development process unique? We help you validate your idea early
						by conducting market studies and user research to help you evolve the right product. Our
						MVP developers with cross-platform development expertise and command over business logic
						will ensure that your MVP is ready for testing in less than 2 weeks. As a sum total of
						all these your business will be able to save a large amount of resources that would
						otherwise be required for full-scale product development. As a customer-centric MVP
						development company, we will ensure that your product is built for the right cause, has
						the right features and can be launched in the market at the right time when it will gain
						maximum momentum.
					</p>
					<p>
						Don’t go to market with the blind faith that your ‘well-conceived’ idea will succeed.
						Test the waters before making the plunge with MVP development. Let our MVP development
						help you steer forward in the right direction.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
