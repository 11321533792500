<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Services We Offer</span>
				<h2 class="fxt-process-dev">Our NodeJS Development Services</h2>
				<p>
					There is no substitute for professionalism. Backed by agile processes, our dedicated teams
					have built applications and dynamic webpages surpassing expectations.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-4" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../../MobileDevelopment/AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Nodejs Api Development And Integration",
               text:"Dynamic End to end Rest APIs",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M22.2,17.6l-1.1-0.2c-0.1-0.4-0.3-0.8-0.5-1.2l0.6-0.9c0.1-0.1,0.1-0.3,0-0.4l-1.4-1.4c-0.1-0.1-0.3-0.1-0.4,0
                        L18.4,14c-0.4-0.2-0.8-0.4-1.2-0.5L17,12.4c0-0.2-0.2-0.3-0.3-0.3h-2c-0.2,0-0.3,0.1-0.3,0.3l-0.2,1.1c-0.4,0.1-0.8,0.3-1.2,0.5
                        L12,13.3c-0.1-0.1-0.3-0.1-0.4,0l-1.4,1.4c-0.1,0.1-0.1,0.3,0,0.4l0.6,0.9c-0.2,0.4-0.4,0.8-0.5,1.2l-1.1,0.2
                        c-0.2,0-0.3,0.2-0.3,0.3v2c0,0.2,0.1,0.3,0.3,0.3l1.1,0.2c0.1,0.4,0.3,0.8,0.5,1.2l-0.6,0.9c-0.1,0.1-0.1,0.3,0,0.4l1.4,1.4
                        c0.1,0.1,0.3,0.1,0.4,0l0.9-0.6c0.4,0.2,0.8,0.4,1.2,0.5l0.2,1.1c0,0.2,0.2,0.3,0.3,0.3h2c0.2,0,0.3-0.1,0.3-0.3l0.2-1.1
                        c0.4-0.1,0.8-0.3,1.2-0.5l0.9,0.6c0.1,0.1,0.3,0.1,0.4,0l1.4-1.4c0.1-0.1,0.1-0.3,0-0.4l-0.6-0.9c0.2-0.4,0.4-0.8,0.5-1.2l1.1-0.2
                        c0.2,0,0.3-0.2,0.3-0.3v-2C22.5,17.7,22.4,17.6,22.2,17.6z M21.8,19.6l-1,0.2c-0.1,0-0.2,0.1-0.3,0.3c-0.1,0.5-0.3,1-0.6,1.4
                        c-0.1,0.1-0.1,0.3,0,0.4l0.6,0.8l-1,1l-0.9-0.6c-0.1-0.1-0.2-0.1-0.4,0c-0.5,0.3-0.9,0.5-1.4,0.6c-0.1,0-0.2,0.1-0.3,0.3l-0.2,1H15
                        l-0.2-1c0-0.1-0.1-0.2-0.3-0.3c-0.5-0.1-1-0.3-1.4-0.6c-0.1-0.1-0.3-0.1-0.4,0l-0.9,0.6l-1-1l0.6-0.8c0.1-0.1,0.1-0.3,0-0.4
                        c-0.3-0.5-0.5-0.9-0.6-1.4c0-0.1-0.1-0.2-0.3-0.3l-1-0.2v-1.4l1-0.2c0.1,0,0.2-0.1,0.3-0.3c0.1-0.5,0.3-1,0.6-1.4
                        c0.1-0.1,0.1-0.3,0-0.4l-0.6-0.9l1-1l0.9,0.6c0.1,0.1,0.3,0.1,0.4,0c0.4-0.3,0.9-0.5,1.4-0.6c0.1,0,0.2-0.1,0.3-0.3l0.2-1h1.4
                        l0.2,1c0,0.1,0.1,0.2,0.3,0.3c0.5,0.1,1,0.3,1.4,0.6c0.1,0.1,0.3,0.1,0.4,0l0.9-0.6l1,1l-0.6,0.9c-0.1,0.1-0.1,0.3,0,0.4
                        c0.3,0.4,0.5,0.9,0.6,1.4c0,0.1,0.1,0.2,0.3,0.3l1,0.2V19.6z"></path>
                     </g>
                     <g>
                        <path class="st0" d="M15.7,14.6c-2.4,0-4.3,1.9-4.3,4.2c0,2.3,1.9,4.3,4.2,4.3h0c1.2,0,2.3-0.5,3-1.3c0.8-0.8,1.2-1.8,1.2-3
                        C19.9,16.6,18.1,14.6,15.7,14.6z M18.8,17.2c0.2,0.4,0.4,0.8,0.4,1.3h-1.9c0-0.5-0.1-0.9-0.1-1.3H18.8z M18.4,16.6h-1.3
                        c-0.1-0.4-0.2-0.8-0.4-1.1C17.4,15.7,17.9,16.1,18.4,16.6z M15.7,15.3L15.7,15.3c0.2,0,0.5,0.4,0.7,1.2h-1.5
                        C15.2,15.8,15.5,15.3,15.7,15.3z M12.5,17.2h1.6C14,17.7,14,18.1,14,18.6h-1.9C12.2,18.1,12.3,17.6,12.5,17.2z M12.5,20.6
                        c-0.2-0.4-0.4-0.8-0.4-1.3H14c0,0.5,0.1,0.9,0.1,1.3H12.5z M13,21.2h1.3c0.1,0.4,0.2,0.8,0.4,1.1C14,22.1,13.4,21.7,13,21.2z
                        M14.2,16.6H13c0.7-0.8,1.4-0.9,1.6-1.1C14.5,15.8,14.3,16.2,14.2,16.6z M15.7,22.5L15.7,22.5c-0.2,0-0.5-0.4-0.7-1.2h1.5
                        C16.2,22,15.9,22.5,15.7,22.5z M16.6,20.6h-1.8c-0.1-0.4-0.1-0.8-0.1-1.3h2C16.7,19.7,16.6,20.2,16.6,20.6z M14.7,18.6
                        c0-0.5,0.1-0.9,0.1-1.3h1.8c0.1,0.4,0.1,0.8,0.1,1.3H14.7z M16.7,22.3c0.2-0.3,0.3-0.7,0.4-1.1h1.3C17.6,22.1,16.8,22.3,16.7,22.3z
                        M17.3,20.6c0.1-0.4,0.1-0.9,0.1-1.3h1.9c0,0.5-0.2,0.9-0.4,1.3H17.3z"></path>
                     </g>
                     <g>
                        <path class="st0" d="M42.1,5.9H6c-0.4,0-0.8,0.4-0.8,0.8c0.1,10.3,0,16.3,0,20c0,4.2-0.1,5,0.3,5.3c0.2,0.2,0.4,0.2,0.5,0.2h23.2
                        v-1.6H6.8V7.5h34.4v10h1.6V6.7C42.9,6.3,42.5,5.9,42.1,5.9z"></path>
                        <path class="st0" d="M45.1,2.9H3c-0.4,0-0.8,0.4-0.8,0.8v29.9H1.4c-0.4,0-0.8,0.4-0.8,0.8c0,2.6,2.1,4.8,4.8,4.8h23.9v-1.6H5.3
                        c-1.5,0-2.7-1-3.1-2.4h0c2.3,0,15.4,0,26.7,0h0.3v-1.6H3.8V4.5h40.5v13h1.6V3.7C45.9,3.3,45.5,2.9,45.1,2.9z"></path>
                        <path class="st0" d="M22.3,35.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h3.5c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
                        H22.3z"></path>
                     </g>
                     <g>
                        <g>
                           <rect x="38" y="46" class="st0" width="2.7" height="1.4"></rect>
                        </g>
                        <g>
                           <path class="st0" d="M47.4,49.9H31.1c-1.2,0-2-0.9-2-2V19.4c0-1.2,0.9-2,2-2h16.3c1.2,0,2,0.9,2,2v28.5
                           C49.4,49,48.5,49.9,47.4,49.9z M31.1,18.7c-0.4,0-0.7,0.3-0.7,0.7v28.5c0,0.4,0.3,0.7,0.7,0.7h16.3c0.4,0,0.7-0.3,0.7-0.7V19.4
                           c0-0.4-0.3-0.7-0.7-0.7H31.1z"></path>
                        </g>
                        <g>
                           <rect x="36.7" y="20.3" class="st0" width="5.4" height="1.4"></rect>
                        </g>
                        <g>
                           <rect x="30.2" y="43.1" class="st0" width="19" height="1.4"></rect>
                        </g>
                        <g>
                           <rect x="30.2" y="22.8" class="st0" width="19" height="1.4"></rect>
                        </g>
                     </g>
                     <g>
                        <path class="st0" d="M34.5,34.6L34.2,36h-1.3l1.7-5.5h1.6l1.7,5.5h-1.3l-0.4-1.4H34.5z M35.9,33.7l-0.3-1.2
                        c-0.1-0.3-0.2-0.7-0.3-1.1h0c-0.1,0.3-0.2,0.7-0.3,1.1l-0.3,1.2H35.9z"></path>
                        <path class="st0" d="M38.6,30.6c0.4-0.1,0.9-0.1,1.7-0.1c0.8,0,1.3,0.1,1.7,0.4c0.4,0.3,0.6,0.7,0.6,1.3s-0.2,1-0.5,1.3
                        c-0.4,0.4-1,0.6-1.8,0.6c-0.2,0-0.3,0-0.4,0v2h-1.2V30.6z M39.8,33.1c0.1,0,0.2,0,0.4,0c0.7,0,1.1-0.3,1.1-0.9c0-0.5-0.3-0.8-1-0.8
                        c-0.3,0-0.4,0-0.5,0V33.1z"></path>
                        <path class="st0" d="M44.6,30.5V36h-1.2v-5.5H44.6z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Real Time Apps With Nodejs",
               text:"Integration of various library modules in the application",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 64 80" style="enable-background:new 0 0 64 80;" xml:space="preserve">
                     <g>
                        <path d="M48,80c2.9,0,5.3-2.4,5.3-5.3V5.3C53.3,2.4,50.9,0,48,0H16c-2.9,0-5.3,2.4-5.3,5.3v69.3c0,2.9,2.4,5.3,5.3,5.3H48z
                        M40.5,66.7L28.9,55.1c-0.5-0.5-1.4-0.5-1.9,0c0,0,0,0,0,0L15.5,66.7h-2.1V53.9l6.3-6.3c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0
                        l-6.3-6.3v-6.9l9.7,9.7c0.5,0.5,1.4,0.5,1.9,0c0,0,0,0,0,0l25.7-25.7v14.9L32.4,49.7c-0.5,0.5-0.5,1.4,0,1.9c0,0,0,0,0,0l15.1,15.1
                        H40.5z M36.8,66.7H19.2l8.8-8.8L36.8,66.7z M23.6,13.3c0.1,1.4,0.6,2.7,1.3,3.8l3.2,4.8l-3.8,3.8c-0.5,0.5-0.5,1.4,0,1.9
                        c0,0,0,0,0,0l5.8,5.7L24,39.5L13.3,28.8V13.3H23.6z M40.4,13.3h9.7l-7.5,7.5l-3.6-3.6C39.8,16,40.3,14.7,40.4,13.3z M39.9,55.3
                        l2.8-2.8l3.5,3.5l-2.8,2.8L39.9,55.3z M48.9,56.9c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0l-5.3-5.3c-0.5-0.5-1.4-0.5-1.9,0c0,0,0,0,0,0
                        L38,53.5l-2.8-2.8l15.5-15.5v30.9l-5.5-5.5L48.9,56.9z M32,31.5l-4.8-4.8l2.4-2.4l1.2,1.8c0.4,0.6,1.2,0.8,1.9,0.4
                        c0.1-0.1,0.3-0.2,0.4-0.4l4.4-6.7l3.2,3.3L32,31.5z M36.8,15.7L32,22.9l-4.8-7.2c-1.8-2.7-1.1-6.3,1.6-8s6.3-1.1,8,1.6
                        C38.1,11.2,38.1,13.7,36.8,15.7z M13.3,50.1v-6.9l3.5,3.5L13.3,50.1z M16,2.7h32c1.5,0,2.7,1.2,2.7,2.7v5.3H40.3
                        c-0.2-0.8-0.4-1.5-0.8-2.2C37.2,4.3,32.1,2.8,28,5c-1.5,0.8-2.7,2-3.4,3.4c-0.4,0.7-0.6,1.4-0.8,2.2H13.3V5.3
                        C13.3,3.9,14.5,2.7,16,2.7z M13.3,74.7v-5.3h37.3v5.3c0,1.5-1.2,2.7-2.7,2.7H16C14.5,77.3,13.3,76.1,13.3,74.7z"></path>
                        <rect x="26.7" y="72" width="10.7" height="2.7"></rect>
                        <circle cx="32" cy="12" r="2.7"></circle>
                        <path d="M19.6,53.4l-4,1.3c-0.4,0.1-0.8,0.5-0.9,1c-0.1,0.4,0,0.9,0.3,1.3l2.7,2.7c0.3,0.2,0.6,0.4,0.9,0.4c0.1,0,0.2,0,0.3,0
                        c0.4-0.1,0.8-0.4,1-0.9l1.3-4c0.2-0.7-0.1-1.5-0.8-1.7C20.2,53.3,19.9,53.3,19.6,53.4L19.6,53.4z"></path>
                        <rect x="23.4" y="49.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -28.4058 32.7526)" width="3.8" height="2.7"></rect>
                        <rect x="28.8" y="44" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -23.066 34.959)" width="3.8" height="2.7"></rect>
                        <rect x="34.1" y="38.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.7357 37.1694)" width="3.8" height="2.7"></rect>
                        <path d="M40.9,36.9l2.7-2.7c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0l-2.7-2.7l-1.9,1.9l1.7,1.7l-1.7,1.7L40.9,36.9z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Nodejs Product Support And Maintenance",
               text:"Indepth knowledge of NodeJS architecture helps deliver comprehensive support",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path d="M48.6,30.2l-4-2.8c0.2-1.7,0.2-3,0-4.7l4-2.7c1.3-0.8,1.8-2.5,1.1-3.8l-4.4-7.6c-0.8-1.3-2.5-1.8-3.7-1.1l-4.4,2
                        c-1.2-0.9-2.4-1.6-4-2.3l-0.4-4.8c0-1.5-1.3-2.9-2.8-2.9h-8.9c-1.5,0-2.8,1.2-2.8,2.7l-0.4,4.9c-1.4,0.6-2.7,1.3-4,2.3l-4.3-2
                        C8.9,7.2,8.1,7.1,7.4,7.3C6.6,7.5,6,8,5.7,8.7l-4.4,7.6c-0.8,1.3-0.3,3,1,3.8l4,2.8c-0.2,1.7-0.2,2.9,0,4.6l-3.9,2.8
                        C1,31,0.6,32.7,1.3,34l4.4,7.6c0.3,0.6,0.9,1,1.7,1.3c0.7,0.2,1.5,0.1,2-0.3l4.4-2c1.3,1,2.7,1.8,4,2.3l0.4,4.8
                        c0,1.5,1.3,2.9,2.8,2.9h8.8c1.5,0,2.8-1.2,2.8-2.7l0.4-4.8c1.4-0.6,2.7-1.3,4-2.3l4.3,2c0.6,0.4,1.4,0.5,2.1,0.3
                        c0.7-0.2,1.4-0.7,1.7-1.4l4.4-7.6c0.5-0.7,0.6-1.5,0.5-2.3C49.7,31.2,49.3,30.6,48.6,30.2z M3.2,32.3c0-0.1,0.1-0.2,0.3-0.3l4.5-3
                        c0.4-0.3,0.6-0.7,0.5-1.1c-0.3-2-0.3-3.4,0-5.3C8.6,22,8.4,21.6,8,21.3l-4.7-3.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.1-0.5
                        l4.4-7.6c0.2-0.2,0.2-0.2,0.3-0.2l0.1,0c0.1,0,0.2,0,0.5,0.1l5,2.3c0.4,0.2,0.9,0.1,1.3-0.1c1.3-1.1,2.7-1.9,4.6-2.7
                        C19.8,9.1,20,8.6,20,8.2l0.5-5.7C20.5,2.3,20.7,2,21,2h8.8c0.3,0,0.5,0.3,0.6,0.6l0.5,5.5c0,0.5,0.4,0.8,0.7,0.9
                        c2,0.8,3.4,1.7,4.6,2.7c0.4,0.3,0.8,0.3,1.2,0.1l5.1-2.4c0.1-0.1,0.3-0.1,0.4,0c0.2,0,0.3,0.1,0.3,0.2l4.4,7.6
                        c0.2,0.3,0.1,0.6-0.3,0.8l-4.4,3.2c-0.4,0.3-0.6,0.7-0.5,1.1c0.3,2,0.3,3.3,0,5.3c-0.1,0.4,0.1,0.9,0.5,1.2l4.5,3.2l0.2,0.1
                        c0.2,0.2,0.2,0.4,0.1,0.6l-4.4,7.6c-0.2,0.4-0.8,0.2-0.9,0.2l-5-2.3c-0.3-0.2-0.8-0.2-1.2,0.1c-1.4,1.1-2.8,1.9-4.6,2.7
                        c-0.5,0.1-0.7,0.6-0.7,0.9l-0.5,5.6c0,0.3-0.3,0.6-0.5,0.6h-8.8c-0.3,0-0.5-0.3-0.6-0.6L20,42c0-0.3-0.1-0.7-0.7-1
                        c-1.6-0.6-3.2-1.5-4.6-2.7c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.3,0-0.5,0.1l-5.1,2.4c-0.1,0.1-0.2,0.1-0.4,0
                        c-0.2-0.1-0.3-0.2-0.4-0.2l-4.4-7.6C3.2,32.6,3.1,32.5,3.2,32.3z"></path>
                        <path d="M25.4,38.4c7.4,0,13.3-6,13.3-13.3s-6-13.3-13.3-13.3s-13.3,6-13.3,13.3S18.1,38.4,25.4,38.4z M30.9,19
                        c-2.2-1.9-5.1-2.5-7.9-1.6c-0.5,0.1-0.8,0.6-0.8,1.1c0,0.3,0.1,0.6,0.3,0.8l3,3v2.9h-2.9l-3-3c-0.2-0.2-0.7-0.3-1-0.3l-0.1,0
                        c-0.4,0.1-0.7,0.4-0.8,0.8C17,25.2,17.4,27.9,19,30c1.6,2.1,4,3.4,6.6,3.4c0.5,0,1.1-0.1,1.6-0.2l2.2,2.3c-1.2,0.4-2.5,0.6-3.8,0.6
                        c-6.1,0-11.1-5-11.1-11.1S19.5,14,25.6,14s11.1,5,11.1,11c-0.1,1.4-0.4,2.7-0.8,3.8l-2.2-2.1C34.2,24,33.1,20.9,30.9,19z M34.8,31
                        c-1,1.5-2.2,2.6-3.5,3.4l-1.9-2c0.8-0.4,1.4-0.9,1.9-1.4c0.6-0.6,1.1-1.2,1.4-1.9L34.8,31z M23.1,30.6c-2.1-1-3.3-3-3.4-5.2
                        l1.8,1.8c0.2,0.2,0.5,0.3,0.8,0.3h4.5c0.5,0,1.1-0.4,1.1-1.1v-4.5c0-0.3-0.1-0.7-0.3-0.9l-1.8-1.8c2.2,0,4.2,1.3,5.2,3.4
                        c0.9,1.8,1.1,4.6-1.1,6.8C27.6,31.6,24.9,31.4,23.1,30.6z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Nodejs Version Migration",
               text:"Comprehensive support for smooth version migration",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g transform="translate(0,-952.36218)">
                        <path d="M1.7,953.7c-0.4,0-0.9,0.1-1.2,0.5S0,955,0,955.4v20.5c0,0.4,0.1,0.9,0.5,1.2c0.4,0.3,0.8,0.5,1.2,0.5h9.7
                        c0.6,0,1.2-0.5,1.2-1.1s-0.6-1.1-1.2-1.1H2.3V956H33v4.5c0,0.6,0.5,1.2,1.1,1.2c0.6,0,1.1-0.6,1.1-1.2v-5.1c0-0.4-0.1-0.9-0.5-1.2
                        s-0.8-0.5-1.2-0.5H1.7z M6.2,958.2c-0.5,0-0.9,0.2-1.2,0.5s-0.6,0.7-0.6,1.2v11.4c0,1,0.8,1.7,1.7,1.7h6.8c0.6,0,1.2-0.5,1.2-1.1
                        s-0.6-1.1-1.2-1.1H6.8v-10.2h21.6v1.7c0,0.6,0.5,1.2,1.1,1.2s1.1-0.6,1.1-1.2v-2.3c0-0.5-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.6-1.3-0.6
                        H6.2z M40.8,965c-0.4,0-0.8,0.4-1,0.8c-0.1,0.4,0,0.9,0.3,1.2l2,2c-8,0-16.1,0-24.1,0c-0.6,0-1.1,0.6-1.1,1.2
                        c0,0.6,0.6,1.1,1.2,1.1h24l-2,2c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0l4-4c0.4-0.4,0.4-1.2,0-1.6l-4-4
                        C41.5,965.1,41.1,965,40.8,965z M38.5,977.6c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1h9.1v6.8c0,0.6,0.5,1.2,1.1,1.2
                        c0.6,0,1.1-0.6,1.1-1.2v-7.4c0-0.4-0.1-0.9-0.5-1.2s-0.8-0.5-1.2-0.5C45,977.6,41.8,977.6,38.5,977.6z M8.9,979.8
                        c-0.2,0-0.5,0.2-0.7,0.3l-4,4c-0.4,0.4-0.4,1.2,0,1.6l4,4c0.4,0.4,1.2,0.4,1.6,0c0.4-0.4,0.4-1.2,0-1.6l-2-2h24
                        c0.6,0,1.2-0.5,1.2-1.1s-0.6-1.1-1.2-1.1h-24l2-2c0.3-0.3,0.5-0.9,0.2-1.3C9.9,980,9.4,979.7,8.9,979.8z M36.8,982.1
                        c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1h6.2v10.2H21.6v-1.7c0-0.6-0.5-1.2-1.1-1.2s-1.1,0.6-1.1,1.2v2.3
                        c0,0.5,0.2,0.8,0.4,1.1c0.3,0.3,0.7,0.6,1.3,0.6h22.7c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.6-0.6,0.6-1.2v-11.4c0-1-0.8-1.7-1.7-1.7
                        C41.4,982.1,39.1,982.1,36.8,982.1z M48.7,990c-0.6,0.1-1,0.6-1,1.2v8H17v-4.5c0-0.6-0.5-1.2-1.1-1.2s-1.1,0.6-1.1,1.2v5.1
                        c0,0.4,0.1,0.9,0.5,1.2c0.4,0.3,0.8,0.5,1.2,0.5h31.8c0.4,0,0.9-0.1,1.2-0.5c0.4-0.3,0.5-0.8,0.5-1.2v-8.5
                        C50,990.6,49.4,990,48.7,990z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Nodejs Cms Development",
               text:"Slash development time with powerful API",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path d="M-0.3,1.9l-0.1,46.4l50.3,0.1l0.4,0l0.1-46.1l0-0.4L-0.3,1.9z M1.5,7.8l0-4l47,0.1l0,4L1.5,7.8z M1.5,46.6L1.5,9.7l47,0.1
                        l-0.1,36.9L1.5,46.6z"></path>
                        <rect x="40.3" y="5" transform="matrix(1.588981e-03 -1 1 1.588981e-03 35.2858 47.1597)" width="1.8" height="1.8"></rect>
                        <rect x="43.2" y="5" transform="matrix(1.588981e-03 -1 1 1.588981e-03 38.1747 50.0631)" width="1.8" height="1.8"></rect>
                        <rect x="37.4" y="5" transform="matrix(1.588981e-03 -1 1 1.588981e-03 32.3968 44.2564)" width="1.8" height="1.8"></rect>
                        <rect x="18.6" y="-9.2" transform="matrix(1.688940e-03 -1 1 1.688940e-03 13.6167 25.3839)" width="1.8" height="30.1"></rect>
                        <path d="M35.6,35.7l-1.5-2.2c0.4-0.8,0.7-1.6,0.9-2.3l2.6-0.5l0-4.6l-2.6-0.5c-0.2-0.8-0.6-1.6-1-2.4l1.5-2.2l-3.3-3.3L30,19.2
                        c-0.8-0.4-1.6-0.7-2.3-0.9l-0.5-2.6l-4.6,0l-0.5,2.6c-0.8,0.2-1.6,0.6-2.4,1l-2.2-1.5L14.3,21l1.5,2.2c-0.4,0.8-0.7,1.6-0.9,2.3
                        l-2.6,0.5l0,4.6l2.6,0.5c0.2,0.8,0.6,1.6,1,2.4l-1.5,2.2l3.3,3.3l2.2-1.5c0.8,0.5,1.7,0.8,2.4,0.9l0.5,2.6l4.6,0l0.5-2.6
                        c0.8-0.2,1.6-0.6,2.4-1l2.2,1.5L35.6,35.7z M24.2,39.2l-0.4-2.3l-0.6-0.1c-1.1-0.2-2-0.6-2.8-1.2l-0.5-0.4l-2,1.3l-1.1-1.1l1.4-2
                        L17.7,33c-0.6-0.8-0.9-1.8-1.2-2.9l-0.1-0.6l-2.3-0.4l0-1.5l2.3-0.4l0.1-0.6c0.2-1.1,0.6-2,1.2-2.8l0.4-0.5l-1.4-2l1.1-1.1l1.9,1.4
                        l0.5-0.4c0.8-0.6,1.8-0.9,2.9-1.2l0.6-0.1l0.4-2.3l1.5,0l0.4,2.3l0.6,0.1c1.1,0.2,2,0.6,2.8,1.2l0.5,0.4l2-1.3l1.1,1.1l-1.4,1.9
                        l0.4,0.5c0.6,0.8,0.9,1.8,1.2,2.9l0.1,0.6l2.3,0.4l0,1.5l-2.4,0.5l0.1,0.7c-0.2,1.1-0.6,2-1.1,2.8l-0.4,0.5l1.3,2l-1.1,1.1
                        l-1.9-1.3l-0.5,0.4c-0.8,0.6-1.8,0.9-2.9,1.2l-0.6,0.1l-0.5,2.3L24.2,39.2z"></path>
                        <path d="M30.7,28.3c0-3.2-2.6-5.8-5.8-5.8c0,0,0,0,0,0c-1.5,0-3,0.6-4.1,1.7c-1.1,1.1-1.7,2.6-1.7,4.1c0,1.5,0.6,3,1.7,4.1
                        c1.1,1.1,2.5,1.7,4.1,1.7c0,0,0,0,0,0C28.1,34.1,30.7,31.5,30.7,28.3z M24.9,24.4C24.9,24.4,24.9,24.4,24.9,24.4
                        c2.2,0,3.9,1.8,3.9,4c0.1,1-0.3,2-1,2.7c-0.7,0.8-1.8,1.2-2.8,1.2c0,0,0,0,0,0c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.7-1.2-1.7-1.2-2.8
                        c0-1.1,0.4-2.1,1.2-2.8C22.8,24.8,23.8,24.4,24.9,24.4z"></path>
                        <polygon points="9.1,14.6 18.4,14.6 18.4,12.7 7.3,12.7 7.3,21.3 9.1,21.3   "></polygon>
                        <polygon points="9.1,35.3 7.3,35.3 7.2,43.9 18.4,44 18.4,42.1 9.1,42.1  "></polygon>
                        <polygon points="40.7,42.2 31.4,42.1 31.4,44 42.6,44 42.6,35.4 40.7,35.4   "></polygon>
                        <polygon points="31.5,14.6 40.8,14.6 40.8,21.3 42.6,21.3 42.6,12.8 31.5,12.8  "></polygon>
                     </g>
                  </svg>
               `
            },
            {
               title:"Nodejs Web App Development",
               text:"Dynamic website server side programming for building applications",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M22.3,49.5l10.9,11c0.6,0.6,1.6,0.6,2.2,0c0.6-0.6,0.6-1.6,0-2.2l-9.8-9.9l9.8-9.9c0.6-0.6,0.6-1.6,0-2.2
                           c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l-10.9,11C21.7,47.9,21.7,48.9,22.3,49.5C22.3,49.5,22.3,49.5,22.3,49.5z M41.5,67.2
                           c0.8,0.4,1.7,0.1,2.1-0.7c0,0,0-0.1,0.1-0.1l15.6-34.6c0.4-0.8,0-1.7-0.8-2.1c0,0,0,0,0,0c-0.8-0.4-1.7,0-2.1,0.8c0,0,0,0,0,0
                           L40.8,65.1C40.4,65.8,40.7,66.8,41.5,67.2z M74.4,48.4l-9.8,9.9c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l10.9-11
                           c0.6-0.6,0.6-1.6,0-2.2c0,0,0,0,0,0l-10.9-11c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2L74.4,48.4z M95.3,6H4.7
                           C2.1,6,0,8.1,0,10.7v66c0,2.6,2.1,4.7,4.7,4.7h43.8v9.4H24.2c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h51.6
                           c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6H51.6v-9.4h43.8c2.6,0,4.7-2.1,4.7-4.7v-66C100,8.1,97.9,6,95.3,6z M96.9,76.7
                           c0,0.9-0.7,1.6-1.6,1.6H4.7c-0.9,0-1.6-0.7-1.6-1.6v-55h93.8V76.7z M96.9,18.6H3.1v-7.9c0-0.9,0.7-1.6,1.6-1.6h90.6
                           c0.9,0,1.6,0.7,1.6,1.6V18.6z M90.6,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C92.2,13,91.5,12.3,90.6,12.3z M84.4,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C85.9,13,85.2,12.3,84.4,12.3z M78.1,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C79.7,13,79,12.3,78.1,12.3z"></path>
                        </g>
                     </g>
                  </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
