<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Advantages</span>
				<h2>Advantages of AngularJS Web Development</h2>
				<p>
					Knowledge and exposure to the right kind of projects of the right size determine the
					ability of teams. Our teams are credited with some of the best solutions.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-4 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Optimize Security',
					text: 'Server agnostic framework renders superior security',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M94.5,38.2c-0.8-0.6-1.7-1.3-1.9-1.4c-0.4-0.2-0.4-0.3,0-1.8c1-4.6,0.4-9.2-1.6-13.5
                                 c-3.2-6.8-9.4-11.5-17-13.2c-1.9-0.4-6.5-0.5-8.2-0.3c-3.6,0.6-7.4,2.1-10.4,4.1c-0.9,0.6-2.3,1.8-3.2,2.7l-1.7,1.6L49,16
                                 c-2.7-1.2-3.5-1.4-6.1-1.4c-1.9,0-2.8,0.1-4,0.5c-6.1,1.7-10.3,6.6-11.1,12.7l-0.1,1.1l-1.2-0.5c-1.4-0.6-2.7-0.8-4.3-0.5
                                 c-2.8,0.6-5.5,2.6-6.8,5.2l-0.7,1.4l-1.4,0.1c-3.3,0.3-6.5,1.9-9.1,4.6c-2.1,2.1-3.4,4.5-4,7.3c-1.3,6.4,2.2,13.1,8.3,16
                                 c2.4,1.1,3.6,1.4,7.4,1.5l3.4,0.1l0.1,0.8c0.2,1.3,0.8,3.5,0.9,3.7c0.1,0.1,2.9-0.5,3.1-0.7c0.1,0,0-0.6-0.2-1.2
                                 c-0.2-0.6-0.4-1.4-0.4-1.9L22.7,64h10.6v15.5l0.5,0.5l0.5,0.5H69l0.5-0.5l0.5-0.5V64h5.3c4.3,0,5.3,0.1,5.3,0.3
                                 c0,0.2,0.5,0.3,1.5,0.5c1.6,0.3,1.9,0.2,1.9-0.4c0-0.3,0.3-0.4,1.7-0.5c6-0.5,11-4,13.3-9.4C101.4,48.9,99.5,42.1,94.5,38.2z
                                 M66.6,65.6v11.5h-30V54.1h30L66.6,65.6z M43.3,50.8v-3c0-4.3,0.5-5.8,2.5-7.8c1.8-1.8,3.7-2.5,6.3-2.4c0.9,0.1,2,0.3,2.5,0.4
                                 c2,0.7,4.2,2.9,4.9,4.9c0.3,0.7,0.4,1.9,0.5,4.4l0.1,3.5H43.3z M96.1,52.7c-1,2.9-3.2,5.3-5.9,6.7c-1.5,0.7-3.8,1.3-5.1,1.3
                                 c-0.7,0-0.7,0-0.7-1c0-1.1-0.6-5.4-0.7-5.5c0-0.1-0.8,0-1.7,0.2c-1.6,0.3-1.6,0.3-1.5,0.8c0.1,0.3,0.2,1.7,0.2,3l0.1,2.5H70v-4.4
                                 c0-4.4,0-4.4-0.5-4.9c-0.5-0.5-0.6-0.5-3.3-0.5h-2.8v-3c0-3.7-0.2-4.9-1.3-7.1c-2.2-4.4-7.1-7-12.1-6.3c-3.8,0.5-7.1,3-8.7,6.3
                                 c-1,2.1-1.3,3.4-1.3,7.1v2.9h-2.8c-2.7,0-2.9,0-3.3,0.5c-0.5,0.5-0.5,0.5-0.5,4.9v4.4h-9.5c-10.7,0-11.4-0.1-14-1.3
                                 C7,58,4.8,55.5,3.8,52.7c-0.7-2-0.7-5.1,0-7.1c1-2.8,3.2-5.3,5.9-6.6c1.6-0.8,3.8-1.3,5.4-1.3c1.3,0,1.8-0.4,2.2-1.7
                                 c0.7-2.1,1.8-3.3,3.8-4.2c1-0.5,1.4-0.5,2.9-0.5c1.8,0,2.3,0.2,4.4,1.3c0.6,0.3,0.9,0.4,1.3,0.2c1-0.4,1.1-0.7,1.1-3
                                 c0-3.6,1.1-6,3.6-8.4c3-2.8,7.4-4,11.5-3c0.9,0.2,2.2,0.7,3,1.1c2,1.1,2.3,1.1,4.1-1.2c2.5-3.1,7.4-5.7,12.2-6.6
                                 c2.3-0.4,6.5-0.2,8.7,0.3C85.5,14.7,92,25.4,89,36.2c-0.4,1.6-0.2,1.9,1.5,3c2.7,1.6,4.5,3.5,5.5,6.3
                                 C96.7,47.3,96.8,50.7,96.1,52.7z M49,27.9c-2,0.2-4,0.5-4.3,0.6c-0.4,0.1-0.4,0.4,0,2.7l0.1,0.6l1.6-0.2c0.9-0.1,2.3-0.3,3.2-0.4
                                 l1.6-0.1v-0.7c0-0.4-0.1-1.1-0.1-1.6C50.9,27.6,50.9,27.6,49,27.9z M55.1,27.9c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.2,0.9-0.3,1.6
                                 l-0.1,1.3l1.2,0.1c0.7,0.1,2.1,0.3,3.1,0.6c1,0.3,1.9,0.3,2,0.3c0.2-0.2,0.9-2.8,0.7-2.9C61.1,29,56,27.9,55.1,27.9z M39,30.2
                                 c-2.4,1-4.5,2.2-4.4,2.5c0.1,0.2,0.4,0.8,0.8,1.4l0.7,1.1l1.7-0.9c1-0.5,2.1-1.1,2.7-1.3c0.5-0.2,1-0.4,1.1-0.5
                                 c0.1-0.1-0.7-2.8-1-3C40.7,29.6,39.9,29.9,39,30.2z M68.9,32.6c-2.2-1.3-3.6-2-3.9-2c-0.1,0-0.4,0.4-0.7,1
                                 c-0.9,1.9-0.9,1.8,0.5,2.6c0.7,0.3,1.8,0.9,2.4,1.3c2,1.3,1.9,1.3,2.5,0.1c0.3-0.5,0.7-1.2,0.9-1.4C70.8,33.8,70.7,33.7,68.9,32.6
                                 z M31.4,34.7c-0.1,0-1.3,1-2.5,2.3l-2.3,2.3l1.2,1.1l1.3,1.1l2.3-2.2c1.2-1.2,2.3-2.3,2.3-2.3C33.5,36.7,31.6,34.7,31.4,34.7z
                                 M78.1,41.3c0-0.3-1.2-1.8-2.9-3.5l-1.5-1.6l-1.2,1.1l-1.2,1.1l1.7,1.8c0.9,1,1.9,2.1,2.1,2.4l0.4,0.6l1.3-0.9
                                 C77.5,41.8,78.1,41.3,78.1,41.3z M24.5,42.4c-0.1,0-0.4,0.3-0.6,0.8c-0.3,0.4-1,1.7-1.6,2.9l-1.1,2.1l1.5,0.7
                                 c0.8,0.4,1.5,0.7,1.5,0.7c0,0,0.5-0.9,0.9-1.8c0.5-1,1.1-2.2,1.5-2.8l0.6-1l-1.3-0.8C25.2,42.7,24.5,42.4,24.5,42.4z M80.1,44.4
                                 c-0.1,0-0.8,0.3-1.5,0.6c-0.7,0.3-1.3,0.6-1.3,0.7c0,0,0.3,0.8,0.8,1.8c0.4,0.9,1,2.2,1.2,2.8c0.2,0.6,0.4,1.1,0.5,1.2
                                 c0.1,0.1,0.8-0.1,1.6-0.4l1.5-0.5l-0.5-1.4C81.6,47,80.3,44.4,80.1,44.4z M22.1,52.1c-0.7-0.2-1.4-0.4-1.6-0.4
                                 c-0.2-0.1-0.4,0.1-0.6,0.6c-0.3,0.9-0.9,4.4-0.9,5.3c0,0.7,0,0.7,1,0.9c0.5,0.1,1.3,0.1,1.7,0.1c0.7,0,0.7,0,0.7-0.8
                                 c0-0.8,0.5-3.8,0.7-4.8C23.3,52.5,23.2,52.5,22.1,52.1z M49.3,66.7l0.6,0.2v7h3.3v-3.5c0-3.4,0-3.5,0.4-3.6
                                 c0.8-0.3,1.9-1.4,2.4-2.5c0.9-1.9,0.6-3.8-1.1-5.4c-1.4-1.5-3.1-1.9-5.1-1.3c-0.4,0.1-1.2,0.7-1.8,1.3c-1.5,1.4-1.9,3-1.3,4.9
                                 C47.2,64.8,48.5,66.3,49.3,66.7z M51.6,60.7c0.4,0,0.8,0.2,1.1,0.5c1,1,0.3,2.8-1.1,2.8c-0.9,0-1.7-0.8-1.7-1.6
                                 C50,61.5,50.8,60.7,51.6,60.7z M81.9,68.2c-0.6-0.2-1.3-0.5-1.5-0.6c-0.3-0.1-0.4,0-0.6,0.4c-0.1,0.3-0.4,1.1-0.6,1.6
                                 c-0.2,0.6-0.6,1.6-1,2.4l-0.6,1.4l1.4,0.7c0.8,0.4,1.5,0.7,1.5,0.6c0.4-0.4,2.7-6,2.6-6.1C83,68.6,82.5,68.4,81.9,68.2z
                                 M27.4,76.3c0-0.1-0.4-0.8-0.9-1.6c-0.5-0.8-1.1-2-1.5-2.7c-0.3-0.7-0.7-1.3-0.8-1.3c-0.1,0-0.7,0.3-1.4,0.7
                                 c-0.7,0.3-1.3,0.7-1.3,0.7c-0.2,0.2,2.8,5.8,3.1,5.9C25,78,27.4,76.5,27.4,76.3z M76.7,76.6L75.9,76l-0.6,0.8
                                 c-0.3,0.5-1.3,1.6-2.1,2.5l-1.5,1.6L73,82l1.3,1.1l1.3-1.4c0.7-0.7,1.7-1.9,2.2-2.6l0.9-1.2l-0.5-0.4
                                 C77.8,77.4,77.2,76.9,76.7,76.6z M32.2,81.4c-1-0.9-2-1.8-2.1-2.1c-0.2-0.3-0.4-0.5-0.6-0.5c-0.1,0-0.8,0.5-1.4,1.1L27,81l2.4,2.3
                                 l2.4,2.3l1.1-1.2c0.6-0.6,1.1-1.2,1.1-1.3C34,83.1,33.2,82.3,32.2,81.4z M69.4,82.8c0,0-0.6,0.4-1.3,0.9c-0.7,0.5-1.9,1.2-2.6,1.6
                                 c-0.7,0.4-1.3,0.7-1.3,0.8c0,0.3,1.2,2.8,1.4,2.8c0.3,0,3.8-2,4.9-2.8l1-0.7l-1-1.3C69.9,83.4,69.5,82.8,69.4,82.8z M41.1,86.8
                                 c-0.6-0.2-1.8-0.7-2.6-1.1c-0.9-0.5-1.7-0.8-1.8-0.8c-0.1,0-0.3,0.3-0.5,0.8c-0.2,0.4-0.5,1.1-0.7,1.4l-0.3,0.7l1.5,0.7
                                 c1.7,0.8,4.6,2,4.7,1.9c0.3-0.3,1.1-2.9,0.9-3C42.2,87.2,41.7,87,41.1,86.8z M61,87.4c-0.2,0-0.8,0.1-1.3,0.3
                                 c-0.6,0.2-1.8,0.4-2.8,0.6c-1,0.2-1.8,0.3-1.9,0.4c-0.1,0.1,0.3,3,0.4,3.1c0.2,0.2,6.1-1.1,6.4-1.3C62.1,90.2,61.3,87.4,61,87.4z
                                 M46.1,88.2c-0.6-0.1-0.6-0.1-0.8,0.6c-0.1,0.4-0.2,1.1-0.3,1.6l-0.1,0.9l1.4,0.2c0.8,0.1,2.3,0.3,3.3,0.4
                                 c2.2,0.2,2.2,0.2,2.2-1.8v-1.4h-1C49.9,88.7,47.4,88.4,46.1,88.2z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Best for Developing Single Page App',
					text: 'Multiple challenges in developing single page applications are addressed effortlessly',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M55,79.7H45V83h10V79.7z M36.6,20.3H40V17h-3.4V20.3z M43.4,20.3H50V17h-6.6V20.3z M41.8,48.4h-3.5v3.3h3.6
                                 c0.3,1.1,0.7,2,1.2,2.9L40.5,57l2.3,2.3l2.4-2.4c0.9,0.5,1.9,0.9,3,1.2v3.5h3.4V58c1.1-0.3,2-0.7,3-1.2l2.4,2.4l2.3-2.3l-2.4-2.4
                                 c0.5-0.9,0.9-1.9,1.2-2.9h3.5v-3.3h-3.5c-0.1-0.9-0.5-1.9-1.2-2.8l2.4-2.4L57,40.6L54.6,43c-0.9-0.5-1.9-0.9-3-1.2v-3.5h-3.4V42
                                 c-1.1,0.3-2,0.7-3,1.2L43,40.6L40.5,43l2.4,2.4C42.4,46.4,42,47.3,41.8,48.4z M50,45.1c2.7,0,5,2.3,5,5c0,2.7-2.3,5-5,5
                                 c-2.7,0-5-2.3-5-5C45,47.3,47.3,45.1,50,45.1z M95,12H85c-2.7,0-5,2.3-5,5v9.9c0,2.7,2.3,5,5,5h10c2.7,0,5-2.3,5-5V17
                                 C100,14.3,97.7,12,95,12z M96.6,26.9c0,0.9-0.8,1.6-1.6,1.6H85c-0.9,0-1.6-0.8-1.6-1.6V17c0-0.9,0.8-1.6,1.6-1.6h10
                                 c0.9,0,1.6,0.8,1.6,1.6V26.9z M95,40.1H85c-2.7,0-5,2.3-5,5V55c0,2.7,2.3,4.9,5,4.9h10c2.7,0,5-2.3,5-4.9v-9.9
                                 C100,42.4,97.7,40.1,95,40.1z M96.6,55c0,0.9-0.8,1.6-1.6,1.6H85c-0.9,0-1.6-0.8-1.6-1.6v-9.9c0-0.9,0.8-1.6,1.6-1.6h10
                                 c0.9,0,1.6,0.8,1.6,1.6V55z M95,68.2H85c-2.7,0-5,2.3-5,4.9V83c0,2.7,2.3,5,5,5h10c2.7,0,5-2.3,5-5v-9.9
                                 C100,70.3,97.7,68.2,95,68.2z M96.6,83c0,0.9-0.8,1.6-1.6,1.6H85c-0.9,0-1.6-0.8-1.6-1.6v-9.9c0-0.9,0.8-1.6,1.6-1.6h10
                                 c0.9,0,1.6,0.8,1.6,1.6V83z M15,12H5c-2.7,0-5,2.3-5,5v9.9c0,2.7,2.3,5,5,5h10c2.7,0,5-2.3,5-5V17C20,14.3,17.7,12,15,12z
                                 M16.6,26.9c0,0.9-0.8,1.6-1.6,1.6H5c-0.9,0-1.6-0.8-1.6-1.6V17c0-0.9,0.8-1.6,1.6-1.6h10c0.9,0,1.6,0.8,1.6,1.6V26.9z M15,40.1H5
                                 c-2.7,0-5,2.3-5,5V55c0,2.7,2.3,4.9,5,4.9h10c2.7,0,5-2.3,5-4.9v-9.9C20,42.4,17.7,40.1,15,40.1z M16.6,55c0,0.9-0.8,1.6-1.6,1.6
                                 H5c-0.9,0-1.6-0.8-1.6-1.6v-9.9c0-0.9,0.8-1.6,1.6-1.6h10c0.9,0,1.6,0.8,1.6,1.6V55z M15,68.2H5c-2.7,0-5,2.3-5,4.9V83
                                 c0,2.7,2.3,5,5,5h10c2.7,0,5-2.3,5-5v-9.9C20,70.3,17.7,68.2,15,68.2z M16.6,83c0,0.9-0.8,1.6-1.6,1.6H5c-0.9,0-1.6-0.8-1.6-1.6
                                 v-9.9c0-0.9,0.7-1.7,1.6-1.7h10c0.9,0,1.6,0.8,1.6,1.6V83z M77.8,24.7l-2.3-2.3l-2.2,2.1v-4.3c0-4.6-3.8-8.3-8.4-8.3H35
                                 c-4.6,0-8.4,3.7-8.4,8.3v4.3l-2.2-2.1l-2.3,2.3l4.5,4.4v19.1h-3.4v3.3h3.4v19.1l-4.5,4.4l2.3,2.3l2.2-2.1v4.3
                                 c0,4.5,3.8,8.3,8.4,8.3h30c4.6,0,8.4-3.7,8.4-8.3v-4.1l2.2,2.1l2.3-2.3l-4.5-4.4V51.6h3.4v-3.3h-3.4v-19L77.8,24.7z M70,79.7
                                 c0,2.7-2.3,5-5,5H35c-2.7,0-5-2.3-5-5v-1.6h40V79.7z M70,27.9l-9.5,9.4l2.3,2.3l7.2-7.1v15.9h-5v3.3h5v15.8l-7.2-7.1l-2.3,2.3
                                 l9.5,9.4v2.7H30v-2.7l9.5-9.4l-2.3-2.3L30,67.5V51.6h5v-3.3h-5V32.5l7.2,7.1l2.3-2.3L30,27.9v-2.7h40V27.9z M70,21.9H30v-1.6
                                 c0-2.7,2.3-4.9,5-4.9h30c2.7,0,5,2.3,5,4.9V21.9z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Two-way Data Binding',
					text: 'Rendering templates in plain HTML which are detected and changed automatically',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M92.6,7.4C87.8,2.6,81.6,0,74.7,0c-6.9,0-13.2,2.6-18,7.4L44.1,20c-1.2,1.2-1.2,3.2,0,4.5
                                 c1.2,1.2,3.2,1.2,4.5,0l12.7-12.7c3.6-3.5,8.4-5.5,13.5-5.5s9.9,1.9,13.5,5.5c7.4,7.4,7.4,19.6,0,27l-18,18
                                 c-7.4,7.4-19.6,7.4-27,0c-2.2-2.2-3.8-4.7-4.7-7.7c-0.5-1.6-2.3-2.6-3.9-2c-1.6,0.5-2.6,2.3-2,3.9c1.2,3.9,3.4,7.4,6.2,10.3
                                 c4.9,4.9,11.2,7.4,18,7.4s13.1-2.7,17.8-7.4l18-18c4.9-4.9,7.4-11.2,7.4-18S97.4,12.2,92.6,7.4z M51.5,75.5L38.8,88.2
                                 c-7.4,7.4-19.6,7.4-27,0c-3.5-3.6-5.5-8.4-5.5-13.5s1.9-9.9,5.5-13.5l18.1-18c3.6-3.5,8.4-5.5,13.5-5.5s9.9,1.9,13.5,5.5
                                 c2.3,2.2,3.8,4.7,4.7,7.7c0.5,1.6,2.3,2.6,3.9,2c1.6-0.5,2.6-2.3,2-3.9c-1.2-3.9-3.4-7.4-6.2-10.3c-4.9-4.9-11.1-7.4-18-7.4
                                 c-6.9,0-13.2,2.7-18,7.4l-18,18C2.6,61.5,0,68,0,74.7c0,6.8,2.6,13.1,7.3,17.8c4.9,4.9,11.2,7.4,18,7.4c6.9,0,13.2-2.6,18-7.4
                                 L55.9,80c1.2-1.2,1.2-3.2,0-4.5C54.7,74.3,52.7,74.3,51.5,75.5z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Material Design',
					text: 'The complete set of fully tested, easily accessible components for a unified and optimized design',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M20,6.6V10h3.4V6.6H20z M26.6,6.6V10H30V6.6H26.6z M33.4,6.6V10h3.4V6.6H33.4z M2.8,45.5
                                 c-0.7-0.7-1.8-0.7-2.3,0C0.1,45.8,0,46.2,0,46.6v51.6c0,0.9,0.8,1.8,1.6,1.8h51.6c0.9,0,1.6-0.8,1.6-1.6c0-0.4-0.1-0.8-0.5-1.2
                                 L2.8,45.5z M3.4,96.6V50.7l45.9,45.9H3.4z M11.6,90h16.8c0.9,0,1.6-0.8,1.6-1.6c0-0.5-0.1-0.9-0.5-1.2L12.8,70.5
                                 c-0.7-0.7-1.8-0.7-2.3,0c-0.3,0.3-0.5,0.7-0.5,1.2v16.6C10,89.3,10.8,90,11.6,90z M13.4,75.7l10.9,10.9H13.4V75.7z M98.4,63.4
                                 H86.8V25.7l11.3-11.5c2.4-2.4,2.4-6.5,0-9.1s-6.5-2.4-9.1,0l-2.4,2.6C86.3,3.4,82.7,0,78.4,0H21.6c-4.6,0-8.4,3.8-8.4,8.4v43.4
                                 h3.4V8.4c0-2.7,2.3-5,5-5h56.7c2.7,0,5,2.3,5,5v2.7L67.7,26.6H55.4c-3.6-9.5-14.3-14.1-23.8-10.4C22.2,19.9,17.6,30.5,21.2,40
                                 c2.6,6.5,8.5,10.9,15.4,11.6v6.8c0,0.9,0.8,1.6,1.6,1.6h36.6c0.9,0,1.6-0.8,1.6-1.6V35.7l6.6-6.6v34.3H45c-0.9,0-1.6,0.8-1.6,1.6
                                 v13.4c0,0.9,0.8,1.6,1.6,1.6h38.4v1.6c0,2.7-2.3,5-5,5H51.6V90h26.8c4.6,0,8.4-3.8,8.4-8.4V80h11.6c0.9,0,1.6-0.8,1.6-1.6V65
                                 C100,64.1,99.2,63.4,98.4,63.4z M91.5,7.6c1.2-1.2,3.1-1.2,4.3-0.1c1.2,1.2,1.2,3.1,0.1,4.3c0,0,0,0-0.1,0.1L95,12.7l-4.3-4.3
                                 L91.5,7.6z M53.4,33.4c0,7.6-5.8,14.1-13.4,14.9V30h13C53.2,31.1,53.4,32.2,53.4,33.4z M36.6,28.4v19.9
                                 c-8.2-0.9-14.2-8.4-13.2-16.6c0.9-8.2,8.4-14.2,16.5-13.2c5.1,0.5,9.6,3.6,11.8,8.2H38.2C37.4,26.6,36.6,27.4,36.6,28.4z
                                 M73.4,56.6H40v-5.1c10-0.8,17.4-9.6,16.6-19.7c0-0.5-0.1-1.2-0.3-1.8h8l-3.8,3.8c-0.3,0.3-0.4,0.5-0.4,0.8L58.5,43
                                 c-0.1,0.9,0.4,1.8,1.4,2c0.1,0,0.3,0,0.3,0c0.1,0,0.3,0,0.3,0l8.4-1.6c0.3,0,0.7-0.3,0.8-0.4l3.8-3.8V56.6z M62.7,38.4l2.3,2.3
                                 l-2.8,0.5L62.7,38.4z M68.4,39.3L64.1,35l24.3-24.3l4.3,4.3L68.4,39.3z M96.7,76.6h-50v-10H50V70h3.4v-3.4h3.4V70h3.4v-3.4h3.4V70
                                 h3.4v-3.4h3.4V70h3.4v-3.4H77V70h3.4v-3.4h3.4V70h3.4v-3.4H90V70h3.4v-3.4h3.4V76.6z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Command Line Interface',
					text: 'The high quality tool set that facilitates development',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M95.4,10H4.6C2,10,0,12.2,0,14.6v59.8C0,77,2,79,4.6,79h30.9l-2.3,8h-6.8c-0.8,0-1.5,0.7-1.5,1.5
                                 c0,0.8,0.7,1.5,1.5,1.5h7.8h31.4h7.8c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-6.8l-2.3-8h30.9c2.6,0,4.6-2,4.6-4.6V14.6
                                 C100,12.2,98,10,95.4,10z M36.4,87.1l2.3-8h22.8l2.2,8H36.4z M97,74.5c0,1-0.8,1.6-1.6,1.6H62.6h-58c-0.9,0-1.6-0.8-1.6-1.6V14.8
                                 c0-1,0.8-1.6,1.6-1.6h90.8c0.9,0,1.6,0.8,1.6,1.6V74.5z M92.3,16.4H7.7c-0.8,0-1.5,0.7-1.5,1.5v53.5c0,0.8,0.7,1.5,1.5,1.5h84.6
                                 c0.8,0,1.5-0.7,1.5-1.5V17.9C93.8,17.1,93.1,16.4,92.3,16.4z M90.8,69.8H9.2V31.9h81.6V69.8z M90.8,28.9H9.2v-9.6h81.6V28.9z
                                 M29.6,25.6h47c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-47c-0.8,0-1.5,0.7-1.5,1.5C28.1,24.9,28.8,25.6,29.6,25.6z
                                 M82.8,25.6h3.1c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-3.1c-0.8,0-1.5,0.7-1.5,1.5C81.4,24.9,82,25.6,82.8,25.6z
                                 M14.1,25.6c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4S13.2,22.8,13,23
                                 c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1C13.2,25.5,13.6,25.6,14.1,25.6z M18.6,25.6c0.4,0,0.8-0.1,1.1-0.4
                                 c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1
                                 c0,0.4,0.1,0.8,0.4,1.1C18,25.5,18.2,25.6,18.6,25.6z M23.4,25.6c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1
                                 c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1
                                 C22.6,25.5,23,25.6,23.4,25.6z M17.7,62.7c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l10.9-11c0.5-0.5,0.5-1.5,0-2L19.7,38.8
                                 c-0.5-0.5-1.5-0.5-2,0c-0.5,0.5-0.5,1.5,0,2l9.9,9.9l-9.9,9.9C17.2,61.2,17.2,62.2,17.7,62.7z M32.8,63.4h15.7
                                 c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.8-1.5-1.6-1.5H32.8c-0.8,0-1.5,0.7-1.5,1.5C31.4,62.7,32,63.4,32.8,63.4z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Less Coding',
					text: 'The flexibility of developing applications with less code',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                              <g>
                                 <path class="st0" d="M35.5,44.7c-0.4-0.8-1.5-0.9-2.2-0.5L14.8,54.7C14.3,55,14,55.5,14,56c0,0.5,0.3,1,0.8,1.3l18.6,10.5
                                 c0.3,0.1,0.5,0.3,0.8,0.3c0.5,0,1.1-0.3,1.3-0.8c0.4-0.8,0.3-1.7-0.5-2.1L18.8,56l16.3-9.2C35.8,46.4,36,45.5,35.5,44.7z
                                 M64.4,67.3c0.3,0.5,0.8,0.8,1.3,0.8c0.3,0,0.5-0.1,0.8-0.3L85,57.3c0.5-0.3,0.8-0.8,0.8-1.3c0-0.5-0.3-1-0.8-1.3L66.4,44.2
                                 c-0.8-0.4-1.8-0.3-2.2,0.5c-0.4,0.8-0.3,1.7,0.5,2.1L81.1,56l-16.3,9.2C64.1,65.7,63.8,66.7,64.4,67.3z M56,44.7L41.2,65.8
                                 c-0.5,0.6-0.3,1.7,0.4,2.1c0.3,0.1,0.5,0.3,0.9,0.3c0.5,0,1.1-0.3,1.3-0.6l14.8-21.1c0.5-0.6,0.3-1.7-0.4-2.1
                                 C57.5,43.8,56.5,44,56,44.7z M98.4,70.8c0.9,0,1.6-0.6,1.6-1.5V8.5C99.9,7.8,99.2,7,98.2,7H1.6C0.7,7,0,7.8,0,8.5v82.9
                                 C0,92.4,0.7,93,1.6,93h96.6c0.9,0,1.6-0.6,1.6-1.5V75.3c0-0.9-0.7-1.5-1.6-1.5c-0.9,0-1.6,0.6-1.6,1.5v14.6H3.2V22.3h93.5v47
                                 C96.8,70.2,97.4,70.8,98.4,70.8z M62.2,19.2h-59v-9.1h59V19.2z M96.6,19.2H65.3v-9.1h31.3V19.2z M81.1,16.4c0.9,0,1.8-0.8,1.8-1.7
                                 c-0.1-0.9-0.8-1.7-1.8-1.7c-0.9,0-1.8,0.8-1.8,1.7S80.2,16.4,81.1,16.4z M72.3,16.4c0.9,0,1.8-0.8,1.8-1.7S73.3,13,72.3,13
                                 c-0.9,0-1.8,0.8-1.8,1.7S71.4,16.4,72.3,16.4z M89.7,16.4c0.9,0,1.8-0.8,1.8-1.7S90.7,13,89.7,13c-0.9,0-1.8,0.8-1.8,1.7
                                 S88.8,16.4,89.7,16.4z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Testing Made Easy',
					text: 'Simplified and comprehensive testing reinforces the developers confidence in the developed modules',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M3.2,81V9.2c0-2.7,2.2-4.9,4.9-4.9h71c2.7,0,4.9,2.2,4.9,4.9v37.6h3.2V9.2c0-4.5-3.6-8.2-8.1-8.2h-71
                                 C3.6,1,0,4.7,0,9.2V81c0,4.5,3.6,8.2,8.1,8.2H50v-3.3H8.1C5.4,85.9,3.2,83.7,3.2,81z M6.5,7.6v3.3h3.2V7.6H6.5z M12.8,7.6v3.3h3.2
                                 V7.6H12.8z M19.3,7.6v3.3h3.2V7.6H19.3z M80.7,14.1H6.5v3.3h74.2V14.1z M23.1,21.1L19,25.2c-3.5-2.3-8.1-1.6-10.7,1.6
                                 c-2.6,3.3-2.4,7.9,0.5,10.9c3,3,7.6,3.1,10.8,0.5c3.2-2.6,3.9-7.4,1.6-10.8l4.1-4.1L23.1,21.1z M14.5,36.9c-2.7,0-4.9-2.2-4.9-4.9
                                 c0-2.7,2.2-4.9,4.9-4.9c0.7,0,1.5,0.1,2,0.5l-3.2,3.3l2.3,2.3l3.4-3.3c0.3,0.7,0.5,1.4,0.5,2C19.3,34.8,17.2,36.9,14.5,36.9z
                                 M54.9,27.2H27.4v3.3h27.4V27.2z M27.4,36.9h11.4v-3.3H27.4V36.9z M64.5,33.7H41.9v3.3h22.6V33.7z M74.2,27.2H58.1v3.3h16.1V27.2z
                                 M23.1,40.8L19,44.9c-3.5-2.3-8.1-1.6-10.7,1.6c-2.6,3.3-2.4,7.9,0.5,10.9c3,3,7.6,3.1,10.8,0.5c3.2-2.6,3.9-7.4,1.6-10.8l4.1-4.1
                                 L23.1,40.8z M14.5,56.5c-2.7,0-4.9-2.2-4.9-4.9s2.2-4.9,4.9-4.9c0.7,0,1.5,0.1,2,0.5l-3.1,3.3l2.3,2.3l3.2-3.3
                                 c0.3,0.7,0.5,1.4,0.5,2.1C19.3,54.3,17.2,56.5,14.5,56.5z M54.9,46.8H27.4v3.3h27.4V46.8z M27.4,53.3v3.3h11.4v-3.3H27.4z
                                 M54.7,53.3H41.9v3.3h12.8V53.3z M64.6,46.8h-6.5v3.3h6.5V46.8z M23.1,60.3L19,64.4c-3.5-2.3-8.1-1.6-10.7,1.6
                                 C5.8,69.3,5.9,74,8.9,77c3,3,7.6,3.1,10.8,0.5c3.2-2.6,3.9-7.4,1.6-10.8l4.1-4.1L23.1,60.3z M14.5,76.2c-2.7,0-4.9-2.2-4.9-4.9
                                 c0-2.7,2.2-4.9,4.9-4.9c0.7,0,1.5,0.1,2,0.5l-3.2,3.3l2.3,2.3l3.2-3.3c0.3,0.7,0.5,1.4,0.5,2.1C19.3,74,17.2,76.2,14.5,76.2z
                                 M48.4,66.3H27.4v3.3h20.9V66.3z M27.4,76.2h11.4v-3.3H27.4V76.2z M48.4,76.2v-3.3h-6.5v3.3H48.4z M75.8,50.1
                                 c-13.4,0-24.2,10.9-24.2,24.5C51.6,88.1,62.4,99,75.8,99S100,88.1,100,74.5C100,61,89.2,50.1,75.8,50.1z M75.8,95.7
                                 c-11.6,0-20.9-9.4-20.9-21.2c0-11.8,9.3-21.2,20.9-21.2c11.6,0,20.9,9.4,20.9,21.2C96.8,86.3,87.4,95.7,75.8,95.7z M68.2,79.9
                                 l-2.3-4.5l-2.8,1.5l3.2,6.6c0.3,0.4,0.7,0.8,1.2,0.8c0.1,0,0.1,0,0.3,0c0.4,0,0.8-0.1,1.1-0.4l19.3-19.5L86,62L68.2,79.9z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Easy Maintenance',
					text: 'Lightweight and dynamic single page application, offers easy maintenance',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M66.6,83.4c-1,0-1.7,0.7-1.7,1.7v10c0,1-0.7,1.7-1.7,1.7H16.5c-1,0-1.7-0.7-1.7-1.7V5c0-1,0.7-1.7,1.7-1.7
                                 h7.2l1.7,4c0.3,0.6,0.8,1,1.5,1h25.7c0.7,0,1.2-0.4,1.5-1l1.7-4H63c1,0,1.7,0.7,1.7,1.7v15.1c0,1,0.7,1.7,1.7,1.7
                                 c1,0,1.7-0.7,1.7-1.7V5c0-2.8-2.2-5-5-5H16.5c-2.8,0-5,2.2-5,5v90c0,2.8,2.2,5,5,5h46.7c2.8,0,5-2.2,5-5v-10
                                 C68.2,84.2,67.6,83.4,66.6,83.4z M52.3,3.3L51.6,5H28.1l-0.7-1.7H52.3z M23.3,93.4c0,1,0.7,1.7,1.7,1.7h30c1,0,1.7-0.7,1.7-1.7
                                 s-0.7-1.7-1.7-1.7H25C24,91.7,23.3,92.5,23.3,93.4z M88.4,47.6l-1.2-6.6c-0.1-0.8-0.8-1.4-1.7-1.4L76.1,40
                                 c-0.6-1.1-1.2-2.2-1.9-3.3l5.1-7.9c0.4-0.7,0.3-1.7-0.3-2.2l-5.3-4.2c-0.7-0.6-1.5-0.6-2.2,0l-6.9,6.5c-1.1-0.6-2.4-1-3.6-1.2
                                 l-1.1-9.4c-0.1-0.8-0.8-1.5-1.7-1.5h-6.6c-0.8,0-1.5,0.7-1.7,1.5l-1.1,9.4c-1.2,0.3-2.5,0.8-3.6,1.2l-6.9-6.5
                                 c-0.6-0.6-1.5-0.6-2.2,0L31,26.7c-0.7,0.6-0.8,1.5-0.3,2.2l5.1,7.9c-0.7,1.1-1.4,2.2-1.9,3.3l-9.4-0.4c-0.8,0-1.5,0.6-1.7,1.4
                                 l-1.1,6.6c-0.1,0.8,0.3,1.7,1.1,1.9l9,2.8c0.1,1.2,0.3,2.5,0.7,3.7l-7.5,5.7c-0.7,0.6-0.8,1.4-0.4,2.2l3.3,5.8
                                 c0.4,0.7,1.4,1,2.1,0.7l8.4-3.9c1,0.8,1.9,1.7,2.9,2.5l-2.1,9.3c-0.1,0.8,0.3,1.7,1.1,1.9l6.2,2.4c0.8,0.3,1.7,0,2.1-0.8l4.3-8.4
                                 c1.2,0.1,2.5,0.1,3.9,0l4.3,8.4c0.4,0.7,1.2,1.1,2.1,0.8l6.2-2.4c0.8-0.3,1.2-1.1,1.1-1.9l-2.1-9.3c1-0.7,2.1-1.5,2.9-2.5l8.7,3.7
                                 c0.8,0.3,1.7,0,2.1-0.7l3.3-5.8c0.4-0.7,0.3-1.7-0.4-2.2L77.6,56c0.3-1.2,0.6-2.5,0.7-3.7l9-2.8C88.2,49.2,88.6,48.4,88.4,47.6z
                                 M76.1,49.4c-0.7,0.3-1.1,0.8-1.1,1.5c0,1.8-0.4,3.5-0.8,5.3c-0.3,0.7,0,1.4,0.6,1.8l7.3,5.5L80,66.7l-8.4-3.6
                                 c-0.7-0.3-1.4-0.1-1.9,0.4c-1.2,1.2-2.5,2.5-4,3.5c-0.6,0.4-0.8,1.1-0.7,1.8l2.1,8.9L63.4,79l-4.1-8.2c-0.3-0.6-0.8-1-1.5-1
                                 c-0.1,0-0.1,0-0.3,0c-1.8,0.3-3.6,0.3-5.3,0c-0.7-0.1-1.4,0.3-1.7,0.8l-4.1,8.2l-3.5-1.2l2.1-8.9c0.1-0.7-0.1-1.4-0.7-1.8
                                 c-1.5-1-2.9-2.1-4-3.5c-0.4-0.6-1.2-0.7-1.9-0.4l-8.4,3.6l-1.8-3.2l7.3-5.5c0.6-0.4,0.8-1.1,0.6-1.8c-0.6-1.7-0.8-3.5-0.8-5.3
                                 c0-0.7-0.6-1.4-1.1-1.5l-8.7-2.6l0.7-3.7l9.1,0.4c0.7,0,1.4-0.4,1.7-1c0.7-1.7,1.5-3.2,2.6-4.6c0.4-0.6,0.4-1.4,0.1-1.9l-5-7.6
                                 l2.9-2.4l6.6,6.2c0.6,0.4,1.2,0.6,1.9,0.3c1.5-0.8,3.2-1.4,5-1.8c0.7-0.1,1.2-0.7,1.2-1.4l1.1-9h3.7l1.1,9
                                 c0.1,0.7,0.6,1.2,1.2,1.4c1.8,0.4,3.5,1,5,1.8c0.7,0.3,1.4,0.3,1.9-0.3l6.6-6.2l2.9,2.4L71,36c-0.4,0.6-0.4,1.4,0.1,1.9
                                 c1.1,1.4,1.9,2.9,2.6,4.6c0.3,0.7,1,1.1,1.7,1l9.1-0.4l0.7,3.7L76.1,49.4z M55,36.8c-7.3,0-13.3,5.9-13.3,13.3
                                 c0,7.3,5.9,13.3,13.3,13.3c7.3,0,13.3-5.9,13.3-13.3C68.2,42.7,62.3,36.8,55,36.8z M55,60c-5.5,0-10-4.4-10-10c0-5.5,4.4-10,10-10
                                 c5.5,0,10,4.4,10,10C64.9,55.6,60.5,60,55,60z M25,10.1h-3.3v3.3H25V10.1z M28.3,16.7v-3.3H25v3.3H28.3z M25,20.1v-3.3h-3.3v3.3
                                 H25z M18.3,13.4v3.3h3.3v-3.3H18.3z M21.6,80.1v3.3H25v-3.3H21.6z M28.3,86.7v-3.3H25v3.3H28.3z M21.6,90H25v-3.3h-3.3V90z
                                 M18.3,83.4v3.3h3.3v-3.3H18.3z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Improved Server Performance',
					text: 'With the support of caching and many other processes, it reduces the load on the server and improves the performance.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path class="st0" d="M30,35H6.6v3.4H30V35z M36.8,35h-3.4v3.4h3.4V35z M98.5,38.4H80.8l-2.8-2.8c-0.3-0.3-0.7-0.5-1.2-0.5h-8.4
                                 c-0.9,0-1.6,0.8-1.6,1.6v23.4c0,0.9,0.8,1.6,1.6,1.6h30c0.9,0,1.6-0.8,1.6-1.6v-20C100.1,39.1,99.3,38.4,98.5,38.4z M96.7,58.5
                                 H70.1v-20h6l2.8,2.8c0.3,0.3,0.7,0.5,1.2,0.5h16.6V58.5z M68.3,26.7h30c0.9,0,1.6-0.8,1.6-1.6V5c0.1-0.9-0.7-1.6-1.5-1.6H80.8
                                 l-2.8-2.8C77.7,0.1,77.3,0,76.7,0h-8.4c-0.9,0-1.6,0.8-1.6,1.6V25C66.7,26,67.5,26.7,68.3,26.7z M70.1,3.4h6l2.8,2.8
                                 c0.3,0.3,0.7,0.5,1.2,0.5h16.6v16.6H70.1V3.4z M98.5,76.7H80.8l-2.8-2.8c-0.3-0.3-0.7-0.5-1.2-0.5h-8.4c-0.9,0-1.6,0.8-1.6,1.6
                                 v23.4c0,0.9,0.8,1.6,1.6,1.6h30c0.9,0,1.6-0.8,1.6-1.6V78.5C100.1,77.5,99.3,76.7,98.5,76.7z M96.7,96.8H70.1v-20h6l2.8,2.8
                                 c0.3,0.3,0.7,0.5,1.2,0.5h16.6V96.8z M53.4,15v33.4h-6.6v3.4h6.6v35c0,0.9,0.8,1.6,1.6,1.6h8.4v-3.4h-6.6V51.7h6.6v-3.4h-6.6V16.6
                                 h6.6v-3.2h-8.4C54.1,13.4,53.4,14.2,53.4,15z M30,48.4H6.6v3.4H30V48.4z M36.8,48.4h-3.4v3.4h3.4V48.4z M41.7,28.4H1.6
                                 C0.8,28.4,0,29.1,0,30v40.1c0,0.9,0.8,1.6,1.6,1.6h40.1c0.9,0,1.6-0.8,1.6-1.6V30C43.3,29.1,42.5,28.4,41.7,28.4z M40,68.5H3.4
                                 v-10H40V68.5z M40,55.1H3.4v-10H40V55.1z M40,41.7H3.4v-10H40V41.7z M30,61.7H6.6v3.4H30V61.7z M36.8,61.7h-3.4v3.4h3.4V61.7z"></path>
                              </g>
                           </g>
                        </svg>
               `
				}
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
