<template>
	<section class="padding-6030 our-history-progress-section bg-light">
		<div class="container">
			<div class="title">
				<span>Our History</span>
				<h2>Tell tales about how we started and how we grew</h2>
				<p>
					Since its
					<span class="tou-node" id="tou-0-97bd7da3-b083-42a6-8bd9-e9d805d48e3e"></span>,
					Genixbit as web and mobile app development company has come a long way. Our
					Journey has been incredible and we thrive to provide seamless digital
					<span class="tou-node" id="tou-0-6da319a4-4cb5-4fb3-a35b-cf14542e9bea"></span>
					solutions for startups and
					<span class="tou-node" id="tou-0-86c93745-379c-4492-a153-36b32465a67e"></span>.
				</p>
			</div>
			<div class="our-history-progress-top">
				<div class="row">
					<div class="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
						<div class="our-history-detail-image">
							<img
								src="../../assets/images/our-history-sideimage.jpg"
								alt="Genixbit"
							/>
						</div>
					</div>
					<div class="col-lg-8 col-md-12 col-sm-12 col-12">
						<div class="our-history-content-wrap box-shadow rounded10">
							<VueSlickCarousel class="our-history-content-slider">
								<div style="width: 735px" tabindex="-1">
									<div>
										<div style="width: 100%; display: inline-block">
											<div class="our-history-detail-box">
												<div class="our-history-detail-box-title-wrap">
													<div class="our-history-detail-num">2021</div>
													<div class="our-history-detail-title">
														Clutch Recognization
													</div>
												</div>
												<div class="our-history-detail-icon">
													<svg
														x="0px"
														y="0px"
														viewBox="0 0 100 100"
														style="enable-background: new 0 0 100 100"
														xml:space="preserve"
													>
														<g>
															<g>
																<path
																	d="M92.2,23.3c-0.9-2-2.6-3.6-4.6-4.3c-2-0.8-4.3-0.7-6.2,0.3c-2,0.9-3.5,2.6-4.2,4.7l-1.1,3.1L60.5,70.7v0.6l0.2,7.1
                                       l0.3,10.7c0,0.7,0.4,1.3,1.1,1.5c0.2,0.1,0.4,0.1,0.6,0.1c0.5,0,0.9-0.2,1.2-0.5l7-8l4.6-5.3l0.1-0.2c0,0,0-0.1,0-0.1
                                       c0-0.1,0.1-0.1,0.1-0.2l8.6-24.1c0.3-0.9-0.1-1.8-1-2.1c-0.8-0.3-1.8,0.1-2.1,1l-8,22.5l-1.2-0.5l-1.8-0.7L84.7,32l3.1,1.1
                                       l-3.8,10.9c-0.2,0.6-0.1,1.2,0.3,1.7c0.4,0.5,1,0.7,1.6,0.6c0.6-0.1,1.1-0.5,1.2-1.1l4.4-12.4l1.1-3.1
                                       C93.2,27.6,93.2,25.3,92.2,23.3z M64.2,84.9l-0.1-4.1l2.8,1.1L64.2,84.9z M71.6,76.5l-2.4,2.7l-5.3-2l-0.1-3.6l1.3,0.5L71.6,76.5z
                                       M67.3,71.4l-3.1-1.1l14.4-40.6l2.9,1.1h0.1L67.3,71.4z M89.5,28.4L88.9,30l-9.2-3.3l0.6-1.6c0.9-2.6,3.7-3.9,6.2-3
                                       C89,23,90.4,25.8,89.5,28.4z M55.7,13.5h14.6c0.9,0,1.6,0.7,1.6,1.7v5.1c0,0.9,0.7,1.7,1.6,1.7c0.9,0,1.6-0.7,1.6-1.7v-5.1
                                       c0-2.8-2.2-5-4.9-5H55.7c-0.9,0-1.6,0.7-1.6,1.7C54.1,12.7,54.8,13.5,55.7,13.5z M73.5,85.7c-0.9,0-1.6,0.7-1.6,1.7v7.5
                                       c0,0.9-0.7,1.7-1.6,1.7H11.9c-0.9,0-1.6-0.7-1.6-1.7V15.1c0-0.9,0.7-1.7,1.6-1.7h13c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7
                                       h-13c-2.7,0-4.9,2.2-4.9,5v79.7c0,2.7,2.2,5,4.9,5h58.4c2.7,0,4.9-2.2,4.9-5v-7.5C75.2,86.4,74.4,85.7,73.5,85.7z M21.7,31.3
                                       l-4.9,19.9c-0.1,0.5,0,1,0.3,1.4c0.3,0.4,0.8,0.6,1.3,0.6h4.9c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7h-2.8l4.3-17.4l3.4-3.5
                                       V50c0,1.2,0.6,2.3,1.6,2.9v8.4c0,2.4,0.4,4.8,1.3,7h-3c-0.9,0-1.6,0.7-1.6,1.7c0,0.9,0.7,1.7,1.6,1.7h4.7c0.1,0.1,0.1,0.2,0.2,0.3
                                       l5.1,7.3c0.3,0.4,0.8,0.7,1.3,0.7c0.5,0,1-0.3,1.3-0.7l5.2-7.5c2-3,3.2-6.5,3.2-10.1v-8.7c1-0.6,1.6-1.7,1.6-2.9V29.1l3.4,3.5
                                       L58.5,50h-2.8c-0.9,0-1.6,0.7-1.6,1.7c0,0.9,0.7,1.7,1.6,1.7h4.9c0.5,0,1-0.2,1.3-0.6c0.3-0.4,0.4-0.9,0.3-1.4l-4.9-19.9
                                       c-0.1-0.3-0.2-0.6-0.4-0.8l-6-6.1v-1h3.2c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7h-3.2v-5.3c-0.1-0.2-0.2-0.5-0.3-0.6
                                       L40.8,0.9c-0.3-0.4-0.8-0.7-1.3-0.7c-0.5,0-1,0.2-1.3,0.7l-9.7,13.3c-0.1,0.2-0.2,0.4-0.3,0.6v0.3l-0.1,9.3l-6,6.1
                                       C21.9,30.8,21.7,31,21.7,31.3z M45.9,61.5c-0.1,3-1,5.9-2.7,8.3l-3.8,5.6l-2.6-3.8h2.6c0.9,0,1.6-0.7,1.6-1.7
                                       c0-0.9-0.7-1.7-1.6-1.7h-4.9c-1.1-2.2-1.6-4.6-1.6-7v-1.3h13V61.5z M45.9,56.6H33v-3.3h13V56.6z M39.5,4.6l6.5,8.8H33L39.5,4.6z
                                       M31.3,16.8h16.2v3.3h-3.2c-0.9,0-1.6,0.7-1.6,1.7c0,0.9,0.7,1.7,1.6,1.7h3.2V50H31.3V16.8z M42.7,28.4c-0.9,0-1.6,0.7-1.6,1.7
                                       c0,0.9-0.7,1.7-1.6,1.7c-0.9,0-1.6-0.7-1.6-1.7c0-0.9,0.7-1.7,1.6-1.7c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7
                                       c-2.7,0-4.9,2.2-4.9,5c0,2.7,2.2,5,4.9,5c2.7,0,4.9-2.2,4.9-5C44.3,29.2,43.6,28.4,42.7,28.4z M42.7,38.4h-6.5
                                       c-0.9,0-1.6,0.7-1.6,1.7c0,0.9,0.7,1.7,1.6,1.7h6.5c0.9,0,1.6-0.7,1.6-1.7C44.3,39.1,43.6,38.4,42.7,38.4z M13.5,71.6
                                       c0,1.8,1.5,3.3,3.2,3.3H20c1.8,0,3.2-1.5,3.2-3.3v-3.3c0-1.8-1.5-3.3-3.2-3.3h-3.2c-1.8,0-3.2,1.5-3.2,3.3V71.6z M16.7,68.2H20
                                       v3.3h-3.2V68.2z M58.9,21.8c0,2.7,2.2,5,4.9,5c2.7,0,4.9-2.2,4.9-5c0-2.8-2.2-5-4.9-5C61.1,16.8,58.9,19,58.9,21.8z M65.4,21.8
                                       c0,0.9-0.7,1.7-1.6,1.7c-0.9,0-1.6-0.7-1.6-1.7c0-0.9,0.7-1.7,1.6-1.7C64.7,20.1,65.4,20.9,65.4,21.8z M15.1,23.4h6.5
                                       c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7h-6.5c-0.9,0-1.6,0.7-1.6,1.7C13.5,22.7,14.2,23.4,15.1,23.4z M15.1,30.1h3.2
                                       c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7h-3.2c-0.9,0-1.6,0.7-1.6,1.7C13.5,29.3,14.2,30.1,15.1,30.1z M47.6,76.5v3.3
                                       c0,1.8,1.5,3.3,3.2,3.3h3.2c1.8,0,3.2-1.5,3.2-3.3v-3.3c0-1.8-1.5-3.3-3.2-3.3h-3.2C49,73.2,47.6,74.7,47.6,76.5z M54.1,79.9h-3.2
                                       v-3.3h3.2V79.9z M55.7,68.2v-10c0-0.9-0.7-1.7-1.6-1.7c-0.9,0-1.6,0.7-1.6,1.7v10c0,0.9,0.7,1.7,1.6,1.7
                                       C55,69.9,55.7,69.2,55.7,68.2z M37.8,86.5c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7H15.1c-0.9,0-1.6,0.7-1.6,1.7v6.6
                                       c0,0.9,0.7,1.7,1.6,1.7h22.7c0.9,0,1.6-0.7,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7H16.7v-3.3H37.8z"
																></path>
															</g>
														</g>
													</svg>
												</div>
												<p>
													Clutch recognizes Genixbit as the best mobile
													app development partner in the UK, making us the
													top mobile app developers.
												</p>
											</div>
										</div>
									</div>
								</div>
							</VueSlickCarousel>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
	components: {
		VueSlickCarousel
	}
};
</script>

<style scoped>
.our-history-detail-image {
	position: relative;
	display: inline-block;
	vertical-align: top;
}
.our-history-detail-image::before {
	content: '';
	display: block;
	background-color: rgba(108, 31, 245, 0.2);
	position: absolute;
	left: -12px;
	top: -15px;
	right: -12px;
	bottom: 0;
	width: calc(100% + 24px);
	height: 100%;
	border-radius: 100%;
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}
.our-history-detail-image::after {
	content: '';
	display: block;
	background-color: rgba(108, 31, 245, 0.2);
	position: absolute;
	left: -60px;
	top: -30px;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	-webkit-transform: scale(0.95);
	-moz-transform: scale(0.95);
	-o-transform: scale(0.95);
	-ms-transform: scale(0.95);
	transform: scale(0.95);
}
.our-history-detail-image img {
	border-radius: 100%;
	position: relative;
	z-index: 1;
}
.our-history-content-wrap {
	margin-left: 15px;
	background: #fff;
}
.slick-track::after,
.slick-track::before {
	display: table;
	content: '';
}
.our-history-detail-box {
	padding: 50px 30px 40px;
	position: relative;
}
.our-history-detail-box-title-wrap {
	padding-right: 120px;
}
.our-history-detail-num {
	color: #1977cc;
	font-size: 48px;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 10px;
}

.our-history-detail-title {
	font-size: 24px;
	margin-bottom: 25px;
}
.our-history-detail-icon {
	position: absolute;
	right: 20px;
	top: 15px;
}
.our-history-detail-box p:last-child {
	margin-bottom: 0;
}
.our-history-detail-icon svg {
	width: 110px;
	height: 110px;
}
.our-history-detail-icon svg * {
	fill: #f0f0f0;
}
</style>
