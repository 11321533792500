<template>
	<div class="fxt-cnt-scas">
		<div class="icn-containter-fxt">
			<div class="svg-container" v-html="obj.svg"></div>
			<p>{{ obj.title }}</p>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.svg-container *{
	fill: #000 !important;
}
</style>