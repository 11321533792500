<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Why GenixBit for Your AngularJS Web Development</h2>
				<p>
					We owe our reputation as one of the best AngularJS Development Company to our experienced
					OOJS Teams and our success in projects
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Dedicated",
               text:"We offer dedicated teams for development which has helped the teams to remain focused on the projects with a laser edge.",
               svg:`
               <svg viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill-opacity: 1;" xml:space="preserve">
                           <path fill="#222" d="M256.1,416.6c-33.1,0-64.2-4.7-92.5-13.9c-8.6-2.8-15-5.5-20.5-8.4c-7.6,0.6-14.5,0.9-21.1,0.9
                           c-28.1,0-54.4-4-78.4-11.8c-14.3-4.8-25.5-10-33.4-19.9c-8.4-10.6-11-24.9-8.1-43.6l0.1-0.4l0.1-0.3c0-0.2,0.1-0.5,0.2-0.8
                           c6.3-23.6,21.8-44.8,43.5-59.9c16.1-11.2,35.3-18.6,55.4-21.6c-9.8-5.7-18.3-15.5-24.6-28.8C69,192,64.6,171.5,64.6,152
                           c0-29.6,24.7-51.9,57.5-51.9c32.7,0,57.5,22.3,57.6,51.8c0,19.5-4.4,40-12.2,56.2c-6.4,13.3-14.8,23.1-24.6,28.9
                           c13.8,2.1,27.1,6.2,39.3,12.2c17.6-9.3,37.1-15.1,57.1-17.2c-14.2-5.6-26.3-18.1-35-36.3c-9.1-19-14.3-43-14.3-65.8
                           c0-16.2,6.5-31.1,18.4-42.1c12.1-11.2,29.1-17.4,47.8-17.4c18.7,0,35.7,6.2,47.7,17.4c11.8,11,18.3,25.9,18.3,42.1
                           c0,22.7-5.2,46.7-14.3,65.8c-8.8,18.4-21,30.9-35.3,36.4c20.1,2.1,39.6,7.9,57.4,17.3c12.4-6.1,25.7-10.3,39.4-12.4
                           c-9.8-5.7-18.3-15.5-24.7-28.9c-7.8-16.2-12.2-36.6-12.2-56.1c0-29.6,24.7-51.9,57.5-51.9c16.2,0,30.9,5.4,41.5,15.1
                           c10.3,9.6,16.1,22.6,16.1,36.7c0,19.5-4.4,40-12.2,56.2c-6.4,13.3-14.8,23.1-24.6,28.9c20.1,3,39.2,10.5,55.3,21.6
                           c21.7,15,37.1,36.3,43.5,59.9c0.1,0.4,0.3,0.9,0.4,1.6c2.9,18.8,0.3,33-8.1,43.4c-8.5,10.9-21.7,16-33.4,19.9
                           c-23.9,7.8-50.3,11.8-78.4,11.8c-6.6,0-13.3-0.3-21.1-0.9c-5.5,3-12,5.6-20.5,8.4l-0.8,0.3h-0.2C319.4,412,288.6,416.6,256.1,416.6z
                           M153.6,373c4.2,2.5,9.8,4.8,17.4,7.3c25.5,8.3,54.9,12.7,85.1,12.7c30.5,0,59.1-4.3,85-12.7c7.3-2.4,12.9-4.7,17.4-7.2
                           c0,0,0.1,0,0.1-0.1l0.6-0.6l0.6-0.3c2.7-1.6,5-3.3,7.1-5.4l0.9-0.9c0.2-0.3,0.6-0.7,0.9-1.1c0.3-0.3,0.5-0.7,0.8-1.3
                           c0.2-0.4,0.5-0.9,0.9-1.3c0.2-0.4,0.5-1,0.8-1.6l0-0.1c0-0.1,0-0.1,0-0.2c0.2-0.7,0.4-1.3,0.7-1.7c1.6-4.3,2.2-9.6,1.8-16.2l0-0.2
                           V342c-0.2-3-0.5-5.8-0.9-8.4l0-0.3c-1.8-6.4-4.1-12.1-6.9-17.4l-0.1-0.1c-0.6-1.1-1.3-2.3-2-3.5l-0.5-0.8
                           c-3.2-5.5-6.8-10.4-10.6-14.8c-0.4-0.4-0.6-0.7-0.8-1l0,0c-1.9-2.1-3.7-4-5.4-5.6c-0.5-0.4-0.8-0.7-1-1l-0.1-0.1
                           c-1.6-1.5-3.5-3.1-5.3-4.6c-1.4-1.2-2.6-2.2-3.9-3.2c-22.3-17-50.8-26.4-80.2-26.4c-0.8,0-1.5,0-2.2,0c-0.6,0-1.1,0-1.7,0
                           c-0.5,0-0.9,0-1.3,0l-0.1,0c-22.8,0.8-44.9,7.3-64.1,18.8l-1.2,0.7c-1.9,1.2-3.9,2.4-5.7,3.7l-0.3,0.3l-0.4,0.3
                           c-0.2,0.1-0.4,0.3-0.7,0.6c-0.4,0.4-0.9,0.8-1.6,1.3c-0.7,0.5-1.4,1-2.1,1.5c-0.9,0.6-1.8,1.3-2.6,1.9l-0.1,0.1
                           c-0.1,0.2-0.3,0.3-0.4,0.4l-0.1,0.1l-0.2,0.1c-8.8,7.4-16.2,16-22,25.5c-0.1,0.3-0.3,0.7-0.6,1.1c-2.7,4.6-4.8,8.8-6.4,12.8
                           c-0.2,0.6-0.4,1.2-0.6,1.6c-0.8,2.2-1.6,4.6-2.3,7.3v0.1l-0.1,0.4c-0.9,6-1.2,11.3-0.9,15.7l0,0.2v0.5c0.2,3.3,0.8,6.2,1.8,8.8
                           c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.3,0.2,0.5,0.2,0.7c1.1,2.4,2.6,4.5,4.4,6.3l0.6,0.6c0.7,0.7,1.3,1.4,2,1.8c1.3,1,2.8,1.9,4.6,2.9
                           l0.1,0.1l0.1,0.1C153.1,372.5,153.4,372.8,153.6,373z M396.4,357.8l-0.1,0.4c-0.6,3.6-1.6,6.9-2.9,10.1c-0.4,1.1-0.9,2.2-1.5,3.3
                           c24.4-0.2,48.2-3.9,69-10.7c11.6-3.8,18.6-7.7,22.2-12.2c3-3.8,5.7-9.8,3.4-25.1l0-0.2c-10.6-37.5-51.1-64.6-96.5-64.6
                           c-11.8,0-23.6,2.2-35.9,6.6c14.7,12.1,26.5,26.8,34.2,43.1c1.2,2.3,2.3,4.8,3.5,7.7c0.2,0.4,0.3,0.8,0.4,1.1l0,0.1l0.1,0.2l0.1,0.3
                           c1.2,3.3,2.4,6.7,3.3,10.3c0.1,0.4,0.3,0.9,0.4,1.5c0.6,3.7,1,7.2,1.2,10.6l0,0.2v1.3c0.1,0.9,0.2,1.9,0.2,2.9v1
                           c0,0.7-0.1,1.4-0.1,1.9c0,0.3-0.1,0.6-0.1,0.8v3l0,0.1c-0.1,2-0.3,4-0.8,6.1V357.8z M25.7,323.4c-2.4,15.6,0.2,21.4,3.2,25.1
                           c3.5,4.5,10.4,8.3,22.4,12.2c20.7,6.8,44.5,10.5,68.8,10.7c-1.7-3.4-2.9-7.1-3.8-11.2c-0.1-0.3-0.2-0.6-0.2-0.8l0-0.1
                           c-0.1-0.5-0.2-0.9-0.2-1.2l0-0.2c-0.3-1.6-0.5-3.3-0.7-5l0-0.2c-0.1-0.7-0.2-1.4-0.2-2.3c-0.1-1.5-0.2-3.5-0.2-5.3
                           c0-1.4,0.1-2.8,0.2-4.3l0-0.1v-0.3l0-0.2c0.2-3.4,0.6-7,1.2-10.7l0.1-0.4l0.1-0.3c0-0.2,0.1-0.5,0.2-0.8c0.8-3,1.8-6.2,3.2-9.9
                           c7.5-20.3,20.6-38.4,38.1-52.8c-12.4-4.4-24.1-6.5-35.5-6.5c-45.5,0-85.9,27-96.3,64.3L25.7,323.4z M122.1,219.3
                           c9.4-0.4,17.5-7.6,24.1-21.4c6.1-12.8,10-30.4,10-46c0-17.6-12.7-28.2-34-28.2c-21.3,0-33.9,10.5-33.9,28.2c0,15.8,3.7,33.4,9.8,46
                           C104.5,211.7,112.7,218.9,122.1,219.3L122.1,219.3z M389.9,123.8c-21.3,0-34,10.5-34.1,28.2c0,15.5,3.8,33.1,10,45.9
                           c6.6,13.7,14.7,20.9,24,21.4c9.5-0.4,17.6-7.6,24.3-21.4c6-12.5,9.8-30.2,9.8-46C423.8,134.3,411.1,123.8,389.9,123.8z M256.2,212
                           c12.1-0.5,22.3-9.4,30.4-26.4c7.4-15.6,12-36.9,12-55.6c0-22.1-16.3-35.8-42.5-35.8c-14.2,0-25.7,4-33.2,11.5
                           c-6.2,6.2-9.4,14.6-9.4,24.3c0,18.8,4.6,40.1,12,55.6C233.7,202.6,243.9,211.5,256.2,212L256.2,212z"></path>
                     </svg>
               `
            },
            {
               title:"Flexible",
               text:"Our processes are flexible, with agile methodology and this allows the sprints in development to help reach milestones at speed.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill-opacity: 1;" xml:space="preserve">
                        <path fill="#222" d="M82.4,238.3H50.6l29.5-29.5l0,0c5.7-5.7,5.7-14.7,0-20.4s-14.7-5.7-20.4,0l-21,21L5.3,242.8
                        c-5.7,5.7-5.7,14.7,0,20.4l33.4,33.4l21,21c2.8,2.8,6.8,4.5,10.2,4.5s7.4-1.7,10.2-4.5c5.7-5.7,5.7-14.7,0-20.4l0,0l-29.5-30h31.7
                        c61.8,0,115.6,35.7,141.7,87.3c4-11.3,9.1-22.1,14.7-32.9C204.8,271.2,147.6,238.3,82.4,238.3z"></path>
                        <path fill="#222" d="M506.4,242.3L473,208.9l-20.4-21c-5.7-5.7-14.7-5.7-20.4,0c-5.7,5.7-5.7,14.7,0,20.4l0,0l29.5,29.5H430
                        c-65.8,0.6-123,33.4-157,83.9c-0.6,1.1-1.7,2.8-2.3,4c-5.7,9.1-10.8,18.1-14.7,27.8c-9.6,22.1-14.7,47-14.7,72.6v58.4
                        c0,7.9,6.8,14.7,14.7,14.7s14.7-6.8,14.7-14.7v-58.4c0-25.5,6.2-49.9,17-71.4c26.1-51.6,79.9-87.3,141.7-87.3H461l-29.5,29.5l0,0
                        c-5.7,5.7-5.7,14.7,0,20.4c2.8,2.8,6.8,4.5,10.2,4.5c4,0,7.4-1.7,10.2-4.5l21-21l33.4-33.4C512.6,257.6,512.6,247.9,506.4,242.3z"></path>
                        <path fill="#222" d="M211.6,92.1l29.5-29.5V278c5.1,5.7,10.2,11.3,14.7,17.6c4.5-6.2,9.6-11.9,14.7-17.6V62.6L300,92.1
                        c2.8,2.8,6.8,4.5,10.2,4.5c3.4,0,7.4-1.7,10.2-4.5c5.7-5.7,5.7-14.7,0-20.4L266,17.3c-5.7-5.7-14.7-5.7-20.4,0l-54.4,54.4
                        c-5.7,5.7-5.7,14.7,0,20.4C196.9,97.7,205.9,97.7,211.6,92.1z"></path>
                     </svg>
               `
            },
            {
               title:"Fast",
               text:"Our projects proceed at the right speed for faster time to market as a result of a focused to projects with exclusive and dedicated teams assigned.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill-opacity: 1;" xml:space="preserve">
                        <path fill="#222" d="M320.6,141.6c-13.7,0-26.6,5.3-36.3,15c-20,20-20,52.6,0,72.6c9.7,9.7,22.6,15,36.3,15
                        c13.7,0,26.6-5.3,36.3-15c9.7-9.7,15-22.6,15-36.3s-5.3-26.6-15-36.3C347.2,147,334.4,141.6,320.6,141.6z M340.8,213.1
                        c-5.4,5.4-12.5,8.3-20.1,8.3s-14.7-3-20.1-8.3c-11.1-11.1-11.1-29.2,0-40.3c5.4-5.4,12.5-8.3,20.1-8.3s14.7,3,20.1,8.3
                        c5.4,5.4,8.3,12.5,8.3,20.1C349.1,200.6,346.1,207.7,340.8,213.1z"></path>
                        <path fill="#222" d="M503.3,19.4c-1.4-4.3-4.8-7.7-9.1-9.1c-2-0.6-12.8-3.8-31.2-3.8c-46.4,0-143.9,19.9-278.1,151.9
                        c-6.3-1.4-15.7-2.8-27.5-2.8c-34.2,0-87,11.9-143.8,68.8l-1.2,1.2l-0.5,0.9c-0.2,0.3-0.3,0.5-0.4,0.7c-0.3,0.5-0.8,1.3-1.1,2.3
                        l-0.2,0.5c-0.2,0.7-0.4,1.3-0.5,1.9l-0.1,0.6c0,0.1,0,0.3-0.1,0.4l-0.2,1l0.6,4.1l0.3,0.6c0.2,0.6,0.4,1.2,0.7,1.9s0.7,1.2,1,1.7
                        l2.6,3.2l1.2,0.6c0.3,0.2,0.7,0.4,1,0.6l2.2,1c0.8,0.3,1.7,0.5,2.2,0.6l0.9,0.1c0.2,0,0.4,0.1,0.7,0.1l0.4,0.1l2.5-0.1
                        c0.6,0,2-0.1,4-0.1c18.4,0,84.1,5.7,157.3,78.8c79.6,79.6,79.2,151.5,78.8,160.6l-0.7,2l0.7,2.4c0,0.2,0.1,0.5,0.1,0.7l0.1,1.1
                        l0.5,1.4c0.7,1.9,1.8,3.6,3.1,4.9c1.2,1.2,2.6,2.2,4.2,2.9l1.3,0.9l3.2,0.3h1l3.9-0.4l1.3-0.5c0.8-0.3,1.6-0.7,2-1
                        c0.4-0.2,0.8-0.5,1.2-0.7l1.2-0.7l0.9-1.1c77.1-77.3,71.1-146.7,65.7-171.1C539.5,141.3,504.9,24.3,503.3,19.4z M334.4,317.3
                        l-4.9,4.9l2,6.6c2.2,7.1,18.3,67.5-44.4,140c-3.6-31.6-19.5-92.9-84.5-157.8C137.3,245.7,76,230,44.9,226.4
                        c45.2-39.1,85.5-47.8,112.4-47.8c14.8,0,24.8,2.7,27.5,3.6l6.6,2l4.9-4.9C327.2,48.8,419.6,29.4,463,29.4c9,0,15.6,0.9,19.6,1.6
                        C486.5,53.5,493.5,157.6,334.4,317.3z"></path>
                        <path fill="#222" d="M47.2,482.6l111.7-111.7c4.5-4.5,4.5-11.7,0-16.2s-11.7-4.5-16.2,0L31,466.4c-4.5,4.5-4.5,11.7,0,16.2
                        C35.5,487,42.7,487,47.2,482.6z"></path>
                        <path fill="#222" d="M113.8,505.2c4.5,4.5,11.7,4.5,16.2,0l81.6-81.6c4.5-4.5,4.5-11.7,0-16.2s-11.7-4.5-16.2,0L113.8,489
                        C109.3,493.4,109.3,500.7,113.8,505.2z"></path>
                        <path fill="#222" d="M106.3,302c-4.5-4.5-11.7-4.5-16.2,0L8.5,383.7c-4.5,4.5-4.5,11.7,0,16.2s11.7,4.5,16.2,0l81.6-81.6
                        C110.7,313.7,110.7,306.5,106.3,302z"></path>
                     </svg>
               `
            },
            {
               title:"Timelines",
               text:"We stick to agreed timelines focusing on projects one at a time. Projects are taken up consecutively and not simultaneously.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;fill-opacity: 1;" xml:space="preserve">
                        <path fill="#222" d="M456.1,159.4L441.6,174c69.7,92.9,50.9,224.7-42,294.4c-32.5,24.4-71.3,38.8-111.8,41.6
                        c-4.8,0.3-9.6,0.5-14.3,0.5c-59.5,0.1-116.3-25.1-156.1-69.3c-4.7-5.5-4.1-13.7,1.4-18.4c5.3-4.5,13.2-4.1,18,0.9
                        c68.2,75.5,184.7,81.4,260.2,13.2c73.7-66.6,81.4-179.7,17.2-255.6c-4.4-5.2-4.1-12.9,0.7-17.7l22.7-22.7c3.4-3.8,3.1-9.6-0.7-13
                        c-3.5-3.2-8.9-3.2-12.4,0L401,151.4c-4.6,4.6-11.9,5.1-17.1,1.2c-26.2-19.6-57.1-31.9-89.6-35.6c-6.6-0.7-11.6-6.3-11.6-12.9v-42
                        c-0.1-7.2,5.6-13.1,12.8-13.2c0.1,0,0.1,0,0.2,0h11.9c5.1,0,9.2-4.1,9.2-9.2s-4.1-9.2-9.2-9.2h-68.4c-5.1,0-9.2,4.1-9.2,9.2
                        s4.1,9.2,9.2,9.2h11.9c7.2,0,13,5.8,13,13v42.3c0,6.6-5,12.2-11.6,12.9c-42.2,4.8-81.4,24-111,54.4c-5.1,5.2-13.3,5.2-18.5,0.2
                        c-5.2-5.1-5.2-13.3-0.2-18.5c31.1-31.8,71.4-52.9,115.2-60.4V74.9c-19.4-1.8-33.7-19-32-38.4c1.6-17.4,15.6-31,33.1-32h68.4
                        c19.5,1.2,34.3,17.9,33.1,37.4c-1,17.4-14.7,31.5-32,33.1v17.7c29.2,4.9,56.9,16,81.5,32.4l15.6-15.6c13.8-13.8,36.1-13.8,49.9,0
                        s13.8,36.1,0,49.9l0,0H456.1z M28.3,233.3c0,7.2,5.8,13,13,13h90.6c7.2,0,13-5.8,13-13s-5.8-13-13-13H41.4
                        C34.2,220.3,28.4,226.1,28.3,233.3L28.3,233.3z M176.6,296.6c0-7.2-5.8-13-13-13H73c-7.2,0-13,5.8-13,13s5.8,13,13,13h90.5
                        C170.7,309.6,176.6,303.8,176.6,296.6z M41.4,372.9h90.5c7.2,0,13-5.8,13-13s-5.8-13-13-13H41.4c-7.2,0-13,5.8-13,13
                        S34.2,372.9,41.4,372.9z M345.8,246.2l-33.4,33.4c11.3,21.5,3.1,48.2-18.5,59.5c-21.5,11.3-48.2,3.1-59.5-18.5
                        c-11.3-21.5-3.1-48.2,18.5-59.5c12.9-6.8,28.2-6.8,41.1,0l33.4-33.4c5.1-5.1,13.4-5.1,18.5,0C350.9,232.9,350.9,241.1,345.8,246.2z
                        M291.3,300.2c0-9.9-8-17.9-17.9-17.9s-17.9,8-17.9,17.9s8,17.9,17.9,17.9C283.3,318,291.3,310,291.3,300.2z M273.4,455.4
                        c85.7,0.1,155.4-69.3,155.5-155s-69.3-155.4-155-155.5c-41.3-0.1-80.9,16.3-110,45.5c-5.1,5.1-5.1,13.4,0,18.5s13.4,5.1,18.5,0
                        c50.4-50.5,132.2-50.6,182.7-0.2s50.6,132.2,0.2,182.7S233,442,182.5,391.6c-0.1-0.1-0.1-0.1-0.2-0.2c-5.1-5.1-13.4-5.1-18.5,0
                        s-5.1,13.4,0,18.5C192.9,439,232.3,455.3,273.4,455.4z"></path>
                     </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
