<template>
	<div class="uiux-service-box">
		<div class="uiux-service-box-image">
			<img :src="`${obj.imageLink}`" alt="ux design company" class="rounded10 box-shadow" />
		</div>
		<div class="uiux-service-content bg-white rounded10 box-shadow">
			<div class="uiux-service-icon box-shadow" v-html="obj.svg"></div>
			<h3>{{ obj.title }}</h3>
			<p>
				{{ obj.text }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.mb-30 {
	margin-bottom: 30px !important;
}
.uiux-service-box-image {
	overflow: hidden;
	border-radius: 10px;
}
.uiux-service-box-image img {
	width: 100%;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
}
.uiux-service-content {
	position: relative;
	padding: 25px 20px;
	margin: -50px 25px 0;
	min-height: 250px;
}
.uiux-service-icon {
	width: 80px;
	height: 80px;
	background-color: #fff;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	position: absolute;
	right: 30px;
	top: -40px;
}
.uiux-service-content h3 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding-right: 110px;
}
.uiux-service-content p:last-child {
	margin-bottom: 0;
}
p {
	font: normal 14px/22px Montserrat, sans-serif;
	color: #6a6d6f;
	margin-top: 0;
}
.uiux-service-box:hover .uiux-service-box-image img {
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}
.uiux-service-box:hover .uiux-service-icon svg path,
.uiux-service-box:hover .uiux-service-icon svg g{

  fill:#fff
}
.uiux-service-box:hover .uiux-service-icon {
	background-color: #008dd2;
}
.uiux-service-icon svg path {
	fill: #000;
}
.uiux-service-icon svg {
	width: 42px;
	height: 42px;
	fill: #000;
}
</style>
