<template>
	<section class="banner innerbanner fxt-about-section" style="padding-top:20px">
		<div class="video">
			<img
				class="lazy img-con"
				alt="Genixbit-contact"
				src="../../assets/images/contact-banner.jpg"
				style=""
			/>
		</div>
		<div class="tenc-intro-main__wrapper contact-div-im">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
						<h1 class="mb-2 font-semibold">Take The First Step Forward</h1>
						<h2>Talk To Our Experts</h2>
						<p>Give us a call. Drop us an email. Connect socially</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style></style>
