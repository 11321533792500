<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our offerings</span>
				<h2 class="fxt-process-dev">Our suite of WordPress Development Services</h2>
				<p>
					Explore our growing range of PHP development services that aspires to enable every
					business with a custom-built website.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-4" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Custom WordPress Development",
               text:"Build any kind and type of website of your choice with our custom WordPress development services.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <path class="st0" d="M15.6,24.9c0,5.2,4.2,9.4,9.4,9.4s9.4-4.2,9.4-9.4s-4.2-9.4-9.4-9.4S15.6,19.7,15.6,24.9z M25,17
                     c4.3,0,7.8,3.5,7.8,7.8s-3.5,7.8-7.8,7.8s-7.8-3.5-7.8-7.8S20.7,17,25,17z M27.6,26.7l1.8-1.8l-1.8-1.8l1.1-1.1l2.9,2.9l-2.9,2.9
                     L27.6,26.7z M18.4,24.9l2.9-2.9l1.1,1.1l-1.8,1.8l1.8,1.8l-1.1,1.1L18.4,24.9z M25,28.9l-1.5-0.3l1.6-7.8l1.5,0.3L25,28.9z M42.2,10
                     v7.8H50V10H42.2z M48.4,16.3h-4.7v-4.7h4.7V16.3z M46.9,14.7h-1.6v-1.6h1.6V14.7z M32.8,13.1h7.8v1.6h-7.8V13.1z M17.2,16.3H9.4
                     v-1.6h7.8V16.3z M0,19.4h7.8v-7.8H0V19.4z M1.6,13.1h4.7v4.7H1.6V13.1z M3.1,14.7h1.6v1.6H3.1V14.7z M17.2,36.6H9.4V35h7.8V36.6z
                     M0,39.7h7.8v-7.8H0V39.7z M1.6,33.5h4.7v4.7H1.6V33.5z M3.1,35h1.6v1.6H3.1V35z M32.8,33.5h7.8V35h-7.8V33.5z M42.2,38.1H50v-7.8
                     h-7.8V38.1z M43.8,31.9h4.7v4.7h-4.7V31.9z M45.3,33.5h1.6V35h-1.6V33.5z M27.3,43.6h-4.7V42h4.7V43.6z M24.2,6.1h4.7v1.6h-4.7V6.1z
                     M21.1,6.1h1.6v1.6h-1.6V6.1z M37.5,38.1H10.9v6.3c0,1.7,1.4,3.1,3.1,3.1h21.9c1.7,0,3.1-1.4,3.1-3.1v-7.8h-1.6V38.1z M37.5,44.4
                     c0,0.9-0.7,1.6-1.6,1.6H14.1c-0.9,0-1.6-0.7-1.6-1.6v-4.7h25V44.4z M10.9,33.5V17.8h1.6v15.6H10.9z M12.5,11.6h26.6V5.3
                     c0-1.7-1.4-3.1-3.1-3.1H14.1c-1.7,0-3.1,1.4-3.1,3.1v7.8h1.6V11.6z M12.5,5.3c0-0.9,0.7-1.6,1.6-1.6h21.9c0.9,0,1.6,0.7,1.6,1.6V10
                     h-25V5.3z M39.1,16.3v15.6h-1.6V16.3H39.1z"></path>
                  </svg>
               `
            },
            {
               title:"WordPress Theme Development",
               text:"Build WordPress themes to amplify the look and feel of your website, standardize web pages or for marketplace selling.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <path class="st0" d="M31.1,19.7c0-2-3-3-6.1-3s-6.1,1-6.1,3v10.6c0,1.9,3,2.9,6.1,2.9s6.1-1,6.1-2.9V19.7z M20.5,21.8
                     c1.4,0.7,3,1,4.5,1c1.6,0,3.1-0.3,4.5-1v3.6c-0.5,0.5-2,1.2-4.5,1.2s-4.1-0.7-4.5-1.2V21.8z M25,18.2c2.8,0,4.5,1,4.5,1.5
                     s-1.7,1.5-4.5,1.5s-4.5-1-4.5-1.5S22.2,18.2,25,18.2z M25,31.6c-3,0-4.5-1-4.5-1.4v-3.1c1.4,0.6,3,0.9,4.5,0.9
                     c1.6,0,3.1-0.3,4.5-0.9v3.1C29.5,30.7,28,31.6,25,31.6z M34.8,0H15.2c-2.5,0-4.5,2-4.5,4.5v40.9c0,2.5,2,4.5,4.5,4.5h19.7
                     c2.5,0,4.5-2,4.5-4.5V4.5C39.4,2,37.4,0,34.8,0z M37.9,45.5c0,1.7-1.4,3-3,3H15.2c-1.7,0-3-1.4-3-3V4.5c0-1.7,1.4-3,3-3h19.7
                     c1.7,0,3,1.4,3,3V45.5z M29.5,46.2c0,0.4-0.3,0.8-0.8,0.8h-7.6c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h7.6
                     C29.2,45.5,29.5,45.8,29.5,46.2z M33.3,3H16.7c-1.7,0-3,1.4-3,3v34.8c0,1.7,1.4,3,3,3h16.7c1.7,0,3-1.4,3-3V6.1
                     C36.4,4.4,35,3,33.3,3z M34.8,40.9c0,0.8-0.7,1.5-1.5,1.5H16.7c-0.8,0-1.5-0.7-1.5-1.5V6.1c0-0.8,0.7-1.5,1.5-1.5h16.7
                     c0.8,0,1.5,0.7,1.5,1.5V40.9z"></path>
                  </svg>
               `
            },
            {
               title:"WordPress Plugin Development",
               text:"Help other WordPress developers and users simplify their lives with WordPress plugins for design, eCommerce, marketing, etc.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g>
                           <g>
                              <path class="st0" d="M23.2,26.9c-0.2-0.2-0.6-0.3-0.8-0.1l-3.8,3.3c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4l3.8,3.3
                              c0.2,0.2,0.6,0.2,0.8-0.1c0.2-0.2,0.2-0.6-0.1-0.8l-3.3-2.8l3.3-2.8C23.4,27.5,23.4,27.2,23.2,26.9z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M31,26.9c-0.2-0.2-0.6-0.2-0.8,0.1c-0.2,0.2-0.2,0.6,0.1,0.8l3.3,2.8l-3.3,2.8c-0.2,0.2-0.3,0.6-0.1,0.8
                              c0.2,0.2,0.6,0.3,0.8,0.1l3.8-3.3c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4L31,26.9z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M28.8,25c-0.3-0.1-0.6,0-0.8,0.3l-3.9,10c-0.1,0.3,0,0.6,0.3,0.8c0.3,0.1,0.6,0,0.8-0.3l3.9-10
                              C29.3,25.4,29.1,25.1,28.8,25z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M42.1,16H11.2c-0.9,0-1.7,0.8-1.7,1.7c0,6.9,0,15.9,0,19.8c0,0.9,0.8,1.7,1.7,1.7h30.9
                              c0.9,0,1.7-0.8,1.7-1.7c0-4,0-12.9,0-19.8C43.8,16.8,43.1,16,42.1,16z M10.7,17.7c0-0.3,0.2-0.5,0.5-0.5h30.9
                              c0.3,0,0.5,0.2,0.5,0.5V21h-32V17.7z M42.1,38H11.2c-0.3,0-0.5-0.2-0.5-0.5V22.2h32v15.4C42.7,37.8,42.4,38,42.1,38z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M49.4,41.9h-1.2V15c0-1.6-1.3-2.9-2.9-2.9H12.8l-0.2-0.3c0.2-0.3,0.3-0.7,0.4-1l1-0.2c0.3,0,0.5-0.3,0.5-0.6
                              V8c0-0.3-0.2-0.5-0.5-0.6l-1-0.2c-0.1-0.4-0.3-0.7-0.4-1l0.6-0.8c0.2-0.2,0.1-0.5-0.1-0.7l-1.5-1.5c-0.2-0.2-0.5-0.2-0.7-0.1
                              L10,3.7C9.7,3.5,9.3,3.4,9,3.2l-0.2-1c0-0.3-0.3-0.5-0.6-0.5H6.2C5.9,1.8,5.7,2,5.6,2.3l-0.2,1c-0.4,0.1-0.7,0.2-1,0.4L3.6,3.1
                              C3.4,2.9,3,3,2.8,3.2L1.4,4.6C1.2,4.8,1.2,5.1,1.3,5.4l0.6,0.8c-0.2,0.3-0.3,0.7-0.4,1l-1,0.2C0.2,7.4,0,7.7,0,8V10
                              c0,0.3,0.2,0.5,0.5,0.6l1,0.2c0.1,0.4,0.2,0.7,0.4,1l-0.6,0.8c-0.2,0.2-0.1,0.5,0.1,0.7l1.5,1.5C3,15,3.4,15.1,3.6,14.9l0.8-0.6
                              c0.3,0.1,0.5,0.2,0.8,0.3c0,0.1,0,0.3,0,0.4v26.9H3.9c-0.3,0-0.6,0.3-0.6,0.6c0,2.9,2.4,5.2,5.2,5.2h36.3c2.9,0,5.2-2.3,5.2-5.2
                              C50,42.2,49.7,41.9,49.4,41.9z M4,13.2l-0.7,0.5l-0.8-0.8l0.5-0.7c0.1-0.2,0.1-0.4,0-0.6c-0.3-0.5-0.5-0.9-0.6-1.4
                              C2.4,9.9,2.3,9.7,2,9.7L1.2,9.5V8.5L2,8.3c0.2,0,0.4-0.2,0.5-0.4c0.1-0.5,0.3-1,0.6-1.4c0.1-0.2,0.1-0.5,0-0.6L2.6,5.1l0.8-0.8
                              L4,4.8C4.2,5,4.5,5,4.7,4.9c0.4-0.3,0.9-0.5,1.4-0.6c0.2-0.1,0.4-0.2,0.4-0.5L6.7,3h1.1l0.2,0.9c0,0.2,0.2,0.4,0.4,0.5
                              c0.5,0.1,1,0.3,1.4,0.6c0.2,0.1,0.4,0.1,0.6,0l0.7-0.5l0.8,0.8l-0.5,0.7c-0.1,0.2-0.1,0.4,0,0.6c0.3,0.4,0.5,0.9,0.6,1.4
                              c0.1,0.2,0.2,0.4,0.5,0.4l0.9,0.2v1.1l-0.9,0.1c-0.2,0-0.4,0.2-0.5,0.4c-0.1,0.5-0.3,0.9-0.6,1.4c-0.1,0.2-0.1,0.4,0,0.6l0.5,0.7
                              l-0.8,0.8l-0.7-0.5c-0.2-0.1-0.4-0.1-0.6,0c-0.4,0.3-0.9,0.5-1.4,0.6c-0.2,0.1-0.4,0.2-0.4,0.5L7.7,15H6.7l-0.2-0.9
                              c0-0.2-0.2-0.4-0.4-0.5c-0.5-0.1-1-0.3-1.4-0.6C4.5,13,4.2,13,4,13.2z M6.3,16.2h1.9c0.3,0,0.5-0.2,0.6-0.5l0.2-1
                              c0.4-0.1,0.7-0.2,1-0.4l0.8,0.6c0.2,0.2,0.5,0.1,0.8-0.1l1.5-1.5c0,0,0,0,0.1-0.1h32.2c1,0,1.8,0.8,1.8,1.8v26.9H6.3V16.2z
                              M44.8,46.5H8.5c-2,0-3.7-1.5-4-3.5c0.1,0,47.3,0,44.3,0C48.5,45,46.8,46.5,44.8,46.5z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M26.8,43.3c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S27.7,43.3,26.8,43.3z M26.8,45.2
                              c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C27.2,45,27,45.2,26.8,45.2z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M13.4,17.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                              C15.1,18.2,14.3,17.4,13.4,17.4z M13.4,19.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
                              C13.9,19.3,13.7,19.5,13.4,19.5z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M16.8,17.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                              C18.5,18.2,17.7,17.4,16.8,17.4z M16.8,19.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
                              C17.3,19.3,17.1,19.5,16.8,19.5z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M20.3,17.4c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                              C21.9,18.2,21.2,17.4,20.3,17.4z M20.3,19.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5
                              C20.7,19.3,20.5,19.5,20.3,19.5z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M40.4,18.5H26.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h13.7c0.3,0,0.6-0.3,0.6-0.6S40.7,18.5,40.4,18.5z"></path>
                           </g>
                           <g>
                              <path class="st0" d="M10.8,9c0-2-1.6-3.6-3.6-3.6C5.2,5.4,3.6,7,3.6,9c0,2,1.6,3.6,3.6,3.6C9.2,12.6,10.8,11,10.8,9z M4.8,9
                              c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4c0,1.4-1.1,2.5-2.4,2.5C5.9,11.4,4.8,10.3,4.8,9z"></path>
                           </g>
                        </g>
                     </g>
                  </svg>
               `
            },
            {
               title:"WordPress Migration  Services",
               text:"Migrate from other CMS platforms to the awesomeness of WordPress with our expertise.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <path class="st0" d="M23.4,39h3.1v1.6h-3.1V39z"></path>
                     <path class="st0" d="M5.5,17.9c-0.4,0-0.8,0.4-0.8,0.8v21.1c0,0.4,0.4,0.8,0.8,0.8h16.4V39H6.3V17.9H5.5z"></path>
                     <path class="st0" d="M28.1,40.5h16.4c0.4,0,0.8-0.4,0.8-0.8V18.7c0-0.4-0.4-0.8-0.8-0.8h-0.8V39H28.1V40.5z"></path>
                     <path class="st0" d="M49.2,42.1h-0.8V15.5c0-0.4-0.4-0.8-0.8-0.8h-3.9v1.6h3.1v25.8H32.8c-0.1,0-0.2,0-0.3,0.1l-1.9,0.7H19.3l-2-0.7
                     c-0.1,0-0.2,0-0.3,0H3.1V16.3h3.1v-1.6H2.3c-0.4,0-0.8,0.4-0.8,0.8v26.6H0.8c-0.4,0-0.8,0.4-0.8,0.8v2.6c0,0.2,0.1,0.3,0.2,0.5
                     l1.1,1.3c0.1,0.2,0.4,0.3,0.6,0.3h46.3c0.2,0,0.4-0.1,0.5-0.2l1.1-1c0.2-0.1,0.2-0.4,0.2-0.6v-2.9C50,42.4,49.6,42.1,49.2,42.1z
                     M48.4,45.5L47.9,46H2.2l-0.6-0.8v-1.5h3.1v0.8h1.6v-0.8h10.6l2,0.7c0.1,0,0.2,0,0.3,0h11.6c0.1,0,0.2,0,0.3-0.1l1.9-0.7h7.7v0.8
                     h1.6v-0.8h1.6v0.8h1.6v-0.8h3.1V45.5z"></path>
                     <path class="st0" d="M15.9,13.2L17,12l-1.8-1.8L17,8.5l-1.1-1.1l-2.3,2.3c-0.3,0.3-0.3,0.8,0,1.1L15.9,13.2z"></path>
                     <path class="st0" d="M23.2,13.2l2.3-2.3c0.3-0.3,0.3-0.8,0-1.1l-2.3-2.3l-1.1,1.1l1.8,1.8L22.1,12L23.2,13.2z"></path>
                     <path class="st0" d="M18,12.4l1.6-4.7l1.5,0.5l-1.6,4.7L18,12.4z"></path>
                     <path class="st0" d="M8.6,22h3c0.3,0,0.5-0.1,0.7-0.4l2.3-3.5h15.2c0.9,0,1.6-0.7,1.6-1.6V4c0-0.9-0.7-1.6-1.6-1.6H9.4
                     C8.5,2.4,7.8,3.1,7.8,4v17.2C7.8,21.6,8.2,22,8.6,22z M9.4,4h20.3v12.5H14c-0.3,0-0.5,0.1-0.7,0.4l-2.3,3.5H9.4V4z"></path>
                     <path class="st0" d="M28.1,23h10.9v1.6H28.1V23z"></path>
                     <path class="st0" d="M35.9,19.9h3.1v1.6h-3.1V19.9z"></path>
                     <path class="st0" d="M21.9,19.9h12.5v1.6H21.9V19.9z"></path>
                     <path class="st0" d="M32.8,16.8h6.3v1.6h-6.3V16.8z"></path>
                     <path class="st0" d="M40.6,29.9h-1.8l-2.3-3.5C36.5,26.1,36.2,26,36,26H20.3v-7h-1.6v7c0,0.9,0.7,1.6,1.6,1.6h15.2l2.3,3.5
                     c0.1,0.2,0.4,0.4,0.7,0.4h3c0.4,0,0.8-0.4,0.8-0.8V13.5c0-0.9-0.7-1.6-1.6-1.6h-7.8v1.6h7.8V29.9z"></path>
                     <path class="st0" d="M21.9,23h1.6v1.6h-1.6V23z"></path>
                     <path class="st0" d="M25,23h1.6v1.6H25V23z"></path>
                     <path class="st0" d="M18.3,27.1C18.3,27.1,18.3,27.1,18.3,27.1l-8.1,0l-0.4-2.2c-0.1-0.2-0.3-0.4-0.5-0.4H7.1v1h1.7l0.4,2.1l1,5.4
                     c-0.6,0.8-0.5,1.9,0.2,2.5c0.1,0.1,0.2,0.2,0.4,0.2h6.3v-1h-6.1c-0.2-0.3-0.2-0.7,0-1h6.1c0.3,0,0.5-0.2,0.5-0.4l1.1-5.6
                     C18.8,27.4,18.6,27.1,18.3,27.1z M16.7,32.7h-5.5l-0.9-4.6h7.2L16.7,32.7z"></path>
                     <rect x="14.5" y="29.1" class="st0" width="1.1" height="2.6"></rect>
                     <rect x="12.4" y="29.1" class="st0" width="1.1" height="2.6"></rect>
                     <path class="st0" d="M12.1,36.3c-0.4,0-0.8,0.3-0.8,0.8s0.4,0.8,0.8,0.8s0.8-0.3,0.8-0.8S12.6,36.3,12.1,36.3z M12.1,37.3
                     c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3S12.3,37.3,12.1,37.3z"></path>
                     <path class="st0" d="M15.8,36.3c-0.4,0-0.8,0.3-0.8,0.8s0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8S16.3,36.3,15.8,36.3z M15.8,37.3
                     c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3S16,37.3,15.8,37.3z"></path>
                  </svg>
               `
            },
            {
               title:"WordPress eCommerce Solution",
               text:"Build online stores easily and quickly with our WordPress eCommerce solutions.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <path d="M34.3,19.7v-8.3c0-5.5-8.6-8.4-17.2-8.4S0,5.9,0,11.4v21.4c0,4,5,7.2,12.7,8.1v8.3c0,5.5,8.6,8.4,17.2,8.4
                      c8.5,0,17.2-2.9,17.2-8.4V27.8C47,23.2,41.1,20.5,34.3,19.7z M14.8,23.4c-4.8-0.3-11.3-1.6-13-3v-5c2.6,1.8,10,3,15.3,3
                      c5.4,0,12.3-1.2,14.9-3v4C26.2,19.4,17.9,20.8,14.8,23.4z M44.6,36.8c-2.1,1.7-9,3.1-14.7,3.1c-5.8,0-12.9-1.4-14.9-3.1v-5
                      c2.6,1.8,9.6,3,14.9,3c5.4,0,12.1-1.2,14.7-3V36.8z M1.8,23.2c2.2,1.2,8.1,2.1,11.3,2.4c-0.3,0.7-0.5,1.4-0.5,2.2v2.6
                      c-3.8-0.5-9.4-1.6-10.8-2.8L1.8,23.2L1.8,23.2z M14.9,39.5c2.9,1.6,9.7,2.6,14.9,2.6c5.2,0,11.9-1,14.7-2.6v4.3
                      c-2.1,1.7-9,3.1-14.7,3.1C24,47,17,45.6,14.9,43.9V39.5z M44.6,27.8v0.7c-0.8,2-8.1,4-14.7,4c-6.7,0-14.1-2-14.9-4v-0.7
                      c0-3.3,8.8-6,16.9-6C40,21.8,44.6,26.6,44.6,27.8z M17.2,5.2c12-0.1,14.7,5.1,14.7,6.2v0.7c-0.8,2-8.1,4-14.7,4s-14.5-2-15.3-4v-0.7
                      C1.8,7.7,9,5.2,17.2,5.2z M1.8,32.8v-2.6c2,1.1,7.5,1.9,10.8,2.4v6.5C7.1,38.3,2,36.1,1.8,32.8z M29.8,55c-8.1,0-14.8-3-14.9-5.8
                      v-2.6c2.9,1.6,9.7,2.6,14.9,2.6c5.2,0,11.9-1,14.7-2.6v2.6C44.6,52.7,38,55,29.8,55z M98.3,57.9h-4.9v-5.2c0-1.3-1-2.3-2.3-2.3h-3.2
                      v-6.3c0-1.3-1-2.3-2.3-2.3H68.1c-0.7,0-1.5,0.4-1.9,1l-4.5,6.3h-9.1c-1.3,0-2.3,1-2.3,2.3V82c0,1.3,1,2.3,2.3,2.3h6.7v3
                      c0,1.6,0.6,2.3,2.3,2.3h4.7v5.2c0,1.3,1,2.3,2.3,2.3h29.9c1.3,0,1.3-1,1.3-2.3V60.2C100,59,99.6,57.9,98.3,57.9z M53,51.5h9.9
                      c0.7,0,0.7-0.4,1.1-1l4.5-6.1h16.6v37.2H53V51.5z M62,84.3h23.6c1.3,0,2.3-1,2.3-2.3V53.7h2.4V87H62V84.3z M97.4,94.6H69.1v-5h22
                      c1.3,0,2.3-1,2.3-2.3V60.9h4V94.6z M70.6,16.5v5.1h-4.2c-0.6,0-1.2,0.3-1.5,0.9c-0.3,0.5-0.3,1.2,0.1,1.7L74.7,39
                      c0.3,0.5,0.9,0.8,1.4,0.8c0.6,0,1.1-0.3,1.4-0.8l9.8-14.7c0.3-0.5,0.4-1.2,0.1-1.7c-0.3-0.6-0.9-0.9-1.5-0.9h-3.4V6.5
                      c0-0.9-0.8-1.7-1.7-1.7H51.3c-1,0-1.7,0.8-1.7,1.7v8.4c0,0.9,0.8,1.7,1.7,1.7H70.6z M51.8,7h28.4v15c0,0.9-0.4,1.7,0.5,1.7l4.3,0.1
                      l-8.8,12.9l-9.1-13h2.7c1,0,3,0.2,3-0.7l0.1-8.1c0-0.8,0.2-0.5-0.7-0.5H51.8V7z M38.8,83.5l-15-9.6c-0.5-0.3-1.2-0.4-1.8-0.1
                      c-0.6,0.3-0.9,0.9-0.9,1.5v4.1h-5.2v-19c0-0.9-0.8-1.7-1.7-1.7H5.7c-1,0-1.7,0.8-1.7,1.7v29.1c0,0.9,0.8,1.7,1.7,1.7h15.4v3.4
                      c0,0.6,0.3,1.2,0.9,1.5c0.7,0.4,1.5,0.1,1.8-0.1l15-9.6c0.5-0.3,0.8-0.9,0.8-1.4C39.6,84.3,39.3,83.8,38.8,83.5z M23.5,93.6
                      c0.3-4.2-0.5-4.4-1.4-4.4h-16v-28h7.6v18.4c0,0.9,0.6,1.7,1.5,1.7h7.7c1,0,0.7-3.1,0.7-4.1v-1.1l13.5,8.8
                      C37,84.9,23.5,93.7,23.5,93.6z M71.6,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C71.1,68,71.6,68.5,71.6,69.1z M78.6,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C78.1,68,78.6,68.5,78.6,69.1z M78.6,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C78.1,57,78.6,57.5,78.6,58.1z M78.6,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C78.1,62.5,78.6,63,78.6,63.6z M64.5,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C64,57,64.5,57.5,64.5,58.1z M64.5,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C64,68,64.5,68.5,64.5,69.1z M64.5,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C64,62.5,64.5,63,64.5,63.6z M71.6,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C71.1,73.5,71.6,74,71.6,74.6z M78.6,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C78.1,73.5,78.6,74,78.6,74.6z M71.6,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C71.1,57,71.6,57.5,71.6,58.1z M71.6,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C71.1,62.5,71.6,63,71.6,63.6z M64.5,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                      C64,73.5,64.5,74,64.5,74.6z"></path>
                  </svg>
               `
            },
            {
               title:"WordPress API Integration",
               text:"Integrate your WordPress applications and web services with other applications with API integration.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M17.4,50.4H0.3V22.4h4.3c0-0.1,0-0.1,0-0.2C4.5,9.8,14.7-0.4,27.1-0.4c12.5,0,22.6,10.1,22.6,22.6
                        S39.6,44.8,27.1,44.8c-1.9,0-3.8-0.2-5.7-0.7v2.5L17.4,50.4z M2,48.6h13.9l0-3.6h3.8V24.2H2V48.6z M17.6,46.8l0,1l1.1-1H17.6z
                        M21.4,42.3c1.1,0.3,2.2,0.5,3.4,0.7c-1.2-1.3-2.4-2.6-3.4-3.9C21.4,39.1,21.4,42.3,21.4,42.3z M37.7,30c-1.4,4.5-4.1,8.9-8.1,13
                        c7.7-0.9,14.1-6,16.9-13C46.5,30,37.7,30,37.7,30z M28,30v12c3.8-3.8,6.4-7.8,7.9-12H28z M21.4,36c1.3,2,2.9,4,4.8,5.9V30h-4.8V36z
                        M38.2,28.2h8.9c0.6-1.9,0.9-3.9,0.9-6c0-2.1-0.3-4.1-0.9-6h-9c0.5,2.1,0.8,4.4,0.8,6.7C38.8,24.7,38.6,26.5,38.2,28.2z M28,28.2
                        h8.4c0.4-1.7,0.7-3.5,0.7-5.3c0-2.4-0.3-4.6-0.8-6.7H28V28.2z M21.4,28.2h4.8v-12h-8.2c-0.5,2-0.8,4.1-0.8,6.3h4.2V28.2z M6.3,22.4
                        h9.2c0-2.2,0.3-4.3,0.8-6.3H7.2c-0.6,1.9-0.9,3.9-0.9,6C6.3,22.3,6.3,22.4,6.3,22.4z M37.6,14.4h8.9C43.8,7.8,37.8,2.8,30.5,1.7
                        C32.5,4,35.8,8.6,37.6,14.4z M28,14.4h7.7c-2.2-6.8-6.6-11.7-7.7-13V14.4z M18.6,14.4h7.7V1.5C25,2.9,20.8,7.8,18.6,14.4z
                        M7.8,14.4h9C18.5,8.6,21.8,4,23.8,1.6C16.5,2.8,10.5,7.8,7.8,14.4z"></path>
                     </g>
                     <path class="st0" d="M7.7,30.1c-0.2-0.2-0.4-0.2-0.6,0l-3.9,4.1c-0.2,0.2-0.2,0.4,0,0.6l3.9,4.1C7.2,39,7.3,39,7.4,39
                     c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.4,0-0.6l-3.6-3.8l3.6-3.8C7.9,30.5,7.9,30.2,7.7,30.1z M18.5,34.2l-3.9-4.1
                     c-0.2-0.2-0.4-0.2-0.6,0c-0.2,0.2-0.2,0.4,0,0.6l3.6,3.8L14,38.3c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1
                     c0.1,0,0.2,0,0.3-0.1l3.9-4.1C18.7,34.6,18.7,34.4,18.5,34.2z M12.6,30c-0.2-0.1-0.5,0-0.6,0.2l-3.1,8.1c-0.1,0.2,0,0.5,0.2,0.6
                     c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4-0.3l3.1-8.1C12.9,30.4,12.8,30.1,12.6,30z"></path>
                  </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
