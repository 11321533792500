<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/web-development-company.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Genixbit a Bespoke web development company</h2>
					<p>
						Speed, innovation and flawless performance. If these three things rank high on your
						priorities for web development projects, then Genixbit is a perfect fit. For close to
						a decade we have been mastering the art of creating cutting-edge web portals for a
						diverse range of industries.
					</p>
					<p>
						From the arithmetically growing eCommerce industry to individual portfolio websites that
						showcase talent to the world, we have worked on a broad range of web development
						projects.
					</p>
					<p>
						The web portals we create are enriched with functionalities like geo-location tracking,
						Cloud Computing, IoT, Augmented Reality, Virtual Reality among many others.
					</p>
					<p>
						We have also worked on countless Content Management Systems (CMS) that help businesses
						create, manage and optimize their content systems for business utilities. To ensure that
						your web portals deliver an engaging experience to mobile users, we also provide
						responsive designing as a part of our web development service.
					</p>
					<p>
						Rest assured, your website is accessible not just on a browser, but on all device
						screens with varying screen sizes.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
