<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/chatbot-main.gif"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Why you need Chatbot Development Services
					</h2>
					<p>
						Unless you have been off the grid for a really long time, you would have encountered at
						least one chatbot in your digital interactions. From social media messengers to website
						landing pages, chatbots have established presence everywhere. Customers too love
						interacting with customers for several reasons.
					</p>
					<p>
						They slash down the time taken to respond to customer queries to a fraction. They tend
						to customer queries round the clock and can handle any volume of traffic easily. If you
						are a business that is keen on serving customers proactively, chatbots can come to your
						aid. At Intellivita, our chat app development expertise has helped businesses deliver
						superior customer experience from day one.
					</p>
					<p>
						Your business can also benefit from the brilliance of an AI-infused chatbot. From
						heavy-duty manufacturing to hyper-sensitive hospitals and even the delicate customer
						support, our services as a chatbot development company have built chatbots for a variety
						of use cases. Do you have a chatbot requirement? Our chatbot development services will
						help you build the perfect chatbot for your business.
					</p>
					<p>
						Why wait any longer? Talk to our experts today to know more about how GenixBit Labs can
						build a chatbot for your business in quicktime.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>