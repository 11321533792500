<template>
	<section class="contact-map-main clearfix d-flex" style="max-width: 100%; overflow: hidden">
		<ul class="map-tabs-nav mb-0">
			<li class="tab-active">
				<a href="#tab-1">
					<img class="lazy" alt="" src="../../assets/images/ahmedabd-img.jpg" />
					<h4 class="area-name">Ahmedabad</h4>
				</a>
			</li>
			<li>
				<a href="#tab-2">
					<img class="lazy" alt="" src="../../assets/images/bengaluru.jpg" />
					<h4 class="area-name">Bengaluru</h4>
				</a>
			</li>
			<li>
				<a href="#tab-3">
					<img class="lazy" alt="" src="../../assets/images/Nagpur-India.jpg" />
					<h4 class="area-name">Nagpur</h4>
				</a>
			</li>
		</ul>
		<div class="map-tabs-iframe">
			<div class="mapiframe" id="tab-1">
				<div class="mapouter">
					<div class="gmap_canvas">
						<iframe
							width="1093"
							height="644"
							id="gmap_canvas"
							src="https://maps.google.com/maps?q=MN%20WEBBASED-%20eCommerce,%20Web%20&%20Mobile%20App%20Development,%20eCommerce%20Digital%20Marketing%20Services&t=&z=13&ie=UTF8&iwloc=&output=embed"
							frameborder="0"
							scrolling="no"
							marginheight="0"
							marginwidth="0"
						></iframe>
					</div>
				</div>
			</div>
			<div class="mapiframe" id="tab-2">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2356.371006913903!2d-1.5528080837939586!3d53.80068168007662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48795c1d7055c793%3A0xfef87e998646f65a!2sMN Webbased!5e0!3m2!1sen!2sin!4v1513592167925"
					style="border: 0"
					allowfullscreen=""
					width="100%"
					height="100%"
					frameborder="0"
				></iframe>
			</div>
			<div class="mapiframe" id="tab-3">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.7364081492346!2d72.4962650155135!3d22.996718484966088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84d577bc3aaf%3A0xe9c0a58f57a26fea!2sMN Webbased!5e0!3m2!1sen!2sin!4v1522998255984"
					style="border: 0"
					allowfullscreen=""
					width="100%"
					height="100%"
					frameborder="0"
				></iframe>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@import url('../../assets/css/map.css');
@media (min-width: 991px) {
	.map-tabs-nav {
			height: 645px;
	}
	.map-tabs-nav li {
		height: 33.33%;
		overflow: hidden;
	}
	.map-tabs-nav li a {
		display: block;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
	.map-tabs-nav li a img {
		height: 100% !important;
		width: 100%;
	}
}
.area-name {
	color: #fff;
	font-weight: bold;
	background: #3b3a3ab5;
	padding: 3px 6px;
}
@media (max-width: 776px) {
	.area-name {
		font-size: 12px !important;
		text-align: center;
	}
	.map-tabs-nav li {
		height: 90px;
		width:33.33%;
		overflow: hidden;
	}
	.map-tabs-nav li a {
		display: block;
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
	.map-tabs-nav li a img {
		height: 100% !important;
		width: 100%;
	}
}
</style>
