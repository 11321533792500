<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>What makes it cool? </span>
				<h2 class="fxt-process-dev">The advantage of our instant messaging app solution</h2>
				<p>
					GenixBit Labs strives to deliver value to clients. Our instant messaging app development
					solution is docked with benefits that will make your time and money spent worthwhile.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../../EnterpriseSolutions/AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
                    {svg:`<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M33.3,18.3c-0.2-1.7-1.5-3-3.1-3.2c-1-0.1-2,0.2-2.8,0.9c-0.8,0.7-1.2,1.6-1.2,2.6c0,0.4,0.1,0.7,0.2,1
                    c0.2,0.6,0.5,1.1,0.9,1.5c0.1,0.1,0.1,0.1,0.2,0.2l-1.2,2.7c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.5,0.2,0.7c0.2,0.3,0.5,0.5,0.8,0.5
                    c0,0,0,0,0,0h3h1.5h0.4c0.4,0,0.7-0.2,0.9-0.5s0.2-0.7,0.1-1L32,21.4C33,20.6,33.4,19.5,33.3,18.3z M30.6,21.8l0.6,1.5l0.3,0.7
                    h-1.2h-0.4h-1.9l0.4-0.9l0.6-1.3c0.1-0.2,0.1-0.4,0.1-0.6c0,0,0,0,0,0c0-0.4-0.2-0.7-0.5-0.9c-0.2-0.2-0.4-0.4-0.5-0.6
                    c-0.2-0.3-0.3-0.7-0.3-1c0-0.6,0.2-1.1,0.7-1.5c0.4-0.4,1-0.6,1.6-0.5c0.9,0.1,1.7,0.9,1.8,1.8c0.1,0.7-0.2,1.4-0.8,1.8
                    c0,0-0.1,0.1-0.1,0.1C30.5,20.7,30.3,21.3,30.6,21.8z"></path>
                    <path d="M8.3,32.8c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0
                    c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                    c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2,0.1,0.4,0.2,0.5c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0s0.1,0,0.1,0
                    c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.5c0,0,0-0.1,0-0.1
                    C8.3,32.9,8.3,32.9,8.3,32.8z"></path>
                    <path d="M37.5,11.7h-0.7V7.4c0-3.9-3.2-7-7-7s-7,3.2-7,7v4.3h-0.7c-1.1,0-2,0.9-2,2v5.9H3.3c-1.6,0-2.8,1.3-2.8,2.8v14.3
                    c0,1.6,1.3,2.8,2.8,2.8H29c1.6,0,2.8-1.3,2.8-2.8v-8h3.8c2.1,0,3.8-1.7,3.8-3.8V13.7C39.5,12.6,38.6,11.7,37.5,11.7z M24.3,7.4
                    c0-3,2.5-5.5,5.5-5.5s5.5,2.5,5.5,5.5v4.3h-11V7.4z M2.1,22C2.1,22,2.1,22,2.1,22c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1
                    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
                    c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0
                    c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0h16.7V25c0,0.9,0.3,1.8,0.9,2.5l-3.8,2.2c-0.6,0.3-1.3,0.3-2,0l-1.6-0.9L2.1,22
                    C2.1,22.1,2.1,22,2.1,22z M30.2,37.3C30.2,37.3,30.2,37.3,30.2,37.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0
                    c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
                    c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
                    c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0H3.3c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0C3,38,3,38,3,38
                    c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
                    c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0
                    c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1l3.2-1.9c0.4-0.2,0.5-0.7,0.3-1c-0.2-0.4-0.7-0.5-1-0.3L2,35.4V23.8l10.1,5.8
                    l-2.5,1.5c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.7,0.5,1,0.3l3.3-1.9l0.5,0.3c0.6,0.4,1.3,0.5,2,0.5c0.7,0,1.4-0.2,2-0.5l0.5-0.3
                    L30.2,37.3C30.3,37.2,30.2,37.2,30.2,37.3z M30.3,35.4l-10.1-5.8l2-1.2c0.5,0.2,1,0.4,1.6,0.4h6.5V35.4z M35.7,27.3h-3.8h-1.5h-6
                    h-0.4c-0.6,0-1.1-0.2-1.6-0.6c-0.4-0.4-0.7-1-0.7-1.7v-3.8v-1.5v-5.9c0-0.3,0.2-0.5,0.5-0.5h1.4h12.6h1.4c0.3,0,0.5,0.2,0.5,0.5
                    V25l0,0C38,26.3,37,27.3,35.7,27.3z"></path>
                  </g>
                </g>
              </g>
            </svg>`,
          title:"Encrypted Messages",
          text:"Your chats should be for your eyes and your recipient's eyes only and no one else. We fortify the instant messaging apps we build with high-end encryption that keeps your chat and media safe from all possible data security threats."},
            {svg:`<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
              <path d="M23.2,6.7c-4.2,0-7.7,2.5-9.4,6c-0.9-0.6-1.9-1.1-3.1-1.1c-3,0-5.4,2.4-5.5,5.3C2.2,18,0,20.8,0,24.1c0,4.3,3.5,7.7,7.7,7.7
              h25.1c3.9,0,7.1-3.2,7.1-7.1c0-3.6-2.8-6.5-6.3-7c0-0.2,0-0.4,0-0.6C33.7,11.4,29,6.7,23.2,6.7z M23.2,8.3c4.9,0,8.9,4,8.9,8.9
              c0,0.4,0,0.7-0.1,1.1l-0.1,0.9h0.9c0.1,0,0.1,0,0,0c3.1,0,5.5,2.5,5.5,5.5s-2.5,5.5-5.5,5.5H7.7c-3.4,0-6.1-2.7-6.1-6.1
              c0-2.8,1.9-5.2,4.5-5.9L6.7,18l0-0.6c0-0.1,0-0.2,0-0.2c0-2.2,1.8-4,4-4c1.1,0,2.1,0.4,2.8,1.2l0.9,0.9l0.4-1.2
              C16.1,10.7,19.3,8.3,23.2,8.3z M20.8,15.1c-3.2,0-5.9,2.4-6.3,5.5c0,0,0,0.1,0,0.1h1.6c0.4-2.3,2.4-4,4.7-4c1.3,0,2.5,0.5,3.4,1.4
              l-1.8,1.8h4.8v-4.8l-1.9,1.9C24.1,15.7,22.5,15.1,20.8,15.1z M25.5,22.3c-0.4,2.3-2.4,4-4.7,4c-1.3,0-2.6-0.5-3.5-1.3l1.9-1.9h-4.8
              v4.8l1.8-1.8c1.2,1.1,2.9,1.8,4.6,1.8c3.3,0,5.9-2.4,6.3-5.6H25.5z"></path>
            </svg>`,
          title:"Cloud Synchronization",
          text:"Chats are meant to go wherever you go. They should not be siloed making information scattered and less accessible. We can help you access your chats anywhere, anytime with cloud synchronization."},
            {svg:`<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M50.7,73.6l-2.5,0.5v8.7c0,0.5-0.7,1.2-1.7,1.2h-4.9c-1,0-1.7-0.7-1.7-1.2v-8.7l-2.5-0.5c-3.5-0.8-7-2.2-10.1-4.2
                  l-2.2-1.3L23.3,70L19,74.3c-0.2,0.2-0.3,0.2-0.7,0.2c-0.5,0-1-0.2-1.3-0.7l-3.5-3.3c-0.7-0.7-0.8-1.7-0.3-2l4.4-4.3l1.8-1.8
                  L18,60.1c-2-3-3.4-6.5-4.2-10l-0.5-2.5H4.4c-0.5,0-1.2-0.7-1.2-1.7v-4.8c0-1,0.7-1.7,1.2-1.7h8.7l0.5-2.5c0.8-3.5,2.2-6.8,4.2-10
                  l1.3-2.2L17.5,23l-4.4-4.3c-0.3-0.3-0.3-1.3,0.3-2l3.4-3.5c0.3-0.3,0.8-0.7,1.3-0.7c0.3,0,0.5,0,0.7,0.2l4.4,4.3l1.8,1.8l2.2-1.3
                  c3.2-2,6.5-3.3,10.1-4.2l2.5-0.3V4.3c0-0.5,0.7-1.2,1.7-1.2h4.9c1,0,1.7,0.7,1.7,1.2V13l2.5,0.5c3.5,0.8,7,2.2,10.1,4.2l2.2,1.3
                  l1.8-1.8l4.4-4.3c0.2-0.2,0.3-0.2,0.7-0.2c0.5,0,1,0.2,1.3,0.7l3.5,3.5c0.7,0.7,0.8,1.7,0.3,2L70.7,23l-1.8,1.8l1.3,2.2
                  c2,3,3.4,6.5,4.2,10l0.5,2.5h8.7c0.5,0,1.2,0.7,1.2,1.7v4.8c0,1-0.7,1.7-1.2,1.7h-8.7l-0.5,2.5c-0.5,2.2-1.2,4.3-2.2,6.3l2.3,2.2
                  c1.2-2.5,2.2-5,2.9-7.8h6.2c2.3,0,4.4-2.2,4.4-4.8v-4.8c0-2.7-2-4.8-4.4-4.8h-6.2c-0.8-4-2.5-7.7-4.5-11l4.4-4.3
                  c1.7-1.7,1.5-4.5-0.3-6.5L73.3,11c-1-1-2.3-1.5-3.7-1.5c-1,0-2.2,0.3-2.9,1.2L62.4,15c-3.4-2.2-7-3.7-11.1-4.5V4.3
                  C51.4,2,49.2,0,46.5,0h-4.9c-2.7,0-4.9,2-4.9,4.3v6.2c-4,0.8-7.7,2.5-11.1,4.5l-4.4-4.3c-0.8-0.8-1.8-1.2-2.9-1.2
                  c-1.3,0-2.5,0.5-3.7,1.5l-3.7,3.5c-1.8,1.8-2,4.8-0.3,6.5l4.4,4.2c-2.2,3.3-3.7,7-4.5,11H4.4C2,36.2,0,38.4,0,41.1v4.8
                  c0,2.7,2,4.8,4.4,4.8h6.2c0.8,4,2.5,7.8,4.5,11l-4.4,4.3c-1.7,1.7-1.5,4.5,0.3,6.5l3.5,3.5c1,1,2.3,1.5,3.7,1.5
                  c1,0,2.2-0.3,2.9-1.2l4.4-4.3c3.4,2.2,7,3.7,11.1,4.5v6.2c0,2.3,2.2,4.3,4.9,4.3h4.9c2.7,0,4.9-2,4.9-4.3v-6.2
                  c2.3-0.5,4.7-1.3,6.9-2.3l-2.2-2.5C54.4,72.6,52.5,73.3,50.7,73.6z M87.1,89c0.2,0.3,0.5,0.5,1,0.5c0.3,0,0.8-0.2,1.2-0.5
                  c0.7-0.7,0.7-1.7,0-2.2l-9.4-9.3c-0.7-0.7-1.7-0.7-2.2,0c-0.7,0.7-0.7,1.7,0,2.2L87.1,89z M98.7,84.8L62.3,51.6
                  c1-2.3,1.7-5,1.7-7.9c0-10.7-8.7-19.5-19.6-19.5c-4.5,0-8.9,1.5-12.1,4.2l11.6,11.5c0.3,0.3,0.7,0.5,1.2,0.5s0.8-0.2,1.2-0.5
                  c0.7-0.7,0.7-1.7,0-2.2L37.3,29c2.2-1,4.5-1.7,7.2-1.7c9.1,0,16.4,7.3,16.4,16.4c0,3.2-0.8,6-2.3,8.5l2.3,2.2l35.6,32.2
                  c0.5,0.5,0.5,1.3,0,1.8L88.9,96c-0.5,0.5-1,0.5-1.2,0.5c-0.2,0-0.5,0-0.8-0.3L54,60.6l-0.2-0.2l-1-1l-1.2-1.2
                  c-2.2,1.2-4.9,1.8-7.6,1.8c-9.1,0-16.4-7.3-16.4-16.4c0-2.2,0.5-4.2,1.2-6l8.6,8.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.8-0.2,1.2-0.5
                  c0.7-0.7,0.7-1.7,0-2.2L28.2,32.7c-2.2,3.2-3.5,7-3.5,11.2c0,10.7,8.7,19.5,19.6,19.5c2.3,0,4.7-0.5,6.9-1.2l33.6,36.4
                  c0.8,0.8,2,1.3,3.2,1.3c1.2,0,2.3-0.5,3.2-1.3l7.6-7.5C100.4,89.5,100.5,86.6,98.7,84.8z"></path>
                </g>
              </g>
            </svg>`,
          title:"100% Customizable",
          text:"Chat app for your organization? Social media app for your online store? A personal chat app for one to on talks. Customize our instant messaging app solution to meet any specific messaging needs."},
            {svg:`<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
              <g>
                <g>
                  <path class="st0" d="M20,0.1C8.9,0.1,0,9,0,20s8.9,20,20,20s20-8.9,20-20S31,0.1,20,0.1z M20,38.8C9.6,38.8,1.2,30.4,1.2,20
                  S9.6,1.3,20,1.3S38.7,9.7,38.7,20S30.3,38.8,20,38.8z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.6,17c-1.5,0-2.7,1-3,2.4h-1V9.9c0-0.4-0.2-0.6-0.6-0.6h-9.5v-1C22,8.1,23,6.9,23,5.4c0-1.7-1.3-3-3-3
                  c-1.7,0-3,1.3-3,3c0,1.5,1,2.7,2.4,3v1H9.9c-0.4,0-0.6,0.2-0.6,0.6v9.5h-1C8,18,6.8,17,5.3,17c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3
                  c1.5,0,2.7-1,3-2.4h1v9.5c0,0.4,0.2,0.6,0.6,0.6h9.5v1c-1.4,0.3-2.4,1.5-2.4,3c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3c0-1.5-1-2.7-2.4-3
                  v-1h9.5c0.4,0,0.6-0.2,0.6-0.6v-9.5h1c0.3,1.4,1.5,2.4,3,2.4c1.7,0,3-1.3,3-3C37.6,18.4,36.3,17,34.6,17z M18.1,5.4
                  c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S21,7.2,20,7.2C19,7.2,18.1,6.4,18.1,5.4z M5.3,21.9c-1,0-1.8-0.8-1.8-1.8
                  s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S6.3,21.9,5.3,21.9z M21.8,34.7c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8
                  c0-1,0.8-1.8,1.8-1.8C20.9,32.9,21.8,33.7,21.8,34.7z M29.4,29.5h-19v-19h19L29.4,29.5L29.4,29.5z M34.6,21.9
                  c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S35.6,21.9,34.6,21.9z"></path>
                </g>
                <g>
                  <polygon class="st0" points="16.7,19.7 13.5,18.2 13.5,18.1 16.7,16.7 16.7,15.7 12.5,17.6 12.5,18.8 16.7,20.7    "></polygon>
                </g>
                <g>
                  <polygon class="st0" points="21.5,14.5 17.6,22.8 18.5,22.8 22.3,14.5    "></polygon>
                </g>
                <g>
                  <polygon class="st0" points="27.4,17.6 23.2,15.7 23.2,16.7 26.4,18.1 26.4,18.2 23.2,19.7 23.2,20.7 27.4,18.8    "></polygon>
                </g>
                <g>
                  <path class="st0" d="M14.5,25.5h-2c-0.4,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6h2c0.4,0,0.6-0.2,0.6-0.6S14.8,25.5,14.5,25.5z"></path>
                </g>
                <g>
                  <path class="st0" d="M18.7,25.5h-1.9c-0.4,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6h1.9c0.4,0,0.6-0.2,0.6-0.6S19.1,25.5,18.7,25.5z"></path>
                </g>
                <g>
                  <path class="st0" d="M23.1,25.5h-1.9c-0.4,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6h2c0.4,0,0.6-0.2,0.6-0.6
                  C23.7,25.7,23.5,25.5,23.1,25.5z"></path>
                </g>
                <g>
                  <path class="st0" d="M27.5,25.5h-2c-0.4,0-0.6,0.2-0.6,0.6s0.2,0.6,0.6,0.6h2c0.4,0,0.6-0.2,0.6-0.6S27.8,25.5,27.5,25.5z"></path>
                </g>
              </g>
            </svg>`,
          title:"Full Access to Source Code",
          text:"Your app, your rules. Make it work anyway with access to the source code. We give access to the source code so that you can decide which features and which ones to modify to suit your requirements."},
            {svg:`<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
              <g>
                <path d="M13.8,22.6l5.9,3.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1l5.9-3.6c0.2-0.1,0.3-0.3,0.3-0.5V15c0-0.2-0.1-0.4-0.3-0.5
                l-5.9-3.6c-0.1-0.1-0.2-0.1-0.3-0.1l0,0c-0.1,0-0.2,0-0.2,0.1l-5.9,3.6c-0.2,0.1-0.3,0.3-0.3,0.5v7.2
                C13.5,22.4,13.6,22.5,13.8,22.6z M15.1,15l4.9-3l4.9,3l-4.9,3L15.1,15z M20.5,24.8v-5.9l4.9-3v5.9L20.5,24.8z M14.6,21.8v-5.9
                l4.9,3v5.9L14.6,21.8z"></path>
                <ellipse cx="20" cy="36.1" rx="1.4" ry="1.5"></ellipse>
                <path d="M28.9,0H11.1C9.9,0,8.9,1.1,8.9,2.4v35.1c0,1.3,1,2.4,2.2,2.4h17.8c1.2,0,2.2-1.1,2.2-2.4V2.4C31.1,1.1,30.1,0,28.9,0z
                M9.9,33.5h20.2v3.9c0,0.7-0.5,1.2-1.2,1.2H11.1c-0.7,0-1.2-0.5-1.2-1.2V33.5z M30.1,5.6v26.8H9.9V5.6H30.1z M11.1,1.1h17.8
                c0.7,0,1.2,0.5,1.2,1.2v2.1H9.9V2.4C9.9,1.7,10.4,1.1,11.1,1.1z"></path>
              </g>
            </svg>`,
          title:"Lightweight Application",
          text:"Don’t cough too much storage for a single app. Our app requires minimal system requirements that will be lighter on your storage as well as RAM memory. You can work it on any system without any trouble."},
            {svg:`<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M82,56.6H70.8v-3.4c0-1-1-1.8-1.9-1.8h-0.2c-0.7,0-1.2,0.5-1.4,1c-0.2,0.3-0.3,0.6-0.3,0.9v3.4H55.9c-0.5,0-1,0.2-1.4,0.6
                  l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.3,0.3-0.5,0.7-0.5,1l0,0.1l0,0.1c0,0.3,0.1,0.6,0.3,0.9L54,60l0.5,0.3
                  c0.2,0.1,0.6,0.4,1.2,0.4H62c0.1,0.4,0.3,0.9,0.5,1.5l-0.1,0l0.1,0.4c0.5,1.3,1,2.6,1.9,4.1c0.5,0.9,1.1,1.8,1.8,2.7
                  c-2.5,2.4-5.6,4.1-9.6,5.4c-0.5,0-0.8,0.4-1,0.6l-0.1,0.1c-0.2,0.4-0.4,0.9-0.4,1.4c0.2,0.8,1,1.4,1.9,1.4h0.3l0.1,0
                  c4.5-1.3,8.3-3.4,11.5-6.4c3.1,3,7,5.1,11.5,6.4l0.4,0c0.3,0,0.7-0.1,1-0.3h1.6l-1.1-0.3c0.2-0.2,0.3-0.3,0.4-0.6
                  c0.2-0.6,0-1.1-0.2-1.4c-0.2-0.4-0.7-0.7-1.1-0.9c-4-1.1-7.2-2.9-9.8-5.5c0.3-0.4,0.6-0.9,0.9-1.4c0.3-0.4,0.5-0.7,0.7-1.1
                  l0.2-0.3l-0.1-0.1c0.8-1.3,1.3-2.5,1.7-3.8c0.3-0.8,0.5-1.4,0.6-2h6.4c0.6,0,1.2-0.3,1.6-0.7l5.3-5.2l-5.5,2.7
                  C83.2,56.9,82.6,56.6,82,56.6z M68.9,66c-1.1-1.7-2-3.6-2.5-5.4h4.9C71,61.7,70.3,63.7,68.9,66z M44.9,39.1c0.3,0,0.6,0,1-0.2
                  c0.6-0.4,0.8-0.8,1-1.1l0.1-0.2L47,37.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.7L33.6,13v-0.2l-0.3-0.3
                  c-0.1,0-0.2-0.2-0.4-0.2l-0.2-0.2h-1.8l0.1,0.1c0,0,0,0,0,0h-0.1l0,0l-0.2-0.3l-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.5L17,36.1
                  c-0.2,0.3-0.4,0.8-0.2,1.5l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6l-1,1h3c0.7,0,1.1-0.4,1.4-0.7l5.1-9.1h12.9l4.8,8.6
                  C43.7,38.7,44.3,39.1,44.9,39.1z M27.2,25.6l4.5-8l4.5,8H27.2z M98.4,42.3c-1.2-1.2-2.8-1.9-4.5-1.9H63.1V7.4
                  c0-3.4-2.8-6.2-6.3-6.2H6.6C3.1,1.2,0.3,4,0.3,7.4V43L0,43v0.4c0,0.2,0,0.4,0,0.5c0.1,1.3,0.5,2.4,1.2,3.3l0.3,0.3l0.3-0.2
                  c1.2,1.4,3,2.2,4.9,2.2h5.8v7.7c0,0.8,0.4,1.5,1,1.6l-1.2,0.9l2.2-0.9c0.2,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.2,0.4-0.3l2-1.6
                  L17,57.1l8.1-7.6h12.1v33.1c0,3.4,2.8,6.2,6.3,6.2h0.3v-0.2h31l-0.2,0.2l10.1,9.5c0.2,0.2,0.3,0.3,0.5,0.4
                  c0.2,0.1,0.4,0.1,0.7,0.1h0.4v-0.5c0.2,0,0.4-0.1,0.6-0.2c0.7-0.4,1-1.1,1-1.6v-7.7h5.8c3.5,0,6.3-2.8,6.3-6.2V46.7
                  C100.1,45,99.5,43.5,98.4,42.3z M37.4,45.8H24.2c-0.8,0-1.1,0.3-1.3,0.6L16,52.9v-5.1c0-1-1-1.8-1.9-1.8H6.6
                  c-1.4,0-2.5-1.1-2.5-2.5V7.4C4.1,6,5.2,5,6.6,5h50.2c1.4,0,2.5,1.1,2.5,2.3v33.1H43.6c-0.8,0-1.6,0.1-2.3,0.4l0-0.1l0,0.1
                  C39.2,41.7,37.7,43.5,37.4,45.8z M96.5,82.3h-0.2v0.4c0,1.3-1.1,2.3-2.5,2.3h-7.7c-0.9,0-1.7,0.7-1.9,1.6l-0.7,0.5l0.7-0.2v5.2
                  l-6.9-6.5c-0.3-0.3-0.8-0.4-1.3-0.4H43.8c-1.4,0-2.5-1.1-2.5-2.3V46.8l0.2,0.1l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.6
                  c0.1-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.5,0.7-0.6l0.4-0.1l0-0.1c0.1,0,0.3-0.1,0.5-0.1h50.2c0.2,0,0.4,0.1,0.6,0.1l0,0.1l0.3,0.1
                  L95,45l0,0c0.3,0.1,0.5,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7l0.2,0.4l0.3-0.2V82.3z"></path>
                </g>
              </g>
            </svg>`,
          title:"Multilingual",
          text:"Let the app speak the language of your users with multi-lingual compatibility. You can set the language preferences in the admin panel or let users choose their own preferred language while configuring the app."}
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
