<template>
	<section class="padding-60 bg-light">
		<div class="container">
			<div class="title">
				<span>Our Value</span>
				<h2 class="fxt-unique-dev">What Makes Us Unique</h2>
				<p>
					At the heart of GenixBit Labs is a team of dreamers and doers who are
					laser-focused on delivering value to clients. We have a collective can-do
					attitude, the right skills to conquer challenges and the integrity to do the
					right thing always.
				</p>
			</div>
			<div class="Feature-slider slick-initialized slick-slider fxt-val-slider">
				<div class="slick-list draggable scroll-me">
					<div
						class="slick-track d-felx justify-content-center align-items-center flex-wrap flex-lg-nowrap"
						style="
							opacity: 1;
							transform: translate3d(0px, 0px, 0px);
							margin-left: 0;
							margin-right: 0;
						"
					>
						<div
							class="slick-slide slick-current slick-active"
							data-slick-index="0"
							aria-hidden="false"
							style="width: 293px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="why-choose-box">
										<svg
											viewBox="0 0 50 50"
											style="enable-background: new 0 0 50 50"
											xml:space="preserve"
										>
											<path
												fill="#313131"
												d="M44.3,33.3H28.5c-0.5,0-0.9,0.4-0.9,0.8v2.5h1.8V35h14v13.3h-14v-0.8h-1.8v1.7c0,0.5,0.4,0.8,0.9,0.8h15.8
                     c0.5,0,0.9-0.4,0.9-0.8v-15C45.1,33.7,44.7,33.3,44.3,33.3z M34.6,46.7h3.5c0.5,0,0.9-0.4,0.9-0.8V42c0.6-0.6,0.9-1.3,0.9-2.1
                     c0-1.8-1.6-3.3-3.5-3.3c-1.9,0-3.5,1.5-3.5,3.3c0,0.8,0.3,1.5,0.9,2.1v3.8C33.8,46.3,34.1,46.7,34.6,46.7z M36.4,38.3
                     c0.9,0,1.7,0.7,1.8,1.5c0,0.4-0.2,0.9-0.6,1.1c-0.2,0.2-0.3,0.4-0.3,0.6V45h-1.8v-3.4c0-0.2-0.1-0.5-0.3-0.6
                     c-0.4-0.3-0.6-0.7-0.6-1.1C34.7,39,35.4,38.3,36.4,38.3z M36.4,18.7c-1.5,0-2.6,1.1-2.6,2.5c0,1.4,1.2,2.5,2.6,2.5
                     c1.5,0,2.6-1.1,2.6-2.5C39,19.9,37.8,18.7,36.4,18.7z M36.4,22.1c-0.5,0-0.9-0.4-0.9-0.8c0-0.5,0.4-0.8,0.9-0.8
                     c0.5,0,0.9,0.4,0.9,0.8C37.3,21.7,36.9,22.1,36.4,22.1z M12.7,39.6c-0.7,0-1.3,0.6-1.3,1.2c0,0.7,0.6,1.3,1.3,1.3
                     c0.7,0,1.3-0.6,1.3-1.3C14.1,40.1,13.5,39.6,12.7,39.6z M12.7,41.3c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4
                     S13,41.3,12.7,41.3z M36.4,0c-6.8,0-12.3,4.9-12.3,11c0,2.5,0.9,4.9,2.6,6.8c1.5,1.7,2.5,6,2.5,8.1c0,0.5,0.4,0.8,0.9,0.8h12.6
                     c0.5,0,0.9-0.4,0.9-0.8c0-2.1,1-6.4,2.5-8.1c1.7-1.9,2.6-4.3,2.6-6.8C48.6,4.9,43.1,0,36.4,0z M44.7,16.7C43,18.6,42,22.5,41.9,25
                     h-11c-0.2-2.5-1.2-6.4-2.8-8.3c-1.4-1.6-2.2-3.6-2.2-5.7c0-5.1,4.7-9.3,10.5-9.3c5.8,0,10.5,4.2,10.5,9.3
                     C46.9,13.1,46.1,15.1,44.7,16.7z M42.5,28.3c0-0.5-0.4-0.8-0.9-0.8H31.1c-0.5,0-0.9,0.4-0.9,0.8c0,0.5,0.4,0.8,0.9,0.8h10.5
                     C42.1,29.2,42.5,28.8,42.5,28.3z M32,30c-0.5,0-0.9,0.4-0.9,0.8c0,0.5,0.4,0.8,0.9,0.8h8.8c0.5,0,0.9-0.4,0.9-0.8
                     c0-0.5-0.4-0.8-0.9-0.8H32z M39.2,3.3c-0.1,0-0.1,0-0.2,0h-5.3c-0.5,0-0.9,0.4-0.9,0.8c0,0.1,0,0.1,0,0.2l2.6,12.5
                     c0.1,0.5,0.6,0.7,1,0.7c0.3-0.1,0.6-0.3,0.7-0.7l2.6-12.5C40,3.9,39.7,3.4,39.2,3.3z M36.4,12.4L34.8,5h3.1L36.4,12.4z M28.5,45
                     v-1.7h0.9c1.5,0,2.6-1.1,2.6-2.5c0-1.4-1.2-2.5-2.6-2.5H16.2c-1.5-1.8-4.2-2.2-6.1-0.8c-1.9,1.4-2.3,4-0.9,5.8
                     c1.5,1.8,4.2,2.2,6.1,0.8c0.3-0.2,0.6-0.5,0.9-0.8h7V45c0,0.5,0.4,0.8,0.9,0.8h3.5C28.1,45.8,28.5,45.5,28.5,45z M26.8,42.5v1.7
                     H25v-1.7c0-0.5-0.4-0.8-0.9-0.8h-8.4c-0.3,0-0.6,0.2-0.8,0.4c-0.5,0.8-1.3,1.3-2.3,1.2c-1.5,0-2.6-1.1-2.6-2.5
                     c0-1.4,1.2-2.5,2.6-2.5c0.9,0,1.8,0.5,2.3,1.3c0.2,0.3,0.4,0.4,0.8,0.4h13.6c0.5,0,0.9,0.4,0.9,0.8c0,0.5-0.4,0.8-0.9,0.8h-1.8
                     C27.1,41.7,26.8,42,26.8,42.5z M17.1,47.5H6.6v-2.7c0-0.5-0.4-0.8-0.9-0.8c-0.1,0-0.2,0-0.3,0c-0.2,0.1-0.4,0.1-0.6,0.1
                     c-1,0-1.8-0.7-1.8-1.7c0-0.9,0.8-1.7,1.8-1.7c0.2,0,0.4,0,0.6,0.1c0.5,0.2,1-0.1,1.1-0.5c0-0.1,0.1-0.2,0.1-0.3V35h2.8
                     c0.5,0,0.9-0.4,0.9-0.8c0-0.1,0-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.9,0.8-1.7,1.8-1.7c1,0,1.8,0.7,1.8,1.7
                     c0,0.2,0,0.4-0.1,0.6c-0.2,0.4,0.1,0.9,0.5,1.1c0.1,0,0.2,0,0.3,0h2.8v1.7h1.8v-2.5c0-0.5-0.4-0.8-0.9-0.8h-2.6
                     c0-1.8-1.6-3.3-3.5-3.3c-1.9,0-3.5,1.5-3.5,3.3H5.7c-0.5,0-0.9,0.4-0.9,0.8v5c-1.9,0-3.5,1.5-3.5,3.3c0,1.8,1.6,3.3,3.5,3.3v2.5
                     c0,0.5,0.4,0.8,0.9,0.8H18c0.5,0,0.9-0.4,0.9-0.8V45h-1.8V47.5z"
											></path>
										</svg>
										<h5>Problem-solvers</h5>
										<p>
											For people at GenixBit Labs, every problem is a new
											challenge to be conquered.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="1"
							aria-hidden="false"
							style="width: 293px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="why-choose-box">
										<svg
											viewBox="0 0 50 50"
											style="enable-background: new 0 0 50 50"
											xml:space="preserve"
										>
											<path
												fill="#313131"
												d="M27,22.7c6.3,0,11.4-5.1,11.4-11.3C38.3,5.1,33.2,0,27,0c-6.3,0-11.4,5.1-11.4,11.3
                     C15.6,17.6,20.7,22.7,27,22.7z M27,1.6c5.4,0,9.8,4.4,9.8,9.8c0,5.4-4.4,9.8-9.8,9.8s-9.8-4.4-9.8-9.8C17.2,5.9,21.5,1.6,27,1.6z
                     M43,30.9c-0.1-0.4-0.5-0.6-0.9-0.7l-2-0.3L39.3,28c-0.2-0.5-0.8-0.8-1.3-0.6c-0.3,0.1-0.5,0.3-0.6,0.6l-0.9,1.9l-2,0.3
                     c-0.4,0.1-0.7,0.3-0.9,0.7c-0.1,0.4,0,0.8,0.3,1.1l1.4,1.5l-0.3,2.1c-0.1,0.3,0,0.7,0.2,0.9c0.3,0.4,0.9,0.4,1.3,0.2l1.7-1
                     l1.7,0.9c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.7-0.3c0.2-0.3,0.3-0.6,0.2-0.9l-0.3-2.1l1.4-1.5C43,31.7,43.1,31.2,43,30.9z
                     M39.6,32.9l0.3,1.8l-1.5-0.9l-1.5,0.8l0.3-1.8l-1.3-1.3l1.8-0.3l0.8-1.6l0.8,1.6l1.8,0.3L39.6,32.9z M7.3,33.9
                     c0-6.2,4.9-11.2,11-11.2h2.4c-0.8-0.4-1.6-1-2.3-1.6h-0.2c-6.9,0-12.6,5.7-12.6,12.8v2.9h1.6V33.9z M35.6,21.1h-0.2
                     c-0.7,0.6-1.5,1.1-2.3,1.6h2.5c6.1,0,11,5,11,11.2v13.5c0,0.6-0.4,1.1-1,1.1c0,0,0,0,0,0H28.8C28.7,49,28.4,49.5,28,50h17.5
                     c1.4,0,2.6-1.2,2.6-2.6V33.9C48.2,26.8,42.5,21.1,35.6,21.1z M24.3,34.7l1.6-10.5c-0.5,0-1.1-0.1-1.6-0.2l-1.3,8.6
                     C23.6,33.2,24.1,33.9,24.3,34.7z M31.8,41.5c0.2-0.2,0.3-0.4,0.3-0.7l-2.4-16.8c-0.5,0.1-1,0.2-1.6,0.2l2.3,16.4
                     c0.1,0.8,0,1.6-0.3,2.3L31.8,41.5z M28,45.3c0-0.2,0-0.3,0-0.5c0.3-0.4,0.5-1,0.5-1.6c0-0.2,0-0.4-0.1-0.6c0.3-0.4,0.5-1,0.5-1.5
                     c0-1.4-1.1-2.4-2.6-2.4H23c0.1-0.6,0.2-1.2,0.2-1.7c-0.1-1-0.3-3.2-2.1-4c-0.9-0.4-2.1-0.3-2.9,0.4c-1.1,0.9-1,2.1-1,2.7
                     c0,0.1,0,0.8,0,0.8c-0.5,0.9-1,1.8-1.6,2.7L15.6,40v-0.9c0-0.4-0.4-0.8-0.8-0.8h-3.9c-0.4,0-0.8,0.3-0.8,0.8v10.2
                     c0,0.4,0.4,0.8,0.8,0.8h3.9c0.3,0,0.7-0.2,0.7-0.6c0.1,0,0.5,0,0.5,0c0.3,0.2,0.6,0.4,1,0.5c0.1,0,0.3,0.1,0.4,0.1h7.3
                     c1.4,0,2.6-1.2,2.6-2.6c0-0.1,0-0.2,0-0.3C27.8,46.6,28,46,28,45.3z M14,48.4h-2.4v-8.6H14V48.4z M27,43.4
                     c-0.1,0.5-0.5,0.9-1.1,0.9h-0.4c0.6,0,1,0.5,1,1.1c0,0.5-0.5,0.9-1,0.9h-0.6c0.6,0,1,0.5,0.9,1.1c0,0.5-0.5,0.9-1,0.9h-7.2
                     c-0.4-0.1-0.6-0.4-1-0.5c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0-0.7,0v-5.9c0,0,1-0.6,1.1-0.7c0.1-0.3,2.2-3.5,2.2-4.1v-0.6
                     c0-0.6-0.2-1.4,0.4-1.8c0.3-0.3,0.8-0.4,1.2-0.2c0.9,0.4,1.1,1.9,1.2,2.7c0,0.5-0.6,3-0.7,3.1h5.4c0.6,0,1,0.3,1,0.9
                     c0,0.6-0.5,1-1,1c0,0,0,0,0,0H26C26.6,42.3,27,42.8,27,43.4z M2.6,44.9c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8
                     c-0.4,0-0.8,0.4-0.8,0.8C1.8,44.6,2.2,44.9,2.6,44.9z M7.7,40.2H2.6c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h5.1
                     c0.4,0,0.8-0.3,0.8-0.8C8.5,40.6,8.2,40.2,7.7,40.2z M7.7,46.5H2.6c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h5.1
                     c0.4,0,0.8-0.4,0.8-0.8C8.5,46.8,8.2,46.5,7.7,46.5z M7.7,43.4h-2c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h2
                     c0.4,0,0.8-0.3,0.8-0.8C8.5,43.7,8.2,43.4,7.7,43.4z"
											></path>
										</svg>
										<h5>Time-bound Professionals</h5>
										<p>
											We work with clockwork precision which enables us to
											deliver work on time, as agreed.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="2"
							aria-hidden="false"
							style="width: 293px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="why-choose-box">
										<svg
											viewBox="0 0 50 50"
											style="enable-background: new 0 0 50 50"
											xml:space="preserve"
										>
											<path
												fill="#313131"
												d="M2.1,34.3H1c-0.3,0-0.5,0.1-0.7,0.3C0.1,34.8,0,35.1,0,35.4s0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h1
                  c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4v7.3c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.2-0.4,0.2H1c-0.3,0-0.5,0.1-0.7,0.3
                  C0.1,45.3,0,45.5,0,45.8c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h1c0.7,0,1.3-0.3,1.8-0.8c0.5-0.5,0.8-1.1,0.8-1.8v-7.3
                  c0-0.7-0.3-1.4-0.8-1.8C3.4,34.6,2.7,34.3,2.1,34.3z M49,13.5h-1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4V5.7
                  c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h1c0.3,0,0.5-0.1,0.7-0.3C49.9,4.6,50,4.4,50,4.1s-0.1-0.5-0.3-0.7
                  c-0.2-0.2-0.5-0.3-0.7-0.3h-1c-0.7,0-1.3,0.3-1.8,0.8c-0.5,0.5-0.8,1.1-0.8,1.8V13c0,0.7,0.3,1.4,0.8,1.8c0.5,0.5,1.1,0.8,1.8,0.8
                  h1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C49.5,13.6,49.2,13.5,49,13.5z M24.9,7.8H30
                  c-0.5,0.6-0.7,1.4-0.7,2.1c0,1,0.4,1.9,1.1,2.6c1.1,1.1,1.7,1.7,1.7,1.8c0.4,0.3,0.7,0.5,1.1,0.7c0.4,0.2,0.9,0.3,1.3,0.3h7.1
                  c0.7,0,1.3-0.3,1.9-0.7c0.5-0.5,0.7-1.2,0.7-1.9V9.3V5.7c0-0.7-0.3-1.4-0.8-1.8c-0.5-0.5-1.1-0.8-1.8-0.8h-2.5
                  c-0.2,0-0.4-0.1-0.6-0.1c-0.1-0.1-0.5-0.3-0.5-0.3l-1.2-1.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.1-0.4-0.2-0.6-0.3
                  c-0.4-0.2-0.9-0.3-1.3-0.3c-0.1,0-0.1,0-0.1,0h-9.5c-0.9,0-1.8,0.4-2.4,1c-0.6,0.7-1,1.6-1,2.4v0.4c0,0.9,0.4,1.8,1,2.4
                  C23.2,7.4,24,7.8,24.9,7.8z M23.6,3.9c0-0.4,0.2-0.7,0.4-1c0.3-0.2,0.6-0.4,1-0.4c0,0,3.2,0,9.5,0c0.2,0,0.4,0.1,0.6,0.2
                  c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0,0.4,0.4,1.2,1.2c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.3
                  c0.4,0.2,0.9,0.3,1.3,0.3h2.5c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4v3.6v3.3c0,0.2-0.1,0.3-0.2,0.4
                  c-0.1,0.1-0.2,0.2-0.4,0.2h-7.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-1.1-1.1-1.7-1.7-1.7-1.7
                  c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.3,0,0.5,0.1,0.7,0.2l0.6,0.5l0.9,0.9
                  c0.2,0.2,0.6,0.4,0.9,0.4h0.9c0.3,0,0.5-0.1,0.7-0.3C37.8,9.8,38,9.6,38,9.3c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3h-0.1
                  c-0.3,0-0.7-0.2-0.9-0.4c-0.8-0.8-1.2-1.2-1.2-1.2C34.4,6.4,34,6.1,33.6,6c-0.4-0.2-0.9-0.3-1.3-0.3c0,0-2.5,0-7.4,0
                  c-0.4,0-0.7-0.2-1-0.4c-0.2-0.3-0.4-0.6-0.4-1V3.9z M25,42.2h-5.1c0.5-0.6,0.7-1.4,0.7-2.1c0-1-0.4-1.9-1.1-2.6
                  c-1.1-1.1-1.7-1.7-1.7-1.7c-0.4-0.4-0.7-0.6-1.1-0.7c-0.4-0.2-0.9-0.3-1.3-0.3H8.3c-0.7,0-1.3,0.3-1.9,0.8C6,36,5.8,36.7,5.8,37.4
                  v3.3v3.6c0,0.7,0.3,1.4,0.7,1.8c0.5,0.5,1.2,0.8,1.9,0.8h2.5c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.5,0.3,0.5,0.3l1.2,1.1
                  c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.2,0.9,0.3,1.3,0.3c0.1,0,0.1,0,0.1,0H25c0.9,0,1.8-0.4,2.4-1
                  c0.6-0.7,1-1.5,1-2.4v-0.4c0-0.9-0.4-1.8-1-2.4C26.8,42.6,25.9,42.2,25,42.2z M26.4,46.1c0,0.4-0.2,0.7-0.4,1
                  c-0.2,0.2-0.6,0.4-0.9,0.4c0,0-3.2,0-9.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.1-0.1
                  c0,0-0.4-0.4-1.2-1.2c-0.2-0.2-0.3-0.3-0.5-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.4-0.2-0.9-0.3-1.3-0.3H8.3c-0.2,0-0.3-0.1-0.4-0.2
                  c-0.1-0.1-0.2-0.2-0.2-0.4v-3.6v-3.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.2h7.1c0.2,0,0.4,0.1,0.5,0.2
                  c0.2,0.1,0.4,0.2,0.5,0.3c1.1,1.1,1.7,1.7,1.7,1.8c0.3,0.3,0.5,0.6,0.5,1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5
                  c-0.3,0-0.5-0.1-0.7-0.2l-0.6-0.5L14.8,40c-0.2-0.2-0.6-0.4-0.9-0.4H13c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7
                  c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h0.1c0.3,0,0.7,0.2,0.9,0.4c0.8,0.8,1.2,1.2,1.2,1.2c0.3,0.3,0.7,0.5,1.1,0.7
                  c0.4,0.2,0.9,0.3,1.3,0.3c0,0,2.5,0,7.4,0c0.4,0,0.7,0.2,0.9,0.4c0.3,0.3,0.4,0.6,0.4,1V46.1z M5.3,27.6c-0.1-0.9-0.2-1.7-0.2-2.6
                  c0-4.1,1.2-8.1,3.5-11.4c2.2-3.3,5.4-5.9,9-7.3c0.3-0.1,0.4-0.3,0.5-0.6c0.2-0.3,0.2-0.6,0.1-0.8c-0.1-0.3-0.3-0.5-0.6-0.6
                  c-0.3-0.1-0.6-0.1-0.8,0c-4.1,1.6-7.5,4.4-9.9,8.1C4.4,16,3,20.5,3,24.9c0,0.9,0.1,1.9,0.2,2.9c0.1,0.9,0.3,1.8,0.6,2.7
                  C3.8,30.8,4,31,4.2,31.1c0.3,0.2,0.5,0.2,0.8,0.1c0.3-0.1,0.5-0.2,0.6-0.5c0.2-0.3,0.2-0.5,0.1-0.8C5.5,29.2,5.4,28.4,5.3,27.6z
                  M45.6,18.9c-0.3-0.2-0.5-0.2-0.8-0.1c-0.3,0.1-0.5,0.2-0.6,0.5C44,19.5,44,19.7,44.1,20c0.4,1.7,0.6,3.3,0.6,5.1
                  c0,4.1-1.2,8.1-3.5,11.5c-2.2,3.3-5.4,5.9-9,7.4c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.3-0.1,0.6,0,0.8c0.1,0.3,0.3,0.4,0.5,0.6
                  c0.3,0.1,0.6,0.1,0.8,0c4-1.7,7.4-4.5,9.9-8.1c2.5-3.7,3.9-8.1,3.9-12.7c0-1.9-0.2-3.7-0.7-5.6C46,19.2,45.8,19,45.6,18.9z
                  M27.1,24.5c-0.4-0.2-0.9-0.3-1.4-0.4c-0.5-0.1-1-0.3-1.2-0.4c-0.2-0.1-0.4-0.3-0.4-0.6c0-0.3,0.1-0.5,0.3-0.6
                  c0.2-0.2,0.5-0.3,0.9-0.3c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.2c0.2,0.1,0.3,0.2,0.4,0.2
                  c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.5-0.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.3-0.3
                  c-0.6-0.4-1.3-0.7-2-0.8v-0.5c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.3,0.4-0.3,0.6
                  v0.6c-0.7,0.2-1.3,0.5-1.8,0.9c-0.4,0.5-0.7,1.1-0.7,1.7c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.6,1,0.8c0.4,0.1,0.9,0.3,1.4,0.4
                  c0.4,0.1,0.7,0.2,0.9,0.3c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.5,0.3-0.9,0.3
                  c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.2-1-0.4c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.3
                  c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.7,0.4,1.4,0.6,2.3,0.8v0.5c0,0.2,0.1,0.5,0.3,0.6
                  c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.3-0.4,0.3-0.6v-0.6c0.7-0.2,1.3-0.5,1.8-0.9c0.4-0.5,0.7-1,0.7-1.7
                  c0-0.6-0.1-1-0.4-1.4C27.8,24.9,27.5,24.7,27.1,24.5z M25.1,14.8c-2.6,0-5.2,1.1-7.1,3c-1.9,1.9-2.9,4.5-2.9,7.2
                  c0,2.7,1,5.3,2.9,7.1c1.9,1.9,4.4,3,7.1,3c2.7,0,5.2-1.1,7.1-3c1.9-1.9,2.9-4.5,2.9-7.1c0-2.7-1-5.3-2.9-7.2
                  C30.3,15.9,27.8,14.8,25.1,14.8z M30.7,30.6c-1.5,1.5-3.5,2.3-5.6,2.3s-4.1-0.8-5.6-2.3c-1.5-1.5-2.3-3.6-2.3-5.7
                  c0-2.1,0.8-4.2,2.3-5.7c1.5-1.5,3.5-2.3,5.6-2.3s4.1,0.8,5.6,2.3c1.5,1.5,2.3,3.6,2.3,5.7C33.1,27,32.2,29.1,30.7,30.6z"
											></path>
										</svg>
										<h5>Value Givers</h5>
										<p>
											We help our clients gain maximum value with the right
											choice of technology for web and mobile app development
											services.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="3"
							aria-hidden="false"
							style="width: 293px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="why-choose-box">
										<svg
											viewBox="0 0 50 50"
											style="enable-background: new 0 0 50 50"
											xml:space="preserve"
										>
											<path
												fill="#313131"
												d="M45.7,28.3l-0.2-0.5c-5.3,0-8.6,0-14,0c-0.2-0.5-0.6-0.8-1.2-0.8h-0.7c0.2-2,1.2-4,2.7-5.6
                  c1.9-1.9,2.8-4.4,2.8-7c0-5.7-4.9-10.4-10.7-10.1c-5,0.3-9,4.3-9.5,9.2c-0.2,3,0.8,5.9,2.9,8c1.5,1.5,2.5,3.5,2.7,5.5h-0.7
                  c-0.5,0-0.9,0.3-1.1,0.7c-9,0-6.5,0-14.2,0l-0.2,0.5c-0.1,0.2-0.2,0.4-0.2,0.6v2.9c0,0.8,0.6,1.4,1.4,1.6L9,33.8
                  c0.1,0,0.1,0.1,0.1,0.1c0.4,1.7,1.1,3.3,2.1,4.7L10.9,39c-0.5,0.7-1.3,1.6-1.8,2.2c-0.5,0.6-0.5,1.5,0.2,2.1l2.1,2
                  c0.6,0.5,1.5,0.6,2.2,0.2l2.8-2c0.1,0,0.1,0,0.2,0c1.5,0.9,3.2,1.5,5,1.9c0,0,0.1,0,0.1,0.1l0.5,3.2c0,0.3,0.2,0.5,0.4,0.8
                  c0.3,0.4,0.8,0.6,1.2,0.6h3c0.5,0,0.9-0.2,1.3-0.6c0.2-0.2,0.3-0.5,0.3-0.8l0.5-3.3c0,0,0,0,0.1-0.1c1.8-0.4,3.4-1.1,5-1.9
                  c0.1,0,0.1,0,0.2,0l2.7,2c0.7,0.5,1.6,0.5,2.2-0.2l2.1-2c0.6-0.5,0.7-1.4,0.2-2.1L39,38.5c0.9-1.4,1.6-3,2.1-4.7
                  c0,0,0-0.1,0.1-0.1l3.2-0.5c0.9-0.1,1.5-0.8,1.5-1.6v-2.8C45.8,28.7,45.8,28.5,45.7,28.3z M19,20.6c-1.8-1.8-2.7-4.3-2.5-6.8
                  c0.4-4.2,3.8-7.6,8-7.8c0.2,0,0.3,0,0.5,0c4.7,0,8.5,3.9,8.5,8.5c0,2.3-0.9,4.4-2.4,6c-1.8,1.8-2.9,4.2-3.1,6.7h-2.1v-8.1
                  c1.3-0.3,2.2-1.5,2.2-2.9c0-1.7-1.4-3-3-3c-1.6,0-3,1.4-3,3c0,1.4,0.9,2.5,2.2,2.9v8.1h-2.2C21.9,24.7,20.7,22.4,19,20.6z
                  M25.2,17.6c-0.9,0-1.5-0.7-1.5-1.4c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.5,0.7,1.5,1.4C26.6,16.9,25.9,17.6,25.2,17.6z M20.1,28.7
                  c0.1-0.1,9.8-0.1,9.8-0.1v0.8v2.2l-9.8,0.1V28.7z M26.6,33.1v2c0,0.5-0.4,0.9-0.9,0.9h-1.3c-0.5,0-0.9-0.4-0.9-0.9v-2H26.6z
                  M16.8,29.4h1.9v2.5c0,0.7,0.5,1.2,1.2,1.2h2.2v1h-0.9c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h1c0.2,1.1,1.2,1.9,2.4,1.9
                  h1.3c1.2,0,2.1-0.8,2.3-1.9h1c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-0.9v-1h2.2c0.7,0,1.2-0.5,1.2-1.2v-2.5h1.9
                  c0.1,0.3,0.1,0.7,0.1,1c0,4.4-3.8,7.9-8.4,7.9s-8.4-3.6-8.4-7.9C16.7,30.1,16.7,29.8,16.8,29.4z M44.2,31.8l-3.4,0.5
                  c-0.7,0.1-1.2,0.6-1.4,1.2c-0.4,1.5-1,2.9-1.9,4.2c-0.4,0.5-0.4,1.3,0.1,1.8l2.1,2.6l-2.1,2c-0.1,0.1-0.2,0.1-0.2,0l-2.7-2
                  c-0.5-0.4-1.2-0.5-1.9-0.1c-1.4,0.8-2.9,1.4-4.5,1.7c-0.7,0.2-1.2,0.7-1.3,1.3l-0.5,3.3l-0.1,0.1h-3c0,0-0.1,0-0.1-0.1l-0.5-3.3
                  c-0.1-0.7-0.6-1.2-1.2-1.3c-1.6-0.4-3.1-0.9-4.5-1.7c-0.6-0.4-1.3-0.3-1.9,0.1l-2.7,2c-0.1,0-0.1,0-0.2,0l-2.2-2
                  c0.5-0.6,1.2-1.5,1.8-2.2l0.7-0.8c0.2-0.3,0.2-0.7,0-1c-1-1.4-1.7-2.9-2.1-4.5c-0.2-0.7-0.7-1.1-1.4-1.2l-3.5-0.5H5.7v-2.3H15
                  c0,0.3-0.1,0.7-0.1,1c0,5.2,4.5,9.5,10,9.5c5.5,0,10-4.2,10-9.5c0-0.3,0-0.6-0.1-1h9.3L44.2,31.8L44.2,31.8
                  C44.3,31.8,44.3,31.8,44.2,31.8z M25,3.9c0.5,0,0.8-0.3,0.8-0.8V0.8C25.8,0.3,25.5,0,25,0c-0.5,0-0.8,0.3-0.8,0.8v2.3
                  C24.2,3.6,24.5,3.9,25,3.9z M36.1,14.8c0,0.5,0.3,0.8,0.8,0.8h2.4c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-2.4
                  C36.4,14,36.1,14.3,36.1,14.8z M10.7,15.5h2.4c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-2.4c-0.5,0-0.8,0.3-0.8,0.8
                  C10,15.1,10.4,15.5,10.7,15.5z M33.9,7l1.7-1.7c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0L32.8,6c-0.3,0.3-0.3,0.8,0,1.1
                  C33.1,7.3,33.6,7.3,33.9,7z M16.1,7c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l-1.7-1.7c-0.3-0.3-0.8-0.3-1.1,0
                  c-0.3,0.3-0.3,0.8,0,1.1L16.1,7z M36.2,18.4c-0.4-0.2-0.9,0-1,0.4c-0.2,0.4,0,0.8,0.4,1l2.2,0.9c0.4,0.2,0.9,0,1-0.4
                  c0.2-0.4,0-0.8-0.4-1L36.2,18.4z M11.5,10.1l2.2,0.9c0.4,0.2,0.9,0,1-0.4c0.2-0.4,0-0.8-0.4-1l-2.2-0.9c-0.4-0.2-0.9,0-1,0.4
                  C10.9,9.5,11.1,10,11.5,10.1z M29.2,4.7c0.4,0.2,0.9,0,1-0.4l0.9-2.2c0.2-0.4,0-0.8-0.4-1c-0.4-0.2-0.9,0-1,0.4l-0.9,2.2
                  C28.6,4.1,28.8,4.5,29.2,4.7z M19.7,4.3c0.2,0.4,0.6,0.5,1,0.4c0.4-0.2,0.5-0.6,0.4-1l-0.9-2.2c-0.2-0.4-0.6-0.5-1-0.4
                  c-0.4,0.2-0.6,0.6-0.4,1L19.7,4.3z M13.8,18.5l-2.2,0.9c-0.4,0.2-0.5,0.6-0.4,1c0.2,0.4,0.6,0.5,1,0.4l2.2-0.9
                  c0.4-0.2,0.5-0.6,0.4-1C14.6,18.5,14.2,18.4,13.8,18.5z M35.2,10.5c0.2,0.4,0.6,0.5,1,0.4l2.2-0.9c0.4-0.2,0.5-0.6,0.4-1
                  c-0.2-0.4-0.6-0.5-1-0.4l-2.2,0.9C35.2,9.7,35,10.1,35.2,10.5z"
											></path>
										</svg>
										<h5>Ideators &amp; Innovators</h5>
										<p>
											When the old ways do not work, new ones have to be
											invented or innovated. Our mobile app developers are
											consistent in it
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.fxt-val-slider {
	all: unset !important;
}

@media (max-width: 576px) {
	.fxt-unique-dev {
		font-size:22px !important;
		font-weight:bold !important;
	}
}
</style>
