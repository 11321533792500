<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <WhatWeDo></WhatWeDo>

      <Values></Values>

      <TypeDiv></TypeDiv>

      <ServicesType></ServicesType>

      <Portfolio></Portfolio>

      <Const></Const>




  </div>
</template>

<script>
import Banner from '../../components/MobileDevelopment/IosDevelopment/Banner.vue'
import WhatWeDo from '../../components/MobileDevelopment/IosDevelopment/WhatWeDo.vue'
import Values from '../../components/MobileDevelopment/IosDevelopment/Values.vue'
import ServicesType from '../../components/MobileDevelopment/IosDevelopment/ServicesType.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/MobileDevelopment/IosDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        TypeDiv,
        Values,
        Portfolio,
        Const,
        WhatWeDo,
        ServicesType
    }
}
</script>


<style>

</style>