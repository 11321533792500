<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Advantage</span>
				<h2>You stand to gain a lot from resorting to our on demand car wash app development services.</h2>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<path
		d="M86.8,37.2l12.7-12.9c0.3-0.3,0.7-1,0.5-1.5c0-0.5-0.3-1-0.8-1.4l-34.3-20c-0.7-0.3-1.7-0.3-2.2,0.3L50,14.7L37.3,1.8
                c-0.7-0.5-1.5-0.7-2.2-0.3l-34.3,20C0.3,21.8,0,22.3,0,22.8c0,0.5,0.2,1.2,0.5,1.5l12.7,12.9L0.5,50C0.2,50.4,0,50.9,0,51.5
                c0,0.5,0.3,1,0.8,1.4l13,7.6v16.6c0,0.7,0.3,1.2,0.8,1.5l34.2,20c0.3,0.2,0.7,0.2,0.8,0.2c0.3,0,0.7,0,0.8-0.2l34.2-20
                c0.5-0.3,0.8-0.8,0.8-1.5V60.5l13-7.6c0.5-0.3,0.8-0.8,0.8-1.4c0-0.5-0.2-1.2-0.5-1.5L86.8,37.2L86.8,37.2z M80.6,37.2L50,54.9
                L19.3,37.2l23-13.4l7.7-4.4l27.7,16.1L80.6,37.2L80.6,37.2z M64.3,5.4l31,18.1L84,34.8l-1.8-1L53,16.9L64.3,5.4L64.3,5.4z M35.7,5.4
                L47,16.9L16,34.8L4.7,23.3L35.7,5.4L35.7,5.4z M16,39.4l31,18.1L35.7,69l-19-11.2l0,0l-12-6.9L16,39.4L16,39.4z M17.5,62.5L35,72.7
                c0.3,0.2,0.7,0.2,0.8,0.2c0,0,0,0,0.2,0c0.5,0,0.8-0.2,1.2-0.5l10.8-11v32.3L17.5,75.9V62.5L17.5,62.5z M82.3,75.9L51.8,93.8V61.5
                l10.8,11c0.3,0.3,0.7,0.5,1.2,0.5c0,0,0,0,0.2,0c0.3,0,0.7,0,0.8-0.2l17.5-10.1V75.9L82.3,75.9z M64.3,69L53,57.5l31-18.1l11.3,11.5
                L64.3,69L64.3,69z"
	></path></svg
>`,
					title: 'White Label Solution',
					text: 'Have your own branded car wash app with our white label solution that can configured with brand presets.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M50.7,73.6l-2.5,0.5v8.7c0,0.5-0.7,1.2-1.7,1.2h-4.9c-1,0-1.7-0.7-1.7-1.2v-8.7l-2.5-0.5c-3.5-0.8-7.1-2.2-10.1-4.2
                    l-2.2-1.3L23.3,70L19,74.3c-0.2,0.2-0.3,0.2-0.7,0.2c-0.5,0-1-0.2-1.3-0.7l-3.5-3.3c-0.7-0.7-0.8-1.7-0.3-2l4.4-4.3l1.8-1.8
                    l-1.3-2.2c-2-3-3.4-6.5-4.2-10l-0.5-2.5H4.3c-0.5,0-1.2-0.7-1.2-1.7v-4.8c0-1,0.7-1.7,1.2-1.7h8.7l0.5-2.5c0.8-3.5,2.2-6.8,4.2-10
                    l1.3-2.2L17.4,23l-4.4-4.3c-0.3-0.3-0.3-1.3,0.3-2l3.4-3.5c0.3-0.3,0.8-0.7,1.3-0.7c0.3,0,0.5,0,0.7,0.2l4.4,4.3l1.8,1.8l2.2-1.3
                    c3.2-2,6.5-3.3,10.1-4.2l2.5-0.3V4.3c0-0.5,0.7-1.2,1.7-1.2h4.9c1,0,1.7,0.7,1.7,1.2V13l2.5,0.5c3.5,0.8,7.1,2.2,10.1,4.2l2.2,1.3
                    l1.8-1.8l4.4-4.3c0.2-0.2,0.3-0.2,0.7-0.2c0.5,0,1,0.2,1.3,0.7l3.5,3.5c0.7,0.7,0.8,1.7,0.3,2L70.7,23l-1.8,1.8l1.3,2.2
                    c2,3,3.4,6.5,4.2,10l0.5,2.5h8.7c0.5,0,1.2,0.7,1.2,1.7v4.8c0,1-0.7,1.7-1.2,1.7h-8.7l-0.5,2.5c-0.5,2.2-1.2,4.3-2.2,6.3l2.4,2.2
                    c1.2-2.5,2.2-5,2.9-7.8h6.2c2.3,0,4.4-2.2,4.4-4.8v-4.8c0-2.7-2-4.8-4.4-4.8h-6.2c-0.8-4-2.5-7.7-4.5-11l4.4-4.3
                    c1.7-1.7,1.5-4.5-0.3-6.5L73.3,11c-1-1-2.4-1.5-3.7-1.5c-1,0-2.2,0.3-2.9,1.2L62.4,15c-3.4-2.2-7.1-3.7-11.1-4.5V4.3
                    C51.4,2,49.2,0,46.5,0h-4.9c-2.7,0-4.9,2-4.9,4.3v6.2c-4,0.8-7.7,2.5-11.1,4.5l-4.4-4.3c-0.8-0.8-1.8-1.2-2.9-1.2
                    c-1.3,0-2.5,0.5-3.7,1.5l-3.7,3.5c-1.8,1.8-2,4.8-0.3,6.5l4.4,4.2c-2.2,3.3-3.7,7-4.5,11H4.3C2,36.2,0,38.4,0,41.1v4.8
                    c0,2.7,2,4.8,4.4,4.8h6.2c0.8,4,2.5,7.8,4.5,11l-4.4,4.3c-1.7,1.7-1.5,4.5,0.3,6.5l3.5,3.5c1,1,2.4,1.5,3.7,1.5
                    c1,0,2.2-0.3,2.9-1.2l4.4-4.3c3.4,2.2,7.1,3.7,11.1,4.5v6.2c0,2.3,2.2,4.3,4.9,4.3h4.9c2.7,0,4.9-2,4.9-4.3v-6.2
                    c2.4-0.5,4.7-1.3,6.9-2.3l-2.2-2.5C54.4,72.6,52.5,73.3,50.7,73.6z M87.1,89c0.2,0.3,0.5,0.5,1,0.5c0.3,0,0.8-0.2,1.2-0.5
                    c0.7-0.7,0.7-1.7,0-2.2l-9.4-9.3c-0.7-0.7-1.7-0.7-2.2,0c-0.7,0.7-0.7,1.7,0,2.2L87.1,89z M98.7,84.8L62.3,51.6
                    c1-2.3,1.7-5,1.7-7.8c0-10.7-8.7-19.5-19.6-19.5c-4.5,0-8.9,1.5-12.1,4.2l11.6,11.5c0.3,0.3,0.7,0.5,1.2,0.5
                    c0.5,0,0.8-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.2l-8.9-8.7c2.2-1,4.5-1.7,7.2-1.7c9.1,0,16.5,7.3,16.5,16.4c0,3.2-0.8,6-2.4,8.5
                    l2.4,2.2l35.6,32.2c0.5,0.5,0.5,1.3,0,1.8L89,96c-0.5,0.5-1,0.5-1.2,0.5c-0.2,0-0.5,0-0.8-0.3L54,60.6l-0.2-0.2l-1-1l-1.2-1.2
                    c-2.2,1.2-4.9,1.8-7.6,1.8c-9.1,0-16.5-7.3-16.5-16.4c0-2.2,0.5-4.2,1.2-6l8.6,8.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.8-0.2,1.2-0.5
                    c0.7-0.7,0.7-1.7,0-2.2L28.2,32.7c-2.2,3.2-3.5,7-3.5,11.2c0,10.7,8.7,19.5,19.6,19.5c2.4,0,4.7-0.5,6.9-1.2l33.6,36.4
                    c0.8,0.8,2,1.3,3.2,1.3c1.2,0,2.4-0.5,3.2-1.3l7.6-7.5C100.4,89.5,100.5,86.6,98.7,84.8z"
			></path>
		</g>
	</g></svg
>`,
					title: '100% customizable',
					text: 'Customize every pixel of the app to give it a unique touch and feel that is different from all competitors.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M50,8.3c-20.9,0-37.9,17.2-37.9,38.2c0,21,17,38.4,37.9,38.4s37.9-17.2,37.9-38.4C87.9,25.3,70.9,8.3,50,8.3z M44.4,12.2
                    c-4.6,4-8.3,8.9-10.9,14.5c-2.8-1.3-5.4-3-7.8-4.8C30.9,16.7,37.4,13.3,44.4,12.2z M23.5,24.3c2.6,2,5.6,3.8,8.7,5.3
                    c-2,4.8-2.9,10-3.1,15.4H15.5C15.9,37.4,18.6,30.2,23.5,24.3z M23,68.4c-4.6-5.7-7.2-12.9-7.7-20.2h13.6c0.2,5.2,1.1,10.2,3.1,15
                    C28.9,64.6,25.8,66.4,23,68.4z M25.3,71.1c2.5-1.8,5.2-3.5,8.2-4.7c2.6,5.7,6.4,10.7,11.1,14.7C37.1,80,30.4,76.4,25.3,71.1z
                    M48.4,80c-5.1-4-9-9-11.8-14.9c3.8-1.2,7.7-2,11.8-2V80z M48.4,59.7c-4.4,0.2-9,1-13.2,2.5c-1.6-4.5-2.6-9.2-2.8-14h16V59.7z
                    M48.4,44.9h-16c0.2-4.8,1.1-9.5,2.9-14c4.1,1.7,8.5,2.5,13.1,2.7V44.9z M48.4,30.2c-4.1-0.2-8-1-11.8-2.3
                    c2.8-5.7,6.7-10.7,11.8-14.5V30.2z M74.2,21.7c-2.5,2-5.1,3.5-7.8,4.8C63.7,21,60,16.2,55.4,12.2C62.6,13.3,69.1,16.7,74.2,21.7z
                    M51.6,13.3c4.9,3.8,9,8.9,11.8,14.7c-3.8,1.3-7.8,2-11.8,2.2V13.3z M64.9,30.9c1.8,4.5,2.8,9.2,2.9,14h-16V33.5h-0.2
                    C56.2,33.4,60.6,32.5,64.9,30.9z M51.6,48.2h16c-0.2,4.7-1.1,9.5-2.9,13.9c-4.2-1.3-8.7-2.2-13.1-2.5V48.2z M51.6,80V63.1
                    c4.1,0.2,8,0.8,11.8,2.2C60.6,70.9,56.5,75.9,51.6,80z M55.4,81.1c4.6-4.2,8.3-9.2,11.1-14.7c2.9,1.2,5.6,2.8,8.2,4.7
                    C69.4,76.4,62.7,80,55.4,81.1z M76.8,68.6c-2.8-2.2-5.7-3.8-9-5.2c2-4.8,2.9-10,3.1-15.2h13.6C84.1,55.6,81.5,62.8,76.8,68.6z
                    M70.9,44.9c-0.2-5.3-1.3-10.5-3.1-15.4c3.1-1.5,6-3.3,8.7-5.5c4.9,5.8,7.7,13.2,8.2,20.9H70.9z M93.6,3.5c-0.5-0.2-1-0.3-1.5,0
                    C80.9,8.1,67,7,50.7,0.1c-0.5-0.2-0.8-0.2-1.3,0C33,7.1,19,8.3,7.8,3.5c-0.5-0.3-1.1-0.2-1.6,0C5.7,3.8,5.6,4.3,5.6,5v40.4
                    C5.6,90,49.2,99.8,49.7,100h0.7c0.5-0.2,44.1-10,44.1-54.6V5C94.4,4.3,94.1,3.8,93.6,3.5z M91.2,45.4c0,40.4-36.9,50.3-41.2,51.3
                    c-4.2-1-41.2-10.9-41.2-51.3V7.3c11.4,4,25.3,2.7,41.2-3.8c15.8,6.7,29.6,8,41.2,4V45.4z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Robust Admin Access',
					text: 'Admin can 360-degree control of the app’s activities, user activity and service provider management.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M82,56.6H70.8v-3.4c0-1-1-1.9-1.9-1.9h-0.2c-0.7,0-1.2,0.5-1.4,1c-0.2,0.3-0.3,0.6-0.3,0.9v3.4H55.9c-0.5,0-1,0.2-1.4,0.6
                    l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.3,0.3-0.5,0.7-0.5,1l0,0.1l0,0.1c0,0.3,0.1,0.6,0.3,0.9L54,60l0.5,0.3
                    c0.2,0.1,0.6,0.4,1.2,0.4H62c0.1,0.4,0.3,0.9,0.5,1.5l-0.1,0l0.1,0.4c0.5,1.3,1,2.6,1.9,4.1c0.5,0.9,1.1,1.8,1.8,2.7
                    c-2.5,2.4-5.6,4.1-9.6,5.4c-0.5,0-0.8,0.4-1,0.6l-0.1,0.1c-0.2,0.4-0.4,0.9-0.4,1.4c0.2,0.8,1,1.4,1.9,1.4h0.3l0.1,0
                    c4.5-1.3,8.3-3.4,11.5-6.4c3.1,3,7,5.1,11.5,6.4l0.4,0c0.3,0,0.7-0.1,1-0.3h1.6l-1.1-0.3c0.2-0.2,0.3-0.3,0.4-0.6
                    c0.2-0.6,0-1.1-0.2-1.4c-0.2-0.4-0.7-0.7-1.1-0.9c-4-1.1-7.2-2.9-9.8-5.5c0.3-0.4,0.6-0.9,0.9-1.4c0.3-0.4,0.5-0.7,0.7-1l0.2-0.3
                    l-0.1-0.1c0.8-1.3,1.3-2.5,1.7-3.8c0.3-0.8,0.5-1.4,0.6-2l6.4,0c0.6,0,1.2-0.3,1.6-0.7l5.3-5.2l-5.5,2.7
                    C83.2,56.9,82.6,56.6,82,56.6z M68.9,66c-1.1-1.7-2-3.7-2.5-5.4h4.9C71,61.7,70.3,63.7,68.9,66z M44.9,39.1c0.3,0,0.6,0,1-0.2
                    c0.6-0.4,0.8-0.8,1-1.1l0.1-0.2L47,37.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.7L33.6,13v-0.2l-0.3-0.3
                    c-0.1-0.1-0.2-0.2-0.4-0.3L32.7,12h-1.8l0.1,0.1c0,0,0,0,0,0h-0.1l0,0l-0.2-0.3l-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.5L17,36.1
                    c-0.2,0.3-0.4,0.8-0.2,1.5l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6l-1,1h3c0.7,0,1.1-0.4,1.4-0.7l5.1-9.1h12.9l4.8,8.6
                    C43.7,38.7,44.3,39.1,44.9,39.1z M27.2,25.6l4.5-8l4.5,8H27.2z M98.4,42.3c-1.2-1.2-2.8-1.9-4.5-1.9H63.1V7.3
                    c0-3.4-2.8-6.2-6.3-6.2H6.6c-3.5,0-6.3,2.8-6.3,6.2V43L0,43v0.4c0,0.2,0,0.4,0,0.5c0.1,1.3,0.5,2.4,1.2,3.3l0.3,0.3l0.3-0.2
                    c1.2,1.4,3,2.3,4.9,2.3h5.8v7.7c0,0.8,0.4,1.5,1,1.6l-1.2,0.9l2.2-0.9c0.2,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.2,0.4-0.3l2-1.6
                    L17,57.1l8.1-7.6h12.1v33.2c0,3.4,2.8,6.2,6.3,6.2h0.3v-0.2h31l-0.2,0.2l10.1,9.5c0.2,0.2,0.3,0.3,0.5,0.4
                    c0.2,0.1,0.4,0.1,0.7,0.1h0.4v-0.5c0.2,0,0.4-0.1,0.6-0.2c0.7-0.4,1-1.1,1-1.6v-7.7h5.8c3.5,0,6.3-2.8,6.3-6.2V46.7
                    C100.1,45,99.5,43.5,98.4,42.3z M37.4,45.8H24.2c-0.8,0-1.1,0.3-1.3,0.6L16,52.9v-5.1c0-1-1-1.8-1.9-1.8H6.6
                    c-1.4,0-2.5-1.1-2.5-2.5V7.3C4.1,6,5.2,5,6.6,5h50.2c1.4,0,2.5,1.1,2.5,2.3v33.2H43.6c-0.8,0-1.6,0.1-2.3,0.4l0-0.1l0,0.1
                    C39.2,41.7,37.7,43.5,37.4,45.8z M96.5,82.4h-0.2v0.4c0,1.3-1.1,2.3-2.5,2.3h-7.7c-0.9,0-1.7,0.7-1.9,1.6l-0.7,0.5l0.7-0.2v5.2
                    l-6.9-6.5c-0.3-0.3-0.8-0.4-1.3-0.4H43.8c-1.4,0-2.5-1.1-2.5-2.3V46.8l0.2,0.1l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.7
                    c0.1-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.5,0.7-0.6l0.4-0.1l0-0.1c0.1,0,0.3-0.1,0.5-0.1h50.2c0.2,0,0.4,0.1,0.6,0.1l0,0.1l0.3,0.1
                    L95,45l0,0c0.3,0.1,0.5,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7l0.2,0.4l0.3-0.2V82.4z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Multi-lingual',
					text: 'The app can be configured to function in more than 60+ languages. Users as well as admin can switch between languages easily.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<g transform="translate(1 1)">
		<g>
			<g>
				<path
					d="M25.7,31.9H39c0.5,0,0.8-0.3,0.8-0.8v-7.5c0-0.5-0.3-0.8-0.8-0.8H25.7c-0.5,0-0.8,0.3-0.8,0.8v7.5
                      C24.8,31.6,25.2,31.9,25.7,31.9z M26.5,24.4h11.7v5.8H26.5V24.4z"
				></path>
				<path
					d="M3.2,21.1h6.7c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H3.2c-0.5,0-0.8,0.3-0.8,0.8S2.7,21.1,3.2,21.1z"
				></path>
				<path
					d="M13.2,21.1h6.7c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-6.7c-0.5,0-0.8,0.3-0.8,0.8S12.7,21.1,13.2,21.1z"
				></path>
				<path
					d="M3.2,24.4H14c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H3.2c-0.5,0-0.8,0.3-0.8,0.8C2.3,24.1,2.7,24.4,3.2,24.4z"
				></path>
				<path
					d="M19.8,22.8h-2.5c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h2.5c0.5,0,0.8-0.3,0.8-0.8C20.7,23.1,20.3,22.8,19.8,22.8
                      z"
				></path>
				<path
					d="M48.2,12.4c-0.6-0.7-1.3-1.2-2.2-1.2l-2-0.2V8.6V6.9c0-1.8-1.5-3.3-3.3-3.3H2.3C0.5,3.6-1,5.1-1,6.9v1.7v6.7v18.3
                      c0,1.5,1,2.8,2.4,3.2c0,1.7,1.2,3.1,2.9,3.3l38.5,4.3c0.1,0,0.2,0,0.3,0c1.7,0,3.2-1.2,3.3-2.8L49,14.8
                      C49.1,14,48.8,13.1,48.2,12.4z M0.7,9.4h41.7v2.2v2.8H0.7V9.4z M2.3,5.3h38.3c0.9,0,1.7,0.7,1.7,1.7v0.8H0.7V6.9
                      C0.7,6,1.4,5.3,2.3,5.3z M0.7,33.6V16.1h41.7v17.5c0,0.9-0.7,1.7-1.7,1.7H2.4H2.3C1.4,35.2,0.7,34.5,0.7,33.6z M47.3,14.5
                      l-2.5,26.7c-0.1,0.9-0.9,1.6-1.8,1.5L4.6,38.4c-0.8-0.1-1.4-0.7-1.5-1.5h37.6c1.8,0,3.3-1.5,3.3-3.3V15.3v-2.7l1.9,0.2
                      c0.4,0,0.8,0.2,1.1,0.6C47.2,13.6,47.4,14.1,47.3,14.5z"
				></path>
			</g>
		</g>
	</g></svg
>`,
					title: 'Multiple Payment Gateway',
					text: 'Integrate your app with any payment gateway or gateways of your choice. Give your customers the flexibility to use any gateway of their choice.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<path
		d="M49.4,0c-0.3,0-0.5,0.2-0.8,0.3C34.3,7.8,24.5,27.4,24.5,50c0,2.8,0.2,5.7,0.5,8.5L6,73.2c-0.5,0.5-0.8,1.2-0.8,1.8v20.5
                c0,1.3,1,2.3,2.4,2.3c0.2,0,0.5,0,0.7-0.2l28.5-8.7c3.5,4.7,7.7,8.3,12.3,10.7c0.7,0.3,1.5,0.3,2.2,0c4.5-2.5,8.8-6.2,12.3-10.7
                l28.5,8.7c1.2,0.3,2.5-0.3,2.9-1.5c0-0.2,0.2-0.5,0.2-0.7V75.1c0-0.7-0.3-1.3-0.8-1.8L75,58.5c0.3-2.8,0.5-5.7,0.5-8.5
                c0-22.7-9.9-42.2-24.1-49.7C50.3,0.2,49.7,0,49.4,0z M49.6,5c11.6,6.8,20.7,24.4,20.7,45c0,20.7-9.1,38.2-20.7,45
                C38,88.2,28.9,70.7,28.9,50C29,29.4,38,11.8,49.6,5z M49.6,21.7c-5.7,0-10.3,4.7-10.3,10.2C39.3,37.5,44,42,49.6,42
                s10.3-4.7,10.3-10.2C59.9,26.2,55.3,21.7,49.6,21.7z M49.6,26.2c3.2,0,5.7,2.5,5.7,5.7c0,3.2-2.5,5.7-5.7,5.7s-5.7-2.5-5.7-5.7
                C43.9,28.7,46.4,26.2,49.6,26.2z M25.7,63.7c1.5,8,4.2,15.3,7.9,21.4L9.5,92.4V76.2L25.7,63.7z M73.7,63.7l16.2,12.3v16.2l-24.1-7.3
                C69.4,78.9,72.1,71.7,73.7,63.7z"
	></path></svg
>`,
					title: 'Ready to Market',
					text: 'Build and launch the app in quick time without losing much time for developing, designing or customization. Our ready-to-market solution will ensure you go live quickly.'
				}
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg * {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg * {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
