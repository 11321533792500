<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">The many possibilities of Fintech App Development</h2>
				<p>
					A single mobile app to do the multifaceted tasks of finance and accounting management.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 66 66.09" style="enable-background:new 0 0 66 66.09;" xml:space="preserve">
                <g>
                  <path d="M24.9,59.1h-4.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.8c0.6,0,1-0.4,1-1S25.4,59.1,24.9,59.1z"></path>
                  <path d="M62.9,28.9l-5.1-1c-1.3-0.3-2.6,0.5-3,1.8L48,27.4c-1.3-0.4-2.7-0.5-4.1-0.1V4.9C44,2.8,42.2,1,40,1H4.9C2.8,1,1,2.8,1,4.9
                  v55C1,62.7,3.3,65,6.1,65H40c2.2,0,3.9-1.8,3.9-3.9V49.5l3.6-0.7l3.3,0.7c-0.2,1.4,0.7,2.7,2,2.9c5.1,1,5.1,1.1,5.6,1.1
                  c1.2,0,2.3-0.8,2.5-2.1l4-19.5C65.2,30.5,64.3,29.1,62.9,28.9z M4.9,3H40c1.1,0,2,0.9,2,1.9v3.8H3V4.9C3,3.9,3.9,3,4.9,3z M40,63
                  H6.1C4.4,63,3,61.6,3,59.9v-2.6h39v3.8C42,62.1,41.1,63,40,63z M42,55.3H3V10.8h39v17l-10,2.8c-2.2,0.6-3.5,2.9-2.8,5.1
                  c0.6,2,2.8,3.2,4.8,2.8l4.5-1.1c0.8-0.2,1.2,0.3,1.4,0.7s0.2,1-0.4,1.5L29,42.3l-13.2-5.2c-1.1-0.4-2.3-0.4-3.4,0.1
                  s-1.9,1.4-2.3,2.4c-0.8,2,0.2,4.4,2.3,5.3l14.7,6.6c1.3,0.6,2.8,0.8,4.3,0.5L42,49.9V55.3z M47.3,46.8L31,50.1
                  c-1,0.2-2.1,0.1-3.1-0.3l-14.7-6.6c-1.2-0.5-1.6-1.9-1.2-2.8c0.4-1.2,1.8-1.8,3-1.4l13.5,5.4c0.2,0.1,0.4,0.1,0.6,0l11.1-2.9
                  c1.2-0.3,2.4-2.2,1.6-4.1c-0.6-1.5-2.2-2.3-3.7-1.9l-4.5,1.1c-1.1,0.3-2.1-0.4-2.5-1.4c-0.3-1.1,0.3-2.3,1.4-2.6l11.8-3.3
                  c1-0.3,2.1-0.3,3.1,0.1l7,2.3c-0.9,4.5-1.6,7.9-3.3,15.9C47.6,46.8,47.7,46.7,47.3,46.8z M63,31.5L59,51c-0.1,0.3-0.4,0.5-0.7,0.4
                  l-5.1-1c-0.3-0.1-0.5-0.4-0.4-0.7c1-4.7,1.7-8.1,4-19.5c0.1-0.3,0.4-0.5,0.7-0.4l5.1,1C62.9,30.9,63.1,31.2,63,31.5z"></path>
                  <path d="M19.1,35.7c5.9,0,10.7-4.8,10.7-10.6S25,14.5,19.1,14.5S8.4,19.3,8.4,25.1S13.2,35.7,19.1,35.7z M19.1,16.5
                  c4.8,0,8.7,3.9,8.7,8.6s-3.9,8.6-8.7,8.6s-8.7-3.9-8.7-8.6S14.3,16.5,19.1,16.5z"></path>
                  <path d="M19.1,28.6c-0.7,0-1.3-0.6-1.3-1.2s-0.4-1-1-1s-1,0.4-1,1c0,1.4,1,2.6,2.3,3.1V31c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.6
                  c1.3-0.4,2.3-1.6,2.3-3.1c0-1.8-1.5-3.2-3.3-3.2c-0.7,0-1.3-0.6-1.3-1.2s0.6-1.2,1.3-1.2c0.7,0,1.3,0.6,1.3,1.2s0.4,1,1,1
                  s1-0.4,1-1c0-1.4-0.9-2.6-2.3-3.1v-0.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v0.6c-1.3,0.4-2.3,1.6-2.3,3.1c0,1.8,1.5,3.2,3.3,3.2
                  c0.7,0,1.3,0.6,1.3,1.2S19.8,28.6,19.1,28.6z"></path>
                  <path d="M27.2,6.9L27.2,6.9c0.6,0,1-0.4,1-1s-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1S26.7,6.9,27.2,6.9z"></path>
                  <path d="M22.5,6.9L22.5,6.9c0.6,0,1-0.4,1-1s-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1S21.9,6.9,22.5,6.9z"></path>
                  <path d="M17.7,6.9L17.7,6.9c0.6,0,1-0.4,1-1s-0.4-1-1-1l0,0c-0.6,0-1,0.4-1,1S17.2,6.9,17.7,6.9z"></path>
                </g>
              </svg>`,
					title: 'Payment Gateway Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M90.3,25.3c-0.1-2.8-0.1-5.4-0.1-7.9c0-1.4-1.1-2.5-2.5-2.5c-15,0-26.4-4.5-36-14.1c-1-1-2.5-1-3.4,0
                    c-9.5,9.6-21,14.1-36,14.1c-1.4,0-2.5,1.1-2.5,2.5c0,2.5,0,5.1-0.1,7.9c-0.5,25.3-1.1,60,39.4,74.6c0.5,0.2,1.1,0.2,1.6,0
                    C91.3,85.3,90.7,50.6,90.3,25.3z M50,96.3c-37.7-14-37.1-46-36.6-71.8c0-2,0.1-3.8,0.1-5.7C28.3,18.4,40,13.8,50,4.5
                    c10,9.3,21.7,13.9,36.5,14.3c0,1.8,0.1,3.7,0.1,5.7C87.1,50.3,87.7,82.3,50,96.3z M30.8,40.3h-9.6c-0.6,0-1.1,0.5-1.1,1.1v1.2
                    c0,0.6,0.5,1.1,1.1,1.1h9.6c0.6,0,1.1-0.5,1.1-1.1v-1.2C31.9,40.8,31.4,40.3,30.8,40.3z M47.9,42.7v-1.2c0-0.6-0.5-1.1-1.1-1.1
                    h-9.6c-0.6,0-1.1,0.5-1.1,1.1v1.2c0,0.6,0.5,1.1,1.1,1.1h9.6C47.4,43.8,47.9,43.3,47.9,42.7z M62.9,40.4h-9.6
                    c-0.6,0-1.1,0.5-1.1,1.1v1.2c0,0.6,0.5,1.1,1.1,1.1h9.6c0.6,0,1.1-0.5,1.1-1.1v-1.2C64,40.9,63.5,40.4,62.9,40.4z M78.8,40.4h-9.6
                    c-0.6,0-1.1,0.5-1.1,1.1v1.2c0,0.6,0.5,1.1,1.1,1.1h9.6c0.6,0,1.1-0.5,1.1-1.1v-1.2C79.9,40.9,79.4,40.4,78.8,40.4z M21.5,34.5
                    l0.4,0.6c0.2,0.3,0.6,0.5,1,0.3l2-1.2v2.4c0,0.4,0.3,0.7,0.7,0.7h0.7c0.4,0,0.7-0.3,0.7-0.7v-2.4l2,1.2c0.1,0.1,0.3,0.1,0.4,0.1
                    c0.3,0,0.5-0.2,0.6-0.4l0.4-0.6c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4L28,32.2l2.1-1.2c0.3-0.2,0.5-0.4,0.5-0.6
                    c0-0.2,0-0.3-0.1-0.5l-0.4-0.6c-0.2-0.3-0.6-0.5-1-0.3l-2,1.2v-2.4c0-0.4-0.3-0.7-0.7-0.7h-0.7c-0.4,0-0.7,0.3-0.7,0.7v2.4l-2-1.2
                    c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.2-0.6,0.4l-0.4,0.6c-0.1,0.2-0.2,0.4-0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4l2.1,1.2l-2.2,1.3
                    C21.4,33.7,21.4,34.2,21.5,34.5z M37.8,31l2.1,1.2l-2.1,1.2c-0.3,0.2-0.4,0.7-0.3,1l0.4,0.6c0.2,0.2,0.4,0.4,0.6,0.4
                    c0.1,0,0.2,0,0.4-0.1l2-1.2v2.4c0,0.4,0.3,0.7,0.7,0.7h0.7c0.4,0,0.7-0.3,0.7-0.7v-2.4l2,1.2c0.1,0.1,0.3,0.1,0.4,0.1
                    c0.3,0,0.5-0.2,0.6-0.4l0.4-0.6c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4l-2.1-1.2L46,31c0.4-0.3,0.4-0.7,0.3-1l-0.4-0.6
                    c-0.2-0.3-0.6-0.5-1-0.3l-2,1.2v-2.4c0-0.4-0.3-0.7-0.7-0.7h-0.7c-0.4,0-0.7,0.3-0.7,0.7v2.4l-2-1.2c-0.1-0.1-0.3-0.1-0.4-0.1
                    c-0.3,0-0.5,0.2-0.6,0.4l-0.4,0.6c-0.1,0.2-0.2,0.4-0.1,0.7C37.4,30.8,37.6,30.9,37.8,31z M53.9,30.8L56,32l-2.1,1.3
                    c-0.4,0.3-0.4,0.7-0.3,1l0.4,0.6c0.2,0.3,0.6,0.5,1,0.3l2-1.2v2.4c0,0.4,0.3,0.7,0.7,0.7h0.7c0.4,0,0.7-0.3,0.7-0.7v-2.4l2,1.2
                    c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.2,0.6-0.4l0.4-0.6c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4l-1.9-1l2.1-1.2
                    c0.4-0.3,0.4-0.7,0.3-1l-0.4-0.6c-0.2-0.3-0.6-0.5-1-0.3l-2,1.2v-2.4l0-0.1C59,27.3,58.7,27,58.3,27h-0.7c-0.4,0-0.7,0.3-0.7,0.7
                    v2.4l-2-1.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.2-0.6,0.4l-0.4,0.6c-0.1,0.2-0.2,0.4-0.1,0.7C53.6,30.6,53.7,30.7,53.9,30.8z
                    M69.9,35.1c0.2,0.2,0.4,0.4,0.6,0.4c0.1,0,0.2,0,0.4-0.1l2-1.2v2.4c0,0.4,0.3,0.7,0.7,0.7h0.7c0.4,0,0.7-0.3,0.7-0.7v-2.4l2,1.2
                    c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.2,0.6-0.4l0.4-0.6c0.1-0.2,0.2-0.4,0.1-0.7c-0.1-0.2-0.2-0.3-0.4-0.4l-1.9-1.2l2.1-1.3
                    c0.4-0.3,0.4-0.7,0.3-1l-0.4-0.6c-0.2-0.3-0.6-0.5-1-0.3l-2,1.2v-2.4c0-0.4-0.3-0.7-0.7-0.7h-0.7c-0.4,0-0.7,0.3-0.7,0.7v2.4
                    l-2-1.2c-0.1-0.1-0.3-0.1-0.4-0.1c-0.3,0-0.5,0.2-0.6,0.4l-0.4,0.6c-0.1,0.2-0.2,0.4-0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4l2.1,1.2
                    l-2,1.2c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.2,0,0.4,0.1,0.6L69.9,35.1z M59.1,60.2h-1.7v-1.8c0-4.2-3.3-7.6-7.4-7.6
                    c-4.1,0-7.4,3.4-7.4,7.6v1.8h-1.7c-0.7,0-1.3,0.6-1.3,1.4v14.6c0,0.8,0.6,1.4,1.3,1.4h18.1c0.7,0,1.3-0.6,1.3-1.4V61.6
                    C60.4,60.9,59.8,60.2,59.1,60.2z M45.3,58.5c0-2.7,2.1-4.9,4.7-4.9c2.6,0,4.7,2.2,4.7,4.9v1.8h-9.5V58.5z M57.8,74.9H42.2V63h15.5
                    V74.9z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Multiple authentication System'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M99.9,13.9c-0.1-0.2-0.2-0.4-0.3-0.5L86.6,0.5c-0.1-0.1-0.3-0.3-0.5-0.3C85.9,0,85.7,0,85.5,0H37.1
                    c-0.9,0-1.6,0.7-1.6,1.6v24.2H1.6c-0.9,0-1.6,0.7-1.6,1.6v71c0,0.9,0.7,1.6,1.6,1.6h51.6c0.9,0,1.6-0.7,1.6-1.6v-21h43.5
                    c0.9,0,1.6-0.7,1.6-1.6V14.5C100,14.3,100,14.1,99.9,13.9z M87.1,5.5l7.4,7.4h-7.4V5.5z M51.6,96.8H3.2V29h48.4V96.8z M96.8,74.2
                    H54.8V54.8h12.9v4.8c0,0.9,0.7,1.6,1.6,1.6h22.6c0.9,0,1.6-0.7,1.6-1.6V21c0-0.9-0.7-1.6-1.6-1.6H69.4c-0.9,0-1.6,0.7-1.6,1.6v4.8
                    h-29V3.2h45.2v11.3c0,0.9,0.7,1.6,1.6,1.6h11.3V74.2z M56.5,32.3h-1.6V29h12.9v3.2h-4.8c-0.9,0-1.6,0.7-1.6,1.6
                    c0,0.9,0.7,1.6,1.6,1.6h4.8v3.2H54.8v-3.2h1.6c0.9,0,1.6-0.7,1.6-1.6C58.1,33,57.3,32.3,56.5,32.3z M67.7,41.9v3.2H54.8v-3.2H67.7
                    z M67.7,48.4v3.2H54.8v-3.2H67.7z M82.3,51.6H71v-3.2h19.4v3.2h-1.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h1.6v3.2H71
                    v-3.2h11.3c0.9,0,1.6-0.7,1.6-1.6C83.9,52.3,83.1,51.6,82.3,51.6z M90.3,29v3.2H71V29H90.3z M71,25.8v-3.2h19.4v3.2H71z
                    M90.3,35.5v3.2H71v-3.2H90.3z M90.3,41.9v3.2H71v-3.2H90.3z M8.1,93.5h38.7c0.9,0,1.6-0.7,1.6-1.6V53.2c0-0.9-0.7-1.6-1.6-1.6
                    H8.1c-0.9,0-1.6,0.7-1.6,1.6v38.7C6.5,92.8,7.2,93.5,8.1,93.5z M29,54.8h16.1V71H29V54.8z M29,74.2h16.1v16.1H29V74.2z M9.7,54.8
                    h16.1V71H9.7V54.8z M9.7,74.2h16.1v16.1H9.7V74.2z M21,61.3h-1.6v-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v1.6h-1.6
                    c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h1.6v1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-1.6H21c0.9,0,1.6-0.7,1.6-1.6
                    S21.9,61.3,21,61.3z M40.3,61.3h-6.5c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h6.5c0.9,0,1.6-0.7,1.6-1.6S41.2,61.3,40.3,61.3z
                    M40.3,77.4h-6.5c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h6.5c0.9,0,1.6-0.7,1.6-1.6S41.2,77.4,40.3,77.4z M40.3,83.9h-6.5
                    c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h6.5c0.9,0,1.6-0.7,1.6-1.6C41.9,84.6,41.2,83.9,40.3,83.9z M22.1,77.9
                    c-0.6-0.6-1.6-0.6-2.3,0L17.7,80l-2.1-2.1c-0.6-0.6-1.7-0.6-2.3,0c-0.6,0.6-0.6,1.7,0,2.3l2.1,2.1l-2.1,2.1
                    c-0.6,0.6-0.6,1.7,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5l2.1-2.1l2.1,2.1c0.3,0.3,0.7,0.5,1.1,0.5
                    c0.4,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.7,0-2.3L20,82.3l2.1-2.1C22.7,79.5,22.7,78.5,22.1,77.9z M8.1,48.4h38.7
                    c0.9,0,1.6-0.7,1.6-1.6V33.9c0-0.9-0.7-1.6-1.6-1.6H8.1c-0.9,0-1.6,0.7-1.6,1.6v12.9C6.5,47.7,7.2,48.4,8.1,48.4z M9.7,35.5h35.5
                    v9.7H9.7V35.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Calculators for loan and tax'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M58.3,0c-23,0-41.7,18.7-41.7,41.7c0,8.8,2.8,17.5,8,24.6L0.5,90.5c-0.7,0.7-0.7,1.7,0,2.4l6.7,6.7c0.7,0.7,1.7,0.7,2.4,0
                    l24.2-24.2c7.1,5.3,15.8,8.1,24.6,8c23,0,41.7-18.7,41.7-41.7C100,18.7,81.4,0,58.3,0z M8.3,96L4,91.7l16-16l4.3,4.3L8.3,96z
                    M58.3,80c-8.6,0-17-2.9-23.7-8.2c-0.7-0.5-1.6-0.5-2.2,0.1l-5.7,5.7l-4.3-4.3l5.7-5.7c0.6-0.6,0.7-1.5,0.1-2.2
                    C22.9,58.6,20,50.3,20,41.7C20,20.5,37.2,3.3,58.3,3.3s38.4,17.2,38.4,38.3C96.7,62.8,79.5,80,58.3,80z M58.3,8.3
                    C39.9,8.3,25,23.3,25,41.7C25,60.1,39.9,75,58.3,75c18.4,0,33.3-14.9,33.3-33.3C91.7,23.3,76.7,8.4,58.3,8.3z M58.3,71.7
                    c-16.6,0-30-13.4-30-30c0-16.6,13.4-30,30-30c16.6,0,30,13.4,30,30C88.3,58.2,74.9,71.6,58.3,71.7z M76.7,51.7v-25
                    c0-0.9-0.7-1.7-1.7-1.7h-6.7c-0.9,0-1.7,0.7-1.7,1.7v25h-3.3V30c0-0.9-0.7-1.7-1.7-1.7H55c-0.9,0-1.7,0.7-1.7,1.7v21.7H50V40
                    c0-0.9-0.7-1.7-1.7-1.7h-6.7c-0.9,0-1.7,0.7-1.7,1.7v11.7h-3.3V55h45v-3.3H76.7z M46.7,51.7h-3.3v-10h3.3V51.7z M60,51.7h-3.3v-20
                    H60V51.7z M73.3,51.7H70V28.3h3.3V51.7z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Data Analytics'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M39.4,45c0.3-0.3,0.4-0.6,0.4-1V16.8c0-0.8-0.6-1.4-1.4-1.4h-23c-0.8,0-1.4,0.6-1.4,1.4v27.1c0,0.8,0.6,1.4,1.4,1.4h23
                    C38.8,45.4,39.2,45.2,39.4,45z M16.9,18.3H37v15.9c-0.7-0.5-1.4-1-2.2-1.3l-2.1-0.8c0.5-1.2,0.8-2.4,0.8-3.7c0-4.4-2.9-8-6.6-8
                    c-3.6,0-6.5,3.6-6.5,8c0,1.3,0.3,2.6,0.8,3.7L19,32.9c-0.8,0.3-1.5,0.7-2.2,1.3V18.3z M30.7,28.4c0,2.8-1.7,5.1-3.7,5.1
                    c-2,0-3.7-2.3-3.7-5.1c0-2.8,1.7-5.1,3.7-5.1C29,23.2,30.7,25.5,30.7,28.4z M37,42.5H16.9v-2.3c0-1.1,0.4-2.1,1-3
                    c0.6-0.7,1.3-1.3,2.2-1.6l2.8-1.1c1.2,1.2,2.6,1.8,4.1,1.8c1.5,0,3-0.6,4.1-1.8l2.8,1.1c0.9,0.3,1.6,0.9,2.2,1.6
                    c0.6,0.9,1,1.9,1,3V42.5z M45.3,21.5h24.8c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H45.3c-0.8,0-1.4,0.6-1.4,1.4
                    C43.9,20.9,44.5,21.5,45.3,21.5z M70.1,28.5H49.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h20.5c0.8,0,1.4-0.6,1.4-1.4
                    c0-0.4-0.1-0.7-0.4-1C70.8,28.6,70.4,28.5,70.1,28.5z M70.1,38.3H49.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h20.5
                    c0.8,0,1.4-0.6,1.4-1.4c0-0.4-0.1-0.7-0.4-1C70.8,38.5,70.4,38.3,70.1,38.3z M70.4,55.4c0-0.8-0.6-1.4-1.4-1.4H18.3
                    c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4H69C69.8,56.8,70.4,56.2,70.4,55.4z M62.6,64H24.6c-0.8,0-1.4,0.6-1.4,1.4
                    c0,0.8,0.6,1.4,1.4,1.4h38.1c0.8,0,1.4-0.6,1.4-1.4C64.1,64.7,63.4,64,62.6,64z M94.1,62.1c-0.7-0.5-1.6-0.7-2.4-0.7
                    c-1.5,0-2.9,0.7-3.7,2l-2.9,4.4h-1.5V5.5c0-0.8-0.6-1.4-1.4-1.4H58.7C58.4,1.8,56.5,0,54.1,0H33.4c-2.3,0-4.3,1.8-4.6,4.1H5.3
                    c-0.8,0-1.4,0.6-1.4,1.4v87.7c0,0.8,0.6,1.4,1.4,1.4h54.9c0.3,3,2.8,5.4,5.8,5.4h20.3c3.2,0,5.8-2.6,5.8-5.9V73.6
                    c0-0.1,0-0.3-0.1-0.4l3.3-5C96.7,66.2,96.2,63.4,94.1,62.1z M58.7,7h22.1v60.8h-1.1V9.5c0-0.8-0.6-1.4-1.4-1.4H58.4
                    C58.5,7.7,58.6,7.4,58.7,7z M31.7,4.6c0-1,0.8-1.8,1.8-1.8h20.7c1,0,1.8,0.8,1.8,1.8v1.6c0,1-0.8,1.8-1.8,1.8H33.4
                    c-1,0-1.8-0.8-1.8-1.8V4.6z M60.2,91.8H6.7V7h22.2c0.1,0.4,0.2,0.7,0.3,1.1H9.3c-0.8,0-1.4,0.6-1.4,1.4v79.7
                    c0,0.8,0.6,1.4,1.4,1.4h50.5c0.1,0,0.2,0,0.4-0.1V91.8z M60.2,73.6v1.3H18.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h41.9
                    v10.1c-0.1,0-0.2-0.1-0.4-0.1H10.7V10.9h66.2v56.8H66C62.8,67.7,60.2,70.4,60.2,73.6z M89.3,94.1c0,1.7-1.4,3-3,3H66
                    c-1.7,0-3-1.4-3-3V73.6c0-1.7,1.4-3,3-3h17.3l-7.6,11.7L72,75.4c-0.8-1.4-2.3-2.3-3.9-2.3c-0.7,0-1.4,0.2-2.1,0.5
                    c-2.1,1.2-2.9,3.9-1.8,6L71.4,93c0.7,1.4,2.1,2.2,3.7,2.3l0.2,0c1.5,0,2.9-0.7,3.7-2l10.4-15.8L89.3,94.1z M93,66.7L76.6,91.7
                    c-0.3,0.4-0.8,0.7-1.3,0.7l-0.1,0c-0.5,0-1-0.3-1.3-0.8l-7.2-13.4c-0.4-0.8-0.1-1.7,0.6-2.1c0.2-0.1,0.5-0.2,0.7-0.2
                    c0.6,0,1.1,0.3,1.4,0.8l4.8,9c0.2,0.4,0.7,0.7,1.2,0.7c0.5,0,1-0.2,1.2-0.6l13.7-20.9c0.2-0.3,0.6-0.6,1-0.7
                    c0.4-0.1,0.8,0,1.2,0.2c0.3,0.2,0.6,0.6,0.7,1C93.3,65.9,93.3,66.3,93,66.7z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Compliance and Regulation'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M72.6,90.3H71V53.2c0-0.9-0.7-1.6-1.6-1.6h-4.8v-21c0-0.9-0.7-1.6-1.6-1.6H11.3c-0.9,0-1.6,0.7-1.6,1.6v21H4.8
                    c-0.9,0-1.6,0.7-1.6,1.6v37.1H1.6C0.7,90.3,0,91,0,91.9c0,4.4,3.6,8.1,8.1,8.1h58.1c4.4,0,8.1-3.6,8.1-8.1
                    C74.2,91,73.5,90.3,72.6,90.3z M12.9,32.3h48.4v9.7H12.9V32.3z M12.9,45.2h48.4v38.7H12.9V45.2z M6.5,54.8h3.2v30.6
                    c0,0.9,0.7,1.6,1.6,1.6h51.6c0.9,0,1.6-0.7,1.6-1.6V54.8h3.2v35.5H6.5V54.8z M66.1,96.8H8.1c-2.1,0-3.9-1.3-4.6-3.2h67.2
                    C70,95.4,68.2,96.8,66.1,96.8z M16.1,38.7h3.2v-3.2h-3.2V38.7z M22.6,38.7h3.2v-3.2h-3.2V38.7z M29,38.7h3.2v-3.2H29V38.7z
                    M25.8,48.4h-9.7v3.2h9.7V48.4z M32.3,48.4H29v3.2h3.2V48.4z M38.7,48.4h-3.2v3.2h3.2V48.4z M41.9,51.6h9.7v-3.2h-9.7V51.6z
                    M32.3,54.8H16.1v3.2h16.1V54.8z M45.2,54.8h-9.7v3.2h9.7V54.8z M25.8,67.7h-9.7V71h9.7V67.7z M29,71h3.2v-3.2H29V71z M38.7,71
                    v-3.2h-3.2V71H38.7z M41.9,71h9.7v-3.2h-9.7V71z M16.1,77.4h16.1v-3.2H16.1V77.4z M35.5,77.4h9.7v-3.2h-9.7V77.4z M41.9,64.5h9.7
                    v-3.2h-9.7V64.5z M38.7,61.3H29v3.2h9.7V61.3z M25.8,61.3h-9.7v3.2h9.7V61.3z M52.8,17.3l3.7-3.7l3.7,3.7l2.3-2.3l-3.7-3.7
                    l3.7-3.7l-2.3-2.3L56.5,9l-3.7-3.7l-2.3,2.3l3.7,3.7L50.5,15L52.8,17.3z M68.9,17.3l3.7-3.7l3.7,3.7l2.3-2.3l-3.7-3.7l3.7-3.7
                    l-2.3-2.3L72.6,9l-3.7-3.7l-2.3,2.3l3.7,3.7L66.6,15L68.9,17.3z M85,17.3l3.7-3.7l3.7,3.7l2.3-2.3L91,11.3l3.7-3.7l-2.3-2.3
                    L88.7,9L85,5.3l-2.3,2.3l3.7,3.7L82.7,15L85,17.3z M98.4,0H46.8c-0.9,0-1.6,0.7-1.6,1.6v8.1h-8.1c-2.7,0-4.8,2.2-4.8,4.8v5.8
                    l-3.7-3.7l-2.3,2.3l6.5,6.5c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5l6.5-6.5l-2.3-2.3l-3.7,3.7v-5.8c0-0.9,0.7-1.6,1.6-1.6
                    h8.1V21c0,0.9,0.7,1.6,1.6,1.6h51.6c0.9,0,1.6-0.7,1.6-1.6V1.6C100,0.7,99.3,0,98.4,0z M96.8,19.4H48.4V3.2h48.4V19.4z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Encrypted Data'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M96,58.3h-1.6V35c0-0.9-0.7-1.7-1.6-1.7h-2.3l-16-32.4c-0.4-0.8-1.4-1.2-2.2-0.7c-0.1,0-0.1,0.1-0.2,0.1L21.6,33.3h-9.4
                    v-6.7h11.5c0.3,0,0.6-0.1,0.9-0.3l9.8-6.7c0.8-0.5,1-1.5,0.5-2.3c-0.3-0.5-0.8-0.7-1.4-0.7h-23c-4.5,0-8.2,3.7-8.2,8.3v66.5
                    c0,4.7,3.8,8.5,8.4,8.5h81.9c0.9,0,1.6-0.7,1.6-1.7V75H96c0.9,0,1.6-0.7,1.6-1.7V60C97.6,59.1,96.9,58.3,96,58.3z M72.3,4.1
                    l14.4,29.3H27.7L72.3,4.1z M5.7,25c0-2.8,2.2-5,4.9-5h17.6l-4.9,3.3h-11c-1.8,0-3.3,1.5-3.3,3.3v6.7H5.7V25z M91,96.7H10.8
                    c-2.8,0-5.1-2.3-5.1-5.2V36.7H91v21.7h-3.3V41.7c0-0.9-0.7-1.7-1.6-1.7H10.6C9.7,40,9,40.7,9,41.7v48.1c0,2,1.6,3.6,3.5,3.6h73.6
                    c0.9,0,1.6-0.7,1.6-1.7V75H91V96.7z M69.7,75h14.8v15h-72c-0.1,0-0.2-0.1-0.2-0.2V43.3h72.2v15H69.7c-0.9,0-1.6,0.7-1.6,1.7v13.3
                    C68.1,74.3,68.8,75,69.7,75z M94.3,71.7h-23v-10h23V71.7z M77.9,65h-3.3v3.3h3.3V65z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Wallet Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M91.2,67.7L79,64.8v-3.2c1.2-1,2.3-2.2,3.1-3.6h3.4c4.4,0,8.1-3.6,8.1-8.1c0-2.6-1.3-4.9-3.2-6.4v-4.9
                    c0-10.7-8.7-19.4-19.4-19.4c-10.7,0-19.4,8.7-19.4,19.4v4.9c-1.9,1.5-3.2,3.8-3.2,6.4c0,4.4,3.6,8.1,8.1,8.1h3.4
                    c0.8,1.4,1.9,2.6,3.1,3.6v3.2l-12.2,2.8c-5.2,1.2-8.8,5.7-8.8,11V100H100V78.7C100,73.4,96.4,68.9,91.2,67.7z M90.3,50
                    c0,2.7-2.2,4.8-4.8,4.8h-2c0.3-1,0.4-2.1,0.4-3.2v-6.5h1.6C88.2,45.2,90.3,47.3,90.3,50z M56.5,54.8c-2.7,0-4.8-2.2-4.8-4.8
                    s2.2-4.8,4.8-4.8h1.6v6.5c0,1.1,0.2,2.2,0.4,3.2H56.5z M58.1,38.7v3.2h-1.6c-0.6,0-1.1,0.1-1.6,0.2v-3.4c0-8.9,7.2-16.1,16.1-16.1
                    c8.9,0,16.1,7.2,16.1,16.1v3.4c-0.5-0.1-1.1-0.2-1.6-0.2h-1.6v-3.2h-3.2v0.9c-1.1,1-2.5,1.7-3.9,2c0.1-0.1,0.1-0.1,0.2-0.2
                    l-2.3-2.3c-1.2,1.2-2.7,2.1-4.4,2.5c0.1-0.1,0.1-0.1,0.2-0.2l-2.3-2.3c-1.8,1.8-4.1,2.8-6.6,2.8h-0.3v-3.2H58.1z M61.3,51.6v-6.5
                    h0.3c1.6,0,3.1-0.3,4.6-0.9v0.9H68c1.6,0,3.1-0.3,4.6-0.9v0.9h1.9c2.2,0,4.3-0.6,6.2-1.6v8.1c0,1.1-0.2,2.2-0.6,3.2H71v3.2h7.2
                    c-1.8,2-4.3,3.2-7.2,3.2C65.6,61.3,61.3,57,61.3,51.6z M66.1,65.3v-1.8c1.5,0.6,3.1,1,4.8,1c1.7,0,3.3-0.3,4.8-1v1.8L71,69
                    L66.1,65.3z M68.1,70.8l-4,2.4l-0.9-5.1l0.9-0.2L68.1,70.8z M77.8,67.9l1.1,0.3l-1,5.1l-4-2.4L77.8,67.9z M96.8,96.8h-8.1V79h-3.2
                    v17.7h-29V79h-3.2v17.7h-8.1V78.7c0-3.8,2.6-7,6.3-7.9l8.7-2l1.6,9.6l9.3-5.6l9.2,5.5l1.9-9.5l8.5,2c3.7,0.9,6.3,4.1,6.3,7.9V96.8
                    z M45.2,0H10.6L0,10.6v50.7h45.2V0z M9.7,5.5v4.2H5.5L9.7,5.5z M41.9,58.1H3.2V12.9h9.7V3.2h29V58.1z M38.7,6.5H25.8v3.2h12.9V6.5
                    z M38.7,12.9H25.8v3.2h12.9V12.9z M38.7,19.4H6.5v3.2h32.3V19.4z M38.7,29v-3.2H24.2V29H38.7z M21,25.8H6.5V29H21V25.8z M8.1,35.5
                    h32.3v-3.2H8.1V35.5z M6.5,41.9h32.3v-3.2H6.5V41.9z M24.2,48.4h14.5v-3.2H24.2V48.4z M21,45.2H6.5v3.2H21V45.2z M6.5,54.8h32.3
                    v-3.2H6.5V54.8z M33.8,72.7l1-2l-3.9-3.9l-2,1c-0.9-0.5-1.9-0.9-2.9-1.2l-0.7-2.1h-5.6l-0.7,2.1c-1,0.3-2,0.7-2.9,1.2l-2-1
                    l-3.9,3.9l1,2c-0.5,0.9-0.9,1.9-1.2,2.9l-2.1,0.7v5.6l2.1,0.7c0.3,1,0.7,2,1.2,2.9l-1,2l3.9,3.9l2-1c0.9,0.5,1.9,0.9,2.9,1.2
                    l0.7,2.1h5.6l0.7-2.1c1-0.3,2-0.7,2.9-1.2l2,1l3.9-3.9l-1-2c0.5-0.9,0.9-1.9,1.2-2.9l2.1-0.7v-5.6L35,75.6
                    C34.7,74.6,34.3,73.6,33.8,72.7z M33.9,79.5L32.3,80l-0.2,0.9c-0.2,1.2-0.7,2.4-1.4,3.4l-0.5,0.8l0.8,1.5l-0.6,0.6l-1.5-0.8
                    l-0.8,0.5c-1.1,0.7-2.2,1.2-3.4,1.4l-0.9,0.2L23,90.3h-0.9l-0.5-1.6l-0.9-0.2c-1.2-0.2-2.4-0.7-3.4-1.4l-0.8-0.5l-1.5,0.8
                    l-0.6-0.6l0.8-1.5l-0.5-0.8c-0.7-1.1-1.2-2.2-1.4-3.4L12.9,80l-1.6-0.5v-0.9l1.6-0.5l0.2-0.9c0.2-1.2,0.7-2.4,1.4-3.5l0.5-0.8
                    l-0.8-1.5l0.6-0.6l1.5,0.8l0.8-0.5c1.1-0.7,2.2-1.2,3.4-1.4l0.9-0.2l0.5-1.6H23l0.5,1.6l0.9,0.2c1.2,0.2,2.4,0.7,3.4,1.4l0.8,0.5
                    l1.5-0.8l0.6,0.6l-0.8,1.5l0.5,0.8c0.7,1.1,1.2,2.2,1.4,3.5l0.2,0.9l1.6,0.5V79.5z M22.6,72.6c-3.6,0-6.5,2.9-6.5,6.5
                    c0,3.6,2.9,6.5,6.5,6.5S29,82.6,29,79C29,75.5,26.1,72.6,22.6,72.6z M22.6,82.3c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2
                    s3.2,1.4,3.2,3.2C25.8,80.8,24.4,82.3,22.6,82.3z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Custom Reporting'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
