<template>
	<section class="padding-60 bg-white">
		<div class="what-we-offer-main-tabs">
			<div class="what-we-offer-main-slider-section bg-white d-none d-lg-block top-sticy">
				<div class="container">
					<div class="what-we-offer-main-slider what-we-offer-main-item">
						<div class="what-we-offer-main-item-box">
							<a href="#section1" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<rect x="10" y="3.3" width="1.7" height="1.7"></rect>
										<rect x="13.3" y="3.3" width="1.7" height="1.7"></rect>
										<rect x="16.7" y="3.3" width="1.7" height="1.7"></rect>
										<path
											d="M0.8,50h25.8c0.5,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.4-0.2-0.6L1.4,22.7c-0.3-0.3-0.9-0.3-1.2,0C0.1,22.9,0,23.1,0,23.3v25.8
                        C0,49.6,0.4,50,0.8,50z M1.7,25.3l23,23h-23V25.3z"
										></path>
										<path
											d="M14.8,43.6l-8.3-8.3c-0.3-0.3-0.9-0.3-1.2,0C5.1,35.4,5,35.6,5,35.8v8.3C5,44.6,5.4,45,5.8,45h8.3c0.5,0,0.8-0.4,0.8-0.8
                        C15,43.9,14.9,43.7,14.8,43.6z M6.7,43.3v-5.5l5.5,5.5H6.7z"
										></path>
										<path
											d="M49.1,7.1c1.2-1.2,1.2-3.3,0-4.5c-1.2-1.2-3.3-1.2-4.5,0l-1.3,1.3c-0.2-2.2-2-3.8-4.1-3.9H10.8C8.5,0,6.7,1.9,6.7,4.2v21.7
                        h1.7V4.2c0-1.4,1.1-2.5,2.5-2.5h28.3c1.4,0,2.5,1.1,2.5,2.5v1.3l-7.8,7.8h-6.1c-1.8-4.7-7.2-7-11.9-5.2s-7,7.2-5.2,11.9
                        c1.3,3.2,4.2,5.5,7.7,5.8v3.4c0,0.5,0.4,0.8,0.8,0.8h18.3c0.5,0,0.8-0.4,0.8-0.8V17.8l3.3-3.3v17.2H22.5c-0.5,0-0.8,0.4-0.8,0.8v6.7
                        c0,0.5,0.4,0.8,0.8,0.8h19.2v0.8c0,1.4-1.1,2.5-2.5,2.5H25.8V45h13.3c2.3,0,4.2-1.9,4.2-4.2V40h5.8c0.5,0,0.8-0.4,0.8-0.8v-6.7
                        c0-0.5-0.4-0.8-0.8-0.8h-5.8V12.8L49.1,7.1z M45.7,3.8c0.6-0.6,1.5-0.6,2.2,0c0.6,0.6,0.6,1.5,0,2.2c0,0,0,0,0,0l-0.4,0.4l-2.2-2.2
                        L45.7,3.8z M26.7,16.7c0,3.8-2.9,7-6.7,7.5V15h6.5C26.6,15.5,26.7,16.1,26.7,16.7z M18.3,14.2v10c-4.1-0.5-7.1-4.2-6.6-8.3
                        s4.2-7.1,8.3-6.6c2.5,0.3,4.8,1.8,5.9,4.1h-6.7C18.7,13.3,18.3,13.7,18.3,14.2z M36.7,28.3H20v-2.5c5-0.4,8.7-4.9,8.3-9.9
                        c0-0.3-0.1-0.6-0.1-0.9h4l-1.9,1.9C30.1,17,30,17.2,30,17.3l-0.8,4.2c-0.1,0.5,0.2,0.9,0.7,1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                        l4.2-0.8c0.2,0,0.3-0.1,0.4-0.2l1.9-1.9V28.3z M31.3,19.2l1.1,1.1l-1.4,0.3L31.3,19.2z M45,33.3V35h1.7v-1.7h1.7v5h-25v-5H25V35h1.7
                        v-1.7h1.7V35H30v-1.7h1.7V35h1.7v-1.7H35V35h1.7v-1.7h1.7V35H40v-1.7h1.7V35h1.7v-1.7H45z M34.2,19.7L32,17.5L44.2,5.3l2.2,2.2
                        L34.2,19.7z"
										></path>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Design</div>
							</a>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section2" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M30.6,0.5c-6,0-11.4,1.3-14.9,3.4C6.6,4.8,0,8.7,0,13.6v26.1c0,5.5,8.5,9.8,19.4,9.8c6,0,11.4-1.3,14.9-3.4
                        c9-0.9,15.6-4.8,15.6-9.6V10.3C50,4.8,41.5,0.5,30.6,0.5z M36.7,39.7c0,4.1-7.9,7.6-17.2,7.6S2.2,43.8,2.2,39.7v-4.1
                        c3.2,3.1,9.7,5.2,17.2,5.2s14-2.1,17.2-5.2V39.7z M36.7,31c0,4.1-7.9,7.6-17.2,7.6S2.2,35.1,2.2,31v-4.1c3.2,3.1,9.7,5.2,17.2,5.2
                        s14-2.1,17.2-5.2V31z M36.7,22.3c0,4.1-7.9,7.6-17.2,7.6S2.2,26.4,2.2,22.3v-4.1c3.2,3.1,9.7,5.2,17.2,5.2s14-2.1,17.2-5.2V22.3z
                        M19.4,21.2c-9.3,0-17.2-3.5-17.2-7.6S10.1,6,19.4,6s17.2,3.5,17.2,7.6S28.8,21.2,19.4,21.2z M47.8,36.4c0,3-4.3,5.7-10.3,6.9
                        c0.9-1.1,1.4-2.4,1.4-3.7v-3.1c3.8-0.9,6.9-2.4,8.9-4.3V36.4z M47.8,27.7c0,2.8-3.6,5.3-8.9,6.6v-6.5c3.8-0.9,6.9-2.4,8.9-4.3V27.7z
                        M47.8,19c0,2.8-3.6,5.3-8.9,6.6v-6.5c3.8-0.9,6.9-2.4,8.9-4.3V19z M38.9,16.9v-3.4c0-5.1-7.4-9.2-17.3-9.7c2.6-0.7,5.7-1.1,8.9-1.1
                        c9.3,0,17.2,3.5,17.2,7.6C47.8,13.1,44.1,15.6,38.9,16.9z"
										></path>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Database</div>
							</a>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section3" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M47.7,35.2h-5.9v-3.9h2c3.4,0,6.2-2.8,6.2-6.3c-0.1-2.8-2-5.2-4.6-5.9c0.2-0.7,0.3-1.6,0.3-2.4c0-5.2-4.2-9.4-9.4-9.4
                          c-1.2,0-2.5,0.2-3.6,0.7c-1.5-4.7-5.8-8-10.9-8c-6.3,0-11.4,5.1-11.4,11.5c0,0.3,0,0.6,0,0.9c0,0.1,0,0.2,0.1,0.3
                          c-0.1,0-0.2,0-0.3-0.1c-0.3,0-0.5,0-0.8,0c-5.2,0-9.4,4.2-9.4,9.4c0,4.8,3.6,8.7,8.2,9.3v4H2.3c-1.3,0-2.3,1.1-2.3,2.3v7.4
                          c0,1.3,1.1,2.3,2.3,2.3h4.5l-0.2,1.2H5.2c-0.4,0-0.8,0.3-0.8,0.8S4.8,50,5.2,50h7.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-1.4
                          l-0.2-1.2h4.5c1.3,0,2.3-1.1,2.3-2.3v-7.4c0-1.3-1.1-2.3-2.3-2.3H9.8v-3.9h30.5v3.9h-5.9c-1.3,0-2.3,1.1-2.3,2.3v7.4
                          c0,1.3,1.1,2.3,2.3,2.3h4.5l-0.2,1.2h-1.4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h7.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8
                          h-1.4l-0.2-1.2h4.5c1.3,0,2.3-1.1,2.3-2.3v-7.4C50,36.2,48.9,35.2,47.7,35.2z M9.8,48.4H8.2l0.2-1.2h1.2L9.8,48.4z M15.6,45.7H2.3
                          c-0.4,0-0.8-0.4-0.8-0.8h14.8C16.4,45.3,16,45.7,15.6,45.7z M16.4,37.5v5.9H1.6v-5.9c0-0.4,0.4-0.8,0.8-0.8h13.3
                          C16,36.7,16.4,37.1,16.4,37.5z M9.4,29.7c-4.4,0-7.8-3.4-7.8-7.8c0-4.4,3.4-7.8,7.8-7.8c0.3,0,0.4,0,0.6,0c0,0,0.1,0,0.2,0l2.5,0.6
                          L12,12.2c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.6c0-5.5,4.4-9.9,9.9-9.9c4.4,0,8.1,2.8,9.4,6.9l0.1,0.4c0,0.1,0,0.1,0,0.2
                          c0.1,0.7,0.2,2.8-0.8,3.7c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.2c1.3-1.2,1.4-3.3,1.3-4.4l0.4-0.2
                          c0.9-0.4,1.9-0.6,3-0.6c4.4,0,7.8,3.4,7.8,7.8c0,0.7-0.1,1.4-0.3,2l-0.4,1.5l1.5,0.4c2,0.6,3.3,2.3,3.4,4.5c0,2.6-2.1,4.7-4.7,4.7
                          H9.4z M34.4,36.7h13.3c0.4,0,0.8,0.4,0.8,0.8v5.9H33.6v-5.9C33.6,37.1,34,36.7,34.4,36.7z M41.8,48.4h-1.5l0.2-1.2h1.2L41.8,48.4z
                          M47.7,45.7H34.4c-0.4,0-0.8-0.4-0.8-0.8h14.8C48.4,45.3,48.1,45.7,47.7,45.7z"
											></path>
											<path
												d="M22.7,25H12.3c-1.6,0-2.9-1.4-2.9-3.1c0-1.8,1.3-3.1,2.9-3.1c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8
                          c-2.5,0-4.5,2.1-4.5,4.7c0,2.6,2,4.7,4.5,4.7h10.4c0.4,0,0.8-0.3,0.8-0.8S23.1,25,22.7,25z"
											></path>
											<circle cx="25.8" cy="25.8" r="0.8"></circle>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Cloud</div>
							</a>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section4" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<path
											class="st0"
											d="M61.8,39.4c0-4-5.9-6.1-11.8-6.1c-5.9,0-11.8,2.1-11.8,6.1v21.1c0,3.8,5.9,5.8,11.8,5.8c5.9,0,11.8-2,11.8-5.8
                        V39.4z M41.2,43.5c2.8,1.3,5.8,2,8.8,1.9c3,0.1,6.1-0.6,8.8-1.9v7.1c-0.9,1-3.9,2.3-8.8,2.3c-4.9,0-8-1.4-8.8-2.4V43.5z M50,36.4
                        c5.5,0,8.8,2,8.8,3c0,1.1-3.4,3-8.8,3s-8.8-2-8.8-3C41.2,38.3,44.5,36.4,50,36.4z M50,63.2c-5.7,0-8.8-1.9-8.8-2.7v-6.3
                        c2.8,1.2,5.8,1.9,8.8,1.8c3,0.1,6-0.5,8.8-1.7v6.2C58.8,61.3,55.7,63.2,50,63.2z M69.1,0H30.9c-4.9,0-8.8,4.1-8.8,9.1v81.8
                        c0,5,4,9.1,8.8,9.1h38.2c4.9,0,8.8-4.1,8.8-9.1V9.1C77.9,4.1,74,0,69.1,0z M75,90.9c0,3.3-2.6,6.1-5.9,6.1H30.9
                        c-3.2,0-5.9-2.7-5.9-6.1V9.1C25,5.7,27.6,3,30.9,3h38.2C72.4,3,75,5.7,75,9.1V90.9z M58.8,92.4c0,0.8-0.7,1.5-1.5,1.5H42.6
                        c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h14.7C58.2,90.9,58.8,91.6,58.8,92.4z M66.2,6.1H33.8c-3.2,0-5.9,2.7-5.9,6.1v69.7
                        c0,3.3,2.6,6.1,5.9,6.1h32.4c3.2,0,5.9-2.7,5.9-6.1V12.1C72.1,8.8,69.4,6.1,66.2,6.1z M69.1,81.8c0,1.7-1.3,3-2.9,3H33.8
                        c-1.6,0-2.9-1.4-2.9-3V12.1c0-1.7,1.3-3,2.9-3h32.4c1.6,0,2.9,1.4,2.9,3V81.8z"
										></path>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">App Technologies</div>
							</a>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section5" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													class="st0"
													d="M8.2,32l4.1,0.6c0.5,1.7,1.1,3.3,2,4.8l-2.6,3.3c-0.5,0.6-0.5,1.5,0.2,2l3.5,3.5c0.6,0.6,1.4,0.6,2,0.1
                            l3.3-2.6c1.5,0.9,3,1.5,4.8,2l0.6,4.1c0.2,0.8,0.8,1.4,1.5,1.4h4.8c0.8,0,1.4-0.6,1.5-1.4l0.6-4.1c1.7-0.5,3.3-1.1,4.8-2l3.2,2.4
                            c0.6,0.5,1.7,0.3,2.1-0.2l3.3-3.3c0.6-0.6,0.6-1.4,0.2-2l-2.6-3.3c0.9-1.5,1.5-3.2,2-4.8l4.1-0.6c0.8-0.2,1.4-0.8,1.4-1.5v-4.8
                            c0-0.8-0.6-1.4-1.4-1.5l-4.1-0.6c-0.5-1.7-1.1-3.3-2-4.9l2.4-3.3c0.5-0.6,0.5-1.5-0.2-2.1l-3.3-3.3c-0.6-0.5-1.5-0.6-2.1-0.1
                            l-3.3,2.6c-1.5-0.9-3-1.5-4.8-2l-0.5-4.2c-0.2-0.8-0.8-1.4-1.5-1.4h-4.8c-0.8,0-1.4,0.6-1.5,1.4l-0.6,4.1c-1.7,0.5-3.3,1.1-4.8,2
                            l-3.3-2.6c-0.6-0.5-1.5-0.5-2,0.2L12,13.3c-0.6,0.6-0.6,1.4-0.2,2l2.6,3.3c-0.9,1.5-1.5,3.2-2,4.9l-4.1,0.6
                            C7.6,24.2,7,24.8,7,25.6v4.8C7,31.2,7.4,31.8,8.2,32z M13.8,26.4c0.6-0.2,1.2-0.6,1.2-1.2c0.5-2.1,1.2-3.9,2.3-5.8
                            c0.3-0.6,0.3-1.2,0-1.8l-2.4-3.2l1.5-1.5l3,2.3c0.5,0.5,1.4,0.5,1.8,0c1.7-1.2,3.6-2,5.6-2.3c0.6-0.2,1.1-0.6,1.2-1.4l0.8-3.6h2.3
                            l0.5,3.9c0.2,0.6,0.6,1.2,1.2,1.4c2.1,0.5,3.9,1.2,5.6,2.4c0.6,0.3,1.2,0.3,1.8,0l3-2.4l1.5,1.5l-2.3,3c-0.5,0.5-0.5,1.2,0,1.8
                            c1.2,1.7,2,3.6,2.3,5.8c0.2,0.6,0.6,1.1,1.2,1.2l3.8,0.5v2.3l-3.8,0.5c-0.6,0.1-1.2,0.6-1.2,1.2c-0.5,2.1-1.2,3.9-2.3,5.8
                            c-0.3,0.6-0.3,1.2,0,1.8l2.4,3.2l-1.5,1.5l-3-2.4c-0.5-0.5-1.2-0.5-1.8,0c-1.7,1.2-3.6,2-5.6,2.4c-0.6,0.2-1.1,0.6-1.2,1.4
                            l-0.5,3.9h-2.4l-0.5-3.9c-0.2-0.6-0.6-1.2-1.2-1.4c-2.1-0.5-3.9-1.2-5.6-2.4c-0.6-0.3-1.2-0.3-1.8,0l-3,2.4l-1.5-1.5l2.3-3
                            c0.5-0.5,0.5-1.4,0-1.8c-1.2-1.7-2-3.6-2.3-5.6C15,30.6,14.6,30.2,14,30l-3.8-0.5v-2.7H10L13.8,26.4z M30,38.2
                            c5.6,0,10.1-4.5,10.1-10.2c0-5.6-4.5-10.2-10.1-10.2c-5.6,0-10.1,4.5-10.1,10.2C19.9,33.6,24.4,38.2,30,38.2z M30,20.9
                            c3.9,0,7.1,3.2,7.1,7.1c0,3.9-3.2,7.1-7.1,7.1S22.9,32,22.9,28C22.9,24.1,26.1,20.9,30,20.9z M39.4,82.9c0.8-1.2,1.2-2.6,1.7-3.9
                            l3.3-0.5c0.8-0.2,1.4-0.8,1.4-1.5v-4.1c0-0.8-0.6-1.4-1.4-1.5l-3.5-0.5c-0.3-1.4-0.9-2.7-1.7-3.9l2-2.7c0.5-0.6,0.5-1.5-0.2-2.1
                            l-2.9-2.9c-0.6-0.6-1.4-0.6-2-0.2l-2.7,2.1c-1.2-0.8-2.6-1.2-3.9-1.7l-0.5-3.3c-0.2-0.8-0.8-1.4-1.5-1.4h-4.1
                            c-0.8,0-1.4,0.6-1.5,1.4l-0.5,3.3c-1.4,0.3-2.7,0.9-3.8,1.7L15,59.1c-0.6-0.5-1.5-0.5-2,0.2l-2.9,2.9c-0.6,0.6-0.6,1.4-0.2,2
                            l2.1,2.7c-0.6,1.4-1.1,2.7-1.5,4.1l-3.3,0.5c-0.8,0.2-1.4,0.8-1.4,1.5V77c0,0.8,0.6,1.4,1.4,1.5l3.3,0.5c0.3,1.4,0.9,2.7,1.7,3.9
                            l-2.1,2.7c-0.5,0.6-0.5,1.5,0.2,2l2.9,2.9c0.6,0.6,1.4,0.6,2,0.1l2.7-2.1c1.2,0.8,2.6,1.2,3.9,1.7l0.5,3.3
                            c0.2,0.8,0.8,1.4,1.5,1.4h4.1c0.8,0,1.4-0.6,1.5-1.4l0.5-3.3c1.4-0.3,2.7-0.9,3.9-1.7l2.6,2c0.3,0.1,0.6,0.3,0.9,0.3
                            c0.5,0,0.9-0.2,1.1-0.5l2.9-2.9c0.6-0.6,0.6-1.4,0.2-2L39.4,82.9z M38.2,77.3c-0.3,1.7-1.1,3.3-2,4.8c-0.3,0.6-0.3,1.2,0,1.8
                            l1.8,2.4L37,87.4l-2.4-1.8c-0.5-0.5-1.2-0.5-1.8,0c-1.4,0.9-3,1.7-4.7,2c-0.6,0.2-1.1,0.6-1.2,1.4l-0.5,3.2h-1.5l-0.5-3.2
                            c-0.2-0.6-0.6-1.2-1.2-1.4c-1.7-0.3-3.3-1.1-4.7-2c-0.6-0.3-1.2-0.3-1.8,0l-2.4,1.8l-0.9-1.1l1.8-2.4c0.5-0.5,0.5-1.4,0-1.8
                            c-0.9-1.4-1.7-3-2-4.7c-0.2-0.6-0.6-1.1-1.2-1.2l-3-0.5v-1.5l3-0.5c0.6-0.2,1.2-0.6,1.2-1.2c0.3-1.7,1.1-3.3,2-4.9
                            c0.3-0.6,0.3-1.2,0-1.8l-1.8-2.4l1.1-1.1l2.4,1.8c0.5,0.5,1.4,0.5,1.8,0c1.4-0.9,3-1.7,4.7-2c0.6-0.2,1.1-0.6,1.2-1.4l0.5-3.2h1.5
                            l0.5,3.2c0.2,0.6,0.6,1.2,1.2,1.4c1.7,0.3,3.3,1.1,4.7,2c0.6,0.3,1.2,0.3,1.8,0l2.4-1.8l1.1,1.1l-1.8,2.4c-0.5,0.5-0.5,1.2,0,1.8
                            c0.9,1.5,1.7,3,2,4.9c0.2,0.6,0.6,1.1,1.2,1.2l3,0.5v1.5l-3,0.5C38.8,76.2,38.3,76.7,38.2,77.3z M25.8,66.1C20.9,66.1,17,70,17,75
                            c0,4.8,3.9,8.9,8.8,8.9c4.8,0,8.8-3.9,8.8-8.9S30.6,66.1,25.8,66.1z M25.8,80.8c-3.2,0-5.8-2.6-5.8-5.9c0-3.2,2.6-5.9,5.8-5.9
                            c3.2,0,5.8,2.6,5.8,5.9C31.5,78.2,28.9,80.8,25.8,80.8z M97.3,30.9c-2.9-2.4-7.1-2.6-10.1,0v-1.4c0-0.3-0.2-0.8-0.5-1.1L60,0.5
                            C59.7,0.1,59.2,0,58.9,0H1.5C0.6,0,0,0.6,0,1.5v97c0,0.9,0.6,1.5,1.5,1.5h84.1c0.9,0,1.5-0.6,1.5-1.5V54.7l5.9-6.8l5-5.9
                            C100.9,38.6,100.6,33.8,97.3,30.9z M60.5,5.3L82.1,28H60.5V5.3z M84.1,97H3V3h54.2v26.5c0,0.9,0.6,1.5,1.5,1.5h25.3v3.2L50.3,73.9
                            L50,74.4l-7.9,18.8c-0.3,0.6-0.2,1.4,0.5,1.7c0.5,0.5,1.2,0.5,1.8,0.2l17.3-10.6l0.3-0.3l22.1-25.8V97z M52,77.4l6.4,5.5L46.8,90
                            L52,77.4z M60.8,81.1l-7.3-6.2l28.9-33.9l7.3,6.2L60.8,81.1z M95.7,40l-4.1,4.7l-7.3-6.2l4.1-4.9c1.7-2,4.8-2.1,6.7-0.5
                            C97.3,35,97.6,38,95.7,40z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Web Technologies</div>
							</a>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section6" class="">
								<div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M44.7,34.2H24c-0.6,0-1-0.5-1-1s0.5-1,1-1h20.7c0.6,0,1,0.5,1,1S45.3,34.2,44.7,34.2z"
												></path>
											</g>
											<g>
												<path
													d="M44.7,29h-6.2c-0.6,0-1-0.5-1-1s0.5-1,1-1h6.2c0.6,0,1,0.5,1,1S45.3,29,44.7,29z"
												></path>
											</g>
											<g>
												<path
													d="M44.7,39.4H24c-0.6,0-1-0.5-1-1s0.5-1,1-1h20.7c0.6,0,1,0.5,1,1S45.3,39.4,44.7,39.4z"
												></path>
											</g>
											<g>
												<path
													d="M8.4,34.2c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.5-0.6-1V29H4.3c-2.3,0-4.1-1.9-4.1-4.1V4.1C0.1,1.9,2,0,4.3,0h27
                            c2.3,0,4.1,1.9,4.1,4.1v20.7c0,2.3-1.9,4.1-4.1,4.1H14.4l-5.2,4.9C8.9,34.1,8.7,34.2,8.4,34.2z M4.3,2.1C3.1,2.1,2.2,3,2.2,4.1
                            v20.7C2.2,26,3.1,27,4.3,27h4.1c0.6,0,1,0.5,1,1v2.8l3.8-3.5c0.2-0.2,0.4-0.3,0.7-0.3h17.3c1.1,0,2.1-0.9,2.1-2.1V4.1
                            c0-1.1-0.9-2.1-2.1-2.1H4.3z"
												></path>
											</g>
											<g>
												<path
													d="M41.6,49.8c-0.3,0-0.5-0.1-0.7-0.3l-5.2-4.9H22.9c-2.3,0-4.1-1.9-4.1-4.1V28c0-0.6,0.5-1,1-1h11.4c1.1,0,2.1-0.9,2.1-2.1
                            v-4.1c0-0.6,0.5-1,1-1h11.4c2.3,0,4.1,1.9,4.1,4.1v16.6c0,2.3-1.9,4.1-4.1,4.1h-3.1v4.1c0,0.4-0.2,0.8-0.6,1
                            C41.9,49.7,41.7,49.8,41.6,49.8z M20.9,29v11.4c0,1.1,0.9,2.1,2.1,2.1h13.1c0.3,0,0.5,0.1,0.7,0.3l3.8,3.5v-2.8c0-0.6,0.5-1,1-1
                            h4.1c1.1,0,2.1-0.9,2.1-2.1V23.8c0-1.1-0.9-2.1-2.1-2.1H35.4v3.1c0,2.3-1.9,4.1-4.1,4.1H20.9z"
												></path>
											</g>
											<g>
												<path
													d="M28.1,9.3H7.4c-0.6,0-1-0.5-1-1s0.5-1,1-1h20.7c0.6,0,1,0.5,1,1S28.7,9.3,28.1,9.3z"
												></path>
											</g>
											<g>
												<path
													d="M28.1,15.6H7.4c-0.6,0-1-0.5-1-1s0.5-1,1-1h20.7c0.6,0,1,0.5,1,1S28.7,15.6,28.1,15.6z"
												></path>
											</g>
											<g>
												<path
													d="M28.1,21.8H7.4c-0.6,0-1-0.5-1-1s0.5-1,1-1h20.7c0.6,0,1,0.5,1,1S28.7,21.8,28.1,21.8z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">
									Strategies &amp; Communication
								</div>
							</a>
						</div>
					</div>
					<div class="border-dashed"></div>
				</div>
			</div>
			<div class="what-we-offer-main-listing">
				<div class="container">
					<div class="row" id="section1">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>Design</h2>
									<p>
										From sketching prototypes to visualizing designs, we use a
										suite of design products that give shape and form to ideas.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/adobe-photoshop.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/adobe-illustrator.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/adobe-after-effects.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/sketch.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/invision.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/justmind.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/figma.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/adobe-xd.png"
											style=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row" id="section2">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>Database</h2>
									<p>
										Your data should reside in the right place where it is east
										to access and is secure too. We have chosen the right
										database platforms to host your data after long research and
										introspection.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/firebase.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/mongoDB.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/mongoDB-mysql.png"
											style=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row" id="section3">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>Cloud</h2>
									<p>
										Grow your business dynamically without burning dollars.
										Savor the benefit of scalability, flexibility and
										affordability with the power of cloud.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/amazon-web-service.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/linode.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/icloud.png"
											style=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row" id="section4">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>App Technologies</h2>
									<p>
										Building apps for the web and mobile require a whole new set
										of abilities. These technologies empower with those
										abilities.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/swift.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/objective-c.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/java.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/flutter.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/android-ar.png"
											alt=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/ar-kit.png"
											alt=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/unity.png"
											alt=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/google-vr.png"
											alt=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/ocolus.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/vive.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/windows-mixed-reality.png"
											style=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row" id="section5">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>Web Technologies</h2>
									<p>
										Your apps should keep running round-the-clock, all
										throughout the year. We bank on these server technologies
										and platforms to deliver maximum uptime for your business.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/php.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/codeigniter.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/wordpress.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/laravel.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/node-js.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/angular-js.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/html5.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/css3.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/jquery.png"
											alt=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											src="../../assets/images/bootstrap-4.png"
											alt=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row" id="section6">
						<div class="col-12">
							<div class="technology-content-main">
								<div class="title max-wid-600 text-center m-0">
									<h2>Strategies &amp; Communication</h2>
									<p>
										Communication and collaboration is the mother of
										productivity. GenixBit’s productivity is dependent on
										these tools that help us strategize, communicate and
										collaborate seamlessly.
									</p>
								</div>
								<ul class="row technology-box">
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/basecam.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/trello.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/asana.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/slack.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/bitbucket.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/skype.png"
											style=""
										/>
									</li>
									<li class="col-lg-3 col-md-4 col-sm-6 col-12">
										<img
											class="lazy"
											alt=""
											src="../../assets/images/google-hangout.png"
											style=""
										/>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nofixed"></div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.what-we-offer-main-slider-section .container {
	position: relative;
}
.what-we-offer-main-slider-section .border-dashed {
	border-top: 2px dashed #cfd1d1;
	position: absolute;
	top: 35px;
	left: 15px;
	right: 15px;
	height: 2px;
}
.what-we-offer-main-item {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	z-index: 1;
}

.what-we-offer-main-slider::before {
	left: 0;
	top: 25px;
}
.what-we-offer-main-slider::after,
.what-we-offer-main-slider::before {
	content: '';
	display: block;
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: #d2d3d4;
	border-radius: 100%;
}
.what-we-offer-main-slider::after {
	right: 0;
	top: 25px;
}
.what-we-offer-main-item-box {
	padding: 0 5px;
	width: 12.5%;
	text-align: center;
}
.what-we-offer-main-item-icon {
	width: 70px;
	height: 70px;
	background-color: #e6e7e8;
	border-radius: 100%;
	display: flex;
	align-items: center;
	margin: 0 auto 20px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.what-we-offer-main-item-title {
	color: #202122;
}
.what-we-offer-main-item-icon svg {
	margin: 0 auto;
	width: 33px;
}
.what-we-offer-main-item-box .what-we-offer-main-item-icon path {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	fill: #202122;
}
.what-we-offer-main-item-box.active .what-we-offer-main-item-icon,
.what-we-offer-main-item-box:hover .what-we-offer-main-item-icon {
	-webkit-box-shadow: 0 5px 10px 0 #1977cc;
	-moz-box-shadow: 0 5px 10px 0 #1977cc;
	box-shadow: 0 5px 10px 0 #1977cc;
	background-color: #1977cc;
}

.what-we-offer-main-item-box:hover .what-we-offer-main-item-icon path {
	fill: #fff;
}

.what-we-offer-main-listing .row {
	padding-top: 80px;
}
.what-we-offer-main-listing .row.technology-box {
	padding-top: 60px;
	position: relative;
	justify-content: center;
}
.technology-box li {
	text-align: center;
	min-height: 290px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #ecedee;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.technology-box li img {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.technology-box li:hover {
	-webkit-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
}
</style>
