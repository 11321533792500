<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Type of Services</span>
				<h2 class="fxt-process-dev">Many Industries, Many Solutions</h2>
			</div>
			<VueSlickCarousel
				@afterChange="setIndex"
				v-bind="setting"
				ref="reviewsCarousel"
				class="portfolio-slider px-2 fxt-vue-slick fxtt-vue-slick"
				style="overflow-x: hidden"
			>
				<IconImageInfo v-for="(item, index) in items" :key="index" :obj="item"></IconImageInfo>
			</VueSlickCarousel>
			<VueSlickCarousel
				v-bind="setting"
				class="portfolio-slider px-2 fxt-vue-slick"
				style="overflow-x: hidden"
			>
				<div v-for="(item, index) in items" :key="index" @click="setActiveIndex(index)">
					<IconTitleCard :obj="item"></IconTitleCard></div
			></VueSlickCarousel>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import IconTitleCard from '../AssetComponents/IconTitleCard.vue';
import IconImageInfo from '../AssetComponents/IconImageInfo.vue';
export default {
	components: {
		VueSlickCarousel,
		IconImageInfo,
		IconTitleCard
	},
	data() {
		return {
			setting: {
				slidesToShow: 1,
				autoplay: true
			},
			index_: 0,
			items: [
				{
					imageLink: '/health-and-fitness.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M71.1,14.3C68.5,7.8,62.8,3.6,55.9,2.9c-0.8-0.1-1.5-0.1-2.2-0.1c-6.7,0-13.1,3-17.3,8.3
                           C32.1,5.8,25.8,2.8,19,2.8c-0.8,0-1.5,0-2.2,0.1C9.9,3.7,4.2,7.9,1.6,14.3c-2.9,6.9-1.7,14.7,2.9,20.4l7,8.5
                           c0.4,0.3,0.8,0.4,1.2,0.4c0.3,0,0.7-0.1,0.8-0.3c0.5-0.5,0.7-1.3,0.1-1.9l-7-8.5c-4-4.9-5-11.6-2.5-17.6c2.2-5.6,7.1-9.1,13-9.8
                           c0.7,0,1.3-0.1,2-0.1c6.5,0,12.6,3.3,16.3,8.7c0.3,0.4,0.7,0.7,1.1,0.7s0.9-0.3,1.1-0.7C41,8.7,47,5.4,53.7,5.4c0.7,0,1.3,0,2,0.1
                           c5.8,0.5,10.7,4.2,13,9.8c2.5,5.9,1.5,12.7-2.5,17.6l-4.6,5.7c-0.5,0.5-0.4,1.5,0.1,1.9c0.5,0.4,1.5,0.4,1.9-0.3l4.6-5.7
                           C72.8,28.9,74,21.1,71.1,14.3z"
													></path>
													<path
														fill="#000"
														d="M49.3,55.1c-0.5-0.5-1.5-0.4-1.9,0.1L36.3,68.6L25.2,55.4c-0.5-0.5-1.3-0.7-1.9-0.1c-0.5,0.5-0.7,1.3-0.1,1.9
                           l12.2,14.5c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.1,1.1-0.5l12.2-14.5C50,56.4,49.8,55.6,49.3,55.1z"
													></path>
													<path
														fill="#000"
														d="M55.2,44.8h-35c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h35c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C56.6,45.4,55.9,44.8,55.2,44.8z M53.8,54.2H21.5v-6.6h32.2C53.8,47.6,53.8,54.2,53.8,54.2z"
													></path>
													<path
														fill="#000"
														d="M6.7,44.8h-4c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h4.1c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C8.1,45.4,7.5,44.8,6.7,44.8z M5.4,54.2H4.1v-6.6h1.3V54.2z"
													></path>
													<path
														fill="#000"
														d="M18.6,35.5h-3.6c-1.6,0-2.9,1.3-2.9,2.9v24.8c0,1.6,1.3,2.9,2.9,2.9h3.6c1.6,0,2.9-1.3,2.9-2.9V38.4
                           C21.5,36.9,20.2,35.5,18.6,35.5z M18.9,63.3c0,0.1-0.1,0.3-0.3,0.3h-3.6c-0.1,0-0.3-0.1-0.3-0.3V38.4c0-0.1,0.1-0.3,0.3-0.3h3.6
                           c0.1,0,0.3,0.1,0.3,0.3V63.3z"
													></path>
													<path
														fill="#000"
														d="M12.2,40.8H8.1c-1.5,0-2.6,1.2-2.6,2.6v14.7c0,1.5,1.2,2.6,2.6,2.6h4.1c1.5,0,2.6-1.2,2.6-2.6V43.5
                           C14.8,42,13.6,40.8,12.2,40.8z M12.2,58.1H8.1V43.5h4.1V58.1z"
													></path>
													<path
														fill="#000"
														d="M72.7,44.8h-4.1c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h4.1c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C74,45.4,73.3,44.8,72.7,44.8z M71.4,54.2H70v-6.6h1.3V54.2z"
													></path>
													<path
														fill="#000"
														d="M60.4,35.5h-3.6c-1.6,0-2.9,1.3-2.9,2.9v24.8c0,1.6,1.3,2.9,2.9,2.9h3.6c1.6,0,2.9-1.3,2.9-2.9V38.4
                           C63.3,36.9,62,35.5,60.4,35.5z M60.5,63.3c0,0.1-0.1,0.3-0.3,0.3h-3.6c-0.1,0-0.3-0.1-0.3-0.3V38.4c0-0.1,0.1-0.3,0.3-0.3h3.6
                           c0.1,0,0.3,0.1,0.3,0.3V63.3z"
													></path>
													<path
														fill="#000"
														d="M67.3,40.8h-4.1c-1.5,0-2.6,1.2-2.6,2.6v14.7c0,1.5,1.2,2.6,2.6,2.6h4.1c1.5,0,2.6-1.2,2.6-2.6V43.5
                           C69.9,42,68.7,40.8,67.3,40.8z M63.3,58.1V43.5h4.1v14.7H63.3z"
													></path>
													<path
														fill="#000"
														d="M37.7,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C39,45.4,38.5,44.8,37.7,44.8z"
													></path>
													<path
														fill="#000"
														d="M30.9,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C32.4,45.4,31.7,44.8,30.9,44.8z"
													></path>
													<path
														fill="#000"
														d="M44.4,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                           C45.7,45.4,45.2,44.8,44.4,44.8z"
													></path>
												</svg>`,
					title: 'Health and Fitness',
					descriptions: [
						'This is the era of ‘quantified self’. Users want to measure the very step that they take, monitor their heart rate, count the number of calories they burn and do much more with their smart devices.',
						'What better way to do all that than with an Apple device. Our iPhone app development services can build health and fitness apps that can turn around lifestyle for the health-conscious users.'
					],
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M71.1,14.3C68.5,7.8,62.8,3.6,55.9,2.9c-0.8-0.1-1.5-0.1-2.2-0.1c-6.7,0-13.1,3-17.3,8.3
                  C32.1,5.8,25.8,2.8,19,2.8c-0.8,0-1.5,0-2.2,0.1C9.9,3.7,4.2,7.9,1.6,14.3c-2.9,6.9-1.7,14.7,2.9,20.4l7,8.5
                  c0.4,0.3,0.8,0.4,1.2,0.4c0.3,0,0.7-0.1,0.8-0.3c0.5-0.5,0.7-1.3,0.1-1.9l-7-8.5c-4-4.9-5-11.6-2.5-17.6c2.2-5.6,7.1-9.1,13-9.8
                  c0.7,0,1.3-0.1,2-0.1c6.5,0,12.6,3.3,16.3,8.7c0.3,0.4,0.7,0.7,1.1,0.7s0.9-0.3,1.1-0.7C41,8.7,47,5.4,53.7,5.4c0.7,0,1.3,0,2,0.1
                  c5.8,0.5,10.7,4.2,13,9.8c2.5,5.9,1.5,12.7-2.5,17.6l-4.6,5.7c-0.5,0.5-0.4,1.5,0.1,1.9c0.5,0.4,1.5,0.4,1.9-0.3l4.6-5.7
                  C72.8,28.9,74,21.1,71.1,14.3z"
									></path>
									<path
										fill="#202122"
										d="M49.3,55.1c-0.5-0.5-1.5-0.4-1.9,0.1L36.3,68.6L25.2,55.4c-0.5-0.5-1.3-0.7-1.9-0.1c-0.5,0.5-0.7,1.3-0.1,1.9
                  l12.2,14.5c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.1,1.1-0.5l12.2-14.5C50,56.4,49.8,55.6,49.3,55.1z"
									></path>
									<path
										fill="#202122"
										d="M55.2,44.8h-35c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h35c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C56.6,45.4,55.9,44.8,55.2,44.8z M53.8,54.2H21.5v-6.6h32.2C53.8,47.6,53.8,54.2,53.8,54.2z"
									></path>
									<path
										fill="#202122"
										d="M6.7,44.8h-4c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h4.1c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C8.1,45.4,7.5,44.8,6.7,44.8z M5.4,54.2H4.1v-6.6h1.3V54.2z"
									></path>
									<path
										fill="#202122"
										d="M18.6,35.5h-3.6c-1.6,0-2.9,1.3-2.9,2.9v24.8c0,1.6,1.3,2.9,2.9,2.9h3.6c1.6,0,2.9-1.3,2.9-2.9V38.4
                  C21.5,36.9,20.2,35.5,18.6,35.5z M18.9,63.3c0,0.1-0.1,0.3-0.3,0.3h-3.6c-0.1,0-0.3-0.1-0.3-0.3V38.4c0-0.1,0.1-0.3,0.3-0.3h3.6
                  c0.1,0,0.3,0.1,0.3,0.3V63.3z"
									></path>
									<path
										fill="#202122"
										d="M12.2,40.8H8.1c-1.5,0-2.6,1.2-2.6,2.6v14.7c0,1.5,1.2,2.6,2.6,2.6h4.1c1.5,0,2.6-1.2,2.6-2.6V43.5
                  C14.8,42,13.6,40.8,12.2,40.8z M12.2,58.1H8.1V43.5h4.1V58.1z"
									></path>
									<path
										fill="#202122"
										d="M72.7,44.8h-4.1c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3h4.1c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C74,45.4,73.3,44.8,72.7,44.8z M71.4,54.2H70v-6.6h1.3V54.2z"
									></path>
									<path
										fill="#202122"
										d="M60.4,35.5h-3.6c-1.6,0-2.9,1.3-2.9,2.9v24.8c0,1.6,1.3,2.9,2.9,2.9h3.6c1.6,0,2.9-1.3,2.9-2.9V38.4
                  C63.3,36.9,62,35.5,60.4,35.5z M60.5,63.3c0,0.1-0.1,0.3-0.3,0.3h-3.6c-0.1,0-0.3-0.1-0.3-0.3V38.4c0-0.1,0.1-0.3,0.3-0.3h3.6
                  c0.1,0,0.3,0.1,0.3,0.3V63.3z"
									></path>
									<path
										fill="#202122"
										d="M67.3,40.8h-4.1c-1.5,0-2.6,1.2-2.6,2.6v14.7c0,1.5,1.2,2.6,2.6,2.6h4.1c1.5,0,2.6-1.2,2.6-2.6V43.5
                  C69.9,42,68.7,40.8,67.3,40.8z M63.3,58.1V43.5h4.1v14.7H63.3z"
									></path>
									<path
										fill="#202122"
										d="M37.7,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C39,45.4,38.5,44.8,37.7,44.8z"
									></path>
									<path
										fill="#202122"
										d="M30.9,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C32.4,45.4,31.7,44.8,30.9,44.8z"
									></path>
									<path
										fill="#202122"
										d="M44.4,44.8c-0.8,0-1.3,0.7-1.3,1.3v9.3c0,0.8,0.7,1.3,1.3,1.3c0.8,0,1.3-0.5,1.3-1.3v-9.3
                  C45.7,45.4,45.2,44.8,44.4,44.8z"
									></path>
								</svg>`,
					title2: 'Health & Fitness'
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M73.6,13l-8.1-6.9c-0.1-0.1-0.4-0.3-0.7-0.3H47.6l-7.9-3.5l-0.9,2.1l2.9,1.3H28.2l-5.5-5.5
                  C22.4,0,22,0,21.4,0.1c-0.4,0.1-0.7,0.5-0.7,1.1V14c0,0.7,0.4,1.2,1.2,1.2h18.8l-3.2,2.5l1.3,1.9l5.7-4.4h28.3
                  c0.4,0,0.9-0.4,1.1-0.8C74,13.8,73.9,13.3,73.6,13z M47.5,12.8l1.9-1.3L48,9.6l-4.4,3.2H23.2V4l3.7,3.7c0.3,0.3,0.4,0.4,0.8,0.4
                  h36.6l5.5,4.7H47.5z"
									></path>
									<rect x="28.9" y="9.3" fill="#202122" width="8.1" height="2.3"></rect>
									<rect x="38.2" y="9.3" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="50.8" y="9.3" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="54.3" y="9.3" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="57.8" y="9.3" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="61.4" y="9.3" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="28.9" y="71.7" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="22" y="71.7" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="41.7" y="71.7" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="48.6" y="71.7" fill="#202122" width="2.3" height="2.3"></rect>
									<path
										fill="#202122"
										d="M28.9,54.4h6.9c0.7,0,1.2-0.4,1.2-1.2v-6.9c0-0.7-0.4-1.2-1.2-1.2h-6.9c-0.7,0-1.2,0.4-1.2,1.2v6.9
                  C27.7,53.9,28.2,54.4,28.9,54.4z M30.1,47.5h4.7v4.7h-4.7V47.5z"
									></path>
									<path
										fill="#202122"
										d="M41.9,65.9c0.3,0.3,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.8-0.4l4.5-4.5c0.4-0.4,0.4-1.2,0-1.6l-4-4
                  c-0.4-0.4-1.2-0.4-1.6,0l-4.5,4.5c-0.4,0.4-0.4,1.2,0,1.6L41.9,65.9z M43.4,58.2l2.4,2.4l-2.9,2.9L40.5,61L43.4,58.2z"
									></path>
									<path
										fill="#202122"
										d="M46.2,51c2.3,0,4-1.9,4-4c0-2.3-1.9-4-4-4c-2.3,0-4,1.9-4,4C42.2,49.1,44.1,51,46.2,51z M46.2,45.1
                  c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7C44.5,45.9,45.3,45.1,46.2,45.1z"
									></path>
									<path
										fill="#202122"
										d="M45.1,24.3h2.3v-2.3h-2.3H28.9h-2.3v2.3h2.3v11.6h-5.7H22h-3.5c-0.7,0-1.2,0.4-1.2,1.2v32.3
                  c0,0.7,0.4,1.2,1.2,1.2H22h1.2h33.5V35.9H45.1V24.3z M22,68.3h-2.3V38.2H22V68.3z M31.1,24.3h11.6v11.6H31.1L31.1,24.3L31.1,24.3
                  z M54.3,38.2v30.1H24.2V38.2H54.3z"
									></path>
									<polygon
										fill="#202122"
										points="15.4,23.4 13.8,25 12.2,23.4 10.6,25 12.2,26.6 10.6,28.2 12.2,29.8 13.8,28.2 15.4,29.8 17,28.2
                  15.4,26.6 17,25         "
									></polygon>
									<rect x="5.7" y="6.9" fill="#202122" width="6.9" height="2.3"></rect>
									<rect x="9.2" y="10.4" fill="#202122" width="3.5" height="2.3"></rect>
									<rect x="5.7" y="10.4" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="0" y="10.4" fill="#202122" width="4.7" height="2.3"></rect>
									<rect x="1.1" y="6.9" fill="#202122" width="3.5" height="2.3"></rect>
									<rect x="17.3" y="10.4" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="17.3" y="6.9" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="13.8" y="10.4" fill="#202122" width="2.3" height="2.3"></rect>
									<rect x="13.8" y="6.9" fill="#202122" width="2.3" height="2.3"></rect>
									<path
										fill="#202122"
										d="M64.8,25.4c0-0.7-0.4-1.2-1.2-1.2h-3.5c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5
                  C64.3,26.6,64.8,26.2,64.8,25.4z"
									></path>
									<path
										fill="#202122"
										d="M9.2,51H5.7c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5c0.7,0,1.2-0.4,1.2-1.2
                  C10.4,51.4,9.8,51,9.2,51z"
									></path>
									<path
										fill="#202122"
										d="M68.3,51h-3.5c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5c0.7,0,1.2-0.4,1.2-1.2
                  C69.3,51.4,68.9,51,68.3,51z"
									></path>
								</svg>`,
					title2: 'Travel & Tourism',
					imageLink: '/travel-and-tourism.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M73.6,13l-8.1-6.9c-0.1-0.1-0.4-0.3-0.7-0.3H47.6l-7.9-3.5l-0.9,2.1l2.9,1.3H28.2l-5.5-5.5
                           C22.4,0,22,0,21.4,0.1c-0.4,0.1-0.7,0.5-0.7,1.1V14c0,0.7,0.4,1.2,1.2,1.2h18.8l-3.2,2.5l1.3,1.9l5.7-4.4h28.3
                           c0.4,0,0.9-0.4,1.1-0.8C74,13.8,73.9,13.3,73.6,13z M47.5,12.8l1.9-1.3L48,9.6l-4.4,3.2H23.2V4l3.7,3.7c0.3,0.3,0.4,0.4,0.8,0.4
                           h36.6l5.5,4.7H47.5z"
													></path>
													<rect x="28.9" y="9.3" fill="#000" width="8.1" height="2.3"></rect>
													<rect x="38.2" y="9.3" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="50.8" y="9.3" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="54.3" y="9.3" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="57.8" y="9.3" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="61.4" y="9.3" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="28.9" y="71.7" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="22" y="71.7" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="41.7" y="71.7" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="48.6" y="71.7" fill="#000" width="2.3" height="2.3"></rect>
													<path
														fill="#000"
														d="M28.9,54.4h6.9c0.7,0,1.2-0.4,1.2-1.2v-6.9c0-0.7-0.4-1.2-1.2-1.2h-6.9c-0.7,0-1.2,0.4-1.2,1.2v6.9
                           C27.7,53.9,28.2,54.4,28.9,54.4z M30.1,47.5h4.7v4.7h-4.7V47.5z"
													></path>
													<path
														fill="#000"
														d="M41.9,65.9c0.3,0.3,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.8-0.4l4.5-4.5c0.4-0.4,0.4-1.2,0-1.6l-4-4
                           c-0.4-0.4-1.2-0.4-1.6,0l-4.5,4.5c-0.4,0.4-0.4,1.2,0,1.6L41.9,65.9z M43.4,58.2l2.4,2.4l-2.9,2.9L40.5,61L43.4,58.2z"
													></path>
													<path
														fill="#000"
														d="M46.2,51c2.3,0,4-1.9,4-4c0-2.3-1.9-4-4-4c-2.3,0-4,1.9-4,4C42.2,49.1,44.1,51,46.2,51z M46.2,45.1
                           c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7C44.5,45.9,45.3,45.1,46.2,45.1z"
													></path>
													<path
														fill="#000"
														d="M45.1,24.3h2.3v-2.3h-2.3H28.9h-2.3v2.3h2.3v11.6h-5.7H22h-3.5c-0.7,0-1.2,0.4-1.2,1.2v32.3
                           c0,0.7,0.4,1.2,1.2,1.2H22h1.2h33.5V35.9H45.1V24.3z M22,68.3h-2.3V38.2H22V68.3z M31.1,24.3h11.6v11.6H31.1L31.1,24.3L31.1,24.3
                           z M54.3,38.2v30.1H24.2V38.2H54.3z"
													></path>
													<polygon
														fill="#000"
														points="15.4,23.4 13.8,25 12.2,23.4 10.6,25 12.2,26.6 10.6,28.2 12.2,29.8 13.8,28.2 15.4,29.8 17,28.2
                           15.4,26.6 17,25         "
													></polygon>
													<rect x="5.7" y="6.9" fill="#000" width="6.9" height="2.3"></rect>
													<rect x="9.2" y="10.4" fill="#000" width="3.5" height="2.3"></rect>
													<rect x="5.7" y="10.4" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="0" y="10.4" fill="#000" width="4.7" height="2.3"></rect>
													<rect x="1.1" y="6.9" fill="#000" width="3.5" height="2.3"></rect>
													<rect x="17.3" y="10.4" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="17.3" y="6.9" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="13.8" y="10.4" fill="#000" width="2.3" height="2.3"></rect>
													<rect x="13.8" y="6.9" fill="#000" width="2.3" height="2.3"></rect>
													<path
														fill="#000"
														d="M64.8,25.4c0-0.7-0.4-1.2-1.2-1.2h-3.5c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5
                           C64.3,26.6,64.8,26.2,64.8,25.4z"
													></path>
													<path
														fill="#000"
														d="M9.2,51H5.7c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5c0.7,0,1.2-0.4,1.2-1.2
                           C10.4,51.4,9.8,51,9.2,51z"
													></path>
													<path
														fill="#000"
														d="M68.3,51h-3.5c-0.7,0-1.2,0.4-1.2,1.2c0,0.7,0.4,1.2,1.2,1.2h3.5c0.7,0,1.2-0.4,1.2-1.2
                           C69.3,51.4,68.9,51,68.3,51z"
													></path>
												</svg>`,
					title: 'Travel and Tourism',
					descriptions: [
						'The millennial generation is ready to exchange fortunes for experiences. And they need mobile apps to help them find new travel experiences.',
						'As an iPhone app development company, we have a keen eye to build travel and tourism apps that will help users to plan better, find more places, document their travels, socialize with fellow travellers and do much more.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M68,55.1v-2.4h3.6v-9.5h2.4V36h-4.8v-9.3c0-2.8-2-5.2-4.6-5.8l-6.1-1.3v-1.7c2.1-1.5,3.6-4,3.6-6.8V8.7
                  c0-4.6-3.7-8.3-8.3-8.3H0v40.4h33.4v2.4h2.4v2.5c-3.4,0.5-6,3.4-6,7v2.4c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5c-0.9,0.3-1.7,0.8-2.3,1.5
                  c-0.5-0.7-1.3-1.2-2.3-1.5l-4.9-1.5v-2.1c1.5-1.3,2.4-3.2,2.4-5.3v-2.4c0-4-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v2.4
                  c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5c-2,0.4-3.4,2.4-3.4,4.5V74h74v-5.6c0-2.1-1.5-4-3.4-4.5l-4.9-1.5v-2.1C67.1,59,68,57.1,68,55.1z
                  M65.6,55.1c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8v-2.4c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8V55.1z M48.9,65.3
                  c-0.5-0.7-1.3-1.2-2.3-1.5l-4.9-1.5v-2.1c1.5-1.3,2.4-3.2,2.4-5.3v-2.4h9.5v2.4c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5
                  C50.3,64.1,49.5,64.6,48.9,65.3z M71.6,40.8H35.8v-2.4h35.7V40.8z M64,23.3c1.6,0.4,2.8,1.9,2.8,3.4V36H40.6v-9.3
                  c0-1.7,1.2-3.2,2.8-3.4l6.2-1.3c0.7,0.9,2,2.3,4.1,2.3s3.4-1.3,4.1-2.3L64,23.3z M56.1,20.3c-0.3,0.4-1.1,1.5-2.4,1.5
                  c-1.3,0-2.1-1.1-2.4-1.5v-1.2c0.8,0.3,1.6,0.4,2.4,0.4s1.6-0.1,2.4-0.4V20.3z M59.7,8.7v2.4c0,3.3-2.6,6-6,6s-6-2.6-6-6V8.7
                  c0-3.3,2.6-6,6-6C57,2.8,59.7,5.4,59.7,8.7z M2.3,38.4V2.8h45.5c-1.6,1.5-2.5,3.6-2.5,6v2.4c0,2.8,1.5,5.3,3.6,6.8v1.7l-6.1,1.3
                  c-2.8,0.7-4.6,3-4.6,5.8V36h-4.8v2.4H2.3z M69.2,43.2v7.1h-1.6c-0.9-2.8-3.6-4.8-6.8-4.8s-5.7,2-6.8,4.8H43.6
                  c-0.8-2.4-2.9-4.2-5.6-4.6v-2.5H69.2z M32.3,52.7c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8v2.4c0,2.6-2.1,4.8-4.8,4.8
                  c-2.6,0-4.8-2.1-4.8-4.8V52.7z M37,62.1c0.8,0,1.6-0.1,2.4-0.4V63c-0.3,0.4-1.1,1.5-2.4,1.5s-2.1-1.1-2.4-1.5v-1.3
                  C35.3,62,36.1,62.1,37,62.1z M28,66.1l4.9-1.5c0.7,0.9,2,2.1,4.1,2.1s3.4-1.3,4.1-2.1l4.9,1.5c1.1,0.3,1.7,1.2,1.7,2.3v3.3H26.2
                  v-3.3C26.2,67.4,27,66.3,28,66.1z M8.3,52.7c0-2.6,2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8v2.4c0,2.6-2.1,4.8-4.8,4.8s-4.8-2.1-4.8-4.8
                  C8.3,55.1,8.3,52.7,8.3,52.7z M13.1,62.1c0.8,0,1.6-0.1,2.4-0.4V63c-0.3,0.4-1.1,1.5-2.4,1.5s-2.1-1.1-2.4-1.5v-1.3
                  C11.5,62,12.3,62.1,13.1,62.1z M2.3,68.4c0-1.1,0.7-2,1.7-2.3L9,64.7c0.7,0.9,2,2.1,4.1,2.1s3.4-1.3,4.1-2.1l4.9,1.5
                  c1.1,0.3,1.7,1.2,1.7,2.3v3.3H2.3V68.4z M69.8,66.1c1.1,0.3,1.7,1.2,1.7,2.3v3.3H50.1v-3.3c0-1.1,0.7-2,1.7-2.3l4.9-1.5
                  c0.7,0.9,2,2.1,4.1,2.1c2.1,0,3.4-1.3,4.1-2.1L69.8,66.1z M63.2,63c-0.3,0.4-1.1,1.5-2.4,1.5c-1.3,0-2.1-1.1-2.4-1.5v-1.3
                  c0.8,0.3,1.6,0.4,2.4,0.4s1.6-0.1,2.4-0.4V63z"
									></path>
									<path fill="#202122" d="M4.7,5.2h2.4v2.4H4.7V5.2z"></path>
									<path fill="#202122" d="M9.5,5.2H19v2.4H9.5V5.2z"></path>
									<path fill="#202122" d="M4.7,9.9h2.4v2.4H4.7V9.9z"></path>
									<path fill="#202122" d="M9.5,9.9H19v2.4H9.5V9.9z"></path>
									<path
										fill="#202122"
										d="M28.5,14l-4.8,2.1c-4.9,2.1-10.2,3.3-15.5,3.3H7.1v14.3H4.7v2.4h26.2v-2.4h-2.4V14z M26.2,17.6v16h-2.4v-15
                  c0.3-0.1,0.7-0.3,0.9-0.4L26.2,17.6z M19,20.4c0.8-0.3,1.6-0.5,2.4-0.8v14H19C19,33.6,19,20.4,19,20.4z M16.6,33.6h-2.4V21.3
                  c0.8-0.1,1.6-0.3,2.4-0.4V33.6z M9.5,21.7c0.8,0,1.6-0.1,2.4-0.1v12H9.5V21.7z"
									></path>
								</svg>`,
					title2: 'Education & Learning',
					imageLink: '/education-and-learning.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M68,55.1v-2.4h3.6v-9.5h2.4V36h-4.8v-9.3c0-2.8-2-5.2-4.6-5.8l-6.1-1.3v-1.7c2.1-1.5,3.6-4,3.6-6.8V8.7
                           c0-4.6-3.7-8.3-8.3-8.3H0v40.4h33.4v2.4h2.4v2.5c-3.4,0.5-6,3.4-6,7v2.4c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5c-0.9,0.3-1.7,0.8-2.3,1.5
                           c-0.5-0.7-1.3-1.2-2.3-1.5l-4.9-1.5v-2.1c1.5-1.3,2.4-3.2,2.4-5.3v-2.4c0-4-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1v2.4
                           c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5c-2,0.4-3.4,2.4-3.4,4.5V74h74v-5.6c0-2.1-1.5-4-3.4-4.5l-4.9-1.5v-2.1C67.1,59,68,57.1,68,55.1z
                           M65.6,55.1c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8v-2.4c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8V55.1z M48.9,65.3
                           c-0.5-0.7-1.3-1.2-2.3-1.5l-4.9-1.5v-2.1c1.5-1.3,2.4-3.2,2.4-5.3v-2.4h9.5v2.4c0,2.1,0.9,4,2.4,5.3v2.1l-4.9,1.5
                           C50.3,64.1,49.5,64.6,48.9,65.3z M71.6,40.8H35.8v-2.4h35.7V40.8z M64,23.3c1.6,0.4,2.8,1.9,2.8,3.4V36H40.6v-9.3
                           c0-1.7,1.2-3.2,2.8-3.4l6.2-1.3c0.7,0.9,2,2.3,4.1,2.3s3.4-1.3,4.1-2.3L64,23.3z M56.1,20.3c-0.3,0.4-1.1,1.5-2.4,1.5
                           c-1.3,0-2.1-1.1-2.4-1.5v-1.2c0.8,0.3,1.6,0.4,2.4,0.4s1.6-0.1,2.4-0.4V20.3z M59.7,8.7v2.4c0,3.3-2.6,6-6,6s-6-2.6-6-6V8.7
                           c0-3.3,2.6-6,6-6C57,2.8,59.7,5.4,59.7,8.7z M2.3,38.4V2.8h45.5c-1.6,1.5-2.5,3.6-2.5,6v2.4c0,2.8,1.5,5.3,3.6,6.8v1.7l-6.1,1.3
                           c-2.8,0.7-4.6,3-4.6,5.8V36h-4.8v2.4H2.3z M69.2,43.2v7.1h-1.6c-0.9-2.8-3.6-4.8-6.8-4.8s-5.7,2-6.8,4.8H43.6
                           c-0.8-2.4-2.9-4.2-5.6-4.6v-2.5H69.2z M32.3,52.7c0-2.6,2.1-4.8,4.8-4.8c2.6,0,4.8,2.1,4.8,4.8v2.4c0,2.6-2.1,4.8-4.8,4.8
                           c-2.6,0-4.8-2.1-4.8-4.8V52.7z M37,62.1c0.8,0,1.6-0.1,2.4-0.4V63c-0.3,0.4-1.1,1.5-2.4,1.5s-2.1-1.1-2.4-1.5v-1.3
                           C35.3,62,36.1,62.1,37,62.1z M28,66.1l4.9-1.5c0.7,0.9,2,2.1,4.1,2.1s3.4-1.3,4.1-2.1l4.9,1.5c1.1,0.3,1.7,1.2,1.7,2.3v3.3H26.2
                           v-3.3C26.2,67.4,27,66.3,28,66.1z M8.3,52.7c0-2.6,2.1-4.8,4.8-4.8s4.8,2.1,4.8,4.8v2.4c0,2.6-2.1,4.8-4.8,4.8s-4.8-2.1-4.8-4.8
                           C8.3,55.1,8.3,52.7,8.3,52.7z M13.1,62.1c0.8,0,1.6-0.1,2.4-0.4V63c-0.3,0.4-1.1,1.5-2.4,1.5s-2.1-1.1-2.4-1.5v-1.3
                           C11.5,62,12.3,62.1,13.1,62.1z M2.3,68.4c0-1.1,0.7-2,1.7-2.3L9,64.7c0.7,0.9,2,2.1,4.1,2.1s3.4-1.3,4.1-2.1l4.9,1.5
                           c1.1,0.3,1.7,1.2,1.7,2.3v3.3H2.3V68.4z M69.8,66.1c1.1,0.3,1.7,1.2,1.7,2.3v3.3H50.1v-3.3c0-1.1,0.7-2,1.7-2.3l4.9-1.5
                           c0.7,0.9,2,2.1,4.1,2.1c2.1,0,3.4-1.3,4.1-2.1L69.8,66.1z M63.2,63c-0.3,0.4-1.1,1.5-2.4,1.5c-1.3,0-2.1-1.1-2.4-1.5v-1.3
                           c0.8,0.3,1.6,0.4,2.4,0.4s1.6-0.1,2.4-0.4V63z"
													></path>
													<path fill="#000" d="M4.7,5.2h2.4v2.4H4.7V5.2z"></path>
													<path fill="#000" d="M9.5,5.2H19v2.4H9.5V5.2z"></path>
													<path fill="#000" d="M4.7,9.9h2.4v2.4H4.7V9.9z"></path>
													<path fill="#000" d="M9.5,9.9H19v2.4H9.5V9.9z"></path>
													<path
														fill="#000"
														d="M28.5,14l-4.8,2.1c-4.9,2.1-10.2,3.3-15.5,3.3H7.1v14.3H4.7v2.4h26.2v-2.4h-2.4V14z M26.2,17.6v16h-2.4v-15
                           c0.3-0.1,0.7-0.3,0.9-0.4L26.2,17.6z M19,20.4c0.8-0.3,1.6-0.5,2.4-0.8v14H19C19,33.6,19,20.4,19,20.4z M16.6,33.6h-2.4V21.3
                           c0.8-0.1,1.6-0.3,2.4-0.4V33.6z M9.5,21.7c0.8,0,1.6-0.1,2.4-0.1v12H9.5V21.7z"
													></path>
												</svg>`,
					title: 'Education and Learning',
					descriptions: [
						'iPhones, iPads and iMacs are the new on-demand tutors. And, the best way to carry around tutors in pockets is with mobile apps. We build iPhone apps for education and learning purposes that turn children into attentive students.',
						'Our iPhone apps for education and learning come equipped with vivid animations, gamification and even paywall features to facilitate monetization.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M50.6,34.4c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H35.8c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
                  H50.6z"
									></path>
									<path
										fill="#202122"
										d="M55.5,55.4c-0.7,0-1.2,0.5-1.2,1.2v11c0,2-1.6,3.7-3.7,3.7H23.4c-2,0-3.7-1.6-3.7-3.7V48c0-0.7-0.5-1.2-1.2-1.2
                  c-0.7,0-1.2,0.5-1.2,1.2v19.7c0,3.5,2.8,6.1,6.1,6.1h27.2c3.5,0,6.1-2.8,6.1-6.1v-11C56.7,55.9,56.2,55.4,55.5,55.4z"
									></path>
									<path
										fill="#202122"
										d="M24.6,48c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h9.8c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H24.6z
                  "
									></path>
									<path
										fill="#202122"
										d="M49.4,54.1H30.9c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h18.5c0.7,0,1.2-0.5,1.2-1.2
                  C50.6,54.7,50,54.1,49.4,54.1z"
									></path>
									<path
										fill="#202122"
										d="M35.8,61.5c0-0.7-0.5-1.2-1.2-1.2h-9.8c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h9.8
                  C35.3,62.7,35.8,62.2,35.8,61.5z"
									></path>
									<path
										fill="#202122"
										d="M50.6,61.5c0-0.7-0.5-1.2-1.2-1.2h-8.7c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h8.7
                  C50,62.7,50.6,62.2,50.6,61.5z"
									></path>
									<path
										fill="#202122"
										d="M24.6,65.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2
                  H24.6z"
									></path>
									<path
										fill="#202122"
										d="M38.2,65.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2
                  H38.2z"
									></path>
									<path
										fill="#202122"
										d="M32.1,38.1v-9.8c0-3.5-2.8-6.1-6.1-6.1v-1.2c0-2-1.6-3.7-3.7-3.7c-2,0-3.7,1.6-3.7,3.7v1.2H6.1
                  c-3.5,0-6.1,2.8-6.1,6.1v9.8c0,3.5,2.8,6.1,6.1,6.1h19.7C29.3,44.2,32.1,41.6,32.1,38.1z M21,20.8c0-0.7,0.5-1.2,1.2-1.2
                  c0.7,0,1.2,0.5,1.2,1.2V22H21V20.8z M2.5,38.1v-9.8c0-2,1.6-3.7,3.7-3.7H26c2,0,3.7,1.6,3.7,3.7v9.8c0,2-1.6,3.7-3.7,3.7H6.1
                  C4.1,41.8,2.5,40.1,2.5,38.1z"
									></path>
									<path
										fill="#202122"
										d="M21,32c-0.7,0-1.2,0.5-1.2,1.2c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
                  c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-3.5,0-6.1,2.8-6.1,6.1c0,3.5,2.8,6.1,6.1,6.1c3.5,0,6.1-2.8,6.1-6.1
                  C22.2,32.5,21.7,32,21,32z"
									></path>
									<path
										fill="#202122"
										d="M7.5,38.1c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2S7.5,37.4,7.5,38.1z"
									></path>
									<path
										fill="#202122"
										d="M73.5,9.9c-0.4-0.3-0.8-0.3-1.2,0l-6,3.6H63c0-2.8-1.9-5.2-4.5-5.9c1.3-2,1.1-4.8-0.8-6.4
                  c-1.9-1.6-4.5-1.7-6.4-0.1c-1.9,1.6-2.4,4.3-1.2,6.4h-3.3C46.8,7.1,47,6.7,47,6.3c0-1.7-1.1-3.2-2.8-3.6c-1.6-0.4-3.3,0.3-4.1,1.7
                  c-0.8,1.5-0.5,3.3,0.5,4.5c-1.1,0.9-1.9,2.1-2.3,3.6h-5.1c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.3-0.4,0.7-0.3,0.9l0.7,4.9
                  c0.1,0.7,0.7,1.1,1.3,1.1c0.7-0.1,1.2-0.7,1.1-1.3l-0.4-3.6h3.6v7.5h-3.7c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h3.7
                  c0.5,2.9,3.1,4.9,6,4.9h12.4c3.5,0,6.1-2.8,6.1-6.1h3.5l5.9,3.5c0.4,0.3,0.8,0.3,1.2,0c0.4-0.3,0.7-0.7,0.7-1.1V11
                  C74,10.6,73.7,10.2,73.5,9.9z M51.8,4.8c0-1.3,1.2-2.4,2.5-2.4c1.3,0,2.5,1.1,2.5,2.5s-1.2,2.4-2.5,2.4C53,7.4,51.8,6.2,51.8,4.8z
                  M43.3,4.8c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2S42.5,4.8,43.3,4.8z M60.4,23.3c0,2-1.6,3.7-3.7,3.7H44.3
                  c-2,0-3.7-1.6-3.7-3.7v-9.8c0-2,1.6-3.7,3.7-3.7h12.4c2,0,3.7,1.6,3.7,3.7V23.3z M63,15.9h2.5v4.9H63V15.9z M71.6,23.6l-3.7-2.3v-6
                  l3.7-2.3V23.6z"
									></path>
									<path
										fill="#202122"
										d="M54.3,12.3h-7.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h7.5c0.7,0,1.2,0.5,1.2,1.2v2.5c0,0.7-0.5,1.2-1.2,1.2
                  h-7.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c2,0,3.7-1.6,3.7-3.7v-2.5C58,13.9,56.3,12.3,54.3,12.3z"
									></path>
									<path
										fill="#202122"
										d="M62.4,33.4c-0.4-0.3-0.8-0.3-1.2-0.1l-12.1,4.8h-8.4c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5V48h-7.5
                  v-2.5c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2V48c0,1.3,1.1,2.5,2.5,2.5h8.4l12.1,4.8c0.4,0.1,0.8,0.1,1.2-0.1
                  c0.4-0.3,0.5-0.7,0.5-1.1V34.4C63,34,62.7,33.6,62.4,33.4z M60.4,52.3l-9.8-4v-8.3l9.8-4V52.3z"
									></path>
									<path
										fill="#202122"
										d="M66.7,38.1c0.3,0,0.5-0.1,0.7-0.3l3.7-2.5c0.5-0.4,0.7-1.2,0.4-1.7c-0.4-0.5-1.2-0.7-1.7-0.4L66,35.7
                  c-0.4,0.3-0.7,0.8-0.5,1.3C65.6,37.7,66.1,38.1,66.7,38.1z"
									></path>
									<path
										fill="#202122"
										d="M67.3,49.4c-0.5-0.4-1.3-0.3-1.7,0.4c-0.4,0.5-0.3,1.3,0.4,1.7l3.7,2.5c0.5,0.4,1.3,0.3,1.7-0.4
                  c0.4-0.5,0.3-1.3-0.4-1.7L67.3,49.4z"
									></path>
									<path
										fill="#202122"
										d="M72.8,41.8h-4.9c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h4.9c0.7,0,1.2-0.5,1.2-1.2S73.5,41.8,72.8,41.8z"
									></path>
								</svg>`,
					title2: 'Photo & Video',
					imageLink: '/photo-and-video.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M50.6,34.4c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H35.8c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2
                           H50.6z"
													></path>
													<path
														fill="#000"
														d="M55.5,55.4c-0.7,0-1.2,0.5-1.2,1.2v11c0,2-1.6,3.7-3.7,3.7H23.4c-2,0-3.7-1.6-3.7-3.7V48c0-0.7-0.5-1.2-1.2-1.2
                           c-0.7,0-1.2,0.5-1.2,1.2v19.7c0,3.5,2.8,6.1,6.1,6.1h27.2c3.5,0,6.1-2.8,6.1-6.1v-11C56.7,55.9,56.2,55.4,55.5,55.4z"
													></path>
													<path
														fill="#000"
														d="M24.6,48c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h9.8c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H24.6z
                           "
													></path>
													<path
														fill="#000"
														d="M49.4,54.1H30.9c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h18.5c0.7,0,1.2-0.5,1.2-1.2
                           C50.6,54.7,50,54.1,49.4,54.1z"
													></path>
													<path
														fill="#000"
														d="M35.8,61.5c0-0.7-0.5-1.2-1.2-1.2h-9.8c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h9.8
                           C35.3,62.7,35.8,62.2,35.8,61.5z"
													></path>
													<path
														fill="#000"
														d="M50.6,61.5c0-0.7-0.5-1.2-1.2-1.2h-8.7c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h8.7
                           C50,62.7,50.6,62.2,50.6,61.5z"
													></path>
													<path
														fill="#000"
														d="M24.6,65.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2
                           H24.6z"
													></path>
													<path
														fill="#000"
														d="M38.2,65.3c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2
                           H38.2z"
													></path>
													<path
														fill="#000"
														d="M32.1,38.1v-9.8c0-3.5-2.8-6.1-6.1-6.1v-1.2c0-2-1.6-3.7-3.7-3.7c-2,0-3.7,1.6-3.7,3.7v1.2H6.1
                           c-3.5,0-6.1,2.8-6.1,6.1v9.8c0,3.5,2.8,6.1,6.1,6.1h19.7C29.3,44.2,32.1,41.6,32.1,38.1z M21,20.8c0-0.7,0.5-1.2,1.2-1.2
                           c0.7,0,1.2,0.5,1.2,1.2V22H21V20.8z M2.5,38.1v-9.8c0-2,1.6-3.7,3.7-3.7H26c2,0,3.7,1.6,3.7,3.7v9.8c0,2-1.6,3.7-3.7,3.7H6.1
                           C4.1,41.8,2.5,40.1,2.5,38.1z"
													></path>
													<path
														fill="#000"
														d="M21,32c-0.7,0-1.2,0.5-1.2,1.2c0,2-1.6,3.7-3.7,3.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7
                           c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2c-3.5,0-6.1,2.8-6.1,6.1c0,3.5,2.8,6.1,6.1,6.1c3.5,0,6.1-2.8,6.1-6.1
                           C22.2,32.5,21.7,32,21,32z"
													></path>
													<path
														fill="#000"
														d="M7.5,38.1c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2S7.5,37.4,7.5,38.1z"
													></path>
													<path
														fill="#000"
														d="M73.5,9.9c-0.4-0.3-0.8-0.3-1.2,0l-6,3.6H63c0-2.8-1.9-5.2-4.5-5.9c1.3-2,1.1-4.8-0.8-6.4
                           c-1.9-1.6-4.5-1.7-6.4-0.1c-1.9,1.6-2.4,4.3-1.2,6.4h-3.3C46.8,7.1,47,6.7,47,6.3c0-1.7-1.1-3.2-2.8-3.6c-1.6-0.4-3.3,0.3-4.1,1.7
                           c-0.8,1.5-0.5,3.3,0.5,4.5c-1.1,0.9-1.9,2.1-2.3,3.6h-5.1c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.3-0.4,0.7-0.3,0.9l0.7,4.9
                           c0.1,0.7,0.7,1.1,1.3,1.1c0.7-0.1,1.2-0.7,1.1-1.3l-0.4-3.6h3.6v7.5h-3.7c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h3.7
                           c0.5,2.9,3.1,4.9,6,4.9h12.4c3.5,0,6.1-2.8,6.1-6.1h3.5l5.9,3.5c0.4,0.3,0.8,0.3,1.2,0c0.4-0.3,0.7-0.7,0.7-1.1V11
                           C74,10.6,73.7,10.2,73.5,9.9z M51.8,4.8c0-1.3,1.2-2.4,2.5-2.4c1.3,0,2.5,1.1,2.5,2.5s-1.2,2.4-2.5,2.4C53,7.4,51.8,6.2,51.8,4.8z
                           M43.3,4.8c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2S42.5,4.8,43.3,4.8z M60.4,23.3c0,2-1.6,3.7-3.7,3.7H44.3
                           c-2,0-3.7-1.6-3.7-3.7v-9.8c0-2,1.6-3.7,3.7-3.7h12.4c2,0,3.7,1.6,3.7,3.7V23.3z M63,15.9h2.5v4.9H63V15.9z M71.6,23.6l-3.7-2.3v-6
                           l3.7-2.3V23.6z"
													></path>
													<path
														fill="#000"
														d="M54.3,12.3h-7.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h7.5c0.7,0,1.2,0.5,1.2,1.2v2.5c0,0.7-0.5,1.2-1.2,1.2
                           h-7.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5c2,0,3.7-1.6,3.7-3.7v-2.5C58,13.9,56.3,12.3,54.3,12.3z"
													></path>
													<path
														fill="#000"
														d="M62.4,33.4c-0.4-0.3-0.8-0.3-1.2-0.1l-12.1,4.8h-8.4c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7.5V48h-7.5
                           v-2.5c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2V48c0,1.3,1.1,2.5,2.5,2.5h8.4l12.1,4.8c0.4,0.1,0.8,0.1,1.2-0.1
                           c0.4-0.3,0.5-0.7,0.5-1.1V34.4C63,34,62.7,33.6,62.4,33.4z M60.4,52.3l-9.8-4v-8.3l9.8-4V52.3z"
													></path>
													<path
														fill="#000"
														d="M66.7,38.1c0.3,0,0.5-0.1,0.7-0.3l3.7-2.5c0.5-0.4,0.7-1.2,0.4-1.7c-0.4-0.5-1.2-0.7-1.7-0.4L66,35.7
                           c-0.4,0.3-0.7,0.8-0.5,1.3C65.6,37.7,66.1,38.1,66.7,38.1z"
													></path>
													<path
														fill="#000"
														d="M67.3,49.4c-0.5-0.4-1.3-0.3-1.7,0.4c-0.4,0.5-0.3,1.3,0.4,1.7l3.7,2.5c0.5,0.4,1.3,0.3,1.7-0.4
                           c0.4-0.5,0.3-1.3-0.4-1.7L67.3,49.4z"
													></path>
													<path
														fill="#000"
														d="M72.8,41.8h-4.9c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h4.9c0.7,0,1.2-0.5,1.2-1.2S73.5,41.8,72.8,41.8z"
													></path>
												</svg>`,
					title: 'Photo and Video',
					descriptions: [
						'From photo editing to video editing, the right iPhone can turn an average user into a resourceful media expert. As an experienced iPhone app development company, we can help you build easy-to-use and feature-rich iPhone apps for photo and video editing.',
						'Take your user’s photo and video experience to a whole new level with custom-built iPhone apps that can do everything from applying filters to cropping and fixing exposure settings automatically.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M29.3,64.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1s0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4
                  c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4S29.7,64.1,29.3,64.4z"
									></path>
									<path
										fill="#202122"
										d="M36.5,66.8h15.6c1.6,0,2.9-1.3,2.9-2.9V30.4c0-1.6-1.3-2.9-2.9-2.9H8.7c-1.6,0-2.9,1.3-2.9,2.9V64
                  c0,1.6,1.3,2.9,2.9,2.9h15.6c0.8,0,1.5-0.7,1.5-1.5S25.1,64,24.3,64H8.7V30.4h43.4V64H36.5c-0.8,0-1.5,0.7-1.5,1.5
                  C35,66.2,35.7,66.8,36.5,66.8z"
									></path>
									<path
										fill="#202122"
										d="M65.6,19.8h-25L57.8,2.6c0.5-0.5,0.5-1.5,0-2s-1.5-0.5-2,0L37,19.4L26.7,9.1c-0.5-0.5-1.5-0.5-2,0
                  c-0.5,0.5-0.5,1.5,0,2l8.7,8.7H19.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h46.5c3.1,0,5.6,2.5,5.6,5.6v37.5
                  c0,3.1-2.5,5.6-5.6,5.6H8.4c-3.1,0-5.6-2.5-5.6-5.6V28.4c0-3.1,2.5-5.6,5.6-5.6c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
                  c-4.7,0-8.4,3.9-8.4,8.5v37.5c0,4.7,3.7,8.5,8.4,8.5h57.2c4.7,0,8.4-3.9,8.4-8.5V28.4C74,23.7,70.3,19.8,65.6,19.8z"
									></path>
									<path
										fill="#202122"
										d="M13.9,22.7c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4
                  c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1C13.2,22.6,13.5,22.7,13.9,22.7z"
									></path>
									<path
										fill="#202122"
										d="M63,37.3c2.7,0,4.9-2.3,4.9-4.9c0-2.7-2.3-4.9-4.9-4.9s-4.9,2.3-4.9,4.9C58.1,35,60.4,37.3,63,37.3z M63,30.4
                  c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C61,31.2,62,30.4,63,30.4z"
									></path>
									<path
										fill="#202122"
										d="M63,50.5c2.7,0,4.9-2.3,4.9-4.9c0-2.7-2.3-4.9-4.9-4.9s-4.9,2.3-4.9,4.9C58.1,48.3,60.4,50.5,63,50.5z M63,43.4
                  c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.8-2-1.9C61,44.4,62,43.4,63,43.4z"
									></path>
									<path
										fill="#202122"
										d="M59.6,66.8h7.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                  C58.1,66.2,58.8,66.8,59.6,66.8z"
									></path>
									<path
										fill="#202122"
										d="M59.6,61.1h7.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                  C58.1,60.4,58.8,61.1,59.6,61.1z"
									></path>
									<path
										fill="#202122"
										d="M59.6,55.2h7.3c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                  C58.1,54.5,58.8,55.2,59.6,55.2z"
									></path>
									<path
										fill="#202122"
										d="M39.3,47.2c0-1.2-0.7-2.3-1.6-2.9l-9.8-5.7c-1.1-0.7-2.3-0.7-3.3,0S23,40.2,23,41.4v11.4c0,1.2,0.7,2.3,1.6,2.9
                  c0.5,0.3,1.1,0.4,1.6,0.4c0.5,0,1.2-0.1,1.6-0.4l9.8-5.7C38.6,49.5,39.3,48.4,39.3,47.2z M36.2,47.5l-9.9,5.7c-0.1,0.1-0.4,0-0.4,0
                  c-0.1,0-0.3-0.1-0.3-0.4V41.4c0-0.3,0.1-0.3,0.3-0.4c0,0,0.1,0,0.3,0c0,0,0.1,0,0.3,0l9.8,5.7c0.1,0.1,0.3,0.3,0.3,0.4
                  C36.3,47.2,36.3,47.5,36.2,47.5z"
									></path>
								</svg>`,
					title2: 'Media & Entertainment',
					imageLink: '/media-and-entertainment.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M29.3,64.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1s0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4
                           c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4S29.7,64.1,29.3,64.4z"
													></path>
													<path
														fill="#000"
														d="M36.5,66.8h15.6c1.6,0,2.9-1.3,2.9-2.9V30.4c0-1.6-1.3-2.9-2.9-2.9H8.7c-1.6,0-2.9,1.3-2.9,2.9V64
                           c0,1.6,1.3,2.9,2.9,2.9h15.6c0.8,0,1.5-0.7,1.5-1.5S25.1,64,24.3,64H8.7V30.4h43.4V64H36.5c-0.8,0-1.5,0.7-1.5,1.5
                           C35,66.2,35.7,66.8,36.5,66.8z"
													></path>
													<path
														fill="#000"
														d="M65.6,19.8h-25L57.8,2.6c0.5-0.5,0.5-1.5,0-2s-1.5-0.5-2,0L37,19.4L26.7,9.1c-0.5-0.5-1.5-0.5-2,0
                           c-0.5,0.5-0.5,1.5,0,2l8.7,8.7H19.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h46.5c3.1,0,5.6,2.5,5.6,5.6v37.5
                           c0,3.1-2.5,5.6-5.6,5.6H8.4c-3.1,0-5.6-2.5-5.6-5.6V28.4c0-3.1,2.5-5.6,5.6-5.6c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
                           c-4.7,0-8.4,3.9-8.4,8.5v37.5c0,4.7,3.7,8.5,8.4,8.5h57.2c4.7,0,8.4-3.9,8.4-8.5V28.4C74,23.7,70.3,19.8,65.6,19.8z"
													></path>
													<path
														fill="#000"
														d="M13.9,22.7c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4
                           c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1C13.2,22.6,13.5,22.7,13.9,22.7z"
													></path>
													<path
														fill="#000"
														d="M63,37.3c2.7,0,4.9-2.3,4.9-4.9c0-2.7-2.3-4.9-4.9-4.9s-4.9,2.3-4.9,4.9C58.1,35,60.4,37.3,63,37.3z M63,30.4
                           c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C61,31.2,62,30.4,63,30.4z"
													></path>
													<path
														fill="#000"
														d="M63,50.5c2.7,0,4.9-2.3,4.9-4.9c0-2.7-2.3-4.9-4.9-4.9s-4.9,2.3-4.9,4.9C58.1,48.3,60.4,50.5,63,50.5z M63,43.4
                           c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.8-2-1.9C61,44.4,62,43.4,63,43.4z"
													></path>
													<path
														fill="#000"
														d="M59.6,66.8h7.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                           C58.1,66.2,58.8,66.8,59.6,66.8z"
													></path>
													<path
														fill="#000"
														d="M59.6,61.1h7.3c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                           C58.1,60.4,58.8,61.1,59.6,61.1z"
													></path>
													<path
														fill="#000"
														d="M59.6,55.2h7.3c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-7.3c-0.8,0-1.5,0.7-1.5,1.5
                           C58.1,54.5,58.8,55.2,59.6,55.2z"
													></path>
													<path
														fill="#000"
														d="M39.3,47.2c0-1.2-0.7-2.3-1.6-2.9l-9.8-5.7c-1.1-0.7-2.3-0.7-3.3,0S23,40.2,23,41.4v11.4c0,1.2,0.7,2.3,1.6,2.9
                           c0.5,0.3,1.1,0.4,1.6,0.4c0.5,0,1.2-0.1,1.6-0.4l9.8-5.7C38.6,49.5,39.3,48.4,39.3,47.2z M36.2,47.5l-9.9,5.7c-0.1,0.1-0.4,0-0.4,0
                           c-0.1,0-0.3-0.1-0.3-0.4V41.4c0-0.3,0.1-0.3,0.3-0.4c0,0,0.1,0,0.3,0c0,0,0.1,0,0.3,0l9.8,5.7c0.1,0.1,0.3,0.3,0.3,0.4
                           C36.3,47.2,36.3,47.5,36.2,47.5z"
													></path>
												</svg>`,
					title: 'Media and Entertainment',
					descriptions: [
						'Build iPhone apps that can do many things including music streaming, video on demand, subscription-based media payback and much more. Bridge the gap between iPhone’s stellar user experience and immersive media apps with our custom iPhone app development expertise.',
						'Come to us with an app idea, greet the world with an iPhone app that will keep users hooked to the media for hours long.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M34.7,7.2h4.6c0.7,0,1.1-0.5,1.1-1.2c0-0.7-0.5-1.2-1.1-1.2h-4.6c-0.7,0-1.1,0.5-1.1,1.2
                  C33.6,6.7,34.1,7.2,34.7,7.2z M37,64.7c-1.7,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2c1.7,0,3.2-1.4,3.2-3.2
                  C40.2,66.3,38.7,64.7,37,64.7z M37,68.9c-0.5,0-0.9-0.3-0.9-0.9c0-0.5,0.3-0.9,0.9-0.9c0.6,0,0.9,0.3,0.9,0.9
                  C37.9,68.4,37.5,68.9,37,68.9z M54.3,9.7c-12.1,0-21.6,0-34.6,0c-0.7,0-1.1,0.5-1.1,1.2V28c0,15.8,0,24.4,0,35
                  c0,0.7,0.5,1.2,1.1,1.2h34.6c0.7,0,1.1-0.5,1.1-1.2V10.9C55.4,10.2,55,9.7,54.3,9.7z M42.5,12c-0.2,9.8-3.2,15.8-5.5,15.8
                  c-2.2,0-5.3-6-5.5-15.8H42.5z M40.6,37.5v5h-7.1v-5C35.3,37.5,38.9,37.5,40.6,37.5z M33.8,29.8c2.2,0.3,4,0.3,6.2,0l-0.8,5.3h-4.6
                  L33.8,29.8z M29.3,12c0.1,5.9,1.4,11.8,3.5,15.3c-6.5-1.8-11.4-8-11.9-15.3H29.3z M53.1,61.9H20.9V51c1.6-2,3.9-1.8,3.8-1.8
                  c5.5,0,5.5,8.4,12.3,8.4c7.1,0,7-8.7,12.6-8.4c1.4,0,2.6,0.7,3.5,1.8V61.9z M53.1,39.2h-3.2c-1.6,0-3-1.4-3-3c0-2.8,3-2.9,3.4-3.1
                  c0.3-0.1,0.6-0.5,0.7-0.8c0.2-1.2,1-2,2.1-2.3V39.2z M49,31c-2.7,0.7-4.3,2.5-4.3,5.1c0,2.9,2.4,5.3,5.3,5.3h3.2V48
                  c-1-0.7-2.3-1-3.5-1c-7.1-0.2-7.2,8.4-12.7,8.4c-5.5,0-5.4-8.4-12.3-8.4c-0.2,0-1.9-0.1-3.8,1v-6.5h2.9c3,0,5.4-2.4,5.4-5.4
                  c0-2.8-1.7-4.7-4.6-5.3c-0.6-1.7-1.9-3-3.8-3.5v-6.9c2.3,4.3,6.1,7.6,10.6,9.1l0.8,5.9c-0.7,0-1.1,0.5-1.1,1.2v7.3
                  c0,0.7,0.5,1.2,1.1,1.2h9.4c0.7,0,1.1-0.5,1.1-1.2v-7.3c0-0.7-0.5-1.2-1.1-1.2l0.8-5.9c4.6-1.5,8.3-4.9,10.6-9.1v7.2h0.2
                  C51.3,27.9,49.7,29.1,49,31z M20.9,39.2v-9.6c2.3,1.2,1,3,2.7,3.2c4.9,0.6,3.7,6.4,0.1,6.4H20.9z M41.3,27.3
                  c2.2-3.5,3.3-9.4,3.5-15.3h8.3C52.7,19.3,47.8,25.4,41.3,27.3z M72,47.2c-0.5-0.3-1.3-0.3-1.6,0.2c-3.7,3.9-5,8.8-9.5,8V41.5H65
                  c6.2,0,7.2-9.1,1.1-10.5c-0.1-1.4-0.8-2.7-1.9-3.6c-0.9-0.8-2.2-1.2-3.3-1.2V3.5c0-2-1.6-3.5-3.4-3.5H16.6c-1.9,0-3.4,1.6-3.4,3.5
                  v22.3c-3.1-0.5-5.9,1.6-6.3,4.7C0.6,31.8,1.4,41.4,8,41.4h5v13.9c-4.5,0.8-5.8-4-9.5-8c-0.3-0.5-1.1-0.6-1.6-0.2
                  c-0.5,0.3-0.6,1.2-0.2,1.6c3.7,3.9,5.4,9.6,11.2,8.9v13c0,2,1.6,3.5,3.4,3.5h41c1.9,0,3.4-1.6,3.4-3.5V57.7
                  c5.9,0.7,7.5-5,11.3-8.9C72.7,48.3,72.6,47.6,72,47.2z M61,28.7c0.6,0,1.3,0.2,1.8,0.6c0.8,0.6,1.1,1.6,1.1,2.7
                  c0,0.7,0.5,1.3,1.1,1.3c3.9,0,3.9,6,0,6H61V28.7z M13,39.2H7.9c-4.1,0-4.1-6.4,0-6.4c0.3,0,0.6-0.1,0.8-0.3
                  C8.9,32.3,9,31.9,9,31.6c-0.2-2.3,1.9-3.9,4-3.4V39.2z M58.7,70.4c0,0.7-0.6,1.3-1.3,1.3H16.6c-0.7,0-1.1-0.6-1.1-1.2V3.5
                  c0-0.7,0.6-1.2,1.1-1.2h41c0.7,0,1.1,0.6,1.1,1.2C58.7,16.3,58.7,55.2,58.7,70.4z M69.1,6.5c0.7,0,1.1-0.5,1.1-1.2
                  c0-1.5-2.3-1.5-2.3,0C68,5.9,68.5,6.5,69.1,6.5z M66.9,12.3c0.5,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                  c-0.6-0.2-1.3,0.1-1.5,0.7C65.9,11.4,66.3,12.1,66.9,12.3z M65.9,15.3c0.5,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                  c-0.6-0.2-1.3,0.1-1.5,0.7C65,14.5,65.3,15,65.9,15.3z M67.9,9.4c0.7,0.2,1.3-0.2,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                  c-0.6-0.2-1.3,0.1-1.5,0.7C66.9,8.6,67.2,9.1,67.9,9.4z M65.3,18.3c1,0,1.5-1.2,0.8-2c-0.7-0.7-1.9-0.2-1.9,0.8
                  C64.1,17.8,64.7,18.3,65.3,18.3z M4.6,6.5c0.7,0,1.1-0.6,1.1-1.2c0-1-1.3-1.5-1.9-0.8C3.5,4.7,3.4,5,3.4,5.3
                  C3.4,5.9,4,6.5,4.6,6.5z M6.2,10.1c-0.6,0.2-0.9,0.9-0.7,1.5c0.1,0.5,0.6,0.8,1,0.8c0.8,0,1.4-0.8,1.1-1.5
                  C7.4,10.3,6.7,9.9,6.2,10.1z M5.3,7.2C4,7.5,4.3,9.5,5.6,9.5c0.8,0,1.4-0.8,1-1.5C6.5,7.3,5.8,6.9,5.3,7.2z M7.1,13.1
                  c-0.6,0.2-0.9,0.8-0.7,1.5c0.1,0.5,0.6,0.8,1,0.8c0.8,0,1.4-0.8,1.1-1.5C8.3,13.2,7.8,12.8,7.1,13.1z M7.7,16.3
                  c-0.2,0.2-0.3,0.6-0.3,0.8c0,0.6,0.5,1.2,1.1,1.2c0.6,0,1.1-0.5,1.1-1.2C9.6,16.1,8.3,15.6,7.7,16.3z"
									></path>
								</svg>`,
					title2: 'Augmented Reality',
					imageLink: '/augmented-reality.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M34.7,7.2h4.6c0.7,0,1.1-0.5,1.1-1.2c0-0.7-0.5-1.2-1.1-1.2h-4.6c-0.7,0-1.1,0.5-1.1,1.2
                           C33.6,6.7,34.1,7.2,34.7,7.2z M37,64.7c-1.7,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2c1.7,0,3.2-1.4,3.2-3.2
                           C40.2,66.3,38.7,64.7,37,64.7z M37,68.9c-0.5,0-0.9-0.3-0.9-0.9c0-0.5,0.3-0.9,0.9-0.9c0.6,0,0.9,0.3,0.9,0.9
                           C37.9,68.4,37.5,68.9,37,68.9z M54.3,9.7c-12.1,0-21.6,0-34.6,0c-0.7,0-1.1,0.5-1.1,1.2V28c0,15.8,0,24.4,0,35
                           c0,0.7,0.5,1.2,1.1,1.2h34.6c0.7,0,1.1-0.5,1.1-1.2V10.9C55.4,10.2,55,9.7,54.3,9.7z M42.5,12c-0.2,9.8-3.2,15.8-5.5,15.8
                           c-2.2,0-5.3-6-5.5-15.8H42.5z M40.6,37.5v5h-7.1v-5C35.3,37.5,38.9,37.5,40.6,37.5z M33.8,29.8c2.2,0.3,4,0.3,6.2,0l-0.8,5.3h-4.6
                           L33.8,29.8z M29.3,12c0.1,5.9,1.4,11.8,3.5,15.3c-6.5-1.8-11.4-8-11.9-15.3H29.3z M53.1,61.9H20.9V51c1.6-2,3.9-1.8,3.8-1.8
                           c5.5,0,5.5,8.4,12.3,8.4c7.1,0,7-8.7,12.6-8.4c1.4,0,2.6,0.7,3.5,1.8V61.9z M53.1,39.2h-3.2c-1.6,0-3-1.4-3-3c0-2.8,3-2.9,3.4-3.1
                           c0.3-0.1,0.6-0.5,0.7-0.8c0.2-1.2,1-2,2.1-2.3V39.2z M49,31c-2.7,0.7-4.3,2.5-4.3,5.1c0,2.9,2.4,5.3,5.3,5.3h3.2V48
                           c-1-0.7-2.3-1-3.5-1c-7.1-0.2-7.2,8.4-12.7,8.4c-5.5,0-5.4-8.4-12.3-8.4c-0.2,0-1.9-0.1-3.8,1v-6.5h2.9c3,0,5.4-2.4,5.4-5.4
                           c0-2.8-1.7-4.7-4.6-5.3c-0.6-1.7-1.9-3-3.8-3.5v-6.9c2.3,4.3,6.1,7.6,10.6,9.1l0.8,5.9c-0.7,0-1.1,0.5-1.1,1.2v7.3
                           c0,0.7,0.5,1.2,1.1,1.2h9.4c0.7,0,1.1-0.5,1.1-1.2v-7.3c0-0.7-0.5-1.2-1.1-1.2l0.8-5.9c4.6-1.5,8.3-4.9,10.6-9.1v7.2h0.2
                           C51.3,27.9,49.7,29.1,49,31z M20.9,39.2v-9.6c2.3,1.2,1,3,2.7,3.2c4.9,0.6,3.7,6.4,0.1,6.4H20.9z M41.3,27.3
                           c2.2-3.5,3.3-9.4,3.5-15.3h8.3C52.7,19.3,47.8,25.4,41.3,27.3z M72,47.2c-0.5-0.3-1.3-0.3-1.6,0.2c-3.7,3.9-5,8.8-9.5,8V41.5H65
                           c6.2,0,7.2-9.1,1.1-10.5c-0.1-1.4-0.8-2.7-1.9-3.6c-0.9-0.8-2.2-1.2-3.3-1.2V3.5c0-2-1.6-3.5-3.4-3.5H16.6c-1.9,0-3.4,1.6-3.4,3.5
                           v22.3c-3.1-0.5-5.9,1.6-6.3,4.7C0.6,31.8,1.4,41.4,8,41.4h5v13.9c-4.5,0.8-5.8-4-9.5-8c-0.3-0.5-1.1-0.6-1.6-0.2
                           c-0.5,0.3-0.6,1.2-0.2,1.6c3.7,3.9,5.4,9.6,11.2,8.9v13c0,2,1.6,3.5,3.4,3.5h41c1.9,0,3.4-1.6,3.4-3.5V57.7
                           c5.9,0.7,7.5-5,11.3-8.9C72.7,48.3,72.6,47.6,72,47.2z M61,28.7c0.6,0,1.3,0.2,1.8,0.6c0.8,0.6,1.1,1.6,1.1,2.7
                           c0,0.7,0.5,1.3,1.1,1.3c3.9,0,3.9,6,0,6H61V28.7z M13,39.2H7.9c-4.1,0-4.1-6.4,0-6.4c0.3,0,0.6-0.1,0.8-0.3
                           C8.9,32.3,9,31.9,9,31.6c-0.2-2.3,1.9-3.9,4-3.4V39.2z M58.7,70.4c0,0.7-0.6,1.3-1.3,1.3H16.6c-0.7,0-1.1-0.6-1.1-1.2V3.5
                           c0-0.7,0.6-1.2,1.1-1.2h41c0.7,0,1.1,0.6,1.1,1.2C58.7,16.3,58.7,55.2,58.7,70.4z M69.1,6.5c0.7,0,1.1-0.5,1.1-1.2
                           c0-1.5-2.3-1.5-2.3,0C68,5.9,68.5,6.5,69.1,6.5z M66.9,12.3c0.5,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                           c-0.6-0.2-1.3,0.1-1.5,0.7C65.9,11.4,66.3,12.1,66.9,12.3z M65.9,15.3c0.5,0.2,1.3-0.1,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                           c-0.6-0.2-1.3,0.1-1.5,0.7C65,14.5,65.3,15,65.9,15.3z M67.9,9.4c0.7,0.2,1.3-0.2,1.5-0.7c0.2-0.6-0.1-1.3-0.7-1.5
                           c-0.6-0.2-1.3,0.1-1.5,0.7C66.9,8.6,67.2,9.1,67.9,9.4z M65.3,18.3c1,0,1.5-1.2,0.8-2c-0.7-0.7-1.9-0.2-1.9,0.8
                           C64.1,17.8,64.7,18.3,65.3,18.3z M4.6,6.5c0.7,0,1.1-0.6,1.1-1.2c0-1-1.3-1.5-1.9-0.8C3.5,4.7,3.4,5,3.4,5.3
                           C3.4,5.9,4,6.5,4.6,6.5z M6.2,10.1c-0.6,0.2-0.9,0.9-0.7,1.5c0.1,0.5,0.6,0.8,1,0.8c0.8,0,1.4-0.8,1.1-1.5
                           {imageLink:" M5.3,7.2C4,7.5,4.3,9.5,5.6,9.5c0.8,0,1.4-0.8,1-1.5C6.5,7.3,5.8,6.9,5.3,7.2z M7.1,13.1,c-0.2,0.2-0.3,0.6-0.3,0.8c0,0.6,0.5,1.2,1.1,1.2c0.6,0,1.1-0.5,1.1-1.2C9.6,16.1,8.3,15.6,7.7,16.3z"
													></path>
												</svg>`,
					title: 'Augmented Reality',
					descriptions: [
						'From manufacturing to education, Augmented Reality is getting prepped to move and shake things in all places where digital experiences can be used. Be there before everyone else with our iPhone app development expertise in Augmented Reality.',
						'We are geared up to weave magic in all forms of AR apps for Apple devices. From enterprise to entertainment, we have you covered in all corners.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M52.6,33.3c0.6-0.8,1-1.6,1.5-2.4c1.9-0.6,3.2-2.3,3.2-4.4V12.9c0-2.5-2.1-4.6-4.6-4.6h-0.1
                  C48.9,3.1,43.3,0,37.1,0c-6.3,0-11.9,3-15.6,8.3h-0.1c-2.5,0-4.6,2.1-4.6,4.6v13.4c0,2.1,1.4,3.8,3.2,4.4c0.5,0.8,0.9,1.7,1.5,2.4
                  C9.3,38.2,1.3,49.9,1.3,63v9.8c0,0.7,0.5,1.2,1.2,1.2c-0.1,0,50.5,0,69.1,0c0.7,0,1.2-0.5,1.2-1.2V63
                  C72.6,49.9,64.6,38.2,52.6,33.3z M36.9,2.3c5,0,9.5,2.2,12.7,6H24.2C27.4,4.5,32,2.3,36.9,2.3z M21,28.7c-1,0.1-2.1-0.9-2.1-2.2
                  V12.9c0-1.3,1-2.3,2.3-2.3h31.5c1.3,0,2.3,1,2.3,2.3v13.4c0,1.3-1,2.3-2.3,2.3H42.5c-0.5,0-0.8-0.3-0.8-0.9c0-1.7-1.4-3.2-3.1-3.2
                  h-3.5c-1.7,0-3.1,1.4-3.1,3.2c0,0.5-0.3,0.9-0.8,0.9H21z M31.8,40.8c3.4,0.9,6.8,0.9,10.2,0l-5.1,5.1L31.8,40.8z M34.9,47.2
                  l-10.5,4.6V36.7C25.2,37.5,29.8,42,34.9,47.2z M49.6,36.7v15.1L39,47.2C45.2,41,48.3,37.8,49.6,36.7z M22.6,31.1h8.7
                  c1.7,0,3.1-1.4,3.1-3.2c0-0.5,0.3-0.9,0.8-0.9h3.5c0.5,0,0.8,0.3,0.8,0.9c0,1.7,1.4,3.2,3.1,3.2h8.7C44.5,42,29.5,42,22.6,31.1z
                  M70.4,71.7h-0.1H60.1v-3.5c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v3.5H38.1V71c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v0.7H16.1
                  v-3.5c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v3.5H3.6V63c0-12.1,7.4-23,18.5-27.5v18c0,0.3,0.2,0.8,0.6,0.9
                  c0.3,0.2,0.8,0.2,1,0.1l13.2-5.8l13.3,5.9c0.3,0.1,0.8,0.1,1-0.1c0.3-0.2,0.6-0.6,0.6-0.9V35.5C63,40,70.4,50.9,70.4,63V71.7z
                  M36.9,54.9c-0.7,0-1.2,0.5-1.2,1.2v2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2C38.1,55.4,37.6,54.9,36.9,54.9z M36.9,62.3
                  c-0.7,0-1.2,0.5-1.2,1.2v2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2C38.1,62.9,37.6,62.3,36.9,62.3z M26.8,14
                  c-0.6-0.3-1.3-0.1-1.6,0.5l-3.7,7.1c-0.3,0.6-0.1,1.3,0.5,1.6c0.6,0.3,1.3,0.1,1.6-0.5l3.7-7.1C27.6,15,27.3,14.3,26.8,14z
                  M30.7,18.6c-0.6-0.3-1.3-0.1-1.6,0.5l-2.5,4.9c-0.2,0.6-0.1,1.3,0.5,1.5c0.6,0.3,1.3,0.1,1.6-0.5l2.5-4.7
                  C31.5,19.7,31.3,19,30.7,18.6z M43.3,23.1c0.6,0.3,1.3,0.1,1.6-0.5l3.7-7.1c0.3-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.3-0.1-1.6,0.5
                  l-3.7,7.1C42.5,22.1,42.7,22.8,43.3,23.1z M48.4,25.4c0.6,0.3,1.3,0.1,1.6-0.5l2.5-4.7c0.3-0.6,0.1-1.3-0.5-1.6
                  c-0.6-0.3-1.3-0.1-1.6,0.5l-2.5,4.7C47.7,24.5,47.9,25.2,48.4,25.4z"
									></path>
								</svg>`,
					title2: 'Virtual Reality',
					imageLink: '/virtual-reality.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M52.6,33.3c0.6-0.8,1-1.6,1.5-2.4c1.9-0.6,3.2-2.3,3.2-4.4V12.9c0-2.5-2.1-4.6-4.6-4.6h-0.1
                           C48.9,3.1,43.3,0,37.1,0c-6.3,0-11.9,3-15.6,8.3h-0.1c-2.5,0-4.6,2.1-4.6,4.6v13.4c0,2.1,1.4,3.8,3.2,4.4c0.5,0.8,0.9,1.7,1.5,2.4
                           C9.3,38.2,1.3,49.9,1.3,63v9.8c0,0.7,0.5,1.2,1.2,1.2c-0.1,0,50.5,0,69.1,0c0.7,0,1.2-0.5,1.2-1.2V63
                           C72.6,49.9,64.6,38.2,52.6,33.3z M36.9,2.3c5,0,9.5,2.2,12.7,6H24.2C27.4,4.5,32,2.3,36.9,2.3z M21,28.7c-1,0.1-2.1-0.9-2.1-2.2
                           V12.9c0-1.3,1-2.3,2.3-2.3h31.5c1.3,0,2.3,1,2.3,2.3v13.4c0,1.3-1,2.3-2.3,2.3H42.5c-0.5,0-0.8-0.3-0.8-0.9c0-1.7-1.4-3.2-3.1-3.2
                           h-3.5c-1.7,0-3.1,1.4-3.1,3.2c0,0.5-0.3,0.9-0.8,0.9H21z M31.8,40.8c3.4,0.9,6.8,0.9,10.2,0l-5.1,5.1L31.8,40.8z M34.9,47.2
                           l-10.5,4.6V36.7C25.2,37.5,29.8,42,34.9,47.2z M49.6,36.7v15.1L39,47.2C45.2,41,48.3,37.8,49.6,36.7z M22.6,31.1h8.7
                           c1.7,0,3.1-1.4,3.1-3.2c0-0.5,0.3-0.9,0.8-0.9h3.5c0.5,0,0.8,0.3,0.8,0.9c0,1.7,1.4,3.2,3.1,3.2h8.7C44.5,42,29.5,42,22.6,31.1z
                           M70.4,71.7h-0.1H60.1v-3.5c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v3.5H38.1V71c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v0.7H16.1
                           v-3.5c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v3.5H3.6V63c0-12.1,7.4-23,18.5-27.5v18c0,0.3,0.2,0.8,0.6,0.9
                           c0.3,0.2,0.8,0.2,1,0.1l13.2-5.8l13.3,5.9c0.3,0.1,0.8,0.1,1-0.1c0.3-0.2,0.6-0.6,0.6-0.9V35.5C63,40,70.4,50.9,70.4,63V71.7z
                           M36.9,54.9c-0.7,0-1.2,0.5-1.2,1.2v2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2C38.1,55.4,37.6,54.9,36.9,54.9z M36.9,62.3
                           c-0.7,0-1.2,0.5-1.2,1.2v2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2v-2C38.1,62.9,37.6,62.3,36.9,62.3z M26.8,14
                           c-0.6-0.3-1.3-0.1-1.6,0.5l-3.7,7.1c-0.3,0.6-0.1,1.3,0.5,1.6c0.6,0.3,1.3,0.1,1.6-0.5l3.7-7.1C27.6,15,27.3,14.3,26.8,14z
                           M30.7,18.6c-0.6-0.3-1.3-0.1-1.6,0.5l-2.5,4.9c-0.2,0.6-0.1,1.3,0.5,1.5c0.6,0.3,1.3,0.1,1.6-0.5l2.5-4.7
                           {imageLink:"6z M43.3,23.1c0.6,0.3,1.3,0.1,1.6-0.5l3.7-7.1c0.3-0.6,0.1-1.3-0.5-1.6c-0.6-0.3-1.3-0.1-1.6,0.5,c-0.6-0.3-1.3-0.1-1.6,0.5l-2.5,4.7C47.7,24.5,47.9,25.2,48.4,25.4z"
													></path>
												</svg>`,
					title: 'Virtual Reality',
					descriptions: [
						'There are immersive experiences that your users can enjoy beyond the screen borders of an iPhone. Deliver that with the same panache of Apple user experience with well-thought virtual reality apps for iPhones.',
						'Come with an idea and we will turn it into a viable project with our app development expertise.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M33.6,4H6.3C2.8,4,0,6.7,0,10c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v39c0,0.7,0.6,1.3,1.3,1.3
                  c0.7,0,1.3-0.6,1.3-1.3v-39c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-1.9,1.6-3.4,3.6-3.4h27.4c2,0,3.6,1.5,3.6,3.4v2.6H8.1
                  c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h29.1V64c0,1.9-1.6,3.4-3.6,3.4H6.3c-2,0-3.6-1.5-3.6-3.4V57h29.1
                  c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3H1.3c-0.7,0-1.3,0.6-1.3,1.3V64c0,3.3,2.8,6,6.3,6h27.4c3.5,0,6.3-2.7,6.3-6V10
                  C39.9,6.7,37.1,4,33.6,4z M17.8,60.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h4c0.7,0,1.3-0.6,1.3-1.3
                  c0-0.7-0.6-1.3-1.3-1.3H17.8z M58.7,18.5c-0.1-0.4-0.4-0.7-0.8-0.9l-7.5-3.1c-0.4-0.2-0.8-0.1-1.2,0.1c-0.4,0.2-0.6,0.6-0.6,1
                  c-0.2,1.6-1.5,2.8-3.2,2.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c2.4,0,4.6-1.4,5.4-3.5l4.7,1.9c-0.9,2.1-0.4,4.6,1.3,6.3
                  c1.7,1.7,4.3,2.1,6.4,1.3l1.9,4.6c-2.1,0.9-3.6,2.9-3.6,5.3c0,2.4,1.5,4.5,3.6,5.3l-1.9,4.6c-2.2-0.9-4.7-0.4-6.4,1.3
                  c-1.7,1.7-2.2,4.2-1.3,6.3l-4.7,1.9c-0.9-2.1-3-3.5-5.4-3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.7,0,3,1.2,3.2,2.8
                  c0,0.4,0.3,0.8,0.6,1c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.3,0,0.5-0.1l7.5-3.1c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.4,0-0.8-0.3-1.2
                  c-1-1.3-0.9-3.1,0.2-4.2c1.2-1.1,3-1.2,4.3-0.2c0.3,0.3,0.8,0.4,1.2,0.3c0.4-0.1,0.8-0.4,0.9-0.8l3.1-7.4c0.2-0.4,0.1-0.8-0.1-1.2
                  c-0.2-0.4-0.6-0.6-1-0.6c-1.6-0.2-2.9-1.5-2.9-3.1c0-1.6,1.2-3,2.9-3.1c0.4,0,0.8-0.3,1-0.6c0.2-0.4,0.3-0.8,0.1-1.2L65,24.7
                  c-0.2-0.4-0.5-0.7-0.9-0.8c-0.4-0.1-0.8,0-1.2,0.3c-1.3,1-3.1,0.9-4.3-0.2c-1.2-1.1-1.3-3-0.2-4.2C58.7,19.4,58.8,18.9,58.7,18.5z
                  M45.3,45.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c6.4,0,11.6-5.1,11.6-11.4c0-6.3-5.2-11.4-11.6-11.4c-0.7,0-1.3,0.6-1.3,1.3
                  c0,0.7,0.6,1.3,1.3,1.3c4.9,0,8.9,3.9,8.9,8.8C54.2,41.8,50.2,45.8,45.3,45.8z M45.3,8.8c-0.7,0-1.3,0.6-1.3,1.3
                  c0,0.7,0.6,1.3,1.3,1.3c14.3,0,26,11.4,26,25.5s-11.7,25.5-26,25.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                  C61.1,65.2,74,52.5,74,37C74,21.5,61.1,8.8,45.3,8.8z M5.2,45.9c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3
                  H8.2c-1.7,0-3.1,1.4-3.1,3V45.9z M7.9,44c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                  c-0.2,0-0.4-0.2-0.4-0.4V44z M15.8,45.9c0,1.7,1.4,3,3.1,3H21c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3h-2.1
                  c-1.7,0-3.1,1.4-3.1,3V45.9z M18.5,44c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V44z M26.5,45.9c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3h-2.1
                  c-1.7,0-3.1,1.4-3.1,3V45.9z M29.2,44c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V44z M5.2,35.7c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3H8.2
                  c-1.7,0-3.1,1.4-3.1,3V35.7z M7.9,33.8c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                  c-0.2,0-0.4-0.2-0.4-0.4V33.8z M15.8,35.7c0,1.7,1.4,3,3.1,3H21c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1
                  c-1.7,0-3.1,1.4-3.1,3V35.7z M18.5,33.8c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V33.8z M26.5,35.7c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1
                  c-1.7,0-3.1,1.4-3.1,3V35.7z M29.2,33.8c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V33.8z M10.3,28.6c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3H8.2c-1.7,0-3.1,1.4-3.1,3v1.9
                  c0,1.7,1.4,3,3.1,3H10.3z M7.9,25.6v-1.9c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                  C8,25.9,7.9,25.8,7.9,25.6z M18.9,28.6H21c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1c-1.7,0-3.1,1.4-3.1,3v1.9
                  C15.8,27.2,17.2,28.6,18.9,28.6z M18.5,23.6c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V23.6z M29.6,28.6h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1c-1.7,0-3.1,1.4-3.1,3v1.9
                  C26.5,27.2,27.9,28.6,29.6,28.6z M29.2,23.6c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                  c-0.2,0-0.4-0.2-0.4-0.4V23.6z"
									></path>
								</svg>`,
					title2: 'On Demand Business',
					imageLink: '/on-demand-business.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M33.6,4H6.3C2.8,4,0,6.7,0,10c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v39c0,0.7,0.6,1.3,1.3,1.3
                           c0.7,0,1.3-0.6,1.3-1.3v-39c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-1.9,1.6-3.4,3.6-3.4h27.4c2,0,3.6,1.5,3.6,3.4v2.6H8.1
                           c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h29.1V64c0,1.9-1.6,3.4-3.6,3.4H6.3c-2,0-3.6-1.5-3.6-3.4V57h29.1
                           c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3H1.3c-0.7,0-1.3,0.6-1.3,1.3V64c0,3.3,2.8,6,6.3,6h27.4c3.5,0,6.3-2.7,6.3-6V10
                           C39.9,6.7,37.1,4,33.6,4z M17.8,60.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h4c0.7,0,1.3-0.6,1.3-1.3
                           c0-0.7-0.6-1.3-1.3-1.3H17.8z M58.7,18.5c-0.1-0.4-0.4-0.7-0.8-0.9l-7.5-3.1c-0.4-0.2-0.8-0.1-1.2,0.1c-0.4,0.2-0.6,0.6-0.6,1
                           c-0.2,1.6-1.5,2.8-3.2,2.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c2.4,0,4.6-1.4,5.4-3.5l4.7,1.9c-0.9,2.1-0.4,4.6,1.3,6.3
                           c1.7,1.7,4.3,2.1,6.4,1.3l1.9,4.6c-2.1,0.9-3.6,2.9-3.6,5.3c0,2.4,1.5,4.5,3.6,5.3l-1.9,4.6c-2.2-0.9-4.7-0.4-6.4,1.3
                           c-1.7,1.7-2.2,4.2-1.3,6.3l-4.7,1.9c-0.9-2.1-3-3.5-5.4-3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.7,0,3,1.2,3.2,2.8
                           c0,0.4,0.3,0.8,0.6,1c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.3,0,0.5-0.1l7.5-3.1c0.4-0.2,0.7-0.5,0.8-0.9c0.1-0.4,0-0.8-0.3-1.2
                           c-1-1.3-0.9-3.1,0.2-4.2c1.2-1.1,3-1.2,4.3-0.2c0.3,0.3,0.8,0.4,1.2,0.3c0.4-0.1,0.8-0.4,0.9-0.8l3.1-7.4c0.2-0.4,0.1-0.8-0.1-1.2
                           c-0.2-0.4-0.6-0.6-1-0.6c-1.6-0.2-2.9-1.5-2.9-3.1c0-1.6,1.2-3,2.9-3.1c0.4,0,0.8-0.3,1-0.6c0.2-0.4,0.3-0.8,0.1-1.2L65,24.7
                           c-0.2-0.4-0.5-0.7-0.9-0.8c-0.4-0.1-0.8,0-1.2,0.3c-1.3,1-3.1,0.9-4.3-0.2c-1.2-1.1-1.3-3-0.2-4.2C58.7,19.4,58.8,18.9,58.7,18.5z
                           M45.3,45.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c6.4,0,11.6-5.1,11.6-11.4c0-6.3-5.2-11.4-11.6-11.4c-0.7,0-1.3,0.6-1.3,1.3
                           c0,0.7,0.6,1.3,1.3,1.3c4.9,0,8.9,3.9,8.9,8.8C54.2,41.8,50.2,45.8,45.3,45.8z M45.3,8.8c-0.7,0-1.3,0.6-1.3,1.3
                           c0,0.7,0.6,1.3,1.3,1.3c14.3,0,26,11.4,26,25.5s-11.7,25.5-26,25.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                           C61.1,65.2,74,52.5,74,37C74,21.5,61.1,8.8,45.3,8.8z M5.2,45.9c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3
                           H8.2c-1.7,0-3.1,1.4-3.1,3V45.9z M7.9,44c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                           c-0.2,0-0.4-0.2-0.4-0.4V44z M15.8,45.9c0,1.7,1.4,3,3.1,3H21c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3h-2.1
                           c-1.7,0-3.1,1.4-3.1,3V45.9z M18.5,44c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                           c-0.2,0-0.4-0.2-0.4-0.4V44z M26.5,45.9c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3V44c0-1.7-1.4-3-3.1-3h-2.1
                           c-1.7,0-3.1,1.4-3.1,3V45.9z M29.2,44c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                           c-0.2,0-0.4-0.2-0.4-0.4V44z M5.2,35.7c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3H8.2
                           c-1.7,0-3.1,1.4-3.1,3V35.7z M7.9,33.8c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                           c-0.2,0-0.4-0.2-0.4-0.4V33.8z M15.8,35.7c0,1.7,1.4,3,3.1,3H21c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1
                           c-1.7,0-3.1,1.4-3.1,3V35.7z M18.5,33.8c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                           c-0.2,0-0.4-0.2-0.4-0.4V33.8z M26.5,35.7c0,1.7,1.4,3,3.1,3h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1
                           c-1.7,0-3.1,1.4-3.1,3V35.7z M29.2,33.8c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                           c-0.2,0-0.4-0.2-0.4-0.4V33.8z M10.3,28.6c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3H8.2c-1.7,0-3.1,1.4-3.1,3v1.9
                           c0,1.7,1.4,3,3.1,3H10.3z M7.9,25.6v-1.9c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4H8.2
                           C8,25.9,7.9,25.8,7.9,25.6z M18.9,28.6H21c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1c-1.7,0-3.1,1.4-3.1,3v1.9
                           C15.8,27.2,17.2,28.6,18.9,28.6z M18.5,23.6c0-0.2,0.2-0.4,0.4-0.4H21c0.2,0,0.4,0.2,0.4,0.4v1.9c0,0.2-0.2,0.4-0.4,0.4h-2.1
                           {imageLink:"6z M29.6,28.6h2.1c1.7,0,3.1-1.4,3.1-3v-1.9c0-1.7-1.4-3-3.1-3h-2.1c-1.7,0-3.1,1.4-3.1,3v1.9,c-0.2,0-0.4-0.2-0.4-0.4V23.6z"
													></path>
												</svg>`,
					title: 'On-demand Business',
					descriptions: [
						'Tap into the growing trend of on-demand businesses. From taxi hailing to food ordering, there is so much that an on-demand app can do. But, it needs the app to be well-thought, especially from the angles of the customer, deliverer and admin.',
						'With a breadth of experience in building all kinds of on-demand businesses, we are sure to turn your business easily available on demand for your customers.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M19.7,48.1h-2.5v19.7c0,3.4,2.8,6.2,6.2,6.2h27.1c3.4,0,6.2-2.8,6.2-6.2v-6.2h-37V48.1z M54.3,64.1v3.7
                  c0,2-1.7,3.7-3.7,3.7H23.4c-2,0-3.7-1.7-3.7-3.7v-3.7H54.3z M19.7,16.6c2,1.2,4.6,0.8,6.2-1c1.7,2,4.8,2.3,6.9,0.5
                  c0.2-0.2,0.4-0.3,0.5-0.5c1.7,2,4.8,2.3,6.9,0.5c0.2-0.2,0.4-0.3,0.5-0.5c1.7,2,4.8,2.3,6.9,0.5c0.2-0.2,0.4-0.3,0.5-0.5
                  c1.5,1.8,4.1,2.2,6.2,1V21h2.5V6.2C56.7,2.8,54,0,50.6,0H23.4c-3.4,0-6.2,2.8-6.2,6.2v12.3h2.5V16.6z M32.1,12.3
                  c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z M39.5,12.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z
                  M46.9,12.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z M54.3,12.3c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V9.9
                  h4.9V12.3z M19.7,6.2c0-2,1.7-3.7,3.7-3.7h27.1c2,0,3.7,1.7,3.7,3.7v1.2H19.7V6.2z M19.7,9.9h4.9v2.5c0,1.4-1.1,2.5-2.5,2.5
                  c-1.4,0-2.5-1.1-2.5-2.5V9.9z M72.8,32.1h-7.4v-2.5c0-3.4-2.8-6.2-6.2-6.2c-0.8,0-1.7,0.2-2.5,0.5c-0.8-0.3-1.6-0.5-2.5-0.5
                  c-3.4,0-6.2,2.8-6.2,6.2v2.5h-4.9c-0.7,0-1.2,0.6-1.2,1.2V58c0,0.7,0.6,1.2,1.2,1.2h29.6c0.7,0,1.2-0.6,1.2-1.2V33.3
                  C74,32.6,73.4,32.1,72.8,32.1z M59.2,25.9c2,0,3.7,1.7,3.7,3.7v2.5h-2.5v-2.5C60.4,28.3,60,27,59.2,25.9L59.2,25.9z M55.5,29.6
                  c0-1,0.5-2,1.2-2.7c0.8,0.7,1.2,1.7,1.2,2.7v2.5h-2.5V29.6z M50.6,29.6c0-2,1.7-3.7,3.7-3.7h0C53.5,27,53,28.3,53,29.6v2.5h-2.5
                  V29.6z M64.1,56.7H44.4V34.5h3.7V37h2.5v-2.5H58V37h2.5v-2.5h3.7V56.7z M67.8,56.7l1.2-1.6l1.2,1.6H67.8z M71.5,54.3l-1.5-2
                  c-0.4-0.5-1.3-0.6-1.8-0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-1.5,2V34.5h4.9V54.3z M37,40.7V25.9c0-2.7-2.2-4.9-4.9-4.9H4.9
                  C2.2,21,0,23.2,0,25.9v14.8c0,2.7,2.2,4.9,4.9,4.9h27.1C34.8,45.6,37,43.4,37,40.7z M2.5,40.7V25.9c0-1.4,1.1-2.5,2.5-2.5h27.1
                  c1.4,0,2.5,1.1,2.5,2.5v14.8c0,1.4-1.1,2.5-2.5,2.5H4.9C3.6,43.2,2.5,42.1,2.5,40.7z M30.9,34.3c-1.4-1.3-3.6-1.3-5,0
                  c-1.5-1.4-3.9-1.3-5.2,0.2c-1.4,1.5-1.3,3.9,0.2,5.2c0.7,0.6,1.6,1,2.5,1c0.9,0,1.8-0.3,2.5-1c1.5,1.4,3.9,1.3,5.2-0.2
                  C32.5,38,32.4,35.6,30.9,34.3z M23.4,38.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
                  C24.7,37.7,24.1,38.2,23.4,38.2z M28.4,38.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
                  C29.6,37.7,29,38.2,28.4,38.2z M32.1,25.9h-2.5v2.5h2.5V25.9z M27.1,25.9h-2.5v2.5h2.5V25.9z M11.1,25.9H6.2
                  c-0.7,0-1.2,0.6-1.2,1.2v4.9c0,0.7,0.6,1.2,1.2,1.2h4.9c0.7,0,1.2-0.6,1.2-1.2v-4.9C12.3,26.5,11.8,25.9,11.1,25.9z M9.9,30.8H7.4
                  v-2.5h2.5V30.8z M4.9,38.2h2.5v-2.5H4.9V38.2z M9.9,38.2h2.5v-2.5H9.9V38.2z M14.8,38.2h2.5v-2.5h-2.5V38.2z"
									></path>
								</svg>`,
					title2: 'Shopping & Ecommerce',
					imageLink: '/shopping-and-ecommerce.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M19.7,48.1h-2.5v19.7c0,3.4,2.8,6.2,6.2,6.2h27.1c3.4,0,6.2-2.8,6.2-6.2v-6.2h-37V48.1z M54.3,64.1v3.7
                           c0,2-1.7,3.7-3.7,3.7H23.4c-2,0-3.7-1.7-3.7-3.7v-3.7H54.3z M19.7,16.6c2,1.2,4.6,0.8,6.2-1c1.7,2,4.8,2.3,6.9,0.5
                           c0.2-0.2,0.4-0.3,0.5-0.5c1.7,2,4.8,2.3,6.9,0.5c0.2-0.2,0.4-0.3,0.5-0.5c1.7,2,4.8,2.3,6.9,0.5c0.2-0.2,0.4-0.3,0.5-0.5
                           c1.5,1.8,4.1,2.2,6.2,1V21h2.5V6.2C56.7,2.8,54,0,50.6,0H23.4c-3.4,0-6.2,2.8-6.2,6.2v12.3h2.5V16.6z M32.1,12.3
                           c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z M39.5,12.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z
                           M46.9,12.3c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V9.9h4.9V12.3z M54.3,12.3c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V9.9
                           h4.9V12.3z M19.7,6.2c0-2,1.7-3.7,3.7-3.7h27.1c2,0,3.7,1.7,3.7,3.7v1.2H19.7V6.2z M19.7,9.9h4.9v2.5c0,1.4-1.1,2.5-2.5,2.5
                           c-1.4,0-2.5-1.1-2.5-2.5V9.9z M72.8,32.1h-7.4v-2.5c0-3.4-2.8-6.2-6.2-6.2c-0.8,0-1.7,0.2-2.5,0.5c-0.8-0.3-1.6-0.5-2.5-0.5
                           c-3.4,0-6.2,2.8-6.2,6.2v2.5h-4.9c-0.7,0-1.2,0.6-1.2,1.2V58c0,0.7,0.6,1.2,1.2,1.2h29.6c0.7,0,1.2-0.6,1.2-1.2V33.3
                           C74,32.6,73.4,32.1,72.8,32.1z M59.2,25.9c2,0,3.7,1.7,3.7,3.7v2.5h-2.5v-2.5C60.4,28.3,60,27,59.2,25.9L59.2,25.9z M55.5,29.6
                           c0-1,0.5-2,1.2-2.7c0.8,0.7,1.2,1.7,1.2,2.7v2.5h-2.5V29.6z M50.6,29.6c0-2,1.7-3.7,3.7-3.7h0C53.5,27,53,28.3,53,29.6v2.5h-2.5
                           V29.6z M64.1,56.7H44.4V34.5h3.7V37h2.5v-2.5H58V37h2.5v-2.5h3.7V56.7z M67.8,56.7l1.2-1.6l1.2,1.6H67.8z M71.5,54.3l-1.5-2
                           c-0.4-0.5-1.3-0.6-1.8-0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-1.5,2V34.5h4.9V54.3z M37,40.7V25.9c0-2.7-2.2-4.9-4.9-4.9H4.9
                           C2.2,21,0,23.2,0,25.9v14.8c0,2.7,2.2,4.9,4.9,4.9h27.1C34.8,45.6,37,43.4,37,40.7z M2.5,40.7V25.9c0-1.4,1.1-2.5,2.5-2.5h27.1
                           c1.4,0,2.5,1.1,2.5,2.5v14.8c0,1.4-1.1,2.5-2.5,2.5H4.9C3.6,43.2,2.5,42.1,2.5,40.7z M30.9,34.3c-1.4-1.3-3.6-1.3-5,0
                           c-1.5-1.4-3.9-1.3-5.2,0.2c-1.4,1.5-1.3,3.9,0.2,5.2c0.7,0.6,1.6,1,2.5,1c0.9,0,1.8-0.3,2.5-1c1.5,1.4,3.9,1.3,5.2-0.2
                           C32.5,38,32.4,35.6,30.9,34.3z M23.4,38.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
                           C24.7,37.7,24.1,38.2,23.4,38.2z M28.4,38.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
                           {imageLink:"2z M32.1,25.9h-2.5v2.5h2.5V25.9z M27.1,25.9h-2.5v2.5h2.5V25.9z M11.1,25.9H6.2,v-2.5h2.5V30.8z M4.9,38.2h2.5v-2.5H4.9V38.2z M9.9,38.2h2.5v-2.5H9.9V38.2z M14.8,38.2h2.5v-2.5h-2.5V38.2z"
													></path>
												</svg>`,
					title: 'Shopping and eCommerce',
					descriptions: [
						'The Trillion-dollar worth mobile commerce world has room for plenty of iPhone apps. Make your app stand out from the crowd with shopping-friendly features like secure mobile checkout, wishlists, digital wallets, order tracking and much more.'
					]
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M28.6,66.3H26v2.6h2.6V66.3z M22.1,7.7h10.3V5.1H22.1V7.7z M66.1,17.9H48V5.1C48,2.3,45.7,0,42.8,0h-31
                  C8.9,0,6.6,2.3,6.6,5.1v63.8c0,2.8,2.3,5.1,5.2,5.1h31c2.9,0,5.2-2.3,5.2-5.1V44.7h18.1c0.7,0,1.3-0.6,1.3-1.3V19.1
                  C67.4,18.4,66.8,17.9,66.1,17.9z M9.2,5.1c0-1.4,1.2-2.6,2.6-2.6h31c1.4,0,2.6,1.1,2.6,2.6v5.1H9.2V5.1z M45.4,68.9
                  c0,1.4-1.2,2.6-2.6,2.6h-31c-1.4,0-2.6-1.1-2.6-2.6v-5.1h36.2V68.9z M45.4,61.2H9.2V12.8h36.2v5.1H22.1c-0.7,0-1.3,0.6-1.3,1.3
                  v24.2c0,0.7,0.6,1.3,1.3,1.3h7.8v7.7c0,0.7,0.6,1.3,1.3,1.3c0.3,0,0.6-0.1,0.9-0.3l10-8.6h3.4V61.2z M64.8,42.1H41.5
                  c-0.3,0-0.6,0.1-0.9,0.3l-8.2,7.1v-6.1c0-0.7-0.6-1.3-1.3-1.3h-7.8V20.4h41.4V42.1z M60.9,24.2H27.3v2.6h33.6V24.2z M50.6,33.2
                  h10.3v-2.6H50.6V33.2z M45.4,30.6v2.6H48v-2.6H45.4z M42.8,30.6H27.3v2.6h15.5V30.6z M42.8,38.3h18.1v-2.6H42.8V38.3z M37.6,38.3
                  h2.6v-2.6h-2.6V38.3z M32.5,38.3h2.6v-2.6h-2.6V38.3z M29.9,35.7h-2.6v2.6h2.6V35.7z"
									></path>
								</svg>`,
					title2: 'Instant Messaging',
					imageLink: '/instant-messaging.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M28.6,66.3H26v2.6h2.6V66.3z M22.1,7.7h10.3V5.1H22.1V7.7z M66.1,17.9H48V5.1C48,2.3,45.7,0,42.8,0h-31
                           C8.9,0,6.6,2.3,6.6,5.1v63.8c0,2.8,2.3,5.1,5.2,5.1h31c2.9,0,5.2-2.3,5.2-5.1V44.7h18.1c0.7,0,1.3-0.6,1.3-1.3V19.1
                           C67.4,18.4,66.8,17.9,66.1,17.9z M9.2,5.1c0-1.4,1.2-2.6,2.6-2.6h31c1.4,0,2.6,1.1,2.6,2.6v5.1H9.2V5.1z M45.4,68.9
                           c0,1.4-1.2,2.6-2.6,2.6h-31c-1.4,0-2.6-1.1-2.6-2.6v-5.1h36.2V68.9z M45.4,61.2H9.2V12.8h36.2v5.1H22.1c-0.7,0-1.3,0.6-1.3,1.3
                           v24.2c0,0.7,0.6,1.3,1.3,1.3h7.8v7.7c0,0.7,0.6,1.3,1.3,1.3c0.3,0,0.6-0.1,0.9-0.3l10-8.6h3.4V61.2z M64.8,42.1H41.5
                           {imageLink:"2,7.1v-6.1c0-0.7-0.6-1.3-1.3-1.3h-7.8V20.4h41.4V42.1z M60.9,24.2H27.3v2.6h33.6V24.2z M50.6,33.2,h2.6v-2.6h-2.6V38.3z M32.5,38.3h2.6v-2.6h-2.6V38.3z M29.9,35.7h-2.6v2.6h2.6V35.7z"
													></path>
												</svg>`,
					title: 'Instant Messaging',
					descriptions: [
						'Be here. Be there. Be everywhere. Be available real-time with instant messaging apps for instant messaging, video communication, online collaboration and much more. Instant messaging can throw open a world of communication options for your business.',
						'Your business may not need another Facebook Messenger or WhatsApp. It needs a customized instant messaging app. We can help you build that.'
					]
				},
				{
					imageLink: '/live-video-streaming.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M56.5,0H17.5c-2,0-3.7,1.7-3.7,3.7v66.6c0,2,1.6,3.7,3.7,3.7h38.9c2,0,3.7-1.7,3.7-3.7V39.5h-2.4v12.3h-7.3
                           V38.2c0-3.4-2.7-6.2-6.1-6.2h-2.6c1.9-1.1,3.5-2.8,4.3-4.9h8h3.7v4.9h2.4v-6.2V6.2V3.7C60.1,1.7,58.5,0,56.5,0z M49.2,54.3h8.5v16
                           c0,0.7-0.5,1.2-1.2,1.2H17.5c-0.7,0-1.2-0.6-1.2-1.2v-16h8.5H49.2z M44.3,34.5c2,0,3.7,1.7,3.7,3.7v13.6h-3.5l1.1-9.7
                           c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.3,1.1l-1.1,10H32l-1.1-10c-0.1-0.7-0.7-1.2-1.3-1.1c-0.7,0.1-1.2,0.7-1.1,1.4
                           l1.1,9.7H26V38.2c0-2,1.6-3.7,3.7-3.7H44.3z M37,30.8c-2.2,0-4.2-1-5.6-2.6c-1.4-1.7-2-3.8-1.6-6.1c0.5-2.9,2.7-5.3,5.5-5.9
                           c0.5-0.1,1.1-0.2,1.6-0.2c0.4,0,0.8,0,1.2,0.1v7.3c0,2,1.6,3.7,3.7,3.7h1.4C42,29.4,39.6,30.8,37,30.8z M52.8,24.7h-7.3v-2.5
                           c0-0.7,0.5-1.2,1.2-1.2h4.9c0.7,0,1.2,0.6,1.2,1.2V24.7z M46.7,14.8c0-1.4,1.1-2.5,2.4-2.5c1.3,0,2.4,1.1,2.4,2.5
                           s-1.1,2.5-2.4,2.5C47.8,17.3,46.7,16.2,46.7,14.8z M57.7,24.7h-2.4v-2.5c0-1.8-1.3-3.3-3-3.6c1.1-0.9,1.8-2.2,1.8-3.8
                           c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9c0,1.5,0.7,2.9,1.8,3.8c-1.7,0.3-3,1.8-3,3.6v2.5h-1.2c-0.7,0-1.2-0.6-1.2-1.2v-8.3
                           V7.4h17V24.7z M57.7,4.9H39.4h-11v2.5h9.7v6.3c-1.1-0.1-2.3-0.1-3.4,0.2c-3.8,0.9-6.8,4.1-7.4,7.9c-0.5,2.9,0.3,5.8,2.2,8.1
                           c0.8,0.9,1.7,1.7,2.7,2.3h-2.6c-3.4,0-6.1,2.8-6.1,6.2v13.6h-7.3V7.4h4.9V4.9h-4.9V3.7c0-0.7,0.5-1.2,1.2-1.2h38.9
                           c0.7,0,1.2,0.6,1.2,1.2V4.9z M57.7,37h2.4v-2.5h-2.4V37z M23.6,7.4H26V4.9h-2.4V7.4z M37,69.1c3.4,0,6.1-2.8,6.1-6.2
                           {imageLink:",2.8-6.1,6.2C30.9,66.3,33.6,69.1,37,69.1z M37,59.2c2,0,3.7,1.7,3.7,3.7c0,2-1.6,3.7-3.7,3.7,H54V61.7z"
													></path>
												</svg>`,
					title: 'Live Video Streaming',
					descriptions: [
						'On the Internet as well as on media, NOW is the new form of currency. The audience wants to consume content that is produced LIVE right in front of their eyes. To serve that right across all digital platforms, you need the right media infrastructure.',
						'Our exposure to video on demand solutions, live streaming solutions and digital media technologies can help you become a force in the live video streaming space.'
					],
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M56.5,0H17.5c-2,0-3.7,1.7-3.7,3.7v66.6c0,2,1.6,3.7,3.7,3.7h38.9c2,0,3.7-1.7,3.7-3.7V39.5h-2.4v12.3h-7.3
                  V38.2c0-3.4-2.7-6.2-6.1-6.2h-2.6c1.9-1.1,3.5-2.8,4.3-4.9h8h3.7v4.9h2.4v-6.2V6.2V3.7C60.1,1.7,58.5,0,56.5,0z M49.2,54.3h8.5v16
                  c0,0.7-0.5,1.2-1.2,1.2H17.5c-0.7,0-1.2-0.6-1.2-1.2v-16h8.5H49.2z M44.3,34.5c2,0,3.7,1.7,3.7,3.7v13.6h-3.5l1.1-9.7
                  c0.1-0.7-0.4-1.3-1.1-1.4c-0.7-0.1-1.3,0.4-1.3,1.1l-1.1,10H32l-1.1-10c-0.1-0.7-0.7-1.2-1.3-1.1c-0.7,0.1-1.2,0.7-1.1,1.4
                  l1.1,9.7H26V38.2c0-2,1.6-3.7,3.7-3.7H44.3z M37,30.8c-2.2,0-4.2-1-5.6-2.6c-1.4-1.7-2-3.8-1.6-6.1c0.5-2.9,2.7-5.3,5.5-5.9
                  c0.5-0.1,1.1-0.2,1.6-0.2c0.4,0,0.8,0,1.2,0.1v7.3c0,2,1.6,3.7,3.7,3.7h1.4C42,29.4,39.6,30.8,37,30.8z M52.8,24.7h-7.3v-2.5
                  c0-0.7,0.5-1.2,1.2-1.2h4.9c0.7,0,1.2,0.6,1.2,1.2V24.7z M46.7,14.8c0-1.4,1.1-2.5,2.4-2.5c1.3,0,2.4,1.1,2.4,2.5
                  s-1.1,2.5-2.4,2.5C47.8,17.3,46.7,16.2,46.7,14.8z M57.7,24.7h-2.4v-2.5c0-1.8-1.3-3.3-3-3.6c1.1-0.9,1.8-2.2,1.8-3.8
                  c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9c0,1.5,0.7,2.9,1.8,3.8c-1.7,0.3-3,1.8-3,3.6v2.5h-1.2c-0.7,0-1.2-0.6-1.2-1.2v-8.3
                  V7.4h17V24.7z M57.7,4.9H39.4h-11v2.5h9.7v6.3c-1.1-0.1-2.3-0.1-3.4,0.2c-3.8,0.9-6.8,4.1-7.4,7.9c-0.5,2.9,0.3,5.8,2.2,8.1
                  c0.8,0.9,1.7,1.7,2.7,2.3h-2.6c-3.4,0-6.1,2.8-6.1,6.2v13.6h-7.3V7.4h4.9V4.9h-4.9V3.7c0-0.7,0.5-1.2,1.2-1.2h38.9
                  c0.7,0,1.2,0.6,1.2,1.2V4.9z M57.7,37h2.4v-2.5h-2.4V37z M23.6,7.4H26V4.9h-2.4V7.4z M37,69.1c3.4,0,6.1-2.8,6.1-6.2
                  c0-3.4-2.7-6.2-6.1-6.2s-6.1,2.8-6.1,6.2C30.9,66.3,33.6,69.1,37,69.1z M37,59.2c2,0,3.7,1.7,3.7,3.7c0,2-1.6,3.7-3.7,3.7
                  c-2,0-3.7-1.7-3.7-3.7C33.3,60.9,35,59.2,37,59.2z M38.2,61.7h-2.4v2.5h2.4V61.7z M20,64.1h7.3v-2.5H20V64.1z M54,61.7h-7.3v2.5
                  H54V61.7z"
									></path>
								</svg>`,
					title2: 'Live Video Streaming'
				},
				{
					svg2: `<svg
									x="0px"
									y="0px"
									viewBox="0 0 74 74"
									width="50"
									style="enable-background: new 0 0 74 74"
									xml:space="preserve"
								>
									<path
										fill="#202122"
										d="M36.8,47.8c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C40.4,49.4,38.8,47.8,36.8,47.8z
                  M36.8,52.6c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S37.4,52.6,36.8,52.6z"
									></path>
									<path
										fill="#202122"
										d="M34.4,8.5h4.8c0.7,0,1.2,0.5,1.2,1.2h2.4c0-2-1.6-3.6-3.6-3.6H38V4.9h-2.4v1.2h-1.2c-2,0-3.6,1.6-3.6,3.6v1.2
                  c0,2,1.6,3.6,3.6,3.6h4.8c0.7,0,1.2,0.5,1.2,1.2v1.2c0,0.7-0.5,1.2-1.2,1.2h-4.8c-0.7,0-1.2-0.5-1.2-1.2h-2.4c0,2,1.6,3.6,3.6,3.6
                  h1.2v1.2H38v-1.2h1.2c2,0,3.6-1.6,3.6-3.6v-1.2c0-2-1.6-3.6-3.6-3.6h-4.8c-0.7,0-1.2-0.5-1.2-1.2V9.6C33.2,9,33.7,8.5,34.4,8.5z"
									></path>
									<path
										fill="#202122"
										d="M36.8,26.3c3,0,5.9-1.1,8.3-2.9l-1.5-1.9c-1.8,1.6-4.4,2.4-6.7,2.4c-5.9,0-10.7-4.8-10.7-10.7
                  c0-2.5,0.8-4.9,2.4-6.7L26.7,5c-1.8,2.4-2.9,5.3-2.9,8.3C23.7,20.5,29.6,26.3,36.8,26.3z"
									></path>
									<path
										fill="#202122"
										d="M36.8,2.5c5.9,0,10.7,4.8,10.7,10.7c0,2.5-0.8,4.9-2.4,6.7l1.9,1.5c1.9-2.4,2.9-5.3,2.9-8.3
                  C49.9,5.8,44.1,0,36.8,0c-3,0-5.9,1.1-8.3,2.9l1.5,1.9C32,3.3,34.4,2.5,36.8,2.5z"
									></path>
									<path
										fill="#202122"
										d="M66.5,16.8H49.9v30.5l-6.1-4.4h0.1V28.7H29.6v14.3h0.1l-6.1,4.4V16.8H7.2v54.8H4.8V74h64.1v-2.4h-2.4V16.8z
                  M52.2,19.2h11.9v52.5H52.2V59.7h2.4v-9L52.2,49V19.2z M21.3,57.4v-5.3L36.8,41l15.5,11.1v5.3H21.3z M26.1,59.7v11.9h-2.4V59.7H26.1
                  z M28.5,59.7h4.8v11.9h-4.8V59.7z M35.6,59.7H38v11.9h-2.4V59.7z M40.4,59.7h4.8v11.9h-4.8C40.4,71.6,40.4,59.7,40.4,59.7z
                  M47.5,59.7h2.4v11.9h-2.4V59.7z M32,31.2h9.5v10.3l-4.8-3.4L32,41.5V31.2z M9.6,19.2h11.9V49l-2.4,1.7v9h2.4v11.9H9.6
                  C9.6,71.6,9.6,19.2,9.6,19.2z"
									></path>
									<path fill="#202122" d="M54.6,21.5H57v2.4h-2.4V21.5z"></path>
									<path fill="#202122" d="M59.4,21.5h2.4v2.4h-2.4V21.5z"></path>
									<path fill="#202122" d="M54.6,26.3H57v2.4h-2.4V26.3z"></path>
									<path fill="#202122" d="M59.4,26.3h2.4v2.4h-2.4V26.3z"></path>
									<path fill="#202122" d="M54.6,31.2H57v2.4h-2.4V31.2z"></path>
									<path fill="#202122" d="M59.4,31.2h2.4v2.4h-2.4V31.2z"></path>
									<path fill="#202122" d="M54.6,35.9H57v2.4h-2.4V35.9z"></path>
									<path fill="#202122" d="M59.4,35.9h2.4v2.4h-2.4V35.9z"></path>
									<path fill="#202122" d="M54.6,40.7H57v2.4h-2.4V40.7z"></path>
									<path fill="#202122" d="M59.4,40.7h2.4v2.4h-2.4V40.7z"></path>
									<path fill="#202122" d="M11.9,21.5h2.4v2.4h-2.4V21.5z"></path>
									<path fill="#202122" d="M16.7,21.5h2.4v2.4h-2.4V21.5z"></path>
									<path fill="#202122" d="M11.9,26.3h2.4v2.4h-2.4V26.3z"></path>
									<path fill="#202122" d="M16.7,26.3h2.4v2.4h-2.4V26.3z"></path>
									<path fill="#202122" d="M11.9,31.2h2.4v2.4h-2.4V31.2z"></path>
									<path fill="#202122" d="M16.7,31.2h2.4v2.4h-2.4V31.2z"></path>
									<path fill="#202122" d="M11.9,35.9h2.4v2.4h-2.4V35.9z"></path>
									<path fill="#202122" d="M16.7,35.9h2.4v2.4h-2.4V35.9z"></path>
									<path fill="#202122" d="M11.9,40.7h2.4v2.4h-2.4V40.7z"></path>
									<path fill="#202122" d="M16.7,40.7h2.4v2.4h-2.4V40.7z"></path>
								</svg>`,
					title2: 'Banking and Finance',
					imageLink: '/banking-and-finance.webp',
					svg: `<svg
													x="0px"
													y="0px"
													viewBox="0 0 74 74"
													width="74"
													style="enable-background: new 0 0 74 74"
													xml:space="preserve"
												>
													<path
														fill="#000"
														d="M36.8,47.8c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C40.4,49.4,38.8,47.8,36.8,47.8z
                           M36.8,52.6c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S37.4,52.6,36.8,52.6z"
													></path>
													<path
														fill="#000"
														d="M34.4,8.5h4.8c0.7,0,1.2,0.5,1.2,1.2h2.4c0-2-1.6-3.6-3.6-3.6H38V4.9h-2.4v1.2h-1.2c-2,0-3.6,1.6-3.6,3.6v1.2
                           c0,2,1.6,3.6,3.6,3.6h4.8c0.7,0,1.2,0.5,1.2,1.2v1.2c0,0.7-0.5,1.2-1.2,1.2h-4.8c-0.7,0-1.2-0.5-1.2-1.2h-2.4c0,2,1.6,3.6,3.6,3.6
                           h1.2v1.2H38v-1.2h1.2c2,0,3.6-1.6,3.6-3.6v-1.2c0-2-1.6-3.6-3.6-3.6h-4.8c-0.7,0-1.2-0.5-1.2-1.2V9.6C33.2,9,33.7,8.5,34.4,8.5z"
													></path>
													<path
														fill="#000"
														d="M36.8,26.3c3,0,5.9-1.1,8.3-2.9l-1.5-1.9c-1.8,1.6-4.4,2.4-6.7,2.4c-5.9,0-10.7-4.8-10.7-10.7
                           c0-2.5,0.8-4.9,2.4-6.7L26.7,5c-1.8,2.4-2.9,5.3-2.9,8.3C23.7,20.5,29.6,26.3,36.8,26.3z"
													></path>
													<path
														fill="#000"
														d="M36.8,2.5c5.9,0,10.7,4.8,10.7,10.7c0,2.5-0.8,4.9-2.4,6.7l1.9,1.5c1.9-2.4,2.9-5.3,2.9-8.3
                           C49.9,5.8,44.1,0,36.8,0c-3,0-5.9,1.1-8.3,2.9l1.5,1.9C32,3.3,34.4,2.5,36.8,2.5z"
													></path>
													<path
														fill="#000"
														d="M66.5,16.8H49.9v30.5l-6.1-4.4h0.1V28.7H29.6v14.3h0.1l-6.1,4.4V16.8H7.2v54.8H4.8V74h64.1v-2.4h-2.4V16.8z
                           M52.2,19.2h11.9v52.5H52.2V59.7h2.4v-9L52.2,49V19.2z M21.3,57.4v-5.3L36.8,41l15.5,11.1v5.3H21.3z M26.1,59.7v11.9h-2.4V59.7H26.1
                           z M28.5,59.7h4.8v11.9h-4.8V59.7z M35.6,59.7H38v11.9h-2.4V59.7z M40.4,59.7h4.8v11.9h-4.8C40.4,71.6,40.4,59.7,40.4,59.7z
                           M47.5,59.7h2.4v11.9h-2.4V59.7z M32,31.2h9.5v10.3l-4.8-3.4L32,41.5V31.2z M9.6,19.2h11.9V49l-2.4,1.7v9h2.4v11.9H9.6
                           C9.6,71.6,9.6,19.2,9.6,19.2z"
													></path>
													<path fill="#000" d="M54.6,21.5H57v2.4h-2.4V21.5z"></path>
													<path fill="#000" d="M59.4,21.5h2.4v2.4h-2.4V21.5z"></path>
													<path fill="#000" d="M54.6,26.3H57v2.4h-2.4V26.3z"></path>
													<path fill="#000" d="M59.4,26.3h2.4v2.4h-2.4V26.3z"></path>
													<path fill="#000" d="M54.6,31.2H57v2.4h-2.4V31.2z"></path>
													<path fill="#000" d="M59.4,31.2h2.4v2.4h-2.4V31.2z"></path>
													<path fill="#000" d="M54.6,35.9H57v2.4h-2.4V35.9z"></path>
													<path fill="#000" d="M59.4,35.9h2.4v2.4h-2.4V35.9z"></path>
													<path fill="#000" d="M54.6,40.7H57v2.4h-2.4V40.7z"></path>
													<path fill="#000" d="M59.4,40.7h2.4v2.4h-2.4V40.7z"></path>
													<path fill="#000" d="M11.9,21.5h2.4v2.4h-2.4V21.5z"></path>
													<path fill="#000" d="M16.7,21.5h2.4v2.4h-2.4V21.5z"></path>
													<path fill="#000" d="M11.9,26.3h2.4v2.4h-2.4V26.3z"></path>
													<path fill="#000" d="M16.7,26.3h2.4v2.4h-2.4V26.3z"></path>
													<path fill="#000" d="M11.9,31.2h2.4v2.4h-2.4V31.2z"></path>
													<path fill="#000" d="M16.7,31.2h2.4v2.4h-2.4V31.2z"></path>
													{imageLink:".9,35.9h2.4v2.4h-2.4V35.9z"></path>,<path fill="#000" d="M11.9,40.7h2.4v2.4h-2.4V40.7z"></path>
													<path fill="#000" d="M16.7,40.7h2.4v2.4h-2.4V40.7z"></path>
												</svg>`,
					title: 'Banking and Finance',
					descriptions: [
						'Thanks to Internet banking, digital wallets and cryptocurrencies money has taken several forms. Still, the functions of banking and finance haven’t undergone much change. There are several redundant processes that the century-old industry can reform with the intelligence of custom iPhone app development.',
						'Our experts who know the logical side of banking and finance and technical side of iPhone app development can facilitate the digital transformation of your financial business.'
					]
				}
			]
		};
	},
	methods: {
		setActiveIndex: function (index) {
			this.index_ = index;
			this.$refs.reviewsCarousel.goTo(index);
		},
		setIndex: function (index) {
			this.index_ = index;
		},
		checkScreen() {
			if (window.innerWidth < 776) this.setting.slidesToShow = 1;
		}
	},
	mounted() {
		this.checkScreen();
	}
};
</script>

<style scoped>
.fxt-cnt-scas {
	margin: 0 10px;
}
.icn-containter-fxt {
	display: flex;
	justify-content: space-between;
	align-items: space-between;
	flex-direction: column;
	height: 165px;
	width: 165px;
	flex-wrap: wrap;
	background: #fff;
	padding: 10px 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.icn-containter-fxt .svg-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 50px !important;
	height: 50px !important;
}

.icn-containter-fxt p {
	margin: 0;
	text-align: center;
	transition: 0.2s;
	color: black;
	font-size: 18px;
	font-weight: 600;
}
.fxtt-vue-slick svg {
	margin-bottom: 20px;
}
.fxtt-vue-slick svg path {
	fill: #008dd2 !important;
}
.icn-containter-fxt:hover p {
	color: #008dd2;
}
.big-data-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 567px) {
	.fxt-cnt-scas {
		margin: 0 30px;
	}
	.icn-containter-fxt {
		width: 150px !important;
		height: 150px !important;
	}
	.slick-slide {
		width: 100vw !important;
		padding: 5px;
	}
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>