<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Pathbreaking></Pathbreaking>

		<MobileDesign></MobileDesign>

		<WebDesign></WebDesign>

		<Process></Process>

		<Tools></Tools>

		<WhyChooseUs></WhyChooseUs>

		<TypeDiv></TypeDiv>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/EnterpriseSolutions/UIUXDevelopment/Banner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import About from '../../components/EnterpriseSolutions/UIUXDevelopment/About.vue';
import Pathbreaking from '../../components/EnterpriseSolutions/UIUXDevelopment/Pathbreaking.vue';
import MobileDesign from '../../components/EnterpriseSolutions/UIUXDevelopment/MobileDesign.vue';
import WebDesign from '../../components/EnterpriseSolutions/UIUXDevelopment/WebDesign.vue';
import Process from '../../components/EnterpriseSolutions/UIUXDevelopment/Process.vue';
import Tools from '../../components/EnterpriseSolutions/UIUXDevelopment/Tools.vue';
import WhyChooseUs from '../../components/EnterpriseSolutions/UIUXDevelopment/WhyChooseUs.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		Pathbreaking,
		MobileDesign,
		WebDesign,
		TypeDiv,
		Process,
		Tools,
		WhyChooseUs,
		Const
	}
};
</script>

<style></style>
