<template>
	<div class="our-services-div" id="our-services">
		<div class="os-wrapper row">
			<div class="col-3">
				<router-link to="/mobile-development" class="a-t px-4 py-2 cc-h fxt-srvs-header">
					<svg
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
						class=""
					>
						<g>
							<path
								class="st1"
								d="M63.5,94.5c0,1.2-0.9,2.1-2.1,2.1H18c-1.2,0-2.1-0.9-2.1-2.1V83.7h42.1v-3H16V5.1C16,4,16.9,3,18,3h43.3
                            c1.2,0,2.1,0.9,2.1,2.1v7.6H21.5v3h41.9v8.2h3V5.1c0-2.8-2.3-5.1-5.1-5.1H18C15.2,0,13,2.3,13,5.1v89.4c0,2.8,2.3,5.1,5.1,5.1h43.3
                            c2.8,0,5.1-2.3,5.1-5.1V80.1h-3V94.5z M36,6.3v3h7.5v-3H36z M34.8,90.3c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9
                            c0-2.7-2.2-4.9-4.9-4.9C37,85.4,34.8,87.6,34.8,90.3z M41.6,90.3c0,1.1-0.8,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9s0.8-1.9,1.9-1.9
                            C40.8,88.4,41.6,89.3,41.6,90.3z M77.6,47.2l1.6-0.3l-0.4-1.6c-0.5-1.9-1.2-3.8-2.2-5.5l-0.8-1.5l-1.4,0.9c-2,1.4-4.8,1.1-6.5-0.6
                            c-1.8-1.8-2-4.6-0.5-6.6l1-1.4l-1.4-0.8c-1.8-1-3.6-1.8-5.5-2.4L59.7,27l-0.3,1.7c-0.4,2.5-2.5,4.3-5,4.3c-2.5,0-4.6-1.8-5-4.3
                            L49.1,27l-1.6,0.5c-1.9,0.6-3.8,1.3-5.5,2.4l-1.4,0.8l1,1.4c1.5,2.1,1.2,4.8-0.5,6.6c-1.7,1.7-4.5,2-6.5,0.6l-1.4-0.9l-0.8,1.5
                            c-0.9,1.7-1.7,3.6-2.2,5.5l-0.4,1.6l1.6,0.3c2.4,0.5,4.1,2.6,4.1,5.1c0,2.4-1.6,4.5-4,5l-1.6,0.4l0.5,1.5c0.5,1.9,1.3,3.7,2.3,5.4
                            l0.8,1.3l1.3-0.8c2-1.2,4.6-0.9,6.3,0.7c1.6,1.6,2,4.2,0.8,6.2l-0.8,1.3l1.3,0.7c1.7,1,3.5,1.7,5.3,2.2l1.5,0.4l0.4-1.5
                            c0.6-2.3,2.6-3.8,4.9-3.8c2.3,0,4.3,1.6,4.9,3.8l0.4,1.5l1.5-0.4c1.8-0.5,3.6-1.3,5.3-2.2l1.3-0.7l-0.8-1.3c-1.2-2-0.9-4.6,0.8-6.2
                            c1.6-1.6,4.3-2,6.3-0.7l1.3,0.8l0.8-1.3c1-1.7,1.7-3.5,2.3-5.4l0.5-1.5l-1.6-0.4c-2.3-0.5-4-2.6-4-5C73.5,49.8,75.2,47.7,77.6,47.2
                            z M75.2,59.7c-0.3,0.7-0.6,1.5-0.9,2.2c-0.9-0.3-1.9-0.5-2.8-0.5c-2.2,0-4.2,0.8-5.7,2.4c-2.2,2.2-2.9,5.5-1.9,8.4
                            c-0.7,0.3-1.4,0.6-2.2,0.9c-1.3-2.8-4.1-4.6-7.3-4.6c-3.1,0-5.9,1.8-7.3,4.6c-0.7-0.3-1.5-0.6-2.2-0.9c1-2.9,0.3-6.2-1.9-8.4
                            c-1.5-1.5-3.5-2.4-5.7-2.4c-1,0-1.9,0.2-2.8,0.5c-0.4-0.7-0.7-1.4-0.9-2.2c2.9-1.3,4.7-4.2,4.7-7.4c0-3.3-2-6.3-5-7.5
                            c0.2-0.7,0.5-1.5,0.9-2.2c1,0.4,2,0.6,3.1,0.6c2.2,0,4.2-0.8,5.7-2.4c2.4-2.4,3-6,1.7-9c0.7-0.3,1.4-0.6,2.1-0.9
                            c1.2,3.1,4.1,5.2,7.5,5.2c3.4,0,6.3-2.1,7.5-5.2c0.7,0.3,1.4,0.6,2.1,0.9c-1.3,3-0.7,6.6,1.7,9c1.5,1.5,3.6,2.4,5.7,2.4
                            c1.1,0,2.1-0.2,3.1-0.6c0.3,0.7,0.6,1.4,0.9,2.2c-2.9,1.3-4.9,4.2-4.9,7.5C70.5,55.5,72.4,58.4,75.2,59.7z M57.7,48.9
                            c1.5-1.1,2.5-2.8,2.5-4.8c0-3.2-2.6-5.9-5.8-5.9c-3.2,0-5.8,2.6-5.8,5.9c0,2,1,3.8,2.5,4.8c-3.5,1.3-6,4.8-6,8.8v6.3h18.6v-6.3
                            C63.7,53.6,61.2,50.2,57.7,48.9z M54.4,41.2c1.6,0,2.8,1.3,2.8,2.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8
                            C51.6,42.5,52.8,41.2,54.4,41.2z M60.7,60.9H48.1v-3.3c0-3.5,2.8-6.4,6.3-6.4c3.5,0,6.3,2.9,6.3,6.4V60.9z M29.2,32.8h-7.6v3h7.6
                            V32.8z M21.5,71.1h7.6v-3h-7.6V71.1z M79.4,32.8v3H87v-3H79.4z M79.4,71.1H87v-3h-7.6V71.1z"
							></path>
						</g>
					</svg>
					<h5 class="">Mobile App Development</h5>
				</router-link>
				<ul class="serv-ul">
					<li class="">
						<router-link to="/mobile-development/iphone-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
									class=""
								>
									<g>
										<g>
											<path
												class="st1"
												d="M49.7,25.7c0.4,0,0.8,0,1.1,0c5.3,0,11-2.9,14.8-7.5c4-4.8,6.1-11,5.5-16.6c-0.1-1-1-1.7-2-1.7
                                  c-5.4,0.2-11.8,3.5-15.6,7.9c-3.3,3.7-6.4,9.9-5.6,16.2C48.1,24.9,48.8,25.6,49.7,25.7z M56.5,10.3c2.7-3.1,7.1-5.6,11-6.3
                                  c-0.1,4.1-1.8,8.4-4.7,11.9c-2.9,3.5-7.2,5.8-11.1,6.1C51.7,17.4,54.1,12.9,56.5,10.3z M90.1,70c-1.2-0.5-11.7-5.1-11.8-17.5
                                  c-0.1-9.9,7.2-15.1,9.6-16.6c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4C82.3,24.5,73,23.2,69.4,23.1c-5-0.5-9.9,1.4-13.6,2.8
                                  c-2,0.8-4,1.5-5.1,1.5c-1.3,0-3.2-0.8-5.3-1.6c-3.1-1.2-6.6-2.6-10.4-2.6l-0.3,0c-8.8,0.1-17.1,5.1-21.6,12.9
                                  c-8.5,14.6-3.2,36.6,6.2,50C23,91.6,28.3,99,35.7,99l0.4,0c3.2-0.1,5.4-1.1,7.6-2c2.4-1,4.6-1.9,8.2-1.9c3.4,0,5.5,0.9,7.7,1.8
                                  c2.2,1,4.6,1.9,8,1.9l0.3,0c7.7-0.1,12.5-7,16.4-12.5c4.1-6,6.2-11.7,6.9-14C91.5,71.4,91,70.4,90.1,70z M81.1,84.3
                                  c-4.2,6.1-7.9,10.8-13.3,10.9l-0.3,0c-2.6,0-4.4-0.8-6.4-1.6c-2.4-1-5.1-2.2-9.2-2.2c-4.4,0-7.2,1.2-9.7,2.2
                                  c-2,0.9-3.8,1.6-6.3,1.7l-0.3,0c-5,0-9.2-5.3-13.4-11.3c-11.7-16.6-11.8-36.1-6-46c3.9-6.7,10.9-10.9,18.4-11l0.2,0
                                  c3.1,0,6.2,1.2,9,2.3c2.5,1,4.7,1.8,6.7,1.8c1.8,0,4-0.8,6.5-1.8c3.2-1.2,6.8-2.6,10.5-2.6c0.4,0,0.9,0,1.5,0.1
                                  c2.9,0.1,10,1.1,15.1,7.1c-3.5,2.6-9.7,8.6-9.6,18.6c0.1,12.4,8.8,18.3,12.6,20.2C86.2,75.3,84.3,79.7,81.1,84.3z"
											></path>
										</g>
									</g></svg></span
							>iPhone App Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/mobile-development/android-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M65.3,9l2.9-4.3c0.5-0.7,0.6-1.5,0.5-2.3c-0.2-0.8-0.6-1.5-1.3-1.9C66-0.4,64,0,63.1,1.3l-3.4,5.1
                                c-6.2-2-13.2-2-19.4,0l-3.4-5.1C36,0,34-0.4,32.7,0.5c-1.4,0.9-1.8,2.8-0.8,4.2L34.7,9c-7.1,4.2-11.5,11.4-11.5,18.9
                                c0,1.2,1,2.1,2.2,2.1h49.6c1.2,0,2.2-1,2.2-2.1C77,20.4,72.5,13.2,65.3,9L65.3,9z M27.6,25.8c0.8-5.9,5-11.2,11.1-14
                                c0.3-0.1,0.5-0.3,0.7-0.5c0.3,0,0.6,0,0.8-0.1c6.1-2.4,13.4-2.4,19.5,0c0.3,0.1,0.6,0.2,0.8,0.1c0.2,0.2,0.4,0.4,0.7,0.5
                                c6.2,2.9,10.4,8.2,11.3,14H27.6z M74.8,32.2H25.2c-1.2,0-2.2,1-2.2,2.2v39.9c0,4.3,3.5,7.8,7.8,7.8h1.7v10.5c0,4.1,3.3,7.5,7.5,7.5
                                c4.1,0,7.5-3.3,7.5-7.5V82h5.1v10.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5V82h1.7c4.3,0,7.8-3.5,7.8-7.8V34.4
                                C77,33.2,76,32.2,74.8,32.2L74.8,32.2z M72.7,74.3c0,1.9-1.5,3.5-3.5,3.5h-4.1c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.3-0.3,1.9
                                c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9
                                c-0.4-0.5-1-0.9-1.7-0.9h-9.9c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2
                                c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9c-0.4-0.5-1.1-0.9-1.7-0.9h-4.1c-1.9,0-3.5-1.6-3.5-3.5
                                V36.5h45.3V74.3z M14.2,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                                C21.6,36.5,18.3,33.2,14.2,33.2L14.2,33.2z M17.3,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                                c1.7,0,3.2,1.4,3.2,3.2V63.3z M85.8,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                                C93.3,36.5,90,33.2,85.8,33.2L85.8,33.2z M89,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                                c1.7,0,3.2,1.4,3.2,3.2V63.3z M60.3,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                                C62.8,17.3,61.7,16.2,60.3,16.2L60.3,16.2z M40.9,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                                C43.4,17.3,42.3,16.2,40.9,16.2L40.9,16.2z M40.9,16.2"
									></path></svg></span
							>Android App Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/mobile-development/flutter-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 30 30"
									style="enable-background: new 0 0 30 30"
									xml:space="preserve"
								>
									<g>
										<path
											class="st1"
											d="M17.3,0L2.2,15.2l5.5,5L27.8,0H17.3z M7.7,18.8l-4-3.6L17.7,1h7.6L7.7,18.8z"
										></path>
										<path
											class="st1"
											d="M17.2,13.5l-8.4,8.3l4.9,4.9L17,30h10.5l-8.1-8.1l8.3-8.4H17.2z M10.4,21.9l3.7-3.8l3.9,3.9l-3.8,3.8
                                L10.4,21.9z M25,29h-7.6l-2.5-2.5l3.8-3.8L25,29z M14.8,17.3l2.8-2.8h7.7l-6.6,6.7L14.8,17.3z"
										></path>
									</g></svg></span
							>Flutter App Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/mobile-development/cross-platform-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
									class=""
								>
									<g>
										<g>
											<path
												class="st1"
												d="M52.3,69.7c0-1.4-1-2.5-2.3-2.5c-1.3,0-2.3,1.1-2.3,2.5s1,2.5,2.3,2.5C51.3,72.1,52.3,71,52.3,69.7z
                                  M13.6,84.4c-1.3,0-2.3,1.1-2.3,2.5c0,1.4,1,2.5,2.3,2.5c1.3,0,2.3-1.1,2.3-2.5C15.9,85.5,14.9,84.4,13.6,84.4z M93.2,3.4H6.8
                                  C3.1,3.4,0,6.7,0,10.8v34.4c0,1.4,1,2.5,2.3,2.5c1.3,0,2.3-1.1,2.3-2.5V10.8c0-1.4,1-2.5,2.3-2.5h86.4c1.3,0,2.3,1.1,2.3,2.5v19.6
                                  c0,1.4,1,2.5,2.3,2.5c1.3,0,2.3-1.1,2.3-2.5V10.8C100,6.7,96.9,3.4,93.2,3.4z M43.2,81.9h9.1c1.3,0,2.3-1.1,2.3-2.5
                                  c0-1.4-1-2.5-2.3-2.5H34.1c-1.3,0-2.3,1.1-2.3,2.5c0,1.4,1,2.5,2.3,2.5h4.5v7.4c0,1.4-1,2.5-2.3,2.5h-2.3c-1.3,0-2.3,1.1-2.3,2.5
                                  c0,1.4,1,2.5,2.3,2.5h18.2c1.3,0,2.3-1.1,2.3-2.5c0-1.4-1-2.5-2.3-2.5h-9.5c0.3-0.8,0.4-1.6,0.4-2.5V81.9z M52.3,57.4H34.1
                                  c-1.3,0-2.3,1.1-2.3,2.5c0,1.4,1,2.5,2.3,2.5h18.2c1.3,0,2.3-1.1,2.3-2.5C54.5,58.5,53.5,57.4,52.3,57.4z M20.5,52.5H6.8
                                  c-3.8,0-6.8,3.3-6.8,7.4v29.5c0,4.1,3.1,7.4,6.8,7.4h13.6c3.8,0,6.8-3.3,6.8-7.4V59.9C27.3,55.8,24.2,52.5,20.5,52.5z M22.7,89.3
                                  c0,1.4-1,2.5-2.3,2.5H6.8c-1.3,0-2.3-1.1-2.3-2.5V59.9c0-1.4,1-2.5,2.3-2.5h13.6c1.3,0,2.3,1.1,2.3,2.5V89.3z M79.5,84.4
                                  c-1.3,0-2.3,1.1-2.3,2.5c0,1.4,1,2.5,2.3,2.5s2.3-1.1,2.3-2.5C81.8,85.5,80.8,84.4,79.5,84.4z M93.2,37.8H65.9
                                  c-3.8,0-6.8,3.3-6.8,7.4v44.2c0,4.1,3.1,7.4,6.8,7.4h27.3c3.8,0,6.8-3.3,6.8-7.4V45.1C100,41.1,96.9,37.8,93.2,37.8z M95.5,89.3
                                  c0,1.4-1,2.5-2.3,2.5H65.9c-1.3,0-2.3-1.1-2.3-2.5V45.1c0-1.4,1-2.5,2.3-2.5h27.3c1.3,0,2.3,1.1,2.3,2.5V89.3z"
											></path>
										</g>
									</g></svg></span
							>Cross Platform App Development</router-link
						>
					</li>
					<li>
						<router-link to="/mobile-development/augmented-reality-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M55,90.4h-8.3c-0.9,0-1.6,0.7-1.6,1.5c0,0.8,0.7,1.5,1.6,1.5H55c0.9,0,1.6-0.7,1.6-1.5
                                  C56.6,91.1,55.9,90.4,55,90.4z M30.6,38.9C30.6,38.9,30.6,38.9,30.6,38.9C30.6,38.9,30.6,38.9,30.6,38.9
                                  C30.6,38.9,30.6,38.9,30.6,38.9C30.6,38.9,30.6,38.9,30.6,38.9c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0l0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.2-0.3,0.4-0.3,0.7
                                  v0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v23c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0.1v0c0,0.3,0.1,0.5,0.3,0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.4,0.2l19.2,6.5
                                  c0.1,0,0.3,0.1,0.4,0.1h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0h0c0.2,0,0.3,0,0.4-0.1l19.2-6.5c0.1,0,0.2-0.1,0.4-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0
                                  c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0.2-0.2,0.3-0.4,0.3-0.7v0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0V40c0,0,0,0,0,0
                                  c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1v0c0-0.3-0.1-0.5-0.3-0.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                  c-0.1-0.1-0.2-0.1-0.4-0.2l-19.3-6.5c-0.4-0.1-0.7-0.1-1.1,0l-19.3,6.5C31,38.7,30.8,38.8,30.6,38.9
                                  C30.7,38.8,30.7,38.8,30.6,38.9C30.7,38.8,30.7,38.8,30.6,38.9C30.7,38.8,30.7,38.8,30.6,38.9C30.7,38.9,30.7,38.9,30.6,38.9
                                  L30.6,38.9C30.6,38.9,30.6,38.9,30.6,38.9z M68.6,60.9l-9.7-3.3c-0.8-0.3-1.7,0.1-2,0.9c-0.3,0.8,0.1,1.6,0.9,1.9l7.8,2.6
                                  l-13.1,4.4V47.6l16.1-5.4V60.9z M50.9,35.1l14.7,4.9L50.9,45l-14.7-4.9L50.9,35.1z M33.2,42.1l16.1,5.4v19.8L36.2,63l7.8-2.6
                                  c0.8-0.3,1.2-1.1,0.9-1.9c-0.3-0.8-1.2-1.2-2-0.9l-9.7,3.3V42.1z M83.8,0H18c-3.4,0-6.2,2.6-6.2,5.7v88.6c0,3.1,2.8,5.7,6.2,5.7
                                  h65.9c3.4,0,6.2-2.6,6.2-5.7V5.7C90,2.6,87.2,0,83.8,0z M86.8,94.3c0,1.5-1.3,2.8-3,2.8H18c-1.7,0-3-1.2-3-2.8V5.7
                                  c0-1.5,1.3-2.8,3-2.8h65.9c1.7,0,3,1.2,3,2.8V94.3z M79.1,7.4H22.7c-1.6,0-2.8,1.2-2.8,2.6v74.8c0,1.4,1.3,2.6,2.8,2.6h56.4
                                  c1.6,0,2.8-1.2,2.8-2.6V10C81.9,8.6,80.7,7.4,79.1,7.4z M78.8,16.1H36.5c-0.9,0-1.6,0.7-1.6,1.5c0,0.8,0.7,1.5,1.6,1.5h42.3v65.4
                                  H23V19h6c0.9,0,1.6-0.7,1.6-1.5c0-0.8-0.7-1.5-1.6-1.5h-6v-5.8h55.7V16.1z"
											></path>
										</g>
									</g></svg></span
							>AR App Development</router-link
						>
					</li>
					<li>
						<router-link to="/mobile-development/virtual-reality-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M35.7,47.2h8.1c1.8,0,3.4-1,4.1-2.6c0.2-0.5,0.7-0.7,1.2-0.7H52c0.5,0,1,0.3,1.2,0.7c0.8,1.6,2.4,2.6,4.1,2.6
                                  h8.1c3.6,0,6.6-3,6.6-6.6V34c0-3.6-3-6.6-6.6-6.6H35.7c-3.6,0-6.6,3-6.6,6.6v6.6C29.1,44.2,32,47.2,35.7,47.2z M32.4,34
                                  c0-1.8,1.5-3.3,3.3-3.3h29.7c1.8,0,3.3,1.5,3.3,3.3v6.6c0,1.8-1.5,3.3-3.3,3.3h-8.1c-0.5,0-1-0.3-1.2-0.7
                                  c-0.8-1.6-2.4-2.6-4.1-2.6H49c-1.8,0-3.4,1-4.1,2.6c-0.2,0.5-0.7,0.7-1.2,0.7h-8.1c-1.8,0-3.3-1.5-3.3-3.3V34z M95,81.5
                                  c-7.7-4.1-20.9-8.7-29.7-10.5c7.1-6.1,11.6-16.2,11.6-25.5h1.6c1.8,0,3.3-1.5,3.3-3.3v-9.9c0-1.8-1.5-3.3-3.3-3.3h-1.6
                                  c0-1-0.2-1.9-0.6-2.7C73.9,13.8,63.7,1,50.5,1C37.3,1,27.1,13.8,24.7,26.3c-0.4,0.8-0.6,1.7-0.6,2.7h-1.6c-1.8,0-3.3,1.5-3.3,3.3
                                  v9.9c0,1.8,1.5,3.3,3.3,3.3h1.6c0,9.3,4.5,19.3,11.6,25.5C26.9,72.9,13.7,77.5,6,81.5c-3.1,1.6-5,4.7-5,8.2v8.7
                                  c0,0.9,0.7,1.7,1.6,1.7h62.7h13.2h19.8c0.9,0,1.6-0.7,1.6-1.7v-8.7C100,86.3,98.1,83.1,95,81.5z M76.9,32.3h1.6l0,9.9h-1.7V32.3z
                                  M24.1,42.3h-1.6v-9.9h1.6V42.3z M50.5,4.3c10,0,18.1,8.7,21.4,18.4c-0.5-0.1-1.1-0.2-1.6-0.2H30.7c-0.6,0-1.1,0.1-1.6,0.2
                                  C32.4,13,40.5,4.3,50.5,4.3z M27.4,29c0-0.5,0.1-0.9,0.3-1.3c0,0,0-0.1,0.1-0.1c0.5-1.1,1.7-1.9,3-1.9h39.6c1.3,0,2.5,0.8,3,2
                                  c0,0,0,0,0,0.1c0.2,0.4,0.3,0.8,0.3,1.3v1.7v13.2v1.7c0,1.8-1.5,3.3-3.3,3.3H30.7c-1.8,0-3.3-1.5-3.3-3.3V29z M28,51.6
                                  c0.8,0.4,1.7,0.6,2.7,0.6h39.6c1,0,1.9-0.2,2.7-0.6c-2.6,13-13,22.1-22.5,22.1C41,73.6,30.5,64.5,28,51.6z M55,76.4l-3.9,5.2
                                  c-0.2,0.3-0.5,0.3-0.6,0.3c-0.1,0-0.4,0-0.6-0.3L46,76.4c1.5,0.3,3,0.5,4.5,0.5C52.1,76.9,53.6,76.8,55,76.4z M76.9,96.8H67v-3.3
                                  h9.9V96.8z M96.7,96.8H80.2v-5c0-0.9-0.7-1.7-1.6-1.7H65.4c-0.9,0-1.7,0.7-1.7,1.7v5H4.3v-7.1c0-2.2,1.2-4.2,3.2-5.2
                                  C16,80,31.2,74.9,39.3,73.7c0.3,0.2,0.6,0.3,0.8,0.5l7.1,9.4c0.8,1,2,1.6,3.2,1.6c1.3,0,2.5-0.6,3.2-1.6l7.1-9.4
                                  c0.3-0.2,0.6-0.3,0.8-0.5C69.8,74.9,85,80,93.5,84.5c2,1,3.2,3,3.2,5.2V96.8z"
											></path>
										</g>
									</g></svg></span
							>VR App Development</router-link
						>
					</li>
				</ul>
			</div>
			<div class="col-3">
				<router-link to="/web-development" class="a-t px-4 py-2 cc-h fxt-srvs-header">
					<svg
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
						class=""
					>
						<g>
							<g>
								<path
									class="st1"
									d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                                c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C12.2,9.3,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                                c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                                c0.8,0,1.4,0.6,1.4,1.4C23.6,9.3,23,9.9,22.3,9.9z M33.8,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                                C38.1,6,36.2,4,33.8,4z M33.8,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C35,9.3,34.5,9.9,33.8,9.9z
                                M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                                l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                                l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                                l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                                l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                                c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                                l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                                c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                                c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                                h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                                c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                                c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                                c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                                l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                                c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5s-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3c1.8,0,3.4,1.5,3.4,3.4
                                c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z M15.9,38.4
                                c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2l-6.3,5.5
                                c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                                c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2s-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                                c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.7,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                                l-5.5,14.5C19.4,39.1,19.9,40.1,20.7,40.4z M95.1,35.4H82c0-0.3,0-0.5,0-0.5c0-1.7,0-8.2,0-30.2C82,2.2,79.8,0,77.2,0H4.8
                                C2.2,0,0,2.2,0,4.8C0,16.2,0,77,0,77.2C0,79.8,2.2,82,4.8,82h54.4c0,0.3,0,12.7,0,13.1c0,2.6,2.2,4.9,4.9,4.9h31
                                c2.6,0,4.9-2.2,4.9-4.9c0-16.6,0-29.3,0-47.1v-7.7C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6c0-0.9,0.8-1.7,1.7-1.7H77
                                c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1C61.5,35.3,59.2,37.4,59.2,40.2
                                z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.3V49.5h34.7V86z M96.9,46.4H62.3
                                v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6
                                c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6
                                c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1
                                c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5c-0.9,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h41.1
                                c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1
                                c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
								></path>
							</g>
						</g>
					</svg>
					<h5 class="px-2">Web Development</h5>
				</router-link>
				<ul class="serv-ul">
					<li class="">
						<router-link to="/web-development/php-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
									class=""
								>
									<g>
										<g>
											<path
												class="st1"
												d="M99.5,28L72.6,1.6c-0.3-0.3-1-0.6-1.3-0.6H31.3c-4.3,0-8,3.5-8,7.5v26.7H7c-3.3,0-6,2.5-6,5.7v28.6
                                    c0,3.1,2.7,5.7,6,5.7h16.3v17.3c0,4.1,3.7,7.5,8,7.5h60.5c4.3,0,8-3.5,8-7.5V29.3C100.2,29,99.8,28.3,99.5,28z M71.9,6.3l22,21.7
                                    h-22V6.3z M7,71.7V72c-1,0-2-0.9-2-1.9V41.2c0-0.9,1-1.9,2-1.9h54.6c1,0,2,0.9,2,1.9v28.6c0,0.9-1,1.9-2,1.9H7z M92.2,96.8H31.3
                                    c-2.3,0-4-1.6-4-3.8V75.8h34.3c3.3,0,6-2.5,6-5.7V41.2c0-3.1-2.7-5.7-6-5.7H27.3V8.9c0-2.2,1.7-3.8,4-3.8h36.3v24.8
                                    c0,0.9,1,1.9,2,1.9h26.3v61.3h0.3C96.2,95.3,94.5,96.8,92.2,96.8z M20.7,48.1c-0.7-0.3-2-0.3-4.3-0.3H11v16.3h3.7v-6.3H17
                                    c1.7,0,2.7,0,3.7-0.3c0.7,0,1.3-0.3,1.7-0.6c0.7-0.3,1-0.9,1.3-1.6c0.3-0.6,0.7-1.6,0.7-2.5c0-1.3-0.3-2.2-1-3.1
                                    C22.7,48.8,21.7,48.4,20.7,48.1z M20.3,54.1c-0.3,0.3-0.7,0.6-1,0.9c-0.3,0.3-1.3,0.3-2.7,0.3h-2.3v-4.7H16c1.3,0,2,0,2.7,0
                                    c0.7,0,1,0.3,1.3,0.6c0.3,0.3,0.7,0.9,0.7,1.6C20.7,53.5,20.3,53.8,20.3,54.1z M37.6,54.4h-7v-6.6H27v16.3h3.7v-6.9h7v6.9H41V47.8
                                    h-3.3V54.4z M54.3,48.1c-0.7-0.3-2-0.3-4.3-0.3h-5.7v16.3h3.7v-6.3h2.3c1.7,0,2.7,0,3.7-0.3c0.7,0,1.3-0.3,1.7-0.6
                                    c0.7-0.3,1-0.9,1.3-1.6c0.3-0.6,0.7-1.6,0.7-2.5c0.3-1.3,0-2.2-0.7-3.1C56.3,48.8,55.3,48.4,54.3,48.1z M53.9,54.1
                                    c-0.3,0.3-0.7,0.6-1,0.9c-0.3,0.3-1.3,0.3-2.7,0.3h-2v-4.7h1.7c1.3,0,2,0,2.7,0c0.7,0,1,0.3,1.3,0.6c0.3,0.3,0.7,0.9,0.7,1.6
                                    C54.3,53.5,54.3,53.8,53.9,54.1z"
											></path>
										</g>
									</g></svg></span
							>PHP Web Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/web-development/wordpress-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M73.1,26.6c-0.9,0.6-0.9,1.9-0.3,2.8c5.3,6.3,1.6,17.5,1.3,18.8l-9.7,27.5L49.9,34.4h5.4
                                    c1.3,0,1.9-0.9,1.9-1.9c0-0.9-0.6-1.9-1.9-1.9h-7.7c-0.4-0.1-0.7-0.1-1.1,0h-7.2c-0.9,0-1.9,0.9-1.9,1.9c0,0.9,0.6,1.9,1.9,1.9
                                    h6.4l4.4,12.8l-9.9,28.4L25.9,34.4h5.7c1.3,0,1.9-0.9,1.9-1.9c0-0.9-0.6-1.9-1.9-1.9h-8c-0.4-0.1-0.7-0.1-1.1,0h-6.9
                                    c-0.9,0-1.9,0.9-1.9,1.9c0,0.9,0.6,1.9,1.9,1.9h6.1L37.8,81c0.3,0.6,0.9,1.3,1.9,1.3h0.9c0.9,0,1.6-0.3,1.9-1.3l9.8-27.7L61.9,81
                                    c0.3,0.6,0.9,1.3,1.9,1.3h0.9c0.6,0,1.6-0.6,2.5-0.9l10.9-31.6c0.6-2.5,4.4-15-2.2-22.8C75.3,26,74.1,26,73.1,26.6z M50,0
                                    C22.5,0,0,22.2,0,50c0,27.8,22.2,50,50,50c27.8,0,50-22.2,50-50C100,22.2,77.5,0,50,0z M50,95.7C24.7,95.7,4.4,75.4,4.4,50
                                    C4.4,24.7,24.7,4.4,50,4.4C75.3,4.4,95.6,25,95.6,50S75,95.7,50,95.7z"
											></path>
										</g>
									</g></svg></span
							>Wordpress Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/web-development/codeigniter-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M88.7,54.5c-3.4-8.4-13.6-15.5-18-18.3c-4.3-2.8-4,1.9-4,1.9c-0.6,2.2-1.9,2.8-2.8,3.1c-2.2,0.3-5-0.6-5.3-4
                               c-0.3-3.4,0-3.7,1.2-8.4c1.2-5,0.3-10.8-0.3-14.2c-0.9-3.1-2.8-5.9-7.7-11.8c-5-5.9-5.9-0.6-5.9-0.6c3.7,13.9-10.5,22.9-14.6,26.6
                               C10.6,46.1,10,57.9,8.8,64.7C7.8,95,35.4,99.1,35.4,99.1c3.1-0.9,1.9-3.1,1.5-3.7c-0.6-0.3-4-3.7-5.3-5.3c-5-5.3-4.6-9.6-4.3-10.2
                               c0.6-4.6,4.3-9.6,4.6-10.2c0,1.9,0.3,3.1,1.2,4.6c3.4,5,9.6,3.7,9.6,3.7c7.1-1.5,6.8-8.7,6.8-11.1c0-2.5-3.4-6.2-4.3-6.8
                               c-1.9-1.5-3.4-4.3-3.4-4.3c-1.2-3.1,0-6.2,0.3-7.1c1.5,3.7,10.2,8.7,10.2,8.7s3.7,2.2,11.8,8.7c7.7,6.5,5.6,13,5.3,15.8
                               c-1.5,6.5-7.7,10.5-9.6,11.8c-4.3,3.1-1.2,4.6,0.3,5c13.6-4,21.7-10.2,27.6-20.7C94,67.2,90.2,57.9,88.7,54.5z M71,90.4
                               c1.9-3.1,5.6-9.6,3.1-18.3c-0.9-2.2-3.7-9-16.1-16.4c-4.6-3.1-10.8-6.5-11.8-9c-0.3-1.5-0.9-1.9-0.3-3.4c0-0.3-0.3-2.8-3.1-1.9
                               c-0.6,0.6-7.7,8-4.3,16.4c0.6,0.9,3.7,5,5,6.2s4,3.4,0.6,9c-0.3,0.3-2.2,2.5-6.5-0.3c-0.9-0.9-2.2-2.5-0.9-6.2
                               c0.6-1.9,0.6-2.5,0.6-2.5s0.3-2.5-2.5-2.2c0,0-4.6,3.1-5.9,5.3c-0.9,1.2-3.7,4.6-4.6,7.7c-0.6,2.5-3.7,8.4,3.7,17
                               c-0.9-0.3-15.2-6.8-15.2-25.4c0.6-5.9,1.9-15.2,11.2-25.4c3.7-3.7,15.8-14.5,16.7-15.5c0.9-0.9,9.6-8.7,9.6-18
                               c0.9,0.9,5.3,5.6,5.6,10.8c0.3,5.3,0.3,8.4-0.9,11.1c-0.6,3.1-2.5,9.3,0.9,12.7c1.2,1.2,6.5,6.2,12.4,0.6c0.9-0.9,1.9-2.5,1.9-2.5
                               s15.2,9.9,16.4,20.7C87.5,64.7,90.6,77.7,71,90.4z"
									></path></svg></span
							>Codeigniter Development</router-link
						>
					</li>
					<li>
						<router-link to="/web-development/laravel-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M99.7,46.4c-0.6-0.7-9.8-13-11.4-15c-1.6-2-2.5-1.7-3.5-1.7c-0.9,0-12.6,2.3-13.9,2.3c-1.3,0.3-2.2,0.7-1.3,2
                               c0.6,1,8.2,12.4,10.1,15l-30.3,7.7L25.5,14c-0.9-1.7-1.3-2-3.5-2C19.8,12,3,13.7,1.8,13.7c-1.3,0-2.5,0.7-1.3,3.7
                               c1.3,3,20.2,46.4,20.8,47.7c0.6,1.3,1.9,3.3,5.1,2.7c3.5-1,14.8-4,21.2-5.7c3.5,6.3,10.1,19,11.4,21c1.6,2.7,2.8,2,5.4,1.3
                               c1.9-0.7,31.3-11.7,32.5-12.4c1.3-0.7,2.2-1,1.3-2.3c-0.6-1-8.2-12-12.3-17.7c2.8-0.7,12.6-3.7,13.6-4
                               C100,47.7,100.3,47.1,99.7,46.4L99.7,46.4z M44.4,58.1c-0.3,0-17.4,4.3-18.3,4.7c-0.9,0.3-0.9,0-0.9-0.3C24.8,62.1,4.9,18,4.6,17.7
                               c-0.3-0.7-0.3-1,0-1c0.3,0,15.8-1.3,16.4-1.3s0.6,0,0.6,0.7c0,0,22.4,40.7,22.7,41.4C44.7,57.7,44.7,58.1,44.4,58.1L44.4,58.1z
                               M92.4,67.8c0.3,0.7,0.6,0.7-0.3,1S63.3,79.1,62.7,79.4c-0.6,0.3-0.9,0.3-1.6-0.7c-0.6-1-9.8-17.7-9.8-17.7L81,52.7
                               c0.6-0.3,0.9-0.3,1.6,0.3C82.6,54.1,92.1,67.4,92.4,67.8L92.4,67.8z M94.3,45.4c-0.6,0-11.7,3-11.7,3l-8.8-13c-0.3-0.3-0.6-0.7,0-1
                               c0.6-0.3,10.7-2,11.4-2c0.6,0,0.9-0.3,1.6,0.7c0.6,0.7,8.2,11,8.5,11.4C95.2,45.1,94.9,45.4,94.3,45.4L94.3,45.4z"
									></path></svg></span
							>Laravel Development</router-link
						>
					</li>
					<li>
						<router-link to="/web-development/angularjs-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M50,0L0,17.9l10.3,60.7L50,100l39.7-21.4L100,17.9L50,0z M83.2,74L50,91.8L16.7,74L8,22.6L50,7.1l42,15.4
                                  L83.2,74z M21.4,71.4h8.9l5.9-12.5h27.4l5.9,12.5h8.9L50,8.9L21.4,71.4z M40.5,50L50,30.1L59.5,50H40.5z"
											></path>
										</g>
									</g></svg></span
							>AngularJS Development</router-link
						>
					</li>
					<li>
						<router-link to="/web-development/nodejs-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M95.3,27.6c-0.4-2.1-1.7-4.1-3.6-5.3c-0.5-0.3-9.2-5.3-19.4-11.1C61.1,4.8,53.6,0.6,52.9,0.4
                                    c-1.4-0.5-3.6-0.5-5,0c-0.6,0.2-8.4,4.6-20.1,11.2c-17.1,9.7-19.2,11-20,11.8c-1.1,1.1-2,2.7-2.2,4.2c-0.1,0.6-0.2,8.5-0.2,22.5
                                    c0,23.4,0,22.6,0.9,24.5c0.3,0.6,0.8,1.3,1.5,2c1,1,1.7,1.4,6.3,4c8.3,4.7,9.8,5.2,14.1,5.1c2.3-0.1,3.4-0.3,4.9-1
                                    c2.8-1.3,4.7-4.1,5.3-7.6c0.1-0.7,0.2-8.4,0.2-22.9c0-21.7,0-22-0.3-22.3c-0.3-0.3-0.5-0.3-3.1-0.3c-2.6,0-2.8,0-3.1,0.3
                                    c-0.3,0.3-0.3,0.5-0.3,21.7c0,23.8,0.1,22.3-1.1,23.7c-0.9,1.1-2,1.7-3.7,1.7c-2.3,0.1-2.3,0.1-12.6-5.8c-1.2-0.7-2-1.3-2.1-1.5
                                    c-0.1-0.3-0.2-6.5-0.2-21.7V28.5l0.4-0.4C13,27.6,50,6.6,50.5,6.6c0.4,0,37.4,21,37.9,21.5c0.3,0.3,0.3,0.5,0.3,21.7
                                    c0,15.4,0,21.5-0.1,21.8c-0.1,0.3-3.1,2.1-15.2,9c-24.2,13.8-22.7,13-23.2,12.9c-0.2,0-2.6-1.4-5.2-2.9c-2.7-1.6-5-2.8-5.2-2.8
                                    c-0.2,0-1,0.3-1.8,0.7c-0.8,0.4-2.1,1-3.1,1.4c-1.9,0.7-2.2,1-1.7,1.4c0.5,0.5,13.4,8,14.5,8.4c1.3,0.5,3.8,0.6,5.2,0.1
                                    c0.5-0.2,4.1-2.2,8.1-4.4c3.9-2.2,10.3-5.9,14.2-8.1c3.8-2.2,7.9-4.5,8.9-5.1c1.1-0.6,3.1-1.8,4.6-2.6c3.3-1.9,3.7-2.2,4.6-3.1
                                    c0.9-0.9,1.3-1.6,1.8-2.8l0.3-0.9l0-22.1C95.5,34.9,95.4,28.3,95.3,27.6z M64.7,67.1c-6,0.2-10.5-0.6-12.7-2.2
                                    c-1.5-1.1-2.3-2.5-2.9-5.1c-0.4-1.9-0.3-1.8-4.1-1.8c-3.4,0-3.5,0-3.8,0.8c-0.2,0.5,0,3,0.4,4.4c0.3,1.1,1.2,3.2,1.9,4.2
                                    c2.3,3.3,6.9,5.6,13,6.6c2.5,0.4,8.8,0.5,11.6,0.2c3.8-0.4,6.9-1.4,9.6-2.9c1.5-0.8,3.9-3.1,4.7-4.5c2.1-3.5,2.3-8.9,0.5-12.3
                                    c-0.7-1.2-2.4-3-3.7-3.7c-3.2-1.7-6.5-2.5-15.2-3.8c-10.3-1.4-12.5-2.2-13-4.3c-0.2-0.9-0.1-2.3,0.3-3.2c0.7-1.5,2.5-2.6,5-3.2
                                    c1.8-0.4,6.6-0.6,9.1-0.3c5.4,0.6,7.8,2.3,9.1,6.6c0.3,0.9,0.5,1.4,0.7,1.5c0.2,0.1,1.6,0.2,3.4,0.2h3.1l0.4-0.4
                                    c0.4-0.4,0.4-0.4,0.3-1.5c-0.2-2.6-1.3-5.4-2.6-7.3c-2.5-3.4-7-5.6-13-6.2c-1.6-0.2-6.2-0.3-7.3-0.2c-3.7,0.3-6.3,0.8-8.6,1.7
                                    c-3.3,1.3-5.7,3.4-7.1,6.1c-0.9,1.7-1.1,2.5-1.1,4.9c-0.1,2.7,0.1,4,1.1,5.8c1.8,3.5,5.7,5.6,12.7,6.7c1.1,0.2,3.5,0.5,5.4,0.7
                                    c6.3,0.7,9.5,1.3,11.6,2.4c1.8,0.9,2.2,1.5,2.3,3.3C76.1,64.9,72.8,66.8,64.7,67.1z"
											></path>
										</g>
									</g></svg></span
							>NodeJS Development</router-link
						>
					</li>
				</ul>
			</div>
			<div class="col-3">
				<router-link to="/game-development" class="a-t px-4 py-2 cc-h fxt-srvs-header">
					<svg
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
						class=""
					>
						<g>
							<g>
								<path
									d="M61.1,94.9c0,1.2-0.9,2.1-2.1,2.1H15.8c-1.1,0-2.1-0.9-2.1-2.1V84h42.1v-3H13.7V5.1C13.7,4,14.6,3,15.8,3H59
                                  c1.1,0,2.1,0.9,2.1,2.1v7.6H19.3v3h41.9v9h3V5.1C64.1,2.3,61.8,0,59,0H15.8c-2.8,0-5.1,2.3-5.1,5.1v89.7c0,2.8,2.3,5.1,5.1,5.1H59
                                  c2.8,0,5.1-2.3,5.1-5.1V63.5h-3V94.9z M33.7,6.3v3h7.5v-3H33.7z M32.5,90.6c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9
                                  c0-2.7-2.2-4.9-4.9-4.9C34.7,85.7,32.5,87.9,32.5,90.6z M39.3,90.6c0,1.1-0.8,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9
                                  c0-1.1,0.8-1.9,1.9-1.9C38.5,88.7,39.3,89.6,39.3,90.6z M34,43.8c0.8,0,1.5-0.7,1.5-1.5v-4.6c0-0.8-0.7-1.5-1.5-1.5
                                  c-0.8,0-1.5,0.7-1.5,1.5v4.6C32.5,43.2,33.1,43.8,34,43.8z M34,45.9c-0.8,0-1.5,0.7-1.5,1.5V52c0,0.8,0.7,1.5,1.5,1.5
                                  c0.8,0,1.5-0.7,1.5-1.5v-4.6C35.5,46.5,34.8,45.9,34,45.9z M41,43.3h-4.6c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H41
                                  c0.8,0,1.5-0.7,1.5-1.5S41.8,43.3,41,43.3z M33,44.9c0-0.8-0.7-1.5-1.5-1.5h-4.6c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h4.6
                                  C32.3,46.4,33,45.7,33,44.9z M72.5,28.6H34c-9.3,0-16.8,7.7-16.8,17.1c0,9.4,7.5,17.1,16.8,17.1c9.3,0,16.8-7.7,16.8-17.1v-0.1
                                  h4.8v0.1c0,9.4,7.5,17.1,16.8,17.1c9.3,0,16.8-7.7,16.8-17.1C89.3,36.3,81.7,28.6,72.5,28.6z M72.5,59.7c-7.6,0-13.8-6.3-13.8-14
                                  c0-0.5,0-1,0.1-1.5l0.2-1.7H47.6l0.2,1.7c0,0.5,0.1,1,0.1,1.5c0,7.7-6.2,14-13.8,14c-7.6,0-13.8-6.3-13.8-14c0-7.7,6.2-14,13.8-14
                                  h17.8V35h-3.3v3h9.5v-3h-3.3v-3.4h17.7c7.6,0,13.8,6.3,13.8,14C86.3,53.4,80.1,59.7,72.5,59.7z M76.5,37.7c0-2.3-1.8-4.1-4-4.1
                                  c-2.2,0-4,1.8-4,4.1s1.8,4.1,4,4.1C74.7,41.8,76.5,40,76.5,37.7z M72.5,38.8c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1
                                  c0.6,0,1,0.5,1,1.1C73.5,38.3,73,38.8,72.5,38.8z M66.4,45.1c-2.2,0-4,1.8-4,4.1c0,2.3,1.8,4.1,4,4.1s4-1.8,4-4.1
                                  C70.4,47,68.6,45.1,66.4,45.1z M66.4,50.3c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1c0.6,0,1,0.5,1,1.1
                                  C67.4,49.8,66.9,50.3,66.4,50.3z M78.7,45.1c-2.2,0-4,1.8-4,4.1c0,2.3,1.8,4.1,4,4.1c2.2,0,4-1.8,4-4.1
                                  C82.7,47,80.9,45.1,78.7,45.1z M78.7,50.3c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1c0.6,0,1,0.5,1,1.1
                                  C79.8,49.8,79.3,50.3,78.7,50.3z"
									class=""
								></path>
							</g>
						</g>
					</svg>
					<h5 class="px-2">Game Development</h5>
				</router-link>
				<ul class="serv-ul">
					<li class="">
						<router-link to="/game-development/ios-game-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M49.7,25.7c0.4,0,0.8,0,1.1,0c5.3,0,11-2.9,14.8-7.5c4-4.8,6.1-11,5.5-16.6c-0.1-1-1-1.7-2-1.7
                                  c-5.4,0.2-11.8,3.5-15.6,7.9c-3.3,3.7-6.4,9.9-5.6,16.2C48.1,24.9,48.8,25.6,49.7,25.7z M56.5,10.3c2.7-3.1,7.1-5.6,11-6.3
                                  c-0.1,4.1-1.8,8.4-4.7,11.9c-2.9,3.5-7.2,5.8-11.1,6.1C51.7,17.4,54.1,12.9,56.5,10.3z M90.1,70c-1.2-0.5-11.7-5.1-11.8-17.5
                                  c-0.1-9.9,7.2-15.1,9.6-16.6c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4C82.3,24.5,73,23.2,69.4,23.1c-5-0.5-9.9,1.4-13.6,2.8
                                  c-2,0.8-4,1.5-5.1,1.5c-1.3,0-3.2-0.8-5.3-1.6c-3.1-1.2-6.6-2.6-10.4-2.6l-0.3,0c-8.8,0.1-17.1,5.1-21.6,12.9
                                  c-8.5,14.6-3.2,36.6,6.2,50C23,91.6,28.3,99,35.7,99l0.4,0c3.2-0.1,5.4-1.1,7.6-2c2.4-1,4.6-1.9,8.2-1.9c3.4,0,5.5,0.9,7.7,1.8
                                  c2.2,1,4.6,1.9,8,1.9l0.3,0c7.7-0.1,12.5-7,16.4-12.5c4.1-6,6.2-11.7,6.9-14C91.5,71.4,91,70.4,90.1,70z M81.1,84.3
                                  c-4.2,6.1-7.9,10.8-13.3,10.9l-0.3,0c-2.6,0-4.4-0.8-6.4-1.6c-2.4-1-5.1-2.2-9.2-2.2c-4.4,0-7.2,1.2-9.7,2.2
                                  c-2,0.9-3.8,1.6-6.3,1.7l-0.3,0c-5,0-9.2-5.3-13.4-11.3c-11.7-16.6-11.8-36.1-6-46c3.9-6.7,10.9-10.9,18.4-11l0.2,0
                                  c3.1,0,6.2,1.2,9,2.3c2.5,1,4.7,1.8,6.7,1.8c1.8,0,4-0.8,6.5-1.8c3.2-1.2,6.8-2.6,10.5-2.6c0.4,0,0.9,0,1.5,0.1
                                  c2.9,0.1,10,1.1,15.1,7.1c-3.5,2.6-9.7,8.6-9.6,18.6c0.1,12.4,8.8,18.3,12.6,20.2C86.2,75.3,84.3,79.7,81.1,84.3z"
											></path>
										</g>
									</g></svg></span
							>iOS Game Development</router-link
						>
					</li>
					<li>
						<router-link to="/game-development/android-game-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M65.3,9l2.9-4.3c0.5-0.7,0.6-1.5,0.5-2.3c-0.2-0.8-0.6-1.5-1.3-1.9C66-0.4,64,0,63.1,1.3l-3.4,5.1
                              c-6.2-2-13.2-2-19.4,0l-3.4-5.1C36,0,34-0.4,32.7,0.5c-1.4,0.9-1.8,2.8-0.8,4.2L34.7,9c-7.1,4.2-11.5,11.4-11.5,18.9
                              c0,1.2,1,2.1,2.2,2.1h49.6c1.2,0,2.2-1,2.2-2.1C77,20.4,72.5,13.2,65.3,9L65.3,9z M27.6,25.8c0.8-5.9,5-11.2,11.1-14
                              c0.3-0.1,0.5-0.3,0.7-0.5c0.3,0,0.6,0,0.8-0.1c6.1-2.4,13.4-2.4,19.5,0c0.3,0.1,0.6,0.2,0.8,0.1c0.2,0.2,0.4,0.4,0.7,0.5
                              c6.2,2.9,10.4,8.2,11.3,14H27.6z M74.8,32.2H25.2c-1.2,0-2.2,1-2.2,2.2v39.9c0,4.3,3.5,7.8,7.8,7.8h1.7v10.5c0,4.1,3.3,7.5,7.5,7.5
                              c4.1,0,7.5-3.3,7.5-7.5V82h5.1v10.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5V82h1.7c4.3,0,7.8-3.5,7.8-7.8V34.4
                              C77,33.2,76,32.2,74.8,32.2L74.8,32.2z M72.7,74.3c0,1.9-1.5,3.5-3.5,3.5h-4.1c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.3-0.3,1.9
                              c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9
                              c-0.4-0.5-1-0.9-1.7-0.9h-9.9c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2
                              c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9c-0.4-0.5-1.1-0.9-1.7-0.9h-4.1c-1.9,0-3.5-1.6-3.5-3.5
                              V36.5h45.3V74.3z M14.2,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                              C21.6,36.5,18.3,33.2,14.2,33.2L14.2,33.2z M17.3,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                              c1.7,0,3.2,1.4,3.2,3.2V63.3z M85.8,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                              C93.3,36.5,90,33.2,85.8,33.2L85.8,33.2z M89,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                              c1.7,0,3.2,1.4,3.2,3.2V63.3z M60.3,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                              C62.8,17.3,61.7,16.2,60.3,16.2L60.3,16.2z M40.9,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                              C43.4,17.3,42.3,16.2,40.9,16.2L40.9,16.2z M40.9,16.2"
									></path></svg></span
							>Android Game Development</router-link
						>
					</li>
					<li class="">
						<router-link to="/game-development/2d-3d-game-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 64 64"
									style="enable-background: new 0 0 64 64"
									xml:space="preserve"
								>
									<g>
										<path
											d="M60.5,54.7c0-7.6,0-15.2,0-22.7c0,0,0,0,0,0c0-7.9,0-15.7,0-23.6c0-3.4-1.9-5-5-4.4c-4.3,0.9-8.7,2-13,2.9
                              c-3.6,0.7-7.1,1.9-10.8,1.9c-7.7-0.1-15.4,0-23,0c-3,0-4.6,1.6-4.6,4.6C4,25,4,36.7,4.2,48.4c0.1,3,1.4,4.3,4.4,4.3
                              c7.3,0,14.5,0.1,21.8,0c2.6,0,5,0.4,7.5,1.3c5.6,2,11.2,3.9,16.8,5.8c3.3,1.1,5.6-0.4,5.8-3.9C60.5,55.5,60.5,55.1,60.5,54.7z
                              M55.2,56c-6.7-2-13.4-3.9-20.1-5.9c-0.7-0.2-1.5-0.3-2.2-0.3c-7.9,0-15.8,0-23.7,0c-2,0-2.1-0.1-2.1-2.1c0-11.4,0-22.9,0-34.3
                              c0-1.7,0.1-1.8,1.9-1.8c7.8,0,15.5,0,23.3,0c3.4,0,6.7-0.9,10-1.5c4.5-0.7,8.9-1.5,13.4-2.4c1.2-0.2,1,0.5,1,1.2c0,7.7,0,15.3,0,23
                              c0,7.6,0,15.3,0,22.9C56.7,56.2,56.3,56.3,55.2,56z"
										></path>
										<path
											d="M46,24.3c-0.3,0.1-0.6,0.2-1,0.2c-0.8,0-1,0.4-0.9,1.2c0,2,0,4.1,0,6.1c0,1.6,0,3.2,0,4.7c0,2.6,0,2.6,2.5,3.1
                              c3,0.7,4.7-0.2,5.7-3.2c0.9-2.6,0.9-5.2,0.3-7.8C51.9,24.9,49.8,23.6,46,24.3z M50,34.8c-0.2,0.6-0.6,1-1.2,1.3
                              c-1.4,0.8-2.3,0.3-2.3-1.3c0-1,0-2,0-3.1c0-1.2,0-2.3,0-3.5c0-0.7,0.3-0.9,0.9-0.9c1.1,0,2,0.5,2.4,1.6
                              C50.8,30.9,50.8,32.9,50,34.8z"
										></path>
										<path
											d="M27.9,27.7c-1.7-0.8-3.5-0.7-5.3-0.6c-0.6,0-0.8,0.3-0.7,0.9c0,1.4,0,2.9,0,4.3c0,0,0,0,0,0c0,1.4,0,2.9,0,4.3
                              c0,0.3-0.1,0.8,0.5,0.8c2,0,4,0.3,5.9-0.7c1.9-1.1,2.7-2.7,2.6-4.9C30.7,29.8,29.7,28.5,27.9,27.7z M28.3,32.7
                              c-0.2,1.5-1.8,2.6-3.4,2.5c-0.5,0-0.6-0.3-0.6-0.7c0-0.8,0-1.6,0-2.4c0-0.5,0.1-1,0-1.5c-0.2-1.2,0.3-1.5,1.5-1.3
                              C27.5,29.6,28.5,30.9,28.3,32.7z"
										></path>
										<path
											d="M41.9,31.5c-0.2-0.7,0.9-1.6,0.8-2.6c0-0.1,0-0.3,0-0.8c-0.1-0.9-0.3-2.1-1.6-2.6c-1.2-0.5-2.3,0-3.2,0.8
                              c-0.4,0.3-0.7,0.7-1.1,1.1c-0.3,0.3-0.2,0.6,0,0.9c0.6,0.8,1,0.8,1.7,0.1c0.4-0.5,1-0.8,1.6-0.3c0.5,0.4,0.4,1.1,0.2,1.7
                              c-0.2,0.4-0.6,0.7-1,0.7c-0.8,0.1-1,0.5-1.1,1.3c0,0.9,0.5,0.9,1.1,1c0.6,0.1,1.1,0.5,1.1,1.2c0,0.5,0,1-0.5,1.4
                              c-0.6,0.4-1.1,0-1.6-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.2-0.6-1.1-1.1-0.2c-0.3,0.6-1.3,1.1-0.3,1.8c0.4,0.3,0.8,0.7,1.3,1
                              c1.2,0.7,2.4,1.4,3.8,0.5c1.3-0.9,1.4-2.3,1.2-3.8C42.8,33.1,42.2,32.3,41.9,31.5z"
										></path>
										<path
											d="M19.1,35.2c-0.8,0-1.6,0-2.7,0c1-1.1,1.9-1.9,2.5-2.9c1.1-1.9,0.8-4.1-0.8-5.1c-1.7-1.1-3.9-0.6-5.2,1.1
                              c-0.2,0.3-0.5,0.8-0.1,0.9c0.7,0.3,1.4,1.2,2.2,0.1c0.4-0.5,0.9-0.6,1.5-0.4c0.5,0.2,0.8,0.5,0.9,1c0.1,0.6-0.3,1.1-0.6,1.5
                              c-1.3,1.6-2.6,3.2-3.9,4.7c-0.2,0.3-0.5,0.6-0.3,1c0.2,0.4,0.6,0.2,0.9,0.2c1.9,0,3.8,0,5.7,0c1.1,0,0.8-0.8,0.9-1.3
                              C20.2,35.4,19.8,35.2,19.1,35.2z"
										></path>
										<path
											d="M38.4,20.9c-0.2,0.2-0.6,0.3-0.6,0.7c1.6,0.4,3.3,0.7,5,1.1c-0.1-0.5-0.1-0.8-0.1-1.2c-0.3-1.3-0.6-2.7-1-4
                              c-1.4,1-1.4,1-3,0.1c-4.6-2.5-9.2-2.4-13.6,0.5c-0.7,0.4-1.1,0.6-1.6-0.1c-0.1-0.2-0.3-0.5-0.7-0.4c-0.4,1.7-0.8,3.3-1.2,5.2
                              c1.2-0.3,2.2-0.6,3.3-0.8c0.4-0.1,0.9-0.2,1.3-0.3c1.2-0.1,0.6-0.5,0.1-0.8c-0.5-0.5-0.3-0.9,0.3-1.2c3.5-2.3,8.1-2.2,11.7,0.1
                              C38.9,20.1,38.9,20.4,38.4,20.9z"
										></path>
									</g></svg></span
							>2D/3D Game Development</router-link
						>
					</li>
					<li>
						<router-link to="/game-development/augmented-reality-game-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 32 32"
									style="enable-background: new 0 0 32 32"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M17.7,28.9h-2.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h2.6c0.3,0,0.5-0.2,0.5-0.5
                                C18.2,29.1,18,28.9,17.7,28.9z M10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.5,10,12.5,10,12.4
                                C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4L10,12.4C10,12.5,10,12.5,10,12.4L10,12.4
                                C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4L10,12.4
                                C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2v0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
                                c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1l6,2.1c0,0,0.1,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0l6-2.1
                                c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0v-7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l-6-2.1c-0.1,0-0.2,0-0.3,0l-6,2.1C10.1,12.4,10.1,12.4,10,12.4
                                C10.1,12.4,10.1,12.4,10,12.4C10.1,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4L10,12.4
                                C10,12.4,10,12.4,10,12.4z M21.9,19.5l-3.1-1c-0.3-0.1-0.5,0-0.6,0.3c-0.1,0.2,0,0.5,0.3,0.6l2.4,0.8l-4.1,1.4v-6.3l5.1-1.7V19.5z
                                M16.4,11.2l4.6,1.6l-4.6,1.6l-4.6-1.6L16.4,11.2z M10.8,13.5l5.1,1.7v6.3l-4.1-1.4l2.4-0.8c0.3-0.1,0.4-0.4,0.3-0.6
                                c-0.1-0.2-0.4-0.4-0.6-0.3l-3,1V13.5z M26.7,0H6C5,0,4.1,0.8,4.1,1.8v28.4C4.1,31.2,5,32,6,32h20.7c1.1,0,1.9-0.8,1.9-1.8V1.8
                                C28.7,0.8,27.8,0,26.7,0z M27.7,30.2c0,0.5-0.4,0.9-0.9,0.9H6c-0.5,0-0.9-0.4-0.9-0.9V1.8c0-0.5,0.4-0.9,0.9-0.9h20.7
                                c0.5,0,0.9,0.4,0.9,0.9V30.2z M25.2,2.4H7.5C7,2.4,6.6,2.7,6.6,3.2v23.9C6.6,27.6,7,28,7.5,28h17.7c0.5,0,0.9-0.4,0.9-0.8V3.2
                                C26.1,2.7,25.7,2.4,25.2,2.4z M25.1,5.2H11.9c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h13.3V27H7.6V6.1h1.9
                                c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H7.6V3.3h17.5V5.2z"
											></path>
										</g>
									</g></svg></span
							>AR Game Development</router-link
						>
					</li>
					<li>
						<router-link to="/game-development/virtual-reality-game-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 32 32"
									style="enable-background: new 0 0 32 32"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M11.2,14.9h2.6c0.6,0,1.1-0.3,1.3-0.8c0.1-0.1,0.2-0.2,0.4-0.2h0.9c0.2,0,0.3,0.1,0.4,0.2
                                c0.3,0.5,0.8,0.8,1.3,0.8h2.6c1.2,0,2.1-1,2.1-2.1v-2.1c0-1.2-1-2.1-2.1-2.1h-9.6c-1.2,0-2.1,1-2.1,2.1v2.1
                                C9.1,14,10,14.9,11.2,14.9z M10.1,10.7c0-0.6,0.5-1.1,1.1-1.1h9.6c0.6,0,1.1,0.5,1.1,1.1v2.1c0,0.6-0.5,1.1-1.1,1.1h-2.6
                                c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.5-0.8-0.8-1.3-0.8h-0.9c-0.6,0-1.1,0.3-1.3,0.8c-0.1,0.1-0.2,0.2-0.4,0.2h-2.6
                                c-0.6,0-1.1-0.5-1.1-1.1V10.7z M30.4,26c-2.5-1.3-6.8-2.8-9.6-3.4c2.3-2,3.7-5.2,3.7-8.2h0.5c0.6,0,1.1-0.5,1.1-1.1v-3.2
                                c0-0.6-0.5-1.1-1.1-1.1h-0.5c0-0.3-0.1-0.6-0.2-0.9C23.6,4.2,20.3,0,16,0c-4.3,0-7.6,4.2-8.3,8.2C7.5,8.5,7.5,8.8,7.5,9.1H6.9
                                c-0.6,0-1.1,0.5-1.1,1.1v3.2c0,0.6,0.5,1.1,1.1,1.1h0.5c0,3,1.4,6.2,3.7,8.2c-2.8,0.6-7.1,2.1-9.6,3.4c-1,0.5-1.6,1.5-1.6,2.6v2.8
                                C0,31.8,0.3,32,0.5,32h30.9c0.3,0,0.5-0.2,0.5-0.5v-2.8C32,27.5,31.4,26.5,30.4,26z M24.5,10.1h0.5l0,3.2h-0.5V10.1z M7.5,13.3
                                H6.9v-3.2h0.5V13.3z M16,1.1c3.2,0,5.8,2.8,6.9,5.9c-0.2,0-0.3-0.1-0.5-0.1H9.6C9.4,6.9,9.2,7,9.1,7C10.2,3.9,12.8,1.1,16,1.1z
                                M8.5,9.1C8.5,8.5,9,8,9.6,8h12.8c0.4,0,0.8,0.3,1,0.6c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4v5.3c0,0.6-0.5,1.1-1.1,1.1H9.6
                                c-0.6,0-1.1-0.5-1.1-1.1V9.1z M8.7,16.3c0.3,0.1,0.6,0.2,0.9,0.2h12.8c0.3,0,0.6-0.1,0.9-0.2c-0.8,4.2-4.2,7.1-7.3,7.1
                                C12.9,23.5,9.6,20.5,8.7,16.3z M17.5,24.4l-1.3,1.7c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.2-0.1l-1.3-1.7c0.5,0.1,1,0.2,1.5,0.2
                                C16.5,24.5,17,24.5,17.5,24.4z M24.5,30.9h-3.2v-1.1h3.2V30.9z M30.9,30.9h-5.3v-1.6c0-0.3-0.2-0.5-0.5-0.5h-4.3
                                c-0.3,0-0.5,0.2-0.5,0.5v1.6H1.1v-2.3c0-0.7,0.4-1.4,1-1.7c2.8-1.4,7.7-3.1,10.3-3.5l0.1,0c0.1,0,0.1,0.1,0.2,0.1l2.3,3
                                c0.2,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5l2.3-3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1c2.6,0.4,7.5,2,10.3,3.5c0.6,0.3,1,1,1,1.7V30.9z
                                "
											></path>
										</g>
									</g></svg></span
							>VR Game Development</router-link
						>
					</li>
					<li>
						<router-link to="/game-development/mac-game-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 32 32"
									style="enable-background: new 0 0 32 32"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M3,14.8h2.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,14.8,3,14.8z"
									></path>
									<path
										class="st1"
										d="M3,12.8h1c0.3,0,0.5-0.2,0.5-0.5S4.3,11.8,4,11.8H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,12.8,3,12.8z"
									></path>
									<path
										class="st1"
										d="M3,16.8h1c0.3,0,0.5-0.2,0.5-0.5S4.3,15.8,4,15.8H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,16.8,3,16.8z"
									></path>
									<path
										class="st1"
										d="M26.5,14.8H29c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-2.5c-0.3,0-0.5,0.2-0.5,0.5S26.2,14.8,26.5,14.8z"
									></path>
									<path
										class="st1"
										d="M28,12.8h1c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-1c-0.3,0-0.5,0.2-0.5,0.5S27.7,12.8,28,12.8z"
									></path>
									<path
										class="st1"
										d="M29,15.8h-1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1c0.3,0,0.5-0.2,0.5-0.5S29.3,15.8,29,15.8z"
									></path>
									<path
										class="st1"
										d="M30.5,3.8h-29C0.7,3.8,0,4.4,0,5.3v18.5c0,0.8,0.7,1.5,1.5,1.5H14c0,0.3,0,0.5-0.1,0.8
                             c-0.2,0.4-0.5,0.8-0.8,1.1c0,0,0,0,0,0h-1.3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h8.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5
                             h-1.6c0,0,0,0,0,0c-0.3-0.4-0.5-0.7-0.8-1.1c-0.1-0.3-0.1-0.5-0.1-0.8h12.7c0.8,0,1.5-0.7,1.5-1.5V5.3C32,4.4,31.3,3.8,30.5,3.8z
                             M1.5,4.8h29C30.8,4.8,31,5,31,5.3v17H1v-17C1,5,1.2,4.8,1.5,4.8z M16.8,25.3c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.3,0.6,0.5,0.8h-3.2
                             c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.4,0.2-0.8,0.2-1.2H16.8z M30.5,24.3h-29C1.2,24.3,1,24,1,23.8v-0.5h30v0.5
                             C31,24,30.8,24.3,30.5,24.3z"
									></path>
									<path
										class="st1"
										d="M12.1,19.6c0.3,0.4,0.8,0.8,1.3,1c0.6,0.2,1.2,0.1,1.7-0.1c0.1,0,0.2-0.1,0.2-0.1c0.5-0.2,1.1-0.2,1.6,0
                             l0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.6,0.2,1.4,0.1,1.9-0.3c0.7-0.5,1.3-1.2,1.7-2c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.2,0.2-0.5,0.2-0.7
                             c0-0.1-0.1-0.2-0.3-0.3c-0.9-0.4-1.3-1-1.4-1.8s0.3-1.5,1.1-2c0.2-0.1,0.3-0.4,0.2-0.7c0,0,0,0,0-0.1l0,0c-0.8-1-2.1-1.5-3.3-1.3
                             c-0.3,0.1-0.6,0.1-0.9,0.2c-0.5,0.2-0.9,0.3-1.4,0.1l-0.3-0.1c-0.6-0.2-1.2-0.3-1.8-0.3c-0.8,0.1-1.6,0.4-2.2,1c-1,1-1.4,2.5-1,4.3
                             C10.6,17.4,11.2,18.6,12.1,19.6z M12.1,12.5c0.4-0.4,1-0.7,1.6-0.8h0.1c0.4,0,0.8,0.1,1.2,0.3l0.3,0.1c0.8,0.3,1.4,0,2-0.1
                             c0.2-0.1,0.5-0.2,0.8-0.2c0.7-0.1,1.5,0.1,2,0.6c-0.8,0.6-1.2,1.6-1.1,2.6c0.1,1,0.7,1.9,1.6,2.4l0,0c-0.5,1-1.1,1.7-1.6,2.1
                             c-0.3,0.2-0.7,0.3-1,0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.2-0.1c-0.8-0.3-1.6-0.3-2.4,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
                             c-0.7,0.1-1.2-0.5-1.4-0.8c-0.8-0.9-1.3-2-1.6-3.1C11.2,15.2,11,13.5,12.1,12.5L12.1,12.5z"
									></path>
									<path
										class="st1"
										d="M15.5,10.1c0.1,0.1,0.2,0.1,0.3,0.1h0.1c2.5-0.4,3-2.5,2.9-3.5c0-0.3-0.3-0.5-0.5-0.5c0,0,0,0-0.1,0
                             c-2.6,0.5-3,2.5-2.9,3.6C15.3,9.9,15.3,10.1,15.5,10.1z M17.7,7.4c-0.1,0.8-0.7,1.4-1.4,1.7C16.4,8.3,17,7.7,17.7,7.4L17.7,7.4z"
									></path>
									<circle class="st1" cx="22.3" cy="27.8" r="0.5"></circle>
									<circle
										class="st1"
										cx="9.8"
										cy="27.8"
										r="0.5"
									></circle></svg></span
							>Mac Game Development</router-link
						>
					</li>
				</ul>
			</div>
			<div class="col-3">
				<span  class="a-t px-4 py-2 cc-h fxt-srvs-header">
					<svg
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
						class=""
					>
						<g>
							<g>
								<path
									class="st1"
									d="M64.1,52.6h26.6c0.9,0,1.6-0.7,1.6-1.6v-31v-7.8c0-0.9-0.7-1.6-1.6-1.6H64.1c-0.9,0-1.6,0.7-1.6,1.6v7.8v31
                                  C62.5,52,63.2,52.6,64.1,52.6z M65.6,13.9h23.4v4.7H65.6V13.9z M65.6,21.6h23.4v27.9H65.6V21.6z M68.8,27.8h3.1v-3.1h-3.1V27.8z
                                  M75,27.8h10.9v-3.1H75V27.8z M68.8,34h3.1v-3.1h-3.1V34z M75,34h10.9v-3.1H75V34z M68.8,40.2h3.1v-3.1h-3.1V40.2z M75,40.2h10.9
                                  v-3.1H75V40.2z M68.8,46.4h3.1v-3.1h-3.1V46.4z M75,46.4h10.9v-3.1H75V46.4z M9.4,52.6h6.3c0.9,0,1.6-0.7,1.6-1.6v-1.6h21.9v1.6
                                  c0,0.9,0.7,1.6,1.6,1.6h6.3c0.9,0,1.6-0.7,1.6-1.6v-6.2c0-0.9-0.7-1.6-1.6-1.6h-1.6v-4.7h12.5c0.9,0,1.6-0.7,1.6-1.6v-9.3
                                  c0-0.9-0.7-1.6-1.6-1.6H45.3v-6.2h1.6c0.9,0,1.6-0.7,1.6-1.6v-6.2c0-0.9-0.7-1.6-1.6-1.6h-6.3c-0.9,0-1.6,0.7-1.6,1.6v1.6H17.2
                                  v-1.6c0-0.9-0.7-1.6-1.6-1.6H9.4c-0.9,0-1.6,0.7-1.6,1.6v6.2c0,0.9,0.7,1.6,1.6,1.6h1.6v23.3H9.4c-0.9,0-1.6,0.7-1.6,1.6v6.2
                                  C7.8,52,8.5,52.6,9.4,52.6z M45.3,49.5h-3.1v-3.1h3.1V49.5z M48.4,32.5v-3.1h3.1v1.6h3.1v-1.6h1.6v6.2H25v-6.2h1.6v1.6h3.1v-1.6
                                  h3.1v3.1h3.1v-3.1h3.1v1.6h3.1v-1.6h3.1v3.1H48.4z M42.2,13.9h3.1V17h-3.1V13.9z M17.2,18.5V17h21.9v1.6c0,0.9,0.7,1.6,1.6,1.6
                                  h1.6v6.2H23.4c-0.9,0-1.6,0.7-1.6,1.6v9.3c0,0.9,0.7,1.6,1.6,1.6h18.8v4.7h-1.6c-0.9,0-1.6,0.7-1.6,1.6v1.6H17.2v-1.6
                                  c0-0.9-0.7-1.6-1.6-1.6h-1.6V20.1h1.6C16.5,20.1,17.2,19.4,17.2,18.5z M10.9,13.9h3.1V17h-3.1V13.9z M10.9,46.4h3.1v3.1h-3.1V46.4
                                  z M96.9,4.5H3.1C1.4,4.5,0,5.9,0,7.6v49.7v10.9c0,1.7,1.4,3.1,3.1,3.1h37.2l-2.6,14h-4.9c-0.4,0-0.8,0.2-1.1,0.5l-3.1,3.1
                                  c-0.3,0.3-0.5,0.7-0.5,1.1V93c0,0.9,0.7,1.6,1.6,1.6h40.6c0.9,0,1.6-0.7,1.6-1.6v-3.1c0-0.4-0.2-0.8-0.5-1.1l-3.1-3.1
                                  c-0.3-0.3-0.7-0.5-1.1-0.5h-4.9l-2.6-14h37.2c1.7,0,3.1-1.4,3.1-3.1V57.3V7.6C100,5.9,98.6,4.5,96.9,4.5z M60.9,88.3h5.6l2.2,2.2
                                  v0.9H31.3v-0.9l2.2-2.2h5.6H60.9z M53.1,80.6v-3.1h-1.6l0-6.2h4.9l2.6,14H40.9l2.6-14h4.9l0,6.2h-1.6v3.1H53.1z M96.9,68.2H57.8
                                  H42.2H3.1v-9.3h93.8V68.2z M96.9,55.8H3.1V7.6h93.8V55.8z M54.7,62h-9.4v3.1h9.4V62z"
								></path>
							</g>
						</g>
					</svg>
					<h5 class="px-2">Enterprise Solutions</h5>
				</span>
				<ul class="serv-ul">
					<li class="">
						<router-link to="/startup-mvp-development"
							><span class="submenuicon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
									class=""
								>
									<g>
										<path
											d="M30,25.1c0,7.2,0,14.4,0,21.6c0,0.7-0.1,1.4-0.3,2c-0.4,0.9-1.2,1.2-2.2,1.2c-7.1,0-14.2,0-21.2,0c-1.6,0-2.5-1-2.5-2.5
                                  c0-15,0-30,0-44.9C3.7,1,4.7,0,6.2,0c7.1,0,14.2,0,21.2,0C29.1,0,30,1,30,2.8C30,10.3,30,17.7,30,25.1C30,25.1,30,25.1,30,25.1z
                                  M5.2,25.1c0,7.3,0,14.6,0,21.8c0,1.6,0,1.6,1.6,1.6c6.7,0,13.5,0,20.2,0c0.3,0,0.7,0,1-0.1c0.2-0.1,0.5-0.4,0.5-0.6
                                  c0.1-0.6,0.1-1.2,0.1-1.8c0-14.4,0-28.7,0-43.1c0-1.3-0.1-1.4-1.4-1.4c-6.9,0-13.7,0-20.6,0c-1.3,0-1.4,0-1.4,1.4
                                  C5.2,10.3,5.2,17.7,5.2,25.1z"
										></path>
										<path
											d="M7.3,8.5c0-0.5,0-0.9,0-1.4c6.4,0,12.9,0,19.3,0c0,0.5,0,0.9,0,1.4C20.1,8.5,13.7,8.5,7.3,8.5z"
										></path>
										<path
											d="M12.8,4.9c0-0.5,0-0.9,0-1.3c2.7,0,5.4,0,8.1,0c0,0.4,0,0.8,0,1.3C18.3,4.9,15.6,4.9,12.8,4.9z"
											class=""
										></path>
										<path
											d="M21.2,23.9c0-0.5,0-1,0-1.4c0-0.6,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.8,0.9c0,1.2,0,2.3,0,3.5c0,1.5,0,3.1,0,4.6
                                  c0,0.3,0,0.6-0.1,0.9c-0.2,0.6-0.8,0.8-1.2,0.4c-0.3-0.2-0.5-0.5-0.7-0.8c-0.6-0.8-1.2-1.5-1.8-2.3c-0.2-0.3-0.4-0.4-0.8-0.4
                                  c-0.9,0-1.7,0-2.6,0c-0.4,0-0.7,0.1-0.9,0.4c-0.7,0.8-1.3,1.7-2,2.5c-0.1,0.2-0.3,0.4-0.5,0.5c-0.5,0.4-1.1,0.2-1.3-0.4
                                  c-0.1-0.2-0.1-0.5-0.1-0.8c0-2.5,0-5,0-7.6c0-0.3,0-0.5,0-0.8c0.1-0.5,0.4-0.7,0.8-0.7c0.4,0,0.7,0.3,0.7,0.8c0,0.5,0,1,0,1.7
                                  c0.5-0.5,0.9-0.8,1.2-1.2c0.1-0.2,0.2-0.5,0.2-0.7c-0.1-1.1,0.2-2,0.7-3c0.5-1.1,0.9-2.2,1.4-3.3c0.1-0.2,0.1-0.4,0.3-0.6
                                  c0.4-0.6,1.1-0.6,1.5,0c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.4,1.2,2.8,1.8,4.2c0.2,0.5,0.3,1.1,0.3,1.7C19.9,22.9,20,23,21.2,23.9z
                                  M17,17.9c-0.1,0-0.1,0-0.2,0c-0.4,1-0.9,2.1-1.3,3.1c0,0.1,0,0.2,0,0.3c0,1.6,0,3.3,0,4.9c0,0.3,0.2,0.5,0.5,0.5
                                  c0.5,0,1.1,0,1.6,0c0.7,0,0.7,0,0.7-0.7c0-1.1-0.1-2.3,0-3.4C18.5,20.7,17.5,19.4,17,17.9z M12.5,28.7c1.2-1,1.6-2.1,1.2-3.6
                                  c-0.4,0.4-0.7,0.7-1,1c-0.1,0.1-0.2,0.4-0.2,0.5C12.5,27.3,12.5,28,12.5,28.7z M21.2,28.9c0-0.8,0-1.4,0-1.9c0-0.5-0.1-0.8-0.5-1.1
                                  c-0.3-0.2-0.5-0.4-0.8-0.6c0,0.3-0.1,0.7-0.1,1c0,0.4-0.1,0.9,0.2,1.2C20.5,27.9,20.8,28.3,21.2,28.9z"
										></path>
										<path
											d="M27.2,37.1c0,0.4-0.1,0.6-0.5,0.7c-0.2,0.1-0.4,0.1-0.7,0.1c-0.8,0-1.3,0.5-1.6,1.2c-0.1,0.3-0.1,0.6-0.2,0.9
                                  c-0.1,0.5-0.3,0.7-0.7,0.7c-0.4,0-0.8-0.2-0.9-0.7c-0.1-0.2,0-0.4-0.1-0.6c-0.1-0.9-0.7-1.5-1.6-1.6c-0.3,0-0.5-0.1-0.8-0.1
                                  c-0.4-0.1-0.6-0.4-0.6-0.8c0-0.3,0.2-0.6,0.6-0.7c0.2-0.1,0.3-0.1,0.5-0.1c1.2-0.2,1.7-0.7,1.9-1.9c0-0.2,0.1-0.4,0.1-0.6
                                  c0.2-0.4,0.5-0.5,0.9-0.4c0.3,0.1,0.6,0.3,0.6,0.6c0,0.3,0.1,0.6,0.2,0.9c0.3,0.7,0.8,1.2,1.6,1.3c0.2,0,0.5,0,0.7,0.1
                                  C27.1,36.4,27.2,36.6,27.2,37.1z M23.5,36.4c-0.2,0.3-0.5,0.5-0.5,0.7c0,0.2,0.3,0.4,0.5,0.6c0.2-0.2,0.5-0.4,0.6-0.7
                                  C24.1,36.8,23.7,36.6,23.5,36.4z"
										></path>
										<path
											d="M6.5,15.4c0.1-0.1,0.2-0.3,0.4-0.5c0.1-0.1,0.4-0.1,0.6-0.1c1.1-0.1,1.6-0.6,1.9-1.6c0-0.2,0-0.3,0.1-0.5
                                  c0.1-0.5,0.4-0.8,0.8-0.8c0.4,0,0.8,0.3,0.9,0.7c0,0.2,0,0.3,0,0.5c0.1,0.9,0.8,1.6,1.7,1.7c0.3,0,0.7,0.1,0.9,0.3
                                  c0.2,0.1,0.3,0.5,0.3,0.8c0,0.3-0.3,0.6-0.6,0.6c-0.1,0-0.3,0-0.4,0c-1.1,0.2-1.7,0.8-1.8,1.9c0,0.2-0.1,0.5-0.2,0.7
                                  c-0.2,0.3-0.5,0.4-0.8,0.3c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.1,0-0.3-0.1-0.4c-0.2-1.2-0.8-1.8-2-1.9c-0.1,0-0.2,0-0.3,0
                                  C6.7,16.3,6.5,16,6.5,15.4z M10.2,14.9c-0.2,0.2-0.6,0.5-0.6,0.6c0.1,0.3,0.4,0.5,0.6,0.7c0.2-0.2,0.5-0.4,0.5-0.6
                                  C10.8,15.4,10.5,15.2,10.2,14.9z"
											class=""
										></path>
										<g>
											<path
												d="M16.8,43.7c0.9,0,1.8,0.9,1.7,1.8c0,0.9-0.9,1.7-1.8,1.7c-0.9,0-1.7-0.8-1.7-1.7C15.1,44.5,15.9,43.7,16.8,43.7z
                                    M16.9,44.7c-0.4,0-0.7,0.2-0.7,0.6c0,0.4,0.2,0.7,0.6,0.7c0.4,0,0.7-0.2,0.7-0.7C17.5,45,17.3,44.7,16.9,44.7z"
											></path>
										</g>
										<path
											d="M15.4,36.3c0,0.3,0,0.6,0,0.9c0,0.4-0.3,0.6-0.7,0.7c-0.4,0-0.8-0.1-0.8-0.6c-0.1-0.6-0.1-1.2,0-1.8c0-0.4,0.4-0.7,0.8-0.6
                                  c0.4,0,0.7,0.3,0.7,0.7C15.4,35.8,15.4,36,15.4,36.3C15.4,36.3,15.4,36.3,15.4,36.3z"
										></path>
										<path
											d="M15.4,32.3c0,0.7-0.2,1.1-0.7,1.1c-0.5,0-0.9-0.4-0.9-1.2c0-0.1,0-0.3,0-0.4c0.1-0.5,0.4-0.7,0.8-0.7
                                  c0.4,0,0.7,0.3,0.7,0.8C15.4,32,15.4,32.2,15.4,32.3z"
										></path>
										<path
											d="M18.3,32.3c0-0.8,0.3-1.2,0.8-1.2c0.5,0,0.8,0.5,0.8,1.2c0,0.1,0,0.3-0.1,0.4c-0.1,0.4-0.4,0.7-0.8,0.7
                                  c-0.4,0-0.7-0.3-0.7-0.8C18.3,32.5,18.3,32.4,18.3,32.3z"
										></path>
										<path
											d="M45.4,32c-0.5-0.3-1-0.6-1.4-0.9c0.1-1,0.1-2,0-2.9c0.5-0.3,1-0.6,1.4-0.8c0.8-0.5,1.1-1.5,0.8-2.3c0,0,0,0,0,0l-1-2.5
                                  c-0.4-0.9-1.3-1.3-2.2-1.1c-0.5,0.1-1.1,0.3-1.6,0.4c-0.6-0.8-1.3-1.5-2.1-2.1c0.1-0.5,0.3-1.1,0.4-1.6c0.2-0.9-0.3-1.8-1.1-2.2
                                  l-2.5-1c-0.9-0.4-1.9,0-2.3,0.8c-0.3,0.5-0.6,1-0.9,1.4C32.4,17,31.9,17,31.5,17c0,0.7,0,1.3,0,2c0.4,0,0.8,0,1.2,0.1
                                  c0.8,0.1,1.5-0.3,1.9-0.9c0.3-0.5,0.5-0.9,0.8-1.4l2.3,1c-0.1,0.5-0.3,1-0.4,1.6c-0.2,0.7,0.1,1.5,0.7,2c0.7,0.5,1.3,1.2,1.8,1.8
                                  c0.5,0.6,1.2,0.9,2,0.7c0.5-0.1,1-0.3,1.6-0.4l1,2.3c-0.5,0.3-0.9,0.5-1.4,0.8c-0.7,0.4-1,1.1-0.9,1.9c0.1,0.9,0.1,1.7,0,2.6
                                  c-0.1,0.8,0.3,1.5,0.9,1.9c0.5,0.3,0.9,0.5,1.4,0.8l-1,2.3c-0.5-0.1-1-0.3-1.6-0.4c-0.7-0.2-1.5,0.1-2,0.7
                                  c-0.5,0.7-1.2,1.3-1.8,1.8c-0.6,0.5-0.9,1.2-0.7,2c0.1,0.5,0.3,1,0.4,1.6l-2.3,1c-0.3-0.5-0.5-0.9-0.8-1.4c-0.4-0.7-1.1-1-1.9-0.9
                                  c-0.4,0.1-0.8,0.1-1.2,0.1c0,0.7,0,1.3,0,2c0.5,0,0.9,0,1.4-0.1c0.3,0.5,0.6,1,0.8,1.4c0.5,0.8,1.5,1.1,2.3,0.8l2.5-1
                                  c0.9-0.4,1.3-1.3,1.1-2.2c-0.1-0.5-0.3-1.1-0.4-1.6c0.8-0.6,1.5-1.3,2.1-2.1c0.5,0.1,1.1,0.3,1.6,0.4c0.9,0.2,1.8-0.3,2.2-1.1
                                  l1-2.5C46.5,33.4,46.2,32.4,45.4,32z"
										></path>
										<path
											d="M37.7,27C37.7,27,37.7,27,37.7,27c-1.1-2.6-3.6-4.2-6.3-4.2c0,0.7,0,1.3,0,2c1.9,0,3.6,1.1,4.4,3c1,2.5-0.2,5.3-2.6,6.3
                                  c-0.6,0.2-1.2,0.4-1.8,0.4c0,0.7,0,1.3,0,2c0.9,0,1.7-0.2,2.6-0.5C37.5,34.5,39.2,30.5,37.7,27z"
										></path>
									</g>
								</svg> </span
							>Startup MVP Development
						</router-link>
					</li>
					<li class="">
						<router-link to="/erp-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100.8 100.8"
									style="enable-background: new 0 0 100.8 100.8"
									xml:space="preserve"
								>
									<path
										class="st1"
										d="M36.71,55.82h-1.98c-1.19,0-2.18-0.8-2.38-2v-0.8c-0.2,0-0.2-0.2-0.4-0.2l-0.79,0.6
                           c-0.99,0.6-2.38,0.6-3.17-0.2l-1.19-1.4c-0.79-0.8-0.99-2.21-0.2-3.21l0.6-0.8c0-0.2-0.2-0.2-0.2-0.4l-0.79-0.2
                           C25,47,24.21,46,24.21,44.8v-1.8c0-1.2,0.79-2.21,1.98-2.41l0.79-0.2c0-0.2,0.2-0.2,0.2-0.4l-0.6-0.8c-0.6-1-0.6-2.41,0.2-3.21
                           l1.39-1.4c0.79-0.8,2.18-1,3.17-0.2l0.79,0.6c0.2,0,0.2-0.2,0.4-0.2l0.2-0.8c0.2-1.2,1.19-2,2.38-2h1.98c1.19,0,2.18,0.8,2.38,2
                           l0.2,0.8c0.2,0,0.2,0.2,0.4,0.2l0.79-0.6c0.99-0.6,2.38-0.6,3.17,0.2l1.39,1.4c0.79,0.8,0.99,2.21,0.2,3.21l-0.99,1
                           c0,0.2,0.2,0.2,0.2,0.4l0.79,0.2c1.19,0.2,1.98,1.2,1.98,2.41v2c0,1.2-0.79,2.21-1.98,2.41h-0.99c0,0.2-0.2,0.2-0.2,0.4l0.6,0.6
                           c0.79,1,0.6,2.41-0.2,3.21l-1.39,1.2c-0.79,0.8-2.18,1-3.17,0.2l-0.79-0.6c-0.2,0-0.2,0-0.4,0.2l-0.2,0.8
                           C38.89,55.02,37.9,55.82,36.71,55.82z M31.75,50.21l0.6,0.4l1.19,0.6l0.6,0.2l0.4,2.21c0,0.2,0.2,0.2,0.2,0.2h1.98
                           c0.2,0,0.2,0,0.2-0.2l0.4-2.21l0.6-0.2l1.19-0.6l0.6-0.4l1.79,1.2h0.2l1.39-1.4v-0.2L41.87,48l0.4-0.6l0.6-1.2l0.2-0.6l2.18-0.4
                           c0.2,0,0.2-0.2,0.2-0.2v-2c0-0.2,0-0.2-0.2-0.2l-2.18-0.4l-0.2-0.6l-0.6-1.2l-0.4-0.6l1.19-1.8v-0.2l-1.39-1.4h-0.2l-1.98,1.4
                           l-0.6-0.4l-1.19-0.6l-0.6-0.2l-0.4-2.21c0-0.2-0.2-0.2-0.2-0.2h-1.98c-0.2,0-0.2,0-0.2,0.2l-0.4,2.21l-0.6,0.2
                           c-0.4,0.2-0.79,0.4-1.39,0.6l-0.6,0.4l-1.79-1.2h-0.2l-1.39,1.4v0.2l1.19,1.8l-0.4,0.6l-0.6,1.2l-0.2,0.6l-2.18,0.4
                           c-0.2,0-0.2,0.2-0.2,0.2v2c0,0.2,0,0.2,0.2,0.2l2.18,0.4l0.2,0.6l0.6,1.2l0.4,0.6l-1.19,1.8v0.2l1.39,1.4h0.2
                           C29.57,51.61,31.75,50.21,31.75,50.21z M35.72,49.81c-3.17,0-5.75-2.61-5.75-5.81c0-3.21,2.58-5.81,5.75-5.81
                           c3.17,0,5.75,2.61,5.75,5.81C41.47,47.2,38.89,49.81,35.72,49.81z M35.72,40.39c-1.98,0-3.57,1.6-3.57,3.61c0,2,1.59,3.61,3.57,3.61
                           c1.98,0,3.57-1.6,3.57-3.61C39.29,41.99,37.7,40.39,35.72,40.39z M54.17,38.58h-1.39c-0.79,0-1.59-0.6-1.59-1.4l-0.2-0.6
                           c0,0-0.2,0-0.2-0.2l-0.4,0.4c-0.6,0.4-1.59,0.4-2.18-0.2l-0.99-1c-0.6-0.6-0.6-1.4-0.2-2.21l0.4-0.4c0,0,0-0.2-0.2-0.2l-0.6-0.2
                           c-0.79-0.2-1.39-0.8-1.39-1.6v-1.4c0-0.8,0.6-1.6,1.39-1.6l0.6-0.2c0,0,0-0.2,0.2-0.2l-0.4-0.4c-0.4-0.6-0.4-1.6,0.2-2.21l0.99-1
                           c0.6-0.6,1.59-0.6,2.18-0.2l0.4,0.4c0,0,0.2,0,0.2-0.2v-0.6c0.2-0.8,0.79-1.4,1.59-1.4h1.39c0.79,0,1.59,0.6,1.59,1.4l0.2,0.6
                           c0,0,0.2,0,0.2,0.2l0.4-0.4c0.6-0.4,1.59-0.4,2.18,0.2l0.99,1c0.6,0.6,0.6,1.6,0.2,2.21l-0.4,0.6c0,0,0,0.2,0.2,0.2h0.6
                           c0.79,0.2,1.39,0.8,1.39,1.6v1.4c0,0.8-0.6,1.6-1.39,1.6l-0.6,0.2c0,0,0,0.2-0.2,0.2l0.4,0.4c0.4,0.6,0.4,1.6-0.2,2.21l-0.99,1
                           c-0.6,0.6-1.59,0.6-2.18,0.2l-0.6-0.4h-0.2l-0.2,0.6C55.76,37.98,54.96,38.58,54.17,38.58z M50.8,34.57l0.4,0.2
                           c0.2,0.2,0.6,0.2,0.79,0.4l0.4,0.2l0.2,1.6c0,0,0,0.2,0.2,0.2h1.39c0,0,0.2,0,0.2-0.2l0.2-1.6l0.4-0.2c0.4,0,0.6-0.2,0.79-0.4
                           l0.4-0.2l1.19,0.8h0.2l0.99-1v-0.2l-0.79-1.2l0.2-0.4c0.2-0.2,0.2-0.6,0.4-0.8l0.2-0.4l1.59-0.2c0,0,0.2,0,0.2-0.2v-1.4
                           c0,0,0-0.2-0.2-0.2l-1.59-0.2l-0.2-0.4c0-0.4-0.2-0.6-0.4-0.8l-0.2-0.4l0.79-1.2v-0.2l-0.99-1h-0.2l-1.39,1l-0.4-0.2
                           c-0.2-0.2-0.6-0.2-0.79-0.4l-0.2-0.4l-0.2-1.6c0,0,0-0.2-0.2-0.2h-1.39c0,0-0.2,0-0.2,0.2v1.6l-0.4,0.2c-0.4,0-0.6,0.2-0.99,0.4
                           l-0.4,0.2l-1.19-0.8h-0.2l-0.99,1v0.2l0.79,1.2l-0.2,0.4c-0.2,0.2-0.2,0.6-0.4,0.8v0.4l-1.59,0.2c0,0-0.2,0-0.2,0.2v1.4
                           c0,0,0,0.2,0.2,0.2l1.59,0.2l0.2,0.4c0,0.2,0.2,0.6,0.4,0.8l0.2,0.4l-0.79,1.2v0.2l0.99,1h0.2C49.8,35.37,50.8,34.57,50.8,34.57z
                           M53.57,34.37c-2.18,0-3.97-1.8-3.97-4.01s1.79-4.01,3.97-4.01s3.97,1.8,3.97,4.01S55.76,34.37,53.57,34.37z M53.57,27.76
                           c-1.39,0-2.38,1-2.38,2.41s0.99,2.41,2.38,2.41s2.38-1,2.38-2.41S54.96,27.76,53.57,27.76z M62.31,67.45h-2.18
                           c-1.39,0-2.58-1-2.78-2.41l-0.2-1c-0.2,0-0.2-0.2-0.4-0.2l-0.79,0.6c-1.39,0.6-2.98,0.6-3.97-0.4l-1.59-1.6
                           c-0.99-1-1.19-2.61-0.4-3.81l0.6-0.8c0-0.2-0.2-0.2-0.2-0.4l-0.99-0.2c-1.39-0.2-2.38-1.4-2.38-2.81v-2.21
                           c0-1.4,0.99-2.61,2.38-2.81l1.19-0.4c0-0.2,0.2-0.2,0.2-0.4l-0.6-0.8c-0.79-1.2-0.6-2.81,0.4-3.81l1.59-1.6
                           c0.99-1,2.58-1.2,3.77-0.4l0.79,0.6c0.2,0,0.4-0.2,0.6-0.2l0.2-1c0.2-1.4,1.39-2.41,2.78-2.41h2.18c1.39,0,2.58,1,2.78,2.41l0.2,1
                           c0.2,0,0.2,0.2,0.4,0.2l0.79-0.6c1.19-0.8,2.78-0.6,3.77,0.4l1.59,1.6c0.99,1,1.19,2.61,0.4,3.81l-0.6,0.8c0,0.2,0.2,0.2,0.2,0.4
                           l0.99,0.2c1.39,0.2,2.38,1.4,2.38,2.81v2.21c0,1.4-0.99,2.61-2.38,2.81l-0.99,0.2c0,0.2-0.2,0.2-0.2,0.4l0.6,0.8
                           c0.79,1.2,0.79,2.81-0.4,3.81l-1.59,1.6c-0.99,1-2.58,1.2-3.77,0.4l-0.79-0.6c-0.2,0-0.2,0.2-0.4,0.2l-0.2,1
                           C64.89,66.25,63.69,67.45,62.31,67.45z M56.35,60.63l0.79,0.4c0.4,0.2,0.99,0.4,1.59,0.6l0.79,0.2l0.4,2.61c0,0.2,0.2,0.2,0.2,0.2
                           h2.18c0.2,0,0.2,0,0.2-0.2l0.4-2.61l0.79-0.2c0.6-0.2,0.99-0.4,1.39-0.6l0.79-0.4l2.18,1.6h0.4l1.59-1.6c0,0,0.2-0.2,0-0.4
                           l-1.59-2.21l0.4-0.8c0.2-0.4,0.4-1,0.6-1.6l0.2-0.8l2.58-0.4c0.2,0,0.2-0.2,0.2-0.2v-2.21c0-0.2,0-0.2-0.2-0.2l-2.58-0.4l-0.2-0.8
                           c-0.2-0.6-0.4-1-0.6-1.6l-0.4-0.6l1.59-2.21v-0.4l-1.59-1.6h-0.2l-2.18,1.6l-0.79-0.4c-0.4-0.2-0.99-0.4-1.59-0.6l-0.79-0.2
                           l-0.4-2.61c0-0.2-0.2-0.2-0.2-0.2h-2.18c-0.2,0-0.2,0-0.2,0.2l-0.4,2.61l-0.99,0.2c-0.6,0.2-0.99,0.4-1.59,0.6l-0.79,0.4l-2.18-1.6
                           h-0.4l-1.59,1.6c0,0-0.2,0.2,0,0.4l1.59,2.21l-0.2,0.6c-0.2,0.4-0.4,1-0.6,1.6l-0.2,0.8L50,51.81c-0.2,0-0.2,0.2-0.2,0.2v2.21
                           c0,0.2,0,0.2,0.2,0.2l2.58,0.6l0.2,0.8c0.2,0.4,0.4,1,0.6,1.6l0.4,0.8l-1.59,2.21v0.4l1.59,1.6h0.2
                           C53.97,62.44,56.35,60.63,56.35,60.63z M61.11,60.03c-3.77,0-6.75-3.01-6.75-6.82s2.98-6.82,6.75-6.82c3.77,0,6.75,3.01,6.75,6.82
                           S64.89,60.03,61.11,60.03z M61.11,49.01c-2.38,0-4.17,1.8-4.17,4.21c0,2.41,1.79,4.21,4.17,4.21s4.17-1.8,4.17-4.21
                           C65.28,50.81,63.5,49.01,61.11,49.01z"
									></path>
									<path
										class="st1"
										d="M92.86,84.49H7.94C3.57,84.49,0,80.88,0,76.47V14.73c0-4.41,3.57-8.02,7.94-8.02h84.73
                           c4.37,0,7.94,3.41,8.14,7.82v61.94C100.8,80.88,97.23,84.49,92.86,84.49z M7.94,10.72c-2.18,0-3.97,1.8-3.97,4.01v61.74
                           c0,2.21,1.79,4.01,3.97,4.01h84.93c2.18,0,3.97-1.8,3.97-4.01V14.73c-0.2-2-1.79-4.01-4.17-4.01C92.66,10.72,7.94,10.72,7.94,10.72z
                           "
									></path>
									<path
										class="st1"
										d="M72.43,95.71H28.18c-0.79,0-1.59-0.4-1.79-1.2c-0.4-0.8-0.2-1.6,0.4-2.21L37.9,80.88c0.4-0.4,0.79-0.6,1.39-0.6
                           h22.03c0.6,0,0.99,0.2,1.39,0.6l11.11,11.43c0.6,0.6,0.79,1.4,0.4,2.21C74.01,95.31,73.22,95.71,72.43,95.71z M32.94,91.7h34.72
                           l-7.14-7.42H40.08C40.08,84.29,32.94,91.7,32.94,91.7z"
									></path></svg></span
							>ERP Solutions</router-link
						>
					</li>
					<li>
						<router-link to="/crm-development" class=""
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											d="M23.31,18.59V9.92c2.03-0.52,3.53-2.37,3.53-4.56c0-2.59-2.11-4.7-4.7-4.7c-2.59,0-4.7,2.11-4.7,4.7
                              c0,2.19,1.5,4.03,3.53,4.56v8.67c-2.46,0.37-4.55,1.89-5.7,3.99l-5.15-4c0.13-0.35,0.19-0.74,0.19-1.13c0-1.87-1.52-3.39-3.39-3.39
                              s-3.39,1.52-3.39,3.39s1.52,3.39,3.39,3.39c0.62,0,1.2-0.17,1.7-0.46l5.82,4.52c-0.09,0.46-0.13,0.94-0.13,1.43
                              c0,1.74,0.57,3.34,1.53,4.64l-6.58,6.58c-0.46,0.46-0.46,1.2,0,1.65c0.23,0.23,0.53,0.34,0.83,0.34s0.6-0.11,0.83-0.34l6.58-6.58
                              c1,0.74,2.19,1.25,3.47,1.44v8.69c-1.3,0.48-2.22,1.72-2.22,3.18c0,1.87,1.52,3.39,3.39,3.39s3.39-1.52,3.39-3.39
                              c0-1.46-0.93-2.71-2.22-3.18v-8.69c3.76-0.57,6.66-3.82,6.66-7.74C29.97,22.41,27.08,19.16,23.31,18.59z M6.93,18.5
                              c-0.58,0-1.05-0.47-1.05-1.05s0.47-1.05,1.05-1.05s1.05,0.47,1.05,1.05S7.51,18.5,6.93,18.5z M19.78,5.36
                              c0-1.3,1.06-2.36,2.36-2.36c1.3,0,2.36,1.06,2.36,2.36s-1.06,2.36-2.36,2.36C20.84,7.72,19.78,6.66,19.78,5.36z M22.14,47
                              c-0.58,0-1.05-0.47-1.05-1.05c0-0.51,0.36-0.93,0.84-1.03c0.07,0.01,0.14,0.02,0.21,0.02c0.07,0,0.14-0.01,0.21-0.02
                              c0.48,0.1,0.84,0.52,0.84,1.03C23.19,46.53,22.72,47,22.14,47z M22.14,31.82c-3.03,0-5.49-2.46-5.49-5.49
                              c0-0.53,0.08-1.05,0.22-1.54c0.01-0.02,0.01-0.05,0.02-0.07c0.69-2.24,2.78-3.88,5.25-3.88c3.03,0,5.49,2.46,5.49,5.49
                              C27.63,29.36,25.17,31.82,22.14,31.82z"
										></path>
										<path
											d="M45.3,14.97c-2.59,0-4.7,2.11-4.7,4.7c0,0.12,0.01,0.24,0.02,0.36l-8.46,2.88c-0.61,0.21-0.94,0.87-0.73,1.48
                              c0.17,0.49,0.62,0.79,1.11,0.79c0.13,0,0.25-0.02,0.38-0.06l8.46-2.88c0.84,1.28,2.29,2.13,3.93,2.13c2.59,0,4.7-2.11,4.7-4.7
                              C50,17.08,47.89,14.97,45.3,14.97z M45.3,22.03c-1.3,0-2.36-1.06-2.36-2.36c0-1.3,1.06-2.36,2.36-2.36c1.3,0,2.36,1.06,2.36,2.36
                              C47.66,20.97,46.6,22.03,45.3,22.03z"
										></path>
										<path
											d="M4.7,39.07c-2.59,0-4.7,2.11-4.7,4.7c0,2.59,2.11,4.7,4.7,4.7c2.59,0,4.7-2.11,4.7-4.7C9.41,41.18,7.3,39.07,4.7,39.07z
                              M4.7,46.13c-1.3,0-2.36-1.06-2.36-2.36c0-1.3,1.06-2.36,2.36-2.36s2.36,1.06,2.36,2.36C7.07,45.07,6.01,46.13,4.7,46.13z"
										></path>
									</g></svg></span
							>CRM Solutions</router-link
						>
					</li>
					<li>
						<router-link to="/cms-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<circle cx="41.16" cy="7.96" r="0.78"></circle>
									<circle cx="38.06" cy="7.96" r="0.78"></circle>
									<circle cx="34.96" cy="7.96" r="0.78"></circle>
									<path
										d="M46.09,29.56c-0.43,0-0.78,0.35-0.78,0.78v13.8c-0.02,0.66-0.56,1.18-1.22,1.17H2.78c-0.66,0.01-1.2-0.51-1.22-1.17V12.5
                             h43.75v2.73c0,0.43,0.35,0.78,0.78,0.78c0.43,0,0.78-0.35,0.78-0.78V5.86c-0.02-1.52-1.26-2.74-2.78-2.73H2.78
                             C1.26,3.12,0.02,4.34,0,5.86v38.28c0.02,1.52,1.26,2.74,2.78,2.73h41.32c1.52,0.01,2.76-1.21,2.78-2.73v-13.8
                             C46.88,29.91,46.53,29.56,46.09,29.56z M2.78,4.69h41.32c0.66-0.01,1.2,0.51,1.22,1.17v5.08H1.56V5.86C1.58,5.2,2.12,4.68,2.78,4.69
                             z"
									></path>
									<path
										d="M22.65,34.38H5.47c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78h17.19c0.43,0,0.78-0.35,0.78-0.78
                             C23.44,34.73,23.09,34.38,22.65,34.38z"
									></path>
									<path
										d="M22.65,39.06H5.47c-0.43,0-0.78,0.35-0.78,0.78s0.35,0.78,0.78,0.78h17.19c0.43,0,0.78-0.35,0.78-0.78
                             S23.09,39.06,22.65,39.06z"
									></path>
									<path
										d="M37.5,17.97c0-0.43-0.35-0.78-0.78-0.78h-9.38c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78h9.38
                             C37.15,18.75,37.5,18.4,37.5,17.97z"
									></path>
									<path
										d="M27.34,21.87c-0.43,0-0.78,0.35-0.78,0.78s0.35,0.78,0.78,0.78h6.25c0.43,0,0.78-0.35,0.78-0.78s-0.35-0.78-0.78-0.78H27.34
                             z"
									></path>
									<path
										d="M45.07,17.4c-0.3-0.31-0.8-0.31-1.1,0c0,0,0,0,0,0L28.58,32.78c-0.09,0.1-0.16,0.21-0.2,0.34l-1.79,6.5
                             c-0.11,0.42,0.13,0.85,0.55,0.96c0.14,0.04,0.28,0.04,0.42,0l6.5-1.79c0.13-0.04,0.25-0.1,0.34-0.2l15.38-15.38
                             c0.31-0.3,0.31-0.8,0-1.1c0,0,0,0,0,0L45.07,17.4z M28.98,36.83l1.36,1.36l-1.88,0.52L28.98,36.83z M33.43,37.34l-1.36,0.39
                             l-2.62-2.62l0.39-1.36l11.56-11.58l3.6,3.61L33.43,37.34z M46.09,24.66l-3.6-3.6l2.04-2.01l3.58,3.6L46.09,24.66z"
									></path>
									<path
										d="M23.44,17.97L23.44,17.97c0-0.03,0-0.06,0-0.09c0-0.02,0-0.04,0-0.06c-0.01-0.03-0.01-0.05-0.02-0.08
                             c0-0.02,0-0.05-0.02-0.07c-0.02-0.02-0.02-0.04-0.03-0.06c-0.01-0.03-0.03-0.05-0.04-0.07l0,0l-0.04-0.04l-0.05-0.06l-0.06-0.05
                             l-0.06-0.04l-0.07-0.04l-0.07-0.03l-0.08-0.02h-0.07h-0.09H5.42H5.33H5.26l-0.08,0.02l-0.06,0.03l-0.07,0.04l-0.06,0.04l-0.06,0.05
                             l-0.05,0.06l-0.04,0.04l0,0c-0.02,0.02-0.03,0.05-0.04,0.07c0,0.02-0.02,0.04-0.03,0.06s0,0.05-0.02,0.07
                             c-0.01,0.03-0.02,0.05-0.02,0.08c0,0.02,0,0.04,0,0.06c-0.01,0.03-0.02,0.06-0.03,0.1l0,0v12.5l0,0c0,0.03,0,0.06,0,0.09
                             c0,0.02,0,0.04,0,0.06c0.01,0.03,0.01,0.05,0.02,0.08c0,0.02,0,0.05,0.02,0.07c0.02,0.02,0.02,0.04,0.03,0.06
                             c0.01,0.03,0.03,0.05,0.04,0.07l0,0l0.03,0.03c0.02,0.03,0.05,0.06,0.08,0.09l0.05,0.04l0.09,0.06l0.05,0.03l0.11,0.04h0.04
                             c0.06,0.03,0.13,0.05,0.2,0.05h17.19c0.06,0,0.11-0.01,0.17-0.02h0.04l0.11-0.04l0.05-0.03l0.09-0.06l0.05-0.04
                             c0.03-0.03,0.06-0.06,0.08-0.09l0.03-0.03l0,0c0.02-0.02,0.03-0.05,0.04-0.07c0-0.02,0.02-0.04,0.03-0.06
                             c0.01-0.02,0-0.05,0.02-0.07c0.01-0.03,0.02-0.05,0.02-0.08c0-0.02,0-0.04,0-0.06c0.01-0.04,0.02-0.09,0.03-0.13l0,0V17.97z
                             M6.25,19.5l6.48,4.72l-6.48,4.72V19.5z M7.87,18.75h12.38l-6.19,4.5L7.87,18.75z M14.06,25.18l6.19,4.5H7.87L14.06,25.18z
                             M15.39,24.22l6.48-4.72v9.43L15.39,24.22z"
									></path></svg></span
							>CMS Solutions</router-link
						>
					</li>
					<li>
						<router-link to="/ecommerce-web-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M99.6,15.3c-0.3-0.6-0.9-1-1.6-1h-78l-1.6-9.7C18.2,4,17.5,3,16.3,3H2.2C0.9,3,0,4,0,4.9c0,1,0.9,2.3,2.2,2.3
                                  h12.2l7.5,46.1c0,0.3,0,0.3,0,0.6l2.5,15.6c0.3,1,0.9,1.9,2.2,1.9h59.5c1.3,0,2.2-1,2.2-2.3c0-1.3-0.9-2.3-2.2-2.3h-58l-1.9-11.7
                                  h62c0.9,0,1.9-0.6,1.9-1.6l9.4-37.3C100.3,16.6,100,16,99.6,15.3z M86.8,51.3H26l-5.3-32.8h74.6L86.8,51.3z M35.7,77
                                  C30.4,77,26,81.5,26,87c0,5.5,4.4,10.1,9.7,10.1s9.7-4.5,9.7-10.1C45.4,81.5,41,77,35.7,77z M35.7,92.9c-3.1,0.3-5.6-2.6-5.6-5.8
                                  s2.5-5.8,5.6-5.8c3.1,0,5.6,2.6,5.6,5.8S38.9,92.9,35.7,92.9z M71.8,77C66.4,77,62,81.5,62,87c0,5.5,4.4,10.1,9.7,10.1
                                  c5.3,0,9.7-4.5,9.7-10.1C81.5,81.5,77.1,77,71.8,77z M71.8,92.9c-3.1,0.3-5.6-2.6-5.6-5.8s2.5-5.8,5.6-5.8c3.1,0,5.6,2.6,5.6,5.8
                                  S74.9,92.9,71.8,92.9z"
											></path>
										</g>
									</g></svg></span
							>Ecommerce Solutions</router-link
						>
					</li>
					<li>
						<router-link to="/ui-ux-design-development"
							><span class="submenuicon"
								><svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st1"
												d="M73.3,83.4h-30v3.3h30v5c0,2.8-2.2,5-5,5H31.7c-1.8,0-3.4-1-4.3-2.5l-2.9,1.7c1.5,2.6,4.2,4.2,7.2,4.2h36.7
                                  c4.6,0,8.3-3.7,8.3-8.3v-15h-3.3V83.4z M48.3,90v3.3h3.3V90H48.3z M10.1,41.7H5V45h5.1c0.8,10.7,9.3,19.1,19.9,19.9V70h3.3v-5.1
                                  C44,64.2,52.5,55.7,53.3,45h5.1v-3.3h-5.1C52.4,31,44,22.6,33.3,21.8v-5.1H30v5.1c-1.1,0.1-2.2,0.3-3.3,0.5v-8.9h26.7V10H26.7V8.3
                                  c0-2.8,2.2-5,5-5h36.7c1.2,0,2.4,0.4,3.3,1.3l2.2-2.5C72.3,0.7,70.4,0,68.3,0H31.7c-4.6,0-8.3,3.7-8.3,8.3v15
                                  C15.8,26.5,10.7,33.6,10.1,41.7z M40,59.7c-2.1,1.1-4.3,1.7-6.7,1.9v-3.3H30v3.3c-2.3-0.2-4.6-0.9-6.7-1.9v-3
                                  c0-4.6,3.7-8.3,8.3-8.3s8.3,3.7,8.3,8.3V59.7z M26.7,40c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5c0,2.8-2.2,5-5,5
                                  C28.9,45,26.7,42.8,26.7,40z M30,25.1v3.2h3.3v-3.2c8.8,0.8,15.8,7.8,16.6,16.6h-3.2V45h3.2c-0.4,4.6-2.6,8.9-6,12v0
                                  c-0.2,0.2-0.4,0.3-0.6,0.5v-0.8c0-4.4-2.4-8.3-6.3-10.3c3.5-3,4-8.2,1-11.7c-3-3.5-8.2-4-11.7-1c-3.5,3-4,8.2-1,11.7
                                  c0.3,0.4,0.6,0.7,1,1c-3.9,2-6.3,6-6.3,10.3v0.8c-0.2-0.2-0.4-0.3-0.6-0.5c-3.5-3.1-5.6-7.4-6-12h3.2v-3.3h-3.2
                                  C14.2,32.9,21.2,25.9,30,25.1z M56.7,8.3v20c0,0.9,0.7,1.7,1.7,1.7H95c0.9,0,1.7-0.7,1.7-1.7v-20c0-0.9-0.7-1.7-1.7-1.7H58.3
                                  C57.4,6.7,56.7,7.4,56.7,8.3z M60,10h33.3v16.7H60V10z M70,13.3h-6.7v3.3H70V13.3z M73.3,16.7H90v-3.3H73.3V16.7z M63.3,23.3H90
                                  V20H63.3V23.3z M98.2,34.7c-1-0.9-2.2-1.3-3.5-1.3c-1.3,0-2.5,0.5-3.5,1.3L76.7,47.5V33.4h-3.3V50h0.5l-9.5,8.4
                                  c-6.2,0.4-11,5.5-11,11.6V73c0,1.7-0.9,3.2-2.4,3.9c-0.8,0.4-1.2,1.4-0.7,2.2c0.3,0.6,0.9,0.9,1.5,0.9h11.7
                                  c6.2,0,11.3-4.8,11.6-11l23.7-26.9C100.6,40,100.4,36.6,98.2,34.7z M63.3,76.7h-7.6c0.6-1.1,1-2.4,1-3.7V70c0-4.3,3.3-8,7.7-8.3
                                  l7.3,7.3C71.3,73.4,67.7,76.7,63.3,76.7z M96.2,39.9l-22.9,26l-5.8-5.8l26-22.9c0.8-0.7,2.1-0.6,2.8,0.2
                                  C96.8,38.1,96.8,39.2,96.2,39.9z M40,90V76.7c0-0.9-0.7-1.7-1.7-1.7H26.7v-8.3h-3.3V75H1.7C0.7,75,0,75.8,0,76.7V90
                                  c0,0.9,0.7,1.7,1.7,1.7h36.7C39.3,91.7,40,91,40,90z M36.7,88.4H3.3v-10h33.3V88.4z M15,81.7H6.7V85H15V81.7z M33.3,81.7h-15V85
                                  h15V81.7z M1.7,23.3h16.7c0.9,0,1.7-0.7,1.7-1.7V8.3c0-0.9-0.7-1.7-1.7-1.7H1.7C0.7,6.7,0,7.4,0,8.3v13.3
                                  C0,22.6,0.7,23.3,1.7,23.3z M3.3,10h13.3v10H3.3V10z M6.7,16.7h6.7v-3.3H6.7V16.7z"
											></path>
										</g>
									</g></svg></span
							>UI-UX Design</router-link
						>
					</li>
				</ul>
			</div>
		</div>
		<DownP></DownP>
	</div>
</template>

<script>
import DownP from './DownP.vue';
export default {
	components: {
		DownP
	}
};
</script>

<style scoped>
.a-t {
	display: flex;
	color: #000;
	align-items: center;
}

.a-t:hover {
	color: #fff;
}

.col-3 {
	padding: 0;
}

.our-services-div {
	width: 100vw;
}

.os-wrapper {
	position: relative;
	margin: 0 auto;
	max-width: 98%;
}

.os-wrapper h4 {
	text-align: center;
	white-space: nowrap;
}
.os-wrapper span {
	display: flex;
	align-items: center;
}

.os-wrapper svg {
	width: 60px;
}

.serv-ul {
	list-style: none;
	padding: 0 24px;
}

.serv-ul li a {
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: start;
	color: #000;
	width: 100%;
}

.cc-h {
	border-bottom: 1px solid #000;
}

.cc-h:hover {
	background: #1977cc;
	color: #fff;
	border-bottom: #1977cc 1px solid;
}
.cc-h:hover svg {
	fill: #fff;
}

.serv-ul li a svg {
	width: 30px;
	height: 30px;
	margin-right: 20px;
}

.serv-ul li:hover a {
	color: #1977cc;
}

.serv-ul li:hover a svg {
	fill: #1977cc;
}
.fxt-srvs-header svg {
	max-width: 40px;
}
.fxt-srvs-header h5 {
	font-size: 1rem;
}
.serv-ul li {
	font-size: 0.8rem;
}
@media (max-width: 991px) {
	#our-services {
		display: none !important;
	}
}
</style>
