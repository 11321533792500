<template>
	<section class="bg-white">
		<div v-if="itemsCount">
			<ImageWithInfo :obj="items[0]"></ImageWithInfo>
		</div>
		<VueSlickCarousel
			v-else
			v-bind="settings"
			class="portfolio-slider px-2"
			style="overflow-x: hidden"
		>
			<div
				style="width: 100%; display: inline-block; margin: 0; padding: 0"
				v-for="(item, index) in items"
				:key="index"
			>
				<ImageWithInfo :obj="item"></ImageWithInfo>
			</div>
		</VueSlickCarousel>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import ImageWithInfo from '../AssetComponents/ImageWithInfo.vue';
export default {
	components: {
		VueSlickCarousel,
		ImageWithInfo
	},
	computed: {
		itemsCount() {
			if (this.items.length > 1) return false;
			return true;
		}
	},
	data() {
		return {
			settings: {
				slidesToShow: 1,
				autoplay: true,
				dotsClass: 'slick-dots custom-dot-class',
				dots: false,
				variableWidth: true
			},
			items: [
				{
					color: 'claireabellabg',
					logo: '/claireabella-logo.png',
					image1: '/claireabella-mobile-1.png',
					image2: '/claireabella-mobile-2.png',
					image3: '/claireabella-main.png',
					title: 'ClaireaBella',
					description: 'Personalized Emoji App',
					paragraphs: [
						"Claireabella is an interesting app that allows to create their own custom emoji app based on the UK's renowned character Mr Bella and Claireabella. The app allows used to create custom emojis by combining the various physical traits of Mr Bella and Claireabella like face, body, hair, ski color, expressions, headwear, outfits and much more.",
						'It garnered attention as it was unique a unique experience compared to default emoticons and also owing to the popularity of Mr. Bella and Clairebella. Given the number of app downloads from popular app stores, it was proven that Clairebella was a runaway success.'
					],
					features: [
						'Custom emoji creation',
						'Emoticons for texting',
						'Multiple customization',
						'Social sharing',
						'Print emojis',
						'iPhone and Android app',
						'Real'
					]
				},
				{
					color: 'guestmebg',
					logo: '/guest-me-logo.png',
					image1: '/guest-me-mobile-1.png',
					image2: '/guest-me-mobile-2.png',
					image3: '/guest-me-main.png',
					title: 'Guest Me',
					description: 'Chat with your favourite creators with built in live chat',
					paragraphs: [
						'A social live video streaming mobile app that helps anyone to become an artist to the global stage. Live streaming of events, personal life, travel — almost everything is the new rage. Guest Me is one such live streaming app that combines the best of social networking and live video streaming. It allows users to create their own personal live videos while broadcasting it to the world at large receiving instant reactions, comments and shares.',
						'Not just life streaming, Guest me can do a whole lot of other stuff as well.'
					],
					features: [
						'Viewers commenting system',
						'Broadcaster responses',
						'Group chat or one to one chat',
						'Video channel subscription',
						'In-app purchases',
						'Social media integrations'
					]
				},
				{
					color: 'strongmindedbg',
					logo: '/strong-minded-logo.jpg',
					image1: '/strong-minded-mobile-1.png',
					image2: '/strong-minded-mobile-2.png',
					image3: '/strong-minded-main.png',
					title: 'Strong Minded',
					description: 'murder-mystery events and games all over the World',
					paragraphs: [
						'An expertly curated list of motivational music tracks. The app has a collection of tracks ranging from success, wisdom, well-being, entrepreneurship, fitness and much more. The app turns creates albums of similar tracks and topic that helps users to listen on loop.',
						'Users can also create custom playlists of their own, create favorites within the app for future listening. The app was well-received amidst gym goers, music lovers and everyone else who wanted a dose of motivation in their lives.'
					],
					features: [
						'Theme-based music tracks',
						'Playlist creation',
						'Favorite playlist',
						'Admin controlled categories'
					]
				},
				{
					color: 'idtrackbg',
					logo: '/id-track-logo.jpg',
					image1: '/id-track-mobile-1.png',
					image2: '/id-track-mobile-2.png',
					image3: '/id-track-main.png',
					title: 'ID Track',
					description: 'Entertainment App',
					paragraphs: [
						'An app that knits together a community of music lovers, enabling them to find, share and enjoy underrated music. The app gives its users a platform to find the track name, artist and album by uploading an image. Other members in the community comment on the post helping the post creator to know more about the particular music track. The end result is that music tracks that do not come to the world’s ears due to other dominant playlists get their deserved attention.',
						'The app is bolted with features that creates a social ecosystem for music lovers, event goers and music festival participants.'
					],
					features: [
						'Post creation',
						'image /video upload',
						'Commenting system',
						'Likes, favorites or reactions',
						'Music track suggestions',
						'Rich media responses'
					]
				}
			]
		};
	}
};
</script>

<style scoped>
.portfolio-slider {
	padding: 0 !important;
}
.portfolio-slider button {
	display: none;
}
</style>