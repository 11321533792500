<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">
					Features for players and admins to make the most of a fantasy sport app experience
				</h2>
				<p>
					Check out the list of our carefully curated features for fantasy sports app experience
					that will keep your players hooked to the app endlessly.
				</p>
			</div>
			<ul
				class="nav nav-tabs car-wash-feature-tab d-flex justify-content-center"
				id="technologies_tabs"
				role="tablist"
			>
				<li>
					<a
						class="active"
						id="Application-tab"
						data-toggle="tab"
						href="#Application"
						role="tab"
						aria-controls="Application"
						aria-selected="true"
						>Participant</a
					>
				</li>
				<li>
					<a
						id="payment-gateway-tab"
						data-toggle="tab"
						href="#payment-gateway"
						role="tab"
						aria-controls="payment-gateway"
						aria-selected="true"
						>Admin</a
					>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade fxt-technologies-tabs show active"
					id="Application"
					role="tabpanel"
					aria-labelledby="Application-tab"
				>
					<div class="row" style="justify-content: center">
						<div
							class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
							v-for="item in items"
							v-bind:key="item.title"
						>
							<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
						</div>
					</div>
				</div>
				<div
					class="tab-pane fade fxt-technologies-tabs"
					id="payment-gateway"
					role="tabpanel"
					aria-labelledby="payment-gateway-tab"
				>
					<div class="row" style="justify-content: center">
						<div
							class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
							v-for="item in items2"
							v-bind:key="item.title"
						>
							<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../../IndustrySolutions/AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <path d="M46.7,28.4c0-0.1,0-15.7,0-15.7L23.5,1.1L0.2,12.7v15.4c0,9.6,4.8,18.6,12.8,24L23.5,59l8-5.3c4.3,5.6,11.8,7.6,18.4,4.9
                  c6.5-2.6,10.5-9.3,9.8-16.3C58.9,35.3,53.7,29.7,46.7,28.4L46.7,28.4z M23.5,56.8l-9.4-6.3c-7.5-5-12-13.4-12-22.4V13.9L23.5,3.2
                  l21.4,10.7v14.4c-0.3,0-0.6,0-0.9,0s-0.6,0-0.9,0c0,0,0-13.2,0-13.2L23.5,5.2L4,15v13.1C4,36.5,8.2,44.4,15.1,49l8.3,5.6l6.1-4.1
                  c0.3,0.6,0.6,1.1,0.9,1.7L23.5,56.8z M28.9,48.7l-5.4,3.6l-7.3-4.9c-0.4-0.2-0.7-0.5-1.1-0.8v-8.3h-1.9v6.8c-1-0.9-2-2-2.8-3.1v-5.4
                  c0-1.3,0.9-2.4,2.1-2.7l6.3-1.6c0.2,2,1.7,3.7,3.7,4.1v3.8h1.9v-3.8c2-0.4,3.5-2.1,3.7-4.1l4.1,1C28.5,37.5,27.2,43.4,28.9,48.7
                  L28.9,48.7z M18.8,20.7h2.6c1.4,0,2.9-0.3,4.2-0.9h0.3l2.3,3.1v3.9L25,29.1h-3.1l-3.1-2.3V20.7z M17.1,18.9c0.2-1.2,0.9-2.2,2-2.7
                  c2.1-0.9,4.4-1.1,6.5-0.4l0.2,0.3h1.4c0.5,0,0.9,0.4,0.9,0.9v2.8l-1.4-1.9h-1.6L24.9,18c-1.1,0.5-2.3,0.8-3.5,0.8H17.1z M26.3,30.5
                  v1.4c0,1.5-1.2,2.8-2.8,2.8c-1.5,0-2.8-1.2-2.8-2.8v-1.4l0.6,0.5h4.3L26.3,30.5z M28.1,30.5v-1.4l1.9-1.4V17c0-1.5-1.2-2.8-2.8-2.8
                  h-0.6c-1.3-0.8-4.7-1-7.1-0.2c-2.7,0.7-4.5,3.1-4.4,5.8v0.9H17v7l1.9,1.4v1.4l-6.7,1.7c-2.1,0.5-3.5,2.4-3.5,4.5v2.5
                  c-1.8-3.4-2.8-7.2-2.8-11v-12l17.7-8.8l17.7,8.8v12c0,0.1,0,0.2,0,0.3c-2.7,0.5-5.2,1.7-7.4,3.4L28.1,30.5z M43.9,57.9
                  C36.2,57.9,30,51.7,30,44C30,36.3,36.2,30,43.9,30S57.9,36.3,57.9,44C57.9,51.7,51.6,57.9,43.9,57.9z"></path>
                  <path d="M48.6,17h11.2v1.9H48.6V17z"></path>
                  <path d="M48.6,20.7H56v1.9h-7.4V20.7z"></path>
                  <path d="M48.6,24.4h3.7v1.9h-3.7V24.4z"></path>
                  <path d="M40.5,43.3L37.2,40L32,45.3l8.5,8.5L55,39.4l-5.3-5.3L40.5,43.3z M40.5,51.2l-5.9-5.9l2.6-2.6l3.3,3.3l9.2-9.2l2.6,2.6
                  L40.5,51.2z"></path>
                </svg>`,
					title: 'User Authentication'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M53.4,5.8l2.5-3.4c0.3-0.4,0.3-0.9,0.1-1.3L56,1c-0.3-0.4-0.7-0.6-1.2-0.6H4.3C3.8,0.3,3.3,0.6,3.1,1
                    C2.8,1.4,2.8,1.9,3.2,2.4l17.6,24.3l3.2,26c0,0.5,0.3,0.9,0.7,1l10,5.9C35,60,35.4,60,35.6,60c0.2,0,0.4,0,0.7-0.2
                    c0.4-0.3,0.7-0.7,0.7-1.1l1.4-32l2.6-3.6c1.6,0.8,3.3,1.3,5.1,1.3c6,0,10.8-4.8,10.8-10.7C57,10.7,55.8,7.9,53.4,5.8z M35.6,27.6
                    l-1.3,28.7l-7.6-4.5l-3-24.2H35.6z M51.3,4.2c-1.6-0.8-3.3-1.3-5.1-1.3c-6,0-10.8,4.8-10.8,10.7c0,3,1.3,5.9,3.5,7.9L36.4,25H22.8
                    L6.9,2.9h39.2h6.1L51.3,4.2z M46.2,5.7c4.5,0,8.1,3.6,8.1,8s-3.6,8-8.1,8s-8.1-3.6-8.1-8S41.7,5.7,46.2,5.7z"></path>
                    <path d="M44.4,16c0.6,0,1.2-0.1,1.7-0.4l2.8,2.8c0.3,0.3,0.7,0.4,1,0.4c0.3,0,0.7-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-0.9
                    c0-0.3-0.1-0.7-0.4-0.9l-2.8-2.8c0.2-0.5,0.4-1.1,0.4-1.7c0-2.2-1.8-4-4.1-4s-4.1,1.8-4.1,4S42.2,16,44.4,16z M44.4,10.6
                    c0.7,0,1.4,0.6,1.4,1.3c0,0.7-0.6,1.3-1.4,1.3c-0.7,0-1.4-0.6-1.4-1.3C43.1,11.2,43.7,10.6,44.4,10.6z"></path>
                  </g>
                </svg>`,
					title: 'Search and Filter Matches'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <style type="text/css">
                    .jointst0{fill-rule:evenodd;clip-rule:evenodd;}
                  </style>
                  <path class="jointst0" d="M16.2,27H12c-3.3,0-6-2.7-6-6V9c0-0.6,0.4-1,1-1h9V3c0-0.6,0.4-1,1-1h26c0.6,0,1,0.4,1,1v5h9c0.6,0,1,0.4,1,1
                  v12c0,3.3-2.7,6-6,6h-4.2c-0.9,5.1-5.4,9-10.8,9h-1v11h4.5c3,0,5.5,2.5,5.5,5.5V57c0,0.6-0.4,1-1,1H21c-0.6,0-1-0.4-1-1v-4.5
                  c0-3,2.5-5.5,5.5-5.5H30V36h-3C21.6,36,17.1,32.1,16.2,27z M18,4v21c0,5,4,9,9,9h6c5,0,9-4,9-9V4H18z M8,10v11c0,2.2,1.8,4,4,4h4V10
                  H8z M40,56v-3.5c0-1.9-1.6-3.5-3.5-3.5h-11c-1.9,0-3.5,1.6-3.5,3.5V56H40z M52,10h-8v15h4c2.2,0,4-1.8,4-4V10z"></path>
                </svg>`,
					title: 'Join Tournament'
				},
				{
					svg: `<svg viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <g>
                    <path d="M32,45.1c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-7.6h14.4v7.6c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-8.4
                    l0-0.1c0-0.5-0.4-0.9-0.9-0.9h-7.2v-7.6c0-0.5-0.4-0.9-0.9-0.9v0H25.9v-7.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v7.9H9
                    c-0.5,0-0.9,0.4-0.9,0.9v0v7.5H0.9c-0.5,0-0.9,0.4-0.9,0.9l0,0.1l0,8.4C0,45.6,0.4,46,0.9,46c0.5,0,0.9-0.4,0.9-0.9v-7.6h14.4v7.6
                    c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-8.4c0-0.5-0.4-0.9-0.9-0.9h0H9.9v-6.7h30.3l0,6.7h-7.2h0c-0.5,0-0.9,0.4-0.9,0.9
                    L32,45.1L32,45.1z M27.5,8.1l5.3,0c0.5,0,0.9,0.4,0.9,0.9c0,0.3-0.1,0.6-0.4,0.7l0,0L29,12.8l1.6,5c0.2,0.5-0.1,1-0.6,1.1
                    c-0.3,0.1-0.6,0-0.8-0.2L25,15.7l-4.3,3.1c-0.4,0.3-1,0.2-1.3-0.2c-0.2-0.2-0.2-0.5-0.1-0.8l0,0l1.6-5l-4.3-3.1
                    c-0.4-0.3-0.5-0.8-0.2-1.2c0.2-0.3,0.5-0.4,0.8-0.4l5.2,0l1.6-5c0.2-0.5,0.7-0.7,1.1-0.6c0.3,0.1,0.5,0.3,0.6,0.6L27.5,8.1
                    L27.5,8.1z M30,9.9l-3.1,0h0c-0.4,0-0.7-0.2-0.9-0.6l-1-3l-1,3c-0.1,0.4-0.5,0.6-0.9,0.6l-3.2,0l2.5,1.8l0,0l0,0
                    c0.3,0.2,0.4,0.6,0.3,1l-1,3l3.1-1.9l3.1,1.9l-1-3c-0.1-0.4,0-0.8,0.3-1L30,9.9L30,9.9L30,9.9z M13.6,7.9c-1.2,0-2.4,0-3.6,0.2l0,0
                    C8.6,8.3,7.4,8.6,6.1,9v3.4c1.2-0.4,2.4-0.7,3.6-0.9c1.3-0.2,2.6-0.2,3.9-0.1V7.9L13.6,7.9z M4.3,12.1l-1.1,0.3L3.6,14
                    c0,0.1,0,0.3,0,0.4l0,0l-0.3,1.4l1.9-0.6v-0.7h0c-0.5,0-0.9-0.4-0.9-0.9L4.3,12.1L4.3,12.1z M7,14v2l0,0c0,0.4-0.3,0.7-0.6,0.9
                    l-4,1.2l0,0c-0.1,0-0.3,0-0.5,0c-0.5-0.1-0.8-0.6-0.7-1.1l0.6-2.6l-0.6-2.2l0,0c-0.1-0.5,0.1-1,0.6-1.1l2.5-0.7V8.4h0
                    c0-0.4,0.2-0.7,0.6-0.8c1.6-0.6,3.2-0.9,4.8-1.2l0,0c1.6-0.2,3.2-0.3,4.8-0.2h0c0.5,0,0.9,0.4,0.9,0.9v5.3h0l0,0.1
                    c-0.1,0.5-0.5,0.8-1,0.8c-1.5-0.2-3-0.2-4.5,0C8.9,13.4,8,13.6,7,14L7,14z M36.4,7.9c1.2,0,2.4,0,3.6,0.2l0,0
                    c1.3,0.2,2.6,0.5,3.8,0.9v3.4c-1.2-0.4-2.4-0.7-3.6-0.9c-1.3-0.2-2.6-0.2-3.9-0.1V7.9L36.4,7.9z M45.7,12.1l1.1,0.3L46.4,14
                    c0,0.1,0,0.3,0,0.4l0,0l0.3,1.4l-1.9-0.6v-0.7h0c0.5,0,0.9-0.4,0.9-0.9L45.7,12.1L45.7,12.1z M43,14v2l0,0c0,0.4,0.3,0.7,0.6,0.9
                    l4,1.2l0,0c0.1,0,0.3,0,0.5,0c0.5-0.1,0.8-0.6,0.7-1.1l-0.6-2.6l0.6-2.2l0,0c0.1-0.5-0.1-1-0.6-1.1l-2.5-0.7V8.4h0
                    c0-0.4-0.2-0.7-0.6-0.8c-1.6-0.6-3.2-0.9-4.8-1.2l0,0c-1.6-0.2-3.2-0.3-4.8-0.2h0c-0.5,0-0.9,0.4-0.9,0.9v5.3l0,0l0,0.1
                    c0.1,0.5,0.5,0.8,1,0.8c1.5-0.2,3-0.2,4.5,0C41.1,13.4,42,13.6,43,14L43,14z"></path>
                  </g>
                </svg>`,
					title: 'Create Private Tournament'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g id="surface1">
                    <path d="M50,10.6V5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5v22c0,2.6,1.9,4.7,4.5,4.9l3.8,9.8c1,2.6,3.9,3.9,6.5,2.9l23.5-9
                    c-1.6,4.2-1.2,9,1.2,12.8l0.6,0.9V59c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V26c0-3.5-1.4-6.8-3.8-9.2L50,10.6z M50,16.6l3,7.9
                    c0.6,1.5-0.2,3.3-1.7,3.9L49.6,29c0.3-0.6,0.4-1.3,0.4-2V16.6z M2,27V5c0-1.7,1.3-3,3-3h40c1.7,0,3,1.3,3,3v6.4h0l0,0V27
                    c0,0.2,0,0.3-0.1,0.5l-9.2-9.2c-2.1-2-5.3-2-7.4,0c-2.1,2-2.2,5.3-0.3,7.4L35,30H5C3.3,30,2,28.7,2,27z M14.8,32l-7.1,2.7L6.6,32
                    H14.8z M14,42.7c-1.5,0.6-3.3-0.2-3.9-1.7l-1.7-4.4L20.4,32v0h16.5l1.3,1.4L14,42.7z M58,58H42v-8h16V58z M58,48H41.6l-0.4-0.7
                    c-2.4-3.9-2.5-8.8-0.3-12.8c0.2-0.4,0.2-0.9-0.1-1.2l-8.2-8.9c-1.2-1.3-1.2-3.4,0.2-4.7c1.3-1.3,3.4-1.2,4.7,0l15.9,15.9l1.4-1.4
                    L51,30.6l1-0.4c2.6-1,3.9-3.9,2.9-6.5l-3.4-8.8l3.2,3.2c2.1,2.1,3.2,4.9,3.2,7.8V48z"></path>
                    <path d="M11.4,14c1.4,0,2.6-1.2,2.6-2.6V6.6C14,5.2,12.8,4,11.4,4H6.6C5.2,4,4,5.2,4,6.6v4.8C4,12.8,5.2,14,6.6,14H11.4z M6,11.4
                    V10h2V8H6V6.6C6,6.3,6.3,6,6.6,6h4.8C11.7,6,12,6.3,12,6.6V8h-2v2h2v1.4c0,0.3-0.3,0.6-0.6,0.6H6.6C6.3,12,6,11.7,6,11.4z"></path>
                    <path d="M5,17h4v2H5V17z"></path>
                    <path d="M5,23h4v2H5V23z"></path>
                    <path d="M23,23h4v2h-4V23z"></path>
                    <path d="M11,17h4v2h-4V17z"></path>
                    <path d="M17,17h4v2h-4V17z"></path>
                    <path d="M23,17h4v2h-4V17z"></path>
                    <path d="M42,5h2v3h-2V5z"></path>
                    <path d="M38,5h2v3h-2V5z"></path>
                    <path d="M34,5h2v3h-2V5z"></path>
                    <path d="M30,5h2v3h-2V5z"></path>
                    <path d="M44,52h2v2h-2V52z"></path>
                  </g>
                </svg>`,
					title: 'Multiple Payment'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <style type="text/css">
                    .cftst0{display:none;}
                    .cftst1{display:inline;}
                    .cftst2{display:none;opacity:0.31;}
                  </style>
                  <g class="cftst0">
                    <rect x="-320.9" y="-549.7" class="cftst1" width="1370.9" height="1263.2"></rect>
                  </g>
                  <g class="cftst2">
                    <g class="cftst1">
                      <path d="M16,3.2H-4.5c-6,0-10.9,4.9-10.9,10.9v15.6c0,2.9,2.1,5.3,4.9,5.8v14.3c0,3.2,2.6,5.9,5.9,5.9h7.4c3.2,0,5.9-2.6,5.9-5.9
                      V15.5H16v16h59.5v-52.2H16L16,3.2L16,3.2z M5.6,12.5v37.3c0,1.6-1.3,2.9-2.9,2.9h-7.4c-1.6,0-2.9-1.3-2.9-2.9V32.6h-2
                      c-1.6,0-2.9-1.3-2.9-2.9V14.1c0-4.4,3.6-7.9,7.9-7.9h29.8c-0.1,3.5-3,6.3-6.6,6.3C18.7,12.5,5.6,12.5,5.6,12.5z M19-17.7h53.5
                      v46.2H19v-13c5.1-0.2,9.2-4.4,9.2-9.6V3.2H19V-17.7z"></path>
                      <path d="M45.6,17.2c6.7,0,12.1-5.4,12.1-12.1V3.6H47.1V-7h-1.5C39-7,33.5-1.6,33.5,5.1S39,17.2,45.6,17.2z M44.1-3.9V6.6h10.5
                      c-0.7,4.3-4.5,7.6-9,7.6c-5,0-9.1-4.1-9.1-9.1C36.5,0.6,39.8-3.1,44.1-3.9z"></path>
                      <path d="M50.9-9.8V0.8h10.6C61.5-5,56.7-9.8,50.9-9.8z"></path>
                      <path d="M-11.6-9.4C-11.6-3.6-6.9,1.1-1,1.1S9.5-3.6,9.5-9.4S4.8-20-1-20S-11.6-15.3-11.6-9.4z M-1-17c4.2,0,7.6,3.4,7.6,7.6
                      S3.1-1.9-1-1.9s-7.6-3.4-7.6-7.6S-5.2-17-1-17z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M39.5,7.9v2.2c3.8,1.8,7,4.6,9.3,8.1h2.3C48.5,13.6,44.4,10,39.5,7.9z"></path>
                      <path d="M8.8,42.8c2.7,4.6,6.8,8.2,11.7,10.2v-2.2c-3.8-1.8-7-4.6-9.3-8.1H8.8z"></path>
                      <path d="M20.5,7.9c-4.9,2.1-9,5.7-11.7,10.2h2.3c2.3-3.5,5.5-6.3,9.3-8.1V7.9z"></path>
                      <path d="M39.5,53.1c4.9-2.1,9-5.7,11.7-10.2h-2.3c-2.3,3.5-5.5,6.3-9.3,8.1L39.5,53.1L39.5,53.1z"></path>
                      <path d="M30.4,11.7c2.3,0,4.1-1.8,4.1-4.1V6.1c0-2.3-1.8-4.1-4.1-4.1h-0.8c-2.3,0-4.1,1.8-4.1,4.1v1.5c0,2.3,1.8,4.1,4.1,4.1H30.4
                      z M27.5,7.6V6.1c0-1.2,0.9-2.1,2.1-2.1h0.8c1.2,0,2.1,0.9,2.1,2.1v1.5c0,1.2-0.9,2.1-2.1,2.1h-0.8C28.5,9.7,27.5,8.8,27.5,7.6z"></path>
                      <path d="M23.3,17.1v1.1v2.3h13.4v-2.3v-1.1c0-2.8-2.2-5-5-5h-3.4C25.5,12.1,23.3,14.3,23.3,17.1z M25.3,17.1c0-1.7,1.4-3,3-3h3.4
                      c1.7,0,3,1.4,3,3v1.4h-9.4V17.1z"></path>
                      <path d="M30.4,40.5h-0.8c-1.6,0-3,1-3.7,2.3c-0.3,0.5-0.4,1.1-0.4,1.8v1.5c0,2.3,1.8,4.1,4.1,4.1h0.8c2.3,0,4.1-1.8,4.1-4.1v-1.5
                      c0-0.6-0.1-1.2-0.4-1.8C33.4,41.5,32,40.5,30.4,40.5z M32.5,46.1c0,1.2-0.9,2.1-2.1,2.1h-0.8c-1.2,0-2.1-0.9-2.1-2.1v-1.5
                      c0-1.2,0.9-2.1,2.1-2.1h0.8c1.2,0,2.1,0.9,2.1,2.1V46.1z"></path>
                      <path d="M23.3,59h13.4v-3.4c0-2.8-2.2-5-5-5h-3.4c-2.8,0-5,2.2-5,5L23.3,59L23.3,59z M25.3,55.6c0-1.7,1.4-3,3-3h3.4
                      c1.7,0,3,1.4,3,3V57h-9.4V55.6z"></path>
                      <path d="M6.3,31h0.8c2.3,0,4.1-1.8,4.1-4.1v-1.5c0-2.3-1.8-4.1-4.1-4.1H6.3c-2.3,0-4.1,1.8-4.1,4.1v1.5C2.2,29.1,4.1,31,6.3,31z
                      M4.2,25.3c0-1.2,0.9-2.1,2.1-2.1h0.8c1.2,0,2.1,0.9,2.1,2.1v1.5C9.2,28,8.2,29,7.1,29H6.3c-1.2,0-2.1-0.9-2.1-2.1V25.3z"></path>
                      <path d="M13.4,36.3c0-2.8-2.2-5-5-5H5c-2.8,0-5,2.2-5,5v3.4h13.4L13.4,36.3L13.4,36.3z M11.4,37.7H2v-1.4c0-1.7,1.4-3,3-3h3.4
                      c1.7,0,3,1.4,3,3L11.4,37.7L11.4,37.7z"></path>
                      <path d="M53.7,21.3h-0.8c-2.3,0-4.1,1.8-4.1,4.1v1.5c0,2.3,1.8,4.1,4.1,4.1h0.8c2.3,0,4.1-1.8,4.1-4.1v-1.5
                      C57.8,23.1,55.9,21.3,53.7,21.3z M55.8,26.9c0,1.2-0.9,2.1-2.1,2.1h-0.8c-1.2,0-2.1-0.9-2.1-2.1v-1.5c0-1.2,0.9-2.1,2.1-2.1h0.8
                      c1.2,0,2.1,0.9,2.1,2.1V26.9z"></path>
                      <path d="M51.6,31.3c-2.8,0-5,2.2-5,5v3.4H60v-3.4c0-2.8-2.2-5-5-5H51.6z M58,36.3v1.4h-9.4v-1.4c0-1.7,1.4-3,3-3H55
                      C56.7,33.3,58,34.7,58,36.3z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Create Fantasy Team'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M57.3,34.4c-1.2-1.1-2.4-1.6-2.7-1.7l-4-1.7l-2.8-1.9v-1c2.1-1.1,3.7-3.2,4-5.6c1.8-0.2,3.2-1.8,3.2-3.6
                      c0-0.9-0.4-1.8-1-2.5V13c0-5.6-4.6-10.2-10.2-10.2h-0.8c-4.6,0-8.6,3.1-9.8,7.5c-0.9-0.2-1.8-0.4-2.8-0.4h-0.8c-1,0-2,0.1-2.9,0.4
                      c-1.2-4.5-5.2-7.6-9.8-7.6h-0.8C10.5,2.9,6,7.4,6,13v3.5C5.4,17.2,5,18,5,19c0,1.8,1.4,3.4,3.2,3.6c0.4,2.4,1.9,4.5,4,5.6v1
                      L9.4,31l-4,1.7c-0.2,0.1-1.5,0.6-2.7,1.7C1.5,35.6,0,37.6,0,40.9v5.8c0,0.5,0.4,0.9,0.9,0.9h11.9c-0.2,0.7-0.3,1.6-0.3,2.5v6.1
                      c0,0.5,0.4,0.9,0.9,0.9h11.3h2.5h5.7h0h2.3h0.5c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.5h-1.5l-1.1-7.1l0.4-1.2l0.7,0.5
                      c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.3,0.6-0.5l2.6-5.3l3.4,1.4c0,0,0,0,0.1,0c0,0,4.3,1.5,4.3,6.8v5.1h-3.9
                      v-5.4c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v5.4h-1c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h7.8c0.5,0,0.9-0.4,0.9-1v-6.1
                      c0-0.9-0.1-1.7-0.3-2.5h11.9c0.5,0,0.9-0.4,0.9-1v-5.7C60,37.6,58.6,35.6,57.3,34.4z M50,21.4c0,3.2-2.6,5.8-5.8,5.8h-1.5
                      c-0.2,0-0.4,0-0.6,0c0-0.1,0-0.1,0-0.2c0-1-0.4-1.9-1-2.6v-3.7c0-1.7-0.4-3.3-1.1-4.7c2.1-0.5,4.5-1.5,6.4-3.3
                      c0.6,1.3,1.7,2.9,3.6,3.6L50,21.4L50,21.4z M45.9,28.9v1.9l-2.4,2.5l-2.4-2.3c0,0,0,0,0,0v-1.4c0,0,0,0,0,0v0
                      c0.2-0.2,0.3-0.4,0.5-0.6c0.4,0.1,0.8,0.1,1.2,0.1h1.5C44.8,29.1,45.4,29,45.9,28.9z M40.3,27c0,0.5-0.2,0.9-0.5,1.2
                      c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.2v-3.6C39.7,25.4,40.3,26.1,40.3,27z M53.1,19c0,0.8-0.5,1.4-1.2,1.6v-3.3
                      C52.6,17.6,53.1,18.2,53.1,19z M35.1,11c0.9-3.7,4.2-6.2,8-6.2h0.8c4.6,0,8.3,3.7,8.3,8.3v2.4c-0.1,0-0.2,0-0.2,0
                      c-0.1-0.3-0.4-0.6-0.7-0.7c-2.7-0.6-3.5-4-3.5-4C47.6,10.3,47.3,10,47,10c-0.4-0.1-0.7,0.1-1,0.4c-1.8,2.5-4.7,3.5-7,4
                      c-1-1.4-2.4-2.5-4-3.2C35.1,11.1,35.1,11,35.1,11z M29.7,12h0.8v0c4.8,0,8.8,3.9,8.8,8.8v2.6c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0
                      c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                      c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0
                      c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-2.8-0.6-3.7-4.2-3.7-4.3c-0.1-0.4-0.4-0.6-0.7-0.7c-0.4-0.1-0.7,0.1-1,0.4
                      c-3.3,4.6-10.5,4.6-10.6,4.6c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
                      c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                      c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                      c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0.1v-2.6C20.9,15.9,24.8,12,29.7,12z M30.1,42.2l-2.5-2.5c0,0,0,0,0-0.1v-1.5
                      c0,0,0,0,0,0v-0.8c0.6,0.1,1.2,0.2,1.8,0.2h1.5c0.6,0,1.2-0.1,1.8-0.2c0,0,0,0,0.1,0v2.1L30.1,42.2z M31.5,45.8L31,47.1h-1.8
                      l-0.6-1.3l1.4-1L31.5,45.8z M17.3,38h-1.6l-0.4-1.2l1.3-0.9l1.3,0.9L17.3,38z M17.5,41.8c-0.5,0.3-1.3,0.7-2.1,1.5c0,0,0,0,0,0
                      l0.5-3.4h1.3L17.5,41.8z M16.5,33.3l-2.4-2.5v-1.9c0.5,0.1,1.1,0.2,1.7,0.2h1.5c0.5,0,0.9,0,1.4-0.1c0.1,0.2,0.2,0.3,0.3,0.4v0.2
                      c0,0,0,0,0,0V31c0,0,0,0,0,0L16.5,33.3z M20.5,32.1c0.2-0.2,0.3-0.5,0.3-0.8l0.8,0.5c0.1,0.3,0.2,0.6,0.3,0.8l-1.7,3.5L18,34.6
                      L20.5,32.1z M19.9,27c0-0.8,0.6-1.6,1.3-1.8v3.6C20.5,28.5,19.9,27.8,19.9,27z M19.4,38l0.6,0.5c0.2,0.1,0.4,0.2,0.6,0.2
                      c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.3,0.6-0.5l1.7-3.5c0.7,0.8,1.5,1.5,2.5,2v1l-3,2c0,0-0.1,0-0.1,0L19.2,41l-0.3-1.9L19.4,38z
                      M25.7,39.9c-0.1,0.3,0,0.7,0.3,0.9l2.7,2.6l-2.5,1.8l-2.1-4.3L25.7,39.9z M30.9,35.7h-1.5c-3.4,0-6.2-2.8-6.2-6.2v-5.2
                      c2-0.1,6.8-0.8,10.1-4c0.6,1.3,1.8,3.1,3.8,3.8v5.4h0C37,32.9,34.3,35.7,30.9,35.7z M8.1,20.6c-0.7-0.2-1.2-0.9-1.2-1.6
                      s0.5-1.4,1.2-1.6V20.6z M9,14.7C9,14.7,9,14.7,9,14.7C9,14.7,9,14.7,9,14.7C9,14.7,9,14.7,9,14.7c-0.5,0-0.8,0.3-0.9,0.7
                      c-0.1,0-0.2,0-0.2,0V13c0-4.6,3.7-8.3,8.3-8.3h0.8c3.8,0,7.1,2.6,8,6.2c0,0.1,0,0.1,0.1,0.2c-1.2,0.6-2.4,1.5-3.3,2.5
                      c-1.1-1.1-1.5-2.7-1.5-2.7c-0.1-0.4-0.4-0.6-0.7-0.7c-0.4-0.1-0.7,0.1-1,0.4C15.6,14.6,9.1,14.7,9,14.7C9,14.7,9,14.7,9,14.7z
                      M10,21.4v-4.8c1.9-0.1,6.1-0.8,9-3.6c0.3,0.7,0.9,1.5,1.6,2.2c-1,1.6-1.6,3.5-1.6,5.5v3.7c-0.6,0.7-1,1.6-1,2.5c0,0,0,0.1,0,0.1
                      c-0.3,0-0.5,0.1-0.8,0.1h-1.5C12.6,27.2,10,24.6,10,21.4z M12.2,31.4c0,0.2,0.1,0.3,0.2,0.5l2.6,2.7l-2.2,1.6l-1.9-4L12.2,31.4z
                      M13,45.7H7.4v-5.1c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v5.1H1.9v-4.8c0-5,3.9-6.4,4.1-6.5c0,0,0,0,0.1,0l3.2-1.3l2.4,5
                      c0.1,0.3,0.4,0.4,0.6,0.5c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2l0.6-0.4l0.4,1.1L13,45.7z M26.4,55.2h-1.7h-4.5v0v-5.4
                      c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9v5.4h-3.9v-5.1c0-5.3,4.2-6.8,4.3-6.8c0,0,0,0,0.1,0l3.5-1.5l2.6,5.3
                      c0.1,0.3,0.4,0.4,0.6,0.5c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2l0.7-0.5l0.5,1.2L26.4,55.2z M28.4,55.2l1-6.2h1.4l1,6.2H28.4z
                      M33.9,45.2l-0.7-0.5c0,0,0,0,0,0l-1.7-1.2l2.8-2.9c0.1-0.2,0.2-0.3,0.3-0.5l1.5,1L33.9,45.2z M40.8,41l-3.2-1.3l-3-2c0,0,0,0,0,0
                      v-1.1c0.9-0.5,1.7-1.1,2.4-1.9l1.6,3.3c0.1,0.3,0.4,0.4,0.6,0.5c0.1,0,0.2,0,0.2,0c0.2,0,0.4-0.1,0.6-0.2l0.6-0.5l0.5,1.1L40.8,41
                      z M39.8,36.2l-1.6-3.3c0.2-0.4,0.3-0.8,0.5-1.2l0.6-0.4c0,0.3,0.1,0.6,0.3,0.8l2.5,2.5L39.8,36.2z M44.6,43.3
                      c-0.8-0.7-1.6-1.2-2.1-1.5l0.3-1.9h1.3L44.6,43.3C44.7,43.3,44.7,43.3,44.6,43.3z M44.3,38h-1.6l-0.5-1.2l1.3-0.9l1.3,0.9L44.3,38
                      z M44.9,34.6l2.6-2.7c0.1-0.1,0.2-0.3,0.2-0.5l1.3,0.9l-1.9,4L44.9,34.6z M54.5,45.7v-5.1c0-0.5-0.4-0.9-0.9-0.9
                      c-0.5,0-0.9,0.4-0.9,0.9v5.1H47l-1.1-6.7l0.4-1.1l0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.2,0c0.3-0.1,0.5-0.3,0.6-0.5
                      l2.4-5l3.2,1.3c0,0,0,0,0.1,0c0,0,4.1,1.4,4.1,6.5v4.8L54.5,45.7L54.5,45.7z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Create Multiple Team'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M39.2,19.1c-0.1-0.4-0.5-0.7-0.9-0.8l-5-0.7L31,13c-0.2-0.4-0.6-0.6-1-0.6h0c-0.4,0-0.8,0.2-1,0.6l-2.2,4.5l-5,0.7
                      c-0.4,0.1-0.8,0.4-0.9,0.8c-0.1,0.4,0,0.9,0.3,1.2l3.6,3.5l-0.9,5c-0.1,0.4,0.1,0.9,0.5,1.1c0.2,0.1,0.4,0.2,0.7,0.2
                      c0.2,0,0.4,0,0.5-0.1l4.5-2.4l4.5,2.3c0.4,0.2,0.9,0.2,1.2-0.1c0.4-0.3,0.5-0.7,0.5-1.1l-0.9-5l3.6-3.5
                      C39.2,19.9,39.3,19.5,39.2,19.1z M33.2,22.5c-0.3,0.3-0.4,0.6-0.3,1l0.6,3.3l-2.9-1.5c-0.3-0.2-0.7-0.2-1.1,0l-2.9,1.5l0.6-3.3
                      c0.1-0.4-0.1-0.8-0.3-1l-2.4-2.3l3.3-0.5c0.4-0.1,0.7-0.3,0.9-0.6l1.5-3l1.5,3c0.2,0.3,0.5,0.6,0.9,0.6l3.3,0.5L33.2,22.5z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M55.8,51.1l-9.5-16.5c2.9-3.6,4.6-8.1,4.6-13.1C50.9,10,41.5,0.6,30,0.6S9.1,10,9.1,21.5c0,4.9,1.7,9.5,4.6,13.1L4.2,51.1
                      c-0.2,0.4-0.2,0.9,0,1.2c0.2,0.4,0.7,0.6,1.1,0.5l7.4-1l2.8,6.9c0.2,0.4,0.5,0.7,1,0.7c0,0,0.1,0,0.1,0c0.4,0,0.8-0.2,1-0.6
                      l9.6-16.6c0,0,0,0,0,0c0.9,0.1,1.8,0.2,2.8,0.2s1.9-0.1,2.8-0.2c0,0,0,0,0,0l9.6,16.6c0.2,0.4,0.6,0.6,1,0.6c0,0,0.1,0,0.1,0
                      c0.4,0,0.8-0.3,1-0.7l2.8-6.9l7.4,1c0.4,0.1,0.9-0.1,1.1-0.5C56,51.9,56,51.5,55.8,51.1z M16.8,55.7l-2.1-5l1.9-3
                      c0.3-0.5,0.2-1.2-0.4-1.6c-0.5-0.3-1.2-0.2-1.6,0.4l-1.9,3.1l-5.4,0.7l8-13.8c2.6,2.6,5.9,4.5,9.6,5.4L16.8,55.7z M30,40.1
                      c-10.3,0-18.6-8.4-18.6-18.6S19.7,2.9,30,2.9s18.6,8.4,18.6,18.6S40.3,40.1,30,40.1z M47.2,49.5l-1.9-3.1c-0.3-0.5-1-0.7-1.6-0.4
                      c-0.5,0.3-0.7,1-0.4,1.6l1.9,3l-2.1,5l-8-13.9c3.7-0.9,6.9-2.8,9.6-5.4l8,13.8L47.2,49.5z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M30,6.6c-2.6,0-5.1,0.7-7.3,1.9c-0.6,0.3-0.7,1-0.4,1.6s1,0.7,1.6,0.4c1.9-1.1,4-1.6,6.2-1.6c7,0,12.6,5.7,12.6,12.6
                      c0,1.9-0.4,3.7-1.2,5.4c-0.3,0.6,0,1.3,0.5,1.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.7c1-2,1.5-4.2,1.5-6.4
                      C44.9,13.3,38.2,6.6,30,6.6z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M40.7,30.3c-0.5-0.4-1.2-0.4-1.6,0l0,0c-0.4,0.5-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l0,0
                      C41.2,31.4,41.1,30.7,40.7,30.3z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M37.7,33c-0.3-0.6-1-0.8-1.6-0.5c-1.9,1-4,1.6-6.1,1.6c-7,0-12.6-5.7-12.6-12.6c0-1.8,0.4-3.6,1.1-5.2
                      c0.3-0.6,0-1.3-0.6-1.5c-0.6-0.3-1.3,0-1.5,0.6c-0.9,1.9-1.3,4-1.3,6.2c0,8.2,6.7,14.9,14.9,14.9c2.5,0,5-0.6,7.2-1.8
                      C37.8,34.3,38,33.6,37.7,33z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M20.7,11.3c-0.5-0.4-1.2-0.4-1.6,0.1l0,0c-0.4,0.5-0.4,1.2,0.1,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.4l0,0
                      C21.2,12.4,21.2,11.7,20.7,11.3z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Reward'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <style type="text/css">
                    .invernst0{fill:#010101;}
                  </style>
                  <g>
                    <g>
                      <path class="invernst0" d="M16,18.3c3.6,0,6.5-2.9,6.5-6.4s-3-6.4-6.5-6.4s-6.5,2.9-6.5,6.4C9.5,15.3,12.4,18.3,16,18.3z M16,7.2
                      c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7S13.4,7.2,16,7.2z"></path>
                    </g>
                    <g>
                      <path class="invernst0" d="M16,0.1C8,0.1,1.5,6.7,1.5,14.6c0,8,6.5,14.5,14.5,14.5s14.5-6.5,14.5-14.5C30.6,6.7,24,0.1,16,0.1z M16,1.9
                      c7,0,12.7,5.7,12.7,12.7c0,3-1.1,5.8-2.9,8c-1.4-2.6-4.2-4.2-7.2-4.2h-5.3c-3,0-5.8,1.6-7.2,4.2c-1.8-2.1-2.9-5-2.9-8
                      C3.3,7.7,9,1.9,16,1.9z M7.5,24c1.1-2.2,3.4-3.8,5.9-3.8h5.3c2.6,0,4.8,1.5,5.9,3.8c-2.2,2.1-5.3,3.3-8.5,3.3
                      C12.8,27.3,9.7,26.1,7.5,24z"></path>
                    </g>
                    <g>
                      <path class="invernst0" d="M45.3,25.5c0.2,0.3,0.4,0.4,0.7,0.4l0,0c0.3,0,0.5-0.2,0.7-0.4l8-11c0.2-0.3,0.3-0.6,0.1-0.9
                      c-0.2-0.3-0.4-0.4-0.8-0.4l0,0h-3.6L50.3,1c0-0.4-0.4-0.9-0.9-0.9h-7.1c-0.3,0-0.4,0.1-0.6,0.3S41.5,0.8,41.5,1l0.1,12H38
                      c-0.4,0-0.6,0.2-0.8,0.5c-0.2,0.3-0.1,0.6,0.1,0.9L45.3,25.5z M42.4,14.8c0.3,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6l-0.1-12
                      h5.3l0.1,11.9c0,0.4,0.4,0.9,0.9,0.9l0,0h2.8L46,23.3l-6.4-8.6L42.4,14.8z"></path>
                    </g>
                    <g>
                      <path class="invernst0" d="M16.7,33.9c-0.2-0.3-0.4-0.4-0.7-0.4c-0.3,0-0.5,0.2-0.7,0.4L7.1,45c-0.2,0.3-0.3,0.6-0.1,0.9
                      c0.2,0.3,0.5,0.5,0.8,0.4h3.7l0.1,12c0,0.4,0.4,0.9,0.9,0.9h7.2c0.3,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.3-0.6l-0.1-12h3.7
                      c0.4,0,0.6-0.2,0.8-0.5c0.2-0.3,0.1-0.6-0.1-0.9L16.7,33.9z M19.6,44.5c-0.3,0-0.4,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.6l0.1,12
                      h-5.4l-0.1-11.9c0-0.5-0.4-0.9-0.9-0.9H9.6l6.4-8.7l6.4,8.6H19.6z"></path>
                    </g>
                    <path d="M46,59.2c6.9,0,12.5-5.6,12.5-12.5S52.9,34.1,46,34.1s-12.5,5.6-12.5,12.5C33.5,53.6,39.1,59.2,46,59.2z M46,35.9
                    c5.9,0,10.7,4.8,10.7,10.7S51.9,57.4,46,57.4s-10.7-4.8-10.7-10.7C35.3,40.7,40.1,35.9,46,35.9z"></path>
                    <path d="M38.8,46.7c0-3.9,3.2-7.2,7.2-7.2v-1.8c-4.9,0-8.9,4-8.9,8.9H38.8z"></path>
                    <path d="M45.1,42.2c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7h1.8c0.5,0,0.9,0.4,0.9,0.9s-0.4,0.9-0.9,0.9h-3.6v1.8h1.8V52h1.8v-0.9
                    c1.5,0,2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7h-1.8c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h3.6v-1.8h-1.8v-0.9h-1.8V42.2z"></path>
                  </g>
                </svg>`,
					title: 'Invite and Earn'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M50.3,54.2H4.5c-1.1,0-1.9-0.9-1.9-1.9V25.6c0-1.1,0.9-1.9,1.9-1.9h1.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H4.5
                      c-2,0-3.6,1.6-3.6,3.6v26.7c0,2,1.6,3.6,3.6,3.6h45.8c0.5,0,0.9-0.4,0.9-0.9S50.8,54.2,50.3,54.2z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M55.5,21.9H9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h46.5c1.1,0,1.9,0.9,1.9,1.9v26.7c0,1.1-0.9,1.9-1.9,1.9h-1.5
                      c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h1.5c2,0,3.6-1.6,3.6-3.6V25.6C59.1,23.6,57.5,21.9,55.5,21.9z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M55.3,24.9H4.7c-0.5,0-0.9,0.4-0.9,0.9v26.4c0,0.5,0.4,0.9,0.9,0.9h50.7c0.5,0,0.9-0.4,0.9-0.9V25.7
                      C56.2,25.3,55.8,24.9,55.3,24.9z M54.5,51.2H5.5V26.6h48.9V51.2z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M33.4,47.7h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S33.9,47.7,33.4,47.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M17.8,28.4h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S18.3,28.4,17.8,28.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M17.8,38h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S18.3,38,17.8,38z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M8.4,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C9.2,30.1,8.9,29.7,8.4,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M20.1,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C21,30.1,20.6,29.7,20.1,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M17.8,47.7h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S18.3,47.7,17.8,47.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M8.4,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C9.2,39.8,8.9,39.4,8.4,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M20.1,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C21,39.8,20.6,39.4,20.1,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M33.4,28.4h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S33.9,28.4,33.4,28.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M33.4,38h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h7.1c0.5,0,0.9-0.4,0.9-0.9S33.9,38,33.4,38z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M24,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C24.9,30.1,24.5,29.7,24,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M35.8,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C36.6,30.1,36.2,29.7,35.8,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M24,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C24.9,39.8,24.5,39.4,24,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M35.8,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C36.6,39.8,36.2,39.4,35.8,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M49,28.4h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H49c0.5,0,0.9-0.4,0.9-0.9S49.5,28.4,49,28.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M49,38h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H49c0.5,0,0.9-0.4,0.9-0.9S49.5,38,49,38z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M39.6,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C40.5,30.1,40.1,29.7,39.6,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M51.4,29.7c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C52.2,30.1,51.8,29.7,51.4,29.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M49,47.7h-7.1c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H49c0.5,0,0.9-0.4,0.9-0.9S49.5,47.7,49,47.7z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M39.6,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C40.5,39.8,40.1,39.4,39.6,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M51.4,39.4c-0.5,0-0.9,0.4-0.9,0.9v7.1c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-7.1C52.2,39.8,51.8,39.4,51.4,39.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M38.3,12.4c0.5-0.5,0.7-1.2,0.5-1.9c-0.2-0.7-0.8-1.2-1.5-1.3l-2.5-0.4c-0.5-0.1-0.9,0.3-1,0.7c-0.1,0.5,0.3,0.9,0.7,1
                      l2.5,0.4c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2L35,13.3c-0.8,0.8-1.2,1.9-1,3.1l0.5,2.9c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0
                      l-3.4-1.8c-0.5-0.3-1.2-0.3-1.7,0l-3.4,1.8c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2l0.5-2.9c0.2-1.1-0.2-2.3-1-3.1l-2.1-2.1
                      c0,0-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1l2.9-0.4c1.1-0.2,2.1-0.9,2.6-1.9l1.3-2.7c0-0.1,0.1-0.1,0.2-0.1s0.1,0,0.2,0.1l1.1,2.3
                      c0.2,0.4,0.7,0.6,1.1,0.4c0.4-0.2,0.6-0.7,0.4-1.1l-1.1-2.3c-0.3-0.6-1-1-1.7-1s-1.4,0.4-1.7,1L27,7.8c-0.3,0.5-0.8,0.9-1.3,1
                      l-2.9,0.4c-0.7,0.1-1.3,0.6-1.5,1.3c-0.2,0.7,0,1.4,0.5,1.9l2.1,2.1c0.4,0.4,0.6,1,0.5,1.6L23.8,19c-0.1,0.7,0.2,1.4,0.7,1.8
                      c0.6,0.4,1.3,0.5,2,0.1l3.4-1.8c0,0,0.1,0,0.2,0l3.4,1.8c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.4c0.6-0.4,0.9-1.1,0.7-1.8
                      l-0.5-2.9c-0.1-0.6,0.1-1.2,0.5-1.6L38.3,12.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M20.5,13.6c0.5-0.5,0.6-1.1,0.4-1.8c-0.2-0.6-0.7-1.1-1.4-1.2l-2.5-0.4c-0.4-0.1-0.8-0.3-1-0.7l-1.1-2.3
                      c-0.3-0.6-0.9-1-1.5-1c-0.7,0-1.2,0.4-1.5,1l-1.1,2.3c-0.2,0.4-0.6,0.7-1,0.7l-2.5,0.4c-0.7,0.1-1.2,0.5-1.4,1.2
                      c-0.2,0.6,0,1.3,0.4,1.8l1.8,1.8c0.3,0.3,0.5,0.8,0.4,1.2L8,19.1c-0.1,0.6,0.2,1.3,0.7,1.7c0.5,0.4,1.2,0.4,1.8,0.1l2.9-1.5
                      l2.9,1.5c0.3,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,1-0.3c0.5-0.4,0.8-1,0.7-1.7l-0.4-2.5c-0.1-0.4,0.1-0.9,0.4-1.2L20.5,13.6z
                      M17.5,14.1c-0.7,0.7-1.1,1.7-0.9,2.7l0.4,2.5c0,0,0,0,0,0l-2.9-1.5c-0.5-0.3-1.1-0.3-1.6,0l-2.9,1.5c0,0,0,0,0,0l0.4-2.5
                      c0.2-1-0.2-2-0.9-2.7l-1.8-1.8c0,0,0,0,0,0L9.9,12c1-0.1,1.9-0.8,2.3-1.7l1.1-2.3c0,0,0,0,0,0c0,0,0,0,0,0l1.1,2.3
                      c0.4,0.9,1.3,1.5,2.3,1.7l2.5,0.4c0,0,0,0,0,0L17.5,14.1z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M53.7,13.6c0.5-0.5,0.6-1.1,0.4-1.8c-0.2-0.6-0.7-1.1-1.4-1.2l-2.5-0.4c-0.4-0.1-0.8-0.3-1-0.7l-1.1-2.3
                      c-0.3-0.6-0.9-1-1.5-1c0,0,0,0,0,0c-0.7,0-1.2,0.4-1.5,1L44,9.6c-0.2,0.4-0.6,0.7-1,0.7l-2.5,0.4c-0.7,0.1-1.2,0.5-1.4,1.2
                      c-0.2,0.6,0,1.3,0.4,1.8l1.8,1.8c0.3,0.3,0.5,0.8,0.4,1.2l-0.4,2.5c-0.1,0.6,0.2,1.3,0.7,1.7c0.3,0.2,0.7,0.3,1,0.3
                      c0.3,0,0.5-0.1,0.8-0.2l2.9-1.5l2.9,1.5c0.6,0.3,1.3,0.3,1.8-0.1c0.5-0.4,0.8-1,0.7-1.7l-0.4-2.5c-0.1-0.4,0.1-0.9,0.4-1.2
                      L53.7,13.6z M50.7,14.1c-0.7,0.7-1.1,1.7-0.9,2.7l0.4,2.5c0,0,0,0,0,0l-2.9-1.5c-0.5-0.3-1.1-0.3-1.6,0L43,19.4c0,0,0,0,0,0
                      l0.4-2.5c0.2-1-0.2-2-0.9-2.7l-1.8-1.8c0,0,0,0,0,0l2.5-0.4c1-0.1,1.9-0.8,2.3-1.7l1.1-2.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                      l1.1,2.3c0.4,0.9,1.3,1.5,2.3,1.7l2.5,0.4c0,0,0,0,0,0L50.7,14.1z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Live Score'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <path d="M6,1v58c0,0.6,0.4,1,1,1h46c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1H7C6.4,0,6,0.4,6,1z M8,2h44v56H8V2z"></path>
                  <path d="M49.2,10.1l-2.2-0.3l-1-2.1C45.7,7.2,45.4,7,45,7s-0.7,0.2-0.9,0.6l-1,2.2l-2.2,0.3c-0.4,0.1-0.7,0.3-0.8,0.7s0,0.7,0.2,1
                  l1.6,1.7l-0.4,2.4c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.2,0.7,0.2,1.1,0.1l2-1.1l2,1.1c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4-0.1,0.6-0.2
                  c0.3-0.2,0.5-0.6,0.4-1l-0.4-2.4l1.6-1.7c0.3-0.3,0.4-0.7,0.2-1S49.5,10.1,49.2,10.1z M46.3,12.4C46.1,12.6,46,13,46,13.3l0.1,0.9
                  l-0.7-0.4c-0.2-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1l-0.7,0.4l0.1-0.9c0.1-0.3,0-0.6-0.3-0.9l-0.6-0.7l0.8-0.1
                  c0.3-0.1,0.6-0.3,0.8-0.6l0.3-0.7l0.3,0.7c0.1,0.3,0.4,0.5,0.8,0.6l0.8,0.1L46.3,12.4z"></path>
                  <path d="M18,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S22.4,4,18,4z M15,17.2V17c0-0.8,0.3-1.6,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9
                  c1.7,0,3,1.3,3,3v0.2c-0.9,0.5-1.9,0.8-3,0.8S15.9,17.7,15,17.2z M17,11c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S17,11.6,17,11z
                  M22.8,15.6c-0.4-1.3-1.2-2.3-2.3-2.9c0.3-0.5,0.5-1.1,0.5-1.7c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.6,0.2,1.2,0.5,1.7
                  c-0.4,0.2-0.7,0.5-1.1,0.8c-0.6,0.6-1,1.3-1.2,2.1c-0.8-1-1.2-2.3-1.2-3.6c0-3.3,2.7-6,6-6s6,2.7,6,6C24,13.4,23.5,14.6,22.8,15.6z"></path>
                  <path d="M18,22c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S22.4,22,18,22z M15,35.2V35c0-0.8,0.3-1.6,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9
                  c1.7,0,3,1.3,3,3v0.2c-0.9,0.5-1.9,0.8-3,0.8S15.9,35.7,15,35.2z M17,29c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S17,29.6,17,29z
                  M22.8,33.6c-0.4-1.3-1.2-2.3-2.3-2.9c0.3-0.5,0.5-1.1,0.5-1.7c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.6,0.2,1.2,0.5,1.7
                  c-0.4,0.2-0.7,0.5-1.1,0.8c-0.6,0.6-1,1.3-1.2,2.1c-0.8-1-1.2-2.3-1.2-3.6c0-3.3,2.7-6,6-6s6,2.7,6,6C24,31.4,23.5,32.6,22.8,33.6z"></path>
                  <path d="M18,40c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S22.4,40,18,40z M15,53.2V53c0-0.8,0.3-1.6,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9
                  c1.7,0,3,1.3,3,3v0.2c-0.9,0.5-1.9,0.8-3,0.8S15.9,53.7,15,53.2z M17,47c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S17,47.6,17,47z
                  M22.8,51.6c-0.4-1.3-1.2-2.3-2.3-2.9c0.3-0.5,0.5-1.1,0.5-1.7c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.6,0.2,1.2,0.5,1.7
                  c-0.4,0.2-0.7,0.5-1.1,0.8c-0.6,0.6-1,1.3-1.2,2.1c-0.8-1-1.2-2.3-1.2-3.6c0-3.3,2.7-6,6-6s6,2.7,6,6C24,49.4,23.5,50.6,22.8,51.6z"></path>
                  <rect x="28" y="11" width="2" height="2"></rect>
                  <rect x="32" y="11" width="6" height="2"></rect>
                  <rect x="28" y="29" width="2" height="2"></rect>
                  <rect x="32" y="29" width="6" height="2"></rect>
                  <rect x="28" y="41" width="2" height="2"></rect>
                  <rect x="32" y="41" width="6" height="2"></rect>
                  <path d="M45,26c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S47.2,26,45,26z M45,32c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S46.1,32,45,32z"></path>
                  <path d="M48,38h-6c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6C49,38.4,48.6,38,48,38z M47,44h-4v-4h4V44z"></path>
                </svg>`,
					title: 'Leaderboard'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M21.8,9.6h17.5c0.6,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H21.8c-0.6,0-1.2,0.5-1.2,1.2C20.6,9.1,21.1,9.6,21.8,9.6z"></path>
                    <path d="M21.8,19.2h17.5c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H21.8c-0.6,0-1.2,0.5-1.2,1.2S21.1,19.2,21.8,19.2z"></path>
                    <path d="M21.8,28.8h14c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-14c-0.6,0-1.2,0.5-1.2,1.2S21.1,28.8,21.8,28.8z"></path>
                    <path d="M28.8,50.4h-7c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7c0.6,0,1.2-0.5,1.2-1.2C30,50.9,29.4,50.4,28.8,50.4z"></path>
                    <path d="M17.1,4.8h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0v4.8c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0V6C18.3,5.3,17.8,4.8,17.1,4.8C17.1,4.8,17.1,4.8,17.1,4.8z M15.9,9.6h-2.3V7.2h2.3V9.6z"></path>
                    <path d="M17.1,14.4h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0v4.8c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,14.9,17.8,14.4,17.1,14.4C17.1,14.4,17.1,14.4,17.1,14.4z M15.9,19.2h-2.3v-2.4h2.3V19.2z"></path>
                    <path d="M17.1,24h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0V30c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,24.5,17.8,24,17.1,24C17.1,24,17.1,24,17.1,24z M15.9,28.8h-2.3v-2.4h2.3V28.8z"></path>
                    <path d="M17.1,48h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0V54c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,48.5,17.8,48,17.1,48C17.1,48,17.1,48,17.1,48z M15.9,52.8h-2.3v-2.4h2.3V52.8z"></path>
                    <path d="M58,44.4c0-6.8-5.1-12.5-11.7-13.1V1.2c0-0.7-0.5-1.2-1.2-1.2c0,0,0,0,0,0H7.8C7.1,0,6.6,0.5,6.6,1.2c0,0,0,0,0,0v57.6
                    c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h37.4c0.6,0,1.2-0.5,1.2-1.2c0,0,0,0,0,0v-1.3C52.9,56.9,58,51.2,58,44.4z M8.9,57.6V2.4h35
                    v28.9c-7.1,0.7-12.3,7.1-11.6,14.3C32.9,51.9,37.8,57,44,57.5v0.1H8.9z M34.6,44.4c0-6,4.7-10.8,10.5-10.8s10.5,4.8,10.5,10.8
                    S51,55.2,45.2,55.2C39.3,55.2,34.6,50.4,34.6,44.4z"></path>
                    <path d="M49.8,45.6c0.6,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2h-3.5v-3.6c0-0.7-0.5-1.2-1.2-1.2S44,38.9,44,39.6v4.8
                    c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0H49.8z"></path>
                  </g>
                </svg>`,
					title: 'Playing History'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M21.8,9.6h17.5c0.6,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H21.8c-0.6,0-1.2,0.5-1.2,1.2C20.6,9.1,21.1,9.6,21.8,9.6z"></path>
                    <path d="M21.8,19.2h17.5c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H21.8c-0.6,0-1.2,0.5-1.2,1.2S21.1,19.2,21.8,19.2z"></path>
                    <path d="M21.8,28.8h14c0.6,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-14c-0.6,0-1.2,0.5-1.2,1.2S21.1,28.8,21.8,28.8z"></path>
                    <path d="M28.8,50.4h-7c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h7c0.6,0,1.2-0.5,1.2-1.2C30,50.9,29.4,50.4,28.8,50.4z"></path>
                    <path d="M17.1,4.8h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0v4.8c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0V6C18.3,5.3,17.8,4.8,17.1,4.8C17.1,4.8,17.1,4.8,17.1,4.8z M15.9,9.6h-2.3V7.2h2.3V9.6z"></path>
                    <path d="M17.1,14.4h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0v4.8c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,14.9,17.8,14.4,17.1,14.4C17.1,14.4,17.1,14.4,17.1,14.4z M15.9,19.2h-2.3v-2.4h2.3V19.2z"></path>
                    <path d="M17.1,24h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0V30c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,24.5,17.8,24,17.1,24C17.1,24,17.1,24,17.1,24z M15.9,28.8h-2.3v-2.4h2.3V28.8z"></path>
                    <path d="M17.1,48h-4.7c-0.6,0-1.2,0.5-1.2,1.2c0,0,0,0,0,0V54c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h4.7c0.6,0,1.2-0.5,1.2-1.2
                    c0,0,0,0,0,0v-4.8C18.3,48.5,17.8,48,17.1,48C17.1,48,17.1,48,17.1,48z M15.9,52.8h-2.3v-2.4h2.3V52.8z"></path>
                    <path d="M58,44.4c0-6.8-5.1-12.5-11.7-13.1V1.2c0-0.7-0.5-1.2-1.2-1.2c0,0,0,0,0,0H7.8C7.1,0,6.6,0.5,6.6,1.2c0,0,0,0,0,0v57.6
                    c0,0.7,0.5,1.2,1.2,1.2c0,0,0,0,0,0h37.4c0.6,0,1.2-0.5,1.2-1.2c0,0,0,0,0,0v-1.3C52.9,56.9,58,51.2,58,44.4z M8.9,57.6V2.4h35
                    v28.9c-7.1,0.7-12.3,7.1-11.6,14.3C32.9,51.9,37.8,57,44,57.5v0.1H8.9z M34.6,44.4c0-6,4.7-10.8,10.5-10.8s10.5,4.8,10.5,10.8
                    S51,55.2,45.2,55.2C39.3,55.2,34.6,50.4,34.6,44.4z"></path>
                    <path d="M51.7,38.3h-2.1v-1c0-0.4-0.3-0.7-0.7-0.7h-7.3c-0.4,0-0.7,0.3-0.7,0.7v1h-2.1c-0.4,0-0.7,0.3-0.7,0.7v3.4
                    c0,1.1,0.9,2.1,2.1,2.1h0.9c0.4,1.5,1.8,2.5,3.3,2.5h0.4v2.3H44c-1.1,0-1.9,0.9-1.9,1.9v1.3c0,0.4,0.3,0.7,0.7,0.7h5.6
                    c0.4,0,0.7-0.3,0.7-0.7v-1.3c0-1.1-0.9-1.9-1.9-1.9h-0.9V47c1.5-0.1,2.8-1.1,3.2-2.5h0.9c1.1,0,2.1-0.9,2.1-2.1V39
                    C52.4,38.6,52.1,38.3,51.7,38.3z M50.3,43.1h-0.7v-3.4H51v2.7C51,42.8,50.7,43.1,50.3,43.1z M46.1,45.6h-1.7c-1.2,0-2.1-1-2.1-2.1
                    V38h5.9v5.5C48.2,44.7,47.3,45.6,46.1,45.6z M47.6,51.8h-4.2v-0.6c0-0.3,0.3-0.6,0.6-0.6h3.1c0.3,0,0.6,0.3,0.6,0.6V51.8z
                    M39.5,39.7h1.4v3.4h-0.7c-0.4,0-0.7-0.3-0.7-0.7V39.7z"></path>
                  </g>
                </svg>`,
					title: 'Winning History'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M29.6,0c-2.3,0-4.6,0.3-6.8,0.9C10.7,3.9,1.7,13.8,0,26.1c-0.1,0.6,0.4,1.2,1,1.2c0.6,0,1.1-0.4,1.1-1
                    C3.7,15,12.1,5.8,23.3,3c11-2.7,22.5,1.4,29.3,10.4l-2.8-0.3c-1.5-0.2-1.7,2.1-0.2,2.2l6.5,0.7l1.3-5.9c0.2-0.7-0.4-1.3-1.1-1.3
                    c-0.5,0-1,0.4-1.1,0.9l-0.6,2.8C48.8,4.4,39.4-0.1,29.6,0z M29.9,11.5c-10.3,0-18.7,8.3-18.7,18.5c0,10.2,8.4,18.5,18.7,18.5
                    c0.4,0,0.8,0,1.3-0.1c1.8,2.5,4.6,4.1,7.9,4.1c5.3,0,9.7-4.3,9.7-9.6c0-2-0.6-3.9-1.7-5.4c1.1-2.4,1.6-4.9,1.6-7.5
                    C48.6,19.8,40.2,11.5,29.9,11.5z M29.9,13.7C39,13.7,46.4,21,46.4,30c0,2-0.4,3.9-1.1,5.7c-1.7-1.4-3.9-2.3-6.3-2.3
                    c-5.3,0-9.7,4.3-9.7,9.6c0,1.2,0.2,2.3,0.6,3.4c0,0-0.1,0-0.1,0c-9.1,0-16.5-7.3-16.5-16.3C13.3,21,20.7,13.7,29.9,13.7L29.9,13.7z
                    M29.1,16.8c-0.6,0-1.1,0.5-1.1,1.1v12c0,0.6,0.5,1.1,1.1,1.1h8.8c1.4,0,1.4-2.2,0-2.2h-7.7V17.9C30.2,17.3,29.7,16.7,29.1,16.8z
                    M58.9,32.5c-0.6,0-1,0.4-1.1,1C56.4,44.9,47.9,54.2,36.6,57c-10.8,2.6-22.1-1.3-28.9-9.9l3.3,0.3c1.5,0.3,1.8-2.1,0.2-2.2
                    l-6.5-0.7l-1.3,5.9c-0.3,1.4,1.8,1.9,2.2,0.5l0.5-2.3c7.3,9.2,19.5,13.3,31,10.5C49.3,56.1,58.4,46,60,33.7
                    C60.1,33,59.6,32.5,58.9,32.5z M39,35.6c4.1,0,7.5,3.3,7.5,7.4s-3.3,7.4-7.5,7.4c-4.1,0-7.5-3.3-7.5-7.4S34.9,35.6,39,35.6z
                    M37.9,36.4c0,0.5,0,1.1,0,1.6c-0.5,0.1-1,0.4-1.4,0.7c-0.7,0.5-1.2,1.3-1.2,2.3c0,1,0.6,1.8,1.3,2.2c0.7,0.4,1.4,0.7,2.1,0.9
                    c0.7,0.2,1.3,0.4,1.6,0.6c0.3,0.2,0.3,0.2,0.3,0.3c0,0.4-0.5,0.9-1.5,0.9c-0.5,0-0.9-0.2-1.2-0.4c-0.3-0.2-0.3-0.4-0.3-0.5h-2.2
                    c0,0.9,0.5,1.8,1.2,2.3c0.4,0.3,0.9,0.5,1.4,0.7c0,0.5,0,1.1,0,1.6h2.2c0-0.5,0-1.1,0-1.6c1.5-0.4,2.6-1.4,2.7-2.9
                    c0-1-0.6-1.8-1.3-2.2c-0.7-0.4-1.4-0.7-2.1-0.9c-0.7-0.2-1.2-0.4-1.5-0.6c-0.3-0.2-0.3-0.2-0.3-0.4c0-0.2,0.1-0.3,0.3-0.5
                    c0.3-0.2,0.7-0.4,1.2-0.4c0.5,0,0.9,0.2,1.2,0.4s0.3,0.4,0.3,0.5h2.2c0-0.9-0.5-1.8-1.2-2.3c-0.4-0.3-0.9-0.5-1.4-0.7
                    c0-0.5,0-1.1,0-1.6H37.9z"></path>
                  </g>
                </svg>`,
					title: 'Transaction History'
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M56.8,45h-2.2V44c0-0.4-0.1-0.8-0.2-1.1c1.4-0.1,2.4-1.3,2.4-2.7V37c0-1.5-1.2-2.6-2.7-2.6h-1.7V16.1h2.2
                    c1.2,0,2.1-1,2.1-2.1V7.5c0-1.2-1-2.1-2.1-2.1H34.9c-1.2,0-2.1,1-2.1,2.1V14c0,1.2,1,2.1,2.1,2.1h2.2v5.4h-3.5
                    c-0.6-1.7-2.4-2.6-4.1-2c-1,0.3-1.7,1.1-2,2H1.1c-0.6,0-1.1,0.5-1.1,1.1c-0.1,5-0.1,5,0.6,5.3c0.1,0,0.3,0.1,0.4,0.1h26.4
                    c0.5,1.3,1.7,2.1,3,2.1l0,0c1.4,0,2.6-0.9,3-2.1h3.5v6.4h-1.6c-1.5,0-2.7,1.2-2.7,2.7v3.2c0,1.4,1,2.5,2.4,2.7
                    c-0.1,0.4-0.2,0.7-0.2,1.1v1.1h-2.2c-1.8,0-3.2,1.4-3.2,3.2v4.3c0,0.6,0.5,1.1,1.1,1.1h28.3c0.6,0,1.1-0.5,1.1-1.1v-4.3
                    C60,46.5,58.6,45,56.8,45z M34.9,14V7.5h19.7V14H34.9z M2.1,23.6h25.2v2.1H2.1C2.1,25.2,2.1,24.4,2.1,23.6z M30.6,27.9L30.6,27.9
                    c-0.6,0-1.1-0.5-1.1-1.1v-4.3c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v4.3C31.7,27.4,31.2,27.9,30.6,27.9
                    C30.6,27.9,30.6,27.9,30.6,27.9L30.6,27.9z M37.1,25.7h-3.3v-2.1h3.3L37.1,25.7L37.1,25.7z M39.2,16.1h11v18.2H39.3L39.2,16.1z
                    M34.9,40.2V37c0-0.3,0.2-0.5,0.5-0.5H54c0.3,0,0.5,0.2,0.5,0.5v3.2c0,0.3-0.2,0.5-0.5,0.5H35.5C35.2,40.7,34.9,40.5,34.9,40.2z
                    M37.1,44c0-0.6,0.5-1.1,1.1-1.1h13.2c0.6,0,1.1,0.5,1.1,1.1v1.1H37.1L37.1,44z M57.9,51.5H31.6v-3.2c0-0.6,0.5-1.1,1.1-1.1h24.1
                    c0.6,0,1.1,0.5,1.1,1.1V51.5z"></path>
                  </g>
                </svg>`,
					title: 'Create Auction (private and public) '
				},
				{
					svg: `<svg viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                  <g>
                    <path d="M51.9,37.4C52,37.3,52,37.1,52,37V12c2.2,0,4-1.8,4-4s-1.8-4-4-4H8C5.8,4,4,5.8,4,8s1.8,4,4,4v25c0,0.6,0.4,1,1,1h8v15
                    c0,3.9,3.1,7,7,7h12.5c3.2,0,6.1-1.7,7.7-4.4l8.1-13.6C53.2,40.6,53,38.7,51.9,37.4z M43,38h2.5L43,41.7V38z M10,30h40v2H10V30z
                    M54,8c0,1.1-0.9,2-2,2V6C53.1,6,54,6.9,54,8z M50,6v22H10V6H50z M6,8c0-1.1,0.9-2,2-2v4C6.9,10,6,9.1,6,8z M10,36v-2h40v2H10z
                    M50.6,41l-8.1,13.6c-1.3,2.1-3.5,3.4-6,3.4H24c-2.8,0-5-2.2-5-5V38h4v4h2v-4h4v4h2v-4h4v4h2v-4h4v6h-3c-5,0-9,4-9,9h2
                    c0-3.9,3.1-7,7-7h4l0,0c0.3,0,0.6-0.2,0.8-0.4l4.4-6.6c0.6-0.9,1.8-1.2,2.8-0.6C50.9,39,51.2,40.1,50.6,41L50.6,41z"></path>
                    <path d="M59,0H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h5v-2H2V2h56v12h-4v2h5c0.6,0,1-0.4,1-1V1C60,0.4,59.6,0,59,0z"></path>
                    <path d="M13,23c1.1,0,2,0.9,2,2c0,0.6,0.4,1,1,1h28c0.6,0,1-0.4,1-1c0-1.1,0.9-2,2-2c0.6,0,1-0.4,1-1V12c0-0.6-0.4-1-1-1
                    c-1.1,0-2-0.9-2-2c0-0.6-0.4-1-1-1H16c-0.6,0-1,0.4-1,1c0,1.1-0.9,2-2,2c-0.6,0-1,0.4-1,1v10C12,22.6,12.4,23,13,23z M24,17
                    c0-3.9,2.7-7,6-7s6,3.1,6,7s-2.7,7-6,7S24,20.9,24,17z M43.1,10c0.4,1.4,1.5,2.5,2.9,2.9v8.3c-1.4,0.4-2.5,1.5-2.9,2.9H35
                    c1.9-1.8,3-4.4,3-7c0-2.6-1.1-5.2-3-7H43.1z M14,12.9c1.4-0.4,2.5-1.5,2.9-2.9H25c-1.9,1.8-3,4.4-3,7c0,2.6,1.1,5.2,3,7h-8.1
                    c-0.4-1.4-1.5-2.5-2.9-2.9V12.9z"></path>
                    <rect x="17" y="16" width="2" height="2"></rect>
                    <rect x="41" y="16" width="2" height="2"></rect>
                    <path d="M4,37H2c0,3.9,3.1,7,7,7v-2C6.2,42,4,39.8,4,37z"></path>
                  </g>
                </svg>`,
					title: 'View and Withdraw Money'
				},
				{
					svg: `<svg viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
                  <path d="M496,256v-64.2c0-17.8-11.5-33.2-28.5-38.3L416,138v-12.2c14.5-10.1,24-26.9,24-45.9V56c0-30.9-25.1-56-56-56
                  s-56,25.1-56,56v24c0,19,9.5,35.8,24,45.9V138l-45.4,13.6c-17-15.2-38.7-23.7-61.8-23.7c-22.9,0-43.9,8.4-60.2,22.3L144,138v-12.2
                  c14.5-10.1,24-26.9,24-45.9V56c0-30.9-25.1-56-56-56S56,25.1,56,56v24c0,19,9.5,35.8,24,45.9V138l-51.5,15.4
                  C11.5,158.6,0,174,0,191.8V256h16v-64.2c0-10.7,6.9-19.9,17.1-23l51.5-15.5c4.7,6,13.7,14.6,27.4,14.6s22.7-8.6,27.4-14.6l32.8,9.8
                  c-10.4,13.1-17.4,29-19.5,46.5C142.5,198.8,128.1,192,112,192c-30.9,0-56,25.1-56,56v24c0,19,9.5,35.8,24,45.9V330l-51.5,15.4
                  C11.5,350.6,0,366,0,383.8V456h96c4.4,0,8,3.6,8,8v32h288v-32c0-4.4,3.6-8,8-8h96v-72.2c0-17.8-11.5-33.2-28.5-38.3L416,330v-12.2
                  c14.5-10.1,24-26.9,24-45.9v-24c0-30.9-25.1-56-56-56c-15.7,0-29.8,6.5-40,16.9v-7.6c0-15.8-8.6-30-22-37.5l34.6-10.4
                  c4.7,6,13.7,14.6,27.4,14.6s22.7-8.6,27.4-14.6l51.5,15.5c10.2,3.1,17.1,12.3,17.1,23V256H496z M112,16c22.1,0,40,17.9,40,40h-36.7
                  c-5.1,0-10.1-2.1-13.7-5.7L96,44.7l-5.7,5.7c-3.6,3.6-8.6,5.7-13.7,5.7H72C72,33.9,89.9,16,112,16z M72,80v-8h4.7
                  c7,0,13.6-2,19.3-5.7c5.7,3.7,12.3,5.7,19.3,5.7H152v8c0,22.1-17.9,40-40,40S72,102.1,72,80z M112,152c-8.5,0-14-7.1-16-10.2v-8.2
                  c5.1,1.5,10.4,2.4,16,2.4s10.9-0.8,16-2.4v8.2C126,144.9,120.5,152,112,152z M331.9,292.4c4.1,10.3,11.1,19.2,20.1,25.5V330
                  l-51.5,15.4c-1.7,0.5-3.3,1.2-5,1.9l-7.6-1.7v-15.8c8.6-6.9,15.3-15.9,19.4-26.2C316.6,302.8,325.3,298.9,331.9,292.4z M248,344
                  c8.5,0,16.6-1.7,24-4.7v8.7l-24,18l-24-18v-8.7C231.4,342.3,239.5,344,248,344z M214.2,360.6l19.5,14.6l-20.1,12l-5.1-25.4
                  L214.2,360.6z M239.2,424h17.7l6.2,56h-30.1L239.2,424z M258.2,408h-20.5l-4.6-13.8l14.8-8.9l14.8,8.9L258.2,408z M262.3,375.3
                  l19.5-14.6l5.6,1.3l-5.1,25.4L262.3,375.3z M321.8,280.1c-2.8,3-6.3,5.2-10.1,6.5c0.2-2.2,0.3-4.4,0.3-6.6v-38.3
                  c8.6,3.3,15,10.8,15.9,19.9C328.5,268.5,326.4,275.1,321.8,280.1z M296,280c0,26.5-21.5,48-48,48s-48-21.5-48-48v-40h27.2
                  c17.4,0,34.4-4.4,49.6-12.7l6.3,12.7H296V280z M184.3,286.6c-3.8-1.3-7.3-3.4-10.1-6.5c-4.6-5.1-6.8-11.6-6.1-18.5
                  c0.9-9.1,7.3-16.6,15.9-19.9V280C184,282.2,184.1,284.4,184.3,286.6z M188.6,303.6c4.1,10.3,10.9,19.4,19.4,26.2v15.8l-7.6,1.7
                  c-1.6-0.7-3.3-1.4-4.9-1.9L144,330v-12.2c9-6.3,16-15.1,20.1-25.5C170.7,298.9,179.4,302.8,188.6,303.6z M112,208
                  c22.1,0,40,17.9,40,40h-4.7c-5.1,0-10.1-2.1-13.7-5.7l-5.7-5.7l-5.7,5.7c-3.6,3.6-8.6,5.7-13.7,5.7H72C72,225.9,89.9,208,112,208z
                  M72,272v-8h36.7c7,0,13.6-2,19.3-5.7c5.7,3.7,12.3,5.7,19.3,5.7h4.7v8c0,22.1-17.9,40-40,40S72,294.1,72,272z M112,328
                  c5.6,0,10.9-0.8,16-2.4v8.2c-2,3.1-7.5,10.2-16,10.2s-14-7.1-16-10.2v-8.2C101.1,327.2,106.4,328,112,328z M96,440H64v-56H48v56H16
                  v-56.2c0-10.7,6.9-19.9,17.1-23l51.5-15.5c4.7,6,13.7,14.6,27.4,14.6s22.7-8.6,27.4-14.6l30.3,9.1l-22.3,5.1
                  c-25.6,5.9-43.4,28.3-43.4,54.6v9.8h16v-9.8c0-18.7,12.8-34.8,31-39l41.9-9.7l9.4,47.2l16.7-10l4.7,14.2l-7,63.1H176v-64h-16v64h-40
                  v-16C120,450.8,109.2,440,96,440z M462.9,360.8c10.2,3.1,17.1,12.3,17.1,23V440h-32v-56h-16v56h-32c-13.2,0-24,10.8-24,24v16h-40
                  v-64h-16v64h-40.8l-7-63.1l4.7-14.2l16.7,10l9.4-47.2l41.9,9.7c18.3,4.2,31,20.2,31,39v9.8h16v-9.8c0-26.2-17.8-48.7-43.4-54.6
                  l-22.3-5.1l30.3-9.1c4.7,6,13.7,14.6,27.4,14.6s22.7-8.6,27.4-14.6L462.9,360.8z M400,333.8c-2,3.1-7.5,10.2-16,10.2
                  c-8.5,0-14-7.2-16-10.2v-8.2c5.1,1.5,10.4,2.4,16,2.4s10.9-0.8,16-2.4V333.8z M384,312c-22.1,0-40-17.9-40-40v-8h4.7
                  c7,0,13.6-2,19.3-5.7c5.7,3.7,12.3,5.7,19.3,5.7H424v8C424,294.1,406.1,312,384,312z M384,208c22.1,0,40,17.9,40,40h-36.7
                  c-5.1,0-10.1-2.1-13.7-5.7l-5.7-5.7l-5.7,5.7c-3.6,3.6-8.6,5.7-13.7,5.7H344C344,225.9,361.9,208,384,208z M328,232.7
                  c-7.1-5.4-15.9-8.7-25.4-8.7h-9.7l-9.8-19.7l-7.5,5c-14.4,9.6-31.1,14.7-48.4,14.7h-33.8c-9.5,0-18.3,3.3-25.4,8.7v-11.8
                  c0-42.4,34.5-76.8,76.8-76.8c20.5,0,39.8,8,54.3,22.5l8.5,8.5l1.8,0.6c11.1,3.7,18.5,14,18.5,25.7V232.7z M384,16
                  c22.1,0,40,17.9,40,40h-4.7c-5.1,0-10.1-2.1-13.7-5.7l-5.7-5.7l-5.7,5.7c-3.6,3.6-8.6,5.7-13.7,5.7H344C344,33.9,361.9,16,384,16z
                  M344,80v-8h36.7c7,0,13.6-2,19.3-5.7c5.7,3.7,12.3,5.7,19.3,5.7h4.7v8c0,22.1-17.9,40-40,40S344,102.1,344,80z M384,152
                  c-8.5,0-14-7.2-16-10.2v-8.2c5.1,1.5,10.4,2.4,16,2.4s10.9-0.8,16-2.4v8.2C398,144.9,392.5,152,384,152z"></path>
                  <path d="M184,0h16v16h-16V0z"></path>
                  <path d="M184,32h16v88h-16V32z"></path>
                  <path d="M296,0h16v16h-16V0z"></path>
                  <path d="M296,32h16v88h-16V32z"></path>
                  <path d="M216,32h16v56h-16V32z"></path>
                  <path d="M216,104h16v16h-16V104z"></path>
                  <path d="M264,32h16v56h-16V32z"></path>
                  <path d="M264,104h16v16h-16V104z"></path>
                </svg>`,
					title: 'View other team'
				}
			],
			items2: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M62.91,15c-5.72,0.54-10.57,5.91-10.55,11.68v40.86c-13.89,0-28.01,0-41.82,0C4.83,68.08-0.02,73.45,0,79.21v402.8
                  c0,6.11,5.54,11.68,11.64,11.68h296.72c6.09,0,11.64-5.56,11.64-11.68v-40.86h40.73c6.09,0,11.63-5.56,11.64-11.68V26.68
                  c0-6.11-5.54-11.67-11.64-11.68C261.48,15,162.08,15,62.91,15z M453.81,15c-14.7,0-28.33,3.26-39.09,9.85
                  c-10.77,6.59-19.09,17.71-19.09,31.01c0,106.89,0,214.37,0,321.07c0.03,1.63,0.4,3.26,1.09,4.74l46.54,105.08
                  c1.86,4,6.15,6.74,10.55,6.74c4.4,0,8.68-2.74,10.55-6.74l46.55-105.08c0.69-1.48,1.06-3.11,1.09-4.74
                  c0.01-106.84,0-214.09,0-321.07c0-13.3-8.33-24.42-19.09-31.01C482.14,18.26,468.51,15,453.81,15z M75.64,38.35h273.45V417.8H75.64
                  C75.67,291.35,75.64,164.54,75.64,38.35z M453.81,38.35c11.09,0,20.85,2.67,26.91,6.38c6.06,3.71,8,7.28,8,11.13v17.51H418.9V55.86
                  c0-3.85,1.94-7.42,8-11.13C432.96,41.02,442.72,38.35,453.81,38.35z M23.27,90.89h29.09v338.59c0,6.11,5.54,11.67,11.64,11.68
                  h232.72v29.19H23.27V90.89z M126.91,90.89c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.2,11.13h40.73
                  c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.66-11.76-11.8-11.68C154.83,90.89,140.71,90.89,126.91,90.89z M220,90.89
                  c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.19,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68
                  c0-6.17-5.66-11.76-11.8-11.68C278.94,90.89,249.33,90.89,220,90.89z M418.9,96.73h69.82v23.35H418.9V96.73z M126.91,137.59
                  c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.2,11.13h40.73c6.15,0.09,11.8-5.51,11.8-11.68
                  c0-6.17-5.66-11.76-11.8-11.68C154.83,137.59,140.71,137.59,126.91,137.59z M220,137.59c-6.1,0.28-11.39,6.11-11.1,12.22
                  c0.29,6.12,6.1,11.42,12.19,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.66-11.76-11.8-11.68
                  C278.94,137.59,249.33,137.59,220,137.59z M418.9,143.43h69.82v210.16H418.9V143.43z M126.91,236.83
                  c-6.09,0.29-11.37,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13h40.73c6.14,0.08,11.79-5.51,11.79-11.68
                  c0-6.16-5.65-11.76-11.79-11.67C154.83,236.83,140.71,236.83,126.91,236.83z M220,236.83c-6.09,0.29-11.38,6.11-11.09,12.22
                  c0.29,6.11,6.09,11.41,12.18,11.13h87.27c6.14,0.08,11.79-5.51,11.79-11.68c0-6.16-5.65-11.76-11.79-11.67
                  C278.94,236.83,249.33,236.83,220,236.83z M126.91,283.53c-6.09,0.29-11.38,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13
                  h40.73c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.65-11.76-11.8-11.67C154.83,283.53,140.71,283.53,126.91,283.53z M220,283.53
                  c-6.09,0.29-11.38,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68
                  c0-6.17-5.65-11.76-11.8-11.67C278.94,283.53,249.33,283.53,220,283.53z M419.81,376.94h68l-34,76.62L419.81,376.94z"></path>
                </svg>`,
					title: 'Admin Login'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M419.74,458.26c-2.43-14.93-4.73-27.44-6.75-38.61c-6.21-34.44-9.73-54.22-8.24-97.4c0.4-11.7-3.24-21.39-9.59-29.73
                      c-5.94-7.8-14.05-14.13-23.37-19.64c-9.18-5.38-15.13-15.74-21.07-26.1c-8.65-15.2-17.42-30.54-36.6-38.21V40.94
                      c0-10.49-4.19-19.91-11.08-26.77C296.15,7.3,286.56,3,276.16,3H127.99C117.45,3,108,7.3,101.11,14.17
                      C94.22,21.03,89.9,30.44,89.9,40.94v297.32c0,10.36,4.32,19.91,11.21,26.77c6.89,6.86,16.34,11.03,26.88,11.03h35.93
                      c-0.41,0.81-0.81,1.75-1.08,2.56l-0.41,0.94c-1.62,4.31-2.16,9.28-1.75,14.13c0.4,4.71,1.89,9.28,4.05,13.45
                      c5.4,9.82,15.53,16.82,29.18,14.8c4.46-0.67,9.05-1.48,12.97-2.15c9.18-1.75,14.32-2.56,24.04-1.21
                      c6.08,11.97,14.59,23,24.18,33.23c9.99,10.63,21.07,20.18,31.88,29.19l2.84,7.13c1.08,2.69,1.89,4.98,2.57,7
                      c0.27,0.67,0.67,1.35,1.08,2.02c8.37,13.18,39.31,14.53,68.21,8.21c10-2.15,19.86-5.11,28.63-8.75
                      c9.19-3.9,17.02-8.48,22.29-13.72C420.01,475.61,423.12,467.27,419.74,458.26z M127.99,356.95c-5.13,0-9.86-2.15-13.24-5.52
                      c-3.51-3.36-5.54-8.07-5.54-13.18V40.94c0-5.11,2.02-9.82,5.54-13.18c3.38-3.5,8.1-5.52,13.24-5.52h148.17
                      c5.13,0,9.86,2.02,13.24,5.52c3.38,3.36,5.54,8.07,5.54,13.18v174.62v81.66c-7.29-5.78-14.86-11.57-22.69-17.49
                      c-13.51-10.22-27.82-20.31-42.55-30.4c-13.24-9.01-25.93-9.69-36.06-5.38c-5.13,2.02-9.32,5.38-12.7,9.55
                      c-3.24,3.9-5.54,8.75-6.75,13.99c-2.16,10.09,0,21.53,8.24,30.94c-4.46,4.84-7.16,11.17-7.16,18.16c0,7.4,2.97,14.13,7.83,18.83
                      c4.86,4.84,11.62,7.8,18.91,7.8c6.62,0,12.7-2.29,17.29-6.32l0.27,0.27c5.54,6.32,10.81,12.92,15.94,19.78H127.99z M191.61,307.58
                      c6.75,6.73,13.24,13.18,19.18,19.78c-2.3,2.02-5.4,3.09-8.78,3.09c-3.78,0-7.29-1.48-9.86-4.04c-2.43-2.56-4.05-5.92-4.05-9.82
                      C188.1,313.09,189.45,310,191.61,307.58z M399.07,469.29c-3.51,3.36-9.32,6.73-16.21,9.69c-7.43,3.09-16.21,5.65-25.26,7.67
                      c-21.21,4.57-42.55,5.92-47.54,0.81l-2.3-6.32l-3.92-9.55c-0.68-1.75-1.89-3.23-3.38-4.3c-10.81-8.88-21.88-18.3-31.34-28.52
                      c-9.45-10.09-17.69-20.85-22.96-32.69c-1.35-3.09-4.05-4.98-7.03-5.52c-16.34-3.09-22.83-1.88-35.66,0.4
                      c-3.11,0.54-6.75,1.21-12.29,2.02c-4.46,0.67-7.83-1.75-9.59-4.98c-0.95-1.88-1.62-3.9-1.76-6.05c-0.27-2.02,0-4.04,0.68-5.78
                      l0.13-0.4c1.08-2.55,3.78-4.44,7.97-4.57l59.57-0.4c1.76,13.18,4.46,23.81,9.05,33.77c5.67,12.38,13.91,22.87,26.07,34.71
                      c3.78,3.63,9.86,3.63,13.64-0.14c3.65-3.77,3.51-9.82-0.27-13.59c-10.4-10.09-17.42-18.97-22.02-28.92
                      c-4.19-9.28-6.62-20.05-8.1-34.31c0-0.4,0-0.81,0-1.21c0-0.94-0.14-2.02-0.41-2.96c-0.4-2.56-1.75-4.84-3.78-6.32
                      c-9.18-13.59-18.37-25.56-28.23-37.13c-10.81-12.51-22.56-24.48-35.93-37.4c-5-4.84-6.35-10.63-5.27-15.74
                      c0.54-2.15,1.49-4.3,2.97-6.05c1.35-1.75,3.11-3.09,5.13-3.9c4.46-1.88,10.67-1.35,17.69,3.5c14.72,10.09,28.77,20.05,42.01,30
                      c12.43,9.28,24.18,18.56,35.39,27.98l21.75,21.66c4.19,4.17,10.81,4.17,14.99,0c4.05-4.17,4.05-10.76-0.13-14.8l-18.64-18.56
                      v-80.99c8.78,6.32,14.45,16.14,19.99,25.83c7.16,12.65,14.32,25.16,27.83,33.23c7.43,4.3,13.64,9.15,17.83,14.66
                      c3.78,4.98,5.94,10.63,5.81,17.62c-1.62,45.07,2.16,65.65,8.51,101.3c2.03,11.03,4.32,23.68,6.75,39.28
                      c0.14,0.81,0.4,1.61,0.81,2.42C401.91,465.79,400.97,467.4,399.07,469.29z M188.8,207.38l6.39-6.36l83.56-83.05
                      c3.47-3.63,3.47-9.27,0.18-12.9c-3.65-3.63-9.3-3.63-12.95,0l-77.18,76.69c-3.65,3.63-9.3,3.63-12.95,0l-25.54-25.62
                      c-3.65-3.63-9.3-3.63-12.95,0c-3.65,3.63-3.65,9.27,0,12.9l38.5,38.35C179.5,211.02,185.15,211.02,188.8,207.38z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'User Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M159.01,76.45c0-4.72-3.83-8.55-8.57-8.55h-5.23c-4.74,0-8.57,3.83-8.57,8.55s3.83,8.55,8.57,8.55h5.23
                      C155.18,85.01,159.01,81.18,159.01,76.45z M187.79,76.45c0-4.72-3.84-8.55-8.57-8.55h-5.23c-4.74,0-8.57,3.83-8.57,8.55
                      s3.83,8.55,8.57,8.55h5.23C183.95,85.01,187.79,81.18,187.79,76.45z M202.46,85.01h5.24c4.74,0,8.57-3.83,8.57-8.55
                      s-3.84-8.55-8.57-8.55h-5.24c-4.73,0-8.57,3.83-8.57,8.55S197.73,85.01,202.46,85.01z M164.69,89.64h-5.24
                      c-4.74,0-8.57,3.83-8.57,8.55c0,4.72,3.83,8.55,8.57,8.55h5.24c4.73,0,8.57-3.83,8.57-8.55
                      C173.26,93.47,169.42,89.64,164.69,89.64z M179.66,98.19c0,4.72,3.83,8.55,8.57,8.55h5.23c4.74,0,8.57-3.83,8.57-8.55
                      c0-4.72-3.83-8.55-8.57-8.55h-5.23C183.49,89.64,179.66,93.47,179.66,98.19z M267.12,330.75c-13.69-1.84-26.61-3.57-35.83-12.43
                      c-6.36-5.97-6.21-18.16-5.03-33.56c34.14-30.88,46.39-90.76,46.39-124.54c0-0.18-0.02-0.35-0.03-0.52
                      c0.98-1.16,1.93-2.36,2.87-3.61c15.02-12.16,18.15-24.99,18.08-33.69c-0.12-16.78-11.42-33.84-33.69-50.77
                      c-28.27-20.92-48.92-31.54-83.28-31.54c-34.76,0-55.34,10.63-83.34,31.59c-22.34,16.84-33.75,33.9-33.9,50.71
                      c-0.09,8.69,3.02,21.52,18.05,33.7c0.94,1.26,1.9,2.47,2.88,3.63c-0.01,0.17-0.03,0.34-0.03,0.51
                      c0,38.11,13.55,92.51,43.29,121.91c1.11,1.1,2.24,2.15,3.39,3.16c0.94,15.74,0.86,27.33-5.12,33.1c-1.41,1.35-2.91,2.52-4.49,3.56
                      c-0.7,0.31-1.37,0.72-1.97,1.21c-8.35,4.75-18.65,6.15-29.42,7.59C69.09,333,50,335.56,36.98,352.4
                      c-22.92,30-36.05,75.94-36.05,126.04c0,4.72,3.84,8.55,8.57,8.55H147.6c0.07,0,0.15,0.01,0.22,0.01c0.05,0,0.09-0.01,0.14-0.01
                      h56.97c0.05,0,0.09,0.01,0.14,0.01c0.07,0,0.15-0.01,0.22-0.01h138.11c4.74,0,8.57-3.82,8.57-8.55
                      c0-50.09-13.14-96.03-36.07-126.07C302.9,335.56,283.9,333,267.12,330.75z M231.99,339.45c3.3,1.64,6.7,2.92,10.14,3.94
                      l-38.43,29.3l-12.56-12.12L231.99,339.45z M176.59,348.83l-40.42-20.79c6.82-8.33,8.43-19.24,8.41-30.78
                      c9.71,4.75,20.2,7.14,31.39,7.14c0.24,0,0.48-0.01,0.72-0.01c11.86-0.11,22.4-2.8,31.74-7.41c-0.14,11.39,1.42,22.55,8.49,31
                      L176.59,348.83z M186.2,379.61l-9.64,11.59l-9.75-11.57l9.77-9.32L186.2,379.61z M76.5,122.53c0.1-11.06,9.47-23.94,27.07-37.2
                      c26.01-19.46,42.73-28.14,73.03-28.14c29.94,0,46.74,8.69,72.98,28.1c17.48,13.29,26.77,26.16,26.85,37.22
                      c0.04,5.89-2.65,11.56-8,16.93c-27.51-16.53-57.62-24.58-91.83-24.58c-34.39,0-64.6,8.05-92.13,24.58
                      C79.13,134.07,76.45,128.4,76.5,122.53z M176.6,131.96c30.08,0,55.67,6.45,79.72,20.2c-7.35,7.3-17.68,13.09-31.16,10.42
                      c-3.34-0.71-6.76-1.55-10.28-2.41c-10.97-2.68-23.41-5.72-38.01-6.19c-0.09,0-0.19,0.02-0.28,0.02c-0.09,0-0.17-0.02-0.26-0.02
                      c-14.87,0.46-27.37,3.51-38.4,6.21c-3.5,0.85-6.89,1.68-10.1,2.37c-13.51,2.67-23.9-3.09-31.25-10.39
                      C120.67,138.41,146.35,131.96,176.6,131.96z M98.05,174.46c7.19,3.86,14.93,5.87,23.02,5.87c3.36,0,6.78-0.34,10.23-1.03
                      c3.48-0.74,7.04-1.6,10.71-2.5c10.63-2.59,21.63-5.27,34.59-5.71c12.7,0.43,23.64,3.1,34.21,5.69c3.7,0.9,7.28,1.78,10.89,2.54
                      c3.43,0.68,6.82,1.02,10.15,1.02c8.05,0,15.76-1.99,22.93-5.84c-4.03,43.05-25.54,112.32-78.26,112.8
                      c-15.36-0.18-29.12-5.7-40.89-17.33C113.73,248.34,100.95,208.13,98.05,174.46z M121.06,339.51l40.93,21.05l-12.74,12.14
                      l-38.24-29.31C114.42,342.38,117.79,341.12,121.06,339.51z M141.96,469.89H78.52v-32.61c0-4.72-3.83-8.55-8.57-8.55
                      c-4.74,0-8.57,3.82-8.57,8.55v32.61H18.2c1.36-43.03,13-81.72,32.38-107.09c8.69-11.23,22.11-13.03,37.65-15.12
                      c0.07-0.01,0.14-0.02,0.21-0.03l56.2,43.07c1.55,1.19,3.39,1.77,5.23,1.77c1.52,0,3.04-0.41,4.38-1.21l12.53,14.87L141.96,469.89z
                      M160.35,469.89l16.21-41.64l16.04,41.64H160.35z M291.54,469.89v-32.61c0-4.72-3.83-8.55-8.57-8.55c-4.74,0-8.57,3.82-8.57,8.55
                      v32.61h-63.43l-24.56-63.75l12.32-14.81c1.33,0.77,2.81,1.17,4.32,1.17c1.83,0,3.66-0.58,5.21-1.76l56.48-43.07
                      c0.03,0,0.06,0.01,0.1,0.01c15.46,2.08,28.8,3.87,37.47,15.08c19.4,25.41,31.04,64.1,32.4,107.12H291.54z M278.28,268.31
                      c-14.1-6.2-30.65,0.18-36.84,14.15c-3.06,6.74-3.25,14.59-0.5,21.52c2.76,6.94,8.06,12.32,14.64,15.01
                      c6.4,2.91,23.03,6.81,37.05,6.81c5.49,0,10.57-0.6,14.49-2.09c5.61-2.13,8.13-5.62,9.26-8.18c8.63-19.5-33-44.9-38-47.17
                      C278.35,268.34,278.31,268.33,278.28,268.31z M299.75,308.2c-7.42,1.88-28.95-1.09-37.35-4.9c-2.52-1.04-4.49-3.02-5.51-5.62
                      c-1.05-2.64-0.99-5.61,0.2-8.23c1.8-4.06,5.68-6.44,9.81-6.44c1.46,0,2.97,0.3,4.43,0.94
                      C279.78,287.81,296.16,301.55,299.75,308.2z M502.67,282.55c-0.58-1.32-1.26-2.58-2.03-3.78c5.69-4.31,9.44-11.54,9.44-19.72
                      c0-13.19-9.71-23.92-21.66-23.92h-17.51l-0.64-1.77c-7.64-19.76-21.17-33.81-37.94-40.19v-1.29h-3.81
                      c-3.96-1.2-8.07-1.99-12.31-2.34v-6.73c0-13.02-10.62-23.62-23.68-23.62h-36.33c-13.05,0-23.68,10.6-23.68,23.62v6.53
                      c-26.13,0.18-48.61,17.04-58.68,43.91l-2.9,7.24c-1.76,4.39,0.38,9.36,4.78,11.11c4.37,1.76,9.38-0.38,11.14-4.76l2.97-7.43
                      c7.74-20.65,23.86-32.98,43.12-32.98h78.17c19.01,0,35.14,12.36,43.08,32.89l2.61,7.24c0.02,0.06,0.05,0.11,0.08,0.17
                      c0.14,0.35,0.29,0.7,0.47,1.03c0.04,0.07,0.08,0.13,0.12,0.2c0.16,0.27,0.33,0.53,0.52,0.78c0.07,0.09,0.13,0.19,0.21,0.27
                      c0.22,0.28,0.46,0.54,0.72,0.79c0.07,0.07,0.15,0.14,0.22,0.2c0.25,0.22,0.5,0.43,0.78,0.63c0.04,0.03,0.08,0.06,0.12,0.09
                      c0.32,0.22,0.66,0.41,1.01,0.59c0.06,0.03,0.11,0.05,0.17,0.08c0.7,0.33,1.46,0.57,2.25,0.71c0.08,0.01,0.16,0.02,0.24,0.04
                      c0.39,0.05,0.78,0.09,1.18,0.09h23.54c2.13,0,4.52,2.91,4.52,6.81c0,3.89-2.38,6.81-4.52,6.81h-11.91c-0.44,0-0.86,0.04-1.28,0.11
                      c-3.24,0.22-6.44,1.01-9.48,2.38c-6.41,2.82-16.72,10.16-24.55,17.46c-12.7,11.83-17.12,21.56-13.59,29.52
                      c1.06,2.56,3.47,6.07,8.91,8.26c4.01,1.61,9.24,2.25,14.85,2.25c13.36,0,28.93-3.61,35.81-6.3c-0.3,8.48-1.94,18.03-4.06,23.42
                      h-146.4c-4.74,0-8.57,3.82-8.57,8.55c0,4.72,3.84,8.55,8.57,8.55h94.02v12.9c0,11.1,9.06,20.14,20.19,20.14h25.57
                      c11.13,0,20.19-9.04,20.19-20.14v-18.91c6.83-11.89,9.28-36.53,6.34-50.12c0.03-0.06,0.05-0.12,0.08-0.19
                      C505.85,296.72,505.68,289.17,502.67,282.55z M399.07,189.34h-49.41v-6.52c0-3.66,2.87-6.52,6.54-6.52h36.33
                      c3.67,0,6.54,2.86,6.54,6.52V189.34z M479.56,372.94c0,1.65-1.4,3.05-3.05,3.05h-25.57c-1.65,0-3.05-1.4-3.05-3.05v-12.9h31.68
                      V372.94z M487.14,297.51c-1.03,2.64-3.05,4.77-5.67,5.88c-8.47,3.71-29.82,6.67-37.2,4.84c3.47-6.71,19.97-20.54,28.44-24.27
                      c0.03-0.01,0.05-0.02,0.08-0.03c2.55-1.17,5.46-1.24,8.13-0.2c2.73,1.04,4.89,3.07,6.09,5.8
                      C488.14,292.01,488.19,294.84,487.14,297.51z M443.82,263.68c0-4.72-3.83-8.55-8.57-8.55H308.54c-4.74,0-8.57,3.82-8.57,8.55
                      c0,4.72,3.83,8.55,8.57,8.55h126.71C439.98,272.23,443.82,268.4,443.82,263.68z M338.76,293.4c-4.74,0-8.57,3.82-8.57,8.55
                      c0,4.72,3.83,8.55,8.57,8.55h66.26c4.74,0,8.57-3.82,8.57-8.55c0-4.72-3.83-8.55-8.57-8.55H338.76z M399.9,91.9l20.92,26.97
                      c1.22,1.57,3.1,2.49,5.09,2.49c1.99,0,3.87-0.92,5.08-2.49L451.9,91.9c0.87-0.79,1.7-1.62,2.5-2.49
                      c14.36-15.7,13.24-40.03-2.5-54.35c-15.74-14.32-40.14-13.21-54.5,2.49C383.04,53.24,384.16,77.57,399.9,91.9z M425.9,37.99
                      c14.2,0,25.72,11.48,25.72,25.65c0,7.32-3.18,14.28-8.72,19.08c-0.3,0.27-0.57,0.56-0.81,0.87l-16.18,20.87L409.71,83.6
                      c-0.24-0.31-0.52-0.61-0.81-0.87c-5.54-4.8-8.72-11.76-8.73-19.08C400.18,49.48,411.69,37.99,425.9,37.99z M425.9,82.88
                      c10.65,0,19.29-8.61,19.29-19.24c0-10.63-8.64-19.24-19.29-19.24c-10.66,0-19.29,8.61-19.29,19.24
                      C406.61,74.27,415.24,82.88,425.9,82.88z M425.9,57.23c3.55,0,6.43,2.87,6.43,6.41c0,3.54-2.88,6.41-6.43,6.41
                      c-3.55,0-6.43-2.87-6.43-6.41C419.47,60.1,422.35,57.23,425.9,57.23z M419.47,179.06h12.86v-12.83h-12.86V179.06z M432.33,140.59
                      h-12.86v12.83h12.86V140.59z M374.46,31.58H361.6v12.82h12.86V31.58z M348.74,31.58h-12.86v12.82h12.86V31.58z M323.02,31.58
                      h-12.86v12.82h12.86V31.58z M290.87,31.58h-12.86v12.82h12.86V31.58z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Manage Game Category'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M90.4,0v511h331.2V0H90.4z M405.13,494.5H106.97v-49.49h298.15V494.5z M405.13,428.51H106.97V82.38h298.15V428.51z
                      M405.13,65.99H106.97V16.5h298.15V65.99z M289.1,32.99h-66.2v16.5h66.2V32.99z M297.39,461.51h-82.77v16.5h82.77V461.51z
                      M206.43,32.99h-16.58v16.5h16.58V32.99z M173.27,32.99H156.7v16.5h16.57V32.99z M256.05,296.74c54.78,0,99.35-44.36,99.35-98.88
                      c0-54.52-44.57-98.88-99.35-98.88c-54.78,0-99.35,44.36-99.35,98.88C156.7,252.38,201.27,296.74,256.05,296.74z M256.05,280.14
                      c-18.7,0-35.78-6.13-49.73-16.6c0.1-27.16,22.34-49.29,49.73-49.29c27.39,0,49.62,22.13,49.73,49.29
                      C291.93,273.91,274.75,280.14,256.05,280.14z M239.47,173.12c0-9.15,7.48-16.5,16.58-16.5c9.09,0,16.57,7.34,16.57,16.5
                      c0,9.15-7.48,16.5-16.57,16.5C246.95,189.61,239.47,182.27,239.47,173.12z M256.05,115.38c45.68,0,82.77,37.02,82.77,82.38
                      c0,19.41-6.87,37.32-18.19,51.5c-5.26-23.44-23.14-42.15-46.19-48.79c8.9-5.94,14.65-15.99,14.65-27.36
                      c0-18.21-14.86-32.99-33.15-32.99c-18.29,0-33.15,14.79-33.15,32.99c0,11.37,5.86,21.43,14.65,27.36
                      c-23.04,6.64-40.83,25.35-46.19,48.79c-11.42-14.08-18.19-31.99-18.19-51.5C173.27,152.39,210.37,115.38,256.05,115.38z
                      M177.42,412.12c29.71,0,53.87-24.04,53.87-53.61c0-29.57-24.15-53.62-53.87-53.62c-29.71,0-53.87,24.04-53.87,53.62
                      C123.55,388.08,147.71,412.12,177.42,412.12z M177.42,321.39c20.52,0,37.29,16.6,37.29,37.12c0,20.42-16.68,37.12-37.29,37.12
                      c-20.62,0-37.3-16.6-37.3-37.12C140.12,338.08,156.8,321.39,177.42,321.39z M204.71,342.91l-13.24-9.96l-19.1,25.35l-9.8-9.76
                      l-11.72,11.67l23.35,23.24L204.71,342.91z M280.91,358.5c0,29.57,24.16,53.61,53.87,53.61c29.71,0,53.87-24.04,53.87-53.61
                      c0-29.57-24.16-53.62-53.87-53.62C305.07,304.89,280.91,329.03,280.91,358.5z M371.98,358.5c0,20.42-16.68,37.12-37.29,37.12
                      c-20.62,0-37.19-16.6-37.19-37.12c0-20.42,16.68-37.12,37.29-37.12C355.4,321.39,371.98,338.08,371.98,358.5z M319.82,332.05
                      l-11.62,11.67l14.86,14.79l-14.86,14.79l11.62,11.67l14.86-14.79l14.86,14.79l11.72-11.67l-14.86-14.79l14.86-14.79l-11.72-11.67
                      l-14.86,14.79L319.82,332.05z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'League Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M29.93,0c-5.96,0.56-11.02,6.16-10.99,12.17v486.67c0,6.37,5.78,12.17,12.13,12.17h351.82c6.35,0,12.13-5.8,12.13-12.17
                  v-97.52c1.29-0.97,2.73-1.83,3.98-2.85l72.41,72.43c4.48,4.62,12.8,4.66,17.32,0.07c4.52-4.59,4.4-12.93-0.26-17.37l-72.22-72.43
                  c17.12-20.98,27.3-47.85,27.3-76.99c0-39.64-19.15-74.72-48.53-96.95v-85.55c0.03-3.24-1.3-6.47-3.6-8.75L282.23,3.42
                  c-2.26-2.19-5.39-3.45-8.53-3.42C192.47,0,111.08,0,29.93,0z M43.2,24.33h212.3v103.42c0,6.37,5.78,12.17,12.13,12.17h103.12v52.85
                  c-14.86-6.53-31.3-10.26-48.53-10.26c-36.7,0-69.66,16.64-91.94,42.58c-44.51,0-89.21,0-133.64,0c-6.33,0.32-11.81,6.38-11.51,12.73
                  c0.3,6.35,6.31,11.87,12.65,11.6h116.2c-6.62,13.06-10.99,27.36-12.51,42.58c-34.91,0-70,0-104.83,0
                  c-6.38,0.26-11.94,6.34-11.64,12.74c0.3,6.4,6.4,11.94,12.78,11.59h103.69c1.5,15.24,5.9,29.51,12.51,42.59
                  c-39.08,0-78.34,0-117.34,0c-6.35,0.3-11.86,6.37-11.56,12.74c0.3,6.37,6.35,11.9,12.7,11.6h132.31
                  c21.01,24.58,51.58,40.83,85.87,42.58c-73.08,0-146.32,0-219.32,0c-6.35,0.3-11.86,6.37-11.56,12.74c0.3,6.37,6.35,11.89,12.7,11.6
                  h218.37c6.41,0.09,12.3-5.74,12.3-12.17c0-6.43-5.89-12.26-12.3-12.17c2,0.1,4.04,0,6.07,0c17.23,0,33.67-3.55,48.53-10.08v70.91
                  H43.2V24.33z M279.77,35.36l79.99,80.22h-79.99V35.36z M322.23,206.83c53.74,0,97.05,43.43,97.05,97.33
                  c0,53.9-43.31,97.33-97.05,97.33s-97.05-43.43-97.05-97.33C225.18,250.27,268.49,206.83,322.23,206.83z M320.9,237.06
                  c-5.96,0.66-10.94,6.34-10.81,12.36v12.17c-0.09,6.43,5.72,12.34,12.13,12.34c6.41,0,12.22-5.91,12.13-12.34v-12.17
                  C334.51,242.52,327.75,236.31,320.9,237.06z M320.9,291.81c-5.96,0.66-10.94,6.34-10.81,12.36v54.75
                  c-0.09,6.43,5.72,12.34,12.13,12.34c6.41,0,12.22-5.91,12.13-12.34v-54.75C334.51,297.27,327.75,291.05,320.9,291.81z"></path>
                </svg>`,
					title: 'Match Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M259.12,237.05c-48.61,0-88.15-38.41-88.15-85.62s39.54-85.62,88.15-85.62c48.61,0,88.15,38.41,88.15,85.62
                  S307.73,237.05,259.12,237.05z M259.12,87.5c-36.29,0-65.81,28.68-65.81,63.92c0,35.25,29.52,63.92,65.81,63.92
                  c36.29,0,65.81-28.68,65.81-63.92C324.93,116.18,295.41,87.5,259.12,87.5z M256.34,473.86c-0.03,0-0.07,0-0.09,0
                  c-3.92-0.03-7.54-2.06-9.53-5.34c-4.23-6.98-104.02-172.06-133.34-269.48c-3.09-8.82-5.4-18.46-6.71-28.21
                  c-1.18-7.39-1.63-13.92-1.33-19.7C105.49,68.87,174.44,2,259.17,2C343.99,2,413,69.03,413,151.42l-0.03,1.38l-11.19,0.44l11.16-0.28
                  c0.1,3.82-0.12,8.02-0.65,12.46c-1.32,14.1-4.8,28.14-10.31,41.49c-33.35,98.28-131.94,255.12-136.12,261.76
                  C263.83,471.9,260.23,473.86,256.34,473.86z M259.17,23.7c-72.5,0-131.49,57.29-131.49,127.72c0,0.2,0,0.4-0.02,0.61
                  c-0.24,4.26,0.13,9.56,1.12,15.74c1.15,8.6,3.12,16.83,5.89,24.74c23.49,78.02,95.84,204.78,121.86,249.1
                  c26.17-42.87,97.92-163.68,124.34-241.8c0.08-0.23,0.16-0.45,0.26-0.67c4.77-11.49,7.78-23.58,8.95-35.95
                  c0.44-3.7,0.61-6.86,0.54-9.67c-0.01-0.12-0.01-0.24-0.01-0.37l0.05-1.72C390.65,81,331.67,23.7,259.17,23.7z M407.41,463.01
                  c0-30.47-59.95-41.88-95.69-45.96c-6.09-0.75-11.67,3.55-12.4,9.51c-0.72,5.95,3.66,11.34,9.79,12.05
                  c57.76,6.61,75.96,20.95,75.96,24.41c0,7.92-45.11,27.29-128.73,27.29c-83.62,0-128.73-19.36-128.73-27.29
                  c0-3.5,18.4-17.95,76.8-24.5c6.13-0.69,10.52-6.07,9.81-12.02c-0.71-5.96-6.25-10.27-12.38-9.54
                  c-29.04,3.25-96.59,14.26-96.59,46.06C105.27,496.67,183.58,512,256.34,512C329.1,512,407.41,496.67,407.41,463.01z"></path>
                </svg>`,
					title: 'Revenue Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M248.1,33.25h48.2v-9.72h-48.2V33.25z M271.7,359.09c-6.53,0-12.05,5.62-12.05,12.27c0,6.64,5.52,12.28,12.05,12.28
                      c6.52,0,12.05-5.63,12.05-12.28C283.76,364.71,278.23,359.09,271.7,359.09z M403.26,306.39l-8.54-7.16l11.05-13.8
                      c8.54-10.23,7.53-25.57-3.01-34.27l-10.04-8.7c-0.5-0.51-0.5-0.51-1-1.02l13.56-16.37c8.53-10.23,7.53-25.57-3.01-34.27
                      l-10.04-8.7c-3.01-2.55-7.03-4.59-11.05-5.11V23.53c0-13.3-10.55-23.53-23.1-23.53H185.84c-13.05,0-23.1,10.74-23.1,23.53v109.46
                      c-4.02-4.09-9.54-8.18-16.07-10.74c-12.05-5.12-28.12-1.53-38.16,8.18c-8.53,8.18-10.54,19.43-6.52,31.21
                      c2.51,6.64,5.02,13.8,6.52,21.49c8.03,33.25,9.54,69.56,4.52,97.19l-1.51,8.18c-7.03,38.87-13.56,75.19-2.01,102.82
                      c10.04,24.03,23.1,36.32,34.65,47.05l1,1.03c2.51,2.04,3.01,5.62,2.01,8.18l-10.55,28.14l146.62,35.29l8.04-20.45
                      c1-2.57,3.01-4.1,5.52-4.61c7.53-1.02,19.08-5.12,31.63-17.9c15.57-16.38,29.63-45.53,35.15-57.8c2.01,0.52,4.02,1.03,6.53,1.03
                      c7.03,0,13.56-3.08,18.08-8.7l10.54-12.78c4.02-5.12,6.03-11.25,5.52-17.91c-0.5-6.65-3.52-12.28-8.54-16.37l-1.01-1.02
                      l11.55-13.81C414.81,330.44,413.81,315.1,403.26,306.39z M393.22,262.41c4.52,3.58,5.02,9.72,1.5,13.81l-13.05,15.86l-6.03,7.15
                      c-1,1.03-2.01,1.53-3.51,2.57l-1.51,0.51h-1.51l-4.52-1.54c-1.01-0.51-2.01-1.02-2.51-1.53l-10.04-8.69
                      c-2.01-1.53-3.01-4.09-3.52-6.65c0-2.04,1-4.09,2.01-6.14l17.58-20.97c1,0,2.01,0,3.01-0.51h1.51c1.51-0.51,3.01-1.02,4.52-1.53
                      c1.5-0.51,3.01-1.53,4.02-2.04c0.5,0.51,1,0.51,2.01,1.02L393.22,262.41z M375.14,191.3h1.5c2.01,0,4.52,1.03,6.03,2.05
                      l11.05,8.69c4.02,3.58,4.52,9.72,1,13.3l-19.08,23.01l-0.5,0.52c-1.01,1.02-2.01,1.54-3.01,2.04c-0.5,0-0.5,0-1,0.52l-4.52-0.52
                      c-1.5-0.5-3.01-1.02-4.52-2.04l-10.04-8.7c-2.01-1.54-3.01-4.09-3.52-6.64c0-2.05,0.5-4.09,2.01-6.14l19.08-23.01
                      C371.13,192.84,373.13,191.3,375.14,191.3z M176.8,23.53c0-4.6,4.02-8.7,9.04-8.7h173.23c5.02,0,8.54,4.1,8.54,8.7v17.38H176.8
                      V23.53z M176.8,56.26h190.81V177.5c-3.01,1.52-6.02,3.57-8.54,6.64l-13.56,16.37c-1-9.21-8.03-15.86-17.07-15.86h-3.51
                      l-11.05-15.35c-4.02-5.62-10.04-8.68-16.57-8.68h-29.62c-5.52,0-10.55,2.03-14.56,6.13l-17.58,17.9h-20.08
                      c-9.54,0-17.58,8.19-17.58,17.91v15.35h16.07c0-0.52,0-1.03,0-1.54c0-9.22,7.53-16.88,16.57-16.88c9.04,0,16.57,7.66,16.57,16.88
                      c0,0.5,0,1.02,0,1.54h49.71c0-0.52,0-1.03,0-1.54c0-9.22,7.53-16.88,16.57-16.88c9.04,0,16.57,7.66,16.57,16.88
                      c0,0.5,0,1.02,0,1.54h5.52c-0.5,2.55-0.5,4.6-0.5,7.16c0.5,6.65,3.01,12.79,8.53,17.39l10.04,8.7c0,0,0.5,0,0.5,0.51L341,267.52
                      c-4.02,5.12-6.03,11.26-5.52,17.91c0,0.51,0,0.51,0,1.02c0.5,6.64,3.52,12.28,8.53,16.36l8.03,7.16L341,322.77
                      c-4.02,4.6-5.52,10.22-5.52,16.36H176.8V56.26z M317.4,458.32c-9.54,9.72-17.57,12.78-23.1,13.82
                      c-7.53,1.02-14.06,6.13-17.07,13.8l-3.01,8.7l-118-29.16l4.52-12.28c3.01-8.7,1.01-18.42-5.52-24.56l-1.01-1.02
                      c-11.05-10.23-22.09-20.97-31.13-41.94c-10.04-23.54-3.52-58.32,3.01-94.64l1.51-8.18c5.53-29.67,3.52-68.55-4.52-103.33
                      c-2.01-8.19-4.52-15.86-7.53-23.02c-2.01-6.14-1-11.25,3.01-15.34c5.52-5.63,15.57-8.2,22.09-5.12
                      c19.58,8.7,21.59,27.62,21.59,29.66v219.45c0,13.29,10.55,23.53,23.1,23.53h162.69C342,421.49,329.95,445.02,317.4,458.32z
                      M348.53,368.29c-4.02,5.12-6.02,11.26-5.52,17.9c0,0.52,0,0.52,0,1.02c0,2.05,1,4.61,1.51,6.65H185.84
                      c-5.02,0-8.53-4.08-8.53-8.69v-31.21h161.18c1,1.53,2.51,3.08,4.02,4.6l8.03,7.17L348.53,368.29z M387.19,380.57l-10.55,12.79
                      c-2.01,2.55-5.02,3.59-8.03,3.59l-4.02-2.56c-0.5-0.52-1.01-0.52-1.51-1.03l-2.51-2.04c-2.01-1.53-3.01-4.1-3.52-6.65
                      c0-2.05,1-4.1,2.01-6.14l5.02-6.14h3.01c0,0,0,0,0.5,0h2.51c1.51,0,2.51-0.52,4.02-0.52c0.5,0,1.01-0.51,1.01-0.51
                      c1,0,1.5-0.52,2.01-0.52c0.5,0,1-0.5,1.5-0.5c0.5,0,1-0.52,1-0.52c1-0.51,2.01-1.54,3.01-2.05c0.5,0,0.5-0.51,1-0.51
                      c0.5-0.51,0.5-0.51,1-1.02l1,1.02c2.01,1.54,3.01,4.09,3.52,6.65C389.7,375.97,389.2,378.53,387.19,380.57z M394.72,331.46
                      l-13.05,15.86l-6.03,7.16c-0.5,1.02-1.51,1.53-3.01,2.04l-0.5,0.52c0,0-0.5,0-1,0.51c-0.5,0-1,0.51-1.51,0.51l-4.52-2.04
                      c-1.01-0.52-2.01-1.02-2.51-1.53l-10.04-8.7c-2.01-1.53-3.01-4.09-3.51-6.65c0-2.04,0.5-4.08,2.01-6.14l13.56-16.36h3.01
                      c0,0,0,0,0.5,0h1.51c0.5,0,1,0,1,0c1.51,0,2.51-0.52,3.52-0.52c0.5,0,0.5,0,1.01,0c0.5,0,1-0.5,1.5-0.5c0.5,0,1.01-0.51,1.01-0.51
                      c0.5,0,1-0.51,1-0.51c1.51-0.51,2.51-1.53,3.52-2.56c0.5-0.51,0.5-0.51,1.01-1.02c0.5-0.52,1-0.52,1.51-1.03l8.54,7.67
                      C397.74,321.24,398.24,327.37,394.72,331.46z M313.38,203.58c-6.93,0-12.55,5.73-12.55,12.79c0,7.06,5.62,12.79,12.55,12.79
                      c6.93,0,12.56-5.73,12.56-12.79C325.94,209.31,320.31,203.58,313.38,203.58z M231.03,229.16c6.93,0,12.55-5.73,12.55-12.79
                      c0-7.06-5.62-12.79-12.55-12.79c-6.93,0-12.55,5.73-12.55,12.79C218.48,223.43,224.1,229.16,231.03,229.16z M227.16,299.2
                      c1.97,0,3.54,0.48,4.68,1l1.13-4.8c-0.98-0.55-3.18-1.18-6.06-1.18c-7.46,0-13.45,5.07-13.45,14.19c0,7.6,4.38,13.35,12.9,13.35
                      c3,0,5.3-0.59,6.32-1.14l-0.84-4.71c-1.1,0.47-2.96,0.86-4.64,0.86c-4.97,0-7.89-3.37-7.89-8.72
                      C219.3,302.09,222.74,299.2,227.16,299.2z M250.71,309.9c0-4.55-1.9-8.35-7.85-8.35c-3.25,0-5.7,0.95-6.94,1.7l1.02,3.88
                      c1.17-0.8,3.11-1.47,4.93-1.47c2.74,0,3.25,1.47,3.25,2.5v0.28c-6.32-0.04-10.49,2.38-10.49,7.41c0,3.09,2.15,5.94,5.77,5.94
                      c2.12,0,3.95-0.83,5.12-2.38h0.11l0.33,1.95h5.01c-0.18-1.08-0.26-2.86-0.26-4.73V309.9z M245.3,314.31
                      c0,0.36-0.04,0.71-0.11,1.03c-0.37,1.23-1.5,2.22-2.89,2.22c-1.24,0-2.19-0.75-2.19-2.3c0-2.34,2.26-3.09,5.19-3.05V314.31z
                      M265.67,301.55c-2.96,0-4.78,1.86-5.52,3.09h-0.11l-0.25-2.66h-4.83c0.07,1.71,0.15,3.77,0.15,6.19v13.21h5.56v-11.18
                      c0-0.56,0.07-1.16,0.22-1.51c0.36-1.11,1.28-2.26,2.81-2.26c2.01,0,2.81,1.71,2.81,4.2v10.76h5.56v-11.5
                      C272.07,304.16,269.32,301.55,265.67,301.55z M286,306.18c1.46,0,2.45,0.28,3.1,0.6l0.88-4.48c-1.17-0.47-2.78-0.75-4.24-0.75
                      c-6.65,0-10.23,4.63-10.23,10.29c0,6.11,3.69,9.91,9.47,9.91c2.12,0,3.98-0.38,4.97-0.87l-0.66-4.43c-0.84,0.36-1.86,0.6-3.18,0.6
                      c-2.7,0-4.9-1.87-4.9-5.43C281.18,308.44,283.08,306.18,286,306.18z M300.63,301.55c-6.21,0-9.03,5.43-9.03,10.33
                      c0,6.07,3.47,9.87,9.54,9.87c2.41,0,4.64-0.38,6.47-1.19l-0.73-4.08c-1.5,0.52-3.04,0.79-4.93,0.79c-2.6,0-4.86-1.19-5.04-3.69
                      h11.51c0.07-0.44,0.18-1.34,0.18-2.38C308.59,306.41,306.4,301.55,300.63,301.55z M296.86,309.47c0.15-1.62,1.1-3.92,3.44-3.92
                      c2.56,0,3.14,2.45,3.14,3.92H296.86z M311.93,321.37h5.56v-28.14h-5.56V321.37z M268.11,132.53l11.68-18.57l10.69,18.57h17.53
                      l-18.12-29.12l16.93-27.65h-16.64l-10.4,17.2l-9.8-17.2h-17.43l17.13,27.76l-18.42,29.01H268.11z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Reward Point'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M506.17,369.82l-32.63-47c-9.32-13.43-28.64-16.79-41.96-7.39l-7.99,5.37c-4.66,3.36-10.66,2.01-13.99-2.69l-53.28-79.23
                      c-2-2.01-2-4.7-2-7.39c0-2.69,2-5.37,3.99-6.71l7.99-5.37c13.32-9.4,16.65-28.87,7.33-42.3l-31.97-46.33
                      c-9.32-13.43-27.97-16.79-41.95-7.38l-25.97,18.8c-26.64,18.8-33.3,55.06-14.65,81.92l132.52,192.04
                      c8.66,12.76,21.98,21.49,37.29,24.17c3.99,0.67,7.33,0.67,10.66,0.67c11.99,0,23.31-3.36,32.63-10.07l25.97-18.8
                      c6.66-4.7,11.32-11.41,12.65-19.47C512.83,384.59,510.83,376.54,506.17,369.82z M490.86,389.29c0,2.69-2,5.37-3.99,6.71
                      l-25.97,18.8c-8.66,5.37-18.65,8.06-28.64,6.04c-9.99-2.01-18.65-7.39-24.64-16.11L275.09,212.7c-5.99-8.73-7.99-18.8-5.99-27.53
                      c2-10.07,7.32-18.8,15.98-24.84l25.97-18.8c4.66-3.36,10.66-2.01,13.99,2.69L357,190.54c3.33,4.7,2,10.74-2.66,14.1l-7.99,5.37
                      c-6.66,4.7-11.32,11.41-12.65,19.47c-2,8.06,0,16.12,4.66,22.83l53.94,77.89c9.32,13.43,28.64,16.79,41.95,7.39l7.99-5.37
                      c4.66-3.36,10.66-2.01,13.99,2.69l32.63,47C490.86,383.92,491.52,386.61,490.86,389.29z M365.66,127.42
                      c7.33-1.34,13.99,1.34,17.98,7.39c4,5.37,4,12.76,0.67,18.8c-3.33,5.37-1.33,11.41,3.33,14.1c2,0.67,3.33,1.34,5.33,1.34
                      c3.33,0,6.66-2.01,7.99-6.04c7.32-12.09,6.66-28.2-1.33-40.29c-7.99-12.76-23.31-18.8-37.96-15.44
                      c-5.99,1.34-9.32,6.72-7.99,12.09C355.01,125.41,360.33,128.77,365.66,127.42z M366.99,92.51c18.65-4.7,37.96,3.36,49.28,19.47
                      c10.66,15.44,11.32,36.26,2,52.37c-3.33,5.37-1.33,11.42,3.33,14.1c2,0.67,3.33,1.34,5.33,1.34c3.33,0,6.66-1.34,8.66-4.7
                      c13.32-23.5,12.65-52.38-2.67-74.53C417.6,77.73,389.63,66.99,363,72.36c-5.99,1.34-9.32,6.71-7.99,12.09
                      C356.34,90.49,361.66,93.85,366.99,92.51z M172.12,268.29c16.83-12.59,27.72-32.77,27.72-55.59c0-38.27-30.63-69.16-68.59-69.16
                      c-37.96,0-68.59,30.89-68.59,69.16c0,22.82,10.89,43,27.72,55.59C37.67,285.71-0.61,335.85-0.61,394.67v35.59
                      c0,5.37,4.66,10.07,9.99,10.07h243.74c5.33,0,9.99-4.7,9.99-10.07v-35.59C263.1,335.85,224.82,285.71,172.12,268.29z
                      M82.64,212.03c0-26.86,21.98-49.02,48.61-49.02c27.3,0,48.61,22.16,48.61,49.02c0,26.86-21.98,49.02-48.61,49.02
                      C104.61,261.05,82.64,238.89,82.64,212.03z M243.13,420.18H19.37v-25.51c0-62.45,49.95-112.81,111.88-112.81
                      c61.93,0,111.88,50.36,111.88,112.81V420.18z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Bonus Cash Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M169.39,336.97c0-45.69-37.7-82.73-84.21-82.73S0.98,291.28,0.98,336.97c0,42.72,69.33,130.97,77.17,140.08l6.95,8.76
                      l6.95-8.76C100.07,467.77,169.39,379.52,169.39,336.97z M19.34,336.97c0-36.02,29.72-65.22,66.38-65.22s66.38,29.2,66.38,65.22
                      c-0.53,27.84-43.66,90.53-66.83,120.99C61.75,427.5,19.34,364.81,19.34,336.97z M85.28,299.15c-21.65,0-39.21,17.25-39.21,38.52
                      c0,21.27,17.55,38.52,39.21,38.52c21.65,0,39.21-17.25,39.21-38.52C124.38,316.44,106.89,299.25,85.28,299.15z M85.28,358.86
                      c-11.81,0-21.39-9.41-21.39-21.01c0-11.6,9.57-21.01,21.39-21.01c11.81,0,21.39,9.41,21.39,21.01
                      C106.56,349.42,97.05,358.77,85.28,358.86z M426.91,231.57l6.95-8.75c7.84-9.98,77.17-98.23,77.17-140.08
                      c0-45.69-37.7-82.73-84.2-82.73c-46.51,0-84.21,37.04-84.21,82.73c0,42.72,69.32,130.97,77.17,140.08L426.91,231.57z
                      M426.91,16.72c36.64,0.1,66.3,29.31,66.3,65.31c0,27.84-42.95,90.18-66.3,120.99c-23.52-30.82-66.47-93.15-66.47-120.99
                      C360.44,45.96,390.2,16.72,426.91,16.72z M466.12,82.73c0-21.27-17.55-38.52-39.21-38.52c-21.65,0-39.21,17.25-39.21,38.52
                      c0,21.27,17.55,38.52,39.21,38.52C448.52,121.16,466.02,103.97,466.12,82.73z M405.35,82.73c0-11.6,9.57-21.01,21.38-21.01
                      c11.81,0,21.39,9.41,21.39,21.01c0,11.54-9.47,20.91-21.21,21.01C415.07,103.75,405.44,94.37,405.35,82.73z M380.04,256.78
                      c-17.82-3.15-39.74-6.83-57.74,10.86c-18,17.68-14.26,38.35-11.05,56.73c3.21,18.38,4.81,30.64-6.06,41.32
                      c-10.87,10.68-22.99,9.11-42.06,5.95s-39.74-6.65-57.74,10.86c-18,17.51-14.26,38.35-11.05,56.73
                      c3.21,18.39,4.63,30.82-6.06,41.32c-10.69,10.51-22.99,9.28-42.06,5.95c-19.07-3.33-39.74-6.48-57.56,11.03l12.65,12.43
                      c10.87-10.68,22.99-9.1,42.06-5.95c19.07,3.15,39.74,6.65,57.74-10.86c18-17.51,14.26-38.35,11.05-56.73
                      c-3.21-18.38-4.63-30.82,6.06-41.32c10.69-10.51,22.99-9.1,42.06-5.95s39.74,6.65,57.74-10.86c18-17.51,14.26-38.35,11.05-56.73
                      c-3.21-18.39-4.81-30.64,6.06-41.32c10.87-10.68,22.99-9.11,42.06-5.95s39.74,6.65,57.74-10.86L422.28,251
                      C411.41,261.51,399.11,259.93,380.04,256.78z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'CMS System'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M277.19,495.04l-4.14-5.17H158.29H43.52l-4.14-2.07c-6.2-3.1-10.34-8.27-12.41-15.51c-1.03-4.14-1.03-428.09,0-432.23
                  c1.03-6.2,6.2-12.41,12.41-15.51l3.1-2.07h174.74h174.74l4.14,2.07c6.2,3.1,10.34,8.27,12.41,15.51c0,2.07,1.03,38.26,1.03,106.51
                  v104.44h4.14c2.07,0,7.24,1.03,10.34,1.03c3.1,1.03,6.2,1.03,6.2,1.03c1.03,0,1.03-15.51,1.03-103.4c0-64.11,0-105.47-1.03-109.61
                  c0-3.1-1.03-7.24-2.07-9.31c-5.17-15.51-17.58-26.88-34.12-29.99c-6.2-1.03-346.38-1.03-352.58,0C31.11,2.84,20.77,9.05,14.57,18.35
                  C8.36,25.59,6.3,31.8,5.26,41.1c0,4.14,0,78.59,0,219.22v213.01l1.03,4.14c4.14,14.48,14.48,25.85,27.92,31.02
                  c2.07,1.03,6.2,2.07,8.27,2.07s41.36,0,126.14,0h122.01l-5.17-5.17C283.4,502.28,279.26,498.15,277.19,495.04z M310.28,141.4v-11.37
                  h-98.23h-98.23v11.37v11.37h98.23h98.23V141.4z M310.28,206.55v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V206.55z
                  M307.18,279.96l3.1-2.07v-8.27v-8.27h-98.23h-98.23v10.34v10.34h95.12h95.12L307.18,279.96z M503.63,377.16
                  c-7.24-31.02-28.95-57.91-57.9-72.38c-18.61-9.31-39.29-12.41-59.97-10.34c-6.2,1.03-16.54,3.1-22.75,5.17
                  c-7.24,2.07-19.64,8.27-25.85,12.41c-22.75,15.51-39.29,38.26-45.49,63.08c-2.07,9.31-3.1,16.54-3.1,26.89
                  c0,17.58,3.1,32.05,10.34,47.57c19.64,40.33,63.07,65.14,108.57,61.01c46.53-4.14,85.82-38.26,96.16-83.76
                  C507.77,412.32,507.77,393.71,503.63,377.16z M480.88,429.9c-10.34,29.99-35.16,52.74-67.21,57.91c-8.27,1.03-24.81,1.03-32.05,0
                  c-33.09-7.24-58.94-31.02-67.21-63.08c-2.07-8.27-3.1-13.44-3.1-22.75c0-9.31,1.03-14.48,3.1-22.75
                  c6.2-23.78,22.75-44.46,45.5-55.84c8.27-4.14,14.48-6.2,24.81-8.27c7.24-1.03,24.81-1.03,32.05,1.03
                  c8.27,2.07,13.44,4.14,21.71,7.24c19.64,9.31,34.12,25.85,41.36,45.5C486.05,389.57,487.09,410.25,480.88,429.9z M114.86,326.5
                  v10.34v10.34h70.31h70.31l2.07-5.17c1.03-3.1,3.1-8.27,5.17-10.34l3.1-5.17h-75.48H114.86z M408.51,391.64v-21.71v-21.71h-11.37
                  h-11.37v33.09v33.09h33.09h33.09v-10.34v-10.34h-21.71h-21.71V391.64z"></path>
                </svg>`,
					title: 'Manage Report'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M255.49,306.18c65.89,0,119.31-52.44,119.31-117.13c0-64.69-53.42-117.13-119.31-117.13
                      c-65.89,0-119.31,52.44-119.31,117.13C136.25,253.71,189.63,306.12,255.49,306.18z M255.49,88.65
                      c56.48,0,102.27,44.95,102.27,100.4c0,55.45-45.79,100.4-102.27,100.4c-56.48,0-102.27-44.95-102.27-100.4
                      C153.29,133.63,199.04,88.71,255.49,88.65z M505.76,368.96c-3.25-5.51-7.8-10.16-13.28-13.56c-11.61-7.26-26.21-8.13-38.64-2.32
                      l-98.53,45.82c7.45-19.47-2.59-41.17-22.42-48.48c-4.31-1.59-8.88-2.4-13.48-2.4H216.33c-38.23-35.12-95.14-42.1-140.99-17.29
                      l-1.02,0.56H-0.17v16.73H68v142.23H-0.17v16.73h299.68c28.49-0.01,56.47-7.39,81.13-21.39l110.49-62.8
                      C510.31,411.89,516.86,387.79,505.76,368.96z M482.56,408.27l0.03,0.03l-110.49,62.8c-22.06,12.53-47.1,19.13-72.59,19.14H85.05
                      V344.59c40.17-20.93,89.54-13.75,121.81,17.71c1.6,1.57,3.76,2.45,6.02,2.45h106.53c11.77,0,21.3,9.37,21.3,20.92
                      s-9.54,20.92-21.3,20.92H212.88c-4.71,0-8.52,3.75-8.52,8.37s3.82,8.37,8.52,8.37h127.83c1.26-0.01,2.51-0.3,3.65-0.84
                      l116.75-54.31c11.52-5.37,25.29-0.56,30.76,10.74C497.04,389.59,492.99,402.37,482.56,408.27z M187.31,189.05
                      c0.04-36.95,30.54-66.89,68.18-66.93v-16.73c-47.05,0.05-85.17,37.48-85.22,83.67H187.31z M255.49,272.72
                      c47.04-0.05,85.17-37.48,85.22-83.67h-17.05c-0.04,36.95-30.54,66.89-68.18,66.93V272.72z M83.65,55.07
                      c13.89,2.28,24.77,12.96,27.08,26.6c0.78,4.56,5.18,7.62,9.82,6.85c3.58-0.59,6.38-3.34,6.98-6.85
                      c2.32-13.63,13.2-24.32,27.08-26.6c4.64-0.77,7.77-5.09,6.98-9.64c-0.6-3.51-3.41-6.26-6.98-6.86
                      c-13.89-2.28-24.77-12.96-27.08-26.6c-0.79-4.56-5.18-7.62-9.82-6.86c-3.58,0.59-6.38,3.34-6.98,6.86
                      c-2.32,13.63-13.2,24.32-27.08,26.6c-4.64,0.77-7.77,5.09-6.98,9.64C77.27,51.73,80.07,54.48,83.65,55.07z M119.14,34.87
                      c3.32,4.64,7.44,8.69,12.17,11.95c-4.73,3.26-8.85,7.3-12.17,11.95c-3.32-4.64-7.44-8.69-12.17-11.95
                      C111.7,43.56,115.81,39.52,119.14,34.87z M50.96,448.42c0-13.86-11.45-25.1-25.57-25.1c-14.12,0-25.57,11.24-25.57,25.1
                      s11.45,25.1,25.57,25.1C39.51,473.52,50.96,462.28,50.96,448.42z M16.87,448.42c0-4.62,3.82-8.37,8.52-8.37
                      c4.71,0,8.52,3.75,8.52,8.37s-3.82,8.37-8.52,8.37C20.69,456.78,16.87,453.04,16.87,448.42z M390.45,264.23
                      c13.89,2.28,24.77,12.96,27.08,26.6c0.79,4.56,5.18,7.62,9.82,6.86c3.58-0.59,6.38-3.34,6.98-6.86
                      c2.32-13.63,13.2-24.32,27.08-26.6c4.64-0.77,7.77-5.09,6.98-9.64c-0.61-3.51-3.41-6.26-6.98-6.86
                      c-13.89-2.28-24.77-12.96-27.08-26.6c-0.78-4.56-5.18-7.63-9.82-6.86c-3.58,0.59-6.38,3.34-6.98,6.86
                      c-2.32,13.63-13.2,24.32-27.08,26.6c-4.64,0.77-7.77,5.09-6.98,9.64C384.07,260.89,386.87,263.64,390.45,264.23z M425.93,244.04
                      c3.32,4.64,7.44,8.69,12.17,11.95c-4.73,3.26-8.85,7.3-12.17,11.95c-3.32-4.64-7.44-8.69-12.17-11.95
                      C418.49,252.72,422.61,248.68,425.93,244.04z M246.97,147.22c-14.12,0-25.57,11.24-25.57,25.1c0,13.86,11.45,25.1,25.57,25.1
                      h17.04c4.71,0,8.52,3.74,8.52,8.37c0,4.62-3.82,8.37-8.52,8.37h-34.09v16.73h17.04v8.37h17.04v-8.37
                      c14.12,0,25.57-11.24,25.57-25.1c0-13.86-11.45-25.1-25.57-25.1h-17.04c-4.71,0-8.52-3.75-8.52-8.37c0-4.62,3.82-8.37,8.52-8.37
                      h34.09v-16.73h-17.04v-8.37h-17.04V147.22z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Player Auction Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M255.5,1C115.08,1,1,115.08,1,255.5S115.08,510,255.5,510C395.92,510,510,395.92,510,255.5C510,115.08,395.92,1,255.5,1z
                  M255.5,24.14c127.92,0,231.36,103.45,231.36,231.36c0,127.91-103.45,231.36-231.36,231.36c-127.91,0-231.36-103.45-231.36-231.36
                  C24.14,127.58,127.59,24.14,255.5,24.14z M254.05,35.7c-5.69,0.62-10.43,6.03-10.3,11.75l0.18,40.13
                  c-0.02,6.07,5.5,11.61,11.57,11.61c6.07,0,11.59-5.54,11.57-11.61l-0.18-40.31C266.93,40.77,260.52,34.99,254.05,35.7z
                  M144.51,66.02c-4.25,3.52-6.12,10.19-3.24,15.05l20.24,34.89c3.08,5.25,10.65,7.23,15.91,4.16c5.25-3.08,7.23-10.65,4.16-15.91
                  l-20.24-34.7C155.8,63.76,151.04,62.78,144.51,66.02z M349.49,69.5l-20.06,34.89c-3.54,5.27-1.63,13.38,3.89,16.52
                  c5.52,3.13,13.47,0.61,16.18-5.13l20.06-34.89c2.68-7.78,0.57-11.97-5.01-16.23C358.06,61.97,353.37,65.72,349.49,69.5z M279,215.55
                  c-6.9-4.1-14.95-6.51-23.5-6.51c-25.42,0-46.27,20.85-46.27,46.27s20.85,46.27,46.27,46.27c25.42,0,46.27-20.85,46.27-46.27
                  c0-8.55-2.41-16.42-6.51-23.32l77.54-77.54c4.72-7.06,4.12-11.7-0.47-17.34c-5.76-3.72-10.16-2.45-15.42,0.66
                  C330.85,163.54,304.94,189.61,279,215.55z M429.93,141.44l-34.7,20.24c-5.91,2.64-8.54,10.79-5.29,16.38
                  c3.25,5.59,11.64,7.34,16.86,3.5l34.71-20.24c5.93-6.25,6.66-10.98,2.88-17.4C439.19,139.52,435.45,139.43,429.93,141.44z
                  M65.26,145.23c-3.97,6.39,0.57,12.63,4.07,16.46l34.89,19.88c5.25,3.03,12.79,1.01,15.82-4.25c3.03-5.25,1-12.79-4.25-15.82
                  l-34.89-19.88C74.49,138.32,69.67,141.44,65.26,145.23z M255.5,232.18c11.57,0.29,23.14,6.07,23.14,23.14
                  c0,12.91-10.22,23.14-23.14,23.14s-23.14-10.22-23.14-23.14C232.36,242.4,242.59,232.18,255.5,232.18z M87.4,243.93l-40.13,0.18
                  c-6.07,0-11.61,5.5-11.61,11.57s5.54,11.59,11.61,11.57l40.13-0.18c6.07,0,11.61-5.5,11.61-11.57
                  C99.01,249.43,93.47,243.91,87.4,243.93z M463.73,243.93l-40.31,0.18c-6.06,0.06-11.53,5.6-11.48,11.66
                  c0.05,6.06,5.6,11.53,11.66,11.48l40.13-0.18c6.07,0,11.61-5.5,11.61-11.57C475.34,249.43,469.8,243.91,463.73,243.93z
                  M151.2,313.52c0,29.83,0,53.64,0,80.98c74.39,0,142.83,0,208.23,0c0-29.83,0-53.64,0-80.98
                  C287.48,313.52,213.03,313.52,151.2,313.52z M174.34,336.66h23.32v34.7h-23.32V336.66z M220.8,336.66h23.13v34.7H220.8V336.66z
                  M267.07,336.66h23.14v34.7h-23.14V336.66z M313.34,336.66h22.95v34.7h-22.95V336.66z"></path>
                </svg>`,
					title: 'Player Valuation for Auction'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M255.55,170.22c47.14,0,85.2-38.02,85.2-85.11c0-47.1-38.06-85.11-85.2-85.11s-85.2,38.02-85.2,85.11
                      C170.35,132.21,208.4,170.22,255.55,170.22z M255.55,22.7c34.65,0,62.48,27.8,62.48,62.42c0,34.61-27.83,62.42-62.48,62.42
                      c-34.65,0-62.48-27.8-62.48-62.42C193.07,50.5,220.9,22.7,255.55,22.7z M255.55,136.18c11.93,0,23.29-5.67,31.24-15.32
                      c3.98-5.11,3.41-11.92-1.71-15.89c-5.11-3.97-11.93-3.41-15.9,1.7c-7.95,9.08-20.45,9.08-27.83,0c-3.98-5.11-11.36-5.67-15.9-1.7
                      c-5.11,3.97-5.68,11.35-1.7,15.89C232.26,130.51,243.62,136.18,255.55,136.18z M112.98,340.45h284.57
                      c12.5,0,22.15-10.21,22.15-22.13c0.57-69.22-55.66-125.4-124.96-125.4h-78.38c-69.3,0-125.53,56.17-125.53,125.4
                      C90.82,330.23,101.05,340.45,112.98,340.45z M216.35,215.62h78.95c56.23,0,102.24,45.96,102.81,102.13H113.54
                      C113.54,261.58,159.55,215.62,216.35,215.62z M126.61,418.19l-31.24-4.54c-1.14,0-2.27-1.13-2.84-2.27l-14.2-30.07
                      c-1.7-3.97-5.68-6.81-10.22-6.81c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27L9.6,418.19
                      c-3.98,0.57-7.95,3.4-9.09,7.38c-1.13,3.97-0.57,8.51,2.84,11.35l22.72,23.83c1.14,1.13,1.14,2.27,1.14,3.41l-5.68,33.48
                      c-0.57,4.54,1.14,8.51,4.54,11.35c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32
                      c1.7,1.13,3.41,1.13,5.68,1.13c2.27,0,4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35L109,463.58c0-1.13,0-2.27,1.14-3.4
                      l22.72-23.26c2.84-2.84,3.98-7.38,2.84-11.35C134.56,421.59,130.59,418.75,126.61,418.19z M93.66,444.85
                      c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.39-3.97c-7.95-4.54-17.61-4.54-25.56,0l-7.38,3.97l1.7-10.21
                      c1.14-8.51-1.14-17.02-7.38-22.7l-7.38-7.94l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                      c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L93.66,444.85z M314.05,418.19l-31.24-4.54c-1.14,0-2.27-1.13-2.84-2.27l-14.2-30.07
                      c-1.71-3.97-5.68-6.81-10.23-6.81c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27l-31.24,4.54
                      c-3.98,0.57-7.95,3.4-9.09,7.38c-1.14,3.97-0.57,8.51,2.84,11.35l22.72,23.83c1.14,1.13,1.14,2.27,1.14,3.41l-5.68,33.48
                      c-0.57,4.54,1.13,8.51,4.54,11.35c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32
                      c1.71,1.13,3.41,1.13,5.68,1.13c2.27,0,4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35l-5.68-33.48c0-1.13,0-2.27,1.13-3.4
                      l22.72-23.26c2.84-2.84,3.97-7.38,2.84-11.35C322,421.59,318.03,418.75,314.05,418.19z M281.11,444.85
                      c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.38-3.97c-3.41-1.7-7.95-2.84-12.5-2.84c-4.54,0-9.09,1.13-13.06,3.4l-7.39,3.97
                      l1.71-10.21c1.14-8.51-1.14-17.02-7.39-22.7l-7.38-8.51l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                      c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L281.11,444.85z M507.74,436.91c2.84-2.84,3.98-7.38,2.84-11.35
                      c-1.14-3.97-5.11-6.81-9.09-7.38l-31.24-4.54c-1.13,0-2.27-1.13-2.84-2.27l-14.2-30.07c-1.7-3.97-5.68-6.81-10.22-6.81
                      c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27l-31.24,4.54c-3.98,0.57-7.95,3.4-9.09,7.38
                      c-1.14,3.97-0.57,8.51,2.84,11.35l22.72,23.26c1.13,1.13,1.13,2.27,1.13,3.4l-5.68,33.48c-0.57,4.54,1.14,8.51,4.55,11.35
                      c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32c1.7,1.13,3.41,1.13,5.68,1.13
                      s4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35l-5.68-33.48c0-1.14,0-2.27,1.14-3.41L507.74,436.91z M468.55,444.85
                      c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.38-3.97c-3.41-1.7-7.95-2.84-12.5-2.84c-4.54,0-9.09,1.13-13.06,3.4l-7.38,3.97
                      l1.7-10.21c1.13-8.51-1.14-17.02-7.39-22.7l-7.38-8.51l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                      c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L468.55,444.85z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Customer Support'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M442.28,69.64C397.17,24.58,337.7,0,274.13,0c-63.57,0-124.06,25.6-168.15,69.64c-7.18-3.07-14.35-4.1-22.56-4.1
                      c-22.56,0-41.01,16.39-57.41,31.75c-3.08,2.05-5.13,4.1-8.2,6.14c-10.25,8.19-15.38,22.53-17.43,39.94
                      c-2.05,26.63,5.13,62.47,21.53,100.36c18.46,41.99,46.14,87.04,82.02,128.01C185.96,467.99,272.08,511,322.32,511
                      c14.35,0,25.63-3.07,33.83-10.24c3.08-2.05,6.15-5.12,9.23-7.17c18.45-15.36,38.96-31.75,39.98-57.35v-1.02
                      c31.78-21.51,57.42-49.15,75.87-82.95c19.48-34.82,29.73-74.76,29.73-114.69C510.97,174.09,486.36,114.69,442.28,69.64z
                      M83.43,86.02c12.3-1.02,24.61,8.19,36.91,26.63c16.4,24.58,23.58,41.99,20.51,54.27c-1.03,4.1-3.08,8.19-6.15,12.29
                      c-15.38-28.67-39.99-67.59-59.47-92.16C78.3,86.02,81.38,86.02,83.43,86.02z M353.08,478.23c-3.08,3.07-6.15,5.12-9.23,7.17
                      c-4.1,4.1-12.3,6.14-21.53,6.14c-44.09,0-126.11-40.96-203-131.08c-33.83-39.94-61.52-82.95-78.95-122.89
                      c-14.35-34.82-21.53-66.56-20.51-91.14c1.02-12.29,5.13-22.53,10.25-26.63c3.08-2.05,5.13-5.12,8.2-7.17
                      c5.13-5.12,11.28-10.24,17.43-15.36c20.5,23.55,48.19,67.59,62.54,96.26c-1.02,1.02-3.08,2.05-4.1,3.07
                      c-1.02,1.02-3.08,2.05-4.1,3.07c-13.33,11.26-12.3,30.72,3.08,57.35c9.23,17.41,25.63,38.91,48.19,65.54
                      c45.11,52.23,75.87,76.8,97.4,76.8c6.15,0,12.3-2.05,17.43-6.14c0,0,0,0,1.02,0c26.66,15.36,66.64,48.13,89.2,74.75
                      C362.31,470.04,357.18,474.13,353.08,478.23z M385.89,435.22c0,6.14-2.05,11.26-6.15,16.38c-22.56-26.63-59.47-56.32-86.12-72.71
                      c7.18-6.14,14.35-9.22,22.56-9.22c12.3,0,28.71,9.22,49.21,28.67C379.73,411.67,386.91,423.95,385.89,435.22z M401.27,413.71
                      c-4.1-10.24-12.3-20.48-23.58-30.72c-24.61-23.55-45.11-33.79-62.54-33.79c-19.48,0-32.81,12.29-47.16,24.58
                      c-1.03,1.02-3.08,3.07-5.13,4.1c-1.02,1.02-2.05,1.02-4.1,1.02c-8.2,0-30.76-9.22-82.02-69.63
                      c-21.53-25.6-36.91-46.08-46.14-62.47c-9.23-16.39-11.28-28.67-7.18-32.77c1.02-1.02,3.08-2.05,4.1-3.07
                      c3.08-3.07,7.18-6.14,10.25-8.19c10.25-9.22,18.46-18.43,21.53-31.74c4.1-18.43-3.08-39.94-23.58-69.64
                      c-5.13-7.17-10.25-13.31-15.38-18.43c39.98-39.94,95.35-62.47,152.77-62.47c119.96,0,217.36,97.28,217.36,217.1
                      C491.49,308.24,457.66,372.75,401.27,413.71z M219.79,53.25c-2.05-5.12-7.18-8.19-12.3-6.14c-15.38,5.12-29.73,12.29-43.06,20.48
                      c-5.13,3.07-6.15,9.22-3.08,13.31c2.05,3.07,5.13,4.1,8.2,4.1c2.05,0,4.1-1.02,5.13-2.05c12.3-8.19,24.61-14.34,38.96-18.43
                      C218.77,63.49,221.84,58.37,219.79,53.25z M238.25,58.37c1.02,0,1.02,0,2.05,0h1.02c5.13-1.02,9.23-6.14,8.2-11.26
                      c-1.02-5.12-6.15-9.22-11.28-8.19h-1.02c-5.13,1.02-9.23,6.14-8.2,11.26C229.02,55.3,233.12,58.37,238.25,58.37z M316.17,165.89
                      c-17.43,0-31.78,14.34-31.78,31.75v57.35c0,17.41,14.35,31.74,31.78,31.74c17.43,0,31.78-14.34,31.78-31.74v-57.35
                      C347.95,180.23,333.6,165.89,316.17,165.89z M327.45,254.99c0,6.14-5.13,11.26-11.28,11.26s-11.28-5.12-11.28-11.26v-57.35
                      c0-6.14,5.13-11.26,11.28-11.26s11.28,5.12,11.28,11.26V254.99z M401.27,216.07c-8.2,0-14.35-6.14-14.35-14.34
                      c0-8.19,6.15-14.34,14.35-14.34c5.13,0,10.25,3.07,12.3,7.17c3.08,5.12,9.23,6.14,13.33,4.1c5.13-3.07,6.15-9.22,4.1-13.31
                      c-6.15-10.24-17.43-17.41-29.73-17.41c-19.48,0-34.86,15.36-34.86,34.82c0,19.46,15.38,34.82,34.86,34.82
                      c8.2,0,14.35,6.14,14.35,14.34c0,8.19-6.15,14.34-14.35,14.34c-5.13,0-10.25-3.07-12.3-7.17c-3.08-5.12-9.23-6.14-13.33-4.1
                      c-5.13,3.07-6.15,9.22-4.1,13.31c6.15,10.24,17.43,17.41,29.73,17.41c19.48,0,34.86-15.36,34.86-34.82
                      C436.12,231.43,420.75,216.07,401.27,216.07z M231.07,216.07c-8.2,0-14.35-6.14-14.35-14.34c0-8.19,6.15-14.34,14.35-14.34
                      c5.13,0,10.25,3.07,12.3,7.17c3.08,5.12,9.23,6.14,13.33,4.1c5.13-3.07,6.15-9.22,4.1-13.31c-6.15-10.24-17.43-17.41-29.73-17.41
                      c-19.48,0-34.86,15.36-34.86,34.82c0,19.46,15.38,34.82,34.86,34.82c8.2,0,14.35,6.14,14.35,14.34c0,8.19-6.15,14.34-14.35,14.34
                      c-5.13,0-10.25-3.07-12.3-7.17c-3.08-5.12-9.23-6.14-13.33-4.1c-5.13,3.07-6.15,9.22-4.1,13.31
                      c6.15,10.24,17.43,17.41,29.73,17.41c19.48,0,34.86-15.36,34.86-34.82C264.9,231.43,249.52,216.07,231.07,216.07z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Push Notification'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M416.68,289.43c-51.54,0-93.32,41.78-93.32,93.32c0,71.84,85.33,123.86,88.95,126.04c1.32,0.8,2.83,1.21,4.37,1.21
                      c1.54,0,3.05-0.42,4.37-1.21c3.62-2.18,88.95-54.19,88.95-126.04C510,331.21,468.22,289.43,416.68,289.43z M416.68,491.42
                      c-16.81-11.27-76.35-54.98-76.35-108.67c0.05-42.15,34.2-76.3,76.35-76.35c42.15,0.05,76.3,34.2,76.35,76.35
                      C493.03,436.44,433.5,480.15,416.68,491.42z M416.68,340.33c-23.43,0-42.42,18.99-42.42,42.42c0,23.43,18.99,42.42,42.42,42.42
                      c23.41-0.03,42.39-19,42.42-42.42C459.1,359.32,440.11,340.33,416.68,340.33z M416.68,408.2c-14.06,0-25.45-11.39-25.45-25.45
                      c0-14.06,11.39-25.45,25.45-25.45c14.06,0,25.45,11.39,25.45,25.45C442.13,396.8,430.74,408.2,416.68,408.2z M94.32,145.22
                      C42.78,145.22,1,187,1,238.53c0,71.85,85.33,123.86,88.95,126.04c1.32,0.8,2.83,1.22,4.37,1.21c1.54,0,3.05-0.42,4.37-1.21
                      c3.62-2.18,88.95-54.19,88.95-126.04C187.63,187,145.85,145.22,94.32,145.22z M94.32,347.2c-16.81-11.27-76.35-54.98-76.35-108.67
                      c0.05-42.15,34.2-76.3,76.35-76.35c42.15,0.05,76.3,34.2,76.35,76.35C170.67,292.22,111.13,335.93,94.32,347.2z M94.32,196.12
                      c-23.43,0-42.42,18.99-42.42,42.42c0,23.43,18.99,42.42,42.42,42.42c23.41-0.03,42.39-19,42.42-42.42
                      C136.73,215.11,117.74,196.12,94.32,196.12z M94.32,263.98c-14.06,0-25.45-11.39-25.45-25.45c0-14.06,11.39-25.45,25.45-25.45
                      c14.05,0,25.45,11.39,25.45,25.45C119.77,252.59,108.37,263.98,94.32,263.98z M412.31,220.35c1.32,0.8,2.83,1.21,4.37,1.21
                      c1.54,0,3.05-0.42,4.37-1.21c3.62-2.18,88.95-54.19,88.95-126.04C510,42.78,468.22,1,416.68,1c-51.54,0-93.32,41.78-93.32,93.32
                      C323.37,166.16,408.69,218.17,412.31,220.35z M416.68,17.97c42.15,0.05,76.3,34.2,76.35,76.35c0,53.69-59.54,97.4-76.35,108.67
                      c-16.81-11.27-76.35-54.98-76.35-108.67C340.38,52.17,374.54,18.01,416.68,17.97z M416.68,136.73c23.41-0.03,42.39-19,42.42-42.42
                      c0-23.43-18.99-42.42-42.42-42.42c-23.43,0-42.42,18.99-42.42,42.42C374.27,117.74,393.26,136.73,416.68,136.73z M416.68,68.87
                      c14.06,0,25.45,11.39,25.45,25.45c0,14.06-11.39,25.45-25.45,25.45c-14.06,0-25.45-11.39-25.45-25.45
                      C391.23,80.26,402.63,68.87,416.68,68.87z M179.15,314.88c-4.68,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48
                      c4.69,0,8.48-3.8,8.48-8.48C187.63,318.68,183.84,314.88,179.15,314.88z M204.6,297.92c-4.69,0-8.48,3.8-8.48,8.48
                      c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C213.08,301.71,209.29,297.92,204.6,297.92z M230.05,280.95
                      c-4.68,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48C238.53,284.75,234.74,280.95,230.05,280.95z
                      M255.5,263.98c-4.69,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48
                      C263.98,267.78,260.19,263.98,255.5,263.98z M280.95,247.02c-4.68,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48
                      c4.69,0,8.48-3.8,8.48-8.48C289.43,250.81,285.64,247.02,280.95,247.02z M306.4,230.05c-4.69,0-8.48,3.8-8.48,8.48
                      c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C314.88,233.85,311.09,230.05,306.4,230.05z M331.85,230.05
                      c4.69,0,8.48-3.8,8.48-8.48c0-4.68-3.8-8.48-8.48-8.48c-4.68,0-8.48,3.8-8.48,8.48C323.37,226.25,327.17,230.05,331.85,230.05z
                      M348.82,204.6c0,4.69,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48c0-4.69-3.8-8.48-8.48-8.48
                      C352.62,196.12,348.82,199.91,348.82,204.6z M204.6,331.85c-4.69,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48
                      c4.68,0,8.48-3.8,8.48-8.48C213.08,335.65,209.29,331.85,204.6,331.85z M230.05,348.82c-4.68,0-8.48,3.8-8.48,8.48
                      c0,4.69,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48C238.53,352.61,234.74,348.82,230.05,348.82z M255.5,365.78
                      c-4.69,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C263.98,369.58,260.19,365.78,255.5,365.78z
                      M280.95,382.75c-4.68,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48
                      C289.43,386.55,285.64,382.75,280.95,382.75z M306.4,399.72c-4.69,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48
                      c4.68,0,8.48-3.8,8.48-8.48C314.88,403.51,311.09,399.72,306.4,399.72z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'User Payment Withdraw Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M49.1,280.78H32.59v16.85H49.1V280.78z M49.1,314.48H32.59v50.55H49.1V314.48z M466.02,293.41
                      c-15.93,0-28.9,13.23-28.9,29.49c0,16.26,12.96,29.49,28.9,29.49c15.94,0,28.9-13.23,28.9-29.49
                      C494.92,306.64,481.96,293.41,466.02,293.41z M466.02,335.55c-6.83,0-12.38-5.67-12.38-12.64s5.56-12.64,12.38-12.64
                      c6.83,0,12.38,5.67,12.38,12.64S472.85,335.55,466.02,335.55z M423.27,97.65l-100.23,94.41l-27.66-42.35l-13.74,9.35l38.39,58.76
                      l114.43-107.81L423.27,97.65z M486.67,162.81h-9.58c0.78-5.52,1.32-11.12,1.32-16.85C478.41,80.92,426.56,28,362.83,28
                      c-63.73,0-115.58,52.92-115.58,117.96c0,5.67,0.42,11.3,1.21,16.85H24.33c-13.66,0-24.77,11.34-24.77,25.28v269.63
                      c0,13.94,11.11,25.28,24.77,25.28h462.33c13.66,0,24.77-11.34,24.77-25.28V188.09C511.43,174.16,500.32,162.81,486.67,162.81z
                      M65.61,466.15H24.33c-4.55,0-8.26-3.77-8.26-8.42V188.09c0-4.65,3.71-8.43,8.26-8.43h41.28V466.15z M420.62,466.15H82.12V179.67
                      h169.99c2.8,9.55,6.75,18.74,11.87,27.36l-21.55,60.5l66.86-17.05c16.49,8.8,34.92,13.46,53.53,13.46
                      c21.06,0,40.77-5.87,57.79-15.96V466.15z M362.83,247.07c-16.89,0-33.63-4.46-48.38-12.93l-2.87-1.63l-43.01,10.97l13.74-38.55
                      l-2.28-3.54c-10.64-16.52-16.27-35.68-16.27-55.43c0-55.75,44.44-101.11,99.07-101.11c54.63,0,99.07,45.36,99.07,101.11
                      C461.9,201.72,417.45,247.07,362.83,247.07z M494.92,322.91v134.82c0,4.65-3.71,8.43-8.26,8.43h-49.54V322.91v-86.72
                      c17.04-14.65,29.88-34.2,36.41-56.52h13.13c4.55,0,8.26,3.78,8.26,8.43V322.91z M184.5,247.06c-45.53,0-82.57,37.8-82.57,84.27
                      c0,23.21,9.25,44.25,24.17,59.51c0.03,0.03,0.03,0.09,0.06,0.12c0.18,0.18,0.39,0.29,0.57,0.47c3.59,3.59,7.46,6.88,11.65,9.76
                      c0.48,0.34,1.01,0.6,1.5,0.93c3.99,2.63,8.18,4.96,12.61,6.88c0.53,0.23,1.07,0.38,1.59,0.6c4.49,1.82,9.14,3.31,13.98,4.32
                      c0.3,0.06,0.6,0.08,0.89,0.14c5.03,0.96,10.22,1.53,15.55,1.53c5.33,0,10.52-0.57,15.57-1.55c0.3-0.06,0.6-0.08,0.89-0.14
                      c4.83-1,9.49-2.5,13.98-4.32c0.53-0.21,1.07-0.37,1.59-0.6c4.43-1.9,8.62-4.24,12.61-6.88c0.5-0.32,1.01-0.6,1.5-0.93
                      c4.17-2.88,8.06-6.16,11.65-9.76c0.18-0.17,0.39-0.29,0.57-0.48c0.03-0.03,0.03-0.09,0.06-0.12
                      c14.91-15.24,24.15-36.28,24.15-59.49C267.07,284.86,230.03,247.06,184.5,247.06z M225.08,386.11c-1.58,1.21-3.14,2.45-4.82,3.49
                      c-1.34,0.86-2.79,1.56-4.19,2.33c-19.51,10.22-43.61,10.22-63.13,0c-1.41-0.76-2.85-1.46-4.19-2.33
                      c-1.68-1.04-3.24-2.28-4.82-3.49c-1.19-0.92-2.31-1.9-3.44-2.9c7.94-17.47,25.01-28.9,44-28.9c18.99,0,36.07,11.43,44.02,28.9
                      C227.39,384.21,226.26,385.19,225.08,386.11z M161.98,316.01c0-12.67,10.1-22.98,22.52-22.98c12.41,0,22.52,10.31,22.52,22.98
                      c0,12.67-10.1,22.98-22.52,22.98C172.08,338.99,161.98,328.68,161.98,316.01z M239.23,371.41c-6.94-12.15-17.43-21.51-29.83-26.97
                      c7.69-7.03,12.63-17.11,12.63-28.44c0-21.11-16.84-38.3-37.53-38.3c-20.69,0-37.53,17.19-37.53,38.3
                      c0,11.32,4.94,21.4,12.63,28.42c-12.4,5.45-22.89,14.82-29.83,26.97c-7.99-11.31-12.82-25.08-12.82-40.07
                      c0-38.01,30.31-68.95,67.56-68.95s67.55,30.93,67.55,68.95C252.05,346.32,247.22,360.09,239.23,371.41z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Document Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M484.58,0L484.58,0H133.15c-14.82,0.06-26.79,12.12-26.73,26.93c0,0.07,0,0.14,0,0.21v185.77H21.22
                      c0-17.64,14.3-31.94,31.95-31.94h31.95v-21.29H53.17c-29.41,0-53.25,23.83-53.25,53.23v266.15c0,17.64,14.3,31.94,31.95,31.94
                      h404.67c17.64,0,31.95-14.3,31.95-31.94V244.85c0-17.64-14.3-31.94-31.95-31.94H127.72V117.1h223.63V95.81H127.72V74.52h362.07
                      v175.12c0.18,3.05-2.15,5.67-5.2,5.85c0,0-0.01,0-0.02,0h-5.43v21.29h5.43c14.82-0.18,26.69-12.33,26.52-27.14v0V27.15
                      C511.27,12.33,499.4,0.18,484.58,0z M447.19,425.83h-63.9c-5.88,0-10.65-4.77-10.65-10.64v-31.94c0-5.88,4.77-10.65,10.65-10.65
                      h63.9V425.83z M436.54,234.21c5.88,0,10.65,4.77,10.65,10.65v106.46h-63.9c-17.64,0-31.95,14.3-31.95,31.94v31.94
                      c0,17.64,14.3,31.94,31.95,31.94h63.9v31.94c0,5.88-4.77,10.64-10.65,10.64H31.87c-5.88,0-10.65-4.77-10.65-10.64V234.21H436.54z
                      M127.72,53.23V27.15c-0.18-3.05,2.15-5.67,5.2-5.85c0.01,0,0.01,0,0.02,0h351.42c3.05,0.17,5.39,2.78,5.22,5.84
                      c0,0,0,0.01,0,0.02l0.21,26.08H127.72z M53.17,468.42h42.6v-21.29H63.82v-31.94h-21.3v42.58
                      C42.52,463.65,47.29,468.42,53.17,468.42z M138.37,447.13h-21.3v21.29h21.3V447.13z M457.84,117.1V95.81h-21.3v21.29H457.84z
                      M404.6,117.1V95.81h-21.3v21.29H404.6z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Players Point Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <path d="M277.19,495.04l-4.14-5.17H158.29H43.52l-4.14-2.07c-6.2-3.1-10.34-8.27-12.41-15.51c-1.03-4.14-1.03-428.09,0-432.23
                  c1.03-6.2,6.2-12.41,12.41-15.51l3.1-2.07h174.74h174.74l4.14,2.07c6.2,3.1,10.34,8.27,12.41,15.51c0,2.07,1.03,38.26,1.03,106.51
                  v104.44h4.14c2.07,0,7.24,1.03,10.34,1.03c3.1,1.03,6.2,1.03,6.2,1.03c1.03,0,1.03-15.51,1.03-103.4c0-64.11,0-105.47-1.03-109.61
                  c0-3.1-1.03-7.24-2.07-9.31c-5.17-15.51-17.58-26.88-34.12-29.99c-6.2-1.03-346.38-1.03-352.58,0C31.11,2.84,20.77,9.05,14.57,18.35
                  C8.36,25.59,6.3,31.8,5.26,41.1c0,4.14,0,78.59,0,219.22v213.01l1.03,4.14c4.14,14.48,14.47,25.85,27.92,31.02
                  c2.07,1.03,6.2,2.07,8.27,2.07c2.07,0,41.36,0,126.14,0h122.01l-5.17-5.17C283.4,502.28,279.26,498.15,277.19,495.04z M310.28,141.4
                  v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V141.4z M310.28,206.55v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V206.55z
                  M307.18,279.96l3.1-2.07v-8.27v-8.27h-98.23h-98.23v10.34v10.34h95.12h95.12L307.18,279.96z M503.63,377.16
                  c-7.24-31.02-28.95-57.91-57.9-72.38c-18.61-9.31-39.29-12.41-59.97-10.34c-6.2,1.03-16.54,3.1-22.75,5.17
                  c-7.24,2.07-19.64,8.27-25.85,12.41c-22.75,15.51-39.29,38.26-45.49,63.08c-2.07,9.31-3.1,16.54-3.1,26.89
                  c0,17.58,3.1,32.05,10.34,47.57c19.65,40.33,63.07,65.14,108.57,61.01c46.53-4.14,85.82-38.26,96.16-83.76
                  C507.77,412.32,507.77,393.71,503.63,377.16z M480.88,429.9c-10.34,29.99-35.15,52.74-67.21,57.91c-8.27,1.03-24.82,1.03-32.05,0
                  c-33.09-7.24-58.94-31.02-67.21-63.08c-2.07-8.27-3.1-13.44-3.1-22.75c0-9.31,1.03-14.48,3.1-22.75
                  c6.2-23.78,22.75-44.46,45.49-55.84c8.27-4.14,14.48-6.2,24.82-8.27c7.24-1.03,24.82-1.03,32.05,1.03
                  c8.27,2.07,13.44,4.14,21.71,7.24c19.64,9.31,34.12,25.85,41.36,45.5C486.05,389.57,487.09,410.25,480.88,429.9z M114.86,326.5
                  v10.34v10.34h70.31h70.31l2.07-5.17c1.03-3.1,3.1-8.27,5.17-10.34l3.1-5.17h-75.48H114.86z M408.51,391.64v-21.71v-21.71h-11.37
                  h-11.37v33.09v33.09h33.09h33.09v-10.34v-10.34h-21.71h-21.71V391.64z"></path>
                </svg>`,
					title: 'Withdraw Request Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M491.34,252.43c-16.4-13.29-40.99-11.24-56.36,4.09l-59.44,59.28c-5.12-3.07-12.3-5.11-18.45-5.11
                      c-1.02,0-29.72-1.02-35.87-1.02c-5.12,0-9.22,0-14.35,0c-24.6,0-50.22,1.02-72.76-5.11c-5.13-1.02-10.25-3.07-15.37-4.09
                      c-24.6-7.15-48.17-14.31-77.88-4.09c-25.62,9.2-51.24,26.57-77.89,54.17l-51.24,45.99c-8.2,7.15-8.2,19.42-2.05,26.57l69.69,80.74
                      c3.07,5.11,8.2,7.15,14.35,7.15c4.1,0,8.2-1.02,11.27-4.09l65.25-49.06h163.28c4.1,0,8.2,0,12.3,0c3.07,0,12.3-8.18,14.35-11.24
                      l134.25-133.88c8.2-8.18,12.3-19.42,12.3-30.66C505.69,270.83,500.57,259.59,491.34,252.43z M170.94,457.33l0.66-0.5
                      C172.63,457.04,172.21,457.2,170.94,457.33z M482.12,300.47L347.87,433.33c-4.1,4.09-15.37,6.13-15.37,6.13h-3.07
                      c0,0-165-1.02-167.04,0L92.69,492.6c-1.02,1.02-2.05,0-2.05,0l-69.69-80.74c1.02,0,1.02-1.02,2.05-2.04l51.24-45.99
                      c25.62-26.57,48.17-41.9,71.74-50.08c24.6-8.18,43.04-3.07,67.64,4.09c5.12,1.02,10.25,3.07,15.37,4.09
                      c25.62,6.13,53.29,6.13,77.89,5.11c5.12,0,9.22,0,14.35,0c5.12,0,25.62,1.02,35.87,1.02c5.12,0,10.25,2.04,14.35,6.13
                      c4.1,4.09,6.15,8.18,6.15,14.31c0,11.24-9.22,19.42-19.47,19.42c0,0-129.13,0-130.15,0c-5.12,0-10.25,5.11-10.25,10.22
                      c1.02,7.15,10.25,7.15,16.4,7.15h124c20.49,0,36.89-16.35,36.89-36.79c0-7.15-2.05-14.31-6.15-20.44l59.44-59.28
                      c9.22-9.2,23.57-10.22,32.79-3.07c5.12,4.09,8.2,10.22,8.2,17.37C489.29,289.23,487.25,296.38,482.12,300.47z M319.18,199.29
                      c30.75,0,56.36-25.55,56.36-56.21c0-30.66-25.62-56.21-56.36-56.21c-30.74,0-56.37,25.55-56.37,56.21
                      C262.81,173.74,288.43,199.29,319.18,199.29z M319.18,105.27c20.5,0,37.92,17.37,37.92,37.81c0,21.46-17.42,37.81-37.92,37.81
                      c-21.52,0-37.92-17.37-37.92-37.81C281.26,122.64,298.68,105.27,319.18,105.27z M200.3,121.62l5.12,3.07
                      c-1.03,6.13-1.03,12.26-1.03,17.37c0,6.13,0,12.26,1.03,17.37l-3.07,4.09c-15.37,9.2-20.5,28.62-12.3,43.95l8.2,13.29
                      c6.15,10.22,16.4,16.35,28.7,16.35c6.15,0,11.27-1.02,16.4-4.09l5.13-3.07c9.22,7.15,19.47,13.29,30.74,17.37v5.11
                      c0,18.4,14.35,32.7,32.79,32.7h15.37c18.45,0,32.79-14.31,32.79-32.7v-5.11c11.27-4.09,21.52-10.22,30.74-17.37l5.12,3.07
                      c5.12,3.07,10.25,4.09,16.4,4.09c11.27,0,22.55-6.13,28.7-16.35l8.2-13.29c9.22-15.33,4.1-35.77-12.3-43.95l-7.17-3.07
                      c1.02-6.13,1.02-12.26,1.02-17.37c0-6.13,0-12.26-1.02-17.37l5.12-3.07c15.37-9.2,21.52-28.62,12.3-43.95l-8.2-13.29
                      c-6.15-10.22-16.4-16.35-28.69-16.35c-6.15,0-11.27,1.02-16.4,4.09l-5.12,3.07c-9.22-7.15-19.47-13.29-30.74-17.37V32.7
                      c0-18.4-14.35-32.7-32.79-32.7h-15.37c-18.45,0-32.79,14.31-32.79,32.7v5.11c-11.27,4.09-21.52,10.22-30.74,17.37l-5.12-3.07
                      c-5.12-3.07-10.25-4.09-16.4-4.09c-11.27,0-22.55,6.13-28.69,16.35L188,77.67C178.78,93,183.9,113.44,200.3,121.62z M205.42,86.87
                      l8.2-13.29c3.07-5.11,7.17-7.15,12.3-7.15c2.05,0,5.12,1.02,7.17,2.04l6.15,3.07c3.07,2.04,6.15,2.04,9.22,2.04
                      c4.1,0,8.2-1.02,11.27-4.09c8.2-6.13,16.4-11.24,26.64-15.33c6.15-2.04,10.25-8.18,10.25-15.33V32.7
                      c0-8.18,6.15-14.31,14.35-14.31h15.37c8.2,0,14.35,6.13,14.35,14.31v6.13c0,7.15,4.1,14.31,11.27,16.35
                      c9.22,4.09,18.45,9.2,26.64,15.33c3.07,3.07,7.17,4.09,11.27,4.09c3.07,0,6.15-1.02,9.22-2.04l5.12-3.07
                      c2.05-1.02,5.12-2.04,7.17-2.04c5.12,0,10.25,3.07,12.3,7.15l8.2,13.29c3.07,6.13,1.02,15.33-6.15,19.42l-5.13,3.07
                      c-6.15,4.09-10.25,11.24-8.2,18.4c1.03,5.11,1.03,10.22,1.03,15.33s0,10.22-1.03,15.33c-1.02,7.15,2.05,14.31,8.2,18.4l5.13,3.07
                      c7.17,4.09,9.22,13.29,5.12,19.42l-8.2,13.29c-3.07,5.11-7.17,7.15-12.3,7.15c-2.05,0-5.12-1.02-7.17-2.04l-4.1-4.09
                      c-3.07-2.04-6.15-2.04-9.22-2.04c-4.1,0-8.2,1.02-11.27,4.09c-8.2,6.13-16.4,11.24-26.64,15.33c-7.17,3.07-11.27,9.2-11.27,16.35
                      v6.13c0,8.18-6.15,14.31-14.35,14.31h-15.37c-8.2,0-14.35-6.13-14.35-14.31v-6.13c0-7.15-4.1-14.31-11.27-16.35
                      c-9.22-4.09-18.45-9.2-26.64-15.33c-3.07-3.07-7.17-4.09-11.27-4.09c-3.07,0-6.15,1.02-9.22,2.04l-5.12,3.07
                      c-2.05,1.02-5.12,2.04-7.17,2.04c-5.12,0-10.25-3.07-12.3-7.15l-8.2-13.29c-4.1-7.15-2.05-15.33,5.12-19.42l5.12-3.07
                      c6.15-4.09,10.25-11.24,8.2-18.4c-1.02-5.11-1.02-10.22-1.02-15.33c0-5.11,0-10.22,1.02-15.33c1.02-7.15-2.05-14.31-8.2-18.4
                      l-5.12-3.07C203.37,102.2,201.32,93,205.42,86.87z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Salary Cap Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M491.34,252.43c-16.4-13.29-40.99-11.24-56.36,4.09l-59.44,59.28c-5.12-3.07-12.3-5.11-18.45-5.11
                      c-1.02,0-29.72-1.02-35.87-1.02c-5.12,0-9.22,0-14.35,0c-24.6,0-50.22,1.02-72.76-5.11c-5.13-1.02-10.25-3.07-15.37-4.09
                      c-24.6-7.15-48.17-14.31-77.88-4.09c-25.62,9.2-51.24,26.57-77.89,54.17l-51.24,45.99c-8.2,7.15-8.2,19.42-2.05,26.57l69.69,80.74
                      c3.07,5.11,8.2,7.15,14.35,7.15c4.1,0,8.2-1.02,11.27-4.09l65.25-49.06h163.28c4.1,0,8.2,0,12.3,0c3.07,0,12.3-8.18,14.35-11.24
                      l134.25-133.88c8.2-8.18,12.3-19.42,12.3-30.66C505.69,270.83,500.57,259.59,491.34,252.43z M170.94,457.33l0.66-0.5
                      C172.63,457.04,172.21,457.2,170.94,457.33z M482.12,300.47L347.87,433.33c-4.1,4.09-15.37,6.13-15.37,6.13h-3.07
                      c0,0-165-1.02-167.04,0L92.69,492.6c-1.02,1.02-2.05,0-2.05,0l-69.69-80.74c1.02,0,1.02-1.02,2.05-2.04l51.24-45.99
                      c25.62-26.57,48.17-41.9,71.74-50.08c24.6-8.18,43.04-3.07,67.64,4.09c5.12,1.02,10.25,3.07,15.37,4.09
                      c25.62,6.13,53.29,6.13,77.89,5.11c5.12,0,9.22,0,14.35,0c5.12,0,25.62,1.02,35.87,1.02c5.12,0,10.25,2.04,14.35,6.13
                      c4.1,4.09,6.15,8.18,6.15,14.31c0,11.24-9.22,19.42-19.47,19.42c0,0-129.13,0-130.15,0c-5.12,0-10.25,5.11-10.25,10.22
                      c1.02,7.15,10.25,7.15,16.4,7.15h124c20.49,0,36.89-16.35,36.89-36.79c0-7.15-2.05-14.31-6.15-20.44l59.44-59.28
                      c9.22-9.2,23.57-10.22,32.79-3.07c5.12,4.09,8.2,10.22,8.2,17.37C489.29,289.23,487.25,296.38,482.12,300.47z M319.18,199.29
                      c30.75,0,56.36-25.55,56.36-56.21c0-30.66-25.62-56.21-56.36-56.21c-30.74,0-56.37,25.55-56.37,56.21
                      C262.81,173.74,288.43,199.29,319.18,199.29z M319.18,105.27c20.5,0,37.92,17.37,37.92,37.81c0,21.46-17.42,37.81-37.92,37.81
                      c-21.52,0-37.92-17.37-37.92-37.81C281.26,122.64,298.68,105.27,319.18,105.27z M200.3,121.62l5.12,3.07
                      c-1.03,6.13-1.03,12.26-1.03,17.37c0,6.13,0,12.26,1.03,17.37l-3.07,4.09c-15.37,9.2-20.5,28.62-12.3,43.95l8.2,13.29
                      c6.15,10.22,16.4,16.35,28.7,16.35c6.15,0,11.27-1.02,16.4-4.09l5.13-3.07c9.22,7.15,19.47,13.29,30.74,17.37v5.11
                      c0,18.4,14.35,32.7,32.79,32.7h15.37c18.45,0,32.79-14.31,32.79-32.7v-5.11c11.27-4.09,21.52-10.22,30.74-17.37l5.12,3.07
                      c5.12,3.07,10.25,4.09,16.4,4.09c11.27,0,22.55-6.13,28.7-16.35l8.2-13.29c9.22-15.33,4.1-35.77-12.3-43.95l-7.17-3.07
                      c1.02-6.13,1.02-12.26,1.02-17.37c0-6.13,0-12.26-1.02-17.37l5.12-3.07c15.37-9.2,21.52-28.62,12.3-43.95l-8.2-13.29
                      c-6.15-10.22-16.4-16.35-28.69-16.35c-6.15,0-11.27,1.02-16.4,4.09l-5.12,3.07c-9.22-7.15-19.47-13.29-30.74-17.37V32.7
                      c0-18.4-14.35-32.7-32.79-32.7h-15.37c-18.45,0-32.79,14.31-32.79,32.7v5.11c-11.27,4.09-21.52,10.22-30.74,17.37l-5.12-3.07
                      c-5.12-3.07-10.25-4.09-16.4-4.09c-11.27,0-22.55,6.13-28.69,16.35L188,77.67C178.78,93,183.9,113.44,200.3,121.62z M205.42,86.87
                      l8.2-13.29c3.07-5.11,7.17-7.15,12.3-7.15c2.05,0,5.12,1.02,7.17,2.04l6.15,3.07c3.07,2.04,6.15,2.04,9.22,2.04
                      c4.1,0,8.2-1.02,11.27-4.09c8.2-6.13,16.4-11.24,26.64-15.33c6.15-2.04,10.25-8.18,10.25-15.33V32.7
                      c0-8.18,6.15-14.31,14.35-14.31h15.37c8.2,0,14.35,6.13,14.35,14.31v6.13c0,7.15,4.1,14.31,11.27,16.35
                      c9.22,4.09,18.45,9.2,26.64,15.33c3.07,3.07,7.17,4.09,11.27,4.09c3.07,0,6.15-1.02,9.22-2.04l5.12-3.07
                      c2.05-1.02,5.12-2.04,7.17-2.04c5.12,0,10.25,3.07,12.3,7.15l8.2,13.29c3.07,6.13,1.02,15.33-6.15,19.42l-5.13,3.07
                      c-6.15,4.09-10.25,11.24-8.2,18.4c1.03,5.11,1.03,10.22,1.03,15.33s0,10.22-1.03,15.33c-1.02,7.15,2.05,14.31,8.2,18.4l5.13,3.07
                      c7.17,4.09,9.22,13.29,5.12,19.42l-8.2,13.29c-3.07,5.11-7.17,7.15-12.3,7.15c-2.05,0-5.12-1.02-7.17-2.04l-4.1-4.09
                      c-3.07-2.04-6.15-2.04-9.22-2.04c-4.1,0-8.2,1.02-11.27,4.09c-8.2,6.13-16.4,11.24-26.64,15.33c-7.17,3.07-11.27,9.2-11.27,16.35
                      v6.13c0,8.18-6.15,14.31-14.35,14.31h-15.37c-8.2,0-14.35-6.13-14.35-14.31v-6.13c0-7.15-4.1-14.31-11.27-16.35
                      c-9.22-4.09-18.45-9.2-26.64-15.33c-3.07-3.07-7.17-4.09-11.27-4.09c-3.07,0-6.15,1.02-9.22,2.04l-5.12,3.07
                      c-2.05,1.02-5.12,2.04-7.17,2.04c-5.12,0-10.25-3.07-12.3-7.15l-8.2-13.29c-4.1-7.15-2.05-15.33,5.12-19.42l5.12-3.07
                      c6.15-4.09,10.25-11.24,8.2-18.4c-1.02-5.11-1.02-10.22-1.02-15.33c0-5.11,0-10.22,1.02-15.33c1.02-7.15-2.05-14.31-8.2-18.4
                      l-5.12-3.07C203.37,102.2,201.32,93,205.42,86.87z"></path>
                    </g>
                  </g>
                </svg>`,
					title: 'Region Restriction'
				}
			]
		};
	}
};
</script>

<style scoped>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li a {
	border: 1px solid transparent;
	background-color: #fff;
	padding: 17px 10px;
}
.car-wash-feature-tab li a {
	display: block;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 2px solid #e8e8e8;
}
a {
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	color: #fff;
	cursor: pointer;
}
a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}
.car-wash-feature-tab li {
	min-width: 180px;
}

li {
	display: inline;
}

.car-wash-feature-tab {
	border-bottom: 0;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.nav-tabs {
	border-bottom: 1px solid #dee2e6;
}
.nav {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border-bottom: 2px solid #0082dd;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border: 1px solid #0082dd;
}
</style>