<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">Why Genixbit is Best Mobile Game Development Company?</h2>
				<p>
					The modern clients are smart and always run comparative analysis before placing a stamp of
					choice on a game app developer to establish long-term relationships. Our following
					differentiators are always tempting game entrepreneurs and businesses to leverage our
					expertise and experiences.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<div class="game-why-us-inner-content-wrap">
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											d="M41.2,20.8v2.7c0,1.7-2.5,2.7-5.4,2.7s-5.4-1.1-5.4-2.7h1.4c0,0.6,1.8,1.4,4.1,1.4s4.1-0.8,4.1-1.4v-0.9
                        c-1,0.6-2.5,0.9-4.1,0.9c-3,0-5.4-1.1-5.4-2.7s2.5-2.7,5.4-2.7S41.2,19.2,41.2,20.8L41.2,20.8z M35.8,22.2c2.3,0,4.1-0.8,4.1-1.4
                        c0-0.6-1.8-1.4-4.1-1.4s-4.1,0.8-4.1,1.4C31.7,21.4,33.5,22.2,35.8,22.2z"
										></path>
										<polygon points="30.4,20.8 31.7,20.8 31.7,23.6 30.4,23.6  "></polygon>
										<path
											d="M30.4,15.9c-1,0.6-2.5,0.9-4.1,0.9s-3.1-0.3-4.1-0.9v0.9c0,0.6,1.8,1.4,4.1,1.4s4.1-0.8,4.1-1.4V15.9z M31.7,14v8.1
                        c0,1.2-1.3,2.1-3.1,2.5l-7.4-1.5c-0.2-0.3-0.3-0.6-0.3-1V14c0-1.7,2.5-2.7,5.4-2.7S31.7,12.4,31.7,14z M30.4,22.2v-0.6
                        c-1,0.4-2.4,0.6-4.1,0.6s-3.1-0.2-4.1-0.6v0.6c0,0.3,0.4,0.6,1.1,0.9c0.7,0.3,1.8,0.5,2.9,0.5c0.2,0,0.4,0,0.5,0
                        C28.9,23.4,30.4,22.7,30.4,22.2z M30.4,19.8v-1.2c-1,0.6-2.5,0.9-4.1,0.9s-3.1-0.3-4.1-0.9v1.2c0,0.5,1.6,1,4.1,1
                        S30.4,20.3,30.4,19.8z M26.3,15.4c2.3,0,4.1-0.8,4.1-1.4c0-0.6-1.8-1.4-4.1-1.4s-4.1,0.8-4.1,1.4C22.2,14.6,24,15.4,26.3,15.4z"
										></path>
										<path
											d="M45.9,22.6c1.4-0.9,2.9-0.7,3.7,0.3c0.7,1,0.6,2.4-0.4,3.3c-0.6,0.5-5.6,3-15.9,8l-0.2,0.1l-12.7,1.4l-0.2,0l-9.4-1.2l0-11
                        l5.6-2l0.2,0c10.4,1.8,16.9,3,19.5,3.6c0.7,0.2,1.1,0.6,1.2,1.1C41.5,24.8,44.4,23.6,45.9,22.6z M35.7,29.2l-0.2,0.2l-0.3,0
                        C28.7,28.5,25,28,24.9,28v-1.4c0.3,0,3.4,0.4,10.1,1.3c0.6-0.6,0.9-1.1,1-1.4c0-0.1,0,0-0.2-0.1c-2.5-0.6-9-1.8-19.3-3.6l-4.4,1.6
                        l0,8.9l8.1,1L32.8,33c9.7-4.6,15-7.3,15.4-7.7c0.5-0.4,0.5-1.1,0.2-1.5c-0.4-0.5-1-0.6-1.8,0c-1.7,1.1-4.9,2.5-9.8,4.1
                        C36.6,28.3,36.2,28.7,35.7,29.2z"
										></path>
										<path
											d="M2.2,23.7c-0.4,0.1-0.6,0.4-0.6,0.8l2.1,12.8c0.1,0.3,0.3,0.6,0.7,0.6h5.7c0.4,0,0.7-0.3,0.7-0.7V23.5c0,0,0-0.1,0-0.1
                        c0-0.4-0.4-0.6-0.7-0.6L2.2,23.7C2.2,23.7,2.2,23.7,2.2,23.7z M9.9,21.5c1.1-0.1,2.1,0.7,2.2,1.8c0,0.1,0,0.1,0,0.2v13.6
                        c0,1.1-0.9,2-2,2H4.4c-1,0-1.9-0.7-2-1.7L0.3,24.7c-0.2-1.1,0.6-2.2,1.7-2.3c0,0,0,0,0.1,0L9.9,21.5z"
										></path>
										<path
											d="M2.2,23.7c-0.4,0.1-0.6,0.4-0.6,0.8l2.1,12.8c0.1,0.3,0.3,0.6,0.7,0.6h5.7c0.4,0,0.7-0.3,0.7-0.7V23.5c0,0,0-0.1,0-0.1
                        c0-0.4-0.4-0.6-0.7-0.6L2.2,23.7C2.2,23.7,2.2,23.7,2.2,23.7z M9.9,21.5c1.1-0.1,2.1,0.7,2.2,1.8c0,0.1,0,0.1,0,0.2v13.6
                        c0,1.1-0.9,2-2,2H4.4c-1,0-1.9-0.7-2-1.7L0.3,24.7c-0.2-1.1,0.6-2.2,1.7-2.3c0,0,0,0,0.1,0L9.9,21.5z"
										></path>
										<circle cx="5.9" cy="34.4" r="1.4"></circle>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content">
								<h3>Successful <br />Monetization</h3>
								<p>
									Our effective monetization strategy wins the hearts of clients approach us and
									game players play it. Thus, we always keep a balance between two ends beautifully.
								</p>
							</div>
						</div>

						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										d="M49.2,41.7H0.8c-0.4,0-0.8,0.4-0.8,0.8s0.3,0.8,0.8,0.8h48.4c0.4,0,0.8-0.4,0.8-0.8S49.7,41.7,49.2,41.7z"
									></path>
									<path
										d="M48.1,29.5L48.1,29.5L48.1,29.5c-1.2-3-3-5.7-5.3-8c-0.1-0.1-0.1-0.2-0.2-0.2c-2.3-2.3-5-4.1-8-5.4l0,0h-0.1
                      c-6.1-2.5-13-2.5-19.1,0.1l0,0c-3,1.2-5.7,3.1-8,5.3c-0.1,0.1-0.2,0.1-0.2,0.2c-2.3,2.3-4.1,5.1-5.3,8.1l0,0c0,0,0,0,0,0.1
                      c-1.2,3-1.9,6.3-1.9,9.5c0,0.1,0,0.1,0,0.2c0,0.4,0.3,0.8,0.8,0.8h3.9c0.4,0,0.8-0.4,0.8-0.8s-0.3-0.8-0.8-0.8H1.6
                      C1.6,36,2.1,33.4,3,30.9L5.6,32c0.4,0.2,0.9,0.1,1.1-0.3c0.2-0.4,0.1-0.9-0.3-1.1c-0.1,0-0.1-0.1-0.2-0.1l-2.6-1.1
                      c1.1-2.4,2.5-4.5,4.3-6.4l2,2c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l0,0l-2-2c1.8-1.7,4-3.2,6.2-4.2l1.1,2.6
                      c0.2,0.4,0.6,0.6,1,0.5c0.4-0.2,0.6-0.6,0.4-1c0,0,0,0,0,0l-1.1-2.6c2.4-0.9,5-1.4,7.5-1.5v2.8c0,0.4,0.3,0.8,0.8,0.8
                      s0.8-0.4,0.8-0.8v-2.8c2.5,0.1,5,0.6,7.4,1.5l-1.1,2.6c-0.1,0.1-0.1,0.3,0,0.5c0.4,0.1,0.8,0.3,1.1,0.5c0.2-0.1,0.3-0.2,0.4-0.4
                      l1.1-2.6c2.3,1.1,4.5,2.5,6.4,4.3l-2,2c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0l2-2c1.7,1.9,3.1,4,4.2,6.3l-2.6,1.1
                      c-0.4,0.2-0.6,0.6-0.4,1c0.2,0.4,0.6,0.6,1,0.5c0,0,0,0,0,0l2.6-1.1c0.9,2.5,1.5,5.1,1.5,7.8h-2.3c-0.4,0-0.8,0.4-0.8,0.8
                      s0.3,0.8,0.8,0.8h3.1c0.4,0,0.8-0.4,0.8-0.8c0-0.1,0-0.1,0-0.2C50,35.9,49.3,32.6,48.1,29.5z"
									></path>
									<path
										d="M42.2,40.2c0.4,0,0.8-0.4,0.8-0.8c0-6.5-3.5-12.6-9.2-15.8l-0.3,1.6c4.9,3,7.9,8.4,7.9,14.1C41.4,39.8,41.8,40.2,42.2,40.2z
                      "
									></path>
									<path
										d="M7,39.4c0,0.4,0.3,0.8,0.8,0.8s0.8-0.4,0.8-0.8c0-9.1,7.3-16.5,16.4-16.5c1.1,0,2.2,0.1,3.2,0.3l1-1.4
                      c-9.6-2.4-19.4,3.6-21.7,13.3C7.2,36.5,7,37.9,7,39.4z"
									></path>
									<path
										d="M29.9,36.7l2.5-14.1c0.1-0.4-0.2-0.8-0.6-0.9c-0.3-0.1-0.6,0.1-0.8,0.3l-8.6,11.8c-0.2,0.2-0.2,0.5-0.1,0.8l0.8,1.8
                      c-0.5,0.5-0.9,1.1-0.9,1.8H12.1c-0.4,0-0.8,0.4-0.8,0.8s0.3,0.8,0.8,0.8h25.8c0.4,0,0.8-0.4,0.8-0.8s-0.3-0.8-0.8-0.8H27.7
                      c0-0.1,0-0.2,0-0.3l1.7-0.7C29.7,37.2,29.8,37,29.9,36.7z M23.8,38.2c0.1-0.6,0.7-1.1,1.4-0.9c0.5,0.1,0.9,0.5,0.9,0.9H23.8z
                      M27,36.5c-0.6-0.7-1.5-1-2.4-0.9L24,34.3l6.3-8.7L28.4,36L27,36.5z"
									></path>
									<path
										d="M10.9,7.4c-0.1-0.3-0.4-0.6-0.7-0.6h-3L6.2,4.1c-0.1-0.4-0.6-0.6-1-0.5C5,3.7,4.8,3.9,4.7,4.1L3.8,6.9h-3
                      C0.4,6.9,0,7.2,0,7.7c0,0.3,0.1,0.5,0.3,0.6L2.7,10l-0.9,2.8c-0.1,0.4,0.1,0.9,0.5,1c0.2,0.1,0.5,0,0.7-0.1l2.5-1.7l2.4,1.7
                      c0.4,0.2,0.8,0.1,1.1-0.2c0.1-0.2,0.2-0.4,0.1-0.7L8.2,10l2.4-1.7C10.9,8.1,11,7.7,10.9,7.4z M6.5,10l0.3,1l-0.9-0.7
                      c-0.3-0.2-0.6-0.2-0.9,0L4.1,11l0.3-1c0.1-0.3,0-0.7-0.3-0.9L3.3,8.4h1.1c0.3,0,0.6-0.2,0.7-0.5l0.4-1.1l0.4,1.1
                      c0.1,0.3,0.4,0.5,0.7,0.5h1.1L6.8,9.1C6.5,9.3,6.4,9.6,6.5,10z"
									></path>
									<path
										d="M50,7.4c-0.1-0.3-0.4-0.6-0.7-0.6h-3l-0.9-2.8c-0.1-0.4-0.6-0.6-1-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-0.9,2.8h-3
                      c-0.4,0-0.8,0.4-0.8,0.8c0,0.3,0.1,0.5,0.3,0.6l2.4,1.7l-0.9,2.8c-0.1,0.4,0.1,0.9,0.5,1c0.2,0.1,0.5,0,0.7-0.1l2.5-1.7l2.4,1.7
                      c0.4,0.2,0.8,0.1,1.1-0.2c0.1-0.2,0.2-0.4,0.1-0.7L47.2,10l2.4-1.7C49.9,8.1,50.1,7.7,50,7.4z M45.6,9.9l0.3,1L45,10.3
                      c-0.3-0.2-0.6-0.2-0.9,0L43.1,11l0.3-1c0.1-0.3,0-0.7-0.3-0.9l-0.9-0.6h1.1c0.3,0,0.6-0.2,0.7-0.5l0.4-1.1l0.4,1.1
                      c0.1,0.3,0.4,0.5,0.7,0.5h1.1l-0.9,0.6C45.6,9.2,45.5,9.6,45.6,9.9L45.6,9.9z"
									></path>
									<path
										d="M29.7,7.2c-0.1-0.3-0.4-0.6-0.7-0.6h-2.4l-0.7-2.2c-0.1-0.4-0.6-0.6-1-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-0.7,2.2h-2.4
                      c-0.4,0-0.8,0.4-0.8,0.8c0,0.2,0.1,0.5,0.3,0.6l1.9,1.3l-0.7,2.1c-0.1,0.4,0.1,0.9,0.5,1c0.2,0.1,0.5,0,0.7-0.1l2-1.4l2,1.4
                      c0.4,0.2,0.8,0.1,1.1-0.2c0.1-0.2,0.2-0.5,0.1-0.7l-0.7-2.1l1.9-1.3C29.6,7.9,29.8,7.5,29.7,7.2z M26.1,8.5
                      c-0.3,0.2-0.4,0.6-0.3,0.9l0.2,0.5l-0.5-0.3c-0.3-0.2-0.6-0.2-0.9,0l-0.5,0.3l0.2-0.5c0.1-0.3,0-0.7-0.3-0.9l-0.3-0.2h0.5
                      c0.3,0,0.6-0.2,0.7-0.6L25,7.2l0.2,0.5c0.1,0.3,0.4,0.5,0.7,0.5h0.5L26.1,8.5z"
									></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content">
								<h3>Performance-oriented <br />Games</h3>
								<p>
									We remove distractions in game development process like slack, crash, error, and
									frequent downloading requirements right before happening.
								</p>
							</div>
						</div>

						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M10.1,43.9c-0.5,0-1,0.4-1,1v4.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-4.3C11.1,44.3,10.7,43.9,10.1,43.9z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M45.4,25.3h-1.8c0.5-0.7,0.8-1.6,0.8-2.6v-0.6c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5v0.6c0,0.9,0.3,1.7,0.7,2.4
                          c-3.6-0.7-6.4-3.9-6.4-7.7V17c0-0.8-0.3-1.5-0.9-2c-0.6-0.5-1.3-0.8-2.1-0.8c-0.3,0-0.5,0.1-0.8,0.1v-5l10.1-3.7
                          c0.4-0.1,0.6-0.5,0.6-0.9c0-0.4-0.3-0.8-0.6-0.9L25.3,0c-0.3-0.1-0.6-0.1-0.9,0.1C24.2,0.3,24,0.6,24,0.9v3.3v4.5v5.6
                          c-0.2-0.1-0.5-0.1-0.8-0.1c-0.8,0-1.5,0.3-2.1,0.8c-0.6,0.5-0.9,1.3-0.9,2v0.5c0,3.8-2.7,7-6.4,7.7c0.4-0.7,0.7-1.5,0.7-2.4v-0.6
                          c0-2.5-2-4.5-4.5-4.5s-4.5,2-4.5,4.5v0.6c0,1,0.3,1.8,0.8,2.6H4.6c-2.6,0-4.7,2.1-4.7,4.7v11.2c0,1.7,1.4,3.1,3.1,3.1
                          c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.6,0-1.1-0.5-1.1-1.1V30c0-1.5,1.2-2.7,2.7-2.7h7.9c5.4,0,9.8-4.4,9.8-9.8V17
                          c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.2c0.5,0,0.8,0.4,0.8,0.9v0.4c0,5.2-3.5,9.8-8.6,11.1c-0.4,0.1-0.7,0.5-0.7,0.9v9.2
                          h-9H5.6v-8.3c0-0.5-0.4-1-1-1s-1,0.4-1,1v9.3c0,0.5,0.4,1,1,1h0.1v8.3c0,0.5,0.4,1,1,1s1-0.4,1-1v-8.3h6.9v8.3c0,0.5,0.4,1,1,1
                          c0.5,0,1-0.4,1-1v-8.3h0.1c0.5,0,1-0.4,1-1v-9.5c3.1-1,5.7-3.1,7.3-5.8v10.3c0,0.5,0.4,1,1,1s1-0.4,1-1V24.5
                          c1.6,2.7,4.2,4.8,7.3,5.8v9.5c0,0.5,0.4,1,1,1h0.1v8.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-8.3h6.9v8.3c0,0.5,0.4,1,1,1s1-0.4,1-1
                          v-8.3h0.1c0.5,0,1-0.4,1-1v-9.3c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1v8.3h-0.1h-9v-9.2c0-0.4-0.3-0.8-0.7-0.9
                          c-5-1.3-8.6-5.9-8.6-11.1v-0.4c0-0.5,0.4-0.9,0.8-0.9c0.2,0,0.5,0.1,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6v0.5c0,5.4,4.4,9.8,9.8,9.8
                          h7.9c1.5,0,2.7,1.2,2.7,2.7v1.7c0,0.5,0.4,1,1,1s1-0.4,1-1V30C50.1,27.4,48,25.3,45.4,25.3z M26,4.2V2.3l6.9,2.5L26,7.3V4.2z
                          M12.7,22.8c0,1.4-1.1,2.6-2.6,2.6s-2.6-1.1-2.6-2.6v-0.6c0-1.4,1.1-2.6,2.6-2.6s2.6,1.1,2.6,2.6V22.8z M42.4,22.8
                          c0,1.4-1.1,2.6-2.6,2.6c-1.4,0-2.6-1.1-2.6-2.6v-0.6c0-1.4,1.1-2.6,2.6-2.6c1.4,0,2.6,1.1,2.6,2.6V22.8z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M49.8,34.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                          c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C50.1,35.4,50,35.1,49.8,34.9z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M49.1,38.4c-0.5,0-1,0.4-1,1v1.8c0,0.6-0.5,1.1-1.1,1.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c1.7,0,3.1-1.4,3.1-3.1v-1.8
                          C50.1,38.9,49.7,38.4,49.1,38.4z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M39.9,43.9c-0.5,0-1,0.4-1,1v4.3c0,0.5,0.4,1,1,1s1-0.4,1-1v-4.3C40.9,44.3,40.4,43.9,39.9,43.9z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M25.7,37.7c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                          c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C26,38.1,25.9,37.9,25.7,37.7z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content">
								<h3>Best Publishing <br />Partner</h3>
								<p>
									Our expertise in the game deployment has made us publishing partner of highly
									engaging and rewarding game titles.
								</p>
							</div>
						</div>

						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<polygon
											points="38.4,25.7 33.5,19.7 33.5,18.4 38.4,12.4 40,13.7 35.6,19 40,24.3  "
										></polygon>
										<polygon
											points="45.1,25.7 43.5,24.3 47.8,19 43.5,13.7 45.1,12.4 50,18.4 50,19.7  "
										></polygon>
										<rect x="43.8" y="29.4" width="2.1" height="11.4"></rect>
										<path
											d="M45.9,8.7h-2.1V5.6c0-1.7-1.4-3.1-3.1-3.1H5.6V0.5h35.1c2.8,0,5.2,2.3,5.2,5.2V8.7z"
										></path>
										<path
											d="M42.8,50l-29.4-4.1c-2.6-0.2-4.7-2.5-4.7-5.1v-31h2.1v31c0,1.5,1.1,2.7,2.5,3l0.4,0.1c1.7,0.1,3.3-1.3,3.3-3.1l1-1h31l1,1
                        v2.1C50,46.4,47.5,50,42.8,50z M17.5,44.4l25.4,3.6c3.4,0,5-2.6,5-5.1v-1.1h-29C18.7,42.7,18.2,43.6,17.5,44.4z"
										></path>
										<path
											d="M9.7,10.8H1.5l-1-1V5.6c0-2.8,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2v4.1L9.7,10.8z M2.5,8.7h6.2V5.6c0-1.7-1.4-3.1-3.1-3.1
                        S2.5,3.9,2.5,5.6V8.7z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content">
								<h3>High-end <br />Programming</h3>
								<p>
									Our expertise in native coding &amp; Unity like frameworks enables our talented
									mobile game developers to deliver the best and futuristic code.
								</p>
							</div>
						</div>

						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											d="M30,19.7c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8S30,20.7,30,19.7z M28.3,19.7
                        C28.2,19.7,28.2,19.7,28.3,19.7c-0.1-0.1,0-0.1,0-0.1S28.3,19.7,28.3,19.7C28.3,19.7,28.3,19.7,28.3,19.7z"
										></path>
										<path
											d="M26.2,23.4c-0.2-0.3-0.5-0.5-0.8-0.5H23c-0.3,0-0.6,0.2-0.8,0.5L21,25.5c-0.2,0.3-0.2,0.6,0,0.9l1.2,2.1
                        c0.2,0.3,0.5,0.5,0.8,0.5h2.4c0.3,0,0.6-0.2,0.8-0.5l1.2-2.1c0.2-0.3,0.2-0.6,0-0.9L26.2,23.4z M24.9,27.1h-1.3L22.9,26l0.7-1.2
                        h1.3l0.7,1.2L24.9,27.1z"
										></path>
										<path
											d="M43.6,22.3h-2.3V8.1c0-1.8-1.5-3.3-3.3-3.3H23.7l-3.9-4.4C19.5,0,18.9,0,18.5,0.3c-0.4,0.3-0.4,0.9-0.1,1.3l2.8,3.2h-2.7
                        c-1.8,0-3.3,1.5-3.3,3.3v4.5c-1.6,1-2.6,2.7-2.6,4.7s1,3.7,2.6,4.7v11.8l-8.5,2.9c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.2,0.9
                        l11.3,11.3c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.1,0,0.2,0c0.3-0.1,0.6-0.3,0.7-0.6l1.3-3.8H38c1.8,0,3.3-1.5,3.3-3.3v-9.1h2.3
                        c0.5,0,0.9-0.4,0.9-0.9v-8.8C44.5,22.7,44.1,22.3,43.6,22.3z M14.5,17.3c0-0.8,0.3-1.6,0.8-2.3v4.5C14.8,18.9,14.5,18.2,14.5,17.3z
                        M18,47.2L8.8,38l6.5-2.2V42c0,1.8,1.5,3.3,3.3,3.3h0.1L18,47.2z M39.4,42c0,0.8-0.6,1.4-1.4,1.4H18.6c-0.8,0-1.4-0.6-1.4-1.4v-34
                        c0-0.8,0.6-1.4,1.4-1.4H38c0.8,0,1.4,0.6,1.4,1.4C39.4,8.1,39.4,42,39.4,42z M42.6,31.1h-1.4v-6.9h1.4V31.1z"
										></path>
										<path
											d="M37.4,9.1H19.1c-0.5,0-0.9,0.4-0.9,0.9v25.7c0,0.5,0.4,0.9,0.9,0.9h18.3c0.5,0,0.9-0.4,0.9-0.9V10
                        C38.3,9.5,37.9,9.1,37.4,9.1z M20,14c1.2,0.6,2,1.9,2,3.3c0,1.4-0.8,2.7-2,3.3V14z M20,34.2l2.7-0.9l-0.5,1.4H20L20,34.2L20,34.2z
                        M24.2,34.7l0.9-2.6c0.1-0.3,0-0.7-0.2-0.9c-0.2-0.2-0.6-0.3-0.9-0.2L20,32.2v-9.6c2.2-0.7,3.8-2.8,3.8-5.3S22.3,12.8,20,12v-1.1
                        h6.6l7.1,8c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.4-0.9,0.1-1.3l-6-6.8h7.4v11.4h-6.6c-0.5,0-0.9,0.4-0.9,0.9
                        V32c0,0.5,0.4,0.9,0.9,0.9h6.6v1.8C36.5,34.7,24.2,34.7,24.2,34.7z M36.5,24.1v6.9h-5.7v-6.9H36.5z"
										></path>
										<path
											d="M28.3,37.4c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C30.8,38.6,29.6,37.4,28.3,37.4z
                        M28.3,40.6c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C28.9,40.3,28.6,40.6,28.3,40.6z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content">
								<h3>Alluring &amp; Engaging <br />Game Design</h3>
								<p>
									Rocky game titles in the market always design to mesmerize the gamers with first
									sight love and engage them as addictive players.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-choose-us-game.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.game-why-us-inner-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 0;
}
.game-why-us-inner-box:hover {
	background: #008dd2;
}
.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}
.game-why-us-inner-icon svg {
	width: 50px;
	height: 50px;
}
.game-why-us-inner-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	min-height: 90px;
	border-radius: 100%;
	background-color: #fff;
	border: 1px solid #e0e2e4;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content p {
	font-size: 14px !important;

}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
