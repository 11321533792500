<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/php-development-company.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Front-running PHP Web Development Company
					</h2>
					<p>
						Genixbit harnesses the open-source power of PHP to create stunning web portals and
						applications. Our PHP developers are experienced professionals whose PHP development
						services has helped several businesses gain a foothold in the hyper-competitive digital
						landscape.
					</p>
					<p>
						Be it an enterprise website that can collect dynamic user input or an eCommerce store
						that has thousands of underlying product pages — Genixbit’s PHP development services
						can ideate, create and launch any kind of website of your choice.
					</p>
					<p>
						We have worked with all sizes and types of businesses — from the pioneers who are
						setting the trends to the visionaries who are on their way to change the IT world.
					</p>
					<p>
						If you have an idea that needs a scalable, time-proof and affordable solution, our PHP
						development services has the answer. Our services would help you reach the market
						quickly with websites and web applications that are stable, bug-free and also enriched
						with great UX.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
