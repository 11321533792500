<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/ar-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Augmented Reality Game Development</h2>
					<p>
						Augmented Reality is not a new technology and has been known in the technology industry
						for some time, but to a large extent augmented reality game development companies are
						still struggling to explore its real potential and deliver the same to the global
						audience. At GenixBit Labs, our augmented reality game developer and the designer team has
						flawless command over the available tools like ARKit, Vuforia, ARCore, Wikitude,
						ARToolkit etc. Besides these, considering the essentials of creativity and user
						engagement, the AR developers team also utilizes its creative approach to design and
						deliver some of the best augmented reality game development services to its clients.
					</p>
					<p>
						The augmented reality game developers team firmly believes that the only way to make a
						deep space in the hearts of global audience through AR mobile game development is to
						create innovative and creative solutions and deliver them effortlessly through best
						augmented reality game development solutions.
					</p>
					<p>
						To ensure prompt and competitive delivery of the same, the team follows a certain set of
						guidelines and procedures for research and development tasks, which effectively works as
						fuel.
					</p>
					<p>
						When the technology stack and expertise match with the above mentioned flavours, time
						and dedication, the team at GenixBit Labs make its way to one of the best augmented reality
						game development company in the UK and worldwide. However, we also believe that just
						earning a badge isn’t the big deal. Hence to maintain the same position in the
						ultra-competitive augmented reality mobile game development market, flawless services
						and client satisfaction remain uncompromised.
					</p>
					<p>
						With that said, we now invite you to get in touch and bring us your idea or
						requirements. This will eventually lead us to develop an undisputed leading game
						solution in the AR industry. We believe in sharing knowledge.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
