<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Solutions</span>
				<h2 class="fxt-process-dev">Our FinTech offerings</h2>
				<p>
					We build mobile apps that will transcend your business into the future where the world of finance is accessible at fingertips, literally.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/insurance-apps.webp",
            title:"Insurance Apps",
            text:"Apps for the insurance industry that help customers choose, compare and purchase insurance products for life, automobile or business.",},
            {imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/investment-apps.webp",
            title:"Investment Apps",
            text:"A personal financial advisor in the user’s pocket. From current stock prices to upcoming IPOs, all information made available in a mobile app.",},
            {imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/accounting-apps.webp",
            title:"Accounting Apps",
            text:"Simple, easy-to-use yet feature-rich accounting apps that helps users to record, classify and manage their accounting records on the go.",},
            {imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/banking-apps.webp",
            title:"Banking Apps",
            text:"Visiting a bank’s branch in person would be a thing of the future with banking apps. Users can transfer funds, check bank balance, give standing instructions and much more from a banking app.",},
            {imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/digital-wallet-apps.webp",
            title:"Digital Wallet Apps",
            text:"Who needs ten apps when a single digital wallet can do the task of paying utility bills, phone recharges, online shopping, sending gifts, ticket booking and much more.",},
            {imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/finance/personal-finance-apps.webp",
            title:"Personal Finance Apps",
            text:"Bringing personal finance under control is not a straightforward task. A well-designed personal finance app can help user manage their personal finance like experts do.",},
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
