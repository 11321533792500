<template>
	<section class="padding-60 bg-light p-relative">
		<div class="row" style="justify-content: center; gap: 25px">
			<div
				:class="`service-we-offer-web-inner row justify-content-center m-height-260 clearfix ${className}`"
				v-for="item in items"
				class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30 health-rounded-card"
				v-bind:key="item.title"
			>
				<HalfRoundedCard v-bind:obj="item"></HalfRoundedCard>
			</div>
		</div>
		<div class="show-all-button text-center mt-20">
			<span
				:class="`btn btn-lg btn-custome waves-effect waves-light fxt-health-button ${className}`"
				>Show All</span
			>
		</div>
	</section>
</template>

<script>
import HalfRoundedCard from './HalfRoundedCard.vue';

export default {
	components: {
		HalfRoundedCard
	},
	props: {
		items: {
			required: true,
			type: Array,
			default: () => {}
		},
		className: {
			required: true,
			type: String,
			default: () => {}
		}
	},
	mounted() {
		const items = document.querySelectorAll(`.health-rounded-card.${this.className}`);
		const showButton = document.querySelector(`.fxt-health-button.${this.className}`);
		if (items.length <= 12) {
			showButton.style.display = 'none';
		}
		items.forEach((item, index) => {
			if (index >= 12) {
				item.style.display = 'none';
			}
		});
		showButton.addEventListener('click', () => {
			if (showButton.classList.contains('active')) {
				showButton.classList.remove('active');
				items.forEach((item, index) => {
					if (index >= 12) {
						item.style.display = 'none';
					}
				});
				showButton.textContent = 'Show Less';
			} else {
				showButton.classList.add('active');
				items.forEach((item) => {
					item.style.display = 'block';
				});
				showButton.textContent = 'Show Less';
			}
		});
	}
};
</script>

<style scoped>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.car-wash-feature-tab-content .show-all-button a {
	font-size: 16px;
}
.btn-custome.btn-lg {
	padding: 15px 40px;
	font-size: 20px;
}
.waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	vertical-align: middle;
	z-index: 1;
	will-change: opacity, transform;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.btn-custome {
	background: #008dd2 !important;
	box-shadow: 0 3px 10px 0 rgba(236, 115, 35, 0.2) !important;
	color: #fff !important;
	padding: 15px 25px;
	border-radius: 5px !important;
	line-height: 1;
	font-size: 14px;
	display: inline-flex;
	position: relative;
	border: none;
}
</style>