<template>
	<div style="width: 100%; display: inline-block">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-6 col-12 order-lg-1">
					<img
						class="lazy"
						alt=""
						:src="require(`../../../assets/images${obj.imageLink}`)"
						style=""
					/>
				</div>
				<div class="col-lg-6 col-12">
					<div class="app-service-tab-text">
						<div v-html="obj.svg"></div>
						<h3>{{ obj.title }}</h3>
						<p v-for="description in obj.descriptions" :key="description">{{ description }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.app-service-tab-text svg {
	width: 74px;
	height: 74px;
}
</style>