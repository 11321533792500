<template>
  <section class="padding-6030 our-core-value-section bg-white">
    <div class="container">
      <div class="title">
        <span>Our Values</span>
        <h2>Our Moral Compass that Drives us Forward</h2>
        <p>
          Genixbit is made up of people; people who put passion into
          everything they do. We are the dreamers and the doers. And everything
          our people do is molded around a set of values:
        </p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
          <div class="our-core-value-box rounded10">
            <div class="our-core-value-icon rounded10">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                style="enable-background: new 0 0 50 50"
                xml:space="preserve"
              >
                <g class="seemst0">
                  <g class="seemst1">
                    <path
                      class="seemst2"
                      d="M44.6,0c-0.1,0-0.2,0-0.3,0.1l-1.8,1.8l0,0L36,8.6c-0.9-0.5-1.9-0.7-3-0.7l0,0c-1.9,0-3.6,0.8-4.8,2.1l0,0
                           H15.3c-1-2.3-3.4-4-6.1-4C9,6,8.8,6.2,8.8,6.5v6.1l-3.1,5.3c-0.1,0.2-0.1,0.5,0.2,0.6c0.8,0.5,1.8,0.8,2.9,0.9v9.2
                           c0,0.6,0.5,1,1,1h12v6L20.9,37l-9.1,5.3c-0.3-0.1-0.6-0.3-1-0.3c-1.4,0-2.5,1.1-2.5,2.5S9.4,47,10.8,47c1.3,0,2.4-1.1,2.4-2.4
                           l8.5-4.9v5.9c-0.7,0.5-1.1,1.2-1.1,2c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-0.8-0.5-1.5-1.1-2v-5.9l8.4,4.8v0.1
                           c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5l0,0c-0.3,0-0.6,0.1-0.9,0.2L25.2,37l-0.8-1.4v-6h11.9c0.6,0,1-0.5,1-1
                           v-8.8l0,0c1.5-1.2,2.5-3.1,2.5-5.2c0-1.1-0.3-2.1-0.7-3l6.5-6.7l1.7-1.8c0.2-0.2,0.2-0.5,0-0.6L45,0.1C44.9,0,44.8,0,44.6,0
                           L44.6,0z M44.7,1.1l1.7,1.7l-0.9,0.9L43.8,2L44.7,1.1z M43.2,2.6l0.6,0.5L35,12.2l0.6,0.6l8.8-9.1L45,4.2l-8.8,9l-2.4,0.7l0.7-2.4
                           L43.2,2.6z M7.1,6.4L7.1,6.4c-2.7,0.9-4.5,3.4-4.5,6.3c0,1.7,0.6,3.2,1.6,4.4c0.4,0.4,1.1-0.1,0.7-0.6c-0.9-1-1.4-2.3-1.4-3.8
                           c0-2.5,1.6-4.7,3.8-5.4C7.8,7.1,7.6,6.4,7.1,6.4L7.1,6.4z M9.6,7c2.8,0.2,5.1,2.4,5.3,5.3H9.6V7L9.6,7L9.6,7z M33,8.8
                           c0.8,0,1.7,0.2,2.4,0.5l-1.8,1.9l-0.3,1c-0.1,0-0.2,0-0.2,0l0,0l0,0c-0.6,0-0.5,0.9,0,0.9l-0.6,2.3l2.2-0.6c0,0.8-0.7,1.5-1.6,1.5
                           c-0.9,0-1.6-0.7-1.5-1.6c0-0.6-0.9-0.6-0.9,0c0,1.3,1.1,2.4,2.4,2.5c1.3,0,2.4-1.1,2.5-2.4c0-0.1,0-0.1,0-0.2l1.1-0.3l1.8-1.8
                           c0.3,0.7,0.5,1.5,0.5,2.3c0,3.2-2.5,5.8-5.8,5.9c-0.6,0-0.6,0.9,0,0.9c1.2,0,2.3-0.3,3.3-0.9v8.2c0,0.1-0.1,0.1-0.1,0.1H9.7
                           c-0.1,0-0.1-0.1-0.1-0.1v-9.2c3.5-0.2,6.3-3.1,6.3-6.6c0-0.6-0.1-1.2-0.3-1.8h11.9c-0.7,1.1-1.2,2.4-1.1,3.8c0,0.6,0.9,0.6,0.9,0
                           C27.3,11.5,29.8,8.9,33,8.8L33,8.8z M33.1,10.1c-2.5,0-4.5,2.1-4.5,4.6c0,1.1,0.5,2.2,1.2,3l-3,4c-0.3-0.1-0.6-0.2-0.9-0.2
                           c-0.6,0-1.1,0.2-1.4,0.6l-3.9-2.9c0.2-0.3,0.3-0.6,0.3-1c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,0.5,0.2,0.9,0.5,1.3l-3.1,3.9
                           c-0.2-0.1-0.4-0.1-0.6-0.1c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2c0-0.6-0.2-1.1-0.6-1.4l3-3.8c0.3,0.1,0.5,0.2,0.8,0.2
                           c0.4,0,0.8-0.1,1.1-0.4l4,3c-0.1,0.2-0.1,0.4-0.1,0.6c0,1.1,0.9,2,2,2s2-0.9,2-2c0-0.4-0.2-0.9-0.4-1.2l3-4
                           c0.8,0.6,1.7,0.9,2.8,0.9c2.5,0,4.5-2.1,4.5-4.6c0-0.6-0.9-0.6-0.9,0c0,2-1.6,3.7-3.6,3.7s-3.7-1.6-3.7-3.6s1.6-3.7,3.6-3.7
                           C33.7,11,33.7,10.1,33.1,10.1L33.1,10.1z M9.5,13.2H15c-0.2,3-2.7,5.3-5.8,5.3c-0.9,0-1.7-0.3-2.5-0.7L9.5,13.2z M18.7,17
                           c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S18.1,17,18.7,17z M25.8,22.3c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1
                           s-1.1-0.5-1.1-1.1C24.6,22.8,25.1,22.3,25.8,22.3z M13.4,24.1c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1
                           C12.3,24.6,12.8,24.1,13.4,24.1z M22.6,29.6h0.9v6.1c0,0.1,0,0.2,0.1,0.2l0.9,1.6c0,0.1,0.1,0.1,0.2,0.2l9.5,5.5
                           c0.1,0.1,0.3,0.1,0.4,0C34.7,43,35,43,35.3,43c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6c0-0.1,0-0.2,0-0.3
                           c0-0.2-0.1-0.4-0.2-0.5l-9.3-5.4c-0.3-0.2-0.7,0-0.7,0.4v6.9c0,0.2,0.1,0.3,0.2,0.4c0.5,0.3,0.9,0.8,0.9,1.4
                           c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6c0-0.6,0.3-1.2,0.9-1.4c0.1-0.1,0.2-0.2,0.2-0.4v-6.8c0-0.3-0.4-0.6-0.7-0.4l-9.4,5.4
                           c-0.1,0.1-0.2,0.3-0.2,0.4v0.1c0,0.9-0.7,1.6-1.6,1.6C9.7,46,9,45.3,9,44.4c0-0.9,0.7-1.6,1.6-1.6c0.3,0,0.6,0.1,0.8,0.2
                           c0.1,0.1,0.3,0.1,0.5,0l9.4-5.4c0.1,0,0.1-0.1,0.2-0.2l0.9-1.6c0-0.1,0.1-0.1,0.1-0.2L22.6,29.6L22.6,29.6z"
                    ></path>
                  </g>
                </g>
                <g>
                  <path
                    d="M7.5,5.5C7.8,5.4,8,5.1,7.9,4.8c0-0.3-0.3-0.5-0.6-0.5c0,0,0,0-0.1,0s-0.1,0-0.2,0C4.3,5.3,2.4,8,2.4,11
                        c0,1.7,0.6,3.3,1.7,4.6c0.1,0.1,0.3,0.2,0.5,0.2s0.5-0.2,0.6-0.4c0-0.1,0-0.4-0.2-0.5c-0.9-1.1-1.4-2.4-1.4-3.8
                        C3.6,8.5,5.2,6.3,7.5,5.5z"
                  ></path>
                  <path
                    d="M47.4,2.6L45,0.2C44.8,0.1,44.7,0,44.5,0l0,0c0,0,0,0-0.1,0S44.1,0.1,44,0.2l-1.8,1.9l0,0l-4.8,4.5c-1-0.5-2-0.7-3.1-0.7
                        h-0.1c-1.9,0-3.7,0.8-5,2.2H15.9C14.7,5.6,12.2,4,9.5,4C9.2,4,8.9,4.3,8.9,4.6v6.3l-3.2,5.5c-0.1,0.1-0.1,0.3-0.1,0.5
                        s0.1,0.3,0.3,0.4C6.8,17.6,7.8,18,8.8,18v9.5c0,0.7,0.5,1.2,1.2,1.2h12.3v6l-0.8,1.4l-9.4,5.4c-0.3-0.1-0.6-0.3-1-0.3
                        c-1.5,0-2.7,1.2-2.7,2.7s1.2,2.7,2.7,2.7c1.4,0,2.6-1.1,2.7-2.5l8.6-4.9V45c-0.7,0.5-1.2,1.3-1.2,2.2c0,1.5,1.2,2.7,2.7,2.7
                        s2.7-1.2,2.7-2.7c0-0.8-0.4-1.6-1.2-2.2v-5.8l8.4,4.9l0,0c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7
                        c-0.3,0-0.6,0.1-0.9,0.2l-9.5-5.5l-0.8-1.4v-6h12.2c0.7,0,1.2-0.5,1.2-1.2v-9.1c1.7-1.4,2.6-3.4,2.6-5.5c0-1.1-0.3-2.1-0.7-3.1
                        l4.8-4.6l1.8-1.9C47.7,3.2,47.7,2.8,47.4,2.6z M34.8,43.3l-9.7-5.6c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.3,0.1-0.4,0.2
                        c-0.1,0.1-0.2,0.3-0.2,0.4v7.1c0,0.2,0.1,0.4,0.3,0.5c0.5,0.3,0.8,0.8,0.8,1.4c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5
                        c0-0.6,0.3-1.1,0.8-1.4c0.2-0.1,0.3-0.3,0.3-0.5v-7.1c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2s-0.2,0-0.3,0.1l-9.7,5.6
                        c-0.2,0.1-0.3,0.3-0.3,0.6V44c0,0.8-0.7,1.5-1.5,1.5S9.5,44.8,9.5,44s0.7-1.5,1.5-1.5c0.3,0,0.6,0.1,0.8,0.2
                        c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l9.8-5.7c0.1-0.1,0.2-0.1,0.2-0.2l0.9-1.6c0.1-0.1,0.1-0.2,0.1-0.3v-6.2h0.7V35
                        c0,0.1,0,0.2,0.1,0.3l0.9,1.6c0.1,0.1,0.1,0.2,0.2,0.2l9.9,5.7c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.2-0.1,0.5-0.2,0.7-0.2
                        c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S35,44.9,35,44.1c0-0.1,0-0.2,0-0.2C35.2,43.7,35.1,43.4,34.8,43.3z M43.8,2.2l0.7-0.7
                        l1.3,1.3l-0.7,0.7L43.8,2.2z M34,19.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.3,0.2,0.6,0.6,0.6c1.1,0,2.3-0.3,3.3-0.8v8.2H10V18
                        c3.7-0.3,6.5-3.4,6.5-7.1c0-0.6-0.1-1.2-0.2-1.7h12c-0.7,1.1-1.1,2.5-1.1,3.8c0,0.4,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2
                        c0.1-0.1,0.2-0.3,0.2-0.4c0-1.6,0.6-3.1,1.7-4.2s2.6-1.7,4.1-1.8l0,0l0,0h0.1c0.7,0,1.5,0.1,2.2,0.4l-1.7,1.8c0,0,0,0,0,0.1l-0.3,1
                        h-0.1c0,0,0,0-0.1,0c-0.4,0-0.6,0.3-0.6,0.6s0.2,0.5,0.4,0.6l-0.6,2.2c0,0.1,0,0.1,0,0.1h0.1l0,0l2-0.6c-0.1,0.7-0.7,1.2-1.4,1.2
                        c-0.8,0-1.5-0.7-1.5-1.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.5
                        c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8c1.5,0,2.7-1.2,2.7-2.7v-0.1l1-0.3c0,0,0,0,0.1,0l1.7-1.7c0.3,0.7,0.4,1.4,0.4,2.1
                        c0,1.6-0.6,3.1-1.7,4.2c-1.1,1.1-2.6,1.7-4.2,1.8C34.2,18.9,34.1,19,34,19.1z M44.2,4.3l0.1,0.1l-7.1,6.9L35,12l0.9-1.9l7.1-7
                        l0.4,0.4L44.2,4.3z M10.1,10.4V5.2c2.8,0.3,5,2.4,5.2,5.2H10.1z M15.3,11.6c-0.3,3-2.8,5.3-5.8,5.3c-0.9,0-1.7-0.3-2.4-0.6l2.7-4.7
                        C9.8,11.6,15.3,11.6,15.3,11.6z"
                  ></path>
                  <path
                    d="M38.6,12.4c-0.2,0-0.3,0.1-0.4,0.2C38.1,12.7,38,12.8,38,13c0,1-0.4,1.9-1,2.6c-0.7,0.7-1.6,1.1-2.6,1.1
                        c-2,0-3.7-1.6-3.7-3.6c0-1,0.4-1.9,1-2.6c0.7-0.7,1.6-1.1,2.6-1.1c0.4,0,0.6-0.3,0.6-0.6s-0.2-0.6-0.6-0.6l0,0
                        c-1.3,0-2.5,0.5-3.4,1.5c-0.9,0.9-1.4,2.2-1.4,3.5c0,1.1,0.4,2.2,1.2,3.1l-3,4c-0.3-0.1-0.6-0.2-0.9-0.2c-0.6,0-1.1,0.2-1.5,0.6
                        l-3.9-2.8c0.2-0.3,0.3-0.7,0.3-1c0-1.2-1-2.2-2.2-2.2s-2.2,1-2.2,2.2c0,0.5,0.2,0.9,0.4,1.3l-3.1,3.9C14.4,22,14.2,22,14,22
                        c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-0.6-0.2-1.1-0.6-1.5l3-3.8c0.3,0.1,0.6,0.2,0.8,0.2c0.4,0,0.8-0.1,1.2-0.3
                        l4,3c-0.1,0.2-0.1,0.4-0.1,0.6c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-0.4-0.1-0.9-0.4-1.2l3-4c0.8,0.6,1.8,0.9,2.8,0.9l0,0
                        c1.3,0,2.5-0.5,3.4-1.5c0.9-0.9,1.4-2.2,1.4-3.5C39.3,12.6,38.9,12.4,38.6,12.4z M27.8,22.2c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1
                        S27.8,21.6,27.8,22.2z M20.4,16.7c0,0.6-0.4,1-1,1s-1-0.4-1-1s0.4-1,1-1S20.4,16.1,20.4,16.7z M14.9,24c0,0.6-0.4,1-1,1s-1-0.4-1-1
                        s0.4-1,1-1S14.9,23.4,14.9,24z"
                  ></path>
                </g>
              </svg>
            </div>
            <h3>Seamless and Stress free Delivery</h3>
            <p>
              A great project includes a great delivery, with regular status
              updates,
              <span
                class="tou-node"
                id="tou-0-3fd7b508-a0fb-4651-9a7f-21d45791add8"
              ></span>
              control and Customer Engagement
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
          <div class="our-core-value-box rounded10">
            <div class="our-core-value-icon rounded10">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                style="enable-background: new 0 0 50 50"
                xml:space="preserve"
              >
                <g class="innost0">
                  <path
                    class="innost1"
                    d="M28.1,21.8c-2.1-0.2-4.2-0.4-6.4,0.1c0.4,0.5,0.8,1,1.1,1.4c3,3.5,1.3,8.8-3.1,9.7c-2.5,0.5-4.4-0.6-5.9-2.5
                        c-2.2-2.8-2.8-6.1-2.6-9.5c0.3-5.6,4.4-11.2,11.6-12.3c6.9-1.1,14.4,3.1,15.8,11.4c1.4,8.6-5.1,16.1-13.8,16.1c-1,0-2.1,0-3.1,0
                        c-0.8,0-1.3,0.5-1.3,1.1c0,0.8,0.4,1.2,1.3,1.2c2.4,0,4.8,0,7.1,0c2,0,3.7,1.6,3.7,3.5c0,1.9-1.7,3.5-3.7,3.5c-2.3,0-4.7,0-7,0
                        c-0.9,0-1.4-0.4-1.4-1.1c0-0.7,0.5-1.2,1.4-1.2c2.4,0,4.8,0,7.1,0c0.9,0,1.3-0.4,1.2-1.2c0-0.8-0.4-1.1-1.2-1.1c-2.4,0-4.8,0-7.1,0
                        c-2,0-3.8-1.5-3.8-3.4c0-2.1,1.6-3.6,3.7-3.6c1.8,0,3.7,0.1,5.5-0.3c4.9-1,7.9-4.1,9-8.9c1.1-4.9-0.8-8.9-4.8-11.8
                        c-6.1-4.5-16-1.2-17.6,6.3c-0.6,2.8-0.6,5.6,0.7,8.2c0.6,1.1,1.4,2.1,2.3,2.9c1.2,1.1,2.4,0.9,3.9-0.2c1.2-1,1.8-2.6,1.2-3.8
                        c-0.3-0.6-0.7-1.1-1.1-1.6c-0.5-0.8-1-1.6-1.3-2.4c-0.3-0.9,0.1-1.8,1-2.2c0.1-0.1,0.2-0.1,0.3-0.2c2.8-0.7,5.5-0.8,8.2,0.3
                        c1.7,0.6,1.9,1.9,0.8,3.3c-0.4,0.6-0.8,1.1-1.2,1.7c-0.6,0.9-0.8,1.9-0.5,3c0.2,0.8-0.2,1.4-0.9,1.5c-0.6,0.1-1.2-0.3-1.4-1.1
                        c-0.3-1.5-0.2-3,0.7-4.4C27.1,23.4,27.6,22.6,28.1,21.8z"
                  ></path>
                  <path
                    class="innost1"
                    d="M33.8,9.3c-1,0-1.6-0.8-1.3-1.6c0.7-1.5,1.5-3,2.3-4.4c0.3-0.5,0.9-0.7,1.5-0.3c0.6,0.3,0.8,0.9,0.5,1.4
                        c-0.7,1.5-1.5,3-2.4,4.4C34.3,9.1,33.9,9.2,33.8,9.3z"
                  ></path>
                  <path
                    class="innost1"
                    d="M23.9,3.5c0-0.7,0-1.4,0-2.1c0-0.8,0.5-1.4,1.2-1.3c0.7,0,1.1,0.5,1.1,1.3c0,1.5,0,2.9,0,4.4
                        c0,0.8-0.5,1.3-1.1,1.3c-0.7,0-1.2-0.5-1.2-1.4C23.9,4.9,23.9,4.2,23.9,3.5z"
                  ></path>
                  <path
                    class="innost1"
                    d="M18.1,8.2c-0.3,0.3-0.6,0.8-0.9,0.9c-0.5,0.2-1,0.1-1.3-0.4c-0.8-1.4-1.5-2.9-2.3-4.4c-0.3-0.6,0-1.2,0.6-1.5
                        c0.6-0.3,1.2-0.2,1.5,0.4c0.8,1.4,1.5,2.9,2.3,4.4c0.1,0.1,0.1,0.3,0.1,0.4C18,8.1,18,8.1,18.1,8.2z"
                  ></path>
                  <path
                    class="innost1"
                    d="M25.1,47.7c0.6,0,1.2,0.5,1.2,1.1c0,0.7-0.6,1.2-1.2,1.2c-0.6,0-1.1-0.6-1.1-1.2
                        C23.9,48.2,24.4,47.7,25.1,47.7z"
                  ></path>
                </g>
                <g>
                  <path
                    d="M38.2,20.2C36.9,11.9,29.4,8,22.9,9c-7.3,1.1-11,7-11.3,11.9c-0.2,3.9,0.6,6.9,2.5,9.3c1.6,2,3.4,2.8,5.5,2.4
                        c1.9-0.4,3.4-1.7,4-3.5c0.7-1.9,0.2-4-1.1-5.6c-0.2-0.3-0.5-0.6-0.7-0.9l-0.8-0.9l0.6-0.1c2-0.5,3.8-0.3,5.7-0.2l1.4,0.1l-0.9,1.3
                        c-0.3,0.5-0.7,1-1,1.5c-0.7,1.2-0.9,2.5-0.6,4.1c0.1,0.6,0.5,0.9,0.9,0.8c0.2,0,0.4-0.2,0.5-0.3c0.1-0.2,0.2-0.5,0.1-0.7
                        c-0.3-1.2-0.1-2.3,0.6-3.3c0.4-0.6,0.8-1.2,1.2-1.7c0.5-0.6,0.7-1.2,0.6-1.7c-0.2-0.5-0.7-0.9-1.2-1.1c-2.4-0.9-5-1-8-0.2
                        c0,0-0.1,0-0.2,0.1C20.5,20.4,19.6,21,20,22c0.2,0.7,0.6,1.3,1,1.9l0.3,0.4c0.1,0.2,0.3,0.4,0.4,0.7c0.2,0.3,0.5,0.6,0.6,1
                        c0.6,1.4,0.1,3.2-1.3,4.3c-1.1,0.8-2.7,1.7-4.4,0.2c-1-0.9-1.8-2-2.4-3c-1.2-2.4-1.5-5.1-0.7-8.4c0.8-3.5,3.4-6.5,7.1-7.9
                        c3.9-1.5,8-1,11.1,1.3c4.3,3.2,6,7.3,4.9,12.2c-1.1,5.1-4.3,8.3-9.3,9.2c-1.4,0.3-2.8,0.3-4.1,0.3c-0.5,0-1,0-1.4,0
                        c-2,0-3.3,1.4-3.4,3.3c0,0.7,0.3,1.4,0.8,1.9c0.7,0.7,1.6,1,2.5,1c0,0,0,0,0,0l0.5,0c2.2,0,4.4,0,6.6,0c1.1,0,1.6,0.5,1.6,1.5
                        c0,0.6-0.1,0.9-0.4,1.2c-0.3,0.3-0.7,0.4-1.2,0.4c-1.3,0-2.6,0-3.8,0c-0.4,0-0.9,0-1.3,0c-0.7,0-1.3,0-2,0c-0.4,0-0.7,0.1-0.9,0.3
                        c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.6,0.6,0.8,1,0.8l0.4,0c2.2,0,4.4,0,6.6,0c0.9,0,1.7-0.3,2.4-1c0.6-0.6,1-1.4,1-2.2
                        c0-1.6-1.6-3.1-3.3-3.1c-1.1,0-2.2,0-3.4,0c-1.3,0-2.5,0-3.8,0c-0.6,0-1-0.2-1.3-0.5c-0.3-0.3-0.4-0.6-0.4-1.1
                        c0-0.8,0.8-1.5,1.7-1.5c0.7,0,1.5,0,2.2,0l1,0c4.1,0,7.9-1.7,10.4-4.7C37.8,28.1,38.9,24.1,38.2,20.2z"
                  ></path>
                  <path
                    d="M35.7,3.2c-0.2,0-0.4,0.1-0.5,0.3c-0.9,1.5-1.6,3-2.3,4.4c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.2,0.5,0.4,0.8,0.4
                        c0,0,0.1,0,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.9-1.6,1.7-3,2.3-4.4c0.2-0.4,0-0.7-0.4-0.9C36.1,3.2,35.9,3.2,35.7,3.2z"
                  ></path>
                  <path
                    d="M24.3,5.6c0,0.4,0.1,0.6,0.3,0.8c0.1,0.1,0.3,0.2,0.5,0.2c0.5,0,0.8-0.4,0.8-0.9c0-1.5,0-2.9,0-4.4c0-0.6-0.3-0.9-0.8-0.9
                        c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.2-0.3,0.4-0.3,0.8c0,0.5,0,1,0,1.5l0,0.7l0,0.7C24.3,4.6,24.3,5.1,24.3,5.6z"
                  ></path>
                  <path
                    d="M17.5,7.8L17,6.8c-0.6-1.1-1.2-2.3-1.8-3.4C15.1,3.1,14.9,3,14.7,3c-0.2,0-0.3,0-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.4
                        c-0.1,0.2-0.1,0.4,0,0.6c0.7,1.4,1.4,2.9,2.2,4.4c0.2,0.3,0.5,0.4,0.8,0.3c0.2-0.1,0.4-0.3,0.5-0.5c0,0,0-0.1,0.1-0.1l0-0.1
                        c0-0.1,0-0.1,0-0.2C17.5,7.9,17.5,7.8,17.5,7.8z"
                  ></path>
                  <path
                    d="M25.1,48c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6
                        C25.8,48.4,25.5,48,25.1,48C25.1,48,25.1,48,25.1,48z"
                  ></path>
                </g>
              </svg>
            </div>
            <h3>Innovative Solutions Provider</h3>
            <p>
              Our projects are delivered with a tailored
              <span
                class="tou-node"
                id="tou-0-d1c77d18-a5bf-410f-af15-44200becdf62"
              ></span>
              in mind at
              <span
                class="tou-node"
                id="tou-0-10e374ee-0a3c-4125-86f7-f8ed3f3df4be"
              ></span>
              of tech innovation
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
          <div class="our-core-value-box rounded10">
            <div class="our-core-value-icon rounded10">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                style="enable-background: new 0 0 50 50"
                xml:space="preserve"
              >
                <path
                  class="longst0"
                  d="M50,21h-1.8l-2.7,1.4c-0.3-0.2-0.6-0.3-1-0.4l-3.4-0.9v-0.4c1.5-1,2.4-2.8,2.4-4.6v-1.4c0-3.1-2.5-5.8-5.5-5.8
                     c-0.2,0-0.3,0-0.5,0l0.9-2.1l-4.3-4.3l-2.5,1.6c-1-0.5-2-1-3-1.3L28.1,0H22l-0.6,2.9c-1.1,0.3-2.1,0.7-3,1.3l-2.5-1.6l-4.3,4.3
                     L12.5,9h-0.1c-1.5,0-3,0.5-4.1,1.6s-1.7,2.5-1.7,4.1v1.6c0,1.8,0.9,3.6,2.4,4.6v0.4l-2.2,0.6l-4-2.3H0v16.7l7.9,2.6h1.5L9.7,38
                     l2.4,1.5c0,1.3,1.1,2.4,2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c0,1.3,1.2,2.4,2.5,2.4c0.5,0,1-0.2,1.4-0.4l0.7,0.6
                     c0.4,0.4,1,0.6,1.6,0.6h0.1c1.3,0,2.4-1.1,2.5-2.4c1.3,0,2.4-1.1,2.4-2.4c1.3,0,2.4-1.1,2.4-2.4c1.3,0,2.4-1.1,2.4-2.5
                     c0-0.1,0-0.2,0-0.2L50,32.8V21z M41.9,14.7v1.4c0,1.4-0.8,2.8-2,3.5l-0.4,0.2v2.6l4,1l-8.9,4.7v-2.2c0-1.1-0.4-2.1-1.2-2.8l2.8-0.7
                     v-2.6l-0.4-0.2c-1.2-0.7-2-2.1-2-3.5v-1.6c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.1C40.2,10.6,41.9,12.4,41.9,14.7z
                     M26.2,27.4l-3.7-1.2c-1.7-0.5-3.5-0.6-5.2-0.2l-0.5,0.1v-0.2c0-1.1,0.8-2.1,1.8-2.3l4.6-1.2v-2.6L23,19.6c-1.2-0.7-2-2.1-2-3.5
                     v-1.6c0-1.1,0.4-2.1,1.2-2.9c0.8-0.8,1.8-1.2,2.9-1.1c2.2,0.1,3.9,2,3.9,4.2v1.4c0,1.4-0.8,2.8-2,3.5l-0.4,0.2v2.6l4.6,1.2
                     c1.1,0.3,1.8,1.2,1.8,2.3v3L32.9,29h-0.5l-5-1.4C27,27.5,26.6,27.5,26.2,27.4z M17.1,12.1c1.5-2.9,4.6-4.8,7.9-4.8s6.3,1.9,7.9,4.7
                     c-0.4,0.8-0.6,1.6-0.6,2.5v1.6c0,1.8,0.9,3.6,2.4,4.6v0.4L31.5,22l-3.2-0.8v-0.4c1.5-1,2.4-2.8,2.4-4.6v-1.4c0-3.1-2.5-5.8-5.5-5.8
                     c-1.5,0-3,0.5-4.1,1.6s-1.7,2.5-1.7,4.1v1.6c0,1.8,0.9,3.6,2.4,4.6v0.4L18.5,22l-3.2-0.8v-0.4c1.5-1,2.4-2.8,2.4-4.6v-1.4
                     C17.7,13.8,17.5,12.9,17.1,12.1z M13.4,7.2L16,4.6l2.3,1.5l0.4-0.3c1.1-0.7,2.3-1.2,3.5-1.4l0.5-0.1l0.6-2.6h3.5l0.6,2.6l0.5,0.1
                     c1.2,0.3,2.4,0.8,3.5,1.4l0.4,0.3L34,4.6l2.6,2.6l-0.9,2.1c-0.6,0.3-1.2,0.6-1.7,1.1l-0.1,0.1c-1.9-3-5.3-4.9-8.9-4.9s-7,1.9-8.9,5
                     c-0.5-0.5-1.1-1-1.8-1.3L13.4,7.2z M10.5,22.4v-2.6l-0.4-0.2c-1.2-0.7-2-2.1-2-3.5v-1.6c0-1.1,0.4-2.1,1.2-2.9
                     c0.8-0.8,1.8-1.2,2.9-1.1c2.2,0.1,3.9,2,3.9,4.2v1.4c0,1.4-0.8,2.8-2,3.5l-0.4,0.2v2.6l2.8,0.7c-0.7,0.7-1.2,1.7-1.2,2.8v0.6
                     l-2.5,0.6l0.1-0.2v-1.5l-4.3-2.5L10.5,22.4z M1.6,21h0.6L8,24.4L5.1,36.1l-3.5-1.2C1.6,34.9,1.6,21,1.6,21z M8.2,37.1l-1.6-0.5
                     l2.8-11.4l1.8,1.1v0.2L8.2,37.1L8.2,37.1z M13.7,39.4c0-0.2,0.1-0.5,0.3-0.6l1.4-1.4c0.2-0.2,0.4-0.3,0.6-0.3c0.5,0,0.9,0.4,0.9,0.9
                     c0,0.2-0.1,0.5-0.3,0.6L15.2,40c-0.2,0.2-0.4,0.3-0.6,0.3C14.1,40.3,13.7,39.9,13.7,39.4z M16.1,41.8c0-0.2,0.1-0.5,0.3-0.6l1.4-1.4
                     c0.2-0.2,0.4-0.3,0.6-0.3c0.5,0,0.9,0.4,0.9,0.9c0,0.2-0.1,0.5-0.3,0.6l-1.4,1.4c-0.2,0.2-0.4,0.3-0.6,0.3
                     C16.5,42.7,16.1,42.3,16.1,41.8z M18.5,44.3c0-0.2,0.1-0.5,0.3-0.6l1.4-1.4c0.2-0.2,0.4-0.3,0.6-0.3c0.5,0,0.9,0.4,0.9,0.9
                     c0,0.2-0.1,0.5-0.3,0.6L20,44.9c-0.2,0.2-0.4,0.3-0.6,0.3C19,45.2,18.5,44.8,18.5,44.3z M21.9,47.6c-0.5,0-0.9-0.4-0.9-0.9
                     c0-0.2,0.1-0.5,0.3-0.6l1.4-1.4c0.2-0.2,0.4-0.3,0.6-0.3c0.5,0,0.9,0.4,0.9,0.9c0,0.2-0.1,0.5-0.3,0.6l-1.4,1.4
                     C22.3,47.5,22.1,47.6,21.9,47.6z M25.8,48.4h-0.1c-0.2,0-0.4-0.1-0.6-0.2l-0.6-0.5l0.6-0.6c0.2-0.2,0.4-0.5,0.5-0.7l0.7,0.6
                     c0.2,0.2,0.3,0.4,0.3,0.6C26.6,48,26.2,48.4,25.8,48.4z M33,41.1L33,41.1c-0.3,0-0.5-0.1-0.7-0.2l-4.4-3.6l-1,1.2l4.2,3.5
                     c0.2,0.2,0.3,0.4,0.3,0.6c0,0.5-0.4,0.8-0.8,0.8h-0.2c-0.2,0-0.4-0.1-0.5-0.2l-4.4-3.6l-1,1.2l4.2,3.5c0.2,0.2,0.3,0.4,0.3,0.6
                     c0,0.5-0.4,0.8-0.8,0.8c-0.3,0-0.6-0.1-0.8-0.3l-1.8-1.5c-0.4-0.8-1.2-1.4-2.2-1.4c0-1.3-1.1-2.4-2.4-2.4c0-1.3-1.1-2.4-2.4-2.4
                     c0-1.3-1.1-2.4-2.5-2.4c-0.7,0-1.3,0.3-1.8,0.7l-1.4,1.4c-0.1,0.1-0.2,0.2-0.2,0.3l-2.5-1.5l2.2-7.6l5.4-1.2
                     c1.4-0.3,2.9-0.3,4.3,0.2l1.2,0.4l-4,2c-0.9,0.5-1.5,1.4-1.5,2.4v0.2c0,1.5,1.2,2.7,2.7,2.7c0.5,0,1-0.1,1.4-0.4l3-1.8
                     c0.6-0.4,1.4-0.3,1.9,0.2l6.8,6.1c0.2,0.2,0.3,0.4,0.3,0.6C33.9,40.7,33.5,41.1,33,41.1z M48.4,31.8l-13.6,6.8l-0.1-0.1l-6.8-6.1
                     c-0.6-0.5-1.4-0.8-2.2-0.8c-0.6,0-1.2,0.2-1.7,0.5l-3,1.8c-0.2,0.1-0.4,0.2-0.6,0.2c-0.6,0-1.1-0.5-1.1-1.1v-0.2
                     c0-0.4,0.2-0.8,0.6-1l4.5-2.2c0.8-0.4,1.7-0.5,2.5-0.2l5.1,1.4h1.2l15.1-8L48.4,31.8L48.4,31.8z"
                ></path>
                <path
                  d="M21.7,44.5c0.4,0,0.8-0.1,1.3-0.4l0.1-0.1l1,0.7c0.4,0.4,0.9,0.6,1.5,0.6h0.1c1.1,0,2.2-1,2.3-2.2v-0.2h0.2
                     c1.2,0,2.1-1,2.1-2.2v-0.3h0.3c1.2,0,2.2-1,2.2-2.1V38H33c1.2,0,2.2-1,2.2-2.3v-0.4l14.6-7.4V16.8h-1.5l-2.8,1.5l-0.1-0.1
                     c-0.3-0.2-0.5-0.3-0.9-0.4l-3.6-1v-0.7L41,16c1.4-0.9,2.3-2.7,2.3-4.4v-1.4c0-3-2.4-5.5-5.3-5.5h-0.5c-0.1,0-3-0.2-4.6,3
                     c-0.4,0.8-0.6,1.5-0.6,2.4v1.6c0,1.7,0.9,3.5,2.3,4.4l0.1,0.1v0.7l-3.5,0.9l-3.5-0.9v-0.7l0.1-0.1c1.4-0.9,2.3-2.7,2.3-4.4v-1.4
                     c0-3-2.4-5.5-5.3-5.5c-1.5,0-2.9,0.5-3.9,1.5c-1.1,1.1-1.6,2.4-1.6,3.9v1.6c0,1.7,0.9,3.5,2.3,4.4l0.1,0.1V17l-3.5,0.9L15,17v-0.7
                     l0.1-0.1c1.4-0.9,2.3-2.7,2.3-4.4v-1.4c0-0.9-0.2-1.7-0.6-2.4c-0.7-1.1-1.4-1.8-2.1-2.4c-0.1,0-0.2-0.1-0.3-0.2s-0.2-0.1-0.3-0.2H14
                     V5.1c-0.6-0.3-1.6-0.3-1.7-0.3h-0.1c-1.5,0-2.9,0.5-3.9,1.5c-1.1,1.1-1.6,2.4-1.6,3.9v1.6c0,1.7,0.9,3.5,2.3,4.4l0,0.1V17l-2.5,0.7
                     l-4-2.3H0.3v16.3L8,34.2h1.3l0.2-0.8l2.8,1.7v0.1c0,1.2,1,2.1,2.1,2.1h0.3v0.3c0,1.2,1,2.2,2.1,2.2H17V40c0,1.2,1,2.2,2.2,2.2h0.3
                     v0.3C19.5,43.5,20.5,44.5,21.7,44.5z M22.7,43.1c-0.2,0.2-0.5,0.4-0.8,0.4c-0.6,0-1.1-0.5-1.1-1.2c0-0.2,0.1-0.6,0.4-0.8l1.4-1.4
                     c0.2-0.2,0.5-0.4,0.8-0.4c0.6,0,1.2,0.5,1.2,1.2c0,0.3-0.1,0.5-0.4,0.8L22.7,43.1z M25.8,44.3L25.8,44.3c-0.4,0-0.6-0.1-0.8-0.2
                     l-0.9-0.7l1.4-1.7l0.9,0.8c0.3,0.3,0.4,0.5,0.4,0.8C26.8,43.7,26.3,44.3,25.8,44.3z M48.5,17.9V27l0,0l-13.8,7.4l-0.2-0.2l-6.8-6.1
                     c-1-0.8-2.4-1-3.6-0.3l-3,1.8c-0.2,0.1-0.5,0.2-0.7,0.2c-0.7,0-1.4-0.6-1.4-1.3v-0.2c0-0.5,0.3-1,0.7-1.2l4.5-2.2
                     c0.9-0.4,1.9-0.5,2.7-0.2l5,1.4H33L48.5,17.9z M35.7,17.8v-2.2l-0.3-0.1c-1.3-0.7-2.1-2.2-2.1-3.7v-1.6c0-1.2,0.4-2.2,1.3-3.1
                     c0.9-0.9,2.2-1.3,3.4-1.2c2.3,0.1,4.1,2.1,4.1,4.4v1.4c0,1.5-0.9,3-2.1,3.7l-0.3,0.1v2.3l4.4,1.1l-9.8,5.2v-2.6c0-1.1-0.4-2-1.1-2.6
                     l-0.4-0.3L35.7,17.8z M18.7,19l3.8-1.1v-2.3l-0.2-0.1c-1.3-0.7-2.1-2.2-2.1-3.7v-1.6c0-1.2,0.4-2.2,1.3-3.1s2.5-1.3,3.7-1.2
                     c2.3,0.1,4.1,2.1,4.1,4.4v1.4c0,1.5-0.9,3-2.1,3.7l-0.3,0.1v2.3l4.4,1.2c1.2,0.3,2,1.3,2,2.5v3.2l-0.4,0.2h-0.6l-5-1.4
                     c-0.3-0.1-0.7-0.2-1-0.2h-0.1L22.4,22c-1.7-0.5-3.5-0.6-5.1-0.2L16.5,22C16.5,22,16.5,19.2,18.7,19z M10,17.8v-2.3l-0.3-0.1
                     c-1.3-0.7-2.1-2.2-2.1-3.7v-1.6C7.6,8.9,8,7.9,8.9,7s2.2-1.3,3.4-1.2c2.3,0.1,4.1,2.1,4.1,4.4v1.4c0,1.5-0.9,3-2.1,3.7L14,15.6v2.3
                     l3,0.8L16.7,19c-0.7,0.7-1.1,1.7-1.1,2.6v0.8l-3.2,0.8l0.3-0.6v-1.3L8,18.4L10,17.8z M1.4,30.7V16.3h0.9l6,3.5l-3,12.1L1.4,30.7z
                     M8.5,33l-2.2-0.6l2.9-12l2.2,1.4v0.4L8.5,33z M12.7,33.7l-3-1.6l2.3-7.9l5.5-1.2c1.6-0.3,3.1-0.3,4.4,0.2l1.8,0.6L19.3,26
                     c-0.8,0.5-1.4,1.3-1.4,2.2v0.2c0,1.4,1.1,2.4,2.4,2.4c0.5,0,0.9-0.1,1.3-0.3l3-1.8c0.7-0.5,1.6-0.4,2.2,0.2l6.8,6.1
                     c0.3,0.3,0.4,0.5,0.4,0.8c0.1,0.2,0,0.5-0.2,0.8C33.6,36.8,33.3,37,33,37h-0.1c-0.3,0-0.5-0.1-0.7-0.2l-4.3-3.5l-0.7,0.8l4,3.3
                     c0.3,0.3,0.4,0.5,0.4,0.8c0,0.7-0.5,1-1.1,1h-0.2c-0.3,0-0.5-0.1-0.7-0.3l-4.2-3.4l-0.7,0.8l4,3.3c0.3,0.3,0.4,0.5,0.4,0.8
                     c0,0.7-0.5,1-1,1c-0.4,0-0.7-0.1-1-0.4l-1.8-1.6c-0.4-0.8-1.1-1.3-2-1.3H23v-0.3c0-1.2-1-2.1-2.1-2.1h-0.3v-0.3c0-1.2-1-2.2-2.1-2.2
                     h-0.3v-0.3c0-1.2-0.7-1.9-2-1.9c-0.6,0-1.1,0.3-1.6,0.7L12.7,33.7z M14.7,36.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.2,0.1-0.6,0.4-0.8
                     l1.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4c0.6,0,1.1,0.5,1.1,1.1c0,0.2-0.1,0.6-0.4,0.8l-1.4,1.4C15.2,36,15,36.2,14.7,36.2z M17.1,38.5
                     c-0.6,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.6,0.4-0.8l1.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4c0.6,0,1.1,0.5,1.1,1.2c0,0.2-0.1,0.6-0.4,0.8
                     l-1.4,1.4C17.6,38.4,17.4,38.5,17.1,38.5z M18.4,39.9c0-0.2,0.1-0.6,0.4-0.8l1.4-1.4c0.2-0.2,0.5-0.4,0.8-0.4c0.6,0,1.1,0.5,1.1,1.2
                     c0,0.2-0.1,0.6-0.4,0.8l-1.4,1.4C20,40.9,19.8,41,19.5,41C18.9,41,18.4,40.5,18.4,39.9z"
                ></path>
              </svg>
            </div>
            <h3>Long Client Relationships</h3>
            <p>
              Whether a single job or
              <span
                class="tou-node"
                id="tou-0-acdd06e9-18c5-4ef8-845c-36b27e3fe30f"
              ></span>
              of projects, we want to construct a long lasting Client
              Relationship with a mutual support
              <span
                class="tou-node"
                id="tou-0-189cfa87-fc66-4dbf-b93c-36c21eb83205"
              ></span
              >.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
          <div class="our-core-value-box rounded10">
            <div class="our-core-value-icon rounded10">
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                style="enable-background: new 0 0 50 50"
                xml:space="preserve"
              >
                <path
                  d="M49.2,24.2h-1.9C46.8,12.5,37.4,3.1,25.8,2.7V0.8C25.8,0.4,25.4,0,25,0c-0.4,0-0.8,0.4-0.8,0.8v1.9
                     c-11.6,0.4-21,9.8-21.4,21.5H0.8C0.4,24.2,0,24.6,0,25c0,0.4,0.4,0.8,0.8,0.8h1.9c0.1,1.8,0.4,3.5,0.8,5.2c0.5-0.3,1-0.5,1.5-0.7
                     c-0.4-1.4-0.6-2.9-0.7-4.5h1.9c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H4.4C4.8,13.4,13.5,4.7,24.2,4.3v1.9
                     C24.2,6.7,24.6,7,25,7c0.4,0,0.8-0.4,0.8-0.8V4.3c10.8,0.4,19.4,9.1,19.8,19.9h-1.9c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8
                     h1.9c-0.4,10.8-9.1,19.5-19.8,19.9v-1.9c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v1.9c-1.6-0.1-3.1-0.3-4.6-0.7
                     c-0.2,0.5-0.4,1-0.7,1.5c1.7,0.5,3.5,0.8,5.3,0.9v1.9c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-1.9c11.6-0.4,21-9.8,21.4-21.5
                     h1.9c0.4,0,0.8-0.4,0.8-0.8C50,24.6,49.6,24.2,49.2,24.2z"
                ></path>
                <g>
                  <g>
                    <g>
                      <circle cx="0.5" cy="49.5" r="0.5"></circle>
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle cx="13.8" cy="36.2" r="0.5"></circle>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M20.6,29.6c-0.4-0.4-1-0.6-1.6-0.6c-0.6,0-1.1,0.2-1.6,0.6c-0.9,0.9-0.9,2.3,0,3.1c0.4,0.4,1,0.6,1.6,0.6
                              c0.6,0,1.1-0.2,1.6-0.6C21.5,31.9,21.5,30.5,20.6,29.6z M19.9,32c-0.5,0.5-1.2,0.5-1.7,0c-0.5-0.5-0.5-1.2,0-1.7
                              c0.2-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3C20.3,30.8,20.3,31.5,19.9,32z"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M6.5,44.8c-0.3-0.1-0.6,0-0.7,0.2l-0.6,1.2c-0.3,0.5-0.8,0.9-1.4,0.9l-0.7,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2
                              l0-0.7c0-0.6,0.4-1.1,0.9-1.4l1.2-0.6c0.3-0.1,0.4-0.4,0.2-0.7c-0.1-0.3-0.4-0.4-0.7-0.2l-1.2,0.6c-0.9,0.4-1.5,1.3-1.5,2.3
                              l0,0.7c0,0.4,0.1,0.8,0.4,1c0.3,0.3,0.6,0.4,1,0.4c0,0,0.1,0,0.1,0l0.7,0c1-0.1,1.8-0.6,2.3-1.5l0.6-1.2
                              C6.8,45.3,6.7,45,6.5,44.8z"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M26.1,23.9c-0.2-0.2-0.6-0.4-0.9-0.3l-0.5,0c-4.3,0.3-8.4,2.4-11.2,5.8l-1.1,1.4l-3-0.1c0,0,0,0,0,0
                              c-2.4,0-4.6,0.9-6.3,2.6l-3.1,3.1C0,36.5-0.1,36.7,0,36.9c0.1,0.2,0.3,0.3,0.5,0.3l6.5,0.3l-0.3,0.3c-0.1,0.2-0.2,0.4-0.1,0.6
                              c0.1,0.2,0.2,0.4,0.3,0.6l-1.5,1.8c-0.1,0.2-0.2,0.4-0.1,0.6c0.3,0.7,0.8,1.3,1.3,1.9c0.6,0.6,1.2,1,1.9,1.4
                              c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1c0.3-0.3,0.7-0.6,1.1-0.9c0.3-0.2,0.5-0.4,0.8-0.7c0.1,0.1,0.3,0.2,0.5,0.3
                              c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1l0.4-0.3l0.3,6.5c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.2
                              l3.1-3.1c1.7-1.7,2.6-3.9,2.6-6.3v-2.9l1.2-0.9c3.6-2.8,5.8-7,6-11.6l0-0.3C26.5,24.5,26.4,24.1,26.1,23.9z M1.7,36.2L3.9,34
                              c1.5-1.5,3.4-2.3,5.5-2.3l2.2,0.1l-3.9,4.8L1.7,36.2z M9.5,42.9c-0.3,0.2-0.6,0.5-0.8,0.7c-0.5-0.3-1-0.6-1.3-1
                              c-0.4-0.4-0.7-0.8-1-1.3l1.1-1.3c0.3,0.5,0.7,0.9,1.1,1.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.4,0.4,0.9,0.9,1.4,1.2
                              C9.9,42.6,9.7,42.7,9.5,42.9z M18.2,40.5c0,2.1-0.8,4.1-2.3,5.5l-2.2,2.2l-0.3-6.1l4.8-3.8L18.2,40.5L18.2,40.5z M25.4,25
                              c-0.2,4.3-2.3,8.2-5.6,10.9l-8.2,6.3c-0.7-0.4-1.3-0.9-1.9-1.4l2.8-2.8c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0L9,40.1
                              c-0.5-0.6-1-1.2-1.3-1.8l6.7-8.3c2.6-3.2,6.4-5.1,10.4-5.4l0.5,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0.1,0,0.1L25.4,25z"
                      ></path>
                    </g>
                  </g>
                </g>
                <path
                  d="M25,15.3c-5.3,0-9.6,4.3-9.6,9.7c0,0.4,0,0.8,0.1,1.2c0.5-0.4,0.9-0.8,1.4-1.2c0-4.5,3.7-8.1,8.1-8.1c4.5,0,8.1,3.7,8.1,8.2
                     c0,4.4-3.6,8.1-8,8.2c-0.4,0.5-0.8,1-1.2,1.4c0.3,0,0.7,0.1,1,0.1c5.3,0,9.6-4.3,9.6-9.7S30.3,15.3,25,15.3z"
                ></path>
              </svg>
            </div>
            <h3>Client Focussed Delivery</h3>
            <p>
              We ensure no two projects are the same, ensuring our Clients
              <span
                class="tou-node"
                id="tou-0-df6674b6-f352-4642-ac84-af1291daa381"
              ></span>
              and aspirations are met through Customer Focused Delivery
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.our-core-value-box {
  background-color: #fff;
  border: 1px solid #dddfe1;
  padding: 20px 20px;
  min-height: 310px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.innost0 {
  display: none;
}
.innost1 {
  display: inline;
}

.seemst0 {
  display: none;
}
.seemst1 {
  display: inline;
}
.seemst2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.longst0 {
  display: none;
}


.our-core-value-icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border: 1px solid #dddfe1;
	margin-bottom: 20px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.our-core-value-icon svg {
	width: 48px;
	height: 48px;
    fill: #1977cc;
}
.our-core-value-box h3, .our-core-value-box h5 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 15px;
}

.our-core-value-box:hover{
      -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.our-core-value-box:hover .our-core-value-icon{
    background: #1977cc;
}
.our-core-value-box:hover .our-core-value-icon svg{
    fill: #fff;
}

</style>
