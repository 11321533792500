<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/nodejs-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						The Bespoke NodeJS development company
					</h2>
					<p>
						We have consistently created lightweight, high speed web applications that are fully scalable and flexible. Open source, cross platform frameworks come with a challenge – all applications developed on open source frameworks may appear to be the same, without major differentiators. At GenixBit Labs, we possess the expertise and proven experience in Node JS Development solutions that are superior. This is possible largely as a result of our ability to use functions and features intelligently and in a manner that makes a difference.
					</p>
					<p>
						The V8 engine for instance, is high speed and our experience helps us to create applications that write the file system at superior speeds. Our NodeJS Development Solutions have helped businesses achieve scalability easily through the use of event loop, which is one of the best functional features of NodeJS.
					</p>
					<p>
						Real time applications involve a different area of expertise. Chats and similar applications in websites demand two way communication channels, and Node JS development offers superior open 2 way communications through Web Sockets. The challenge is in integrating the channel and delivering solutions that work seamlessly.
					</p>
					<p>
						If you're planning to build a feature-rich NodeJS Application and want to discuss the challenges of converting your idea into an innovation. Get in touch with us, the experts at GenixBit Labs will help you with all your queries.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
