<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Multi-functional</span>
				<h2 class="fxt-process-dev">Develop one fantasy sports app that plays many sports</h2>
				<p>
					Fantasy Cricket App Development is time-consuming and requires resources. Why waste too
					much of both when fantasy sports app developers can build one fantasy sports all that has
					multiple sports within it,
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../../IndustrySolutions/AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink: '/football-solution.webp',
					title: 'Football',
					text: 'Build a fantasy sports app that lets users to put up a virtual team of world-class soccers handpicked from various teams and clubs.'
				},

				{
					imageLink: '/tennis-solution.webp',
					title: 'Tennis',
					text: 'The league of extraordinary players who whack it off at ATP and WTA tournaments brought together in a single fantasy sport for tennis.'
				},

				{
					imageLink: '/cricket-solution.webp',
					title: 'Cricket',
					text: '11 players play a game of cricket. With a cricket fantasy sports app, players can choose which are those 11 players and which teams they will play for.'
				},

				{
					imageLink: '/basketball-solution.webp',
					title: 'Basketball',
					text: 'Build a fantasy basketball app with super league teams from around the world. Host contests, manage tournaments and give out rewards.'
				},
				{
					imageLink: '/golf-solution.webp',
					title: 'Golf',
					text: 'Assemble a virtual team of real-world golf players whose points will be evaluated based on their performance in the tournament. The app can also create contests based on major golf championships.'
				},
				{
					imageLink: '/hockey-solution.webp',
					title: 'Hockey',
					text: 'Create a fantasy hockey app that can create a league of its own or a team of its own to host matches. Individual players or teams can compete with each other to find the victor for major championships.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
