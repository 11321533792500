<template>
	<section class="padding-60 bg-white web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Answers for You</span>
				<h2>FAQ's on Taxi App Development Solutions</h2>
			</div>
			<div>
				<FaqCard
					v-bind:obj="item"
					v-for="item in items"
					v-bind:key="item.title"
					class="col-lg-10 col-12 text-center"
					style="padding: 0; margin:15px auto"
				></FaqCard>
			</div>
		</div>
	</section>
</template>

<script>
import FaqCard from '../AssetComponents/FaqCard.vue';
export default {
	components: {
		FaqCard
	},
	data() {
		return {
			items: [
				{
					class: 'option1',
					question: 'Can you Customise The App As Per Our Business Model?',
					answer:
						'Yes, being a white label taxi booking solution, the app can be customised extensively to meet the branding requirements of your business.'
				},
				{
					class: 'option2',
					question: 'How Much Time Does it Take to Develop Uber Like Taxi Booking App?',
					answer:
						'The application can be launched into the market within 6-8 weeks with you branding changes. Depending on the level of customization requirement and additional features desired, the delivery period would vary accordingly. Let us know ycomplete requirements and we will chart out a definite timeline of taxi app development.'
				},
				{
					class: 'option3',
					question: 'How Much Does The Taxi App Solution Cost?',
					answer:
						'Our taxi app development pricing. is very competitive and flexible. The exact pricing can be intimated depending on the customization and features involved.'
				},
				{
					class: 'option4',
					question: 'Will You Provide Unique Branding for Our Application?',
					answer:
						'Yes, we do. From logo placement to color theme, our taxi app developers will help you customize every pixel of the taxi app.'
				},
				{
					class: 'option5',
					question: 'Can I Develop An On-demand Taxi Booking App for My Private Taxi Business?',
					answer:
						'Yes, taxi booking solutions are not limited to any size of business. You can use it for your local business, within your city, corporate offices, airport, schools or anywhere else.'
				},
				{
					class: 'option6',
					question: 'Can you Develop The Application in Multi-Language?',
					answer:
						'Yes. with the help of language predefined language packs, Our taxi app developers will help you build an app that speaks the user’s language.'
				},
				{
					class: 'option7',
					question: 'Will I Have Access to The Source Code After The Product is Launched?',
					answer:
						'Yes absolutely. You are the owner of the taxi booking solutions once the contract is signed and application is deployed on the marketplace. You will granted all access to the code as well as the database.'
				},
				{
					class: 'option8',
					question: 'If There is An Error in The App After Launch, How Will That be Resolved?',
					answer:
						'We offer post-deployment maintenance to all of our taxi app development solutions. Our taxi app developers team will offer prompt solutions for all kinds of minor and major issues in your timezone.'
				},
				{
					class: 'option9',
					question: 'Why Should I Hire GenixBit Labs for Developing a Taxi Booking App Solution?',
					answer:
						'Timely delivery. Competitive pricing. Proven expertise. And still many more reasons that makes us best taxi app development company. Talk to our experts to get a complete picture of our credentials.'
				},
				{
					class: 'option10',
					question: '>How Can I Get in Touch With, If I Have Any Questions?',
					answer:
						'Our Managing Director, Oliver is always there to help the entrepreneurs who want to enhance the taxi booking business.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
</style>
