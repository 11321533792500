<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>Industries We Serve</span>
				<h2 class="fxt-bespoke-dev">Bespoke Solutions For Every Industry</h2>
				<p>
					Every industry, every business is as unique as a fingerprint. Bulk produced or cookie cut
					software does not further the business objectives. What your business needs is focused and
					bespoke digital solutions for diverse use cases. GenixBit Labs can help with that.
				</p>
			</div>
			<div class="row">
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M24.1,3.3h5.6v-1h-5.6V3.3z M26.8,35.1c-0.8,0-1.4,0.6-1.4,1.2c0,0.7,0.6,1.2,1.4,1.2c0.8,0,1.4-0.6,1.4-1.2
                        C28.2,35.7,27.6,35.1,26.8,35.1z M42,30l-1-0.7l1.3-1.4c1-1,0.9-2.5-0.3-3.4l-1.2-0.9c-0.1-0.1-0.1-0.1-0.1-0.1l1.6-1.6
                        c1-1,0.9-2.5-0.3-3.4l-1.2-0.9c-0.3-0.3-0.8-0.5-1.3-0.5v-15C39.5,1,38.3,0,36.8,0H16.9c-1.5,0-2.7,1.1-2.7,2.3V13
                        c-0.5-0.4-1.1-0.8-1.9-1.1c-1.4-0.5-3.3-0.2-4.4,0.8c-1,0.8-1.2,1.9-0.8,3.1c0.3,0.7,0.6,1.4,0.8,2.1c0.9,3.3,1.1,6.8,0.5,9.5
                        l-0.2,0.8C7.5,32,6.7,35.6,8,38.3c1.2,2.4,2.7,3.6,4,4.6l0.1,0.1c0.3,0.2,0.3,0.6,0.2,0.8l-1.2,2.8l17,3.5l0.9-2
                        c0.1-0.3,0.3-0.4,0.6-0.5c0.9-0.1,2.2-0.5,3.7-1.8c1.8-1.6,3.4-4.5,4.1-5.7c0.2,0,0.5,0.1,0.8,0.1c0.8,0,1.6-0.3,2.1-0.9l1.2-1.3
                        c0.5-0.5,0.7-1.1,0.6-1.8c-0.1-0.7-0.4-1.2-1-1.6l-0.1-0.1l1.3-1.4C43.4,32.3,43.3,30.8,42,30z M40.9,25.7c0.5,0.3,0.6,1,0.2,1.4
                        l-1.5,1.6l-0.7,0.7c-0.1,0.1-0.2,0.2-0.4,0.3l-0.2,0h-0.2l-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-1.2-0.9c-0.2-0.2-0.3-0.4-0.4-0.7
                        c0-0.2,0.1-0.4,0.2-0.6l2-2.1c0.1,0,0.2,0,0.3,0h0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.1,0,0.2,0.1
                        L40.9,25.7z M38.8,18.7H39c0.2,0,0.5,0.1,0.7,0.2l1.3,0.9c0.5,0.4,0.5,1,0.1,1.3l-2.2,2.3l-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2
                        c-0.1,0-0.1,0-0.1,0l-0.5,0c-0.2,0-0.3-0.1-0.5-0.2l-1.2-0.9c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.2,0.1-0.4,0.2-0.6l2.2-2.3
                        C38.3,18.9,38.6,18.7,38.8,18.7z M15.8,2.3c0-0.5,0.5-0.9,1-0.9h20.1c0.6,0,1,0.4,1,0.9V4H15.8V2.3z M15.8,5.5h22.1v11.9
                        c-0.3,0.2-0.7,0.4-1,0.7l-1.6,1.6c-0.1-0.9-0.9-1.6-2-1.6H33l-1.3-1.5c-0.5-0.6-1.2-0.9-1.9-0.9h-3.4c-0.6,0-1.2,0.2-1.7,0.6
                        l-2,1.8h-2.3c-1.1,0-2,0.8-2,1.8v1.5h1.9c0-0.1,0-0.1,0-0.2c0-0.9,0.9-1.7,1.9-1.7c1,0,1.9,0.8,1.9,1.7c0,0.1,0,0.1,0,0.2h5.8
                        c0-0.1,0-0.1,0-0.2c0-0.9,0.9-1.7,1.9-1.7c1,0,1.9,0.8,1.9,1.7c0,0.1,0,0.1,0,0.2h0.6c-0.1,0.2-0.1,0.5-0.1,0.7
                        c0.1,0.7,0.3,1.3,1,1.7l1.2,0.9c0,0,0.1,0,0.1,0l-1.5,1.6c-0.5,0.5-0.7,1.1-0.6,1.8c0,0.1,0,0.1,0,0.1c0.1,0.7,0.4,1.2,1,1.6
                        l0.9,0.7l-1.3,1.3c-0.5,0.5-0.6,1-0.6,1.6H15.8V5.5z M32.1,44.8c-1.1,1-2,1.3-2.7,1.4c-0.9,0.1-1.6,0.6-2,1.4l-0.3,0.9l-13.7-2.9
                        l0.5-1.2c0.3-0.9,0.1-1.8-0.6-2.4l-0.1-0.1c-1.3-1-2.6-2.1-3.6-4.1C8.5,35.4,9.2,32,10,28.5l0.2-0.8c0.6-2.9,0.4-6.7-0.5-10.1
                        c-0.2-0.8-0.5-1.6-0.9-2.3c-0.2-0.6-0.1-1.1,0.3-1.5c0.6-0.6,1.8-0.8,2.6-0.5c2.3,0.9,2.5,2.7,2.5,2.9v21.5c0,1.3,1.2,2.3,2.7,2.3
                        h18.8C35,41.2,33.6,43.5,32.1,44.8z M35.7,36c-0.5,0.5-0.7,1.1-0.6,1.8c0,0,0,0,0,0.1c0,0.2,0.1,0.5,0.2,0.7H16.9
                        c-0.6,0-1-0.4-1-0.9v-3.1h18.7c0.1,0.2,0.3,0.3,0.5,0.5l0.9,0.7L35.7,36z M40.2,37.2L39,38.5c-0.2,0.3-0.6,0.4-0.9,0.4l-0.5-0.3
                        c-0.1,0-0.1,0-0.2-0.1l-0.3-0.2c-0.2-0.1-0.3-0.4-0.4-0.7c0-0.2,0.1-0.4,0.2-0.6l0.6-0.6h0.3c0,0,0,0,0.1,0h0.3c0.2,0,0.3,0,0.5,0
                        c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2
                        c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1l0.1,0.1c0.2,0.2,0.3,0.4,0.4,0.7C40.5,36.8,40.4,37,40.2,37.2z M41.1,32.4L39.5,34
                        l-0.7,0.7c-0.1,0.1-0.2,0.2-0.3,0.2l-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1l-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l-1.2-0.9
                        c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.2,0.1-0.4,0.2-0.6l1.6-1.6h0.3c0,0,0,0,0.1,0h0.2c0.1,0,0.1,0,0.1,0c0.2,0,0.3,0,0.4,0
                        c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0.2,0,0.3-0.2,0.4-0.3c0.1,0,0.1,0,0.1-0.1
                        s0.1,0,0.2-0.1l1,0.8C41.4,31.4,41.5,32,41.1,32.4z M31.6,19.9c-0.8,0-1.5,0.6-1.5,1.3c0,0.7,0.7,1.3,1.5,1.3
                        c0.8,0,1.5-0.6,1.5-1.3C33.1,20.5,32.4,19.9,31.6,19.9z M22.1,19.9c-0.8,0-1.5,0.6-1.5,1.3c0,0.7,0.7,1.3,1.5,1.3
                        c0.8,0,1.5-0.6,1.5-1.3C23.6,20.5,22.9,19.9,22.1,19.9z"
								></path>
							</svg>
						</div>
						<h4>Taxi Booking</h4>
						<p>
							Ride the on-demand wave with a taxi booking app complete with real-time navigation,
							payment integrations and much more.
						</p>
						<router-link
							to="/taxi-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M41.9,34.6c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4s4-1.8,4-4C46,36.4,44.2,34.6,41.9,34.6z M41.9,41
                        c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4C44.4,39.9,43.3,41,41.9,41z M11.3,34.6c-2.2,0-4,1.8-4,4
                        c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C15.3,36.4,13.5,34.6,11.3,34.6z M11.3,41c-1.3,0-2.4-1.1-2.4-2.4c0-1.3,1.1-2.4,2.4-2.4
                        c1.3,0,2.4,1.1,2.4,2.4C13.7,39.9,12.6,41,11.3,41z M45.2,27.4h-3.2v-5.3c0.5,0.3,1,0.4,1.6,0.4h1.6c0.4,0,0.8-0.4,0.8-0.8V17
                        c0-0.4-0.4-0.8-0.8-0.8h-1.6c-0.6,0-1.1,0.2-1.6,0.4v-0.4c0-0.4-0.4-0.8-0.8-0.8h-0.8v-3.2h-1.6v3.2h-0.8c-1.9,0-3.6,1.4-4,3.2
                        h-4.1v-3.2c0-0.2-0.1-0.4-0.2-0.6L28.8,14c1.3-0.7,2.3-2,2.6-3.5h0.1c0.4,0,0.8-0.4,0.8-0.8c0-3.1-2.5-5.6-5.6-5.6S21,6.7,21,9.8
                        c0,0.4,0.4,0.8,0.8,0.8h0.1c0.2,1,0.7,2,1.4,2.7c-2.3,0.7-3.9,2.8-3.9,5.3v5.6c0,1.6,0.7,3,1.7,4h-2.2l-1.1-1.1V14.6v-3.2
                        c0-0.4-0.4-0.8-0.8-0.8H0.8c-0.4,0-0.8,0.4-0.8,0.8v3.2v9.6c0,2.1,1.6,3.8,3.7,4c-0.3,0.5-0.5,1-0.5,1.6c0,0.6,0.2,1.1,0.5,1.6
                        L1,34l1.1,1.1l2.6-2.6c0.5,0.3,1,0.5,1.6,0.5h0.4c-1.2,1-2.2,2.4-2.5,4H0.8C0.4,37,0,37.4,0,37.8V41c0,0.4,0.4,0.8,0.8,0.8h4.1
                        c1.2,2.4,3.6,4,6.5,4c3.4,0,6.3-2.4,7.1-5.6h16.4c0.7,3.2,3.6,5.6,7.1,5.6c4,0,7.3-3.2,7.3-7.2c0-2.3-1.1-4.3-2.7-5.6h2.7
                        c0.4,0,0.8-0.4,0.8-0.8C50,29.6,47.8,27.4,45.2,27.4z M43.5,17.8h0.8V21h-0.8c-0.9,0-1.6-0.7-1.6-1.6S42.7,17.8,43.5,17.8z
                        M1.6,40.2v-1.6h2.5c0,0.6,0.1,1.1,0.2,1.6H1.6z M28.8,12.1c-0.3-0.1-0.6-0.4-0.6-0.8h-1.6c0,0.6,0.2,1.1,0.6,1.5
                        C27,13,26.8,13,26.6,13c-1.5,0-2.8-1-3.1-2.4h6.2C29.6,11.2,29.2,11.7,28.8,12.1z M22.7,9c0.4-1.8,2-3.2,4-3.2
                        c1.9,0,3.6,1.4,4,3.2H22.7z M1.6,12.2h14.5v1.6H1.6V12.2z M4,26.6c-1.3,0-2.4-1.1-2.4-2.4v-8.8h14.5v11.2H6.5H4z M6.5,31.4
                        c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h10.2l1.4,1.4c0.2,0.2,0.4,0.2,0.6,0.2H25h3.2c0.4,0,0.8,0.4,0.8,0.8
                        c0,0.4-0.4,0.8-0.8,0.8H6.5z M29,32.9v2.6h-7.7L18.9,33h9.3C28.5,33,28.8,33,29,32.9z M11.4,44.2c-3.1,0-5.6-2.5-5.6-5.6
                        c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C17,41.7,14.5,44.2,11.4,44.2z M34.7,38.6H18.6c0-2.3-1.1-4.3-2.7-5.6h0.7l3.8,3.8
                        c0.2,0.2,0.4,0.2,0.6,0.2h8.9h3.2h1.6c0.1,0,0.1,0,0.2,0C34.7,37.5,34.7,38.1,34.7,38.6z M35.5,34.6c0,0.4-0.4,0.8-0.8,0.8h-0.8
                        v-4.8c0-3.1-2.5-5.6-5.6-5.6v-1.6h7.3V34.6z M35.5,21.8h-8.1c-0.4,0-0.8-0.4-0.8-0.8v-3.2H25V21c0,1,0.7,1.9,1.6,2.3v2.6
                        c0,0.4,0.4,0.8,0.8,0.8h0.8c2.2,0,4,1.8,4,4v4.8h-1.6v-4.8c0-1.3-1.1-2.4-2.4-2.4H25c-2.2,0-4-1.8-4-4v-5.6c0-2.2,1.8-4,4-4h2.1
                        l1.1,1.1v3.7c0,0.4,0.4,0.8,0.8,0.8h6.5c0.4,0,0.8,0.4,0.8,0.8C36.3,21.4,35.9,21.8,35.5,21.8z M47.6,38.6c0,3.1-2.5,5.6-5.6,5.6
                        c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6C45,33,47.6,35.5,47.6,38.6z M41.9,31.4c-1.9,0-3.6,0.7-4.8,1.9V22.8
                        c0.5-0.4,0.8-1.1,0.8-1.8c0-1.3-1-2.3-2.3-2.4c0.3-0.9,1.2-1.6,2.3-1.6h2.4v11.2c0,0.4,0.4,0.8,0.8,0.8h4c1.5,0,2.8,1,3.1,2.4
                        H41.9z M9.2,23.2c0.3,0.4,0.6,0.7,0.9,1c1,1,1.7,1.5,2.2,1.5c0.3,0,0.5-0.2,0.6-0.3c0.7-0.7,0.1-1.5-1.2-2.7
                        c-0.3-0.3-0.6-0.6-1-0.9c0.1,0,0.2-0.1,0.3-0.1c0.7-0.2,1.4-0.4,2-1.1c0.9-0.9,1.7-2.5,1.1-4.1c0-0.1-0.2-0.2-0.3-0.3
                        c-0.1,0-0.3,0-0.4,0.1l-3.9,3.9c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.3-0.2c0-0.1,0-0.2,0-0.3c0.1-0.4,0.2-1.2-0.5-1.9
                        c-0.8-0.8-2.2-2-2.3-2.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.6,0.2-1.4,1c-0.8,0.8-1,1.3-1,1.4c0,0.1,0,0.3,0.1,0.4
                        c0.1,0.1,1.6,1.7,2.1,2.2c0.4,0.4,0.8,0.6,1.3,0.6c0.2,0,0.4,0,0.6-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1
                        c-0.3,0.3-0.7,0.7-1.2,1.1c-1.4,1.4-2,2.2-1.4,2.9c0.1,0.1,0.3,0.3,0.7,0.3c0.5,0,1.2-0.4,2.2-1.5C8.6,24,9,23.6,9.2,23.2z
                        M11.1,23.2c1.2,1.2,1.2,1.5,1.2,1.6c0,0,0,0,0,0c-0.1,0-0.4-0.1-1.6-1.2c-0.3-0.3-0.7-0.7-1-1.1c0.1-0.1,0.2-0.2,0.2-0.3
                        C10.3,22.5,10.8,22.9,11.1,23.2z M7.3,20.6c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.4,0-0.7-0.3c-0.4-0.4-1.5-1.5-1.9-2
                        c0,0,0.1-0.1,0.1-0.1l1.5,1.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.4,0-0.6l-1.5-1.5c0,0,0,0,0.1-0.1
                        c0,0,0.1-0.1,0.1-0.1l1.5,1.5C6.7,19,6.8,19,6.9,19c0.1,0,0.2,0,0.3-0.1c0.2-0.2,0.2-0.4,0-0.6l-1.5-1.5c0,0,0.1-0.1,0.1-0.1
                        c0.4,0.4,1.4,1.3,2,1.9c0.4,0.4,0.3,0.7,0.3,1.1c-0.1,0.3-0.1,0.7,0.2,1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0
                        c-0.1-0.1-0.1-0.2-0.2-0.2C8.1,20.5,7.7,20.5,7.3,20.6z M6,25c0,0,0,0-0.1-0.1c0-0.1,0-0.4,1.4-1.7c1.1-1.1,1.8-1.7,2.3-2
                        c0.3-0.2,0.4-0.3,0.6-0.5l3.4-3.3c0.1,1.3-0.7,2.2-1,2.5c-0.5,0.5-1.1,0.7-1.7,0.9c-0.5,0.2-1.1,0.3-1.4,0.7
                        C9.2,21.7,9,22,8.8,22.3c-0.3,0.5-0.7,1-1.2,1.5C6.5,24.9,6.1,25,6,25z"
								></path>
							</svg>
						</div>
						<h4>Food Delivery</h4>
						<p>
							Building a thriving business by connecting hungry customers to restaurants with a
							custom-built food delivery app.
						</p>
						<router-link
							to="/food-delivery-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M29.8,7.3H35c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.4-0.8,0.8
                        C29,7,29.3,7.3,29.8,7.3z M29.8,11.7H35c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.4-0.8,0.8
                        C29,11.4,29.3,11.7,29.8,11.7z M29.8,16.1H35c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.3-0.8,0.8
                        C29,15.8,29.3,16.1,29.8,16.1z M29.8,20.5H35c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.3-0.8,0.8
                        C29,20.2,29.3,20.5,29.8,20.5z M29.8,24.9H35c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.4-0.8,0.8
                        C29,24.6,29.3,24.9,29.8,24.9z M29.8,29.3H35c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.3-0.8,0.8
                        C29,28.9,29.3,29.3,29.8,29.3z M29.8,33.7H35c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.4-0.8,0.8
                        C29,33.3,29.3,33.7,29.8,33.7z M49.2,48.4h-2.9v-3.2c1.7-0.4,3-1.9,3-3.7c0-1.3-0.7-2.4-1.7-3.1c1-0.7,1.7-1.8,1.7-3.1
                        c0-2.1-1.7-3.8-3.9-3.8c-2.1,0-3.9,1.7-3.9,3.8c0,1.3,0.7,2.4,1.7,3.1c-1,0.7-1.7,1.8-1.7,3.1c0,1.9,1.4,3.4,3.2,3.7v3.1h-4.3V0.8
                        c0-0.4-0.4-0.8-0.8-0.8H24.9c-0.4,0-0.8,0.4-0.8,0.8v21.1c-11.4,6.7-14,8.4-14.7,9c-0.1,0-0.1,0.1-0.1,0.1
                        c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0v17H5.1v-3.2c1.7-0.4,3-1.9,3-3.7
                        c0-1.3-0.7-2.4-1.7-3.1c1-0.7,1.7-1.8,1.7-3.1c0-2.1-1.7-3.8-3.9-3.8s-3.9,1.7-3.9,3.8c0,1.3,0.7,2.4,1.7,3.1
                        c-1,0.7-1.7,1.8-1.7,3.1c0,1.9,1.4,3.4,3.2,3.7v3.1H0.8c-0.4,0-0.8,0.4-0.8,0.8C0,49.6,0.4,50,0.8,50h48.4c0.4,0,0.8-0.4,0.8-0.8
                        C50,48.8,49.6,48.4,49.2,48.4z M2,35.3C2,34,3,33,4.3,33s2.3,1,2.3,2.2c0,1.2-1,2.2-2.3,2.2S2,36.5,2,35.3z M2,41.5
                        c0-1.2,1-2.2,2.3-2.2s2.3,1,2.3,2.2c0,1.2-1,2.2-2.3,2.2S2,42.8,2,41.5z M20.7,48.4h-6.2v-6.1h6.2V48.4z M24.1,48.4h-1.8v-6.9
                        c0-0.4-0.4-0.8-0.8-0.8h-7.8c-0.4,0-0.8,0.3-0.8,0.8v6.9h-2.3V32.2h13.5V48.4z M24.1,30.7L24.1,30.7H12.4c2.8-1.7,7.8-4.7,11.7-7
                        V30.7z M35.5,48.4h-6.2v-6.1h6.2V48.4z M38.8,48.4h-1.8v-6.9c0-0.4-0.4-0.8-0.8-0.8h-7.8c-0.4,0-0.8,0.3-0.8,0.8v6.9h-2V38h13.2
                        V48.4z M38.8,36.4H25.7V1.6h13.2V36.4z M43.2,35.3c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,1.2-1,2.2-2.3,2.2
                        C44.2,37.5,43.2,36.5,43.2,35.3z M43.2,41.5c0-1.2,1-2.2,2.3-2.2c1.3,0,2.3,1,2.3,2.2c0,1.2-1,2.2-2.3,2.2
                        C44.2,43.8,43.2,42.8,43.2,41.5z"
								></path>
							</svg>
						</div>
						<h4>Real Estate</h4>
						<p>
							Architect a new way of buying, renting, selling or trading real estate properties with
							a real estate aggregator app.
						</p>
						<router-link
							to="/real-estate-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M36.6,37.4c-0.5,0-0.8,0.3-0.8,0.8v8.2c0,1.1-0.9,2.1-2.1,2.1H3.6c-1.2,0-2.1-0.9-2.1-2.1V3.6
                        c0-1.2,0.9-2.1,2.1-2.1h30.2C35,1.5,36,2.4,36,3.6v4.1c0,0.5,0.3,0.8,0.8,0.8c0.5,0,0.8-0.3,0.8-0.8V3.6c0-2-1.6-3.6-3.6-3.6H3.6
                        C1.6,0,0,1.6,0,3.6v42.8c0,2,1.6,3.6,3.6,3.6h30.2c2,0,3.6-1.6,3.6-3.6v-8.2C37.4,37.8,37.1,37.4,36.6,37.4z M32.6,8.9V6.6
                        c0-0.5-0.3-0.8-0.8-0.8H5.5c-0.5,0-0.8,0.3-0.8,0.8v33.6c0,0.5,0.3,0.8,0.8,0.8h26.3c0.5,0,0.8-0.3,0.8-0.8v-2
                        c0-0.5-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v1.2H6.3v-32h24.8v1.6c0,0.5,0.3,0.8,0.8,0.8S32.6,9.3,32.6,8.9z M21.9,4.4
                        c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-6.4c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8H21.9z M15.9,44.7
                        c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7C17.1,42,15.9,43.2,15.9,44.7z M20,44.7
                        c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.3-0.5-1.3-1.2c0-0.7,0.5-1.2,1.2-1.2C19.3,43.5,20,44,20,44.7z M25.6,43.9
                        c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h3.9c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H25.6z M7.9,43.9
                        c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h3.9c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H7.9z M31.6,30.4l0.5-2.2
                        c0.1-0.4-0.2-0.8-0.6-0.9c-0.4-0.1-0.8,0.2-0.9,0.6l-0.3,1.6H16.1l-1-3.2h15.3c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H14.6
                        l-0.9-3h16.8c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H13.3l-1.7-5.5c-0.1-0.3-0.4-0.5-0.8-0.5h-3c-0.5,0-0.8,0.3-0.8,0.8
                        c0,0.5,0.3,0.8,0.8,0.8h2.4l4.5,14.8l0.6,2c-0.7,0.5-1.2,1.3-1.2,2.3c0,1.5,1.2,2.7,2.7,2.7c1.2,0,2.3-0.8,2.6-2h5.1
                        c0.3,1.1,1.4,2,2.6,2c1.2,0,2.3-0.8,2.6-2h0.7c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-0.7c-0.3-1.1-1.4-2-2.6-2
                        c-1.2,0-2.3,0.8-2.6,2h-5.1c-0.3-1.1-1.4-2-2.6-2h-0.1l-0.3-1h14.4C31.2,31,31.6,30.8,31.6,30.4z M27.2,33.5
                        c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C26,34,26.5,33.5,27.2,33.5z M16.9,33.5
                        c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C15.7,34,16.2,33.5,16.9,33.5z M49.3,14h-2.9v-0.4
                        c0-3.2-2.2-5.9-5-5.9c-2.8,0-5,2.7-5,5.9V14h-2.9c-0.5,0-0.8,0.3-0.8,0.8V27c0,1.1,0.8,1.9,1.9,1.9h13.4c1.1,0,1.9-0.8,1.9-1.9
                        V14.8C50.1,14.3,49.8,14,49.3,14z M37.8,13.6L37.8,13.6c0.1-2.4,1.7-4.4,3.6-4.4c2,0,3.5,2,3.5,4.4V14h-7.1V13.6z M48.5,27
                        c0,0.2-0.2,0.4-0.4,0.4H34.7c-0.2,0-0.4-0.2-0.4-0.4V15.5h2.1V16h-0.2c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h2
                        c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H38v-0.5h7.1V16h-0.2c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h2
                        c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-0.2v-0.5h1.9V27z M46.1,21.4h-9.4c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9.4
                        c0.5,0,0.8-0.3,0.8-0.8S46.6,21.4,46.1,21.4z M46.1,24h-9.4c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h9.4
                        c0.5,0,0.8-0.3,0.8-0.8C46.9,24.4,46.6,24,46.1,24z"
								></path>
							</svg>
						</div>
						<h4>Shopping &amp; Ecommerce</h4>
						<p>
							Click into the trillion dollar eCommerce and retail industry with a single vendor or
							multi-vendor eCommerce store.
						</p>
						<router-link
							to="/ecommerce-web-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 45 45"
								style="enable-background: new 0 0 45 45"
								xml:space="preserve"
							>
								<path
									d="M18,1.5h19.5v5.3c0,0.4,0.3,0.8,0.8,0.8h5.3V36H32.3v1.5h12c0.4,0,0.8-0.3,0.8-0.8v-30c0-0.2-0.1-0.4-0.2-0.5l-6-6
                     C38.6,0.1,38.4,0,38.3,0h-21c-0.4,0-0.8,0.3-0.8,0.8V9H18V1.5z M39,2.6L42.4,6H39V2.6z"
								></path>
								<path
									d="M0.8,45H24c0.4,0,0.8-0.3,0.8-0.8v-33c0-0.4-0.3-0.8-0.8-0.8H0.8c-0.4,0-0.8,0.3-0.8,0.8v33C0,44.7,0.3,45,0.8,45z M1.5,12
                     h21.8v31.5H1.5V12z"
								></path>
								<path
									d="M21,14.3H3.8C3.3,14.3,3,14.6,3,15v6c0,0.4,0.3,0.8,0.8,0.8H21c0.4,0,0.8-0.3,0.8-0.8v-6C21.8,14.6,21.4,14.3,21,14.3z
                     M20.3,20.3H4.5v-4.5h15.8V20.3z"
								></path>
								<path
									d="M7.5,36.8H3.8c-0.4,0-0.8,0.3-0.8,0.8v3.8C3,41.7,3.3,42,3.8,42h3.8c0.4,0,0.8-0.3,0.8-0.8v-3.8C8.3,37.1,7.9,36.8,7.5,36.8
                     z M6.8,40.5H4.5v-2.3h2.3V40.5z"
								></path>
								<path
									d="M14.3,36.8h-3.8c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8h3.8c0.4,0,0.8-0.3,0.8-0.8v-3.8
                     C15,37.1,14.7,36.8,14.3,36.8z M13.5,40.5h-2.3v-2.3h2.3V40.5z"
								></path>
								<path
									d="M7.5,30H3.8C3.3,30,3,30.3,3,30.8v3.8c0,0.4,0.3,0.8,0.8,0.8h3.8c0.4,0,0.8-0.3,0.8-0.8v-3.8C8.3,30.3,7.9,30,7.5,30z
                     M6.8,33.8H4.5v-2.3h2.3V33.8z"
								></path>
								<path
									d="M14.3,30h-3.8c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8h3.8c0.4,0,0.8-0.3,0.8-0.8v-3.8C15,30.3,14.7,30,14.3,30z
                     M13.5,33.8h-2.3v-2.3h2.3V33.8z"
								></path>
								<path
									d="M7.5,23.3H3.8C3.3,23.3,3,23.6,3,24v3.8c0,0.4,0.3,0.8,0.8,0.8h3.8c0.4,0,0.8-0.3,0.8-0.8V24C8.3,23.6,7.9,23.3,7.5,23.3z
                     M6.8,27H4.5v-2.3h2.3V27z"
								></path>
								<path
									d="M14.3,23.3h-3.8c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8h3.8c0.4,0,0.8-0.3,0.8-0.8V24
                     C15,23.6,14.7,23.3,14.3,23.3z M13.5,27h-2.3v-2.3h2.3V27z"
								></path>
								<path
									d="M21,23.3h-3.8c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8H21c0.4,0,0.8-0.3,0.8-0.8V24C21.8,23.6,21.4,23.3,21,23.3z
                     M20.3,27H18v-2.3h2.3V27z"
								></path>
								<path
									d="M21,30h-3.8c-0.4,0-0.8,0.3-0.8,0.8v10.5c0,0.4,0.3,0.8,0.8,0.8H21c0.4,0,0.8-0.3,0.8-0.8V30.8C21.8,30.3,21.4,30,21,30z
                     M20.3,40.5H18v-9h2.3V40.5z"
								></path>
								<rect x="17.3" y="17.3" width="1.5" height="1.5"></rect>
								<rect x="14.3" y="17.3" width="1.5" height="1.5"></rect>
								<rect x="11.3" y="17.3" width="1.5" height="1.5"></rect>
								<rect x="21.8" y="4.5" width="14.3" height="1.5"></rect>
								<rect x="21.8" y="7.5" width="14.3" height="1.5"></rect>
								<polygon
									points="35.3,12 35.3,18.8 33.8,18.8 33.8,15.8 32.3,15.8 32.3,18.8 30.8,18.8 30.8,13.5 29.3,13.5 29.3,18.8 27.8,18.8
                     27.8,15.8 26.3,15.8 26.3,18.8 26.3,19.5 26.3,20.3 39.8,20.3 39.8,19.5 39.8,18.8 39.8,10.5 38.3,10.5 38.3,18.8 36.8,18.8
                     36.8,12 "
								></polygon>
								<path
									d="M27.9,44.7c0.2,0.3,0.7,0.4,1,0.2c0.1-0.1,0.2-0.1,0.2-0.2l1.5-2.3c0.1-0.1,0.1-0.3,0.1-0.4V27h1.5v7.5h1.5v-8.3
                     c0-0.4-0.3-0.8-0.8-0.8h-2.3v-2.3c0-0.4-0.3-0.8-0.8-0.8h-3c-0.4,0-0.8,0.3-0.8,0.8V42c0,0.1,0,0.3,0.1,0.4L27.9,44.7z M27.8,24h1.5
                     v1.5h-1.5V24z M27.8,27h1.5v14.8l-0.8,1.1l-0.8-1.1V27z"
								></path>
								<rect x="32.3" y="22.5" width="7.5" height="1.5"></rect>
								<rect x="35.3" y="25.5" width="4.5" height="1.5"></rect>
								<rect x="35.3" y="28.5" width="4.5" height="1.5"></rect>
								<rect x="35.3" y="31.5" width="4.5" height="1.5"></rect>
							</svg>
						</div>
						<h4>Finance</h4>
						<p>
							Cash in on the rising wave of FinTech with mobile apps for banking, digital wallets,
							analytics and much more.
						</p>
						<router-link
							to="/finance-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#0082dd"
									d="M44.4,46.8c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C41.9,45.7,43,46.8,44.4,46.8
                     z M44.4,43.5c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8C43.5,43.9,43.9,43.5,44.4,43.5z M44.4,30.6H50V21
                     c0-3.6-2.9-6.5-6.5-6.5h-1.1c1.6-1.3,2.7-3.4,2.7-5.6V5.6c0-3.1-2.5-5.6-5.6-5.6h-3.2c-3.1,0-5.6,2.5-5.6,5.6v3.2
                     c0,2.3,1.1,4.3,2.7,5.6h-1.1c-3.6,0-6.5,2.9-6.5,6.5v7c-1.1-0.9-2.4-1.3-3.7-1.3c-3.3,0-5.9,2.7-5.9,5.9c0,1.6,0.6,3.1,1.7,4.2
                     l5.8,5.8c0.3,0.3,0.6,0.6,1,0.9c-0.5,0.1-0.9,0.1-1.4,0.1h-3.9c-4,0-7.3-3.3-7.3-7.3V21.7c5.8-0.4,10.5-5.3,10.5-11.2V4
                     c0-1.3-1.1-2.4-2.4-2.4h-1C18.9,0.7,18,0,16.9,0h-4v4.8h4c1,0,1.9-0.7,2.3-1.6h1C20.6,3.2,21,3.6,21,4v6.5c0,5.3-4.3,9.7-9.7,9.7
                     c-5.3,0-9.7-4.3-9.7-9.7V4c0-0.4,0.4-0.8,0.8-0.8h1c0.3,0.9,1.2,1.6,2.3,1.6h4V0h-4c-1,0-1.9,0.7-2.3,1.6h-1C1.1,1.6,0,2.7,0,4
                     v6.5c0,6,4.6,10.8,10.5,11.2v14.6c0,4.9,4,8.9,8.9,8.9h3.9c1.2,0,2.3-0.2,3.3-0.6c1,0.4,2.2,0.6,3.3,0.6h8.8
                     c0.4,2.7,2.7,4.8,5.6,4.8c3.1,0,5.6-2.5,5.6-5.6c0-3.1-2.5-5.6-5.6-5.6c-2.8,0-5.2,2.1-5.6,4.8H30c-0.5,0-0.9,0-1.4-0.1
                     c0.3-0.3,0.7-0.5,1-0.9l5.8-5.8c1.1-1.1,1.7-2.6,1.7-4.2c0-0.7-0.1-1.3-0.3-1.9h6.8H44.4z M16.9,3.2h-2.4V1.6h2.4
                     c0.4,0,0.8,0.4,0.8,0.8C17.7,2.9,17.4,3.2,16.9,3.2z M5.6,1.6h2.4v1.6H5.6c-0.4,0-0.8-0.4-0.8-0.8C4.8,2,5.2,1.6,5.6,1.6z
                     M44.4,40.3c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4C40.3,42.1,42.1,40.3,44.4,40.3z M48.4,29h-3.2v-3.2h3.2V29z
                     M42.6,18.1l-3.3,1.4l2.2-3.4h0.6L42.6,18.1z M37.9,18.7L36,15.9c0.6,0.2,1.2,0.3,1.9,0.3c0.7,0,1.3-0.1,1.9-0.3L37.9,18.7z
                     M32.3,5.6c0-2.2,1.8-4,4-4h3.2c2.2,0,4,1.8,4,4v1.5c-0.9-0.2-1.7-0.8-2.3-1.6l-0.8-1.2l-1.8,1.8C38,6.9,37,7.3,36,7.3h-3.8V5.6z
                     M32.3,8.9H36c1.4,0,2.8-0.6,3.8-1.6l0.4-0.4c0.8,1,2,1.7,3.3,1.9v0.1c0,3.1-2.5,5.6-5.6,5.6C34.8,14.5,32.3,12,32.3,8.9z
                     M33.7,16.1h0.6l2.2,3.4l-3.3-1.4L33.7,16.1z M27.4,25.8h3.2v0.8c-1.2,0.1-2.3,0.5-3.2,1.3V25.8z M34.2,35.6l-5.8,5.8
                     c-0.5,0.5-1.1,1-1.8,1.3c-0.7-0.3-1.3-0.8-1.8-1.3L19,35.6c-0.8-0.8-1.3-1.9-1.3-3.1c0-2.4,1.9-4.3,4.3-4.3c1.1,0,2.3,0.5,3.1,1.3
                     l1.5,1.5l1.5-1.5c0.8-0.8,1.9-1.3,3.1-1.3c2.4,0,4.3,1.9,4.3,4.3C35.5,33.7,35,34.8,34.2,35.6z M35.9,29c-0.9-1.2-2.2-2-3.7-2.3
                     V21h-1.6v3.2h-3.2V21c0-2.6,2-4.7,4.6-4.8L31.3,19l6.6,2.8l6.6-2.8l-0.7-2.9c2.6,0.1,4.6,2.2,4.6,4.8v3.2h-3.2V21h-1.6V29H35.9z"
								></path>
							</svg>
						</div>
						<h4>Health &amp; Fitness</h4>
						<p>
							Climb to the top of app store leaderboards with mobile apps for personal health,
							fitness and sports.
						</p>
						<router-link
							to="/health-fitness-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M49.9,1.3c-0.1-0.2-0.3-0.4-0.5-0.4L46.2,0c-0.3-0.1-0.6,0-0.8,0.2L34.5,9.8l-6.2-1.7l5-5.1
                        c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.3-0.5-0.6-0.6l-3.2-0.9c-0.2,0-0.4,0-0.5,0L17.8,5.3l-5.8-1.5C7.3,2.5,3.1,4.3,0.2,8.7
                        c0,0.1-0.1,0.2-0.1,0.2c-0.2,0.6-0.1,1.3,0.2,1.9c0.3,0.6,0.9,1,1.5,1.2l8.5,2.4c-2.2,2.2-3.9,4.9-4.9,7.8c0,0,0,0.1,0,0.1
                        c-0.2,0.5-0.4,1.1-0.5,1.6c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.3,1.1-0.4,1.6c0,0,0,0,0,0c-0.2,1.1-0.3,2.3-0.3,3.5
                        c0,0.9,0.1,1.7,0.2,2.6c0,0,0,0,0,0c0,0,0,0,0,0c0.2,1.7,0.6,3.4,1.2,4.9c0,0,0,0.1,0.1,0.1c0.1,0.3,0.3,0.7,0.4,1
                        c0,0.1,0,0.1,0.1,0.1c0.2,0.4,0.4,0.7,0.6,1.1c0,0,0,0.1,0.1,0.1c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.1,0.2,0.3,0.3,0.4
                        c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0.1C11.9,46.6,18,50,24.9,50c11.4,0,20.8-9.3,20.8-20.8
                        c0-2.5-0.5-5-1.4-7.4c0.1,0,0.2-0.1,0.2-0.1c0.3-0.3,0.4-0.7,0.2-1l-2.3-4l3.9-2.1c0.3-0.2,0.5-0.5,0.4-0.8c0-0.3-0.3-0.6-0.6-0.7
                        l-0.7-0.2L49.9,2C50,1.8,50,1.5,49.9,1.3z M29.5,2.4l1.6,0.4l-4.6,4.8l-5.9-1.6L29.5,2.4z M6.3,24.9c0.1-0.4,0.2-0.8,0.3-1.3
                        c0-0.1,0-0.1,0-0.2c1-3.3,2.9-6.2,5.5-8.6l5.3,1.5l2.3,2.6c-0.2,0.1-0.4,0.1-0.7,0.1h-3.4c-0.4,0-0.8,0.3-0.8,0.7
                        c-0.4,2.9-3,5.1-5.9,5.1L6.3,24.9C6.3,25,6.3,25,6.3,24.9z M9.2,40c0,0,0-0.1-0.1-0.1C9,39.8,9,39.7,8.9,39.7
                        c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.3-0.3-0.6-0.5-0.8c0,0,0-0.1,0-0.1c-0.1-0.3-0.3-0.5-0.4-0.8c0,0,0-0.1,0-0.1
                        c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0,0,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9c0,0,0,0,0,0c-0.1-0.3-0.2-0.6-0.3-0.9c0,0,0,0,0,0
                        c-0.1-0.3-0.2-0.6-0.2-0.9c0,0,0,0,0-0.1c-0.1-0.3-0.1-0.6-0.2-0.9c0,0,0,0,0,0h2.4c1.7,0,3.2,0.8,4.2,2.1c0.8,1,0.9,2.4,0.3,3.5
                        c-0.6,1.2-1.7,1.8-3,1.8H9.2z M44,29.2c0,0.8-0.1,1.7-0.2,2.5h-7.3c-2.5,0-4.7,1.9-4.9,4.4c-0.2,2.2-2,3.9-4.1,3.9
                        c-2.3,0-4.2-1.9-4.2-4.2v-5c0-1.4,0.5-2.6,1.5-3.6l0.7-0.7l-1.2-1.2L23.6,26c-1.3,1.3-2,3-2,4.8v5c0,3.2,2.6,5.9,5.9,5.9
                        c3,0,5.4-2.3,5.8-5.4c0.2-1.7,1.6-3,3.3-3h7c-1.9,8.6-9.5,15-18.6,15c-5.8,0-11-2.6-14.5-6.7c1.7-0.1,3.3-1.1,4.1-2.7
                        c0.9-1.7,0.7-3.7-0.4-5.2c-1.3-1.8-3.4-2.8-5.5-2.8H5.9c0-0.5-0.1-1.1-0.1-1.7c0-0.8,0.1-1.7,0.2-2.5h3c3.5,0,6.6-2.5,7.4-5.8h2.7
                        c0.6,0,1.2-0.2,1.8-0.4l2.3,2.6c0.1,0.1,0.3,0.2,0.4,0.3l3.2,0.9c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1
                        c0,0,0,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.3,0.2-0.6,0.1-0.9l-2.2-4.4c4,0.9,6.4,0.4,10.6-0.5l2.3-0.5l3.4,2.7
                        C43.3,23.1,44,26.1,44,29.2z M40.9,15.5c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2,0,0.5,0.1,0.6l0.6,1l-6.5-5.2
                        c-0.4-0.3-0.9-0.2-1.2,0.1c-0.3,0.4-0.2,0.9,0.1,1.2l3.2,2.5l-0.9,0.2c-0.3,0.1-0.5,0.1-0.8,0.2c-3.9,0.8-6.1,1.1-10.2,0l-0.3-0.1
                        L23.2,14c-0.2-0.4-0.7-0.6-1.1-0.4c-0.4,0.2-0.6,0.7-0.4,1.1l1.5,3c0,0,0,0,0,0l2.2,4.3l-1.2-0.3l-5.8-6.6c0,0,0,0,0,0l-3.2-3.7
                        l-0.7-0.8c-0.3-0.3-0.8-0.4-1.2-0.1c-0.3,0.3-0.4,0.8-0.1,1.2l2.1,2.4l-2.6-0.7l-0.6-0.2c0,0,0,0,0,0l-9.9-2.8
                        c-0.2-0.1-0.4-0.2-0.5-0.4C1.7,9.8,1.7,9.7,1.7,9.5c0.6-0.8,1.2-1.7,2.1-2.4l5.1,1l0.3-1.6L5.7,5.8c1.6-0.7,3.5-1,5.9-0.4l6,1.6
                        l2.9,0.8l13.9,3.7c0,0,0,0,0,0l9.2,2.5L40.9,15.5z M43.8,12.3l-7.4-2l9.7-8.6L48,2.3L43.8,12.3z M9.1,29.2h1.7v-1.7H9.1V29.2z
                        M11.2,8.8l1.6,0.4l0.4-1.6l-1.6-0.4L11.2,8.8z M14.4,9.6l1.6,0.4l0.4-1.6L14.8,8L14.4,9.6z M17.6,10.5l1.6,0.4l0.4-1.6L18,8.9
                        L17.6,10.5z M20.8,11.3l1.6,0.4l0.4-1.6l-1.6-0.4L20.8,11.3z M24,12.2l1.6,0.4L26,11l-1.6-0.4L24,12.2z"
								></path>
							</svg>
						</div>
						<h4>Travel &amp; Tourism</h4>
						<p>
							Travel booking, adventure planning, route-sharing — we can build a travel mobile app
							for any purpose.
						</p>
						<router-link
							to="/travel-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-lg-3 fxt-industries-box">
					<div class="solutions-box rounded10 fxt-slu-bx">
						<div class="solutions-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#F5851F"
									d="M25,5.8h-3.3V4.2c0-0.5-0.4-0.8-0.8-0.8h-6.6c-0.5,0-0.8,0.4-0.8,0.8v1.7h-3.3c-0.5,0-0.8,0.4-0.8,0.8v9.2
                        c0,0.5,0.4,0.8,0.8,0.8H25c0.5,0,0.8-0.4,0.8-0.8V6.7C25.8,6.2,25.5,5.8,25,5.8z M24.2,15H11V7.5h3.3c0.5,0,0.8-0.4,0.8-0.8V5h4.9
                        v1.7c0,0.5,0.4,0.8,0.8,0.8h3.3V15z M25,25c-0.2,0-0.4,0.1-0.5,0.2l-2,1.5v-0.8c0-0.5-0.4-0.8-0.8-0.8h-1.8
                        c0.5-1.3-0.2-2.7-1.5-3.2c-0.3-0.1-0.5-0.1-0.8-0.1c-0.6,0-1.2,0.2-1.6,0.7c-0.5-0.4-1-0.6-1.6-0.7c-1.4,0-2.5,1.1-2.5,2.5
                        c0,0.3,0,0.6,0.1,0.8h-1.8c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5,0.4,0.8,0.8,0.8h11.5c0.5,0,0.8-0.4,0.8-0.8v-0.8l2,1.5
                        c0.3,0.2,0.6,0.2,0.9,0.1c0.3-0.1,0.5-0.4,0.5-0.7v-8.3C25.8,25.4,25.5,25,25,25z M17.6,23.3c0.5,0,0.8,0.4,0.8,0.8
                        c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8C16.8,23.7,17.1,23.3,17.6,23.3z M14.3,23.3c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8
                        c-0.5,0-0.8-0.4-0.8-0.8C13.5,23.7,13.8,23.3,14.3,23.3z M20.9,33.3H11v-6.7h9.9V33.3z M24.2,32.5l-1.6-1.2v-2.5l1.6-1.3V32.5z
                        M39.8,15H28.3c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5,0.4,0.8,0.8,0.8h4.9v1.7h-1.6v1.7h4.9v-1.7h-1.6V25h4.9c0.5,0,0.8-0.4,0.8-0.8
                        v-8.3C40.7,15.4,40.3,15,39.8,15z M39,23.3h-9.9v-6.7H39V23.3z M17.6,8.3c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5
                        c1.4,0,2.5-1.1,2.5-2.5C20.1,9.5,18.9,8.3,17.6,8.3z M17.6,11.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8
                        C18.4,11.3,18,11.7,17.6,11.7z M40.9,0H9.1C7.4,0,6,1.4,6,3.1v43.7C6,48.6,7.4,50,9.1,50h31.8c1.7,0,3.1-1.4,3.1-3.1V3.1
                        C44,1.4,42.6,0,40.9,0z M42.3,46.9c0,0.8-0.6,1.5-1.4,1.5H9.1c-0.8,0-1.4-0.7-1.4-1.5v-8.5h34.6V46.9z M42.3,36.7H7.7V3.1
                        c0-0.8,0.6-1.5,1.4-1.5h31.8c0.8,0,1.4,0.7,1.4,1.5V36.7z M25,46.7c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3
                        c-1.8,0-3.3,1.5-3.3,3.3C21.7,45.2,23.2,46.7,25,46.7z M25,41.7c0.9,0,1.6,0.7,1.6,1.7S25.9,45,25,45c-0.9,0-1.6-0.7-1.6-1.7
                        S24.1,41.7,25,41.7z M37.4,3.3h-1.6V5h1.6V3.3z M39,5h1.6V3.3H39V5z"
								></path>
							</svg>
						</div>
						<h4>Media &amp; Entertainment</h4>
						<p>
							Video and audio streaming apps are the new rage. Tune into that market with our app
							development solutions.
						</p>
						<router-link
							to="/media-entertainment-app-development"
							class="btn-custome btn-custome-small btn waves-effect waves-light"
							>Explore More</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.fxt-slu-bx {
	margin-bottom: 20px !important;
	max-height: 320px;
}
.fxt-slu-bx h4 {
	font-size: 18px;
}
@media (max-width: 576px) {
	.fxt-bespoke-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
	.fxt-slu-bx {
		text-align: center !important;
	}
	.fxt-slu-bx .solutions-box-icon {
		margin: auto;
	}
	.fxt-industries-box h4,
	.fxt-industries-box p {
		text-align: start !important;
	}
	.fxt-industries-box .solutions-box-icon {
		margin: 0;
	}
}
</style>