<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <Vistion></Vistion>

      <Advantages></Advantages>

      <Techno></Techno>

      <WhyChooseUs></WhyChooseUs>

      <TypeDiv></TypeDiv>

      <Portfolio></Portfolio>

      <Const></Const>

  </div>
</template>

<script>
import Banner from '../../components/EnterpriseSolutions/EcommerceDevelopment/Banner.vue'
import Vistion from '../../components/EnterpriseSolutions/EcommerceDevelopment/Vistion.vue'
import Advantages from '../../components/EnterpriseSolutions/EcommerceDevelopment/Advantages.vue'
import Techno from '../../components/EnterpriseSolutions/EcommerceDevelopment/Techno.vue'
import WhyChooseUs from '../../components/EnterpriseSolutions/EcommerceDevelopment/WhyChooseUs.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/EnterpriseSolutions/EcommerceDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        TypeDiv,
        WhyChooseUs,
        Portfolio,
        Const,
        Vistion,
        Advantages,
        Techno
    }
}
</script>


<style>

</style>