<template>
	<section class="padding-60 p-relative" style="background: #fff">
		<div class="container mt-5">
			<div class="title">
				<span>6 sprints for MVP development</span>
				<h2 class="fxt-process-dev">Our Proven MVP Development Process is Smart, Lean, & Agile</h2>
				<p>
					Our process of MVP development spans from understanding the thought that became an idea
					and the various activities required to turn that idea into a market-worthy product.
				</p>
			</div>
			<div class="mvp-process-dev-box-wrap">
				<div class="row">
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-0"
						data-wow-duration=".3s"
						data-wow-delay=".3s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M87.9,80.9c4.5-3.7,5.3-10.4,1.7-14.9c-3.6-4.6-10.2-5.3-14.8-1.7c-4.5,3.7-5.3,10.4-1.7,14.9c0.5,0.6,1,1.2,1.7,1.7
                              c-3.9,2.3-6.3,6.6-6.3,11.2v2.6c0,2.9,2.3,5.3,5.2,5.3H89c2.9,0,5.2-2.4,5.2-5.3v-2.6C94.2,87.5,91.8,83.3,87.9,80.9z M81.3,66.3
                              c3.5,0,6.3,2.9,6.3,6.4c0,3.5-2.8,6.4-6.3,6.4c-3.5,0-6.3-2.9-6.3-6.4C75,69.2,77.8,66.3,81.3,66.3z M90.1,94.7
                              c0,0.6-0.5,1.1-1,1.1H73.6c-0.6,0-1-0.5-1-1.1v-2.6c0-4.9,3.9-8.8,8.7-8.8c4.8,0,8.7,4,8.7,8.8V94.7z M25.2,80.9
                              c4.5-3.7,5.3-10.4,1.7-14.9c-3.6-4.6-10.2-5.3-14.8-1.7C7.6,68,6.8,74.7,10.5,79.3c0.5,0.6,1,1.2,1.7,1.7
                              c-3.9,2.3-6.3,6.6-6.3,11.2v2.6c0,2.9,2.3,5.3,5.2,5.3h15.4c2.9,0,5.2-2.4,5.2-5.3v-2.6C31.5,87.5,29.1,83.3,25.2,80.9z
                              M18.7,66.3c3.5,0,6.3,2.9,6.3,6.4c0,3.5-2.8,6.4-6.3,6.4c-3.5,0-6.3-2.9-6.3-6.4C12.3,69.2,15.2,66.3,18.7,66.3z M27.4,94.7
                              c0,0.6-0.5,1.1-1,1.1H11c-0.6,0-1-0.5-1-1.1v-2.6c0-4.9,3.9-8.8,8.7-8.8c4.8,0,8.7,4,8.7,8.8V94.7z M56.6,80.9
                              c4.5-3.7,5.3-10.4,1.7-14.9c-3.6-4.6-10.2-5.3-14.8-1.7c-4.5,3.7-5.3,10.4-1.7,14.9c0.5,0.6,1,1.2,1.7,1.7
                              c-3.9,2.3-6.3,6.6-6.3,11.2v2.6c0,2.9,2.3,5.3,5.2,5.3h15.4c2.9,0,5.2-2.4,5.2-5.3v-2.6C62.9,87.5,60.5,83.3,56.6,80.9z M50,66.3
                              c3.5,0,6.3,2.9,6.3,6.4c0,3.5-2.8,6.4-6.3,6.4c-3.5,0-6.3-2.9-6.3-6.4C43.7,69.2,46.5,66.3,50,66.3z M58.7,94.7
                              c0,0.6-0.5,1.1-1,1.1H42.3c-0.6,0-1-0.5-1-1.1v-2.6c0-4.9,3.9-8.8,8.7-8.8c4.8,0,8.7,4,8.7,8.8V94.7z M73,45.3h8.4
                              c5.7,0,10.4-4.7,10.4-10.5V10.5c0-5.8-4.6-10.5-10.3-10.5h-61c-5.7,0-10.4,4.7-10.4,10.5v24.2c0,5.8,4.7,10.5,10.4,10.5h6.3
                              l4.6,8.4c0.6,1,1.8,1.4,2.8,0.8c0.3-0.2,0.6-0.5,0.8-0.8l4.6-8.4h20.5l4.6,8.4c0.6,1,1.8,1.4,2.8,0.8c0.3-0.2,0.6-0.5,0.8-0.8
                              L73,45.3z M66.7,48.3l-3.4-6.2c-0.4-0.6-1-1-1.8-1.1H38.6c-0.7,0-1.4,0.4-1.8,1.1l-3.4,6.2l-3.4-6.2c-0.4-0.6-1-1-1.8-1.1h-7.6
                              c-3.4,0-6.2-2.8-6.2-6.3V10.5c0-3.5,2.8-6.3,6.2-6.3h61c3.4,0,6.2,2.8,6.2,6.3v24.2c0,3.5-2.8,6.3-6.2,6.3h-9.7
                              c-0.7,0-1.4,0.4-1.8,1.1L66.7,48.3z M24.2,18.5h26.3c1.1,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1H24.2c-1.1,0-2.1,0.9-2.1,2.1
                              C22.1,17.6,23,18.5,24.2,18.5z M77.9,27.9c0-1.2-0.9-2.1-2.1-2.1H24.2c-1.1,0-2.1,0.9-2.1,2.1S23,30,24.2,30h51.6
                              C77,30,77.9,29.1,77.9,27.9z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>Stakeholder Workshop</h6>
								<p>
									When the thinkers and doers get together, innovation happens. Our first step in
									MVP development is sitting with the ideators to understand their ideas and
									instincts. We run several brainstorming sessions, SWOT analysis and competitor
									analysis to arrive at a consensus about the intended product.
								</p>
							</div>
							<div class="num">01</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-1"
						data-wow-duration=".3s"
						data-wow-delay=".6s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M0,0v100h77.4V13.8L63.6,0H0z M74.2,96.8h-71V3.2h58.1v12.9h12.9V96.8z M64.5,5.5l7.4,7.4h-7.4V5.5z M21,9.7H6.5v3.2H21
                              V9.7z M61.3,25.8H71v-3.2h-9.7V25.8z M34.5,48.4h20.3V25.8h3.2v-3.2h-3.2v-6.5H36.2l-6.5-6.5h-5.5v3.2h4.2l3.2,3.2h-9v32.3H30
                              l-3.2,3.2h-4.2v3.2h5.5L34.5,48.4z M37.1,19.4h3.2V29h-3.2V19.4z M25.8,45.2V19.4h8.1v12.9h9.7V19.4h8.1v4.2l-6,6l2.3,2.3l3.7-3.7
                              v17.1H25.8z M19.4,51.6H6.5v3.2h12.9V51.6z M6.5,93.5h32.3V58.1H6.5V93.5z M9.7,61.3h25.8v29H9.7V61.3z M41.9,61.3h29v-3.2h-29
                              V61.3z M41.9,67.7h29v-3.2h-29V67.7z M41.9,74.2h29V71h-29V74.2z M41.9,80.6h29v-3.2h-29V80.6z M41.9,87.1h29v-3.2h-29V87.1z
                              M67.7,93.5H71v-3.2h-3.2V93.5z M41.9,93.5h22.6v-3.2H41.9V93.5z M90.3,0.4L80.6,14v76.3c0,5.3,4.3,9.7,9.7,9.7
                              c5.3,0,9.7-4.3,9.7-9.7V14L90.3,0.4z M90.3,6l4.9,6.9h-9.9L90.3,6z M96.8,90.3c0,3.6-2.9,6.5-6.5,6.5c-3.6,0-6.5-2.9-6.5-6.5v-4.8
                              h12.9V90.3z M96.8,82.3H83.9V79h12.9V82.3z M96.8,75.8h-4.8v-3.2h-3.2v3.2h-4.8V16.1h4.8v53.2h3.2V16.1h4.8V75.8z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>Technical Analysis</h6>
								<p>
									What platform should be considered for building the product? What are the
									technical possibilities and challenges that lie ahead? What accelerators can we
									use to quicken development. Is there an existing Proof of concept or pilot that
									can be improvised? Answers to these questions will be found with our technical
									analysis.
								</p>
							</div>
							<div class="num">02</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-2"
						data-wow-duration=".3s"
						data-wow-delay=".9s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M95,60.5V15c0-3-2.1-5.7-5-6.4V6.7C90,3,87,0,83.3,0H6.7C3,0,0,3,0,6.7v71.7C0,82,3,85,6.7,85h1.7c0,3.7,3,6.7,6.7,6.7
                              h56.6c3.7,7.4,12.7,10.5,20.1,6.8c5.1-2.5,8.3-7.7,8.3-13.4V71.7C100,67.4,98.2,63.3,95,60.5z M88.3,11.7c1.8,0,3.3,1.5,3.3,3.3
                              v6.7h-80V15c0-1.8,1.5-3.3,3.3-3.3H88.3z M8.3,15v66.7H6.7c-1.8,0-3.3-1.5-3.3-3.3V6.7c0-1.8,1.5-3.3,3.3-3.3h76.7
                              c1.8,0,3.3,1.5,3.3,3.3v1.7H15C11.3,8.3,8.3,11.3,8.3,15z M70.4,88.3H15c-1.8,0-3.3-1.5-3.3-3.3V25h80v33.2
                              c-2.1-1-4.4-1.6-6.7-1.6c-8.3,0-15,6.7-15,15V85C70,86.1,70.1,87.2,70.4,88.3z M96.7,85c0,6.4-5.2,11.7-11.7,11.7
                              c-6.4,0-11.7-5.2-11.7-11.7v-2.7c1.5,0.7,2.5,2.1,2.8,3.7c0.4,2.1,1.6,4,3.3,5.3l0.3,0.2c3.1,2.3,7.4,2.3,10.5,0l0.3-0.2
                              c1.7-1.3,2.9-3.2,3.3-5.3c0.3-1.6,1.4-3,2.8-3.7V85z M96.7,78.7c-3.1,0.9-5.5,3.5-6.1,6.6c-0.3,1.3-1,2.5-2.1,3.3l-0.3,0.2
                              c-1.9,1.4-4.6,1.4-6.5,0l-0.3-0.2c-1.1-0.8-1.8-2-2.1-3.3c-0.6-3.2-3-5.7-6.1-6.6v-7.1C73.3,65.2,78.6,60,85,60
                              c6.4,0,11.7,5.2,11.7,11.7V78.7z M85,65c-2.8,0-5,2.2-5,5v5c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5v-5C90,67.2,87.8,65,85,65z M86.7,75
                              c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7v-5c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7V75z M18.3,60h33.3
                              c0.9,0,1.7-0.7,1.7-1.7V31.7c0-0.9-0.7-1.7-1.7-1.7H18.3c-0.9,0-1.7,0.7-1.7,1.7v26.7C16.7,59.3,17.4,60,18.3,60z M20,33.3h30
                              v23.3H20V33.3z M45.6,43.5L29,36.8c-0.9-0.3-1.8,0.1-2.2,0.9c-0.1,0.2-0.1,0.4-0.1,0.6v13.3c0,0.9,0.7,1.7,1.7,1.7
                              c0.2,0,0.4,0,0.6-0.1l16.7-6.7c0.9-0.3,1.3-1.3,0.9-2.2C46.4,44,46,43.6,45.6,43.5z M30,49.2v-8.4L40.5,45L30,49.2z M86.7,36.7
                              H58.3V40h28.3V36.7z M86.7,43.3H58.3v3.3h28.3V43.3z M78.3,50h-20v3.3h20V50z M86.7,30H70v3.3h16.7V30z M66.7,30h-3.3v3.3h3.3V30z
                              M16.7,76.7H65v-3.3H16.7V76.7z M16.7,83.3H65V80H16.7V83.3z M28.3,70H65v-3.3H28.3V70z M25,70v-3.3h-3.3V70H25z M15,18.3h3.3V15
                              H15V18.3z M25,15h-3.3v3.3H25V15z M31.7,15h-3.3v3.3h3.3V15z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>Prototyping</h6>
								<p>
									Your product needs validation — validation from stakeholders, actual users and
									investors. It cannot be done with an intangible idea. A prototype with a mockup
									design, working features and well-defined use cases can help the cause. GenixBit Labs
									can help you build a prototype that can win your stakeholder’s interest.
								</p>
							</div>
							<div class="num">03</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-3 order-md-5 order-5"
						data-wow-duration=".3s"
						data-wow-delay="1.8s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M38.8,83.3h-7.5c-0.8,0-1.6,0.6-1.6,1.6v6.1c0,0.8,0.6,1.6,1.6,1.6h7.5c0.8,0,1.6-0.6,1.6-1.6v-6.1
                              C40.2,83.9,39.5,83.3,38.8,83.3z M37.2,89.4h-4.5v-3.1h4.5V89.4z M53.7,74.4h-7.5c-0.8,0-1.6,0.6-1.6,1.6v15
                              c0,0.8,0.6,1.6,1.6,1.6h7.5c0.8,0,1.6-0.6,1.6-1.6v-15C55.3,75,54.7,74.4,53.7,74.4z M52.3,89.4h-4.5v-12h4.5V89.4z M68.9,66.7
                              h-7.5c-0.8,0-1.6,0.6-1.6,1.6v22.7c0,0.8,0.6,1.6,1.6,1.6h7.5c0.8,0,1.6-0.6,1.6-1.6V68.1C70.5,67.3,69.7,66.7,68.9,66.7z
                              M67.3,89.4h-4.5V69.7h4.5V89.4z M90.8,19.4h-7.7c-0.8,0-1.6,0.6-1.6,1.6v9.2H63.4C63.3,13.9,51.6,1.1,51.1,0.5
                              c-0.6-0.6-1.7-0.6-2.2,0C48.4,0.9,36.7,13.8,36.7,30H18.4v-9.2c0-0.8-0.6-1.6-1.6-1.6H9.2c-5,0-9.2,4.2-9.2,9.4v62.2
                              c0,5,4.2,9.2,9.2,9.2h81.5c5.2,0,9.2-4.2,9.2-9.2V28.6C100,23.4,95.8,19.4,90.8,19.4z M50,3.9c1.6,1.9,4.2,5.8,6.6,10.6
                              c-4.1,2-9.1,2-13.1,0C45.8,9.5,48.4,5.8,50,3.9z M42.2,17.3c4.8,2.3,10.5,2.5,15.6,0c1.4,3.9,2.5,8.3,2.5,12.8
                              c0,6.2-1.9,11.9-4.2,16.7c-1.1,2.2-2.2,4.1-3.3,5.8H47c-3.4-5.3-7.5-13.4-7.5-22.3C39.7,25.6,40.8,21.2,42.2,17.3z M62.7,37.2
                              c1.4,1.4,2.3,3.3,2.3,5.3v14.7c-2.3-1.9-5.8-5.3-5.9-9.5C60.6,44.5,61.9,41.1,62.7,37.2z M52.2,55.6v5.6l-0.9-1.4
                              c-0.3-0.5-0.8-0.8-1.2-0.8c-0.5,0-0.9,0.3-1.2,0.8l-0.9,1.4v-5.6H52.2z M37.3,37.2c0.8,3.9,2,7.3,3.6,10.5
                              c-0.2,4.2-3.4,7.7-5.9,9.5V42.5C35,40.5,35.8,38.4,37.3,37.2z M3,28.6c0-3.4,2.8-6.2,6.2-6.2h6.2v59.2H9.2c-2.3,0-4.5,0.9-6.2,2.3
                              V28.6z M90.6,97H9.2C5.8,97,3,94.2,3,90.8c0-3.4,2.8-6.2,6.2-6.2h7.7c0.8,0,1.6-0.6,1.6-1.6V33h18.3v0.8c-3,1.9-4.8,5.2-4.8,8.8
                              v17.7c0,0.5,0.3,1.1,0.8,1.3c0.5,0.3,1.1,0.3,1.6,0c0.3-0.2,6.6-3.8,8.9-9.7c0.6,0.9,1.1,1.9,1.7,2.7v12c0,0.6,0.5,1.2,1.1,1.4
                              c0.6,0.2,1.4-0.2,1.7-0.6l2.3-3.9l2.3,3.9c0.3,0.6,1.1,0.8,1.7,0.6c0.6-0.2,1.1-0.8,1.1-1.4v-12c0.6-0.8,1.1-1.7,1.7-2.7
                              c2.3,5.9,8.6,9.5,8.9,9.7c0.9,0.6,2.3-0.2,2.3-1.3V42.7c0-3.6-1.9-6.9-4.8-8.7v-0.8h18.3v50c0,0.8,0.6,1.4,1.4,1.4h7.7
                              c3.4,0,6.2,2.8,6.2,6.2C96.9,94.2,94,97,90.6,97z M97,83.9c-1.7-1.6-3.7-2.3-6.2-2.3h-6.2V22.3h6.2c3.4,0,6.2,2.8,6.2,6.2V83.9z
                              M55.9,30.8c0-3.3-2.7-5.9-5.9-5.9c-3.3,0-5.9,2.7-5.9,5.9c0,3.3,2.7,5.9,5.9,5.9C53.3,36.7,55.9,34.1,55.9,30.8z M47,30.8
                              c0-1.6,1.2-3,3-3c1.6,0,3,1.4,3,3c0,1.6-1.4,3-3,3C48.4,33.7,47,32.3,47,30.8z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>MVP Release (Beta Launch)</h6>
								<p>
									Once the MVP is built, its beta version has to be released to the world to seek
									feedback. We assist in releasing the MVP in selected destinations like
									marketplaces, product communities, startup forums and so on.
								</p>
							</div>
							<div class="num">06</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-4 order-md-4 order-4"
						data-wow-duration=".3s"
						data-wow-delay="1.5s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M98.4,7.7H1.6C0.7,7.7,0,8.4,0,9.3v81.5c0,0.9,0.7,1.6,1.6,1.6h96.9c0.9,0,1.6-0.7,1.6-1.6V9.3C100,8.4,99.3,7.7,98.4,7.7
                              z M18.7,10.8h65.6v6.3H18.7V10.8z M3.1,10.8h12.5v6.3H3.1V10.8z M96.9,89.2H3.1V20.2h62.5v6.3c0,0.8,0.6,1.5,1.5,1.6l5.7,0.3
                              c0.5,1.6,1.1,3.2,2,4.7l-3.8,4.3c-0.6,0.6-0.5,1.6,0.1,2.2l6.6,6.6c0.6,0.6,1.5,0.6,2.1,0.1l4.3-3.9c1.5,0.8,3.1,1.5,4.7,2
                              l0.3,5.7c0,0.8,0.7,1.5,1.6,1.5h6.2V89.2z M96.9,48.4h-4.8L91.8,43c0-0.7-0.5-1.3-1.2-1.4c-2.1-0.5-4.1-1.3-5.9-2.5
                              c-0.6-0.4-1.3-0.3-1.9,0.2l-4,3.6l-4.5-4.5l3.6-4c0.5-0.5,0.5-1.3,0.2-1.9c-1.1-1.8-2-3.9-2.5-6c-0.2-0.7-0.7-1.2-1.4-1.2L68.7,25
                              v-4.8h10.9v1.6c0,8.7,7,15.7,15.6,15.7h1.6V48.4z M96.9,34.3h-1.6c-6.9,0-12.5-5.6-12.5-12.5v-1.6h14.1V34.3z M96.9,17.1h-9.4
                              v-6.3h9.4V17.1z M65.7,48.4L60,48.1c-0.5-1.6-1.1-3.2-2-4.7l3.8-4.3c0.6-0.6,0.5-1.6-0.1-2.2l-6.6-6.6c-0.6-0.6-1.5-0.6-2.1-0.1
                              l-4.3,3.8c-1.5-0.8-3.1-1.5-4.7-2l-0.3-5.7c0-0.8-0.7-1.5-1.6-1.5h-9.4c-0.8,0-1.5,0.6-1.6,1.5l-0.3,5.7c-1.6,0.5-3.2,1.1-4.7,2
                              L22,30.3c-0.6-0.6-1.6-0.5-2.1,0.1L13.2,37c-0.6,0.6-0.6,1.5-0.1,2.2l3.8,4.3c-0.8,1.5-1.5,3.1-2,4.7l-5.7,0.3
                              c-0.8,0-1.5,0.7-1.5,1.6v9.4c0,0.8,0.6,1.5,1.5,1.6l5.7,0.3c0.5,1.6,1.1,3.2,2,4.7l-3.8,4.3c-0.6,0.6-0.5,1.6,0.1,2.2l6.6,6.6
                              c0.6,0.6,1.5,0.6,2.1,0.1l4.3-3.9c1.5,0.8,3.1,1.5,4.7,2l0.3,5.7c0,0.8,0.7,1.5,1.6,1.5h9.4c0.8,0,1.5-0.7,1.6-1.5l0.3-5.7
                              c1.6-0.5,3.2-1.1,4.7-2l4.3,3.8c0.6,0.6,1.6,0.5,2.1-0.1l6.6-6.6c0.6-0.6,0.6-1.5,0.1-2.2L58,66c0.8-1.5,1.5-3.1,2-4.7l5.7-0.3
                              c0.8,0,1.5-0.7,1.5-1.6V50C67.2,49.2,66.5,48.5,65.7,48.4z M64.1,57.9l-5.4,0.3c-0.7,0-1.3,0.5-1.4,1.2c-0.5,2.1-1.3,4.1-2.5,6
                              c-0.4,0.6-0.3,1.4,0.2,1.9l3.6,4L54,75.8l-4-3.6c-0.5-0.5-1.3-0.5-1.9-0.2c-1.8,1.1-3.8,2-5.9,2.5c-0.7,0.2-1.2,0.7-1.2,1.4
                              l-0.3,5.4h-6.4L34,75.9c0-0.7-0.5-1.3-1.2-1.4c-2.1-0.5-4.1-1.3-5.9-2.5c-0.6-0.4-1.3-0.3-1.9,0.2l-4,3.6l-4.5-4.5l3.6-4
                              c0.5-0.5,0.5-1.3,0.2-1.9c-1.1-1.8-2-3.9-2.5-6c-0.2-0.7-0.7-1.2-1.4-1.2l-5.4-0.3v-6.4l5.4-0.3c0.7,0,1.3-0.5,1.4-1.2
                              c0.5-2.1,1.3-4.1,2.5-6c0.4-0.6,0.3-1.4-0.2-1.9l-3.6-4l4.5-4.5l4,3.6c0.5,0.5,1.3,0.5,1.9,0.2c1.8-1.1,3.8-2,5.9-2.5
                              c0.7-0.2,1.2-0.7,1.2-1.4l0.3-5.4h6.4l0.3,5.4c0,0.7,0.5,1.3,1.2,1.4c2.1,0.5,4.1,1.3,5.9,2.5c0.6,0.4,1.3,0.3,1.9-0.2l4-3.6
                              l4.5,4.5l-3.6,4c-0.5,0.5-0.5,1.3-0.2,1.9c1.1,1.8,2,3.9,2.5,6c0.2,0.7,0.7,1.2,1.4,1.2l5.4,0.3V57.9z M37.5,39
                              c-8.6,0-15.6,7-15.6,15.7c0,8.7,7,15.7,15.6,15.7c8.6,0,15.6-7,15.6-15.7C53.1,46,46.1,39,37.5,39z M37.5,67.2
                              c-6.9,0-12.5-5.6-12.5-12.5c0-6.9,5.6-12.5,12.5-12.5c6.9,0,12.5,5.6,12.5,12.5C50,61.6,44.4,67.2,37.5,67.2z M31.3,59.4h4.7v-3.1
                              h-4.7V59.4z M40.2,48.9L38,51.1l3.6,3.6L38,58.3l2.2,2.2l4.7-4.7c0.6-0.6,0.6-1.6,0-2.2L40.2,48.9z M70.3,84.5h21.9v-3.1H70.3
                              V84.5z M70.3,78.2h21.9v-3.1H70.3V78.2z M70.3,71.9h21.9v-3.1H70.3V71.9z M73.4,65.7h18.8v-3.1H73.4V65.7z M89.1,59.4h3.1v-3.1
                              h-3.1V59.4z M85.9,56.3h-3.1v3.1h3.1V56.3z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>Testing as per milestones</h6>
								<p>
									An MVP is built in stages. At every stage it has to be tested for finding bugs and
									inconsistencies that should be weeded out. We resort to regression testing and pen
									testing to ensure that the MVP is built as per specifications.
								</p>
							</div>
							<div class="num">05</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-4 col-md-6 col-sm-12 col-12 order-lg-5 order-md-3 order-3"
						data-wow-duration=".3s"
						data-wow-delay="1.2s"
					>
						<div class="mvp-process-dev-box rounded10">
							<div class="mvp-process-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M73.3,0H11.7C10.7,0,10,0.7,10,1.7V10H1.7C0.7,10,0,10.7,0,11.7v86.7c0,0.9,0.7,1.7,1.7,1.7h61.7c0.9,0,1.7-0.7,1.7-1.7
                              v-6.7h8.3c0.9,0,1.7-0.7,1.7-1.7V1.7C75,0.7,74.3,0,73.3,0z M61.7,96.7H3.3V13.3H45v15c0,0.9,0.7,1.7,1.7,1.7h15V96.7z M48.3,26.7
                              v-11l11,11H48.3z M71.7,88.3H65v-60c0-0.4-0.2-0.9-0.5-1.2l-3.8-3.8H65V20h-7.6l-5-5H55v-3.3h-6l-1.2-1.2
                              c-0.3-0.3-0.7-0.5-1.2-0.5H13.3V3.3h58.3V88.3z M95,25h-1.7c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7v65c0,0.3,0.1,0.6,0.2,0.9
                              l5,8.3c0.5,0.8,1.5,1,2.3,0.6c0.2-0.1,0.4-0.3,0.6-0.6l5-8.3c0.2-0.3,0.2-0.6,0.2-0.9V28.3H95c0.9,0,1.6,0.7,1.6,1.6v28.4h3.3V30
                              C100,27.2,97.8,25,95,25z M86.7,95.1l-2.1-3.4h4.1L86.7,95.1z M90,88.3h-6.7v-30H90V88.3z M90,55h-6.7V28.3H90V55z M83.3,25
                              c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3H83.3z M11.7,76.7h41.7v-3.3H11.7V76.7z M11.7,85h25v-3.3h-25V85z M21.7,68.3h31.7
                              V65H21.7V68.3z M18.3,65h-6.7v3.3h6.7V65z M45,41.7v15h-3.3V45h-3.3v11.7H35V40h-3.3v16.7h-3.3V45H25v11.7h-3.3v-5h-3.3v5H15V40
                              h-3.3v18.3c0,0.9,0.7,1.7,1.7,1.7h40v-3.3h-5v-15H45z M33.3,21.7H11.7V25h21.7V21.7z M26.7,30h-15v3.3h15V30z M65,11.7h-6.7V15H65
                              V11.7z M40,85h6.7v-3.3H40V85z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-process-dev-content">
								<h6>Functional Specification</h6>
								<p>
									Like a Bill of Materials that lists down all materials required for manufacturing,
									a functional specification sheet is a document that lists down the various
									prototype feedback, choice of technology, use cases, and everything else until the
									prototyping stage. The functional specification document will ensure that MVP
									development happens as per plan.
								</p>
							</div>
							<div class="num">04</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media (min-width: 992px) {
	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0;
	}
	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
}
.mvp-process-dev-box {
	padding: 30px 15px 10px;
	-webkit-box-shadow: 0 2px 9px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 9px 0 rgb(0 0 0 / 15%);
	margin: 57px 0;
	position: relative;
	border: 1px solid #e0e2e4;
	min-height: 400px;
}
.rounded10 {
	border-radius: 10px !important;
}
.mvp-process-dev-box:before {
	content: '';
	display: block;
	width: 40px;
	height: 27px;
	position: absolute;
	bottom: -27px;
	z-index: 1;
	background: url('../../../assets/images/process-arrow.png') no-repeat center center;
	right: 40px;
}
.mvp-process-dev-icon {
	margin-bottom: 30px;
}
.mvp-process-dev-icon svg {
	width: 80px;
	height: 80px;
}
.mvp-process-dev-box h6 {
	color: #202122;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.mvp-process-dev-box p {
  font-size:16px !important;
}
.mvp-process-dev-box p:last-child {
	margin-bottom: 0;
}

p {
	font: normal 14px/22px Montserrat, sans-serif;
	color: #6a6d6f;
	margin-top: 0;
}
.mvp-process-dev-box .num {
	position: absolute;
	right: 20px;
	top: 20px;
	color: #dfdfdf;
	font-size: 30px;
	line-height: 1;
}
.mvp-process-dev-box:after {
	content: '';
	display: block;
	width: 30px;
	height: 30px;
	position: absolute;
	bottom: -65px;
	z-index: 1;
	background-color: #f5f5f5;
	border-radius: 100%;
	right: 45px;
}
.mvp-process-dev-box-wrap {
	position: relative;
}
.mvp-process-dev-box-wrap:before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	height: 2px;
	width: 100%;
	background-color: #e8e8e8;
}
.mvp-process-dev-box-wrap .row > div:nth-child(4) .mvp-process-dev-box:after,
.mvp-process-dev-box-wrap .row > div:nth-child(5) .mvp-process-dev-box:after,
.mvp-process-dev-box-wrap .row > div:nth-child(6) .mvp-process-dev-box:after {
	right: inherit;
	left: 45px;
	top: -82px;
	bottom: inherit;
}

.mvp-process-dev-box-wrap .row > div:nth-child(4) .mvp-process-dev-box:before,
.mvp-process-dev-box-wrap .row > div:nth-child(5) .mvp-process-dev-box:before,
.mvp-process-dev-box-wrap .row > div:nth-child(6) .mvp-process-dev-box:before {
	right: inherit;
	left: 40px;
	top: -27px;
	bottom: inherit;
	transform: rotate(180deg);
}
</style>
