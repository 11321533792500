<template>
	<div class="faq-list-item">
		<div :class="`faq-list-title ${obj.class}`">
			<span>{{ obj.question }}</span>
			<span class="plus">
				<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M50,93.8
                      C25.8,93.8,6.2,74.2,6.2,50C6.2,25.8,25.8,6.2,50,6.2c24.2,0,43.7,19.6,43.7,43.8C93.8,74.2,74.2,93.8,50,93.8z M68.8,46.9H53.1
                      V31.3c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1v15.6H31.3c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1h15.6v15.6
                      c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1V53.1h15.6c1.7,0,3.1-1.4,3.1-3.1C71.9,48.3,70.5,46.9,68.8,46.9z"
					></path>
				</svg>
			</span>
			<span class="minus">
				<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M50,0C22.4,0,0,22.4,0,50c0,27.6,22.4,50,50,50c27.6,0,50-22.4,50-50C100,22.4,77.6,0,50,0z M50,92.3
                          c-23.3,0-42.3-19-42.3-42.3c0-23.3,19-42.3,42.3-42.3c23.3,0,42.3,19,42.3,42.3C92.3,73.3,73.3,92.3,50,92.3z M69.4,45.8H30.6
                          c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9h38.7c2.1,0,3.9-1.7,3.9-3.9C73.2,47.5,71.5,45.8,69.4,45.8z"
							></path>
						</g>
					</g>
				</svg>
			</span>
		</div>
		<div class="faq-list-content">
			<p>
				{{ obj.answer }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	},
	mounted() {
		const collapsControler = document.querySelectorAll(`.faq-list-title.${this.obj.class}`);
		collapsControler.forEach((item) => {
			item.addEventListener('click', async (e) => {
				if (e.target) {
					const eventTarget = e.target;
					eventTarget.classList.toggle('active');
					let content = await eventTarget.nextElementSibling;
					if (content) {
						if (content.style.maxHeight) {
							content.style.maxHeight = '';
							content.style.paddingTop = '0';
							content.style.paddingBottom = '0';
							content.style.border = 'none';
						} else {
							content.style.paddingTop = '20px';
							content.style.paddingBottom = '20px';
							content.style.border = '1px solid #008dd2';
							content.style.maxHeight = content.scrollHeight + 'px';
						}
					}
				}
			});
		});
	}
};
</script>

<style>
.faq-list-item {
	border: 1px solid #e6e6e6;
	border-radius: 10px;
	margin-bottom: 25px;
	overflow: hidden;
}
.faq-list-title span {
	pointer-events: none;
	cursor: pointer;
	user-select: none;
}
.faq-list-title {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
}

.faq-list-title.active .plus {
	display: none;
}
.faq-list-title.active .minus {
	display: block;
}
.faq-list-title:not(.active) .minus {
	display: none;
}
.faq-list-title:hover .plus,
.faq-list-title:hover .minus {
	fill: #fff !important;
}
.faq-list-title.active .plus,
.faq-list-title.active .minus {
	fill: #fff !important;
}
.faq-list-title:hover,
.faq-list-title.active {
	color: #fff;
	background-color: #0088d2;
}

.faq-list-title a {
	color: #202122;
	font-size: 18px;
	display: block;
}
a:hover {
	color: #0088d2;
}
.faq-list-title span.plus,
.faq-list-title span.minus {
	display: block;
	width: 26px;
	height: 26px;
	line-height: 1;
	pointer-events: none;
}
.faq-list-content p {
	font-size: 16px !important;
	user-select: none;
}
.faq-list-content::before {
	content: '';
	width: 2px;
	height: calc(100% - 40px);
	background: #008dd2;
	position: absolute;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
}
.faq-list-content {
	padding: 0px 50px;
	transition: max-height 0.2s;
	position: relative;
	margin: 0;
	background: #fff;
	border-top: none;
	border-radius: 0 0 10px 10px;
	max-height: 0;
	overflow-y: hidden;
}
.faq-list-content p:last-child {
	margin-bottom: 0;
}
</style>