<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/media-entertainment-main.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Design otherworldly experiences with our Entertainment Mobile App Development expertise
					</h2>
					<p>
						Listening to music. Watching a movie. Tuning into a podcast. Reading a book. Playing a
						game. Almost every imaginable form of entertainment has moved to the digital medium.
						Mobile apps have become the one source for all entertainment. GenixBit Labs builds mobile
						apps for entertainment that will turn your users into loyal customers who get used to it
						like a habit.
					</p>
					<p>
						And, how do we do that? During our decade-long experience in mobile app development, we
						have found out that entertainment is one of a kind of an industry. It is highly
						segmented and requires personalization. As an entertainment app owner, you would want to
						build apps that can personalization at a mass scale. That is quite a challenge.
					</p>
					<p>
						With GenixBit Labs by your side, you can overcome those challenges in Media and
						Entertainment App Development and build entertainment apps that can keep users hooked
						for hours long.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>