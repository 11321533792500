<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Redefine your Business Standards with CMS</span>
				<h2 class="fxt-process-dev">Why Choose Genixbit for CMS Web Development?</h2>
				<p>
					With a dedicated CMS team committed to excellence, we thrive market understanding and
					providing the latest CMS solutions for businesses. Tap into the whole new world of custom
					CMS development scaling business to uncharted territory.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
					<div class="why-you-invest-box-text">
						<h5 class="mb-20">Our Clients Love Us</h5>
						<a class="btn-custome btn waves-effect waves-light" href="/client-review"
							>Our Reviews</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
        {
					title: "Flexible Models",
					text: "Our custom CMS web development service is flexible enough to mend it itself to ever-evolving business requirements. Offering scalable custom CMS solutions to match your dynamic demands.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M35.2,16.6c-2.7,2.2-5.4,4.4-8.2,6.6c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3-0.2-0.5-0.3-0.8-0.5c-2.8-2.3-5.6-4.5-8.4-6.8
                  c-0.8-0.6-0.8-1.1,0-1.7c2.8-2.3,5.6-4.5,8.4-6.8c0.6-0.5,1.1-0.4,1.6,0c2.7,2.2,5.4,4.4,8.1,6.6C36.2,15.4,36.2,15.8,35.2,16.6z"></path>
                  <path d="M26.6,34.3c1.9-1.6,3.9-3.2,5.8-4.8c1.2-1,2.3-1.9,3.5-2.9c0.5-0.4,1-0.7,1.4-0.1c0.5,0.6,0.1,1-0.3,1.4
                  c-3.2,2.6-6.4,5.3-9.5,8c-0.6,0.5-1.1,0.5-1.7,0c-3.1-2.7-6.3-5.3-9.5-7.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.4-0.6-0.8-0.2-1.2
                  c0.4-0.5,0.9-0.4,1.3,0c1.7,1.4,3.4,2.8,5.1,4.3C23.7,31.9,25.2,33.1,26.6,34.3z"></path>
                  <path d="M26.6,40.8c1.9-1.6,3.9-3.2,5.8-4.8c1.2-1,2.3-1.9,3.5-2.9c0.5-0.4,1-0.7,1.4-0.1c0.5,0.6,0.1,1-0.3,1.4
                  c-3.2,2.6-6.4,5.3-9.5,8c-0.6,0.5-1.1,0.5-1.7,0c-3.1-2.7-6.3-5.3-9.5-7.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.4-0.6-0.8-0.2-1.2
                  c0.4-0.5,0.9-0.4,1.3,0c1.7,1.4,3.4,2.8,5.1,4.3C23.7,38.3,25.2,39.5,26.6,40.8z"></path>
                  <path d="M26.6,28.4c3-2.5,5.9-4.9,8.7-7.3c0.3-0.2,0.5-0.6,0.8-0.6c0.4-0.1,0.8,0,1.2,0.1c0,0.4,0.1,0.9-0.1,1.2
                  c-0.3,0.5-0.9,0.8-1.3,1.1c-2.8,2.3-5.6,4.7-8.4,7c-0.6,0.5-1.1,0.6-1.8,0c-3.1-2.7-6.3-5.3-9.5-7.9c-0.5-0.4-0.9-0.9-0.4-1.5
                  c0.5-0.5,1-0.2,1.4,0.1c2.8,2.3,5.6,4.6,8.3,7C26,27.9,26.3,28.1,26.6,28.4z"></path>
                  <path d="M10.7,3.2c0,0.9,0,1.7,0,2.5c0,0.6-0.1,1.2-0.8,1.2C9.1,6.9,9,6.4,9,5.8c0-1.5-0.1-2.9-0.2-4.4c0-0.9,0.3-1.2,1.2-1.2
                  c1.5,0,2.9-0.1,4.4-0.2c0.6,0,1.1,0.1,1.1,0.8c0,0.7-0.5,0.9-1.1,0.9c-0.8,0-1.6,0-2.4,0c-0.1,0.1-0.2,0.2-0.2,0.4
                  c0.3,0.2,0.6,0.3,0.8,0.5c1.7,1.6,3.5,3.2,5.2,4.8c0.5,0.4,0.9,0.9,0.4,1.5c-0.5,0.6-1,0.2-1.5-0.2c-1.7-1.6-3.5-3.2-5.2-4.8
                  C11.2,3.6,11,3.4,10.7,3.2z"></path>
                  <path d="M41.1,3.2c0,0.9,0,1.7,0,2.5c0,0.6,0.1,1.2,0.8,1.2c0.7,0,0.9-0.5,0.9-1.1c0-1.5,0.1-2.9,0.2-4.4c0-0.9-0.3-1.2-1.2-1.2
                  c-1.5,0-2.9-0.1-4.4-0.2c-0.6,0-1.1,0.1-1.1,0.8c0,0.7,0.5,0.9,1.1,0.9c0.8,0,1.6,0,2.4,0c0.1,0.1,0.2,0.2,0.2,0.4
                  c-0.3,0.2-0.6,0.3-0.8,0.5c-1.7,1.6-3.5,3.2-5.2,4.8c-0.5,0.4-0.9,0.9-0.4,1.5c0.5,0.6,1,0.2,1.5-0.2c1.7-1.6,3.5-3.2,5.2-4.8
                  C40.6,3.6,40.9,3.4,41.1,3.2z"></path>
                  <path d="M10.7,46.9c1.2-1.1,2.3-2.1,3.4-3.2c0.9-0.8,1.8-1.7,2.7-2.5c0.4-0.4,0.9-0.5,1.3-0.1c0.4,0.5,0.2,0.9-0.2,1.3
                  c-1.8,1.7-3.6,3.3-5.4,5c-0.2,0.2-0.5,0.4-0.7,0.6c0,0.1,0.1,0.2,0.1,0.3c0.8,0,1.7,0,2.5,0c0.6,0,1.1,0.2,1,0.9
                  c0,0.7-0.5,0.8-1.1,0.8c-1.5-0.1-3.1-0.1-4.6-0.2c-0.6,0-1-0.4-1-1c0-1.6,0.1-3.3,0.2-4.9c0-0.3,0.5-0.8,0.8-0.8
                  c0.5-0.1,0.8,0.3,0.9,0.9C10.7,44.9,10.7,45.8,10.7,46.9z"></path>
                  <path d="M42.2,46.9c-1.2-1.1-2.3-2.1-3.4-3.2c-0.9-0.8-1.8-1.7-2.7-2.5c-0.4-0.4-0.9-0.5-1.3-0.1c-0.4,0.5-0.2,0.9,0.2,1.3
                  c1.8,1.7,3.6,3.3,5.4,5c0.2,0.2,0.5,0.4,0.7,0.6c0,0.1-0.1,0.2-0.1,0.3c-0.8,0-1.7,0-2.5,0c-0.6,0-1.1,0.2-1,0.9
                  c0,0.7,0.5,0.8,1.1,0.8c1.5-0.1,3.1-0.1,4.6-0.2c0.6,0,1-0.4,1-1c0-1.6-0.1-3.3-0.2-4.9c0-0.3-0.5-0.8-0.8-0.8
                  c-0.5-0.1-0.8,0.3-0.9,0.9C42.2,44.9,42.2,45.8,42.2,46.9z"></path>
                  <path d="M33.7,15.6c-2.5,2.1-5,4-7.5,6.1c-2.5-2-4.9-4-7.5-6.1c2.5-2,5-4,7.5-6.1C28.7,11.6,31.2,13.6,33.7,15.6z"></path>
                </g>
              </svg>
          `
				},
        {
					title: "Transparency",
					text: "The soul of CMS is transparency and we commit ourselves to the fullest. Timely communication and keeping key personnel in loop from the beginning help us staying transparent and keeping you familiar with the system.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M17.5,14.2c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C20.8,15.7,19.3,14.2,17.5,14.2z M17.5,19.2
                c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7C19.2,18.4,18.4,19.2,17.5,19.2z"></path>
                <path d="M27.1,15.2c-5.4-5-13.7-5-19.2,0l-2.4,2.2l2.4,2.2c5.4,5,13.7,5,19.2,0l2.4-2.2L27.1,15.2z M26,18.5
                c-4.8,4.4-12.2,4.4-16.9,0l-1.2-1l1.2-1c4.8-4.4,12.2-4.4,16.9,0l1.2,1L26,18.5z"></path>
                <path d="M49.1,44.6L37.2,32.7c-0.6-0.6-1.4-0.7-2.2-0.5l-3.9-3.9c6-7.6,4.7-18.7-2.8-24.6S9.7-1.1,3.7,6.6s-4.7,18.6,2.9,24.6
                c6.4,5.1,15.4,5.1,21.8,0l3.9,3.9c-0.2,0.7-0.1,1.6,0.5,2.2l11.8,11.8c1.2,1.2,3.2,1.2,4.5,0C50.3,47.8,50.3,45.8,49.1,44.6
                L49.1,44.6L49.1,44.6z M1.7,17.5c0-8.7,7.1-15.8,15.8-15.8s15.8,7.1,15.8,15.8s-7.1,15.8-15.8,15.8S1.7,26.2,1.7,17.5z M29.6,30.1
                c0.2-0.2,0.3-0.3,0.5-0.5l3.7,3.7l-0.5,0.5L29.6,30.1z M46.8,48.3c-0.4,0-0.8-0.2-1.1-0.4L33.9,36.1c-0.2-0.2-0.2-0.3,0-0.5l0,0
                l0.6-0.6l0.5-0.5l0.6-0.6l0,0c0.2-0.2,0.3-0.2,0.5,0l11.8,11.8c0.6,0.6,0.6,1.6,0,2.2C47.6,48.1,47.2,48.3,46.8,48.3z"></path>
                <path d="M17.5,2.5c-8.2,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15S25.7,2.5,17.5,2.5z M17.5,30.8c-7.3,0-13.3-6-13.3-13.3
                s6-13.3,13.3-13.3s13.3,6,13.3,13.3S24.8,30.8,17.5,30.8z"></path>
                <path d="M44.1,8.3v29.1h1.7V8.3c0-2.3-1.8-4.2-4.2-4.2h-9.2v1.7h9.2C43.1,5.8,44.1,6.9,44.1,8.3z"></path>
                <path d="M5.8,41.6v-8.3H4.2v8.3c0,2.3,1.8,4.2,4.2,4.2h29.1v-1.7H8.3C6.9,44.1,5.8,43.1,5.8,41.6z"></path>
                <rect x="35.8" y="8.3" width="6.7" height="1.7"></rect>
                <rect x="35.8" y="12.5" width="6.7" height="1.7"></rect>
                <rect x="35.8" y="16.7" width="6.7" height="1.7"></rect>
                <rect x="35.8" y="20.8" width="6.7" height="1.7"></rect>
                <rect x="34.1" y="25" width="8.3" height="1.7"></rect>
                <rect x="34.1" y="29.2" width="8.3" height="1.7"></rect>
                <rect x="40" y="33.3" width="2.5" height="1.7"></rect>
                <rect x="7.5" y="35.8" width="22.5" height="1.7"></rect>
                <rect x="7.5" y="40" width="25.8" height="1.7"></rect>
                <rect x="10" y="25" width="15" height="1.7"></rect>
                <rect x="10" y="8.3" width="15" height="1.7"></rect>
              </svg>
          `
				},
        {
					title: "On-Time Sprint Run",
					text: "Time is money and we understand how urgent it will be for your business to grow quickly and outperform competitors. We create phase-wise CMS development sprints to avail you with real-time progress information.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g id="XMLID_806_">
                  <g id="XMLID_386_">
                    <path id="XMLID_389_" d="M49.9,27.3c-0.4-4.5-2.5-8.8-5.7-12c-2.7-2.7-6.1-4.5-9.6-5.3V7.5c1.4-0.4,2.5-1.7,2.5-3.2
                    c0-1.8-1.5-3.3-3.3-3.3h-7c-1.8,0-3.3,1.5-3.3,3.3c0,1.6,1.1,2.9,2.5,3.2V10c-3.5,0.8-6.9,2.6-9.6,5.3c-0.6,0.6-1.2,1.2-1.7,1.9
                    c0,0,0,0-0.1,0h-7c-0.5,0-1,0.4-1,1s0.4,1,1,1h5.7c-0.7,1.2-1.3,2.5-1.7,3.8H1c-0.5,0-1,0.4-1,1s0.4,1,1,1h10.1
                    c-0.3,1.2-0.4,2.5-0.5,3.8H6.3c-0.5,0-1,0.4-1,1s0.4,1,1,1h4.3c0.1,1.3,0.3,2.5,0.6,3.8H4.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h6.9
                    c1,2.5,2.5,4.9,4.5,6.9c3.2,3.2,7.5,5.2,12,5.7C29,49,29.6,49,30.3,49c3.8,0,7.6-1.1,10.8-3.2c0.5-0.3,0.6-0.9,0.3-1.4
                    C41,44,40.4,43.9,40,44.2c-7,4.6-16.3,3.6-22.3-2.3c-6.9-6.9-6.9-18.2,0-25.1c6.9-6.9,18.2-6.9,25.1,0c5.9,5.9,6.9,15.2,2.4,22.2
                    c-0.3,0.5-0.2,1.1,0.3,1.4c0.5,0.3,1.1,0.2,1.4-0.3C49.3,36.3,50.3,31.8,49.9,27.3z M25.4,4.3C25.4,3.6,26,3,26.7,3h7
                    c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4h-0.2h-6.7h-0.2C26,5.7,25.4,5.1,25.4,4.3z M27.9,9.7V7.6h4.7v2.1
                    C31.1,9.5,29.5,9.5,27.9,9.7z"></path>
                    <path id="XMLID_391_" d="M43.5,41.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
                    c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C44,41.7,43.8,41.6,43.5,41.6z"></path>
                    <path id="XMLID_394_" d="M30.3,14.1c-8.4,0-15.1,6.8-15.1,15.1s6.8,15.1,15.1,15.1s15.1-6.8,15.1-15.1S38.6,14.1,30.3,14.1z
                    M30.3,42.5c-7.3,0-13.2-5.9-13.2-13.2S23,16.1,30.3,16.1S43.5,22,43.5,29.3S37.6,42.5,30.3,42.5z"></path>
                    <path id="XMLID_397_" d="M36.5,21.7l-4.8,4.8c-0.4-0.2-0.9-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3l-2.2-2.2c-0.4-0.4-1-0.4-1.4,0
                    c-0.4,0.4-0.4,1,0,1.4l2.2,2.2c-0.2,0.4-0.3,0.9-0.3,1.3c0,1.7,1.4,3,3,3s3-1.4,3-3c0-0.5-0.1-0.9-0.3-1.3l4.7-4.7l0,0l0.1-0.1
                    c0.4-0.4,0.4-1,0-1.4C37.5,21.3,36.8,21.3,36.5,21.7z M30.3,30.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
                    c0.6,0,1.1,0.5,1.1,1.1C31.4,29.9,30.9,30.4,30.3,30.4z"></path>
                    <path id="XMLID_398_" d="M40.7,28.3h-0.9c-0.5,0-1,0.4-1,1s0.4,1,1,1h0.9c0.5,0,1-0.4,1-1S41.3,28.3,40.7,28.3z"></path>
                    <path id="XMLID_399_" d="M20.7,28.3h-0.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h0.9c0.5,0,1-0.4,1-1C21.7,28.7,21.3,28.3,20.7,28.3z"></path>
                    <path id="XMLID_400_" d="M30.3,20.7c0.5,0,1-0.4,1-1v-0.9c0-0.5-0.4-1-1-1s-1,0.4-1,1v0.9C29.3,20.3,29.7,20.7,30.3,20.7z"></path>
                    <path id="XMLID_424_" d="M30.3,37.8c-0.5,0-1,0.4-1,1v0.9c0,0.5,0.4,1,1,1s1-0.4,1-1v-0.9C31.2,38.3,30.8,37.8,30.3,37.8z"></path>
                    <path id="XMLID_425_" d="M1,34.3c-0.3,0-0.5,0.1-0.7,0.3S0,35,0,35.3c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3
                    C1.8,35.8,2,35.6,2,35.3c0-0.3-0.1-0.5-0.3-0.7C1.5,34.4,1.2,34.3,1,34.3z"></path>
                  </g>
                </g>
              </svg>
          `
				},
        {
					title: "Structured CMS reporting",
					text: "Our CMS web development reporting offers real picture of each development stage on paper. Charting your success route with structured CMS development service reporting helps us stay agile and make it easier to implement changes.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M1.1,45.4c0,2.4,2.1,4.4,4.7,4.4h27.3c2.3,0,4.2-1.6,4.6-3.7c2.2-0.3,3.9-2.2,3.9-4.3V22h2.2c0.2,0,0.3,0,0.4-0.1l4.1-2.6
                c0.2-0.1,0.4-0.4,0.4-0.7c0-0.3-0.1-0.5-0.4-0.7l-4.1-2.7c-0.1-0.1-0.3-0.1-0.4-0.2h-2.2v-4.4c0-0.3-0.1-0.5-0.4-0.7l-9.2-9.4
                c-0.1-0.1-0.1-0.1-0.2-0.2l0,0c-0.2-0.2-0.4-0.2-0.6-0.2H9.6C7.3,0.2,5.4,1.7,5,3.8C2.8,4.2,1.1,6,1.1,8.2V45.4z M14.7,20.4v-3.7
                h28.2v3.7H14.7z M44.5,19.7v-2.3l1.8,1.2L44.5,19.7z M32.1,3l6.6,6.8h-5.6c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7V3z
                M9.6,1.8h20.9v7.1c0,1.5,1.2,2.6,2.7,2.6h6.7v3.6H14c-0.5,0-0.8,0.4-0.8,0.8V16l-2.4,0h0c-0.4,0-0.8,0.4-0.8,0.8l0,3.5
                c0,0.4,0.4,0.8,0.8,0.8h2.4v0.1c0,0.4,0.4,0.8,0.8,0.8h1.7l0,1.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.6,0.5,1,0.5h9.7
                c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-9.6c0-0.1,0-0.1,0-0.1l0-1.2h22.6v19.8c0,1.5-1.4,2.8-3,2.8H9.6c-1.7,0-3-1.3-3-2.8
                V4.7c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1C6.6,3,7.9,1.8,9.6,1.8z M13.1,19.5h-1.6l0-1.9l1.6,0V19.5z M2.7,8.2c0-1.3,1-2.4,2.2-2.7v36.4
                c0,2.4,2.1,4.4,4.6,4.4h26.4c-0.4,1.2-1.5,2-2.9,2H5.8c-1.7,0-3-1.3-3-2.8V8.2z"></path>
                <path d="M10.8,28.6h13.2c0.4,0,0.8-0.4,0.8-0.8S24.4,27,23.9,27H10.8c-0.4,0-0.8,0.4-0.8,0.8S10.3,28.6,10.8,28.6z"></path>
                <path d="M10.8,32.5h22.9c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8H10.8c-0.4,0-0.8,0.4-0.8,0.8S10.3,32.5,10.8,32.5z"></path>
                <path d="M10.8,36.4h22.9c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8H10.8c-0.4,0-0.8,0.4-0.8,0.8S10.3,36.4,10.8,36.4z"></path>
                <path d="M10.8,40.3h22.9c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8H10.8c-0.4,0-0.8,0.4-0.8,0.8S10.3,40.3,10.8,40.3z"></path>
              </svg>
          `
				},
        {
					title: "Standardised Processes",
					text: "A proven, successful and standardised custom CMS development process ensure considering your inputs and requirements while staying committed to a bigger goal. Our CMS implement proven methods to higher success rate of each element involved.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M49.2-0.1H32.5c-0.5,0-0.8,0.4-0.8,0.8v16.7c0,0.5,0.4,0.8,0.8,0.8h16.7c0.5,0,0.8-0.4,0.8-0.8V0.7
                C50,0.3,49.6-0.1,49.2-0.1z M48.3,16.6h-15v-15h15V16.6z"></path>
                <path d="M9.2,49.9c5.1,0,9.2-4.1,9.2-9.2s-4.1-9.2-9.2-9.2S0,35.7,0,40.7S4.1,49.9,9.2,49.9z M9.2,33.2c4.1,0,7.5,3.4,7.5,7.5
                s-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5S5,33.2,9.2,33.2z"></path>
                <path d="M49.6,33.4l-8.8-5.1c-0.3-0.1-0.6-0.1-0.8,0l-8.8,5.1c-0.3,0.1-0.4,0.4-0.4,0.7v10.1c0,0.3,0.2,0.6,0.4,0.7L40,50
                c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1l8.8-5.1c0.3-0.1,0.4-0.4,0.4-0.7V34.1C50,33.8,49.8,33.5,49.6,33.4z M48.3,43.7
                l-7.9,4.6l-7.9-4.6v-9.1l7.9-4.6l7.9,4.6V43.7z"></path>
                <path d="M10-0.1c-0.3,0-0.6,0.2-0.7,0.4L0.1,17c-0.1,0.3-0.1,0.6,0,0.8c0.1,0.3,0.4,0.4,0.7,0.4h17.9c0.3,0,0.6-0.2,0.7-0.4
                c0.2-0.2,0.2-0.6,0-0.8L10.7,0.3C10.6,0.1,10.3-0.1,10-0.1z M2.2,16.6L10,2.5l7.4,14.1H2.2z"></path>
                <path d="M24.8,4.3l-1.2,1.2l2.7,2.7H20v1.7h6.3l-2.7,2.7l1.2,1.2l4.2-4.2c0.3-0.3,0.3-0.9,0-1.2L24.8,4.3z"></path>
                <path d="M24.8,44.7l4.2-4.2c0.3-0.3,0.3-0.9,0-1.2l-4.2-4.2l-1.2,1.2l2.7,2.7H20v1.7h6.3l-2.7,2.7L24.8,44.7z"></path>
                <path d="M41.7,22.4v-2.5H40v1.7H9.2c-0.5,0-0.8,0.4-0.8,0.8v4.7l-2.7-2.7l-1.2,1.2l4.2,4.2c0.2,0.2,0.4,0.2,0.6,0.2
                c0.2,0,0.4-0.1,0.6-0.2l4.2-4.2l-1.2-1.2L10,27.1v-3.8h30.8C41.3,23.2,41.7,22.9,41.7,22.4z"></path>
              </svg>
          `
				},
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
