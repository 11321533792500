<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Plan & Execute your Travel App Development</h2>
				<p>
					Features designed to make lives easier for travelers, travel operators and aggregators.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path class="st0" d="M60.8,0C43,0,28,11.8,23.2,28H5.9C2.7,28,0,30.6,0,33.9v60.2c0,3.2,2.7,5.9,5.9,5.9h34.4c3.2,0,5.9-2.7,5.9-5.9
                V75.7c4.5,1.8,9.4,2.8,14.5,2.8c21.7,0,39.2-17.6,39.2-39.2S82.4,0,60.8,0L60.8,0z M60.8,3.2c19.9,0,36,16.1,36,36s-16.1,36-36,36
                c-19.9,0-36-16.1-36-36S40.8,3.2,60.8,3.2L60.8,3.2z M48.2,18.8c-0.9,0-1.6,0.7-1.6,1.6V23H44c-2,0-3.7,1.7-3.7,3.7V56
                c0,2,1.7,3.7,3.7,3.7h33.5c2,0,3.7-1.7,3.7-3.7V26.7c0-2-1.7-3.7-3.7-3.7h-2.6v-2.6c0-0.9-0.7-1.6-1.6-1.6c0,0,0,0,0,0
                c-0.9,0-1.6,0.7-1.6,1.6V23h-9.3v-2.6c0-0.9-0.7-1.6-1.6-1.6c0,0,0,0,0,0c-0.9,0-1.6,0.7-1.6,1.6V23h-9.3v-2.6
                C49.8,19.6,49.1,18.8,48.2,18.8C48.2,18.8,48.2,18.8,48.2,18.8z M44,26.2h2.6v2.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                c0,0,0,0,0,0v-2.6h9.3v2.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0v-2.6h9.3v2.6c0,0.9,0.7,1.6,1.6,1.6
                c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0v-2.6h2.6c0.3,0,0.5,0.2,0.5,0.5v4.7H43.5v-4.7C43.5,26.4,43.7,26.2,44,26.2z M5.9,31.2h16.4
                c-0.4,1.8-0.6,3.6-0.7,5.5c-0.2-0.1-0.4-0.1-0.6-0.1h-6.5c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0,0,0,0,0,0H21
                c0.2,0,0.4,0,0.5-0.1c0.2,15.1,8.9,28.1,21.5,34.5v19.8c0,1.5-1.2,2.7-2.7,2.7H5.9c-1.5,0-2.7-1.2-2.7-2.7V33.9
                C3.2,32.4,4.4,31.2,5.9,31.2z M43.5,34.6H78V56c0,0.3-0.2,0.5-0.5,0.5H44c-0.3,0-0.5-0.2-0.5-0.5V34.6z M46.1,37.2v6.3h6.3v-6.3
                H46.1z M57.6,37.2v6.3h6.3v-6.3H57.6z M69.1,37.2v6.3h6.3v-6.3H69.1z M46.1,47.6v6.3h6.3v-6.3H46.1z M57.6,47.6v6.3h6.3v-6.3H57.6z
                M69.1,47.6v6.3h6.3v-6.3H69.1z M23.1,87.1c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7
                C25.8,88.3,24.6,87.1,23.1,87.1z"></path>
              </svg>`,
					title: 'Interactive Scheduling'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M53.3,14.2h-30v3.3h30V14.2z M53.3,20.8h-30v3.3h30V20.8z M45,27.5H23.3v3.3H45V27.5z M13.3,53.1l12.3-12.3H55
                    c2.8,0,5-2.2,5-5V9.2c0-2.8-2.2-5-5-5H5c-2.8,0-5,2.2-5,5v26.7c0,2.8,2.2,5,5,5h8.3V53.1z M5,37.5c-1,0-1.7-0.7-1.7-1.7V9.2
                    c0-1,0.7-1.7,1.7-1.7h50c1,0,1.7,0.7,1.7,1.7v26.7c0,1-0.7,1.7-1.7,1.7H24.3l-7.7,7.7v-7.7H5z M13.3,19.2c2.8,0,5-2.2,5-5
                    c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5C8.3,17,10.5,19.2,13.3,19.2z M13.3,12.5c1,0,1.7,0.7,1.7,1.7c0,1-0.7,1.7-1.7,1.7
                    c-1,0-1.7-0.7-1.7-1.7C11.7,13.2,12.3,12.5,13.3,12.5z M20,27.5c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7v8.3H10v-8.3
                    c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v5h-5v3.3H20V27.5z M95,15.8H63.3v3.3H95c1,0,1.7,0.7,1.7,1.7v26.6
                    c0,1-0.7,1.7-1.7,1.7H83.3v7.7l-7.7-7.7H45c-1,0-1.7-0.7-1.7-1.7v-3.3H40v3.3c0,2.8,2.2,5,5,5h29.3l12.3,12.3V52.5H95
                    c2.8,0,5-2.2,5-5V20.8C100,18,97.8,15.8,95,15.8z M76.7,25.8H63.3v3.3h13.3V25.8z M76.7,32.5H63.3v3.3h13.3V32.5z M76.7,42.5v-3.3
                    h-10v3.3H76.7z M86.7,30.8c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5C81.7,28.6,83.8,30.8,86.7,30.8z M86.7,24.1
                    c1,0,1.7,0.7,1.7,1.7c0,1-0.7,1.7-1.7,1.7c-1,0-1.7-0.7-1.7-1.7C85,24.8,85.7,24.1,86.7,24.1z M80,47.5h8.3v-3.3h-5v-5
                    c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v8.3h3.3v-8.3c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7V47.5z M23.3,57.5
                    c-1,0-1.7-0.7-1.7-1.7v-5h-3.3v5c0,2.8,2.2,5,5,5h8.3v12.3L44,60.8h29.3v-3.3H42.7L35,65.1v-7.7H23.3z M91.7,78.3h-7.3l-2.8-6.5
                    c-0.2-0.7-0.8-1-1.5-1c-0.7,0-1.2,0.3-1.5,1l-2.8,6.5h-7.3c-0.7,0-1.3,0.3-1.5,1c-0.2,0.7,0,1.3,0.5,1.8l5.8,5.2L71,93.6
                    c-0.2,0.7,0,1.3,0.5,1.8c0.5,0.5,1.2,0.5,1.8,0.2l6.7-3.7l6.7,3.7c0.3,0.2,0.5,0.2,0.8,0.2c0.3,0,0.7-0.2,1-0.3
                    c0.5-0.5,0.7-1.2,0.5-1.8l-2.2-7.3l5.8-5.2c0.5-0.5,0.7-1.2,0.5-1.8C93,78.8,92.3,78.3,91.7,78.3z M83.8,84.6
                    c-0.5,0.3-0.7,1-0.5,1.7l1.3,4.3l-3.8-2.2c-0.3-0.2-0.5-0.2-0.8-0.2h-0.8l-3.8,2.2l1.3-4.3c0.2-0.5,0-1.2-0.5-1.7l-3.3-2.8h3.8
                    c0.7,0,1.2-0.3,1.5-1l1.8-4l1.8,4.2c0.2,0.7,0.8,1,1.5,1h3.8L83.8,84.6z M31.7,78.3h-7.3l-2.8-6.5c-0.2-0.7-0.8-1-1.5-1
                    c-0.7,0-1.2,0.3-1.5,1l-2.8,6.5H8.3c-0.7,0-1.3,0.3-1.5,1c-0.3,0.7-0.2,1.5,0.5,1.8l5.8,5.2L11,93.6c-0.2,0.7,0,1.3,0.5,1.8
                    c0.5,0.5,1.2,0.5,1.8,0.2l6.7-3.7l6.7,3.7c0.3,0.2,0.5,0.2,0.8,0.2c0.3,0,0.7-0.2,1-0.3c0.5-0.5,0.7-1.2,0.5-1.8l-2.2-7.3l5.8-5.2
                    c0.5-0.5,0.7-1.2,0.5-1.8C33,78.8,32.3,78.3,31.7,78.3z M23.8,84.6c-0.5,0.3-0.7,1-0.5,1.7l1.3,4.3l-3.8-2.2
                    c-0.3-0.2-0.5-0.2-0.8-0.2h-0.8l-3.8,2.2l1.3-4.3c0.2-0.5,0-1.2-0.5-1.7l-3.3-2.8h3.8c0.7,0,1.2-0.3,1.5-1l1.8-4l1.8,4.2
                    c0.2,0.7,0.8,1,1.5,1h3.8L23.8,84.6z M61.7,78.3h-7.3l-2.8-6.5c-0.2-0.7-0.8-1-1.5-1c-0.7,0-1.2,0.3-1.5,1l-2.8,6.5h-7.3
                    c-0.7,0-1.3,0.3-1.5,1c-0.2,0.7,0,1.3,0.5,1.8l5.8,5.2L41,93.6c-0.2,0.7,0,1.3,0.5,1.8c0.5,0.5,1.2,0.5,1.8,0.2l6.7-3.7l6.7,3.7
                    c0.3,0.2,0.5,0.2,0.8,0.2s0.7-0.2,1-0.3c0.5-0.5,0.7-1.2,0.5-1.8l-2.2-7.3l5.8-5.2c0.5-0.5,0.7-1.2,0.5-1.8
                    C63,78.8,62.3,78.3,61.7,78.3z M53.8,84.6c-0.5,0.3-0.7,1-0.5,1.7l1.3,4.3l-3.8-2.2c-0.3-0.2-0.5-0.2-0.8-0.2h-0.8l-3.8,2.2
                    l1.3-4.3c0.2-0.5,0-1.2-0.5-1.7l-3.3-2.8h3.8c0.7,0,1.2-0.3,1.5-1l1.8-4l1.8,4.2c0.2,0.7,0.8,1,1.5,1h3.8L53.8,84.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Reviews and Recommendations'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M72.6,14.5c-15.1,0-27.4,12.3-27.4,27.4v0.4c0,5.3,1.5,10.5,4.5,15l19.1,29.3c0.9,1.3,2.3,2.1,3.8,2.1
                    c1.6,0,3-0.8,3.8-2.1l19.1-29.3c2.9-4.5,4.5-9.6,4.5-15v-0.4C100,26.8,87.7,14.5,72.6,14.5z M96.8,42.3c0,4.7-1.4,9.3-3.9,13.2
                    L73.7,84.9c-0.5,0.8-1.8,0.8-2.3,0L52.3,55.5c-2.6-3.9-3.9-8.5-3.9-13.2v-0.4c0-13.3,10.9-24.2,24.2-24.2
                    c13.3,0,24.2,10.9,24.2,24.2V42.3z M51.6,80.6h3.2V69.4h-3.2V80.6z M3.2,12.9h48.4v4.8h3.2V8.1c0-4.4-3.6-8.1-8.1-8.1H8.1
                    C3.6,0,0,3.6,0,8.1v72.6h3.2V12.9z M3.2,8.1c0-2.7,2.2-4.8,4.8-4.8h38.7c2.7,0,4.8,2.2,4.8,4.8v1.6H3.2V8.1z M0,91.9
                    c0,4.4,3.6,8.1,8.1,8.1h38.7c4.4,0,8.1-3.6,8.1-8.1v-8.1H0V91.9z M3.2,87.1h48.4v4.8c0,2.7-2.2,4.8-4.8,4.8H8.1
                    c-2.7,0-4.8-2.2-4.8-4.8V87.1z M33.9,90.3H21v3.2h12.9V90.3z M48.4,24.2v-8.1H6.5v64.5h41.9V61.3h-3.2v16.1h-6.5V45.2h3.2v-3.2
                    h-6.5v35.5H29V64.5H9.7v-6.5H29V41.9H9.7v-6.5h9.7V19.4h6.5v16.1h16.1v-3.2H29V19.4h16.1v4.8H48.4z M25.8,67.7v9.7H9.7v-9.7H25.8z
                    M25.8,45.2v9.7H9.7v-9.7H25.8z M16.1,32.3H9.7V19.4h6.5V32.3z M72.6,24.2c-9.8,0-17.7,8-17.7,17.7c0,9.8,8,17.7,17.7,17.7
                    s17.7-8,17.7-17.7C90.3,32.2,82.4,24.2,72.6,24.2z M72.6,56.5c-8,0-14.5-6.5-14.5-14.5c0-8,6.5-14.5,14.5-14.5
                    c8,0,14.5,6.5,14.5,14.5C87.1,49.9,80.6,56.5,72.6,56.5z M64.5,43.5h3.2v-3.2h-3.2V43.5z M71,43.5h3.2v-3.2H71V43.5z M77.4,43.5
                    h3.2v-3.2h-3.2V43.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'GEO Location'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M17.5,14.1c0,0.9,0.7,1.6,1.5,1.6h43.4c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6H19C18.2,12.5,17.5,13.2,17.5,14.1z
                    M45.4,9.4c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-9.3c-0.9,0-1.5,0.7-1.5,1.6c0,0.9,0.7,1.6,1.5,1.6H45.4z M54.6,85.9H19
                    c-0.9,0-1.5,0.7-1.5,1.6c0,0.9,0.7,1.6,1.5,1.6h35.6c0.9,0,1.5-0.7,1.5-1.6C56.2,86.6,55.5,85.9,54.6,85.9z M17.5,26.6
                    c0,0.9,0.7,1.6,1.5,1.6s1.5-0.7,1.5-1.6v-3.1c0-0.9,0.7-1.6,1.5-1.6h3.1c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-3.1
                    c-2.6,0-4.6,2.1-4.6,4.7V26.6z M33,21.9c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-1.5c-0.9,0-1.5,0.7-1.5,1.6
                    c0,0.9,0.7,1.6,1.5,1.6H33z M17.5,78.1c0,2.6,2.1,4.7,4.6,4.7h3.1c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-3.1
                    c-0.9,0-1.5-0.7-1.5-1.6V75c0-0.9-0.7-1.6-1.5-1.6s-1.5,0.7-1.5,1.6V78.1z M17.5,68.7c0,0.9,0.7,1.6,1.5,1.6s1.5-0.7,1.5-1.6v-1.6
                    c0-0.9-0.7-1.6-1.5-1.6s-1.5,0.7-1.5,1.6V68.7z M22.1,50c0,10.4,8.3,18.7,18.6,18.7c10.3,0,18.6-8.4,18.6-18.7S51,31.2,40.7,31.2
                    C30.4,31.2,22.1,39.6,22.1,50z M40.7,34.4c8.6,0,15.5,7,15.5,15.6s-6.9,15.6-15.5,15.6c-8.6,0-15.5-7-15.5-15.6
                    S32.2,34.4,40.7,34.4z M42.3,54.7h-3.1c-0.9,0-1.5-0.7-1.5-1.6c0-0.9-0.7-1.6-1.5-1.6s-1.5,0.7-1.5,1.6c0,2.6,2.1,4.7,4.6,4.7v1.6
                    c0,0.9,0.7,1.6,1.5,1.6c0.9,0,1.5-0.7,1.5-1.6v-1.6c2.6,0,4.6-2.1,4.6-4.7c0-2.6-2.1-4.7-4.6-4.7h-3.1c-0.9,0-1.5-0.7-1.5-1.6
                    c0-0.9,0.7-1.6,1.5-1.6h3.1c0.9,0,1.5,0.7,1.5,1.6c0,0.9,0.7,1.6,1.5,1.6s1.5-0.7,1.5-1.6c0-2.6-2.1-4.7-4.6-4.7v-1.6
                    c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6v1.6c-2.6,0-4.6,2.1-4.6,4.7c0,2.6,2.1,4.7,4.6,4.7h3.1c0.9,0,1.5,0.7,1.5,1.6
                    C43.8,54,43.1,54.7,42.3,54.7z M83.9,52c-3.3-3.2-7.6-5.1-12.2-5.1V7.8c0-4.3-3.5-7.8-7.7-7.8H17.5c-4.3,0-7.7,3.5-7.7,7.8v84.4
                    c0,4.3,3.5,7.8,7.7,7.8h35.6c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6H17.5c-2.6,0-4.6-2.1-4.6-4.7V7.8c0-2.6,2.1-4.7,4.6-4.7
                    h46.5c2.6,0,4.6,2.1,4.6,4.7v54.7c0,0.9,0.7,1.6,1.5,1.6c0.9,0,1.5-0.7,1.5-1.6V50C87,50.8,87.2,68,87.2,68.7v28.1H61.2
                    c-1.3-5.2,0.4-10.7,4.2-14.4c0.7-0.5,0.8-1.5,0.2-2.2c0,0-0.1-0.1-0.1-0.1l-6.8-7c-1.5-1.6-1.5-4.1,0-5.6c1.4-1.5,3.8-1.5,5.2-0.1
                    c0,0,0.1,0.1,0.1,0.1L77,80.8c0.6,0.6,1.6,0.6,2.2,0c0.6-0.6,0.6-1.6,0-2.2L66.4,65.3c-1.3-1.3-3-2.1-4.9-2.1
                    c-1.8,0-3.6,0.8-4.9,2.1c-2.7,2.8-2.7,7.2,0,10l5.8,5.9c-4.3,4.8-5.7,11.5-3.8,17.6c0.2,0.7,0.8,1.2,1.5,1.1h28.6
                    c0.9,0,1.5-0.7,1.5-1.6V68.7C90.3,68.3,90.3,58.4,83.9,52z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'In-App Purchases'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M3.4,13.7h3.3v3.2c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.1v-3.2h3.3c1.2,0,2.2-1,2.2-2.1c0-1.2-1-2.1-2.2-2.1H11V6.2
                    C11,5,10.1,4,8.9,4C7.7,4,6.7,5,6.7,6.2v3.2H3.4c-1.2,0-2.2,1-2.2,2.1C1.3,12.7,2.2,13.7,3.4,13.7z M97.8,54h-3.3v-3.2
                    c0-1.2-1-2.1-2.2-2.1c-1.2,0-2.2,1-2.2,2.1V54H87c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h3.3v3.2c0,1.2,1,2.1,2.2,2.1
                    c1.2,0,2.2-1,2.2-2.1v-3.2h3.3c1.2,0,2.2-1,2.2-2.1C100,54.9,99,54,97.8,54z M91.3,22.8c3.3,0,6-2.6,6-5.9c0-3.2-2.7-5.9-6-5.9
                    c-3.3,0-6,2.6-6,5.9C85.4,20.2,88,22.8,91.3,22.8z M91.3,15.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
                    c-0.9,0-1.6-0.7-1.6-1.6C89.7,16.1,90.4,15.3,91.3,15.3z M11.9,46.1c0-3.2-2.7-5.9-6-5.9c-3.3,0-6,2.6-6,5.9C0,49.4,2.7,52,6,52
                    C9.2,52,11.9,49.4,11.9,46.1z M6,47.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
                    C7.6,47,6.9,47.7,6,47.7z M12.3,26c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7S12.3,24.5,12.3,26z M80,6.8
                    c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C77.3,5.6,78.5,6.8,80,6.8z M87,44.3c1.5,0,2.7-1.2,2.7-2.7
                    c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C84.3,43.1,85.5,44.3,87,44.3z M81.7,68c-2.3-2.9-3.5-6.5-3.5-10.2V42.6
                    c0-15.5-12.1-28.5-27.3-29.6V8.1c0-1.2-1-2.1-2.2-2.1c-1.2,0-2.2,1-2.2,2.1v4.8c-6.9,0.5-13.3,3.3-18.3,8.1c-5.8,5.5-9,13-9,20.9
                    v15.9c0,3.7-1.3,7.3-3.5,10.2c-2.1,2.7-3.9,5.7-5.3,8.8c-1.1,2.4-0.8,5.2,0.7,7.4c1.5,2.3,4,3.6,6.8,3.6H36
                    c1,6,6.4,10.7,12.8,10.7c6.4,0,11.7-4.6,12.8-10.7h18.1c2.7,0,5.3-1.3,6.8-3.6c1.5-2.2,1.7-5,0.7-7.4C85.7,73.7,83.9,70.8,81.7,68
                    z M48.8,94.3c-4,0-7.4-2.7-8.3-6.4h16.7C56.1,91.6,52.8,94.3,48.8,94.3z M82.8,82c-0.7,1.1-1.9,1.7-3.2,1.7H17.9
                    c-1.3,0-2.5-0.6-3.2-1.7c-0.7-1-0.8-2.3-0.3-3.4c1.2-2.8,2.8-5.5,4.8-7.9c2.9-3.7,4.5-8.2,4.5-12.8V41.9c0-6.8,2.7-13.1,7.7-17.9
                    c4.7-4.5,10.9-6.9,17.4-6.9c0.3,0,0.6,0,0.9,0C63,17.6,73.9,29,73.9,42.6v15.2c0,3.2,0.8,6.4,2.2,9.3H56.6c-1.2,0-2.2,1-2.2,2.1
                    c0,1.2,1,2.1,2.2,2.1h22.2c0.9,1.2,1.8,2.5,2.5,3.9H68c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h15.3
                    C83.5,80.4,83.3,81.2,82.8,82z M57.8,28c0.4,0.2,0.8,0.4,1.2,0.4c0.7,0,1.4-0.3,1.8-0.9c0.7-1,0.4-2.3-0.6-3
                    c-3.2-2.1-6.9-3.3-10.7-3.5c-2-0.1-4,0.1-5.9,0.6c-1.2,0.3-1.9,1.4-1.6,2.6c0.3,1.1,1.5,1.8,2.6,1.6c1.5-0.4,3.1-0.5,4.7-0.5
                    C52.4,25.4,55.3,26.3,57.8,28z M36.9,24.6c-0.8,0.6-1.6,1.2-2.4,1.9c-0.9,0.8-1,2.2-0.1,3c0.4,0.5,1,0.7,1.6,0.7
                    c0.5,0,1-0.2,1.4-0.6c0.6-0.5,1.3-1,1.9-1.5c1-0.7,1.3-2,0.6-3C39.3,24.2,37.9,23.9,36.9,24.6z M33.1,31.1c-1-0.6-2.4-0.2-2.9,0.8
                    c-1.7,3.1-2.6,6.5-2.6,10v10.6c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.1V41.9c0-2.8,0.7-5.5,2.1-8C34.5,32.9,34.2,31.6,33.1,31.1z
                    M60.4,75.2h-9.7c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h9.7c1.2,0,2.2-1,2.2-2.1C62.6,76.2,61.6,75.2,60.4,75.2z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Push Notifications'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M38.2,10h3.4V6.7h-3.4V10z M58.4,10h3.4V6.7h-3.4V10z M56.7,30h-3.4v3.3h3.4V30z M63.5,30h-3.4v3.3h3.4V30z M70.2,30h-3.4
                    v3.3h3.4V30z M31.4,70h3.4v-3.3h-3.4V70z M38.2,70h3.4v-3.3h-3.4V70z M48.3,66.7h-3.4V70h3.4V66.7z M44.9,10h10.1V6.7H44.9V10z
                    M38.2,86.7h-6.7V90h6.7V86.7z M68.6,86.7h-6.7V90h6.7V86.7z M50,93.3c2.8,0,5.1-2.2,5.1-5c0-2.8-2.3-5-5.1-5
                    c-2.8,0-5.1,2.2-5.1,5C44.9,91.1,47.2,93.3,50,93.3z M50,86.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.7-1.7-1.7
                    C48.3,87.4,49.1,86.7,50,86.7z M36.5,30h-27v3.3h27V30z M21.3,36.7H9.5V40h11.8V36.7z M36.5,43.3h-27v3.3h27V43.3z M31.4,40h5.1
                    v-3.3h-5.1V40z M28.1,40v-3.3h-3.4V40H28.1z M90.5,63.3c-2.3,0-4.3,1.1-5.6,2.9L80.4,64V49.4l4.6-2.3c1.2,1.7,3.2,2.9,5.6,2.9
                    c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7c0,0.2,0,0.4,0.1,0.6l-3.4,1.7V5c0-2.8-2.3-5-5.1-5H24.7
                    c-2.8,0-5.1,2.2-5.1,5v18.3H7.8c-2.8,0-5.1,2.2-5.1,5v20c0,2.8,2.3,5,5.1,5h11.8V95c0,2.8,2.3,5,5.1,5h50.6c2.8,0,5.1-2.2,5.1-5
                    V67.7l3.4,1.7c0,0.2-0.1,0.4-0.1,0.6c0,3.7,3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7C97.2,66.3,94.2,63.3,90.5,63.3z M90.5,40
                    c1.9,0,3.4,1.5,3.4,3.3c0,1.8-1.5,3.3-3.4,3.3c-1.9,0-3.4-1.5-3.4-3.3C87.1,41.5,88.6,40,90.5,40z M7.8,50c-0.9,0-1.7-0.7-1.7-1.7
                    v-20c0-0.9,0.8-1.7,1.7-1.7h30.4c0.9,0,1.7,0.7,1.7,1.7v20c0,0.9-0.8,1.7-1.7,1.7h-1.7c-0.9,0-1.7,0.7-1.7,1.7v2.6L31,50.5
                    c-0.3-0.3-0.7-0.5-1.2-0.5H7.8z M77,95c0,0.9-0.8,1.7-1.7,1.7H24.7c-0.9,0-1.7-0.7-1.7-1.7V80h54V95z M77,62.3l-5.7-2.8
                    c0.4-0.9,0.7-1.8,0.7-2.8c0-1-0.2-2-0.7-2.8L77,51V62.3z M77,47.3l-7.9,3.9C68,50.5,66.6,50,65.2,50c-3.7,0-6.7,3-6.7,6.7
                    c0,3.7,3,6.7,6.7,6.7c1.4,0,2.8-0.5,3.9-1.2L77,66v10.6H23V53.3h6l6.3,6.2c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1
                    c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6-0.1c0.6-0.3,1-0.9,1-1.5v-5c2.8,0,5.1-2.2,5.1-5v-20
                    c0-2.8-2.3-5-5.1-5H23v-6.7h54V47.3z M68.6,56.7c0,1.8-1.5,3.3-3.4,3.3c-1.9,0-3.4-1.5-3.4-3.3c0-1.8,1.5-3.3,3.4-3.3
                    C67,53.3,68.6,54.8,68.6,56.7z M77,13.3H23V5c0-0.9,0.8-1.7,1.7-1.7h50.6C76.2,3.3,77,4.1,77,5V13.3z M90.5,73.3
                    c-1.9,0-3.4-1.5-3.4-3.3c0-1.8,1.5-3.3,3.4-3.3c1.9,0,3.4,1.5,3.4,3.3C93.8,71.8,92.3,73.3,90.5,73.3z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'API Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M62.3,35C62.4,17.7,48.5,3.5,31.2,3.3C13.1,3.3,0.5,19.7,0,34.9c-0.1,6,1.6,12,4.9,17.1c-1.1,3.1-1.6,6.4-1.6,9.7
                    c0,4.9,1.2,9.8,3.5,14.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1C6.9,76,7,76.2,7.2,76.3c0,0,0.1,0.1,0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3
                    l0,0c0.1,0,0.3,0.1,0.4,0.1h0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.1
                    c0.3-0.2,0.6-0.6,0.7-0.9c0,0,0,0,0,0c1.3-5.2,5.1-9.4,10.2-11c3.5,1.5,7.3,2.3,11.2,2.3C48.5,66.5,62.4,52.3,62.3,35z M31.2,63.3
                    c-3.6,0-7.2-0.8-10.5-2.3c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.5-0.1-0.7,0c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0l0,0
                    c-3.6,1.2-6.8,3.3-9.2,6.3c-0.8,1-1.5,2.1-2.1,3.3c-2.1-5.9-2-12.3,0.2-18.2c0,0,0-0.1,0-0.1c0.1-0.4,0.1-0.9-0.1-1.3
                    c0,0,0-0.1,0-0.1C4.9,46.2,3.3,40.7,3.3,35C3.8,21.3,15,6.7,31.2,6.7C46.7,6.8,59.1,19.5,59,35C59.1,50.5,46.7,63.2,31.2,63.3z
                    M39.4,21.7c-0.1-4.5-3.8-8.2-8.3-8.1c-4.5,0.1-8.2,3.8-8.1,8.3c0.1,4.5,3.7,8.1,8.2,8.1C35.7,30,39.4,26.2,39.4,21.7z M31.2,26.7
                    c-2.8,0.1-5.1-2.1-5.1-4.9c-0.1-2.8,2.1-5.1,4.9-5.1c2.8-0.1,5.1,2.1,5.1,4.9c0,0,0,0.1,0,0.1C36.1,24.4,33.9,26.6,31.2,26.7z
                    M34.3,30h-3.1H28c-4.6,0-8.3,3.7-8.3,8.3V55c0,0.9,0.7,1.7,1.7,1.7H41c0.9,0,1.7-0.7,1.7-1.7V38.4C42.6,33.8,38.9,30,34.3,30z
                    M39.3,53.3H23v-15c0-2.8,2.2-5,5-5h6.3c2.8,0,5.1,2.3,5.1,5.1V53.3z M75.4,50c-12.7,0-23,9.7-23,21.7c0,11.9,10.3,21.7,23,21.7
                    c2.8,0,5.5-0.5,8.1-1.5c1.9,0.6,3.6,1.7,4.9,3.2c0.9,1.1,1.6,2.4,2,3.8c0.1,0.2,0.1,0.4,0.3,0.5c0,0,0.1,0.1,0.1,0.1
                    c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1l0,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
                    l0,0c0,0,0,0,0,0c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.3,0.4-0.4l0,0l0,0c2.8-4.9,3.4-10.7,1.5-16.1
                    c2.3-3.4,3.5-7.4,3.5-11.5C97.5,59.5,87.5,50.1,75.4,50z M91.7,81.9c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
                    c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c0.8,1.9,1.2,3.9,1.2,6c0,1.6-0.2,3.2-0.7,4.8
                    c-0.3-0.5-0.7-1-1.1-1.5c-1.8-2-4.1-3.5-6.7-4.3l0,0c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.5,0-0.7,0
                    c-0.1,0-0.1,0-0.2,0c-2.4,1-4.9,1.5-7.4,1.5c-10.8,0-19.6-8.2-19.6-18.3s8.8-18.3,19.6-18.3c10.3,0.1,18.9,8.1,19.6,18.4
                    C95.1,75.4,93.9,78.9,91.7,81.9z M81.9,0c-10,0.1-18.1,8.3-18.1,18.3c-0.2,10,7.8,18.2,17.8,18.3c10,0.2,18.2-7.8,18.3-17.8
                    c0-0.2,0-0.4,0-0.6C100.1,8.3,92,0.1,81.9,0z M96.7,18.3c0.1,8.2-6.5,14.9-14.7,15c-8.2-0.1-14.8-6.8-14.7-15
                    c-0.2-8.1,6.3-14.8,14.4-15c8.1-0.2,14.8,6.3,15,14.4C96.7,18,96.7,18.1,96.7,18.3z M88.7,8.7c-1.4-0.5-2.9-0.5-4.2,0
                    c-1,0.4-1.8,0.9-2.5,1.7c-1.7-1.9-4.3-2.5-6.7-1.7c-1.9,0.8-3.3,2.3-4,4.2c-0.9,2.5-1,5.2-0.1,7.7c1.9,6.5,7.7,9.8,10.1,10.9
                    c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.1l0,0l0,0l0,0l0,0c0.2,0,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0c2.4-1.1,8.2-4.5,10.1-11
                    c0.9-2.5,0.9-5.2-0.1-7.7C92,11,90.5,9.5,88.7,8.7z M89.6,19.7c-1.3,3.7-4.1,6.8-7.6,8.5c-3.6-1.7-6.3-4.8-7.6-8.5
                    c-0.7-1.7-0.7-3.6-0.1-5.4c0.4-1,1.1-1.9,2.1-2.4c0.6-0.2,1.3-0.2,1.9,0c1,0.4,1.7,1.3,2.1,2.3c0,0,0,0.1,0.1,0.1
                    c0.2,0.4,0.5,0.7,0.9,0.9c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.2,0.1,0.5,0.1,0.7,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1-0.1
                    c0.4-0.2,0.7-0.5,0.9-0.9c0,0,0-0.1,0-0.1c0.3-1,1.1-1.8,2.1-2.3c0.6-0.2,1.3-0.2,1.9,0c1,0.5,1.8,1.4,2.1,2.4
                    C90.3,16,90.2,17.9,89.6,19.7z M85.5,65.5h-4.5c1.3-4.7,0.8-8.2-1.3-9.7c-1.7-1.2-3.9-1.1-5.5,0.3c-0.7,1-0.9,2.2-0.6,3.4
                    c0.1,0.3,0.1,0.7,0.1,1c0,1.4-1.9,3.2-5.3,5h-6.1c-0.9,0-1.7,0.7-1.7,1.7c0,0,0,0,0,0v13.3c0,0.9,0.7,1.7,1.7,1.7H82
                    c4.5,0,8.2-3.7,8.2-8.3v-3.6C90.2,67.7,88.1,65.5,85.5,65.5z M67.2,78.9h-3.2v-10h3.2V78.9z M86.8,73.9c0,2.7-2.1,4.9-4.8,4.9
                    H70.5V68.2c4.4-2.4,6.5-4.9,6.6-7.6c0-0.6-0.1-1.1-0.2-1.7c0-0.2-0.1-0.4-0.1-0.5c0.3,0,0.7,0.1,0.9,0.2c0.7,0.5,1.1,3.4-0.6,8.1
                    c-0.3,0.9,0.1,1.8,1,2.1c0.2,0.1,0.4,0.1,0.6,0.1h6.8c0.8,0,1.4,0.6,1.4,1.4V73.9z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Social Media Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M98.4,64.5H80.6V1.6C80.6,0.7,79.9,0,79,0H1.6C0.7,0,0,0.7,0,1.6v87.1C0,94.9,5.1,100,11.3,100h77.4
                    c6.2,0,11.3-5.1,11.3-11.3V66.1C100,65.2,99.3,64.5,98.4,64.5z M11.3,96.8c-4.4,0-8.1-3.6-8.1-8.1V3.2h74.2v85.5
                    c0,3.2,1.3,6,3.4,8.1H11.3z M96.8,88.7c0,4.4-3.6,8.1-8.1,8.1c-4.4,0-8.1-3.6-8.1-8.1v-21h16.1V88.7z M6.5,71h32.3v-3.2H6.5V71z
                    M6.5,77.4h32.3v-3.2H6.5V77.4z M6.5,83.9h32.3v-3.2H6.5V83.9z M6.5,90.3h32.3v-3.2H6.5V90.3z M41.9,71h32.3v-3.2H41.9V71z
                    M41.9,77.4h32.3v-3.2H41.9V77.4z M41.9,83.9h32.3v-3.2H41.9V83.9z M41.9,90.3h32.3v-3.2H41.9V90.3z M67.7,6.5
                    c-3,0-5.5,2.1-6.2,4.8h-6.7c-0.4,0-0.8,0.2-1.1,0.5l-4.6,4.6c-3.3-5.9-9.6-9.9-16.9-9.9c-0.9,0-1.6,0.7-1.6,1.6v6.5
                    c0,0.9,0.7,1.6,1.6,1.6c5.3,0,9.7,4.3,9.7,9.7c0,0.9,0.7,1.6,1.6,1.6H50c0.9,0,1.6-0.7,1.6-1.6c0-2.2-0.4-4.3-1.1-6.3l5-5h6
                    c0.7,2.8,3.2,4.8,6.2,4.8c3.6,0,6.5-2.9,6.5-6.5S71.3,6.5,67.7,6.5z M45.1,24.2c-0.7-5.8-5.4-10.5-11.2-11.2V9.8
                    c7.6,0.8,13.7,6.8,14.4,14.4H45.1z M67.7,16.1c-1.8,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2S69.5,16.1,67.7,16.1
                    z M47.2,47.9c0.3,0.3,0.7,0.5,1.1,0.5h6.7c0.7,2.8,3.2,4.8,6.2,4.8c3.6,0,6.5-2.9,6.5-6.5s-2.9-6.5-6.5-6.5c-3,0-5.5,2.1-6.2,4.8
                    h-6l-5.4-5.4c1-2.3,1.5-4.8,1.5-7.5c0-0.9-0.7-1.6-1.6-1.6h-6.5c-0.9,0-1.6,0.7-1.6,1.6c0,5.3-4.3,9.7-9.7,9.7
                    c-5.3,0-9.7-4.3-9.7-9.7c0-5.3,4.3-9.7,9.7-9.7c0.9,0,1.6-0.7,1.6-1.6v-6.5c0-0.9-0.7-1.6-1.6-1.6c-10.7,0-19.4,8.7-19.4,19.4
                    s8.7,19.4,19.4,19.4c6.8,0,12.8-3.5,16.3-8.9L47.2,47.9z M61.3,43.5c1.8,0,3.2,1.4,3.2,3.2S63.1,50,61.3,50
                    c-1.8,0-3.2-1.4-3.2-3.2S59.5,43.5,61.3,43.5z M24.2,16.2v3.2c-5.8,0.7-10.4,5.4-11.2,11.2H9.8C10.5,23,16.6,17,24.2,16.2z
                    M24.2,48.3c-7.6-0.8-13.7-6.8-14.4-14.4H13c0.7,5.8,5.4,10.4,11.2,11.2V48.3z M27.4,48.3V45c5.8-0.7,10.5-5.4,11.2-11.2h3.2
                    C41.1,41.5,35,47.5,27.4,48.3z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Comprehensive Reporting'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
