    <template>
        <seciton>
            <FirstStep></FirstStep>

            <mobileSecondStep></mobileSecondStep>

            <mobileStepPlatformNew></mobileStepPlatformNew>
            <mobileStep4IOS></mobileStep4IOS>
            <mobileStep4Android></mobileStep4Android>
            <mobileStepDesign></mobileStepDesign>
            <mobileStepUse></mobileStepUse>

            <mobileStepLogin></mobileStepLogin>
            <mobileStepIntegration></mobileStepIntegration>
            <mobileStepChat></mobileStepChat>
            <mobileGpsStep></mobileGpsStep>
            <mobileEarnStep></mobileEarnStep>
            <mobileAdminPanel></mobileAdminPanel>
            <FormStep></FormStep>

            <mobileStepPlatformOld></mobileStepPlatformOld>
            <mobileStep4IOSOld></mobileStep4IOSOld>
            <mobileTechnologyIosOld></mobileTechnologyIosOld>
            <mobileStep4AndroidOld></mobileStep4AndroidOld>
            <mobileTechnologyBothOld></mobileTechnologyBothOld>
            <mobileTechnologyAndroidOld></mobileTechnologyAndroidOld>
            <mobileStepUseOld></mobileStepUseOld>
            <mobileIosDescription></mobileIosDescription>

            <webSecondStep></webSecondStep>
            <webSolutionNew></webSolutionNew>
            <webCategory></webCategory>
            <webTech8></webTech8>
            <webDesign></webDesign>
            <webMobileNeed></webMobileNeed>
            <webMobilePlatform></webMobilePlatform>
            <StaticFromStep></StaticFromStep>
            <webIosUse></webIosUse>
            <webMobileDesign></webMobileDesign>
            <webGpsStep></webGpsStep>
            <webFormStep></webFormStep>
            <webBothUse></webBothUse>
            <webTech6></webTech6>
            <webPayment></webPayment>
            <webKindStep></webKindStep>
            <webMobilePlatformCustom></webMobilePlatformCustom>
            <webIosUseCustom></webIosUseCustom>
            <webBothUseCustom></webBothUseCustom>
            <webMakeMoney></webMakeMoney>
            <webChatStap></webChatStap>
            <webMakeMoneyLive></webMakeMoneyLive>
            <webPaymentLive></webPaymentLive>
            <webLoginStep></webLoginStep>
            <webMakeMoneyBlog></webMakeMoneyBlog>
            <webFormStepBlog></webFormStepBlog>
            <webSolutionOld></webSolutionOld>
            <webCategoryOld></webCategoryOld>
            <webTech8Old></webTech8Old>
            <webDesignNeed></webDesignNeed>
            <webMobileNeedOld></webMobileNeedOld>
            <webDescriptionOld></webDescriptionOld>
            <webMobilePlatformOld></webMobilePlatformOld>
            <webDescriptionOldSpc></webDescriptionOldSpc>
            <webTech5Old></webTech5Old>
            <webMobilePlatformOldCms></webMobilePlatformOldCms>
            <webKindStepOld></webKindStepOld>
            <webIosUseCustomOld></webIosUseCustomOld>
            <webBothUseCustomOld></webBothUseCustomOld>
            <webDescriptionOldStatic></webDescriptionOldStatic>

            <gameSecondStep></gameSecondStep>
            <gameType></gameType>
            <gameMultiplayer></gameMultiplayer>
            <gameCategory></gameCategory>
            <gameDesign></gameDesign>
            <gameLogin></gameLogin>
            <gameManage></gameManage>
            <gameEarn></gameEarn>

        </seciton>
    </template>

<script>
import FirstStep from "./FirstStep"

import mobileSecondStep from "./mobile/global/mobileSecondStep"

import mobileStepPlatformNew from './mobile/new-scenario/mobileStepPlatformNew'
import mobileStep4IOS from './mobile/new-scenario/mobileStep4IOS'
import mobileStep4Android from './mobile/new-scenario/mobileStep4Android'
import mobileStepDesign from './mobile/new-scenario/mobileStepDesign'
import mobileStepUse from './mobile/new-scenario/mobileStepUse'

import mobileStepLogin from "./mobile/new-scenario/mobileStepLogin"
import mobileStepIntegration from "./mobile/new-scenario/mobileStepIntegration"
import mobileStepChat from "./mobile/new-scenario/mobileStepChat"
import mobileGpsStep from "./mobile/new-scenario/mobileGpsStep"
import mobileEarnStep from "./mobile/new-scenario/mobileEarnStep"
import mobileAdminPanel from "./mobile/new-scenario/mobileAdminPanel"
import FormStep from "./mobile/new-scenario/FormStep"

import mobileStepPlatformOld from './mobile/old-scenario/mobileStepPlatformOld'
import mobileStep4IOSOld from './mobile/old-scenario/IOS/mobileStep4IOSOld'
import mobileTechnologyIosOld from './mobile/old-scenario/IOS/mobileTechnologyIosOld'
import mobileStep4AndroidOld from './mobile/old-scenario/Andriod/mobileStep4AndroidOld'
import mobileTechnologyAndroidOld from './mobile/old-scenario/Andriod/mobileTechnologyAndroidOld'
import mobileTechnologyBothOld from './mobile/old-scenario/Both/mobileTechnologyBothOld'
import mobileStepUseOld from './mobile/old-scenario/mobileStepUseOld'
import mobileIosDescription from './mobile/old-scenario/mobileIosDescription'


import webSecondStep from "./web/webSecondStep"
import webSolutionNew from "./web/new-scenario/webSolutionNew"
import webCategory from "./web/new-scenario/webCategory"
import webTech8 from "./web/new-scenario/webTech8"
import webDesign from "./web/new-scenario/webDesign"
import webMobileNeed from "./web/new-scenario/webMobileNeed"
import webMobilePlatform from "./web/new-scenario/webMobilePlatform"
import StaticFromStep from "./web/new-scenario/StaticFromStep"
import webIosUse from "./web/new-scenario/webIosUse"
import webMobileDesign from "./web/new-scenario/webMobileDesign"
import webGpsStep from "./web/new-scenario/webGpsStep"
import webFormStep from "./web/new-scenario/webFormStep"
import webBothUse from "./web/new-scenario/webBothUse"
import webTech6 from "./web/new-scenario/webTech6"
import webPayment from "./web/new-scenario/webPayment"
import webKindStep from "./web/new-scenario/webKindStep"
import webMobilePlatformCustom from "./web/new-scenario/webMobilePlatformCustom"
import webIosUseCustom from "./web/new-scenario/webIosUseCustom"
import webBothUseCustom from "./web/new-scenario/webBothUseCustom"
import webMakeMoney from "./web/new-scenario/webMakeMoney"
import webChatStap from "./web/new-scenario/webChatStap"
import webMakeMoneyLive from "./web/new-scenario/webMakeMoneyLive"
import webPaymentLive from "./web/new-scenario/webPaymentLive"
import webLoginStep from "./web/new-scenario/webLoginStep"
import webMakeMoneyBlog from "./web/new-scenario/webMakeMoneyBlog"
import webFormStepBlog from "./web/new-scenario/webFormStepBlog"

import webSolutionOld from "./web/old-scenario/webSolutionOld"
import webCategoryOld from "./web/old-scenario/webCategoryOld"
import webTech8Old from "./web/old-scenario/webTech8Old"
import webDesignNeed from "./web/old-scenario/webDesignNeed"
import webMobileNeedOld from "./web/old-scenario/webMobileNeedOld"
import webDescriptionOld from "./web/old-scenario/webDescriptionOld"
import webMobilePlatformOld from "./web/old-scenario/webMobilePlatformOld"
import webDescriptionOldSpc from "./web/old-scenario/webDescriptionOldSpc"
import webTech5Old from "./web/old-scenario/webTech5Old"
import webMobilePlatformOldCms from "./web/old-scenario/webMobilePlatformOldCms"
import webKindStepOld from "./web/old-scenario/webKindStepOld"
import webIosUseCustomOld from "./web/old-scenario/webIosUseCustomOld"
import webBothUseCustomOld from "./web/old-scenario/webBothUseCustomOld"
import webDescriptionOldStatic from "./web/old-scenario/webDescriptionOldStatic"

import gameSecondStep from './game/gameSecondStep'
import gameType from './game/gameType'
import gameMultiplayer from './game/gameMultiplayer'
import gameCategory from './game/gameCategory'
import gameDesign from './game/gameDesign'
import gameLogin from './game/gameLogin'
import gameManage from './game/gameManage'
import gameEarn from './game/gameEarn'

export default {
    mounted() {
        const visiblePagesItems = document.querySelectorAll(".main-cont-app-cost .choice-item");
        const choiceItemsMulti = document.querySelectorAll(".main-cont-app-cost .choice-item-multi");
        const appConstGoBackItems = document.querySelectorAll(".app-cost-goBack");
        const appConstGoNextItems = document.querySelectorAll(".app-cost-goNext");

        const pathes = {
            Taxi : {
                1: "mobileStepLogin",
                2: "mobileStepChat",
                3: "mobileEarnStep",
                4: "FormStep",
            },
            Travel : {
                1: "mobileStepLogin",
                2: "mobileAdminPanel",
                3: "mobileGpsStep",
                4: "mobileStepChat",
                5: "mobileEarnStep",
                6: "FormStep",
            },
            Chat : {
                1: "mobileStepLogin",
                2: "mobileAdminPanel",
                3: "mobileGpsStep",
                4: "mobileEarnStep",
                5: "FormStep",
            },
            Education : {
                1: "mobileStepLogin",
                2: "mobileAdminPanel",
                3: "mobileGpsStep",
                4: "mobileStepChat",
                5: "mobileEarnStep",
                6: "mobileStepIntegration",
                7: "FormStep",
            },
            Food : {
                1: "mobileStepLogin",
                2: "mobileStepChat",
                3: "mobileEarnStep",
                4: "mobileStepIntegration",
                5: "FormStep",
            },
            ios : {
                1: "mobileStep4IosOld",
                2: "mobileTechnologyIosOld",
                3: "mobileStepUseOld",
                4: "mobileIosDescription",
                5: "FormStep"
            },
            andriod : {
                1: "mobileStep4AndroidOld",
                2: "mobileTechnologyAndroidOld",
                3: "mobileStepUseOld",
                4: "mobileIosDescription",
                5: "FormStep"
            },
            both : {
                1: "mobileStep4AndroidOld",
                2: "mobileTechnologyBothOld",
                3: "mobileStepUseOld",
                4: "mobileIosDescription",
                5: "FormStep"
            },
            ecommerce : {
                1: "webTech8",
                2: "webDesign",
                3: "webMobileNeed",
                4: "webMobilePlatform"
            },
            CustomWeb : {
                1: "webTech6",
                2: "webDesign",
                3: "webPayment",
                4: "webMobileNeed",
                5: "webMobilePlatformCustom",
            },
            webIos : {
                1: "webIosUse",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webFormStep"
            },
            webBoth : {
                1: "webBothUse",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webFormStep"
            },
            webEcomm: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webMakeMoney",
                4: "webFormStep"
            },
            webTravel: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webChatStap",
                4: "webMakeMoney",
                5: "webFormStep"
            },
            liveStream: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webMakeMoneyLive",
                4: "webPaymentLive",
                5: "webFormStep"
            },
            webOther : {
                1: "webLoginStep",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webChatStap",
                5: "webMakeMoney",
                6: "webFormStep",
            },
            blogWeb : {
                1: "webTech6",
                2: "webDesign",
                3: "webPayment",
                4: "webMakeMoneyBlog",
                5: "webFormStepBlog",
            },
            staticWeb : {
                1 : "webTech6",
                2 : "webDesign",
                3 : "webFormStepBlog"
            },
            ecommerceOld: {
                1 : "webTech8Old",
                2 : "webDesignNeed",
                3 : "webMobileNeedOld",
                4 : "webMobilePlatformOld"
            },
            webIosOld : {
                1: "webIosUse",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webDescriptionOldSpc",
                5: "webFormStep"
            },
            webBothOld : {
                1: "webBothUse",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webDescriptionOldSpc",
                5: "webFormStep"
            },
            CMS : {
                1 : "webTech5Old",
                2 : "webDesignNeed",
                3 : "webMobileNeedOld",
                4 : "webMobilePlatformOldCms"
            },
            webIosOldCms : {
                1: "webKindStepOld",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webDescriptionOldSpc",
                5: "webFormStep"
            },
            webBothOldCms : {
                1: "webKindStepOld",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webDescriptionOldSpc",
                5: "webFormStep"
            },
            webEcommCms: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webMakeMoney",
                4: "webDescriptionOldSpc",
                5: "webFormStep"
            },
            webTravelCms: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webChatStap",
                4: "webMakeMoney",
                5: "webDescriptionOldSpc",
                6: "webFormStep"
            },
            liveStreamCms: {
                1: "webMobileDesign",
                2: "webGpsStep",
                3: "webMakeMoneyLive",
                4: "webPaymentLive",
                5: "webDescriptionOldSpc",
                6: "webFormStep",
            },
            webOtherCms: {
                1: "webLoginStep",
                2: "webMobileDesign",
                3: "webGpsStep",
                4: "webChatStap",
                5: "webMakeMoney",
                6: "webDescriptionOldSpc",
                7: "webFormStep",
            },
            staticOld: {
                1: "webTech6",
                2: "webDesignNeed",
                3: "webDescriptionOldStatic",
                4: "webFormStepBlog",
            },
            game: {
                1: "gameSecondStep",
                2: "gameType",
                3: "gameMultiplayer",
                4: "gameCategory",
                5: "gameDesign",
                6: "gameLogin",
                7: "gameManage",
                8: "gameEarn",
                9: "FormStep",
            }
        }
        let currentPath = {};
        let currentPathKey = 1;
        let currentSubPathKey = 1;

        visiblePagesItems.forEach((item) =>
            item.addEventListener('click', () => {
                if(item.classList.contains("use-item")) {
                    currentPath = pathes[item.dataset.path];
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentPathKey = currentPathKey + 1;
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                } else if(item.classList.contains("spc-item")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentPathKey = currentPathKey + 1;
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                } else if(item.classList.contains("spc-sub-item")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentSubPathKey = currentSubPathKey + 1;
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                } else if(item.classList.contains("turn-item")) {
                    currentPath = pathes[item.dataset.path];
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentSubPathKey = currentSubPathKey + 1;
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                } else {
                    item.classList.add("active")
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                }
                console.log(currentPath)
                console.log(currentPathKey)
                console.log(currentSubPathKey)
            })
        );
        const choiceItemMultAll = document.querySelectorAll(".choice-item-mult-all")
        let controleNextButton = 0;
        choiceItemsMulti.forEach((item) => {
            item.addEventListener('click', () => {
                if(item.classList.contains("active")) {
                    item.classList.remove("active");
                    --controleNextButton;
                } else {
                    item.classList.add("active");
                    ++controleNextButton;
                    choiceItemMultAll.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                            --controleNextButton;
                        }
                    })
                }
                if(controleNextButton>0) {
                    appConstGoNextItems.forEach((item) =>
                        item.classList.add("active")
                    )
                } else {
                    appConstGoNextItems.forEach((item) =>
                        item.classList.remove("active")
                    )
                } if(item.classList.contains("choice-item-mult-all")) {
                    controleNextButton = 1;
                    choiceItemsMulti.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                        }
                    })
                    item.classList.add("active")
                }
            })
        });

        appConstGoBackItems.forEach((item) =>
            item.addEventListener('click', () => {
                if(item.classList.contains("spc-item")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.add('show');
                    currentPathKey = currentPathKey - 1;
                    if(item.classList.contains("spc-item-earn")) {
                        document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item-multi`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                        })
                    } else {
                        document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                        })
                    }
                } else if(item.classList.contains("turn-item")) {
                    currentPath = pathes[item.dataset.path];
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.add('show');
                    currentSubPathKey = 1;
                    currentPathKey = currentPathKey - 1;
                    if(item.classList.contains("spc-item-earn")) {
                            document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item-multi`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                    })
                    } else {
                        document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                        })
                    }
                } else if(item.classList.contains("spc-sub-item")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.add('show');
                    currentSubPathKey = currentSubPathKey - 1;
                    if(item.classList.contains("spc-item-earn")) {
                        document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item-multi`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                        })
                    } else {
                        document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                            if(choiceItem.classList.contains("active")) {
                                choiceItem.classList.remove("active")
                            }
                        })
                    }
                } else {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.prev}`).classList.add('show');
                    document.querySelectorAll(`.main-cont-app-cost.${item.dataset.parent} .choice-item`).forEach((choiceItem) => {
                        if(choiceItem.classList.contains("active")) {
                            choiceItem.classList.remove("active")
                        }
                    })
                }
                console.log(currentPath)
                console.log(currentPathKey)
                console.log(currentSubPathKey)
            })
        );

        appConstGoNextItems.forEach((item) =>
            item.addEventListener('click', () => {
                if(item.classList.contains("spc-sub-item")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentSubPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentSubPathKey = currentSubPathKey + 1;
                } else if(item.classList.contains("app-cost-goNext-webOld")) {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.next} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                }
                else {
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.remove('show');
                    document.querySelector(`.main-cont-app-cost.${item.dataset.parent}`).classList.add('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.remove('dontShow');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]}`).classList.add('show');
                    document.querySelector(`.main-cont-app-cost.${currentPath[currentPathKey]} .app-cost-goBack`).dataset.prev = item.dataset.parent;
                    currentPathKey = currentPathKey + 1;
                }
            })
        );

        const choiceItemMultiOld = document.querySelectorAll(".choice-item-multi-old")
        const choiceItemMultiOldCross = document.querySelectorAll(".choice-item-multi-cross")
        const oldNext = document.querySelector(".app-cost-goNext-old");
        let andriodFlag = false;
        let iosFlag = false;
        let crossFlag = false;

        choiceItemMultiOld.forEach((item) => {
            item.addEventListener('click', () => {
                if(item.classList.contains("active")) {
                    item.classList.remove("active");
                    if((item.classList.contains("andriod1")) || (item.classList.contains("andriod2"))) {
                        andriodFlag = false;
                    }
                    if((item.classList.contains("ios1")) || (item.classList.contains("ios2"))) {
                        iosFlag = false;
                    }
                    if(item.classList.contains("choice-item-multi-cross")) {
                        crossFlag = false;
                    }
                }
                else {
                    item.classList.add("active");
                }
                if((item.classList.contains("andriod1")) && (item.classList.contains("active"))) {
                    andriodFlag = true;
                    crossFlag = false;
                    choiceItemMultiOldCross.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                        }
                    })
                    if(document.querySelector(".andriod2").classList.contains("active")) {
                        document.querySelector(".andriod2").classList.remove("active")
                    }
                } else if((item.classList.contains("andriod2")) && (item.classList.contains("active"))) {
                    andriodFlag = true;
                    crossFlag = false;
                    choiceItemMultiOldCross.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                        }
                    })
                    if(document.querySelector(".andriod1").classList.contains("active")) {
                        document.querySelector(".andriod1").classList.remove("active")
                    }
                }

                if((item.classList.contains("ios1")) && (item.classList.contains("active"))) {
                    iosFlag = true;
                    crossFlag = false;
                    choiceItemMultiOldCross.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                        }
                    })
                    if(document.querySelector(".ios2").classList.contains("active")) {
                        document.querySelector(".ios2").classList.remove("active")
                    }
                } else if((item.classList.contains("ios2")) && (item.classList.contains("active"))) {
                    iosFlag = true;
                    crossFlag = false;
                    choiceItemMultiOldCross.forEach((cross) => {
                        if(cross.classList.contains("active")) {
                            cross.classList.remove("active")
                        }
                    })
                    if(document.querySelector(".ios1").classList.contains("active")) {
                        document.querySelector(".ios1").classList.remove("active")
                    }
                }

                if((item.classList.contains("choice-item-multi-cross")) && (item.classList.contains("active"))) {
                    if(document.querySelector(".andriod1").classList.contains("active")) {
                        document.querySelector(".andriod1").classList.remove("active")
                        andriodFlag = false;
                    }
                    if(document.querySelector(".andriod2").classList.contains("active")) {
                        document.querySelector(".andriod2").classList.remove("active")
                        andriodFlag = false;
                    }
                    if(document.querySelector(".ios1").classList.contains("active")) {
                        document.querySelector(".ios1").classList.remove("active")
                        iosFlag = false;
                    }
                    if(document.querySelector(".ios2").classList.contains("active")) {
                        document.querySelector(".ios2").classList.remove("active")
                        iosFlag = false;
                    }
                    crossFlag= true;
                    choiceItemMultiOldCross.forEach((cross) => {
                        if(cross.dataset.key !== item.dataset.key ) {
                            if(cross.classList.contains("active")) {
                                cross.classList.remove("active")
                            }
                        }
                    })
                }

                if((andriodFlag&&iosFlag) || crossFlag) {
                    oldNext.classList.add("active")
                } else {
                    if(oldNext.classList.contains("active")) {
                        oldNext.classList.remove("active")
                    }
                }
            })
        });



    },
    components: {
        FirstStep,

        mobileSecondStep,
        mobileStepPlatformNew,
        mobileStep4IOS,
        mobileStep4Android,
        mobileStepDesign,
        mobileStepUse,

        mobileStepLogin,
        mobileStepIntegration,
        mobileStepChat,
        mobileGpsStep,
        mobileEarnStep,
        mobileAdminPanel,
        FormStep,

        mobileStepPlatformOld,
        mobileStep4IOSOld,
        mobileTechnologyIosOld,
        mobileStep4AndroidOld,
        mobileStepUseOld,
        mobileTechnologyAndroidOld,
        mobileIosDescription,
        mobileTechnologyBothOld,

        webSecondStep,
        webSolutionNew,
        webCategory,
        webTech8,
        webDesign,
        webMobileNeed,
        webMobilePlatform,
        StaticFromStep,
        webIosUse,
        webMobileDesign,
        webGpsStep,
        webFormStep,
        webBothUse,
        webTech6,
        webPayment,
        webKindStep,
        webMobilePlatformCustom,
        webIosUseCustom,
        webBothUseCustom,
        webMakeMoney,
        webChatStap,
        webMakeMoneyLive,
        webPaymentLive,
        webLoginStep,
        webMakeMoneyBlog,
        webFormStepBlog,

        webSolutionOld,
        webCategoryOld,
        webTech8Old,
        webDesignNeed,
        webMobileNeedOld,
        webDescriptionOld,
        webMobilePlatformOld,
        webDescriptionOldSpc,
        webTech5Old,
        webMobilePlatformOldCms,
        webKindStepOld,
        webIosUseCustomOld,
        webBothUseCustomOld,
        webDescriptionOldStatic,

        gameSecondStep,
        gameType,
        gameMultiplayer,
        gameCategory,
        gameDesign,
        gameLogin,
        gameManage,
        gameEarn
    }
};

</script>

<style scoped>
@import url('../../assets/css/app-cost.css');

</style>