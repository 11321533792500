<template>
	<section class="padding-60 bg-white o-hidden">
		<div class="container">
			<div class="title">
				<span>Mobilise Your Business</span>
				<h2 class="fxt-process-dev">Our Android App Development is Boundless to Smartphone</h2>
				<p>
					We craft and implement result oriented immersive android application development solutions
					for Startups, Small and Medium Businesses and Enterprises across the globe.
				</p>
			</div>
			<div class="icns-containter-fxt">
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M31.2,91.4h8.6c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-8.6c-0.5,0-0.8,0.3-0.8,0.8S30.7,91.4,31.2,91.4z M60.2,91.4h8.6
                        c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-8.6c-0.5,0-0.8,0.3-0.8,0.8S59.7,91.4,60.2,91.4z M54.7,6.2h-9.4
                        c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h9.4c0.5,0,0.8-0.3,0.8-0.8C55.5,6.6,55.2,6.2,54.7,6.2z M69.6,0H30.4
                        c-5.2,0-9.4,4.2-9.4,9.4v81.3c0,5.2,4.2,9.4,9.4,9.4h39.3c5.2,0,9.4-4.2,9.4-9.4V9.4C79.1,4.2,74.8,0,69.6,0z M75.9,90.6
                        c0,3.4-2.8,6.2-6.3,6.2H30.4c-3.5,0-6.3-2.8-6.3-6.2v-6.3h51.8V90.6z M75.9,82.8H24.1V12.5h51.8V82.8z M75.9,10.9H24.1V9.4
                        c0-3.4,2.8-6.2,6.3-6.2h39.3c3.5,0,6.3,2.8,6.3,6.2V10.9z M48.4,93.8h3.1c0.9,0,1.6-0.7,1.6-1.6v-3.1c0-0.9-0.7-1.6-1.6-1.6h-3.1
                        c-0.9,0-1.6,0.7-1.6,1.6v3.1C46.9,93,47.6,93.8,48.4,93.8z M48.4,89.1h3.1v3.1h-3.1V89.1z"
								></path>
							</g>
						</g>
					</svg>
					<p>Android</p>
				</div>
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M73.7,0H26.3c-7,0-12.6,5.7-12.6,12.7v74.7c0,7,5.7,12.7,12.6,12.7h47.4c7,0,12.6-5.7,12.6-12.7V12.7
                        C86.3,5.7,80.6,0,73.7,0z M83,87.3c0,5.2-4.2,9.4-9.4,9.4H26.3c-5.2,0-9.4-4.2-9.4-9.4V12.7c0-5.2,4.2-9.4,9.4-9.4h47.4
                        c5.2,0,9.4,4.2,9.4,9.4V87.3z M21.4,83.9h57.3V13.4H21.4V83.9z M24.6,16.7h50.8v63.9H24.6V16.7z M55.1,86H45
                        c-2.3,0-4.1,1.9-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1h10.1c2.3,0,4.1-1.9,4.1-4.1C59.2,87.8,57.3,86,55.1,86z M55.1,91H45
                        c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h10.1c0.5,0,0.9,0.4,0.9,0.9C55.9,90.6,55.5,91,55.1,91z M32.7,7h-6.8v3.3h6.8V7z"
								></path>
							</g>
						</g>
					</svg>
					<p>Tablet</p>
				</div>
				<div class="icn-containter-fxt">
					<svg viewBox="0 0 120 120" xml:space="preserve">
						<path
							fill="#6A6D6F"
							d="M60,44c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C58,43.1,58.9,44,60,44z M60,36c1.1,0,2-0.9,2-2
                     c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C58,35.1,58.9,36,60,36z M44,34h-8c-2.2,0-4,1.8-4,4v8c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4v-8
                     C48,35.8,46.2,34,44,34z M36,46v-8h8l0,8H36z M76,50h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8
                     C72,48.2,73.8,50,76,50z M76,38h8l0,8h-8V38z M114,97.6c-9.4-4.9-25.4-10.5-36.1-12.7C85.8,78,91,67.3,91.8,57
                     c2.5-1.4,4.2-4,4.2-7V34c0-3.6-2.4-6.7-5.8-7.7C86.1,13,74.7,0,60,0C45.3,0,33.9,13,29.8,26.3c-3.3,1-5.8,4-5.8,7.7v16
                     c0,3,1.7,5.7,4.2,7c0.8,10.2,6,21,13.9,27.8c-10.6,2.2-26.6,7.8-36,12.7c-3.7,1.9-6,5.7-6,9.9V118c0,1.1,0.9,2,2,2h76h16h24
                     c1.1,0,2-0.9,2-2v-10.6C120,103.3,117.7,99.5,114,97.6z M60,4c12.2,0,21.9,10.7,25.9,22H34.1C38.1,14.7,47.8,4,60,4z M30.8,53.8
                     C30.8,53.8,30.8,53.8,30.8,53.8c-1.6-0.5-2.8-2-2.8-3.8V34c0-2,1.5-3.7,3.5-3.9c0,0,0.1,0,0.1,0c0,0,0.4,0,0.4-0.1h56
                     c0.1,0,0.1,0,0.2,0c0,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0C90.5,30.4,92,32,92,34v16c0,1.7-1.1,3.1-2.6,3.7c-0.1,0-0.2,0-0.3,0.1
                     C88.8,53.9,88.4,54,88,54H71.1c-0.9,0-1.7-0.6-1.9-1.5l-0.5-1.9C68,47.9,65.6,46,62.9,46h-5.8c-2.8,0-5.2,1.9-5.8,4.5l-0.5,1.9
                     c-0.2,0.9-1,1.5-1.9,1.5H32C31.6,54,31.2,53.9,30.8,53.8z M32.3,58h16.6c2.8,0,5.2-1.9,5.8-4.5l0.5-1.9c0.2-0.9,1-1.5,1.9-1.5h5.8
                     c0.9,0,1.7,0.6,1.9,1.5l0.5,1.9c0.7,2.7,3.1,4.5,5.8,4.5h16.6c-1.2,10.2-7.1,20.8-15.5,26.3c-0.1,0-0.1,0-0.2,0.1l-1.3,0.8
                     l-0.2,0.1l-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1C67,87.1,63.6,88,60,88c-3.6,0-7-0.9-10-2.5c-0.1,0-0.1-0.1-0.2-0.1L48,84.3
                     c-0.1,0-0.1,0-0.2-0.1C39.4,78.8,33.5,68.2,32.3,58z M65.5,91.4l-4.8,6.3c-0.2,0.3-0.6,0.4-0.7,0.4s-0.5,0-0.7-0.4l-4.8-6.3
                     c1.8,0.4,3.6,0.6,5.5,0.6C61.9,92,63.7,91.8,65.5,91.4z M92,116H80v-4h12V116z M116,116H96v-6c0-1.1-0.9-2-2-2H78
                     c-1.1,0-2,0.9-2,2v6H4v-8.6c0-2.7,1.5-5.1,3.9-6.3c10.3-5.4,28.8-11.6,38.6-13l1,0.6l8.6,11.4c0.9,1.2,2.4,2,3.9,2s3-0.7,3.9-2
                     l8.6-11.4l0.1-0.1c0,0,0.2-0.1,0.2-0.1l0.7-0.4c9.8,1.4,28.3,7.6,38.6,13c2.4,1.3,3.9,3.7,3.9,6.3V116z"
						></path>
					</svg>
					<p>Virtual Reality</p>
				</div>
				<div class="icn-containter-fxt">
					<svg
						viewBox="0 0 120 120"
						style="enable-background: new 0 0 120 120"
						xml:space="preserve"
					>
						<path
							fill="#6A6D6F"
							d="M114.4,46.5h-11.7H87.3L74.4,31.7l-8.1,8.1L55.1,26.9L35.4,46.5H19.7h-14c-3.1,0-5.6,2.5-5.6,5.6v50.2
                     c0,3.1,2.5,5.6,5.6,5.6h14h83h11.7c3.1,0,5.6-2.5,5.6-5.6V52.2C120,49.1,117.5,46.5,114.4,46.5z M74.2,37.2l8.1,9.4H72.2l-3.4-4
                     L74.2,37.2z M54.9,32.4l12.3,14.2H40.7L54.9,32.4z M17.8,52.2c0-1,0.8-1.9,1.9-1.9h4.8L17.8,57V52.2z M14.4,104.3H5.6
                     c-1,0-1.9-0.8-1.9-1.9V52.2c0-1,0.8-1.9,1.9-1.9h8.7c-0.2,0.6-0.3,1.2-0.3,1.9v50.2C14,103.1,14.2,103.7,14.4,104.3z M104.6,102.4
                     c0,1-0.8,1.9-1.9,1.9h-83c-1,0-1.9-0.8-1.9-1.9V62.3l12-12h62.7l12,11.9V102.4z M104.6,57l-6.7-6.7h4.8c1,0,1.9,0.8,1.9,1.9V57z
                     M116.2,102.4c0,1-0.8,1.9-1.9,1.9H108c0.2-0.6,0.3-1.2,0.3-1.9V61.8c0-0.2,0-0.4,0-0.6v-9.1c0-0.7-0.1-1.3-0.3-1.9h6.4
                     c1,0,1.9,0.8,1.9,1.9V102.4z M8.9,67.8c-1,0-1.9,0.8-1.9,1.9V86c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9V69.7
                     C10.8,68.6,9.9,67.8,8.9,67.8z M95.6,27.6c4.3,0,7.8-3.5,7.8-7.8c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8
                     C87.7,24.1,91.2,27.6,95.6,27.6z M95.6,15.7c2.3,0,4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1
                     C91.5,17.6,93.3,15.7,95.6,15.7z M91.3,57.8H28.9c-1,0-1.9,0.8-1.9,1.9v35.2c0,1,0.8,1.9,1.9,1.9h48.7c1,0,1.9-0.8,1.9-1.9v-26
                     l12.9-7.7c0.7-0.4,1.1-1.3,0.8-2.1C92.9,58.4,92.2,57.8,91.3,57.8z M76.6,66.3c-0.6,0.3-0.9,0.9-0.9,1.6V93H30.7V61.5h53.8
                     L76.6,66.3z M38.2,84.6h18.6c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H38.2c-1,0-1.9,0.8-1.9,1.9C36.4,83.7,37.2,84.6,38.2,84.6z
                     M38.2,73.7H54c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H38.2c-1,0-1.9,0.8-1.9,1.9C36.4,72.9,37.2,73.7,38.2,73.7z M62.7,84.6
                     c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9C60.8,83.7,61.7,84.6,62.7,84.6z"
						></path>
					</svg>
					<p style="padding:0">Augmented Reality</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.icns-containter-fxt {
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
}

.icn-containter-fxt {
	display: flex;
	flex-direction: column;
  flex-wrap:nowrap;
	justify-content: space-between;
	align-items: center;
	height: 160px;
  width:fit-content;
}
.icn-containter-fxt svg {
	width: 100px;
	height: 100px;
	transition: 0.2s;
  fill:#6A6D6F;
}
.icn-containter-fxt p {
  margin:0;
	padding: 10px 0 0 0;
	text-align: center;
	transition: 0.2s;
	color: black;
	font-size: 18px;
	font-weight: 600;
}
.icn-containter-fxt:hover svg path {
	fill: #008dd2;
}
.icn-containter-fxt:hover p {
	color: #008dd2;
}
@media (max-width: 991px) {
	.process-items::before {
		display: none;
	}
	.process-items i {
		top: unset !important;
		bottom: -30px !important;
		left: 50% !important;
	}
	.process-items i.goDown {
		transform: translateX(-50%) rotate(90deg);
	}
	.process-items i.goUp {
		top: -30px !important;
		bottom: unset !important;
		transform: translateX(-50%) rotate(-90deg);
	}
}
@media (max-width: 576px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.icns-containter-fxt {
		flex-wrap: wrap;
	}
	.icn-containter-fxt {
		margin: 0;
		padding: 10px 0 !important;
		box-shadow: none !important;
		height: 150px;
		width: 150px;
	}
}
</style>
