<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/ios-about.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Full Service Cross Platform Flutter Development Company</h2>
					<p>
						In one or the other way, Google manages to gain positive attention from tech geeks
						across the globe. This time it happened with the newly launched cross-platform app
						development framework, Flutter. The SDK is open source and can be utilised freely by
						developers using a single code structure. The launch is not only believed to ease the
						development process and fasten the same but is also expected to deliver a better app
						experience to users.
					</p>
					<p>
						Though newly launched on the market, at Genixbit, we have a dedicated team of flutter
						app developers, who can provide high-quality flutter app development services
						flawlessly. Well yes, that’s what counts. We firmly believe that to leverage the
						benefits of any new technology, it is crucial to have it implemented in the right
						manner. Our in-house mobile app developers can assure the same to you by executing your
						flutter app development process effortlessly.
					</p>
					<p>
						To ensure that the cross-platform app development process is executed without any error,
						we follow a defined procedure which primarily includes research and rounds of
						discussion. This includes understanding your brand, the industry, target audience, and
						competitors. We firmly believe that as a flutter app development company, it is our key
						responsibility to learn and acknowledge these vital details before initiating your
						Flutter app development process.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
