<template>
	<section class="pb-30 pt-30 padding-md-60 bg-light">
		<div class="container">
			<div class="title">
				<span>Our Offerings</span>
				<h2 class="fxt-srvs-title">Our Customer Focused Services</h2>
				<p>
					Explore our mobile app development and web development services that can help launch your
					business, grow your digital presence or amplify brand awareness. We leverage the pinnacle
					of digital technologies to help our clients become leading digital enterprises.
				</p>
			</div>
			<div class="services-tabs">
				<ul class="nav navbar-nav fxt-navbar-nav d-flex" id="servicesTab" role="tablist">
					<li>
						<a
							class="active"
							id="mobile2-technologies-tab"
							data-toggle="tab"
							href="#mobile2-technologies"
							role="tab"
							aria-controls="mobile2-technologies"
							aria-selected="true"
						>
							<svg
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 150 150"
								style="enable-background: new 0 0 150 150"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M96.1,94c-0.9-0.9-2.4-0.8-3.3,0.1l-10.5,11.1c-0.8,0.9-0.8,2.3,0,3.2l10.5,11.1c0.5,0.5,1.1,0.7,1.7,0.7
                     c0.6,0,1.1-0.2,1.6-0.6c0.9-0.9,1-2.3,0.1-3.3l-9-9.5l9-9.5C97.1,96.3,97,94.9,96.1,94z M125.4,105.1l-10.5-11.1
                     c-0.9-0.9-2.4-1-3.3-0.1c-0.9,0.9-1,2.3-0.1,3.3l9,9.5l-9,9.5c-0.9,0.9-0.8,2.4,0.1,3.3c0.4,0.4,1,0.6,1.6,0.6
                     c0.6,0,1.2-0.2,1.7-0.7l10.5-11.1C126.2,107.4,126.2,106,125.4,105.1z M107,93.6c-1.3-0.3-2.5,0.5-2.8,1.7l-5.3,21.8
                     c-0.3,1.2,0.5,2.5,1.7,2.8c0.2,0,0.4,0.1,0.5,0.1c1,0,2-0.7,2.3-1.8l5.3-21.8C109.1,95.1,108.3,93.9,107,93.6z M103.8,74.4
                     c-1.8,0-3.5,0.2-5.2,0.5V46.1c2.1,0,3.9-1.7,3.9-3.9V32.1c0-2.1-1.7-3.9-3.9-3.9V11.6C98.7,5.2,93.4,0,87,0H25.3
                     c-6.4,0-11.6,5.2-11.6,11.6v126.8c0,6.4,5.2,11.6,11.6,11.6H87c6.4,0,11.5-5.1,11.6-11.4c1.7,0.3,3.4,0.5,5.2,0.5
                     c17.9,0,32.5-14.5,32.5-32.3C136.3,88.9,121.7,74.4,103.8,74.4z M94,138.4c0,3.8-3.1,6.9-7,6.9H25.3c-3.8,0-7-3.1-7-6.9V25.3H82
                     c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3H18.4v-9.1c0-3.8,3.1-6.9,7-6.9H87c3.8,0,7,3.1,7,6.9v64.4c-13.1,4.2-22.6,16.4-22.6,30.8
                     c0,14.4,9.5,26.6,22.6,30.8V138.4z M103.8,134.4C88.5,134.4,76,122,76,106.7C76,91.5,88.5,79,103.8,79c15.3,0,27.8,12.4,27.8,27.7
                     C131.6,122,119.2,134.4,103.8,134.4z M58.1,124c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9c4.9,0,8.9-4,8.9-8.9
                     C67,128,63,124,58.1,124z M58.1,137.1c-2.4,0-4.3-1.9-4.3-4.3c0-2.3,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3
                     C62.4,135.2,60.5,137.1,58.1,137.1z M71.5,8.9c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6
                     C75.1,10.5,73.5,8.9,71.5,8.9z M71.5,13.6c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
                     C72.6,13.1,72.1,13.6,71.5,13.6z"
								></path>
							</svg>
							<span class="w-100">App <span>Development</span></span>
						</a>
					</li>
					<li>
						<a
							id="web2-technologies-tab"
							data-toggle="tab"
							href="#web2-technologies"
							role="tab"
							aria-controls="web2-technologies"
							aria-selected="true"
						>
							<svg
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 150 150"
								style="enable-background: new 0 0 150 150"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M146.1,115.3H145V29.6c0-5.6-4.5-10.1-10-10.1H15c-5.5,0-10,4.5-10,10.1v85.7H3.9c-2.2,0-3.9,1.8-3.9,4v7.2
                        c0,2.2,1.8,4,3.9,4h142.1c2.2,0,3.9-1.8,3.9-4v-7.2C150,117.1,148.2,115.3,146.1,115.3z M10,29.6c0-2.8,2.2-5,5-5h120
                        c2.8,0,5,2.3,5,5v85.7H88l-0.6,1.7c-0.1,0.4-0.6,0.9-1.2,0.9h-20c-0.6,0-1-0.4-1.2-0.9l-0.6-1.7H10V29.6z
                        M145,125.4H5v-5h56.3 c1.2,1.6,3,2.5,5,2.5h20c2,0,3.8-1,5-2.5H145V125.4z M135,107.8V32.2c0-1.4-1.1-2.5-2.5-2.5h-115c-1.4,0-2.5,1.1-2.5,2.5v75.6
                        c0,1.4,1.1,2.5,2.5,2.5h115C133.9,110.3,135,109.2,135,107.8z M130,105.2H20V34.7h110V105.2z M60,85.1c1.4,0,2.5-1.1,2.5-2.5
                        c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C57.5,84,58.6,85.1,60,85.1z M48,84.1c0.5,0.6,1.2,0.9,2,0.9c0.5,0,1.1-0.2,1.6-0.6
                        c1.1-0.9,1.3-2.5,0.4-3.5l-8.7-11l8.8-11c0.9-1.1,0.7-2.7-0.4-3.5c-1.1-0.9-2.7-0.7-3.5,0.4L38,68.4c-0.7,0.9-0.7,2.2,0,3.1
                        L48,84.1z M98.4,84.5c0.5,0.4,1,0.6,1.6,0.6c0.7,0,1.5-0.3,2-0.9l10-12.6c0.7-0.9,0.7-2.2,0-3.1l-10-12.6 c-0.9-1.1-2.4-1.3-3.5-0.4c-1.1,0.9-1.3,2.5-0.4,3.5l8.8,11L98,81C97.2,82.1,97.4,83.7,98.4,84.5z
                        M60,75c1.4,0,2.5-1.1,2.5-2.5 V57.4c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v15.1C57.5,73.9,58.6,75,60,75z M70,85.1h5c1.4,0,2.5-1.1,2.5-2.5
                        c0-1.4-1.1-2.5-2.5-2.5h-5c-1.4,0-2.5,1.1-2.5,2.5C67.5,84,68.6,85.1,70,85.1z M85,85.1h5c1.4,0,2.5-1.1,2.5-2.5
                        c0-1.4-1.1-2.5-2.5-2.5h-5c-1.4,0-2.5,1.1-2.5,2.5C82.5,84,83.6,85.1,85,85.1z"
								></path>
							</svg>
							<span class="w-100">Web <span>Development</span></span>
						</a>
					</li>
					<li>
						<a
							id="cloud-ssa-tab"
							data-toggle="tab"
							href="#cloud-ssa"
							role="tab"
							aria-controls="cloud-ssa"
							aria-selected="true"
						>
							<svg
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 150 150"
								style="enable-background: new 0 0 150 150"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M75,55c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C72.5,53.9,73.6,55,75,55z M75,45
                     c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C72.5,43.9,73.6,45,75,45z M55,42.5H45c-2.8,0-5,2.2-5,5v10
                     c0,2.8,2.2,5,5,5h10c2.8,0,5-2.2,5-5v-10C60,44.7,57.8,42.5,55,42.5z M45,57.5v-10h10l0,10H45z M95,62.5h10c2.8,0,5-2.2,5-5v-10
                     c0-2.8-2.2-5-5-5H95c-2.8,0-5,2.2-5,5v10C90,60.3,92.2,62.5,95,62.5z M95,47.5h10l0,10H95V47.5z M142.5,122
                     c-11.7-6.2-31.7-13.1-45.1-15.9c9.8-8.5,16.3-22,17.4-34.8c3.1-1.7,5.2-5,5.2-8.8v-20c0-4.6-3.1-8.4-7.2-9.6
                     C107.7,16.3,93.4,0,75,0C56.6,0,42.3,16.3,37.2,32.9c-4.2,1.2-7.2,5-7.2,9.6v20c0,3.8,2.1,7.1,5.2,8.8c1,12.8,7.5,26.2,17.4,34.8
                     c-13.3,2.8-33.3,9.7-45.1,15.9c-4.6,2.4-7.5,7.2-7.5,12.4v13.2c0,1.4,1.1,2.5,2.5,2.5h95h20h30c1.4,0,2.5-1.1,2.5-2.5v-13.2
                     C150,129.1,147.1,124.4,142.5,122z M75,5c15.3,0,27.4,13.4,32.4,27.5H42.6C47.6,18.4,59.7,5,75,5z M38.5,67.2
                     C38.5,67.2,38.5,67.2,38.5,67.2c-2-0.7-3.5-2.5-3.5-4.7v-20c0-2.5,1.9-4.6,4.4-4.9c0,0,0.1,0,0.1,0c0,0,0.5-0.1,0.5-0.1h69.9
                     c0.1,0,0.2,0,0.3,0c0,0,0.3,0,0.3,0c0.1,0,0.2,0,0.3,0c2.4,0.4,4.2,2.4,4.2,4.9v20c0,2.1-1.3,3.9-3.2,4.7c-0.1,0-0.2,0.1-0.3,0.1
                     c-0.5,0.1-1,0.2-1.5,0.2H88.9c-1.2,0-2.1-0.8-2.4-1.9l-0.6-2.4c-0.8-3.3-3.8-5.7-7.3-5.7h-7.2c-3.4,0-6.4,2.3-7.3,5.7l-0.6,2.4
                     c-0.3,1.1-1.3,1.9-2.4,1.9H40C39.5,67.5,39,67.4,38.5,67.2z M40.4,72.5h20.7c3.4,0,6.4-2.3,7.3-5.7l0.6-2.4
                     c0.3-1.1,1.3-1.9,2.4-1.9h7.2c1.2,0,2.1,0.8,2.4,1.9l0.6,2.4c0.8,3.3,3.8,5.7,7.3,5.7h20.7c-1.5,12.8-8.8,25.9-19.3,32.7
                     c-0.1,0-0.2,0.1-0.3,0.2l-1.6,1l-0.2,0.1l-0.4,0.2c-0.1,0-0.2,0.1-0.2,0.2c-3.9,2-8.1,3.1-12.6,3.1c-4.5,0-8.7-1.1-12.6-3.1
                     c-0.1-0.1-0.1-0.1-0.2-0.2l-2.3-1.3c-0.1-0.1-0.2-0.1-0.3-0.2C49.1,98.4,41.9,85.3,40.4,72.5z M81.9,114.2l-5.9,7.9
                     c-0.3,0.4-0.7,0.5-0.9,0.5c-0.2,0-0.6-0.1-0.9-0.5l-5.9-7.9c2.2,0.5,4.5,0.8,6.9,0.8C77.4,115,79.6,114.7,81.9,114.2z M115,145
                     h-15v-5h15V145z M145,145h-25v-7.5c0-1.4-1.1-2.5-2.5-2.5h-20c-1.4,0-2.5,1.1-2.5,2.5v7.5H5v-10.7c0-3.3,1.9-6.4,4.8-7.9
                     c12.9-6.8,36-14.5,48.3-16.3l1.2,0.7l10.7,14.3c1.2,1.6,3,2.5,4.9,2.5c2,0,3.7-0.9,4.9-2.5l10.7-14.3l0.1-0.1c0,0,0.2-0.1,0.2-0.1
                     l0.9-0.5c12.3,1.7,35.4,9.5,48.3,16.2c3,1.6,4.8,4.6,4.8,7.9V145z"
								></path>
							</svg>
							<span class="w-100">AR/VR <span>Development</span></span>
						</a>
					</li>
					<li>
						<a
							id="database-game-tab"
							data-toggle="tab"
							href="#database-game"
							role="tab"
							aria-controls="database-game"
							aria-selected="true"
						>
							<svg
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 150 150"
								style="enable-background: new 0 0 150 150"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M121.6,97.3c-1.5-0.6-2.8-0.9-4.1-0.9c-1.6,0-2.7,0.5-3.3,0.8l-6.7-2.7c-0.5-1.2-1.3-3.4-5.3-5.1
                     c-1.2-0.5-2.4-0.7-3.7-0.7c-3.2,0-6.2,1.6-8,4.2l-7.4,8.9c-2.3,2.8-5.1,6.9-2.7,10.2c1.1,1.5,2.7,2.3,4.7,2.3c1.4,0,2.9-0.4,4.7-1.2
                     c3.1-1.4,5-2.3,6.3-3c2,0.9,4.3,0.6,6.2-0.7l5,2.1c0.4,2,1.6,4,3.9,4.8c0.5,1.4,1.2,3.4,2.4,6.6c1.5,3.9,3.7,5.9,6.6,5.9
                     c0.3,0,0.6,0,0.9-0.1c4-0.7,5-5.5,5.3-9.2l1-11.5C128.3,103.5,125.9,99,121.6,97.3z M122.9,107.2c0,0.1,0,0.2,0,0.3l-1.1,11.6
                     c-0.4,4.3-1.3,5-1.4,5c-0.1,0-0.1,0-0.2,0c-0.7,0-1.5-1.1-2.2-2.9c0,0-2.7-7.2-2.8-7.6c-0.3-0.8-1-1.4-1.8-1.5
                     c-1.3-0.2-1.4-1.4-1.4-2c0.1-1-0.5-1.9-1.4-2.3l-7.7-3.2c-0.9-0.4-2-0.1-2.6,0.6c-0.4,0.4-1,0.8-1.5,0.8c-0.3,0-0.6-0.1-0.9-0.3
                     c-0.6-0.5-1.4-0.6-2.2-0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.5,0.2-7.3,3.4-7.3,3.4c-1.4,0.6-2.3,0.8-2.8,0.8c-0.6,0-0.8-0.2-1-0.5
                     c0,0-0.2-1.2,2.6-4.5l7.5-9c0.1-0.1,0.1-0.1,0.2-0.2c1.3-2,3.9-2.8,6.1-1.9c2.2,0.9,2.5,1.7,2.8,2.5c0.3,0.7,0.7,1.8,2.1,2.4
                     l7.7,3.2c0.4,0.2,0.8,0.2,1.2,0.2c0.8,0,1.4-0.3,1.9-0.5c0.5-0.2,0.8-0.4,1.4-0.4c0.6,0,1.4,0.2,2.3,0.6
                     C122.1,102.5,123.4,104.8,122.9,107.2z M120.3,105.5c0.3-1.1-0.2-2.4-1.3-2.8c-1.1-0.4-2.4,0-2.9,1.1c-1.1-0.3-2.4,0.2-2.8,1.3
                     c-0.5,1.1,0,2.4,1.1,2.9c-0.3,1.1,0.2,2.3,1.3,2.8c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0,1.6-0.5,2-1.2c0.2,0.1,0.5,0.1,0.7,0.1
                     c0.9,0,1.8-0.5,2.1-1.4C121.9,107.4,121.4,106.1,120.3,105.5z M101.7,98.5l-0.2-0.1l0.1-0.2c0.5-1.2-0.1-2.5-1.3-3
                     c-1.2-0.5-2.5,0.1-3,1.3l-0.1,0.2L97,96.5c-1.2-0.5-2.5,0.1-3,1.3c-0.5,1.2,0.1,2.5,1.3,3l0.2,0.1l-0.1,0.2c-0.5,1.2,0.1,2.5,1.3,3
                     c0.3,0.1,0.6,0.2,0.9,0.2c0.9,0,1.8-0.5,2.2-1.4l0.1-0.2l0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.2c0.9,0,1.8-0.5,2.2-1.4
                     C103.5,100.3,102.9,99,101.7,98.5z M104.9,124.6c-1.3,0-2.3,1-2.3,2.3v11.5c0,3.8-3.1,6.9-7,6.9H34c-3.8,0-7-3.1-7-6.9V11.6
                     c0-3.8,3.1-6.9,7-6.9h61.6c3.8,0,7,3.1,7,6.9v62.6c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3V42.9c1.8-0.4,3.1-1.9,3.1-3.8V29
                     c0-1.9-1.3-3.4-3.1-3.8V11.6C107.2,5.2,102,0,95.6,0H34c-6.4,0-11.6,5.2-11.6,11.6v126.8c0,6.4,5.2,11.6,11.6,11.6h61.6
                     c6.4,0,11.6-5.2,11.6-11.6v-11.5C107.2,125.6,106.2,124.6,104.9,124.6z M76.4,11.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3H53.2
                     c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3H76.4z M58.3,137.4c0,3.5,2.9,6.4,6.5,6.4c3.6,0,6.5-2.9,6.5-6.4c0-3.5-2.9-6.4-6.5-6.4
                     C61.2,130.9,58.3,133.8,58.3,137.4z M66.6,137.4c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
                     C65.8,135.6,66.6,136.4,66.6,137.4z M92.5,76.8c1.4,0,2.6-1.1,2.6-2.6V32.2c0-4.9-4-8.9-8.9-8.9h-42c-4.9,0-8.9,4-8.9,8.9v86.4
                     c0,4.9,4,8.9,8.9,8.9h30.5c1.4,0,2.6-1.1,2.6-2.6c0-1.4-1.1-2.6-2.6-2.6H44.2c-2.1,0-3.8-1.7-3.8-3.8V32.2c0-2.1,1.7-3.8,3.8-3.8h42
                     c2.1,0,3.8,1.7,3.8,3.8v42.1C90,75.6,91.1,76.8,92.5,76.8z"
								></path>
							</svg>
							<span class="w-100">Game <span>Development</span></span>
						</a>
					</li>
					<li>
						<a
							id="uiux-design-tab"
							data-toggle="tab"
							href="#uiux-design"
							role="tab"
							aria-controls="uiux-design"
							aria-selected="true"
						>
							<svg
								version="1.1"
								x="0px"
								y="0px"
								viewBox="0 0 150 150"
								style="enable-background: new 0 0 150 150"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M104,0H12.1C5.4,0,0,5.4,0,12.1v125.8c0,6.7,5.4,12.1,12.1,12.1H104c6.7,0,12.1-5.4,12.1-12.1V12.1
                     C116.1,5.4,110.7,0,104,0z M111.3,137.9c0,4-3.3,7.3-7.3,7.3H12.1c-4,0-7.3-3.3-7.3-7.3v-7.3h106.5V137.9z M111.3,125.8H4.8V19.4
                     h106.5V125.8z M111.3,14.5H4.8v-2.4c0-4,3.3-7.3,7.3-7.3H104c4,0,7.3,3.3,7.3,7.3V14.5z M43.5,140.3h29c1.3,0,2.4-1.1,2.4-2.4
                     c0-1.3-1.1-2.4-2.4-2.4h-29c-1.3,0-2.4,1.1-2.4,2.4C41.1,139.2,42.2,140.3,43.5,140.3z M12.1,121H104c1.3,0,2.4-1.1,2.4-2.4V26.6
                     c0-1.3-1.1-2.4-2.4-2.4H12.1c-1.3,0-2.4,1.1-2.4,2.4v91.9C9.7,119.9,10.8,121,12.1,121z M101.6,87.1h-7.3v-2.4
                     c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4v2.4h-4.8v-29h4.8V75c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4V58.1h7.3V87.1z
                     M14.5,29h87.1v24.2H82.5L61,48.4c-0.8-0.2-1.6,0-2.1,0.6L34.7,70.8c-0.5,0.5-0.8,1.1-0.8,1.8c0,0.7,0.3,1.3,0.8,1.8l24.2,21.8
                     c0.4,0.4,1,0.6,1.6,0.6c0.2,0,0.4,0,0.5-0.1l21.5-4.8h19.1v24.2H14.5V29z M62.9,79.8c4,0,7.3-3.3,7.3-7.3c0-4-3.3-7.3-7.3-7.3
                     c-3.1,0-5.8,2-6.8,4.8H42.6l18.6-16.7l18.7,4.1v30l-18.7,4.1L42.6,75h13.5C57.1,77.8,59.8,79.8,62.9,79.8z M60.5,72.6
                     c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4S64.2,75,62.9,75C61.6,75,60.5,73.9,60.5,72.6z M137.9,33.9
                     c-6.7,0-12.1,5.4-12.1,12.1v82.3c0,0.3,0.1,0.6,0.2,0.8l6.4,17.1c0.9,2.3,3.1,3.8,5.5,3.8c2.4,0,4.7-1.5,5.5-3.8l6.4-17.1
                     c0.1-0.3,0.2-0.6,0.2-0.9V46C150,39.3,144.6,33.9,137.9,33.9z M138.9,144.5c-0.3,0.8-1.7,0.8-2,0l-5.2-13.8h12.4L138.9,144.5z
                     M145.2,125.8h-14.5V72.6h7.3c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4h-7.3v-4.8h14.5V125.8z M145.2,58.1h-14.5V46
                     c0-4,3.3-7.3,7.3-7.3c4,0,7.3,3.3,7.3,7.3V58.1z M58.1,96.8c-3.1,0-5.8,2-6.8,4.7c-15.1-1-27.1-13.6-27.1-28.9
                     c0-15.4,12-27.9,27.1-28.9c1,2.8,3.7,4.7,6.8,4.7c4,0,7.3-3.3,7.3-7.3c0-4-3.3-7.3-7.3-7.3c-3.2,0-5.9,2.1-6.8,4.9
                     c-17.7,1-31.9,15.8-31.9,33.8s14.1,32.7,31.9,33.8c1,2.9,3.7,4.9,6.8,4.9c4,0,7.3-3.3,7.3-7.3C65.3,100,62.1,96.8,58.1,96.8z
                     M58.1,38.7c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4C55.6,39.8,56.7,38.7,58.1,38.7z M58.1,106.5
                     c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4S59.4,106.5,58.1,106.5z"
								></path>
							</svg>
							<span class="w-100">UI/UX <span>Design</span></span>
						</a>
					</li>
				</ul>
				<div class="tab-content" id="technologies_tabs_Content">
					<div
						class="tab-pane fade active show fxt-tab-pane"
						id="mobile2-technologies"
						role="tabpanel"
						aria-labelledby="mobile2-technologies-tab"
					>
						<div
							class="row align-items-center justify-content-center justify-content-lg-between img-cont-container"
						>
							<div class="col-12 col-lg-5 order-lg-1 text-center d-none d-md-block">
								<img
									class="lazy"
									alt="Mobile App Development"
									src="../../assets/images/app-tab.png"
									style=""
									width="458"
									height="399"
								/>
							</div>
							<div class="col-12 col-lg-7">
								<div class="servicesTabContent_text">
									<h3 class="fxt-mobile-dev">
										<a style="font-weight: bolder">Mobile App Development</a>
									</h3>
									<span style="font-weight: bolder"
										>Join the future of mobility with our expertise</span
									>
									<p style="margin-top: 10px">
										From setting an alarm to playing a favourite tune, users turn to mobile apps for
										anything and everything. Is your business geared up to serve users who live in
										the mobile-world?
									</p>
									<p>
										For close to a decade, GenixBit Labs being a top mobile app development company
										has produced apps for various industries and partners, from the start-up Social
										Media app Chatagram, to the Art of Works Virtual Reality app for tech giant’s
										Microsoft, we have worked with them all.
									</p>
									<p>
										Our team of best app developers in UK has a proven record in developing mobile
										applications for Android, iOS and hybrid app solutions.
									</p>
									<pnk>
										Want to know more information about our mobile app development? Check the below
										links.
									</pnk>
									<ul class="fxt-icons-conteinter big-icn-con">
										<li>
											<router-link
												to="/mobile-development/android-development"
												aria-label="Android App"
												title="Android App"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M22.3,3.6c-0.4-0.4-0.8-0.8-1.4-1.1l0.8-1.7c0.1-0.3,0-0.6-0.3-0.7c-0.3-0.1-0.6,0-0.7,0.3L20,1.9
                                    c-1-0.5-2.3-0.9-3.9-0.9c-1.6,0-3,0.3-4.1,0.9l-0.8-1.6C11.1,0,10.8-0.1,10.5,0c-0.3,0.1-0.4,0.4-0.3,0.7l0.8,1.7
                                    C8.8,3.9,7.7,6.2,7.6,7.9L7.5,8.5h16.9l-0.1-0.6C24.3,6.8,23.7,5.1,22.3,3.6z M8.7,7.5c0.5-2.3,2.7-5.3,7.4-5.3
                                    c1.5,0,2.7,0.4,3.7,0.9c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c1.9,1.1,2.9,3,3.2,4.3H8.7z M3.9,9.6c-1.5,0-2.6,1.2-2.6,2.7v7.5
                                    c0,1.5,1.2,2.7,2.6,2.7s2.6-1.2,2.6-2.7v-7.5C6.5,10.8,5.4,9.6,3.9,9.6z M5.5,19.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                                    v-7.5c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V19.7z M28.1,9.6c-1.5,0-2.6,1.2-2.6,2.7v7.5c0,1.5,1.2,2.7,2.6,2.7
                                    s2.6-1.2,2.6-2.7v-7.5C30.7,10.8,29.6,9.6,28.1,9.6z M29.7,19.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-7.5
                                    c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V19.7z M7.6,25.1h2.1v4.8c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.8h4.2v4.8
                                    c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.8h2.1V9.6H7.6V25.1z M8.6,10.7h14.7V24h-2.1v5.9c0,0.6-0.5,1.1-1.1,1.1
                                    c-0.6,0-1.1-0.5-1.1-1.1V24h-6.3v5.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V24H8.6V10.7z M13.4,4.3
                                    c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C14.4,4.7,13.9,4.3,13.4,4.3z M18.6,4.3
                                    c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C19.7,4.7,19.2,4.3,18.6,4.3z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/mobile-development/iphone-development"
												aria-label="iPhone App"
												title="iPhone App"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M15.9,8.3c0.1,0,0.2,0,0.4,0c1.7,0,3.6-0.9,4.9-2.4c1.3-1.6,2-3.6,1.8-5.4c0-0.3-0.3-0.6-0.6-0.5
                                    c-1.8,0.1-3.9,1.1-5.1,2.5c-1.1,1.2-2.1,3.2-1.8,5.2C15.4,8.1,15.6,8.3,15.9,8.3z M18.1,3.3c0.9-1,2.3-1.8,3.6-2
                                    c0,1.3-0.6,2.7-1.5,3.9c-1,1.1-2.3,1.9-3.6,2C16.5,5.6,17.4,4.2,18.1,3.3z M29.1,22.6c-0.4-0.2-3.8-1.6-3.8-5.7
                                    c0-3.2,2.4-4.9,3.1-5.4c0.1-0.1,0.2-0.2,0.3-0.4c0-0.2,0-0.3-0.1-0.5c-2-2.8-5.1-3.3-6.2-3.3c-1.6-0.2-3.2,0.4-4.5,0.9
                                    c-0.7,0.3-1.3,0.5-1.7,0.5c-0.4,0-1.1-0.2-1.7-0.5c-1-0.4-2.2-0.8-3.4-0.8l-0.1,0c-2.9,0-5.6,1.6-7.1,4.2c-2.8,4.7-1,11.8,2,16.2
                                    c1.3,1.8,3,4.2,5.4,4.2l0.1,0c1,0,1.8-0.3,2.5-0.6c0.8-0.3,1.5-0.6,2.7-0.6c1.1,0,1.8,0.3,2.5,0.6c0.7,0.3,1.5,0.6,2.6,0.6l0.1,0
                                    c2.5,0,4.1-2.3,5.3-4c1.4-1.9,2-3.8,2.3-4.5C29.6,23.1,29.4,22.8,29.1,22.6z M26.2,27.2c-1.4,2-2.6,3.5-4.3,3.5l-0.1,0
                                    c-0.9,0-1.4-0.2-2.1-0.5c-0.8-0.3-1.7-0.7-3-0.7c-1.4,0-2.3,0.4-3.2,0.7c-0.7,0.3-1.2,0.5-2.1,0.5l-0.1,0c-1.6,0-3-1.7-4.4-3.6
                                    c-3.8-5.4-3.9-11.7-2-14.9c1.3-2.2,3.6-3.5,6-3.6l0.1,0c1,0,2,0.4,3,0.7c0.8,0.3,1.5,0.6,2.2,0.6c0.6,0,1.3-0.3,2.1-0.6
                                    c1-0.4,2.2-0.9,3.4-0.9c0.1,0,0.3,0,0.5,0c1,0,3.3,0.3,4.9,2.3C26,11.8,24,13.8,24,17c0,4,2.9,5.9,4.1,6.5
                                    C27.8,24.3,27.2,25.8,26.2,27.2z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/mobile-development/flutter-development"
												aria-label="Flutter App"
												title="Flutter App"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M17.8,0L2.5,16.5l5.4,6.4L29.5,0H17.8z M4.3,16.5L18.7,0.9h8.1L7.9,21L4.3,16.5z M17.8,14.6l-9,9.1l5.4,4.6
                                    l4.5,3.7h10.8c0,0-5.6-6.2-8.8-9.5c4.2-3.7,8.8-7.9,8.8-7.9H17.8z M10.6,23.8c0,0,2.4-2.5,4.5-4.6c0.4,0.4,2.7,2.7,3.6,3.7
                                    c-2.3,2-4.5,3.7-4.5,3.7L10.6,23.8z M26.8,31.1h-7.2c0,0-3.1-2.6-4.4-3.7c0.9-0.8,2.7-2.1,4.4-3.7C21.2,25.5,26.8,31.1,26.8,31.1
                                    z M19.5,21.9c-1.3-1.3-2.8-2.7-3.6-3.5c1.5-1.6,2.8-2.8,2.8-2.8h8.1C26.8,15.5,22.9,18.9,19.5,21.9z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/mobile-development/cross-platform-development"
												aria-label="Cross Platform"
												title="Cross Platform"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M31.2,26.4h-7.7h-2.3H18c-0.4,0-0.8-0.4-0.8-0.8v-2.4h-3.5V24h2.5c0.1,0,0.2,0.1,0.2,0.2v0.4
                                       c0,0.1-0.1,0.2-0.2,0.2h-5.8c-0.1,0-0.2-0.1-0.2-0.2v-0.4c0-0.1,0.1-0.2,0.2-0.2h2.6v-0.8H0.8c-0.4,0-0.8-0.4-0.8-0.8V6.4
                                       c0-0.5,0.4-0.8,0.8-0.8h24.9c0.4,0,0.8,0.4,0.8,0.8V10h4.7c0.4,0,0.8,0.4,0.8,0.8v14.9C32,26.1,31.6,26.4,31.2,26.4z M18,25.3h5.5
                                       V15.1H18C18,15.1,18,25.3,18,25.3z M25.8,6.4H0.8v15.7h16.3v-7.1c0-0.4,0.4-0.8,0.8-0.8h2.3v-3.6c0-0.4,0.4-0.8,0.8-0.8h4.7
                                       L25.8,6.4L25.8,6.4z M31.3,10.8H21.1v3.6h2.3c0.4,0,0.8,0.4,0.8,0.8v10.3h7L31.3,10.8L31.3,10.8z M26,23.7h0.4
                                       c0.1,0,0.2,0.1,0.2,0.2v0.4c0,0.1-0.1,0.2-0.2,0.2H26c-0.1,0-0.2-0.1-0.2-0.2V24C25.8,23.9,25.8,23.7,26,23.7z M20.9,24.5h-0.4
                                       c-0.1,0-0.2-0.1-0.2-0.2V24c0-0.1,0.1-0.2,0.2-0.2h0.4c0.1,0,0.2,0.1,0.2,0.2v0.4C21.1,24.5,21,24.5,20.9,24.5z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<a
												href="/app-development-cost"
												class="btn-custome btn waves-effect waves-light appointment-btn fxt-btn-head fxt-waves-light"
												title=""
												>Calculate App Development Cost</a
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="web2-technologies"
						role="tabpanel"
						aria-labelledby="web2-technologies-tab"
					>
						<div class="row align-items-center justify-content-center justify-content-lg-between">
							<div class="col-12 col-lg-5 order-lg-1 text-center d-none d-md-block">
								<img
									class="lazy"
									alt="Web Application Development"
									src="../../assets/images/web-tab.png"
								/>
							</div>
							<div class="col-12 col-lg-7">
								<div class="servicesTabContent_text">
									<h3>
										<a style="font-weight: bolder">Web Development</a>
									</h3>
									<span
										>Take your business to the next level with our web development expertise</span
									>
									<p>
										From day one, the web has remained the backbone of digital transformation.
										Accelerate your growth with our web engineering experience.
									</p>
									<p>
										Being one of the top web app development companies in UK, our web developers
										have extensive experience in developing web applications using a wide variety of
										web frameworks, programming languages and protocols; all your web development
										requirements taken care of under one roof.
									</p>
									<p>
										Build a bespoke web-based product, launch an application or open an online store
										— whatever your goal is our web development team can make it happen.
									</p>
									<p>Want to know more information? Check the below links.</p>
									<ul class="big-icn-con">
										<li>
											<router-link
												to="/web-development/php-development"
												class="waves-effect waves-light"
												title=""
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M31.1,8.8L23,0.4c-0.1-0.1-0.3-0.2-0.4-0.2h-12c-1.3,0-2.4,1.1-2.4,2.4v8.5H3.3c-1,0-1.8,0.8-1.8,1.8V22
                                       c0,1,0.8,1.8,1.8,1.8h4.9v5.5c0,1.3,1.1,2.4,2.4,2.4h18.2c1.3,0,2.4-1.1,2.4-2.4V9.2C31.3,9.1,31.2,8.9,31.1,8.8z M22.8,1.9l6.6,6.9
                                       h-6.6V1.9z M3.3,22.8c-0.3,0-0.6-0.3-0.6-0.6V13c0-0.3,0.3-0.6,0.6-0.6h16.4c0.3,0,0.6,0.3,0.6,0.6v9.1c0,0.3-0.3,0.6-0.6,0.6
                                       L3.3,22.8L3.3,22.8z M30.1,29.5c0,0.7-0.5,1.2-1.2,1.2H10.6c-0.7,0-1.2-0.5-1.2-1.2V24h10.3c1,0,1.8-0.8,1.8-1.8V13
                                       c0-1-0.8-1.8-1.8-1.8H9.4V2.7c0-0.7,0.5-1.2,1.2-1.2h10.9v7.9c0,0.3,0.3,0.6,0.6,0.6H30v19.5H30.1z"
													></path>
													<path
														fill="#202122"
														d="M8.2,15.7c-0.2-0.3-0.5-0.4-0.8-0.5c-0.2-0.1-0.6-0.1-1.3-0.1H4.5v5.2h1.1v-2h0.7c0.5,0,0.8,0,1.1-0.1
                                       c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.5,0.2-0.8C8.5,16.3,8.4,16,8.2,15.7z M7.3,17.1
                                       c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.4,0.1-0.8,0.1H5.5V16H6c0.4,0,0.6,0,0.8,0c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.5
                                       C7.4,16.9,7.3,17,7.3,17.1z"
													></path>
													<polygon
														fill="#202122"
														points="12.5,15.1 12.5,17.2 10.4,17.2 10.4,15.1 9.3,15.1 9.3,20.3 10.4,20.3 10.4,18.1 12.5,18.1 12.5,20.3
                                       13.5,20.3 13.5,15.1 "
													></polygon>
													<path
														fill="#202122"
														d="M18.3,15.7c-0.2-0.3-0.5-0.4-0.8-0.5c-0.2-0.1-0.6-0.1-1.3-0.1h-1.7v5.2h1.1v-2h0.7c0.5,0,0.8,0,1.1-0.1
                                       c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.3-0.3,0.4-0.5s0.2-0.5,0.2-0.8C18.6,16.3,18.5,16,18.3,15.7z M17.4,17.1c-0.1,0.1-0.2,0.2-0.3,0.3
                                       c-0.1,0.1-0.4,0.1-0.8,0.1h-0.6V16h0.5c0.4,0,0.6,0,0.8,0c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.5C17.5,16.9,17.5,17,17.4,17.1z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/web-development/wordpress-development"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M10.1,11H5c-0.4,0-0.6-0.3-0.6-0.6S4.7,9.8,5,9.8h5.1c0.4,0,0.6,0.3,0.6,0.6S10.5,11,10.1,11z"
													></path>
													<path
														fill="#202122"
														d="M17.7,11h-5.1c-0.4,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h5.1c0.4,0,0.6,0.3,0.6,0.6S18.1,11,17.7,11z"
													></path>
													<path
														fill="#202122"
														d="M13,26.3h-0.3c-0.3,0-0.5-0.2-0.6-0.4L6.8,10.6c-0.1-0.3,0-0.7,0.4-0.8l0,0C7.5,9.7,7.9,9.9,8,10.2l0,0l4.9,14
                                       l3.2-9.2c0.1-0.3,0.5-0.5,0.8-0.4s0.5,0.5,0.4,0.8l-3.7,10.5C13.5,26.2,13.3,26.3,13,26.3z"
													></path>
													<path
														fill="#202122"
														d="M20.7,26.3h-0.3c-0.3,0-0.5-0.2-0.6-0.4l-5.3-15.3c-0.1-0.3,0.1-0.7,0.4-0.8c0.3-0.1,0.7,0.1,0.8,0.4l4.9,14
                                       l3.1-8.8c0.1-0.4,1.3-4-0.4-6c-0.2-0.3-0.2-0.7,0.1-0.9c0.3-0.2,0.7-0.2,0.9,0.1c2.1,2.5,0.9,6.5,0.7,7.3L21.5,26
                                       C21.2,26.1,20.9,26.3,20.7,26.3z"
													></path>
													<path
														fill="#202122"
														d="M16,1.4C24.1,1.4,30.6,8,30.6,16S24,30.6,16,30.6C7.9,30.6,1.4,24.1,1.4,16S7.9,1.4,16,1.4 M16,0
                                       C7.2,0,0,7.1,0,16s7.1,16,16,16s16-7.1,16-16S24.8,0,16,0z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/web-development/codeigniter-development"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M28.5,17.6c-1.1-2.7-4.4-5-5.8-5.9s-1.3,0.6-1.3,0.6c-0.2,0.7-0.6,0.9-0.9,1c-0.7,0.1-1.6-0.2-1.7-1.3
                                       s0-1.2,0.4-2.7c0.4-1.6,0.1-3.5-0.1-4.6c-0.3-1-0.9-1.9-2.5-3.8s-1.9-0.2-1.9-0.2c1.2,4.5-3.4,7.4-4.7,8.6
                                       c-6.7,5.6-6.9,9.4-7.3,11.6C2.4,30.7,11.3,32,11.3,32c1-0.3,0.6-1,0.5-1.2c-0.2-0.1-1.3-1.2-1.7-1.7c-1.6-1.7-1.5-3.1-1.4-3.3
                                       c0.2-1.5,1.4-3.1,1.5-3.3c0,0.6,0.1,1,0.4,1.5c1.1,1.6,3.1,1.2,3.1,1.2c2.3-0.5,2.2-2.8,2.2-3.6c0-0.8-1.1-2-1.4-2.2
                                       c-0.6-0.5-1.1-1.4-1.1-1.4c-0.4-1,0-2,0.1-2.3c0.5,1.2,3.3,2.8,3.3,2.8s1.2,0.7,3.8,2.8c2.5,2.1,1.8,4.2,1.7,5.1
                                       c-0.5,2.1-2.5,3.4-3.1,3.8c-1.4,1-0.4,1.5,0.1,1.6c4.4-1.3,7-3.3,8.9-6.7C30.2,21.7,29,18.7,28.5,17.6z M22.8,29.2
                                       c0.6-1,1.8-3.1,1-5.9c-0.3-0.7-1.2-2.9-5.2-5.3c-1.5-1-3.5-2.1-3.8-2.9c-0.1-0.5-0.3-0.6-0.1-1.1c0-0.1-0.1-0.9-1-0.6
                                       c-0.2,0.2-2.5,2.6-1.4,5.3c0.2,0.3,1.2,1.6,1.6,2c0.4,0.4,1.3,1.1,0.2,2.9c-0.1,0.1-0.7,0.8-2.1-0.1c-0.3-0.3-0.7-0.8-0.3-2
                                       c0.2-0.6,0.2-0.8,0.2-0.8s0.1-0.8-0.8-0.7c0,0-1.5,1-1.9,1.7c-0.3,0.4-1.2,1.5-1.5,2.5c-0.2,0.8-1.2,2.7,1.2,5.5
                                       C8.6,29.6,4,27.5,4,21.5c0.2-1.9,0.6-4.9,3.6-8.2c1.2-1.2,5.1-4.7,5.4-5s3.1-2.8,3.1-5.8c0.3,0.3,1.7,1.8,1.8,3.5s0.1,2.7-0.3,3.6
                                       c-0.2,1-0.8,3,0.3,4.1c0.4,0.4,2.1,2,4,0.2c0.3-0.3,0.6-0.8,0.6-0.8s4.9,3.2,5.3,6.7C28.1,20.9,29.1,25.1,22.8,29.2z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/web-development/laravel-development"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M31.7,15.4c-0.2-0.2-3.1-3.9-3.6-4.5s-0.8-0.5-1.1-0.5s-4,0.7-4.4,0.7c-0.4,0.1-0.7,0.2-0.4,0.6
                                       c0.2,0.3,2.6,3.7,3.2,4.5l-9.6,2.3L8.2,5.7C7.9,5.2,7.8,5.1,7.1,5.1s-6,0.5-6.4,0.5s-0.8,0.2-0.4,1.1S6.7,20.6,6.9,21
                                       c0.2,0.4,0.6,1,1.6,0.8c1.1-0.3,4.7-1.2,6.7-1.7c1.1,1.9,3.2,5.7,3.6,6.3c0.5,0.8,0.9,0.6,1.7,0.4c0.6-0.2,9.9-3.5,10.3-3.7
                                       s0.7-0.3,0.4-0.7c-0.2-0.3-2.6-3.6-3.9-5.3c0.9-0.2,4-1.1,4.3-1.2C31.8,15.8,31.9,15.6,31.7,15.4L31.7,15.4z M14.2,18.9
                                       c-0.1,0-5.5,1.3-5.8,1.4s-0.3,0-0.3-0.1C8,20.1,1.7,6.9,1.6,6.8c-0.1-0.2-0.1-0.3,0-0.3s5-0.4,5.2-0.4S7,6.1,7,6.3
                                       c0,0,7.1,12.2,7.2,12.4C14.3,18.8,14.3,18.9,14.2,18.9L14.2,18.9z M29.4,21.8c0.1,0.2,0.2,0.2-0.1,0.3c-0.3,0.1-9.1,3.1-9.3,3.2
                                       s-0.3,0.1-0.5-0.2c-0.2-0.3-3.1-5.3-3.1-5.3l9.4-2.5c0.2-0.1,0.3-0.1,0.5,0.1C26.3,17.7,29.3,21.7,29.4,21.8L29.4,21.8z M30,15.1
                                       c-0.2,0-3.7,0.9-3.7,0.9l-2.8-3.9c-0.1-0.1-0.2-0.2,0-0.3s3.4-0.6,3.6-0.6s0.3-0.1,0.5,0.2c0.2,0.2,2.6,3.3,2.7,3.4
                                       C30.3,15,30.2,15.1,30,15.1L30,15.1z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link to="/ecommerce-web-development" class="waves-effect waves-light">
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M31.8,5.3C31.7,5.1,31.5,5,31.3,5H6.4L5.9,2C5.8,1.8,5.6,1.5,5.2,1.5H0.7C0.3,1.5,0,1.8,0,2.1s0.3,0.7,0.7,0.7
                                       h3.9L7,17l0,0c0,0.1,0,0.1,0,0.2L7.8,22c0.1,0.3,0.3,0.6,0.7,0.6h19c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H9l-0.6-3.6h19.8
                                       c0.3,0,0.6-0.2,0.6-0.5l3-11.5C32,5.7,31.9,5.5,31.8,5.3z M27.7,16.4H8.3L6.6,6.3h23.8L27.7,16.4z"
													></path>
													<path
														fill="#202122"
														d="M14.5,27.4c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1S14.5,29.1,14.5,27.4z M9.6,27.4
                                       c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8C10.4,29.3,9.6,28.4,9.6,27.4z"
													></path>
													<path
														fill="#202122"
														d="M26,27.4c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1S26,29.1,26,27.4z M21.1,27.4
                                       c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8C21.9,29.3,21.1,28.4,21.1,27.4z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/app-development-cost"
												class="btn-custome btn waves-effect waves-light fxt-waves-light"
												title=""
												>Calculate Web App Development Cost</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="cloud-ssa"
						role="tabpanel"
						aria-labelledby="cloud-ssa-tab"
					>
						<div class="row align-items-center justify-content-center justify-content-lg-between">
							<div class="col-12 col-lg-5 order-lg-1 text-center d-none d-md-block">
								<img
									class="lazy"
									alt="Augmented Reality Mobile App Development"
									src="../../assets/images/ar-tab-1.png"
								/>
							</div>
							<div class="col-12 col-lg-7">
								<div class="servicesTabContent_text">
									<h3>
										<a style="font-weight: bolder">AR/VR App Development</a>
									</h3>
									<span>Blur the void between the physical and digital worlds</span>
									<p>
										Augmented reality and virtual reality hold the power to transport users to a
										whole new world and experience everything and anything. They conjure a new
										cognitive experience through the few-inches wide mobile phone screens.
									</p>
									<p>
										Gaming, healthcare, education, even the defence sector stand to benefit from
										Augmented Reality or Virtual Reality development.
									</p>
									<p>
										GenixBit Labs is proud to be recognized as one among the top VR/AR mobile app
										developers in UK. We have helped countless clients with our expertise and would
										be happy to make you our next.
									</p>
									<p>
										Want to know more information about what makes GenixBit Labs the best AR/VR mobile
										app development company in UK? Check the below links.
									</p>
									<ul class="big-icn-con">
										<li>
											<router-link
												to="/mobile-development/virtual-reality-development"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M21.9,10.7c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3s2.3-1,2.3-2.3S23.2,10.7,21.9,10.7z M21.9,14.3
                                    c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2C23.2,13.7,22.6,14.3,21.9,14.3z"
													></path>
													<path
														fill="#202122"
														d="M31.4,10.2h-1.2c-0.4-1.3-1.7-5-5.8-7.3c-0.7-0.4-1.5-0.7-2.3-1s-1.7-0.5-2.6-0.6c-7.1-1-11,2.3-12.3,3.7
                                    c-0.5-0.1-1-0.2-1.6-0.2c-3,0-5.5,2.5-5.5,5.5v5.6c0,2.8,2.1,5.1,4.9,5.4l-0.7,1.4v0.1l0,0c0,0.1,0,0.1,0,0.2l0,0
                                    c0,0.1,0,0.1,0.1,0.2l2,2.9c-0.1,0.7-0.2,2.6,0.8,3.8c0.5,0.6,1.1,0.8,1.9,0.8c3.4,0,9.1-1.4,9.1-6.9c0-0.3-0.2-0.5-0.5-0.5
                                    s-0.5,0.2-0.5,0.5c0,5.7-7.7,5.8-8,5.8c-0.5,0-0.8-0.2-1.1-0.5c-0.7-0.8-0.6-2.5-0.6-3.1c0-0.1,0-0.3-0.1-0.4l-1.5-2.2h1.3
                                    c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H5.7l0.5-1c2.2-0.2,4-1.7,4.7-3.8c0.3,0.1,0.6,0.1,0.8,0.1c1.2,0,2.3-0.7,2.8-1.7h3.7
                                    c0.9,1.1,2.2,1.8,3.7,1.8c1.4,0,2.8-0.7,3.7-1.8h3.7c-0.3,1.5-1.8,3.5-2.4,4.1c-0.1,0.1-0.1,0.2-0.1,0.4v8.9c0,0.3,0.2,0.5,0.5,0.5
                                    s0.5-0.2,0.5-0.5v-8.7c0.5-0.6,2.3-2.8,2.6-4.7h1.1c0.3,0,0.5-0.2,0.5-0.5v-4.7C32,10.4,31.7,10.2,31.4,10.2z M2.2,18.5
                                    c-0.6-0.8-0.9-1.7-0.9-2.7v-5.6c0-1,0.4-1.9,0.9-2.7V18.5z M10,16.8c-0.5,2-2.3,3.5-4.3,3.5c-0.9,0-1.7-0.3-2.4-0.7v-13
                                    C4,6.1,4.8,5.9,5.7,5.9c2.1,0,3.9,1.5,4.3,3.5V16.8z M13.8,14.5c0,1.1-0.9,2.1-2.1,2.1c-0.2,0-0.4-0.1-0.7-0.1V9.6
                                    c0.2-0.1,0.4-0.1,0.7-0.1c1.1,0,2.1,0.9,2.1,2.1C13.8,11.6,13.8,14.5,13.8,14.5z M11.7,8.4c-0.3,0-0.6,0-0.8,0.1
                                    c-0.4-1.3-1.3-2.4-2.5-3c1.4-1.4,4.9-4,11.1-3.1c0.9,0.1,1.7,0.3,2.4,0.6c0.7,0.2,1.4,0.6,2.1,0.9c3.4,1.9,4.8,5,5.2,6.3h-3.6
                                    c-0.9-1.1-2.2-1.8-3.7-1.8c-1.4,0-2.8,0.7-3.7,1.8h-3.7C14,9.1,13,8.4,11.7,8.4z M31,14.8h-5.7c-0.2,0-0.3,0.1-0.5,0.2
                                    c-0.7,1-1.8,1.5-3,1.5s-2.3-0.6-3-1.5c-0.1-0.1-0.3-0.2-0.5-0.2h-3.7c0-0.1,0-0.2,0-0.3v-3c0-0.1,0-0.2,0-0.3h3.7
                                    c0.2,0,0.3-0.1,0.5-0.2c0.7-1,1.8-1.5,3-1.5s2.3,0.6,3,1.5c0.1,0.1,0.3,0.2,0.5,0.2H31V14.8z"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/mobile-development/augmented-reality-development"
												class="waves-effect waves-light"
											>
												<svg
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<path
														fill="#202122"
														d="M20.6,27.2c-0.4,0-0.8-0.1-1.2-0.3L12.5,23c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.3
                                       c0.1,0,0.2,0,0.4,0.1l6.7,3.9c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.2l9.4-5.4l0.1-0.1l0-0.6l-8.9,5.1
                                       c-0.4,0.2-0.8,0.3-1.3,0.3c-0.4,0-0.8-0.1-1.2-0.3l-5.2-3c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1
                                       l5.2,2.9c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.2l9.4-5.4l-5-2.9c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.1-0.5
                                       c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1l5.1,3c0.4,0.3,0.7,0.7,0.7,1.1l-0.1,0.1v2c0,0.4-0.3,0.8-0.7,1.1l-9.4,5.4
                                       C21.4,27.1,21,27.2,20.6,27.2z M20.4,22.1c-0.1,0-0.2,0-0.4-0.1l-0.6-0.4c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.3
                                       c0.1,0,0.2,0,0.4,0.1l0.6,0.4c0.3,0.2,0.4,0.6,0.2,0.9C20.8,22,20.6,22.1,20.4,22.1L20.4,22.1z M11.5,22.1c-0.2,0-0.4-0.1-0.5-0.3
                                       c-0.2-0.3-0.1-0.7,0.2-0.9l0.6-0.4c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.4-0.1l-3.4-1.9C8,18.2,7.9,17.9,8,17.6
                                       c0-0.1,0.1-0.1,0.2-0.2l-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.3,0-0.7,0.2-0.9L8,16
                                       c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5,0.3c0.2,0.3,0.1,0.7-0.2,0.9l-0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0.1l3.4,1.9
                                       c0.3,0.2,0.4,0.6,0.2,0.9c-0.1,0.2-0.3,0.3-0.4,0.3c0,0,0,0,0,0c0.3,0,0.5,0.1,0.6,0.3c0.1,0.1,0.1,0.3,0.1,0.5
                                       c0,0.2-0.1,0.3-0.3,0.4L11.9,22C11.8,22.1,11.6,22.1,11.5,22.1z M10.2,21.5c-0.1,0-0.2,0-0.4-0.1l-3.4-1.9c-0.2-0.1-0.3-0.2-0.3-0.4
                                       c0-0.2,0-0.3,0.1-0.5c0-0.1,0.1-0.1,0.1-0.2l-0.3,0.2c-0.1,0.1-0.2,0.1-0.4,0.1l0,0c-0.2,0-0.4-0.1-0.5-0.2
                                       c-0.1-0.2-0.2-0.4-0.1-0.5c0-0.2,0.1-0.3,0.3-0.4L6,17.2c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5,0.3C7,17.6,7,17.8,7,17.9
                                       c-0.1,0.2-0.2,0.3-0.4,0.3l0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0.1l3.4,1.9c0.3,0.2,0.4,0.6,0.2,0.9
                                       C10.6,21.4,10.4,21.5,10.2,21.5L10.2,21.5z M18.6,21c-0.1,0-0.2,0-0.4-0.1l-0.7-0.4c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.1-0.5
                                       c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1l0.7,0.4c0.2,0.1,0.3,0.3,0.3,0.4c0,0.2,0,0.3-0.1,0.5C19,20.9,18.8,21,18.6,21
                                       L18.6,21z M13.4,21c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.5c0-0.2,0.1-0.3,0.3-0.4l0.7-0.4c0.1-0.1,0.2-0.1,0.3-0.1
                                       c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.2,0.4,0.1,0.5c0,0.2-0.1,0.3-0.3,0.4l-0.7,0.4C13.6,21,13.5,21,13.4,21z M16.6,19.9
                                       c-0.1,0-0.2,0-0.4-0.1L16,19.6l-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.5
                                       c0-0.1,0-0.1,0.1-0.2l-4.7-2.7l-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.5
                                       c0-0.1,0.1-0.2,0.1-0.2c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.2,0-0.3,0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.3V8.8c0-0.2,0.1-0.5,0.3-0.6l1.5-0.8
                                       c-0.2,0-0.5,0.1-0.6,0.2L9.5,8.2l0,0.5C9.4,9.2,9.1,9.6,8.7,9.8l-3.4,2c-0.5,0.2-0.9,0.3-1.4,0.3H2.7c0,0-1.5,0.9-1.5,0.9l5,2.8
                                       c0.3,0.2,0.4,0.6,0.2,0.9C6.3,16.9,6.1,17,5.9,17c-0.1,0-0.2,0-0.4-0.1l-4.4-2.5V15l3.5,2c0.2,0.1,0.4,0.2,0.4,0.4
                                       c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2,0-0.4-0.1l-3.4-2c-0.4-0.2-0.6-0.6-0.7-1.1L0,13c0-0.4,0.3-0.8,0.7-1.1
                                       l9.4-5.4c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.8,0.1,1.2,0.3l0.2,0.1l2.9-1.7c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1l5.8,3.3
                                       c0.2,0.1,0.3,0.3,0.3,0.6V12l1.1,0.6c0.3,0.2,0.4,0.6,0.2,0.9c0,0,0,0,0,0l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5,0.3
                                       c0.1,0.2,0.1,0.3,0.1,0.5c0,0.2-0.1,0.3-0.3,0.4L24,15c-0.1,0.1-0.2,0.1-0.3,0.1l0,0c-0.2,0-0.4-0.1-0.5-0.3
                                       c-0.2-0.3-0.1-0.7,0.2-0.9l0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4-0.1l-0.4-0.3v0.9c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,0.1,0.3,0.1,0.5
                                       c0,0.1-0.1,0.2-0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.2c0,0.2,0,0.3-0.1,0.5c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.2,0-0.4-0.1l-0.3-0.1
                                       l-4.7,2.7c0,0.1,0,0.1,0.1,0.2c0,0.2,0,0.3-0.1,0.5C17,19.7,16.8,19.9,16.6,19.9z M15.3,17.8v-5.2l-4.5-2.6v5.2L15.3,17.8z
                                       M16.6,17.8l4.5-2.6V9.9l-4.5,2.6V17.8z M16,11.4l4.5-2.6L16,6.2l-4.5,2.6L16,11.4z M26.3,18.8c-0.1,0-0.2,0-0.4-0.1l-0.6-0.4
                                       c-0.3-0.2-0.4-0.6-0.2-0.9c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1l0.6,0.4c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2,0,0.3-0.1,0.5
                                       C26.7,18.7,26.5,18.8,26.3,18.8L26.3,18.8z M24.4,17.7c-0.1,0-0.2,0-0.4-0.1l-0.7-0.4c-0.3-0.2-0.4-0.6-0.2-0.9
                                       c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1l0.7,0.4c0.3,0.1,0.3,0.5,0.3,0.8C24.8,17.6,24.6,17.7,24.4,17.7z M8.3,15.1
                                       c-0.1,0-0.2,0-0.4-0.1l-0.7-0.4C7,14.4,6.9,14,7,13.7c0.2-0.3,0.4-0.4,0.6-0.4c0.1,0,0.2,0,0.4,0.1l0.7,0.4c0.3,0.2,0.4,0.6,0.2,0.9
                                       C8.7,15,8.5,15.1,8.3,15.1z M6.3,14c-0.1,0-0.2,0-0.4-0.1l-0.6-0.4c-0.2-0.1-0.3-0.2-0.3-0.4c0-0.2,0-0.3,0.1-0.5
                                       c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0,0.4,0.1l0.6,0.4C7,13,7.1,13.4,6.9,13.7C6.7,13.9,6.6,14,6.3,14L6.3,14z M25.7,13.9
                                       c-0.2,0-0.5-0.1-0.6-0.3c-0.2-0.3-0.1-0.6,0.2-0.9l0.6-0.4c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.3,0.1,0.5
                                       c0,0.2-0.1,0.3-0.3,0.4L26,13.8C25.9,13.9,25.7,13.9,25.7,13.9z"
													></path>
												</svg>
											</router-link>
										</li>
										<span class="or" style="margin-right: 15px">OR</span>
										<li>
											<router-link
												to="/contact"
												class="btn-custome btn waves-effect waves-light fxt-waves-light"
												title=""
												>Talk to Our Experts</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="database-game"
						role="tabpanel"
						aria-labelledby="database-game-tab"
					>
						<div class="row align-items-center justify-content-center justify-content-lg-between">
							<div class="col-12 col-lg-5 order-lg-1 text-center d-none d-md-block">
								<img
									class="lazy"
									alt="Mobile Game Development"
									src="../../assets/images/game-tab.png"
								/>
							</div>
							<div class="col-12 col-lg-7">
								<div class="servicesTabContent_text">
									<h3>
										<a style="font-weight: bolder">Game Development</a>
									</h3>
									<span
										>Build mobile apps that are high on strategy, challenges &amp;
										entertainment</span
									>
									<p>
										Games turned computers into the most sought-after entertainment source. With
										time, they have become extraordinarily intelligent, minutely detailed and
										extremely challenging. Developing a game is an entirely different ball game
										compared to building any other digital product.
									</p>
									<p>
										At GenixBit Labs, games are not just a source of entertainment; it is a passion
										that runs high through all of our team. We obsess over the latest games, see how
										they work and what makes them successful, and try to learn from them. We then
										take our findings and incorporate into the games we develop so that your users
										get nothing but the best gaming experience.
									</p>
									<p>
										Don’t take our word. Talk to our experts to find out about the diverse range of
										games we have built so far.
									</p>
									<p>Want to know more information? Check the below links.</p>
									<ul class="big-icn-con">
										<li>
											<router-link
												to="/game-development/ios-game-development"
												class="waves-effect waves-light"
												title="iOS Game"
											>
												<svg
													x="0px"
													y="0px"
													viewBox="0 0 100 100"
													style="enable-background: new 0 0 100 100"
													fill="currentColor"
													xml:space="preserve"
												>
													<g>
														<g>
															<path
																class="st0"
																d="M49.7,25.7c0.4,0,0.8,0,1.1,0c5.3,0,11-2.9,14.8-7.5c4-4.8,6.1-11,5.5-16.6c-0.1-1-1-1.7-2-1.7
                                       c-5.4,0.2-11.8,3.5-15.6,7.9c-3.3,3.7-6.4,9.9-5.6,16.2C48.1,24.9,48.8,25.6,49.7,25.7z M56.5,10.3c2.7-3.1,7.1-5.6,11-6.3
                                       c-0.1,4.1-1.8,8.4-4.7,11.9c-2.9,3.5-7.2,5.8-11.1,6.1C51.7,17.4,54.1,12.9,56.5,10.3z M90.1,70c-1.2-0.5-11.7-5.1-11.8-17.5
                                       c-0.1-9.9,7.2-15.1,9.6-16.6c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4C82.3,24.5,73,23.2,69.4,23.1c-5-0.5-9.9,1.4-13.6,2.8
                                       c-2,0.8-4,1.5-5.1,1.5c-1.3,0-3.2-0.8-5.3-1.6c-3.1-1.2-6.6-2.6-10.4-2.6l-0.3,0c-8.8,0.1-17.1,5.1-21.6,12.9
                                       c-8.5,14.6-3.2,36.6,6.2,50C23,91.6,28.3,99,35.7,99l0.4,0c3.2-0.1,5.4-1.1,7.6-2c2.4-1,4.6-1.9,8.2-1.9c3.4,0,5.5,0.9,7.7,1.8
                                       c2.2,1,4.6,1.9,8,1.9l0.3,0c7.7-0.1,12.5-7,16.4-12.5c4.1-6,6.2-11.7,6.9-14C91.5,71.4,91,70.4,90.1,70z M81.1,84.3
                                       c-4.2,6.1-7.9,10.8-13.3,10.9l-0.3,0c-2.6,0-4.4-0.8-6.4-1.6c-2.4-1-5.1-2.2-9.2-2.2c-4.4,0-7.2,1.2-9.7,2.2
                                       c-2,0.9-3.8,1.6-6.3,1.7l-0.3,0c-5,0-9.2-5.3-13.4-11.3c-11.7-16.6-11.8-36.1-6-46c3.9-6.7,10.9-10.9,18.4-11l0.2,0
                                       c3.1,0,6.2,1.2,9,2.3c2.5,1,4.7,1.8,6.7,1.8c1.8,0,4-0.8,6.5-1.8c3.2-1.2,6.8-2.6,10.5-2.6c0.4,0,0.9,0,1.5,0.1
                                       c2.9,0.1,10,1.1,15.1,7.1c-3.5,2.6-9.7,8.6-9.6,18.6c0.1,12.4,8.8,18.3,12.6,20.2C86.2,75.3,84.3,79.7,81.1,84.3z"
															></path>
														</g>
													</g>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/game-development/android-game-development"
												class="waves-effect waves-light"
												title="Android Game"
											>
												<svg
													x="0px"
													y="0px"
													viewBox="0 0 100 100"
													style="enable-background: new 0 0 100 100"
													xml:space="preserve"
												>
													<path
														class="st0"
														d="M65.3,9l2.9-4.3c0.5-0.7,0.6-1.5,0.5-2.3c-0.2-0.8-0.6-1.5-1.3-1.9C66-0.4,64,0,63.1,1.3l-3.4,5.1
                                     c-6.2-2-13.2-2-19.4,0l-3.4-5.1C36,0,34-0.4,32.7,0.5c-1.4,0.9-1.8,2.8-0.8,4.2L34.7,9c-7.1,4.2-11.5,11.4-11.5,18.9
                                     c0,1.2,1,2.1,2.2,2.1h49.6c1.2,0,2.2-1,2.2-2.1C77,20.4,72.5,13.2,65.3,9L65.3,9z M27.6,25.8c0.8-5.9,5-11.2,11.1-14
                                     c0.3-0.1,0.5-0.3,0.7-0.5c0.3,0,0.6,0,0.8-0.1c6.1-2.4,13.4-2.4,19.5,0c0.3,0.1,0.6,0.2,0.8,0.1c0.2,0.2,0.4,0.4,0.7,0.5
                                     c6.2,2.9,10.4,8.2,11.3,14H27.6z M74.8,32.2H25.2c-1.2,0-2.2,1-2.2,2.2v39.9c0,4.3,3.5,7.8,7.8,7.8h1.7v10.5c0,4.1,3.3,7.5,7.5,7.5
                                     c4.1,0,7.5-3.3,7.5-7.5V82h5.1v10.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5V82h1.7c4.3,0,7.8-3.5,7.8-7.8V34.4
                                     C77,33.2,76,32.2,74.8,32.2L74.8,32.2z M72.7,74.3c0,1.9-1.5,3.5-3.5,3.5h-4.1c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.3-0.3,1.9
                                     c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9
                                     c-0.4-0.5-1-0.9-1.7-0.9h-9.9c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2
                                     c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9c-0.4-0.5-1.1-0.9-1.7-0.9h-4.1c-1.9,0-3.5-1.6-3.5-3.5
                                     V36.5h45.3V74.3z M14.2,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                                     C21.6,36.5,18.3,33.2,14.2,33.2L14.2,33.2z M17.3,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                                     c1.7,0,3.2,1.4,3.2,3.2V63.3z M85.8,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                                     C93.3,36.5,90,33.2,85.8,33.2L85.8,33.2z M89,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                                     c1.7,0,3.2,1.4,3.2,3.2V63.3z M60.3,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                                     C62.8,17.3,61.7,16.2,60.3,16.2L60.3,16.2z M40.9,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                                     C43.4,17.3,42.3,16.2,40.9,16.2L40.9,16.2z M40.9,16.2"
													></path>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/game-development/augmented-reality-game-development"
												class="waves-effect waves-light"
												title="AR Game"
											>
												<svg
													x="0px"
													y="0px"
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<g>
														<g>
															<path
																class="st0"
																d="M17.7,28.9h-2.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h2.6c0.3,0,0.5-0.2,0.5-0.5
                                        C18.2,29.1,18,28.9,17.7,28.9z M10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.5,10,12.5,10,12.4
                                        C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4L10,12.4C10,12.5,10,12.5,10,12.4L10,12.4
                                        C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4L10,12.4
                                        C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2v0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
                                        c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1l6,2.1c0,0,0.1,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0l6-2.1
                                        c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0v-7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
                                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l-6-2.1c-0.1,0-0.2,0-0.3,0l-6,2.1C10.1,12.4,10.1,12.4,10,12.4
                                        C10.1,12.4,10.1,12.4,10,12.4C10.1,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4L10,12.4
                                        C10,12.4,10,12.4,10,12.4z M21.9,19.5l-3.1-1c-0.3-0.1-0.5,0-0.6,0.3c-0.1,0.2,0,0.5,0.3,0.6l2.4,0.8l-4.1,1.4v-6.3l5.1-1.7V19.5z
                                        M16.4,11.2l4.6,1.6l-4.6,1.6l-4.6-1.6L16.4,11.2z M10.8,13.5l5.1,1.7v6.3l-4.1-1.4l2.4-0.8c0.3-0.1,0.4-0.4,0.3-0.6
                                        c-0.1-0.2-0.4-0.4-0.6-0.3l-3,1V13.5z M26.7,0H6C5,0,4.1,0.8,4.1,1.8v28.4C4.1,31.2,5,32,6,32h20.7c1.1,0,1.9-0.8,1.9-1.8V1.8
                                        C28.7,0.8,27.8,0,26.7,0z M27.7,30.2c0,0.5-0.4,0.9-0.9,0.9H6c-0.5,0-0.9-0.4-0.9-0.9V1.8c0-0.5,0.4-0.9,0.9-0.9h20.7
                                        c0.5,0,0.9,0.4,0.9,0.9V30.2z M25.2,2.4H7.5C7,2.4,6.6,2.7,6.6,3.2v23.9C6.6,27.6,7,28,7.5,28h17.7c0.5,0,0.9-0.4,0.9-0.8V3.2
                                        C26.1,2.7,25.7,2.4,25.2,2.4z M25.1,5.2H11.9c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h13.3V27H7.6V6.1h1.9
                                        c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H7.6V3.3h17.5V5.2z"
															></path>
														</g>
													</g>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/game-development/virtual-reality-game-development"
												class="waves-effect waves-light"
												title="VR Game"
											>
												<svg
													x="0px"
													y="0px"
													viewBox="0 0 32 32"
													style="enable-background: new 0 0 32 32"
													xml:space="preserve"
												>
													<g>
														<g>
															<path
																class="st0"
																d="M11.2,14.9h2.6c0.6,0,1.1-0.3,1.3-0.8c0.1-0.1,0.2-0.2,0.4-0.2h0.9c0.2,0,0.3,0.1,0.4,0.2
                                        c0.3,0.5,0.8,0.8,1.3,0.8h2.6c1.2,0,2.1-1,2.1-2.1v-2.1c0-1.2-1-2.1-2.1-2.1h-9.6c-1.2,0-2.1,1-2.1,2.1v2.1
                                        C9.1,14,10,14.9,11.2,14.9z M10.1,10.7c0-0.6,0.5-1.1,1.1-1.1h9.6c0.6,0,1.1,0.5,1.1,1.1v2.1c0,0.6-0.5,1.1-1.1,1.1h-2.6
                                        c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.5-0.8-0.8-1.3-0.8h-0.9c-0.6,0-1.1,0.3-1.3,0.8c-0.1,0.1-0.2,0.2-0.4,0.2h-2.6
                                        c-0.6,0-1.1-0.5-1.1-1.1V10.7z M30.4,26c-2.5-1.3-6.8-2.8-9.6-3.4c2.3-2,3.7-5.2,3.7-8.2h0.5c0.6,0,1.1-0.5,1.1-1.1v-3.2
                                        c0-0.6-0.5-1.1-1.1-1.1h-0.5c0-0.3-0.1-0.6-0.2-0.9C23.6,4.2,20.3,0,16,0c-4.3,0-7.6,4.2-8.3,8.2C7.5,8.5,7.5,8.8,7.5,9.1H6.9
                                        c-0.6,0-1.1,0.5-1.1,1.1v3.2c0,0.6,0.5,1.1,1.1,1.1h0.5c0,3,1.4,6.2,3.7,8.2c-2.8,0.6-7.1,2.1-9.6,3.4c-1,0.5-1.6,1.5-1.6,2.6v2.8
                                        C0,31.8,0.3,32,0.5,32h30.9c0.3,0,0.5-0.2,0.5-0.5v-2.8C32,27.5,31.4,26.5,30.4,26z M24.5,10.1h0.5l0,3.2h-0.5V10.1z M7.5,13.3
                                        H6.9v-3.2h0.5V13.3z M16,1.1c3.2,0,5.8,2.8,6.9,5.9c-0.2,0-0.3-0.1-0.5-0.1H9.6C9.4,6.9,9.2,7,9.1,7C10.2,3.9,12.8,1.1,16,1.1z
                                        M8.5,9.1C8.5,8.5,9,8,9.6,8h12.8c0.4,0,0.8,0.3,1,0.6c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4v5.3c0,0.6-0.5,1.1-1.1,1.1H9.6
                                        c-0.6,0-1.1-0.5-1.1-1.1V9.1z M8.7,16.3c0.3,0.1,0.6,0.2,0.9,0.2h12.8c0.3,0,0.6-0.1,0.9-0.2c-0.8,4.2-4.2,7.1-7.3,7.1
                                        C12.9,23.5,9.6,20.5,8.7,16.3z M17.5,24.4l-1.3,1.7c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.2-0.1l-1.3-1.7c0.5,0.1,1,0.2,1.5,0.2
                                        C16.5,24.5,17,24.5,17.5,24.4z M24.5,30.9h-3.2v-1.1h3.2V30.9z M30.9,30.9h-5.3v-1.6c0-0.3-0.2-0.5-0.5-0.5h-4.3
                                        c-0.3,0-0.5,0.2-0.5,0.5v1.6H1.1v-2.3c0-0.7,0.4-1.4,1-1.7c2.8-1.4,7.7-3.1,10.3-3.5l0.1,0c0.1,0,0.1,0.1,0.2,0.1l2.3,3
                                        c0.2,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5l2.3-3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1c2.6,0.4,7.5,2,10.3,3.5c0.6,0.3,1,1,1,1.7V30.9z
                                        "
															></path>
														</g>
													</g>
												</svg>
											</router-link>
										</li>
										<li>
											<router-link
												to="/app-development-cost"
												class="btn-custome btn waves-effect waves-light fxt-waves-light"
												title=""
												>Calculate Game Development Cost</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="uiux-design"
						role="tabpanel"
						aria-labelledby="uiux-design-tab"
					>
						<div class="row align-items-center justify-content-center justify-content-lg-between">
							<div class="col-12 col-lg-5 order-lg-1 text-center d-none d-md-block">
								<img
									class="lazy"
									alt="GenixBit Labs offers mobile user interface design services in the UK and across the world"
									src="../../assets/images/ui-ux-tab.jpg"
								/>
							</div>
							<div class="col-12 col-lg-7">
								<div class="servicesTabContent_text">
									<h3>
										<a style="font-weight: bolder">UX &amp; UI Design</a>
									</h3>
									<span>Our design make everything look great and work intuitively</span>
									<p>
										Good design is not only about making something look better. It makes things work
										better. A mobile app, a web application, software or online store; good designs
										can turn anything into a stellar experience.
									</p>
									<p>
										GenixBit Labs UI/UX designers are masters of styles, shapes and hues who can
										turn any interface into a compelling surface. They think from the mindset of the
										user to design interfaces that heighten an experience and increase app
										engagement.
									</p>
									<p>
										Increase your app usage, downloads, retention, user experience and ultimately,
										business growth with our UI/UX design expertise.
									</p>
									<p>
										As a leading web and mobile app development agency, do you want to know more
										information about our UI/UX? Check the below links.
									</p>
									<ul class="big-icn-con">
										<li>
											<router-link
												to="/contact"
												class="btn-custome btn waves-effect waves-light fxt-waves-light"
												title=""
												>Talk to Our UI/UX Experts</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 991px) {
	.img-cont-container {
		flex-direction: column-reverse;
	}
}
@media (max-width: 576px) {
	.fxt-navbar-nav {
		padding: 8px 0 !important;
	}
	.fxt-srvs-title {
		font-size: 22px !important;
		font-weight: bold !important;
	}
	.big-icn-con {
		padding-left: 0 !important;
		justify-content: center;
	}
	.fxt-waves-light {
		margin-top: 20px;
	}
	.fxt-icns-list {
		padding-top: 0;
		padding-bottom: 0;
	}
	.fxt-mobile-dev {
		font-size: 22px;
		margin-top: 30px;
		font-weight: bold;
	}
	.fxt-icons-conteinter {
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: space-around;
	}
	.fxt-icons-conteinter li:last-child a {
		font-size: 14px !important;
		margin: 5px 0;
	}
}
@media (max-width: 450px) {
	.fxt-btn-head {
		font-size: 10px !important;
	}
	.fxt-navbar-nav li {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin: 0;
	}
}
</style>