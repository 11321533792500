<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					Why Genixbit is an Ideal Mac Game Development Partner for You?
				</h2>
				<p>
					Genixbit is a known hub for various digital game development solutions, and have a
					specialist team in Mac game development within our studio.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<div class="game-why-us-inner-content-wrap">
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<g>
												<path
													class="st0"
													d="M17.4,34.1h2.5v7.7c0,1.2,1,2.2,2.2,2.2H28c1.2,0,2.2-1,2.2-2.2v-7.7h2.4c0.5,0,0.8-0.3,0.8-0.8V21
                          c0-2.8-2-5.1-4.6-5.1h-7.4c-2.6,0-4.6,2.3-4.6,5.1v12.4C16.7,33.8,17,34.1,17.4,34.1z M18.2,21c0-2,1.4-3.6,3.1-3.6h7.4
                          c1.7,0,3.1,1.6,3.1,3.6v11.6h-1.7v-6.1c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v15.3c0,0.4-0.3,0.7-0.7,0.7h-2.1v-8.7
                          c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v8.7h-2.1c-0.4,0-0.7-0.3-0.7-0.7V26.4c0-0.5-0.3-0.8-0.8-0.8
                          c-0.5,0-0.8,0.3-0.8,0.8v6.1h-1.7V21z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M25,15.5c2.8,0,5.2-2.3,5.2-5c0-2.8-2.3-5-5.2-5s-5.2,2.3-5.2,5S22.2,15.5,25,15.5z M25,6.9
                          c2,0,3.6,1.6,3.6,3.5s-1.6,3.5-3.6,3.5s-3.6-1.6-3.6-3.5S23,6.9,25,6.9z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M42.4,9.5c2.6,0,4.7-2.1,4.7-4.8C47.1,2.1,45,0,42.4,0s-4.7,2.1-4.7,4.8C37.7,7.3,39.9,9.5,42.4,9.5z
                          M42.4,1.5c1.7,0,3.2,1.4,3.2,3.3S44.2,8,42.4,8s-3.2-1.4-3.2-3.3S40.7,1.5,42.4,1.5z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M7.6,9.5c2.6,0,4.7-2.1,4.7-4.8c0-2.6-2.1-4.7-4.7-4.7S2.9,2.1,2.9,4.8C2.9,7.3,5,9.5,7.6,9.5z M7.6,1.5
                          c1.7,0,3.2,1.4,3.2,3.3S9.3,8,7.6,8S4.4,6.5,4.4,4.8S5.8,1.5,7.6,1.5z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M47.1,31.6V27h2.1c0.5,0,0.8-0.3,0.8-0.8V14.6c0-2.7-1.9-4.9-4.2-4.9h-6.7c-2.3,0-4.2,2.2-4.2,4.9v11.6
                          c0,0.5,0.3,0.8,0.8,0.8h2.1v7.1c0,1.1,0.9,2.1,2,2.1H45c1.1,0,2-0.9,2-2.1v-0.3c0.9,1.2,1.4,2.4,1.4,3.6c0,6.1-10.5,11-23.5,11
                          C12,48.5,1.5,43.6,1.5,37.5c0-1.3,0.5-2.5,1.4-3.6v0.2c0,1.1,0.9,2.1,2,2.1h5.2c1.1,0,2-0.9,2-2.1V27h2.2c0.5,0,0.8-0.3,0.8-0.8
                          V14.6c0-2.7-1.9-4.9-4.2-4.9H4.2C1.9,9.8,0,12,0,14.6v11.6C0,26.7,0.3,27,0.8,27h2.1v4.6C1,33.3,0,35.4,0,37.5
                          C0,44.5,11,50,25,50s25-5.5,25-12.5C50,35.4,49,33.3,47.1,31.6z M45,34.7h-1.8v-8.1c0-0.5-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v8.1
                          h-1.9c-0.3,0-0.5-0.2-0.5-0.6V19.8c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v5.7h-1.4V14.6c0-1.8,1.2-3.3,2.7-3.3h6.7
                          c1.5,0,2.7,1.5,2.7,3.3v10.8h-1.4v-5.7c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v12.1l0,0v2.2C45.6,34.4,45.3,34.7,45,34.7z
                          M1.5,25.5V14.6c0-1.8,1.2-3.3,2.7-3.3h6.7c1.5,0,2.7,1.5,2.7,3.3v10.8h-1.4v-5.7c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8
                          v14.2c0,0.3-0.2,0.6-0.5,0.6H8.3v-8.1c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v8.1H5c-0.3,0-0.5-0.2-0.5-0.6V19.8
                          c0-0.5-0.3-0.8-0.8-0.8S3,19.3,3,19.8v5.7H1.5z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M20.6,44.9c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h8.7c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8
                          H20.6z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M11.5,37.7H3.6c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h7.9c0.5,0,0.8-0.3,0.8-0.8
                          C12.3,38,12,37.7,11.5,37.7z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													class="st0"
													d="M46.4,39.2c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-7.9c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8
                          H46.4z"
												></path>
											</g>
										</g>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="one">
								<h3>Team of Mac Game Artists</h3>
								<p>
									Our firm grips on Mac game conceptualisation, storyline, motion graphics design
									and background artistry result in stunning yet engaging games.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M21.8,32.1c-0.3-0.3-0.8-0.4-1.2-0.1l-4.7,3.9c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.2,0.1,0.5,0.3,0.6l4.5,3.7
                        c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.2-0.9-0.1-1.2l-3.7-3.1l3.9-3.2C22,32.9,22.1,32.4,21.8,32.1z"
											></path>
											<path
												d="M30.7,39.3h-5.5c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h5.5c0.5,0,0.8-0.4,0.8-0.8C31.5,39.7,31.2,39.3,30.7,39.3z
                        "
											></path>
											<path
												d="M48.9,27c0-6.1-7.6-9.8-7.9-9.9c-0.9-0.5-1.4-1.2-0.7-3.2c0.6-1.8,1.2-3.6,0.9-6c-0.4-3.8-3.9-6.8-7.8-6.8c0,0,0,0-0.1,0
                        c-3.8,0-7.3,3-7.8,6.6c-0.6-0.1-1.2-0.2-1.7-0.2c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.6-3.6-3.9-6.4-7.7-6.4c0,0,0,0-0.1,0
                        c-3.9,0-7.4,3-7.8,6.8c-0.3,2.4,0.3,4.2,0.9,6C9.5,15.8,9,16.5,8.1,17c-0.3,0.2-7.9,3.8-7.9,9.9c0,5.7,0,12.2,0,12.2
                        C0.2,39.6,0.5,40,1,40h7.3c0,4.2,0,8,0,8.1c0,0.5,0.4,0.8,0.8,0.8h29.4c0.5,0,0.8-0.4,0.8-0.8c0,0,0-3.8,0-8.1h8.8
                        c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6L48.9,27z M27.2,8c0.3-2.9,3.1-5.3,6.1-5.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c3,0,5.8,2.4,6.1,5.3c0.2,1.9-0.1,3.3-0.8,5.3c-0.2,0.5-0.6,1.7-0.3,2.9c-1.6,1.5-3.3,2.3-5.1,2.2c-0.7,0-1.4-0.2-2-0.4
                        c0.3-1.2,0.4-2.4,0.3-3.8c-0.3-2.6-2.1-4.9-4.4-6C27.2,8.2,27.2,8.1,27.2,8z M23.6,9.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0.6,0,1.1,0.1,1.7,0.2c0.6,0.2,1.2,0.5,1.7,0.8c1.5,1,2.6,2.5,2.8,4.3c0.1,1,0,2-0.2,2.9c-0.1,0.5-0.3,1.1-0.4,1.6
                        c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.3-0.3,0.9-0.4,1.5c-0.1,0.5-0.1,1,0.1,1.5c-0.2,0.2-0.4,0.4-0.6,0.5c-1.4,1.2-2.9,1.7-4.5,1.7
                        c-1.3,0-2.5-0.5-3.4-1.1c-0.6-0.3-1-0.7-1.3-1c-0.1-0.1-0.2-0.2-0.3-0.2c0.3-1.2-0.1-2.4-0.3-2.9c0,0,0-0.1,0-0.1
                        c-0.2-0.6-0.4-1.1-0.5-1.6c-0.3-1.2-0.5-2.3-0.3-3.6c0.2-2.3,2-4.2,4.2-5C22.4,9.3,23,9.2,23.6,9.2z M9.5,8
                        c0.3-2.9,3.1-5.3,6.1-5.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c2.9,0,5.7,2.3,6.1,5.1C20,8.3,18.4,9.4,17.3,11
                        c-0.1-0.1-0.2-0.1-0.4-0.1h-1.2V8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v3.7c0,0.5,0.4,0.8,0.8,0.8h1.6
                        c-0.2,0.6-0.4,1.2-0.4,1.8c-0.2,1.6,0,2.9,0.4,4.2c-0.3,0-0.5,0-0.8,0c-2.4-0.1-4.3-1.6-5-2.3c0.3-1.2-0.1-2.4-0.3-2.9
                        C9.6,11.3,9.3,9.9,9.5,8z M8.3,33.4c0,1.5,0,3.2,0,5H1.8c0-1.9,0-6.9,0-11.4c0-5.1,6.9-8.4,7-8.5c0.4-0.2,0.7-0.5,1-0.7
                        c1,0.9,3.1,2.4,5.7,2.4c0.1,0,0.1,0,0.2,0c0.4,0,0.7,0,1.1-0.1c0,0.1,0,0.1,0.1,0.2c0.7,1.9,0.2,2.7-0.7,3.1
                        C16,23.6,8.4,27.3,8.3,33.4z M37.7,47.3H10c0-1.3,0-4.2,0-7.2c0-0.5,0-1.1,0-1.6c0-1.7,0-3.5,0-5c0-5.1,6.9-8.4,7-8.5
                        c0.4-0.2,0.7-0.5,1-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0.4,0.3,0.9,0.6,1.4,1c1.1,0.6,2.4,1.1,4,1.2c0.1,0,0.1,0,0.2,0
                        c1.8,0,3.5-0.6,5.1-1.9c0.2-0.2,0.4-0.3,0.6-0.5c0.3,0.3,0.6,0.5,1,0.8c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0
                        c1.3,0.7,6.7,3.8,6.7,8.3c0,1.5,0,3.2,0,5c0,0.5,0,1.1,0,1.6C37.8,43.1,37.7,45.9,37.7,47.3z M39.4,38.4c0-1.7,0-3.5,0-5
                        c0-6.1-7.6-9.8-7.9-9.9c-0.6-0.3-1-0.7-1-1.4c0-0.4,0.1-1,0.3-1.7c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.3,0.1-0.4
                        c0.7,0.3,1.5,0.4,2.4,0.5c0.1,0,0.1,0,0.2,0c2,0,3.9-0.8,5.8-2.4c0.3,0.3,0.6,0.5,1,0.7c0.1,0,7,3.4,7,8.5l0.1,11.4H39.4z"
											></path>
											<path
												d="M32.6,12.5h2.1c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-1.2V8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v3.7
                        C31.8,12.1,32.2,12.5,32.6,12.5z"
											></path>
											<path
												d="M22.9,18.9c0.1,0,0.1,0,0.2,0l0.3,0l1.8,0c0,0,0,0,0,0c0.3,0,0.5-0.2,0.7-0.4c0.1-0.1,0.1-0.3,0.1-0.4
                        c0-0.4-0.3-0.7-0.7-0.8c-0.1,0-0.1,0-0.2,0l-1.3,0l0-2.8c0-0.5-0.4-0.8-0.8-0.8c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.4,0.1
                        c-0.2,0.1-0.4,0.4-0.4,0.7l0,1.6l0,1.1l0,1c0,0.1,0,0.2,0,0.2C22.3,18.6,22.6,18.8,22.9,18.9z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="two">
								<h3>Expert Programmers</h3>
								<p>
									Our hands-on expertise in Apple programming technologies like Objective-C and
									Swift enable us to produce high-standard code, and our proficiency in Unity 3D/4D
									sets us apart from the rests.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										d="M15.1,41.4l-3.7-3.8c-1-1-2.6-1-3.6,0c0,0,0,0,0,0c-1,1-1,2.6,0,3.7l2.3,2.3c0.3,0.3,0.8,0.4,1.1,0.1
                    c0.3-0.3,0.4-0.8,0.1-1.1c0,0,0,0-0.1-0.1l-2.3-2.3c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0l3.7,3.8
                    c1.1,1.2,1.1,3,0,4.2c-1.1,1.1-2.8,1.1-3.9,0c0,0,0,0,0,0l-8.2-8.5c-0.3-0.3-0.3-0.9,0-1.3l0,0c0.3-0.3,0.8-0.4,1.2-0.1c0,0,0,0,0,0
                    l1.8,1.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.7,0-1.1l-1.8-1.8C3.7,35.3,3,35,2.4,35C1,35.1-0.1,36.2,0,37.6c0,0.6,0.3,1.2,0.7,1.6
                    l8.2,8.5c1.7,1.7,4.5,1.7,6.2,0C16.8,46,16.8,43.2,15.1,41.4z"
									></path>
									<path
										d="M47.6,35c-0.7,0-1.3,0.3-1.8,0.7l-1.8,1.8c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0.1-0.1l1.8-1.8
                    c0.3-0.3,0.9-0.3,1.2,0c0,0,0,0,0,0l0,0c0.3,0.4,0.3,0.9,0,1.3l-8.2,8.5c-1.1,1.1-2.8,1.1-3.9,0.1c0,0,0,0-0.1-0.1
                    c-1.1-1.2-1.1-3,0-4.2l3.7-3.8c0.4-0.4,1-0.4,1.4,0c0,0,0,0,0,0c0.4,0.4,0.4,1.1,0,1.5l-2.3,2.3c-0.3,0.3-0.3,0.8,0,1.1
                    s0.8,0.3,1.1,0c0,0,0,0,0.1-0.1l2.3-2.3c1-1,1-2.6,0-3.7c-1-1-2.6-1-3.6,0c0,0,0,0,0,0l-3.7,3.8c-1.7,1.8-1.7,4.6,0,6.3
                    c1.7,1.7,4.5,1.7,6.2,0l8.2-8.5c1-1,0.9-2.6-0.1-3.5C48.8,35.3,48.2,35,47.6,35L47.6,35z"
									></path>
									<path
										d="M49.2,4.9h-3.6l-1.1-3.3c-0.1-0.4-0.6-0.6-1-0.5c-0.2,0.1-0.4,0.3-0.5,0.5l-1.1,3.3h-3.6c-0.4,0-0.8,0.3-0.8,0.8
                    c0,0.3,0.1,0.5,0.3,0.6l2.9,2.1l-1.1,3.3c-0.1,0.4,0.1,0.9,0.5,1c0.2,0.1,0.5,0,0.7-0.1l2.9-2.1l2.9,2.1c0.4,0.2,0.8,0.2,1.1-0.2
                    c0.1-0.2,0.2-0.5,0.1-0.7l-1.1-3.3l2.9-2.1C50,6,50.1,5.6,49.8,5.2C49.7,5,49.5,4.9,49.2,4.9z M45.4,7.4C45.1,7.6,45,8,45.1,8.3
                    l0.5,1.6l-1.4-1c-0.3-0.2-0.6-0.2-0.9,0l-1.4,1l0.5-1.6c0.1-0.3,0-0.7-0.3-0.9l-1.4-1h1.7c0.3,0,0.6-0.2,0.7-0.5l0.5-1.6l0.6,1.7
                    c0.1,0.3,0.4,0.5,0.7,0.5h1.7L45.4,7.4z"
									></path>
									<polygon
										points="44.7,18.7 44.3,17.4 43.8,18.7 42.4,18.7 43.6,19.5 43.1,20.8 44.3,20 45.4,20.8 45,19.5 46.1,18.7 "
									></polygon>
									<polygon
										points="24.9,5.2 24.5,3.9 24,5.2 22.6,5.2 23.8,6 23.3,7.3 24.5,6.5 25.6,7.3 25.2,6 26.3,5.2 "
									></polygon>
									<polygon
										points="5,24 4.5,22.8 4.1,24 2.7,24 3.8,24.8 3.4,26.1 4.5,25.3 5.7,26.1 5.2,24.8 6.4,24 "
									></polygon>
									<path
										d="M0.3,6.3l2.9,2.1l-1.1,3.3c-0.1,0.4,0.1,0.9,0.5,1c0.2,0.1,0.5,0,0.7-0.1l2.9-2.1l2.9,2.1c0.4,0.2,0.8,0.2,1.1-0.2
                    c0.1-0.2,0.2-0.5,0.1-0.7L9.3,8.4l2.9-2.1c0.4-0.2,0.4-0.7,0.2-1.1C12.2,5,12,4.9,11.7,4.9H8.1L7,1.5C6.8,1.1,6.4,0.9,6,1
                    C5.8,1.1,5.6,1.3,5.5,1.5L4.4,4.9H0.8C0.3,4.9,0,5.2,0,5.7C0,5.9,0.1,6.2,0.3,6.3z M4.9,6.4c0.3,0,0.6-0.2,0.7-0.5l0.6-1.7l0.6,1.7
                    c0.1,0.3,0.4,0.5,0.7,0.5h1.7l-1.4,1C7.6,7.6,7.5,8,7.6,8.3l0.5,1.6l-1.4-1c-0.3-0.2-0.6-0.2-0.9,0l-1.4,1l0.5-1.6
                    C5,8,4.9,7.6,4.6,7.4l-1.4-1H4.9z"
									></path>
									<path
										d="M25.8,42.9v-1.7c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v0.2c-3.4-0.2-6.6-1.5-9.1-3.8l0.1-0.1c0.3-0.3,0.3-0.8,0-1.1
                    c-0.3-0.3-0.8-0.3-1.1,0L14,36.4c-2.4-2.6-3.7-6-3.8-9.5h0.2c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H8.6c0,0.4,0,0.8,0,1.2
                    c0,9.1,7.3,16.4,16.4,16.4C25.3,42.9,25.5,42.9,25.8,42.9z"
									></path>
									<path
										d="M27.3,10.3v1.6c2.8,0.5,5.5,1.7,7.6,3.6l-0.1,0.1c-0.3,0.3-0.3,0.8,0,1.1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0
                    l0.1-0.1c1.8,2,3.1,4.5,3.6,7.2h1.6C40,16.7,34.4,11.3,27.3,10.3z"
									></path>
									<path
										d="M12.5,26.5C12.5,33.4,18.1,39,25,39s12.5-5.6,12.5-12.5S31.9,14,25,14S12.5,19.6,12.5,26.5z M35.9,26.5
                    c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S19,15.6,25,15.6S35.9,20.5,35.9,26.5z"
									></path>
									<path
										d="M24.3,20.1V21c-2,0.2-2.9,1.4-2.9,2.8c0,3.8,5.8,2.7,5.8,5c0,1-0.7,1.4-2.1,1.4c-1,0-1.9-0.5-2.6-1.2
                    c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-0.8,0.4-0.8,0.9c0,0.2,0,0.3,0.1,0.4c0.8,1.1,2,1.8,3.3,1.9v0.9c0,0.4,0.3,0.8,0.7,0.8
                    c0.4,0,0.8-0.3,0.8-0.7c0,0,0-0.1,0-0.1V32c2.1-0.2,3.2-1.3,3.2-3.1c0-3.9-5.8-3-5.8-5c0-0.6,0.5-1.2,1.8-1.2c0.8,0,1.6,0.3,2.2,0.8
                    c0.2,0.2,0.4,0.3,0.7,0.3c0.5,0,0.8-0.4,0.9-0.8c0,0,0,0,0,0c0-0.2-0.1-0.4-0.2-0.5c-0.5-0.8-1.6-1.3-2.8-1.4v-0.9
                    c0-0.4-0.3-0.8-0.7-0.8c-0.4,0-0.8,0.3-0.8,0.7C24.4,20.1,24.4,20.1,24.3,20.1L24.3,20.1z"
									></path>
									<path
										d="M41.4,26.5c0-0.4,0-0.8,0-1.2h-1.7c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h0.2c-0.1,3.5-1.4,6.9-3.8,9.5l-0.1-0.1
                    c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l0.1,0.1c-2.1,1.9-4.8,3.2-7.6,3.6v1.6C35.4,41.6,41.4,34.7,41.4,26.5z"
									></path>
									<path
										d="M8.8,23.8h1.6c0.5-2.7,1.7-5.2,3.6-7.2l0.1,0.1c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l-0.1-0.1
                    c2.5-2.3,5.8-3.6,9.1-3.8v0.2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.7c-0.3,0-0.5,0-0.8,0C17,10.1,10.2,15.9,8.8,23.8z"
									></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="three">
								<h3>Cost-effective Development</h3>
								<p>
									Our Mac game development team knows how to cut time-to-market and overall
									investment on Mac game development projects to make them cost efficient and viable
									in the market.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M22.8,46.8c-10.8-1.5-19-10.8-19-21.7c0-0.4,0.1-0.9,0.1-1.3L5,24.6l-1.4-6.3l-3.5,5.4l1.5-0.3c0,0.6-0.1,1.2-0.1,1.7
                        c0,12.1,9,22.4,20.9,24c0,0,0.1,0,0.2,0c0.6,0,1-0.4,1.1-1C23.8,47.5,23.4,46.9,22.8,46.8z"
											></path>
											<path
												d="M49.9,26.1c0-0.6-0.5-1.1-1.1-1.2c-0.7,0-1.1,0.5-1.2,1.1c-0.4,8.7-5.9,16.3-13.9,19.5l0.2-1.4l-5.1,3.9l6.3,1l-0.9-1.2
                        C43.2,44.3,49.5,35.8,49.9,26.1z"
											></path>
											<path
												d="M5.4,14.1C6,14.4,6.7,14.2,7,13.7C11,7.1,18,3.2,25.7,3.2c8,0,15.4,4.5,19.2,11.5l-1.4,0l4.5,4.6l0.2-6.4l-1.1,1.1
                        C43,6,34.7,0.9,25.7,0.9c-8.5,0-16.2,4.3-20.7,11.6C4.7,13.1,4.9,13.8,5.4,14.1z"
											></path>
											<path
												d="M20.4,24.8c1.8-1.5,2.6-3.2,2.6-5.1c0-1.9-0.6-3.5-1.9-4.6c-1.2-1.1-2.8-1.6-4.7-1.6c-1.1,0-2.3,0.2-3.4,0.7
                        c-1.5,0.7-2.7,1.7-3.6,3.1l1.9,1.9c1.2-1.9,2.9-2.8,5-2.8c2.4,0,3.6,1.1,3.6,3.4c0,1.2-0.9,2.5-2.6,3.8c-2.6,1.9-4.3,3.2-4.9,3.9
                        c-1.8,2-2.6,4.7-2.6,7.8c0,0.5,0,1,0.1,1.5h13v-2.9h-10c0-2.1,0.9-3.9,2.6-5.5C17.1,27.2,18.7,26,20.4,24.8z"
											></path>
											<path
												d="M35,36.8h3v-6.7h4v-2.9h-4V14.1h-3l-9.3,13.1v2.9H35V36.8z M29.1,27.2l5.9-8.3v8.3H29.1z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="four">
								<h3>24*7 Support</h3>
								<p>
									We never refrain from our responsibility to provide the best client-experiences,
									and our support team uses the latest communication technologies to deliver quick
									and accurate responses.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-choose-us-game.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.game-why-us-inner-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 0;
}
.game-why-us-inner-box:hover h3,
.game-why-us-inner-box:hover p {
	color: #fff !important;
}
.game-why-us-inner-box:hover {
	background: #008dd2;
}

.game-why-us-inner-box:hover .game-why-us-inner-icon svg circle,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg ellipse,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg path,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg polygon,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg rect {
	fill: #008dd2;
}

.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}

.game-why-us-inner-icon svg circle,
.game-why-us-inner-icon svg ellipse,
.game-why-us-inner-icon svg path,
.game-why-us-inner-icon svg polygon,
.game-why-us-inner-icon svg rect {
	fill: black;
}

.game-why-us-inner-icon svg {
	width: 50px;
	height: 50px;
	fill: black;
}
.game-why-us-inner-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	min-height: 90px;
	border-radius: 100%;
	background-color: #fff;
	border: 1px solid #e0e2e4;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
