<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Features></Features>

		<Solutions></Solutions>

		<MiniPortfolio></MiniPortfolio>

		<TypeDiv></TypeDiv>

		<Partner></Partner>

		<Expertise></Expertise>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/Banner.vue';
import About from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/About.vue';
import Expertise from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/Expertise.vue';
import Features from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/Features.vue';
import Solutions from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/Solutions.vue';
import MiniPortfolio from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/MiniPortfolio.vue';
import Partner from '../../components/SocialSolutions/MediaEntertainmentAppDevelopment/Partner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		TypeDiv,
		Expertise,
		Partner,
		MiniPortfolio,
		Solutions,
		Const,
		Features
	}
};
</script>

<style></style>