<template>
	<section class="padding-60 bg-white web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>We are Versatile</span>
				<h2>Our Expertise in Other On-demand Solution</h2>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-30">
					<div class="other-demand-app-box text-center box-shadow rounded10">
						<router-link to="/taxi-app-development">
							<div class="other-demand-app-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M50,82.2c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6
                      c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4c0-0.5-0.2-1-0.6-1.4C51,82.4,50.5,82.2,50,82.2z M67.6,69.3v-2.7
                      c0-2.9-2.4-5.3-5.3-5.3H37.7c-2.9,0-5.3,2.4-5.3,5.3v2.7c0,2.9,2.4,5.3,5.3,5.3h24.5C65.2,74.6,67.6,72.3,67.6,69.3z M40.2,70.7
                      h-2.5c-0.8,0-1.4-0.6-1.4-1.4v-2.7c0-0.8,0.6-1.4,1.4-1.4h2.5V70.7z M48.1,70.7h-3.9v-5.5h3.9V70.7z M55.9,70.7H52v-5.5h3.9V70.7z
                      M59.8,65.2h2.5c0.8,0,1.4,0.6,1.4,1.4v2.7c0,0.8-0.6,1.4-1.4,1.4h-2.5V65.2z M49.8,35.1c-0.4-0.4-0.9-0.6-1.4-0.6
                      c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6
                      c0.4-0.4,0.6-0.9,0.6-1.4C50.4,36,50.2,35.5,49.8,35.1z M7.4,68.9c0,4.1,3.3,7.5,7.4,7.5c4.1,0,7.4-3.3,7.4-7.5
                      c0-4.1-3.3-7.5-7.4-7.5C10.8,61.5,7.4,64.8,7.4,68.9z M18.4,68.9c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5
                      c0-1.9,1.6-3.5,3.5-3.5C16.8,65.4,18.4,67,18.4,68.9z M92.6,68.9c0-4.1-3.3-7.5-7.4-7.5c-4.1,0-7.4,3.3-7.4,7.5
                      c0,4.1,3.3,7.5,7.4,7.5C89.3,76.4,92.6,73,92.6,68.9z M81.6,68.9c0-1.9,1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5
                      S81.6,70.9,81.6,68.9z M89.5,86.1h0.8c5.4,0,9.8-4.4,9.8-9.8V64.6c0-3.4-1.7-6.5-4.6-8.3l-1-0.6H98c1.1,0,2-0.9,2-2V46
                      c0-1.1-0.9-2-2-2h-6.2c-0.7,0-1.3,0.4-1.7,1l-2,3.5l-4.6-18.8c-1.2-4.9-5-8.6-9.8-9.7V8.7c0-3.9-3.2-7.1-7.1-7.1H33.3
                      c-3.9,0-7.1,3.2-7.1,7.1V20c-4.8,1.1-8.6,4.8-9.8,9.7l-4.6,18.8l-2-3.5c-0.3-0.6-1-1-1.7-1H2c-1.1,0-2,0.9-2,2v7.7
                      c0,1.1,0.9,2,2,2h3.7l-1,0.6C1.7,58.1,0,61.2,0,64.6v11.6c0,5.4,4.4,9.8,9.8,9.8h0.8V89c0,1.6,0.4,3.1,1.2,4.3H9.7
                      c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h34.9c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H25.7c0.8-1.3,1.2-2.7,1.2-4.3v-2.9h14.5c1.1,0,2-0.9,2-2
                      c0-1.1-0.9-2-2-2H9.8c-3.2,0-5.9-2.6-5.9-5.9V64.6c0-2.1,1-3.9,2.8-5l6.4-3.9h73.9l6.4,3.9c1.7,1.1,2.8,3,2.8,5v11.6
                      c0,3.2-2.6,5.9-5.9,5.9H58.5c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h14.5V89c0,1.6,0.4,3.1,1.2,4.3H61.9c-1.1,0-2,0.9-2,2
                      c0,1.1,0.9,2,2,2h34.9c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-8.5c0.8-1.3,1.2-2.7,1.2-4.3V86.1z M14.5,86.1h8.6V89
                      c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3V86.1z M3.9,51.8V48H7l2.2,3.8H3.9z M30.1,8.7c0-1.8,1.4-3.2,3.2-3.2h33.5
                      c1.8,0,3.2,1.4,3.2,3.2v10.9H30.1V8.7z M36,51.8l9-9c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L30.6,51.6c0,0-0.1,0.1-0.1,0.1H23
                      l4.7-19.3c0.2-0.7,0.8-1.1,1.4-1.1h33.2L50.5,43.3c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l14.7-14.7
                      h2.9c0.7,0,1.3,0.5,1.4,1.1L77,51.8H36z M81,51.8l-4.9-20.2c-0.6-2.4-2.7-4.1-5.2-4.1H29.2c-2.5,0-4.6,1.7-5.2,4.1L19,51.8h-4
                      l5.2-21.1c1-4.2,4.7-7.1,9-7.1h41.6c4.3,0,8,2.9,9,7.1L85,51.8H81z M90.9,51.8L93,48h3.1v3.8H90.9z M85.5,89
                      c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3v-2.9h8.6V89z M64.7,7.4c-1.1,0-2,0.9-2,2v6.2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V9.4
                      C66.7,8.3,65.8,7.4,64.7,7.4z M39.8,7.4h-4.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h0.3v4.2c0,1.1,0.9,2,2,2s2-0.9,2-2v-4.2h0.3
                      c1.1,0,2-0.9,2-2C41.7,8.3,40.9,7.4,39.8,7.4z M60.2,7.9c-0.8-0.7-2.1-0.6-2.8,0.2l-1.1,1.4l-1.1-1.4c-0.7-0.8-1.9-0.9-2.8-0.2
                      c-0.8,0.7-0.9,1.9-0.2,2.8l1.6,1.9l-1.6,1.9c-0.7,0.8-0.6,2.1,0.2,2.8c0.4,0.3,0.8,0.5,1.2,0.5c0.6,0,1.1-0.2,1.5-0.7l1.1-1.4
                      l1.1,1.4c0.4,0.5,0.9,0.7,1.5,0.7c0.4,0,0.9-0.2,1.3-0.5c0.8-0.7,0.9-1.9,0.2-2.8l-1.6-1.9l1.6-1.9C61.2,9.8,61.1,8.5,60.2,7.9z
                      M47.8,8.6c-0.3-0.7-1-1.2-1.8-1.2s-1.5,0.5-1.8,1.2l-2.8,6.4c-0.4,1,0,2.1,1,2.6c1,0.4,2.1,0,2.6-1l1-2.2l1,2.2
                      c0.3,0.7,1,1.2,1.8,1.2c0.3,0,0.5-0.1,0.8-0.2c1-0.4,1.4-1.6,1-2.6L47.8,8.6z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<h5>Taxi</h5>
						</router-link>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-30">
          <div class="other-demand-app-box text-center box-shadow rounded10">
            <router-link to="/food-delivery-app-development">
              <div class="other-demand-app-icon">
                <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <g>
                    <path d="M26.7,0C19.5,0,12.3,0,5.1,0C2.7,0,0.7,1.9,0.7,4.3c0,13.7,0,27.4,0,41.2C0.7,48.7,2,50,5.3,50c2.8,0,19.6,0,22.4,0
                    c0,0,0.1,0,0.1,0v0h1.5c0.4,0,0.8-0.4,0.8-0.8v0c0-0.4-0.4-0.8-0.8-0.8h-1.5v0c-2.9,0-19.7,0-22.6,0c-2.3,0-3-0.6-3-2.9
                    c0-13.6,0-27.2,0-40.8c0-0.3,0-0.5,0-0.8c0.1-2.1,1.6-3,4.5-2c5.7,2,11.9,1.9,17.7,0.1c3.3-1,4.7-0.6,5.1,1.6
                    c0.1,0.6,0.1,1.1,0.1,1.7c0,5.3,0,3.9,0,9.3c0,0.6,0.1,1.6,0.6,1.6c0.8,0,0.8-1,0.8-1.7c0-5.6,0-4.5,0-10.2C30.9,1.3,29.6,0,26.7,0
                    z"></path>
                    <path d="M21.6,13.1c0.5,0.9,0.9,1.8,1.3,2.7c0.3,0.6,0.5,1.2,0.8,1.8c0.7,1.2,1,2.5,0.8,4c0.3,0,0.5,0,0.8,0c1.6,0,3.2,0,4.8,0
                    c0.8,0,0.9,0.1,0.9,0.9c0,0.8,0.2,1.7-0.9,2.3c1.1,0.7,0.9,1.7,0.9,2.6c0,4.8,0,9.7,0,14.5c0,0.8,0.1,1.6-0.9,2.2
                    c1,0.6,0.9,1.5,0.9,2.4c0,0.8-0.1,0.9-0.9,0.9c-3.7,0-7.5,0-11.2,0c-1,0-2.1,0-3.1,0c-0.3,0-0.6-0.2-0.8-0.3c0-0.7,0-1.4,0-2.1
                    c0.2-0.3,0.5-0.5,0.9-0.9c-0.3-0.3-0.6-0.5-0.9-0.7c0-5.9,0-11.8,0-17.7c0.2-0.3,0.5-0.5,0.9-0.9c-0.3-0.2-0.6-0.5-0.9-0.7
                    c0-0.7,0-1.4,0-2.1c0.3-0.1,0.6-0.3,0.9-0.3c1.6,0,3.3,0,4.9,0c0.2,0,0.6-0.2,0.6-0.3c0-0.8,0-1.6-0.3-2.3
                    c-0.6-1.7-1.4-3.3-2.2-4.9c-0.2-0.4-0.2-0.8,0.1-1.1C19.8,13.1,20.7,13.1,21.6,13.1z M16.1,46.2c0.1,0,0.2,0,0.3,0
                    c4.3,0,8.7,0,13,0c0.5,0,0.7-0.4,0.4-0.9c-0.1-0.2-0.3-0.4-0.5-0.5c-0.6-0.6-0.7-0.8,0-1.4c0.5-0.4,0.6-0.9,0.6-1.5
                    c0-5,0-10,0-15.1c0-0.5-0.1-0.8-0.5-1.2c-0.8-0.7-0.8-0.9,0-1.7c0.3-0.3,0.7-0.7,0.4-1.2c-0.1,0-0.2,0-0.2,0c-4.4,0-8.7,0-13.1,0
                    c-0.5,0-0.7,0.4-0.4,0.9c0.1,0.2,0.3,0.4,0.5,0.5c0.6,0.6,0.6,0.8,0,1.4C16.1,26,16,26.5,16,27.1c0,5,0,10,0,14.9
                    c0,0.6,0.1,1,0.6,1.3c0.7,0.6,0.7,0.8,0,1.5C16.2,45.2,15.8,45.6,16.1,46.2z M23.5,21.5c-0.1-0.8,0-1.6-0.3-2.2
                    c-0.6-1.6-1.4-3.1-2.1-4.6c-0.2-0.5-0.5-0.6-1-0.4c0.6,1.3,1.1,2.5,1.7,3.7c0.5,0.9,0.7,1.9,0.6,2.9C22.4,21.6,22.5,21.7,23.5,21.5
                    z"></path>
                    <path d="M25.8,19.4c-0.5-0.9-0.5-1,0.5-1.2c3.5-0.9,7.1-1,10.8-0.8c2.2,0.1,4.4,0.5,6.5,1.3c2.8,1.1,4.9,2.9,5.5,6
                    c0.2,0.8,0.2,1.7,0.2,2.6c0,0.7-0.1,0.9-1,0.9c-3.8,0-7.5,0-11.3,0c-1.5,0-2.9,0-4.4,0c-0.4,0-0.7-0.1-0.7-0.6
                    c0-0.5,0.2-0.6,0.7-0.6c4.8,0,9.6,0,14.3,0c0.3,0,0.7,0,1.1,0c0-1.9-0.3-3.6-1.6-5c-1.3-1.5-3.1-2.3-5-2.7c-3.7-0.9-7.4-1-11.2-0.6
                    C28.9,18.8,27.4,19.1,25.8,19.4z"></path>
                    <path d="M40.5,40.9c2.6,0,5.2,0,7.8,0c0.8,0,0.9,0.1,1,0.9c0,0.8,0,1.5,0,2.3c0,1.8-1.5,3.3-3.3,3.3c-4.4,0-8.8,0-13.3,0
                    c-0.4,0-0.7,0-0.7-0.5c0-0.6,0.3-0.6,0.7-0.6c4.1,0,8.2,0,12.3,0c0.3,0,0.6,0,0.9,0c1.3-0.1,2.1-0.8,2.1-2.1c0.1-2.1,0.1-2.1-2-2.1
                    c-4.5,0-8.9,0-13.4,0c-0.3,0-0.7,0.1-0.7-0.4c0-0.5,0.1-0.7,0.7-0.7C35.3,40.9,37.9,40.9,40.5,40.9C40.5,40.9,40.5,40.9,40.5,40.9z
                    "></path>
                    <path d="M39.9,35.6c-2.4,0-4.8,0-7.2,0c-0.4,0-0.6-0.1-0.6-0.6c0-0.5,0.3-0.6,0.7-0.6c2.2,0,4.5,0,6.7,0c2.5,0,4.9,0,7.4,0
                    c0.4,0,0.9-0.2,1.1-0.4c0.2-0.2,0.2-0.8,0.1-1.1c-0.2-0.3-0.6-0.5-1-0.5c-1.4-0.1-2.7,0-4.1,0c-3.4,0-6.7,0-10.1,0
                    c-0.4,0-0.7,0.1-0.7-0.5c0-0.5,0.2-0.6,0.7-0.6c3.3,0,6.7,0,10,0c1.4,0,2.9,0,4.3,0c1.3,0,2.3,1,2.3,2.1c0,1.2-1,2.2-2.3,2.2
                    C44.6,35.6,42.2,35.6,39.9,35.6C39.9,35.6,39.9,35.6,39.9,35.6z"></path>
                    <path d="M44.8,37.8c-0.2,0.4-0.4,0.8-0.7,1.1c-0.8,1.3-2,1.3-2.7,0c-0.2-0.4-0.4-0.8-0.7-1.3c-0.2,0.3-0.3,0.4-0.5,0.6
                    c-0.2,0.3-0.3,0.6-0.4,0.8c-0.7,1.1-1.8,1.1-2.6,0c-0.3-0.4-0.5-0.9-0.8-1.4c-0.2,0.4-0.4,0.6-0.5,0.8c-0.2,0.3-0.4,0.7-0.7,1
                    c-0.4,0.4-0.9,0.6-1.5,0.4c-0.8-0.3-1.8-2.2-1.6-3.2c1.3,0.1,1.3,1.4,2.1,2.2c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.3-0.6,0.5-0.9
                    c0.7-0.9,1.7-0.9,2.3,0c0.3,0.5,0.6,1,1,1.6c0.3-0.5,0.5-0.8,0.7-1.2c0.3-0.6,0.7-1.1,1.4-1.1c0.8,0,1.2,0.5,1.5,1.1
                    c0.2,0.3,0.4,0.6,0.6,1.1c0.2-0.2,0.3-0.3,0.4-0.5c0.2-0.3,0.3-0.6,0.5-0.8c0.8-1.3,1.9-1.3,2.7,0c0.2,0.4,0.5,0.8,0.7,1.3
                    c0.8-0.7,0.8-2.1,2.1-2.2c0.2,0.5,0.2,1-0.2,1.4c-0.2,0.2-0.3,0.5-0.5,0.8c-0.3,0.6-0.7,1.1-1.4,1.1c-0.7,0-1.1-0.5-1.4-1.1
                    c-0.2-0.4-0.4-0.7-0.6-1.1C45,37.7,44.9,37.8,44.8,37.8z"></path>
                    <path d="M40.6,29.1c2.6,0,5.3,0,7.9,0c0.4,0,0.7,0,0.7,0.6c0,0.6-0.3,0.6-0.7,0.6c-5.3,0-10.6,0-15.9,0c-0.4,0-0.7-0.1-0.7-0.6
                    c0-0.5,0.2-0.6,0.7-0.6C35.3,29.1,38,29.1,40.6,29.1z"></path>
                    <path d="M22.9,28.6c2.6,0,4.8,2.2,4.9,4.8c0,2.7-2.2,4.9-4.8,4.9c-2.6,0-4.9-2.2-4.9-4.8C18.1,30.8,20.2,28.6,22.9,28.6z
                    M22.9,37.1c2.1,0,3.7-1.6,3.7-3.7c0-2.1-1.6-3.7-3.7-3.7c-2.1,0-3.7,1.6-3.7,3.7C19.2,35.5,20.9,37.1,22.9,37.1z"></path>
                    <path d="M22.9,25.4c-1.4,0-2.8,0-4.1,0c-0.4,0-0.7-0.1-0.7-0.6c0-0.5,0.3-0.6,0.7-0.6c2.8,0,5.6,0,8.4,0c0.4,0,0.7,0.1,0.7,0.6
                    c0,0.5-0.2,0.6-0.7,0.6C25.7,25.4,24.3,25.4,22.9,25.4C22.9,25.4,22.9,25.4,22.9,25.4z"></path>
                    <path d="M23,44.7c-1.4,0-2.8,0-4.2,0c-0.4,0-0.7,0-0.7-0.6c0-0.5,0.2-0.6,0.6-0.6c2.8,0,5.7,0,8.5,0c0.4,0,0.6,0.1,0.6,0.6
                    c0,0.4-0.1,0.6-0.6,0.6C25.8,44.7,24.4,44.7,23,44.7z"></path>
                    <path d="M23,40.4c-0.6,0-1.1,0-1.7,0c-0.5,0-0.6-0.2-0.6-0.6c0-0.4,0.1-0.6,0.5-0.6c1.1,0,2.2,0,3.3,0c0.4,0,0.6,0.2,0.6,0.6
                    c0,0.5-0.2,0.6-0.6,0.6C24,40.4,23.5,40.4,23,40.4z"></path>
                    <path d="M7.3,47.4c-0.3,0-0.6,0-1,0c0-0.2,0-0.5,0-0.7c0-1.8,0-19.5,0-21.3c0-0.8-0.2-1.5-0.9-1.9c-1-0.8-1.4-1.8-1.4-3.1
                    c0-1.4,0-7.5,0.1-8.9c0.2-1.8,1.4-2.9,3.1-3.5C7.3,14.3,7.3,41,7.3,47.4z M6.3,9.8c-0.7,0.7-1.1,1.4-1.1,2.2c0,1.5,0,7.5,0,8.9
                    c0,0.8,0.4,1.3,1.1,1.8C6.3,19.9,6.3,12.6,6.3,9.8z"></path>
                    <path d="M8.6,8.2c0.4,0,0.6,0,1,0c0,0.3,0,0.5,0,0.7c0,1.5,0,7.7,0,9.2c0,1.1,0.7,1.8,1.7,1.8c1,0,1.7-0.7,1.7-1.8
                    c0-1.5,0-7.6,0-9.2c0-0.2,0-0.5,0-0.8c0.4,0,0.7,0,1,0C14,8.3,14,8.5,14,8.6c0,1.7,0,7.9,0,9.6c0,1.2-0.6,2.1-1.7,2.6
                    c-0.4,0.2-0.5,0.4-0.5,0.8c0,3,0,21.9,0,24.9c0,0.3,0,0.5,0,0.9c-0.4,0-0.6,0-1,0c0-0.2,0-0.5,0-0.7c0-3.1,0-22,0-25
                    c0-0.5-0.1-0.7-0.6-0.9c-1-0.4-1.6-1.2-1.6-2.2C8.5,16.7,8.6,10.2,8.6,8.2z"></path>
                    <path d="M11.8,18.8c-0.3,0-0.6,0-1,0c0-2,0-8.5,0-10.6c0.3,0,0.6,0,1,0C11.8,10.2,11.8,16.8,11.8,18.8z"></path>
                  </g>
                </svg>
              </div>
              <h5>Food App</h5>
            </router-link>
          </div>
        </div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-30">
					<div class="other-demand-app-box text-center box-shadow rounded10">
						<router-link to="/chat-instant-messaging-app-development">
							<div class="other-demand-app-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g id="chat_1_" class="chatst0">
										<path
											class="chatst1"
											d="M4,2.3c0-0.7,0.5-1.5,1.2-1.7C6.2,0.4,7.1,0.2,8.1,0c0.2,0,0.5,0,0.8,0.1c3.8,1.2,7.6,2.4,11.5,3.5
                    c0.9,0.3,1.4,0.8,1.6,1.7c0.5,2.1,0.9,4.3,1.4,6.4c0.1,0.5-0.1,0.6-0.4,0.6c-0.5,0.2-0.9,0.1-1-0.3c-0.2-0.5-0.2-1.1-0.3-1.6
                    c-0.2-0.9-0.3-1.1-1.2-1.3c-3.3-0.7-6.7-1.4-10-2.1c-0.3-0.1-0.6,0-0.8-0.1C9.1,6.8,9.1,7.1,9.2,7.4c0.3,1.6,0.7,3.1,1.1,4.7
                    c0.8,3.7,1.6,7.4,2.5,11.1c0.6,2.5,1.2,5,1.7,7.6c0.8,3.4,1.5,6.8,2.3,10.2c0.2,1.1,0.2,1.1,1.2,0.6c2.9-1.7,5.8-3.3,8.7-5
                    c0.8-0.5,0.8-0.5,0.6-1.4c-0.2-0.7-0.3-1.4-0.4-2.1c-0.1-0.4,0.1-0.7,0.5-0.7c0.5,0,0.9,0.2,1,0.8c0.6,2.4,1.1,4.8,1.6,7.2
                    c0.1,0.7-0.2,1.3-0.7,1.8c-1,0.8-2.1,1.6-3.1,2.3c-1.7,1.3-3.5,2.6-5.2,3.9c-1.1,0.8-2.3,1.4-3.6,1.5c-0.1,0-0.2,0-0.4,0
                    c-1.4,0.3-2.3-0.2-2.6-1.7c-0.6-2.4-1.1-4.9-1.7-7.3c-0.5-2.2-1-4.4-1.4-6.5c-0.9-4.2-1.9-8.3-2.8-12.5c-1-4.4-2-8.8-3-13.2
                    c-0.4-2-0.9-4-1.3-6C4.1,2.6,4,2.5,4,2.3z M16.9,48c0-0.1,0-0.2-0.1-0.4c-0.8-3.4-1.6-6.9-2.3-10.3c-1.3-5.6-2.5-11.2-3.8-16.8
                    c-1.1-4.8-2.2-9.5-3.3-14.3C7.2,4.9,6.8,3.6,6.6,2.4C6.5,2,6.3,1.8,5.9,1.9C5.6,2,5.3,2.2,5.4,2.7c1.1,4.6,2.1,9.2,3.1,13.9
                    c0.5,2.1,1,4.3,1.5,6.4c0.9,4.1,1.8,8.3,2.7,12.4c0.8,3.8,1.7,7.6,2.6,11.5c0.1,0.5,0.3,1,0.4,1.5c0.1,0.3,0.2,0.4,0.5,0.3
                    C16.6,48.5,16.9,48.5,16.9,48z M28.5,40.7c-0.3-1.1-0.5-2.1-0.7-3.1c-0.1-0.4-0.3-0.5-0.7-0.3c-1.3,0.7-2.5,1.4-3.8,2.2
                    c-1.9,1.1-3.7,2.2-5.6,3.2c-0.4,0.2-0.5,0.4-0.4,0.9c0.3,1.3,0.6,2.5,0.9,3.8c0.2,0.6,0.5,0.7,1,0.3c0.9-0.6,1.7-1.3,2.5-1.9
                    c2.1-1.6,4.2-3.1,6.3-4.7C28.2,41,28.4,40.8,28.5,40.7z M8,2.2c0.2,1,0.5,2.2,0.8,3.4c0,0.2,0.4,0.3,0.6,0.4c1,0.2,2.1,0.4,3.1,0.6
                    c2.7,0.6,5.4,1.1,8,1.7C20.9,8.4,21,8.3,20.9,8c-0.1-0.7-0.4-1.4-0.4-2.1c0-0.6-0.4-0.9-0.9-1c-1.8-0.5-3.6-1.1-5.4-1.6
                    c-1.9-0.6-3.7-1.2-5.6-1.7C8.1,1.4,8,1.5,8,2.2z"
										></path>
										<path
											class="chatst1"
											d="M22.8,41.8c0-0.4,0-0.7,0.1-0.9c0.2-0.3,0.5-0.6,0.8-0.6c0.2-0.1,0.6,0.2,0.7,0.4c0.5,0.6,0.6,1.3,0.2,2
                    c-0.1,0.2-0.4,0.6-0.7,0.6c-0.3,0-0.7-0.2-0.8-0.5C22.9,42.4,22.9,42,22.8,41.8z"
										></path>
										<path
											class="chatst1"
											d="M16.8,5.9c-1-0.3-2-0.6-3-0.9c-0.1,0-0.3-0.1-0.3-0.1c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.1,0.3-0.3,0.5-0.2
                    c1.2,0.3,2.3,0.6,3.5,0.9c0.2,0,0.4,0.4,0.3,0.5C17.5,5.9,17.2,5.9,16.8,5.9z"
										></path>
										<path
											class="chatst1"
											d="M42.4,15.7h-15c-0.3,0-0.6,0.2-0.6,0.5v3.6h-8.1c-0.3,0-0.6,0.2-0.6,0.5v6.1c0,0.3,0.3,0.5,0.6,0.5h4.7
                    l2.3,1.9c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.2,0c0.2-0.1,0.4-0.3,0.4-0.5v-1.5h4.4c0.3,0,0.6-0.2,0.6-0.5v-1.5h2.5v1.5
                    c0,0.2,0.2,0.4,0.4,0.5c0.1,0,0.2,0,0.2,0c0.2,0,0.3-0.1,0.4-0.1l2.3-1.9h4.7c0.3,0,0.6-0.2,0.6-0.5v-8.1
                    C43.1,15.9,42.8,15.7,42.4,15.7z M30.6,25.9h-4.4c-0.3,0-0.6,0.2-0.6,0.5v0.8L24.2,26c-0.1-0.1-0.3-0.1-0.4-0.1h-4.4v-5.1h11.2
                    V25.9z M41.8,23.8h-4.4c-0.2,0-0.3,0.1-0.4,0.1l-1.4,1.2v-0.8c0-0.3-0.3-0.5-0.6-0.5h-3.1v-1h8.7v-1h-8.7v-1h7.5v-1H28.1v-3h13.7
                    V23.8z"
										></path>
										<path class="chatst1" d="M20.2,21.8H29v1h-8.8V21.8z"></path>
										<path class="chatst1" d="M21.2,23.9h5.2v1h-5.2V23.9z"></path>
										<path class="chatst1" d="M29.5,17.8h10.9v1H29.5V17.8z"></path>
										<path
											class="chatst1"
											d="M43.4,13.5H32.1h-0.6h-1h-0.7H18.7c-1.4,0-2.6,1.2-2.6,2.6v12.4c0,1.4,1.2,2.6,2.6,2.6h11.1h0.7h1h0.6h11.3
                    c1.4,0,2.6-1.2,2.6-2.6V16.1C46,14.7,44.9,13.5,43.4,13.5z M45,28.5c0,0.9-0.7,1.5-1.5,1.5H18.7c-0.9,0-1.5-0.7-1.5-1.5V16.1
                    c0-0.9,0.7-1.5,1.5-1.5h24.8c0.9,0,1.5,0.7,1.5,1.5V28.5z"
										></path>
									</g>
									<g id="chat">
										<path
											d="M6,2.3c0-0.7,0.5-1.5,1.2-1.7C8.2,0.4,9.2,0.2,10.2,0c0.2,0,0.5,0,0.8,0.1c3.8,1.2,7.6,2.4,11.5,3.5
                    c0.9,0.3,1.4,0.8,1.6,1.7c0.5,2.1,0.9,4.3,1.4,6.4c0.1,0.5-0.1,0.6-0.4,0.6c-0.5,0.2-0.9,0.1-1-0.3c-0.2-0.5-0.2-1.1-0.3-1.6
                    c-0.2-0.9-0.3-1.1-1.2-1.3c-3.3-0.7-6.7-1.4-10-2.1c-0.3-0.1-0.6,0-0.8-0.1c-0.5-0.2-0.5,0.1-0.4,0.4c0.3,1.6,0.7,3.1,1.1,4.7
                    c0.8,3.7,1.6,7.4,2.5,11.1c0.6,2.5,1.2,5,1.7,7.6c0.8,3.4,1.5,6.8,2.3,10.2C19,42,19,42.1,20,41.5c2.9-1.7,5.8-3.3,8.7-5
                    c0.8-0.5,0.8-0.5,0.6-1.4c-0.2-0.7-0.3-1.4-0.4-2.1c-0.1-0.4,0.1-0.7,0.5-0.7c0.5,0,0.9,0.2,1,0.8c0.6,2.4,1.1,4.8,1.6,7.2
                    c0.1,0.7-0.2,1.3-0.7,1.8c-1,0.8-2.1,1.6-3.1,2.3c-1.7,1.3-3.5,2.6-5.2,3.9c-1.1,0.8-2.3,1.4-3.6,1.5c-0.1,0-0.2,0-0.4,0
                    c-1.4,0.3-2.3-0.2-2.6-1.7c-0.6-2.4-1.1-4.9-1.7-7.3c-0.5-2.2-1-4.4-1.4-6.5c-0.9-4.2-1.9-8.3-2.8-12.5c-1-4.4-2-8.8-3-13.2
                    c-0.4-2-0.9-4-1.3-6C6.1,2.6,6.1,2.5,6,2.3z M19,48c0-0.1,0-0.2-0.1-0.4c-0.8-3.4-1.6-6.9-2.3-10.3c-1.3-5.6-2.5-11.2-3.8-16.8
                    c-1.1-4.8-2.2-9.5-3.3-14.3C9.2,4.9,8.9,3.6,8.6,2.4C8.5,2,8.4,1.8,8,1.9C7.6,2,7.4,2.2,7.5,2.7c1.1,4.6,2.1,9.2,3.1,13.9
                    c0.5,2.1,1,4.3,1.5,6.4c0.9,4.1,1.8,8.3,2.7,12.4c0.8,3.8,1.7,7.6,2.6,11.5c0.1,0.5,0.3,1,0.4,1.5c0.1,0.3,0.2,0.4,0.5,0.3
                    C18.6,48.5,19,48.5,19,48z M30.5,40.7c-0.3-1.1-0.5-2.1-0.7-3.1c-0.1-0.4-0.3-0.5-0.7-0.3c-1.3,0.7-2.5,1.4-3.8,2.2
                    c-1.9,1.1-3.7,2.2-5.6,3.2c-0.4,0.2-0.5,0.4-0.4,0.9c0.3,1.3,0.6,2.5,0.9,3.8c0.2,0.6,0.5,0.7,1,0.3c0.9-0.6,1.7-1.3,2.5-1.9
                    c2.1-1.6,4.2-3.1,6.3-4.7C30.3,41,30.4,40.8,30.5,40.7z M10,2.2c0.2,1,0.5,2.2,0.8,3.4c0,0.2,0.4,0.3,0.6,0.4
                    c1,0.2,2.1,0.4,3.1,0.6c2.7,0.6,5.4,1.1,8,1.7C22.9,8.4,23.1,8.3,23,8c-0.1-0.7-0.4-1.4-0.4-2.1c0-0.6-0.4-0.9-0.9-1
                    c-1.8-0.5-3.6-1.1-5.4-1.6c-1.9-0.6-3.7-1.2-5.6-1.7C10.1,1.4,10,1.5,10,2.2z"
										></path>
										<g>
											<path
												d="M27.6,25.3c-0.6,0-1.1,0-1.7,0c-0.2,0-0.3,0.1-0.4,0.3c-1.1,1.1-2.1,2.3-3.1,3.4c-0.1,0.1-0.2,0.3-0.4,0.3
                      c-0.3,0.2-0.6,0.1-0.7-0.4c0-0.1,0-0.3,0-0.4c0-4.7,0-9.4,0-14.1c0-0.8,0.1-0.9,0.8-0.9c4.9,0,9.8,0,14.7,0c0.7,0,0.8,0.1,0.8,0.9
                      c0,1.2,0,2.4,0,3.6c0,0.2,0,0.3,0,0.6c0.2,0,0.4,0,0.5,0c1.7,0,3.4,0,5.1,0c0.6,0,0.7,0.1,0.7,0.8c0,4.7,0,9.5,0,14.2
                      c0,0.3,0,0.7-0.4,0.8c-0.4,0.2-0.6-0.1-0.8-0.3c-1-1.1-2-2.2-3-3.3c-0.3-0.3-0.5-0.4-0.9-0.4c-3.5,0-7,0-10.5,0
                      c-0.6,0-0.7-0.1-0.7-0.8c0-1.2,0-2.5,0-3.7C27.6,25.7,27.6,25.5,27.6,25.3z M22.3,14.7c0,4.3,0,8.5,0,12.9c1-1,1.9-2,2.8-2.9
                      c0.2-0.2,0.5-0.3,0.7-0.3c3.4,0,6.8,0,10.2,0c0.2,0,0.4,0,0.6,0c0-3.2,0.1-6.4,0.1-9.6C31.9,14.7,27.1,14.7,22.3,14.7z M37.7,19.7
                      c0,0.2,0,0.4,0,0.6c0,1.4,0,2.8,0,4.2c0,0.7-0.1,0.8-0.8,0.8c-2.6,0-5.2,0-7.8,0c-0.2,0-0.3,0-0.5,0c0,1.4,0,2.7,0,4
                      c0.2,0,0.4,0,0.6,0c3.3,0,6.7,0,10,0c0.5,0,0.9,0.2,1.2,0.6c0.7,0.8,1.5,1.6,2.3,2.4c0.1,0.1,0.2,0.1,0.3,0.2c0-4.3,0-8.6,0-12.8
                      C41.2,19.7,39.5,19.7,37.7,19.7z"
											></path>
											<path
												d="M33.5,17.6c0,0.3,0,0.6,0,1c-2.7,0-5.4,0-8.2,0c0-0.3,0-0.6,0-1C28,17.6,30.7,17.6,33.5,17.6z"
											></path>
											<path
												d="M29.4,20.4c0,0.3,0,0.6,0,1c-1.4,0-2.7,0-4.1,0c0-0.3,0-0.6,0-1C26.7,20.4,28,20.4,29.4,20.4z"
											></path>
										</g>
										<path
											d="M18.4,6.1l-2.4-0.5c-0.4-0.1-0.6-0.5-0.5-0.8v0c0.1-0.4,0.5-0.6,0.8-0.5l2.4,0.5c0.4,0.1,0.6,0.5,0.5,0.8v0
                    C19.1,6,18.7,6.2,18.4,6.1z"
										></path>
									</g>
								</svg>
							</div>
							<h5>Chat App</h5>
						</router-link>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-30">
					<div class="other-demand-app-box text-center box-shadow rounded10">
						<router-link to="/social-media-app-development">
							<div class="other-demand-app-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<rect x="21.8" y="45.2" width="6.5" height="1.6"></rect>
										<path
											d="M41.9,25.8h-0.8v-1.6h0.8c4.4,0,8.1-3.6,8.1-8.1s-3.6-8.1-8.1-8.1h-0.8V4c0-2.2-1.8-4-4-4H12.9c-2.2,0-4,1.8-4,4v4H8.1
                    C3.6,8.1,0,11.7,0,16.1s3.6,8.1,8.1,8.1h0.8v1.6H8.1c-4.4,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1h0.8v4c0,2.2,1.8,4,4,4h24.2
                    c2.2,0,4-1.8,4-4v-4h0.8c4.4,0,8.1-3.6,8.1-8.1S46.4,25.8,41.9,25.8z M48.4,16.1c0,3.6-2.9,6.5-6.5,6.5H27.4v-6.5
                    c0-3.6,2.9-6.5,6.5-6.5h8.1C45.5,9.7,48.4,12.6,48.4,16.1z M12.9,1.6h24.2c1.3,0,2.4,1.1,2.4,2.4v0.8h-29V4
                    C10.5,2.7,11.6,1.6,12.9,1.6z M1.6,16.1c0-3.6,2.9-6.5,6.5-6.5h8.1c3.6,0,6.5,2.9,6.5,6.5v6.5H8.1C4.5,22.6,1.6,19.7,1.6,16.1z
                    M1.6,33.9c0-3.6,2.9-6.5,6.5-6.5h14.5v6.5c0,3.6-2.9,6.5-6.5,6.5H8.1C4.5,40.3,1.6,37.4,1.6,33.9z M39.5,46c0,1.3-1.1,2.4-2.4,2.4
                    H12.9c-1.3,0-2.4-1.1-2.4-2.4v-4h5.6c4.4,0,8.1-3.6,8.1-8.1v-7.3c0-0.4-0.4-0.8-0.8-0.8H10.5v-1.6h12.9c0.4,0,0.8-0.4,0.8-0.8v-7.3
                    c0-4.4-3.6-8.1-8.1-8.1h-5.6V6.5h29v1.6h-5.6c-4.4,0-8.1,3.6-8.1,8.1v7.3c0,0.4,0.4,0.8,0.8,0.8h12.9v1.6H26.6
                    c-0.4,0-0.8,0.4-0.8,0.8v7.3c0,4.4,3.6,8.1,8.1,8.1h5.6V46z M41.9,40.3h-8.1c-3.6,0-6.5-2.9-6.5-6.5v-6.5h14.5
                    c3.6,0,6.5,2.9,6.5,6.5S45.5,40.3,41.9,40.3z"
										></path>
										<path
											d="M15.3,30.6H10l-0.4-1.1C9.5,29.3,9.2,29,8.9,29H7.3v1.6h1l1.4,4.3c0.1,0.3,0.4,0.6,0.8,0.6h4c0.4,0,0.7-0.3,0.8-0.6
                    l0.8-3.2c0.1-0.2,0-0.5-0.1-0.7C15.8,30.8,15.6,30.6,15.3,30.6z M13.9,33.9h-2.8l-0.5-1.6h3.8L13.9,33.9z"
										></path>
										<path
											d="M10.1,36.3c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S10.7,36.3,10.1,36.3z M10.1,37.9
                    c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S10.3,37.9,10.1,37.9z"
										></path>
										<path
											d="M14.1,36.3c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S14.8,36.3,14.1,36.3z M14.1,37.9
                    c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4S14.3,37.9,14.1,37.9z"
										></path>
										<path
											d="M36.3,31.5v0.8h-1.6v1.6h1.6v4.8h1.6v-4.8h1.6v-1.6h-1.6v-0.8c0-0.4,0.4-0.8,0.8-0.8h0.8V29h-0.8
                    C37.4,29,36.3,30.1,36.3,31.5z"
										></path>
										<path
											d="M36.3,18.5c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4v-0.8h-1.6v0.8c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8v-4h1.6
                    v-1.6h-1.6v-1.6h-1.6v1.6h-1.6v1.6h1.6V18.5z"
										></path>
										<path
											d="M16.1,11.3H8.1c-0.4,0-0.8,0.4-0.8,0.8v8.1c0,0.4,0.4,0.8,0.8,0.8h8.1c0.4,0,0.8-0.4,0.8-0.8v-8.1
                    C16.9,11.7,16.6,11.3,16.1,11.3z M15.3,19.4H8.9v-6.5h6.5V19.4z"
										></path>
										<path
											d="M12.1,18.5c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S10.8,18.5,12.1,18.5z M12.1,15.3
                    c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S11.7,15.3,12.1,15.3z"
										></path>
									</g>
								</svg>
							</div>
							<h5>Social Media</h5>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
.other-demand-app-box {
	overflow: hidden;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.other-demand-app-box a {
	padding: 45px 15px 30px;
	display: block;
	background-color: #fdfefe;
}
.other-demand-app-icon {
	width: 71px;
	height: 71px;
	margin: 0 auto 40px;
}
.other-demand-app-box h5 {
	color: #202122;
	font-size: 18px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin: 0;
}
.other-demand-app-box a:hover {
	background-color: #0082dd;
}
a:hover {
	color: #0082dd;
}
.other-demand-app-box a:hover h5 {
	color: #fff;
}
.other-demand-app-box a svg * {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
.other-demand-app-box a:hover svg * {
	fill: #fff;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
</style>
