<template>
	<div class="callouts-separate callouts d-flex align-items-center justify-content-center">
		<span class="fxt-type-dev"
			>Do you have a
			<span id="callouts-type">
				<span class="typed fxt-type-dev"></span
				><span class="fxt-type-dev">We have a TEAM to get you there. &nbsp; &nbsp;</span>
			</span>
		</span>
		<router-link class="btn-custome btn waves-effect waves-light appointement-btn fxt-type-btn" to="/contact"
			>Get In Touch</router-link
		>
	</div>
</template>

<script>
import Typed from 'typed.js';

export default {
	mounted() {
		new Typed('.typed', {
			strings: ['MISSION ?', 'VISION?'],
			typeSpeed: 100,
			loop: true,
			backSpeed: 100
		});
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-type-dev {
		font-size:20px !important;
		font-weight:bold !important;
	}
	.fxt-type-btn span a{
		padding:5px 10px !important;
		font-size:16px !important;
	}
}

</style>
