<template>
	<div class="mvp-services-offer-box">
		<div class="mvp-services-offer-image text-center">
			<img class="lazy" alt="" :src="`${obj.imageLink}`" />
		</div>
		<div class="mvp-services-offer-content-box mhbox rounded10 text-center fxt-box-shadow bg-white">
			<h3>{{ obj.title }}</h3>
			<p>
				{{ obj.text }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.mvp-services-offer-box:hover .mvp-services-offer-image {
	top: -10px;
}
.mvp-services-offer-box:hover .mvp-services-offer-content-box h3 {
	color: #008dd2 !important;
}
.mvp-services-offer-content-box h3 {
	font-size: 18px;
	font-weight: bold;
}
.mvp-services-offer-content-box p {
	font-size: 16px;
}
.mvp-services-offer-image {
	margin-bottom: -70px;
	padding: 0 25px;
	top: 0;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.fxt-box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.mvp-services-offer-content-box {
	padding: 100px 15px 30px;
	min-height: 330px;
}
</style>
