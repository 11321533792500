<template>
	<section class="padding-60 bg-light game-service-offer-main">
		<div class="container">
			<div class="title">
				<span>What We Offers</span>
				<h2 class="fxt-off-dev">Mobile App Development Services We Offer</h2>
				<p>
					Our mobile app development span across all mobile platforms offering functional solutions
					as we develop scalable and robust mobile applications for our clients.
				</p>
			</div>
			<div
				class="row justify-content-between m-height-450 mob-cinsaff-fa"
				style="gap: 15px; flex-wrap: nowrap"
			>
				<LongCard
					v-for="card in cards"
					v-bind:obj="card"
					class="col-12 col-sm-6 col-md-6 col-lg-4"
					v-bind:key="card.title"
				>
				</LongCard>
			</div>
			<div
				class="row justify-content-between m-height-450 mob-cinsaff-fa"
				style="gap: 15px; flex-wrap: nowrap"
			>
				<LongCard
					v-for="card in cards1"
					v-bind:obj="card"
					class="col-12 col-sm-6 col-md-6 col-lg-4"
					v-bind:key="card.title"
				>
				</LongCard>
			</div>
		</div>
	</section>
</template>

<script>
import LongCard from '../AssetComponents/LongCard';

export default {
	data: () => {
		return {
			cards: [
				{
					icon: `M55.6,8.9c-0.9-0.9-2-1.9-3.4-2.8l2-4.3c0.3-0.7,0-1.5-0.7-1.7c-0.7-0.3-1.4,0-1.7,0.7l-1.9,4
                              c-2.6-1.3-5.8-2.2-9.8-2.2c-4,0-7.4,0.8-10.2,2.2l-1.9-4c-0.3-0.7-1.1-0.9-1.7-0.7c-0.7,0.3-0.9,1.1-0.7,1.7l2,4.2
                              C22,9.7,19.3,15.6,19,19.8l-0.1,1.5h42.3L61,19.8C60.8,17,59.2,12.6,55.6,8.9z M21.9,18.6C23,12.9,28.6,5.3,40.3,5.3
                              c3.7,0,6.8,0.9,9.3,2.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.1c4.7,2.9,7.2,7.4,8,10.7H21.9z M9.8,24
                              c-3.7,0-6.6,2.9-6.6,6.7v18.7c0,3.7,2.9,6.7,6.6,6.7c3.7,0,6.6-2.9,6.6-6.7V30.6C16.3,26.9,13.5,24,9.8,24z M13.7,49.3
                              c0,2.3-1.7,4-3.9,4c-2.2,0-3.9-1.7-3.9-4V30.6c0-2.3,1.7-4,3.9-4c2.2,0,3.9,1.7,3.9,4V49.3z M70.2,24c-3.7,0-6.6,2.9-6.6,6.7v18.7
                              c0,3.7,2.9,6.7,6.6,6.7c3.7,0,6.6-2.9,6.6-6.7V30.6C76.8,26.9,73.9,24,70.2,24z M74.2,49.3c0,2.3-1.7,4-3.9,4
                              c-2.2,0-3.9-1.7-3.9-4V30.6c0-2.3,1.7-4,3.9-4c2.2,0,3.9,1.7,3.9,4V49.3z M19,62.7h5.3v12c0,2.9,2.4,5.3,5.3,5.3
                              c2.9,0,5.3-2.4,5.3-5.3v-12h10.5v12c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3v-12H61V24H19V62.7z M21.6,26.6h36.8V60h-5.3
                              v14.7c0,1.5-1.2,2.7-2.6,2.7s-2.6-1.2-2.6-2.7V60H32.1v14.7c0,1.5-1.2,2.7-2.6,2.7c-1.4,0-2.6-1.2-2.6-2.7V60h-5.3V26.6z
                              M33.4,10.6c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7C36.1,11.8,34.9,10.6,33.4,10.6z M46.6,10.6
                              c-1.4,0-2.6,1.2-2.6,2.7c0,1.5,1.2,2.7,2.6,2.7c1.4,0,2.6-1.2,2.6-2.7C49.2,11.8,48,10.6,46.6,10.6z`,
					title: 'Android App Development',
					text: 'The number of android users is increasing day by day and so is the need for Android apps. Our team of highly passionate professionals are committed to deliver the finest quality of Android apps for smartphones and tablet. We channel your ideas into a digitized custom Android app that your end users will enjoy using. Get your app developed today to help take your business to the next level or bring your ideas to life.',
					link: '/mobile-development/android-development'
				},
				{
					icon: `M39.8,20.8c0.3,0,0.6,0,0.9,0c4.3,0,9-2.3,12.2-6c3.3-3.9,5-8.9,4.5-13.4C57.2,0.6,56.5,0,55.7,0
                              C51.3,0.2,46,2.8,42.9,6.4c-2.7,3-5.3,8-4.6,13.1C38.4,20.2,39,20.7,39.8,20.8z M45.3,8.3c2.2-2.5,5.8-4.5,9-5.1
                              c-0.1,3.3-1.4,6.8-3.9,9.6c-2.4,2.8-5.9,4.7-9.1,4.9C41.4,14.1,43.4,10.5,45.3,8.3z M72.9,56.6c-1-0.4-9.6-4.1-9.7-14.2
                              c-0.1-8,5.9-12.2,7.9-13.4c0.4-0.2,0.6-0.6,0.7-1c0.1-0.4,0-0.8-0.2-1.1c-5-7.1-12.7-8.2-15.6-8.3c-4.1-0.4-8.1,1.1-11.2,2.3
                              c-1.7,0.6-3.3,1.2-4.2,1.2c-1.1,0-2.6-0.6-4.3-1.3c-2.5-1-5.4-2.1-8.6-2.1l-0.2,0c-7.2,0.1-14,4.1-17.7,10.4
                              c-7,11.8-2.6,29.6,5.1,40.4C17.9,74,22.2,80,28.3,80l0.3,0c2.6-0.1,4.5-0.9,6.3-1.6c1.9-0.8,3.7-1.5,6.7-1.5
                              c2.8,0,4.5,0.7,6.3,1.5c1.8,0.8,3.7,1.6,6.5,1.6l0.3,0c6.3-0.1,10.3-5.7,13.4-10.1c3.4-4.8,5.1-9.5,5.7-11.3
                              C74,57.7,73.6,56.9,72.9,56.6z M65.5,68.1C62,73,59,76.8,54.6,76.9l-0.2,0c-2.1,0-3.6-0.6-5.3-1.3c-1.9-0.8-4.1-1.7-7.6-1.7
                              c-3.6,0-5.9,1-7.9,1.8c-1.7,0.7-3.1,1.3-5.1,1.4l-0.2,0c-4.1,0-7.5-4.3-11-9.1c-9.6-13.4-9.7-29.2-5-37.2c3.2-5.4,9-8.8,15.1-8.9
                              l0.2,0c2.5,0,5.1,1,7.4,1.9c2.1,0.8,3.8,1.5,5.5,1.5c1.5,0,3.3-0.7,5.3-1.5c2.6-1,5.6-2.1,8.6-2.1c0.4,0,0.7,0,1.2,0.1
                              c2.4,0.1,8.2,0.9,12.3,5.8c-2.9,2.1-8,7-7.9,15c0.1,10,7.2,14.8,10.3,16.3C69.6,60.9,68.1,64.4,65.5,68.1z`,
					title: 'Iphone App Development',
					text: 'We develop iPhone apps that cater to the needs of the end users, as well as meeting your business requirements. The sleek, lean ios offer a unique platform to your users. Our iOS app developers use the latest tools and technology to create superior quality and user-friendly iPhone applications. Get in touch today with GenixBit to create the best iPhone app development experience for your users.',
					link: '/mobile-development/iphone-development'
				},
				{
					icon: `M27.5,61.2h5v-2.5h-30c-1.1,0-2.5-1.5-2.5-2.5V15.8c0-1.2,0.9-2,2-2h63c1.1,0,2.5,1.4,2.5,2.5v7.5h10
                              c1.1,0,2.5,2,2.5,3.1v37.4c-0.2,0.9-1.1,1.8-2.1,1.8H58.5h-5.8h-7.8c-1.1,0-2.3-1.4-2.3-2.5v-5H35v2.5h5v2.5H27.5
                              C27.5,63.7,27.2,61.2,27.5,61.2z M45,63.7h12.5V38.8H45V63.7z M65,16.3H2.5v39.9h40V38.8c0-1.1,1.4-2.5,2.5-2.5h5v-10
                              c0-1.1,1.4-2.5,2.5-2.5H65V16.3L65,16.3z M77.5,26.3h-25v10h5c1.1,0,2.5,1.4,2.5,2.5v24.9h17.5V26.3L77.5,26.3z M65.7,58.7h1.2
                              c0.4,0,0.6,0.2,0.6,0.6v1.2c0,0.4-0.2,0.6-0.6,0.6h-1.2c-0.4,0-0.6-0.2-0.6-0.6v-1C65.1,59.1,65.3,58.7,65.7,58.7z M51.9,61.1h-1.3
                              c-0.4,0-0.6-0.2-0.6-0.6v-1.2c0-0.4,0.2-0.6,0.6-0.6h1.3c0.4,0,0.6,0.2,0.6,0.6v1.2C52.7,60.9,52.3,61.1,51.9,61.1z`,
					title: 'Cross Platform Development',
					text: 'We create mobile applications that run on any device and operating system as and when your business needs. Cross platform apps helps you yield better results by enabling you to reach a wider audience. We provide hybrid app frameworks, which combine native apps and web based applications that ensures platform specific functionality, giving you the look and feel of a native mobile app.',
					link: '/mobile-development/cross-platform-development'
				}
			],
			cards1: [
				{
					icon: `M44.5,0L6.3,41.3l13.5,16L73.8,0H44.5z M10.8,41.3l36-39H67L19.8,52.5L10.8,41.3z M44.5,36.5L22,59.3l13.5,11.5
                              L46.7,80h27c0,0-14-15.5-22-23.8c10.5-9.3,22-19.8,22-19.8H44.5z M26.5,59.5c0,0,6-6.3,11.3-11.5c1,1,6.8,6.8,9,9.3
                              c-5.7,5-11.3,9.3-11.3,9.3L26.5,59.5z M67,77.8H49c0,0-7.8-6.5-11-9.3c2.3-2,6.8-5.3,11-9.3C53,63.8,67,77.8,67,77.8z M48.8,54.8
                              c-3.3-3.3-7-6.8-9-8.8c3.8-4,7-7,7-7H67C67,38.8,57.3,47.3,48.8,54.8z`,
					title: 'Flutter App Development',
					text: 'At Genixbit, we adhere to innovation and adapting to the latest changes in the mobile app development technology. Google flutter SDK is the new addition to the app development framework. It gives compatibility and flexibility to developers in building native-like mobile applications for both Android and iOS devices with excellent user experience and material design.',
					link: '/mobile-development/flutter-development'
				},
				{
					icon: `M20.3,44.3c0.3-0.5,0.8-0.6,1.2-0.3l0,0l8.6,4.9c0.5,0.3,0.6,0.8,0.3,1.2c-0.3,0.5-0.8,0.6-1.2,0.3l-8.6-4.9
                              C20.3,45.2,20.2,44.8,20.3,44.3L20.3,44.3z M11.4,44.5c-0.3,0.5-0.8,0.6-1.2,0.3l-8.8-5.1c-0.8-0.5-1.2-1.2-1.4-2.1l0,0l0,0v-5.2
                              l0,0l0,0c0-0.9,0.6-1.7,1.4-2.1l24-13.8c1.8-0.9,3.8-0.9,5.7,0l0.9,0.5l7.7-4.4c0.3-0.2,0.6-0.2,0.9,0l14.8,8.4
                              c0.3,0.2,0.5,0.5,0.5,0.8v8.7l3.2,1.8c0.5,0.3,0.6,0.8,0.3,1.2c-0.3,0.5-0.8,0.6-1.2,0.3l0,0l-2.3-1.4v4.8L56,37
                              c0.5-0.3,0.9-0.2,1.2,0.3c0.3,0.5,0.2,0.9-0.3,1.2l-0.2,0.2l0.2,0.2c0.5,0.3,0.6,0.8,0.3,1.2c-0.3,0.5-0.8,0.6-1.2,0.3l-1.1-0.6
                              l-13.1,7.5l0.2,0.2c0.5,0.3,0.6,0.8,0.3,1.2c-0.3,0.5-0.8,0.6-1.2,0.3L40,48.3l-1.1,0.6c-0.5,0.3-0.9,0.2-1.2-0.3
                              c-0.3-0.5-0.2-0.9,0.3-1.2l0.2-0.2l-13.1-7.5L24,40.3c-0.5,0.3-0.9,0.2-1.2-0.3c-0.3-0.5-0.2-0.9,0.3-1.2l0.2-0.2l-0.2-0.2
                              c-0.5-0.3-0.6-0.8-0.3-1.2c0.3-0.5,0.8-0.6,1.2-0.3l0.2,0.2V21.8c0-0.3,0.2-0.6,0.5-0.8l5.4-3.1c-1.2-0.6-2.8-0.6-4,0l-3.4,1.8v1.5
                              c0,0,0,0,0,0.2c-0.2,0.9-0.6,1.7-1.5,2.1l-8.8,5.1c-0.9,0.5-2,0.8-3.1,0.8H5.8L2,31.7c-0.3,0.2-0.6,0.5-0.6,0.6
                              c0,0.2,0.2,0.5,0.6,0.6l12.6,7.2c0.5,0.3,0.6,0.8,0.3,1.2c-0.3,0.5-0.8,0.6-1.2,0.3L1.4,34.7v2.9c0,0.2,0.2,0.5,0.6,0.6l8.8,5.1
                              C11.5,43.5,11.7,44,11.4,44.5L11.4,44.5z M26,38.3l13.1,7.5v-15L26,23.3V38.3z M53.8,38.3v-15l-13.1,7.5v15L53.8,38.3z M26.9,21.8
                              L40,29.3l13.1-7.5L40,14.3L26.9,21.8z M21.2,20.9l-12,6.7h0.5c0.8,0,1.5-0.2,2.2-0.5l8.8-5.1c0.3-0.2,0.6-0.5,0.6-0.8V20.9z
                              M25.7,51.5l-8.6-4.9c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l8.6,4.9c0.5,0.3,0.9,0.2,1.2-0.3
                              C26.2,52.4,26,51.8,25.7,51.5L25.7,51.5z M64.2,45.2l1.5,0.9c0.5,0.3,0.9,0.2,1.2-0.3c0.3-0.5,0.2-0.9-0.3-1.2l0,0l-1.5-0.9
                              c-0.5-0.3-0.9-0.2-1.2,0.3C63.5,44.5,63.7,44.9,64.2,45.2z M61.8,41.9L60,40.8c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2
                              l0,0l1.8,1.1c0.5,0.3,0.9,0.2,1.2-0.3C62.3,42.6,62.2,42,61.8,41.9L61.8,41.9z M29.7,52.1l-1.5,0.9c-0.5,0.3-0.6,0.8-0.3,1.2
                              c0.3,0.5,0.8,0.6,1.2,0.3l1.5-0.9c0.5-0.3,0.6-0.8,0.3-1.2C30.8,52.1,30.2,52,29.7,52.1L29.7,52.1z M34.8,49.2l-1.8,1.1
                              c-0.5,0.3-0.6,0.8-0.3,1.2c0.3,0.5,0.8,0.6,1.2,0.3l1.8-1.1c0.5-0.3,0.6-0.8,0.3-1.2C35.7,49.2,35.2,49.1,34.8,49.2z M58.8,36.5
                              c0.3,0.5,0.8,0.6,1.2,0.3l1.8-1.1c0.5-0.3,0.6-0.8,0.3-1.2c-0.3-0.5-0.8-0.6-1.2-0.3l-1.8,1.1C58.6,35.6,58.5,36,58.8,36.5
                              L58.8,36.5L58.8,36.5z M64.6,34c0.2,0,0.3,0,0.5-0.2l1.5-0.9c0.5-0.3,0.6-0.8,0.3-1.2c-0.3-0.5-0.8-0.6-1.2-0.3l-1.5,0.9
                              c-0.5,0.3-0.6,0.8-0.3,1.2C64,33.9,64.3,34,64.6,34L64.6,34z M16.2,44c-0.3-0.5-0.8-0.6-1.2-0.3l0,0l-1.5,0.9
                              c-0.5,0.3-0.6,0.8-0.3,1.2c0.3,0.3,0.8,0.5,1.2,0.2l1.5-0.9C16.3,44.9,16.5,44.5,16.2,44L16.2,44z M17.8,42.9
                              c0.3,0.5,0.8,0.6,1.2,0.3l1.8-1.1c0.5-0.3,0.6-0.8,0.3-1.2c-0.3-0.5-0.8-0.6-1.2-0.3l0,0l-1.8,1.1C17.8,42,17.7,42.6,17.8,42.9
                              L17.8,42.9z M50.2,52.1c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l0,0l1.5,0.9c0.5,0.3,0.9,0.2,1.2-0.3
                              c0.3-0.5,0.2-0.9-0.3-1.2L50.2,52.1z M45.2,49.2c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l0,0l1.8,1.1
                              c0.5,0.3,0.9,0.2,1.2-0.3c0.3-0.5,0.2-0.9-0.3-1.2L45.2,49.2z M18.2,35.7l1.8,1.1c0.5,0.3,0.9,0.2,1.2-0.3c0.3-0.5,0.2-0.9-0.3-1.2
                              l0,0l-1.8-1.1c-0.5-0.3-0.9-0.2-1.2,0.3C17.7,35,17.8,35.6,18.2,35.7L18.2,35.7z M14.3,31.6c-0.5-0.3-0.9-0.2-1.2,0.3
                              c-0.3,0.5-0.2,0.9,0.3,1.2l1.5,0.9c0.5,0.3,0.9,0.2,1.2-0.3c0.3-0.5,0.2-0.9-0.3-1.2l0,0L14.3,31.6z M80,45.8c0-0.9-0.6-1.7-1.4-2.1
                              l-12.9-7.5c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l12.9,7.5c0.3,0.2,0.5,0.5,0.5,0.6c0,0.2-0.2,0.5-0.6,0.6l-24,13.8
                              c-1.2,0.6-2.8,0.6-4,0l-13.2-7.5c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l13.2,7.5c1.8,0.9,3.8,0.9,5.7,0L78,48.1v2.9
                              c0,0.2-0.2,0.5-0.6,0.6l-24,13.8c-1.2,0.6-2.8,0.6-4,0l-17.1-10c-0.5-0.3-0.9-0.2-1.2,0.3c-0.3,0.5-0.2,0.9,0.3,1.2l17.4,10
                              c1.8,0.9,3.8,0.9,5.7,0l24-13.8c0.8-0.5,1.4-1.2,1.4-2.1l0,0l0,0v-5.2H80z`,
					title: 'AR Development',
					text: 'Augmented Reality has passed its infantile phase, but our maturity in this awesome technology enables us to be first in your niche market with apparent results.',
					link: '/mobile-development/ar-development'
				},
				{
					icon: `M54.7,27c-3.3,0-5.8,2.5-5.8,5.7c0,3.2,2.5,5.7,5.8,5.7c3.3,0,5.8-2.5,5.8-5.7C60.4,29.5,57.9,27,54.7,27z
                              M54.7,36c-1.8,0-3-1.5-3-3c0-1.7,1.5-3,3-3c1.5,0,3,1.5,3,3C57.9,34.5,56.4,36,54.7,36z M78.5,25.8h-3c-1-3.2-4.3-12.5-14.5-18.2
                              c-1.8-1-3.8-1.7-5.8-2.5c-2-0.7-4.3-1.2-6.5-1.5c-17.8-2.5-27.6,5.7-30.8,9.2c-1.3-0.2-2.5-0.5-4-0.5C6.3,12.3,0,18.5,0,26v14
                              c0,7,5.3,12.7,12.3,13.5L10.5,57v0.2c0,0.2,0,0.2,0,0.5s0,0.2,0.3,0.5l5,7.2c-0.3,1.7-0.5,6.5,2,9.5c1.3,1.5,2.8,2,4.8,2
                              c8.5,0,22.8-3.5,22.8-17.2c0-0.7-0.5-1.2-1.3-1.2c-0.8,0-1.3,0.5-1.3,1.2c0,14.2-19.3,14.5-20.1,14.5c-1.3,0-2-0.5-2.8-1.2
                              c-1.8-2-1.5-6.2-1.5-7.7c0-0.2,0-0.7-0.3-1l-3.8-5.5h3.3c0.8,0,1.3-0.5,1.3-1.2s-0.5-1.2-1.3-1.2H14l1.3-2.5
                              c5.5-0.5,10-4.2,11.8-9.5c0.8,0.2,1.5,0.2,2,0.2c3,0,5.8-1.7,7-4.2h9.3c2.3,2.7,5.5,4.5,9.3,4.5c3.5,0,7-1.7,9.3-4.5h9.3
                              c-0.8,3.7-4.5,8.7-6,10.2C67,50.7,67,51,67,51.5v22.2c0,0.7,0.5,1.2,1.3,1.2c0.8,0,1.3-0.5,1.3-1.2V52c1.3-1.5,5.8-7,6.5-11.7h2.8
                              c0.8,0,1.3-0.5,1.3-1.2V27.3C80,26.3,79.2,25.8,78.5,25.8z M5.3,46.5c-1.5-2-2.3-4.2-2.3-6.7v-14C3,23.3,4,21,5.3,19V46.5z
                              M24.8,42.2c-1.3,5-5.8,8.7-10.8,8.7c-2.3,0-4.3-0.7-6-1.7V16.8c1.8-1.2,3.8-1.7,6-1.7c5.3,0,9.8,3.7,10.8,8.7V42.2z M34.4,36.5
                              c0,2.7-2.3,5.2-5.3,5.2c-0.5,0-1-0.2-1.8-0.2V24.3c0.5-0.2,1-0.2,1.8-0.2c2.8,0,5.3,2.2,5.3,5.2V36.5z M29.1,21.3
                              c-0.8,0-1.5,0-2,0.2c-1-3.2-3.3-6-6.3-7.5c3.5-3.5,12.3-10,27.8-7.7c2.3,0.2,4.3,0.7,6,1.5c1.8,0.5,3.5,1.5,5.3,2.2
                              c8.5,4.7,12,12.5,13,15.7h-9c-2.3-2.7-5.5-4.5-9.3-4.5c-3.5,0-7,1.7-9.3,4.5h-9.3C34.9,23,32.4,21.3,29.1,21.3z M77.5,37.2H63.2
                              c-0.5,0-0.8,0.2-1.3,0.5c-1.8,2.5-4.5,3.7-7.5,3.7c-3,0-5.8-1.5-7.5-3.7c-0.3-0.2-0.8-0.5-1.3-0.5h-9.3c0-0.2,0-0.5,0-0.7V29
                              c0-0.2,0-0.5,0-0.7h9.3c0.5,0,0.8-0.2,1.3-0.5c1.8-2.5,4.5-3.7,7.5-3.7c3,0,5.8,1.5,7.5,3.7c0.3,0.2,0.8,0.5,1.3,0.5h14.3V37.2z`,
					title: 'VR Development',
					text: 'Virtual Reality is becoming a big industry driver in itself by providing innovative ways to perceive and do business, and our share is noteworthy in its ecosystem.Virtual Reality is becoming a big industry driver in itself by providing innovative ways to perceive and do business, and our share is noteworthy in its ecosystem.',
					link: '/mobile-development/vr-development'
				}
			]
		};
	},
	components: {
		LongCard
	}
};
</script>

<style>
@media (max-width: 991px) {
	.mob-cinsaff-fa {
        flex-wrap: wrap !important;
    }
}
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>