<template>
<section class="padding-60 bg-light p-relative">


`  <div class="container mt-5">

          <div class="title">
             <span>Delivering Perfection Since Decades</span>
             <h2 class="fxt-process-dev">What Makes Genixbit Best Flutter Development Company</h2>
             <p>The flutter developers at Genixbit are quick to learn and adapt with all progress happening in the app development technologies. Following are the traits that make us the best flutter app development company.</p>
          </div>
          <div class="row">
             <div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
                 <MiniCard v-bind:obj="item" ></MiniCard>
             </div>
          </div>
    </div>`
</section>
</template>

<script>
import MiniCard from '../AssetComponents/MiniCard'

export default {
    components:{
        MiniCard
    },
    data : ()=>{
        return {
            items : [
                {
                  title:"A dedicated team Of experts",
                  text:"Being top flutter development company, at Genixbit we assign a dedicated team of experts to every client and project. This helps us build better solutions for them.",
                  svg:`
                    <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M50,33.4c-9.2,0-16.6,7.4-16.6,16.6c0,9.2,7.4,16.6,16.6,16.6c9.2,0,16.6-7.4,16.6-16.6C66.5,40.8,59.1,33.4,50,33.4z
                           M43.4,61.5c0.1-2.7,2.3-4.9,5-4.9h3.4c2.7,0,4.9,2.2,5,4.9C52.5,63.9,47.4,63.9,43.4,61.5z M45,48.4c0-2.7,2.3-5,5-5s5,2.3,5,5
                           c0,2.7-2.3,5-5,5S45,51.1,45,48.4z M59.5,59.2c-0.7-2-2-3.8-3.9-4.9C59,51.2,59.1,46,56,42.6c-3.1-3.4-8.4-3.5-11.7-0.4
                           c-3.4,3.1-3.5,8.4-0.4,11.8c0.1,0.1,0.3,0.3,0.4,0.4c-1.9,1.1-3.2,2.8-3.9,4.9c-5.1-5.3-5-13.8,0.3-18.8c5.3-5,13.8-5,18.7,0.3
                           C64.5,46,64.5,54.1,59.5,59.2z M67.8,38c7-3,12.4-8.8,14.8-16.1c5.7,3.1,12.7,0.9,15.8-4.7c3.1-5.7,0.9-12.7-4.7-15.8
                           C92,0.6,90.1,0,88.3,0c-6.5,0-11.6,5.1-11.6,11.6c0,3.1,1.2,5.9,3.4,8.1c-2,6.8-6.9,12.3-13.5,15.1l-0.7,0.3l1.3,3.1L67.8,38z
                           M84.9,19.4c0.4-1.8,2.2-3,3.9-2.6c1.3,0.3,2.3,1.4,2.6,2.6C89.3,20.3,87,20.3,84.9,19.4z M86.5,11.6c0-0.9,0.8-1.6,1.6-1.6
                           c0.9,0,1.6,0.8,1.6,1.6c0,0.8-0.7,1.8-1.5,1.8C87.3,13.4,86.5,12.6,86.5,11.6z M82.6,5.6c3.4-3.1,8.6-3,11.7,0.4
                           c3,3.2,3,8.1,0,11.3c-0.4-1.1-1.2-1.9-2.2-2.7c0.7-0.8,1.1-1.9,1.1-3c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,1.1,0.4,2.2,1.1,3
                           c-0.9,0.7-1.6,1.6-2.2,2.7C79.1,13.9,79.2,8.7,82.6,5.6z M96.5,79.9c-2.3-2-5.3-3.2-8.2-3.2c-1.9,0-3.9,0.5-5.5,1.4
                           c-2.4-7.2-8-13.1-14.8-16.1l-0.5-0.3L66,64.7l0.5,0.3C73,67.8,78,73.4,80,80.1c-4.4,4.6-4.3,12,0.3,16.5c4.6,4.5,12,4.3,16.5-0.3
                           C101.2,91.8,101.1,84.3,96.5,79.9z M84.9,95.9c0.4-1.8,2.2-3,3.9-2.6c1.3,0.3,2.3,1.2,2.6,2.6C89.3,96.9,87,96.9,84.9,95.9z
                           M86.5,88.4c0-0.9,0.8-1.6,1.6-1.6c0.9,0,1.6,0.8,1.6,1.6c0,0.9-0.8,1.6-1.6,1.6C87.3,90,86.5,89.2,86.5,88.4z M94.3,93.9
                           c-0.4-1.1-1.2-2-2.2-2.7c0.7-0.8,1.1-1.9,1.1-3c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,1.1,0.4,2.2,1.1,3c-0.9,0.7-1.6,1.6-2.2,2.7
                           c-3.1-3.4-3-8.6,0.4-11.8c3.4-3.1,8.6-3,11.7,0.4C97.3,85.8,97.3,90.8,94.3,93.9z M11.7,23.4c1.9,0,3.9-0.5,5.5-1.4
                           c2.4,7.2,8,13.1,14.8,16.1l0.5,0.3l1.3-3.1L33.4,35c-6.5-2.8-11.5-8.4-13.5-15.1c4.4-4.6,4.3-12-0.3-16.5C15-1,7.9-1,3.3,3.5
                           C-1.3,8.1-1,15.6,3.6,20C5.7,22.2,8.7,23.4,11.7,23.4z M8.4,19.4c0.4-1.8,2.2-3,3.9-2.6c1.3,0.3,2.3,1.4,2.6,2.6
                           C12.9,20.3,10.6,20.3,8.4,19.4z M10,11.6c0-0.8,0.8-1.6,1.6-1.6c0.8,0,1.8,0.8,1.8,1.6c0,0.8-0.8,1.8-1.8,1.8
                           C10.7,13.4,10,12.6,10,11.6z M6,5.6c1.6-1.4,3.6-2.2,5.7-2.2c4.6,0,8.4,3.6,8.4,8.2c0,2.2-0.8,4.2-2.3,5.7
                           c-0.4-1.1-1.2-1.9-2.2-2.7c0.7-0.8,1.1-1.9,1.1-3c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,1.1,0.4,2.2,1.1,3c-0.9,0.7-1.6,1.6-2.2,2.7
                           C2.5,13.9,2.6,8.7,6,5.6z M49.1,16.6H50c4.3,0,8-3.2,8.2-7.6c0.4-4.6-3-8.6-7.6-9.1c-4.6-0.4-8.6,3-9,7.6
                           C41.2,12.2,44.6,16.2,49.1,16.6z M50,3.4h0.4c2.8,0.3,4.9,2.7,4.6,5.4c-0.3,2.7-2.7,4.7-5.4,4.6c-2.7-0.3-4.7-2.7-4.6-5.4
                           C45.2,5.4,47.4,3.4,50,3.4z M32,62c-7,3-12.4,8.8-14.8,16.1C11.7,75,4.5,77,1.5,82.7c-3,5.7-0.9,12.7,4.7,15.8
                           c1.6,0.9,3.5,1.5,5.4,1.5c6.5,0,11.6-5.1,11.6-11.6c0-3.1-1.2-5.9-3.4-8.1c2-6.8,6.9-12.3,13.5-15.1l0.5-0.3l-1.3-3.1L32,62z
                           M8.4,95.9c0.4-1.8,2.2-3,3.9-2.6c1.3,0.3,2.3,1.3,2.6,2.6C12.9,96.9,10.6,96.9,8.4,95.9z M10,88.4c0-0.9,0.8-1.6,1.6-1.6
                           c0.8,0,1.6,0.8,1.6,1.6c0,0.9-0.8,1.6-1.6,1.6C10.9,90,10,89.2,10,88.4z M20,88.2c0,2.2-0.8,4.2-2.3,5.7c-0.4-1.1-1.2-1.9-2.2-2.7
                           c0.7-0.8,1.1-1.9,1.1-3c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,1.1,0.4,2.2,1.1,3c-0.9,0.7-1.6,1.6-2.2,2.7c-3.1-3.4-3-8.6,0.4-11.8
                           c1.6-1.4,3.6-2.2,5.7-2.2C16.2,80,20,83.7,20,88.2z M30,51.8v-3.4h-3.4v3.4H30z M23.4,51.8v-3.4H20v3.4H23.4z M79.9,51.8v-3.4
                           h-3.4v3.4H79.9z M73.3,51.8v-3.4h-3.4v3.4H73.3z M48.3,30h3.4v-3.4h-3.4V30z M51.7,20h-3.4v3.4h3.4V20z M48.3,80h3.4v-3.4h-3.4V80
                           z M51.7,70h-3.4v3.4h3.4V70z M49.3,83.4c-4.6,0.4-8,4.5-7.6,9.1c0.4,4.3,3.9,7.6,8.2,7.6h0.8c4.6-0.4,8-4.5,7.6-9
                           C57.9,86.4,53.9,83,49.3,83.4z M50.4,96.6c-2.7,0.3-5.1-1.8-5.4-4.6c-0.3-2.7,1.8-5.1,4.6-5.4H50c2.6,0,4.7,2,5,4.6
                           C55.2,93.9,53.2,96.4,50.4,96.6z M8.4,58.4c4.6,0,8.4-3.8,8.4-8.4c0-4.6-3.8-8.4-8.4-8.4c-4.6,0-8.4,3.8-8.4,8.4
                           C0.1,54.6,3.8,58.4,8.4,58.4z M8.4,45c2.7,0,5,2.3,5,5c0,2.7-2.3,5-5,5c-2.7,0-5-2.3-5-5C3.4,47.3,5.6,45,8.4,45z M91.5,41.6
                           c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C99.9,45.4,96.1,41.6,91.5,41.6z M91.5,55c-2.7,0-5-2.3-5-5
                           c0-2.7,2.3-5,5-5c2.7,0,5,2.3,5,5C96.5,52.7,94.3,55,91.5,55z"></path>
                        </g>
                     </g>
                  </svg>
                  `
                },
                {
                  title:"Years of Experience",
                  text:"With us, you get decades of industry experience under a single roof. This empowers us to serve you with excellence.",
                  svg:`
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <path d="M93.8,11.8c-1.8-0.1-3.5,0.5-4.7,1.8L83,19.2l-2.8,2.6v-6.6c0-2.7-2.3-5-5-5h-5V5c0-2.7-2.3-5-5-5H18.4c0,0,0,0-0.1,0
                     S18,0,17.9,0.1h-0.1c-0.1,0.1-0.4,0.1-0.5,0.3L0.5,17.2c-0.1,0.1-0.3,0.3-0.3,0.5v0.1C0.1,18,0,18.1,0,18.2c0,0,0,0,0,0.1V85
                     c0,2.7,2.3,5,5,5h5v5c0,2.7,2.3,5,5,5h60.1c2.7,0,5-2.3,5-5V39.9L90.8,30l2.3,2.4l-9.2,8.4c-0.7,0.7-0.7,1.6-0.1,2.3
                     c0.7,0.7,1.6,0.7,2.3,0.1l9.1-8.4c1.4-1.2,1.5-3.4,0.1-4.7l0,0L93,27.7l4.9-4.6c2.7-2.4,2.8-6.8,0.4-9.5
                     C97.2,12.6,95.6,11.8,93.8,11.8z M88.5,27.6L67.3,47.2c0,0,0,0-0.1,0L59,54.7l-2.7-3l-1.8-1.9l29.4-27.2l2.7,3L88.5,27.6z
                     M45.8,64.9c-1.8,1.5-3.8,2.6-6,3.4c0.8-2.2,2.2-4.1,3.7-5.7l0.1,0.1L45.8,64.9z M48.3,62.8l-1.1-1.4L45.9,60l6.5-7.7l4.2,4.6
                     L48.3,62.8z M75.1,13.4c0.9,0,1.6,0.8,1.6,1.6v9.7l-6.6,6.2V13.4H75.1z M16.8,5.7V15c0,0.9-0.8,1.6-1.6,1.6H5.7L16.8,5.7z M3.4,85
                     V20H15c2.7,0,5-2.3,5-5V3.4h45.1c0.9,0,1.6,0.8,1.6,1.6v29.1L51,48.6c0,0,0,0,0,0.1c0,0,0,0-0.1,0l-8.5,10.1l-0.3,0.3
                     c-2.3,2.2-4.2,4.9-5.4,7.8c-0.5,1.2-0.3,2.6,0.5,3.5c0.7,0.7,1.5,1.1,2.4,1.1c0.4,0,0.7,0,1.1-0.1c3.1-0.9,5.8-2.7,8.1-4.9l0.3-0.3
                     L60,58.5c0,0,0,0,0-0.1h0.1l6.6-6.1V85c0,0.9-0.8,1.6-1.6,1.6H5C4.1,86.6,3.4,85.9,3.4,85z M76.7,95c0,0.9-0.8,1.6-1.6,1.6H15
                     c-0.9,0-1.6-0.8-1.6-1.6v-5h51.7c2.7,0,5-2.3,5-5V49.2l6.6-6.2V95z M95.8,20.8L91,25.4l-2.7-3l-1.8-1.9l4.9-4.6
                     c1.4-1.2,3.5-1.2,4.7,0.1C97.2,17.4,97.2,19.6,95.8,20.8z"></path>
                  </svg>
                  `
                },
                {
                  title:"Innovative and Custom Approach",
                  text:"Every mobile has its own set of needs. With our innovative and custom approach, your mobile app gets the perks it deserves.",
                  svg:`
                    <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M37,8.1H24.2v3.2H37V8.1z M20.9,8.1h-3.2v3.2h3.2V8.1z M100,59.6V40.4h-5.7c-0.5-1.6-1.2-3.2-2-4.7l4.1-3.9L82.8,18.2
                           l-3.9,3.9c-1.5-0.8-3.1-1.5-4.7-1.9v-5.7H61.4V0H0v100h61.4V85.4h12.8v-5.7c1.6-0.5,3.2-1.2,4.7-2l3.9,4.1l13.5-13.5l-4.1-3.9
                           c0.8-1.5,1.5-3.1,2-4.7H100z M58.1,96.8H3.2V77.4h54.9V96.8z M58.1,74.2H3.2V19.3h54.9V74.2z M58.1,16.1H3.2V3.2h54.9V16.1z
                           M91.9,56.4l-0.3,1.2c-0.7,2.2-1.5,4.3-2.6,6.4l-0.7,0.9l3.4,3.4l-9.1,9.1l-3.4-3.4l-0.9,0.5c-1.9,1.1-4.1,2-6.4,2.6l-1.2,0.3v4.9
                           h-9.6v-17c1.1,0.3,2.2,0.4,3.2,0.4c8.6,0,15.5-7,15.5-15.5c0-8.5-7-15.5-15.5-15.5c-1.1,0-2.2,0.1-3.2,0.4V17.7h9.6v4.9l1.2,0.3
                           c2.2,0.7,4.3,1.5,6.4,2.6l1.1,0.7l3.4-3.4l9.1,9.1l-3.5,3.4l0.7,1.1c1.1,1.9,2,4.1,2.6,6.2l0.3,1.2h4.9v12.7H91.9z M61.4,61.9
                           V38.1c1.1-0.3,2.2-0.4,3.2-0.4c6.8,0,12.3,5.5,12.3,12.3c0,6.8-5.5,12.3-12.3,12.3C63.5,62.3,62.4,62.2,61.4,61.9z M62.6,79.5h3.9
                           v-3.2h-3.9V79.5z M62.6,23.8h3.9v-3.2h-3.9V23.8z M86.7,30.5l-2.8-2.8L81.6,30l2.8,2.8L86.7,30.5z M90.8,51.9h3.2V48h-3.2V51.9z
                           M81.7,70l2.3,2.3l2.8-2.8l-2.3-2.3L81.7,70z M30.7,93.6c3.6,0,6.5-2.8,6.5-6.5c0-3.6-3-6.5-6.5-6.5c-3.5,0-6.5,2.8-6.5,6.5
                           C24.2,90.8,27,93.6,30.7,93.6z M30.7,83.8c1.8,0,3.2,1.5,3.2,3.2c0,1.9-1.5,3.2-3.2,3.2c-1.8,0-3.2-1.5-3.2-3.2
                           C27.4,85.3,28.9,83.8,30.7,83.8z M29.1,69.3h3.2v-3.2h-3.2V69.3z M35.5,69.3h3.2v-3.2h-3.2V69.3z M22.6,69.3h3.2v-3.2h-3.2V69.3z
                           M46.8,53.2h-3.2v4.9H17.7v-4.9h-3.1v8.1h32.2V53.2z M43.8,38.6H37V24.2H24.2v14.5h-6.8l13.2,15.4L43.8,38.6z M27.4,41.9V27.4h6.5
                           v14.5h3l-6.2,7.2l-6.2-7.2H27.4z"></path>
                        </g>
                     </g>
                  </svg>
                  `
                },
                {
                  title:"Instant Support Team",
                  text:"We have a devoted support team, which ensures that all your queries are resolved on a priority basis.",
                  svg:`
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M18.8,48.4h-3.1v3.1h3.1V48.4z M48.5,18.9h3.1v-3.1h-3.1V18.9z M93.8,12.7h6.2V9.6h-6.6c-0.7-1.8-2.3-3.1-4.3-3.1
                           c-0.5,0-1.2,0.1-1.8,0.3c-0.5-2-2.3-3.5-4.6-3.5c-0.5,0-1.2,0.1-1.8,0.3c-0.5-2-2.3-3.5-4.6-3.5c-2.2,0-4.1,1.5-4.6,3.5
                           c-0.5-0.3-1.1-0.3-1.8-0.3c-2.6,0-4.7,2.2-4.7,4.7v1.6H45.4c-1.1,0-2.2,0.7-2.7,1.6h-1.8l-6.5-1.9V0.1h-3.1v8l-5.8-1.6H12.7V0.1
                           H9.5v6.6C7.7,7.4,6.4,9,6.4,11.1c0,0.5,0.1,1.2,0.4,1.8c-2,0.5-3.5,2.3-3.5,4.6c0,0.5,0.1,1.2,0.3,1.8c-1.9,0.5-3.4,2.3-3.4,4.6
                           c0,2.2,1.5,4,3.5,4.6c-0.3,0.5-0.3,1.1-0.3,1.8c0,2.6,2.2,4.7,4.7,4.7h1.4v20.2c0,1.1,0.7,2.2,1.6,2.7v1.6l-2,6.2H0v3.1h8.1
                           l-1.6,5.8L6.4,87.3H0v3.1h6.6c0.7,1.8,2.3,3.1,4.3,3.1c0.5,0,1.2-0.1,1.8-0.3c0.5,2,2.3,3.5,4.6,3.5c0.5,0,1.2-0.1,1.8-0.3
                           c0.5,2,2.3,3.5,4.6,3.5c2.2,0,4.1-1.5,4.6-3.5c0.5,0.3,1.1,0.3,1.8,0.3c2.6,0,4.7-2.2,4.7-4.7v-1.6H55c1.1,0,2.2-0.7,2.7-1.6h4.5
                           l3.6,2.8v8.2h3.1v-6.1l5.9,3h6.6c2.6,0,4.7-2.2,4.7-4.7v-1.6h1.6v9.3h3.1v-9.3h1.6c2.6,0,4.7-2.2,4.7-4.7c0-0.5-0.1-1.2-0.3-1.8
                           c1.6-0.3,3-2.2,3-4.3c0-2.2-1.5-4-3.5-4.6c0.3-0.5,0.3-1.1,0.3-1.8c0-2.2-1.5-4-3.5-4.6c0.3-0.5,0.3-1.1,0.3-1.8
                           c0-2-1.4-3.8-3.1-4.3V45.3c0-1.1-0.7-2.2-1.6-2.7v-1.8l1.9-6.5h9.1v-3.1h-8l1.8-5.8L93.8,12.7z M45.4,12.7h20.3v1.6h-1.6
                           c-2.6,0-4.7,2.2-4.7,4.7v6.6l2.8,5.9H45.4V12.7z M7.8,31.4c-0.8,0-1.5-0.8-1.5-1.6c0-0.9,0.7-1.6,1.6-1.6h9.3v-3.1H4.7
                           c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h12.4v-3.1H7.8c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h9.3v-2.8h-6.2
                           c-0.8,0-1.5-0.7-1.5-1.5c0-0.9,0.7-1.6,1.5-1.6h13.8l15.4,4.6h2v15.7h-5.3l-6.6,4.9l-5.8,2.8h-5.9c-0.9,0-1.6-0.7-1.6-1.6v-1.3
                           h4.7v-3.1H7.8z M18.8,40.9h6.6l5.9-2.8v16.7H12.6V34.5h1.5v1.6C14.1,38.7,16.2,40.9,18.8,40.9z M31.1,78.4v13.8h0.3
                           c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-9.3H25v12.4c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6V82.9h-2.8v9.3
                           c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-9.3h-3v6.2c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6V75.3l4.6-15.4v-2h15.7
                           v5.3l4.9,6.6l2.8,5.8v5.9c0,0.9-0.7,1.6-1.6,1.6h-1.6v-4.7H31.1z M54.7,87.4H34.5v-1.6h1.6c2.6,0,4.7-2.2,4.7-4.7v-6.6l-3-5.7h17
                           L54.7,87.4z M57.4,67.2c-0.5-0.9-1.6-1.6-2.7-1.6H35.9v0.5l-3.1-4.2v-4.5c0.9-0.5,1.6-1.6,1.6-2.7V35.6l3.6-2.7h4.6
                           c0.5,0.9,1.6,1.6,2.7,1.6h19.2l2.7,3.6v4.6c-0.9,0.5-1.6,1.6-1.6,2.7v19.8l-6.5,1.9H57.4z M89.1,65.6c0.9,0,1.6,0.7,1.6,1.6
                           c0,0.9-0.7,1.6-1.6,1.6h-6.2v3.1h9.3c0.9-0.1,1.6,0.7,1.6,1.5c0,0.9-0.7,1.6-1.6,1.6h-9.3v3.1h12.4c0.9,0,1.6,0.7,1.6,1.6
                           c0,0.9-0.7,1.6-1.6,1.6H82.8v3.1h9.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6H78.1v3.1h4.7v1.6c0,0.9-0.7,1.6-1.6,1.6h-5.9
                           l-5.8-2.8L63,85.8h-5.3V70.3h1.6l15.9-4.7H89.1z M68.6,64.4v-19h18.8v17.1H74.9L68.6,64.4z M91.2,24.8l-5.3,15.5v2H70.3v-5.3
                           l-4.9-6.6l-2.8-5.8v-5.9c0-0.9,0.7-1.6,1.6-1.6h1.6v4.7h3.1V8c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v9.3h3.1V4.9
                           c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v12.4h3.1V8c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v9.3H88v-6.2
                           c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6V24.8z M81.2,51.6h3.1v-3.1h-3.1V51.6z M51.6,71.8h-3.1v3.1h3.1V71.8z"></path>
                        </g>
                     </g>
                  </svg>
                  `
                },
                {
                  title:"Creative Solutions",
                  text:"Our team of experienced flutter app developers is bestowed with an invincible blend of creativity which helps us build creative solutions for you.",
                  svg:`
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M57.2,88.1h2.4V85h-2.4V88.1z M57.2,94.2h2.4v-3.1h-2.4V94.2z M57.2,81.9h2.4v-3.1h-2.4V81.9z M49.1,87.9h2.4v-3.1h-2.4
                           V87.9z M49.1,100h2.4v-3h-2.4V100z M49.1,93.9h2.4v-3.1h-2.4V93.9z M40.7,88.1h2.4V85h-2.4V88.1z M40.7,81.9h2.4v-3.1h-2.4V81.9z
                           M65.4,47.1c3-3.5,4.6-8.1,4.6-12.7c0-5.4-2.2-10.5-5.9-14.2c-3.9-3.8-9.1-5.8-14.5-5.7c-10.7,0.3-19.5,9.2-19.5,19.8
                           c0,4.7,1.6,9.2,4.6,12.8c3.8,4.2,5.8,9.9,5.8,15.8v1.9h-2v2.4h2v2.4h-2v2.4h2v4.7h19.3v-4.7H62v-2.4h-2.2v-2.4H62v-2.4h-2.2v-2
                           C59.9,57.1,61.9,51.4,65.4,47.1z M57.4,74.5H43v-2.3h14.5V74.5z M57.4,69.8H43v-2.4h14.5V69.8z M57.4,62.9v2H43v-2.3h14.5
                           C57.4,62.6,57.4,62.8,57.4,62.9z M57.6,60.1h-3.5v-3.5h-2.4v3.5h-3v-3.5h-2.4v3.5h-3.4c-0.5-5.4-2.7-10.5-6.1-14.6
                           c-2.6-3.1-4.1-7.2-4.1-11.2c0-9.4,7.7-17.3,17-17.4c4.7-0.1,9.2,1.6,12.6,5c3.4,3.4,5.3,7.7,5.3,12.5c0,4-1.5,8.1-4.1,11.2
                           C60.1,49.5,58.1,54.7,57.6,60.1z M61.9,38.7l2.8-0.9l0.1-0.7c0.1-0.7,0.1-1.5,0.1-2.3c0-0.8,0-1.5-0.1-2.3l-0.1-0.7l-2.8-0.9
                           c-0.1-0.5-0.4-0.9-0.5-1.5l1.4-2.7L62.2,26c-0.9-1.2-2-2.3-3.2-3.2l-0.5-0.4l-2.7,1.3c-0.4-0.3-0.9-0.4-1.5-0.5l-0.9-2.8l-0.7-0.1
                           c-1.5-0.3-3-0.3-4.5,0h-0.8l-1.1,2.8c-0.5,0.1-0.9,0.4-1.5,0.5L42,22.3l-0.5,0.4c-1.2,0.9-2.3,2-3.2,3.2l-0.4,0.5l1.4,2.7
                           c-0.3,0.4-0.4,0.9-0.5,1.5l-2.8,0.9l-0.1,0.9c-0.1,0.7-0.1,1.5-0.1,2.3c0,0.8,0,1.5,0.1,2.3l0.1,0.7l2.8,0.9
                           c0.1,0.5,0.4,0.9,0.5,1.5l-1.4,2.7l0.4,0.5c0.9,1.2,2,2.3,3.2,3.2l0.5,0.4l2.7-1.3c0.4,0.3,0.9,0.4,1.5,0.5l0.9,2.8l0.7,0.1
                           c0.7,0.1,1.5,0.1,2.3,0.1c0.8,0,1.5,0,2.3-0.1l0.7-0.1l0.9-2.8c0.5-0.1,0.9-0.4,1.5-0.5l2.7,1.3l0.5-0.4c1.2-0.9,2.3-2,3.2-3.2
                           l0.4-0.5l-1.4-2.7C61.6,39.7,61.8,39.3,61.9,38.7z M60,36.8l-0.1,0.7c-0.3,0.7-0.5,1.5-0.8,2l-0.3,0.5l1.2,2.6
                           c-0.5,0.5-1.1,1.2-1.6,1.6L55.8,43L55,43.3c-0.7,0.4-1.4,0.7-2.2,0.8l-0.7,0.1l-0.9,2.6c-0.8,0.1-1.6,0.1-2.3,0L48,44.3l-0.7-0.1
                           c-0.7-0.3-1.5-0.5-2.2-0.8L44.6,43L42,44.3c-0.5-0.5-1.2-1.1-1.6-1.6l1.2-2.6l-0.3-0.5c-0.4-0.7-0.7-1.3-0.8-2v-0.7L38,35.9v-2.4
                           l2.6-0.9l0.1-0.7c0.3-0.7,0.5-1.5,0.8-2l0.3-0.5l-1.2-2.6c0.5-0.5,1.1-1.2,1.6-1.6l2.6,1.2l0.5-0.3c0.7-0.4,1.4-0.7,2-0.8l0.7-0.1
                           l0.9-2.6c0.8-0.1,1.6-0.1,2.3,0l0.9,2.6l0.7,0.1c0.7,0.3,1.5,0.5,2,0.8l0.5,0.3l2.6-1.2c0.5,0.5,1.2,1.1,1.6,1.6l-1.2,2.6l0.3,0.5
                           c0.4,0.7,0.7,1.3,0.8,2l0.1,0.7l2.6,0.9v2.4L60,36.8z M50.1,27.5c-4.1,0-7.3,3.2-7.3,7.3c0,4,3.2,7.3,7.3,7.3
                           c4.1,0,7.3-3.2,7.3-7.3C57.4,30.8,54.2,27.5,50.1,27.5z M50.1,39.5c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9
                           c2.7,0,4.9,2.2,4.9,4.9C55,37.4,52.8,39.5,50.1,39.5z M30.3,20.4l-1.8-1.8c-8.9,8.9-8.9,23.3,0,32.3l1.8-1.8
                           C22.3,41.2,22.3,28.3,30.3,20.4z M26.1,16.6l-1.8-1.8c-11.1,10.9-11.1,28.9,0,39.8l1.8-1.8C15.9,42.9,15.9,26.6,26.1,16.6z
                           M71.5,18.6l-1.8,1.8c3.8,3.8,5.9,8.9,5.9,14.3c0,5.4-2.2,10.5-5.9,14.3l1.8,1.8c4.3-4.3,6.6-10,6.6-16.1
                           C78.1,28.6,75.8,22.9,71.5,18.6z M75.7,14.8l-1.8,1.8c10,10,10,26.3,0,36.3l1.8,1.8C86.8,43.7,86.8,25.8,75.7,14.8z M51.5,0h-2.4
                           v10.4h2.4V0z M68,6.1l-1.7-1.7l-7.4,7.3l1.7,1.7L68,6.1z M41.6,11.8l-7.4-7.3l-1.7,1.7l7.4,7.3L41.6,11.8z"></path>
                        </g>
                     </g>
                  </svg>
                  `
                },
                {
                  title:"Thorough Research",
                  text:"For every project we initiate, our team first ensures doing thorough research to build custom solutions.",
                  svg:`
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M30,6.6C17.2,6.6,6.6,17,6.6,30C6.6,43,17,53.4,30,53.4C43,53.4,53.4,43,53.4,30C53.4,17.2,42.8,6.6,30,6.6z M30,50
                           c-11.1,0-20-8.9-20-20c0-11.1,8.9-20,20-20c11.1,0,20,8.9,20,20C50,41.1,41.1,50,30,50z M98.4,35h-6.6v-5c0-0.9-0.8-1.6-1.6-1.6
                           h-6.8c-0.7-7.6-7-13.4-14.6-13.4H56.1C47.7,0.7,29.3-4.3,15,4.1C0.7,12.4-4.3,30.6,4,45c0.8,1.4,1.6,2.7,2.7,3.9V100h3.4V52.3
                           c4.2,3.8,9.5,6.3,15,7.3V70h-10v3.4h5V80h-5v3.4h5V90h-5v3.4h5v6.6h3.4V73.4h13.4V100h3.4V71.6c0-0.9-0.8-1.6-1.6-1.6h-3.4V59.6
                           c5-0.8,9.9-3,13.8-6.2h7.8V50h-4.3c7.8-8.6,9.9-20.9,5.3-31.6h9.1V30c0,0.9,0.8,1.6,1.6,1.6h11.6V100h3.4V31.6h5V100h3.4V38.4h5
                           V100h3.1V36.6C100,35.8,99.2,35,98.4,35z M31.6,70h-3.2V59.9c0.5,0,1.1,0.1,1.6,0.1c0.5,0,1.1,0,1.6-0.1V70z M30,56.6
                           C15.3,56.6,3.4,44.7,3.4,30S15.3,3.4,30,3.4c14.7,0,26.6,11.9,26.6,26.6S44.7,56.6,30,56.6z M70,28.4v-9.9
                           c5.3,0.5,9.3,4.7,9.9,9.9H70z M18.4,40h3.4V30h-3.4V40z M25,40h3.4V25H25V40z M31.6,40H35V20h-3.4V40z M38.4,40h3.4V26.6h-3.4V40z
                           M66.6,43.4H75V40h-8.4V43.4z M66.6,53.4H75V50h-8.4V53.4z M66.6,63.4H75V60h-8.4V63.4z M66.6,73.4H75V70h-8.4V73.4z M66.6,83.4
                           H75V80h-8.4V83.4z M66.6,93.4H75V90h-8.4V93.4z M48.4,63.4h8.4V60h-8.4V63.4z M48.4,73.4h8.4V70h-8.4V73.4z M48.4,83.4h8.4V80
                           h-8.4V83.4z M48.4,93.4h8.4V90h-8.4V93.4z M21.6,60H15v3.4h6.6V60z"></path>
                        </g>
                     </g>
                  </svg>
                  `
                },
                {
                  title:"Industry-specific",
                  text:"We believe that if the app development process isn’t accomplished keeping in mind the industry you belong, the results won’t be what your business deserves.",
                  svg:`
                  <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <path class="st0" d="M72.8,20.2h-1.3L69.1,3.9c-0.1-0.6-0.6-1-1.2-1h-6.5c-0.6,0-1.1,0.4-1.2,1L58,20.2h-1.9c-0.6,0-1.2,0.5-1.2,1.2
                     v6.1L41,32.4v-4.7c0-0.3-0.2-0.7-0.4-0.9l-22-17.9c-0.5-0.4-1.2-0.3-1.6,0.2c-0.2,0.2-0.3,0.5-0.3,0.7V14l-6.2-5.1
                     c-0.5-0.4-1.2-0.3-1.6,0.2C8.8,9.3,8.7,9.6,8.7,9.8v4.6L1.9,8.9C1.4,8.5,0.7,8.6,0.3,9.1C0.1,9.3,0,9.6,0,9.8V70
                     c0,0.6,0.5,1.2,1.2,1.2h71.7c0.6,0,1.2-0.5,1.2-1.2V21.4C74,20.8,73.5,20.2,72.8,20.2z M62.5,5.2H67l2.2,15h-8.9L62.5,5.2z
                     M19.1,12.3l19.7,16v4.9l-2.7,1c-0.5,0.2-0.8,0.6-0.8,1.1v3.5H33v-11c0-0.3-0.2-0.7-0.4-0.9l-13.4-11V12.3z M11,12.3l19.7,16v10.4
                     h-6.4v-11c0-0.3-0.2-0.7-0.4-0.9L11,16.4V12.3z M2.3,12.3l19.7,16v10.4H2.3V12.3z M23.1,68.8H2.3V41h33v4.6h-13
                     c-1.3,0-2.3,1-2.3,2.3v3.5c0,1.3,1,2.3,2.3,2.3h0.9V68.8z M45.1,68.8h-15V58.4h15V68.8z M49.7,68.8h-2.3V57.2c0-0.6-0.5-1.2-1.2-1.2
                     H28.9c-0.6,0-1.2,0.5-1.2,1.2v11.6h-2.3v-15h24.3V68.8z M24.3,51.5h-2V48h30.6v3.5H24.3z M71.7,68.8H52v-15h0.9c1.3,0,2.3-1,2.3-2.3
                     V48c0-1.3-1-2.3-2.3-2.3H37.6v-9.6l18.9-6.7c0.5-0.2,0.8-0.6,0.8-1.1v-5.8h14.5V68.8z"></path>
                     <path class="st0" d="M67.6,24.9h-6.9c-0.6,0-1.2,0.5-1.2,1.2v5.8c0,0.6,0.5,1.2,1.2,1.2h6.9c0.6,0,1.2-0.5,1.2-1.2V26
                     C68.8,25.4,68.3,24.9,67.6,24.9z M66.5,30.6h-4.6v-3.5h4.6V30.6z"></path>
                     <path class="st0" d="M13.9,28.3H6.9c-0.6,0-1.2,0.5-1.2,1.2v5.8c0,0.6,0.5,1.2,1.2,1.2h6.9c0.6,0,1.2-0.5,1.2-1.2v-5.8
                     C15,28.8,14.5,28.3,13.9,28.3z M12.7,34.1H8.1v-3.5h4.6V34.1z"></path>
                     <path class="st0" d="M12.7,43.4H5.8c-0.6,0-1.2,0.5-1.2,1.2v5.8c0,0.6,0.5,1.2,1.2,1.2h6.9c0.6,0,1.2-0.5,1.2-1.2v-5.8
                     C13.9,43.9,13.4,43.4,12.7,43.4z M11.6,49.1H6.9v-3.5h4.6V49.1z"></path>
                     <path class="st0" d="M12.7,53.8H5.8c-0.6,0-1.2,0.5-1.2,1.2v5.8c0,0.6,0.5,1.2,1.2,1.2h6.9c0.6,0,1.2-0.5,1.2-1.2v-5.8
                     C13.9,54.3,13.4,53.8,12.7,53.8z M11.6,59.5H6.9v-3.5h4.6V59.5z"></path>
                     <path class="st0" d="M67.6,35.3h-6.9c-0.6,0-1.2,0.5-1.2,1.2v5.8c0,0.6,0.5,1.2,1.2,1.2h6.9c0.6,0,1.2-0.5,1.2-1.2v-5.8
                     C68.8,35.8,68.3,35.3,67.6,35.3z M66.5,41h-4.6v-3.5h4.6V41z"></path>
                  </svg>
                  `
                },
                {
                  title:"Well-defined Process",
                  text:"Being a leading flutter app development company, we have a well-defined process to accomplish every web and app development project we receive.",
                  svg:`
                  <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <path class="st0" d="M67.2,21.8c-2.5-4.5-6.1-8.5-10.3-11.4c-1.1-0.8-2.6-0.8-3.7-0.1l-3.3,2.2c-0.8,0.5-1.3,1.4-1.2,2.4
                     c0,1,0.5,1.8,1.4,2.3c3.7,2.2,6.7,5.3,8.8,9c4.4,7.4,4.6,16.5,0.6,24.1L57,49c-0.8-0.5-1.9-0.2-2.4,0.6c-0.2,0.3-0.3,0.6-0.2,1
                     l0.8,13.6c0.1,1,0.9,1.7,1.8,1.6c0.2,0,0.5-0.1,0.7-0.2l12.2-6.2c0.9-0.4,1.2-1.5,0.8-2.3c-0.2-0.3-0.4-0.6-0.7-0.7l-2.4-1.4
                     l0.2-0.4C73,44.3,72.8,32,67.2,21.8z M65.5,56.5l2.5,1.4l-10.6,5.4l-0.7-11.9l2.5,1.4c0.5,0.3,1.2,0.1,1.5-0.4
                     c4.9-8.5,4.9-18.9-0.1-27.4c-2.3-4-5.7-7.4-9.6-9.8c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.3,0.2-0.4l0,0l3.3-2.2
                     c0.4-0.2,0.8-0.2,1.2,0c4,2.8,7.3,6.5,9.7,10.7c5.3,9.5,5.5,21,0.5,30.7L65,55C64.8,55.6,65,56.2,65.5,56.5z M5.3,58.1l2.4-1.3
                     C13.8,67,25,73.3,36.9,73.3h0.2c5,0,10-1.1,14.5-3.2c1.2-0.6,2-1.8,1.9-3.2l-0.2-4c-0.1-0.9-0.6-1.8-1.4-2.3c-0.8-0.5-1.9-0.5-2.7,0
                     c-3.7,2-7.9,3.1-12.1,3.1H37c-8.6,0-16.6-4.4-21.1-11.8l2.3-1.3c0.8-0.5,1.1-1.5,0.7-2.4c-0.2-0.3-0.4-0.5-0.7-0.7L6,41.4
                     c-0.9-0.4-1.9-0.1-2.3,0.7c-0.1,0.2-0.2,0.5-0.2,0.7L2.7,56.5c-0.1,1,0.7,1.8,1.6,1.8C4.6,58.3,5,58.2,5.3,58.1z M5.7,43.7l10.6,5.5
                     l-2.5,1.4c-0.5,0.3-0.7,1-0.4,1.5C18.1,60.7,27.2,66,37,66h0.2c4.6,0,9.2-1.2,13.2-3.4c0.2-0.1,0.4-0.1,0.5,0
                     c0.2,0.1,0.3,0.2,0.3,0.4l0.2,4c0,0.4-0.2,0.8-0.6,1c-4.3,2-9,3-13.8,3C25.4,71,14.7,64.7,9,54.6c-0.3-0.5-1-0.7-1.5-0.4L5,55.6
                     L5.7,43.7z M4.3,38.6l3.5,1.8l0,0c1.4,0.7,3,0.2,3.7-1.2c0.2-0.4,0.3-0.8,0.3-1.2c0.2-4.1,1.3-8.2,3.4-11.7
                     c4.2-7.5,12-12.2,20.6-12.5v2.7c0,1,0.8,1.7,1.8,1.7c0.3,0,0.6-0.1,0.9-0.3l11.4-7.5c0.8-0.5,1-1.6,0.5-2.4
                     c-0.1-0.2-0.3-0.4-0.5-0.5L38.5,0.1c-0.8-0.5-1.9-0.3-2.4,0.5c-0.2,0.3-0.3,0.6-0.3,1v2.7C23.8,4.6,12.9,11.1,7,21.5
                     c-2.5,4.2-4,8.9-4.5,13.8C2.3,36.7,3,38,4.3,38.6z M8.9,22.7C14.7,12.6,25.4,6.4,37,6.4c0.6,0,1.1-0.5,1.1-1.1V2.5l10,6.5l-10,6.5
                     v-2.9c0-0.6-0.5-1.1-1.1-1.1c-9.8-0.1-18.9,5.2-23.7,13.7C11,29.1,9.7,33.5,9.6,38c0,0.3-0.3,0.5-0.5,0.5c-0.1,0-0.1,0-0.2-0.1
                     l-3.5-1.8c-0.4-0.2-0.6-0.6-0.6-1C5.2,31,6.6,26.6,8.9,22.7z M32.3,35.2c-0.4-0.4-1.2-0.4-1.6,0l-3.4,3.4c-0.4,0.4-0.4,1.2,0,1.6
                     l6.6,6.6c0.4,0.4,1.2,0.4,1.6,0l3.4-3.4l7.7-7.7c0.4-0.4,0.4-1.2,0-1.6l-3.4-3.4c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
                     l-6.9,6.9L32.3,35.2z M42.5,33.2l1.8,1.8l-9.5,9.5l-5-5l1.8-1.8L34,40c0.4,0.4,1.2,0.4,1.6,0L42.5,33.2z M18.7,38.8
                     c0,10.1,8.2,18.3,18.3,18.3c10.1,0,18.3-8.2,18.3-18.3c0-10.1-8.2-18.3-18.3-18.3C26.9,20.5,18.7,28.7,18.7,38.8z M53,38.8
                     c0,8.9-7.2,16-16,16c-8.9,0-16-7.2-16-16s7.2-16,16-16c0,0,0,0,0,0C45.8,22.8,53,29.9,53,38.8L53,38.8z"></path>
                  </svg>
                  `
                },
            ]
        }
    }
}
</script>

<style>
@media (max-width: 567px) {
   .fxt-process-dev {
      font-size:20px !important;
      font-weight:bold !important;
   }
}
</style>