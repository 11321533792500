<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>MVP Benefits</span>
				<h2 class="fxt-process-dev">Benefits of MVP Development</h2>
				<p>
					Being the top MVP development company, we will help you research the market and prove your
					assumptions right with a minimal budget. We will help you fix the right product type,
					featuresets, and deployment model that will catapult your idea from a prototype to a
					scalable product.
				</p>
			</div>
			<div class="mvp-after-before-section box-shadow rounded10 o-hidden">
				<div class="twentytwenty-wrapper twentytwenty-horizontal">
					<div class="twentytwenty-container" style="height: 494.469px">
						<img
							src="https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/mvp-before.jpg"
							alt="mvp development company"
							class="twentytwenty-before"
							style="clip: rect(0px, 570px, 494.469px, 0px)"
						/>
						<img
							src="https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/mvp-after.jpg"
							alt="mvp development services"
							class="twentytwenty-after"
							style="clip: rect(0px, 1140px, 494.469px, 570px)"
						/>
						<div class="twentytwenty-overlay"></div>
						<div class="twentytwenty-before-label"></div>
						<div class="twentytwenty-after-label"></div>
					</div>
				</div>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<MidCard v-bind:obj="item"></MidCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import MidCard from '../AssetComponents/MidCard.vue';

export default {
	components: {
		MidCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Validate business idea',
					text: 'Ask the right questions before starting product development. Should this product be developed at all? How can it be developed? Who will it benefit? Know it all to validate the business idea.',
					svg: `
						<svg
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<g>
								<polygon
									points="33.7,44.1 32,44.1 32,45.8 6.2,45.8 6.2,4.3 31.8,4.3 31.8,6.9 33.5,6.9 33.5,4.3 36.1,4.3 36.1,2.6 33.5,2.6
														33.5,0 31.8,0 31.8,2.6 6.2,2.6 6.2,0 4.5,0 4.5,2.6 1.6,2.6 1.6,4.3 4.5,4.3 4.5,45.8 1.6,45.8 1.6,47.5 4.5,47.5 4.5,50 6.2,50
														6.2,47.5 32,47.5 32,50 33.7,50 33.7,47.5 36.1,47.5 36.1,45.8 33.7,45.8  "
								></polygon>
								<rect x="31.8" y="8.7" width="1.7" height="2.6"></rect>
								<path
									d="M33.4,12.7c-5.7-0.2-10.6,4.2-10.8,9.9c0,0.1,0,0.3,0,0.4c0,3.8,2.1,7.3,5.3,9.1v1.7c0,2,1.6,3.7,3.7,3.7h2.6
														c2,0,3.7-1.7,3.7-3.7v-1.7c3.4-1.9,5.5-5.5,5.4-9.4C43.1,17.3,38.8,13,33.4,12.7z M36.8,30.8c-0.3,0.1-0.5,0.4-0.5,0.8v2.3
														c0,1.1-0.9,2-2,2c0,0,0,0,0,0h-0.5v-9.4l1-0.4c0.6,1.3,2.1,1.8,3.4,1.2s1.8-2.1,1.2-3.4c-0.6-1.3-2.1-1.8-3.4-1.2
														c-0.7,0.3-1.2,0.9-1.4,1.7L33.1,25l-1.7-0.6c-0.4-1.3-1.7-2.1-3.1-1.8c-1.3,0.4-2.1,1.7-1.8,3.1c0.4,1.3,1.7,2.1,3.1,1.8
														c0.7-0.2,1.3-0.7,1.6-1.3l1,0.4v9.4h-0.5c-1.1,0.1-2-0.7-2.1-1.8c0-0.1,0-0.1,0-0.2v-2.3c0-0.3-0.2-0.6-0.5-0.8
														c-3-1.5-4.9-4.5-4.9-7.8c0-4.8,3.9-8.7,8.7-8.7c0,0,0,0,0,0h0.4c4.5,0.2,8.1,3.9,8.3,8.4C41.7,26.2,39.8,29.4,36.8,30.8L36.8,30.8z
														M36.4,25.1c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9C36.7,26,36.3,25.6,36.4,25.1
														C36.3,25.1,36.3,25.1,36.4,25.1L36.4,25.1z M29.7,25.1c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8
														C29.3,24.3,29.6,24.7,29.7,25.1C29.6,25.1,29.6,25.1,29.7,25.1L29.7,25.1z"
								></path>
								<rect x="45.8" y="22.4" width="2.6" height="1.7"></rect>
								<rect x="17.1" y="22.4" width="2.6" height="1.7"></rect>
								<rect
									x="21.5"
									y="11.5"
									transform="matrix(0.776 -0.6307 0.6307 0.776 -3.1046 16.9561)"
									width="1.7"
									height="2.6"
								></rect>
								<rect
									x="41.5"
									y="31.4"
									transform="matrix(0.7762 -0.6305 0.6305 0.7762 -11.1692 34.0196)"
									width="1.7"
									height="2.6"
								></rect>
								<rect
									x="19.6"
									y="30.3"
									transform="matrix(0.8945 -0.447 0.447 0.8945 -11.7176 12.623)"
									width="2.6"
									height="1.7"
								></rect>
								<rect
									x="42.6"
									y="14.5"
									transform="matrix(0.8944 -0.4473 0.4473 0.8944 -2.243 21.2773)"
									width="2.6"
									height="1.7"
								></rect>
								<path
									d="M35.9,38.2h-5.4c-0.5,0-0.9,0.4-0.9,0.9v0.6c0,1.5,1.2,2.7,2.7,2.7c0,0,0,0,0,0h1.7c1.5,0,2.7-1.2,2.7-2.7V39
														C36.8,38.6,36.4,38.2,35.9,38.2C35.9,38.2,35.9,38.2,35.9,38.2z M34,40.7h-1.7c-0.5,0-0.9-0.3-1-0.8H35
														C34.9,40.3,34.5,40.6,34,40.7L34,40.7z"
								></path>
								<path
									d="M12,7L12,7c-1.1,0-2.3,0.4-3.1,1.1C8,8.9,7.5,10,7.5,11.1v26.7c0,0.1,0,0.2,0.1,0.3v0.1c0,0,0,0,0,0.1l3.6,6.3
														c0.2,0.3,0.4,0.4,0.7,0.4l0,0c0.3,0,0.6-0.2,0.7-0.4l3.7-6.4v-0.1v-0.1c0-0.1,0.1-0.2,0.1-0.3V11C16.3,8.7,14.3,6.9,12,7z M10,9.5
														c0.6-0.5,1.3-0.7,2-0.7l0,0c1.4-0.1,2.6,0.9,2.8,2.3c0,0,0,0,0,0v1.3H9.2v-1.3C9.3,10.5,9.5,9.9,10,9.5z M14.8,35.5l-0.3-0.1h-0.2
														c-0.2,0-0.3-0.1-0.5-0.1l0,0c-0.7,0-1.3,0.2-1.8,0.7c-0.5-0.4-1.2-0.7-1.8-0.6l0,0c-0.2,0-0.3,0-0.5,0H9.6l-0.3,0.1l0-21.5h5.5
														L14.8,35.5z M12.1,42.4l-2.7-4.8c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1l0.1-0.1c0.1,0,0.1-0.1,0.2-0.1h0.1
														c0.1,0,0.2-0.1,0.3-0.1l0,0c0.5,0,1,0.3,1,0.7c0,0.5,0.4,0.9,0.9,0.9l0,0c0.5,0,0.9-0.4,0.9-0.9l0,0c0-0.4,0.5-0.7,1-0.7l0,0
														c0.1,0,0.2,0,0.3,0.1h0.1c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1L12.1,42.4z"
								></path>

								<rect
									x="11.2"
									y="14.1"
									transform="matrix(1 -1.396262e-03 1.396262e-03 1 -3.305346e-02 1.682232e-02)"
									width="1.7"
									height="19.2"
								></rect>
							</g>
						</svg>
					`
				},
				{
					title: 'Plan long-term',
					text: 'Know in advance the technical challenges that should be addressed to scale the product post the MVP stage. Set the ground right for long-term development of the product.',
					svg: `
						<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<path
										d="M44.7,15.7l-3.4-1.8l4.8-4.8c0.5-0.5,0.7-1.1,0.7-1.8c0-1.4-1.1-2.5-2.5-2.5c-0.7,0-1.3,0.3-1.8,0.7l-3,3V0H4.1v50h35.5
															v-5.6h6.4v-6.4h-1.6v-3.4c0-0.3,0.1-0.7,0.2-1l1.9-4.4c0.2-0.5,0.3-1,0.3-1.6v-8.2c0-0.6-0.1-1.3-0.4-1.8
															C46.1,16.8,45.5,16.1,44.7,15.7z M43.7,6.7c0.2-0.2,0.4-0.3,0.6-0.3c0.5,0,0.9,0.4,0.9,0.9c0,0.2-0.1,0.5-0.3,0.6l-8.8,8.8
															c-0.4-0.5-0.9-0.9-1.5-1.1L43.7,6.7z M37,18.3l0.3-0.3l3.4,1.7c0.3,0.1,0.4,0.4,0.4,0.7c0,0.9-0.1,1.7-0.4,2.5l-0.6,1.9
															c-0.2,0.6-0.8,1.1-1.5,1.1c-0.8,0-1.5-0.7-1.5-1.5v-4.9C37.2,19,37.1,18.6,37,18.3z M31.5,21.4l-3.6,3.6l-1.7,0.4l0.4-1.7l4.9-4.9
															V21.4z M17.8,31.4c-4.1-0.4-7.3-3.8-7.3-8s3.2-7.6,7.3-8V31.4z M24.9,24.2h-5.4v-8.8c3.4,0.3,6.4,2.3,7.9,5.4L25.2,23L24.9,24.2z
															M19.4,25.8h5L24,27.7l4.2-1c-0.8,4.3-4.3,7.6-8.7,8V25.8z M33.8,14.4l-1.6-0.8c-0.4-0.2-0.6-0.6-0.6-1v-0.1c0-0.6,0.5-1.1,1.1-1.1
															c0.2,0,0.4,0,0.5,0.1l2.3,1.2L33.8,14.4z M44.4,42.7h-4.8c-0.9,0-1.6,0.7-1.6,1.6v4H5.7V1.6H38v8.5l-1.3,1.3L34,10
															c-0.4-0.2-0.8-0.3-1.3-0.3c-1.5,0-2.7,1.2-2.7,2.7v0.1c0,1,0.6,2,1.5,2.5l1,0.5c-0.4,0.2-0.8,0.6-0.9,1.1l-3,3
															c-2-3.6-5.7-5.9-9.9-5.9h-0.8v0c-5,0.4-8.9,4.6-8.9,9.6s3.9,9.2,8.9,9.6v3.3h0.8c6.1,0,11.1-4.9,11.3-10.9l1.6-1.6v3.6
															c0,0.7,0.2,1.4,0.5,2l2.4,4.1c0.2,0.4,0.3,0.8,0.3,1.2v3.2h-1.6v6.4h3.2v-1.6h-1.6v-3.2h9.7V42.7z M45.2,27.6c0,0.3-0.1,0.7-0.2,1
															l-1.9,4.4c-0.2,0.5-0.3,1-0.3,1.6v3.4h-6.4v-3.2c0-0.7-0.2-1.4-0.5-2l-2.4-4.1c-0.2-0.4-0.3-0.8-0.3-1.2V16.9
															c1.3,0,2.4,1.1,2.4,2.4v4.9c0,1.7,1.4,3.1,3.1,3.1c1.4,0,2.6-0.9,3-2.2l0.6-1.9c0.3-1,0.5-2,0.5-3c0-0.9-0.5-1.8-1.3-2.2l-2.9-1.5
															l1.6-1.6l3.8,2c0.4,0.2,0.8,0.6,1,1.1c0.2,0.3,0.3,0.7,0.3,1.1V27.6z"
									></path>
									<rect x="21.1" y="28.2" width="1.6" height="1.6"></rect>
									<rect x="24.3" y="28.2" width="1.6" height="1.6"></rect>
									<rect x="12.2" y="21" width="4" height="1.6"></rect>
									<rect x="12.2" y="24.2" width="4" height="1.6"></rect>
									<polygon
										points="18.6,41.1 17,41.1 17,45.2 15.4,45.2 15.4,39.5 13.8,39.5 13.8,45.2 12.2,45.2 12.2,37.1 10.6,37.1 10.6,45.2
															9,45.2 9,34.7 7.4,34.7 7.4,46.8 20.3,46.8 20.3,45.2 18.6,45.2  "
									></polygon>
									<polygon
										points="27.5,37.9 28.8,37.9 26.7,40 25.1,38.4 20.5,43 21.6,44.1 25.1,40.7 26.7,42.3 29.9,39 29.9,40.3 31.5,40.3
															31.5,36.3 27.5,36.3  "
									></polygon>
									<rect x="7.4" y="3.2" width="1.6" height="1.6"></rect>
									<rect x="10.6" y="3.2" width="11.3" height="1.6"></rect>
									<rect x="7.4" y="6.5" width="1.6" height="1.6"></rect>
									<rect x="10.6" y="6.5" width="11.3" height="1.6"></rect>
									<rect x="7.4" y="9.7" width="1.6" height="1.6"></rect>
									<rect x="10.6" y="9.7" width="11.3" height="1.6"></rect>
									<rect x="28.3" y="3.2" width="1.6" height="1.6"></rect>
									<rect x="31.5" y="3.2" width="1.6" height="1.6"></rect>
									<rect x="34.8" y="3.2" width="1.6" height="1.6"></rect>
								</g>
							</svg>
					`
				},
				{
					title: 'Win first-adopter confidence',
					text: 'Present the MVP to your investors or first-adopters to win confidence, secure funding or to kickstart a crowdsourcing campaign.',
					svg: `
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									d="M5.7,35.6c0.2,0.2,0.4,0.3,0.7,0.2c0.2,0,0.5-0.1,0.6-0.3l1-1.4v15.1c0,0.5,0.4,0.8,0.8,0.8h5c0.5,0,0.8-0.4,0.8-0.8V35.8
													h5.9V40H18c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5,0.4,0.8,0.8,0.8h16.8c0.5,0,0.8-0.4,0.8-0.8v-8.3c0-0.5-0.4-0.8-0.8-0.8h-7.5v-6.7
													c0-2.3-1.9-4.2-4.2-4.2h-5v-4.6l11.4-8.1c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2,0-0.4-0.2-0.6L27.1,12c-0.3-0.4-0.8-0.4-1.1-0.2l-8.6,5.9
													c1.5-2.8,0.4-6.4-2.4-7.9S8.5,9.4,7,12.3c-1.2,2.3-0.8,5.1,1.1,6.9H7.1c-0.2,0-0.5,0.1-0.6,0.3l-5.4,6c-1.5,1.7-1.4,4.2,0.2,5.7
													L5.7,35.6z M7.9,29.8l-1.3-1.4L7.9,27V29.8z M33.9,48.3H18.8v-6.7h15.1V48.3z M14.1,20.8L13,23.1l-1.2-2.3H14.1z M7.9,15
													c0-2.3,1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2S7.9,17.3,7.9,15z M2.3,26.5l5.2-5.7h2.5l2.3,4.5c0.2,0.4,0.7,0.6,1.1,0.4
													c0.2-0.1,0.3-0.2,0.4-0.4l2.4-4.8l10.1-6.9l1.5,2l-11,7.8c-0.2,0.2-0.4,0.4-0.4,0.7V30c0,0.5,0.4,0.8,0.8,0.8H23
													c1.4,0,2.5,1.1,2.5,2.5V40h-3.4v-5c0-0.5-0.4-0.8-0.8-0.8h-7.5c-0.5,0-0.8,0.4-0.8,0.8v13.3H9.6V25c0-0.3-0.2-0.6-0.5-0.8
													c-0.3-0.1-0.7-0.1-0.9,0.2l-3.4,3.3c-0.3,0.3-0.3,0.8,0,1.2l2.7,2.8l-1.4,2L2.4,30C1.4,29.1,1.4,27.5,2.3,26.5z"
								></path>
								<path
									d="M49,6.7c-3.2,0-5.9-2.6-5.9-5.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,3.2-2.6,5.8-5.9,5.8
													c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c3.2,0,5.9,2.6,5.9,5.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-3.2,2.6-5.8,5.9-5.8
													c0.5,0,0.8-0.4,0.8-0.8S49.5,6.7,49,6.7z M42.3,10.7c-0.7-1.4-1.9-2.5-3.3-3.2c1.4-0.7,2.5-1.8,3.3-3.2c0.7,1.4,1.9,2.5,3.3,3.2
													C44.2,8.2,43.1,9.3,42.3,10.7z"
								></path>
								<polygon points="25.9,22.7 28.2,21 29.2,22.3 26.9,24 "></polygon>
								<polygon points="30.1,19.6 38.5,13.5 39.5,14.8 31.1,21 "></polygon>
								<polygon points="30.1,12.6 35.2,9.3 36.1,10.7 31.1,14 "></polygon>
							</svg>
					`
				},
				{
					title: 'Scale rapidly',
					text: 'Going from scratch till any capacity you want is easier with GenixBit Labs. You will have the same MVP development team serving you until the end.',
					svg: `
						<svg
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<path
								d="M30.2,32.7c1.4-2.6,0.6-5.8-1.8-7.5v-4.5c0.3-0.1,0.6-0.2,0.8-0.3l1.7,0.8c0.3,0.1,0.6,0.1,0.8-0.1c1.1-0.8,2-1.7,2.8-2.8
												c0.2-0.2,0.2-0.6,0.1-0.8l-0.8-1.7c0.1-0.3,0.3-0.6,0.4-0.9l2-0.7c0.3-0.1,0.6-0.4,0.6-0.8V9.9c0-0.4-0.2-0.7-0.6-0.8l-2-0.7
												c-0.1-0.3-0.2-0.6-0.4-0.9l0.8-1.7c0.1-0.3,0.1-0.6-0.1-0.8c-0.8-1.1-1.7-2-2.8-2.8C31.5,2,31.2,2,30.9,2.1l-1.7,0.8
												c-0.3-0.1-0.6-0.3-0.9-0.4l-0.7-2C27.5,0.2,27.2,0,26.8,0h-3.6c-0.4,0-0.7,0.2-0.8,0.6l-0.7,2l-0.9,0.4l-1.8-0.8
												C18.8,2,18.5,2,18.3,2.2c-1.1,0.8-2,1.7-2.8,2.8c-0.2,0.2-0.2,0.6-0.1,0.8l0.8,1.8l-0.4,0.9l-2,0.7c-0.3,0.1-0.6,0.4-0.6,0.8v3.6
												c0,0.4,0.2,0.7,0.6,0.8l2,0.7l0.4,0.9l-0.8,1.8c-0.1,0.3-0.1,0.6,0.1,0.8c0.8,1.1,1.7,2,2.8,2.8c0.2,0.2,0.6,0.2,0.8,0.1l1.8-0.8
												l0.8,0.3v4.5c-0.2,0.1-0.4,0.3-0.6,0.4c-2,1.8-2.5,4.7-1.3,7c-2.4,0.7-4,2.8-4,5.3v11.2c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h16.7
												c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V37.9C34.2,35.5,32.5,33.4,30.2,32.7z M27.1,16.2c-0.3,0.1-0.5,0.4-0.5,0.8v7.5
												c-0.3-0.1-0.7-0.2-1-0.2c-0.8-0.1-1.6,0-2.3,0.2v-7.5c0-0.3-0.2-0.6-0.5-0.8c-2.5-1.2-3.6-4.1-2.4-6.6c0.3-0.6,0.7-1.1,1.2-1.6v4.5
												c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h4.2c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V7.3c2.4,1.4,3.2,4.4,1.8,6.8
												C28.8,15,28.1,15.7,27.1,16.2z M21.2,18.7c-0.2-0.1-0.5-0.1-0.7,0l-1.7,0.8c-0.6-0.5-1.2-1.1-1.7-1.7l0.8-1.7c0.1-0.2,0.1-0.5,0-0.7
												l-0.6-1.6c-0.1-0.2-0.3-0.4-0.5-0.5L15,12.9v-2.4l1.8-0.6c0.2-0.1,0.4-0.2,0.5-0.5l0.6-1.6c0.1-0.2,0.1-0.5,0-0.7l-0.8-1.7
												c0.5-0.6,1.1-1.2,1.7-1.7l1.7,0.8c0.2,0.1,0.5,0.1,0.7,0L22.7,4c0.2-0.1,0.4-0.3,0.5-0.5l0.6-1.8h2.4l0.6,1.8
												c0.1,0.3,0.3,0.5,0.5,0.5c0.5,0.1,1,0.3,1.4,0.6c0.2,0.1,0.5,0.1,0.7,0l1.7-0.8c0.6,0.5,1.2,1.1,1.7,1.7l-0.8,1.7
												c-0.1,0.2-0.1,0.5,0,0.7c0.2,0.5,0.4,0.9,0.6,1.4c0.1,0.3,0.3,0.5,0.5,0.5l1.8,0.6v2.4l-1.8,0.6c-0.3,0.1-0.5,0.3-0.5,0.5
												c-0.1,0.5-0.3,1-0.6,1.4c-0.1,0.2-0.1,0.5,0,0.7l0.8,1.7c-0.5,0.6-1.1,1.2-1.7,1.7l-1.7-0.8c-0.2-0.1-0.5-0.1-0.7,0
												c-0.1,0.1-0.3,0.1-0.4,0.2v-1.5c3.2-1.8,4.3-5.9,2.4-9.1c-0.9-1.5-2.2-2.6-3.9-3.1c-0.4-0.1-0.9,0.1-1,0.6c0,0.1,0,0.2,0,0.2v5.6
												h-2.5V6.4c0-0.5-0.4-0.8-0.8-0.8c-0.1,0-0.2,0-0.4,0.1c-3.3,1.6-4.7,5.5-3.2,8.9c0.6,1.2,1.5,2.2,2.7,2.9v1.5L21.2,18.7z M22.2,26.9
												c0.9-0.8,2.1-1.2,3.3-1c1.9,0.2,3.3,1.7,3.6,3.5c0.2,1.2-0.2,2.5-1,3.4C28,32.9,28,33,27.9,33.1L25,34.9l-2.9-1.8
												c0-0.1-0.1-0.2-0.2-0.3C20.4,31.1,20.5,28.4,22.2,26.9C22.2,26.9,22.2,26.9,22.2,26.9L22.2,26.9z M26.6,38.4L25,43.2l-1.6-4.8l1-2
												l0.2,0.1c0.3,0.2,0.6,0.2,0.9,0l0.2-0.1L26.6,38.4z M32.5,48.3h-15V37.9c0-1.9,1.4-3.4,3.2-3.7l2.3,1.3L21.8,38
												c-0.1,0.2-0.1,0.4,0,0.6l2.5,7.5c0.1,0.4,0.6,0.7,1.1,0.5c0.2-0.1,0.4-0.3,0.5-0.5l2.5-7.5c0.1-0.2,0.1-0.4,0-0.6L27,35.6l2.3-1.3
												c1.8,0.3,3.2,1.9,3.2,3.7V48.3z"
							></path>
							<path
								d="M45.8,17.5h-4.9c-2.3,0-4.2,1.9-4.2,4.2v2.8l-4.5,2.3c-0.4,0.2-0.6,0.7-0.4,1.1c0.1,0.2,0.3,0.4,0.6,0.4l4.3,0.7v3.4
												c0,2.3,1.9,4.2,4.2,4.2h4.9c2.3,0,4.2-1.9,4.2-4.2V21.7C50,19.4,48.1,17.5,45.8,17.5z M48.3,32.4c0,1.4-1.1,2.6-2.6,2.6h-4.9
												c-1.4,0-2.6-1.1-2.6-2.6v-4.1c0-0.4-0.3-0.8-0.7-0.8l-2.5-0.4l2.7-1.4c0.3-0.1,0.5-0.4,0.5-0.7v-3.3c0-1.4,1.1-2.6,2.6-2.6h4.9
												c1.4,0,2.6,1.1,2.6,2.6V32.4z"
							></path>
							<path
								d="M13.3,29l4.3-0.7c0.5-0.1,0.8-0.5,0.7-1c0-0.3-0.2-0.5-0.4-0.6l-4.5-2.3v-2.8c0-2.3-1.9-4.2-4.2-4.2H4.2
												c-2.3,0-4.2,1.9-4.2,4.2v10.7c0,2.3,1.9,4.2,4.2,4.2h4.9c2.3,0,4.2-1.9,4.2-4.2V29z M11.7,28.3v4.1c0,1.4-1.1,2.6-2.6,2.6H4.2
												c-1.4,0-2.6-1.1-2.6-2.6V21.7c0-1.4,1.1-2.6,2.6-2.6h4.9c1.4,0,2.6,1.1,2.6,2.6V25c0,0.3,0.2,0.6,0.5,0.7l2.7,1.4l-2.5,0.4
												C12,27.6,11.7,27.9,11.7,28.3z"
							></path>
							<path
								d="M43.3,20.8c-1.8,0-3.3,1.5-3.3,3.3h1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
												c-0.5,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0V30h1.7v-2.6c1.8-0.5,2.9-2.3,2.4-4.1C46.2,21.9,44.9,20.8,43.3,20.8z"
							></path>
							<rect x="42.5" y="31.7" width="1.7" height="1.7"></rect>
							<path
								d="M10,23.3c0-1.4-1.1-2.5-2.5-2.5h-1c-1.7,0-3.1,1.4-3.1,3.1v6.2c0,1.7,1.4,3.1,3.1,3.1h1c1.4,0,2.5-1.1,2.5-2.5
												c0-1.4-1.1-2.5-2.5-2.5v-2.5C8.9,25.8,10,24.7,10,23.3z M6.7,30h0.8c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8h-1
												C5.7,31.7,5,31,5,30.2V24c0-0.8,0.7-1.5,1.5-1.5h1c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8H6.7c-0.5,0-0.8,0.4-0.8,0.8
												c0,0,0,0,0,0v4.2C5.8,29.6,6.2,30,6.7,30C6.7,30,6.7,30,6.7,30z"
							></path>
							<rect x="5.8" y="38.3" width="1.7" height="1.7"></rect>
							<rect x="5.8" y="41.7" width="1.7" height="1.7"></rect>
							<rect x="5.8" y="45" width="1.7" height="1.7"></rect>
							<rect x="9.2" y="45" width="1.7" height="1.7"></rect>
							<rect x="12.5" y="45" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="38.3" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="41.7" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="45" width="1.7" height="1.7"></rect>
							<rect x="39.2" y="45" width="1.7" height="1.7"></rect>
							<rect x="35.8" y="45" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="14.2" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="10.8" width="1.7" height="1.7"></rect>
							<rect x="42.5" y="7.5" width="1.7" height="1.7"></rect>
							<rect x="39.2" y="7.5" width="1.7" height="1.7"></rect>
							<rect x="5.8" y="14.2" width="1.7" height="1.7"></rect>
							<rect x="5.8" y="10.8" width="1.7" height="1.7"></rect>
							<rect x="5.8" y="7.5" width="1.7" height="1.7"></rect>
							<rect x="9.2" y="7.5" width="1.7" height="1.7"></rect>
						</svg>
					`
				},
				{
					title: 'Save time',
					text: 'Save time and money by getting started with a lean working model of the product before fleshing out the entire software, UI, design and user experience.',
					svg: `
						<svg
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<path
								d="M2.8,47.1c0.1,0.5,0.6,0.8,1,0.7l7.3-1.3c0.5-0.1,0.8-0.6,0.7-1l-0.1-0.8l1.7-1.4c0.7-0.6,1.5-0.8,2.4-0.8l12,0.9
												c0,0,0,0,0.1,0H28c2.6,0,5.2-0.8,7.3-2.4c0,0,0.1-0.1,0.1-0.1l14.3-14c0.3-0.3,0.4-0.9,0.1-1.2c-1.6-1.9-4.5-2.2-6.5-0.6L36.8,30
												c-1.4-1.7-3.5-2.5-5.6-2.1l-6.7,1.2c-1.1,0.2-2.2,0.1-3.2-0.2l-0.7-0.2c-3.8-1.1-8-0.2-11.1,2.3l-0.3-1.6c-0.1-0.5-0.6-0.8-1-0.7
												L0.7,30c-0.5,0.1-0.8,0.6-0.7,1L2.8,47.1z M20,30.5l0.7,0.2c1.3,0.4,2.7,0.4,4,0.2l6.7-1.2c1.9-0.3,3.8,0.7,4.6,2.5L26,34
												c-0.5,0.1-0.8,0.5-0.7,1c0,0.5,0.4,0.8,0.9,0.8c0.1,0,0.1,0,0.2,0l11-1.9c0.5-0.1,0.8-0.6,0.7-1c-0.1-0.4-0.2-0.8-0.3-1.2l6.7-5
												c0,0,0,0,0,0c1-0.8,2.3-0.8,3.4-0.2L34.3,39.6c-1.8,1.3-4,2-6.2,2h0L16,40.8c-1.3-0.1-2.6,0.3-3.7,1.2l-0.9,0.8l-1.7-9.6
												C12.4,30.4,16.4,29.4,20,30.5z M7.5,30.6L10,45l-5.6,1L1.9,31.6L7.5,30.6z"
							></path>
							<path
								d="M27.2,26.6c6.7,0,12.2-5.5,12.2-12.2S34,2.1,27.2,2.1C20.5,2.1,15,7.6,15,14.3C15,21.1,20.5,26.5,27.2,26.6z M27.2,3.9
												c5.8,0,10.4,4.7,10.4,10.4S33,24.8,27.2,24.8c-5.8,0-10.4-4.7-10.4-10.4C16.8,8.6,21.5,3.9,27.2,3.9z"
							></path>
							<path
								d="M26.6,16.2h5c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-4.2V8.7c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v6.6
												C25.7,15.8,26.1,16.2,26.6,16.2z"
							></path>
						</svg>
					`
				},
				{
					title: 'Test market feasibility',
					text: 'MVP development lets you estimate market size, competition, USPs, challenges and overall market feasibility before taking the deep dive.',
					svg: `
						<svg
							x="0px"
							y="0px"
							viewBox="0 0 50 50"
							style="enable-background: new 0 0 50 50"
							xml:space="preserve"
						>
							<g>
								<rect
									x="40.9"
									y="44.2"
									transform="matrix(0.8481 -0.5298 0.5298 0.8481 -17.4005 28.7571)"
									width="1.1"
									height="1.1"
								></rect>
								<rect
									x="42.1"
									y="46.1"
									transform="matrix(0.8479 -0.5301 0.5301 0.8479 -18.2485 29.7099)"
									width="1.1"
									height="1.1"
								></rect>
								<rect x="42.7" y="4.3" width="1.1" height="1.1"></rect>
								<rect x="39.8" y="4.3" width="1.1" height="1.1"></rect>
								<rect x="36.9" y="4.3" width="1.1" height="1.1"></rect>
								<rect x="7.3" y="4.3" width="25.3" height="1.1"></rect>
								<path
									d="M47.3,31.8V1.4H2.7v30.4H0v2.3l2.1,2.7h21.6c1.5,1.8,3.7,3.1,6.2,3.6c2.1,0.4,4.2,0.2,6.2-0.6l4.6,8.6
														c0.5,0.9,1.4,1.5,2.4,1.5l0,0c0.5,0,1.1-0.2,1.5-0.5c0.6-0.4,1.1-1.1,1.2-1.8c0.2-0.8-0.1-1.5-0.5-2.1l-5.8-7.8
														c0.3-0.2,0.5-0.5,0.8-0.8h7.6l2.1-2.7v-2.3H47.3z M46.1,2.5v4.4H3.8V2.5H46.1z M3.8,8h42.3v23.7h-3.5c0-0.2,0.1-0.2,0.1-0.4
														c0.8-5.4-2.5-10.6-7.8-12.1c-1-0.3-2-0.5-3.1-0.5c-5.7,0-10.4,4.4-10.9,10.1c-0.1,1,0,2,0.2,2.9H3.8V8z M2.7,35.8l-1.5-2.1v-0.8
														h20.4c0.3,1,0.8,1.9,1.3,2.7L2.7,35.8L2.7,35.8z M44.3,46.2c0.3,0.4,0.4,0.8,0.3,1.2c-0.1,0.5-0.3,0.8-0.7,1.1
														c-0.2,0.2-0.5,0.2-0.8,0.2c-0.6,0-1.1-0.3-1.5-0.8l-5.1-9.5L36,38.7c-1.8,0.9-3.9,1.1-6,0.8c-4.8-0.9-8.2-5.5-7.9-10.3
														c0.5-5.1,4.6-9,9.8-9c0.9,0,1.8,0.2,2.7,0.4c4.7,1.4,7.7,6,6.9,10.9c-0.4,2.3-1.5,4.4-3.3,6l-0.5,0.2L44.3,46.2z M48.9,33.7
														l-1.5,2.1h-6.2c0.5-0.8,1-1.8,1.3-2.7h6.5L48.9,33.7L48.9,33.7z"
								></path>
								<path
									d="M32.4,29.7h6.4c0.2,0,0.3-0.1,0.5-0.2c0.1-0.2,0.2-0.3,0.2-0.5c-0.4-3.7-3.3-6.5-6.9-6.8c-0.2,0-0.3,0.1-0.5,0.2
														c-0.2,0.1-0.2,0.2-0.2,0.4v6.3C31.8,29.5,32.1,29.7,32.4,29.7z M33,23.5c2.6,0.5,4.7,2.4,5.2,5H33V23.5z"
								></path>
								<path
									d="M39.1,30.9c-0.1-0.2-0.3-0.2-0.5-0.2h-6c-0.2,0-0.5,0.2-0.5,0.4s-0.1,0.5,0.2,0.6l3.7,3.8c0.1,0.1,0.2,0.2,0.4,0.2
														s0.3-0.1,0.4-0.2c1.2-1.1,2.1-2.5,2.4-4.1C39.2,31.2,39.2,30.9,39.1,30.9z M36.5,34.2l-2.4-2.4h3.8C37.6,32.7,37.2,33.5,36.5,34.2z
														"
								></path>
								<path
									d="M34.7,35.5c-1.1,0.5-2.4,0.8-3.7,0.5c-2.7-0.4-4.9-2.6-5.3-5.3c-0.5-3.4,1.7-6.6,5-7.2c0.3-0.1,0.5-0.4,0.5-0.7
														s-0.4-0.5-0.7-0.5c-3.9,0.8-6.6,4.5-6,8.5c0.5,3.2,3.1,5.8,6.3,6.3c0.4,0.1,0.8,0.1,1.1,0.1c1.1,0,2.2-0.2,3.3-0.8
														c0.3-0.2,0.4-0.5,0.2-0.8C35.3,35.5,35,35.4,34.7,35.5z"
								></path>
								<path
									d="M21,18.3c0.2,0.9,1.1,1.5,2.1,1.5c1.1,0,2.1-1,2.1-2.1c0-0.4-0.1-0.8-0.3-1.1l4.8-4.8v2.4h1.1V9.8h-4.4v1.1h2.4L24,15.8
														c-0.3-0.2-0.7-0.3-1.1-0.3c-1,0-1.8,0.7-2.1,1.6h-4.3c-0.2-0.9-1.1-1.6-2.1-1.6c-1.1,0-2.1,1-2.1,2.1c0,0.4,0.1,0.8,0.3,1.1
														l-3.9,3.9c-0.3-0.2-0.7-0.3-1.1-0.3c-1.1,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1s2.1-1,2.1-2.1c0-0.4-0.1-0.7-0.3-1l3.9-3.9
														c0.3,0.2,0.7,0.3,1,0.3c0.9,0,1.8-0.6,2.1-1.5H21z M23,16.7c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.7c0,0.5-0.5,1-1,1
														c-0.5,0-1-0.5-1-1C22,17.1,22.4,16.7,23,16.7z M7.9,25.4c-0.5,0-1-0.5-1-1s0.5-1,1-1c0.5,0,1,0.5,1,1C8.9,25,8.4,25.4,7.9,25.4z
														M14.7,18.7c-0.2,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.5,0.5-1,1-1c0.5,0,1,0.5,1,1C15.6,18.2,15.2,18.7,14.7,18.7z"
								></path>
							</g>
						</svg>
					`
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}

.mvp-after-before-section {
	margin-bottom: 60px;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.o-hidden {
	overflow: hidden;
}
.twentytwenty-container {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	overflow: hidden;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
}
.twentytwenty-container:hover .twentytwenty-overlay {
	opacity: 0.5;
}
.twentytwenty-container:hover .twentytwenty-after-label {
	background: #777;
}
.twentytwenty-container:hover .twentytwenty-after-label::before,
.twentytwenty-container:hover .twentytwenty-after-label::after {
	opacity: 1;
}

.twentytwenty-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.3s;
	background: #333;
	opacity: 0;
	z-index: 2;
}
.twentytwenty-before-label {
	width: 5px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 50%;
	transition: 0.3s;
	transform: translateX(-50%);
	background: #fff;
	opacity: 1;
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	z-index: 4;
}
.twentytwenty-after-label {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: 0.3s;
	transform: translate(-50%, -50%);
	background: #fff;
	border-radius: 50%;
	border: 5px solid #fff;
	opacity: 1;
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	z-index: 5;
}
.twentytwenty-container img {
	position: absolute;
	top: 0;
	display: block;
}
.twentytwenty-after-label::before,
.twentytwenty-after-label::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border: 6px solid transparent;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 99;
	opacity: 0;
	transition: 0.3s;
}
.twentytwenty-after-label::before {
	border-left-color: #fff;
	right: -7px;
}
.twentytwenty-after-label::after {
	border-right-color: #fff;
	left: 5px;
}

.twentytwenty-container * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border-style: none;
}
.mvp-benefits-section .our-core-value-box {
    background-color: #f8f9fa;
}

</style>
