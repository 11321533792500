<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Full Stack Tools</span>
				<h2 class="fxt-process-dev">AR Application Development Technology Stack</h2>
				<p>
					As a leading custom Augmented Reality app development company, technology matters to us.
					We focus on delivering high-quality digital solutions with the use of cutting edge tools.
				</p>
			</div>
			<div class="row align-items-center justify-content-center">
				<div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-30">
					<div
						class="types-of-ar-platform-box box-shadow rounded10 d-flex align-items-center bg-white o-hidden"
					>
						<img
							class="lazy"
							alt="Augmented Reality App Development London"
							src="../../../assets/images/type-of-platform-unity.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-30">
					<div
						class="types-of-ar-platform-box box-shadow rounded10 d-flex align-items-center bg-white o-hidden"
					>
						<img
							class="lazy"
							alt="Augmented Reality Developers London"
							src="../../../assets/images/type-of-platform-ar-kit.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-30">
					<div
						class="types-of-ar-platform-box box-shadow rounded10 d-flex align-items-center bg-white o-hidden"
					>
						<img
							class="lazy"
							alt="Augmented Reality Mobile App Development"
							src="../../../assets/images/type-of-platform-arcore.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-30">
					<div
						class="types-of-ar-platform-box box-shadow rounded10 d-flex align-items-center bg-white o-hidden"
					>
						<img
							class="lazy"
							alt="Augmented Reality Development Companies"
							src="../../../assets/images/type-of-platform-vuforia.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-30">
					<div
						class="types-of-ar-platform-box box-shadow rounded10 d-flex align-items-center bg-white o-hidden"
					>
						<img
							class="lazy"
							alt="Augmented Reality Development Company"
							src="../../../assets/images/type-of-platform-wikitude.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
  min-height:190px;
  padding:10px 20px;
}
</style>
