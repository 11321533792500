<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>What makes us Android Specialist</span>
				<h2 class="fxt-process-dev">Why Choose Our Expert Android App Developers?</h2>
				<p>
					Your custom android application development requires a team of developers that has proven
					expertise in developing android applications and has a perfect knowledge of your business
					and customers. We are one of the leading android app development companies in UK,
					specialising in the android app design and development
				</p>
			</div>
			<div class="row">
				<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
					<MiniCard v-bind:obj="item"></MiniCard>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import MiniCard from '../AssetComponents/MiniCard';

export default {
	components: {
		MiniCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Online  Shopping',
					text: 'Create a stunning eCommerce android application to manifold your business growth.',
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M0,91.9c0,4.5,3.6,8.1,8.1,8.1H50c4.5,0,8.1-3.6,8.1-8.1v-8.1H0V91.9z M3.2,87.2h51.6v4.7c0,2.7-2.2,4.9-4.9,4.9H8.1
                           c-2.7,0-4.9-2.2-4.9-4.9V87.2z M3.2,12.8h54.9V8.1C58.1,3.6,54.5,0,50,0H8.1C3.6,0,0,3.6,0,8.1v72.6h3.2V12.8z M3.2,8.1
                           c0-2.7,2.2-4.9,4.9-4.9H50c2.7,0,4.9,2.2,4.9,4.9v1.6H3.2V8.1z M35.4,90.3H22.6v3.2h12.8V90.3z M45.1,48.4c0-4.3-1.4-8.5-4.1-11.9
                           c-3.6-4.7-9.3-7.4-15.3-7.4c-10.7,0-19.3,8.6-19.3,19.3s8.6,19.3,19.3,19.3C36.5,67.7,45.1,59.1,45.1,48.4z M9.7,48.4
                           c0-8.9,7.3-16.1,16.1-16.1c5,0,9.6,2.3,12.7,6.2c2.3,2.8,3.4,6.4,3.4,9.9c0,8.9-7.3,16.1-16.1,16.1C17,64.5,9.7,57.3,9.7,48.4z
                           M27.4,61.4v-3.2c2.7-0.4,4.9-2.7,4.9-5.5c0-3.1-2.6-5.7-5.7-5.7H25c-1.4,0-2.4-1.1-2.4-2.4S23.6,42,25,42h1.6
                           c1.4,0,2.4,1.1,2.4,2.4v0.8h3.2v-0.8c0-2.8-2.2-5.1-4.9-5.5v-3.2h-3.2v3.2c-2.7,0.4-4.9,2.7-4.9,5.5c0,3.1,2.6,5.7,5.7,5.7h1.6
                           c1.4,0,2.4,1.1,2.4,2.4S28,55,26.6,55H25c-1.4,0-2.4-1.1-2.4-2.4v-0.8h-3.2v0.8c0,2.8,2.2,5.1,4.9,5.5v3.2H27.4z M88.6,32.3v-6.5
                           c0-6.2-5-11.4-11.4-11.4c-6.4,0-11.4,5-11.4,11.4v6.5H54.6v38.6H100V32.3H88.6z M87,41.9c0.9,0,1.6,0.8,1.6,1.6
                           c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C85.4,42.6,86.1,41.9,87,41.9z M69.3,25.8c0-4.5,3.6-8.1,8.1-8.1
                           c4.5,0,8.1,3.6,8.1,8.1v6.5H69.3V25.8z M67.7,41.9c0.9,0,1.6,0.8,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                           C66.1,42.6,66.8,41.9,67.7,41.9z M96.8,67.7H58.1V35.5h8.1v3.5c-1.9,0.7-3.2,2.4-3.2,4.6c0,2.7,2.2,4.9,4.9,4.9
                           c2.7,0,4.9-2.2,4.9-4.9c0-2.2-1.4-3.9-3.2-4.6v-3.5h16.1v3.5c-1.9,0.7-3.2,2.4-3.2,4.6c0,2.7,2.2,4.9,4.9,4.9
                           c2.7,0,4.9-2.2,4.9-4.9c0-2.2-1.4-3.9-3.2-4.6v-3.5h8.1L96.8,67.7z M62.8,22.6H40.1l4.5-3.5l-2-2.6l-9.7,7.7l9.7,7.7l2-2.6
                           l-4.5-3.5h22.7V22.6z M38.1,81.9l9.6-7.7l-9.6-7.7l-2,2.6l4.5,3.5H17.7v3.2h22.8l-4.5,3.5L38.1,81.9z"></path>
                        </g>
                     </g>
                  </svg>`
				},
				{
					title: 'IOT Applications',
					text: 'Move ahead with time while creating android applications for internet of things as you step into the future with our android app developers.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <path class="st0" d="M65.6,41.2c-2.8,0-5.4,1.4-6.9,3.6l-11.9-5.4c0.2-0.9,0.4-1.8,0.4-2.7c0-3.8-2.2-7.1-5.3-8.8l4.3-12.6
                     c0.3,0,0.7,0.1,1,0.1c4.2,0,7.6-3.4,7.6-7.6s-3.4-7.6-7.6-7.6c-4.2,0-7.6,3.4-7.6,7.6c0,2.8,1.5,5.2,3.8,6.6L39,26.9
                     c-0.6-0.1-1.1-0.2-1.7-0.2c-3,0-5.7,1.3-7.5,3.4l-14.9-9.2c0.2-0.6,0.3-1.2,0.3-1.8c0-3.4-2.7-6.1-6.1-6.1s-6.1,2.7-6.1,6.1
                     c0,3.4,2.7,6.1,6.1,6.1c1.6,0,3.1-0.6,4.2-1.7l14.9,9.2c-0.5,1.2-0.8,2.5-0.8,3.9c0,1.6,0.4,3.2,1.1,4.5l-13.7,9.9
                     c-1.5-1.4-3.5-2.3-5.8-2.3c-4.6,0-8.4,3.7-8.4,8.4s3.8,8.4,8.4,8.4c4.6,0,8.4-3.7,8.4-8.4c0-1.3-0.3-2.5-0.8-3.6l13.7-9.9
                     c1.7,1.7,4.1,2.8,6.7,2.9l1.9,15.6C36.6,63,35,65.2,35,67.8c0,3.4,2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1c0-3.1-2.3-5.6-5.3-6L40,46.1
                     c2.3-0.6,4.3-2.1,5.6-4.1l11.9,5.4c-0.2,0.7-0.3,1.3-0.3,2.1c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.7,8.4-8.4S70.2,41.2,65.6,41.2z
                     M42.6,7.7c0-2.5,2.1-4.6,4.6-4.6s4.6,2,4.6,4.6c0,2.5-2,4.5-4.5,4.6h0c-1.1,0-2.1-0.4-2.8-1C43.3,10.4,42.6,9.1,42.6,7.7z M12,19.2
                     c0,1.1-0.7,2.1-1.6,2.6C10,22,9.5,22.1,9,22.1c-1.7,0-3.1-1.4-3.1-3s1.4-3,3.1-3S12,17.4,12,19.2C12,19.1,12,19.2,12,19.2z M9,62.5
                     c-3,0-5.4-2.4-5.4-5.3S6,51.8,9,51.8c1.2,0,2.3,0.4,3.2,1.1c0.8,0.6,1.5,1.5,1.8,2.5c0.2,0.6,0.3,1.1,0.3,1.8
                     C14.3,60.1,11.9,62.5,9,62.5z M41.1,64.7c0.4,0,0.8,0.1,1.2,0.3c1.1,0.5,1.9,1.5,1.9,2.8c0,1.7-1.4,3-3.1,3s-3.1-1.4-3.1-3
                     c0-1,0.5-1.9,1.2-2.4C39.8,65,40.4,64.7,41.1,64.7z M44,38c-0.2,1-0.7,2-1.3,2.8c-0.8,1-1.9,1.8-3.1,2.3c-0.7,0.3-1.5,0.4-2.3,0.4
                     c-0.2,0-0.5,0-0.7,0c-1.4-0.1-2.8-0.7-3.8-1.6c-0.8-0.7-1.4-1.5-1.8-2.5c-0.4-0.8-0.6-1.7-0.6-2.7c0-0.8,0.1-1.5,0.4-2.2
                     c0.3-1,0.9-1.9,1.6-2.6c1.2-1.3,3-2,4.9-2c0.2,0,0.5,0,0.7,0c1.1,0.1,2,0.5,2.9,1c2,1.2,3.3,3.4,3.3,5.8C44.2,37.1,44.1,37.5,44,38z
                     M65.6,54.9c-3,0-5.4-2.4-5.4-5.3c0-0.3,0-0.5,0.1-0.7c0.1-1,0.6-2,1.3-2.8c1-1.1,2.4-1.8,4-1.8c3,0,5.4,2.4,5.4,5.3
                     S68.5,54.9,65.6,54.9z"></path>
                  </svg>
          `
				},
				{
					title: 'UX/UI',
					text: 'Elevate your user experience with our skillfully crafted android mobile applications as it offers UI that appeals your users.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M52,15H40.5c-0.6,0-1.2,0.5-1.2,1.2v11.6c0,0.6,0.5,1.2,1.2,1.2H52c0.6,0,1.2-0.5,1.2-1.2V16.2
                           C53.2,15.5,52.7,15,52,15z M41.6,26.6V19l7.6,7.6H41.6z M50.9,25l-7.6-7.6h7.6V25z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path class="st0" d="M52,31.2H40.5c-0.6,0-1.2,0.5-1.2,1.2v11.6c0,0.6,0.5,1.2,1.2,1.2H52c0.6,0,1.2-0.5,1.2-1.2V32.4
                           C53.2,31.7,52.7,31.2,52,31.2z M41.6,42.8v-7.6l7.6,7.6H41.6z M50.9,41.1l-7.6-7.6h7.6V41.1z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="39.3" y="47.4" class="st0" width="9.3" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="50.9" y="47.4" class="st0" width="2.3" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="39.3" y="52" class="st0" width="13.9" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="39.3" y="56.7" class="st0" width="13.9" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="19.7" y="26.6" class="st0" width="11.6" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="19.7" y="35.8" class="st0" width="11.6" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="19.7" y="45.1" class="st0" width="11.6" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path class="st0" d="M67.1,8.1c-0.6,0-1.2,0.5-1.2,1.2v4.6H59V2.3C59,1,57.9,0,56.7,0H17.3C16.1,0,15,1,15,2.3v11.6H8.1V9.3
                           c0-0.6-0.5-1.2-1.2-1.2C6.9,8.1,0,8.2,0,17.3v45.1c0,4.4,4.4,6.5,6.7,6.9c0.1,0,0.2,0,0.2,0H15v2.3c0,1.3,1,2.3,2.3,2.3h39.3
                           c1.3,0,2.3-1,2.3-2.3v-2.3h8.1c0.1,0,0.2,0,0.2,0c2.3-0.5,6.7-2.5,6.7-6.9V17.3C74,8.2,67.1,8.1,67.1,8.1z M2.3,17.3
                           c0-4.7,2.1-6.2,3.5-6.7v45.1c-1.3,0.4-2.4,1-3.5,1.9V17.3z M15,67.1h-8c-0.7-0.2-4.8-1.3-4.8-4.6c0-3.6,4.7-4.6,4.9-4.6
                           c0.5-0.1,0.9-0.6,0.9-1.1V16.2H15V67.1z M17.3,12.7h13.3L17.3,21V12.7z M33.5,13.7V22H20.2L33.5,13.7z M17.3,24.3h16.2v6.9H17.3
                           V24.3z M17.3,33.5h16.2v6.9H17.3V33.5z M17.3,42.8h16.2v6.9H17.3V42.8z M17.3,52H31l-13.6,9.4V52z M33.5,53.1v9.4H19.9L33.5,53.1z
                           M56.7,71.7H17.3v-6.9h39.3V71.7z M56.7,62.4H35.8V12.7h20.8V62.4z M56.7,10.4H17.3V2.3h39.3V10.4z M66.9,67.1h-8V16.2h6.9v40.5
                           c0,0.5,0.4,1,0.9,1.1c0.1,0,4.9,1,4.9,4.6C71.7,65.8,67.7,66.9,66.9,67.1z M71.7,57.6c-1-0.8-2.2-1.5-3.5-1.9V10.6
                           c1.4,0.5,3.5,2,3.5,6.7V57.6z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="28.9" y="5.8" class="st0" width="9.3" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="40.5" y="5.8" class="st0" width="2.3" height="2.3"></rect>
                        </g>
                     </g>
                     <g>
                        <g>
                           <rect x="45.1" y="5.8" class="st0" width="2.3" height="2.3"></rect>
                        </g>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Custom Implementation',
					text: 'Our android app development process include custom implementation on your organizational infrastructure or Google Play store.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M27.9,59l11,8c0.4,0.3,0.7,0.4,1.1,0.4c0.5,0,1.1-0.3,1.5-0.7c0.5-0.8,0.4-1.9-0.4-2.6l-10.3-7.5l10.1-7.5
                           c0.9-0.8,1.1-1.9,0.5-2.8c-0.5-0.8-1.6-1-2.6-0.4l-11,8c-0.8,0.6-1.4,1.5-1.4,2.5S27.1,58.5,27.9,59z M44,69.7
                           c0.3,0.1,0.5,0.1,0.7,0.1c0.7,0,1.4-0.4,1.6-1.1l10.4-23c0.7-0.8,0.3-1.8-0.7-2.4c-0.9-0.6-1.9,0-2.4,0.8l-10.4,23
                           C42.6,68.2,43.2,69.1,44,69.7z M58.6,66.7c0.4,0.6,0.9,0.7,1.5,0.7c0.4,0,0.7-0.1,1.1-0.4l11-8c0.8-0.6,1.4-1.5,1.4-2.5
                           c0-0.8-0.4-1.8-1.4-2.4l-11-8c-0.8-0.6-1.9-0.4-2.6,0.4c-0.5,0.8-0.4,1.9,0.4,2.6l10.1,7.5L59,64C58.2,64.6,58.1,65.7,58.6,66.7z
                           M98.2,17.4H1.8c-0.9,0-1.8,0.8-1.8,1.8v61.6c0,1,0.8,1.8,1.8,1.8h96.5c0.9,0,1.8-0.8,1.8-1.8V19.2C100,18.2,99.2,17.4,98.2,17.4z
                           M96.5,78.9h-93v-48h93V78.9z M96.5,27.1h-93V21h93V27.1z M6.5,25.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8
                           C4.7,25,5.5,25.9,6.5,25.9z M11.4,25.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C9.6,25,10.4,25.9,11.4,25.9
                           z M16.1,25.9c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8C14.3,25,15.1,25.9,16.1,25.9z"></path>
                        </g>
                     </g>
                  </svg>`
				},
				{
					title: 'Cloud integration',
					text: 'Get rid of the excessive data space with our android app cloud integration offering you quick and reliable cloud services for android apps',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M64.6,59.9V34.6c6.4-4.8,4.4-15-3.4-17.1c-0.9-5.3-5.7-8.8-10.4-8.8C46.3-2.7,29.7-2.6,25.5,8.9
                           c-6.2-1.1-11.8,3-12.8,8.7c-7.2,2.5-8.9,12.1-2.8,16.9v25.3L6.1,72.3c-0.2,0.7,0.3,1.5,1,1.5h60l0,0c0.9,0,1.4-0.9,1-1.7
                           L64.6,59.9z M27.7,38.8l5.7,6.7c0.5,0.6,1.3,0.6,1.7,0l5.7-6.7c0.7-0.7,0.1-1.8-0.9-1.8h-1.5V18.8c0-0.7-0.5-1.1-1.2-1.1H37
                           l3.1-3.7l3.1,3.7H43c-0.7,0-1.2,0.5-1.2,1.1v9.6c0,0.7,0.5,1.1,1.2,1.1h16c1.7,0,3.2,1.4,3.2,3.2v26.1H47.2
                           c-0.3,0-0.7,0.2-0.9,0.5l-1.4,2.1H29.8l-1.4-2.1c-0.2-0.3-0.6-0.5-0.9-0.5H12.4V32.7c0-1.7,1.4-3.2,3.2-3.2h14.9v7.3h-1.6
                           C27.6,36.8,27,38,27.7,38.8z M14,19.7c0.5-0.1,0.8-0.6,0.8-1c0.5-5.2,5.6-8.8,11-7.2c0.6,0.2,1.3-0.1,1.4-0.8
                           c3-10.4,18.1-10.8,21.6-0.3c0.1,0.6,0.7,0.8,1.3,0.8c3.9-0.5,8.5,2.8,8.9,7.6c0,0.5,0.5,0.9,0.9,1c5.8,1,7.9,7.9,4.5,11.9
                           c-0.5-2.5-2.8-4.4-5.5-4.4H44.2v-7.3h1.6c1,0,1.5-1.1,0.9-1.8L41,11.4c-0.5-0.6-1.3-0.6-1.7,0l-5.7,6.7c-0.7,0.7-0.1,1.8,0.9,1.8
                           H36v18c0,0.7,0.5,1.1,1.2,1.1h0.2l-3.1,3.7l-3.1-3.7h0.2c0.7,0,1.2-0.5,1.2-1.1v-9.6c0-0.7-0.5-1.1-1.2-1.1h-16
                           c-2.7,0-4.9,1.8-5.5,4.4C6.7,27.5,8.7,21.2,14,19.7z M8.8,71.5l3.1-10.2h14.7l1.4,2.1c0.2,0.3,0.6,0.5,0.9,0.5h16.4
                           c0.3,0,0.7-0.2,0.9-0.5l1.4-2.1h14.7l3.1,10.2H8.8z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M27.7,34.7c0.7,0,1.2-0.5,1.2-1.1s-0.5-1.1-1.2-1.1H16.4c-0.7,0-1.2,0.5-1.2,1.1v21.2c0,0.7,0.5,1.1,1.2,1.1
                           h41.7c0.7,0,1.2-0.5,1.2-1.1V33.6c0-0.7-0.5-1.1-1.2-1.1H43c-0.7,0-1.2,0.5-1.2,1.1s0.5,1.1,1.2,1.1h13.9v18.9H17.6V34.7H27.7z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M50.9,66.3H23.4c-0.7,0-1.2,0.5-1.2,1.1s0.5,1.1,1.2,1.1h27.5c0.7,0,1.2-0.5,1.2-1.1
                           C52.1,66.9,51.6,66.3,50.9,66.3z"></path>
                        </g>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Wearable Applications',
					text: 'Create a wearable android application to widen your scope of sales cater to larger sets of audience.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M74,45.6c0-1.7-0.8-3.2-2.1-4.2c1.3-0.9,2.1-2.5,2.1-4.2c0-2.3-1.5-4.4-3.7-5.1c0.8-0.9,1.3-2.1,1.3-3.4
                           c0-2.9-2.4-5.3-5.3-5.3c-7.9,0.5-18.5-1.7-31.6,3.9c-1.2,0.5-2,0.8-3.1,0.8c-0.2-0.1,1.7,0-30.3,0c-0.6,0-1.2,0.5-1.2,1.2
                           c0,0.6,0.5,1.2,1.2,1.2H8v1.9H5.4c-0.6,0-1.2,0.5-1.2,1.2v15.7c0,0.6,0.5,1.2,1.2,1.2h2.4v2H1.2c-0.6,0-1.2,0.5-1.2,1.2
                           c0,0.6,0.5,1.2,1.2,1.2c23.5,0,22.4,0,22.5,0c5.8-0.7,8.8,1.5,10.4,3.6c2,2.5,3.9,4.4,6,5.8c5.1,3.2,9.1,3.9,11.8,3.9
                           c1.8,0,3-0.3,3.1-0.3c7.9-2.2,9.6-5.9,9.8-7.9h3.8c2.9,0,5.3-2.4,5.3-5.3c0-1.7-0.8-3.2-2.1-4.2C73.2,48.9,74,47.4,74,45.6z
                           M10.1,30.5h6.5v1.9h-6.5V30.5z M6.6,34.7h13.5v13.5H6.6V34.7z M16.5,50.4v1.8h-6.5v-1.8H16.5z M59,51.1h9.7
                           c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1H59c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h3.7c-0.3,1.4-1.8,4-8.2,5.8
                           c0,0-5.3,1.6-13.1-3.2c-2-1.3-3.7-2.9-5.4-5.2c-1.6-2.1-5.2-5.3-12.4-4.4H19v-2h2.4c0.6,0,1.2-0.5,1.2-1.2v-6.9h1.4v0.3
                           c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2V40c0-0.6-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v0.2h-1.4v-6.7
                           c0-0.6-0.5-1.2-1.2-1.2H19v-1.9c15,0.1,14.2,0.1,16.8-1c12.6-5.5,22.9-3.4,30.6-3.8c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1
                           c-0.2,0-10.4,0-9.7,0c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2c0.3,0,13.2,0,12.1,0c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1
                           H59c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h9.7c1.7,0,3.1,1.4,3.1,3.1S70.4,49,68.7,49H59c-0.6,0-1.2,0.5-1.2,1.2
                           C57.8,50.5,58.3,51.1,59,51.1z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M2.5,12.5c2.9-2.9,6.7-4.5,10.8-4.5s7.9,1.6,10.8,4.4c0.5,0.5,1.2,0.5,1.6,0c0.5-0.5,0.5-1.2,0-1.6
                           C22.4,7.5,18,5.6,13.3,5.6c-4.6,0-9,1.8-12.4,5.1c-0.5,0.5-0.5,1.2,0,1.6C1.4,12.8,2.1,12.8,2.5,12.5z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M22.7,16.3c0.5-0.5,0.5-1.2,0-1.6C17.5,9.4,9,9.4,3.8,14.7c-0.5,0.5-0.5,1.2,0,1.6c0.5,0.5,1.2,0.5,1.6,0
                           C9.7,12,16.8,12,21,16.3C21.5,16.7,22.3,16.7,22.7,16.3z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M19.8,20.2c0.5-0.5,0.5-1.2,0-1.6c-1.7-1.7-4-2.7-6.5-2.7s-4.7,0.9-6.5,2.7c-0.5,0.5-0.5,1.2,0,1.6
                           s1.2,0.5,1.6,0c1.3-1.3,3-2,4.9-2s3.6,0.7,4.9,2C18.6,20.5,19.3,20.5,19.8,20.2z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M18,39.2l-2.5-0.3l-1.2-2.3c-0.3-0.8-1.6-0.8-2,0l-1.2,2.3l-2.5,0.3c-0.5,0.1-0.8,0.3-0.9,0.8
                           c-0.1,0.5,0,0.8,0.2,1.2L9.8,43l-0.5,2.5c-0.1,0.5,0.1,0.8,0.5,1.2c0.3,0.2,0.8,0.2,1.2,0.1l2.3-1.3l2.3,1.3
                           c0.3,0.2,0.8,0.1,1.2-0.1c0.3-0.2,0.6-0.7,0.5-1.2L16.8,43l1.8-1.8c0.3-0.3,0.5-0.7,0.2-1.2C18.8,39.5,18.5,39.3,18,39.2z
                           M14.8,41.7c-0.2,0.2-0.3,0.6-0.3,1l0.1,0.9l-0.7-0.5c-0.1-0.1-0.3-0.1-0.6-0.1s-0.3,0-0.6,0.1l-0.8,0.5l0.1-0.9
                           c0.1-0.3-0.1-0.7-0.3-1L11,41l0.9-0.1c0.3,0,0.7-0.2,0.8-0.6l0.5-0.8l0.5,0.8c0.1,0.3,0.5,0.6,0.8,0.6l0.9,0.1L14.8,41.7z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M10.1,23.1c0,1.7,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2S10.1,21.4,10.1,23.1z M14.2,23.1
                           c0,0.6-0.5,0.9-0.9,0.9c-0.6,0-0.9-0.5-0.9-0.9c0-0.6,0.5-0.9,0.9-0.9C13.9,22.2,14.2,22.6,14.2,23.1z"></path>
                        </g>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Hassle Free Solution',
					text: 'Our expert android app developers follow advance development methodology to provide hassle-free secure and robust android application to clients.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <path class="st0" d="M37,13.9h22c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v15h2.3V15c0-0.9-0.4-1.8-1-2.5
                     c-0.7-0.7-1.5-1-2.5-1H42.2c1.1-1.2,1.8-2.8,1.8-4.6C43.9,3.1,40.8,0,37,0s-6.9,3.1-6.9,6.9S33.2,13.9,37,13.9z M37,2.3
                     c2.6,0,4.6,2.1,4.6,4.6s-2.1,4.6-4.6,4.6c-2.6,0-4.6-2.1-4.6-4.6S34.4,2.3,37,2.3z"></path>
                     <rect x="35.8" y="5.8" class="st0" width="2.3" height="2.3"></rect>
                     <path class="st0" d="M67.1,30.1c-3.8,0-6.9,3.1-6.9,6.9v22c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h-15v2.3h15
                     c0.9,0,1.8-0.4,2.5-1c0.7-0.7,1-1.5,1-2.5V42.2c1.2,1.1,2.8,1.8,4.6,1.8c3.8,0,6.9-3.1,6.9-6.9S70.9,30.1,67.1,30.1z M67.1,41.6
                     c-2.6,0-4.6-2.1-4.6-4.6c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6C71.7,39.6,69.6,41.6,67.1,41.6z"></path>
                     <rect x="65.9" y="35.8" class="st0" width="2.3" height="2.3"></rect>
                     <path class="st0" d="M37,60.1H15c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8v-15h-2.3v15c0,0.9,0.4,1.8,1,2.5
                     c0.7,0.7,1.5,1,2.5,1h16.8c-1.1,1.2-1.8,2.8-1.8,4.6c0,3.8,3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9S40.8,60.1,37,60.1z M37,71.7
                     c-2.6,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6c2.6,0,4.6,2.1,4.6,4.6S39.6,71.7,37,71.7z"></path>
                     <rect x="35.8" y="65.9" class="st0" width="2.3" height="2.3"></rect>
                     <path class="st0" d="M13.9,37V15c0-0.3,0.1-0.6,0.3-0.8s0.5-0.3,0.8-0.3h15v-2.3H15c-0.9,0-1.8,0.4-2.5,1c-0.7,0.7-1,1.5-1,2.5v16.8
                     c-1.2-1.1-2.8-1.8-4.6-1.8C3.1,30.1,0,33.2,0,37s3.1,6.9,6.9,6.9S13.9,40.8,13.9,37z M6.9,41.6c-2.6,0-4.6-2.1-4.6-4.6
                     c0-2.6,2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6C11.6,39.6,9.5,41.6,6.9,41.6z"></path>
                     <rect x="5.8" y="35.8" class="st0" width="2.3" height="2.3"></rect>
                     <path class="st0" d="M16.2,57.8h41.6V16.2H16.2V57.8z M18.5,18.5h37v37h-37V18.5z"></path>
                     <path class="st0" d="M37,24.3c-7,0-12.7,5.7-12.7,12.7S30,49.7,37,49.7S49.7,44,49.7,37S44,24.3,37,24.3z M37,47.4
                     c-5.7,0-10.4-4.7-10.4-10.4c0-5.7,4.7-10.4,10.4-10.4c5.7,0,10.4,4.7,10.4,10.4C47.4,42.7,42.7,47.4,37,47.4z"></path>
                     <polygon class="st0" points="35.8,38.8 33.2,36.2 31.6,37.8 35.8,42.1 43.6,34.3 42,32.7 "></polygon>
                  </svg>`
				},
				{
					title: 'Flexible Solution',
					text: 'Expertize in providing a flexible solution to develop, integrate and configure third-party API as per client business requirements.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M53.9,46.2l2.3,2.3H37.8c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h18.4l-2.3,2.3c-0.7,0.7-0.7,1.5,0,2.2
                           c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l6-6l-6-6c-0.7-0.7-1.5-0.7-2.2,0C53.2,44.7,53.3,45.6,53.9,46.2z M94,44
                           c-0.7-0.7-1.5-0.7-2.2,0c-0.7,0.7-0.7,1.5,0,2.2l2.3,2.3h-8.1c-4.7,0-8.7-4-8.7-8.7c0-6.4-5.1-11.7-11.6-11.7h-2.8
                           c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h2.8c4.7,0,8.7,4,8.7,8.7c0,3.5,1.5,6.6,3.9,8.7H76c-6.4,0-11.6,5.2-11.6,11.7
                           c0,4.8-3.9,8.7-8.7,8.7h-3.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h3.1c6.4,0,11.6-5.2,11.6-11.7c0-4.8,3.9-8.7,8.7-8.7
                           h18.1l-2.3,2.3c-0.7,0.7-0.7,1.5,0,2.2c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l6-6L94,44z M16.8,51.5h5.3
                           c6.4,0,11.6-5.2,11.6-11.7c0-4.8,3.9-8.7,8.7-8.7h6.4l-2.3,2.3c-0.7,0.7-0.7,1.5,0,2.2c0.3,0.3,0.7,0.4,1.1,0.4
                           c0.4,0,0.8-0.1,1.1-0.4l6-6l-6-6c-0.7-0.7-1.5-0.7-2.2,0c-0.7,0.7-0.7,1.5,0,2.2l2.3,2.3h-6.4c-6.4,0-11.6,5.2-11.6,11.7
                           c0,4.8-3.9,8.7-8.7,8.7h-5.3c-0.8,0-1.5,0.7-1.5,1.5C15.4,50.8,16,51.5,16.8,51.5z M36.7,64.6c-0.7,0.7-0.7,1.5,0,2.2l2.3,2.3
                           H22.1c-4.7,0-8.7-4-8.7-8.7c0-6.4-5.1-11.7-11.6-11.7H1.5c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h0.3c4.7,0,8.7,4,8.7,8.7
                           c0,6.4,5.1,11.7,11.6,11.7h16.8l-2.3,2.3c-0.7,0.7-0.7,1.5,0,2.2c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4l6-6l-6-6
                           C38.2,63.9,37.2,63.9,36.7,64.6z"></path>
                        </g>
                     </g>
                  </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
