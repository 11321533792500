<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Advantages</span>
				<h2 class="fxt-process-dev">The many benefits of PHP</h2>
				<p>
					As a web development framework, PHP bestows its users with a range of benefits that
					simplifies the task of delivering efficient and affordable web development.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed text-left" style="color: #008dd2">
						Why choose PHP framework?
					</h2>
					<div>
						<ul class="featchers-list">
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g transform="translate(0,-952.36218)">
											<path
												d="M39.7,952.4c-0.6,0-1.1,0.6-1.1,1.2c0,0.6,0.6,1.1,1.2,1.1c0,0,0,0,0,0h6.3l-20,20c-0.4,0.4-0.4,1.2,0,1.6s1.2,0.4,1.6,0
                              l0,0l20-20v6.4c0,0.6,0.5,1.1,1.1,1.2c0.6,0,1.1-0.5,1.2-1.1c0,0,0,0,0,0v-9.1c0-0.6-0.5-1.1-1.1-1.1h-9.1
                              C39.7,952.4,39.7,952.4,39.7,952.4L39.7,952.4z M1,956.9c-0.6,0.1-1,0.5-1,1.1v43.2c0,0.6,0.5,1.1,1.1,1.1h43.2
                              c0.6,0,1.1-0.5,1.1-1.1v-33.3c0-0.6-0.5-1.1-1.1-1.2c-0.6,0-1.1,0.5-1.2,1.1c0,0,0,0,0,0v32.2H2.3v-40.9h32.2
                              c0.6,0,1.1-0.5,1.2-1.1c0-0.6-0.5-1.1-1.1-1.2c0,0,0,0,0,0H1.1C1.1,956.9,1.1,956.9,1,956.9z"
											></path>
										</g>
									</svg>
								</div>
								<p>
									<strong>Thousands of open source libraries</strong> that can take your web
									application performance to new highs.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M45.7,2.7H4.3C2,2.7,0,4.6,0,7v35.7c0,0.1,0,0.2,0,0.2V29.6h1.6V15.9h46.8v26.8c0,1.5-1.2,2.8-2.8,2.8H21.2v1.6h24.5
                           c2.4,0,4.3-1.9,4.4-4.3V7C50,4.6,48.1,2.7,45.7,2.7z M48.4,14.3H1.6V7c0-1.5,1.2-2.8,2.7-2.8h41.3c1.5,0,2.8,1.2,2.8,2.8V14.3z"
										></path>
										<path
											d="M6.8,6C4.9,6,3.4,7.5,3.4,9.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C10.1,7.5,8.6,6,6.8,6z M6.8,11
                           C5.8,11,5,10.2,5,9.3c0-1,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7C8.5,10.2,7.7,11,6.8,11z"
										></path>
										<path
											d="M14.6,6c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c0.9,0,1.7-0.4,2.3-1c0.6-0.6,1-1.4,1-2.3C17.9,7.5,16.4,6,14.6,6z
                           M14.6,11c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7C16.3,10.2,15.5,11,14.6,11z"
										></path>
										<path
											d="M43.6,6H22.9c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3h20.7c1.8,0,3.3-1.5,3.3-3.3C46.9,7.5,45.4,6,43.6,6z M43.6,11
                           H22.9c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7h20.7c0.9,0,1.7,0.8,1.7,1.7C45.3,10.2,44.5,11,43.6,11z"
										></path>
										<path d="M3.4,17.7v11.9H5V19.3h14.5v10.3h1.6V17.7H3.4z"></path>
										<path
											d="M23.8,17.7h19.8c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8H23.8c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C23,18,23.4,17.7,23.8,17.7z"
										></path>
										<path
											d="M23.8,21.4h19.8c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8H23.8c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C23,21.7,23.4,21.4,23.8,21.4z"
										></path>
										<path
											d="M23.8,25.1h12.7c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8H23.8c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C23,25.4,23.4,25.1,23.8,25.1z"
										></path>
										<path
											d="M39.6,25.1h3.9c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8h-3.9c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C38.8,25.4,39.1,25.1,39.6,25.1z"
										></path>
										<path
											d="M23.6,28.8h7c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8h-7c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C22.8,29.1,23.2,28.8,23.6,28.8z"
										></path>
										<path
											d="M33.7,28.8h10c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8h-10c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C32.9,29.1,33.2,28.8,33.7,28.8z"
										></path>
										<path
											d="M23.8,32.5h19.8c0.4,0,0.8,0.4,0.8,0.8l0,0c0,0.4-0.4,0.8-0.8,0.8H23.8c-0.4,0-0.8-0.4-0.8-0.8l0,0
                           C23,32.8,23.4,32.5,23.8,32.5z"
										></path>
										<path
											d="M41.9,36.2h-6.6c-1.6,0-2.8,1.3-2.8,2.8v0.9c0,1.6,1.3,2.8,2.8,2.8h6.6c1.6,0,2.8-1.3,2.8-2.8v-0.9
                           C44.7,37.5,43.5,36.2,41.9,36.2z M43.2,39.9c0,0.7-0.6,1.2-1.2,1.2h-6.6c-0.7,0-1.2-0.6-1.2-1.2v-0.9c0-0.7,0.6-1.2,1.2-1.2h6.6
                           c0.7,0,1.2,0.5,1.2,1.2V39.9z"
										></path>
										<path
											d="M3.9,32.7l1.7,10.3c0.2,1.1,1.6,0.7,2.1,0.7c-0.7,1.8,0.7,3.7,2.6,3.7s3.3-1.8,2.6-3.7h2.2c-0.7,1.8,0.7,3.7,2.6,3.7
                           c1.9,0,3.3-1.8,2.6-3.7c0.4-0.1,2.1,0.4,2.1-0.9c0-1.7-2.2-0.5-15.2-0.9L7,40h12.6c1.1,0,0.9-1.2,2.7-5.1c0.1-0.3,0.1-0.6-0.1-0.8
                           c-0.6-0.7-0.4-0.3-16.4-0.4c-0.3-1.1,0-2.7-1.2-2.7C2.3,31.1,0,30.4,0,31.9C0,32.3,2.1,32.5,3.9,32.7z M10.3,45.5
                           c-1.2,0-1.2-1.8,0-1.8S11.5,45.5,10.3,45.5z M17.8,45.5c-1.2,0-1.2-1.8,0-1.8C18.3,43.7,19,45.5,17.8,45.5z M20.1,35.4L19,38.2H6.7
                           l-0.5-2.7H20.1z"
										></path>
									</svg>
								</div>
								<p>
									Fits perfectly for <strong>eCommerce web development</strong> that requires
									thousands of product pages to load lightning fast.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M28.2,9.4c0,0.1,0,0.3,0.1,0.4c0.2,0.4,0.7,0.5,1.1,0.3l1.4-0.8c0.4-0.2,0.5-0.7,0.3-1.1C31.1,8.1,31,8,30.8,7.9l-1.4-0.8
                           c-0.4-0.2-0.9-0.1-1.1,0.3c-0.1,0.1-0.1,0.3-0.1,0.4h-6.4c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8L28.2,9.4z"
										></path>
										<path
											d="M20.6,47.2c0.4,0.2,0.9,0.1,1.1-0.3c0.1-0.1,0.1-0.2,0.1-0.4h6.4c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-6.4
                           c0-0.1,0-0.3-0.1-0.4c-0.2-0.4-0.7-0.5-1.1-0.3L19.1,45c-0.4,0.2-0.5,0.7-0.3,1.1c0.1,0.1,0.2,0.2,0.3,0.3L20.6,47.2z"
										></path>
										<path
											d="M15.2,18v10.9c0,0.9,0.7,1.6,1.6,1.6h5.1V32h-1.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h9.4c0.4,0,0.8-0.4,0.8-0.8
                           c0-0.4-0.4-0.8-0.8-0.8h-1.6v-1.6h5.1c0.9,0,1.6-0.7,1.6-1.6V18c0-0.9-0.7-1.6-1.6-1.6H16.8C15.9,16.4,15.2,17.1,15.2,18z M26.5,32
                           h-3.1v-1.6h3.1V32z M33.2,28.9H16.8V18h16.4V28.9z"
										></path>
										<path
											d="M48.3,7H35.2c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0v2.1c0,0.9,0.7,1.7,1.7,1.7h13.1c0.9,0,1.7-0.8,1.7-1.7V8.7
                           C50,7.8,49.3,7,48.3,7C48.3,7,48.3,7,48.3,7z M48.4,10.8c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0H35.2c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
                           V8.7c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0h13.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0L48.4,10.8z"
										></path>
										<path
											d="M48.3,0H35.2c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0v2.1c0,0.9,0.7,1.7,1.7,1.7c0,0,0,0,0,0h13.1c0.9,0,1.7-0.8,1.7-1.7V1.7
                           C50,0.7,49.3,0,48.3,0C48.3,0,48.3,0,48.3,0z M48.4,3.8c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0H35.2c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
                           V1.7c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0h13.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0L48.4,3.8z"
										></path>
										<path
											d="M16.4,48.3v-2.1c0-0.9-0.7-1.7-1.7-1.7H1.6c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0v2.1C0,49.3,0.7,50,1.6,50h13.1
                           C15.6,50,16.4,49.3,16.4,48.3C16.4,48.3,16.4,48.3,16.4,48.3z M1.5,48.3v-2.1c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0h13.1
                           c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0v2.1c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0H1.6C1.6,48.5,1.5,48.4,1.5,48.3
                           C1.5,48.3,1.5,48.3,1.5,48.3z"
										></path>
										<path
											d="M1.6,43h13.1c0.9,0,1.7-0.8,1.7-1.7c0,0,0,0,0,0v-2.1c0-0.9-0.7-1.7-1.7-1.7H1.6c-0.9,0-1.7,0.8-1.7,1.7c0,0,0,0,0,0v2.1
                           C0,42.2,0.7,43,1.6,43z M1.5,39.2c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0h13.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0v2.1c0,0.1,0,0.1-0.1,0.1
                           c0,0,0,0,0,0H1.6c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0V39.2z"
										></path>
										<path
											d="M12.8,1c-0.6,0-1.2,0.2-1.7,0.5c-1.5-1.8-4.2-2-6-0.4C4.2,1.8,3.7,2.9,3.6,4.1C1.3,4.4-0.3,6.5,0,8.8
                           c0.3,2.1,2.1,3.7,4.2,3.7h10.3c2.3,0,4.2-1.9,4.3-4.2c0-1.7-1-3.2-2.5-3.9c0,0,0,0,0,0C16.2,2.5,14.7,1,12.8,1z M17.2,8.3
                           c0,1.5-1.2,2.7-2.7,2.7H4.2c-1.5,0-2.7-1.1-2.7-2.6c0-1.4,1-2.6,2.3-2.7l1.3-0.2l0-1.3c0-1.5,1.2-2.6,2.7-2.6c0.8,0,1.5,0.3,2,0.9
                           l0.9,1l1.1-0.7c0.9-0.5,2-0.2,2.5,0.7c0.2,0.3,0.2,0.6,0.2,0.9v0.1v1l0.9,0.4C16.5,6.3,17.2,7.2,17.2,8.3z"
										></path>
										<path
											d="M47.5,41.9C47.5,41.9,47.5,41.9,47.5,41.9c0-1.9-1.5-3.4-3.4-3.4c-0.6,0-1.2,0.2-1.7,0.5c-1.5-1.8-4.2-2-6-0.4
                           c-0.9,0.8-1.4,1.9-1.5,3.1c-2.3,0.3-3.9,2.4-3.6,4.7c0.3,2.1,2.1,3.7,4.2,3.7h10.3c2.3,0,4.2-1.9,4.3-4.2C50,44.1,49,42.6,47.5,41.9
                           z M45.7,48.5H35.5c-1.5,0-2.7-1.1-2.7-2.6c0-1.4,1-2.6,2.3-2.7l1.3-0.2l0-1.3c0-1.4,1.2-2.6,2.7-2.6c0.8,0,1.5,0.3,2,0.9l0.9,1
                           l1.1-0.7c0.9-0.5,2-0.2,2.5,0.7c0.2,0.3,0.2,0.6,0.2,0.9v0.1v1l0.9,0.4c1.3,0.6,1.9,2.2,1.3,3.5C47.7,47.8,46.8,48.4,45.7,48.5
                           L45.7,48.5z"
										></path>
										<path
											d="M7.4,14.1c-0.4,0-0.8,0.4-0.8,0.8v20.3c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-9.4h2.1c0,0.4,0.3,0.8,0.8,0.8
                           c0.1,0,0.2,0,0.3-0.1l1.8-0.8c0.4-0.2,0.6-0.6,0.4-1c-0.1-0.2-0.2-0.3-0.4-0.4l-1.8-0.8c-0.4-0.2-0.9,0-1,0.4c0,0.1-0.1,0.2-0.1,0.3
                           H8.2v-9.4C8.2,14.4,7.8,14.1,7.4,14.1z"
										></path>
										<path
											d="M42.6,35.9c0.4,0,0.8-0.4,0.8-0.8V14.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v9.4h-2.1c0-0.4-0.3-0.8-0.8-0.8
                           c-0.1,0-0.2,0-0.3,0.1l-1.8,0.8c-0.4,0.2-0.6,0.6-0.4,1c0.1,0.2,0.2,0.3,0.4,0.4l1.8,0.8c0.4,0.2,0.9,0,1-0.4c0-0.1,0.1-0.2,0.1-0.3
                           h2.1v9.4C41.8,35.6,42.1,35.9,42.6,35.9z"
										></path>
									</svg>
								</div>
								<p>
									PHP has a <strong>built-In HTTP Server</strong> which makes it easy to develop and
									test applications locally. Eliminates the need to configure a full-featured web
									server such as Apache or Nginx.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M50,11.8c0-1.2-0.9-2.1-2.1-2.1h-7V8.9c0-0.5-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v0.8H27.8c-0.8-2.2-2-4.1-3.7-5.7
                                 c-2.7-2.4-6.1-3.7-9.8-3.7l0,0l0,0c-0.2,0-0.4,0-0.5,0C6.1,0.5,0,6.9,0,14.5c0,9.3,8.8,16.2,17.8,13.8c0.1,0.2,0.2,0.2,0.2,0.4
                                 c-0.3,0.4-0.5,0.8-0.5,1.3v6.6c0,1.2,0.9,2.1,2.1,2.1H42v2.3c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2.3h4.3
                                 c1.2,0,2.1-0.9,2.1-2.1v-6.6c0-0.5-0.2-0.9-0.5-1.3c0.3-0.4,0.5-0.8,0.5-1.3v-6.6c0-0.5-0.2-0.9-0.5-1.3c0.3-0.4,0.5-0.8,0.5-1.3
                                 V11.8z M48.4,11.8v6.6c0,0.3-0.2,0.5-0.5,0.5H19.7c-0.3,0-0.5-0.2-0.5-0.5v-6.6c0-0.3,0.2-0.5,0.5-0.5H48
                                 C48.2,11.3,48.4,11.5,48.4,11.8z M48.4,20.9v6.6c0,0.3-0.2,0.5-0.5,0.5H19.7c-0.3,0-0.5-0.2-0.5-0.5v-6.6c0-0.3,0.2-0.5,0.5-0.5
                                 H48C48.2,20.4,48.4,20.6,48.4,20.9z M15.1,20.4v-5.1h2.5v3c0,0.5,0.2,0.9,0.5,1.3c-0.2,0.3-0.4,0.7-0.5,1.1
                                 C16.8,20.5,15.9,20.5,15.1,20.4z M13.5,27.2c-1.8-0.3-3.4-1.7-4.7-4c1.5-0.7,3-1.1,4.7-1.2V27.2z M15.1,21.9
                                 c0.9,0.1,1.7,0.2,2.5,0.4v3.6c-0.8,0.7-1.6,1.1-2.5,1.2V21.9z M17.6,11.8v2h-2.5V9C17,9,18.8,8.4,20.5,7.6C20.8,8.3,21,9,21.3,9.8
                                 h-1.6C18.5,9.8,17.6,10.7,17.6,11.8z M15.1,1.9c1.9,0.3,3.6,1.9,4.8,4.3c-1.5,0.7-3.1,1.2-4.8,1.3V1.9z M21.3,5.4
                                 c-0.5-0.9-1-1.7-1.6-2.4c0.9,0.5,1.9,1,2.7,1.6C22,4.9,21.6,5.1,21.3,5.4z M13.5,7.4c-1.6-0.1-3.4-0.5-4.8-1.3
                                 c1.3-2.4,3-4,4.8-4.3V7.4z M7.3,5.4C7,5.1,6.6,4.9,6.3,4.6C7.1,4,8,3.4,9,3C8.4,3.7,7.8,4.4,7.3,5.4z M8,7.6
                                 c1.7,0.9,3.6,1.3,5.5,1.4v4.7H6.9C7,11.5,7.3,9.4,8,7.6z M13.5,15.3v5.1c-1.9,0.1-3.7,0.5-5.3,1.3c-0.8-1.9-1.3-4.1-1.3-6.5
                                 L13.5,15.3L13.5,15.3z M7.5,23.9C8,24.7,8.4,25.4,8.9,26c-0.9-0.4-1.6-0.9-2.3-1.5C6.9,24.4,7.2,24.1,7.5,23.9z M26.1,9.8h-3.3
                                 c-0.2-1-0.5-2-0.9-2.9c0.5-0.3,1.1-0.7,1.6-1.2C24.6,6.9,25.5,8.2,26.1,9.8z M5.2,5.7c0.5,0.4,1,0.8,1.6,1.1
                                 c-0.9,2.1-1.3,4.5-1.4,6.9H1.6C1.8,10.6,3.1,7.8,5.2,5.7z M1.6,15.3h3.7c0.1,2.6,0.6,5.1,1.5,7.2c-0.5,0.3-1,0.7-1.5,1
                                 C3.2,21.4,1.8,18.5,1.6,15.3z M48.4,36.5c0,0.3-0.2,0.5-0.5,0.5H19.7c-0.3,0-0.5-0.2-0.5-0.5V30c0-0.3,0.2-0.5,0.5-0.5H48
                                 c0.3,0,0.5,0.2,0.5,0.5L48.4,36.5L48.4,36.5z"
												></path>
											</g>
											<g>
												<path
													d="M28.3,12.8h-5.6c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h5.6c0.5,0,0.8-0.3,0.8-0.8C29.1,13.2,28.7,12.8,28.3,12.8z
                                 "
												></path>
											</g>
											<g>
												<path
													d="M28.3,15.8h-5.6c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.6c0.5,0,0.8-0.3,0.8-0.8S28.7,15.8,28.3,15.8z"
												></path>
											</g>
											<g>
												<path
													d="M39.1,15.1c0,1.6,1.2,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9C40.5,12.2,39.1,13.5,39.1,15.1z M42,13.7
                                 c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3C40.7,14.4,41.3,13.7,42,13.7z"
												></path>
											</g>
											<g>
												<path
													d="M22.7,23.5h5.6c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.3-0.8,0.8C21.9,23.1,22.3,23.5,22.7,23.5z"
												></path>
											</g>
											<g>
												<path
													d="M22.7,26.4h5.6c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-5.6c-0.5,0-0.8,0.3-0.8,0.8C21.9,26.1,22.3,26.4,22.7,26.4z
                                 "
												></path>
											</g>
											<g>
												<path
													d="M42,27c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9C39.1,25.8,40.5,27,42,27z M42,22.9
                                 c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3C40.7,23.4,41.3,22.9,42,22.9z"
												></path>
											</g>
											<g>
												<path
													d="M28.3,31h-5.6c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h5.6c0.5,0,0.8-0.3,0.8-0.8C29.1,31.4,28.7,31,28.3,31z"
												></path>
											</g>
											<g>
												<path
													d="M28.3,34h-5.6c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.6c0.5,0,0.8-0.3,0.8-0.8C29.1,34.4,28.7,34,28.3,34z"
												></path>
											</g>
											<g>
												<path
													d="M42,30.4c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.2,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9C44.9,31.7,43.6,30.4,42,30.4z M42,34.6
                                 c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3C43.4,34,42.7,34.6,42,34.6z"
												></path>
											</g>
											<g>
												<path
													d="M25.5,2.5c-0.5,0-0.8,0.3-0.8,0.8S25,4,25.5,4H27c0.5,0,0.8-0.3,0.8-0.8S27.5,2.5,27,2.5H25.5z"
												></path>
											</g>
											<g>
												<path
													d="M30.9,2.5c-0.5,0-0.8,0.3-0.8,0.8S30.4,4,30.9,4h3.8c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H30.9z"
												></path>
											</g>
											<g>
												<path
													d="M40.1,5.6c0.5,0,0.8-0.3,0.8-0.8V3.3c0-0.5-0.3-0.8-0.8-0.8h-1.6c-0.5,0-0.8,0.3-0.8,0.8S38,4,38.5,4h0.8v0.8
                                 C39.3,5.2,39.7,5.6,40.1,5.6z"
												></path>
											</g>
											<g>
												<path
													d="M31.6,47.7c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h3.1c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H31.6z"
												></path>
											</g>
											<g>
												<path
													d="M12.9,49.3H16c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-3.1c-0.5,0-0.8,0.3-0.8,0.8C12.1,49,12.4,49.3,12.9,49.3z"
												></path>
											</g>
											<g>
												<path
													d="M11,46.5c0,0.5,0.3,0.8,0.8,0.8c0.5,0,0.8-0.3,0.8-0.8v-3.1c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8V46.5z"
												></path>
											</g>
											<g>
												<path
													d="M11.8,34.8c0.5,0,0.8-0.3,0.8-0.8c-0.2-2,0.5-3.9-0.8-3.9c-0.5,0-0.8,0.3-0.8,0.8V34C11,34.4,11.4,34.8,11.8,34.8z"
												></path>
											</g>
											<g>
												<path
													d="M37.9,47.7c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8H41c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H37.9z"
												></path>
											</g>
											<g>
												<path
													d="M42.8,43.3c-0.5,0-0.8,0.3-0.8,0.8v3.1c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-3.1C43.6,43.7,43.3,43.3,42.8,43.3z"
												></path>
											</g>
											<g>
												<path
													d="M25.4,47.7c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h3.1c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H25.4z"
												></path>
											</g>
											<g>
												<path
													d="M19.1,47.7c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h3.1c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8H19.1z"
												></path>
											</g>
											<g>
												<path
													d="M11,40.2c0,0.5,0.3,0.8,0.8,0.8c0.5,0,0.8-0.3,0.8-0.8v-3.1c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8V40.2z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<p>
									<strong>Supports any hosting environment</strong> like Windows, Linux and Mac.
									This makes PHP an extremely flexible and universally supported web development
									framework.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<g>
													<g>
														<path
															d="M41.7,50H10.9c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.2-0.2-0.5-0.1-0.8l4-9.7c0.1-0.3,0.4-0.5,0.8-0.5h22.8
                                       c0.3,0,0.6,0.2,0.8,0.5l4,9.7c0.1,0.3,0.1,0.5-0.1,0.8C42.2,49.9,42,50,41.7,50z M12.2,48.3h28.3l-3.3-8H15.5L12.2,48.3z"
														></path>
													</g>
													<g>
														<path
															d="M31.3,43.4h-4.2v-1.7h4.2V43.4z M22.9,43.4h-4.2v-1.7h4.2V43.4z"
														></path>
													</g>
													<g>
														<rect x="17.9" y="44.8" width="16.6" height="1.7"></rect>
													</g>
												</g>
												<g>
													<g>
														<g>
															<path
																d="M15.3,18.3c-0.2,0-0.4,0-0.5-0.1c-0.3-0.2-0.6-0.5-0.6-0.9v-0.9H4c-0.9,0-1.5-0.7-1.5-1.5v-1.9c0-0.4,0.1-0.8,0.4-1.1
                                          c0.3-0.3,0.7-0.5,1.1-0.5l10.1,0v-0.9c0-0.5,0.3-0.8,0.6-1c0.3-0.2,0.7-0.1,1.1,0.1l5,3.4c0.3,0.2,0.5,0.6,0.5,0.9
                                          s-0.2,0.6-0.4,0.9l-5,3.4C15.7,18.2,15.5,18.3,15.3,18.3z M4.2,14.6H15c0.5,0,0.8,0.4,0.8,0.8v0.6l3.3-2.3l-3.3-2.3l0,0.6
                                          c0,0.5-0.4,0.8-0.8,0.8L4.2,13V14.6z"
															></path>
														</g>
													</g>
													<g>
														<g>
															<g>
																<rect x="28.1" y="28" width="1.7" height="4.6"></rect>
															</g>
															<g>
																<rect x="21.1" y="31.8" width="10.8" height="1.7"></rect>
															</g>
														</g>
														<g>
															<rect x="23.2" y="28" width="1.7" height="4.6"></rect>
														</g>
													</g>
													<g>
														<path
															d="M46.7,28.9H6.3c-0.5,0-0.8-0.4-0.8-0.8V15.5c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v11.7h38.7V1.7H7.1v10.5
                                       c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8V0.8C5.4,0.4,5.8,0,6.3,0h40.4c0.5,0,0.8,0.4,0.8,0.8V28C47.5,28.5,47.1,28.9,46.7,28.9
                                       z"
														></path>
													</g>
													<g>
														<path
															d="M43.3,25.6H9.7c-0.5,0-0.8-0.4-0.8-0.8v-9.3c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v8.4h31.9V5H10.5v7.2
                                       c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8V4.1c0-0.5,0.4-0.8,0.8-0.8h33.6c0.5,0,0.8,0.4,0.8,0.8v20.6
                                       C44.1,25.2,43.7,25.6,43.3,25.6z"
														></path>
													</g>
												</g>
											</g>
										</g>
										<path
											d="M40.5,15.4H24.3c-0.5,0-0.9,0.3-0.9,0.6s0.4,0.6,0.9,0.6h16.2c0.5,0,0.9-0.3,0.9-0.6S41,15.4,40.5,15.4z"
										></path>
										<path
											d="M40.5,17.9H24.3c-0.5,0-0.9,0.3-0.9,0.6s0.4,0.6,0.9,0.6h16.2c0.5,0,0.9-0.3,0.9-0.6S41,17.9,40.5,17.9z"
										></path>
										<path
											d="M40.5,20.4H24.3c-0.5,0-0.9,0.3-0.9,0.6c0,0.3,0.4,0.6,0.9,0.6h16.2c0.5,0,0.9-0.3,0.9-0.6C41.4,20.6,41,20.4,40.5,20.4z"
										></path>
										<path
											d="M27.8,10.5l2.6-3.2c0.2-0.3,0.2-0.7-0.1-0.9c-0.3-0.2-0.7-0.2-0.9,0.1l-3,3.7c-0.2,0.3-0.2,0.6,0,0.9l3.3,3.3
                           c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.7,0-0.9L27.8,10.5z"
										></path>
										<path
											d="M38.9,9.7l-3.3-3.3c-0.2-0.2-0.7-0.2-0.9,0c-0.2,0.2-0.2,0.7,0,0.9l2.9,2.9L35,13.4c-0.2,0.3-0.2,0.7,0.1,0.9
                           c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2l3-3.7C39.2,10.3,39.2,9.9,38.9,9.7z"
										></path>
									</svg>
								</div>
								<p>
									<strong>Direct integration</strong> with HTML and CSS. Requires no additional
									plugins or software to create interactive web pages on WordPress, Drupal or Joomla
									that can gather user input.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g sketch:type="MSPage">
											<g
												transform="translate(-435.000000, -75.000000)"
												sketch:type="MSArtboardGroup"
											>
												<g transform="translate(435.000000, 78.000000)" sketch:type="MSLayerGroup">
													<path
														sketch:type="MSShapeGroup"
														class="st0"
														d="M40,5.9V4.4l-1.9-1.8H1.9L0,4.4v28.1l1.9,1.8h14.3l-1.3,5h-3.3v1.7h11.1
                                    l-1.1-1.1v-0.6h-4.9l1.3-5h3.7v-1.7H2.5l-0.8-0.8V5.1l0.8-0.8h35l0.8,0.8v0.8H40z M21.7,27.6h-20v-1.7h20V27.6z M20,28.4l1.4,0.8
                                    v1.7L20,31.8l-1.4-0.8v-1.7L20,28.4z"
													></path>
													<path
														sketch:type="MSShapeGroup"
														class="st0"
														d="M31.7,34.3H25l0,0V12.6l0,0h20v1.7h1.7V9.4l-1.9-1.8H25.2l-1.9,1.8v29.7l1.9,1.8
                                    H33l-1.3-1.3v-0.3h-5.8L25,38.4v-2.5l0,0h6.7V34.3z M25,10.9v-0.8l0.8-0.8h18.3l0.8,0.8v0.8H25L25,10.9z"
													></path>
													<path
														sketch:type="MSShapeGroup"
														class="st0"
														d="M35,20.9v13.3l0,0h13.3V20.9H35z M35,19.3v-0.8l0.8-0.8h11.7l0.8,0.8v0.8H35z
                                    M35,35.9v2.5l0.8,0.8h11.7l0.8-0.8v-2.5H35L35,35.9z M33.3,17.7l1.9-1.8h12.9l1.9,1.8v21.4l-1.9,1.8H35.2l-1.9-1.8V17.7z
                                    M41.7,36.3l1.1,0.6v1.3l-1.1,0.6l-1.1-0.6V37L41.7,36.3z"
													></path>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<p>
									<strong>Responsive display</strong> that displays the best version of the website
									that suits the screen size display configuration of the user system.
								</p>
							</li>
						</ul>
					</div>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our PHP Expert Now</router-link
					>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-go-php.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>

.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill:black;
}
.web-why-go-icon svg path{
	fill:black;
}
.featchers-list {
	padding:0
}
.featchers-list li {
	display:flex;
	gap:20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
