<template>
	<section class="padding-60 bg-white similar-app-white-main fitness p-relative" style="padding-bottom:100px">
		<div class="container-2 bg-light">
			<div class="row">
				<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-1">
					<div class="similar-app-content-wrap d-flex align-items-center">
						<div class="similar-app-content">
							<h2>An on-Demand Taxi-Hailing App for Commuters Convenience</h2>
							<p>
								With the tremendous success of Uber across the globe, the taxi-hailing app is
								becoming the prime startup ideas for delivering convenience to passengers to the
								extreme remote of the world. With stepping in of cab-hailing leader Uber in Nigeria,
								On-demand taxi booking business was instantly on the fast track to success. Our
								client wanted to leverage the arising market opportunity and give both passengers
								and drivers the Nigerian system of the business.
							</p>
							<p>
								The taxi app developers at GenixBit Labs developed an on-demand taxi booking app Dorel
								Taxi for Nigerians. The application was developed for iOS and Android platform which
								includes passengers as well as driver app. The admin manages entire taxi dispatch
								and drivers through the robust ad secure admin dashboard.
							</p>
							<a
								class="btn-custome btn btn-lg waves-effect waves-light"
								href="/contact"
								style="font-size: 14px;"
								>Get Started, Request a Demo Now!</a
							>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-0"></div>
			</div>
		</div>
		<div class="similar-app-image">
			<div class="similar-app-image-wid">
				<img
					class="img1 hid-in-mobile"
					alt="taxi app developers"
					src="../../../assets/images/similar-app-main1.webp"
				/>
				<img
					class="img2 hid-in-mobile"
					alt="taxi booking app development company"
					src="../../../assets/images/similar-app-main2.webp"
				/>
				<img
					class="d-block d-lg-none hid-in-mobile"
					alt="taxi app development services"
					src="../../../assets/images/similar-app-main.webp"
				/>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 1500px) {
	.similar-app-white-main:before {
		height: 75%;
		top: 13%;
	}
}
.similar-app-white-main:before {
	content: '';
	display: block;
	position: absolute;
	right: 0;
	width: 60%;
	height: 70%;
	top: 15%;
	background-color: #f8f9fa;
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media (max-width: 1500px) {
	.similar-app-white-main .similar-app-content-wrap {
		padding: 0px 40px;
	}
}
@media (max-width: 1440px) {
	.similar-app-content-wrap {
		padding: 30px 30px;
	}
}
.similar-app-content-wrap {
	position: relative;
	padding: 30px 60px;
	min-height: 480px;
}
.similar-app-content {
	position: relative;
}
.similar-app-white-main .similar-app-content h2 {
	color: #202122;
}
@media (max-width: 1440px) {
	.similar-app-content h2 {
		font-size: 18px;
		margin-bottom: 20px;
	}
}
.similar-app-content h2 {
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 30px;
}
.similar-app-white-main .similar-app-content p {
	color: #6a6d6f;
	font-size:14px !important;
	margin-bottom:8px !important;
}

@media (max-width: 1440px) {
	.similar-app-content p {
		margin-bottom: 20px;
		font-size:16px !important;
	}
}
.similar-app-content p {
	color: #fff;
	margin-bottom: 25px;
	font-size:16px !important;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.similar-app-content .btn-custome {
	font-size: 14px;
}
.btn-custome.btn-lg {
	padding: 15px 40px;
	font-size: 20px;
}
p {
	font-size:14px !important;
}
.waves-effect {
	position: relative;
	cursor: pointer;
	display: inline-block;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	vertical-align: middle;
	z-index: 1;
	will-change: opacity, transform;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.similar-app-white-main .similar-app-image {
	top: -45px;
}

.similar-app-image {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 30px;
	position: absolute;
}
@media (max-width: 1440px) {
	.similar-app-image-wid {
		width: 50%;
	}
}
.similar-app-image-wid {
	position: relative;
	max-width: 580px;
	width: 100%;
}
.fitness .similar-app-image-wid .img1 {
	width: 65%;
	top: 0;
}

.similar-app-image-wid .img1 {
	position: absolute;
	left: 0;
	top: 65px;
	z-index: 2;
}
.similar-app-image-wid img {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 60%;
	transform:scale(0.9)
}
.fitness .similar-app-image-wid .img2 {
	width: 50%;
	top: 70px;
	right: inherit;
	left: 45%;
}

.similar-app-image-wid .img2 {
	position: absolute;
	right: 0;
	z-index: 1;
	top: 0;
}
.similar-app-image-wid img {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 60%;
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
}
@media (max-width: 992px) {
	.hid-in-mobile {
		display: none !important;
	}
}
.p-relative {
	position:relative
}
.fitness.similar-app-white-main .similar-app-image-wid:hover .img1 {
    left: -10px;
}
.fitness.similar-app-white-main .similar-app-image-wid:hover .img2 {
    left: 50%;
}
</style>
