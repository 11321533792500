<template>
  <section class="padding-6030 mission-n-vision-section bg-light">
   <div class="container">
      <div class="title">
         <span>Mission &amp; Vision</span>
         <h2>Where we are now &amp; where we intend to go</h2>
         <p>We have come a long way helping startups and enterprises with digital <span class="tou-node" id="tou-0-2e02a209-0182-4a96-ab23-2ed807214f31"></span>. Our <span class="tou-node" id="tou-0-2baa7373-f4eb-454a-8c4d-a640248d58a1"></span> is not yet over though, and continued growth will be centered on innovative digital solutions.</p>
      </div>
      <div class="row">
         <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-30">
            <div class="mission-n-vision-box rounded10">
               <div class="mission-n-vision-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M71.7,54.6V33.3h1.7c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5h-1.7v-8.9l14-6.2c0.8-0.4,1.2-1.4,0.8-2.2c-0.2-0.4-0.6-0.8-1-0.9
                           l-15-5C70-0.1,69.4,0,69,0.3c-0.4,0.3-0.7,0.8-0.7,1.4v21.7H65v-5c0-2.8-2.2-5-5-5h-4.3c0.6-1,0.9-2.2,0.9-3.3V5c0-2.8-2.2-5-5-5
                           h-8.3c-0.9,0-1.7,0.7-1.7,1.7c0,0.3,0.1,0.5,0.2,0.7l1.5,3V10c0,1.2,0.3,2.3,0.9,3.3h-5.9V5.2c0-2.5-1.7-4.6-4.2-5.1
                           c-2.7-0.5-5.3,1.4-5.8,4.1c0,0.3-0.1,0.6-0.1,0.8v11.7c0,3.7,3,6.7,6.7,6.7h6.7v30H40c-0.5,0-1,0.2-1.3,0.7l-9.5,12.7h-5.8
                           c-0.5,0-1,0.3-1.4,0.7l-21.7,30c-0.5,0.7-0.4,1.8,0.4,2.3c0.3,0.2,0.6,0.3,1,0.3h96.6c0.9,0,1.7-0.7,1.7-1.7
                           c0-0.3-0.1-0.6-0.3-0.9L71.7,54.6z M71.7,4l8.7,2.9l-8.7,3.9V4z M71.7,26.7h1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7h-1.7
                           V26.7z M51.7,3.3c0.9,0,1.7,0.7,1.7,1.7v5c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3V5c0-0.3-0.1-0.5-0.2-0.7L46,3.3H51.7z
                           M35,20c-1.8,0-3.3-1.5-3.3-3.3V5c0-0.9,0.7-1.7,1.7-1.7c0.1,0,0.2,0,0.3,0c0.8,0.2,1.4,1,1.4,1.8V15c0,0.9,0.7,1.7,1.7,1.7H60
                           c0.9,0,1.7,0.7,1.7,1.7V25c0,0.9,0.7,1.7,1.7,1.7h5V30h-6.7c-1.8,0-3.3-1.5-3.3-3.3V20H55v10H45v-8.3c0-0.9-0.7-1.7-1.7-1.7H35z
                           M68.3,33.3v16.2l-0.3-0.4c-0.3-0.5-0.8-0.8-1.4-0.8h-3.3c-0.7,0-1.4,0.5-1.6,1.1l-1.2,3.7L58.3,51V32.4c1,0.6,2.2,0.9,3.3,0.9
                           H68.3z M48.3,36.7v15c0,0.4,0.2,0.9,0.5,1.2l0.5,0.5H45v-20h10v18.3c0,0.4,0.2,0.9,0.5,1.2l0.5,0.5h-2L51.7,51V36.7H48.3z M5,96.7
                           l10.8-15h10l6.2,9.3l2.8-1.8l-6.7-10c-0.3-0.5-0.8-0.7-1.4-0.7h-8.5l6-8.3h16.5l6.2,10.8c0.3,0.5,0.8,0.8,1.4,0.8h9l8.2,15H5z
                           M69.3,96.7l-5.5-10h7l4.5,6l2.7-2L73,84c-0.3-0.4-0.8-0.7-1.3-0.7H62l-2.3-4.1c-0.3-0.5-0.9-0.9-1.5-0.9h-9l-6.2-10.8
                           c-0.3-0.5-0.8-0.8-1.4-0.8h-8.3l7.5-10h20.8c0.7,0,1.4-0.5,1.6-1.1l1.3-3.9h1.2l29.5,45H69.3z M26.7,31.7c0-4.6-3.7-8.3-8.3-8.3
                           c-0.8,0-1.6,0.1-2.4,0.3c-4.4-3.3-10.6-2.4-14,2c-1.3,1.7-2,3.9-2,6v1.7h26.7V31.7z M3.6,30c0.9-3.6,4.5-5.7,8.1-4.8
                           c1.1,0.3,2.1,0.8,2.9,1.6l0.8,0.7l1-0.4c0.6-0.3,1.3-0.4,2-0.4c2.1,0,4,1.3,4.7,3.3H3.6z M91.6,36.7c-0.4,0-0.7,0-1.1,0.1
                           C88.9,35.6,87,35,85,35c-5.5,0-10,4.5-10,10v1.7h25V45C100,40.4,96.2,36.7,91.6,36.7z M78.5,43.3c0.8-2.9,3.4-5,6.5-5
                           c1.5,0,3,0.5,4.1,1.5l0.6,0.5l0.8-0.2c0.4-0.1,0.7-0.1,1.1-0.1c2.1,0,4,1.3,4.7,3.3H78.5z M15,50v1.7h16.7V50
                           c0-4.6-3.7-8.3-8.3-8.3C18.7,41.7,15,45.4,15,50z M28.1,48.3h-9.4c0.9-2.6,3.8-4,6.4-3.1C26.4,45.8,27.6,46.9,28.1,48.3z"></path>
                        </g>
                     </g>
                  </svg>
               </div>
               <h3>Mission</h3>
               <p>Digital Innovation for our Client’s to achieve their <span class="tou-node" id="tou-0-653a4a17-5aa3-4a78-8f2a-b087616c3b37"></span> and aspirations for their projects. We have delivered for all types of Client’s, from Jack Parkin and his Social Media Startup Chatagram, to the global tech <span class="tou-node" id="tou-0-04143968-2af3-4696-9a5e-35651bc54bfc"></span> Microsoft and their Virtual Reality disrupter Art of Works, and we will continue to help all our partners with the same passion and <span class="tou-node" id="tou-0-8e7f6247-7a04-46a0-a3d5-52ea5143bf08"></span>, no matter their <span class="tou-node" id="tou-0-4aed3a48-de42-4c14-96fa-73af44a9e0e7"></span>.</p>
            </div>
         </div>
         <div class="col-lg-6 col-md-6 col-sm-12 col-12 mb-30">
            <div class="mission-n-vision-box rounded10">
               <div class="mission-n-vision-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M99.9,51.6c0-11.8-7.3-22.3-18.3-26.5V20c0-8.3-6.7-15-15-15h-6.9c-0.7-2.9-3.4-5-6.4-5H40C28,0,18.3,9.7,18.3,21.6v5
                           h-6.7c-0.9,0-1.7,0.7-1.7,1.7c0,4.9,1.9,9.5,5.4,12.9c1.3,1.3,2.7,2.3,4.2,3.2c-1.8,1.2-3,3.2-3,5.5c0,3.7,3,6.7,6.7,6.7h3.5
                           c0.6,6.2,3.7,12.2,8.6,16.3c1.9,1.6,4,2.8,6.2,3.7H26.6v-5c0-0.9-0.7-1.7-1.7-1.7h-5v-5c0-4.6-3.7-8.3-8.3-8.3H10
                           c-0.9,0-1.7,0.7-1.7,1.7v11.7H1.7c-0.9,0-1.7,0.7-1.7,1.7v26.6c0,0.9,0.7,1.7,1.7,1.7h73.3l23.4,0.1h0c0.4,0,0.9-0.2,1.2-0.5
                           c0.3-0.3,0.5-0.7,0.5-1.2L99.9,51.6z M11.7,59.9c2.8,0,5,2.2,5,5v5h-5V59.9z M23.3,76.6H10v3.3h13.3v3.4H10v3.3h13.3v3.2H10v3.3
                           h13.3v3.3h-20V73.3h20V76.6z M26.6,53.3h-3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3h3.3V53.3z M17.7,38.9
                           c-2.4-2.4-3.9-5.6-4.3-8.9H30v-3.3h-8.3v-5c0-10.1,8.2-18.3,18.3-18.3h13.3c1.8,0,3.3,1.5,3.3,3.3c0,0.9,0.7,1.7,1.7,1.7h8.3
                           c6.4,0,11.7,5.2,11.7,11.7v4.1c-0.1,0-0.2,0-0.2,0c-0.7-0.2-1.4-0.3-2.1-0.4c-0.3,0-0.5-0.1-0.8-0.1c-0.5-0.1-1.1-0.1-1.6-0.2
                           c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.2-0.3-0.2-0.5
                           c-0.1-0.3-0.3-0.6-0.5-0.9c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.2-0.5-0.4-0.7-0.6
                           c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.2-0.5-0.3-0.8-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.1-0.7-0.2-1-0.3c-0.2,0-0.3-0.1-0.5-0.1
                           c-0.5-0.1-1-0.2-1.6-0.2h-6.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.4,0,0.9-0.1,1.3c0,0.4-0.1,0.8-0.2,1.3c-0.1,0.7-0.3,1.5-0.6,2.4
                           c-0.1,0.4-0.3,0.8-0.4,1.2c-0.2,0.4-0.3,0.9-0.5,1.3c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.4,0.7-0.6,1
                           c-0.8,1.4-1.7,2.6-2.7,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c-0.4,0.4-0.7,0.8-1.1,1.1c-0.6,0.6-1.2,1.1-1.8,1.6c-0.4,0.3-0.8,0.6-1.2,0.9
                           c-0.3,0.2-0.6,0.4-1,0.7c-0.3,0.2-0.6,0.4-1,0.6c-0.4,0.2-0.7,0.4-1.1,0.6c-0.3,0.2-0.7,0.4-1.1,0.6c-0.1,0.1-0.2,0.1-0.4,0.2
                           c-0.1,0.1-0.3,0.1-0.4,0.2c-0.7,0.3-1.5,0.6-2.4,0.9c0,0,0,0,0,0h0c-1.4,0.4-4.6,1.1-6.8,1.4c-0.7,0.1-1.5,0.1-2.2,0.1
                           C24.3,43.3,20.5,41.7,17.7,38.9z M37.5,70.3c-4.7-4-7.6-9.9-7.6-16v-7.6c0.3,0,0.6-0.1,0.9-0.1c0.6-0.1,1.3-0.1,1.9-0.2
                           c0.2,0,0.4-0.1,0.7-0.1c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.5-0.1,0.7-0.2c0.4-0.1,0.7-0.2,1.1-0.3c0.2-0.1,0.5-0.1,0.7-0.2
                           c0.3-0.1,0.7-0.2,1-0.3c0.4-0.1,0.6-0.2,0.7-0.2c0.9-0.3,1.6-0.6,2.2-0.9c0.4-0.1,0.7-0.3,0.9-0.4c0.4-0.2,0.9-0.4,1.3-0.6
                           c0.4-0.2,0.8-0.5,1.2-0.7c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.2,0.7-0.5,1-0.7c0.5-0.3,0.9-0.7,1.4-1
                           c0.7-0.5,1.4-1.1,2-1.8c0.5-0.4,0.9-0.8,1.3-1.3c0.7-0.7,1.3-1.4,1.8-2c0.1-0.1,0.1-0.1,0.2-0.2c1-1.2,2-2.5,2.7-3.8c0,0,0,0,0,0
                           c0,0,0-0.1,0.1-0.1c0.2-0.3,0.4-0.6,0.6-1c0,0,0.1-0.1,0.1-0.1c0.2-0.4,0.4-0.8,0.6-1.1c0.2-0.4,0.4-0.8,0.6-1.1
                           c0.2-0.5,0.4-1,0.6-1.5c0,0,0,0,0,0c0,0,0,0,0-0.1c0.2-0.5,0.3-0.9,0.5-1.4c0.3-1,0.5-2,0.7-2.8c0.1-0.5,0.2-1,0.2-1.5h5.1
                           c0.3,0,0.6,0,0.9,0.1c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.6,0.3
                           c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.5,0.4,0.7,0.6c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.2
                           c0.1,0.2,0.2,0.4,0.3,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0.1,0.2h-4.7c-2.8,0-5,2.2-5,5c0,0.3,0,0.7,0.1,1
                           c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.2,0.3,0.3
                           c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.6,0.4-0.9,0.5
                           c-0.2,0.1-0.3,0.2-0.5,0.3c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.3-0.6,0.5-0.9,0.8c-0.1,0.1-0.2,0.1-0.2,0.2
                           c-0.4,0.4-0.7,0.7-1.1,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.3-0.5,0.6-0.8,0.9c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.4,0.6-0.6,0.9
                           c-0.1,0.1-0.2,0.3-0.3,0.5c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.4-0.4,0.8-0.6,1.2c0,0.1-0.1,0.1-0.1,0.2
                           c-0.2,0.5-0.4,1-0.6,1.4c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.4-0.2,0.7-0.3,1.1c0,0.2-0.1,0.4-0.1,0.5c-0.1,0.3-0.1,0.7-0.2,1
                           c0,0.2-0.1,0.4-0.1,0.6C51,47.2,51,47.5,51,47.9c0,0.2,0,0.3-0.1,0.5c0,0.5-0.1,1-0.1,1.6c0,0.6,0,1.1,0.1,1.7
                           c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.7,0.1,1.1c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.1,0.7,0.2,1c0.1,0.2,0.1,0.4,0.2,0.7
                           c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.6,0.4,0.8
                           c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.3,0.5,0.5,0.8c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.2,0.2,0.3,0.4,0.5
                           c0.2,0.3,0.5,0.6,0.7,0.9c0.1,0.1,0.2,0.2,0.3,0.4c0.4,0.4,0.7,0.8,1.1,1.1c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.3,0.6,0.5,0.9,0.7
                           c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.2,0.5,0.4,0.8,0.6c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.5,0.3,0.8,0.5c0.2,0.1,0.4,0.2,0.6,0.3
                           c0.3,0.1,0.5,0.3,0.8,0.4c0.2,0.1,0.4,0.2,0.7,0.3c0.1,0.1,0.3,0.1,0.4,0.2c-0.4,0.4-0.8,0.7-1.2,1.1c-3.6,3-8.1,4.6-12.7,4.6
                           C45.5,74.9,41.2,73.3,37.5,70.3z M66.7,33.2L66.7,33.2c0.7-0.2,1.4-0.3,2.2-0.5c0.2,0,0.4-0.1,0.5-0.1c0.7-0.1,1.4-0.1,2.2-0.2
                           c0.2,0,0.3,0,0.5,0c0.4,0,0.8,0,1.3,0.1c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.6,0.1,0.8,0.2
                           c0.3,0.1,0.7,0.2,1,0.3c0.2,0.1,0.5,0.1,0.7,0.2c0.6,0.2,1.1,0.4,1.6,0.7c6,2.9,9.8,9,9.8,15.7c0,9.6-7.8,17.4-17.4,17.4
                           c-0.9,0-1.8-0.1-2.7-0.2c-0.6-0.1-1.1-0.2-1.6-0.3c-7.7-2-13.1-8.9-13.1-16.9c0-1.4,0.2-2.9,0.5-4.2C56.2,39.9,60.7,35,66.7,33.2z
                           M62,74.9c-0.1,0.6-0.2,1.2-0.3,1.7h-3.2C59.7,76.1,60.9,75.5,62,74.9z M26.6,79.9h14.3l-2.2,2.2L26.6,94.2V79.9z M43.6,96.6H29
                           l11-11l4.9,4.9L43.6,96.6z M47,96.6l1.3-6.3c0.1-0.5-0.1-1.1-0.5-1.5l-5.5-5.5l3.3-3.3h8.6l3.3,3.3l-5.5,5.5
                           c-0.4,0.4-0.6,1-0.5,1.5l1.3,6.3H47z M56.3,96.6l-1.2-6.1l4.9-4.9l11,11H56.3z M73.3,94.2L61.1,82.1L59,79.9h14.3V94.2z
                           M96.6,96.6h-20v-10h20V96.6z M96.6,83.2h-20v-3.3h20V83.2z M65.1,76.6c0.2-1.6,0.7-3.3,1.2-4c0.2-0.2,0.4-0.5,0.6-0.7
                           c0.6-0.6,1.3-1,2-1.4c0.1,0,0.2,0,0.3,0c0.8,0.1,1.7,0.2,2.5,0.2c11.4,0,20.7-9.3,20.7-20.7c0-7.9-4.6-15.2-11.7-18.7
                           c-0.6-0.3-1.1-0.5-1.7-0.7c-0.3-0.1-0.7-0.2-1.1-0.3c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4-0.1-0.8-0.2-1.2-0.3c-0.2,0-0.4-0.1-0.7-0.2
                           c-0.4-0.1-0.8-0.1-1.1-0.2c-0.2,0-0.5-0.1-0.7-0.1c-0.4,0-0.7,0-1.1,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.7,0-1,0c-0.3,0-0.6,0-0.9,0
                           c-0.3,0-0.6,0.1-1,0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0.1-0.6,0.1-0.9,0.2h-1c-0.9,0-1.7-0.7-1.7-1.7
                           c0-0.9,0.7-1.7,1.7-1.7h6.7c0.7,0,1.4,0,2.1,0.1c1.9,0.2,3.8,0.5,5.7,1.2c10.3,3.4,17.2,12.9,17.2,23.7l0.1,25H65.1z M37.1,48.8
                           l2.4,2.4c1.2-1.2,3.1-1.2,4.3,0l2.4-2.4C43.6,46.3,39.6,46.3,37.1,48.8z M59.9,50c0-6.4,5.2-11.7,11.7-11.7V35
                           c-8.3,0-15,6.7-15,15H59.9z M71.6,64.9c8.3,0,15-6.7,15-15h-3.3c0,6.4-5.2,11.7-11.7,11.7V64.9z M66.6,50c0,2.8,2.2,5,5,5
                           c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5C68.8,45,66.6,47.2,66.6,50z M73.3,50c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7
                           c0-0.9,0.7-1.7,1.7-1.7C72.5,48.3,73.3,49,73.3,50z"></path>
                        </g>
                     </g>
                  </svg>
               </div>
               <h3>Vision</h3>
               <p>To be the first <span class="tou-node" id="tou-0-1b675256-b029-4fae-b319-7378b1cabede"></span> for clients who want affordable, state-of-the-art <span class="tou-node" id="tou-0-cbed8252-84dd-46db-9c6d-811104622484"></span> solutions for multi-faceted business requirements.</p>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {

}
</script>

<style scoped>
.mission-n-vision-section {
	position: relative;
}.mission-n-vision-section .container {
	position: relative;
}
.mission-n-vision-box {
	-webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
	-moz-box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
	box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);
	padding: 45px 45px;
	background-color: #fff;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	min-height: 405px;
}
.mission-n-vision-icon {
	width: 90px;
	height: 90px;
	line-height: 90px;
	background-color: #1977cc;
	text-align: center;
	border-radius: 100%;
	margin-bottom: 20px;
	position: relative;
}
.mission-n-vision-icon::before {
	content: "";
	display: block;
	background-color: rgba(108, 31, 245, 0.2);
	position: absolute;
	left: 10px;
	top: -5px;
	right: -10px;
	bottom: -50px;
	width: 100%;
	height: 100%;
	border-radius: 100%;
}

.mission-n-vision-icon svg {
	width: 50px;
	height: 50px;
   fill:#fff;
}

.mission-n-vision-box h3 {
	font-size: 24px;
	font-weight: 500;
	color: #1977cc;
	margin-bottom: 18px;
}

</style>
