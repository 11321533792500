<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our offerings</span>
				<h2 class="fxt-process-dev">Our suite of PHP development services</h2>
				<p>
					Explore our growing range of PHP development services that aspires to enable every
					business with a custom-built website.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../../MobileDevelopment/AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Custom Web Development',
					text: 'Custom-built and not cookie-cut is the future of web development. We will help you leverage PHP’s open-source capabilities and thousands of libraries to build custom web applications.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M17.3,35.8c-0.3,0-0.5-0.1-0.7-0.3c-1.7-1.3-3.2-2.6-4.7-3.8c-0.6-0.5-0.9-1.2-0.9-1.9c0-0.8,0.4-1.5,1-2.1
                        c0.9-0.7,1.7-1.4,2.6-2.1l1.3-1c0.2-0.2,0.5-0.4,0.7-0.6c0.5-0.4,1.3-0.3,1.7,0.2c0.4,0.5,0.3,1.3-0.2,1.7
                        c-0.2,0.2-0.4,0.3-0.6,0.5l-1.3,1c-0.9,0.7-1.7,1.4-2.5,2.1c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0,0,0.1,0.1c1.5,1.2,3,2.5,4.7,3.8
                        c0.5,0.4,0.6,1.2,0.2,1.7C18,35.6,17.7,35.8,17.3,35.8z"></path>
                        <path class="st0" d="M32.7,35.8c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.3-1.3,0.2-1.7c1.7-1.3,3.2-2.6,4.7-3.8c0,0,0.1-0.1,0.1-0.1
                        c0,0,0-0.1-0.2-0.2c-0.8-0.7-1.7-1.4-2.5-2.1l-1.3-1c-0.2-0.2-0.4-0.4-0.6-0.5c-0.5-0.4-0.6-1.1-0.2-1.7c0.4-0.5,1.1-0.6,1.7-0.2
                        c0.3,0.2,0.5,0.4,0.7,0.6l1.3,1c0.9,0.7,1.7,1.4,2.6,2.1c0.7,0.5,1,1.3,1,2.1c0,0.7-0.3,1.4-0.9,1.9c-1.5,1.2-3,2.5-4.7,3.8
                        C33.2,35.7,32.9,35.8,32.7,35.8z"></path>
                        <path class="st0" d="M21.6,37.7c-0.2,0-0.4,0-0.5-0.1c-0.6-0.3-0.8-1-0.5-1.6l6.8-13.6c0.3-0.6,1-0.8,1.6-0.5
                        c0.6,0.3,0.8,1,0.5,1.6l-6.8,13.6C22.5,37.5,22,37.7,21.6,37.7z"></path>
                        <ellipse class="st0" cx="6.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <ellipse class="st0" cx="10.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <ellipse class="st0" cx="14.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <path class="st0" d="M45.6,50H4.4C2,50,0,48,0,45.6V20.2C0,19.5,0.5,19,1.2,19s1.2,0.5,1.2,1.2v25.4c0,1.1,0.9,2,2,2h41.2
                        c1.1,0,2-0.9,2-2V4.4c0-1.1-0.9-2-2-2H4.4c-1.1,0-2,0.9-2,2V8c0,1.1,0.9,2,2,2h38.4c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2H4.4
                        C2,12.4,0,10.5,0,8V4.4C0,2,2,0,4.4,0h41.2C48,0,50,2,50,4.4v41.2C50,48,48,50,45.6,50z"></path>
                        <ellipse class="st0" cx="1.2" cy="16.3" rx="1.2" ry="1.2"></ellipse>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Content Management System',
					text: 'Unique business models require robust content management systems. An off-the-shelf CMS may not serve your business well. Allow us to build one for you with the might of PHP.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <circle class="st0" cx="41.2" cy="9" r="0.8"></circle>
                     <circle class="st0" cx="38.1" cy="9" r="0.8"></circle>
                     <circle class="st0" cx="35" cy="9" r="0.8"></circle>
                     <path class="st0" d="M46.1,30.6c-0.4,0-0.8,0.3-0.8,0.8v13.8c0,0.7-0.6,1.2-1.2,1.2H2.8c-0.7,0-1.2-0.5-1.2-1.2V13.5h43.8v2.7
                     c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V6.9c0-1.5-1.3-2.7-2.8-2.7H2.8C1.3,4.1,0,5.3,0,6.9v38.3c0,1.5,1.3,2.7,2.8,2.7h41.3
                     c1.5,0,2.8-1.2,2.8-2.7V31.3C46.9,30.9,46.5,30.6,46.1,30.6z M2.8,5.7h41.3c0.7,0,1.2,0.5,1.2,1.2v5.1H1.6V6.9
                     C1.6,6.2,2.1,5.7,2.8,5.7z"></path>
                     <path class="st0" d="M22.7,35.4H5.5c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h17.2c0.4,0,0.8-0.3,0.8-0.8
                     C23.4,35.7,23.1,35.4,22.7,35.4z"></path>
                     <path class="st0" d="M22.7,40.1H5.5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h17.2c0.4,0,0.8-0.3,0.8-0.8S23.1,40.1,22.7,40.1z"></path>
                     <path class="st0" d="M37.5,19c0-0.4-0.3-0.8-0.8-0.8h-9.4c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h9.4
                     C37.2,19.7,37.5,19.4,37.5,19z"></path>
                     <path class="st0" d="M27.3,22.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.3c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H27.3z"></path>
                     <path class="st0" d="M45.1,18.4c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L28.6,33.8c-0.1,0.1-0.2,0.2-0.2,0.3l-1.8,6.5
                     c-0.1,0.4,0.1,0.8,0.5,1c0.1,0,0.3,0,0.4,0l6.5-1.8c0.1,0,0.2-0.1,0.3-0.2l15.4-15.4c0.3-0.3,0.3-0.8,0-1.1c0,0,0,0,0,0L45.1,18.4z
                     M29,37.8l1.4,1.4l-1.9,0.5L29,37.8z M33.4,38.3l-1.4,0.4l-2.6-2.6l0.4-1.4l11.6-11.6l3.6,3.6L33.4,38.3z M46.1,25.7l-3.6-3.6l2-2
                     l3.6,3.6L46.1,25.7z"></path>
                     <path class="st0" d="M23.4,19L23.4,19C23.4,18.9,23.4,18.9,23.4,19c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
                     c0,0,0-0.1,0-0.1l0,0l0,0l-0.1-0.1l-0.1-0.1l-0.1,0l-0.1,0l-0.1,0l-0.1,0h-0.1h-0.1H5.4H5.3H5.3l-0.1,0l-0.1,0l-0.1,0l-0.1,0
                     l-0.1,0.1l-0.1,0.1l0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1s0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1l0,0v12.5l0,0
                     c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1l0,0l0,0c0,0,0.1,0.1,0.1,0.1l0.1,0
                     l0.1,0.1l0.1,0l0.1,0h0c0.1,0,0.1,0,0.2,0.1h17.2c0.1,0,0.1,0,0.2,0h0l0.1,0l0.1,0l0.1-0.1l0.1,0c0,0,0.1-0.1,0.1-0.1l0,0l0,0
                     c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l0,0V19z M6.2,20.5l6.5,4.7l-6.5,4.7
                     V20.5z M7.9,19.7h12.4l-6.2,4.5L7.9,19.7z M14.1,26.2l6.2,4.5H7.9L14.1,26.2z M15.4,25.2l6.5-4.7v9.4L15.4,25.2z"></path>
                  </svg>
          `
				},
				{
					title: 'CRM Solutions',
					text: 'As an open-source development framework, PHP makes it possible to build high-end CRM solutions that can help manage your customer data, sales pipeline and selling efforts.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M94.6,10H5.4c-3,0.2-5.4,2.6-5.4,5.3v69.3c0,3,2.4,5.3,5.4,5.3h89.3c3,0,5.4-2.4,5.4-5.3V15.3C100,12.4,97.6,10,94.6,10z
                           M24,86H5.4C4.6,86,4,85.5,4,84.9V29.8h20V86z M96,84.9c0,0.4-0.2,0.8-0.4,1c-0.2,0.2-0.6,0.4-1,0.4H28V29.8h68V84.9z M96,26H4
                           V15.3c0-0.6,0.6-1.2,1.4-1.2h89.3c0.8,0,1.4,0.6,1.4,1.4V26z M8.9,40.8h10.3c1.2,0,2-0.8,2-2c0-1.2-0.8-2-2-2H8.9
                           c-1.2,0-2,0.8-2,2C7,40,7.7,40.8,8.9,40.8z M8.9,49.1h10.3c1.2,0,2-0.8,2-2c0-1.2-1-2-2.2-2H8.9c-1.2,0-2,0.8-2,2
                           C7,48.3,7.7,49.1,8.9,49.1z M8.9,57.4h10.3c1.2,0,2-0.8,2-2c0-1.2-1-2-2.2-2H8.9c-1.2,0-2,0.8-2,2C7,56.6,7.7,57.4,8.9,57.4z
                           M8.9,65.5h3.4c1.2,0,2-0.8,2-2c0-1.2-1-2-2-2H8.9c-1.2,0-2,0.8-2,2C7,64.7,7.7,65.5,8.9,65.5z M8.9,73.8h3.4c1.2,0,2-0.8,2-2
                           c0-1.2-1-2-2-2H8.9c-1.2,0-2,0.8-2,2C7,73,7.7,73.8,8.9,73.8z M44,67.5c2.2,0,4-0.4,5-0.8l-0.6-2c-1,0.4-2.4,0.8-4,0.8
                           c-4.6,0-7.1-3-7.1-7.5c0-4.9,3-7.7,7.3-7.7c1.6,0,2.8,0.4,3.8,0.8l0.6-2c-0.6-0.4-2.2-0.8-4.4-0.8c-5.8,0-9.7,4-9.7,9.9
                           C34.7,64.3,38.7,67.5,44,67.5z M54.6,59.2h2c2.2,0,3.2,1,3.8,3.6c0.6,2.4,1,4,1.2,4.5h2.6c-0.4-0.8-0.8-2.6-1.4-5.1
                           c-0.4-2-1.4-3.4-2.8-3.8c2-0.6,3.6-2.4,3.6-4.7c0-1.4-0.6-2.8-1.4-3.6c-1.2-1-2.8-1.6-5.4-1.6c-1.6,0-3.4,0.2-4.6,0.4v18.4h2.4
                           V59.2z M56.7,50.3c2.6,0,4.4,1,4.4,3.6c0,2.2-1.6,3.6-4.2,3.6h-2.4v-6.9h-0.2C54.8,50.3,55.6,50.3,56.7,50.3z M69.2,59.2
                           c0.2-2.8,0.2-5.5,0.4-8.3c0.6,2.2,1.2,4.5,2.2,7.3l3,8.9h1.8L80,58c0.8-2.4,1.6-4.7,2.4-7.1c0,2.4,0.2,5.5,0.2,8.1l0.4,8.3h2.8
                           l-1.2-18.8h-3.2L78,57.6c-0.8,2.4-1.6,4.3-2,6.3c-0.4-2-1.2-3.9-2-6.3l-3.2-9.1h-3l-1.4,18.8h2.4L69.2,59.2z"></path>
                        </g>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Enterprise Web Portal',
					text: 'PHP’s server-side scripting will help create web-based solutions, desktop applications and customized enterprise applications that can help your business work efficiently.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M17.3,50.5H0V23h4.3c0-0.1,0-0.1,0-0.2c0-12.3,10.2-22.3,22.8-22.3c12.6,0,22.8,10,22.8,22.3S39.8,45,27.2,45
                        c-2,0-3.9-0.2-5.8-0.7v2.5L17.3,50.5z M1.8,48.8h14l0-3.6h3.8V24.7H1.8V48.8z M17.5,46.9l0,1l1.1-1H17.5z M21.4,42.5
                        c1.1,0.3,2.3,0.5,3.4,0.6c-1.3-1.2-2.4-2.5-3.4-3.8C21.4,39.4,21.4,42.5,21.4,42.5z M37.8,30.4c-1.4,4.5-4.2,8.8-8.2,12.8
                        c7.8-0.9,14.3-5.9,17.1-12.8C46.7,30.4,37.8,30.4,37.8,30.4z M28,30.4v11.9c3.9-3.7,6.5-7.7,7.9-11.9H28z M21.4,36.4
                        c1.3,2,3,3.9,4.9,5.8V30.4h-4.9V36.4z M38.3,28.7h9c0.6-1.9,0.9-3.9,0.9-5.9c0-2.1-0.3-4.1-0.9-5.9h-9.1c0.5,2.1,0.8,4.3,0.8,6.6
                        C38.9,25.2,38.7,27,38.3,28.7z M28,28.7h8.5c0.4-1.7,0.7-3.5,0.7-5.3c0-2.3-0.3-4.5-0.8-6.6H28V28.7z M21.4,28.7h4.9V16.8H18
                        c-0.5,1.9-0.8,4-0.8,6.2h4.2V28.7z M6.1,23h9.3c0-2.2,0.3-4.2,0.8-6.2H7c-0.6,1.9-0.9,3.9-0.9,5.9C6.1,22.8,6.1,22.9,6.1,23z
                        M37.7,15.1h9C44,8.5,37.9,3.7,30.6,2.5C32.6,4.8,35.9,9.3,37.7,15.1z M28,15.1h7.8C33.6,8.4,29.2,3.5,28,2.3V15.1z M18.5,15.1h7.8
                        V2.4C25,3.7,20.7,8.5,18.5,15.1z M7.6,15.1h9.1c1.8-5.8,5.2-10.3,7.2-12.6C16.4,3.6,10.3,8.5,7.6,15.1z"></path>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Custom eCommerce',
					text: 'An eCommerce store might begin with one product and scale upto a million of product listing. We will help you scale seamlessly and effortlessly with our PHP development expertise.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M46.8,4.1H3.2c-1.7,0-3.1,1.4-3.1,3.1v29.5c0,1.7,1.4,3.1,3.1,3.1H18l-0.6,4.8h-2.5c-0.4,0-0.7,0.3-0.7,0.7
                           s0.3,0.7,0.7,0.7h20.2c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-2.5L32,39.7h14.7c1.7,0,3.1-1.4,3.1-3.1V7.1
                           C49.8,5.4,48.4,4.1,46.8,4.1z M18.8,44.5l0.6-4.8h2.7l0.3,1c0.3,1.2,1.4,2.1,2.6,2.1c1.2,0,2.3-0.8,2.6-2.1l0.3-1h2.7l0.6,4.8
                           H18.8z M23.6,39.7h2.8l-0.2,0.7c-0.1,0.6-0.6,1-1.2,1c-0.6,0-1.1-0.4-1.2-1L23.6,39.7z M48.4,36.7L48.4,36.7
                           c0,0.9-0.7,1.6-1.6,1.6H3.2c-0.9,0-1.6-0.7-1.6-1.6V7.1c0-0.9,0.7-1.6,1.6-1.6h43.5c0.9,0,1.6,0.7,1.6,1.6L48.4,36.7L48.4,36.7z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path class="st0" d="M41.3,7.2H4c-0.4,0-0.7,0.3-0.7,0.7v28c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7
                           c0-0.4-0.3-0.7-0.7-0.7H4.8V8.6h36.6c0.4,0,0.7-0.3,0.7-0.7C42,7.5,41.7,7.2,41.3,7.2z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path class="st0" d="M46,7.2h-1.6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h0.8v26.5H8.7c-0.4,0-0.7,0.3-0.7,0.7
                           c0,0.4,0.3,0.7,0.7,0.7H46c0.4,0,0.7-0.3,0.7-0.7v-28C46.7,7.5,46.4,7.2,46,7.2z"></path>
                        </g>
                     </g>
                     <g>
                        <g>
                           <path class="st0" d="M34.9,15.2c-0.1-0.2-0.3-0.3-0.5-0.3l-17.1-0.8c0,0,0,0,0,0c-0.1-0.3-0.3-0.6-0.6-0.8L14.5,12
                           c-0.3-0.2-0.8-0.1-1,0.2s-0.1,0.8,0.2,1l2.1,1.4c0,0,0,0,0,0l2.7,9.3c0.1,0.3,0.2,0.6,0.4,0.8l-1,2c-0.2,0.5-0.2,1,0.1,1.5
                           c0.1,0.2,0.2,0.3,0.4,0.4c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-0.3-0.1-0.6-0.2-0.8h6.6
                           c-0.1,0.3-0.2,0.5-0.2,0.8c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0-0.4-0.1-0.7-0.2-1c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4-0.3-0.7-0.7-0.7
                           H19.3c0,0,0,0,0,0c0,0,0,0,0,0l0.9-1.8c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3,0l10.2-1.2c1.3-0.2,2.4-1.1,2.6-2.4l1.2-6.1
                           C35.1,15.6,35,15.4,34.9,15.2z M31.2,28.8c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S30.8,28.8,31.2,28.8z
                           M20.3,28.8c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8S19.9,28.8,20.3,28.8z M18.6,18.8l-0.9-3.2
                           l2.9,0.1l0.5,3.1H18.6z M20.8,24.1c-0.4,0-0.8-0.2-0.9-0.6L19,20.3h2.3l0.6,3.7L20.8,24.1z M25.1,23.6l-1.7,0.2l-0.6-3.5h2.3V23.6
                           z M25.1,18.8h-2.5l-0.5-3l3,0.1V18.8z M28.7,23.2l-2.2,0.3v-3.2H29L28.7,23.2z M29.1,18.8h-2.6V16l2.9,0.1L29.1,18.8z M32.4,21.6
                           c-0.1,0.7-0.7,1.2-1.4,1.3L30.2,23l0.2-2.7h2.2L32.4,21.6z M32.9,18.8h-2.4l0.2-2.6l2.6,0.1L32.9,18.8z"></path>
                        </g>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Cloud SaaS Application',
					text: 'The future of the web is vested in the cloud. A cloud-based SaaS solution can empower your workforce, lighten your workload and maximize productivity. Our PHP development services offers all of that.',
					svg: `
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g transform="translate(0,-952.36218)">
                        <g>
                           <path class="st0" d="M25.1,960.3c-7.4,0-13.6,5.3-14.6,12.4h-0.1c-5.6,0-10.1,4.7-10.1,10.3c0,5.6,4.5,10.1,10.1,10.1h29.5
                           c5.6,0,10.1-4.5,10.1-10.1c0-5.6-4.5-10.3-10.1-10.3h-0.1C38.7,965.6,32.5,960.3,25.1,960.3z M25.1,962.5
                           c6.7,0,12.1,4.9,12.6,11.4c0,0.6,0.5,1.1,1.1,1.1h1.1c4.3,0,7.8,3.7,7.8,8c0,4.3-3.5,7.8-7.8,7.8H10.4c-4.4,0-7.8-3.5-7.8-7.8
                           c0-4.3,3.5-8,7.8-8h1.1c0.6,0,1.1-0.5,1.1-1.1C13.1,967.4,18.5,962.5,25.1,962.5z"></path>
                        </g>
                     </g>
                     <g>
                        <path class="st0" d="M14.6,30.4c0.5,0.3,1.2,0.5,2,0.5c0.9,0,1.5-0.4,1.5-1.1c0-0.6-0.4-1-1.4-1.3c-1.3-0.5-2.2-1.2-2.2-2.4
                        c0-1.3,1.1-2.3,2.9-2.3c0.9,0,1.5,0.2,1.9,0.4l-0.4,1.2C18.6,25.2,18,25,17.3,25c-0.9,0-1.3,0.5-1.3,1c0,0.6,0.5,0.9,1.5,1.3
                        c1.4,0.5,2.1,1.2,2.1,2.4c0,1.3-1,2.4-3.1,2.4c-0.9,0-1.7-0.2-2.2-0.5L14.6,30.4z"></path>
                        <path class="st0" d="M24.2,32l-0.1-0.6h0c-0.4,0.5-1,0.8-1.7,0.8c-1.2,0-1.8-0.9-1.8-1.7c0-1.5,1.3-2.2,3.5-2.2v-0.1
                        c0-0.4-0.2-1-1.2-1c-0.6,0-1.2,0.2-1.6,0.4l-0.3-1c0.4-0.3,1.2-0.5,2.1-0.5c1.9,0,2.4,1.2,2.4,2.5v2.1c0,0.5,0,1,0.1,1.4H24.2z
                        M24,29.2c-1,0-2,0.2-2,1.1c0,0.6,0.4,0.8,0.8,0.8c0.6,0,1-0.4,1.2-0.8c0-0.1,0-0.2,0-0.3V29.2z"></path>
                        <path class="st0" d="M30.3,32l-0.1-0.6h0c-0.4,0.5-1,0.8-1.7,0.8c-1.2,0-1.8-0.9-1.8-1.7c0-1.5,1.3-2.2,3.5-2.2v-0.1
                        c0-0.4-0.2-1-1.2-1c-0.6,0-1.2,0.2-1.6,0.4l-0.3-1c0.4-0.3,1.2-0.5,2.1-0.5c1.9,0,2.4,1.2,2.4,2.5v2.1c0,0.5,0,1,0.1,1.4H30.3z
                        M30.1,29.2c-1,0-2,0.2-2,1.1c0,0.6,0.4,0.8,0.8,0.8c0.6,0,1-0.4,1.2-0.8c0-0.1,0-0.2,0-0.3V29.2z"></path>
                        <path class="st0" d="M33,30.7c0.3,0.2,1,0.4,1.5,0.4c0.6,0,0.9-0.3,0.9-0.6c0-0.4-0.2-0.6-1-0.9c-1.1-0.4-1.6-1-1.6-1.7
                        c0-1,0.9-1.8,2.2-1.8c0.6,0,1.2,0.2,1.5,0.3l-0.3,1c-0.3-0.1-0.7-0.3-1.2-0.3c-0.5,0-0.8,0.3-0.8,0.6c0,0.4,0.3,0.6,1,0.8
                        c1.1,0.4,1.5,0.9,1.6,1.8c0,1.1-0.8,1.8-2.4,1.8c-0.7,0-1.3-0.2-1.8-0.4L33,30.7z"></path>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Social Networking Solutions',
					text: 'Collaboration ranks high on the must-have digital capabilities for the future. With PHP by your side, you can build a social networking website that will become the centre of all collaborative efforts.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M45.6,29.8c-0.2,0-0.3,0-0.5,0l-0.7-9.1c3.2-0.8,5.6-3.6,5.6-7.1c0-4-3.3-7.3-7.3-7.3c-2.4,0-4.5,1.2-5.8,2.9
                        l-4.3-3.1c0.3-0.6,0.4-1.2,0.4-1.9c0-2.4-2-4.4-4.4-4.4s-4.4,2-4.4,4.4c0,0.2,0,0.3,0,0.5l-9.8,1.4C14,2.8,10.9,0,7.3,0
                        C3.3,0,0,3.3,0,7.3c0,3.1,2,5.8,4.8,6.8l-0.6,3.7C1.8,17.9,0,19.8,0,22.2c0,2.4,2,4.4,4.4,4.4c0.6,0,1.1-0.1,1.6-0.3l1.9,5.6
                        c-0.9,1.2-1.5,2.7-1.5,4.4C6.5,38,7,39.6,8,40.8l-1.1,1.1c-0.7-0.5-1.6-0.8-2.5-0.8c-2.4,0-4.4,2-4.4,4.4C0,48,2,50,4.4,50
                        s4.4-2,4.4-4.4c0-0.9-0.3-1.8-0.8-2.5L9.2,42c1.2,1,2.8,1.6,4.5,1.6c2.7,0,5-1.5,6.3-3.6l5,2.5c0,0.1,0,0.2,0,0.3
                        c0,4,3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3c0-1-0.2-2-0.6-2.9l3.6-2.4c0.8,0.8,1.9,1.3,3.1,1.3c2.4,0,4.4-2,4.4-4.4S48,29.8,45.6,29.8z
                        M39.5,18.4c0.1-1.2,1.1-2.2,2.4-2.2h1.6c1.3,0,2.3,1,2.4,2.2c-0.9,0.6-2,1-3.2,1S40.4,19,39.5,18.4z M42.7,14.5
                        c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C44.4,13.8,43.6,14.5,42.7,14.5z M42.7,8.1c3.1,0,5.6,2.5,5.6,5.6
                        c0,1.2-0.4,2.4-1.1,3.3c-0.4-0.9-1.1-1.7-2-2.1c0.4-0.6,0.7-1.2,0.7-2c0-1.8-1.4-3.2-3.2-3.2s-3.2,1.4-3.2,3.2c0,0.8,0.3,1.5,0.7,2
                        c-0.9,0.4-1.7,1.2-2,2.1c-0.7-0.9-1.1-2.1-1.1-3.3C37.1,10.6,39.6,8.1,42.7,8.1z M35.5,13.7c0,0.6,0.1,1.2,0.2,1.8l-4.5,1.9
                        c-0.6-2.2-2.3-4-4.4-4.9L28,8.8c0.2,0,0.4,0,0.6,0c1.2,0,2.3-0.5,3.1-1.2l4.4,3.1C35.7,11.7,35.5,12.7,35.5,13.7z M13.7,29
                        c0,0-0.1,0-0.1,0l-1.5-7.9l4.9-0.8c0.3,2,1.4,3.8,2.9,4.9l-2.7,4.7C16.2,29.4,15,29,13.7,29z M18.5,19.4c0-3.1,2.5-5.6,5.6-5.6
                        s5.6,2.5,5.6,5.6S27.3,25,24.2,25S18.5,22.5,18.5,19.4z M28.6,1.6c1.6,0,2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8S25.8,6,25.8,4.4
                        S27.1,1.6,28.6,1.6z M1.6,7.3c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6s-2.5,5.6-5.6,5.6S1.6,10.4,1.6,7.3z M7.3,14.5
                        c0.6,0,1.3-0.1,1.9-0.3l0.2,1.2l1.6-0.3l-0.3-1.5c2.1-1.1,3.6-3.2,3.8-5.7l10.2-1.5c0.4,0.8,1,1.4,1.8,1.8l-1.3,3.9
                        c-0.3,0-0.7-0.1-1-0.1c-3.8,0-6.9,2.9-7.2,6.6l-5.2,0.9l-0.5-2.7l-1.6,0.3l0.5,2.7l-1.8,0.3c-0.5-1-1.5-1.8-2.6-2.2l0.6-3.5
                        C6.6,14.5,6.9,14.5,7.3,14.5z M1.6,22.2c0-1.6,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S6,25,4.4,25S1.6,23.7,1.6,22.2z M8.9,22.2
                        c0-0.2,0-0.4,0-0.5l1.6-0.3l1.5,7.9c-1,0.3-2,0.7-2.8,1.4l-1.7-5.2C8.3,24.6,8.9,23.5,8.9,22.2z M4.4,48.4c-1.6,0-2.8-1.3-2.8-2.8
                        c0-1.6,1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8C7.3,47.1,6,48.4,4.4,48.4z M13.7,41.9c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6
                        c3.1,0,5.6,2.5,5.6,5.6l-8.1-4.5v9l8.1-4.5C19.3,39.4,16.8,41.9,13.7,41.9z M16.1,36.3l-3.2,1.8v-3.5L16.1,36.3z M20.6,38.5
                        c0.2-0.7,0.3-1.4,0.3-2.2c0-0.6-0.1-1.2-0.2-1.8l1.3-0.5l-0.6-1.5L20.2,33c-0.4-0.8-1-1.5-1.6-2.1l2.8-4.9c0.9,0.4,1.9,0.6,2.9,0.6
                        c4,0,7.3-3.3,7.3-7.3c0-0.1,0-0.2,0-0.3l4.8-2.1c0.5,1,1.3,1.9,2.2,2.6l-1.6,3.1c-0.3-0.1-0.7-0.1-1-0.1c-2.4,0-4.4,2-4.4,4.4
                        c0,0.6,0.1,1.1,0.3,1.6l-8.7,3.2l0.6,1.5l9-3.4c0.3,0.4,0.7,0.7,1.2,0.9l-0.7,4.6c-0.3,0-0.6-0.1-0.9-0.1c-3.3,0-6.1,2.2-7,5.3
                        L20.6,38.5z M33.1,27c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8s-1.3,2.8-2.8,2.8C34.3,29.8,33.1,28.6,33.1,27z M32.3,48.4
                        c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6S35.4,48.4,32.3,48.4z M34.7,35.9l0.6-4.5c0.2,0,0.3,0,0.5,0
                        c2.4,0,4.4-2,4.4-4.4c0-1.5-0.8-2.8-1.9-3.6l1.5-3c0.9,0.4,1.8,0.6,2.8,0.6c0,0,0,0,0.1,0l0.7,9.4c-1.4,0.7-2.4,2.2-2.4,3.9
                        c0,0.7,0.1,1.3,0.4,1.8l-3.5,2.3C37.2,37.3,36.1,36.4,34.7,35.9z M45.6,37.1c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
                        c1.6,0,2.8,1.3,2.8,2.8C48.4,35.8,47.1,37.1,45.6,37.1z"></path>
                        <path class="st0" d="M29,46h6.5v-6.5H29V46z M30.6,41.1h3.2v3.2h-3.2V41.1z"></path>
                        <rect x="6.5" y="6.5" class="st0" width="1.6" height="1.6"></rect>
                        <rect x="9.7" y="6.5" class="st0" width="1.6" height="1.6"></rect>
                        <rect x="3.2" y="6.5" class="st0" width="1.6" height="1.6"></rect>
                        <path class="st0" d="M27.4,19.4c0-1.8-1.4-3.2-3.2-3.2S21,17.6,21,19.4s1.4,3.2,3.2,3.2C26,22.6,27.4,21.1,27.4,19.4z M22.6,19.4
                        c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6S25.1,21,24.2,21S22.6,20.2,22.6,19.4z"></path>
                     </g>
                  </svg>
          `
				},
				{
					title: 'Backend Solutions',
					text: 'Like a performance car’s turbo-powered engine, every robust web application requires a strong backend. We will help you build the most powerful, stable &amp; scalable backends with PHP as the foundation.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M4.4,15.9l3,1.5c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.7-0.4c0.2-0.4,0-0.8-0.3-1l-1.7-0.8l1.7-0.8
                        c0.4-0.2,0.5-0.6,0.3-1c-0.2-0.4-0.6-0.5-1-0.3l-3,1.5C4.2,14.6,4,14.9,4,15.2C4,15.5,4.2,15.7,4.4,15.9z"></path>
                        <path class="st0" d="M26.1,17.1c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1l3-1.5c0.3-0.1,0.4-0.4,0.4-0.7c0-0.3-0.2-0.6-0.4-0.7
                        l-3-1.5c-0.4-0.2-0.8,0-1,0.3c-0.2,0.4,0,0.8,0.3,1l1.7,0.8L26.5,16C26.1,16.2,26,16.7,26.1,17.1z"></path>
                        <path class="st0" d="M11.5,19.4c-0.2-0.4-0.6-0.5-1-0.3l-3,1.5c-0.3,0.1-0.4,0.4-0.4,0.7s0.2,0.6,0.4,0.7l3,1.5
                        c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.7-0.4c0.2-0.4,0-0.8-0.3-1l-1.7-0.8l1.7-0.8C11.6,20.2,11.7,19.8,11.5,19.4z"></path>
                        <path class="st0" d="M8.5,6.9c-0.2-0.4-0.6-0.5-1-0.3l-3,1.5C4.2,8.2,4,8.4,4,8.7s0.2,0.6,0.4,0.7l3,1.5C7.6,11,7.7,11,7.8,11
                        c0.3,0,0.5-0.2,0.7-0.4c0.2-0.4,0-0.8-0.3-1L6.5,8.7l1.7-0.8C8.5,7.7,8.7,7.2,8.5,6.9z"></path>
                        <path class="st0" d="M30.2,8.1l-3-1.5c-0.4-0.2-0.8,0-1,0.3c-0.2,0.4,0,0.8,0.3,1l1.7,0.8l-1.7,0.8c-0.4,0.2-0.5,0.6-0.3,1
                        c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1l3-1.5c0.3-0.1,0.4-0.4,0.4-0.7S30.5,8.2,30.2,8.1z"></path>
                        <path class="st0" d="M1.7,26.6V3.8c0-0.8,0.7-1.5,1.5-1.5h28.1c0.8,0,1.5,0.7,1.5,1.5V19h1.5V3.8c0-1.7-1.4-3-3-3H3.3
                        c-1.7,0-3,1.4-3,3v22.8c0,1.7,1.4,3,3,3h9.9v-1.5H3.3C2.4,28.1,1.7,27.4,1.7,26.6z"></path>
                        <path class="st0" d="M45.8,20.5H17.7c-1.7,0-3,1.4-3,3v22.8c0,1.7,1.4,3,3,3h28.1c1.7,0,3-1.4,3-3V23.6
                        C48.9,21.9,47.5,20.5,45.8,20.5z M17.7,22h28.1c0.8,0,1.5,0.7,1.5,1.5v1.5H16.2v-1.5C16.2,22.7,16.9,22,17.7,22z M45.8,47.9H17.7
                        c-0.8,0-1.5-0.7-1.5-1.5V26.6h31.2v19.8C47.3,47.2,46.7,47.9,45.8,47.9z"></path>
                        <path class="st0" d="M25.3,44.8h-6.8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.8c0.4,0,0.8-0.3,0.8-0.8S25.7,44.8,25.3,44.8z"></path>
                        <path class="st0" d="M35.2,44.8h-6.8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.8c0.4,0,0.8-0.3,0.8-0.8S35.6,44.8,35.2,44.8z"></path>
                        <path class="st0" d="M45.1,44.8h-6.8c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.8c0.4,0,0.8-0.3,0.8-0.8S45.5,44.8,45.1,44.8z"></path>
                        <path class="st0" d="M24.5,37.2h-5.3c-0.8,0-1.5,0.7-1.5,1.5v3c0,0.8,0.7,1.5,1.5,1.5h5.3c0.8,0,1.5-0.7,1.5-1.5v-3
                        C26.1,37.9,25.4,37.2,24.5,37.2z M24.5,41.8h-5.3v-3h5.3V41.8z"></path>
                        <path class="st0" d="M44.3,37.2H39c-0.8,0-1.5,0.7-1.5,1.5v3c0,0.8,0.7,1.5,1.5,1.5h5.3c0.8,0,1.5-0.7,1.5-1.5v-3
                        C45.8,37.9,45.1,37.2,44.3,37.2z M44.3,41.8H39v-3h5.3V41.8z"></path>
                        <path class="st0" d="M34.4,37.2h-5.3c-0.8,0-1.5,0.7-1.5,1.5v3c0,0.8,0.7,1.5,1.5,1.5h5.3c0.8,0,1.5-0.7,1.5-1.5v-3
                        C35.9,37.9,35.3,37.2,34.4,37.2z M34.4,41.8h-5.3v-3h5.3V41.8z"></path>
                        <path class="st0" d="M44.3,28.1H19.2c-0.8,0-1.5,0.7-1.5,1.5v4.6c0,0.8,0.7,1.5,1.5,1.5h25.1c0.8,0,1.5-0.7,1.5-1.5v-4.6
                        C45.8,28.8,45.1,28.1,44.3,28.1z M44.3,34.2H19.2v-4.6h25.1V34.2z"></path>
                        <circle class="st0" cx="41.6" cy="23.6" r="0.8"></circle>
                        <circle class="st0" cx="44.7" cy="23.6" r="0.8"></circle>
                        <path class="st0" d="M15.2,9.5c0.4,0,0.8-0.3,0.8-0.8S15.6,8,15.2,8h-0.6c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8H15.2z"></path>
                        <path class="st0" d="M20.1,9.5c0.4,0,0.8-0.3,0.8-0.8S20.5,8,20.1,8h-1.3C18.3,8,18,8.3,18,8.7s0.3,0.8,0.8,0.8H20.1z"></path>
                        <path class="st0" d="M24.2,8h-0.6c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h0.6c0.4,0,0.8-0.3,0.8-0.8S24.6,8,24.2,8z"></path>
                        <path class="st0" d="M24,14.4h-0.6c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8H24c0.4,0,0.8-0.3,0.8-0.8
                        C24.7,14.8,24.4,14.4,24,14.4z"></path>
                        <path class="st0" d="M19.1,16h1.2c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-1.2c-0.4,0-0.8,0.3-0.8,0.8
                        C18.3,15.6,18.7,16,19.1,16z"></path>
                        <path class="st0" d="M14.8,16h1.2c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-1.2c-0.4,0-0.8,0.3-0.8,0.8
                        C14,15.6,14.3,16,14.8,16z"></path>
                        <path class="st0" d="M12.4,15.2c0-0.4-0.3-0.8-0.8-0.8H11c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h0.6
                        C12,16,12.4,15.6,12.4,15.2z"></path>
                        <path class="st0" d="M11.2,10c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.6,0.6,0.6c0.3,0,0.5-0.2,0.6-0.5C11.8,10.2,11.6,9.9,11.2,10z"></path>
                        <path class="st0" d="M12,7.1C12,7.1,12,7,12,7.1c0-0.4-0.4-0.7-0.8-0.7c-0.4,0-0.7,0.2-0.7,0.6c0,0,0.2,2.1,0.3,2.4
                        c0,0.1,0,0.1,0.1,0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.1-0.1c0-0.2,0.1-0.5,0.1-0.7c0-0.4,0.1-0.7,0.1-1.1C12,7.4,12,7.3,12,7.1z"></path>
                        <path class="st0" d="M15.7,18.3c-0.4-0.2-0.8,0-1,0.4l-1.9,4.6c-0.1,0.4,0,0.8,0.3,0.9v-0.7c0-2,1.2-3.6,3-4.3
                        C16.3,18.9,16.1,18.5,15.7,18.3z"></path>
                     </g>
                  </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
