<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<WhyChooseUs></WhyChooseUs>

		<Types></Types>

    <TypeDiv></TypeDiv>

    <Portfolio></Portfolio>

    <Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/GameDevelopment/AndroidDevelopment/Banner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import About from '../../components/GameDevelopment/AndroidDevelopment/About.vue';
import Types from '../../components/GameDevelopment/AndroidDevelopment/Types.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import WhyChooseUs from '../../components/GameDevelopment/AndroidDevelopment/WhyChooseUs.vue';
import Portfolio from '../../components/Home/Portfolio.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		Types,
    TypeDiv,
		WhyChooseUs,
    Portfolio,
    Const
	}
};
</script>

<style></style>
