<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/codeigniter-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Mission-focused PHP Codeigniter Development Services
					</h2>
					<p>
						There are millions of web applications in the internet. But, not all of them make it to
						the leaderboard. The ones that secure the ranks in the leaderboard are lightweight in
						footprint but heavy-duty in performance. If you are yearning to build a web application
						that can make it to the leaderboards, CodeIgniter is the right choice. It is a powerful
						PHP framework that can help build web applications that does not demand heavy resources
						to run smoothly.
					</p>
					<p>
						As a provider of PHP CodeIgniter development services, Genixbit helps you build web
						applications that have a small footprint but a large scope of application. Our PHP
						expertise will ensure that you are able to build dynamic websites that can interact
						fluently with users. As a matter of fact, a lion share of the enterprise-grade
						applications we have delivered are built with the power of CodeIgniter.
					</p>
					<p>
						We harness the power of CodeIgniter open-source development to build web applications
						that can load in as quick as 50 ms. To amplify application performance we also make use
						of CodeIgniter’s built-in MVC Architecture which keeps logic, presentation and data
						separate from each other. We also have a dedicated team of quality assurance
						professionals who ensure that the application is run down for any bugs or errors before
						it can be pushed to live production.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
