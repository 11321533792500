<template>
	<div class="divvv">
		<h1>
			Let's Build Great Things Together
			<span>Call Us at:<a href="tel:+917000830437" class="text-white">&nbsp;+917000830437</a></span>
		</h1>
		<h4>OR</h4>
		<a data-toggle="modal" data-target="#get-qoute" class="m-0 appointment-btn scrollto"
			style="cursor:pointer;user-select:none"
			>Request Free Quote</a
		>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.divvv {
	width: 100%;
	background-color: #202122;
	padding: 10px 60px;
	justify-content: space-between;
	display: flex;
	align-items: center;
	color: #fff;
	position: relative;
}

.divvv h4 {
	margin-bottom: -0.1rem;
}
.divvv h1 {
	padding: 0;
	margin: 0;
	font-size: 1.5rem;
	font-weight: bold;
}

.divvv span a {
	margin: 0 15px;
}
.divvv span {
	margin: 0 10px;
	color: #1977cc;
}
</style>
