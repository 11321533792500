<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Why should Genixbit be your Choice for Nodejs Development</h2>
				<p>
					Our experience and track record in executing big ticket projects have earned us a
					reputation as a reliable Node JS Development Company. Join our growing list of clients.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Large Exposure",
               text:"Our teams have exposure to big ticket projects. Leverage our experience and expertise to develop projects that fully meet requirements.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M39.7,33.6l0.7-4.3c0,0,0-0.1,0-0.1c0-1.3-1.1-2.3-2.3-2.3h-5.7c-1.5-0.5-2.4-2.2-2-3.7c2.4-1.5,4.1-3.9,4.5-6.6
                        C35.6,15,38.5,9,35.1,5.2c-0.3-0.3-4.1-4.8-9.6-5c-3.1-0.1-6.1,1.2-8.8,4c-1.5,1-2.6,2.5-3,4.2c-0.4,2,0.1,4.1,1.6,6.3
                        c0,3.4,1.8,6.6,4.7,8.3c0.4,1.6-0.4,3.2-2,3.8h-5.9c-1.3,0-2.3,1-2.3,2.3c0,0,0,0.1,0,0.1l0.7,4.4c-1.8,2.5-2.7,5.4-2.7,8.5V48
                        c0,1.4,1.1,2.5,2.5,2.5H40c1.4,0,2.5-1.1,2.5-2.5v-5.8C42.4,38.7,41.5,36,39.7,33.6z M15.4,8.8c0.3-1.3,1.2-2.5,2.3-3.3
                        c0.1,0,0.1-0.1,0.2-0.1c2.4-2.5,5-3.7,7.6-3.6c4.7,0.2,8.3,4.4,8.4,4.4l0,0c2.4,2.7,0.5,7.3-0.3,8.9c-0.1-0.1-0.1-0.2-0.2-0.3
                        c0,0,0-0.1-0.1-0.1l-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.2c0-0.1,0-0.1-0.1-0.2
                        c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2l-0.1-0.4l0-0.2c0-0.2,0-0.4-0.1-0.7c0-0.9-0.5-1.7-1.2-2.3c-0.1-0.1-0.3-0.2-0.4-0.3
                        l-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2h-0.1c-0.1,0-0.3-0.1-0.4-0.1h-0.1c-0.1,0-0.3,0-0.4,0h-0.1c-0.2,0-0.3,0-0.5,0
                        C25,9.4,18.7,9,18.6,9c-0.5,0-0.8,0.3-0.9,0.8c0,0,0,0.1,0,0.1c0.2,1.5-0.1,3-0.7,4.4L16.8,14C15.5,12.1,15,10.3,15.4,8.8z
                        M17.2,16.6L17.2,16.6c0.1,0,0.2,0,0.3-0.1h0c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,1.8-1.9,1.7-5.6c2,0.1,6.6,0.2,9.8-0.3
                        c0.4-0.1,0.8,0,1.1,0.3c0.3,0.3,0.5,0.7,0.5,1.1c0,0.1,0,0.3,0,0.4v0.1l0,0.3c0,0.1,0,0.1,0,0.2s0,0.2,0,0.3c0,0.1,0,0.1,0,0.2
                        l0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0.1,0.3,0.1,0.4v0c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1c0,0,0.1,0.2,0.1,0.3l0.1,0.1l0.1,0.3
                        c0,0,0,0.1,0.1,0.1l0.1,0.3l0,0.1c0.1,0.1,0.1,0.2,0.2,0.3l0,0c0.1,0.1,0.1,0.2,0.2,0.2l0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0.1
                        c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1l0,0c-0.6,2.1-1.9,3.8-3.8,4.9c-0.1,0-0.2,0.1-0.2,0.1c-4,2.1-9,0.6-11.1-3.4c-0.3-0.6-0.6-1.3-0.7-2
                        L17.2,16.6z M21.7,23.9c2.2,0.8,4.6,0.8,6.8,0c0,1.1,0.4,2.1,1,2.9h-8.8C21.4,26,21.8,25,21.7,23.9z M12.1,28.5H38
                        c0.4,0,0.7,0.3,0.7,0.6l-2.4,15.7c0,0,0,0.1,0,0.1c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0H14.5c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0
                        c0,0,0-0.1,0-0.1l-2.4-15.7C11.4,28.8,11.7,28.5,12.1,28.5z M9.3,48v-5.8c0-2.1,0.5-4.1,1.5-6l1.4,8.9c0,0.2,0,0.4,0.1,0.6h-0.4
                        c-0.8,0-1.5,0.7-1.5,1.5c0,0,0,0,0,0v1.6h-0.1C9.7,48.8,9.4,48.4,9.3,48C9.4,48,9.4,48,9.3,48L9.3,48z M12,48.8v-1.4h25.7v1.4
                        L12,48.8z M40.8,48c0,0.5-0.4,0.8-0.8,0.8h-0.6v-1.6c0-0.8-0.7-1.5-1.5-1.5c0.1-0.2,0.1-0.4,0.1-0.6l1.4-9.1c1,1.9,1.6,4,1.5,6.2
                        L40.8,48z"></path>
                        <circle cx="22.3" cy="13.4" r="1.3"></circle>
                        <circle cx="28.1" cy="13.4" r="1.3"></circle>
                        <path d="M28.2,18.4c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.5-0.4-0.8-0.8-0.8
                        s-0.8,0.4-0.8,0.8c0,1.7,1.4,3.1,3.1,3.1C26.8,21.5,28.2,20.1,28.2,18.4z"></path>
                        <path d="M21.2,41.9c0.2,0.2,0.4,0.2,0.6,0.2c0.5,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.4-0.2-0.6l-3.7-3.7l3.7-3.7c0.3-0.3,0.4-0.8,0-1.2
                        c-0.3-0.3-0.8-0.4-1.2,0c0,0,0,0,0,0l-4.2,4.2c-0.3,0.3-0.3,0.8,0,1.2L21.2,41.9z"></path>
                        <path d="M28,33.4l3.7,3.7L28,40.8c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l4.3-4.3
                        c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6l-4.2-4.2c-0.3-0.3-0.9-0.3-1.2,0C27.7,32.6,27.7,33.1,28,33.4L28,33.4z"></path>
                        <path d="M23.1,43.2c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.2,0.8-0.5l3.6-10.6c0.2-0.4-0.1-0.9-0.5-1.1c-0.4-0.2-0.9,0.1-1.1,0.5l-3.6,10.7
                        C22.4,42.6,22.7,43.1,23.1,43.2z"></path>
                     </svg>
               `
            },
            {
               title:"Experience",
               text:"Cross trained, expert developers on teams. Every member on our team is cross trained; this fosters collaboration from a trained and informed perspective.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path d="M46.8,21.9l-2.8-0.8c0.6-0.7,1-1.5,1.2-2.4c0.5-0.3,0.8-0.8,0.9-1.5l0.2-1.3c0.1-0.7-0.1-1.4-0.5-1.9c0,0-0.1-0.1-0.1-0.1
                           c0,0,0,0,0,0l0-1.6c0-2.5-2-4.4-4.5-4.4h-1.9c-2.5,0-4.5,2-4.5,4.4l0,1.6L34.7,14c-0.4,0.5-0.6,1.2-0.5,1.8l0.2,1.3
                           c0.1,0.6,0.4,1.1,0.9,1.5c0.2,0.9,0.6,1.7,1.2,2.4l-2.8,0.8l1.1,0.3c0.7,0.2,1.3,0.4,1.9,0.8l0.1,0.1l1.1-0.3
                           c0.3-0.1,0.5-0.3,0.5-0.7v-1.8l-0.2-0.1c-0.6-0.5-1-1.2-1.1-2l0-0.2c0-0.2,0-0.4,0-0.4v-0.3h-0.6c-0.1,0-0.2-0.1-0.2-0.2L36,15.6
                           c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h0.3l0.1-2.9c0-1.4,1.2-2.5,2.6-2.5h1.9c1.4,0,2.6,1.1,2.6,2.5l0.1,2.8h0.3
                           c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0.1,0.1,0.3L44.3,17c0,0.1-0.1,0.2-0.2,0.2h-0.5l-0.1,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.3
                           c-0.1,0.8-0.5,1.5-1.1,2l-0.2,0.2V22c0,0.3,0.2,0.6,0.5,0.7l3.7,1.1c1,0.2,1.8,1.1,1.8,2.2v8.5l-8.8,5.2v2.2l9.9-5.8l0.8-0.5V26
                           C50.1,24,48.8,22.3,46.8,21.9z"></path>
                           <path d="M30.1,9.6V7.7h-3.9c-1,0-2-0.6-2.5-1.4l-0.9-1.6l-1.4,2.6c-0.2,0.3-0.4,0.4-0.7,0.4h-0.9l0,2h1c0.9,0,1.7-0.4,2.3-1.2
                           c0.9,0.8,2,1.2,3.1,1.2H30.1z"></path>
                           <path d="M34.7,22.5l-4.8-1.4v-0.9c1.1-1,1.8-2.4,1.9-3.9c0.7-0.3,1.2-1,1.3-1.8l0.3-2c0.1-0.7-0.1-1.5-0.6-2.1
                           c-0.1-0.2-0.3-0.3-0.4-0.4l-0.1-2.8c0-3.3-2.7-5.9-6-5.9h-2.8c-3.3,0-6,2.6-6,5.9L17.5,10c-0.1,0.1-0.3,0.2-0.4,0.4
                           c-0.5,0.6-0.7,1.3-0.6,2.1l0.3,2c0.1,0.8,0.6,1.4,1.3,1.8c0.2,1.4,0.9,2.8,1.9,3.9v0.9L16.9,22l-3.5-1c0.6-0.7,1-1.5,1.2-2.4
                           c0.5-0.3,0.8-0.9,0.9-1.4l0.2-1.3c0.1-0.7-0.1-1.4-0.5-1.9L15.1,14c0,0,0,0-0.1-0.1l0-1.6c0-2.5-2-4.4-4.5-4.4H8.7
                           c-2.5,0-4.5,2-4.5,4.4l0,1.6L4,14.1c-0.4,0.5-0.6,1.2-0.5,1.8l0.2,1.3c0.1,0.6,0.4,1.1,0.9,1.4c0.1,0.9,0.6,1.7,1.2,2.4L3,21.8
                           c-1.9,0.4-3.3,2.2-3.3,4.1v9.7l10.7,6.4v-2.2l-8.8-5.2v-8.5c0-1.1,0.7-2,1.8-2.2l3.7-1.1c0.3-0.1,0.5-0.4,0.5-0.7v-1.7l-0.2-0.2
                           c-0.6-0.5-1-1.2-1.1-2c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.4l-0.1-0.2H5.8c-0.1,0-0.2-0.1-0.2-0.2l-0.2-1.4c0-0.1,0-0.2,0.1-0.3
                           c0.1-0.1,0.2-0.1,0.3-0.1H6l0.1-2.9c0-1.4,1.2-2.6,2.6-2.6h1.9c1.4,0,2.6,1.1,2.6,2.6l0.1,2.8h0.3c0.1,0,0.2,0,0.3,0.1
                           c0,0,0.1,0.1,0.1,0.3l-0.2,1.3c0,0.1-0.1,0.2-0.2,0.2h-0.5l-0.1,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.3c-0.1,0.8-0.5,1.5-1.1,2
                           l-0.2,0.2v1.8c0,0.3,0.2,0.6,0.5,0.7l1.2,0.3l0.1-0.1c0.7-0.4,1.2-0.6,1.8-0.8h1.5l-1.3,0.4c-2.5,0.5-4.3,2.8-4.3,5.4V42l13.4,7.9
                           l0.8,0.5l13.1-8l0.8-0.5V27.9C39,25.3,37.2,23.1,34.7,22.5z M22,21.9v-0.3c0.7,0.4,1.4,0.6,2.1,0.6h1v-1.9h-1c-0.5,0-1-0.1-1.4-0.4
                           l-1-0.7c-1-0.8-1.6-1.9-1.7-3.2c0-0.4-0.1-0.9-0.1-1.1l0-0.3h-0.7c-0.2,0-0.5-0.2-0.5-0.4l-0.3-2c0-0.2,0-0.4,0.2-0.6
                           c0.1-0.2,0.3-0.3,0.5-0.3h0.3l0.1-4.2c0-2.2,1.8-4,4.1-4h2.8c2.2,0,4.1,1.8,4.1,4l0.1,4.2h0.3c0.2,0,0.4,0.1,0.5,0.2
                           c0.1,0.2,0.2,0.4,0.2,0.6l-0.3,2c0,0.2-0.2,0.4-0.5,0.4H30l0,0.3c0,0.2-0.1,0.6-0.1,1.1c-0.1,1.2-0.7,2.4-1.7,3.2l-0.3,0.2v5.4h1.9
                           v-1.6l4.4,1.3c1.6,0.3,2.8,1.8,2.8,3.5V41l-11.9,7.2l-12.3-7.3v-13c0-1.7,1.2-3.2,2.9-3.5l5.6-1.6C21.7,22.7,22,22.3,22,21.9z"></path>
                           <path d="M21.6,38.4c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L20.5,35l1.8-1.8c0.2-0.2,0.3-0.4,0.3-0.6
                           c0-0.3-0.1-0.5-0.3-0.7c-0.4-0.4-1-0.4-1.4,0l-2.5,2.5c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7l2.5,2.5
                           C21.1,38.3,21.4,38.4,21.6,38.4z"></path>
                           <path d="M27.6,38.2c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l2.5-2.5c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7l-2.5-2.5
                           c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.8,1.8l-1.8,1.8C27.2,37.2,27.2,37.8,27.6,38.2z"></path>
                           <path d="M24.1,38.4c0,0,0.1,0,0.2,0c0.4,0,0.8-0.3,0.9-0.7l1.3-5c0.1-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.3-0.4-0.6-0.5
                           c-0.3-0.1-0.5,0-0.8,0.1c-0.2,0.1-0.4,0.3-0.4,0.6l-1.3,5c-0.1,0.3,0,0.5,0.1,0.8C23.6,38.2,23.8,38.4,24.1,38.4z"></path>
                           <path d="M24.9,44.5c5.2,0,9.5-4.3,9.5-9.5c0-5.2-4.3-9.5-9.5-9.5s-9.5,4.3-9.5,9.5C15.4,40.3,19.7,44.5,24.9,44.5z M17.3,35
                           c0-4.2,3.4-7.6,7.6-7.6c4.2,0,7.6,3.4,7.6,7.6c0,4.2-3.4,7.6-7.6,7.6C20.8,42.6,17.3,39.2,17.3,35z"></path>
                        </g>
                     </svg>
               `
            },
            {
               title:"Dedicated",
               text:"We have a dedicated team of developers for NodeJS Development. Teams exclusively handle NodeJS Development and this specialization offers greater focus.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path class="st0" d="M25,24.2c4.2,0,7.2-5.4,7.2-9c0-3.9-3.3-7.1-7.2-7.1c-4,0-7.2,3.2-7.2,7.1C17.8,18.8,20.8,24.2,25,24.2z
                           M25,9.7c3.1,0,5.6,2.4,5.6,5.5c0,3.2-2.7,7.4-5.6,7.4c-2.9,0-5.6-4.2-5.6-7.4C19.4,12.2,21.9,9.7,25,9.7z"></path>
                           <path class="st0" d="M40.6,27c3.3,0,5.6-4.2,5.6-7c0-3-2.5-5.5-5.6-5.5c-3.1,0-5.6,2.5-5.6,5.5C35,22.9,37.4,27,40.6,27z
                           M40.6,16.1c2.2,0,4,1.7,4,3.9c0,2.3-2,5.4-4,5.4c-2.1,0-4-3-4-5.4C36.6,17.9,38.4,16.1,40.6,16.1z"></path>
                           <path class="st0" d="M9.4,27c3.3,0,5.6-4.2,5.6-7c0-3-2.5-5.5-5.6-5.5c-3.1,0-5.6,2.5-5.6,5.5C3.8,22.9,6.2,27,9.4,27z M9.4,16.1
                           c2.2,0,4,1.7,4,3.9c0,2.3-2,5.4-4,5.4s-4-3-4-5.4C5.4,17.9,7.2,16.1,9.4,16.1z"></path>
                           <path class="st0" d="M48.6,28.5c-1.8-2-4.6-1.9-4.6-1.9l-0.2,0.2c-1.9,1.4-4.1,1.4-6.1,0l-0.2-0.2c0,0-0.7-0.1-1.8,0.2
                           c-2.7-2.8-6.1-2.6-6.1-2.6l-0.2,0.2c-2.7,1.9-5.8,1.9-8.4,0l-0.2-0.2c0,0-3.3-0.3-6.1,2.6c-1.1-0.3-1.8-0.2-1.8-0.2l-0.2,0.2
                           c-1.9,1.4-4.1,1.4-6.1,0l-0.2-0.2c0,0-2.9-0.1-4.6,1.9c-1.1,1.2-1.6,2.8-1.4,4.3l0.7,7.5h12.8l0.2,1.6h22.4l0.2-1.6h12.8l0.7-7.5
                           C50.1,31.2,49.6,29.7,48.6,28.5z M12.9,32.2l0.6,6.5H2.3l-0.6-6c-0.1-1.1,0.3-2.3,1-3.1c0.7-0.8,1.8-1.3,2.9-1.3
                           c2.3,1.6,5.2,1.6,7.5,0c0.1,0,0.3,0,0.4,0C13,29.5,12.7,30.8,12.9,32.2z M35.6,32l-0.8,8.3H15.3L14.5,32c-0.2-1.6,0.4-3.2,1.4-4.3
                           c1.1-1.2,2.6-1.9,4.2-1.9h0c3.1,2.1,6.8,2.1,9.9,0h0c1.6,0,3.1,0.7,4.2,1.9C35.3,28.8,35.8,30.4,35.6,32z M47.8,38.7H36.6l0.6-6.5
                           c0.1-1.4-0.1-2.7-0.7-3.9c0.1,0,0.3,0,0.4,0c2.3,1.6,5.2,1.6,7.5,0c1.1,0,2.2,0.5,2.9,1.3c0.8,0.8,1.1,2,1,3.1L47.8,38.7z"></path>
                        </g>
                     </svg>
               `
            },
            {
               title:"Support",
               text:"Our support & maintenance have been critical dimensions of our success. Projects go beyond delivery with extended support and maintenance offering true value to clients.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M48.9,12.4c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-1-0.7-1.6-0.7c-2.2,0.1-3.8,1.9-3.8,4.1l0.1,3.6l-0.6,2.5c-0.7,0-1.5,0.3-2,0.8
                        l-4,4.1l-1.4,0.2c-0.9,0.2-1.7,0.6-2.4,1.2l-2.8,2.8c-0.8,0.8-1.3,1.9-1.3,3.1l-0.1,4.6c-1.3,0.4-2.2,1.7-2.2,3.1l-0.1,6.1
                        c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.4,1,2.3,1l9.9,0.1c0,0,0,0,0,0c0.9,0,1.7-0.3,2.3-0.9c0.6-0.6,1-1.4,1-2.3l0.1-6.1
                        c0-0.9-0.3-1.7-0.9-2.3c-0.3-0.4-0.8-0.6-1.2-0.8l0-0.1c0.2-1,0.6-2,1.3-2.7l4.7-4.7c1.2-1.2,1.9-2.8,2.1-4.5l0.5-4.7
                        c0-0.2,0.1-0.4,0.1-0.5l0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1L48.9,12.4z M40.9,40.7l-0.1,6.1c0,0.4-0.2,0.8-0.5,1.1
                        c-0.3,0.3-0.7,0.5-1.1,0.5l-9.9-0.1c-0.4,0-0.8-0.2-1.1-0.5s-0.5-0.7-0.5-1.2l0.1-6.1c0-0.9,0.7-1.6,1.6-1.6c0,0,0,0,0,0l9.9,0.1
                        c0.4,0,0.8,0.2,1.1,0.5C40.8,39.8,40.9,40.2,40.9,40.7z M45.3,28.8l-4.7,4.7c-1,1-1.6,2.2-1.8,3.6l0,0.2L30,37.2l0.1-4.4
                        c0-0.7,0.3-1.4,0.8-1.9l2.8-2.8c0.4-0.4,0.9-0.7,1.5-0.8l1.6-0.3c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0,0
                        l4.2-4.2c0.5-0.5,1.2-0.5,1.7,0s0.5,1.3,0,1.7l-4.8,4.9c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
                        l4.8-4.9c1.1-1.1,1.1-3,0-4.1c-0.1-0.1-0.3-0.3-0.4-0.4l0.7-2.7c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0l-0.1-3.8
                        c0-1.3,0.9-2.3,2.2-2.4c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.4l0.2,7.6l-0.1,0.5c0,0,0,0,0,0.1l-0.5,4.6
                        C46.8,26.6,46.2,27.9,45.3,28.8z"></path>
                        <ellipse cx="39" cy="41.4" rx="1.4" ry="1.4"></ellipse>
                        <path d="M21.9,37.4l-0.1-4.6c0-1.2-0.5-2.3-1.3-3.1l-2.8-2.8c-0.6-0.7-1.5-1.1-2.4-1.2L14,25.4l-4-4.1c-0.6-0.6-1.3-0.8-2-0.8
                        L7.4,18l0.1-3.6c0.1-2.2-1.6-4-3.8-4.1c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,0.9-0.6,1.5l-0.2,7.7c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0
                        c0,0.2,0,0.4,0.1,0.5l0.5,4.7c0.2,1.7,0.9,3.3,2.1,4.5l4.7,4.7c0.7,0.7,1.2,1.7,1.3,2.7l0,0.1C9.4,37.7,9,38,8.7,38.3
                        c-0.6,0.6-0.9,1.5-0.9,2.3l0.1,6.1c0,0.9,0.4,1.7,1,2.3c0.6,0.6,1.4,0.9,2.3,0.9c0,0,0,0,0,0l9.9-0.1c0.9,0,1.7-0.4,2.3-1
                        s0.9-1.5,0.9-2.3l-0.1-6.1C24.2,39,23.2,37.8,21.9,37.4z M9.7,33.5L5,28.8c-0.9-0.9-1.5-2.2-1.6-3.5l-0.5-4.6c0,0,0,0,0-0.1
                        l-0.1-0.5l0.2-7.6c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c1.2,0,2.2,1.1,2.2,2.4l-0.1,3.8c0,0,0,0,0,0c0,0,0,0.1,0,0.1
                        c0,0,0,0.1,0,0.1c0,0,0,0,0,0L6.4,21c-0.2,0.1-0.3,0.2-0.4,0.4c-1.1,1.1-1.1,3,0,4.1l4.8,4.9c0.2,0.2,0.4,0.2,0.6,0.2
                        s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.9,0-1.2l-4.8-4.9c-0.5-0.5-0.5-1.3,0-1.7c0.5-0.5,1.2-0.5,1.7,0l4.2,4.2c0,0,0,0,0,0
                        c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0l1.6,0.3c0.6,0.1,1.1,0.4,1.5,0.8l2.8,2.8c0.5,0.5,0.8,1.2,0.8,1.9l0.1,4.4
                        l-8.8,0.1l0-0.2C11.3,35.8,10.7,34.5,9.7,33.5z M22.1,47.7c-0.3,0.3-0.7,0.5-1.1,0.5l-9.9,0.1c-0.4,0-0.8-0.2-1.1-0.5
                        c-0.3-0.3-0.5-0.7-0.5-1.1l-0.1-6.1c0-0.4,0.2-0.8,0.5-1.2c0.3-0.3,0.7-0.5,1.1-0.5l9.9-0.1c0,0,0,0,0,0c0.9,0,1.6,0.7,1.6,1.6
                        l0.1,6.1C22.6,47,22.4,47.4,22.1,47.7z"></path>
                        <ellipse cx="11.4" cy="41.4" rx="1.4" ry="1.4"></ellipse>
                        <path d="M14.1,15.8h1.5c0.2,0.7,0.5,1.3,0.8,1.9l-1.1,1.1c-0.7,0.7-0.7,1.8,0,2.5l1.5,1.6c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5
                        l1.1-1.1c0.6,0.3,1.3,0.6,1.9,0.8v1.6c0,1,0.8,1.8,1.7,1.8h2.2c1,0,1.7-0.8,1.7-1.8v-1.6c0.7-0.2,1.3-0.5,1.9-0.8l1.1,1.1
                        c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5l1.5-1.6c0.7-0.7,0.7-1.8,0-2.5l-1.1-1.1c0.3-0.6,0.6-1.3,0.8-1.9h1.5
                        c1,0,1.7-0.8,1.7-1.8v-2.2c0-1-0.8-1.8-1.7-1.8h-1.5c-0.2-0.7-0.5-1.3-0.8-1.9L35,7c0.7-0.7,0.7-1.8,0-2.5L33.4,3
                        c-0.7-0.7-1.8-0.7-2.4,0l-1.1,1.1c-0.6-0.3-1.3-0.6-1.9-0.8V1.7c0-1-0.8-1.8-1.7-1.8h-2.2c-1,0-1.7,0.8-1.7,1.8v1.6
                        c-0.7,0.2-1.3,0.5-1.9,0.8L19.3,3c-0.7-0.7-1.8-0.7-2.4,0l-1.5,1.6c-0.7,0.7-0.7,1.8,0,2.5l1.1,1.1c-0.3,0.6-0.6,1.3-0.8,1.9h-1.5
                        c-1,0-1.7,0.8-1.7,1.8v2.2C12.4,15,13.1,15.8,14.1,15.8z M14,11.9C14,11.8,14,11.8,14,11.9l2.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0
                        c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.2
                        c0,0,0,0,0,0c0.2-0.9,0.6-1.8,1.1-2.6c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3
                        c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-1.5-1.6c0,0,0-0.1,0-0.1L18,4.2c0,0,0.1,0,0.1,0l1.5,1.6
                        c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0,0.1,0C20.1,6,20.2,6,20.3,6c0,0,0,0,0,0h0c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0
                        c0,0,0.1,0,0.1,0c0.8-0.5,1.7-0.9,2.6-1.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1
                        c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1
                        c0,0,0,0,0-0.1v0c0,0,0,0,0,0V1.7c0-0.1,0-0.1,0.1-0.1h2.2c0.1,0,0.1,0,0.1,0.1v2.2c0,0,0,0,0,0v0c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
                        c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0.1,0.1
                        c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.9,0.2,1.8,0.6,2.6,1.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
                        C29.8,6,29.9,6,30,6h0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0,0,0,0l1.5-1.6c0,0,0.1,0,0.1,0
                        l1.5,1.6c0,0,0,0.1,0,0.1l-1.5,1.6c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0C32.1,7.8,32,7.9,32,8
                        c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0.5,0.8,0.9,1.7,1.1,2.6c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.2
                        c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0h2.2
                        c0.1,0,0.1,0,0.1,0.1v2.2c0,0.1,0,0.1-0.1,0.1h-2.2c0,0,0,0,0,0h0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                        c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1
                        c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.2,0.9-0.6,1.8-1.1,2.6c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
                        c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0l0,0c0,0,0,0,0,0l1.5,1.6c0,0,0,0.1,0,0.1l-1.5,1.6
                        c0,0-0.1,0-0.1,0l-1.5-1.6c0,0,0,0,0,0l0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0
                        c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.8,0.5-1.7,0.9-2.6,1.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
                        c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                        c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1v2.2c0,0.1,0,0.1-0.1,0.1h-2.2c-0.1,0-0.1,0-0.1-0.1V22c0,0,0,0,0-0.1
                        c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1
                        c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.9-0.2-1.8-0.6-2.6-1.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0
                        c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0l0,0c0,0,0,0,0,0
                        l-1.5,1.6c0,0-0.1,0-0.1,0l-1.5-1.6c0,0,0-0.1,0-0.1l1.5-1.6c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0
                        c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c-0.5-0.8-0.9-1.7-1.1-2.6c0,0,0,0,0,0
                        c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1,0
                        c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0h0c0,0,0,0,0,0h-2.2c-0.1,0-0.1,0-0.1-0.1L14,11.9
                        L14,11.9z"></path>
                        <path d="M25.2,17.6c2.5,0,4.6-2.1,4.6-4.6s-2-4.6-4.6-4.6s-4.6,2.1-4.6,4.6S22.6,17.6,25.2,17.6z M25.2,10c1.6,0,2.9,1.3,2.9,2.9
                        s-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9S23.6,10,25.2,10z"></path>
                     </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
