<template>
	<div>
		<div class="solution-we-offer-box clearfix">
			<div class="solution-we-offer-image">
				<img
					alt=""
					class="lazy rounded10 box-shadow"
					:src="require(`../../../assets/images${obj.imageLink}`)"
					style=""
				/>
			</div>
			<div class="solution-we-offer-content rounded10 box-shadow">
				<h3>{{ obj.title }}</h3>
				<p>{{ obj.text }}</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.solution-we-offer-box {
	position: relative;
}
.clearfix {
	clear: both;
}
.clearfix::after {
	display: block;
	clear: both;
	content: '';
}
.solution-we-offer-image {
	width: 268px;
	float: none;
	position: relative;
	left: 0;
	top: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.solution-we-offer-content {
	max-width: 370px;
	width: 100%;
	padding: 30px 30px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	border: 1px solid #ecedee;
	background-color: #fff;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.solution-we-offer-content h3 {
	font-size: 18px;
	font-weight: 600;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.solution-we-offer-content p:last-child {
	margin-bottom: 0;
}
.solution-we-offer-box:hover .solution-we-offer-image {
	position: relative;
	left: -5px;
	top: -5px;
}
.solution-we-offer-box:hover .solution-we-offer-content h3 {
	color: #0082dd;
}
</style>