<template>
	<form
		id="contact_form"
		v-on:submit.prevent="sendEmail"
		method="post"
		enctype="multipart/form-data"
		novalidate="novalidate"
		class="bv-form"
		action="info@mnwebbased.in"
	>
		<div class="row">
			<div class="form-group col-md-6 col-12">
				<input
					type="text"
					name="from_name"
					class="form-control"
					placeholder="Name"
					required
					v-model="from_name"
					onfocus="this.placeholder = ''"
					onblur="this.placeholder = 'Name'"
					data-bv-field="name"
				/>
				<small
					style="display: none"
					class="help-block"
					data-bv-validator="notEmpty"
					data-bv-for="name"
					data-bv-result="NOT_VALIDATED"
					>The name is required and cannot be empty</small
				>
			</div>
			<div class="form-group col-md-6 col-12">
				<input
					type="email"
					name="from_email"
					class="form-control"
					placeholder="Email"
					required
					onfocus="this.placeholder = ''"
					onblur="this.placeholder = 'Email'"
					data-bv-field="email"
				/>
				<small
					style="display: none"
					class="help-block"
					data-bv-validator="notEmpty"
					data-bv-for="email"
					data-bv-result="NOT_VALIDATED"
					>The email address is required and cannot be empty</small
				><small
					style="display: none"
					class="help-block"
					data-bv-validator="emailAddress"
					data-bv-for="email"
					data-bv-result="NOT_VALIDATED"
					>The email address is not a valid</small
				>
			</div>
			<div class="form-group col-md-6 col-12">
				<div class="phone-field">
					<div class="con-num">
						<div class="iti iti--allow-dropdown iti--separate-dial-code fxt-iti--allow-dropdown">
							<select name="countryCode" id="" onfocus='this.size=5;' onblur='this.size=1;' style="border-color:#ced4da;border-radius:3px 0 0 3px"
                    onchange='this.size=1; this.blur();'>
										<option data-countryCode="IN" value="91">(+91)</option>
										<option data-countryCode="DZ" value="213">(+213)</option>
										<option data-countryCode="AD" value="376">(+376)</option>
										<option data-countryCode="AO" value="244">(+244)</option>
										<option data-countryCode="AI" value="1264">(+1264)</option>
										<option data-countryCode="AG" value="1268">(+1268)</option>
										<option data-countryCode="AR" value="54">(+54)</option>
										<option data-countryCode="AM" value="374">(+374)</option>
										<option data-countryCode="AW" value="297">(+297)</option>
										<option data-countryCode="AU" value="61">(+61)</option>
										<option data-countryCode="AT" value="43">(+43)</option>
										<option data-countryCode="AZ" value="994">(+994)</option>
										<option data-countryCode="BS" value="1242">(+1242)</option>
										<option data-countryCode="BH" value="973">(+973)</option>
										<option data-countryCode="BD" value="880">(+880)</option>
										<option data-countryCode="BB" value="1246">(+1246)</option>
										<option data-countryCode="BY" value="375">(+375)</option>
										<option data-countryCode="BE" value="32">(+32)</option>
										<option data-countryCode="BZ" value="501">(+501)</option>
										<option data-countryCode="BJ" value="229">(+229)</option>
										<option data-countryCode="BM" value="1441">(+1441)</option>
										<option data-countryCode="BT" value="975">(+975)</option>
										<option data-countryCode="BO" value="591">(+591)</option>
										<option data-countryCode="BA" value="387">(+387)</option>
										<option data-countryCode="BW" value="267">(+267)</option>
										<option data-countryCode="BR" value="55">(+55)</option>
										<option data-countryCode="BN" value="673">(+673)</option>
										<option data-countryCode="BG" value="359">(+359)</option>
										<option data-countryCode="BF" value="226">(+226)</option>
										<option data-countryCode="BI" value="257">(+257)</option>
										<option data-countryCode="KH" value="855">(+855)</option>
										<option data-countryCode="CM" value="237">(+237)</option>
										<option data-countryCode="CA" value="1">(+1)</option>
										<option data-countryCode="CV" value="238">(+238)</option>
										<option data-countryCode="KY" value="1345">(+1345)</option>
										<option data-countryCode="CF" value="236">(+236)</option>
										<option data-countryCode="CL" value="56">(+56)</option>
										<option data-countryCode="CN" value="86">(+86)</option>
										<option data-countryCode="CO" value="57">(+57)</option>
										<option data-countryCode="KM" value="269">(+269)</option>
										<option data-countryCode="CG" value="242">(+242)</option>
										<option data-countryCode="CK" value="682">(+682)</option>
										<option data-countryCode="CR" value="506">(+506)</option>
										<option data-countryCode="HR" value="385">(+385)</option>
										<option data-countryCode="CU" value="53">(+53)</option>
										<option data-countryCode="CY" value="90392">(+90392)</option>
										<option data-countryCode="CY" value="357">(+357)</option>
										<option data-countryCode="CZ" value="42">(+42)</option>
										<option data-countryCode="DK" value="45">(+45)</option>
										<option data-countryCode="DJ" value="253">(+253)</option>
										<option data-countryCode="DM" value="1809">(+1809)</option>
										<option data-countryCode="DO" value="1809">(+1809)</option>
										<option data-countryCode="EC" value="593">(+593)</option>
										<option data-countryCode="EG" value="20">(+20)</option>
										<option data-countryCode="SV" value="503">(+503)</option>
										<option data-countryCode="GQ" value="240">(+240)</option>
										<option data-countryCode="ER" value="291">(+291)</option>
										<option data-countryCode="EE" value="372">(+372)</option>
										<option data-countryCode="ET" value="251">(+251)</option>
										<option data-countryCode="FK" value="500">(+500)</option>
										<option data-countryCode="FO" value="298">(+298)</option>
										<option data-countryCode="FJ" value="679">(+679)</option>
										<option data-countryCode="FI" value="358">(+358)</option>
										<option data-countryCode="FR" value="33">(+33)</option>
										<option data-countryCode="GF" value="594">(+594)</option>
										<option data-countryCode="PF" value="689">(+689)</option>
										<option data-countryCode="GA" value="241">(+241)</option>
										<option data-countryCode="GM" value="220">(+220)</option>
										<option data-countryCode="GE" value="7880">(+7880)</option>
										<option data-countryCode="DE" value="49">(+49)</option>
										<option data-countryCode="GH" value="233">(+233)</option>
										<option data-countryCode="GI" value="350">(+350)</option>
										<option data-countryCode="GR" value="30">(+30)</option>
										<option data-countryCode="GL" value="299">(+299)</option>
										<option data-countryCode="GD" value="1473">(+1473)</option>
										<option data-countryCode="GP" value="590">(+590)</option>
										<option data-countryCode="GU" value="671">(+671)</option>
										<option data-countryCode="GT" value="502">(+502)</option>
										<option data-countryCode="GN" value="224">(+224)</option>
										<option data-countryCode="GW" value="245">(+245)</option>
										<option data-countryCode="GY" value="592">(+592)</option>
										<option data-countryCode="HT" value="509">(+509)</option>
										<option data-countryCode="HN" value="504">(+504)</option>
										<option data-countryCode="HK" value="852">(+852)</option>
										<option data-countryCode="HU" value="36">(+36)</option>
										<option data-countryCode="IS" value="354">(+354)</option>
										<option data-countryCode="ID" value="62">(+62)</option>
										<option data-countryCode="IR" value="98">(+98)</option>
										<option data-countryCode="IQ" value="964">(+964)</option>
										<option data-countryCode="IE" value="353">(+353)</option>
										<option data-countryCode="IL" value="972">(+972)</option>
										<option data-countryCode="IT" value="39">(+39)</option>
										<option data-countryCode="JM" value="1876">(+1876)</option>
										<option data-countryCode="JP" value="81">(+81)</option>
										<option data-countryCode="JO" value="962">(+962)</option>
										<option data-countryCode="KZ" value="7">(+7)</option>
										<option data-countryCode="KE" value="254">(+254)</option>
										<option data-countryCode="KI" value="686">(+686)</option>
										<option data-countryCode="KP" value="850">(+850)</option>
										<option data-countryCode="KR" value="82">(+82)</option>
										<option data-countryCode="KW" value="965">(+965)</option>
										<option data-countryCode="KG" value="996">(+996)</option>
										<option data-countryCode="LA" value="856">(+856)</option>
										<option data-countryCode="LV" value="371">(+371)</option>
										<option data-countryCode="LB" value="961">(+961)</option>
										<option data-countryCode="LS" value="266">(+266)</option>
										<option data-countryCode="LR" value="231">(+231)</option>
										<option data-countryCode="LY" value="218">(+218)</option>
										<option data-countryCode="LI" value="417">(+417)</option>
										<option data-countryCode="LT" value="370">(+370)</option>
										<option data-countryCode="LU" value="352">(+352)</option>
										<option data-countryCode="MO" value="853">(+853)</option>
										<option data-countryCode="MK" value="389">(+389)</option>
										<option data-countryCode="MG" value="261">(+261)</option>
										<option data-countryCode="MW" value="265">(+265)</option>
										<option data-countryCode="MY" value="60">(+60)</option>
										<option data-countryCode="MV" value="960">(+960)</option>
										<option data-countryCode="ML" value="223">(+223)</option>
										<option data-countryCode="MT" value="356">(+356)</option>
										<option data-countryCode="MH" value="692">(+692)</option>
										<option data-countryCode="MQ" value="596">(+596)</option>
										<option data-countryCode="MR" value="222">(+222)</option>
										<option data-countryCode="YT" value="269">(+269)</option>
										<option data-countryCode="MX" value="52">(+52)</option>
										<option data-countryCode="FM" value="691">(+691)</option>
										<option data-countryCode="MD" value="373">(+373)</option>
										<option data-countryCode="MC" value="377">(+377)</option>
										<option data-countryCode="MN" value="976">(+976)</option>
										<option data-countryCode="MS" value="1664">(+1664)</option>
										<option data-countryCode="MA" value="212">(+212)</option>
										<option data-countryCode="MZ" value="258">(+258)</option>
										<option data-countryCode="MN" value="95">(+95)</option>
										<option data-countryCode="NA" value="264">(+264)</option>
										<option data-countryCode="NR" value="674">(+674)</option>
										<option data-countryCode="NP" value="977">(+977)</option>
										<option data-countryCode="NL" value="31">(+31)</option>
										<option data-countryCode="NC" value="687">(+687)</option>
										<option data-countryCode="NZ" value="64">(+64)</option>
										<option data-countryCode="NI" value="505">(+505)</option>
										<option data-countryCode="NE" value="227">(+227)</option>
										<option data-countryCode="NG" value="234">(+234)</option>
										<option data-countryCode="NU" value="683">(+683)</option>
										<option data-countryCode="NF" value="672">(+672)</option>
										<option data-countryCode="NP" value="670">(+670)</option>
										<option data-countryCode="NO" value="47">(+47)</option>
										<option data-countryCode="OM" value="968">(+968)</option>
										<option data-countryCode="PW" value="680">(+680)</option>
										<option data-countryCode="PA" value="507">(+507)</option>
										<option data-countryCode="PG" value="675">(+675)</option>
										<option data-countryCode="PY" value="595">(+595)</option>
										<option data-countryCode="PE" value="51">(+51)</option>
										<option data-countryCode="PH" value="63">(+63)</option>
										<option data-countryCode="PL" value="48">(+48)</option>
										<option data-countryCode="PT" value="351">(+351)</option>
										<option data-countryCode="PR" value="1787">(+1787)</option>
										<option data-countryCode="QA" value="974">(+974)</option>
										<option data-countryCode="RE" value="262">(+262)</option>
										<option data-countryCode="RO" value="40">(+40)</option>
										<option data-countryCode="RU" value="7">(+7)</option>
										<option data-countryCode="RW" value="250">(+250)</option>
										<option data-countryCode="SM" value="378">(+378)</option>
										<option data-countryCode="ST" value="239">(+239)</option>
										<option data-countryCode="SA" value="966">(+966)</option>
										<option data-countryCode="SN" value="221">(+221)</option>
										<option data-countryCode="CS" value="381">(+381)</option>
										<option data-countryCode="SC" value="248">(+248)</option>
										<option data-countryCode="SL" value="232">(+232)</option>
										<option data-countryCode="SG" value="65">(+65)</option>
										<option data-countryCode="SK" value="421">(+421)</option>
										<option data-countryCode="SI" value="386">(+386)</option>
										<option data-countryCode="SB" value="677">(+677)</option>
										<option data-countryCode="SO" value="252">(+252)</option>
										<option data-countryCode="ZA" value="27">(+27)</option>
										<option data-countryCode="ES" value="34">(+34)</option>
										<option data-countryCode="LK" value="94">(+94)</option>
										<option data-countryCode="SH" value="290">(+290)</option>
										<option data-countryCode="KN" value="1869">(+1869)</option>
										<option data-countryCode="SC" value="1758">(+1758)</option>
										<option data-countryCode="SD" value="249">(+249)</option>
										<option data-countryCode="SR" value="597">(+597)</option>
										<option data-countryCode="SZ" value="268">(+268)</option>
										<option data-countryCode="SE" value="46">(+46)</option>
										<option data-countryCode="CH" value="41">(+41)</option>
										<option data-countryCode="SI" value="963">(+963)</option>
										<option data-countryCode="TW" value="886">(+886)</option>
										<option data-countryCode="TJ" value="7">(+7)</option>
										<option data-countryCode="TH" value="66">(+66)</option>
										<option data-countryCode="TG" value="228">(+228)</option>
										<option data-countryCode="TO" value="676">(+676)</option>
										<option data-countryCode="TT" value="1868">(+1868)</option>
										<option data-countryCode="TN" value="216">(+216)</option>
										<option data-countryCode="TR" value="90">(+90)</option>
										<option data-countryCode="TM" value="7">(+7)</option>
										<option data-countryCode="TM" value="993">(+993)</option>
										<option data-countryCode="TC" value="1649">(+1649)</option>
										<option data-countryCode="TV" value="688">(+688)</option>
										<option data-countryCode="UG" value="256">(+256)</option>
										<option data-countryCode="GB" value="(+44)">(+44)</option>
										<option data-countryCode="UA" value="380">(+380)</option>
										<option data-countryCode="AE" value="971">(+971)</option>
										<option data-countryCode="UY" value="598">(+598)</option>
										<option data-countryCode="US" value="1">(+1)</option>
										<option data-countryCode="UZ" value="7">(+7)</option>
										<option data-countryCode="VU" value="678">(+678)</option>
										<option data-countryCode="VA" value="379">(+379)</option>
										<option data-countryCode="VE" value="58">(+58)</option>
										<option data-countryCode="VN" value="84">(+84)</option>
										<option data-countryCode="VG" value="84">(+1284)</option>
										<option data-countryCode="VI" value="84">(+1340)</option>
										<option data-countryCode="WF" value="681">(+681)</option>
										<option data-countryCode="YE" value="969">(+969)</option>
										<option data-countryCode="YE" value="967">(+967)</option>
										<option data-countryCode="ZM" value="260">(+260)</option>
										<option data-countryCode="ZW" value="263">(+263)</option>
								</select>
							<input
								type="number"
								name="phone"
								class="form-control contact-phone"
								placeholder="Contact No"
								required
								onfocus="this.placeholder = ''"
								onblur="this.placeholder = 'Contact No'"
								autocomplete="off"
								style="padding-left: 20px;border-radius:0 3px 3px 0"
								data-intl-tel-input-id="0"
								data-bv-field="phone"
								v-model="phone_number"
								oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
								maxlength ="10"
							/>
						</div>
					</div>
				</div>
				<small
					style="display: none"
					class="help-block"
					data-bv-validator="notEmpty"
					data-bv-for="phone"
					data-bv-result="NOT_VALIDATED"
					>The phone is required and cannot be empty</small
				><small
					style="display: none"
					class="help-block"
					data-bv-validator="stringLength"
					data-bv-for="phone"
					data-bv-result="NOT_VALIDATED"
					>Contact number must be at least 10 numbers</small
				>
			</div>
			<div class="form-group col-md-6 col-12">
				<input
					type="text"
					name="company_name"
					class="form-control"
					placeholder="Company Name"
					onfocus="this.placeholder = ''"
					onblur="this.placeholder = 'Company Name'"
					v-model="company_name"
				/>
			</div>
			<div class="form-group col-md-6 col-12">
				<select class="form-control" name="budget" v-model="budget">
					<option value="">Your Budget</option>
					<option value="$0 - $1000">$0 - $1000</option>
					<option value="$1000 - $5000">$1000 - $5000</option>
					<option value="$5000 - $10000">$5000 - $10000</option>
					<option value="$10000 - $20000">$10000 - $20000</option>
					<option value="$20000+">$20000 +</option>
				</select>
			</div>
			<!-- <div class="form-group col-md-6 col-12">
                        <input type="text" name="skype" class="form-control" placeholder="Skype Id">
                     </div> -->
			<div class="form-group col-md-6 col-12">
				<select class="form-control" name="region" required data-bv-field="region" v-model="region">
					<option value="">Your Region</option>
					<option value="Europe">Europe</option>
					<option value="North America">North America</option>
					<option value="South America">South America</option>
					<option value="Asia">Asia</option>
					<option value="Africa">Africa</option>
					<option value="Australia">Australia</option>
				</select>
				<small
					style="display: none"
					class="help-block"
					data-bv-validator="notEmpty"
					data-bv-for="region"
					data-bv-result="NOT_VALIDATED"
					>Please select region</small
				>
			</div>
			<div class="form-group col-12">
				<select
					class="form-control"
					name="service"
					required
					data-bv-field="service"
					v-model="service"
				>
					<option value="">Select the Service</option>
					<option value="Mobile App Development">Mobile App Development</option>
					<option value="AR Development">AR Development</option>
					<option value="VR Development">VR Development</option>
					<option value="Web Development">Web Development</option>
					<option value="Game Development">Game Development</option>
					<option value="Enterprise Solutions">Enterprise Solutions</option>
					<option value="Startup MVP">Startup MVP</option>
					<option value="Other">Other</option>
				</select>
				<small
					style="display: none"
					class="help-block"
					data-bv-validator="notEmpty"
					data-bv-for="service"
					data-bv-result="NOT_VALIDATED"
					>Please select service</small
				>
			</div>
			<div class="form-group col-12">
				<select class="form-control" name="reference">
					<option value="">Where Did You Hear About Us?</option>
					<option value="Google">Google</option>
					<option value="Clutch">Clutch</option>
					<option value="People Per Hour">People Per Hour</option>
					<option value="GoodFirms">GoodFirms</option>
					<option value="Personal Reference">Personal Reference</option>
					<option value="Social Media">Social Media</option>
					<option value="Other">Other</option>
				</select>
			</div>

			<div class="form-group col-12">
				<textarea
					name="message"
					v-model="message"
					class="form-control"
					placeholder="Message"
					rows="10"
				></textarea>
			</div>
		</div>
		<div class="form-group col-12 text-right">
			<button type="submit" class="btn-custome btn waves-effect waves-light contact-custome-btn fxt-contact-custome-btn">
				<i class="fa fa-spinner fa-spin start-loader"></i>Send Message
			</button>
		</div>
	</form>
</template>

<script>
import {init, sendForm} from '@emailjs/browser';
import {Flags} from '../../assets/js/flags.js';
init('A5rOsYhA1IcUo8q4J');

export default {
	data: function () {
		return {
			from_name: '',
			message: '',
			phone_number: '',
			service: '',
			company_name: '',
			region: '',
			budget: '',
		};
	},
	created() {
		const enableBtn = function () {
			document.querySelectorAll('.contact-custome-btn').forEach((el) => {
				el.disabled = false;
			});
		};
		const handleCallBack = function () {
			document.querySelectorAll('.contact-custome-btn').forEach((el) => {
				el.disabled = 'disabled';
			});
		};
		window.enableBtn = enableBtn;
		window.handleCallBack = handleCallBack;
	},
	mounted() {
		Flags();
	},
	methods: {
		sendEmail: (_this = this) => {
			sendForm('service_241arra', 'template_ys131rl', _this.target)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => console.log(err));
		},
		enableBtn: (inn) => {
			console.log(inn);
		}
	}
};
</script>

<style>
@import url('../../assets/css/flags.css');
.iti__hide {
	dispaly: block;
}
.iti__hide.hidden {
	display: none;
}
@media (max-width: 576px) {
	.fxt-contact-custome-btn {
		width:100%;
		justify-content: center;
	}
}
</style>
