<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Notch></Notch>

		<Customized></Customized>

		<TypeDiv></TypeDiv>

		<Partner></Partner>

		<Techno></Techno>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/EnterpriseSolutions/CrmDevelopment/Banner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import About from '../../components/EnterpriseSolutions/CrmDevelopment/About.vue';
import Notch from '../../components/EnterpriseSolutions/CrmDevelopment/Notch.vue';
import Customized from '../../components/EnterpriseSolutions/CrmDevelopment/Customized.vue';
import Partner from '../../components/EnterpriseSolutions/CrmDevelopment/Partner.vue';
import Techno from '../../components/EnterpriseSolutions/CrmDevelopment/Techno.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		Notch,
		Customized,
		TypeDiv,
		Partner,
		Techno,
		Const
	}
};
</script>

<style></style>
