<template>
	<section class="banner home fxt-lst-section">
		<div class="video" style="min-width: 100%">
			<!-- <video width=100% height=100% id="main_video"  class="lazy" data-poster="https://www.intelivita.co.uk/assets/frameworks/public/images/banner/home-banner.jpg" playsinline autoplay muted loop> -->
			<!--  <source src="https://www.intelivita.co.uk/assets/frameworks/public/images/video/10clouds-demo-hq--short.mp4" type="video/mp4"/>
          Your browser does not support the video tag.
      </video> -->
			<img
				class="lazy p-0 fxt-lst-img"
				alt="Web and mobile App Development Company in the UK"
				src="../../assets/images/home-banner.jpg"
				style="width: 100%"
			/>
			<!-- <img class="lazy" data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/home/home-banner.jpg"> -->
		</div>
		<div class="tenc-intro-main__wrapper">
			<div class="container">
				<h1 class="fxt-top-head">
					<span>Inspiring</span><span> the future </span> <span>with technology</span>
				</h1>
				<p class="fxt-top-par">
					We are the AI, Data and Digital Innovation partner of choice for global customer-centric enterprises and product-led startups.
				</p>
				<router-link
					to="/mobile-development"
					title=""
					class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
					>Explore More</router-link
				>
				<router-link
					to="/contact"
					title=""
					class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
					>Get in Touch</router-link
				>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.banner .video img {
	object-fit: none;
	max-width: 100%;
	max-height: 80vh;
	min-height: 916px;
}
.tenc-intro-main__wrapper {
	min-height: 900px;
	top: -33px;
	padding-top: 100px;
}
.tenc-intro-main__wrapper h1 {
	white-space: nowrap;
}
.fxt-btn-custome {
	background: #12599a !important;
}
.fxt-btn-custome:hover {
	background: #04294b !important;
}
.fxt-top-head {
	font-size: 38px;
}
.fxt-top-par {
	font-size: 20px !important;
}

@media (max-width: 1199px) {
	.fxt-top-head {
		font-size: 24px;
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 0px;
	}
}
@media (max-width: 991px) {
	.fxt-top-head {
		font-size: 24px;
	}

	.fxt-top-par {
		font-size: 16px !important;
	}
}
@media (max-width: 576px) {
	.fxt-top-head {
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.fxt-lst-section {
		height: 64vh !important;
	}
	.fxt-lst-section .video {
		height: 64vh !important;
	}
	.fxt-top-par {
		/* font-size: 12px !important; */
	}
	.tenc-intro-main__wrapper {
		padding-top: 0 !important;
	}
	.fxt-btn-custome {
		font-size: 12px;
	}
}
</style>