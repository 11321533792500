<template>
	<section class="padding-60  p-relative" style="background:#FFF">
		<div class="container mt-5">
			<div class="title">
				<span>MVP Variants</span>
				<h2 class="fxt-process-dev">MVP Development Variants That You Can Test Right Away</h2>
				<p>
					At Genixbit, we believe that progress is running ahead of the competition and world at
					large. That is why we proactively zeroed in on MVPs and use cases that our clients can
					make use of.
				</p>
			</div>
			<div style="display:flex;justify-content:center;flex-wrap:wrap">
				<div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ImageBox v-bind:obj="item"></ImageBox>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ImageBox from '../AssetComponents/ImageBox.vue';
export default {
	components: {
		ImageBox
	},
	data: () => {
		return {
			items: [
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/on-demand-taxi-booking.jpg",
					title:"On-demand Taxi App",
					text:"Augue neque gravida in fermentum sollicitudin ac orci phasellus. Amet est placerat in egestas erat imperdiet sed euismod. Risus in hendrerit gravida rutrum quisque tellus neque  Pretium viverra suspendisse potenti nullam.",
					exploreLink:"/taxi-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/on-demand-food-delivery.jpg",
					title:"On-demand Food Delivery",
					text:"Build the MVP for an on demand food delivery app that will connect customers, restaurants, and delivery professionals. Loyalty schemes, location-based restaurants, etc. can be curated in the MVP.",
					exploreLink:"/food-delivery-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/instant-messaging.jpg",
					title:"Instant Messaging",
					text:"Get to know how your end users will take the instant messaging app with an MVP. Create the basic app outline with functions for real-time chat, voice calling, video calling, etc.",
					exploreLink:"chat-instant-messaging-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/on-demand-car-wash.jpg",
					title:"On-demand Car Wash",
					text:"A MVP that demonstrates how users can find the best on-demand car washes nearby. The app can also be integrated with map APIs for real-time navigation, online booking and also digital wallet payment.",
					exploreLink:"/car-wash-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/healthcare-and-fitness.jpg",
					title:"Health and Fitness App",
					text:"Become the UBER of the fitness industry with a mobile app that allows users to book gyms, take personal trainer appointments or even chat with dietitians. Features to find recipes, workout plans, etc. also available.",
					exploreLink:"/health-fitness-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/social-media.jpg",
					title:"Social Media App",
					text:"The world still has room for another social network. A new concept or idea is all it takes. We can help you build a MVP of a social media app that does more than connecting people.",
					exploreLink:"/social-media-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/event-and-community-building.jpg",
					title:"Media and Entertainment app",
					text:"Media streaming is the new rage. Bu,t building a media and entertainment app right from scratch is an expensive process. A well-designed MVP of media and entertainment app will help you know which direction to go.",
					exploreLink:"/media-entertainment-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/finance-app.jpg",
					title:"Finance App",
					text:"Can an app predict stock movements? Or analyze and inform credit score? How about an app that handpicks best insurance? Have an idea that needs to be tested? We can build a MVP for your finance app ida.",
					exploreLink:"/finance-app-development"
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/real-estate.jpg",
					title:"Real Estate App",
					text:"A mobile app can find houses, find tenants for properties and also help tenants find allied services to maintain their properties. Test waters with the idea of a real estate app by building its MVP.",
					exploreLink:"/real-estate-app-development"
				},
			]
		};
	}
};
</script>

<style>

@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
