<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Media and Entertainment App Development </span>
				<h2 class="fxt-process-dev">Our experience-driven Entertainment Mobile App Development</h2>
				<p>
					We offer a lot more than Entertainment Mobile App Development. We offer experience
					development through entertainment mobile apps.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../../IndustrySolutions/AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink: '/video-streaming-apps.jpg',
					title: 'Video Streaming Apps',
					text: 'Build video streaming apps that can stream pre-recorded videos or live videos to millions of customers with high quality. We can also help integrate paywalls into the app for monetization.'
				},
				{
					imageLink: '/music-streaming-apps.jpg',
					title: 'Music Streaming Apps',
					text: 'Build the next Spotify or SoundCloud that will allow users to stream music from their favorite artists and bands to any device. Apps can be configured with features for offline downloads, playlist creation, etc.'
				},
				{
					imageLink: '/photo-editing-and-sharing-apps.jpg',
					title: 'Photo Editing & Sharing Apps',
					text: 'Not everybody is an expert with image editing tools. But, with our media app development expertise, your users can have photo editing and sharing apps that will allow them to edit photos without any prior expertise.'
				},
				{
					imageLink: '/gaming-app.jpg',
					title: 'Gaming App',
					text: 'Build a mobile gaming app — puzzle, strategy, action, adventure, word, trivia — or anything else with our gaming app development expertise.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>