<template>
	<section class="padding-60 p-relative" style="background: #fff">
		<div class="container mt-5">
			<div class="title">
				<span>Comprehensive Feature Set CRM</span>
				<h2 class="fxt-process-dev">Custom CRM Software Development Solutions</h2>
				<p>
					Try developing cost-effective custom CRM software aimed at minimising resources spending
					and amplifying impactful output resulting out of customer satisfaction and multiple sales.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<NormalCard v-bind:obj="item"></NormalCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NormalCard from '../AssetComponents/NormalCard';

export default {
	components: {
		NormalCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'CRM Consultancy',
					text: 'Being a CRM development company, we emphasise on consulting small and big businesses to drive multi-departmental growth. With CRM consultants from GenixBit Labs, take your business towards leveraging existing technology and tailoring CRM solutions to best suit your requirements.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M98.4,0H1.6C0.7,0,0,0.7,0,1.6V79c0,4.5,3.6,8.1,8.1,8.1v11.3c0,0.7,0.4,1.2,1,1.5c0.6,0.3,1.3,0.1,1.8-0.3l12.4-12.4
                  h68.7c4.5,0,8.1-3.6,8.1-8.1V1.6C100,0.7,99.3,0,98.4,0z M96.8,79c0,2.7-2.2,4.8-4.8,4.8H22.6c-0.2,0-0.4,0-0.6,0.1
                  c-0.2,0.1-0.4,0.2-0.5,0.3L11.3,94.5v-9c0-0.9-0.7-1.6-1.6-1.6H8.1c-2.7,0-4.8-2.2-4.8-4.8V19.4h93.5V79z M96.8,16.1H3.2V3.2h93.5
                  V16.1z M11.3,14.5c2.7,0,4.8-2.2,4.8-4.8c0-2.7-2.2-4.8-4.8-4.8C8.6,4.8,6.5,7,6.5,9.7C6.5,12.4,8.6,14.5,11.3,14.5z M11.3,8.1
                  c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C9.7,8.8,10.4,8.1,11.3,8.1z M22.6,14.5c2.7,0,4.8-2.2,4.8-4.8
                  c0-2.7-2.2-4.8-4.8-4.8c-2.7,0-4.8,2.2-4.8,4.8C17.7,12.4,19.9,14.5,22.6,14.5z M22.6,8.1c0.9,0,1.6,0.7,1.6,1.6
                  c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C21,8.8,21.7,8.1,22.6,8.1z M33.9,14.5c2.7,0,4.8-2.2,4.8-4.8
                  c0-2.7-2.2-4.8-4.8-4.8C31.2,4.8,29,7,29,9.7C29,12.4,31.2,14.5,33.9,14.5z M33.9,8.1c0.9,0,1.6,0.7,1.6,1.6
                  c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C32.3,8.8,33,8.1,33.9,8.1z M27.4,56.5c3.6,0,6.5-2.9,6.5-6.5
                  c0-3.6-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5C21,53.6,23.9,56.5,27.4,56.5z M27.4,46.8c1.8,0,3.2,1.4,3.2,3.2
                  c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2C24.2,48.2,25.6,46.8,27.4,46.8z M50,56.5c3.6,0,6.5-2.9,6.5-6.5
                  c0-3.6-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5C43.5,53.6,46.4,56.5,50,56.5z M50,46.8c1.8,0,3.2,1.4,3.2,3.2
                  c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2C46.8,48.2,48.2,46.8,50,46.8z M72.6,56.5c3.6,0,6.5-2.9,6.5-6.5c0-3.6-2.9-6.5-6.5-6.5
                  c-3.6,0-6.5,2.9-6.5,6.5C66.1,53.6,69,56.5,72.6,56.5z M72.6,46.8c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2s-3.2-1.4-3.2-3.2
                  C69.4,48.2,70.8,46.8,72.6,46.8z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Mobile Application',
					text: 'Take your CRM wherever you go with latest mobility CRM development for mobile apps. Get real-time dashboards with intuitive data for better decision0-making and faster operational ramification driving higher customer satisfaction.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M98.3,71.6V58.3h-7.5c-0.7-2.9-1.8-5.6-3.3-8.1l5.3-5.3l-9.5-9.5L78,40.8c-2.5-1.5-5.2-2.6-8.1-3.3V30h-5V10
                  c0-5.5-4.5-10-10-10H11.7c-5.5,0-10,4.5-10,10v80c0,5.5,4.5,10,10,10H70v-7.5c2.9-0.7,5.6-1.8,8.1-3.3l5.3,5.3l9.5-9.5l-5.3-5.3
                  c1.5-2.5,2.6-5.2,3.3-8.1H98.3z M5,10c0-3.7,3-6.7,6.7-6.7H55c3.7,0,6.7,3,6.7,6.7v3.3H5V10z M5,16.7h56.7V30h-5v7.5
                  c-2.9,0.7-5.6,1.8-8.1,3.3l-5.3-5.3L33.9,45l5.3,5.3c-1.5,2.5-2.6,5.2-3.3,8.1h-7.5v13.3h7.5c0.7,2.9,1.8,5.6,3.3,8.1l-3.6,3.5H5
                  V16.7z M56.7,96.7h-45C8,96.7,5,93.7,5,90v-3.3h30.5l7.8,7.8l5.3-5.3c2.5,1.5,5.2,2.6,8.1,3.3V96.7z M87.9,69.7
                  c-0.6,3.4-2,6.5-3.9,9.4l-0.8,1.1l4.8,4.8l-4.7,4.8l-4.8-4.8l-1.1,0.8c-2.8,1.9-6,3.2-9.4,3.9l-1.4,0.2v6.9H60v-6.9l-1.3-0.2
                  c-3.4-0.6-6.5-2-9.4-3.9l-1.1-0.8l-4.8,4.8L38.6,85l4.8-4.8L42.7,79c-1.9-2.8-3.2-6-3.9-9.4l-0.3-1.3h-6.9v-6.7h6.9l0.3-1.3
                  c0.6-3.4,2-6.5,3.9-9.4l0.8-1.1L38.6,45l4.7-4.8l4.8,4.8l1.1-0.8c2.8-1.9,6-3.2,9.4-3.9l1.4-0.3v-6.9h6.6v6.9l1.3,0.3
                  c3.4,0.6,6.5,2,9.4,3.9l1.1,0.8l4.8-4.8l4.7,4.8l-4.8,4.8L84,51c1.9,2.8,3.2,6,3.9,9.4l0.2,1.4H95v6.7h-6.9L87.9,69.7z M25,10h3.3
                  V6.7H25V10z M31.7,10h10V6.7h-10V10z M73.3,65c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10
                  C68.9,75,73.3,70.5,73.3,65z M63.3,71.7c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7c3.7,0,6.7,3,6.7,6.7C70,68.7,67,71.7,63.3,71.7
                  z M73.3,78.3l2,2.6c1-0.7,1.9-1.6,2.7-2.5l-2.5-2.3C74.9,77,74.2,77.7,73.3,78.3z M68.7,45.7c-1.2-0.3-2.4-0.5-3.6-0.7l-0.3,3.3
                  c1,0.1,2,0.3,3,0.5L68.7,45.7z M67.9,81l0.9,3.2c1.2-0.3,2.3-0.8,3.5-1.3L70.8,80C69.9,80.4,68.9,80.8,67.9,81z M61.8,81.7
                  L61.5,85h3.7l-0.3-3.3C63.9,81.8,62.8,81.8,61.8,81.7z M79.7,68.1l3.3,0.6c0.2-1.2,0.3-2.4,0.3-3.7H80C80,66,79.9,67.1,79.7,68.1z
                  M54.4,82.9c1.1,0.5,2.3,1,3.4,1.3l0.9-3.2c-1-0.3-1.9-0.6-2.9-1L54.4,82.9z M80.3,54.4c-0.7-1-1.4-2-2.2-2.9l-2.5,2.3
                  c0.7,0.8,1.3,1.6,1.8,2.4L80.3,54.4z M79.7,61.9l3.3-0.6c-0.2-1.2-0.6-2.4-1-3.6l-3.1,1.2C79.3,59.9,79.5,60.9,79.7,61.9z
                  M75.3,49c-1-0.7-2-1.4-3.2-1.9L70.7,50c0.9,0.5,1.8,1,2.6,1.7L75.3,49z M77.5,73.8l2.8,1.8c0.7-1.1,1.2-2.2,1.7-3.3L78.9,71
                  C78.5,72,78.1,72.9,77.5,73.8z M47.8,71.1l-3.1,1.2c0.4,1.2,1,2.3,1.7,3.3l2.8-1.8C48.6,72.9,48.2,72,47.8,71.1z M47.8,59
                  l-3.1-1.2c-0.4,1.1-0.8,2.3-1,3.6L47,62C47.2,61,47.4,60,47.8,59z M51,53.8l-2.5-2.2c-0.8,0.9-1.6,1.9-2.2,3l2.8,1.7
                  C49.7,55.3,50.3,54.5,51,53.8z M55.9,50.1l-1.5-3c-1.1,0.6-2.1,1.2-3.1,2l2.1,2.6C54.1,51.1,55,50.5,55.9,50.1z M46.7,65h-3.3
                  c0,1.2,0.1,2.5,0.4,3.7l3.3-0.6C46.8,67,46.7,66,46.7,65z M61.7,48.3L61.4,45c-1.2,0.1-2.4,0.3-3.6,0.7l1,3.3
                  C59.7,48.7,60.7,48.5,61.7,48.3z M48.6,78.5c0.8,0.9,1.8,1.7,2.7,2.5l2-2.6c-0.8-0.6-1.6-1.3-2.3-2.1L48.6,78.5z M31.7,21.7H10V25
                  h21.7V21.7z M38.3,21.7H35V25h3.3V21.7z M38.3,28.3H10v3.3h28.3V28.3z M45,28.3h-3.3v3.3H45V28.3z M28.3,35H10v3.3h18.3V35z
                  M18.3,61.7H10V65h8.3V61.7z M23.3,68.3H10v3.3h13.3V68.3z M10,78.3h10V75H10V78.3z M23.3,78.3h3.3V75h-3.3V78.3z M31.7,38.3H35
                  V35h-3.3V38.3z M28.3,43.3H10v13.3h18.3V43.3z M25,53.3H13.3v-6.7H25V53.3z M31.7,93.3H35V90h-3.3V93.3z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Customisation',
					text: 'Make the most out of multiple CRM tools with CRM customisation at every stage. Drive metrics that matter the most to your business. Go for custom CRM software development based on available resources to remain a step ahead from the competitors by improving conversion rate.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M3.3,76.7V10c0-1.8,1.5-3.3,3.3-3.3H80c1.8,0,3.3,1.5,3.3,3.3v3.3h3.3V10c0-3.7-3-6.7-6.7-6.7H6.7C3,3.3,0,6.3,0,10v66.7
                  c0,3.7,3,6.7,6.7,6.7H10V80H6.7C4.8,80,3.3,78.5,3.3,76.7z M6.7,13.3H10V10H6.7V13.3z M16.7,13.3V10h-3.3v3.3H16.7z M23.3,13.3V10
                  H20v3.3H23.3z M93.3,16.7H20c-3.7,0-6.7,3-6.7,6.7V90c0,3.7,3,6.7,6.7,6.7h73.3c3.7,0,6.7-3,6.7-6.7V23.3
                  C100,19.7,97,16.7,93.3,16.7z M96.7,90c0,1.8-1.5,3.3-3.3,3.3H20c-1.8,0-3.3-1.5-3.3-3.3V23.3c0-1.8,1.5-3.3,3.3-3.3h73.3
                  c1.8,0,3.3,1.5,3.3,3.3V90z M23.3,23.3H20v3.3h3.3V23.3z M30,23.3h-3.3v3.3H30V23.3z M36.7,23.3h-3.3v3.3h3.3V23.3z M20,33.3h73.3
                  V30H20V33.3z M33.3,38.3H20v3.3h13.3V38.3z M30,45H20v3.3h10V45z M56.7,51.7c-6.4,0-11.7,5.2-11.7,11.7C45,69.8,50.2,75,56.7,75
                  c6.4,0,11.7-5.2,11.7-11.7C68.3,56.9,63.1,51.7,56.7,51.7z M56.7,71.7c-4.6,0-8.3-3.7-8.3-8.3c0-4.6,3.7-8.3,8.3-8.3
                  c4.6,0,8.3,3.7,8.3,8.3C65,67.9,61.3,71.7,56.7,71.7z M83.3,58.3c0-0.9-0.7-1.7-1.7-1.7h-4.4c-0.4-1.1-0.8-2.2-1.3-3.2l3.1-3.1
                  c0.7-0.7,0.7-1.7,0-2.4L72,40.9c-0.7-0.7-1.7-0.7-2.4,0l-3.1,3.1c-1-0.5-2.1-1-3.2-1.3v-4.4c0-0.9-0.7-1.7-1.7-1.7h-10
                  c-0.9,0-1.7,0.7-1.7,1.7v4.4c-1.1,0.4-2.2,0.8-3.2,1.3l-3.1-3.1c-0.7-0.7-1.7-0.7-2.4,0L34.3,48c-0.7,0.7-0.7,1.7,0,2.4l3.1,3.1
                  c-0.1,0.3-0.3,0.5-0.4,0.8c-0.4,0.8-0.7,1.6-0.9,2.4h-4.4c-0.9,0-1.7,0.7-1.7,1.7v10c0,0.9,0.7,1.7,1.7,1.7h4.4
                  c0.4,1.1,0.8,2.2,1.3,3.2l-3.1,3.1c-0.7,0.7-0.7,1.7,0,2.4l7.1,7.1c0.7,0.7,1.7,0.7,2.4,0l3.1-3.1c1,0.5,2.1,1,3.2,1.3v4.4
                  c0,0.9,0.7,1.7,1.7,1.7h10c0.9,0,1.7-0.7,1.7-1.7v-4.4c1.1-0.4,2.2-0.8,3.2-1.3l3.1,3.1c0.7,0.7,1.7,0.7,2.4,0l7.1-7.1
                  c0.7-0.7,0.7-1.7,0-2.4l-3.1-3.1c0.5-1,1-2.1,1.3-3.2h4.4c0.9,0,1.7-0.7,1.7-1.7V58.3z M80,66.7h-4c-0.8,0-1.4,0.5-1.6,1.2
                  c-0.4,1.7-1.1,3.2-1.9,4.7c-0.4,0.7-0.3,1.5,0.3,2l2.8,2.8l-4.7,4.7L68,79.4c-0.5-0.5-1.4-0.6-2-0.3c-1.5,0.9-3.1,1.5-4.7,2
                  c-0.7,0.2-1.3,0.9-1.3,1.6v4h-6.7v-4c0-0.8-0.5-1.4-1.3-1.6c-1.7-0.4-3.2-1.1-4.7-2c-0.7-0.4-1.5-0.3-2,0.3l-2.8,2.8l-4.7-4.7
                  l2.8-2.8c0.5-0.5,0.6-1.4,0.3-2c-0.9-1.5-1.5-3.1-1.9-4.7c-0.2-0.7-0.9-1.2-1.6-1.2h-4V60h4c0.8,0,1.4-0.5,1.6-1.2
                  c0.3-1,0.6-2.1,1.1-3.1c0.3-0.6,0.5-1.1,0.9-1.7c0.4-0.7,0.3-1.5-0.3-2l-2.8-2.8l4.7-4.7l2.8,2.8c0.5,0.5,1.4,0.6,2,0.3
                  c1.5-0.9,3.1-1.5,4.7-2c0.7-0.2,1.3-0.9,1.3-1.6v-4H60v4c0,0.8,0.5,1.4,1.3,1.6c1.7,0.4,3.2,1.1,4.7,1.9c0.7,0.4,1.5,0.3,2-0.3
                  l2.8-2.8l4.7,4.7L72.7,52c-0.5,0.5-0.6,1.4-0.3,2c0.9,1.5,1.5,3.1,1.9,4.7c0.2,0.7,0.9,1.2,1.6,1.2h4V66.7z M83.3,90h10v-3.3h-10
                  V90z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Integration',
					text: 'Connect the missing dots within and outside the dedicated departments with a cohesive CRM integration. Share multiple details like customer contact, SLA, bills and other variables over a single portal to have a dynamic customer information centre for easy management and maintenance.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <path d="M4.2,64.7h21.6l-10.3,9.8c-1,1-1.3,2.4-0.7,3.7c0.6,1.3,1.9,2,3.3,1.9l13.6-1v14.3c0,2.3,1.9,4.1,4.2,4.1h60
              c2.3,0,4.2-1.8,4.2-4.1v-59c0-2.3-1.9-4.1-4.2-4.1H66.7V6.6c0-2.3-1.9-4.1-4.2-4.1H4.2C1.9,2.5,0,4.3,0,6.6v6.6
              c0,0.9,0.7,1.6,1.7,1.6c0.9,0,1.7-0.7,1.7-1.6V6.6c0-0.5,0.4-0.8,0.8-0.8h58.3c0.5,0,0.8,0.4,0.8,0.8v23.8c-0.3-0.1-0.6-0.1-0.8-0.1
              H35.8c-2.3,0-4.2,1.8-4.2,4.1v23.8c0,0.3,0,0.6,0.1,0.9l-2.5,2.4H4.2c-0.5,0-0.8-0.4-0.8-0.8V26.3c0-0.9-0.7-1.6-1.7-1.6
              c-0.9,0-1.7,0.7-1.7,1.6v34.4C0,62.9,1.9,64.7,4.2,64.7z M17.8,76.9l13.3-12.6l0.1-0.1l17.2-16.3v41.4L37.5,76.8
              c-0.6-0.7-1.6-1.1-2.6-1.1c-0.1,0-0.1,0-0.2,0L17.8,76.9z M96.7,34.4v59c0,0.4-0.4,0.8-0.8,0.8h-60c-0.5,0-0.8-0.4-0.8-0.8V79
              l10.8,12.4c0.6,0.7,1.5,1.1,2.5,1.1c0.4,0,0.8-0.1,1.2-0.2c1.3-0.5,2.2-1.7,2.2-3.1V64.7h10.8c2.3,0,4.2-1.8,4.2-4.1V34.4
              c0-0.3,0-0.5-0.1-0.8h29.3C96.3,33.6,96.7,34,96.7,34.4z M35,34.4c0-0.5,0.4-0.8,0.8-0.8h26.7c0.5,0,0.8,0.4,0.8,0.8v26.2
              c0,0.5-0.4,0.8-0.8,0.8H51.7V47.9c0-1.3-0.8-2.5-2-3c-1.2-0.5-2.6-0.3-3.6,0.6L35,56V34.4z"></path>
            </svg>
          `
				},
        {
					title: 'CRM Development Solutions',
					text: 'Our core CRM development solutions are easy to implement that takes weeks rather than months. Integrate our custom CRM solutions to your existing solutions that are easy to learn and adapt to. We also offer to modify your existing CRM software with evolved needs of time making it future proof.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M91.9,38.7h-3.2v1.6h-1.6c-2.4-3.2-5.8-5.3-9.7-6.1V20.3L57.1,0H0v100h77.4V65.8c3.8-0.8,7.3-2.9,9.7-6.1h1.6v1.6h3.2
                  v-1.6h8.1V40.3h-8.1V38.7z M58.1,5.5l13.8,13.8H58.1V5.5z M74.2,96.8h-71V3.2h51.6v19.4h19.4v11.3c-6.2,0-11.5,3.5-14.2,8.6
                  c-0.4-1.1-0.8-2.2-1.4-3.2l4.1-4.1L53.5,26l-4.1,4.1c-1.4-0.7-2.8-1.3-4.3-1.8v-5.8H32.3v5.8c-1.5,0.4-2.9,1-4.3,1.8L23.9,26
                  l-9.1,9.1l4.1,4.1c-0.7,1.4-1.3,2.8-1.8,4.3h-5.8v12.9h5.8c0.4,1.5,1,2.9,1.8,4.3l-4.1,4.1l9.1,9.1l4.1-4.1
                  c1.4,0.7,2.8,1.3,4.3,1.8v5.8h12.9v-5.8c1.5-0.4,2.9-1,4.3-1.8l4.1,4.1l9.1-9.1l-4.1-4.1c0.6-1,1-2.1,1.4-3.2
                  c2.7,5.1,8.1,8.6,14.2,8.6V96.8z M40.6,42.6L35.1,50l5.6,7.4l2.6-1.9l-2.9-3.9h7.9c-0.8,4.6-4.7,8.1-9.5,8.1
                  c-5.3,0-9.7-4.3-9.7-9.7c0-5.3,4.3-9.7,9.7-9.7c4.8,0,8.8,3.5,9.5,8.1h-7.9l2.9-3.9L40.6,42.6z M38.7,37.1
                  c-7.1,0-12.9,5.8-12.9,12.9c0,7.1,5.8,12.9,12.9,12.9c6.6,0,12-4.9,12.8-11.3h6.6c0.1,0.5,0.1,1.1,0.2,1.6h-0.6l-0.3,1.2
                  c-0.5,2-1.3,3.9-2.4,5.7l-0.7,1.1l3.6,3.6l-4.6,4.6l-3.6-3.6l-1.1,0.7c-1.8,1.1-3.7,1.9-5.7,2.4l-1.2,0.3v5.1h-6.5v-5.1l-1.2-0.3
                  c-2-0.5-3.9-1.3-5.7-2.4l-1.1-0.7l-3.6,3.6l-4.6-4.6l3.6-3.6l-0.7-1.1c-1.1-1.8-1.9-3.7-2.4-5.7l-0.3-1.2h-5.1v-6.5h5.1l0.3-1.2
                  c0.5-2,1.3-3.9,2.4-5.7l0.7-1.1l-3.6-3.6l4.6-4.6l3.6,3.6l1.1-0.7c1.8-1.1,3.7-1.9,5.7-2.4l1.2-0.3v-5.1h6.5v5.1l1.2,0.3
                  c2,0.5,3.9,1.3,5.7,2.4l1.1,0.7l3.6-3.6l4.6,4.6l-3.6,3.6l0.7,1.1c1.1,1.8,1.9,3.7,2.4,5.7l0.3,1.2h0.6c-0.1,0.5-0.2,1.1-0.2,1.6
                  h-6.6C50.7,42,45.3,37.1,38.7,37.1z M88.7,48.4h-10c-0.7-1.9-2.4-3.2-4.5-3.2c-2.7,0-4.8,2.2-4.8,4.8c0,2.7,2.2,4.8,4.8,4.8
                  c2.1,0,3.9-1.4,4.5-3.2h10v4.8h-3.3l-0.5,0.7c-2.4,3.6-6.4,5.7-10.7,5.7c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9
                  c4.3,0,8.3,2.1,10.7,5.7l0.5,0.7h3.3V48.4z M75.8,50c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
                  C75.1,48.4,75.8,49.1,75.8,50z M96.8,43.5v12.9h-4.8V43.5H96.8z M90.3,29h-9.7v3.2h9.7V29z M93.5,32.3h3.2V29h-3.2V32.3z
                  M93.5,22.6h-9.7v3.2h9.7V22.6z M96.8,22.6v3.2h3.2v-3.2H96.8z M90.3,67.7h-9.7V71h9.7V67.7z M96.8,67.7h-3.2V71h3.2V67.7z
                  M83.9,77.4h9.7v-3.2h-9.7V77.4z M96.8,77.4h3.2v-3.2h-3.2V77.4z M71,90.3h-3.2v3.2H71V90.3z M61.3,93.5h3.2v-3.2h-3.2V93.5z
                  M54.8,93.5h3.2v-3.2h-3.2V93.5z M6.5,9.7h3.2V6.5H6.5V9.7z M16.1,6.5h-3.2v3.2h3.2V6.5z M22.6,6.5h-3.2v3.2h3.2V6.5z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Implementation',
					text: 'We make CRM implementation an effortless exercise for your business with a proven implantation plan that considers data migration and rolling out each module phase-wise. Our dedicated CRM implementation team are assigned strategic and executive roles for successful implementation.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M95,0H5C2.2,0,0,2.2,0,5v90c0,2.8,2.2,5,5,5h90c2.8,0,5-2.2,5-5V5C100,2.2,97.8,0,95,0z M96.7,95c0,0.9-0.7,1.7-1.7,1.7H5
                  c-0.9,0-1.7-0.7-1.7-1.7V30h93.3V95z M96.7,26.7H3.3V5c0-0.9,0.7-1.7,1.7-1.7h90c0.9,0,1.7,0.7,1.7,1.7V26.7z M15,8.3
                  c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7S18.7,8.3,15,8.3z M15,18.3c-1.8,0-3.3-1.5-3.3-3.3
                  c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3C18.3,16.8,16.8,18.3,15,18.3z M31.7,8.3c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7
                  c3.7,0,6.7-3,6.7-6.7S35.3,8.3,31.7,8.3z M31.7,18.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3
                  C35,16.8,33.5,18.3,31.7,18.3z M48.3,8.3c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7S52,8.3,48.3,8.3z M48.3,18.3
                  c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3C51.7,16.8,50.2,18.3,48.3,18.3z M8.3,65h6.3
                  c0.1,0.2,0.2,0.5,0.3,0.7l-4.4,4.4c-0.7,0.7-0.7,1.7,0,2.4l7.1,7.1c0.7,0.7,1.7,0.7,2.4,0l4.4-4.4c0.2,0.1,0.5,0.2,0.7,0.3v6.3
                  c0,0.9,0.7,1.7,1.7,1.7h10c0.9,0,1.7-0.7,1.7-1.7v-6.3c0.2-0.1,0.5-0.2,0.7-0.3l4.4,4.4c0.7,0.7,1.7,0.7,2.4,0l7.1-7.1
                  c0.7-0.7,0.7-1.7,0-2.4l-4.4-4.4c0.1-0.2,0.2-0.5,0.3-0.7H55c0.9,0,1.7-0.7,1.7-1.7v-10c0-0.9-0.7-1.7-1.7-1.7h-6.3
                  c-0.1-0.2-0.2-0.5-0.3-0.7l4.4-4.4c0.7-0.6,0.7-1.7,0-2.4l-7.1-7.1c-0.7-0.7-1.7-0.7-2.4,0L39,41.5c-0.2-0.1-0.5-0.2-0.7-0.3V35
                  c0-0.9-0.7-1.7-1.7-1.7h-10c-0.9,0-1.7,0.7-1.7,1.7v6.3c-0.2,0.1-0.5,0.2-0.7,0.3l-4.4-4.4c-0.7-0.7-1.7-0.7-2.4,0l-7.1,7.1
                  c-0.6,0.7-0.6,1.7,0,2.4l4.4,4.4c-0.1,0.2-0.2,0.5-0.3,0.7H8.3c-0.9,0-1.7,0.7-1.7,1.7v10C6.7,64.3,7.4,65,8.3,65z M10,55h5.8
                  c0.7,0,1.4-0.5,1.6-1.2c0.3-0.8,0.6-1.6,1-2.4c0.4-0.6,0.2-1.5-0.3-2L14,45.4l4.7-4.7l4.1,4.1c0.5,0.5,1.3,0.6,2,0.3
                  c0.8-0.4,1.6-0.7,2.4-1c0.7-0.2,1.2-0.9,1.2-1.6v-5.8H35v5.8c0,0.7,0.5,1.4,1.2,1.6c0.8,0.3,1.6,0.6,2.4,1c0.6,0.4,1.5,0.2,2-0.3
                  l4.1-4.1l4.7,4.7l-4.1,4.1c-0.5,0.5-0.6,1.3-0.3,2c0.4,0.8,0.8,1.6,1,2.4c0.2,0.7,0.9,1.1,1.6,1.1h5.8v6.7h-5.8
                  c-0.7,0-1.4,0.5-1.6,1.2c-0.3,0.8-0.6,1.6-1,2.4c-0.4,0.6-0.2,1.5,0.3,2l4.1,4.1L44.6,76l-4.1-4.1c-0.5-0.5-1.3-0.6-2-0.3
                  c-0.8,0.4-1.6,0.7-2.4,1c-0.7,0.2-1.2,0.9-1.2,1.6V80h-6.7v-5.8c0-0.7-0.5-1.4-1.2-1.6c-0.8-0.3-1.6-0.6-2.4-1
                  c-0.6-0.4-1.5-0.2-2,0.3L18.7,76L14,71.3l4.1-4.1c0.5-0.5,0.6-1.3,0.3-2c-0.4-0.8-0.7-1.6-1-2.4c-0.2-0.7-0.9-1.2-1.6-1.2H10V55z
                  M31.7,68.3c5.5,0,10-4.5,10-10s-4.5-10-10-10c-5.5,0-10,4.5-10,10C21.7,63.9,26.1,68.3,31.7,68.3z M31.7,51.7
                  c3.7,0,6.7,3,6.7,6.7s-3,6.7-6.7,6.7C28,65,25,62,25,58.3S28,51.7,31.7,51.7z M56.7,78.3c0,0.9,0.7,1.7,1.7,1.7h4.6l-3.3,3.3
                  c-0.7,0.7-0.7,1.7,0,2.4l4.7,4.7c0.7,0.7,1.7,0.7,2.4,0l3.3-3.3v4.6c0,0.9,0.7,1.7,1.7,1.7h6.7c0.9,0,1.7-0.7,1.7-1.7v-4.6
                  l3.3,3.3c0.7,0.7,1.7,0.7,2.4,0l4.7-4.7c0.7-0.7,0.7-1.7,0-2.4L87.1,80h4.6c0.9,0,1.7-0.7,1.7-1.7v-6.7c0-0.9-0.7-1.7-1.7-1.7
                  h-4.6l3.3-3.3c0.7-0.7,0.7-1.7,0-2.4l-4.7-4.7c-0.7-0.7-1.7-0.7-2.4,0L80,62.9v-4.6c0-0.9-0.7-1.7-1.7-1.7h-6.7
                  c-0.9,0-1.7,0.7-1.7,1.7v4.6l-3.3-3.3c-0.7-0.7-1.7-0.7-2.4,0l-4.7,4.7c-0.7,0.7-0.7,1.7,0,2.4l3.3,3.3h-4.6
                  c-0.9,0-1.7,0.7-1.7,1.7V78.3z M60,73.3h5.6c0.7,0,1.4-0.5,1.6-1.2c0.1-0.2,0.2-0.5,0.3-0.7c0.3-0.6,0.2-1.4-0.3-2l-4-4l2.4-2.4
                  l4,4c0.5,0.5,1.3,0.6,2,0.3c0.2-0.1,0.4-0.2,0.7-0.3c0.7-0.2,1.2-0.9,1.2-1.6V60h3.3v5.6c0,0.7,0.5,1.4,1.2,1.6
                  c0.2,0.1,0.5,0.2,0.7,0.3c0.6,0.3,1.4,0.2,2-0.3l4-4l2.4,2.4l-4,4c-0.5,0.5-0.6,1.3-0.3,2c0.1,0.2,0.2,0.4,0.3,0.7
                  c0.2,0.7,0.9,1.2,1.6,1.2H90v3.3h-5.6c-0.7,0-1.4,0.5-1.6,1.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.3,0.6-0.2,1.4,0.3,2l4,4l-2.4,2.4l-4-4
                  c-0.5-0.5-1.3-0.6-2-0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.7,0.2-1.2,0.9-1.2,1.6V90h-3.3v-5.6c0-0.7-0.5-1.4-1.2-1.6
                  c-0.2-0.1-0.5-0.2-0.7-0.3c-0.6-0.3-1.4-0.2-2,0.3l-4,4l-2.4-2.4l4-4c0.5-0.5,0.6-1.3,0.3-2c-0.1-0.2-0.2-0.4-0.3-0.7
                  c-0.2-0.7-0.9-1.2-1.6-1.2H60V73.3z M75,80c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5s-5,2.2-5,5C70,77.8,72.2,80,75,80z M75,73.3
                  c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C73.3,74.1,74.1,73.3,75,73.3z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Migration Solutions',
					text: 'Ensuring zero data loss and guaranteeing utmost data integrity is our foremost priority. Our CRM software services support end-to-end, efficient CRM migration solutions that initiate with high-level brief and forecasting possible data gaps to keep the process decluttered and fast at each stage.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M70.1,0H29.9c-4.3,0-7.7,3.5-7.7,7.8v84.4c0,4.3,3.5,7.8,7.7,7.8h40.3c4.3,0,7.7-3.5,7.7-7.8V7.8C77.9,3.5,74.4,0,70.1,0z
                  M74.8,92.2c0,2.6-2.1,4.7-4.6,4.7H29.9c-2.6,0-4.6-2.1-4.6-4.7V81.2h49.5V92.2z M74.8,78.1H25.2V14.1h49.5V78.1z M74.8,10.9H25.2
                  V7.8c0-2.6,2.1-4.7,4.6-4.7h40.3c2.6,0,4.6,2.1,4.6,4.7V10.9z M50,95.3c3.4,0,6.2-2.8,6.2-6.3c0-3.5-2.8-6.3-6.2-6.3
                  c-3.4,0-6.2,2.8-6.2,6.3C43.8,92.5,46.6,95.3,50,95.3z M50,85.9c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1
                  c-1.7,0-3.1-1.4-3.1-3.1C46.9,87.3,48.3,85.9,50,85.9z M37.7,56.3h10.8c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6H37.7
                  c-1.9,0-3.7-1-4.7-2.6c-1-1.7-1-3.7,0-5.4c1-1.7,2.7-2.7,4.7-2.6c0.8,0,1.5,0.2,2.2,0.5c0.4,0.2,1,0.2,1.4-0.1
                  c0.4-0.2,0.7-0.7,0.8-1.2c0.6-5,4.8-8.8,9.8-8.9c5-0.1,9.3,3.5,10.1,8.4c0.1,0.7,0.6,1.2,1.3,1.3c1.5,0.3,2.8,1.2,3.6,2.5
                  c0.8,1.3,0.9,2.9,0.5,4.4c-0.2,0.5-0.1,1.1,0.3,1.6c0.4,0.4,0.9,0.6,1.5,0.5c0.6-0.1,1-0.5,1.2-1.1c0.7-2.2,0.6-4.5-0.5-6.5
                  c-1-2-2.8-3.6-5-4.2l0,0c-1.5-5.9-6.7-10-12.6-10c-6-0.1-11.3,4-12.8,9.8c-3.1-0.6-6.3,0.6-8.3,3.1c-1.9,2.6-2.3,6-0.9,8.9
                  C31.6,54.4,34.5,56.2,37.7,56.3z M63.9,45.3h-9.3c-0.9,0-1.5,0.7-1.5,1.6v18.8c0,0.9,0.7,1.6,1.5,1.6h9.3c0.9,0,1.5-0.7,1.5-1.6
                  V46.9C65.5,46,64.8,45.3,63.9,45.3z M62.4,64.1h-6.2v-1.6h3.1c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-3.1v-1.6h3.1
                  c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-3.1v-1.6h3.1c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-3.1v-1.6h6.2V64.1z
                  M29.9,95.3h1.5c0.9,0,1.5-0.7,1.5-1.6c0-0.9-0.7-1.6-1.5-1.6h-1.5c-0.9,0-1.5,0.7-1.5,1.6C28.3,94.6,29,95.3,29.9,95.3z
                  M70.1,6.3h-1.5c-0.9,0-1.5,0.7-1.5,1.6c0,0.9,0.7,1.6,1.5,1.6h1.5c0.9,0,1.5-0.7,1.5-1.6C71.7,6.9,71,6.3,70.1,6.3z M65.5,95.3
                  h4.6c1.7,0,3.1-1.4,3.1-3.1v-4.7c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.5,0.7-1.5,1.6v4.7h-4.6c-0.9,0-1.5,0.7-1.5,1.6
                  C63.9,94.6,64.6,95.3,65.5,95.3z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Support & Maintenance',
					text: 'Your data is crucial to business success and we ensure providing 24*7/365 support as a part of CRM software solutions. Timely maintenance and updates are necessary for smooth functioning and we plan out our CRM maintenance with your consultation that least affects your business in the process.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M10.6,46.9H44c0.7,0,1.2-0.5,1.2-1.2V25.8c0-0.6-0.5-1.2-1.2-1.2H10.6c-0.7,0-1.2,0.5-1.2,1.2v19.9
                  C9.4,46.3,10,46.9,10.6,46.9z M11.8,26.9h31v17.6h-31V26.9z M49.1,28.1h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,27.5,48.5,28.1,49.1,28.1z M49.1,36.9h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,36.4,48.5,36.9,49.1,36.9z M49.1,45.7h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,45.2,48.5,45.7,49.1,45.7z M42.1,66.2c-1.8,0-3.2,1.4-3.2,3.1c0,1.7,1.4,3.1,3.2,3.1
                  c1.8,0,3.2-1.4,3.2-3.1C45.3,67.6,43.8,66.2,42.1,66.2z M42.1,70.1c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
                  c0.4,0,0.8,0.3,0.8,0.8C42.9,69.7,42.5,70.1,42.1,70.1z M42.1,13.1H9.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h32.6
                  c0.7,0,1.2-0.5,1.2-1.2C43.3,13.6,42.7,13.1,42.1,13.1z M73,11c-1.9,0-3.4,1.5-3.4,3.3c0,1.8,1.5,3.3,3.4,3.3
                  c1.9,0,3.4-1.5,3.4-3.3C76.4,12.5,74.9,11,73,11z M73,15.2c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1s1,0.4,1,1C74,14.8,73.5,15.2,73,15.2
                  z M64.4,11c-1.9,0-3.4,1.5-3.4,3.3c0,1.8,1.5,3.3,3.4,3.3c1.9,0,3.4-1.5,3.4-3.3C67.8,12.5,66.3,11,64.4,11z M64.4,15.2
                  c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1C65.4,14.8,65,15.2,64.4,15.2z M55.9,11c-1.9,0-3.4,1.5-3.4,3.3
                  c0,1.8,1.5,3.3,3.4,3.3s3.4-1.5,3.4-3.3C59.2,12.5,57.7,11,55.9,11z M55.9,15.2c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1s1,0.4,1,1
                  C56.9,14.8,56.4,15.2,55.9,15.2z M99.2,64.7c-0.1-0.6-0.5-1-1.1-1l-3.5-0.2c-0.5-1.3-1.2-2.5-2.1-3.6l1.7-3.1
                  c0.3-0.5,0.1-1.1-0.3-1.5l-5.2-3.9c-0.5-0.3-1.1-0.3-1.5,0l-2.6,2.3c-1.1-0.4-2.3-0.7-3.4-1v-40c0-2.9-2.4-5.3-5.4-5.3H9
                  c-3,0-5.4,2.4-5.4,5.3v46.7c0,1.1,0.3,2.1,0.9,3H1.2C0.5,62.5,0,63,0,63.7v2.9c0,5.1,4.3,9.3,9.5,9.3h46.4l0.1,0.4
                  c0.1,0.6,0.5,1,1.1,1l3.5,0.2c0.5,1.3,1.2,2.5,2.1,3.6L61,84.2c-0.3,0.5-0.1,1.1,0.3,1.5l5.1,4c0.5,0.4,1.1,0.3,1.5,0l2.6-2.3
                  c1.3,0.5,2.7,0.9,4,1.1l1,3.3c0.2,0.5,0.6,0.8,1.1,0.8c0,0,0.1,0,0.1,0l6.5-0.8c0.6-0.1,1-0.5,1-1.1l0.2-3.5
                  c1.3-0.5,2.5-1.2,3.7-2l3.1,1.6c0.5,0.3,1.1,0.1,1.5-0.3l4-5c0.4-0.4,0.3-1.1,0-1.5l-2.4-2.6c0.5-1.3,0.9-2.6,1.1-4l3.4-1
                  c0.6-0.2,0.9-0.7,0.8-1.3L99.2,64.7z M6,12.8c0-1.6,1.4-3,3-3h66.7c1.7,0,3,1.3,3,3v5.9H6V12.8z M6,21h72.8v27.5
                  c-0.2-0.1-0.4-0.1-0.6-0.1l-6.5,0.8c-0.6,0.1-1,0.5-1,1.1c0,0.1-0.1,0.9-0.1,1.9H10.8c-0.7,0-1.2,0.5-1.2,1.2
                  c0,0.6,0.5,1.2,1.2,1.2h51.3l-4,5c-0.4,0.4-0.3,1.1,0.1,1.5l1.3,1.4H9c-1.7,0-3-1.3-3-2.9V21z M68.9,54.5
                  c-0.7,0.4-1.4,0.8-2.1,1.3l-2.5-1.3H68.9z M56,68.6c-0.6,0.2-0.9,0.7-0.8,1.3l0.4,3.6H9.5v0c-3.9,0-7.1-3.1-7.1-6.9v-1.7h57.7
                  c-0.3,0.9-0.6,1.9-0.7,2.8L56,68.6z M94.3,71.3c-0.5,0.1-0.8,0.5-0.8,1c-0.2,1.6-0.6,3.2-1.3,4.7C92,77.5,92,78,92.3,78.3l2.2,2.4
                  l-2.8,3.5l-2.9-1.5c-0.4-0.2-0.9-0.2-1.3,0.1c-1.3,1-2.8,1.8-4.4,2.4c-0.5,0.2-0.8,0.6-0.8,1l-0.2,3.2L77.7,90l-1-3.1
                  c-0.1-0.4-0.5-0.8-1-0.8c-1.7-0.2-3.3-0.6-4.8-1.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.6,0.1-0.8,0.3l-2.5,2.2l-3.6-2.8l1.6-2.9
                  c0.2-0.4,0.2-0.9-0.1-1.3c-1.1-1.3-1.9-2.8-2.5-4.3c-0.2-0.4-0.6-0.7-1.1-0.8L58.2,75l-0.5-4.4l3.1-0.9c0.5-0.1,0.8-0.5,0.8-1
                  c0.2-1.5,0.6-3,1.3-4.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.2,0.1-0.2c0.2-0.4,0.2-1-0.2-1.3l-2.2-2.4l2.8-3.5l2.9,1.5
                  c0.4,0.2,0.9,0.2,1.3-0.1c1.3-1,2.7-1.8,4.2-2.3c0.7-0.1,0.8-0.5,0.9-1.1l0.1,0c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-1
                  c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.5,0.1-1.1,0.1-1.9l4.5-0.5l1,3.1c0.1,0.4,0.5,0.8,1,0.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                  c1.5,0.2,3,0.6,4.5,1.2c0.4,0.2,0.9,0.1,1.3-0.2l2.5-2.1l3.6,2.7L90,59.4c-0.2,0.4-0.2,0.9,0.1,1.3c1.1,1.3,1.9,2.8,2.5,4.3
                  c0.2,0.4,0.6,0.7,1,0.8l3.3,0.2l0.5,4.4L94.3,71.3z M77.5,59.4c-6.2,0-11.2,5-11.2,11.1c0,6.1,5.1,11.1,11.3,11.1
                  c6.2,0,11.3-5,11.3-11.1C88.8,64.5,83.9,59.4,77.5,59.4z M77.6,79.2c-4.9,0-8.9-3.9-8.9-8.7c0-4.8,4-8.7,8.9-8.7
                  c4.9,0,8.9,4,8.9,8.7C86.5,75.3,82.5,79.2,77.6,79.2z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CRM Portal Development',
					text: 'Our stepwise approach to CRM portal development aids enhancing productivity and reduced portal costs with highly usable CRM extension based on Dynamics or other tools. Create an enterprise-level CRM portal for efficient data management negating redundancy and leveraging the team resources.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M18.6,28.1h49.8c0.9,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H18.6c-0.9,0-1.6,0.6-1.6,1.6C17,27.4,17.7,28.1,18.6,28.1z
                  M10.3,35.7h25.2c0.9,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H10.3c-0.9,0-1.6,0.6-1.6,1.6C8.8,35.1,9.4,35.7,10.3,35.7z
                  M63,34.1c0-0.9-0.6-1.6-1.6-1.6H41.7c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h19.7C62.3,35.7,63,35.1,63,34.1z M65.3,34.1
                  c0,0.9,0.6,1.6,1.6,1.6h10.5c0.9,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H66.9C66.1,32.6,65.3,33.4,65.3,34.1z M43.8,39.9H10.3
                  c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h33.4c0.9,0,1.6-0.6,1.6-1.6C45.3,40.5,44.5,39.9,43.8,39.9z M60.2,41.4
                  c0-0.9-0.6-1.6-1.6-1.6H50c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h8.6C59.5,43,60.2,42.4,60.2,41.4z M77.3,39.9H64.1
                  c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h13.3c0.9,0,1.6-0.6,1.6-1.6C78.9,40.5,78.3,39.9,77.3,39.9z M37.5,47.8H26.2
                  c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h11.3c0.9,0,1.6-0.6,1.6-1.6C39.1,48.4,38.4,47.8,37.5,47.8z M10.3,50.9h10.5
                  c0.9,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H10.3c-0.9,0-1.6,0.6-1.6,1.6C8.8,50.3,9.4,50.9,10.3,50.9z M37.5,54.2h-3
                  c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h3c0.9,0,1.6-0.6,1.6-1.6C39.1,54.8,38.4,54.2,37.5,54.2z M29.1,54.2H10.3
                  c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h18.8c0.9,0,1.6-0.6,1.6-1.6C30.6,54.8,29.8,54.2,29.1,54.2z M86.9,51.1V8
                  c0-2.5-2-4.7-4.7-4.7H4.7C2,3.3,0,5.4,0,8v67.2c0,2.8,1.9,5,4.4,5h48.3c3.3,9.6,12.3,16.5,23,16.5c13.4,0,24.4-10.7,24.4-24.1
                  C100,63.2,94.7,55.1,86.9,51.1z M3.1,8c0-0.9,0.6-1.6,1.6-1.6h77.5c0.8,0,1.6,0.6,1.6,1.6v8.6H3.1V8z M51.7,77.1H4.4
                  c-0.6,0-1.2-0.8-1.2-1.9V19.7h80.6v30c-2.5-0.9-5.3-1.4-8.1-1.4c-13.4,0-24.4,10.9-24.4,24.3C51.2,74.1,51.4,75.7,51.7,77.1z
                  M75.6,93.6c-11.7,0-21.3-9.5-21.3-21.2c0-11.7,9.7-21,21.3-21c11.6,0,21.3,9.5,21.3,21.2C96.9,84.2,87.3,93.6,75.6,93.6z
                  M69.5,68.5c0-1.1-1.3-1.9-2.3-1.4l-9.8,5.1c-0.6,0.3-1.1,0.9-1.1,1.7c0,0.8,0.5,1.4,1.1,1.7l9.8,5.1c1.1,0.5,2.3-0.2,2.3-1.4
                  c0-0.6-0.3-1.1-0.9-1.4l-8-4l8-4C69.1,69.6,69.5,69.1,69.5,68.5z M79.7,62.6c-0.6,0-1.3,0.5-1.6,1.1l-6.4,16.5
                  c-0.5,1.1,0.3,2.2,1.6,2.2c0.6,0,1.3-0.5,1.6-1.1l6.4-16.5C81.6,63.8,80.8,62.6,79.7,62.6z M94.1,72.2l-9.8-5.1
                  c-1.1-0.5-2.3,0.3-2.3,1.4c0,0.6,0.3,1.1,0.9,1.4l8,4l-8,3.9c-0.5,0.3-0.9,0.8-0.9,1.4c0,1.2,1.3,1.9,2.3,1.4l9.8-5
                  c0.6-0.3,1.1-0.9,1.1-1.7C95.2,73.2,94.7,72.6,94.1,72.2z M77.3,9.9h-3.1c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.8,1.6,1.6,1.6h3.1
                  c0.9,0,1.6-0.6,1.6-1.6C78.9,10.5,78.3,9.9,77.3,9.9z M65.8,9.9h-3.1c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h3.1
                  c0.9,0,1.6-0.6,1.6-1.6C67.3,10.5,66.7,9.9,65.8,9.9z"></path>
                </g>
              </g>
            </svg>
          `
				},
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
