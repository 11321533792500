<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>How we Build Your Product</span>
				<h2 class="fxt-tech-use">Technologies we Use</h2>
			</div>
			<div class="technologies_tabs fxt-technologies-tabs">
				<ul class="nav nav-tabs" id="technologies_tabs" role="tablist">
					<li>
						<a
							class="active"
							id="mobile-technologies-tab"
							data-toggle="tab"
							href="#mobile-technologies"
							role="tab"
							aria-controls="mobile-technologies"
							aria-selected="true"
							>Mobile Technologies</a
						>
					</li>
					<li>
						<a
							id="web-technologies-tab"
							data-toggle="tab"
							href="#web-technologies"
							role="tab"
							aria-controls="web-technologies"
							aria-selected="true"
							>Web Technologies</a
						>
					</li>
					<li>
						<a
							id="cloud-tab"
							data-toggle="tab"
							href="#cloud"
							role="tab"
							aria-controls="cloud"
							aria-selected="true"
							>Cloud</a
						>
					</li>
					<li>
						<a
							id="database-tab"
							data-toggle="tab"
							href="#database"
							role="tab"
							aria-controls="database"
							aria-selected="true"
							>Database</a
						>
					</li>
					<li>
						<a
							id="design-tab"
							data-toggle="tab"
							href="#design"
							role="tab"
							aria-controls="design"
							aria-selected="true"
							>Design</a
						>
					</li>
					<li>
						<a
							id="strategies-tab"
							data-toggle="tab"
							href="#strategies"
							role="tab"
							aria-controls="strategies"
							aria-selected="true"
							>Strategies &amp; Communication</a
						>
					</li>
				</ul>
				<div class="tab-content" id="technologies_tabs_Content">
					<div
						class="tab-pane fade show active fxt-tab-pane"
						id="mobile-technologies"
						role="tabpanel"
						aria-labelledby="mobile-technologies-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									title=""
									alt="Swift"
									src="../../assets/images/swift.svg"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Objective-C"
									alt="Objective-C"
									src="../../assets/images/objactive-c.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Java"
									alt="Java"
									src="../../assets/images/java.svg"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Flutter"
									alt="Flutter"
									src="../../assets/images/flutter.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Augmented reality Android"
									alt="Augmented reality Android"
									src="../../assets/images/android-ar.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Augmented reality Kit"
									alt="Augmented reality Kit"
									src="../../assets/images/arkit.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Unity"
									alt="Unity"
									src="../../assets/images/unity.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Google virtual reality"
									alt="Google virtual reality"
									src="../../assets/images/google-vr.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="culus VR"
									alt="Oculus VR"
									src="../../assets/images/oculus.svg"
									style=""
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									title="Mixed reality"
									alt="Mixed reality"
									src="../../assets/images/mixed-reality.png"
									style=""
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="web-technologies"
						role="tabpanel"
						aria-labelledby="web-technologies-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									src="../../assets/images/php.svg"
									title="PHP"
									alt="PHP"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/wordpress.svg"
									title="WordPress"
									alt="WordPress"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/codeigniter.svg"
									title="CodeIgniter"
									alt="CodeIgniter"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/laravel.svg"
									title="Laravel"
									alt="Laravel"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/nodejs.svg"
									title="Node.js"
									alt="Node.js"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/angular.svg"
									title="Angular"
									alt="Angular"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/html5.svg"
									title="HTML5"
									alt="HTML5"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/css3.svg"
									title="CSS3"
									alt="CSS3"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/jquery.svg"
									title="jQuery"
									alt="jQuery"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/bootstrap-4.svg"
									title="Bootstrap 4"
									alt="Bootstrap 4"
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="cloud"
						role="tabpanel"
						aria-labelledby="cloud-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									src="../../assets/images/amazon-web-services.svg"
									title="Amazon Web Services"
									alt="Amazon Web Services"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/linode.svg"
									title="Linode"
									alt="Linode"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/icloud.svg"
									title="iCloud"
									alt="iCloud"
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="database"
						role="tabpanel"
						aria-labelledby="database-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									src="../../assets/images/firebase.svg"
									title="Firebase"
									alt="Firebase"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/mongodb.svg"
									title="MongoDB"
									alt="MongoDB"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/mysql.svg"
									title="MySQL"
									alt="MySQL"
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="design"
						role="tabpanel"
						aria-labelledby="design-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									src="../../assets/images/adobe-illustrator-cc.svg"
									title="Adobe Illustrator"
									alt="Adobe Illustrator"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/after-effects-cc.svg"
									title="Adobe After Effects"
									alt="Adobe After Effects"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/figma.svg"
									title="Figma"
									alt="Figma"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/invision.svg"
									title="InVision"
									alt="InVision"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/justinmind.svg"
									title="Justinmind"
									alt="Justinmind"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/photoshop-cc.svg"
									title="Adobe Photoshop"
									alt="Adobe Photoshop"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/sketch.svg"
									title="Sketch"
									alt="Sketch"
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-tab-pane"
						id="strategies"
						role="tabpanel"
						aria-labelledby="strategies-tab"
					>
						<ul
							class="technologies_icons d-flex align-items-center justify-content-center"
						>
							<li>
								<img
									class="lazy"
									src="../../assets/images/basecamp.svg"
									title="Basecamp"
									alt="Basecamp"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/asana.svg"
									title="Asana"
									alt="Asana"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/bitbucket.svg"
									title="Bitbucket"
									alt="Bitbucket"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/google-hangouts.svg"
									title="Google Hangouts"
									alt="Google Hangouts"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/skype.svg"
									title="Skype"
									alt="Skype"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/slack.svg"
									title="Slack"
									alt="Slack"
									width="78"
									height="78"
								/>
							</li>
							<li>
								<img
									class="lazy"
									src="../../assets/images/trello.svg"
									title="Trello"
									alt="Trello"
									width="78"
									height="78"
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size:20px !important;
		font-weight:bold !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
</style>
