<template>
	<section
		class="similar-app-main fitness fitness-sec-ban"
		style="
			background: url(https://www.intelivita.co.uk/assets/frameworks/public/images/fitness/similar-app-background.webp)
				0% 0% / cover no-repeat;
		"
	>
		<img
			class="imgbg"
			src="../../../assets/images/similar-app-background.webp"
			alt="health and fitness app development"
			style="display: none"
		/>
		<div class="container-2">
			<div class="row">
				<div class="col-lg-6 col-md-6 col-12"></div>
				<div class="col-lg-6 col-md-12 col-12">
					<div class="similar-app-content-wrap d-flex align-items-center">
						<div class="similar-app-content">
							<h2>From out-of-shape to ripped model, an app that helps users get fit</h2>
							<p>
								Fitness is for all. But, not everyone knows the best practices to get fit without
								burning out. One of our clients approached to build a mobile app that will act like
								an assistant to those who want to get fit. Form Assist would be a mobile app that
								will train users what to eat, how to work out and how to become the best selves that
								they want to be.
							</p>
							<p>
								In order to make the app a truly personalized health and fitness coach, we
								integrated the app with features like recipe finding, workout planning, chat with
								dietitians and trainers, goal setting and activity tracking and much more. The app
								was well-received in app stores and continues to help several users reach their
								fitness goals.
							</p>
							<router-link class="btn-custome btn btn-lg waves-effect waves-light" to="/contact"
								>Get Your Fitness App Developed Now</router-link
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="similar-app-image">
			<div class="similar-app-image-wid">
				<img
					class="lazy img1"
					alt=""
					src="../../../assets/images/similar-app-main1 (1).webp"
					style=""
				/>
				<img
					class="lazy img2"
					alt=""
					src="../../../assets/images/similar-app-main2 (1).webp"
					style=""
				/>
				<img
					class="lazy d-block d-lg-none"
					alt=""
					src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
				/>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.similar-app-main {
	position: relative;
}
@media (max-width: 1500px) {
	.container-2 {
		max-width: 1170px;
	}
}
.container-2 {
	width: 100% !important;
	max-width: 1500px;
	margin: 0 auto;
	padding: 0 15px;
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media (max-width: 1440px) {
	.similar-app-content-wrap {
		padding: 30px 30px;
	}
}
.similar-app-content-wrap {
	position: relative;
	padding: 30px 60px;
	min-height: 560px;
}
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.similar-app-image {
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 30px;
	position: absolute;
}
@media (max-width: 1440px) {
	.similar-app-image-wid {
		width: 50%;
	}
}
.similar-app-image-wid {
	position: relative;
	max-width: 580px;
	width: 100%;
}
.fitness .similar-app-image-wid .img1 {
	width: 65%;
	top: 0;
}
.similar-app-image-wid .img1 {
	position: absolute;
	left: 0;
	top: 65px;
	z-index: 2;
}
.similar-app-image-wid img {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 60%;
}
.fitness .similar-app-image-wid .img2 {
	width: 50%;
	top: 70px;
	right: inherit;
	left: 45%;
}

.similar-app-image-wid .img2 {
	position: absolute;
	right: 0;
	z-index: 1;
	top: 0;
}
.similar-app-image-wid img {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 60%;
}
.similar-app-image-wid img {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 60%;
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
}
.fitness-sec-ban {
	position: relative;
}
.fitness-sec-ban::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #235d86;
	opacity: 0.8;
}
.fitness .similar-app-image-wid:hover .img1 {
	left: -10px;
}
.fitness .similar-app-image-wid:hover .img2 {
	left: 46%;
}
@media (max-width: 1440px) {
	.similar-app-content-wrap {
		padding: 30px 30px;
	}
}
.similar-app-content-wrap:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.226);
}
.similar-app-content-wrap {
	position: relative;
	padding: 30px 60px;
	min-height: 560px;
}
@media (max-width: 1440px) {
	.similar-app-content h2 {
		font-size: 22px;
		margin-bottom: 20px;
	}
}
.similar-app-content h2 {
	color: #fff;
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 30px;
}
@media (max-width: 1440px) {
	.similar-app-content p {
		margin-bottom: 20px;
	}
}
.similar-app-content p {
	color: #fff;
	margin-bottom: 25px;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.similar-app-content .btn-custome {
	font-size: 14px;
}
</style>