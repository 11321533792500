<template>
	<div>
		<div class="game-what-we-offer-box rounded10">
			<div class="game-what-we-offer-icon" v-html="obj.svg"></div>
			<h5>{{ obj.title }}</h5>
			<p>{{ obj.text }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.real-estate-type-main .game-what-we-offer-box {
	min-height: 310px;
}
.game-what-we-offer-box {
	border: 1px solid #ecedee;
	padding: 30px 20px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	min-height: 260px;
}
.real-estate-type-main  .game-what-we-offer-icon svg *{
	fill:#0082dd
}
.game-what-we-offer-icon {
	width: 60px;
	height: 60px;
	margin-bottom: 20px;
}
.game-what-we-offer-box h4 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
}
.game-what-we-offer-box:hover {
    border: 1px solid #0082dd;
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
</style>
