<template>
	<section class="footer-main">
		<!--====== Footer top ======-->
		<div class="footer-top pt-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-md-12 col-12 order-2 foot-center-col pb-2">
						<div class="foot-exp-wrap text-right">
							<div class="foot-exp-box d-flex">
								<div class="foot-exp-char">
									<div class="num-6">6</div>
									<div>YEARS</div>
								</div>
								<div class="foot-exp-text-wrap text-center fxt-foot-exp-text">
									<div class="foot-exp-text-button rounded10 mb-30">
										<span class="exp">Experience</span>
										<span class="indus">in the Industry</span>
									</div>
									<router-link
										class="btn-custome btn btn-lg waves-effect waves-light"
										to="/app-development-cost"
										>Discuss Your Project</router-link
									>
								</div>
							</div>
							<div class="foot-social text-center">
								<ul class="header_social">
									<li>
										<a
											title="Facebook"
											href="https://www.facebook.com/genixbit"
											rel="noopener"
											target="_blank"
											class="fab fa-facebook-f"
										></a>
									</li>
									<li>
										<a
											title="Instagram"
											href="https://www.instagram.com/genixbit/"
											rel="noopener"
											target="_blank"
											class="fab fa-instagram"
										></a>
									</li>
									<li>
										<a
											title="Twitter"
											href="https://twitter.com/genixbit"
											rel="noopener"
											target="_blank"
											class="fab fa-twitter"
										></a>
									</li>
									<li>
										<a
											title="Linkedin"
											href="https://www.linkedin.com/company/genixbit"
											rel="noopener"
											target="_blank"
											class="fab fa-linkedin-in"
										></a>
									</li>
									<li>
										<a
											title="Behance"
											href="https://www.behance.net/genixbit"
											rel="noopener"
											target="_blank"
											class="fab fa-behance"
										></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div
						class="col-lg-3 col-md-6 col-sm-6 col-12 order-0 foot-center-col text-center text-md-left"
					>
						<div class="foot-menu">
							<h4>Expertise</h4>
							<ul>
								<li><router-link to="/mobile-development">Mobile App Development</router-link></li>
								<li><router-link to="/web-development">Web App Development</router-link></li>
								<li><router-link to="/game-development">Mobile Games Development</router-link></li>
								<li>
									<router-link to="/game-development/augmented-reality-game-development"
										>Augmented Reality Development</router-link
									>
								</li>
								<li>
									<router-link to="/mobile-development/virtual-reality-development"
										>Virtual Reality</router-link
									>
								</li>
								<li><router-link to="/startup-mvp-development">Startup MVP</router-link></li>
							</ul>
						</div>
					</div>
					<div
						class="col-lg-3 col-md-6 col-sm-6 col-12 order-1 foot-center-col text-center text-md-left pb-xl-0"
					>
						<div class="foot-menu">
							<h4>Solution</h4>
							<ul>
								<li>
									<router-link to="/chat-instant-messaging-app-development"
										>Instant Messaging</router-link
									>
								</li>
								<li>
									<router-link to="/car-wash-app-development">On-demand Car Wash</router-link>
								</li>
								<li>
									<router-link to="/health-fitness-app-development"
										>Health &amp; Fitness</router-link
									>
								</li>
								<li><router-link to="/taxi-app-development">Taxi Booking</router-link></li>
								<li>
									<router-link to="/food-delivery-app-development">Food Delivery</router-link>
								</li>
								<li><router-link to="/social-media-app-development">Social Media</router-link></li>
							</ul>
						</div>
					</div>
				</div>
				<hr />
			</div>
		</div>
		<!--====== End Footer top ======-->

		<!--====== Footer center ======-->
		<div class="footer-center">
			<div class="container">
				<div class="footer-center-top-content text-center">
					<p>
						<img
							src="../../assets/images/footer-rating.png"
							alt="We're top mobile app development company London offering Android and iPhone app development solutions for clients in London and across the UK."
							width="110"
							height="19"
						/>
						4.8 / 5.0 on Clutch for the quality of services offered for Web and Mobile App
						development
					</p>
					<span
						>All product and company names are trademarks™ or registered<sup>®</sup> trademarks of
						their respective holders. Use of them does not imply any affiliation with or endorsement
						by them.</span
					>
				</div>
				<div class="footer-center-top-market">
					<div class="row align-items-center">
						<div class="col-lg-8 col-md-12 col-sm-12 col-12">
							<div class="footer-center-market-list d-flex my-3">
								<div class="item" style="width: 117px !important">
									<img
										class="lazy"
										alt="We're top mobile app development company London offering Android and iPhone app development solutions for clients in London and across the UK."
										src="../../assets/images/clutch-award.png"
										style=""
										width="127"
										height="135"
									/>
								</div>
								<div class="item" style="width: 129px !important">
									<img
										class="lazy"
										alt="Clutch awarded Genixbit as a top mobile app developer for is high-quality mobile app development services UK"
										src="../../assets/images/appfutura-badge.png"
										style=""
										width="140"
										height="135"
									/>
								</div>
								<div class="item" style="width: 125px !important">
									<img
										class="lazy"
										alt="client say we deliver excellent mobile app development solutions."
										src="../../assets/images/clutch-award2.png"
										style=""
										width="135"
										height="135"
									/>
								</div>
								<div class="item" style="width: 156px !important">
									<img
										class="lazy"
										alt="Goodfirms recognizes Genixbit as a top augmented reality and virtual reality app development company across the globe"
										src="../../assets/images/goodfirms-award.png"
										style=""
										width="169"
										height="135"
									/>
								</div>
								<div class="item" style="width: 125px !important">
									<img
										class="lazy"
										alt="Mobile App Daily rated Genixbit among few of the flutter app development company in the UK"
										src="../../assets/images/mobile-app-daily-award.png"
										style=""
										width="136"
										height="135"
									/>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-12 col-sm-12 col-12 text-center text-lg-right">
							<router-link class="btn-custome btn waves-effect waves-light my-2" to="/contact"
								>Get in Touch Now</router-link
							>
						</div>
					</div>
				</div>
				<hr />
			</div>
		</div>
		<!--====== End Footer center ======-->

		<!--====== Footer Bottom ======-->
		<div class="footer-bottom pb-xl-0">
			<div class="container">
				<div class="row align-items-center">
					<div
						class="col-lg-5 col-md-12 col-sm-12 col-12 text-center text-lg-left order-1 order-lg-0"
					>
						<div class="foot-copy">
							<p>© 2016-2024 GenixBit Labs Pvt. Ltd. All Rights Reserved.</p>
							<p>Ver: 0.1.3</p>
						</div>
					</div>
					<div
						class="col-lg-7 col-md-12 col-sm-12 col-12 text-center text-lg-right order-0 order-lg-1"
					>
						<ul class="foot-link fxt-foot-link">
							<li><router-link to="/contact">Contact Us</router-link></li>
							<li><a href="#">Privacy Policy</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--====== End Footer Bottom ======-->
	</section>
</template>

<script>
export default {};
</script>

<style>
.footer-center {
	padding: 5px 0 0;
}
.footer-bottom {
	padding: 5px 0;
}
.footer-main {
}
.footer-main hr {
	border-top: 1px solid rgba(245, 133, 31, 0.3);
	margin: 0;
}
.order-2 {
	-ms-flex-order: 2;
	order: 2;
}
.foot-exp-wrap {
	padding-left: 5px 15px;
}

.foot-social {
	margin-top: 15px;
}
.foot-exp-char {
	width: 100px;
}
.foot-exp-text-button {
	background-color: #2b2c2d;
	text-transform: uppercase;
	text-align: center;
	padding: 13px 10px;
}
.foot-exp-text-button {
	margin-bottom: 0px !important;
}
.foot-exp-text-button .exp {
	display: block;
	font-size: 48px;
	font-weight: 700;
	color: #1977cc;
	line-height: 1.1;
}
.foot-exp-text-button .exp {
	font-size: 28px;
}

.foot-exp-text-button .indus {
	display: block;
	padding-top: 3px;
	font-size: 20px;
	font-weight: 500;
	color: #fff;
	line-height: 1.1;
}
.foot-exp-text-wrap {
	width: calc(100% - 120px);
}
.footer-main {
	position: fixed;
	background-color: #202122;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}

@media (max-width: 1600px) {
	.footer-main {
		height: 90vh;
	}
}
@media (max-width: 1200px) {
	.footer-main {
		position: relative;
		height: unset !important;
		overflow-y: unset !important;
	}
}
.foot-center-col {
	padding: 0px 15px;
}
.header_social {
	display: inline-block;
}
.foot-social .header_social li {
	margin-left: 11px;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: auto;
}
.foot-social .header_social li:first-child {
	margin-left: 0;
}
.foot-social .header_social li a {
	margin: 0;
	border: 1px solid #1977cc;
	color: #1977cc;
	background-color: transparent;
	width: 40px;
	height: 40px;
	font-size: 20px;
}
.header_social li a {
	width: 33px;
	height: 33px;
	background: #0d0d0e;
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50%;
	margin: 1px;
	color: #fff;
	font-size: 14px;
}
.foot-exp-text-wrap {
	width: calc(100% - 160px);
	margin-left: 20px;
}

.foot-social .header_social li a:hover {
	background-color: #1977cc;
	color: #fff;
}

.foot-exp-text-wrap .btn-custome {
	font-size: 14px;
}
.num-6 {
	color: #1977cc;
	font-size: 10rem;
	position: absolute;
	top: -57px;
}
.foot-exp-char {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}
.foot-exp-char div:nth-child(2) {
	position: absolute;
	color: #fff;
	top: 125px;
	font-size: 1.6rem;
}

.foot-center-col h4 {
	margin-bottom: 0px;
}

.foot-center-col h4 {
	font-size: 24px;
	font-weight: 500;
	color: #fff;
	margin-bottom: 5px;
}

.foot-menu ul {
	padding: 0;
}

.foot-menu ul li {
	margin-bottom: 3px;
}
.foot-menu ul li:hover {
	color: #1977cc;
}
@media (min-width: 1200px) {
	.footer-center-market-list .item {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		min-width: 40px;
		min-height: 40px;
		object-fit: cover;
		max-width: 150px;
		max-height: 125px;
	}
	.footer-center-market-list .item img {
		width: 100%;
		height: 100%;
	}
}
.footer-center-top-content span {
	display: block;
	color: rgba(255, 255, 255, 0.5);
}
.footer-center-top-market .btn-custome {
	font-size: 16px;
	color: #1977cc !important;
	background-color: transparent !important;
	border: 1px solid #1977cc !important;
	font-weight: 500;
	box-shadow: none !important;
}
.footer-center-top-market .btn-custome:hover {
	color: #fff !important;
	background-color: #1977cc !important;
}
.foot-menu ul li a {
	color: #cecece;
}
.footer-bottom p {
	color: #fff;
	margin: 0;
}
.foot-link {
	display: flex;
	justify-content: end;
	margin-bottom: 0;
}
.foot-link li {
	position: relative;
	margin: 0 0 0 10px;
	padding: 0 0 0 10px;
}
.foot-copy {
	padding-top: 15px;
	min-width: 500px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.foot-copy p:last-child {
	color: #0082dd;
}
@media (max-width: 991px) {
	.fxt-foot-exp-text div {
		padding: 10px 0;
		min-width: 190px;
	}
	.fxt-foot-exp-text div span:first-of-type {
		font-size: 20px !important;
		padding: 10px;
	}
	.fxt-foot-exp-text div span:last-of-type,
	.fxt-foot-exp-text a {
		font-size: 18px !important;
	}
	.header_social {
		padding-left: 0;
	}
	.fxt-foot-link {
		justify-content: space-evenly;
		padding-left: 0 !important ;
		margin: 20px 0;
	}
}

@media (max-width: 576px) {
	.footer-bottom {
		padding-bottom: 100px;
	}
	.num-6 {
		font-size: 7rem;
		top: -30px;
	}
	.fxt-foot-exp-text div {
		padding: 10px 0;
		min-width: 140px;
	}
	.foot-copy {
		min-width: unset !important;
		display: block;
	}
	.foot-copy p {
		font-size: 14px !important;
	}
}
</style>