<template>
	<section class="padding-60 bg-white o-hidden">
		<div class="container">
			<div class="title">
				<span>Our Process</span>
				<h2 class="fxt-process-dev">From Sprint To Launch, Agility In Action</h2>
				<p>
					GenixBit Labs follow the agile project management process to get things done
					faster and with maximum quality. We break down the entire mobile app development
					project into several phases, each phase dedicated to taking the project further
					to completion.
				</p>
			</div>
			<div class="row justify-content-center d-block d-lg-flex">
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="0"
				>
					<i class="fas fa-angle-right goDown"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M31.2,3.9h53.6c-0.8,1.3-1.2,2.7-1.2,4.3v25.6c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-6.1H98c1.1,0,2-0.9,2-2V8.2
                           c0-4.5-3.7-8.2-8.2-8.2H31.2c-1.1,0-2,0.9-2,2C29.3,3,30.2,3.9,31.2,3.9z M87.5,8.2c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3
                           v15.6h-8.6V8.2z M97.9,79.6L85.1,66.8c1.5-3,2.3-6.4,2.3-9.9c0-12.5-10.2-22.7-22.7-22.7c-12.5,0-22.7,10.2-22.7,22.7
                           c0,2.9,0.5,5.6,1.5,8.1l-2.3,2.3l-9.4-9.4c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6l-2.2,2.2V42.3c0-1.1-0.9-2-2-2
                           c-1.1,0-2,0.9-2,2V64L5.4,81.7c-0.8,0.8-0.8,2,0,2.8C5.8,84.8,6.3,85,6.8,85c0.5,0,1-0.2,1.4-0.6L23,69.5v14.1h-8.6
                           c-1.1,0-2,0.9-2,2v6.2c0,4.5,3.7,8.2,8.2,8.2h58.6c4.5,0,8.2-3.7,8.2-8.2v-1.7c1.4,1.3,3.2,2.1,5.1,2.1h0c2,0,3.8-0.8,5.2-2.2
                           C100.7,87.1,100.7,82.5,97.9,79.6z M64.8,38.1c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.8-18.7,18.8c-7.1,0-13.3-4-16.5-9.8
                           l11.3-11.3V59c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-9.4c0-1.1-0.9-2-2-2h-9.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2H57L46.7,61.9
                           c-0.4-1.6-0.7-3.3-0.7-5C46,46.6,54.4,38.1,64.8,38.1z M20.7,96.1c-2.4,0-4.3-1.9-4.3-4.3v-4.3h54.7v2.3c0,0.1,0,0.1,0,0.2
                           c0,0.1,0,0.1,0,0.2v1.6c0,1.6,0.4,3,1.2,4.3H20.7z M83.6,91.8c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3v-1.6
                           c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-4.3c0-1.1-0.9-2-2-2H26.9v-18l3.6-3.6l9.4,9.4c0.4,0.4,0.9,0.6,1.4,0.6
                           c0.5,0,1-0.2,1.4-0.6l2.7-2.7c4,6.5,11.2,10.8,19.3,10.8c3.5,0,6.9-0.8,9.9-2.3l8.9,8.9V91.8z M95.1,87.2c-0.7,0.7-1.5,1-2.5,1h0
                           c-0.9,0-1.8-0.4-2.4-1L78.1,75.2c1.9-1.4,3.5-3,4.9-4.9l12.1,12.1C96.4,83.7,96.4,85.9,95.1,87.2z M62.8,69.3c0,1.1,0.9,2,2,2
                           c7.9,0,14.3-6.4,14.3-14.3c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,5.7-4.7,10.4-10.4,10.4C63.7,67.3,62.8,68.2,62.8,69.3z M2,37.5H50
                           c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-1.8V20.5c0-1.1-0.9-2-2-2h-9c-1.1,0-2,0.9-2,2v13.1h-3.1V12.5c0-1.1-0.9-2-2-2h-8.6
                           c-1.1,0-2,0.9-2,2v21.1h-3.1V25c0-1.1-0.9-2-2-2H6.1c-1.1,0-2,0.9-2,2v8.6H2c-1.1,0-2,0.9-2,2C0,36.6,0.9,37.5,2,37.5z M39.3,22.5
                           h5.1v11.1h-5.1V22.5z M23.6,14.5h4.7v19.1h-4.7V14.5z M8,27h4.7v6.6H8V27z M67.9,12.5c0-1.1-0.9-2-2-2H50.8c-1.1,0-2,0.9-2,2
                           c0,1.1,0.9,2,2,2h15.1C67,14.5,67.9,13.6,67.9,12.5z M77,11.1c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6
                           c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           C77.5,12,77.3,11.5,77,11.1z M55.1,19.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h3.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H55.1z M65.9,23
                           h9.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-9.7c-1.1,0-2,0.9-2,2C64,22.2,64.8,23,65.9,23z M2,85.9c-0.5,0-1,0.2-1.4,0.6
                           C0.2,86.8,0,87.3,0,87.8c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           c0-0.5-0.2-1-0.6-1.4C3,86.1,2.5,85.9,2,85.9z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>01 Discover</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Requirement gathering</li>
							<li><i class="fas fa-chevron-right"></i>Timeline planning</li>
							<li><i class="fas fa-chevron-right"></i>Commercials</li>
						</ul>
					</div>
				</div>
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="0.2s"
				>
					<i class="fas fa-angle-right goDown"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M0,0v80.6h100V0H0z M96.8,77.4H3.2V3.2h93.5V77.4z M62.9,6.5H6.5v35.5h56.5V6.5z M59.7,38.7h-50v-29h50V38.7z M93.5,6.5
                           H66.1v3.2h27.4V6.5z M93.5,12.9H66.1v3.2h27.4V12.9z M93.5,19.4H66.1v3.2h27.4V19.4z M93.5,25.8H66.1V29h27.4V25.8z M93.5,32.3
                           H66.1v3.2h27.4V32.3z M93.5,38.7H66.1v3.2h27.4V38.7z M33.9,45.2H6.5v29h27.4V45.2z M30.6,71h-21V48.4h21V71z M37.1,74.2h25.8v-29
                           H37.1V74.2z M40.3,48.4h19.4V71H40.3V48.4z M93.5,45.2H66.1v29h27.4V45.2z M90.3,71h-21V48.4h21V71z M33.9,35.5
                           c6.2,0,11.3-5.1,11.3-11.3s-5.1-11.3-11.3-11.3c-6.2,0-11.3,5.1-11.3,11.3S27.6,35.5,33.9,35.5z M33.9,16.1c4.4,0,8.1,3.6,8.1,8.1
                           c0,4.4-3.6,8.1-8.1,8.1s-8.1-3.6-8.1-8.1C25.8,19.7,29.4,16.1,33.9,16.1z M91.9,83.9H15.7L1.6,91.9l14.1,8.1h76.2
                           c4.4,0,8.1-3.6,8.1-8.1C100,87.5,96.4,83.9,91.9,83.9z M16.1,96.5l-8-4.6l8-4.6V96.5z M77.4,96.8H19.4v-9.7h58.1V96.8z M83.9,96.8
                           h-3.2v-9.7h3.2V96.8z M91.9,96.8h-4.8v-9.7h4.8c2.7,0,4.8,2.2,4.8,4.8S94.6,96.8,91.9,96.8z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>02 UI/UX Design</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Wireframe Designing</li>
							<li><i class="fas fa-chevron-right"></i>Prototyping</li>
							<li>
								<i class="fas fa-chevron-right"></i>Brand styling &amp; preset
								creation
							</li>
						</ul>
					</div>
				</div>
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="0.4s"
				>
					<i class="fas fa-angle-right goDown"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M98.3,10.8H57.6c-0.9,0-1.7,0.8-1.7,1.7v32.4c-2.6,0-4.8,1.8-5.3,4.3c-0.1,0.2-0.1,0.4-0.1,0.6v7.4c0.1,1-0.7,1.8-1.7,1.9
                          h-4.7V12.5c0-0.9-0.8-1.7-1.7-1.7H1.7c-0.9,0-1.7,0.8-1.7,1.7v74.9c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7v-25h4.7
                          c2.9-0.1,5.1-2.4,5.1-5.3v-7c0.1-1.1,1-1.9,2.1-1.8v39.2c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7V12.5
                          C100,11.6,99.2,10.8,98.3,10.8z M3.4,14.2h32.7L3.4,33.4V14.2z M33.9,85.8H10.2v-1.7h23.7V85.8z M40.7,59h-3.4V48.3
                          c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v27.2c0,0.9,0.8,1.7,1.7,1.7h27.1c0.9,0,1.7-0.8,1.7-1.7V62.4h3.4v23.3h-3.4
                          v-3.4c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v3.4H3.4V38.1h37.3V59z M22.5,55.5c-3.1,1.1-4.6,4.6-3.5,7.6
                          c1.1,3.1,4.5,4.6,7.6,3.5c1.9-0.7,3.2-2.3,3.7-4.2h3.6v11.4H10.2V50h23.7v9h-3.8C29,56,25.6,54.4,22.5,55.5z M27.1,61.1
                          c0,1.4-1.1,2.6-2.5,2.6c-1.4,0-2.5-1.1-2.5-2.6c0-1.4,1.1-2.6,2.5-2.6C26,58.5,27.1,59.7,27.1,61.1z M40.7,34.7H8l32.7-19.2V34.7z
                          M59.3,61.9h33.4L59.3,84.3V61.9z M96.6,85.8H63.2l33.4-22.4V85.8z M96.6,58.5H59.3V48.3h4.4L61.9,50c-0.7,0.6-0.7,1.7-0.1,2.4
                          c0,0,0,0,0.1,0.1v0.1c0.7,0.7,1.7,0.7,2.4,0l4.7-4.7l0.1-0.1c0.2-0.2,0.4-0.6,0.4-0.9c0-0.1,0-0.2,0-0.3c0-0.4-0.2-0.8-0.5-1.1
                          l-4.7-4.7c-0.7-0.7-1.7-0.7-2.4,0c-0.7,0.7-0.7,1.7,0,2.4l1.8,1.8h-4.4V14.2h37.3V58.5z M16.9,41.5H6.8v3.4h10.2V41.5z M78,17.6
                          H62.7v3.4H78V17.6z M93.2,24.5H62.7v3.4h30.5V24.5z M84.7,31.3h-22v3.4h22V31.3z M71.2,41.5h22v-3.4h-22V41.5z M71.2,48.3h22v-3.4
                          h-22V48.3z M71.2,55.1h16.9v-3.4H71.2V55.1z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>03 Prototypes</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Low-fidelity prototyping</li>
							<li><i class="fas fa-chevron-right"></i>High-Fidelity prototyping</li>
							<li><i class="fas fa-chevron-right"></i>Applying design thinking</li>
						</ul>
					</div>
				</div>
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="0.6s"
				>
					<i class="fas fa-angle-down"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>04 Development</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Technology selection</li>
							<li><i class="fas fa-chevron-right"></i>API</li>
							<li>
								<i class="fas fa-chevron-right"></i>Integrations agile development
								in action
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="row justify-content-center flex-lg-row-reverse d-lg-flex">
				<div
					class="col-lg-3 pl-20 wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="0.8s"
				>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M12.5,85.9c0,0.9,0.7,1.6,1.6,1.6h12.5c0.9,0,1.6-0.7,1.6-1.6V73.4c0-0.9-0.7-1.6-1.6-1.6H14.1c-0.9,0-1.6,0.7-1.6,1.6
                           V85.9z M15.6,75H25v9.4h-9.4V75z M27.7,50.5c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l-5.1,5.1l-5.1-5.1c-0.6-0.6-1.6-0.6-2.2,0
                           c-0.6,0.6-0.6,1.6,0,2.2l5.1,5.1L13,62.9c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l5.1-5.1l5.1,5.1c0.6,0.6,1.6,0.6,2.2,0
                           c0.6-0.6,0.6-1.6,0-2.2l-5.1-5.1l5.1-5.1C28.3,52.1,28.3,51.1,27.7,50.5z M27.8,30.4c0,0,0,0-0.1,0c-0.6-0.6-1.6-0.6-2.2,0
                           c0,0,0,0,0,0L19,36.9L15.2,33c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2l5,5c0.6,0.6,1.6,0.6,2.2,0c0,0,0,0,0,0l7.5-7.5
                           C28.3,32,28.4,31.1,27.8,30.4z M54.7,34.4H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,35.1,55.6,34.4,54.7,34.4z M54.7,56.3H32.8c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           S55.6,56.3,54.7,56.3z M54.7,78.1H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,78.8,55.6,78.1,54.7,78.1z M99.9,43.8c-0.8-10.6-9.6-18.7-20.2-18.7v0c-11.2-0.1-20.4,8.9-20.5,20.1
                           c-0.1,10.8,8.2,19.7,18.9,20.5v32.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V65.6C92.5,64.7,100.8,55,99.9,43.8z M79.7,62.6
                           c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2c9.5,0,17.2,7.7,17.2,17.2C96.9,54.9,89.2,62.6,79.7,62.6z M79.7,36.3
                           c-8.6,0-13.9,7.9-14.2,8.2c-0.4,0.5-0.4,1.2,0,1.7c0.2,0.3,5.6,8.2,14.2,8.2s13.9-7.9,14.2-8.2c0.4-0.5,0.4-1.2,0-1.7
                           C93.6,44.2,88.3,36.3,79.7,36.3z M79.7,51.3L79.7,51.3c-5.4,0-9.4-4.1-10.9-5.9c1.5-1.8,5.5-5.9,10.9-5.9c5.4,0,9.4,4.1,10.9,5.9
                           C89.1,47.2,85.1,51.3,79.7,51.3z M79.7,41.2c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2
                           C83.9,43,82,41.2,79.7,41.2z M79.7,46.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
                           C80.8,45.9,80.3,46.4,79.7,46.4z M67.2,65.6c-0.9,0-1.6,0.7-1.6,1.6v29.7H3.1V25h20.3c0.9,0,1.6-0.7,1.6-1.6V3.1h40.6v21.1
                           c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V1.6c0-0.9-0.7-1.6-1.6-1.6H23.6c-0.5-0.1-0.9,0.1-1.3,0.4L0.5,22.3
                           c-0.1,0.1-0.2,0.2-0.2,0.3l0,0.1C0.1,22.9,0.1,23,0,23.1c0,0.1,0,0.2,0,0.3v75c0,0.9,0.7,1.6,1.6,1.6h65.6c0.9,0,1.6-0.7,1.6-1.6
                           V67.2C68.8,66.3,68.1,65.6,67.2,65.6z M21.9,5.3v16.5H5.3L21.9,5.3z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>05 Quality Assurance</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>User test creation</li>
							<li><i class="fas fa-chevron-right"></i>Manual testing</li>
							<li><i class="fas fa-chevron-right"></i>Automation testing</li>
						</ul>
					</div>
				</div>
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="1s"
				>
					<i class="fas fa-angle-left goUp"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<h4>06 Deployment</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Application handover</li>
							<li><i class="fas fa-chevron-right"></i>Marketplace launches</li>
							<li><i class="fas fa-chevron-right"></i>App store submissions</li>
						</ul>
					</div>
				</div>
				<div
					class="col-lg-3 process-items wow fadeIn"
					data-wow-duration="0.2s"
					data-wow-delay="1.2s"
				>
					<i class="fas fa-angle-left goUp"></i>
					<div class="process-box">
						<div class="process-box-icon">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M23.9,26.7c-5.7,0-10.7,2.6-14.1,6.7H0v1.5h8.8c-1.6,2.4-2.6,5.3-2.9,8.3H3.3v1.5h2.5c0,0.1,0,3.8,0,3.8H0V50
                     h48.6v-1.5H42c0,0,0-3.8,0-3.8h2.9v-1.5h-3c-0.3-3.1-1.3-5.9-2.9-8.3h9.5v-1.5H38C34.6,29.3,29.6,26.7,23.9,26.7z M23.2,48.5h-8.2
                     v-5.3h-1.5v5.3H7.2v-3.7c0-5.9,3.1-11.2,7.8-14.1l8.2,8.2V48.5z M16.4,30c2.3-1.2,4.8-1.8,7.5-1.8c2.6,0,5.1,0.6,7.3,1.7L24,37.6
                     L16.4,30z M40.6,44.9v3.7h-5.7v-5.3h-1.5v5.3h-8.7v-9.6l7.8-8.4C37.4,33.5,40.6,38.8,40.6,44.9z M24.6,12.3h-6.4v1.5h4.9v4.7h3.2
                     V17h-1.8V12.3z M26.4,13.8h3.5v-1.5h-3.5V13.8z M13.5,19.1h0.7V10h-0.5c0.9-4.9,5.1-8.5,10.2-8.5c5.1,0,9.3,3.6,10.2,8.5h-0.2v7.8
                     l-0.8,0.2c0-0.3,0.1-0.7,0.1-1v-4.5c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2v4.5c0,5.1,4.1,9.2,9.2,9.2
                     c4.2,0,7.7-2.8,8.8-6.6l2-0.6c2.4-0.1,4.4-2.1,4.4-4.5c0-2.2-1.5-4-3.6-4.4C34.7,4.3,29.8,0,23.9,0C18,0,13.1,4.3,12.2,10.1
                     c-1.9,0.5-3.3,2.3-3.3,4.4C8.9,17,10.9,19.1,13.5,19.1z M31.7,17.1c0,0.5-0.1,1-0.1,1.5l-7,2.2h-1.2v1.5h1.5l6.3-2
                     c-1.2,2.7-3.9,4.5-7,4.5c-4.3,0-7.8-3.5-7.8-7.8v-4.5c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8V17.1z M35.3,11.5
                     c1.4,0.3,2.4,1.6,2.4,3c0,1.5-1,2.7-2.4,3V11.5z M12.7,11.5v6c-1.4-0.3-2.4-1.6-2.4-3C10.4,13.1,11.4,11.9,12.7,11.5z M44.3,11.5
                     c3.2,0,5.7-2.6,5.7-5.7S47.4,0,44.3,0c-3.2,0-5.7,2.6-5.7,5.7S41.1,11.5,44.3,11.5z M44.3,1.5c2.4,0,4.3,1.9,4.3,4.3
                     c0,2.4-1.9,4.3-4.3,4.3C41.9,10,40,8.1,40,5.7C40,3.4,41.9,1.5,44.3,1.5z M47,5h-2V2.8h-1.5v3.7H47V5z"
								></path>
							</svg>
						</div>
						<h4>07 Support &amp; Maintenance</h4>
						<ul>
							<li><i class="fas fa-chevron-right"></i>Troubleshooting</li>
							<li><i class="fas fa-chevron-right"></i>24/7 support</li>
							<li><i class="fas fa-chevron-right"></i>Managed services</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				class="process-slider d-none p-relative slick-initialized slick-slider slick-dotted"
				style=""
			>
				<div class="slick-list draggable">
					<div
						class="slick-track"
						style="opacity: 1; width: 0px; transform: translate3d(0px, 0px, 0px)"
					>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="-2"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>06 Deployment</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Application
												handover
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Marketplace
												launches
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>App store
												submissions
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="-1"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<path
													fill="#202122"
													d="M23.9,26.7c-5.7,0-10.7,2.6-14.1,6.7H0v1.5h8.8c-1.6,2.4-2.6,5.3-2.9,8.3H3.3v1.5h2.5c0,0.1,0,3.8,0,3.8H0V50
                     h48.6v-1.5H42c0,0,0-3.8,0-3.8h2.9v-1.5h-3c-0.3-3.1-1.3-5.9-2.9-8.3h9.5v-1.5H38C34.6,29.3,29.6,26.7,23.9,26.7z M23.2,48.5h-8.2
                     v-5.3h-1.5v5.3H7.2v-3.7c0-5.9,3.1-11.2,7.8-14.1l8.2,8.2V48.5z M16.4,30c2.3-1.2,4.8-1.8,7.5-1.8c2.6,0,5.1,0.6,7.3,1.7L24,37.6
                     L16.4,30z M40.6,44.9v3.7h-5.7v-5.3h-1.5v5.3h-8.7v-9.6l7.8-8.4C37.4,33.5,40.6,38.8,40.6,44.9z M24.6,12.3h-6.4v1.5h4.9v4.7h3.2
                     V17h-1.8V12.3z M26.4,13.8h3.5v-1.5h-3.5V13.8z M13.5,19.1h0.7V10h-0.5c0.9-4.9,5.1-8.5,10.2-8.5c5.1,0,9.3,3.6,10.2,8.5h-0.2v7.8
                     l-0.8,0.2c0-0.3,0.1-0.7,0.1-1v-4.5c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2v4.5c0,5.1,4.1,9.2,9.2,9.2
                     c4.2,0,7.7-2.8,8.8-6.6l2-0.6c2.4-0.1,4.4-2.1,4.4-4.5c0-2.2-1.5-4-3.6-4.4C34.7,4.3,29.8,0,23.9,0C18,0,13.1,4.3,12.2,10.1
                     c-1.9,0.5-3.3,2.3-3.3,4.4C8.9,17,10.9,19.1,13.5,19.1z M31.7,17.1c0,0.5-0.1,1-0.1,1.5l-7,2.2h-1.2v1.5h1.5l6.3-2
                     c-1.2,2.7-3.9,4.5-7,4.5c-4.3,0-7.8-3.5-7.8-7.8v-4.5c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8V17.1z M35.3,11.5
                     c1.4,0.3,2.4,1.6,2.4,3c0,1.5-1,2.7-2.4,3V11.5z M12.7,11.5v6c-1.4-0.3-2.4-1.6-2.4-3C10.4,13.1,11.4,11.9,12.7,11.5z M44.3,11.5
                     c3.2,0,5.7-2.6,5.7-5.7S47.4,0,44.3,0c-3.2,0-5.7,2.6-5.7,5.7S41.1,11.5,44.3,11.5z M44.3,1.5c2.4,0,4.3,1.9,4.3,4.3
                     c0,2.4-1.9,4.3-4.3,4.3C41.9,10,40,8.1,40,5.7C40,3.4,41.9,1.5,44.3,1.5z M47,5h-2V2.8h-1.5v3.7H47V5z"
												></path>
											</svg>
										</div>
										<h4>07 Support &amp; Maintenance</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Troubleshooting
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>24/7 support
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Managed services
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide"
							data-slick-index="0"
							aria-hidden="true"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide30"
							aria-describedby="slick-slide-control30"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M31.2,3.9h53.6c-0.8,1.3-1.2,2.7-1.2,4.3v25.6c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-6.1H98c1.1,0,2-0.9,2-2V8.2
                           c0-4.5-3.7-8.2-8.2-8.2H31.2c-1.1,0-2,0.9-2,2C29.3,3,30.2,3.9,31.2,3.9z M87.5,8.2c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3
                           v15.6h-8.6V8.2z M97.9,79.6L85.1,66.8c1.5-3,2.3-6.4,2.3-9.9c0-12.5-10.2-22.7-22.7-22.7c-12.5,0-22.7,10.2-22.7,22.7
                           c0,2.9,0.5,5.6,1.5,8.1l-2.3,2.3l-9.4-9.4c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6l-2.2,2.2V42.3c0-1.1-0.9-2-2-2
                           c-1.1,0-2,0.9-2,2V64L5.4,81.7c-0.8,0.8-0.8,2,0,2.8C5.8,84.8,6.3,85,6.8,85c0.5,0,1-0.2,1.4-0.6L23,69.5v14.1h-8.6
                           c-1.1,0-2,0.9-2,2v6.2c0,4.5,3.7,8.2,8.2,8.2h58.6c4.5,0,8.2-3.7,8.2-8.2v-1.7c1.4,1.3,3.2,2.1,5.1,2.1h0c2,0,3.8-0.8,5.2-2.2
                           C100.7,87.1,100.7,82.5,97.9,79.6z M64.8,38.1c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.8-18.7,18.8c-7.1,0-13.3-4-16.5-9.8
                           l11.3-11.3V59c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-9.4c0-1.1-0.9-2-2-2h-9.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2H57L46.7,61.9
                           c-0.4-1.6-0.7-3.3-0.7-5C46,46.6,54.4,38.1,64.8,38.1z M20.7,96.1c-2.4,0-4.3-1.9-4.3-4.3v-4.3h54.7v2.3c0,0.1,0,0.1,0,0.2
                           c0,0.1,0,0.1,0,0.2v1.6c0,1.6,0.4,3,1.2,4.3H20.7z M83.6,91.8c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3v-1.6
                           c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-4.3c0-1.1-0.9-2-2-2H26.9v-18l3.6-3.6l9.4,9.4c0.4,0.4,0.9,0.6,1.4,0.6
                           c0.5,0,1-0.2,1.4-0.6l2.7-2.7c4,6.5,11.2,10.8,19.3,10.8c3.5,0,6.9-0.8,9.9-2.3l8.9,8.9V91.8z M95.1,87.2c-0.7,0.7-1.5,1-2.5,1h0
                           c-0.9,0-1.8-0.4-2.4-1L78.1,75.2c1.9-1.4,3.5-3,4.9-4.9l12.1,12.1C96.4,83.7,96.4,85.9,95.1,87.2z M62.8,69.3c0,1.1,0.9,2,2,2
                           c7.9,0,14.3-6.4,14.3-14.3c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,5.7-4.7,10.4-10.4,10.4C63.7,67.3,62.8,68.2,62.8,69.3z M2,37.5H50
                           c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-1.8V20.5c0-1.1-0.9-2-2-2h-9c-1.1,0-2,0.9-2,2v13.1h-3.1V12.5c0-1.1-0.9-2-2-2h-8.6
                           c-1.1,0-2,0.9-2,2v21.1h-3.1V25c0-1.1-0.9-2-2-2H6.1c-1.1,0-2,0.9-2,2v8.6H2c-1.1,0-2,0.9-2,2C0,36.6,0.9,37.5,2,37.5z M39.3,22.5
                           h5.1v11.1h-5.1V22.5z M23.6,14.5h4.7v19.1h-4.7V14.5z M8,27h4.7v6.6H8V27z M67.9,12.5c0-1.1-0.9-2-2-2H50.8c-1.1,0-2,0.9-2,2
                           c0,1.1,0.9,2,2,2h15.1C67,14.5,67.9,13.6,67.9,12.5z M77,11.1c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6
                           c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           C77.5,12,77.3,11.5,77,11.1z M55.1,19.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h3.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H55.1z M65.9,23
                           h9.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-9.7c-1.1,0-2,0.9-2,2C64,22.2,64.8,23,65.9,23z M2,85.9c-0.5,0-1,0.2-1.4,0.6
                           C0.2,86.8,0,87.3,0,87.8c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           c0-0.5-0.2-1-0.6-1.4C3,86.1,2.5,85.9,2,85.9z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>01 Discover</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Requirement
												gathering
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Timeline
												planning
											</li>
											<li><i class="fas fa-chevron-right"></i>Commercials</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide"
							data-slick-index="1"
							aria-hidden="true"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide31"
							aria-describedby="slick-slide-control31"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M0,0v80.6h100V0H0z M96.8,77.4H3.2V3.2h93.5V77.4z M62.9,6.5H6.5v35.5h56.5V6.5z M59.7,38.7h-50v-29h50V38.7z M93.5,6.5
                           H66.1v3.2h27.4V6.5z M93.5,12.9H66.1v3.2h27.4V12.9z M93.5,19.4H66.1v3.2h27.4V19.4z M93.5,25.8H66.1V29h27.4V25.8z M93.5,32.3
                           H66.1v3.2h27.4V32.3z M93.5,38.7H66.1v3.2h27.4V38.7z M33.9,45.2H6.5v29h27.4V45.2z M30.6,71h-21V48.4h21V71z M37.1,74.2h25.8v-29
                           H37.1V74.2z M40.3,48.4h19.4V71H40.3V48.4z M93.5,45.2H66.1v29h27.4V45.2z M90.3,71h-21V48.4h21V71z M33.9,35.5
                           c6.2,0,11.3-5.1,11.3-11.3s-5.1-11.3-11.3-11.3c-6.2,0-11.3,5.1-11.3,11.3S27.6,35.5,33.9,35.5z M33.9,16.1c4.4,0,8.1,3.6,8.1,8.1
                           c0,4.4-3.6,8.1-8.1,8.1s-8.1-3.6-8.1-8.1C25.8,19.7,29.4,16.1,33.9,16.1z M91.9,83.9H15.7L1.6,91.9l14.1,8.1h76.2
                           c4.4,0,8.1-3.6,8.1-8.1C100,87.5,96.4,83.9,91.9,83.9z M16.1,96.5l-8-4.6l8-4.6V96.5z M77.4,96.8H19.4v-9.7h58.1V96.8z M83.9,96.8
                           h-3.2v-9.7h3.2V96.8z M91.9,96.8h-4.8v-9.7h4.8c2.7,0,4.8,2.2,4.8,4.8S94.6,96.8,91.9,96.8z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>02 UI/UX Design</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Wireframe
												Designing
											</li>
											<li><i class="fas fa-chevron-right"></i>Prototyping</li>
											<li>
												<i class="fas fa-chevron-right"></i>Brand styling
												&amp; preset creation
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide"
							data-slick-index="2"
							aria-hidden="true"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide32"
							aria-describedby="slick-slide-control32"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M98.3,10.8H57.6c-0.9,0-1.7,0.8-1.7,1.7v32.4c-2.6,0-4.8,1.8-5.3,4.3c-0.1,0.2-0.1,0.4-0.1,0.6v7.4c0.1,1-0.7,1.8-1.7,1.9
                          h-4.7V12.5c0-0.9-0.8-1.7-1.7-1.7H1.7c-0.9,0-1.7,0.8-1.7,1.7v74.9c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7v-25h4.7
                          c2.9-0.1,5.1-2.4,5.1-5.3v-7c0.1-1.1,1-1.9,2.1-1.8v39.2c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7V12.5
                          C100,11.6,99.2,10.8,98.3,10.8z M3.4,14.2h32.7L3.4,33.4V14.2z M33.9,85.8H10.2v-1.7h23.7V85.8z M40.7,59h-3.4V48.3
                          c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v27.2c0,0.9,0.8,1.7,1.7,1.7h27.1c0.9,0,1.7-0.8,1.7-1.7V62.4h3.4v23.3h-3.4
                          v-3.4c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v3.4H3.4V38.1h37.3V59z M22.5,55.5c-3.1,1.1-4.6,4.6-3.5,7.6
                          c1.1,3.1,4.5,4.6,7.6,3.5c1.9-0.7,3.2-2.3,3.7-4.2h3.6v11.4H10.2V50h23.7v9h-3.8C29,56,25.6,54.4,22.5,55.5z M27.1,61.1
                          c0,1.4-1.1,2.6-2.5,2.6c-1.4,0-2.5-1.1-2.5-2.6c0-1.4,1.1-2.6,2.5-2.6C26,58.5,27.1,59.7,27.1,61.1z M40.7,34.7H8l32.7-19.2V34.7z
                          M59.3,61.9h33.4L59.3,84.3V61.9z M96.6,85.8H63.2l33.4-22.4V85.8z M96.6,58.5H59.3V48.3h4.4L61.9,50c-0.7,0.6-0.7,1.7-0.1,2.4
                          c0,0,0,0,0.1,0.1v0.1c0.7,0.7,1.7,0.7,2.4,0l4.7-4.7l0.1-0.1c0.2-0.2,0.4-0.6,0.4-0.9c0-0.1,0-0.2,0-0.3c0-0.4-0.2-0.8-0.5-1.1
                          l-4.7-4.7c-0.7-0.7-1.7-0.7-2.4,0c-0.7,0.7-0.7,1.7,0,2.4l1.8,1.8h-4.4V14.2h37.3V58.5z M16.9,41.5H6.8v3.4h10.2V41.5z M78,17.6
                          H62.7v3.4H78V17.6z M93.2,24.5H62.7v3.4h30.5V24.5z M84.7,31.3h-22v3.4h22V31.3z M71.2,41.5h22v-3.4h-22V41.5z M71.2,48.3h22v-3.4
                          h-22V48.3z M71.2,55.1h16.9v-3.4H71.2V55.1z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>03 Prototypes</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Low-fidelity
												prototyping
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>High-Fidelity
												prototyping
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Applying design
												thinking
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide"
							data-slick-index="3"
							aria-hidden="true"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide33"
							aria-describedby="slick-slide-control33"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>04 Development</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Technology
												selection
											</li>
											<li><i class="fas fa-chevron-right"></i>API</li>
											<li>
												<i class="fas fa-chevron-right"></i>Integrations
												agile development in action
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-current slick-active"
							data-slick-index="4"
							aria-hidden="false"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide34"
							aria-describedby="slick-slide-control34"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M12.5,85.9c0,0.9,0.7,1.6,1.6,1.6h12.5c0.9,0,1.6-0.7,1.6-1.6V73.4c0-0.9-0.7-1.6-1.6-1.6H14.1c-0.9,0-1.6,0.7-1.6,1.6
                           V85.9z M15.6,75H25v9.4h-9.4V75z M27.7,50.5c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l-5.1,5.1l-5.1-5.1c-0.6-0.6-1.6-0.6-2.2,0
                           c-0.6,0.6-0.6,1.6,0,2.2l5.1,5.1L13,62.9c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l5.1-5.1l5.1,5.1c0.6,0.6,1.6,0.6,2.2,0
                           c0.6-0.6,0.6-1.6,0-2.2l-5.1-5.1l5.1-5.1C28.3,52.1,28.3,51.1,27.7,50.5z M27.8,30.4c0,0,0,0-0.1,0c-0.6-0.6-1.6-0.6-2.2,0
                           c0,0,0,0,0,0L19,36.9L15.2,33c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2l5,5c0.6,0.6,1.6,0.6,2.2,0c0,0,0,0,0,0l7.5-7.5
                           C28.3,32,28.4,31.1,27.8,30.4z M54.7,34.4H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,35.1,55.6,34.4,54.7,34.4z M54.7,56.3H32.8c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           S55.6,56.3,54.7,56.3z M54.7,78.1H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,78.8,55.6,78.1,54.7,78.1z M99.9,43.8c-0.8-10.6-9.6-18.7-20.2-18.7v0c-11.2-0.1-20.4,8.9-20.5,20.1
                           c-0.1,10.8,8.2,19.7,18.9,20.5v32.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V65.6C92.5,64.7,100.8,55,99.9,43.8z M79.7,62.6
                           c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2c9.5,0,17.2,7.7,17.2,17.2C96.9,54.9,89.2,62.6,79.7,62.6z M79.7,36.3
                           c-8.6,0-13.9,7.9-14.2,8.2c-0.4,0.5-0.4,1.2,0,1.7c0.2,0.3,5.6,8.2,14.2,8.2s13.9-7.9,14.2-8.2c0.4-0.5,0.4-1.2,0-1.7
                           C93.6,44.2,88.3,36.3,79.7,36.3z M79.7,51.3L79.7,51.3c-5.4,0-9.4-4.1-10.9-5.9c1.5-1.8,5.5-5.9,10.9-5.9c5.4,0,9.4,4.1,10.9,5.9
                           C89.1,47.2,85.1,51.3,79.7,51.3z M79.7,41.2c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2
                           C83.9,43,82,41.2,79.7,41.2z M79.7,46.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
                           C80.8,45.9,80.3,46.4,79.7,46.4z M67.2,65.6c-0.9,0-1.6,0.7-1.6,1.6v29.7H3.1V25h20.3c0.9,0,1.6-0.7,1.6-1.6V3.1h40.6v21.1
                           c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V1.6c0-0.9-0.7-1.6-1.6-1.6H23.6c-0.5-0.1-0.9,0.1-1.3,0.4L0.5,22.3
                           c-0.1,0.1-0.2,0.2-0.2,0.3l0,0.1C0.1,22.9,0.1,23,0,23.1c0,0.1,0,0.2,0,0.3v75c0,0.9,0.7,1.6,1.6,1.6h65.6c0.9,0,1.6-0.7,1.6-1.6
                           V67.2C68.8,66.3,68.1,65.6,67.2,65.6z M21.9,5.3v16.5H5.3L21.9,5.3z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>05 Quality Assurance</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>User test
												creation
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Manual testing
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Automation
												testing
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="5"
							aria-hidden="false"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide35"
							aria-describedby="slick-slide-control35"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>06 Deployment</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Application
												handover
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Marketplace
												launches
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>App store
												submissions
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide"
							data-slick-index="6"
							aria-hidden="true"
							style="width: 0px"
							role="tabpanel"
							id="slick-slide36"
							aria-describedby="slick-slide-control36"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<path
													fill="#202122"
													d="M23.9,26.7c-5.7,0-10.7,2.6-14.1,6.7H0v1.5h8.8c-1.6,2.4-2.6,5.3-2.9,8.3H3.3v1.5h2.5c0,0.1,0,3.8,0,3.8H0V50
                     h48.6v-1.5H42c0,0,0-3.8,0-3.8h2.9v-1.5h-3c-0.3-3.1-1.3-5.9-2.9-8.3h9.5v-1.5H38C34.6,29.3,29.6,26.7,23.9,26.7z M23.2,48.5h-8.2
                     v-5.3h-1.5v5.3H7.2v-3.7c0-5.9,3.1-11.2,7.8-14.1l8.2,8.2V48.5z M16.4,30c2.3-1.2,4.8-1.8,7.5-1.8c2.6,0,5.1,0.6,7.3,1.7L24,37.6
                     L16.4,30z M40.6,44.9v3.7h-5.7v-5.3h-1.5v5.3h-8.7v-9.6l7.8-8.4C37.4,33.5,40.6,38.8,40.6,44.9z M24.6,12.3h-6.4v1.5h4.9v4.7h3.2
                     V17h-1.8V12.3z M26.4,13.8h3.5v-1.5h-3.5V13.8z M13.5,19.1h0.7V10h-0.5c0.9-4.9,5.1-8.5,10.2-8.5c5.1,0,9.3,3.6,10.2,8.5h-0.2v7.8
                     l-0.8,0.2c0-0.3,0.1-0.7,0.1-1v-4.5c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2v4.5c0,5.1,4.1,9.2,9.2,9.2
                     c4.2,0,7.7-2.8,8.8-6.6l2-0.6c2.4-0.1,4.4-2.1,4.4-4.5c0-2.2-1.5-4-3.6-4.4C34.7,4.3,29.8,0,23.9,0C18,0,13.1,4.3,12.2,10.1
                     c-1.9,0.5-3.3,2.3-3.3,4.4C8.9,17,10.9,19.1,13.5,19.1z M31.7,17.1c0,0.5-0.1,1-0.1,1.5l-7,2.2h-1.2v1.5h1.5l6.3-2
                     c-1.2,2.7-3.9,4.5-7,4.5c-4.3,0-7.8-3.5-7.8-7.8v-4.5c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8V17.1z M35.3,11.5
                     c1.4,0.3,2.4,1.6,2.4,3c0,1.5-1,2.7-2.4,3V11.5z M12.7,11.5v6c-1.4-0.3-2.4-1.6-2.4-3C10.4,13.1,11.4,11.9,12.7,11.5z M44.3,11.5
                     c3.2,0,5.7-2.6,5.7-5.7S47.4,0,44.3,0c-3.2,0-5.7,2.6-5.7,5.7S41.1,11.5,44.3,11.5z M44.3,1.5c2.4,0,4.3,1.9,4.3,4.3
                     c0,2.4-1.9,4.3-4.3,4.3C41.9,10,40,8.1,40,5.7C40,3.4,41.9,1.5,44.3,1.5z M47,5h-2V2.8h-1.5v3.7H47V5z"
												></path>
											</svg>
										</div>
										<h4>07 Support &amp; Maintenance</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Troubleshooting
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>24/7 support
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Managed services
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="7"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M31.2,3.9h53.6c-0.8,1.3-1.2,2.7-1.2,4.3v25.6c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-6.1H98c1.1,0,2-0.9,2-2V8.2
                           c0-4.5-3.7-8.2-8.2-8.2H31.2c-1.1,0-2,0.9-2,2C29.3,3,30.2,3.9,31.2,3.9z M87.5,8.2c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3
                           v15.6h-8.6V8.2z M97.9,79.6L85.1,66.8c1.5-3,2.3-6.4,2.3-9.9c0-12.5-10.2-22.7-22.7-22.7c-12.5,0-22.7,10.2-22.7,22.7
                           c0,2.9,0.5,5.6,1.5,8.1l-2.3,2.3l-9.4-9.4c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6l-2.2,2.2V42.3c0-1.1-0.9-2-2-2
                           c-1.1,0-2,0.9-2,2V64L5.4,81.7c-0.8,0.8-0.8,2,0,2.8C5.8,84.8,6.3,85,6.8,85c0.5,0,1-0.2,1.4-0.6L23,69.5v14.1h-8.6
                           c-1.1,0-2,0.9-2,2v6.2c0,4.5,3.7,8.2,8.2,8.2h58.6c4.5,0,8.2-3.7,8.2-8.2v-1.7c1.4,1.3,3.2,2.1,5.1,2.1h0c2,0,3.8-0.8,5.2-2.2
                           C100.7,87.1,100.7,82.5,97.9,79.6z M64.8,38.1c10.3,0,18.7,8.4,18.7,18.7c0,10.3-8.4,18.8-18.7,18.8c-7.1,0-13.3-4-16.5-9.8
                           l11.3-11.3V59c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-9.4c0-1.1-0.9-2-2-2h-9.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2H57L46.7,61.9
                           c-0.4-1.6-0.7-3.3-0.7-5C46,46.6,54.4,38.1,64.8,38.1z M20.7,96.1c-2.4,0-4.3-1.9-4.3-4.3v-4.3h54.7v2.3c0,0.1,0,0.1,0,0.2
                           c0,0.1,0,0.1,0,0.2v1.6c0,1.6,0.4,3,1.2,4.3H20.7z M83.6,91.8c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3v-1.6
                           c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-4.3c0-1.1-0.9-2-2-2H26.9v-18l3.6-3.6l9.4,9.4c0.4,0.4,0.9,0.6,1.4,0.6
                           c0.5,0,1-0.2,1.4-0.6l2.7-2.7c4,6.5,11.2,10.8,19.3,10.8c3.5,0,6.9-0.8,9.9-2.3l8.9,8.9V91.8z M95.1,87.2c-0.7,0.7-1.5,1-2.5,1h0
                           c-0.9,0-1.8-0.4-2.4-1L78.1,75.2c1.9-1.4,3.5-3,4.9-4.9l12.1,12.1C96.4,83.7,96.4,85.9,95.1,87.2z M62.8,69.3c0,1.1,0.9,2,2,2
                           c7.9,0,14.3-6.4,14.3-14.3c0-1.1-0.9-2-2-2s-2,0.9-2,2c0,5.7-4.7,10.4-10.4,10.4C63.7,67.3,62.8,68.2,62.8,69.3z M2,37.5H50
                           c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-1.8V20.5c0-1.1-0.9-2-2-2h-9c-1.1,0-2,0.9-2,2v13.1h-3.1V12.5c0-1.1-0.9-2-2-2h-8.6
                           c-1.1,0-2,0.9-2,2v21.1h-3.1V25c0-1.1-0.9-2-2-2H6.1c-1.1,0-2,0.9-2,2v8.6H2c-1.1,0-2,0.9-2,2C0,36.6,0.9,37.5,2,37.5z M39.3,22.5
                           h5.1v11.1h-5.1V22.5z M23.6,14.5h4.7v19.1h-4.7V14.5z M8,27h4.7v6.6H8V27z M67.9,12.5c0-1.1-0.9-2-2-2H50.8c-1.1,0-2,0.9-2,2
                           c0,1.1,0.9,2,2,2h15.1C67,14.5,67.9,13.6,67.9,12.5z M77,11.1c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6
                           c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           C77.5,12,77.3,11.5,77,11.1z M55.1,19.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h3.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H55.1z M65.9,23
                           h9.7c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2h-9.7c-1.1,0-2,0.9-2,2C64,22.2,64.8,23,65.9,23z M2,85.9c-0.5,0-1,0.2-1.4,0.6
                           C0.2,86.8,0,87.3,0,87.8c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
                           c0-0.5-0.2-1-0.6-1.4C3,86.1,2.5,85.9,2,85.9z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>01 Discover</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Requirement
												gathering
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Timeline
												planning
											</li>
											<li><i class="fas fa-chevron-right"></i>Commercials</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="8"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M0,0v80.6h100V0H0z M96.8,77.4H3.2V3.2h93.5V77.4z M62.9,6.5H6.5v35.5h56.5V6.5z M59.7,38.7h-50v-29h50V38.7z M93.5,6.5
                           H66.1v3.2h27.4V6.5z M93.5,12.9H66.1v3.2h27.4V12.9z M93.5,19.4H66.1v3.2h27.4V19.4z M93.5,25.8H66.1V29h27.4V25.8z M93.5,32.3
                           H66.1v3.2h27.4V32.3z M93.5,38.7H66.1v3.2h27.4V38.7z M33.9,45.2H6.5v29h27.4V45.2z M30.6,71h-21V48.4h21V71z M37.1,74.2h25.8v-29
                           H37.1V74.2z M40.3,48.4h19.4V71H40.3V48.4z M93.5,45.2H66.1v29h27.4V45.2z M90.3,71h-21V48.4h21V71z M33.9,35.5
                           c6.2,0,11.3-5.1,11.3-11.3s-5.1-11.3-11.3-11.3c-6.2,0-11.3,5.1-11.3,11.3S27.6,35.5,33.9,35.5z M33.9,16.1c4.4,0,8.1,3.6,8.1,8.1
                           c0,4.4-3.6,8.1-8.1,8.1s-8.1-3.6-8.1-8.1C25.8,19.7,29.4,16.1,33.9,16.1z M91.9,83.9H15.7L1.6,91.9l14.1,8.1h76.2
                           c4.4,0,8.1-3.6,8.1-8.1C100,87.5,96.4,83.9,91.9,83.9z M16.1,96.5l-8-4.6l8-4.6V96.5z M77.4,96.8H19.4v-9.7h58.1V96.8z M83.9,96.8
                           h-3.2v-9.7h3.2V96.8z M91.9,96.8h-4.8v-9.7h4.8c2.7,0,4.8,2.2,4.8,4.8S94.6,96.8,91.9,96.8z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>02 UI/UX Design</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Wireframe
												Designing
											</li>
											<li><i class="fas fa-chevron-right"></i>Prototyping</li>
											<li>
												<i class="fas fa-chevron-right"></i>Brand styling
												&amp; preset creation
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="9"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M98.3,10.8H57.6c-0.9,0-1.7,0.8-1.7,1.7v32.4c-2.6,0-4.8,1.8-5.3,4.3c-0.1,0.2-0.1,0.4-0.1,0.6v7.4c0.1,1-0.7,1.8-1.7,1.9
                          h-4.7V12.5c0-0.9-0.8-1.7-1.7-1.7H1.7c-0.9,0-1.7,0.8-1.7,1.7v74.9c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7v-25h4.7
                          c2.9-0.1,5.1-2.4,5.1-5.3v-7c0.1-1.1,1-1.9,2.1-1.8v39.2c0,0.9,0.8,1.7,1.7,1.7h40.7c0.9,0,1.7-0.8,1.7-1.7V12.5
                          C100,11.6,99.2,10.8,98.3,10.8z M3.4,14.2h32.7L3.4,33.4V14.2z M33.9,85.8H10.2v-1.7h23.7V85.8z M40.7,59h-3.4V48.3
                          c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v27.2c0,0.9,0.8,1.7,1.7,1.7h27.1c0.9,0,1.7-0.8,1.7-1.7V62.4h3.4v23.3h-3.4
                          v-3.4c0-0.9-0.8-1.7-1.7-1.7H8.5c-0.9,0-1.7,0.8-1.7,1.7v3.4H3.4V38.1h37.3V59z M22.5,55.5c-3.1,1.1-4.6,4.6-3.5,7.6
                          c1.1,3.1,4.5,4.6,7.6,3.5c1.9-0.7,3.2-2.3,3.7-4.2h3.6v11.4H10.2V50h23.7v9h-3.8C29,56,25.6,54.4,22.5,55.5z M27.1,61.1
                          c0,1.4-1.1,2.6-2.5,2.6c-1.4,0-2.5-1.1-2.5-2.6c0-1.4,1.1-2.6,2.5-2.6C26,58.5,27.1,59.7,27.1,61.1z M40.7,34.7H8l32.7-19.2V34.7z
                          M59.3,61.9h33.4L59.3,84.3V61.9z M96.6,85.8H63.2l33.4-22.4V85.8z M96.6,58.5H59.3V48.3h4.4L61.9,50c-0.7,0.6-0.7,1.7-0.1,2.4
                          c0,0,0,0,0.1,0.1v0.1c0.7,0.7,1.7,0.7,2.4,0l4.7-4.7l0.1-0.1c0.2-0.2,0.4-0.6,0.4-0.9c0-0.1,0-0.2,0-0.3c0-0.4-0.2-0.8-0.5-1.1
                          l-4.7-4.7c-0.7-0.7-1.7-0.7-2.4,0c-0.7,0.7-0.7,1.7,0,2.4l1.8,1.8h-4.4V14.2h37.3V58.5z M16.9,41.5H6.8v3.4h10.2V41.5z M78,17.6
                          H62.7v3.4H78V17.6z M93.2,24.5H62.7v3.4h30.5V24.5z M84.7,31.3h-22v3.4h22V31.3z M71.2,41.5h22v-3.4h-22V41.5z M71.2,48.3h22v-3.4
                          h-22V48.3z M71.2,55.1h16.9v-3.4H71.2V55.1z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>03 Prototypes</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Low-fidelity
												prototyping
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>High-Fidelity
												prototyping
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Applying design
												thinking
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="10"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>04 Development</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Technology
												selection
											</li>
											<li><i class="fas fa-chevron-right"></i>API</li>
											<li>
												<i class="fas fa-chevron-right"></i>Integrations
												agile development in action
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="11"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M12.5,85.9c0,0.9,0.7,1.6,1.6,1.6h12.5c0.9,0,1.6-0.7,1.6-1.6V73.4c0-0.9-0.7-1.6-1.6-1.6H14.1c-0.9,0-1.6,0.7-1.6,1.6
                           V85.9z M15.6,75H25v9.4h-9.4V75z M27.7,50.5c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l-5.1,5.1l-5.1-5.1c-0.6-0.6-1.6-0.6-2.2,0
                           c-0.6,0.6-0.6,1.6,0,2.2l5.1,5.1L13,62.9c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l5.1-5.1l5.1,5.1c0.6,0.6,1.6,0.6,2.2,0
                           c0.6-0.6,0.6-1.6,0-2.2l-5.1-5.1l5.1-5.1C28.3,52.1,28.3,51.1,27.7,50.5z M27.8,30.4c0,0,0,0-0.1,0c-0.6-0.6-1.6-0.6-2.2,0
                           c0,0,0,0,0,0L19,36.9L15.2,33c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2l5,5c0.6,0.6,1.6,0.6,2.2,0c0,0,0,0,0,0l7.5-7.5
                           C28.3,32,28.4,31.1,27.8,30.4z M54.7,34.4H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,35.1,55.6,34.4,54.7,34.4z M54.7,56.3H32.8c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           S55.6,56.3,54.7,56.3z M54.7,78.1H32.8c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h21.9c0.9,0,1.6-0.7,1.6-1.6
                           C56.3,78.8,55.6,78.1,54.7,78.1z M99.9,43.8c-0.8-10.6-9.6-18.7-20.2-18.7v0c-11.2-0.1-20.4,8.9-20.5,20.1
                           c-0.1,10.8,8.2,19.7,18.9,20.5v32.8c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6V65.6C92.5,64.7,100.8,55,99.9,43.8z M79.7,62.6
                           c-9.5,0-17.2-7.7-17.2-17.2c0-9.5,7.7-17.2,17.2-17.2c9.5,0,17.2,7.7,17.2,17.2C96.9,54.9,89.2,62.6,79.7,62.6z M79.7,36.3
                           c-8.6,0-13.9,7.9-14.2,8.2c-0.4,0.5-0.4,1.2,0,1.7c0.2,0.3,5.6,8.2,14.2,8.2s13.9-7.9,14.2-8.2c0.4-0.5,0.4-1.2,0-1.7
                           C93.6,44.2,88.3,36.3,79.7,36.3z M79.7,51.3L79.7,51.3c-5.4,0-9.4-4.1-10.9-5.9c1.5-1.8,5.5-5.9,10.9-5.9c5.4,0,9.4,4.1,10.9,5.9
                           C89.1,47.2,85.1,51.3,79.7,51.3z M79.7,41.2c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2
                           C83.9,43,82,41.2,79.7,41.2z M79.7,46.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
                           C80.8,45.9,80.3,46.4,79.7,46.4z M67.2,65.6c-0.9,0-1.6,0.7-1.6,1.6v29.7H3.1V25h20.3c0.9,0,1.6-0.7,1.6-1.6V3.1h40.6v21.1
                           c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6V1.6c0-0.9-0.7-1.6-1.6-1.6H23.6c-0.5-0.1-0.9,0.1-1.3,0.4L0.5,22.3
                           c-0.1,0.1-0.2,0.2-0.2,0.3l0,0.1C0.1,22.9,0.1,23,0,23.1c0,0.1,0,0.2,0,0.3v75c0,0.9,0.7,1.6,1.6,1.6h65.6c0.9,0,1.6-0.7,1.6-1.6
                           V67.2C68.8,66.3,68.1,65.6,67.2,65.6z M21.9,5.3v16.5H5.3L21.9,5.3z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>05 Quality Assurance</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>User test
												creation
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Manual testing
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Automation
												testing
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="12"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 100 100"
												style="enable-background: new 0 0 100 100"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.8,4C8.3,4,6.5,6,6.5,8.5c0,2.5,1.8,4.5,4.3,4.5c2.5,0,4.5-2,4.5-4.5C15.3,6,13.3,4,10.8,4z M10.8,9.9
                           c-0.8,0-1.2-0.6-1.2-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C12.2,9.2,11.6,9.9,10.8,9.9z M22.3,4c-2.5,0-4.5,2-4.5,4.5
                           c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C26.7,6,24.7,4,22.3,4z M22.3,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4
                           c0.8,0,1.4,0.6,1.4,1.4C23.6,9.2,23,9.9,22.3,9.9z M33.7,4c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5
                           C38.1,6,36.2,4,33.7,4z M33.7,9.9c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C35,9.2,34.5,9.9,33.7,9.9z
                           M73.8,6.9H45c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h28.8c0.9,0,1.5-0.6,1.5-1.5C75.3,7.7,74.7,6.9,73.8,6.9z M66.6,71.3
                           l1.7,0.3c0.2,0.6,0.5,1.1,0.6,1.5L68,74.6c-0.5,0.6-0.3,1.4,0.2,2l2.8,2.8c0.5,0.5,1.4,0.6,2,0.2l1.4-0.9c0.5,0.3,1.1,0.5,1.5,0.6
                           l0.3,1.7c0.2,0.8,0.8,1.2,1.5,1.2h4c0.8,0,1.4-0.5,1.5-1.2l0.3-1.7c0.6-0.2,1.1-0.5,1.5-0.6l1.4,0.9c0.6,0.5,1.4,0.3,2-0.2
                           l2.8-2.8c0.5-0.5,0.6-1.4,0.2-2l-0.9-1.4c0.3-0.5,0.5-1.1,0.6-1.5l1.7-0.3c0.8-0.2,1.2-0.8,1.2-1.5v-4c0-0.8-0.6-1.4-1.2-1.5
                           l-1.7-0.3c-0.2-0.6-0.5-1.1-0.6-1.5l0.9-1.4c0.5-0.6,0.3-1.4-0.2-2l-2.8-2.8c-0.5-0.5-1.4-0.6-2-0.2L85.1,57
                           c-0.5-0.3-1.1-0.5-1.5-0.6l-0.3-1.7c-0.2-0.8-0.8-1.2-1.5-1.2h-4c-0.8,0-1.4,0.5-1.5,1.2l-0.3,1.7c-0.6,0.2-1.1,0.5-1.5,0.6
                           l-1.4-0.9c-0.6-0.5-1.4-0.3-2,0.2L68.1,59c-0.5,0.5-0.6,1.4-0.2,2l0.9,1.4c-0.3,0.5-0.5,1.1-0.6,1.5l-1.7,0.3
                           c-0.8,0.2-1.2,0.8-1.2,1.5v4C65.3,70.6,65.8,71.2,66.6,71.3z M68.4,66.9l1.2-0.2c0.6-0.2,1.1-0.6,1.2-1.1c0.2-0.9,0.6-1.8,1.1-2.6
                           c0.3-0.5,0.3-1.2,0-1.7l-0.8-1.1l0.9-0.9l1.1,0.8c0.5,0.3,1.1,0.3,1.7,0c0.8-0.5,1.7-0.8,2.6-1.1c0.6-0.2,1.1-0.6,1.2-1.2l0.3-1.4
                           h1.4l0.2,1.4c0.2,0.6,0.6,1.1,1.2,1.2c0.9,0.2,1.7,0.6,2.6,1.1c0.5,0.3,1.2,0.3,1.7,0l1.1-0.8l0.9,0.9l-0.8,1.1
                           c-0.3,0.5-0.3,1.2,0,1.7c0.5,0.8,0.9,1.7,1.1,2.6c0.2,0.6,0.6,1.1,1.2,1.2l1.2,0.3v1.2l-1.4,0.2c-0.6,0.2-1.1,0.6-1.2,1.2
                           c-0.2,0.9-0.6,1.7-1.1,2.6c-0.3,0.5-0.3,1.2,0,1.7l0.8,1.1l-0.9,0.9L86,75.3c-0.5-0.3-1.2-0.3-1.7,0c-0.8,0.5-1.7,0.8-2.6,1.1
                           c-0.6,0.2-1.1,0.6-1.2,1.2L80.1,79h-1.4l-0.2-1.4c-0.2-0.6-0.6-1.1-1.2-1.2c-0.9-0.2-1.7-0.6-2.6-1.1c-0.5-0.3-1.2-0.3-1.7,0
                           l-0.9,0.8l-0.9-0.9l0.8-1.1c0.3-0.5,0.3-1.2,0-1.7c-0.5-0.8-0.9-1.7-1.1-2.6c-0.2-0.6-0.6-1.1-1.2-1.2l-1.2-0.3V66.9z M79.7,74.1
                           c3.5,0,6.5-2.9,6.5-6.5c0-3.5-2.9-6.5-6.5-6.5c-3.5,0-6.5,2.9-6.5,6.5C73.2,71.2,76.1,74.1,79.7,74.1z M79.7,64.3
                           c1.8,0,3.4,1.5,3.4,3.4c0,1.8-1.5,3.4-3.4,3.4c-0.9,0-1.7-0.3-2.5-0.9c-0.6-0.6-0.9-1.5-0.9-2.5C76.3,65.8,77.8,64.3,79.7,64.3z
                           M15.9,38.4c0.6,0.6,1.5,0.5,2.2-0.2c0.6-0.6,0.5-1.5-0.2-2.2l-5.1-4.3l5.1-4.3c0.6-0.6,0.8-1.5,0.2-2.2c-0.6-0.6-1.5-0.8-2.2-0.2
                           l-6.3,5.5c-0.3,0.3-0.5,0.8-0.5,1.2c0,0.5,0.2,0.9,0.5,1.2L15.9,38.4z M35.4,31.4l-5.1,4.6c-0.6,0.5-0.6,1.5-0.2,2.2
                           c0.6,0.6,1.5,0.8,2.2,0.2l6.3-5.5c0.3-0.3,0.5-0.8,0.5-1.2c0-0.5-0.2-0.9-0.5-1.2l-6.3-5.5c-0.6-0.6-1.5-0.5-2.2,0.2
                           c-0.6,0.6-0.5,1.5,0.2,2.2L35.4,31.4z M20.6,40.4c0.8,0.3,1.7-0.2,2-0.9L28.2,25c0.3-0.8-0.2-1.7-0.9-2c-0.8-0.3-1.7,0.2-2,0.9
                           l-5.5,14.5C19.4,39.1,19.9,40.1,20.6,40.4z M95.1,35.4H82V4.8C82,2.2,79.8,0,77.2,0H4.8C2.2,0,0,2.2,0,4.8v72.4
                           C0,79.8,2.2,82,4.8,82h54.4v13.1c0,2.6,2.2,4.9,4.9,4.9h31c2.6,0,4.9-2.2,4.9-4.9V40.2C100,37.6,97.8,35.4,95.1,35.4z M2.9,4.6
                           c0-0.9,0.8-1.7,1.7-1.7H77c1.1,0,1.7,0.9,1.7,1.7V14H2.9V4.6z M59.2,40.2v38.7H4.6c-0.9,0-1.7-0.8-1.7-1.7V17.1h76v18.2H64.1
                           C61.5,35.3,59.2,37.4,59.2,40.2z M96.9,95.1c0,1.1-0.8,1.8-1.8,1.8h-31c-0.9,0-1.8-0.8-1.8-1.8v-6.2h34.7V95.1z M96.9,86H62.2
                           V49.5h34.7V86z M96.9,46.4H62.2v-6.2c0-0.9,0.8-1.8,1.8-1.8h31c0.9,0,1.8,0.8,1.8,1.8V46.4z M84.4,40.8h-9.6
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h9.6c0.9,0,1.5-0.6,1.5-1.5C86,41.6,85.2,40.8,84.4,40.8z M74.9,94.5h9.6
                           c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-9.6c-0.9,0-1.5,0.6-1.5,1.5C73.3,93.8,74,94.5,74.9,94.5z M51.6,67.5H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,68.1,52.4,67.5,51.6,67.5z M51.6,56.9H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,57.6,52.4,56.9,51.6,56.9z M51.6,46.4H10.5
                           c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h41.1c0.9,0,1.5-0.6,1.5-1.5C53.2,47,52.4,46.4,51.6,46.4z"
														></path>
													</g>
												</g>
											</svg>
										</div>
										<h4>06 Deployment</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Application
												handover
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Marketplace
												launches
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>App store
												submissions
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-cloned"
							data-slick-index="13"
							aria-hidden="true"
							style="width: 0px"
							tabindex="-1"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="process-box">
										<div class="process-box-icon">
											<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<path
													fill="#202122"
													d="M23.9,26.7c-5.7,0-10.7,2.6-14.1,6.7H0v1.5h8.8c-1.6,2.4-2.6,5.3-2.9,8.3H3.3v1.5h2.5c0,0.1,0,3.8,0,3.8H0V50
                     h48.6v-1.5H42c0,0,0-3.8,0-3.8h2.9v-1.5h-3c-0.3-3.1-1.3-5.9-2.9-8.3h9.5v-1.5H38C34.6,29.3,29.6,26.7,23.9,26.7z M23.2,48.5h-8.2
                     v-5.3h-1.5v5.3H7.2v-3.7c0-5.9,3.1-11.2,7.8-14.1l8.2,8.2V48.5z M16.4,30c2.3-1.2,4.8-1.8,7.5-1.8c2.6,0,5.1,0.6,7.3,1.7L24,37.6
                     L16.4,30z M40.6,44.9v3.7h-5.7v-5.3h-1.5v5.3h-8.7v-9.6l7.8-8.4C37.4,33.5,40.6,38.8,40.6,44.9z M24.6,12.3h-6.4v1.5h4.9v4.7h3.2
                     V17h-1.8V12.3z M26.4,13.8h3.5v-1.5h-3.5V13.8z M13.5,19.1h0.7V10h-0.5c0.9-4.9,5.1-8.5,10.2-8.5c5.1,0,9.3,3.6,10.2,8.5h-0.2v7.8
                     l-0.8,0.2c0-0.3,0.1-0.7,0.1-1v-4.5c0-5.1-4.1-9.2-9.2-9.2c-5.1,0-9.2,4.1-9.2,9.2v4.5c0,5.1,4.1,9.2,9.2,9.2
                     c4.2,0,7.7-2.8,8.8-6.6l2-0.6c2.4-0.1,4.4-2.1,4.4-4.5c0-2.2-1.5-4-3.6-4.4C34.7,4.3,29.8,0,23.9,0C18,0,13.1,4.3,12.2,10.1
                     c-1.9,0.5-3.3,2.3-3.3,4.4C8.9,17,10.9,19.1,13.5,19.1z M31.7,17.1c0,0.5-0.1,1-0.1,1.5l-7,2.2h-1.2v1.5h1.5l6.3-2
                     c-1.2,2.7-3.9,4.5-7,4.5c-4.3,0-7.8-3.5-7.8-7.8v-4.5c0-4.3,3.5-7.8,7.8-7.8s7.8,3.5,7.8,7.8V17.1z M35.3,11.5
                     c1.4,0.3,2.4,1.6,2.4,3c0,1.5-1,2.7-2.4,3V11.5z M12.7,11.5v6c-1.4-0.3-2.4-1.6-2.4-3C10.4,13.1,11.4,11.9,12.7,11.5z M44.3,11.5
                     c3.2,0,5.7-2.6,5.7-5.7S47.4,0,44.3,0c-3.2,0-5.7,2.6-5.7,5.7S41.1,11.5,44.3,11.5z M44.3,1.5c2.4,0,4.3,1.9,4.3,4.3
                     c0,2.4-1.9,4.3-4.3,4.3C41.9,10,40,8.1,40,5.7C40,3.4,41.9,1.5,44.3,1.5z M47,5h-2V2.8h-1.5v3.7H47V5z"
												></path>
											</svg>
										</div>
										<h4>07 Support &amp; Maintenance</h4>
										<ul>
											<li>
												<i class="fas fa-chevron-right"></i>Troubleshooting
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>24/7 support
											</li>
											<li>
												<i class="fas fa-chevron-right"></i>Managed services
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ul class="slick-dots" style="" role="tablist">
					<li class="" role="presentation">
						<button
							type="button"
							role="tab"
							id="slick-slide-control30"
							aria-controls="slick-slide30"
							aria-label="1 of 4"
							tabindex="-1"
						>
							1
						</button>
					</li>
					<li role="presentation" class="">
						<button
							type="button"
							role="tab"
							id="slick-slide-control31"
							aria-controls="slick-slide31"
							aria-label="2 of 4"
							tabindex="-1"
						>
							2
						</button>
					</li>
					<li role="presentation" class="">
						<button
							type="button"
							role="tab"
							id="slick-slide-control32"
							aria-controls="slick-slide32"
							aria-label="3 of 4"
							tabindex="-1"
						>
							3
						</button>
					</li>
					<li role="presentation" class="">
						<button
							type="button"
							role="tab"
							id="slick-slide-control33"
							aria-controls="slick-slide33"
							aria-label="4 of 4"
							tabindex="-1"
						>
							4
						</button>
					</li>
					<li role="presentation" class="slick-active">
						<button
							type="button"
							role="tab"
							id="slick-slide-control34"
							aria-controls="slick-slide34"
							aria-label="5 of 4"
							tabindex="0"
							aria-selected="true"
						>
							5
						</button>
					</li>
					<li role="presentation" class="">
						<button
							type="button"
							role="tab"
							id="slick-slide-control35"
							aria-controls="slick-slide35"
							aria-label="6 of 4"
							tabindex="-1"
						>
							6
						</button>
					</li>
					<li role="presentation" class="">
						<button
							type="button"
							role="tab"
							id="slick-slide-control36"
							aria-controls="slick-slide36"
							aria-label="7 of 4"
							tabindex="-1"
						>
							7
						</button>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 991px) {
	.process-items::before {
		display: none;
	}
	.process-items i {
		top: unset !important;
		bottom: -30px !important;
		left: 50% !important;
	}
	.process-items i.goDown {
		transform: translateX(-50%) rotate(90deg);
	}
	.process-items i.goUp {
		top: -30px !important;
		bottom: unset !important;
		transform: translateX(-50%) rotate(-90deg);
	}
}
@media (max-width: 576px) {
	.fxt-process-dev {
		font-size:20px !important;
		font-weight:bold !important;
	}
}
</style>
