<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Reality Visions for Your Business</span>
				<h2 class="fxt-process-dev">Our Feet in Augmented Reality App Development Services</h2>
				<p>
					Our skills in developing stunning, immersive AR experiences across AR supporting devices
					have won the favor of our clientele coming from diverse markets and industries.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-4" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-4" v-for="item in items2" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'AR Apps for Smartphones &amp; Tablets',
					text: 'We leverage built-in capabilities of mobile devices like camera, GPS, Bluetooth, connectivity, and other features &amp; functionality to support AR application development for your brands and business to touch new highs that ultimately help you to hit your business goals. We carry AR app development in native platform tools and Unity.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <g transform="translate(0,-288.53332)">
                      <path class="st0" d="M4,288.7c-2.3,0-4.1,1.9-4.1,4.1v65.8c0,2.3,1.9,4.1,4.1,4.1h38.4V360H4c-0.8,0-1.4-0.6-1.4-1.4v-65.8
                      c0-0.8,0.6-1.4,1.4-1.4h46.6c0.8,0,1.4,0.6,1.4,1.4v15.1h2.7v-15.1c0-2.3-1.9-4.1-4.1-4.1H4z"></path>
                      <path class="st0" d="M49.2,310.7c-2.3,0-4.1,1.9-4.1,4.1v43.9c0,2.3,1.9,4.1,4.1,4.1h20.6c2.3,0,4.1-1.9,4.1-4.1v-43.9
                      c0-2.3-1.9-4.1-4.1-4.1H49.2z M49.2,313.4h20.6c0.8,0,1.4,0.6,1.4,1.4v43.9c0,0.8-0.6,1.4-1.4,1.4H49.2c-0.8,0-1.4-0.6-1.4-1.4
                      v-43.9C47.8,314,48.4,313.4,49.2,313.4z"></path>
                      <path class="st0" d="M60.9,355.9c0,0.8-0.6,1.4-1.4,1.4c0,0,0,0,0,0c-0.8,0-1.4-0.6-1.4-1.4l0,0c0-0.8,0.6-1.4,1.4-1.4c0,0,0,0,0,0
                      C60.2,354.5,60.9,355.1,60.9,355.9L60.9,355.9z"></path>
                      <path class="st0" d="M27.3,349c-2.3,0-4.1,1.9-4.1,4.1c0,2.3,1.9,4.1,4.1,4.1s4.1-1.9,4.1-4.1C31.4,350.9,29.5,349,27.3,349z
                      M27.3,351.8c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4C25.9,352.4,26.5,351.8,27.3,351.8z"></path>
                      <path class="st0" d="M23,294.2c-0.8,0.1-1.3,0.8-1.2,1.5c0.1,0.7,0.7,1.2,1.3,1.2h9.6c0.8,0.1,1.4-0.5,1.5-1.3
                      c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0h-9.6C23.1,294.2,23.1,294.2,23,294.2z"></path>
                      <path class="st0" d="M57.4,316.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0,0,0,0,0,0h4.1c0.8,0,1.4-0.6,1.4-1.4
                      c0-0.8-0.6-1.4-1.4-1.4H57.4z"></path>
                   </g>
                </svg>
          `
				},
				{
					title: 'AR Consultation',
					text: 'Augmented Reality is a buzz in the market, but it is not for everyone and not every company has equal capacities to meet your bespoke requirements. In due course, the right consultant can lead you to the right decision and the right team to get the best out of the present market. Do you think our AR consulting services can add value?',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <path class="st0" d="M33.6,0.3c-2.2,0-4,1.2-5,2.9c-0.6-0.4-1.2-0.6-1.9-0.6h-0.5c-2.3,0-5.1,1.1-6.4,3.2c-1.4,2.1-2.1,4.4-1.4,6.9
                   l2.2,8.3h-0.8c-1.8,0-3.4,1.6-3.4,3.4v2.3c0,1.8,1.6,3.4,3.4,3.4h1.3c0.6,4.5,3.1,8.4,6.7,10.9v5.1c0,0.1,0,0.2,0,0.3l-12,4.1
                   C11.2,52,7.9,56,7.3,60.7c-0.1,0.6-0.1,1.2-0.1,1.8v10.1c0,0.7,0.5,1.1,1.1,1.1h57.4c0.7,0,1.1-0.5,1.1-1.1V62.5
                   c0-0.6,0-1.2-0.1-1.7c-0.6-4.8-3.9-8.8-8.6-10.2l-12-4.1c0-0.1,0-0.2,0-0.3V41c1.6-1.1,3-2.5,4.1-4.1c4.1-0.3,7.4-3.8,7.4-8v-4.6
                   c0-1.8-1.6-3.4-3.4-3.4h-0.8l1.8-8c0.7-3-0.2-6.2-2.1-8.7c-1.8-2.5-4.8-3.9-8-3.9H33.6z M33.6,2.6H45c2.5,0,4.8,1.1,6.2,3.2
                   c1.6,1.8,2.1,4.4,1.6,6.7L51,20.9h-0.2c-0.7,0-1.1-0.5-1.1-1.1v-4.6c0-1.8-1.6-3.4-3.4-3.4H27.8c-1.8,0-3.4,1.6-3.4,3.4v4.6
                   c0,0.7-0.5,1.1-1.1,1.1H23l-2.3-9c-0.5-1.6-0.2-3.4,0.9-4.8c1.1-1.4,2.8-2.3,4.6-2.3h0.5c0.7,0,1.1,0.5,1.1,1.1
                   c0,0.5,0.3,0.9,0.7,1.1c0.1,0.1,0.3,0.1,0.5,0.1c0.7,0,1.1-0.5,1.1-1.1C30.1,4.2,31.7,2.6,33.6,2.6z M27.8,14h18.4
                   c0.7,0,1.1,0.5,1.1,1.1v4.6c0,1.8,1.6,3.4,3.4,3.4v4.6c0,2.5-0.7,4.9-1.9,6.9h-5.2c-0.5-1.3-1.8-2.3-3.2-2.3h-2.3
                   c-1.8,0-3.4,1.6-3.4,3.4c0,1.8,1.6,3.4,3.4,3.4h2.3c1.4,0,2.7-1,3.2-2.3h3.6c-2.5,2.8-6.2,4.6-10.2,4.6c-7.6,0-13.8-6.2-13.8-13.8
                   v-4.6c1.8,0,3.4-1.6,3.4-3.4v-4.6C26.7,14.5,27.1,14,27.8,14z M19.8,23.2h1.1v4.6h-1.1c-0.7,0-1.1-0.5-1.1-1.1v-2.3
                   C18.6,23.7,19.1,23.2,19.8,23.2z M53.1,23.2h1.1c0.7,0,1.1,0.5,1.1,1.1v3.4h-2.3V23.2z M52.9,30.1h2.4c-0.4,1.9-1.7,3.5-3.5,4.2
                   C52.3,33,52.7,31.6,52.9,30.1z M38.1,34.7h2.3c0.7,0,1.1,0.5,1.1,1.1S41.1,37,40.4,37h-2.3c-0.7,0-1.1-0.5-1.1-1.1
                   S37.5,34.7,38.1,34.7z M30.1,42.3c2.1,1,4.4,1.5,6.9,1.5c2.5,0,4.8-0.6,6.9-1.5v3.8c0,0.5,0,0.9-0.1,1.3c-0.8,3-3.5,5.3-6.8,5.3
                   c-2.5,0-4.7-1.2-6-3.2c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.8-0.5-1.7-0.5-2.6V42.3z M28.2,48.7
                   c1.1,3.8,4.6,6.6,8.8,6.6s7.7-2.8,8.8-6.6l11.7,3.9c1.9,0.6,3.5,1.8,4.7,3.3c-6.5,1.4-11.4,7.4-11.4,14.4v1.1H23.2v-1.4
                   c0-6.7-4.6-12.7-11.3-14.3c1.2-1.4,2.7-2.5,4.6-3.1L28.2,48.7z M10.6,57.9c6,1.1,10.3,6.2,10.3,12.4v1.1H9.5v-9c0-0.4,0-0.9,0.1-1.3
                   C9.7,60,10.1,58.9,10.6,57.9z M63.4,57.9c0.5,1,0.9,2.1,1,3.3c0.1,0.4,0.1,0.9,0.1,1.3v9H53.1v-1.1C53.1,64.1,57.4,59,63.4,57.9z"></path>
                </svg>
          `
				},
				{
					title: 'AR Cardboard Applications',
					text: 'Today Google Cardboard is one of the cheapest AR and VR viewer for the apps in mobile devices. Our expertise to provide the excellence in the AR app development that uses Cardboard viewer. Do your industry and targeted audience favor AR cardboard apps?',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <g transform="translate(0,-1020.3622)">
                      <path class="st0" d="M11.6,1033.3c-3.1,0-5.7,2.6-5.7,5.7v2.8H1.6c-0.8,0-1.4,0.6-1.4,1.4v37c0,0.8,0.6,1.4,1.4,1.4h29.7
                      c0.7,0,1.2-0.5,1.4-1.1l0.4-1.7h8.1l0.4,1.7c0.1,0.6,0.7,1.1,1.4,1.1h29.7c0.8,0,1.4-0.6,1.4-1.4v-37c0-0.8-0.6-1.4-1.4-1.4h-4.3
                      v-1.2l0-1.6c0-3.1-2.6-5.7-5.7-5.7H11.6z M11.6,1036.1h51.2c1.6,0,2.8,1.2,2.8,2.8l0,1.6v1.2H8.7v-2.8
                      C8.7,1037.4,10,1036.1,11.6,1036.1z M3.1,1044.6h63.8c0.1,0,0.1,0,0.2,0h4.2v34.1H44.2l-1.3-6c-0.3-1.3-0.8-2.6-1.8-3.6
                      c-0.9-1-2.3-1.7-3.9-1.7c-1.6,0-2.9,0.7-3.9,1.7c-0.9,1-1.5,2.3-1.8,3.6l-1.3,6H3.1V1044.6z M20.1,1053.2c-4.7,0-8.5,3.8-8.5,8.5
                      c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5C28.6,1057,24.8,1053.2,20.1,1053.2z M54.2,1053.2c-4.7,0-8.5,3.8-8.5,8.5
                      c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5C62.8,1057,58.9,1053.2,54.2,1053.2z M20.1,1056c3.2,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7
                      s-5.7-2.5-5.7-5.7S17,1056,20.1,1056z M54.2,1056c3.2,0,5.7,2.5,5.7,5.7s-2.5,5.7-5.7,5.7c-3.2,0-5.7-2.5-5.7-5.7
                      S51.1,1056,54.2,1056z M37.2,1070.2c0.8,0,1.3,0.3,1.8,0.8c0.5,0.5,0.9,1.4,1.1,2.3l0.6,2.5h-6.9l0.6-2.5c0.2-1,0.6-1.8,1.1-2.3
                      C35.9,1070.5,36.4,1070.2,37.2,1070.2z"></path>
                   </g>
                </svg>
          `
				}
			],
			items2: [
				{
					title: 'AR Apps For Desktop',
					text: 'Using webcam and AR-media plugin, we can simulate AR experiences on desktops like large, powerful, and static devices. We have delivered educational, entertainment, and several gaming apps powered by AR technologies. Do you look for AR applications for your desktops or laptops based customers or users?',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <path class="st0" d="M72.8,5.9H47.4c-0.7,0-1.2,0.5-1.2,1.2v57.8c0,0.7,0.5,1.2,1.2,1.2h6.9c0.7,0,1.2-0.5,1.2-1.2s0.5-1.2,1.2-1.2
                   h6.9c0.7,0,1.2,0.5,1.2,1.2s0.5,1.2,1.2,1.2h6.9c0.7,0,1.2-0.5,1.2-1.2V7.1C74,6.4,73.5,5.9,72.8,5.9z M48.6,8.3h23.1v9.3H48.6V8.3z
                   M71.7,63.8h-4.9c-0.5-1.4-1.7-2.3-3.2-2.3h-6.9c-1.5,0-2.8,0.9-3.2,2.3h-4.9V51H52c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-3.5
                   V19.8h23.1v28.9h-3.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h3.5V63.8z M52,24.4c0-0.7,0.5-1.2,1.2-1.2h13.9c0.7,0,1.2,0.5,1.2,1.2
                   s-0.5,1.2-1.2,1.2H53.2C52.5,25.6,52,25.1,52,24.4z M67.1,31.4H53.2c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h13.9
                   c0.7,0,1.2,0.5,1.2,1.2S67.8,31.4,67.1,31.4z M62.4,58c0,0.7-0.5,1.2-1.2,1.2H59c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h2.3
                   C62,56.8,62.4,57.3,62.4,58z M60.1,45.3c-2.5,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S62.7,45.3,60.1,45.3z M60.1,52.2
                   c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S61.4,52.2,60.1,52.2z M42.8,17.5H3.5C1.5,17.5,0,19,0,21v32.4
                   c0,2,1.5,3.5,3.5,3.5H22v6.9h-4.6c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h25.4c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-4.6v-6.9
                   h4.6c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H3.5c-0.7,0-1.2-0.5-1.2-1.2V21c0-0.7,0.5-1.2,1.2-1.2h39.3c0.7,0,1.2-0.5,1.2-1.2
                   S43.5,17.5,42.8,17.5z M31.2,56.8v2.3h-2.3v-2.3H31.2z M26.6,56.8v3.5c0,0.7,0.5,1.2,1.2,1.2h4.6c0.7,0,1.2-0.5,1.2-1.2v-3.5h2.3
                   v6.9H24.3v-6.9H26.6z M42.8,24.4H6.9v25.4h35.8c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-37c-0.7,0-1.2-0.5-1.2-1.2V23.3
                   c0-0.7,0.5-1.2,1.2-1.2h37c0.7,0,1.2,0.5,1.2,1.2S43.5,24.4,42.8,24.4z M37,31.4H12.7c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2H37
                   c0.7,0,1.2,0.5,1.2,1.2S37.7,31.4,37,31.4z M31.2,33.7c0.7,0,1.2,0.5,1.2,1.2S31.9,36,31.2,36h-4.6c-0.7,0-1.2-0.5-1.2-1.2
                   s0.5-1.2,1.2-1.2H31.2z M22,36h-9.3c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2H22c0.7,0,1.2,0.5,1.2,1.2S22.7,36,22,36z M19.7,38.3
                   c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-6.9c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2H19.7z M69.4,11.7c0,0.7-0.5,1.2-1.2,1.2h-15
                   V14c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2v-2.3c0-0.7,0.5-1.2,1.2-1.2h16.2C68.9,10.6,69.4,11,69.4,11.7z"></path>
                </svg>
          `
				},
				{
					title: 'AR Game Apps',
					text: 'Our AR games allow gamers to interact with virtual characters and objects laid on real-world objects or scenario and participate in real-time to have immersive experiences as Pokémon Go has provided. It makes AR games addictive, engaging, and more entertaining to boost download and in-app purchases.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <g>
                      <g>
                         <path class="st0" d="M67.7,15.9H6.3c-3.4,0-6.1,2.7-6.1,6.1V54c0,3.4,2.7,6.1,6.1,6.1h61.3c3.4,0,6.1-2.7,6.1-6.1V22
                         C73.8,18.7,71.1,15.9,67.7,15.9z M71.4,54c0,2-1.6,3.7-3.7,3.7H6.3c-2,0-3.7-1.6-3.7-3.7V22c0-2,1.6-3.7,3.7-3.7h61.3
                         c2,0,3.7,1.6,3.7,3.7V54z"></path>
                         <path class="st0" d="M67.7,20.2H6.3c-1,0-1.8,0.8-1.8,1.8v4c0,0.8,0.5,1.5,1.3,1.7l1.2,0.4c0.8,0.3,1.3,1,1.3,1.8V46
                         c0,0.8-0.5,1.5-1.3,1.7l-1.2,0.4c-0.8,0.2-1.3,1-1.3,1.7v4c0,1,0.8,1.8,1.8,1.8h61.3c1,0,1.8-0.8,1.8-1.8V22
                         C69.5,21,68.7,20.2,67.7,20.2L67.7,20.2z M68.3,54c0,0.3-0.3,0.6-0.6,0.6H6.3c-0.3,0-0.6-0.3-0.6-0.6v-4c0-0.3,0.2-0.5,0.4-0.6
                         L7.3,49c1.3-0.4,2.1-1.6,2.1-2.9V30c0-1.3-0.8-2.5-2.1-2.9l-1.2-0.4c-0.3-0.1-0.4-0.3-0.4-0.6v-4c0-0.3,0.3-0.6,0.6-0.6h61.3
                         c0.3,0,0.6,0.3,0.6,0.6V54z"></path>
                         <path class="st0" d="M5.3,33.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.5-0.5,0.5-1.3,0-1.7
                         c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.5,0-0.7,0.1c-0.4,0.2-0.7,0.5-0.7,0.9
                         c-0.1,0.4,0,0.8,0.3,1.1c0.2,0.2,0.5,0.4,0.9,0.4C5.2,33.1,5.3,33.1,5.3,33.1z"></path>
                         <path class="st0" d="M12.5,25.1h14.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H12.5c-0.3,0-0.6,0.3-0.6,0.6S12.1,25.1,12.5,25.1z"></path>
                         <path class="st0" d="M46.8,25.1h14.7c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H46.8c-0.3,0-0.6,0.3-0.6,0.6S46.5,25.1,46.8,25.1z"></path>
                         <path class="st0" d="M40.1,38c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1C38.7,41.1,40.1,39.7,40.1,38L40.1,38z
                         M37,39.8c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8C38.8,39,38,39.8,37,39.8L37,39.8z"></path>
                         <path class="st0" d="M48,37.4h-1.9c-0.3-4.6-4-8.2-8.6-8.6V27c0-0.3-0.3-0.6-0.6-0.6s-0.6,0.3-0.6,0.6v1.9c-4.6,0.3-8.2,4-8.6,8.6
                         H26c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h1.9c0.3,4.6,4,8.2,8.6,8.6V49c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-1.9
                         c4.6-0.3,8.2-4,8.6-8.6H48c0.3,0,0.6-0.3,0.6-0.6S48.4,37.4,48,37.4z M37.6,45.9v-2.8l0.2,0.2c0.2,0.2,0.6,0.2,0.9,0
                         c0.2-0.2,0.2-0.6,0-0.9l-1.2-1.2c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0l0,0l0,0c-0.1,0-0.1,0.1-0.2,0.1
                         l-1.2,1.2c-0.2,0.2-0.2,0.4-0.2,0.6c0.1,0.2,0.2,0.4,0.4,0.4c0.2,0.1,0.4,0,0.6-0.2l0.2-0.2v2.8c-3.9-0.3-7-3.4-7.3-7.3h2.8
                         l-0.2,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0.1,0.2,0.2,0.4,0.4,0.4s0.4,0,0.6-0.2l1.2-1.2c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
                         c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2l-1.2-1.2c-0.2-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.2,0.6,0,0.9
                         l0.2,0.2h-2.8c0.3-3.9,3.4-7,7.3-7.3v2.8l-0.2-0.2c-0.2-0.2-0.6-0.2-0.9,0c-0.2,0.2-0.2,0.6,0,0.9l1.2,1.2
                         c0.2,0.2,0.4,0.2,0.7,0.1l0,0l0,0c0.1,0,0.1-0.1,0.2-0.1l1.2-1.2c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-0.2,0.2v-2.8
                         c3.9,0.3,7,3.4,7.3,7.3h-2.8l0.2-0.2c0.2-0.2,0.2-0.4,0.2-0.6c-0.1-0.2-0.2-0.4-0.4-0.4s-0.4,0-0.6,0.2l-1.2,1.2
                         c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2l1.2,1.2c0.2,0.2,0.4,0.2,0.6,0.2
                         c0.2-0.1,0.4-0.2,0.4-0.4s0-0.4-0.2-0.6l-0.2-0.2h2.8C44.6,42.5,41.5,45.6,37.6,45.9L37.6,45.9z"></path>
                         <path class="st0" d="M36.8,37.4c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.1,0.2,0.1
                         c0.1,0,0.2,0.1,0.2,0c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0-0.2c0-0.2-0.1-0.3-0.2-0.4
                         C37.3,37.4,37,37.3,36.8,37.4L36.8,37.4z"></path>
                         <path class="st0" d="M17.4,28.8h-4.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.9c0.3,0,0.6-0.3,0.6-0.6
                         C18,29.1,17.7,28.8,17.4,28.8z"></path>
                         <path class="st0" d="M17.4,31.3h-4.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.9c0.3,0,0.6-0.3,0.6-0.6
                         C18,31.5,17.7,31.3,17.4,31.3z"></path>
                         <path class="st0" d="M17.4,33.7h-4.9c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.9c0.3,0,0.6-0.3,0.6-0.6
                         C18,34,17.7,33.7,17.4,33.7z"></path>
                         <path class="st0" d="M14.9,46c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1S18,50.7,18,49C18,47.3,16.6,46,14.9,46z M14.9,50.9
                         c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8C16.8,50.1,15.9,50.9,14.9,50.9z"></path>
                         <path class="st0" d="M22.3,46c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1C25.3,47.3,24,46,22.3,46z M22.3,50.9
                         c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8C24.1,50.1,23.3,50.9,22.3,50.9z"></path>
                         <path class="st0" d="M63.4,27.6c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7C67.1,29.2,65.4,27.6,63.4,27.6
                         L63.4,27.6z M63.4,33.7c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C65.8,32.6,64.7,33.7,63.4,33.7z"></path>
                         <path class="st0" d="M63.4,36.2c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7C67.1,37.8,65.4,36.2,63.4,36.2
                         L63.4,36.2z M63.4,42.3c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C65.8,41.2,64.7,42.3,63.4,42.3z"></path>
                         <path class="st0" d="M63.4,44.7c-2,0-3.7,1.6-3.7,3.7s1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7C67.1,46.4,65.4,44.8,63.4,44.7
                         L63.4,44.7z M63.4,50.9c-1.4,0-2.5-1.1-2.5-2.5S62,46,63.4,46s2.5,1.1,2.5,2.5C65.8,49.8,64.7,50.9,63.4,50.9z"></path>
                      </g>
                   </g>
                </svg>
          `
				},
				{
					title: 'AR Support & Maintenance',
					text: 'We have allocated some AR experts for your AR applications to provide you quick and quality support round-the-clock address your queries and issues in real-time. Our AR maintenance team is fixing bugs, crashes, and errors on a regular basis to keep your AR apps running smoothly and high performing. Our updates and upgrade services are unbeatable in industry.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                   <path class="st0" d="M27,34.2c-0.6,0-1.1,0.5-1.1,1.1v3.7c0,0.6,0.5,1.1,1.1,1.1l2.8,0.1c0.1,0.3,0.2,0.6,0.4,0.8l-1.9,2.1
                   c-0.4,0.5-0.4,1.1,0,1.6l2.6,2.6c0.4,0.4,1.1,0.4,1.6,0l2.1-1.9c0.3,0.1,0.6,0.3,0.9,0.4l0.1,2.8c0,0.6,0.5,1.1,1.1,1.1h3.7
                   c0.6,0,1.1-0.5,1.1-1.1l0.1-2.8c0.3-0.1,0.6-0.2,0.9-0.4l2.1,1.9c0.5,0.4,1.1,0.4,1.6,0l2.6-2.6c0.4-0.4,0.4-1.1,0-1.6l-1.9-2.1
                   c0.1-0.3,0.3-0.6,0.4-0.9l2.8-0.1c0.6,0,1.1-0.5,1.1-1.1v-3.7c0-0.6-0.5-1.1-1.1-1.1L47.4,34c-0.1-0.3-0.2-0.6-0.4-0.9l1.9-2.1
                   c0.4-0.5,0.4-1.1,0-1.6l-2.6-2.6c-0.4-0.4-1.1-0.4-1.6,0l-2.1,1.9c-0.3-0.1-0.6-0.3-0.9-0.4l-0.1-2.8c0-0.6-0.5-1.1-1.1-1.1h-3.7
                   c-0.6,0-1.1,0.5-1.1,1.1l-0.1,2.8c-0.3,0.1-0.6,0.2-0.8,0.4l-2.1-1.9c-0.5-0.4-1.1-0.4-1.6,0l-2.6,2.6c-0.4,0.4-0.4,1.1,0,1.6
                   l1.9,2.1c-0.1,0.3-0.2,0.6-0.4,0.8L27,34.2z M32.4,32.3l-1.7-1.9l1.1-1.1l1.8,1.7c0.4,0.3,0.9,0.4,1.3,0.1c0.6-0.3,1.2-0.6,1.8-0.8
                   c0.5-0.1,0.8-0.5,0.8-1l0.1-2.5h1.6l0.1,2.5c0,0.5,0.4,0.9,0.9,1c0.6,0.2,1.3,0.4,1.8,0.8c0.4,0.3,1,0.2,1.3-0.1l1.8-1.7l1.1,1.1
                   l-1.7,1.8c-0.3,0.4-0.4,0.9-0.1,1.3c0.3,0.6,0.6,1.2,0.8,1.8c0.1,0.5,0.5,0.8,1,0.9l2.5,0.1V38l-2.5,0.1c-0.5,0-0.9,0.4-1,0.9
                   c-0.2,0.6-0.4,1.2-0.8,1.8c-0.3,0.4-0.2,1,0.1,1.3l1.7,1.8l-1.1,1.1l-1.8-1.7c-0.4-0.3-0.9-0.4-1.3-0.1c-0.6,0.3-1.2,0.6-1.8,0.8
                   c-0.5,0.1-0.8,0.5-0.9,1l-0.1,2.5h-1.6l-0.1-2.5c0-0.5-0.4-0.9-0.9-1c-0.6-0.2-1.2-0.4-1.8-0.8c-0.4-0.3-1-0.2-1.3,0.1l-1.8,1.7
                   L30.7,44l1.7-1.8c0.3-0.4,0.4-0.9,0.1-1.3c-0.3-0.6-0.6-1.2-0.8-1.8c-0.1-0.5-0.5-0.8-1-0.9L28.2,38v-1.6l2.5-0.1
                   c0.5,0,0.9-0.4,1-0.9c0.2-0.6,0.4-1.2,0.8-1.8C32.8,33.2,32.7,32.6,32.4,32.3z M38.6,42.3c2.8,0,5.1-2.3,5.1-5.1
                   c0-2.8-2.3-5.1-5.1-5.1c-2.8,0-5.1,2.3-5.1,5.1C33.5,40,35.8,42.3,38.6,42.3z M38.6,34.4c1.6,0,2.8,1.3,2.8,2.8
                   c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8C35.8,35.6,37.1,34.4,38.6,34.4z M57.2,17.8V6c0-3.1-2.6-5.7-5.7-5.7H24.3
                   c-3.1,0-5.7,2.6-5.7,5.7v6.8c0,0.6,0.5,1.1,1.1,1.1h35.2v41.6c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-29c0.9-0.2,1.5-0.9,1.5-1.9
                   v-5C58.7,18.8,58,18,57.2,17.8z M54.9,11.7H20.8V6c0-1.9,1.5-3.4,3.4-3.4h27.2c1.9,0,3.4,1.5,3.4,3.4V11.7z M37.9,70.4
                   c1.7,0,3.2-1.4,3.2-3.2c0-1.7-1.4-3.2-3.2-3.2c-1.7,0-3.2,1.4-3.2,3.2C34.7,69,36.1,70.4,37.9,70.4z M37.9,66.4
                   c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9S37,67.8,37,67.3C37,66.8,37.4,66.4,37.9,66.4z M56,60.4H20.8V18.9
                   c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1v49.5c0,3.1,2.6,5.7,5.7,5.7h27.2c3.1,0,5.7-2.6,5.7-5.7v-6.8
                   C57.2,61,56.7,60.4,56,60.4z M54.9,68.4c0,1.9-1.5,3.4-3.4,3.4H24.3c-1.9,0-3.4-1.5-3.4-3.4v-5.7h34.1V68.4z"></path>
                </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
