<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Type of Services</span>
				<h2 class="fxt-process-dev">Many Industries, Many Solutions</h2>
			</div>
			<VueSlickCarousel
				:pauseOnHover="false"
				ref="reviewsCarousel"
				:slidesToShow="1"
				:autoplay="true"
				@afterChange="setIndex"
				class="portfolio-slider px-2 fxt-vue-slick fxtt-vue-slick"
				style="overflow-x: hidden"
			>
				<IconImageInfo v-for="(item, index) in items" :key="index" :obj="item"></IconImageInfo>
			</VueSlickCarousel>
			<div style="margin:20px 0"></div>
			<VueSlickCarousel
				:pauseOnHover="false"
				:slidesToShow="5"
				:autoplay="true"
				class="portfolio-slider px-2 fxt-vue-slick"
				style="overflow-x: hidden"
			>
				<div v-for="(item, index) in items" :key="index" @click="setActiveIndex(index)">
					<IconTitleCard :obj="item"></IconTitleCard></div
			></VueSlickCarousel>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import IconTitleCard from '../../MobileDevelopment/AssetComponents/IconTitleCard.vue';
import IconImageInfo from '../../MobileDevelopment/AssetComponents/IconImageInfo.vue';
export default {
	components: {
		VueSlickCarousel,
		IconImageInfo,
		IconTitleCard
	},
	data() {
		return {
			index_: 0,
			items: [
				{
					svg: `
						<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<g>
											<path
												d="M22.5,4.2h1.7V2.5h-1.7V4.2z M25.8,4.2h1.7V2.5h-1.7V4.2z M27.5,45h-5v1.7h5V45z M40.7,5.2v-1c0-2.3-1.9-4.2-4.1-4.2
                                 H13.4c-2.3,0-4.1,1.9-4.1,4.2v1c-1,0.4-1.7,1.3-1.7,2.3v5c0,1.3,0.6,2.5,1.7,3.3v30c0,2.3,1.9,4.2,4.1,4.2h24
                                 c2.1,0,3.3-1.6,3.3-4.2v-30c1-0.8,1.7-2,1.7-3.3v-5C42.4,6.4,41.7,5.5,40.7,5.2z M10.9,4.2c0-1.4,1.1-2.5,2.5-2.5h23.2
                                 c1.4,0,2.5,1.1,2.5,2.5V5H10.9V4.2z M34.1,6.7v5.8c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V6.7H34.1z M27.5,6.7v5.8
                                 c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V6.7H27.5z M20.9,6.7v5.8c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V6.7H20.9z
                                 M9.3,12.5v-5c0-0.5,0.4-0.8,0.8-0.8h4.1v5.8c0,1.4-1.1,2.5-2.5,2.5C10.4,15,9.3,13.9,9.3,12.5z M39.1,45.8
                                 c0,2.5-1.2,2.5-1.7,2.5h-24c-1.4,0-2.5-1.1-2.5-2.5v-2.5h28.1V45.8z M39.1,41.7H10.9V16.6c1.6,0.3,3.2-0.3,4.1-1.6
                                 c0.8,1.1,2,1.7,3.3,1.7c1.3,0,2.5-0.6,3.3-1.7c0.8,1.1,2,1.7,3.3,1.7c1.3,0,2.5-0.6,3.3-1.7c0.8,1.1,2,1.7,3.3,1.7
                                 c1.3,0,2.5-0.6,3.3-1.7c0.9,1.3,2.6,1.9,4.1,1.6V41.7z M40.7,12.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V6.7h4.1
                                 c0.5,0,0.8,0.4,0.8,0.8V12.5z M34.9,25h-7.4v-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5V25h-7.4c-1.4,0-2.5,1.1-2.5,2.5
                                 c0,1.1,0.7,2.1,1.8,2.4l1.7,6.9c0.5,1.9,2.1,3.2,4,3.2h9.7c1.9,0,3.6-1.3,4-3.2l1.7-6.9c0.4-0.1,0.8-0.3,1.1-0.6
                                 c0.5-0.5,0.7-1.1,0.7-1.8C37.4,26.1,36.3,25,34.9,25z M24.2,22.5c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v6.7
                                 c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V22.5z M15.1,28.3c-0.5,0-0.8-0.4-0.8-0.8c0-0.2,0.1-0.4,0.3-0.6
                                 c0.2-0.2,0.4-0.2,0.6-0.2h7.4v1.7H15.1z M32.3,36.4c-0.3,1.1-1.3,1.9-2.4,1.9h-9.7c-1.1,0-2.1-0.8-2.4-1.9L16.1,30h6.5
                                 c0.3,1,1.3,1.7,2.3,1.7c1,0,2-0.7,2.3-1.7h6.5L32.3,36.4z M35.5,28.1c-0.2,0.2-0.4,0.2-0.6,0.2h-7.4v-1.7h7.4
                                 c0.5,0,0.8,0.4,0.8,0.8C35.8,27.7,35.7,27.9,35.5,28.1z M25,32.5c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8
                                 c0.5,0,0.8-0.4,0.8-0.8v-3.3C25.8,32.9,25.5,32.5,25,32.5z M28.9,37.5c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.3,0.8-0.6l0.8-3.3
                                 c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.5,0.3-0.6,0.6l-0.8,3.3C28.2,36.9,28.5,37.4,28.9,37.5z M20.8,33.1
                                 c-0.1-0.3-0.3-0.5-0.6-0.6c-0.3-0.1-0.6,0-0.8,0.2c-0.2,0.2-0.3,0.5-0.2,0.8l0.8,3.3c0.1,0.4,0.4,0.6,0.8,0.6c0.1,0,0.1,0,0.2,0
                                 c0.4-0.1,0.7-0.6,0.6-1L20.8,33.1z"
											></path>
										</g>
									</g>
								</g>
							</svg>
					`,
					title: 'eCommerce',
					imageLink: '/ecomm-app.jpg',
					descriptions: [
						'Increases your online sales, boost customer retention and elevate customer service with chatbots for eCommerce. Chatbots can offer related product recommendations that will lead to higher upsell and cross-selling opportunities.'
					]
				},
				{
					svg: `
						<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M44.9,21.2h-6.8v5.1h6.8V21.2z M43.2,24.6h-3.4v-1.7h3.4V24.6z M44.9,28h-6.8v5.1h6.8V28z M43.2,31.4h-3.4v-1.7h3.4V31.4z
                              M44.9,34.8h-6.8v5.1h6.8V34.8z M43.2,38.1h-3.4v-1.7h3.4V38.1z M44.9,41.5h-6.8v5.1h6.8V41.5z M43.2,44.9h-3.4v-1.7h3.4V44.9z
                              M22,20.3H28v-5.1h5.1V9.3H28V4.2H22v5.1h-5.1v5.9H22V20.3z M18.6,13.6V11h5.1V5.9h2.5V11h5.1v2.5h-5.1v5.1h-2.5v-5.1H18.6z
                              M48.3,17.8H36.4V0H13.6v17.8H1.7l0,32.2h46.6L48.3,17.8z M13.6,48.3H3.4V19.5h10.2V48.3z M20.3,48.3V33.1h4.2l0,15.3L20.3,48.3z
                              M30.5,48.3l-4.3,0l0-15.3h4.2V48.3z M34.7,48.3l-2.5,0v-17H18.6v17l-3.4,0l0-46.6h19.5L34.7,48.3z M46.6,48.3H36.4V19.5h10.2
                              V48.3z M11.9,21.2H5.1v5.1h6.8V21.2z M10.2,24.6H6.8v-1.7h3.4V24.6z M11.9,28H5.1v5.1h6.8V28z M10.2,31.4H6.8v-1.7h3.4V31.4z
                              M11.9,34.8H5.1v5.1h6.8V34.8z M10.2,38.1H6.8v-1.7h3.4V38.1z M11.9,41.5H5.1v5.1h6.8V41.5z M10.2,44.9H6.8v-1.7h3.4V44.9z"
										></path>
									</g>
								</g>
							</svg>
					`,
					title: 'Hospitals',
					imageLink: '/hospital-app.jpg',
					descriptions: [
						'Time is of essence for hospitals and healthcare giving institutions. We can help you build a chatbot that will save time and effort for your patients and doctors in scheduling appointments, following up on previous appointments, making critical care instantly available and so on.'
					]
				},
				{
					svg: `
						<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M0.8,21.8h4.5c0.3,1,0.6,1.9,1.1,2.7l-3.2,3.2C3.1,27.8,3,28,3,28.2c0,0.2,0.1,0.4,0.2,0.6l3.4,3.4c0.3,0.3,0.8,0.3,1.1,0
                              l3.2-3.2c0.9,0.5,1.8,0.9,2.7,1.1v4.5c0,0.4,0.4,0.8,0.8,0.8h4.8c0.4,0,0.8-0.4,0.8-0.8v-4.5c1-0.3,1.9-0.6,2.7-1.1l3.2,3.2
                              c0.3,0.3,0.8,0.3,1.1,0l3.4-3.4c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6l-3.2-3.2c0.5-0.9,0.9-1.8,1.1-2.7h4.5
                              c0.4,0,0.8-0.4,0.8-0.8v-4.8c0-0.4-0.4-0.8-0.8-0.8h-4.5c-0.3-1-0.6-1.9-1.1-2.7l3.2-3.2c0.2-0.2,0.2-0.4,0.2-0.6
                              c0-0.2-0.1-0.4-0.2-0.6l-3.4-3.4c-0.3-0.3-0.8-0.3-1.1,0L22.9,8c-0.9-0.5-1.8-0.9-2.7-1.1V2.4c0-0.4-0.4-0.8-0.8-0.8h-4.8
                              c-0.4,0-0.8,0.4-0.8,0.8v4.5c-1,0.3-1.9,0.6-2.7,1.1L7.8,4.9C7.5,4.6,7,4.6,6.7,4.9L3.2,8.3C3.1,8.4,3,8.6,3,8.9
                              c0,0.2,0.1,0.4,0.2,0.6l3.2,3.2c-0.5,0.9-0.9,1.8-1.1,2.7H0.8c-0.4,0-0.8,0.4-0.8,0.8V21C0,21.4,0.4,21.8,0.8,21.8z M1.6,16.9h4.3
                              c0.4,0,0.7-0.3,0.8-0.6c0.3-1.2,0.7-2.4,1.4-3.4c0.2-0.3,0.2-0.7-0.1-1l-3-3l2.3-2.3l3,3c0.3,0.3,0.7,0.3,1,0.1
                              c1-0.7,2.2-1.1,3.4-1.4c0.4-0.1,0.6-0.4,0.6-0.8V3.2h3.2v4.3c0,0.4,0.3,0.7,0.6,0.8c1.2,0.3,2.4,0.7,3.4,1.4
                              c0.3,0.2,0.7,0.2,1-0.1l3-3l2.3,2.3l-3,3c-0.3,0.3-0.3,0.7-0.1,1c0.7,1,1.1,2.2,1.4,3.4c0.1,0.4,0.4,0.6,0.8,0.6h4.3v3.2H28
                              c-0.4,0-0.7,0.3-0.8,0.6c-0.3,1.2-0.7,2.4-1.4,3.4c-0.2,0.3-0.2,0.7,0.1,1l3,3l-2.3,2.3l-3-3c-0.3-0.3-0.7-0.3-1-0.1
                              c-1,0.7-2.2,1.1-3.4,1.4c-0.4,0.1-0.6,0.4-0.6,0.8v4.3h-3.2v-4.3c0-0.4-0.3-0.7-0.6-0.8c-1.2-0.3-2.4-0.7-3.4-1.4
                              c-0.3-0.2-0.7-0.2-1,0.1l-3,3L5,28.2l3-3c0.3-0.3,0.3-0.7,0.1-1c-0.7-1-1.1-2.2-1.4-3.4c-0.1-0.4-0.4-0.6-0.8-0.6H1.6V16.9z
                              M16.9,25c3.6,0,6.5-2.9,6.5-6.5c0-3.6-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5C10.5,22.1,13.4,25,16.9,25z M16.9,13.7
                              c2.7,0,4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8C12.1,15.9,14.3,13.7,16.9,13.7z M16.9,27.4
                              c4.9,0,8.9-4,8.9-8.9c0-4.9-4-8.9-8.9-8.9c-4.9,0-8.9,4-8.9,8.9C8.1,23.4,12,27.4,16.9,27.4z M16.9,11.3c4,0,7.3,3.2,7.3,7.3
                              c0,4-3.2,7.3-7.3,7.3c-4,0-7.3-3.2-7.3-7.3C9.7,14.5,12.9,11.3,16.9,11.3z M46,38.7v-6.5c0-0.4-0.4-0.8-0.8-0.8h-1.6V25
                              c0-0.4-0.4-0.8-0.8-0.8h-7.3c-0.4,0-0.8,0.4-0.8,0.8v6.5h-4c-0.4,0-0.8,0.4-0.8,0.8v6.5H4c-2.2,0-4,1.8-4,4v1.6c0,2.2,1.8,4,4,4
                              H46c2.2,0,4-1.8,4-4v-1.6C50,40.5,48.2,38.7,46,38.7z M44.4,33.1v5.6h-5.6v-5.6H44.4z M36.3,25.8h5.6v5.6h-5.6V25.8z M31.5,33.1
                              h5.6v5.6h-5.6V33.1z M48.4,44.4c0,1.3-1.1,2.4-2.4,2.4H4c-1.3,0-2.4-1.1-2.4-2.4v-1.6c0-1.3,1.1-2.4,2.4-2.4H46
                              c1.3,0,2.4,1.1,2.4,2.4V44.4z M25,41.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
                              C27.4,42.2,26.3,41.1,25,41.1z M25,44.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                              C25.8,44,25.4,44.4,25,44.4z M16.9,41.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
                              C19.4,42.2,18.3,41.1,16.9,41.1z M16.9,44.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                              C17.7,44,17.4,44.4,16.9,44.4z M33.1,41.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
                              C35.5,42.2,34.4,41.1,33.1,41.1z M33.1,44.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                              C33.9,44,33.5,44.4,33.1,44.4z M8.9,41.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
                              C11.3,42.2,10.2,41.1,8.9,41.1z M8.9,44.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                              C9.7,44,9.3,44.4,8.9,44.4z M41.1,41.1c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4
                              C43.5,42.2,42.5,41.1,41.1,41.1z M41.1,44.4c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8
                              C41.9,44,41.6,44.4,41.1,44.4z"
										></path>
									</g>
								</g>
							</svg>
					`,
					title: 'Manufacturing',
					imageLink: '/manufacturing-app.jpg',
					descriptions: [
						'Manufacturing is one domain where timely communication can make or break the bottom lines. Quite often excess inventory gets piled up because one department is unable to inform the other about placing orders or cancelling orders. With a chatbot system, managers can easily monitor the queue of requirements and ensure optimized order fulfillment.'
					]
				},
				{
					svg: `
						<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M10.5,7.3c0.9,0,1.6,0.7,1.6,1.6h1.6c0-1.5-1-2.8-2.4-3.1V4H9.7v1.7C8.3,6.1,7.3,7.4,7.3,8.9c0,1.8,1.4,3.2,3.2,3.2
                              c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6H7.3c0,1.5,1,2.8,2.4,3.1v1.7h1.6v-1.7c1.4-0.4,2.4-1.6,2.4-3.1
                              c0-1.8-1.4-3.2-3.2-3.2c-0.9,0-1.6-0.7-1.6-1.6C8.9,8,9.6,7.3,10.5,7.3z M0,50h14.4V39H0V50z M1.6,40.5h11.2v1.6H1.6V40.5z
                              M1.6,43.7h11.2v1.6H1.6V43.7z M1.6,46.8h11.2v1.6H1.6V46.8z M16.1,50h14.4V32.2H16.1V50z M17.7,33.8h11.2v1.6H17.7V33.8z
                              M17.7,37.1h11.2v1.6H17.7V37.1z M17.7,40.3h11.2v1.6H17.7V40.3z M17.7,46.8h1.6v-1.6h-1.6v-1.6h11.2v1.6h-8v1.6h8v1.6H17.7V46.8z
                              M32.2,50h14.4V25.4H32.2V50z M33.8,27.1H45v1.6H33.8V27.1z M33.8,33.6h8V32h-8v-1.6H45V32h-1.6v1.6H45v1.6H33.8V33.6z M33.8,36.9
                              H45v1.6H33.8V36.9z M33.8,40.2H45v1.6H33.8V40.2z M33.8,43.4H45v1.6H33.8V43.4z M33.8,46.7H45v1.6H33.8V46.7z M37.9,0
                              c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h3.7L29.7,16.7l-6.4-4l-3.1,2.5c0.5-1.2,0.8-2.5,0.8-3.9c0-5.8-4.7-10.5-10.5-10.5
                              C4.7,0.8,0,5.5,0,11.3c0,5.8,4.7,10.5,10.5,10.5c0.5,0,1,0,1.5-0.1L0.3,30.8l1,1.3l22.1-17.4l6.5,4.1L45.5,3.2h-7.6
                              c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h10.5v10.5c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8V5.3L30.5,21.6l-7.2-4L2.7,34
                              l1,1.3l19.7-15.8l7.3,4.1L45.2,9.2v2.9c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4V0H37.9z M10.5,20.2c-4.9,0-8.9-4-8.9-8.9
                              c0-4.9,4-8.9,8.9-8.9c4.9,0,8.9,4,8.9,8.9C19.4,16.2,15.4,20.2,10.5,20.2z M3.2,12.1h1.6v-1.6H3.2V12.1z M16.1,12.1h1.6v-1.6h-1.6
                              V12.1z"
														></path>
													</g>
												</g>
											</svg>
					`,
					title: 'Finance',
					imageLink: '/finance-app.jpg',
					descriptions: [
						'Millions of customers. Billions of conversations. The BFSI industry is a perfect place to deploy a chatbot to automate conversations. be it for customer support or real-time information retrieval, there are umpteen number of use cases for a chatbot in BFSI.'
					]
				},
				{
					svg: `
					<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M38.5,37.9c-0.6-2-2.3-3.4-4.3-3.8l-5.3-1v-0.4c0.9-0.5,1.7-1.3,2.4-2.1h1.6c1.3,0,2.4-1.1,2.4-2.4v-9.7
                              c0-5.8-4.6-10.5-10.3-10.5c-5.7,0-10.3,4.7-10.3,10.5v5.6c0,1.6,1.1,2.9,2.5,3.2c0.4,2.3,1.9,4.3,3.8,5.4v0.4l-5.3,1
                              c-2,0.4-3.6,1.8-4.3,3.8l-0.3,1l-2.7-2.7C8,35.9,8,35.3,8.4,35l3.1-3.2l-5.9-6l-3.5,3.6c-1.1,1.1-1.6,2.5-1.6,4
                              c0,1.5,0.6,2.9,1.6,4l10.7,10.9c1.1,1.1,2.4,1.7,3.9,1.7c1.5,0,2.9-0.6,3.9-1.6l3.9-3.6l-1.2-1.2h17.1L38.5,37.9z M33.7,28.2
                              c0,0.4-0.4,0.8-0.8,0.8h-0.7c0.2-0.5,0.4-1.1,0.5-1.7c0.3-0.1,0.7-0.2,0.9-0.4V28.2z M32.9,25.6v-2.8c0.5,0.3,0.8,0.8,0.8,1.4
                              C33.7,24.8,33.4,25.3,32.9,25.6z M17,25.6c-0.5-0.3-0.8-0.8-0.8-1.4c0-0.6,0.3-1.1,0.8-1.4V25.6z M17,20.2v0.9
                              c-0.3,0.1-0.5,0.2-0.8,0.3v-2.9c0-4.9,3.9-8.9,8.7-8.9c4.8,0,8.7,4,8.7,8.9v2.9c-0.2-0.1-0.5-0.3-0.8-0.3v-0.9h-0.8
                              c-2.3,0-4.6-0.9-6.2-2.6l-0.9-0.9L24,17.5c-1.7,1.7-3.9,2.6-6.2,2.6H17z M18.6,25.8v-4.1c2.4-0.2,4.6-1.2,6.4-2.9
                              c1.7,1.7,4,2.7,6.4,2.9v4.1c0,1.2-0.3,2.3-0.9,3.2h-5.5v1.6h4.2c-1.1,1-2.6,1.6-4.2,1.6C21.4,32.3,18.6,29.4,18.6,25.8z
                              M27.3,33.5L27.3,33.5L24.9,36l-2.4-2.4v0c0.8,0.2,1.6,0.4,2.4,0.4C25.8,33.9,26.6,33.7,27.3,33.5z M5.6,28.1l3.6,3.7l-0.9,0.9
                              L4.7,29L5.6,28.1z M19.5,47.2c-0.8,0.8-1.7,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2L3.2,36.3C2.4,35.5,2,34.5,2,33.5
                              c0-1.1,0.4-2.1,1.2-2.9l0.4-0.4l3.6,3.7l0,0c-0.9,0.9-0.9,2.5,0,3.4l5.7,5.8c0.5,0.5,1,0.7,1.7,0.7s1.2-0.3,1.7-0.7l0.1-0.1
                              l3.7,3.7L19.5,47.2z M21.2,45.7L17.5,42l1-1l3.6,3.7L21.2,45.7z M21.7,41.9l-3.1-3.1L15.2,42c-0.3,0.3-0.8,0.3-1.1,0l-1.7-1.8
                              l0.6-1.7c0.5-1.4,1.6-2.4,3.1-2.7l5.6-1l3.4,3.5l3.4-3.5l5.6,1c1.4,0.3,2.6,1.3,3.1,2.7l1.1,3.5H21.7z M47.2,0H36.1
                              c-1.3,0-2.4,1.1-2.4,2.4v6.5c0,1.3,1.1,2.4,2.4,2.4h2.1l-0.8,4.9l7.7-4.9h2.2c1.3,0,2.4-1.1,2.4-2.4V2.4C49.6,1.1,48.5,0,47.2,0z
                              M48,8.9c0,0.4-0.4,0.8-0.8,0.8h-2.6l-5,3.2l0.5-3.2h-4c-0.4,0-0.8-0.4-0.8-0.8V2.4c0-0.4,0.4-0.8,0.8-0.8h11.1
                              C47.6,1.6,48,2,48,2.4V8.9z M36.9,4.8h9.5V3.2h-9.5V4.8z M36.9,8.1h6.4V6.5h-6.4V8.1z M44.8,8.1h1.6V6.5h-1.6V8.1z M7.9,20
                              l-0.8-1.4L4.9,20C7.2,10.7,15.4,4,24.9,4C27,4,29.1,4.3,31.1,5l0.5-1.5c-2.1-0.7-4.3-1-6.6-1C14.6,2.4,5.6,9.7,3.3,19.9l-1.5-2.5
                              l-1.4,0.8l2.8,4.7L7.9,20z M41.7,19.7l1.4,0.8l1.4-2.4c0.7,2.2,1.1,4.4,1.1,6.8c0,4.8-1.6,9.5-4.6,13.2l1.2,1c3.2-4,5-9.1,5-14.2
                              c0-2.5-0.4-4.9-1.2-7.2l2.3,1.4l0.8-1.4L44.5,15L41.7,19.7z"
														></path>
													</g>
												</g>
											</svg>
					`,
					title: 'Customer support',
					imageLink: '/customer-support-app.jpg',
					descriptions: [
						'Your customer support agents need breaks and have capped working hours. But, support calls can come in at any point in time, day or night or even at midnight. A customer support chatbot can step in as a round the clock support system that can attend to your customers calls without any limitations.'
					]
				},
				{
					svg: `
						<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M34.1,18.2L34.1,18.2l2.4,1.1c0.2,0.1,0.3,0.1,0.5,0l2.4-1.1c1.2-0.5,2.3-1.4,2.9-2.6c0.6-1,0.9-2.2,0.9-3.3V3.7
                              c0-0.4-0.4-0.7-0.8-0.6c-1.7,0.4-3.5,0-5-0.9L37.1,2c0,0,0,0,0,0l-0.1,0c-0.1,0-0.3-0.1-0.4,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
                              l-0.4,0.2c-1.5,0.9-3.3,1.2-5.1,0.9c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0.1,0,0.1v8.6
                              c0,1.2,0.3,2.3,0.9,3.3C31.8,16.8,32.8,17.7,34.1,18.2z M37.4,3.6l0.3,0.1c1.3,0.6,2.7,0.8,4.1,0.7l0.2,0v7.8
                              c0,0.9-0.3,1.8-0.7,2.6c-0.5,0.9-1.4,1.7-2.3,2.1l-1.5,0.7V3.6z M31.5,4.5l0.2,0c1.4,0.1,2.8-0.1,4.1-0.7l0.3-0.1v14L34.6,17
                              c-1-0.4-1.8-1.2-2.4-2.1c-0.5-0.8-0.7-1.7-0.7-2.6V4.5z M9.1,14c0.2,0,0.3-0.1,0.4-0.2C13.4,9.2,19,6.6,25,6.6h0
                              c0.4,0,0.6-0.3,0.6-0.7c0-0.4-0.3-0.7-0.6-0.7h0c-6.3,0-12.3,2.8-16.4,7.7c-0.2,0.3-0.2,0.7,0.1,0.9C8.8,14,8.9,14,9.1,14z
                              M49.3,34.4L49.3,34.4l-5.6-5.7c-0.3-0.3-0.6-0.6-1-0.8l-0.2-0.1l0.2-0.2c1.7-1.4,1.9-4,0.5-5.7c-0.7-0.8-1.6-1.3-2.7-1.4
                              c-1.1-0.1-2.1,0.2-2.9,0.9c-1.7,1.4-1.9,4-0.5,5.7c0.2,0.2,0.4,0.4,0.6,0.6l0.2,0.2l-0.2,0.2c-0.3,0.2-0.6,0.4-0.8,0.6l-1.5,1.5
                              c-0.3,0.3-0.7,0.4-1.1,0.4c0,0,0,0,0,0c-0.4,0-0.8-0.2-1.1-0.5l-5.6-5.7c-0.2-0.2-0.5-0.4-0.7-0.6l-0.2-0.2l0.2-0.2
                              c2.2-1.5,2.8-4.5,1.4-6.8c-0.7-1.1-1.8-1.8-3.1-2.1c-1.3-0.3-2.6,0-3.6,0.7c-2.2,1.5-2.8,4.5-1.4,6.8c0.4,0.6,0.8,1,1.4,1.4
                              l0.2,0.2l-0.2,0.2c-0.3,0.2-0.5,0.4-0.7,0.6L16.3,29l-3.9-2.8c-0.2-0.2-0.5-0.4-0.7-0.6l-0.2-0.2l0.2-0.2c0.3-0.2,0.6-0.5,0.9-0.8
                              l0.1-0.1h2.2c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.2-0.1-0.3l-1.9-3.8c-0.2-0.5-0.4-0.9-0.6-1.3
                              c0-0.1-0.1-0.2-0.2-0.2c-0.8-1-2-1.7-3.2-1.8c-1.3-0.2-2.5,0.2-3.5,1c-0.3,0.3-0.6,0.6-0.9,0.9c0,0-0.1,0.1-0.1,0.1
                              c-0.3,0.4-0.5,0.8-0.6,1.3l-1.9,3.8c-0.2,0.3,0,0.7,0.3,0.9c0.1,0,0.2,0.1,0.3,0.1h2.2l0.1,0.1C5.7,24.8,6,25,6.3,25.3l0.2,0.2
                              l-0.2,0.2c-0.3,0.2-0.5,0.4-0.7,0.6l-4.9,5c-0.9,1-0.9,2.5,0,3.5c0.5,0.5,1.1,0.7,1.7,0.7c0.6,0,1.1-0.2,1.6-0.6l0.5-0.4l-1.9,7.8
                              C2.4,42.6,2.7,42.9,3,43c0.1,0,0.1,0,0.2,0H5v2.7c0,1.3,1,2.3,2.3,2.3c0.6,0,1.1-0.2,1.6-0.6L9,47.4l0.1,0.1
                              c0.5,0.4,1.1,0.6,1.7,0.6c0.6,0,1.2-0.3,1.6-0.8c0.4-0.4,0.6-1,0.6-1.6V43h1.9c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5
                              c0-0.1,0-0.1,0-0.2l-2.2-8.9l1.5,0.8c0.5,0.2,1,0.4,1.5,0.4c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.7,0,1.3-0.3,1.7-0.7l1.7-1.8
                              v13.7c0,0.6,0.2,1.2,0.7,1.7c0.4,0.4,1,0.7,1.6,0.7h0c0.6,0,1.1-0.2,1.6-0.6l0.1-0.1l0.1,0.1c0.5,0.4,1.1,0.6,1.7,0.6
                              c0.6,0,1.2-0.3,1.6-0.8c0.4-0.4,0.6-1,0.6-1.6V32.1l3,3.1c1.2,1.3,3,1.7,4.7,1l0.3-0.1v9.8c0,1.3,1,2.3,2.3,2.3h0
                              c0.6,0,1.1-0.2,1.6-0.6l0.1-0.1l0.1,0.1c0.5,0.4,1.1,0.6,1.7,0.6c0.6,0,1.2-0.3,1.6-0.8c0.4-0.4,0.6-1,0.6-1.6v-9.5l1.7,1.7
                              c0.5,0.5,1.1,0.7,1.7,0.7c0,0,0,0,0,0c0.6,0,1.3-0.3,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.7C50,35.5,49.7,34.9,49.3,34.4z M37.4,24.6
                              c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7s-1.2,2.7-2.7,2.7C38.6,27.3,37.4,26.1,37.4,24.6z M20.6,19.5c0-2,1.6-3.6,3.6-3.6
                              c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6C22.2,23.1,20.6,21.5,20.6,19.5z M6.2,23.4c-0.1-0.2-0.3-0.3-0.5-0.3H4.2l1.4-2.9
                              c0,0,0-0.1,0-0.1c0.1-0.4,0.3-0.7,0.5-1l0-0.1c0.6-0.8,1.4-1.3,2.3-1.4c0.9-0.1,1.9,0.1,2.6,0.7c0.3,0.2,0.5,0.5,0.7,0.7l0,0
                              c0.2,0.3,0.4,0.7,0.5,1c0,0,0,0.1,0,0.1l1.4,2.9h-1.5c-0.2,0-0.4,0.1-0.5,0.3c-0.6,0.8-1.5,1.3-2.6,1.4c-0.2,0-0.4,0-0.5,0
                              C7.8,24.7,6.8,24.2,6.2,23.4z M48.4,37c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.4l-2.8-2.7c-0.1-0.1-0.3-0.2-0.4-0.2h0
                              c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v11c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-6.4c0-0.4-0.3-0.7-0.6-0.7
                              c-0.4,0-0.6,0.3-0.6,0.7v6.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-11c0-0.4-0.3-0.7-0.6-0.7c-0.2,0-0.3,0.1-0.4,0.2
                              c-0.6,0.6-1.3,0.8-2.1,0.9c-0.8,0-1.5-0.3-2.1-0.9l0.1-0.2l-0.1,0.1L28,30c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2
                              c-0.1,0.1-0.2,0.3-0.2,0.5v15.3c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1V35.6c0-0.4-0.3-0.7-0.6-0.7c-0.4,0-0.6,0.3-0.6,0.7v10.2
                              c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1V30.5c0-0.4-0.3-0.7-0.6-0.7c-0.2,0-0.3,0.1-0.5,0.2l-2.8,2.9c-0.2,0.2-0.5,0.3-0.7,0.3
                              c0,0-0.1,0-0.1,0l-0.1,0c-0.1,0-0.1,0-0.1,0l-0.1,0c-0.3,0-0.6-0.1-0.9-0.2l-2.8-1.5c-0.2-0.1-0.3-0.1-0.5,0
                              c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5l2.3,9.4h-1.7c-0.4,0-0.6,0.3-0.6,0.7v3.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1
                              v-3.4c0-0.4-0.3-0.7-0.6-0.7c-0.4,0-0.6,0.3-0.6,0.7v3.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-3.4c0-0.4-0.3-0.7-0.6-0.7H4l2.3-9.4
                              c0.1-0.3-0.1-0.7-0.5-0.8c-0.2-0.1-0.5,0-0.6,0.2l-2,2.1C3,34,2.7,34.1,2.4,34.1c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.8-0.3
                              c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.6,0.3-0.8l4.9-5c0.6-0.6,1.3-0.9,2.1-1l0,0l0.1,0c0.2,0,0.4,0,0.6,0l0.1,0
                              c0.8,0.1,1.5,0.4,2.1,1c0,0,0.1,0.1,0.1,0.1l4.4,3.2c0.3,0.2,0.6,0.2,0.8-0.1l4.8-4.8c0.7-0.7,1.6-1.1,2.5-1.1
                              c0.9,0,1.8,0.4,2.5,1.1l5.6,5.7c1.1,1.1,2.8,1.1,3.9,0.1l1.5-1.5c1.4-1.4,3.6-1.4,5,0l5.6,5.7C48.8,35.8,48.8,36.5,48.4,37z
                              M46.2,18.9c1.1,2.6,1.7,5.4,1.7,8.2c0,0.4,0.3,0.7,0.6,0.7c0.4,0,0.6-0.3,0.6-0.7c0-3-0.6-6-1.8-8.8c-0.1-0.2-0.2-0.3-0.3-0.4
                              c-0.2-0.1-0.3-0.1-0.5,0C46.2,18.2,46,18.5,46.2,18.9L46,19L46.2,18.9z"
														></path>
													</g>
												</g>
											</svg>
					`,
					title: 'Insurance',
					imageLink: '/insurance-app.jpg',
					descriptions: [
						'In the insurance sector, selling is difficult, retaining is even difficult. A chatbot can interact with your customers to help them choose the right insurance product, get receipts for premiums paid, lodge requests for claim settlement, monitor claim settlement progress and much more.'
					]
				},
				{
					svg: `
						<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M22.3,21.9H21v1.3h1.3V21.9z M21,26.5h13v-1.3H21V26.5z M9.7,26.5v-1.7h1.9c0.3,0,0.6-0.3,0.6-0.6v-3.4
                              c0-0.3-0.3-0.6-0.6-0.6H7.1v-2.1h5v-1.3H9.7v-1.7H8.4v1.7H6.5c-0.3,0-0.6,0.3-0.6,0.6v3.4c0,0.3,0.3,0.6,0.6,0.6h4.4v2.1h-5v1.3
                              h2.5v1.7H9.7z M24.4,23.1h12.2v-1.3H24.4V23.1z M37.6,5H21.9v1.3h3.5l2.2,9l-0.2,0.1c-1,0.5-1.6,1.6-1.3,2.7
                              c0.3,1.1,1.4,1.8,2.5,1.7c1.1-0.2,2-1.1,2-2.3c0-0.3-0.1-0.5-0.1-0.8l-0.1-0.3H33l-0.1,0.3c-0.1,0.2-0.1,0.5-0.1,0.8
                              c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3h-6.2L28.3,13h7.6c0.3,0,0.5-0.2,0.6-0.5l1.7-6.7
                              c0-0.2,0-0.4-0.1-0.5C38,5.1,37.8,5,37.6,5z M35.1,16.4c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1
                              C34,16.9,34.5,16.4,35.1,16.4z M28.4,16.4c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1
                              C27.3,16.9,27.8,16.4,28.4,16.4z M35.4,11.8H28l-0.5-2.1h5.7V8.4h-6l-0.5-2.1h10.2L35.4,11.8z M49.4,24.8c0.3,0,0.6-0.3,0.6-0.6V4
                              c0-2.2-1.8-4-4-4H20.8c-2.2,0-4,1.8-4,4v12.2l-0.4-0.6c-2.1-3-5.7-4.4-9.2-3.7c-3.5,0.7-6.3,3.5-7,7c-0.7,3.5,0.7,7.2,3.7,9.2
                              l0.6,0.4H0.6c-0.3,0-0.6,0.3-0.6,0.6v3.4c0,0.3,0.3,0.6,0.6,0.6h1.2l3.3,16.3C5.1,49.8,5.4,50,5.7,50h38.7c0.3,0,0.6-0.2,0.6-0.5
                              l3.3-16.3h1.2c0.3,0,0.6-0.3,0.6-0.6v-3.4c0-0.3-0.3-0.6-0.6-0.6h-6.1v-3.8H49.4z M43.3,4c0-1.5,1.2-2.7,2.7-2.7
                              c1.5,0,2.7,1.2,2.7,2.7v19.5h-5.5V4z M18.1,4c0-1.5,1.2-2.7,2.7-2.7h22.3l-0.3,0.3C42.3,2.3,42,3.1,42,4v24.6h-24V4z M1.3,20.8
                              L1.3,20.8C1.3,16.5,4.8,13,9,13s7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8C4.8,28.6,1.3,25.1,1.3,20.8z M16.8,25.4v3.2h-3.2l0.6-0.4
                              c0.9-0.6,1.6-1.4,2.2-2.2L16.8,25.4z M26.1,48.7H13.9v-2.1h12.2V48.7z M18.5,45.4l4.3-3.2l0,0.5c0,0.1,0,0.1,0,0.2
                              c0,0.2,0.2,0.3,0.3,0.4l4.1,2.2H18.5z M16.7,45.2L15,43.6l9.1-6.8l1.6,1.6L16.7,45.2z M27.3,48.7V46c0,0,0-0.1,0-0.1l-0.2-0.5
                              l6.3,3.4H27.3z M35.7,48.5l-11.5-6.1l1-1.5l11.5,6.1L35.7,48.5z M43.8,48.7h-6.7l1-1.5c0.1-0.1,0.1-0.3,0.1-0.5
                              c0-0.2-0.2-0.3-0.3-0.4l-12.1-6.4l1.2-0.9c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5l-2.5-2.5c-0.2-0.2-0.6-0.2-0.8-0.1
                              L13.7,43c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5l1.5,1.5h-1.8c-0.3,0-0.6,0.3-0.6,0.6v2.7H6.2l-1.8-8.8h4.4v-1.3H4.2
                              l-0.4-2.1h10.1v-1.3H3.5l-0.4-2.1h43.8L43.8,48.7z M48.7,29.8v2.1H1.3v-2.1H48.7z M15.5,0.8h-1.3v1.3h1.3V0.8z M11.8,9.7H13V8h1.7
                              V6.7H13V5h-1.3v1.7h-1.7V8h1.7V9.7z M1.7,4.6h1.3V2.9h1.7V1.7H2.9V0H1.7v1.7H0v1.3h1.7V4.6z M4.6,9.7V8.4H3.4v1.3H4.6z"
														></path>
													</g>
												</g>
											</svg>
					`,
					title: 'Retail',
					imageLink: '/retail-app.jpg',
					descriptions: [
						'Millennial customers want novel in-store experiences more than anything else. They value customer experience above brand and pricing. A chatbot can help your business deliver remarkable in-store experience with chatbots that can assist in navigating the product racks, bill products instantly, collect customer feedback and much more.'
					]
				},
				{
					svg: `
						<svg
												x="0px"
												y="0px"
												viewBox="0 0 50 50"
												style="enable-background: new 0 0 50 50"
												xml:space="preserve"
											>
												<g>
													<g>
														<path
															d="M13.2,32.1l-5.5-2.6c-0.1-0.1-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.2-0.7,0.5c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.2,0.4,0.4,0.5
                              l5.5,2.6c0.4,0.2,0.9,0,1.1-0.4c0.1-0.2,0.1-0.4,0-0.6C13.5,32.4,13.4,32.2,13.2,32.1z M44.7,31.9c-0.4-0.2-0.9,0-1.1,0.4
                              c-0.2,0.5-0.6,0.9-1,1.1l-3.1,1.4c-0.4,0.2-1,0.2-1.5,0.1c-0.1,0-0.2,0-0.2,0c-0.4,0-0.7,0.2-0.8,0.6c-0.1,0.2,0,0.4,0.1,0.6
                              c0.1,0.2,0.3,0.3,0.5,0.4c0.9,0.3,1.9,0.2,2.7-0.2l3.1-1.4c0.8-0.4,1.4-1,1.8-1.9c0.1-0.2,0.1-0.4,0-0.6
                              C45.1,32.1,44.9,31.9,44.7,31.9z M9.8,13.7h0.7V23c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-9.3H25c3.8,0,6.9-3.1,6.9-6.9
                              c0-0.4-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,2.9-2.4,5.2-5.3,5.2H9.8c-2.9,0-5.3-2.3-5.3-5.2c0-2.9,2.4-5.2,5.3-5.2h8
                              l-0.6,0.7c-1.1,1.2-1.7,2.8-1.7,4.4c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8c0-2.8,2.4-5.2,5.3-5.2h17.8c2.9,0,5.3,2.3,5.3,5.2
                              c0,2.9-2.4,5.2-5.3,5.2h-6.1c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h3.8V23c0,0.4,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-9.3
                              h0.7c3.8,0,6.9-3.1,6.9-6.9S44,0,40.2,0H9.8C6,0,2.9,3.1,2.9,6.9S6,13.7,9.8,13.7z M11.7,35.2l-5.5-2.6c-0.1-0.1-0.2-0.1-0.3-0.1
                              c-0.3,0-0.6,0.2-0.7,0.5C5,33.3,5,33.5,5,33.7c0.1,0.2,0.2,0.4,0.4,0.5l5.5,2.6c0.4,0.2,0.9,0,1.1-0.4c0.1-0.2,0.1-0.4,0-0.6
                              C12,35.5,11.9,35.3,11.7,35.2z M48.5,29.9c-1.4-3-5-4.3-8-2.9L36,29.1c-2.6,1.2-4,4.1-3.3,6.9l0.1,0.3l-7.3,3.4l-7.3-3.4l0.1-0.3
                              c0.3-1.2,0.2-2.4-0.2-3.5c-0.6-1.5-1.7-2.7-3.1-3.4l-7-3.3c-0.8-0.4-1.7-0.4-2.5-0.1C4.7,26,4,26.6,3.6,27.4l-2.4,5.1
                              c-0.4,0.8-0.4,1.6-0.1,2.4c0.3,0.8,0.9,1.5,1.7,1.8l7,3.3c1.5,0.7,3.1,0.7,4.6,0.2c1.1-0.4,2.1-1.1,2.8-2.1l0.2-0.3l6,2.8
                              L6.6,48.4c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2-0.1,0.4,0,0.6c0.2,0.4,0.7,0.6,1.1,0.4l18.1-8.4l18.2,8.4c0.4,0.2,0.9,0,1.1-0.4
                              c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.2-0.4-0.4-0.5l-17-7.9l6.1-2.8l0.2,0.3c0.7,1,1.7,1.7,2.8,2.1c1.5,0.6,3.2,0.5,4.6-0.2l4.5-2.1
                              c1.5-0.7,2.6-1.9,3.1-3.4C49.2,33,49.2,31.4,48.5,29.9z M16.4,36.4c-0.5,1.1-1.4,1.9-2.5,2.3c-0.5,0.2-1,0.3-1.5,0.3
                              c-0.6,0-1.3-0.1-1.8-0.4l-7-3.3c-0.4-0.2-0.7-0.5-0.8-0.9c-0.1-0.4-0.1-0.8,0.1-1.2l2.4-5.1c0.2-0.4,0.5-0.7,0.9-0.8
                              c0.4-0.1,0.8-0.1,1.2,0l7,3.3c1.1,0.5,1.9,1.4,2.3,2.5C16.9,34.2,16.9,35.4,16.4,36.4z M47.1,34c-0.4,1.1-1.2,2-2.3,2.5l-4.5,2.1
                              c-0.6,0.3-1.2,0.4-1.8,0.4c-0.5,0-1-0.1-1.5-0.3c-1-0.4-1.9-1.1-2.4-2.1c0-0.1-0.1-0.3-0.1-0.3c-0.9-2.2,0-4.7,2.2-5.7l4.5-2.1
                              c2.2-1,4.8-0.1,5.9,2.1C47.5,31.7,47.5,32.9,47.1,34z"
														></path>
													</g>
												</g>
											</svg>
					`,
					title: 'Restaurant',
					imageLink: '/restaurent-app.jpg',
					descriptions: [
						'Place a chatbot for your restaurant on your website, social media channels or even on your mobile app. The chatbot can be trained to respond to user queries, make table reservations, take orders, inform customers of deals and promo codes and much more.'
					]
				}
			]
		};
	},
	methods: {
		setActiveIndex: function (index) {
			this.index_ = index;
			this.$refs.reviewsCarousel.goTo(index);
		},
		setIndex: function (index) {
			this.index_ = index;
		}
	}
};
</script>

<style>
.app-service-tab-text svg {
	width: 74px;
	height: 74px;
}
.fxt-cnt-scas {
	margin: 0 10px;
}
.icn-containter-fxt {
	display: flex;
	justify-content: space-between;
	align-items: space-between;
	flex-direction: column;
	height: 165px;
	width: 165px;
	flex-wrap: wrap;
	background: #fff;
	padding: 10px 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.icn-containter-fxt .svg-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 50px !important;
	height: 50px !important;
}

.icn-containter-fxt p {
	margin: 0;
	text-align: center;
	transition: 0.2s;
	color: black;
	font-size: 18px;
	font-weight: 600;
}
.fxtt-vue-slick svg {
	margin-bottom: 20px;
}
.fxtt-vue-slick svg path {
	fill: #008dd2 !important;
}
.icn-containter-fxt:hover p {
	color: #008dd2;
}
.big-data-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 567px) {
	.fxt-cnt-scas {
		margin: 0 30px;
	}
	.icn-containter-fxt {
		width: 150px !important;
		height: 150px !important;
	}
	.slick-slide {
		width: 100vw !important;
		padding: 5px;
	}
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>