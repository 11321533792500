<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Custom Instant Messaging App Solutions Features</h2>
				<p>
					Instant messaging can take any form. Whichever form you want to use, we will help you get
					it.
				</p>
			</div>
			<div style="display: flex; justify-content: space-between; align-items: center">
				<div class="ins-msg-content-wrap">
					<LittleImageWithInfo
						v-for="item in items"
						:key="item.imageLink"
						:obj="item"
					></LittleImageWithInfo>
				</div>
				<ul class="tab_list clearfix ins-msg-tab-wrap">
					<li v-for="(link, index) in links" :key="link.title" @click="handleClick(index)">
						<CustomLink :obj="link"></CustomLink>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import LittleImageWithInfo from '../AssetComponents/LittleImageWithInfo.vue';
import CustomLink from '../AssetComponents/CustomLink.vue';

export default {
	components: {
		LittleImageWithInfo,
		CustomLink
	},
	data() {
		return {
			idx: 0,
			items: [
				{
					imageLink: '/social-integration.png',
					title: 'Social Integrations',
					paragraphs: [
						'Build a chat app that can integrated into your social handles. Add more life to your website or support portal with a real-time chat app that shows online presence indicators, typing status, timestamp, multimedia sharing and much more. Users can also log into the chat app using their social media logins sparing them from the need to remember usernames and passwords.'
					]
				},
				{
					imageLink: '/contact-syncing.png',
					title: 'Contact Syncing',
					paragraphs: [
						'Sync contacts from your address book, email or SIM card without any trouble. The contacts will be carried forward with names, display pictures and statuses as set by the contacts. No manual editing is required since the contacts are synced via cloud. Contacts added to phonebook or email can also be automatically synced in the instant messaging app.'
					]
				},
				{
					imageLink: '/group-chat.png',
					title: 'Group Chat',
					paragraphs: [
						'Add more members to a chat to create a group chat. Assign a name to the group chat complete with text, special characters and emojis. Group chat admins can add or remove users at their discretion. All group members can send messages, audio clippings, video recordings, real-time location, and also respond inline to other group member’s conversations.'
					]
				},
				{
					imageLink: '/location.png',
					title: 'Location',
					paragraphs: [
						'Instant messaging is not just about sending and receiving text messages. Take your user’s messaging experience to the next level with features like real-time location sharing. The instant messaging app solutions will be integrated with GPS and navigation APIs that fetches the user device’s real-time location for real-time sharing.'
					]
				},
				{
					imageLink: '/multimedia-and-file-sharing.png',
					title: 'Multimedia and File Sharing',
					paragraphs: [
						'Sharing multimedia content in the form of images, video, audio and documents is what makes instant messaging a powerful communication medium. Your instant messaging app needs that power to make users into productive professionals. GenixBit Labs has built instant messengers with multimedia and file sharing features for a diverse range of industries.',
						'Be it official usage or social usage, we can help build the right instant message app for you.'
					]
				},
				{
					imageLink: '/voice-call.png',
					title: 'Voice Calling',
					paragraphs: [
						'Connect remote teams and individuals to one another with voice calling. Users can place voice calls from the app with internet as the communication medium. The app is also capable of call holding, call recording, call conferencing and many other telephony features.',
						'Want to turn your instant messaging app into a carry-anywhere telephone? GenixBit Labs can help.'
					]
				},
				{
					imageLink: '/video-calling.png',
					title: 'Video Calling',
					paragraphs: [
						'Catch up with your friends, families and teams with face-to-face video calling. It is simple, secure and easy-to-setup and does not require you have to any extra hardware or software. All it takes is a tap on the app interface to connect visually, real-time with your contacts.',
						'Build bonds or enhance productivity with a custom-built instant messaging app.'
					]
				},
				{
					imageLink: '/push-notifications.png',
					title: 'Push Notifications',
					paragraphs: [
						'Be in the know of incoming messages, missed voice/video calls, files received and much more. Your instant messaging app can be integrated with a push notification feature that will allow users to preview notifications without going into the app.',
						'Make sure your users see what their notifications even with a locked screen. Push notifications can prove to be useful for that.'
					]
				}
			],
			links: [
				{
					title: 'Social Integrations',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M93.3,33.3H71.7c-0.9,0-1.7,0.7-1.7,1.7v6.7c0,0.9,0.7,1.7,1.7,1.7h5c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3h-5
                        c-0.9,0-1.7,0.7-1.7,1.7V60H45c-0.9,0-1.7,0.7-1.7,1.7v21.7c0,3.7,3,6.7,6.7,6.7h43.3c3.7,0,6.7-3,6.7-6.7V40
                        C100,36.3,97,33.3,93.3,33.3z M70,86.7H50c-1.8,0-3.3-1.5-3.3-3.3v-20H70v3.3h-3.3c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7H70
                        V86.7z M96.6,83.3c0,1.8-1.5,3.3-3.3,3.3h-20v-8.3c0-0.9-0.7-1.7-1.7-1.7h-5c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3h5
                        c0.9,0,1.7-0.7,1.7-1.7v-5h5v3.3c0,3.7,3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7v-3.3h5V83.3z M96.6,60H90c-0.9,0-1.7,0.7-1.7,1.7v5
                        c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3v-5c0-0.9-0.7-1.7-1.7-1.7h-6.7v-6.7h3.3c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7
                        h-3.3v-3.3h20c1.8,0,3.3,1.5,3.3,3.3V60z M42.5,91.6c-1.9-0.9-4-1.1-6-0.5l1.1-5.5c0.5-2.4,0.7-4.9,0.7-7.4V63.2l-1.9-22.3
                        L48,35.4c2.1-1,3.6-3,3.7-5.4h16.7c0.9,0,1.7-0.7,1.7-1.7V20h3.3c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7H70v-5
                        C70,0.8,69.2,0,68.3,0H46.7C43,0,40,3,40,6.7v18.7l-6.9,3.3c-1-1.4-2.3-2.5-3.8-3.4c6.6-3.3,9.2-11.3,5.9-17.9
                        C32,0.8,24-1.9,17.4,1.4c-6.6,3.3-9.2,11.3-5.9,17.9c1.1,2.3,2.9,4.2,5.1,5.5c-3.5,1.7-6.1,4.8-7.1,8.6L2.8,54.3
                        c-1.9,5.9-1.5,12.3,1.1,17.9c2.7,6,2.6,12.8-0.3,18.7l-3.3,6.7c-0.4,0.8-0.1,1.8,0.7,2.2c0.2,0.1,0.5,0.2,0.7,0.2H20
                        c0.9,0,1.7-0.7,1.7-1.7c0-3.1-1.7-5.9-4.5-7.4l0.8-1.6c2.4-4.8,3.2-10.3,2.2-15.7L19,66.9c-0.2-0.9,0.4-1.7,1.3-1.9
                        c0.1,0,0.2,0,0.3,0c0.8,0,1.4,0.6,1.6,1.3l2.3,12.5c0.4,2,0.6,4.1,0.6,6.1v13.4c0,0.9,0.7,1.7,1.7,1.7H45c0.9,0,1.7-0.7,1.7-1.7
                        c0-0.3,0-0.6-0.1-1C46.3,94.9,44.8,92.7,42.5,91.6z M43.3,6.7c0-1.8,1.5-3.3,3.3-3.3h20v5c0,0.9,0.7,1.7,1.7,1.7h5
                        c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3h-5c-0.9,0-1.7,0.7-1.7,1.7v8.3H50.9c-1.5-2.7-4.7-3.9-7.6-2.9V6.7z M44.3,27
                        c0.9-0.4,1.9-0.3,2.8,0.2c0.8,0.5,1.3,1.5,1.3,2.4c0,1.2-0.7,2.2-1.7,2.7l0,0L24.1,43.1c-1.4,0.7-3.1,0.1-3.8-1.4
                        c-0.2-0.4-0.3-0.8-0.3-1.2c0-1.1,0.6-2.1,1.6-2.6L44.3,27z M13.4,13.3c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10
                        C17.8,23.3,13.4,18.9,13.4,13.3z M28.3,96.7V84.9c0-2.3-0.2-4.5-0.6-6.7l-2.3-12.4c-0.5-2.7-3-4.5-5.7-4c-2.7,0.5-4.5,3-4,5.7
                        c0,0,0,0.1,0,0.1l1.2,6.8c0.8,4.6,0.1,9.4-1.9,13.6l-1.6,3.2c-0.4,0.8-0.1,1.8,0.7,2.2c0.1,0.1,0.2,0.1,0.4,0.1
                        c1.6,0.4,3,1.6,3.5,3.2H4.4l2.1-4.3c3.4-6.8,3.5-14.7,0.4-21.6C4.7,65.9,4.4,60.4,6,55.3l6.7-21c1.4-5.2,6.5-8.4,11.8-7.4
                        c2.2,0.4,4.1,1.6,5.6,3.3l-9.8,4.8c-2.1,1-3.5,3.2-3.5,5.6c0,3.4,2.7,6.2,6.2,6.2c0.9,0,1.8-0.2,2.7-0.6l7.7-3.7L35,63.3v14.8
                        c0,2.3-0.2,4.5-0.7,6.7l-1.8,8.9c-0.2,0.9,0.4,1.8,1.3,2c0.4,0.1,0.8,0,1.2-0.2l1.5-0.8c1.4-0.8,3.1-0.8,4.5-0.1
                        c0.9,0.4,1.6,1.2,2,2.1H28.3z M51.7,49.3l-5.5-5.5l-2.4,2.4l11.7,11.7c0.7,0.6,1.7,0.6,2.4,0l11.7-11.7l-2.4-2.4l-5.5,5.5V40h-3.3
                        v12.6l-1.7,1.7L55,52.6v-6h-3.3V49.3z M51.7,43.3H55V40h-3.3V43.3z M55,33.3h-3.3v3.3H55V33.3z M61.7,33.3h-3.3v3.3h3.3V33.3z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Contact Syncing',
					svg: `
							<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <path d="M73.1,71.8h0.1c1.3,0,2.3-1,2.4-2.2c0.4-5.9-1.8-9.9-6-10.9c-7.4-1.9-10.5-4.2-11.5-5.6c0.5-0.4,1-0.8,1.4-1.3
                    c4.7-5.4,4-14.3,4-15.4C63,25.9,55.1,23.2,50.5,23.4c-4.6-0.1-12.5,2.4-13.1,13.1c-0.1,1-0.8,10,4,15.4c0.5,0.5,1,1,1.7,1.5
                    c-1.2,1.4-4.4,3.6-11.4,5.4c-4.2,1-6.5,5-6,10.9c0.1,1.3,1.2,2.2,2.4,2.2h0.1c1.3-0.1,2.3-1.3,2.2-2.6c-0.1-1.9,0-5.2,2.4-5.9
                    c9.5-2.3,13.2-5.6,14.7-8.1c0.9,0.3,1.8,0.3,2.8,0.4h0.1h0.1c0,0,0,0,0.1,0h0.1c1.2,0,2.2-0.1,3.2-0.4c1.4,2.4,5.2,5.8,14.7,8.1
                    c2.4,0.6,2.6,4,2.4,5.9C70.8,70.6,71.7,71.7,73.1,71.8z M50.4,51C50.4,51,50.2,51,50.4,51c-2.4,0-4.2-0.8-5.5-2.3
                    c-3.5-4-2.7-11.7-2.7-11.7v-0.1c0.4-7.9,6.1-8.6,7.8-8.6c0.1,0,0.1,0,0.3,0h0.1c1.3,0,7.8,0.1,8.2,8.6V37c0,0.1,0.8,7.8-2.7,11.7
                    C54.5,50.2,52.7,50.8,50.4,51z M7.5,65.3c0.4,1.3-0.3,2.6-1.4,3.1c-0.3,0.1-0.5,0.1-0.8,0.1c-1,0-1.9-0.6-2.3-1.5
                    c-6.5-18.2-2.2-38,11.3-51.9l0,0C23.6,5.5,36.2,0.3,49.5,0c13.1-0.1,25.3,4.7,34.8,13.6V9.3c0-1.3,1-2.4,2.4-2.4
                    c1.3,0,2.4,1,2.4,2.4l0.1,10c0,1.3-1,2.4-2.4,2.4l-10,0.1l0,0c-1.3,0-2.4-1-2.4-2.4c0-1.3,1-2.4,2.4-2.4H81
                    C72.4,9.1,61.4,4.7,49.6,4.9c-12,0.1-23.4,5-31.9,13.6l0,0C5.4,30.9,1.5,48.8,7.5,65.3z M85.7,84.9c-9.3,9.6-21.9,14.9-35.2,15.1
                    c-0.3,0-0.4,0-0.6,0c-12.8,0-25-4.9-34.3-13.6v4.2c0,1.3-1,2.4-2.4,2.4l0,0c-1.3,0-2.4-1-2.4-2.4l-0.1-10c0-1.3,1-2.4,2.4-2.4
                    l10-0.1c1.3,0,2.4,1,2.4,2.4c0,1.4-1,2.4-2.4,2.4h-4.1c8.4,7.9,19.3,12.2,30.8,12.2c0.1,0,0.4,0,0.5,0c12-0.1,23.4-5,31.9-13.6
                    C94.3,69,98.2,51.1,92.3,34.7c-0.4-1.3,0.3-2.6,1.4-3.1c1.3-0.4,2.6,0.3,3.1,1.4C103.6,51.2,99.3,71.1,85.7,84.9z"></path>
                  </svg>
					`
				},
				{
					title: 'Group Chat',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M96.5,83l-5.7-1.6c0,0,0,0,0,0c4.5-0.4,6.4-2.2,6.6-2.4c0.4-0.4,0.5-0.9,0.5-1.4c-0.1-0.5-0.3-1-0.8-1.2
                        c-1.3-0.9-2-3.1-1.9-6.4c0.2-5.3-0.9-8.8-3.2-10.5c-1.2-0.9-2.4-1-3.3-1c-0.4-0.5-1-1-1.9-1.3c-1.9-0.7-4.2-0.4-7,1.1
                        c-3.7,1.9-5,5.2-4.8,12.5c0.1,2.1-0.4,4.8-2,5.5c-0.5,0.2-0.9,0.7-1,1.2c-0.1,0.5,0.1,1.1,0.4,1.5c0.1,0.1,2.3,2.4,6.5,2.7
                        c0,0,0,0-0.1,0L73.9,83c-2.2,0.6-3.7,2.6-3.7,4.9v10.4c0,0.9,0.8,1.7,1.7,1.7h26.5c0.9,0,1.7-0.8,1.7-1.7V87.6
                        C100,85.5,98.6,83.6,96.5,83z M76.5,77.4c1.8-2.3,1.9-5.9,1.8-7.3c-0.1-7.4,1.6-8.3,3-9c1.8-0.9,3.2-1.3,4.2-0.9
                        c0.6,0.2,0.8,0.6,0.8,0.6c0.3,0.8,1.2,1.2,2.1,1c0.1,0,0.9-0.2,1.6,0.4c0.7,0.6,1.9,2.3,1.8,7.7c-0.1,3.3,0.4,5.8,1.6,7.6
                        c-0.9,0.3-2.3,0.7-4.3,0.7c-0.9,0-1.6,0.7-1.7,1.6c-0.1,3.1-0.1,3.1-0.1,3.3c0.1,0.6,0.6,1.1,1.2,1.3l0.6,0.2
                        c-0.5,1.1-2,2.8-4,4.5c-1.9-1.6-3.6-3.5-4-4.6l0.3-0.1c0.7-0.2,1.2-0.8,1.2-1.6l0.1-2.9c0-0.5-0.2-0.9-0.5-1.3
                        c-0.3-0.3-0.8-0.5-1.3-0.5C79,78.3,77.5,77.9,76.5,77.4z M96.6,96.6H73.5v-8.7c0-0.8,0.5-1.5,1.3-1.7l3.2-0.9c1.1,3,5,6.1,6.3,7.1
                        c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.7-0.1,1-0.3c1.3-1,5.2-4.1,6.3-7l3.1,0.9c0.6,0.2,1,0.7,1,1.3V96.6z M26.2,80.8
                        c-1.6-0.6-2.4-1.1-2.7-1.4l0-0.2c1.2-1.2,1.8-2.7,2.1-3.6c0.4-0.2,0.7-0.6,0.9-0.9c1-1.6,1.4-3.1,1-4.5c-0.1-0.5-0.4-0.9-0.6-1.2
                        c0.4-2,0.8-6.3-2.1-9.1c-1.6-1.6-3.2-2.3-4.8-2.2c-0.5,0-0.9,0.2-1.2,0.3c-1-0.6-2.5-1.3-4.2-1c-2,0.3-3.7,1.6-5,4
                        C8,63.9,8,66.5,8.4,68.9c-0.1,0.1-0.1,0.1-0.2,0.2c-1.2,1.4-0.4,3.6-0.2,4.4c0.4,1,0.9,1.6,1.4,2c0.4,1,1.1,2.7,2.3,3.8l0,0.2
                        c-0.4,0.3-1.8,1.3-5.6,2.6c-6.3,2.2-6.3,6.7-6.1,7.8l0,8.4c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5h31.7
                        c0.9,0,1.7-0.8,1.7-1.7v-8.4C35.4,84.1,29.2,81.9,26.2,80.8z M11.5,72.7c0,0-0.1-0.2-0.2-0.4c-0.1-0.4-0.2-0.6-0.2-0.8
                        c0.2-0.1,0.4-0.2,0.6-0.4c0.4-0.4,0.5-1,0.4-1.6c-0.6-2.4-0.8-4.4,0.5-6.7c0.5-1,1.4-2.2,2.5-2.3c1.1-0.1,2.2,0.7,2.6,1
                        c0.4,0.4,0.9,0.5,1.4,0.5c0.5-0.1,1-0.3,1.3-0.8c0.3-0.1,1.1,0.3,2.1,1.2c1.8,1.8,1.3,5.5,0.9,6.7c-0.2,0.8,0,1.6,0.8,1.9
                        c0.1,0.1,0.2,0.6-0.4,1.6c-0.6,0.2-1.1,0.7-1.2,1.3c0,0-0.4,2-1.8,3.2c-0.4,0.3-0.6,0.8-0.6,1.4l0.1,1.6c0,0.3,0.1,0.6,0.2,0.8
                        c0.1,0.2,0.6,0.8,1.6,1.5c0,0,0,0,0,0c0,1-2.2,3.4-4.4,5.3c-2.2-1.8-4.3-4.1-4.4-5.2c1.6-1,1.8-1.8,1.9-2.3l0.1-1.7
                        c0.1-0.7-0.3-1.4-0.9-1.7c-0.6-0.3-1.4-1.9-1.8-3.1C12.3,73.3,12,72.9,11.5,72.7z M31.7,89.7c0,0,0,0.1,0,0.1v6.7H3.4l0-6.9
                        c-0.1-1.1,0.2-3.1,3.9-4.4c1.1-0.4,2-0.8,2.9-1.1c1.2,3,5.1,6.1,6.4,7.1c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3
                        c1.4-1,5.3-4.2,6.5-7.3c0,0,0.1,0,0.1,0C29.2,85.5,31.9,87,31.7,89.7z M69.3,66.2c-0.4-2.4-2.5-4.5-4.9-5.1L59.3,60
                        c-0.2-0.5-0.4-0.9-0.6-1.2c0.7-1,1.3-2.2,1.8-3.4c1.3-0.7,2.4-2,2.7-3.4c0.5-1.9,0.1-3.7-1-4.9v-0.1c1.5-4.9,0.2-9.2-3.6-11.4
                        c-0.8-0.5-1.6-0.8-2.3-1c0.1-0.3,0.1-0.7-0.1-1c-0.2-0.6-0.8-1.1-1.5-1.1c-1.1-0.1-2.1,0.2-3.4,1.4c-1.5-0.4-3.1-0.4-4.9-0.1
                        c-3.2,0.7-5.8,2.9-7.2,6.3c-1,2.5-1.4,5.4-0.9,7.4c-0.8,1.2-1.1,2.8-0.7,4.5c0.3,1.4,1.4,2.7,2.7,3.4c0.5,1.2,1.1,2.4,1.8,3.5
                        c-0.2,0.3-0.4,0.7-0.6,1.2l-5.1,1.1c-2.4,0.5-4.5,2.7-4.9,5.4l0.1,11.8c0,0.9,0.8,1.7,1.7,1.7h34.2c0.9,0,1.7-0.8,1.7-1.7l0-11.7
                        L69.3,66.2z M41,51.2c-0.2-0.9,0-1.6,0.3-1.8c0.8-0.5,1-1.5,0.5-2.3c-0.3-0.6-0.5-3.2,0.7-5.8c0.7-1.5,2.1-3.5,4.7-4
                        c1.6-0.3,2.9-0.2,4,0.3c0.3,0.1,0.6,0.2,0.9,0.1c0.4,0.4,1.1,0.5,1.6,0.3c0.9-0.3,2-0.1,3.2,0.6c3.3,1.9,2.7,5.7,2,7.7
                        c-0.1,0.2-0.1,0.4-0.1,0.5v1.1c0,0.6,0.3,1.1,0.8,1.4c0.3,0.2,0.5,0.9,0.3,1.8c-0.2,0.7-0.8,1.2-1.4,1.4c-0.5,0.2-0.8,0.5-1,1
                        c-0.6,1.6-1.4,3-2.3,4.2c-0.3,0.4-0.4,0.9-0.3,1.4c0.1,0.3,0.2,0.5,0.3,0.7c0.3,0.4,1,1.9,1.2,2.3c0,0.1,0.1,0.2,0.1,0.2
                        c0,1.4-3,4.6-6.1,7c-3.1-2.4-6.1-5.6-6.1-7l0.1-0.3c0.2-0.5,0.9-1.9,1.2-2.3c0.2-0.2,0.3-0.5,0.3-0.7c0.1-0.5,0-1-0.3-1.4
                        c-0.9-1.1-1.7-2.6-2.3-4.2c-0.2-0.5-0.5-0.8-1-1C41.8,52.4,41.2,51.9,41,51.2z M35.1,76.6L35,66.8c0.2-1,1.2-2.1,2.2-2.3l3.9-0.8
                        c1.1,3.8,6.5,7.9,8.3,9.2c0.3,0.2,0.6,0.3,1,0.3s0.7-0.1,1-0.3c1.8-1.3,7.2-5.5,8.3-9.2l3.9,0.8c1,0.2,2.1,1.3,2.3,2.3l0.1,9.9
                        H35.1z M47.3,25.5H48c2.3,2.7,1,5.5,1,5.6c-0.3,0.6-0.2,1.4,0.3,1.9c0.3,0.3,0.8,0.5,1.2,0.5c0.2,0,0.5-0.1,0.7-0.2
                        c0.2-0.1,5.4-2.5,7.5-7.9h14c3,0,5.4-2.4,5.4-5.4V5.4c0-3-2.4-5.4-5.4-5.4H47.3c-3,0-5.4,2.4-5.4,5.4V20
                        C41.9,23,44.3,25.5,47.3,25.5z M45.2,5.4L45.2,5.4c0-1.1,0.9-2,2-2h25.5c1.1,0,2,0.9,2,2V20c0,1.1-0.9,2-2,2H57.5
                        c-0.8,0-1.4,0.5-1.6,1.3c-0.6,2.1-1.9,3.8-3.1,4.9c-0.2-1.7-0.8-3.7-2.8-5.7c-0.3-0.3-0.7-0.5-1.2-0.5h-1.5c-1.1,0-2-0.9-2-2V5.4z
                        M6.3,51.1h13.5c2,5.2,7,7.5,7.2,7.6c0.2,0.1,0.5,0.1,0.7,0.1c0.4,0,0.9-0.2,1.2-0.5c0.5-0.5,0.6-1.3,0.3-1.9
                        c-0.1-0.3-1.2-2.8,0.9-5.3h0.7c2.9,0,5.3-2.4,5.3-5.3V31.8c0-2.9-2.4-5.3-5.3-5.3H6.3c-2.9,0-5.3,2.4-5.3,5.3v14.1
                        C1,48.8,3.4,51.1,6.3,51.1z M4.4,31.8c0-1,0.9-1.9,1.9-1.9h24.5c1,0,1.9,0.9,1.9,1.9v14.1c0,1-0.9,1.9-1.9,1.9h-1.4
                        c-0.4,0-0.9,0.2-1.2,0.5c-1.8,1.8-2.5,3.8-2.7,5.4c-1.2-1.1-2.3-2.6-2.9-4.6c-0.2-0.7-0.9-1.3-1.6-1.3H6.3c-1,0-1.9-0.9-1.9-1.9
                        V31.8z M65,39.1c0,8.1,7.8,14.6,17.5,14.6c0.4,0,0.8,0,1.2,0c4.9,4.2,9.6,4.3,9.8,4.3c0.7,0,1.2-0.4,1.5-1s0.2-1.3-0.2-1.8
                        c-0.1-0.1-1.3-1.7-1.2-4.8c4-2.8,6.4-6.9,6.4-11.3c0-6.9-5.6-12.8-13.6-14.3c-1.2-0.2-2.5-0.4-3.8-0.4C72.9,24.5,65,31,65,39.1z
                        M82.5,27.9c1.1,0,2.2,0.1,3.2,0.3h0c6.3,1.2,10.9,5.8,10.9,10.9c0,3.5-2,6.8-5.5,8.9c-0.4,0.3-0.7,0.7-0.8,1.2
                        c-0.2,1.9-0.1,3.5,0.3,4.7c-1.4-0.5-3.3-1.5-5.2-3.2c-0.3-0.3-0.7-0.5-1.1-0.5c0,0-0.1,0-0.1,0c-0.5,0-1.1,0.1-1.6,0.1
                        c-7.8,0-14.1-5-14.1-11.2C68.4,32.9,74.7,27.9,82.5,27.9z M54.1,10.5h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H54.1
                        c-0.9,0-1.7,0.8-1.7,1.7C52.4,9.8,53.1,10.5,54.1,10.5z M54.1,17.6h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H54.1
                        c-0.9,0-1.7,0.8-1.7,1.7C52.4,16.8,53.1,17.6,54.1,17.6z M77.3,38h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H77.3
                        c-0.9,0-1.7,0.8-1.7,1.7C75.6,37.3,76.3,38,77.3,38z M77.3,45.1h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H77.3
                        c-0.9,0-1.7,0.8-1.7,1.7C75.6,44.3,76.3,45.1,77.3,45.1z M11.2,37.3h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H11.2
                        c-0.9,0-1.7,0.8-1.7,1.7C9.5,36.6,10.3,37.3,11.2,37.3z M11.2,44.4h12.7c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H11.2
                        c-0.9,0-1.7,0.8-1.7,1.7C9.5,43.6,10.3,44.4,11.2,44.4z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Location',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M82,73.1c-4.8-2.2-11.1-3.8-18.2-4.7c10.9-14.7,16.9-24.1,17.7-27.9c0,0,0,0,0,0l0.2-1.1c0,0,0,0,0,0c0.4-2,0.6-4,0.6-6
                        C82.2,14.9,67.3,0,49,0C30.7,0,15.8,14.9,15.8,33.3c0,2,0.2,4.1,0.5,6c0,0,0,0,0,0l0.2,1.1c0,0,0,0,0,0
                        c0.8,3.8,6.9,13.3,17.9,28.2c-6.7,1-12.6,2.6-17,4.8C9.9,77,8.3,81,8.3,83.8c0,4.8,4.5,8.9,13,12C29,98.5,39.2,100,50,100
                        c10.8,0,21-1.5,28.7-4.3c8.5-3,13-7.2,13-12C91.7,80.9,90,76.8,82,73.1z M20.6,39.6l-0.2-1c-0.3-1.7-0.5-3.5-0.5-5.3
                        c0-16,13-29.1,29.1-29.1c16,0,29.1,13,29.1,29.1c0,1.8-0.2,3.5-0.5,5.3l-0.2,1c-0.4,1.6-3,7.6-19.2,29.2c0,0,0,0,0,0
                        c-1.6,2.1-3.2,4.3-4.9,6.4c0,0,0,0,0,0c-1.8,2.3-3.3,4.3-4.3,5.6c-1-1.3-2.5-3.2-4.2-5.5h0c-1.7-2.2-3.3-4.3-4.9-6.4
                        C23.6,47.2,21,41.1,20.6,39.6z M58.5,79c0.2,0,0.3,0,0.5,0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0,0.2,0
                        c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.4,0.1c0.1,0,0.2,0,0.2,0.1
                        c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
                        c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0.1
                        c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0.1,0c2.6,0.9,3.5,1.8,3.7,2.1c-0.2,0.4-1.5,1.5-5.2,2.5c-3.7,1-8.5,1.5-13.5,1.5
                        c-5,0-9.8-0.6-13.5-1.5c-3.8-1-5-2.2-5.2-2.5c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0.1-0.1
                        c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1
                        c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0
                        c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
                        c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
                        c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0
                        c0.1,0,0.3-0.1,0.4-0.1c0,0,0,0,0.1,0c0.1,0,0.3-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2-0.1
                        c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1
                        c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4-0.1
                        c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4-0.1c0,0,0.1,0,0.1,0c3.1,4,5.2,6.6,5.2,6.7
                        c0.4,0.5,1,0.8,1.6,0.8c0.6,0,1.2-0.3,1.6-0.8c0,0,2.2-2.8,5.3-6.9c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0
                        c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.4,0.1C58.3,79,58.4,79,58.5,79z M77.3,91.8
                        c-7.3,2.6-17,4-27.3,4c-10.3,0-20-1.4-27.3-4c-6.4-2.3-10.2-5.3-10.2-8c0-4,8.7-9.3,24.8-11.3c0.1,0.1,0.2,0.2,0.2,0.3
                        c0.1,0.2,0.3,0.4,0.4,0.6c0.3,0.4,0.7,0.9,1,1.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.4c-0.3,0.1-0.7,0.1-1,0.2c0,0,0,0,0,0
                        c-0.3,0.1-0.7,0.1-1,0.2c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0c-0.3,0.1-0.7,0.2-1,0.3c0,0,0,0,0,0
                        c-0.3,0.1-0.7,0.2-1,0.3c0,0,0,0-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.2c0,0,0,0,0,0
                        c-0.2,0.1-0.3,0.1-0.5,0.2c0,0,0,0-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0-0.1,0
                        c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0-0.1,0
                        c-0.1,0.1-0.2,0.1-0.4,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.4,0.2c0,0,0,0-0.1,0c-0.2,0.2-0.5,0.3-0.7,0.5c0,0,0,0-0.1,0.1
                        c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.2c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0-0.1,0.1
                        c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0
                        c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1
                        c0,0.1,0,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0,2.7,2.5,4.9,7.6,6.4c4.1,1.2,9.5,1.9,15.3,1.9
                        c4.1,0,8-0.3,11.4-1c1.4-0.3,2.7-0.6,3.9-0.9c5-1.5,7.6-3.6,7.6-6.4c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0-0.1,0-0.3-0.1-0.4
                        c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.4c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0
                        c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1-0.1
                        c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0-0.1,0
                        c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.4-0.2c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0,0,0
                        c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1,0
                        c-0.2-0.1-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0
                        c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0
                        c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0
                        c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c0,0-0.1,0-0.1,0
                        C60.3,75,60.2,75,60,75c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4-0.1c0,0,0,0,0,0
                        c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.4,0.6-0.8,0.9-1.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.3,0.4-0.5
                        c16.7,1.7,26.6,7.1,26.6,11.5C87.5,86.5,83.7,89.5,77.3,91.8z M63.8,31.8C63.8,23.7,57.2,17,49,17c-8.2,0-14.8,6.7-14.8,14.8
                        c0,8.2,6.7,14.8,14.8,14.8C57.2,46.7,63.8,40,63.8,31.8z M38.3,31.8c0-5.9,4.8-10.7,10.7-10.7c5.9,0,10.7,4.8,10.7,10.7
                        c0,5.9-4.8,10.6-10.7,10.6C43.1,42.5,38.3,37.7,38.3,31.8z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Multimedia and File Sharing',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M38.2,10h3.4V6.7h-3.4V10z M58.4,10h3.4V6.7h-3.4V10z M56.7,30h-3.4v3.3h3.4V30z M63.5,30h-3.4v3.3h3.4V30z M70.2,30h-3.4
                        v3.3h3.4V30z M31.4,70h3.4v-3.3h-3.4V70z M38.2,70h3.4v-3.3h-3.4V70z M48.3,66.7h-3.4V70h3.4V66.7z M44.9,10h10.1V6.7H44.9V10z
                        M38.2,86.7h-6.7V90h6.7V86.7z M68.6,86.7h-6.7V90h6.7V86.7z M50,93.3c2.8,0,5.1-2.2,5.1-5s-2.3-5-5.1-5c-2.8,0-5.1,2.2-5.1,5
                        S47.2,93.3,50,93.3z M50,86.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C48.3,87.4,49.1,86.7,50,86.7z
                        M36.5,30h-27v3.3h27V30z M21.3,36.7H9.5V40h11.8V36.7z M36.5,43.3h-27v3.3h27V43.3z M31.4,40h5.1v-3.3h-5.1V40z M28.1,40v-3.3
                        h-3.4V40H28.1z M90.5,63.3c-2.3,0-4.3,1.1-5.6,2.9L80.4,64V49.4l4.6-2.3c1.2,1.7,3.2,2.9,5.6,2.9c3.7,0,6.7-3,6.7-6.7
                        c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7c0,0.2,0,0.4,0.1,0.6l-3.4,1.7V5c0-2.8-2.3-5-5.1-5H24.7c-2.8,0-5.1,2.2-5.1,5v18.3H7.8
                        c-2.8,0-5.1,2.2-5.1,5v20c0,2.8,2.3,5,5.1,5h11.8V95c0,2.8,2.3,5,5.1,5h50.6c2.8,0,5.1-2.2,5.1-5V67.7l3.4,1.7
                        c0,0.2-0.1,0.4-0.1,0.6c0,3.7,3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7C97.2,66.3,94.2,63.3,90.5,63.3z M90.5,40c1.9,0,3.4,1.5,3.4,3.3
                        c0,1.8-1.5,3.3-3.4,3.3c-1.9,0-3.4-1.5-3.4-3.3C87.1,41.5,88.6,40,90.5,40z M7.8,50c-0.9,0-1.7-0.7-1.7-1.7v-20
                        c0-0.9,0.8-1.7,1.7-1.7h30.4c0.9,0,1.7,0.7,1.7,1.7v20c0,0.9-0.8,1.7-1.7,1.7h-1.7c-0.9,0-1.7,0.7-1.7,1.7v2.6L31,50.5
                        c-0.3-0.3-0.7-0.5-1.2-0.5H7.8z M77,95c0,0.9-0.8,1.7-1.7,1.7H24.7c-0.9,0-1.7-0.7-1.7-1.7V80h54V95z M77,62.3l-5.7-2.8
                        c0.4-0.9,0.7-1.8,0.7-2.8c0-1-0.2-2-0.7-2.8L77,51V62.3z M77,47.3l-7.9,3.9C68,50.5,66.6,50,65.2,50c-3.7,0-6.7,3-6.7,6.7
                        c0,3.7,3,6.7,6.7,6.7c1.4,0,2.8-0.5,3.9-1.2L77,66v10.6H23V53.3h6l6.3,6.2c0.1,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1
                        c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6-0.1c0.6-0.3,1-0.9,1-1.5v-5c2.8,0,5.1-2.2,5.1-5v-20
                        c0-2.8-2.3-5-5.1-5H23v-6.7h54V47.3z M68.6,56.7c0,1.8-1.5,3.3-3.4,3.3c-1.9,0-3.4-1.5-3.4-3.3c0-1.8,1.5-3.3,3.4-3.3
                        C67,53.3,68.6,54.8,68.6,56.7z M77,13.3H23V5c0-0.9,0.8-1.7,1.7-1.7h50.6C76.2,3.3,77,4.1,77,5V13.3z M90.5,73.3
                        c-1.9,0-3.4-1.5-3.4-3.3c0-1.8,1.5-3.3,3.4-3.3c1.9,0,3.4,1.5,3.4,3.3C93.8,71.8,92.3,73.3,90.5,73.3z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Voice Calling',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M35.2,71.4c-0.2-1-0.9-1.8-1.7-2.4c-0.9-0.5-1.9-0.7-2.9-0.5L24.2,70c-1.6,0.4-4.8-3.3-6.4-9.9
                        c-1.6-6.6-0.3-11.3,1.3-11.7l6.3-1.5c2.1-0.5,3.4-2.6,2.9-4.6l-3-12.7c-0.5-2.1-2.6-3.3-4.7-2.8l-6.3,1.5c0,0,0,0,0,0c0,0,0,0,0,0
                        l-5.5,1.3c-8,1.9-11,16.9-6.9,34.3c3.9,16.2,12.4,27.8,20,27.8c0.6,0,1.1-0.1,1.7-0.2l11.8-2.7c2.1-0.5,3.4-2.6,2.9-4.6L35.2,71.4
                        z M21.2,29.7c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.4,0.1c0.2,0.1,0.3,0.3,0.4,0.5l3,12.7c0.1,0.2,0,0.4-0.1,0.6
                        c-0.1,0.2-0.3,0.3-0.5,0.4l-4.8,1.1l-3.4-14.3L21.2,29.7z M22.9,88.6c-5.5,1.3-14-8.6-18-25.4C0.8,46.4,4,33.8,9.4,32.5l4-0.9
                        l3.5,14.6c-3.1,2.2-3.5,8.8-2.1,14.6c1.4,5.9,4.7,11.6,8.5,12.2l3.5,14.6L22.9,88.6z M35.2,85.4c-0.1,0.2-0.3,0.3-0.5,0.4
                        l-4.8,1.1l-3.4-14.3l4.8-1.1c0.5-0.1,0.9,0.2,1,0.6l3,12.7C35.3,85,35.3,85.2,35.2,85.4z M95,47.1c2.6-13.7,1.9-22.6-2-26.6
                        c-1.5-1.5-3.2-2-4.4-2.1C80.1,7,71.1,8.2,69.4,8.6C53.8,8,44,17.1,43.7,17.4c-1.9,1.5-8,0.1-11.7-1.3c-0.5-0.2-1-0.1-1.4,0.1
                        c-0.4,0.3-0.7,0.7-0.7,1.2c-0.5,6.5,2.6,10,4.2,11.4c-2.6,9.1-1.8,14.9-0.8,18c-0.1,0-0.3,0-0.3,0c-3.7,0.2-6.6,3.1-6.6,6.5
                        c0,3.6,3.2,6.6,7.1,6.6l0.5,0c0.3,0,0.5,0,0.7,0c2.1,10.8,11.8,20.9,29.7,20.9c17.5,0,27.3-10.2,28.7-21c3.8-0.1,6.9-3,6.9-6.5
                        C100,50.4,97.9,47.9,95,47.1z M34.6,53.7c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.1,1-0.1,2-0.2,3c-0.1,0-0.2,0-0.3,0l-0.6,0
                        c-2.2,0-4.1-1.6-4.1-3.6c0-1.9,1.7-3.4,3.9-3.6c0,0,0.1,0,0.1,0c0.3,0,1,0.1,1.6,0.2C34.9,51.2,34.7,52.4,34.6,53.7z M90.3,57.6
                        c-0.2,10.1-9.1,20.2-25.8,20.2c-17.3,0-26-10.2-27-20.4c0.1-3.3,0.4-6.3,1-8.9c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.5,0.2-0.8
                        c0,0,0-0.1,0-0.1c0.2-0.5,0.4-0.9,0.8-1.4c0.1-0.1,0.1-0.1,0.2-0.2c0.6-0.5,1.3-1,1.9-1.6c2.1-1.7,4.3-3.5,6.3-5.6
                        c0.3-0.3,0.4-0.7,0.4-1.1l-0.2-8l8.1,3.9c0.7,0.3,1.5,0.1,1.9-0.5l3.2-4.4c1.1-1.5,2.9-2.5,4.8-2.6c1.9-0.1,3.8,0.6,5.1,1.9
                        c3.3,3.4,7,6.4,10.6,9.3l1.2,1c2.6,2.1,4.7,6,6.5,11.8c0.3,0.9,0.5,1.8,0.6,2.7c0,0.2,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0
                        c0.2,1.5,0.2,2.9,0.2,3.7C90.3,57.3,90.3,57.4,90.3,57.6z M91.5,46.9c-1.3-3.7-3.3-8-6.6-10.7l-1.2-1c-3.5-2.9-7.1-5.8-10.3-9.1
                        c-2-2-4.7-3-7.5-2.8c-2.8,0.2-5.3,1.6-7,3.8l-2.5,3.3L47.3,26c-0.5-0.2-1-0.2-1.5,0.1c-0.4,0.3-0.7,0.8-0.7,1.3l0.2,9.8
                        c-1.8,1.7-3.7,3.3-5.6,4.9c-0.6,0.5-1.3,1-1.9,1.6c-0.2,0.1-0.3,0.3-0.5,0.5c-0.4,0.5-0.8,1-1,1.5c-0.8-2.7-1.6-8.2,1.1-17
                        c0.2-0.6-0.1-1.3-0.6-1.7c-0.2-0.1-3.4-2.2-3.8-7.4c3.3,1.1,9.6,2.6,12.8,0.1c0.1-0.1,9.2-8.7,23.7-8.1c0.2,0,0.3,0,0.5,0
                        c0.1,0,8.5-2.1,16.6,9.3c0.3,0.5,0.9,0.7,1.5,0.6c0.1,0,1.5-0.2,2.8,1.2c1.8,1.9,4.3,7.6,1.1,24.2C91.8,46.9,91.7,46.9,91.5,46.9z
                        M93.3,56.9c0-0.9-0.1-2.2-0.2-3.6c0,0,0,0,0,0c0,0,0-0.2,0-0.5c-0.1-0.9-0.3-1.9-0.6-2.9c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.3,0
                        c2.1,0.1,3.8,1.7,3.8,3.6C97,55.2,95.4,56.7,93.3,56.9z M55.5,52c0.2,0.6,0.8,1.1,1.4,1.1c0.1,0,0.3,0,0.4-0.1
                        c0.8-0.2,1.3-1.1,1-1.9c-0.6-2-2.6-4.2-6.4-4.5c-3.9-0.4-6.3,1.6-7.1,3.4c-0.4,0.7,0,1.6,0.7,2c0.8,0.3,1.7,0,2-0.7
                        c0.4-0.9,1.7-1.9,4.1-1.6C54.1,49.8,55.2,51,55.5,52z M75.1,43.5c-3.9-0.3-6.3,1.6-7.1,3.4c-0.4,0.7,0,1.6,0.7,2
                        c0.8,0.3,1.7,0,2-0.7c0.4-0.9,1.7-1.9,4.1-1.6c2.3,0.2,3.4,1.4,3.7,2.4c0.2,0.6,0.8,1.1,1.4,1.1c0.1,0,0.3,0,0.4-0.1
                        c0.8-0.2,1.3-1.1,1-1.9C80.8,46.1,78.9,43.9,75.1,43.5z M76.2,61c-8,2.8-15.3,2.8-22,0c-0.5-0.2-1.1-0.1-1.6,0.3
                        c-0.4,0.4-0.6,1-0.5,1.5c1,3.7,3.7,8.1,7.3,9.8c1,0.5,2.1,0.7,3.2,0.7c1,0,2.1-0.2,3.1-0.7c3.9-1.7,7-4.6,9.3-6.8
                        c1-0.9,1.9-1.8,2.6-2.3c0.6-0.4,0.8-1.2,0.4-1.9C77.6,61,76.9,60.7,76.2,61z M64.5,69.9c-1.3,0.6-2.5,0.6-3.8,0
                        c-1.8-0.9-3.5-2.9-4.6-5c4.7,1.3,9.7,1.5,14.9,0.6C69.2,67.1,67,68.8,64.5,69.9z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Video Calling',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M76.9,0H23.1c-2.8,0-5,2.2-5,5v90c0,2.8,2.3,5,5,5h53.8c2.8,0,5-2.2,5-5V53.3h-3.4V70H68.5V51.7c0-4.6-3.8-8.3-8.4-8.3
                        h-3.6c2.7-1.5,4.8-3.8,6-6.7h16.2v6.7h3.4V5C81.9,2.2,79.7,0,76.9,0z M78.6,73.3V95c0,0.9-0.8,1.7-1.7,1.7H23.1
                        c-0.9,0-1.7-0.7-1.7-1.7V73.3H78.6z M60.1,46.7c2.8,0,5,2.2,5,5V70h-4.8l1.5-13.1c0.1-0.9-0.6-1.7-1.5-1.8
                        c-0.9-0.1-1.8,0.6-1.9,1.5L56.9,70H43.1l-1.5-13.5c-0.1-0.9-0.9-1.6-1.9-1.5c-0.9,0.1-1.6,0.9-1.5,1.8L39.7,70h-4.8V51.7
                        c0-2.8,2.3-5,5-5H60.1z M50,41.7c-3,0-5.8-1.3-7.7-3.5c-1.9-2.3-2.7-5.2-2.2-8.2c0.7-3.9,3.7-7.1,7.7-8c0.8-0.2,1.5-0.3,2.3-0.3
                        c0.6,0,1.1,0,1.7,0.1v9.9c0,2.8,2.3,5,5,5h2C57,39.7,53.6,41.7,50,41.7z M71.9,33.3H61.8V30c0-0.9,0.8-1.7,1.7-1.7h6.7
                        c0.9,0,1.7,0.7,1.7,1.7V33.3z M63.5,20c0-1.8,1.5-3.3,3.4-3.3c1.9,0,3.4,1.5,3.4,3.3c0,1.8-1.5,3.3-3.4,3.3
                        C65,23.3,63.5,21.8,63.5,20z M78.6,33.3h-3.4V30c0-2.4-1.8-4.5-4.1-4.9c1.5-1.2,2.4-3,2.4-5.1c0-3.7-3-6.7-6.7-6.7
                        c-3.7,0-6.7,3-6.7,6.7c0,2.1,1,3.9,2.4,5.1c-2.3,0.4-4.1,2.5-4.1,4.9v3.3h-1.7c-0.9,0-1.7-0.7-1.7-1.7V10h23.5V33.3z M78.6,6.7
                        H38.2V10h13.5v8.5c-1.6-0.2-3.1-0.1-4.7,0.2c-5.2,1.2-9.4,5.5-10.2,10.7c-0.7,3.9,0.4,7.9,3,10.9c1.1,1.3,2.4,2.3,3.8,3.1h-3.6
                        c-4.6,0-8.4,3.7-8.4,8.3V70H21.4V10h6.7V6.7h-6.7V5c0-0.9,0.8-1.7,1.7-1.7h53.8c0.9,0,1.7,0.7,1.7,1.7V6.7z M78.6,50h3.4v-3.3
                        h-3.4V50z M31.5,10h3.4V6.7h-3.4V10z M50,93.3c4.6,0,8.4-3.7,8.4-8.3c0-4.6-3.8-8.3-8.4-8.3c-4.6,0-8.4,3.7-8.4,8.3
                        C41.6,89.6,45.4,93.3,50,93.3z M50,80c2.8,0,5,2.2,5,5c0,2.8-2.3,5-5,5c-2.8,0-5-2.2-5-5C45,82.2,47.2,80,50,80z M51.7,83.3h-3.4
                        v3.3h3.4V83.3z M36.5,83.3H26.5v3.3h10.1V83.3z M73.5,83.3H63.5v3.3h10.1V83.3z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Push Notifications',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M73.2,54.5c-0.9,0-1.5,0.6-1.5,1.5v36.8c0,2.3-1.8,4.2-4.2,4.2H7.3C4.8,97,3,95.2,3,92.7V7.3C3,4.8,4.8,3,7.3,3h60.3
                        c2.3,0,4.2,1.8,4.2,4.2c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5c0-3.9-3.2-7.3-7.3-7.3H7.3C3.2,0,0,3.2,0,7.3v85.6
                        c0,3.9,3.2,7.1,7.3,7.1h60.3c3.9,0,7.3-3.2,7.3-7.3V56.1C74.7,55.2,74.1,54.5,73.2,54.5z M55.9,13.2c0-0.9-0.6-1.5-1.5-1.5H11.1
                        c-0.9,0-1.5,0.6-1.5,1.5v67.1c0,0.9,0.6,1.5,1.5,1.5h52.6c0.9,0,1.5-0.6,1.5-1.5V57.9c0-0.9-0.6-1.5-1.5-1.5
                        c-0.9,0-1.5,0.6-1.5,1.5v20.8H12.6V14.7h41.8C55.3,14.7,55.9,13.9,55.9,13.2z M43.8,8.8c0.9,0,1.5-0.6,1.5-1.5
                        c0-0.9-0.6-1.5-1.5-1.5H30.9c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5H43.8z M31.8,89.4c0,3,2.4,5.5,5.5,5.5
                        c3,0,5.5-2.4,5.5-5.5c0-3-2.4-5.5-5.5-5.5C34.2,83.9,31.8,86.4,31.8,89.4z M39.8,89.4c0,1.4-1.1,2.4-2.4,2.4
                        c-1.4,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4C38.8,87,39.8,88,39.8,89.4z M51.2,87.9c-0.9,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h7.7
                        c0.9,0,1.5-0.6,1.5-1.5s-0.6-1.5-1.5-1.5H51.2z M15.8,87.9c-0.9,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h7.7c0.9,0,1.5-0.6,1.5-1.5
                        s-0.6-1.5-1.5-1.5H15.8z M43.9,51.4c0-5-4.1-8.9-8.9-8.9c-5,0-8.9,4.1-8.9,8.9c0,3,1.7,5.9,3.9,7.4c-5.9,0-10.6,4.7-10.6,10.6v3.9
                        c0,0.9,0.6,1.5,1.5,1.5h27.6c0.9,0,1.5-0.6,1.5-1.5v-3.9c0-5.8-4.5-10.5-10.2-10.6C42.3,57.1,43.9,54.4,43.9,51.4z M47,69.2v2.4
                        H22.4v-2.4c0-4.1,3.5-7.6,7.7-7.6h9.2C43.6,61.7,47,65.2,47,69.2z M34.8,57.1c-3.2,0-6.1-2.6-6.1-5.9c0-3.3,2.7-5.9,6.1-5.9
                        s5.9,2.7,5.9,5.9C40.8,54.5,38,57.1,34.8,57.1z M73.5,10C58.8,10,47,19.7,47,31.5c0,5.8,2.9,11.2,7.7,15.2L50.2,58
                        c-0.3,0.6-0.2,1.2,0.5,1.7c0.5,0.5,1.2,0.5,1.7,0.3l15-7.6c2.1,0.5,4.2,0.6,6.2,0.6C88,53,100,43.3,100,31.5
                        C100,19.7,88,10,73.5,10z M73.5,50c-2,0-4.1-0.2-6.2-0.6c-0.3,0-0.8,0-1.1,0.2l-11.8,6.1l3.5-8.8c0.3-0.6,0-1.4-0.5-1.8
                        c-4.8-3.5-7.4-8.3-7.4-13.5C50,21.4,60.6,13,73.5,13C86.4,13,97,21.4,97,31.5C97,41.7,86.4,50,73.5,50z M84.8,35
                        c-1.4-1.8-2.1-3.6-2.3-5.3l-0.2-1.4c-0.3-3.8-3-6.8-6.7-7.4v-3.5c0-0.9-0.6-1.5-1.5-1.5c-0.9,0-1.5,0.6-1.5,1.5v3.5
                        c-3.6,0.6-6.4,3.6-6.7,7.4v0.9c0,2-0.9,4.1-2.4,6.1c-1.1,1.5-2,3.2-2.6,5c-0.2,0.5,0,0.9,0.2,1.4c0.3,0.5,0.8,0.6,1.2,0.6h7.3
                        c0,2.6,2.1,4.5,4.7,4.5c2.6,0,4.5-2,4.7-4.5h7.3c0.5,0,0.9-0.3,1.2-0.6c0.3-0.5,0.3-0.9,0.2-1.4C87,38.3,86.1,36.5,84.8,35z
                        M74.4,43.8c-0.9,0-1.5-0.6-1.5-1.5h3.2C75.9,43.2,75.2,43.8,74.4,43.8z M64.7,39.4v-0.2c0.5-0.8,0.8-1.5,1.4-2.1
                        c1.8-2.4,2.9-5,3.2-7.6v-0.9c0.2-2.7,2.4-4.8,5.2-4.8c2.7,0,5,2.1,5.2,4.8l0.2,1.4c0.2,2.3,1.2,4.7,2.9,7c0.6,0.8,1.1,1.5,1.5,2.4
                        H64.7z"></path>
                      </g>
                    </g>
                  </svg>
					`
				}
			]
		};
	},
	methods: {
		handleClick(idx) {
			this.idx = idx;
			const images = document.querySelectorAll('.ins-msg-content-wrap .contentrow');
			const links = document.querySelectorAll('.ins-msg-tab-wrap li');
			images.forEach((img) => {
				if (img.classList.contains('active')) {
					img.classList.remove('active');
				}
			});
			links.forEach((link) => {
				if (link.classList.contains('active')) {
					link.classList.remove('active');
				}
			});
			images[this.idx].classList.add('active');
			links[this.idx].classList.add('active');
		}
	},
	mounted() {
		const images = document.querySelectorAll('.ins-msg-content-wrap .contentrow');
		const links = document.querySelectorAll('.ins-msg-tab-wrap li');
		images[this.idx].classList.add('active');
		links[this.idx].classList.add('active');
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.ins-msg-content-wrap  {
  flex-basis: 50%;
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
.ins-msg-tab-wrap li {
  min-height: 100px;
}
.ins-msg-tab-wrap li.active,
.ins-msg-tab-wrap li:hover {
	background-color: #0082dd;
}

.ins-msg-tab-wrap li:nth-child(2n + 1) {
	clear: both;
}
.ins-msg-tab-wrap {
	flex-basis: 50%;
}
.ins-msg-tab-wrap li {
	width: calc(50% - 30px);
	float: left;
	display: flex;
	align-items: center;
	padding: 20px 15px;
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	border-radius: 10px;
	margin: 8px 15px;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
</style>