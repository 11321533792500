<template>
<section class="padding-60 bg-light p-relative">
    
      
`  <div class="container mt-5">
      
          <div class="title">
             <span>What Makes Us Best</span>
             <h2>Why Choose Genixbit as Your Mobile App Development Partner</h2>
             <p>Customer-centric mobile app development needs a tech-minded partner, not just a vendor. As a leading mobile app development agency, GenixBit understands the deliverables expected timely delivery with great user experience.</p>
          </div>
          <div class="row">
             <div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
                 <MiniCard v-bind:obj="item" ></MiniCard>
             </div>          
          </div>
    </div>`
</section>
</template>

<script>
import MiniCard from '../AssetComponents/MiniCard'

export default {
    components:{
        MiniCard
    },
    data : ()=>{
        return {
            items : [
                {
                    title : 'Agile Development Methodology',
                    text : 'We follow the Agile development methodology to facilitate rapid product releases with stability and high-quality code.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M15.5,55.1c0,0-13.6-7.8-8.7-24.6c0.7-2,4.6-14.2,19.6-14.6h0.1v2.7c0,0-12-0.8-17,12.2
                            C9,32.7,5.2,44.7,17,52.7l0.1,0.1L15.5,55.1L15.5,55.1z"></path>
                         <path fill="#6a6d6f" d="M37.2,16.6l2.5-2.6c0,0,1-1.5-0.8-2.2c-0.2,0-0.8-0.1-1.3,0.5c-0.5,0.5-4.4,4.4-4.4,4.4s-0.9,1.1,0.4,2.1
                            c0.7,0.7,3.9,3.8,3.9,3.8s0.9,1.1,2,0.2c0.1-0.1,1-1,0-2c-1-0.9-1.4-1.4-1.4-1.4s3.6,0.5,6.9,2.7c1.1,0.8,13.1,8.2,6.7,23.9
                            c-0.9,1.5-4.4,9-14.9,10.4c-5.9,0.1-35.5,0.1-35.5,0.1S0.1,56.4,0,57.8c0,1.4,1.5,1.4,1.5,1.4l36-0.1c0,0,15.8-1.2,18.8-18.5
                            c0.3-2.8,1.4-15.1-12-22C42.8,17.8,38.6,16.5,37.2,16.6z"></path>
                         <path fill="#6a6d6f" d="M53.1,58.3v-2.8L70,55.6l-2.8-2.8c0,0-0.6-1.2,0.5-1.9c0.2-0.1,0.8-0.4,1.6,0.2c0.6,0.7,3.8,3.8,3.8,3.8
                            l0.4,0.4c0,0,1,1.1,0,2.1s-4.2,4.1-4.2,4.1s-1,1.2-2.2-0.1c-0.2-0.4-0.4-1.2,0.4-1.9c0.4-0.5,1.3-1.3,1.3-1.3L53.1,58.3z"></path>
                      </svg>`
                },
                {
                    title : 'Bug-Free Deployment',
                    text : 'Our QA professionals use a combination of manual and automation testing to ensure that the app is released bug-free.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M28.7,49.7c-3.2-1.5-5.7-4.2-7.4-7.5c0-0.1-0.1-0.2-0.1-0.3c-1.3-2.8-2.1-6-2.1-9.4c0-10.2,6.8-18.5,15.1-18.5
                            c5.5,0,10.3,3.6,12.9,9c0,0.1,0.1,0.1,0.1,0.2c1.3,2.7,2,5.9,2,9.3c0,0.9,0.8,1.7,1.7,1.7c0.2,0,0.4,0,0.6-0.1
                            c0.2,0.1,0.4,0.1,0.6,0.1h7c0.9,0,1.7-0.8,1.7-1.7s-0.8-1.7-1.7-1.7h-6.5c-0.2-2.5-0.7-4.8-1.5-7c4.6-1.1,8-5.3,8-10.3
                            c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7c0,3.6-2.7,6.6-6.1,7.1c-2.2-4-5.4-7.1-9.3-8.7L45,2.7c0.4-0.8,0.1-1.9-0.7-2.3
                            C43.4,0,42.4,0.3,42,1.2l-4.8,9.6c0,0,0,0,0,0.1c-1-0.2-2-0.3-3-0.3s-2,0.1-3,0.3c0,0,0,0,0-0.1l-4.8-9.6C26,0.4,25,0,24.1,0.5
                            c-0.8,0.4-1.2,1.5-0.7,2.3l4.6,9.1c-3.8,1.6-7,4.7-9.2,8.7c-3.5-0.5-6.2-3.5-6.2-7.1c0-0.9-0.8-1.7-1.7-1.7s-1.7,0.8-1.7,1.7
                            c0,5,3.5,9.2,8.1,10.3c-0.8,2.2-1.3,4.5-1.5,7H9.2c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7h6.6c0.2,2.9,0.9,5.7,1.9,8.2l-5.2,5.3
                            c-0.7,0.7-0.7,1.8,0,2.4c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5l4.5-4.5c2,3.1,4.7,5.7,7.9,7.2c0.2,0.1,0.5,0.2,0.7,0.2
                            c0.6,0,1.2-0.4,1.5-1C29.9,51.1,29.5,50.1,28.7,49.7z"></path>
                         <path fill="#6a6d6f" d="M55.6,51l-7.4,7.5l-3-3.1c-0.7-0.7-1.7-0.7-2.4,0s-0.7,1.8,0,2.4l4.2,4.3c0.3,0.3,0.7,0.5,1.2,0.5
                            c0.4,0,0.9-0.2,1.2-0.5l8.6-8.7c0.7-0.7,0.7-1.8,0-2.4C57.4,50.3,56.3,50.3,55.6,51z"></path>
                         <path fill="#6a6d6f" d="M50.4,39.2c-9.4,0-17.1,7.8-17.1,17.4S41,73.9,50.4,73.9s17.1-7.8,17.1-17.4S59.9,39.2,50.4,39.2z M50.4,70.5
                            c-7.6,0-13.8-6.3-13.8-14s6.2-14,13.8-14s13.8,6.3,13.8,14S58,70.5,50.4,70.5z"></path>
                      </svg>`
                },
                {
                    title : 'Cost Efficient',
                    text : 'High-quality services and affordability may not be offered together elsewhere. But, for Genixbit it is one of the key USPs.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M24.2,30.4c-1.5-0.5-3.1,0-3.8,1.2l-0.6,0.7v-2.5c0.1-0.7,0.2-1.4,0.5-2l5.1-11.1c0.6-1.2,0.4-2.6-0.6-3.6
                            c-0.7-0.6-1.6-0.9-2.5-0.9c-1,0.1-1.7,0.5-2.3,1.2l-7.5,9.5c-0.7,0.9-1.2,2-1.6,3.1l-2.1,7.5H0v19.8h9.9v-2.5h1.2
                            c8.9,0,12.1-2,12.4-7.8c0-0.4,0.1-0.9,0.2-1.6l2.6-6.8c0.4-1,0.2-1.9-0.1-2.7C25.8,31.3,25,30.7,24.2,30.4z M2.4,51.1V36.2h4.9V51
                            L2.4,51.1L2.4,51.1z M24.1,34.1l-2.6,6.8c-0.4,0.7-0.5,1.5-0.5,2.2c-0.1,3.3-0.9,5.4-9.9,5.4H9.8v-9.8L13.2,27
                            c0.2-0.9,0.6-1.6,1.1-2.2l7.5-9.5C22,15,22.2,15,22.3,15s0.2,0,0.5,0.2c0.1,0.1,0.2,0.5,0.1,0.7L17.8,27c-0.4,1.1-0.5,2.1-0.6,3v9.8
                            l5.2-6.5c0.2-0.4,0.7-0.5,1.1-0.4c0.2,0.1,0.4,0.2,0.5,0.5S24.2,33.9,24.1,34.1z"></path>
                         <path fill="#6a6d6f" d="M65.3,33.8l-2-7.4c-0.4-1.1-0.9-2.1-1.6-3.1l-7.5-9.5c-0.6-0.7-1.5-1.2-2.3-1.2c-1-0.1-1.9,0.2-2.5,0.9
                            c-0.9,1-1.1,2.3-0.6,3.6l5.1,11.1c0.4,0.7,0.5,1.4,0.5,2v2.5l-0.5-0.6c-0.9-1.2-2.5-1.9-4-1.4c-0.9,0.2-1.6,0.9-2,1.7
                            c-0.4,0.9-0.5,1.9-0.1,2.7l2.6,6.8c0.1,0.5,0.2,1,0.2,1.5c0.2,5.8,3.5,7.8,12.4,7.8h1.2v2.5h9.9V33.8H65.3z M63.2,48.6
                            c-9,0-9.8-2.2-9.9-5.4c-0.1-0.7-0.2-1.5-0.5-2.2l-2.6-6.8c-0.1-0.2-0.1-0.5,0-0.7c0-0.2,0.2-0.4,0.5-0.5c0,0,0.1,0,0.2,0
                            c0.2,0,0.6,0.2,0.9,0.5l4.9,6.4V30c0-0.7-0.1-1.9-0.6-2.8l-5-11.2c-0.1-0.2-0.1-0.5,0.1-0.7c0.1-0.2,0.4-0.2,0.5-0.2s0.4,0,0.5,0.2
                            l7.9,9.4c0.5,0.6,0.9,1.4,1.1,2.2l3,11.1v10.6H63.2z M71.6,51.1h-4.9V36.2h4.9V51.1z"></path>
                         <path fill="#6a6d6f" d="M42,26.4h2.5c0-3.7-2.7-6.7-6.2-7.3v-2.6h-2.5v2.6c-3.5,0.6-6.2,3.7-6.2,7.3s2.7,6.7,6.2,7.3v9.8
                            c-2.1-0.5-3.7-2.5-3.7-4.8h-2.5c0,3.7,2.7,6.7,6.2,7.3v2.7h2.5V46c3.5-0.6,6.2-3.7,6.2-7.3s-2.7-6.7-6.2-7.3v-9.8
                            C40.4,22.1,42,24,42,26.4z M32.1,26.4c0-2.3,1.6-4.2,3.7-4.8V31C33.7,30.6,32.1,28.7,32.1,26.4z M42,38.7c0,2.3-1.6,4.2-3.7,4.8V34
                            C40.4,34.5,42,36.4,42,38.7z"></path>
                      </svg>`
                },
                {
                    title : 'Expert Native Developers',
                    text : 'Your apps will be built by qualified, experienced and passionate iPhone app developers who see their work as pieces of art.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M16.4,29.7c0-4.4,0-8.7,0-13.1c0-0.9,0-1.9,0-2.8c0-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5c0,0.3,0-0.1,0-0.1
                            c0-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.3c-0.1,0.2,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.2-0.3,0.1-0.1
                            c0.1-0.2,0.3-0.3,0.5-0.5c0,0,0.3-0.2,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.2-0.1
                            c-0.1,0-0.1,0,0,0c0.2-0.1,0.4-0.1,0.7-0.2c0.4-0.1-0.2,0,0.1,0c0.1,0,0.2,0,0.4,0l0,0c0.2,0,0.5,0,0.7,0c3.5,0,7.1,0,10.6,0
                            c5.6,0,11.2,0,16.8,0c5,0,10,0,15,0c1.5,0,2.9,0,4.4,0c0.3,0,0.6,0,1,0c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.4,0c-0.3,0,0.1,0,0.1,0
                            c0.2,0,0.5,0.1,0.7,0.2c-0.2-0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c-0.2-0.1,0.2,0.1,0.2,0.2
                            c0.1,0.1,0.2,0.2,0.2,0.2s0.2,0.3,0.1,0.1c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0.2,0.4,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3
                            s0,0.2,0,0.2c0-0.1,0-0.1,0,0c0,0.2,0,0.4,0,0.5c0,0.5,0,1,0,1.6c0,2.2,0,4.4,0,6.6c0,5.4,0,10.7,0,16.1c0,1.1,0,2.2,0,3.2
                            c0,0.1,0,0.1,0,0.2c0,0,0,0.4,0,0.2c0,0.2-0.1,0.5-0.2,0.7c0,0-0.1,0.4,0,0.1c0,0.1-0.1,0.2-0.2,0.3s-0.1,0.2-0.2,0.3
                            c0,0.1-0.1,0.1-0.1,0.2c0.1-0.1,0.1-0.1,0,0c-0.1,0.2-0.3,0.3-0.5,0.5c-0.3,0.2,0.2-0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
                            c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2,0.2-0.1-0.1,0.1c-0.3,0.1-0.5,0.2-0.8,0.2c0.3,0-0.1,0-0.2,0s-0.2,0-0.2,0l0,0h-0.1
                            c-0.1,0-0.3,0-0.4,0c-3.6,0-7.2,0-10.8,0c-5.6,0-11.3,0-16.9,0c-4,0-8.1,0-12.1,0c-0.4,0-0.7,0-1.1,0c-0.7,0-1.2,0.6-1.2,1.2
                            c0,0.7,0.6,1.2,1.2,1.2c2.6,0,5.3,0,7.9,0c5.5,0,11,0,16.5,0c4.7,0,9.4,0,14.2,0c0.9,0,1.9,0,2.8,0c3.2,0,5.7-2.7,5.7-5.8
                            c0-4.1,0-8.1,0-12.2c0-4.8,0-9.5,0-14.3c0-0.3,0-0.6,0-1c0-2.1-1.2-4.1-3.1-5.1c-1.2-0.6-2.5-0.6-3.8-0.6c-3.8,0-7.6,0-11.5,0
                            c-5.6,0-11.3,0-16.9,0c-4.8,0-9.6,0-14.4,0c-1.3,0-2.5,0-3.8,0c-0.2,0-0.4,0-0.6,0c-2,0-3.9,1.1-4.9,2.8s-0.8,4-0.8,6
                            c0,2.8,0,5.5,0,8.3c0,1.7,0,3.4,0,5.1c0,0.7,0.6,1.2,1.2,1.2C15.9,30.9,16.4,30.4,16.4,29.7L16.4,29.7z"></path>
                         <path fill="#6a6d6f" d="M47.7,45.3c0,3.7,0,7.4,0,11.1c0.4-0.4,0.8-0.8,1.2-1.2c-3.3,0-6.6,0-10,0c0.4,0.4,0.8,0.8,1.2,1.2
                            c0-3.7,0-7.4,0-11.1c-0.4,0.4-0.8,0.8-1.2,1.2c3.3,0,6.6,0,10,0c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2c-3.3,0-6.6,0-10,0
                            c-0.7,0-1.2,0.6-1.2,1.2c0,3.7,0,7.4,0,11.1c0,0.7,0.6,1.2,1.2,1.2c3.3,0,6.6,0,10,0c0.7,0,1.2-0.6,1.2-1.2c0-3.7,0-7.4,0-11.1
                            c0-0.7-0.6-1.2-1.2-1.2C48.2,44,47.7,44.6,47.7,45.3z"></path>
                         <path fill="#6a6d6f" d="M33.2,57.6c3.9,0,7.7,0,11.6,0c3.8,0,7.6,0,11.4,0c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2
                            c-3.9,0-7.7,0-11.6,0c-3.8,0-7.6,0-11.4,0c-0.7,0-1.2,0.6-1.2,1.2C32,57,32.6,57.6,33.2,57.6L33.2,57.6z"></path>
                         <path fill="#6a6d6f" d="M27.1,38.9c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c0,0.4,0-0.2,0,0.1c-0.1,0.5-0.2,1-0.4,1.5
                            c0,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0.4,0,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.4-0.5,0.9-0.8,1.3c-0.2,0.3,0.1-0.2-0.1,0.1
                            c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.3-0.5,0.5s-0.3,0.3-0.5,0.5c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1,0,0
                            c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.3-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.2-0.6,0.3c0.2-0.1-0.1,0-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.2
                            c-0.4,0.1-0.9,0.3-1.4,0.3c-0.4,0.1,0.2,0-0.1,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6,0-0.8,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.4,0
                            c0.3,0-0.1,0-0.1,0c-0.5-0.1-1-0.2-1.5-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1,0.2,0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
                            c-0.4-0.2-0.9-0.5-1.2-0.8c0.2,0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.3-0.5-0.5s-0.3-0.3-0.5-0.5
                            c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.2-0.1-0.2-0.1-0.2c-0.3-0.4-0.5-0.8-0.7-1.2c-0.1-0.1-0.1-0.2-0.1-0.3c0,0-0.2-0.4-0.1-0.1
                            c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.5-0.2-0.9-0.3-1.4c0,0.3,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4c0-0.3,0-0.6,0-0.9c0-0.2,0-0.5,0-0.7
                            c0,0,0.1-0.4,0-0.1c0-0.1,0-0.2,0.1-0.4c0.1-0.5,0.2-1,0.4-1.5c0-0.1,0.1-0.2,0.1-0.3c-0.1,0.2,0.1-0.1,0.1-0.1
                            c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.3-0.4,0.1-0.2c0.3-0.4,0.6-0.7,0.9-1
                            c0.2-0.2,0.3-0.3,0.5-0.5c0,0,0.3-0.2,0.1-0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.3,0.8-0.5,1.2-0.7c0.1-0.1,0.2-0.1,0.3-0.1
                            c0.3-0.2-0.2,0.1,0.1-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.5-0.1,0.7-0.2c0.1,0,0.2,0,0.3-0.1c0,0,0.4-0.1,0.1,0
                            c0.5-0.1,1-0.1,1.6-0.1c0.2,0,0.5,0,0.7,0.1c0.4,0-0.2,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.4c0,0,0.4,0.1,0.1,0
                            c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.3,0.2,0.1,0.1
                            c0.4,0.3,0.7,0.6,1,0.9c0.2,0.2,0.3,0.3,0.5,0.5c0,0,0.2,0.3,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.5,0.8,0.7,1.2
                            c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0.2,0.4,0.1,0.1c0.1,0.2,0.2,0.4,0.2,0.7c0.1,0.4,0.2,0.9,0.3,1.4c0.1,0.4,0-0.2,0,0.1
                            c0,0.1,0,0.2,0,0.4C27.1,38.4,27.1,38.6,27.1,38.9c0,0.7,0.6,1.2,1.2,1.2c0.7,0,1.2-0.6,1.2-1.2c0-4.2-2.5-8.2-6.3-9.9
                            c-4-1.8-8.6-1-11.8,1.9c-3.1,2.8-4.3,7.5-2.9,11.5c1.4,4.1,5,6.9,9.3,7.3c3,0.3,6-0.8,8.3-2.8c2.2-2,3.5-5,3.5-8
                            c0-0.7-0.6-1.2-1.2-1.2C27.6,37.6,27.1,38.2,27.1,38.9z"></path>
                         <path fill="#6a6d6f" d="M37.4,66.3c0-4.1-1.3-8.1-3.8-11.3s-5.9-5.6-9.8-6.7c-3.9-1.1-8.3-0.9-12.1,0.7c-3.7,1.6-6.9,4.3-9,7.7
                            C1,59.6,0.1,63,0.1,66.3c0,0.7,0.6,1.2,1.2,1.2c3,0,6,0,9,0c5.6,0,11.2,0,16.8,0c3,0,6,0,9,0C36.8,67.5,37.4,66.9,37.4,66.3
                            S36.8,65,36.1,65c-3,0-6,0-9,0c-5.6,0-11.2,0-16.8,0c-3,0-6,0-9,0c0.4,0.4,0.8,0.8,1.2,1.2c0-0.5,0-1,0.1-1.5c0-0.2,0-0.5,0.1-0.7
                            c0,0.3,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.5c0.2-1,0.4-1.9,0.8-2.8c0.2-0.5,0.3-0.7,0.5-1.1c0.2-0.5,0.4-0.9,0.7-1.3
                            C5,57.8,5.3,57.4,5.5,57c0.1-0.2,0.3-0.4,0.4-0.6c0,0,0.2-0.3,0.1-0.1c0-0.1,0.1-0.2,0.2-0.3c0.6-0.7,1.3-1.4,2-2s1.3-1,2.1-1.6
                            c0.4-0.2,0.8-0.5,1.2-0.7c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3-0.1c-0.2,0.1,0.2-0.1,0.2-0.1c0.9-0.4,1.8-0.6,2.8-0.8
                            c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.4-0.1c0.4-0.1-0.2,0,0.1,0c0.5-0.1,1-0.1,1.6-0.1c0.5,0,1,0,1.5,0c0.2,0,0.5,0,0.7,0.1
                            c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0,0c0.1,0,0.2,0,0.2,0c1,0.1,1.9,0.4,2.9,0.7c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.2,0.1,0.3,0.1
                            c0,0,0.4,0.2,0.1,0.1c0.5,0.2,0.9,0.4,1.3,0.7c0.4,0.2,0.8,0.5,1.2,0.7c0.2,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.2,0.1,0.3,0.2
                            c0,0,0.4,0.3,0.2,0.2c0.7,0.6,1.4,1.2,2.1,1.9c0.3,0.3,0.6,0.7,0.9,1c-0.2-0.2,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3
                            c0.2,0.2,0.3,0.4,0.4,0.7c0.5,0.8,1,1.7,1.4,2.5c-0.1-0.2,0,0.1,0.1,0.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.5,0.3,0.8
                            c0.2,0.6,0.3,1.1,0.4,1.7c0.1,0.3,0.1,0.5,0.2,0.8c0,0.1,0,0.2,0.1,0.4c0-0.2,0,0.2,0,0.3c0.1,0.6,0.1,1.2,0.1,1.8
                            c0,0.7,0.6,1.2,1.2,1.2C36.8,67.5,37.4,66.9,37.4,66.3z"></path>
                         <path fill="#6a6d6f" d="M57.7,26.3c0-1.6-2-3.2-5.2-4.2c0.7-3.2,0.4-5.8-1-6.6c-0.3-0.2-0.7-0.3-1.1-0.3v1.1c0.2,0,0.4,0,0.6,0.1
                            c0.7,0.4,1,1.9,0.7,3.8c-0.1,0.5-0.1,1-0.3,1.5c-1-0.2-2.1-0.4-3.2-0.6c-0.7-0.9-1.4-1.8-2.1-2.5c1.6-1.5,3.2-2.4,4.2-2.4v-1.1l0,0
                            c-1.4,0-3.2,1-5,2.7c-1.8-1.7-3.6-2.7-5-2.7v1.1c1,0,2.6,0.8,4.2,2.4c-0.7,0.7-1.4,1.6-2.1,2.5c-1.1,0.1-2.2,0.3-3.2,0.6
                            c-0.1-0.5-0.2-1-0.3-1.5c-0.2-1.9,0.1-3.4,0.7-3.8c0.2-0.1,0.3-0.1,0.6-0.1v-1.1l0,0c-0.4,0-0.8,0.1-1.1,0.3c-1.4,0.8-1.7,3.4-1,6.6
                            c-3.1,1-5.2,2.5-5.2,4.2c0,1.6,2,3.2,5.2,4.2c-0.7,3.2-0.4,5.8,1,6.6c0.3,0.2,0.7,0.3,1.1,0.3c1.4,0,3.2-1,5-2.7
                            c1.8,1.7,3.6,2.7,5,2.7c0.4,0,0.8-0.1,1.1-0.3c1.4-0.8,1.7-3.4,1-6.6C55.7,29.5,57.7,28,57.7,26.3z M51.2,22.9
                            c-0.2,0.7-0.4,1.3-0.7,2c-0.2-0.4-0.4-0.8-0.7-1.2c-0.2-0.4-0.5-0.8-0.7-1.2C49.8,22.7,50.5,22.8,51.2,22.9z M48.9,28.3
                            c-0.4,0.7-0.8,1.3-1.2,1.9c-0.7,0.1-1.5,0.1-2.3,0.1s-1.5,0-2.3-0.1c-0.4-0.6-0.8-1.2-1.2-1.9c-0.4-0.7-0.7-1.3-1-2
                            c0.3-0.7,0.7-1.4,1-2c0.4-0.7,0.8-1.3,1.2-1.9c0.7-0.1,1.5-0.1,2.3-0.1s1.5,0,2.3,0.1c0.4,0.6,0.8,1.2,1.2,1.9c0.4,0.7,0.7,1.3,1,2
                            C49.6,27,49.2,27.7,48.9,28.3z M50.5,27.7c0.3,0.7,0.5,1.4,0.7,2c-0.7,0.2-1.4,0.3-2.1,0.4c0.2-0.4,0.5-0.8,0.7-1.2
                            C50.1,28.5,50.3,28.1,50.5,27.7z M45.4,33.1c-0.5-0.5-0.9-1-1.4-1.6c0.5,0,0.9,0,1.4,0s0.9,0,1.4,0C46.3,32.1,45.8,32.6,45.4,33.1z
                            M41.6,30.1c-0.7-0.1-1.4-0.2-2.1-0.4c0.2-0.7,0.4-1.3,0.7-2c0.2,0.4,0.4,0.8,0.7,1.2C41.2,29.3,41.4,29.7,41.6,30.1z M45.4,19.6
                            c0.5,0.5,0.9,1,1.4,1.6c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0C44.4,20.6,44.9,20,45.4,19.6z M41.6,22.5c-0.2,0.4-0.5,0.8-0.7,1.2
                            c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3-0.7-0.5-1.4-0.7-2C40.2,22.8,40.9,22.7,41.6,22.5z M37.1,28.9c-1.8-0.8-2.9-1.8-2.9-2.6
                            s1.2-1.8,2.9-2.6c0.4-0.2,0.9-0.4,1.4-0.5c0.3,1,0.7,2,1.1,3.1c-0.5,1.1-0.8,2.1-1.1,3.1C38,29.2,37.5,29.1,37.1,28.9z M39.8,36.1
                            c-0.7-0.4-1-1.9-0.7-3.8c0.1-0.5,0.1-1,0.3-1.5c1,0.2,2.1,0.4,3.2,0.6c0.7,0.9,1.4,1.8,2.1,2.5c-1.6,1.5-3.2,2.4-4.2,2.4
                            C40.1,36.3,39.9,36.2,39.8,36.1z M51.7,32.3c0.2,1.9-0.1,3.4-0.7,3.8c-0.2,0.1-0.3,0.1-0.6,0.1c-1,0-2.6-0.8-4.2-2.4
                            c0.7-0.7,1.4-1.6,2.1-2.5c1.1-0.1,2.2-0.3,3.2-0.6C51.6,31.3,51.7,31.8,51.7,32.3z M53.7,28.9c-0.4,0.2-0.9,0.4-1.4,0.5
                            c-0.3-1-0.7-2-1.1-3.1c0.5-1.1,0.8-2.1,1.1-3.1c0.5,0.2,1,0.3,1.4,0.5c1.8,0.8,2.9,1.8,2.9,2.6C56.6,27.1,55.4,28.1,53.7,28.9z"></path>
                         <path fill="#6a6d6f" d="M40.3,15.3L40.3,15.3L40.3,15.3z"></path>
                         <ellipse fill="#6a6d6f" cx="45.4" cy="26.3" rx="2.3" ry="2.3"></ellipse>
                         <path fill="#6a6d6f" d="M50.4,15.3L50.4,15.3L50.4,15.3z"></path>
                      </svg>`
                },
                {
                    title : 'Inspirational UI/UX',
                    text : 'Our UI/UX designs are inspired by the habits and actions that users have become accustomed to.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M56.7,56.7h-2.4v4.9h-22V64h21.9v3.7c0,2-1.6,3.7-3.7,3.7H23.8c-1.3,0-2.5-0.7-3.2-1.8l-2.1,1.2
                            c1.1,1.9,3.1,3.1,5.3,3.1h26.8c3.4,0,6.1-2.8,6.1-6.2C56.7,67.7,56.7,56.7,56.7,56.7z"></path>
                         <path fill="#6a6d6f" d="M43.3,33.3v-2.5h-3.7C39,23,32.8,16.7,25,16.1v-3.8h-2.4v3.8c-0.8,0.1-1.6,0.2-2.4,0.4V9.9h19.5V7.4H20.2V6.2
                            c0-2,1.6-3.7,3.7-3.7h26.8c0.9,0,1.7,0.3,2.4,0.9l1.6-1.8c-1.1-1-2.5-1.5-4-1.5H23.8c-3.4,0-6.1,2.8-6.1,6.2v11.1
                            c-5.5,2.3-9.2,7.5-9.7,13.5H4.3v2.5H8c0.6,7.9,6.8,14.1,14.5,14.7v3.7H25V48c7.8-0.6,14-6.8,14.5-14.7H43.3z M22.6,43.1v2.4
                            c-1.7-0.2-3.4-0.6-4.9-1.4v-2.2c0-3.4,2.7-6.2,6.1-6.2s6.1,2.8,6.1,6.2v2.2c-1.5,0.8-3.2,1.3-4.9,1.4v-2.4H22.6z M20.2,29.6
                            c0-2,1.6-3.7,3.7-3.7s3.7,1.7,3.7,3.7s-1.6,3.7-3.7,3.7S20.2,31.6,20.2,29.6z M32.7,42.1c-0.1,0.1-0.3,0.2-0.4,0.3v-0.6
                            c0-3.2-1.8-6.2-4.6-7.6c2.6-2.2,2.9-6.1,0.7-8.7s-6-2.9-8.6-0.7s-2.9,6.1-0.7,8.7c0.2,0.3,0.5,0.5,0.7,0.7c-2.8,1.5-4.6,4.4-4.6,7.6
                            v0.6c-0.1-0.1-0.3-0.2-0.4-0.3c-2.5-2.3-4.1-5.5-4.4-8.9h2.4v-2.5h-2.4c0.6-6.5,5.7-11.6,12.1-12.2V21H25v-2.4
                            c6.4,0.6,11.5,5.7,12.1,12.2h-2.4v2.5h2.4C36.8,36.7,35.3,39.9,32.7,42.1L32.7,42.1z"></path>
                         <path fill="#6a6d6f" d="M70.1,22.2c0.7,0,1.2-0.6,1.2-1.2V6.2c0-0.7-0.5-1.2-1.2-1.2H43.3c-0.7,0-1.2,0.6-1.2,1.2V21
                            c0,0.7,0.5,1.2,1.2,1.2H70.1z M44.5,7.4h24.4v12.3H44.5V7.4z"></path>
                         <rect x="47" y="9.9" fill="#6a6d6f" width="4.9" height="2.5"></rect>
                         <rect x="54.3" y="9.9" fill="#6a6d6f" width="12.2" height="2.5"></rect>
                         <rect x="47" y="14.8" fill="#6a6d6f" width="19.5" height="2.5"></rect>
                         <path fill="#6a6d6f" d="M69.9,24.7c-0.9,0-1.9,0.4-2.6,1l-10.6,9.5V24.7h-2.4V37h0.4l-6.9,6.2c-4.5,0.3-8,4-8,8.6V54
                            c0,1.2-0.7,2.3-1.8,2.9c-0.6,0.3-0.8,1-0.5,1.7c0.2,0.4,0.6,0.7,1.1,0.7H47c4.5,0,8.2-3.6,8.5-8.1l17.3-19.8
                            c1.4-1.6,1.3-4.1-0.3-5.5C71.7,25,70.8,24.7,69.9,24.7z M47,56.7h-5.6c0.5-0.8,0.7-1.8,0.7-2.8v-2.2c0-3.2,2.4-5.9,5.6-6.1L53,51
                            C52.8,54.2,50.1,56.7,47,56.7z M71,29.5L54.2,48.7L50,44.4l19-16.9c0.6-0.5,1.5-0.5,2,0.1C71.4,28.2,71.4,29,71,29.5z"></path>
                         <path fill="#6a6d6f" d="M1.9,67.7h26.8c0.7,0,1.2-0.6,1.2-1.2v-9.8c0-0.7-0.5-1.2-1.2-1.2h-8.5v-6.2h-2.4v6.2H1.9
                            c-0.7,0-1.2,0.6-1.2,1.2v9.8C0.7,67.2,1.2,67.7,1.9,67.7z M3.1,57.9h24.4v7.4H3.1V57.9z"></path>
                         <rect x="5.6" y="60.4" fill="#6a6d6f" width="6.1" height="2.5"></rect>
                         <rect x="14.1" y="60.4" fill="#6a6d6f" width="11" height="2.5"></rect>
                         <path fill="#6a6d6f" d="M1.9,17.3h12.2c0.7,0,1.2-0.6,1.2-1.2V6.2c0-0.7-0.5-1.2-1.2-1.2H1.9C1.2,5,0.7,5.5,0.7,6.2V16
                            C0.7,16.7,1.2,17.3,1.9,17.3z M3.1,7.4h9.7v7.4H3.1V7.4z"></path>
                         <rect x="5.6" y="9.9" fill="#6a6d6f" width="4.9" height="2.5"></rect>
                      </svg>`
                },
                {
                    title : 'Non-disclosure Agreement',
                    text : 'Every project would be accompanied with an NDA where we agree not to share your project details to any outsider.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <polygon fill="#6a6d6f" points="63.3,74.1 0.2,74.1 0.2,0.1 63.3,0.1 63.3,23.7 60.3,23.7 60.3,3.2 3.3,3.2 3.3,71 60.3,71 60.3,37.5 
                            63.3,37.5 "></polygon>
                         <path fill="#6a6d6f" d="M44.6,66.7l3.1-16.5l18-37.6c0.6-1.5,2.9-1.8,5.2-0.8c1.4,0.5,2.5,1.5,2.9,2.6c0.3,0.6,0.2,1.4,0,1.8L55.6,54.3
                            L44.6,66.7z M50.6,51.3l-0.9,5.2l3.5-4l17.7-37.1c-0.2-0.2-0.6-0.5-1.1-0.8c-0.8-0.3-1.2-0.5-1.4-0.5L50.6,51.3z"></path>
                         <rect x="14.2" y="12.9" fill="#6a6d6f" width="32.9" height="3.1"></rect>
                         <rect x="14.2" y="24.4" fill="#6a6d6f" width="32.9" height="3.1"></rect>
                         <rect x="14.2" y="36" fill="#6a6d6f" width="32.9" height="3.1"></rect>
                         <text transform="matrix(0.9659 0 0 1 12.7153 59.7729)" class="st0 st1 st2">NDA</text>
                      </svg>`
                },
                {
                    title : 'Transparent & Competitive',
                    text : 'Our devs, tech leads and rest of the team take pride in delivering what is promised to the client.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M9.4,57c4.2,0,7.7-4.1,7.7-9.2s-3.4-9.2-7.7-9.2s-7.7,4.1-7.7,9.2S5.2,57,9.4,57z M9.4,41.7
                            c2.5,0,4.6,2.8,4.6,6.1c0,3.4-2.1,6.1-4.6,6.1s-4.6-2.8-4.6-6.1C4.8,44.4,6.9,41.7,9.4,41.7z"></path>
                         <path fill="#6a6d6f" d="M64.7,57c4.2,0,7.7-4.1,7.7-9.2s-3.4-9.2-7.7-9.2S57,42.7,57,47.8S60.4,57,64.7,57z M64.7,41.7
                            c2.5,0,4.6,2.8,4.6,6.1c0,3.4-2.1,6.1-4.6,6.1s-4.6-2.8-4.6-6.1C60,44.4,62.1,41.7,64.7,41.7z"></path>
                         <path fill="#6a6d6f" d="M27.8,47.8c0,5.1,4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2s-4.1-9.2-9.2-9.2S27.8,42.7,27.8,47.8z M43.2,47.8
                            c0,3.4-2.8,6.1-6.1,6.1c-3.4,0-6.1-2.8-6.1-6.1c0-3.4,2.8-6.1,6.1-6.1S43.2,44.4,43.2,47.8z"></path>
                         <path fill="#6a6d6f" d="M14,32.5h7.2l-1.1,4.2c-0.1,0.6,0.1,1.2,0.6,1.6c0.3,0.2,0.6,0.3,0.9,0.3s0.5-0.1,0.8-0.2l10.4-5.9h14.9
                            c5.4,0,9.2-3.2,9.2-7.7V7.9c0-4.2-3.4-7.7-7.7-7.7H14c-4.2,0-7.7,3.4-7.7,7.7v16.9C6.3,29,9.8,32.5,14,32.5z M9.4,7.9
                            c0-2.5,2.1-4.6,4.6-4.6h35.3c2.5,0,4.6,2.1,4.6,4.6v16.9c0,2.8-2.4,4.6-6.1,4.6H32.4c-0.3,0-0.5,0.1-0.8,0.2L24,34l0.7-2.6
                            c0.1-0.5,0-0.9-0.3-1.3s-0.7-0.6-1.2-0.6H14c-2.5,0-4.6-2.1-4.6-4.6V7.9z"></path>
                         <path fill="#6a6d6f" d="M64.7,58.6c-1.3,0-2.5,0.3-3.7,0.8c-0.5,0.2-1,0.5-1.5,0.9l0,0l-6.9,2.6c-0.3,0-0.6,0.1-0.8,0.1
                            c-2-2.7-5.2-4.4-8.7-4.4H30.9c-3.4,0-6.5,1.6-8.6,4.3c-0.1,0-0.2-0.1-0.4-0.1l-7.3-2.6c-0.5-0.4-1-0.7-1.6-0.9
                            c-1.2-0.5-2.4-0.8-3.7-0.8c-5.1,0-9.2,4.1-9.2,9.2v4.6c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-4.6c0-3.4,2.8-6.1,6.1-6.1
                            c0.9,0,1.7,0.2,2.5,0.5c0.4,0.2,0.8,0.4,1.2,0.7c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1l7.4,2.6c0.9,0.4,1.9,0.6,2.9,0.6H27
                            c0.3,0,0.5,0.2,0.6,0.3c0.1,0.1,0.2,0.4,0.1,0.7c-0.1,0.3-0.4,0.5-0.7,0.5h-0.8H14.6l-1.1-1.1c-0.1-0.1-0.3-0.3-0.5-0.3
                            c-0.4-0.2-0.8-0.2-1.2,0s-0.7,0.5-0.8,0.8c-0.1,0.2-0.1,0.4-0.1,0.6v4.6c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-1.5h7.7v1.5
                            c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-1.5h1.5H27h20h0.8h1.5v1.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-1.5H60v1.5
                            c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-4.6c0-0.2,0-0.4-0.1-0.6c-0.2-0.4-0.5-0.7-0.8-0.8c-0.4-0.2-0.8-0.2-1.2,0
                            c-0.2,0.1-0.4,0.2-0.5,0.3l-1.1,1.1H47.8H47c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.3,0-0.6,0.1-0.7s0.3-0.3,0.6-0.3h3.6
                            c1,0,2-0.2,2.9-0.5l6.8-2.6c0.2,0,0.4-0.1,0.6-0.3c0.4-0.3,0.8-0.5,1.2-0.7c0.8-0.3,1.6-0.5,2.5-0.5c3.4,0,6.1,2.8,6.1,6.1v4.6
                            c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-4.6C73.9,62.7,69.7,58.6,64.7,58.6z M43.9,64.8c-0.6,0.9-0.8,2-0.6,3H30.8
                            c0.2-1,0-2.1-0.6-3c-0.7-1-1.9-1.6-3.1-1.6h-0.6c1.3-0.9,2.9-1.4,4.5-1.4h12.2c1.6,0,3.2,0.5,4.5,1.4H47
                            C45.7,63.2,44.6,63.7,43.9,64.8z"></path>
                      </svg>`
                },
                {
                    title : 'On-Time Delivery',
                    text : 'We know how it feels when someone fails to meet their deadlines. So we take acute measures to ensure that the project is delivered on time, if not earlier.',
                    svg : `<svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                         <path fill="#6a6d6f" d="M12.1,51.5c-3.3,0-5.9,2.8-5.9,6.1c0,3.4,2.7,6.1,5.9,6.1s5.9-2.7,5.9-6C18,54.3,15.3,51.5,12.1,51.5z
                            M12.1,61.3c-2,0-3.6-1.7-3.6-3.7s1.6-3.7,3.6-3.7s3.6,1.7,3.6,3.7S14,61.3,12.1,61.3z"></path>
                         <path fill="#6a6d6f" d="M57.2,51.5c-3.3,0-5.9,2.8-5.9,6.1c0,3.4,2.7,6.1,5.9,6.1c3.3,0,5.9-2.8,5.9-6.1C63.1,54.3,60.4,51.5,57.2,51.5
                            z M57.2,61.3c-2,0-3.6-1.7-3.6-3.7s1.6-3.7,3.6-3.7s3.6,1.7,3.6,3.7S59.1,61.3,57.2,61.3z"></path>
                         <path fill="#6a6d6f" d="M72.6,14.7H48.9c-0.7,0-1.2,0.6-1.2,1.2v4.9V40L46,41.7h-3.2c1.5-1.6,2.5-3.7,2.5-6.1V27c0-3.8-2.4-7.1-5.8-8.2
                            c1.1-1.1,1.8-2.5,2.1-4.1h0.1c0.7,0,1.2-0.6,1.2-1.2c0-4.7-3.7-8.6-8.3-8.6s-8.3,3.9-8.3,8.6c0,0.7,0.5,1.2,1.2,1.2h0.1
                            C28,17,29.4,19,31.4,20l-1.1,1.2c-0.2,0.2-0.3,0.5-0.3,0.9V27h-6.1c-0.6-2.8-3-5-5.9-5h-1.2v-4.9h-2.4V22h-1.2
                            c-0.7,0-1.2,0.6-1.2,1.2V24c-0.7-0.4-1.5-0.7-2.4-0.7H7.3c-0.7,0-1.2,0.6-1.2,1.2v7.4c0,0.7,0.5,1.2,1.2,1.2h2.4
                            c0.9,0,1.7-0.3,2.4-0.7v8H7.3c-3.9,0-7.1,3.3-7.1,7.4c0,0.7,0.5,1.2,1.2,1.2h4c-2.4,2-4,5.1-4,8.6c0,6.1,4.8,11.1,10.7,11.1
                            c5.1,0,9.3-3.7,10.4-8.6h24.2c1.1,4.9,5.3,8.6,10.4,8.6c4.2,0,7.8-2.5,9.6-6.1h6c0.7,0,1.2-0.6,1.2-1.2v-4.9c0-0.7-0.5-1.2-1.2-1.2
                            h-5.1c-0.5-2.5-1.9-4.6-3.7-6.1h0.5c0.9,0,1.7-0.3,2.4-0.7l3.9,4l1.7-1.7l-3.9-4c0.4-0.7,0.7-1.6,0.7-2.5s-0.3-1.8-0.7-2.5
                            c3-0.3,5.4-2.9,5.4-6.1V20.8v-4.9C73.8,15.2,73.2,14.7,72.6,14.7z M67.7,57.7h3.7v2.5h-3.9C67.6,59.3,67.7,58.5,67.7,57.7z
                            M28.8,12.2c0.6-2.8,2.9-4.9,5.8-4.9s5.3,2.1,5.8,4.9H28.8z M30,14.7h9.2c-0.5,2.1-2.4,3.7-4.6,3.7c-0.3,0-0.6,0-0.9-0.1
                            c0.5-0.6,0.9-1.5,0.9-2.4h-2.4c0,0.5-0.3,1-0.8,1.2C30.8,16.4,30.3,15.6,30,14.7z M9.7,30.6H8.5v-4.9h1.2c1.3,0,2.4,1.1,2.4,2.5
                            C12.1,29.5,11,30.6,9.7,30.6z M2.7,46.6c0.5-2.1,2.4-3.7,4.6-3.7h5.9c0.7,0,1.2-0.6,1.2-1.2V24.5H18c1.6,0,2.9,1,3.3,2.5
                            c-1.9,0.1-3.3,1.7-3.3,3.7c0,1.1,0.5,2.1,1.2,2.7v16.1c-1.9-1.8-4.4-2.8-7.1-2.8H2.7V46.6z M12.1,66.2c-4.6,0-8.3-3.9-8.3-8.6
                            S7.5,49,12.1,49s8.3,3.9,8.3,8.6S16.7,66.2,12.1,66.2z M20.4,30.6c0-0.7,0.5-1.2,1.2-1.2h9.5c0.7,0,1.2-0.6,1.2-1.2v-5.6l1.7-1.7h3
                            c3.3,0,5.9,2.8,5.9,6.1v8.6c0,3.4-2.7,6.1-5.9,6.1h-4.7c-2,0-3.6,1.7-3.6,3.7v7.4h-2.4v-7.4c0-3.4,2.7-6.1,5.9-6.1h1.2
                            c0.7,0,1.2-0.6,1.2-1.2v-3.9c1.4-0.5,2.4-1.9,2.4-3.5v-4.9h-2.4v4.9c0,0.7-0.5,1.2-1.2,1.2H21.6C20.9,31.9,20.4,31.3,20.4,30.6z
                            M31.1,48.8c0.4,0.1,0.8,0.2,1.2,0.2H46l-3.6,3.7H31.1V48.8z M21.6,51.5V34.3h10.7v2.5c-4.6,0-8.3,3.9-8.3,8.6v7.4h-1.2
                            C22.1,52.7,21.6,52.2,21.6,51.5z M46.4,57.7H22.8c0-0.9-0.1-1.7-0.3-2.5c0.1,0,0.2,0,0.3,0h2.4h4.7H43c0.3,0,0.6-0.1,0.8-0.4
                            l5.6-5.8h1C47.9,51.1,46.4,54.2,46.4,57.7z M65.4,57.7c0,4.7-3.7,8.6-8.3,8.6s-8.3-3.9-8.3-8.6s3.7-8.6,8.3-8.6S65.4,52.9,65.4,57.7
                            z M64.3,46.6h-32c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2H37h9.5c0.3,0,0.6-0.1,0.8-0.4l2-2.1h14.9c1.3,0,2.4,1.1,2.4,2.5
                            C66.7,45.5,65.6,46.6,64.3,46.6z M71.4,35.5c0,2-1.6,3.7-3.6,3.7h-3.6H50V22h21.4V35.5z M71.4,19.6H50v-2.5h21.4V19.6z"></path>
                         <rect x="52.4" y="34.3" fill="#6a6d6f" width="5.9" height="2.5"></rect>
                         <rect x="52.4" y="29.4" fill="#6a6d6f" width="9.5" height="2.5"></rect>
                         <rect x="52.4" y="24.5" fill="#6a6d6f" width="14.2" height="2.5"></rect>
                         <rect x="42.9" y="4.9" fill="#6a6d6f" width="10.7" height="2.5"></rect>
                         <rect x="47.7" y="9.8" fill="#6a6d6f" width="10.7" height="2.5"></rect>
                      </svg>`
                }
            ]
        }
    }
}
</script>

<style>

</style>