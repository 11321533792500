<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/game-developement-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Chartbuster Mobile Game Development Company
					</h2>
					<p>
						Do you know the game app consumers in the UK have spent £4.33 billion in 2016 on games?
						The mobile game app craze is persistent and even goes up years-after-years. Do you want
						to have some bite of the pie?
					</p>
					<p>
						GenixBit Labs is one of the active mobile game development companies in the UK that use
						Standard Industrial Classification codes to deliver high-end, engaging, and rewarding
						mobile games apps.
					</p>
					<p>
						Our game development studio is loaded with the latest infrastructural facilities and
						capable of nurturing collaborative game development that is hard to find at other
						places.
					</p>
					<p>
						We bet on all the major digital devices including smartphones, tablets, desktops, and
						game consoles while going to create stunning games using 2D 3D Unity game engine
						development tools & technologies.
					</p>
					<p>
						Thus, our games reach a wide audience present on that wide range of devices and enable
						our clients to extract the maximum benefits out of their dream game ideas and
						investment.
					</p>
					<p>
						Since the inception, we have benefitted the UK clients by delivering 500+ game projects.
					</p>
					<p>
						We did so by covering the most of game genre and serving causes of various
						industries/business niches. Therefore, they are witnessing streaming revenues and brand
						augmentation as well.
					</p>
					<p>
						Do you have a game idea and want to get flourished on the game canvas. Partner with
						GenixBit Labs, we will create an addictive game and help you generate best ROI.?
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
