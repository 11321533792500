<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/fantasysport-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Building a fantasy app world of fantasy sports
					</h2>
					<p>
						Fantasy sports is a $7.22 billion industry with 59.3 million fantasy sports. Rugby,
						soccer, cricket, basketball, tennis, baseball, hockey may be games that are played on
						the field with real players. But, fantasy sports app development makes it possible for
						users to build their own team, set their own game and play a match that drives greater
						satisfaction.
					</p>
					<p>
						GenixBit Labs is an experienced fantasy sports app development company that has built and
						launched several fantasy sports all that are delivering unrivaled gaming experience
						around the world. Our focus to creating a perfect app interface that gives players a
						fulfilling experience is what helps us stand out from the crowd.
					</p>
					<p>
						Since delivering experience is out forte, we build fantasy sports mobile apps that have
						multiple sports integrated into them. Be it basketball, cricket, hockey, tennis — we are
						equipped to build any type of fantasy sports mobile app for you.
					</p>
					<p>
						Have a fantasy sports mobile app in mind? Want to know how to make it a reality? Talk to
						our experts.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>