<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Go WordPress?</span>
				<h2 class="fxt-process-dev">Benefits of WordPress development</h2>
				<p>
					WordPress changed the digital landscape on its axis by empowering professionals as well as
					amateurs to build blogs and websites on their own. There is more to it than
					user-friendliness.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed text-left" style="color: #008dd2">
						What makes WordPress awesome?
					</h2>
					<div>
						<ul class="featchers-list">
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M46.8,21.4l-3.4-0.2c0-0.2-0.1-0.5-0.2-0.7c-0.5-1.8-1.2-3.4-2.1-4.9c0,0,1.1-1.1,2.3-2.5s1-3.8-0.7-5.3L40.8,6
                           c-0.9-0.8-2-1.2-3.1-1.2c-0.9,0-1.8,0.3-2.4,1l-2.4,2.6c-1.8-0.9-3.7-1.4-5.8-1.7c0,0-0.2-1.6-0.5-3.5c-0.3-1.8-2-2.9-3.9-2.9
                           c-0.3,0-0.5,0-0.8,0.1l-2.6,0.4C17,1,15.4,2.9,15.7,4.9l0.5,3.5c-1.8,0.9-3.5,2-4.9,3.4c0,0-1.4-0.8-3.1-1.8c-0.5-0.3-1-0.4-1.6-0.4
                           c-1.4,0-2.8,0.9-3.7,2.3l-1.3,2.2c-1.2,2-0.7,4.4,1,5.4l3.1,1.8c-0.5,1.9-0.6,3.9-0.4,5.9c0,0-1.5,0.6-3.3,1.3
                           c-1.8,0.7-2.6,3.1-1.8,5.2l0.9,2.3C2,37.8,3.5,38.9,5,38.9c0.4,0,0.8-0.1,1.1-0.2l3.3-1.3c1.2,1.6,2.8,3,4.4,4.1c0,0-0.5,1.5-1,3.4
                           c-0.5,1.8,0.8,3.9,3,4.5l2.5,0.7c0.5,0.2,1,0.2,1.4,0.2c1.7,0,3.2-0.9,3.6-2.3l1-3.3c0,0,0.1,0,0.2,0c1.7,0,3.4-0.2,5.2-0.7
                           c0.3-0.1,0.5-0.2,0.8-0.2c0,0,0.9,1.2,2,2.8c0.6,0.9,1.6,1.3,2.7,1.3c0.9,0,1.8-0.3,2.7-0.9l2.1-1.5c1.9-1.3,2.6-3.6,1.5-5.2l-2-2.8
                           c1.3-1.6,2.3-3.4,3.1-5.4c0,0,1.5,0.1,3.4,0.2c0.1,0,0.1,0,0.2,0c1.8,0,3.4-1.7,3.6-4l0.2-2.5C50.1,23.5,48.7,21.5,46.8,21.4z
                           M48.3,25.7l-0.2,2.5c-0.1,1.4-1,2.5-1.9,2.5c0,0,0,0-0.1,0l-3.4-0.2l-1.2-0.1l-0.4,1.1c-0.7,1.8-1.6,3.4-2.8,4.9l-0.8,0.9l0.7,1
                           l2,2.8c0.2,0.4,0.2,0.8,0.2,1.1c-0.1,0.7-0.6,1.4-1.3,1.8l-2.1,1.5c-0.5,0.4-1.2,0.6-1.8,0.6c-0.6,0-1.1-0.2-1.4-0.6l-2-2.8l-0.7-1
                           l-1.1,0.4c-0.2,0.1-0.5,0.2-0.7,0.2c-1.5,0.4-3.1,0.6-4.7,0.6h-0.1h-1.2l-0.4,1.2l-1,3.3c-0.2,0.7-1.1,1.2-2.1,1.2
                           c-0.3,0-0.7,0-1-0.2l-2.5-0.7c-0.8-0.2-1.4-0.7-1.8-1.3c-0.2-0.3-0.3-0.7-0.2-1.2l1-3.4l0.4-1.2l-1-0.7c-1.6-1-2.9-2.3-4.1-3.7
                           L10,35.3l-1.1,0.4l-3.3,1.3c-0.2,0.1-0.4,0.1-0.5,0.1c-0.9,0-1.8-0.7-2.2-1.8l-0.9-2.3c-0.3-0.7-0.3-1.5,0-2.2
                           c0.1-0.3,0.4-0.7,0.9-0.9L6,28.6l1.1-0.4L7,27c-0.2-1.8,0-3.7,0.4-5.4l0.3-1.2l-1.1-0.6L3.5,18c-0.4-0.2-0.6-0.7-0.7-1
                           c-0.2-0.7-0.1-1.4,0.3-2.1l1.3-2.2c0.5-0.9,1.4-1.5,2.3-1.5c0.3,0,0.5,0.1,0.8,0.2l3.1,1.8l1.1,0.6l0.9-0.8c1.3-1.2,2.8-2.3,4.5-3.1
                           l1.1-0.5l-0.2-1.2l-0.5-3.5c-0.1-0.6,0.2-1.1,0.5-1.4c0.4-0.5,1.1-0.8,1.8-0.9l2.6-0.4c0.2,0,0.4,0,0.5,0c1.2,0,2.2,0.7,2.3,1.5
                           l0.5,3.5l0.2,1.2L27,8.2c1.8,0.2,3.6,0.7,5.3,1.5l1.1,0.5l0.8-0.9l2.4-2.6c0.4-0.4,0.9-0.5,1.2-0.5c0.7,0,1.4,0.3,2,0.8l1.9,1.7
                           c0.6,0.5,1,1.3,1,2c0,0.3,0,0.7-0.4,1.1l-2.3,2.5l-0.8,0.9l0.7,1.1c0.9,1.4,1.5,2.9,2,4.5c0,0.2,0.1,0.4,0.2,0.7l0.3,1.2l1.2,0.1
                           l3.4,0.2c0.5,0,0.8,0.3,1,0.5C48.2,24.1,48.4,24.9,48.3,25.7z"
										></path>
										<path
											d="M25,12.2c-7.1,0-12.9,5.8-12.9,13s5.8,13,12.9,13s12.9-5.8,12.9-13S32.1,12.2,25,12.2z M25,36.6c-6.2,0-11.3-5.1-11.3-11.4
                           c0-6.3,5.1-11.4,11.3-11.4S36.3,19,36.3,25.2C36.3,31.5,31.2,36.6,25,36.6z"
										></path>
										<path
											d="M28.1,29.5h-6.2c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4h6.2c0.2,0,0.4-0.2,0.4-0.4C28.5,29.7,28.3,29.5,28.1,29.5z"
										></path>
										<path
											d="M28.1,27.1h-6.2c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h6.2c0.2,0,0.4-0.2,0.4-0.4C28.5,27.3,28.3,27.1,28.1,27.1z"
										></path>
										<path
											d="M28.1,24.8h-6.2c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4h6.2c0.2,0,0.4-0.2,0.4-0.4S28.3,24.8,28.1,24.8z"
										></path>
										<path
											d="M21.5,22.9c0,0.2,0.2,0.4,0.4,0.4h6.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-6.2C21.6,22.5,21.5,22.6,21.5,22.9z"
										></path>
										<path
											d="M21.9,20.9h3.5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-3.5c-0.2,0-0.4,0.2-0.4,0.4C21.5,20.7,21.6,20.9,21.9,20.9z"
										></path>
										<path
											d="M19.5,17c-0.9,0-1.6,0.6-1.6,1.4v13.9c0,0.9,0.7,1.5,1.6,1.5h10.5c0.9,0,1.6-0.6,1.6-1.5V20.9l-4-3.9H19.5z M30.1,21.7v10.6
                           H19.5V18.6h7.4v1.5c0,0.9,0.6,1.6,1.5,1.6H30.1z"
										></path>
									</svg>
								</div>
								<p>
									An <strong>easy-to-use content management system</strong> that requires no prior
									training or technical expertise.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g transform="translate(0,-952.36218)">
											<path
												d="M0.4,957.9c-0.2,0-0.4,0.3-0.4,0.5v28.8c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0H24c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H1
                              v-22.4l17.3-0.1c0.1,0,0.3-0.1,0.4-0.2l1.3-1.4h16.2v4.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-9c0-0.3-0.2-0.5-0.5-0.5
                              H0.5C0.5,957.9,0.4,957.9,0.4,957.9L0.4,957.9z M1,959h35.1v2.7H19.7c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.2l-1.3,1.4l-17,0.1
                              L1,959L1,959z M3.7,959.7c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4S5,961.8,5,961C5,960.3,4.4,959.7,3.7,959.7z M7.6,959.7
                              c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4S9,961.8,9,961C9,960.3,8.4,959.7,7.6,959.7z M11.6,959.7c-0.7,0-1.4,0.6-1.4,1.4
                              c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.3-0.6,1.3-1.4C12.9,960.3,12.3,959.7,11.6,959.7z M3.7,960.7c0.2,0,0.3,0.2,0.3,0.3
                              s-0.1,0.3-0.3,0.3s-0.3-0.1-0.3-0.3S3.5,960.7,3.7,960.7z M7.6,960.7c0.2,0,0.3,0.2,0.3,0.3s-0.1,0.3-0.3,0.3
                              c-0.2,0-0.3-0.1-0.3-0.3S7.4,960.7,7.6,960.7z M11.6,960.7c0.2,0,0.3,0.2,0.3,0.3s-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
                              S11.4,960.7,11.6,960.7z M2.7,967.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h10.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H2.7z
                              M34.6,970c-0.3,0-0.5,0.3-0.5,0.5v2.3c-0.9,0.2-1.7,0.5-2.5,1l-1.5-1.8c-0.2-0.2-0.5-0.2-0.7-0.1l-2.9,2.5
                              c-0.2,0.2-0.2,0.5-0.1,0.7L28,977c-0.8,1.1-1.4,2.4-1.7,3.8h-2.5c-0.3,0-0.5,0.2-0.5,0.5v3.9c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0
                              h2.6c0.3,1.3,0.9,2.6,1.7,3.7l-1.7,2c-0.2,0.2-0.2,0.5,0.1,0.7l2.9,2.5c0.2,0.2,0.5,0.2,0.7-0.1l1.8-2c0.7,0.4,1.5,0.6,2.3,0.8v2.7
                              c0,0.3,0.2,0.5,0.5,0.5c0,0,0,0,0,0h3.9c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0,0v-2.7c0.8-0.2,1.6-0.5,2.3-0.8l1.8,2
                              c0.2,0.2,0.5,0.2,0.7,0.1c0,0,0,0,0,0l3-2.5c0.2-0.2,0.2-0.5,0.1-0.7l-1.7-2c0.8-1.1,1.4-2.3,1.7-3.7h2.6c0.3,0,0.5-0.2,0.5-0.5
                              c0,0,0,0,0,0v-3.9c0-0.3-0.2-0.5-0.5-0.5H47c-0.3-1.4-0.9-2.7-1.7-3.8l1.6-1.8c0.2-0.2,0.2-0.5-0.1-0.7l-3-2.5
                              c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0l-1.5,1.8c-0.8-0.4-1.6-0.7-2.5-1v-2.3c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0H34.6
                              C34.7,970,34.6,970,34.6,970L34.6,970z M2.7,970.1c-0.3,0-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5l0,0h18.1c0.3,0,0.5-0.2,0.5-0.5
                              c0-0.3-0.2-0.5-0.5-0.5H2.7C2.7,970.1,2.7,970.1,2.7,970.1z M35.2,971H38v2.2c0,0.2,0.2,0.5,0.4,0.5c1.1,0.2,2.1,0.6,3,1.1
                              c0.2,0.1,0.5,0.1,0.6-0.1l1.5-1.7l2.2,1.9l-1.5,1.7c-0.2,0.2-0.2,0.5,0,0.6c0.9,1.2,1.6,2.6,1.8,4.2c0,0.3,0.3,0.4,0.5,0.4H49v2.9
                              h-2.4c-0.2,0-0.4,0.2-0.5,0.4c-0.3,1.5-1,2.9-1.9,4c-0.2,0.2-0.2,0.5,0,0.7l1.6,1.9l-2.2,1.8l-1.7-1.9c-0.2-0.2-0.4-0.2-0.6-0.1
                              c-0.9,0.5-1.8,0.8-2.8,1c-0.2,0-0.4,0.3-0.4,0.5v2.6h-2.9v-2.6c0-0.2-0.2-0.5-0.4-0.5c-1-0.2-1.9-0.5-2.8-1
                              c-0.2-0.1-0.5-0.1-0.6,0.1l-1.7,1.9l-2.2-1.9l1.6-1.9c0.2-0.2,0.2-0.5,0-0.7c-0.9-1.2-1.6-2.5-1.9-4c-0.1-0.2-0.3-0.4-0.5-0.4h-2.4
                              v-2.9h2.4c0.2,0,0.5-0.2,0.5-0.4c0.3-1.6,0.9-3,1.8-4.2c0.1-0.2,0.1-0.5,0-0.6l-1.5-1.7l2.2-1.9l1.5,1.7c0.2,0.2,0.4,0.2,0.6,0.1
                              c0.9-0.5,1.9-0.9,3-1.1c0.2,0,0.4-0.3,0.4-0.5L35.2,971L35.2,971z M2.6,972.6c-0.3,0.1-0.5,0.3-0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.4
                              h18.1c0.3,0,0.5-0.2,0.6-0.4c0-0.3-0.2-0.5-0.4-0.6c0,0-0.1,0-0.1,0H2.7C2.7,972.6,2.6,972.6,2.6,972.6z M36.6,974.5
                              c-4.9,0-8.9,4-8.9,8.9c0,3.8,2.5,7,5.9,8.3c0,0,0.1,0,0.1,0h0c0,0,0.1,0,0.1,0c0.8,0.3,1.7,0.5,2.6,0.5c0.1,0,0.2,0,0.3,0
                              c0.9,0,1.8-0.2,2.6-0.5c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c3.4-1.2,5.9-4.4,5.9-8.3C45.5,978.5,41.5,974.5,36.6,974.5
                              L36.6,974.5z M2.7,975.1c-0.3,0-0.5,0.3-0.5,0.5c0,0.3,0.3,0.5,0.5,0.5h18.1c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H2.7
                              C2.7,975.1,2.7,975.1,2.7,975.1z M36.6,975.5c4.3,0,7.8,3.5,7.8,7.8c0,3.1-1.9,5.7-4.6,7v-4.9c0,0,0,0,0-0.1c0,0,0,0,0,0
                              c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1l-2.7-5.7c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.1
                              c-0.2,0-0.3,0.1-0.4,0.3l-2.7,5.6c-0.1,0.1-0.2,0.4-0.1,0.6v4.7c-2.7-1.2-4.6-3.8-4.6-7C28.8,979,32.3,975.5,36.6,975.5L36.6,975.5
                              z M2.6,977.7c-0.3,0.1-0.5,0.3-0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.4h18.1c0.3,0,0.5-0.2,0.6-0.4c0-0.3-0.2-0.5-0.4-0.6c0,0-0.1,0-0.1,0
                              H2.7C2.7,977.7,2.6,977.7,2.6,977.7z M2.7,980.2c-0.3,0-0.5,0.3-0.5,0.5s0.3,0.5,0.5,0.5l0,0h18.1c0.3,0,0.5-0.2,0.5-0.5
                              c0-0.3-0.2-0.5-0.5-0.5H2.7C2.7,980.2,2.7,980.2,2.7,980.2z M36.6,980.9l2,4.3l-2,1.1l-2-1.1L36.6,980.9z M2.6,982.8
                              c-0.3,0.1-0.5,0.3-0.4,0.6c0.1,0.2,0.3,0.4,0.5,0.4h18.1c0.3,0,0.5-0.2,0.6-0.4c0-0.3-0.2-0.5-0.4-0.6c0,0-0.1,0-0.1,0H2.7
                              C2.7,982.8,2.6,982.8,2.6,982.8z M34.4,986.3l1.7,0.9v4c-0.6-0.1-1.1-0.2-1.7-0.4V986.3L34.4,986.3z M38.8,986.3v4.5
                              c-0.5,0.2-1.1,0.3-1.7,0.4v-4L38.8,986.3L38.8,986.3z"
											></path>
										</g>
									</svg>
								</div>
								<p>
									From single page personal blogs to eCommerce stores with thousands of individual
									product pages, <strong>WordPress can build anything.</strong>
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M43.3,34.7H6.7C6.3,34.7,6,34.4,6,34V16.5c0-0.4,0.3-0.8,0.8-0.8h36.5c0.4,0,0.8,0.3,0.8,0.8V34
                                 C44,34.4,43.7,34.7,43.3,34.7z M7.5,33.2h35V17.3h-35V33.2z"
												></path>
											</g>
											<g>
												<g>
													<path
														d="M6.7,20h-3C3.4,20,3,19.6,3,19.2v-8.9c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v8.2h2.2c0.4,0,0.8,0.3,0.8,0.8
                                    S7.2,20,6.7,20z"
													></path>
												</g>
												<g>
													<path
														d="M3.8,11C1.7,11,0,9.3,0,7.2s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8S5.9,11,3.8,11z M3.8,5C2.5,5,1.5,6,1.5,7.2s1,2.3,2.3,2.3
                                    S6,8.5,6,7.2S5,5,3.8,5z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M3.8,41C3.4,41,3,40.7,3,40.2v-8.9c0-0.4,0.3-0.8,0.8-0.8h3c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H4.5v8.2
                                    C4.5,40.7,4.2,41,3.8,41z"
													></path>
												</g>
												<g>
													<path
														d="M3.8,47C1.7,47,0,45.3,0,43.3c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C7.6,45.3,5.9,47,3.8,47z M3.8,41
                                    c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3C6,42,5,41,3.8,41z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M46.2,20h-3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2.2v-8.2c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v8.9
                                    C47,19.6,46.6,20,46.2,20z"
													></path>
												</g>
												<g>
													<path
														d="M46.2,11c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8S48.3,11,46.2,11z M46.2,5C45,5,44,6,44,7.2
                                    s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S47.5,5,46.2,5z"
													></path>
												</g>
												<g>
													<path
														d="M32.6,11c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8S34.6,11,32.6,11z M32.6,5c-1.3,0-2.3,1-2.3,2.3
                                    s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S33.8,5,32.6,5z"
													></path>
												</g>
												<g>
													<path
														d="M17.4,11c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8S19.5,11,17.4,11z M17.4,5c-1.3,0-2.3,1-2.3,2.3
                                    s1,2.3,2.3,2.3s2.3-1,2.3-2.3S18.7,5,17.4,5z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M46.2,41c-0.4,0-0.8-0.3-0.8-0.8v-8.2h-2.2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h3c0.4,0,0.8,0.3,0.8,0.8v8.9
                                    C47,40.7,46.6,41,46.2,41z"
													></path>
												</g>
												<g>
													<path
														d="M46.2,47c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8C50,45.3,48.3,47,46.2,47z M46.2,41
                                    C45,41,44,42,44,43.3c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3C48.5,42,47.5,41,46.2,41z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M17.4,17.3c-0.4,0-0.8-0.3-0.8-0.8v-6.3c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v6.3C18.2,17,17.9,17.3,17.4,17.3z"
													></path>
												</g>
												<g>
													<path
														d="M32.6,17.3c-0.4,0-0.8-0.3-0.8-0.8v-6.3c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v6.3C33.3,17,33,17.3,32.6,17.3z"
													></path>
												</g>
											</g>
											<g>
												<path
													d="M32.6,47c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C36.3,45.3,34.6,47,32.6,47z M32.6,41
                                 c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3C34.8,42,33.8,41,32.6,41z"
												></path>
											</g>
											<g>
												<path
													d="M17.4,47c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C21.2,45.3,19.5,47,17.4,47z M17.4,41
                                 c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3C19.7,42,18.7,41,17.4,41z"
												></path>
											</g>
											<g>
												<g>
													<path
														d="M17.4,41c-0.4,0-0.8-0.3-0.8-0.8V34c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v6.3C18.2,40.7,17.9,41,17.4,41z"
													></path>
												</g>
												<g>
													<path
														d="M32.6,41c-0.4,0-0.8-0.3-0.8-0.8V34c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v6.3C33.3,40.7,33,41,32.6,41z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M13.5,27.8c0.4,0.3,1,0.5,1.7,0.5c1,0,1.5-0.5,1.5-1.2c0-0.7-0.4-1.1-1.4-1.5c-1.2-0.4-1.9-1-1.9-2.1c0-1.1,0.9-2,2.4-2
                                    c0.7,0,1.3,0.2,1.6,0.4l-0.3,0.8c-0.2-0.1-0.7-0.3-1.4-0.3c-1,0-1.4,0.6-1.4,1.1c0,0.7,0.4,1,1.5,1.4c1.2,0.5,1.9,1.1,1.9,2.1
                                    c0,1.1-0.8,2.1-2.6,2.1c-0.7,0-1.5-0.2-1.9-0.5L13.5,27.8z"
													></path>
												</g>
												<g>
													<path
														d="M25.9,25.5H23v2.6h3.2v0.8h-4.1v-7.3H26v0.8h-3v2.3h2.8V25.5z"
													></path>
												</g>
												<g>
													<path
														d="M36.8,25.2c0,2.5-1.5,3.8-3.4,3.8c-1.9,0-3.3-1.5-3.3-3.7c0-2.3,1.4-3.8,3.4-3.8C35.5,21.5,36.8,23,36.8,25.2z
                                    M31.1,25.3c0,1.6,0.8,3,2.3,3c1.5,0,2.3-1.4,2.3-3c0-1.5-0.8-3-2.3-3C31.9,22.2,31.1,23.7,31.1,25.3z"
													></path>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<p>
									WordPress has <strong>built-in SEO parameters</strong> that makes websites SEO
									friendly. Additional plugins can also be used to extend the SEO-friendliness of
									the website.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M25,15.5c2.9,0,5.6,1.3,7.3,3.4l1.3-0.9c-2-2.5-5.1-4.1-8.6-4.1c-6.1,0-11.1,5-11.1,11.1s5,11.1,11.1,11.1
                              c5.3,0,9.7-3.7,10.8-8.6c-0.8,0.5-1.5,1.1-2.2,1.5c-1.5,3.2-4.8,5.5-8.6,5.5c-5.3,0-9.5-4.3-9.5-9.5C15.5,19.7,19.7,15.5,25,15.5z"
											></path>
											<path
												d="M47.5,20.8h-2.3c-0.7,0.5-1.4,1-2.2,1.6H44h3.5c0.5,0,0.9,0.6,0.9,1.2v2.8c0,0.7-0.4,1.2-0.9,1.2H44h-1.3l-0.3,1.2
                              c-0.4,2.1-1.3,4-2.4,5.8l-0.7,1.1l0.9,0.9l2.5,2.5c0.2,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.6-0.4,0.9l-2,2c-0.3,0.3-0.6,0.4-1,0.4
                              c-0.2,0-0.4,0-0.5-0.2l-2.5-2.5l-0.9-0.9l-1.1,0.7c-1.8,1.1-3.7,2-5.8,2.4l-1.2,0.3V44v3.5c0,0.5-0.6,0.9-1.2,0.9h-2.8
                              c-0.7,0-1.2-0.4-1.2-0.9V44v-1.3l-1.2-0.3c-2.1-0.4-4-1.3-5.8-2.4l-1.1-0.7l-0.9,0.9l-2.5,2.5c-0.2,0.2-0.4,0.2-0.5,0.2
                              c-0.3,0-0.7-0.2-1-0.4l-2-2C7.2,40.3,7,40,7,39.7c0-0.2,0-0.4,0.2-0.6l2.5-2.5l0.9-0.9l-0.7-1.1c-1.1-1.8-2-3.7-2.4-5.8l-0.3-1.2H6
                              H2.5c-0.5,0-0.9-0.6-0.9-1.2v-2.8c0-0.7,0.4-1.2,0.9-1.2H6h1.3l0.3-1.2c0.4-2.1,1.3-4,2.4-5.8l0.7-1.1l-0.9-0.9l-2.5-2.5
                              C7.1,10.7,7,10.5,7,10.4c0-0.3,0.2-0.7,0.4-1l2-2C9.7,7.2,10,7,10.4,7c0.2,0,0.4,0,0.5,0.2l2.5,2.5l0.9,0.9l1.1-0.7
                              c1.8-1.1,3.7-2,5.8-2.4l1.2-0.3V6V2.5c0-0.5,0.6-0.9,1.2-0.9h2.8c0.7,0,1.2,0.4,1.2,0.9V6v1.3l1.2,0.3c2.1,0.4,4,1.3,5.8,2.4
                              l1.1,0.7l0.9-0.9l2.5-2.5C39.3,7.1,39.5,7,39.6,7c0.3,0,0.7,0.2,1,0.4l2,2C42.8,9.7,43,10,43,10.3c0,0.2,0,0.4-0.2,0.6l-1.9,1.9
                              l3.7-2.6c0-0.7-0.3-1.4-0.9-1.9l-2-2c-0.6-0.6-1.3-0.9-2.1-0.9c-0.6,0-1.2,0.2-1.7,0.7l-2.5,2.5c-1.9-1.2-4-2.1-6.3-2.6V2.5
                              c0-1.4-1.2-2.5-2.8-2.5h-2.8c-1.5,0-2.8,1.1-2.8,2.5V6c-2.3,0.5-4.4,1.4-6.3,2.6L12,6.1c-0.4-0.4-1-0.7-1.7-0.7
                              c-0.7,0-1.5,0.3-2.1,0.9l-2,2C5.3,9.4,5.2,11.1,6.1,12l2.5,2.5c-1.2,1.9-2.1,4-2.6,6.3H2.5c-1.4,0-2.5,1.2-2.5,2.8v2.8
                              c0,1.5,1.1,2.8,2.5,2.8H6c0.5,2.3,1.4,4.4,2.6,6.3L6.1,38c-1,1-0.9,2.6,0.2,3.7l2,2c0.6,0.6,1.3,0.9,2.1,0.9c0.6,0,1.2-0.2,1.7-0.7
                              l2.5-2.5c1.9,1.2,4,2.1,6.3,2.6v3.5c0,1.4,1.2,2.5,2.8,2.5h2.8c1.5,0,2.8-1.1,2.8-2.5V44c2.3-0.5,4.4-1.4,6.3-2.6l2.5,2.5
                              c0.4,0.4,1,0.7,1.7,0.7c0.7,0,1.5-0.3,2.1-0.9l2-2c1.1-1.1,1.2-2.7,0.2-3.7l-2.5-2.5c1.2-1.9,2.1-4,2.6-6.3h3.5
                              c1.4,0,2.5-1.2,2.5-2.8v-2.8C50,22.1,48.9,20.8,47.5,20.8z"
											></path>
											<path
												d="M27.1,24.5c0,0-0.1-0.1-0.1-0.1l-3.1-4.1c-0.4-0.6-1-0.9-1.7-0.9c-0.4,0-0.9,0.1-1.2,0.4l-1.4,1c-0.9,0.7-1.2,2-0.5,3
                              c0,0.1,3.8,5.2,5.5,7.6c0.3,0.5,0.9,0.8,1.5,0.8c0,0,0,0,0,0c0.4,0,0.8-0.1,1.1-0.4c4.3-3,21.8-15.5,21.9-15.5c0.9-0.7,1.2-2,0.5-3
                              l-1-1.4c-0.4-0.6-1-0.9-1.7-0.9c-0.4,0-0.9,0.1-1.2,0.4L27.6,24.1C27.4,24.3,27.2,24.4,27.1,24.5z M46.6,12.6
                              c0.2-0.2,0.6-0.1,0.8,0.1l1,1.4c0.2,0.3,0.1,0.6-0.1,0.8c0,0-17.6,12.5-21.8,15.5c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1
                              c-1.7-2.4-5.5-7.5-5.5-7.6c-0.2-0.3-0.1-0.6,0.1-0.8l1.4-1c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.5,0.2l3,4.1
                              c0.1,0.2,0.4,0.6,0.6,0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1.2-0.4,1.6-0.7L46.6,12.6z"
											></path>
										</g>
									</svg>
								</div>
								<p>
									WordPress is a <strong>100% customizable</strong> CMS platform. It helps users
									perfect their website look and feel to any brand preset that they wish.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M49,36.8h-1.7V5.1c0-0.6-0.4-1-1-1H3.7c-0.6,0-1,0.4-1,1v31.7H1c-0.6,0-1,0.4-1,1v2c0,3.4,2.7,6.1,6.1,6.1h37.8
                           c3.4,0,6.1-2.7,6.1-6.1v-2C50,37.2,49.5,36.8,49,36.8z M4.7,6.1h40.6v30.7H4.7V6.1z M48,39.8c0,2.3-1.8,4.1-4.1,4.1H6.1
                           C3.8,43.9,2,42,2,39.8v-1h46V39.8z"
										></path>
										<path
											d="M23.1,42.3h3.8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3.8c-0.6,0-1,0.4-1,1S22.5,42.3,23.1,42.3z"
										></path>
										<path
											d="M41.5,23.5C41.5,23.5,41.4,23.4,41.5,23.5c-1-8.2-8-14.6-16.5-14.6c-2.8,0-5.5,0.7-7.8,2c0,0,0,0,0,0c0,0,0,0,0,0
                           c-1.9,1-3.6,2.4-5,4.1c0,0,0,0,0,0c0,0,0,0,0,0c-1.9,2.3-3.2,5.2-3.6,8.4c0,0,0,0,0,0.1c0,0,0,0,0,0c-0.1,0.7-0.1,1.3-0.1,2
                           c0,0.6,0.4,1,1,1s1-0.4,1-1c0-0.4,0-0.7,0.1-1h3.2c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2.9c0.4-1.9,1.2-3.7,2.2-5.2l1.4,1.4
                           c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-1.5-1.5c0.9-1,1.9-1.8,3.1-2.5l1,1.8c0.2,0.3,0.5,0.5,0.9,0.5
                           c0.2,0,0.3,0,0.5-0.1c0.5-0.3,0.7-0.9,0.4-1.4l-1-1.8c1.5-0.7,3.2-1.1,5-1.2v1.5c0,0.6,0.4,1,1,1s1-0.4,1-1V11
                           c1.8,0.1,3.6,0.6,5.1,1.3l-1.5,1.5c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.9-1.9c1,0.6,1.9,1.4,2.6,2.2
                           l-1.7,1.7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.6-1.6c1.1,1.6,2,3.4,2.4,5.4h-3.5c-0.6,0-1,0.4-1,1
                           s0.4,1,1,1h3.8c0,0.3,0.1,0.7,0.1,1c0,0.6,0.4,1,1,1s1-0.4,1-1C41.6,24.8,41.5,24.1,41.5,23.5C41.5,23.5,41.5,23.5,41.5,23.5z"
										></path>
										<path
											d="M30.8,20.6l-4.5,4.8c-0.4-0.1-0.8-0.2-1.3-0.2c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4c0-1.2-0.5-2.3-1.3-3.1
                           l4.2-4.5c0.4-0.4,0.4-1,0-1.4C31.8,20.2,31.2,20.2,30.8,20.6z M25,32c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4
                           S26.3,32,25,32z"
										></path>
									</svg>
								</div>
								<p>
									<strong>Sturdy performance</strong> that is unabated by the number of pages,
									application or traffic. There is a reason why one-fourth of the internet uses
									WordPress.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M9.5,27.5c0,0.4,0.2,0.7,0.6,0.8l2.8,0.9l0.6,1.4l-1.2,2.5c-0.1,0.3-0.1,0.6,0.1,0.8c1,1.4,2.2,2.7,3.7,3.7
                           c0.2,0.2,0.6,0.2,0.8,0.1l2.5-1.2l1.4,0.6l0.9,2.8c0.1,0.3,0.4,0.6,0.8,0.6h4.9c0.4,0,0.7-0.2,0.8-0.6l0.9-2.8
                           c0.5-0.2,1-0.4,1.5-0.6l2.5,1.2c0.3,0.1,0.6,0.1,0.8-0.1c1.4-1,2.7-2.2,3.7-3.7c0.2-0.2,0.2-0.6,0.1-0.8l-1.2-2.5
                           c0.2-0.5,0.4-1,0.6-1.5l2.8-0.9c0.3-0.1,0.6-0.4,0.6-0.8v-4.9c0-0.4-0.2-0.7-0.6-0.8l-2.8-0.9c-0.2-0.5-0.4-1-0.6-1.5l1.2-2.5
                           c0.1-0.3,0.1-0.6-0.1-0.8c-1-1.4-2.2-2.7-3.7-3.7c-0.2-0.2-0.6-0.2-0.8-0.1l-2.5,1.2c-0.5-0.2-1-0.4-1.5-0.6L28.2,10
                           c-0.1-0.3-0.4-0.6-0.8-0.6h-4.9c-0.4,0-0.7,0.2-0.8,0.6l-0.9,2.8l-1.4,0.6l-2.5-1.2c-0.3-0.1-0.6-0.1-0.8,0.1
                           c-1.4,1-2.7,2.2-3.7,3.7c-0.2,0.2-0.2,0.6-0.1,0.8l1.2,2.5l-0.6,1.4L10,21.8c-0.3,0.1-0.6,0.4-0.6,0.8V27.5z M11.1,23.1l2.6-0.9
                           c0.2-0.1,0.4-0.2,0.5-0.5l0.9-2.1c0.1-0.2,0.1-0.5,0-0.7L14,16.6c0.8-1,1.6-1.9,2.6-2.6l2.4,1.1c0.2,0.1,0.5,0.1,0.7,0l2.1-0.9
                           c0.2-0.1,0.4-0.3,0.5-0.5l0.9-2.6h3.7l0.9,2.6c0.1,0.3,0.3,0.4,0.5,0.5c0.7,0.2,1.4,0.5,2,0.8c0.2,0.1,0.5,0.1,0.7,0l2.4-1.1
                           c1,0.8,1.9,1.6,2.6,2.6L34.9,19c-0.1,0.2-0.1,0.5,0,0.7c0.3,0.6,0.6,1.3,0.8,2c0.1,0.3,0.3,0.5,0.5,0.5l2.6,0.9v3.7l-2.6,0.9
                           c-0.3,0.1-0.4,0.3-0.5,0.5c-0.2,0.7-0.5,1.3-0.8,2c-0.1,0.2-0.1,0.5,0,0.7l1.1,2.4c-0.8,1-1.6,1.9-2.6,2.6L31,34.9
                           c-0.2-0.1-0.5-0.1-0.7,0c-0.6,0.3-1.3,0.6-2,0.8c-0.3,0.1-0.5,0.3-0.5,0.5l-0.9,2.6h-3.7l-0.9-2.6c-0.1-0.2-0.2-0.4-0.5-0.5
                           l-2.1-0.9c-0.2-0.1-0.5-0.1-0.7,0L16.6,36c-1-0.8-1.9-1.6-2.6-2.6l1.1-2.4c0.1-0.2,0.1-0.4,0-0.7l-0.9-2.1c-0.1-0.2-0.3-0.4-0.5-0.5
                           l-2.6-0.9V23.1z"
										></path>
										<path
											d="M25,34.8c5.4,0,9.8-4.4,9.8-9.8s-4.4-9.8-9.8-9.8s-9.8,4.4-9.8,9.8C15.2,30.4,19.6,34.8,25,34.8z M25,16.8
                           c4.5,0,8.2,3.7,8.2,8.2s-3.7,8.2-8.2,8.2s-8.2-3.7-8.2-8.2C16.8,20.5,20.5,16.8,25,16.8z"
										></path>
										<path
											d="M24.2,23.1v8.4h1.6v-8.4c0.8-0.4,1-1.4,0.6-2.2c-0.1-0.3-0.4-0.5-0.6-0.6v-1.9h-1.6v1.9c-0.8,0.4-1,1.4-0.6,2.2
                           C23.7,22.8,23.9,23,24.2,23.1z"
										></path>
										<path
											d="M28.3,28v1.9h1.6V28c0.8-0.4,1-1.4,0.6-2.2c-0.1-0.3-0.4-0.5-0.6-0.6v-5.1h-1.6v5.1c-0.8,0.4-1,1.4-0.6,2.2
                           C27.8,27.7,28,27.9,28.3,28z"
										></path>
										<path
											d="M20.1,26.9v3h1.6v-3c0.8-0.5,1.1-1.6,0.6-2.4c-0.1-0.2-0.3-0.4-0.6-0.6v-3.9h-1.6V24c-0.8,0.5-1.1,1.6-0.6,2.4
                           C19.7,26.6,19.9,26.8,20.1,26.9z"
										></path>
										<path
											d="M14.4,7h4.1v2.5h1.6V6.2c0-0.5-0.4-0.8-0.8-0.8c0,0,0,0,0,0h-1.2L25,2.2l6.9,3.2h-1.2c-0.5,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0
                           v3.3h1.6V7h4.1c0.5,0,0.8-0.4,0.8-0.8c0-0.3-0.2-0.6-0.5-0.7L25.3,0.6c-0.2-0.1-0.5-0.1-0.7,0L14,5.5c-0.4,0.2-0.6,0.7-0.4,1.1
                           C13.8,6.8,14.1,7,14.4,7z"
										></path>
										<path
											d="M36.4,43.6C36.3,43.2,36,43,35.6,43h-4.1v-2.5h-1.6v3.3c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h1.2L25,47.8l-6.9-3.2h1.2
                           c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0v-3.3h-1.6V43h-4.1c-0.5,0-0.8,0.4-0.8,0.8c0,0.3,0.2,0.6,0.5,0.7l10.6,4.9
                           c0.2,0.1,0.5,0.1,0.7,0L36,44.5C36.3,44.4,36.5,44,36.4,43.6z"
										></path>
										<path
											d="M44.6,19.3v-1.2l3.2,6.9l-3.2,6.9v-1.2c0-0.4-0.4-0.8-0.8-0.8l-3.3-0.1l0,1.6l2.5,0.1v4.1c0,0.4,0.3,0.7,0.6,0.8
                           c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5l4.9-10.6c0.1-0.2,0.1-0.5,0-0.7L44.5,14c-0.2-0.4-0.5-0.5-0.9-0.5
                           C43.2,13.7,43,14,43,14.4v4.1h-2.5v1.6h3.3C44.2,20.1,44.6,19.7,44.6,19.3C44.6,19.3,44.6,19.3,44.6,19.3z"
										></path>
										<path
											d="M5.5,36c0.1,0.3,0.4,0.5,0.7,0.5c0.1,0,0.1,0,0.2,0C6.8,36.3,7,36,7,35.6v-4.1h2.5v-1.6H6.2c-0.5,0-0.8,0.4-0.8,0.8
                           c0,0,0,0,0,0v1.2L2.2,25l3.2-6.9v1.2c0,0.4,0.4,0.8,0.8,0.8l3.3,0.1l0-1.6L7,18.5v-4.1c0-0.5-0.4-0.8-0.8-0.8
                           c-0.3,0-0.6,0.2-0.7,0.5L0.6,24.7c-0.1,0.2-0.1,0.5,0,0.7L5.5,36z"
										></path>
										<rect x="44.6" y="24.2" width="1.6" height="1.6"></rect>
										<rect x="3.8" y="24.2" width="1.6" height="1.6"></rect>
										<rect x="24.2" y="3.8" width="1.6" height="1.6"></rect>
										<rect x="24.2" y="44.6" width="1.6" height="1.6"></rect>
									</svg>
								</div>
								<p>
									<strong>Establish total control</strong> over website or blog management with
									admin, editor and user level privileges.
								</p>
							</li>
						</ul>
					</div>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our PHP Expert Now</router-link
					>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-go-wordpress.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
