<template>
	<section class="bg-white">
		<div v-if="itemsCount">
			<ImageWithInfo :obj="items[0]"></ImageWithInfo>
		</div>
		<VueSlickCarousel
			v-else
			v-bind="settings"
			class="portfolio-slider px-2"
			style="overflow-x: hidden"
		>
			<div
				style="width: 100%; display: inline-block; margin: 0; padding: 0"
				v-for="(item, index) in items"
				:key="index"
			>
				<ImageWithInfo :obj="item"></ImageWithInfo>
			</div>
		</VueSlickCarousel>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import ImageWithInfo from '../AssetComponents/ImageWithInfo.vue';
export default {
	components: {
		VueSlickCarousel,
		ImageWithInfo
	},
	computed: {
		itemsCount() {
			if (this.items.length > 1) return false;
			return true;
		}
	},
	data() {
		return {
			settings: {
				slidesToShow: 1,
				autoplay: true,
				dotsClass: 'slick-dots custom-dot-class',
				dots: false,
				variableWidth: true
			},
			items: [
				{
					color: 'idtrackbg',
					logo: '/id-track-logo.jpg',
					image1: '/id-track-mobile-1.png',
					image2: '/id-track-mobile-2.png',
					image3: '/id-track-main.png',
					title: 'ID Track',
					description: 'Entertainment App',
					paragraphs: [
						'An app that knits together a community of music lovers, enabling them to find, share and enjoy underrated music. The app gives its users a platform to find the track name, artist and album by uploading an image. Other members in the community comment on the post helping the post creator to know more about the particular music track. The end result is that music tracks that do not come to the world’s ears due to other dominant playlists get their deserved attention.',
						'The app is bolted with features that creates a social ecosystem for music lovers, event goers and music festival participants.'
					],
					features: [
						'Post creation',
						'image /video upload',
						'Commenting system',
						'Likes, favorites or reactions',
						'Music track suggestions',
						'Rich media responses'
					]
				},
				{
					color: 'guestmebg',
					logo: '/guest-me-logo.png',
					image1: '/guest-me-mobile-1.png',
					image2: '/guest-me-mobile-2.png',
					image3: '/guest-me-main.png',
					title: 'Guest Me',
					description: 'Chat with your favourite creators with built in live chat',
					paragraphs: [
						'A social live video streaming mobile app that helps anyone to become an artist to the global stage. Live streaming of events, personal life, travel — almost everything is the new rage. Guest Me is one such live streaming app that combines the best of social networking and live video streaming. It allows users to create their own personal live videos while broadcasting it to the world at large receiving instant reactions, comments and shares.',
						'Not just life streaming, Guest me can do a whole lot of other stuff as well.'
					],
					features: [
						'Viewers commenting system',
						'Broadcaster responses',
						'Group chat or one to one chat',
						'Video channel subscription',
						'In-app purchases',
						'Social media integrations'
					]
				},
				{
					color: 'awaybackbg',
					logo: '/awayback-logo.jpg',
					image1: '/awayback-mobile-1.png',
					image2: '/awayback-mobile-2.png',
					image3: '/awayback-main.png',
					title: 'Awayback',
					description: 'Augmented Personal Memories',
					paragraphs: [
						'Awayback is an immersive way of creating personal memories at physical locations. It enables users to create personal stories using Augmented reality in the backdrop of physical locations where they have been to. The immersive stories can be shared as images or animations to phone contacts or social media connections. Users can also set the memory as private or public for specific locations. Other users with whom the memory has been shared can see it only when they reach the actual location.',
						'The radical app idea comes with features like:'
					],
					features: [
						'Augmented reality animation',
						'Location pins',
						'Social sharing',
						'Commenting system',
						'Seen status',
						'Privacy settings'
					]
				},
				{
					color: 'chatagrambg',
					logo: '/chatagram-logo.jpg',
					image1: '/chatagram-mobile-1.png',
					image2: '/chatagram-mobile-2.png',
					image3: '/chatagram-main.png',
					title: 'Chatagram',
					description: 'Chat with your favourite creators with built in live chat',
					paragraphs: [
						'Social media messaging is too noisy. Instant messaging is too limited in features. Our client felt the need to build an instant messaging app that had the personalized feel of a social media app and the feature-richness of a full-stack communication app. They asked and we delivered.',
						'GenixBit Labs build a instant messaging app for real-time communication. The app allowed users to search and add friends, send and receive multimedia files, create personalized one-to-one chats or group chats, customize chat backgrounds and much more.'
					],
					features: []
				}
			]
		};
	}
};
</script>

<style scoped>
.portfolio-slider {
	padding: 0 !important;
}
.portfolio-slider button {
	display: none;
}
</style>