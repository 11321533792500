<template>
	<div class="game-icn-box">
		<div class="game-icn-box-icon" v-html="obj.svg"></div>
		<h4>{{ obj.title }}</h4>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.game-icn-box {
	width: 185px !important;
	text-align: center;
	transition: all 0.3s ease;
	min-height: 180px;
	position: relative;
  user-select:none;
	background:#f8f9fa;
  cursor:pointer;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	border: 2px solid #ecedee;
	padding:20px 3px;
	border-radius:10px
}

.game-icn-box-icon {
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 20px;
	transition: all 0.4s ease;
}
.game-icn-box h4 {
	font-size: 18px;
	margin: 15px 0 10px;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;
	line-height: 1.3;
	justify-content: center;
	width: 100%;
  font-size:18px !important;
  font-weight:bold
}
.game-icn-box-icon svg circle,
.game-icn-box-icon svg ellipse,
.game-icn-box-icon svg path,
.game-icn-box-icon svg polygon,
.game-icn-box-icon svg rect {
	transition: all 0.3s ease;
	fill: #6a6d6f;
}
.game-icn-box:hover {
	background: #1977cc;
}
.game-icn-box:hover h4{
  color:#fff
}

.game-icn-box .game-icn-box-icon svg:hover circle,
.game-icn-box .game-icn-box-icon svg:hover ellipse,
.game-icn-box .game-icn-box-icon svg:hover path,
.game-icn-box .game-icn-box-icon svg:hover polygon,
.game-icn-box .game-icn-box-icon svg:hover rect {
	fill: #1977cc;
}
</style>
