<template>
	<div>
		<div class="real-state-us-inner-box mhbox">
        <div style="position:relative">
			<div class="real-state-inner-icon" v-html="obj.svg"></div>
			<h5>{{ obj.title }}</h5>
        </div>
			<p>{{ obj.text }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.advantage-of-real-estate-main .real-state-us-inner-box {
	margin-top: 55px;
	padding-top: 30px;
	min-height: 250px;
}
.real-state-us-inner-box:hover {
	border: 1px solid #0082dd;
}
.real-state-us-inner-box {
	border: 1px solid #ecedee;
	-webkit-box-shadow: 0 1px 5px 0 rgb(72 73 121 / 15%);
	-moz-box-shadow: 0 1px 5px 0 rgba(72, 73, 121, 0.15);
	box-shadow: 0 1px 5px 0 rgb(72 73 121 / 15%);
	margin-top: 50px;
	border-radius: 10px;
	padding: 20px 20px 20px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.advantage-of-real-estate-main .real-state-inner-icon {
	position:absolute;
	width: 108px;
	height: 108px;
	line-height: 94px;
	background-color: #eef2f3;
	border: 8px solid #fff;
	top: -24px;
    right:-10px;
}
.real-state-inner-icon {
	width: 75px;
	height: 75px;
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	border-radius: 100%;
	line-height: 75px;
	text-align: center;
	margin-top: -57px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.advantage-of-real-estate-main .real-state-inner-icon svg {
	width: 50px;
	height: 50px;
}
.real-state-inner-icon svg {
	width: 44px;
	height: 44px;
}
.advantage-of-real-estate-main .real-state-us-inner-box h5 {
	width: calc(100% - 118px);
	padding-left: 0;
	margin: 0 10px 15px 0;
	font-weight: 600;
}
.real-state-us-inner-box h5 {
	font-size: 18px;
	font-weight: 500;
	width: calc(100% - 75px);
	padding-left: 10px;
	margin-bottom: 15px;
}
.real-state-us-inner-box:hover .real-state-inner-icon {
	background-color: #0082dd;
}
.advantage-of-real-estate-main .real-state-inner-icon,
.advantage-of-real-estate-main .real-state-inner-icon svg * {
	-webkit-transition: all 0.1s ease-in-out !important;
	-moz-transition: all 0.1s ease-in-out !important;
	-o-transition: all 0.1s ease-in-out !important;
	transition: all 0.1s ease-in-out !important;
}
.real-state-us-inner-box:hover .real-state-inner-icon svg * {
	fill: #fff;
}
</style>
