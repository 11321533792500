<template>
	<section class="padding-60 p-relative" style="background: #fff">
		<div class="container mt-5">
			<div class="title">
				<span>Why eCommerce</span>
				<h2 class="fxt-process-dev">How Custom eCommerce Development Benefit Your Business</h2>
				<p>
					Custom-built and not cookie cut — that is the speciality of being a top eCommerce
					development companies. Our custom eCommerce development services will give your store a
					unique edge that stands apart from competitors.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed text-left" style="color: #008dd2">
						Feature for your store’s good
					</h2>
					<div>
						<ul class="featchers-list">
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M29.6,20.4c-0.3-0.1-0.7-0.1-0.9,0.2l-1.1,1.1l-1.1-1.1c-0.3-0.3-0.8-0.3-1.2,0c0,0,0,0,0,0l-1.1,1.1l-1.1-1.1
                              c-0.3-0.3-0.8-0.3-1.2,0c0,0,0,0,0,0L21,21.7l-1.1-1.1c-0.2-0.2-0.6-0.3-0.9-0.2c-0.3,0.1-0.5,0.4-0.5,0.8v6.6h-5.8
                              c-0.5,0-0.8,0.4-0.8,0.8v5.8h-1.6c-0.5,0-0.8,0.4-0.8,0.8v3.3c-0.5,0-0.8,0.4-0.8,0.8v9.9C8.6,49.6,9,50,9.5,50H39
                              c0.5,0,0.8-0.4,0.8-0.8v-9.9c0-0.4-0.3-0.8-0.7-0.8v-3.3c0-0.5-0.4-0.8-0.8-0.8h-1.7v-5.8c0-0.5-0.4-0.8-0.8-0.8h-5.7v-6.6
                              C30.1,20.8,29.9,20.5,29.6,20.4z M20.2,23.2l0.2,0.2c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l1.1-1.1l1.1,1.1c0.3,0.3,0.8,0.3,1.2,0
                              c0,0,0,0,0,0l1.1-1.1l1.1,1.1c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0.2-0.2l0,7.9h-8.2V23.2z M38.2,48.4H10.3v-8.2h27.9V48.4z
                              M37.5,38.5H11.1V36h26.4V38.5z M34.9,29.4v4.9H13.6v-4.9h4.9v1.6h-1.6v1.6h14.8v-1.6H30v-1.6H34.9z"
											></path>
											<path
												d="M12.8,46.7h23.1c0.5,0,0.8-0.4,0.8-0.8v-3.3c0-0.5-0.4-0.8-0.8-0.8H12.8c-0.5,0-0.8,0.4-0.8,0.8v3.3
                              C11.9,46.3,12.3,46.7,12.8,46.7z M13.6,43.4H35v1.6H13.6V43.4z"
											></path>
											<path
												d="M35,10.5h6.3l-1.1,1.1l1.2,1.2l2.5-2.5c0.3-0.3,0.3-0.8,0-1.2c0,0,0,0,0,0l-2.5-2.5l-1.2,1.2l1.1,1.1H35V10.5z"
											></path>
											<path
												d="M37.2,20.1l2.5-2.5c0.3-0.3,0.3-0.8,0-1.2c0,0,0,0,0,0L37.2,14l-1.2,1.2l1.1,1.1h-3v1.6h3l-1.1,1.1L37.2,20.1z"
											></path>
											<path
												d="M30.1,3.9h3l-1.1,1.1l1.2,1.2l2.5-2.5c0.3-0.3,0.3-0.8,0-1.2c0,0,0,0,0,0L33.1,0l-1.2,1.2L33,2.2h-3V3.9z"
											></path>
											<rect x="16.1" y="8.8" width="1.6" height="1.6"></rect>
											<path
												d="M19.3,0.6h-6.6c-0.5,0-0.8,0.4-0.8,0.8v4.1h-1.2L9.5,1.2C9.4,0.8,9,0.6,8.6,0.6H5.3v1.6H8l4.7,15.9
                              c0.1,0.4,0.4,0.6,0.8,0.6h1.1c0.5,0.8,1.5,1,2.3,0.6c0.2-0.1,0.4-0.3,0.6-0.6h3.8c0.3,0.5,0.8,0.8,1.4,0.8c0.9,0,1.6-0.7,1.6-1.6
                              c0-0.9-0.7-1.6-1.6-1.6c-0.6,0-1.1,0.3-1.4,0.8h-3.8c-0.5-0.8-1.5-1-2.3-0.6c-0.2,0.1-0.4,0.3-0.6,0.6h-0.4l-0.5-1.6h11.4
                              c0.4,0,0.7-0.3,0.8-0.7l1.6-8.2c0-0.2,0-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-0.8V3.1c0-0.5-0.4-0.8-0.8-0.8h-4.9V1.4
                              C20.2,1,19.8,0.6,19.3,0.6z M13.6,2.2h4.9v3.3h-4.9V2.2z M13.2,13.8l-2-6.6h14.5l-0.3,1.6h-6.1v1.6h5.8l-0.7,3.3H13.2z M24.3,3.9
                              v1.6h-4.1V3.9H24.3z"
											></path>
											<rect x="31.7" y="8.8" width="1.6" height="1.6"></rect>
											<rect x="30.9" y="16.2" width="1.6" height="1.6"></rect>
										</g>
									</svg>
								</div>
								<p>
									Your customers will never have to drag around to complete a transaction.
									<strong>One-page checkout</strong> will ensure that it gets done in a jiffy.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M41.7,18.3v1.9c-1,0.4-1.7,1.3-1.7,2.3v6.7c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5v-6.7c0-1.1-0.7-2-1.7-2.3v-1.9
                           c2.6-0.4,4.4-2.8,4.1-5.3c-0.2-2.6-2.4-4.6-5-4.6s-4.8,2-5,4.6C37.3,15.5,39.1,17.8,41.7,18.3z M43.3,29.2c0,0.5-0.4,0.8-0.8,0.8
                           s-0.8-0.4-0.8-0.8v-6.7c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8V29.2z M42.5,10c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3
                           s-3.3-1.5-3.3-3.3C39.2,11.5,40.7,10,42.5,10z"
										></path>
										<path d="M3.3,10H5v1.7H3.3V10z"></path>
										<path d="M6.7,10h5v1.7h-5V10z"></path>
										<path d="M3.3,15h8.3v1.7H3.3V15z"></path>
										<path d="M3.3,20h8.3v1.7H3.3V20z"></path>
										<path d="M3.3,25H5v1.7H3.3V25z"></path>
										<path d="M6.7,25h5v1.7h-5V25z"></path>
										<path
											d="M45.8,5H34.1c-2-3.1-5.4-5-9.1-5c-3.7,0-7.1,1.9-9.1,5H4.2C1.9,5,0,6.9,0,9.2v26.7C0,38.1,1.9,40,4.2,40h16.4l-1.3,5.1
                           c-1.2,0.3-2,1.5-1.8,2.7c0.2,1.2,1.2,2.2,2.5,2.2h10c1.2,0,2.3-0.9,2.5-2.2c0.2-1.2-0.6-2.4-1.8-2.7L29.4,40h16.4
                           c2.3,0,4.2-1.9,4.2-4.2V9.2C50,6.9,48.1,5,45.8,5z M34.1,10h-5c0-1.3-0.2-2.7-0.4-4c1-0.4,2-0.9,2.8-1.5C33,5.9,33.9,7.9,34.1,10z
                           M20.8,11.7c0,1.3,0.2,2.7,0.4,4c-1,0.4-2,0.9-2.8,1.5c-1.5-1.5-2.4-3.5-2.6-5.6H20.8z M25,1.7c0.5,0,1.2,1.1,1.8,3.2
                           C26.2,4.9,25.6,5,25,5c-0.6,0-1.2-0.1-1.8-0.2C23.8,2.8,24.5,1.7,25,1.7z M21.6,4.3c-0.7-0.3-1.3-0.6-1.9-1c0.8-0.6,1.8-1,2.7-1.3
                           C22.1,2.8,21.8,3.5,21.6,4.3z M27.5,2c1,0.3,1.9,0.7,2.7,1.3c-0.6,0.4-1.2,0.7-1.9,1C28.2,3.5,27.9,2.8,27.5,2z M25,6.7
                           c0.7,0,1.4-0.1,2.2-0.2c0.2,1.2,0.3,2.4,0.3,3.6h-5c0-1.2,0.1-2.4,0.3-3.6C23.6,6.6,24.3,6.7,25,6.7z M27.5,11.7
                           c0,1.2-0.1,2.4-0.3,3.6c-1.4-0.3-2.9-0.3-4.3,0c-0.2-1.2-0.3-2.4-0.3-3.6H27.5z M21.6,17.3c0.2,0.8,0.5,1.6,0.9,2.3
                           c-1-0.3-1.9-0.7-2.7-1.3C20.3,17.9,20.9,17.6,21.6,17.3z M23.2,16.8c1.2-0.2,2.4-0.2,3.6,0C26.3,18.9,25.5,20,25,20
                           S23.8,18.9,23.2,16.8z M28.4,17.3c0.7,0.3,1.3,0.6,1.9,1c-0.8,0.6-1.8,1-2.7,1.3C27.9,18.9,28.2,18.1,28.4,17.3z M31.6,17.2
                           c-0.9-0.7-1.8-1.2-2.8-1.5c0.2-1.3,0.4-2.7,0.4-4h5C33.9,13.8,33,15.7,31.6,17.2L31.6,17.2z M18.4,4.4c0.9,0.7,1.8,1.2,2.8,1.5
                           c-0.2,1.3-0.4,2.7-0.4,4h-5C16.1,7.9,17,5.9,18.4,4.4z M4.2,6.7H15c-1.9,4.5-0.5,9.8,3.4,12.8s9.3,3,13.2,0c3.9-3,5.3-8.2,3.4-12.8
                           h10.8c1.4,0,2.5,1.1,2.5,2.5v24.2H1.7V9.2C1.7,7.8,2.8,6.7,4.2,6.7z M30,48.3H20c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h10
                           c0.5,0,0.8,0.4,0.8,0.8S30.5,48.3,30,48.3z M28.9,45h-7.9l1.2-5h5.4L28.9,45z M45.8,38.3H4.2c-1.4,0-2.5-1.1-2.5-2.5V35h46.7v0.8
                           C48.3,37.2,47.2,38.3,45.8,38.3z"
										></path>
										<path d="M23.3,35.8h3.3v1.7h-3.3V35.8z"></path>
									</svg>
								</div>
								<p>
									Grab the internet’s attention. Be in the first search results when customers
									search for products. Our
									<strong>SEO-optimized eCommerce web development</strong> will give you quite an
									advantage.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M25,15.5c2.9,0,5.6,1.3,7.3,3.4l1.3-0.9c-2-2.5-5.1-4.1-8.6-4.1c-6.1,0-11.1,5-11.1,11.1s5,11.1,11.1,11.1
                              c5.3,0,9.7-3.7,10.8-8.6c-0.8,0.5-1.5,1.1-2.2,1.5c-1.5,3.2-4.8,5.5-8.6,5.5c-5.3,0-9.5-4.3-9.5-9.5C15.5,19.7,19.7,15.5,25,15.5z"
											></path>
											<path
												d="M47.5,20.8h-2.3c-0.7,0.5-1.4,1-2.2,1.6H44h3.5c0.5,0,0.9,0.6,0.9,1.2v2.8c0,0.7-0.4,1.2-0.9,1.2H44h-1.3l-0.3,1.2
                              c-0.4,2.1-1.3,4-2.4,5.8l-0.7,1.1l0.9,0.9l2.5,2.5c0.2,0.2,0.2,0.4,0.2,0.6c0,0.3-0.2,0.6-0.4,0.9l-2,2c-0.3,0.3-0.6,0.4-1,0.4
                              c-0.2,0-0.4,0-0.5-0.2l-2.5-2.5l-0.9-0.9l-1.1,0.7c-1.8,1.1-3.7,2-5.8,2.4l-1.2,0.3V44v3.5c0,0.5-0.6,0.9-1.2,0.9h-2.8
                              c-0.7,0-1.2-0.4-1.2-0.9V44v-1.3l-1.2-0.3c-2.1-0.4-4-1.3-5.8-2.4l-1.1-0.7l-0.9,0.9l-2.5,2.5c-0.2,0.2-0.4,0.2-0.5,0.2
                              c-0.3,0-0.7-0.2-1-0.4l-2-2C7.2,40.3,7,40,7,39.7c0-0.2,0-0.4,0.2-0.6l2.5-2.5l0.9-0.9l-0.7-1.1c-1.1-1.8-2-3.7-2.4-5.8l-0.3-1.2H6
                              H2.5c-0.5,0-0.9-0.6-0.9-1.2v-2.8c0-0.7,0.4-1.2,0.9-1.2H6h1.3l0.3-1.2c0.4-2.1,1.3-4,2.4-5.8l0.7-1.1l-0.9-0.9l-2.5-2.5
                              C7.1,10.7,7,10.5,7,10.4c0-0.3,0.2-0.7,0.4-1l2-2C9.7,7.2,10,7,10.4,7c0.2,0,0.4,0,0.5,0.2l2.5,2.5l0.9,0.9l1.1-0.7
                              c1.8-1.1,3.7-2,5.8-2.4l1.2-0.3V6V2.5c0-0.5,0.6-0.9,1.2-0.9h2.8c0.7,0,1.2,0.4,1.2,0.9V6v1.3l1.2,0.3c2.1,0.4,4,1.3,5.8,2.4
                              l1.1,0.7l0.9-0.9l2.5-2.5C39.3,7.1,39.5,7,39.6,7c0.3,0,0.7,0.2,1,0.4l2,2C42.8,9.7,43,10,43,10.3c0,0.2,0,0.4-0.2,0.6l-1.9,1.9
                              l3.7-2.6c0-0.7-0.3-1.4-0.9-1.9l-2-2c-0.6-0.6-1.3-0.9-2.1-0.9c-0.6,0-1.2,0.2-1.7,0.7l-2.5,2.5c-1.9-1.2-4-2.1-6.3-2.6V2.5
                              c0-1.4-1.2-2.5-2.8-2.5h-2.8c-1.5,0-2.8,1.1-2.8,2.5V6c-2.3,0.5-4.4,1.4-6.3,2.6L12,6.1c-0.4-0.4-1-0.7-1.7-0.7
                              c-0.7,0-1.5,0.3-2.1,0.9l-2,2C5.3,9.4,5.2,11.1,6.1,12l2.5,2.5c-1.2,1.9-2.1,4-2.6,6.3H2.5c-1.4,0-2.5,1.2-2.5,2.8v2.8
                              c0,1.5,1.1,2.8,2.5,2.8H6c0.5,2.3,1.4,4.4,2.6,6.3L6.1,38c-1,1-0.9,2.6,0.2,3.7l2,2c0.6,0.6,1.3,0.9,2.1,0.9c0.6,0,1.2-0.2,1.7-0.7
                              l2.5-2.5c1.9,1.2,4,2.1,6.3,2.6v3.5c0,1.4,1.2,2.5,2.8,2.5h2.8c1.5,0,2.8-1.1,2.8-2.5V44c2.3-0.5,4.4-1.4,6.3-2.6l2.5,2.5
                              c0.4,0.4,1,0.7,1.7,0.7c0.7,0,1.5-0.3,2.1-0.9l2-2c1.1-1.1,1.2-2.7,0.2-3.7l-2.5-2.5c1.2-1.9,2.1-4,2.6-6.3h3.5
                              c1.4,0,2.5-1.2,2.5-2.8v-2.8C50,22.1,48.9,20.8,47.5,20.8z"
											></path>
											<path
												d="M27.1,24.5c0,0-0.1-0.1-0.1-0.1l-3.1-4.1c-0.4-0.6-1-0.9-1.7-0.9c-0.4,0-0.9,0.1-1.2,0.4l-1.4,1c-0.9,0.7-1.2,2-0.5,3
                              c0,0.1,3.8,5.2,5.5,7.6c0.3,0.5,0.9,0.8,1.5,0.8c0,0,0,0,0,0c0.4,0,0.8-0.1,1.1-0.4c4.3-3,21.8-15.5,21.9-15.5c0.9-0.7,1.2-2,0.5-3
                              l-1-1.4c-0.4-0.6-1-0.9-1.7-0.9c-0.4,0-0.9,0.1-1.2,0.4L27.6,24.1C27.4,24.3,27.2,24.4,27.1,24.5z M46.6,12.6
                              c0.2-0.2,0.6-0.1,0.8,0.1l1,1.4c0.2,0.3,0.1,0.6-0.1,0.8c0,0-17.6,12.5-21.8,15.5c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.2-0.1
                              c-1.7-2.4-5.5-7.5-5.5-7.6c-0.2-0.3-0.1-0.6,0.1-0.8l1.4-1c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.5,0.2l3,4.1
                              c0.1,0.2,0.4,0.6,0.6,0.8c0.2,0.1,0.4,0.2,0.6,0.2c0.5,0,1.2-0.4,1.6-0.7L46.6,12.6z"
											></path>
										</g>
									</svg>
								</div>
								<p>
									<strong>100% customizable.</strong> Your online store, your rules. From the look
									and feel to the backend functioning, you get to choose on how your store should
									function.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M47.3,12.4H32.8V3.1c0-1.7-1.3-3-3-3H3c-1.7,0-3,1.3-3,3V47c0,1.7,1.3,3,3,3h26.8c1.6,0,3-1.3,3-3v-9.3h14.6
                              c1.4,0,2.6-1.2,2.6-2.6V15C49.9,13.6,48.8,12.4,47.3,12.4z M48.4,15v2.7H26.8c-0.2-1.4-0.8-2.7-1.6-3.7h22.2
                              C48,13.9,48.4,14.4,48.4,15z M11.9,18.9c0-3.7,3-6.7,6.7-6.7s6.7,3,6.7,6.7s-3,6.7-6.7,6.7S11.9,22.5,11.9,18.9z M26.8,19.2h21.5
                              v2.6h-22C26.7,20.9,26.8,20,26.8,19.2z M3,1.6h26.8c0.9,0,1.5,0.7,1.5,1.5v3.6H1.5V3.1C1.5,2.3,2.2,1.6,3,1.6z M29.8,48.5H3
                              c-0.9,0-1.5-0.7-1.5-1.5v-3.6h29.8V47C31.3,47.8,30.6,48.5,29.8,48.5z M31.3,41.9H1.5V8.2h29.8v4.1h-7.6c-1.4-1.1-3.1-1.7-5.1-1.7
                              c-4.5,0-8.2,3.7-8.2,8.3c0,2.7,1.4,5.2,3.4,6.7v9.5c0,1.4,1.2,2.6,2.6,2.6h14.8V41.9z M47.3,36.2H16.5c-0.5,0-1-0.5-1-1v-8.7
                              c1,0.5,2.1,0.7,3.3,0.7c3,0,5.5-1.6,6.9-3.8h22.9v11.9C48.4,35.7,48,36.2,47.3,36.2z"
											></path>
											<path
												d="M42.6,27.8c-0.6,0-1.2,0.2-1.7,0.5c-0.5-0.3-1.1-0.5-1.7-0.5c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9
                              c0.6,0,1.2-0.2,1.7-0.5c0.5,0.4,1.1,0.5,1.7,0.5c1.6,0,2.9-1.3,2.9-2.9S44.1,27.8,42.6,27.8z M37.8,30.7c0-0.8,0.6-1.4,1.4-1.4
                              c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4C38.4,32.1,37.8,31.5,37.8,30.7z M42.6,32.2c-0.2,0-0.5-0.1-0.7-0.2
                              c0.2-0.4,0.2-0.8,0.2-1.2c0-0.4-0.1-0.9-0.2-1.2c0.2-0.2,0.5-0.2,0.7-0.2c0.8,0,1.4,0.6,1.4,1.4S43.4,32.2,42.6,32.2z"
											></path>
											<path
												d="M20.2,29.7h9.7c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-9.7c-0.4,0-0.8,0.3-0.8,0.8S19.7,29.7,20.2,29.7z"
											></path>
											<path
												d="M34.5,31.8H20.2c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h14.3c0.4,0,0.8-0.3,0.8-0.8S34.9,31.8,34.5,31.8z"
											></path>
											<path
												d="M22.8,16c-0.3-0.3-0.8-0.3-1.1,0l-4.2,4.2l-1.9-1.9c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l2.4,2.4
                              c0.2,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l4.8-4.8C23,16.8,23,16.2,22.8,16z"
											></path>
											<path
												d="M18.5,45.2h-4.1c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4.1c0.4,0,0.8-0.3,0.8-0.8S18.9,45.2,18.5,45.2z"
											></path>
											<path
												d="M14.2,4.7c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C13.4,5,13.9,5,14.2,4.7z"
											></path>
											<path
												d="M16.9,4.7c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.2-0.8-0.2-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C16.1,5,16.6,5,16.9,4.7z"
											></path>
											<path
												d="M19.7,4.7c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C18.9,5,19.3,5,19.7,4.7z"
											></path>
										</g>
									</svg>
								</div>
								<p>
									Your customers from all corners of the world would like to pay with modes of their
									choice. Meet their expectations with our constantly updated list of
									<strong>payment gateway integrations.</strong>
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M31.7,17.5h-6.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.9c0.3,0,0.6-0.3,0.6-0.6S32.1,17.5,31.7,17.5z"
										></path>
										<path
											d="M31.7,20.6h-6.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.9c0.3,0,0.6-0.3,0.6-0.6S32.1,20.6,31.7,20.6z"
										></path>
										<path
											d="M31.7,23.8h-6.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.9c0.3,0,0.6-0.3,0.6-0.6S32.1,23.8,31.7,23.8z"
										></path>
										<path
											d="M31.7,27h-6.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.9c0.3,0,0.6-0.3,0.6-0.6S32.1,27,31.7,27z"
										></path>
										<path
											d="M21.3,17.5h-8.1c-0.3,0-0.6,0.3-0.6,0.6v9.5c0,0.3,0.3,0.6,0.6,0.6h8.1c0.3,0,0.6-0.3,0.6-0.6V18
                           C21.9,17.7,21.7,17.5,21.3,17.5z M20.8,27h-6.9v-8.4h6.9V27z"
										></path>
										<path
											d="M32.1,11.3h2c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2c-0.3,0-0.6,0.3-0.6,0.6S31.8,11.3,32.1,11.3z"
										></path>
										<path
											d="M27.6,11.3h2c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2c-0.3,0-0.6,0.3-0.6,0.6S27.3,11.3,27.6,11.3z"
										></path>
										<path
											d="M36.6,11.3h2c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6h-2c-0.3,0-0.6,0.3-0.6,0.6S36.2,11.3,36.6,11.3z"
										></path>
										<path
											d="M6.4,12.2H10c0.3,0,0.6-0.3,0.6-0.6V9.8c0-0.3-0.3-0.6-0.6-0.6H6.4c-0.3,0-0.6,0.3-0.6,0.6v1.9C5.9,11.9,6.1,12.2,6.4,12.2z
                           M7,10.3h2.5V11H7V10.3z"
										></path>
										<path
											d="M48,16.8c-0.4-0.1-0.7-0.2-1.1-0.2h-2V6.7c0-1-0.8-1.8-1.8-1.8H1.8c-1,0-1.8,0.8-1.8,1.8V32c0,1,0.8,1.8,1.8,1.8h15.5
                           c-0.1,1.7-0.3,3.5-0.4,3.8c-0.2,0.3-0.4,0.5-1,0.8l-0.1,0.1c-0.5,0.3-0.8,0.6-0.8,1c0,0.2,0.1,0.5,0.3,0.6c0.3,0.2,0.7,0.3,1.4,0.3
                           h11.6l0.3,0c0.4,0,0.9,0,1.2-0.3c0.2-0.2,0.3-0.4,0.3-0.6c0-0.4-0.3-0.7-0.8-1l-0.1-0.1c-0.6-0.3-0.8-0.5-1-0.8
                           c-0.1-0.3-0.3-2.1-0.4-3.8H36v7.3c0,1.2,0.7,2.3,1.9,2.8c0.4,0.1,0.7,0.2,1.1,0.2h8c1.6,0,3-1.3,3-3V19.5
                           C49.9,18.3,49.2,17.2,48,16.8z M28.2,39.2H16.8c0.4-0.3,0.8-0.5,1.1-1c0.3-0.5,0.5-2.7,0.6-4.3h8c0.1,1.6,0.3,3.8,0.6,4.3
                           C27.4,38.6,27.7,38.9,28.2,39.2z M36,32.7H27H18H1.8c-0.3,0-0.6-0.3-0.6-0.7V6.7C1.2,6.4,1.5,6,1.8,6h41.3c0.3,0,0.6,0.3,0.6,0.7
                           v9.8h-0.6l0-3c0,0,0,0,0,0c0,0,0,0,0,0l0-6.2c0-0.3-0.3-0.6-0.6-0.6H2.4C2.1,6.7,1.9,7,1.9,7.3v6.1c0,0,0,0.1,0,0.1s0,0.1,0,0.1V31
                           c0,0.3,0.3,0.6,0.6,0.6H36L36,32.7L36,32.7z M42,12.9H3V7.9h39L42,12.9z M36,19.5v10.9H3V14.1h39l0,2.5h-3.1
                           C37.3,16.6,36,17.9,36,19.5z M42.9,42.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4S43.2,42.2,42.9,42.2z
                           M47,25.6v13.9h-8.2V25.6H47z M38.9,24.4v-3.2H47v3.2H38.9z M48.8,41.1c0,1-0.8,1.8-1.8,1.8h-3c0.3-0.3,0.5-0.7,0.5-1.1
                           c0-0.5-0.2-0.9-0.6-1.2h3.8c0.3,0,0.6-0.3,0.6-0.6V25c0,0,0,0,0,0c0,0,0,0,0,0v-4.4c0-0.3-0.3-0.6-0.6-0.6h-9.3
                           c-0.3,0-0.6,0.3-0.6,0.6V40c0,0.3,0.3,0.6,0.6,0.6H42c-0.4,0.3-0.6,0.7-0.6,1.2c0,0.5,0.2,0.9,0.5,1.1h-3c-0.2,0-0.5,0-0.7-0.1
                           c-0.7-0.3-1.2-1-1.2-1.7V19.5c0-1,0.8-1.8,1.8-1.8h3.6c0,0,0,0,0,0h4.4c0.2,0,0.5,0,0.7,0.1c0.7,0.3,1.2,1,1.2,1.7L48.8,41.1
                           L48.8,41.1z"
										></path>
										<path
											d="M45.4,35.2h-4.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h4.9c0.3,0,0.6-0.3,0.6-0.6S45.7,35.2,45.4,35.2z"
										></path>
										<path
											d="M45.4,37.4h-4.9c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h4.9c0.3,0,0.6-0.3,0.6-0.6S45.7,37.4,45.4,37.4z"
										></path>
										<path
											d="M40.1,34.6h5.8c0.3,0,0.6-0.3,0.6-0.6v-6.8c0-0.3-0.3-0.6-0.6-0.6h-5.8c-0.3,0-0.6,0.3-0.6,0.6V34
                           C39.5,34.4,39.7,34.6,40.1,34.6z M40.6,27.8h4.6v5.7h-4.6V27.8z"
										></path>
										<path
											d="M45.8,22.2h-1.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h1.4c0.3,0,0.6-0.3,0.6-0.6S46.1,22.2,45.8,22.2z"
										></path>
										<path
											d="M42.6,21.6h-2.6c-0.3,0-0.6,0.3-0.6,0.6v1.3c0,0.3,0.3,0.6,0.6,0.6h2.6c0.3,0,0.6-0.3,0.6-0.6v-1.3
                           C43.2,21.8,42.9,21.6,42.6,21.6z M42.1,22.9h-1.4v-0.2h1.4V22.9z"
										></path>
									</svg>
								</div>
								<p>
									Deliver a seamless shopping experience with a
									<strong>mobile responsive</strong> eCommerce website that works equally good on
									browsers as well as mobile devices.
								</p>
							</li>
							<li>
								<div class="web-why-go-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g class="advst0">
											<g class="advst1">
												<rect x="-5" y="-13" class="advst2" width="60" height="60"></rect>
												<line class="advst2" x1="25" y1="-13" x2="25" y2="47"></line>
												<line class="advst2" x1="55" y1="17" x2="-5" y2="17"></line>
												<line class="advst2" x1="-5" y1="-13" x2="55" y2="47"></line>
												<rect x="3" y="-5" class="advst2" width="44" height="44"></rect>
												<line class="advst2" x1="55" y1="-13" x2="-5" y2="47"></line>
												<line class="advst2" x1="-5" y1="17" x2="25" y2="-13"></line>
												<line class="advst2" x1="25" y1="-13" x2="55" y2="17"></line>
												<line class="advst2" x1="55" y1="17" x2="25" y2="47"></line>
												<line class="advst2" x1="25" y1="47" x2="-5" y2="17"></line>
												<circle class="advst2" cx="25" cy="17" r="21"></circle>
											</g>
										</g>
										<circle cx="4.2" cy="4.2" r="0.8"></circle>
										<circle cx="7.5" cy="4.2" r="0.8"></circle>
										<circle cx="10.8" cy="4.2" r="0.8"></circle>
										<path d="M20,10H3.3v13.3H20V10z M18.3,21.7H5v-10h13.3V21.7z"></path>
										<path
											d="M32.5,20h-0.8c-8.3,0-15,6.8-15,15s6.8,15,15,15s15-6.8,15-15v-0.8H32.5V20z M30.8,21.7v12L20,28.6
                           C22.1,24.7,26.2,22,30.8,21.7z M18.3,35c0-1.8,0.3-3.3,0.9-4.8l11.1,5.2l-7.9,9.3C19.9,42.2,18.3,38.8,18.3,35z M45,35.8
                           c-0.3,5.3-3.8,9.8-8.6,11.6l-3.6-11.6H45z M34.8,47.9c-1,0.3-2.1,0.4-3.2,0.4c-3,0-5.8-1-7.9-2.7l7.6-9L34.8,47.9z"
										></path>
										<path
											d="M35,16.7h-0.8v15.8H50v-0.8C50,23.4,43.3,16.7,35,16.7z M35.8,30.8V18.3c6.7,0.4,12.1,5.8,12.5,12.5H35.8z"
										></path>
										<path
											d="M1.7,45V8.3h40v8.1c0.6,0.3,1.2,0.5,1.7,0.8V0H0v46.7h19.8c-0.5-0.5-0.9-1.1-1.4-1.7H1.7z M1.7,1.7h40v5h-40V1.7z"
										></path>
										<rect x="21.7" y="10" width="18.3" height="1.7"></rect>
										<rect x="21.7" y="13.3" width="18.3" height="1.7"></rect>
										<path d="M3.3,43.3h13.9c-0.3-0.5-0.6-1.1-0.8-1.7H3.3V43.3z"></path>
										<path d="M3.3,40h12.4c-0.2-0.6-0.3-1.1-0.4-1.7h-12V40z"></path>
										<path d="M3.3,36.7h11.8C15,36.1,15,35.6,15,35H3.3V36.7z"></path>
										<path d="M15.3,31.7h-12v1.7h11.8C15.2,32.8,15.2,32.3,15.3,31.7z"></path>
										<path d="M3.3,30h12.4c0.2-0.6,0.4-1.1,0.7-1.7H3.3V30z"></path>
										<path d="M18.3,25h-15v1.7h13.9C17.6,26.1,17.9,25.5,18.3,25z"></path>
									</svg>
								</div>
								<p>
									Be in the know of how your store is performing, which product is selling the most
									and much more with <strong>advanced reporting module.</strong>
								</p>
							</li>
						</ul>
					</div>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our eCommerce Expert Now</router-link
					>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-go-ecommerce.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.advst0 {
	display: none;
}
.advst1 {
	display: inline;
}
.advst2 {
	fill: none;
	stroke: #b3b3b3;
	stroke-width: 0.1;
	stroke-miterlimit: 10;
}
</style>
