<template>
	<section class="padding-60 bg-white o-hidden">
		<div class="container">
			<div class="title">
				<span>Portfolio</span>
				<h2 class="fxt-port-dev">The Masterpieces We Have Crafted So Far</h2>
				<p>
					They asked, we delivered. Get to know behind-the-scenes details about the clients we’ve
					worked with and how our web and mobile app development service enhanced their business.
					Yours could be next.
				</p>
			</div>
		</div>
		<div class="fxt-portfolio-slider">
			<VueSlickCarousel v-bind="settings" class="portfolio-slider px-2" style="overflow-x: hidden">
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>MowMow</h3>
							<p>
								An Augmented Reality social game of creating, hiding and finding painted rocks in
								the digital world.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/mowmow.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>MowMow</h3>
							<p>
								An Augmented Reality social game of creating, hiding and finding painted rocks in
								the digital world.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>Adani Saksham</h3>
							<p>
								A mobile app to support the initiative of the Adani Foundation of Skill Development
								for the Youth of India.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/adani.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>Adani Saksham</h3>
							<p>
								A mobile app to support the initiative of the Adani Foundation of Skill Development
								for the Youth of India.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>Dorel Taxi</h3>
							<p>
								On-demand taxi booking app for Nigeria with features for passengers, drivers and
								admins.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/doreltaxi.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>Dorel Taxi</h3>
							<p>
								On-demand taxi booking app for Nigeria with features for passengers, drivers and
								admins.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>FantasyJi</h3>
							<p>
								A fantasy sports app that allows users to create private leagues, auctions and bid
								for players and play fantasy games.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/fantasyji.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>FantasyJi</h3>
							<p>
								A fantasy sports app that allows users to create private leagues, auctions and bid
								for players and play fantasy games.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>AK Microblading</h3>
							<p>
								A training academy app for students &amp; trainers with built-in chat and task
								management features.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/ak.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>AK Microblading</h3>
							<p>
								A training academy app for students &amp; trainers with built-in chat and task
								management features.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
				<div class="item" style="min-width: 350px !important; width: 100%; display: inline-block">
					<figure class="portfolio-figure" style="min-width: 450px !important">
						<div class="portfolio-title">
							<h3>Claireabella</h3>
							<p>
								A custom emoji creating app for UK’s popular Mrs. Bella and Claireabella characters.
							</p>
						</div>
						<img
							class="img-fluid lazy"
							alt="app development company"
							src="../../assets/images/claireabella.webp"
							style=""
							width="430"
							height="560"
						/>
						<figcaption class="portfolio-figure-caption">
							<h3>Claireabella</h3>
							<p>
								A custom emoji creating app for UK’s popular Mrs. Bella and Claireabella characters.
							</p>
							<a href="#" class="waves-effect waves-light" tabindex="-1">Explore More</a>
						</figcaption>
					</figure>
				</div>
			</VueSlickCarousel>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import $ from 'jquery';
export default {
	components: {
		VueSlickCarousel
	},
	methods: {
		onInitCarousel() {
			$('.portfolio-slider').width($('.slick-list').width() - 103);
		},
		checkScreen() {
			if (window.innerWidth < 776) this.settings.slidesToShow = 1;
		}
	},
	data() {
		return {
			settings: {
				slidesToShow: 4,
				autoplay: true,
				dotsClass: 'slick-dots custom-dot-class',
				dots: true,
				variableWidth: true
			}
		};
	},
	mounted() {
		this.checkScreen();
	}
};
</script>

<style scoped>
.portfolio-slider {
	display: block;
	width: 100%;
	position: relative;
	padding: 0 15px 20px !important;
	overflow: hidden;
}
.portfolio-slider .item {
	padding: 0 15px;
}
.portfolio-figure {
	width: 100%;
	overflow: hidden;
	transition: all 0.3s ease;
	background: #f9fafa;
	border-radius: 10px;
	text-align: center;
	position: relative;
	min-height: 510px;
}

figure {
	min-width: 450px !important;
	margin: 0 10px 1rem;
}
.portfolio-figure::before {
	content: '';
	width: 100%;
	height: 0;
	background: #202122;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
	transition: all 0.3s ease;
}
.portfolio-figure .img-fluid {
	height: 510px;
	object-fit: cover;
	margin: 0 auto;
}
.portfolio-figure-caption {
	width: 100%;
	background: #1977cc;
	padding: 20px;
	transition: all 0.3s ease;
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateY(100%);
}
.portfolio-figure-caption h3 {
	font-size: 24px;
	color: #fff;
	font-weight: 600;
}
.portfolio-figure-caption p {
	color: #fff;
}
.portfolio-figure-caption a {
	display: inline-block;
	border: 1px solid #fff;
	padding: 8px 20px;
	border-radius: 5px;
}
.portfolio-title {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding: 20px;
}

.portfolio-title p {
	font-size: 14px !important;
}
.portfolio-figure:hover .portfolio-figure-caption {
	transform: translateY(0);
}
.portfolio-figure-caption a:hover {
	background: #fff;
	box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.2);
}
.portfolio-figure:hover:before {
	height: 100%;
}
.slick-dots {
	position: absolute;
	bottom: -25px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	height: 60px;
	list-style: none;
	text-align: center;
}
.slick-dots button {
	width: 12px;
	height: 12px;
	background: #c8c8c8;
	opacity: 1;
	font-size: 0;
	border: none;
	border-radius: 50%;
	margin: 0;
	outline: 0;
	cursor: pointer;
}
.waves-light {
	color: #fff;
}
.slick-dots li.slick-active button {
	background-color: #1977cc;
}
.fxt-portfolio-slider .slick-slide {
	width: unset !important;
	min-width: 320px !important;
}
.fxt-portfolio-slider .slick-slide img {
	max-width: 90%;
}
.fxt-portfolio-slider .slick-slide h3 {
	font-size: 18px;
}
.fxt-portfolio-slider .slick-slide p {
	font-size: 14px;
}
figure {
	min-width: 270px !important;
	max-width: 290px !important;
}
@media (max-width: 1200px) {
	.fxt-portfolio-slider .slick-slide {
		width: unset !important;
		min-width: 390px !important;
		max-width: 400px !important;
		padding: 0;
	}
	.fxt-portfolio-slider .slick-slide h3 {
		font-size: 16px;
	}
	.fxt-portfolio-slider .slick-slide p {
		font-size: 12px;
	}
	figure {
		min-width: 270px !important;
		max-width: 290px !important;
	}
}
@media (max-width: 1200px) {
	.fxt-portfolio-slider .slick-slide {
		width: unset !important;
		min-width: 410px !important;
		max-width: 480px !important;
		padding: 0;
	}
	.fxt-portfolio-slider .slick-slide h3 {
		font-size: 16px;
	}
	.fxt-portfolio-slider .slick-slide p {
		font-size: 12px;
	}
	figure {
		min-width: 400px !important;
		max-width: 470px !important;
	}
}
@media (max-width: 576px) {
	.fxt-port-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.fxt-portfolio-slider .slick-slide {
		width: unset !important;
		min-width: 500px !important;
		max-width: 520px !important;
		padding: 0;
	}
	.fxt-portfolio-slider .slick-slide h3 {
		font-size: 18px;
	}
	.fxt-portfolio-slider .slick-slide p {
		font-size: 14px;
	}
	figure {
		min-width: 360px !important;
		max-width: 360px !important;
	}
}
</style>