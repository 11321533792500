<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Experience the Reality</span>
				<h2 class="fxt-process-dev">Types of Augmented Reality Apps We Develop</h2>
				<p>
					Knowing the types of AR technologies is essential to enrich the user experience with an
					interactive digital product. The types of augmented reality app development are classified
					into various categories each varies with its objectives and use cases.
				</p>
			</div>
			<div style="padding-top: 30px">
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-2" v-for="item in items" v-bind:key="item.title">
						<RoundedIcon v-bind:obj="item"></RoundedIcon>
					</div>
				</div>
			</div>
			<div class="augmented-text-container">
				<h3>Superimposition Based Augmented Reality</h3>
				<p>
					The Superimposition AR technology is a kind of haptic communication of brand and user.
					Superimposition Based AR replaces the original appearance of the object with an augmented,
					fully or partially enriched view of the same object. The object recognition plays an
					essential role in superimposition based AR. IKEA Catalog app is a great example of
					superimposition based augmented reality basically, it allows users to places virtual
					furniture in their home with the help of augmented reality.
				</p>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedIcon from '../AssetComponents/RoundedIcon';

export default {
	components: {
		RoundedIcon
	},
	data: () => {
		return {
			items: [
				{
					title: 'Marker-Based',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <g>
                    <rect x="20.3" y="33.6" width="8.6" height="1.6"></rect>
                    <path d="M50,46.9c0-0.1,0-0.2-0.1-0.3l0,0c0-0.1-0.1-0.1-0.1-0.2l-9.4-11.6c-0.6-0.7-1.5-1.2-2.5-1.2H32v1.6h2.8l8.9,10.9H6.2
                    l8.2-10.9h2.7v-1.6h-5.1c-1,0-1.9,0.4-2.5,1.2L0.2,46.4c0,0.1-0.1,0.1-0.1,0.2l0,0C0,46.7,0,46.8,0,46.9l0,0v2.3
                    C0,49.7,0.3,50,0.8,50h48.4c0.4,0,0.8-0.3,0.8-0.8V46.9L50,46.9z M36.8,35.2h1.1c0.5,0,1,0.2,1.3,0.6l8.4,10.3h-1.9L36.8,35.2z
                    M10.8,35.8c0.3-0.4,0.8-0.6,1.3-0.6h0.4L4.3,46.1H2.4L10.8,35.8z M48.4,48.4H1.6v-0.8h46.9V48.4z"></path>
                    <path d="M14.1,23c0,0.3,0.2,0.6,0.4,0.7l10.2,5.1h0c0.2,0.1,0.4,0.1,0.6,0h0l10.2-5.1c0.3-0.1,0.4-0.4,0.4-0.7V11.4
                    c0-0.1,0-0.2,0-0.2c0-0.3-0.2-0.6-0.5-0.7L25.2,7.1c-0.2-0.1-0.3-0.1-0.5,0l-10.2,3.3c-0.3,0.1-0.5,0.4-0.5,0.7c0,0.1,0,0.2,0,0.2
                    L14.1,23z M15.6,12.3l8.6,3.6v10.9l-8.6-4.3V12.3z M34.4,22.5l-8.6,4.3V15.9l8.6-3.6V22.5z M25,8.6l7.9,2.6L25,14.5l-7.9-3.3
                    L25,8.6z"></path>
                    <path d="M18,18.8h-1.6v2.3c0,0.3,0.2,0.6,0.4,0.7l1.6,0.8l0.7-1.4L18,20.6V18.8z"></path>
                    <path d="M4.7,1.6h7V0H3.9C3.5,0,3.1,0.3,3.1,0.8v7h1.6V1.6z"></path>
                    <path d="M9.4,4.7V3.1H7c-0.4,0-0.8,0.3-0.8,0.8v2.3h1.6V4.7H9.4z"></path>
                    <path d="M45.3,7.8h1.6v-7c0-0.4-0.3-0.8-0.8-0.8h-7.8v1.6h7V7.8z"></path>
                    <path d="M43.7,6.2V3.9c0-0.4-0.3-0.8-0.8-0.8h-2.3v1.6h1.6v1.6H43.7z"></path>
                    <rect x="18" y="30.2" transform="matrix(0.9285 -0.3714 0.3714 0.9285 -11.4236 9.4218)" width="1.6" height="8.4"></rect>
                    <rect x="7.4" y="15.9" transform="matrix(0.932 -0.3624 0.3624 0.932 -7.937 4.5659)" width="1.6" height="15.1"></rect>
                    <rect x="34.3" y="22.7" transform="matrix(0.3624 -0.932 0.932 0.3624 4.7996 53.8996)" width="15.1" height="1.6"></rect>
                    <rect x="10.5" y="13.8" transform="matrix(0.9191 -0.3939 0.3939 0.9191 -5.7009 5.82)" width="1.6" height="6"></rect>
                    <rect x="35.7" y="16" transform="matrix(0.3941 -0.9191 0.9191 0.3941 7.9932 45.7174)" width="6" height="1.6"></rect>
                    <rect x="26.3" y="33.6" transform="matrix(0.3714 -0.9285 0.9285 0.3714 -12.7685 49.8971)" width="8.4" height="1.6"></rect>
                    <rect x="10.2" y="43.8" width="26.6" height="1.6"></rect>
                    <rect x="38.3" y="43.8" width="1.6" height="1.6"></rect>
                  </g>
                </svg>
          `
				},
				{
					title: 'Markerless',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M49.4,39.8l-3.3-0.9v-6.1h-1.6v5.6l-3.2-0.9h0.1V27.3h-1.6v9.7L36.2,36l-0.2-0.1l0,0V22.4c-0.1-2.1-1.8-3.7-3.9-3.7h-22
                  c-2.1,0-3.8,1.6-3.9,3.7v1.8c-0.2,0.1-0.3,0.3-0.4,0.5l-3.4,6.2c0,0,0,0.1-0.1,0.1c-1.6,4.7-2.2,9.7-1.9,14.7l0.2,3.5l1.6-0.1
                  l-0.3-3.5c-0.3-4.8,0.3-9.5,1.8-14.1l3.4-6.1c0.1-0.2,0.3-0.3,0.5-0.2C8,25.3,8.1,25.6,8,25.8c0,0,0,0.1,0,0.1
                  C7.3,27,6.4,28.8,5.7,30C5,31.3,5.1,32.8,6,33.9c0.4,0.6,1.1,1,1.9,1c0.7,0,1.4-0.3,1.9-0.9l1-1.2c0.3-0.3,0.8-0.2,1.1,0.1
                  c0.2,0.2,0.2,0.5,0.1,0.7L9,38.8c-0.2,0.3-0.4,0.6-0.7,0.8l-0.8,0.7c-1.8,1.4-2.2,3.9-1,5.8l2.2,3.5C8.9,49.8,9.1,50,9.4,50h39.8
                  c0.4,0,0.8-0.3,0.8-0.8v-8.6C50,40.3,49.8,39.9,49.4,39.8z M34.4,22.4v14.5c0,0.9-0.7,1.7-1.6,2V20.4
                  C33.7,20.7,34.3,21.5,34.4,22.4z M7.8,23.7v-1.3c0-0.9,0.7-1.7,1.6-2v4.4c-0.2-0.5-0.6-0.8-1.1-1C8.1,23.8,8,23.7,7.8,23.7z
                  M7.8,33.4c-0.2,0-0.5-0.1-0.6-0.3c-0.5-0.7-0.6-1.5-0.2-2.2c0.7-1.3,1.6-3,2.3-4.2l0-0.1v5.3L8.5,33C8.3,33.2,8.1,33.4,7.8,33.4z
                  M13.3,34.4c0-0.1,0.1-0.2,0.1-0.3c0.7,1.8,4.7,2.6,8.5,2.6c4.1,0,8.6-1,8.6-3.1c0-1-0.9-1.7-2.5-2.3l-0.5,1.6
                  c1.1,0.4,1.4,0.7,1.5,0.8c-0.1,0.4-2.5,1.6-7,1.6s-6.9-1.1-7-1.6c0-0.1,0.4-0.5,1.8-0.9l-0.4-1.5c-1.4,0.4-2.3,1-2.7,1.6
                  c-0.3-1.2-1.4-1.9-2.6-1.8V20.3h20.3v18.7H10.7L13.3,34.4z M48.4,48.4H9.8l-2-3.1c-0.8-1.2-0.5-2.8,0.6-3.7l0.8-0.7
                  c0.1-0.1,0.2-0.2,0.3-0.3h22.5c1.8,0,3.4-1.3,3.8-3.1l12.6,3.7V48.4z"></path>
                  <path d="M48.4,0c-0.1,0-0.2,0-0.2,0L30.4,5.5L0.9,0C0.7,0,0.5,0,0.3,0.2C0.1,0.3,0,0.5,0,0.8v4.7c0,0.4,0.3,0.7,0.7,0.8l8.7,1.3
                  v9.7h1.6V7.7l7.8,1.1v8.3h1.6V9.1l6.2,0.9v7.1h1.6V9.4c0-0.4-0.3-0.7-0.7-0.8L1.6,4.8V1.7l28.1,5.2v10.3h1.6V6.8l17.3-5.3H50V0
                  H48.4z"></path>
                  <path d="M27.8,23.9C27.8,23.9,27.8,23.9,27.8,23.9c0-0.1,0-0.1,0-0.2v-0.1c0-0.1-0.1-0.1-0.1-0.1v0c0-0.1-0.1-0.1-0.2-0.1l0,0h0
                  c-0.1,0-0.1-0.1-0.2-0.1l0,0l-5.5-1.4c-0.1,0-0.3,0-0.4,0l-5.3,1.3l0,0c-0.1,0-0.1,0-0.2,0.1h0l0,0c-0.1,0-0.1,0.1-0.2,0.1
                  c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0v6.2c0,0.3,0.2,0.6,0.4,0.7l5.3,2.6
                  l0,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1h0l5.2-2.6c0.3-0.1,0.4-0.4,0.4-0.7V23.9C27.8,24,27.8,24,27.8,23.9L27.8,23.9z
                  M21.7,23.4l2.8,0.7l-2.8,1.2l-2.8-1.2L21.7,23.4z M17.2,25.2l3.7,1.6v4.8l-3.7-1.9V25.2z M26.2,29.7l-3.7,1.9v-4.8l3.7-1.6V29.7z"></path>
                  <path d="M45.1,5.5L34.2,8.6c-0.3,0.1-0.6,0.4-0.6,0.8v7.8h1.6V10l9.4-2.7v16.9h1.6V7H50V5.5h-4.7C45.2,5.5,45.2,5.5,45.1,5.5z"></path>
                </g>
              </svg>
          `
				},
				{
					title: 'Location-Based',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M50,46.9c0-0.1,0-0.2-0.1-0.3l0,0c0-0.1-0.1-0.1-0.1-0.2l-9.4-11.6c-0.5-0.6-1.3-1.1-2.1-1.1V32c0.4-0.4,0.8-0.8,1.1-1.2
                  l-1.1-1v-4l0,0c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0-0.1,0L32,25.4v-9c0-0.4-0.2-0.7-0.6-0.8l-7.8-2.3c-0.2-0.1-0.5,0-0.7,0.1l0,0l0,0
                  l-3.9,3.1c-0.2,0.2-0.3,0.4-0.3,0.7v6.3h-3.1c-0.4,0-0.8,0.3-0.8,0.8v3.9h-3.1c-0.4,0-0.8,0.3-0.8,0.8V29l-1.1,0.8
                  c0.3,0.5,0.7,0.9,1.1,1.3v2.7c-0.5,0.2-1,0.5-1.3,0.9L0.2,46.4c0,0.1-0.1,0.1-0.1,0.2l0,0C0,46.7,0,46.8,0,46.9l0,0v2.3
                  C0,49.7,0.3,50,0.8,50h48.4c0.4,0,0.8-0.3,0.8-0.8V46.9L50,46.9z M47.6,46.1h-1.9L39.1,38v-2c0-0.2,0-0.3-0.1-0.4
                  c0.1,0.1,0.2,0.2,0.3,0.2L47.6,46.1z M11.5,39.1c0.1,0,0.1,0,0.2,0h3.1V43c0,0.4,0.3,0.8,0.8,0.8h4.7c0.1,0,0.2,0,0.3-0.1
                  c0.1,0,0.2-0.1,0.3-0.2l2.2-2.2c0.1,0.1,0.2,0.1,0.4,0.1h3.1l1.9,1.4l0.1,0.1l0.1,0c0.1,0,0.2,0.1,0.3,0.1h9.4
                  c0.4,0,0.8-0.3,0.8-0.8v-1.8l4.6,5.7H6.2L11.5,39.1z M22.7,39.5l-1.6,1.6v-15l1.6,1.6V39.5z M28.1,27.6l6.3,1.9v4.3l-5.2-1.7l0,0
                  c-0.1,0-0.2,0-0.3,0h0c-0.1,0-0.2,0.1-0.3,0.1l0,0l-0.3,0.2L28.1,27.6z M29.7,33.9l7.8,2.6v4.9h-7.8V33.9z M36.7,34.6l-0.8-0.2
                  v-5.2l0.8-1V34.6z M28.1,40.6l-1.6-1.2v-3.9l1.6-1.2V40.6z M34.9,28l-2.2-0.7L31.6,27l4.3-0.3L34.9,28z M24.2,15.1l6.3,1.9v8.6
                  l-3.2,0.2h0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0l0,0c-0.1,0.1-0.1,0.1-0.2,0.2l0,0c0,0.1-0.1,0.2-0.1,0.3
                  c0,0,0,0,0,0.1s0,0,0,0v7l0,0l-1.2,0.9C25.2,34.6,25,34.8,25,35v4.7h-0.8V15.1z M20.3,17.6l2.3-1.9v9.8l-1.8-1.8l0,0
                  c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0h0V17.6z M16.4,25h3.1v17.2h-3.1V25z M12.5,29.7h2.3v7.8h-2.3V29.7z
                  M10.8,35.8c0,0,0.1-0.1,0.1-0.1v1.6l-6.6,8.9H2.4L10.8,35.8z M48.4,48.4H1.6v-0.8h46.9V48.4z"></path>
                  <path d="M38.1,5.3c-1-0.9-2-1.8-3.1-2.5l-0.8,1.3c1,0.6,2,1.4,2.9,2.3L38.1,5.3z"></path>
                  <path d="M42.5,11.9c-0.5-1.2-1.1-2.4-1.8-3.5l-1.3,0.9c0.7,1,1.2,2.1,1.7,3.2L42.5,11.9z"></path>
                  <path d="M42.2,18.8c0,0.3,0,0.6,0,0.9l1.6,0.1c0-0.3,0-0.7,0-1c0-1-0.1-2-0.2-3L42,16C42.1,16.9,42.2,17.8,42.2,18.8z"></path>
                  <path d="M40.3,26.7l1.4,0.7c0.6-1.2,1.1-2.4,1.4-3.7l-1.5-0.4C41.3,24.4,40.8,25.6,40.3,26.7z"></path>
                  <path d="M31.4,1.1c-1.3-0.5-2.6-0.8-3.9-1l-0.2,1.5c1.2,0.2,2.4,0.5,3.5,0.9L31.4,1.1z"></path>
                  <path d="M8.2,15l-1.5-0.3c-0.3,1.3-0.4,2.6-0.4,4h1.6C7.8,17.4,8,16.2,8.2,15z"></path>
                  <path d="M6.6,22.6c0.3,1.3,0.7,2.6,1.2,3.8l1.4-0.6c-0.5-1.1-0.9-2.3-1.1-3.5L6.6,22.6z"></path>
                  <path d="M23.7,1.6l-0.1-1.6c-1.3,0.1-2.6,0.3-3.9,0.7l0.4,1.5C21.3,1.9,22.5,1.7,23.7,1.6z"></path>
                  <path d="M11.3,8.4L10,7.5c-0.8,1.1-1.5,2.2-2,3.5l1.4,0.7C9.9,10.5,10.5,9.4,11.3,8.4z"></path>
                  <path d="M16.7,3.7L16,2.4c-1.2,0.6-2.3,1.4-3.3,2.3l1,1.1C14.7,5,15.7,4.3,16.7,3.7z"></path>
                </g>
                <g>
                  <path d="M12.7,16c-1.7,0-3.2,1.4-3.2,3.2c0,1.9,2.2,5,3,5.9l0.1,0.2l0.1-0.2c1-1.1,3-4.1,3-5.9C15.9,17.5,14.5,16,12.7,16z
                  M15,19.2c0,1.2-1.4,3.5-2.3,4.6c-0.8-1.1-2.3-3.4-2.3-4.6c0-1.2,1-2.3,2.3-2.3C14,16.9,15,17.9,15,19.2z"></path>
                  <path d="M12.7,17.5c-0.9,0-1.6,0.8-1.6,1.6c0,1.1,0.8,1.6,1.7,1.6c0.8,0,1.7-0.6,1.7-1.6C14.4,18.3,13.6,17.5,12.7,17.5z
                  M13.5,19.2c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.3-0.7,0.7-0.7C13.1,18.4,13.5,18.8,13.5,19.2z"></path>
                </g>
                <g>
                  <path d="M24.6,3.3c-1.7,0-3.2,1.4-3.2,3.2c0,1.9,2.2,5,3,5.9l0.1,0.2l0.1-0.2c1-1.1,3-4.1,3-5.9C27.8,4.7,26.4,3.3,24.6,3.3z
                  M26.9,6.5c0,1.2-1.4,3.5-2.3,4.6c-0.8-1.1-2.3-3.4-2.3-4.6c0-1.2,1-2.3,2.3-2.3C25.9,4.2,26.9,5.2,26.9,6.5z"></path>
                  <path d="M24.6,4.8c-0.9,0-1.6,0.8-1.6,1.6c0,1.1,0.8,1.6,1.7,1.6c0.8,0,1.7-0.6,1.7-1.6C26.3,5.6,25.6,4.8,24.6,4.8z M25.4,6.5
                  c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.3-0.7,0.7-0.7C25.1,5.7,25.4,6.1,25.4,6.5z"></path>
                </g>
                <g>
                  <path d="M36.3,15.1c-1.7,0-3.2,1.4-3.2,3.2c0,1.9,2.2,5,3,5.9l0.1,0.2l0.1-0.2c1-1.1,3-4.1,3-5.9C39.5,16.6,38.1,15.1,36.3,15.1z
                  M38.6,18.3c0,1.2-1.4,3.5-2.3,4.6c-0.8-1.1-2.3-3.4-2.3-4.6c0-1.2,1-2.3,2.3-2.3C37.6,16,38.6,17.1,38.6,18.3z"></path>
                  <path d="M36.3,16.7c-0.9,0-1.6,0.8-1.6,1.6c0,1.1,0.8,1.6,1.7,1.6c0.8,0,1.7-0.6,1.7-1.6C38,17.4,37.2,16.7,36.3,16.7z M37.1,18.3
                  c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.3-0.7,0.7-0.7C36.7,17.6,37.1,17.9,37.1,18.3z"></path>
                </g>
              </svg>
          `
				},
				{
					title: 'Projection',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M24.9,17.9c-5.1,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3S30,17.9,24.9,17.9z M24.9,35c-4.3,0-7.8-3.5-7.8-7.8
                    c0-4.3,3.5-7.8,7.8-7.8c4.3,0,7.8,3.5,7.8,7.8S29.3,35,24.9,35z"></path>
                  </g>
                  <g>
                    <path d="M24.9,23.4c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C28.8,25.1,27.1,23.4,24.9,23.4z M24.9,29.6
                    c-1.3,0-2.3-1.1-2.3-2.3c0-1.3,1.1-2.3,2.3-2.3c1.3,0,2.3,1.1,2.3,2.3C27.3,28.5,26.2,29.6,24.9,29.6z"></path>
                  </g>
                  <g>
                    <path d="M17.1,7.1c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8C16.3,6.8,16.6,7.1,17.1,7.1z"></path>
                  </g>
                  <g>
                    <path d="M14.8,7.1c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C14,6.8,14.4,7.1,14.8,7.1z"></path>
                  </g>
                  <g>
                    <path d="M12.6,7.1c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8C11.8,6.8,12.2,7.1,12.6,7.1z"></path>
                  </g>
                  <g>
                    <path d="M49.1,45.5H37l-4.6-6.3c6.4-1.7,12.4-5.7,17.2-11.5c0.2-0.3,0.2-0.7,0-0.9c-4.8-5.9-10.8-9.9-17.3-11.6l4.5-6.1h12.1
                    c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H36.3c-0.2,0-0.5,0.1-0.6,0.3l-5.1,6.9c-1.9-0.4-3.8-0.6-5.7-0.6
                    c-1.5,0-2.9,0.2-4.3,0.3V3.3c0-0.4-0.3-0.8-0.8-0.8H1c-0.4,0-0.8,0.3-0.8,0.8v20.1c0,0.4,0.3,0.8,0.8,0.8h1.6
                    c-0.8,0.8-1.6,1.6-2.3,2.6c-0.2,0.3-0.2,0.7,0,0.9c5,6.1,11.1,10.1,17.8,11.7l-4.5,6.1h-12c-0.4,0-0.8,0.3-0.8,0.8
                    c0,0.4,0.3,0.8,0.8,0.8H14c0.2,0,0.5-0.1,0.6-0.3l5.1-6.9c1.7,0.3,3.4,0.5,5.2,0.5c2,0,4-0.2,5.8-0.6l5.2,7.1
                    c0.2,0.2,0.4,0.3,0.6,0.3h12.4c0.4,0,0.8-0.3,0.8-0.8C49.8,45.8,49.5,45.5,49.1,45.5z M19.2,4v10.8c-0.9,0.2-1.7,0.4-2.6,0.6
                    l-6.3-6.8C10.2,8.5,10,8.4,9.8,8.4h-8V4H19.2z M1.8,12.8h2.9v4.4H1.8V12.8z M1.8,18.8h3.7c0.4,0,0.8-0.3,0.8-0.8v-5.9
                    c0-0.4-0.3-0.8-0.8-0.8H1.8V9.8h7.7l5.6,6.1c-3.9,1.4-7.6,3.7-10.9,6.7H1.8V18.8z M1.9,27.2c6.2-7.4,14.4-11.5,23.1-11.5
                    c8.7,0,16.9,4,23.1,11.5c-6.2,7.4-14.4,11.5-23.1,11.5C16.3,38.7,8.1,34.6,1.9,27.2z"></path>
                  </g>
                  <g>
                    <path d="M48.8,10.7h-8.7c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h8.7c0.4,0,0.8-0.3,0.8-0.8S49.3,10.7,48.8,10.7z"></path>
                  </g>
                  <g>
                    <path d="M48.8,14.2h-8.7c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h8.7c0.4,0,0.8-0.3,0.8-0.8C49.6,14.6,49.3,14.2,48.8,14.2z
                    "></path>
                  </g>
                  <g>
                    <path d="M2.2,42.5v0.9c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.9c0-0.4-0.3-0.8-0.8-0.8C2.6,41.8,2.2,42.2,2.2,42.5z"></path>
                  </g>
                  <g>
                    <path d="M5.3,42.5v0.9c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.9c0-0.4-0.3-0.8-0.8-0.8C5.7,41.8,5.3,42.2,5.3,42.5z"></path>
                  </g>
                  <g>
                    <path d="M8.4,42.5v0.9c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.9c0-0.4-0.3-0.8-0.8-0.8C8.8,41.8,8.4,42.2,8.4,42.5z"></path>
                  </g>
                  <g>
                    <path d="M12.3,41.8c-0.4,0-0.8,0.3-0.8,0.8v0.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.9C13,42.2,12.6,41.8,12.3,41.8z"></path>
                  </g>
                  <g>
                    <path d="M12.3,39.1H1.5c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h10.7c0.4,0,0.8-0.3,0.8-0.8C13,39.4,12.6,39.1,12.3,39.1z"></path>
                  </g>
                  <g>
                    <path d="M49.1,38.2h-9.6c-0.4,0-0.8,0.3-0.8,0.8v4.3c0,0.4,0.3,0.8,0.8,0.8h9.6c0.4,0,0.8-0.3,0.8-0.8V39
                    C49.8,38.5,49.5,38.2,49.1,38.2z M48.4,42.5h-8.2v-2.9h8.1L48.4,42.5L48.4,42.5z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Superimposition Based',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M37.2,25.1c0,7.2,0,14.5,0,21.7c0,2-1.2,3.2-3.2,3.2c-6.1,0-12.1,0-18.2,0c-1.9,0-3.1-1.2-3.1-3c0-14.7,0-29.3,0-44
                c0-1.7,1.2-3,3-3C21.9,0,28,0,34.1,0c1.9,0,3.1,1.2,3.1,3.1C37.2,10.4,37.2,17.7,37.2,25.1z M36.2,25c0-6,0-11.9,0-17.9
                c0-1.8-1.1-2.9-2.9-2.9c-5.5,0-11,0-16.5,0c-1.9,0-2.9,1.1-2.9,2.9c0,11.9,0,23.8,0,35.6c0,1.8,1.1,2.9,3,2.9c5.5,0,11,0,16.4,0
                c1.9,0,3-1.1,3-3C36.2,36.8,36.2,30.9,36.2,25z M36.2,4.3c0-0.5,0-0.8,0-1.2c0-1.4-0.7-2.1-2.1-2.1c-3.2,0-6.3,0-9.5,0
                c-2.9,0-5.9,0-8.8,0c-1,0-1.8,0.6-1.9,1.5c-0.1,0.5,0,1.1,0,1.7c1-1,2.2-1.1,3.5-1.1c5.1,0,10.2,0,15.3,0
                C33.9,3.2,35.1,3.3,36.2,4.3z M13.8,45.7c0,0.5,0,0.8,0,1.1c0,1.4,0.7,2.1,2.1,2.1c2.5,0,5.1,0,7.6,0c3.5,0,7.1,0,10.6,0
                c0.9,0,1.6-0.3,1.9-1.1c0.2-0.6,0.2-1.3,0.2-2c-1,0.8-2,1-3.2,1c-5.4,0-10.7,0-16.1,0C15.8,46.8,14.8,46.6,13.8,45.7z"></path>
                <path d="M10.7,17.1c0.2,0.3,0.4,0.6,0.6,0.9c-2.6,2-5.2,3.9-7.8,6c2.7,2,5.3,4,7.9,6c-0.2,0.3-0.4,0.5-0.6,0.8
                c-2.5-1.9-5-3.8-7.7-5.7c0,0.6,0,1.2,0,1.7c0,0.1,0.2,0.3,0.3,0.4c2.5,1.9,5,3.8,7.5,5.7c0.1,0.1,0.3,0.2,0.4,0.4
                c-0.2,0.3-0.4,0.5-0.6,0.8c-1.3-1-2.5-1.9-3.8-2.9C5.6,30,4,28.8,2.5,27.7c-0.3-0.2-0.4-0.4-0.4-0.7c0-1,0-1.9,0-2.9
                c0-0.2,0.1-0.4,0.2-0.5C5.1,21.4,7.9,19.3,10.7,17.1z"></path>
                <path d="M38.6,18c0.2-0.3,0.4-0.6,0.6-0.9c0.5,0.4,1,0.8,1.5,1.2c2.2,1.7,4.5,3.4,6.7,5.1c0.3,0.2,0.4,0.4,0.3,0.7c0,1,0,1.9,0,2.9
                c0,0.2-0.1,0.4-0.2,0.5c-2.8,2.1-5.5,4.2-8.3,6.3c0,0-0.1,0-0.2,0.1c-0.2-0.2-0.4-0.5-0.6-0.8c0.2-0.1,0.3-0.3,0.5-0.4
                c2.5-1.9,4.9-3.8,7.4-5.6c0.3-0.3,0.5-0.5,0.4-0.9c0-0.4,0-0.8,0-1.3c-2.6,1.9-5.1,3.8-7.7,5.7c-0.2-0.3-0.4-0.5-0.6-0.8
                c2.6-2,5.3-3.9,7.9-6C43.8,21.9,41.3,20,38.6,18z"></path>
                <path d="M34.3,33.3c0.2,0.3,0.4,0.6,0.6,0.8c-3.3,2.5-6.6,5-9.9,7.5c-3.3-2.5-6.6-5-9.9-7.5c0.2-0.3,0.4-0.5,0.6-0.8
                c0.2,0.1,0.3,0.2,0.5,0.3c2.8,2.1,5.6,4.2,8.4,6.4c0.3,0.3,0.5,0.3,0.9,0c2.8-2.1,5.6-4.3,8.4-6.4C33.9,33.5,34.1,33.4,34.3,33.3z"></path>
                <path d="M34.3,36.3c0.2,0.3,0.4,0.6,0.6,0.8c-3.3,2.5-6.6,5-9.9,7.5c-3.3-2.5-6.6-5-9.9-7.5c0.2-0.3,0.4-0.5,0.6-0.8
                c1,0.8,2,1.5,3,2.3c1.9,1.5,3.8,2.9,5.7,4.4c0.4,0.3,0.6,0.3,0.9,0c2.8-2.1,5.5-4.2,8.3-6.3C33.9,36.6,34.1,36.5,34.3,36.3z"></path>
                <g>
                  <path d="M25,32.9c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C26.3,32.4,25.7,32.9,25,32.9z M25,31.3
                  c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C25.4,31.4,25.2,31.3,25,31.3z"></path>
                  <path d="M18.3,32.9c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C19.6,32.4,19,32.9,18.3,32.9z M18.3,31.3
                  c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C18.7,31.4,18.5,31.3,18.3,31.3z"></path>
                  <path d="M17.9,30.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V30C18.3,30.2,18.1,30.4,17.9,30.4z"></path>
                  <path d="M17.9,30.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4l6.3-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4L17.9,30.4
                  C17.9,30.4,17.9,30.4,17.9,30.4z"></path>
                  <path d="M17.9,29.6c-0.2,0-0.4-0.1-0.4-0.3c0-0.2,0.1-0.4,0.3-0.5l6.3-1.3c0.2,0,0.4,0.1,0.5,0.3c0,0.2-0.1,0.4-0.3,0.5L17.9,29.6
                  C18,29.6,17.9,29.6,17.9,29.6z"></path>
                  <path d="M31.7,32.9c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C32.9,32.4,32.4,32.9,31.7,32.9z
                  M31.7,31.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4C32.1,31.4,31.9,31.3,31.7,31.3z"></path>
                  <path d="M32.1,30.4c-0.2,0-0.4-0.2-0.4-0.4v-0.8c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4V30C32.5,30.2,32.3,30.4,32.1,30.4z"></path>
                  <path d="M32.1,30.4C32.1,30.4,32.1,30.4,32.1,30.4L25.8,30c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4l6.3,0.4
                  c0.2,0,0.4,0.2,0.4,0.4C32.5,30.3,32.3,30.4,32.1,30.4z"></path>
                  <path d="M32.1,29.6C32.1,29.6,32,29.6,32.1,29.6l-6.3-1.3c-0.2,0-0.4-0.3-0.3-0.5c0-0.2,0.3-0.4,0.5-0.3l6.3,1.3
                  c0.2,0,0.4,0.3,0.3,0.5C32.5,29.5,32.3,29.6,32.1,29.6z"></path>
                  <path d="M25.8,30.4h-1.7c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h1.7c0.2,0,0.4,0.2,0.4,0.4C26.3,30.2,26.1,30.4,25.8,30.4z"></path>
                  <path d="M24.2,30.4c-0.2,0-0.4-0.2-0.4-0.4v-5c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v5C24.6,30.2,24.4,30.4,24.2,30.4z"></path>
                  <path d="M25.8,30.4c-0.2,0-0.4-0.2-0.4-0.4v-5c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v5C26.3,30.2,26.1,30.4,25.8,30.4z"></path>
                  <path d="M25.8,28.3h-1.7c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h1.7c0.2,0,0.4,0.2,0.4,0.4C26.3,28.2,26.1,28.3,25.8,28.3z"></path>
                  <path d="M23.3,25.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0.2,0.4,0.4,0.4
                  c0.2,0,0.4,0.2,0.4,0.4C23.8,25.2,23.6,25.4,23.3,25.4z"></path>
                  <path d="M26.7,25.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4-0.2,0.4-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4
                  C27.9,24.9,27.4,25.4,26.7,25.4z"></path>
                  <path d="M26.7,25.4h-3.3c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h3.3c0.2,0,0.4,0.2,0.4,0.4C27.1,25.2,26.9,25.4,26.7,25.4z"></path>
                  <path d="M24.2,26.7h-0.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h0.8c0.2,0,0.4,0.2,0.4,0.4C24.6,26.5,24.4,26.7,24.2,26.7z"></path>
                  <path d="M20.8,24.2c-1.2,0-2.1-0.9-2.1-2.1c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.7,0.6,1.3,1.3,1.3
                  c0.2,0,0.4,0.2,0.4,0.4C21.2,24,21.1,24.2,20.8,24.2z"></path>
                  <path d="M27.9,10c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4-0.2,0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
                  c0.2,0,0.4,0.2,0.4,0.4C29.2,9.4,28.6,10,27.9,10z"></path>
                  <path d="M29.2,24.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.7,0,1.3-0.6,1.3-1.3c0-0.2,0.2-0.4,0.4-0.4
                  c0.2,0,0.4,0.2,0.4,0.4C31.3,23.2,30.3,24.2,29.2,24.2z"></path>
                  <path d="M29.2,24.2h-8.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h8.4c0.2,0,0.4,0.2,0.4,0.4C29.6,24,29.4,24.2,29.2,24.2z"></path>
                  <path d="M27.5,24.6c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v0.4C27.9,24.4,27.7,24.6,27.5,24.6z"></path>
                  <path d="M22.5,24.6c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v0.4C22.9,24.4,22.7,24.6,22.5,24.6z"></path>
                  <path d="M19.2,22.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.7,0.6-1.3,1.3-1.3c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4s-0.4,0.2-0.4,0.4
                  C19.6,21.9,19.4,22.1,19.2,22.1z"></path>
                  <path d="M30.9,22.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
                  c0.7,0,1.3,0.6,1.3,1.3C31.3,21.9,31.1,22.1,30.9,22.1z"></path>
                  <path d="M30,21.2H20c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h10c0.2,0,0.4,0.2,0.4,0.4C30.4,21,30.3,21.2,30,21.2z"></path>
                  <path d="M19.2,22.5h-1.3c-0.2,0-0.4-0.2-0.4-0.4v-5.4c0-0.2,0.2-0.4,0.4-0.4h1.3c0.2,0,0.4,0.2,0.4,0.4v5.4
                  C19.6,22.3,19.4,22.5,19.2,22.5z M18.3,21.7h0.4v-4.6h-0.4V21.7z"></path>
                  <path d="M17.9,22.5h-0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h0.4c0.2,0,0.4,0.2,0.4,0.4C18.3,22.3,18.1,22.5,17.9,22.5z"></path>
                  <path d="M19.6,17.1h-2.1c-0.2,0-0.4-0.2-0.4-0.4v-1.3c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.3
                  C20,16.9,19.8,17.1,19.6,17.1z M17.9,16.2h1.3v-0.4h-1.3V16.2z"></path>
                  <path d="M32.1,22.5h-1.3c-0.2,0-0.4-0.2-0.4-0.4v-5.4c0-0.2,0.2-0.4,0.4-0.4h1.3c0.2,0,0.4,0.2,0.4,0.4v5.4
                  C32.5,22.3,32.3,22.5,32.1,22.5z M31.3,21.7h0.4v-4.6h-0.4V21.7z"></path>
                  <path d="M32.5,22.5h-0.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h0.4c0.2,0,0.4,0.2,0.4,0.4C32.9,22.3,32.8,22.5,32.5,22.5z"></path>
                  <path d="M32.5,17.1h-2.1c-0.2,0-0.4-0.2-0.4-0.4v-1.3c0-0.2,0.2-0.4,0.4-0.4h2.1c0.2,0,0.4,0.2,0.4,0.4v1.3
                  C32.9,16.9,32.8,17.1,32.5,17.1z M30.9,16.2h1.3v-0.4h-1.3V16.2z"></path>
                  <path d="M19.2,15.8c-0.2,0-0.4-0.2-0.4-0.4V7.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v7.9C19.6,15.6,19.4,15.8,19.2,15.8z"></path>
                  <path d="M30.9,15.8c-0.2,0-0.4-0.2-0.4-0.4V7.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4v7.9C31.3,15.6,31.1,15.8,30.9,15.8z"></path>
                  <path d="M30,7H20c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h10c0.2,0,0.4,0.2,0.4,0.4C30.4,6.8,30.2,7,30,7z"></path>
                  <path d="M28.8,9.1c-0.2,0-0.4-0.2-0.4-0.4l0-2.1c0-0.2,0.2-0.4,0.4-0.4h0c0.2,0,0.4,0.2,0.4,0.4l0,2.1C29.2,8.9,29,9.1,28.8,9.1z"></path>
                  <path d="M22.1,10c-0.3,0-0.7-0.1-0.9-0.4C21,9.4,20.8,9,20.8,8.7c0-0.2,0.2-0.4,0.4-0.4h0c0.2,0,0.4,0.2,0.4,0.4
                  c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.4,0.2,0.4,0.4C22.5,9.8,22.3,10,22.1,10z"></path>
                  <path d="M30.9,7.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2-0.1-0.3C30.2,7.1,30.1,7,30,7c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4
                  c0.3,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,0.9C31.3,7.7,31.1,7.9,30.9,7.9z"></path>
                  <path d="M19.2,7.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.7,0.6-1.3,1.3-1.3c0.2,0,0.4,0.2,0.4,0.4C20.4,6.8,20.2,7,20,7s-0.4,0.2-0.4,0.4
                  C19.6,7.7,19.4,7.9,19.2,7.9z"></path>
                  <path d="M21.2,9.1c-0.2,0-0.4-0.2-0.4-0.4l0-2.1c0-0.2,0.2-0.4,0.4-0.4h0c0.2,0,0.4,0.2,0.4,0.4l0,2.1C21.7,8.9,21.5,9.1,21.2,9.1z
                  "></path>
                  <path d="M27.9,10h-5.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h5.8c0.2,0,0.4,0.2,0.4,0.4C28.3,9.8,28.2,10,27.9,10z"></path>
                </g>
              </svg>
          `
				},
				{
					title: 'Outlining',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <rect x="28.4" y="17" width="1.5" height="3.7"></rect>
                  <rect x="28.4" y="13" width="1.5" height="2.5"></rect>
                  <rect x="28.4" y="22.4" width="1.5" height="5.4"></rect>
                  <path d="M47.6,7.3H10.8c-1.3,0-2.4,1.1-2.4,2.4v17H6L3.5,31h-3v1.5h2.2l-0.4,0.7C1,33.3,0,34.4,0,35.7v4.1c0,1.2,0.9,2.3,2.1,2.5
                  v0.9c0,1.4,1.1,2.5,2.5,2.5h1.8c1.4,0,2.5-1.1,2.5-2.5v-0.9h10.4v0.9c0,1.4,1.1,2.5,2.5,2.5h1.8c1.4,0,2.5-1.1,2.5-2.5v-0.9
                  c1.2-0.2,2.1-1.2,2.1-2.5v-2.3h19.2c1.3,0,2.4-1.1,2.4-2.4V9.7C50,8.3,48.9,7.3,47.6,7.3z M6.9,28.2h6.6v0.9h-1.1v1.5H16v-1.5h-1.1
                  v-0.9h6.6l2.9,5H4L6.9,28.2z M20.5,13l-5.8,13.7h-1.4V12h31.8v16.7H23.5l-1.1-2h-6.1L22,13L20.5,13L20.5,13z M7.5,43.2
                  c0,0.6-0.5,1-1,1H4.6c-0.6,0-1-0.5-1-1v-0.9h3.9L7.5,43.2L7.5,43.2z M24.7,43.2c0,0.6-0.5,1-1,1h-1.8c-0.6,0-1-0.5-1-1v-0.9h3.9
                  L24.7,43.2L24.7,43.2z M26.8,39.8c0,0.6-0.5,1-1,1H2.5c-0.6,0-1-0.5-1-1v-4.1c0-0.6,0.5-1,1-1h23.3c0.6,0,1,0.5,1,1L26.8,39.8
                  L26.8,39.8z M48.5,35.1c0,0.5-0.4,0.9-0.9,0.9H28.4v-0.3c0-1.3-1-2.4-2.2-2.5l-0.4-0.7h2.2V31h-3l-0.5-0.8h22.2V10.5H11.8v16.2H9.9
                  v-17c0-0.5,0.4-0.9,0.9-0.9h36.8c0.5,0,0.9,0.4,0.9,0.9V35.1z"></path>
                  <polygon points="44.1,27.8 37.9,13 36.4,13 42.6,27.8  "></polygon>
                  <circle cx="42.6" cy="33.1" r="0.9"></circle>
                  <circle cx="45.7" cy="33.1" r="0.9"></circle>
                  <path d="M5.6,35.3c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C8,36.4,6.9,35.3,5.6,35.3z M5.6,38.7
                  c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C6.5,38.3,6.1,38.7,5.6,38.7z"></path>
                  <path d="M22.8,35.3c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C25.2,36.4,24.1,35.3,22.8,35.3z
                  M22.8,38.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C23.7,38.3,23.3,38.7,22.8,38.7z"></path>
                  <rect x="9.9" y="37" width="8.7" height="1.5"></rect>
                </g>
              </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
.augmented-text-container {
	border: 1px solid #1977cc;
	padding: 20px 25px;
  border-radius:10px;
	text-align: center;
}
.augmented-text-container h3 {
	font-weight: bold;
	color: #1977cc;
}
.augmented-text-container p {
  margin-top:20px
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
