<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Social features for a true-blue social media app</h2>
				<p>
					From infinite newsfeed to individual profile management, we integrate all imaginable
					social media features into your mobile app.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../../IndustrySolutions/AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M38.44,7.83h-4.5V6.47c0-1.46-1.23-2.71-2.66-2.71h-1.64C29.12,1.57,27.18,0,24.82,0s-4.3,1.57-4.81,3.76h-1.64
                    c-1.43,0-2.66,1.15-2.66,2.71v1.36h-4.5c-2.46,0-4.4,1.98-4.4,4.28v33.61c0,2.4,1.94,4.28,4.4,4.28h27.33
                    c2.46,0,4.4-1.98,4.4-4.28V12.11C42.84,9.71,40.89,7.83,38.44,7.83z M17.66,6.37c0-0.31,0.31-0.63,0.72-0.63h2.46
                    c0.51,0,0.92-0.42,1.02-0.94c0.1-1.57,1.43-2.82,3.07-2.82S27.89,3.24,28,4.8c0,0.1,0.1,0.31,0.2,0.52
                    c0.2,0.31,0.51,0.42,0.82,0.42h2.46c0.41,0,0.72,0.31,0.72,0.63v4.18c0,0.31-0.31,0.63-0.72,0.63h-13.1
                    c-0.41,0-0.72-0.31-0.72-0.63C17.66,10.35,17.66,6.62,17.66,6.37z M40.89,45.82c0,1.25-1.13,2.3-2.46,2.3H11.21
                    c-1.33,0-2.46-1.04-2.46-2.3V12.11c0-1.25,1.13-2.3,2.46-2.3h4.5v0.84c0,1.46,1.23,2.71,2.66,2.71h12.9
                    c1.43,0,2.66-1.15,2.66-2.71V9.81h4.5c1.33,0,2.46,1.04,2.46,2.3V45.82z M21.24,40.19c-0.51,0-1.02,0.42-1.02,1.04v1.04h-6.55
                    v-6.26h4.5c0.51,0,1.02-0.42,1.02-1.04c0-0.63-0.41-1.04-1.02-1.04h-5.53c-0.51,0-1.02,0.42-1.02,1.04v8.35
                    c0,0.52,0.41,1.04,1.02,1.04h8.5c0.51,0,1.02-0.42,1.02-1.04v-2.09C22.26,40.61,21.85,40.19,21.24,40.19z M21.44,35.18l-3.68,3.55
                    l-1.43-1.46c-0.41-0.42-1.02-0.42-1.43,0c-0.41,0.42-0.41,1.04,0,1.46l2.15,2.09c0.2,0.21,0.41,0.31,0.72,0.31
                    c0.2,0,0.51-0.1,0.72-0.31l4.4-4.28c0.41-0.42,0.41-1.04,0-1.46C22.47,34.86,21.85,34.86,21.44,35.18z M36.9,36.01h-9.11
                    c-0.51,0-1.02,0.42-1.02,1.04c0,0.63,0.41,1.04,1.02,1.04h9.11c0.51,0,1.02-0.42,1.02-1.04C37.92,36.43,37.41,36.01,36.9,36.01z
                    M36.9,40.08h-9.11c-0.51,0-1.02,0.42-1.02,1.04c0,0.63,0.41,1.04,1.02,1.04h9.11c0.51,0,1.02-0.42,1.02-1.04
                    C37.92,40.5,37.41,40.08,36.9,40.08z M24.82,23.28c2.15,0,3.58-2.4,3.58-4.59c0-2.19-1.43-3.76-3.58-3.76
                    c-2.15,0-3.58,1.57-3.58,3.76C21.24,20.88,22.78,23.28,24.82,23.28z M24.82,17.01c1.02,0,1.64,0.63,1.64,1.77
                    c0,1.25-0.82,2.61-1.64,2.61c-0.82,0-1.64-1.25-1.64-2.61C23.18,17.64,23.8,17.01,24.82,17.01z M18.99,31.73c0.05,0,0.08,0,0.08,0
                    h11.39c0.61,0,1.13-0.31,1.54-0.73c0.41-0.52,0.61-1.15,0.41-1.88c-0.72-3.65-3.89-4.91-7.57-4.91c0,0,0,0-0.1,0
                    c-3.68,0-6.86,1.25-7.57,4.91c-0.1,0.63,0,1.36,0.41,1.88C17.96,31.42,18.48,31.73,18.99,31.73z M19.19,29.65
                    c0.2-1.46,1.13-3.34,5.63-3.34c4.5,0,5.42,1.88,5.73,3.34c0,0.1,0,0.21,0,0.21H19.19C19.19,29.85,19.09,29.75,19.19,29.65z
                    M24.72,5.22h0.31c0.51,0,1.02-0.42,1.02-1.04c0-0.63-0.41-1.04-1.02-1.04h-0.31c-0.51,0-1.02,0.42-1.02,1.04
                    C23.7,4.8,24.11,5.22,24.72,5.22z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Profile Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M25,12.9c-6.67,0-12.1,5.43-12.1,12.1c0,6.67,5.43,12.1,12.1,12.1c6.67,0,12.1-5.43,12.1-12.1
                    C37.1,18.33,31.67,12.9,25,12.9z M22.64,14.79c-0.68,0.74-1.44,1.71-2.12,2.9c-0.88-0.37-1.49-0.77-1.85-1.03
                    C19.83,15.78,21.18,15.13,22.64,14.79z M17.45,17.75c0.38,0.32,1.16,0.88,2.34,1.39c-0.64,1.44-1.1,3.13-1.2,5.05h-4.03
                    C14.75,21.7,15.81,19.45,17.45,17.75z M14.56,25.81h4.03c0.11,1.92,0.57,3.61,1.2,5.05c-1.18,0.51-1.96,1.08-2.34,1.39
                    C15.81,30.55,14.75,28.3,14.56,25.81z M18.67,33.34c0.35-0.27,0.97-0.67,1.85-1.03c0.68,1.19,1.44,2.16,2.12,2.9
                    C21.18,34.87,19.83,34.22,18.67,33.34z M24.19,34.49c-0.63-0.64-1.39-1.55-2.09-2.7c0.62-0.15,1.32-0.27,2.09-0.31V34.49z
                    M24.19,29.86c-1.08,0.06-2.04,0.23-2.87,0.46c-0.59-1.29-1.02-2.8-1.13-4.52h3.99V29.86z M24.19,24.19H20.2
                    c0.11-1.71,0.54-3.23,1.13-4.52c0.83,0.23,1.78,0.4,2.86,0.46V24.19z M24.19,18.52c-0.77-0.05-1.46-0.16-2.08-0.31
                    c0.7-1.15,1.46-2.06,2.08-2.7V18.52z M31.33,16.66c-0.35,0.27-0.97,0.67-1.85,1.03c-0.68-1.19-1.44-2.16-2.12-2.9
                    C28.82,15.13,30.17,15.78,31.33,16.66z M25.81,15.51c0.63,0.64,1.39,1.55,2.09,2.7c-0.62,0.15-1.32,0.27-2.09,0.31V15.51z
                    M25.81,20.14c1.08-0.06,2.04-0.23,2.87-0.46c0.59,1.29,1.02,2.8,1.13,4.52h-3.99V20.14z M25.81,25.81h3.99
                    c-0.11,1.71-0.54,3.23-1.13,4.52c-0.83-0.23-1.78-0.4-2.86-0.46V25.81z M25.81,31.48c0.77,0.05,1.46,0.16,2.08,0.31
                    c-0.7,1.15-1.46,2.06-2.08,2.7V31.48z M27.36,35.21c0.68-0.74,1.44-1.71,2.12-2.9c0.88,0.37,1.49,0.77,1.85,1.03
                    C30.17,34.22,28.82,34.87,27.36,35.21z M32.55,32.25c-0.38-0.32-1.16-0.88-2.34-1.39c0.64-1.44,1.1-3.13,1.2-5.05h4.03
                    C35.25,28.3,34.19,30.55,32.55,32.25z M31.41,24.19c-0.11-1.92-0.57-3.61-1.2-5.05c1.18-0.51,1.96-1.08,2.34-1.39
                    c1.63,1.7,2.7,3.95,2.89,6.44H31.41z M45.97,20.97c-0.21,0-0.4,0.03-0.6,0.06l-2.92-7.94c1.14-0.71,1.91-1.97,1.91-3.42
                    c0-2.22-1.81-4.03-4.03-4.03c-1.44,0-2.7,0.77-3.42,1.92l-7.94-2.92c0.03-0.2,0.06-0.4,0.06-0.6C29.03,1.81,27.22,0,25,0
                    c-2.22,0-4.03,1.81-4.03,4.03c0,0.21,0.03,0.4,0.06,0.6l-7.94,2.92c-0.71-1.14-1.97-1.91-3.42-1.91c-2.22,0-4.03,1.81-4.03,4.03
                    c0,1.44,0.77,2.7,1.91,3.42l-2.92,7.94c-0.2-0.03-0.4-0.06-0.6-0.06C1.81,20.97,0,22.78,0,25c0,2.22,1.81,4.03,4.03,4.03
                    c0.21,0,0.4-0.03,0.6-0.06l2.92,7.94c-1.14,0.71-1.91,1.97-1.91,3.42c0,2.22,1.81,4.03,4.03,4.03c1.44,0,2.7-0.77,3.42-1.91
                    l7.94,2.92c-0.03,0.2-0.06,0.4-0.06,0.6c0,2.22,1.81,4.03,4.03,4.03c2.22,0,4.03-1.81,4.03-4.03c0-0.21-0.03-0.41-0.06-0.6
                    l7.94-2.92c0.71,1.14,1.97,1.91,3.42,1.91c2.22,0,4.03-1.81,4.03-4.03c0-1.44-0.77-2.7-1.91-3.42l2.92-7.94
                    c0.2,0.03,0.4,0.06,0.6,0.06c2.22,0,4.03-1.81,4.03-4.03C50,22.78,48.19,20.97,45.97,20.97z M40.32,7.26
                    c1.33,0,2.42,1.09,2.42,2.42s-1.09,2.42-2.42,2.42c-1.33,0-2.42-1.09-2.42-2.42S38.99,7.26,40.32,7.26z M25,1.61
                    c1.33,0,2.42,1.09,2.42,2.42c0,1.33-1.09,2.42-2.42,2.42s-2.42-1.09-2.42-2.42C22.58,2.7,23.67,1.61,25,1.61z M7.26,9.68
                    c0-1.33,1.09-2.42,2.42-2.42s2.42,1.09,2.42,2.42s-1.09,2.42-2.42,2.42S7.26,11.01,7.26,9.68z M4.03,27.42
                    c-1.33,0-2.42-1.09-2.42-2.42c0-1.33,1.09-2.42,2.42-2.42S6.45,23.67,6.45,25C6.45,26.33,5.37,27.42,4.03,27.42z M9.68,42.74
                    c-1.33,0-2.42-1.09-2.42-2.42c0-1.33,1.09-2.42,2.42-2.42s2.42,1.09,2.42,2.42C12.1,41.66,11.01,42.74,9.68,42.74z M25,48.39
                    c-1.33,0-2.42-1.09-2.42-2.42c0-1.33,1.09-2.42,2.42-2.42s2.42,1.09,2.42,2.42C27.42,47.3,26.33,48.39,25,48.39z M42.74,40.32
                    c0,1.33-1.09,2.42-2.42,2.42c-1.33,0-2.42-1.09-2.42-2.42c0-1.33,1.09-2.42,2.42-2.42C41.66,37.9,42.74,38.99,42.74,40.32z
                    M40.93,36.35c-0.2-0.03-0.4-0.06-0.6-0.06c-0.82,0-1.58,0.25-2.22,0.67l-2.27-2.27l-1.14,1.14l2.27,2.27
                    c-0.42,0.64-0.67,1.4-0.67,2.22c0,0.21,0.03,0.41,0.06,0.6l-7.94,2.92c-0.58-0.93-1.51-1.61-2.61-1.83v-2.5h-1.61v2.5
                    c-1.1,0.22-2.03,0.91-2.61,1.83l-7.94-2.92c0.03-0.2,0.06-0.4,0.06-0.6c0-0.82-0.25-1.58-0.67-2.22l2.27-2.27l-1.14-1.14
                    l-2.27,2.27c-0.64-0.42-1.4-0.67-2.22-0.67c-0.21,0-0.4,0.03-0.6,0.06l-2.92-7.94c0.93-0.58,1.61-1.51,1.83-2.61h2.5v-1.61h-2.5
                    c-0.23-1.1-0.91-2.03-1.83-2.61l2.92-7.94c0.2,0.03,0.4,0.06,0.6,0.06c0.82,0,1.58-0.25,2.22-0.67l2.27,2.27l1.14-1.14l-2.27-2.27
                    c0.42-0.64,0.67-1.4,0.67-2.22c0-0.21-0.03-0.4-0.06-0.6l7.94-2.92c0.58,0.93,1.51,1.61,2.61,1.83v2.5h1.61v-2.5
                    c1.1-0.23,2.03-0.91,2.61-1.83l7.94,2.92c-0.03,0.2-0.06,0.4-0.06,0.6c0,0.82,0.25,1.58,0.67,2.22l-2.27,2.27l1.14,1.14l2.27-2.27
                    c0.64,0.42,1.4,0.67,2.22,0.67c0.21,0,0.4-0.03,0.6-0.06l2.92,7.94c-0.93,0.58-1.61,1.51-1.83,2.61h-2.5v1.61h2.5
                    c0.22,1.1,0.91,2.03,1.83,2.61L40.93,36.35z M45.97,27.42c-1.33,0-2.42-1.09-2.42-2.42c0-1.33,1.09-2.42,2.42-2.42
                    c1.33,0,2.42,1.09,2.42,2.42C48.39,26.33,47.3,27.42,45.97,27.42z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Networking'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path class="st0" d="M220-3506.5h-49c-1.93,0-3.5,1.56-3.5,3.48v65.03c0,1.92,1.57,3.48,3.5,3.48h49c1.93,0,3.5-1.56,3.5-3.48
                  v-65.03C223.5-3504.94,221.93-3506.5,220-3506.5z M221.17-3437.98c0,0.64-0.52,1.16-1.17,1.16h-49c-0.64,0-1.17-0.52-1.17-1.16
                  v-56.9h51.33V-3437.98z M221.17-3497.21h-51.33v-5.81c0-0.64,0.52-1.16,1.17-1.16h49c0.64,0,1.17,0.52,1.17,1.16V-3497.21z
                  M172.17-3499.53h2.33v-2.32h-2.33V-3499.53z M176.83-3499.53h2.33v-2.32h-2.33V-3499.53z M181.5-3499.53h2.33v-2.32h-2.33
                  V-3499.53z M207.17-3499.53h2.33v-2.32h-2.33V-3499.53z M211.83-3499.53h2.33v-2.32h-2.33V-3499.53z M216.5-3499.53h2.33v-2.32
                  h-2.33V-3499.53z M173.33-3471.66h30.33v8.13c0,0.5,0.32,0.94,0.8,1.1c0.12,0.04,0.25,0.06,0.37,0.06c0.36,0,0.71-0.17,0.93-0.46
                  l3.15-4.18h5.25c0.47,0,0.9-0.28,1.08-0.72c0.18-0.43,0.08-0.93-0.25-1.27l-2.68-2.66h5.35c0.64,0,1.17-0.52,1.17-1.16v-18.58
                  c0-0.64-0.52-1.16-1.17-1.16h-44.33c-0.64,0-1.17,0.52-1.17,1.16v18.58C172.17-3472.18,172.69-3471.66,173.33-3471.66z
                  M208.33-3469.34c-0.37,0-0.71,0.17-0.93,0.46l-1.4,1.86v-7.65l5.35,5.33H208.33z M174.5-3490.24h42v16.26h-6.52l-4.32-4.31
                  c-0.33-0.33-0.84-0.43-1.27-0.25c-0.44,0.18-0.72,0.6-0.72,1.07v3.48H174.5V-3490.24z M193.17-3469.34h-21v2.32h21V-3469.34z
                  M193.17-3464.69h-21v2.32h21V-3464.69z M193.17-3460.05h-21v2.32h21V-3460.05z M193.17-3455.4h-21v2.32h21V-3455.4z
                  M197.83-3453.08h21v-2.32h-21V-3453.08z M197.83-3448.44h21v-2.32h-21V-3448.44z M197.83-3443.79h21v-2.32h-21V-3443.79z
                  M197.83-3439.15h21v-2.32h-21V-3439.15z M173.33-3439.15H192c0.64,0,1.17-0.52,1.17-1.16v-9.29c0-0.64-0.52-1.16-1.17-1.16h-18.67
                  c-0.64,0-1.17,0.52-1.17,1.16v9.29C172.17-3439.67,172.69-3439.15,173.33-3439.15z M174.5-3448.44h16.33v6.97H174.5V-3448.44z
                  M201.33-3484.44c1.93,0,3.5,1.56,3.5,3.48h2.33c0-3.2-2.62-5.81-5.83-5.81s-5.83,2.6-5.83,5.81c0,3.2,2.62,5.81,5.83,5.81v-2.32
                  c-1.93,0-3.5-1.56-3.5-3.48S199.4-3484.44,201.33-3484.44z"></path>
                </g>
                <g>
                  <g>
                    <path d="M41.17,0H7.83C6.51,0,5.44,1.06,5.44,2.37v44.26c0,1.31,1.07,2.37,2.38,2.37h33.35c1.31,0,2.38-1.06,2.38-2.37V2.37
                    C43.56,1.06,42.49,0,41.17,0z M41.97,46.63c0,0.44-0.36,0.79-0.79,0.79H7.83c-0.44,0-0.79-0.35-0.79-0.79V7.9h34.93V46.63z
                    M41.97,6.32H7.03V2.37c0-0.44,0.36-0.79,0.79-0.79h33.35c0.44,0,0.79,0.35,0.79,0.79V6.32z M8.62,4.74h1.59V3.16H8.62V4.74z
                    M11.8,4.74h1.59V3.16H11.8V4.74z M14.97,4.74h1.59V3.16h-1.59V4.74z M32.44,4.74h1.59V3.16h-1.59V4.74z M35.62,4.74h1.59V3.16
                    h-1.59V4.74z M38.79,4.74h1.59V3.16h-1.59V4.74z M9.41,23.71h20.64v5.53c0,0.34,0.22,0.64,0.54,0.75
                    c0.08,0.03,0.17,0.04,0.25,0.04c0.25,0,0.48-0.11,0.64-0.32l2.14-2.84h3.57c0.32,0,0.61-0.19,0.73-0.49
                    c0.12-0.3,0.05-0.64-0.17-0.86l-1.82-1.81h3.64c0.44,0,0.79-0.35,0.79-0.79V10.27c0-0.44-0.36-0.79-0.79-0.79H9.41
                    c-0.44,0-0.79,0.35-0.79,0.79v12.64C8.62,23.36,8.98,23.71,9.41,23.71z M33.23,25.29c-0.25,0-0.49,0.12-0.64,0.32l-0.95,1.26v-5.2
                    l3.64,3.62H33.23z M10.21,11.06h28.58v11.06h-4.43l-2.94-2.93c-0.23-0.23-0.57-0.29-0.87-0.17c-0.3,0.12-0.49,0.41-0.49,0.73v2.37
                    H10.21V11.06z M22.91,25.29H8.62v1.58h14.29V25.29z M22.91,28.45H8.62v1.58h14.29V28.45z M22.91,31.61H8.62v1.58h14.29V31.61z
                    M22.91,34.77H8.62v1.58h14.29V34.77z M26.09,36.35h14.29v-1.58H26.09V36.35z M26.09,39.51h14.29v-1.58H26.09V39.51z M26.09,42.68
                    h14.29V41.1H26.09V42.68z M26.09,45.84h14.29v-1.58H26.09V45.84z M9.41,45.84h12.7c0.44,0,0.79-0.35,0.79-0.79v-6.32
                    c0-0.44-0.36-0.79-0.79-0.79H9.41c-0.44,0-0.79,0.35-0.79,0.79v6.32C8.62,45.48,8.98,45.84,9.41,45.84z M10.21,39.51h11.12v4.74
                    H10.21V39.51z M28.47,15.02c1.31,0,2.38,1.06,2.38,2.37h1.59c0-2.18-1.78-3.95-3.97-3.95c-2.19,0-3.97,1.77-3.97,3.95
                    c0,2.18,1.78,3.95,3.97,3.95v-1.58c-1.31,0-2.38-1.06-2.38-2.37C26.09,16.08,27.16,15.02,28.47,15.02z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'News Feed'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M38.46,0H11.39c-1.4,0-2.54,1.12-2.54,2.5v45c0,1.38,1.14,2.5,2.54,2.5h27.08c1.4,0,2.54-1.12,2.54-2.5V26.67h-1.69V35
                    h-5.08v-9.17c0-2.3-1.9-4.17-4.23-4.17h-1.84c1.34-0.73,2.41-1.9,3.01-3.33h5.6h2.54v3.33H41V17.5V4.17V2.5
                    C41,1.12,39.86,0,38.46,0z M33.39,36.67h5.92V47.5c0,0.46-0.38,0.83-0.85,0.83H11.39c-0.47,0-0.85-0.37-0.85-0.83V36.67h5.92
                    H33.39z M30,23.33c1.4,0,2.54,1.12,2.54,2.5V35H30.1l0.74-6.57c0.05-0.46-0.28-0.87-0.75-0.92c-0.46-0.05-0.88,0.28-0.93,0.74
                    L28.4,35h-6.95l-0.76-6.76c-0.05-0.46-0.46-0.79-0.93-0.74c-0.46,0.05-0.8,0.46-0.75,0.92L19.75,35h-2.44v-9.17
                    c0-1.38,1.14-2.5,2.54-2.5H30z M24.92,20.83c-1.5,0-2.91-0.65-3.88-1.77c-0.98-1.14-1.38-2.6-1.13-4.1c0.33-1.95,1.88-3.55,3.86-4
                    c0.38-0.09,0.77-0.13,1.15-0.13c0.28,0,0.57,0.02,0.85,0.07v4.93c0,1.38,1.14,2.5,2.54,2.5h1.01
                    C28.42,19.85,26.75,20.83,24.92,20.83z M35.92,16.67h-5.08V15c0-0.46,0.38-0.83,0.85-0.83h3.38c0.47,0,0.85,0.37,0.85,0.83V16.67z
                    M31.69,10c0-0.92,0.76-1.67,1.69-1.67c0.93,0,1.69,0.75,1.69,1.67c0,0.92-0.76,1.67-1.69,1.67C32.45,11.67,31.69,10.92,31.69,10z
                    M39.31,16.67h-1.69V15c0-1.22-0.9-2.24-2.07-2.45c0.74-0.61,1.23-1.52,1.23-2.55c0-1.84-1.52-3.33-3.38-3.33
                    C31.52,6.67,30,8.16,30,10c0,1.03,0.48,1.93,1.23,2.55c-1.18,0.22-2.07,1.23-2.07,2.45v1.67h-0.85c-0.47,0-0.85-0.37-0.85-0.83
                    v-5.59V5h11.85V16.67z M39.31,3.33H26.62H19V5h6.77v4.23c-0.79-0.1-1.58-0.07-2.37,0.11c-2.64,0.59-4.71,2.74-5.15,5.35
                    c-0.33,1.97,0.21,3.95,1.5,5.45c0.54,0.63,1.19,1.14,1.91,1.53h-1.82c-2.33,0-4.23,1.87-4.23,4.17V35h-5.08V5h3.38V3.33h-3.38V2.5
                    c0-0.46,0.38-0.83,0.85-0.83h27.08c0.47,0,0.85,0.37,0.85,0.83V3.33z M39.31,25H41v-1.67h-1.69V25z M15.62,5h1.69V3.33h-1.69V5z
                    M24.92,46.67c2.33,0,4.23-1.87,4.23-4.17c0-2.3-1.9-4.17-4.23-4.17c-2.33,0-4.23,1.87-4.23,4.17
                    C20.69,44.8,22.59,46.67,24.92,46.67z M24.92,40c1.4,0,2.54,1.12,2.54,2.5c0,1.38-1.14,2.5-2.54,2.5c-1.4,0-2.54-1.12-2.54-2.5
                    C22.39,41.12,23.52,40,24.92,40z M25.77,41.67h-1.69v1.67h1.69V41.67z M13.08,43.33h5.08v-1.67h-5.08V43.33z M36.77,41.67h-5.08
                    v1.67h5.08V41.67z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Audio Video Calling'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M49.22,35.87H48.2V20.6c-0.16-4.81-4.3-7.98-5.63-8.84V4.33c0-1.32-1.02-2.33-2.34-2.33H2.34C1.02,2,0,3.09,0,4.33v26.04
                    c0,1.32,1.02,2.33,2.34,2.33h32.42c0.7,1.4,1.25,1.78,1.72,3.1h-0.86c-0.47,0-0.78,0.31-0.78,0.78v9.61
                    c0,0.47,0.31,0.78,0.78,0.78h13.59c0.47,0,0.78-0.31,0.78-0.78v-9.53C50,36.18,49.69,35.87,49.22,35.87z M37.19,3.55h3.13
                    c0.31,0,0.7,0.39,0.7,0.77c0,4.42,0,7.52,0,11.94c0,0.16,0.08,0.39,0.16,0.47c0.7,0.85,1.02,3.8,0.86,7.21
                    c-0.08,0.7-0.7,1.24-1.48,1.08c-0.78-0.15-0.86-0.7-0.86-2.63c0-0.62,0-1.32-0.08-2.09c-0.16-1.4-1.02-2.25-2.42-2.48V3.55z
                    M33.91,20.6c-0.08,3.26,0.08,2.48-0.16,4.34c-0.23,1.55-0.47,3.72,0.39,6.28H2.34v-0.08c-0.47,0-0.78-0.31-0.78-0.77V4.33
                    c0-0.46,0.31-0.77,0.78-0.77h33.2v14.49C34.53,18.51,33.91,19.44,33.91,20.6z M35.31,25.18c0.31-2.02,0.08-1.4,0.16-4.57
                    c0-0.62,0.47-1.16,1.17-1.24c1.17,0.08,1.33,0.62,1.41,1.16c0.08,0.7,0.08,1.4,0.08,1.94c0,1.86,0.08,3.57,2.03,4.11
                    c1.72,0.46,3.36-0.78,3.44-2.48c0.08-1.86,0.23-6.2-1.02-8.06V13.7c1.48,1.16,3.91,3.64,4.06,6.9v15.27h-8.52
                    C37.19,32.23,34.38,31.84,35.31,25.18z M48.44,45.41H36.41v-7.98c3.28,0,9.37,0,12.03,0V45.41z M20.31,23.24
                    c4.77,0,8.59-3.8,8.59-8.53c0-4.73-3.83-8.53-8.59-8.53c-4.77,0-8.59,3.8-8.59,8.53C11.72,19.36,15.55,23.24,20.31,23.24z
                    M20.31,7.66c3.91,0,7.03,3.1,7.03,6.98c0,3.88-3.13,6.98-7.03,6.98c-3.91,0-7.03-3.1-7.03-6.98
                    C13.28,10.84,16.41,7.66,20.31,7.66z M19.3,18.9l4.77-3.18c0.78-0.54,0.78-1.63,0-2.17l-4.77-3.18c-0.86-0.54-2.03,0.08-2.03,1.08
                    v6.28C17.27,18.9,18.44,19.52,19.3,18.9z M18.83,12l4.06,2.71l-4.06,2.71V12z M30.16,25.87H11.09v-0.47
                    c0-0.46-0.31-0.77-0.78-0.77s-0.78,0.31-0.78,0.77v0.47H6.56c-0.47,0-0.78,0.31-0.78,0.77c0,0.47,0.31,0.78,0.78,0.78h2.97v0.47
                    c0,0.46,0.31,0.77,0.78,0.77s0.78-0.31,0.78-0.77v-0.47h19.06c0.47,0,0.78-0.31,0.78-0.78C30.94,26.18,30.55,25.87,30.16,25.87z
                    M39.53,42.15h1.56c0.47,0,0.78-0.31,0.78-0.77c0-0.39-0.39-0.78-0.78-0.78h-1.56c-0.47,0-0.78,0.31-0.78,0.78
                    C38.75,41.84,39.06,42.15,39.53,42.15z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Live Video Streaming'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M35,25h1.67v-2.5H35V25z M38.33,25H40v-5h-1.67V25z M41.67,25h1.67v-6.67h-1.67V25z M9.17,36.67
                    c-2.3,0-4.17,1.86-4.17,4.17C5,43.13,6.87,45,9.17,45c2.3,0,4.17-1.87,4.17-4.17S11.47,36.67,9.17,36.67z M9.17,43.33
                    c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5c1.38,0,2.5,1.12,2.5,2.5C11.67,42.21,10.55,43.33,9.17,43.33z M18.33,39.44
                    c0-0.36-0.23-0.68-0.57-0.79l-1.42-0.47c-0.07-0.19-0.15-0.37-0.23-0.55l0.6-1.26c0.13-0.27,0.1-0.59-0.07-0.84
                    c-0.6-0.84-1.33-1.57-2.16-2.16c-0.25-0.18-0.57-0.2-0.84-0.07l-1.26,0.6c-0.18-0.08-0.37-0.16-0.55-0.23l-0.47-1.42
                    c-0.11-0.34-0.43-0.57-0.79-0.57H7.77c-0.36,0-0.68,0.23-0.79,0.57L6.5,33.69L5.99,33.9L4.7,33.29c-0.27-0.13-0.59-0.1-0.84,0.07
                    c-0.84,0.6-1.57,1.33-2.17,2.17c-0.17,0.25-0.2,0.57-0.07,0.84l0.61,1.29l-0.21,0.51l-1.45,0.48C0.23,38.76,0,39.08,0,39.44v2.78
                    c0,0.36,0.23,0.68,0.57,0.79l1.45,0.48l0.21,0.51L1.62,45.3c-0.13,0.27-0.1,0.59,0.07,0.84c0.6,0.84,1.33,1.57,2.16,2.16
                    c0.25,0.17,0.57,0.2,0.84,0.07l1.29-0.61l0.51,0.21l0.48,1.45C7.1,49.77,7.41,50,7.77,50h2.78c0.36,0,0.68-0.23,0.79-0.57
                    l0.47-1.42c0.19-0.07,0.37-0.15,0.55-0.23l1.26,0.6c0.27,0.13,0.59,0.1,0.84-0.07c0.84-0.6,1.57-1.33,2.16-2.16
                    c0.18-0.25,0.2-0.57,0.07-0.84l-0.6-1.26c0.08-0.18,0.16-0.37,0.23-0.55l1.42-0.47c0.34-0.11,0.57-0.43,0.57-0.79V39.44z
                    M16.67,41.62l-1.24,0.41c-0.26,0.08-0.46,0.29-0.53,0.55c-0.11,0.37-0.26,0.72-0.44,1.06c-0.12,0.23-0.13,0.51-0.02,0.75
                    l0.55,1.16c-0.33,0.4-0.7,0.77-1.1,1.1l-1.16-0.55c-0.24-0.11-0.52-0.11-0.75,0.02c-0.34,0.18-0.69,0.33-1.06,0.44
                    c-0.26,0.08-0.46,0.28-0.55,0.53l-0.41,1.24H8.38l-0.41-1.24c-0.08-0.23-0.25-0.41-0.47-0.51l-1.21-0.5
                    C6.07,45.99,5.82,46,5.61,46.1l-1.16,0.55c-0.4-0.33-0.77-0.7-1.1-1.1l0.55-1.16c0.1-0.21,0.11-0.46,0.02-0.68l-0.5-1.21
                    c-0.09-0.22-0.28-0.4-0.51-0.47l-1.24-0.41v-1.58l1.24-0.41c0.23-0.08,0.41-0.25,0.51-0.47l0.5-1.21C4.01,37.74,4,37.49,3.9,37.28
                    l-0.55-1.16c0.33-0.4,0.7-0.77,1.1-1.1l1.16,0.55c0.21,0.1,0.46,0.11,0.68,0.02l1.21-0.5c0.22-0.09,0.4-0.28,0.47-0.51l0.41-1.24
                    h1.58l0.41,1.24c0.08,0.26,0.29,0.46,0.55,0.53c0.37,0.11,0.72,0.26,1.06,0.44c0.23,0.12,0.51,0.13,0.75,0.02l1.16-0.55
                    c0.4,0.33,0.77,0.7,1.1,1.1l-0.55,1.16c-0.11,0.24-0.11,0.52,0.02,0.75c0.18,0.34,0.33,0.69,0.44,1.06
                    c0.08,0.26,0.28,0.46,0.53,0.55l1.24,0.41V41.62z M49.17,3.33C49.17,3.33,49.17,3.33,49.17,3.33h-5.83v-2.5
                    C43.33,0.37,42.96,0,42.5,0c0,0,0,0,0,0h-5c-0.46,0-0.83,0.37-0.83,0.83c0,0,0,0,0,0v2.5h-8.33v-2.5C28.33,0.37,27.96,0,27.5,0
                    c0,0,0,0,0,0h-5c-0.46,0-0.83,0.37-0.83,0.83c0,0,0,0,0,0v2.5h-8.33v-2.5C13.33,0.37,12.96,0,12.5,0c0,0,0,0,0,0h-5
                    C7.04,0,6.67,0.37,6.67,0.83c0,0,0,0,0,0v2.5H0.83C0.37,3.33,0,3.71,0,4.17c0,0,0,0,0,0v29.17h1.67V10h46.67v38.33h-30V50h30.83
                    c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0v-45C50,3.71,49.63,3.33,49.17,3.33z M38.33,1.67h3.33V5h-3.33V1.67z M23.33,1.67h3.33V5
                    h-3.33V1.67z M8.33,1.67h3.33V5H8.33V1.67z M48.33,8.33H1.67V5h5v0.83c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0h5
                    c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0V5h8.33v0.83c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0h5c0.46,0,0.83-0.37,0.83-0.83
                    c0,0,0,0,0,0V5h8.33v0.83c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0h5c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0V5h5V8.33z
                    M27.5,28.33h18.33c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0v-15c0-0.46-0.37-0.83-0.83-0.83c0,0,0,0,0,0H27.5
                    c-0.46,0-0.83,0.37-0.83,0.83c0,0,0,0,0,0v15C26.67,27.96,27.04,28.33,27.5,28.33C27.5,28.33,27.5,28.33,27.5,28.33z M28.33,13.33
                    H45v13.33H28.33V13.33z M26.67,30.83v15c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0h18.33c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0
                    v-15c0-0.46-0.37-0.83-0.83-0.83c0,0,0,0,0,0H27.5C27.04,30,26.67,30.37,26.67,30.83C26.67,30.83,26.67,30.83,26.67,30.83z
                    M28.33,31.67H45V45H28.33V31.67z M18.33,45v1.67h4.17c0.46,0,0.83-0.37,0.83-0.83c0,0,0,0,0,0v-15c0-0.46-0.37-0.83-0.83-0.83
                    c0,0,0,0,0,0h-10v1.67h9.17V45H18.33z M23.33,27.5v-15c0-0.46-0.37-0.83-0.83-0.83c0,0,0,0,0,0H4.17c-0.46,0-0.83,0.37-0.83,0.83
                    c0,0,0,0,0,0v15c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0H22.5C22.96,28.33,23.33,27.96,23.33,27.5
                    C23.33,27.5,23.33,27.5,23.33,27.5z M21.67,26.67H5V13.33h16.67V26.67z M8.51,20.67l4.25,4.1c0.32,0.31,0.83,0.31,1.16,0l4.25-4.1
                    c1.3-1.24,1.35-3.3,0.11-4.6c-0.04-0.04-0.07-0.07-0.11-0.11c-1.34-1.28-3.45-1.28-4.8,0l-0.03,0.03l-0.03-0.03
                    c-1.34-1.28-3.45-1.28-4.8,0c-1.3,1.24-1.35,3.3-0.11,4.6C8.43,20.6,8.47,20.63,8.51,20.67z M9.6,17.23
                    c0.02-0.02,0.04-0.05,0.07-0.07h0c0.7-0.66,1.79-0.66,2.48,0l0.61,0.59c0.32,0.31,0.83,0.31,1.16,0l0.61-0.59
                    c0.7-0.66,1.79-0.66,2.48,0c0.64,0.6,0.67,1.61,0.07,2.24c-0.02,0.02-0.04,0.05-0.07,0.07l-3.67,3.54l-3.67-3.54
                    C9.03,18.87,9,17.86,9.6,17.23z M32.92,17.5h-0.83c-0.23,0-0.42-0.19-0.42-0.42c0-0.23,0.19-0.42,0.42-0.42H35V15h-2.92
                    C30.93,15,30,15.93,30,17.08c0,1.15,0.93,2.08,2.08,2.08h0.83c0.23,0,0.42,0.19,0.42,0.42c0,0.23-0.19,0.42-0.42,0.42H30v1.67
                    h2.92c1.15,0,2.08-0.93,2.08-2.08C35,18.43,34.07,17.5,32.92,17.5z M40.91,33.33h-8.49c-1.34,0-2.42,1.08-2.42,2.42v3.49
                    c0,1.34,1.09,2.42,2.42,2.42h1.74v1.67c0,0.46,0.37,0.83,0.83,0.83c0.18,0,0.36-0.06,0.5-0.17l3.11-2.33h2.3
                    c1.34,0,2.42-1.08,2.42-2.42v-3.49C43.33,34.42,42.25,33.33,40.91,33.33z M41.67,39.24c0,0.42-0.34,0.75-0.76,0.76h-2.58
                    c-0.18,0-0.36,0.06-0.5,0.17l-2,1.5v-0.83c0-0.46-0.37-0.83-0.83-0.83c0,0,0,0,0,0h-2.58c-0.42,0-0.75-0.34-0.76-0.76v-3.49
                    c0-0.42,0.34-0.75,0.76-0.76h8.49c0.42,0,0.75,0.34,0.76,0.76V39.24z M33.33,38.33h4.17v-1.67h-4.17V38.33z M38.33,38.33H40v-1.67
                    h-1.67V38.33z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Event Management'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M5.58,5c-3.03,0-5.49,2.46-5.49,5.49c0,3.03,2.46,5.49,5.49,5.49c3.03,0,5.49-2.46,5.49-5.49C11.07,7.46,8.62,5,5.58,5z
                M5.58,13.98c-1.93,0-3.49-1.57-3.49-3.49C2.09,8.56,3.66,7,5.58,7c1.93,0,3.49,1.57,3.49,3.49C9.08,12.41,7.51,13.98,5.58,13.98z
                M20.56,15.97h18.96c3.03,0,5.49-2.46,5.49-5.49c0-3.03-2.46-5.49-5.49-5.49H20.56c-3.03,0-5.49,2.46-5.49,5.49
                C15.07,13.52,17.52,15.97,20.56,15.97z M20.56,7h18.96c1.93,0,3.49,1.57,3.49,3.49c0,1.92-1.57,3.49-3.49,3.49H20.56
                c-1.93,0-3.49-1.57-3.49-3.49C17.06,8.56,18.63,7,20.56,7z M5.58,19.96c-3.03,0-5.49,2.46-5.49,5.49s2.46,5.49,5.49,5.49
                c3.03,0,5.49-2.46,5.49-5.49S8.62,19.96,5.58,19.96z M5.58,28.94c-1.93,0-3.49-1.57-3.49-3.49c0-1.92,1.57-3.49,3.49-3.49
                c1.93,0,3.49,1.57,3.49,3.49C9.08,27.37,7.51,28.94,5.58,28.94z M44.51,19.96H20.56c-3.03,0-5.49,2.46-5.49,5.49
                s2.46,5.49,5.49,5.49h23.95c3.03,0,5.49-2.46,5.49-5.49S47.54,19.96,44.51,19.96z M44.51,28.94H20.56c-1.93,0-3.49-1.57-3.49-3.49
                c0-1.92,1.57-3.49,3.49-3.49h23.95c1.93,0,3.49,1.57,3.49,3.49C48,27.37,46.44,28.94,44.51,28.94z M5.58,34.92
                c-3.03,0-5.49,2.46-5.49,5.49c0,3.03,2.46,5.49,5.49,5.49c3.03,0,5.49-2.46,5.49-5.49C11.07,37.38,8.62,34.92,5.58,34.92z
                M5.58,43.9c-1.93,0-3.49-1.57-3.49-3.49c0-1.92,1.57-3.49,3.49-3.49c1.93,0,3.49,1.57,3.49,3.49C9.08,42.33,7.51,43.9,5.58,43.9z
                M34.53,34.92H20.56c-3.03,0-5.49,2.46-5.49,5.49c0,3.03,2.46,5.49,5.49,5.49h13.97c3.03,0,5.49-2.46,5.49-5.49
                C40.02,37.38,37.56,34.92,34.53,34.92z M34.53,43.9H20.56c-1.93,0-3.49-1.57-3.49-3.49c0-1.92,1.57-3.49,3.49-3.49h13.97
                c1.93,0,3.49,1.57,3.49,3.49C38.02,42.33,36.46,43.9,34.53,43.9z"></path>
              </svg>`,
					title: 'Online Pooling'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path class="st0" d="M20.62,41.67h-1.54c-0.96,0-1.75,0.78-1.75,1.74v1.53c0,0.96,0.78,1.74,1.75,1.74h1.54
                    c0.96,0,1.75-0.78,1.75-1.74V43.4C22.37,42.44,21.59,41.67,20.62,41.67z M20.69,44.93c0,0.04-0.03,0.07-0.07,0.07h-1.54
                    c-0.04,0-0.07-0.03-0.07-0.07V43.4c0-0.04,0.03-0.07,0.07-0.07h1.54c0.04,0,0.07,0.03,0.07,0.07V44.93z M41.15,8.33H34.1V3.3
                    c0-1.82-1.49-3.3-3.32-3.3H8.93C7.09,0,5.6,1.48,5.6,3.3v43.4c0,1.82,1.49,3.3,3.32,3.3h21.86c1.83,0,3.32-1.48,3.32-3.3V40
                    c0,0,0,0,0,0s0,0,0,0V29.15h7.05c2.13,0,3.85-1.71,3.85-3.83V12.16C45,10.05,43.28,8.33,41.15,8.33z M32.43,39.17h-5.87
                    c-0.46,0-0.84,0.37-0.84,0.83c0,0.46,0.38,0.83,0.84,0.83h5.87v5.87c0,0.9-0.74,1.64-1.64,1.64H8.93c-0.91,0-1.64-0.73-1.64-1.64
                    V6.67h5.87c0.46,0,0.84-0.37,0.84-0.83S13.61,5,13.15,5H7.28V3.3c0-0.9,0.74-1.63,1.64-1.63h21.86c0.91,0,1.64,0.73,1.64,1.63
                    v5.03H17.84h0c-1.01,0-1.91,0.39-2.6,1.02c-0.04,0.04-0.09,0.06-0.12,0.1c-0.03,0.03-0.05,0.07-0.08,0.1
                    c-0.65,0.68-1.05,1.6-1.05,2.61v13.16c0,0.56,0.13,1.09,0.35,1.57c0.6,1.33,1.94,2.26,3.5,2.26h3.69v4.97l1.32-0.94c0,0,0,0,0,0
                    l5.68-4.03h3.89V39.17z M43.32,25.32c0,1.19-0.97,2.16-2.17,2.16H34.1v0H28l-4.8,3.4v-2.57c0,0,0,0,0,0v-0.83h-5.37
                    c-0.78,0-1.45-0.41-1.84-1.02c-0.21-0.33-0.34-0.72-0.34-1.14V12.16c0-0.55,0.21-1.04,0.55-1.42c0.4-0.45,0.98-0.74,1.63-0.74
                    H34.1h7.05c1.2,0,2.17,0.97,2.17,2.16V25.32z M21.55,16.67h8.36c0.46,0,0.84-0.37,0.84-0.83c0-0.46-0.38-0.83-0.84-0.83h-8.36
                    c-0.46,0-0.84,0.37-0.84,0.83C20.71,16.29,21.09,16.67,21.55,16.67z M37.46,19.98L21.55,20c-0.46,0-0.84,0.37-0.84,0.83
                    c0,0.46,0.38,0.83,0.84,0.83l15.91-0.01c0.46,0,0.84-0.37,0.84-0.83C38.29,20.36,37.92,19.98,37.46,19.98z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Instant Messaging'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M47.43,33.87l1.18-2l-4.3-4.21l-2.04,1.15c-0.24-0.11-0.48-0.21-0.72-0.3l-0.61-2.23h-0.62v-7.11
                    c0-0.27-0.03-0.54-0.08-0.79h4.92c2.67,0,4.84-2.13,4.84-4.74c0-2.36-1.78-4.32-4.09-4.68c0.03-0.28,0.05-0.57,0.05-0.85
                    c0-3.92-3.26-7.11-7.26-7.11c-2.87,0-5.42,1.64-6.59,4.14c-0.48-0.13-0.97-0.19-1.47-0.19c-2.55,0-4.73,1.64-5.42,3.99
                    c-2.4,0.28-4.26,2.28-4.26,4.7c0,0.55,0.1,1.08,0.28,1.58H4.03C1.81,15.23,0,17,0,19.18v23.71c0,2.18,1.81,3.95,4.03,3.95h25.63
                    l1.83,1.79l2.04-1.15c0.24,0.11,0.48,0.21,0.72,0.3L34.87,50h6.08l0.61-2.22c0.24-0.09,0.48-0.19,0.72-0.3l2.04,1.15l4.3-4.21
                    l-1.18-2c0.11-0.23,0.21-0.47,0.3-0.71l2.27-0.6v-5.95l-2.27-0.6C47.64,34.34,47.54,34.1,47.43,33.87z M25.81,10.48
                    c1.78,0,3.23,1.42,3.23,3.16h1.61c0-2.25-1.6-4.13-3.74-4.61c0.59-1.48,2.05-2.5,3.74-2.5c0.56,0,1.1,0.11,1.61,0.33l0.81,0.35
                    l0.28-0.83c0.76-2.28,2.92-3.81,5.36-3.81c3.11,0,5.65,2.48,5.65,5.53c0,0.29-0.03,0.59-0.08,0.87c-2.25,0.41-3.95,2.34-3.95,4.66
                    h1.61c0-1.74,1.45-3.16,3.23-3.16c1.78,0,3.23,1.42,3.23,3.16c0,1.74-1.45,3.16-3.23,3.16h-5.65v0.02
                    c-0.74-0.97-1.9-1.61-3.23-1.61H23.02c-0.28-0.48-0.44-1.02-0.44-1.58C22.58,11.9,24.03,10.48,25.81,10.48z M4.03,45.26
                    c-1.33,0-2.42-1.06-2.42-2.37V19.18c0-1.31,1.09-2.37,2.42-2.37h32.26c1.33,0,2.42,1.06,2.42,2.37v7.11H37.1v-7.9H3.23v25.29
                    h24.41l-0.44,0.75l0.85,0.84H4.03z M28.38,33.87c-0.11,0.23-0.21,0.47-0.3,0.71l-2.27,0.6v1.13l-3.06-4
                    C22,31.32,21,30.57,19.89,30.08l2.75-3.77c0.73-1,1.91-1.6,3.17-1.6c1.25,0,2.44,0.6,3.17,1.6l1.63,2.23l-3.4,3.33L28.38,33.87z
                    M22.97,35.23c-0.45,0.32-0.84,0.54-1.6,0.54c-0.85,0-1.22-0.27-1.75-0.64c-0.59-0.42-1.32-0.94-2.69-0.94
                    c-1.37,0-2.1,0.52-2.69,0.94c-0.52,0.37-0.9,0.64-1.74,0.64c-0.76,0-1.15-0.22-1.6-0.54l1.52-1.99c1.06-1.39,2.75-2.21,4.52-2.21
                    s3.46,0.83,4.52,2.21L22.97,35.23z M30.29,25.39c-1.03-1.42-2.71-2.26-4.48-2.26c-1.77,0-3.45,0.84-4.48,2.26l-3.06,4.2
                    c-0.44-0.08-0.88-0.13-1.33-0.13c-2.27,0-4.44,1.06-5.81,2.85l-6.29,8.22V19.97h30.65v6.32h-0.62l-0.61,2.22
                    c-0.24,0.09-0.48,0.19-0.72,0.3l-1.28-0.73L30.29,25.39z M5.65,42.1l4.28-5.6c0.57,0.4,1.29,0.86,2.57,0.86
                    c1.37,0,2.1-0.52,2.69-0.94c0.52-0.37,0.9-0.64,1.74-0.64c0.85,0,1.22,0.27,1.75,0.64c0.59,0.42,1.32,0.94,2.69,0.94
                    c1.28,0,2-0.45,2.57-0.86l1.87,2.44v2.19l2.27,0.6c0.05,0.13,0.11,0.25,0.16,0.38H5.65z M48.39,39.91l-1.91,0.5l-0.13,0.41
                    c-0.14,0.42-0.32,0.85-0.54,1.28l-0.2,0.38l0.99,1.68l-2.55,2.5l-1.72-0.97l-0.39,0.2c-0.43,0.22-0.87,0.4-1.3,0.53l-0.42,0.13
                    l-0.51,1.87h-3.6l-0.51-1.87l-0.42-0.13c-0.43-0.14-0.87-0.32-1.3-0.53l-0.39-0.2l-1.72,0.97l-2.55-2.5l0.99-1.68l-0.2-0.38
                    c-0.22-0.42-0.4-0.85-0.54-1.28l-0.13-0.41l-1.91-0.5v-3.53l1.91-0.5l0.13-0.41c0.14-0.42,0.32-0.85,0.54-1.27l0.2-0.38
                    l-0.99-1.68l2.55-2.5l1.72,0.97l0.39-0.2c0.43-0.22,0.87-0.4,1.3-0.53l0.42-0.13l0.51-1.87h3.6l0.51,1.87l0.42,0.13
                    c0.43,0.14,0.87,0.32,1.3,0.53l0.39,0.2l1.72-0.97l2.55,2.5l-0.99,1.68l0.2,0.38c0.22,0.42,0.4,0.85,0.54,1.27l0.13,0.41l1.91,0.5
                    V39.91z M37.9,31.03c-4,0-7.26,3.19-7.26,7.11c0,3.92,3.26,7.11,7.26,7.11c4,0,7.26-3.19,7.26-7.11
                    C45.16,34.22,41.9,31.03,37.9,31.03z M37.9,43.68c-3.11,0-5.65-2.48-5.65-5.53c0-3.05,2.53-5.53,5.65-5.53
                    c3.11,0,5.65,2.48,5.65,5.53C43.55,41.19,41.02,43.68,37.9,43.68z M37.9,34.19c-2.22,0-4.03,1.77-4.03,3.95
                    c0,2.18,1.81,3.95,4.03,3.95c2.22,0,4.03-1.77,4.03-3.95C41.94,35.97,40.13,34.19,37.9,34.19z M37.9,40.52
                    c-1.33,0-2.42-1.06-2.42-2.37c0-1.31,1.09-2.37,2.42-2.37c1.33,0,2.42,1.06,2.42,2.37C40.32,39.45,39.24,40.52,37.9,40.52z
                    M10.48,21.55c-2.22,0-4.03,1.77-4.03,3.95s1.81,3.95,4.03,3.95c2.22,0,4.03-1.77,4.03-3.95S12.71,21.55,10.48,21.55z
                    M10.48,27.87c-1.33,0-2.42-1.06-2.42-2.37c0-1.31,1.09-2.37,2.42-2.37c1.33,0,2.42,1.06,2.42,2.37
                    C12.9,26.81,11.82,27.87,10.48,27.87z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Video and Image Editing &amp; Sharing'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M17.11,44.36v2.44c0,0.97,0.79,1.76,1.75,1.77h12.29c0.97,0,1.75-0.79,1.75-1.77v-2.44c0.01-2.77-1.44-5.35-3.8-6.77
                c2.13-2.28,2.02-5.87-0.25-8.02c-2.27-2.14-5.83-2.03-7.95,0.25c-2.03,2.18-2.03,5.58,0,7.77C18.55,39.01,17.11,41.59,17.11,44.36z
                M22.55,38.46c0.39-0.16,0.57-0.61,0.41-1c-0.06-0.14-0.15-0.25-0.27-0.34c-1.87-1.29-2.35-3.87-1.07-5.76s3.84-2.37,5.72-1.08
                c1.87,1.29,2.35,3.87,1.07,5.76c-0.29,0.42-0.65,0.79-1.07,1.08c-0.34,0.24-0.43,0.72-0.19,1.06c0.08,0.12,0.2,0.22,0.33,0.27
                c2.38,0.98,3.93,3.31,3.92,5.9v2.44c0,0.13-0.11,0.24-0.24,0.24l0,0h-12.3c-0.13,0-0.24-0.11-0.24-0.24l0,0v-2.44
                C18.62,41.77,20.17,39.44,22.55,38.46z M1.78,23.81h12.27c0.97,0,1.75-0.79,1.75-1.77V19.6c0.01-2.77-1.44-5.35-3.8-6.77
                c2.13-2.28,2.02-5.87-0.24-8.02C9.49,2.66,5.93,2.77,3.8,5.06c-2.04,2.18-2.04,5.58,0,7.77c-2.36,1.43-3.81,4-3.8,6.78v2.44
                c0,0.98,0.78,1.77,1.75,1.77C1.76,23.81,1.77,23.81,1.78,23.81z M1.54,19.6c-0.01-2.58,1.53-4.92,3.9-5.9
                c0.39-0.16,0.57-0.61,0.41-1c-0.06-0.14-0.15-0.25-0.27-0.34C3.71,11.07,3.23,8.49,4.51,6.6c1.28-1.89,3.84-2.37,5.71-1.08
                c1.87,1.29,2.35,3.87,1.07,5.76c-0.29,0.42-0.65,0.79-1.07,1.08c-0.34,0.24-0.43,0.72-0.19,1.06c0.08,0.12,0.2,0.22,0.33,0.27
                c2.38,0.98,3.93,3.31,3.92,5.9v2.44c0,0.13-0.11,0.24-0.24,0.24l0,0H1.78c-0.13,0-0.24-0.11-0.24-0.24l0,0V19.6z M46.23,12.82
                c2.13-2.28,2.02-5.87-0.25-8.02c-2.27-2.14-5.83-2.03-7.95,0.25c-2.03,2.18-2.03,5.58,0,7.77c-2.36,1.43-3.8,4-3.8,6.77v2.44
                c0,0.97,0.79,1.76,1.75,1.77h12.27c0.97,0,1.75-0.79,1.75-1.77l0,0V19.6C50.01,16.83,48.58,14.26,46.23,12.82z M48.51,22.04
                c0,0.13-0.11,0.24-0.24,0.24l0,0H35.98c-0.13,0-0.24-0.11-0.24-0.24l0,0V19.6c0-2.59,1.55-4.92,3.92-5.9c0.39-0.16,0.57-0.61,0.41-1
                c-0.06-0.14-0.15-0.25-0.27-0.34c-1.87-1.29-2.35-3.87-1.07-5.76c1.28-1.89,3.84-2.37,5.71-1.08c1.87,1.29,2.35,3.87,1.07,5.76
                c-0.29,0.42-0.65,0.79-1.07,1.08c-0.34,0.24-0.43,0.72-0.19,1.06c0.08,0.12,0.2,0.22,0.33,0.27c2.38,0.98,3.93,3.31,3.92,5.9
                L48.51,22.04z M16.46,36.9L16.46,36.9c-3.6-2.27-6.17-5.87-7.18-10.03c-0.4-1.44-1.88-2.27-3.3-1.87c-1.35,0.38-2.17,1.74-1.9,3.13
                c1.34,5.53,4.77,10.32,9.55,13.34c1.26,0.79,2.91,0.4,3.69-0.86s0.4-2.93-0.86-3.72l0,0V36.9z M16.03,39.81
                c-0.35,0.55-1.07,0.71-1.62,0.37c-4.44-2.82-7.62-7.27-8.86-12.4c-0.17-0.62,0.18-1.27,0.8-1.45c0.62-0.18,1.26,0.19,1.43,0.81
                c0.01,0.03,0.02,0.06,0.02,0.09c1.1,4.55,3.92,8.48,7.85,10.96l0,0C16.2,38.54,16.37,39.26,16.03,39.81
                C16.03,39.81,16.03,39.81,16.03,39.81z M15.95,8.47c0.66,0.23,1.39,0.19,2.02-0.12c4.44-2.15,9.61-2.15,14.05,0
                c0.36,0.18,0.76,0.27,1.16,0.27c1.03,0,1.96-0.6,2.41-1.53c0.64-1.34,0.08-2.95-1.25-3.6c-5.91-2.86-12.78-2.86-18.69,0
                c-1.33,0.65-1.89,2.26-1.25,3.6c0.31,0.65,0.86,1.14,1.53,1.38L15.95,8.47z M15.73,5.53c0.1-0.29,0.31-0.53,0.59-0.67
                c5.49-2.66,11.89-2.66,17.38,0c0.58,0.28,0.82,0.98,0.54,1.56C33.95,7,33.27,7.24,32.69,6.97c-4.86-2.35-10.51-2.35-15.37,0
                c-0.58,0.28-1.27,0.04-1.55-0.55C15.64,6.15,15.62,5.83,15.73,5.53L15.73,5.53z M44.01,24.88c-1.44-0.35-2.88,0.54-3.23,1.99
                c-1.01,4.16-3.59,7.75-7.19,10.03c-1.26,0.79-1.64,2.45-0.86,3.72c0.78,1.27,2.43,1.65,3.69,0.86l0,0
                c4.78-3.03,8.21-7.81,9.55-13.34c0.35-1.44-0.52-2.9-1.95-3.26L44.01,24.88z M44.52,27.78c-1.25,5.14-4.43,9.58-8.87,12.4
                c-0.55,0.33-1.25,0.17-1.6-0.37c-0.34-0.55-0.17-1.27,0.37-1.61c0,0,0,0,0,0c3.93-2.49,6.75-6.42,7.85-10.96
                c0.15-0.63,0.78-1.02,1.4-0.87l0,0c0.62,0.15,1.01,0.78,0.86,1.41L44.52,27.78z"></path>
              </svg>`,
					title: 'Community Building'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M24.11,46.78h1.6v-1.61h-1.6V46.78z M35.68,17.06l1.13,1.14l1.13-1.14l-1.13-1.14L35.68,17.06z M11.88,41.02l1.13,1.14
                    l1.13-1.14l-1.13-1.14L11.88,41.02z M40.95,29.85h1.6v-1.61h-1.6V29.85z M7.28,29.85h1.6v-1.61h-1.6V29.85z M35.68,41.02
                    l1.13,1.14l1.13-1.14l-1.13-1.14L35.68,41.02z M14.15,17.06l-1.13-1.14l-1.13,1.14l1.13,1.14L14.15,17.06z M31.32,10.48
                    c0-3.55-2.88-6.45-6.41-6.45c-3.53,0-6.41,2.89-6.41,6.45c0,3.55,2.88,6.45,6.41,6.45C28.45,16.93,31.32,14.05,31.32,10.48z
                    M20.1,10.48c0-2.67,2.16-4.84,4.81-4.84c2.65,0,4.81,2.18,4.81,4.84c0,2.67-2.16,4.84-4.81,4.84
                    C22.27,15.33,20.1,13.15,20.1,10.48z M45.75,33.06v-8.04l-3.28-0.66c-0.41-1.59-1.04-3.09-1.85-4.51l1.86-2.8l-5.66-5.7l-1.8,1.28
                    c0.21-0.81,0.32-1.53,0.32-2.15C35.33,4.71,30.66,0,24.92,0C19.18,0,14.5,4.71,14.5,10.48c0,0.6,0.12,1.31,0.31,2.09l-1.8-1.21
                    l-5.65,5.69l1.86,2.8c-0.81,1.41-1.44,2.92-1.85,4.51l-3.29,0.66v8.04l3.28,0.66c0.41,1.58,1.04,3.09,1.85,4.51l-1.86,2.8
                    L13,46.71l2.78-1.87c1.4,0.82,2.9,1.45,4.48,1.86l0.66,3.3h7.99l0.66-3.3c1.57-0.41,3.07-1.04,4.48-1.86l2.78,1.87l5.65-5.69
                    l-1.86-2.8c0.82-1.41,1.44-2.92,1.85-4.51L45.75,33.06z M24.92,1.61c4.86,0,8.81,3.98,8.81,8.87c0,3.87-5.85,13.95-8.81,18.67
                    c-2.97-4.72-8.81-14.81-8.81-18.67C16.1,5.59,20.06,1.61,24.92,1.61z M31.88,20.21c2.67,2.14,4.25,5.37,4.25,8.83
                    c0,6.23-5.04,11.29-11.22,11.29c-6.19,0-11.22-5.07-11.22-11.29c0-3.47,1.58-6.69,4.25-8.83c2.81,5.63,6.96,11.91,6.96,11.91
                    S29.03,25.94,31.88,20.21z M38.72,38.27l1.69,2.56l-3.79,3.82l-2.54-1.7c-2.1,1.45-4.92,2.3-5.88,2.45l-0.6,3.01h-5.36l-0.6-3.01
                    c-2.67-0.53-4.92-1.79-5.88-2.45l-2.54,1.7l-3.8-3.82l1.69-2.56c-1.41-2.07-2.23-4.69-2.44-5.93l-2.99-0.6v-5.39l2.99-0.6
                    c0.36-1.52,0.76-3.37,2.44-5.93l-1.69-2.56l3.8-3.82l2.4,1.61c0.44,1.16,1,2.41,1.6,3.67C14,21.16,12.1,24.97,12.1,29.04
                    c0,7.12,5.75,12.91,12.82,12.91s12.82-5.79,12.82-12.91c0-4.08-1.91-7.88-5.12-10.32c0.59-1.22,1.12-2.43,1.57-3.56l0.01,0.02
                    l2.44-1.72l3.79,3.81l-1.69,2.56c1.4,2.2,1.97,3.71,2.44,5.93l2.99,0.6v5.39l-2.99,0.6C40.51,35.45,39.1,37.71,38.72,38.27z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Google Map Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M1.72,7.13h1.63v1.6c0,0.59,0.48,1.07,1.08,1.07s1.08-0.48,1.08-1.07v-1.6h1.63c0.6,0,1.08-0.48,1.08-1.07
                    c0-0.59-0.48-1.07-1.08-1.07H5.51v-1.6c0-0.59-0.48-1.07-1.08-1.07S3.35,2.8,3.35,3.39v1.6H1.72c-0.6,0-1.08,0.48-1.08,1.07
                    C0.64,6.65,1.12,7.13,1.72,7.13z M48.92,27.29h-1.63v-1.6c0-0.59-0.48-1.07-1.08-1.07c-0.6,0-1.08,0.48-1.08,1.07v1.6H43.5
                    c-0.6,0-1.08,0.48-1.08,1.07c0,0.59,0.48,1.07,1.08,1.07h1.62v1.6c0,0.59,0.48,1.07,1.08,1.07c0.6,0,1.08-0.48,1.08-1.07v-1.6
                    h1.63c0.6,0,1.08-0.48,1.08-1.07C50,27.76,49.52,27.29,48.92,27.29z M45.67,11.7c1.64,0,2.98-1.32,2.98-2.94
                    c0-1.62-1.33-2.95-2.98-2.95s-2.98,1.32-2.98,2.95C42.69,10.38,44.02,11.7,45.67,11.7z M45.67,7.96c0.45,0,0.81,0.36,0.81,0.81
                    c0,0.44-0.36,0.8-0.81,0.8c-0.45,0-0.81-0.36-0.81-0.8S45.22,7.96,45.67,7.96z M5.96,23.36c0-1.62-1.33-2.95-2.98-2.95
                    C1.34,20.41,0,21.73,0,23.36c0,1.62,1.33,2.94,2.98,2.94C4.62,26.3,5.96,24.98,5.96,23.36z M2.98,24.16
                    c-0.45,0-0.82-0.36-0.82-0.8c0-0.44,0.36-0.81,0.82-0.81c0.45,0,0.81,0.36,0.81,0.81C3.79,23.81,3.43,24.16,2.98,24.16z
                    M6.13,13.31c0,0.74,0.61,1.34,1.36,1.34c0.75,0,1.36-0.6,1.36-1.34c0-0.74-0.61-1.34-1.36-1.34C6.74,11.97,6.13,12.57,6.13,13.31
                    z M40,3.68c0.75,0,1.36-0.6,1.36-1.34C41.36,1.6,40.75,1,40,1c-0.75,0-1.36,0.6-1.36,1.34C38.64,3.08,39.25,3.68,40,3.68z
                    M43.5,22.47c0.75,0,1.36-0.6,1.36-1.34c0-0.74-0.61-1.34-1.36-1.34c-0.75,0-1.36,0.6-1.36,1.34
                    C42.14,21.87,42.75,22.47,43.5,22.47z M40.87,34.32c-1.15-1.45-1.77-3.27-1.77-5.11v-7.62c0-7.73-6.07-14.26-13.64-14.82v-2.4
                    c0-0.59-0.48-1.07-1.08-1.07s-1.08,0.48-1.08,1.07v2.4C19.86,7,16.66,8.4,14.15,10.8c-2.9,2.77-4.5,6.48-4.5,10.46v7.95
                    c0,1.84-0.63,3.66-1.77,5.11c-1.07,1.36-1.96,2.84-2.65,4.42c-0.54,1.22-0.41,2.6,0.33,3.72c0.75,1.13,2.02,1.81,3.39,1.81H18
                    c0.52,3.02,3.18,5.33,6.38,5.33s5.86-2.31,6.38-5.33h9.05c1.37,0,2.63-0.68,3.39-1.81c0.74-1.11,0.86-2.51,0.33-3.72
                    C42.83,37.17,41.94,35.68,40.87,34.32z M24.38,47.46c-2,0-3.68-1.36-4.17-3.19h8.33C28.06,46.1,26.38,47.46,24.38,47.46z
                    M41.38,41.29c-0.35,0.53-0.94,0.85-1.58,0.85H8.95c-0.64,0-1.23-0.32-1.58-0.85c-0.34-0.52-0.4-1.13-0.15-1.69
                    c0.62-1.41,1.42-2.74,2.38-3.96c1.44-1.82,2.23-4.11,2.23-6.42v-7.95c0-3.4,1.36-6.57,3.84-8.92c2.36-2.25,5.44-3.47,8.71-3.47
                    c0.15,0,0.31,0,0.46,0.01c6.67,0.24,12.09,5.94,12.09,12.71v7.62c0,1.6,0.39,3.19,1.1,4.63H28.3c-0.6,0-1.08,0.48-1.08,1.07
                    c0,0.59,0.48,1.07,1.08,1.07h11.11c0.47,0.62,0.89,1.27,1.27,1.94h-6.67c-0.6,0-1.08,0.48-1.08,1.07c0,0.59,0.48,1.07,1.08,1.07
                    h7.65C41.73,40.48,41.64,40.91,41.38,41.29z M28.9,14.31c0.19,0.12,0.39,0.18,0.6,0.18c0.35,0,0.69-0.16,0.9-0.47
                    c0.34-0.49,0.2-1.15-0.3-1.49c-1.6-1.06-3.45-1.66-5.35-1.73c-1-0.03-1.98,0.07-2.93,0.3c-0.58,0.14-0.94,0.72-0.79,1.29
                    c0.14,0.57,0.73,0.92,1.31,0.78c0.76-0.19,1.56-0.26,2.34-0.24C26.18,13,27.63,13.47,28.9,14.31z M18.47,12.58
                    c-0.42,0.28-0.82,0.59-1.2,0.93c-0.44,0.39-0.48,1.08-0.07,1.51c0.22,0.23,0.51,0.35,0.8,0.35c0.26,0,0.52-0.09,0.72-0.28
                    c0.3-0.27,0.63-0.52,0.96-0.74c0.5-0.33,0.63-1,0.29-1.49C19.64,12.38,18.96,12.25,18.47,12.58z M16.55,15.83
                    c-0.52-0.29-1.19-0.1-1.47,0.42c-0.85,1.53-1.3,3.26-1.3,5.01v5.28c0,0.59,0.48,1.07,1.08,1.07c0.6,0,1.08-0.48,1.08-1.07v-5.28
                    c0-1.39,0.36-2.77,1.03-3.99C17.27,16.76,17.08,16.11,16.55,15.83z M30.22,37.92h-4.87c-0.6,0-1.08,0.48-1.08,1.07
                    c0,0.59,0.48,1.07,1.08,1.07h4.87c0.6,0,1.08-0.48,1.08-1.07C31.3,38.4,30.82,37.92,30.22,37.92z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Push Notification'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>