<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">Your Benefits of Partnership for VR App Development?</h2>
				<p>
					Despite the fact that Virtual Reality app development isn’t a new name, we believe that there’s still a lot that can be explored. Our team of VR mobile app developers primarily focuses on building custom innovative solutions for our clients. But wait!! This isn’t all.
				</p>
			</div>
			<div class="row">
				<div class="col-5 ar-img-cont">
					<img src="../../../assets/images/why-choose-us.webp" />
				</div>
				<div class="row col-7">
					<div class="col-12 col-sm-6 col-lg-6" v-for="item in items" v-bind:key="item.title">
						<ArCard v-bind:obj="item"></ArCard>
					</div>
					<div class="col-12 col-sm-6 col-lg-6" v-for="item in items2" v-bind:key="item.title">
						<ArCard v-bind:obj="item"></ArCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import ArCard from '../AssetComponents/ArCard';

export default {
	components: {
		ArCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Our AR Tech Proficiencies',
					text: 'Once you hire AR developers at GenixBit, you don’t have to be concerned about 3D modeling, rigging and animation, SLAM tracking, marker-less and marker-based tracking, and many other creative aspects.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                         <g>
                            <path class="st0" d="M38.5,24.2c0.2,0,0.4-0.1,0.5-0.1l1.3-0.7c0.5-0.3,0.7-1,0.4-1.5c-0.1-0.3-0.4-0.4-0.7-0.5
                            c-0.3-0.1-0.6,0-0.8,0.1L38,22.1c-0.5,0.3-0.7,1-0.4,1.5C37.8,23.9,38.2,24.2,38.5,24.2z M39.5,21.8L39.5,21.8L39.5,21.8L39.5,21.8
                            z"></path>
                            <path class="st0" d="M43.7,28.5l-1-0.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.4,1.5l1,0.6c0.2,0.1,0.4,0.1,0.5,0.1
                            c0.4,0,0.7-0.2,0.9-0.5c0.1-0.3,0.2-0.5,0.1-0.8C44.1,28.9,43.9,28.7,43.7,28.5z"></path>
                            <path class="st0" d="M7.4,27.9l-1,0.6c-0.5,0.3-0.7,1-0.4,1.5c0.1,0.3,0.4,0.4,0.7,0.5c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5-0.1
                            l1-0.6c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.3,0-0.6-0.1-0.8C8.6,27.8,7.9,27.6,7.4,27.9z M8.5,28.6L8.5,28.6L8.5,28.6L8.5,28.6z"></path>
                            <path class="st0" d="M12.7,26.1c0.3,0.5,1,0.7,1.5,0.4l0.5-0.3L23,31c-0.2,0.3-0.2,0.7,0,1.1c0.3,0.5,1,0.7,1.5,0.4l0.5-0.3
                            l0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.3,0.2-0.7,0-1.1l8.3-4.8l0.5,0.3
                            c0.5,0.3,1.2,0.1,1.5-0.4c0.2-0.4,0.2-0.8,0-1.1c0.2-0.3,0.3-0.8,0-1.1c-0.1-0.3-0.4-0.4-0.7-0.5c-0.1,0-0.2,0-0.2,0V13.1
                            c0-0.4-0.2-0.7-0.5-0.9L25.5,6.2c-0.3-0.2-0.8-0.2-1.1,0l-10.3,5.9c-0.3,0.2-0.5,0.6-0.5,0.9v10.2c-0.1,0-0.2,0-0.2,0
                            c-0.3,0.1-0.5,0.3-0.7,0.5c-0.1,0.3-0.2,0.5-0.1,0.8c0,0.1,0.1,0.2,0.1,0.3C12.6,25.3,12.5,25.7,12.7,26.1z M16.9,13.1L25,8.4
                            l8.1,4.7L25,17.8L16.9,13.1z M26.1,29v-9.3l8.1-4.7v9.3L26.1,29z M15.8,24.3V15l8.1,4.7V29L15.8,24.3z"></path>
                            <path class="st0" d="M42.6,19.5l-1,0.6c-0.3,0.1-0.4,0.4-0.5,0.7c-0.1,0.3,0,0.6,0.1,0.8c0.2,0.3,0.6,0.5,0.9,0.5c0,0,0,0,0,0
                            c0.2,0,0.4-0.1,0.5-0.1l1-0.6c0.5-0.3,0.7-1,0.4-1.5C43.8,19.4,43.1,19.2,42.6,19.5z M41.5,21.3L41.5,21.3L41.5,21.3L41.5,21.3z"></path>
                            <path class="st0" d="M40.4,26.7l-1.3-0.7c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.1,0.3,1.5l1.3,0.8c0.2,0.1,0.4,0.1,0.5,0.1
                            c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.3,0.7-0.5c0.1-0.3,0.2-0.5,0.1-0.8C40.8,27,40.6,26.8,40.4,26.7z"></path>
                            <path class="st0" d="M10.7,21.4c-0.5-0.3-1.2-0.1-1.5,0.4l0,0c-0.3,0.5-0.1,1.2,0.4,1.5l1.3,0.7c0.2,0.1,0.4,0.1,0.5,0.1
                            c0.4,0,0.7-0.2,0.9-0.5c0.3-0.5,0.1-1.2-0.4-1.5L10.7,21.4z"></path>
                            <path class="st0" d="M44.5,15.8c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.2,0.2-0.5,0.2-0.8c-0.1-0.6-0.7-1-1.3-0.9l-2,0.4
                            c-0.8-0.5-1.7-0.9-2.6-1.3c-0.3-0.1-0.6-0.1-0.8,0c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.6,0,1.2,0.6,1.4c0.8,0.3,1.6,0.7,2.3,1.1
                            l0.4,1.9c0.1,0.3,0.2,0.5,0.5,0.7c0.2,0.2,0.5,0.2,0.8,0.2c0.3-0.1,0.5-0.2,0.7-0.5c0.1-0.2,0.2-0.4,0.2-0.7
                            c2.8,2.1,4.3,4.7,4.3,7.3c0,3.1-2.1,6.1-6,8.5l-1.9-0.7c-0.3-0.1-0.6-0.1-0.8,0c-0.3,0.1-0.5,0.3-0.6,0.6c-0.1,0.3-0.1,0.6,0,0.8
                            c0.1,0.3,0.3,0.5,0.6,0.5c-8.8,3.9-21.6,3.6-29.7-0.9L8.9,32c-0.1-0.3-0.2-0.5-0.5-0.7c-0.2-0.2-0.5-0.2-0.8-0.2
                            c-0.5,0.1-0.9,0.6-0.9,1.1c-2.8-2.1-4.3-4.7-4.3-7.3c0-3.1,2.1-6.1,6-8.5l1.9,0.7c0.6,0.2,1.2-0.1,1.4-0.7c0.2-0.6-0.1-1.2-0.6-1.4
                            c0.2-0.1,0.3-0.1,0.5-0.2c0.6-0.2,0.8-0.9,0.6-1.4c-0.1-0.3-0.3-0.5-0.6-0.6c-0.3-0.1-0.6-0.1-0.8,0c-0.2,0.1-0.4,0.2-0.5,0.2
                            c0.1-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.4-0.5-0.6-0.5c-0.6-0.2-1.2,0.1-1.4,0.7l-0.7,2C2.8,17.3,0.3,21,0.3,25c0,3.3,1.8,6.5,5.2,9.1
                            c-0.4,0.2-0.7,0.6-0.7,1c0,0.3,0.1,0.6,0.3,0.8c0.3,0.3,0.7,0.4,1,0.3l2-0.4c4.6,2.5,10.6,3.9,16.9,3.9c5.4,0,10.6-1,14.8-2.9
                            c-0.1,0.2,0,0.5,0.1,0.7c0.1,0.3,0.3,0.5,0.6,0.6c0.3,0.1,0.6,0.1,0.8,0c0.3-0.1,0.5-0.3,0.6-0.6l0.7-2c4.6-2.8,7.1-6.4,7.1-10.4
                            C49.7,21.6,47.9,18.4,44.5,15.8z"></path>
                            <path class="st0" d="M8.5,20.1l-1-0.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.4,1.5l1,0.6c0.2,0.1,0.4,0.1,0.5,0.1
                            c0.4,0,0.7-0.2,0.9-0.5C9.2,21.1,9,20.4,8.5,20.1z"></path>
                            <path class="st0" d="M33.4,34.5l-1-0.6c-0.5-0.3-1.2-0.1-1.5,0.4c-0.3,0.5-0.1,1.2,0.4,1.5l1,0.6c0.2,0.1,0.4,0.1,0.5,0.1
                            c0.4,0,0.7-0.2,0.9-0.5c0.1-0.3,0.2-0.5,0.1-0.8C33.8,34.9,33.6,34.6,33.4,34.5z"></path>
                            <path class="st0" d="M10.9,25.9l-1.3,0.7c-0.5,0.3-0.7,1-0.4,1.5c0.2,0.3,0.6,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1l1.3-0.7
                            c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.3,0-0.6-0.1-0.8C12.1,25.8,11.4,25.6,10.9,25.9z M11.2,26.3L11.2,26.3L11.2,26.3L11.2,26.3z"></path>
                            <path class="st0" d="M18.5,33.8c-0.3-0.1-0.6,0-0.8,0.1l-1,0.6c-0.3,0.1-0.4,0.4-0.5,0.7c-0.1,0.3,0,0.6,0.1,0.8
                            c0.2,0.3,0.6,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1l1-0.6c0.5-0.3,0.7-1,0.4-1.5C19,34,18.8,33.8,18.5,33.8z M16.6,35.7L16.6,35.7
                            L16.6,35.7L16.6,35.7z"></path>
                            <path class="st0" d="M28.8,31.8L28.8,31.8c-0.5-0.3-1.2-0.1-1.5,0.4c-0.1,0.3-0.2,0.5-0.1,0.8c0.1,0.3,0.3,0.5,0.5,0.7l1.3,0.7
                            c0.2,0.1,0.4,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0.3-0.1,0.5-0.3,0.7-0.5c0.3-0.5,0.1-1.2-0.4-1.5L28.8,31.8z"></path>
                            <path class="st0" d="M21.2,31.8l-1.3,0.7c-0.5,0.3-0.7,1-0.4,1.5c0.1,0.3,0.4,0.4,0.7,0.5c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.5-0.1
                            l1.3-0.7c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.3,0-0.6-0.1-0.8C22.4,31.7,21.7,31.5,21.2,31.8z"></path>
                         </g>
                      </svg>
          `
				},
				{
					title: 'Multiple Device Compatibility',
					text: 'Our augmented reality mobile apps perform flawlessly on a range of devices. Whether it’s an iOS, Android, head-mounted device or a wearable, the AR mobile app developers at GenixBit will serve your best experience with each of them.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                         <g>
                            <path class="st0" d="M5.6,8.9c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7S6.3,10,6.3,9.6C6.3,9.2,5.9,8.9,5.6,8.9z"></path>
                            <path class="st0" d="M11.1,9.6c0-1.6-1.3-2.9-2.9-2.9H5.6C4,6.7,2.7,8,2.7,9.6s1.3,2.9,2.9,2.9h2.6C9.8,12.4,11.1,11.2,11.1,9.6z
                            M4,9.6C4,8.7,4.7,8,5.6,8h2.6c0.9,0,1.5,0.7,1.5,1.6s-0.7,1.6-1.5,1.6H5.6C4.7,11.1,4,10.4,4,9.6z"></path>
                            <path class="st0" d="M12,17.2c0.2,0,0.3-0.1,0.5-0.2l1-1c0.3-0.3,0.3-0.7,0-0.9c-0.3-0.3-0.7-0.3-0.9,0l-1,1
                            c-0.3,0.3-0.3,0.7,0,0.9C11.7,17.1,11.8,17.2,12,17.2z"></path>
                            <path class="st0" d="M13.9,17.3c-0.6,0-1.3,0.2-1.9,0.6c-0.6-0.4-1.3-0.6-1.9-0.6c-1,0-2,0.5-2.6,1.3c-0.7,1-0.9,2.3-0.6,3.6
                            c0.7,2.4,2.2,4,3.8,4c0.4,0,0.9-0.1,1.3-0.3c0.4,0.2,0.8,0.3,1.3,0.3c0,0,0,0,0,0c1.2,0,2.4-0.9,3.2-2.5c0.1-0.2,0.1-0.4,0-0.5
                            c-0.1-0.2-0.2-0.3-0.4-0.4c-0.4-0.2-0.7-0.6-0.7-1.1c0-0.6,0.5-1.1,1.1-1.1c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.2-0.4,0.1-0.6
                            C16.7,18.4,15.4,17.3,13.9,17.3z M14.1,21.8c0,0.8,0.4,1.5,0.9,1.9c-0.5,0.8-1.1,1.2-1.7,1.2c-0.3,0-0.6-0.1-0.9-0.3
                            c-0.2-0.2-0.5-0.2-0.8,0C11.3,24.9,11,25,10.7,25c-1,0-2-1.2-2.5-3C7.9,21,8,20.1,8.5,19.5c0.4-0.5,1-0.8,1.6-0.8
                            c0.5,0,1,0.2,1.5,0.6c0.2,0.2,0.6,0.2,0.8,0c0.5-0.4,1-0.6,1.5-0.6c0.7,0,1.3,0.3,1.6,0.9C14.7,20,14.1,20.8,14.1,21.8z"></path>
                            <path class="st0" d="M20.9,4H3C1.4,4,0,5.3,0,7v35.3c0,1.6,1.3,3,2.9,3h17.9c1.6,0,2.9-1.3,2.9-3V7C23.8,5.3,22.5,4,20.9,4z
                            M22.5,42.3c0,0.9-0.7,1.6-1.6,1.6H3c-0.9,0-1.6-0.7-1.6-1.6V7c0-0.9,0.7-1.6,1.6-1.6h17.9c0.9,0,1.6,0.7,1.6,1.6L22.5,42.3
                            L22.5,42.3z"></path>
                         </g>
                         <g>
                            <path class="st0" d="M37.7,38.6c-1.3,0-2.3,1.1-2.3,2.4c0,1.3,1.1,2.4,2.3,2.4c1.3,0,2.3-1.1,2.3-2.4C40,39.7,39,38.6,37.7,38.6z
                            M37.7,42.2c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C38.8,41.6,38.3,42.2,37.7,42.2z"></path>
                            <path class="st0" d="M28.7,37.5h17.9c0.4,0,0.7-0.3,0.7-0.7V9.9c0-0.4-0.3-0.7-0.7-0.7H28.7c-0.4,0-0.7,0.3-0.7,0.7v26.9
                            C28,37.2,28.3,37.5,28.7,37.5z M29.4,10.6h16.5v25.5H29.4V10.6z"></path>
                            <path class="st0" d="M28.1,45.9h19.2c1.5,0,2.7-1.2,2.7-2.7v-36c0-1.5-1.2-2.7-2.7-2.7H28.1c-1.5,0-2.7,1.2-2.7,2.7v36
                            C25.3,44.6,26.6,45.9,28.1,45.9z M39.8,5.8c-0.1,0.4-0.3,0.7-0.7,0.7h-2.9c-0.3,0-0.6-0.3-0.7-0.7C35.5,5.8,39.8,5.8,39.8,5.8z
                            M26.7,7.2c0-0.7,0.6-1.3,1.3-1.3h6.3c0.1,1.1,0.8,1.9,1.8,1.9h2.9c1,0,1.7-0.8,1.8-1.9h6.3c0.7,0,1.3,0.6,1.3,1.3v36
                            c0,0.7-0.6,1.3-1.3,1.3H28.1c-0.7,0-1.3-0.6-1.3-1.3V7.2z"></path>
                            <path class="st0" d="M32.5,20.9c-0.3,0-0.6,0.3-0.6,0.6v4.8c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-4.8
                            C33.2,21.1,32.9,20.9,32.5,20.9z"></path>
                            <path class="st0" d="M42.8,20.9c-0.3,0-0.6,0.3-0.6,0.6v4.8c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-4.8
                            C43.4,21.1,43.1,20.9,42.8,20.9z"></path>
                            <circle class="st0" cx="36.7" cy="19.7" r="0.4"></circle>
                            <circle class="st0" cx="38.6" cy="19.7" r="0.4"></circle>
                            <path class="st0" d="M41.8,20.9C41.8,20.9,41.8,20.9,41.8,20.9c-0.1-1.1-0.7-2-1.7-2.7l0.3-0.5c0.2-0.3,0.1-0.7-0.2-0.8
                            c-0.3-0.2-0.7-0.1-0.8,0.2L39,17.7c-0.4-0.1-0.9-0.2-1.3-0.2c-0.5,0-0.9,0.1-1.4,0.2L35.9,17c-0.2-0.3-0.6-0.3-0.8-0.2
                            c-0.3,0.2-0.3,0.6-0.2,0.8l0.3,0.5c-0.9,0.6-1.5,1.5-1.7,2.6c0,0,0,0.1,0,0.1c0,0,0,0,0,0v6.3c0,0.4,0.2,0.7,0.3,0.9
                            c0.3,0.3,0.7,0.3,0.8,0.3c0,0,0,0,0,0h0.5h0.3h0.1v2c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6v-2h0c0.1,0,0.1,0,0.2,0h1.3
                            c0.1,0,0.1,0,0.2,0h0v2c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-2h0.6c0,0,0.1,0,0.1,0h0.2c0.6,0,1-0.5,1.1-1.1L41.8,20.9
                            C41.8,20.9,41.8,20.9,41.8,20.9z M36.2,19C36.3,19,36.3,19,36.2,19c0.1-0.1,0.2-0.1,0.2-0.1c0.4-0.2,0.8-0.2,1.3-0.2
                            c0.4,0,0.8,0.1,1.2,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.6,0.3,1,0.7,1.3,1.3h-5.5C35.2,19.8,35.6,19.3,36.2,19z M40.6,21.5
                            l0.1,5.7h-0.8c0,0-0.1,0-0.1,0h-4.6c0,0-0.1,0-0.1,0l-0.3,0v-5.7H40.6z"></path>
                         </g>
                      </svg>
          `
				}
			],
			items2: [
				{
					title: 'Cost Efficient',
					text: 'We understand the role funds play when it’s about your entrepreneurial journey. To assist you in the best possible ways, we thrive to build highly cost-effective solutions for your business. As they say, a penny saved is a penny earned.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                         <path class="st3" d="M16.3,22.6c-1-0.3-2.1,0-2.6,0.8L13.3,24v-1.7c0.1-0.5,0.2-0.9,0.3-1.3l3.4-7.5c0.4-0.8,0.2-1.8-0.4-2.4
                         c-0.5-0.4-1.1-0.6-1.7-0.6c-0.7,0.1-1.2,0.3-1.6,0.8l-5.1,6.4c-0.5,0.6-0.8,1.3-1.1,2.1l-1.4,5.1H0v13.3h6.7v-1.7h0.8
                         c6,0,8.2-1.3,8.3-5.3c0-0.3,0.1-0.6,0.2-1.1l1.8-4.6c0.3-0.7,0.2-1.3-0.1-1.8C17.4,23.2,16.9,22.8,16.3,22.6z M1.7,36.6v-10H5v10
                         H1.7z M16.3,25.1l-1.8,4.6c-0.3,0.5-0.3,1-0.3,1.5c-0.1,2.3-0.6,3.7-6.7,3.7H6.7v-6.6l2.2-8c0.2-0.6,0.4-1.1,0.8-1.5l5.1-6.4
                         c0.1-0.2,0.2-0.2,0.3-0.2s0.2,0,0.3,0.2c0.1,0.1,0.2,0.3,0.1,0.5l-3.4,7.5c-0.3,0.8-0.3,1.4-0.4,2V29l3.5-4.4
                         c0.2-0.2,0.5-0.3,0.8-0.2c0.2,0.1,0.3,0.2,0.3,0.3C16.3,24.8,16.3,25,16.3,25.1z"></path>
                         <path class="st3" d="M44.1,24.9l-1.3-5c-0.3-0.8-0.6-1.4-1.1-2.1l-5.1-6.4c-0.4-0.5-1-0.8-1.6-0.8c-0.7-0.1-1.3,0.2-1.7,0.6
                         c-0.6,0.7-0.8,1.6-0.4,2.4l3.4,7.5c0.3,0.5,0.3,0.9,0.3,1.3v1.7l-0.3-0.4c-0.6-0.8-1.7-1.3-2.7-0.9c-0.6,0.2-1.1,0.6-1.3,1.2
                         c-0.3,0.6-0.3,1.3-0.1,1.8l1.8,4.6c0.1,0.3,0.2,0.7,0.2,1c0.2,3.9,2.3,5.3,8.3,5.3h0.8v1.7H50V24.9H44.1z M42.7,34.9
                         c-6.1,0-6.6-1.5-6.7-3.7c-0.1-0.5-0.2-1-0.3-1.5l-1.7-4.6c-0.1-0.2-0.1-0.3,0-0.5c0-0.2,0.2-0.2,0.3-0.3c0,0,0.1,0,0.2,0
                         c0.2,0,0.4,0.2,0.6,0.3l3.3,4.3v-6.7c0-0.5-0.1-1.3-0.4-1.9l-3.4-7.5c-0.1-0.2-0.1-0.3,0.1-0.5c0.1-0.2,0.3-0.2,0.3-0.2
                         c0.1,0,0.3,0,0.3,0.2l5.3,6.3c0.3,0.4,0.6,0.9,0.8,1.5l2,7.5v7.2H42.7z M48.3,36.6H45v-10h3.3V36.6z"></path>
                         <path class="st3" d="M28.3,19.9H30c0-2.5-1.8-4.5-4.2-4.9v-1.8h-1.7V15c-2.3,0.4-4.2,2.5-4.2,4.9s1.8,4.5,4.2,4.9v6.6
                         c-1.4-0.3-2.5-1.7-2.5-3.3H20c0,2.5,1.8,4.5,4.2,4.9v1.8h1.7v-1.8c2.3-0.4,4.2-2.5,4.2-4.9s-1.8-4.5-4.2-4.9v-6.6
                         C27.3,17.1,28.3,18.3,28.3,19.9z M21.7,19.9c0-1.6,1.1-2.8,2.5-3.3v6.4C22.8,22.7,21.7,21.5,21.7,19.9z M28.3,28.2
                         c0,1.6-1.1,2.8-2.5,3.3v-6.4C27.3,25.4,28.3,26.6,28.3,28.2z"></path>
                      </svg>
          `
				},
				{
					title: 'Enhance your Brand Value',
					text: 'We ensure building AR mobile app development solutions that help you attract and engage your target audience. With more exposure, you can surpass your competitors and build a better brand value.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                         <g>
                            <path class="st0" d="M36.4,42.1l3.9-3c1.1-0.8,1.5-1.9,2-3c0.1-0.2,0.1-0.3,0.2-0.5l0,0l1.1-3.5c0.1-0.2,0.2-0.5,0.2-0.8
                            c0-0.5-0.2-1-0.6-1.4c-0.5-0.5-1.1-0.7-1.8-0.6c-0.7,0.1-1.2,0.6-1.5,1.2l0,0l-1,3.2c-0.1-0.1-0.3-0.2-0.4-0.2
                            c-0.6-0.3-1.3-0.2-1.8,0.1l-3.6,1.7c-1.9,0.9-3.2,2.9-3.1,5l0,1.3h0v7h-7.6v-7h0l0-1.3c0.1-2.1-1.2-4.1-3.1-5l-3.6-1.7
                            c-0.5-0.3-1.2-0.4-1.8-0.1c-0.1,0.1-0.3,0.1-0.4,0.2l-1-3.1l0-0.1c-0.3-0.6-0.8-1-1.5-1.2c-0.7-0.1-1.3,0.1-1.8,0.6
                            c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.3,0.1,0.5,0.2,0.8l1.1,3.5l0,0.1c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.1,0.9,2.2,2,3l3.9,3v6.5h-5.3v1.2
                            h5.3h6.5h7.6h6.5h5.5v-1.2h-5.5V42.1z M12.5,38.2L12.5,38.2c-0.8-0.6-1.2-1.5-1.6-2.5c-0.1-0.2-0.1-0.3-0.2-0.5l-1.1-3.5l0-0.1
                            c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.7-0.2c0.3,0,0.5,0.2,0.6,0.4l1.4,4.2c0,0,0,0.1,0,0.1
                            c0,0.7,0.3,1.3,0.9,1.7l2.1,1.3l0.7-1L14,35.9c-0.2-0.1-0.4-0.4-0.4-0.7c0,0,0-0.1,0-0.1l0,0c0,0,0,0,0,0c0.1-0.3,0.2-0.5,0.5-0.6
                            c0.2-0.1,0.5-0.1,0.7,0.1l0,0l3.7,1.7c1.5,0.7,2.4,2.2,2.4,3.9l0,0l0,1.4h-4L12.5,38.2z M21,48.6h-4.1v-5.8H21V48.6z M35.2,48.6
                            h-4.1v-5.8h4.1V48.6z M35,41.6h-4l0-1.3l0,0c0-1.7,0.9-3.2,2.4-3.9l3.7-1.7l0,0c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0.1,0.4,0.3,0.5,0.6
                            c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1c0,0.3-0.1,0.5-0.4,0.7l-2.1,1.3l0.7,1l2.1-1.3c0.6-0.4,0.9-1,0.9-1.7c0,0,0-0.1,0-0.1L41,31
                            c0.1-0.2,0.3-0.4,0.6-0.4c0.3,0,0.5,0,0.7,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.1,0,0.2-0.1,0.3l0,0l-1.1,3.5c-0.1,0.2-0.1,0.3-0.2,0.5
                            c-0.4,1-0.8,1.9-1.6,2.5L35,41.6z"></path>
                            <path class="st0" d="M14.5,30.8L26,38.1l11.6-7.3v-6.5h-1.2v5.8l-9.7,6.2V24.5l3.5,2l12.3-7l-10.3-5.7l-1.2-0.7c0,0,0-0.1,0-0.1
                            c0.1-0.1,0.2-0.3,0.4-0.5c0.4-0.4,0.8-0.9,0.8-1.5c0-0.6-0.4-1.1-0.8-1.5C31.2,9.2,31,9,30.9,8.9c-0.1-0.2-0.1-0.4-0.1-0.7
                            c0-0.5,0-1.2-0.5-1.6c-0.4-0.4-1.1-0.5-1.6-0.5c-0.3,0-0.5,0-0.7-0.1c-0.1-0.1-0.3-0.2-0.5-0.4c-0.4-0.4-0.9-0.8-1.5-0.8
                            s-1.1,0.4-1.5,0.8C24.3,5.7,24.1,5.9,24,6c-0.2,0.1-0.4,0.1-0.7,0.1c-0.5,0-1.2,0-1.6,0.5c-0.4,0.4-0.5,1.1-0.5,1.6
                            c0,0.3,0,0.5-0.1,0.7c-0.1,0.1-0.2,0.3-0.4,0.5c-0.4,0.4-0.8,0.9-0.8,1.5c0,0.6,0.4,1.1,0.8,1.5c0.2,0.2,0.4,0.4,0.4,0.5
                            c0,0,0,0.1,0,0.1L9.6,19.4l4.8,2.8L14.5,30.8z M25.4,36.3l-9.7-6.2l0-7.2l6.2,3.6l3.5-2V36.3z M26,22.7l-0.9-0.5l0.9-0.9l0.9,0.9
                            L26,22.7z M39.9,19.5l-9.8,5.6l-2.9-1.7l9.7-5.6L39.9,19.5z M31.2,14.6l4.6,2.5l-6.3,3.6v-5.1c0.3-0.1,0.7-0.2,1-0.4
                            c0.2-0.2,0.4-0.5,0.4-0.8L31.2,14.6z M21.1,10.9c0-0.2,0.3-0.4,0.5-0.7c0.3-0.3,0.5-0.5,0.7-0.9c0.2-0.4,0.2-0.8,0.2-1.1
                            c0-0.3,0-0.7,0.1-0.8c0.1-0.1,0.5-0.1,0.8-0.1c0.4,0,0.8,0,1.1-0.2c0.3-0.1,0.6-0.4,0.9-0.7C25.6,6.2,25.9,6,26,6
                            c0.2,0,0.4,0.3,0.7,0.5C27,6.7,27.2,7,27.6,7.1c0.4,0.2,0.8,0.2,1.1,0.2c0.3,0,0.7,0,0.8,0.1c0.1,0.1,0.1,0.5,0.1,0.8
                            c0,0.4,0,0.8,0.2,1.1c0.1,0.3,0.4,0.6,0.7,0.9c0.2,0.2,0.5,0.5,0.5,0.7c0,0.2-0.3,0.4-0.5,0.7c-0.3,0.3-0.5,0.5-0.7,0.9
                            c-0.2,0.4-0.2,0.8-0.2,1.1c0,0.3,0,0.7-0.1,0.8c-0.1,0.1-0.5,0.1-0.8,0.1c-0.4,0-0.8,0-1.1,0.2c-0.3,0.1-0.6,0.4-0.9,0.7
                            c-0.2,0.2-0.5,0.5-0.7,0.5c-0.2,0-0.4-0.3-0.7-0.5c-0.3-0.3-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.2-1.1-0.2c-0.3,0-0.7,0-0.8-0.1
                            c-0.1-0.1-0.1-0.5-0.1-0.8c0-0.4,0-0.8-0.2-1.1c-0.1-0.3-0.4-0.6-0.7-0.9C21.4,11.3,21.1,11.1,21.1,10.9z M21.3,14.4
                            c0.1,0.3,0.2,0.6,0.4,0.8c0.4,0.4,1.1,0.5,1.6,0.5c0.3,0,0.5,0,0.7,0.1c0.1,0.1,0.3,0.2,0.5,0.4c0.4,0.4,0.9,0.8,1.5,0.8
                            c0.6,0,1.1-0.4,1.5-0.8c0.2-0.2,0.4-0.4,0.5-0.4c0,0,0.1,0,0.1,0v5.7L28,21.6l-2-2l-2,2l-0.2-0.1v-4.9h-1.2v4.2l-6.3-3.6L21.3,14.4
                            z M12.1,19.5l2.9-1.6l9.7,5.6l-2.9,1.7L12.1,19.5z"></path>
                            <path class="st0" d="M26,14.2c1.8,0,3.3-1.5,3.3-3.3c0-0.5-0.1-1-0.3-1.5L27.9,10c0.1,0.3,0.2,0.6,0.2,0.9c0,1.2-0.9,2.1-2.1,2.1
                            c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1c0.5,0,1,0.2,1.4,0.5l0.8-0.9c-0.6-0.5-1.4-0.8-2.2-0.8c-1.8,0-3.3,1.5-3.3,3.3
                            C22.7,12.7,24.2,14.2,26,14.2z"></path>
                            <rect x="25.4" y="0.2" class="st0" width="1.2" height="3.8"></rect>
                            <rect x="28.1" y="2.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.327 22.2278)" class="st0" width="3.8" height="1.2"></rect>
                            <rect x="21.4" y="1.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 4.002 16.6133)" class="st0" width="1.2" height="3.8"></rect>
                            <rect x="10.2" y="16" transform="matrix(0.8757 -0.4828 0.4828 0.8757 -6.6216 7.4993)" class="st0" width="2.1" height="1.2"></rect>
                            <rect x="13.1" y="13.3" transform="matrix(0.8757 -0.4828 0.4828 0.8757 -4.6813 9.5584)" class="st0" width="6.2" height="1.2"></rect>
                            <rect x="43.7" y="33.5" transform="matrix(0.2885 -0.9575 0.9575 0.2885 -0.8262 67.1495)" class="st0" width="2.2" height="1.2"></rect>
                            <path class="st0" d="M43.2,36.9c-0.4,1-0.8,1.9-1.6,2.5l-4.1,3.2v3.6h1.2v-3.1l3.6-2.8c1-0.8,1.5-1.9,2-3c0.1-0.2,0.1-0.3,0.2-0.5
                            l-1.1-0.5C43.4,36.6,43.3,36.7,43.2,36.9z"></path>
                         </g>
                      </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
.ar-img-cont ,
.ar-img-cont img{
  display:block;
  min-height:97%;
  border-radius:15px
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
