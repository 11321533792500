<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Everything you need to serve endless entertainment</h2>
				<p>
					We build all types of entertainment apps that modern-day users have got accustomed to as a
					way of life.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../../IndustrySolutions/AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M49.22,35.87H48.2V20.6c-0.16-4.81-4.3-7.98-5.63-8.84V4.33c0-1.32-1.02-2.33-2.34-2.33H2.34C1.02,2,0,3.09,0,4.33v26.04
                    c0,1.32,1.02,2.33,2.34,2.33h32.42c0.7,1.4,1.25,1.78,1.72,3.1h-0.86c-0.47,0-0.78,0.31-0.78,0.78v9.61
                    c0,0.47,0.31,0.78,0.78,0.78h13.59c0.47,0,0.78-0.31,0.78-0.78v-9.53C50,36.18,49.69,35.87,49.22,35.87z M37.19,3.55h3.13
                    c0.31,0,0.7,0.39,0.7,0.77c0,4.42,0,7.52,0,11.94c0,0.16,0.08,0.39,0.16,0.47c0.7,0.85,1.02,3.8,0.86,7.21
                    c-0.08,0.7-0.7,1.24-1.48,1.08c-0.78-0.15-0.86-0.7-0.86-2.63c0-0.62,0-1.32-0.08-2.09c-0.16-1.4-1.02-2.25-2.42-2.48V3.55z
                    M33.91,20.6c-0.08,3.26,0.08,2.48-0.16,4.34c-0.23,1.55-0.47,3.72,0.39,6.28H2.34v-0.08c-0.47,0-0.78-0.31-0.78-0.77V4.33
                    c0-0.46,0.31-0.77,0.78-0.77h33.2v14.49C34.53,18.51,33.91,19.44,33.91,20.6z M35.31,25.18c0.31-2.02,0.08-1.4,0.16-4.57
                    c0-0.62,0.47-1.16,1.17-1.24c1.17,0.08,1.33,0.62,1.41,1.16c0.08,0.7,0.08,1.4,0.08,1.94c0,1.86,0.08,3.57,2.03,4.11
                    c1.72,0.46,3.36-0.78,3.44-2.48c0.08-1.86,0.23-6.2-1.02-8.06V13.7c1.48,1.16,3.91,3.64,4.06,6.9v15.27h-8.52
                    C37.19,32.23,34.38,31.84,35.31,25.18z M48.44,45.41H36.41v-7.98c3.28,0,9.37,0,12.03,0V45.41z M20.31,23.24
                    c4.77,0,8.59-3.8,8.59-8.53c0-4.73-3.83-8.53-8.59-8.53c-4.77,0-8.59,3.8-8.59,8.53C11.72,19.36,15.55,23.24,20.31,23.24z
                    M20.31,7.66c3.91,0,7.03,3.1,7.03,6.98c0,3.88-3.13,6.98-7.03,6.98c-3.91,0-7.03-3.1-7.03-6.98
                    C13.28,10.84,16.41,7.66,20.31,7.66z M19.3,18.9l4.77-3.18c0.78-0.54,0.78-1.63,0-2.17l-4.77-3.18c-0.86-0.54-2.03,0.08-2.03,1.08
                    v6.28C17.27,18.9,18.44,19.52,19.3,18.9z M18.83,12l4.06,2.71l-4.06,2.71V12z M30.16,25.87H11.09v-0.47
                    c0-0.46-0.31-0.77-0.78-0.77s-0.78,0.31-0.78,0.77v0.47H6.56c-0.47,0-0.78,0.31-0.78,0.77c0,0.47,0.31,0.78,0.78,0.78h2.97v0.47
                    c0,0.46,0.31,0.77,0.78,0.77s0.78-0.31,0.78-0.77v-0.47h19.06c0.47,0,0.78-0.31,0.78-0.78C30.94,26.18,30.55,25.87,30.16,25.87z
                    M39.53,42.15h1.56c0.47,0,0.78-0.31,0.78-0.77c0-0.39-0.39-0.78-0.78-0.78h-1.56c-0.47,0-0.78,0.31-0.78,0.78
                    C38.75,41.84,39.06,42.15,39.53,42.15z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Audio & Video Streaming'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M59.6,60.1l5.7-4.4l5.7,3.4c0.4,0.2,0.8,0.3,1.2,0.2c0.4-0.1,0.8-0.3,1.1-0.6l2.8-3.1c0.7-0.8,0.6-2-0.2-2.6
                    c-0.8-0.6-1.9-0.6-2.6,0.1l-1.7,1.9l-5.5-3.3c-0.7-0.4-1.6-0.4-2.2,0.1l-4.4,3.4v-8.4h12.4c1,0,1.9-0.8,1.9-1.9
                    c0-1-0.8-1.9-1.9-1.9H57.7c-1,0-1.9,0.8-1.9,1.9v18.7c0,1,0.8,1.9,1.9,1.9h29.1c1,0,1.9-0.8,1.9-1.9v-7.5c0-1-0.9-1.8-1.9-1.8
                    c-1,0-1.9,0.8-1.9,1.9v5.6H59.6V60.1z M86.8,69.3H57.7c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h29.1c1,0,1.9-0.8,1.9-1.9
                    C88.7,70.1,87.8,69.3,86.8,69.3z M86.8,76.8H57.7c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h29.1c1,0,1.9-0.8,1.9-1.9
                    C88.7,77.7,87.8,76.8,86.8,76.8z M34.1,7.2c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9C32.2,6.4,33.1,7.2,34.1,7.2
                    z M44.5,9.1c7.1,2.5,12.9,7.6,16.4,14.3c0.7,0.8,1.9,0.9,2.7,0.2c0.6-0.5,0.8-1.2,0.6-1.9C60.2,14.2,53.7,8.4,45.8,5.6
                    c-1-0.3-2,0.3-2.3,1.3C43.2,7.7,43.7,8.7,44.5,9.1z M3.7,26.7c1,0.4,2.1,0,2.5-1c0,0,0,0,0,0C9.9,16.9,17.7,10.3,27,8.1
                    c1-0.3,1.6-1.3,1.3-2.3c-0.3-0.9-1.2-1.5-2.2-1.3C15.6,6.9,6.9,14.3,2.7,24.3C2.3,25.3,2.8,26.3,3.7,26.7z M38.9,67.6
                    c-8.8,1.3-17.7-1.2-24.6-6.8c-7-6.2-10.9-15.3-10.5-24.7c0.1-1-0.7-1.9-1.8-2c-1-0.1-1.9,0.7-2,1.8C-0.3,46,3.7,55.8,11.1,62.8
                    c7.6,6.7,17.8,9.9,27.8,8.6c1,0,1.9-0.8,1.9-1.9C40.8,68.4,39.9,67.6,38.9,67.6z M99.5,44.7L83.6,28.9c-0.4-0.4-0.9-0.6-1.4-0.6
                    H49.9c-3,0-5.4,2.4-5.4,5.4v57.5c0,3,2.4,5.4,5.4,5.4h44.7c3,0,5.4-2.4,5.4-5.4V46C100,45.5,99.8,45.1,99.5,44.7z M79.6,35.7
                    c-1,0-1.9,0.8-1.9,1.9v11.1c0,1,0.8,1.9,1.9,1.9h16.7v40.5c0,0.9-0.7,1.6-1.6,1.6H49.9c-0.9,0-1.6-0.7-1.6-1.6V33.6
                    c0-0.9,0.7-1.6,1.6-1.6h31.5l14.8,14.8H81.4v-9.2C81.4,36.6,80.6,35.7,79.6,35.7z M38.9,60c-12.3,2.7-24.5-5.1-27.2-17.5
                    C9.1,30.2,16.9,18,29.2,15.3c8.6-1.9,17.6,1.4,22.9,8.4c0.6,0.8,1.8,0.9,2.6,0.3c0.8-0.6,1-1.7,0.5-2.5
                    c-8.9-11.7-25.5-13.9-37.2-5C6.5,25.4,4.2,42,13.1,53.7c6,7.9,16,11.8,25.8,10c1,0,1.9-0.8,1.9-1.9C40.8,60.8,39.9,60,38.9,60z
                    M32.2,18.8C32.2,18.8,32.2,18.8,32.2,18.8v18.1l-7.4,7.4c-0.8,0.7-0.8,1.9-0.2,2.7c0.7,0.8,1.9,0.8,2.7,0.2
                    c0.1,0,0.1-0.1,0.2-0.2l7.9-8c0.4-0.4,0.6-0.8,0.6-1.3l0-18.7c0-1-0.8-1.9-1.9-1.9C33.1,16.9,32.2,17.7,32.2,18.8z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Real-Time Content Update'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M72.6,14.5c-15.1,0-27.4,12.3-27.4,27.4v0.4c0,5.3,1.5,10.5,4.5,15l19.1,29.3c0.9,1.3,2.3,2.1,3.8,2.1
                    c1.6,0,3-0.8,3.8-2.1l19.1-29.3c2.9-4.5,4.5-9.6,4.5-15v-0.4C100,26.8,87.7,14.5,72.6,14.5z M96.8,42.3c0,4.7-1.4,9.3-3.9,13.2
                    L73.7,84.9c-0.5,0.8-1.8,0.8-2.3,0L52.3,55.5c-2.6-3.9-3.9-8.5-3.9-13.2v-0.4c0-13.3,10.9-24.2,24.2-24.2
                    c13.3,0,24.2,10.9,24.2,24.2V42.3z M51.6,80.6h3.2V69.4h-3.2V80.6z M3.2,12.9h48.4v4.8h3.2V8.1c0-4.4-3.6-8.1-8.1-8.1H8.1
                    C3.6,0,0,3.6,0,8.1v72.6h3.2V12.9z M3.2,8.1c0-2.7,2.2-4.8,4.8-4.8h38.7c2.7,0,4.8,2.2,4.8,4.8v1.6H3.2V8.1z M0,91.9
                    c0,4.4,3.6,8.1,8.1,8.1h38.7c4.4,0,8.1-3.6,8.1-8.1v-8.1H0V91.9z M3.2,87.1h48.4v4.8c0,2.7-2.2,4.8-4.8,4.8H8.1
                    c-2.7,0-4.8-2.2-4.8-4.8V87.1z M33.9,90.3H21v3.2h12.9V90.3z M48.4,24.2v-8.1H6.5v64.5h41.9V61.3h-3.2v16.1h-6.5V45.2h3.2v-3.2
                    h-6.5v35.5H29V64.5H9.7v-6.5H29V41.9H9.7v-6.5h9.7V19.4h6.5v16.1h16.1v-3.2H29V19.4h16.1v4.8H48.4z M25.8,67.7v9.7H9.7v-9.7H25.8z
                    M25.8,45.2v9.7H9.7v-9.7H25.8z M16.1,32.3H9.7V19.4h6.5V32.3z M72.6,24.2c-9.8,0-17.7,8-17.7,17.7c0,9.8,8,17.7,17.7,17.7
                    s17.7-8,17.7-17.7C90.3,32.2,82.4,24.2,72.6,24.2z M72.6,56.5c-8,0-14.5-6.5-14.5-14.5c0-8,6.5-14.5,14.5-14.5
                    c8,0,14.5,6.5,14.5,14.5C87.1,49.9,80.6,56.5,72.6,56.5z M64.5,43.5h3.2v-3.2h-3.2V43.5z M71,43.5h3.2v-3.2H71V43.5z M77.4,43.5
                    h3.2v-3.2h-3.2V43.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'GPS Enabled'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M8.23,7.03c0,0.43,0.35,0.78,0.77,0.78H30.7c0.43,0,0.77-0.35,0.77-0.78s-0.35-0.78-0.77-0.78H9.01
                    C8.58,6.25,8.23,6.6,8.23,7.03z M22.18,4.69c0.43,0,0.77-0.35,0.77-0.78c0-0.43-0.35-0.78-0.77-0.78h-4.65
                    c-0.43,0-0.77,0.35-0.77,0.78c0,0.43,0.35,0.78,0.77,0.78H22.18z M26.82,42.97H9.01c-0.43,0-0.77,0.35-0.77,0.78
                    c0,0.43,0.35,0.78,0.77,0.78h17.82c0.43,0,0.77-0.35,0.77-0.78C27.6,43.33,27.25,42.97,26.82,42.97z M8.23,13.28
                    c0,0.43,0.35,0.78,0.77,0.78c0.43,0,0.77-0.35,0.77-0.78v-1.56c0-0.43,0.35-0.78,0.77-0.78h1.55c0.43,0,0.77-0.35,0.77-0.78
                    c0-0.43-0.35-0.78-0.77-0.78h-1.55c-1.28,0-2.32,1.05-2.32,2.34V13.28z M15.98,10.94c0.43,0,0.77-0.35,0.77-0.78
                    c0-0.43-0.35-0.78-0.77-0.78H15.2c-0.43,0-0.77,0.35-0.77,0.78c0,0.43,0.35,0.78,0.77,0.78H15.98z M8.23,39.07
                    c0,1.29,1.04,2.34,2.32,2.34h1.55c0.43,0,0.77-0.35,0.77-0.78s-0.35-0.78-0.77-0.78h-1.55c-0.43,0-0.77-0.35-0.77-0.78v-1.56
                    c0-0.43-0.35-0.78-0.77-0.78c-0.43,0-0.77,0.35-0.77,0.78V39.07z M8.23,34.38c0,0.43,0.35,0.78,0.77,0.78
                    c0.43,0,0.77-0.35,0.77-0.78V33.6c0-0.43-0.35-0.78-0.77-0.78c-0.43,0-0.77,0.35-0.77,0.78V34.38z M10.56,25
                    c0,5.18,4.16,9.38,9.3,9.38c5.13,0,9.3-4.2,9.3-9.38c0-5.18-4.16-9.38-9.3-9.38C14.72,15.63,10.56,19.83,10.56,25z M19.85,17.19
                    c4.28,0,7.75,3.5,7.75,7.81c0,4.32-3.47,7.81-7.75,7.81c-4.28,0-7.75-3.5-7.75-7.81C12.11,20.69,15.57,17.19,19.85,17.19z
                    M20.63,27.35h-1.55c-0.43,0-0.77-0.35-0.77-0.78c0-0.43-0.35-0.78-0.77-0.78s-0.77,0.35-0.77,0.78c0,1.29,1.04,2.34,2.32,2.34
                    v0.78c0,0.43,0.35,0.78,0.77,0.78c0.43,0,0.77-0.35,0.77-0.78v-0.78c1.28,0,2.32-1.05,2.32-2.34s-1.04-2.34-2.32-2.34h-1.55
                    c-0.43,0-0.77-0.35-0.77-0.78c0-0.43,0.35-0.78,0.77-0.78h1.55c0.43,0,0.77,0.35,0.77,0.78c0,0.43,0.35,0.78,0.77,0.78
                    s0.77-0.35,0.77-0.78c0-1.29-1.04-2.34-2.32-2.34v-0.78c0-0.43-0.35-0.78-0.77-0.78c-0.43,0-0.77,0.35-0.77,0.78v0.78
                    c-1.28,0-2.32,1.05-2.32,2.34c0,1.29,1.04,2.34,2.32,2.34h1.55c0.43,0,0.77,0.35,0.77,0.78C21.4,27,21.05,27.35,20.63,27.35z
                    M41.46,26c-1.63-1.62-3.82-2.54-6.11-2.56V3.91c0-2.16-1.73-3.91-3.87-3.91H8.23C6.09,0,4.36,1.75,4.36,3.91V46.1
                    c0,2.16,1.73,3.91,3.87,3.91h17.82c0.43,0,0.77-0.35,0.77-0.78c0-0.43-0.35-0.78-0.77-0.78H8.23c-1.28,0-2.32-1.05-2.32-2.34V3.91
                    c0-1.29,1.04-2.34,2.32-2.34h23.24c1.28,0,2.32,1.05,2.32,2.34v27.35c0,0.43,0.35,0.78,0.77,0.78c0.43,0,0.77-0.35,0.77-0.78V25
                    c7.65,0.39,7.75,8.99,7.75,9.38v14.07H30.12c-0.63-2.61,0.18-5.37,2.12-7.21c0.33-0.27,0.38-0.77,0.11-1.1
                    c-0.01-0.02-0.03-0.03-0.04-0.05l-3.4-3.52c-0.75-0.79-0.75-2.03,0-2.81c0.71-0.74,1.88-0.77,2.62-0.06
                    c0.02,0.02,0.04,0.04,0.05,0.06l6.43,6.64c0.3,0.31,0.79,0.31,1.1,0.01s0.31-0.8,0.01-1.11l-6.43-6.64
                    c-0.64-0.67-1.52-1.05-2.45-1.05c-0.92,0-1.81,0.38-2.45,1.05c-1.33,1.4-1.33,3.61,0,5l2.9,2.97c-2.14,2.39-2.87,5.74-1.92,8.81
                    c0.1,0.35,0.42,0.58,0.77,0.57h14.32c0.43,0,0.77-0.35,0.77-0.78V34.38C44.64,34.17,44.64,29.19,41.46,26z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'In-App Purchases'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M1.96,6.44h1.62v1.6c0,0.59,0.48,1.06,1.08,1.06c0.6,0,1.08-0.48,1.08-1.06v-1.6h1.62c0.6,0,1.08-0.48,1.08-1.06
                    c0-0.59-0.48-1.06-1.08-1.06H5.74v-1.6c0-0.59-0.48-1.06-1.08-1.06c-0.6,0-1.08,0.48-1.08,1.06v1.6H1.96
                    c-0.6,0-1.08,0.48-1.08,1.06C0.89,5.96,1.37,6.44,1.96,6.44z M48.92,26.49h-1.62v-1.6c0-0.59-0.48-1.06-1.08-1.06
                    c-0.6,0-1.08,0.48-1.08,1.06v1.6h-1.62c-0.6,0-1.08,0.48-1.08,1.06c0,0.59,0.48,1.06,1.08,1.06h1.62v1.6
                    c0,0.59,0.48,1.07,1.08,1.07c0.6,0,1.08-0.48,1.08-1.07v-1.6h1.62c0.6,0,1.08-0.48,1.08-1.06C50,26.97,49.52,26.49,48.92,26.49z
                    M45.69,10.99c1.63,0,2.97-1.31,2.97-2.92s-1.33-2.93-2.97-2.93s-2.96,1.31-2.96,2.93C42.73,9.67,44.05,10.99,45.69,10.99z
                    M45.69,7.26c0.45,0,0.81,0.36,0.81,0.8c0,0.44-0.36,0.8-0.81,0.8c-0.45,0-0.81-0.36-0.81-0.8C44.88,7.63,45.25,7.26,45.69,7.26z
                    M6.18,22.58c0-1.61-1.33-2.93-2.96-2.93c-1.63,0-2.97,1.31-2.97,2.93c0,1.61,1.33,2.92,2.97,2.92
                    C4.85,25.51,6.18,24.19,6.18,22.58z M3.22,23.38c-0.45,0-0.81-0.36-0.81-0.8c0-0.44,0.36-0.8,0.81-0.8c0.45,0,0.81,0.36,0.81,0.8
                    S3.66,23.38,3.22,23.38z M6.35,12.58c0,0.74,0.6,1.33,1.35,1.33c0.75,0,1.35-0.6,1.35-1.33c0-0.74-0.6-1.33-1.35-1.33
                    C6.95,11.25,6.35,11.85,6.35,12.58z M40.05,3c0.75,0,1.35-0.6,1.35-1.33c0-0.74-0.6-1.33-1.35-1.33c-0.75,0-1.35,0.6-1.35,1.33
                    C38.7,2.41,39.3,3,40.05,3z M43.53,21.7c0.75,0,1.35-0.6,1.35-1.33c0-0.74-0.6-1.33-1.35-1.33c-0.75,0-1.35,0.6-1.35,1.33
                    C42.18,21.1,42.79,21.7,43.53,21.7z M40.92,33.49c-1.14-1.45-1.76-3.26-1.76-5.09v-7.59c0-7.69-6.04-14.19-13.57-14.75V3.68
                    c0-0.59-0.48-1.06-1.08-1.06c-0.6,0-1.08,0.48-1.08,1.06v2.39c-3.42,0.24-6.6,1.64-9.09,4.02c-2.89,2.75-4.48,6.45-4.48,10.41
                    v7.91c0,1.83-0.62,3.64-1.76,5.09c-1.06,1.35-1.95,2.83-2.64,4.4c-0.53,1.21-0.41,2.59,0.33,3.7c0.75,1.13,2.01,1.8,3.37,1.8h9
                    c0.52,3,3.16,5.3,6.35,5.3c3.19,0,5.83-2.3,6.35-5.3h9c1.36,0,2.62-0.67,3.37-1.8c0.74-1.11,0.86-2.49,0.33-3.7
                    C42.87,36.32,41.98,34.84,40.92,33.49z M24.5,46.57c-1.99,0-3.66-1.35-4.15-3.17h8.29C28.17,45.22,26.5,46.57,24.5,46.57z
                    M41.43,40.43c-0.35,0.53-0.94,0.84-1.57,0.84H9.15c-0.64,0-1.22-0.31-1.57-0.84c-0.34-0.52-0.4-1.13-0.15-1.69
                    c0.62-1.4,1.41-2.73,2.37-3.94c1.43-1.82,2.22-4.09,2.22-6.39V20.5c0-3.38,1.36-6.53,3.82-8.88c2.35-2.24,5.41-3.46,8.67-3.46
                    c0.15,0,0.31,0,0.46,0.01c6.64,0.24,12.03,5.91,12.03,12.65v7.59c0,1.6,0.39,3.18,1.09,4.61h-9.68c-0.6,0-1.08,0.48-1.08,1.06
                    c0,0.59,0.48,1.06,1.08,1.06h11.06c0.47,0.62,0.89,1.26,1.27,1.93H34.1c-0.6,0-1.08,0.48-1.08,1.06c0,0.59,0.48,1.06,1.08,1.06
                    h7.61C41.77,39.62,41.68,40.05,41.43,40.43z M29,13.58c0.19,0.12,0.39,0.18,0.6,0.18c0.35,0,0.69-0.16,0.9-0.47
                    c0.33-0.49,0.2-1.15-0.29-1.48c-1.59-1.06-3.43-1.65-5.32-1.72c-0.99-0.03-1.97,0.07-2.92,0.3c-0.58,0.14-0.93,0.72-0.79,1.29
                    c0.14,0.57,0.73,0.92,1.3,0.78c0.75-0.18,1.55-0.26,2.33-0.24C26.3,12.28,27.74,12.75,29,13.58z M18.63,11.86
                    c-0.42,0.28-0.82,0.59-1.2,0.92c-0.44,0.39-0.48,1.07-0.07,1.5c0.22,0.23,0.5,0.35,0.8,0.35c0.26,0,0.52-0.09,0.72-0.27
                    c0.3-0.27,0.62-0.52,0.95-0.74c0.49-0.33,0.62-0.99,0.29-1.48C19.79,11.66,19.12,11.53,18.63,11.86z M16.72,15.09
                    c-0.52-0.29-1.18-0.1-1.46,0.42c-0.85,1.52-1.29,3.24-1.29,4.99v5.25c0,0.59,0.48,1.06,1.08,1.06c0.6,0,1.08-0.48,1.08-1.06V20.5
                    c0-1.38,0.36-2.76,1.03-3.97C17.43,16.02,17.24,15.37,16.72,15.09z M30.32,37.07h-4.85c-0.6,0-1.08,0.48-1.08,1.06
                    c0,0.59,0.48,1.06,1.08,1.06h4.85c0.6,0,1.08-0.48,1.08-1.06C31.4,37.54,30.91,37.07,30.32,37.07z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Push Notifications'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M18.6,5h1.69V3.33H18.6V5z M28.72,5h1.69V3.33h-1.69V5z M27.87,15h-1.69v1.67h1.69V15z M31.25,15h-1.69v1.67h1.69V15z
                    M34.62,15h-1.69v1.67h1.69V15z M15.22,35h1.69v-1.67h-1.69V35z M18.6,35h1.69v-1.67H18.6V35z M23.66,33.33h-1.69V35h1.69V33.33z
                    M21.97,5h5.06V3.33h-5.06V5z M18.6,43.33h-3.37V45h3.37V43.33z M33.78,43.33H30.4V45h3.37V43.33z M24.5,46.67
                    c1.39,0,2.53-1.12,2.53-2.5c0-1.38-1.14-2.5-2.53-2.5c-1.39,0-2.53,1.12-2.53,2.5C21.97,45.54,23.11,46.67,24.5,46.67z
                    M24.5,43.33c0.47,0,0.84,0.37,0.84,0.83c0,0.46-0.38,0.83-0.84,0.83c-0.47,0-0.84-0.37-0.84-0.83
                    C23.66,43.71,24.03,43.33,24.5,43.33z M17.75,15H4.26v1.67h13.49V15z M10.16,18.33h-5.9V20h5.9V18.33z M17.75,21.67H4.26v1.67
                    h13.49V21.67z M15.22,20h2.53v-1.67h-2.53V20z M13.54,20v-1.67h-1.69V20H13.54z M44.74,31.67c-1.15,0-2.17,0.57-2.78,1.45
                    l-2.28-1.13v-7.3l2.28-1.13c0.61,0.87,1.62,1.45,2.78,1.45c1.86,0,3.37-1.49,3.37-3.33c0-1.84-1.51-3.33-3.37-3.33
                    c-1.86,0-3.37,1.49-3.37,3.33c0,0.1,0.02,0.2,0.03,0.3l-1.72,0.85V7.5v-5c0-1.38-1.14-2.5-2.53-2.5h-25.3
                    c-1.39,0-2.53,1.12-2.53,2.5v5v4.17h-5.9c-1.39,0-2.53,1.12-2.53,2.5v10c0,1.38,1.13,2.5,2.53,2.5h5.9v12.5v8.33
                    c0,1.38,1.14,2.5,2.53,2.5h25.3c1.39,0,2.53-1.12,2.53-2.5v-8.33v-5.32l1.72,0.85c-0.01,0.1-0.03,0.2-0.03,0.3
                    c0,1.84,1.51,3.33,3.37,3.33c1.86,0,3.37-1.49,3.37-3.33C48.11,33.16,46.6,31.67,44.74,31.67z M44.74,20
                    c0.93,0,1.69,0.75,1.69,1.67c0,0.92-0.76,1.67-1.69,1.67c-0.93,0-1.69-0.75-1.69-1.67C43.05,20.75,43.81,20,44.74,20z M3.42,25
                    c-0.47,0-0.84-0.37-0.84-0.83v-10c0-0.46,0.38-0.83,0.84-0.83h6.75h8.43c0.47,0,0.84,0.37,0.84,0.83v10
                    c0,0.46-0.38,0.83-0.84,0.83h-0.84c-0.47,0-0.84,0.37-0.84,0.83v1.32l-1.93-1.91c-0.16-0.16-0.37-0.24-0.6-0.24h-4.22H3.42z
                    M37.99,47.5c0,0.46-0.38,0.83-0.84,0.83h-25.3c-0.47,0-0.84-0.37-0.84-0.83V40h26.98V47.5z M37.99,31.15l-2.86-1.41
                    c0.2-0.43,0.33-0.9,0.33-1.41c0-0.5-0.12-0.98-0.33-1.41l2.86-1.41V31.15z M37.99,23.65l-3.97,1.96C33.48,25.23,32.81,25,32.09,25
                    c-1.86,0-3.37,1.49-3.37,3.33c0,1.84,1.51,3.33,3.37,3.33c0.72,0,1.39-0.23,1.94-0.61l3.97,1.96v5.32H11.01V26.67h3.02l3.13,3.09
                    c0.07,0.07,0.16,0.12,0.25,0.17c0.02,0.01,0.05,0.02,0.07,0.03c0.07,0.02,0.15,0.04,0.22,0.04c0.02,0,0.03,0.01,0.05,0.01
                    c0.01,0,0.02,0,0.03-0.01c0.1,0,0.2-0.02,0.3-0.06c0.32-0.13,0.52-0.43,0.52-0.77v-2.5c1.39,0,2.53-1.12,2.53-2.5v-10
                    c0-1.38-1.14-2.5-2.53-2.5h-7.59V8.33h26.98V23.65z M33.78,28.33c0,0.92-0.76,1.67-1.69,1.67c-0.93,0-1.69-0.75-1.69-1.67
                    c0-0.92,0.76-1.67,1.69-1.67C33.02,26.67,33.78,27.41,33.78,28.33z M37.99,6.67H11.01V2.5c0-0.46,0.38-0.83,0.84-0.83h25.3
                    c0.47,0,0.84,0.37,0.84,0.83V6.67z M44.74,36.67c-0.93,0-1.69-0.75-1.69-1.67c0-0.92,0.76-1.67,1.69-1.67
                    c0.93,0,1.69,0.75,1.69,1.67C46.42,35.92,45.67,36.67,44.74,36.67z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Social Media Sharing'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M31.15,17.33c0.06-8.58-6.91-15.6-15.58-15.68C6.53,1.65,0.22,9.74-0.01,17.3c-0.06,2.99,0.79,5.93,2.45,8.44
                    c-0.53,1.54-0.8,3.16-0.81,4.79c0,2.43,0.6,4.83,1.73,6.98c0,0.01,0.01,0.01,0.02,0.02c0.01,0.02,0.03,0.03,0.04,0.05
                    c0.04,0.06,0.09,0.12,0.15,0.17c0.02,0.02,0.04,0.03,0.06,0.05c0.08,0.06,0.17,0.1,0.26,0.12l0.01,0
                    c0.07,0.02,0.14,0.03,0.21,0.03h0h0c0.08,0,0.16-0.02,0.24-0.04c0.02-0.01,0.04-0.02,0.07-0.02c0.03-0.01,0.05-0.02,0.08-0.03
                    c0.02-0.01,0.03-0.03,0.05-0.04c0.16-0.12,0.29-0.28,0.37-0.47c0-0.01,0.01-0.01,0.01-0.02c0.63-2.57,2.55-4.63,5.08-5.47
                    c1.77,0.74,3.66,1.13,5.58,1.14C24.24,32.92,31.21,25.91,31.15,17.33z M15.57,31.36c-1.81,0-3.6-0.39-5.25-1.13
                    c-0.04-0.01-0.08-0.02-0.12-0.03c-0.11-0.05-0.24-0.05-0.35-0.02c-0.04,0-0.08,0.01-0.13,0.01c-0.01,0-0.02,0.01-0.03,0.01
                    l-0.01,0c-1.8,0.57-3.4,1.65-4.59,3.1c-0.4,0.5-0.75,1.04-1.04,1.62c-1.03-2.93-1-6.11,0.08-9.02c0.01-0.02,0-0.04,0.01-0.06
                    c0.05-0.21,0.03-0.43-0.07-0.62c-0.01-0.02-0.01-0.04-0.02-0.06c-1.59-2.3-2.43-5.03-2.38-7.82C1.86,10.57,7.5,3.3,15.57,3.3
                    c7.75,0.08,13.98,6.35,13.91,14.03C29.55,25,23.32,31.28,15.57,31.36z M19.68,10.73c-0.03-2.25-1.9-4.04-4.17-4.01
                    c-2.27,0.03-4.09,1.88-4.05,4.13c0.03,2.23,1.86,4.01,4.11,4.01C17.86,14.83,19.69,12.99,19.68,10.73z M15.57,13.2
                    c-1.38,0.03-2.53-1.05-2.56-2.42c-0.03-1.37,1.06-2.5,2.44-2.53c1.38-0.03,2.53,1.05,2.56,2.42c0,0.02,0,0.04,0,0.06
                    C18.03,12.08,16.94,13.18,15.57,13.2z M17.12,14.85h-1.55h-1.58c-2.3,0-4.17,1.85-4.17,4.13v8.25c0,0.46,0.37,0.83,0.83,0.83h9.83
                    c0.46,0,0.83-0.37,0.83-0.83v-8.22C21.32,16.72,19.44,14.85,17.12,14.85z M19.65,26.4h-8.16v-7.43c0-1.37,1.12-2.47,2.5-2.48h3.13
                    c1.4,0,2.53,1.12,2.54,2.51V26.4z M37.69,24.75c-6.33,0-11.48,4.81-11.48,10.73c0,5.91,5.15,10.73,11.48,10.73
                    c1.38,0,2.74-0.26,4.03-0.75c0.94,0.3,1.78,0.83,2.44,1.56c0.47,0.55,0.82,1.19,1.01,1.88c0.03,0.1,0.07,0.19,0.13,0.26
                    c0.02,0.02,0.04,0.04,0.06,0.06c0.05,0.06,0.11,0.1,0.17,0.14c0.01,0.01,0.02,0.02,0.03,0.03c0.03,0.01,0.06,0.03,0.1,0.04
                    c0.1,0.04,0.2,0.07,0.3,0.07l0.01,0c0.02,0,0.03-0.01,0.05-0.01c0.06,0,0.11-0.01,0.17-0.02l0.01,0c0.01,0,0.01-0.01,0.01-0.01
                    c0.09-0.03,0.17-0.07,0.25-0.12c0.02-0.01,0.04-0.03,0.06-0.04c0.07-0.06,0.14-0.13,0.18-0.21l0,0l0,0
                    c1.41-2.42,1.68-5.32,0.74-7.95c1.16-1.67,1.77-3.66,1.73-5.68C48.77,29.46,43.76,24.79,37.69,24.75z M45.84,40.53
                    c-0.01,0.03-0.03,0.05-0.04,0.08c-0.03,0.05-0.05,0.1-0.07,0.15c-0.03,0.1-0.04,0.2-0.04,0.31c0,0.06,0.01,0.11,0.03,0.17
                    c0,0.03,0.01,0.06,0.02,0.09c0.38,0.94,0.58,1.95,0.58,2.96c0,0.81-0.12,1.61-0.37,2.38c-0.16-0.25-0.34-0.49-0.53-0.72
                    c-0.9-1-2.07-1.73-3.37-2.12l-0.01,0c-0.03-0.01-0.05-0.02-0.07-0.03c-0.04-0.01-0.07-0.01-0.11-0.01
                    c-0.11-0.03-0.23-0.02-0.34,0.02c-0.04,0-0.07,0.01-0.11,0.02c-1.18,0.48-2.44,0.73-3.72,0.74c-5.41,0-9.82-4.07-9.82-9.08
                    c0-5.01,4.4-9.08,9.82-9.08c5.17,0.03,9.45,3.99,9.82,9.1C47.53,37.32,46.94,39.08,45.84,40.53z M40.97,0
                    c-5.02,0.04-9.06,4.1-9.03,9.08c-0.08,4.94,3.9,9,8.89,9.08c4.99,0.08,9.09-3.86,9.17-8.8c0-0.09,0-0.19,0-0.28
                    C50.03,4.1,45.99,0.04,40.97,0z M48.33,9.08c0.03,4.06-3.26,7.38-7.36,7.43c-4.1-0.04-7.4-3.37-7.36-7.43
                    c-0.08-4.02,3.15-7.35,7.22-7.43c4.07-0.08,7.42,3.12,7.5,7.15C48.33,8.89,48.33,8.98,48.33,9.08z M44.33,4.31
                    c-0.68-0.24-1.43-0.24-2.11,0c-0.48,0.17-0.91,0.46-1.25,0.84c-0.84-0.93-2.17-1.26-3.36-0.83c-0.93,0.39-1.65,1.14-2.01,2.07
                    c-0.47,1.23-0.49,2.58-0.04,3.82c0.94,3.2,3.85,4.84,5.06,5.4c0.01,0.01,0.03,0,0.04,0.01c0.1,0.04,0.2,0.07,0.31,0.07l0,0l0,0
                    l0,0l0,0c0.11,0,0.21-0.03,0.31-0.07c0.01-0.01,0.03,0,0.04-0.01c1.21-0.56,4.12-2.2,5.07-5.43c0.44-1.23,0.43-2.58-0.05-3.79
                    C45.98,5.45,45.26,4.7,44.33,4.31z M44.78,9.74c-0.65,1.84-2.03,3.35-3.81,4.19c-1.79-0.84-3.17-2.36-3.82-4.22
                    c-0.33-0.86-0.34-1.8-0.03-2.67c0.18-0.52,0.56-0.95,1.06-1.19c0.31-0.11,0.65-0.11,0.96,0c0.49,0.21,0.86,0.62,1.03,1.12
                    C40.19,7,40.2,7.01,40.21,7.03c0.09,0.2,0.25,0.35,0.45,0.44c0.02,0.01,0.03,0.02,0.04,0.03c0.03,0.01,0.07,0.01,0.1,0.01
                    c0.11,0.03,0.23,0.03,0.34,0c0.03,0,0.07-0.01,0.1-0.01c0.02-0.01,0.03-0.02,0.04-0.03c0.2-0.09,0.36-0.25,0.45-0.45
                    c0.01-0.02,0.02-0.03,0.02-0.04c0.17-0.5,0.55-0.9,1.03-1.12c0.31-0.11,0.65-0.11,0.96,0c0.5,0.24,0.88,0.67,1.06,1.19
                    C45.13,7.92,45.12,8.87,44.78,9.74z M42.74,32.44h-2.27c0.63-2.31,0.41-4.06-0.66-4.8c-0.83-0.59-1.97-0.54-2.74,0.13
                    c-0.36,0.48-0.47,1.11-0.28,1.68c0.04,0.17,0.06,0.34,0.07,0.51c-0.01,0.71-0.96,1.59-2.66,2.49h-3.06
                    c-0.46,0-0.83,0.37-0.83,0.82c0,0,0,0,0,0v6.6c0,0.46,0.37,0.83,0.83,0.83c0,0,0,0,0,0H41c2.27-0.02,4.1-1.85,4.09-4.1v-1.81
                    C45.09,33.5,44.04,32.45,42.74,32.44z M33.58,39.04h-1.61v-4.95h1.61V39.04z M43.41,36.59c0.01,1.34-1.07,2.43-2.42,2.45h-5.75
                    v-5.28c2.18-1.2,3.26-2.44,3.28-3.78c0-0.28-0.03-0.56-0.1-0.84c-0.01-0.08-0.03-0.18-0.05-0.26c0.16-0.01,0.33,0.03,0.47,0.11
                    c0.37,0.26,0.53,1.7-0.31,3.99c-0.16,0.43,0.07,0.9,0.5,1.06c0.09,0.03,0.19,0.05,0.28,0.05h3.41c0.38,0.01,0.68,0.32,0.68,0.7
                    V36.59z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Social Media Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M41.17,7.12c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78c0.43,0,0.78-0.35,0.78-0.78
                    C41.95,7.47,41.6,7.12,41.17,7.12z M38.06,7.12c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78
                    c0.43,0,0.78-0.35,0.78-0.78C38.84,7.47,38.5,7.12,38.06,7.12z M34.96,7.12c-0.43,0-0.78,0.35-0.78,0.78
                    c0,0.43,0.35,0.78,0.78,0.78c0.43,0,0.78-0.35,0.78-0.78C35.74,7.47,35.39,7.12,34.96,7.12z M46.09,29.5
                    c-0.43,0-0.78,0.35-0.78,0.78v13.81c-0.02,0.66-0.56,1.18-1.22,1.17H2.78c-0.66,0.01-1.2-0.51-1.22-1.17V12.44h43.75v2.73
                    c0,0.43,0.35,0.78,0.78,0.78c0.43,0,0.78-0.35,0.78-0.78V5.79c-0.02-1.52-1.26-2.74-2.78-2.73H2.78C1.26,3.05,0.02,4.27,0,5.79
                    v38.29c0.02,1.52,1.26,2.74,2.78,2.73h41.31c1.52,0.01,2.76-1.21,2.78-2.73V30.28C46.88,29.85,46.53,29.5,46.09,29.5z M1.56,5.79
                    c0.02-0.66,0.56-1.18,1.22-1.17h41.31c0.66-0.01,1.2,0.51,1.22,1.17v5.08H1.56V5.79z M22.66,34.32H5.47
                    c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78h17.19c0.43,0,0.78-0.35,0.78-0.78C23.44,34.67,23.09,34.32,22.66,34.32z
                    M22.66,39.01H5.47c-0.43,0-0.78,0.35-0.78,0.78c0,0.43,0.35,0.78,0.78,0.78h17.19c0.43,0,0.78-0.35,0.78-0.78
                    C23.44,39.36,23.09,39.01,22.66,39.01z M37.5,17.91c0-0.43-0.35-0.78-0.78-0.78h-9.38c-0.43,0-0.78,0.35-0.78,0.78
                    c0,0.43,0.35,0.78,0.78,0.78h9.38C37.15,18.69,37.5,18.34,37.5,17.91z M27.34,21.82c-0.43,0-0.78,0.35-0.78,0.78
                    c0,0.43,0.35,0.78,0.78,0.78h6.25c0.43,0,0.78-0.35,0.78-0.78c0-0.43-0.35-0.78-0.78-0.78H27.34z M49.77,22.05
                    C49.77,22.05,49.77,22.05,49.77,22.05l-4.7-4.71c-0.3-0.31-0.8-0.31-1.1,0c0,0,0,0,0,0L28.58,32.72c-0.09,0.1-0.16,0.21-0.2,0.34
                    l-1.79,6.5c-0.11,0.42,0.13,0.85,0.55,0.96c0.14,0.04,0.28,0.04,0.42,0l6.5-1.79c0.13-0.04,0.25-0.1,0.34-0.2l15.38-15.38
                    C50.08,22.85,50.08,22.35,49.77,22.05z M28.46,38.66l0.52-1.88l1.36,1.36L28.46,38.66z M33.43,37.28l-1.36,0.39l-2.62-2.62
                    l0.39-1.36l11.56-11.59l3.6,3.61L33.43,37.28z M46.09,24.6l-3.6-3.6l2.04-2.01l3.58,3.6L46.09,24.6z M23.44,17.91
                    c0-0.03,0-0.06,0-0.09c0-0.02,0-0.04,0-0.06c-0.01-0.03-0.01-0.05-0.02-0.08c0-0.02,0-0.05-0.02-0.07
                    c-0.02-0.02-0.02-0.04-0.03-0.06c-0.01-0.03-0.03-0.05-0.04-0.07l-0.04-0.04l-0.05-0.06l-0.06-0.05l-0.06-0.04l-0.07-0.04
                    l-0.07-0.03l-0.08-0.02h-0.07h-0.09H5.42H5.33H5.26L5.18,17.2l-0.06,0.03l-0.07,0.04l-0.06,0.04l-0.06,0.05l-0.05,0.06l-0.04,0.04
                    c-0.02,0.02-0.03,0.05-0.04,0.07c0,0.02-0.02,0.04-0.03,0.06c-0.01,0.02,0,0.05-0.02,0.07c-0.01,0.03-0.02,0.05-0.02,0.08
                    c0,0.02,0,0.04,0,0.06c-0.01,0.03-0.02,0.06-0.03,0.1v12.5c0,0.03,0,0.06,0,0.09c0,0.02,0,0.04,0,0.06
                    c0.01,0.03,0.01,0.05,0.02,0.08c0,0.02,0,0.05,0.02,0.07c0.02,0.02,0.02,0.04,0.03,0.06c0.01,0.03,0.03,0.05,0.04,0.07l0.03,0.03
                    c0.02,0.03,0.05,0.06,0.08,0.09l0.05,0.04l0.09,0.06l0.05,0.03l0.11,0.04h0.04c0.06,0.03,0.13,0.05,0.2,0.05h17.19
                    c0.06,0,0.11-0.01,0.17-0.02h0.04l0.11-0.04l0.05-0.03l0.09-0.06l0.05-0.04c0.03-0.03,0.06-0.06,0.08-0.09l0.03-0.03
                    c0.02-0.02,0.03-0.05,0.04-0.07c0-0.02,0.02-0.04,0.03-0.06c0.01-0.02,0-0.05,0.02-0.07c0.01-0.03,0.02-0.05,0.02-0.08
                    c0-0.02,0-0.04,0-0.06c0.01-0.04,0.02-0.09,0.03-0.13V17.91z M20.25,18.69l-6.19,4.51l-6.19-4.51H20.25z M6.25,19.44l6.48,4.72
                    l-6.48,4.72V19.44z M7.87,29.63l6.19-4.51l6.19,4.51H7.87z M21.88,28.88l-6.48-4.72l6.48-4.72V28.88z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Advance CMS'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
