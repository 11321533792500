<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Why Choose our Wordpress Development Services</h2>
				<p>
					The internet has countless websites. If you want your website to stand out from the crowd
					and create its own identity, you need an expert’s help. That’s where Inteliviate comes in.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Dedicated Wordpress team",
               text:"An exclusive WordPress development team that stays on top of the latest trends and complex development practices.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path class="st0" d="M25,24.2c4.2,0,7.2-5.4,7.2-9c0-3.9-3.3-7.1-7.2-7.1c-4,0-7.2,3.2-7.2,7.1C17.8,18.8,20.8,24.2,25,24.2z
                           M25,9.7c3.1,0,5.6,2.4,5.6,5.5c0,3.2-2.7,7.4-5.6,7.4c-2.9,0-5.6-4.2-5.6-7.4C19.4,12.2,21.9,9.7,25,9.7z"></path>
                           <path class="st0" d="M40.6,27c3.3,0,5.6-4.2,5.6-7c0-3-2.5-5.5-5.6-5.5c-3.1,0-5.6,2.5-5.6,5.5C35,22.9,37.4,27,40.6,27z
                           M40.6,16.1c2.2,0,4,1.7,4,3.9c0,2.3-2,5.4-4,5.4c-2.1,0-4-3-4-5.4C36.6,17.9,38.4,16.1,40.6,16.1z"></path>
                           <path class="st0" d="M9.4,27c3.3,0,5.6-4.2,5.6-7c0-3-2.5-5.5-5.6-5.5c-3.1,0-5.6,2.5-5.6,5.5C3.8,22.9,6.2,27,9.4,27z M9.4,16.1
                           c2.2,0,4,1.7,4,3.9c0,2.3-2,5.4-4,5.4s-4-3-4-5.4C5.4,17.9,7.2,16.1,9.4,16.1z"></path>
                           <path class="st0" d="M48.6,28.5c-1.8-2-4.6-1.9-4.6-1.9l-0.2,0.2c-1.9,1.4-4.1,1.4-6.1,0l-0.2-0.2c0,0-0.7-0.1-1.8,0.2
                           c-2.7-2.8-6.1-2.6-6.1-2.6l-0.2,0.2c-2.7,1.9-5.8,1.9-8.4,0l-0.2-0.2c0,0-3.3-0.3-6.1,2.6c-1.1-0.3-1.8-0.2-1.8-0.2l-0.2,0.2
                           c-1.9,1.4-4.1,1.4-6.1,0l-0.2-0.2c0,0-2.9-0.1-4.6,1.9c-1.1,1.2-1.6,2.8-1.4,4.3l0.7,7.5h12.8l0.2,1.6h22.4l0.2-1.6h12.8l0.7-7.5
                           C50.1,31.2,49.6,29.7,48.6,28.5z M12.9,32.2l0.6,6.5H2.3l-0.6-6c-0.1-1.1,0.3-2.3,1-3.1c0.7-0.8,1.8-1.3,2.9-1.3
                           c2.3,1.6,5.2,1.6,7.5,0c0.1,0,0.3,0,0.4,0C13,29.5,12.7,30.8,12.9,32.2z M35.6,32l-0.8,8.3H15.3L14.5,32c-0.2-1.6,0.4-3.2,1.4-4.3
                           c1.1-1.2,2.6-1.9,4.2-1.9h0c3.1,2.1,6.8,2.1,9.9,0h0c1.6,0,3.1,0.7,4.2,1.9C35.3,28.8,35.8,30.4,35.6,32z M47.8,38.7H36.6l0.6-6.5
                           c0.1-1.4-0.1-2.7-0.7-3.9c0.1,0,0.3,0,0.4,0c2.3,1.6,5.2,1.6,7.5,0c1.1,0,2.2,0.5,2.9,1.3c0.8,0.8,1.1,2,1,3.1L47.8,38.7z"></path>
                        </g>
                     </svg>
               `
            },
            {
               title:"WordPress coding compliance",
               text:"All WordPress development offerings of Genixbit comply to the coding standards set forth by WordPress.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path d="M36.7,45.8v-2.5h5c1.8,0,3.3-1.5,3.3-3.3V15c1.8,0,3.3-1.5,3.3-3.3V3.3C48.3,1.5,46.8,0,45,0H28.3C26.5,0,25,1.5,25,3.3
                           H8.3C6.5,3.3,5,4.8,5,6.7v25H2.5c-0.5,0-0.8,0.4-0.8,0.8v13.3c0,0.5,0.4,0.8,0.8,0.8h33.3C36.3,46.7,36.7,46.3,36.7,45.8z
                           M26.7,3.3c0-0.9,0.7-1.7,1.7-1.7H45c0.9,0,1.7,0.7,1.7,1.7v8.3c0,0.9-0.7,1.7-1.7,1.7H35c-0.2,0-0.3,0-0.5,0.1l-3.7,2.5v-1.8
                           c0-0.5-0.4-0.8-0.8-0.8h-1.7c-0.9,0-1.7-0.7-1.7-1.7V3.3z M6.7,11.7h16.7V10H6.7V6.7C6.7,5.7,7.4,5,8.3,5H25v6.7
                           c0,1.8,1.5,3.3,3.3,3.3h0.8v2.5c0,0.5,0.4,0.8,0.8,0.8c0.2,0,0.3,0,0.5-0.1l4.8-3.2h8.1v25c0,0.9-0.7,1.7-1.7,1.7h-5v-9.2
                           c0-0.5-0.4-0.8-0.8-0.8H6.7V11.7z M35,45H3.3V33.3H35V45z"></path>
                           <rect x="5" y="41.7" width="1.7" height="1.7"></rect>
                           <rect x="5" y="38.3" width="3.3" height="1.7"></rect>
                           <rect x="10" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="13.3" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="16.7" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="20" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="23.3" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="26.7" y="38.3" width="1.7" height="1.7"></rect>
                           <rect x="30" y="38.3" width="3.3" height="1.7"></rect>
                           <rect x="5" y="35" width="1.7" height="1.7"></rect>
                           <rect x="8.3" y="35" width="1.7" height="1.7"></rect>
                           <rect x="11.7" y="35" width="1.7" height="1.7"></rect>
                           <rect x="15" y="35" width="1.7" height="1.7"></rect>
                           <rect x="18.3" y="35" width="1.7" height="1.7"></rect>
                           <rect x="21.7" y="35" width="1.7" height="1.7"></rect>
                           <rect x="25" y="35" width="1.7" height="1.7"></rect>
                           <rect x="28.3" y="35" width="1.7" height="1.7"></rect>
                           <rect x="31.7" y="35" width="1.7" height="1.7"></rect>
                           <rect x="8.3" y="41.7" width="1.7" height="1.7"></rect>
                           <rect x="31.7" y="41.7" width="1.7" height="1.7"></rect>
                           <rect x="28.3" y="41.7" width="1.7" height="1.7"></rect>
                           <rect x="11.7" y="41.7" width="15" height="1.7"></rect>
                           <rect x="8.3" y="6.7" width="1.7" height="1.7"></rect>
                           <rect x="11.7" y="6.7" width="1.7" height="1.7"></rect>
                           <rect x="15" y="6.7" width="1.7" height="1.7"></rect>
                           <path d="M11.1,14.4l-1.7,1.7c-0.3,0.3-0.3,0.9,0,1.2l1.7,1.7l1.2-1.2l-1.1-1.1l1.1-1.1L11.1,14.4z"></path>
                           <path d="M17.3,18.9l1.7-1.7c0.3-0.3,0.3-0.9,0-1.2l-1.7-1.7l-1.2,1.2l1.1,1.1l-1.1,1.1L17.3,18.9z"></path>
                           <rect x="11.5" y="15.8" transform="matrix(0.3165 -0.9486 0.9486 0.3165 -6.1274 24.829)" width="5.3" height="1.7"></rect>
                           <rect x="20.8" y="17.5" width="1.7" height="1.7"></rect>
                           <rect x="9.2" y="20.8" width="1.7" height="1.7"></rect>
                           <rect x="9.2" y="24.2" width="1.7" height="1.7"></rect>
                           <rect x="9.2" y="27.5" width="1.7" height="1.7"></rect>
                           <rect x="12.5" y="20.8" width="23.3" height="1.7"></rect>
                           <rect x="24.2" y="17.5" width="1.7" height="1.7"></rect>
                           <rect x="12.5" y="24.2" width="3.3" height="1.7"></rect>
                           <rect x="12.5" y="27.5" width="3.3" height="1.7"></rect>
                           <rect x="17.5" y="27.5" width="3.3" height="1.7"></rect>
                           <rect x="22.5" y="27.5" width="3.3" height="1.7"></rect>
                           <rect x="17.5" y="24.2" width="3.3" height="1.7"></rect>
                           <rect x="22.5" y="24.2" width="3.3" height="1.7"></rect>
                           <rect x="27.5" y="24.2" width="3.3" height="1.7"></rect>
                           <rect x="37.5" y="20.8" width="3.3" height="1.7"></rect>
                           <rect x="6.7" y="48.3" width="21.7" height="1.7"></rect>
                           <path d="M1.7,5.8v13.3h1.7V5.8c0-2.3,1.9-4.2,4.2-4.2h3.3V0H7.5C4.3,0,1.7,2.6,1.7,5.8z"></path>
                           <rect x="46.7" y="35.8" width="1.7" height="1.7"></rect>
                           <rect x="46.7" y="19.2" width="1.7" height="15"></rect>
                           <path d="M30.2,8.1l1.2-1.2l-1.1-1.1l1.1-1.1l-1.2-1.2l-1.7,1.7c-0.3,0.3-0.3,0.9,0,1.2L30.2,8.1z"></path>
                           <path d="M36.4,8.1l1.7-1.7c0.3-0.3,0.3-0.9,0-1.2l-1.7-1.7l-1.2,1.2l1.1,1.1l-1.1,1.1L36.4,8.1z"></path>
                           <rect x="30.7" y="5" transform="matrix(0.3165 -0.9486 0.9486 0.3165 17.2494 35.6062)" width="5.3" height="1.7"></rect>
                           <rect x="40" y="5" width="1.7" height="1.7"></rect>
                           <rect x="28.3" y="10" width="1.7" height="1.7"></rect>
                           <rect x="31.7" y="10" width="13.3" height="1.7"></rect>
                           <rect x="43.3" y="5" width="1.7" height="1.7"></rect>
                        </g>
                     </svg>
               `
            },
            {
               title:"Result-oriented Process",
               text:"All our WordPress development efforts are done with the long-term goal and results in mind. No quick fixes whatsoever.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M8.5,30.9c-2.9,2.9-2.9,7.7,0,10.6c1.5,1.5,3.4,2.2,5.3,2.2s3.8-0.7,5.3-2.2c2.9-2.9,2.9-7.7,0-10.6S11.4,27.9,8.5,30.9z
                        M17.9,40.3c-2.3,2.3-6,2.3-8.3,0c-2.3-2.3-2.3-6,0-8.3c1.1-1.1,2.6-1.7,4.1-1.7c1.5,0,3,0.6,4.1,1.7C20.2,34.3,20.2,38,17.9,40.3z"></path>
                        <path d="M11.4,32.6l1.2,1.2c0.6-0.6,1.7-0.6,2.4,0l1.2-1.2C14.9,31.4,12.7,31.4,11.4,32.6z"></path>
                        <path d="M13.8,26.2c-2.7,0-5.2,1-7.1,2.9C3.7,32.2,3,36.7,4.8,40.5L3.2,42c-0.3,0.3-0.3,0.9,0,1.2l-2.9,2.9c-0.3,0.3-0.3,0.9,0,1.2
                        l2.4,2.4C2.8,49.9,3,50,3.2,50c0,0,0,0,0,0c0.2,0,0.4-0.1,0.6-0.3l2.9-3C6.9,46.9,7.1,47,7.3,47c0.2,0,0.4-0.1,0.6-0.2l1.6-1.6
                        c1.3,0.6,2.8,1,4.3,1c2.7,0,5.2-1,7.1-2.9c3.9-3.9,3.9-10.2,0-14.1C19,27.2,16.5,26.2,13.8,26.2z M3.2,48L2,46.8l2.4-2.4l1.2,1.2
                        L3.2,48z M7.3,45L5,42.6l0.7-0.7c0.3,0.4,0.7,0.9,1.1,1.3C7.1,43.6,7.6,44,8,44.3L7.3,45z M19.7,42c-1.6,1.6-3.7,2.4-5.9,2.4
                        S9.5,43.6,7.9,42c-3.2-3.2-3.2-8.5,0-11.8c1.6-1.6,3.7-2.4,5.9-2.4s4.3,0.9,5.9,2.4C22.9,33.5,22.9,38.8,19.7,42z"></path>
                        <path d="M48.3,25V8.3v-5c0-0.5-0.4-0.8-0.8-0.8h-3.3V0.8c0-0.5-0.4-0.8-0.8-0.8H40c-0.5,0-0.8,0.4-0.8,0.8v1.7h-6.7V0.8
                        c0-0.5-0.4-0.8-0.8-0.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8v1.7h-3.3c-0.5,0-0.8,0.4-0.8,0.8v5V25c0,0.5,0.4,0.8,0.8,0.8h23.3
                        C48,25.8,48.3,25.5,48.3,25z M40.8,1.7h1.7v2.5h-1.7V1.7z M29.2,1.7h1.7v2.5h-1.7V1.7z M25,4.2h2.5V5c0,0.5,0.4,0.8,0.8,0.8h3.3
                        c0.5,0,0.8-0.4,0.8-0.8V4.2h6.7V5c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V4.2h2.5v3.3H25V4.2z M25,24.2v-15h21.7v15H25z"></path>
                        <path d="M30.8,10.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8V15c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C31.7,11.2,31.3,10.8,30.8,10.8z M30,14.2h-1.7v-1.7H30V14.2z"></path>
                        <path d="M37.5,10.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8V15c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C38.3,11.2,38,10.8,37.5,10.8z M36.7,14.2H35v-1.7h1.7V14.2z"></path>
                        <path d="M44.2,10.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8V15c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C45,11.2,44.6,10.8,44.2,10.8z M43.3,14.2h-1.7v-1.7h1.7V14.2z"></path>
                        <path d="M30.8,17.5h-3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C31.7,17.9,31.3,17.5,30.8,17.5z M30,20.8h-1.7v-1.7H30V20.8z"></path>
                        <path d="M37.5,17.5h-3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C38.3,17.9,38,17.5,37.5,17.5z M36.7,20.8H35v-1.7h1.7V20.8z"></path>
                        <path d="M44.2,17.5h-3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        C45,17.9,44.6,17.5,44.2,17.5z M43.3,20.8h-1.7v-1.7h1.7V20.8z"></path>
                        <path d="M45.5,27.7l-1,1.3l2.3,1.8H33.3v1.7h13.5l-2.3,1.8l1,1.3l4.2-3.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L45.5,27.7z
                        "></path>
                        <rect x="30" y="30.8" width="1.7" height="1.7"></rect>
                        <path d="M15.5,19l4.2-3.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7L15.5,11l-1,1.3l2.3,1.8H3.3v1.7h13.5l-2.3,1.8L15.5,19z"></path>
                        <rect x="0" y="14.2" width="1.7" height="1.7"></rect>
                        <path d="M33.3,44.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V45
                        C34.2,44.5,33.8,44.2,33.3,44.2z M32.5,48.3H30v-2.5h2.5V48.3z"></path>
                        <path d="M40.8,41.7h-4.2c-0.5,0-0.8,0.4-0.8,0.8v6.7c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8v-6.7
                        C41.7,42,41.3,41.7,40.8,41.7z M40,48.3h-2.5v-5H40V48.3z"></path>
                        <path d="M48.3,39.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v9.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V40
                        C49.2,39.5,48.8,39.2,48.3,39.2z M47.5,48.3H45v-7.5h2.5V48.3z"></path>
                     </svg>
               `
            },
            {
               title:"Full-service Development",
               text:"From designing to development and post launch maintenance, we offer full-service for your WordPress requirements.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M47.8,2.7H2.2C1,2.7,0,3.7,0,5v31.3c0,1.2,1,2.2,2.2,2.2h18.5l-0.4,4c0,0.2-0.3,2.3-4.2,2.3c-0.5,0-1,0.4-1,1s0.4,1,1,1
                        h17.7c0.5,0,1-0.4,1-1s-0.4-1-1-1c-3.9,0-4.2-2.1-4.2-2.3l-0.4-4h18.5c1.2,0,2.2-1,2.2-2.2V5C50,3.7,49,2.7,47.8,2.7z M21.3,44.8
                        c0.9-1.1,0.9-2.2,0.9-2.2l0.4-4.2h4.7l0.4,4.1c0,0,0.1,1.1,0.9,2.2H21.3z M48.1,36.2c0,0.2-0.1,0.3-0.3,0.3H2.2
                        c-0.2,0-0.3-0.1-0.3-0.3v-3.5h46.1V36.2z M48.1,30.8H1.9V5c0-0.2,0.1-0.3,0.3-0.3h45.5c0.2,0,0.3,0.1,0.3,0.3V30.8z M32,10.2
                        c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.5,6.9l-6.5,6.9c-0.4,0.4-0.3,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l7.1-7.5
                        c0.4-0.4,0.4-1,0-1.3L32,10.2z M19.3,10.2c-0.4-0.4-1-0.3-1.4,0l-7.1,7.5c-0.4,0.4-0.4,1,0,1.3l7.1,7.5c0.2,0.2,0.4,0.3,0.7,0.3
                        c0.2,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-6.5-6.9l6.5-6.9C19.7,11.2,19.7,10.6,19.3,10.2z M28.2,10.1c-0.5-0.2-1.1,0.1-1.2,0.6
                        l-5.7,14.8c-0.2,0.5,0.1,1.1,0.6,1.2c0.1,0,0.2,0.1,0.3,0.1c0.4,0,0.8-0.2,0.9-0.6l5.7-14.8C28.9,10.8,28.7,10.3,28.2,10.1z"></path>
                     </svg>
               `
            },
            {
               title:"Responsive Design",
               text:"One WordPress website is all you need to serve your content across all formats including web, mobile and hybrid devices.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M48.3,25.4H38.1V12.4c0-1-0.8-1.9-1.9-1.9H5.3c-1,0-1.9,0.8-1.9,1.9v11.6h-2c-0.8,0-1.4,0.6-1.4,1.4v14.5
                        c0,0.8,0.6,1.4,1.4,1.4h7.2c0.8,0,1.4-0.6,1.4-1.4v-5.4h6.7l0,0.7c-0.2,1.8-1.2,2.1-3.2,2.7L12.9,38c-0.5,0.1-1.3,0.4-1.4,1.1
                        c0,0.2-0.1,0.6,0.1,0.8c0.2,0.3,0.6,0.3,0.9,0.3h13.9c0.1,0.7,0.7,1.3,1.4,1.3h20.5c0.8,0,1.4-0.7,1.4-1.4V26.9
                        C49.8,26.1,49.1,25.4,48.3,25.4z M4.4,12.4c0-0.5,0.4-0.9,0.9-0.9h30.9c0.5,0,0.9,0.4,0.9,0.9v13.1h-1.5V14.1c0-0.7-0.6-1.2-1.2-1.2
                        H7.2c-0.7,0-1.2,0.6-1.2,1.2v9.8H4.4L4.4,12.4L4.4,12.4z M9.1,39.9c0,0.3-0.2,0.5-0.5,0.5H1.4c-0.3,0-0.5-0.2-0.5-0.5V25.4
                        c0-0.3,0.2-0.5,0.5-0.5h7.2c0.3,0,0.5,0.2,0.5,0.5L9.1,39.9L9.1,39.9z M26.4,39.3H12.5c0,0-0.1,0-0.1,0c0.1-0.1,0.3-0.2,0.8-0.3
                        l0.6-0.2c1.9-0.5,3.6-1,3.8-3.6v-0.7H23l0,0.9c0.3,2.3,1.9,2.8,3.3,3.2L26.4,39.3L26.4,39.3z M26.4,37.6c-1.4-0.4-2.2-0.9-2.4-2.3
                        v-0.9h2.4V37.6z M26.4,33.5H10.1v-3.7h16.3V33.5z M26.4,26.9v2H10.1v-3.5c0-0.8-0.6-1.4-1.4-1.4H6.9v-9.8c0-0.2,0.1-0.3,0.3-0.3
                        h27.3c0.2,0,0.3,0.1,0.3,0.3v11.3h-6.9C27,25.4,26.4,26.1,26.4,26.9z M48.8,40.1c0,0.3-0.2,0.5-0.5,0.5H27.8c-0.3,0-0.5-0.2-0.5-0.5
                        V26.9c0-0.3,0.2-0.5,0.5-0.5h20.5c0.3,0,0.5,0.2,0.5,0.5C48.8,26.9,48.8,40.1,48.8,40.1z M30.8,39.7h15.8V27.2H30.8V39.7z
                        M31.7,28.2h13.9v10.6H31.7V28.2z M29,32.6c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9
                        C29.9,33,29.5,32.6,29,32.6z M47.8,31.5c-0.2,0-0.4,0.2-0.4,0.4v4c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-4
                        C48.2,31.7,48,31.5,47.8,31.5z M5.1,37.9c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9S6,39.2,6,38.7C6,38.3,5.6,37.9,5.1,37.9z
                        M1.5,36.9h7.1V26.8H1.5V36.9z M2.5,27.8h5.2v8.2H2.5V27.8z M3.4,26.1h3.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H3.4
                        c-0.2,0-0.4,0.2-0.4,0.4C3,25.9,3.2,26.1,3.4,26.1z"></path>
                     </svg>
               `
            },
            {
               title:"Continued Support",
               text:"Once a client, always a client. Genixbit will be by your side to provide continued support after the project launch.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path d="M30.9,26.8l-0.6,0c-0.5-2.7-1.8-5.1-3.6-7c-2.4-2.5-5.7-4-9.3-4s-6.9,1.5-9.3,4c-1.8,1.8-3,4.2-3.6,6.9l-0.8,0
                           c-2,0-3.7,1.6-3.7,3.5V36c0,1.9,1.6,3.5,3.7,3.5H6c0.5,1.4,1.2,2.8,2,4c0.9,1.4,2,2.5,3.2,3.5c0,1.5,1.3,2.7,2.8,2.7h2.9
                           c1.5,0,2.8-1.2,2.8-2.8s-1.2-2.8-2.8-2.8H14c-0.8,0-1.6,0.4-2.1,1c-0.9-0.7-1.7-1.6-2.3-2.6c-2.1-3.2-3.1-7.3-3.4-10.9
                           c1-2.3,3.1-3.3,4.7-3.8c1.9-0.6,3.6-0.6,4.7-0.5c-0.2,0.4-0.3,0.7-0.2,1.1c0.3,0.9,1.4,1.3,3.3,1.3c2.9-0.1,7.3-1.4,9.2-4.5
                           c0.5,1.3,0.8,2.8,0.8,4.3c0,3-0.6,6.8-2,10.2h0.4l0,0h3.8c2,0,3.7-1.6,3.7-3.5v-5.8C34.6,28.4,32.9,26.8,30.9,26.8z M14,46.1h2.9
                           c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H14c-0.5,0-0.9-0.4-0.9-0.9C13.2,46.5,13.5,46.1,14,46.1z M4.4,33.8l0.7,3.9H3.7
                           c-1,0-1.8-0.7-1.8-1.6v-5.8c0-0.9,0.8-1.6,1.8-1.6h0.6c0,0.3,0,0.6,0,0.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3l0,1.4l0,0
                           c0,0,0,0,0,0.1l0,0L4.4,33.8z M23,26.8c-1.9,0.8-3.9,1.1-5,1c1.7-0.5,3.1-1.7,4-3.2c0.6-1,0.9-2.2,0.9-3.4l-1.9,0
                           c0,0.9-0.3,1.7-0.7,2.5c-0.6,1.1-1.6,1.9-2.7,2.3c-0.4-0.3-1-0.4-1.9-0.5c-2.3-0.2-6.8,0.2-9.6,3c0.2-2.8,1.4-5.3,3.3-7.2
                           c2-2.1,4.9-3.4,8-3.4s5.9,1.3,8,3.4c0.5,0.6,1,1.2,1.4,1.8C26.2,25,24.4,26.2,23,26.8z M32.7,36.2c0,0.9-0.8,1.6-1.8,1.6h-1.3
                           c0.6-2.5,1-5.2,1-7.6c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.5c0-0.3,0-0.5,0-0.8h0.4c1,0,1.8,0.7,1.8,1.6V36.2z"></path>
                           <path d="M31.6,0c-8.3,0-15.5,5.7-17.4,13.9l1.9,0.4C17.8,7,24.2,1.9,31.6,1.9c8.8,0,15.9,7.2,15.9,16c0,6.9-4.4,13-10.9,15.2
                           l0.6,1.8c7.3-2.4,12.2-9.3,12.2-17C49.5,8.1,41.5,0,31.6,0z"></path>
                           <polygon points="33.8,19.2 41.2,12.5 39.9,11.1 32.7,17.7 23.8,8.6 22.4,9.9 32.6,20.4   "></polygon>
                        </g>
                     </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
