<template>
	<section class="padding-60 bg-white o-hidden">
		<div class="container">
			<div class="title">
				<span>Mobilise Your Business</span>
				<h2 class="fxt-process-dev">Cross Platform App Development Services</h2>
				<p>
					Our top-notch cross-platform app development services can give you an extra edge in your
					niche market. Our expertise on various hybrid app development frameworks enables us to
					deliver cost-effective and functional cross-platform app solutions that anyone in the
					industry can give you.
				</p>
			</div>
			<div class="icns-containter-fxt">
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M31.2,91.4h8.6c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-8.6c-0.5,0-0.8,0.3-0.8,0.8S30.7,91.4,31.2,91.4z M60.2,91.4h8.6
                        c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-8.6c-0.5,0-0.8,0.3-0.8,0.8S59.7,91.4,60.2,91.4z M54.7,6.2h-9.4
                        c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h9.4c0.5,0,0.8-0.3,0.8-0.8C55.5,6.6,55.2,6.2,54.7,6.2z M69.6,0H30.4
                        c-5.2,0-9.4,4.2-9.4,9.4v81.3c0,5.2,4.2,9.4,9.4,9.4h39.3c5.2,0,9.4-4.2,9.4-9.4V9.4C79.1,4.2,74.8,0,69.6,0z M75.9,90.6
                        c0,3.4-2.8,6.2-6.3,6.2H30.4c-3.5,0-6.3-2.8-6.3-6.2v-6.3h51.8V90.6z M75.9,82.8H24.1V12.5h51.8V82.8z M75.9,10.9H24.1V9.4
                        c0-3.4,2.8-6.2,6.3-6.2h39.3c3.5,0,6.3,2.8,6.3,6.2V10.9z M48.4,93.8h3.1c0.9,0,1.6-0.7,1.6-1.6v-3.1c0-0.9-0.7-1.6-1.6-1.6h-3.1
                        c-0.9,0-1.6,0.7-1.6,1.6v3.1C46.9,93,47.6,93.8,48.4,93.8z M48.4,89.1h3.1v3.1h-3.1V89.1z"
								></path>
							</g>
						</g>
					</svg>
					<p>Android Phone</p>
				</div>
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M73.7,0H26.3c-7,0-12.6,5.7-12.6,12.7v74.7c0,7,5.7,12.7,12.6,12.7h47.4c7,0,12.6-5.7,12.6-12.7V12.7
                        C86.3,5.7,80.6,0,73.7,0z M83,87.3c0,5.2-4.2,9.4-9.4,9.4H26.3c-5.2,0-9.4-4.2-9.4-9.4V12.7c0-5.2,4.2-9.4,9.4-9.4h47.4
                        c5.2,0,9.4,4.2,9.4,9.4V87.3z M21.4,83.9h57.3V13.4H21.4V83.9z M24.6,16.7h50.8v63.9H24.6V16.7z M55.1,86H45
                        c-2.3,0-4.1,1.9-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1h10.1c2.3,0,4.1-1.9,4.1-4.1C59.2,87.8,57.3,86,55.1,86z M55.1,91H45
                        c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h10.1c0.5,0,0.9,0.4,0.9,0.9C55.9,90.6,55.5,91,55.1,91z M32.7,7h-6.8v3.3h6.8V7z"
								></path>
							</g>
						</g>
					</svg>
					<p>Android Tablet</p>
				</div>
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M75.4,17.7C75.4,17.7,75.4,17.7,75.4,17.7C75.4,17.7,75.4,17.7,75.4,17.7V8.6c0,0,0,0,0,0c0-4.8-3.8-8.6-8.4-8.6H33.2
                        c-1.4,0-2.7,0.3-4,0.9c-3,1.4-4.6,4.7-4.6,8v82.4c0,4.7,3.8,8.6,8.3,8.6h31c1.5,0,3.1,0.1,4.6-0.2c4-0.6,6.8-4.5,6.8-8.6v-9.4
                        c0,0,0,0,0,0s0,0,0,0V17.7z M72.4,91.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1
                        c0,0.3-0.1,0.6-0.2,0.8c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.3,0.5-0.4,0.7
                        c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.2,0.2-0.2,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1,0,0
                        c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.3-0.7,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.2,0.1-0.2,0.1c-0.3,0.1-0.5,0.2-0.8,0.2
                        c-0.1,0-0.2,0-0.3,0.1c0.1,0-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0H33c-0.1,0-0.2,0-0.3,0c0,0-0.3,0-0.4,0c-0.3,0-0.5-0.1-0.8-0.2
                        c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.4-0.2-0.7-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2
                        c0.1,0.1,0.1,0.1,0,0c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1-0.1-0.1-0.2c-0.2-0.2-0.3-0.4-0.4-0.7
                        c-0.1-0.1-0.1-0.2-0.2-0.4c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.1-0.1-0.3-0.1-0.4c0,0,0,0,0,0
                        c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6v-8h44.9V91.4z M72.4,80.4H27.6V19.2h44.9V80.4z M72.4,16.2H27.6V9c0-0.3,0-0.7,0-1
                        c0-0.1,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.1-0.6,0.2-0.8c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
                        C28,6.3,28.1,6.2,28.2,6c0.1-0.3,0.3-0.5,0.5-0.8c0-0.1,0.1-0.2,0.1-0.2C28.9,5,29,4.9,29,4.7c0.2-0.2,0.4-0.4,0.6-0.6
                        c0,0,0,0,0,0c0,0,0,0,0.1-0.1C29.9,4,30,3.9,30.1,3.8c0.2-0.2,0.5-0.3,0.8-0.4c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3-0.1,0.4-0.1
                        c0.2-0.1,0.5-0.1,0.7-0.2c0,0,0.3,0,0.3,0c0.1,0,0.3,0,0.4,0h33.9C68.1,3,69,3.2,70,3.9c0.4,0.2,0.5,0.4,1,0.8
                        C71,4.8,71.1,5,71.2,5.1c0,0,0.1,0.1,0.1,0.2c0.1,0.2,0.3,0.4,0.4,0.7C71.9,6.1,72,6.3,72,6.4c0,0,0.1,0.2,0.1,0.3
                        c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.5,0.2,0.8c0,0,0,0.3,0,0.3c0,0.2,0,0.3,0,0.4V16.2z M40.8,8c-0.8,0-1.5,0.7-1.5,1.5
                        c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C42.2,8.7,41.6,8,40.8,8z M53.4,8h-6.9c-0.8,0-1.5,0.7-1.5,1.5
                        c0,0.8,0.7,1.5,1.5,1.5h6.9c0.8,0,1.5-0.7,1.5-1.5C54.9,8.7,54.2,8,53.4,8z M50,95.2c2.7,0.1,4.8-2.3,4.8-5c0-0.1,0-0.2,0-0.3
                        c-0.2-2.6-2.2-4.6-4.8-4.7c-2.7-0.1-4.8,2.3-4.8,5C45.1,92.9,47.4,95.1,50,95.2z M48.1,89.9C48.1,89.9,48.1,89.9,48.1,89.9
                        c0-0.2,0.1-0.4,0.1-0.5c0,0,0.2-0.3,0.2-0.3c0-0.1,0-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0.2-0.2,0.3-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0,0.2,0c-0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0
                        c0.2,0,0.3,0.1,0.4,0.1c0,0,0.3,0.2,0.3,0.2c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.2,0.2,0.2,0.3
                        c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0,0.2c0-0.1,0,0.2,0,0.3c0,0.5-0.2,1-0.5,1.4c0,0-0.2,0.2-0.3,0.2
                        c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0c0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0
                        c-0.2,0-0.3-0.1-0.4-0.1c0,0-0.3-0.2-0.3-0.2c-0.1,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.2-0.2-0.2-0.3
                        c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.3-0.1-0.4c0-0.1,0-0.1,0-0.2c0,0.1,0-0.2,0-0.3C48.1,90.1,48.1,90,48.1,89.9z"
								></path>
							</g>
						</g>
					</svg>
					<p>Iphone</p>
				</div>
				<div class="icn-containter-fxt">
					<svg
						version="1.1"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<g>
							<g>
								<path
									d="M86.5,5.3c0-2.9-2.4-5.3-5.3-5.3H18.9c-2.9,0-5.3,2.4-5.3,5.3v89.5c0,2.9,2.4,5.3,5.3,5.3h62.3c2.9,0,5.3-2.4,5.3-5.3
                        L86.5,5.3z M16.7,5.3c0-1.2,1-2.2,2.2-2.2h62.3c1.2,0,2.2,1,2.2,2.2l0,74.8H16.7V5.3z M81.2,96.9H18.9c-1.2,0-2.2-1-2.2-2.2V83.2
                        h66.7l0,11.5C83.3,95.9,82.4,96.9,81.2,96.9z M50,86.8c-2.3,0-4.1,1.8-4.1,4.1c0,2.3,1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1
                        C54.1,88.6,52.3,86.8,50,86.8z M50,91.8c-0.5,0-1-0.4-1-1s0.4-1,1-1c0.5,0,1,0.4,1,1S50.5,91.8,50,91.8z"
								></path>
							</g>
						</g>
					</svg>
					<p>Ipad</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.icns-containter-fxt {
	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
}

.icn-containter-fxt {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	height: 160px;
	width: fit-content;
}
.icn-containter-fxt svg {
	width: 100px;
	height: 100px;
	transition: 0.2s;
	fill: #6a6d6f;
}
.icn-containter-fxt p {
	margin: 0;
	padding: 10px 0 0 0;
	text-align: center;
	transition: 0.2s;
	color: black;
	font-size: 18px;
	font-weight: 600;
}
.icn-containter-fxt:hover svg path {
	fill: #008dd2;
}
.icn-containter-fxt:hover p {
	color: #008dd2;
}
@media (max-width: 991px) {
	.process-items::before {
		display: none;
	}
	.process-items i {
		top: unset !important;
		bottom: -30px !important;
		left: 50% !important;
	}
	.process-items i.goDown {
		transform: translateX(-50%) rotate(90deg);
	}
	.process-items i.goUp {
		top: -30px !important;
		bottom: unset !important;
		transform: translateX(-50%) rotate(-90deg);
	}
}
@media (max-width: 576px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.icns-containter-fxt {
		flex-wrap: wrap;
	}
	.icn-containter-fxt {
		margin: 0;
		padding: 10px 0 !important;
		box-shadow: none !important;
		height: 150px;
		width: 150px;
	}
}
</style>
