<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/angularjs-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						The Bespoke AngularJS development company
					</h2>
					<p>
						At GenixBit, our Angular JS Web Development solutions demonstrate our abilities. Applications built from open source web development frameworks may sometimes appear difficult to compare, if the applications only scratch the surface of the powerful possibilities. This is where GenixBit helps clients to fully leverage the benefits of the flexible features of the versatile front end framework. With hundreds of hours of expertise on complex projects, our AngularJS Developers surpass expectations with superior deliverables.
					</p>
					<p>
						Our experience in the Angular JS Development framework and the processes we follow help to create superior applications that are not just developed but tested rigorously through MVC and MVVM architectures. This gives us the ability to deliver applications that will function smoothly, free from error and in a manner that fully meets business objectives.
					</p>
					<p>
						Our technological prowess and domain expertise equip us with the perspective that is necessary to understand the need for getting the technical and technological aspects aligned with the business needs of the organization. Our design and development team have core expertise in Object Oriented Programming and this expertise shows in our Angular JS Web Development applications.
					</p>
					<p>
						If you have questions related to your requirements or AngularJS framework. Get in touch with us now, our expert AngularJS Developers will be happy to help you.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
