<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/uiux-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">A New-Age UX/UI Design Solutions</h2>
					<p>
						User interaction drives conversion and conversion drives your revenue. We understand
						your product and try putting in our perspective to it that blends the latest technology,
						design, and usability. Offering minimalist UI/UX solutions that match trends of today
						and technology of tomorrow. Creating a design that improves app stickiness and user
						retention is the recipe of successful user experience.
					</p>
					<p>
						We keep our design sleek, bare minimum and try topping it up with vital brand elements.
						Following a user-centric approach to design, the design decisions are guided by how user
						interact with your app or website.
					</p>
					<p>
						Our design fundamentals for creating the best minimal UI design lies in wireframing a
						screen-by-screen blueprint. The minimalism in wireframe display the holistic product
						picture equipped with all the features and functional elements that the user interacts
						with. Our UI/UX relies on keeping the interface self-explanatory overcoming the
						technical sophistication for ease of use.
					</p>
					<p>
						The design philosophy of any UI UX design company relies on creating a prototype-based
						interface that lifts up the brand persona. Our UI designers play a key role to style the
						product right from font colours, design theme and packaging it as per the latest design
						convention to keep it sleek, stylish and yet engaging.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
