<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/mac-game-development-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						A Reputed Mac Game Development Company, UK
					</h2>
					<p>
						GenixBit Labs offers game solutions for projects of all genre and industry, including
						tailored mobile or desktop platforms, delivering to Client’s based in UK and globally.
					</p>
					<p>
						With the high end inherent graphical user-interface of the MacBook and iMac, the number
						of Apple computer owners is growing, and so is the demand for Mac game development. Our
						prolonged exposures to Mac game development established us as a leading Mac game
						development company with a substantial portfolio.
					</p>
					<p>
						Being an acclaimed game development company, our proficiencies in Unity 3D game engines
						has enabled us to deliver stunning graphics and animations for immersive experiences in
						our Mac games. We produce a wide range of desktop based games through our Mac game
						development services by implementing an array of core technologies, which includes the
						Unity 2D/3D game development platform.
					</p>
					<p>
						If you have a query or want to discuss a Mac based game project, then please don’t
						hesitate in getting in touch with us or read on.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
