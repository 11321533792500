<template>
	<section class="bg-white" style="padding-bottom: 60px; min-height: fit-content">
		<div class="technologies_tabs fxt-technologies-tabs" style="position: relative">
			<ul class="nav nav-tabs portfolio container" id="technologies_tabs" role="tablist">
				<li>
					<a
						class="active"
						id="mobile-technologies-tab"
						data-toggle="tab"
						href="#mobile-technologies"
						role="tab"
						aria-controls="mobile-technologies"
						aria-selected="true"
					>
						<div class="portfolio-listing-box current" style="border-radius: 10px 0 0 10px">
							<div class="portfolio-listing-icon" data-filter="*" data-value="*">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M60,37.2H38.9c-1.2,0-2.1,1-2.1,2.1v21.3c0,1.2,0.9,2.1,2.1,2.1H60c1.2,0,2.1-1,2.1-2.1V39.4C62.1,38.2,61.2,37.2,60,37.2
                           z M57.9,58.5H41.1v-17h16.8V58.5z M34.2,0H2.6C1.5,0,0,1,0,2.1V34c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1V7.2l26.1,26.2
                           c0.4,0.4,1.1,0.6,1.6,0.6c0.5,0,1.2-0.2,1.6-0.6c0.8-0.9,0.8-2.1,0-3L7.7,4.3h26.5c1.2,0,2.1-1,2.1-2.1C36.3,1,35.4,0,34.2,0z
                           M92.3,4.3L66.4,30.4c-0.8,0.9-0.8,2.1,0,3c0.4,0.4,0.9,0.6,1.5,0.6c0.5,0,0.8-0.2,1.3-0.6L94.7,7.2V34c0,1.2,0.9,2.1,2.1,2.1
                           c1.2,0,2.1-1,2.1-2.1V2.1C98.9,1,98.5,0,97.4,0H65.8c-1.2,0-2.1,1-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1H92.3z M97.9,63.8
                           c-1.2,0-2.1,1-2.1,2.1v27.3L69.4,66.6c-0.8-0.9-2.1-0.9-2.9,0c-0.8,0.9-0.8,2.1,0,3l25.9,26.2H66.8c-1.2,0-2.1,1-2.1,2.1
                           c0,1.2,0.9,2.1,2.1,2.1h31.1c1.2,0,2.1-1,2.1-2.1V66C100,64.8,99.1,63.8,97.9,63.8z M34.2,95.7H7.7l25.9-26.2c0.8-0.9,0.8-2.1,0-3
                           c-0.8-0.9-2.4-0.9-3.3,0L4.2,92.8V66c0-1.2-0.9-2.1-2.1-2.1c-1.2,0-2.1,1-2.1,2.1v31.9C0,99,1.5,100,2.6,100h31.6
                           c1.2,0,2.1-1,2.1-2.1C36.3,96.7,35.4,95.7,34.2,95.7z"
											></path>
										</g>
									</g>
								</svg>
								<span>All</span>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a
						id="web-technologies-tab"
						data-toggle="tab"
						href="#web-technologies"
						role="tab"
						aria-controls="web-technologies"
						aria-selected="true"
					>
						<div class="portfolio-listing-box">
							<div class="portfolio-listing-icon" data-filter=".mobapp" data-value="mobapp">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										d="M64.1,62.7c-0.6-0.6-1.6-0.6-2.2,0.1l-7,7.4c-0.6,0.6-0.6,1.5,0,2.1l7,7.4c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.1,1.1-0.4
                     c0.6-0.6,0.7-1.6,0.1-2.2l-6-6.3l6-6.3C64.7,64.2,64.7,63.2,64.1,62.7z M83.6,70.1l-7-7.4c-0.6-0.6-1.6-0.6-2.2-0.1
                     c-0.6,0.6-0.7,1.6-0.1,2.2l6,6.3l-6,6.3c-0.6,0.6-0.6,1.6,0.1,2.2c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.2,1.1-0.5l7-7.4
                     C84.1,71.6,84.1,70.7,83.6,70.1z M71.4,62.4c-0.8-0.2-1.7,0.3-1.9,1.1l-3.5,14.6c-0.2,0.8,0.3,1.7,1.1,1.9c0.1,0,0.2,0,0.4,0
                     c0.7,0,1.3-0.5,1.5-1.2l3.5-14.6C72.7,63.4,72.2,62.6,71.4,62.4z M69.2,49.6c-1.2,0-2.3,0.1-3.5,0.3V30.7c1.4,0,2.6-1.2,2.6-2.6
                     v-6.8c0-1.4-1.2-2.6-2.6-2.6V7.7C65.8,3.5,62.3,0,58,0H16.9c-4.3,0-7.8,3.5-7.8,7.7v84.6c0,4.3,3.5,7.7,7.8,7.7H58
                     c4.2,0,7.7-3.4,7.7-7.6c1.1,0.2,2.3,0.3,3.5,0.3c11.9,0,21.6-9.7,21.6-21.5C90.9,59.3,81.2,49.6,69.2,49.6z M62.7,92.3
                     c0,2.6-2.1,4.6-4.7,4.6H16.9c-2.6,0-4.7-2.1-4.7-4.6V16.9h42.4c0.9,0,1.6-0.7,1.6-1.5c0-0.9-0.7-1.5-1.6-1.5H12.2V7.7
                     c0-2.6,2.1-4.6,4.7-4.6H58c2.6,0,4.7,2.1,4.7,4.6v42.9c-8.7,2.8-15.1,10.9-15.1,20.5c0,9.6,6.3,17.8,15.1,20.5V92.3z M69.2,89.6
                     c-10.2,0-18.5-8.3-18.5-18.5c0-10.2,8.3-18.5,18.5-18.5c10.2,0,18.5,8.3,18.5,18.5C87.8,81.3,79.4,89.6,69.2,89.6z M38.7,82.6
                     c-3.3,0-5.9,2.7-5.9,5.9c0,3.3,2.7,5.9,5.9,5.9c3.3,0,5.9-2.7,5.9-5.9C44.7,85.3,42,82.6,38.7,82.6z M38.7,91.4
                     c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8C41.6,90.1,40.3,91.4,38.7,91.4z M47.7,6
                     c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C50.1,7,49,6,47.7,6z M47.7,9C47.3,9,47,8.7,47,8.3
                     c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C48.4,8.7,48.1,9,47.7,9z"
									></path>
								</svg>
								<span>Mobile App</span>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a
						id="cloud-tab"
						data-toggle="tab"
						href="#cloud"
						role="tab"
						aria-controls="cloud"
						aria-selected="true"
					>
						<div class="portfolio-listing-box">
							<div class="portfolio-listing-icon" data-filter=".arvrapp" data-value="arvrapp">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M50,36.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7C48.3,35.9,49.1,36.7,50,36.7z
                           M50,30c0.9,0,1.7-0.7,1.7-1.7s-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7S49.1,30,50,30z M36.7,28.3H30c-1.8,0-3.3,1.5-3.3,3.3v6.7
                           c0,1.8,1.5,3.3,3.3,3.3h6.7c1.8,0,3.3-1.5,3.3-3.3v-6.7C40,29.8,38.5,28.3,36.7,28.3z M30,38.3v-6.7h6.7l0,6.7H30z M63.3,41.7H70
                           c1.8,0,3.3-1.5,3.3-3.3v-6.7c0-1.8-1.5-3.3-3.3-3.3h-6.7c-1.8,0-3.3,1.5-3.3,3.3v6.7C60,40.2,61.5,41.7,63.3,41.7z M63.3,31.7H70
                           l0,6.7h-6.7V31.7z M95,81.3c-7.8-4.1-21.2-8.7-30-10.6c6.6-5.7,10.9-14.7,11.6-23.2c2.1-1.1,3.5-3.3,3.5-5.8V28.3
                           c0-3-2-5.6-4.8-6.4C71.8,10.9,62.2,0,50,0C37.8,0,28.2,10.9,24.8,21.9C22,22.7,20,25.3,20,28.3v13.3c0,2.5,1.4,4.7,3.5,5.8
                           c0.7,8.5,5,17.5,11.6,23.2c-8.9,1.9-22.2,6.5-30,10.6c-3.1,1.6-5,4.8-5,8.2v8.8c0,0.9,0.7,1.7,1.7,1.7h96.7c0.9,0,1.7-0.7,1.7-1.7
                           v-8.8C100,86.1,98.1,82.9,95,81.3z M50,3.3c10.2,0,18.2,8.9,21.6,18.3H28.4C31.8,12.2,39.8,3.3,50,3.3z M23.3,41.7V28.3
                           c0-1.7,1.3-3.1,2.9-3.3c0,0,0.1,0,0.1,0c0,0,0.3,0,0.3,0h46.6c0.1,0,0.1,0,0.2,0c0,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0h0
                           c1.6,0.3,2.8,1.6,2.8,3.3v13.3c0,1.4-0.9,2.6-2.2,3.1c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.6,0.2-1,0.2H59.3c-0.8,0-1.4-0.5-1.6-1.3
                           l-0.4-1.6c-0.6-2.2-2.6-3.8-4.8-3.8h-4.8c-2.3,0-4.3,1.6-4.8,3.8l-0.4,1.6c-0.2,0.7-0.9,1.3-1.6,1.3H26.7
                           C24.8,45,23.3,43.5,23.3,41.7z M26.9,48.3h13.8c2.3,0,4.3-1.6,4.8-3.8l0.4-1.6c0.2-0.7,0.9-1.3,1.6-1.3h4.8c0.8,0,1.4,0.5,1.6,1.3
                           l0.4,1.6c0.6,2.2,2.6,3.8,4.8,3.8h13.8c-1,8.5-5.9,17.4-12.9,21.9c0,0-0.1,0-0.1,0.1l-1.1,0.6L58.8,71l-0.3,0.1
                           c-0.1,0-0.1,0.1-0.1,0.1c-2.6,1.3-5.4,2.1-8.4,2.1c-3,0-5.8-0.8-8.4-2.1c0,0-0.1-0.1-0.1-0.1L40,70.3c0,0-0.1,0-0.1-0.1
                           C32.8,65.7,27.9,56.9,26.9,48.3z M54.6,76.1l-4,5.3c-0.2,0.3-0.5,0.3-0.6,0.3c-0.1,0-0.4,0-0.6-0.3l-4-5.3c1.5,0.3,3,0.5,4.6,0.5
                           C51.6,76.7,53.1,76.5,54.6,76.1z M76.7,96.7h-10v-3.3h10V96.7z M96.7,96.7H80v-5c0-0.9-0.7-1.7-1.7-1.7H65c-0.9,0-1.7,0.7-1.7,1.7
                           v5h-60v-7.1c0-2.2,1.2-4.2,3.2-5.3c8.6-4.5,24-9.7,32.2-10.8l0.8,0.5l7.2,9.5c0.8,1,2,1.6,3.3,1.6c1.3,0,2.5-0.6,3.3-1.6l7.2-9.5
                           l0.1,0c0,0,0.1-0.1,0.1-0.1l0.6-0.3c8.2,1.2,23.6,6.3,32.2,10.8c2,1,3.2,3.1,3.2,5.3V96.7z"
											></path>
										</g>
									</g>
								</svg>
								<span>AR / VR App</span>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a
						id="database-tab"
						data-toggle="tab"
						href="#database"
						role="tab"
						aria-controls="database"
						aria-selected="true"
					>
						<div class="portfolio-listing-box">
							<div class="portfolio-listing-icon" data-filter=".gameapp" data-value="gameapp">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										d="M81.1,64.9c-1-0.4-1.9-0.6-2.7-0.6c-1.1,0-1.8,0.3-2.2,0.5l-4.5-1.8c-0.3-0.8-0.9-2.3-3.6-3.4c-0.8-0.3-1.6-0.5-2.4-0.5
                     c-2.2,0-4.1,1.1-5.3,2.8l-4.9,5.9c-1.5,1.9-3.4,4.6-1.8,6.8c0.7,1,1.8,1.5,3.2,1.5c0.9,0,1.9-0.2,3.2-0.8c2.1-1,3.4-1.6,4.2-2
                     c1.3,0.6,2.9,0.4,4.1-0.5l3.3,1.4c0.2,1.4,1.1,2.7,2.6,3.2c0.3,0.9,0.8,2.3,1.6,4.4c1,2.6,2.5,3.9,4.4,3.9c0.2,0,0.4,0,0.6-0.1
                     c2.7-0.4,3.3-3.7,3.5-6.1L85,72C85.5,69,83.9,66,81.1,64.9z M81.9,71.5c0,0.1,0,0.1,0,0.2l-0.7,7.7c-0.3,2.9-0.9,3.3-0.9,3.3
                     c0,0-0.1,0-0.1,0c-0.5,0-1-0.7-1.5-1.9c0,0-1.8-4.8-1.9-5.1c-0.2-0.5-0.7-0.9-1.2-1c-0.9-0.1-0.9-0.9-0.9-1.4c0-0.7-0.3-1.3-1-1.5
                     l-5.1-2.1c-0.6-0.3-1.3-0.1-1.8,0.4c-0.2,0.3-0.6,0.6-1,0.6c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-1-0.4-1.4-0.2c-0.1,0-0.1,0-0.2,0.1
                     c-0.3,0.1-4.9,2.3-4.9,2.3c-1,0.4-1.5,0.5-1.9,0.5c-0.4,0-0.5-0.1-0.7-0.3c0,0-0.1-0.8,1.7-3l5-6c0,0,0.1-0.1,0.1-0.1
                     c0.9-1.3,2.6-1.9,4.1-1.3c1.4,0.6,1.7,1.1,1.9,1.7c0.2,0.5,0.5,1.2,1.4,1.6l5.1,2.1c0.3,0.1,0.5,0.2,0.8,0.2c0.5,0,0.9-0.2,1.3-0.3
                     c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.9,0.1,1.5,0.4C81.4,68.3,82.2,69.9,81.9,71.5z M80.2,70.4c0.2-0.8-0.1-1.6-0.9-1.9
                     c-0.7-0.3-1.6,0-1.9,0.7c-0.8-0.2-1.6,0.1-1.9,0.9c-0.3,0.7,0,1.6,0.7,1.9c-0.2,0.8,0.1,1.6,0.9,1.9c0.2,0.1,0.4,0.1,0.6,0.1
                     c0.6,0,1.1-0.3,1.4-0.8c0.2,0,0.3,0.1,0.5,0.1c0.6,0,1.2-0.4,1.4-0.9C81.2,71.6,80.9,70.7,80.2,70.4z M67.8,65.7l-0.2-0.1l0.1-0.2
                     c0.3-0.8-0.1-1.7-0.8-2c-0.8-0.3-1.7,0.1-2,0.8l-0.1,0.2l-0.2-0.1c-0.8-0.3-1.7,0.1-2,0.8c-0.3,0.8,0.1,1.7,0.8,2l0.2,0.1l-0.1,0.2
                     c-0.3,0.8,0.1,1.7,0.8,2c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1.2-0.4,1.4-1l0.1-0.2l0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.6,0,1.2-0.4,1.4-1
                     C69,66.9,68.6,66,67.8,65.7z M69.9,83.1c-0.9,0-1.5,0.7-1.5,1.5v7.7c0,2.6-2.1,4.6-4.6,4.6H22.7c-2.6,0-4.6-2.1-4.6-4.6V7.7
                     c0-2.6,2.1-4.6,4.6-4.6h41.1c2.6,0,4.6,2.1,4.6,4.6v41.7c0,0.9,0.7,1.5,1.5,1.5c0.9,0,1.5-0.7,1.5-1.5V28.6c1.2-0.2,2.1-1.3,2.1-2.5
                     v-6.8c0-1.2-0.9-2.3-2.1-2.5V7.7C71.5,3.5,68,0,63.7,0H22.7c-4.3,0-7.7,3.5-7.7,7.7v84.6c0,4.3,3.5,7.7,7.7,7.7h41.1
                     c4.3,0,7.7-3.5,7.7-7.7v-7.7C71.5,83.8,70.8,83.1,69.9,83.1z M50.9,7.9c0.9,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H35.5
                     c-0.9,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H50.9z M38.9,91.6c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3
                     C40.8,87.3,38.9,89.2,38.9,91.6z M44.4,91.6c0,0.7-0.5,1.2-1.2,1.2S42,92.2,42,91.6c0-0.7,0.5-1.2,1.2-1.2S44.4,90.9,44.4,91.6z
                     M61.7,51.2c0.9,0,1.7-0.8,1.7-1.7v-28c0-3.3-2.7-5.9-5.9-5.9h-28c-3.3,0-5.9,2.7-5.9,5.9v57.6c0,3.3,2.7,5.9,5.9,5.9h20.3
                     c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7H29.5c-1.4,0-2.5-1.1-2.5-2.5V21.4c0-1.4,1.1-2.5,2.5-2.5h28c1.4,0,2.5,1.1,2.5,2.5v28
                     C60,50.4,60.7,51.2,61.7,51.2z"
									></path>
								</svg>
								<span>Games</span>
							</div>
						</div>
					</a>
				</li>
				<li>
					<a
						id="design-tab"
						data-toggle="tab"
						href="#design"
						role="tab"
						aria-controls="design"
						aria-selected="true"
					>
						<div class="portfolio-listing-box" style="border-radius: 0 10px 10px 0">
							<div class="portfolio-listing-icon" data-filter=".websiteapp" data-value="websiteapp">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M97.4,76.9h-0.7V19.8c0-3.7-3-6.7-6.7-6.7H10c-3.7,0-6.7,3-6.7,6.7v57.1H2.6c-1.4,0-2.6,1.2-2.6,2.7v4.8
                           C0,85.8,1.2,87,2.6,87h94.7c1.5,0,2.6-1.2,2.6-2.7v-4.8C100,78.1,98.8,76.9,97.4,76.9z M6.7,19.8c0-1.9,1.5-3.4,3.3-3.4h80
                           c1.8,0,3.3,1.5,3.3,3.4v57.1H58.7L58.3,78c-0.1,0.3-0.4,0.6-0.8,0.6H44.2c-0.4,0-0.7-0.3-0.8-0.6L43,76.9H6.7V19.8z M96.7,83.6
                           H3.3v-3.4h37.5c0.8,1,2,1.7,3.3,1.7h13.3c1.3,0,2.5-0.6,3.3-1.7h35.8V83.6z M90,71.8V21.4c0-0.9-0.7-1.7-1.7-1.7H11.7
                           c-0.9,0-1.7,0.8-1.7,1.7v50.4c0,0.9,0.7,1.7,1.7,1.7h76.7C89.3,73.5,90,72.8,90,71.8z M86.7,70.2H13.3v-47h73.3V70.2z M40,56.7
                           c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7C38.3,56,39.1,56.7,40,56.7z M32,56.1
                           c0.3,0.4,0.8,0.6,1.3,0.6c0.4,0,0.7-0.1,1-0.4c0.7-0.6,0.8-1.6,0.3-2.4l-5.8-7.3l5.8-7.4c0.6-0.7,0.5-1.8-0.3-2.4
                           c-0.7-0.6-1.8-0.5-2.3,0.3l-6.7,8.4c-0.5,0.6-0.5,1.5,0,2.1L32,56.1z M65.6,56.4c0.3,0.3,0.7,0.4,1,0.4c0.5,0,1-0.2,1.3-0.6
                           l6.7-8.4c0.5-0.6,0.5-1.5,0-2.1L68,37.2c-0.6-0.7-1.6-0.8-2.3-0.3c-0.7,0.6-0.8,1.6-0.3,2.4l5.8,7.4L65.4,54
                           C64.8,54.7,64.9,55.8,65.6,56.4z M40,50c0.9,0,1.7-0.8,1.7-1.7V38.2c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7v10.1
                           C38.3,49.3,39.1,50,40,50z M46.7,56.7H50c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7h-3.3c-0.9,0-1.7,0.8-1.7,1.7
                           C45,56,45.7,56.7,46.7,56.7z M56.7,56.7H60c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7h-3.3c-0.9,0-1.7,0.8-1.7,1.7
                           C55,56,55.7,56.7,56.7,56.7z"
											></path>
										</g>
									</g>
								</svg>
								<span>Website</span>
							</div>
						</div>
					</a>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade show active fxt-tab-pane"
					id="mobile-technologies"
					role="tabpanel"
					aria-labelledby="mobile-technologies-tab"
					style="min-height: 1500vh"
				>
					<section
						class="section portfolio-preview-box arvrapp bluebg"
						data-hash="virtual-reality-app"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/microsoft-art-of-work-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 0px;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/microsoft-art-of-work-logo.png"
													style=""
												/>
											</div>
											<h3>A 360-Degree Virtual Reality App for Microsoft</h3>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Microsoft wanted to build an app for artists to share their creative work
												with the world at large. The technology for the idea was introduced through
												a SAP and Microsoft Azure partnership. GenixBit Labs capitalized on the
												possibilities of the technology and built a virtual reality app with the
												capabilities of Unity 3D. The app experience was also enriched with a
												virtual reality 360-Degree experience.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.microsoft.com/en-in/p/art-of-work-by-microsoft/9p0ftv7qv4kv?activetab=pivot%3Aoverviewtab"
													rel="noreferrer"
													target="_blank"
													class="btn-custome btn waves-effect waves-light"
													>View Website</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="../../assets/images/microsoft-art-of-work-laptop.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp pinkbg claireabella"
						data-hash="claireabella"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/claireabella-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="127"
													height="129"
													src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/claireabella-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Claireabella</span>
													<span class="moreaboutapp">A Personalized Emoji Building App</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Emojis have become a critical component of modern day communication. They
												help emote our feelings better and in a vivid way than with text.
												Clairebella approached GenixBit Labs with the task of building an app that will
												allow users to create their own emojis. The emojis were to look and resemble
												UL’s popular cartoon character Clairebella. The app has enriched features
												that allows users to customize every single facial and physical aspect of
												the emoji like skin tone, hair, eye colours, expressions body, outfit among
												many others. The emoji can be used on device keyboard, social media and also
												order custom printed products from Toxicfox — a personalised gift online
												portal.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/claireabella/id1291769080"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/claireabella-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp orangebg awayback"
						data-hash="awayback"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/awayback-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 200vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/awayback-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Awayback</span>
													<span class="moreaboutapp">An AR App for a Trip down Memory Lane</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development </li>
											</ul>
											<p>
												Awayback is a location-based Augmented Reality app that allows users to
												create, store, share and also rediscover their memories. The app works by
												allowing the user to create a memory at a given location. The memory can be
												shared with friends who use the app who can rediscover when they visit the
												same place. Being an AR app, the content created was not limited to images
												but also videos. GenixBit Labs made this app happen by leveraging Wikitude and
												ARkit Augmented Reality SDK. The app was released worldwide for Android and
												iOS devices.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/awayback-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp arvrapp mowmowbg mowmow"
						data-hash="mowmow"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/mowmow-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 300vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="133"
													height="136"
													src="../../assets/images/mowmow-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Mow Mow</span>
													<span class="moreaboutapp"
														>A Hide and Find Game with a Fitness Twist</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Mow mow is an Augmented reality mobile app that is aimed to make users to
												walk more and also have fun in the process. It works by allowing users to
												click a rock, called MowMow or select one the gallery. Highlight features of
												the app include the feature to add friends, and MoBucks — a rewarding point
												system for hiding or finding MowMows. Users can also add custom animated
												digi-stickers and tag friends, use hashtags to hide a MowMow. Tagged friends
												can walk around, find MowMows and share their finds on newsfeed. Every step
												that they take in the process is recorded with a pedometer, just like a
												fitness wearable. MowMow is developed using ARcore and ARkit for Android and
												iOS devices respectively.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/mowmow/id1465226420"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/mowmow-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp formassistbg"
						data-hash="form-assist"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/form-assist-background.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 400vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy landscape"
													alt=""
													src="../../assets/images/form-assist-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">FormAssist</span>
													<span class="moreaboutapp"
														>A Fitness App with Personal Guide to Keep in Shape</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												From the out-of-shape to ripped model, an app that helps users get fit. Form
												Assist is a fitness training mobile app that will train users on what to
												eat, how to work out and how to become the best selves that they want to be.
												The app is integrated with features like recipe finding, workout planning,
												chat with dietitians and trainers, goal setting and activity tracking and
												much more. On the timeline, users can track all the activities such as terms
												of diet, exercise, weight, walking step.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/tr/app/formassist-diet-and-exercise/id1345964523"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/form-assist-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp fantasybg"
						data-hash="fantasyji"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/fantasyji-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 500vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/fantasyji-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Fantasyji</span>
													<span class="moreaboutapp"
														>A Fantasy Game App Designed for Cricket Fanatics</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Cricket is a 11-player game. But, who said one player cannot play them all?
												Fantasyji is a fantasy sports app that allows users to play a fantasy game
												of cricket complete with a star-decked team, matches, tournaments and
												leagues. Users can form their own teams through auctioning, create their own
												private leagues, play according to real-time performance of players and
												accumulate rewards for their match wins.
											</p>
											<div class="button-wrap">
												<a
													href="https://s3.ap-south-1.amazonaws.com/devfantasyji/android_apk/v_3_1_1/fantasyji_3_1_1.apk"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/in/app/fantasyji/id1453701051"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/fantasyji-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp appai appaibg"
						data-hash="appai"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/appai-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 600vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="126"
													height="128"
													src="../../assets/images/appai-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Search by Shape AI</span>
													<span class="moreaboutapp"
														>An Augmented Reality and 3D-Based Object Detection App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Search by Shape AI is a mobile app that is powered by Augmented Reality and
												3D modeling. It works by allowing users to click real-time locations, upload
												them to the app and to detect objects. The 3D models of detected objects can
												then be placed on other real-time environments with the help of Augmented
												Reality. GenixBit Labs’s expertise in building immersive reality apps using
												ARKit for iOS devices made this idea become a reality.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/appai-device-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp adanibg"
						data-hash="adani-saksham"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/adani-bg.png');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 700vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/adani-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Adani Saksham</span>
													<span class="moreaboutapp"
														>A Comprehensive Mobile App for Adani Group’s Skill Development
														Programs</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												The Adani Foundation of Skill Development for the Youth of India that had
												partnered with Government of India's Skill India Mission needed a
												resourceful mobile application. Thus was created Adani Saksham, a
												comprehensive mobile application that provides all skill development program
												information like types of courses, location and centres. To further
												disseminate information, the mobile app was released on Android and iOS
												platforms along with a full-fledged website.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/am/app/adani-saksham/id1457556359"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/adani-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp doreltaxibg"
						data-hash="dorel-taxi"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/dorel-taxi-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 800vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/dorel-taxi-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Dorel Taxi</span>
													<span class="moreaboutapp"
														>An On-demand Taxi Hailing App for Nigeria</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Dorel is the UBER for Nigeria. Intelivita helped build Dorel to bring the
												convenience of on-demand taxi hailing to the people of Nigeria. Its
												uniqueness lies in the long list of features for drivers, passengers and
												admins alike. To make the app experience seamless for passengers, a built-in
												support system was also embedded. The admin interface was also equipped with
												a neat interface that allows quick information access, delegation of rides
												and providing real-time support to discerning passengers.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Dorel Taxi"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Dorel Driver"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/dorel-taxi-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp yoca lightgreenbg"
						data-hash="yoca"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/yoca-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 900vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/yoca-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Yoca</span>
													<span class="moreaboutapp">The Uber for Coffee-ordering</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Coffee gives an instant jolt of energy. But, ordering it does not always
												happen instantly. Yoca helps get customers get their cup of coffee in
												quicktime. It is like an UBER for coffee-ordering. Users can browse coffee
												menus, order coffees from their favorite cafes, pay for it through the app,
												set up pickup time or even collect it on the go. Coffee shop owners can use
												an order management system to keep their deliveries organized.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/yoca-device-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp squshybg"
						data-hash="squshy"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/squshy-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1000vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy landscape"
													alt=""
													src="../../assets/images/squshy-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Squishysaurus</span>
													<span class="moreaboutapp"
														>Bundle of Interactive, Fun, Educational Augmented App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Squishy Saurus is an augmented reality based fun and educational app that
												lets children to scan a QR code and interact with characters through actions
												buttons or voice commands. The app has three central characters Emma,
												Madison and Alifie. The characters can be made to read through voice
												commands. The app also has provision for in-app purchases of toys scanned
												with QR code. Unity 3D and Augmentation are the core technologies that power
												this app.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/squishy-ar/id1463014175"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main landscapescreen">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/squshy-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box gameapp mobapp cluedbg"
						data-hash="clued-app"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/clued-app-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/clued-app-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Clued App</span>
													<span class="moreaboutapp">The Crime-solving Adventure Game</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												CluedUpp is an award-winning crime-solving adventure game app played by over
												250,000 budding detectives. CluedUpp host murder-mystery manhunt events all
												across the globe, allowing the team of detectives to put their sleuthing
												skills to the test. The crime-solving teams hunt all over their chosen city,
												finding virtual witnesses, eliminating suspects and bringing the killer to
												justice. The game was developed for both Android and iOS. The app has won
												multiple awards by the UK's top publications such as BBC, Nottingham Post,
												The Sun, Daily Mail, Manchester Evening News.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/gb/app/cluedupp-detective/id1360283188"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/clued-app-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box gameapp blogasfriendbg"
						data-hash="blogb-as-friend"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/blog-as-a-friend.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1200vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/blob-as-friend-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Blob &amp; Friends: coloring book</span>
													<span class="moreaboutapp">Fun Game for All Kids</span>
												</div>
											</div>
											<h3></h3>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Coloring book is a fun game for all kids. Play Learn Snap, simple colour
												filling mechanics by choosing the character in the game. This game is
												developed in the Unity game engine for Android and iOS devices. The game
												includes Multiple categories- “ANIMALS, BIRDS, FASHION, NATURE”, Multiple
												FREE, UNIQUE and APPEALING sketches, including dog, bear, cat, makeup,
												dress. Unlimited create, undo and save feature.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/blob-as-friend-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box websiteapp newheavencafe purpalbg"
						data-hash="new-heaven-cafe"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/new-heaven-cafe.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1300vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/new-heaven-cafe-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">New Haven Cafe</span>
													<span class="moreaboutapp"
														>A Mobile App for a Popular Eastery Based out of Timperley</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Finding a good eatery that serves a delectable range of eateries throughout
												the year is difficult. The New Haven Cafe at Timperley had achieved this
												rare feat. The cafe wanted an intuitive website of their own that will let
												their customers, both loyal and new ones, find everything from menu to
												working hours. The website was also designed in such a way that users can
												find the restaurant when they run an online search.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.newhavencafetimperley.co.uk/"
													rel="noreferrer"
													target="_blank"
													class="btn-custome btn waves-effect waves-light"
													>View Website</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="../../assets/images/new-heaven-cafe-device-view.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box websiteapp pmustock skybluebg"
						data-hash="pmu-stock"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/pmu-stock-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1400vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/pmu-stock-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">PMU Stock</span>
													<span class="moreaboutapp"
														>A Dedicated Online Store for Microblading Tools</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												PMU stocks is the ultimate destination for finding a broad range of genuine
												and branded microblading tools and semi-permanent accessories. The website
												was built in partnership with AK Microblading, a leading microblading
												training institute in the UK. The website was devised to help microblading
												artists and training students find the right microblading tools and
												accessories in online destination without having to undergo the difficulty
												of sourcing it from multiple locations.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="../../assets/images/pmu-stock-device-view.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="web-technologies"
					role="tabpanel"
					aria-labelledby="web-technologies-tab"
					style="min-height: 1100vh"
				>
					<section
						class="section portfolio-preview-box mobapp pinkbg claireabella"
						data-hash="claireabella"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/claireabella-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 0;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="127"
													height="129"
													src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/claireabella-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Claireabella</span>
													<span class="moreaboutapp">A Personalized Emoji Building App</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Emojis have become a critical component of modern day communication. They
												help emote our feelings better and in a vivid way than with text.
												Clairebella approached Intelivita with the task of building an app that will
												allow users to create their own emojis. The emojis were to look and resemble
												UL’s popular cartoon character Clairebella. The app has enriched features
												that allows users to customize every single facial and physical aspect of
												the emoji like skin tone, hair, eye colours, expressions body, outfit among
												many others. The emoji can be used on device keyboard, social media and also
												order custom printed products from Toxicfox — a personalised gift online
												portal.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/claireabella/id1291769080"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div
												class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2"
												style="bottom: -30px"
											>
												<img
													class="lazy portrait"
													alt=""
													src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/claireabella-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp orangebg awayback"
						data-hash="awayback"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/awayback-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/awayback-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Awayback</span>
													<span class="moreaboutapp">An AR App for a Trip down Memory Lane</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Awayback is a location-based Augmented Reality app that allows users to
												create, store, share and also rediscover their memories. The app works by
												allowing the user to create a memory at a given location. The memory can be
												shared with friends who use the app who can rediscover when they visit the
												same place. Being an AR app, the content created was not limited to images
												but also videos. Intelivita made this app happen by leveraging Wikitude and
												ARkit Augmented Reality SDK. The app was released worldwide for Android and
												iOS devices.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp arvrapp mowmowbg mowmow"
						data-hash="mowmow"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/mowmow-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 200vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="133"
													height="136"
													src="../../assets/images/mowmow-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Mow Mow</span>
													<span class="moreaboutapp"
														>A Hide and Find Game with a Fitness Twist</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Mow mow is an Augmented reality mobile app that is aimed to make users to
												walk more and also have fun in the process. It works by allowing users to
												click a rock, called MowMow or select one the gallery. Highlight features of
												the app include the feature to add friends, and MoBucks — a rewarding point
												system for hiding or finding MowMows. Users can also add custom animated
												digi-stickers and tag friends, use hashtags to hide a MowMow. Tagged friends
												can walk around, find MowMows and share their finds on newsfeed. Every step
												that they take in the process is recorded with a pedometer, just like a
												fitness wearable. MowMow is developed using ARcore and ARkit for Android and
												iOS devices respectively.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/mowmow/id1465226420"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/mowmow-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp formassistbg"
						data-hash="form-assist"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/form-assist-background.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 300vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy landscape"
													alt=""
													src="../../assets/images/form-assist-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">FormAssist</span>
													<span class="moreaboutapp"
														>A Fitness App with Personal Guide to Keep in Shape</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												From the out-of-shape to ripped model, an app that helps users get fit. Form
												Assist is a fitness training mobile app that will train users on what to
												eat, how to work out and how to become the best selves that they want to be.
												The app is integrated with features like recipe finding, workout planning,
												chat with dietitians and trainers, goal setting and activity tracking and
												much more. On the timeline, users can track all the activities such as terms
												of diet, exercise, weight, walking step.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/tr/app/formassist-diet-and-exercise/id1345964523"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/form-assist-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp fantasybg"
						data-hash="fantasyji"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/fantasyji-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 400vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/fantasyji-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Fantasyji</span>
													<span class="moreaboutapp"
														>A Fantasy Game App Designed for Cricket Fanatics</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Cricket is a 11-player game. But, who said one player cannot play them all?
												Fantasyji is a fantasy sports app that allows users to play a fantasy game
												of cricket complete with a star-decked team, matches, tournaments and
												leagues. Users can form their own teams through auctioning, create their own
												private leagues, play according to real-time performance of players and
												accumulate rewards for their match wins.
											</p>
											<div class="button-wrap">
												<a
													href="https://s3.ap-south-1.amazonaws.com/devfantasyji/android_apk/v_3_1_1/fantasyji_3_1_1.apk"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/in/app/fantasyji/id1453701051"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/fantasyji-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp appai appaibg"
						data-hash="appai"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/appai-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 500vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="126"
													height="128"
													src="../../assets/images/appai-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Search by Shape AI</span>
													<span class="moreaboutapp"
														>An Augmented Reality and 3D-Based Object Detection App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Search by Shape AI is a mobile app that is powered by Augmented Reality and
												3D modeling. It works by allowing users to click real-time locations, upload
												them to the app and to detect objects. The 3D models of detected objects can
												then be placed on other real-time environments with the help of Augmented
												Reality. Intelivita’s expertise in building immersive reality apps using
												ARKit for iOS devices made this idea become a reality.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/appai-device-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp adanibg"
						data-hash="adani-saksham"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/adani-bg.png');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 600vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/adani-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Adani Saksham</span>
													<span class="moreaboutapp"
														>A Comprehensive Mobile App for Adani Group’s Skill Development
														Programs</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												The Adani Foundation of Skill Development for the Youth of India that had
												partnered with Government of India's Skill India Mission needed a
												resourceful mobile application. Thus was created Adani Saksham, a
												comprehensive mobile application that provides all skill development program
												information like types of courses, location and centres. To further
												disseminate information, the mobile app was released on Android and iOS
												platforms along with a full-fledged website.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/am/app/adani-saksham/id1457556359"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/adani-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp doreltaxibg"
						data-hash="dorel-taxi"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/dorel-taxi-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 700vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/dorel-taxi-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Dorel Taxi</span>
													<span class="moreaboutapp"
														>An On-demand Taxi Hailing App for Nigeria</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Dorel is the UBER for Nigeria. Intelivita helped build Dorel to bring the
												convenience of on-demand taxi hailing to the people of Nigeria. Its
												uniqueness lies in the long list of features for drivers, passengers and
												admins alike. To make the app experience seamless for passengers, a built-in
												support system was also embedded. The admin interface was also equipped with
												a neat interface that allows quick information access, delegation of rides
												and providing real-time support to discerning passengers.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Dorel Taxi"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Dorel Driver"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/dorel-taxi-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp yoca lightgreenbg"
						data-hash="yoca"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/yoca-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 800vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/yoca-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Yoca</span>
													<span class="moreaboutapp">The Uber for Coffee-ordering</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Coffee gives an instant jolt of energy. But, ordering it does not always
												happen instantly. Yoca helps get customers get their cup of coffee in
												quicktime. It is like an UBER for coffee-ordering. Users can browse coffee
												menus, order coffees from their favorite cafes, pay for it through the app,
												set up pickup time or even collect it on the go. Coffee shop owners can use
												an order management system to keep their deliveries organized.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.intelivita.co.uk/contact.php"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/yoca-device-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp squshybg"
						data-hash="squshy"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/squshy-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 900vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy landscape"
													alt=""
													src="../../assets/images/squshy-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Squishysaurus</span>
													<span class="moreaboutapp"
														>Bundle of Interactive, Fun, Educational Augmented App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Squishy Saurus is an augmented reality based fun and educational app that
												lets children to scan a QR code and interact with characters through actions
												buttons or voice commands. The app has three central characters Emma,
												Madison and Alifie. The characters can be made to read through voice
												commands. The app also has provision for in-app purchases of toys scanned
												with QR code. Unity 3D and Augmentation are the core technologies that power
												this app.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/squishy-ar/id1463014175"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main landscapescreen">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/squshy-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box gameapp mobapp cluedbg"
						data-hash="clued-app"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/clued-app-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 1000vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/clued-app-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Clued App</span>
													<span class="moreaboutapp">The Crime-solving Adventure Game</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												CluedUpp is an award-winning crime-solving adventure game app played by over
												250,000 budding detectives. CluedUpp host murder-mystery manhunt events all
												across the globe, allowing the team of detectives to put their sleuthing
												skills to the test. The crime-solving teams hunt all over their chosen city,
												finding virtual witnesses, eliminating suspects and bringing the killer to
												justice. The game was developed for both Android and iOS. The app has won
												multiple awards by the UK's top publications such as BBC, Nottingham Post,
												The Sun, Daily Mail, Manchester Evening News.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/gb/app/cluedupp-detective/id1360283188"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/clued-app-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="cloud"
					role="tabpanel"
					aria-labelledby="cloud-tab"
					style="min-height: 500vh"
				>
					<section
						class="section portfolio-preview-box arvrapp bluebg"
						data-hash="virtual-reality-app"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/microsoft-art-of-work-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 0px;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/microsoft-art-of-work-logo.png"
													style=""
												/>
											</div>
											<h3>A 360-Degree Virtual Reality App for Microsoft</h3>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Microsoft wanted to build an app for artists to share their creative work
												with the world at large. The technology for the idea was introduced through
												a SAP and Microsoft Azure partnership. Intelivita capitalized on the
												possibilities of the technology and built a virtual reality app with the
												capabilities of Unity 3D. The app experience was also enriched with a
												virtual reality 360-Degree experience.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.microsoft.com/en-in/p/art-of-work-by-microsoft/9p0ftv7qv4kv?activetab=pivot%3Aoverviewtab"
													rel="noreferrer"
													target="_blank"
													class="btn-custome btn waves-effect waves-light"
													>View Website</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/microsoft-art-of-work-laptop.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp orangebg awayback"
						data-hash="awayback"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/awayback-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/awayback-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Awayback</span>
													<span class="moreaboutapp">An AR App for a Trip down Memory Lane</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Awayback is a location-based Augmented Reality app that allows users to
												create, store, share and also rediscover their memories. The app works by
												allowing the user to create a memory at a given location. The memory can be
												shared with friends who use the app who can rediscover when they visit the
												same place. Being an AR app, the content created was not limited to images
												but also videos. Intelivita made this app happen by leveraging Wikitude and
												ARkit Augmented Reality SDK. The app was released worldwide for Android and
												iOS devices.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/awayback-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp arvrapp mowmowbg mowmow"
						data-hash="mowmow"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/mowmow-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 200vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="133"
													height="136"
													src="../../assets/images/mowmow-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Mow Mow</span>
													<span class="moreaboutapp"
														>A Hide and Find Game with a Fitness Twist</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Mow mow is an Augmented reality mobile app that is aimed to make users to
												walk more and also have fun in the process. It works by allowing users to
												click a rock, called MowMow or select one the gallery. Highlight features of
												the app include the feature to add friends, and MoBucks — a rewarding point
												system for hiding or finding MowMows. Users can also add custom animated
												digi-stickers and tag friends, use hashtags to hide a MowMow. Tagged friends
												can walk around, find MowMows and share their finds on newsfeed. Every step
												that they take in the process is recorded with a pedometer, just like a
												fitness wearable. MowMow is developed using ARcore and ARkit for Android and
												iOS devices respectively.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/mowmow/id1465226420"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/mowmow-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp appai appaibg"
						data-hash="appai"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/appai-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 300vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="126"
													height="128"
													src="../../assets/images/appai-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Search by Shape AI</span>
													<span class="moreaboutapp"
														>An Augmented Reality and 3D-Based Object Detection App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Search by Shape AI is a mobile app that is powered by Augmented Reality and
												3D modeling. It works by allowing users to click real-time locations, upload
												them to the app and to detect objects. The 3D models of detected objects can
												then be placed on other real-time environments with the help of Augmented
												Reality. Intelivita’s expertise in building immersive reality apps using
												ARKit for iOS devices made this idea become a reality.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/appai-device-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp arvrapp squshybg"
						data-hash="squshy"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/squshy-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 400vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy landscape"
													alt=""
													src="../../assets/images/squshy-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Squishysaurus</span>
													<span class="moreaboutapp"
														>Bundle of Interactive, Fun, Educational Augmented App</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Squishy Saurus is an augmented reality based fun and educational app that
												lets children to scan a QR code and interact with characters through actions
												buttons or voice commands. The app has three central characters Emma,
												Madison and Alifie. The characters can be made to read through voice
												commands. The app also has provision for in-app purchases of toys scanned
												with QR code. Unity 3D and Augmentation are the core technologies that power
												this app.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/squishy-ar/id1463014175"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main landscapescreen">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/squshy-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="database"
					role="tabpanel"
					aria-labelledby="database-tab"
					style="min-height: 400vh;"
				>
					<section
						class="section portfolio-preview-box mobapp gameapp arvrapp mowmowbg mowmow"
						data-hash="mowmow"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/mowmow-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 0;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													width="133"
													height="136"
													src="../../assets/images/mowmow-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Mow Mow</span>
													<span class="moreaboutapp"
														>A Hide and Find Game with a Fitness Twist</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Mow mow is an Augmented reality mobile app that is aimed to make users to
												walk more and also have fun in the process. It works by allowing users to
												click a rock, called MowMow or select one the gallery. Highlight features of
												the app include the feature to add friends, and MoBucks — a rewarding point
												system for hiding or finding MowMows. Users can also add custom animated
												digi-stickers and tag friends, use hashtags to hide a MowMow. Tagged friends
												can walk around, find MowMows and share their finds on newsfeed. Every step
												that they take in the process is recorded with a pedometer, just like a
												fitness wearable. MowMow is developed using ARcore and ARkit for Android and
												iOS devices respectively.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/us/app/mowmow/id1465226420"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2" style="bottom:-30px !important">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/mowmow-mobile-view.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box mobapp gameapp fantasybg"
						data-hash="fantasyji"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/fantasyji-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/fantasyji-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Fantasyji</span>
													<span class="moreaboutapp"
														>A Fantasy Game App Designed for Cricket Fanatics</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Cricket is a 11-player game. But, who said one player cannot play them all?
												Fantasyji is a fantasy sports app that allows users to play a fantasy game
												of cricket complete with a star-decked team, matches, tournaments and
												leagues. Users can form their own teams through auctioning, create their own
												private leagues, play according to real-time performance of players and
												accumulate rewards for their match wins.
											</p>
											<div class="button-wrap">
												<a
													href="https://s3.ap-south-1.amazonaws.com/devfantasyji/android_apk/v_3_1_1/fantasyji_3_1_1.apk"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/in/app/fantasyji/id1453701051"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/fantasyji-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box gameapp mobapp cluedbg"
						data-hash="clued-app"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/clued-app-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 200vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/clued-app-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Clued App</span>
													<span class="moreaboutapp">The Crime-solving Adventure Game</span>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												CluedUpp is an award-winning crime-solving adventure game app played by over
												250,000 budding detectives. CluedUpp host murder-mystery manhunt events all
												across the globe, allowing the team of detectives to put their sleuthing
												skills to the test. The crime-solving teams hunt all over their chosen city,
												finding virtual witnesses, eliminating suspects and bringing the killer to
												justice. The game was developed for both Android and iOS. The app has won
												multiple awards by the UK's top publications such as BBC, Nottingham Post,
												The Sun, Daily Mail, Manchester Evening News.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
												<a
													href="https://apps.apple.com/gb/app/cluedupp-detective/id1360283188"
													rel="noreferrer"
													target="_blank"
													title="App Store"
													><img
														src="../../assets/images/app-store.png"
														width="40"
														height="40"
														alt="mobile app development company uk"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main portfolio-preview-thumb-main-opt2">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/clued-app-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box gameapp blogasfriendbg"
						data-hash="blogb-as-friend"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/blog-as-a-friend.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 300vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/blob-as-friend-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">Blob &amp; Friends: coloring book</span>
													<span class="moreaboutapp">Fun Game for All Kids</span>
												</div>
											</div>
											<h3></h3>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Coloring book is a fun game for all kids. Play Learn Snap, simple colour
												filling mechanics by choosing the character in the game. This game is
												developed in the Unity game engine for Android and iOS devices. The game
												includes Multiple categories- “ANIMALS, BIRDS, FASHION, NATURE”, Multiple
												FREE, UNIQUE and APPEALING sketches, including dog, bear, cat, makeup,
												dress. Unlimited create, undo and save feature.
											</p>
											<div class="button-wrap">
												<a
													href="/"
													rel="noreferrer"
													target="_blank"
													title="Play Store"
													><img
														src="../../assets/images/play-store.png"
														width="40"
														height="40"
														alt="GenixBit Labs"
												/></a>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<div class="portfolio-preview-thumb-main">
												<img
													class="lazy portrait"
													alt=""
													src="../../assets/images/blob-as-friend-mobile.png"
													style=""
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="design"
					role="tabpanel"
					aria-labelledby="design-tab"
					style="min-height: 200vh;"
				>
					<section
						class="section portfolio-preview-box websiteapp newheavencafe purpalbg"
						data-hash="new-heaven-cafe"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/new-heaven-cafe.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 0;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/new-heaven-cafe-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">New Haven Cafe</span>
													<span class="moreaboutapp"
														>A Mobile App for a Popular Eastery Based out of Timperley</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												Finding a good eatery that serves a delectable range of eateries throughout
												the year is difficult. The New Haven Cafe at Timperley had achieved this
												rare feat. The cafe wanted an intuitive website of their own that will let
												their customers, both loyal and new ones, find everything from menu to
												working hours. The website was also designed in such a way that users can
												find the restaurant when they run an online search.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.newhavencafetimperley.co.uk/"
													rel="noreferrer"
													target="_blank"
													class="btn-custome btn waves-effect waves-light"
													>View Website</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="../../assets/images/new-heaven-cafe-device-view.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						class="section portfolio-preview-box websiteapp pmustock skybluebg"
						data-hash="pmu-stock"
						style="
							background-image: url('https://www.intelivita.co.uk/assets/frameworks/public/images/portfolio/mainpage/pmu-stock-bg.jpg');
							height: 100vh;
							position: absolute;
							left: 0px;
							top: 100vh;
						"
					>
						<div>
							<div class="container">
								<div class="row align-items-center">
									<div class="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1">
										<div class="portfolio-preview-content-box">
											<div class="portfolio-preview-main-logo">
												<img
													class="lazy"
													alt=""
													src="../../assets/images/pmu-stock-main-logo-icon.png"
													style=""
												/>
												<div class="portfolio-preview-main-logo-text">
													<span class="appname">PMU Stock</span>
													<span class="moreaboutapp"
														>A Dedicated Online Store for Microblading Tools</span
													>
												</div>
											</div>
											<ul class="contentboxlist">
												<li>Design</li>
												<li>Development</li>
											</ul>
											<p>
												PMU stocks is the ultimate destination for finding a broad range of genuine
												and branded microblading tools and semi-permanent accessories. The website
												was built in partnership with AK Microblading, a leading microblading
												training institute in the UK. The website was devised to help microblading
												artists and training students find the right microblading tools and
												accessories in online destination without having to undergo the difficulty
												of sourcing it from multiple locations.
											</p>
											<div class="button-wrap">
												<a
													href="https://www.genixbit.com/contact"
													class="btn-custome btn waves-effect waves-light"
													>Request a Demo</a
												>
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-12">
										<div class="portfolio-preview-thumb text-center">
											<img
												class="lazy"
												alt=""
												src="../../assets/images/pmu-stock-device-view.png"
												style=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
#technologies_tabs.portfolio {
	border: none;
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: unset;
	margin-bottom: unset;
	border-radius: 10px;
	border-top: 1px solid #dddfe1;
	border-bottom: 1px solid #dddfe1;
	background: #fff;
	padding: 0 !important;
	position: absolute;
	top: 0;
	z-index: 999;
	left: 50%;
	transform: translate(-50%, -50%);
}
#technologies_tabs.portfolio li {
	padding: unset;
	border-right: unset;
	width: 20%;
}
#technologies_tabs.portfolio li a.active,
#technologies_tabs.portfolio li a:hover {
	color: unset;
}
#technologies_tabs.portfolio li a {
	color: unset;
	font-weight: unset;
}
a.active .option1 .portfolio-listing-box,
.option1 .portfolio-listing-box:hover {
	background-color: #0082dd;
}

.option1 .portfolio-listing-box:first-child,
.option1 .portfolio-listing-box:last-child {
	border-left: 0;
}
.option1 .portfolio-listing-box {
	background-color: #fff;
}
a.active .portfolio-listing-box,
.portfolio-listing-box:hover {
	background-color: #0082dd;
}
a.active .portfolio-listing-box svg *,
.portfolio-listing-box:hover svg * {
	fill: #fff;
}
a.active .portfolio-listing-box span,
.portfolio-listing-box:hover span {
	color: #fff;
}

.portfolio-listing-box:first-child {
	border-left: 1px solid #dddfe1;
}
.portfolio-listing-box {
	width: 100%;
	text-align: center;
	display: flex;
	align-items: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-right: 1px solid #dddfe1;
}
.portfolio-listing-icon {
	width: 100%;
	margin: 0 auto;
	cursor: pointer;
	min-height: 120px;
	padding: 18px 10px;
}
.portfolio-listing-icon svg {
	width: 44px;
	height: 44px;
}
svg {
	overflow: hidden;
	vertical-align: middle;
}
.portfolio-listing-box.current .portfolio-listing-icon span,
.portfolio-listing-box:hover .portfolio-listing-icon span {
	color: #fff;
}
.portfolio-listing-icon span {
	display: block;
	font-size: 16px;
	margin-top: 15px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-preview-box.section {
	display: table;
	width: 100%;
}

.portfolio-preview-box {
	position: relative;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
.portfolio-preview-box:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
}
.portfolio-preview-box.section > div {
	display: table-cell;
	vertical-align: middle;
}
.portfolio-preview-box .container {
	max-width: 1300px;
}
.portfolio-preview-main-logo {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}
.portfolio-preview-main-logo img {
	display: block;
}
.portfolio-preview-content-box h3 {
	font-size: 24px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}
.portfolio-preview-content-box .contentboxlist {
	margin-bottom: 20px;
}
.portfolio-preview-content-box .contentboxlist li:before {
	content: '';
	display: block;
	width: 1px;
	height: 18px;
	background-color: #fff;
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -9px;
}
.portfolio-preview-content-box .contentboxlist li:last-child {
	margin: 0;
	padding: 0;
}
.portfolio-preview-content-box p {
	color: #fff;
	margin-bottom: 25px;
}
.portfolio-preview-content-box .button-wrap {
	margin-top: 40px;
	display: flex;
	align-items: center;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.portfolio-preview-content-box .button-wrap a {
	margin-right: 15px;
}
.portfolio-preview-content-box .btn-custome {
	background-color: #fff !important;
	color: #202122 !important;
	font-weight: 500;
}
.portfolio-preview-main-logo {
	margin-bottom: 15px;
	display: flex;
	align-items: center;
}
.portfolio-preview-main-logo img {
	display: block;
}
.portfolio-preview-main-logo-text {
	padding-left: 15px;
	text-align: left;
}

.portfolio-preview-main-logo-text .moreaboutapp {
	color: #fff;
	display: block;
	font-size: 24px;
	line-height: 1.3;
}
.portfolio-preview-main-logo-text .appname {
	color: #fff;
	display: block;
	font-size: 36px;
	font-weight: 500;
	line-height: 1.3;
}
.portfolio-preview-content-box .contentboxlist li {
	display: inline-block;
	vertical-align: top;
	position: relative;
	color: #fff;
	padding: 0 15px 0 0;
	margin: 0 15px 0 0;
}
.portfolio-preview-content-box .button-wrap {
	margin-top: 40px;
	display: flex;
	align-items: center;
}
.portfolio-preview-thumb-main-opt2 {
	position: relative;
	bottom: 50px;
}
.portfolio-preview-box.bluebg:before {
	background: rgba(41, 127, 221, 0.85);
}
.portfolio-preview-box.pinkbg:before {
	background: rgba(238, 20, 133, 0.85);
}
.portfolio-preview-box.orangebg:before {
	background: rgba(239, 108, 61, 0.85);
}
.portfolio-preview-box.mowmowbg:before {
	background: rgba(48, 99, 213, 0.5);
}
.portfolio-preview-box.formassistbg:before {
	background: rgba(0, 210, 165, 0.9);
}
.portfolio-preview-box.fantasybg:before {
	background: rgba(18, 46, 79, 0.9);
}
.portfolio-preview-box.appaibg:before {
	background: rgba(122, 78, 35, 0.9);
}
.portfolio-preview-box.adanibg:before {
	background: rgba(0, 109, 159, 0.9);
}
.portfolio-preview-box.doreltaxibg:before {
	background: rgba(170, 121, 68, 0.8);
}
.portfolio-preview-box.lightgreenbg:before {
	background: rgba(38, 162, 127, 0.85);
}
.portfolio-preview-box.squshybg:before {
	background: rgba(0, 84, 144, 0.97);
}
.portfolio-preview-box.cluedbg:before {
	background: rgba(88, 88, 90, 0.9);
}
.portfolio-preview-box.blogasfriendbg:before {
	background: rgba(97, 168, 143, 0.9);
}
.portfolio-preview-box.purpalbg:before {
	background: rgba(77, 27, 74, 0.95);
}
.portfolio-preview-box.skybluebg:before {
	background: rgba(0, 168, 177, 0.85);
}

@media (min-width: 992px) {
	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-ms-flex-order: -4;
		order: -4;
	}
	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}
}
@media (max-width: 1500px) {
	.portfolio-preview-main-logo-text .appname {
		font-size: 34px;
	}
	.portfolio-preview-main-logo-text .moreaboutapp {
		font-size: 22px;
	}
}
@media (max-width: 1500px) {
	.portfolio-preview-thumb img.portrait {
		height: 500px;
		margin: 0 auto;
		display: block;
	}
}

@media (max-width: 1600px) {
	.portfolio-preview-thumb img.portrait {
		height: 550px;
		margin: 0 auto;
		display: block;
	}
}
</style>
