<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/real-estate-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Why you should place your bet on real estate app development solutions
					</h2>
					<p>
						The millennial generation has a different way of doing things. They think is is pretty
						old school to shortlist brokers and visit each property until a good one is spotted.
						They do not have the patience nor the time to explore real estate opportunities. What
						they need is a real estate mobile app that will help them filter the right kind of
						property and shortlist it for buying or renting. Who else than a top-rated real estate
						app development company like GenixBit Labs to do it for you?
					</p>
					<p>
						GenixBit Labs is a mobile app development company that has been helping businesses become
						digital corporations with the power of mobility. We craft mobile apps for diverse
						industries, including real estate that can help you reach customer faster, serve them
						better and also retain them for lifetime. Our app development expertise is designed
						taking into consideration the needs of builders, brokers, home buyers and also allied
						business providers.
					</p>
					<p>
						Want to see how your real estate business can reach new heights with a real estate
						mobile app? Talk to our experts.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
