<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="title spc-titleds">
				<span>Manage with ease</span>
				<h2 class="fxt-process-dev">Ready-to-go Admin Panel</h2>
				<p>
					From setting the theme of the chatbox to moderating member acitivity, command complete
					control over your instant messaging app
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12">
					<h3 style="font-size:1.8em">One panel to manage them all</h3>
					<p>
						Being a leader and being an admin are almost the same thing. You have to ensure that all
						your users get the features they want. At the same time, you cannot be monitoring their
						activity on an individual basis. You need a single command center, a control console
						from where everything can be handled.
					</p>
					<p>
						GenixBit Labs understands this need for building a comprehensive control panel for its
						instant messaging app solutions. We have designed the admin app panel in such a manner
						that you will be able to do everything from adding new members, removing dormant
						accounts, viewing reports or even setting premium plan features from a single dashboard.
					</p>
				</div>
				<div class="col-12 col-lg-7 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/app-panels.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.spc-titleds::before {
	display: none;
}
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
