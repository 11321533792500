<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/augmented-reality-app-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Augmented Reality App Development Company</h2>
					<p>
						With its roots in the UK and a global client base, Genixbit has commendably made its space as leading Augmented Reality app development company.
					</p>
					<p>
						At Genixbit, we have a team of expert Augmented Reality app developers which has years of proven expertise in building best-in-industry custom android augmented reality apps, iOS- iPad/iPhone AR apps and wearable devices offering robust backend and highly friendly user interface to attract and engage your target audience and reach your set goals. The team thrives to build innovative solutions for industries from various niches.
					</p>
					<p>
						AR mobile applications built by us work flawlessly with devices like AR headsets, smart glasses, smart lenses, tablets, smartphones and other augmented reality compatible devices. This eventually lets you reach a larger audience.
					</p>
					<p>
						With our presence in the UK and across Europe, the team Genixbit has inevitably established its name as one of the highly advanced AR app development companies. The proficiency and ‘out-of-the-box’ approach has indeed helped us to build custom augmented reality app solutions for our global customers.
					</p>
					<p>
						Have queries? Well, having questions is a sign of intelligence. Please allow us to help you find solutions to your queries.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
