<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					Our unique traits as a Entertainment App Development Company
				</h2>
				<p>
					Get to know our specialities that makes us the best choice for Entertainment App
					Development Solutions.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
					<div class="why-you-invest-box-text">
						<h5 class="mb-20">Our Clients Love Us</h5>
						<a class="btn-custome btn waves-effect waves-light" href="/client-review"
							>Our Reviews</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../../EnterpriseSolutions/AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M82,56.6H70.8v-3.4c0-1-1-1.9-1.9-1.9h-0.2c-0.7,0-1.2,0.5-1.4,1c-0.2,0.3-0.3,0.6-0.3,0.9v3.4H55.9c-0.5,0-1,0.2-1.4,0.6
                    l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.3,0.3-0.5,0.7-0.5,1l0,0.1l0,0.1c0,0.3,0.1,0.6,0.3,0.9L54,60l0.5,0.3
                    c0.2,0.1,0.6,0.4,1.2,0.4H62c0.1,0.4,0.3,0.9,0.5,1.5l-0.1,0l0.1,0.4c0.5,1.3,1,2.6,1.9,4.1c0.5,0.9,1.1,1.8,1.8,2.7
                    c-2.5,2.4-5.6,4.1-9.6,5.4c-0.5,0-0.8,0.4-1,0.6l-0.1,0.1c-0.2,0.4-0.4,0.9-0.4,1.4c0.2,0.8,1,1.4,1.9,1.4h0.3l0.1,0
                    c4.5-1.3,8.3-3.4,11.5-6.4c3.1,3,7,5.1,11.5,6.4l0.4,0c0.3,0,0.7-0.1,1-0.3h1.6l-1.1-0.3c0.2-0.2,0.3-0.3,0.4-0.6
                    c0.2-0.6,0-1.1-0.2-1.4c-0.2-0.4-0.7-0.7-1.1-0.9c-4-1.1-7.2-2.9-9.8-5.5c0.3-0.4,0.6-0.9,0.9-1.4c0.3-0.4,0.5-0.7,0.7-1l0.2-0.3
                    l-0.1-0.1c0.8-1.3,1.3-2.5,1.7-3.8c0.3-0.8,0.5-1.4,0.6-2l6.4,0c0.6,0,1.2-0.3,1.6-0.7l5.3-5.2l-5.5,2.7
                    C83.2,56.9,82.6,56.6,82,56.6z M68.9,66c-1.1-1.7-2-3.7-2.5-5.4h4.9C71,61.7,70.3,63.7,68.9,66z M44.9,39.1c0.3,0,0.6,0,1-0.2
                    c0.6-0.4,0.8-0.8,1-1.1l0.1-0.2L47,37.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.7L33.6,13v-0.2l-0.3-0.3
                    c-0.1-0.1-0.2-0.2-0.4-0.3L32.7,12h-1.8l0.1,0.1c0,0,0,0,0,0h-0.1l0,0l-0.2-0.3l-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.5L17,36.1
                    c-0.2,0.3-0.4,0.8-0.2,1.5l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6l-1,1h3c0.7,0,1.1-0.4,1.4-0.7l5.1-9.1h12.9l4.8,8.6
                    C43.7,38.7,44.3,39.1,44.9,39.1z M27.2,25.6l4.5-8l4.5,8H27.2z M98.4,42.3c-1.2-1.2-2.8-1.9-4.5-1.9H63.1V7.3
                    c0-3.4-2.8-6.2-6.3-6.2H6.6c-3.5,0-6.3,2.8-6.3,6.2V43L0,43v0.4c0,0.2,0,0.4,0,0.5c0.1,1.3,0.5,2.4,1.2,3.3l0.3,0.3l0.3-0.2
                    c1.2,1.4,3,2.3,4.9,2.3h5.8v7.7c0,0.8,0.4,1.5,1,1.6l-1.2,0.9l2.2-0.9c0.2,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.2,0.4-0.3l2-1.6
                    L17,57.1l8.1-7.6h12.1v33.2c0,3.4,2.8,6.2,6.3,6.2h0.3v-0.2h31l-0.2,0.2l10.1,9.5c0.2,0.2,0.3,0.3,0.5,0.4
                    c0.2,0.1,0.4,0.1,0.7,0.1h0.4v-0.5c0.2,0,0.4-0.1,0.6-0.2c0.7-0.4,1-1.1,1-1.6v-7.7h5.8c3.5,0,6.3-2.8,6.3-6.2V46.7
                    C100.1,45,99.5,43.5,98.4,42.3z M37.4,45.8H24.2c-0.8,0-1.1,0.3-1.3,0.6L16,52.9v-5.1c0-1-1-1.8-1.9-1.8H6.6
                    c-1.4,0-2.5-1.1-2.5-2.5V7.3C4.1,6,5.2,5,6.6,5h50.2c1.4,0,2.5,1.1,2.5,2.3v33.2H43.6c-0.8,0-1.6,0.1-2.3,0.4l0-0.1l0,0.1
                    C39.2,41.7,37.7,43.5,37.4,45.8z M96.5,82.4h-0.2v0.4c0,1.3-1.1,2.3-2.5,2.3h-7.7c-0.9,0-1.7,0.7-1.9,1.6l-0.7,0.5l0.7-0.2v5.2
                    l-6.9-6.5c-0.3-0.3-0.8-0.4-1.3-0.4H43.8c-1.4,0-2.5-1.1-2.5-2.3V46.8l0.2,0.1l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.7
                    c0.1-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.5,0.7-0.6l0.4-0.1l0-0.1c0.1,0,0.3-0.1,0.5-0.1h50.2c0.2,0,0.4,0.1,0.6,0.1l0,0.1l0.3,0.1
                    L95,45l0,0c0.3,0.1,0.5,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7l0.2,0.4l0.3-0.2V82.4z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Multi-language Support',
					text: 'Your media and entertainment apps must appeal to a global audience. They must have multi-language support so that uses can switch to a language of their preference to use the app conveniently.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M36.67,0.01H5.83C5.37,0.01,5,0.38,5,0.84v4.17H0.83C0.37,5.01,0,5.38,0,5.84v43.32C0,49.63,0.37,50,0.83,50h30.83
                    c0.46,0,0.83-0.37,0.83-0.83v-3.33h4.17c0.46,0,0.83-0.37,0.83-0.83V0.84C37.5,0.38,37.13,0.01,36.67,0.01z M30.83,48.33H1.67
                    V6.67H22.5v7.5c0,0.46,0.37,0.83,0.83,0.83h7.5V48.33z M24.17,13.34V7.85l5.49,5.49H24.17z M35.83,44.17H32.5V14.17
                    c0-0.22-0.09-0.43-0.24-0.59l-1.91-1.91h2.15v-1.67h-3.82l-2.5-2.5h1.32V5.84h-2.99l-0.59-0.59c-0.16-0.16-0.37-0.24-0.59-0.24
                    H6.67V1.67h29.17V44.17z M47.51,12.5h-0.85c0-1.84-1.49-3.33-3.33-3.33c-1.84,0-3.33,1.49-3.33,3.33V45c0,0.15,0.04,0.3,0.12,0.43
                    l2.5,4.17c0.24,0.39,0.75,0.52,1.14,0.28c0.12-0.07,0.21-0.17,0.28-0.28l2.5-4.17c0.08-0.13,0.12-0.28,0.12-0.43V14.17h0.85
                    c0.45,0,0.82,0.37,0.82,0.82v14.18H50V14.99C50,13.62,48.89,12.51,47.51,12.5z M43.33,47.54l-1.03-1.71h2.06L43.33,47.54z
                    M45,44.17h-3.33v-15H45V44.17z M45,27.5h-3.33V14.17H45V27.5z M41.67,12.5c0-0.92,0.75-1.67,1.67-1.67
                    c0.92,0,1.67,0.75,1.67,1.67H41.67z M5.83,38.33h20.83v-1.67H5.83V38.33z M5.83,42.5h12.5v-1.67H5.83V42.5z M10.83,34.17h15.83
                    V32.5H10.83V34.17z M9.17,32.5H5.83v1.67h3.33V32.5z M22.5,20.84v7.5h-1.67V22.5h-1.67v5.83H17.5V20h-1.67v8.33h-1.67V22.5H12.5
                    v5.83h-1.67v-2.5H9.17v2.5H7.5V20H5.83v9.16c0,0.46,0.37,0.83,0.83,0.83h20v-1.67h-2.5v-7.5H22.5z M16.67,10.84H5.83v1.67h10.83
                    V10.84z M13.33,15h-7.5v1.67h7.5V15z M32.5,5.84h-3.33v1.67h3.33V5.84z M20,42.5h3.33v-1.67H20V42.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'NDA',
					text: 'Your app requirements, business model, USPs — and everything else will remain discreet. We can sign a Non-disclosure agreement to put the terms in writing.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M15.4,22.1c0.5,0,0.9-0.4,0.9-0.9v-4.8c0-3.8,3.1-6.9,6.9-6.9h11.2c2.2,0,4.3,1.1,5.6,2.9c0.3,0.4,0.9,0.5,1.2,0.2
                  c0.4-0.3,0.5-0.8,0.2-1.2c-1.6-2.3-4.3-3.6-7.1-3.6H23.3c-4.8,0-8.7,3.9-8.7,8.7v4.8C14.5,21.7,14.9,22.1,15.4,22.1z"></path>
                  <path d="M42.3,23.1c-0.5,0-0.9,0.4-0.9,0.9v3.7c0,3.8-3.1,6.9-6.9,6.9H23.3c-3.1,0-5.8-2.1-6.7-5.1c-0.1-0.5-0.6-0.8-1.1-0.6
                  C15,29,14.7,29.5,14.9,30c1,3.8,4.5,6.4,8.4,6.4h11.2c4.8,0,8.7-3.9,8.7-8.7V24C43.2,23.5,42.8,23.1,42.3,23.1z"></path>
                  <path d="M46.7,14C46.7,14,46.7,14,46.7,14C46.7,14,46.7,14,46.7,14c-0.1-0.2-0.2-0.3-0.3-0.3c0,0-0.1,0-0.1-0.1
                  c-0.1-0.1-0.3-0.1-0.4-0.1c0,0,0,0,0,0h-7c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h5.1L32.4,28.9l-10.3-9c-0.3-0.3-0.9-0.3-1.2,0
                  l-9,8.1c-0.4,0.3-0.4,0.9-0.1,1.3c0.3,0.4,0.9,0.4,1.3,0.1l8.4-7.6l10.4,9.1c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0,0,0.1,0
                  c0.2,0,0.5-0.1,0.6-0.3L45,16.8v4.4c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-6.8C46.8,14.3,46.7,14.1,46.7,14z"></path>
                  <path d="M21.6,6.3c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.2,0.9-0.6c0.8-2.4,3-4,5.4-4c2.4,0,4.6,1.6,5.4,3.9c0.2,0.5,0.7,0.7,1.1,0.5
                  C35.2,6,35.4,5.5,35.2,5C34.2,2,31.3,0,28.1,0C24.9,0,22,2.1,21,5.2C20.8,5.7,21.1,6.2,21.6,6.3z"></path>
                  <path d="M23.7,37.9c-0.3-0.3-0.9-0.3-1.3,0c-0.3,0.3-0.3,0.9,0,1.3l6.5,6.5c0.5,0.5,0.8,1.2,0.6,1.7c-0.1,0.4-0.4,0.6-0.7,0.7
                  c-0.7,0.2-1.7-0.3-2.6-1.3c-2.3-2.4-6.7-6.9-6.7-6.9c-0.3-0.3-0.9-0.4-1.2,0c0,0-3.2,2.8-8.1,0.5c-3.2-1.5-5.3-4.8-5.2-8.2
                  C5,29,6.1,26.6,8,25c1.9-1.5,4.2-1.7,4.7-1.6c0.5,0.1,1-0.2,1-0.7c0.1-0.5-0.2-1-0.7-1c-1-0.2-3.8,0-6.2,1.9
                  c-1.6,1.3-3.6,3.8-3.8,8.6c-0.1,4,2.4,8,6.2,9.8c4.6,2.2,8,0.7,9.4-0.2c1.3,1.3,4.4,4.5,6.2,6.4c1.4,1.5,2.7,1.8,3.6,1.8
                  c0.3,0,0.6,0,0.8-0.1c1-0.3,1.7-1,2-2c0.3-1.2-0.1-2.5-1.1-3.5L23.7,37.9z"></path>
                </g>
              </svg>`,
					title: 'App-store Friendly',
					text: 'App store optimization is a powerful tool that can help your media or gaming app rank higher in app stores. Our ASO expertise will ensure that your app will get maximum momentum and downloads from the day of launch.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M26.8,46.7h3.3c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8C26,46.3,26.4,46.7,26.8,46.7z"></path>
                  <path d="M14.3,12.5c0.5,0,0.8-0.4,0.8-0.8v-5h26.7v6.7c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-10c0-1.5-1.9-3.3-4.2-3.3
                  H17.7c-2.3,0-4.2,1.9-4.2,4.2v7.5C13.5,12.1,13.9,12.5,14.3,12.5z M15.2,4.2c0-1.4,1.1-2.5,2.5-2.5h21.7c1.4,0,2.5,1.2,2.5,1.7V5
                  H15.2V4.2z"></path>
                  <path d="M42.7,36.7c-0.5,0-0.8,0.4-0.8,0.8v4.2H15.2v-3.3c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v7.5c0,2.3,1.9,4.2,4.2,4.2h21.7
                  c2.3,0,4.2-1.9,4.2-4.2v-8.3C43.5,37,43.1,36.7,42.7,36.7z M41.8,45.8c0,1.4-1.1,2.5-2.5,2.5H17.7c-1.4,0-2.5-1.1-2.5-2.5v-2.5
                  h26.7V45.8z"></path>
                  <path d="M31.8,2.5h-6.7c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h6.7c0.5,0,0.8-0.4,0.8-0.8S32.3,2.5,31.8,2.5z"></path>
                  <path d="M37,14.5c-6,0-10.8,4.9-10.8,10.8S31,36.2,37,36.2c6,0,10.8-4.9,10.8-10.8C47.8,19.4,43,14.5,37,14.5z M37,34.5
                  c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2S42,34.5,37,34.5z"></path>
                  <path d="M37,21.2c0.9,0,1.7,0.7,1.7,1.7c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-1.5-1-2.8-2.5-3.2v-1
                  c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8v1c-1.8,0.5-2.9,2.3-2.4,4.1c0.4,1.5,1.7,2.5,3.2,2.5c0.9,0,1.7,0.7,1.7,1.7
                  c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,1.5,1,2.8,2.5,3.2v1
                  c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-1c1.8-0.5,2.9-2.3,2.4-4.1c-0.4-1.5-1.7-2.5-3.2-2.5c-0.9,0-1.7-0.7-1.7-1.7
                  C35.3,21.9,36.1,21.2,37,21.2z"></path>
                  <path d="M15.5,25.3c0-0.5-0.4-0.8-0.8-0.8H3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h11.7C15.1,26.2,15.5,25.8,15.5,25.3z"></path>
                  <path d="M17.2,24.5c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h1.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8H17.2z"></path>
                  <path d="M20.1,31.3c0-0.5-0.4-0.8-0.8-0.8H7.6c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h11.7C19.7,32.1,20.1,31.7,20.1,31.3z"></path>
                  <path d="M23.4,30.4h-1.7c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h1.7c0.5,0,0.8-0.4,0.8-0.8C24.3,30.8,23.9,30.4,23.4,30.4z"></path>
                  <path d="M7.6,20.2h11.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8H7.6c-0.5,0-0.8,0.4-0.8,0.8C6.8,19.9,7.1,20.2,7.6,20.2z"></path>
                  <path d="M23.4,18.6h-1.7c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h1.7c0.5,0,0.8-0.4,0.8-0.8C24.3,18.9,23.9,18.6,23.4,18.6z"></path>
                </g>
              </svg>`,
					title: 'App Monetization',
					text: 'Turning a mobile app into a source of revenue is not a task that is easily done. Our app monetization techniques will help you create the right monetization channels that will help you monetize the app aptly.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M78.4,66.5l1.3-8.5c0-0.1,0-0.2,0-0.3c0-2.6-2-4.7-4.6-4.7H63.9c-3-1.1-4.6-4.3-3.9-7.4c4.7-2.9,7.9-7.7,8.8-13.2
                    c1.5-2.9,7.1-14.9,0.7-22.4C69.1,9.4,61.5,0.4,51,0c-6.1-0.2-11.9,2.4-17.2,8c-2.9,2-5,5-5.8,8.4c-0.8,3.9,0.2,8.1,3.1,12.6
                    c0,6.8,3.5,13.1,9.1,16.6c0.8,3.2-0.9,6.4-3.9,7.5H24.8c-2.5,0-4.5,2.1-4.6,4.7c0,0.1,0,0.2,0,0.3l1.3,8.7
                    c-3.4,4.9-5.3,10.8-5.3,16.9v11.5c0,2.7,2.1,4.9,4.8,4.9H79c2.6,0,4.8-2.2,4.8-4.9V83.6C83.8,76.7,82,71.2,78.4,66.5z M34,27.6
                    c-2.6-3.7-3.6-7.3-2.9-10.4c0.7-2.7,2.3-5,4.5-6.5c0.1-0.1,0.2-0.2,0.3-0.3c4.7-4.9,9.7-7.3,14.8-7.1C60.1,3.6,67,12,67.1,12.1
                    c4.6,5.3,1,14.4-0.6,17.8c-0.1-0.2-0.2-0.3-0.4-0.5c0-0.1-0.1-0.1-0.1-0.2l-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
                    c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.4l-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4C65,26.4,65,26.2,64.9,26
                    c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.8l-0.1-0.4c-0.1-0.4-0.1-0.9-0.1-1.4c-0.1-1.8-0.9-3.5-2.3-4.6c-0.2-0.2-0.5-0.4-0.8-0.5l-0.2-0.1
                    c-0.3-0.1-0.5-0.3-0.8-0.4h-0.1c-0.3-0.1-0.5-0.2-0.8-0.2h-0.1c-0.3-0.1-0.6-0.1-0.9-0.1h-0.2c-0.3,0-0.6,0-0.9,0.1
                    c-7.6,1.3-19.9,0.6-20,0.5c-0.9-0.1-1.6,0.6-1.7,1.5c0,0.1,0,0.2,0,0.2c0.3,3-0.1,6-1.3,8.7L34,27.6z M35.9,36.6
                    c-0.7-1.2-1.1-2.6-1.5-3.9l0.2,0c0.2,0,0.3-0.1,0.5-0.1h0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,3.4-3.8,3.3-11.2
                    c3.8,0.2,12.8,0.4,19-0.7c0.8-0.1,1.6,0.1,2.2,0.6c0.6,0.5,1,1.3,1.1,2.2v1.2l0.1,0.6v0.3c0,0.1,0.1,0.4,0.1,0.6
                    c0,0.1,0,0.2,0.1,0.3l0.1,0.6c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.1,0.6,0.2,0.9v0.1c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1,0.1,0.2,0.1,0.2
                    c0,0.1,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.5c0,0.1,0.1,0.2,0.1,0.2l0.3,0.5l0.1,0.2c0.1,0.2,0.3,0.4,0.4,0.6l0.1,0.1
                    c0.1,0.2,0.2,0.3,0.4,0.5l0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.1l0.1,0.1
                    c-1.1,4.1-3.7,7.6-7.3,9.7c-0.2,0.1-0.3,0.2-0.5,0.3C49.7,47.6,40.1,44.6,35.9,36.6z M58.7,53H41.6c1.3-1.7,1.9-3.7,1.9-5.8
                    c4.2,1.6,8.9,1.6,13.2,0C56.7,49.3,57.4,51.4,58.7,53z M24.8,56.3h50.3c0.7,0,1.3,0.6,1.4,1.3L71.8,89c0,0.1,0,0.2,0,0.2
                    c0,0.8-0.6,1.4-1.3,1.4h-41c-0.7,0-1.4-0.6-1.4-1.4v0c0-0.1,0-0.2,0-0.2l-4.7-31.3C23.5,56.9,24.1,56.4,24.8,56.3z M21.3,93.6v3.1
                    h-0.3c-0.9,0-1.6-0.7-1.6-1.6v0h0V83.6c0-4.1,1-8.2,2.8-11.9l2.6,17.7c0,0.4,0.1,0.8,0.2,1.3h-0.8C22.7,90.6,21.3,91.9,21.3,93.6z
                    M74.5,96.7h-50v-2.9h50V96.7z M80.5,95.1c0,0.9-0.7,1.6-1.6,1.6h-1.2v-3.1c0-1.7-1.3-3-2.9-3c0.1-0.4,0.2-0.8,0.2-1.3l2.7-18.1
                    c2,3.8,3,8,2.9,12.3L80.5,95.1z M47.3,26.3c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.7,2.6,2.7S47.3,27.8,47.3,26.3z
                    M56,29c1.4,0,2.6-1.2,2.6-2.7c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C53.4,27.8,54.5,29,56,29z M56,36.2c0-0.9-0.7-1.6-1.6-1.6
                    c-0.9,0-1.6,0.7-1.6,1.6c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6
                    c0,3.4,2.7,6.2,6.1,6.2C53.3,42.4,56,39.6,56,36.2z M42.5,83.1c0.3,0.3,0.7,0.5,1.1,0.5c0.9,0,1.6-0.8,1.6-1.7
                    c0-0.4-0.2-0.8-0.5-1.1l-7.1-7.4l7.1-7.3c0.6-0.6,0.7-1.7,0.1-2.3c-0.6-0.7-1.6-0.7-2.3-0.1c0,0-0.1,0.1-0.1,0.1l-8.2,8.4
                    c-0.6,0.6-0.6,1.7,0,2.3L42.5,83.1z M55.7,66.1l7.1,7.3l-7.2,7.4c-0.6,0.6-0.6,1.7,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5
                    c0.4,0,0.8-0.2,1.1-0.5l8.3-8.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.8-0.5-1.2L58,63.8c-0.6-0.6-1.7-0.6-2.3,0.1
                    C55.1,64.5,55.1,65.5,55.7,66.1L55.7,66.1z M46.1,85.6c0.2,0.1,0.3,0.1,0.5,0.1c0.7,0,1.3-0.4,1.5-1.1l7-21.2
                    c0.3-0.9-0.1-1.8-1-2.1c-0.8-0.3-1.8,0.1-2,1l-7,21.2C44.9,84.4,45.3,85.3,46.1,85.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Experienced Developers',
					text: 'Your media and gaming app is in safe hands. We have experienced developers who know how to turn abstract gaming concepts into highly-compelling game concepts that will engage users.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>