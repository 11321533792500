<template>
	<section
		class="service-main-content padding-60 bg-white"
		style="margin-top: -50px; padding-top: 100px"
	>
		<div class="container">
			<div class="row align-items-center">
				<div class="col-lg-7 col-md-12 col-sm-12 col-12 order-lg-1 mb-4 m-lg-0">
					<h2 class="h4 mb-3">How it works</h2>
					<p>
						Modern-day businesses - be it a startup, a SMB or an enterprise - all have almost
						identical requirements. They want their projects to be completed quickly, without any
						functional issues and with the highest quality possible. For close to a decade, MN
						Webbased has been delivering on that constantly raising expectations with our agile
						process.
					</p>
					<p>
						To ensure high-quality of work delivered, we created a organization-wide process that
						will foster a culture of quality, innovation and integrity in software development. The
						objective of our process is to deliver value to our clients in the best manner possible,
						within the shortest time span and without overrunning budgets.
					</p>
					<p>
						The process brings together the combined efforts of a scrum masters, project managers,
						software developers, UI/UX designers, testing engineers and stakeholders. This
						collaborative effort ensures that end result is nothing short of perfect. And that’s how
						GenixBit has been putting businesses on the fast lane to digital transformation all
						these years.
					</p>
					<router-link to="/contact" class="btn btn-custome waves-effect waves-light"
						>Talk to Our Expert Now</router-link
					>
				</div>
				<div class="col-lg-5 col-md-12 col-sm-12 col-12 text-center">
					<img
						alt=""
						class="lazy rounded10 box-shadow"
						src="../../assets/images/software-development-process.jpg"
						style=""
						width="470"
						height="500"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.service-main-content h2::before {
	content: '';
	display: block;
	height: 5px;
	background-color: #1977cc;
	width: 30%;
	position: absolute;
	bottom: 0;
	border-radius: 10px;
}
</style>