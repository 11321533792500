<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/crm-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Best in class CRM Software Solutions</h2>
					<p>
						Create valuable customer relationships with CRM software solutions tailored to your
						requirements. Time to leverage technology by integrating cutting edge CRM software
						solutions in your day-to-day business transactions that saves time and cuts expenditure
						in gaining and retaining customers.
					</p>
					<p>
						Drive business growth with profitable customer service and relationship management by
						roping in CRM development company with new IT environment. Go for a highly strategic
						imperative option that serves as a viable option to extend automation for even your
						customer relationship. Adopt a viable data-driven CRM software solution making way for
						intelligent reporting and analytics that helps taking data-backed decisions. Take a
						modern approach to customer management for building a better future with a continuous
						business opportunity. Opt for CRM software solutions to integrate multiple customer
						touchpoints that adds up to build loyalty resulting in higher satisfaction and long-term
						business partnerships.
					</p>
					<p>
						CRM developers at Genixbit are can design CMS adaptable to small businesses and big
						corporations alike. The success of CRM lies in making the most out of the best available
						customer-facing resources that aids planning, analysing, developing and deploying
						targeted sales and marketing activities. .
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
