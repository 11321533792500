<template>
	<div class="similar-app-option3-main inst__simila_chat">
		<div class="col-lg-6 d-none d-lg-block p-0 img-col fixedimage">
			<img
				src="../../../assets/images/similar-app-main1.png"
				alt=""
				class="img1 d-none d-lg-block"
			/>
			<img
				src="../../../assets/images/similar-app-main2.png"
				alt=""
				class="img2 d-none d-lg-block"
			/>
			<img src="../../../assets/images/similar-app-main.png" alt="" class="d-lg-none" />
		</div>
		<div class="col-lg-6 col-md-12 col-sm-12 col-12 p-0 content-col">
			<div class="content-box">
				<div class="content-logo-section d-flex align-items-center">
					<div class="content-logo-name">
						<span class="mainname" style="color: #fff"
							>How an instant messaging app build personal bonding</span
						>
					</div>
				</div>
				<div class="content-description">
					<p style="color: #fff">
						Social media messaging is too noisy. Instant messaging is too limited in features. Our
						client felt the need to build an instant messaging app that had the personalized feel of
						a social media app and the feature-richness of a full-stack communication app. They
						asked and we delivered.
					</p>
					<p style="color: #fff">
						GenixBit Labs provided an instant messaging app development for real-time communication.
						The app allowed users to search and add friends, send and receive multimedia files,
						create personalized one-to-one chats or group chats, customize chat backgrounds and much
						more.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Build Your Messaging App Now</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
.portfolio-slider {
	padding: 0 !important;
}
.portfolio-slider button {
	display: none;
}
.similar-app-option3-main {
	position: relative;
	display: flex;
	padding: 0;
	margin: 0;
	align-items: center;
	background-color: #f8f9fa;
	min-height: 100vh;
	min-width: 100vw;
}
.similar-app-option3-main .feature-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.similar-app-option3-main .feature-list li {
	flex: 50%;
	margin-top: 30px;
}
.similar-app-option3-main .content-col {
	padding: 50px !important;
}
.similar-app-option3-main > div {
	flex-basis: 50%;
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.m-0 {
	margin: 0 !important;
}
.dattingbg {
	background: -moz-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -webkit-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -o-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -ms-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
}
.strongmindedbg {
	background-color: #0e0e0e;
}
.claireabellabg {
	background-color: #e42ba1;
}
.guestmebg {
	background-color: #ff0a00;
}
.idtrackbg {
	background-color: #349943;
}
.awaybackbg {
	background-color: #ef6c3d;
}
.chatagrambg {
	background-color: #ec7575;
}
.similar-app-option3-main .content-logo-name .mainname {
	font-size: 2.4rem;
	display: block;
}
.similar-app-option3-main .content-logo-name .subname {
	font-size: 1.2rem;
	color: #fff;
}

.similar-app-option3-main .content-logo-section {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	gap: 30px;
}
.similar-app-option3-main .content-logo {
	width: 100px;
	height: 100px;
	border-radius: 20px;
	overflow: hidden;
}
.similar-app-option3-main .fixedimage {
	max-width: 650px;
	height: 100vh;
	position: relative;
}

.similar-app-option3-main .fixedimage .img1 {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(0.9);
	position: absolute;
	left: 40px;
	top: 20px;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.slick-slide img {
	display: block;
}
.d-none {
	display: none !important;
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
}
@media (min-width: 992px) {
	.d-lg-block {
		display: block !important;
	}
}

img {
	max-width: 100%;
	height: auto;
}
.similar-app-option3-main .fixedimage .img2 {
	position: absolute;
	left: 35%;
	top: 10px;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.fixedimage:hover .img1 {
	top: 10px;
}
.fixedimage:hover .img2 {
	top: 20px;
}
.inst__simila_chat::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(to right, #006c8d, #1a314be8);
	opacity: 0.6;
}
.inst__simila_chat {
	position: relative;
	background: url('../../../assets/images/similar-app-background.jpg') 0% 0% / cover no-repeat;
}
</style>