<template>
	<section class="brand-logo">
		<div class="container" style="background-color: transparent">
			<div class="brands_logo d-flex flex-wrap align-items-center">
				<div>
					<img
						src="../../assets/images/Microsoft.png"
						alt="Virtual reality application development is done for Microsoft, to help them with Microsoft art of work project"
						width="188"
						height="94"
					/>
				</div>
				<div>
					<img
						src="../../assets/images/Savvy.png"
						alt="Genixbit provided on demand taxi booking application development solution for Savvy"
						width="188"
						height="94"
					/>
				</div>
				<div>
					<img
						src="../../assets/images/Zinc-Media.png"
						alt="Zinc Media is a mobile app development vendor of Genixbit,  We provide mobile app development solutions for their end clients"
						width="188"
						height="94"
					/>
				</div>
				<div>
					<img
						src="../../assets/images/itv-colour-picked-neg.png"
						alt="Genixbit developed an escape room web application for ITV."
						width="188"
						height="94"
					/>
				</div>
				<div>
					<img
						src="../../assets/images/Toxic-Fox.png"
						alt="Custom emoji mobile application development for UK based e-commerce platform Toxicfox"
						width="188"
						height="94"
					/>
				</div>

				<!-- <div>
           <img data-src="https://www.Genixbit.com/assets/frameworks/public/images/brand-logo/CluedUpp.png" alt="CluedUpp" width="188">
        </div> -->
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.brand-logo {
	margin-top: -10vh;
}

@media (min-width:1200px) {
	.brands_logo {
		justify-content:space-between;
		padding: 10px 20px !important;
	}
}
@media (max-width:1199px) {
	.brands_logo {
		justify-content:center;
	}
}

@media (max-width: 776px) {
	.brand-logo {
		margin-top: -3vh !important;
	}
	.brands_logo img {
		max-width: 100px;
	}
}
</style>
