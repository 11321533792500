<template>
	<section class="padding-60 bg-light what-we-offer-main">
		<div class="container">
			<div class="title">
				<span>Our web development offerings</span>
				<h2>
					Explore our web development expertise that encompasses every corners of the digital web
				</h2>
			</div>
		</div>
		<div class="what-we-offer-main-tabs">
			<div
				class="what-we-offer-main-slider-section bg-light d-none d-lg-block top-sticy"
				style="z-index: auto; position: static; top: auto"
			>
				<div class="container">
					<div class="what-we-offer-main-slider what-we-offer-main-item">
						<div class="what-we-offer-main-item-box">
							<a href="#section1" id="section1-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													class="st0"
													d="M100,56.7V8.3c0-4.6-3.7-8.3-8.3-8.3H18.3C13.7,0,10,3.7,10,8.3v33.3H5c-2.8,0-5,2.2-5,5V95c0,2.8,2.2,5,5,5
                              h35c2.8,0,5-2.2,5-5V85h5v10c0,2.8,2.2,5,5,5h40c2.8,0,5-2.2,5-5V76.7c0-2.8-2.2-5-5-5H74.5L72.3,65h19.4
                              C96.3,65,100,61.3,100,56.7z M41.7,95c0,0.9-0.7,1.7-1.7,1.7H5c-0.9,0-1.7-0.7-1.7-1.7v-3.3h38.3V95z M41.7,88.3H3.3v-35h38.3
                              V88.3z M41.7,50H3.3v-3.3C3.3,45.7,4.1,45,5,45h35c0.9,0,1.7,0.7,1.7,1.7V50z M40,41.7H13.3V8.3c0-2.8,2.2-5,5-5h73.3
                              c2.8,0,5,2.2,5,5V50H45v-3.3C45,43.9,42.8,41.7,40,41.7z M50,81.7h-5v-3.3h5V81.7z M50.3,75H45V65h23.8l2.2,6.7H55
                              C52.8,71.7,51,73.1,50.3,75z M56.7,96.7H55c-0.9,0-1.7-0.7-1.7-1.7V76.7c0-0.9,0.7-1.7,1.7-1.7h1.7V96.7z M93.3,75H95
                              c0.9,0,1.7,0.7,1.7,1.7V95c0,0.9-0.7,1.7-1.7,1.7h-1.7V75z M90,75v21.7H60V75H90z M45,61.7v-8.3h51.7v3.3c0,2.8-2.2,5-5,5H45z
                              M55.5,13.8l-20,20l2.4,2.4l20-20L55.5,13.8z M57.8,36.2l20-20l-2.4-2.4l-20,20L57.8,36.2z M24.5,62.8l-2.4-2.4L8.8,73.8l2.4,2.4
                              L24.5,62.8z M21.2,79.5l13.3-13.3l-2.4-2.4L18.8,77.2L21.2,79.5z M76.2,81.2l-2.4-2.4l-10,10l2.4,2.4L76.2,81.2z M76.2,91.2l10-10
                              l-2.4-2.4l-10,10L76.2,91.2z M93.3,43.3H90v3.3h3.3V43.3z M86.7,43.3h-3.3v3.3h3.3V43.3z M76.7,46.7H80v-3.3h-3.3V46.7z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Custom Web Development</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section2" id="section2-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													class="st0"
													d="M95.8,0H4.2C1.8,0,0,1.8,0,4.2v91.5c0,2.4,1.8,4.2,4.2,4.2h91.5c2.3,0,4.2-1.8,4.2-4.2V4.2
                              C100,1.8,98.2,0,95.8,0z M97,95.8c0,0.6-0.6,1.2-1.2,1.2H4.2C3.6,97,3,96.4,3,95.8V23.9H97V95.8z M97,20.9H3V4.2
                              C3,3.6,3.6,3,4.2,3h91.5C96.4,3,97,3.6,97,4.2V20.9z M12.7,5.6c-3.5,0-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4s6.4-2.9,6.4-6.4
                              C19.1,8.5,16.2,5.6,12.7,5.6z M12.7,15.1c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3
                              C16.1,13.6,14.5,15.1,12.7,15.1z M28,5.6c-3.5,0-6.4,2.9-6.4,6.4c0,3.5,2.9,6.4,6.4,6.4c3.5,0,6.4-2.9,6.4-6.4
                              C34.4,8.5,31.5,5.6,28,5.6z M28,15.1c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3
                              C31.4,13.6,29.8,15.1,28,15.1z M43.5,5.6c-3.5,0-6.4,2.9-6.4,6.4c0,3.5,2.7,6.4,6.4,6.4c3.5,0,6.4-2.9,6.4-6.4
                              C49.8,8.5,47,5.6,43.5,5.6z M43.5,15.1c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.4-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3
                              C46.8,13.6,45.3,15.1,43.5,15.1z M91.7,6.1H55c-0.9,0-1.5,0.6-1.5,1.5v8.5c0,0.9,0.6,1.7,1.5,1.7h36.7c0.9,0,1.5-0.6,1.5-1.5V7.6
                              C93.2,6.7,92.6,6.1,91.7,6.1z M90.2,14.5H56.5V9.1h33.6V14.5z M12.1,91.8h27.1c1.7,0,3-0.6,4.2-1.8c1.2-1.2,1.8-3,1.8-4.8
                              l-1.5-29.1c-0.3-0.8-0.9-1.4-1.8-1.4h-4.5V52c0-7.4-5.5-13.3-12-13.3c-6.5,0-12,6.1-12,13.3v2.7H8.9c-0.8,0-1.5,0.6-1.5,1.4
                              L6.1,85.2C5.9,87,6.7,88.8,7.9,90C8.9,91.2,10.5,91.8,12.1,91.8z M16.7,52c0-5.8,3.9-10.3,8.9-10.3c4.8,0,8.9,4.5,8.9,10.3v2.7
                              H16.7V52z M10.6,57.7h3v2.9H13c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h4.5c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5
                              h-0.9v-2.9h17.7v2.9h-0.8c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h4.5c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5h-0.8
                              v-2.9h3L42,85.3c0.2,1.1-0.2,2-0.8,2.7c-0.6,0.6-1.2,0.9-2,0.9H12.1c-0.8,0-1.5-0.3-2-0.9c-0.8-0.8-1.1-1.7-1.1-2.7L10.6,57.7z
                               M15.2,74.8h20.8c0.9,0,1.5-0.6,1.5-1.5c0.2-0.9-0.6-1.5-1.4-1.5H15.2c-0.9,0-1.5,0.6-1.5,1.5C13.6,74.2,14.2,74.8,15.2,74.8z
                               M15.2,83.3h20.8c0.9,0,1.5-0.6,1.5-1.5c0.2-0.9-0.6-1.5-1.4-1.5H15.2c-0.9,0-1.5,0.6-1.5,1.5C13.6,82.7,14.2,83.3,15.2,83.3z
                               M49.7,61.1c0.2,0.6,0.8,1.1,1.5,1.1h30.2c0.8,0,1.4-0.5,1.5-1.1l8-31.2h2.9c0.9,0,1.5-0.6,1.5-1.5c0-0.8-0.8-1.5-1.5-1.5h-3.9
                              c-0.8,0-1.4,0.5-1.5,1.1l-3.9,15H46.8c-1.1,0-1.7,1.1-1.5,2L49.7,61.1z M83.6,46.1l-3.5,13.2H52.4l-3.6-13.2H83.6z M63.6,68.8
                              c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,2.7,2.3,5,5,5C61.5,73.8,63.6,71.5,63.6,68.8z M56.8,68.8c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2
                              s-0.9,2-2,2C57.7,70.8,56.8,69.8,56.8,68.8z M81.7,68.8c0-2.7-2.3-5-5-5c-2.7,0-5,2.3-5,5c0,2.7,2.3,5,5,5
                              C79.4,73.8,81.7,71.5,81.7,68.8z M74.8,68.8c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2s-0.9,2-2,2C75.6,70.8,74.8,69.8,74.8,68.8z
                               M90.6,78.5H51.2c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h39.4c0.9,0,1.5-0.6,1.5-1.5C92.1,79.1,91.4,78.5,90.6,78.5z
                               M90.6,88H51.2c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5h39.4c0.9,0,1.5-0.6,1.5-1.5C92.1,88.6,91.4,88,90.6,88z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">eCommerce Website</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section3" id="section3-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													class="st0"
													d="M51.7,83.3h-3.3v3.3h3.3V83.3z M98.3,86.7h-1.7v-60c0-3.7-3-6.7-6.7-6.7H80V6.7C80,3,77,0,73.3,0H26.7
                              C23,0,20,3,20,6.7V20H10c-3.7,0-6.7,3-6.7,6.7v60H1.7c-0.9,0-1.7,0.7-1.7,1.7v5C0,97,3,100,6.7,100h86.7c3.7,0,6.7-3,6.7-6.7v-5
                              C100,87.4,99.3,86.7,98.3,86.7z M23.3,6.7c0-1.8,1.5-3.3,3.3-3.3h46.7c1.8,0,3.3,1.5,3.3,3.3v6.7H23.3V6.7z M23.3,16.7h53.3V65
                              c0,1.8-1.5,3.3-3.3,3.3H26.7c-1.8,0-3.3-1.5-3.3-3.3V16.7z M26.7,71.7h46.7c3.7,0,6.7-3,6.7-6.7V30h6.7v46.7H13.3V30H20v35
                              C20,68.7,23,71.7,26.7,71.7z M6.7,26.7c0-1.8,1.5-3.3,3.3-3.3h10v3.3h-8.3c-0.9,0-1.7,0.7-1.7,1.7v50c0,0.9,0.7,1.7,1.7,1.7h76.7
                              c0.9,0,1.7-0.7,1.7-1.7v-50c0-0.9-0.7-1.7-1.7-1.7H80v-3.3h10c1.8,0,3.3,1.5,3.3,3.3v60H65c-0.4,0-0.9,0.2-1.2,0.5L61,90H39
                              l-2.8-2.8c-0.3-0.3-0.7-0.5-1.2-0.5H6.7V26.7z M96.7,93.3c0,1.8-1.5,3.3-3.3,3.3H6.7c-1.8,0-3.3-1.5-3.3-3.3V90h31l2.8,2.8
                              c0.3,0.3,0.7,0.5,1.2,0.5h23.3c0.4,0,0.9-0.2,1.2-0.5l2.8-2.8h31V93.3z M71.7,53.3H28.3v3.3h43.3V53.3z M68.3,60h-40v3.3h40V60z
                              M38.3,50h33.3v-3.3H38.3V50z M26.7,10H30V6.7h-3.3V10z M33.3,10h3.3V6.7h-3.3V10z M40,10h3.3V6.7H40V10z M35,46.7h-3.3V50H35
                              V46.7z M35.7,33l6.7,5l2-2.7l-4.9-3.7l4.9-3.7l-2-2.7l-6.7,5c-0.7,0.6-0.9,1.6-0.3,2.3C35.4,32.8,35.5,32.9,35.7,33z M57.7,38
                              l6.7-5c0.7-0.6,0.9-1.6,0.3-2.3c-0.1-0.1-0.2-0.2-0.3-0.3l-6.7-5l-2,2.7l4.9,3.7l-4.9,3.7L57.7,38z M54.9,24l-3.1-1.2l-6.7,16.7
                              l3.1,1.2L54.9,24z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Web Portal Development</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section4" id="section4-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<path
											class="st0"
											d="M61.8,39.4c0-4-5.9-6.1-11.8-6.1c-5.9,0-11.8,2.1-11.8,6.1v21.1c0,3.8,5.9,5.8,11.8,5.8c5.9,0,11.8-2,11.8-5.8
                        V39.4z M41.2,43.5c2.8,1.3,5.8,2,8.8,1.9c3,0.1,6.1-0.6,8.8-1.9v7.1c-0.9,1-3.9,2.3-8.8,2.3c-4.9,0-8-1.4-8.8-2.4V43.5z M50,36.4
                        c5.5,0,8.8,2,8.8,3c0,1.1-3.4,3-8.8,3s-8.8-2-8.8-3C41.2,38.3,44.5,36.4,50,36.4z M50,63.2c-5.7,0-8.8-1.9-8.8-2.7v-6.3
                        c2.8,1.2,5.8,1.9,8.8,1.8c3,0.1,6-0.5,8.8-1.7v6.2C58.8,61.3,55.7,63.2,50,63.2z M69.1,0H30.9c-4.9,0-8.8,4.1-8.8,9.1v81.8
                        c0,5,4,9.1,8.8,9.1h38.2c4.9,0,8.8-4.1,8.8-9.1V9.1C77.9,4.1,74,0,69.1,0z M75,90.9c0,3.3-2.6,6.1-5.9,6.1H30.9
                        c-3.2,0-5.9-2.7-5.9-6.1V9.1C25,5.7,27.6,3,30.9,3h38.2C72.4,3,75,5.7,75,9.1V90.9z M58.8,92.4c0,0.8-0.7,1.5-1.5,1.5H42.6
                        c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h14.7C58.2,90.9,58.8,91.6,58.8,92.4z M66.2,6.1H33.8c-3.2,0-5.9,2.7-5.9,6.1v69.7
                        c0,3.3,2.6,6.1,5.9,6.1h32.4c3.2,0,5.9-2.7,5.9-6.1V12.1C72.1,8.8,69.4,6.1,66.2,6.1z M69.1,81.8c0,1.7-1.3,3-2.9,3H33.8
                        c-1.6,0-2.9-1.4-2.9-3V12.1c0-1.7,1.3-3,2.9-3h32.4c1.6,0,2.9,1.4,2.9,3V81.8z"
										></path>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">Mobile Backend &amp; APIs</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section5" id="section5-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										version="1.1"
										xmlns="http://www.w3.org/2000/svg"
										xmlns:xlink="http://www.w3.org/1999/xlink"
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													class="st0"
													d="M8.2,32l4.1,0.6c0.5,1.7,1.1,3.3,2,4.8l-2.6,3.3c-0.5,0.6-0.5,1.5,0.2,2l3.5,3.5c0.6,0.6,1.4,0.6,2,0.1
                              l3.3-2.6c1.5,0.9,3,1.5,4.8,2l0.6,4.1c0.2,0.8,0.8,1.4,1.5,1.4h4.8c0.8,0,1.4-0.6,1.5-1.4l0.6-4.1c1.7-0.5,3.3-1.1,4.8-2l3.2,2.4
                              c0.6,0.5,1.7,0.3,2.1-0.2l3.3-3.3c0.6-0.6,0.6-1.4,0.2-2l-2.6-3.3c0.9-1.5,1.5-3.2,2-4.8l4.1-0.6c0.8-0.2,1.4-0.8,1.4-1.5v-4.8
                              c0-0.8-0.6-1.4-1.4-1.5l-4.1-0.6c-0.5-1.7-1.1-3.3-2-4.9l2.4-3.3c0.5-0.6,0.5-1.5-0.2-2.1l-3.3-3.3c-0.6-0.5-1.5-0.6-2.1-0.1
                              l-3.3,2.6c-1.5-0.9-3-1.5-4.8-2l-0.5-4.2c-0.2-0.8-0.8-1.4-1.5-1.4h-4.8c-0.8,0-1.4,0.6-1.5,1.4l-0.6,4.1c-1.7,0.5-3.3,1.1-4.8,2
                              l-3.3-2.6c-0.6-0.5-1.5-0.5-2,0.2L12,13.3c-0.6,0.6-0.6,1.4-0.2,2l2.6,3.3c-0.9,1.5-1.5,3.2-2,4.9l-4.1,0.6
                              C7.6,24.2,7,24.8,7,25.6v4.8C7,31.2,7.4,31.8,8.2,32z M13.8,26.4c0.6-0.2,1.2-0.6,1.2-1.2c0.5-2.1,1.2-3.9,2.3-5.8
                              c0.3-0.6,0.3-1.2,0-1.8l-2.4-3.2l1.5-1.5l3,2.3c0.5,0.5,1.4,0.5,1.8,0c1.7-1.2,3.6-2,5.6-2.3c0.6-0.2,1.1-0.6,1.2-1.4l0.8-3.6h2.3
                              l0.5,3.9c0.2,0.6,0.6,1.2,1.2,1.4c2.1,0.5,3.9,1.2,5.6,2.4c0.6,0.3,1.2,0.3,1.8,0l3-2.4l1.5,1.5l-2.3,3c-0.5,0.5-0.5,1.2,0,1.8
                              c1.2,1.7,2,3.6,2.3,5.8c0.2,0.6,0.6,1.1,1.2,1.2l3.8,0.5v2.3l-3.8,0.5c-0.6,0.1-1.2,0.6-1.2,1.2c-0.5,2.1-1.2,3.9-2.3,5.8
                              c-0.3,0.6-0.3,1.2,0,1.8l2.4,3.2l-1.5,1.5l-3-2.4c-0.5-0.5-1.2-0.5-1.8,0c-1.7,1.2-3.6,2-5.6,2.4c-0.6,0.2-1.1,0.6-1.2,1.4
                              l-0.5,3.9h-2.4l-0.5-3.9c-0.2-0.6-0.6-1.2-1.2-1.4c-2.1-0.5-3.9-1.2-5.6-2.4c-0.6-0.3-1.2-0.3-1.8,0l-3,2.4l-1.5-1.5l2.3-3
                              c0.5-0.5,0.5-1.4,0-1.8c-1.2-1.7-2-3.6-2.3-5.6C15,30.6,14.6,30.2,14,30l-3.8-0.5v-2.7H10L13.8,26.4z M30,38.2
                              c5.6,0,10.1-4.5,10.1-10.2c0-5.6-4.5-10.2-10.1-10.2c-5.6,0-10.1,4.5-10.1,10.2C19.9,33.6,24.4,38.2,30,38.2z M30,20.9
                              c3.9,0,7.1,3.2,7.1,7.1c0,3.9-3.2,7.1-7.1,7.1S22.9,32,22.9,28C22.9,24.1,26.1,20.9,30,20.9z M39.4,82.9c0.8-1.2,1.2-2.6,1.7-3.9
                              l3.3-0.5c0.8-0.2,1.4-0.8,1.4-1.5v-4.1c0-0.8-0.6-1.4-1.4-1.5l-3.5-0.5c-0.3-1.4-0.9-2.7-1.7-3.9l2-2.7c0.5-0.6,0.5-1.5-0.2-2.1
                              l-2.9-2.9c-0.6-0.6-1.4-0.6-2-0.2l-2.7,2.1c-1.2-0.8-2.6-1.2-3.9-1.7l-0.5-3.3c-0.2-0.8-0.8-1.4-1.5-1.4h-4.1
                              c-0.8,0-1.4,0.6-1.5,1.4l-0.5,3.3c-1.4,0.3-2.7,0.9-3.8,1.7L15,59.1c-0.6-0.5-1.5-0.5-2,0.2l-2.9,2.9c-0.6,0.6-0.6,1.4-0.2,2
                              l2.1,2.7c-0.6,1.4-1.1,2.7-1.5,4.1l-3.3,0.5c-0.8,0.2-1.4,0.8-1.4,1.5V77c0,0.8,0.6,1.4,1.4,1.5l3.3,0.5c0.3,1.4,0.9,2.7,1.7,3.9
                              l-2.1,2.7c-0.5,0.6-0.5,1.5,0.2,2l2.9,2.9c0.6,0.6,1.4,0.6,2,0.1l2.7-2.1c1.2,0.8,2.6,1.2,3.9,1.7l0.5,3.3
                              c0.2,0.8,0.8,1.4,1.5,1.4h4.1c0.8,0,1.4-0.6,1.5-1.4l0.5-3.3c1.4-0.3,2.7-0.9,3.9-1.7l2.6,2c0.3,0.1,0.6,0.3,0.9,0.3
                              c0.5,0,0.9-0.2,1.1-0.5l2.9-2.9c0.6-0.6,0.6-1.4,0.2-2L39.4,82.9z M38.2,77.3c-0.3,1.7-1.1,3.3-2,4.8c-0.3,0.6-0.3,1.2,0,1.8
                              l1.8,2.4L37,87.4l-2.4-1.8c-0.5-0.5-1.2-0.5-1.8,0c-1.4,0.9-3,1.7-4.7,2c-0.6,0.2-1.1,0.6-1.2,1.4l-0.5,3.2h-1.5l-0.5-3.2
                              c-0.2-0.6-0.6-1.2-1.2-1.4c-1.7-0.3-3.3-1.1-4.7-2c-0.6-0.3-1.2-0.3-1.8,0l-2.4,1.8l-0.9-1.1l1.8-2.4c0.5-0.5,0.5-1.4,0-1.8
                              c-0.9-1.4-1.7-3-2-4.7c-0.2-0.6-0.6-1.1-1.2-1.2l-3-0.5v-1.5l3-0.5c0.6-0.2,1.2-0.6,1.2-1.2c0.3-1.7,1.1-3.3,2-4.9
                              c0.3-0.6,0.3-1.2,0-1.8l-1.8-2.4l1.1-1.1l2.4,1.8c0.5,0.5,1.4,0.5,1.8,0c1.4-0.9,3-1.7,4.7-2c0.6-0.2,1.1-0.6,1.2-1.4l0.5-3.2h1.5
                              l0.5,3.2c0.2,0.6,0.6,1.2,1.2,1.4c1.7,0.3,3.3,1.1,4.7,2c0.6,0.3,1.2,0.3,1.8,0l2.4-1.8l1.1,1.1l-1.8,2.4c-0.5,0.5-0.5,1.2,0,1.8
                              c0.9,1.5,1.7,3,2,4.9c0.2,0.6,0.6,1.1,1.2,1.2l3,0.5v1.5l-3,0.5C38.8,76.2,38.3,76.7,38.2,77.3z M25.8,66.1C20.9,66.1,17,70,17,75
                              c0,4.8,3.9,8.9,8.8,8.9c4.8,0,8.8-3.9,8.8-8.9S30.6,66.1,25.8,66.1z M25.8,80.8c-3.2,0-5.8-2.6-5.8-5.9c0-3.2,2.6-5.9,5.8-5.9
                              c3.2,0,5.8,2.6,5.8,5.9C31.5,78.2,28.9,80.8,25.8,80.8z M97.3,30.9c-2.9-2.4-7.1-2.6-10.1,0v-1.4c0-0.3-0.2-0.8-0.5-1.1L60,0.5
                              C59.7,0.1,59.2,0,58.9,0H1.5C0.6,0,0,0.6,0,1.5v97c0,0.9,0.6,1.5,1.5,1.5h84.1c0.9,0,1.5-0.6,1.5-1.5V54.7l5.9-6.8l5-5.9
                              C100.9,38.6,100.6,33.8,97.3,30.9z M60.5,5.3L82.1,28H60.5V5.3z M84.1,97H3V3h54.2v26.5c0,0.9,0.6,1.5,1.5,1.5h25.3v3.2L50.3,73.9
                              L50,74.4l-7.9,18.8c-0.3,0.6-0.2,1.4,0.5,1.7c0.5,0.5,1.2,0.5,1.8,0.2l17.3-10.6l0.3-0.3l22.1-25.8V97z M52,77.4l6.4,5.5L46.8,90
                              L52,77.4z M60.8,81.1l-7.3-6.2l28.9-33.9l7.3,6.2L60.8,81.1z M95.7,40l-4.1,4.7l-7.3-6.2l4.1-4.9c1.7-2,4.8-2.1,6.7-0.5
                              C97.3,35,97.6,38,95.7,40z"
												></path>
											</g>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">CMS</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section6" id="section6-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M47.2,46.4h-0.5V28c0-1-0.8-1.7-1.7-1.7H19.4c-1,0-1.7,0.8-1.7,1.7v18.4h-0.5c-0.6,0-1.1,0.5-1.1,1.1v0.6
                              c0,0.6,0.5,1.1,1.1,1.1h30c0.6,0,1.1-0.5,1.1-1.1v-0.6C48.3,46.9,47.8,46.4,47.2,46.4z M18.6,28c0-0.4,0.4-0.8,0.8-0.8H45
                              c0.4,0,0.8,0.4,0.8,0.8v18.4H43v-0.9H44c0.4,0,0.8-0.4,0.8-0.8V28.9c0-0.4-0.4-0.8-0.8-0.8H20.4c-0.4,0-0.8,0.4-0.8,0.8v3.2
                              c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-3h23.4v15.5H43V32.5c0-0.8-0.6-1.4-1.4-1.4h-8.5c-0.8,0-1.4,0.6-1.4,1.4v12.1H20.5V34.3
                              c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v10.4c0,0.4,0.4,0.8,0.8,0.8h11.3v0.9h-13V28z M42,32.9h-9.4v-0.5c0-0.3,0.2-0.5,0.5-0.5
                              h8.5c0.3,0,0.5,0.2,0.5,0.5V32.9z M17.1,48.2v-0.6c0-0.1,0.1-0.2,0.2-0.2h14.4v0.5c0,0.2,0,0.3,0.1,0.5H17.2
                              C17.1,48.3,17.1,48.2,17.1,48.2z M33.1,48.3c-0.3,0-0.5-0.2-0.5-0.5v-14H42v12.6h-7.7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H42
                              v0.5c0,0.3-0.2,0.5-0.5,0.5H33.1z M47.3,48.2c0,0.1-0.1,0.2-0.2,0.2h-4.3c0.1-0.1,0.1-0.3,0.1-0.5v-0.5h4.2c0.1,0,0.2,0.1,0.2,0.2
                              V48.2z"
												></path>
												<path
													d="M36.4,41.2h1.8c0.6,0,1.1-0.5,1.1-1.1v-1.8c0-0.6-0.5-1.1-1.1-1.1h-1.8c-0.6,0-1.1,0.5-1.1,1.1v1.8
                              C35.3,40.7,35.8,41.2,36.4,41.2z M36.2,38.3c0-0.1,0.1-0.2,0.2-0.2h1.8c0.1,0,0.2,0.1,0.2,0.2v1.8c0,0.1-0.1,0.2-0.2,0.2h-1.8
                              c-0.1,0-0.2-0.1-0.2-0.2V38.3z"
												></path>
												<path
													d="M35.7,43.1h3.1c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-3.1c-0.3,0-0.5,0.2-0.5,0.5S35.4,43.1,35.7,43.1z"
												></path>
												<path
													d="M23,36l1.6,0.9c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.3,0.3-0.5v-0.4h3.6c0.3,0,0.5-0.2,0.5-0.5
                              S29.3,35,29.1,35h-3.6v-0.4c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.1-0.6,0L23,35c-0.2,0.1-0.3,0.3-0.3,0.5
                              C22.7,35.7,22.8,35.9,23,36z"
												></path>
												<path
													d="M29.4,39l-1.6-0.9c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.3,0.3-0.3,0.5v0.4h-3.6c-0.3,0-0.5,0.2-0.5,0.5S23,40,23.3,40h3.6
                              v0.4c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1h0l1.6-0.9c0.2-0.1,0.3-0.3,0.3-0.5
                              C29.7,39.3,29.5,39.1,29.4,39z"
												></path>
											</g>
											<g>
												<g>
													<path
														d="M19.2,20c-0.3-0.2-0.5-0.4-0.8-0.6c0.1-0.6,0.2-1.2,0.2-1.8c0.3-0.1,0.6-0.3,0.9-0.4c0.5-0.2,0.8-0.8,0.6-1.4
                                 c0,0,0,0,0,0l-0.4-1.6c-0.2-0.5-0.7-0.9-1.2-0.8c-0.3,0-0.7,0.1-1,0.1c-0.3-0.5-0.7-1-1.1-1.4c0.1-0.3,0.3-0.6,0.4-0.9
                                 c0.2-0.5,0-1.1-0.5-1.4L14.9,9c-0.5-0.3-1.1-0.2-1.5,0.3c-0.2,0.3-0.4,0.5-0.6,0.8C12.2,10,11.6,9.9,11,9.9
                                 c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.5-0.8-0.8-1.4-0.6L7.7,8.8C7.2,8.9,6.8,9.4,6.9,10c0,0.3,0.1,0.7,0.1,1c-0.5,0.3-1,0.7-1.4,1.1
                                 C5.3,12,5,11.8,4.7,11.7c-0.5-0.2-1.1,0-1.4,0.5l-0.8,1.4c-0.3,0.5-0.2,1.1,0.3,1.5c0.3,0.2,0.5,0.4,0.8,0.6
                                 c-0.1,0.6-0.2,1.2-0.2,1.8c-0.3,0.1-0.6,0.3-0.9,0.4c-0.5,0.2-0.8,0.8-0.6,1.4l0.4,1.6c0.2,0.5,0.7,0.9,1.2,0.8
                                 c0.3,0,0.7-0.1,1-0.1c0.3,0.5,0.7,1,1.1,1.4c-0.1,0.3-0.3,0.6-0.4,0.9c-0.2,0.5,0,1.1,0.5,1.4l1.4,0.8c0.1,0.1,0.3,0.1,0.4,0.1
                                 c0.4,0,0.8-0.1,1-0.4C8.8,25.5,9,25.3,9.2,25c0.6,0.1,1.2,0.2,1.8,0.2c0.1,0.3,0.3,0.6,0.4,0.9c0.2,0.5,0.8,0.8,1.4,0.6l1.6-0.4
                                 c0.5-0.2,0.9-0.7,0.8-1.2c0-0.3-0.1-0.7-0.1-1c0.5-0.3,1-0.7,1.4-1.1c0.3,0.1,0.6,0.3,0.9,0.4c0.5,0.2,1.1,0,1.4-0.5l0.8-1.4
                                 C19.8,21,19.7,20.3,19.2,20z M17.7,22.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.4-0.2-0.9-0.1-1.2,0.3c-0.4,0.4-0.8,0.7-1.2,1
                                 c-0.4,0.2-0.6,0.7-0.5,1.1c0,0.3,0.1,0.6,0.1,1l-1.5,0.4c-0.1-0.3-0.3-0.6-0.4-0.9C11.9,24.3,11.4,24,11,24
                                 c-0.5,0-1.1-0.1-1.6-0.2c-0.4-0.1-0.9,0.1-1.2,0.4C8,24.5,7.8,24.7,7.6,25l-1.3-0.7c0.1-0.3,0.2-0.6,0.4-0.9
                                 c0.2-0.4,0.1-0.9-0.3-1.2c-0.4-0.4-0.7-0.8-1-1.2c-0.2-0.4-0.7-0.6-1.1-0.5c-0.3,0-0.6,0.1-1,0.1L2.9,19c0.3-0.1,0.6-0.3,0.9-0.4
                                 c0.4-0.2,0.7-0.6,0.7-1.1c0-0.5,0.1-1.1,0.2-1.6c0.1-0.4-0.1-0.9-0.4-1.2c-0.3-0.2-0.5-0.4-0.8-0.6l0.7-1.3
                                 c0.3,0.1,0.6,0.2,0.9,0.4c0.4,0.2,0.9,0.1,1.2-0.3c0.4-0.4,0.8-0.7,1.2-1c0.4-0.2,0.6-0.7,0.5-1.1c0-0.3-0.1-0.6-0.1-1l1.5-0.4
                                 c0.1,0.3,0.3,0.6,0.4,0.9c0.2,0.4,0.6,0.7,1.1,0.7c0.5,0,1.1,0.1,1.6,0.2c0.4,0.1,0.9-0.1,1.2-0.4c0.2-0.3,0.4-0.5,0.6-0.8
                                 l1.3,0.7c-0.1,0.3-0.2,0.6-0.4,0.9c-0.2,0.4-0.1,0.9,0.3,1.2c0.4,0.4,0.7,0.8,1,1.2c0.2,0.4,0.7,0.6,1.1,0.5c0.3,0,0.6-0.1,1-0.1
                                 l0.4,1.5c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.7,0.6-0.7,1.1c0,0.5-0.1,1.1-0.2,1.6c-0.1,0.4,0.1,0.9,0.4,1.2
                                 c0.3,0.2,0.5,0.4,0.8,0.6L17.7,22.2z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M15,16.4C15,16.4,15,16.4,15,16.4c-0.6-2.2-2.9-3.5-5.1-2.9c-2.2,0.6-3.5,2.9-2.9,5.1c0.3,1.1,1,2,2,2.5
                                 c0.5,0.3,1,0.4,1.5,0.5c0.5,0.1,1.1,0,1.6-0.1C14.3,20.9,15.6,18.6,15,16.4z M11.8,20.4c-0.8,0.2-1.6,0.1-2.2-0.3
                                 c-0.7-0.4-1.2-1-1.4-1.8c-0.4-1.6,0.5-3.2,2-3.6c0.4-0.1,0.8-0.1,1.1-0.1c1.1,0.1,2.2,1,2.5,2.1C14.2,18.3,13.3,20,11.8,20.4z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M29.8,6.9c-0.2-0.1-0.4-0.1-0.6-0.2C29.1,6.4,29,6,28.9,5.6c0.2-0.2,0.3-0.3,0.5-0.5c0.3-0.3,0.4-0.9,0.1-1.3L28.7,3
                                 c-0.3-0.4-0.8-0.5-1.2-0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3-0.2-0.6-0.4-1-0.6c0-0.2,0-0.4,0-0.7c0-0.5-0.4-0.9-0.8-0.9l-1.2-0.1
                                 c-0.5-0.1-0.9,0.2-1.1,0.7c-0.1,0.2-0.1,0.4-0.2,0.6c-0.4,0.1-0.7,0.2-1.1,0.3c-0.2-0.2-0.3-0.3-0.5-0.5
                                 c-0.3-0.3-0.9-0.4-1.3-0.1l-0.9,0.7c-0.4,0.3-0.5,0.8-0.2,1.2c0.1,0.2,0.2,0.4,0.3,0.6c-0.2,0.3-0.4,0.6-0.6,1
                                 c-0.2,0-0.4,0-0.7,0c-0.5,0-0.9,0.4-0.9,0.8l-0.1,1.2c-0.1,0.5,0.2,0.9,0.7,1.1c0.2,0.1,0.4,0.1,0.6,0.2C18,9,18.1,9.4,18.3,9.8
                                 c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3,0.3-0.4,0.9-0.1,1.3l0.7,0.9c0.3,0.4,0.8,0.5,1.2,0.2c0.2-0.1,0.4-0.2,0.6-0.3
                                 c0.3,0.2,0.6,0.4,1,0.6c0,0.2,0,0.4,0,0.7c0,0.5,0.4,0.9,0.8,0.9l1.2,0.1c0.5,0.1,0.9-0.2,1.1-0.7c0.1-0.2,0.1-0.4,0.2-0.6
                                 c0.4-0.1,0.7-0.2,1.1-0.3c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.3,0.9,0.4,1.3,0.1l0.9-0.7c0.4-0.3,0.5-0.8,0.2-1.2
                                 c-0.1-0.2-0.2-0.4-0.3-0.6c0.2-0.3,0.4-0.6,0.6-1c0.2,0,0.4,0,0.7,0c0.5,0,0.9-0.4,0.9-0.8L30.5,8C30.6,7.5,30.3,7,29.8,6.9z
                                 M29.3,8.8c-0.2,0-0.4,0-0.6,0c-0.4,0-0.7,0.2-0.9,0.6c-0.1,0.3-0.3,0.7-0.5,1c-0.2,0.3-0.3,0.7-0.1,1.1c0.1,0.2,0.2,0.3,0.3,0.5
                                 l-0.7,0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.3-0.3-0.7-0.4-1.1-0.2c-0.3,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.7,0.3-0.8,0.7
                                 c0,0.2-0.1,0.4-0.2,0.6l-0.9-0.1c0-0.2,0-0.4,0-0.6c0-0.4-0.2-0.7-0.6-0.9c-0.3-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.7-0.3-1.1-0.1
                                 c-0.2,0.1-0.3,0.2-0.5,0.3l-0.5-0.7c0.1-0.1,0.3-0.3,0.4-0.4c0.3-0.3,0.4-0.7,0.2-1.1c-0.1-0.3-0.2-0.7-0.3-1.1
                                 C19,8,18.8,7.7,18.4,7.6c-0.2,0-0.4-0.1-0.6-0.2l0.1-0.9c0.2,0,0.4,0,0.6,0c0.4,0,0.7-0.2,0.9-0.6c0.1-0.3,0.3-0.7,0.5-1
                                 c0.2-0.3,0.3-0.7,0.1-1.1c-0.1-0.2-0.2-0.3-0.3-0.5l0.7-0.5c0.1,0.1,0.3,0.3,0.4,0.4c0.3,0.3,0.7,0.4,1.1,0.2
                                 c0.3-0.1,0.7-0.2,1.1-0.3c0.4-0.1,0.7-0.3,0.8-0.7c0-0.2,0.1-0.4,0.2-0.6L24.7,2c0,0.2,0,0.4,0,0.6c0,0.4,0.2,0.7,0.6,0.9
                                 c0.3,0.1,0.7,0.3,1,0.5c0.3,0.2,0.7,0.3,1.1,0.1c0.2-0.1,0.3-0.2,0.5-0.3l0.5,0.7c-0.1,0.1-0.3,0.3-0.4,0.4
                                 c-0.3,0.3-0.4,0.7-0.2,1.1C27.9,6.3,28,6.7,28.1,7c0.1,0.4,0.3,0.7,0.7,0.8c0.2,0,0.4,0.1,0.6,0.2L29.3,8.8L29.3,8.8z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M23.9,5.2c-1.4-0.2-2.6,0.8-2.8,2.2c-0.2,1.4,0.8,2.6,2.2,2.8c1.4,0.2,2.6-0.8,2.8-2.2C26.2,6.6,25.2,5.4,23.9,5.2z
                                 M23.4,9.1c-0.8-0.1-1.3-0.8-1.2-1.5s0.8-1.3,1.5-1.2S25,7.1,25,7.9C24.9,8.6,24.2,9.2,23.4,9.1z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M32.3,18.8c-0.1,0-0.3-0.1-0.5-0.1c0-0.3-0.1-0.5-0.2-0.8c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.6,0-0.9l-0.5-0.7
                                 c-0.2-0.3-0.6-0.3-0.9-0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2-0.2-0.5-0.3-0.7-0.4c0-0.2,0-0.3,0-0.5c0-0.3-0.3-0.6-0.6-0.7L28,14.3
                                 c-0.3,0-0.7,0.2-0.8,0.5c0,0.1-0.1,0.3-0.1,0.5c-0.3,0-0.5,0.1-0.8,0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.6-0.3-0.9,0
                                 l-0.7,0.5c-0.3,0.2-0.3,0.6-0.2,0.9c0.1,0.1,0.2,0.3,0.2,0.4c-0.2,0.2-0.3,0.5-0.4,0.7c-0.2,0-0.3,0-0.5,0
                                 c-0.3,0-0.6,0.3-0.7,0.6l-0.1,0.9c0,0.3,0.2,0.7,0.5,0.8c0.1,0,0.3,0.1,0.5,0.1c0,0.3,0.1,0.5,0.2,0.8c-0.1,0.1-0.2,0.2-0.3,0.3
                                 c-0.2,0.2-0.3,0.6,0,0.9l0.5,0.7c0.2,0.3,0.6,0.3,0.9,0.2c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0.2,0.5,0.3,0.7,0.4c0,0.2,0,0.3,0,0.5
                                 c0,0.3,0.3,0.6,0.6,0.7l0.9,0.1c0.3,0,0.7-0.2,0.8-0.5c0-0.1,0.1-0.3,0.1-0.5c0.3,0,0.5-0.1,0.8-0.2c0.1,0.1,0.2,0.2,0.3,0.3
                                 c0.2,0.2,0.6,0.3,0.9,0l0.7-0.5c0.3-0.2,0.3-0.6,0.2-0.9c-0.1-0.1-0.2-0.3-0.2-0.4c0.2-0.2,0.3-0.5,0.4-0.7c0.2,0,0.3,0,0.5,0
                                 c0.3,0,0.6-0.3,0.7-0.6l0.1-0.9C32.9,19.2,32.7,18.9,32.3,18.8z M31.9,20.2c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5,0.2-0.6,0.4
                                 c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.2-0.2,0.5,0,0.8c0.1,0.1,0.1,0.2,0.2,0.4l-0.5,0.4c-0.1-0.1-0.2-0.2-0.3-0.3
                                 c-0.2-0.2-0.5-0.3-0.8-0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.5,0.2-0.6,0.5c0,0.1-0.1,0.3-0.1,0.4L27,23.5c0-0.1,0-0.3,0-0.4
                                 c0-0.3-0.2-0.5-0.4-0.6c-0.2-0.1-0.5-0.2-0.7-0.4c-0.2-0.2-0.5-0.2-0.8,0c-0.1,0.1-0.2,0.1-0.4,0.2l-0.4-0.5
                                 c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.5,0.1-0.8c-0.1-0.2-0.2-0.5-0.2-0.8c0-0.3-0.2-0.5-0.5-0.6c-0.1,0-0.3-0.1-0.4-0.1
                                 l0.1-0.6c0.1,0,0.3,0,0.4,0c0.3,0,0.5-0.2,0.6-0.4c0.1-0.2,0.2-0.5,0.4-0.7c0.2-0.2,0.2-0.5,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.4
                                 l0.5-0.4c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.5,0.3,0.8,0.1c0.2-0.1,0.5-0.2,0.8-0.2c0.3,0,0.5-0.2,0.6-0.5c0-0.1,0.1-0.3,0.1-0.4
                                 l0.6,0.1c0,0.1,0,0.3,0,0.4c0,0.3,0.2,0.5,0.4,0.6c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.5,0.2,0.8,0c0.1-0.1,0.2-0.1,0.4-0.2
                                 l0.4,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.5-0.1,0.8c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3,0.2,0.5,0.5,0.6
                                 c0.1,0,0.3,0.1,0.4,0.1L31.9,20.2L31.9,20.2z"
													></path>
												</g>
											</g>
											<g>
												<g>
													<path
														d="M28,17.6c-1-0.1-1.9,0.6-2,1.6c-0.1,1,0.6,1.9,1.6,2c1,0.1,1.9-0.6,2-1.6C29.7,18.6,29,17.7,28,17.6z M27.7,20.3
                                 c-0.5-0.1-0.9-0.6-0.9-1.1c0.1-0.5,0.6-0.9,1.1-0.9c0.5,0.1,0.9,0.6,0.9,1.1C28.7,20,28.2,20.4,27.7,20.3z"
													></path>
												</g>
											</g>
											<path
												d="M14.3,37.5c-0.1,0-0.2,0-0.3-0.1c-1-0.6-1.9-1.2-2.8-2c-0.2-0.2-0.2-0.5,0-0.8c0.2-0.2,0.5-0.2,0.8,0
                           c0.8,0.7,1.7,1.3,2.6,1.9c0.3,0.1,0.3,0.5,0.2,0.7C14.7,37.4,14.5,37.5,14.3,37.5z"
											></path>
											<path
												d="M9.5,33.1c-0.2,0-0.3-0.1-0.4-0.2c-0.7-0.9-1.3-1.9-1.7-2.9c-0.1-0.3,0-0.6,0.3-0.7c0.3-0.1,0.6,0,0.7,0.3
                           c0.4,1,1,1.9,1.6,2.7c0.2,0.2,0.1,0.6-0.1,0.8C9.7,33.1,9.6,33.1,9.5,33.1z"
											></path>
											<path
												d="M37.6,20.3c-0.2,0-0.5-0.2-0.5-0.4c-0.2-1-0.6-2.1-1-3c-0.1-0.3,0-0.6,0.3-0.7c0.3-0.1,0.6,0,0.7,0.3
                           c0.5,1,0.8,2.1,1.1,3.2c0.1,0.3-0.1,0.6-0.4,0.6C37.7,20.3,37.6,20.3,37.6,20.3z"
											></path>
											<path
												d="M34.9,14.4c-0.2,0-0.3-0.1-0.4-0.2c-0.6-0.9-1.4-1.6-2.2-2.3c-0.2-0.2-0.2-0.5,0-0.8c0.2-0.2,0.5-0.2,0.8,0
                           c0.9,0.8,1.6,1.6,2.3,2.5c0.2,0.2,0.1,0.6-0.1,0.8C35.1,14.3,35,14.4,34.9,14.4z"
											></path>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">ERP Solution</div></a
							>
						</div>
						<div class="what-we-offer-main-item-box">
							<a href="#section7" id="section7-link" class="what-we-offer-main-item-box-link"
								><div class="what-we-offer-main-item-icon">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M25,36.1c-5.8,0-10.9-4.6-11-10.9c-0.2-6,4.7-11.1,10.6-11.4c6.3-0.2,11.4,4.7,11.5,10.9C36.2,31,31.1,36.1,25,36.1z
                           M20.4,27.3c0.3-0.3,0.5-0.7,0.8-1c0.3-0.3,0.8-0.4,1.2-0.2c0.8,0.3,0.9,0.9,0.9,1.6c0.9,0.4,1.1,0.7,1,1.6c0.9,0.4,1.2,1,0.8,2
                           c0.4,0.2,0.8,0.5,1.3,0.7c0.5,0.3,0.7,0.2,1-0.4c-0.2-0.1-0.5-0.3-0.7-0.5c-0.3-0.1-0.4-0.3-0.2-0.6c0.2-0.3,0.4-0.2,0.7-0.1
                           c0.3,0.2,0.6,0.4,1,0.6c0.5,0.2,1,0,1.1-0.6c-0.6-0.3-1.2-0.7-1.8-1c-0.3-0.1-0.5-0.3-0.3-0.6c0.2-0.3,0.5-0.2,0.7-0.1
                           c0.6,0.4,1.3,0.7,1.9,1.1c0.3,0.2,0.8,0.1,1-0.2c0.2-0.4,0-0.6-0.3-0.7c-0.9-0.6-1.9-1.2-2.8-1.8c-0.3-0.2-0.4-0.4-0.3-0.7
                           c0.2-0.3,0.5-0.2,0.7,0c1,0.6,2,1.3,3.1,1.9c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.6-0.1,0.7-0.3c0.1-0.2,0-0.5-0.1-0.7
                           c-0.2-0.3-0.5-0.5-0.8-0.7c-1.2-0.8-2.3-1.6-3.5-2.4c-0.7-0.5-1.4-0.9-2.2-1.4c-0.5-0.3-0.6-0.3-1.1,0.1c-0.5,0.4-0.9,0.8-1.4,1.2
                           c-0.3,0.2-0.6,0.4-1,0.5c-0.9,0.3-1.7-0.5-1.5-1.4c0.2-0.5,0.5-1,0.7-1.5c0.1-0.2,0.2-0.3,0.4-0.5c-1-0.1-1.8-0.3-2.5-1
                           c-0.1,0-0.1,0-0.1,0.1c-0.8,1.4-1.7,2.8-2.5,4.2c0,0.1-0.1,0.2,0,0.3c0.5,0.8,1.1,1.5,1.9,2C19.2,26.5,19.7,26.6,20.4,27.3z
                           M19.5,19.8c0.5,0.5,1.1,0.7,1.7,0.8c0.7,0.1,1.3,0.1,1.8-0.4c0.6-0.5,1.3-0.7,2-0.8c1.5-0.2,2.9,0.1,4.3,0.7c0.6,0.2,1,0,1.6-0.1
                           c-0.4-0.7-0.8-1.3-1.2-2c0.3-0.3,0.6-0.5,1-0.8c-3-2.3-7.4-2.4-10.3-0.6c0.1,0.2,0.2,0.3,0.3,0.4c0.2,0.3,0.2,0.5,0,0.9
                           C20.2,18.4,19.9,19.1,19.5,19.8z M34.6,26.1c0,0-0.1,0-0.1-0.1c-0.6,0.4-1.1,0.8-1.6,1.2c0.3,1.2,0.1,1.7-1.2,2.1
                           c-0.1,0-0.2,0.2-0.2,0.3c-0.2,0.6-0.6,0.9-1.2,1.1c-0.2,0-0.3,0.2-0.4,0.3c-0.3,0.5-0.7,0.9-1.3,0.9c-0.3,0-0.5,0.1-0.6,0.4
                           c-0.5,0.7-1.1,0.9-1.9,0.4c-0.5-0.3-0.9-0.5-1.4-0.8c-0.4,0.5-0.7,1.1-1.4,1.2c-0.8,0.1-1.2-0.4-1.5-1c-0.6,0-1.1-0.3-1.3-0.9
                           c0-0.1-0.1-0.2-0.2-0.2c-0.7,0-1.1-0.5-1.3-1.1c-1.2,0-1.9-1.2-1.1-2.2c-0.6-0.7-1.3-1.4-2-2.1c-0.1,0.4-0.3,0.7-0.3,1
                           c0.2,1.4,0.8,2.7,1.5,3.8c2.2,3.2,6.1,4.9,9.8,4c4-0.9,6.5-3.4,7.6-7.4C34.5,26.8,34.5,26.5,34.6,26.1z M32.3,26.6
                           c0.5-0.4,1.1-0.9,1.7-1.3c-0.9-1.6-1.8-3.1-2.7-4.6c-0.5,0.1-0.9,0.2-1.3,0.3c-0.2,0-0.3,0-0.5,0c-0.9-0.2-1.7-0.5-2.6-0.7
                           c-1.3-0.3-2.6-0.1-3.7,0.8c-0.7,0.6-1.3,1.3-1.7,2.1c-0.2,0.3-0.4,0.6-0.1,0.9c0.4,0.3,0.7,0,1-0.2c0.5-0.4,1-0.9,1.5-1.4
                           c0.5-0.5,1-0.5,1.6-0.2c0.2,0.1,0.4,0.2,0.5,0.3C28.1,23.9,30.2,25.2,32.3,26.6z M22.5,27.3c-0.1-0.1-0.2-0.4-0.4-0.4
                           c-0.2,0-0.5,0-0.6,0.2c-0.6,0.8-1.2,1.6-1.7,2.3c-0.2,0.3-0.3,0.5,0,0.8c0.3,0.2,0.5,0.1,0.7-0.2c0.6-0.8,1.1-1.5,1.7-2.3
                           C22.4,27.6,22.5,27.4,22.5,27.3z M23.6,28.9c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2,0-0.4,0.1-0.5,0.2c-0.5,0.6-0.9,1.2-1.3,1.8
                           c-0.2,0.3-0.2,0.5,0,0.7c0.3,0.2,0.5,0.1,0.7-0.2c0.4-0.6,0.9-1.2,1.3-1.7C23.4,29.2,23.5,29.1,23.6,28.9z M24.5,30.6
                           c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2,0-0.4,0-0.5,0.1c-0.4,0.4-0.7,0.9-1,1.3c-0.2,0.2-0.1,0.5,0.1,0.6c0.2,0.2,0.5,0.1,0.6-0.1
                           c0.4-0.4,0.7-0.9,1-1.4C24.5,30.8,24.5,30.7,24.5,30.6z M19.3,27.5c0,0-0.2,0-0.2,0.1c-0.3,0.3-0.5,0.6-0.7,1
                           c-0.1,0.1,0.1,0.4,0.2,0.5c0.1,0.1,0.4,0,0.5-0.1c0.2-0.2,0.4-0.5,0.6-0.8C19.9,27.9,19.6,27.5,19.3,27.5z"
											></path>
											<path
												d="M33.9,6.8c-1.2-1.1-2.4-2.1-3.5-3.2c-0.4-0.3-0.7-0.5-1.1-0.1c-0.3,0.4-0.1,0.7,0.2,1c0.5,0.4,0.9,0.9,1.6,1.4
                           c-0.2,0-0.3-0.1-0.4-0.1C25.8,4.5,21,4.8,16.4,7c-0.3,0.1-0.6,0.3-0.8,0.4c0.2,0.3,0.4,0.7,0.6,1.1C19,6.9,22.3,6.2,26,6.3
                           c1.6,0,3.1,0.3,4.6,0.9c-0.7,0.2-1.4,0.3-2.1,0.4c-0.4,0.1-0.7,0.3-0.6,0.7c0.1,0.4,0.4,0.6,0.8,0.5c1.6-0.3,3.3-0.6,4.9-0.9
                           c0.2,0,0.5-0.3,0.6-0.5C34.2,7.3,34,6.9,33.9,6.8z"
											></path>
											<path
												d="M34.1,42.4c-1,0.5-2,1-3.1,1.4c-3.7,1.2-7.4,1.3-11.1,0.3c-0.1,0-0.3-0.1-0.5-0.2c0.8-0.2,1.5-0.3,2.2-0.4
                           c0.4-0.1,0.7-0.3,0.6-0.7c-0.1-0.4-0.4-0.5-0.8-0.5c-1.7,0.3-3.3,0.5-5,0.8c-0.5,0.1-0.7,0.6-0.4,1c0.1,0.1,0.2,0.2,0.4,0.4
                           c1.1,1,2.3,2,3.3,3c0.4,0.4,0.7,0.4,1,0.1c0.4-0.3,0.3-0.7,0-1c-0.5-0.5-1-0.9-1.5-1.4c0,0,0-0.1,0.1-0.1c0.1,0,0.3,0.1,0.4,0.1
                           c3.1,0.9,6.3,1,9.5,0.4c2-0.4,3.9-1.1,5.7-2.1C34.5,43.2,34.3,42.8,34.1,42.4z"
											></path>
											<g>
												<path
													d="M7.3,1C3.3,1,0,4.3,0,8.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S11.3,1,7.3,1z M3.1,12.8l0.2,0c0-1,0.5-2,1.3-2.7
                              c0.8-0.7,1.8-1.1,2.8-1c1,0,2,0.5,2.7,1.3c0.7,0.8,1.1,1.8,1,2.8c-1,0.8-2.4,1.3-3.8,1.3C5.7,14.5,4.2,13.9,3.1,12.8z M8.5,7.2
                              C8.3,7.5,8,7.8,7.7,7.9C7.3,8,6.9,8,6.6,7.8C6.3,7.7,6,7.4,5.9,7.1C5.7,6.3,6,5.6,6.8,5.3c0.1-0.1,0.3-0.1,0.5-0.1
                              c0.6,0,1.1,0.4,1.3,0.9C8.6,6.5,8.6,6.9,8.5,7.2z M12.1,12.2c-0.2-0.9-0.6-1.8-1.2-2.5c-0.5-0.6-1.2-1-1.9-1.3
                              c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.3-1.3,0.1-1.9c-0.5-1.3-1.9-2-3.2-1.5c-1.3,0.5-2,1.9-1.5,3.2C5,7.8,5.2,8.1,5.4,8.3
                              c-0.6,0.2-1.1,0.5-1.6,1C3.1,10,2.5,10.9,2.3,12c-0.8-1-1.2-2.3-1.2-3.7c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                              C13.5,9.8,13,11.1,12.1,12.2z"
												></path>
												<path
													d="M42.7,1c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3S50,12.3,50,8.3S46.7,1,42.7,1z M38.5,12.8l0.2,0c0-1,0.5-2,1.3-2.7
                              c0.8-0.7,1.8-1.1,2.8-1c1,0,2,0.5,2.7,1.3c0.7,0.8,1.1,1.8,1,2.8c-1,0.8-2.4,1.3-3.8,1.3C41.1,14.5,39.6,13.9,38.5,12.8z
                              M43.8,7.2c-0.2,0.3-0.4,0.6-0.8,0.7C42.7,8,42.3,8,42,7.8c-0.3-0.2-0.6-0.4-0.7-0.8c-0.2-0.7,0.1-1.5,0.9-1.8
                              c0.1-0.1,0.3-0.1,0.5-0.1c0.6,0,1.1,0.4,1.3,0.9C44,6.5,44,6.9,43.8,7.2z M47.5,12.2c-0.2-0.9-0.6-1.8-1.2-2.5
                              c-0.5-0.6-1.2-1-1.9-1.3c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.3-1.3,0.1-1.9c-0.5-1.3-1.9-2-3.2-1.5c-1.3,0.5-2,1.9-1.5,3.2
                              c0.1,0.4,0.3,0.7,0.6,0.9c-0.6,0.2-1.1,0.5-1.6,1c-0.8,0.7-1.3,1.6-1.5,2.6c-0.8-1-1.2-2.3-1.2-3.7c0-3.4,2.8-6.2,6.2-6.2
                              s6.2,2.8,6.2,6.2C48.9,9.8,48.4,11.1,47.5,12.2z"
												></path>
												<path
													d="M7.3,34.4c-4,0-7.3,3.3-7.3,7.3S3.3,49,7.3,49s7.3-3.3,7.3-7.3S11.3,34.4,7.3,34.4z M3.1,46.2l0.2,0c0-1,0.5-2,1.3-2.7
                              c0.8-0.7,1.8-1.1,2.8-1c1,0,2,0.5,2.7,1.3c0.7,0.8,1.1,1.8,1,2.8c-1,0.8-2.4,1.3-3.8,1.3C5.7,47.9,4.2,47.3,3.1,46.2z M8.5,40.6
                              c-0.2,0.3-0.4,0.6-0.8,0.7c-0.3,0.1-0.7,0.1-1.1-0.1c-0.3-0.2-0.6-0.4-0.7-0.8C5.7,39.7,6,39,6.8,38.7c0.1-0.1,0.3-0.1,0.5-0.1
                              c0.6,0,1.1,0.4,1.3,0.9C8.6,39.9,8.6,40.3,8.5,40.6z M12.1,45.6c-0.2-0.9-0.6-1.8-1.2-2.5c-0.5-0.6-1.2-1-1.9-1.3
                              c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.3-1.3,0.1-1.9c-0.5-1.3-1.9-2-3.2-1.5c-1.3,0.5-2,1.9-1.5,3.2c0.1,0.4,0.3,0.7,0.6,0.9
                              c-0.6,0.2-1.1,0.5-1.6,1c-0.8,0.7-1.3,1.6-1.5,2.6c-0.8-1-1.2-2.3-1.2-3.7c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
                              C13.5,43.2,13,44.5,12.1,45.6z"
												></path>
												<path
													d="M42.7,34.4c-4,0-7.3,3.3-7.3,7.3s3.3,7.3,7.3,7.3s7.3-3.3,7.3-7.3S46.7,34.4,42.7,34.4z M38.5,46.2l0.2,0
                              c0-1,0.5-2,1.3-2.7c0.8-0.7,1.8-1.1,2.8-1c1,0,2,0.5,2.7,1.3c0.7,0.8,1.1,1.8,1,2.8c-1,0.8-2.4,1.3-3.8,1.3
                              C41.1,47.9,39.6,47.3,38.5,46.2z M43.8,40.6c-0.2,0.3-0.4,0.6-0.8,0.7c-0.3,0.1-0.7,0.1-1.1-0.1c-0.3-0.2-0.6-0.4-0.7-0.8
                              c-0.2-0.7,0.1-1.5,0.9-1.8c0.1-0.1,0.3-0.1,0.5-0.1c0.6,0,1.1,0.4,1.3,0.9C44,39.9,44,40.3,43.8,40.6z M47.5,45.6
                              c-0.2-0.9-0.6-1.8-1.2-2.5c-0.5-0.6-1.2-1-1.9-1.3c0.2-0.2,0.4-0.4,0.5-0.7c0.3-0.6,0.3-1.3,0.1-1.9c-0.5-1.3-1.9-2-3.2-1.5
                              c-1.3,0.5-2,1.9-1.5,3.2c0.1,0.4,0.3,0.7,0.6,0.9c-0.6,0.2-1.1,0.5-1.6,1c-0.8,0.7-1.3,1.6-1.5,2.6c-0.8-1-1.2-2.3-1.2-3.7
                              c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2C48.9,43.2,48.4,44.5,47.5,45.6z"
												></path>
											</g>
											<path
												d="M33.2,18.7l5.8-5l-0.7-0.8l-5.8,5C32.8,18.1,33,18.4,33.2,18.7z"
											></path>
											<path
												d="M17.5,17.8l-5.8-5l-0.7,0.8l5.8,5C17,18.4,17.2,18.1,17.5,17.8z"
											></path>
											<path d="M16.8,31.3l-5.8,5l0.7,0.8l5.8-5C17.2,31.9,17,31.6,16.8,31.3z"></path>
											<path d="M32.5,32.2l5.8,5l0.7-0.8l-5.8-5C33,31.6,32.8,31.9,32.5,32.2z"></path>
											<path
												d="M4,20.7c0.5-0.5,1-0.9,1.5-1.5c-0.3,1.1-0.6,2.2-0.8,3.2c-0.6,2.8-0.5,5.6,0,8.3c0.2,0.9,0.5,1.8,0.8,2.7
                           c0.4-0.1,0.8-0.3,1.2-0.4c-0.4-1.2-0.8-2.5-1-3.8c-0.4-3.1-0.1-6.1,0.9-9c0.1-0.2,0.1-0.4,0.3-0.6C7,20.3,7.1,21,7.1,21.7
                           c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.6-0.4,0.5-0.8c-0.2-1.7-0.3-3.4-0.5-5c-0.1-0.5-0.6-0.7-1-0.4c-0.1,0.1-0.3,0.2-0.4,0.4
                           c-1.1,1-2.1,2.1-3.3,3.1c-0.4,0.4-0.4,0.6-0.2,1C3.3,21,3.7,21,4,20.7z"
											></path>
											<path
												d="M46.8,28.6c-0.5,0.5-0.9,1-1.4,1.6c0.2-1.1,0.5-2.2,0.6-3.3c0.5-2.8,0.1-5.6-0.5-8.3c-0.2-0.9-0.6-1.8-1-2.6
                           c-0.4,0.2-0.8,0.3-1.2,0.5c0.5,1.2,0.9,2.4,1.2,3.8c0.6,3.1,0.5,6.1-0.3,9c-0.1,0.2-0.1,0.4-0.2,0.7c-0.1-0.8-0.3-1.5-0.4-2.2
                           c-0.1-0.4-0.3-0.7-0.7-0.6c-0.4,0.1-0.5,0.4-0.5,0.8c0.3,1.7,0.5,3.3,0.8,5c0.1,0.5,0.6,0.7,1,0.4c0.1-0.1,0.2-0.2,0.4-0.4
                           c1-1.1,2-2.3,3.1-3.3c0.4-0.4,0.4-0.7,0.1-1C47.5,28.1,47.1,28.2,46.8,28.6z"
											></path>
										</g>
									</svg>
								</div>
								<div class="what-we-offer-main-item-title">CRM Solutions</div></a
							>
						</div>
					</div>
					<div class="border-dashed"></div>
				</div>
			</div>
			<div style="display: none; width: 1519.2px; height: 132px; float: none"></div>
			<div class="what-we-offer-main-listing">
				<div class="container">
					<div class="row offers-sections" id="section1">
						<div class="col-lg-6 col-md-12 col-12 order-lg-1 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">01<span>01</span></div>
									<h2 class="m-0">Custom Web Development</h2>
								</div>
								<div class="top-text-block">
									In business, you try to solve problems. And every problem demands a specific
									solution. A cookie cut solution will not suffice. That’s why you need custom web
									development.
								</div>
								<p>
									Custom development can bestow upon your business several benefits that cookie cut
									solutions cannot. To begin with, it gives a tailor-made solution that solves
									specific problems. A custom-built web portal can mirror your brand’s presets. It
									can provide the user experience that the whole business envisions to deliver in
									every aspect of operations. It can cut away the fluff that could be slowing your
									processes or making them redundant.
								</p>
								<p>
									As a custom web development company we can bring to your business several
									benefits, some of which includes:
								</p>
								<ul>
									<li>Industry-focused web development that matches your business needs</li>
									<li>
										Consistent user experience across all web, mobile &amp; hybrid digital platforms
									</li>
									<li>Highly scalable solution that can accommodate business growth</li>
								</ul>
								<a
									href="https://www.genixbit.com/contact"
									class="btn btn-custome waves-effect waves-light"
									>Talk to Our Expert</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-ce text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="Genixbit offers top class website development services in London, Leeds and UK."
									src="../../../assets/images/custom-web-development-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section2">
						<div class="col-lg-6 col-md-12 col-12 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">02<span>02</span></div>
									<h2 class="m-0">eCommerce website development</h2>
								</div>
								<div class="top-text-block">
									Fasttrack your growth in online retail or add a new channel to your omni channel
									strategy with our eCommerce development expertise.
								</div>
								<p>
									Developing an eCommerce website is not like driving down an arrow-straight road.
									It requires expertise, cautious decision-making, thoughtful designing and also
									abundant tech intel. Intellivita has all this and much more. When you sign up with
									us to develop an eCommerce website, you are taking the best forward in online
									retail. Here are some pointers that makes us a right choice.
								</p>
								<ul>
									<li>Advanced expertise in Magento, Drupal, WooCommerce, 3dcart, Shopify, etc.</li>
									<li>Single vendor or multi-vendor eCommerce development</li>
									<li>Integrations with third-party software providers</li>
								</ul>
								<a
									href="https://www.genixbit.com/ecommerce-web-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="We have provided custom website development services to our esteemed clients in UK, London, Leeds."
									src="../../../assets/images/ecommerce-website-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section3">
						<div class="col-lg-6 col-md-12 col-12 order-lg-1 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">03<span>03</span></div>
									<h2 class="m-0">Web app development</h2>
								</div>
								<div class="top-text-block">
									Genixbit is a name to trust for building web applications that are scalable +
									interactive + secure.
								</div>
								<p>
									Front-end web development has been our forte since our beginning days. We have
									built a host of web applications that can cater to dynamic user needs. Our web
									developers are experts in building web applications using a wide-range of web
									development frameworks including React, Angular among many others. As a web
									development company, our USPs include
								</p>
								<ul>
									<li>Expertise on multiple web development platforms</li>
									<li>User-centric approach to web UI design &amp; development</li>
									<li>Full-stack web development that accelerates speed to market</li>
								</ul>
								<a
									href="https://www.genixbit.com/contact"
									class="btn btn-custome waves-effect waves-light"
									>Get in Touch with Us</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="Are you looking for a website development company? Genixbit is a custom web design company in Leeds, Helping small to large businesses across the UK."
									src="../../../assets/images/web-app-development-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section4">
						<div class="col-lg-6 col-md-12 col-12 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">04<span>04</span></div>
									<h2 class="m-0">Mobile backend and APIs</h2>
								</div>
								<div class="top-text-block">
									We build mobile backends and APIs that will help you take your business and
									applications anywhere.
								</div>
								<p>
									Mobile app development has become mainstream that developers today focus not just
									on building mobile apps but also mobile backends. For any mobile app, its backend
									is like the backbone. It gives the app structure, the network infrastructure
									required to fetch data, and also take care of other activities like file storage,
									database management, web services and so on. Genixbit can help you create
									mobile backends and APIs that can be integrated with your other apps to create
									full-blown top-notch mobile apps.
								</p>
								<!--  <ul>
                        <li>Simply dummy text of the printing and typesetting industry.</li>
                        <li>Simply dummy text of the printing and typesetting industry.</li>
                        <li>Simply dummy text of the printing and typesetting industry.</li>
                     </ul> -->
								<a
									href="https://www.genixbit.com/contact"
									class="btn btn-custome waves-effect waves-light"
									>Talk to Our Backend Expert</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="Genixbit is the business website development company in Leeds, UK. We help entrepreneurs create a stunning business website at an affordable price."
									src="../../../assets/images/mobile-backend-and-apis-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section5">
						<div class="col-lg-6 col-md-12 col-12 order-lg-1 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">05<span>05</span></div>
									<h2 class="m-0">CMS development</h2>
								</div>
								<div class="top-text-block">
									Your digital content is your organizational asset. Our expert services will help
									you create a content management system that can facilitate easy creation and
									management of your digital content.
								</div>
								<p>
									If your business dabbles in creating content and publishing it frequently, it
									needs a powerful content management system. A CMS that is easy to use, customize,
									and also has the capabilities to set user privileges. Be it for eCommerce or for a
									corporate blog or for an e-publishing website — we are fully geared to create the
									perfect CMS for you. Here are some strandard traits that all the content
									management systems we create will have:
								</p>
								<ul>
									<li>Easy to update &amp; manage</li>
									<li>Quick scalability</li>
									<li>User-level controls</li>
									<li>High-end security</li>
								</ul>
								<a
									href="https://www.genixbit.com/cms-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="GenixBit Labs is one of the best web development company in UK. Over the years of experience has helped us to provide website development services"
									src="../../../assets/images/content-management-system-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section6">
						<div class="col-lg-6 col-md-12 col-12 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">06<span>06</span></div>
									<h2 class="m-0">ERP solution</h2>
								</div>
								<div class="top-text-block">
									Bring in strategic coherence in your business by opting for ERP application
									development for resource planning and functional management.
								</div>
								<p>
									Gain total control of your business operations with an Enterprise Resource
									Planning (ERP) system. Genixbit can help you build ERP solutions that can do it
									all — maintaining finances to tying them with inventory control or even monitoring
									marketing — 360 degree control of operations made possible.
								</p>
								<ul>
									<li>ERP solutions implementation</li>
									<li>Consultation and strategy formulation for ERP implementation</li>
									<li>Updating, maintaining and enhancing ERP features</li>
								</ul>
								<a
									href="https://www.genixbit.com/erp-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="ERP Solution"
									src="../../../assets/images/erp-solution-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
					<div class="row offers-sections" id="section7">
						<div class="col-lg-6 col-md-12 col-12 order-lg-1 mb-4 m-lg-0">
							<div class="what-we-offer-main-listing-content">
								<div class="what-we-offer-main-listing-title d-inline-flex align-items-center">
									<div class="number">07<span>07</span></div>
									<h2 class="m-0">CRM solutions</h2>
								</div>
								<div class="top-text-block">
									When you manage your customer data the right, you manage your business the right.
									A CRM (customer relationship management) system allows you to do that.
								</div>
								<p>
									Just-launched, mid-scale or large scale — every business that is thriving to serve
									its customers requires a CRM solution. Our purpose is to help you build a CRM
									solution from scratch or integrate a sophisticated one that will work fine with
									your business. No matter what your requirement is, consider your every CRM
									solution wish granted.
								</p>
								<ul>
									<li>Build CRM solution from scratch</li>
									<li>Integrate your existing data with a new CRM system</li>
									<li>Update, manage and maintain your CRM system</li>
								</ul>
								<a
									href="https://www.genixbit.com/crm-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
						</div>
						<div class="col-lg-6 col-md-12 col-12 text-center">
							<div class="what-we-offer-main-listing-img">
								<img
									class="lazy rounded10"
									alt="CRM Solutions"
									src="../../../assets/images/crm-solution-listing.jpg"
									style=""
									width="511"
									height="478"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="nofixed"></div>
	</section>
</template>

<script>
import $ from 'jquery';
export default {
	mounted() {
		// document.querySelector('.what-we-offer-main-listing').addEventListener('mouseenter', () => {
		// 	$('.what-we-offer-main-slider-section')
		// 		.css('position', 'fixed')
		// 		.css('z-index', '999')
		// 		.css('margin-left', '0px')
		// 		.css('top', '87.4167px')
		// 		.css('width', '1519.2px')
		// 		.css('left', '50%')
		// 		.css('transform', 'translateX(-50%)')
		// });

		window.onscroll = () => {
			// to change the links container

			if (
				window.scrollY > document.querySelector('.what-we-offer-main-listing').offsetTop - 200 &&
				window.scrollY <
					document.querySelector('.what-we-offer-main-listing').offsetHeight +
						document.querySelector('.what-we-offer-main-listing').offsetTop -
						200
			) {
				$('.what-we-offer-main-listing').css('padding-top', '225px');
				$('.what-we-offer-main-tabs').css('position', 'unset');
				$('.what-we-offer-main-slider-section')
					.css('position', 'fixed')
					.css('z-index', '999')
					.css('margin-left', '0px')
					.css('top', `83px`)
					.css('width', '1519.2px')
					.css('left', '50%')
					.css('transform', 'translateX(-50%)')
					.css('bottom', 'unset');
			} else {
				$('.what-we-offer-main-listing').css('padding-top', 'unset');
				$('.what-we-offer-main-tabs').css('position', 'unset');
				$('.what-we-offer-main-slider-section')
					.css('position', 'relative')
					.css('z-index', 'unset')
					.css('margin-left', 'unset')
					.css('top', 'unset')
					.css('width', '1519.2px')
					.css('left', '50%')
					.css('transform', 'translateX(-50%)')
					.css('bottom', 'unset');
			}

			// to add active style to the current link
			const offersSections = document.querySelectorAll('.offers-sections');
			offersSections.forEach((section, idx) => {
				if (idx != offersSections.length - 1) {
					if (window.scrollY >= document.querySelector(`.offers-sections#section${idx + 1}`).offsetTop &&
						window.scrollY < document.querySelector(`.offers-sections#section${idx + 2}`).offsetTop) {
						document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.add('active');
					} else if (document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.contains('active')) {
						document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.remove('active');
					}
				} else {
					if (window.scrollY >= document.querySelector(`.offers-sections#section${idx + 1}`).offsetTop) {
						document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.add('active');
					} else if (document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.contains('active')) {
							document.querySelector(`.what-we-offer-main-item-box-link#section${idx + 1}-link`).classList.remove('active');
					}
				}
			});
		};
	}
};
</script>

<style>
.what-we-offer-main-listing-title {
	padding-bottom: 25px;
	position: relative;
	margin-bottom: 30px;
}
.what-we-offer-main-listing-title::before {
	content: '';
	display: block;
	height: 5px;
	background-color: #1977cc;
	width: 75%;
	position: absolute;
	bottom: 0;
	border-radius: 10px;
}
.what-we-offer-main-listing-title .number {
	width: auto;
	font-size: 75px;
	font-weight: 900;
	color: rgba(32, 33, 34, 0.2);
	line-height: 55px;
	position: relative;
	margin-right: 15px;
}
.what-we-offer-main-listing-title .number span {
	font-size: 35px;
	line-height: 28px;
	font-weight: 600;
	color: #1977cc;
	position: absolute;
	right: 0;
	bottom: 0;
}
.what-we-offer-main-slider-section {
	padding: 20px 0;
}
.what-we-offer-main-listing-content ul {
	margin-bottom: 1rem;
}
.what-we-offer-main-listing-content li {
	display: block;
	color: #6a6d6f;
	margin-bottom: 10px;
	position: relative;
	padding-left: 25px;
}
.what-we-offer-main-listing-content li::before {
	display: inline-block;
	content: '\f105';
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiase;
	background: #b9baba;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	color: #202122;
	position: absolute;
	left: 0;
	top: 0;
}

.what-we-offer-main-item-box-link.active .what-we-offer-main-item-icon svg,
.what-we-offer-main-item-box-link.active .what-we-offer-main-item-icon svg path {
	fill: white !important;
}
.what-we-offer-main-item-box-link.active .what-we-offer-main-item-icon {
	box-shadow: 0 5px 10px 0 #1977cc;
	background-color: #1977cc;
}
@media (max-width:776px) {
	.offers-sections:nth-child(2n+1) {
		flex-direction:column-reverse;
	}
	.what-we-offer-main-listing{
		padding-top:0 !important;
	}
}
</style>
