<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Unique Features We Embed in Our Dating App Development</h2>
				<p>
					From finding the nearby matches to integrating social media handles, your dating app can
					be decked with a host of dating-friendly features. We offer Tinder like dating app
					development solutions that replicate popular features that the dating app world expects as
					default.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../../IndustrySolutions/AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<path
			d="M46.6,0l-9.1,0c0,0-0.2,0-0.5,0C35.1,0,25.6,0.5,23,10.7c0,0.1-0.9,2.7-0.9,8.6h-8.7c-0.8,0-1.4,0.6-1.4,1.4v8
                  c0,0.8,0.6,1.4,1.4,1.4h9.5v28.6c0,0.8,0.6,1.4,1.4,1.4h10.9c0.8,0,1.4-0.6,1.4-1.4V30h9.5c0.8,0,1.4-0.6,1.4-1.4v-8
                  c0-0.8-0.6-1.4-1.4-1.4h-9.5v-3.4c0-2.3,1.5-4.7,5.5-4.7h4.5c0.8,0,1.4-0.6,1.4-1.4V1.4C48,0.6,47.4,0,46.6,0z M45.2,8.3h-3.1
                  c-5.7,0-8.3,3.8-8.3,7.5v4.8c0,0.8,0.6,1.4,1.4,1.4h9.5v5.2h-9.5c-0.8,0-1.4,0.6-1.4,1.4v28.6h-8.1V28.6c0-0.8-0.6-1.4-1.4-1.4h-9.5
                  V22h8.7c0.4,0,0.7-0.2,1-0.4c0.3-0.3,0.4-0.6,0.4-1c-0.1-6.3,0.7-9,0.8-9.1c2.1-8.1,9.2-8.7,11.3-8.7c0.2,0,0.3,0,0.4,0h7.8V8.3
                  L45.2,8.3z"
		></path></svg
	>`,
					title: 'Facebook Login'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M53.6,8.6C53,4.3,49.4,1,45,1c-4.4,0-8,3.3-8.6,7.6H0v2.5h36.3c0.6,4.3,4.2,7.6,8.6,7.6c4.4,0,8-3.3,8.6-7.6H60V8.6H53.6z
                    M45,16.1c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3C51.2,13.3,48.4,16.1,45,16.1z M45,41.3
                    c-4.4,0-8,3.3-8.6,7.6H0v2.5h36.3C37,55.7,40.6,59,45,59c4.4,0,8-3.3,8.6-7.6H60v-2.5h-6.3C53,44.6,49.4,41.3,45,41.3z M45,56.5
                    c-3.4,0-6.2-2.8-6.2-6.3c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3C51.2,53.7,48.4,56.5,45,56.5z M8.8,21.2C4,21.2,0,25.1,0,30
                    c0,4.9,3.9,8.8,8.7,8.8c4.4,0,8-3.3,8.6-7.6H60v-2.5H17.4C16.8,24.5,13.2,21.2,8.8,21.2z M8.8,36.3c-3.4,0-6.2-2.8-6.2-6.3
                    c0-3.5,2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3C15,33.5,12.2,36.3,8.8,36.3z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Discovery Preferences'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<path
			d="M59.1,26.6c-0.5,0-0.9,0.4-0.9,0.9v1h-3c-0.1-0.3-0.1-0.5-0.2-0.8c0.1-0.1,0.1-0.2,0.2-0.3c1.6-5.1,0.2-10.7-3.7-14.5
                C46,7.5,37,7.5,31.4,12.9l-0.8,0.8c-0.6,0.6-1.6,0.6-2.2,0l-0.8-0.8c-2.3-2.2-5.3-3.6-8.5-4c-3.1-0.3-6.3,0.4-9,2
                c-0.4,0.2-0.5,0.8-0.3,1.2c0.3,0.4,0.8,0.5,1.2,0.3c4.9-3,11.2-2.2,15.3,1.8l0.8,0.8c1.3,1.2,3.4,1.2,4.6,0l0.8-0.8
                c4.9-4.8,12.8-4.8,17.6,0c3.1,3.1,4.4,7.4,3.6,11.6c-0.2-0.3-0.5-0.5-0.7-0.7c-2.2-1.9-5.7-4.9-6.9-6c-0.6-0.5-1.4-0.5-2,0.1l0,0
                c-1.3,1.2-1.4,3.2-0.2,4.6l1.8,2.2c0.1,0.1,0.1,0.2,0,0.2c0,0.1-0.1,0.1-0.2,0.1H35.3c-1.4,0-2.6,1.1-2.6,2.5c0,0.3,0,0.5,0.1,0.8
                c-1.3,0.1-2.4,1.2-2.4,2.5c0,1.4,1.2,2.5,2.6,2.5h1c-0.1,0.3-0.1,0.5-0.1,0.8c0,1.3,1.1,2.4,2.4,2.5c-0.1,0.3-0.1,0.5-0.1,0.8
                c0,1.4,1.2,2.5,2.6,2.5l3,0c-2.3,2-4.8,3.8-7.4,5.4l-3.9,2.5c-0.5,0.3-1.1,0.3-1.6,0l-3.9-2.5c-2.6-1.6-5.1-3.4-7.4-5.4l3.9,0
                c1.4,0,2.6-1.1,2.6-2.5c0-0.3,0-0.6-0.1-0.8c1.3-0.1,2.4-1.2,2.4-2.5c0-0.3,0-0.5-0.1-0.8h1c1.4,0,2.6-1.1,2.6-2.5
                c0-1.3-1.1-2.4-2.4-2.5c0.1-0.3,0.1-0.5,0.1-0.8c0-1.4-1.2-2.5-2.6-2.5H14.4c-0.1,0-0.2-0.1-0.2-0.1c0-0.1,0-0.2,0-0.2l1.8-2.2
                c1.1-1.4,1-3.3-0.2-4.6l0,0c-0.5-0.5-1.4-0.6-2-0.1c-1.2,1-4.7,4.1-6.9,6c-0.5,0.5-1,1-1.4,1.6c-1.3-4.4,0-9.2,3.3-12.5
                c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.3-1.2,0c-4.1,4-5.4,10-3.4,15.3c0,0.1,0.1,0.2,0.2,0.3H1.8v-1c0-0.5-0.4-0.9-0.9-0.9
                S0,27,0,27.4v13c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9v-1.4h4.1c1.8,0,3.6,0.4,5.2,1.2c1.1,0.6,2.4,0.9,3.7,1
                c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.5,0.8,0.7c2.5,2.2,5.2,4.2,8.1,6l3.9,2.5c0.5,0.3,1.1,0.5,1.8,0.5c0.6,0,1.2-0.2,1.8-0.5
                l3.9-2.5c3.2-2,6.3-4.3,9.1-6.8c0,0,0,0,0.1-0.1h0.1c1.5,0,3.1-0.4,4.5-1c1.6-0.8,3.4-1.2,5.2-1.2h4.1v1.4c0,0.5,0.4,0.9,0.9,0.9
                s0.9-0.4,0.9-0.9v-13C60,27,59.6,26.6,59.1,26.6z M15.5,39.6c-1.3,0-2.5-0.3-3.7-0.8c-1.8-0.9-3.9-1.4-6-1.4H1.8v-7.2h3.7
                c0.4,0,0.8-0.3,0.9-0.7c0.2-1.2,0.8-2.4,1.7-3.2c2-1.8,5.3-4.6,6.7-5.8c0.5,0.6,0.5,1.4,0,2l-1.8,2.2c-0.5,0.6-0.6,1.4-0.3,2.1
                c0.3,0.7,1,1.1,1.8,1.1h10.3c0.5,0,0.8,0.4,0.8,0.8c0,0.4-0.3,0.8-0.7,0.8l-9.1,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
                c0,0,0,0,0,0l9,0c0,0,0,0,0.1,0H27c0.5,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8h-7.5l-3.8,0c-0.5,0-0.9,0.4-0.9,0.9
                c0,0.5,0.4,0.9,0.9,0.9h7.9c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8l-7.9,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
                c0,0,0,0,0,0l5.6,0c0.5,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8L15.5,39.6z M58.2,37.4h-4.1c-2.1,0-4.1,0.5-6,1.4
                c-1.1,0.6-2.4,0.8-3.7,0.8l-5.7,0c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h1.8l3.8,0c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9
                c0-0.5-0.4-0.9-0.9-0.9l-7.9,0c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h7.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
                l-3.8,0H33c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h2.2c0,0,0,0,0,0l9,0c0,0,0,0,0,0c0.5,0,0.9-0.4,0.9-0.9
                c0-0.5-0.4-0.9-0.9-0.9l-9,0c0,0,0,0,0,0c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h10.3c0.8,0,1.5-0.4,1.8-1.1
                c0.3-0.7,0.2-1.5-0.3-2.1l-1.8-2.2c-0.5-0.6-0.5-1.4,0-2c1.4,1.2,4.6,4,6.7,5.8c1,0.8,1.6,2,1.7,3.2c0.1,0.4,0.4,0.7,0.9,0.7h3.7
                V37.4z"
		></path></svg
	>`,
					title: 'Matchmaking'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M58.4,26.2c0-3-2.4-5.4-5.2-5.4H53c-1.3,0-2.5,0.5-3.5,1.4c-0.7-2.1-2.7-3.6-4.9-3.6c-1.4,0-2.8,0.6-3.7,1.6
                    c-0.9-1.8-2.6-3-4.7-3h-0.1c-1.2,0-2.3,0.3-3.1,1V5.5c0-3-2.4-5.5-5.3-5.5c-2.9,0-5.3,2.4-5.3,5.5l0,25.2l-1.6-1.9
                    c-1.2-1.5-3.1-2.5-5.1-2.6C13.9,26,12,26.7,10.5,28l-1.3,1c-0.4,0.3-0.5,0.8-0.3,1.3l12.2,23c2.2,4.2,6.4,6.8,11,6.8h13.6
                    c6.9,0,12.5-5.9,12.6-13.1c0-3.2,0-5.6,0-7.6C58.4,34,58.4,32,58.4,26.2z M56.3,39.3c0,2,0,4.4,0,7.6c0,6.1-4.7,11.1-10.5,11.1
                    H32.2c-3.8,0-7.3-2.2-9.1-5.7L11.3,30l0.6-0.5c0,0,0,0,0,0c1-0.9,2.4-1.4,3.8-1.3c1.4,0.1,2.7,0.8,3.6,1.9l3.4,4.1
                    c0.3,0.3,0.7,0.5,1.2,0.3c0.4-0.1,0.7-0.5,0.7-1l0-28c0-1.9,1.4-3.4,3.2-3.4c1.7,0,3.2,1.5,3.2,3.4v16.9c0,0.1,0,0.1,0,0.2
                    c0,0,0,0,0,0.1v7.2c0,0.6,0.5,1,1,1s1-0.5,1-1v-7.4c0-1.8,1.4-3.2,3.1-3.2h0.1c1.7,0,3.2,1.5,3.2,3.3v6.6c0,0.6,0.5,1,1,1
                    c0.6,0,1-0.5,1-1V24c0-1.9,1.4-3.4,3.1-3.4c1.7,0,3.1,1.5,3.1,3.4v4.9c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-2.7
                    c0-1.9,1.4-3.4,3.1-3.4h0.1c1.7,0,3.1,1.5,3.2,3.4C56.3,32,56.3,34,56.3,39.3z M36.7,10.2h13.6l-2.4,2.4c-0.3,0.3-0.4,0.6-0.3,1
                    c0.1,0.4,0.4,0.6,0.7,0.7c0.4,0.1,0.8,0,1-0.3l4.2-4.1c0.4-0.4,0.4-1.1,0-1.5l-4.2-4.1c-0.4-0.4-1.1-0.4-1.5,0
                    c-0.4,0.4-0.4,1,0,1.5l2.4,2.4H36.7c-0.6,0-1,0.5-1,1C35.6,9.7,36.1,10.2,36.7,10.2z M5.2,10.2h13.4c0.6,0,1-0.5,1-1
                    c0-0.6-0.5-1-1-1H5.2l2.4-2.4C8,5.4,8,4.7,7.6,4.3c-0.4-0.4-1.1-0.4-1.5,0L1.9,8.5c-0.4,0.4-0.4,1.1,0,1.5L6.1,14
                    c0.3,0.3,0.7,0.4,1,0.3c0.4-0.1,0.6-0.4,0.7-0.7c0.1-0.4,0-0.7-0.3-1L5.2,10.2z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Swipe- Like or Pass'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M56.5,31.9H41.4v-6.4l7.2,3.3c0.6,0.3,1.2-0.1,1.3-0.7c4.8-24.9,6.3-29,3.9-28l-34,14.6c-0.7,0.3-0.7,1.4,0,1.7l5.8,2.8
                    H3.5c-2,0-3.5,1.6-3.5,3.5v20.5c0,2,1.6,3.5,3.5,3.5h5.4v5.3c0,0.8,0.9,1.2,1.5,0.8l7.2-6H27v4.6c0,2,1.6,3.5,3.5,3.5h11.9
                    l7.2,5.1c0.7,0.5,1.5,0,1.5-0.8v-4.3h5.4c2,0,3.5-1.6,3.5-3.5V35.5C60,33.5,58.4,31.9,56.5,31.9z M48.3,26.6l-7.4-3.4L38.3,22
                    L52.6,4.6L48.3,26.6z M36.1,21.8c-0.1,0.1-0.2,0.2-0.2,0.4l-1.8,6.3L32,20.9L47.2,8.2L36.1,21.8z M22.5,15.6L48.8,4.4L30.8,19.5
                    L22.5,15.6z M17.3,44.8c-0.2,0-0.5,0.1-0.6,0.2l-5.9,5v-4.2c0-0.6-0.4-0.9-0.9-0.9H3.5c-0.8,0-1.6-0.8-1.6-1.6V22.7
                    c0-0.8,0.8-1.6,1.6-1.6h26.1l0.6,0.3l3.1,10.9c0.3,0.9,1.6,0.9,1.8,0l2.3-8.5l2.2,1v18.3c0,0.8-0.7,1.6-1.6,1.6H17.3z M56.5,52.9
                    h-6.4c-0.6,0-0.9,0.4-0.9,0.9v3.5l-6-4.2c-0.2-0.1-0.4-0.2-0.6-0.2H30.5c-0.8,0-1.6-0.8-1.6-1.6v-4.6h9c2,0,3.5-1.6,3.5-3.5v-9.4
                    h15.1c0.8,0,1.6,0.8,1.6,1.6v15.9h0.1C58.1,52.1,57.4,52.9,56.5,52.9z M6.7,28.1h15.1c0.6,0,0.9-0.4,0.9-0.9
                    c0-0.6-0.4-0.9-0.9-0.9H6.7c-0.6,0-0.9,0.4-0.9,0.9C5.8,27.8,6.2,28.1,6.7,28.1z M6.7,33.8h15.1c0.6,0,0.9-0.4,0.9-0.9
                    c0-0.6-0.4-0.9-0.9-0.9H6.7c-0.6,0-0.9,0.4-0.9,0.9C5.8,33.4,6.2,33.8,6.7,33.8z M33.8,37.5h-27c-0.6,0-0.9,0.4-0.9,0.9
                    c0,0.6,0.4,0.9,0.9,0.9h27c0.6,0,0.9-0.5,0.9-0.9C34.7,37.9,34.3,37.5,33.8,37.5z M54.5,39.1h-9.4c-0.6,0-0.9,0.4-0.9,0.9
                    c0,0.6,0.4,0.9,0.9,0.9h9.4c0.6,0,0.9-0.4,0.9-0.9C55.4,39.5,55,39.1,54.5,39.1z M54.5,45.7h-9.4c-0.6,0-0.9,0.4-0.9,0.9
                    c0,0.6,0.4,0.9,0.9,0.9h9.4c0.6,0,0.9-0.4,0.9-0.9C55.4,46.1,55,45.7,54.5,45.7z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Instant Messaging'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M59,2.8H1c-0.6,0-1,0.4-1,0.9v52.7c0,0.5,0.4,0.9,1,0.9h58c0.6,0,1-0.4,1-0.9V3.7C60,3.2,59.6,2.8,59,2.8z M58,55.4H2
                    V11.8h56V55.4z M58,10H2V4.6h56V10z M13,8.2c0.6,0,1-0.4,1-0.9c0-0.5-0.4-0.9-1-0.9c-0.6,0-1,0.4-1,0.9C12,7.8,12.4,8.2,13,8.2z
                    M9,8.2c0.6,0,1-0.4,1-0.9c0-0.5-0.4-0.9-1-0.9S8,6.8,8,7.3C8,7.8,8.4,8.2,9,8.2z M5,8.2c0.6,0,1-0.4,1-0.9c0-0.5-0.4-0.9-1-0.9
                    S4,6.8,4,7.3C4,7.8,4.4,8.2,5,8.2z M53,8.2h2c0.6,0,1-0.4,1-0.9c0-0.5-0.4-0.9-1-0.9h-2c-0.6,0-1,0.4-1,0.9
                    C52,7.8,52.4,8.2,53,8.2z M20,35.4h20c0.6,0,1-0.4,1-0.9c0-4.3-3-8.1-7.5-9.5c2-1.7,2-4.6,0.1-6.4c-1.9-1.8-5.1-1.8-7.1-0.1
                    c-2,1.7-2,4.6-0.1,6.4c0,0,0.1,0.1,0.1,0.1C22,26.4,19,30.2,19,34.5C19,35,19.4,35.4,20,35.4z M30,19.1c1.7,0,3,1.2,3,2.7
                    c0,1.5-1.3,2.7-3,2.7c-1.7,0-3-1.2-3-2.7C27,20.3,28.3,19.1,30,19.1z M30,26.4c4.6,0,8.4,3.1,8.9,7.3H21.1
                    C21.6,29.5,25.4,26.4,30,26.4z M21,51.8c3.9,0,7-2.8,7-6.4c0-3.5-3.1-6.4-7-6.4c-3.9,0-7,2.8-7,6.4C14,48.9,17.1,51.8,21,51.8z
                    M21,40.9c1.2,0,2.4,0.4,3.3,1.2c0,0-0.1,0-0.1,0.1l-3.3,3.7l-1.2-1.1c-0.4-0.3-1-0.3-1.4,0c-0.4,0.4-0.4,0.9,0,1.3l2,1.8
                    c0.2,0.2,0.4,0.3,0.7,0.3h0.1c0.3,0,0.5-0.1,0.7-0.3l3.8-4.3c0.3,0.6,0.5,1.2,0.5,1.9c0,2.5-2.2,4.5-5,4.5c-2.8,0-5-2-5-4.5
                    C16,42.9,18.2,40.9,21,40.9z M37.7,43c-0.4-0.3-1-0.3-1.4,0c-0.4,0.4-0.4,0.9,0,1.3l1.3,1.2l-1.3,1.2c-0.4,0.3-0.4,0.9,0,1.3
                    c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0l1.3-1.2l1.3,1.2c0.4,0.3,1,0.3,1.4,0c0.4-0.4,0.4-0.9,0-1.3l-1.3-1.2l1.3-1.2
                    c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.3-1-0.3-1.4,0L39,44.2L37.7,43z M32,45.4c0,3.5,3.1,6.4,7,6.4s7-2.8,7-6.4c0-3.5-3.1-6.4-7-6.4
                    C35.1,39.1,32,41.9,32,45.4z M44,45.4c0,2.5-2.2,4.5-5,4.5s-5-2-5-4.5c0-2.5,2.2-4.5,5-4.5C41.8,40.9,44,42.9,44,45.4z M6.2,29.6
                    c0.3,0.4,0.9,0.5,1.4,0.3C8,29.6,8.1,29,7.8,28.6l-1.6-2.2l1.6-2.2c0.3-0.4,0.2-1-0.3-1.3c-0.5-0.3-1.1-0.2-1.4,0.3l-2,2.7
                    C4.1,26,4,26.1,4,26.2c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.3L6.2,29.6z M53.8,26.4l-1.6,2.2c-0.3,0.4-0.2,1,0.3,1.3
                    c0.5,0.3,1.1,0.2,1.4-0.3h0l2-2.7c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3l-2-2.7
                    c-0.3-0.4-0.9-0.5-1.4-0.3c-0.5,0.3-0.6,0.8-0.3,1.3L53.8,26.4z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Match Feed'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<path
			d="M54.6,11.3c0-2.6-2.1-4.7-4.7-4.7c-2.6,0-4.7,2.1-4.7,4.7c0,2.6,2.1,4.7,4.7,4.7C52.4,16,54.6,13.9,54.6,11.3z M47.6,11.3
                c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2C48.6,13.5,47.6,12.6,47.6,11.3z M46.4,27.7c0.5,1,1.2,2.6,3.4,2.6
                c2.2,0,2.9-1.5,3.4-2.6c0.1-0.1,1.3-2.5,1.3-2.5C57.2,20,60,14.2,60,11.3c0-5.5-4.6-10.1-10.2-10.1S39.7,5.8,39.7,11.3
                c0,2.9,2.8,8.6,5.5,13.8C45.1,25.1,46.4,27.5,46.4,27.7z M49.8,3.7c4.2,0,7.7,3.4,7.7,7.6c0,2.5-3.5,9.4-5.2,12.7
                c0,0-1.3,2.4-1.3,2.6c-0.5,1-0.6,1.2-1.1,1.2c-0.5,0-0.6-0.1-1.1-1.2c-0.1-0.1-1.3-2.6-1.3-2.6c-1.7-3.3-5.2-10.1-5.2-12.7
                C42.1,7.2,45.6,3.7,49.8,3.7z M54.8,29.8c-0.7,0-1.2,0.6-1.2,1.2c0,2-0.2,4-0.7,5.8c-4.9,1-9.9,1.8-15,2.2c0.2-2.6,0.3-5.3,0.3-8
                c0-2.4-0.1-4.7-0.2-6.9c0.4,0,0.8-0.1,1.2-0.1c0.7-0.1,1.2-0.7,1.1-1.3c-0.1-0.7-0.7-1.2-1.4-1.1c-0.4,0-0.7,0.1-1.1,0.1
                c-0.5-5.8-1.5-11-2.9-14.9c0.5,0.1,1,0.3,1.5,0.4C37,7.3,37.7,7,37.9,6.4c0.2-0.6-0.1-1.3-0.8-1.6c-2.9-1-6-1.5-9.1-1.5
                c-1.7,0-3.4,0.1-5,0.4c0,0-0.1,0-0.1,0C13.7,5.4,6.1,11.6,2.4,19.8c0,0.1-0.1,0.1-0.1,0.2C0.8,23.4,0,27.1,0,31
                c0,2.5,0.3,4.8,0.9,7.1c0,0,0,0.1,0,0.1c2.8,10.2,11.3,18.1,22,20c0,0,0.1,0,0.1,0c1.6,0.3,3.3,0.4,5,0.4c1.7,0,3.4-0.1,5-0.4
                c0,0,0.1,0,0.1,0c10.6-1.9,19.2-9.8,22-20c0,0,0-0.1,0-0.1c0.6-2.3,0.9-4.7,0.9-7.1C56,30.3,55.5,29.8,54.8,29.8z M35.7,31
                c0,2.8-0.1,5.5-0.3,8.2c-2.4,0.1-4.9,0.2-7.4,0.2s-4.9-0.1-7.4-0.2c-0.2-2.6-0.3-5.4-0.3-8.2c0-2.3,0.1-4.6,0.2-6.7
                c2.5,0.2,4.9,0.2,7.5,0.2c2.5,0,5-0.1,7.5-0.2C35.6,26.4,35.7,28.7,35.7,31z M24.1,6.1c1.3-0.2,2.6-0.3,3.9-0.3
                c1.3,0,2.6,0.1,3.9,0.3c1.6,3.9,2.8,9.4,3.4,15.8c-2.4,0.2-4.8,0.2-7.3,0.2c-2.5,0-4.9-0.1-7.3-0.2C21.3,15.5,22.5,9.9,24.1,6.1z
                M21.2,6.7c-1.4,3.9-2.4,9.1-2.9,14.9c-4.5-0.4-8.8-1.1-13-2.1C8.5,13.3,14.3,8.6,21.2,6.7z M19.3,47.9c-3.2-1.6-5.9-4.1-7.7-7.1
                c2.2,0.3,4.5,0.5,6.8,0.7C18.6,43.7,18.9,45.9,19.3,47.9z M2.5,31c0-3.2,0.6-6.3,1.7-9.1c4.4,1,9.1,1.8,13.8,2.2
                c-0.1,2.2-0.2,4.6-0.2,6.9c0,2.7,0.1,5.4,0.3,8c-2.7-0.2-5.3-0.5-7.9-0.9c-0.9-2.2-1.4-4.6-1.4-7.1c0-0.7-0.6-1.2-1.2-1.2
                c-0.7,0-1.2,0.6-1.2,1.2c0,2.3,0.4,4.5,1.1,6.6c-1.4-0.2-2.8-0.5-4.2-0.8C2.7,35,2.5,33,2.5,31z M4,39.5c1.5,0.3,3,0.6,4.5,0.8
                c2.3,4.8,6.4,8.6,11.4,10.6c0.4,1.6,0.8,3.1,1.3,4.4C13.2,53.1,6.8,47.2,4,39.5z M31.9,56c-1.3,0.2-2.6,0.3-3.9,0.3
                c-1.3,0-2.6-0.1-3.9-0.3c-0.5-1.2-1-2.6-1.4-4.2c1.7,0.4,3.5,0.7,5.3,0.7c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2
                c-2.1,0-4.1-0.3-6-0.9c-0.5-2.3-0.9-4.8-1.2-7.4c4.7,0.3,9.5,0.3,14.3,0C34.5,47.4,33.4,52.4,31.9,56z M34.8,55.3
                c1.3-3.7,2.3-8.5,2.8-13.9c4.9-0.4,9.7-1,14.4-2C49.3,47.2,42.8,53.1,34.8,55.3z"
		></path></svg
	>`,
					title: 'Geo Positioning'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<path
			d="M45.4,52.1c-8.1,0-14.6-6.5-14.6-14.4c0-8,6.6-14.4,14.6-14.4c8.1,0,14.6,6.5,14.6,14.4C60,45.6,53.4,52.1,45.4,52.1z
                M45.4,24.9c-7.1,0-12.9,5.7-12.9,12.8c0,7,5.8,12.8,12.9,12.8c7.1,0,12.9-5.7,12.9-12.8C58.3,30.6,52.5,24.9,45.4,24.9z M45.4,47.6
                c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.3-2.8,2.8-2.8s2.8,1.2,2.8,2.8C48.2,46.3,46.9,47.6,45.4,47.6z M45.4,43.7c-0.6,0-1.1,0.5-1.1,1.1
                c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C46.5,44.2,46,43.7,45.4,43.7z M46.6,41.6h-2.4c-0.4,0-0.8-0.3-0.8-0.8l-1.2-11.5
                c0-0.2,0.1-0.5,0.2-0.6c0.2-0.2,0.4-0.3,0.6-0.3h4.9c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.6l-1.2,11.5
                C47.4,41.3,47.1,41.6,46.6,41.6z M45,39.9h0.9l1-9.8h-3L45,39.9z M0.9,39.1L0.9,39.1L0.9,39.1C0.9,39.1,0.9,39.1,0.9,39.1L0.9,39.1
                c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0l0,0c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0
                c-0.1-0.1-0.1-0.2-0.2-0.3C0,38.4,0,38.3,0,38.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L0,8.8l0,0l0,0l0,0c0,0,0,0,0,0
                l0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.2,0.1-0.4,0.2-0.5c0,0,0.1-0.1,0.1-0.1C0.5,8,0.6,7.9,0.8,7.9c0,0,0,0,0,0l45.1,0c0,0,0,0,0,0h0
                h0c0.2,0,0.4,0.1,0.5,0.2c0,0,0,0,0,0l0,0c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.2,0.5l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                l0,0l0,0l0,0v11.6c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-10L29.8,21.1l3.8,3.8c0.3,0.3,0.3,0.9,0,1.2
                c-0.3,0.3-0.9,0.3-1.2,0l-4-4l-4.6,3.2c-0.3,0.2-0.7,0.2-1,0l-4.4-3.1L2.9,37.4l25,0c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8
                L0.9,39.1z M1.7,10.4l0,25.8L17,21.1L1.7,10.4z M3.5,9.6l19.8,13.9l20-13.9L3.5,9.6z"
		></path></svg
	>`,
					title: 'Report and Spam'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M6,33h48c0.6,0,1-0.4,1-1V18c0-0.6-0.4-1-1-1H6c-0.6,0-1,0.4-1,1v14C5,32.6,5.4,33,6,33z M7,19h46v12H7V19z M9,4H5
                    C4.4,4,4,4.4,4,5v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V5C10,4.4,9.6,4,9,4z M8,8H6V6h2V8z M17,4h-4c-0.6,0-1,0.4-1,1v4
                    c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V5C18,4.4,17.6,4,17,4z M16,8h-2V6h2V8z M25,4h-4c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h4
                    c0.6,0,1-0.4,1-1V5C26,4.4,25.6,4,25,4z M24,8h-2V6h2V8z M55.5,0h-51C2,0,0,2,0,4.5v51C0,58,2,60,4.5,60h51c2.5,0,4.5-2,4.5-4.5
                    v-51C60,2,58,0,55.5,0z M58,55.5c0,1.4-1.1,2.5-2.5,2.5h-51C3.1,58,2,56.9,2,55.5V14h56V55.5z M58,12H2V4.5C2,3.1,3.1,2,4.5,2h51
                    C56.9,2,58,3.1,58,4.5V12z M7,41c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4C8.8,37,7,38.8,7,41z M13,41c0,1.1-0.9,2-2,2
                    c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2C12.1,39,13,39.9,13,41z M6,55h10c0.6,0,1-0.4,1-1v-2.6c0-2.4-2-4.4-4.4-4.4H9.4
                    C7,47,5,49,5,51.4V54C5,54.6,5.4,55,6,55z M7,51.4C7,50.1,8.1,49,9.4,49h3.3c1.3,0,2.4,1.1,2.4,2.4V53H7V51.4z M22,45h22
                    c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H22c-0.6,0-1,0.4-1,1C21,44.6,21.4,45,22,45z M22,49h27c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H22
                    c-0.6,0-1,0.4-1,1C21,48.6,21.4,49,22,49z M10,24h19c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H10c-0.6,0-1,0.4-1,1C9,23.6,9.4,24,10,24z
                    M34,26H10c-0.6,0-1,0.4-1,1s0.4,1,1,1h24c0.6,0,1-0.4,1-1S34.6,26,34,26z M54,51H22c-0.6,0-1,0.4-1,1s0.4,1,1,1h32
                    c0.6,0,1-0.4,1-1S54.6,51,54,51z M48,22h-6c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3h6c1.7,0,3-1.3,3-3C51,23.3,49.7,22,48,22z M48,26h-6
                    c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1C49,25.6,48.6,26,48,26z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'User Profile'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M44.3,0H15.7c-2.9,0-5.4,2.5-5.4,5.5v49c0,3,2.4,5.5,5.4,5.5h28.6c2.9,0,5.4-2.5,5.4-5.5v-49C49.7,2.5,47.3,0,44.3,0z
                    M47.1,54.4c0.1,1.6-1.2,3-2.8,3H15.7c-1.6,0-2.9-1.4-2.9-3v-2.9h34.4V54.4z M47.3,49.1H12.7V5.5c0-1.6,1.3-3,2.9-3h28.6
                    c1.6,0,2.9,1.4,2.9,3V49.1z M32.4,38.7c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7
                    C36.1,40.4,34.4,38.7,32.4,38.7z M32.4,43.7c-0.6,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2
                    C33.7,43.2,33.1,43.7,32.4,43.7z M22,38.7c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7
                    C25.6,40.4,24,38.7,22,38.7z M22,43.7c-0.6,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2
                    C23.3,43.2,22.7,43.7,22,43.7z M43.2,13.7h-3.4c-0.5,0-0.9,0.2-1.1,0.6L37,17.7H16.8c-0.7,0-1.3,0.7-1.2,1.5
                    c2.8,14.6,2.3,11.1,2.8,17c0,0.6,0.6,1.1,1.2,1.1h13.8c0.6,0,1.1-0.4,1.2-1l1-4.6c3.9-14.3,2.8-11.2,4.9-15.5h2.7
                    c0.7,0,1.2-0.5,1.2-1.2C44.4,14.2,44,13.7,43.2,13.7z M32.4,34.8H20.7l-0.2-2.1h12.4L32.4,34.8z M33.4,30.2H20.2l-2-9.9h17.9
                    L33.4,30.2z M27.3,8.1h5.3c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2h-5.3c-0.7,0-1.2,0.5-1.2,1.2C26.1,7.6,26.7,8.1,27.3,8.1z
                    "
				></path>
			</g>
		</g></svg
	>`,
					title: 'In-App Purchases'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 60 60"
		style="enable-background: new 0 0 60 60"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M25,40h-6c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h22c0.6,0,1,0.4,1,1v36c0,0.6-0.4,1-1,1h-6v2h6c1.7,0,3-1.3,3-3V3
                    c0-1.7-1.3-3-3-3H19c-1.7,0-3,1.3-3,3v36c0,1.7,1.3,3,3,3h6V40z M0,7v28c0,1.7,1.3,3,3,3h11v-2H3c-0.6,0-1-0.4-1-1V7
                    c0-0.6,0.4-1,1-1h11V4H3C1.3,4,0,5.3,0,7z M24,7h2V5h-2V7z M36,5h-8v2h8V5z M23,14v-4h-2v4H23z M25,14h2v-4h-2V14z M29,10v4h2v-4
                    H29z M33,10v4h2v-4H33z M37,10v4h2v-4H37z M29,19h7v-2h-7V19z M27,35h6c1.7,0,3-1.3,3-3c0-1.7-1.3-3-3-3h-6c-1.7,0-3,1.3-3,3
                    C24,33.7,25.3,35,27,35z M27,31h6c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1C26,31.4,26.4,31,27,31z M11,10h3V8h-3V10z
                    M6,13H4v3h2V13z M8,16h2v-3H8V16z M12,16h2v-3h-2V16z M7,10h2V8H7V10z M5,30c0,1.7,1.3,3,3,3h6v-2H8c-0.6,0-1-0.4-1-1s0.4-1,1-1
                    h6v-2H8C6.3,27,5,28.3,5,30z M57,4H46v2h11c0.6,0,1,0.4,1,1v28c0,0.6-0.4,1-1,1H46v2h11c1.7,0,3-1.3,3-3V7C60,5.3,58.7,4,57,4z
                    M53,8h-7v2h7V8z M56,13h-2v3h2V13z M52,16v-3h-2v3H52z M48,13h-2v3h2V13z M55,30c0-1.7-1.3-3-3-3h-6v2h6c0.6,0,1,0.4,1,1
                    s-0.4,1-1,1h-6v2h6C53.7,33,55,31.7,55,30z M42.5,45c-0.9-0.1-1.8,0.1-2.4,0.7c0,0,0,0-0.1,0.1c-1.1-1-2.9-1-4,0
                    c-0.8-0.7-2-0.9-3-0.6v-5.1c0-1.5-1-2.8-2.5-3.1c-1.6-0.3-3.2,0.8-3.5,2.5c0,0.2,0,0.3,0,0.5v12.2l-2.7-1.6
                    c-0.8-0.5-1.8-0.5-2.7-0.1l-0.2,0.1c-1.4,0.7-1.9,2.3-1.2,3.7c0.2,0.4,0.5,0.8,0.9,1l6.3,4.5c0.2,0.1,0.4,0.2,0.6,0.2h16
                    c0.6,0,1-0.4,1-1V48.1C45,46.6,44,45.3,42.5,45z M43,58H28.3l-6-4.3c-0.3-0.2-0.4-0.7-0.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2l0.2-0.1
                    c0.2-0.1,0.5-0.1,0.7,0l4.2,2.5c0.5,0.3,1.1,0.1,1.4-0.3C29,54.4,29,54.2,29,54V40c0-0.6,0.4-1,1-1c0.1,0,0.1,0,0.2,0
                    c0.5,0.1,0.8,0.6,0.8,1.1V48c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1c0,0.6,0.4,1,1,1s1-0.4,1-1
                    c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1c0-0.6,0.4-1,1-1c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.6,0.8,1.1V58
                    z M24,19h3v-2h-3V19z M24,23h12v-2H24V23z M34,27v-2h-8v2H34z M8,21h6v-2H8V21z M10,25h4v-2h-4V25z M51,19h-5v2h5V19z M49,23h-3v2
                    h3V23z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Subscriptions'
				},
				{
					svg: `<svg
		x="0px"
		y="0px"
		viewBox="0 0 50 50"
		style="enable-background: new 0 0 50 50"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M1.72,7.13h1.63v1.6c0,0.59,0.48,1.07,1.08,1.07s1.08-0.48,1.08-1.07v-1.6h1.63c0.6,0,1.08-0.48,1.08-1.07
                    c0-0.59-0.48-1.07-1.08-1.07H5.51v-1.6c0-0.59-0.48-1.07-1.08-1.07S3.35,2.8,3.35,3.39v1.6H1.72c-0.6,0-1.08,0.48-1.08,1.07
                    C0.64,6.65,1.12,7.13,1.72,7.13z M48.92,27.29h-1.63v-1.6c0-0.59-0.48-1.07-1.08-1.07c-0.6,0-1.08,0.48-1.08,1.07v1.6H43.5
                    c-0.6,0-1.08,0.48-1.08,1.07c0,0.59,0.48,1.07,1.08,1.07h1.62v1.6c0,0.59,0.48,1.07,1.08,1.07c0.6,0,1.08-0.48,1.08-1.07v-1.6
                    h1.63c0.6,0,1.08-0.48,1.08-1.07C50,27.76,49.52,27.29,48.92,27.29z M45.67,11.7c1.64,0,2.98-1.32,2.98-2.94
                    c0-1.62-1.33-2.95-2.98-2.95s-2.98,1.32-2.98,2.95C42.69,10.38,44.02,11.7,45.67,11.7z M45.67,7.96c0.45,0,0.81,0.36,0.81,0.81
                    c0,0.44-0.36,0.8-0.81,0.8c-0.45,0-0.81-0.36-0.81-0.8S45.22,7.96,45.67,7.96z M5.96,23.36c0-1.62-1.33-2.95-2.98-2.95
                    C1.34,20.41,0,21.73,0,23.36c0,1.62,1.33,2.94,2.98,2.94C4.62,26.3,5.96,24.98,5.96,23.36z M2.98,24.16
                    c-0.45,0-0.82-0.36-0.82-0.8c0-0.44,0.36-0.81,0.82-0.81c0.45,0,0.81,0.36,0.81,0.81C3.79,23.81,3.43,24.16,2.98,24.16z
                    M6.13,13.31c0,0.74,0.61,1.34,1.36,1.34c0.75,0,1.36-0.6,1.36-1.34c0-0.74-0.61-1.34-1.36-1.34C6.74,11.97,6.13,12.57,6.13,13.31
                    z M40,3.68c0.75,0,1.36-0.6,1.36-1.34C41.36,1.6,40.75,1,40,1c-0.75,0-1.36,0.6-1.36,1.34C38.64,3.08,39.25,3.68,40,3.68z
                    M43.5,22.47c0.75,0,1.36-0.6,1.36-1.34c0-0.74-0.61-1.34-1.36-1.34c-0.75,0-1.36,0.6-1.36,1.34
                    C42.14,21.87,42.75,22.47,43.5,22.47z M40.87,34.32c-1.15-1.45-1.77-3.27-1.77-5.11v-7.62c0-7.73-6.07-14.26-13.64-14.82v-2.4
                    c0-0.59-0.48-1.07-1.08-1.07s-1.08,0.48-1.08,1.07v2.4C19.86,7,16.66,8.4,14.15,10.8c-2.9,2.77-4.5,6.48-4.5,10.46v7.95
                    c0,1.84-0.63,3.66-1.77,5.11c-1.07,1.36-1.96,2.84-2.65,4.42c-0.54,1.22-0.41,2.6,0.33,3.72c0.75,1.13,2.02,1.81,3.39,1.81H18
                    c0.52,3.02,3.18,5.33,6.38,5.33s5.86-2.31,6.38-5.33h9.05c1.37,0,2.63-0.68,3.39-1.81c0.74-1.11,0.86-2.51,0.33-3.72
                    C42.83,37.17,41.94,35.68,40.87,34.32z M24.38,47.46c-2,0-3.68-1.36-4.17-3.19h8.33C28.06,46.1,26.38,47.46,24.38,47.46z
                    M41.38,41.29c-0.35,0.53-0.94,0.85-1.58,0.85H8.95c-0.64,0-1.23-0.32-1.58-0.85c-0.34-0.52-0.4-1.13-0.15-1.69
                    c0.62-1.41,1.42-2.74,2.38-3.96c1.44-1.82,2.23-4.11,2.23-6.42v-7.95c0-3.4,1.36-6.57,3.84-8.92c2.36-2.25,5.44-3.47,8.71-3.47
                    c0.15,0,0.31,0,0.46,0.01c6.67,0.24,12.09,5.94,12.09,12.71v7.62c0,1.6,0.39,3.19,1.1,4.63H28.3c-0.6,0-1.08,0.48-1.08,1.07
                    c0,0.59,0.48,1.07,1.08,1.07h11.11c0.47,0.62,0.89,1.27,1.27,1.94h-6.67c-0.6,0-1.08,0.48-1.08,1.07c0,0.59,0.48,1.07,1.08,1.07
                    h7.65C41.73,40.48,41.64,40.91,41.38,41.29z M28.9,14.31c0.19,0.12,0.39,0.18,0.6,0.18c0.35,0,0.69-0.16,0.9-0.47
                    c0.34-0.49,0.2-1.15-0.3-1.49c-1.6-1.06-3.45-1.66-5.35-1.73c-1-0.03-1.98,0.07-2.93,0.3c-0.58,0.14-0.94,0.72-0.79,1.29
                    c0.14,0.57,0.73,0.92,1.31,0.78c0.76-0.19,1.56-0.26,2.34-0.24C26.18,13,27.63,13.47,28.9,14.31z M18.47,12.58
                    c-0.42,0.28-0.82,0.59-1.2,0.93c-0.44,0.39-0.48,1.08-0.07,1.51c0.22,0.23,0.51,0.35,0.8,0.35c0.26,0,0.52-0.09,0.72-0.28
                    c0.3-0.27,0.63-0.52,0.96-0.74c0.5-0.33,0.63-1,0.29-1.49C19.64,12.38,18.96,12.25,18.47,12.58z M16.55,15.83
                    c-0.52-0.29-1.19-0.1-1.47,0.42c-0.85,1.53-1.3,3.26-1.3,5.01v5.28c0,0.59,0.48,1.07,1.08,1.07c0.6,0,1.08-0.48,1.08-1.07v-5.28
                    c0-1.39,0.36-2.77,1.03-3.99C17.27,16.76,17.08,16.11,16.55,15.83z M30.22,37.92h-4.87c-0.6,0-1.08,0.48-1.08,1.07
                    c0,0.59,0.48,1.07,1.08,1.07h4.87c0.6,0,1.08-0.48,1.08-1.07C31.3,38.4,30.82,37.92,30.22,37.92z"
				></path>
			</g>
		</g></svg
	>`,
					title: 'Push Notification'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
