<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <Vistion></Vistion>

      <Advantages></Advantages>

      <WhyChooseUs></WhyChooseUs>

      <TypeDiv></TypeDiv>

      <Portfolio></Portfolio>

      <Const></Const>

  </div>
</template>

<script>
import Banner from '../../components/WebDevelopment/NodeDevelopment/Banner.vue'
import Vistion from '../../components/WebDevelopment/NodeDevelopment/Vistion.vue'
import Advantages from '../../components/WebDevelopment/NodeDevelopment/Advantages.vue'
import WhyChooseUs from '../../components/WebDevelopment/NodeDevelopment/WhyChooseUs.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/WebDevelopment/NodeDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        TypeDiv,
        Advantages,
        WhyChooseUs,
        Portfolio,
        Const,
        Vistion,
    }
}
</script>


<style>

</style>