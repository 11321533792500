<template>
	<section class="uiux-app-design-main padding-60 p-relative bg-light">
		<div class="container mt-5">
			<div class="title">
				<span>Web Application Design</span>
				<h2 class="fxt-process-dev">Web Application UX/UI Design and Development Services</h2>
				<p>
					Bring in users to your website let the design speak the rest. Hire us as your UX UI
					company for creating smooth, feature-rich, sleek yet stylish website for maximum
					conversions.
				</p>
			</div>
			<div class="row">
				<div class="col-lg-1 col-12"></div>
				<div class="col-lg-5 col-md-6 col-12 mb-30">
					<div class="uiux-app-design-box d-flex">
						<div class="uiux-app-design-icon box-shadow">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 40 40"
								style="enable-background: new 0 0 40 40"
								xml:space="preserve"
							>
								<path d="M2.7,0.2v40h34.7v-40H2.7z M36,1.5v4H4v-4H36z M4,38.8v-32h32v32H4z"></path>
								<rect x="5.3" y="2.8" width="1.3" height="1.3"></rect>
								<rect x="8" y="2.8" width="1.3" height="1.3"></rect>
								<rect x="10.7" y="2.8" width="1.3" height="1.3"></rect>
								<path
									d="M11.6,10.6l-1.5-2.3l-1.5,2.3c-1.2,1.8-1.3,4.1-0.4,6l0.3,0.7c-0.3,0.3-0.6,0.7-0.7,1.1l-0.3,1.7c-0.1,0.5-0.1,0.9-0.1,1.4
              l0.6,14.1c0.1,1.1,0.9,1.9,2,1.9H10c1.1,0,2-0.9,2-1.9l0.6-14.1c0-0.5-0.1-0.9-0.1-1.4l-0.3-1.7c-0.1-0.4-0.3-0.8-0.6-1.1l0.5-0.7
              C12.9,14.7,12.8,12.4,11.6,10.6z M10.7,35.6c0,0.4-0.3,0.7-0.7,0.7H9.9c-0.3,0-0.7-0.3-0.7-0.7L8.7,21.5c0-0.4,0-0.7,0.1-1.1
              l0.3-1.7c0.1-0.3,0.3-0.5,0.7-0.5h0.5c0.3,0,0.6,0.2,0.7,0.5l0.3,1.7c0.1,0.3,0.1,0.7,0.1,1.1L10.7,35.6z M10.2,16.8H9.7l0,0L9.3,16
              c-0.8-1.5-0.7-3.3,0.3-4.7l0.4-0.6l0.4,0.6c0.9,1.4,1,3.2,0.3,4.7L10.2,16.8L10.2,16.8z"
								></path>
								<path
									d="M22.7,8.2h-5.3c-1.1,0-2,0.9-2,2v25.3c0,1.1,0.9,2,2,2h5.3c1.1,0,2-0.9,2-2V10.2C24.7,9,23.8,8.2,22.7,8.2z M23.3,35.5
              c0,0.4-0.3,0.7-0.7,0.7h-5.3c-0.4,0-0.7-0.3-0.7-0.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7
              v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3h2.7v-1.3h-2.7v-1.3c0-0.4,0.3-0.7,0.7-0.7h5.3
              c0.4,0,0.7,0.3,0.7,0.7V35.5z"
								></path>
								<path
									d="M27.3,16v18.8c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7V16L30,8L27.3,16z M31.1,15.5h-2.1l1.1-3.2L31.1,15.5z M31.3,16.8v14
              h-2.7v-14H31.3z M30,36.2c-0.7,0-1.3-0.6-1.3-1.3v-2.7h2.7v2.7C31.3,35.6,30.7,36.2,30,36.2z"
								></path>
							</svg>
						</div>
						<div class="uiux-app-design-content">
							<h4>Custom Web Application Design</h4>
							<p>
								Take your business online with custom web development as we ensure offering best
								UX/UI design solutions for creating responsive website and impactful engagement.
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-5 col-md-6 col-12 mb-30">
					<div class="uiux-app-design-box d-flex">
						<div class="uiux-app-design-icon box-shadow">
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M98.2,7.5H1.8C0.8,7.5,0,8.3,0,9.2v69.1c0,1,0.8,1.8,1.8,1.8h6c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H3.6V35.8L96.4,36
                  v40.5H86.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.1c1,0,1.8-0.8,1.8-1.8V9.2C100,8.3,99.2,7.5,98.2,7.5z M96.4,32.5
                  L3.6,32.3V11h92.9V32.5z M16.1,28.7h28.6c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1H16.1c-3.9,0-7.1,3.2-7.1,7.1
                  S12.1,28.7,16.1,28.7z M16.1,18.1h28.6c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.5-3.6,3.5H16.1c-2,0-3.6-1.6-3.6-3.5
                  C12.5,19.7,14.1,18.1,16.1,18.1z M85.7,28.7c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1S81.8,28.7,85.7,28.7z
                  M85.7,18.1c2,0,3.6,1.6,3.6,3.5c0,2-1.6,3.5-3.6,3.5c-2,0-3.6-1.6-3.6-3.5C82.1,19.7,83.7,18.1,85.7,18.1z M69.6,28.7
                  c3.9,0,7.1-3.2,7.1-7.1s-3.2-7.1-7.1-7.1c-3.9,0-7.1,3.2-7.1,7.1S65.7,28.7,69.6,28.7z M69.6,18.1c2,0,3.6,1.6,3.6,3.5
                  c0,2-1.6,3.5-3.6,3.5c-2,0-3.6-1.6-3.6-3.5C66.1,19.7,67.7,18.1,69.6,18.1z M85.7,50.1c1,0,1.8-0.8,1.8-1.8v-5.8
                  c0-1-0.8-1.8-1.8-1.8H14.3c-1,0-1.8,0.8-1.8,1.8v20.2c0,1,0.8,1.8,1.8,1.8h48c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H16.1V44.3
                  h67.9v4C83.9,49.3,84.7,50.1,85.7,50.1z M86.3,53.6c-0.2-0.1-0.4-0.1-0.6-0.1H75c-0.7,0-1.3,0.4-1.6,1l-7.6,16.7H21.4
                  c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9h14.5c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8H21.4c-3,0-5.4-2.4-5.4-5.3
                  c0-2.9,2.4-5.3,5.4-5.3h45.5c0.7,0,1.3-0.4,1.6-1l7.6-16.7h7.1l-4.7,15.4c0,0.2,0,0.3,0,0.5v12.4h-1.9c-0.4-3-3.1-5.3-6.2-5.3h-25
                  c-3.5,0-6.3,2.8-6.3,6.2c0,3.4,2.8,6.2,6.3,6.2h25c2.4,0,4.6-1.4,5.6-3.5h4.2c1,0,1.8-0.8,1.8-1.8V73.3l5.4-17.5
                  C87.8,54.9,87.3,53.9,86.3,53.6z M70.5,89h-25c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7h25c1.5,0,2.7,1.2,2.7,2.7
                  C73.2,87.8,72,89,70.5,89z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<div class="uiux-app-design-content">
							<h4>Crafting Landing Page Design</h4>
							<p>
								Hold your visitors at landing page and drive conversion on the go with our top UX UI
								design services for website that connects to user instantly,
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="uiux-app-design-main-image uiux-web-app mt-30">
				<img
					src="../../../assets/images/uiux-web-app-design.webp"
					alt="ux services"
					class="mainimg"
				/>
				<div class="laptop wow uiux-web-app-laptop" data-wow-duration="1.2s" data-wow-delay="1.5s">
					<img
						alt=""
						class="lazy"
						src="../../../assets/images/uiux-web-app-design-laptop.webp"
						style=""
					/>
				</div>
				<div class="desktop wow uiux-web-app-desktop" data-wow-duration=".5s" data-wow-delay=".5s">
					<img
						alt=""
						class="lazy"
						src="../../../assets/images/uiux-web-app-design-desktop.webp"
						style=""
					/>
				</div>
				<div class="ipad wow uiux-web-app-ipad" data-wow-duration="1.2s" data-wow-delay="3.2s">
					<img
						alt=""
						class="lazy"
						src="../../../assets/images/uiux-web-app-design-ipad.webp"
						style=""
					/>
				</div>
				<div class="iphone wow uiux-web-app-iphone" data-wow-duration="1.2s" data-wow-delay="4.4s">
					<img
						alt=""
						class="lazy"
						src="../../../assets/images/uiux-web-app-design-iphone.webp"
						style=""
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	mounted() {
		const container = document.querySelector('.fxt-uiux-mobile-app');
		const containerTop = document.querySelector('.fxt-uiux-mobile-app').offsetTop + 100;
		const constainerBottom =
			document.querySelector('.fxt-uiux-mobile-app').offsetTop +
			document.querySelector('.fxt-uiux-mobile-app').offsetHeight;
		const container2 = document.querySelector('.uiux-mobile-app-one');
		const container3 = document.querySelector('.uiux-mobile-app-two');
		const container4 = document.querySelector('.uiux-mobile-app-four');
		const container5 = document.querySelector('.uiux-mobile-app-five');
		let lastScrollTop = 0;
		let x = 200;
		window.addEventListener(
			'scroll',
			function () {
				if (
					window.scrollY + window.innerHeight - 75 >= containerTop &&
					window.scrollY < constainerBottom
				) {
					let st = window.pageYOffset || container.scrollTop;
					if (st > lastScrollTop) {
						// 240 step
						// 200 at the top to -100 at the bottom
						x = x - 1.4;
						container2.style.transform = `translateX(${x * 1.2}px)`;
						container3.style.transform = `translateX(${x * 1.2}px)`;
						container4.style.transform = `translateX(${-x * 1.2}px)`;
						container5.style.transform = `translateX(${-x * 1.2}px)`;
						console.log(x);
						// console.log("down")
					} else {
						x = x + 1.4;
						container2.style.transform = `translateX(${x * 0.8}px)`;
						container3.style.transform = `translateX(${x * 0.5}px)`;
						container4.style.transform = `translateX(${-x * 0.5}px)`;
						container5.style.transform = `translateX(${-x * 0.8}px)`;
						console.log(x);
						// console.log("up")
					}
					lastScrollTop = st <= 0 ? 0 : st;
				} else {
					x = 200;
				}
			},
			false
		);
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.uiux-app-design-icon {
	width: 90px;
	height: 90px;
	border: 1px solid #f6f8fa;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}

.uiux-app-design-icon svg {
	width: 52px;
	height: 52px;
}
.uiux-app-design-icon svg circle,
.uiux-app-design-icon svg ellipse,
.uiux-app-design-icon svg path,
.uiux-app-design-icon svg polygon,
.uiux-app-design-icon svg rect {
	fill: #202122;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.uiux-app-design-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}

.uiux-app-design-content h4 {
	font-size: 20px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.uiux-app-design-box {
	max-width: 360px;
	width: 100%;
	margin: 0 auto;
}
.uiux-app-design-icon {
	width: 90px;
	height: 90px;
	border: 1px solid #f6f8fa;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.uiux-app-design-icon svg {
	width: 52px;
	height: 52px;
}
.uiux-app-design-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
.uiux-app-design-content h4 {
	font-size: 20px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.uiux-app-design-main-image.uiux-web-app {
	position: relative;
}
.mt-30 {
	margin-top: 30px !important;
}
.uiux-app-design-main-image.uiux-web-app .mainimg {
	opacity: 0;
	visibility: hidden;
}

img {
	max-width: 100%;
	height: auto;
}
.uiux-app-design-main-image.uiux-web-app .laptop {
	z-index: 2;
	width: 44.5%;
}

.uiux-app-design-main-image.uiux-web-app > div {
	position: absolute;
	bottom: 0;
}
.uiux-web-app-laptop {
	-webkit-animation-name: uiux-web-app-laptop;
	animation-name: uiux-web-app-laptop;
	left: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.uiux-app-design-main-image.uiux-web-app .desktop {
	z-index: 1;
	width: 53%;
}
.uiux-app-design-main-image.uiux-web-app > div {
	position: absolute;
	bottom: 0;
}
.uiux-web-app-desktop {
	-webkit-animation-name: uiux-web-app-desktop;
	animation-name: uiux-web-app-desktop;
	left: 50%;
	-webkit-transform: translate3d(-40%, 0, 0);
	-moz-transform: translate3d(-40%, 0, 0);
	-o-transform: translate3d(-40%, 0, 0);
	-ms-transform: translate3d(-40%, 0, 0);
	transform: translate3d(-40%, 0, 0);
}
.uiux-app-design-main-image.uiux-web-app .ipad {
	z-index: 3;
	width: 22%;
}

.uiux-app-design-main-image.uiux-web-app > div {
	position: absolute;
	bottom: 0;
}
.uiux-web-app-ipad {
	-webkit-animation-name: uiux-web-app-ipad;
	animation-name: uiux-web-app-ipad;
	right: 8%;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.uiux-app-design-main-image.uiux-web-app .iphone {
	z-index: 4;
	width: 11%;
}

.uiux-app-design-main-image.uiux-web-app > div {
	position: absolute;
	bottom: 0;
}
.uiux-web-app-iphone {
	-webkit-animation-name: uiux-web-app-iphone;
	animation-name: uiux-web-app-iphone;
	right: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

</style>
