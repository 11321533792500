<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>How we Build it?</span>
				<h2 class="fxt-tech-use">Technology Stack Behind ERP Development</h2>
			</div>
			<div class="technologies_tabs fxt-technologies-tabs">
				<ul class="nav nav-tabs" id="technologies_tabs" role="tablist">
					<li>
						<a
							class="active"
							id="Application-tab"
							data-toggle="tab"
							href="#Application"
							role="tab"
							aria-controls="Application"
							aria-selected="true"
							>Application</a
						>
					</li>
					<li>
						<a
							id="payment-gateway-tab"
							data-toggle="tab"
							href="#payment-gateway"
							role="tab"
							aria-controls="payment-gateway"
							aria-selected="true"
							>Payment Gateway</a
						>
					</li>
					<li>
						<a
							id="backend-tab"
							data-toggle="tab"
							href="#backend"
							role="tab"
							aria-controls="backend"
							aria-selected="true"
							>Backend</a
						>
					</li>
					<li>
						<a
							id="database-server-tab"
							data-toggle="tab"
							href="#database"
							role="tab"
							aria-controls="database"
							aria-selected="true"
							>Database &amp; Server</a
						>
					</li>
					<li>
						<a
							id="sms-email-tab"
							data-toggle="tab"
							href="#sms-email"
							role="tab"
							aria-controls="sms-email"
							aria-selected="true"
							>SMS &amp; E-mail</a
						>
					</li>
					<li>
						<a
							id="map-and-navigation-tab"
							data-toggle="tab"
							href="#map-and-navigation"
							role="tab"
							aria-controls="map-and-navigation"
							aria-selected="true"
							>Map &amp; Navigation</a
						>
					</li>
				</ul>
				<div class="tab-content" id="technologies_tabs_Content">
					<div
						class="tab-pane fade fxt-technologies-tabs show active"
						id="Application"
						role="tabpanel"
						aria-labelledby="Application-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" alt="" src="../../../assets/images/php.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/node.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/angular.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/my-sql.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/amazon-rds.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/aws-ec2.png" style="" />
							</li>
							<li>
								<img class="lazy" alt="" src="../../../assets/images/aws-s3.png" style="" />
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-technologies-tabs"
						id="payment-gateway"
						role="tabpanel"
						aria-labelledby="payment-gateway-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" src="../../../assets/images/php.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/node.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/angular.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/my-sql.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/amazon-rds.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-ec2.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-s3.png" alt="" />
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-technologies-tabs"
						id="backend"
						role="tabpanel"
						aria-labelledby="backend-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" src="../../../assets/images/php.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/node.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/angular.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/my-sql.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/amazon-rds.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-ec2.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-s3.png" alt="" />
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-technologies-tabs"
						id="database"
						role="tabpanel"
						aria-labelledby="database-server-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" src="../../../assets/images/php.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/node.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/angular.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/my-sql.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/amazon-rds.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-ec2.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-s3.png" alt="" />
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-technologies-tabs"
						id="sms-email"
						role="tabpanel"
						aria-labelledby="sms-email-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" src="../../../assets/images/php.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/node.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/angular.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/my-sql.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/amazon-rds.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-ec2.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-s3.png" alt="" />
							</li>
						</ul>
					</div>
					<div
						class="tab-pane fade fxt-technologies-tabs"
						id="map-and-navigation"
						role="tabpanel"
						aria-labelledby="map-and-navigation-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img class="lazy" src="../../../assets/images/php.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/node.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/angular.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/my-sql.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/amazon-rds.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images/aws-ec2.png" alt="" />
							</li>
							<li>
								<img class="lazy" src="../../../assets/images//aws-s3.png" alt="" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.fxt-technologies-tabs ul {
		padding:0 !important
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
</style>
