<template>
  <div class="game-service-offer-box rounded10 mhbox">
                  <div class="game-service-offer-icon box-shadow" v-html="obj.svg">
                  </div>
                  <h3><router-link v-bind:to="obj.link">{{obj.title}}</router-link></h3>
                  <p>{{obj.text}}</p>
                  <router-link class="btn-custome btn waves-effect waves-light" v-bind:to="obj.link">Explore More</router-link>
              </div>
</template>

<script>
export default {
    props:{
        obj : {
            type: Object,
            default: () => {return {}},
            required : true
        }
    }
}
</script>

<style>
.game-service-offer-box {
	border: 1px solid #ecedee;
	padding: 30px 20px;
	background-color: #fff;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	min-height: 360px;
	margin: 70px 0 30px;
}
.m-height-450 .mhbox {
	min-height: 450px;
}
.game-service-offer-icon {
	width: 100px;
	height: 100px;
	margin: -86px 0 30px;
	background-color: #fff;
	border-radius: 100%;
	border: 1px solid #ecedee;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	-moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.game-service-offer-icon svg {
	width: 55px;
}

.game-service-offer-box h3 a {
	color: #202122;
}
.game-service-offer-box h3 a:focus, .game-service-offer-box h3 a:hover {
	color: #1977cc;
}

.game-service-offer-icon svg circle, .game-service-offer-icon svg ellipse, .game-service-offer-icon svg path, .game-service-offer-icon svg polygon, .game-service-offer-icon svg rect {
	fill: #202122;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.game-service-offer-box h3 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.game-service-offer-box:hover svg circle, .game-service-offer-box:hover svg ellipse, .game-service-offer-box:hover svg path, .game-service-offer-box:hover svg polygon, .game-service-offer-box:hover svg rect {
	fill: #fff;
}
.game-service-offer-box:hover .game-service-offer-icon {
	background-color: #1977cc;
}

.game-service-offer-box:hover {
	border: 1px solid #1977cc;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
	-moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
	box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
}

</style>

