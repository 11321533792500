<template>

		<div class="mvp-what-we-offer-box rounded10">
			<div class="mvp-what-we-offer-image rounded10 o-hidden">
				<img
					class="lazy"
					alt=""
					:src="`${obj.imageLink}`"
					style=""
				/>
			</div>
			<h4>{{ obj.title }}</h4>
			<p>
				{{ obj.text }}
			</p>
			<a
				:href="`${obj.exploreLink}`"
				class="btn-custome btn-custome-small btn waves-effect waves-light"
				>Explore More</a
			>
		</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.mvp-what-we-offer-box {
    padding: 10px;
    border: 1px solid #e4e6e8;
    min-height: 590px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: relative;
    overflow: hidden;
}
.mvp-what-we-offer-image {
    margin-bottom: 15px;
}

.rounded10 {
    border-radius: 10px!important;
}
.o-hidden {
    overflow: hidden;
}
.mvp-what-we-offer-box:hover h4 {
	color:#008dd2
}
.mvp-what-we-offer-box h4 {
    color: #202122;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.mvp-what-we-offer-box {
	transition:0.3s
}
.mvp-what-we-offer-box:hover {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.mvp-what-we-offer-box .mvp-what-we-offer-image img {
	transition:0.3s
}
.mvp-what-we-offer-box:hover .mvp-what-we-offer-image img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}
</style>
