<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Our Dating App Developers Are Your Perfect Match! </span>
				<h2 class="fxt-process-dev">Our USPs That Will Prove to Be Advantageous for You</h2>
				<p>
					Get to know some of our many USPs as a dating app development company that will help you
					build a dating app in any mobile platform in the shortest time span possible and with
					top-notch quality.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
					<div class="why-you-invest-box-text">
						<h5 class="mb-20">Our Clients Love Us</h5>
						<a class="btn-custome btn waves-effect waves-light" href="/client-review"
							>Our Reviews</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../../EnterpriseSolutions/AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M6.67,3.33c0-0.92,0.75-1.67,1.67-1.67h33.33c0.92,0,1.67,0.75,1.67,1.67H45C45,1.49,43.51,0,41.67,0H8.33
                    C6.49,0,5,1.49,5,3.33v8.33h1.67V3.33z M43.33,46.67c0,0.92-0.75,1.67-1.67,1.67c-0.92,0-1.67-0.75-1.67-1.67v-2.5
                    c0-0.46-0.37-0.83-0.83-0.83H6.67V26.67H5v16.67H0.83C0.37,43.33,0,43.71,0,44.17v2.5C0,48.51,1.49,50,3.33,50h38.33
                    c1.84,0,3.33-1.49,3.33-3.33v-3.33h-1.67V46.67z M3.33,48.33c-0.92,0-1.67-0.75-1.67-1.67V45h36.67v1.67
                    c0,0.59,0.15,1.16,0.45,1.67H3.33z M0.83,25H17.5c0.46,0,0.83-0.37,0.83-0.83v-10c0-0.46-0.37-0.83-0.83-0.83H0.83
                    C0.37,13.33,0,13.71,0,14.17v10C0,24.63,0.37,25,0.83,25z M1.67,15h15v8.33h-15V15z M5.24,16.91l-1.67,1.67
                    c-0.33,0.33-0.33,0.85,0,1.18l1.67,1.67l1.18-1.18l-1.08-1.08l1.08-1.08L5.24,16.91z M13.09,16.91l-1.18,1.18l1.08,1.08
                    l-1.08,1.08l1.18,1.18l1.67-1.67c0.33-0.33,0.33-0.85,0-1.18L13.09,16.91z M7.54,21.4l1.58,0.53l1.67-5L9.21,16.4L7.54,21.4z
                    M49.17,5H20.83C20.37,5,20,5.37,20,5.83V22.5c0,0.46,0.37,0.83,0.83,0.83h28.33c0.46,0,0.83-0.37,0.83-0.83V5.83
                    C50,5.37,49.63,5,49.17,5z M48.33,21.67H21.67v-2.03l7.48-4.49l7.3,4.72c0.25,0.16,0.57,0.18,0.83,0.04l6.03-3.12l5.02,2.87V21.67
                    z M48.33,17.73l-4.58-2.62c-0.25-0.14-0.55-0.15-0.8-0.02l-6.01,3.11l-7.32-4.74c-0.27-0.17-0.61-0.18-0.88-0.01l-7.07,4.24V6.67
                    h26.67V17.73z M39.58,14.17c1.61,0,2.92-1.31,2.92-2.92c0-1.61-1.31-2.92-2.92-2.92c-1.61,0-2.92,1.31-2.92,2.92
                    C36.67,12.86,37.97,14.17,39.58,14.17z M39.58,10c0.69,0,1.25,0.56,1.25,1.25c0,0.69-0.56,1.25-1.25,1.25s-1.25-0.56-1.25-1.25
                    C38.33,10.56,38.89,10,39.58,10z M8.33,27.5v10c0,0.46,0.37,0.83,0.83,0.83H30c0.46,0,0.83-0.37,0.83-0.83v-10
                    c0-0.46-0.37-0.83-0.83-0.83H9.17C8.71,26.67,8.33,27.04,8.33,27.5z M10,28.33h19.17v8.33H10V28.33z M15,30h-3.33v1.67H15V30z
                    M24.17,30h-7.5v1.67h7.5V30z M25.83,31.67h1.67V30h-1.67V31.67z M11.67,35h10v-1.67h-10V35z M23.33,35h4.17v-1.67h-4.17V35z
                    M8.33,3.33V5H10V3.33H8.33z M11.67,3.33V5h1.67V3.33H11.67z M15,3.33V5h1.67V3.33H15z M8.33,8.33h10V6.67h-10V8.33z M49.17,30
                    H33.33c-0.46,0-0.83,0.37-0.83,0.83v10c0,0.46,0.37,0.83,0.83,0.83h15.83c0.46,0,0.83-0.37,0.83-0.83v-10
                    C50,30.37,49.63,30,49.17,30z M48.33,40H34.17v-8.33h14.17V40z M37.5,33.33h-1.67V35h1.67V33.33z M37.5,36.67h-1.67v1.67h1.67
                    V36.67z M46.67,33.33h-7.5V35h7.5V33.33z M46.67,36.67h-7.5v1.67h7.5V36.67z M43.33,25v3.33H45V25H43.33z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Custom Design',
					text: 'We have UI/UX designers who will craft every pixel of the dating app with custom designs. From the app’s workflow to visual appeal of the interface, we will create a custom design for your dating app  that will stand out from the crowd of dating apps.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M46.67,6.67v1.67c0.92,0,1.67,0.75,1.67,1.67v25H1.67V10c0-0.92,0.75-1.67,1.67-1.67V6.67C1.49,6.67,0,8.16,0,10v28.33
                    c0,1.84,1.49,3.33,3.33,3.33h16.38l-2,6.67h-1.88V50h18.33v-1.67h-1.88l-2-6.67h16.38c1.84,0,3.33-1.49,3.33-3.33V10
                    C50,8.16,48.51,6.67,46.67,6.67z M30.55,48.33H19.45l2-6.67h7.09L30.55,48.33z M48.33,38.33c0,0.92-0.75,1.67-1.67,1.67H3.33
                    c-0.92,0-1.67-0.75-1.67-1.67v-1.67h46.67V38.33z M8.33,33.33h33.33c1.84,0,3.33-1.49,3.33-3.33V3.33C45,1.49,43.51,0,41.67,0
                    H8.33C6.49,0,5,1.49,5,3.33V30C5,31.84,6.49,33.33,8.33,33.33z M6.67,3.33c0-0.92,0.75-1.67,1.67-1.67h33.33
                    c0.92,0,1.67,0.75,1.67,1.67V30c0,0.92-0.75,1.67-1.67,1.67H8.33c-0.92,0-1.67-0.75-1.67-1.67V3.33z M41.67,6.67H8.33v1.67h33.33
                    V6.67z M15.83,10.83h-7.5v1.67h7.5V10.83z M14.17,14.17H8.33v1.67h5.83V14.17z M13.33,17.5h-5v1.67h5V17.5z M13.33,20.83h-5v1.67
                    h5V20.83z M13.33,24.17h-5v1.67h5V24.17z M15.83,27.5h-7.5v1.67h7.5V27.5z M34.17,12.5h7.5v-1.67h-7.5V12.5z M35.83,15.83h5.83
                    v-1.67h-5.83V15.83z M36.67,19.17h5V17.5h-5V19.17z M36.67,22.5h5v-1.67h-5V22.5z M36.67,25.83h5v-1.67h-5V25.83z M34.17,29.17
                    h7.5V27.5h-7.5V29.17z M10,3.33H8.33V5H10V3.33z M13.33,3.33h-1.67V5h1.67V3.33z M16.67,3.33H15V5h1.67V3.33z M31.76,23.23
                    c0.11-0.24,0.21-0.48,0.3-0.73h2.1c0.46,0,0.83-0.37,0.83-0.83v-3.33c0-0.46-0.37-0.83-0.83-0.83h-2.1
                    c-0.09-0.25-0.19-0.49-0.3-0.73l1.49-1.49c0.33-0.33,0.33-0.85,0-1.18l-2.36-2.36c-0.33-0.33-0.85-0.33-1.18,0l-1.49,1.49
                    c-0.24-0.11-0.48-0.21-0.73-0.3v-2.1c0-0.46-0.37-0.83-0.83-0.83h-3.33c-0.46,0-0.83,0.37-0.83,0.83v2.1
                    c-0.25,0.09-0.49,0.19-0.73,0.3l-1.49-1.49c-0.33-0.33-0.85-0.33-1.18,0l-2.36,2.36c-0.33,0.33-0.33,0.85,0,1.18l1.49,1.49
                    c-0.11,0.24-0.21,0.48-0.3,0.73h-2.1c-0.46,0-0.83,0.37-0.83,0.83v3.33c0,0.46,0.37,0.83,0.83,0.83h2.1
                    c0.09,0.25,0.19,0.49,0.3,0.73l-1.49,1.49c-0.33,0.33-0.33,0.85,0,1.18l2.36,2.36c0.33,0.32,0.85,0.32,1.18,0l1.49-1.49
                    c0.24,0.11,0.48,0.21,0.73,0.3v2.1c0,0.46,0.37,0.83,0.83,0.83h3.33c0.46,0,0.83-0.37,0.83-0.83v-2.1
                    c0.25-0.09,0.49-0.19,0.73-0.3l1.49,1.49c0.33,0.32,0.85,0.32,1.18,0l2.36-2.36c0.33-0.33,0.33-0.85,0-1.18L31.76,23.23z
                    M30.3,26.48l-1.33-1.33c-0.27-0.27-0.69-0.32-1.01-0.13c-0.47,0.28-0.97,0.48-1.5,0.62c-0.37,0.1-0.62,0.43-0.62,0.81v1.89h-1.66
                    v-1.89c0-0.38-0.26-0.71-0.63-0.81c-0.53-0.14-1.03-0.34-1.5-0.62c-0.33-0.19-0.74-0.14-1.01,0.13l-1.33,1.33l-1.18-1.18
                    l1.33-1.33c0.27-0.27,0.32-0.69,0.13-1.01c-0.28-0.47-0.48-0.97-0.62-1.5c-0.09-0.37-0.43-0.62-0.81-0.63h-1.88v-1.67h1.88
                    c0.38,0,0.71-0.26,0.81-0.63c0.14-0.53,0.34-1.03,0.62-1.5c0.19-0.33,0.14-0.74-0.13-1.01l-1.33-1.33l1.18-1.18l1.33,1.33
                    c0.27,0.27,0.69,0.32,1.01,0.13c0.47-0.28,0.97-0.49,1.5-0.62c0.37-0.1,0.62-0.43,0.63-0.81v-1.88h1.67v1.89
                    c0,0.38,0.26,0.71,0.63,0.81c0.53,0.14,1.03,0.34,1.5,0.62c0.33,0.19,0.74,0.14,1.01-0.13l1.33-1.33l1.18,1.18l-1.33,1.33
                    c-0.27,0.27-0.32,0.69-0.13,1.01c0.28,0.47,0.48,0.97,0.62,1.5c0.1,0.37,0.43,0.62,0.81,0.62h1.88v1.67h-1.88
                    c-0.38,0-0.71,0.26-0.81,0.63c-0.14,0.53-0.35,1.03-0.62,1.5c-0.19,0.33-0.14,0.74,0.13,1.01l1.33,1.33L30.3,26.48z M25,15.83
                    c-2.3,0-4.17,1.87-4.17,4.17c0,2.3,1.87,4.17,4.17,4.17c2.3,0,4.17-1.87,4.17-4.17C29.16,17.7,27.3,15.84,25,15.83z M25,22.5
                    c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5c1.38,0,2.5,1.12,2.5,2.5C27.5,21.38,26.38,22.5,25,22.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Custom Development',
					text: 'Bespoke mobile app development has its own merits. Complete customization of features, end-to-end support and faster releases to name a few. Gain this and much more with our custom dating app development services.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M49.8,11.7c1.1,0.1,2.1-0.8,2.1-1.9c0-1.1-0.9-2-1.9-2c-1.1,0-1.9,0.9-1.9,2C48.1,10.8,48.8,11.6,49.8,11.7z M88.3,11.7
                    c-5.3,0-9.6-4.4-9.6-9.8c0-1.1-0.9-2-1.9-2H23.2c-1.1,0-1.9,0.9-1.9,2c0,5.4-4.3,9.8-9.6,9.8c-1.1,0-1.9,0.9-1.9,2v31.9
                    c0,24.8,15.9,47.1,39.7,54.4c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c23.3-7.1,39.7-29,39.7-54.4V13.7
                    C90.2,12.6,89.4,11.7,88.3,11.7z M86.4,45.5C86.4,68.9,71.8,89.1,50,96c-21.6-6.9-36.4-27.3-36.4-50.5V15.5
                    C19.5,14.6,24.1,9.9,25,3.9H75c0.8,6,5.5,10.7,11.4,11.6V45.5z M72.5,9c-0.3-0.7-1-1.2-1.8-1.2H57.7c-1.1,0-1.9,0.9-1.9,2
                    c0,1.1,0.9,2,1.9,2h11.9c2,4,5.2,7.3,9.2,9.4v24.5c0,19.1-11.3,35.6-28.7,42.2c-17.5-6.6-28.7-23.1-28.7-42.2V21.1
                    c4-2.1,7.2-5.3,9.2-9.4h11.9c1.1,0,1.9-0.9,1.9-2c0-1.1-0.9-2-1.9-2H29.2c-0.8,0-1.5,0.5-1.8,1.2c-1.8,4.1-4.9,7.3-8.9,9.1
                    c-0.7,0.3-1.1,1-1.1,1.8v25.7c0,20.9,12.7,39.2,31.9,46.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1
                    c19.2-6.9,31.9-25.2,31.9-46.1V19.9c0-0.8-0.5-1.5-1.1-1.8C77.4,16.3,74.3,13.1,72.5,9z M32.8,46.1c0,9.7,7.7,17.6,17.2,17.6
                    c9.5,0,17.2-7.9,17.2-17.6c0-9.7-7.7-17.6-17.2-17.6C40.5,28.5,32.8,36.4,32.8,46.1z M63.4,46.1c0,7.5-6,13.7-13.4,13.7
                    c-7.4,0-13.4-6.1-13.4-13.7c0-7.5,6-13.7,13.4-13.7C57.4,32.4,63.4,38.6,63.4,46.1z M45.6,46.7c-0.7-0.8-2-0.8-2.7,0
                    c-0.7,0.8-0.7,2,0,2.8l3.8,3.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l9.6-9.8c0.7-0.8,0.7-2,0-2.8c-0.7-0.8-2-0.8-2.7,0
                    l-8.2,8.4L45.6,46.7z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'High-end Security',
					text: 'GenixBit Labs takes user security and privacy seriously. We follow GDPR compliant processes and also have PCI DSS compliance to ensure that your user data remains safe and private at all times. We also ensure physical safety of all your data to eliminate any possibilities of mishaps.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M78.4,66.5l1.3-8.5c0-0.1,0-0.2,0-0.3c0-2.6-2-4.7-4.6-4.7H63.9c-3-1.1-4.6-4.3-3.9-7.4c4.7-2.9,7.9-7.7,8.8-13.2
                    c1.5-2.9,7.1-14.9,0.7-22.4C69.1,9.4,61.5,0.4,51,0c-6.1-0.2-11.9,2.4-17.2,8c-2.9,2-5,5-5.8,8.4c-0.8,3.9,0.2,8.1,3.1,12.6
                    c0,6.8,3.5,13.1,9.1,16.6c0.8,3.2-0.9,6.4-3.9,7.5H24.8c-2.5,0-4.5,2.1-4.6,4.7c0,0.1,0,0.2,0,0.3l1.3,8.7
                    c-3.4,4.9-5.3,10.8-5.3,16.9v11.5c0,2.7,2.1,4.9,4.8,4.9H79c2.6,0,4.8-2.2,4.8-4.9V83.6C83.8,76.7,82,71.2,78.4,66.5z M34,27.6
                    c-2.6-3.7-3.6-7.3-2.9-10.4c0.7-2.7,2.3-5,4.5-6.5c0.1-0.1,0.2-0.2,0.3-0.3c4.7-4.9,9.7-7.3,14.8-7.1C60.1,3.6,67,12,67.1,12.1
                    c4.6,5.3,1,14.4-0.6,17.8c-0.1-0.2-0.2-0.3-0.4-0.5c0-0.1-0.1-0.1-0.1-0.2l-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
                    c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.4l-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4C65,26.4,65,26.2,64.9,26
                    c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.8l-0.1-0.4c-0.1-0.4-0.1-0.9-0.1-1.4c-0.1-1.8-0.9-3.5-2.3-4.6c-0.2-0.2-0.5-0.4-0.8-0.5l-0.2-0.1
                    c-0.3-0.1-0.5-0.3-0.8-0.4h-0.1c-0.3-0.1-0.5-0.2-0.8-0.2h-0.1c-0.3-0.1-0.6-0.1-0.9-0.1h-0.2c-0.3,0-0.6,0-0.9,0.1
                    c-7.6,1.3-19.9,0.6-20,0.5c-0.9-0.1-1.6,0.6-1.7,1.5c0,0.1,0,0.2,0,0.2c0.3,3-0.1,6-1.3,8.7L34,27.6z M35.9,36.6
                    c-0.7-1.2-1.1-2.6-1.5-3.9l0.2,0c0.2,0,0.3-0.1,0.5-0.1h0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,3.4-3.8,3.3-11.2
                    c3.8,0.2,12.8,0.4,19-0.7c0.8-0.1,1.6,0.1,2.2,0.6c0.6,0.5,1,1.3,1.1,2.2v1.2l0.1,0.6v0.3c0,0.1,0.1,0.4,0.1,0.6
                    c0,0.1,0,0.2,0.1,0.3l0.1,0.6c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.1,0.6,0.2,0.9v0.1c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1,0.1,0.2,0.1,0.2
                    c0,0.1,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.5c0,0.1,0.1,0.2,0.1,0.2l0.3,0.5l0.1,0.2c0.1,0.2,0.3,0.4,0.4,0.6l0.1,0.1
                    c0.1,0.2,0.2,0.3,0.4,0.5l0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.1l0.1,0.1
                    c-1.1,4.1-3.7,7.6-7.3,9.7c-0.2,0.1-0.3,0.2-0.5,0.3C49.7,47.6,40.1,44.6,35.9,36.6z M58.7,53H41.6c1.3-1.7,1.9-3.7,1.9-5.8
                    c4.2,1.6,8.9,1.6,13.2,0C56.7,49.3,57.4,51.4,58.7,53z M24.8,56.3h50.3c0.7,0,1.3,0.6,1.4,1.3L71.8,89c0,0.1,0,0.2,0,0.2
                    c0,0.8-0.6,1.4-1.3,1.4h-41c-0.7,0-1.4-0.6-1.4-1.4v0c0-0.1,0-0.2,0-0.2l-4.7-31.3C23.5,56.9,24.1,56.4,24.8,56.3z M21.3,93.6v3.1
                    h-0.3c-0.9,0-1.6-0.7-1.6-1.6v0h0V83.6c0-4.1,1-8.2,2.8-11.9l2.6,17.7c0,0.4,0.1,0.8,0.2,1.3h-0.8C22.7,90.6,21.3,91.9,21.3,93.6z
                    M74.5,96.7h-50v-2.9h50V96.7z M80.5,95.1c0,0.9-0.7,1.6-1.6,1.6h-1.2v-3.1c0-1.7-1.3-3-2.9-3c0.1-0.4,0.2-0.8,0.2-1.3l2.7-18.1
                    c2,3.8,3,8,2.9,12.3L80.5,95.1z M47.3,26.3c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.7,2.6,2.7S47.3,27.8,47.3,26.3z
                    M56,29c1.4,0,2.6-1.2,2.6-2.7c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C53.4,27.8,54.5,29,56,29z M56,36.2c0-0.9-0.7-1.6-1.6-1.6
                    c-0.9,0-1.6,0.7-1.6,1.6c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6
                    c0,3.4,2.7,6.2,6.1,6.2C53.3,42.4,56,39.6,56,36.2z M42.5,83.1c0.3,0.3,0.7,0.5,1.1,0.5c0.9,0,1.6-0.8,1.6-1.7
                    c0-0.4-0.2-0.8-0.5-1.1l-7.1-7.4l7.1-7.3c0.6-0.6,0.7-1.7,0.1-2.3c-0.6-0.7-1.6-0.7-2.3-0.1c0,0-0.1,0.1-0.1,0.1l-8.2,8.4
                    c-0.6,0.6-0.6,1.7,0,2.3L42.5,83.1z M55.7,66.1l7.1,7.3l-7.2,7.4c-0.6,0.6-0.6,1.7,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5
                    c0.4,0,0.8-0.2,1.1-0.5l8.3-8.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.8-0.5-1.2L58,63.8c-0.6-0.6-1.7-0.6-2.3,0.1
                    C55.1,64.5,55.1,65.5,55.7,66.1L55.7,66.1z M46.1,85.6c0.2,0.1,0.3,0.1,0.5,0.1c0.7,0,1.3-0.4,1.5-1.1l7-21.2
                    c0.3-0.9-0.1-1.8-1-2.1c-0.8-0.3-1.8,0.1-2,1l-7,21.2C44.9,84.4,45.3,85.3,46.1,85.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Experienced Developers',
					text: 'Since our inception and over the years, GenixBit Labs has taken special care in assembling a star team of dating app developers who have inch wide expertise and mile-long experience in developing a dating app. Be assured that your dating app development is in expert hands.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M36.67,0.01H5.83C5.37,0.01,5,0.38,5,0.84v4.17H0.83C0.37,5.01,0,5.38,0,5.84v43.32C0,49.63,0.37,50,0.83,50h30.83
                    c0.46,0,0.83-0.37,0.83-0.83v-3.33h4.17c0.46,0,0.83-0.37,0.83-0.83V0.84C37.5,0.38,37.13,0.01,36.67,0.01z M30.83,48.33H1.67
                    V6.67H22.5v7.5c0,0.46,0.37,0.83,0.83,0.83h7.5V48.33z M24.17,13.34V7.85l5.49,5.49H24.17z M35.83,44.17H32.5V14.17
                    c0-0.22-0.09-0.43-0.24-0.59l-1.91-1.91h2.15v-1.67h-3.82l-2.5-2.5h1.32V5.84h-2.99l-0.59-0.59c-0.16-0.16-0.37-0.24-0.59-0.24
                    H6.67V1.67h29.17V44.17z M47.51,12.5h-0.85c0-1.84-1.49-3.33-3.33-3.33c-1.84,0-3.33,1.49-3.33,3.33V45c0,0.15,0.04,0.3,0.12,0.43
                    l2.5,4.17c0.24,0.39,0.75,0.52,1.14,0.28c0.12-0.07,0.21-0.17,0.28-0.28l2.5-4.17c0.08-0.13,0.12-0.28,0.12-0.43V14.17h0.85
                    c0.45,0,0.82,0.37,0.82,0.82v14.18H50V14.99C50,13.62,48.89,12.51,47.51,12.5z M43.33,47.54l-1.03-1.71h2.06L43.33,47.54z
                    M45,44.17h-3.33v-15H45V44.17z M45,27.5h-3.33V14.17H45V27.5z M41.67,12.5c0-0.92,0.75-1.67,1.67-1.67
                    c0.92,0,1.67,0.75,1.67,1.67H41.67z M5.83,38.33h20.83v-1.67H5.83V38.33z M5.83,42.5h12.5v-1.67H5.83V42.5z M10.83,34.17h15.83
                    V32.5H10.83V34.17z M9.17,32.5H5.83v1.67h3.33V32.5z M22.5,20.84v7.5h-1.67V22.5h-1.67v5.83H17.5V20h-1.67v8.33h-1.67V22.5H12.5
                    v5.83h-1.67v-2.5H9.17v2.5H7.5V20H5.83v9.16c0,0.46,0.37,0.83,0.83,0.83h20v-1.67h-2.5v-7.5H22.5z M16.67,10.84H5.83v1.67h10.83
                    V10.84z M13.33,15h-7.5v1.67h7.5V15z M32.5,5.84h-3.33v1.67h3.33V5.84z M20,42.5h3.33v-1.67H20V42.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Non Disclosure Agreement',
					text: 'We understand your need to keep the news of dating app development, its technical know-how and every minute detail under the wraps. That’s why we sign an NDA before the commencement of the project to give you total control.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
