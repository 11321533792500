<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/instant-messaging-main.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Connect real-time, everytime with the instant messaging app development
					</h2>
					<p>
						Once upon a time, pigeons carried messages. Then came post mail. Then, Graham Bell
						introduced the world to landlines. In the past few decades, landlines have evolved into
						mobiles and mobiles into the ultimate smartphones. Man’s need for communication is what
						has pushed the glorious transformation of telecommunication.
					</p>
					<p>
						Instant messaging was born out of the same need. Thanks to smartphones that are as
						powerful as the computers that put man on the moon, today instant messaging has become a
						thing that we take for granted. Instant messaging has empowered us all with a utility
						that even Graham Bell would not have dreamt of — real-time communication.
					</p>
					<p>
						From a small group of friends to a thousand-strong workforce, instant messaging helps
						everyone share information and get things done. An instant messaging app can connect
						remotely located individuals to each other in real-time without the need for any bulky
						hardware or expensive bandwidth.
					</p>
					<p>
						All that it takes is an instant messaging app. A feature-rich instant messaging app can
						empower users to exchange real-time text messages, audio messages, transact video
						calling, share multimedia or even create chat groups and communities.
					</p>
					<p>
						Want to build a custom-made instant messaging app that can connect users
						anywhere,anytime?
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>