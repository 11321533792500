<template>
	<div class="our-core-value-box rounded10">
		<div class="our-core-value-icon rounded10">
			<div class="our-core-value-icon rounded10" v-html="obj.svg"></div>
		</div>
		<h5>{{ obj.title }}</h5>
		<p>{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			type: Object,
			default: () => {
				return {};
			},
			required: true
		}
	}
};
</script>

<style>
.our-core-value-box:hover {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.our-core-value-box {
    background-color: #fff;
    border: 1px solid #dddfe1;
    padding: 20px 20px;
    min-height: 310px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.rounded10 {
    border-radius: 10px!important;
}
.our-core-value-box:hover .our-core-value-icon {
    background-color: #008dd2;
}

.our-core-value-box:hover .our-core-value-icon svg{
    fill:#FFF
}
.our-core-value-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: 1px solid #dddfe1;
    margin-bottom: 20px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}
.our-core-value-icon svg {
    width: 48px;
    height: 48px;
    fill:#008dd2;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
</style>
