<template>
	<section class="padding-60 p-relative bg-light">
		<div class="container mt-5">
			<div class="title">
				<span>Pathbreaking UX/UI</span>
				<h2 class="fxt-process-dev">Design that captivates users</h2>
			</div>
			<div class="row">
					<BigImageCard v-for="item in items" v-bind:obj="item" v-bind:key="item.title" class="col-lg-6 col-md-12 col-12 mb-30">
					</BigImageCard>
			</div>
		</div>
	</section>
</template>

<script>
import BigImageCard from '../AssetComponents/BigImageCard.vue';

export default {
	components: {
		BigImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink: "https://www.intelivita.co.uk/assets/frameworks/public/images/uiux/branding.webp",
					title: "Branding",
					text: "A designing interface that adds value to your offering creates a brand out of your product. Our UI UX services move beyond the usual colour combinations, graphics, and display to tell the story of each element that blends with the other, providing a rich User Experience. This contributes to extending your brand virtually to speak what business stands for.",
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M440,120v-8.9c21.6-22.1,21.3-57.5-0.8-79.2c-22.1-21.6-57.5-21.3-79.2,0.8c-21.3,21.8-21.3,56.6,0,78.4v8.9h-16v16h112
                    v-16H440z M429.8,98.6c-1,1.1-2,2.2-3.2,3.2c-1.7,1.5-2.7,3.7-2.7,6V120h-16V99.3l21.7-21.7l-11.3-11.3L400,84.7l-18.3-18.3
                    l-11.3,11.3L392,99.3V120h-16v-12.3c0-2.3-1-4.4-2.7-6c-16.5-14.7-17.9-40-3.2-56.5c14.7-16.5,40-17.9,56.5-3.2
                    C443.1,56.8,444.5,82.1,429.8,98.6z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="64" y="64" width="16" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="352" y="184" width="16" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M72,32c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40C112,49.9,94.1,32,72,32z M72,96c-13.3,0-24-10.7-24-24
                    s10.7-24,24-24s24,10.7,24,24S85.3,96,72,96z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M472,280H304V8c0-4.4-3.6-8-8-8H8C3.6,0,0,3.6,0,8v432c0,4.4,3.6,8,8,8h32v8c0,13.3,10.7,24,24,24s24-10.7,24-24v-8h72v24
                    c0,4.4,3.6,8,8,8h304c4.4,0,8-3.6,8-8V288C480,283.6,476.4,280,472,280z M463.2,296c-3.8,18.6-20.2,32-39.2,32H216
                    c-19,0-35.4-13.4-39.2-32H463.2z M72,456c0,4.4-3.6,8-8,8s-8-3.6-8-8v-24h16V456z M72,416H56v-16h16V416z M72,384H56V256h16V384z
                    M56.9,240l7.1-14.1l7.1,14.1H56.9z M160,288v144H88V240c0-1.2-0.3-2.5-0.8-3.6l-16-32c-2.4-4-7.5-5.2-11.4-2.9
                    c-1.2,0.7-2.2,1.7-2.9,2.9l-16,32c-0.6,1.1-0.8,2.3-0.8,3.6v192H16V16h272v264H168C163.6,280,160,283.6,160,288z M464,464H176
                    V327.1c10.5,10.8,24.9,16.9,40,16.9h208c15.1,0,29.5-6.1,40-16.9V464z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M472,152H328c-4.4,0-8,3.6-8,8v96c0,4.4,3.6,8,8,8h144c4.4,0,8-3.6,8-8v-96C480,155.6,476.4,152,472,152z M464,248H336
                    v-80h128V248z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="392" y="216" width="40" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="136" y="48" width="136" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="136" y="80" width="104" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="192" y="400" width="16" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="232" y="400" width="104" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="232" y="432" width="72" height="16"></rect>
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="392" y="184" width="56" height="16"></rect>
                  </g>
                </g>
              </svg>
					`
				},
				{
					imageLink: "https://www.intelivita.co.uk/assets/frameworks/public/images/uiux/product-researchand-strategy.webp",
					title: "Product Research & Strategy",
					text: "Our UX/UI development services researching and implementing current UX/UI trends and comparing market competitors to define a range of UI styles. Our product research and strategy involve picking up ideas that are best in business while design strategy is backed by numbers. We commit ourselves to match global standards and writing a new success story.",
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M47,29.7l-9.2-5.9h-1V9.2l-8.4-8.4H0.3V50h36.5v-5.2l2.4,3.8V50h1.6v-1.8l-8-12.8l-1.4-2.8c-0.1-0.1-0.1-0.2-0.1-0.4v-0.4
                c0-0.4,0.4-0.8,0.8-0.8h0.1c0.2,0,0.4,0.1,0.6,0.2l1.9,1.9l2.9,3.9l0.1-0.1l3.5,3.5c0.6,0.6,1.3,0.9,2.1,0.9c1.7,0,3-1.4,3-3
                c0-0.8-0.3-1.6-0.9-2.1L41.3,32l-4.2-5h-4.3c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h4.5l8.2,5.2l2.3,10.7V50h1.6v-8.7
                L47,29.7z M28.8,3.5l5.2,5.2h-5.2V3.5z M35.2,48.4H1.9v-46h25.4v7.9h7.9v13.5h-2.1l-0.3-0.3v-4.4h-7.9v3.2h-2.4v-3.2h-3.2v-4.8
                c2.2-0.4,4-2.3,4-4.7c0-2.6-2.1-4.8-4.8-4.8s-4.8,2.1-4.8,4.8c0,2.4,1.7,4.3,4,4.7v1.7H7.4v3.2H4.3V27h7.9v-7.9H9v-1.6h8.7v1.6h-3.2
                V27h3.2v4.8H4.3v7.9h28.5v-1.2l2.4,3.8V48.4z M29.9,33.3l1.3,2.6v2.2H5.8v-4.8H29.9z M29.6,31.7H19.3V27h3.2v-3.2h2.4V27h2.8
                l2.9,2.9C30,30.3,29.6,31,29.6,31.7z M18.5,12.7c-1.7,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2S20.3,12.7,18.5,12.7z
                M20.9,20.6v4.8h-4.8v-4.8H20.9z M31.2,22.2h-4v3.2h-0.8v-4.8h4.8V22.2z M28.8,23.8h2.1l-2.1,2.1V23.8z M10.6,20.6v4.8H5.8v-4.8
                H10.6z M38.7,35.7l2.1-2.1l1.3,1.3L39.9,37L38.7,35.7z M44.7,38.2c0,0.8-0.7,1.5-1.4,1.5c-0.4,0-0.8-0.2-1-0.4l-1.2-1.2l2.1-2.1
                l1.2,1.2C44.5,37.5,44.7,37.8,44.7,38.2z M32.8,28.6h2.8l4,4l-2,2l-1.8-2.4l-2-2c-0.4-0.4-0.9-0.6-1.5-0.7L30,27l0.5-0.5
                C30.6,27.7,31.6,28.6,32.8,28.6z"></path>
              </svg>
					`
				},
				{
					imageLink: "https://www.intelivita.co.uk/assets/frameworks/public/images/uiux/wireframing.webp",
					title: "Wireframing",
					text: "Key to top-notch UX/UI design solution is in its wireframing. Breeding a team of experienced designers, we do core wireframing that promises smooth user experience. Our expert UX designers create a pattern library to use in building features. Offering a quick way to articulate the position of elements and user workflow in building features, pages and app components.",
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path class="st0" d="M49.2,0H1.8C1.4,0,1,0.4,1,0.8v11.4c0,0.5,0.4,0.8,0.8,0.8h47.4c0.5,0,0.8-0.4,0.8-0.8V0.8
                    C50,0.4,49.6,0,49.2,0z M42.3,1.6L25.5,5.7L8.7,1.6H42.3z M2.6,1.9L22,6.5L2.6,11.2V1.9z M8.7,11.4l16.8-4.1l16.8,4.1H8.7z
                    M48.4,11.2L29,6.5l19.4-4.7V11.2z M14.9,14.7H1.8c-0.5,0-0.8,0.4-0.8,0.8v26.1v6.5C1,48.6,1.4,49,1.8,49h13.1
                    c0.5,0,0.8-0.4,0.8-0.8v-6.5V15.5C15.7,15.1,15.3,14.7,14.9,14.7z M13.6,16.3L8.3,26.8L3.1,16.3H13.6z M13.6,40.8H3.1l5.2-10.4
                    L13.6,40.8z M2.6,19l4.8,9.6l-4.8,9.6V19z M14.1,47.4H2.6v-4.9h11.4V47.4z M14.1,38.2l-4.8-9.6l4.8-9.6V38.2z M5.9,44.1H4.3v1.6
                    h1.6V44.1z M9.2,44.1H7.5v1.6h1.6V44.1z M12.4,44.1h-1.6v1.6h1.6V44.1z M32,14.7H19c-0.5,0-0.8,0.4-0.8,0.8v10.6v6.5
                    c0,0.5,0.4,0.8,0.8,0.8H32c0.5,0,0.8-0.4,0.8-0.8v-6.5V15.5C32.8,15.1,32.5,14.7,32,14.7z M29.7,16.3l-4.2,3.4l-4.2-3.4H29.7z
                    M29.7,25.3h-8.5l4.2-3.4L29.7,25.3z M19.8,17.2l4.4,3.6l-4.4,3.6V17.2z M31.2,31.8H19.8V27h11.4V31.8z M31.2,24.4l-4.4-3.6
                    l4.4-3.6V24.4z M23,28.6h-1.6v1.6H23V28.6z M26.3,28.6h-1.6v1.6h1.6V28.6z M29.6,28.6h-1.6v1.6h1.6V28.6z M49.2,14.7H35.3
                    c-0.5,0-0.8,0.4-0.8,0.8v10.6c0,0.5,0.4,0.8,0.8,0.8h13.9c0.5,0,0.8-0.4,0.8-0.8V15.5C50,15.1,49.6,14.7,49.2,14.7z M48.4,25.3
                    H36.1v-9h12.3V25.3z M36.9,28.6v1.6h10.6v-1.6H36.9z M34.5,33.5H50v-1.6H34.5V33.5z M32,35.1H19c-0.5,0-0.8,0.4-0.8,0.8v9
                    c0,0.5,0.4,0.8,0.8,0.8H32c0.5,0,0.8-0.4,0.8-0.8v-9C32.8,35.5,32.5,35.1,32,35.1z M31.2,44.1H19.8v-7.3h11.4V44.1z M18.1,49h14.7
                    v-1.6H18.1V49z M49.2,35.1H35.3c-0.5,0-0.8,0.4-0.8,0.8v12.3c0,0.5,0.4,0.8,0.8,0.8h13.9c0.5,0,0.8-0.4,0.8-0.8V35.9
                    C50,35.5,49.6,35.1,49.2,35.1z M47,36.8L42.2,41l-4.8-4.2H47z M36.1,37.7l4.9,4.3l-4.9,4.3V37.7z M37.5,47.4l4.8-4.2l4.8,4.2H37.5
                    z M48.4,46.4l-4.9-4.3l4.9-4.3V46.4z"></path>
                  </g>
                </g>
              </svg>
					`
				},
				{
					imageLink: "https://www.intelivita.co.uk/assets/frameworks/public/images/uiux/rapid-prototype.webp",
					title: "Rapid Prototype",
					text: "‘Time is money’ and our UX/UI design and development services live up to this idea. We create quick mock-ups for client’s validation before scaling up. The back and forth feedback process mean we continuously work to better our offerings and achieving end results efficiently by quick designs iteration for high fidelity prototype and choosing an ideal process for it.",
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M379.9,228.9c14.3,0,26-11.7,26-26V26c0-14.3-11.7-26-26-26h-88.5c-14.3,0-26,11.7-26,26v53.7c-2.3-0.2-4.7,0.6-6.4,2.4
                    c-3.2,3.2-3.2,8.5,0,11.7l12.4,12.4h-24.8V26c0-14.3-11.7-26-26-26h-88.5c-14.3,0-26,11.7-26,26v176.9c0,14.3,11.7,26,26,26h35.9
                    v54.2h-35.9c-14.3,0-26,11.7-26,26V486c0,14.3,11.7,26,26,26h88.5c14.3,0,26-11.7,26-26V309.1c0-14.3-11.7-26-26-26h-35.9v-54.2
                    h35.9c14.3,0,26-11.7,26-26v-80.2h24.8L259,135.1c-3.2,3.2-3.2,8.5,0,11.7c1.6,1.6,3.7,2.4,5.9,2.4c0.2,0,0.4,0,0.6,0v53.7
                    c0,14.3,11.7,26,26,26h35.9v54.2h-35.9c-14.3,0-26,11.7-26,26V486c0,14.3,11.7,26,26,26h88.5c14.3,0,26-11.7,26-26V309.1
                    c0-14.3-11.7-26-26-26h-35.9v-54.2L379.9,228.9L379.9,228.9z M220.6,299.7c5.2,0,9.4,4.2,9.4,9.4V486c0,5.2-4.2,9.4-9.4,9.4h-88.5
                    c-5.2,0-9.4-4.2-9.4-9.4V309.1c0-5.2,4.2-9.4,9.4-9.4h35.9v33.6l-12.4-12.4c-3.2-3.2-8.5-3.2-11.7,0s-3.2,8.5,0,11.7l26.5,26.5
                    c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2
                    c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
                    c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0
                    c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1
                    c0.1,0,0.2-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.3-0.2
                    c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0,0l26.5-26.5c3.2-3.2,3.2-8.5,0-11.7
                    c-3.2-3.2-8.5-3.2-11.7,0l-12.4,12.4v-33.6H220.6z M176.4,123.9c-5.2,0-9.4-4.2-9.4-9.4s4.2-9.4,9.4-9.4s9.4,4.2,9.4,9.4
                    S181.6,123.9,176.4,123.9z M230,202.9c0,5.2-4.2,9.4-9.4,9.4h-35.9v-73.2c7.7-2.6,13.7-8.7,16.3-16.3h29L230,202.9L230,202.9z
                    M230,106.2h-29c-3.5-10.3-13.2-17.7-24.6-17.7c-14.3,0-26,11.7-26,26c0,11.4,7.4,21.2,17.7,24.6v73.2h-35.9
                    c-5.2,0-9.4-4.2-9.4-9.4V26c0-5.2,4.2-9.4,9.4-9.4h88.5c5.2,0,9.4,4.2,9.4,9.4L230,106.2L230,106.2z M291.4,212.3
                    c-5.2,0-9.4-4.2-9.4-9.4v-67.3l15.3-15.3c3.2-3.2,3.2-8.5,0-11.7L282,93.3V26c0-5.2,4.2-9.4,9.4-9.4h88.5c5.2,0,9.4,4.2,9.4,9.4
                    v176.9c0,5.2-4.2,9.4-9.4,9.4h-35.9v-73.2c10.3-3.5,17.7-13.2,17.7-24.6c0-14.3-11.7-26-26-26s-26,11.7-26,26
                    c0,11.4,7.4,21.2,17.7,24.6v73.2L291.4,212.3L291.4,212.3z M379.9,299.7c5.2,0,9.4,4.2,9.4,9.4V486c0,5.2-4.2,9.4-9.4,9.4h-88.5
                    c-5.2,0-9.4-4.2-9.4-9.4V309.1c0-5.2,4.2-9.4,9.4-9.4h35.9v33.6l-12.4-12.4c-3.2-3.2-8.5-3.2-11.7,0c-3.2,3.2-3.2,8.5,0,11.7
                    l26.5,26.5c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.2
                    c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1
                    c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0,0.4,0.1c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0
                    c0.1,0,0.2,0,0.4-0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1
                    c0.1,0,0.2-0.1,0.4-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.4-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.3-0.2
                    c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0,0l26.5-26.5c3.2-3.2,3.2-8.5,0-11.7
                    c-3.2-3.2-8.5-3.2-11.7,0l-12.4,12.4v-33.6H379.9z M335.6,123.9c-5.2,0-9.4-4.2-9.4-9.4s4.2-9.4,9.4-9.4s9.4,4.2,9.4,9.4
                    S340.8,123.9,335.6,123.9z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M335.6,371.6c-14.3,0-26,11.7-26,26s11.7,26,26,26s26-11.7,26-26C361.6,383.2,349.9,371.6,335.6,371.6z M335.6,406.9
                    c-5.2,0-9.4-4.2-9.4-9.4s4.2-9.4,9.4-9.4s9.4,4.2,9.4,9.4S340.8,406.9,335.6,406.9z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M176.4,371.6c-14.3,0-26,11.7-26,26s11.7,26,26,26s26-11.7,26-26C202.4,383.2,190.7,371.6,176.4,371.6z M176.4,406.9
                    c-5.2,0-9.4-4.2-9.4-9.4s4.2-9.4,9.4-9.4s9.4,4.2,9.4,9.4S181.6,406.9,176.4,406.9z"></path>
                  </g>
                </g>
              </svg>
					`
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
</style>
