<template>
	<div class="normal-card-inner-box mhbox-Notch">
		<div class="normal-card-inner-icon" v-html="obj.svg"></div>
		<h5>{{ obj.title }}</h5>
		<p>{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.normal-card-inner-box {
	border: 1px solid #ecedee;
	-webkit-box-shadow: 0 1px 5px 0 rgba(72, 73, 121, 0.15);
	-moz-box-shadow: 0 1px 5px 0 rgba(72, 73, 121, 0.15);
	box-shadow: 0 1px 5px 0 rgba(72, 73, 121, 0.15);
	margin-top: 30px;
	border-radius: 10px;
	padding: 20px 20px 20px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	min-height: 400px;
}
.normal-card-inner-icon {
	width: 60px;
	height: 60px;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin-bottom:20px
}
.normal-card-inner-box h5 {
	font-size: 18px;
	font-weight: bold;
	padding-left: 10px;
	margin-bottom: 15px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.normal-card-inner-box:hover {
	border: 1px solid #008dd2;
}
.normal-card-inner-box:hover .normal-card-inner-icon {
	fill:#008dd2
}
</style>
