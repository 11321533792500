<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/ecommerce-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Custom eCommerce Development Services</h2>
					<p>
						If you want your eCommerce store to attract more traffic and sell more, it must be a
						purple cow amidst a sea of black and white cattle. It must have its own identity, an
						interface that makes things easy for customers and also features that will engage them
						more.
					</p>
					<p>
						Cookie cut websites built with templates available on the internet will not make the
						cut. You need dedicated custom eCommerce development services to create an online store
						that can stand out from the crowd. It is here that Genixbit comes in.
					</p>
					<p>
						Be it a single vendor marketplace that is truly owned and managed by you, or a
						multi-vendor store where multiple vendors with multiple stores arrange virtual
						storefronts under one brand name, our custom eCommerce development services can meet all
						your requirements.
					</p>
					<p>
						Also being a top ecommerce website development company, our eCommerce development
						expertise is not restricted to a single platform. We can help you build an online store
						on multiple platforms including Core PHP, CodeIgniter Laravel, Magento, Shopify, Drupal,
						WooCommerce, BigCommerce among many others.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
