<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>CRM for Customers-First Philosophy</span>
				<h2 class="fxt-process-dev">Why CRM for Your Business</h2>
				<p>
					Integrating your marketing, sales and service verticals under one umbrella tailored for
					each customer. Leverage through customer-first philosophy with CRM software services for
					your business.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<BigWidthCard v-bind:obj="item"></BigWidthCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard';

export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
        {
          title:"Choosing Personalised Marketing",
          text:"Custom CRM solutions enable you to engage in multiple marketing tools for market segmentation that helps to create a personlised marketing campaign. Track user journey through landing page segmenting and track responses to better serve your prospective customers offering value to their needs.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M24.5,42.3c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                    c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C24.8,42.7,24.7,42.4,24.5,42.3z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M29.2,33.3v-7.2c0-0.3-0.2-0.6-0.4-0.8c-0.3-0.2-0.6-0.2-0.9-0.1L11,31.9H5.6c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6
                    h0.6V49c0,0.5,0.4,1,1,1h4c0.5,0,1-0.4,1-1v-5.4l15.7,6.3c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c0.3-0.2,0.4-0.5,0.4-0.8
                    v-7.2c2-0.4,3.4-2.2,3.4-4.3S31.2,33.7,29.2,33.3z M10.2,48h-2v-4.9h2L10.2,48L10.2,48z M10.2,41.2H5.6c-2,0-3.7-1.6-3.7-3.7
                    c0-2,1.6-3.7,3.7-3.7h4.6L10.2,41.2z M27.2,47.6L27.2,47.6l-15-6.1v-7.9l10.7-4.3V39c0,0.5,0.4,1,1,1s1-0.4,1-1V28.5l2.4-1V47.6z
                    M29.2,39.8v-4.5c0.9,0.4,1.5,1.2,1.5,2.2S30.1,39.4,29.2,39.8z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M41.9,36.6h-5.5c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h5.5c0.5,0,1-0.4,1-1C42.9,37,42.5,36.6,41.9,36.6z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M37.4,29.4c-0.4-0.4-1-0.4-1.4,0l-1.9,1.9c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l1.9-1.9
                    C37.8,30.4,37.8,29.8,37.4,29.4z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M37.5,44.2l-2-2c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2,2c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3
                    C37.9,45.2,37.9,44.6,37.5,44.2z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M46.1,0H27.9C25.8,0,24,1.8,24,3.9v0.4H7.6c-1.3,0-2.3,1.1-2.3,2.3v15.4c0,1.3,1.1,2.3,2.3,2.3h1v3
                    c0,0.4,0.2,0.8,0.6,0.9c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3l3.7-3.7h19.4c1.3,0,2.3-1.1,2.3-2.3v-2.9h10.4
                    c2.2,0,3.9-1.8,3.9-3.9V3.9C50,1.8,48.2,0,46.1,0z M33.8,22.1c0,0.2-0.2,0.4-0.4,0.4H13.6c-0.3,0-0.5,0.1-0.7,0.3l-2.3,2.3v-1.6
                    c0-0.5-0.4-1-1-1h-2c-0.2,0-0.4-0.2-0.4-0.4V6.6c0-0.2,0.2-0.4,0.4-0.4H24v9c0,2.2,1.8,3.9,3.9,3.9h5.8V22.1z M48,15.2
                    c0,1.1-0.9,2-2,2H27.9c-1.1,0-2-0.9-2-2V3.9c0-1.1,0.9-2,2-2h18.2c1.1,0,2,0.9,2,2V15.2z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M40.9,8.7l-6.7-3.9c-0.3-0.2-0.7-0.2-1,0C33,5,32.8,5.4,32.8,5.7v7.7c0,0.3,0.2,0.7,0.5,0.8c0.2,0.1,0.3,0.1,0.5,0.1
                    s0.3,0,0.5-0.1l6.7-3.9c0.3-0.2,0.5-0.5,0.5-0.8S41.2,8.9,40.9,8.7z M34.7,11.7V7.4l3.8,2.2L34.7,11.7z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M20.9,10.3h-9.8c-0.5,0-1,0.4-1,1s0.4,1,1,1h9.8c0.5,0,1-0.4,1-1S21.5,10.3,20.9,10.3z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M21.6,16c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
                    c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7S21.8,16.2,21.6,16z"></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M17.3,15.7h-6.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h6.1c0.5,0,1-0.4,1-1C18.3,16.2,17.8,15.7,17.3,15.7z"></path>
                  </g>
                </g>
              </g>
            </svg>
          `
        },
        {
          title:"Enabling Targeted Sales",
          text:"CRM software service enables advance sales management with integrated features providing sales analytics, custom role permissions, and defining sales team hierarchy. Empower tour sales team with tailored dashboards for targeted upselling and helping to achieve online/offline territorial sales targets.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M19.4,29.4l10-10l1.2,1.2l-10,10L19.4,29.4z"></path>
              <path d="M47.5,24.2C47.3,18.8,45.2,14,42,10.3l-1.2,1.2c3,3.4,4.8,7.9,5,12.7h-3.4c-0.2-3.9-1.7-7.5-4-10.4L37.2,15
              c2.1,2.5,3.4,5.7,3.6,9.2h-2.5v1.7h2.5c-0.4,8.1-6.9,14.5-15,15v-2.5h-1.7v2.5c-8.1-0.4-14.5-6.9-15-15h2.5v-1.7H9.2
              c0.4-8.1,6.9-14.5,15-15v2.5h1.7V9.2c3.5,0.2,6.6,1.5,9.2,3.6l1.2-1.2c-2.8-2.4-6.4-3.8-10.4-4V4.2c4.9,0.2,9.3,2.1,12.7,5L39.7,8
              C36,4.8,31.2,2.7,25.8,2.5V0h-1.7v2.5C12.4,3,3,12.4,2.5,24.2H0v1.7h2.5C3,37.6,12.4,47,24.2,47.5V50h1.7v-2.5
              C37.6,47,47,37.6,47.5,25.8H50v-1.7H47.5z M24.2,4.2v3.4C15.2,8,8,15.2,7.5,24.2H4.2C4.6,13.3,13.3,4.6,24.2,4.2z M24.2,45.8
              c-10.8-0.4-19.5-9.1-20-20h3.4c0.4,9,7.6,16.2,16.6,16.6V45.8z M25.8,45.8v-3.4c9-0.4,16.2-7.6,16.6-16.6h3.4
              C45.4,36.7,36.7,45.4,25.8,45.8z"></path>
              <path d="M28.3,25.8c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S29.7,25.8,28.3,25.8z M28.3,29.2
              c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8S28.8,29.2,28.3,29.2z"></path>
              <path d="M21.7,24.2c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5S20.3,24.2,21.7,24.2z M21.7,20.8
              c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S21.2,20.8,21.7,20.8z"></path>
              <path d="M44.8,1.1c0,0.2,0.1,0.3,0.1,0.4c0.2,1.1,0.4,2.1,0.7,3.2c0,0.1,0.1,0.2,0.2,0.2c0.9,0.2,1.7,0.4,2.6,0.6
              c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.5c-0.2,0.2-0.5,0.4-0.7,0.7c-0.7,0.6-1.3,1.3-2,1.9
              c-0.6,0.6-1.3,0.8-2.2,0.6c-0.5-0.1-0.9-0.1-1.4-0.2c-0.1,0-0.2,0-0.3,0.1c-0.5,0.4-1,0.8-1.4,1.2c-0.4,0.4-8.5,8.2-8.9,8.6
              c-0.1,0.1-0.1,0-0.2,0c-0.3-0.3-0.6-0.6-0.8-0.9c-0.1-0.1-0.1-0.1,0-0.2c0.4-0.4,8.3-8.2,8.6-8.6c0.5-0.5,1-1.1,1.5-1.6
              c0,0,0.1-0.1,0.1-0.2c0-0.4-0.1-0.8-0.1-1.2c-0.1-1,0.3-1.8,1-2.5c0.7-0.6,1.4-1.3,2-1.9C44.2,1.7,44.5,1.4,44.8,1.1z"></path>
            </svg>
          `
        },
        {
          title:"Custom Service Management",
          text:"Keep a close watch over service level agreements and scope-driven performances for timely customer services. Custom CRM software development enables easy AMC/call-based management for intelligent automated service allocation. It also involves proactive service resource allocation to customers to better after-sales interactions.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M46.1,43.5c1.9,2,1.5,1.6-0.1,3.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.9,0.9-1.1,0.9-2.1,0c-0.4-0.3-0.7-0.7-1.2-0.3
                c-0.6,0.4-1.6,0.2-1.6,1.4c0,1.4-0.1,1.4-1.5,1.4c-0.7,0-1.3,0-2,0c-0.9,0.1-1.3-0.3-1.3-1.2c0-0.5,0.1-1.1-0.7-1.3
                c-0.8-0.2-1.5-1-2.3,0.1c-0.5,0.7-1.1,0.6-1.7,0c-0.5-0.5-1-1-1.5-1.5c-0.3-0.4-0.5-1-0.9-1.7c-0.8,0-2.1,0-3.3,0
                c-7.7,0-15.3,0-23,0c-1.6,0-1.7-0.1-1.7-1.6c0-4.4,0-8.7,0-13.1c0-3.4,1.7-5.6,5-6.4c2.6-0.6,5.3-1.1,8-1.6
                c1.5-0.3,1.6-0.1,1.7,1.4c0.1,4.1,1.7,8,4.4,10.8c0.9-1.5,1.9-2.9,2.6-4.4c1-2.1,1.6-4.4,1.5-6.8c0-1,0.3-1.3,1.3-1.1
                c3,0.5,6.1,1.1,9.1,1.8c1.9,0.5,3.3,1.8,3.9,3.7c0.2,0.5,0.4,0.9,1,0.8c0.9-0.1,1,0.6,1.1,1.2c0,0.5-0.1,1,0.6,1.2
                c0.8,0.1,1.5,1.1,2.3,0c0.6-0.8,1.2-0.7,1.8,0.1c0.5,0.6,1.1,1.1,1.6,1.6c0.5,0.5,0.6,1,0.1,1.5c-0.4,0.4-1,0.7-0.6,1.5
                c0.4,0.7,0.4,1.7,1.7,1.6c0.8-0.1,1.3,0.4,1.2,1.3c0,0.8,0.1,1.7,0,2.5c0,0.3-0.5,0.9-0.7,0.9C47,41,46.7,42,46.3,43
                C46.3,43.2,46.2,43.3,46.1,43.5z M25.7,23c-0.1,0.6-0.1,0.9-0.1,1.1c-0.3,4.3-2,7.9-4.9,11.1c-0.6,0.7-1.1,0.7-1.7,0
                c-2.1-2.3-3.6-4.9-4.4-7.9c-0.3-1.3-0.5-2.6-0.8-4.1c-2.5,0.5-5,1-7.4,1.6C3.5,25.5,2.3,27,2.3,30c0,4,0,8,0,12
                c0,0.3,0.1,0.6,0.1,0.9c9.1,0,18.1,0,27.1,0c-0.1-1-0.5-1.5-1.5-1.4c-0.9,0.1-1.3-0.4-1.2-1.3c0-0.7,0-1.5,0-2.2
                c0-0.8,0.3-1.4,1.2-1.2c1.3,0.2,1.3-0.9,1.7-1.6c0.4-0.7-0.1-1-0.5-1.3c-0.6-0.6-0.6-1.1,0-1.7c0.5-0.4,0.9-0.9,1.4-1.3
                c1.1-1.1,1.1-1.1,2.3-0.1c0.2,0.2,0.8,0.4,1,0.2c0.6-0.4,1.7-0.1,1.7-1.3c0-1,0.2-1.7,1.4-1.7c-0.5-1.3-1.3-2.4-2.5-2.7
                C31.6,24.3,28.7,23.7,25.7,23z M47.5,38.2c-1.7-0.2-1.7-0.2-2.3-1.8c-0.1-0.4-0.3-0.8-0.5-1.1c-0.6-0.7-0.5-1.3,0.2-1.9
                c0.1-0.1,0.2-0.3,0.3-0.4c-0.4-0.4-0.7-0.8-1.1-1.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.6,0.7-1.2,0.8-2,0.2c-0.5-0.4-1.3-0.4-1.8-0.7
                c-0.4-0.2-0.7-0.6-0.9-1c-0.1-0.3-0.1-0.6-0.1-0.9c-1.5-0.2-1.5-0.2-1.8,1.2c-0.1,0.3-0.4,0.6-0.7,0.8c-0.8,0.4-1.6,0.7-2.4,1
                c-0.4,0.1-0.9,0-1.3-0.2c-0.3-0.1-0.5-0.4-0.6-0.5c-0.4,0.4-0.8,0.7-1.3,1.1c1.2,0.8,1.3,1.7,0.5,2.7c-0.2,0.2-0.3,0.5-0.3,0.8
                c-0.2,1.2-0.8,1.8-2.1,1.7c0,0-0.1,0.1-0.1,0.1c0,0.5,0,1,0,1.7c1.5-0.4,2.1,0.5,2.3,1.7c0,0.2,0.1,0.4,0.2,0.6
                c0.8,1,0.9,2-0.4,2.8c0.4,0.4,0.8,0.8,1.2,1.3c0.8-1.3,1.8-1.3,2.8-0.4c0.1,0.1,0.2,0.1,0.4,0.2c1.3,0.2,2.3,0.7,2,2.3
                c0.7,0,1.2,0,1.7,0c0.3-1.7,0.4-1.7,1.9-2.3c0.4-0.2,0.9-0.4,1.3-0.6c0.6-0.4,1.1-0.3,1.5,0.2c0.2,0.2,0.4,0.4,0.5,0.4
                c0.5-0.3,0.9-0.6,1.3-0.9c-0.3-0.3-0.6-0.6-0.7-0.9c-0.1-0.4-0.2-0.8-0.1-1.2c0.3-0.8,0.6-1.6,1-2.4c0.2-0.3,0.5-0.7,0.9-0.7
                C47.8,39.7,47.8,39.7,47.5,38.2z"></path>
                <path d="M12.8,13.2c-0.8,1.7-1.9,2.4-3.2,2C8.7,15,8,14.4,7.9,13.4c-0.2-1.2-0.3-2.5,0.9-3.3c0.6-0.4,0.8-0.8,0.9-1.4
                c0.8-5.1,5.2-8.7,10.4-8.6c5,0.1,9.3,3.9,10,8.9c0.1,0.4,0.4,0.8,0.7,1c1.2,0.8,1.2,1.9,1.1,3.1c-0.1,1-0.7,1.7-1.6,2.1
                c-0.3,0.2-0.6,0.4-0.9,0.8c-1.2,1.8-2.8,2.9-5.1,2.8c-0.3,0-0.7,0.2-1,0.4c-2.4,2-4.9,1.9-7.1-0.3c-1.4-1.4-2.3-3-3-4.8
                C13,13.8,13,13.5,12.8,13.2z M21.9,17.8c-0.8-0.2-1.5-0.4-2.2-0.5c-0.8-0.1-1.4-0.6-1.5-1.4c-0.1-0.8,0.3-1.4,1-1.6
                c0.8-0.3,1.5,0,1.9,0.7c0.1,0.2,0.1,0.5,0.2,0.7c0.5,0.4,1.1,0.8,1.6,1c0.2,0.1,0.6-0.4,0.8-0.7c1.1-1.6,1.7-3.4,1.9-5.3
                c0.4-3.1-1.8-5.9-5-6.5c-2.9-0.5-5.9,1.4-6.7,4.4c-0.6,2.2,0.1,4.2,1,6.1c0.7,1.5,1.6,2.9,3.1,3.9C19.3,19.2,21,19.2,21.9,17.8z
                M12.2,10.3c0.5-4.5,3-7.4,6.7-7.7c1.8-0.2,3.5,0.2,5.1,1.2c2.3,1.6,3.3,3.8,3.4,6.5c0.4-0.5,1.2-0.4,1.1-1.4
                c-0.6-4.1-4.1-7.2-8.2-7.4c-4.2-0.2-8,2.6-9,6.6C11,9.2,11,10,12.2,10.3z M26.9,13.4c-0.6,1.2-1.1,2.4-1.7,3.8
                c1.4-0.3,2.2-1.1,2.9-2.1C27.6,14.4,27.2,13.9,26.9,13.4z M11.2,12.4c0-0.7-0.2-1.3-1-1.3c-0.8,0-0.9,0.7-0.9,1.3
                c0,0.7,0.1,1.4,1,1.3C11.1,13.8,11.2,13.1,11.2,12.4z M30.3,12.4c0-0.7-0.1-1.3-0.9-1.3c-0.8,0-1,0.6-1,1.3c0,0.7,0.1,1.3,1,1.3
                C30.2,13.8,30.3,13.1,30.3,12.4z"></path>
                <path d="M43.2,39.1c0,2.7-2.3,5-5,5c-2.8,0-5.1-2.3-5.1-5.1c0-2.7,2.3-5.1,5.1-5.1C40.8,33.9,43.2,36.3,43.2,39.1z M34.6,38.9
                c0,2,1.5,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5c0-1.9-1.5-3.4-3.5-3.4C36.2,35.4,34.6,36.9,34.6,38.9z"></path>
              </g>
            </svg>
          `
        },
        {
          title:"Integrating other SaaS Solutions",
          text:"No matter how your system architecture is, you can always integrate CRM software solutions with other existing software. Leverage holistic IT infrastructure with effective CRM software from supply chain to customer service for smooth and efficient business operations across all departments.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M29.8,45.7c-0.4,0.2-0.9,0-1.1-0.4l-1.1-2.8c-0.2-0.5-0.7-0.8-1.2-0.7c-2.2,0.4-4.5,0.4-6.7,0c-0.5-0.1-1,0.2-1.2,0.7
                    l-1.2,2.8c-0.2,0.4-0.7,0.6-1.1,0.4l-3-1.2c-0.4-0.2-0.6-0.7-0.4-1.1l1.1-2.8c0.2-0.5,0-1.1-0.4-1.3c-1.9-1.2-3.5-2.8-4.7-4.7
                    c-0.3-0.4-0.9-0.6-1.3-0.4l-2.8,1.1c-0.4,0.2-0.9,0-1.1-0.4l-1.2-3c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.2-0.4,0.5-0.5l2.8-1.1
                    c0.5-0.2,0.8-0.7,0.7-1.2c-0.4-2.2-0.4-4.5,0-6.7c0.1-0.5-0.2-1-0.7-1.2l-2.8-1.1c-0.4-0.2-0.6-0.7-0.4-1.1l1.2-3
                    c0.2-0.4,0.7-0.6,1.1-0.4l2.8,1.1c0.5,0.2,1.1,0,1.3-0.4c1.2-1.9,2.8-3.5,4.7-4.7c0.4-0.3,0.6-0.9,0.4-1.3l-1.1-2.8
                    c-0.1-0.2-0.1-0.4,0-0.6c0.1-0.2,0.2-0.4,0.5-0.5l3-1.2c0.2-0.1,0.4-0.1,0.6,0c0.2,0.1,0.4,0.2,0.5,0.5l1.1,2.8
                    c0.2,0.5,0.7,0.8,1.2,0.7c2.2-0.4,4.5-0.4,6.7,0c0.5,0.1,1-0.2,1.2-0.7l1.1-2.8c0.1-0.2,0.2-0.4,0.5-0.5c0.2-0.1,0.4-0.1,0.6,0
                    l3,1.2c0.4,0.2,0.6,0.7,0.4,1.1l-1.1,2.8c-0.2,0.6,0,1.2,0.6,1.4c0.6,0.2,1.2,0,1.4-0.6l1.1-2.8c0.6-1.6-0.1-3.3-1.6-4l-3-1.2
                    c-0.7-0.3-1.6-0.3-2.3,0c-0.7,0.3-1.3,0.9-1.7,1.7l-0.8,1.9c-1.9-0.3-3.8-0.3-5.7,0l-0.8-1.9c-0.3-0.7-0.9-1.3-1.7-1.7
                    c-0.7-0.3-1.6-0.3-2.3,0l-3,1.2c-0.7,0.3-1.3,0.9-1.7,1.7c-0.3,0.7-0.3,1.6,0,2.3l0.8,1.9c-1.6,1.1-2.9,2.5-4.1,4.1l-1.9-0.8
                    c-1.6-0.6-3.3,0.1-4,1.6l-1.2,3c-0.6,1.5,0.1,3.3,1.6,4l1.9,0.8c-0.3,1.9-0.3,3.8,0,5.7l-1.9,0.8c-0.7,0.3-1.3,0.9-1.7,1.7
                    c-0.3,0.7-0.3,1.6,0,2.3l1.2,3c0.6,1.5,2.4,2.3,4,1.6l1.9-0.8c1.1,1.6,2.5,2.9,4.1,4.1l-0.8,1.9c-0.5,1.2-0.2,2.5,0.7,3.3
                    c0.3,0.3,0.6,0.5,1,0.7l3,1.2c1.6,0.6,3.3-0.1,4-1.6l0.8-1.9c1.9,0.3,3.8,0.3,5.7,0l0.8,1.9c0.6,1.6,2.4,2.3,4,1.6l3-1.2
                    c0.7-0.3,1.3-0.9,1.7-1.7c0.3-0.7,0.3-1.6,0-2.3l-1.1-2.8c-0.2-0.6-0.9-0.8-1.4-0.6c-0.6,0.2-0.8,0.9-0.6,1.4l1.1,2.8
                    c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.2,0.4-0.5,0.5L29.8,45.7z"></path>
                  </g>
                  <g>
                    <path d="M28.3,19.6c0.4,0.4,1.1,0.4,1.6,0c0.4-0.4,0.4-1.1,0-1.6c0,0,0,0,0,0c-1.9-1.8-4.3-2.8-6.9-2.8c-2.6,0-5.1,1.1-6.9,2.9
                    c-1.8,1.9-2.8,4.3-2.8,6.9c0,2.6,1,5,2.9,6.8c0,0,0,0,0.1,0.1c1.9,1.8,4.3,2.8,6.9,2.8s5.1-1.1,6.9-2.9c0.4-0.4,0.4-1.1,0-1.6
                    c0,0,0,0,0,0c-0.4-0.4-1.1-0.4-1.6,0c-1.4,1.4-3.3,2.2-5.3,2.3c-2,0-3.9-0.8-5.4-2.2c0,0,0,0,0,0c-1.4-1.4-2.2-3.3-2.2-5.3
                    c0-2,0.8-3.9,2.2-5.4c1.4-1.4,3.3-2.2,5.3-2.3C24.9,17.4,26.8,18.2,28.3,19.6z"></path>
                  </g>
                </g>
                <g>
                  <path d="M36.4,31.9c-0.2,0-0.3,0-0.5-0.1l-10.6-5.9c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.2-0.7,0.5-0.9l10.6-5.9c0.3-0.2,0.7-0.2,1,0
                  c0.3,0.2,0.5,0.5,0.5,0.9v2.6H49c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1H36.4c-0.6,0-1-0.5-1-1v-1.9L27.9,25l7.4,4.1v-1.4
                  c0-0.6,0.5-1,1-1H49c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1H37.4v2.1c0,0.4-0.2,0.7-0.5,0.9C36.7,31.9,36.5,31.9,36.4,31.9z"></path>
                </g>
              </g>
            </svg>
          `
        },
        {
          title:"Automated Billing Process",
          text:"Manage multiple bills – vendors and customers. Gain insights to achieve a cost per customer acquisition and retention enabling effective marketing and sales strategy. Chose CRM development company for crafting an efficient CRM software that fits your inter and intra-departmental needs.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M45.8,14.2c-0.1-0.1-0.1-0.2-0.2-0.3L32,0.3C31.8,0.1,31.5,0,31.3,0H9.4C6.5,0,4.2,2.3,4.2,5.2v39.6
                C4.1,47.6,6.2,49.9,9,50c0.1,0,0.3,0,0.4,0h31.2c2.9,0,5.2-2.3,5.2-5.2V14.6C45.8,14.4,45.8,14.3,45.8,14.2z M40.6,47.9H9.4
                c-1.6,0.1-3-1.2-3.1-2.8c0-0.1,0-0.2,0-0.3V5.2c0-1.7,1.4-3.1,3.1-3.1h21.4l11.5,11.5h-6.9c-1.7,0-3.1-1.4-3.1-3.1V7.3
                c0-0.6-0.5-1-1-1s-1,0.5-1,1v3.1c0,2.9,2.3,5.2,5.2,5.2h8.3v29.2C43.8,46.5,42.4,47.9,40.6,47.9z"></path>
                <path d="M37.5,31.2h-2.6c-0.2-0.6-0.4-1.1-0.7-1.7l1.8-1.8c0.8-0.8,0.8-2.1,0-3l-1.5-1.5c-0.8-0.8-2.1-0.8-2.9,0l-1.8,1.8
                c-0.5-0.3-1.1-0.5-1.7-0.7v-2.6c0-1.2-0.9-2.1-2.1-2.1H24c-1.2,0-2.1,0.9-2.1,2.1v2.6c-0.6,0.2-1.1,0.4-1.7,0.7l-1.8-1.8
                c-0.8-0.8-2.1-0.8-3,0l-1.5,1.5c-0.8,0.8-0.8,2.1,0,2.9l1.8,1.8c-0.3,0.5-0.5,1.1-0.7,1.7h-2.6c-1.2,0-2.1,0.9-2.1,2.1v2.1
                c0,1.2,0.9,2.1,2.1,2.1h3.1c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1h-3.1v-2.1h3.4c0.5,0,0.9-0.3,1-0.8c0.2-0.9,0.6-1.8,1.1-2.6
                c0.3-0.4,0.2-1-0.1-1.3l-2.4-2.4l1.5-1.5l2.4,2.4c0.3,0.3,0.9,0.4,1.3,0.1c0.8-0.5,1.7-0.9,2.6-1.1c0.5-0.1,0.8-0.5,0.8-1v-3.4H26
                v3.4c0,0.5,0.3,0.9,0.8,1c0.9,0.2,1.8,0.6,2.6,1.1c0.4,0.3,1,0.2,1.3-0.1l2.4-2.4l1.5,1.5l-2.4,2.4c-0.3,0.3-0.4,0.9-0.1,1.3
                c0.5,0.8,0.9,1.7,1.1,2.6c0.1,0.5,0.5,0.8,1,0.8h3.4v2.1h-3.1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h3.1c1.2,0,2.1-0.9,2.1-2.1v-2.1
                C39.6,32.2,38.7,31.2,37.5,31.2z"></path>
                <path d="M25,30.2c-2.3,0-4.2,1.9-4.2,4.2c0,0.6,0.5,1,1,1s1-0.5,1-1c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1c0,0.6,0.5,1,1,1
                s1-0.5,1-1C29.2,32.1,27.3,30.2,25,30.2z"></path>
              </g>
            </svg>
          `
        },
        {
          title:"Enable Online Customer Tracking",
          text:"Staying a step ahead of customers’ need equals to having an edge over the competitors. Custom CRM development helps online customer tracking to cater to each client, personally. Offer the best possible client solution by tracking entire cycle from delivery to service needs in real-time.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M19.8,29.5c0.3,0,0.5-0.1,0.7-0.3c0.7-0.7,6.4-6.6,6.4-10.9c0-3.9-3.2-7.1-7.1-7.1s-7.1,3.2-7.1,7.1
                c0,4.4,5.8,10.3,6.4,10.9C19.3,29.4,19.5,29.5,19.8,29.5z M19.8,13.1c2.8,0,5.1,2.3,5.1,5.1c0,2.6-3.2,6.7-5.1,8.7
                c-1.9-2.1-5.1-6.1-5.1-8.7C14.7,15.4,17,13.1,19.8,13.1z"></path>
                <path d="M22.9,18.2c0-1.7-1.4-3.1-3.1-3.1s-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1S22.9,19.9,22.9,18.2z M18.8,18.2c0-0.6,0.5-1,1-1
                c0.6,0,1,0.5,1,1s-0.5,1-1,1C19.3,19.3,18.8,18.8,18.8,18.2z"></path>
                <path d="M18.7,40.1c0-1,0-1.9,0-2.9c-9.3-1.4-14.6-6.7-16-16c-0.9,0-1.8,0-2.8,0c0-0.7,0-1.4,0-2.2c0.9,0,1.9,0,2.8,0
                c1.4-9.3,6.7-14.5,15.9-16c0-0.9,0-1.8,0-2.8c0.8,0,1.5,0,2.2,0c0,1,0,1.9,0,2.8c9.3,1.4,14.5,6.7,16,16c0.9,0,1.9,0,2.9,0
                c0,0.7,0,1.5,0,2.2c-1,0-2,0-2.9,0c-1.5,9.3-6.8,14.6-16,16c0,0.9,0,1.9,0,2.9C20.1,40.1,19.4,40.1,18.7,40.1z M18.7,4.8
                C10.2,5.4,4.6,12.6,4.5,19.1c1,0,2,0,3.1,0c0,0.8,0,1.5,0,2.2c-1.1,0-2.1,0-3.1,0c0.6,8.4,7.8,14,14.3,14.2c0-1,0-2,0-3.1
                c0.8,0,1.5,0,2.2,0c0,1.1,0,2.1,0,3.1c8.4-0.6,14.1-7.9,14.1-14.3c-1,0-2,0-3.1,0c0-0.8,0-1.5,0-2.2c1.1,0,2.1,0,3.1,0
                c-0.6-8.4-7.8-14-14.3-14.1c0,1,0,2,0,3.1c-0.8,0-1.5,0-2.2,0C18.7,6.8,18.7,5.8,18.7,4.8z"></path>
                <path d="M49.9,44.5c0-2.1-0.8-3.2-2.7-3.9c-0.8-0.3-1.7-0.5-2.5-0.7l-0.3-0.1c-0.1,0-0.1,0-0.2-0.1c-0.3-0.1-0.7-0.2-1.1,0
                c-0.3,0.1-0.4,0.4-0.5,0.7c-0.1,0.3-0.1,0.6,0.1,0.9c0.2,0.4,0.7,0.5,1,0.6c0.1,0,0.1,0,0.2,0c0.3,0.1,0.7,0.2,1,0.3
                c0.6,0.2,1.2,0.3,1.8,0.6c0.8,0.3,1.1,0.7,1,1.5c0,0.4,0,0.8,0,1.1c0,0.2,0,0.4,0,0.6c0,0.9-0.5,1.4-1.5,1.4c-0.7,0-1.5,0-2.2,0
                l-16.5,0c-1.1,0-1.6-0.5-1.6-1.6l0-0.6c0-0.4,0-0.8,0-1.2c0-0.5,0.2-0.8,0.6-1c0.3-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.8-0.2,1.1-0.3
                c0.5-0.1,1-0.3,1.5-0.4c0.3-0.1,0.5-0.4,0.6-0.6c0-0.1,0.1-0.1,0.1-0.1l0.4-0.5l-0.5-0.3c0,0-0.1-0.1-0.1-0.1
                c-0.2-0.2-0.5-0.4-0.9-0.3l-0.6,0.2c-1.2,0.3-2.4,0.7-3.5,1.1c-1.3,0.6-2.1,1.7-2,3.3c0,0.3,0,0.7,0,1l0,0.5c0,2.4,1.5,3.8,3.8,3.8
                c0,0,0,0,0,0l9.2,0l3,0c0.9,0,1.7,0,2.6,0c1.3,0,2.6,0,4,0c2,0,3.5-1.5,3.5-3.5c0-0.4,0-0.9,0-1.3L49.9,44.5z"></path>
                <path d="M44.5,32.4l0-0.1c0-0.5,0-1,0-1.4c-0.1-3.2-2.3-5.9-5.2-6.9c-0.1,0.7-0.3,1.5-0.5,2.1c2,0.7,3.5,2.6,3.6,4.9
                c0,0.4,0,0.9,0,1.3l0,0.6l0,0.6l0,0c0,0.1,0,0.3,0,0.4c0,0.7-0.1,1.4-0.2,2c-0.6,2.6-3,4.4-5.7,4.1c-2.5-0.2-4.3-1.9-4.9-4.2
                c-0.6,0.4-1.3,0.8-2,1.1c0.9,2.8,3.3,4.9,6.2,5.2c0.3,0,0.7,0.1,1,0.1c3.4,0,6.4-2.1,7.3-5.4c0.3-0.9,0.3-1.8,0.4-2.7
                c0-0.4,0.1-0.8,0.1-1.2L44.5,32.4z"></path>
                <path d="M29.5,29.5c-0.1,0.4-0.1,0.8-0.2,1.2c2-1.8,3.5-4,4.3-6.3C31.6,25.2,30,27.1,29.5,29.5z"></path>
              </g>
            </svg>
          `
        },
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
