<template>
	<div class="ar-why-box">
		<div class="ar-why-box-icon" v-html="obj.svg"></div>
		<h4>{{ obj.title }}</h4>
		<p class="mb-0">{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.ar-why-box {
	width: 100%;
	background: #fff;
	border: 1px solid #ecedee;
	border-radius: 10px;
	text-align: start;
	padding: 20px 13px;
	transition: all 0.3s ease;
	margin-bottom: 30px;
	min-height: 320px;
}

.ar-why-box:hover {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}
.ar-why-box-icon {
	margin: 0;
	width: 70px;
	height: 70px;
	border-radius: 7px;
	padding: 10px;
	transition: all 0.3s ease;
}
.ar-why-box h4 {
  font-weight: bold;
	font-size: 18px;
	margin: 15px 0 10px;
	transition: all 0.3s ease;
	min-height: 50px;
	display: flex;
	align-items: start;
	line-height: 1.3;
	justify-content: start;
	width: 100%;
}
.ar-why-box-icon svg circle,
.ar-why-box-icon svg ellipse,
.ar-why-box-icon svg path,
.ar-why-box-icon svg polygon,
.ar-why-box-icon svg rect {
	transition: all 0.3s ease;
	fill: #6a6d6f;
}

.ar-why-box:hover  h4{
  color:#1977cc
}
.ar-why-box:hover .ar-why-box-icon svg circle,
.ar-why-box:hover .ar-why-box-icon svg ellipse,
.ar-why-box:hover .ar-why-box-icon svg path,
.ar-why-box:hover .ar-why-box-icon svg polygon,
.ar-why-box:hover .ar-why-box-icon svg rect {
	fill: #1977cc;
}
</style>
