<template>
	<section class="padding-60 bg-light game-service-offer-main">
		<div class="container">
			<div class="title">
				<span>Services We Offer</span>
				<h2 class="fxt-off-dev">Our Player-centric Mobile Game Development Services</h2>
				<p>
					Our proficiency in the art of designing games for the variety of game categories and game
					programming in a range of game arena wins the hearts of avid mobile game lovers. In turn,
					it helps clients to win tons of downloads, and hefty contribution of game-based revenue
					through various monetization models.
				</p>
			</div>
			<div class="row justify-content-between m-height-450" style="gap: 15px; flex-wrap: nowrap">
				<LongCard
					v-for="card in cards"
					v-bind:obj="card"
					class="col-12 col-sm-6 col-md-6 col-lg-4"
					v-bind:key="card.title"
				>
				</LongCard>
			</div>
			<div class="row justify-content-between m-height-450" style="gap: 15px; flex-wrap: nowrap">
				<LongCard
					v-for="card in cards1"
					v-bind:obj="card"
					class="col-12 col-sm-6 col-md-6 col-lg-4"
					v-bind:key="card.title"
				>
				</LongCard>
			</div>
		</div>
	</section>
</template>

<script>
import LongCard from '../AssetComponents/LongCard';

export default {
	data: () => {
		return {
			cards: [
                {
                    title:"iOS Game Development",
                    text:"Our familiarity with iPhone & iPad SDKs as well as device & OS features enables us to deliver excellent iPhone/iPad game experiences. Our standard game development approaches results in quick approvals in App Store.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                   <g>
                     <g>
                         <path class="st0" d="M49.7,25.7c0.4,0,0.8,0,1.1,0c5.3,0,11-2.9,14.8-7.5c4-4.8,6.1-11,5.5-16.6c-0.1-1-1-1.7-2-1.7
                         c-5.4,0.2-11.8,3.5-15.6,7.9c-3.3,3.7-6.4,9.9-5.6,16.2C48.1,24.9,48.8,25.6,49.7,25.7z M56.5,10.3c2.7-3.1,7.1-5.6,11-6.3
                         c-0.1,4.1-1.8,8.4-4.7,11.9c-2.9,3.5-7.2,5.8-11.1,6.1C51.7,17.4,54.1,12.9,56.5,10.3z M90.1,70c-1.2-0.5-11.7-5.1-11.8-17.5
                         c-0.1-9.9,7.2-15.1,9.6-16.6c0.4-0.3,0.7-0.7,0.8-1.2c0.1-0.5,0-1-0.3-1.4C82.3,24.5,73,23.2,69.4,23.1c-5-0.5-9.9,1.4-13.6,2.8
                         c-2,0.8-4,1.5-5.1,1.5c-1.3,0-3.2-0.8-5.3-1.6c-3.1-1.2-6.6-2.6-10.4-2.6l-0.3,0c-8.8,0.1-17.1,5.1-21.6,12.9
                         c-8.5,14.6-3.2,36.6,6.2,50C23,91.6,28.3,99,35.7,99l0.4,0c3.2-0.1,5.4-1.1,7.6-2c2.4-1,4.6-1.9,8.2-1.9c3.4,0,5.5,0.9,7.7,1.8
                         c2.2,1,4.6,1.9,8,1.9l0.3,0c7.7-0.1,12.5-7,16.4-12.5c4.1-6,6.2-11.7,6.9-14C91.5,71.4,91,70.4,90.1,70z M81.1,84.3
                         c-4.2,6.1-7.9,10.8-13.3,10.9l-0.3,0c-2.6,0-4.4-0.8-6.4-1.6c-2.4-1-5.1-2.2-9.2-2.2c-4.4,0-7.2,1.2-9.7,2.2
                         c-2,0.9-3.8,1.6-6.3,1.7l-0.3,0c-5,0-9.2-5.3-13.4-11.3c-11.7-16.6-11.8-36.1-6-46c3.9-6.7,10.9-10.9,18.4-11l0.2,0
                         c3.1,0,6.2,1.2,9,2.3c2.5,1,4.7,1.8,6.7,1.8c1.8,0,4-0.8,6.5-1.8c3.2-1.2,6.8-2.6,10.5-2.6c0.4,0,0.9,0,1.5,0.1
                         c2.9,0.1,10,1.1,15.1,7.1c-3.5,2.6-9.7,8.6-9.6,18.6c0.1,12.4,8.8,18.3,12.6,20.2C86.2,75.3,84.3,79.7,81.1,84.3z"></path>
                      </g>
                   </g>
                </svg>
                    `
                },
                {
                    title:"Android Game Development",
                    text:"We create addictive Android games thanks to our expertise on Android technologies and tools to deliver seamless game experiences across the range of Android devices including smartphones and tablets. Of course, everything with quick game adoption.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <path class="st0" d="M65.3,9l2.9-4.3c0.5-0.7,0.6-1.5,0.5-2.3c-0.2-0.8-0.6-1.5-1.3-1.9C66-0.4,64,0,63.1,1.3l-3.4,5.1
                  c-6.2-2-13.2-2-19.4,0l-3.4-5.1C36,0,34-0.4,32.7,0.5c-1.4,0.9-1.8,2.8-0.8,4.2L34.7,9c-7.1,4.2-11.5,11.4-11.5,18.9
                  c0,1.2,1,2.1,2.2,2.1h49.6c1.2,0,2.2-1,2.2-2.1C77,20.4,72.5,13.2,65.3,9L65.3,9z M27.6,25.8c0.8-5.9,5-11.2,11.1-14
                  c0.3-0.1,0.5-0.3,0.7-0.5c0.3,0,0.6,0,0.8-0.1c6.1-2.4,13.4-2.4,19.5,0c0.3,0.1,0.6,0.2,0.8,0.1c0.2,0.2,0.4,0.4,0.7,0.5
                  c6.2,2.9,10.4,8.2,11.3,14H27.6z M74.8,32.2H25.2c-1.2,0-2.2,1-2.2,2.2v39.9c0,4.3,3.5,7.8,7.8,7.8h1.7v10.5c0,4.1,3.3,7.5,7.5,7.5
                  c4.1,0,7.5-3.3,7.5-7.5V82h5.1v10.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5V82h1.7c4.3,0,7.8-3.5,7.8-7.8V34.4
                  C77,33.2,76,32.2,74.8,32.2L74.8,32.2z M72.7,74.3c0,1.9-1.5,3.5-3.5,3.5h-4.1c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.3-0.3,1.9
                  c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9
                  c-0.4-0.5-1-0.9-1.7-0.9h-9.9c-0.7,0-1.3,0.3-1.7,0.9c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.3,0.1,0.6,0.1,0.9v11.2c0,1.7-1.4,3.2-3.2,3.2
                  c-1.7,0-3.2-1.4-3.2-3.2V81.3c0-0.3,0-0.6,0.1-0.9c0.2-0.7,0.1-1.4-0.3-1.9c-0.4-0.5-1.1-0.9-1.7-0.9h-4.1c-1.9,0-3.5-1.6-3.5-3.5
                  V36.5h45.3V74.3z M14.2,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                  C21.6,36.5,18.3,33.2,14.2,33.2L14.2,33.2z M17.3,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                  c1.7,0,3.2,1.4,3.2,3.2V63.3z M85.8,33.2c-4.1,0-7.5,3.3-7.5,7.5v22.7c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5V40.6
                  C93.3,36.5,90,33.2,85.8,33.2L85.8,33.2z M89,63.3c0,1.7-1.4,3.2-3.2,3.2c-1.7,0-3.2-1.4-3.2-3.2V40.6c0-1.7,1.4-3.2,3.2-3.2
                  c1.7,0,3.2,1.4,3.2,3.2V63.3z M60.3,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                  C62.8,17.3,61.7,16.2,60.3,16.2L60.3,16.2z M40.9,16.2c-1.4,0-2.6,1.1-2.6,2.6c0,1.4,1.1,2.6,2.6,2.6c1.4,0,2.6-1.1,2.6-2.6
                  C43.4,17.3,42.3,16.2,40.9,16.2L40.9,16.2z M40.9,16.2"></path>
               </svg>
                    `
                },
                {
                    title:"2D/3D Game Development",
                    text:"Our hands-on expertise in Unity platform and various game development frameworks like Cocoa Touch and Cocos2d enable us to create stunning graphics for game UI, background, and 2D/2D animations to provide real-world game experiences.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
                  <g>
                    <path d="M60.5,54.7c0-7.6,0-15.2,0-22.7c0,0,0,0,0,0c0-7.9,0-15.7,0-23.6c0-3.4-1.9-5-5-4.4c-4.3,0.9-8.7,2-13,2.9
                    c-3.6,0.7-7.1,1.9-10.8,1.9c-7.7-0.1-15.4,0-23,0c-3,0-4.6,1.6-4.6,4.6C4,25,4,36.7,4.2,48.4c0.1,3,1.4,4.3,4.4,4.3
                    c7.3,0,14.5,0.1,21.8,0c2.6,0,5,0.4,7.5,1.3c5.6,2,11.2,3.9,16.8,5.8c3.3,1.1,5.6-0.4,5.8-3.9C60.5,55.5,60.5,55.1,60.5,54.7z
                    M55.2,56c-6.7-2-13.4-3.9-20.1-5.9c-0.7-0.2-1.5-0.3-2.2-0.3c-7.9,0-15.8,0-23.7,0c-2,0-2.1-0.1-2.1-2.1c0-11.4,0-22.9,0-34.3
                    c0-1.7,0.1-1.8,1.9-1.8c7.8,0,15.5,0,23.3,0c3.4,0,6.7-0.9,10-1.5c4.5-0.7,8.9-1.5,13.4-2.4c1.2-0.2,1,0.5,1,1.2c0,7.7,0,15.3,0,23
                    c0,7.6,0,15.3,0,22.9C56.7,56.2,56.3,56.3,55.2,56z"></path>
                    <path d="M46,24.3c-0.3,0.1-0.6,0.2-1,0.2c-0.8,0-1,0.4-0.9,1.2c0,2,0,4.1,0,6.1c0,1.6,0,3.2,0,4.7c0,2.6,0,2.6,2.5,3.1
                    c3,0.7,4.7-0.2,5.7-3.2c0.9-2.6,0.9-5.2,0.3-7.8C51.9,24.9,49.8,23.6,46,24.3z M50,34.8c-0.2,0.6-0.6,1-1.2,1.3
                    c-1.4,0.8-2.3,0.3-2.3-1.3c0-1,0-2,0-3.1c0-1.2,0-2.3,0-3.5c0-0.7,0.3-0.9,0.9-0.9c1.1,0,2,0.5,2.4,1.6
                    C50.8,30.9,50.8,32.9,50,34.8z"></path>
                    <path d="M27.9,27.7c-1.7-0.8-3.5-0.7-5.3-0.6c-0.6,0-0.8,0.3-0.7,0.9c0,1.4,0,2.9,0,4.3c0,0,0,0,0,0c0,1.4,0,2.9,0,4.3
                    c0,0.3-0.1,0.8,0.5,0.8c2,0,4,0.3,5.9-0.7c1.9-1.1,2.7-2.7,2.6-4.9C30.7,29.8,29.7,28.5,27.9,27.7z M28.3,32.7
                    c-0.2,1.5-1.8,2.6-3.4,2.5c-0.5,0-0.6-0.3-0.6-0.7c0-0.8,0-1.6,0-2.4c0-0.5,0.1-1,0-1.5c-0.2-1.2,0.3-1.5,1.5-1.3
                    C27.5,29.6,28.5,30.9,28.3,32.7z"></path>
                    <path d="M41.9,31.5c-0.2-0.7,0.9-1.6,0.8-2.6c0-0.1,0-0.3,0-0.8c-0.1-0.9-0.3-2.1-1.6-2.6c-1.2-0.5-2.3,0-3.2,0.8
                    c-0.4,0.3-0.7,0.7-1.1,1.1c-0.3,0.3-0.2,0.6,0,0.9c0.6,0.8,1,0.8,1.7,0.1c0.4-0.5,1-0.8,1.6-0.3c0.5,0.4,0.4,1.1,0.2,1.7
                    c-0.2,0.4-0.6,0.7-1,0.7c-0.8,0.1-1,0.5-1.1,1.3c0,0.9,0.5,0.9,1.1,1c0.6,0.1,1.1,0.5,1.1,1.2c0,0.5,0,1-0.5,1.4
                    c-0.6,0.4-1.1,0-1.6-0.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.2-0.6-1.1-1.1-0.2c-0.3,0.6-1.3,1.1-0.3,1.8c0.4,0.3,0.8,0.7,1.3,1
                    c1.2,0.7,2.4,1.4,3.8,0.5c1.3-0.9,1.4-2.3,1.2-3.8C42.8,33.1,42.2,32.3,41.9,31.5z"></path>
                    <path d="M19.1,35.2c-0.8,0-1.6,0-2.7,0c1-1.1,1.9-1.9,2.5-2.9c1.1-1.9,0.8-4.1-0.8-5.1c-1.7-1.1-3.9-0.6-5.2,1.1
                    c-0.2,0.3-0.5,0.8-0.1,0.9c0.7,0.3,1.4,1.2,2.2,0.1c0.4-0.5,0.9-0.6,1.5-0.4c0.5,0.2,0.8,0.5,0.9,1c0.1,0.6-0.3,1.1-0.6,1.5
                    c-1.3,1.6-2.6,3.2-3.9,4.7c-0.2,0.3-0.5,0.6-0.3,1c0.2,0.4,0.6,0.2,0.9,0.2c1.9,0,3.8,0,5.7,0c1.1,0,0.8-0.8,0.9-1.3
                    C20.2,35.4,19.8,35.2,19.1,35.2z"></path>
                    <path d="M38.4,20.9c-0.2,0.2-0.6,0.3-0.6,0.7c1.6,0.4,3.3,0.7,5,1.1c-0.1-0.5-0.1-0.8-0.1-1.2c-0.3-1.3-0.6-2.7-1-4
                    c-1.4,1-1.4,1-3,0.1c-4.6-2.5-9.2-2.4-13.6,0.5c-0.7,0.4-1.1,0.6-1.6-0.1c-0.1-0.2-0.3-0.5-0.7-0.4c-0.4,1.7-0.8,3.3-1.2,5.2
                    c1.2-0.3,2.2-0.6,3.3-0.8c0.4-0.1,0.9-0.2,1.3-0.3c1.2-0.1,0.6-0.5,0.1-0.8c-0.5-0.5-0.3-0.9,0.3-1.2c3.5-2.3,8.1-2.2,11.7,0.1
                    C38.9,20.1,38.9,20.4,38.4,20.9z"></path>
                  </g>
                </svg>
                    `
                },
            ],
			cards1: [
                {
                    title:"AR Game Development",
                    text:"The rise of pokemon go has been a gamechanger in the augmented reality game industry.  With the vast experience in cutting-edge reality technologies, our developers are proficient in creating fun, exciting and an immersive AR game.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                   <g>
                      <g>
                        <path class="st0" d="M17.7,28.9h-2.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h2.6c0.3,0,0.5-0.2,0.5-0.5
                        C18.2,29.1,18,28.9,17.7,28.9z M10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.5,10,12.5,10,12.4
                        C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4L10,12.4C10,12.5,10,12.5,10,12.4L10,12.4
                        C10,12.5,10,12.5,10,12.4C10,12.5,10,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4L10,12.4
                        C9.9,12.5,9.9,12.5,10,12.4C9.9,12.5,9.9,12.5,10,12.4c-0.1,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2v0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
                        c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1l6,2.1c0,0,0.1,0,0.1,0h0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0.1,0,0.1,0l6-2.1
                        c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0v-7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.1,0-0.2-0.1-0.2c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l-6-2.1c-0.1,0-0.2,0-0.3,0l-6,2.1C10.1,12.4,10.1,12.4,10,12.4
                        C10.1,12.4,10.1,12.4,10,12.4C10.1,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4C10,12.4,10,12.4,10,12.4L10,12.4
                        C10,12.4,10,12.4,10,12.4z M21.9,19.5l-3.1-1c-0.3-0.1-0.5,0-0.6,0.3c-0.1,0.2,0,0.5,0.3,0.6l2.4,0.8l-4.1,1.4v-6.3l5.1-1.7V19.5z
                        M16.4,11.2l4.6,1.6l-4.6,1.6l-4.6-1.6L16.4,11.2z M10.8,13.5l5.1,1.7v6.3l-4.1-1.4l2.4-0.8c0.3-0.1,0.4-0.4,0.3-0.6
                        c-0.1-0.2-0.4-0.4-0.6-0.3l-3,1V13.5z M26.7,0H6C5,0,4.1,0.8,4.1,1.8v28.4C4.1,31.2,5,32,6,32h20.7c1.1,0,1.9-0.8,1.9-1.8V1.8
                        C28.7,0.8,27.8,0,26.7,0z M27.7,30.2c0,0.5-0.4,0.9-0.9,0.9H6c-0.5,0-0.9-0.4-0.9-0.9V1.8c0-0.5,0.4-0.9,0.9-0.9h20.7
                        c0.5,0,0.9,0.4,0.9,0.9V30.2z M25.2,2.4H7.5C7,2.4,6.6,2.7,6.6,3.2v23.9C6.6,27.6,7,28,7.5,28h17.7c0.5,0,0.9-0.4,0.9-0.8V3.2
                        C26.1,2.7,25.7,2.4,25.2,2.4z M25.1,5.2H11.9c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h13.3V27H7.6V6.1h1.9
                        c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H7.6V3.3h17.5V5.2z"></path>
                     </g>
                  </g>
                </svg>
                    `
                },
                {
                    title:"VR Game Development",
                    text:"The massive hike in the demand for virtual reality game has brought us to the top of the table in VR game development. We develop innovative and exciting looking feature rich and high interface interactive reality game for devices like goggles or headsets.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                   <g>
                      <g>
                        <path class="st0" d="M11.2,14.9h2.6c0.6,0,1.1-0.3,1.3-0.8c0.1-0.1,0.2-0.2,0.4-0.2h0.9c0.2,0,0.3,0.1,0.4,0.2
                        c0.3,0.5,0.8,0.8,1.3,0.8h2.6c1.2,0,2.1-1,2.1-2.1v-2.1c0-1.2-1-2.1-2.1-2.1h-9.6c-1.2,0-2.1,1-2.1,2.1v2.1
                        C9.1,14,10,14.9,11.2,14.9z M10.1,10.7c0-0.6,0.5-1.1,1.1-1.1h9.6c0.6,0,1.1,0.5,1.1,1.1v2.1c0,0.6-0.5,1.1-1.1,1.1h-2.6
                        c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.5-0.8-0.8-1.3-0.8h-0.9c-0.6,0-1.1,0.3-1.3,0.8c-0.1,0.1-0.2,0.2-0.4,0.2h-2.6
                        c-0.6,0-1.1-0.5-1.1-1.1V10.7z M30.4,26c-2.5-1.3-6.8-2.8-9.6-3.4c2.3-2,3.7-5.2,3.7-8.2h0.5c0.6,0,1.1-0.5,1.1-1.1v-3.2
                        c0-0.6-0.5-1.1-1.1-1.1h-0.5c0-0.3-0.1-0.6-0.2-0.9C23.6,4.2,20.3,0,16,0c-4.3,0-7.6,4.2-8.3,8.2C7.5,8.5,7.5,8.8,7.5,9.1H6.9
                        c-0.6,0-1.1,0.5-1.1,1.1v3.2c0,0.6,0.5,1.1,1.1,1.1h0.5c0,3,1.4,6.2,3.7,8.2c-2.8,0.6-7.1,2.1-9.6,3.4c-1,0.5-1.6,1.5-1.6,2.6v2.8
                        C0,31.8,0.3,32,0.5,32h30.9c0.3,0,0.5-0.2,0.5-0.5v-2.8C32,27.5,31.4,26.5,30.4,26z M24.5,10.1h0.5l0,3.2h-0.5V10.1z M7.5,13.3
                        H6.9v-3.2h0.5V13.3z M16,1.1c3.2,0,5.8,2.8,6.9,5.9c-0.2,0-0.3-0.1-0.5-0.1H9.6C9.4,6.9,9.2,7,9.1,7C10.2,3.9,12.8,1.1,16,1.1z
                        M8.5,9.1C8.5,8.5,9,8,9.6,8h12.8c0.4,0,0.8,0.3,1,0.6c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4v5.3c0,0.6-0.5,1.1-1.1,1.1H9.6
                        c-0.6,0-1.1-0.5-1.1-1.1V9.1z M8.7,16.3c0.3,0.1,0.6,0.2,0.9,0.2h12.8c0.3,0,0.6-0.1,0.9-0.2c-0.8,4.2-4.2,7.1-7.3,7.1
                        C12.9,23.5,9.6,20.5,8.7,16.3z M17.5,24.4l-1.3,1.7c-0.1,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0-0.2-0.1l-1.3-1.7c0.5,0.1,1,0.2,1.5,0.2
                        C16.5,24.5,17,24.5,17.5,24.4z M24.5,30.9h-3.2v-1.1h3.2V30.9z M30.9,30.9h-5.3v-1.6c0-0.3-0.2-0.5-0.5-0.5h-4.3
                        c-0.3,0-0.5,0.2-0.5,0.5v1.6H1.1v-2.3c0-0.7,0.4-1.4,1-1.7c2.8-1.4,7.7-3.1,10.3-3.5l0.1,0c0.1,0,0.1,0.1,0.2,0.1l2.3,3
                        c0.2,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5l2.3-3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1c2.6,0.4,7.5,2,10.3,3.5c0.6,0.3,1,1,1,1.7V30.9z
                        "></path>
                      </g>
                   </g>
                </svg>
                    `
                },
                {
                    title:"Mac Game Development",
                    text:"Apple is known for its affinity to the quality and user experiences, so we are expert Mac game developers with a knack in leveraging out-of-box features of OS X to deliver awesome game plays using our expertise in Apple technologies, tools, and devices.",
                    svg:`
                    <svg x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
             		<path class="st0" d="M3,14.8h2.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,14.8,3,14.8z"></path>
             		<path class="st0" d="M3,12.8h1c0.3,0,0.5-0.2,0.5-0.5S4.3,11.8,4,11.8H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,12.8,3,12.8z"></path>
             		<path class="st0" d="M3,16.8h1c0.3,0,0.5-0.2,0.5-0.5S4.3,15.8,4,15.8H3c-0.3,0-0.5,0.2-0.5,0.5S2.7,16.8,3,16.8z"></path>
             		<path class="st0" d="M26.5,14.8H29c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-2.5c-0.3,0-0.5,0.2-0.5,0.5S26.2,14.8,26.5,14.8z"></path>
             		<path class="st0" d="M28,12.8h1c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-1c-0.3,0-0.5,0.2-0.5,0.5S27.7,12.8,28,12.8z"></path>
             		<path class="st0" d="M29,15.8h-1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1c0.3,0,0.5-0.2,0.5-0.5S29.3,15.8,29,15.8z"></path>
             		<path class="st0" d="M30.5,3.8h-29C0.7,3.8,0,4.4,0,5.3v18.5c0,0.8,0.7,1.5,1.5,1.5H14c0,0.3,0,0.5-0.1,0.8
             		c-0.2,0.4-0.5,0.8-0.8,1.1c0,0,0,0,0,0h-1.3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h8.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5
             		h-1.6c0,0,0,0,0,0c-0.3-0.4-0.5-0.7-0.8-1.1c-0.1-0.3-0.1-0.5-0.1-0.8h12.7c0.8,0,1.5-0.7,1.5-1.5V5.3C32,4.4,31.3,3.8,30.5,3.8z
             		M1.5,4.8h29C30.8,4.8,31,5,31,5.3v17H1v-17C1,5,1.2,4.8,1.5,4.8z M16.8,25.3c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.3,0.6,0.5,0.8h-3.2
             		c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.4,0.2-0.8,0.2-1.2H16.8z M30.5,24.3h-29C1.2,24.3,1,24,1,23.8v-0.5h30v0.5
             		C31,24,30.8,24.3,30.5,24.3z"></path>
             		<path class="st0" d="M12.1,19.6c0.3,0.4,0.8,0.8,1.3,1c0.6,0.2,1.2,0.1,1.7-0.1c0.1,0,0.2-0.1,0.2-0.1c0.5-0.2,1.1-0.2,1.6,0
             		l0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.6,0.2,1.4,0.1,1.9-0.3c0.7-0.5,1.3-1.2,1.7-2c0.1-0.3,0.3-0.5,0.4-0.7c0.2-0.2,0.2-0.5,0.2-0.7
             		c0-0.1-0.1-0.2-0.3-0.3c-0.9-0.4-1.3-1-1.4-1.8s0.3-1.5,1.1-2c0.2-0.1,0.3-0.4,0.2-0.7c0,0,0,0,0-0.1l0,0c-0.8-1-2.1-1.5-3.3-1.3
             		c-0.3,0.1-0.6,0.1-0.9,0.2c-0.5,0.2-0.9,0.3-1.4,0.1l-0.3-0.1c-0.6-0.2-1.2-0.3-1.8-0.3c-0.8,0.1-1.6,0.4-2.2,1c-1,1-1.4,2.5-1,4.3
             		C10.6,17.4,11.2,18.6,12.1,19.6z M12.1,12.5c0.4-0.4,1-0.7,1.6-0.8h0.1c0.4,0,0.8,0.1,1.2,0.3l0.3,0.1c0.8,0.3,1.4,0,2-0.1
             		c0.2-0.1,0.5-0.2,0.8-0.2c0.7-0.1,1.5,0.1,2,0.6c-0.8,0.6-1.2,1.6-1.1,2.6c0.1,1,0.7,1.9,1.6,2.4l0,0c-0.5,1-1.1,1.7-1.6,2.1
             		c-0.3,0.2-0.7,0.3-1,0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.2-0.1c-0.8-0.3-1.6-0.3-2.4,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.5,0.2
             		c-0.7,0.1-1.2-0.5-1.4-0.8c-0.8-0.9-1.3-2-1.6-3.1C11.2,15.2,11,13.5,12.1,12.5L12.1,12.5z"></path>
             		<path class="st0" d="M15.5,10.1c0.1,0.1,0.2,0.1,0.3,0.1h0.1c2.5-0.4,3-2.5,2.9-3.5c0-0.3-0.3-0.5-0.5-0.5c0,0,0,0-0.1,0
             		c-2.6,0.5-3,2.5-2.9,3.6C15.3,9.9,15.3,10.1,15.5,10.1z M17.7,7.4c-0.1,0.8-0.7,1.4-1.4,1.7C16.4,8.3,17,7.7,17.7,7.4L17.7,7.4z"></path>
             		<circle class="st0" cx="22.3" cy="27.8" r="0.5"></circle>
             		<circle class="st0" cx="9.8" cy="27.8" r="0.5"></circle>
             	</svg>
                    `
                },
            ],
		};
	},
	components: {
		LongCard
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>
