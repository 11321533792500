<template>
	<section class="padding-60" style="background:#FFF">
		<div class="container">
			<div class="title">
				<span>Game Development Expertise</span>
				<h2 class="fxt-off-dev">Types of 2D 3D Mobile Game we Develop</h2>
				<p>
					Our proficiency in various game niches has earned us the big applauds from our clients in
					the UK and across the globe.
				</p>
			</div>
			<div class="row justify-content-between" style="gap: 15px; flex-wrap: wrap">
				<IconCard
					v-for="card in cards"
					v-bind:obj="card"
          class="col-6 col-sm-6 col-md-2 col-lg-2"
					v-bind:key="card.title"
				>
				</IconCard>
			</div>
		</div>
	</section>
</template>

<script>
import IconCard from '../AssetComponents/IconCard';

export default {
	data: () => {
		return {
			cards: [
				{
					title: 'Sport Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M99.3,53.3c-0.4-0.3-1-0.4-1.4-0.2l-23.7,9c0,0,0,0-0.1,0l-10.7,4.1h-4.7c-4.8,0-8.7,3.9-8.7,8.7c0,4.8,3.9,8.7,8.7,8.7
                    h4.7l34.5,13.1c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6-0.1,0.9-0.3c0.4-0.3,0.7-0.8,0.7-1.3V54.6C100,54,99.8,53.6,99.3,53.3z
                    M63.3,80.5h-4.5c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6h4.5V80.5z M80.8,63l1.6,2.9l-3.9,0.8c0-0.1-0.1-0.2-0.1-0.3
                    l-1.3-2L80.8,63z M77,70.1l3.9-0.8l-0.3,0.6c-0.3,0.5-0.3,1.1,0,1.5l1.1,1.9H78L76.4,71L77,70.1z M66.4,72.2l6.6-1.3
                    c0,0.3,0.1,0.7,0.2,1l1,1.5h-7.9V72.2z M66.4,76.5h7.7l-0.8,1.3C73,78.1,73,78.6,73.1,79l-6.7-1.3V76.5z M74,84.3l-7.6-2.9v-0.6
                    l8.7,1.7L74,84.3z M75.1,67.3l-8.7,1.7v-0.6l7.7-2.9L75.1,67.3L75.1,67.3z M77.8,76.5h4l-1.3,2.3c-0.3,0.5-0.3,1.1,0,1.5l0.1,0.1
                    l-3.4-0.7l-0.8-1.2L77.8,76.5z M81,87l-4-1.5l1.4-2.2c0,0,0,0,0-0.1l4.2,0.8L81,87z M96.9,93l-13-4.9l1.9-3.3c0,0,0-0.1,0-0.1
                    l11.1,2.2V93z M96.9,83.7l-12.2-2.4l-1-1.7l1.7-3.1h11.4V83.7z M96.9,73.4H85.2l-1.5-2.7l1.2-2.2l12-2.4V73.4z M96.9,63l-11.3,2.3
                    l-1.9-3.4l13.2-5V63z M57.8,62.6c1.3,0,2.7-0.1,4.1-0.3c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c3-0.5,6.2-1.4,9.3-2.7
                    c2.8-1.2,5.5-2.7,8-4.4c2.4-1.6,4.7-3.5,6.9-5.5c0,0,0,0,0,0l0.4-0.4c0.2-0.2,0.5-0.4,0.7-0.7c1.7-1.7,3.4-3.6,4.8-5.5
                    c1.9-2.5,3.4-5.1,4.7-7.9c0,0,0,0,0,0c1.5-3.3,2.4-6.7,2.7-10c0-0.3,0-0.6,0.1-0.8c0,0,0,0,0-0.1c0.2-3.6-0.3-7-1.6-10.2
                    c0-0.1-0.1-0.3-0.2-0.4c-1.1-2.6-2.7-5.1-4.8-7.1c-2.1-2.1-4.5-3.7-7.1-4.8c-0.1-0.1-0.3-0.1-0.4-0.2C82.7,0.4,79.3-0.2,75.7,0
                    c0,0,0,0-0.1,0c-0.3,0-0.6,0-0.9,0.1c0,0,0,0,0,0c-3.3,0.3-6.6,1.2-10,2.7C62.1,4,59.4,5.6,57,7.5c0,0,0,0,0,0
                    c-1.9,1.4-3.7,3-5.5,4.8c-0.2,0.2-0.5,0.5-0.7,0.7l-0.4,0.4c0,0,0,0,0,0c-2.1,2.2-3.9,4.5-5.5,6.9c-1.7,2.6-3.2,5.3-4.4,8
                    c-1.3,3.1-2.2,6.3-2.7,9.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.4,2.6-0.4,5-0.1,7.3L29.3,63
                    c-0.2,0.1-0.4,0.2-0.5,0.3L0.4,91.7c-0.6,0.6-0.6,1.6,0,2.2l5.7,5.7c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.8-0.2,1.1-0.5l28.3-28.3
                    c0.1-0.1,0.3-0.3,0.3-0.5l17.6-8.3C55.6,62.5,56.7,62.6,57.8,62.6z M61.8,58.6l-3.5-3.5l3.8-3.8l3.5,3.5L61.8,58.6z M47,43.8
                    l3.8-3.8l3.5,3.5l-3.8,3.8L47,43.8z M48.3,49.5l-4.1,4.1c-1-1.3-1.9-2.8-2.5-4.4l3.1-3.1L48.3,49.5z M78,31.1l-3.8,3.8l-3.5-3.5
                    l3.8-3.8L78,31.1z M76.8,25.4l3.8-3.8L84,25l-3.8,3.8L76.8,25.4z M72,37.1l-3.8,3.8l-3.5-3.5l3.8-3.8L72,37.1z M68.5,29.2
                    l-3.5-3.5l3.8-3.8l3.5,3.5L68.5,29.2z M66.3,31.4l-3.8,3.8l-3.5-3.5l3.8-3.8L66.3,31.4z M60.3,37.5l-3.8,3.8L53,37.8l3.8-3.8
                    L60.3,37.5z M62.5,39.7l3.5,3.5l-3.8,3.8l-3.5-3.5L62.5,39.7z M68.2,45.3l3.5,3.5l-3.8,3.8l-3.5-3.5L68.2,45.3z M70.4,43.1
                    l3.8-3.8l0.6,0.6l2.8,2.8l-3.8,3.8L70.4,43.1z M76.4,37.1l3.8-3.8l3.5,3.5l-3.8,3.8L76.4,37.1z M82.4,31.1l3.8-3.8l3.5,3.5
                    l-3.8,3.8L82.4,31.1z M88.4,25l3.8-3.8l3.5,3.5l-3.8,3.8L88.4,25z M86.2,22.8l-3.5-3.5l3.8-3.8l3.5,3.5L86.2,22.8z M80.6,17.2
                    l-3.5-3.5l3.8-3.8l3.5,3.5L80.6,17.2z M78.4,19.4l-3.8,3.8l-3.5-3.5l3.8-3.8L78.4,19.4z M68.9,17.5l-3.5-3.5l3.8-3.8l3.5,3.5
                    L68.9,17.5z M66.7,19.7l-3.8,3.8l-3.5-3.5l3.8-3.8L66.7,19.7z M60.7,25.8l-3.8,3.8l-3.5-3.5l3.8-3.8L60.7,25.8z M54.6,31.8
                    l-3.8,3.8l-1.1-1.1l-2.3-2.3l3.8-3.8L54.6,31.8z M56.5,45.7l3.5,3.5L56.1,53l-3.5-3.5L56.5,45.7z M66.9,57.9l0.9-0.9l0.4,0.4
                    C67.8,57.6,67.4,57.8,66.9,57.9z M71.4,56.2L70,54.8l3.8-3.8l2.5,2.5C74.7,54.5,73.1,55.4,71.4,56.2z M79,51.8l-3-3l3.8-3.8
                    l3.4,3.4C81.9,49.6,80.4,50.7,79,51.8z M85.5,46.2l-3.5-3.5l3.8-3.8l3.3,3.3C88,43.6,86.8,44.9,85.5,46.2z M91,39.7l-2.9-2.9
                    l3.8-3.8l1.9,1.9C93,36.5,92.1,38.1,91,39.7z M95.2,31.8l-1-1l1.9-1.9C95.8,29.8,95.5,30.8,95.2,31.8z M96.8,21.3L94.5,19l1.6-1.6
                    C96.4,18.7,96.7,20,96.8,21.3z M94.9,14.2l-2.7,2.7l-2.4-2.4l-1-1l3.4-3.4C93.3,11.2,94.2,12.6,94.9,14.2z M85.8,5
                    c1.5,0.7,2.9,1.6,4.2,2.7l-3.4,3.4l-3.5-3.5L85.8,5z M82.6,3.8l-1.6,1.6l-2.3-2.3C80,3.3,81.3,3.5,82.6,3.8z M75.3,4.2l3.5,3.5
                    l-3.8,3.8L71.4,8L75.3,4.2z M71.2,3.9l-1.9,1.9l-1-1C69.2,4.4,70.2,4.1,71.2,3.9z M65.1,6.1L67,8l-3.8,3.8l-2.9-2.9
                    C61.9,7.9,63.5,6.9,65.1,6.1z M57.8,10.8l3.2,3.3l-3.8,3.8l-3.5-3.5C55,13.1,56.4,11.9,57.8,10.8z M51.6,16.7l3.4,3.4l-3.8,3.8
                    l-3-3C49.2,19.5,50.4,18.1,51.6,16.7z M46.4,23.6l2.5,2.5l-3.8,3.8l-1.4-1.4C44.6,26.9,45.4,25.2,46.4,23.6z M42.5,31.7l0.4,0.4
                    L42.1,33C42.2,32.6,42.3,32.1,42.5,31.7z M45.2,34.3l3.5,3.5l-3.8,3.8l-3.5-3.5L45.2,34.3z M42.6,43.8l-1.8,1.8
                    c-0.2-1.2-0.3-2.5-0.3-3.9L42.6,43.8z M7.2,96.2l-3.5-3.5l6.3-6.3l3.5,3.5L7.2,96.2z M15.7,87.7l-3.5-3.5l17.6-17.6l3.5,3.5
                    L15.7,87.7z M35.6,67.9l-3.5-3.5l6.7-14.1c0.9,2.6,2.4,4.9,4.2,6.7c1.9,1.9,4.2,3.3,6.7,4.2L35.6,67.9z M50.8,58.3
                    c-1.7-0.6-3.1-1.4-4.5-2.5l4.1-4.1l3.5,3.5L50.8,58.3z M56.1,57.4l0.6,0.6l1.5,1.5c-1.3,0-2.6-0.1-3.8-0.3L56.1,57.4z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Shooting Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M27.1,20.9c5,0,9.2-3.6,10.2-8.3h4.4V8.4h-4.4C36.3,3.6,32.1,0,27.1,0c-5.7,0-10.4,4.7-10.4,10.4
                    C16.7,16.2,21.3,20.9,27.1,20.9z M27.1,4.2c2.7,0,5,1.7,5.9,4.2H21.2C22.1,5.9,24.4,4.2,27.1,4.2z M33,12.5
                    c-0.9,2.4-3.2,4.2-5.9,4.2c-2.7,0-5-1.7-5.9-4.2H33z M97.9,25v-2.1h-4.2V25H39.8c-1.6-0.2-3.3,0.2-4.7,1.4l-3,2.4
                    c-1.6-3.2-4.7-5.4-8.2-5.8c-2.9-0.3-5.9,0.7-8,2.7c-2.2,2-3.4,4.8-3.4,7.7v28L0.4,91.4c-1.1,3,0.1,6.3,2.8,7.8
                    c1.6,0.9,3.5,1,5.3,0.4c1.7-0.7,3.1-2,3.6-3.7l9.6-23.1c0.7,0.1,1.4,0.1,2.1,0.1l7.8,22.6c0.8,2.7,3.3,4.5,6,4.5
                    c0.6,0,1.2-0.1,1.8-0.3c1.6-0.5,2.9-1.5,3.7-3c0.8-1.5,1-3.2,0.5-4.8L33.3,61.6v-9.5h11.9l23-11.7l0.4-0.3c2-1.7,2.6-4.4,1.8-6.7
                    l2.4-0.3v-3.9H100V25H97.9z M39.5,93.1c0.2,0.5,0.1,1.1-0.2,1.6c-0.3,0.5-0.7,0.8-1.2,1c-1.1,0.3-2.3-0.3-2.6-1.5l-9-26.3
                    l-1.9,0.5c-1.4,0.4-2.2,0.3-3.5-0.1l-1.8-0.6l-11,26.6c-0.2,0.6-0.6,1-1.2,1.3c-0.6,0.2-1.2,0.2-1.8-0.1c-0.9-0.5-1.2-1.7-0.9-2.6
                    l12.4-30.7V33.3c0-1.8,0.7-3.4,2-4.6c1.3-1.2,3-1.8,4.8-1.6c2.3,0.2,4.3,1.9,5.2,4.1h-1.9c-3.4,0-6.3,2.8-6.3,6.2
                    c0,3.4,2.8,6.2,6.3,6.2h2.4v18.5L39.5,93.1z M32,39.6h-5.2c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1h3.7l7.3-5.8
                    c0.4-0.4,1-0.5,1.5-0.5c0.6,0.1,1.1,0.3,1.4,0.7c0.4,0.4,0.5,1,0.5,1.5c-0.1,0.6-0.3,1.1-0.7,1.4l-0.6,0.5l-0.5,0.1l0,0.3L32,39.6
                    z M41.5,37.3l6.5-0.9l-6.9,3.2h-2.5L41.5,37.3z M66.1,36.8L44.2,47.9H33.3v-4.2h8.7l19.8-9.2l4.2-0.6c0,0,0,0,0,0.1h0
                    C66.9,34.9,66.8,36.1,66.1,36.8z M68.7,29.4l-1.8,0.2c-2.1-0.7-4.3,0.1-6,0.7l-0.2,0.1l-15.6,2.1c0.3-1.1,0.2-2.3-0.2-3.4h23.8
                    V29.4z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Racing Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M98.2,97.4H91c0.3-0.6,0.5-1.3,0.5-2V91l2.3-17.3c0-0.1,0-0.1,0-0.2c0-2-0.8-3.9-2.2-5.4l-1-0.9h2.7c0.5,0,1-0.3,1.2-0.8
                    l2.6-5.7c0.2-0.4,0.2-0.9-0.1-1.2c-0.3-0.3-0.7-0.6-1.1-0.6H82.7l-6.5-6.7c-1.5-1.6-3.7-2.5-5.9-2.5H28.6c-2.3,0-4.4,0.9-5.9,2.5
                    l-6.5,6.7H3c-0.4,0-0.9,0.3-1.1,0.6c-0.3,0.3-0.3,0.9-0.1,1.2l2.6,5.7c0.2,0.4,0.7,0.8,1.2,0.8h2.5l-1,0.9C5.8,69.5,5,71.4,5,73.5
                    c0,0.1,0,0.1,0,0.2L7.3,91v4.4c0,0.8,0.2,1.5,0.5,2H0.6v2.6h97.4c0.7,0,1.3-0.6,1.3-1.3C99.4,98,99,97.4,98.2,97.4z M93.9,61.3
                    l-1.5,3.2h-4.4L85,61.3H93.9z M6.5,64.5L5,61.3h8.7l-3.1,3.2H6.5z M23.7,95.4c0,1.1-1,2-2.1,2H12c-1.1-0.1-2.1-0.9-2.1-2v-3.2
                    h13.8V95.4z M25.9,97.4c0.3-0.7,0.4-1.4,0.4-2v-3.2h46v3.2c0,0.8,0.2,1.5,0.5,2H25.9z M88.9,95.4c0,1.1-1,2-2.1,2h-9.6
                    c-1.1-0.1-2.1-0.9-2.1-2v-3.2h13.8V95.4z M9.8,89.5L7.7,73.3c0-1.3,0.5-2.5,1.4-3.5l15.4-16c1-1.1,2.5-1.7,4-1.7h41.7
                    c1.5,0,3,0.6,4,1.7l15.5,16c1,0.9,1.5,2.2,1.5,3.5l-2.1,16.2H9.8z M34.8,74.2c-0.2-0.4-0.6-0.8-1-0.9l-17-2.2
                    c-0.3-0.1-0.8,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.4,0.9V78c0,0.8,0.6,1.3,1.3,1.3h18.1c0.4,0,0.8-0.2,1-0.5c0.3-0.3,0.3-0.8,0.2-1.1
                    L34.8,74.2z M17.9,76.7v-2.8l14.7,1.9l0.3,0.9H17.9z M82.1,71.1l-16.9,2.2c-0.5,0.1-1,0.4-1.1,0.9l-1.1,3.4
                    c-0.1,0.4-0.1,0.9,0.2,1.1c0.3,0.3,0.6,0.5,1,0.5h18.1c0.7,0,1.3-0.6,1.3-1.3v-5.5c0-0.3-0.2-0.7-0.4-0.9
                    C82.9,71.2,82.4,71.1,82.1,71.1z M81,76.7H66l0.3-0.9L81,73.9V76.7z M20.8,65.7H78c0.5,0,1-0.3,1.2-0.8c0.3-0.4,0.1-1-0.3-1.4
                    l-3.4-3.4c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8l1.2,1.3H23.9l1.2-1.3c0.5-0.5,0.5-1.3,0-1.8c-0.5-0.5-1.3-0.5-1.8,0
                    l-3.4,3.4c-0.3,0.3-0.4,0.9-0.3,1.4C19.8,65.4,20.3,65.7,20.8,65.7z M25.4,42.9c0.2,0.3,0.4,0.5,0.8,0.6h0.3
                    c0.3,0,0.4-0.1,0.7-0.2l11.6-6.7c0.9-0.5,1.6-1.4,1.8-2.3c0.3-1,0.1-2-0.4-2.9l-1.9-3.2l6.8-3.9l2.8,4.7l-7.3,12.5
                    c-0.3,0.6-0.2,1.4,0.4,1.7c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0,0.9-0.3,1.1-0.7l6.6-11.2l6.6,11.2c0.3,0.4,0.7,0.7,1.1,0.7
                    c0.3,0,0.4-0.1,0.6-0.2c0.6-0.3,0.9-1.1,0.4-1.7L50.7,29l2.8-4.7l6.8,3.9l-1.9,3.2c-1.1,1.8-0.4,4.2,1.4,5.3l11.6,6.7
                    c0.2,0.1,0.4,0.2,0.7,0.2h0.3c0.3-0.1,0.6-0.3,0.8-0.6l11.3-18.6c0.2-0.3,0.3-0.7,0.2-0.9c-0.1-0.3-0.3-0.6-0.6-0.8L77.6,19
                    l1.9-3.2c1.1-1.8,0.4-4.2-1.4-5.3L66.1,3.5l1-1.6c0.3-0.6,0.2-1.4-0.4-1.7C66-0.1,65.2,0,64.9,0.6L49.4,26.5L34.2,0.6
                    C33.8,0,33-0.2,32.4,0.2c-0.6,0.3-0.9,1.1-0.4,1.7l1,1.6l-12.1,6.9c-0.9,0.5-1.6,1.4-1.8,2.3c-0.3,1-0.1,2,0.4,2.9l1.9,3.2
                    l-6.6,3.8c-0.6,0.3-0.9,1.1-0.4,1.8L25.4,42.9z M81.5,24.3l-9.7,16.3L62.1,35l5.5-1c0.6-0.1,1-0.4,1.4-0.9
                    c0.1-0.1,0.1-0.2,0.2-0.3l5.9-9.8l1.1-1.9L81.5,24.3z M60.9,32.6l1.8-3.1l3.7,2L60.9,32.6z M64.8,5.8l12,6.8
                    c0.6,0.3,0.9,1.1,0.4,1.7l-9,15.2l-13.1-7.4L64.8,5.8z M21.4,13.4c0.1-0.3,0.3-0.6,0.6-0.8l12-6.8l9.7,16.3l-13.1,7.4l-9-15.2
                    C21.4,14,21.3,13.7,21.4,13.4z M37.9,32.6l-5.5-1l3.7-2L37.9,32.6z M22.6,21.1l7,11.8c0.1,0.1,0.1,0.2,0.2,0.3
                    c0.3,0.4,0.9,0.8,1.5,0.9l5.5,1L27,40.6l-9.8-16.4L22.6,21.1z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Adventure Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M21.7,18.1c3.6,0,6.5-3,6.5-6.6V1.6c0-0.9-0.7-1.6-1.6-1.6H20c-2.7,0-4.9,2.2-4.9,4.9v6.6C15.1,15.2,18,18.1,21.7,18.1z
                    M18.4,4.9c0-0.9,0.7-1.6,1.6-1.6h4.9v3.3H20v3.3h4.9v1.6c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3V4.9z M75.6,60.3l-7-7.1
                    c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-5.4,5.6V11.5h1.6V8.2h-1.6V0h-3.3v8.2H56c-0.4,0-0.7,0.1-1,0.3L40.7,19.8H18.4
                    c-0.6,0-1.2,0.1-1.8,0.4c-0.7-3.1-3.5-5.3-6.6-5.3H8.9c-3.7,0-6.8,3-6.8,6.8c0,1.7,0.7,3.4,1.8,4.7c-1.2,0.9-1.8,2.3-1.9,3.8v12.6
                    c0,4.5,3.7,8.2,8.2,8.2h3.3v19.5L5.4,96.7c-0.3,0.9,0.2,1.8,1.1,2.1c0.2,0,0.3,0.1,0.5,0.1h9.8v-3.3h-2.5l8.9-22.5
                    c0-0.1,0.1-0.2,0.1-0.4l1.9-13.6l9.4,5.9l-3.1,20.3c-0.1,0.9,0.5,1.7,1.4,1.9c0.1,0,0.2,0,0.2,0h0.6l-9.9,10.4l2.4,2.3l12.1-12.7
                    h4.7V84h-1.5l16.2-17v5.5H56v3.3h1.6v1.6h3.3v-1.6h1.6v-3.3h-1.6v-8.9l6.6-6.9l7,7.1c0.6,0.6,1.7,0.6,2.3,0l6.5-6.6l-2.3-2.3
                    L75.6,60.3z M34.7,23.1h6.5c0.4,0,0.7-0.1,1-0.3L53.3,14l-9.6,10.8l-9,1.3V23.1z M8.7,18.1c0.1,0,0.1,0,0.2,0H10
                    c2,0,3.6,1.5,3.6,3.5c0,2-1.5,3.6-3.5,3.6c-0.1,0-0.1,0-0.2,0H8.9c-2,0-3.6-1.5-3.6-3.5C5.2,19.8,6.8,18.2,8.7,18.1z M10.2,47.8
                    c-2.7,0-4.9-2.2-4.9-4.9V30.2c0-0.9,0.7-1.6,1.6-1.6h4.9c0.9,0,1.6,0.7,1.6,1.6v17.6H10.2z M20.1,72.1l-9.3,23.5H9.2l7.5-24.2
                    c0-0.2,0.1-0.3,0.1-0.5V51.1H23L20.1,72.1z M25.1,47.8h-8.3V24.7c0-0.9,0.7-1.6,1.6-1.6h13.1v3.5L19.8,28l0.4,3.3l6.3-0.8
                    L25.1,47.8z M36.7,84H35l3-19.5c0.1-0.7-0.2-1.3-0.7-1.6l-11.5-7.3l0.6-4.4l16.3,10.5L36.7,84z M57.6,62.3l-16.2,17l4.7-17.9
                    c0.2-0.7-0.1-1.4-0.7-1.8l-17.1-11l1.5-18.5l3.5-0.4l0,0L44.8,28c0.4-0.1,0.7-0.2,1-0.5l11.9-13.3V62.3z M90.7,45l2.3,2.3l4.9-4.9
                    L95.6,40L90.7,45z M84.2,51.6l2.3,2.3l3.3-3.3l-2.3-2.3L84.2,51.6z M75.6,23.7l8.6,8.7c0.6,0.6,1.7,0.6,2.3,0l4.6-4.6l3.7,3.8
                    l2.3-2.3l-4.9-4.9c-0.6-0.6-1.7-0.6-2.3,0L85.4,29l-7.5-7.6l3.4-3.4l2.9,3c0.6,0.6,1.7,0.6,2.3,0L97.9,9.4l-2.3-2.3L85.4,17.4
                    l-2.9-3c-0.6-0.6-1.7-0.6-2.3,0L63,31.8l2.3,2.3L75.6,23.7z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Multiplayer Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path d="M0,0v1.5V2v39.4v17.3v2v37.9v1.5h1.5h39.8h17.3h39.8h1.5v-1.5V58.6V41.4V1.5V0h-1.5H58.6H41.4H1.5H0z M41.4,3h17.3V11h-8.7
                c-1.1,0-2,0.9-2,2v32.3l-0.5-0.5c-0.4-0.4-0.9-0.6-1.4-0.6c-1.1,0-2,0.9-2,2c0,0.5,0.2,1,0.6,1.4l0.4,0.4H14.9v-6.6h26.5V3z
                M3.9,3.9h33.6v33.6H3.9V3.9z M62.5,3.9h33.6v33.6H62.5V3.9z M6.3,6.3v2v26.8h28.7V6.3H6.3z M64.9,6.3v2v26.8h28.7V6.3H64.9z
                M10.2,10.2h20.9v20.9H10.2V10.2z M68.9,10.2h20.9v20.9H68.9V10.2z M14.6,14.7v4.9h5v-4.9H14.6z M21.8,14.7v4.9h5v-4.9H21.8z
                M73.2,14.7v4.9h5v-4.9H73.2z M80.4,14.7v4.9h5v-4.9H80.4z M51.9,14.9h6.8v26.5H97v17.3H89v-8.7c0-1.1-0.9-2-2-2H54.8l0.5-0.4
                c0.8-0.8,0.8-2,0-2.8c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.2-1.3,0.6l-0.6,0.6V14.9z M14.6,21.7v4.9h5v-4.9H14.6z M21.8,21.7v4.9h5
                v-4.9H21.8z M73.2,21.7v4.9h5v-4.9H73.2z M80.4,21.7v4.9h5v-4.9H80.4z M3,41.4H11v8.5c0,1.1,0.9,2,2,2h32.5l-0.7,0.7
                c-0.8,0.7-0.8,2-0.1,2.8c0.7,0.8,2,0.8,2.8,0.1c0,0,0,0,0.1-0.1l0.5-0.5v30.3h-6.6V58.6H3V41.4z M54.6,51.8h30.5v6.8H58.6v2V97H41.4
                V89h8.6c1.1,0,2-0.9,2-2V54.6l0.6,0.6c0.7,0.8,2,0.8,2.8,0.1c0.8-0.7,0.8-2,0.1-2.8c0,0,0,0-0.1-0.1L54.6,51.8z M3.9,62.5h33.6v33.6
                H3.9V62.5z M62.5,62.5h33.6v33.6H62.5V62.5z M6.3,64.9v2v26.8h28.7V64.9H6.3z M64.9,64.9v2v26.8h28.7V64.9H64.9z M10.2,68.9h20.9
                v20.9H10.2V68.9z M68.9,68.9h20.9v20.9H68.9V68.9z M14.6,73.4v4.9h5v-4.9H14.6z M21.8,73.4v4.9h5v-4.9H21.8z M73.2,73.4v4.9h5v-4.9
                H73.2z M80.4,73.4v4.9h5v-4.9H80.4z M14.6,80.4v4.9h5v-4.9H14.6z M21.8,80.4v4.9h5v-4.9H21.8z M73.2,80.4v4.9h5v-4.9H73.2z
                M80.4,80.4v4.9h5v-4.9H80.4z"></path>
              </svg>
          `
				},
				{
					title: 'Puzzle Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path d="M57,18v9.8c0,1.3,0.7,2.4,1.8,3c1.1,0.6,2.5,0.6,3.6,0c0.6-0.3,1.3-0.5,1.9-0.5c2.1,0,3.9,1.7,3.9,3.8
                c0,2.1-1.7,3.8-3.9,3.8c-0.7,0-1.4-0.2-1.9-0.5c-1.1-0.6-2.5-0.6-3.6,0c-1.1,0.6-1.8,1.8-1.8,3v16.9H46.2c-0.1,0-0.1,0-0.2-0.1
                c-0.1-0.1-0.1-0.1,0-0.2c0.6-1.1,0.9-2.4,0.9-3.7c0-4.4-3.6-8-8.1-8c-2.7,0-5.1,1.3-6.6,3.4c0.1-0.3,0.2-0.6,0.2-0.9
                c0.2-1.2-0.3-2.4-1.3-3.2c-0.6-0.5-1.1-1.1-1.4-1.8c-0.5-1.2-0.5-2.4-0.1-3.6c0.5-1.2,1.4-2.1,2.5-2.6c0.6-0.3,1.3-0.4,1.9-0.4
                c1.9,0,3.6,1.1,4.4,2.8c0.3,0.7,0.4,1.4,0.4,2.2c-0.1,1.3,0.5,2.4,1.5,3.1c1,0.7,2.3,0.8,3.5,0.3l13-5.6l-8.2-18.4
                c0-0.1,0-0.1,0-0.2c0.1-0.1,0.1-0.1,0.2-0.1c1.2,0.1,2.5-0.1,3.7-0.6c3.6-1.6,5.3-5.8,3.7-9.4c-1.1-2.6-3.7-4.3-6.6-4.3
                c-1,0-2,0.2-2.9,0.6C45.6,7.2,44.6,8,43.9,9c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1l-4-9L0,17.1l17.3,39l13-5.6
                c0.5-0.2,0.9-0.6,1.3-1c-0.6,1.1-0.9,2.4-0.9,3.7c0,1.3,0.3,2.6,0.9,3.7c0,0.1,0,0.1,0,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H17.2V100h39.7
                v0H100V60.6v-3.3V18H57z M29,47.6l-9.9,4.3l-14.7-33L37.8,4.3l2.7,6c0.5,1.2,1.6,1.9,2.9,2.1c1.3,0.1,2.5-0.4,3.3-1.4
                c0.4-0.5,0.9-1,1.6-1.2c0.5-0.2,1-0.3,1.6-0.3c1.5,0,2.9,0.9,3.5,2.3c0.9,1.9,0,4.2-2,5c-0.6,0.3-1.3,0.4-2,0.3
                c-1.3-0.1-2.5,0.4-3.3,1.4c-0.8,1-0.9,2.3-0.4,3.5l6.9,15.4l-10,4.3c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.1-0.1-0.2
                c0.1-1.3-0.1-2.6-0.7-3.7c-1.3-2.9-4.2-4.8-7.4-4.8c-1.1,0-2.2,0.2-3.3,0.7c-2,0.9-3.5,2.4-4.3,4.4c-0.8,2-0.7,4.2,0.1,6.1
                c0.5,1.2,1.3,2.2,2.3,3c0.1,0.1,0.1,0.1,0.1,0.2C29.1,47.5,29.1,47.5,29,47.6z M60.3,40.5c0-0.1,0-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0
                c1.1,0.6,2.3,1,3.6,1c4,0,7.2-3.2,7.2-7.1c0-3.9-3.2-7.1-7.2-7.1c-1.3,0-2.5,0.3-3.6,1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2
                v-6.5h36.3v36.1H85.2c-0.1,0-0.1,0-0.2-0.1C85,57.2,85,57.1,85,57c0.1-0.1,0.2-0.3,0.3-0.4c0.9-1.3,1.4-2.9,1.4-4.5
                c0-4.4-3.6-7.9-8-7.9c-4.4,0-8,3.5-8,7.9c0,1.6,0.5,3.1,1.4,4.5c0.1,0.1,0.2,0.3,0.3,0.4c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.1
                H60.3V40.5z M20.5,96.7V60.6h10.8c1.3,0,2.4-0.6,3-1.7c0.6-1.1,0.7-2.3,0.1-3.4c-0.4-0.7-0.5-1.4-0.5-2.2c0-2.6,2.2-4.7,4.8-4.7
                c2.6,0,4.8,2.1,4.8,4.7c0,0.8-0.2,1.5-0.5,2.2c-0.6,1.1-0.5,2.4,0.1,3.4c0.7,1.1,1.8,1.7,3,1.7h10.7v13.4c0,0.1-0.1,0.2-0.2,0.2
                h-0.1c-0.7,0-1.2-0.2-1.7-0.5c-1.3-0.9-2.9-1.4-4.5-1.4c-4.4,0-8,3.5-8,7.9c0,4.4,3.6,7.9,8,7.9c1.6,0,3.2-0.5,4.5-1.4
                c0.5-0.4,1.1-0.5,1.7-0.5h0.1c0.1,0,0.2,0.1,0.2,0.2v10.2H20.5L20.5,96.7z M96.7,96.7H60.2V86.5c0-1.9-1.6-3.5-3.6-3.5h-0.1
                c-1.3,0-2.5,0.4-3.6,1.1c-0.8,0.5-1.7,0.8-2.6,0.8c-2.6,0-4.7-2.1-4.7-4.6c0-2.6,2.1-4.6,4.7-4.6c0.9,0,1.9,0.3,2.6,0.8
                c1.1,0.7,2.3,1.1,3.6,1.1h0.1c2,0,3.6-1.6,3.6-3.5V60.6h11.9c1.3,0,2.4-0.6,3-1.7c0.7-1.1,0.7-2.3,0.2-3.4c-0.1-0.3-0.3-0.5-0.5-0.8
                C74.2,54,74,53.1,74,52.2c0-2.6,2.1-4.6,4.7-4.6c2.6,0,4.7,2.1,4.7,4.6c0,0.9-0.3,1.8-0.8,2.6c-0.2,0.3-0.4,0.6-0.5,0.8
                c-0.6,1.1-0.5,2.4,0.2,3.4c0.7,1.1,1.8,1.7,3,1.7h11.5L96.7,96.7L96.7,96.7z"></path>
              </svg>
          `
				},
				{
					title: 'Fighting Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M100,50v-3.2H87.5c-0.9,0-1.6,0.7-1.6,1.6V50H85l-4.2-4.3c-0.3-0.3-0.7-0.5-1.1-0.5H56.2c-1.8,0-3.4,0.6-4.7,1.6v0
                    c0-4.4-3.5-7.9-7.8-7.9H20.3c-0.4,0-0.8,0.2-1.1,0.5L15,43.7h-0.9v-1.6c0-0.9-0.7-1.6-1.6-1.6H0v3.2h10.9v3.2H0V50h10.9v3.2H0v3.2
                    h10.9v3.2H0v3.2h10.9v3.2H0V69h10.9v3.2H0v3.2h12.5c0.9,0,1.6,0,1.6-1.6H15l2.7,2.7c0.3,0.3,0.7,0.5,1.1,0.5h12.5
                    c0.4,0,0.8-0.2,1.1-0.5l4.2-4.3h7.2c1.8,0,3.4-0.6,4.7-1.6v0c0,4.4,3.5,7.9,7.8,7.9h7.2l4.2,4.3c0.3,0.3,0.7,0.5,1.1,0.5h12.5
                    c0.4,0,0.8-0.2,1.1-0.5l2.7-2.7h0.9v1.6c0,0.9,0.7,1.6,1.6,1.6H100v-3.2H89.1V50H100z M48.4,64.3c0,2.6-2.1,4.8-4.7,4.8H32.8
                    c-2.6,0-4.7-2.1-4.7-4.8v-6.3h6.2v-3.2h-7.8c-0.9,0-1.6,0.7-1.6,1.6v7.9c0,4.2,3.2,7.6,7.2,7.9l-1.6,1.6H19.4l-2.7-2.7
                    c-0.3-0.3-0.7-0.5-1.1-0.5h-1.6V46.8h1.6c0.4,0,0.8-0.2,1.1-0.5l4.2-4.3h22.8c2.6,0,4.7,2.1,4.7,4.8V64.3z M85.9,77h-1.6
                    c-0.4,0-0.8,0.2-1.1,0.5l-2.7,2.7H69.4l-1.6-1.6c4-0.3,7.2-3.7,7.2-7.9v-7.9c0-0.9-0.7-1.6-1.6-1.6h-7.8v3.2h6.3v6.3
                    c0,2.6-2.1,4.8-4.7,4.8H56.2c-2.6,0-4.7-2.1-4.7-4.8V53.2c0-2.6,2.1-4.8,4.7-4.8H79l4.2,4.3c0.3,0.3,0.7,0.5,1.1,0.5h1.6V77z
                    M37.3,36.3l2.6-1.7l-3.6-5.7l6.4,1.7c0.5,0.1,0.9,0,1.3-0.3c0.4-0.3,0.6-0.7,0.6-1.2l0.4-6.8l4.2,5.3c0.3,0.4,0.7,0.6,1.2,0.6
                    s0.9-0.2,1.2-0.6l4.2-5.3l0.4,6.8c0,0.5,0.3,0.9,0.6,1.2c0.4,0.3,0.9,0.4,1.3,0.3l6.4-1.7l-3.6,5.7c-0.3,0.4-0.3,0.9-0.2,1.4
                    c0.1,0.5,0.5,0.8,0.9,1l6.9,2.7l-1.8,1l1.5,2.8l4.7-2.7c0.5-0.3,0.8-0.9,0.8-1.5c0-0.6-0.4-1.1-1-1.4l-8.2-3.2l4.8-7.5
                    c0.3-0.6,0.3-1.3,0-1.8c-0.4-0.5-1-0.8-1.7-0.6l-8.5,2.3l-0.5-8.9c0-0.7-0.5-1.2-1.1-1.4c-0.6-0.2-1.3,0-1.7,0.5l-5.5,6.9L45,17.3
                    c-0.4-0.5-1.1-0.7-1.7-0.5c-0.6,0.2-1,0.8-1.1,1.4l-0.5,8.9l-8.5-2.3c-0.6-0.2-1.3,0.1-1.7,0.6c-0.4,0.5-0.4,1.2,0,1.8L37.3,36.3z
                    "></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Action Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M91.6,37.6L100,2.1c0.2-0.9-0.4-1.8-1.2-2C98.5,0,98.2,0,98,0.1l-35,8.4c-0.4,0.1-0.7,0.3-0.9,0.6L50,24.6L38,9.1
                    c-0.2-0.3-0.6-0.5-0.9-0.6L2,0.1C1.1-0.1,0.3,0.4,0,1.4C0,1.6,0,1.9,0,2.1l8.3,35.5c0.1,0.4,0.3,0.7,0.6,0.9l18.7,14.9l-1.2,1.5
                    l-5.3-5.4c-0.6-0.7-1.7-0.7-2.4,0c-0.3,0.3-0.4,0.6-0.5,1l-1.7,11.8c-0.1,0.5,0.1,1.1,0.5,1.4l3.8,3.9L2.8,86c-2,2-2,5.2,0,7.2
                    c0,0,0,0,0,0l5.3,5.4c2,2,5.1,2,7.1,0l18.1-18.4l3.8,3.9c0.3,0.3,0.7,0.5,1.2,0.5c0.1,0,0.2,0,0.2,0L50,82.8l11.4,1.7
                    c0.1,0,0.2,0,0.2,0c0.4,0,0.9-0.2,1.2-0.5l3.8-3.9l18.1,18.4c2,2,5.1,2,7.1,0l5.3-5.4c2-2,2-5.2,0-7.2L79,67.6l3.8-3.9
                    c0.4-0.4,0.5-0.9,0.5-1.4l-1.7-11.8c-0.1-0.9-1-1.6-1.9-1.4c-0.4,0.1-0.7,0.2-1,0.5l-5.3,5.4l-1.2-1.5L91,38.5
                    C91.3,38.3,91.5,38,91.6,37.6z M35.7,11.7l35.4,45.6L65,63.5L32.4,30.4h11v-3.4H29l-3.3-3.4H40v-3.4H22.3L7,4.8L35.7,11.7z
                    M11.5,36.2L4.6,7.1l58,58.7l-6.1,6.2L11.5,36.2z M40,63.2l7.3,5.8l-3.8,3.1l-6.1-6.2L40,63.2z M35,63.5l-6.1-6.2l1.4-1.8l7.1,5.6
                    L35,63.5z M12.8,96.1c-0.7,0.7-1.7,0.7-2.4,0l-5.3-5.4c-0.7-0.7-0.7-1.7,0-2.4l11.5-11.6l7.6,7.7L12.8,96.1z M26.6,82.1L19,74.3
                    l4.3-4.4l7.6,7.7L26.6,82.1z M38.9,81l-4.4-4.5l-10-10.1l-4.4-4.5l1.1-7.6l25.3,25.6L38.9,81z M50,78.7l-4.2-4.2l4.2-3.3l4.2,3.3
                    L50,78.7z M94.8,88.3c0.7,0.7,0.7,1.7,0,2.4l-5.3,5.4c-0.7,0.6-1.7,0.6-2.4,0L75.7,84.5l7.6-7.7L94.8,88.3z M81,74.3l-7.7,7.7
                    L69,77.7l7.6-7.7L81,74.3z M78.8,54.3l1.1,7.6l-4.4,4.5l-10,10.1L61.1,81l-7.5-1.1L78.8,54.3z M70.3,50.7L52.1,27.4l12.2-15.7
                    L96.1,4l-7.6,32.2L70.3,50.7z M60,23.7h18.3v-3.4H60V23.7z M56.7,30.4h15v-3.4h-15V30.4z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Board Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M98.4,90.3h-2.1L93.5,82v-6.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-2.7-10.9h1.2c0.9,0,1.6-0.7,1.6-1.6v-6.5
                    c0-0.4-0.1-0.7-0.3-0.9c0.2-0.7,0.3-1.5,0.3-2.3c0-3.9-2.8-7.2-6.5-7.9v-6.6h-3.2v6.6c-3.7,0.7-6.5,4-6.5,7.9
                    c0,0.8,0.1,1.5,0.3,2.3c-0.2,0.3-0.3,0.6-0.3,0.9v6.5c0,0.9,0.7,1.6,1.6,1.6h1.2l-2.7,10.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                    v4.8H64.5V58.1h9.7v-3.2h-9.7V29h22.6v6.5h3.2V1.6c0-0.9-0.7-1.6-1.6-1.6H11.3c-0.9,0-1.6,0.7-1.6,1.6v33.9h3.2V29h22.6v25.8h-9.7
                    v3.2h9.7v22.6H22.6v-4.8c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-2.7-10.9H21c0.9,0,1.6-0.7,1.6-1.6v-6.5c0-0.4-0.1-0.7-0.3-0.9
                    c0.2-0.7,0.3-1.5,0.3-2.3c0-3.9-2.8-7.2-6.5-7.9v-6.6h-3.2v6.6c-3.7,0.7-6.5,4-6.5,7.9c0,0.8,0.1,1.5,0.3,2.3
                    c-0.2,0.3-0.3,0.6-0.3,0.9v6.5c0,0.9,0.7,1.6,1.6,1.6h1.2L6.5,75.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2V82l-2.8,8.3H1.6
                    C0.7,90.3,0,91,0,91.9v6.5c0,0.9,0.7,1.6,1.6,1.6h25.8c0.9,0,1.6-0.7,1.6-1.6v-6.5c0-0.9-0.7-1.6-1.6-1.6h-2.1l-2.2-6.5h18.1
                    l-2.1,6.5h-2.1c-0.9,0-1.6,0.7-1.6,1.6v6.5c0,0.9,0.7,1.6,1.6,1.6h25.8c0.9,0,1.6-0.7,1.6-1.6v-6.5c0-0.9-0.7-1.6-1.6-1.6h-2.1
                    l-2.1-6.5h18.1l-2.1,6.5h-2.1c-0.9,0-1.6,0.7-1.6,1.6v6.5c0,0.9,0.7,1.6,1.6,1.6h25.8c0.9,0,1.6-0.7,1.6-1.6v-6.5
                    C100,91,99.3,90.3,98.4,90.3z M64.5,3.2h22.6v22.6H64.5V3.2z M14.5,48.4c2.7,0,4.8,2.2,4.8,4.8c0,0.6-0.1,1.1-0.3,1.6H10
                    c-0.2-0.5-0.3-1.1-0.3-1.6C9.7,50.6,11.8,48.4,14.5,48.4z M9.7,58.1h9.7v3.2H9.7V58.1z M18.9,74.2h-8.8l2.4-9.7h3.9L18.9,74.2z
                    M9.7,77.4h9.7v3.2H9.7V77.4z M9.2,83.9h10.6l2.1,6.5H7.1L9.2,83.9z M25.8,93.5v3.2H3.2v-3.2H25.8z M35.5,25.8H12.9V3.2h22.6V25.8
                    z M38.7,80.6V58.1h3.2v4.8c0,0.9,0.7,1.6,1.6,1.6h1.2L42,75.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v4.8H38.7z M54.8,58.1v3.2h-9.7
                    v-3.2H54.8z M45.2,53.2c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8c0,0.6-0.1,1.1-0.3,1.6h-9.1C45.3,54.3,45.2,53.8,45.2,53.2z
                    M54.4,74.2h-8.8l2.4-9.7H52L54.4,74.2z M54.8,77.4v3.2h-9.7v-3.2H54.8z M55.3,83.9l2.1,6.5H42.6l2.1-6.5H55.3z M61.3,93.5v3.2
                    H38.7v-3.2H61.3z M61.3,80.6h-3.2v-4.8c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-2.7-10.9h1.2c0.9,0,1.6-0.7,1.6-1.6v-4.8h3.2V80.6z
                    M61.3,54.8h-3.4c0.1-0.5,0.2-1.1,0.2-1.6c0-3.9-2.8-7.2-6.5-7.9v-6.6h-3.2v6.6c-3.7,0.7-6.5,4-6.5,7.9c0,0.5,0.1,1.1,0.2,1.6
                    h-3.4V29h22.6V54.8z M61.3,25.8H38.7V3.2h22.6V25.8z M85.5,48.4c2.7,0,4.8,2.2,4.8,4.8c0,0.6-0.1,1.1-0.3,1.6H81
                    c-0.2-0.5-0.3-1.1-0.3-1.6C80.6,50.6,82.8,48.4,85.5,48.4z M80.6,58.1h9.7v3.2h-9.7V58.1z M89.9,74.2h-8.8l2.4-9.7h3.9L89.9,74.2z
                    M80.6,77.4h9.7v3.2h-9.7V77.4z M80.2,83.9h10.6l2.1,6.5H78L80.2,83.9z M96.8,96.8H74.2v-3.2h22.6V96.8z M23.7,7.6l-2.3-2.3
                    L15,11.8l2.3,2.3L23.7,7.6z M24.7,21.4l2.3,2.3l6.5-6.5L31.1,15L24.7,21.4z M33.4,7.6l-2.3-2.3L15,21.4l2.3,2.3L33.4,7.6z
                    M75.3,7.6l-2.3-2.3l-6.5,6.5l2.3,2.3L75.3,7.6z M76.3,21.4l2.3,2.3l6.5-6.5L82.7,15L76.3,21.4z M82.7,5.3L66.6,21.4l2.3,2.3
                    L85,7.6L82.7,5.3z"></path>
                  </g>
                </g>
              </svg>
          `
				},
				{
					title: 'Simulator Game',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M71.1,73.6v-9.8c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7v6.9L62,66.1l-4.9-9.6c-1.4-2.6-4.1-4.3-7.1-4.3
                    c-3,0-5.7,1.6-7.1,4.3L38,66.1l-5.6,4.6v-6.9c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7v9.8l-8.2,6.6c-0.4,0.3-0.6,0.8-0.6,1.3v6.4
                    c0,1,0.8,1.7,1.8,1.7h56.3c1,0,1.8-0.8,1.8-1.7v-6.4c0-0.5-0.2-1-0.6-1.3L71.1,73.6z M37.7,86.2H23.6v-3.8l5.3-4.3v3
                    c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7v-5.8l5.3-4.3V86.2z M58.8,86.2H41.2V67.6l4.9-9.5c0.8-1.5,2.2-2.4,3.9-2.4
                    c1.7,0,3.2,0.9,3.9,2.4l4.9,9.5V86.2z M76.4,86.2H62.3V70.9l5.3,4.3V81c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7v-3l5.3,4.3V86.2z
                    M44.7,93.1c-1,0-1.8,0.8-1.8,1.7v3.4c0,1,0.8,1.7,1.8,1.7c1,0,1.8-0.8,1.8-1.7v-3.4C46.5,93.9,45.7,93.1,44.7,93.1z M55.3,93.1
                    c-1,0-1.8,0.8-1.8,1.7v3.4c0,1,0.8,1.7,1.8,1.7c1,0,1.8-0.8,1.8-1.7v-3.4C57,93.9,56.3,93.1,55.3,93.1z M32.4,31
                    c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7v3.4c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7V31z M32.4,41.4c0-1-0.8-1.7-1.8-1.7
                    s-1.8,0.8-1.8,1.7v3.4c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7V41.4z M30.6,50c-1,0-1.8,0.8-1.8,1.7v3.4c0,1,0.8,1.7,1.8,1.7
                    s1.8-0.8,1.8-1.7v-3.4C32.4,50.8,31.6,50,30.6,50z M69.4,46.6c1,0,1.8-0.8,1.8-1.7v-3.4c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7v3.4
                    C67.6,45.8,68.4,46.6,69.4,46.6z M67.6,55.2c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7v-3.4c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7V55.2
                    z M30.6,20.7c10.3,0,13-10.4,13-15.8c0-0.6-0.3-1.1-0.7-1.4c-0.5-0.3-1.1-0.4-1.6-0.2L38.8,4V1.7c0-0.6-0.3-1.2-0.8-1.5
                    c-0.5-0.3-1.2-0.3-1.7-0.1l-5.6,2.7L25,0.2c-0.5-0.3-1.2-0.2-1.7,0.1c-0.5,0.3-0.8,0.9-0.8,1.5V4L20,3.2c-0.5-0.2-1.1-0.1-1.6,0.2
                    c-0.5,0.3-0.7,0.8-0.7,1.4C17.7,10.3,20.4,20.7,30.6,20.7z M23.7,8.1c0.5,0.2,1.1,0.1,1.6-0.2C25.7,7.5,26,7,26,6.4V4.5l3.9,1.9
                    c0.5,0.2,1.1,0.2,1.6,0l3.9-1.9v1.9c0,0.6,0.3,1.1,0.7,1.4c0.5,0.3,1.1,0.4,1.6,0.2l2.3-0.7c-0.5,3.6-2.5,9.9-9.2,9.9
                    s-8.7-6.3-9.2-9.9L23.7,8.1z M81.6,13.8c-0.5-0.3-1.1-0.4-1.6-0.2l-2.5,0.8v-2.3c0-0.6-0.3-1.2-0.8-1.5c-0.5-0.3-1.2-0.3-1.7-0.1
                    l-5.6,2.7l-5.6-2.7c-0.5-0.3-1.2-0.2-1.7,0.1c-0.5,0.3-0.8,0.9-0.8,1.5v2.3l-2.5-0.8c-0.5-0.2-1.1-0.1-1.6,0.2
                    c-0.5,0.3-0.7,0.8-0.7,1.4c0,5.5,2.7,15.8,13,15.8c10.3,0,13-10.4,13-15.8C82.3,14.7,82.1,14.1,81.6,13.8z M69.4,27.6
                    c-6.8,0-8.7-6.3-9.2-9.9l2.3,0.7c0.5,0.2,1.1,0.1,1.6-0.2c0.5-0.3,0.7-0.8,0.7-1.4v-1.9l3.9,1.9c0.5,0.2,1.1,0.2,1.6,0l3.9-1.9
                    v1.9c0,0.6,0.3,1.1,0.7,1.4c0.5,0.3,1,0.4,1.6,0.2l2.3-0.7C78.1,21.3,76.1,27.6,69.4,27.6z M9,7.4c-0.7-0.7-1.8-0.7-2.5,0
                    c-0.7,0.7-0.7,1.8,0,2.4l7,6.9c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.4L9,7.4z M93.5,17.7
                    c-0.7-0.7-1.8-0.7-2.5,0l-7,6.9c-0.7,0.7-0.7,1.8,0,2.4c0.3,0.3,0.8,0.5,1.2,0.5c0.5,0,0.9-0.2,1.2-0.5l7-6.9
                    C94.2,19.5,94.2,18.4,93.5,17.7z M69.4,8.6c1,0,1.8-0.8,1.8-1.7V1.7c0-1-0.8-1.7-1.8-1.7s-1.8,0.8-1.8,1.7v5.2
                    C67.6,7.9,68.4,8.6,69.4,8.6z M50,63.8c-1,0-1.8,0.8-1.8,1.7v8.6c0,1,0.8,1.7,1.8,1.7s1.8-0.8,1.8-1.7v-8.6
                    C51.8,64.6,51,63.8,50,63.8z"></path>
                  </g>
                </g>
              </svg>
          `
				}
			]
		};
	},
	components: {
		IconCard
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>
