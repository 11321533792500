<template>
	<div class="col-md-4 col-sm-6 col-12 ideal-business-box text-center">
		<div class="ideal-business-box-icon box-shadow" v-html="obj.svg"></div>
		<h5>{{ obj.title }}</h5>
		<p class="mb-0">{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.ideal-business-box {
	padding: 25px 15px;
	border-right: 1px solid #ecedee;
	border-bottom: 1px solid #ecedee;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.ideal-business-box-icon {
	width: 103px;
	height: 103px;
	margin: 0 auto 15px;
	border-radius: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.ideal-business-box-icon svg {
	width: 52px;
	height: 52px;
}
.ideal-business-box h5 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
}
.ideal-business-box:hover {
    -webkit-box-shadow: 0 3px 10px 0 rgb(245 133 31 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(245,133,31,.15);
    box-shadow: 0 3px 10px 0 rgb(245 133 31 / 15%);
    background-color: #fff;
}
.ideal-business-box:hover .ideal-business-box-icon {
    background-color: #008dd2;
}
.ideal-business-box:hover .ideal-business-box-icon svg *{
  fill:#fff
}
</style>
