<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>Tools</span>
				<h2 class="fxt-tech-use">Leveraging Best Tools to Offer UX/UI Design Solutions</h2>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/adobe-photoshop.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/adobe-illustrator.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/adobe-after-effects.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/invision.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/sketch.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/figma.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/adobe-after-effects.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30">
					<div class="uiux-tools-box rounded10">
						<img
							alt=""
							class="lazy"
							src="../../../assets/images/adobe-xd.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>

.uiux-tools-box {
    text-align: center;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ecedee;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding: 5px 10px;
}
.rounded10 {
    border-radius: 10px!important;
}
.uiux-tools-box:hover {
    border: 1px solid #008dd2;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 10px 0 rgb(236 115 35 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(236,115,35,.15);
    box-shadow: 0 3px 10px 0 rgb(236 115 35 / 15%);
}
</style>
