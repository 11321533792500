<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <Features></Features>

      <TypeDiv></TypeDiv>

      <WhyInvest></WhyInvest>

      <Expertise></Expertise>

      <Const></Const>

  </div>
</template>

<script>
import Banner from '../../components/OnDemandSolutions/FoodDeliveryAppDevelopment/Banner.vue'
import Features from '../../components/OnDemandSolutions/FoodDeliveryAppDevelopment/Features.vue'
import WhyInvest from '../../components/OnDemandSolutions/FoodDeliveryAppDevelopment/WhyInvest.vue'
import Expertise from '../../components/OnDemandSolutions/FoodDeliveryAppDevelopment/Expertise.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import About from '../../components/OnDemandSolutions/FoodDeliveryAppDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        TypeDiv,
        WhyInvest,
        Expertise,
        Const,
        Features,
    }
}
</script>


<style>

</style>