<template>
	<div class="col-lg-6 col-md-6 col-12 mb-30">
		<div class="chatbot-platform-box box-shadow rounded10">
			<div class="chatbot-platform-box-icon">
				<img
					class="lazy"
					alt=""
					:src="require(`../../../assets/images${obj.imageLink}`)"
					style=""
				/>
			</div>
			<p class="mb-0">
				{{ obj.text }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.mb-30 {
	margin-bottom: 30px !important;
}
.chatbot-platform-box {
	padding: 30px 30px;
	border: 1px solid #f0f1f2;
	min-height: 210px;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.chatbot-platform-box-icon {
	display: block;
	margin-bottom: 25px;
}
</style>