<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Features></Features>

		<TypeDiv></TypeDiv>

		<Solutions></Solutions>

		<Advertisement></Advertisement>

		<Target></Target>

		<WhyChooseUs></WhyChooseUs>

		<Techno></Techno>

		<ContactUs></ContactUs>

		<Expertise></Expertise>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Banner.vue';
import Features from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Features.vue';
import Solutions from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Solutions.vue';
import WhyChooseUs from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/WhyChooseUs.vue';
import Advertisement from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Advertisement.vue';
import Target from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Target.vue';
import Techno from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Techno.vue';
import ContactUs from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/ContactUs.vue';
import Expertise from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/Expertise.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import About from '../../components/IndustrySolutions/HealthFitnessAppDevelopment/About.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		TypeDiv,
		Solutions,
		Advertisement,
		Target,
		Techno,
		ContactUs,
		WhyChooseUs,
		Expertise,
		Const,
		Features
	}
};
</script>