<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/wordpress-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Full-service WordPress Development Company
					</h2>
					<p>

WordPress offers an endless range of highly functional and user-friendly websites. As a full-service WordPress development company, we help our clients build all kind of websites on WordPress - from personal blogs to professional portfolios or even multi-vendor marketplaces with paywall integrations.
					</p>
					<p>
						In addition to building WordPress websites from scratch, Genixbit also offers to develop WordPress plugins that will extend the usability and utility of your website. For every problem that your business faces, there could be a WordPress plugin that could offer a permanent fix.
					</p>
					<p>
						Don’t be worried of the time taken. Our exclusive team of WordPress developers will see to it that your project gets completed in the shortest time span possible with utmost perfection. Launching bug-free applications is a culture that we take seriously.
					</p>
					<p>
						Tell us your idea and will show how WordPress can make it happen. Our WordPress expertise will ensure that you are able to reach the market in quick time with a well-designed, well-built and fully-functional WordPress website.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Get in Touch with Us</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
