<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/social-media-main.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Bring the world together with our social media app development solutions
					</h2>
					<p>
						A decade ago, meeting and greeting strangers happened on streets. Thanks to social media
						apps, they happen everywhere. Strangers from two corners of the world turn into friends
						with the power of social media. Social media is also an economic powerhouse that has a
						population of many countries put together.
					</p>
					<p>
						Does the world have room for another social media network? Why not? Facebook, Twitter,
						LinkedIn and several others might have the first-mover advantage. Still a unique theme
						that brings together people can still make a difference. After all, the global internet
						users count is increasing steadily as is mobile apps. A new social media app will still
						have takers.
					</p>
					<p>
						Want to build a social media app that can bring together people and can also be
						monetized? GenixBit Labs can help.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>