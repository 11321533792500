<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/android-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Bespoke Custom Android App Development Company</h2>
					<h5 style="font-weight: bold; margin: 15px 0;font-size:16px">
						Address Your Personalised Business Needs!
					</h5>
					<p>
						Regardless to what business you run, we offer customised android app development
						solutions that caters specifically to your business needs. The variety of customisation
						in android is well explored by our highly skilled android mobile app development
						experts. Get a complete, end-to-end app package for your business, including integration
						with existing back-end systems, and even the support and maintenance from our expert
						android app developers.
					</p>
					<p>
						We deliver the best, cost efficient and reliable custom android app development services
						for startups, small and large enterprises. Avail the best possible mobility solutions
						for your business from our dedicated team of experts who has an acute knowledge,
						specialist abilities and proficiency in developing android applications. Get the precise
						estimate and assessment of your business requirements and hire the best android app
						developer on an hourly basis as well as on project basis who will stay dedicated to your
						custom android app development project. Our android app development team will focus on
						creating a user-friendly, high-performance mobile application that will offer your
						business with the best possible opportunity to scale. Our android app developers and UX
						designers highly skilled in creating bespoke enterprise apps that help your business in
						building effective mobile strategies.
					</p>
					<p>
						A good enterprise android app will bring in efficiency in organisation and help keeping
						your staff motivated, happy and performing to the highest level.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
