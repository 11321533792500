<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/finance-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Spearheading the convergence of finance and technology
					</h2>
					<p>
						There has been a time in history when banking, finance and insurance has undergone a sea
						change. Technology has transformed all these services into digital offerings with an
						economic edge to them. In the proces, a new breed of technology that converges both
						finance and technology has come into existence - FinTech. FinTech enables everybody —
						from households that want to build corpus for their retirement to CFOs that want to
						revamp the financial operations of their businesses — a novel way of functioning.
					</p>
					<p>
						GenixBit Labs has been dabbling with FinTech for quite sometime now. We were fascinated by
						its ability to bring financial inclusion to all equally. As a digital corporation,
						GenixBit Labs believes that banks, insurers, financial institutions and customers can
						partake in the benefits of financial transformation. We are here to enable that
						transformation.
					</p>
					<p>
						GenixBit Labs builds finance apps that can simplify mobile banking, integrate digital
						wallets into eCommerce stores, bring insurers and claimants together and do much more.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
