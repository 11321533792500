<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/ios-about.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Our USP as an iPhone App Development Company</h2>
					<p>
						The best of engineering + design - Genixbit brings to you the best of both worlds that
						Apple is reputed for since incarnation. As a digitally inspired enterprise, we are
						always on the lookout for the next big breakthrough in iPhone app development.Our focus
						is not to build just another mobile app, but bring an idea to reality through wholesome
						app development. This has helped the apps we built climb to the top ranks of app store
						leaderboards quickly.
					</p>
					<p>
						Our team comprises of the cream of engineering talent who have been dabbling in iPhone
						app development passionately. We are also made up of designer brains who can think from
						the shoes of a user an create vivid and detailed app interfaces and workflows that will
						make your app a winner in the iTunes leaderboards.Be it to solve an enterprise problem
						or to build the next big thing in the app world, our engineering + design expertise will
						help you do both.
					</p>
					<p>
						Have queries about your next iPhone app development? Well, having questions is a sign of
						intelligence. Don’t hesitate!! Contact us today and get answers to all your queries.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size:20px !important;
		font-weight:bold !important;
	}
}
</style>
