<template>
	<section class="banner innerbanner aboutusbanner fxt-about-section">
		<div class="video pt-0">
			<img
				class="lazy fxt-about-img pt-0"
				alt="GenixBit careers"
				src="../../assets/images/about-us-banner.jpg"
			/>
		</div>

		<div
			class="tenc-intro-main__wrapper"
			style="
				min-height: unset !important;
				background-image: linear-gradient(to right, #000000e8, #000000a8);
			"
		>
			<div class="container pt-5">
				<div class="row align-items-center pt-5">
					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
						<h1 class="fxt-top-head">The Glimpse of our Creative Works</h1>
						<p class="fxt-top-par">
							Like a spanner to a mechanic, steth to a doctor or a hammer to a mason — the right
							tool in the right hands can make a world of a difference. At GenixBit, we have handpicked
							digital technologies that will allow us to serve the best value to our clients.
						</p>
						<div class="d-flex align-items-center" style="max-width:700px;margin:0 auto">
						<router-link
							to="/contact"
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>Talk to Our Expert</router-link
						>
						<span>OR</span>
						<router-link
							to="/app-development-cost"
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>Calculate App Development Cost</router-link
						>
						</div>
						<ol class="breadcrumb">
							<li class="breadcrumb-item text-white">
								<router-link
									to="/"
									class="text-white breadcrumb-item fxt-breadcrumb-item"
									style="background: none; font-size: 14px; margin-right: 0;"
								>
									Home</router-link
								>
							</li>
							<li class="breadcrumb-item text-white active fxt-breadcrumb-item">Portfolio</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 1600px) {
	.banner {
	}
}
.banner {
	margin-top: 0px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.breadcrumb {
	background: 0 0;
	width: 100%;
	max-width: 1170px;
	margin: 0;
	padding: 0 15px;
	z-index: 9999;
}

.video img {
	width: 100%;
	height: 100%;
	min-height: 600px;
	object-fit: cover;
}
.tenc-intro-main__wrapper {
	width: 100%;
	position: absolute;
	top: -33px;
	left: 0;
	display: flex;
	align-items: center;
	background: linear-gradient(
		90deg,
		#236aec,
		rgba(35, 95, 236, 0.95) 40%,
		rgba(35, 161, 236, 0.7) 70%,
		rgba(35, 66, 236, 0.4)
	);
	z-index: 999;
}
.fxt-btn-custome {
	display:block;
	font-size:16px;
	width:fit-content;
	margin: 20px auto;
	background: #1977cc !important;
}
.fxt-top-head {
	display: black;
	text-align:center;
	font-size: 34px;
}
.fxt-top-head span:first-child {
}
.fxt-top-head span:last-child {
	font-size: 24px;
	margin: 20px 0;
}
.fxt-top-par {
	font-size:16px !important;
	margin: 30px 0 5px 0;
	text-align:center;
}
@media (max-width: 991px) {
	.fxt-top-par {
		font-size: 14px;
	}
	.fxt-tenc-intro-main__wrapper {
		top: 0;
	}
}

	.fxt-about-section {
		height: calc(95vh - 40px) !important;
	}
	.video {
		height: 95vh !important;
	}
	.tenc-intro-main__wrapper {
		height: 95vh !important;
	}
	.fxt-about-img {
		height: 100% !important;
		min-height: unset !important;
	}

.breadcrumb {
	padding-top: 15px;
	padding-left: 0 !important;
	margin-left: -20px;
}
.breadcrumb-item.active {
	color: #1977cc !important;
}
.fxt-about-img {
	position: relative;
}
.fxt-about-img::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: red;
}

@media (max-width: 576px) {
	.fxt-top-head {
		font-size: 20px;
		text-overflow: ellipsis;
	}
	.fxt-top-par {
		font-size: 12px !important;
		line-height: 2;
	}
	.fxt-btn-custome {
		font-size: 12px;
		padding:5px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 100px;
	}
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 34px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 28px !important;
	}
}
@media (max-width: 400px) {
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 28px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 22px !important;
	}
	.fxt-breadcrumb-item {
		font-size:12px;
	}
}
</style>
