<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/android-game-development-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Leading Android Game Development Agency</h2>
					<p>
						The Android platform enjoys a large audience through handheld devices across the globe
						and with it comes and array of opportunities for businesses to get a slice of the
						billions revenue generated through Android game development.
					</p>
					<p>
						Our expertise in the Unity game engine, Cocos2d, Android Studio and Java are
						instrumental in infusing your creative and innovative game ideas, stories, and gameplay
						with our excellent game experiences.
					</p>
					<p>
						With exceptional experience in the game industry within the UK and across Europe, you
						can be assured you will have an Android Game that that a local and global audience will
						love.
					</p>
					<p>
						Our android game developers and design team ensure the end product is tailored to the
						chosen genre, including designing, animation development, coding/scripting, testing, and
						marketing accordingly.
					</p>
					<p>
						When faced with the challenge of fragmentation, known to the Android game development
						industry, our developers are on hand to resolve. Our Android game development
						infrastructure in our game studio is highly capable to deal with fragmentation issues
						effectively.
					</p>
					<p>
						Our project management through the latest PMS enable clients to participate actively in
						the game development process, monitor their project’s progress, as well as gameplay
						testing to assure the desired quality.
					</p>
					<p>
						Would you like to test your game ideas with the android game development team at
						GenixBit Labs? We can help you attain the top game list in play store.?
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
