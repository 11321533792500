<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Target audience </span>
				<h2 class="fxt-process-dev">Who can benefit the most from our fitness app development</h2>
				<p>
					Everyone who has it in them to train and mentor others to lead a healthy lifestyle can
					benefit from our health and fitness app development expertise.
				</p>
			</div>
			<div class="fitness-app-image">
				<img
					alt=""
					class="box-shadow rounded10 lazy"
					src="../../../assets/images/right-choice-fitness-app.webp"
					style=""
				/>
				<div class="fitness-app-overlay-content">
					<div class="fitness-app-overlay-content-table">
						<div class="fitness-app-overlay-content-box">
							<div class="fitness-app-overlay-content-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M94.5,31.3c-3.9-1.9-9.2-1.6-13.4,0.8c-1.1-0.8-2.7-1.6-6-1.8c-2.4-0.2-4.8,0.6-6.7,1.4
                      c0.4-1.4,0.8-2.9,1.2-4.4c1.4-4.9,3.4-7.9,4.1-8.1c1.6-0.1,2.9-0.3,4-0.6c1.1-0.2,2-0.4,2.8-0.4c4.1,0,5.5-1.8,5.5-3.4
                      c0-0.9-0.4-1.4-0.8-1.8c0.4-0.4,0.8-0.9,1.1-1.5l0-0.1c0.3-0.6,0.2-1.3-0.2-1.9c-1.2-1.8-5.7-2.9-7.6-3.1
                      c-3.8-0.6-5.6,1.6-6.3,2.6c-0.9,1.2-4.7,5.5-10.5,10.6c-4.4,3.9-8.1,10.6-10.5,15.7c-0.9,2-0.7,4.3,0.6,6.1c5.5,7.3,22,8,28.3,7
                      c2,0.9,5,1.6,8.1,1.6c2.4,0,4.8-0.4,6.9-1.5c2.2-1.1,4.8-3.4,4.8-8.1C99.9,36.3,98,33,94.5,31.3z M93.6,46
                      c-3.6,1.8-9.5,1-12.2-0.4l-0.6-0.3l-0.6,0.1c-5.3,1-20.8,0.3-25.4-5.6c-0.7-1-0.8-2.2-0.3-3.2c3.2-6.8,6.6-11.9,9.8-14.6
                      c6-5.3,9.9-9.8,10.9-11.1c0.8-1,1.3-1.5,3-1.3C80,9.7,82,10.4,83,10.9c-0.2,0.3-0.5,0.6-0.7,0.8c-0.7,0.7-1.3,1.3-1.2,2.2l0.1,0.7
                      l0.6,0.4c0.1,0,0.1,0.1,0.2,0.1c-0.3,0.1-0.8,0.1-1.4,0.1c-1.2,0-2.3,0.2-3.5,0.4c-1.1,0.2-2.2,0.4-3.7,0.5
                      c-3.6,0.3-6,6.6-7.1,10.3c-0.5,1.6-1,3.3-1.3,4.6c-0.2,0.8-0.4,1.6-0.6,2.2c-0.2,0.8,0.1,1.5,0.7,2c0.7,0.5,1.6,0.6,2.4,0.2
                      l0.2-0.1c1.2-0.7,4.3-2.4,7-2.2c2.9,0.2,3.9,0.9,4.3,1.3c0.7,0.6,1.3,1.2,1.6,1.8l0.1,0.1c0.4,0.7,1.2,1.1,2.1,1.1
                      c0.3,0,0.6-0.1,1-0.3c1.1-0.4,1.6-1.5,1.2-2.6l-0.1-0.3c-0.1-0.2-0.3-0.4-0.4-0.6c2.8-1.1,6.1-1.1,8.4,0.1
                      c2.4,1.2,3.7,3.4,3.7,6.5C96.6,43.1,95.6,44.9,93.6,46z M83.7,57.8c-2.2-1.3-4.9-1.7-7.4-1.2c-6.3,1.3-17.2,2.5-21,1.6
                      c-1.4-0.7-5.7-4.9-7.3-6.5l-0.2-0.2c-2.5-2.5-4.4-4.3-6.1-5.7c-3.2-2.9-6.1-5.3-11.4-6.5c-7.7-1.8-21.9-2.4-26.2,6.7
                      c-4.1,8.7-3.8,29.6-3.6,46.4l0.1,3.5c0,2.4,1.9,4.2,4.4,4.2h30.7c2.6,0,4.2-1.3,4.4-3.6c0.1-0.9,1-14.4,1.6-25.1
                      c2.1,1.6,4.5,3,7.1,3.8c2.9,0.9,6.5,1.3,10.9,1.3c10.1,0,20.3-2.2,20.8-2.3c2.6-0.5,4.8-1.9,6.2-3.9c1.4-2,1.9-4.4,1.3-6.7
                      C87.5,61.1,86,59.1,83.7,57.8z M83.9,68.5c-0.9,1.3-2.4,2.3-4.1,2.6c-0.4,0.1-10.3,2.2-20,2.2c-4.1,0-7.3-0.4-9.9-1.1
                      c-3.1-0.9-6-3.1-8.5-5.2l-2.7-2.3l-0.2,3.3c-0.7,11.5-1.7,27.3-1.8,28.2c-0.1,0.7-0.2,0.8-1,0.8H5.1c-1,0-1-0.9-1-1.1L4,92.3
                      C3.8,76.6,3.5,55.2,7.3,47.1c2.2-4.7,8.1-5.9,13.3-5.9c4.6,0,8.6,0.9,8.9,1c4.4,1,6.8,2.9,9.9,5.7c1.7,1.4,3.6,3.2,6,5.6l0.2,0.2
                      c3.4,3.4,6.6,6.5,8.4,7.3l0.2,0.1c4.5,1.2,16.3-0.2,22.8-1.5l0,0c1.7-0.3,3.4-0.1,4.8,0.8c1.5,0.9,2.5,2.2,2.8,3.8
                      C85.1,65.6,84.8,67.2,83.9,68.5z M23.5,37.3c11.2,0,20.4-8.2,20.4-18.4c0-10.1-9.1-18.4-20.4-18.4C12.3,0.5,3.2,8.7,3.2,18.9
                      C3.2,29,12.3,37.3,23.5,37.3z M23.5,3.5c9.4,0,17,6.9,17,15.3c0,8.5-7.6,15.3-17,15.3c-9.4,0-17-6.9-17-15.3
                      C6.5,10.4,14.2,3.5,23.5,3.5z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<span>Fitness Coach</span>
						</div>
						<div class="fitness-app-overlay-content-box">
							<div class="fitness-app-overlay-content-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<path
										class="st0"
										d="M1.2,100h96.7c0.6,0,1.1-0.3,1.4-0.8c0.3-0.5,0.3-1.1,0-1.6L87.6,75.9c-0.3-0.5-0.9-0.9-1.5-0.9H75l5.8-7.3
                  c0.2-0.3,0.4-0.7,0.4-1v-5c0-0.1,0-0.1,0-0.2l14.4-9.6c2.5-1.7,3.7-4.8,3-7.7c-0.6-2.4-2.8-4.1-5.3-4.1c-1.8,0-3.5,0.9-4.5,2.4
                  l-2,3.1l-10,7.1L65.9,35.8c-0.1-0.1-0.2-0.3-0.3-0.4c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.4-0.2c0,0,0,0-0.1,0l-6.8-2.3c0,0,0,0,0,0
                  L55.9,32l-0.5-2h4.1c5.5,0,10-4.5,10-10c0-11-9-20-20-20c-11,0-20,9-20,20c0,5.5,4.5,10,10,10h4.1l-0.5,2l-2.4,0.8c0,0,0,0,0,0
                  L34,35.1c0,0,0,0-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.3c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.3,0.4L22.4,52.5l-8.4-7L12,42.4
                  C11,40.9,9.3,40,7.5,40H6.8c-2.1,0-4,1.2-4.9,3l-0.1,0.2c-1.6,3.2-0.6,7.2,2.4,9.2l13.6,9c0,0.1,0,0.1,0,0.2v5c0,0.4,0.1,0.7,0.4,1
                  L24,75H12.8c-0.6,0-1.2,0.3-1.5,0.9L-0.3,97.5c-0.3,0.5-0.3,1.1,0,1.6C0,99.7,0.6,100,1.2,100z M34,58.8c-0.3-0.3-0.7-0.5-1.2-0.5
                  H32l4.4-7.5l1.5,6v2.9L37.2,62L34,58.8z M42.7,22.4c-0.2-0.4-0.4-0.8-0.6-1.2c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.3-0.9-0.4-1.3
                  c0,0,0,0,0,0c-0.1-0.4-0.2-0.9-0.3-1.4c0-0.1,0-0.2,0-0.3c-0.1-0.5-0.1-0.9-0.1-1.4c0-1.1,0.2-2.3,0.5-3.3h15.7
                  c0.3,1.1,0.5,2.2,0.5,3.3c0,0.5,0,1-0.1,1.4c0,0.1,0,0.2,0,0.3c-0.1,0.5-0.2,0.9-0.3,1.4c0,0,0,0,0,0c-0.1,0.4-0.3,0.9-0.4,1.3
                  c0,0,0,0.1-0.1,0.1c-0.2,0.4-0.4,0.8-0.6,1.2c0,0,0,0,0,0.1c-0.5,0.8-1.1,1.5-1.7,2.1c0,0,0,0,0,0c-0.7,0.6-1.4,1.1-2.2,1.4
                  c-0.4,0.2-0.9,0.3-1.3,0.4c0,0,0,0-0.1,0c-0.9,0.2-1.9,0.2-2.8,0c0,0,0,0-0.1,0c-0.4-0.1-0.9-0.2-1.3-0.4c-0.8-0.3-1.5-0.8-2.2-1.4
                  c0,0,0,0,0,0C43.8,24,43.2,23.3,42.7,22.4C42.7,22.5,42.7,22.5,42.7,22.4z M49.5,30c0.8,0,1.6-0.1,2.4-0.3l1,4
                  c0.1,0.6,0.5,1,1.1,1.2l1.7,0.6c-0.8,1.7-2.5,2.9-4.5,2.9h-3.3c-2,0-3.7-1.1-4.5-2.9l1.7-0.6c0.5-0.2,1-0.6,1.1-1.2l1-4
                  C47.9,29.9,48.7,30,49.5,30z M47.8,41.7h3.3c3.4,0,6.4-2.1,7.7-5.1l3.7,1.2l-2.1,8.3v0L58.2,55H40.8l-2.2-8.9v0l-2.1-8.3l3.7-1.2
                  C41.4,39.6,44.4,41.7,47.8,41.7z M41.1,60.5c0-0.2,0.1-0.3,0.1-0.5v-1.7h16.7V60c0,0.2,0,0.3,0.1,0.5l0.4,1.2H40.7L41.1,60.5z
                  M61.8,62l-0.7-2.2v-2.9l1.5-6l4.3,7.5h-0.8c-0.4,0-0.9,0.2-1.2,0.5L61.8,62z M41.2,65h16.7v1l-8.3,8.3L41.2,66V65z M45.4,85.5
                  L40,87.9L37.1,84l2.5-1.1L45.4,85.5z M34.2,85.7l2.3,3.1l-2.9,1.4c-1.3,0.6-2.8,0.4-3.8-0.6l-0.9-0.9L34.2,85.7z M64.8,85.7l5.3,3
                  l-0.9,0.9c-1,1-2.6,1.3-3.8,0.6l-2.9-1.4L64.8,85.7z M71.8,73.6l-3.3,1.5l0,0l-0.5,0.2l-8.6,3.9l-5.2-2.3l-1.7-0.8l14.5-14.5h3.1
                  h2.7l5.2,1.3v3.1L71.8,73.6z M75.8,56.6c0.5,0.1,0.9,0,1.3-0.3L88.8,48c0.2-0.1,0.3-0.3,0.4-0.4l2.2-3.3c0.4-0.6,1.1-0.9,1.8-0.9
                  c1,0,1.8,0.7,2.1,1.6c0.4,1.6-0.3,3.3-1.6,4.2L78,59.6l-4.7-1.2c-0.1,0-0.3-0.1-0.4-0.1h-2l-7-12.1l1.4-5.4l9.6,15.1
                  C75,56.3,75.4,56.5,75.8,56.6z M39.5,26.7c-3.7,0-6.7-3-6.7-6.7c0-9.2,7.5-16.7,16.7-16.7c9.2,0,16.7,7.5,16.7,16.7
                  c0,3.7-3,6.7-6.7,6.7h-2.3c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.7c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.4-0.5,0.6-0.8
                  c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.3-0.6,0.5-0.9c0-0.1,0.1-0.2,0.1-0.3c0.2-0.3,0.3-0.6,0.4-1c0-0.1,0.1-0.2,0.1-0.2
                  c0.1-0.3,0.3-0.7,0.4-1.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.4,0.2-0.7,0.3-1.1c0-0.1,0-0.2,0.1-0.2c0.1-0.4,0.1-0.7,0.2-1.1
                  c0-0.1,0-0.2,0-0.3c0-0.5,0.1-0.9,0.1-1.4c0-1.9-0.4-3.8-1.1-5.6c-0.3-0.6-0.9-1-1.5-1H40.5c-0.7,0-1.3,0.4-1.5,1
                  c-0.7,1.8-1.1,3.7-1.1,5.6c0,0.5,0,1,0.1,1.4c0,0.1,0,0.2,0,0.3c0,0.4,0.1,0.8,0.2,1.1c0,0.1,0,0.2,0.1,0.2c0.1,0.4,0.2,0.7,0.3,1.1
                  c0,0.1,0.1,0.2,0.1,0.2c0.1,0.4,0.2,0.7,0.4,1.1c0,0.1,0.1,0.2,0.1,0.2c0.1,0.3,0.3,0.7,0.4,1c0,0.1,0.1,0.2,0.1,0.3
                  c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.2,0.4,0.5,0.6,0.7
                  c0.1,0.1,0.1,0.1,0.2,0.2L39.5,26.7L39.5,26.7z M4.8,44.7l0.1-0.2c0.4-0.7,1.1-1.2,1.9-1.2h0.6c0.7,0,1.4,0.4,1.8,0.9l2.2,3.3
                  c0.1,0.1,0.2,0.3,0.3,0.4l10,8.3c0.4,0.3,0.8,0.4,1.3,0.4c0.5-0.1,0.9-0.3,1.1-0.8l9.6-15.1l1.4,5.4l-7,12.1h-2
                  c-0.1,0-0.3,0-0.4,0.1L21,59.6L6.2,49.7C4.5,48.6,4,46.4,4.8,44.7z M21.2,63l5.2-1.3h2.7h3.1l4.6,4.6l1.5,1.5l10,10c0,0,0,0,0.1,0
                  c0.1,0.1,0.3,0.2,0.4,0.3l9.9,4.4c0,0,0,0,0,0l3.2,1.4L59,87.9l-8.8-4l-9.8-4.4L39,78.9l-8.4-3.8l0,0l-3.3-1.5l-6.1-7.6V63z
                  M13.8,78.3h15.7l6,2.7l-1.7,0.8c-0.3,0.1-0.5,0.4-0.7,0.6l-5.9,3.4c-0.9,0.5-1.5,1.4-1.7,2.5c-0.1,1,0.2,2.1,0.9,2.8l0.9,0.9
                  c1.3,1.3,3,2,4.7,2c1,0,2-0.2,3-0.7l3.6-1.8c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0
                  c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6-0.1l9.3-4.2l9.3,4.2c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.5-0.1
                  c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0l3.6,1.8c1,0.5,2,0.7,3,0.7c1.7,0,3.4-0.7,4.7-2l0.9-0.9c0.7-0.7,1.1-1.8,0.9-2.8
                  c-0.1-1-0.7-1.9-1.6-2.5l-5.9-3.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.2-0.3-0.3-0.5-0.4L63.5,81l6-2.7h15.7L95,96.7H4L13.8,78.3z"
									></path>
								</svg>
							</div>
							<span>Yoga Instructors</span>
						</div>
						<div class="fitness-app-overlay-content-box">
							<div class="fitness-app-overlay-content-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M93.1,37.9h-2.8v-1.7c1.7-0.3,3.2-1.2,4.2-2.5c1-1.4,1.4-3.2,0.9-4.8c-0.4-1.6-1.5-3-3-3.8
                      c-0.5-0.3-1.1-0.5-1.7-0.6c-0.1,0-0.3,0-0.4-0.1v-2.2h2.8c1.1,0,2-0.9,2-2v-9c0-1.1-0.9-2-2-2h-9.5c-1.1,0-2,0.9-2,2v9
                      c0,1.1,0.9,2,2,2h2.7v2.4l0,0c-0.9,0.1-1.8,0.2-2.8,0.4c-4,0.5-8,0.7-12,0.7c-1.8,0-3.7-0.1-5.5-0.1c-1.6-0.1-3.3-0.1-5-0.1
                      c-0.5-0.4-1-0.8-1.5-1.2c1.6-2.6,2.5-5.9,2.5-9.3c0-3.8-1.1-7.5-3.1-10.3c-2.1-3-5.1-4.6-8.2-4.6c-3.2,0-6.1,1.6-8.2,4.6
                      c-2,2.8-3.1,6.4-3.1,10.3c0,3.4,0.9,6.7,2.5,9.3c-0.5,0.4-1,0.8-1.5,1.2c-1.7,0-3.5,0.1-5.2,0.1c-1.8,0.1-3.6,0.1-5.2,0.1
                      c-3.5,0-7.2-0.2-12-0.7c0,0,0,0,0,0c-0.9-0.1-1.9-0.2-3-0.4l0,0v-2.4h2.8c1.1,0,2-0.9,2-2v-9c0-1.1-0.9-2-2-2H8.1
                      c-1.1,0-2,0.9-2,2v9c0,1.1,0.9,2,2,2h2.7v2.3c-1.3,0.2-2.6,0.8-3.6,2.2c-1.2,1.7-1.5,3.8-0.7,5.7c0.7,1.9,2.4,3.3,4.4,3.7v1.8H8.1
                      c-1.1,0-2,0.9-2,2v9c0,1.1,0.9,2,2,2h9.5c1.1,0,2-0.9,2-2v-9c0-1.1-0.9-2-2-2h-2.8v-1.4l2.6,0.2l4.7,0.3c3.3,0.2,6.5,0.7,9.7,1.4
                      c3,0.7,5,3.2,5,6.2v8.8l-2.8,24.5c-0.1,0.6,0.1,1.1,0.5,1.5c0.4,0.4,0.9,0.7,1.5,0.7h0.1v9h-1.7c-5.1,0-9.2,4-9.2,9
                      c0,1.1,0.9,2,2,2h17.6c1.1,0,2-0.9,2-2V80h0.2c0.9,0,1.7-0.6,2-1.5l2-8.2l2.7,8.4c0.3,0.8,1,1.4,1.9,1.4v18c0,1.1,0.9,2,2,2h17.6
                      c1.1,0,2-0.9,2-2c0-5-4.1-9-9.2-9h-1.7v-9h0.2c0.6,0,1.1-0.3,1.5-0.7c0.4-0.4,0.5-1,0.5-1.6l-3.9-24.5v-8.7c0-3,2.1-5.5,5-6.2
                      c3.2-0.7,6.5-1.2,9.7-1.4l4.3-0.3l2.5-0.2v1.4h-2.7c-1.1,0-2,0.9-2,2v9c0,1.1,0.9,2,2,2h9.5c1.1,0,2-0.9,2-2v-9
                      C95.1,38.8,94.2,37.9,93.1,37.9z M10.1,18.3v-5.1h5.5v5.1H10.1z M15.5,41.9v5.1h-5.5v-5.1H15.5z M15.5,32.6l-3.5-0.2
                      c-0.9-0.1-1.6-0.6-1.9-1.4c-0.3-0.7-0.2-1.4,0.3-2c0.3-0.5,0.9-0.7,1.8-0.7c0.5,0,1.2,0.1,2,0.2l0.1,0c0.4,0.1,0.8,0.1,1.2,0.1
                      V32.6z M50.6,3.9c3.9,0,7.3,5,7.3,10.9c0,5.9-3.3,10.9-7.3,10.9c-3.9,0-7.3-5-7.3-10.9C43.3,8.9,46.6,3.9,50.6,3.9z M57.7,27.9
                      c-1.3,2.7-4.1,4.5-7.1,4.5c-3.1,0-5.9-1.8-7.2-4.5c0.3-0.2,0.6-0.4,0.8-0.6c1.8,1.6,4,2.5,6.3,2.5c2.3,0,4.4-0.9,6.3-2.5
                      C57.2,27.5,57.5,27.7,57.7,27.9z M42.8,96.1H29.7c0.8-1.8,2.6-3.1,4.8-3.1h8.4V96.1z M42.8,89h-2.7v-9h2.7V89z M57.2,76.1
                      l-4.5-13.7c-0.3-0.8-1.1-1.4-2-1.4c-0.9,0-1.7,0.6-1.9,1.5l-3.3,13.6h-7.2l2.4-20.7H61l3.3,20.7H57.2z M62.4,80v9h-2.7v-9H62.4z
                      M68.1,93c2.1,0,4,1.3,4.8,3.1H59.7V93H68.1z M81.6,32.9L79.2,33c-3.5,0.2-7,0.7-10.4,1.5c-4.8,1.1-8.1,5.2-8.1,10v6.9H40.9v-6.9
                      c0-4.8-3.3-8.9-8.1-10c-3.4-0.8-6.9-1.3-10.4-1.5l-2.8-0.2v-3.8c4,0.4,7.2,0.6,10.3,0.5c1.7,0,3.5-0.1,5.3-0.1
                      c1.5-0.1,3.1-0.1,4.5-0.1c1.6,3.6,5,6.1,8.9,6.8v3.5c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-3.5c3.9-0.7,7.3-3.2,8.9-6.8
                      c1.4,0,2.9,0.1,4.3,0.1c1.8,0.1,3.7,0.1,5.6,0.1c3.4,0,6.8-0.1,10.2-0.5V32.9z M85.6,18.3v-5.1H91v5.1H85.6z M85.6,32.6v-3.9
                      c0.4-0.1,0.8-0.1,1.2-0.2l0.2,0c0.5-0.1,1-0.1,1.4-0.2c0,0,0.1,0,0.1,0c0.5,0,1,0,1.4,0c0.2,0,0.4,0.1,0.6,0.2h0
                      c0.5,0.2,0.8,0.7,1,1.2c0.1,0.6,0,1.2-0.3,1.7c-0.4,0.5-1,0.9-1.8,0.9L85.6,32.6z M91,46.9h-5.5v-5.1H91V46.9z M52.5,47
                      c-0.3-1.1-1.3-1.7-2.4-1.5c-1.1,0.2-1.8,1.3-1.5,2.4l0,0.1c0.2,0.9,1,1.5,2,1.5c0.2,0,0.3,0,0.5-0.1C52.1,49.2,52.8,48.1,52.5,47
                      L52.5,47z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<span>Gym Owner</span>
						</div>
						<div class="fitness-app-overlay-content-box">
							<div class="fitness-app-overlay-content-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M67.2,66.2l-0.1,0c-0.3-1-0.6-2-1.2-2.8c-1.3-2.1-3.4-3.6-6.3-4.4c0-0.9,0-1.9,0.1-3
                      c5.2-6.2,7.2-16.5,7.5-23.1c3.3-7.3,3.4-14,0.3-18.9c-1.7-2.6-4.2-4.6-7.4-5.9c0-0.1,0-0.1,0-0.2c0-4.4-4.7-7.8-10.6-7.8
                      c-6,0-10.6,3.4-10.6,7.8c0,0.1,0,0.1,0,0.2c-3.2,1.3-5.7,3.3-7.3,5.9c-3.1,4.9-3,11.6,0.3,19c0.4,7.5,2.7,17.2,7.5,23
                      c0.1,1.1,0.1,2.1,0.1,3c-3.1,0.9-5.4,2.6-6.7,5c-0.4,0.7-0.7,1.5-0.9,2.3l-0.1,0c-3.2,0.5-6.9,1.1-9.4,4.8
                      c-4.3,6.5-6.7,16.4-6.7,27.3c0,0.9,0.8,1.7,1.7,1.7h64c0.9,0,1.7-0.8,1.7-1.7c0-10.9-2.4-20.9-6.7-27.3
                      C74.1,67.3,70.4,66.7,67.2,66.2z M49.5,3.4c3.4,0,6.4,1.6,7.1,3.6c-2.2-0.5-4.5-0.8-7.1-0.8c-2.6,0-5,0.3-7.1,0.8
                      C43.1,4.9,46,3.4,49.5,3.4z M34.3,15.8c1.4-2.2,3.7-3.9,6.7-5c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c2.3-0.8,5.1-1.2,8.2-1.2
                      c7.2,0,12.6,2.2,15.2,6.2c2.5,3.9,2.3,9.5-0.6,15.8c-2.8-0.4-4.8-1.9-7.3-3.8c-0.6-0.4-1.1-0.8-1.7-1.3c-6.8-4.8-14.5-3.7-21,3
                      C32,24,32.1,19.2,34.3,15.8z M35.2,33.1c3-3.5,9.9-9.6,17.9-3.9c0.6,0.4,1.1,0.8,1.7,1.2c2.7,2,5.2,3.9,8.9,4.4
                      c-1,10-5.2,22.8-14.2,22.9c0,0-0.1,0-0.1,0C40,57.7,35.8,42.9,35.2,33.1z M47.8,96.6H28.7v-4.1c0-0.9-0.8-1.7-1.7-1.7
                      s-1.7,0.8-1.7,1.7v4.1h-6.1c0.2-9.4,2.5-18.2,6.2-23.8c1.5-2.3,3.6-2.8,6.3-3.2c0,2.3,0.6,5,1.7,8.1c-0.8,0.9-1.4,2-1.4,3.3
                      c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8c0-2.6-2.1-4.8-4.8-4.8c-0.1,0-0.2,0-0.3,0c-1.6-4.8-1.8-8.5-0.5-10.9
                      c0.6-1.2,1.7-2.1,3.1-2.8c-0.1,0.3-0.3,0.6-0.5,0.8c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2
                      c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2
                      c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0l9.7,18.1V96.6z M35.2,81.1c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
                      c0,0.8-0.6,1.4-1.4,1.4C35.8,82.5,35.2,81.9,35.2,81.1z M41.6,64.8c1-1.6,1.2-3.6,1.3-5.7c1.9,1.2,4.1,2,6.5,2c0,0,0.1,0,0.1,0
                      c2.6,0,4.8-0.8,6.7-2c0,0.3,0,0.7,0,1c0,0.2,0,0.4,0,0.6c0.1,1.5,0.5,3,1.2,4.1l-7.9,14.8L41.6,64.8z M73.7,96.6v-4.1
                      c0-0.9-0.8-1.7-1.7-1.7c-0.9,0-1.7,0.8-1.7,1.7v4.1H51.2v-13l9.7-18.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
                      c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
                      c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.2-0.2-0.4-0.5-0.5-0.8
                      c1.3,0.6,2.2,1.4,2.9,2.4c1,1.6,1.3,3.9,0.8,6.8c-1.3,0-2.7,0.4-3.9,1.2c-2,1.3-3.3,3.4-3.8,5.9c-0.5,2.9-0.9,5.7-1.2,8.3
                      c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.2
                      c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1
                      c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0.1,0l1.8,0.4c0.1,0,0.2,0,0.4,0c0.8,0,1.5-0.5,1.6-1.3c0.2-0.9-0.4-1.8-1.3-2
                      l-0.3-0.1c0.3-2.1,0.6-4.3,1-6.6c0.3-1.6,1.1-2.9,2.3-3.7c0.6-0.4,1.5-0.8,2.6-0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0
                      c2.4,0.8,3.9,3.6,3.3,6.5c-0.2,1-0.6,3.4-1.3,6.3l-0.2,0c-0.9-0.2-1.8,0.4-2,1.3c-0.2,0.9,0.4,1.8,1.3,2l1.7,0.4c0,0,0,0,0,0
                      c0,0,0,0,0,0l0,0c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0,0.6-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.1-0.1
                      c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0.9-3.7,1.5-6.9,1.7-8.1c0.8-4.1-1.2-8.2-4.5-9.9
                      c0.2-1.1,0.3-2.1,0.3-3.1c2.6,0.4,4.8,0.9,6.3,3.2c3.7,5.6,5.9,14.2,6.1,23.8H73.7z M99.2,45.9c-0.3-0.4-0.6-0.6-1.1-0.7l-5.4-1
                      l0.2-0.9c0.1-0.4,0-0.9-0.3-1.3c-0.2-0.4-0.6-0.6-1.1-0.7l-13.5-2.6c-0.9-0.2-1.8,0.4-2,1.3l-0.2,0.9l-5.4-1.1
                      c-0.9-0.2-1.8,0.4-2,1.3l-3.4,17.4c-0.2,0.9,0.4,1.8,1.3,2c0.1,0,0.2,0,0.3,0c0.8,0,1.5-0.6,1.6-1.4l3.1-15.8l3.8,0.7l-0.2,1
                      c-0.1,0.4,0,0.9,0.3,1.3c0.2,0.4,0.6,0.6,1.1,0.7l13.5,2.6c0.1,0,0.2,0,0.3,0c0.8,0,1.5-0.6,1.6-1.4l0.2-1l3.8,0.7l-6.3,32.6
                      l-4.7-0.9c-0.9-0.2-1.8,0.4-2,1.3c-0.2,0.9,0.4,1.8,1.3,2l6.4,1.3c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,0.9-0.3
                      c0.4-0.3,0.6-0.6,0.7-1.1l7-35.9C99.6,46.8,99.5,46.3,99.2,45.9z M89.1,45.1C89.1,45.2,89.1,45.2,89.1,45.1c0,0.1,0,0.1,0,0.1
                      l-0.2,0.9l-10.2-2l0.4-1.9l10.2,2L89.1,45.1z M88.3,57.4c0.1,0,0.2,0,0.3,0c0.8,0,1.5-0.6,1.7-1.4c0.2-0.9-0.4-1.8-1.3-2
                      l-13.2-2.6c-0.9-0.2-1.8,0.4-2,1.3c-0.2,0.9,0.4,1.8,1.3,2L88.3,57.4z M72.6,58.6c-0.2,0.9,0.4,1.8,1.3,2l13.2,2.6
                      c0.1,0,0.2,0,0.3,0c0.8,0,1.5-0.6,1.7-1.4c0.2-0.9-0.4-1.8-1.3-2l-13.2-2.6C73.6,57.1,72.8,57.7,72.6,58.6z M71.5,64.4
                      c-0.2,0.9,0.4,1.8,1.3,2L86,69c0.1,0,0.2,0,0.3,0c0.8,0,1.5-0.6,1.6-1.4c0.2-0.9-0.4-1.8-1.3-2l-13.2-2.6
                      C72.5,62.9,71.7,63.5,71.5,64.4z M81,70.6c-0.9-0.2-1.8,0.4-2,1.3c-0.2,0.9,0.4,1.8,1.3,2l4.6,0.9c0.1,0,0.2,0,0.3,0
                      c0.8,0,1.5-0.6,1.6-1.4c0.2-0.9-0.4-1.8-1.3-2L81,70.6z M21,40.9c0.2-0.4,0.4-0.8,0.6-1.2c0.5-0.8,0.2-1.8-0.5-2.3
                      c-0.8-0.5-1.8-0.2-2.3,0.5c-1,1.6-1.5,3.2-1.6,4.8c-2.9,0.8-4.8,2.1-5.8,3.9c-0.9,1.8-0.8,4.1,0.4,6.7c2.7,5.7,5.3,8.4,8.2,8.5
                      c0.1,0,0.1,0,0.2,0c1.8,0,3.5-1.1,5-3.3c0.6,0.1,1.2,0.1,1.7,0.1c1.9,0,3.3-0.6,4.3-1.7c1.9-2.2,1.7-5.9-0.6-11.8
                      c-1-2.6-2.6-4.3-4.5-4.9C24.5,39.9,22.8,40.1,21,40.9z M25,43.6c0.9,0.3,1.7,1.2,2.4,2.9c2.4,6.2,1.5,8,1.2,8.3
                      c-0.5,0.6-1.9,0.7-3.8,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
                      c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                      c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0.1c-1,1.7-2,2.6-2.8,2.6c-0.5,0-2.4-0.6-5.2-6.6c-0.7-1.6-0.9-2.9-0.4-3.7
                      c0.6-1.1,2.2-2,4.8-2.5c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
                      c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0C22,43.9,23.8,43.2,25,43.6z M12.2,64.8c0.3-0.9-0.2-1.8-1.1-2.1c-0.9-0.3-1.8,0.2-2.1,1.1
                      c-0.3,1-0.4,2.4-0.1,4.1c0,0,0,0,0,0c0,0,0,0,0,0c1,5.7-1.8,10-8.4,13.2c-0.6,0.3-1,1-0.9,1.7c0.1,0.7,0.6,1.3,1.3,1.4
                      c1.3,0.3,2.6,0.4,3.8,0.4c4.2,0,7.7-1.7,9.6-4.8c2.3-3.7,1.5-8.5-2-13C12,65.5,12.2,64.9,12.2,64.8z M11.3,78.2
                      c-0.9,1.5-2.5,2.5-4.4,2.9c2.6-2.1,4.3-4.5,5.1-7.2C12.2,75.5,12,76.9,11.3,78.2z M4.7,29.1C4.7,29.2,4.7,29.2,4.7,29.1
                      C4.8,29.2,4.8,29.2,4.7,29.1c1,3.9,4.6,6.7,8.6,6.7c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.2,0,0.3,0,0.5-0.1
                      c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0,0,0,0,0,0c0.1-0.2,0.2-0.3,0.2-0.5
                      c0,0,0,0,0,0c0.1-0.6,0.3-1.2,0.5-1.9c0.5,0.5,1,0.8,1.6,0.9c0.3,0.1,0.6,0.1,0.9,0.1c1.2,0,2.4-0.6,3.7-1.7
                      c2.3-2.1,3.4-4.1,3.3-6.2c-0.1-3.2-3-5.7-5.4-7.5c0,0,0,0,0,0c-2.5-1.8-5.7-3.8-8.8-3c-2.1,0.5-3.7,2.2-5,5
                      c-0.9,2-0.9,3.6-0.1,4.8c0.3,0.5,0.8,0.9,1.3,1.2c-0.5,0.4-1.1,0.8-1.6,1.1c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
                      c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1
                      c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2C4.7,29.1,4.7,29.1,4.7,29.1z
                      M12.3,25.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
                      c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1
                      c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-1.4,0-1.9-0.3-1.9-0.4
                      c-0.1-0.1-0.1-0.6,0.4-1.6c0.9-1.8,1.8-2.9,2.8-3.1c1.3-0.3,3.2,0.5,5.9,2.5c0,0,0,0,0,0c2.7,2,4,3.6,4.1,4.9c0,1-0.7,2.2-2.2,3.6
                      c-0.9,0.8-1.4,0.9-1.5,0.8c0,0-0.5-0.2-1-1.8c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
                      c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1
                      c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
                      c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                      c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.8,1.4-1.4,2.8-1.8,4.1
                      c-1.6-0.3-3.1-1.4-3.8-2.9c1.2-0.8,2.3-1.8,3.5-3.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
                      c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2C12.3,25.3,12.3,25.3,12.3,25.2
                      C12.3,25.3,12.3,25.3,12.3,25.2z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<span>Dietician</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.fitness-app-image {
	position: relative;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.fitness-app-overlay-content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	max-width: 840px;
	width: 100%;
	margin: 0 auto;
	background-color: #fff;
	border: 1px solid #ecedee;
	border-radius: 10px 10px 0 0;
}
.fitness-app-overlay-content-table {
	display: table;
	min-height: 120px;
	width: 100%;
}
.fitness-app-overlay-content-box {
	width: 25%;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	padding: 10px 10px;
	border-right: 1px solid #ecedee;
}
.fitness-app-overlay-content-icon {
	margin-bottom: 12px;
}
.fitness-app-overlay-content-icon svg * {
	fill: #7e8082;
}
.fitness-app-overlay-content-icon svg {
	width: 50px;
	height: 50px;
}
.fitness-app-overlay-content-box span {
	font-size: 16px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.fitness-app-overlay-content-box:hover span,
.fitness-app-overlay-content-box:hover svg * {
	color: #0082dd;
	fill: #0082dd;
}
</style>