<template>
	<section class="padding-60 p-relative" style="background: #fff">
		<div class="container mt-5">
			<div class="title">
				<span>Comprehensive Feature-set</span>
				<h2 class="fxt-process-dev">Custom CMS Development Solutions</h2>
				<p>
					Neatly crafted custom CMS development solutions are tailored to your platform, be it web
					or mobile app. Blended with quality delivery standards we ensure putting your content
					concepts in a way that lets reader to dive deep and stick to it.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<NormalCard v-bind:obj="item"></NormalCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NormalCard from '../AssetComponents/NormalCard';

export default {
	components: {
		NormalCard
	},
	data: () => {
		return {
			items: [
        {
					title: 'Custom CMS Website Development',
					text: 'Creating high-performance CMS-driven websites aims at offering expertly fabricated content that’s managed with delivery applications. It features indexing tools, asset managers, search engine and even version control module offering utmost back-end personalisation.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <path class="st0" d="M-449.8-2083.9v-7.3l-2.7-0.7c-0.1-0.3-0.2-0.6-0.4-0.9l1.4-2.4l-2.2-2.2v-35.6h-56.1v46.5h5.1
                c0.2,0.6,0.7,1,1.2,1.3l6.1,2.6h21.5c0.1,0.1,0.1,0.3,0.2,0.4l-1.4,2.4l5.2,5.2l2.4-1.4c0.3,0.1,0.6,0.3,0.9,0.4l0.7,2.7h7.3
                l0.7-2.7c0.3-0.1,0.6-0.2,0.9-0.4l2.4,1.4l5.2-5.2l-1.4-2.4c0.1-0.3,0.3-0.6,0.4-0.9L-449.8-2083.9z M-507.9-2131.1h52.3v5.8h-52.3
                V-2131.1z M-498.2-2085.1l-4.5-1.9c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.4,0.3-0.5l4.5-1.9V-2085.1z M-503.5-2089.8
                c-0.6,0.2-1,0.7-1.2,1.3h-3.1v-34.8h52.3v24l-1-1l-2.4,1.4c-0.3-0.1-0.6-0.3-0.9-0.4l-0.7-2.7h-7.3l-0.7,2.7
                c-0.3,0.1-0.6,0.2-0.9,0.4l-2.4-1.4l-5.2,5.2l1.4,2.4c-0.1,0.1-0.1,0.3-0.2,0.4h-21.5L-503.5-2089.8z M-496.3-2084.6v-1.9h1.9v-1.9
                h-1.9v-1.9h23.7c-0.2,0.6-0.4,1.3-0.5,1.9h-19.4v1.9h19.4c0.1,0.7,0.2,1.3,0.5,1.9H-496.3z M-453.9-2080.1l-3.1,3.1l-2.1-1.2
                l-0.5,0.2c-0.5,0.3-1,0.5-1.6,0.7l-0.5,0.2l-0.6,2.3h-4.3l-0.6-2.3l-0.5-0.2c-0.5-0.2-1-0.4-1.6-0.7l-0.5-0.2l-2.1,1.2l-3.1-3.1
                l1.2-2.1l-0.2-0.5h2.2c1.6,2.3,4.2,3.9,7.2,3.9c4.8,0,8.7-3.9,8.7-8.7c0-4.8-3.9-8.7-8.7-8.7c-3,0-5.7,1.5-7.2,3.9h-2.2l0.2-0.5
                l-1.2-2.1l3.1-3.1l2.1,1.2l0.5-0.2c0.5-0.3,1-0.5,1.6-0.7l0.5-0.2l0.6-2.3h4.3l0.6,2.3l0.5,0.2c0.5,0.2,1,0.4,1.6,0.7l0.5,0.2
                l2.1-1.2l3.1,3.1l-1.2,2.1l0.2,0.5c0.3,0.5,0.5,1,0.7,1.6l0.2,0.5l2.3,0.6v4.3l-2.3,0.6l-0.2,0.5c-0.2,0.5-0.4,1-0.7,1.6l-0.2,0.5
                L-453.9-2080.1z M-471.1-2087.5c0-3.7,3-6.8,6.8-6.8c3.7,0,6.8,3,6.8,6.8c0,3.7-3,6.8-6.8,6.8
                C-468.1-2080.7-471.1-2083.8-471.1-2087.5z M-464.3-2082.7c2.7,0,4.8-2.2,4.8-4.8c0-2.7-2.2-4.8-4.8-4.8c-2.7,0-4.8,2.2-4.8,4.8
                C-469.2-2084.8-467-2082.7-464.3-2082.7z M-464.3-2090.4c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9
                C-467.2-2089.1-465.9-2090.4-464.3-2090.4z M-505.9-2127.2h1.9v-1.9h-1.9V-2127.2z M-502.1-2127.2h1.9v-1.9h-1.9V-2127.2z
                M-498.2-2127.2h1.9v-1.9h-1.9V-2127.2z M-505.9-2104h27.1v-17.4h-27.1V-2104z M-504-2119.5h23.2v13.5H-504V-2119.5z M-475-2121.4
                h-1.9v1.9h1.9V-2121.4z M-457.6-2121.4H-473v1.9h15.5V-2121.4z M-457.6-2117.5h-19.4v1.9h19.4V-2117.5z M-457.6-2113.6h-19.4v1.9
                h19.4V-2113.6z M-467.2-2107.8h9.7v-1.9h-9.7V-2107.8z M-469.2-2109.8h-7.7v1.9h7.7V-2109.8z M-461.4-2105.9h-15.5v1.9h15.5
                V-2105.9z M-500.1-2102h-3.9c-1.1,0-1.9,0.9-1.9,1.9v1.9c0,1.1,0.9,1.9,1.9,1.9h3.9c1.1,0,1.9-0.9,1.9-1.9v-1.9
                C-498.2-2101.2-499.1-2102-500.1-2102z M-504-2098.2v-1.9h3.9l0,1.9H-504z M-490.5-2102h-3.9c-1.1,0-1.9,0.9-1.9,1.9v1.9
                c0,1.1,0.9,1.9,1.9,1.9h3.9c1.1,0,1.9-0.9,1.9-1.9v-1.9C-488.5-2101.2-489.4-2102-490.5-2102z M-494.3-2098.2v-1.9h3.9l0,1.9
                H-494.3z M-480.8-2102h-3.9c-1.1,0-1.9,0.9-1.9,1.9v1.9c0,1.1,0.9,1.9,1.9,1.9h3.9c1.1,0,1.9-0.9,1.9-1.9v-1.9
                C-478.8-2101.2-479.7-2102-480.8-2102z M-484.7-2098.2v-1.9h3.9l0,1.9H-484.7z M-486.6-2117.5h-15.5v1.9h15.5V-2117.5z
                M-484.7-2115.6h1.9v-1.9h-1.9V-2115.6z M-498.2-2111.7h15.5v-1.9h-15.5V-2111.7z M-502.1-2107.8h15.5v-1.9h-15.5V-2107.8z
                M-500.1-2113.6h-1.9v1.9h1.9V-2113.6z"></path>
              </g>
              <g>
                <g>
                  <path d="M100,81.9V69.7l-4.5-1.2c-0.2-0.5-0.4-1-0.6-1.4l2.4-4.1l-3.7-3.7V0H0v77.4h8.4c0.4,0.9,1.1,1.7,2.1,2.1l10.1,4.3h35.8
                  c0.1,0.2,0.2,0.4,0.3,0.7l-2.4,4.1l8.6,8.6l4.1-2.4c0.5,0.2,1,0.4,1.4,0.6l1.2,4.5h12.2l1.2-4.5c0.5-0.2,1-0.4,1.4-0.6l4.1,2.4
                  l8.6-8.6l-2.4-4.1c0.2-0.5,0.4-1,0.6-1.4L100,81.9z M3.2,3.2h87.1v9.7H3.2V3.2z M19.4,79.8l-7.6-3.2c-0.3-0.1-0.5-0.4-0.5-0.8
                  c0-0.3,0.2-0.6,0.5-0.8l7.6-3.2V79.8z M10.5,72.1c-1,0.4-1.7,1.2-2.1,2.1H3.2V16.1h87.1v40l-1.7-1.7l-4.1,2.4
                  c-0.5-0.2-1-0.4-1.4-0.6l-1.2-4.5H69.7l-1.2,4.5c-0.5,0.2-1,0.4-1.4,0.6L63,54.4L54.4,63l2.4,4.1c-0.1,0.2-0.2,0.4-0.3,0.7H20.6
                  L10.5,72.1z M22.6,80.6v-3.2h3.2v-3.2h-3.2V71h39.6c-0.4,1-0.6,2.1-0.8,3.2H29v3.2h32.4c0.1,1.1,0.4,2.2,0.8,3.2H22.6z M93.2,88.1
                  l-5.1,5.1l-3.4-2l-0.8,0.4c-0.9,0.4-1.7,0.8-2.6,1.1L80.4,93l-1,3.8h-7.2l-1-3.8l-0.8-0.3c-0.9-0.3-1.7-0.6-2.6-1.1L67,91.2
                  l-3.4,2l-5.1-5.1l2-3.4L60,83.9h3.7c2.6,3.9,7,6.5,12.1,6.5c8,0,14.5-6.5,14.5-14.5c0-8-6.5-14.5-14.5-14.5c-5,0-9.5,2.6-12.1,6.5
                  H60l0.4-0.8l-2-3.4l5.1-5.1l3.4,2l0.8-0.4c0.9-0.4,1.7-0.8,2.6-1.1l0.8-0.3l1-3.8h7.2l1,3.8l0.8,0.3c0.9,0.3,1.7,0.6,2.6,1.1
                  l0.8,0.4l3.4-2l5.1,5.1l-2,3.4l0.4,0.8c0.4,0.9,0.8,1.7,1.1,2.6l0.3,0.8l3.8,1v7.2l-3.8,1l-0.3,0.8c-0.3,0.9-0.6,1.7-1.1,2.6
                  l-0.4,0.8L93.2,88.1z M64.5,75.8c0-6.2,5.1-11.3,11.3-11.3c6.2,0,11.3,5.1,11.3,11.3c0,6.2-5.1,11.3-11.3,11.3
                  C69.6,87.1,64.5,82,64.5,75.8z M75.8,83.9c4.4,0,8.1-3.6,8.1-8.1c0-4.4-3.6-8.1-8.1-8.1s-8.1,3.6-8.1,8.1
                  C67.7,80.3,71.4,83.9,75.8,83.9z M75.8,71c2.7,0,4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8
                  C71,73.1,73.1,71,75.8,71z M6.5,9.7h3.2V6.5H6.5V9.7z M12.9,9.7h3.2V6.5h-3.2V9.7z M19.4,9.7h3.2V6.5h-3.2V9.7z M6.5,48.4h45.2
                  v-29H6.5V48.4z M9.7,22.6h38.7v22.6H9.7V22.6z M58.1,19.4h-3.2v3.2h3.2V19.4z M87.1,19.4H61.3v3.2h25.8V19.4z M87.1,25.8H54.8V29
                  h32.3V25.8z M87.1,32.3H54.8v3.2h32.3V32.3z M71,41.9h16.1v-3.2H71V41.9z M67.7,38.7H54.8v3.2h12.9V38.7z M80.6,45.2H54.8v3.2
                  h25.8V45.2z M16.1,51.6H9.7c-1.8,0-3.2,1.4-3.2,3.2v3.2c0,1.8,1.4,3.2,3.2,3.2h6.5c1.8,0,3.2-1.4,3.2-3.2v-3.2
                  C19.4,53.1,17.9,51.6,16.1,51.6z M9.7,58.1v-3.2h6.5l0,3.2H9.7z M32.3,51.6h-6.5c-1.8,0-3.2,1.4-3.2,3.2v3.2
                  c0,1.8,1.4,3.2,3.2,3.2h6.5c1.8,0,3.2-1.4,3.2-3.2v-3.2C35.5,53.1,34,51.6,32.3,51.6z M25.8,58.1v-3.2h6.5l0,3.2H25.8z M48.4,51.6
                  h-6.5c-1.8,0-3.2,1.4-3.2,3.2v3.2c0,1.8,1.4,3.2,3.2,3.2h6.5c1.8,0,3.2-1.4,3.2-3.2v-3.2C51.6,53.1,50.2,51.6,48.4,51.6z
                  M41.9,58.1v-3.2h6.5l0,3.2H41.9z M38.7,25.8H12.9V29h25.8V25.8z M41.9,29h3.2v-3.2h-3.2V29z M19.4,35.5h25.8v-3.2H19.4V35.5z
                  M12.9,41.9h25.8v-3.2H12.9V41.9z M16.1,32.3h-3.2v3.2h3.2V32.3z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CMS Customization',
					text: 'Moving away from the proprietary-based CMS development go for an agile, custom-built content solution. A custom CMS development ensures smooth browsing and quicker navigation. Choose your CMS platform wisely after a thorough recommendation from CMS development company.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M70.8,50.8c0-0.8-0.5-1.5-1.3-1.6l-4.2-1.1l2.2-3.8c0.4-0.7,0.3-1.5-0.3-2.1l-4.7-4.8c-0.5-0.6-1.4-0.7-2-0.3l-3.8,2.3
                  l-1.1-4.3c-0.2-0.8-0.8-1.3-1.6-1.3h-6.7c-0.8,0-1.4,0.5-1.6,1.3l-1.1,4.3l-3.8-2.3c-0.7-0.4-1.5-0.3-2,0.3l-4.7,4.8
                  c-0.5,0.5-0.6,1.4-0.3,2.1l2.3,3.8l-4.2,1.1c-0.7,0.2-1.3,0.9-1.3,1.6v6.8c0,0.8,0.5,1.5,1.3,1.6l4.2,1.1l-2.2,3.8
                  c-0.4,0.7-0.3,1.5,0.3,2.1L39,71c0.5,0.6,1.4,0.7,2,0.3l3.8-2.3l1.1,4.3c0.2,0.8,0.8,1.3,1.6,1.3h6.7c0.8,0,1.4-0.5,1.6-1.3
                  l1.1-4.3l3.7,2.3c0.7,0.4,1.5,0.3,2-0.3l4.7-4.8c0.5-0.5,0.6-1.4,0.3-2.1l-2.3-3.8l4.2-1.1c0.7-0.2,1.3-0.9,1.3-1.6V50.8z
                  M67.5,56.3l-4.6,1.2c-0.5,0.1-1,0.5-1.2,1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.5-0.2,1.1,0.1,1.6l2.5,4.2
                  l-2.9,2.9l-4.1-2.5c-0.5-0.3-1-0.3-1.5-0.1L55,65.3c-0.5,0.2-0.9,0.6-1,1.2l-1.2,4.8h-4.1l-1.2-4.7c-0.1-0.5-0.5-1-1-1.2
                  c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.2-1.1-0.2-1.5,0.1l-4.1,2.5l-2.9-2.9l2.5-4.2c0.3-0.5,0.3-1.1,0.1-1.6
                  L40,58.5c-0.2-0.5-0.6-0.9-1.1-1l-4.7-1.2v-4.1l4.7-1.2c0.5-0.1,1-0.5,1.2-1l0.2-0.5c0.2-0.5,0.2-1.1-0.1-1.6l-2.5-4.2l2.9-2.9
                  l4.1,2.5c0.5,0.3,1,0.3,1.5,0.1l0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.5-0.2,0.9-0.6,1-1.2l1.2-4.7h4.1L54,42c0.1,0.5,0.5,1,1,1.2
                  c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.2,1.1,0.2,1.5-0.1l4.1-2.5l2.9,2.9l-2.5,4.2c-0.3,0.5-0.3,1.1-0.1,1.6
                  l0.2,0.5c0.2,0.5,0.6,0.9,1.1,1l4.7,1.2L67.5,56.3z M99.1,81.4c-0.8-8-7-14.4-14.9-15.2V11.9c0-0.9-0.7-1.7-1.7-1.7h-8.3V1.7
                  c0-0.9-0.7-1.7-1.7-1.7h-70C1.6,0,0.8,0.8,0.8,1.7v71.2c0,0.9,0.7,1.7,1.7,1.7h8.3V83c0,0.9,0.7,1.7,1.7,1.7h53.4
                  c0.9,9.3,9.1,16.1,18.3,15.2C93.3,99,100,90.7,99.1,81.4z M80.8,13.6v10.2H14.1V13.6H80.8z M10.8,71.2H4.1V16.9h6.7V71.2z
                  M10.8,11.9v1.7H4.1V3.4h66.7v6.8H12.5C11.6,10.2,10.8,10.9,10.8,11.9z M14.1,81.4V27.1h66.7v39.1C73,67,66.7,73.3,65.9,81.4H14.1
                  z M82.5,96.6c-7.4,0-13.3-6.1-13.3-13.6s6-13.6,13.3-13.6c7.4,0,13.3,6.1,13.3,13.6C95.9,90.5,89.9,96.6,82.5,96.6z M20.8,16.9
                  h-3.3v3.4h3.3V16.9z M27.5,16.9h-3.3v3.4h3.3V16.9z M34.2,16.9h-3.3v3.4h3.3V16.9z M77.5,16.9h-3.3v3.4h3.3V16.9z M84.7,79.2
                  l2.2,2.2h-8.6l2.2-2.2L78,76.8l-5,5.1c-0.7,0.7-0.7,1.7,0,2.4l5,5.1l2.4-2.4l-2.2-2.2h8.6l-2.2,2.2l2.4,2.4l5-5.1
                  c0.7-0.7,0.7-1.7,0-2.4l-5-5.1L84.7,79.2z M50.8,47.5c-3.7,0-6.7,3-6.7,6.8c0,3.7,3,6.8,6.7,6.8s6.7-3,6.7-6.8
                  C57.5,50.5,54.5,47.5,50.8,47.5z M50.8,57.6c-1.8,0-3.3-1.5-3.3-3.4c0-1.9,1.5-3.4,3.3-3.4c1.8,0,3.3,1.5,3.3,3.4
                  C54.2,56.1,52.7,57.6,50.8,57.6z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'Custom CMS Integration',
					text: 'Start with creating an ECMs-enabled solution to establish seamless communication between multiple applications in organisation. This means, with CMS you can weave in Customer Relationship Management (CRM), digital asset management, documentation, and even automating sales and marketing departments.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M95.2,82.7V34.4c0-4.3-3.6-7.8-8.1-7.8h-9.7v-4.7h-4.1c-0.5-1.8-1.3-3.6-2.3-5.3l2.9-2.8L64.8,5l-2.9,2.8
                  c-1.7-1-3.6-1.7-5.5-2.2v-4H43.5v4c-1.9,0.5-3.7,1.2-5.5,2.2L35.2,5L26,13.8l2.9,2.8c-1,1.7-1.7,3.4-2.3,5.3h-4.1v4.7h-9.7
                  c-4.4,0-8.1,3.5-8.1,7.8v48.3H0v7.8c0,4.3,3.6,7.8,8.1,7.8h83.9c4.4,0,8.1-3.5,8.1-7.8v-7.8H95.2z M25.8,25.1h3.4l0.3-1.2
                  c0.5-2.3,1.5-4.5,2.8-6.6l0.7-1.1l-2.4-2.4l4.6-4.4l2.4,2.4l1.1-0.7c2.1-1.3,4.4-2.2,6.8-2.7l1.3-0.3V4.8h6.5v3.3l1.3,0.3
                  c2.4,0.5,4.7,1.4,6.8,2.7l1.1,0.7l2.4-2.4l4.6,4.4l-2.4,2.4l0.7,1.1c1.3,2,2.3,4.2,2.8,6.6l0.3,1.2h3.4v1.6h-6.5
                  c-0.1-1.2-0.4-2.4-0.7-3.6L63.8,24c0.3,0.9,0.4,1.8,0.5,2.7h-3.2c-0.8-5.3-5.5-9.4-11.2-9.4c-5.7,0-10.4,4.1-11.2,9.4h-3.3
                  c0.8-7,7-12.5,14.4-12.5c5.2,0,10,2.7,12.6,7l2.8-1.6C62.2,14.3,56.3,11,50,11c-9.2,0-16.8,6.9-17.7,15.6h-6.5V25.1z M57.9,26.6
                  H42.1c0.7-3.6,4-6.2,7.9-6.2C53.9,20.4,57.2,23.1,57.9,26.6z M8.1,34.4c0-2.6,2.2-4.7,4.8-4.7h74.2c2.7,0,4.8,2.1,4.8,4.7v48.3
                  h-3.2V36c0-1.7-1.4-3.1-3.2-3.1H62.9V36h22.6v46.8h-71V36h22.6v-3.1H14.5c-1.8,0-3.2,1.4-3.2,3.1v46.8H8.1V34.4z M59.7,85.9v1.6
                  c0,0.9-0.7,1.6-1.6,1.6H41.9c-0.9,0-1.6-0.7-1.6-1.6v-1.6H59.7z M96.8,90.5c0,2.6-2.2,4.7-4.8,4.7H8.1c-2.7,0-4.8-2.1-4.8-4.7
                  v-4.7h33.9v1.6c0,2.6,2.2,4.7,4.8,4.7h16.1c2.7,0,4.8-2.1,4.8-4.7v-1.6h33.9V90.5z M14.5,92.1h3.2V89h-3.2V92.1z M21,92.1h3.2V89
                  H21V92.1z M27.4,92.1h3.2V89h-3.2V92.1z M43.5,64c-2.7,0-4.8,2.1-4.8,4.7c0,2.6,2.2,4.7,4.8,4.7c2.7,0,4.8-2.1,4.8-4.7
                  C48.4,66.1,46.2,64,43.5,64z M43.5,70.3c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
                  C45.2,69.6,44.4,70.3,43.5,70.3z M56.5,74.9c2.7,0,4.8-2.1,4.8-4.7s-2.2-4.7-4.8-4.7c-2.7,0-4.8,2.1-4.8,4.7S53.8,74.9,56.5,74.9z
                  M56.5,68.7c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C54.8,69.4,55.6,68.7,56.5,68.7z M43.5,51.1
                  L31.6,69.8c-0.6,1-1,2.2-1,3.4c0,3.5,3,6.4,6.6,6.4h25.4c3.7,0,6.6-2.9,6.6-6.4c0-1.2-0.3-2.4-1-3.4L56.5,51.1v-8.9h3.2v-9.4H40.3
                  v9.4h3.2V51.1z M65.6,71.5c0.3,0.5,0.5,1.1,0.5,1.7c0,1.8-1.5,3.3-3.4,3.3H37.3c-1.9,0-3.4-1.5-3.4-3.3c0-0.6,0.2-1.2,0.5-1.7
                  L43,57.9c1.8,0.2,3.1,0.8,4.7,1.6c2.6,1.3,5.8,2.9,12.2,3L65.6,71.5z M57.8,59.2c-4.2-0.4-6.5-1.5-8.6-2.5
                  c-1.4-0.7-2.7-1.3-4.3-1.7l1.9-3v-9.8h6.5V52L57.8,59.2z M43.5,36h12.9v3.1H43.5V36z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'Custom CMS Integration',
					text: 'Start with creating an ECMs-enabled solution to establish seamless communication between multiple applications in organisation. This means, with CMS you can weave in Customer Relationship Management (CRM), digital asset management, documentation, and even automating sales and marketing departments.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M35.3,76.2v-9.6h21.1v-4.2H35.3c-0.1,0-0.1,0-0.2,0c-2.3,0-4.1,1.9-4,4.2v9.6l-4.8-4.8l-3,2.9l8.4,8.3
                  c0.4,0.4,0.9,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6l8.4-8.3l-3-2.9L35.3,76.2z M65.4,67.3l-8.4,8.3l3,2.9l4.8-4.8v9.6H43.7v4.2h21.1
                  c0.1,0,0.1,0,0.2,0c2.3,0,4.1-1.9,4-4.2v-9.6l4.8,4.8l3-2.9l-8.4-8.3C67.5,66.5,66.2,66.5,65.4,67.3z M43.7,12.5H22.6v4.2h21.1
                  V12.5z M26.8,20.8h-4.2V25h4.2V20.8z M35.3,25v-4.2h-4.2V25H35.3z M89.4,17.3L72.5,0.6C72.1,0.2,71.6,0,71.1,0H16.3
                  C12.8,0,10,2.8,10,6.3v87.5c0,3.5,2.8,6.2,6.3,6.2h67.4c3.5,0,6.3-2.8,6.3-6.2v-75C90,18.2,89.8,17.7,89.4,17.3z M85.8,93.8
                  c0,1.1-0.9,2.1-2.1,2.1H16.3c-1.2,0-2.1-0.9-2.1-2.1V6.3c0-1.2,0.9-2.1,2.1-2.1h52.6v10.4c0,3.5,2.8,6.2,6.3,6.2h4.2v-4.2h-4.2
                  c-1.2,0-2.1-0.9-2.1-2.1V7.1l12.6,12.5V93.8z M73.2,45.8H47.9v-2.1h23.2v-2.1c0-1.2-0.9-2.1-2.1-2.1H47.9c0-2.3-1.9-4.2-4.2-4.2
                  H26.8c-2.3,0-4.2,1.9-4.2,4.2v18.7h4.2V39.6h16.8v6.2h-6.3V50h35.8v8.3h4.2V50C77.4,47.7,75.5,45.8,73.2,45.8z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'CMS Support & Maintenance',
					text: 'A successful CMS is the one that offers value for a long time. This requires 24/7 support from the implementation and support team to address concerns on the go. Have a healthy relationship with technology for time to come. We offer end to end CMS support for your business to function uninterruptedly.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M95.2,90.5c-2.7,0-4.8,2.1-4.8,4.8s2.2,4.8,4.8,4.8c2.7,0,4.8-2.1,4.8-4.8S97.8,90.5,95.2,90.5z M95.2,96.8
                  c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6S96,96.8,95.2,96.8z M9.7,4.8C9.7,2.1,7.5,0,4.8,0
                  C2.2,0,0,2.1,0,4.8c0,2.6,2.2,4.8,4.8,4.8C7.5,9.5,9.7,7.4,9.7,4.8z M3.2,4.8c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
                  c0,0.9-0.7,1.6-1.6,1.6C4,6.3,3.2,5.6,3.2,4.8z M86.6,62.9l-3.2-6.3c0.7-1.3,1.3-2.7,1.7-4.1l6.7-2.2V32.2L85.2,30
                  c-0.5-1.4-1.1-2.7-1.7-4.1l3.2-6.3l-13-12.8l-6.4,3.1c-1.4-0.7-2.8-1.2-4.1-1.7l-2.2-6.6H42.4l-2.2,6.6c-1.4,0.5-2.8,1-4.1,1.7
                  l-6.4-3.1l-13,12.8l3.2,6.3c-0.7,1.3-1.3,2.7-1.7,4.1l-6.7,2.2v18.2l6.7,2.2c0.5,1.3,1,2.7,1.7,4l-2.1,3.7H8.1v31.7h11.3v-4.8
                  h12.2l4.8,4.8h8.7v1.6c0,3.5,2.9,6.3,6.5,6.3s6.5-2.8,6.5-6.3v-1.6h11.3c2.7,0,4.8-2.1,4.8-4.8c0-1.2-0.5-2.3-1.3-3.2
                  c0.8-0.8,1.3-2,1.3-3.2s-0.5-2.3-1.3-3.2c0.6-0.7,1-1.5,1.2-2.4L86.6,62.9z M16.1,88.9h-4.8V63.5h4.8V88.9z M46.1,63.5l-6.9,6.8
                  l2.3,2.2l3.7-3.6v20h-7.4l-4.8-4.8H19.4V66.7h16.8l6.5-6.3h20.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6H46.1z M47.4,47.6
                  l-5.5-5.4v-7.3c0-1.8,1.4-3.2,3.2-3.2v7l2.6,2.5h7.8l2.6-2.5v-7c1.8,0,3.2,1.4,3.2,3.2v7.3l-5.5,5.4H47.4z M54.8,50.8v6.3h-6.5
                  v-6.3H54.8z M58.1,49.9l6.5-6.3v-8.6c0-3.5-2.9-6.3-6.5-6.3h-3.2v8.9l-0.7,0.7h-5.1l-0.7-0.7v-8.9h-3.2c-3.6,0-6.5,2.8-6.5,6.3
                  v8.6l6.5,6.3v7.3h-3.9l-0.1,0.1c-5.5-3.5-8.9-9.5-8.9-16c0-10.5,8.7-19,19.4-19c10.7,0,19.4,8.5,19.4,19c0,6.4-3.3,12.3-8.7,15.9
                  h-4.2V49.9z M74.2,41.3C74.2,29,64.1,19,51.6,19C39.2,19,29,29,29,41.3c0,7.3,3.7,14.2,9.8,18.3l-2.3,2.3
                  c-6.7-4.8-10.7-12.4-10.7-20.6c0-14,11.6-25.4,25.8-25.4c14.2,0,25.8,11.4,25.8,25.4c0,7.8-3.6,15.1-9.8,19.9
                  c-0.2-1.1-0.8-2.1-1.6-2.8C71.2,54.1,74.2,47.9,74.2,41.3z M54.8,93.7c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2v-27h3.5
                  c-0.2,0.5-0.3,1-0.3,1.6c0,1.2,0.5,2.3,1.3,3.2c-0.8,0.8-1.3,2-1.3,3.2c0,1.2,0.5,2.3,1.3,3.2c-0.8,0.8-1.3,2-1.3,3.2
                  s0.5,2.3,1.3,3.2c-0.8,0.8-1.3,2-1.3,3.2c0,2.1,1.4,3.8,3.2,4.5V93.7z M69.4,88.9H56.5c-0.9,0-1.6-0.7-1.6-1.6
                  c0-0.9,0.7-1.6,1.6-1.6h12.9c0.9,0,1.6,0.7,1.6,1.6C71,88.2,70.2,88.9,69.4,88.9z M69.4,82.5H56.5c-0.9,0-1.6-0.7-1.6-1.6
                  s0.7-1.6,1.6-1.6h12.9c0.9,0,1.6,0.7,1.6,1.6S70.2,82.5,69.4,82.5z M69.4,76.2H56.5c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
                  h12.9c0.9,0,1.6,0.7,1.6,1.6C71,75.5,70.2,76.2,69.4,76.2z M69.4,69.8H56.5c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h12.9
                  c0.9,0,1.6,0.7,1.6,1.6S70.2,69.8,69.4,69.8z M72.9,71.4c0.8-0.8,1.3-2,1.3-3.2c0-2.5-1.9-4.5-4.4-4.7
                  c6.8-5.4,10.8-13.5,10.8-22.3c0-15.8-13-28.6-29-28.6c-16,0-29,12.8-29,28.6c0,8.7,4,16.8,10.8,22.2H19.6l3.8-6.8L23,55.9
                  c-0.9-1.7-1.6-3.4-2.2-5.1l-0.2-0.8l-6.1-2V34.5l6.1-2l0.2-0.8c0.5-1.7,1.3-3.4,2.2-5.1l0.4-0.7l-2.9-5.7l9.8-9.6l5.8,2.8l0.7-0.4
                  c1.7-0.9,3.4-1.6,5.1-2.1l0.8-0.2l2-6h13.8l2,6l0.8,0.2c1.7,0.5,3.4,1.2,5.1,2.1l0.7,0.4l5.8-2.8l9.8,9.6l-2.9,5.7l0.4,0.7
                  c0.9,1.7,1.6,3.4,2.2,5.1l0.2,0.8l6.1,2v13.6l-6.1,2l-0.2,0.8c-0.5,1.7-1.3,3.4-2.2,5.1l-0.4,0.7l2.9,5.7l-9.5,9.5
                  C73.1,71.6,73,71.5,72.9,71.4z M3.2,17.5h3.2v-3.2H3.2V17.5z M3.2,25.4h3.2v-3.2H3.2V25.4z M3.2,33.3h3.2v-3.2H3.2V33.3z
                  M96.8,66.7h-3.2v3.2h3.2V66.7z M96.8,74.6h-3.2v3.2h3.2V74.6z M96.8,82.5h-3.2v3.2h3.2V82.5z"></path>
                </g>
              </g>
            </svg>
          `
				},
        {
					title: 'eCommerce CMS Development',
					text: 'An ideal eCommerce CMS development goes beyond your typical website with more emphasis on custom CMS development. Our CMS services cater to this with designing responsive and optimised CRM solution for it to function the way you want. Tap the powerhouse of ecommerce content with scalable and agile CMS for ecommerce.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M95.8,5H4.2C1.8,5,0,6.6,0,8.8v82.4C0,93.4,1.8,95,4.2,95h91.5c2.3,0,4.2-1.6,4.2-3.8V8.8C100,6.6,98.2,5,95.8,5z
                  M97,91.2c0,0.5-0.6,1.1-1.2,1.1H4.2c-0.6,0-1.2-0.5-1.2-1.1V26.5H97V91.2z M97,23.8H3v-15c0-0.5,0.6-1.1,1.2-1.1h91.5
                  c0.6,0,1.2,0.5,1.2,1.1V23.8z M12.7,10c-3.5,0-6.4,2.6-6.4,5.7c0,3.1,2.9,5.7,6.4,5.7c3.5,0,6.4-2.6,6.4-5.7
                  C19.1,12.6,16.2,10,12.7,10z M12.7,18.6c-1.8,0-3.3-1.4-3.3-3c0-1.6,1.5-3,3.3-3c1.8,0,3.3,1.4,3.3,3
                  C16.1,17.3,14.5,18.6,12.7,18.6z M28,10c-3.5,0-6.4,2.6-6.4,5.7c0,3.1,2.9,5.7,6.4,5.7c3.5,0,6.4-2.6,6.4-5.7
                  C34.4,12.6,31.5,10,28,10z M28,18.6c-1.8,0-3.3-1.4-3.3-3c0-1.6,1.5-3,3.3-3c1.8,0,3.3,1.4,3.3,3C31.4,17.3,29.8,18.6,28,18.6z
                  M43.5,10c-3.5,0-6.4,2.6-6.4,5.7c0,3.1,2.7,5.7,6.4,5.7c3.5,0,6.4-2.6,6.4-5.7C49.8,12.6,47,10,43.5,10z M43.5,18.6
                  c-1.8,0-3.3-1.4-3.3-3c0-1.6,1.4-3,3.3-3c1.8,0,3.3,1.4,3.3,3C46.8,17.3,45.3,18.6,43.5,18.6z M91.7,10.5H55
                  c-0.9,0-1.5,0.5-1.5,1.4v7.6c0,0.8,0.6,1.5,1.5,1.5h36.7c0.9,0,1.5-0.5,1.5-1.4v-7.8C93.2,11,92.6,10.5,91.7,10.5z M90.2,18.1
                  H56.5v-4.9h33.6V18.1z M12.1,87.6h27.1c1.7,0,3-0.5,4.2-1.6c1.2-1.1,1.8-2.7,1.8-4.4l-1.5-26.2c-0.3-0.7-0.9-1.2-1.8-1.2h-4.5
                  v-2.5c0-6.7-5.5-12-12-12s-12,5.5-12,12v2.5H8.9c-0.8,0-1.5,0.5-1.5,1.2L6.1,81.6c-0.2,1.6,0.6,3.3,1.8,4.4
                  C8.9,87.1,10.5,87.6,12.1,87.6z M16.7,51.8c0-5.2,3.9-9.3,8.9-9.3c4.8,0,8.9,4.1,8.9,9.3v2.5H16.7V51.8z M10.6,57h3v2.6H13
                  c-0.9,0-1.5,0.5-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4h4.5c0.9,0,1.5-0.5,1.5-1.4c0-0.8-0.6-1.4-1.5-1.4h-0.9V57h17.7v2.6h-0.8
                  c-0.9,0-1.5,0.5-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4h4.5c0.9,0,1.5-0.5,1.5-1.4c0-0.8-0.6-1.4-1.5-1.4h-0.8V57h3L42,81.8
                  c0.2,1-0.2,1.8-0.8,2.5c-0.6,0.5-1.2,0.8-2,0.8H12.1c-0.8,0-1.5-0.3-2-0.8c-0.8-0.7-1.1-1.5-1.1-2.5L10.6,57z M15.2,72.4h20.8
                  c0.9,0,1.5-0.5,1.5-1.4c0.2-0.8-0.6-1.4-1.4-1.4H15.2c-0.9,0-1.5,0.5-1.5,1.4C13.6,71.8,14.2,72.4,15.2,72.4z M15.2,80h20.8
                  c0.9,0,1.5-0.5,1.5-1.4c0.2-0.8-0.6-1.4-1.4-1.4H15.2c-0.9,0-1.5,0.5-1.5,1.4C13.6,79.5,14.2,80,15.2,80z M49.7,60
                  c0.2,0.5,0.8,1,1.5,1h30.2c0.8,0,1.4-0.4,1.5-1l8-28.1h2.9c0.9,0,1.5-0.5,1.5-1.4c0-0.7-0.8-1.4-1.5-1.4h-3.9
                  c-0.8,0-1.4,0.4-1.5,1l-3.9,13.5H46.8c-1.1,0-1.7,1-1.5,1.8L49.7,60z M83.6,46.5l-3.5,11.9H52.4l-3.6-11.9H83.6z M63.6,66.9
                  c0-2.5-2.3-4.5-5-4.5c-2.7,0-5,2-5,4.5c0,2.5,2.3,4.5,5,4.5C61.5,71.4,63.6,69.4,63.6,66.9z M56.8,66.9c0-1,0.9-1.8,2-1.8
                  c1.1,0,2,0.8,2,1.8c0,1-0.9,1.8-2,1.8C57.7,68.7,56.8,67.9,56.8,66.9z M81.7,66.9c0-2.5-2.3-4.5-5-4.5c-2.7,0-5,2-5,4.5
                  c0,2.5,2.3,4.5,5,4.5C79.4,71.4,81.7,69.4,81.7,66.9z M74.8,66.9c0-1,0.9-1.8,2-1.8c1.1,0,2,0.8,2,1.8c0,1-0.9,1.8-2,1.8
                  C75.6,68.7,74.8,67.9,74.8,66.9z M90.6,75.6H51.2c-0.9,0-1.5,0.5-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4h39.4c0.9,0,1.5-0.5,1.5-1.4
                  C92.1,76.2,91.4,75.6,90.6,75.6z M90.6,84.2H51.2c-0.9,0-1.5,0.5-1.5,1.4c0,0.8,0.6,1.4,1.5,1.4h39.4c0.9,0,1.5-0.5,1.5-1.4
                  C92.1,84.8,91.4,84.2,90.6,84.2z"></path>
                </g>
              </g>
            </svg>
          `
				},
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
