<template>
	<section class="padding-60 bg-light car-wash-feature-main">
		<div class="container">
			<div class="title">
				<span>Sets of Features</span>
				<h2 class="fxt-tech-use">The Main Features Of Our Taxi App Development Services</h2>
			</div>
			<ul class="nav nav-tabs car-wash-feature-tab" id="technologies_tabs" role="tablist">
				<li>
					<a
						class="active"
						id="mobile-technologies-tab"
						data-toggle="tab"
						href="#mobile-technologies"
						role="tab"
						aria-controls="mobile-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M29.9,17.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C28.9,17.1,29.3,17.6,29.9,17.6z M20.1,15.6
                      c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C21.1,16.1,20.7,15.6,20.1,15.6z M28,23c-0.2-0.5-0.8-0.7-1.3-0.5
                      c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3c0.8,0.4,1.7,0.6,2.6,0.6
                      c0.9,0,1.8-0.2,2.6-0.6C28,24.1,28.3,23.5,28,23z M35.2,29.8l-2.7-2.4c3.3-2.4,5.2-6.2,5.2-10.3v-6.4C37.7,4.8,32.9,0,27,0h-3.9
                      c-5.9,0-10.7,4.8-10.7,10.7v6.4c0,4.1,1.9,7.9,5.2,10.2l-2.7,2.4C9.6,30,3.5,34.2,3.5,39.6V49c0,0.5,0.4,1,1,1h41.1
                      c0.5,0,1-0.4,1-1v-9.4C46.5,34.3,40.4,30,35.2,29.8z M33.6,31.1l-3.2,7.2l-4-2l4.7-7.5L33.6,31.1z M25,37.8l3,1.5l-0.8,2.3h-4.5
                      L22,39.3L25,37.8z M25,34.8l-3.4-5.4c1.1,0.3,2.2,0.5,3.4,0.5c1.1,0,2.3-0.2,3.4-0.5L25,34.8z M14.3,10.7c0-4.8,3.9-8.8,8.7-8.8
                      H27c4.9,0,8.8,3.9,8.8,8.8v0.8l-1.8-2.6c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3c-2,2-4.3,2.9-7.4,2.9
                      c-3.2,0-5.5-0.9-7.4-2.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.7,0.4l-1.7,2.6V10.7z M14.3,17.2v-2.3l2.7-4
                      c2.2,1.8,4.7,2.7,8,2.7c3.2,0,5.8-0.9,8-2.7l2.8,4v2.2C35.8,23,31,27.9,25,27.9C19,27.9,14.3,23.1,14.3,17.2z M18.9,28.8l4.7,7.5
                      l-4,2l-3.2-7.2L18.9,28.8z M19.8,48H5.4v-8.4c0-4,4.9-7.5,9.1-7.8l3.6,8.2c0.1,0.2,0.3,0.4,0.6,0.5s0.5,0.1,0.8,0l0.7-0.3l0.8,2.5
                      L19.8,48z M21.8,48l1.1-4.5h4.3l1,4.5H21.8z M44.5,48H30.2l-1.2-5.4l0.8-2.5l0.6,0.3c0.2,0.1,0.5,0.1,0.8,0
                      c0.3-0.1,0.5-0.3,0.6-0.5l3.6-8.2c4.3,0.3,9.1,3.8,9.1,7.8V48z M37.7,43.6h-3.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3.9
                      c0.5,0,1-0.4,1-1C38.6,44,38.2,43.6,37.7,43.6z M40.5,44.5c0,0.5,0.4,1,1,1s1-0.4,1-1c0-0.5-0.4-1-1-1S40.5,44,40.5,44.5z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>User</span>
					</a>
				</li>
				<li>
					<a
						id="web-technologies-tab"
						data-toggle="tab"
						href="#web-technologies"
						role="tab"
						aria-controls="web-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M99.4,64.5c-0.1-3.2-2.7-5.8-6-5.8H77.5c-1.1-2.2-3.1-3.8-6.1-4.6l-0.1,0c-6.8-1.3-11.6-1.5-17.1-0.5V41.7
                      c5.3-1.8,9.1-6.7,9.1-12.5c0-7.3-6-13.2-13.4-13.2c-7.4,0-13.4,5.9-13.4,13.2c0,2.1,0.5,4,1.4,5.8l0,0c-2.1,0.4-4.2,0.6-6.2,0.6
                      c-4.9,0-9.2-1.3-12.6-4c-0.1-0.1-0.2-0.2-0.3-0.3l0.1-0.4c0.4-3.2-0.6-7-3.4-8.6c0.7,0.4,1.5,0.7,2.3,0.9c0.7,0.2,1.5,0.3,2.2,0.3
                      c4.5,0,8.5-3,9.6-7.5c0.6-2.5,0.2-5.1-1.2-7.3c-1.4-2.2-3.6-3.7-6.1-4.3c-2.6-0.6-5.2-0.2-7.4,1.2c-2.2,1.4-3.8,3.5-4.4,6
                      c-1,4.4,1.1,8.7,4.9,10.7c-0.4-0.2-0.8-0.4-1.2-0.5c-3.9-1-9.3,1.2-11.4,7.3C0,37-0.5,42.3,0.5,50.7l0,0.1
                      c0.7,3.7,2.6,6.1,5.6,7.1c0.9,3.2,2,8.1,2,13.8c0,5.6-1,11.1-2.9,16.3C4,91,5.6,94.5,8.7,95.6c0.7,0.2,1.4,0.4,2.1,0.4
                      c2.5,0,4.7-1.6,5.6-3.9c2.4-6.5,3.7-13.3,3.7-20.4c0-9.5-2.2-16.8-3.5-20.4c-0.5-3.8,0-8,0.5-11c3.4,1.9,8.3,3.7,14.7,3.7
                      c2.5,0,5.1-0.3,7.8-0.8c1.5-0.3,2.8-1.4,3.2-2.9c0.9,0.6,1.9,1.1,3,1.4v14.5l-0.3,0.2c-1.8,1.2-3,2.8-3.7,4.6c0,0,0-0.1,0-0.1
                      c-0.4-5.3-5.2-9.4-10.6-8.9c-5.4,0.4-9.4,5.1-9,10.4c0.4,5.1,4.7,9,9.8,9c0.3,0,0.5,0,0.8,0c4-0.3,7.2-2.9,8.5-6.4
                      c0,0.1,0,0.3,0.1,0.4c0.3,1.9,1.6,3.5,3.7,4.5c1.8,0.9,4,1.2,6.1,0.9h36.6l0.5,19.6c0.1,3.2,2.8,5.8,6,5.8l0.1,0
                      c3.3-0.1,5.9-2.8,5.8-6.1L99.4,64.5z M32.4,69c-4.1,0.3-7.7-2.7-8-6.8c-0.3-4.1,2.8-7.6,6.9-8c0.2,0,0.4,0,0.6,0
                      c3.9,0,7.1,3,7.5,6.8C39.6,65.1,36.5,68.6,32.4,69z M12.7,12.1c0.5-1.9,1.6-3.6,3.3-4.6c1.7-1,3.7-1.4,5.6-0.9
                      c1.9,0.4,3.6,1.6,4.7,3.3c1.1,1.7,1.4,3.7,0.9,5.6c-0.9,4-5,6.5-9,5.5C14.2,20,11.7,16.1,12.7,12.1z M17.7,71.7
                      c0,6.8-1.2,13.3-3.5,19.6c-0.5,1.4-1.9,2.3-3.4,2.3c-0.4,0-0.9-0.1-1.3-0.2c-1.9-0.7-2.8-2.8-2.1-4.6c2-5.4,3.1-11.2,3.1-17.1
                      c0-6.4-1.2-11.7-2.2-15.1L8,56l-0.6-0.2c-1.6-0.4-3.8-1.4-4.6-5.4c-1-8.2-0.5-13,2.1-20.6c1.6-4.7,5.6-6.5,8.6-5.8
                      c1.4,0.3,2.4,1.6,2.8,3.2C15.4,25.9,13.8,25,12,25c-0.8,0-1.7,0.2-2.4,0.6c-1.2,0.6-2.1,1.7-2.5,3c-0.4,1.3-0.2,2.7,0.4,3.8l0,0
                      c0.4,0.8,2.2,3.6,5.8,6.4c0.4,0.3,0.9,0.7,1.4,1c-0.5,3.2-1.1,7.7-0.5,11.9l0.1,0.2C15.5,55.4,17.7,62.5,17.7,71.7z M39,40.9
                      c-2.5,0.5-5,0.7-7.4,0.7c-7.9,0-13.2-2.9-16.3-5.2c-3.2-2.5-4.7-5-5-5.4c-0.2-0.4-0.3-1-0.2-1.4c0.1-0.5,0.5-0.9,0.9-1.1
                      c0.3-0.2,0.6-0.2,0.9-0.2c0.7,0,1.3,0.4,1.7,1c0,0,0.1,0.1,0.2,0.2c0.1,0.2,0.4,0.5,0.7,1c0.6,0.8,1.6,1.9,3.1,3
                      c3.8,2.9,8.5,4.4,14,4.4c1.9,0,3.9-0.2,5.9-0.5c0.7,1.1,1.6,2,2.6,2.9C39.9,40.5,39.5,40.8,39,40.9z M38.9,29.1
                      c0-6,4.9-10.9,11-10.9c6.1,0,11,4.9,11,10.9c0,6-4.9,10.9-11,10.9C43.8,40.1,38.9,35.2,38.9,29.1z M51.8,43.7v18.5
                      c0,1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.8-1.9-1.9V43.7C49.3,43.8,50.6,43.8,51.8,43.7z M94.1,93.6L94.1,93.6c-2,0-3.7-1.6-3.7-3.5
                      l-0.5-21.9H50.7l-0.1,0c-1.7,0.3-3.4,0-4.8-0.6c-0.8-0.4-2.2-1.2-2.4-2.8c-0.2-1.4,0.2-3.3,1.4-4.9v2.1c0,2.8,2.3,5,5.1,5
                      c2.8,0,5.1-2.3,5.1-5v-6.3c5.1-0.8,9.4-0.7,15.8,0.6c2.6,0.7,4.2,2,4.8,3.9l0.3,0.8h17.5c2,0,3.6,1.5,3.6,3.5L97.7,90
                      C97.7,91.9,96.1,93.6,94.1,93.6z M21.8,78.6h64.8v-7.3H21.8V78.6z M24.2,73.7h60.1v2.6H24.2V73.7z M55.9,29.1c0-3.3-2.7-5.9-6-5.9
                      c-3.3,0-6,2.7-6,5.9c0,3.3,2.7,5.9,6,5.9C53.2,35.1,55.9,32.4,55.9,29.1z M47.5,29.1c0-1.3,1.1-2.4,2.5-2.4c1.4,0,2.5,1.1,2.5,2.4
                      c0,1.3-1.1,2.4-2.5,2.4C48.6,31.6,47.5,30.5,47.5,29.1z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>Personal Trainer</span>
					</a>
				</li>
				<li>
					<a
						id="admin-panel-tab"
						data-toggle="tab"
						href="#admin-panel"
						role="tab"
						aria-controls="admin-panel"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M65.4,37.9L65.4,37.9c0.3-0.9-0.2-1.9-1.2-2.1c-0.9-0.3-1.9,0.2-2.2,1.2c-0.5,1.5-2,2.3-3.4,1.8c-0.8-0.3-1.5-0.9-1.8-1.7
                      c-0.4-0.9-1.5-1.2-2.3-0.8c-0.7,0.4-1.1,1.2-0.9,2c1.2,3.2,4.8,4.9,8,3.7C63.4,41.2,64.8,39.7,65.4,37.9z M51.6,26.6
                      c-1.6,0-2.8,1.2-2.8,2.8c0,1.6,1.2,2.8,2.8,2.8h0c1.5,0,2.8-1.3,2.8-2.8C54.4,27.8,53.1,26.6,51.6,26.6z M66.5,32.2
                      c1.6,0,2.8-1.2,2.8-2.8c0-1.6-1.2-2.8-2.8-2.8c-1.6,0-2.8,1.2-2.8,2.8v0C63.7,30.9,64.9,32.2,66.5,32.2z M75.1,56.7
                      c-0.2-0.2-0.5-0.4-0.8-0.5h-0.1c-4.6-2.5-4.2-7.9-3.9-9.7c2-1.1,3.8-2.5,5.3-4.2c1,1.7,2.2,3.2,3.7,4.5c0.3,0.3,0.7,0.4,1.2,0.4
                      c0.4,0,0.8-0.1,1.2-0.4c0.7-0.5,0.8-1.5,0.4-2.2c-1.1-2.1-1.1-4.6,0-6.8c1.8-3.7,5.7-17.5,1.6-27.1c-1.7-4.2-5.3-7.5-9.7-8.7
                      C72.8,1.9,51-4,40.1,4.9L40,5c-0.4,0.4-10.4,10.1-4.6,26c0,0.1,0,0.1,0.1,0.2c2.2,4.5,4.2,11.3,2.1,12.6c-0.8,0.5-1.2,1.5-0.7,2.3
                      c0.4,0.8,1.5,1.2,2.3,0.7c2.1-1.2,3.8-3,4.8-5.2c1.7,2,3.7,3.7,6,5c0.3,1.8,0.8,7.4-4,9.9c-1.3,0.4-2.5,0.9-3.7,1.5v-0.3
                      c0-1-0.8-1.7-1.7-1.7h-5.9c-1.1-3-4.8-5.2-9.4-5.2c-4.6,0-8.4,2.2-9.4,5.2H7.8c-1,0-1.7,0.8-1.7,1.7v40.7c0,1,0.8,1.7,1.7,1.7H89
                      c2.7,0,4.9-2.2,4.9-4.9V83.7C94,71.7,86.4,60.8,75.1,56.7z M25.1,54.1L25.1,54.1c3.7,0,6.3,1.8,6.3,3.5c0,1.6-2.6,3.5-6.3,3.5
                      s-6.4-1.8-6.4-3.5C18.8,55.9,21.4,54.1,25.1,54.1z M38.7,96.5H9.6V59.3h6.1v0.1c0.1,0.3,0.3,0.7,0.5,1l0.1,0.1
                      c0.2,0.3,0.4,0.6,0.6,0.8l0.1,0.1c0.3,0.3,0.5,0.5,0.8,0.8l0.2,0.1c0.3,0.2,0.7,0.5,1,0.7l0.2,0.1c0.4,0.2,0.8,0.4,1.2,0.5h0.1
                      c0.4,0.2,0.9,0.3,1.3,0.4l0.3,0.1c0.4,0.1,0.9,0.2,1.4,0.3h0.3c1,0.1,2,0.1,3.1,0h0.3c0.5-0.1,0.9-0.2,1.4-0.3l0.3-0.1
                      c0.4-0.1,0.9-0.2,1.3-0.4H30c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1c0.3-0.2,0.7-0.4,1-0.7l0.2-0.1c0.3-0.2,0.6-0.5,0.9-0.8l0.1-0.1
                      c0.2-0.3,0.4-0.5,0.6-0.8l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0,0,0-0.1,0-0.1h4.2V96.5z M43.7,18.3l-0.3-0.2c-0.6-0.4-1.4-0.4-2,0.1
                      c-0.6,0.4-0.9,1.2-0.6,1.9l0.2,0.7c2,6.7,2.3,12.4,1,16.7c-0.1,0.3-0.2,0.7-0.3,1c-0.6-3.1-1.7-6-3-8.8c-4.7-13.2,3-21.4,3.7-22.1
                      C51.8,0,72,5.3,72.8,5.5c3.4,1,6.2,3.5,7.6,6.8c3.7,8.6-0.1,21.3-1.5,24.1c-0.4,0.9-0.8,1.9-1,2.9c-0.1-0.2-0.2-0.5-0.3-0.8
                      c-3.3-8.2-0.5-18.3-0.5-18.5c0.3-0.9-0.2-1.8-1-2.1h-0.2c-0.1,0-0.2-0.1-0.4-0.1h-0.4c-0.2,0-0.3,0.1-0.4,0.1
                      C58.4,25.9,46,19.6,43.7,18.3z M49.5,42.1c-1.6-1.2-2.9-2.7-4-4.3h0l0.1-0.5c0-0.2,0.1-0.5,0.2-0.7c0-0.2,0.1-0.6,0.2-0.9
                      c0-0.3,0.1-0.5,0.1-0.8c0-0.3,0.1-0.7,0.1-1c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0-0.7,0-1.1v-2.7c0-0.4-0.1-0.8-0.1-1.3
                      c0-0.3,0-0.5-0.1-0.8c-0.1-0.5-0.2-1-0.2-1.5l-0.1-0.6c-0.1-0.7-0.3-1.4-0.5-2.2c9,3.5,19,3.4,27.9-0.3c-0.1,0.5-0.2,1-0.2,1.5
                      v0.1c-0.1,0.5-0.1,1.1-0.2,1.6V26c0,0.6-0.1,1.2-0.1,1.7c0,0.1,0,0.2,0,0.2v2.1c0,0.6,0,1.3,0.1,1.9c0,0.1,0,0.2,0,0.3
                      c0,0.6,0.1,1.3,0.2,2v0.3c0.1,0.7,0.2,1.3,0.4,2c0,0.1,0,0.2,0.1,0.3c0.2,0.7,0.4,1.3,0.6,2c0,0,0,0,0,0.1
                      C68.1,46.6,57.1,48,49.5,42.1z M53.7,48.1L53.7,48.1c4.2,1.4,8.8,1.4,13,0c-0.1,3.6,0.9,8.2,5.3,10.8L69,72.4
                      c-1.2,0.2-2.5,0.3-3.8,0.3H54.8c-1.1,0-2.2-0.1-3.3-0.2l-2.9-13.7C52.8,56.2,53.8,51.6,53.7,48.1z M42.2,96.5V61.8
                      c1-0.6,2.1-1.1,3.2-1.5l7.7,36.2H42.2z M56.6,96.5l-4.3-20.4c0.8,0.1,1.7,0.1,2.5,0.1h10.5c1,0,2-0.1,3-0.2l-4.4,20.5H56.6z
                      M90.4,95.1c0,0.8-0.7,1.5-1.5,1.5H67.4l7.7-36.1c9.3,3.9,15.4,13.1,15.4,23.3V95.1z M27,72.4c-0.6-0.1-1.2,0-1.7,0.4
                      c-0.5-6.9-3.8-7.8-4.2-7.9c-0.6-0.1-1.2,0.3-1.3,0.9c-0.2,0.6,0.2,1.2,0.8,1.4c0.5,0.2,0.8,0.6,1.1,1c-1.7-0.7-3.7-0.1-4.9,1.3
                      c-0.4,0.4-0.4,1,0,1.5c0.7,0.9,1.8,1.4,3,1.5H20c1,0,2.1-0.5,2.8-1.2c0.1,0.4,0.1,0.9,0.2,1.5c-0.6-0.4-1.2-0.5-1.9-0.3
                      c-5.3,1-8.5,4.6-8.5,9.6c0,5.6,3.8,11.3,8.6,12.9c1,0.3,2,0.1,2.8-0.6l0.2-0.1h0.1c0.6,0.5,1.3,0.8,2,0.8c0.2,0,0.5,0,0.7-0.1
                      c4.9-1.5,8.9-7.4,8.9-13C35.9,76.9,32.6,73.3,27,72.4z M26.4,91.6c-0.6-0.5-1.3-0.8-2.1-0.8c-0.7,0-1.5,0.3-2.1,0.7
                      c0,0-0.1,0.1-0.2,0.1c-3.2-1.4-5.9-5.6-5.9-9.5c0-4.2,3.2-5.6,5.3-6.1l0.3,0.3c0.7,0.7,1.6,1.2,2.5,1.2c1-0.1,1.9-0.6,2.5-1.3
                      L27,76c2.2,0.5,5.5,1.9,5.5,6.1C32.4,86,29.7,90.3,26.4,91.6z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>Dietitian</span>
					</a>
				</li>
				<li>
					<a
						id="cloud-tab"
						data-toggle="tab"
						href="#cloud"
						role="tab"
						aria-controls="cloud"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M32.3,43.5H29c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5h-3.2c0,1.8-1.4,3.2-3.2,3.2C33.7,46.8,32.3,45.3,32.3,43.5z
                      M27.4,32.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C29,33,28.3,32.3,27.4,32.3z M43.5,35.5
                      c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6C41.9,34.8,42.7,35.5,43.5,35.5z M86.2,17.7H59.5
                      c-0.7-3.7-3.3-6.7-6.9-7.8C48.4,3.7,41.4,0,33.9,0C21.4,0,11.3,10.2,11.3,22.6v18.2c0,6.1-3.4,11.5-8.8,14.2l-1.4,0.7l0.7,1.4
                      c0.1,0.2,2.2,4.3,7.7,6.2L7.7,64C3.1,65.5,0,69.9,0,74.7V100h71V85.5h29V31.6L86.2,17.7z M87.1,23.2l7.4,7.4h-7.4V23.2z M65.4,57
                      c-1.4,1.6-4.5,4.2-10.3,4.3l-8.3-2.8V54c2.8-2.3,4.8-5.4,5.8-8.9c1.5-0.2,2.9-0.8,4.1-1.7C57.4,49,60.6,54,65.4,57z M38.5,26.7
                      c-0.5-1-0.7-2.1-0.6-3.1c3.6,2.4,7.7,4.1,12,5.2v11.6c0,8-6.5,14.5-14.5,14.5c-8,0-14.5-6.5-14.5-14.5V24
                      c3.8,2.3,8.1,3.9,12.6,4.5l6.4,0.9L38.5,26.7z M43.5,56.1v2.8l-8.1,6.7l-8.1-6.7v-2.8c2.4,1.2,5.2,2,8.1,2
                      C38.4,58.1,41.1,57.3,43.5,56.1z M25.5,61.5l7.6,6.4l-8.3,8.3l-5.1-12.7L25.5,61.5z M35.5,70l3.9,3.9l-1.7,3.5h-4.5L31.5,74
                      L35.5,70z M37.9,67.8l7.6-6.4l5.8,1.9l-5.1,12.7L37.9,67.8z M53.2,41.7c0-0.4,0.1-0.9,0.1-1.3v-7.8c1.9,0.7,3.2,2.4,3.2,4.5
                      C56.5,39.2,55.1,41,53.2,41.7z M14.5,22.6c0-10.7,8.7-19.4,19.4-19.4c6.5,0,12.5,3.2,16.1,8.6l0.6,0.8l0.6,0.2
                      c3.1,0.8,5.2,3.5,5.2,6.7v11.1c-0.9-0.7-2-1.2-3.2-1.5v-3.1l-1.5-0.3c-4.9-1-9.7-3.1-13.7-6.1l-1.5-1.2l-0.9,1.7
                      c-0.8,1.6-1.1,3.5-0.8,5.2L34,25.3c-5-0.7-9.7-2.7-13.7-5.7l-2.6-1.9v11.5c-1.2,0.2-2.3,0.8-3.2,1.5V22.6z M17.7,32.6v7.8
                      c0,0.5,0,0.9,0.1,1.3c-1.9-0.6-3.3-2.4-3.3-4.6C14.5,35,15.9,33.2,17.7,32.6z M5.6,57c4.8-3,8-8,8.8-13.6c1.1,0.9,2.5,1.5,4.1,1.7
                      c1,3.5,3,6.7,5.8,8.9v4.5l-8.3,2.8C10.1,61.2,6.9,58.6,5.6,57z M27.7,96.8H16.1V83.9h-3.2v12.9H3.2V74.7c0-3.5,2.2-6.6,5.5-7.7
                      l7.9-2.6l7,17.5l5.5-5.5l1.4,2.9L27.7,96.8z M30.9,96.8l2.7-16.1h3.7L40,96.8H30.9z M67.7,96.8h-9.7V83.9h-3.2v12.9H43.3
                      l-2.9-17.5l1.4-2.9l5.5,5.5l7-17.5l7.9,2.6c3.3,1.1,5.5,4.2,5.5,7.7V96.8z M96.8,82.3H71v-7.5c0-1.3-0.2-2.6-0.7-3.7h23.2v-3.2
                      h-3.2V56.5h-3.2v11.3h-3.2V53.2h-3.2v14.5h-3.2v-8.1h-3.2v8.1h-5.7c-1.3-1.7-3.1-3-5.3-3.7l-1.8-0.6c5.5-2,7.6-6,7.7-6.2l0.7-1.4
                      L68.5,55c-5.4-2.7-8.8-8.2-8.8-14.2V21h24.2v12.9h12.9V82.3z M93.5,37.1h-3.2v3.2h3.2V37.1z M62.9,40.3h24.2v-3.2H62.9V40.3z
                      M62.9,46.8h30.6v-3.2H62.9V46.8z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>Admin</span>
					</a>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade show active fxt-tab-pane"
					id="mobile-technologies"
					role="tabpanel"
					aria-labelledby="mobile-technologies-tab"
				>
					<RoundedCardContainer :items="items1" :className="'health-user'"></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="web-technologies"
					role="tabpanel"
					aria-labelledby="web-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items2"
						v-bind:className="'health-personal'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="admin-panel"
					role="tabpanel"
					aria-labelledby="admin-panel-tab"
				>
					<RoundedCardContainer
						v-bind:items="items3"
						v-bind:className="'health-dietitian'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="cloud"
					role="tabpanel"
					aria-labelledby="cloud-tab"
				>
					<RoundedCardContainer
						v-bind:items="items4"
						v-bind:className="'health-admin'"
					></RoundedCardContainer>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RoundedCardContainer from '../AssetComponents/RoundedCardContainer.vue';
export default {
	components: {
		RoundedCardContainer
	},
	data() {
		return {
			items1: [
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<path
		d="M100,50c0-27.6-22.4-50-50-50S0,22.4,0,50c0,10.2,3.1,19.8,8.4,27.7l0,0l0.3,0.5c0.3,0.4,0.6,0.9,0.9,1.3
                    c0.1,0.2,0.3,0.3,0.4,0.5c0.4,0.5,0.8,1,1.2,1.6c0.1,0.1,0.2,0.3,0.4,0.4c1.9,2.3,4,4.4,6.3,6.3c0.1,0.1,0.3,0.2,0.4,0.3
                    c0.5,0.4,1,0.8,1.5,1.2c0.2,0.2,0.4,0.3,0.6,0.5c0.5,0.4,1,0.7,1.5,1c0.3,0.2,0.5,0.4,0.8,0.5c0.7,0.4,1.4,0.9,2.1,1.3
                    c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.6,0.3,0.9,0.5c0.5,0.3,1.1,0.5,1.6,0.8c0.3,0.1,0.6,0.3,0.8,0.4c0.6,0.3,1.3,0.6,1.9,0.8
                    c0.2,0.1,0.4,0.2,0.6,0.2c1.8,0.7,3.6,1.3,5.5,1.7c0.1,0,0.2,0,0.2,0.1c0.8,0.2,1.7,0.4,2.5,0.5c0.2,0,0.5,0.1,0.7,0.1
                    c0,0,0.1,0,0.1,0l0,0l0.9,0.1c0.2,0,0.4,0.1,0.6,0.1l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.8,0.1
                    c0.7,0.1,1.3,0.1,2,0.2c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c1,0.1,1.9,0.1,2.9,0.1c1,0,1.9,0,2.9-0.1c0.2,0,0.3,0,0.5,0
                    c0.1,0,0.3,0,0.4,0c0.7,0,1.3-0.1,2-0.2c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0l0.2,0c0.2,0,0.4-0.1,0.6-0.1
                    l0.9-0.1l0,0c0,0,0,0,0,0c0.2,0,0.5-0.1,0.7-0.1c0.8-0.2,1.7-0.3,2.5-0.5c0.1,0,0.2,0,0.2-0.1c1.9-0.5,3.7-1.1,5.5-1.7
                    c0.2-0.1,0.4-0.2,0.6-0.2c0.7-0.3,1.3-0.5,1.9-0.8c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.3,1.1-0.5,1.6-0.8c0.3-0.2,0.6-0.3,0.9-0.5
                    c0.5-0.3,1-0.6,1.5-0.9c0.7-0.4,1.4-0.8,2.1-1.3c0.3-0.2,0.5-0.3,0.8-0.5c0.5-0.3,1-0.7,1.5-1c0.2-0.2,0.4-0.3,0.6-0.5
                    c0.5-0.4,1-0.8,1.5-1.2c0.1-0.1,0.3-0.2,0.4-0.3c2.3-1.9,4.4-4,6.3-6.3c0.1-0.1,0.2-0.3,0.4-0.4c0.4-0.5,0.8-1,1.2-1.6
                    c0.1-0.2,0.3-0.3,0.4-0.5c0.3-0.4,0.6-0.8,0.9-1.3l0.3-0.5l0,0C96.9,69.8,100,60.2,100,50z M96.7,50c0,8.3-2.2,16.1-6,22.8
                    c-2.7-8.9-10.5-15.3-20-16.1l-0.1-0.1h-1.8h-8h-1.9c-0.3,0-0.6-0.3-0.6-0.6V55c0-0.3,0.2-0.5,0.5-0.6c10.7-4,17.4-20.8,16-32.5
                    c-0.7-6.1-3.7-11.5-8.1-15.5C84.2,13.2,96.7,30.1,96.7,50z M87.6,77.7c-0.1,0.2-0.3,0.4-0.4,0.5c-0.4,0.5-0.7,1-1.1,1.4
                    c0,0-0.1,0.1-0.1,0.1c-1.3,1.6-2.8,3.1-4.4,4.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-0.2,0.1-0.3,0.2-0.5,0.4
                    c-0.5,0.4-0.9,0.8-1.4,1.1c-0.2,0.1-0.3,0.2-0.5,0.4c-0.3,0.2-0.6,0.4-0.8,0.6V80h-3.3v10.4c-0.1,0-0.1,0.1-0.2,0.1
                    c-0.4,0.2-0.8,0.5-1.3,0.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.5,0.3-1,0.5-1.5,0.7c-0.3,0.1-0.5,0.2-0.8,0.3c-0.6,0.3-1.3,0.5-1.9,0.8
                    c-0.1,0.1-0.3,0.1-0.4,0.2c-1.7,0.6-3.4,1.2-5.1,1.6c-0.1,0-0.3,0.1-0.4,0.1c-0.7,0.2-1.4,0.3-2.2,0.5c-0.2,0-0.3,0.1-0.5,0.1
                    l-2.9-19.3h1.4v-4.1l7.5,5l7-17.4c8.8,1,15.9,7.8,17.1,16.7C88,77.1,87.8,77.4,87.6,77.7z M39.1,95.4c-0.1,0-0.3-0.1-0.4-0.1
                    c-1.7-0.4-3.4-1-5.1-1.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-0.3-1.3-0.5-1.9-0.8c-0.3-0.1-0.5-0.2-0.8-0.3c-0.5-0.2-1-0.5-1.5-0.7
                    c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.2-0.9-0.5-1.3-0.7c-0.1,0-0.1-0.1-0.2-0.1V80h-3.3v8.3c-0.3-0.2-0.6-0.4-0.8-0.6
                    c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-1-0.8-1.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3
                    c-1.5-1.4-3-3-4.3-4.6c0,0-0.1-0.1-0.1-0.1c-0.4-0.5-0.8-1-1.1-1.4c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.3-0.4-0.5-0.6-0.8
                    c1.2-8.9,8.3-15.7,17.1-16.7l7,17.4l7.5-5v4.1h1.4l-2.9,19.3c-0.2,0-0.4-0.1-0.6-0.1C40.6,95.7,39.9,95.5,39.1,95.4z M42.5,51.3
                    c-8-2.9-14.2-16.6-14.2-26.3c0-0.2,0-0.4,0-0.6l1.2-1.5c2.8-3.3,7.4-4.2,11.7-2.3c1.7,0.8,3.5,1.1,5.4,1.1c5,0,9.4-2.7,11.7-7
                    c5.3,1.7,10,5,13.2,9.2c0.5,10.2-5.5,24.2-14,27.4C56,51.9,55,53.3,55,55v1.1c0,1.9,1.4,3.5,3.2,3.9l-2.8,3.4H44.7l-2.8-3.4
                    c1.8-0.4,3.2-2,3.2-3.9V55C45,53.4,44,51.9,42.5,51.3z M50.1,3.3C50.1,3.3,50.1,3.3,50.1,3.3c0.9,0,1.7,0,2.4,0.1
                    c8.3,0.9,15.2,6.7,17.9,14.3c-3.5-3.1-7.8-5.4-12.5-6.7l-1.4-0.4l-0.6,1.4c-1.5,3.8-5.2,6.3-9.3,6.3c-1.4,0-2.8-0.3-4-0.9
                    c-4.6-2-9.6-1.5-13.4,1.2c1-3.4,2.9-6.5,5.4-9C38.8,5.6,44.2,3.3,50.1,3.3z M56.7,66.9l5.8-6.9h5.1l-5,12.5l-5.9-3.9V66.9z
                    M53.9,96.5c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0-1.7,0.1-2.6,0.1c-0.1,0-0.1,0-0.2,0c-0.8,0-1.6,0-2.4-0.1c-0.4,0-0.9-0.1-1.3-0.1
                    c-0.3,0-0.6-0.1-0.9-0.1l2.9-19.7h3.8l3,19.7C54.6,96.4,54.3,96.5,53.9,96.5z M53.3,73.3h-6.7v-3v-3.7h6.7v3.7V73.3z M43.3,66.9v1.6
                    l-5.9,3.9l-5-12.5h5.1L43.3,66.9z M33.3,6.4c-0.3,0.3-0.7,0.6-1,0.9C27.6,12,25,18.3,25,25c0,11,7,26,16.3,29.4
                    c0.2,0.1,0.3,0.3,0.3,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.9h-8h-0.9c-0.2,0-0.3,0-0.5,0.1C20.1,57.3,12,63.8,9.3,72.8
                    c-3.8-6.8-6-14.5-6-22.8C3.3,30.2,15.8,13.2,33.3,6.4z"
	></path></svg
>`,
					title: 'Profile',
					text: 'Each user can create their own profile complete with current health metrics like height, weight, and health inputs.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M41.7,66.7h-10c-0.9,0-1.7-0.7-1.7-1.7V5c0-0.9,0.7-1.7,1.7-1.7h36.7C69.3,3.3,70,4.1,70,5v60c0,0.9-0.7,1.7-1.7,1.7h-10
                        V70h10c2.8,0,5-2.2,5-5V5c0-2.8-2.2-5-5-5H31.7c-2.8,0-5,2.2-5,5v60c0,2.8,2.2,5,5,5h10V66.7z M0,11.7v46.7c0,2.8,2.2,5,5,5h18.3
                        V60H5c-0.9,0-1.7-0.7-1.7-1.7V11.7C3.3,10.7,4.1,10,5,10h18.3V6.7H5C2.2,6.7,0,8.9,0,11.7z M40,11.7h3.3V8.3H40V11.7z M60,8.3
                        H46.7v3.3H60V8.3z M38.3,23.3v-6.7H35v6.7H38.3z M41.7,23.3H45v-6.7h-3.3V23.3z M48.3,16.7v6.7h3.3v-6.7H48.3z M55,16.7v6.7h3.3
                        v-6.7H55z M61.7,16.7v6.7H65v-6.7H61.7z M48.3,31.7H60v-3.3H48.3V31.7z M45,58.3h10c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5H45
                        c-2.8,0-5,2.2-5,5C40,56.1,42.2,58.3,45,58.3z M45,51.7h10c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7H45
                        c-0.9,0-1.7-0.7-1.7-1.7C43.3,52.4,44.1,51.7,45,51.7z M18.3,16.7h5v-3.3h-5V16.7z M10,21.7H6.7v5H10V21.7z M13.3,26.7h3.3v-5
                        h-3.3V26.7z M20,26.7h3.3v-5H20V26.7z M11.7,16.7H15v-3.3h-3.3V16.7z M8.3,50c0,2.8,2.2,5,5,5h10v-3.3h-10c-0.9,0-1.7-0.7-1.7-1.7
                        c0-0.9,0.7-1.7,1.7-1.7h10V45h-10C10.6,45,8.3,47.2,8.3,50z M95,6.7H76.7V10H95c0.9,0,1.7,0.7,1.7,1.7v46.7c0,0.9-0.7,1.7-1.7,1.7
                        H76.7v3.3H95c2.8,0,5-2.2,5-5V11.7C100,8.9,97.8,6.7,95,6.7z M88.3,13.3H76.7v3.3h11.7V13.3z M93.3,21.7H90v5h3.3V21.7z
                        M86.7,26.7v-5h-3.3v5H86.7z M80,21.7h-3.3v5H80V21.7z M91.7,50c0-2.8-2.2-5-5-5h-10v3.3h10c0.9,0,1.7,0.7,1.7,1.7
                        c0,0.9-0.7,1.7-1.7,1.7h-10V55h10C89.4,55,91.7,52.8,91.7,50z M70.8,75.1c-1.5-0.2-2.9,0.2-4.1,1.1c0,0-0.1,0.1-0.1,0.1
                        c-1.9-1.7-4.8-1.7-6.7,0c-1.4-1.2-3.3-1.6-5-1v-8.4c0-2.5-1.7-4.6-4.2-5.1c-2.7-0.5-5.3,1.4-5.8,4.1c0,0.3-0.1,0.6-0.1,0.8v20.4
                        l-4.4-2.7c-1.3-0.8-3-0.9-4.4-0.2l-0.3,0.1c-2.3,1.1-3.2,3.9-2.1,6.2c0.3,0.7,0.8,1.3,1.4,1.7l10.4,7.5c0.3,0.2,0.6,0.3,1,0.3
                        h26.7c0.9,0,1.7-0.7,1.7-1.7V80.2C75,77.7,73.3,75.5,70.8,75.1z M71.7,96.7H47.2l-10-7.1c-0.6-0.4-0.7-1.2-0.3-1.8
                        c0.1-0.2,0.3-0.3,0.5-0.4l0.3-0.1c0.4-0.2,0.9-0.2,1.2,0l6.9,4.2c0.8,0.5,1.8,0.2,2.3-0.6c0.2-0.3,0.2-0.6,0.2-0.9V66.7
                        c0-0.9,0.7-1.7,1.7-1.7c0.1,0,0.2,0,0.3,0c0.8,0.2,1.4,1,1.4,1.8V80c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7
                        c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
                        c0.9,0,1.7,0.7,1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.1,0,0.2,0,0.3,0
                        c0.8,0.2,1.4,1,1.4,1.8V96.7z M40,31.7h5v-3.3h-5V31.7z M40,38.3h20V35H40V38.3z M56.7,45v-3.3H43.3V45H56.7z M13.3,35h10v-3.3
                        h-10V35z M16.7,41.7h6.7v-3.3h-6.7V41.7z M85,31.7h-8.3V35H85V31.7z M81.7,38.3h-5v3.3h5V38.3z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Subscription',
					text: 'The app can be configured as a free service with limited functionalities or as a paid one with subscription.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M98.2,80.7h-5.4c-1-3.8-4.9-6.2-8.7-5.2c-2.6,0.6-4.6,2.6-5.2,5.2h-22c-0.6-2.2-2.1-4-4.2-4.9l13-12.9h20.1
                        c4.2,0,7.6-3.4,7.6-7.6V18c0-4.2-3.4-7.6-7.6-7.6H15.1c-4.2,0-7.6,3.4-7.6,7.6v37.3c0,4.2,3.4,7.6,7.6,7.6h20.1l12.7,12.7
                        c-2.4,0.7-4.3,2.7-4.9,5.1h-22c-1-3.8-4.9-6.2-8.7-5.2c-2.6,0.6-4.6,2.6-5.2,5.2H1.8c-1,0-1.8,0.8-1.8,1.7c0,1,0.8,1.7,1.8,1.7
                        h5.4c1,3.8,4.9,6.2,8.7,5.2c2.6-0.6,4.6-2.6,5.2-5.2h22c1,3.8,4.9,6.2,8.7,5.2c2.6-0.6,4.6-2.6,5.2-5.2h22c1,3.8,4.9,6.2,8.7,5.2
                        c2.6-0.6,4.6-2.6,5.2-5.2h5.4c1,0,1.8-0.8,1.8-1.7C100,81.5,99.2,80.7,98.2,80.7z M14.1,86.1c-2,0-3.7-1.6-3.7-3.7
                        s1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7S16.1,86.1,14.1,86.1z M37.1,59.9c-0.3-0.3-0.7-0.5-1.2-0.5H15.1c-2.3,0-4.1-1.8-4.1-4.1V18
                        c0-2.3,1.8-4.1,4.1-4.1h70.6c2.3,0,4.1,1.8,4.1,4.1v37.3c0,2.3-1.8,4.1-4.1,4.1H65c-0.5,0-0.9,0.2-1.2,0.5L50.5,73.1L37.1,59.9z
                        M50,86.1c-2,0-3.7-1.6-3.7-3.7s1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7S52,86.1,50,86.1z M85.9,86.1c-2,0-3.7-1.6-3.7-3.7
                        s1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7S87.9,86.1,85.9,86.1z M51.9,38.1h27.6c1,0,1.8-0.8,1.8-1.7c0-1-0.8-1.7-1.8-1.7H51.9
                        c-1,0-1.8,0.8-1.8,1.7C50.1,37.3,50.9,38.1,51.9,38.1z M51.9,26.4h27.6c1,0,1.8-0.8,1.8-1.7c0-1-0.8-1.7-1.8-1.7H51.9
                        c-1,0-1.8,0.8-1.8,1.7C50.1,25.7,50.9,26.4,51.9,26.4z M51.9,49.8h21.7c1,0,1.8-0.8,1.8-1.7c0-1-0.8-1.7-1.8-1.7H51.9
                        c-1,0-1.8,0.8-1.8,1.7C50.1,49,50.9,49.8,51.9,49.8z M42.2,22.9H18.8c-1,0-1.8,0.8-1.8,1.7V48c0,1,0.8,1.7,1.8,1.7h23.4
                        c1,0,1.8-0.8,1.8-1.7V24.7C43.9,23.7,43.2,22.9,42.2,22.9z M40.4,46.3H20.6V26.4h19.9V46.3z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Activity timeline',
					text: 'Users can view their activity routine from day one until the present day through a well-designed dashboard.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M82.2,50.7c0-0.5,0-0.6,0-0.6V13.5c0-2.7-2-4.7-4.7-4.7h-4.4V5.7c0-0.9-0.6-1.6-1.6-1.6c-0.9,0-1.6,0.6-1.6,1.6v3.1h-6.3
                        V5.7c0-0.9-0.6-1.6-1.6-1.6c-0.9,0-1.6,0.6-1.6,1.6v3.1H21.6V5.7c0-0.9-0.6-1.6-1.6-1.6s-1.6,0.6-1.6,1.6v3.1h-6.3V5.7
                        c0-0.9-0.6-1.6-1.6-1.6c-0.9,0-1.6,0.6-1.6,1.6v3.1H4.7C2,8.8,0,10.9,0,13.5V74c0,2.7,2,4.7,4.7,4.7h50.5
                        c2.5,9.8,11.4,17.2,22,17.2C89.7,95.8,100,85.7,100,73C100,62.1,92.3,53,82.2,50.7z M3.1,13.5c0-0.8,0.6-1.6,1.6-1.6h4.4v3.1
                        c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6V12h6.3v3.1c0,0.9,0.6,1.6,1.6,1.6s1.6-0.6,1.6-1.6V12h39.1v3.1
                        c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6V12H70v3.1c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6V12h4.4
                        c0.9,0,1.6,0.6,1.6,1.6v7.8H3.1V13.5z M54.5,75.4H4.7v0.2c-0.9,0-1.6-0.6-1.6-1.6V24.5h75.9v25.8c-0.6,0-1.3-0.2-1.9-0.2
                        c-12.5,0-22.8,10.2-22.8,22.8C54.4,73.7,54.4,74.6,54.5,75.4z M77.2,92.6c-10.8,0-19.7-8.7-19.7-19.7c0-10.8,8.8-19.7,19.7-19.7
                        c10.8,0,19.7,8.7,19.7,19.7C96.9,83.8,88.1,92.6,77.2,92.6z M78.8,56.5h-3.1v3.1h3.1V56.5z M75.6,89.4h3.1v-3.1h-3.1V89.4z
                        M85.6,62.4l2.2,2.2l2.2-2.2l-2.2-2.2L85.6,62.4z M64.4,83.5l2.2,2.2l2.2-2.2l-2.2-2.2L64.4,83.5z M90.6,71.5v3.1h3.1v-3.1H90.6z
                        M60.8,74.6h3.1v-3.1h-3.1V74.6z M85.6,83.6l2.2,2.2l2.2-2.2l-2.2-2.2L85.6,83.6z M64.4,62.3l2.2,2.2l2.2-2.2l-2.2-2.2L64.4,62.3z
                        M84.1,71.5h-6.7c-0.5,0-0.8,0.2-1.1,0.5l-5.9,5.6c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l5.5-5.2h6.1
                        c0.9,0,1.6-0.6,1.6-1.6C85.6,72.1,84.8,71.5,84.1,71.5z M62.3,40.4h8.4c0.9,0,1.6-0.6,1.6-1.6v-8.4c0-0.9-0.6-1.6-1.6-1.6h-8.4
                        c-0.9,0-1.6,0.6-1.6,1.6v8.4C60.8,39.8,61.4,40.4,62.3,40.4z M63.9,32h5.3v5.3h-5.3V32z M53.7,28.8h-8.4c-0.9,0-1.6,0.6-1.6,1.6
                        v8.4c0,0.9,0.8,1.6,1.6,1.6h8.4c0.9,0,1.6-0.6,1.6-1.6v-8.4C55.3,29.5,54.7,28.8,53.7,28.8z M52.2,37.3h-5.3V32h5.3V37.3z
                        M53.7,44.1h-8.4c-0.9,0-1.6,0.6-1.6,1.6v8.4c0,0.9,0.6,1.6,1.6,1.6h8.4c0.8,0,1.6-0.6,1.6-1.6v-8.4
                        C55.3,44.8,54.7,44.1,53.7,44.1z M52.2,52.6h-5.3v-5.3h5.3V52.6z M19.8,28.8h-8.3c-0.9,0-1.6,0.6-1.6,1.6v8.4
                        c0,0.9,0.6,1.6,1.6,1.6H20c0.9,0,1.6-0.6,1.6-1.6v-8.4C21.4,29.6,20.8,28.8,19.8,28.8z M18.4,37.3h-5.3V32h5.3V37.3z M19.8,44.3
                        h-8.3c-0.9,0-1.6,0.6-1.6,1.6v8.4c0,0.9,0.6,1.6,1.6,1.6H20c0.9,0,1.6-0.6,1.6-1.6v-8.4C21.4,44.9,20.8,44.3,19.8,44.3z
                        M18.4,52.6h-5.3v-5.3h5.3V52.6z M19.8,59.6h-8.3c-0.9,0-1.6,0.6-1.6,1.6v8.4c0,0.9,0.6,1.6,1.6,1.6H20c0.9,0,1.6-0.6,1.6-1.6
                        v-8.4C21.4,60.2,20.8,59.6,19.8,59.6z M18.4,67.9h-5.3v-5.3h5.3V67.9z M36.9,28.8h-8.4c-0.9,0-1.6,0.6-1.6,1.6v8.4
                        c0,0.9,0.6,1.6,1.6,1.6h8.4c0.9,0,1.6-0.6,1.6-1.6v-8.4C38.4,29.6,37.7,28.8,36.9,28.8z M35.3,37.3H30V32h5.3V37.3z M36.9,44.3
                        h-8.4c-0.9,0-1.6,0.6-1.6,1.6v8.4c0,0.9,0.6,1.6,1.6,1.6h8.4c0.9,0,1.6-0.6,1.6-1.6v-8.4C38.4,44.9,37.7,44.3,36.9,44.3z
                        M35.3,52.6H30v-5.3h5.3V52.6z M36.9,59.6h-8.4c-0.9,0-1.6,0.6-1.6,1.6v8.4c0,0.9,0.6,1.6,1.6,1.6h8.4c0.9,0,1.6-0.6,1.6-1.6v-8.4
                        C38.4,60.2,37.7,59.6,36.9,59.6z M35.3,67.9H30v-5.3h5.3V67.9z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Daily plan',
					text: 'A customized daily plan to help users to train for their goals using weights, cardio, strength training, etc.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<path
			class="st0"
			d="M-378-3287.7h-0.6v3.4h-3.3v-1.4h-1.2v1.4h-6.9v-11.7h-32.4v48h32.4v-11.2h-1.2v2.7h-11.9v-15.2h-4.2v15.2
                      h-1.8v-11.7h-4.2v11.7h-1.8v-9.4h-4.2v9.4h-1.7v-33.9h29.9v6.2h-4.2v-1.4h-1.2v1.4h-4.7v23.3h4.6v-1.2h-3.4v-17.2h21.6v12.7h0.6
                      c1.2,0,2.2,1,2.3,2.2c0,1.2-1,2.2-2.3,2.2h-16.6v1.2h16.6c1.9,0,3.5-1.5,3.5-3.4h0c0,0,0,0,0,0c0,0,0,0,0-0.1v-17.3v-1.9v-0.5
                      C-374.5-3286.1-376.1-3287.7-378-3287.7z M-406.2-3270.5h1.8v14h-1.8V-3270.5z M-412.3-3267h1.8v10.4h-1.8V-3267z M-418.3-3264.7
                      h1.8v8.2h-1.8V-3264.7z M-419.6-3255.3h4.2h1.8h4.2h1.8h4.2h11.9v6.1h-29.9v-6.1H-419.6z M-421.3-3291.7v-3.1h29.9v3.1H-421.3z
                      M-378.6-3280.7h-21.6v-2.4h3.5v1.2h1.2v-1.2h12.4v1.2h1.2v-1.2h3.3V-3280.7z M-375.7-3283.7v1.9v14.7c-0.5-0.4-1-0.7-1.7-0.8
                      v-18.5c1,0.3,1.7,1.1,1.7,2.2V-3283.7z M-406.3-3250.1c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2
                      S-407.5-3250.1-406.3-3250.1z M-406.3-3253.3c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1
                      C-407.3-3252.8-406.8-3253.3-406.3-3253.3z M-419.5-3280.6c0,1.9,0.7,3.8,1.9,5.2c1.6,1.8,3.8,2.8,6.2,2.8c2.4,0,4.6-1,6.1-2.8
                      c1.3-1.5,2-3.4,2-5.3c0-4.5-3.7-8.1-8.1-8.1C-415.9-3288.7-419.5-3285.1-419.5-3280.6z M-411.4-3273.7c-1.9,0-3.7-0.8-5-2.2
                      c0-0.2,0-0.3,0-0.3l0,0v0c0-0.4,0.2-0.9,0.5-1.2c0.3-0.3,0.6-0.4,1-0.5l1.2-0.1l0.3,0c0.1,0,0.2,0,0.3-0.1l1.7,1.7l1.7-1.7
                      c0.1,0.1,0.3,0.1,0.4,0.1l1.5,0.2c0.4,0.1,0.7,0.2,1,0.5c0.3,0.3,0.5,0.7,0.5,1.2l0,0.3C-407.6-3274.6-409.4-3273.7-411.4-3273.7z
                      M-411.4-3280.5c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2C-409.2-3281.5-410.2-3280.5-411.4-3280.5z M-410.6-3279
                      l-0.7,0.7l-0.7-0.7c0.1-0.1,0.2-0.3,0.2-0.4c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0C-410.8-3279.2-410.7-3279.1-410.6-3279z
                      M-404.5-3280.6c0,1.1-0.3,2.2-0.8,3.2c-0.1-0.3-0.3-0.6-0.6-0.9c-0.5-0.5-1.1-0.8-1.7-0.8l-1.3-0.1l-0.2,0c-0.2,0-0.4-0.1-0.6-0.4
                      c1-0.6,1.7-1.7,1.7-3c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.3,0.7,2.4,1.8,3c-0.2,0.3-0.4,0.4-0.6,0.4l-1.5,0.2l0,0
                      c-0.6,0.1-1.3,0.4-1.7,0.8c-0.3,0.3-0.5,0.7-0.7,1c-0.6-1-0.9-2.2-0.9-3.3c0-3.8,3.1-6.9,6.9-6.9
                      C-407.6-3287.5-404.5-3284.4-404.5-3280.6z M-408.2-3292.7h3.9v-1.2h-3.9V-3292.7z M-393.9-3275.6h-4.4v4.4h4.4V-3275.6z
                      M-395.2-3272.4h-1.9v-1.9h1.9V-3272.4z M-384.7-3271.2h4.4v-4.4h-4.4V-3271.2z M-383.5-3274.3h1.9v1.9h-1.9V-3274.3z M-398.3-3265
                      h4.4v-4.4h-4.4V-3265z M-397.1-3268.1h1.9v1.9h-1.9V-3268.1z M-384.7-3265h4.4v-4.4h-4.4V-3265z M-383.5-3268.1h1.9v1.9h-1.9
                      V-3268.1z M-391.5-3265h4.4v-4.4h-4.4V-3265z M-390.3-3268.1h1.9v1.9h-1.9V-3268.1z M-389.4-3275.5c-1.2,0-2.2,1-2.2,2.2
                      c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2C-387.2-3274.5-388.2-3275.5-389.4-3275.5z M-389.4-3272.4c-0.5,0-1-0.4-1-0.9
                      c0-0.5,0.4-0.9,1-0.9c0.5,0,1,0.4,1,0.9C-388.4-3272.8-388.8-3272.4-389.4-3272.4z M-380.3-3278.1h-1.3v1.2h1.3V-3278.1z
                      M-387-3278.1v1.2h1.3v-1.2H-387z M-384.4-3278.1v1.2h1.3v-1.2H-384.4z"
		></path>
	</g>
	<g>
		<g>
			<path
				d="M100,24.5c0-4-3.3-7.3-7.3-7.3h-1.3v7.1h-6.8v-2.9H82v2.9H67.5V0H0v100h67.5V76.6h-2.6v5.6H40.2V50.5h-8.8v31.7h-3.8V57.9
                        h-8.8v24.3H15V62.7H6.1v19.6H2.6V11.5h62.4v12.9h-8.7v-2.9h-2.6v2.9h-9.8v48.4h9.6v-2.6h-7V34.5h45v26.5h1.3c2.6,0,4.7,2,4.7,4.6
                        c-0.1,2.5-2.2,4.6-4.7,4.6H58.2v2.6h34.5c3.9,0,7.2-3.2,7.3-7.1h0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1V24.5z M34,53.1h3.7v29.1H34
                        V53.1z M21.3,60.5H25v21.7h-3.7V60.5z M8.7,65.2h3.7v17H8.7V65.2z M64.9,84.8v12.6H2.6V84.8H64.9z M2.6,9V2.6h62.4V9H2.6z
                        M91.4,32h-45v-5h7.2v2.5h2.6V27H82v2.5h2.6V27h6.8V32z M97.4,60.2c-1-0.8-2.1-1.4-3.4-1.6V20c2,0.6,3.4,2.4,3.4,4.5V60.2z
                        M33.8,95.7c2.6,0,4.7-2.1,4.7-4.6c0-2.5-2.1-4.6-4.7-4.6c-2.6,0-4.7,2.1-4.7,4.6C29.1,93.6,31.2,95.7,33.8,95.7z M33.8,89
                        c1.1,0,2.1,0.9,2.1,2.1c0,1.1-0.9,2.1-2.1,2.1c-1.1,0-2.1-0.9-2.1-2.1C31.7,89.9,32.6,89,33.8,89z M6.2,32.1c0,4,1.4,7.9,4,10.9
                        c3.2,3.8,7.9,5.9,12.9,5.9c4.9,0,9.6-2.1,12.8-5.8c2.7-3.1,4.2-7,4.2-11.1c0-9.3-7.6-16.9-17-16.9C13.8,15.2,6.2,22.8,6.2,32.1z
                        M23.2,46.4c-4,0-7.8-1.6-10.5-4.5c0.1-0.4,0.1-0.6,0.1-0.6l0-0.1v-0.1c0-0.9,0.4-1.8,1-2.4c0.5-0.6,1.3-0.9,2-1l2.5-0.3l0.6-0.1
                        c0.2,0,0.5-0.1,0.7-0.1l3.5,3.5l3.6-3.5c0.3,0.1,0.6,0.2,0.9,0.2l3.1,0.3c0.8,0.1,1.5,0.5,2,1c0.6,0.7,1,1.5,1,2.4l0,0.6
                        C31.1,44.7,27.2,46.4,23.2,46.4z M23.2,32.2c-2.5,0-4.6-2-4.6-4.5c0-2.5,2-4.5,4.6-4.5c2.5,0,4.6,2,4.6,4.5
                        C27.7,30.2,25.7,32.2,23.2,32.2z M24.7,35.5L23.2,37l-1.4-1.4c0.2-0.3,0.4-0.6,0.5-0.9c0.3,0,0.6,0.1,0.9,0.1c0.4,0,0.7,0,1.1-0.1
                        C24.4,35,24.5,35.3,24.7,35.5z M37.6,32.1c0,2.3-0.6,4.6-1.7,6.7c-0.3-0.7-0.7-1.3-1.3-1.9c-1-1-2.2-1.6-3.6-1.8l-2.8-0.3l-0.3,0
                        c-0.4-0.1-0.9-0.2-1.2-0.9c2.1-1.2,3.6-3.5,3.6-6.2c0-3.9-3.2-7.1-7.2-7.1c-3.9,0-7.2,3.2-7.2,7.1c0,2.7,1.5,5.1,3.8,6.3
                        c-0.3,0.6-0.7,0.7-1.2,0.8l-3.1,0.3l0,0c-1.4,0.2-2.6,0.8-3.6,1.8c-0.6,0.6-1.1,1.4-1.4,2.2c-1.2-2.1-1.8-4.5-1.8-6.9
                        c0-7.9,6.5-14.3,14.4-14.3C31.1,17.8,37.6,24.2,37.6,32.1z M29.7,7h8.2V4.4h-8.2V7z M59.5,42.6h-9.1v9.1h9.1V42.6z M56.9,49.1h-4
                        v-4h4V49.1z M78.7,51.7h9.1v-9.1h-9.1V51.7z M81.3,45.2h4v4h-4V45.2z M50.3,64.6h9.1v-9.1h-9.1V64.6z M52.9,58.1h4V62h-4V58.1z
                        M78.7,64.6h9.1v-9.1h-9.1V64.6z M81.3,58.1h4V62h-4V58.1z M64.5,64.6h9.1v-9.1h-9.1V64.6z M67.1,58.1h4V62h-4V58.1z M69,42.7
                        c-2.5,0-4.6,2-4.6,4.5c0,2.5,2.1,4.5,4.6,4.5c2.5,0,4.6-2,4.6-4.5C73.6,44.7,71.6,42.7,69,42.7z M69,49.2c-1.1,0-2-0.9-2-2
                        c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C71,48.3,70.1,49.2,69,49.2z M87.8,37.3H85v2.6h2.8V37.3z M73.9,37.3v2.6h2.7v-2.6H73.9z
                        M79.4,37.3v2.6h2.8v-2.6H79.4z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Daily activity inputs',
					text: 'Users can input the app with relevant information about daily activities which will be used to create the activity timeline.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M9.3,62.5h81.1c0.9,0,1.5-0.6,1.5-1.5v-8.6c0-0.9-0.6-1.5-1.5-1.5h-2.9V14.5h2.9c0.9,0,1.5-0.6,1.5-1.5V4.5
                        C92,3.6,91.3,3,90.4,3H9.3C8.4,3,7.7,3.6,7.7,4.5v8.6c0,0.9,0.6,1.5,1.5,1.5h2.8V51H9.3v-0.2c-0.9,0-1.5,0.6-1.5,1.5V61
                        C7.7,61.9,8.4,62.5,9.3,62.5z M10.8,11.6V6.1h78v5.5H13.3c-0.8,0-1.1,0-1.1,0H10.8z M84.4,14.5v36.3H15.1V14.5H84.4z M10.8,53.9
                        c53.5,0-13.7,0,78,0v5.5h-78V53.9z M85.8,80.1c-5.2,0-9.7,2.6-12,6.6c-2.3-3.8-6.8-6.6-12-6.6c-5.2,0-9.6,2.6-11.9,6.6
                        c-2.3-3.8-6.8-6.6-12-6.6s-9.6,2.6-11.9,6.6c-2.3-3.8-6.8-6.6-12-6.6c-7.4,0-13.6,5.5-13.6,12.3v3.1C0.3,96.4,1,97,1.9,97
                        c0.9,0,1.5-0.6,1.5-1.5v-3.1c0-5.1,4.6-9.2,10.5-9.2c5.9,0,10.5,4.1,10.5,9.2v3.1c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5
                        v-3.1c0-5.1,4.6-9.2,10.5-9.2c5.9,0,10.5,4.1,10.5,9.2v3.1c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5v-3.1
                        c0-5.1,4.6-9.2,10.5-9.2c5.9,0,10.5,4.1,10.5,9.2v3.1c0,0.9,0.6,1.5,1.5,1.5s1.5-0.6,1.5-1.5v-3.1c0-5.1,4.6-9.2,10.5-9.2
                        s10.5,4.1,10.5,9.2v3.1c0,0.9,0.6,1.5,1.5,1.5c0.9,0,1.5-0.6,1.5-1.5v-3.1C99.2,85.7,93.2,80.1,85.8,80.1z M14.1,78.6
                        c4,0,7.4-3.1,7.4-6.9c0-3.8-3.2-6.9-7.4-6.9S6.7,68,6.7,71.7C6.7,75.4,9.9,78.6,14.1,78.6z M14.1,68c2.3,0,4.3,1.7,4.3,3.8
                        s-2,3.8-4.3,3.8c-2.3,0-4.3-1.8-4.3-4C9.7,69.6,11.6,68,14.1,68z M30.6,71.7c0,3.8,3.2,6.9,7.4,6.9s7.4-3.1,7.4-6.9
                        c0-3.8-3.2-6.9-7.4-6.9S30.6,68,30.6,71.7z M42.1,71.7c0,2.1-2,3.8-4.3,3.8c-2.3,0-4.3-1.7-4.3-3.8c0-2.1,2-3.8,4.3-3.8
                        C40.1,67.9,42.1,69.7,42.1,71.7z M54.5,71.7c0,3.8,3.2,6.9,7.4,6.9s7.4-3.1,7.4-6.9c0-3.8-3.2-6.9-7.4-6.9S54.5,68,54.5,71.7z
                        M66,71.7c0,2.1-2,3.8-4.3,3.8c-2.3,0-4.2-1.7-4.2-3.8c0-2.1,2-3.8,4.3-3.8C64.2,67.9,66,69.7,66,71.7z M85.8,65
                        c-4,0-7.4,3.1-7.4,6.9c0,3.8,3.2,6.9,7.4,6.9s7.4-3.1,7.4-6.9C93.2,68,89.8,65,85.8,65z M85.8,75.5c-2.3,0-4.3-1.7-4.3-3.8
                        c0-2.1,2-3.8,4.3-3.8c2.3,0,4.3,1.7,4.3,3.8C90.1,73.8,88.1,75.5,85.8,75.5z M48.1,45.2l14.5-8.9c2.5-1.7,2.5-5.4,0-6.9l-14.5-8.9
                        c-2.6-1.7-6.2,0.3-6.2,3.4v17.9C42,44.9,45.4,46.9,48.1,45.2z M45.1,23.7c0-0.8,0.8-1.2,1.4-0.8L61,31.8c0.6,0.5,0.6,1.2,0,1.7
                        l-14.5,8.9c-0.6,0.3-1.4,0-1.4-0.8V23.7z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Video training',
					text: 'The app will have videos from experts to help them train the right manner without suffering any injuries due to mistakes.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M43.8,8.8c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5H30.9c-0.9,0-1.5,0.6-1.5,1.5c0,0.9,0.6,1.5,1.5,1.5H43.8z
                        M31.8,89.4c0,3,2.4,5.5,5.5,5.5s5.5-2.4,5.5-5.5c0-3-2.4-5.5-5.5-5.5S31.8,86.4,31.8,89.4z M39.8,89.4c0,1.4-1.1,2.4-2.4,2.4
                        c-1.4,0-2.6-1.1-2.6-2.4c0-1.4,1.1-2.4,2.4-2.4C38.6,87,39.8,88,39.8,89.4z M51.1,87.9c-0.9,0-1.5,0.6-1.5,1.5
                        c0,0.9,0.6,1.5,1.5,1.5h7.7c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5H51.1z M15.8,87.9c-0.9,0-1.5,0.6-1.5,1.5
                        c0,0.9,0.6,1.5,1.5,1.5h7.7c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5H15.8z M18.8,68v7.1c0,0.9,0.6,1.5,1.5,1.5h34.1
                        c0.9,0,1.5-0.6,1.5-1.5V68c0-5.9-4.8-10.6-10.6-10.6h-2.9c3.5-1.8,5.9-5.5,5.9-9.5c0-5.9-4.8-10.8-10.8-10.8
                        c-5.9,0-10.8,4.8-10.8,10.8c0,4.2,2.4,7.7,5.9,9.5h-3.2C23.6,57.4,18.8,62.1,18.8,68z M29.7,47.9c0-4.2,3.5-7.7,7.7-7.7
                        c4.2,0,7.7,3.5,7.7,7.7c0,4.2-3.5,7.7-7.7,7.7C33.2,55.6,29.7,52.1,29.7,47.9z M45.2,60.5c4.2,0,7.7,3.3,7.7,7.6v5.6H21.8V68
                        c0-4.2,3.5-7.6,7.6-7.6H45.2z M97.1,8H74.7V7.3c0-3.9-3.2-7.3-7.3-7.3H7.3C3.2,0,0,3.2,0,7.3v85.6c0,3.9,3.2,7.1,7.3,7.1h60.3
                        c3.9,0,7.3-3.2,7.3-7.3V46.7c0-0.9-0.6-1.5-1.5-1.5s-1.5,0.6-1.5,1.5v46.1c0,2.3-1.8,4.2-4.2,4.2H7.3C4.8,97,3,95.1,3,92.7V7.3
                        C3,4.8,4.8,3,7.3,3h60.3c2.3,0,4.2,1.8,4.2,4.2V8H55.8c-1.7,0-2.9,1.4-2.9,2.9v0.8H11.1c-0.9,0-1.5,0.6-1.5,1.5v67.1
                        c0,0.9,0.6,1.5,1.5,1.5h52.6c0.9,0,1.5-0.6,1.5-1.5V54.2c0-0.9-0.6-1.5-1.5-1.5c-0.9,0-1.5,0.6-1.5,1.5v24.4H12.6V14.7h40.3v25
                        c0,1.7,1.4,2.9,2.9,2.9h3.5l-2.7,8.9c-0.2,0.6,0,1.4,0.6,1.7c0.6,0.3,1.2,0.3,1.8,0l13.2-10.6h25c1.7,0,2.9-1.4,2.9-2.9V10.9
                        C100,9.4,98.6,8,97.1,8z M97,39.4H71.7c-0.3,0-0.6,0.2-0.9,0.3l-9.8,7.9l1.8-6.2c0.2-0.5,0-0.9-0.3-1.4c-0.3-0.3-0.8-0.6-1.2-0.6
                        h-5.3V11.1H97V39.4z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Live chat',
					text: 'A live chat feature will be available in the app to enable users to chat with their fitness instructors for improving results.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M71.6,57.5c0.6-3.4,2.7-6.4,5.6-8.1c0.8-0.5,1-1.4,0.5-2.2c-0.5-0.8-1.4-0.9-2.2-0.5c-4,2.3-6.5,6.4-7.2,11.1
                        c-1.1-0.5-2.1-0.8-3.2-1.1V15.3c0-1.7-1.4-3.1-3.2-3.1h-8.1V8.9c0-0.9-0.6-1.6-1.6-1.6h-8.6C42.8,3.1,39.1,0,34.6,0
                        c-4.5,0-8.1,3.1-8.9,7.3h-8.6c-1,0-1.6,0.6-1.6,1.6v3.3H7.4c-1.8,0-3.2,1.4-3.2,3.1v73c0,1.6,1.4,3,3.2,3h41.9
                        c5.1,8.3,11.8,11.3,20.4,6.4c16.1,9.1,26.1-12.7,26.1-24.4C95.8,61.1,82.9,52.8,71.6,57.5z M18.9,10.5h8.4c1,0,1.6-0.6,1.6-1.6
                        c0-3.1,2.7-5.8,5.9-5.8c3.2,0,5.9,2.7,5.9,5.8c0,0.9,0.6,1.6,1.6,1.6h8.4V17H18.9C18.9,16.9,18.9,10.2,18.9,10.5z M7.4,88.3v-73
                        h8.1v3.3c0,0.9,0.6,1.6,1.6,1.6H52c1,0,1.6-0.6,1.6-1.6v-3.3h8.1v40.9c-10.2-0.5-18.3,7.5-18.3,17c0,4.1,1.4,9.8,4,15H7.4z
                        M70.8,94.5c-0.5-0.3-1.3-0.3-1.8,0c-14.2,9.2-22-13-22-21.3c0-11.1,12.7-17.7,22-11.7c0.5,0.3,1.3,0.3,1.8,0
                        c9.2-6.1,22,0.6,22,11.7C92.8,81.3,85.1,103.9,70.8,94.5z M63.8,88.1c-4,2.2-6.7-4.2-7.8-8.6c-0.2-0.8-1.1-1.4-1.9-1.1
                        c-0.8,0.2-1.4,1.1-1.1,1.9c1.8,7.2,6.2,14.1,12.4,10.5c0.8-0.5,1.1-1.4,0.6-2.2C65.5,88,64.6,87.7,63.8,88.1z M81.5,64.2
                        c-0.8-0.2-1.8,0.3-1.9,1.1c-0.2,0.9,0.5,1.7,1.3,1.9c4.1,0.9,3.8,7.3,3.7,8.1c0,0.9,0.6,1.6,1.4,1.7h0.2c0.8,0,1.6-0.6,1.6-1.4
                        C88,71.1,86.7,65.5,81.5,64.2z M16.5,35.3h36.6c1,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H16.5c-1,0-1.6,0.6-1.6,1.6
                        C14.9,34.7,15.5,35.3,16.5,35.3z M16.5,48.9h36.6c1,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H16.5c-1,0-1.6,0.6-1.6,1.6
                        C14.9,48.3,15.5,48.9,16.5,48.9z M34.6,59.4H16.5c-1,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h18.3c1,0,1.6-0.6,1.6-1.6
                        C36.4,60,35.6,59.4,34.6,59.4z M34.6,72.8H16.5c-1,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h18.3c1,0,1.6-0.6,1.6-1.6
                        C36.4,73.4,35.6,72.8,34.6,72.8z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Diet chart',
					text: 'Weight loss, weight gain, fitness training — the app can give a customized diet chart customized to each fitness goal'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M84.3,20.8C84.3,20.8,84.3,20.8,84.3,20.8c0-0.1-0.1-0.3-0.1-0.4c0,0,0-0.1,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0,0,0,0,0,0
                        L63.6,0.4c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.4-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0H25
                        c-7.4,0-9.4,6.1-9.4,9.4v81.3c0,3.2,2,9.4,9.4,9.4h50c7.4,0,9.4-6.1,9.4-9.4V21.1C84.4,21,84.4,20.9,84.3,20.8z M64.1,5.2
                        l14.9,14.3h-7.8c-6.8,0-7-5.6-7-6.3V5.2z M81.3,90.6c0,0.3-0.1,6.3-6.3,6.3H25c-6,0-6.2-5.6-6.2-6.3V9.4c0-0.3,0.1-6.3,6.2-6.3
                        h35.9v10.2c0,3.2,2.1,9.4,10.2,9.4h10.2V90.6z M49.2,39.1H32c-0.9,0-1.6,0.7-1.6,1.6v42.2c0,0.9,0.7,1.6,1.6,1.6h17.2
                        c0.9,0,1.6-0.7,1.6-1.6V40.6C50.8,39.8,50.1,39.1,49.2,39.1z M47.7,46.1h-9.4v3.1h9.4v1.6h-3.9v3.1h3.9v1.6h-9.4v3.1h9.4v1.6h-1.6
                        v3.1h1.6v1.6h-7V68h7v1.6h-1.6v3.1h1.6v1.6h-7v3.1h7v3.9H33.6V42.2h14.1V46.1z M49.2,28.1H30.5v3.1h18.8V28.1z M55.5,45.3h3.9
                        v-3.1h-3.9V45.3z M61.7,45.3h7.8v-3.1h-7.8V45.3z M55.5,53.9h3.9v-3.1h-3.9V53.9z M61.7,53.9h7.8v-3.1h-7.8V53.9z M55.5,61.7h3.9
                        v-3.1h-3.9V61.7z M61.7,61.7h7.8v-3.1h-7.8V61.7z M55.5,70.3h3.9v-3.1h-3.9V70.3z M61.7,70.3h7.8v-3.1h-7.8V70.3z M55.5,78.1h3.9
                        V75h-3.9V78.1z M61.7,78.1h7.8V75h-7.8V78.1z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Fitness plans',
					text: 'Daily, weekly, monthly and quarterly fitness plans will be charted to help the user progress consistently.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M91.7,6.7H80V5c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5v1.7H30V5c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5v1.7H8.3C3.7,6.7,0,10.4,0,15
                        v76.7c0,4.6,3.7,8.3,8.3,8.3h83.3c4.6,0,8.3-3.7,8.3-8.3V15C100,10.4,96.3,6.7,91.7,6.7z M73.3,5c0-0.9,0.7-1.7,1.7-1.7
                        c0.9,0,1.7,0.7,1.7,1.7v13.3c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7V5z M70,18.4c0,2.7,2.3,4.9,5,4.9c2.7,0,4.9-2.2,5-4.9
                        c1.9,0.4,3.3,2.1,3.3,4.1c0,2.3-1.9,4.2-4.2,4.2h-8.3c-2.3,0-4.2-1.9-4.2-4.2C66.7,20.5,68.1,18.8,70,18.4z M23.3,5
                        c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7v13.3c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7V5z M20,18.4
                        c0,2.7,2.3,4.9,5,4.9c2.7,0,4.9-2.2,5-4.9c1.9,0.4,3.3,2.1,3.3,4.1c0,2.3-1.9,4.2-4.2,4.2h-8.3c-2.3,0-4.2-1.9-4.2-4.2
                        C16.7,20.5,18.1,18.8,20,18.4z M96.7,91.7c0,2.8-2.2,5-5,5H8.3c-2.8,0-5-2.2-5-5v-55h93.3V91.7z M96.7,33.3H3.3V15
                        c0-2.8,2.2-5,5-5H20v5.1c-3.7,0.4-6.7,3.6-6.7,7.4c0,4.1,3.4,7.5,7.5,7.5h8.3c4.1,0,7.5-3.4,7.5-7.5c0-3.8-2.9-7-6.7-7.4V10h40
                        v5.1c-3.7,0.4-6.7,3.6-6.7,7.4c0,4.1,3.4,7.5,7.5,7.5h8.3c4.1,0,7.5-3.4,7.5-7.5c0-3.8-2.9-7-6.7-7.4V10h11.7c2.8,0,5,2.2,5,5
                        V33.3z M93.3,40H90v3.3h3.3V40z M86.7,40h-3.3v3.3h3.3V40z M80,40h-3.3v3.3H80V40z M10,85h8.3v3.3c0,0.9,0.7,1.7,1.7,1.7h10
                        c0.9,0,1.7-0.7,1.7-1.7V75h36.7v13.3c0,0.9,0.7,1.7,1.7,1.7h10c0.9,0,1.7-0.7,1.7-1.7V85H90c0.9,0,1.7-0.7,1.7-1.7V56.7
                        c0-0.9-0.7-1.7-1.7-1.7h-8.3v-3.3c0-0.9-0.7-1.7-1.7-1.7H70c-0.9,0-1.7,0.7-1.7,1.7V65H31.7V51.7c0-0.9-0.7-1.7-1.7-1.7H20
                        c-0.9,0-1.7,0.7-1.7,1.7V55H10c-0.9,0-1.7,0.7-1.7,1.7v26.7C8.3,84.3,9.1,85,10,85z M81.7,58.3h6.7v23.3h-6.7V58.3z M71.7,53.3
                        h6.7v33.3h-6.7V53.3z M31.7,68.3h36.7v3.3H31.7V68.3z M21.7,53.3h6.7v33.3h-6.7V53.3z M11.7,58.3h6.7v23.3h-6.7V58.3z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Workout plans',
					text: 'According to fitnes goals, the personal trainer provides exclusive custom workout routines to the premium customers.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<path
		d="M84.9,51.7H74.5c-1.6-1.4-3-2-3.1-2.1c0,0,0,0,0,0l-15.9-6.3v-3.7c2.5-2,4.3-5,4.7-8.3c3.1-0.3,5.4-2.9,5.4-5.9
                    c0-1.4-0.5-2.8-1.4-3.8v-5c0-3.9-1.3-7.7-3.7-10.7c-2.3-3-5.6-5.1-9.3-5.9c-0.8-0.2-1.5,0.3-1.7,1.1c-0.2,0.8,0.3,1.6,1.1,1.8
                    C57,4.4,61.4,10,61.4,16.6v3.1c-0.3-0.1-0.7-0.2-1-0.2v0c0-1.2-0.2-1.2-4.2-5.6h0C55.3,13,54,12.8,53,13.5
                    c-4.6,3.1-10.3,4.6-15.5,4.3c-1.2-0.1-2.2,0.7-2.6,1.8c-0.3,0-0.7,0.1-1.1,0.2v-3.2c0-6.6,4.4-12.2,10.7-13.7c0.8-0.2,1.3-1,1.1-1.8
                    C45.5,0.4,44.7-0.1,44,0c-3.6,0.8-6.9,2.9-9.3,5.9c-2.4,3-3.7,6.8-3.7,10.7v5.1c-0.9,1-1.4,2.3-1.4,3.7c0,1.7,0.7,3.3,2,4.5
                    c1,0.8,2.1,1.3,3.4,1.4c0.5,3.3,2.2,6.4,5,8.5v3.5l-15.9,6.3c0,0,0,0,0,0c-0.3,0.1-7.1,3.1-7.1,11.2v26.7l-2.9,2.9
                    c-2.1,2.2-2.1,5.7,0,7.9c2.1,2.2,5.6,2.2,7.7,0l18.7-19l11.9,10.4c2.2,2,5.6,1.8,7.6-0.4l21-23l2.2,2.2c1.6,1.6,4.4,0.5,4.4-1.9
                    V54.3C87.5,52.8,86.4,51.7,84.9,51.7z M60.4,22.5c1.4,0.3,2.4,1.5,2.4,2.9c0,1.4-0.9,2.6-2.4,2.9V22.5z M34.9,28.3
                    c-3-0.6-3.3-4.8,0-5.7V28.3z M70.8,52.6c-0.2,0.2-0.3,0.4-0.4,0.7c-0.4,1-0.2,2.1,0.6,2.9l2.3,2.3L68,64.2v-0.9
                    c0-0.8-0.6-1.5-1.4-1.5c-0.8,0-1.4,0.7-1.4,1.5v4l-9.6,10.5L54.6,77l-1.2-7.1c-0.1-0.8-0.9-1.3-1.7-1.2c-0.8,0.1-1.3,0.9-1.2,1.7
                    l0.6,3.6l-6-5.3l1.4-8.4h2.3l0.5,3.2c0.1,0.8,0.9,1.3,1.7,1.2c0.8-0.1,1.3-0.9,1.2-1.7l-0.6-3.8l1-1.5l0.5,0.3
                    c1.2,0.9,2.9,0.4,3.6-0.9l0,0l4.2-8.5C71.2,52.7,70.4,52.3,70.8,52.6z M50.3,56l-0.9,1.4h-3.2L45.2,56l2.5-1.8L50.3,56z M42.9,43.8
                    v-2.3c1.6,0.6,3.3,0.9,4.9,0.9c1.7,0,3.4-0.4,4.9-1v2.4L47.8,50L42.9,43.8z M58.2,47.5l-3.8,7.8c-0.5-0.4-1-0.7-4.5-3.3l3.7-4.7
                    l1-1.3L58.2,47.5z M37.8,29.5c0-2.1,0-4.4,0-8.8c5.7,0.3,11.7-1.4,16.5-4.6c1.9,2,2.9,3.1,3.2,3.5c0,11.1,0,9.6,0,10.1
                    c-0.1,5.3-4.4,9.7-9.7,9.7C41.8,39.4,37.8,34.7,37.8,29.5z M41,46.1l4.7,5.9c-1.8,1.3-4.4,3.2-4.5,3.3l-3.8-7.8L41,46.1z M25.2,52.3
                    l9.5-3.8l4.2,8.5l0,0c0.3,0.7,0.9,1.1,1.6,1.3c0.7,0.2,1.4,0,2-0.4l0.5-0.3l1,1.5l-1.3,7.6c-2-1-4.6-0.6-6.2,1.1l-5.9,6V63.3
                    c0-0.8-0.6-1.5-1.4-1.5s-1.4,0.7-1.4,1.5v13.5l-7.7,7.8V60.8h0C19.9,54.8,24.9,52.5,25.2,52.3z M84.6,65.9l-2.7-2.7
                    c-0.6-0.6-1.5-0.5-2.1,0l-22,24.2c-0.9,1-2.5,1.1-3.6,0.2L41.4,76.2c-0.6-0.5-1.4-0.5-2,0.1l-19.6,20c-1,1-2.6,1-3.7,0
                    c-1-1-1-2.7,0-3.7c1.1-1.1,21.6-21.9,22.3-22.7c1-1,2.5-1,3.5-0.1c0,0,12.8,11.2,12.8,11.2c0.6,0.5,1.5,0.5,2-0.1l19.5-21.5
                    c0.5-0.6,0.5-1.5,0-2l-2.7-2.8H74l0,0h10.6V65.9z"
	></path></svg
>`,
					title: 'Track Progress',
					text: 'Users can monitor their progress in terms of calories burnt, fitness performance, endurance, etc'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M91.3,33.9L81.2,21.1c-0.6-0.7-1.6-0.8-2.3-0.3c-0.7,0.6-0.8,1.6-0.3,2.4l4,5.1l-6.3,5.1c-5.8-6.5-13.8-11.1-22.7-12.5
                        c2.5-2.2,4.1-5.3,4.1-8.9C57.8,5.3,52.6,0,46.2,0c-6.4,0-11.6,5.3-11.6,11.8c0,3.8,1.8,7.2,4.5,9.3C21.6,25.1,8.4,41,8.4,60.1
                        c0,22,17.6,39.9,39.1,39.9c21.6,0,39.1-17.9,39.1-39.9c0-9.1-3-17.5-8.1-24.2l6.2-5l4,5.1c0.3,0.4,0.8,0.6,1.3,0.6
                        c0.4,0,0.7-0.1,1-0.4C91.7,35.7,91.8,34.6,91.3,33.9z M37.9,11.8c0-4.7,3.7-8.5,8.3-8.5c4.6,0,8.3,3.8,8.3,8.5
                        c0,4.1-2.9,7.5-6.7,8.3l0-6.6H49c0.9,0,1.6-0.8,1.6-1.7c0-0.9-0.7-1.7-1.6-1.7h-5.7c-0.9,0-1.6,0.8-1.6,1.7c0,0.9,0.7,1.7,1.6,1.7
                        h1.2l0,6.6C40.7,19.3,37.9,15.9,37.9,11.8z M83.3,60.1c0,20.1-16.1,36.5-35.8,36.5S11.7,80.3,11.7,60.1
                        c0-19.6,15.3-35.7,34.3-36.5c0,0,0.1,0,0.1,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0.2,0,0.3,0,0.5-0.1c0.3,0,0.5,0,0.8,0
                        C67.2,23.6,83.3,40,83.3,60.1z M49.3,56.1V38.3c0-0.9-0.7-1.7-1.6-1.7S46,37.4,46,38.3v17.8c-1.7,0.7-3,2.4-3,4.4
                        c0,2,1.2,3.7,3,4.4v3c0,0.9,0.7,1.7,1.6,1.7s1.6-0.8,1.6-1.7v-3c1.7-0.7,3-2.4,3-4.4C52.3,58.4,51,56.8,49.3,56.1z M47.7,28.7
                        c-17.2,0-31.1,14.2-31.1,31.7c0,17.5,14,31.7,31.1,31.7c17.2,0,31.1-14.2,31.1-31.7S64.8,28.7,47.7,28.7z M47.7,88.8
                        c-15.3,0-27.8-12.7-27.8-28.4c0-15.6,12.5-28.4,27.8-28.4c15.3,0,27.8,12.7,27.8,28.4C75.5,76.1,63,88.8,47.7,88.8z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Steps counter',
					text: 'In-built navigation and motion sensors will ensure that every step taken by the user during a day is counted by steps counter.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<path
		d="M35,16.8V0.8C35,0.4,34.6,0,34.2,0H0.8C0.4,0,0,0.4,0,0.8v41.7c0,0.5,0.4,0.8,0.8,0.8H20c3,4,7.9,6.7,13.3,6.7
                    C42.5,50,50,42.5,50,33.3C50,24.7,43.4,17.6,35,16.8z M1.7,41.7v-40h31.7v15c-0.6,0-1.1,0-1.7,0.1v-0.9c0-0.5-0.4-0.8-0.8-0.8h-6.7
                    c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.3,0.1,0.5,0.3,0.6c-1.1,0.8-2.1,1.7-2.9,2.7H20v0.9c-0.4,0.5-0.8,1.1-1.1,1.6h-5.6v1.7h4.7
                    c-0.6,1.3-1,2.7-1.2,4.2h-3.5v1.7h3.4c0,0.3,0,0.6,0,0.8c0,1.1,0.1,2.3,0.3,3.3h-3.7v1.7h4.1c0.4,1.2,0.9,2.3,1.5,3.3H1.7z
                    M26.1,18.3H25v-1.7h5V17C28.6,17.3,27.3,17.7,26.1,18.3z M33.3,48.3c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15
                    S41.6,48.3,33.3,48.3z"
	></path>
	<path
		d="M33.3,24.2c0-0.5-0.4-0.8-0.8-0.8h-8.3c-0.5,0-0.8,0.4-0.8,0.8c0,1.2-0.3,1.7-0.7,2.3c-0.4,0.7-1,1.6-1,3.2
                    c0,1.6,0.5,2.5,1,3.2c0.4,0.7,0.7,1.2,0.7,2.3S23,37,22.6,37.6c-0.4,0.7-1,1.6-1,3.2c0,0.5,0.4,0.8,0.8,0.8h8.3
                    c0.5,0,0.8-0.4,0.8-0.8c0-1.2,0.3-1.7,0.7-2.3c0.4-0.7,1-1.6,1-3.2c0-1.6-0.5-2.5-1-3.2c-0.4-0.7-0.7-1.2-0.7-2.3
                    c0-1.2,0.3-1.7,0.7-2.3C32.8,26.6,33.3,25.8,33.3,24.2z M24,32.1c-0.4-0.7-0.7-1.2-0.7-2.3c0-1.2,0.3-1.7,0.7-2.3
                    c0.4-0.6,0.8-1.3,0.9-2.4h2.5c-0.1,0.6-0.4,1-0.6,1.5c-0.4,0.7-1,1.6-1,3.2c0,1.6,0.5,2.5,1,3.2c0.4,0.7,0.7,1.2,0.7,2.3
                    s-0.3,1.7-0.7,2.3c-0.4,0.6-0.8,1.3-0.9,2.4h-2.5c0.1-0.6,0.4-1,0.6-1.5c0.4-0.7,1-1.6,1-3.2C25,33.7,24.5,32.8,24,32.1z M31,37.6
                    c-0.4,0.6-0.8,1.3-0.9,2.4h-2.5c0.1-0.6,0.4-1,0.6-1.5c0.4-0.7,1-1.6,1-3.2c0-1.6-0.5-2.5-1-3.2c-0.4-0.7-0.7-1.2-0.7-2.3
                    c0-1.2,0.3-1.7,0.7-2.3c0.4-0.6,0.8-1.3,0.9-2.4h2.5c-0.1,0.6-0.4,1-0.6,1.5c-0.4,0.7-1,1.6-1,3.2c0,1.6,0.5,2.5,1,3.2
                    c0.4,0.7,0.7,1.2,0.7,2.3S31.4,37,31,37.6z"
	></path>
	<path
		d="M35,34.3c0,3.9,2.4,6.5,5.8,6.5s5.8-2.7,5.8-6.5c0-4-2.5-8.5-5.8-8.5C37.5,25.8,35,30.3,35,34.3z M45,34.3
                    c0,3-1.6,4.9-4.2,4.9s-4.2-1.9-4.2-4.9c0-3.4,2.1-6.8,4.2-6.8C42.9,27.5,45,30.9,45,34.3z"
	></path>
	<path
		d="M37.5,34.2c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3C39,30.8,37.5,32.3,37.5,34.2z M42.5,34.2
                    c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7C41.8,32.5,42.5,33.2,42.5,34.2z"
	></path>
	<path
		d="M3.8,12.5h26.7c0.5,0,0.8-0.4,0.8-0.8V5c0-0.5-0.4-0.8-0.8-0.8H3.8C3.4,4.2,3,4.5,3,5v6.7C3,12.1,3.4,12.5,3.8,12.5z
                    M4.7,5.8h25v5h-25V5.8z"
	></path>
	<path
		d="M10.8,15H4.2c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h6.7c0.5,0,0.8-0.4,0.8-0.8v-3.3C11.7,15.4,11.3,15,10.8,15z
                    M10,18.3H5v-1.7h5V18.3z"
	></path>
	<path
		d="M20.8,15h-6.7c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h6.7c0.5,0,0.8-0.4,0.8-0.8v-3.3C21.7,15.4,21.3,15,20.8,15
                    z M20,18.3h-5v-1.7h5V18.3z"
	></path>
	<path d="M10,25H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V25z"></path>
	<path d="M10,30.8H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V30.8z"></path>
	<path d="M10,36.7H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V36.7z"></path>
	<rect x="26.7" y="7.5" width="1.7" height="1.7"></rect>
	<rect x="23.3" y="7.5" width="1.7" height="1.7"></rect>
	<rect x="20" y="7.5" width="1.7" height="1.7"></rect>
	<rect x="16.7" y="7.5" width="1.7" height="1.7"></rect>
	<rect x="13.3" y="7.5" width="1.7" height="1.7"></rect></svg
>`,
					title: 'Calorie counter',
					text: 'Users can input the food items that are consuming daily to keep track of their calorie intake.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M28.6,5.3H15.4c-0.5,0-0.8,0.4-0.8,0.8S14.9,7,15.4,7h13.3c0.5,0,0.8-0.4,0.8-0.8C29.4,5.7,29.1,5.3,28.6,5.3z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M32.7,2.1c-0.5,0-0.8,0.4-0.8,0.8v6.4c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8V2.9C33.5,2.5,33.1,2.1,32.7,2.1z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M11.5,2.1c-0.5,0-0.8,0.4-0.8,0.8v6.4c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V2.9C12.3,2.5,11.9,2.1,11.5,2.1z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M38,15.4H6.1c-0.5,0-0.8,0.4-0.8,0.8S5.7,17,6.1,17H38c0.5,0,0.8-0.4,0.8-0.8C38.8,15.7,38.5,15.4,38,15.4z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M30.6,21.8H6.1c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h24.5c0.5,0,0.8-0.4,0.8-0.8S31,21.8,30.6,21.8z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M19.9,28.2H6.1c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h13.8c0.5,0,0.8-0.4,0.8-0.8S20.4,28.2,19.9,28.2z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M48.8,31.9c-1-2-2.7-3.6-4.6-4.6V6.1c0-0.4-0.3-0.8-0.8-0.8c0,0,0,0,0,0h-7.4c-0.5,0-0.8,0.4-0.8,0.8
                        c0,0.5,0.4,0.8,0.8,0.8h6.6v19.6c-1.1-0.4-2.3-0.6-3.4-0.6c-6,0-10.9,4.9-10.9,10.9c0,1.2,0.2,2.3,0.6,3.4H1.6V7h5.5
                        C7.6,7,8,6.6,8,6.2c0-0.5-0.4-0.8-0.8-0.8H0.8C0.4,5.3,0,5.7,0,6.1c0,0,0,0,0,0.1v35.1C0,41.6,0.3,42,0.7,42c0,0,0,0,0.1,0h28.6
                        c2.8,5.3,9.4,7.4,14.7,4.6S51.5,37.3,48.8,31.9z M39.1,46.3c-5.1,0-9.3-4.2-9.3-9.3s4.2-9.3,9.3-9.3s9.3,4.2,9.3,9.3
                        C48.4,42.1,44.2,46.3,39.1,46.3z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M44.9,40.7h-0.8v-4c0-2.5-1.8-4.6-4.2-5v-1.4c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v1.4c-2.5,0.4-4.3,2.5-4.3,5v4
                        h-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h5.1v1.3c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-1.3h5
                        c0.5,0,0.8-0.4,0.8-0.8C45.7,41,45.4,40.7,44.9,40.7z M42.5,40.7h-6.9v-4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4V40.7z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Alerts and reminders',
					text: 'Built-in alert and reminder system that notifies when it is time for daily workout.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M50,32.3c-0.1-1.1-0.6-2.1-1.5-2.8c-1.5-1.1-3.5-1-4.8,0.3l-8.1,7.5c0-1.7-1-3.1-2.7-3.5l-9.8-2.5
                        c-1.7-0.4-3.5-0.4-5.2,0.2l-6.8,2.2v-2c0-0.5-0.4-1-1-1H1c-0.5,0-1,0.4-1,1V49c0,0.5,0.4,1,1,1h9.1c0.5,0,1-0.4,1-1V35.6l7.4-2.4
                        c1.3-0.4,2.8-0.5,4.1-0.1l9.8,2.5c0.8,0.2,1.3,0.9,1.2,1.7c-0.1,0.8-0.8,1.4-1.6,1.4h-7.8c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3.1
                        c0,0,0.1,0,0.1,0h4.8c1.6,0,3.2-0.6,4.3-1.7l8.4-7.8c0.6-0.6,1.6-0.6,2.3-0.1c0.4,0.3,0.7,0.8,0.7,1.3c0,0.5-0.2,1.1-0.6,1.4
                        L35.9,44.6c-0.8,0.7-1.8,1.2-2.9,1.2H17.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1H33c1.6,0,3.1-0.6,4.3-1.7l11.6-10.8
                        C49.6,34.5,50.1,33.4,50,32.3z M9.1,48H2V32.6h7.2L9.1,48L9.1,48z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M14.6,46c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C14.9,46.4,14.8,46.2,14.6,46z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M6.2,45c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C6.5,45.4,6.4,45.2,6.2,45z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M21.2,0h-1.1c-4.7,0-8.6,3.9-8.6,8.6v2.5c-3.1,0.3-5.5,2.9-5.5,6c0,3.3,2.7,6.1,6.1,6.1h0.4c0.5,0,1-0.4,1-1V8.6
                        c0-3.7,3-6.7,6.7-6.7h1.1c0.5,0,1-0.4,1-1C22.2,0.4,21.8,0,21.2,0z M11.6,19.6v1.6c-2-0.3-3.5-2-3.5-4.1s1.5-3.8,3.5-4.1V19.6z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M25,0L25,0c-0.6,0-1,0.4-1,1c0,0.5,0.4,1,1,1h0c0.5,0,1-0.4,1-1C26,0.4,25.6,0,25,0z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M38.4,11.1V8.6c0-4.7-3.9-8.6-8.6-8.6h-1.1c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h1.1c3.7,0,6.7,3,6.7,6.7V25
                        c0,0.3-0.3,0.6-0.6,0.6h-7.3c-0.4-0.9-1.3-1.6-2.3-1.6H24c-1.4,0-2.5,1.1-2.5,2.5v0c0,1.4,1.1,2.5,2.5,2.5h2.2
                        c1.1,0,2-0.6,2.3-1.6h7.3c1.4,0,2.6-1.1,2.6-2.6v-1.8c3.1-0.3,5.5-2.9,5.5-6S41.5,11.4,38.4,11.1z M26.3,27.1H24
                        c-0.3,0-0.6-0.3-0.6-0.6v0c0-0.3,0.3-0.6,0.6-0.6h2.2c0.3,0,0.6,0.3,0.6,0.6C26.8,26.9,26.6,27.1,26.3,27.1z M38.4,21.2v-1.6v-6.5
                        c2,0.3,3.5,2,3.5,4.1S40.4,20.9,38.4,21.2z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M33.6,10.8h-0.9c-0.1-0.4-0.3-0.8-0.5-1.1L32.9,9c0.2-0.2,0.3-0.4,0.3-0.7S33,7.8,32.9,7.7l-2.2-2.2c-0.4-0.4-1-0.4-1.4,0
                        l-0.6,0.6c-0.4-0.2-0.7-0.3-1.1-0.5V4.7c0-0.5-0.4-1-1-1h-3.1c-0.5,0-1,0.4-1,1v0.9c-0.4,0.1-0.8,0.3-1.1,0.5l-0.6-0.6
                        c-0.4-0.4-1-0.4-1.4,0l-2.2,2.2c-0.2,0.2-0.3,0.4-0.3,0.7S17,8.9,17.1,9l0.7,0.7c-0.2,0.4-0.3,0.7-0.5,1.1h-0.9c-0.5,0-1,0.4-1,1
                        v3.1c0,0.5,0.4,1,1,1h0.9c0.1,0.4,0.3,0.8,0.5,1.1l-0.7,0.7c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7l2.2,2.2
                        c0.4,0.4,1,0.4,1.4,0l0.7-0.7c0.4,0.2,0.7,0.3,1.1,0.5v0.9c0,0.5,0.4,1,1,1h3.1c0.5,0,1-0.4,1-1V21c0.4-0.1,0.8-0.3,1.1-0.5
                        l0.7,0.7c0.4,0.4,1,0.4,1.4,0l2.2-2.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L32.2,17c0.2-0.4,0.3-0.7,0.5-1.1h0.9
                        c0.5,0,1-0.4,1-1v-3.1C34.6,11.3,34.1,10.8,33.6,10.8z M32.6,13.9h-0.7c-0.5,0-0.9,0.3-1,0.8c-0.2,0.7-0.4,1.4-0.8,2
                        c-0.2,0.4-0.2,0.9,0.1,1.2l0.5,0.5L30,19.1l-0.5-0.5c-0.3-0.3-0.8-0.4-1.2-0.1c-0.6,0.4-1.3,0.7-2,0.8c-0.4,0.1-0.8,0.5-0.8,1V21
                        h-1.1v-0.7c0-0.5-0.3-0.9-0.8-1c-0.7-0.2-1.4-0.4-2-0.8c-0.4-0.2-0.9-0.2-1.2,0.1L20,19.1l-0.8-0.8l0.5-0.5
                        c0.3-0.3,0.4-0.8,0.1-1.2c-0.4-0.6-0.7-1.3-0.8-2c-0.1-0.4-0.5-0.8-1-0.8h-0.7v-1.1h0.7c0.4,0,0.8-0.3,0.9-0.7c0,0,0,0,0,0
                        c0.2-0.7,0.4-1.4,0.8-2c0.2-0.4,0.2-0.9-0.1-1.2l-0.5-0.5L20,7.6L20.5,8c0.3,0.3,0.8,0.4,1.2,0.1c0.6-0.4,1.3-0.7,1.9-0.8
                        c0.4-0.1,0.8-0.5,0.8-1V5.7h1.1v0.7c0,0.5,0.3,0.9,0.8,1c0,0,0,0,0,0c0.7,0.2,1.4,0.4,2,0.8c0.4,0.2,0.9,0.2,1.2-0.1L30,7.6
                        l0.8,0.8l-0.5,0.5C30,9.2,29.9,9.7,30.2,10c0.4,0.6,0.7,1.3,0.8,1.9c0.1,0.4,0.5,0.8,1,0.8h0.7L32.6,13.9L32.6,13.9z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M25,9.6c-2,0-3.7,1.7-3.7,3.7S23,17,25,17c2,0,3.7-1.7,3.7-3.7C28.7,11.3,27,9.6,25,9.6z M25,15.1c-1,0-1.8-0.8-1.8-1.8
                        c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C26.8,14.3,26,15.1,25,15.1z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M5.5,34.4c-0.5,0-1,0.4-1,1v6.7c0,0.5,0.4,1,1,1s1-0.4,1-1v-6.7C6.5,34.8,6.1,34.4,5.5,34.4z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Customer support',
					text: 'Round the clock, multi-channel support to attend to any troubleshooting issues with the app.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<path
		d="M49.2,31.7H34.8l14.9-14.9c0.3-0.3,0.3-0.9,0-1.2L34.4,0.2c-0.3-0.3-0.9-0.3-1.2,0l-6.6,6.6V5c0-1.8-1.5-3.3-3.3-3.3h-20
                    C1.5,1.7,0,3.2,0,5v41.7C0,48.5,1.5,50,3.3,50h45.8c0.5,0,0.8-0.4,0.8-0.8V32.5C50,32,49.6,31.7,49.2,31.7z M48.3,33.3V35H31.5
                    l1.7-1.7H48.3z M26.7,18.8c2.7,0.3,4.8,2.7,4.5,5.4c-0.2,2.4-2.1,4.3-4.5,4.5V18.8z M25,41.7H1.7V10H25V41.7z M29.8,36.7h18.5v1.7
                    H28.2L29.8,36.7z M45.1,19c-1.2-1.7-1.2-4,0-5.8l2.9,2.9L45.1,19z M33.8,2l2.9,2.9c-1.7,1.2-4,1.2-5.8,0L33.8,2z M29.8,6.1
                    c2.4,1.9,5.8,1.9,8.2,0l6,6c-1.9,2.4-1.9,5.8,0,8.2L26.7,37.5v-7c3.7-0.3,6.4-3.4,6.2-7.1c-0.2-3.3-2.9-5.9-6.2-6.2v-8L29.8,6.1z
                    M3.3,3.3h20C24.3,3.3,25,4.1,25,5v3.3H1.7V5C1.7,4.1,2.4,3.3,3.3,3.3z M3.3,48.3c-0.9,0-1.7-0.7-1.7-1.7v-3.3H25v3.3
                    c0,0.9-0.7,1.7-1.7,1.7H3.3z M26.2,48.3c0.3-0.5,0.5-1.1,0.5-1.7h10.8V45H26.7v-1.7h0.8v-1.7h-0.8V40h21.7v8.3H26.2z"
	></path>
	<rect x="29.2" y="41.6" width="1.7" height="1.7"></rect>
	<rect x="32.5" y="41.6" width="1.7" height="1.7"></rect>
	<rect x="35.8" y="41.6" width="1.7" height="1.7"></rect>
	<path
		d="M45.8,41.7H40c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h5.8c0.5,0,0.8-0.4,0.8-0.8v-3.3
                    C46.7,42,46.3,41.7,45.8,41.7z M45,45h-4.2v-1.7H45V45z"
	></path>
	<rect
		x="31.8"
		y="16.5"
		transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.6877 28.1759)"
		width="1.7"
		height="1.7"
	></rect>
	<rect x="12.5" y="45" width="1.7" height="1.7"></rect>
	<rect x="10" y="5" width="6.7" height="1.7"></rect>
	<path
		d="M12.7,25.6H14c0.6,0,1,0.5,1,1v0.6c0,0.6-0.5,1-1,1h-1.3c-0.6,0-1-0.5-1-1H10c0,1.4,1.1,2.6,2.5,2.7v1.5h1.7V30
                    c1.4-0.1,2.5-1.3,2.5-2.7v-0.6c0-1.5-1.2-2.7-2.7-2.7h-1.3c-0.6,0-1-0.5-1-1v-0.6c0-0.6,0.5-1,1-1H14c0.6,0,1,0.5,1,1h1.7
                    c0-1.4-1.1-2.6-2.5-2.7v-1.5h-1.7v1.5c-1.4,0.1-2.5,1.3-2.5,2.7v0.6C10,24.4,11.2,25.6,12.7,25.6z"
	></path></svg
>`,
					title: 'Payment options',
					text: 'In-app purchases and multiple payment options to enable users to pay in the medium that suits them the most.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M50,32.3c-0.1-1.1-0.6-2.1-1.5-2.8c-1.5-1.1-3.5-1-4.8,0.3l-8.1,7.5c0-1.7-1-3.1-2.7-3.5l-9.8-2.5
                        c-1.7-0.4-3.5-0.4-5.2,0.2l-6.8,2.2v-2c0-0.5-0.4-1-1-1H1c-0.5,0-1,0.4-1,1V49c0,0.5,0.4,1,1,1h9.1c0.5,0,1-0.4,1-1V35.6l7.4-2.4
                        c1.3-0.4,2.8-0.5,4.1-0.1l9.8,2.5c0.8,0.2,1.3,0.9,1.2,1.7c-0.1,0.8-0.8,1.4-1.6,1.4h-6.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h2.1
                        c0,0,0.1,0,0.1,0h4.8c1.6,0,3.2-0.6,4.3-1.7l8.4-7.8c0.6-0.6,1.6-0.6,2.3-0.1c0.4,0.3,0.7,0.8,0.7,1.3c0,0.5-0.2,1.1-0.6,1.4
                        L35.9,44.6c-0.8,0.7-1.8,1.2-2.9,1.2H17.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1H33c1.6,0,3.1-0.6,4.3-1.7l11.6-10.8
                        C49.6,34.5,50.1,33.4,50,32.3z M9.1,48H2V32.6h7.2L9.1,48L9.1,48z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M14.6,46c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C14.9,46.4,14.8,46.2,14.6,46z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M6.2,45c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7C6.5,45.4,6.4,45.2,6.2,45z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M25,0c-4.8,0-8.8,3.9-8.8,8.8c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9s1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9
                        s-1.3,2.9-2.9,2.9c-1.6,0-2.9,1.3-2.9,2.9v4.2c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9v-1.8c3.5-1.2,5.9-4.5,5.9-8.3
                        C33.8,3.9,29.8,0,25,0z M26.7,15.4c-0.4,0.1-0.7,0.5-0.7,0.9v2.5c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1v-4.2c0-0.5,0.4-1,1-1
                        c2.7,0,4.9-2.2,4.9-4.9S27.7,3.9,25,3.9s-4.9,2.2-4.9,4.9c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1C18.2,5,21.2,2,25,2s6.8,3.1,6.8,6.8
                        C31.8,11.9,29.7,14.6,26.7,15.4z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M27.1,24.9C26.5,24.3,25.8,24,25,24c-0.8,0-1.5,0.3-2.1,0.9c-0.5,0.5-0.9,1.3-0.9,2.1c0,0.8,0.3,1.5,0.9,2.1
                        c0.5,0.5,1.3,0.9,2.1,0.9c0.8,0,1.5-0.3,2.1-0.9c0.5-0.5,0.9-1.3,0.9-2.1C27.9,26.2,27.6,25.4,27.1,24.9z M25.7,27.7
                        c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3C24.1,27.5,24,27.2,24,27c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3
                        c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7C26,27.2,25.9,27.5,25.7,27.7z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M5.5,34.6c-0.5,0-1,0.4-1,1v6.2c0,0.5,0.4,1,1,1s1-0.4,1-1v-6.2C6.5,35.1,6.1,34.6,5.5,34.6z"
			></path>
		</g>
	</g></svg
>`,
					title: 'FAQ',
					text: 'A detailed FAQ section where every possible query is addressed in a detailed manner.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<path
		d="M47.6,7.3H2.4C1.1,7.3,0,8.3,0,9.7v30.6c0,1.3,1.1,2.4,2.4,2.4h45.2c1.3,0,2.4-1.1,2.4-2.4V9.7C50,8.3,48.9,7.3,47.6,7.3z
                    M1.6,9.7c0-0.4,0.4-0.8,0.8-0.8h45.2c0.4,0,0.8,0.4,0.8,0.8v5.6H1.6V9.7z M48.4,40.3c0,0.4-0.4,0.8-0.8,0.8H2.4
                    c-0.4,0-0.8-0.4-0.8-0.8V16.9h46.8V40.3z"
	></path>
	<path
		d="M4.8,9.7c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S6.2,9.7,4.8,9.7z M4.8,12.9c-0.4,0-0.8-0.4-0.8-0.8
                    c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C5.6,12.5,5.3,12.9,4.8,12.9z"
	></path>
	<path
		d="M10.5,9.7c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S11.8,9.7,10.5,9.7z M10.5,12.9c-0.4,0-0.8-0.4-0.8-0.8
                    c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8C11.3,12.5,10.9,12.9,10.5,12.9z"
	></path>
	<path
		d="M16.1,9.7c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4S17.5,9.7,16.1,9.7z M16.1,12.9c-0.4,0-0.8-0.4-0.8-0.8
                    c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8C16.9,12.5,16.6,12.9,16.1,12.9z"
	></path>
	<path
		d="M4,36.3h16.1c0.4,0,0.8-0.4,0.8-0.8V22.6c0-0.4-0.4-0.8-0.8-0.8H4c-0.4,0-0.8,0.4-0.8,0.8v12.9C3.2,35.9,3.6,36.3,4,36.3z
                    M4.8,23.4h14.5v11.3H4.8V23.4z"
	></path>
	<path
		d="M22.6,23.4h24.1c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H22.6c-0.4,0-0.8,0.4-0.8,0.8C21.8,23,22.1,23.4,22.6,23.4z"
	></path>
	<path
		d="M46.8,25h-7.3c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h7.3c0.4,0,0.8-0.4,0.8-0.8S47.2,25,46.8,25z"
	></path>
	<path
		d="M22.6,26.6h13.7c0.4,0,0.8-0.4,0.8-0.8S36.8,25,36.3,25H22.6c-0.4,0-0.8,0.4-0.8,0.8S22.1,26.6,22.6,26.6z"
	></path>
	<path
		d="M22.6,36.3h3.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-3.2c-0.4,0-0.8,0.4-0.8,0.8C21.8,35.9,22.1,36.3,22.6,36.3z"
	></path>
	<path
		d="M42.7,34.7H29.1c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h13.6c0.4,0,0.8-0.4,0.8-0.8C43.5,35,43.2,34.7,42.7,34.7z"
	></path>
	<path
		d="M22.6,33.1h24.3c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H22.6c-0.4,0-0.8,0.4-0.8,0.8C21.8,32.7,22.1,33.1,22.6,33.1z"
	></path>
	<path
		d="M22.6,29.8h24.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8H22.6c-0.4,0-0.8,0.4-0.8,0.8C21.8,29.5,22.1,29.8,22.6,29.8z"
	></path></svg
>`,
					title: 'Blogs',
					text: 'A branded blog complete with facility to publish text, image and video blogs.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M49.2,0H0.8C0.4,0,0,0.4,0,0.8v48.3C0,49.6,0.4,50,0.8,50h48.3c0.5,0,0.8-0.4,0.8-0.8V0.8C50,0.4,49.6,0,49.2,0z
                        M48.3,48.3H1.7V1.7h46.7V48.3z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M24.2,17.5h-1c0.1-0.3,0.1-0.5,0.1-0.8c0-0.9-0.4-1.7-1-2.4c0.1-0.3,0.2-0.6,0.2-1c0-1.8-1.5-3.3-3.3-3.3
                        c-0.1,0-0.3,0-0.4,0c-0.6-1-1.7-1.7-2.9-1.7S13.5,9,12.9,10c-0.1,0-0.3,0-0.4,0c-1.8,0-3.3,1.5-3.3,3.3c0,0.3,0.1,0.7,0.2,1
                        c-0.6,0.6-1,1.5-1,2.4c0,0.3,0.1,0.6,0.1,0.8h-1c-0.5,0-0.8,0.4-0.8,0.8V20c0,2.9,1.7,5.5,4.2,6.7v1.6c0,1.4,1.1,2.5,2.5,2.5h5
                        c1.4,0,2.5-1.1,2.5-2.5v-1.6c2.5-1.2,4.2-3.8,4.2-6.7v-1.7C25,17.9,24.6,17.5,24.2,17.5z M10.8,15.3c0.4-0.2,0.5-0.7,0.3-1.1
                        c-0.1-0.3-0.2-0.5-0.2-0.8c0-1.1,1.2-2,2.3-1.5c0.2,0.1,0.5,0.1,0.7,0c0.2-0.1,0.4-0.3,0.4-0.5c0.2-0.7,0.9-1.2,1.6-1.2
                        c0.7,0,1.4,0.5,1.6,1.2c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.5,0.1,0.7,0c1.1-0.4,2.3,0.4,2.3,1.5c0,0.3-0.1,0.6-0.2,0.8
                        c-0.2,0.4-0.1,0.9,0.3,1.1c0.5,0.3,0.8,0.8,0.8,1.4c0,0.3-0.1,0.6-0.2,0.8H10.2C10.1,17.3,10,17,10,16.7
                        C10,16.1,10.3,15.6,10.8,15.3z M19.2,28.3c0,0.5-0.4,0.8-0.8,0.8h-5c-0.5,0-0.8-0.4-0.8-0.8v-0.8h1.7h3.3h1.7V28.3z M23.3,20
                        c0,3.2-2.6,5.8-5.8,5.8h-3.3c-3.2,0-5.8-2.6-5.8-5.8v-0.8h1.5h12h1.5V20z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M20,20.8c0,0.5-0.4,0.8-0.8,0.8v1.7c1.4,0,2.5-1.1,2.5-2.5H20z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<rect x="14.2" y="14.2" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="17.5" y="14.2" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="15.8" y="22.5" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M27.5,3.3H4.2c-0.5,0-0.8,0.4-0.8,0.8V35c0,0.5,0.4,0.8,0.8,0.8h23.3c0.5,0,0.8-0.4,0.8-0.8V4.2C28.3,3.7,28,3.3,27.5,3.3
                        z M26.7,34.2H5V5h21.7V34.2z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M24.2,38.3H7.5c-0.5,0-0.8,0.4-0.8,0.8v6.7c0,0.5,0.4,0.8,0.8,0.8h16.7c0.5,0,0.8-0.4,0.8-0.8v-6.7
                        C25,38.7,24.6,38.3,24.2,38.3z M23.3,45h-15v-5h15V45z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<rect x="10" y="41.7" width="11.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="30" y="16.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="33.3" y="16.7" width="8.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="30" y="21.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="33.3" y="21.7" width="8.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="30" y="26.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="33.3" y="26.7" width="8.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="30" y="31.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="33.3" y="31.7" width="8.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="43.3" y="16.7" width="3.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="43.3" y="21.7" width="3.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="43.3" y="26.7" width="3.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="43.3" y="31.7" width="3.3" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M45.8,3.3h-15c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5,0.4,0.8,0.8,0.8h15c0.5,0,0.8-0.4,0.8-0.8V4.2
                        C46.7,3.7,46.3,3.3,45.8,3.3z M45,11.7H31.7V5H45V11.7z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<rect x="33.3" y="7.5" width="10" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M40.8,35c-2.6,0-4.9,1.7-5.6,4.2h-6c-0.4,0-0.8,0.3-0.8,0.7c-0.1,0.3-0.1,0.7-0.1,1c0,3.2,2.6,5.8,5.8,5.8
                        c1.2,0,2.4-0.4,3.3-1.1c1,0.7,2.1,1.1,3.3,1.1c3.2,0,5.8-2.6,5.8-5.8S44,35,40.8,35z M38.3,41.6C38.2,41.6,38.2,41.7,38.3,41.6
                        c-0.1,0.3-0.1,0.5-0.2,0.7c0,0,0,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.3,0.3-0.4,0.5
                        c0,0-0.1,0.1-0.1,0.1c-0.7,0.7-1.8,1.2-2.9,1.2c-2.3,0-4.2-1.9-4.2-4.2h5.9h2.4C38.3,41.1,38.3,41.4,38.3,41.6z M40.8,45
                        c-0.7,0-1.5-0.2-2.1-0.6c0,0,0,0,0,0c0.4-0.5,0.7-1,0.9-1.6c0,0,0,0,0-0.1c0.1-0.3,0.2-0.6,0.2-0.9c0,0,0-0.1,0-0.1
                        c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-1c-0.1-0.4-0.4-0.7-0.8-0.7H37c0.6-1.5,2.1-2.5,3.8-2.5c2.3,0,4.2,1.9,4.2,4.2
                        C45,43.1,43.1,45,40.8,45z"
			></path>
		</g>
	</g>
	<g>
		<g>
			<rect x="31.7" y="41.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<rect x="35" y="41.7" width="1.7" height="1.7"></rect>
		</g>
	</g>
	<g>
		<g>
			<path
				d="M41.7,40.8c0,0.5-0.4,0.8-0.8,0.8v1.7c1.4,0,2.5-1.1,2.5-2.5H41.7z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Recipes',
					text: 'Explore and find recipes for weight gain, reduction or improving endurance.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 50 50"
	style="enable-background: new 0 0 50 50"
	xml:space="preserve"
>
	<path
		d="M28.3,30.3h-7.6c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3h6.1v-1.5h-6.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h7.6
                    c2.1,0,3.8-1.7,3.8-3.8c0-1.7-1.2-3.2-2.8-3.7l-0.4,1.5c1.2,0.3,1.9,1.6,1.6,2.8C30.3,29.6,29.4,30.3,28.3,30.3z"
	></path>
	<rect x="23.8" y="27.3" width="1.5" height="1.5"></rect>
	<rect x="26.8" y="27.3" width="1.5" height="1.5"></rect>
	<rect x="20.8" y="27.3" width="1.5" height="1.5"></rect>
	<path
		d="M10.9,18.2h18l-1.3,5.1c-0.1,0.4,0.1,0.8,0.6,0.9c0.3,0.1,0.5,0,0.7-0.2l5.8-5.8h3.5c5,0,9.1-4.1,9.1-9.1S43.2,0,38.2,0
                    H10.9c-5,0-9.1,4.1-9.1,9.1S5.9,18.2,10.9,18.2z M10.9,1.5h27.3c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6h-3.8
                    c-0.2,0-0.4,0.1-0.5,0.2L29.7,21l0.9-3.4c0.1-0.4-0.1-0.8-0.6-0.9c-0.1,0-0.1,0-0.2,0H10.9c-4.2,0-7.6-3.4-7.6-7.6S6.7,1.5,10.9,1.5
                    z"
	></path>
	<path
		d="M24.5,15.2c3.3,0,6.1-2.7,6.1-6.1S27.9,3,24.5,3s-6.1,2.7-6.1,6.1C18.5,12.4,21.2,15.1,24.5,15.2z M24.5,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C20,6.6,22,4.5,24.5,4.5z"
	></path>
	<path
		d="M10.9,15.2c3.3,0,6.1-2.7,6.1-6.1S14.3,3,10.9,3S4.8,5.7,4.8,9.1C4.9,12.4,7.6,15.1,10.9,15.2z M10.9,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C6.4,6.6,8.4,4.5,10.9,4.5z"
	></path>
	<path
		d="M38.2,15.2c3.3,0,6.1-2.7,6.1-6.1S41.5,3,38.2,3c-3.3,0-6.1,2.7-6.1,6.1C32.1,12.4,34.8,15.1,38.2,15.2z M38.2,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C33.6,6.6,35.7,4.5,38.2,4.5z"
	></path>
	<rect x="21.5" y="7.6" width="1.5" height="1.5"></rect>
	<rect x="26.1" y="7.6" width="1.5" height="1.5"></rect>
	<rect x="23" y="10.6" width="3" height="1.5"></rect>
	<rect x="7.9" y="7.6" width="1.5" height="1.5"></rect>
	<rect x="12.4" y="7.6" width="1.5" height="1.5"></rect>
	<path
		d="M9.9,11.8c0.5-0.5,1.4-0.5,2,0l1.1-1.1c-1.1-1.1-3-1.1-4.1,0L9.9,11.8z"
	></path>
	<rect x="35.2" y="7.6" width="1.5" height="1.5"></rect>
	<rect x="39.7" y="7.6" width="1.5" height="1.5"></rect>
	<path
		d="M38.2,12c0.8,0,1.5-0.3,2.1-0.8l-1.1-1.1c-0.5,0.5-1.4,0.5-2,0l-1.1,1.1C36.7,11.7,37.4,12,38.2,12z"
	></path>
	<path
		d="M41.8,40c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
                    c0-0.5,0.4-0.9,0.9-0.9c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1V50H50V39.2c0-1.4-0.9-2.5-2.3-2.8c-0.8-0.1-1.6,0.1-2.2,0.6l-0.1,0
                    c-1-0.9-2.6-0.9-3.6,0c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.3-0.9,0.3-1.4V22.7c0-2-1.6-3.6-3.6-3.6h-2.7v10.2
                    c-1.1,0.4-1.8,1.4-1.8,2.6v5.5h-20v-4.2l0.1-0.1c1.6-1,2.1-3.1,1.2-4.8c-0.3-0.5-0.8-0.9-1.3-1.2v-6.1h13.6v-1.8H10.9
                    c-2,0-3.6,1.6-3.6,3.6V24L3,26.5c-1.9,1.2-3,3.2-3,5.4V50h1.8v-4.5h7.3V50h1.8v-5.5c0-0.5-0.4-0.9-0.9-0.9H9.1v-0.5
                    c1-1.1,1.5-2.5,1.5-4c0.1,0,0.2,0,0.3,0h22.7v3.8l-2.4-1.4c-0.7-0.4-1.7-0.5-2.4-0.1l-0.1,0.1c-1.2,0.6-1.7,2.1-1.1,3.4
                    c0.2,0.4,0.5,0.7,0.8,0.9l5.3,3.8V50h1.8v-0.9c0-0.3-0.1-0.6-0.4-0.7l-5.7-4.1c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.5,0.4-0.6
                    l0.1-0.1c0.2-0.1,0.5-0.1,0.7,0l3.8,2.3c0.4,0.3,1,0.1,1.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5V31.8c0-0.5,0.4-0.9,0.9-0.9
                    c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1v7.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                    C40.9,39.6,41.3,40,41.8,40z M10.9,20.9h0.9v5.6c-0.7,0-1.3,0.2-1.9,0.6l-0.9,0.6v-5C9.1,21.7,9.9,20.9,10.9,20.9z M7.5,36.1
                    c1.7,1.7,1.7,4.3,0,6c-0.2,0.2-0.3,0.4-0.3,0.6v0.9H1.8V31.9c0-1.6,0.8-3,2.1-3.8l3.3-1.9v2.8L4,31.1l1,1.5l5.9-3.9
                    c0.8-0.5,1.8-0.3,2.4,0.5c0,0,0,0,0,0.1c0.5,0.8,0.2,1.8-0.6,2.3l-5.1,3.2c-0.4,0.3-0.6,0.8-0.3,1.3C7.4,36,7.5,36,7.5,36.1z
                    M10.3,37.2L10.3,37.2c-0.2-0.5-0.4-1-0.8-1.5l2.3-1.4v3h-0.9C10.7,37.3,10.5,37.2,10.3,37.2z M39.1,36.5v-4.6
                    c0-1.2-0.7-2.2-1.8-2.7v-8.3h0.9c1,0,1.8,0.8,1.8,1.8v12.7c0,0.3-0.1,0.7-0.3,0.9C39.5,36.4,39.3,36.4,39.1,36.5z"
	></path>
	<rect x="8.6" y="43.9" width="1.5" height="1.5"></rect></svg
>`,
					title: 'Rating and Reviews',
					text: 'Users can review and rate each personal trainer based on their training experience and results achieved.'
				}
			],
			items2: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <path d="M100,50c0-27.6-22.4-50-50-50S0,22.4,0,50c0,10.2,3.1,19.8,8.4,27.7l0,0l0.3,0.5c0.3,0.4,0.6,0.9,0.9,1.3
                    c0.1,0.2,0.3,0.3,0.4,0.5c0.4,0.5,0.8,1,1.2,1.6c0.1,0.1,0.2,0.3,0.4,0.4c1.9,2.3,4,4.4,6.3,6.3c0.1,0.1,0.3,0.2,0.4,0.3
                    c0.5,0.4,1,0.8,1.5,1.2c0.2,0.2,0.4,0.3,0.6,0.5c0.5,0.4,1,0.7,1.5,1c0.3,0.2,0.5,0.4,0.8,0.5c0.7,0.4,1.4,0.9,2.1,1.3
                    c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.6,0.3,0.9,0.5c0.5,0.3,1.1,0.5,1.6,0.8c0.3,0.1,0.6,0.3,0.8,0.4c0.6,0.3,1.3,0.6,1.9,0.8
                    c0.2,0.1,0.4,0.2,0.6,0.2c1.8,0.7,3.6,1.3,5.5,1.7c0.1,0,0.2,0,0.2,0.1c0.8,0.2,1.7,0.4,2.5,0.5c0.2,0,0.5,0.1,0.7,0.1
                    c0,0,0.1,0,0.1,0l0,0l0.9,0.1c0.2,0,0.4,0.1,0.6,0.1l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.8,0.1
                    c0.7,0.1,1.3,0.1,2,0.2c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c1,0.1,1.9,0.1,2.9,0.1c1,0,1.9,0,2.9-0.1c0.2,0,0.3,0,0.5,0
                    c0.1,0,0.3,0,0.4,0c0.7,0,1.3-0.1,2-0.2c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0l0.2,0c0.2,0,0.4-0.1,0.6-0.1
                    l0.9-0.1l0,0c0,0,0,0,0,0c0.2,0,0.5-0.1,0.7-0.1c0.8-0.2,1.7-0.3,2.5-0.5c0.1,0,0.2,0,0.2-0.1c1.9-0.5,3.7-1.1,5.5-1.7
                    c0.2-0.1,0.4-0.2,0.6-0.2c0.7-0.3,1.3-0.5,1.9-0.8c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.3,1.1-0.5,1.6-0.8c0.3-0.2,0.6-0.3,0.9-0.5
                    c0.5-0.3,1-0.6,1.5-0.9c0.7-0.4,1.4-0.8,2.1-1.3c0.3-0.2,0.5-0.3,0.8-0.5c0.5-0.3,1-0.7,1.5-1c0.2-0.2,0.4-0.3,0.6-0.5
                    c0.5-0.4,1-0.8,1.5-1.2c0.1-0.1,0.3-0.2,0.4-0.3c2.3-1.9,4.4-4,6.3-6.3c0.1-0.1,0.2-0.3,0.4-0.4c0.4-0.5,0.8-1,1.2-1.6
                    c0.1-0.2,0.3-0.3,0.4-0.5c0.3-0.4,0.6-0.8,0.9-1.3l0.3-0.5l0,0C96.9,69.8,100,60.2,100,50z M96.7,50c0,8.3-2.2,16.1-6,22.8
                    c-2.7-8.9-10.5-15.3-20-16.1l-0.1-0.1h-1.8h-8h-1.9c-0.3,0-0.6-0.3-0.6-0.6V55c0-0.3,0.2-0.5,0.5-0.6c10.7-4,17.4-20.8,16-32.5
                    c-0.7-6.1-3.7-11.5-8.1-15.5C84.2,13.2,96.7,30.1,96.7,50z M87.6,77.7c-0.1,0.2-0.3,0.4-0.4,0.5c-0.4,0.5-0.7,1-1.1,1.4
                    c0,0-0.1,0.1-0.1,0.1c-1.3,1.6-2.8,3.1-4.4,4.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-0.2,0.1-0.3,0.2-0.5,0.4
                    c-0.5,0.4-0.9,0.8-1.4,1.1c-0.2,0.1-0.3,0.2-0.5,0.4c-0.3,0.2-0.6,0.4-0.8,0.6V80h-3.3v10.4c-0.1,0-0.1,0.1-0.2,0.1
                    c-0.4,0.2-0.8,0.5-1.3,0.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.5,0.3-1,0.5-1.5,0.7c-0.3,0.1-0.5,0.2-0.8,0.3c-0.6,0.3-1.3,0.5-1.9,0.8
                    c-0.1,0.1-0.3,0.1-0.4,0.2c-1.7,0.6-3.4,1.2-5.1,1.6c-0.1,0-0.3,0.1-0.4,0.1c-0.7,0.2-1.4,0.3-2.2,0.5c-0.2,0-0.3,0.1-0.5,0.1
                    l-2.9-19.3h1.4v-4.1l7.5,5l7-17.4c8.8,1,15.9,7.8,17.1,16.7C88,77.1,87.8,77.4,87.6,77.7z M39.1,95.4c-0.1,0-0.3-0.1-0.4-0.1
                    c-1.7-0.4-3.4-1-5.1-1.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-0.3-1.3-0.5-1.9-0.8c-0.3-0.1-0.5-0.2-0.8-0.3c-0.5-0.2-1-0.5-1.5-0.7
                    c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.2-0.9-0.5-1.3-0.7c-0.1,0-0.1-0.1-0.2-0.1V80h-3.3v8.3c-0.3-0.2-0.6-0.4-0.8-0.6
                    c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-1-0.8-1.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3
                    c-1.5-1.4-3-3-4.3-4.6c0,0-0.1-0.1-0.1-0.1c-0.4-0.5-0.8-1-1.1-1.4c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.3-0.4-0.5-0.6-0.8
                    c1.2-8.9,8.3-15.7,17.1-16.7l7,17.4l7.5-5v4.1h1.4l-2.9,19.3c-0.2,0-0.4-0.1-0.6-0.1C40.6,95.7,39.9,95.5,39.1,95.4z M42.5,51.3
                    c-8-2.9-14.2-16.6-14.2-26.3c0-0.2,0-0.4,0-0.6l1.2-1.5c2.8-3.3,7.4-4.2,11.7-2.3c1.7,0.8,3.5,1.1,5.4,1.1c5,0,9.4-2.7,11.7-7
                    c5.3,1.7,10,5,13.2,9.2c0.5,10.2-5.5,24.2-14,27.4C56,51.9,55,53.3,55,55v1.1c0,1.9,1.4,3.5,3.2,3.9l-2.8,3.4H44.7l-2.8-3.4
                    c1.8-0.4,3.2-2,3.2-3.9V55C45,53.4,44,51.9,42.5,51.3z M50.1,3.3C50.1,3.3,50.1,3.3,50.1,3.3c0.9,0,1.7,0,2.4,0.1
                    c8.3,0.9,15.2,6.7,17.9,14.3c-3.5-3.1-7.8-5.4-12.5-6.7l-1.4-0.4l-0.6,1.4c-1.5,3.8-5.2,6.3-9.3,6.3c-1.4,0-2.8-0.3-4-0.9
                    c-4.6-2-9.6-1.5-13.4,1.2c1-3.4,2.9-6.5,5.4-9C38.8,5.6,44.2,3.3,50.1,3.3z M56.7,66.9l5.8-6.9h5.1l-5,12.5l-5.9-3.9V66.9z
                    M53.9,96.5c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0-1.7,0.1-2.6,0.1c-0.1,0-0.1,0-0.2,0c-0.8,0-1.6,0-2.4-0.1c-0.4,0-0.9-0.1-1.3-0.1
                    c-0.3,0-0.6-0.1-0.9-0.1l2.9-19.7h3.8l3,19.7C54.6,96.4,54.3,96.5,53.9,96.5z M53.3,73.3h-6.7v-3v-3.7h6.7v3.7V73.3z M43.3,66.9v1.6
                    l-5.9,3.9l-5-12.5h5.1L43.3,66.9z M33.3,6.4c-0.3,0.3-0.7,0.6-1,0.9C27.6,12,25,18.3,25,25c0,11,7,26,16.3,29.4
                    c0.2,0.1,0.3,0.3,0.3,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.9h-8h-0.9c-0.2,0-0.3,0-0.5,0.1C20.1,57.3,12,63.8,9.3,72.8
                    c-3.8-6.8-6-14.5-6-22.8C3.3,30.2,15.8,13.2,33.3,6.4z"></path>
                  </svg>`,
					title: 'Profile',
					text: 'Each personal trainer can create their own profile enlisting their experience, qualifications and certifications.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M20.7,10.7c2.8,0,5.1-2.2,5.1-5c0-2.8-2.3-5-5.1-5c-2.8,0-5.1,2.2-5.1,5C15.7,8.5,17.9,10.7,20.7,10.7z M20.7,2.2
                        c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5C17.2,3.8,18.8,2.2,20.7,2.2z M49.2,11.1
                        c-0.1,0-0.3,0-0.4,0.1l-10.9,6.4c-0.2,0.1-0.4,0.4-0.4,0.7v2.1c0,0.4,0.3,0.8,0.8,0.8h6.6v3.3l-8,19.1l-1.7,0.1
                        c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.8,0.7-1.7,0.3-2.6v0l-4-9.1c-0.2-0.3-0.4-0.6-0.8-0.8l-3.5-2.1c-0.2-0.1-0.3-0.2-0.3-0.4v-6.5
                        l3.4,0.8c0.3,0.1,0.7-0.1,0.8-0.3l4.1-6.3c0.6-0.9,0.4-2-0.3-2.7c-0.9-0.9-2.3-0.8-3.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-2.7,3.9
                        L26,16.7c-1.6-2.7-4.6-4.4-7.8-4.4c-0.6,0-1.3,0.2-1.8,0.5l0,0l-8.1,5.1c-0.3,0.2-0.4,0.5-0.3,0.8l1.8,7.9c0.2,1,1.1,1.8,2.2,1.8
                        c1.3,0,2.3-1,2.3-2.3c0-0.1-1-5.4-1-5.4l1.7-1.1v8.6c0,1.1,0.6,2.2,1.6,2.8l-1.2,3.5c0,0.1-0.1,0.2-0.2,0.2l-7,2.8
                        c-1.4,0.6-2.1,2.1-1.5,3.5c0.2,0.6,0.7,1.1,1.2,1.4c0.7,0.4,1.6,0.4,2.3,0.2l8.7-3.4c0.5-0.2,0.9-0.6,1.1-1.1l2.1-4.9l4,1.6
                        c0,0,0.1,0,0.1,0.1l4.4,8c0.2,0.3,0.4,0.6,0.7,0.9L2.3,45.3C1,45.3,0,46.4,0,47.7C0,49,1.1,50,2.4,50h30.8c0.4,0,0.8-0.3,0.8-0.8
                        s-0.3-0.8-0.8-0.8H2.4c-0.5,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8l43.7-2.2v3.8h-9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9
                        c0.9,0,1.6-0.7,1.6-1.5v-3.8c0-0.9-0.7-1.5-1.6-1.5c0,0-0.1,0-0.1,0l-3.8,0.2l7.7-18.4c0-0.1,0.1-0.2,0.1-0.3V11.9
                        C50,11.4,49.6,11.1,49.2,11.1z M29.3,19c0.3,0.1,0.6,0,0.8-0.3l3-4.3c0.2-0.3,0.7-0.4,1-0.1c0,0,0,0,0.1,0.1
                        c0.2,0.2,0.2,0.5,0.1,0.7L30.5,21l-3.3-0.7c-0.1-0.6-0.2-1.3-0.4-1.9L29.3,19z M18.5,37.7c0,0.1-0.1,0.2-0.2,0.2l-8.7,3.4
                        c-0.6,0.2-1.3-0.1-1.5-0.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.5,0.2-1.1,0.7-1.3l6.9-2.8c0.5-0.2,0.9-0.6,1.1-1.2l1.2-3.4
                        c0.6,0.2,1.6,0.6,2.6,1L18.5,37.7z M34.1,42.5c-0.2,0.3-0.6,0.6-1.1,0.6c-0.5,0-1-0.3-1.2-0.8l0,0l-4.5-8.1
                        c-0.2-0.3-0.5-0.5-0.8-0.7c-0.8-0.3-8.2-3.4-9-3.6c-0.7-0.2-1.2-0.9-1.2-1.6v-9.6l1.2-0.7c0.4-0.2,0.4-0.7,0.2-1.1
                        c-0.2-0.3-0.7-0.4-1-0.2l-5,3c-0.3,0.2-0.4,0.5-0.4,0.8l1.1,5.5c0,0.1,0,0.1,0,0.2c0,0.4-0.3,0.7-0.7,0.7c-0.3,0-0.6-0.2-0.7-0.6
                        l-1.7-7.4l7.6-4.8c0,0,0.1,0,0.1-0.1c0.3-0.2,0.6-0.3,0.9-0.3c4.2,0,7.6,3.4,7.6,7.6v7.1c0,0.7,0.4,1.4,1,1.7c0,0,3.6,2.2,3.6,2.2
                        l3.9,9C34.4,41.7,34.4,42.2,34.1,42.5z M48.4,24.4l-8,19l-1.9,0.1l7.8-18.6c0-0.1,0.1-0.2,0.1-0.3v-3.5h2V24.4z M48.4,19.6h-9.4
                        v-0.9l9.4-5.4V19.6z M31.9,10.3h9.8c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2h-9.8c-1.1,0-2,0.9-2,2v6.3C29.9,9.4,30.8,10.3,31.9,10.3z
                        M31.4,2.7c0-0.7,0.5-1.2,1.2-1.2H41c0.7,0,1.2,0.5,1.2,1.2v5c0,0.7-0.5,1.2-1.2,1.2h-8.4c-0.7,0-1.2-0.5-1.2-1.2V2.7z M34.8,2.5
                        v5.4l4.9-2.7L34.8,2.5z M36.1,4.1l1.9,1l-1.9,1V4.1z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Upload Workout Videos',
					text: 'Personal trainers can upload their own workout videos that can help users train themselves.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M50,8.3L50,8.3L50,8.3c0-0.1-0.1-0.2-0.1-0.2l0,0c0-0.1-0.1-0.1-0.2-0.2h0c-0.1-0.1-0.1-0.1-0.2-0.1h-0.1
                    c-0.1,0-0.2,0-0.3,0H1c-0.1,0-0.2,0-0.3,0H0.7c-0.1,0-0.2,0.1-0.2,0.1h0C0.3,7.9,0.3,7.9,0.2,8l0,0C0.2,8.1,0.1,8.2,0.1,8.3l0,0l0,0
                    C0.1,8.3,0,8.4,0,8.5v32.1c0,0.5,0.4,1,1,1H49c0.5,0,1-0.4,1-1V8.5C50,8.4,50,8.3,50,8.3z M45.9,9.5L25,23.4L4.1,9.5H45.9z
                    M1.9,39.6V10.3l22.5,15c0.3,0.2,0.7,0.2,1.1,0l22.5-15v29.3H1.9z"></path>
                    <path d="M11.1,23.5v-3c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1v3C6.2,24,4,26.5,4,29.6v3.7c0,1.3,1.1,2.4,2.4,2.4h0.8
                    c0.4,1.6,2,2.7,3.6,2.3c1.1-0.3,2-1.1,2.3-2.3h0.8c1.3,0,2.4-1.1,2.4-2.4c0,0,0,0,0,0v-3.7C16.3,26.5,14.1,24,11.1,23.5z M10.2,36.2
                    c-0.3,0-0.7-0.2-0.9-0.4h1.7C10.8,36,10.5,36.2,10.2,36.2z M14.4,33.3c0,0.3-0.2,0.5-0.5,0.5H6.4c-0.3,0-0.5-0.2-0.5-0.5l0,0v-3.7
                    c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2L14.4,33.3z"></path>
                  </svg>`,
					title: 'Send Reminders',
					text: 'Facility to send timely reminders to users who have subscribed to personal trainer services.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M8.4,21.3L8.4,21.3c0,0.2,0,0.5,0,0.6c0,0.2,0.1,0.6,0.1,0.8c0,0.1,0,0.2,0.1,0.3c0.2,1.2,0.7,2.1,1.6,2.6
                      c0.6,2,1.6,3.6,3.2,4.7c0.2,0.2,0.4,0.2,0.6,0.4c0.3,0.2,0.6,0.3,1,0.5l0,0c0.3,0.2,0.6,0.2,0.9,0.3c0.2,0.1,0.6,0.1,0.8,0.1
                      c0.1,0,0.2,0,0.2,0c0.2,0,0.6,0,0.8-0.1c0.3-0.1,0.6-0.2,0.9-0.2h0.1c0.4-0.2,0.8-0.3,1.1-0.5c0.2-0.2,0.4-0.2,0.6-0.4
                      c0.9-0.6,1.6-1.3,2.1-2.3c0.2-0.4,0.5-0.9,0.7-1.4c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.3
                      c0.1-0.1,0.2-0.2,0.2-0.2c0.7-0.7,0.9-1.5,1-2.1c0-0.2,0-0.2,0.1-0.4c0-0.2,0.1-0.6,0.1-0.8c0-0.2,0-0.4,0-0.6l0,0l0,0l0,0
                      c0-0.7-0.3-1.6-1-1.9v-0.1c0-0.2,0.1-0.5,0.1-0.6c0.2-0.7,0.3-1.4,0.3-2c0.1-2.4-1.1-4.3-3.2-5.3c-0.2-0.1-0.5-0.2-0.7-0.2
                      c-1.3-0.5-2.5-0.7-3.9-0.7c-0.3,0-0.6,0-0.9,0c-0.8,0.1-2,0.1-2.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.7,0.6-1,1l0,0.1
                      c-0.1,0.2-0.2,0.2-0.2,0.2c-0.2,0.2-0.4,0.2-0.6,0.3c-0.5,0.2-0.9,0.6-1.1,0.9c-0.3,0.6-0.5,1.3-0.6,2.1c0,0.8,0,1.6,0.1,2.2
                      c0,0.3,0.1,0.6,0.2,0.9c0,0.1,0,0.2,0.1,0.3C8.7,19.6,8.3,20.5,8.4,21.3z M10.3,21.2c0.1,0,0.1,0,0.2,0l0.3-0.2L11,21
                      c0.4-0.2,0.6-0.6,0.6-1c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.6-0.1-1.3-0.1-2c0-0.6,0.1-0.9,0.2-1.2
                      c0,0,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.6c0.3-0.3,0.5-0.6,0.6-0.8l0.1-0.2c0.2-0.2,0.2-0.3,0.4-0.5c0.1,0,0.1-0.1,0.2-0.1
                      c0.6-0.3,1.3-0.3,2.2-0.4c0.2,0,0.5,0,0.7,0c1.1,0,2.2,0.2,3.2,0.6c0.2,0.1,0.4,0.2,0.6,0.2c1.4,0.6,2.1,1.8,2.1,3.4
                      c0,0.6-0.1,1.1-0.2,1.7c-0.1,0.3-0.1,0.6-0.2,0.9v0.3c-0.1,0.3-0.2,0.6,0,1c0.2,0.3,0.4,0.6,1,0.7l0,0l0.9,0.1h0.6h-0.1l-0.6-0.1
                      h-1v0.1v0.1c0,0.2,0,0.3,0,0.4c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.6-0.2,0.7-0.4,0.9c-0.1,0.1-0.2,0.2-0.2,0.2
                      c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.4-0.4,0.9-0.5,1.2c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.3,0.8-0.6,1.1c-0.4,0.6-0.9,1.3-1.6,1.7
                      c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.2-0.5,0.2-0.8,0.3l0.1,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3,0.1-0.6,0.1
                      c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-1.2-0.8-2-2-2.4-3.6
                      c-0.2-0.6-0.5-0.9-0.6-1l-0.2-0.1c-0.5-0.2-0.6-0.9-0.7-1.2c0-0.2,0-0.2-0.1-0.4c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.2,0-0.4v-0.1
                      L10.3,21.2L10.3,21.2z"></path>
                      <path d="M37,6.5c-0.6,0-1,0.5-1,1v4.4c-1.1,0.4-1.8,1.4-1.8,2.6c0,1.6,1.3,2.8,2.8,2.8s2.8-1.3,2.8-2.8c0-1.2-0.8-2.2-1.8-2.6V7.5
                      C37.9,6.9,37.5,6.5,37,6.5z M37,15.4c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9l0,0l0,0c0.5,0,0.9,0.4,0.9,0.9
                      C37.9,15,37.5,15.4,37,15.4z"></path>
                      <path d="M50,14.4c0-7.2-5.8-13-13-13c-5.6,0-10.5,3.5-12.3,8.8c-0.2,0.6,0.1,1.1,0.6,1.3c0.6,0.2,1.1-0.1,1.3-0.6
                      c1.5-4.5,5.8-7.5,10.5-7.5c6.1,0,11,5,11,11s-5,11-11,11c-2.8,0-5.6-1.1-7.6-3.1c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
                      c2.4,2.4,5.7,3.6,9.1,3.6C44.2,27.4,50,21.6,50,14.4z"></path>
                      <path d="M6.8,33.8c-0.2,0.1-0.2,0.2-0.4,0.2L5,34.8c-0.6,0.4-1.2,0.7-1.7,1.1c-1,0.8-2.2,1.7-2.8,3C0,40,0.1,41.2,0.1,42.4v0.4
                      c0,0.1,0,0.6,0,3.2c0,1.4,1.2,2.5,2.5,2.5H31c1.4,0,2.5-1.2,2.5-2.5c0-1.3,0-3.1,0-3.2v-0.4c0-1.1,0.1-2.4-0.4-3.5
                      c-0.6-1.3-1.7-2.2-2.8-3c-0.3-0.2-0.7-0.5-1.2-0.8l-1.3-0.8c-0.3-0.2-0.7-0.4-1.2-0.6l-0.4-0.2c-0.4-0.2-0.8-0.4-1.2-0.6
                      c-0.6-0.2-1.1-0.5-1.6-0.6l-0.9-0.2l-0.4,0.6c-0.3,0.5-2.9,2.9-5.5,5.3c-2.6-2.4-5.2-4.9-5.5-5.3l-0.4-0.6L10,32.3
                      c-0.5,0.2-0.9,0.3-1.6,0.6c-0.5,0.2-0.9,0.4-1.3,0.6L6.8,33.8z M7.7,35.5l0.5-0.2c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.6-0.2,0.9-0.3
                      c1.3,1.3,4.5,4.3,6,5.7l0.6,0.6l0.6-0.6c1.2-1.1,4.7-4.3,6-5.7c0.2,0.1,0.6,0.2,0.9,0.4c0.4,0.2,0.8,0.4,1.1,0.6l0.5,0.2
                      c0.4,0.2,0.8,0.4,1.1,0.6l1.3,0.8c0.4,0.2,0.7,0.5,1,0.7c0.9,0.6,1.7,1.3,2.1,2.2c0.3,0.7,0.3,1.7,0.2,2.7v0.4c0,0.1,0,1.9,0,3.3
                      c0,0.3-0.2,0.6-0.6,0.6H2.7l0,0c-0.3,0-0.6-0.2-0.6-0.6c0-3.2,0-3.2,0-3.3v-0.4c0-0.9-0.1-1.9,0.2-2.6c0.4-0.9,1.3-1.6,2.1-2.2
                      c0.5-0.3,0.9-0.6,1.5-1l1.4-0.8C7.4,35.6,7.6,35.6,7.7,35.5z"></path>
                      <path d="M23.5,42.3h1.4v1.4c0,0.6,0.5,1,1,1s1-0.5,1-1v-1.4h1.4c0.6,0,1-0.5,1-1s-0.5-1-1-1H27v-1.4c0-0.6-0.5-1-1-1s-1,0.5-1,1
                      v1.4h-1.4c-0.6,0-1,0.5-1,1S23,42.3,23.5,42.3z"></path>
                    </g>
                  </svg>`,
					title: 'Schedule Consultation',
					text: ' Trainers can approve/reject personal consultation requests made by users.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M21.7,13.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C25,14.8,23.5,13.3,21.7,13.3z M21.7,18.3
                    c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7C23.4,17.5,22.6,18.3,21.7,18.3z"></path>
                    <path d="M30.9,19.9c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3C27.5,18.4,29,19.9,30.9,19.9z M30.9,14.9
                    c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7C29.2,15.7,30,14.9,30.9,14.9z"></path>
                    <path d="M21.7,21.6c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C25,23.1,23.5,21.6,21.7,21.6z M21.7,26.6
                    c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7C23.4,25.9,22.6,26.6,21.7,26.6z"></path>
                    <path d="M34.2,24.9c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3C32.7,28.3,34.2,26.8,34.2,24.9z M29.2,24.9
                    c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7C30,26.6,29.2,25.9,29.2,24.9z"></path>
                    <path d="M12.5,27.9c0.1,0,0.2,0,0.2,0c0.8-0.1,1.5-0.4,2-1c0.5-0.6,0.8-1.3,0.7-2.1c-0.1-0.8-0.4-1.5-1-2c-0.6-0.5-1.4-0.8-2.1-0.7
                    c-0.8,0.1-1.5,0.4-2,1s-0.8,1.3-0.7,2.1c0.1,0.8,0.4,1.5,1,2C11.1,27.6,11.8,27.9,12.5,27.9z"></path>
                    <path d="M21.7,30c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C25,31.5,23.5,30,21.7,30z M21.7,35
                    c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7S22.6,35,21.7,35z"></path>
                    <path d="M12.5,30c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3C15.8,31.5,14.3,30,12.5,30z M12.5,35
                    c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7S13.4,35,12.5,35z"></path>
                    <path d="M39.2,28.3v-2.5h4.2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.7L39.2,4V3.2h-3.3V2.4c0-1.4-1.1-2.5-2.5-2.5
                    S30.9,1,30.9,2.4v0.8h-1.7V2.4c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v0.8h-5V2.4c0-1.4-1.1-2.5-2.5-2.5S14.2,1,14.2,2.4v0.8
                    h-1.7V2.4c0-1.4-1.1-2.5-2.5-2.5S7.5,1,7.5,2.4v0.8H4.2v6.7v1.7V40h23.4c0.4,5.6,5.1,10,10.8,10c6,0,10.9-4.9,10.9-10.9
                    C49.3,33.4,44.8,28.8,39.2,28.3z M39.2,11.6v-0.5l3.1,13h-3.1V11.6z M32.5,2.4c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v3.3
                    c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V2.4z M25.9,2.4c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v3.3
                    c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V2.4z M15.8,2.4c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.3c0,0.5-0.4,0.8-0.8,0.8
                    s-0.8-0.4-0.8-0.8V2.4z M9.2,2.4c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.3c0,0.5-0.4,0.8-0.8,0.8S9.2,6.2,9.2,5.7V2.4z M5.8,4.9
                    h1.7v0.8c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V4.9h1.7v0.8c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V4.9h5v0.8
                    c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5V4.9h1.7v0.8c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5V4.9h1.7v5H5.8V4.9z M5.8,38.3
                    V11.6h31.7v16.7c-5.3,0.4-9.6,4.7-10,10H5.8z M38.4,48.3c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2s9.2,4.1,9.2,9.2
                    S43.5,48.3,38.4,48.3z"></path>
                    <path d="M40.1,36.6h-3.3V35h-5v7.5h5v-1.7h3.3v1.7h5V35h-5V36.6z M35.1,40.8h-1.7v-4.2h1.7V40.8z M40.1,39.1h-3.3v-0.8h3.3V39.1z
                    M41.7,36.6h1.7v4.2h-1.7V36.6z"></path>
                  </svg>`,
					title: 'Manage Workout Plans',
					text: 'Trainers can organize and manage all workout plans they have suggested to users.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M47.5,11.7h-1.7V7.5c0-1.4-1.1-2.5-2.5-2.5H40c-1.4,0-2.5,1.1-2.5,2.5v6.7h-2l-0.2-0.5c-0.9-2.2-3-3.7-5.4-3.7h-0.1
                    c-0.3-1-1.3-1.7-2.3-1.7c-0.6,0-1.2,0.3-1.7,0.7c-0.4-0.4-1-0.7-1.7-0.7S22.9,8.6,22.5,9c-0.4-0.4-1-0.7-1.7-0.7S19.6,8.6,19.2,9
                    c-0.4-0.4-1-0.7-1.7-0.7c-1.4,0-2.5,1.1-2.5,2.5v3.3h-2.5V7.5C12.5,6.1,11.4,5,10,5H6.7C5.3,5,4.2,6.1,4.2,7.5v4.2H2.5
                    c-1.4,0-2.5,1.1-2.5,2.5v8.3C0,23.9,1.1,25,2.5,25h1.7v4.2c0,1.4,1.1,2.5,2.5,2.5H10c1.4,0,2.5-1.1,2.5-2.5v-6.7h1.9l0.6,2.1
                    c0,0.1,0.1,0.2,0.1,0.3l2.2,2.9l-1.4,15c-0.1,0.6,0.1,1.2,0.5,1.6c0.4,0.4,1,0.7,1.6,0.7h11.3c0.6,0,1.2-0.3,1.6-0.7
                    c0.4-0.4,0.6-1,0.5-1.6l-1.5-14.6c0-0.2,0-0.3,0.2-0.4l4.6-3.9c0.4-0.3,0.7-0.8,0.9-1.3h1.9v6.7c0,1.4,1.1,2.5,2.5,2.5h3.3
                    c1.4,0,2.5-1.1,2.5-2.5V25h1.7c1.4,0,2.5-1.1,2.5-2.5v-8.3C50,12.8,48.9,11.7,47.5,11.7z M27.5,10c0.5,0,0.8,0.4,0.8,0.8V15v0.7
                    c0,0.3-0.2,0.6-0.4,0.8c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
                    c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3v-5
                    C26.7,10.4,27,10,27.5,10z M24.2,10c0.5,0,0.8,0.4,0.8,0.8v5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-5
                    C23.3,10.4,23.7,10,24.2,10z M20.8,10c0.5,0,0.8,0.4,0.8,0.8v5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-5
                    C20,10.4,20.4,10,20.8,10z M16.7,10.8c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v5c0,0.1,0,0.2-0.1,0.3c0,0,0,0.1-0.1,0.1
                    c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
                    c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c-0.2-0.2-0.4-0.5-0.4-0.8V15V10.8z M2.5,23.3c-0.5,0-0.8-0.4-0.8-0.8v-8.3c0-0.5,0.4-0.8,0.8-0.8
                    h1.7v10H2.5z M10.8,29.2c0,0.5-0.4,0.8-0.8,0.8H6.7c-0.5,0-0.8-0.4-0.8-0.8v-5V12.5v-5c0-0.5,0.4-0.8,0.8-0.8H10
                    c0.5,0,0.8,0.4,0.8,0.8V15v6.7V29.2z M12.5,15.8H15c0,0.9,0.5,1.7,1.2,2.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
                    c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0
                    c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0.1,0,0.1-0.1
                    c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
                    c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1
                    c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
                    c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c0.2,0,0.3,0,0.5,0
                    c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
                    c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
                    c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
                    c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.6-0.3,1.1-0.9,1.2-1.6c0,0.1,0.1,0.2,0.1,0.3
                    v3l-1.2,1.2H12.5V15.8z M29.1,26.4c-0.5,0.5-0.8,1.1-0.7,1.8l1.5,14.6c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.2H18.1
                    c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.4l1.5-15.3c0-0.2,0-0.4-0.2-0.6l-2.3-3l-0.4-1.4h12.7c0.4,0,0.9-0.2,1.2-0.5
                    l1.2-1.2c0.3-0.3,0.5-0.7,0.5-1.2v-3c0-1.1-0.7-2-1.7-2.3v-2.7c1.7,0,3.2,1.1,3.8,2.6l0.3,0.8c0,0.1,0.1,0.1,0.1,0.2v6.2
                    c0,0.4-0.2,0.7-0.5,1L29.1,26.4z M35.8,20.8v-5h1.7v5H35.8z M44.2,29.2c0,0.5-0.4,0.8-0.8,0.8H40c-0.5,0-0.8-0.4-0.8-0.8v-7.5V15
                    V7.5c0-0.5,0.4-0.8,0.8-0.8h3.3c0.5,0,0.8,0.4,0.8,0.8v5v11.7V29.2z M48.3,22.5c0,0.5-0.4,0.8-0.8,0.8h-1.7v-10h1.7
                    c0.5,0,0.8,0.4,0.8,0.8V22.5z"></path>
                  </svg>`,
					title: 'Track Pre-Workout Details',
					text: 'Users can be instructed to prep the right way before the intense workout session begins'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M21.9,37.5H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h12.5c0.6,0,1-0.5,1-1C22.9,38,22.5,37.5,21.9,37.5z M28.3,47.9H2.1V10.4
                        h7.3c0.6,0,1-0.5,1-1V2.1h22.9v16.2c0,0.6,0.5,1,1,1s1-0.5,1-1V1c0-0.6-0.5-1-1-1h-25C9.2,0,9.1,0,9,0.1c-0.1,0-0.1,0.1-0.2,0.1
                        c-0.1,0-0.1,0.1-0.2,0.1L0.3,8.6c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2C0,9.1,0,9.2,0,9.4V49c0,0.6,0.5,1,1,1h27.3
                        c0.6,0,1-0.5,1-1C29.4,48.4,28.9,47.9,28.3,47.9z M8.3,3.6v4.8H3.6L8.3,3.6z M21.9,31.3H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
                        h12.5c0.6,0,1-0.5,1-1C22.9,31.7,22.5,31.3,21.9,31.3z M26,12.5H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H26c0.6,0,1-0.5,1-1
                        C27.1,13,26.6,12.5,26,12.5z M43.7,27.1c1.3-1.2,2.1-3,2.1-5.3c0-0.6-0.5-1-1-1c-2.4,0-4.8,1.1-6.1,3.2c-0.5-1.4-1.1-2.3-1.3-2.7
                        c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,1-0.3,1.4c0.8,1.3,1.6,3.3,1.8,5.6c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.4-1.9-0.8-3.1-0.8
                        c-4.1,0-6.9,3.9-6.9,9.6c0,5.3,3.7,13.3,9.2,13.3c1,0,1.5-0.2,1.9-0.5c0.2-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.4,0.1
                        c0.5,0.2,0.9,0.5,1.9,0.5c5.5,0,9.2-8,9.2-13.3C50,31.2,47.5,27.5,43.7,27.1z M43.6,23c-0.4,2.3-2.1,3.6-4,3.9
                        C40.1,24.7,41.8,23.4,43.6,23z M40.8,47.9c-0.5,0-0.7-0.1-0.9-0.2c-0.3-0.2-0.7-0.4-1.3-0.4c-0.1,0-0.1,0-0.2,0
                        c-0.6,0-1,0.2-1.2,0.4c-0.2,0.1-0.4,0.2-0.9,0.2c-3.6,0-7.1-6.3-7.1-11.2c0-4.5,1.9-7.5,4.8-7.5c0.8,0,1.6,0.3,2.3,0.6
                        c0.7,0.3,1.4,0.6,2.3,0.6h0c0.8,0,1.6-0.3,2.3-0.6c0.8-0.3,1.5-0.6,2.3-0.6c2.9,0,4.8,3,4.8,7.5C47.9,41.6,44.5,47.9,40.8,47.9z
                        M27.1,19.8c0-0.6-0.5-1-1-1H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H26C26.6,20.8,27.1,20.4,27.1,19.8z M25,26c0-0.6-0.5-1-1-1H9.4
                        c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H24C24.5,27.1,25,26.6,25,26z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Fitness Chart',
					text: 'Trainers can see the progress of users in a fitness chart that is designed like a comprehensive dashboard.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M28.3,30.3h-7.6c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3h6.1v-1.5h-6.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h7.6
                    c2.1,0,3.8-1.7,3.8-3.8c0-1.7-1.2-3.2-2.8-3.7l-0.4,1.5c1.2,0.3,1.9,1.6,1.6,2.8C30.3,29.6,29.4,30.3,28.3,30.3z"></path>
                    <rect x="23.8" y="27.3" width="1.5" height="1.5"></rect>
                    <rect x="26.8" y="27.3" width="1.5" height="1.5"></rect>
                    <rect x="20.8" y="27.3" width="1.5" height="1.5"></rect>
                    <path d="M10.9,18.2h18l-1.3,5.1c-0.1,0.4,0.1,0.8,0.6,0.9c0.3,0.1,0.5,0,0.7-0.2l5.8-5.8h3.5c5,0,9.1-4.1,9.1-9.1S43.2,0,38.2,0
                    H10.9c-5,0-9.1,4.1-9.1,9.1S5.9,18.2,10.9,18.2z M10.9,1.5h27.3c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6h-3.8
                    c-0.2,0-0.4,0.1-0.5,0.2L29.7,21l0.9-3.4c0.1-0.4-0.1-0.8-0.6-0.9c-0.1,0-0.1,0-0.2,0H10.9c-4.2,0-7.6-3.4-7.6-7.6S6.7,1.5,10.9,1.5
                    z"></path>
                    <path d="M24.5,15.2c3.3,0,6.1-2.7,6.1-6.1S27.9,3,24.5,3s-6.1,2.7-6.1,6.1C18.5,12.4,21.2,15.1,24.5,15.2z M24.5,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C20,6.6,22,4.5,24.5,4.5z"></path>
                    <path d="M10.9,15.2c3.3,0,6.1-2.7,6.1-6.1S14.3,3,10.9,3S4.8,5.7,4.8,9.1C4.9,12.4,7.6,15.1,10.9,15.2z M10.9,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C6.4,6.6,8.4,4.5,10.9,4.5z"></path>
                    <path d="M38.2,15.2c3.3,0,6.1-2.7,6.1-6.1S41.5,3,38.2,3c-3.3,0-6.1,2.7-6.1,6.1C32.1,12.4,34.8,15.1,38.2,15.2z M38.2,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C33.6,6.6,35.7,4.5,38.2,4.5z"></path>
                    <rect x="21.5" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="26.1" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="23" y="10.6" width="3" height="1.5"></rect>
                    <rect x="7.9" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="12.4" y="7.6" width="1.5" height="1.5"></rect>
                    <path d="M9.9,11.8c0.5-0.5,1.4-0.5,2,0l1.1-1.1c-1.1-1.1-3-1.1-4.1,0L9.9,11.8z"></path>
                    <rect x="35.2" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="39.7" y="7.6" width="1.5" height="1.5"></rect>
                    <path d="M38.2,12c0.8,0,1.5-0.3,2.1-0.8l-1.1-1.1c-0.5,0.5-1.4,0.5-2,0l-1.1,1.1C36.7,11.7,37.4,12,38.2,12z"></path>
                    <path d="M41.8,40c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
                    c0-0.5,0.4-0.9,0.9-0.9c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1V50H50V39.2c0-1.4-0.9-2.5-2.3-2.8c-0.8-0.1-1.6,0.1-2.2,0.6l-0.1,0
                    c-1-0.9-2.6-0.9-3.6,0c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.3-0.9,0.3-1.4V22.7c0-2-1.6-3.6-3.6-3.6h-2.7v10.2
                    c-1.1,0.4-1.8,1.4-1.8,2.6v5.5h-20v-4.2l0.1-0.1c1.6-1,2.1-3.1,1.2-4.8c-0.3-0.5-0.8-0.9-1.3-1.2v-6.1h13.6v-1.8H10.9
                    c-2,0-3.6,1.6-3.6,3.6V24L3,26.5c-1.9,1.2-3,3.2-3,5.4V50h1.8v-4.5h7.3V50h1.8v-5.5c0-0.5-0.4-0.9-0.9-0.9H9.1v-0.5
                    c1-1.1,1.5-2.5,1.5-4c0.1,0,0.2,0,0.3,0h22.7v3.8l-2.4-1.4c-0.7-0.4-1.7-0.5-2.4-0.1l-0.1,0.1c-1.2,0.6-1.7,2.1-1.1,3.4
                    c0.2,0.4,0.5,0.7,0.8,0.9l5.3,3.8V50h1.8v-0.9c0-0.3-0.1-0.6-0.4-0.7l-5.7-4.1c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.5,0.4-0.6
                    l0.1-0.1c0.2-0.1,0.5-0.1,0.7,0l3.8,2.3c0.4,0.3,1,0.1,1.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5V31.8c0-0.5,0.4-0.9,0.9-0.9
                    c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1v7.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                    C40.9,39.6,41.3,40,41.8,40z M10.9,20.9h0.9v5.6c-0.7,0-1.3,0.2-1.9,0.6l-0.9,0.6v-5C9.1,21.7,9.9,20.9,10.9,20.9z M7.5,36.1
                    c1.7,1.7,1.7,4.3,0,6c-0.2,0.2-0.3,0.4-0.3,0.6v0.9H1.8V31.9c0-1.6,0.8-3,2.1-3.8l3.3-1.9v2.8L4,31.1l1,1.5l5.9-3.9
                    c0.8-0.5,1.8-0.3,2.4,0.5c0,0,0,0,0,0.1c0.5,0.8,0.2,1.8-0.6,2.3l-5.1,3.2c-0.4,0.3-0.6,0.8-0.3,1.3C7.4,36,7.5,36,7.5,36.1z
                    M10.3,37.2L10.3,37.2c-0.2-0.5-0.4-1-0.8-1.5l2.3-1.4v3h-0.9C10.7,37.3,10.5,37.2,10.3,37.2z M39.1,36.5v-4.6
                    c0-1.2-0.7-2.2-1.8-2.7v-8.3h0.9c1,0,1.8,0.8,1.8,1.8v12.7c0,0.3-0.1,0.7-0.3,0.9C39.5,36.4,39.3,36.4,39.1,36.5z"></path>
                    <rect x="8.6" y="43.9" width="1.5" height="1.5"></rect>
                  </svg>`,
					title: 'Rating and Reviews',
					text: 'Personal trainer can rate and review its trainee as per its dedication and other fitness parameters.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g transform="translate(0,-952.36218)">
                      <path d="M8.4,952.4c-3.4,0-6.1,2.9-6.1,6.2v22.7c0,3.4,2.8,6.2,6.2,6.2h6.1l-2.1,13.5c-0.1,0.6,0.3,1.2,0.9,1.3
                      c0.4,0.1,0.8-0.1,1.1-0.4l11-14.3h15.9c3.4,0,6.2-2.7,6.2-6.1v-22.9c0-3.4-2.8-6.2-6.2-6.2H8.4z M8.4,954.7h33.1c2.1,0,4,1.9,4,4
                      v22.9c0,2.1-1.8,3.8-4,3.8H25c-0.4,0-0.7,0.2-0.9,0.4L15.5,997l1.6-10.4c0.1-0.6-0.3-1.2-1-1.3c-0.1,0-0.1,0-0.2,0H8.6
                      c-2.1,0-4-1.9-4-4v-22.7C4.6,956.5,6.3,954.7,8.4,954.7z M25,960.9c-2.2,0-4,1.8-4,4s1.8,4,4,4c2.2,0,4-1.8,4-4
                      S27.2,960.9,25,960.9z M25,968.9c-2.1,0-3.9,0.5-5.4,1.3c-1.5,0.8-2.6,2.2-2.6,3.8v4.5c0,0.5,0.4,0.9,0.8,1.1
                      c2.1,0.6,3.8,0.6,6,0.6h2.3c2.2,0,3.9,0,6-0.6c0.5-0.1,0.8-0.6,0.8-1.1V974c0-1.6-1.1-3-2.6-3.8C29,969.3,27.1,968.9,25,968.9z
                      M25,963.2c1,0,1.7,0.7,1.7,1.7c0,1-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7C23.3,963.9,24.1,963.2,25,963.2z M25,971.1
                      c1.7,0,3.2,0.4,4.3,1c1,0.6,1.4,1.2,1.4,1.8v3.6c-1.4,0.3-2.7,0.3-4.5,0.4h-2.3c-1.8,0-3.1-0.1-4.5-0.4V974c0-0.6,0.4-1.3,1.4-1.8
                      C21.8,971.5,23.3,971.1,25,971.1z"></path>
                    </g>
                  </svg>`,
					title: 'Message the users',
					text: 'Trainers can message to communicate with users to check ongoing workout sessions, future plans and goals to be achieved.'
				}
			],
			items3: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                    <path d="M100,50c0-27.6-22.4-50-50-50S0,22.4,0,50c0,10.2,3.1,19.8,8.4,27.7l0,0l0.3,0.5c0.3,0.4,0.6,0.9,0.9,1.3
                    c0.1,0.2,0.3,0.3,0.4,0.5c0.4,0.5,0.8,1,1.2,1.6c0.1,0.1,0.2,0.3,0.4,0.4c1.9,2.3,4,4.4,6.3,6.3c0.1,0.1,0.3,0.2,0.4,0.3
                    c0.5,0.4,1,0.8,1.5,1.2c0.2,0.2,0.4,0.3,0.6,0.5c0.5,0.4,1,0.7,1.5,1c0.3,0.2,0.5,0.4,0.8,0.5c0.7,0.4,1.4,0.9,2.1,1.3
                    c0.5,0.3,1,0.6,1.5,0.9c0.3,0.2,0.6,0.3,0.9,0.5c0.5,0.3,1.1,0.5,1.6,0.8c0.3,0.1,0.6,0.3,0.8,0.4c0.6,0.3,1.3,0.6,1.9,0.8
                    c0.2,0.1,0.4,0.2,0.6,0.2c1.8,0.7,3.6,1.3,5.5,1.7c0.1,0,0.2,0,0.2,0.1c0.8,0.2,1.7,0.4,2.5,0.5c0.2,0,0.5,0.1,0.7,0.1
                    c0,0,0.1,0,0.1,0l0,0l0.9,0.1c0.2,0,0.4,0.1,0.6,0.1l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0.1,0.8,0.1
                    c0.7,0.1,1.3,0.1,2,0.2c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0c1,0.1,1.9,0.1,2.9,0.1c1,0,1.9,0,2.9-0.1c0.2,0,0.3,0,0.5,0
                    c0.1,0,0.3,0,0.4,0c0.7,0,1.3-0.1,2-0.2c0.3,0,0.5-0.1,0.8-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0l0.2,0c0.2,0,0.4-0.1,0.6-0.1
                    l0.9-0.1l0,0c0,0,0,0,0,0c0.2,0,0.5-0.1,0.7-0.1c0.8-0.2,1.7-0.3,2.5-0.5c0.1,0,0.2,0,0.2-0.1c1.9-0.5,3.7-1.1,5.5-1.7
                    c0.2-0.1,0.4-0.2,0.6-0.2c0.7-0.3,1.3-0.5,1.9-0.8c0.3-0.1,0.6-0.2,0.8-0.4c0.6-0.3,1.1-0.5,1.6-0.8c0.3-0.2,0.6-0.3,0.9-0.5
                    c0.5-0.3,1-0.6,1.5-0.9c0.7-0.4,1.4-0.8,2.1-1.3c0.3-0.2,0.5-0.3,0.8-0.5c0.5-0.3,1-0.7,1.5-1c0.2-0.2,0.4-0.3,0.6-0.5
                    c0.5-0.4,1-0.8,1.5-1.2c0.1-0.1,0.3-0.2,0.4-0.3c2.3-1.9,4.4-4,6.3-6.3c0.1-0.1,0.2-0.3,0.4-0.4c0.4-0.5,0.8-1,1.2-1.6
                    c0.1-0.2,0.3-0.3,0.4-0.5c0.3-0.4,0.6-0.8,0.9-1.3l0.3-0.5l0,0C96.9,69.8,100,60.2,100,50z M96.7,50c0,8.3-2.2,16.1-6,22.8
                    c-2.7-8.9-10.5-15.3-20-16.1l-0.1-0.1h-1.8h-8h-1.9c-0.3,0-0.6-0.3-0.6-0.6V55c0-0.3,0.2-0.5,0.5-0.6c10.7-4,17.4-20.8,16-32.5
                    c-0.7-6.1-3.7-11.5-8.1-15.5C84.2,13.2,96.7,30.1,96.7,50z M87.6,77.7c-0.1,0.2-0.3,0.4-0.4,0.5c-0.4,0.5-0.7,1-1.1,1.4
                    c0,0-0.1,0.1-0.1,0.1c-1.3,1.6-2.8,3.1-4.4,4.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.5,0.4-0.9,0.8-1.4,1.2c-0.2,0.1-0.3,0.2-0.5,0.4
                    c-0.5,0.4-0.9,0.8-1.4,1.1c-0.2,0.1-0.3,0.2-0.5,0.4c-0.3,0.2-0.6,0.4-0.8,0.6V80h-3.3v10.4c-0.1,0-0.1,0.1-0.2,0.1
                    c-0.4,0.2-0.8,0.5-1.3,0.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.5,0.3-1,0.5-1.5,0.7c-0.3,0.1-0.5,0.2-0.8,0.3c-0.6,0.3-1.3,0.5-1.9,0.8
                    c-0.1,0.1-0.3,0.1-0.4,0.2c-1.7,0.6-3.4,1.2-5.1,1.6c-0.1,0-0.3,0.1-0.4,0.1c-0.7,0.2-1.4,0.3-2.2,0.5c-0.2,0-0.3,0.1-0.5,0.1
                    l-2.9-19.3h1.4v-4.1l7.5,5l7-17.4c8.8,1,15.9,7.8,17.1,16.7C88,77.1,87.8,77.4,87.6,77.7z M39.1,95.4c-0.1,0-0.3-0.1-0.4-0.1
                    c-1.7-0.4-3.4-1-5.1-1.6c-0.1-0.1-0.3-0.1-0.4-0.2c-0.7-0.3-1.3-0.5-1.9-0.8c-0.3-0.1-0.5-0.2-0.8-0.3c-0.5-0.2-1-0.5-1.5-0.7
                    c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.2-0.9-0.5-1.3-0.7c-0.1,0-0.1-0.1-0.2-0.1V80h-3.3v8.3c-0.3-0.2-0.6-0.4-0.8-0.6
                    c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-0.9-0.7-1.4-1.1c-0.2-0.1-0.3-0.2-0.5-0.4c-0.5-0.4-1-0.8-1.4-1.2c-0.1-0.1-0.2-0.2-0.3-0.3
                    c-1.5-1.4-3-3-4.3-4.6c0,0-0.1-0.1-0.1-0.1c-0.4-0.5-0.8-1-1.1-1.4c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.3-0.4-0.5-0.6-0.8
                    c1.2-8.9,8.3-15.7,17.1-16.7l7,17.4l7.5-5v4.1h1.4l-2.9,19.3c-0.2,0-0.4-0.1-0.6-0.1C40.6,95.7,39.9,95.5,39.1,95.4z M42.5,51.3
                    c-8-2.9-14.2-16.6-14.2-26.3c0-0.2,0-0.4,0-0.6l1.2-1.5c2.8-3.3,7.4-4.2,11.7-2.3c1.7,0.8,3.5,1.1,5.4,1.1c5,0,9.4-2.7,11.7-7
                    c5.3,1.7,10,5,13.2,9.2c0.5,10.2-5.5,24.2-14,27.4C56,51.9,55,53.3,55,55v1.1c0,1.9,1.4,3.5,3.2,3.9l-2.8,3.4H44.7l-2.8-3.4
                    c1.8-0.4,3.2-2,3.2-3.9V55C45,53.4,44,51.9,42.5,51.3z M50.1,3.3C50.1,3.3,50.1,3.3,50.1,3.3c0.9,0,1.7,0,2.4,0.1
                    c8.3,0.9,15.2,6.7,17.9,14.3c-3.5-3.1-7.8-5.4-12.5-6.7l-1.4-0.4l-0.6,1.4c-1.5,3.8-5.2,6.3-9.3,6.3c-1.4,0-2.8-0.3-4-0.9
                    c-4.6-2-9.6-1.5-13.4,1.2c1-3.4,2.9-6.5,5.4-9C38.8,5.6,44.2,3.3,50.1,3.3z M56.7,66.9l5.8-6.9h5.1l-5,12.5l-5.9-3.9V66.9z
                    M53.9,96.5c-0.4,0-0.9,0.1-1.3,0.1c-0.9,0-1.7,0.1-2.6,0.1c-0.1,0-0.1,0-0.2,0c-0.8,0-1.6,0-2.4-0.1c-0.4,0-0.9-0.1-1.3-0.1
                    c-0.3,0-0.6-0.1-0.9-0.1l2.9-19.7h3.8l3,19.7C54.6,96.4,54.3,96.5,53.9,96.5z M53.3,73.3h-6.7v-3v-3.7h6.7v3.7V73.3z M43.3,66.9v1.6
                    l-5.9,3.9l-5-12.5h5.1L43.3,66.9z M33.3,6.4c-0.3,0.3-0.7,0.6-1,0.9C27.6,12,25,18.3,25,25c0,11,7,26,16.3,29.4
                    c0.2,0.1,0.3,0.3,0.3,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.9h-8h-0.9c-0.2,0-0.3,0-0.5,0.1C20.1,57.3,12,63.8,9.3,72.8
                    c-3.8-6.8-6-14.5-6-22.8C3.3,30.2,15.8,13.2,33.3,6.4z">
                    </path>
                  </svg>`,

					title: 'Profile',
					text: 'Each dietitian can create a personalized profile complete with information about their educational background, working experience and expertise.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M27.3,35.5c-0.2,0-0.4,0-0.6,0c0.2-0.3,0.3-0.6,0.4-0.9c0.5-1.3,0.2-2-0.1-2.3c-0.1-0.1-0.3-0.2-0.5-0.2
                    c-0.1,0-1,0.1-1.7,0.9c-0.2,0.3-0.4,0.6-0.5,1c-0.3-0.9-0.7-1.2-0.8-1.3c-0.2-0.1-0.3-0.1-0.5-0.1c0,0-0.3,0.1-0.7,0.2
                    c-0.6,0.2-1.1,0.3-1.1,0.9c0,0.3,0.1,0.6,0.4,0.7c0.3,0.1,0.7,0.6,1.2,1.3c0,0-0.1,0-0.1,0c-0.9,0-2.2,0.3-3.3,1.7
                    c-1,1.2-1.1,3.1-0.2,5c0.9,2,2.6,3.2,4.2,3.2c0.1,0,0.1,0,0.2,0c1-0.1,1.7-0.3,2.2-0.7l0.1,0l0,0c0.2,0.1,0.5,0.1,0.7-0.1l0.1,0
                    c0.2,0,0.4-0.1,0.6-0.3c0-0.1,0.1-0.1,0.1-0.2c0.9,0,1.8-0.3,2.4-0.7c1-0.7,1.5-1.7,1.5-3.2c0-1.8-0.4-3.1-1.3-4
                    C29.1,35.6,28,35.5,27.3,35.5z M25.8,33.9c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c-0.1,0.5-0.4,1-0.7,1.4
                    C25.5,34.6,25.6,34.1,25.8,33.9z M29,42.6c-0.5,0.4-1.3,0.5-1.8,0.5c-0.5,0-1-0.1-1-0.1c-0.2,0-0.3,0-0.5,0.1
                    c-0.1,0.1-0.2,0.2-0.2,0.3l-0.4,0.5c-0.3,0.2-0.8,0.4-1.6,0.4c0,0-0.1,0-0.1,0c-1.3,0-2.5-1.2-3-2.5c-0.6-1.4-0.6-2.8,0-3.6
                    c0.7-0.8,1.4-1.2,2.3-1.2c0.6,0,1.1,0.2,1.1,0.2l0,0c0.1,0.1,0.4,0.2,0.8,0.2c0.4,0,0.9-0.1,1.4-0.4c0.3-0.2,0.8-0.3,1.2-0.3
                    c0.5,0,1.1,0.1,1.7,0.7c0.6,0.6,0.9,1.7,0.9,3.1C29.9,41.5,29.6,42.2,29,42.6z"></path>
                    <path d="M42.1,1.2h-8.5V0.7c0-0.4-0.3-0.7-0.7-0.7H17.3c-0.4,0-0.7,0.3-0.7,0.7v0.6H8.2c-0.4,0-0.7,0.3-0.7,0.7v47.4
                    c0,0.4,0.3,0.7,0.7,0.7H36c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l6-5.5
                    c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.1-0.4V1.9C42.7,1.5,42.4,1.2,42.1,1.2z M18,1.3h4V2c0,0.4,0.3,0.7,0.7,0.7H27
                    c0.4,0,0.7-0.3,0.7-0.7V1.3h4.6v3.1H18V1.3z M36.7,47.9l0-3.6l3.8,0L36.7,47.9z M41.4,43L36,43c-0.2,0-0.3,0.1-0.5,0.2
                    c-0.1,0.1-0.2,0.3-0.2,0.5l0,5H8.9V2.6h7.8v2.6c0,0.4,0.3,0.7,0.7,0.7h15.6c0.4,0,0.7-0.3,0.7-0.7V2.6h7.8V43z"></path>
                    <path d="M16.3,11.2c0,0.4,0.3,0.7,0.7,0.7h19.8c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7H17C16.6,10.5,16.3,10.8,16.3,11.2z"></path>
                    <path d="M14.6,10.5H13c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7S14.9,10.5,14.6,10.5z"></path>
                    <path d="M36.8,14.6H17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h19.8c0.4,0,0.7-0.3,0.7-0.7C37.4,14.9,37.1,14.6,36.8,14.6z"></path>
                    <path d="M14.6,14.6H13c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7C15.2,14.9,14.9,14.6,14.6,14.6z"></path>
                    <path d="M36.8,18.9H17c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h19.8c0.4,0,0.7-0.3,0.7-0.7S37.1,18.9,36.8,18.9z"></path>
                    <path d="M14.6,18.9H13c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7S14.9,18.9,14.6,18.9z"></path>
                    <path d="M36.8,23.3H17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h19.8c0.4,0,0.7-0.3,0.7-0.7C37.4,23.6,37.1,23.3,36.8,23.3z"></path>
                    <path d="M14.6,23.3H13c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7C15.2,23.6,14.9,23.3,14.6,23.3z"></path>
                    <path d="M36.8,27.6H17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h19.8c0.4,0,0.7-0.3,0.7-0.7C37.4,27.9,37.1,27.6,36.8,27.6z"></path>
                    <path d="M14.6,27.6H13c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.7-0.3,0.7-0.7C15.2,27.9,14.9,27.6,14.6,27.6z"></path>
                  </svg>`,

					title: 'Customized Nutrition Plan',
					text: 'Dietitian can easily create a customized nutrition plan for each user with the help of a simplified UI for picking and adding diet items.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M34.2,0H15.8c-1.4,0-2.5,1.1-2.5,2.5v30c0,1.4,1.1,2.5,2.5,2.5h5v-1.7h-5c-0.5,0-0.8-0.4-0.8-0.8v-30c0-0.5,0.4-0.8,0.8-0.8
                    h18.3C34.6,1.7,35,2,35,2.5v30c0,0.5-0.4,0.8-0.8,0.8h-5V35h5c1.4,0,2.5-1.1,2.5-2.5v-30C36.7,1.1,35.5,0,34.2,0z"></path>
                    <path d="M2.5,31.7h9.2V30H2.5c-0.5,0-0.8-0.4-0.8-0.8V5.8C1.7,5.4,2,5,2.5,5h9.2V3.3H2.5C1.1,3.3,0,4.5,0,5.8v23.3
                    C0,30.5,1.1,31.7,2.5,31.7z"></path>
                    <rect x="20" y="4.2" width="1.7" height="1.7"></rect>
                    <rect x="23.3" y="4.2" width="6.7" height="1.7"></rect>
                    <rect x="17.5" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="20.8" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="24.2" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="27.5" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="30.8" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="24.2" y="14.2" width="5.8" height="1.7"></rect>
                    <path d="M22.5,29.2h5c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5h-5c-1.4,0-2.5,1.1-2.5,2.5S21.1,29.2,22.5,29.2z M22.5,25.8h5
                    c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-5c-0.5,0-0.8-0.4-0.8-0.8S22,25.8,22.5,25.8z"></path>
                    <rect x="9.2" y="6.7" width="2.5" height="1.7"></rect>
                    <rect x="3.3" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="6.7" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="10" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="5.8" y="6.7" width="1.7" height="1.7"></rect>
                    <path d="M6.7,27.5h5v-1.7h-5c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h5v-1.7h-5c-1.4,0-2.5,1.1-2.5,2.5
                    C4.2,26.4,5.3,27.5,6.7,27.5z"></path>
                    <path d="M47.5,3.3h-9.2V5h9.2c0.5,0,0.8,0.4,0.8,0.8v23.3c0,0.5-0.4,0.8-0.8,0.8h-9.2v1.7h9.2c1.4,0,2.5-1.1,2.5-2.5V5.8
                    C50,4.5,48.9,3.3,47.5,3.3z"></path>
                    <rect x="38.3" y="6.7" width="5.8" height="1.7"></rect>
                    <rect x="45" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="41.7" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="38.3" y="10.8" width="1.7" height="2.5"></rect>
                    <path d="M38.3,22.5v1.7h5c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8h-5v1.7h5c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5H38.3z
                    "></path>
                    <path d="M37.5,49.2v-9.1c0-1.2-0.9-2.3-2.1-2.6c-0.7-0.1-1.5,0.1-2,0.6c0,0,0,0,0,0c-0.9-0.9-2.4-0.9-3.3,0
                    c-0.7-0.6-1.6-0.8-2.5-0.5v-4.2c0-1.2-0.9-2.3-2.1-2.6c-1.4-0.2-2.7,0.7-2.9,2c0,0.1,0,0.3,0,0.4v10.2l-2.2-1.3
                    c-0.7-0.4-1.5-0.4-2.2-0.1l-0.1,0.1c-1.1,0.6-1.6,2-1,3.1c0.2,0.3,0.4,0.6,0.7,0.8l5.2,3.7c0.1,0.1,0.3,0.2,0.5,0.2h13.3
                    C37.1,50,37.5,49.6,37.5,49.2z M35.8,48.3H23.6l-5-3.6c-0.3-0.2-0.4-0.6-0.1-0.9c0.1-0.1,0.1-0.2,0.2-0.2l0.1-0.1
                    c0.2-0.1,0.4-0.1,0.6,0l3.5,2.1c0.4,0.2,0.9,0.1,1.1-0.3c0.1-0.1,0.1-0.3,0.1-0.4V33.3c0-0.5,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0
                    c0.4,0.1,0.7,0.5,0.7,0.9V40c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8
                    c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
                    c0-0.5,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0c0.4,0.1,0.7,0.5,0.7,0.9V48.3z"></path>
                    <rect x="20" y="14.2" width="2.5" height="1.7"></rect>
                    <rect x="20" y="17.5" width="10" height="1.7"></rect>
                    <rect x="21.7" y="20.8" width="6.7" height="1.7"></rect>
                    <rect x="6.7" y="15.8" width="5" height="1.7"></rect>
                    <rect x="8.3" y="19.2" width="3.3" height="1.7"></rect>
                    <rect x="38.3" y="15.8" width="4.2" height="1.7"></rect>
                    <rect x="38.3" y="19.2" width="2.5" height="1.7"></rect>
                  </svg>`,

					title: 'Subscription Packages',
					text: 'Dietitians can set their own subscription packages based on their level of experience, expertise and services offered.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M21.8,41c-0.1-0.4-0.2-0.8-0.3-1.3c-0.2-0.7-0.4-1.3-0.4-1.9c-0.2-1.4,0.2-2.8,0.7-4.3c0-0.1,0.4-1.5,0.4-2.2l0-0.2
                      c0-0.2,0-0.4,0-0.6c-0.2-1.7-1.1-2.6-2.9-2.6c0,0-0.2,0-0.2,0c-1.7,0-3.4,0.5-5,1.4c-2.7,1.6-3,3.5-2.7,5.8c0.2,1.5,1,2.8,1.7,3.9
                      l0.1,0.2c1,1.6,1.3,3.4,1.5,5c0.2,1.9,1.6,3.3,3.5,3.3c0.1,0,0.3,0,0.4,0c2.7-0.3,3.9-1.5,3.8-3.7c0-0.8-0.2-1.5-0.4-2.1L21.8,41z
                      M18.4,45.9c-1.1,0.1-2.1-0.7-2.2-1.9c-0.2-1.8-0.5-3.8-1.7-5.6l-0.1-0.2c-0.7-1.1-1.3-2.1-1.5-3.3c-0.3-1.9,0-3.1,2-4.3
                      c1.3-0.8,2.7-1.2,4.3-1.2c0,0,0,0,0,0c1,0,1.4,0.3,1.5,1.3c0,0.1,0,0.3,0,0.4l0,0.3c0,0.4-0.2,1.5-0.3,1.7c-0.6,1.7-1,3.3-0.8,5
                      c0.1,0.7,0.3,1.4,0.5,2.2c0.1,0.4,0.2,0.7,0.3,1.1l0.1,0.6c0.2,0.6,0.3,1.2,0.4,1.9C21,45.3,20,45.7,18.4,45.9z"></path>
                      <path d="M15.8,27c0-0.3-0.1-0.6-0.3-0.9c-0.3-0.5-0.9-0.5-1.1,0c-0.1,0.3-0.2,0.6-0.2,1c0,0.6,0.4,1,0.8,0.9
                      C15.4,28,15.9,27.6,15.8,27z"></path>
                      <path d="M13.9,28.7c0.3-0.3,0.3-0.7,0.1-1.1c0,0,0,0,0,0c-0.1-0.3-0.3-0.5-0.7-0.5c-0.4,0.1-0.5,0.4-0.5,0.7c0,0.4,0.1,0.8,0.6,1
                      C13.5,28.9,13.8,28.8,13.9,28.7z"></path>
                      <path d="M12.9,29.1c0-0.3-0.2-0.8-0.5-0.8c-0.3,0-0.5,0.3-0.6,0.7c0,0.4,0.2,0.8,0.5,0.8C12.6,29.9,12.8,29.5,12.9,29.1z"></path>
                      <path d="M20.5,27.3c0.8-0.7,0.8-2.5,0-3.3c-0.2-0.2-0.4-0.3-0.6-0.3c-0.2,0-0.5,0.1-0.7,0.2c-0.9,0.7-1.1,2.4-0.4,3.3
                      c0.2,0.3,0.5,0.4,0.8,0.4C20,27.6,20.3,27.5,20.5,27.3z"></path>
                      <path d="M17.7,27.2c0.4-0.5,0.5-1.4,0.2-2c-0.2-0.3-0.4-0.5-0.7-0.5c-0.3,0-0.6,0.1-0.8,0.4c-0.4,0.5-0.4,1.5,0,2
                      c0.2,0.2,0.4,0.4,0.6,0.4C17.3,27.5,17.5,27.4,17.7,27.2z"></path>
                      <path d="M35.9,29.2c-1.6-0.9-3.3-1.3-5.1-1.4c-1.8,0-2.7,0.9-2.9,2.6c0,0.2,0,0.4,0,0.6l0,0.1c0,0.8,0.3,2.2,0.3,2.2
                      c0.5,1.6,0.9,3,0.7,4.4c-0.1,0.6-0.2,1.2-0.5,2c-0.1,0.4-0.2,0.8-0.3,1.2L28,41.5c-0.2,0.6-0.4,1.4-0.4,2.2
                      c-0.1,2.1,1.1,3.4,3.8,3.7c0.1,0,0.3,0,0.4,0c1.8,0,3.3-1.3,3.5-3.3c0.2-1.6,0.5-3.4,1.5-5l0.1-0.2c0.7-1.1,1.5-2.4,1.7-3.9
                      C39,32.7,38.6,30.8,35.9,29.2z M37.2,34.8c-0.2,1.2-0.8,2.2-1.5,3.3l-0.1,0.2c-1.2,1.9-1.5,3.8-1.7,5.6c-0.1,1.3-1,2.1-2.2,1.9
                      c-1.6-0.2-2.6-0.6-2.5-2.1c0-0.6,0.2-1.3,0.4-1.9l0.2-0.6c0.1-0.4,0.2-0.7,0.3-1.2c0.2-0.7,0.4-1.4,0.5-2.1c0.2-1.7-0.2-3.2-0.8-5
                      c-0.1-0.2-0.3-1.3-0.3-1.8l0-0.2c0-0.1,0-0.3,0-0.4c0.1-0.9,0.5-1.3,1.6-1.3c1.5,0,2.9,0.4,4.2,1.2C37.2,31.7,37.4,32.9,37.2,34.8z
                      "></path>
                      <path d="M35.7,27.1c0-0.3,0-0.7-0.2-1c-0.2-0.5-0.8-0.5-1.1,0c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.6,0.4,1,0.8,1
                      C35.3,28.1,35.7,27.7,35.7,27.1z"></path>
                      <path d="M36.6,28.8c0.4-0.2,0.5-0.6,0.6-1c0-0.3-0.1-0.7-0.5-0.7c-0.3-0.1-0.6,0.2-0.7,0.5c0,0,0,0,0,0c-0.1,0.4-0.2,0.8,0.1,1.1
                      C36.2,28.8,36.5,28.9,36.6,28.8z"></path>
                      <path d="M38.2,29.1c0-0.4-0.3-0.7-0.6-0.7c-0.3,0-0.5,0.4-0.5,0.8c0,0.4,0.3,0.7,0.6,0.7C38,29.9,38.2,29.5,38.2,29.1z"></path>
                      <path d="M31.1,27.2c0.7-0.8,0.5-2.6-0.4-3.3c-0.2-0.2-0.5-0.2-0.7-0.2c-0.2,0-0.4,0.1-0.6,0.3c-0.8,0.8-0.8,2.5,0,3.3
                      c0.3,0.2,0.6,0.3,0.9,0.3C30.6,27.6,30.9,27.5,31.1,27.2z"></path>
                      <path d="M33.5,27.1c0.4-0.5,0.4-1.4,0-2c-0.2-0.3-0.5-0.4-0.8-0.4c-0.3,0-0.5,0.2-0.7,0.5c-0.3,0.6-0.2,1.5,0.2,2
                      c0.2,0.2,0.4,0.3,0.7,0.3C33.1,27.4,33.4,27.3,33.5,27.1z"></path>
                      <path d="M45.8,0c0,0-0.1,0-0.1,0H4.3C1.8,0,0.2,1.6,0,4.1c0,0.1,0,0.2,0,0.3v41.3c0,0.1,0.1,0.3,0.1,0.4l0.1,0.4
                      C0.8,48.6,2.7,50,5,50l9.8,0h4.9h18.4h6.8c3,0,5.2-2.1,5.2-5.2l0-39.7C50,1.6,47.8,0.2,45.8,0z M48.3,44.8c0,2.1-1.4,3.5-3.5,3.5
                      L5,48.3c-1.5,0-2.8-0.9-3.2-2.3l-0.1-0.4V4.4c0,0,0,0,0-0.1c0.1-1.7,1-2.6,2.6-2.6h41.3c0,0,0,0,0,0c1,0.1,2.7,0.6,2.7,3.5
                      L48.3,44.8z"></path>
                      <path d="M35.3,6.1c-0.7-1.6-2.1-2.6-4-2.6c-4.2,0-8.4,0-12.6,0c-1.8,0-3.3,0.9-4,2.6c-1.1,2.4-2.1,4.8-3.2,7.2l-0.8,1.9
                      c-0.4,0.9-0.5,1.9-0.2,2.9c0.5,2,2.2,3.2,4.4,3.2c1.3,0,2.6,0,3.9,0c1,0,2.1,0,3.1,0l3.1,0l3.3,0c2.3,0,4.6,0,6.9,0
                      c1.6,0,3-0.7,3.8-2c0.8-1.2,0.9-2.7,0.3-4C38,12.3,36.7,9.2,35.3,6.1z M13,14.1c1-2.4,2.1-4.8,3.2-7.2c0.5-1.1,1.3-1.6,2.5-1.6
                      c4.2,0,8.4,0,12.6,0c1.2,0,2,0.5,2.5,1.6c1.2,2.7,2.4,5.5,3.6,8.3c0,0-0.1-0.1-0.1-0.1c-1.1-0.7-2.4-1.2-4-1.6
                      c-2.5-0.6-5.2-0.9-8-0.9L27,10c0.3-0.4,0.1-0.9-0.2-1.2c-0.4-0.3-0.9-0.2-1.2,0.2l-2.3,3.5c-1.2,0.1-2.4,0.1-3.7,0.3
                      c-2.3,0.3-4.1,0.8-5.8,1.5c-0.4,0.2-0.8,0.4-1.2,0.7L13,14.1z M37.6,18.5c-0.5,0.8-1.4,1.2-2.4,1.2c-2.3,0-4.6,0-6.9,0H25h-3.1
                      c-2.3,0-4.7,0-7,0c-1.3,0-2.2-0.6-2.6-1.7c0.3-0.8,1.1-1.5,2.3-2.1c1.4-0.7,3.1-1.1,5.2-1.4c4.8-0.6,9.1-0.4,13.1,0.5
                      c1.4,0.3,2.6,0.8,3.5,1.4c0.7,0.5,1.2,1,1.4,1.6C37.8,18.2,37.7,18.3,37.6,18.5z"></path>
                    </g>
                  </svg>`,

					title: 'BMI Calculator',
					text: 'A built-in BMI calculator that allows dietitian to quickly calculate the BMI'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M21.9,37.5H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h12.5c0.6,0,1-0.5,1-1C22.9,38,22.5,37.5,21.9,37.5z M28.3,47.9H2.1V10.4
                        h7.3c0.6,0,1-0.5,1-1V2.1h22.9v16.2c0,0.6,0.5,1,1,1s1-0.5,1-1V1c0-0.6-0.5-1-1-1h-25C9.2,0,9.1,0,9,0.1c-0.1,0-0.1,0.1-0.2,0.1
                        c-0.1,0-0.1,0.1-0.2,0.1L0.3,8.6c0,0-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2C0,9.1,0,9.2,0,9.4V49c0,0.6,0.5,1,1,1h27.3
                        c0.6,0,1-0.5,1-1C29.4,48.4,28.9,47.9,28.3,47.9z M8.3,3.6v4.8H3.6L8.3,3.6z M21.9,31.3H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
                        h12.5c0.6,0,1-0.5,1-1C22.9,31.7,22.5,31.3,21.9,31.3z M26,12.5H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H26c0.6,0,1-0.5,1-1
                        C27.1,13,26.6,12.5,26,12.5z M43.7,27.1c1.3-1.2,2.1-3,2.1-5.3c0-0.6-0.5-1-1-1c-2.4,0-4.8,1.1-6.1,3.2c-0.5-1.4-1.1-2.3-1.3-2.7
                        c-0.3-0.5-0.9-0.6-1.4-0.3c-0.5,0.3-0.6,1-0.3,1.4c0.8,1.3,1.6,3.3,1.8,5.6c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.4-1.9-0.8-3.1-0.8
                        c-4.1,0-6.9,3.9-6.9,9.6c0,5.3,3.7,13.3,9.2,13.3c1,0,1.5-0.2,1.9-0.5c0.2-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.4,0.1
                        c0.5,0.2,0.9,0.5,1.9,0.5c5.5,0,9.2-8,9.2-13.3C50,31.2,47.5,27.5,43.7,27.1z M43.6,23c-0.4,2.3-2.1,3.6-4,3.9
                        C40.1,24.7,41.8,23.4,43.6,23z M40.8,47.9c-0.5,0-0.7-0.1-0.9-0.2c-0.3-0.2-0.7-0.4-1.3-0.4c-0.1,0-0.1,0-0.2,0
                        c-0.6,0-1,0.2-1.2,0.4c-0.2,0.1-0.4,0.2-0.9,0.2c-3.6,0-7.1-6.3-7.1-11.2c0-4.5,1.9-7.5,4.8-7.5c0.8,0,1.6,0.3,2.3,0.6
                        c0.7,0.3,1.4,0.6,2.3,0.6h0c0.8,0,1.6-0.3,2.3-0.6c0.8-0.3,1.5-0.6,2.3-0.6c2.9,0,4.8,3,4.8,7.5C47.9,41.6,44.5,47.9,40.8,47.9z
                        M27.1,19.8c0-0.6-0.5-1-1-1H9.4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H26C26.6,20.8,27.1,20.4,27.1,19.8z M25,26c0-0.6-0.5-1-1-1H9.4
                        c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H24C24.5,27.1,25,26.6,25,26z"></path>
                      </g>
                    </g>
                  </svg>`,

					title: 'Fitness Chart',
					text: 'Dietitians can create customized fitness charts for each users aligned to their fitness goals and diet practices.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M28.3,30.3h-7.6c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3h6.1v-1.5h-6.1c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8h7.6
                    c2.1,0,3.8-1.7,3.8-3.8c0-1.7-1.2-3.2-2.8-3.7l-0.4,1.5c1.2,0.3,1.9,1.6,1.6,2.8C30.3,29.6,29.4,30.3,28.3,30.3z"></path>
                    <rect x="23.8" y="27.3" width="1.5" height="1.5"></rect>
                    <rect x="26.8" y="27.3" width="1.5" height="1.5"></rect>
                    <rect x="20.8" y="27.3" width="1.5" height="1.5"></rect>
                    <path d="M10.9,18.2h18l-1.3,5.1c-0.1,0.4,0.1,0.8,0.6,0.9c0.3,0.1,0.5,0,0.7-0.2l5.8-5.8h3.5c5,0,9.1-4.1,9.1-9.1S43.2,0,38.2,0
                    H10.9c-5,0-9.1,4.1-9.1,9.1S5.9,18.2,10.9,18.2z M10.9,1.5h27.3c4.2,0,7.6,3.4,7.6,7.6s-3.4,7.6-7.6,7.6h-3.8
                    c-0.2,0-0.4,0.1-0.5,0.2L29.7,21l0.9-3.4c0.1-0.4-0.1-0.8-0.6-0.9c-0.1,0-0.1,0-0.2,0H10.9c-4.2,0-7.6-3.4-7.6-7.6S6.7,1.5,10.9,1.5
                    z"></path>
                    <path d="M24.5,15.2c3.3,0,6.1-2.7,6.1-6.1S27.9,3,24.5,3s-6.1,2.7-6.1,6.1C18.5,12.4,21.2,15.1,24.5,15.2z M24.5,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C20,6.6,22,4.5,24.5,4.5z"></path>
                    <path d="M10.9,15.2c3.3,0,6.1-2.7,6.1-6.1S14.3,3,10.9,3S4.8,5.7,4.8,9.1C4.9,12.4,7.6,15.1,10.9,15.2z M10.9,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C6.4,6.6,8.4,4.5,10.9,4.5z"></path>
                    <path d="M38.2,15.2c3.3,0,6.1-2.7,6.1-6.1S41.5,3,38.2,3c-3.3,0-6.1,2.7-6.1,6.1C32.1,12.4,34.8,15.1,38.2,15.2z M38.2,4.5
                    c2.5,0,4.5,2,4.5,4.5s-2,4.5-4.5,4.5s-4.5-2-4.5-4.5C33.6,6.6,35.7,4.5,38.2,4.5z"></path>
                    <rect x="21.5" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="26.1" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="23" y="10.6" width="3" height="1.5"></rect>
                    <rect x="7.9" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="12.4" y="7.6" width="1.5" height="1.5"></rect>
                    <path d="M9.9,11.8c0.5-0.5,1.4-0.5,2,0l1.1-1.1c-1.1-1.1-3-1.1-4.1,0L9.9,11.8z"></path>
                    <rect x="35.2" y="7.6" width="1.5" height="1.5"></rect>
                    <rect x="39.7" y="7.6" width="1.5" height="1.5"></rect>
                    <path d="M38.2,12c0.8,0,1.5-0.3,2.1-0.8l-1.1-1.1c-0.5,0.5-1.4,0.5-2,0l-1.1,1.1C36.7,11.7,37.4,12,38.2,12z"></path>
                    <path d="M41.8,40c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9
                    c0-0.5,0.4-0.9,0.9-0.9c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1V50H50V39.2c0-1.4-0.9-2.5-2.3-2.8c-0.8-0.1-1.6,0.1-2.2,0.6l-0.1,0
                    c-1-0.9-2.6-0.9-3.6,0c-0.1-0.1-0.2-0.2-0.3-0.2c0.2-0.4,0.3-0.9,0.3-1.4V22.7c0-2-1.6-3.6-3.6-3.6h-2.7v10.2
                    c-1.1,0.4-1.8,1.4-1.8,2.6v5.5h-20v-4.2l0.1-0.1c1.6-1,2.1-3.1,1.2-4.8c-0.3-0.5-0.8-0.9-1.3-1.2v-6.1h13.6v-1.8H10.9
                    c-2,0-3.6,1.6-3.6,3.6V24L3,26.5c-1.9,1.2-3,3.2-3,5.4V50h1.8v-4.5h7.3V50h1.8v-5.5c0-0.5-0.4-0.9-0.9-0.9H9.1v-0.5
                    c1-1.1,1.5-2.5,1.5-4c0.1,0,0.2,0,0.3,0h22.7v3.8l-2.4-1.4c-0.7-0.4-1.7-0.5-2.4-0.1l-0.1,0.1c-1.2,0.6-1.7,2.1-1.1,3.4
                    c0.2,0.4,0.5,0.7,0.8,0.9l5.3,3.8V50h1.8v-0.9c0-0.3-0.1-0.6-0.4-0.7l-5.7-4.1c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.5,0.4-0.6
                    l0.1-0.1c0.2-0.1,0.5-0.1,0.7,0l3.8,2.3c0.4,0.3,1,0.1,1.2-0.3c0.1-0.1,0.1-0.3,0.1-0.5V31.8c0-0.5,0.4-0.9,0.9-0.9
                    c0.1,0,0.1,0,0.2,0c0.5,0.1,0.8,0.5,0.7,1v7.2c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                    C40.9,39.6,41.3,40,41.8,40z M10.9,20.9h0.9v5.6c-0.7,0-1.3,0.2-1.9,0.6l-0.9,0.6v-5C9.1,21.7,9.9,20.9,10.9,20.9z M7.5,36.1
                    c1.7,1.7,1.7,4.3,0,6c-0.2,0.2-0.3,0.4-0.3,0.6v0.9H1.8V31.9c0-1.6,0.8-3,2.1-3.8l3.3-1.9v2.8L4,31.1l1,1.5l5.9-3.9
                    c0.8-0.5,1.8-0.3,2.4,0.5c0,0,0,0,0,0.1c0.5,0.8,0.2,1.8-0.6,2.3l-5.1,3.2c-0.4,0.3-0.6,0.8-0.3,1.3C7.4,36,7.5,36,7.5,36.1z
                    M10.3,37.2L10.3,37.2c-0.2-0.5-0.4-1-0.8-1.5l2.3-1.4v3h-0.9C10.7,37.3,10.5,37.2,10.3,37.2z M39.1,36.5v-4.6
                    c0-1.2-0.7-2.2-1.8-2.7v-8.3h0.9c1,0,1.8,0.8,1.8,1.8v12.7c0,0.3-0.1,0.7-0.3,0.9C39.5,36.4,39.3,36.4,39.1,36.5z"></path>
                    <rect x="8.6" y="43.9" width="1.5" height="1.5"></rect>
                  </svg>`,

					title: 'Rating and Reviews',
					text: 'Dietitian can rate and review its client as per its observation and fitness progress.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g transform="translate(0,-952.36218)">
                      <path d="M8.4,952.4c-3.4,0-6.1,2.9-6.1,6.2v22.7c0,3.4,2.8,6.2,6.2,6.2h6.1l-2.1,13.5c-0.1,0.6,0.3,1.2,0.9,1.3
                      c0.4,0.1,0.8-0.1,1.1-0.4l11-14.3h15.9c3.4,0,6.2-2.7,6.2-6.1v-22.9c0-3.4-2.8-6.2-6.2-6.2H8.4z M8.4,954.7h33.1c2.1,0,4,1.9,4,4
                      v22.9c0,2.1-1.8,3.8-4,3.8H25c-0.4,0-0.7,0.2-0.9,0.4L15.5,997l1.6-10.4c0.1-0.6-0.3-1.2-1-1.3c-0.1,0-0.1,0-0.2,0H8.6
                      c-2.1,0-4-1.9-4-4v-22.7C4.6,956.5,6.3,954.7,8.4,954.7z M25,960.9c-2.2,0-4,1.8-4,4s1.8,4,4,4c2.2,0,4-1.8,4-4
                      S27.2,960.9,25,960.9z M25,968.9c-2.1,0-3.9,0.5-5.4,1.3c-1.5,0.8-2.6,2.2-2.6,3.8v4.5c0,0.5,0.4,0.9,0.8,1.1
                      c2.1,0.6,3.8,0.6,6,0.6h2.3c2.2,0,3.9,0,6-0.6c0.5-0.1,0.8-0.6,0.8-1.1V974c0-1.6-1.1-3-2.6-3.8C29,969.3,27.1,968.9,25,968.9z
                      M25,963.2c1,0,1.7,0.7,1.7,1.7c0,1-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7C23.3,963.9,24.1,963.2,25,963.2z M25,971.1
                      c1.7,0,3.2,0.4,4.3,1c1,0.6,1.4,1.2,1.4,1.8v3.6c-1.4,0.3-2.7,0.3-4.5,0.4h-2.3c-1.8,0-3.1-0.1-4.5-0.4V974c0-0.6,0.4-1.3,1.4-1.8
                      C21.8,971.5,23.3,971.1,25,971.1z"></path>
                    </g>
                  </svg>`,

					title: 'Message the users',
					text: 'Users and dietitians can text each other to exchange diet charts, frequency of mails, supplements if any, to be taken.'
				}
			],
			items4: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M25,9.1C11.2,9,0.1,20.1,0,33.9c0,0.6,0.4,1,1,1h4c0.5,0,1-0.4,1-1s-0.4-1-1-1c0,0,0,0,0,0H2c0.1-2.3,0.5-4.5,1.3-6.6
                    l2.8,1.1c0.5,0.2,1.1,0,1.3-0.5c0.2-0.5,0-1.1-0.5-1.3c0,0,0,0,0,0l-2.8-1.1c1-2.2,2.4-4.2,4-6l2.1,2.1c0.4,0.4,1,0.4,1.4,0
                    c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0l-2.1-2.1c1.7-1.5,3.6-2.8,5.6-3.8l1.2,2.7c0.2,0.5,0.8,0.7,1.3,0.5c0.5-0.2,0.7-0.8,0.5-1.3
                    c0,0,0,0,0,0l-1.2-2.7c2.3-0.8,4.7-1.3,7.1-1.4v3c0,0.6,0.5,1,1,1c0.5,0,1-0.4,1-1v-3c2.3,0.1,4.5,0.5,6.7,1.3l-1.1,2.7
                    c-0.2,0.5,0,1.1,0.5,1.3c0,0,0,0,0,0c0.5,0.2,1.1,0,1.3-0.5l1.1-2.7c2.2,1,4.2,2.3,6,4l-2.1,2.1c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0
                    c0.4,0.4,1,0.4,1.4,0l2.1-2.1c1.5,1.7,2.8,3.5,3.8,5.6L43,25.2c-0.5,0.2-0.7,0.8-0.5,1.3c0,0,0,0,0,0c0.2,0.5,0.8,0.7,1.3,0.5
                    l2.7-1.2c0.9,2.2,1.3,4.6,1.4,7h-3c-0.5,0-1,0.4-1,1s0.4,1,1,1c0,0,0,0,0,0h4c0.6,0,1-0.4,1-1C49.9,20.1,38.8,9,25,9.1z"></path>
                    <path d="M20.2,25.5c-4.5,2.7-6,8.5-3.3,13s8.5,6,13,3.3c4.5-2.7,6-8.5,3.3-13c-0.9-1.5-2.1-2.7-3.6-3.5l0.4,2.7
                    c1.6,1.4,2.5,3.4,2.5,5.5c0,4.1-3.4,7.5-7.6,7.4c-4.1,0-7.4-3.3-7.4-7.4c0-2,0.8-3.9,2.2-5.3L20.2,25.5z"></path>
                    <path d="M22.9,36.8c0.6,0.4,1.3,0.6,2.1,0.6c1.5,0,2.8-0.8,3.5-2.1c0.9-1.5,0.7-3.5-0.5-4.8l0.1-0.3c0.1-0.3,0.2-0.7,0.1-1l-1.3-8.1
                    c-0.2-1-1-1.7-2-1.7h0c-1,0-1.8,0.7-2,1.7l-1.3,8.1c-0.1,0.3,0,0.7,0.1,1l0.2,0.4c-0.1,0.2-0.2,0.3-0.3,0.5
                    C20.4,33.1,21.1,35.6,22.9,36.8z M23.3,32.2c0.2-0.4,0.5-0.7,0.9-0.9l-0.7-1.7l1.3-8.1l1.3,8.1l-0.6,1.7c0.2,0,0.3,0.1,0.4,0.2
                    c1,0.6,1.3,1.9,0.7,2.9c-0.3,0.6-1,1-1.7,1.1c-0.4,0-0.7-0.1-1-0.3C23,34.4,22.7,33.2,23.3,32.2z"></path>
                  </svg>`,

					title: 'Dashboard',
					text: 'Admins can have control the app’s functioning, users/dietitians/trainers being added to the app from the dashboard.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M38.6,32.9c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6C44.1,35.4,41.6,32.9,38.6,32.9z M38.6,42.3
                    c-2.1,0-3.8-1.7-3.8-3.8c0-2.1,1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C42.4,40.6,40.7,42.3,38.6,42.3z"></path>
                    <path d="M47.7,35.3c-0.5,0-0.7-0.6-0.4-0.9c0.9-0.9,0.9-2.4,0-3.3L46,29.8c-0.9-0.9-2.4-0.9-3.3,0c-0.4,0.3-0.9,0.1-0.9-0.4
                    c0-1.3-1-2.3-2.3-2.3h-1.8c-0.3,0-0.6,0.1-0.9,0.2c-1.8-3.1-5.1-5.1-8.8-5.1H11.5c-4.9,0-9.2,3.5-10.1,8.4L0,38.3
                    c-0.1,0.5,0.3,1,0.9,1h26.3v0c0,1.3,1,2.3,2.3,2.3c0.5,0,0.7,0.6,0.4,0.9c-0.9,0.9-0.9,2.4,0,3.3l1.3,1.3c0.9,0.9,2.4,0.9,3.3,0
                    c0.3-0.3,0.9-0.1,0.9,0.4c0,1.3,1,2.3,2.3,2.3h1.8c1.3,0,2.3-1,2.3-2.3c0-0.5,0.6-0.7,0.9-0.4c0.9,0.9,2.4,0.9,3.3,0l1.3-1.3
                    c0.9-0.9,0.9-2.4,0-3.3c-0.3-0.3-0.1-0.9,0.4-0.9c1.3,0,2.3-1,2.3-2.3v-1.8C50,36.3,49,35.3,47.7,35.3z M1.9,37.6l1.2-6.7
                    c0.7-4,4.3-7,8.4-7h16.4c3.3,0,6.2,1.9,7.6,4.7c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.5-0.6,0.7-0.9,0.4c-0.9-0.9-2.4-0.9-3.3,0l-1.3,1.3
                    c-0.9,0.9-0.9,2.4,0,3.3c0.3,0.3,0.1,0.9-0.4,0.9c-1.3,0-2.3,1-2.3,2.3v0H1.9z M48.2,39.4c0,0.3-0.2,0.6-0.6,0.6
                    c-2.1,0-3.1,2.5-1.6,3.9c0.2,0.2,0.2,0.6,0,0.8L44.8,46c-0.2,0.2-0.6,0.2-0.8,0c-1.5-1.5-3.9-0.4-3.9,1.6c0,0.3-0.2,0.6-0.6,0.6
                    h-1.8c-0.3,0-0.6-0.2-0.6-0.6c0-2.1-2.5-3.1-3.9-1.6c-0.2,0.2-0.6,0.2-0.8,0l-1.3-1.3c-0.2-0.2-0.2-0.6,0-0.8
                    c1.5-1.5,0.4-3.9-1.6-3.9c-0.3,0-0.6-0.2-0.6-0.6v-1.8c0-0.3,0.2-0.6,0.6-0.6c2.1,0,3.1-2.5,1.6-3.9c-0.2-0.2-0.2-0.6,0-0.8l1.3-1.3
                    c0.2-0.2,0.6-0.2,0.8,0c1.5,1.5,3.9,0.4,3.9-1.6c0-0.3,0.2-0.6,0.6-0.6h1.8c0.3,0,0.6,0.2,0.6,0.6c0,2.1,2.5,3.1,3.9,1.6
                    c0.2-0.2,0.6-0.2,0.8,0l1.3,1.3c0.2,0.2,0.2,0.6,0,0.8c-1.5,1.5-0.4,3.9,1.6,3.9c0.3,0,0.6,0.2,0.6,0.6
                    C48.2,37.6,48.2,39.4,48.2,39.4z"></path>
                    <path d="M19.7,19.3c-4.9,0-8.9-4-8.9-8.9s4-8.9,8.9-8.9s8.9,4,8.9,8.9S24.6,19.3,19.7,19.3z M19.7,3.3c-3.9,0-7.1,3.2-7.1,7.1
                    s3.2,7.1,7.1,7.1s7.1-3.2,7.1-7.1S23.7,3.3,19.7,3.3z"></path>
                  </svg>`,

					title: 'User management',
					text: 'Adding new users, deleting dormant accounts, controlling user privileges — users can do much more with their user management controls.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M34.2,0H15.8c-1.4,0-2.5,1.1-2.5,2.5v30c0,1.4,1.1,2.5,2.5,2.5h5v-1.7h-5c-0.5,0-0.8-0.4-0.8-0.8v-30c0-0.5,0.4-0.8,0.8-0.8
                    h18.3C34.6,1.7,35,2,35,2.5v30c0,0.5-0.4,0.8-0.8,0.8h-5V35h5c1.4,0,2.5-1.1,2.5-2.5v-30C36.7,1.1,35.5,0,34.2,0z"></path>
                    <path d="M2.5,31.7h9.2V30H2.5c-0.5,0-0.8-0.4-0.8-0.8V5.8C1.7,5.4,2,5,2.5,5h9.2V3.3H2.5C1.1,3.3,0,4.5,0,5.8v23.3
                    C0,30.5,1.1,31.7,2.5,31.7z"></path>
                    <rect x="20" y="4.2" width="1.7" height="1.7"></rect>
                    <rect x="23.3" y="4.2" width="6.7" height="1.7"></rect>
                    <rect x="17.5" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="20.8" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="24.2" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="27.5" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="30.8" y="8.3" width="1.7" height="3.3"></rect>
                    <rect x="24.2" y="14.2" width="5.8" height="1.7"></rect>
                    <path d="M22.5,29.2h5c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5h-5c-1.4,0-2.5,1.1-2.5,2.5S21.1,29.2,22.5,29.2z M22.5,25.8h5
                    c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-5c-0.5,0-0.8-0.4-0.8-0.8S22,25.8,22.5,25.8z"></path>
                    <rect x="9.2" y="6.7" width="2.5" height="1.7"></rect>
                    <rect x="3.3" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="6.7" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="10" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="5.8" y="6.7" width="1.7" height="1.7"></rect>
                    <path d="M6.7,27.5h5v-1.7h-5c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h5v-1.7h-5c-1.4,0-2.5,1.1-2.5,2.5
                    C4.2,26.4,5.3,27.5,6.7,27.5z"></path>
                    <path d="M47.5,3.3h-9.2V5h9.2c0.5,0,0.8,0.4,0.8,0.8v23.3c0,0.5-0.4,0.8-0.8,0.8h-9.2v1.7h9.2c1.4,0,2.5-1.1,2.5-2.5V5.8
                    C50,4.5,48.9,3.3,47.5,3.3z"></path>
                    <rect x="38.3" y="6.7" width="5.8" height="1.7"></rect>
                    <rect x="45" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="41.7" y="10.8" width="1.7" height="2.5"></rect>
                    <rect x="38.3" y="10.8" width="1.7" height="2.5"></rect>
                    <path d="M38.3,22.5v1.7h5c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8h-5v1.7h5c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5H38.3z
                    "></path>
                    <rect x="20" y="14.2" width="2.5" height="1.7"></rect>
                    <rect x="20" y="17.5" width="10" height="1.7"></rect>
                    <rect x="21.7" y="20.8" width="6.7" height="1.7"></rect>
                    <rect x="6.7" y="15.8" width="5" height="1.7"></rect>
                    <rect x="8.3" y="19.2" width="3.3" height="1.7"></rect>
                    <rect x="38.3" y="15.8" width="4.2" height="1.7"></rect>
                    <rect x="38.3" y="19.2" width="2.5" height="1.7"></rect>
                    <g>
                      <path d="M42.6,37.7c-0.7-0.9-1.8-1.1-2.8-0.7l-9.1,3.8c-0.4,0.2-0.6,0.6-0.4,1c0.2,0.4,0.6,0.6,1,0.4l9.1-3.8
                      c0.4-0.2,0.8,0,1.1,0.3c0.2,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.5-0.4,0.6c-4,2.6-13.1,7.7-13.5,8c0,0,0,0,0,0l0,0
                      c-1.1,0.7-2.3,0.8-3.9,0.6c-0.1,0-1.6-0.2-7.2-0.9l-2.6-0.3v-10c0.4,0,0.8,0,0.9,0h0.1c3.4-0.2,6.7,0.4,10.2,1.2
                      c0.4,0.1,0.9,0.2,1.3,0.3c0.5,0.1,0.8,0.5,0.8,1c0,0,0,0.1,0,0.1c0,0.3-0.2,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3h-7
                      c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h7c0.7,0,1.3-0.3,1.8-0.7c0.4-0.4,0.6-0.9,0.7-1.4c0-0.1,0-0.2,0-0.3
                      c0-1.2-0.9-2.2-2-2.5c-0.4-0.1-0.9-0.2-1.3-0.3c-3.4-0.7-7-1.4-10.6-1.2h-0.1c-0.2,0-0.5,0-0.9,0c-0.1-0.3-0.4-0.6-0.7-0.6H7.8
                      c-0.4,0-0.7,0.3-0.7,0.7v12.5c0,0.4,0.3,0.7,0.7,0.7h5.5c0.3,0,0.6-0.2,0.7-0.4l2.5,0.3c2.9,0.4,6.9,0.9,7.1,0.9
                      c0.6,0.1,1.1,0.1,1.6,0.1c1.2,0,2.3-0.3,3.3-0.9c0,0,0,0,0,0c0.1-0.1,9.5-5.4,13.6-8c0.6-0.4,1-1,1.1-1.7
                      C43.2,39,43.1,38.3,42.6,37.7z M12.6,47.8h-4v-11h4V47.8z"></path>
                    </g>
                  </svg>`,

					title: 'Manage Subscription plans',
					text: 'Add new subscription plans or edit existing ones to reflect the right pricing plan on the app.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M19.6,13.2c0.9,0.5,1.9,0.8,2.8,0.8c1,0,2-0.3,2.8-0.8c2.1-1.2,3.2-3.7,3.1-6.7c-0.1-3.6-3.1-5.4-5.9-5.5c0,0,0,0-0.1,0
                    c-2.7,0.1-5.8,1.9-5.9,5.5c0,0.2,0,1,0,1.3C16.8,10.2,17.9,12.1,19.6,13.2z M24.5,11.8c-1.2,0.7-2.8,0.7-4,0c-1.2-0.7-2-2-2.2-3.7
                    c1.4,0.3,2.8,0.5,4.3,0.5c0,0,0,0,0,0c1.4,0,2.8-0.2,4.1-0.5C26.4,9.8,25.7,11.1,24.5,11.8z M22.5,2.6c0.9,0,1.8,0.3,2.6,0.8
                    c-0.8,0.1-1.6,0.2-2.5,0.2c-0.9,0-1.8-0.1-2.7-0.2C20.7,2.9,21.6,2.7,22.5,2.6z M18.6,4.8c1.3,0.3,2.6,0.4,3.9,0.4c0,0,0,0,0,0
                    c1.3,0,2.5-0.2,3.7-0.4c0.2,0.4,0.4,1,0.4,1.5c-1.3,0.3-2.7,0.5-4.2,0.5c-1.5,0-3-0.2-4.4-0.6C18.2,5.8,18.4,5.3,18.6,4.8z
                    M38.1,28.5c-0.1,0-0.2,0-0.3,0v-2c0-0.4-0.4-0.8-0.8-0.8H33c-0.4,0-0.8,0.3-0.8,0.8V30h-2.8v-3.4c0-0.4-0.3-0.8-0.8-0.8h-3.9
                    c-0.4,0-0.8,0.3-0.8,0.8v2c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0.4-0.8,0.8v3c0,0.4,0.3,0.8,0.8,0.8c0.1,0,0.1,0,0.2,0v2
                    c0,0.4,0.4,0.8,0.8,0.8h3.9c0.4,0,0.8-0.4,0.8-0.8v-3.4h2.8V35c0,0.4,0.4,0.8,0.8,0.8H37c0.4,0,0.8-0.4,0.8-0.8v-2
                    c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.4,0.8-0.8v-3C38.9,28.8,38.5,28.5,38.1,28.5z M27.7,34.2h-2.3v-6.8h2.3V34.2z M36.2,34.2h-2.4v-6.8
                    h2.4V34.2z M19.1,18.1c0.4,1.5,1.8,2.7,3.4,2.7c1.6,0,3-1.1,3.4-2.7c1.1,0.6,1.9,1.6,2.2,3l0.3,1.4c0.1,0.4,0.5,0.7,0.9,0.7
                    c0.4-0.1,0.7-0.5,0.7-0.9l-0.3-1.3c-0.7-3.6-4.1-5.2-7.2-5.2c0,0,0,0,0,0c-3.1,0-6.5,1.6-7.2,5.2l-1.9,10.3c0,0.2,0,0.5,0.2,0.7
                    c0.2,0.2,0.4,0.3,0.6,0.3h5.3c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-4.3l1.7-9.4C17.1,19.8,18,18.8,19.1,18.1z M22.5,17.3
                    c0.6,0,1.3,0.1,1.9,0.2c-0.1,0.9-0.9,1.7-1.9,1.7c-1,0-1.8-0.7-1.9-1.7C21.2,17.3,21.8,17.3,22.5,17.3z"></path>
                    <g>
                      <path d="M42.4,37.5c-0.7-0.9-1.8-1.1-2.8-0.7l-9.2,3.9c-0.4,0.2-0.6,0.6-0.4,1c0.2,0.4,0.6,0.6,1,0.4l9.2-3.9
                      c0.4-0.2,0.8,0,1.1,0.3c0.2,0.2,0.2,0.5,0.2,0.7c0,0.3-0.2,0.5-0.4,0.6c-4,2.6-13.1,7.8-13.6,8c0,0,0,0,0,0l0,0
                      c-1.1,0.7-2.4,0.8-3.9,0.6c-0.1,0-1.6-0.2-7.2-0.9l-2.6-0.3V37.1c0.4,0,0.8,0,0.9,0h0.1c3.5-0.2,6.8,0.5,10.3,1.2
                      c0.5,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.8,0.5,0.8,1c0,0,0,0.1,0,0.1c0,0.3-0.2,0.5-0.3,0.6c-0.2,0.2-0.5,0.3-0.7,0.3h-7
                      c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h7c0.7,0,1.3-0.3,1.8-0.7c0.4-0.4,0.6-0.9,0.7-1.4c0-0.1,0-0.2,0-0.3c0-1.2-0.9-2.2-2-2.5
                      c-0.5-0.1-0.9-0.2-1.3-0.3c-3.5-0.7-7-1.4-10.7-1.2h-0.1c-0.2,0-0.5,0-0.9,0c-0.1-0.3-0.4-0.6-0.7-0.6H7.4c-0.4,0-0.7,0.3-0.7,0.7
                      v12.5c0,0.4,0.3,0.7,0.7,0.7h5.5c0.3,0,0.6-0.2,0.7-0.4l2.5,0.3c2.9,0.4,7,0.9,7.2,0.9c0.6,0.1,1.1,0.2,1.6,0.2
                      c1.2,0,2.3-0.3,3.3-0.9c0,0,0,0,0,0c0.1-0.1,9.5-5.4,13.7-8.1c0.6-0.4,1-1,1.1-1.7C43,38.7,42.8,38,42.4,37.5z M12.2,47.6H8.1v-11
                      h4.1V47.6z"></path>
                    </g>
                  </svg>`,

					title: 'Manage trainer',
					text: 'Admins can add new trainers to the app, check their background and also maintain trainer records in the backend.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M10,8.3h0.8c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8H10C9.5,6.7,9.2,7,9.2,7.5C9.2,8,9.5,8.3,10,8.3z"></path>
                    <path d="M14.2,8.3H25c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8H14.2c-0.5,0-0.8,0.4-0.8,0.8C13.3,8,13.7,8.3,14.2,8.3z"></path>
                    <path d="M42.4,26.7h-1.8c0,0,0,0,0,0c-0.4,0-0.8,0.1-1.1,0.2c0,0-0.1,0-0.1,0c-0.4,0.2-0.9,0.4-1.2,0.7c0.2-1.9,1.1-4.7,4.7-6.8
                    c0.4-0.2,0.5-0.7,0.3-1.1c-0.2-0.4-0.7-0.5-1.1-0.3c-2.2,1.2-4,3.2-4.9,5.5c0-0.1-0.1-0.3-0.1-0.4c-0.4-1.1-1.1-2.1-2-2.8V7.5
                    C35,6.1,33.9,5,32.5,5H29c-0.4-1-1.3-1.7-2.3-1.7h-4.5c-0.7-2-2.6-3.3-4.7-3.3s-4,1.3-4.7,3.3H8.3C7.3,3.3,6.3,4,6,5H2.5
                    C1.1,5,0,6.1,0,7.5v40C0,48.9,1.1,50,2.5,50h30c0.4,0,0.8-0.1,1.1-0.3c0.8,0.2,1.5,0.3,2.3,0.3h3.1c6,0,10.9-5.2,10.9-11.7v-3.6
                    C50,30.3,46.6,26.7,42.4,26.7z M35.4,25c0.2,0.7,0.4,1.3,0.5,2c-0.1,0-0.2-0.1-0.2-0.1c-0.4-0.1-0.8-0.2-1.3-0.2c0,0,0,0,0,0h-1.8
                    c-0.2,0-0.5,0-0.7,0c0,0,0,0-0.1,0c-2.8-0.6-2.7-3.8-2.6-4.9c0.5-0.1,1.1-0.1,1.6-0.1h0C32.9,21.7,34.8,23,35.4,25L35.4,25z M27,45
                    H5V10h1c0.4,1,1.3,1.7,2.3,1.7h18.3c1.1,0,2-0.7,2.3-1.7h1v10c-2.2,0.1-2.3,0.7-2.4,1c-0.3,1.7-0.1,4.7,1.8,6.4
                    c-2.8,1.4-4.5,4.2-4.5,7.3v3.6C25,40.7,25.7,43,27,45z M33.3,7.5v13c-0.5-0.2-1.1-0.4-1.7-0.5V10c0-0.9-0.7-1.7-1.7-1.7h-0.8V6.7
                    h3.3C33,6.7,33.3,7,33.3,7.5z M8.3,5h5.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.2,0.3-0.3
                    c0,0,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0.3-1.6,1.7-2.7,3.3-2.7c1.6,0,2.9,1.1,3.3,2.7c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                    c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1C21.3,5,21.4,5,21.6,5h5.1c0.5,0,0.8,0.4,0.8,0.8v3.3
                    c0,0.5-0.4,0.8-0.8,0.8H8.3c-0.5,0-0.8-0.4-0.8-0.8V5.8C7.5,5.4,7.9,5,8.3,5z M2.5,48.3c-0.5,0-0.8-0.4-0.8-0.8v-40
                    C1.7,7,2,6.7,2.5,6.7h3.3v1.7H5c-0.9,0-1.7,0.7-1.7,1.7v35c0,0.9,0.7,1.7,1.7,1.7h23.3c0.6,0.6,1.3,1.2,2,1.7H2.5z M48.3,38.3
                    c0,5.5-4.2,10-9.3,10h-3.1c-5.1,0-9.3-4.5-9.3-10v-3.6c0-3.5,2.6-6.4,5.9-6.4h1.8c0.2,0,0.5,0,0.7,0.1c1,0.4,1.6,1.3,1.6,2.4v0.8
                    c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8v-0.8c0-1,0.6-2,1.6-2.4l0,0c0.2-0.1,0.4-0.1,0.7-0.1h1.8c3.3,0,5.9,2.9,5.9,6.4V38.3z
                    "></path>
                    <path d="M31.4,30c-0.1,0-3.1,1.1-3.1,5c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8c0-2.6,1.8-3.3,1.9-3.4c0.4-0.1,0.7-0.6,0.5-1.1
                    S31.8,29.9,31.4,30z"></path>
                    <path d="M10.8,14.2H8.3c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C12.5,14.9,11.8,14.2,10.8,14.2z M8.3,18.3v-2.5h2.5v2.5H8.3z"></path>
                    <path d="M10.8,21.7H8.3c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C12.5,22.4,11.8,21.7,10.8,21.7z M8.3,25.8v-2.5h2.5v2.5H8.3z"></path>
                    <path d="M10.8,29.2H8.3c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C12.5,29.9,11.8,29.2,10.8,29.2z M8.3,33.3v-2.5h2.5v2.5H8.3z"></path>
                    <path d="M10.8,36.7H8.3c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C12.5,37.4,11.8,36.7,10.8,36.7z M8.3,40.8v-2.5h2.5v2.5H8.3z"></path>
                    <path d="M15,18.3h11.7c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8H15c-0.5,0-0.8,0.4-0.8,0.8C14.2,18,14.5,18.3,15,18.3z"></path>
                    <path d="M24.2,24.2H15c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h9.2c0.5,0,0.8-0.4,0.8-0.8C25,24.5,24.6,24.2,24.2,24.2z"></path>
                    <path d="M22.5,31.7H15c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h7.5c0.5,0,0.8-0.4,0.8-0.8C23.3,32,23,31.7,22.5,31.7z"></path>
                    <path d="M22.5,39.2H15c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h7.5c0.5,0,0.8-0.4,0.8-0.8C23.3,39.5,23,39.2,22.5,39.2z"></path>
                  </svg>`,

					title: 'Manage food plans',
					text: 'Admins can create custom food plans on their own and position them in the app for maximum visibility.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M41.3,19c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6s2.7,6,6,6C38.6,25,41.3,22.3,41.3,19z M35.3,23.9c-2.7,0-4.9-2.2-4.9-4.9
                    s2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C40.2,21.7,38,23.9,35.3,23.9z"></path>
                    <polygon points="35.9,15.8 34.8,15.8 34.8,18.5 32.1,18.5 32.1,19.6 34.8,19.6 34.8,22.3 35.9,22.3 35.9,19.6 38.6,19.6 38.6,18.5
                    35.9,18.5 "></polygon>
                    <path d="M29.9,7.6c0-3.3-2.7-6-6-6s-6,2.7-6,6s2.7,6,6,6C27.2,13.6,29.9,10.9,29.9,7.6z M23.9,12.5c-2.7,0-4.9-2.2-4.9-4.9
                    s2.2-4.9,4.9-4.9s4.9,2.2,4.9,4.9C28.8,10.3,26.6,12.5,23.9,12.5z"></path>
                    <polygon points="24.5,4.4 23.4,4.4 23.4,7.1 20.7,7.1 20.7,8.2 23.4,8.2 23.4,10.9 24.5,10.9 24.5,8.2 27.2,8.2 27.2,7.1 24.5,7.1
                    "></polygon>
                    <path d="M12,49.5c3.5,0,8.4-0.8,11.4-3c0.1,2,1.5,3.5,3.2,3.5c0.8,0,1.6-0.4,2.2-1c0.8,0.6,1.7,1,2.7,1c2.1,0,4.3-1.4,4.3-3.3
                    c0,0,0,0,0-0.1c0.4,0,0.8,0,1.2-0.1c1,1.8,2.5,3.4,4.2,3.4c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.8-0.3,1.1-0.5c0.4,0.2,0.7,0.4,1.1,0.5
                    c2.6,0.6,5-2.9,5.7-5.6c0.4-1.5,0.2-3.1-0.5-4.5c-0.2-0.3-0.4-0.6-0.7-0.9c0.9-1.8,1.4-3.8,1.5-5.9v-1c0-0.3-0.2-0.5-0.5-0.5H30.6
                    c0-0.1,0-0.1,0-0.2c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.4-1.1-0.5c0-0.4-0.2-0.8-0.5-1.1c-0.6-0.6-1.7-0.6-2.3,0
                    c-0.5,0.5-0.6,1.2-0.3,1.8l-1,1h-1c0.1-0.3,0.3-0.6,0.4-0.9c1.3-3,1.9-6.2,1.6-9.5L26,20.1c0-0.2-0.1-0.3-0.2-0.4
                    c-0.1-0.1-0.3-0.1-0.5-0.1c-3.4,1-6.5,2.8-8.9,5.4l0,0c-1.1,1.2-2.1,2.5-3,3.9l-1.7,2.9c-0.5,0-0.9,0-1.4,0.1l0.3-6.5
                    c0.1-1.6-1.2-3-2.8-3.1c-0.2,0-0.4,0-0.7,0l-1.3,0.2l-0.4-1.6l1.9-1.4l-0.7-0.9l-1.5,1.1l-0.3-1.4l-1.1,0.3l0.4,1.5h-2v1.1h2.3
                    l0.4,1.5l-0.7,0.1c-1.6,0.3-2.7,1.8-2.4,3.4c0,0.3,0.1,0.5,0.2,0.8l3.3,7C2.1,36.2,0,39.7,0,42.8C0,47.2,4.1,49.5,12,49.5z M26.6,49
                    c-1.2,0-2.2-1.2-2.2-2.7c0-1.6,1.1-3.3,2.2-3.3s2.2,1.6,2.2,3.3C28.8,47.8,27.8,49,26.6,49z M31.5,49c-0.8,0-1.5-0.3-2.1-0.8
                    c0.3-0.6,0.5-1.3,0.5-1.9c0-0.4,0-0.7-0.1-1.1c0.5-0.4,1.1-0.5,1.8-0.5c1.6,0,3.3,1.1,3.3,2.2S33.1,49,31.5,49z M35.6,45.7
                    c-0.7-1.3-2.4-2.1-4-2.1c-0.7,0-1.5,0.2-2.1,0.6c-0.6-1.2-1.6-2.2-2.8-2.2c-0.2,0-0.4,0-0.5,0.1c0-0.6-0.1-1.2-0.3-1.8
                    c2.7,2.2,6.1,3.3,9.6,3.3c0.2,0,0.4,0,0.6,0c0,0.3,0.1,0.6,0.2,0.9c0.1,0.4,0.3,0.9,0.4,1.3C36.2,45.6,35.9,45.7,35.6,45.7z
                    M22.3,34.3c0.6,0,1.2,0.2,1.6,0.5c0.4,0.4,0.6,1,0.5,1.6c-0.1,1.7-0.8,3.4-2,4.7c-1.6,1.6-4,1.7-5.3,0.4c-0.6-0.6-0.9-1.4-0.9-2.2
                    c0-1.1,0.5-2.2,1.3-3C18.8,35.1,20.5,34.4,22.3,34.3z M47,38.1c0.6-0.8,1.2-1.6,1.6-2.5c-0.2,1-0.5,1.9-1,2.8
                    C47.4,38.2,47.2,38.2,47,38.1z M45.7,37.9c-0.8,0.1-1.6,0.3-2.2,0.8c0-1,0.3-1.9,0.8-2.8l-0.9-0.6c-0.7,1-1,2.2-1,3.4
                    c-1-0.7-2.2-0.9-3.3-0.7c-1,0.3-1.9,0.9-2.5,1.9c-0.4,0.8-0.7,1.6-0.7,2.5c-0.2,0-0.4,0-0.6,0c-3.8,0-7.4-1.5-10.1-4.1
                    c0.2-0.6,0.3-1.2,0.3-1.8c0-0.3,0-0.6-0.1-0.9l2.8-2.8c0.6,0.3,1.2,0.2,1.7-0.2h18.6C48.1,34.6,47.1,36.4,45.7,37.9z M48.7,44.1
                    c-0.6,2.5-2.8,5.2-4.4,4.8c-0.4-0.1-0.7-0.3-1.1-0.5c-0.2-0.2-0.5-0.2-0.7,0c-0.3,0.3-0.7,0.4-1.1,0.5c-1.6,0.4-3.8-2.3-4.4-4.8
                    c-0.3-1.2-0.2-2.5,0.4-3.6c0.4-0.7,1-1.2,1.8-1.4c0.2,0,0.4-0.1,0.6-0.1c1,0,1.9,0.5,2.6,1.3c0.2,0.2,0.5,0.2,0.8,0c0,0,0,0,0,0
                    c0.8-0.9,2-1.4,3.2-1.2c0.8,0.2,1.4,0.7,1.8,1.4C48.9,41.6,49.1,42.9,48.7,44.1z M27.1,31c0.2-0.2,0.2-0.6,0-0.8c0,0,0,0,0,0
                    c-0.2-0.2-0.2-0.5,0-0.7c0,0,0,0,0,0c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.5,0,0.7c0,0,0,0,0,0c-0.2,0.2-0.2,0.6,0,0.8
                    c0.2,0.2,0.6,0.2,0.8,0c0.2-0.2,0.6-0.2,0.8,0c0.2,0.2,0.2,0.5,0,0.7c0,0,0,0,0,0c-0.2,0.2-0.6,0.2-0.8,0c-0.2-0.2-0.6-0.2-0.8,0
                    L25,34.5c-0.2-0.3-0.5-0.6-0.8-0.8L27.1,31z M23.2,33.3c-0.5-0.1-0.9-0.1-1.4-0.1c0-0.1,0-0.1,0-0.2v-0.5h2.1L23.2,33.3z M17.8,25.1
                    c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.3-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.1,0.4-0.3,0.5-0.4c0.1-0.1,0.2-0.2,0.4-0.3
                    c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.5-0.2,0.7-0.3
                    c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0.2-0.1,0.2-0.1c0.4-0.1,0.7-0.3,1.1-0.4l0,0.4c0,0.4,0,0.7,0,1.1
                    c0,0,0,0,0,0c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0,0.1l-2.1,2.7l0.8,0.7l1.1-1.4c0,0,0,0.1,0,0.1c0,0.2-0.1,0.4-0.1,0.6
                    c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.2-0.1,0.4-0.2,0.6C24,28.7,24,28.8,23.9,29
                    c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.2-0.1,0.3c-2.7-0.3-5-2-6.1-4.4C17.5,25.4,17.6,25.2,17.8,25.1z M14.4,29.4
                    c0.6-1.1,1.4-2.2,2.3-3.1c1.3,2.5,3.7,4.2,6.5,4.6c-0.1,0.2-0.2,0.4-0.3,0.6h-1.6c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.1,0,0.2,0,0.4
                    c-0.4,0.1-0.8,0.2-1.2,0.4c-2-1.1-4.2-1.8-6.5-2L14.4,29.4z M4,28.7l3.7-0.5l-0.1-1.1l-4.1,0.5L3,26.6c-0.4-0.9,0-2.1,0.9-2.5
                    C4,24,4.2,23.9,4.3,23.9l3.1-0.5c0.9-0.2,1.8,0.4,2.1,1.2L6.5,25l0.2,1.1l3-0.4l-0.2,3.7l-3,0.5L6.6,31l2.7-0.5l-0.1,1.6
                    c-1.1,0.3-2.1,0.7-3.1,1.3L4,28.7z M12,32.9c2.2,0,4.4,0.6,6.3,1.5c-0.6,0.3-1.1,0.7-1.5,1.1c-1,1-1.6,2.3-1.6,3.8
                    c0,1.1,0.4,2.2,1.2,3c0.8,0.8,1.9,1.2,3,1.2c1.4,0,2.8-0.6,3.8-1.6c0.5-0.6,1-1.2,1.4-1.9c0.3,0.6,0.4,1.3,0.5,1.9
                    c0,0.2,0,0.4-0.1,0.6c-0.7,0.7-1.2,1.5-1.4,2.4c-2.2,2-6.8,3.4-11.6,3.4c-5,0-10.9-1-10.9-5.6C1.1,38.3,6.1,32.9,12,32.9z"></path>
                    <rect x="40.8" y="34.8" width="1.1" height="1.6"></rect>
                    <rect x="37.5" y="34.8" width="1.1" height="1.6"></rect>
                    <rect x="34.2" y="34.8" width="1.1" height="1.6"></rect>
                    <rect x="31" y="34.8" width="1.1" height="1.6"></rect>
                    <rect x="27.7" y="34.8" width="1.1" height="1.6"></rect>
                    <path d="M12.5,35.6v-1.1c-4.5,0-8.1,2.8-8.1,6.2h1.1C5.4,37.9,8.6,35.6,12.5,35.6z"></path>
                    <path d="M4.9,9.8c2.7,0,4.9-2.2,4.9-4.9S7.6,0,4.9,0S0,2.2,0,4.9C0,7.6,2.2,9.8,4.9,9.8z M4.9,1.1c2.1,0,3.8,1.7,3.8,3.8
                    S7,8.7,4.9,8.7S1.1,7,1.1,4.9C1.1,2.8,2.8,1.1,4.9,1.1z"></path>
                    <polygon points="4.4,7.6 5.4,7.6 5.4,5.5 7.6,5.5 7.6,4.4 5.4,4.4 5.4,2.2 4.4,2.2 4.4,4.4 2.2,4.4 2.2,5.5 4.4,5.5 "></polygon>
                    <path d="M50,4.9C50,2.2,47.8,0,45.1,0s-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9C47.8,9.8,50,7.6,50,4.9z M45.1,8.7c-2.1,0-3.8-1.7-3.8-3.8
                    s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8C48.9,7,47.2,8.7,45.1,8.7z"></path>
                    <polygon points="45.6,4.4 45.6,2.2 44.6,2.2 44.6,4.4 42.4,4.4 42.4,5.5 44.6,5.5 44.6,7.6 45.6,7.6 45.6,5.5 47.8,5.5 47.8,4.4 "></polygon>
                    <rect x="4.4" y="10.9" width="1.1" height="1.1"></rect>
                    <rect x="4.4" y="13.1" width="1.1" height="1.1"></rect>
                    <rect x="4.4" y="15.2" width="1.1" height="1.1"></rect>
                    <rect x="23.4" y="14.7" width="1.1" height="1.1"></rect>
                    <rect x="23.4" y="16.9" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="10.9" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="13.1" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="15.2" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="17.4" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="19.6" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="21.8" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="23.9" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="26.1" width="1.1" height="1.1"></rect>
                    <rect x="44.6" y="28.3" width="1.1" height="1.1"></rect>
                    <rect x="34.8" y="26.1" width="1.1" height="1.1"></rect>
                    <rect x="34.8" y="28.3" width="1.1" height="1.1"></rect>
                    <rect x="19.6" y="24.5" width="1.1" height="1.1"></rect>
                  </svg>`,

					title: 'Manage Nutrition',
					text: 'Create goal-based nutrition plans to help users achieve their fitness goals. Admin can assort and arrange nutrition plans for paid and free members.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M11.8,17.8h17c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-17c-0.4,0-0.7,0.3-0.7,0.7C11,17.5,11.4,17.8,11.8,17.8z"></path>
                    <path d="M11.8,22.6h17c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-17c-0.4,0-0.7,0.3-0.7,0.7C11,22.3,11.4,22.6,11.8,22.6z"></path>
                    <path d="M29.5,26.7c0-0.4-0.3-0.7-0.7-0.7h-17c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h17C29.2,27.4,29.5,27.1,29.5,26.7z"></path>
                    <path d="M30.7,38.2v1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-1c0-0.4-0.3-0.7-0.7-0.7C31.1,37.5,30.7,37.8,30.7,38.2z"></path>
                    <path d="M41.3,38.2v1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-1c0-0.4-0.3-0.7-0.7-0.7C41.7,37.5,41.3,37.8,41.3,38.2z"></path>
                    <path d="M37.5,38.6c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.3-0.3-0.7-0.3-1-0.1c-0.3,0.3-0.3,0.7-0.1,1
                    c0.5,0.5,1.1,0.8,1.9,0.8s1.4-0.3,1.9-0.8c0.3-0.3,0.2-0.8-0.1-1C38.3,38.3,37.8,38.3,37.5,38.6z"></path>
                    <path d="M44.7,45.1c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0c2.4-2.2,2.9-4.8,2.9-6.6c0-2.3-0.7-4.6-2.1-6.4
                    c-1.1-1.4-2.4-2.4-3.8-2.8c0.9-2,0.7-4.2-0.4-6.1c-0.1-0.2-0.4-0.4-0.6-0.4c-1.3,0-2.6,0.4-3.7,1.2V10.3c0-2.5-2.1-4.5-4.6-4.5h-4
                    c-0.3-1.3-1.5-2.2-2.9-2.2h-2.7c-0.1-1.9-1.6-3.4-3.5-3.5c-2-0.1-3.6,1.5-3.7,3.5H14c-1.4,0-2.6,1-2.9,2.2h-4c-2.5,0-4.6,2-4.6,4.5
                    v7.3c0,0.4,0.3,0.7,0.7,0.7S4,18,4,17.6v-7.3c0-1.7,1.4-3.1,3.1-3.1H11v1.5H9.1c-1.6,0-2.9,1.3-2.9,2.9v29.8c0,1.6,1.3,2.9,2.9,2.9
                    h17.2c0,0.1,0.1,0.1,0.1,0.2c1,1.5,2.5,2.7,4.2,3.5c0,0,0,0,0,0H7.1C5.4,48,4,46.6,4,44.9V20.5c0-0.4-0.3-0.7-0.7-0.7
                    s-0.7,0.3-0.7,0.7v24.4c0,2.5,2.1,4.5,4.6,4.5h26.3c0.5,0,1-0.1,1.5-0.2c0.6,0.1,1.3,0.1,1.9,0.1c1.6,0,3.1-0.2,4.6-0.7
                    c0.6-0.2,1.1-0.4,1.7-0.7c0.4-0.2,0.5-0.6,0.3-1c-0.2-0.4-0.6-0.5-1-0.3c-0.5,0.2-1,0.4-1.5,0.6c-2.4,0.8-4.9,0.9-7.3,0.3
                    c-2.6-0.6-5.3-2.2-6.5-4.7c0,0,0,0,0,0c-1.2-2.5-0.9-5.6,0.5-7.9c1.2-2.1,3.1-3.4,5.1-3.4c1.1,0,2.1,0.5,3,1c0,0,0,0,0,0
                    c0,0,0,0,0,0c0.7,0.3,1.5,0.3,2.2,0c1.2-0.6,2.3-1.1,3.6-0.9c1.6,0.2,2.9,1.2,3.9,2.5c1.2,1.6,1.8,3.6,1.8,5.5
                    C47.1,41.1,46.7,43.3,44.7,45.1z M40.8,30.1c-0.5,0-0.9,0.1-1.4,0.2l2.2-4c0.4,1.2,0.2,2.6-0.4,3.8C41.2,30.1,40.9,30.1,40.8,30.1z
                    M40.4,25.6l-3.1,5.6c-0.1,0.1-0.3,0.1-0.4,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.2-1.4,0.2-2.9,1.1-3.9
                    C38.5,26.4,39.4,25.9,40.4,25.6z M36.5,10.3v16.2c0,0,0,0,0,0c-0.9,1.1-1.3,2.5-1.4,3.9c0,0,0,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.1
                    c-0.2-0.1-0.4-0.1-0.6-0.2V11.6c0-1.6-1.3-2.9-2.9-2.9h-1.9V7.2h3.9C35.1,7.2,36.5,8.6,36.5,10.3z M14,4.9h3.4
                    c0.4,0,0.7-0.3,0.7-0.7V3.5c0-1.2,1-2.1,2.2-2.1c1.1,0,2,1,2,2.1v0.6c0,0.4,0.3,0.7,0.7,0.7h3.4c0.8,0,1.5,0.7,1.5,1.5v3
                    c0,0.3-0.3,0.6-0.6,0.6c0,0-14.2,0-14.2,0c-0.3,0-0.6-0.3-0.6-0.6v-3C12.5,5.6,13.2,4.9,14,4.9z M7.6,41.4V11.6
                    c0-0.8,0.7-1.5,1.5-1.5h2.1c0,0,0,0,0,0c0.3,0.8,1.1,1.4,2,1.4h14.2c0.9,0,1.7-0.6,2-1.4c0,0,0,0,0,0h2.1c0.8,0,1.5,0.7,1.5,1.5V30
                    c-1.3,0-2.6,0.4-3.7,1.1c-2.6,1.7-4.1,4.8-4.3,7.8c-0.1,1.4,0.1,2.7,0.6,4H9.1C8.3,42.9,7.6,42.2,7.6,41.4z"></path>
                  </svg>`,
					title: '>Diet list',
					text: 'Create and upload a detailed diet list for dishes to be made with specific types of ingredients, recipes, time of the day, etc.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M42,10.5C42,10.5,42,10.5,42,10.5c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0L31.7,0.5
                      c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0H12.6C9,0.3,8,3.3,8,4.9v40.2
                      c0,1.6,1,4.6,4.6,4.6h24.7c3.7,0,4.6-3,4.6-4.6V10.7C42,10.6,42,10.6,42,10.5z M32,2.8l7.4,7.1h-3.9c-3.3,0-3.5-2.8-3.5-3.1V2.8z
                      M37.4,48.2H12.6c-3,0-3.1-2.8-3.1-3.1V4.9c0-0.1,0-3.1,3.1-3.1h17.8v5c0,1.6,1.1,4.6,5,4.6h5v33.6C40.5,45.2,40.4,48.2,37.4,48.2z
                      "></path>
                      <path d="M24.6,19.6h-8.5c-0.4,0-0.8,0.3-0.8,0.8v20.9c0,0.4,0.3,0.8,0.8,0.8h8.5c0.4,0,0.8-0.3,0.8-0.8V20.4
                      C25.4,19.9,25,19.6,24.6,19.6z M16.9,40.5V21.1h7v1.9h-4.6v1.5h4.6v0.8h-1.9v1.5h1.9v0.8h-4.6v1.5h4.6V30h-0.8v1.5h0.8v0.8h-3.5
                      v1.5h3.5v0.8h-0.8v1.5h0.8V37h-3.5v1.5h3.5v1.9H16.9z"></path>
                      <rect x="15.3" y="14.2" width="9.3" height="1.5"></rect>
                      <rect x="27.7" y="21.1" width="1.9" height="1.5"></rect>
                      <rect x="30.8" y="21.1" width="3.9" height="1.5"></rect>
                      <rect x="27.7" y="25.4" width="1.9" height="1.5"></rect>
                      <rect x="30.8" y="25.4" width="3.9" height="1.5"></rect>
                      <rect x="27.7" y="29.3" width="1.9" height="1.5"></rect>
                      <rect x="30.8" y="29.3" width="3.9" height="1.5"></rect>
                      <rect x="27.7" y="33.5" width="1.9" height="1.5"></rect>
                      <rect x="30.8" y="33.5" width="3.9" height="1.5"></rect>
                      <rect x="27.7" y="37.4" width="1.9" height="1.5"></rect>
                      <rect x="30.8" y="37.4" width="3.9" height="1.5"></rect>
                    </g>
                  </svg>`,

					title: 'Diet template',
					text: 'Make it easy for users to follow a diet chart with diet templates that list down food intake, calorie count all in one place.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M47.7,31.6h-0.5c-0.1,0-0.2,0-0.3,0v-2.2c0-2.2-1.8-4-4-4h-1.2c-2.2,0-4,1.8-4,4V34h-0.9l-1.1-5.6c-0.4-2.3-3-2.6-4.8-2.7
                        v-1.4h1c0.5,0,1-0.4,1-1v-5.2c0-0.5-0.4-1-1-1H16.9c-0.5,0-1,0.4-1,1v5.2c0,0.5,0.4,1,1,1h1v1.4h-0.8c-1.1,0-1.8,0.5-2.2,0.8
                        c-0.6,0.7-0.9,1.7-0.9,3V34h-1.8v-4.5c0-2.2-1.8-4-4-4H7.1c-2.2,0-4,1.8-4,4v2.2c-0.1,0-0.2,0-0.3,0H2.3c-1.2,0-2.3,1-2.3,2.3v7.7
                        c0,1.2,1,2.3,2.3,2.3h0.5c0.1,0,0.2,0,0.3,0V46c0,2.2,1.8,4,4,4h1.2c2.2,0,4-1.8,4-4v-4.2h1.8v1.3c0,1.5,1.3,2.7,2.8,2.7h0.7
                        c0.6,0,1.2-0.2,1.7-0.5c0.5,0.5,1.2,0.8,2,0.8h0.6c0.5,0,1-0.2,1.5-0.4c0.5,0.6,1.3,0.9,2.1,0.9h0.6c0.9,0,1.7-0.4,2.2-1
                        c0.4,0.2,0.9,0.4,1.4,0.4h0.6c1.5,0,2.8-1.2,2.8-2.7l0-1.6h4.6V46c0,2.2,1.8,4,4,4h1.2c2.2,0,4-1.8,4-4v-2.2c0.1,0,0.2,0,0.3,0
                        h0.5c1.2,0,2.3-1,2.3-2.3v-7.7C50,32.6,49,31.6,47.7,31.6z M3.1,41.6c0,0.2-0.1,0.3-0.3,0.3H2.3c-0.2,0-0.3-0.1-0.3-0.3v-7.7
                        c0-0.2,0.1-0.3,0.3-0.3h0.5c0.2,0,0.3,0.1,0.3,0.3V41.6z M10.4,46L10.4,46c0,1-0.7,1.9-1.7,2v-4.8c0-0.5-0.4-1-1-1
                        c-0.5,0-1,0.4-1,1V48c-1-0.2-1.7-1-1.7-2V29.4c0-1.1,0.9-2.1,2.1-2.1h1.2c1.1,0,2.1,0.9,2.1,2.1V46z M14.1,39.9h-1.8v-3.9h1.8
                        V39.9z M17.9,22.3v-3.2h13v3.2H17.9z M32.2,34c-0.5,0-1,0.4-1,1l-0.1,5.9v0v0l0,2.5c0,0.4-0.4,0.7-0.8,0.7h-0.6
                        c-0.4,0-0.8-0.3-0.8-0.7c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1V44c0,0.4-0.4,0.7-0.8,0.7h-0.6c-0.4,0-0.8-0.3-0.8-0.7v-0.5
                        c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1c0,0.4-0.4,0.7-0.8,0.7h-0.6c-0.4,0-0.8-0.3-0.8-0.7v-0.4c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1
                        c0,0.4-0.4,0.8-0.9,0.8H17c-0.5,0-0.9-0.4-0.9-0.8V29.4c0,0,0,0,0,0c0-0.7,0.1-1.3,0.3-1.5c0.1-0.1,0.2-0.2,0.8-0.2h1.7
                        c0.5,0,1-0.4,1-1v-2.3h9v2.3c0,0.5,0.4,1,1,1H30c1.2,0,3.6,0,3.8,1.1l1,5.3H32.2L32.2,34z M37.7,39.9h-4.5l0-3.9h4.5V39.9z M45,46
                        L45,46c0,1-0.7,1.9-1.7,2v-4.8c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1V48c-1-0.2-1.7-1-1.7-2V29.4c0-1.1,0.9-2.1,2.1-2.1h1.2
                        c1.1,0,2.1,0.9,2.1,2.1V46L45,46z M48,41.6c0,0.2-0.1,0.3-0.3,0.3h-0.5c-0.2,0-0.3-0.1-0.3-0.3c0,0,0-0.1,0-0.1V34
                        c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.3-0.3h0.5c0.2,0,0.3,0.1,0.3,0.3V41.6z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M41.6,4.2L41.6,4.2c-0.5,0-1,0.4-1,1l0,3l-1.7,1.7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
                        l1.9-2c0.2-0.2,0.3-0.4,0.3-0.7l0-3.4C42.6,4.7,42.1,4.2,41.6,4.2z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M41.3,0c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5C49.7,3.8,45.9,0,41.3,0z M41.3,14.9
                        c-3.6,0-6.5-2.9-6.5-6.5c0-3.6,2.9-6.5,6.5-6.5c3.6,0,6.5,2.9,6.5,6.5C47.8,12,44.8,14.9,41.3,14.9z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M7.7,38.8c-0.5,0-1,0.4-1,1v0c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v0C8.7,39.3,8.2,38.8,7.7,38.8z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path d="M42.3,38.8c-0.5,0-1,0.4-1,1v0c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v0C43.3,39.3,42.8,38.8,42.3,38.8z"></path>
                      </g>
                    </g>
                  </svg>`,

					title: 'Workout creation',
					text: 'Create diverse kinds of workouts like weight training, strength training, athletics, endurance, yoga, etc.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path class="st0" d="M35.3,17.7"></path>
                          <path class="st0" d="M14.7,17.7"></path>
                          <path class="st0" d="M35.3,18"></path>
                          <path class="st0" d="M14.7,18"></path>
                          <path class="st0" d="M35.3,18.2"></path>
                          <path class="st0" d="M14.7,18.2"></path>
                          <g>
                            <path d="M39.7,26.5h-1.2c-2.4,0-4.3-1.8-4.3-4v-9.6c0-2.2,1.9-4,4.3-4h1.2c2.4,0,4.3,1.8,4.3,4v9.6C44,24.7,42.1,26.5,39.7,26.5
                            z M38.5,11c-1.2,0-2.2,0.9-2.2,1.9v9.6c0,1.1,1,1.9,2.2,1.9h1.2c1.2,0,2.2-0.9,2.2-1.9v-9.6c0-1.1-1-1.9-2.2-1.9
                            C39.7,11,38.5,11,38.5,11z"></path>
                            <path d="M45.6,23.6h-0.7c-1.6,0-2.9-1.3-2.9-2.9v-6c0-1.6,1.3-2.9,2.9-2.9h0.7c1.6,0,2.9,1.3,2.9,2.9v6
                            C48.5,22.3,47.2,23.6,45.6,23.6z M44.9,13.9c-0.4,0-0.8,0.4-0.8,0.8v6c0,0.4,0.4,0.8,0.8,0.8h0.7c0.4,0,0.8-0.4,0.8-0.8v-6
                            c0-0.4-0.4-0.8-0.8-0.8H44.9z"></path>
                            <path d="M49,18.7h-1.5c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1H49c0.6,0,1,0.5,1,1C50,18.3,49.5,18.7,49,18.7z"></path>
                          </g>
                        </g>
                        <g>
                          <path d="M11.5,26.5h-1.2c-2.4,0-4.3-1.8-4.3-4v-9.6c0-2.2,1.9-4,4.3-4h1.2c2.4,0,4.3,1.8,4.3,4v9.6
                          C15.8,24.7,13.9,26.5,11.5,26.5z M10.3,11c-1.2,0-2.2,0.9-2.2,1.9v9.6c0,1.1,1,1.9,2.2,1.9h1.2c1.2,0,2.2-0.9,2.2-1.9v-9.6
                          c0-1.1-1-1.9-2.2-1.9C11.5,11,10.3,11,10.3,11z"></path>
                          <path d="M5.1,23.6H4.4c-1.6,0-2.9-1.3-2.9-2.9v-6c0-1.6,1.3-2.9,2.9-2.9h0.7c1.6,0,2.9,1.3,2.9,2.9v6C8,22.3,6.7,23.6,5.1,23.6z
                          M4.4,13.9c-0.4,0-0.8,0.4-0.8,0.8v6c0,0.4,0.4,0.8,0.8,0.8h0.7c0.4,0,0.8-0.4,0.8-0.8v-6c0-0.4-0.4-0.8-0.8-0.8H4.4z"></path>
                          <path d="M2.6,18.7H1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h1.5c0.6,0,1,0.5,1,1C3.6,18.3,3.2,18.7,2.6,18.7z"></path>
                        </g>
                      </g>
                      <g>
                        <path d="M31.7,43.1C31.7,43.1,31.7,43.1,31.7,43.1H18.7c-0.3,0-0.6-0.1-0.8-0.4c-0.2-0.2-0.3-0.5-0.2-0.8c0-0.1,1.9-11.2,2.5-15
                        c-0.9-0.2-1.6-0.7-2.1-1.3c-2.1-2.5-1.3-7.5-1.2-8.1c0.1-0.5,0.5-0.9,1-0.9h1.5V14c0-0.6,0.5-1,1-1h11.3c0.6,0,1,0.5,1,1V23
                        c0,0.2,0,0.4-0.1,0.5l-2.4,4.3l2.5,13.7c0.1,0.1,0.1,0.3,0.1,0.5C32.8,42.7,32.3,43.1,31.7,43.1z M19.9,41h10.5l-2.4-13.1
                        c0-0.2,0-0.5,0.1-0.7l2.4-4.4V15h-9.2v2.7c0,0.6-0.5,1-1,1h-1.6c-0.2,1.6-0.2,4.2,0.9,5.5c0.4,0.5,0.9,0.7,1.6,0.7
                        c0.3,0,0.6,0.1,0.8,0.4c0.2,0.2,0.3,0.5,0.3,0.8C22.2,27.6,20.5,37.5,19.9,41z"></path>
                        <path d="M23.3,20c-0.6,0-1-0.5-1-1v-5c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v5C24.3,19.6,23.8,20,23.3,20z"></path>
                        <path d="M26.1,20c-0.6,0-1-0.5-1-1v-5c0-0.6,0.5-1,1-1s1,0.5,1,1v5C27.1,19.6,26.7,20,26.1,20z"></path>
                        <path d="M28.9,20c-0.6,0-1-0.5-1-1v-5c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v5C29.9,19.6,29.5,20,28.9,20z"></path>
                      </g>
                      <path d="M35.3,18.7h-2.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h2.6c0.6,0,1,0.5,1,1C36.3,18.3,35.8,18.7,35.3,18.7z"></path>
                      <path d="M20.2,18.7h-5.5c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h5.5c0.6,0,1,0.5,1,1C21.2,18.3,20.8,18.7,20.2,18.7z"></path>
                    </g>
                  </svg>`,

					title: 'Exercise management',
					text: 'Manage exercises to be carried out and personal goals to be achieved from a dashboard.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M35,16.8V0.8C35,0.4,34.6,0,34.2,0H0.8C0.4,0,0,0.4,0,0.8v41.7c0,0.5,0.4,0.8,0.8,0.8H20c3,4,7.9,6.7,13.3,6.7
                    C42.5,50,50,42.5,50,33.3C50,24.7,43.4,17.6,35,16.8z M1.7,41.7v-40h31.7v15c-0.6,0-1.1,0-1.7,0.1v-0.9c0-0.5-0.4-0.8-0.8-0.8h-6.7
                    c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.3,0.1,0.5,0.3,0.6c-1.1,0.8-2.1,1.7-2.9,2.7H20v0.9c-0.4,0.5-0.8,1.1-1.1,1.6h-5.6v1.7h4.7
                    c-0.6,1.3-1,2.7-1.2,4.2h-3.5v1.7h3.4c0,0.3,0,0.6,0,0.8c0,1.1,0.1,2.3,0.3,3.3h-3.7v1.7h4.1c0.4,1.2,0.9,2.3,1.5,3.3H1.7z
                    M26.1,18.3H25v-1.7h5V17C28.6,17.3,27.3,17.7,26.1,18.3z M33.3,48.3c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15
                    S41.6,48.3,33.3,48.3z"></path>
                    <path d="M33.3,24.2c0-0.5-0.4-0.8-0.8-0.8h-8.3c-0.5,0-0.8,0.4-0.8,0.8c0,1.2-0.3,1.7-0.7,2.3c-0.4,0.7-1,1.6-1,3.2
                    c0,1.6,0.5,2.5,1,3.2c0.4,0.7,0.7,1.2,0.7,2.3S23,37,22.6,37.6c-0.4,0.7-1,1.6-1,3.2c0,0.5,0.4,0.8,0.8,0.8h8.3
                    c0.5,0,0.8-0.4,0.8-0.8c0-1.2,0.3-1.7,0.7-2.3c0.4-0.7,1-1.6,1-3.2c0-1.6-0.5-2.5-1-3.2c-0.4-0.7-0.7-1.2-0.7-2.3
                    c0-1.2,0.3-1.7,0.7-2.3C32.8,26.6,33.3,25.8,33.3,24.2z M24,32.1c-0.4-0.7-0.7-1.2-0.7-2.3c0-1.2,0.3-1.7,0.7-2.3
                    c0.4-0.6,0.8-1.3,0.9-2.4h2.5c-0.1,0.6-0.4,1-0.6,1.5c-0.4,0.7-1,1.6-1,3.2c0,1.6,0.5,2.5,1,3.2c0.4,0.7,0.7,1.2,0.7,2.3
                    s-0.3,1.7-0.7,2.3c-0.4,0.6-0.8,1.3-0.9,2.4h-2.5c0.1-0.6,0.4-1,0.6-1.5c0.4-0.7,1-1.6,1-3.2C25,33.7,24.5,32.8,24,32.1z M31,37.6
                    c-0.4,0.6-0.8,1.3-0.9,2.4h-2.5c0.1-0.6,0.4-1,0.6-1.5c0.4-0.7,1-1.6,1-3.2c0-1.6-0.5-2.5-1-3.2c-0.4-0.7-0.7-1.2-0.7-2.3
                    c0-1.2,0.3-1.7,0.7-2.3c0.4-0.6,0.8-1.3,0.9-2.4h2.5c-0.1,0.6-0.4,1-0.6,1.5c-0.4,0.7-1,1.6-1,3.2c0,1.6,0.5,2.5,1,3.2
                    c0.4,0.7,0.7,1.2,0.7,2.3S31.4,37,31,37.6z"></path>
                    <path d="M35,34.3c0,3.9,2.4,6.5,5.8,6.5s5.8-2.7,5.8-6.5c0-4-2.5-8.5-5.8-8.5C37.5,25.8,35,30.3,35,34.3z M45,34.3
                    c0,3-1.6,4.9-4.2,4.9s-4.2-1.9-4.2-4.9c0-3.4,2.1-6.8,4.2-6.8C42.9,27.5,45,30.9,45,34.3z"></path>
                    <path d="M37.5,34.2c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3C39,30.8,37.5,32.3,37.5,34.2z M42.5,34.2
                    c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7C41.8,32.5,42.5,33.2,42.5,34.2z"></path>
                    <path d="M3.8,12.5h26.7c0.5,0,0.8-0.4,0.8-0.8V5c0-0.5-0.4-0.8-0.8-0.8H3.8C3.4,4.2,3,4.5,3,5v6.7C3,12.1,3.4,12.5,3.8,12.5z
                    M4.7,5.8h25v5h-25V5.8z"></path>
                    <path d="M10.8,15H4.2c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h6.7c0.5,0,0.8-0.4,0.8-0.8v-3.3C11.7,15.4,11.3,15,10.8,15z
                    M10,18.3H5v-1.7h5V18.3z"></path>
                    <path d="M20.8,15h-6.7c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h6.7c0.5,0,0.8-0.4,0.8-0.8v-3.3C21.7,15.4,21.3,15,20.8,15
                    z M20,18.3h-5v-1.7h5V18.3z"></path>
                    <path d="M10,25H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V25z"></path>
                    <path d="M10,30.8H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V30.8z"></path>
                    <path d="M10,36.7H3.3v1.7h7.5c0.5,0,0.8-0.4,0.8-0.8v-3.3H10V36.7z"></path>
                    <rect x="26.7" y="7.5" width="1.7" height="1.7"></rect>
                    <rect x="23.3" y="7.5" width="1.7" height="1.7"></rect>
                    <rect x="20" y="7.5" width="1.7" height="1.7"></rect>
                    <rect x="16.7" y="7.5" width="1.7" height="1.7"></rect>
                    <rect x="13.3" y="7.5" width="1.7" height="1.7"></rect>
                  </svg>`,

					title: 'Calorie counter',
					text: 'Measure calorie intake on a regular basis by giving input of food consumed.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M20.7,10.7c2.8,0,5.1-2.2,5.1-5c0-2.8-2.3-5-5.1-5c-2.8,0-5.1,2.2-5.1,5C15.7,8.5,17.9,10.7,20.7,10.7z M20.7,2.2
                        c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5C17.2,3.8,18.8,2.2,20.7,2.2z M49.2,11.1
                        c-0.1,0-0.3,0-0.4,0.1l-10.9,6.4c-0.2,0.1-0.4,0.4-0.4,0.7v2.1c0,0.4,0.3,0.8,0.8,0.8h6.6v3.3l-8,19.1l-1.7,0.1
                        c0.1-0.1,0.1-0.2,0.2-0.3c0.5-0.8,0.7-1.7,0.3-2.6v0l-4-9.1c-0.2-0.3-0.4-0.6-0.8-0.8l-3.5-2.1c-0.2-0.1-0.3-0.2-0.3-0.4v-6.5
                        l3.4,0.8c0.3,0.1,0.7-0.1,0.8-0.3l4.1-6.3c0.6-0.9,0.4-2-0.3-2.7c-0.9-0.9-2.3-0.8-3.2,0.1c-0.1,0.1-0.2,0.2-0.2,0.3l-2.7,3.9
                        L26,16.7c-1.6-2.7-4.6-4.4-7.8-4.4c-0.6,0-1.3,0.2-1.8,0.5l0,0l-8.1,5.1c-0.3,0.2-0.4,0.5-0.3,0.8l1.8,7.9c0.2,1,1.1,1.8,2.2,1.8
                        c1.3,0,2.3-1,2.3-2.3c0-0.1-1-5.4-1-5.4l1.7-1.1v8.6c0,1.1,0.6,2.2,1.6,2.8l-1.2,3.5c0,0.1-0.1,0.2-0.2,0.2l-7,2.8
                        c-1.4,0.6-2.1,2.1-1.5,3.5c0.2,0.6,0.7,1.1,1.2,1.4c0.7,0.4,1.6,0.4,2.3,0.2l8.7-3.4c0.5-0.2,0.9-0.6,1.1-1.1l2.1-4.9l4,1.6
                        c0,0,0.1,0,0.1,0.1l4.4,8c0.2,0.3,0.4,0.6,0.7,0.9L2.3,45.3C1,45.3,0,46.4,0,47.7C0,49,1.1,50,2.4,50h30.8c0.4,0,0.8-0.3,0.8-0.8
                        s-0.3-0.8-0.8-0.8H2.4c-0.5,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8l43.7-2.2v3.8h-9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h9
                        c0.9,0,1.6-0.7,1.6-1.5v-3.8c0-0.9-0.7-1.5-1.6-1.5c0,0-0.1,0-0.1,0l-3.8,0.2l7.7-18.4c0-0.1,0.1-0.2,0.1-0.3V11.9
                        C50,11.4,49.6,11.1,49.2,11.1z M29.3,19c0.3,0.1,0.6,0,0.8-0.3l3-4.3c0.2-0.3,0.7-0.4,1-0.1c0,0,0,0,0.1,0.1
                        c0.2,0.2,0.2,0.5,0.1,0.7L30.5,21l-3.3-0.7c-0.1-0.6-0.2-1.3-0.4-1.9L29.3,19z M18.5,37.7c0,0.1-0.1,0.2-0.2,0.2l-8.7,3.4
                        c-0.6,0.2-1.3-0.1-1.5-0.7c0-0.1,0-0.1-0.1-0.2c-0.1-0.5,0.2-1.1,0.7-1.3l6.9-2.8c0.5-0.2,0.9-0.6,1.1-1.2l1.2-3.4
                        c0.6,0.2,1.6,0.6,2.6,1L18.5,37.7z M34.1,42.5c-0.2,0.3-0.6,0.6-1.1,0.6c-0.5,0-1-0.3-1.2-0.8l0,0l-4.5-8.1
                        c-0.2-0.3-0.5-0.5-0.8-0.7c-0.8-0.3-8.2-3.4-9-3.6c-0.7-0.2-1.2-0.9-1.2-1.6v-9.6l1.2-0.7c0.4-0.2,0.4-0.7,0.2-1.1
                        c-0.2-0.3-0.7-0.4-1-0.2l-5,3c-0.3,0.2-0.4,0.5-0.4,0.8l1.1,5.5c0,0.1,0,0.1,0,0.2c0,0.4-0.3,0.7-0.7,0.7c-0.3,0-0.6-0.2-0.7-0.6
                        l-1.7-7.4l7.6-4.8c0,0,0.1,0,0.1-0.1c0.3-0.2,0.6-0.3,0.9-0.3c4.2,0,7.6,3.4,7.6,7.6v7.1c0,0.7,0.4,1.4,1,1.7c0,0,3.6,2.2,3.6,2.2
                        l3.9,9C34.4,41.7,34.4,42.2,34.1,42.5z M48.4,24.4l-8,19l-1.9,0.1l7.8-18.6c0-0.1,0.1-0.2,0.1-0.3v-3.5h2V24.4z M48.4,19.6h-9.4
                        v-0.9l9.4-5.4V19.6z M31.9,10.3h9.8c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2h-9.8c-1.1,0-2,0.9-2,2v6.3C29.9,9.4,30.8,10.3,31.9,10.3z
                        M31.4,2.7c0-0.7,0.5-1.2,1.2-1.2H41c0.7,0,1.2,0.5,1.2,1.2v5c0,0.7-0.5,1.2-1.2,1.2h-8.4c-0.7,0-1.2-0.5-1.2-1.2V2.7z M34.8,2.5
                        v5.4l4.9-2.7L34.8,2.5z M36.1,4.1l1.9,1l-1.9,1V4.1z"></path>
                      </g>
                    </g>
                  </svg>`,

					title: 'Workout videos',
					text: 'Learn the right way of working out with videos from expert fitness trainers.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M10.4,48.1c-0.1,0-0.3,0-0.4,0c-0.4-0.2-0.7-0.6-0.7-1.1v-8.4H4.4C2,38.6,0,36.7,0,34.3c0,0,0,0,0,0V7.4C0,5,2,3.1,4.4,3.1
                    l0,0h30.8c0.7,0.1,1.1,0.7,1,1.3c-0.1,0.5-0.5,1-1,1H4.4c-1,0-1.9,0.8-1.9,1.9v26.9c0,1,0.8,1.9,1.9,1.9h6c0.7,0,1.2,0.5,1.2,1.2
                    v6.4l7-7.1c0.2-0.2,0.5-0.4,0.9-0.4h26.1c1,0,1.9-0.8,1.9-1.9v-17c-0.1-0.7,0.4-1.3,1-1.3c0.7-0.1,1.3,0.4,1.3,1c0,0.1,0,0.2,0,0.3
                    v16.8c0,2.4-1.9,4.3-4.3,4.3H19.9l-8.6,9C11,47.9,10.7,48.1,10.4,48.1z"></path>
                    <path d="M28.8,12.6H6.4c-0.7-0.1-1.1-0.7-1-1.3c0.1-0.5,0.5-1,1-1h22.3c0.7-0.1,1.3,0.4,1.3,1c0.1,0.7-0.4,1.3-1,1.3
                    C29,12.6,28.9,12.6,28.8,12.6z"></path>
                    <path d="M21.8,19.8H6.4c-0.7-0.1-1.1-0.7-1-1.3c0.1-0.5,0.5-1,1-1h15.3c0.7-0.1,1.3,0.4,1.3,1c0.1,0.7-0.4,1.3-1,1.3
                    C22,19.8,21.9,19.8,21.8,19.8z"></path>
                    <path d="M21.8,27H6.4c-0.7-0.1-1.1-0.7-1-1.3c0.1-0.5,0.5-1,1-1h15.3c0.7-0.1,1.3,0.4,1.3,1c0.1,0.7-0.4,1.3-1,1.3
                    C22,27,21.9,27,21.8,27z"></path>
                    <path d="M26.7,27c-0.6,0-1.2-0.5-1.2-1.2c0-0.2,0-0.3,0.1-0.5l2.9-7.2c0.1-0.2,0.2-0.3,0.3-0.4L43.6,2.9c0.5-0.5,1.2-0.5,1.7,0
                    l4.4,4.4C49.9,7.6,50,7.9,50,8.2c0,0.3-0.1,0.6-0.4,0.8L35.2,23.4c-0.1,0.1-0.2,0.2-0.4,0.3l-7.7,3.2C27,27,26.8,27,26.7,27z
                    M30.7,19.3l-1.9,4.3l4.9-2.1L47.1,8.3l-2.7-2.8L30.7,19.3z"></path>
                  </svg>`,

					title: 'Blog post',
					text: 'A dedicated module to manage blogs published in the app’s name.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M24,19.5c0.2,0.2,0.5,0.2,0.8,0c0,0,0,0,0,0l7.8-8.2c1.9-2,1.9-5.2,0-7.3c-1.8-1.9-4.9-2-6.8-0.2c-0.1,0.1-0.1,0.1-0.2,0.2
                    l-1.2,1.2l-1.2-1.2c-1.8-1.9-4.9-2-6.8-0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-1.9,2-1.9,5.2,0,7.3L24,19.5z M17,4.8
                    c1.4-1.5,3.8-1.6,5.3-0.2c0.1,0.1,0.1,0.1,0.2,0.2L24,6.4c0.2,0.2,0.6,0.2,0.8,0l1.6-1.6c1.4-1.5,3.8-1.6,5.3-0.2
                    c0.1,0.1,0.1,0.1,0.2,0.2c1.5,1.6,1.5,4.2,0,5.8l-7.4,7.8L17,10.6C15.5,9,15.5,6.4,17,4.8z"></path>
                    <path d="M24.4,14.8L24.4,14.8C24.4,14.8,24.4,14.8,24.4,14.8L24.4,14.8C24.4,14.8,24.4,14.8,24.4,14.8L24.4,14.8
                    c3.3-0.1,4.7-1.5,4.8-4.8c0-0.2,0-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.8,0-1.6,0.2-2.3,0.4C26,9,25.5,8.2,24.8,7.5
                    c-0.1-0.1-0.2-0.2-0.4-0.2h0c-0.1,0-0.3,0.1-0.4,0.2c-0.7,0.7-1.2,1.5-1.6,2.4c-0.7-0.3-1.5-0.4-2.3-0.4c-0.3,0-0.5,0.2-0.6,0.5
                    c0,0,0,0,0,0C19.7,13.3,21.1,14.7,24.4,14.8z M28.1,10.6c-0.2,1.8-0.9,2.7-2.5,3c0.6-0.8,0.9-1.7,0.9-2.6
                    C27,10.8,27.6,10.6,28.1,10.6L28.1,10.6z M24.4,8.7c1.4,1.8,1.4,3,0,4.8C23,11.7,23,10.4,24.4,8.7L24.4,8.7z M22.2,11
                    c0,0.9,0.3,1.9,0.9,2.6c-1.6-0.3-2.3-1.2-2.5-3C21.2,10.6,21.8,10.8,22.2,11z"></path>
                    <rect x="20.1" y="20.7" width="8.6" height="1.1"></rect>
                    <rect x="8.3" y="23.9" width="15" height="1.1"></rect>
                    <rect x="24.4" y="23.9" width="16" height="1.1"></rect>
                    <rect x="10.5" y="27.7" width="12.8" height="1.1"></rect>
                    <rect x="12.6" y="31.4" width="10.7" height="1.1"></rect>
                    <rect x="24.4" y="31.5" width="4.3" height="1.1"></rect>
                    <rect x="24.4" y="27.7" width="7.5" height="1.1"></rect>
                    <rect x="29.7" y="40.5" width="1.1" height="1.6"></rect>
                    <rect x="26" y="40.5" width="1.1" height="1.6"></rect>
                    <rect x="33.5" y="40.5" width="1.1" height="1.6"></rect>
                    <path d="M45.3,30.3c0.6-0.4,0.8-1.1,0.5-1.7c-0.1-0.2,0-0.3,0.1-0.4l0.4-0.2l1.2,1.2l0.8-0.8l-1.3-1.3c-0.1-0.1-0.1-0.2-0.1-0.3
                    l-0.3-1.6l-1,0.2l0.3,1.5l-0.4,0.2c-0.7,0.3-1,1.2-0.6,1.9c0.1,0.2,0,0.3-0.1,0.4c0,0,0,0,0,0l-0.3,0.1c-0.3-0.2-0.5-0.3-0.8-0.5
                    V0.9c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0H5.7c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v27.4l-0.5-0.8v-3H3.5v1.4l-1.2-1.7l-0.9,0.6
                    l1.3,1.9L1,26.1l-0.3,1l3,1L4.3,29l-0.5,0.3c-1.6,1-2.1,3-1.1,4.6c0.1,0.1,0.1,0.2,0.2,0.3l2.2,2.8v10.4c0,0.3,0.2,0.5,0.5,0.5
                    c0,0,0,0,0,0h6.7c1,1.1,2.2,1.8,3.4,1.6c0.2,0,0.4-0.1,0.6-0.1c0.3-0.1,0.6-0.1,0.8,0c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.3,0,0.4,0
                    c1.2-0.1,2.3-0.7,3-1.6h3.3c3.1,1.9,7,2.1,10.3,0.5c5.6,1.9,11.6-1.2,13.5-6.8C49.8,37.7,48.6,33.2,45.3,30.3z M39.8,34.6
                    c0.3,0.7,0.5,1.1,0.2,1.7c-0.2,0.5-0.6,0.8-1,0.9c-0.6,0.2-1.2,0.6-1.5,1.1H32c0.3-0.2,0.6-0.3,1-0.4c1.8-0.3,2.9-2,2.6-3.8
                    c0-0.3-0.1-0.5-0.2-0.8c-0.3-0.6-0.3-1.4,0-2c0.4-0.8,1-1,1.7-1.2c0.9-0.2,1.6-0.7,2.1-1.4c1.3,0.1,2.6,0.5,3.8,1.2
                    c-0.3,0.3-0.8,0.5-1.2,0.6c-1.5,0.2-2.5,1.5-2.3,3C39.4,33.9,39.6,34.3,39.8,34.6L39.8,34.6z M23.3,43.4c-0.1-1.6-1.1-3.1-2.6-3.6
                    c0-0.1,0-0.2,0-0.3h18.9c-1.5,5.1-6.8,8-11.9,6.6C26,45.6,24.5,44.6,23.3,43.4z M34.3,33.7c0.3,0.6,0.3,1.4,0,2
                    c-0.4,0.8-1,1-1.7,1.2c-0.9,0.2-1.6,0.7-2.1,1.4h-1.8c0-5.1,3.9-9.2,9-9.6c-0.3,0.2-0.6,0.3-0.9,0.4c-1.8,0.3-2.9,2-2.6,3.8
                    C34.1,33.2,34.2,33.5,34.3,33.7z M42.6,1.5v27.1c-5.4-2.4-11.7,0.1-14.1,5.5c-0.6,1.4-0.9,2.8-0.9,4.3h-7.5c-0.3,0-0.5,0.2-0.5,0.5
                    c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6c-0.8-0.1-1.6,0-2.3,0.3c0-0.3-0.1-0.6-0.2-0.9c0.2,0,0.4-0.1,0.5-0.1c1.3-0.4,2.1-1.4,1.8-2.2
                    c-0.1-0.3-0.3-0.6-0.7-0.8c-0.7-0.3-1.4-0.3-2.1-0.1c-0.2,0.1-0.4,0.2-0.7,0.3c-0.1-0.2-0.2-0.5-0.3-0.7l-1,0.5
                    c0.7,1.2,1.2,2.6,1.4,4c-0.4-0.1-0.7-0.2-1.1-0.2l-4.9-9.8c-0.7-1.5-2.4-2.2-4-1.7V1.5H42.6z M16.5,36.9c0.2-0.1,0.3-0.2,0.5-0.2
                    c0.4-0.2,0.9-0.2,1.3,0c0,0,0.1,0.1,0.1,0.1c0.1,0.2-0.3,0.7-1.1,0.9c-0.2,0.1-0.3,0.1-0.5,0.1C16.8,37.5,16.7,37.2,16.5,36.9z
                    M3.3,31.8c0.1-0.6,0.5-1.2,1.1-1.6l1.6-1c1.1-0.7,2.5-0.3,3.2,0.8c0,0.1,0.1,0.1,0.1,0.2l1.6,3.2l-2.3,1.3l0.6,0.9l2.2-1.3l2.6,5.2
                    c-1.5,0.3-2.8,1.4-3.3,2.9L8.8,40l2-1.2l-0.6-0.9l-2.1,1.2l-3.1-4l2.6-1.7L7,32.6l-2.6,1.8l-0.6-0.7C3.4,33.1,3.2,32.4,3.3,31.8z
                    M6.2,46.9v-8.5l4.3,5.5c0,0.2,0,0.4,0.1,0.6c0.2,0.8,0.5,1.7,1,2.4H6.2z M18.1,48.5c-0.2,0-0.3-0.1-0.5-0.1c-0.5-0.1-1-0.1-1.5,0
                    c-0.2,0-0.3,0.1-0.5,0.1c-1.8,0.3-3.7-2.3-4.1-4.2c-0.4-1.7,0.7-3.3,2.3-3.7c0.1,0,0.2,0,0.3,0c0.8-0.1,1.7,0.1,2.4,0.5
                    c0.2,0.1,0.4,0.1,0.6,0c0.7-0.4,1.6-0.6,2.4-0.5c1.7,0.2,2.9,1.8,2.6,3.5c0,0.1,0,0.2,0,0.3C21.8,46.2,19.9,48.8,18.1,48.5z
                    M22.2,46.9c0.1-0.2,0.2-0.4,0.4-0.6c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2,0.3,0.3,0.5,0.5L22.2,46.9z M30.3,48.5c-2.6,0-5-1-6.8-2.8
                    c-0.2-0.2-0.3-0.3-0.4-0.5c0.1-0.2,0.1-0.3,0.1-0.5c4.4,3.9,11.1,3.5,15.1-0.9c0.3-0.3,0.6-0.7,0.8-1.1
                    C37.5,46.3,34.1,48.5,30.3,48.5z M36.3,47.8c3-2,4.7-5.3,4.7-8.9c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-1.5c0.1-0.1,0.2-0.1,0.3-0.1
                    c0.7-0.2,1.3-0.7,1.6-1.5c0.4-0.8,0.3-1.8-0.2-2.6c-0.3-0.7-0.5-1.1-0.2-1.7c0.3-0.6,0.7-0.7,1.4-0.9c0.7-0.1,1.3-0.5,1.8-1
                    c1.5,1.1,2.7,2.6,3.4,4.4c-0.2,0.1-0.5,0.2-0.7,0.2c-0.7,0.2-1.6,0.4-2.1,1.4c-0.5,1-0.1,1.8,0.2,2.5c0.3,0.6,0.5,1,0.2,1.6
                    c-0.3,0.5-0.7,0.6-1.4,0.8c-0.7,0.2-1.6,0.4-2.1,1.4c-0.3,0.9-0.4,1.9-0.3,2.8c0.1,0.7,0.1,1.4-0.1,2.1
                    C39.5,48.1,37.9,48.1,36.3,47.8z M42.3,47.1c0-0.6,0-1.1,0-1.7c-0.1-0.8-0.1-1.5,0.2-2.3c0.3-0.5,0.7-0.6,1.4-0.8
                    c0.7-0.2,1.6-0.4,2.1-1.4c0.5-1,0.1-1.8-0.2-2.5c-0.3-0.6-0.5-1-0.2-1.6c0.3-0.5,0.7-0.6,1.3-0.8c0.3-0.1,0.6-0.1,0.8-0.2
                    C48.8,40.5,46.6,45.2,42.3,47.1L42.3,47.1z"></path>
                  </svg>`,

					title: 'Diet recipes',
					text: 'Super admin creates entire diet recipes for all the dietitian. Further dietitians this diet recipes according to users plans.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M19.3,25.7c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1C21.4,26.6,20.5,25.7,19.3,25.7z M18.8,27.8
                    c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0C19.3,27.5,19.1,27.7,18.8,27.8L18.8,27.8
                    L18.8,27.8z"></path>
                    <path d="M30.8,25.7c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1c1.1,0,2.1-0.9,2.1-2.1S31.9,25.7,30.8,25.7L30.8,25.7z M30.2,27.8
                    c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C30.8,27.5,30.5,27.7,30.2,27.8C30.2,27.7,30.2,27.7,30.2,27.8
                    L30.2,27.8z"></path>
                    <path d="M27.2,30.2c-0.4,0-0.7,0.3-0.7,0.7c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5c0,0,0,0,0,0c0-0.4-0.4-0.6-0.8-0.6
                    c-0.3,0-0.6,0.3-0.6,0.6c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9c0,0,0,0,0,0C27.9,30.5,27.6,30.2,27.2,30.2z"></path>
                    <path d="M33.1,19.9H17c-3.7,0-6.8,3-6.8,6.8v6.6c0,2.5,2,4.9,4.4,4.9h20.7c2.5,0,4.5-2.4,4.5-4.9v-6.6
                    C39.9,22.9,36.9,19.9,33.1,19.9z M38.6,33.3c0,1.7-1.4,3.5-3.2,3.5c0,0,0,0,0,0H14.7c-1.7,0-3.1-1.8-3.1-3.5v-6.6
                    c0-3,2.4-5.4,5.4-5.4h16.1c3,0,5.4,2.4,5.4,5.4L38.6,33.3z"></path>
                    <path d="M50,15.7c-0.2-1.5-1.5-2.5-3-2.3s-2.5,1.5-2.3,3c0.2,1.7,1.7,2.2,2,2.2l0.3,1.6l-1.2-0.6c-0.3-0.2-0.7,0-0.8,0.2
                    c-0.1,0.1-0.3,1.2-0.3,1.2c-0.5-0.3-1.1-0.4-1.7-0.5c-0.7-3.7-4-6.3-7.7-6.3h-20c-3.7,0-7,2.6-7.7,6.3c-0.7,0.1-1.4,0.3-2.1,0.6
                    L5.1,20c-0.1-0.4-0.4-0.6-0.9-0.4l-1.2,0.6l0.3-1.5c2.2-0.6,2.3-2.7,1.9-3.7c-0.7-1.7-2.9-2.1-4.2-1.1c-1.2,0.9-1.4,2.6-0.5,3.7
                    c0.4,0.5,0.9,0.9,1.6,1l-0.6,2.7c-0.1,0.5,0.5,0.7,0.9,0.5l1.7-0.9L4.3,22c-1.1,1.1-1.8,2.6-1.8,4.1c0,2.8,2,5.2,4.7,5.6v1.5
                    c0.1,4.3,3.6,7.7,7.9,7.7h4.3c0,1.6-0.4,3.1-1.2,4.4c-0.7,1.1,0.4,1.9,1.2,1.7c3.4-0.9,6-4.5,6.9-6.1l8.8,0c4.3,0,7.8-3.4,7.9-7.7
                    v-1.4c2.8-0.4,4.8-2.8,4.8-5.6c0-1.7-0.7-3.4-2.1-4.5l0.1-0.6l1.7,0.9c0.5,0.3,1-0.1,0.9-0.5l-0.6-2.7C49.3,18.4,50.2,17.1,50,15.7
                    L50,15.7z M1.6,17c-0.5-0.6-0.4-1.5,0.3-2c0.6-0.5,1.6-0.4,2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c-0.1,0.8-0.8,1.3-1.6,1.2
                    C1.9,17.4,1.6,17,1.6,17z M7.3,22v8.4C5,29.9,3.5,27.6,4,25.3C4.3,23.6,5.6,22.3,7.3,22C7.3,22,7.3,22,7.3,22L7.3,22z M41.7,33.4
                    c-0.2,3.5-3,6.2-6.5,6.2h-9.5c-0.1,0-0.2,0.1-0.3,0.1l-0.9,1.5c-0.4,0.7-4,4.6-4.7,4.2c0.7-1.3,1-2.6,1.3-4c0.1-0.4,0.1-1.5,0.1-1.6
                    c0-0.1-0.1-0.2-0.2-0.2h-5.9c-3.5-0.1-6.3-2.9-6.4-6.4V21.9c0.2-3.5,3-6.2,6.5-6.3h20c3.5,0.1,6.3,3,6.5,6.5V33.4z M46.5,26.2
                    c0,2.1-1.4,3.9-3.5,4.2V22C46.4,22.3,46.5,26.2,46.5,26.2z M47.5,17.5c-0.4,0-0.7-0.1-1-0.3c-0.6-0.5-0.8-1.4-0.3-2
                    c0.2-0.3,0.6-0.5,0.9-0.6h0.2c0.8,0,1.4,0.7,1.4,1.4C48.8,17.1,47.9,17.4,47.5,17.5z"></path>
                    <path d="M42.1,5.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V3.4c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v1.4
                    C41.5,5.2,41.8,5.5,42.1,5.5z"></path>
                    <path d="M42.1,9.9c0.4,0,0.7-0.3,0.7-0.7v0V8c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v1.3
                    C41.5,9.6,41.8,9.9,42.1,9.9z"></path>
                    <path d="M43.7,7h1.4c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7S43.4,7,43.7,7L43.7,7z"></path>
                    <path d="M39.2,7h1.4c0.4,0,0.7-0.3,0.7-0.7S41,5.7,40.6,5.7l0,0h-1.4c-0.4,0-0.7,0.3-0.7,0.7S38.9,7,39.2,7z"></path>
                    <path d="M34.5,8.1c0.3,0,0.6-0.3,0.6-0.6V6.7c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7C33.8,7.8,34.1,8.1,34.5,8.1
                    C34.5,8.1,34.5,8.1,34.5,8.1z"></path>
                    <path d="M34.5,11.7c0.4,0,0.7-0.3,0.7-0.7v0v-0.6c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v0.6
                    C33.8,11.4,34.1,11.7,34.5,11.7z"></path>
                    <path d="M35.9,9.6h0.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.7c-0.4,0-0.7,0.3-0.7,0.7C35.2,9.3,35.5,9.6,35.9,9.6
                    L35.9,9.6z"></path>
                    <path d="M32.4,9.6H33c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.6c-0.4,0-0.7,0.3-0.7,0.7C31.7,9.3,32,9.6,32.4,9.6z"></path>
                  </svg>`,

					title: 'Manage bots',
					text: 'The app can be integrated with bots that can engage in live chat with users.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M46.3,1.9H3.7C1.6,1.9,0,3.5,0,5.6v32.5c0,2.1,1.6,3.8,3.8,3.8H12l-2,2c-0.8,0.8-0.9,1.8-0.5,2.8c0.4,1,1.3,1.5,2.3,1.5
                    h26.5c1,0,1.9-0.6,2.3-1.5c0.4-0.9,0.1-2-0.5-2.8l-2-2h8.3c2.1,0,3.8-1.6,3.8-3.8V5.6C50,3.5,48.4,1.9,46.3,1.9z M38.3,45.6
                    L38.3,45.6H11.7l3.8-3.8h19L38.3,45.6z M47.5,38.1c0,0.8-0.5,1.3-1.3,1.3H35H15H3.7c-0.8,0-1.3-0.5-1.3-1.3V5.6
                    c0-0.8,0.5-1.3,1.3-1.3h42.5c0.8,0,1.3,0.5,1.3,1.3V38.1z M43.8,6.9H6.2C5.5,6.9,5,7.4,5,8.1v27.5c0,0.8,0.5,1.3,1.3,1.3h37.5
                    c0.8,0,1.3-0.5,1.3-1.3V8.1C45,7.4,44.5,6.9,43.8,6.9z M42.5,34.4h-35v-25h35V34.4z M17.5,28.1H20h0.1c0.5,2.1,2.5,3.8,4.9,3.8
                    s4.3-1.6,4.9-3.8H30h2.5c0.8,0,1.3-0.5,1.3-1.3c0-0.8-0.5-1.3-1.3-1.3h-1.3v-5c0-3-2.1-5.5-5-6.1v-1.4c0-0.8-0.5-1.3-1.3-1.3
                    s-1.3,0.5-1.3,1.3v1.4c-2.9,0.6-5,3.1-5,6.1v5h-1.3c-0.8,0-1.3,0.5-1.3,1.3C16.2,27.6,16.7,28.1,17.5,28.1z M25,29.4
                    c-0.9,0-1.8-0.5-2.1-1.3h4.3C26.8,28.9,25.9,29.4,25,29.4z M21.2,20.6c0-2.1,1.6-3.8,3.8-3.8s3.8,1.6,3.8,3.8v5h-7.5V20.6z"></path>
                  </svg>`,

					title: 'Notification management',
					text: 'Admins set the custom notifications system for the users daily meals, workout plans and fitness goals.'
				}
			]
		};
	}
};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a.active .car-wash-feature-tab-icon,
#technologies_tabs li a:hover .car-wash-feature-tab-icon {
	fill: #fff;
	background: #008dd2;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
.car-wash-feature-tab-icon {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin: 0 auto 15px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a span {
	font-size: 16px;
	color: #202122;
	display: block;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.car-wash-feature-tab li {
	min-width: 180px;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li:last-child a {
	border-radius: 0 10px 10px 0;
}

.car-wash-feature-tab li a {
	border: 1px solid transparent;
	background-color: #fff;
	padding: 17px 10px;
	display: block;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 2px solid #e8e8e8;
}
a:hover {
	color: #008dd2;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border: 1px solid #008dd2;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border-bottom: 2px solid #008dd2;
}
.feature-tab li a:hover .car-wash-feature-tab-icon {
	background-color: #008dd2;
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a.active span,
.car-wash-feature-tab li a:hover span {
	color: #008dd2;
}
.car-wash-feature-tab li {
	padding: 0 !important;
}
.car-wash-feature-tab {
	border-bottom: 0;
	justify-content: center;
	display: flex;
}
@media (max-width: 776px) {
	#technologies_tabs {
		flex-wrap: wrap;
		justify-content: center;
	}
	.car-wash-feature-tab li {
		min-width: 60px;
	}
	#technologies_tabs li {
		width: fit-content;
		padding: 0;
		border-right: 1px solid #e0e2e3;
	}
	#technologies_tabs li a {
		width: fit-content;
		padding: 10px;
	}
	.car-wash-feature-tab-icon {
		width: 50px;
		height: 50px;
	}
	#technologies_tabs li a.active .car-wash-feature-tab-icon,
	#technologies_tabs li a:hover .car-wash-feature-tab-icon {
		background-color: transparent;
		fill: #0082dd;
	}
}
</style>
