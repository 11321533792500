<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Driving Results that Creates an Impact Through UX/UI Design</h2>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../../WebDevelopment/AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Turn vision into reality",
               text:"Let your ideas breathe on our designing canvas as we carve a thoughtful design out of your vision. Blending technology and expertise in offering UX UI design solutions.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M44.9,50h-40c-2.8,0-5-2.2-5-5V5c0-2.8,2.2-5,5-5h40c2.8,0,5,2.2,5,5v40C49.9,47.8,47.7,50,44.9,50z M4.9,1.7
                  C3.1,1.7,1.6,3.2,1.6,5v40c0,1.8,1.5,3.3,3.3,3.3h40c1.8,0,3.3-1.5,3.3-3.3V5c0-1.8-1.5-3.3-3.3-3.3H4.9z"></path>
                  <circle cx="44.9" cy="5.8" r="0.8"></circle>
                  <circle cx="41.6" cy="5.8" r="0.8"></circle>
                  <path d="M9.1,8.3H5.8c-0.5,0-1.7-0.2-1.7-2.1s1.2-2.1,1.7-2.1h3.3c0.5,0,1.7,0.2,1.7,2.1S9.6,8.3,9.1,8.3z M5.8,6.7L5.8,6.7z
                  M5.8,6.7h3.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4H5.8c0,0.1,0,0.3,0,0.4C5.8,6.4,5.8,6.5,5.8,6.7z"></path>
                  <path d="M44.1,45.8H5.8c-0.9,0-1.7-0.7-1.7-1.7V12.5c0-0.9,0.7-1.7,1.7-1.7h38.3c0.9,0,1.7,0.7,1.7,1.7v31.7
                  C45.8,45.1,45,45.8,44.1,45.8z M5.8,12.5v31.7h38.3V12.5H5.8z"></path>
                  <path d="M35.8,6.7h-20c-1.3,0-2.5-1-2.5-2.4c0,0,0-0.1,0-0.1V0.8c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v3.3
                  c0,0.4,0.3,0.8,0.7,0.8c0,0,0.1,0,0.1,0h20c0.4,0,0.8-0.3,0.8-0.7c0,0,0-0.1,0-0.1V0.8C36.6,0.4,37,0,37.4,0c0.5,0,0.8,0.4,0.8,0.8
                  v3.3c0,1.3-1,2.5-2.4,2.5C35.9,6.7,35.8,6.7,35.8,6.7z"></path>
                  <path d="M12.4,41.7c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2C16.6,39.8,14.7,41.7,12.4,41.7z M12.4,35
                  c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S13.8,35,12.4,35z"></path>
                  <path d="M37.4,41.7c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2C41.6,39.8,39.7,41.7,37.4,41.7z M37.4,35
                  c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S38.8,35,37.4,35z"></path>
                  <path d="M24.9,41.7c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2C29.1,39.8,27.2,41.7,24.9,41.7z M24.9,35
                  c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5S26.3,35,24.9,35z"></path>
                  <circle cx="24.9" cy="37.5" r="0.8"></circle>
                  <path d="M21.6,38.3h-5.8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h5.8c0.5,0,0.8,0.4,0.8,0.8S22.1,38.3,21.6,38.3z"></path>
                  <path d="M34.1,38.3h-5.8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h5.8c0.5,0,0.8,0.4,0.8,0.8S34.6,38.3,34.1,38.3z"></path>
                  <path d="M30.8,26.7H19.1c-0.5,0-0.8-0.4-0.8-0.8c0-0.2,0.1-0.3,0.2-0.5l4.2-5.8c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3
                  l1.9,2.6l1-1c0.2-0.2,0.4-0.3,0.6-0.2c0.2,0,0.5,0.1,0.6,0.3l3.3,4.2c0.3,0.4,0.2,0.9-0.1,1.2C31.2,26.6,31,26.7,30.8,26.7z
                  M20.7,25H29l-1.7-2.1l-1,1c-0.3,0.3-0.9,0.3-1.2,0c0,0-0.1-0.1-0.1-0.1l-1.8-2.4L20.7,25z"></path>
                  <path d="M31.6,30.8H18.3c-1.8,0-3.3-1.5-3.3-3.3v-9.2c0-1.8,1.5-3.3,3.3-3.3h13.3c1.8,0,3.3,1.5,3.3,3.3v9.2
                  C34.9,29.3,33.5,30.8,31.6,30.8z M18.3,16.7c-0.9,0-1.7,0.7-1.7,1.7v9.2c0,0.9,0.7,1.7,1.7,1.7h13.3c0.9,0,1.7-0.7,1.7-1.7v-9.2
                  c0-0.9-0.7-1.7-1.7-1.7H18.3z"></path>
                  <path d="M44.9,30.8h-4.2c-1.8,0-3.3-1.5-3.3-3.3v-9.2c0-1.8,1.5-3.3,3.3-3.3h4.2c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-4.2
                  c-0.9,0-1.7,0.7-1.7,1.7v9.2c0,0.9,0.7,1.7,1.7,1.7h4.2c0.5,0,0.8,0.4,0.8,0.8S45.4,30.8,44.9,30.8z"></path>
                  <path d="M9.1,30.8H4.9c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h4.2c0.9,0,1.7-0.7,1.7-1.7v-9.2c0-0.9-0.7-1.7-1.7-1.7H4.9
                  c-0.5,0-0.8-0.4-0.8-0.8S4.5,15,4.9,15h4.2c1.8,0,3.3,1.5,3.3,3.3v9.2C12.4,29.3,11,30.8,9.1,30.8z"></path>
                </g>
              </svg>
               `
            },
            {
               title:"Holistic UX/UI Solutions",
               text:"We go beyond simply designing the prototype and elaborative UI. Being a UI UX company, we emphasise on providing comprehensive business solutions through intuitive app design.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M29.2,10.8h-8.3c-0.5,0-0.8,0.4-0.8,0.8v2.5c0,0.5,0.4,0.8,0.8,0.8h8.3c0.5,0,0.8-0.4,0.8-0.8v-2.5
                  C30,11.2,29.6,10.8,29.2,10.8z M28.3,13.3h-6.7v-0.8h6.7V13.3z"></path>
                  <rect x="44.2" y="6.7" width="3.3" height="1.7"></rect>
                  <rect x="44.2" y="9.2" width="3.3" height="1.7"></rect>
                  <path d="M43.3,17.5v-4.2c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2
                  C43,18.3,43.3,18,43.3,17.5z M41.7,16.7h-2.5v-2.5h2.5V16.7z"></path>
                  <rect x="44.2" y="13.3" width="3.3" height="1.7"></rect>
                  <rect x="44.2" y="15.8" width="3.3" height="1.7"></rect>
                  <path d="M41.7,25c0.5,0,0.8-0.4,0.8-0.8v-1.7h6.7c0.5,0,0.8-0.4,0.8-0.8V0.8C50,0.4,49.6,0,49.2,0H35.7c-0.5,0-0.8,0.4-0.8,0.8v6.7
                  h-2.3V0.8c0-0.5-0.4-0.8-0.8-0.8H18.3c-0.5,0-0.8,0.4-0.8,0.8v15.8h-2.3V0.8c0-0.5-0.4-0.8-0.8-0.8H0.8C0.4,0,0,0.4,0,0.8v20.8
                  c0,0.5,0.4,0.8,0.8,0.8h13.5c0.5,0,0.8-0.4,0.8-0.8v-3.3h2.3v3.3c0,0.5,0.4,0.8,0.8,0.8h13.5c0.5,0,0.8-0.4,0.8-0.8V9.2h2.3v12.5
                  c0,0.5,0.4,0.8,0.8,0.8h5.1v0.8H7.5c-0.5,0-0.8,0.4-0.8,0.8v2.5H0.8C0.4,26.7,0,27,0,27.5v21.7C0,49.6,0.4,50,0.8,50h13.5
                  c0.5,0,0.8-0.4,0.8-0.8v-6.7h2.3v6.7c0,0.5,0.4,0.8,0.8,0.8h13.5c0.5,0,0.8-0.4,0.8-0.8v-8.3h2.3v8.3c0,0.5,0.4,0.8,0.8,0.8h13.5
                  c0.5,0,0.8-0.4,0.8-0.8V27.5c0-0.5-0.4-0.8-0.8-0.8H35.7c-0.5,0-0.8,0.4-0.8,0.8v11.7h-2.3V27.5c0-0.5-0.4-0.8-0.8-0.8H18.3
                  c-0.5,0-0.8,0.4-0.8,0.8v13.3h-2.3V27.5c0-0.5-0.4-0.8-0.8-0.8h-6V25H41.7z M13.5,6.3v4.5H4.4L13.5,6.3z M1.7,10.3V5h10.6L1.7,10.3
                  z M13.5,1.7v1.7H1.7V1.7H13.5z M13.5,20.8H1.7v-8.3h11.8v4.2h-1v-0.8c0-0.5-0.4-0.8-0.8-0.8H3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3
                  c0,0.5,0.4,0.8,0.8,0.8h8.3c0.5,0,0.8-0.4,0.8-0.8v-0.8h1V20.8z M10.8,16.7v1.7H4.2v-1.7H10.8z M30.9,1.7v1.7H19.1V1.7H30.9z
                  M30.9,20.8H19.1v-2.5H20v0.8c0,0.5,0.4,0.8,0.8,0.8h8.3c0.5,0,0.8-0.4,0.8-0.8v-2.5c0-0.5-0.4-0.8-0.8-0.8h-8.3
                  c-0.5,0-0.8,0.4-0.8,0.8h-0.9V5h11.8v2.5H30V6.7c0-0.5-0.4-0.8-0.8-0.8h-8.3c-0.5,0-0.8,0.4-0.8,0.8v2.5c0,0.5,0.4,0.8,0.8,0.8h8.3
                  c0.5,0,0.8-0.4,0.8-0.8h0.9V20.8z M21.7,18.3v-0.8h6.7v0.8H21.7z M28.3,7.5v0.8h-6.7V7.5H28.3z M48.3,1.7v1.7H36.5V1.7H48.3z
                  M36.5,20.8V9.2h1v1.7c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V6.7c0-0.5-0.4-0.8-0.8-0.8h-4.2c-0.5,0-0.8,0.4-0.8,0.8
                  v0.8h-1V5h11.8v15.8h-5.8v-1.7h-1.7v1.7H36.5z M39.2,10V7.5h2.5V10H39.2z M41.7,48.3V31.7h6.7v16.7H41.7z M48.3,28.3V30H36.5v-1.7
                  H48.3z M36.5,31.7H40v16.7h-3.5v-7.5h2.6v-1.7h-2.6V31.7z M19.1,48.3V45h11.8v3.3H19.1z M30.9,28.3V30H19.1v-1.7H30.9z M19.1,31.7
                  h11.8v7.5h-1.7v1.7h1.7v2.5H19.1v-0.8h1.7v-1.7h-1.7V31.7z M13.5,38.3H1.7v-6.7h11.8V38.3z M13.5,42.5v5.8H1.7V40h11.8v0.8h-1.8
                  v1.7H13.5z M13.5,28.3V30H1.7v-1.7h5v0.8h1.7v-0.8H13.5z"></path>
                  <path d="M3.3,37.4h3.2c0.5,0,0.8-0.4,0.8-0.8v-3.2c0-0.5-0.4-0.8-0.8-0.8H3.3c-0.5,0-0.8,0.4-0.8,0.8v3.2C2.5,37,2.9,37.4,3.3,37.4
                  z M4.2,34.2h1.5v1.5H4.2V34.2z"></path>
                  <path d="M8.5,37.4h3.2c0.5,0,0.8-0.4,0.8-0.8v-3.2c0-0.5-0.4-0.8-0.8-0.8H8.5c-0.5,0-0.8,0.4-0.8,0.8v3.2C7.6,37,8,37.4,8.5,37.4z
                  M9.3,34.2h1.5v1.5H9.3V34.2z"></path>
                  <rect x="2.5" y="40.8" width="5" height="1.7"></rect>
                  <rect x="2.5" y="43.3" width="10" height="1.7"></rect>
                  <rect x="2.5" y="45.8" width="10" height="1.7"></rect>
                  <path d="M22.9,40.7c0.3,0.1,0.6,0.1,0.8,0l3.3-2.2c0.4-0.3,0.5-0.8,0.2-1.2c-0.1-0.1-0.1-0.2-0.2-0.2l-3.3-2.2
                  c-0.4-0.3-0.9-0.1-1.2,0.2c-0.1,0.1-0.1,0.3-0.1,0.5V40C22.5,40.3,22.7,40.6,22.9,40.7z M24.2,37.1l1,0.7l-1,0.7V37.1z"></path>
                  <rect x="20.8" y="45.8" width="8.3" height="1.7"></rect>
                  <rect x="42.5" y="33.3" width="5" height="1.7"></rect>
                  <rect x="42.5" y="35.8" width="5" height="1.7"></rect>
                  <rect x="42.5" y="38.3" width="5" height="1.7"></rect>
                  <rect x="42.5" y="40.8" width="5" height="1.7"></rect>
                  <rect x="42.5" y="43.3" width="5" height="1.7"></rect>
                  <rect x="42.5" y="45.8" width="5" height="1.7"></rect>
                </g>
              </svg>
               `
            },
            {
               title:"UX/UI Services at Par",
               text:"Our UX/UI development service standards are at par with the industry to empower your business with latest technology. Our experienced state team ensures offering scalable UI for a dynamic UX.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <rect x="3.3" y="12.5" width="6.7" height="1.7"></rect>
                  <rect x="3.3" y="15.8" width="13.3" height="1.7"></rect>
                  <path d="M4.2,25h9.2c0.5,0,0.8-0.4,0.8-0.8v-3.3c0-0.5-0.4-0.8-0.8-0.8H4.2c-0.5,0-0.8,0.4-0.8,0.8v3.3C3.3,24.6,3.7,25,4.2,25z
                  M5,21.7h7.5v1.7H5V21.7z"></path>
                  <path d="M49.2,2.5H0.8C0.4,2.5,0,2.9,0,3.3v38.3c0,0.5,0.4,0.8,0.8,0.8h30.1v4.2c0,0.5,0.4,0.8,0.8,0.8l0,0h8.5
                  c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.2-0.4,0.2-0.6v-4.2h8.1c0.5,0,0.8-0.4,0.8-0.8V3.3C50,2.9,49.6,2.5,49.2,2.5z M41.9,30.8H45v6.7
                  h-3.9V37c0-1.2,0.7-2.3,1.8-2.8c0.2-0.1,0.4-0.3,0.4-0.5c0.1-0.2,0-0.5-0.1-0.6L41.9,30.8z M39.4,37v1.7h-6.8V37
                  c0-1.5-0.8-2.9-2.1-3.8l1.7-2.8l0,0l2-3.3l0,0l0.8-1.3v4.7l-0.2,0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1
                  c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.4-0.1,0.6
                  c0,0.8,0.5,1.6,1.2,2c0.3,0.2,0.7,0.3,1.1,0.3c1.3,0,2.3-1.1,2.3-2.3c0-1-0.6-1.8-1.6-2.2v-4.8l4.7,7.7
                  C40.1,34.1,39.4,35.5,39.4,37L39.4,37z M28.6,33.1c-0.1,0.2-0.2,0.4-0.1,0.7c0.1,0.2,0.2,0.4,0.4,0.5c1.1,0.5,1.9,1.5,1.9,2.7v0.5
                  H20.5v-6.7H30L28.6,33.1z M35.6,32c0.1-0.1,0.2-0.1,0.3-0.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7c-0.1,0-0.2,0-0.3-0.1
                  c-0.2-0.1-0.3-0.3-0.3-0.6C35.2,32.3,35.4,32.1,35.6,32z M36.7,22.5c0-0.6,0.3-1.1,0.8-1.5c0.8-0.5,2.1-0.3,4,0.7
                  c2,1,3.6,1.2,4.7,0.6c0.7-0.4,1.2-1.1,1.3-1.9l-1.7-0.2c-0.1,0.3-0.2,0.5-0.5,0.6c-0.3,0.2-1.2,0.3-3.1-0.6
                  c-2.4-1.2-4.3-1.4-5.6-0.6c-0.9,0.7-1.5,1.7-1.6,2.9L33,25.8h-6.8l22.1-14v14h-9.5L36.7,22.5z M22.5,25.8H1.7v-15h20.8V25.8z
                  M24.2,10.8h22.6L24.2,25.1V10.8z M48.3,4.2v5H1.7v-5H48.3z M1.7,40.8V27.5H32l-1,1.7H19.7c-0.5,0-0.8,0.4-0.8,0.8v8.3
                  c0,0.5,0.4,0.8,0.8,0.8h11.2v1.7H1.7z M32.6,45.8v-4.2l0,0v-1.4h6.8v5.5H32.6z M41.1,40.8v-1.7h4.8c0.5,0,0.8-0.4,0.8-0.8V30
                  c0-0.5-0.4-0.8-0.8-0.8h-5l-1-1.7h8.5v13.3H41.1z"></path>
                  <rect x="3.3" y="5.8" width="1.7" height="1.7"></rect>
                  <rect x="6.7" y="5.8" width="1.7" height="1.7"></rect>
                  <rect x="10" y="5.8" width="1.7" height="1.7"></rect>
                  <path d="M16.4,29.2H4.2c-0.5,0-0.8,0.4-0.8,0.8v8.3c0,0.5,0.4,0.8,0.8,0.8h12.3c0.5,0,0.8-0.4,0.8-0.8V30
                  C17.3,29.5,16.9,29.2,16.4,29.2z M15.6,37.5H5v-6.7h10.6V37.5z"></path>
                </g>
              </svg>
               `
            },
            {
               title:"Timeliness",
               text:"Integrating time in delivery can determine business success. Our commitment to timely delivering UX/UI services safeguard your business from competition to get you to launch ready in a jiffy.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g id="XMLID_806_">
                  <g id="XMLID_386_">
                    <path id="XMLID_389_" d="M49.9,27.3c-0.4-4.5-2.5-8.8-5.7-12c-2.7-2.7-6.1-4.5-9.6-5.3V7.5c1.4-0.4,2.5-1.7,2.5-3.2
                    c0-1.8-1.5-3.3-3.3-3.3h-7c-1.8,0-3.3,1.5-3.3,3.3c0,1.6,1.1,2.9,2.5,3.2V10c-3.5,0.8-6.9,2.6-9.6,5.3c-0.6,0.6-1.2,1.2-1.7,1.9
                    c0,0,0,0-0.1,0h-7c-0.5,0-1,0.4-1,1s0.4,1,1,1h5.7c-0.7,1.2-1.3,2.5-1.7,3.8H1c-0.5,0-1,0.4-1,1s0.4,1,1,1h10.1
                    c-0.3,1.2-0.4,2.5-0.5,3.8H6.3c-0.5,0-1,0.4-1,1s0.4,1,1,1h4.3c0.1,1.3,0.3,2.5,0.6,3.8H4.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h6.9
                    c1,2.5,2.5,4.9,4.5,6.9c3.2,3.2,7.5,5.2,12,5.7C29,49,29.6,49,30.3,49c3.8,0,7.6-1.1,10.8-3.2c0.5-0.3,0.6-0.9,0.3-1.4
                    C41,44,40.4,43.9,40,44.2c-7,4.6-16.3,3.6-22.3-2.3c-6.9-6.9-6.9-18.2,0-25.1c6.9-6.9,18.2-6.9,25.1,0c5.9,5.9,6.9,15.2,2.4,22.2
                    c-0.3,0.5-0.2,1.1,0.3,1.4c0.5,0.3,1.1,0.2,1.4-0.3C49.3,36.3,50.3,31.8,49.9,27.3z M25.4,4.3C25.4,3.6,26,3,26.7,3h7
                    c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4h-0.2h-6.7h-0.2C26,5.7,25.4,5.1,25.4,4.3z M27.9,9.7V7.6h4.7v2.1
                    C31.1,9.5,29.5,9.5,27.9,9.7z"></path>
                    <path id="XMLID_391_" d="M43.5,41.6c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
                    c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C44,41.7,43.8,41.6,43.5,41.6z"></path>
                    <path id="XMLID_394_" d="M30.3,14.1c-8.4,0-15.1,6.8-15.1,15.1s6.8,15.1,15.1,15.1s15.1-6.8,15.1-15.1S38.6,14.1,30.3,14.1z
                    M30.3,42.5c-7.3,0-13.2-5.9-13.2-13.2S23,16.1,30.3,16.1S43.5,22,43.5,29.3S37.6,42.5,30.3,42.5z"></path>
                    <path id="XMLID_397_" d="M36.5,21.7l-4.8,4.8c-0.4-0.2-0.9-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3l-2.2-2.2c-0.4-0.4-1-0.4-1.4,0
                    c-0.4,0.4-0.4,1,0,1.4l2.2,2.2c-0.2,0.4-0.3,0.9-0.3,1.3c0,1.7,1.4,3,3,3s3-1.4,3-3c0-0.5-0.1-0.9-0.3-1.3l4.7-4.7l0,0l0.1-0.1
                    c0.4-0.4,0.4-1,0-1.4C37.5,21.3,36.8,21.3,36.5,21.7z M30.3,30.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
                    c0.6,0,1.1,0.5,1.1,1.1C31.4,29.9,30.9,30.4,30.3,30.4z"></path>
                    <path id="XMLID_398_" d="M40.7,28.3h-0.9c-0.5,0-1,0.4-1,1s0.4,1,1,1h0.9c0.5,0,1-0.4,1-1S41.3,28.3,40.7,28.3z"></path>
                    <path id="XMLID_399_" d="M20.7,28.3h-0.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h0.9c0.5,0,1-0.4,1-1C21.7,28.7,21.3,28.3,20.7,28.3z"></path>
                    <path id="XMLID_400_" d="M30.3,20.7c0.5,0,1-0.4,1-1v-0.9c0-0.5-0.4-1-1-1s-1,0.4-1,1v0.9C29.3,20.3,29.7,20.7,30.3,20.7z"></path>
                    <path id="XMLID_424_" d="M30.3,37.8c-0.5,0-1,0.4-1,1v0.9c0,0.5,0.4,1,1,1s1-0.4,1-1v-0.9C31.2,38.3,30.8,37.8,30.3,37.8z"></path>
                    <path id="XMLID_425_" d="M1,34.3c-0.3,0-0.5,0.1-0.7,0.3S0,35,0,35.3c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3
                    C1.8,35.8,2,35.6,2,35.3c0-0.3-0.1-0.5-0.3-0.7C1.5,34.4,1.2,34.3,1,34.3z"></path>
                  </g>
                </g>
              </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
