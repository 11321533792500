<template>
	<section class="banner innerbanner aboutusbanner fxt-about-section">
		<div class="video pt-0">
			<img
				class="lazy fxt-about-img pt-0"
				alt="GenixBit careers"
				src="../../../assets/images/vr-app-banner.webp"
			/>
		</div>
		<div
			class="tenc-intro-main__wrapper"
			style="
				min-height: unset !important;
				background-image: linear-gradient(to right, #000000e8, #000000a8);
			"
		>
			<div class="container py-0" style="margin-top: 30px">
				<div class="row align-items-center">
					<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
						<h1 class="fxt-top-head">
							<span>Virtual Reality App Development</span>
						</h1>
						<p style="margin: 0 0 10px; font-size: 16px" class="fxt-top-par about">
							Experts claim that Virtual Reality technology will transform the way brands used to
							interact with and engage their audience. Explore and relish new benchmarks with
							Virtual Reality app development. Our team of Virtual Reality app developers has
							unmatched command in building custom VR app development solutions for the clients.
						</p>
						<router-link to="/portfolio"
							title=""
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>View Our Work</router-link
						>
						<router-link to="/app-development-cost"
							title=""
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>Claculate App Development Cost</router-link
						>
						<ol class="breadcrumb">
							<li class="breadcrumb-item text-white">
								<router-link
									to="/"
									class="text-white breadcrumb-item fxt-breadcrumb-item"
									style="background: none; margin-right: 0"
								>
									Home</router-link
								>
							</li>
							<li class="breadcrumb-item text-white active fxt-breadcrumb-item">
								VR App Development
							</li>
						</ol>
					</div>
					<div
						class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 fxt-ar-form-cont"
						style="padding-top: 50px"
					>
						<form>
							<div class="form-group col-12">
								<input
									type="text"
									name="company_name"
									class="form-control"
									placeholder="Name"
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Company Name'"
									v-model="company_name"
								/>
							</div>
							<div class="form-group col-12">
								<input
									type="email"
									name="from_email"
									class="form-control"
									placeholder="Email"
									required
									onfocus="this.placeholder = ''"
									onblur="this.placeholder = 'Email'"
									data-bv-field="email"
								/>
								<small
									style="display: none"
									class="help-block"
									data-bv-validator="notEmpty"
									data-bv-for="email"
									data-bv-result="NOT_VALIDATED"
									>The email address is required and cannot be empty</small
								><small
									style="display: none"
									class="help-block"
									data-bv-validator="emailAddress"
									data-bv-for="email"
									data-bv-result="NOT_VALIDATED"
									>The email address is not a valid</small
								>
							</div>
							<div class="form-group col-12">
								<div class="phone-field">
									<div class="con-num">
										<div
											class="iti iti--allow-dropdown iti--separate-dial-code fxt-iti--allow-dropdown"
										>
											<select
												name="countryCode"
												id=""
												onfocus="this.size=5;"
												onblur="this.size=1;"
												style="border-color: #ced4da; border-radius: 3px 0 0 3px"
												onchange="this.size=1; this.blur();"
											>
												<option data-countryCode="IN" value="91">(+91)</option>
												<option data-countryCode="DZ" value="213">(+213)</option>
												<option data-countryCode="AD" value="376">(+376)</option>
												<option data-countryCode="AO" value="244">(+244)</option>
												<option data-countryCode="AI" value="1264">(+1264)</option>
												<option data-countryCode="AG" value="1268">(+1268)</option>
												<option data-countryCode="AR" value="54">(+54)</option>
												<option data-countryCode="AM" value="374">(+374)</option>
												<option data-countryCode="AW" value="297">(+297)</option>
												<option data-countryCode="AU" value="61">(+61)</option>
												<option data-countryCode="AT" value="43">(+43)</option>
												<option data-countryCode="AZ" value="994">(+994)</option>
												<option data-countryCode="BS" value="1242">(+1242)</option>
												<option data-countryCode="BH" value="973">(+973)</option>
												<option data-countryCode="BD" value="880">(+880)</option>
												<option data-countryCode="BB" value="1246">(+1246)</option>
												<option data-countryCode="BY" value="375">(+375)</option>
												<option data-countryCode="BE" value="32">(+32)</option>
												<option data-countryCode="BZ" value="501">(+501)</option>
												<option data-countryCode="BJ" value="229">(+229)</option>
												<option data-countryCode="BM" value="1441">(+1441)</option>
												<option data-countryCode="BT" value="975">(+975)</option>
												<option data-countryCode="BO" value="591">(+591)</option>
												<option data-countryCode="BA" value="387">(+387)</option>
												<option data-countryCode="BW" value="267">(+267)</option>
												<option data-countryCode="BR" value="55">(+55)</option>
												<option data-countryCode="BN" value="673">(+673)</option>
												<option data-countryCode="BG" value="359">(+359)</option>
												<option data-countryCode="BF" value="226">(+226)</option>
												<option data-countryCode="BI" value="257">(+257)</option>
												<option data-countryCode="KH" value="855">(+855)</option>
												<option data-countryCode="CM" value="237">(+237)</option>
												<option data-countryCode="CA" value="1">(+1)</option>
												<option data-countryCode="CV" value="238">(+238)</option>
												<option data-countryCode="KY" value="1345">(+1345)</option>
												<option data-countryCode="CF" value="236">(+236)</option>
												<option data-countryCode="CL" value="56">(+56)</option>
												<option data-countryCode="CN" value="86">(+86)</option>
												<option data-countryCode="CO" value="57">(+57)</option>
												<option data-countryCode="KM" value="269">(+269)</option>
												<option data-countryCode="CG" value="242">(+242)</option>
												<option data-countryCode="CK" value="682">(+682)</option>
												<option data-countryCode="CR" value="506">(+506)</option>
												<option data-countryCode="HR" value="385">(+385)</option>
												<option data-countryCode="CU" value="53">(+53)</option>
												<option data-countryCode="CY" value="90392">(+90392)</option>
												<option data-countryCode="CY" value="357">(+357)</option>
												<option data-countryCode="CZ" value="42">(+42)</option>
												<option data-countryCode="DK" value="45">(+45)</option>
												<option data-countryCode="DJ" value="253">(+253)</option>
												<option data-countryCode="DM" value="1809">(+1809)</option>
												<option data-countryCode="DO" value="1809">(+1809)</option>
												<option data-countryCode="EC" value="593">(+593)</option>
												<option data-countryCode="EG" value="20">(+20)</option>
												<option data-countryCode="SV" value="503">(+503)</option>
												<option data-countryCode="GQ" value="240">(+240)</option>
												<option data-countryCode="ER" value="291">(+291)</option>
												<option data-countryCode="EE" value="372">(+372)</option>
												<option data-countryCode="ET" value="251">(+251)</option>
												<option data-countryCode="FK" value="500">(+500)</option>
												<option data-countryCode="FO" value="298">(+298)</option>
												<option data-countryCode="FJ" value="679">(+679)</option>
												<option data-countryCode="FI" value="358">(+358)</option>
												<option data-countryCode="FR" value="33">(+33)</option>
												<option data-countryCode="GF" value="594">(+594)</option>
												<option data-countryCode="PF" value="689">(+689)</option>
												<option data-countryCode="GA" value="241">(+241)</option>
												<option data-countryCode="GM" value="220">(+220)</option>
												<option data-countryCode="GE" value="7880">(+7880)</option>
												<option data-countryCode="DE" value="49">(+49)</option>
												<option data-countryCode="GH" value="233">(+233)</option>
												<option data-countryCode="GI" value="350">(+350)</option>
												<option data-countryCode="GR" value="30">(+30)</option>
												<option data-countryCode="GL" value="299">(+299)</option>
												<option data-countryCode="GD" value="1473">(+1473)</option>
												<option data-countryCode="GP" value="590">(+590)</option>
												<option data-countryCode="GU" value="671">(+671)</option>
												<option data-countryCode="GT" value="502">(+502)</option>
												<option data-countryCode="GN" value="224">(+224)</option>
												<option data-countryCode="GW" value="245">(+245)</option>
												<option data-countryCode="GY" value="592">(+592)</option>
												<option data-countryCode="HT" value="509">(+509)</option>
												<option data-countryCode="HN" value="504">(+504)</option>
												<option data-countryCode="HK" value="852">(+852)</option>
												<option data-countryCode="HU" value="36">(+36)</option>
												<option data-countryCode="IS" value="354">(+354)</option>
												<option data-countryCode="ID" value="62">(+62)</option>
												<option data-countryCode="IR" value="98">(+98)</option>
												<option data-countryCode="IQ" value="964">(+964)</option>
												<option data-countryCode="IE" value="353">(+353)</option>
												<option data-countryCode="IL" value="972">(+972)</option>
												<option data-countryCode="IT" value="39">(+39)</option>
												<option data-countryCode="JM" value="1876">(+1876)</option>
												<option data-countryCode="JP" value="81">(+81)</option>
												<option data-countryCode="JO" value="962">(+962)</option>
												<option data-countryCode="KZ" value="7">(+7)</option>
												<option data-countryCode="KE" value="254">(+254)</option>
												<option data-countryCode="KI" value="686">(+686)</option>
												<option data-countryCode="KP" value="850">(+850)</option>
												<option data-countryCode="KR" value="82">(+82)</option>
												<option data-countryCode="KW" value="965">(+965)</option>
												<option data-countryCode="KG" value="996">(+996)</option>
												<option data-countryCode="LA" value="856">(+856)</option>
												<option data-countryCode="LV" value="371">(+371)</option>
												<option data-countryCode="LB" value="961">(+961)</option>
												<option data-countryCode="LS" value="266">(+266)</option>
												<option data-countryCode="LR" value="231">(+231)</option>
												<option data-countryCode="LY" value="218">(+218)</option>
												<option data-countryCode="LI" value="417">(+417)</option>
												<option data-countryCode="LT" value="370">(+370)</option>
												<option data-countryCode="LU" value="352">(+352)</option>
												<option data-countryCode="MO" value="853">(+853)</option>
												<option data-countryCode="MK" value="389">(+389)</option>
												<option data-countryCode="MG" value="261">(+261)</option>
												<option data-countryCode="MW" value="265">(+265)</option>
												<option data-countryCode="MY" value="60">(+60)</option>
												<option data-countryCode="MV" value="960">(+960)</option>
												<option data-countryCode="ML" value="223">(+223)</option>
												<option data-countryCode="MT" value="356">(+356)</option>
												<option data-countryCode="MH" value="692">(+692)</option>
												<option data-countryCode="MQ" value="596">(+596)</option>
												<option data-countryCode="MR" value="222">(+222)</option>
												<option data-countryCode="YT" value="269">(+269)</option>
												<option data-countryCode="MX" value="52">(+52)</option>
												<option data-countryCode="FM" value="691">(+691)</option>
												<option data-countryCode="MD" value="373">(+373)</option>
												<option data-countryCode="MC" value="377">(+377)</option>
												<option data-countryCode="MN" value="976">(+976)</option>
												<option data-countryCode="MS" value="1664">(+1664)</option>
												<option data-countryCode="MA" value="212">(+212)</option>
												<option data-countryCode="MZ" value="258">(+258)</option>
												<option data-countryCode="MN" value="95">(+95)</option>
												<option data-countryCode="NA" value="264">(+264)</option>
												<option data-countryCode="NR" value="674">(+674)</option>
												<option data-countryCode="NP" value="977">(+977)</option>
												<option data-countryCode="NL" value="31">(+31)</option>
												<option data-countryCode="NC" value="687">(+687)</option>
												<option data-countryCode="NZ" value="64">(+64)</option>
												<option data-countryCode="NI" value="505">(+505)</option>
												<option data-countryCode="NE" value="227">(+227)</option>
												<option data-countryCode="NG" value="234">(+234)</option>
												<option data-countryCode="NU" value="683">(+683)</option>
												<option data-countryCode="NF" value="672">(+672)</option>
												<option data-countryCode="NP" value="670">(+670)</option>
												<option data-countryCode="NO" value="47">(+47)</option>
												<option data-countryCode="OM" value="968">(+968)</option>
												<option data-countryCode="PW" value="680">(+680)</option>
												<option data-countryCode="PA" value="507">(+507)</option>
												<option data-countryCode="PG" value="675">(+675)</option>
												<option data-countryCode="PY" value="595">(+595)</option>
												<option data-countryCode="PE" value="51">(+51)</option>
												<option data-countryCode="PH" value="63">(+63)</option>
												<option data-countryCode="PL" value="48">(+48)</option>
												<option data-countryCode="PT" value="351">(+351)</option>
												<option data-countryCode="PR" value="1787">(+1787)</option>
												<option data-countryCode="QA" value="974">(+974)</option>
												<option data-countryCode="RE" value="262">(+262)</option>
												<option data-countryCode="RO" value="40">(+40)</option>
												<option data-countryCode="RU" value="7">(+7)</option>
												<option data-countryCode="RW" value="250">(+250)</option>
												<option data-countryCode="SM" value="378">(+378)</option>
												<option data-countryCode="ST" value="239">(+239)</option>
												<option data-countryCode="SA" value="966">(+966)</option>
												<option data-countryCode="SN" value="221">(+221)</option>
												<option data-countryCode="CS" value="381">(+381)</option>
												<option data-countryCode="SC" value="248">(+248)</option>
												<option data-countryCode="SL" value="232">(+232)</option>
												<option data-countryCode="SG" value="65">(+65)</option>
												<option data-countryCode="SK" value="421">(+421)</option>
												<option data-countryCode="SI" value="386">(+386)</option>
												<option data-countryCode="SB" value="677">(+677)</option>
												<option data-countryCode="SO" value="252">(+252)</option>
												<option data-countryCode="ZA" value="27">(+27)</option>
												<option data-countryCode="ES" value="34">(+34)</option>
												<option data-countryCode="LK" value="94">(+94)</option>
												<option data-countryCode="SH" value="290">(+290)</option>
												<option data-countryCode="KN" value="1869">(+1869)</option>
												<option data-countryCode="SC" value="1758">(+1758)</option>
												<option data-countryCode="SD" value="249">(+249)</option>
												<option data-countryCode="SR" value="597">(+597)</option>
												<option data-countryCode="SZ" value="268">(+268)</option>
												<option data-countryCode="SE" value="46">(+46)</option>
												<option data-countryCode="CH" value="41">(+41)</option>
												<option data-countryCode="SI" value="963">(+963)</option>
												<option data-countryCode="TW" value="886">(+886)</option>
												<option data-countryCode="TJ" value="7">(+7)</option>
												<option data-countryCode="TH" value="66">(+66)</option>
												<option data-countryCode="TG" value="228">(+228)</option>
												<option data-countryCode="TO" value="676">(+676)</option>
												<option data-countryCode="TT" value="1868">(+1868)</option>
												<option data-countryCode="TN" value="216">(+216)</option>
												<option data-countryCode="TR" value="90">(+90)</option>
												<option data-countryCode="TM" value="7">(+7)</option>
												<option data-countryCode="TM" value="993">(+993)</option>
												<option data-countryCode="TC" value="1649">(+1649)</option>
												<option data-countryCode="TV" value="688">(+688)</option>
												<option data-countryCode="UG" value="256">(+256)</option>
												<option data-countryCode="GB" value="(+44)">(+44)</option>
												<option data-countryCode="UA" value="380">(+380)</option>
												<option data-countryCode="AE" value="971">(+971)</option>
												<option data-countryCode="UY" value="598">(+598)</option>
												<option data-countryCode="US" value="1">(+1)</option>
												<option data-countryCode="UZ" value="7">(+7)</option>
												<option data-countryCode="VU" value="678">(+678)</option>
												<option data-countryCode="VA" value="379">(+379)</option>
												<option data-countryCode="VE" value="58">(+58)</option>
												<option data-countryCode="VN" value="84">(+84)</option>
												<option data-countryCode="VG" value="84">(+1284)</option>
												<option data-countryCode="VI" value="84">(+1340)</option>
												<option data-countryCode="WF" value="681">(+681)</option>
												<option data-countryCode="YE" value="969">(+969)</option>
												<option data-countryCode="YE" value="967">(+967)</option>
												<option data-countryCode="ZM" value="260">(+260)</option>
												<option data-countryCode="ZW" value="263">(+263)</option>
											</select>
											<input
												type="number"
												name="phone"
												class="form-control contact-phone"
												placeholder="Phone Number"
												required
												onfocus="this.placeholder = ''"
												onblur="this.placeholder = 'Contact No'"
												autocomplete="off"
												style="padding-left: 20px; border-radius: 0 3px 3px 0"
												data-intl-tel-input-id="0"
												data-bv-field="phone"
												v-model="phone_number"
												oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
												maxlength="10"
											/>
										</div>
									</div>
								</div>
								<small
									style="display: none"
									class="help-block"
									data-bv-validator="notEmpty"
									data-bv-for="phone"
									data-bv-result="NOT_VALIDATED"
									>The phone is required and cannot be empty</small
								><small
									style="display: none"
									class="help-block"
									data-bv-validator="stringLength"
									data-bv-for="phone"
									data-bv-result="NOT_VALIDATED"
									>Contact number must be at least 10 numbers</small
								>
							</div>
							<div class="form-group col-12">
								<textarea
									name="message"
									v-model="message"
									class="form-control"
									placeholder="Project Description"
									rows="3"
								></textarea>
							</div>
							<div class="form-group col-12 text-right d-flex align-items-center" style="gap: 15px">
								<p style="font-size: 13px !important; margin: 0 !important">
									* No Minimum Contract
								</p>
								<button
									type="submit"
									class="btn-custome btn waves-effect waves-light contact-custome-btn fxt-contact-custome-btn"
								>
									<i class="fa fa-spinner fa-spin start-loader"></i>Get Started Now!
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 1600px) {
	.banner {
	}
}
.banner {
	margin-top: 40px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.breadcrumb {
	background: 0 0;
	width: 100%;
	max-width: 1170px;
	margin-top: 40px;
	padding: 0 15px;
	z-index: 9999;
	padding-top: 15px;
	padding-left: 0 !important;
	margin-left: -20px;
}

.video img {
	width: 100%;
	height: 100%;
	min-height: 650px;
	object-fit: cover;
}
.tenc-intro-main__wrapper {
	width: 100%;
	position: absolute;
	top: -33px;
	left: 0;
	display: flex;
	align-items: center;
	background: linear-gradient(
		90deg,
		#236aec,
		rgba(35, 95, 236, 0.95) 40%,
		rgba(35, 161, 236, 0.7) 70%,
		rgba(35, 66, 236, 0.4)
	);
	z-index: 999;
}
.fxt-btn-custome {
	margin-top: 10px;
}
.fxt-top-head span {
	display: block;
	font-size: 44px;
}
.fxt-top-head span:first-child {
}
.fxt-top-head span:last-child {
	font-size: 34px;
	margin: 20px 0;
}
.fxt-breadcrumb-item {
	text-transform: none !important;
}
.fxt-ar-form-cont {
	padding: 30px 5px !important;
	border: 1px #008dd2 solid;
	border-radius: 5px;
	background: #222324;
	margin-top: 0px;
}
.form-group input,
.form-group select,
.form-group textarea {
	border: 1px #fff solid;
	background: #222324;
	color: white;
	resize: none;
}
.form-group input::placeholder,
.form-group select::placeholder,
.form-group textarea::placeholder {
	color: white;
}
@media (max-width: 991px) {
	.fxt-top-head {
		font-size: 20px;
	}
	.fxt-top-par {
		font-size: 16px;
	}
	.fxt-tenc-intro-main__wrapper {
		top: 0;
	}
}
@media (min-width: 776px) {
	.fxt-about-section {
		height: calc(90vh - 40px) !important;
	}
	.video {
		height: 95vh !important;
	}
	.tenc-intro-main__wrapper {
		height: 95vh !important;
	}
	.fxt-about-img {
		min-height: 650px !important;
	}
}

.breadcrumb-item.active {
	color: #1977cc !important;
}
.fxt-about-img {
	position: relative;
}
.fxt-about-img::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: red;
}
.fxt-contact-custome-btn {
	padding: 5px 10px !important;
	height: 35px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 !important;
}
@media (max-width: 576px) {
	.fxt-top-head {
		font-size: 20px;
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.fxt-top-par {
		font-size: 14px !important;
		line-height: 2;
	}
	.fxt-btn-custome {
		font-size: 12px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 0px;
		top: 20px;
	}
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 34px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 28px !important;
	}
	.breadcrumb {
		width: 100% !important;
		display: flex !important;
		flex-wrap: wrap !important;
		align-items: center !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.fxt-breadcrumb-item {
		font-size: 12px !important;
		padding: 5px !important;
	}
}
@media (max-width: 400px) {
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 28px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 22px !important;
	}
}
.fxt-btn-custome {
	background: #12599a !important;
	font-size: 12px;
	margin-right: 25px;
}
.fxt-btn-custome:hover {
	background: #04294b !important;
}
</style>
