<template>
	<div class="web-why-choose-main-box mhbox text-center">
		<div class="web-why-choose-main-icon" v-html="obj.svg"></div>
		<h3>{{ obj.title }}</h3>
		<p>{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.service-we-offer-web-inner .web-why-choose-main-box {
	box-shadow: none;
	border: 1px solid #ecedee;
	border-radius: 40px 0 40px 0;
	padding: 0 15px 10px;
}
.service-we-offer-web-inner .web-why-choose-main-box {
	padding: 10px 15px;
	position: relative;
	border-bottom: 5px solid #ecedee;
	-webkit-box-shadow: 0 5px 10px 0 rgb(72 73 121 / 15%);
	-moz-box-shadow: 0 5px 10px 0 rgba(72, 73, 121, 0.15);
	box-shadow: 0 5px 10px 0 rgb(72 73 121 / 15%);
	border: 0;
	border-bottom: 5px solid transparent;
}
.m-height-260 .mhbox {
	min-height: 260px;
}
.web-why-choose-main-box {
	border: 1px solid #ecedee;
	padding: 35px 20px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	min-height: 340px;
}
.text-center {
	text-align: center !important;
}
.service-we-offer-web-inner .web-why-choose-main-icon {
	width: 78px;
	height: 78px;
	border-radius: 10px;
	line-height: 78px;
}

.service-we-offer-web-inner .web-why-choose-main-icon {
	width: 100px;
	height: 100px;
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
	border-radius: 100%;
	line-height: 100px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-main-icon {
	width: 75px;
	height: 75px;
	margin: 0 auto 15px;
}
.service-we-offer-web-inner .web-why-choose-main-box h3 {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 15px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-main-box p {
	margin: 0;
}
.service-we-offer-web-inner .web-why-choose-main-icon svg {
	width: 48px;
	height: 48px;
}
.service-we-offer-web-inner .web-why-choose-main-box:hover {
	border: 1px solid #0082dd;
}
	.service-we-offer-web-inner
	.web-why-choose-main-box:hover
	.web-why-choose-main-icon {
	background-color: #0082dd;
	fill:#fff
}
.service-we-offer-web-inner .web-why-choose-main-box:hover h3 {
	color: #0082dd;
}
</style>
