<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Platforms</span>
				<h2 class="fxt-process-dev">Platform-wide Chatbot App Development Services</h2>
				<p>
					Your business uses several apps and platforms everyday. A chatbot can make things easy. We
					build chatbots that can be integrated with every major platform.
				</p>
			</div>
			<div class="row">
				<PlatformCard v-for="item in items" :key="item.text" :obj="item"></PlatformCard>
			</div>
		</div>
	</section>
</template>

<script>
import PlatformCard from '../AssetComponents/PlatformCard.vue';
export default {
	components: {
		PlatformCard
	},
	data() {
		return {
			items: [
				{
					imageLink: '/facebook (2).png',
					text: 'ld a chatbot for Facebook which will respond to user queries on your business page. The chatbot can be trained to respond to FAQs or even take online orders that will directly reflect on your dashboard.'
				},
				{
					imageLink: '/slack.png',
					text: 'Slack makes communication and collaboration easier. What if a chatbot can be integrated into your Slack community for automating responses or sending timely updates? We can do that for you.'
				},
				{
					imageLink: '/skype.png',
					text: 'As a chatbot development company we are feared up to build a Skype bot that can search for news, play games, summarize webpages and do much more that you will expect a human agent to do.'
				},
				{
					imageLink: '/webchat.png',
					text: 'al-time communication takes a leap forward with WebChat. Add a dose of intelligence to WebChat conversations with our chatbot app development services.'
				}
			]
		};
	}
};
</script>

<style scoped>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>