<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Types of Apps</span>
				<h2 class="fxt-process-dev">Travel App Development Solutions we offer</h2>
				<p>
					The travel and tourism industry is not made up of a single activity. It is the coming
					together of several activities. No matter what activity you want to focus on, we can build
					an app for that.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/travel-planning.webp',
					title: 'Travel Planning',
					text: 'Apps that help travelers to find new destinations, explore mode of transport to reach them, know about its history and landmarks, read other traveler stories and much more.'
				},
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/hotel-booking.webp',
					title: 'Hotel Booking',
					text: 'A place to rest when done traveling is hard to find. We build mobile apps that will help weary travelers find a safe place to rest and rejuvenate so that they can start again the next day.'
				},
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/ticket-booking.webp',
					title: 'Ticket Booking',
					text: 'GenixBit Labs can help your business build mobile apps for ticket booking for flight, train, cab, bikes or even luxury cruises. If you want to sell a ticket, we can build you an app for that.'
				},
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/finding-home-stay.webp',
					title: 'Finding Home Stay',
					text: 'On one hand hosts with home stays. On the other, verified travelers who want interesting places to stay. Make them both meet with a mobile app that is built on the lines of vacation rental marketplace.'
				},
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/virtual-tours.webp',
					title: 'Virtual Tours',
					text: 'Travel is an experience. Let your users experience them the best from the comfort of their couch with virtual tours. We can help you build a mobile app that gives virtual tours from Australia to Zambia.'
				},
				{
					imageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/travel/vehicle-rental-booking.webp',
					title: 'Vehicle Rental Booking',
					text: 'On-demand vehicle rental services are better when they can be accessed through a mobile app. Build the UBER for vehicle rental booking with our mobile app development expertise.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
