<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <Features></Features>

      <TypeDiv></TypeDiv>

      <WhyInvest></WhyInvest>

      <Techno></Techno>

      <Convenience></Convenience>

      <Cases></Cases>

      <FAQs></FAQs>

      <ContactUs></ContactUs>

      <Expertise></Expertise>

      <Const></Const>

  </div>
</template>

<script>
import Banner from '../../components/OnDemandSolutions/TaxiDevelopment/Banner.vue'
import Features from '../../components/OnDemandSolutions/TaxiDevelopment/Features.vue'
import Techno from '../../components/OnDemandSolutions/TaxiDevelopment/Techno.vue'
import WhyInvest from '../../components/OnDemandSolutions/TaxiDevelopment/WhyInvest.vue'
import Convenience from '../../components/OnDemandSolutions/TaxiDevelopment/Convenience.vue'
import Cases from '../../components/OnDemandSolutions/TaxiDevelopment/Cases.vue'
import FAQs from '../../components/OnDemandSolutions/TaxiDevelopment/FAQs.vue'
import ContactUs from '../../components/OnDemandSolutions/TaxiDevelopment/ContactUs.vue'
import Expertise from '../../components/OnDemandSolutions/TaxiDevelopment/Expertise.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import About from '../../components/OnDemandSolutions/TaxiDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        TypeDiv,
        WhyInvest,
        Techno,
        Convenience,
        Cases,
        FAQs,
        ContactUs,
        Expertise,
        Const,
        Features,
    }
}
</script>


<style>

</style>