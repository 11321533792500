<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Our offerings</span>
				<h2 class="fxt-process-dev">Our MVP Development Services</h2>
				<p>
					From first-time entrepreneurs to serial entrepreneurs, our Startup MVP development
					offerings are attuned to help everyone refine their idea into a scalable and market-worthy
					product.
				</p>
			</div>
			<div style="display:flex;justify-content:center">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ImageCard v-bind:obj="item"></ImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ImageCard from '../AssetComponents/ImageCard.vue';
export default {
	components: {
		ImageCard
	},
	data: () => {
		return {
			items: [
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/prototype-design.png",
					title:"Prototype Design",
					text:"Give your idea shape and form with a prototype design. Our MVP developers will create hi-fidelity interactive prototypes that you can interact with to form ideas about improvements and further product development."
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/single-feature-mvp.png",
					title:"Single Feature MVP",
					text:"MVP is a focused approach to product development. It eliminates clutter and focuses on the singlemost feature that will be cornerstone for the product’s growth story. Building that single feature MVP for you is our responsibility."
				},
				{
					imageLink:"https://www.intelivita.co.uk/assets/frameworks/public/images/mvp/single-feature-mvp2.png",
					title:"Pilot MVP Development",
					text:"Take your MVP from being a single feature product to a pilot product that can prove usability, scalability and market feasibility. GenixBit Labs’s product development expertise combined will help you launch the pilot MVP in less than 3 months."
				},
			]
		};
	}
};
</script>

<style>

@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
