<template>
	<section class="padding-60 about-leadership-section bg-white">
		<div class="title">
			<span>Leadership</span>
			<h2>Leading from the front</h2>
			<p>
				Genixbit Labs is led by passionate
				<span class="tou-node" id="tou-0-1ccdf7af-a9be-458e-a40b-056f152ed96a"></span>
				who are driven by the passion
				<span class="tou-node" id="tou-0-d6f2f93c-7690-4306-abe8-e13a8d980d10"></span> a great
				company that will surprise its clients with flawless service.
			</p>
		</div>
		<div class="row align-items-center fxt-leaders-container">
			<div class="col-lg-5 col-md-5 col-12 order-md-1">
				<div class="about-leadership-image text-center">
					<img class="lazy" alt="" src="../../assets/images/genixbit.png" style="" />
					<div class="socialUser">
						<a href="https://www.linkedin.com/in/manojnandanwar" target="_blank"
							><i class="fab fa-linkedin-in"></i
						></a>
						<a href="https://twitter.com/devopsmanoj" target="_blank"
							><i class="fab fa-twitter"></i
						></a>
					</div>
				</div>
			</div>
			<div class="col-lg-7 col-md-7 col-sm-12 col-12">
				<div class="about-leadership-content-wrap">
					<div class="about-leadership-content">
						<div class="about-leadership-title">Tech Enthusiast, Entrepreneur, Giant Nerd...</div>
						<p>
							Meet Manoj, our Co-founder from Ahmedabad who has been
							<span class="tou-node" id="tou-0-01a2a2f1-b578-4f73-b968-bb6033d39d16"></span>
							for making Genixbit what it is
							<span class="tou-node" id="tou-0-5d2fbaa4-72a2-4b0f-b6ee-38ae74add29a"></span>. Manoj
							began
							<span class="tou-node" id="tou-0-a32b1675-f7de-46af-b893-42a57adcd554"></span>
							in Project Management but crossed over into UI/UX and Front End mobile delivery. In
							<span class="tou-node" id="tou-0-72650a52-d04a-40a5-8bcb-a8db0eefd0a9"></span>
							of the Smartphone, and Silicon Valley
							<span class="tou-node" id="tou-0-7a9536c3-d680-4078-b2d1-4b5fd1da8b61"></span>
							giant
							<span class="tou-node" id="tou-0-47dd6001-c592-4640-8c68-987715e3a111"></span>, Manoj
							identified a need for outsource development requirements for small, medium and large
							organisations. Essentially Client’s handle
							<span class="tou-node" id="tou-0-66c3ed90-8179-4214-b16d-ab7dc613e343"></span>, GenixBit handle
							<span class="tou-node" id="tou-0-8b496f9a-057d-4841-855a-e65a8f738596"></span>.
						</p>
						<p>
							Currently Manoj is serving as the Co-founder of Genixbit. He has taken it upon
							himself the onus of building Genixbit into a global digital
							<span class="tou-node" id="tou-0-672b833d-8676-437a-9ac4-2149b3eb3ba7"></span>
							with
							<span class="tou-node" id="tou-0-950b4ce4-3acc-4f3b-9e0b-0add2431d95e"></span>
							of digital services.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center fxt-leaders-container pt-5 pb-5 partner-2">
			<div class="col-lg-5 col-md-5 col-12 order-1">
				<div class="about-leadership-image tow text-center">
					<img class="lazy" src="../../assets/images/genixbit.png" style="" />
					<div class="socialUser">
						<a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a>
						<a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-7 col-md-7 col-sm-12 col-12 order-2">
				<div class="about-leadership-content-wrap tow">
					<div class="about-leadership-content">
						<div class="about-leadership-title">
							Business Development Officer @ Genixbit, Sales 
						</div>
						<p>
							Pranesh Lingayat, a dynamic Business Development Officer,
							<span class="tou-node" id="tou-0-619d6ea2-b207-482c-a1f6-4a2de419b40e"></span>
							is instrumental in spearheading the company's efforts to
							<span class="tou-node" id="tou-0-c5d0dcf0-0960-45db-b339-d88abba0ac10"></span>
							secure new business opportunities and drive growth. With his strategic vision and proactive approach,
							<span class="tou-node" id="tou-0-155aed22-8827-44ac-87ca-284c9d1d6a0c"></span>.
						</p>
						<p>
							His strong leadership skills and deep understanding of the industry 
							<span class="tou-node" id="tou-0-1e7c16c4-7a31-47f9-9095-027faca2ae1b"></span>
							landscape enable him to identify emerging trends and capitalize on untapped markets.
							Pranesh's unwavering dedication to customer satisfaction ensures that client needs are not only met but exceeded, 
							<span class="tou-node" id="tou-0-b35ca1b9-812f-4452-a132-259b268d989b"></span>,  
							fostering long-lasting relationships and repeat business.
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center fxt-leaders-container">
			<div class="col-lg-5 col-md-5 col-12 order-md-1">
				<div class="about-leadership-image text-center" style="background-color: #d0d7d9">
					<img class="lazy" src="../../assets/images/genixbit.png" style="" />
					<div class="socialUser">
						<a href="www.linkedin.com/in/genixbit" target="_blank"
							><i class="fab fa-linkedin-in"></i
						></a>
						<a href="https://www.linkedin.com/company/genixbit/" target="_blank"
							><i class="fab fa-facebook"></i
						></a>
					</div>
				</div>
			</div>
			<div class="col-lg-7 col-md-7 col-sm-12 col-12">
				<div class="about-leadership-content-wrap">
					<div class="about-leadership-content">
						<div class="about-leadership-title">Front End Developer</div>
						<p>
							Moataz Abdulbaqi Is Front End Developer @ Genixbit, who, along with Shareef
							Hussami, brought Genixbit to life. It is now one of the top Game, Web and Mobile
							App development companies with offices in India and the UK. He brings over 6+ years of
							experience as an Information Technology Consultant with a demonstrated
							<span class="tou-node" id="tou-0-0ad8a19d-404e-4532-94fd-92d9d1dae121"></span>
							of working with clients in
							<span class="tou-node" id="tou-0-847e317f-300d-4d05-acc7-4cdef25cec79"></span>
							across
							<span class="tou-node" id="tou-0-b2037fa4-e633-46b2-b677-8f6461cfb184"></span>.
						</p>
						<p>
							Moataz is
							<span class="tou-node" id="tou-0-36dab129-5a36-4046-b8b9-8625f0129081"></span>
							an expert in CSS globaly and improving
							<span class="tou-node" id="tou-0-746398fd-5231-49af-87b5-58dae6fbff58"></span>
							business by intervening
							<span class="tou-node" id="tou-0-46fdb3f2-7e58-4abf-9099-65dfb9a0839b"></span>
							with the team of creative brains.
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center fxt-leaders-container pt-5 pb-5 partner-2">
			<div class="col-lg-5 col-md-5 col-12 order-1">
				<div class="about-leadership-image tow text-center">
					<img class="lazy" src="../../assets/images/genixbit.png" style="" />
					<div class="socialUser">
						<a href="https://www.linkedin.com/company/genixbit/" target="_blank"
							><i class="fab fa-linkedin-in"></i
						></a>
						<a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
					</div>
				</div>
			</div>
			<div class="col-lg-7 col-md-7 col-sm-12 col-12 order-2">
				<div class="about-leadership-content-wrap tow">
					<div class="about-leadership-content">
						<div class="about-leadership-title">Senior Software Engineer, Project Manager</div>
						<p>
							Dan is passionate about information systems (vehicle registration, microfinance, chat
							and management system, etc...).
							<span class="tou-node" id="tou-0-c5d0dcf0-0960-45db-b339-d88abba0ac10"></span>
							Having started his career in software development, mobile and web applications
						</p>
						<p>
							he moved on to team management because his ability to analyze and architect a project
							from top to bottom gives him good reflexes as a Product
							<span class="tou-node" id="tou-0-1e7c16c4-7a31-47f9-9095-027faca2ae1b"></span>
							Manager and Lead Developer. With comprehensive and flawless command over
						</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.title span {
	font-size: 16px;
	font-weight: 500;
	color: #1977cc;
}
.title span {
	font-size: 16px;
	font-weight: 500;
	color: #1977cc;
}
.about-leadership-image {
	position: relative;
	margin-left: 50px !important;
	background-color: #fbfcfc !important;
	border-radius: 50% !important;
	min-height: auto !important;
	height: 360px;
	width: 360px;
	padding: 5px 10px 0 !important;
}
.about-leadership-image {
	background-color: #fbfcfc;
	border-radius: 15px;
	margin-left: 20px;
	min-height: 500px;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: center;
	-webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
	padding: 20px 10px 0;
	overflow: hidden;
}
.about-leadership-image::before {
	content: '';
	position: absolute;
	background-color: rgba(245, 133, 31, 0.1);
	height: 90%;
	width: 90%;
	left: 20px;
	bottom: 20px;
	top: 20px;
	right: 20px;
	border-radius: 50%;
}
.about-leadership-image img {
	position: relative;
	z-index: 9;
	max-height: 360px;
}
.about-leadership-image.tow {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.socialUser {
	text-align: center;
	position: absolute;
	z-index: 9;
	background-color: #13131359;
	width: 100%;
	padding: 10px 10px;
	transition: all 0.3s ease-out;
}
.socialUser a {
	border: 1px solid #1977cc;
	color: #fff;
	background-color: #1977cc;
	width: 36px;
	height: 36px;
	font-size: 16px;
	display: inline-block;
	border-radius: 50%;
	line-height: 36px;
	margin: 5px 3px;
}

.about-leadership-content-wrap {
	position: relative;
}
.about-leadership-content-wrap::before {
	content: '';
	display: block;
	position: absolute;
	left: 15px;
	right: -15px;
	top: 0;
	bottom: 0;
	background-color: #f7f7f7;
	border-radius: 15px;
}
.partner-2 .about-leadership-content-wrap::before {
	left: -15px;
	right: 15px;
}
.about-leadership-content {
	background-color: #fff;
	position: relative;
	padding: 30px 30px;
	border: 1px solid #dddfe1;
	border-radius: 15px;
}
.about-leadership-title {
	font-size: 30px;
	font-weight: 600;
	color: #1977cc;
	margin-bottom: 20px;
	line-height: 1.3;
}
.fxt-leaders-container {
	padding: 0 50px;
}
@media (max-width: 991px) {
	.about-leadership-image {
		margin-left: 0px !important;
		max-width: 300px;
		max-height: 300px;
	}
	.about-leadership-image.tow {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.about-leadership-content-wrap.tow {
		margin-left: 0px !important;
		right: 0px;
	}
	.fxt-leaders-container {
		padding: 0 20px;
	}
	about-leadership-image img {
		max-width: 300px;
		max-height: 300px;
		margin-left: 0 !important;
	}
}
@media (max-width: 776px) {
	.fxt-leaders-container {
		justify-content: center;
	}
	.about-leadership-image.tow,
	.about-leadership-image {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.about-leadership-content-wrap.tow,
	.about-leadership-content-wrap {
		margin-left: unset !important;
		right: unset !important;
	}
}
@media (max-width: 576px) {
	.about-leadership-image {
		margin-left: auto !important;
		margin-right: auto !important;
		max-height: 300px !important;
		max-width: 300px !important;
	}
	.about-leadership-image img {
		max-height: 300px;
	}
	.about-leadership-image {
		min-height: 360px;
		max-height: 370px;
	}
}
</style>