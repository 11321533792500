<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					What Makes Genixbit the Prominent MVP Development Company
				</h2>
				<p>
					MVP development brings to the table several benefits that will save you time and resources
					in the long run.
				</p>
			</div>
			<div class="align-items-center" style="display:flex;">
				<div class="col-lg-5 col-md-12 col-sm-12 col-12 col-left d-none d-lg-block" style="position:relative;padding:0">
					<img
						class="lazy box-shadow rounded10  why-img-cont"
						alt=""
						src="../../../assets/images/why-choose-mvp-development.jpg"
						style="width:100%"
					/>
				</div>
				<div class="col-lg-8 col-md-12 col-sm-12 col-12 col-right mb-40">
					<div class="mvp-why-choose-dev-box-wrap box-shadow rounded10">
						<div class="mvp-why-choose-dev-box">
							<div class="mvp-why-choose-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g id="Outline">
										<path
											d="M48.3,15.8c0-1.4-1.1-2.5-2.5-2.5H45V2.5C45,1.1,43.9,0,42.5,0h-35C6.1,0,5,1.1,5,2.5v10.8H4.2c-1.4,0-2.5,1.1-2.5,2.5V45
                           H0v2.5C0,48.9,1.1,50,2.5,50h45c1.4,0,2.5-1.1,2.5-2.5V45h-1.7V15.8z M6.7,2.5C6.7,2,7,1.7,7.5,1.7h35c0.5,0,0.8,0.4,0.8,0.8v4.2
                           H6.7V2.5z M43.3,8.3v32.5c0,0.5-0.4,0.8-0.8,0.8h-35c-0.5,0-0.8-0.4-0.8-0.8V8.3H43.3z M3.3,15.8c0-0.5,0.4-0.8,0.8-0.8H5v25.8
                           c0,1.4,1.1,2.5,2.5,2.5h35c1.4,0,2.5-1.1,2.5-2.5V15h0.8c0.5,0,0.8,0.4,0.8,0.8V45H3.3V15.8z M48.3,47.5c0,0.5-0.4,0.8-0.8,0.8h-45
                           c-0.5,0-0.8-0.4-0.8-0.8v-0.8h46.7V47.5z"
										></path>
										<path
											d="M9.8,3.6C9.7,3.5,9.6,3.4,9.5,3.4c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.3-0.3,0.9,0,1.2
                           c0.3,0.3,0.9,0.3,1.2,0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0-0.6C9.9,3.7,9.8,3.7,9.8,3.6z"
										></path>
										<path
											d="M13.1,3.6c-0.3-0.3-0.8-0.3-1.2,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.2,0.6
                           c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6c0-0.1,0-0.2-0.1-0.3C13.2,3.7,13.2,3.7,13.1,3.6z"
										></path>
										<path
											d="M16.4,3.6c-0.3-0.3-0.9-0.3-1.2,0C15.1,3.7,15,3.9,15,4.2C15,4.6,15.4,5,15.8,5c0.2,0,0.4-0.1,0.6-0.2
                           c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0-0.6C16.6,3.7,16.5,3.7,16.4,3.6z"
										></path>
										<path
											d="M10.9,25l-1.5,1.5l3.3,3.3l1.5-1.5c0.4,0.2,0.7,0.3,1.1,0.5v2.1h4.6v-2.1c0.4-0.1,0.7-0.3,1.1-0.5l1.5,1.5l3.3-3.3L24.2,25
                           c0.2-0.4,0.3-0.7,0.5-1.1h2.1v-2.6l0.2,0.4l3-1.7l-0.7-1.3c0.3-0.3,0.5-0.7,0.6-1.1h1.5v-3.5h-1.5c-0.2-0.4-0.4-0.8-0.6-1.1
                           l0.7-1.3l-3-1.7l-0.7,1.3c-0.4-0.1-0.9-0.1-1.3,0l-0.7-1.3l-3,1.7L22,13c-0.3,0.3-0.5,0.7-0.6,1.1h-1.5v-1.7h-4.6v2.1
                           c-0.4,0.1-0.7,0.3-1.1,0.5l-1.5-1.5l-3.3,3.3l1.5,1.5c-0.2,0.4-0.3,0.7-0.5,1.1H8.3v4.6h2.1C10.6,24.3,10.7,24.6,10.9,25z
                           M22.2,15.2l0.1-0.4c0.2-0.5,0.4-1,0.8-1.4l0.3-0.3l-0.6-1l1-0.6l0.6,1l0.4-0.1c0.5-0.1,1.1-0.1,1.6,0l0.4,0.1l0.6-1l1,0.6l-0.6,1
                           l0.3,0.3c0.4,0.4,0.6,0.9,0.8,1.4l0.1,0.4h1.2v1.2h-1.2L29,16.8c-0.2,0.5-0.4,1-0.8,1.4l-0.3,0.3l0.6,1l-1,0.6l-0.6-1l-0.4,0.1
                           c-0.5,0.1-1.1,0.1-1.6,0l-0.4-0.1l-0.6,1l-1-0.6l0.6-1l-0.3-0.3c-0.4-0.4-0.6-0.9-0.8-1.4l-0.1-0.4H21v-1.2H22.2z M9.5,20.4h1.8
                           l0.1-0.4c0.1-0.6,0.4-1.1,0.7-1.6l0.2-0.4L11,16.7l1.6-1.6l1.3,1.3l0.4-0.2c0.5-0.3,1.1-0.5,1.6-0.7l0.4-0.1v-1.8h2.3v1.8l0.4,0.1
                           c0.2,0.1,0.5,0.1,0.7,0.2v1.9h1.5c0.2,0.4,0.4,0.8,0.6,1.1l-0.4,0.7c-1.3-2.3-4.1-3.1-6.4-1.9s-3.1,4.1-1.9,6.4s4.1,3.1,6.4,1.9
                           c1.9-1,2.9-3.3,2.3-5.4l2.2,1.3l0.7-1.3c0.2,0,0.4,0,0.6,0l0,0v2.3h-1.8l-0.1,0.4c-0.1,0.6-0.4,1.1-0.7,1.6l-0.2,0.4l1.3,1.3
                           l-1.6,1.6l-1.3-1.3L20.8,27c-0.5,0.3-1.1,0.5-1.6,0.7l-0.4,0.1v1.8h-2.3v-1.8L16,27.7c-0.6-0.1-1.1-0.4-1.6-0.7L14,26.8l-1.3,1.3
                           L11,26.5l1.3-1.3l-0.2-0.4c-0.3-0.5-0.5-1.1-0.7-1.6l-0.1-0.4H9.5V20.4z M21,21.6c0,1.9-1.5,3.5-3.5,3.5s-3.5-1.5-3.5-3.5
                           s1.5-3.5,3.5-3.5C19.5,18.1,21,19.7,21,21.6z"
										></path>
										<path
											d="M25.6,18.1c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3S24.4,18.1,25.6,18.1z M25.6,14.6
                           c0.6,0,1.2,0.5,1.2,1.2S26.3,17,25.6,17c-0.6,0-1.2-0.5-1.2-1.2S25,14.6,25.6,14.6z"
										></path>
									</g>
									<path
										d="M33.6,39.7c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8s-8,3.6-8,8C25.6,36.1,29.2,39.7,33.6,39.7z M33.6,24.7c3.8,0,6.9,3.1,6.9,6.9
                        s-3.1,6.9-6.9,6.9c-3.8,0-6.9-3.1-6.9-6.9C26.7,27.8,29.8,24.7,33.6,24.7z"
									></path>
									<path
										d="M30.8,32.2l-0.9,0.4l1,1.9c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1l6-5.8L37,28.2l-5.6,5.3
                        L30.8,32.2z"
									></path>
								</svg>
							</div>
							<div class="mvp-why-choose-dev-content">
								<h6>Test before launch</h6>
								<p>
									Know whether your product idea is going the right direction by testing it before
									launch.
								</p>
							</div>
						</div>
						<div class="mvp-why-choose-dev-box">
							<div class="mvp-why-choose-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path d="M4.8,16.1h3.2v1.6H4.8V16.1z"></path>
									<path
										d="M47.6,12.9c1.3,0,2.4-1.1,2.4-2.4V2.4C50,1.1,48.9,0,47.6,0H36.3c-1.3,0-2.4,1.1-2.4,2.4v8.1c0,1.3,1.1,2.4,2.4,2.4h2.4v1.6
                        h-1.6v1.6h4v8.1h-3.3c-0.6-0.6-1.4-1.1-2.3-1.4c0-0.1,0-0.2,0-0.2c0-3.6-2.9-6.5-6.5-6.5c-2.2,0-4.3,1.1-5.4,3
                        c-0.8-0.4-1.7-0.6-2.6-0.6c-2.7,0-5.1,1.7-6,4.2c-0.6,0.1-1.2,0.4-1.7,0.7h-6V21h3.2c1.3,0,2.4-1.1,2.4-2.4V2.4
                        c0-1.3-1.1-2.4-2.4-2.4H2.4C1.1,0,0,1.1,0,2.4v16.1C0,19.9,1.1,21,2.4,21h3.2v4h5.9c-0.3,0.5-0.6,1-0.8,1.6H7.3v8.9H4
                        c-1.3,0-2.4,1.1-2.4,2.4v7.3H0v2.4C0,48.9,1.1,50,2.4,50h12.9c1.3,0,2.4-1.1,2.4-2.4v-2.4h-1.6v-7.3c0-1.3-1.1-2.4-2.4-2.4H8.9v-7.3
                        h1.6c0,3.1,2.5,5.6,5.6,5.6h17.7c2.8,0,5.2-2.1,5.6-4.8h3.3v6.5h-5.6V50H50V35.5h-5.6v-8.1h-4.9c-0.1-0.6-0.3-1.1-0.5-1.6h3.8v-9.7
                        h4v-1.6h-1.6v-1.6H47.6z M11.3,12.9H1.6V4.8h9.7V12.9z M2.4,1.6h8.1c0.4,0,0.8,0.4,0.8,0.8v0.8H1.6V2.4C1.6,2,2,1.6,2.4,1.6z
                        M1.6,18.5v-4h9.7v4c0,0.4-0.4,0.8-0.8,0.8H2.4C2,19.4,1.6,19,1.6,18.5z M16.1,47.6c0,0.4-0.4,0.8-0.8,0.8H2.4
                        c-0.4,0-0.8-0.4-0.8-0.8v-0.8h14.5V47.6z M14.5,37.9v7.3H3.2v-7.3c0-0.4,0.4-0.8,0.8-0.8h9.7C14.2,37.1,14.5,37.5,14.5,37.9z
                        M38.7,48.4v-4.8h9.7v4.8H38.7z M48.4,41.9h-9.7v-4.8h9.7V41.9z M33.9,32.3H16.1c-2.2,0-4-1.8-4-4c0-2,1.5-3.8,3.5-4l0.5-0.1
                        l0.1-0.5c0.6-2.1,2.5-3.5,4.6-3.5c0.9,0,1.8,0.3,2.5,0.7l0.8,0.5l0.4-0.8c0.8-1.7,2.5-2.8,4.4-2.8c2.7,0,4.8,2.2,4.8,4.8
                        c0,0.2,0,0.5-0.1,0.7l-0.1,0.8l0.8,0.1c1.9,0.3,3.4,2,3.4,4C37.9,30.4,36.1,32.3,33.9,32.3z M36.3,1.6h11.3c0.4,0,0.8,0.4,0.8,0.8
                        v5.6H35.5V2.4C35.5,2,35.8,1.6,36.3,1.6z M35.5,10.5V9.7h12.9v0.8c0,0.4-0.4,0.8-0.8,0.8H36.3C35.8,11.3,35.5,10.9,35.5,10.5z
                        M43.5,14.5h-3.2v-1.6h3.2V14.5z"
									></path>
									<path d="M40.3,38.7h1.6v1.6h-1.6V38.7z"></path>
									<path d="M45.2,45.2h1.6v1.6h-1.6V45.2z"></path>
									<path d="M43.5,38.7h3.2v1.6h-3.2V38.7z"></path>
									<path d="M40.3,45.2h3.2v1.6h-3.2V45.2z"></path>
								</svg>
							</div>
							<div class="mvp-why-choose-dev-content">
								<h6>Deploy on production server</h6>
								<p>
									Mimic the real life scenario of app usage by deploying the app on production
									server.
								</p>
							</div>
						</div>
						<div class="mvp-why-choose-dev-box">
							<div class="mvp-why-choose-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<circle cx="1" cy="48.9" r="1"></circle>
										</g>
									</g>
									<g>
										<g>
											<circle cx="26.1" cy="24" r="1"></circle>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M38.9,11.5c-0.8-0.8-1.8-1.2-3-1.2c-1.1,0-2.2,0.4-3,1.2c-1.6,1.6-1.6,4.3,0,5.9c0.8,0.8,1.9,1.2,3,1.2
                              c1.1,0,2.1-0.4,3-1.2C40.5,15.8,40.5,13.2,38.9,11.5z M37.5,16c-0.9,0.9-2.3,0.9-3.1,0c-0.9-0.9-0.9-2.3,0-3.1
                              c0.4-0.4,1-0.6,1.6-0.6c0.6,0,1.1,0.2,1.6,0.6C38.4,13.8,38.4,15.2,37.5,16z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M12.2,40.2c-0.5-0.2-1.1,0-1.3,0.4L9.8,43c-0.5,1-1.5,1.7-2.7,1.8l-1.4,0.1c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.2-0.2-0.5
                              l0.1-1.4C5.3,41.7,6,40.7,7,40.2L9.3,39c0.5-0.2,0.7-0.8,0.4-1.3c-0.2-0.5-0.8-0.7-1.3-0.4l-2.3,1.2c-1.6,0.8-2.7,2.5-2.9,4.3
                              l-0.1,1.4c-0.1,0.7,0.2,1.5,0.7,2c0.5,0.5,1.1,0.7,1.8,0.7c0.1,0,0.1,0,0.2,0l1.4-0.1c1.8-0.1,3.5-1.2,4.3-2.9l1.2-2.3
                              C12.9,41,12.7,40.4,12.2,40.2z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M49.3,0.8c-0.5-0.5-1.1-0.7-1.7-0.6l-0.9,0.1c-8.2,0.5-15.8,4.5-21,10.8l-2.1,2.6l-5.6-0.2c0,0,0,0,0,0
                              c-4.5,0-8.7,1.7-11.8,4.9l-5.8,5.8c-0.3,0.3-0.4,0.7-0.2,1c0.1,0.4,0.5,0.6,0.9,0.6l12.2,0.6l-0.5,0.6c-0.3,0.3-0.3,0.7-0.1,1.1
                              c0.2,0.4,0.4,0.7,0.6,1l-2.9,3.4c-0.2,0.3-0.3,0.7-0.1,1.1c0.6,1.3,1.5,2.5,2.5,3.6c1,1.1,2.3,1.9,3.6,2.6
                              c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.6-0.5,1.3-1.1,2-1.7c0.5-0.4,1-0.8,1.5-1.2c0.3,0.2,0.6,0.3,0.9,0.5
                              c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4-0.1,0.6-0.2l0.7-0.5L24,48.9c0,0.4,0.3,0.7,0.6,0.9c0.1,0,0.2,0.1,0.4,0.1
                              c0.3,0,0.5-0.1,0.7-0.3l5.8-5.8c3.2-3.2,4.9-7.3,4.9-11.8v-5.4l2.3-1.8C45.4,19.6,49.6,11.6,50,3l0-0.5C50,1.9,49.8,1.3,49.3,0.8z
                              M3.2,24l4.2-4.2c2.8-2.8,6.5-4.3,10.4-4.3l4.1,0.1l-7.3,9L3.2,24z M18,36.5c-0.5,0.4-1,0.9-1.5,1.3c-0.9-0.5-1.8-1.1-2.5-1.9
                              c-0.7-0.7-1.3-1.6-1.8-2.5l2.1-2.5c0.6,0.9,1.3,1.7,2.1,2.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.8,0.8,1.7,1.6,2.7,2.3
                              C18.7,35.9,18.4,36.2,18,36.5z M34.4,32c0,3.9-1.5,7.6-4.3,10.4l-4.2,4.2l-0.6-11.4l9.1-7.1L34.4,32L34.4,32z M48,2.9
                              c-0.4,8-4.3,15.5-10.6,20.4L22,35.2c-1.3-0.7-2.5-1.6-3.6-2.6l5.2-5.2c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-5.2,5.2
                              c-1-1.1-1.8-2.2-2.5-3.4l12.6-15.5c4.8-5.9,12-9.6,19.6-10.1l0.9-0.1c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.2L48,2.9z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-why-choose-dev-content">
								<h6>Launch on marketplaces</h6>
								<p>
									We help you launch the MVP or pivot project on marketplaces and product
									communities.
								</p>
							</div>
						</div>
						<div class="mvp-why-choose-dev-box">
							<div class="mvp-why-choose-dev-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M24.2,3.5L24.2,3.5l2.9,2.9c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l-1.6-1.6c9.6,0.9,17.6,8.4,19,18
                              c0.1,0.4,0.5,0.7,0.8,0.6c0.4-0.1,0.7-0.5,0.6-0.8C45.6,11.4,37.4,3.5,27.4,2.4l1.9-1c0.4-0.2,0.5-0.7,0.3-1.1
                              c-0.2-0.4-0.6-0.5-1-0.3l-4.1,2.3C24,2.6,23.9,3.2,24.2,3.5z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M49.5,27.7l-2.3-3.3c-0.2-0.3-0.8-0.5-1.1-0.1l-2.9,2.9c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1.1,0l1.6-1.6
                              c-0.9,9.7-8.4,17.7-18.1,19.1c-0.4,0.1-0.7,0.5-0.7,0.8c0.1,0.5,0.5,0.7,0.8,0.7c10.4-1.4,18.4-9.9,19.4-20.3l1.1,1.5
                              c0.2,0.3,0.8,0.4,1.1,0.2C49.7,28.5,49.8,28.1,49.5,27.7z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M25.4,46.2L22,42.9c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l1.9,1.9C13.4,45,5.5,37.7,4,28.1
                              c-0.1-0.4-0.5-0.7-0.8-0.6c-0.4,0.1-0.7,0.5-0.6,0.8c1.5,10,9.6,17.8,19.4,19.1l-1.9,1c-0.4,0.2-0.5,0.6-0.3,1
                              c0.2,0.4,0.7,0.5,1,0.3l4.4-2.2C25.6,47.2,25.7,46.6,25.4,46.2z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M3,25.6L3,25.6L3,25.6L3,25.6c0.2,0,0.4-0.1,0.5-0.2l3.1-3.1c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0
                              L3.8,23c0.8-9.5,7.9-17.3,17.4-18.9c0.4-0.1,0.7-0.5,0.6-0.9c0-0.4-0.4-0.7-0.8-0.6c-10.2,1.7-17.7,9.9-18.7,20L1.3,21
                              c-0.2-0.4-0.7-0.5-1.1-0.2C0,21-0.1,21.5,0.1,21.8l2.3,3.5l0,0C2.5,25.5,2.8,25.6,3,25.6z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M13.5,19.8l-5.3,6.9C8.1,27,8,27.3,8.2,27.5c0.2,0.2,0.4,0.5,0.7,0.5h2.4v6.4c0,0.5,0.3,0.8,0.8,0.8h4.2
                              c0.5,0,0.8-0.3,0.8-0.8v-6.4h2.4c0.3,0,0.5-0.2,0.7-0.5c0.2-0.2,0.1-0.5-0.1-0.8l-5.3-6.9C14.4,19.4,13.8,19.4,13.5,19.8z
                              M16.2,26.4c-0.5,0-0.8,0.3-0.8,0.8v6.4h-2.7v-6.4c0-0.5-0.3-0.8-0.8-0.8h-1.7l3.8-4.9l3.8,4.9H16.2z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M22.6,35.1h4.2c0.5,0,0.8-0.3,0.8-0.8V22.9H30c0.3,0,0.5-0.2,0.7-0.5c0.2-0.2,0.1-0.5-0.1-0.8l-5.3-6.9
                              c-0.2-0.2-0.4-0.3-0.6-0.3s-0.5,0.1-0.6,0.3l-5.3,6.9c-0.2,0.2-0.2,0.5-0.1,0.8c0.2,0.2,0.4,0.5,0.7,0.5h2.4v11.4
                              C21.8,34.7,22.2,35.1,22.6,35.1z M20.9,21.4l3.8-4.9l3.8,4.9h-1.7c-0.5,0-0.8,0.3-0.8,0.8v11.4h-2.7V22.1c0-0.5-0.3-0.8-0.8-0.8
                              H20.9z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M41.2,16.7c0.2-0.2,0.1-0.5-0.1-0.8L35.8,9c-0.3-0.4-0.9-0.4-1.2,0L29.4,16c-0.2,0.2-0.2,0.5-0.1,0.8
                              s0.4,0.5,0.7,0.5h2.4v17.2c0,0.5,0.3,0.8,0.8,0.8h4.2c0.5,0,0.8-0.3,0.8-0.8V17.2h2.4C40.8,17.2,41.1,16.9,41.2,16.7z M37.3,15.7
                              c-0.5,0-0.8,0.3-0.8,0.8v17.2h-2.7V16.4c0-0.5-0.3-0.8-0.8-0.8h-1.7l3.8-4.9l3.8,4.9H37.3z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M9.6,37.6H40c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H9.6c-0.5,0-0.8,0.3-0.8,0.8S9.2,37.6,9.6,37.6z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="mvp-why-choose-dev-content">
								<h6>Continuous improvement</h6>
								<p>
									The first cut of the MVP will be made better through continuous improvement to
									features.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
}
.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.box-shadow {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
    border-radius: 10px!important;
}
.why-img-cont {
	position:relative;
	right:-50px
}
img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}
.mvp-why-choose-dev-section .col-right {
    margin-right: 0!important;
    margin-left: -9%!important;
}
.mb-40 {
    margin-bottom: 40px!important;
}
.mvp-why-choose-dev-box-wrap {
    padding: 40px 20px 40px 65px;
}
.mvp-why-choose-dev-box {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 25px 20px 15px;
    border-radius: 80px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.mvp-why-choose-dev-icon {
    width: 120px;
    height: 120px;
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
}
.mvp-why-choose-dev-icon svg path{
	fill:#000
}
.mvp-why-choose-dev-icon svg {
    width: 68px;
    height: 68px;
}
.mvp-why-choose-dev-content {
    width: calc(100% - 135px);
    margin-left: 15px;
}
.mvp-why-choose-dev-box h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.mvp-why-choose-dev-box p:last-child {
    margin-bottom: 0;
}

.mvp-why-choose-dev-box p {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
p {
    font: normal 14px/22px Montserrat,sans-serif;
    color: #6a6d6f;
}
.mvp-why-choose-dev-box:hover  svg path{
	fill:#008dd2
}
.mvp-why-choose-dev-box:hover  {
    background-color: #008dd2;
}
.mvp-why-choose-dev-box:hover h6 ,
.mvp-why-choose-dev-box:hover p {
    color: #fff;
}

</style>

