<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Delivering Perfection Since Decades</span>
				<h2 class="fxt-process-dev">What Makes Genixbit Best Flutter Development Company</h2>
				<p>
					The flutter developers at Genixbit are quick to learn and adapt with all progress
					happening in the app development technologies. Following are the traits that make us the
					best flutter app development company.
				</p>
			</div>
			<div class="row">
				<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
					<MiniCard v-bind:obj="item"></MiniCard>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import MiniCard from '../AssetComponents/MiniCard';

export default {
	components: {
		MiniCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'We Have Talents They Strive To Get',
					text: 'We have trained the qualified developers to grasp and implement the latest trends and technologies in the industry of cross-platform app development.',
					svg: `
					<svg
					x="0px"
					y="0px"
					viewBox="0 0 74 74"
					style="enable-background: new 0 0 74 74"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M35.8,21.8c-0.1,3.9,1.2,7.7,3.7,10.7c2.2,2.6,3.7,6.6,3.7,10.1v0.6c0,0.7,0.6,1.2,1.2,1.2v3.7
                     c0,0.7,0.6,1.2,1.2,1.2h1.2V53c0,0.7,0.6,1.2,1.2,1.2h7.4c0.7,0,1.2-0.6,1.2-1.2v-3.7H58c0.7,0,1.2-0.6,1.2-1.2v-3.7
                     c0.7,0,1.2-0.6,1.2-1.2v-0.6c0-3.6,1.4-7.5,3.5-9.9c2.5-2.9,3.9-6.6,3.9-10.5c0-4.4-1.7-8.5-4.9-11.6C59.8,7.6,55.6,6,51.2,6.2
                     C42.9,6.5,36,13.5,35.8,21.8z M54.3,51.8h-4.9v-2.5h4.9V51.8z M56.7,46.9h-9.9v-2.5h9.9V46.9z M51.3,8.6c3.7-0.1,7.3,1.2,9.9,3.8
                     c2.7,2.6,4.2,6.1,4.2,9.8c0,3.3-1.2,6.4-3.3,8.9c-2.4,2.8-3.9,6.9-4.1,10.9H53V37h-2.5v4.9h-4.9c-0.2-3.9-1.8-8.1-4.2-11
                     c-2.1-2.5-3.2-5.7-3.2-9C38.4,14.9,44.2,8.9,51.3,8.6z"
					></path>
					<rect x="50.6" class="st0" width="2.5" height="3.7"></rect>
					<rect x="70.3" y="19.7" class="st0" width="3.7" height="2.5"></rect>
					<rect x="29.6" y="19.7" class="st0" width="3.7" height="2.5"></rect>
					<rect
						x="36.2"
						y="4.7"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 6.3065 28.3796)"
						class="st0"
						width="2.5"
						height="3.7"
					></rect>
					<rect
						x="64.3"
						y="5.3"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 14.735 48.7293)"
						class="st0"
						width="3.7"
						height="2.5"
					></rect>
					<rect x="53" y="34.5" class="st0" width="2.5" height="2.5"></rect>
					<rect x="48.1" y="34.5" class="st0" width="2.5" height="2.5"></rect>
					<path
						class="st0"
						d="M0.7,60.3l1.8,0.9v11.6C2.5,73.4,3,74,3.7,74H16c0.7,0,1.2-0.6,1.2-1.2V61.2l1.8-0.9c0.4-0.2,0.7-0.6,0.7-1.1
                     v-7.4c0-2.5-1.9-4.6-4.2-4.9c0.4-0.8,0.5-1.6,0.5-2.5c0-3.4-2.8-6.2-6.2-6.2S3.7,41,3.7,44.4c0,0.9,0.2,1.7,0.5,2.5
                     C1.9,47.2,0,49.3,0,51.8v7.4C0,59.7,0.3,60.1,0.7,60.3z M2.5,51.8c0-1.4,1.1-2.5,2.5-2.5h1.7c0.5,0,1-0.3,1.2-0.8s0-1-0.3-1.4
                     c-0.8-0.7-1.3-1.7-1.3-2.8c0-2,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7c0,1.1-0.5,2-1.3,2.8c-0.4,0.3-0.5,0.9-0.3,1.4
                     c0.2,0.5,0.6,0.8,1.2,0.8h1.7c1.4,0,2.5,1.1,2.5,2.5v6.6l-1.8,0.9c-0.4,0.2-0.7,0.6-0.7,1.1v11.1H4.9V60.4c0-0.5-0.3-0.9-0.7-1.1
                     l-1.8-0.9V51.8z"
					></path>
					<polygon
						class="st0"
						points="8.6,58 11.1,58 11.1,53.8 12.9,52.9 11.8,50.7 9.9,51.7 8,50.7 6.8,52.9 8.6,53.8 "
					></polygon>
					<path
						class="st0"
						d="M47.5,24.4L46,29.2c-0.2,0.5,0,1.1,0.5,1.4c0.4,0.3,1,0.3,1.5,0l3.8-2.9l3.8,2.9c0.2,0.2,0.5,0.3,0.7,0.3
                     c0.2,0,0.5-0.1,0.7-0.2c0.4-0.3,0.6-0.9,0.5-1.4l-1.5-4.8l3.9-3c0.4-0.3,0.6-0.9,0.4-1.4c-0.2-0.5-0.6-0.8-1.2-0.8h-4.7L53,14.4
                     c-0.2-0.5-0.6-0.9-1.2-0.9s-1,0.4-1.2,0.9l-1.5,4.8h-4.7c-0.5,0-1,0.3-1.2,0.8c-0.2,0.5,0,1.1,0.4,1.4L47.5,24.4z M50.1,21.7
                     c0.5,0,1-0.4,1.2-0.9l0.6-1.8l0.6,1.8c0.2,0.5,0.6,0.9,1.2,0.9h2L53.9,23c-0.4,0.3-0.6,0.8-0.4,1.3l0.6,2l-1.5-1.2
                     c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-1.5,1.2l0.6-2c0.2-0.5,0-1-0.4-1.3L48,21.7L50.1,21.7L50.1,21.7z"
					></path>
					<rect x="8.6" y="33.3" class="st0" width="2.5" height="2.5"></rect>
					<rect x="8.6" y="28.4" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="28.4" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="33.3" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="38.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="43.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="48.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="53" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="58" class="st0" width="2.5" height="2.5"></rect>
					<rect x="23.4" y="62.9" class="st0" width="2.5" height="2.5"></rect>
					<path
						class="st0"
						d="M53,60.9l1.6,1.6l1.7-1.7l-3.7-3.7c-0.5-0.5-1.3-0.5-1.7,0l-3.7,3.7l1.7,1.7l1.6-1.6v4.4H53V60.9z"
					></path>
					<rect x="50.6" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="45.6" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="40.7" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="35.8" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="30.8" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="25.9" y="67.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="13.6" y="28.4" class="st0" width="2.5" height="2.5"></rect>
					<rect x="18.5" y="28.4" class="st0" width="2.5" height="2.5"></rect>
				</svg>
					`
				},
				{
					title: 'We Foster Collaboration And Creativity',
					text: 'We have the latest infrastructural facilities to create collaborative environment and nurture the creativity by facilitating them with the latest tools and technologies',
					svg: `
						<svg
					viewBox="0 0 74 74"
					style="enable-background: new 0 0 74 74"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M72.8,14.8h-2.5V3.7c0-2-1.7-3.7-3.7-3.7H51.8c-2,0-3.7,1.7-3.7,3.7v11.1h-2.5c-0.7,0-1.2,0.6-1.2,1.2
                     c0,3.4,2.8,6.2,6.2,6.2h17.3c3.4,0,6.2-2.8,6.2-6.2C74,15.4,73.4,14.8,72.8,14.8z M50.6,3.7c0-0.7,0.6-1.2,1.2-1.2h14.8
                     c0.7,0,1.2,0.6,1.2,1.2v11.1H50.6V3.7z M67.8,19.7H50.6c-1.6,0-3-1-3.5-2.5h2.3h19.7h2.3C70.8,18.7,69.4,19.7,67.8,19.7z"
					></path>
					<path
						class="st0"
						d="M64.1,4.9h-9.9c-0.7,0-1.2,0.6-1.2,1.2v4.9c0,0.7,0.6,1.2,1.2,1.2h9.9c0.7,0,1.2-0.6,1.2-1.2V6.2
                     C65.4,5.5,64.8,4.9,64.1,4.9z M62.9,9.9h-7.4V7.4h7.4V9.9z"
					></path>
					<path
						class="st0"
						d="M24.7,67.2v-6.8c0-3.4-2.8-6.2-6.2-6.2H6.2C2.8,54.3,0,57,0,60.4v7.4C0,71.2,2.8,74,6.2,74h23.4
                     c0.5,0,1-0.3,1.2-0.8s0-1-0.4-1.4L24.7,67.2z M6.2,71.5c-2,0-3.7-1.7-3.7-3.7v-7.4c0-2,1.7-3.7,3.7-3.7h12.3c2,0,3.7,1.7,3.7,3.7
                     v7.4c0,0.4,0.2,0.7,0.5,1l3.4,2.7H6.2z"
					></path>
					<rect x="6.2" y="62.9" class="st0" width="2.5" height="2.5"></rect>
					<rect x="11.1" y="62.9" class="st0" width="2.5" height="2.5"></rect>
					<rect x="16" y="62.9" class="st0" width="2.5" height="2.5"></rect>
					<path
						class="st0"
						d="M6.2,30.8h7.4c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.3-0.8,0.1-1.1l-2.8-7.6c1-0.7,1.7-1.8,1.7-3.1
                     c0-2-1.7-3.7-3.7-3.7s-3.7,1.7-3.7,3.7c0,1.3,0.7,2.4,1.7,3.1L5,29.2c-0.1,0.4-0.1,0.8,0.1,1.1C5.4,30.6,5.8,30.8,6.2,30.8z
                     M7.9,28.4l1.9-5.2l1.9,5.2H7.9z M9.9,17.2c0.7,0,1.2,0.6,1.2,1.2c0,0.6-0.5,1.1-1.1,1.2c-0.1,0-0.2,0-0.2,0
                     c-0.6-0.1-1.1-0.6-1.1-1.2C8.6,17.8,9.2,17.2,9.9,17.2z"
					></path>
					<path
						class="st0"
						d="M7,13.5c1.6-1.5,4.1-1.5,5.7,0l1.7-1.8c-2.5-2.4-6.6-2.4-9.1,0L7,13.5z"
					></path>
					<path
						class="st0"
						d="M15,9.6l1.7-1.7C12.9,4,6.7,4,2.8,7.8l1.7,1.7C7.5,6.7,12.2,6.7,15,9.6z"
					></path>
					<path
						class="st0"
						d="M17.6,5.7L19.4,4c-2.6-2.6-6-4-9.6-4s-7,1.4-9.6,4l1.7,1.7C4,3.6,6.8,2.5,9.8,2.5S15.5,3.6,17.6,5.7z"
					></path>
					<path
						class="st0"
						d="M72.8,56.7H55.5c-0.7,0-1.2,0.6-1.2,1.2v4.9v4.9v4.9c0,0.7,0.6,1.2,1.2,1.2h17.3c0.7,0,1.2-0.6,1.2-1.2v-4.9
                     v-4.9V58C74,57.3,73.4,56.7,72.8,56.7z M56.7,64.1h14.8v2.5H56.7V64.1z M56.7,59.2h14.8v2.5H56.7V59.2z M71.5,71.5H56.7v-2.5h14.8
                     V71.5z"
					></path>
					<path
						class="st0"
						d="M33.3,40.7c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9S30.6,40.7,33.3,40.7z M33.3,33.3
                     c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5S31.9,33.3,33.3,33.3z"
					></path>
					<path
						class="st0"
						d="M23.4,39.5h0.7c0,0.1,0,0.1,0.1,0.2l-0.5,0.5c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9l3.5,3.5
                     c0.5,0.5,1.3,0.5,1.7,0l0.5-0.5c0,0,0.1,0,0.2,0.1v0.7c0,0.7,0.6,1.2,1.2,1.2h4.9c0.7,0,1.2-0.6,1.2-1.2v-0.7c0.1,0,0.1,0,0.2-0.1
                     l0.5,0.5c0.2,0.2,0.5,0.4,0.9,0.4c0.3,0,0.6-0.1,0.9-0.4l3.5-3.5c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9l-0.5-0.5
                     c0,0,0-0.1,0.1-0.2h0.7c0.7,0,1.2-0.6,1.2-1.2v-4.9c0-0.7-0.6-1.2-1.2-1.2h-0.7c0-0.1,0-0.1-0.1-0.2l0.5-0.5
                     c0.2-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.9l-3.5-3.5c-0.5-0.5-1.3-0.5-1.7,0l-0.5,0.5c0,0-0.1,0-0.2-0.1v-0.7
                     c0-0.7-0.6-1.2-1.2-1.2h-4.9c-0.7,0-1.2,0.6-1.2,1.2v0.7c-0.1,0-0.1,0-0.2,0.1l-0.5-0.5c-0.2-0.2-0.5-0.4-0.9-0.4s-0.6,0.1-0.9,0.4
                     l-3.5,3.5c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.3,0.1,0.6,0.4,0.9l0.5,0.5c0,0,0,0.1-0.1,0.2h-0.7c-0.7,0-1.2,0.6-1.2,1.2v4.9
                     C22.2,38.9,22.8,39.5,23.4,39.5z M24.7,34.5H25c0.5,0,1-0.4,1.2-0.9c0.2-0.5,0.3-1,0.6-1.4c0.3-0.5,0.2-1.1-0.2-1.5l-0.3-0.3
                     l1.7-1.7l0.3,0.3c0.4,0.4,1,0.5,1.5,0.2c0.4-0.2,0.9-0.4,1.4-0.6c0.5-0.2,0.9-0.6,0.9-1.2v-0.4h2.5v0.4c0,0.5,0.4,1,0.9,1.2
                     c0.5,0.2,1,0.3,1.4,0.6c0.5,0.3,1.1,0.2,1.5-0.2l0.3-0.3l1.7,1.7L40,30.8c-0.4,0.4-0.5,1-0.2,1.5c0.2,0.4,0.4,0.9,0.6,1.4
                     c0.2,0.5,0.6,0.9,1.2,0.9h0.4V37h-0.4c-0.5,0-1,0.4-1.2,0.9c-0.2,0.5-0.3,1-0.6,1.4c-0.3,0.5-0.2,1.1,0.2,1.5l0.3,0.3l-1.7,1.7
                     l-0.3-0.3c-0.4-0.4-1-0.5-1.5-0.2c-0.4,0.2-0.9,0.4-1.4,0.6c-0.5,0.2-0.9,0.6-0.9,1.2v0.4h-2.5V44c0-0.5-0.4-1-0.9-1.2
                     c-0.5-0.2-1-0.3-1.4-0.6c-0.5-0.3-1.1-0.2-1.5,0.2l-0.3,0.3L26.3,41l0.3-0.3c0.4-0.4,0.5-1,0.2-1.5c-0.2-0.4-0.4-0.9-0.6-1.4
                     C26.1,37.4,25.6,37,25,37h-0.4V34.5z"
					></path>
					<rect x="22.2" y="6.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="27.1" y="6.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="32.1" y="6.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="37" y="6.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="41.9" y="6.2" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="25.9" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="30.8" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="50.6" class="st0" width="2.5" height="2.5"></rect>
					<rect x="48.1" y="64.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="43.2" y="64.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="38.2" y="64.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="33.3" y="64.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="28.4" y="64.1" class="st0" width="2.5" height="2.5"></rect>
					<rect x="2.5" y="49.3" class="st0" width="2.5" height="2.5"></rect>
					<rect x="2.5" y="44.4" class="st0" width="2.5" height="2.5"></rect>
					<rect x="2.5" y="39.5" class="st0" width="2.5" height="2.5"></rect>
					<rect x="2.5" y="34.5" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="45.6" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="40.7" class="st0" width="2.5" height="2.5"></rect>
					<rect x="69.1" y="35.8" class="st0" width="2.5" height="2.5"></rect>
					<path
						class="st0"
						d="M17.3,51.8H58c0.2,0,0.4-0.1,0.6-0.2c4.6-0.9,8-5,8-9.7c0-5.4-4.4-9.9-9.9-9.9c0-3.6-2.6-6.7-6.2-7.3
                     c-1.5-0.3-3-0.1-4.3,0.6c-1.8-6.2-7.5-10.6-14.2-10.6c-8.2,0-14.8,6.6-14.8,14.8c0,0.8,0.1,1.7,0.2,2.5C12,32,7.4,36.4,7.4,41.9
                     C7.4,47.4,11.8,51.8,17.3,51.8z M17.3,34.5c0.5,0,1,0.1,1.6,0.2c0.4,0.1,0.9,0,1.2-0.4c0.3-0.3,0.4-0.8,0.3-1.2
                     c-0.3-1.2-0.5-2.3-0.5-3.5c0-6.8,5.5-12.3,12.3-12.3c6,0,11.1,4.3,12.1,10.3c0.1,0.4,0.4,0.8,0.8,1c0.4,0.2,0.9,0.1,1.2-0.2
                     c0.9-0.8,2-1.2,3.1-1.2c0.3,0,0.5,0,0.8,0.1c2.4,0.4,4.1,2.4,4.1,4.8c0,0.3,0,0.7-0.1,1.1c-0.1,0.4,0,0.8,0.3,1.1
                     c0.3,0.3,0.7,0.4,1.1,0.4c0.5-0.1,0.8-0.1,1.2-0.1c4.1,0,7.4,3.3,7.4,7.4c0,3.7-2.7,6.8-6.4,7.3c-0.1,0-0.2,0-0.3,0.1H17.3
                     c-4.1,0-7.4-3.3-7.4-7.4S13.2,34.5,17.3,34.5z"
					></path>
				</svg>
					`
				},
				{
					title: 'We Have Knowledge of Latest Frameworks',
					text: 'Our expertise on the latest cross-platform frameworks and technologies enable us to remain competitive.',
					svg: `
						<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M58.1,48.4h-3.2v20.9h3.2V48.4z M45.1,48.4h-3.2v20.9h3.2V48.4z M62.8,46.8v9.7c0,2.7,2.2,4.9,4.9,4.9h6.5v-3.2h-6.5
                           c-0.9,0-1.6-0.7-1.6-1.6v-9.7H62.8z M37.2,56.5v-9.7h-3.2v9.7c0,0.9-0.7,1.6-1.6,1.6h-6.5v3.2h6.5C34.9,61.4,37.2,59.1,37.2,56.5z
                           M54.9,75.8h3.2v-3.2h-3.2V75.8z M41.9,75.8h3.2v-3.2h-3.2V75.8z M80.7,58.1h-3.2v3.2h3.2V58.1z M29.2,27.6
                           c-0.1,0.5-0.1,0.9-0.1,1.5c0,2.3,1.2,4.5,3.2,5.5v0.8c0,5.3,4.3,9.7,9.7,9.7c3.4,0,6.4-1.8,8.1-4.3c1.8,2.6,4.7,4.3,8.1,4.3
                           c5.3,0,9.7-4.3,9.7-9.7v-0.8c2-1.1,3.2-3.2,3.2-5.5c0-0.5,0-0.9-0.1-1.5c2.2-1.5,3.4-3.9,3.4-6.6c0-2.6-1.2-5-3.4-6.5
                           c0.1-0.5,0.1-0.9,0.1-1.5c0-4.1-3.1-7.4-7-8c-1.2-2.8-4.1-5-7.4-5c-2.7,0-5,1.4-6.5,3.2C48.5,1.4,46.2,0,43.5,0
                           c-3.4,0-6.2,2-7.4,5c-3.9,0.5-7,3.9-7,8c0,0.5,0,1.1,0.1,1.5c-2.2,1.5-3.4,3.9-3.4,6.5C25.8,23.6,27.2,26.1,29.2,27.6z M51.6,8.1
                           c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9S59.2,13,56.5,13v3.2c4.3,0,7.8-3.5,8-7.8c1.9,0.7,3.2,2.4,3.2,4.6
                           c0,2.7-2.2,4.9-4.9,4.9v3.2c2.7,0,5.1-1.4,6.6-3.5c0.9,0.9,1.5,2.2,1.5,3.5c0,1.9-1.1,3.6-2.8,4.3l-1.5,0.7l0.8,1.5
                           c0.3,0.5,0.4,0.9,0.4,1.5c0,0.8-0.4,1.6-0.9,2.3c-1.6-3.2-4.9-5.5-8.6-5.5v3.2c3.5,0,6.5,2.8,6.5,6.5s-3.1,6.4-6.6,6.4
                           c-3.5,0-6.5-2.8-6.5-6.5V8.1z M30.5,17.6c1.5,2,3.9,3.5,6.6,3.5v-3.2c-2.7,0-4.9-2.2-4.9-4.9c0-2.2,1.4-3.9,3.2-4.6
                           c0.1,4.3,3.6,7.8,8,7.8v-3.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.3,4.9,5v27.4c0,3.5-2.8,6.5-6.5,6.5
                           c-3.6,0-6.5-2.8-6.5-6.5s2.8-6.5,6.5-6.5v-3.2c-3.8,0-7.2,2.3-8.6,5.5c-0.5-0.5-0.9-1.4-0.9-2.3c0-0.5,0.1-0.9,0.4-1.5l0.8-1.5
                           L32,25.4c-1.8-0.8-2.8-2.6-2.8-4.3C29.1,19.6,29.6,18.4,30.5,17.6z M90.3,17.7H77.4v3.2h9.7v58.1H51.6V45.1h-3.2v33.9H12.8V20.9
                           h9.7v-3.2H9.7v64.6h80.5V17.7z M93.5,32.3v3.2h3.2v53.2H56.5v1.6c0,3.5-2.8,6.5-6.5,6.5c-3.6,0-6.5-2.8-6.5-6.5v-1.6H3.2V35.5h3.2
                           v-3.2H0v59.6h40.4c0.8,4.6,4.7,8.1,9.6,8.1c4.9,0,8.8-3.5,9.6-8.1H100V32.3H93.5z M22.6,61.4v-3.2h-3.2v3.2H22.6z"
							></path>
						</g>
					</g>
				</svg>
					`
				},
				{
					title: 'Our Prolonged Experiences',
					text: 'Our prolonged exposure in the industry has enabled us to deal with any scale of complexities without affecting the quality.',
					svg: `
						<svg
    				x="0px"
    				y="0px"
    				viewBox="0 0 100 100"
    				style="enable-background: new 0 0 100 100"
    				xml:space="preserve"
    			>
    				<g>
    					<g>
    						<path
    							d="M26.1,68.8c0.5,0.3,1.1,0.4,1.5,0.4c0.7,0,1.4-0.3,1.8-0.8c0.4-0.4,0.7-1.2,0.5-2.4l-0.5-3.4l2.4-2.4
                           c0.8-0.8,1.2-1.9,0.8-2.9c-0.3-1-1.2-1.6-2.3-1.8L26.9,55l-1.5-3c-0.5-1.1-1.4-1.6-2.4-1.6c-0.9,0-1.9,0.7-2.4,1.6L19,55.1
                           l-3.4,0.5c-1.2,0.1-2,0.8-2.3,1.8c-0.3,1,0,1.9,0.8,2.9l2.4,2.4L16.2,66c-0.3,1.2,0.1,2,0.5,2.4c0.8,1,2,1.1,3.2,0.4l3.1-1.6
                           L26.1,68.8z M19.4,65.6l0.7-4.1l-3-2.9l4.1-0.5l1.9-3.7l1.9,3.7l4.1,0.5l-3.1,2.9l0.7,4.1L23,63.7L19.4,65.6z M14.7,47.2l-0.5-3
                           l2-2.2c0.8-0.8,1.1-1.8,0.8-2.7c-0.3-0.8-1.1-1.5-2.2-1.6l-2.7-0.3l-1.2-2.7c-0.7-1.5-1.8-1.6-2.3-1.6c-0.4,0-1.6,0.1-2.3,1.6
                           l-1.2,2.7l-2.8,0.4c-1.1,0.1-1.9,0.8-2.2,1.6c-0.3,1,0,1.9,0.8,2.7l2,2.2l-0.5,3c-0.1,1.2,0.1,1.9,0.5,2.3c0.7,0.8,1.9,1,3.1,0.4
                           l2.4-1.4l2.4,1.4c0.5,0.3,0.9,0.4,1.5,0.4c0.7,0,1.2-0.3,1.8-0.8C14.6,49.1,14.8,48.4,14.7,47.2z M10.9,43.1l0.5,3.4l-2.8-1.6
                           l-2.8,1.6l0.5-3.4l-2.4-2.4l3.2-0.5l1.4-3l1.4,3l3.2,0.5L10.9,43.1z M84.3,55.6L81,55.1L79.3,52c-0.5-1.1-1.4-1.6-2.4-1.6
                           C76,50.3,75,51,74.5,52L73,55.1l-3.4,0.5c-1.2,0.1-2,0.8-2.3,1.8c-0.3,1,0,1.9,0.8,2.9l2.4,2.4L70,66.1c-0.3,1.2,0.1,2,0.5,2.4
                           c0.8,1,2,1.1,3.4,0.4l3.1-1.6l3.1,1.6c0.5,0.3,1.1,0.4,1.5,0.4c0.7,0,1.4-0.3,1.8-0.8c0.4-0.4,0.7-1.2,0.5-2.4l-0.5-3.4l2.4-2.4
                           c0.8-0.8,1.2-1.9,0.8-2.9C86.4,56.5,85.4,55.8,84.3,55.6z M79.9,61.5l0.7,4.1l-3.7-1.9l-3.7,1.9l0.7-4.1l-3-2.9l4.1-0.5l1.9-3.7
                           l1.9,3.7l4.1,0.5L79.9,61.5z M99.9,39.5c-0.3-1-1.1-1.5-2.2-1.6l-2.8-0.4l-1.2-2.7c-0.7-1.5-1.8-1.6-2.3-1.6
                           c-0.4,0-1.5,0.1-2.3,1.6l-1.2,2.7l-2.7,0.4c-1.1,0.1-1.9,0.8-2.2,1.6c-0.3,1,0,1.9,0.8,2.7l2,2.2l-0.5,3c-0.1,1.2,0.1,1.9,0.5,2.3
                           c0.7,0.8,1.9,1,3.1,0.4l2.4-1.4l2.4,1.4c0.5,0.3,0.9,0.4,1.5,0.4c0.7,0,1.2-0.3,1.8-0.8c0.4-0.4,0.7-1.1,0.5-2.3l-0.5-3l2-2.2
                           C99.9,41.4,100.2,40.3,99.9,39.5z M93.7,43.1l0.5,3.4l-2.8-1.6l-2.8,1.6l0.5-3.4l-2.4-2.4l3.2-0.5l1.4-3l1.4,3l3.2,0.5L93.7,43.1z
                           M50,41.6c7.3,0,13.3-6,13.3-13.3C63.3,21,57.3,15,50,15s-13.3,6-13.3,13.3C36.7,35.7,42.7,41.6,50,41.6z M50,18.1
                           c5.5,0,10.1,4.6,10.1,10.2c0,5.6-4.6,10.2-10.1,10.2c-5.5,0-10.1-4.6-10.1-10.2C39.9,22.7,44.5,18.1,50,18.1z M58.8,74.9l4.1-4.2
                           c1.1-1.1,1.5-2.3,1.1-3.5c-0.4-1.1-1.5-1.9-2.8-2.2l-5.7-0.8l-2.6-5.4c-0.9-2-2.3-2.2-2.8-2.2s-2,0.3-2.8,2.2l-2.6,5.4L38.9,65
                           c-1.5,0.3-2.6,1-2.8,2.2c-0.4,1.1,0,2.4,1.1,3.5l4.1,4.2l-0.9,6c-0.3,1.6,0.3,2.6,0.7,3.1c0.9,1.1,2.4,1.2,4.1,0.4l5-2.7l5,2.7
                           c0.7,0.4,1.4,0.5,2,0.5c0.8,0,1.6-0.3,2-1c0.4-0.5,0.9-1.5,0.7-3.1L58.8,74.9z M55.4,73.9l1.2,7.5c0,0.1,0,0.1,0,0.3h-0.1L50,77.9
                           l-6.5,3.7h-0.1c0-0.1,0-0.1,0-0.3l1.2-7.5l-5.3-5.3c0-0.1-0.1-0.3-0.1-0.3c0.1,0,0.1,0,0.3,0l7.3-1.1l3.2-7.1c0,0,0-0.1,0.1-0.1
                           c0,0,0,0.1,0.1,0.1l3.2,6.9l7.3,1.1c0.1,0,0.1,0,0.3,0c0,0.1-0.1,0.1-0.3,0.3L55.4,73.9z M28.8,51.7c0.8-1.5,1.8-2.9,3-4.1
                           c2.4-2.6,5.7-4.4,9.7-5.3c-1.1-0.7-2.2-1.5-3.1-2.4c-5.4,1.8-9.1,4.9-11.5,8.8c0.5,0.5,1.1,1.1,1.4,1.9L28.8,51.7z M25.9,73
                           c-0.5,0-0.9-0.4-0.9-0.8v-0.5c-0.1,0-0.3-0.1-0.4-0.1L23,70.7l-1.2,0.7v1c0,2.2,1.9,3.8,4.1,3.8h11.9V76l-3-3H25.9z M58.4,42.5
                           c3.9,1,7.2,2.7,9.6,5.3c1.2,1.2,2.2,2.6,3.1,4.2l0.7-1.4c0.3-0.7,0.7-1.2,1.2-1.6c-2.6-3.8-6.2-7.1-11.5-8.8
                           C60.5,41,59.5,41.8,58.4,42.5z M75.4,71.5c-0.1,0-0.3,0.1-0.4,0.1v0.5c0,0.3-0.4,0.7-0.9,0.7H65l-2.8,3V76h12
                           c2.2,0,4.1-1.8,4.1-3.8v-1L77,70.6L75.4,71.5z"
    						></path>
    					</g>
    				</g>
    			</svg>
					`
				},
				{
					title: 'Our Project Management',
					text: 'We use the latest project management software, and our trained & experienced project managers make active participation of clients possible.',
					svg: `
						<svg
    				x="0px"
    				y="0px"
    				viewBox="0 0 74 74"
    				style="enable-background: new 0 0 74 74"
    				xml:space="preserve"
    			>
    				<g>
    					<g>
    						<rect x="20.4" y="4" class="st0" width="2.3" height="2.3"></rect>
    					</g>
    					<g>
    						<rect x="15.7" y="4" class="st0" width="2.3" height="2.3"></rect>
    					</g>
    					<g>
    						<rect x="25.1" y="4" class="st0" width="2.3" height="2.3"></rect>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M35.7,32.4c0.6-0.1,1-0.6,1-1.2v-5.1c0-0.6-0.5-1-1-1.2l-3-0.3c-0.1-0.3-0.3-0.8-0.5-1.2l1.8-2.3
                           c0.3-0.5,0.3-1.2-0.1-1.5l-3.6-3.6c-0.5-0.5-1-0.5-1.5-0.1l-2.3,1.8c-0.3-0.2-0.7-0.3-1.2-0.5l-0.2-2.9c-0.1-0.6-0.6-1-1.2-1H19
                           c-0.6,0-1,0.5-1.2,1l-0.3,3c-0.3,0.1-0.8,0.3-1.2,0.5L14,16.1c-0.5-0.3-1.2-0.3-1.5,0.1L9,19.8c-0.5,0.5-0.5,1-0.1,1.5l1.8,2.3
                           c-0.2,0.3-0.3,0.7-0.5,1.2l-3,0.3c-0.6,0.1-1,0.6-1,1.2v5.1c0,0.6,0.5,1,1,1.2l3,0.3c0.1,0.3,0.3,0.8,0.5,1.2l-1.8,2.3
                           c-0.3,0.5-0.3,1.2,0.1,1.5l3.6,3.6c0.5,0.5,1,0.5,1.5,0.1l2.3-1.8c0.3,0.2,0.7,0.3,1.2,0.5l0.3,3c0.1,0.6,0.6,1,1.2,1h5.1
                           c0.6,0,1-0.5,1.2-1l0.3-3c0.3-0.1,0.8-0.3,1.2-0.5l2.3,1.8c0.5,0.3,1.2,0.3,1.5-0.1l3.6-3.6c0.5-0.5,0.5-1,0.1-1.5L32.3,34
                           c0.2-0.3,0.3-0.7,0.5-1.2L35.7,32.4z M31.8,30.5c-0.5,0-0.8,0.3-1,0.8c-0.2,0.7-0.5,1.4-0.8,2.1c-0.2,0.5-0.2,0.9,0.1,1.3l1.7,2.1
                           l-2.1,2.1l-2.2-1.6c-0.3-0.3-0.9-0.3-1.3-0.1c-0.7,0.3-1.4,0.7-2.1,0.8c-0.5,0.1-0.8,0.5-0.8,1L23,41.6h-3l-0.3-2.7
                           c0-0.5-0.3-0.8-0.8-1c-0.7-0.2-1.4-0.5-2.1-0.8c-0.5-0.2-0.9-0.2-1.3,0.1l-2.1,1.7l-2.1-2.1l1.7-2.1c0.3-0.3,0.3-0.9,0.1-1.3
                           c-0.3-0.7-0.7-1.4-0.8-2.1c-0.1-0.5-0.5-0.8-1-0.8l-2.6-0.3v-3l2.6-0.3c0.5,0,0.8-0.3,1-0.8c0.2-0.7,0.5-1.4,0.8-2.1
                           c0.2-0.5,0.2-0.9-0.1-1.3l-1.7-2.2l2.1-2.1l2.1,1.7c0.3,0.3,0.9,0.3,1.3,0.1c0.7-0.3,1.4-0.7,2.1-0.8c0.5-0.1,0.8-0.5,0.8-1
                           l0.3-2.7h3l0.3,2.7c0,0.5,0.3,0.8,0.8,1c0.7,0.2,1.4,0.5,2.1,0.8c0.5,0.2,0.9,0.2,1.3-0.1l2.1-1.7l2.1,2.1l-1.7,2.1
                           c-0.3,0.3-0.3,0.9-0.1,1.3c0.3,0.7,0.7,1.4,0.8,2.1c0.1,0.5,0.5,0.8,1,0.8l2.6,0.3v3L31.8,30.5z"
    						></path>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M21.5,21c-4.1,0-7.6,3.5-7.6,7.6s3.5,7.6,7.6,7.6s7.6-3.5,7.6-7.6S25.8,21,21.5,21z M21.5,34.1
                           c-2.9,0-5.3-2.4-5.3-5.3s2.4-5.3,5.3-5.3s5.3,2.4,5.3,5.3S24.5,34.1,21.5,34.1z"
    						></path>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M20.4,47.7H7.5c-0.7,0-1.2,0.5-1.2,1.2S6.8,50,7.5,50h12.9c0.7,0,1.2-0.5,1.2-1.2
                           C21.5,48.3,21.1,47.7,20.4,47.7z"
    						></path>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M20.4,53.6H7.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h12.9c0.7,0,1.2-0.5,1.2-1.2
                           C21.5,54.2,21.1,53.6,20.4,53.6z"
    						></path>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M20.4,59.5H7.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h12.9c0.7,0,1.2-0.5,1.2-1.2
                           C21.5,60.1,21.1,59.5,20.4,59.5z"
    						></path>
    					</g>
    					<g>
    						<path
    							class="st0"
    							d="M72.7,38.3l-4.1-3.8l0,0l0,0C59.7,26.6,41.5,10,43.2,12V7.2c0-1.3-1-2.3-2.3-2.3h-6.9V2.2
                           c0-1.3-1-2.3-2.3-2.3H11.5c-1.3,0-2.3,1-2.3,2.3v2.5H2.3C1,4.7,0,5.8,0,7v58.5c0,1.3,1,2.3,2.3,2.3H26v4.9c0,0.7,0.5,1.2,1.2,1.2
                           h7.3l0,0l0,0h19.1l0,0l0,0h7.4c0.7,0,1.2-0.5,1.2-1.2V58.8c0-4.5-3.7-8.2-8.2-8.2h-3c-0.3,0-0.6,0.1-0.8,0.3l-6,6l-6-6
                           c-0.2-0.2-0.5-0.3-0.8-0.3h-3c-4.5,0-8.2,3.7-8.2,8.2v6.8H2.4V7.2h6.9v1.2c0,1.3,1,2.3,2.3,2.3h20.1c1.3,0,2.3-1,2.3-2.3V7.2H41
                           v4.6l-4.7-0.6c-0.8-0.1-1.4,0.7-1.3,1.4c0.6,2.2,1.6,6.8,1.7,7.2c0.2,0.5,0.1,0.2,4.3,4v10.1c-3.1,1.3-5.3,4.3-5.3,7.9
                           c0,4.7,3.8,8.6,8.5,8.6s8.5-3.8,8.5-8.6s-3.8-8.6-8.5-8.6c-0.3,0-0.6,0-0.9,0v-7.4l22.4,20.3c0.9,0.8,2.4,0.8,3.2-0.2l3.9-4.4
                           c0.5-0.5,0.6-1,0.6-1.7C73.4,39.2,73.2,38.6,72.7,38.3z M28.3,58.8c0-3.2,2.6-5.9,5.9-5.9h2.5l6.6,6.6c0.2,0.2,0.5,0.3,0.8,0.3
                           l0,0c0.3,0,0.6-0.1,0.8-0.3l6.6-6.6H54c3.2,0,5.9,2.7,5.9,5.9v12.7h-4.9v-9.5c0-0.7-0.5-1.2-1.2-1.2c-0.7,0-1.2,0.5-1.2,1.2v9.5
                           h-17v-9.5c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v9.5h-4.9V58.8z M11.5,8.3V2.2h20.1v6.1H11.5z M50.2,41.7c0,3.5-2.8,6.2-6.2,6.2
                           s-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2S50.2,38.3,50.2,41.7z M39.2,19.2l3.9-4.4l22.9,20.6l-3.9,4.4L39.2,19.2z M40.7,14.1l-2.5,2.8
                           l-0.8-3.2L40.7,14.1z M67.2,44.4L67.2,44.4l-3.3-3l3.9-4.4l3.3,3L67.2,44.4z"
    						></path>
    					</g>
    				</g>
    			</svg>
					`
				},
				{
					title: 'Our Dedicated Developers',
					text: 'Our team of dedicated developers is the real force behind our success, hence yours. Thus, they take care of your project with full attention.',
					svg: `
						<svg
    				x="0px"
    				y="0px"
    				viewBox="0 0 100 100"
    				style="enable-background: new 0 0 100 100"
    				xml:space="preserve"
    			>
    				<g>
    					<g>
    						<path
    							d="M11.4,28.4l0.1-5.1c0-3.1,2.6-5.6,5.8-5.6h3.8c3.2,0,5.8,2.5,5.8,5.6l0.1,5.1c0.8,0,1.5,0.7,1.4,1.5L28,32.5
                           c0,0.5-0.5,0.8-1.1,0.8h-0.5c0,0.3,0,0.8-0.1,1.3c-0.1,1.7-1.1,3.3-2.4,4.4l-0.1,0.1v3.2c0,0.4,0.3,0.7,0.5,0.7l2.2,0.5
                           c1.2-0.7,2.4-1.3,3.9-1.6l-4.1-1.1v-0.5c1.4-1.3,2.3-3.1,2.6-4.9c0.9-0.5,1.6-1.5,1.8-2.5l0.4-2.7c0.1-1.1-0.1-2.3-0.9-3.2
                           c-0.1-0.1-0.3-0.3-0.4-0.4v-3.5c0-4.5-3.8-8.3-8.5-8.3h-3.8c-4.7,0-8.5,3.7-8.5,8.3v3.5c-0.1,0.1-0.3,0.3-0.4,0.4
                           c-0.7,0.8-1.1,2-0.9,3.2l0.4,2.7c0.1,1.1,0.8,2,1.8,2.7c0.3,1.9,1.2,3.6,2.6,4.9V41l-1.4,0.4l-4.7,1.3c-3.5,0.8-6.1,4-6.1,7.6
                           v18.8l1.4,0.8L20.4,81v-3.1L2.7,67.6V50.3c0-2.4,1.8-4.5,4.1-5.1l4.7-1.3l2.7-0.8c0.3,0,0.5-0.4,0.5-0.7v-3.2l-0.1-0.1
                           c-1.4-1.1-2.3-2.7-2.4-4.4c0-0.5,0-1.1-0.1-1.3h-0.5c-0.5,0-0.9-0.4-1.1-0.8L10,29.8C9.9,29,10.5,28.4,11.4,28.4z M94,42.7
                           L88,41.1v-0.5c1.4-1.3,2.3-3.1,2.6-4.9c0.9-0.5,1.6-1.5,1.8-2.7l0.4-2.7c0.1-1.1-0.1-2.3-0.9-3.2c-0.1-0.1-0.3-0.3-0.4-0.4v-3.5
                           c0-4.5-3.8-8.3-8.5-8.3H79c-4.7,0-8.5,3.7-8.5,8.3v3.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.7,0.8-1.1,2-0.9,3.2l0.4,2.7
                           c0.1,1.1,0.8,2.1,1.8,2.7c0.3,1.9,1.2,3.6,2.6,4.9V41l-1.4,0.4L70,42.1c1.4,0.3,2.7,0.8,3.9,1.6l2-0.5c0.3,0,0.5-0.4,0.5-0.7v-3.2
                           l-0.1-0.1c-1.4-1.1-2.3-2.7-2.4-4.4c0-0.5-0.1-1.1-0.1-1.3h-0.7c-0.5,0-0.9-0.4-1.1-0.8L71.6,30c-0.1-0.8,0.5-1.5,1.4-1.5l0.1-5.1
                           c0-3.1,2.6-5.6,5.8-5.6h3.8c3.2,0,5.8,2.5,5.8,5.6l0.1,5.1c0.8,0,1.5,0.7,1.4,1.5l-0.4,2.7c0,0.5-0.5,0.8-1.1,0.8H88
                           c0,0.3,0,0.8-0.1,1.3c-0.1,1.7-1.1,3.3-2.4,4.4l-0.1,0.1v3.2c0,0.3,0.3,0.7,0.5,0.7l7.4,2.1c2.4,0.4,4.1,2.5,4.1,5.1v17.3
                           L79.6,77.9V81l19.1-11.1l1.4-0.8V50.3C100.1,46.7,97.6,43.5,94,42.7z M59.7,17.2v-2.7h-7.3c-2.3,0-4.5-1.2-5.5-3.2l-1.2-2.1
                           l-2.2,4.1c-0.4,0.7-1.1,1.2-2,1.2h-1.2v2.7h1.4v0.1c1.9,0,3.5-0.9,4.3-2.7v-0.1c1.6,1.7,4.1,2.7,6.5,2.7H59.7z M69.5,44.1
                           l-10.3-2.9v-2.5c2.3-2,3.6-4.8,3.9-7.9c1.4-0.5,2.4-1.7,2.7-3.3l0.7-4c0.1-1.3-0.1-2.7-1.1-3.7c-0.3-0.3-0.5-0.5-0.9-0.8l-0.1-5.9
                           C64.3,6.9,59.2,2,52.8,2h-5.7c-6.4,0-11.5,5.1-11.5,11.1l-0.1,5.9c-0.3,0.3-0.7,0.5-0.9,0.8c-0.8,0.9-1.2,2.4-1.1,3.7l0.7,4
                           c0.3,1.5,1.2,2.7,2.7,3.2c0.3,3.1,1.8,5.7,3.9,7.9V41l-3.1,0.8l-7,2c-4.9,0.9-8.2,5.3-8.2,10.1v27.7l1.4,0.8l25.4,14.8l1.4,0.8
                           l1.4-0.8l24.7-14.8l1.2-0.8V54.2C77.8,49.4,74.3,45.1,69.5,44.1z M75.1,80.4L50.4,95.2L25,80.4V54.2c0-3.7,2.6-6.8,6.2-7.6l7.2-2
                           l4.2-1.2c0.5-0.1,0.9-0.5,0.9-1.1v-1.6l0.1,0.1c1.4,0.9,3,1.5,4.7,1.5h1.4v-2.7h-1.4c-1.1,0-2.2-0.3-3.1-0.9l-1.8-1.2l-0.3-0.1
                           c-2.2-1.6-3.5-4.1-3.8-6.7c0-0.8-0.1-1.6-0.1-2h-0.8c-0.8,0-1.5-0.5-1.6-1.3l-0.7-4c-0.1-1.2,0.7-2.3,2-2.3l0.1-7.7
                           c0-4.8,3.9-8.5,8.8-8.5h5.7c4.9,0,8.8,3.9,8.8,8.5l0.1,7.7c1.2,0,2.2,1.1,2,2.3l-0.7,4c-0.1,0.8-0.8,1.3-1.6,1.3h-0.8
                           c0,0.4-0.1,1.2-0.1,2c-0.1,2.7-1.6,5.1-3.8,6.7l-0.3,0.1v9.7h2.7v-3.3l9.6,2.7c3.6,0.7,6.2,3.9,6.2,7.6L75.1,80.4z M44.3,62.4
                           c-0.5-0.5-1.4-0.5-1.9,0l-5,4.9c-0.3,0.3-0.4,0.5-0.4,0.9c0,0.3,0.1,0.7,0.4,0.9l5,4.9c0.3,0.3,0.5,0.4,0.9,0.4
                           c0.3,0,0.7-0.1,0.9-0.4c0.5-0.5,0.5-1.3,0-1.9l-4.1-4l4.1-4C44.9,63.8,44.9,63,44.3,62.4z M55.8,62.4c-0.5,0.5-0.5,1.3,0,1.9
                           l4.1,4l-4.1,4c-0.5,0.5-0.5,1.3,0,1.9c0.3,0.3,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4l5-4.9c0.3-0.3,0.4-0.5,0.4-0.9
                           c0-0.3-0.1-0.7-0.4-0.9l-5-4.9C57.2,61.9,56.3,61.9,55.8,62.4z M51.8,62.1c-0.7-0.1-1.5,0.3-1.6,0.9l-2.7,10
                           c-0.1,0.7,0.3,1.5,0.9,1.6c0.1,0,0.1,0,0.3,0c0.5,0,1.1-0.4,1.2-0.9l2.7-10C52.8,63,52.4,62.3,51.8,62.1z M50.1,50.1
                           c-10.3,0-18.5,8.3-18.5,18.2c0,10,8.2,18.2,18.5,18.2c10.3,0,18.5-8.3,18.5-18.2C68.6,58.3,60.3,50.1,50.1,50.1z M50.1,84
                           c-8.8,0-15.9-7.1-15.9-15.7c0-8.7,7.2-15.6,15.9-15.6c8.8,0,15.8,6.9,15.8,15.6C65.9,77,58.8,84,50.1,84z"
    						></path>
    					</g>
    				</g>
    			</svg>
					`
				},
				{
					title: 'Our Flexible Engagement Models',
					text: 'We offer various engagement models ranging from fixed priced to full-time, part-time, and hourly hiring with required flexibilities.',
					svg: `
						<svg
    				x="0px"
    				y="0px"
    				viewBox="0 0 100 100"
    				style="enable-background: new 0 0 100 100"
    				xml:space="preserve"
    			>
    				<g>
    					<g>
    						<path
    							d="M89.6,85.4c-0.8,0.8-0.8,2.2,0,3l1.9,1.9H62.4c-1.2,0-2.2,0.9-2.2,2.2c0,1.2,1,2.2,2.2,2.2h29l-1.9,1.9
                           c-0.8,0.8-0.8,2.2,0,3c0.4,0.4,1,0.5,1.6,0.5c0.5,0,1.2-0.1,1.6-0.5l7.1-7l-7.2-7C91.8,84.5,90.3,84.5,89.6,85.4z M72.9,62.8h18.5
                           l-1.8,1.9c-0.8,0.8-0.8,2.2,0,3c0.4,0.4,1,0.5,1.6,0.5c0.7,0,1.2-0.1,1.6-0.5l7.1-7l-7.1-7c-0.8-0.8-2.2-0.8-3,0
                           c-0.8,0.8-0.8,2.2,0,3l1.9,1.9H72.9c-1.2,0-2.2,0.9-2.2,2.2C70.7,61.8,71.7,62.8,72.9,62.8z M63.1,49.1c0.1,0,0.3,0,0.3,0l1.9,1.9
                           c0.4,0.4,1,0.5,1.6,0.5c0.5,0,1.2-0.1,1.6-0.5c0.8-0.8,0.8-2.2,0-3l-7.2-7.2l-7.1,7c-0.8,0.8-0.8,2.2,0,3c0.8,0.8,2.2,0.8,3,0
                           l1.9-1.9v20.2c0,11.6-9.5,20.8-21,20.8H3.8c-1.2,0-2.2,0.9-2.2,2.2c0,1.2,1.1,2.3,2.2,2.3h33.9c14,0,25.3-11.3,25.3-25.1V49.1z
                           M31.7,62.8H49c1.2,0,2.2-0.9,2.2-2.2c0-1.2-1-2.2-2.2-2.2H31.7c-15,0-27.3-12.1-27.3-27c0-14.8,12.3-27,27.3-27
                           c15,0,27.3,12.1,27.3,27c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-0.9,2.2-2.2C63.4,14,49.2,0,31.7,0C14.3,0,0,14,0,31.3
                           C0,48.6,14.3,62.8,31.7,62.8z"
    						></path>
    					</g>
    				</g>
    			</svg>
					`
				},
				{
					title: 'Our Exclusive Support And Maintenance',
					text: 'We always welcome queries from the clients and provide them technical and general support round-the-clock by allocating a dedicated team.',
					svg: `
						<svg
    				x="0px"
    				y="0px"
    				viewBox="0 0 100 100"
    				style="enable-background: new 0 0 100 100"
    				xml:space="preserve"
    			>
    				<g>
    					<g>
    						<path
    							d="M33.3,53.7c-5.5,0-10.3,4.2-10.8,9.7c0,0.3,0.1,0.7,0.3,0.8c0.3,0.3,0.5,0.4,0.8,0.4H43c0.3,0,0.7-0.1,0.8-0.4
                           c0.3-0.3,0.3-0.5,0.3-0.8C43.6,57.9,38.9,53.7,33.3,53.7z M24.8,62.5c0.9-3.8,4.3-6.5,8.4-6.5c4,0,7.4,2.7,8.4,6.5H24.8z
                           M39.3,46.4c0-3.2-2.7-5.9-5.9-5.9c-3.2,0-5.9,2.7-5.9,5.9c0,3.2,2.7,5.9,5.9,5.9C36.6,52.4,39.3,49.7,39.3,46.4z M29.6,46.4
                           c0-2,1.6-3.8,3.8-3.8c2,0,3.8,1.6,3.8,3.8c0,2-1.6,3.8-3.8,3.8S29.6,48.5,29.6,46.4z M21.1,52.1c2.2,0,3.9-1.8,3.9-3.9v-3.4
                           c0-1.8-1.2-3.2-2.8-3.8c0.4-5.8,5.1-10.4,11.1-10.4c5.9,0,10.8,4.6,11.1,10.4c-1.6,0.5-2.8,2-2.8,3.8v3.4c0,2.2,1.8,3.9,3.9,3.9
                           c2.2,0,3.9-1.8,3.9-3.9v-3.4c0-1.8-1.2-3.4-2.8-3.8c-0.4-7-6.2-12.7-13.4-12.7S20.1,34,19.7,41c-1.6,0.4-2.8,2-2.8,3.8v3.4
                           C17.1,50.3,18.9,52.1,21.1,52.1z M47.2,44.7v3.5c0,0.9-0.8,1.8-1.8,1.8c-0.9,0-1.8-0.8-1.8-1.8v-3.4c0-0.9,0.8-1.8,1.8-1.8
                           C46.4,42.9,47.2,43.7,47.2,44.7z M19.3,44.7c0-0.9,0.8-1.8,1.8-1.8c0.9,0,1.8,0.8,1.8,1.8v3.5c0,0.9-0.8,1.8-1.8,1.8
                           c-0.9,0-1.8-0.8-1.8-1.8V44.7z M98.8,72.3H91V19.1c0-0.7-0.5-1.1-1.1-1.1H10c-0.7,0-1.1,0.5-1.1,1.1v53.3H1.1
                           C0.4,72.4,0,73,0,73.5C0,76.9,2.7,82,6.3,82h87.3c3.6,0,6.3-5.1,6.3-8.5C99.9,72.8,99.3,72.3,98.8,72.3z M11.1,20.2h77.6v52.1
                           H11.1V20.2z M93.5,79.7H6.3c-1.8,0-3.5-2.7-3.9-5.1h95.1C97,77,95.3,79.7,93.5,79.7z M43.6,78.2h11.1c0.7,0,1.1-0.5,1.1-1.1
                           c0-0.7-0.5-1.1-1.1-1.1H43.6c-0.7,0-1.1,0.5-1.1,1.1C42.5,77.8,42.9,78.2,43.6,78.2z M85.4,21.8H13.9c-0.7,0-1.1,0.5-1.1,1.1v46.1
                           c0,0.7,0.5,1.1,1.1,1.1h71.5c0.7,0,1.1-0.5,1.1-1.1V22.9C86.5,22.2,86.1,21.8,85.4,21.8z M84.3,67.7H15V23.9h69.4V67.7z
                           M52.2,61.4h16.2l0.8,0.8c0.5,0.5,1.6,0.5,2.2,0l0.5-0.5l0.9,0.4v0.7c0,0.8,0.7,1.6,1.5,1.6h1.2c0.8,0,1.6-0.7,1.6-1.6v-0.7
                           l0.9-0.4l0.5,0.5c0.5,0.5,1.6,0.5,2.2,0l0.8-0.8c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1l-0.5-0.5l0.4-0.9h0.8
                           c0.8,0,1.6-0.7,1.6-1.6v-1.2c0-0.8-0.7-1.5-1.6-1.5h-0.7l-0.4-0.9l0.5-0.5c0.5-0.5,0.5-1.6,0-2.2L81,49c-0.5-0.5-1.6-0.5-2.2,0
                           l-0.5,0.5l-0.9-0.4v-0.7c0-0.4-0.3-0.9-0.5-1.2V33.4c0-0.1,0-0.3,0-0.3s0,0,0-0.1c0-0.1-0.1-0.3-0.1-0.3L72.3,28c0,0,0,0-0.1,0
                           c-0.1-0.1-0.1-0.1-0.3-0.1h-0.1c-0.1,0-0.1,0-0.3,0c0,0,0,0-0.1,0H52.5c-0.7,0-1.1,0.5-1.1,1.1v31.6
                           C51.1,60.8,51.7,61.4,52.2,61.4z M75,49.8c0.1,0.5,0.5,0.9,1.1,1.1l1.5,0.5c0.5,0.3,1.1,0.3,1.6,0c-0.3,0.5-0.3,1.1,0,1.6l0.5,1.3
                           c0.1,0.5,0.7,0.9,1.2,1.1c-0.5,0.1-0.9,0.5-1.2,1.1l-0.5,1.3c-0.3,0.5-0.3,1.1,0,1.6c-0.4-0.3-1.1-0.3-1.6,0L76.2,60
                           c-0.5,0.1-0.9,0.7-1.1,1.2c-0.1-0.5-0.5-0.9-1.1-1.2l-1.3-0.5c-0.5-0.3-1.1-0.3-1.6,0c0.3-0.5,0.3-1.1,0-1.6l-0.5-1.3
                           c-0.1-0.5-0.7-0.9-1.2-1.1c0.5-0.1,0.9-0.5,1.2-1.1l0.5-1.3c0.3-0.5,0.3-1.1,0-1.6c0.4,0.3,1.1,0.3,1.6,0l1.3-0.5
                           C74.5,50.7,74.9,50.3,75,49.8z M72.3,31.5l0.7,0.8h-0.7V31.5z M53.4,29.7H70v3.6c0,0.3,0.1,0.5,0.3,0.8c0.3,0.3,0.5,0.3,0.8,0.3
                           h3.2v12.3c-0.8,0-1.5,0.7-1.5,1.5v0.7l-0.9,0.4l-0.5-0.5c-0.5-0.5-1.6-0.5-2.2,0l-0.8,0.8c-0.5,0.5-0.5,1.6,0,2.2l0.5,0.5
                           l-0.4,0.9h-0.7c-0.8,0-1.5,0.7-1.5,1.5v1.2c0,0.8,0.7,1.6,1.5,1.6h0.7l0.4,0.9L68.4,59h-15V29.7z M55.6,33.5H68
                           c0.7,0,1.1-0.5,1.1-1.1c0-0.7-0.5-1.1-1.1-1.1H55.6c-0.7,0-1.1,0.5-1.1,1.1C54.5,33.1,55.1,33.5,55.6,33.5z M55.6,38.4H68
                           c0.7,0,1.1-0.5,1.1-1.1c0-0.5-0.5-1.1-1.1-1.1H55.6c-0.7,0-1.1,0.5-1.1,1.1C54.5,37.8,55.1,38.4,55.6,38.4z M55.6,43.2h16.2
                           c0.7,0,1.1-0.5,1.1-1.1c0-0.7-0.5-1.1-1.1-1.1H55.6c-0.7,0-1.1,0.5-1.1,1.1C54.5,42.7,55.1,43.2,55.6,43.2z M55.6,48h16.2
                           c0.7,0,1.1-0.5,1.1-1.1c0-0.5-0.5-1.1-1.1-1.1H55.6c-0.7,0-1.1,0.5-1.1,1.1C54.5,47.5,55.1,48,55.6,48z M64.6,50.6h-8.9
                           c-0.7,0-1.1,0.5-1.1,1.1c0,0.7,0.5,1.1,1.1,1.1h8.9c0.7,0,1.1-0.5,1.1-1.1S65.2,50.6,64.6,50.6z M64.6,55.5h-8.9
                           c-0.7,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h8.9c0.7,0,1.1-0.5,1.1-1.1S65.2,55.5,64.6,55.5z"
    						></path>
    					</g>
    				</g>
    			</svg>
					`
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
