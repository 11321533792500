<template>
	<section class="padding-60 p-relative" style="background:#fff">
		<div class="container mt-5">
			<div class="title">
				<span>Customized ERP Modules</span>
				<h2 class="fxt-process-dev">Custom ERP Software Development Modules</h2>
				<p>
					Seamlessly integrate various ERP modules at the departmental level. Opt custom ERP
					implementation that is intra-departmental and applied at individual levels. Ensure
					improved forecasting and higher transparency for taking beneficial business decisions
					achieving quality organisational benchmarks.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<NormalCard v-bind:obj="item"></NormalCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import NormalCard from '../AssetComponents/NormalCard';

export default {
	components: {
		NormalCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Sales & Marketing',
					text: 'Reach out to customers on time and ensure timely delivery with quality to boost sales. Leverage targeted marketing to achieve higher conversions through focused marketing and reaching out to right people. Tap into multiple marketing channels for holistic approach towards conversion-driven sales with integrated departments.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M47.1,21.9c-0.6,0-1.2,0.5-1.2,1.2v24.5h-6.4V38c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v9.6h-6.4V35.6
                  c0-0.7-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v11.9h-7v-4.8c0-0.7-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v4.8h-6.4V41
                  c0-0.7-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v6.6H4.1v-2.4c0-0.7-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.5-1.2,1.2v3.6
                  c0,0.7,0.5,1.2,1.2,1.2h44.2c0.6,0,1.2-0.5,1.2-1.2V23.1C48.3,22.4,47.8,21.9,47.1,21.9z M46.9,8.7C46.9,8.7,46.9,8.7,46.9,8.7
                  l-6.8,0c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h4.5c-17.1,21-41.6,23-41.8,23c-0.6,0-1.1,0.6-1.1,1.2
                  c0,0.7,0.6,1.2,1.2,1.2H3c0.3,0,25.2-2,43-23.3v3.8c0,0.7,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2V9.9c0,0,0,0,0-0.1
                  c0-0.1,0-0.1,0-0.2C48.2,9.1,47.6,8.6,46.9,8.7z M3.5,14.1c0,0.1,0,0.1,0.1,0.2L7.2,23c0.3,0.6,1,0.8,1.6,0.6
                  c0.5-0.2,0.8-0.8,0.6-1.4L23.3,19l0.3,0.8c0.2,0.6,0.9,0.9,1.5,0.7c0.6-0.2,0.9-0.9,0.7-1.6l-0.7-1.8L20.8,6.4L19,1.9
                  c-0.2-0.6-0.9-0.9-1.5-0.7c-0.6,0.3-0.9,0.9-0.6,1.6L17.3,4l-12.1,8c-0.3-0.6-1.1-0.7-1.6-0.4c-0.5,0.3-0.7,0.9-0.5,1.5L3.5,14.1z
                  M18.2,6.2l4.2,10.5L8.4,20l-2.4-5.8L18.2,6.2z M24.8,9.2C24.8,9.2,24.8,9.2,24.8,9.2c0.3,0.6,1,0.8,1.6,0.5l5.6-2.9
                  c0.6-0.3,0.8-1,0.5-1.6c-0.3-0.6-1-0.8-1.6-0.5l-5.6,2.9C24.7,7.8,24.5,8.6,24.8,9.2z M31.1,12.7l-4.4,0.1c-0.6,0-1.2,0.6-1.1,1.2
                  c0,0.7,0.5,1.2,1.2,1.2h0l4.4-0.1c0.6,0,1.1-0.6,1.1-1.2C32.3,13.2,31.7,12.7,31.1,12.7z M22.7,5.2c0.3,0,0.6-0.1,0.8-0.4l2.6-2.7
                  c0.4-0.5,0.4-1.2,0-1.7c-0.5-0.5-1.2-0.5-1.6,0l-2.6,2.7c-0.4,0.5-0.4,1.2,0,1.7C22.1,5,22.4,5.2,22.7,5.2z"></path>
                </g>
              </g>
            </svg>
          `
				},
				{
					title: 'Right Purchasing',
					text: 'Keep a real-time track of sticks and get notified for every change to ensure making profitable purchase decisions. Custom ERP software development helps materials procurement and raw materials purchasing based on exact requirements with enabled quotation requests and analysis.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M49.8,28.5l-3-3.6c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.5,0.1-0.7,0.3l-3.6,3c-0.4,0.3-0.5,1-0.1,1.4c0.3,0.4,1,0.5,1.4,0.1
              l1.9-1.6c-0.5,4.6-2.6,8.8-5.7,11.9c-3.6,3.6-8.6,5.8-14.1,5.8c-4.7,0-9.1-1.6-12.5-4.4c-3.5-2.8-6-6.8-7-11.3
              c-0.1-0.5-0.6-0.9-1.2-0.7c-0.5,0.1-0.9,0.6-0.8,1.2c1.1,5,3.9,9.3,7.7,12.4c3.8,3,8.5,4.8,13.7,4.8c6.1,0,11.5-2.5,15.5-6.4
              c3.4-3.4,5.7-7.9,6.3-13l1.5,1.8c0.3,0.4,1,0.5,1.4,0.1C50.1,29.6,50.1,28.9,49.8,28.5z M26,18.3c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1
              c0.5,0,1-0.4,1-1C27,18.7,26.5,18.3,26,18.3z M33.8,30.2l3.4-10.6c0-0.1,0.1-0.2,0.1-0.4c0-0.5-0.4-1-1-1h-6.4c-0.5,0-1,0.4-1,1
              c0,0.5,0.4,1,1,1H35l-1.1,3.3H18L17,20.2h5.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1h-5.7l-1-3c-0.1-0.4-0.5-0.7-0.9-0.7l-3.7,0
              c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3c1.8,5.4,3.5,10.7,5.3,16.1c-0.7,0.1-1.3,0.5-1.8,1c-0.6,0.6-1,1.5-1,2.5c0,1,0.4,1.9,1,2.5
              c0.6,0.6,1.5,1,2.5,1c1,0,1.9-0.4,2.5-1c0.6-0.6,1-1.5,1-2.5c0-0.6-0.1-1.1-0.4-1.6h6.8c-0.2,0.5-0.4,1-0.4,1.6c0,1,0.4,1.9,1,2.5
              c0.6,0.6,1.5,1,2.5,1c1,0,1.9-0.4,2.5-1c0.6-0.6,1-1.5,1-2.5c0-1-0.4-1.9-1-2.5c-0.7-0.7-1.6-1-2.5-1H21l-0.6-1.7l12.4,0
              C33.3,30.8,33.7,30.6,33.8,30.2z M31.7,35c0.3-0.3,0.6-0.4,1-0.5c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1
              c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5s-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1C31.3,35.7,31.4,35.3,31.7,35z M20.8,35
              c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1
              c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5C20.1,34.5,20.6,34.7,20.8,35z M19.8,28.9l-1.1-3.5h14.6l-1.1,3.5H19.8z M46.4,20.8
              c-1.1-5-3.9-9.3-7.7-12.4C35,5.4,30.2,3.6,25,3.6C18.9,3.6,13.5,6,9.5,10c-3.4,3.4-5.7,7.9-6.3,13l-1.5-1.8c-0.3-0.4-1-0.5-1.4-0.1
              c-0.4,0.3-0.5,1-0.1,1.4l3,3.6c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.5-0.1,0.7-0.3l3.6-3c0.4-0.3,0.5-1,0.1-1.4c-0.3-0.4-1-0.5-1.4-0.1
              l-1.9,1.6c0.5-4.6,2.6-8.8,5.7-11.9c3.6-3.6,8.6-5.8,14.1-5.8c4.7,0,9.1,1.6,12.5,4.4c3.5,2.8,6,6.8,7,11.3c0.1,0.5,0.6,0.9,1.2,0.7
              C46.2,21.8,46.5,21.3,46.4,20.8z"></path>
            </svg>
          `
				},
				{
					title: 'Bettering QA Management',
					text: 'ERP-enabled QA helps to standardize procedures top to bottom. Validate production schedules, track inventory, manage delivery and billing for. ERP allows checking production status and stats to ensure smooth operations right from procurement to delivery while maintaining standard throughout the process.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M49.2,2.5h-5.8V0.8C43.3,0.4,43,0,42.5,0H25.8C25.4,0,25,0.4,25,0.8v1.7h-6.7c-0.5,0-0.8,0.4-0.8,0.8H0.8
                C0.4,3.3,0,3.7,0,4.2v5C0,9.6,0.4,10,0.8,10h0.8v8.3H0.8c-0.5,0-0.8,0.4-0.8,0.8v5C0,24.6,0.4,25,0.8,25h0.8v8.3H0.8
                c-0.5,0-0.8,0.4-0.8,0.8v5C0,39.6,0.4,40,0.8,40h0.8v9.2C1.7,49.6,2,50,2.5,50h46.7c0.5,0,0.8-0.4,0.8-0.8V3.3
                C50,2.9,49.6,2.5,49.2,2.5z M10.8,10h6.7v4.2h-6.7V10z M10.8,8.3V5h6.7v3.3H10.8z M1.7,5h7.5v3.3H1.7V5z M3.3,10h5.8v5
                c0,0.5,0.4,0.8,0.8,0.8h7.5v2.5H3.3V10z M10.8,25h6.7v4.2h-6.7V25z M10.8,23.3V20h6.7v3.3H10.8z M1.7,20h7.5v3.3H1.7V20z M3.3,25
                h5.8v5c0,0.5,0.4,0.8,0.8,0.8h7.5v2.5H3.3V25z M10.8,40h6.7v4.2h-6.7V40z M10.8,38.3V35h6.7v3.3H10.8z M1.7,35h7.5v3.3H1.7V35z
                M3.3,40h5.8v5c0,0.5,0.4,0.8,0.8,0.8h7.5v2.5H3.3V40z M26.7,1.7h15V5h-15V1.7z M48.3,48.3H19.2V4.2H25v1.7c0,0.5,0.4,0.8,0.8,0.8
                h16.7c0.5,0,0.8-0.4,0.8-0.8V4.2h5V48.3z"></path>
                <path d="M28.3,9.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V10
                C29.2,9.5,28.8,9.2,28.3,9.2z M27.5,13.3H25v-2.5h2.5V13.3z"></path>
                <rect x="31.7" y="9.2" width="12.5" height="1.7"></rect>
                <rect x="31.7" y="12.5" width="9.2" height="1.7"></rect>
                <path d="M28.3,19.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V20
                C29.2,19.5,28.8,19.2,28.3,19.2z M27.5,23.3H25v-2.5h2.5V23.3z"></path>
                <rect x="31.7" y="19.2" width="12.5" height="1.7"></rect>
                <rect x="31.7" y="22.5" width="9.2" height="1.7"></rect>
                <path d="M28.3,29.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V30
                C29.2,29.5,28.8,29.2,28.3,29.2z M27.5,33.3H25v-2.5h2.5V33.3z"></path>
                <rect x="31.7" y="29.2" width="12.5" height="1.7"></rect>
                <rect x="31.7" y="32.5" width="9.2" height="1.7"></rect>
                <path d="M28.3,39.2h-4.2c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V40
                C29.2,39.5,28.8,39.2,28.3,39.2z M27.5,43.3H25v-2.5h2.5V43.3z"></path>
                <rect x="31.7" y="39.2" width="12.5" height="1.7"></rect>
                <rect x="31.7" y="42.5" width="9.2" height="1.7"></rect>
              </g>
            </svg>
          `
				},
				{
					title: 'Streamlining Finance and Accounting',
					text: 'Capitalise on creating real-time account entries and ledger sharing with enabled TDS and currency management. Bring in your cash flow management right from purchase orders to sales invoice and generating P&amp;L statements at one place to stay updated with real-time financial status.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M10.4,50H3.1C1.4,50,0,48.6,0,46.9V9.4c0-0.3,0.1-0.5,0.3-0.7l8.3-8.3C8.8,0.1,9.1,0,9.4,0h22.9c1.7,0,3.1,1.4,3.1,3.1
                v13.5h-2.1V3.1c0-0.6-0.5-1-1-1H9.8L2.1,9.8v37.1c0,0.6,0.5,1,1,1h7.3V50z"></path>
                <path d="M9.4,10.4H1V8.3h7.3V1h2.1v8.3C10.4,10,10,10.4,9.4,10.4z"></path>
                <path d="M46.9,50H26c-1.7,0-3.1-1.4-3.1-3.1v-25c0-1.7,1.4-3.1,3.1-3.1h20.8c1.7,0,3.1,1.4,3.1,3.1v25C50,48.6,48.6,50,46.9,50z
                M26,20.8c-0.6,0-1,0.5-1,1v25c0,0.6,0.5,1,1,1h20.8c0.6,0,1-0.5,1-1v-25c0-0.6-0.5-1-1-1H26z"></path>
                <path d="M42.7,29.2H30.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h12.5c1.7,0,3.1,1.4,3.1,3.1C45.8,27.8,44.4,29.2,42.7,29.2z
                M30.2,25c-0.6,0-1,0.5-1,1s0.5,1,1,1h12.5c0.6,0,1-0.5,1-1s-0.5-1-1-1H30.2z"></path>
                <path d="M32.3,37.5h-2.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h2.1c1.7,0,3.1,1.4,3.1,3.1C35.4,36.1,34,37.5,32.3,37.5z
                M30.2,33.3c-0.6,0-1,0.5-1,1s0.5,1,1,1h2.1c0.6,0,1-0.5,1-1s-0.5-1-1-1H30.2z"></path>
                <path d="M42.7,37.5h-2.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h2.1c1.7,0,3.1,1.4,3.1,3.1C45.8,36.1,44.4,37.5,42.7,37.5z
                M40.6,33.3c-0.6,0-1,0.5-1,1s0.5,1,1,1h2.1c0.6,0,1-0.5,1-1s-0.5-1-1-1H40.6z"></path>
                <path d="M32.3,45.8h-2.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h2.1c1.7,0,3.1,1.4,3.1,3.1C35.4,44.4,34,45.8,32.3,45.8z
                M30.2,41.7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h2.1c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1H30.2z"></path>
                <path d="M42.7,45.8h-2.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h2.1c1.7,0,3.1,1.4,3.1,3.1C45.8,44.4,44.4,45.8,42.7,45.8z
                M40.6,41.7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h2.1c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1H40.6z"></path>
                <path d="M20.8,50h-5.2c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1h5.2v2.1h-5.2c-0.6,0-1,0.5-1,1s0.5,1,1,1h5.2V50z"></path>
                <path d="M20.8,45.8h-5.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h5.2v2.1h-5.2c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h5.2V45.8z"></path>
                <path d="M20.8,41.7h-5.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h5.2v2.1h-5.2c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1h5.2V41.7z"></path>
                <path d="M20.8,37.5h-5.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1h5.2v2.1h-5.2c-0.6,0-1,0.5-1,1s0.5,1,1,1h5.2V37.5z"></path>
                <path d="M31.3,9.4h-2.1V7.3c0-0.6-0.5-1-1-1H26V4.2h2.1c1.7,0,3.1,1.4,3.1,3.1V9.4z"></path>
                <rect x="21.9" y="4.2" width="2.1" height="2.1"></rect>
                <rect x="5.2" y="31.3" width="5.2" height="2.1"></rect>
                <rect x="5.2" y="35.4" width="5.2" height="2.1"></rect>
                <rect x="5.2" y="27.1" width="15.6" height="2.1"></rect>
                <rect x="5.2" y="22.9" width="15.6" height="2.1"></rect>
                <rect x="5.2" y="18.8" width="15.6" height="2.1"></rect>
                <rect x="5.2" y="14.6" width="26" height="2.1"></rect>
              </g>
            </svg>
          `
				},
				{
					title: 'Automating HR & Payroll',
					text: 'Modern ERP solutions help you automate and streamline multiple employee management tasks like hiring, payroll, and departmental expenses. With ERP application development you can monitor employee performance and respond to HR issues by offering solutions proactively.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M35.3,19.8c-1.1-0.9-2.6-1.5-4.9-2.2h-0.2v-5.4c1.5,0.2,2.9,0.8,4.1,1.7l1.8-2.6c-1.8-1.2-3.8-2-6-2.2V7.7h-1.8v1.4
                  c-1.8,0.2-3.4,0.6-4.6,1.7c-1.2,1.1-1.7,2.5-1.7,4.2c0,1.7,0.5,2.9,1.5,3.8c1.1,0.9,2.6,1.5,4.7,2v5.5c-1.7-0.3-3.4-1.1-5-2.5
                  l-2,2.6c2.1,1.8,4.4,2.9,7,3.1v2.2h1.8v-2.2c2-0.2,3.5-0.6,4.7-1.7c1.2-1.1,1.8-2.5,1.8-4.2C36.8,22,36.3,20.8,35.3,19.8z
                  M28.4,17.2c-1.1-0.3-1.8-0.8-2.1-1.1c-0.5-0.3-0.6-0.9-0.6-1.5s0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.6,2-0.8V17.2z M32.5,25.7
                  c-0.6,0.5-1.2,0.8-2.1,0.8v-5.2c1.2,0.3,2,0.8,2.3,1.1c0.5,0.5,0.6,0.9,0.6,1.5C33.3,24.8,33,25.2,32.5,25.7z M67.6,65.8v-4
                  c4.7-2.8,7.8-7.8,7.9-13.7c3.1-0.2,5.3-2.6,5.3-5.7c0-3.1-2.4-5.5-5.3-5.7v-7.2c0.2-0.2,0.3-0.5,0.3-0.6
                  c4.1-8.3-8.2-16.9-8.7-17.2c-0.5-0.3-1.2-0.3-1.7,0s-0.8,0.9-0.6,1.5c0.2,0.8,0,1.4-0.5,1.8c-1.7,2-6.9,3.4-14.2,3.8
                  C49.3,8.3,40.5,0,29.8,0C18.5,0,9.2,9.2,9.2,20.8c0,11.5,9.2,20.8,20.6,20.8c1.2,0,2.4-0.2,3.5-0.3c-0.2,0.5-0.2,0.9-0.2,1.4
                  c0,3.1,2.4,5.5,5.3,5.7c0,5.8,3.2,10.9,7.9,13.7v3.8c-13.9,4.5-23.5,17.5-23.5,32.3v0.3c0,0.9,0.6,1.5,1.5,1.5h64.8
                  c0.9,0,1.5-0.6,1.5-1.5v-0.3C90.8,83.5,81.4,70.5,67.6,65.8z M75.6,39.8c1.4,0.2,2.3,1.2,2.3,2.6c0,1.4-1.1,2.5-2.3,2.6V39.8z
                  M38.5,45.1c-1.2-0.2-2.3-1.2-2.3-2.6c0-1.4,1.1-2.5,2.3-2.6V45.1z M38.5,30.2v6.6c-0.8,0-3.1,0.6-3.1,0.6
                  c-1.8,0.6-3.7,0.9-5.6,0.9c-9.6,0-17.5-7.8-17.5-17.5c0-9.8,7.9-17.7,17.5-17.7c9.2,0,16.6,7.1,17.4,16c-2.9,0.2-6.7-0.3-9,3.7
                  C36.8,25.2,37,27.7,38.5,30.2z M40.9,24.3c0.8-1.2,2-2,3.5-2c6,0,18.5-0.6,22.3-5.2c0.3-0.5,0.6-0.8,0.8-1.2
                  c9.2,8,7,14.2,0.9,14.6c-13,0-14.3-0.3-24,0c-1.5-0.2-2.1-0.2-3.1-1.4C40.2,27.4,40,25.8,40.9,24.3z M41.5,47.8V32.9
                  c2.4,0.8,26.7,0.5,26.9,0.5c0.9,0,1.8-0.2,2.6-0.5c0.5-0.2,0.9-0.3,1.4-0.5v15.4c0,5.2-3.1,9.8-7.6,11.8c-3.2,1.5-5.5,1.1-8.2,1.2
                  c-2.7,0-4.6,0.2-7.5-1.1C44.7,57.8,41.5,53.2,41.5,47.8z M64.6,63.2c0,0.5,0,4.2,0,4c0,4.3-3.5,7.8-7.9,7.7
                  c-4.1-0.2-7.3-3.5-7.3-7.7v-4c2.6,0.8,4.9,0.9,7.5,0.8C59.7,63.8,61.2,64.3,64.6,63.2z M39.9,97.1V84.8c0-0.9-0.6-1.5-1.5-1.5
                  c-0.9,0-1.5,0.6-1.5,1.5v12.3H26.1c0.5-12.8,8.7-24,20.4-28.2c0.5,3.4,2.7,6.6,6.1,8.2l-3.2,14c-0.2,0.5,0,1.1,0.3,1.4l4.3,4.6
                  H39.9z M52.5,90.9L55.6,78c1.1,0,2.3,0,3.4-0.2l2.6,13.5l-4.9,4L52.5,90.9z M77.5,96.9V84.6c0-0.9-0.6-1.5-1.5-1.5
                  c-0.9,0-1.5,0.6-1.5,1.5v12.3H59.7l4.6-3.7c0.5-0.3,0.6-0.9,0.6-1.5L62,76.8c3.2-1.7,5.2-4.6,5.6-7.8c11.6,4.3,19.7,15.4,20.3,28
                  H77.5z"></path>
                </g>
              </g>
            </svg>
          `
				},
				{
					title: 'Production Planning',
					text: 'Manage production pipeline with real-time access to material procurement and production planning, production costing, and implementing process definition by forecasting in machine handling and maintenance issues. Ensure efficient capacity utilization with ERP software Implementation.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M38.4,3.7l-2.5,2.5c-0.1-0.1-0.3-0.1-0.4-0.2V2.5h-8.1v3.5c-0.1,0.1-0.3,0.1-0.4,0.2l-2.5-2.5l-5.6,5.6h-4V9.1
              c0-4.1-3.4-7.5-7.5-7.5C3.2,1.6-0.1,5-0.1,9.1v31.8c0,4.1,3.4,7.5,7.5,7.5h42.8V9.3H44L38.4,3.7z M38.4,6.2l3.2,3.2l0.2,0.2
              l-0.9,0.9C40.6,10,40.3,9.7,40,9.3c-0.7-0.9-1.6-1.6-2.5-2.2L38.4,6.2z M29.1,4.2h4.7v1.3c-0.8-0.2-1.6-0.3-2.4-0.3
              c-0.8,0-1.6,0.1-2.4,0.3V4.2z M31.3,7c0,0,0.1,0,0.1,0s0.1,0,0.1,0c2.3,0,4.4,0.9,6.1,2.3c0.6,0.5,1.1,1.1,1.6,1.7
              c1.1,1.5,1.7,3.3,1.7,5.3c0,0.1,0,0.1,0,0.2s0,0.1,0,0.2c-0.1,5.1-4.2,9.2-9.3,9.3c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
              c-2.5,0-4.8-1.1-6.5-2.7c0,0,0,0,0,0c-1.7-1.7-2.7-4-2.7-6.6c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0-2,0.7-3.8,1.7-5.3
              c0.3-0.4,0.6-0.8,1-1.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.3,0.5-0.4C26.9,7.9,29,7,31.3,7z M41.7,23.4l-3.3,3.3l-0.9-0.9
              c1.3-0.9,2.5-2,3.3-3.3L41.7,23.4z M33.8,27.4v1.3h-4.7v-1.3c0.8,0.2,1.5,0.3,2.4,0.3C32.2,27.6,33,27.5,33.8,27.4z M25.4,25.8
              l-0.9,0.9l-3.3-3.3l0.9-0.9C22.9,23.8,24.1,25,25.4,25.8z M21.3,9.3l3.2-3.2l0.9,0.9c-1,0.6-1.8,1.4-2.5,2.2
              c-0.3,0.3-0.6,0.7-0.8,1.1l-0.9-0.9L21.3,9.3z M1.6,9.1c0-3.2,2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8v27c-1.4-1.7-3.5-2.7-5.8-2.7
              S3,34.5,1.6,36.1V9.1z M48.4,46.7H7.4c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8h1.7V11h5.4l1,1
              c-0.1,0.1-0.1,0.3-0.2,0.4h-3.5v8.1H21c0.1,0.1,0.1,0.3,0.2,0.4l-2.5,2.5l5.8,5.8l2.5-2.5c0.1,0.1,0.3,0.1,0.4,0.2v3.5h8.1v-3.5
              c0.1-0.1,0.3-0.1,0.4-0.2l2.5,2.5l5.8-5.8l-2.5-2.5c0.1-0.1,0.1-0.3,0.2-0.4h3.5v-8.1h-3.5c-0.1-0.1-0.1-0.3-0.2-0.4l1-1h5.8V46.7z
              M20.5,18.8h-1.3v-4.7h1.3c-0.2,0.8-0.3,1.5-0.3,2.4C20.3,17.3,20.4,18.1,20.5,18.8z M42.3,14.1h1.3v4.7h-1.3
              c0.2-0.8,0.3-1.6,0.3-2.4C42.6,15.7,42.5,14.9,42.3,14.1z"></path>
              <path d="M31.4,24.1c4.2,0,7.6-3.4,7.6-7.6c0-2.1-0.9-4.1-2.3-5.4C36,10.3,35.1,9.7,34,9.3c-0.8-0.3-1.7-0.5-2.6-0.5
              c-0.9,0-1.8,0.2-2.6,0.5c-1,0.4-2,1-2.7,1.7c-1.4,1.4-2.3,3.3-2.3,5.4C23.8,20.7,27.2,24.1,31.4,24.1z M29.1,11
              c0.7-0.3,1.5-0.5,2.3-0.5s1.6,0.2,2.3,0.5c2.1,0.9,3.6,3,3.6,5.4c0,3.3-2.6,5.9-5.9,5.9c-3.3,0-5.9-2.7-5.9-5.9
              C25.5,14,27,11.9,29.1,11z"></path>
              <path d="M31.4,19.6c1.7,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2c-1.7,0-3.2,1.4-3.2,3.2C28.3,18.2,29.7,19.6,31.4,19.6z M31.4,15
              c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S30.6,15,31.4,15z"></path>
              <path d="M42.2,33.7H21c-1.7,0-3.1,1.2-3.1,2.6c0,1.4,1.4,2.6,3.1,2.6h21.2c1.7,0,3.1-1.2,3.1-2.6C45.4,34.9,43.9,33.7,42.2,33.7z
              M42.2,37.3H21c-0.8,0-1.4-0.5-1.4-0.9c0-0.4,0.6-0.9,1.4-0.9h21.2c0.8,0,1.4,0.5,1.4,0.9C43.6,36.8,43.1,37.3,42.2,37.3z"></path>
            </svg>
          `
				},
				{
					title: 'Fostering Production Line',
					text: 'ERP implementation in production line better business communication and automate daily production processes to ensure timely order fulfilment through real-time data access. ERP application development help optimising project and cost management modules for reliable and impactful production planning.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M98.5,78.6h-97c-0.9,0-1.5,0.6-1.5,1.5v18.3c0,0.9,0.6,1.5,1.5,1.5h97c0.9,0,1.5-0.6,1.5-1.5V80.2
                  C100,79.2,99.4,78.6,98.5,78.6z M15.9,97H3V81.7h12.9V97z M48.5,97H18.9V81.7h29.5V97z M81.1,97H51.5V81.7h29.5V97z M97,97H84.1
                  V81.7H97V97z M11.8,76.5h34.4c0.8,0,1.5-0.8,1.4-1.5V50.4c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7v-7.9
                  c0-0.9-0.6-1.5-1.5-1.5H11.8c-0.9,0-1.5,0.6-1.5,1.5V75C10.3,75.9,10.9,76.5,11.8,76.5z M35.9,42.6h8.6v5.6h-8.6V42.6z M25.2,42.6
                  h7.7v8.9L30,49.1c-0.3-0.2-0.8-0.3-1.1-0.3c-0.3,0-0.6,0.2-0.9,0.3l-2.9,2.4V42.6z M13.3,42.6h8.8v5.6h-8.8V42.6z M13.3,51.2h8.8
                  V55c0,0.6,0.3,1.1,0.9,1.4c0.6,0.2,1.2,0.2,1.7-0.3l4.2-3.8l4.4,3.8c0.5,0.5,1.1,0.6,1.7,0.3c0.6-0.3,0.9-0.8,0.9-1.4v-3.8h8.6
                  v22.3H13.3V51.2z M52.3,41.1V75c0,0.9,0.6,1.5,1.5,1.5h34.4c0.8,0,1.5-0.8,1.4-1.5V50.4c0.1-0.2,0.2-0.4,0.2-0.7
                  c0-0.3-0.1-0.5-0.2-0.7v-7.9c0-0.9-0.6-1.5-1.5-1.5H53.8C52.9,39.5,52.3,40.2,52.3,41.1z M77.9,42.6h8.6v5.6h-8.6V42.6z
                  M67.1,42.6h7.7v8.9L72,49.1c-0.3-0.2-0.8-0.3-1.1-0.3c-0.3,0-0.6,0.2-0.9,0.3l-2.9,2.4V42.6z M55.3,42.6h8.8v5.6h-8.8V42.6z
                  M55.3,51.2h8.8V55c0,0.6,0.3,1.1,0.9,1.4c0.6,0.2,1.2,0.2,1.7-0.3l4.2-3.8l4.4,3.8c0.5,0.5,1.1,0.6,1.7,0.3
                  c0.6-0.3,0.9-0.8,0.9-1.4v-3.8h8.6v22.3H55.3V51.2z M32.9,36.8H67c0.9,0,1.5-0.8,1.5-1.5V10.7c0.1-0.2,0.2-0.4,0.2-0.6
                  c0-0.3-0.1-0.5-0.2-0.7V1.5C68.5,0.6,67.9,0,67,0H32.9c-0.9,0-1.5,0.6-1.5,1.5v33.8C31.4,36.2,32,36.8,32.9,36.8z M56.8,3h8.6v5.6
                  h-8.6V3z M46.2,3h7.6V12l-2.7-2.4c-0.3-0.2-0.8-0.3-1.1-0.3c-0.3,0-0.8,0-1.1,0.3L46.2,12V3z M34.4,3h8.8v5.6h-8.8V3z M34.4,11.7
                  h8.8v3.8c0,0.6,0.3,1.1,0.9,1.4c0.6,0.2,1.2,0.2,1.7-0.3l4.2-3.8l4.2,3.8c0.3,0.2,0.8,0.3,1.1,0.3c0.2,0,0.5,0,0.6-0.2
                  c0.6-0.3,0.9-0.8,0.9-1.4v-3.6h8.6v22.1H34.4V11.7z"></path>
                </g>
              </g>
            </svg>
          `
				},
				{
					title: 'Timely Maintenance',
					text: 'Get real-time insights to key inventory performance indicators and leverage ERP implementation for optimizing current stock levels. Supply management and reliable forecasting in machine maintenance ensure timely deliveries to customers. Partnering ERP software development company allows timely inventory valuation LIFO, FIFO, etc.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M10.6,46.9H44c0.7,0,1.2-0.5,1.2-1.2V25.8c0-0.6-0.5-1.2-1.2-1.2H10.6c-0.7,0-1.2,0.5-1.2,1.2v19.9
                  C9.4,46.3,10,46.9,10.6,46.9z M11.8,26.9h31v17.6h-31V26.9z M49.1,28.1h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,27.5,48.5,28.1,49.1,28.1z M49.1,36.9h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,36.4,48.5,36.9,49.1,36.9z M49.1,45.7h25c0.7,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-25
                  c-0.7,0-1.2,0.5-1.2,1.2C47.9,45.2,48.5,45.7,49.1,45.7z M42.1,66.2c-1.8,0-3.2,1.4-3.2,3.1c0,1.7,1.4,3.1,3.2,3.1
                  c1.8,0,3.2-1.4,3.2-3.1C45.3,67.6,43.8,66.2,42.1,66.2z M42.1,70.1c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8
                  c0.4,0,0.8,0.3,0.8,0.8C42.9,69.7,42.5,70.1,42.1,70.1z M42.1,13.1H9.5c-0.7,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h32.6
                  c0.7,0,1.2-0.5,1.2-1.2C43.3,13.6,42.7,13.1,42.1,13.1z M73,11c-1.9,0-3.4,1.5-3.4,3.3c0,1.8,1.5,3.3,3.4,3.3
                  c1.9,0,3.4-1.5,3.4-3.3C76.4,12.5,74.9,11,73,11z M73,15.2c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1s1,0.4,1,1C74,14.8,73.5,15.2,73,15.2
                  z M64.4,11c-1.9,0-3.4,1.5-3.4,3.3c0,1.8,1.5,3.3,3.4,3.3c1.9,0,3.4-1.5,3.4-3.3C67.8,12.5,66.3,11,64.4,11z M64.4,15.2
                  c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1C65.4,14.8,65,15.2,64.4,15.2z M55.9,11c-1.9,0-3.4,1.5-3.4,3.3
                  c0,1.8,1.5,3.3,3.4,3.3s3.4-1.5,3.4-3.3C59.2,12.5,57.7,11,55.9,11z M55.9,15.2c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1s1,0.4,1,1
                  C56.9,14.8,56.4,15.2,55.9,15.2z M99.2,64.7c-0.1-0.6-0.5-1-1.1-1l-3.5-0.2c-0.5-1.3-1.2-2.5-2.1-3.6l1.7-3.1
                  c0.3-0.5,0.1-1.1-0.3-1.5l-5.2-3.9c-0.5-0.3-1.1-0.3-1.5,0l-2.6,2.3c-1.1-0.4-2.3-0.7-3.4-1v-40c0-2.9-2.4-5.3-5.4-5.3H9
                  c-3,0-5.4,2.4-5.4,5.3v46.7c0,1.1,0.3,2.1,0.9,3H1.2C0.5,62.5,0,63,0,63.7v2.9c0,5.1,4.3,9.3,9.5,9.3h46.4l0.1,0.4
                  c0.1,0.6,0.5,1,1.1,1l3.5,0.2c0.5,1.3,1.2,2.5,2.1,3.6L61,84.2c-0.3,0.5-0.1,1.1,0.3,1.5l5.1,4c0.5,0.4,1.1,0.3,1.5,0l2.6-2.3
                  c1.3,0.5,2.7,0.9,4,1.1l1,3.3c0.2,0.5,0.6,0.8,1.1,0.8c0,0,0.1,0,0.1,0l6.5-0.8c0.6-0.1,1-0.5,1-1.1l0.2-3.5
                  c1.3-0.5,2.5-1.2,3.7-2l3.1,1.6c0.5,0.3,1.1,0.1,1.5-0.3l4-5c0.4-0.4,0.3-1.1,0-1.5l-2.4-2.6c0.5-1.3,0.9-2.6,1.1-4l3.4-1
                  c0.6-0.2,0.9-0.7,0.8-1.3L99.2,64.7z M6,12.8c0-1.6,1.4-3,3-3h66.7c1.7,0,3,1.3,3,3v5.9H6V12.8z M6,21h72.8v27.5
                  c-0.2-0.1-0.4-0.1-0.6-0.1l-6.5,0.8c-0.6,0.1-1,0.5-1,1.1c0,0.1-0.1,0.9-0.1,1.9H10.8c-0.7,0-1.2,0.5-1.2,1.2
                  c0,0.6,0.5,1.2,1.2,1.2h51.3l-4,5c-0.4,0.4-0.3,1.1,0.1,1.5l1.3,1.4H9c-1.7,0-3-1.3-3-2.9V21z M68.9,54.5
                  c-0.7,0.4-1.4,0.8-2.1,1.3l-2.5-1.3H68.9z M56,68.6c-0.6,0.2-0.9,0.7-0.8,1.3l0.4,3.6H9.5v0c-3.9,0-7.1-3.1-7.1-6.9v-1.7h57.7
                  c-0.3,0.9-0.6,1.9-0.7,2.8L56,68.6z M94.3,71.3c-0.5,0.1-0.8,0.5-0.8,1c-0.2,1.6-0.6,3.2-1.3,4.7C92,77.5,92,78,92.3,78.3l2.2,2.4
                  l-2.8,3.5l-2.9-1.5c-0.4-0.2-0.9-0.2-1.3,0.1c-1.3,1-2.8,1.8-4.4,2.4c-0.5,0.2-0.8,0.6-0.8,1l-0.2,3.2L77.7,90l-1-3.1
                  c-0.1-0.4-0.5-0.8-1-0.8c-1.7-0.2-3.3-0.6-4.8-1.3c-0.2-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.6,0.1-0.8,0.3l-2.5,2.2l-3.6-2.8l1.6-2.9
                  c0.2-0.4,0.2-0.9-0.1-1.3c-1.1-1.3-1.9-2.8-2.5-4.3c-0.2-0.4-0.6-0.7-1.1-0.8L58.2,75l-0.5-4.4l3.1-0.9c0.5-0.1,0.8-0.5,0.8-1
                  c0.2-1.5,0.6-3,1.3-4.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.2,0.1-0.2c0.2-0.4,0.2-1-0.2-1.3l-2.2-2.4l2.8-3.5l2.9,1.5
                  c0.4,0.2,0.9,0.2,1.3-0.1c1.3-1,2.7-1.8,4.2-2.3c0.7-0.1,0.8-0.5,0.9-1.1l0.1,0c0-0.1,0-0.2,0-0.3c0-0.3,0.1-0.6,0.1-1
                  c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.5,0.1-1.1,0.1-1.9l4.5-0.5l1,3.1c0.1,0.4,0.5,0.8,1,0.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
                  c1.5,0.2,3,0.6,4.5,1.2c0.4,0.2,0.9,0.1,1.3-0.2l2.5-2.1l3.6,2.7L90,59.4c-0.2,0.4-0.2,0.9,0.1,1.3c1.1,1.3,1.9,2.8,2.5,4.3
                  c0.2,0.4,0.6,0.7,1,0.8l3.3,0.2l0.5,4.4L94.3,71.3z M77.5,59.4c-6.2,0-11.2,5-11.2,11.1c0,6.1,5.1,11.1,11.3,11.1
                  c6.2,0,11.3-5,11.3-11.1C88.8,64.5,83.9,59.4,77.5,59.4z M77.6,79.2c-4.9,0-8.9-3.9-8.9-8.7c0-4.8,4-8.7,8.9-8.7
                  c4.9,0,8.9,4,8.9,8.7C86.5,75.3,82.5,79.2,77.6,79.2z"></path>
                </g>
              </g>
            </svg>
          `
				},
				{
					title: 'Effortless Administration',
					text: 'ERP software development ensure rapid yet flawless update execution for you to gain timely insights while keeping an eye on the latest updates. It makes data retrieving easy and generating report within minutes reduces administrative hiccups with automated email alerts and timely personal management updates.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <g>
                <g>
                  <path d="M50,32.3c-9.8,0-17.7,8-17.7,17.7c0,9.8,8,17.7,17.7,17.7c9.8,0,17.7-8,17.7-17.7C67.7,40.2,59.8,32.3,50,32.3z M56.5,63
                  c-1.9,1-4.1,1.5-6.5,1.5s-4.5-0.6-6.5-1.5v-4.9c0-2.7,2.2-4.8,4.8-4.8v6.4h3.2v-6.4c2.7,0,4.8,2.2,4.8,4.8V63z M46.8,45.2
                  c0-1.8,1.4-3.2,3.2-3.2s3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2S46.8,46.9,46.8,45.2z M59.7,60.8v-2.7c0-3.7-2.5-6.8-5.9-7.7
                  c1.6-1.2,2.6-3,2.6-5.2c0-3.6-2.9-6.4-6.5-6.4c-3.6,0-6.5,2.9-6.5,6.4c0,2.1,1,4,2.6,5.2c-3.4,1-5.9,4-5.9,7.7v2.7
                  c-3-2.7-4.8-6.5-4.8-10.8c0-8,6.5-14.5,14.5-14.5c8,0,14.5,6.5,14.5,14.5C64.5,54.3,62.6,58.1,59.7,60.8z M77.4,41.9l-4.6,0
                  c-0.3-0.8-0.6-1.6-1-2.3l3.3-3.3c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1L66,24.9c-0.6-0.6-1.7-0.6-2.3,0l-3.3,3.2
                  c-0.7-0.4-1.5-0.7-2.3-0.9v-4.6c0-0.9-0.7-1.6-1.6-1.6H43.5c-0.9,0-1.6,0.7-1.6,1.6l0,4.6c-0.8,0.3-1.6,0.6-2.3,1l-3.3-3.3
                  c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.5l-9.1,9.1c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1l3.2,3.3
                  c-0.4,0.7-0.7,1.5-0.9,2.3h-4.6c-0.9,0-1.6,0.7-1.6,1.6v12.9c0,0.9,0.7,1.6,1.6,1.6l4.6,0c0.3,0.8,0.6,1.6,1,2.3l-3.3,3.3
                  c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1l9.1,9.1c0.6,0.6,1.7,0.6,2.3,0l3.3-3.2c0.7,0.4,1.5,0.7,2.3,0.9v4.6
                  c0,0.9,0.7,1.6,1.6,1.6h12.9c0.9,0,1.6-0.7,1.6-1.6l0-4.6c0.8-0.3,1.6-0.6,2.3-1l3.3,3.3c0.3,0.3,0.7,0.5,1.1,0.5
                  c0.4,0,0.8-0.2,1.1-0.5l9.1-9.1c0.3-0.3,0.5-0.7,0.5-1.1c0-0.4-0.2-0.8-0.5-1.1l-3.2-3.3c0.4-0.7,0.7-1.5,0.9-2.3h4.6
                  c0.9,0,1.6-0.7,1.6-1.6V43.6C79,42.7,78.3,41.9,77.4,41.9z M75.8,54.8h-3c-1.4,0-2.6,0.9-3,2.2c-0.2,0.7-0.5,1.4-0.8,2
                  c-0.6,1.2-0.3,2.7,0.6,3.7l2.1,2.1l-6.8,6.8l-2.1-2.1c-1-1-2.4-1.2-3.7-0.6c-0.6,0.3-1.3,0.6-2,0.8c-1.3,0.5-2.2,1.7-2.2,3v3h-9.7
                  v-3c0-1.4-0.9-2.6-2.2-3c-0.7-0.2-1.4-0.5-2-0.8c-1.2-0.6-2.7-0.3-3.7,0.6l-2.1,2.1l-6.8-6.8l2.1-2.1c1-1,1.2-2.4,0.6-3.7
                  c-0.3-0.6-0.6-1.3-0.8-2c-0.5-1.3-1.7-2.2-3-2.2h-3v-9.7h3c1.4,0,2.6-0.9,3-2.2c0.2-0.7,0.5-1.4,0.8-2c0.6-1.2,0.3-2.7-0.6-3.7
                  l-2.1-2.1l6.8-6.8l2.1,2.1c1,1,2.4,1.2,3.7,0.6c0.6-0.3,1.3-0.6,2-0.8c1.3-0.5,2.2-1.7,2.2-3v-3h9.7v3c0,1.4,0.9,2.6,2.2,3
                  c0.7,0.2,1.4,0.5,2,0.8c1.2,0.6,2.7,0.3,3.7-0.6l2.1-2.1l6.8,6.8l-2.1,2.1c-1,1-1.2,2.4-0.6,3.7c0.3,0.6,0.6,1.3,0.8,2
                  c0.5,1.3,1.7,2.2,3,2.2h3V54.8z M14.7,12.1c0.9-1.1,1.4-2.5,1.4-4c0-3.6-2.9-6.4-6.5-6.4c-3.6,0-6.5,2.9-6.5,6.4
                  c0,1.5,0.5,2.9,1.4,4C1.9,13.4,0,16.2,0,19.4v3.2c0,0.9,0.7,1.6,1.6,1.6h16.1c0.9,0,1.6-0.7,1.6-1.6v-3.2
                  C19.4,16.2,17.4,13.4,14.7,12.1z M9.7,4.9c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2S6.5,9.9,6.5,8.1C6.5,6.3,7.9,4.9,9.7,4.9z
                  M16.1,21H3.2v-1.6c0-2.7,2.2-4.8,4.8-4.8h3.2c2.7,0,4.8,2.2,4.8,4.8V21z M95.3,12.1c0.9-1.1,1.4-2.5,1.4-4c0-3.6-2.9-6.4-6.5-6.4
                  c-3.6,0-6.5,2.9-6.5,6.4c0,1.5,0.6,2.9,1.4,4c-2.7,1.3-4.7,4.1-4.7,7.3v3.2c0,0.9,0.7,1.6,1.6,1.6h16.1c0.9,0,1.6-0.7,1.6-1.6
                  v-3.2C100,16.2,98.1,13.4,95.3,12.1z M90.3,4.9c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2
                  C87.1,6.3,88.5,4.9,90.3,4.9z M96.8,21H83.9v-1.6c0-2.7,2.2-4.8,4.8-4.8h3.2c2.7,0,4.8,2.2,4.8,4.8V21z M14.7,86.2
                  c0.9-1.1,1.4-2.5,1.4-4c0-3.6-2.9-6.4-6.5-6.4c-3.6,0-6.5,2.9-6.5,6.4c0,1.5,0.5,2.9,1.4,4C1.9,87.5,0,90.3,0,93.5v3.2
                  c0,0.9,0.7,1.6,1.6,1.6h16.1c0.9,0,1.6-0.7,1.6-1.6v-3.2C19.4,90.3,17.4,87.5,14.7,86.2z M9.7,79c1.8,0,3.2,1.4,3.2,3.2
                  s-1.4,3.2-3.2,3.2S6.5,84,6.5,82.2S7.9,79,9.7,79z M16.1,95.1H3.2v-1.6c0-2.7,2.2-4.8,4.8-4.8h3.2c2.7,0,4.8,2.2,4.8,4.8V95.1z
                  M95.3,86.2c0.9-1.1,1.4-2.5,1.4-4c0-3.6-2.9-6.4-6.5-6.4c-3.6,0-6.5,2.9-6.5,6.4c0,1.5,0.6,2.9,1.4,4c-2.7,1.3-4.7,4.1-4.7,7.3
                  v3.2c0,0.9,0.7,1.6,1.6,1.6h16.1c0.9,0,1.6-0.7,1.6-1.6v-3.2C100,90.3,98.1,87.5,95.3,86.2z M90.3,79c1.8,0,3.2,1.4,3.2,3.2
                  s-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2S88.5,79,90.3,79z M96.8,95.1H83.9v-1.6c0-2.7,2.2-4.8,4.8-4.8h3.2
                  c2.7,0,4.8,2.2,4.8,4.8V95.1z M5.3,68.9c0.3,0.3,0.7,0.5,1.1,0.5s0.8-0.2,1.1-0.5l4.8-4.8l-2.3-2.3l-2.1,2.1V53.2h9.7V50H6.5
                  c-0.9,0-1.6,0.7-1.6,1.6v12.2l-2.1-2.1L0.5,64L5.3,68.9z M82.3,53.2h9.7v10.6l-2.1-2.1L87.6,64l4.8,4.8c0.3,0.3,0.7,0.5,1.1,0.5
                  c0.4,0,0.8-0.2,1.1-0.5l4.8-4.8l-2.3-2.3l-2.1,2.1V51.6c0-0.9-0.7-1.6-1.6-1.6H82.3V53.2z M82.3,43.6v3.2h11.3
                  c0.9,0,1.6-0.7,1.6-1.6V32.9l2.1,2.1l2.3-2.3l-4.8-4.8c-0.6-0.6-1.7-0.6-2.3,0l-4.8,4.8l2.3,2.3l2.1-2.1v10.6H82.3z M10.2,35
                  l2.3-2.3l-4.8-4.8c-0.6-0.6-1.6-0.6-2.3,0l-4.8,4.8L2.8,35l2.1-2.1v12.2c0,0.9,0.7,1.6,1.6,1.6h11.3v-3.2H8.1V32.9L10.2,35z"></path>
                </g>
              </g>
            </svg>
          `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
