<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Why Choose Us As Your Ally For Custom eCommerce Development</h2>
				<p>
					The right technology partner can accelerate your digital transformation and take you
					closer to your organizational goals. Our eCommerce website development services are fine
					tuned to ensure that you are bang on the fast lane.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../../WebDevelopment/AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Diverse platform expertise',
					text: 'Magento, Drupal, WooCommerce, Shopify, Volusion, or any other eCommerce platform — choose your pick and we will build an online store for you.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M50,14.1c0-0.4-0.2-0.8-0.6-1l-23.9-13c-0.3-0.2-0.7-0.2-1,0l-23.9,13c-0.3,0.2-0.6,0.6-0.6,1c0,0.4,0.2,0.8,0.6,1l8.2,4.5
                        L0.6,24C0.2,24.2,0,24.6,0,25s0.2,0.8,0.6,1l8.2,4.5l-8.2,4.5c-0.3,0.2-0.6,0.6-0.6,1s0.2,0.8,0.6,1l23.9,13C24.6,50,24.8,50,25,50
                        s0.4,0,0.5-0.1l23.9-13c0.3-0.2,0.6-0.6,0.6-1s-0.2-0.8-0.6-1l-8.2-4.5l8.2-4.5c0.3-0.2,0.6-0.6,0.6-1s-0.2-0.8-0.6-1l-8.2-4.5
                        l8.2-4.5C49.8,14.9,50,14.5,50,14.1z M25,47.7L3.4,35.9l7.7-4.2L24.5,39c0.3,0.2,0.7,0.2,1,0l13.4-7.3l7.7,4.2L25,47.7z M46.6,25
                        l-8.2,4.5L25,36.8l-13.4-7.3c0,0,0,0,0,0L3.4,25l7.7-4.2l13.4,7.3c0.2,0.1,0.3,0.1,0.5,0.1s0.4,0,0.5-0.1l13.4-7.3L46.6,25z
                        M25,25.9L3.4,14.1L25,2.3l21.6,11.8L25,25.9z"></path>
                     </svg>
               `
				},
				{
					title: 'Proven Track Record',
					text: 'We have helped countless clients launch their eCommerce websites on a timely basis. Do check out online review sites to checkout what they feel about us.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M22.5,0c-0.2,0-0.5,0-0.8,0.1c-1.1,0.3-1.7,1.2-2,1.8c-0.3,0.6-0.5,1-0.9,1.2c-0.5,0.3-0.9,0.2-1.6,0.2
                        c-0.7,0-1.7,0.1-2.5,0.9C14,5,13.8,6,13.8,6.7s0,1.1-0.2,1.5c-0.2,0.4-0.5,0.5-1.2,0.8s-1.5,1-1.8,2.1c-0.3,1.1,0.2,2.1,0.6,2.6
                        s0.6,0.8,0.6,1.4s-0.2,0.9-0.6,1.5c-0.4,0.6-0.8,1.5-0.5,2.6c0.3,1.1,1.2,1.7,1.8,2s1,0.5,1.2,0.9c0.2,0.4,0.2,0.8,0.2,1.5
                        c0,0.7,0.1,1.7,0.9,2.5c0.8,0.8,1.8,0.9,2.5,0.9c0,0,0.1,0,0.1,0l-6.1,18.5c-0.2,0.5,0.1,1,0.6,1.2c0.8,0.3,1.6,0.2,2.3-0.1
                        c0.6-0.3,1.1-0.7,1.6-1c0.4-0.4,0.8-0.7,1.1-0.8c0.3-0.2,0.4-0.2,0.6-0.1c0.1,0,0.2,0.1,0.3,0.4c0.2,0.3,0.3,0.8,0.5,1.4
                        c0.2,0.6,0.4,1.2,0.8,1.7c0.4,0.6,0.9,1.1,1.7,1.4c0.5,0.2,1.1-0.1,1.2-0.6c0,0,0,0,0,0l3.9-12l3.9,12.2c0.2,0.5,0.7,0.8,1.2,0.6
                        c0.8-0.2,1.4-0.8,1.7-1.4c0.4-0.6,0.6-1.2,0.8-1.7s0.3-1.1,0.5-1.4c0.2-0.3,0.2-0.3,0.2-0.3c0,0,0,0,0,0c0.2-0.1,0.3,0,0.6,0.1
                        s0.7,0.5,1.1,0.8c0.4,0.4,0.9,0.8,1.5,1s1.4,0.4,2.2,0.1c0.5-0.2,0.7-0.6,0.6-1.1c-0.4-1.7-1.6-5.8-2.9-9.9c-1.2-3.7-2.2-7-2.9-9.1
                        c0.7,0,1.7-0.1,2.4-0.9c0.8-0.8,0.9-1.8,0.9-2.5c0-0.7,0-1.1,0.2-1.5c0.2-0.4,0.6-0.6,1.2-0.9s1.5-0.9,1.8-2c0,0,0,0,0,0
                        c0.3-1.1-0.2-2.1-0.6-2.6c-0.4-0.6-0.6-0.8-0.6-1.4s0.2-0.9,0.6-1.5c0.4-0.6,0.8-1.5,0.5-2.6c-0.3-1.1-1.2-1.7-1.8-2s-1-0.5-1.2-0.9
                        c-0.2-0.4-0.2-0.8-0.2-1.5S37.7,5,36.9,4.2c-0.8-0.8-1.8-0.9-2.5-0.9c-0.7,0-1.1,0-1.5-0.2c-0.4-0.2-0.6-0.6-0.9-1.2
                        c-0.3-0.6-0.9-1.5-2-1.8c0,0,0,0,0,0c-1.1-0.3-2.1,0.2-2.7,0.6c-0.6,0.4-0.8,0.6-1.4,0.6c-0.6,0-0.9-0.2-1.5-0.6
                        C23.9,0.4,23.3,0,22.5,0z M22.2,1.9c0.4-0.1,0.6,0,1.1,0.3s1.3,0.9,2.5,0.9c1.2,0,1.9-0.6,2.4-0.9c0.5-0.3,0.6-0.4,1.2-0.3
                        c0.4,0.1,0.5,0.3,0.8,0.8c0.3,0.5,0.7,1.4,1.6,2c0.9,0.5,1.9,0.5,2.5,0.5c0.6,0,0.8,0,1.2,0.3c0.4,0.3,0.3,0.6,0.3,1.2
                        s-0.1,1.5,0.5,2.5c0.5,1,1.4,1.3,2,1.6c0.5,0.3,0.7,0.4,0.8,0.8c0.1,0.4,0,0.6-0.3,1.1c-0.3,0.5-0.9,1.3-0.9,2.5s0.6,1.9,0.9,2.4
                        c0.3,0.5,0.4,0.6,0.3,1.2c-0.1,0.4-0.3,0.5-0.8,0.8c-0.5,0.3-1.4,0.7-2,1.6c-0.5,0.9-0.5,1.9-0.5,2.5s0,0.8-0.3,1.2
                        c-0.4,0.3-0.6,0.3-1.2,0.3c-0.6,0-1.5-0.1-2.4,0.5c-0.3,0.1-0.5,0.3-0.7,0.4c-0.6,0.5-0.9,1.1-1.1,1.5v0c-0.3,0.5-0.4,0.7-0.8,0.9
                        c-0.2,0-0.3,0-0.5,0h0c-0.2,0-0.3-0.1-0.6-0.3c-0.4-0.2-1-0.6-1.7-0.8c-0.2-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.5,0-0.7,0.1
                        c-0.8,0.2-1.4,0.5-1.8,0.8c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0.1-0.3,0.1-0.6,0c-0.6-0.1-0.6-0.3-0.8-0.8c-0.1-0.3-0.3-0.6-0.5-1
                        c0,0,0,0,0,0c-0.1-0.2-0.3-0.4-0.4-0.5c-0.2-0.2-0.4-0.3-0.6-0.5c0,0,0,0,0,0c0,0,0,0,0,0c-1-0.5-1.9-0.4-2.5-0.4
                        c-0.6,0-0.7,0-1.1-0.3c-0.4-0.3-0.3-0.6-0.3-1.2s0.1-1.5-0.5-2.5c-0.5-1-1.4-1.4-2-1.6c-0.5-0.3-0.7-0.4-0.8-0.8
                        c-0.1-0.4,0-0.6,0.3-1.1c0.3-0.5,0.9-1.3,0.9-2.5s-0.6-1.9-0.9-2.4c-0.3-0.5-0.4-0.6-0.3-1.2c0.1-0.6,0.3-0.6,0.8-0.8
                        c0.5-0.3,1.4-0.6,2-1.6c0.5-0.9,0.5-1.9,0.5-2.5s0-0.8,0.3-1.2c0.4-0.4,0.5-0.3,1.1-0.3c0.6,0,1.5,0.1,2.5-0.4c0,0,0,0,0,0
                        c1-0.5,1.4-1.4,1.6-2S21.8,2.1,22.2,1.9z M25.8,5.2c-5.4,0-9.8,4.5-9.8,10s4.5,10,9.8,10s9.8-4.5,9.8-10c0-0.1,0-0.3-0.1-0.4
                        C35.4,9.5,31.1,5.2,25.8,5.2z M25.8,7.2c4.3,0,7.9,3.6,7.9,8.1s-3.6,8.1-7.9,8.1s-7.9-3.6-7.9-8.1S21.6,7.2,25.8,7.2z M32.6,27.7
                        c0.7,2,1.8,5.4,2.9,9.2c1.1,3.6,2,6.5,2.4,8.4c-0.3-0.2-0.5-0.3-0.8-0.6c-0.4-0.3-0.9-0.7-1.4-1c-0.6-0.3-1.3-0.5-2.1-0.3
                        c-0.7,0.2-1.2,0.8-1.5,1.3c-0.3,0.5-0.4,1.1-0.6,1.6c-0.1,0.4-0.3,0.8-0.5,1.1l-4.3-13.6c0,0,0,0,0,0l-1.4-4.3
                        c0.1,0,0.2-0.2,0.4-0.2c0.6,0,0.9,0.2,1.5,0.6c0.5,0.3,1.3,0.7,2.3,0.6c0,0,0,0,0.1,0v0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0
                        c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.9-0.4,1.4-1.2,1.7-1.7C32.1,28.1,32.3,27.9,32.6,27.7z M19.1,27.9c0.2,0.2,0.3,0.3,0.5,0.7
                        c0.3,0.6,1,1.5,2.1,1.8c0.7,0.2,1.4,0,1.9-0.2l0.1,0.3l1.1,3.5l-4.3,13.1c-0.2-0.3-0.3-0.7-0.5-1.2c-0.2-0.5-0.3-1.1-0.6-1.6
                        c-0.3-0.5-0.7-1.1-1.4-1.4c0,0,0,0-0.1,0c-0.8-0.2-1.5,0-2.1,0.3c-0.6,0.3-1,0.7-1.4,1c-0.4,0.3-0.7,0.5-1,0.6L19.1,27.9z"></path>
                        <g transform="translate(0,-952.36218)">
                           <path d="M30.8,963.8l-6.6,6.3l-3.4-2.5l-0.9,1.1l3.9,2.9l0.5,0.4l0.4-0.4l7.1-6.8L30.8,963.8L30.8,963.8z"></path>
                        </g>
                     </svg>
               `
				},
				{
					title: 'Flexible engagement models',
					text: 'Hire a dedicated resource, engage a team or hire a ecommerce website developer on a hourly basis. GenixBit Labs gives you flexible engagement models you can choose from to best serve your needs.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <g>
                              <g>
                                 <path class="st0" d="M10.5,42c1.2-0.8,1.9-2.1,1.9-3.6C12.4,36,10.5,34,8,34c-2.4,0-4.4,2-4.4,4.4c0,1.5,0.7,2.9,1.9,3.6
                                 c-2.8,0.1-5,2.4-5,5.3v1.9c0,0.5,0.3,0.8,0.7,0.8h13.4c0.4,0,0.7-0.3,0.7-0.8v-1.9C15.4,44.5,13.2,42.2,10.5,42z M8,35.5
                                 c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9s-2.9-1.3-2.9-2.9C5.1,36.7,6.4,35.5,8,35.5z M13.9,48.5H2v-1.1
                                 c0-2.1,1.7-3.8,3.7-3.8h4.3c2.1,0,3.7,1.7,3.7,3.8L13.9,48.5L13.9,48.5z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M27.7,42c1.2-0.8,1.9-2.1,1.9-3.6c0-2.4-1.9-4.4-4.4-4.4s-4.4,2-4.4,4.4c0,1.5,0.7,2.9,1.9,3.6
                                 c-2.8,0.2-5,2.5-5,5.3v1.9c0,0.5,0.3,0.8,0.7,0.8h13.4c0.4,0,0.7-0.3,0.7-0.8v-1.9C32.7,44.5,30.5,42.2,27.7,42z M25.3,35.5
                                 c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9C22.4,36.7,23.7,35.5,25.3,35.5z M31.2,48.5H19.3v-1.1
                                 c0-2.1,1.7-3.8,3.7-3.8h4.3c2.1,0,3.7,1.7,3.7,3.8V48.5z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M45.1,42c1.2-0.8,1.9-2.1,1.9-3.6c0-2.4-1.9-4.4-4.4-4.4s-4.4,2-4.4,4.4c0,1.5,0.7,2.9,1.9,3.6
                                 c-2.8,0.2-5,2.5-5,5.3v1.9c0,0.5,0.3,0.8,0.7,0.8h13.4c0.4,0,0.7-0.3,0.7-0.8v-1.9C50,44.5,47.8,42.2,45.1,42z M42.6,35.5
                                 c1.6,0,2.9,1.3,2.9,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9C39.7,36.7,41,35.5,42.6,35.5z M48.5,48.5H36.6v-1.1
                                 c0-2.1,1.7-3.8,3.7-3.8h4.4c2.1,0,3.7,1.7,3.7,3.8V48.5z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M16.9,19.9v2.9c0,0.5,0.3,0.8,0.7,0.8h15.3c0.4,0,0.7-0.3,0.7-0.8v-2.8c0-3-2.4-5.3-5.2-5.3h-0.9
                                 c1.6-0.8,2.7-2.5,2.7-4.5c0-2.7-2.2-4.9-4.9-4.9c-2.7,0-4.9,2.2-4.9,4.9c0,2,1.1,3.6,2.7,4.5h-1C19.2,14.6,16.9,16.9,16.9,19.9z
                                 M21.9,10.1c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4C23.5,13.6,21.9,12,21.9,10.1z M32.2,19.9V22H18.4v-2
                                 c0-2.1,1.7-3.8,3.7-3.8h6.3C30.5,16.1,32.2,17.7,32.2,19.9z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M14,5.6C14.2,5.8,14.4,6,14.6,6l4.1,0.5c0.4,0.1,0.7-0.2,0.8-0.7c0.1-0.4-0.2-0.8-0.7-0.8l-2.4-0.2
                                 c2.4-2,5.5-3.2,8.8-3.2c7.8,0,14.2,6.4,14.2,14.3c0,2.3-0.5,4.5-1.6,6.5c-0.2,0.4-0.1,0.8,0.3,1c0.4,0.2,0.8,0.1,1-0.3
                                 c1.1-2.3,1.7-4.6,1.7-7.2C40.9,7.1,33.9,0,25.3,0c-3.4,0-6.7,1.1-9.4,3.3l0.4-2.3c0-0.5-0.2-0.8-0.7-0.9
                                 c-0.4-0.1-0.8,0.2-0.9,0.6l-0.8,4.5C13.9,5.3,13.9,5.5,14,5.6z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M35.7,31l0.8-4.4c0.1-0.2,0-0.5-0.1-0.6c-0.1-0.2-0.4-0.3-0.5-0.3l-4.3-0.4c-0.4,0-0.7,0.3-0.8,0.7
                                 c-0.1,0.4,0.3,0.8,0.7,0.8l2.5,0.2c-2.5,2-5.5,3-8.8,3c-7.8,0-14.2-6.4-14.2-14.3c0-2.3,0.5-4.6,1.6-6.6c0.2-0.4,0.1-0.8-0.3-1.1
                                 c-0.4-0.2-0.8-0.1-1,0.3c-1.2,2.3-1.8,4.8-1.8,7.3c0,8.7,7,15.8,15.7,15.8c3.4,0,6.7-1.1,9.4-3.2l-0.4,2.3
                                 c-0.1,0.4,0.2,0.8,0.6,0.9C35.2,31.7,35.6,31.4,35.7,31z"></path>
                              </g>
                           </g>
                        </g>
                     </svg>
               `
				},
				{
					title: 'Platform Partnerships',
					text: 'We have professional associations and partnership agreements with most eCommerce platforms that make us a legit eCommerce development company to rely on.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M49.1,14.4l-3.9-5c-1.1-1.5-3.2-1.8-4.8-0.8l0,0l-3.2,2.1c-1,0.7-2.4,0.7-3.5,0.1l-2.6-1.5c-1.8-1-4-0.9-5.7,0.2l-0.6,0.4
                        l-0.6-0.4c-2-1.2-4.5-1.4-6.6-0.3l-0.9,0.5c-1.8,0.9-3.8,0.8-5.5-0.1l-0.6-0.3c-1.8-1-4.1-0.6-5.4,0.9l-4.3,5
                        c-0.7,0.7-1,1.7-0.9,2.7c0.1,1,0.5,1.9,1.3,2.6l3.9,3.3l-1.7,1.9c-0.6,0.7-1,1.6-1,2.6s0.4,1.9,1.1,2.5l0,0c0.5,0.4,1,0.7,1.7,0.9
                        c-0.2,0.5-0.3,1-0.3,1.6c0,1,0.4,1.9,1.1,2.5l0,0c0.7,0.6,1.5,1,2.4,1c0.4,0,0.9-0.1,1.3-0.2c0.1,0.7,0.5,1.3,1,1.8l0,0
                        c0.7,0.6,1.5,1,2.4,1c0.6,0,1.2-0.2,1.8-0.5c0.2,0.6,0.5,1.1,1,1.6l0,0c0.7,0.6,1.5,1,2.4,1c1,0,1.9-0.4,2.6-1.1l1.5-1.6l2.4,2
                        c0.6,0.5,1.4,0.8,2.1,0.8c0.9,0,1.8-0.4,2.5-1.1c0.3-0.4,0.6-0.8,0.7-1.3l0.7,0.7c1.5,1.3,3.7,1.2,5-0.2c0.5-0.5,0.8-1.2,0.9-1.9
                        c1.2,0.4,2.7,0.1,3.6-1c0.8-0.9,1-2.1,0.8-3.2c0.7-0.1,1.4-0.5,1.9-1.1c0.6-0.7,0.9-1.6,0.9-2.5c0-0.9-0.5-1.7-1.1-2.3l-0.3-0.2
                        l6.5-7.5C50.3,18.1,50.3,15.9,49.1,14.4z M4.9,29.3c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.4,0.1-0.9,0.4-1.2l3.8-4.2
                        c0.3-0.3,0.8-0.5,1.2-0.5c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.1,0.9-0.4,1.2l-3.8,4.2c-0.3,0.3-0.7,0.5-1.1,0.5
                        C5.7,29.7,5.2,29.6,4.9,29.3L4.9,29.3z M7.4,34.3C7,34,6.9,33.6,6.8,33.2c0-0.4,0.1-0.9,0.4-1.2l5.2-5.7c0.6-0.7,1.6-0.7,2.3-0.1
                        c0.7,0.6,0.7,1.7,0.1,2.3l-1.1,1.2l0,0l-1.5,1.6l-2.7,3C9,34.9,8,35,7.4,34.3z M12.1,36.9c-0.3-0.3-0.5-0.7-0.5-1.1
                        c0-0.4,0.1-0.9,0.4-1.2l1.8-1.9l1.5-1.6c0.6-0.7,1.6-0.7,2.3-0.1c0.7,0.6,0.7,1.7,0.1,2.3l-0.4,0.4l-1.4,1.5l0,0l-1.4,1.5
                        C13.8,37.5,12.7,37.5,12.1,36.9z M19.6,38.9c-0.6,0.7-1.6,0.7-2.3,0.1c-0.7-0.6-0.7-1.7-0.1-2.3l1.8-2l0,0l0,0
                        c0.3-0.3,0.7-0.5,1.1-0.5s0.9,0.1,1.2,0.4c0.7,0.6,0.7,1.7,0.1,2.3L19.6,38.9z M41.7,30.9c-0.2,0.3-0.6,0.4-1,0.5
                        c-0.4,0-0.7-0.1-1-0.4l-6.6-6.3c-0.4-0.4-1-0.3-1.4,0c-0.4,0.4-0.3,1,0,1.4l7.2,6.9c0.6,0.6,0.6,1.6,0.1,2.2
                        c-0.6,0.6-1.5,0.7-2.2,0.1l-1.1-1.1c0,0,0,0,0,0l-5.6-5.1c-0.4-0.4-1-0.3-1.4,0.1s-0.3,1,0.1,1.4l5.6,5.2c0.3,0.3,0.5,0.7,0.5,1.2
                        c0,0.4-0.1,0.9-0.4,1.2c-0.6,0.7-1.7,0.7-2.3,0.1l-3-2.7c0,0,0,0,0,0l-2.8-2.6c-0.4-0.3-1-0.3-1.4,0.1c-0.3,0.4-0.3,1,0.1,1.4
                        l2.9,2.6c0.3,0.2,0.4,0.6,0.5,1c0,0.4-0.1,0.7-0.4,1c-0.5,0.6-1.3,0.6-1.9,0.1l-2.5-2.2c0.4-1.3,0.1-2.8-0.9-3.8
                        c-0.7-0.6-1.6-1-2.5-1c0,0-0.1,0-0.1,0c0-1-0.4-1.9-1.1-2.6c-0.5-0.5-1.1-0.8-1.8-0.9c0.5-1.3,0.1-2.8-0.9-3.8
                        c-0.8-0.7-1.8-1-2.8-0.9l0,0c0-1-0.4-1.9-1.1-2.5c-1.4-1.4-3.7-1.3-5,0.2l-0.8,0.9l-3.9-3.4c-0.4-0.3-0.6-0.7-0.6-1.2
                        c0-0.5,0.1-0.9,0.4-1.3l4.3-5c0.8-0.9,2.1-1.1,3.1-0.5l0.6,0.3c2.2,1.3,5,1.4,7.3,0.2l0.9-0.5c1.5-0.7,3.1-0.7,4.5,0.1l-1.9,1.2
                        c-0.8,0.5-1.4,1.4-1.6,2.4s0,2,0.6,2.8c1,1.5,3,2,4.6,1.2l1.4-0.7c1.1-0.5,2.4-0.4,3.3,0.4l10.9,9.5l0,0l1,0.9
                        c0.3,0.2,0.5,0.6,0.5,1C42.1,30.2,41.9,30.6,41.7,30.9z M47.6,18.3l-6.5,7.5l-10.2-8.9c-0.9-0.8-2.1-1.2-3.2-1.2
                        c-0.8,0-1.5,0.2-2.2,0.5L24.1,17c-0.8,0.4-1.7,0.1-2.2-0.6c-0.3-0.4-0.4-0.9-0.3-1.3c0.1-0.5,0.4-0.9,0.8-1.1l4.2-2.8
                        c1.1-0.7,2.5-0.8,3.7-0.1l2.6,1.5c1.7,1,3.8,0.9,5.4-0.1l3.2-2.1c0.7-0.5,1.7-0.3,2.2,0.4l3.9,5C48.3,16.4,48.2,17.5,47.6,18.3z"></path>
                     </svg>
               `
				},
				{
					title: 'World-class Support',
					text: 'Once a client, always a client. GenixBit Labs will be by your side to provide continued support after each project launch. You can reach us through mail or phone to resolve any of your queries.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M42.3,14.6l0.1,3.5l-0.5,2.2c-0.8,0.1-1.5,0.4-2,0.9L36,25.1l-1.3,0.2c-1,0.2-1.9,0.6-2.6,1.3l-2.8,2.7
                        c-0.9,0.9-1.4,2-1.4,3.3L27.9,37c-0.4,0.2-0.8,0.4-1.2,0.8c-0.7,0.7-1.1,1.6-1.1,2.5l-0.1,6c0,1,0.4,1.9,1,2.5
                        c0.7,0.7,1.6,1.1,2.5,1.1l9.9,0.1c0,0,0,0,0,0c0.9,0,1.8-0.4,2.5-1c0.7-0.7,1.1-1.6,1.1-2.5l0.1-6c0-1-0.4-1.9-1-2.5
                        c-0.3-0.3-0.7-0.6-1.1-0.7c0.2-0.9,0.6-1.7,1.2-2.4l4.6-4.6c1.2-1.2,2-2.9,2.2-4.6l0.5-4.7c0-0.2,0.1-0.3,0.1-0.5l0,0c0,0,0,0,0,0
                        c0,0,0-0.1,0-0.1L49,12.6c0-0.6-0.3-1.2-0.7-1.7c-0.5-0.5-1.2-0.8-1.9-0.7C44.1,10.3,42.2,12.2,42.3,14.6z M40.3,46.4
                        c0,0.3-0.1,0.7-0.4,0.9c-0.2,0.2-0.6,0.4-0.9,0.4l-9.9-0.1c-0.3,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9l0.1-6
                        c0-0.3,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4c0,0,0,0,0,0l9.9,0.1c0.3,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,0.9L40.3,46.4z
                        M46.6,12.6c0.1,0.1,0.1,0.1,0.1,0.2l0.2,7.4l-0.1,0.5c0,0,0,0,0,0l-0.5,4.6c-0.1,1.2-0.7,2.4-1.5,3.2l-4.6,4.6
                        c-1,1-1.6,2.3-1.9,3.6l-8.2-0.1l0.1-4c0-0.6,0.3-1.2,0.7-1.7l2.8-2.7c0.4-0.4,0.8-0.6,1.3-0.7l1.6-0.3c0.1,0,0.2,0,0.2-0.1
                        c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0,0l4.2-4.2c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2l-4.8,4.8
                        c-0.5,0.4-0.5,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l4.8-4.8c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2
                        c-0.1-0.1-0.2-0.2-0.3-0.2l0.6-2.4c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0l-0.1-3.7c0-1.1,0.8-2,1.9-2
                        C46.5,12.5,46.6,12.5,46.6,12.6z"></path>
                        <ellipse cx="38.8" cy="41.1" rx="1.4" ry="1.4"></ellipse>
                        <path d="M23.3,37.7c-0.3-0.3-0.7-0.6-1.2-0.8L22,32.6c0-1.2-0.5-2.4-1.4-3.2l-2.8-2.7c-0.7-0.7-1.6-1.2-2.6-1.3L14,25.1l-3.9-3.9
                        c-0.5-0.5-1.2-0.8-2-0.9l-0.5-2.2l0.1-3.5c0.1-2.3-1.8-4.3-4.1-4.4c-0.7,0-1.4,0.3-1.9,0.7C1.3,11.4,1,12,1,12.6l-0.2,7.5
                        c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0,0c0,0.2,0,0.4,0.1,0.5l0.5,4.7c0.2,1.7,1,3.4,2.2,4.6l4.6,4.6c0.6,0.6,1.1,1.5,1.2,2.4
                        c-0.4,0.2-0.8,0.4-1.1,0.7c-0.7,0.7-1,1.6-1,2.5l0.1,6c0,1,0.4,1.8,1.1,2.5c0.7,0.7,1.6,1,2.5,1c0,0,0,0,0,0l9.9-0.1
                        c1,0,1.8-0.4,2.5-1.1c0.7-0.7,1-1.6,1-2.5l-0.1-6C24.3,39.3,24,38.4,23.3,37.7z M5.1,28.5c-0.9-0.9-1.4-2-1.5-3.2l-0.5-4.6
                        c0,0,0,0,0,0l-0.1-0.5l0.2-7.4c0,0,0-0.1,0.1-0.2c0,0,0.1-0.1,0.2-0.1c1.1,0,1.9,0.9,1.9,2l-0.1,3.7c0,0,0,0,0,0c0,0,0,0.1,0,0.1
                        c0,0,0,0.1,0,0.1c0,0,0,0,0,0l0.6,2.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2l4.8,4.8
                        c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.5-0.4,0.5-1.2,0-1.6L7.3,24c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0
                        l4.2,4.2c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.1l1.6,0.3c0.5,0.1,1,0.3,1.3,0.7L19,31
                        c0.4,0.4,0.7,1,0.7,1.7l0.1,4l-8.2,0.1c-0.2-1.4-0.9-2.6-1.9-3.6L5.1,28.5z M21.8,47.1c-0.2,0.2-0.6,0.4-0.9,0.4l-9.9,0.1
                        c-0.3,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9l-0.1-6l0,0c0-0.3,0.1-0.7,0.4-0.9c0.2-0.2,0.6-0.4,0.9-0.4l9.9-0.1c0,0,0,0,0,0
                        c0.3,0,0.7,0.1,0.9,0.4c0.2,0.2,0.4,0.6,0.4,0.9l0.1,6C22.1,46.6,22,46.9,21.8,47.1z"></path>
                        <ellipse cx="11.2" cy="41.1" rx="1.4" ry="1.4"></ellipse>
                        <path d="M30.3,13.2c0-2.9-2.4-5.2-5.3-5.2s-5.3,2.4-5.3,5.2s2.4,5.2,5.3,5.2S30.3,16.1,30.3,13.2z M25,16.1c-1.6,0-3-1.3-3-2.9
                        s1.3-2.9,3-2.9c1.6,0,3,1.3,3,2.9S26.6,16.1,25,16.1z"></path>
                        <path d="M28.5,24.1v-1.5c1.1-0.4,2.1-1,2.9-1.7l1.3,0.8c0.3,0.2,0.7,0.3,1.1,0.3c0.8,0,1.5-0.4,1.9-1.1l1.3-2.2c0.6-1,0.2-2.4-0.8-3
                        l-1.3-0.8c0.1-0.6,0.2-1.1,0.2-1.7c0-0.6,0-1.1-0.1-1.7l1.3-0.7c1-0.6,1.4-1.9,0.8-3l-1.3-2.2c-0.6-1-1.9-1.4-3-0.8l-1.3,0.8
                        c-0.9-0.7-1.9-1.3-2.9-1.7V2.3c0-1.2-1-2.2-2.2-2.2h-2.6c-1.2,0-2.2,1-2.2,2.2v1.5c-1.1,0.4-2.1,1-2.9,1.7l-1.3-0.8
                        c-1-0.6-2.4-0.2-3,0.8L13,7.7c-0.6,1-0.2,2.4,0.8,3l1.3,0.8C15,12,15,12.6,15,13.2c0,0.6,0.1,1.1,0.2,1.7l-1.3,0.8
                        c-1,0.6-1.4,1.9-0.8,3l1.3,2.2c0.4,0.7,1.1,1.1,1.9,1.1c0.4,0,0.7-0.1,1.1-0.3l1.3-0.8c0.9,0.7,1.9,1.3,2.9,1.7v1.5
                        c0,1.2,1,2.2,2.2,2.2h2.6C27.5,26.2,28.5,25.3,28.5,24.1z M26.2,23.9h-2.3v-2c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
                        c0,0,0,0,0-0.1c-0.1-0.4-0.4-0.6-0.8-0.7c-1.3-0.3-2.5-1-3.4-2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0,0,0
                        c-0.3-0.1-0.6-0.1-0.9,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2l-1.7,1l-1.2-2l1.7-1c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0
                        c0.4-0.3,0.5-0.7,0.4-1.2c-0.2-0.6-0.3-1.3-0.3-2c0-0.7,0.1-1.3,0.3-2c0-0.1,0-0.3,0-0.4c0-0.3-0.2-0.6-0.5-0.8
                        c-0.1-0.1-0.2-0.1-0.3-0.2l-1.7-1l1.2-2l1.7,1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0C18.5,8,18.6,8,18.7,8c0,0,0,0,0,0
                        c0,0,0,0,0,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.2l0,0c1-0.9,2.1-1.6,3.4-2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
                        c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.2-0.3c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3v-2
                        h2.3v2c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0.1,0.4,0.4,0.6,0.8,0.7c1.3,0.3,2.5,1,3.4,2c0,0,0,0,0,0
                        c0.1,0.1,0.2,0.2,0.4,0.2c0,0,0,0,0,0C31,8,31.1,8,31.2,8c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0
                        c0.1,0,0.2-0.1,0.3-0.2l1.7-1l1.2,2l-1.7,1C33.1,9.8,33,9.9,32.9,10c-0.2,0.2-0.4,0.4-0.5,0.8c0,0.2,0,0.3,0,0.5l0,0
                        c0.2,0.6,0.3,1.3,0.3,2c0,0.7-0.1,1.3-0.3,2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0.3,0.1,0.7,0.4,0.9c0,0,0,0,0.1,0.1
                        c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2l1.7,1l-1.2,2l-1.7-1c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0,0,0,0,0
                        c-0.3-0.1-0.6-0.1-0.9,0c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0c-1,0.9-2.1,1.6-3.4,2c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                        c-0.3,0.1-0.5,0.3-0.6,0.5c0,0,0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.2c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3L26.2,23.9
                        L26.2,23.9z"></path>
                     </svg>
               `
				},
				{
					title: 'Happy Customers',
					text: 'From first-gen entrepreneurs to retail behemoths stepping into online retailing, we have accelerated the eCommerce transformation of all kinds of enterprises. We would happy to make you a happy customer as well.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M11,46.9c0.5,0,0.8-0.4,0.8-0.8v-0.8h2.3l9.1,4.5c0.4,0.2,0.8,0.3,1.2,0.3c0.5,0,1-0.1,1.4-0.4L44.4,39
                        c0.8-0.5,1.4-1.4,1.4-2.4c0-0.8-0.4-1.6-1-2.1c-0.6-0.5-1.5-0.7-2.3-0.6l-16.8,2.9l-4.8-4.7c-0.2-0.2-0.4-0.2-0.6-0.2H11H7.6H0v1.7
                        h6.8v11.7H0v1.7h7.6H11z M11.9,33.5H20l6.7,6.6c0.3,0.3,0.5,0.7,0.5,1.1c0,0.8-0.7,1.5-1.5,1.5c-0.4,0-0.8-0.2-1.1-0.4l-4.4-4.3
                        l-1.2,1.2l4.4,4.3c0.6,0.6,1.4,0.9,2.3,0.9c1.8,0,3.2-1.4,3.2-3.2c0-0.9-0.3-1.7-0.9-2.3l-0.7-0.7l15.6-2.7c0.3-0.1,0.6,0,0.9,0.2
                        c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.2,0.7-0.5,0.9L25,48.2c-0.3,0.2-0.7,0.2-1,0l-9.2-4.6c-0.1-0.1-0.2-0.1-0.4-0.1h-2.5V33.5z
                        M8.5,33.5h1.7v11.7H8.5V33.5z"></path>
                        <path d="M24.6,11.7C24.6,5.3,19.3,0,12.7,0S0.8,5.3,0.8,11.7s5.3,11.7,11.9,11.7S24.6,18.2,24.6,11.7z M2.5,11.7
                        c0-5.5,4.6-10.1,10.2-10.1s10.2,4.5,10.2,10.1s-4.6,10.1-10.2,10.1S2.5,17.3,2.5,11.7z"></path>
                        <path d="M26.3,16.8c0,6.5,5.3,11.7,11.9,11.7S50,23.2,50,16.8S44.7,5,38.1,5S26.3,10.3,26.3,16.8z M38.1,6.7
                        c5.6,0,10.2,4.5,10.2,10.1s-4.6,10.1-10.2,10.1S28,22.3,28,16.8S32.5,6.7,38.1,6.7z"></path>
                        <path d="M11,10.1c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S11,11.4,11,10.1z M7.6,10.1c0-0.5,0.4-0.8,0.8-0.8
                        c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8C8,10.9,7.6,10.5,7.6,10.1z"></path>
                        <path d="M16.9,12.6c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5S15.5,12.6,16.9,12.6z M16.9,9.2
                        c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S16.5,9.2,16.9,9.2z"></path>
                        <path d="M33.9,17.6c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5S32.5,17.6,33.9,17.6z M33.9,14.2
                        c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8S33.4,14.2,33.9,14.2z"></path>
                        <path d="M42.4,17.6c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5S41,17.6,42.4,17.6z M42.4,14.2
                        c0.5,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8S41.9,14.2,42.4,14.2z"></path>
                        <path d="M35.6,20.1h-1.7c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2h-1.7c0,1.4-1.1,2.5-2.5,2.5S35.6,21.5,35.6,20.1z"></path>
                        <path d="M10.2,14.7H8.5c0,2.3,1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2h-1.7c0,1.4-1.1,2.5-2.5,2.5S10.2,16,10.2,14.7z"></path>
                     </svg>
               `
				},
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
