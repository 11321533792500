<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/travel-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Modernize travel experience with a mobile app
					</h2>
					<p>
						We travel for the experience — to experience the thrill of visiting a new place, culture
						and food. Until the last decade, travel magazines and tour operators helped with going
						to places. But, it was a cumbersome process. Enjoying a good trip was like winning a
						jackpot. Travel mobile apps have rewritten the laws of the game. They make travel
						planning, booking and even navigating during the travel a hassle-free affair.
					</p>
					<p>
						GenixBit Labs is a travel app development company that caters to its client needs to build
						high-tech mobile apps within the shortest time possible. We build mobile apps for
						booking flights, planning tours, exploring new destinations and also explore
						destinations virtually with 3D visuals.
					</p>
					<p>
						What are you waiting for? Grow with the explosive growth of the travel and tourism
						industry. Let your business take flight with our travel app development services.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
