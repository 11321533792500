<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <OurOffer></OurOffer>

      <Process></Process>

      <TypeDiv></TypeDiv>

      <Values></Values>

      <Portfolio></Portfolio>
      <Const></Const>




  </div>
</template>

<script>
import Banner from '../../components/MobileDevelopment/Main/Banner.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/MobileDevelopment/Main/About.vue'
import OurOffer from '../../components/MobileDevelopment/Main/OurOffer.vue'
import Process from '../../components/Home/Process.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Values from '../../components/MobileDevelopment/Main/Values.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        OurOffer,
        Process,
        TypeDiv,
        Values,
        Portfolio,
        Const,
    }
}
</script>


<style>

</style>