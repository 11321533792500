<template>
	<div class="tab_list_title-wrap d-flex align-items-center">
		<div class="tab_list_title_icon" v-html="obj.svg"></div>
		<div class="tab_list_title">{{ obj.title }}</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.ins-msg-tab-wrap .tab_list_title_icon {
	width: 40px;
	height: 40px;
}
.ins-msg-tab-wrap li.active .tab_list_title,
.ins-msg-tab-wrap li:hover .tab_list_title {
	color: #fff;
}
.ins-msg-tab-wrap li.active .tab_list_title_icon,
.ins-msg-tab-wrap li:hover .tab_list_title_icon {
	fill: #fff;
}

.ins-msg-tab-wrap .tab_list_title {
	font-size: 16px;
	font-weight: 500;
	margin-left: 20px;
	width: calc(100% - 60px);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
</style>