<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<InnerService></InnerService>

		<OurOffers></OurOffers>

		<Frameworks></Frameworks>

		<TypeDiv></TypeDiv>

		<WhyChooseUs></WhyChooseUs>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/WebDevelopment/Main/Banner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import InnerService from '../../components/WebDevelopment/Main/InnerService.vue';
import OurOffers from '../../components/WebDevelopment/Main/OurOffers.vue';
import Frameworks from '../../components/WebDevelopment/Main/Frameworks.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import WhyChooseUs from '../../components/WebDevelopment/Main/WhyChooseUs.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		InnerService,
		WhyChooseUs,
		OurOffers,
		Frameworks,
		TypeDiv,
		Const
	}
};
</script>

<style></style>
