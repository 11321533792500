<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Our unique traits </span>
				<h2 class="fxt-process-dev">Why choose us?</h2>
				<p>
					Choosing a mobile app development company is not an easy decision. Know what makes us good
					and why you should choose us for your health and fitness app development.
				</p>
			</div>
			<div class="row real-estate-type-main" style="justify-content: center">
				<div class="col-md-4 col-sm-6 col-12 mb-30" v-for="item in items" v-bind:key="item.title">
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard.vue';
export default {
	components: {
		SqureCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M18.3,25c-0.5,0-0.8,0.4-0.8,0.9l0.8,15.5L16.7,43L15,41.3l0.8-15.5c0-0.5-0.3-0.9-0.8-0.9c-0.5,0-0.9,0.3-0.9,0.8
                  l-0.8,15.8c0,0.2,0.1,0.5,0.2,0.6l2.5,2.5c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l2.5-2.5c0.2-0.2,0.3-0.4,0.2-0.6l-0.8-15.8
                  C19.1,25.3,18.7,25,18.3,25z"></path>
                  <path d="M4.2,43.3H1.7V29.8c0-1.2,0.9-2.6,2-3.1l9.9-4.3c0.3-0.1,0.5-0.4,0.5-0.8v-2.9c0-0.3-0.2-0.6-0.5-0.8c0,0-1.5-0.8-2.5-4.3
                  c0-0.1-0.1-0.2-0.2-0.3c0,0-0.8-0.8-1-1.9c-0.1-0.6,0-1.2,0.1-1.4c0.4-0.1,0.6-0.5,0.5-0.9c-0.2-0.9-0.3-3.7,0.6-5.2
                  c1.3-1.9,4-2.4,5.5-2.4s4.2,0.5,5.5,2.4c1,1.5,0.8,4.2,0.6,5.2c-0.1,0.4,0.1,0.8,0.5,0.9c0.1,0.2,0.2,0.8,0.1,1.4
                  c-0.2,1-0.9,1.9-1,1.9c-0.1,0.1-0.2,0.2-0.2,0.3c-1,3.5-2.5,4.3-2.5,4.3c-0.3,0.1-0.5,0.4-0.5,0.8v2.9c0,0.3,0.2,0.6,0.5,0.8
                  l9.9,4.3c0.2,0.1,0.4,0.2,0.7,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.6-0.3c0.3-0.4,0.2-0.9-0.1-1.2
                  c-0.3-0.3-0.7-0.5-1.1-0.7l-9.4-4.1v-1.9c0.7-0.5,2-1.8,2.8-4.8c0.3-0.4,1-1.3,1.2-2.5c0.2-0.7,0.2-2.1-0.5-2.8
                  c0.1-1.2,0.3-4-1-5.9C22,0.8,18.9,0,16.7,0s-5.4,0.8-6.9,3.1C8.6,5,8.7,7.8,8.8,9c-0.7,0.7-0.6,2.1-0.5,2.8
                  C8.6,13,9.3,14,9.6,14.3c0.9,3,2.1,4.3,2.8,4.8v1.9L3,25.2c-1.7,0.7-3,2.8-3,4.6v14.4C0,44.6,0.4,45,0.8,45h3.3
                  C4.6,45,5,44.6,5,44.2S4.6,43.3,4.2,43.3z"></path>
                  <path d="M6.9,43.6c-0.2,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6C7.1,44.9,7.3,45,7.5,45c0.2,0,0.4-0.1,0.6-0.2
                  c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C7.8,43.3,7.2,43.3,6.9,43.6z"></path>
                  <path d="M49.3,32.7l-8.6-1.5l-4.1-7.7c-0.3-0.5-1.2-0.5-1.5,0L31,31.2l-8.6,1.5c-0.3,0.1-0.6,0.3-0.6,0.6c-0.1,0.3,0,0.6,0.2,0.8
                  l6.1,6.3L26.8,49c0,0.3,0.1,0.6,0.3,0.8c0.3,0.2,0.6,0.2,0.9,0.1l7.9-3.9l7.9,3.9c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2
                  c0.2-0.2,0.4-0.5,0.3-0.8l-1.2-8.7l6.1-6.3c0.2-0.2,0.3-0.5,0.2-0.8C49.9,32.9,49.6,32.7,49.3,32.7z M42.2,39.5
                  c-0.2,0.2-0.3,0.4-0.2,0.7l1.1,7.5l-6.8-3.3c-0.1-0.1-0.2-0.1-0.4-0.1s-0.3,0-0.4,0.1l-6.8,3.3l1.1-7.5c0-0.3,0-0.5-0.2-0.7
                  L24.2,34l7.5-1.3c0.3,0,0.5-0.2,0.6-0.4l3.6-6.7l3.6,6.7c0.1,0.2,0.3,0.4,0.6,0.4l7.5,1.3L42.2,39.5z"></path>
                  <path d="M49.3,13.2l-4.1-0.7l-2-3.7c-0.3-0.5-1.2-0.5-1.5,0l-2,3.7l-4.1,0.7c-0.3,0.1-0.6,0.3-0.6,0.6c-0.1,0.3,0,0.6,0.2,0.8
                  l2.9,3l-0.6,4.1c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.6,0.2,0.9,0.1l3.8-1.8l3.8,1.8c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3-0.1,0.5-0.2
                  c0.3-0.2,0.4-0.5,0.3-0.8l-0.6-4.1l2.9-3c0.2-0.2,0.3-0.5,0.2-0.8C49.9,13.5,49.6,13.2,49.3,13.2z M45.4,16.7
                  c-0.2,0.2-0.3,0.4-0.2,0.7l0.4,3l-2.7-1.3C42.8,19,42.6,19,42.5,19s-0.3,0-0.4,0.1l-2.7,1.3l0.4-3c0-0.3,0-0.5-0.2-0.7l-2.1-2.2
                  l3-0.5c0.3,0,0.5-0.2,0.6-0.4l1.4-2.7l1.4,2.7c0.1,0.2,0.3,0.4,0.6,0.4l3,0.5L45.4,16.7z"></path>
                  <path d="M38.3,4.2c-0.1-0.3-0.3-0.5-0.6-0.6l-3-0.5l-1.4-2.7c-0.3-0.5-1.2-0.5-1.5,0l-1.4,2.7l-3,0.5c-0.3,0.1-0.6,0.3-0.6,0.6
                  c-0.1,0.3,0,0.6,0.2,0.8L29,7.2l-0.4,3c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.6,0.2,0.9,0.1l2.7-1.3l2.7,1.3c0.1,0.1,0.2,0.1,0.4,0.1
                  c0.2,0,0.3-0.1,0.5-0.2c0.3-0.2,0.4-0.5,0.3-0.8l-0.4-3L38.1,5C38.3,4.8,38.4,4.5,38.3,4.2z M34.5,6.4c-0.2,0.2-0.3,0.4-0.2,0.7
                  l0.3,1.8l-1.7-0.8C32.7,8,32.6,8,32.5,8c-0.1,0-0.3,0-0.4,0.1l-1.7,0.8l0.3-1.8c0-0.3,0-0.5-0.2-0.7L29.2,5L31,4.7
                  c0.3,0,0.5-0.2,0.6-0.4l0.9-1.6l0.9,1.7c0.1,0.2,0.3,0.4,0.6,0.4L35.8,5L34.5,6.4z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Industry expertise',
					text: 'Our inches-wide, miles deep industry expertise will help you create mobile apps that are bespoke to the industry requirements, practices and user expectations.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M47,7.9c-1.7,0-3,1.3-3,3c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3C50,9.3,48.7,7.9,47,7.9z M47,12.6c-0.9,0-1.6-0.7-1.6-1.6
                  c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C48.6,11.8,47.9,12.6,47,12.6z"></path>
                  <path d="M2.4,8h5.2c0.3,0,0.5-0.1,0.6-0.4c0.1-0.2,0.1-0.5,0-0.7L5.6,2.8c-0.3-0.4-0.9-0.4-1.2,0L1.9,6.9c-0.1,0.2-0.1,0.5,0,0.7
                  C2,7.8,2.2,8,2.4,8z M5,4.5l1.4,2.1H3.7L5,4.5z"></path>
                  <path d="M19.7,9h5.2c0.2,0,0.5-0.1,0.6-0.4c0.1-0.2,0.1-0.5,0-0.7l-2.6-4.1c-0.3-0.4-0.9-0.4-1.2,0L19.1,8c-0.1,0.2-0.1,0.5,0,0.7
                  C19.2,8.9,19.4,9,19.7,9z M22.3,5.5l1.4,2.1h-2.7L22.3,5.5z"></path>
                  <path d="M44.5,40.2h-0.2V16.8c0-0.8-0.6-1.4-1.4-1.4h-7.4c-0.8,0-1.4,0.6-1.4,1.4v23.3H29V24.7c0-0.8-0.6-1.4-1.4-1.4h-7.4
                  c-0.8,0-1.4,0.6-1.4,1.4v15.5h-5.1V20.5c0-0.8-0.6-1.4-1.4-1.4H4.8c-0.8,0-1.4,0.6-1.4,1.4v19.7H3.2c-1.7,0-3.2,1.4-3.2,3.2
                  c0,1.7,1.4,3.2,3.2,3.2h41.4c1.7,0,3.2-1.4,3.2-3.2C47.7,41.6,46.3,40.2,44.5,40.2z M35.5,16.8l7.4,0v23.3h-7.5L35.5,16.8z
                  M20.1,24.6l7.4,0v15.5h-7.5L20.1,24.6z M4.8,20.4l7.4,0v19.7H4.8L4.8,20.4z M44.5,45.1H3.2c-1,0-1.8-0.8-1.8-1.8
                  c0-1,0.8-1.8,1.8-1.8h41.4c1,0,1.8,0.8,1.8,1.8C46.3,44.3,45.5,45.1,44.5,45.1z"></path>
                  <path d="M4.4,13l1.8,3.4c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0.1,0.4,0,0.5,0l6.8-3.6l4.8,6.9c0.1,0.2,0.3,0.3,0.6,0.3
                  c0.1,0,0.2,0,0.3-0.1l17.2-9l1,1.9c0.1,0.2,0.3,0.4,0.6,0.4c0.2,0,0.5-0.1,0.6-0.3L44,5.3c0.1-0.2,0.1-0.5,0-0.7s-0.3-0.4-0.6-0.4
                  l-9.5-0.3c-0.2,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.1,0.5,0,0.7l1,1.9l-13.5,7.1l-4.8-7c-0.2-0.3-0.6-0.4-0.9-0.2L4.7,12.1
                  C4.4,12.3,4.2,12.7,4.4,13z M15.2,8.2l4.8,6.9c0.2,0.3,0.6,0.4,0.9,0.2l14.7-7.7c0.2-0.1,0.3-0.2,0.3-0.4c0.1-0.2,0-0.4,0-0.5
                  l-0.8-1.4l7.1,0.3l-3.8,6.1l-0.8-1.5c-0.2-0.3-0.6-0.5-0.9-0.3l-17.2,9l-4.8-6.9c-0.1-0.2-0.3-0.3-0.6-0.3c-0.1,0-0.2,0-0.3,0.1
                  l-6.7,3.5L6,13L15.2,8.2z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Long-standing experience',
					text: 'Genixbit has been building mobile apps for almost close to a decade. Our long-standing experience contributes to perfect commissioning of apps in the first go.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path class="st0" d="M49.7,13.9l-13-13.6l0,0l0,0l0,0C36.6,0.1,36.4,0,36.2,0H8.6C8.1,0,7.8,0.3,7.8,0.8v18.9h-7
                  C0.3,19.7,0,20,0,20.5v2.7c0,0.5,0.3,0.8,0.8,0.8h0.3v1.3c0,1.5,0.6,3,1.7,4.1l3.6,3.9l-3.6,3.9c-1.1,1.1-1.7,2.6-1.7,4.1v1.3H0.8
                  c-0.5,0-0.8,0.3-0.8,0.8v2.7c0,0.5,0.3,0.8,0.8,0.8h7v2.6c0,0.5,0.3,0.8,0.8,0.8h40.7c0.5,0,0.8-0.3,0.8-0.8V14.5
                  C50,14.2,49.9,14,49.7,13.9z M37,2.7l10.5,11.1H37V2.7z M1.5,21.2h13v1.1c-4.6,0-8.6,0-13,0V21.2z M2.6,25.2v-1.3h10.8v1.3
                  c0,1.1-0.5,2.2-1.2,3.1l-3.8,4.1c-0.2,0.2-0.7,0.2-0.8,0l-3.8-4.1C3,27.4,2.6,26.3,2.6,25.2z M2.6,41.3c0-1.1,0.5-2.2,1.2-3.1
                  l3.8-4.1c0.2-0.2,0.7-0.2,0.8,0l3.8,4.1c0.8,0.8,1.2,2,1.2,3.1v1.3H2.6V41.3z M1.5,44.2c4.6,0,8.6,0,13,0v1.1h-13V44.2z M9.3,48.5
                  v-1.7h6c0.5,0,0.8-0.3,0.8-0.8v-2.7c0-0.5-0.3-0.8-0.8-0.8H15v-1.3c0-1.5-0.6-3-1.7-4.1l-3.6-3.9l3.6-3.9c1.1-1.1,1.7-2.6,1.7-4.1
                  V24h0.3c0.5,0,0.8-0.3,0.8-0.8v-2.8c0-0.5-0.3-0.8-0.8-0.8h-6V1.5h26.1v13c0,0.5,0.3,0.8,0.8,0.8h12.3v33.3H9.3z"></path>
                </g>
                <g>
                  <path class="st0" d="M31.4,14.3V6c0-0.5-0.3-0.8-0.8-0.8h-17c-0.5,0-0.8,0.3-0.8,0.8v8.3c0,0.5,0.3,0.8,0.8,0.8h16.9
                  C31,15.1,31.4,14.7,31.4,14.3z M29.8,13.6H14.4V6.7h15.4L29.8,13.6L29.8,13.6z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.2,21.1H19c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h15.2c0.5,0,0.8-0.3,0.8-0.8
                  C34.9,21.4,34.5,21.1,34.2,21.1z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.2,26.4H19c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h15.2c0.5,0,0.8-0.3,0.8-0.8S34.5,26.4,34.2,26.4
                  z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.2,31.8H19c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h15.2c0.5,0,0.8-0.3,0.8-0.8
                  C34.9,32.2,34.5,31.8,34.2,31.8z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.2,37.2H19c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h15.2c0.5,0,0.8-0.3,0.8-0.8
                  C34.9,37.6,34.5,37.2,34.2,37.2z"></path>
                </g>
                <g>
                  <path class="st0" d="M34.2,42.7H19c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h15.2c0.5,0,0.8-0.3,0.8-0.8S34.5,42.7,34.2,42.7z"></path>
                </g>
                <g>
                  <path class="st0" d="M37.6,30.2h7.8c0.5,0,0.8-0.3,0.8-0.8v-2.6c0-0.5-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v1.8h-6.3v-6.2h7
                  c0.5,0,0.8-0.3,0.8-0.8c0-0.5-0.3-0.8-0.8-0.8h-7.8c-0.5,0-0.8,0.3-0.8,0.8v7.7C36.8,29.9,37.2,30.2,37.6,30.2z"></path>
                </g>
                <g>
                  <path class="st0" d="M45.5,23.3l-4.2,3l-0.8-1.7c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.5,0.7-0.3,1l1.3,2.5c0.1,0.2,0.3,0.3,0.5,0.4
                  s0.5,0,0.6-0.2l4.9-3.5c0.3-0.2,0.5-0.7,0.2-1.1C46.4,23,45.9,23,45.5,23.3z"></path>
                </g>
                <g>
                  <path class="st0" d="M45.4,38.9c-0.5,0-0.8,0.3-0.8,0.8v1.8h-6.3v-6.2h7c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-7.8
                  c-0.5,0-0.8,0.3-0.8,0.8v7.7c0,0.5,0.3,0.8,0.8,0.8h7.8c0.5,0,0.8-0.3,0.8-0.8v-2.6C46.1,39.2,45.8,38.9,45.4,38.9z"></path>
                </g>
                <g>
                  <path class="st0" d="M45.5,35.9l-4.2,3l-0.8-1.7c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.5,0.7-0.3,1l1.3,2.5c0.1,0.2,0.3,0.3,0.5,0.4
                  s0.5,0,0.6-0.2l4.9-3.5c0.3-0.2,0.5-0.7,0.2-1.1C46.4,35.8,45.9,35.7,45.5,35.9z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Time-bound deliverables',
					text: 'We take pride in being one of those companies which can deliver completed projects as per agreed timelines. We have dedicated project managers who get things done proactively.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <style type="text/css">
                .hallmarkst0{clip-path:url(#SVGID_2_);}
                .hallmarkst1{clip-path:url(#SVGID_4_);}
              </style>
              <g>
                <g>
                  <path id="b" d="M17.6,30.7c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7S17.2,30.7,17.6,30.7z M31.8,25h1.4
                  c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.4c0.4,0,0.7,0.3,0.7,0.7
                  c0,0.4-0.3,0.7-0.7,0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-6
                  c-0.7,0-1.4-0.1-2-0.3l-2.7-0.9v-8.8c1.1-0.2,2.2-0.9,3.2-2.1c1-1.1,1.7-2.6,2.1-4l0.3-1h0.2c0.8,0,1.4,0.6,1.4,1.4v0.7
                  c0,1-0.3,2.8-0.7,3.6h-1.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h7.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-1.4
                  c-0.4,0-0.7,0.3-0.7,0.7S31.4,25,31.8,25z M19.7,31.4c0,0.4-0.3,0.7-0.7,0.7h-3.6V20.8H19c0.4,0,0.7,0.3,0.7,0.7V31.4z M34.8,24.3
                  c0.3-0.4,0.5-0.9,0.5-1.4c0-1.2-1-2.1-2.1-2.1h-4.1c0.4-1.2,0.6-2.9,0.6-3.6v-0.7c0-1.6-1.3-2.9-2.8-2.9h-0.7
                  c-0.3,0-0.6,0.2-0.7,0.5l-0.4,1.6c-0.5,2.2-2.3,4.6-4.1,5c-0.3-0.8-1.1-1.4-2-1.4h-4.3c-0.4,0-0.7,0.3-0.7,0.7v12.8
                  c0,0.4,0.3,0.7,0.7,0.7H19c0.9,0,1.6-0.5,1.9-1.2l2.4,0.8c0.8,0.3,1.6,0.4,2.5,0.4h6c1.2,0,2.1-1,2.1-2.1c0-0.3-0.1-0.5-0.1-0.8
                  c0.9-0.2,1.6-1.1,1.6-2.1c0-0.5-0.2-1-0.5-1.4c0.3-0.4,0.5-0.9,0.5-1.4C35.4,25.2,35.2,24.7,34.8,24.3z M34.8,39.5
                  c-0.3-0.5-0.9-0.6-1.3-0.3c-2.6,1.6-5.5,2.3-8.4,2.3c-4.3,0-8.5-1.7-11.7-4.8c-4.8-4.8-6.2-11.9-3.5-18.1c0.2-0.5,0-1.1-0.5-1.3
                  c-0.5-0.2-1.1,0-1.3,0.5c-1.4,3.3-1.8,7-1.1,10.6c0.7,3.7,2.4,7,5.1,9.7c3.6,3.6,8.3,5.4,13.1,5.4c3.2,0,6.5-0.8,9.4-2.6
                  C34.9,40.6,35,40,34.8,39.5z M38.1,36.7c-0.4-0.4-1-0.4-1.4,0l0,0c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3
                  c0.2,0,0.5-0.1,0.7-0.3l0,0C38.4,37.7,38.4,37.1,38.1,36.7z M43.1,21.6c-0.7-3.7-2.4-7-5.1-9.7c-6-6-15.2-7.1-22.5-2.8
                  c-0.5,0.3-0.6,0.9-0.3,1.3c0.3,0.5,0.9,0.6,1.3,0.3c6.5-3.9,14.8-2.8,20.1,2.5c4.8,4.8,6.2,11.9,3.5,18.1c-0.2,0.5,0,1.1,0.5,1.3
                  c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6C43.4,28.9,43.8,25.2,43.1,21.6z M13.3,11.9c-0.4-0.4-1-0.4-1.4,0l0,0
                  c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l0,0C13.7,12.9,13.7,12.3,13.3,11.9z M46.2,26.2
                  c-0.6,1-0.8,2.2-0.4,3.4l0.8,2.6c0.3,0.9-0.1,2-0.9,2.5L43.4,36c-1,0.6-1.7,1.6-1.9,2.8L41,41.5c-0.2,1-1,1.7-2,1.8l-2.7,0.1
                  c-1.2,0.1-2.3,0.6-3,1.6l-1.6,2.1c-0.6,0.8-1.7,1.1-2.6,0.6l-2.4-1.1c-1.1-0.5-2.3-0.5-3.4,0l-2.4,1.1c-0.9,0.4-2,0.2-2.6-0.6
                  L16.7,45c-0.7-1-1.8-1.5-3-1.6L11,43.2c-1-0.1-1.8-0.8-2-1.8l-0.5-2.7c-0.2-1.2-0.9-2.2-1.9-2.8l-2.3-1.4
                  c-0.9-0.5-1.2-1.5-0.9-2.5l0.8-2.6c0.4-1.1,0.2-2.4-0.4-3.4l-1.4-2.3C1.9,23,2,21.9,2.7,21.3l1.9-1.9c0.8-0.8,1.3-2,1.2-3.2
                  l-0.2-2.7c-0.1-1,0.5-1.9,1.5-2.2l2.6-0.8c1.1-0.3,2.1-1.2,2.5-2.3l1.1-2.5c0.3-0.8,1.1-1.3,1.9-1.3c0.1,0,0.3,0,0.4,0L18.3,5
                  c1.2,0.2,2.4-0.1,3.3-0.8l2.1-1.7c0.8-0.6,1.9-0.6,2.6,0l2.1,1.7C29.3,5,30.5,5.3,31.7,5l2.6-0.5c1-0.2,1.9,0.3,2.3,1.2l1.1,2.5
                  c0.5,1.1,1.4,1.9,2.5,2.3l2.6,0.8c1,0.3,1.6,1.2,1.5,2.2l-0.2,2.7c-0.1,1.2,0.4,2.3,1.2,3.2l1.9,1.9c0.7,0.7,0.8,1.8,0.3,2.6
                  L46.2,26.2z M49.3,24.9c1-1.6,0.7-3.7-0.6-5L46.7,18c-0.4-0.4-0.7-1-0.6-1.7l0.2-2.7c0.1-1.9-1-3.6-2.9-4.2l-2.6-0.8
                  c-0.6-0.2-1.1-0.6-1.3-1.2L38.5,5c-0.8-1.7-2.6-2.7-4.5-2.4l-2.6,0.5c-0.6,0.1-1.2,0-1.7-0.4L27.5,1c-1.5-1.2-3.6-1.2-5.1,0
                  l-2.1,1.7c-0.5,0.4-1.1,0.5-1.7,0.4L16,2.6c-1.9-0.4-3.7,0.6-4.5,2.4l-1.1,2.5c-0.2,0.6-0.7,1-1.3,1.2L6.6,9.4
                  c-1.8,0.6-3,2.3-2.9,4.2l0.2,2.7c0,0.6-0.2,1.2-0.6,1.7l-1.9,1.9c-1.4,1.3-1.6,3.4-0.6,5l1.4,2.3c0.3,0.5,0.4,1.2,0.2,1.7
                  l-0.8,2.6c-0.6,1.8,0.2,3.8,1.8,4.7l2.3,1.4c0.5,0.3,0.9,0.8,1,1.5l0.5,2.7c0.3,1.9,1.9,3.3,3.8,3.4l2.7,0.1
                  c0.6,0,1.2,0.3,1.6,0.8l1.6,2.1c0.8,1,2,1.6,3.2,1.6c0.6,0,1.2-0.1,1.7-0.4l2.4-1.1c0.6-0.3,1.2-0.3,1.8,0l2.4,1.1
                  c1.7,0.8,3.8,0.3,4.9-1.2l1.6-2.1c0.4-0.5,1-0.8,1.6-0.8l2.7-0.1c1.9-0.1,3.5-1.5,3.8-3.4l0.5-2.7c0.1-0.6,0.5-1.1,1-1.5l2.3-1.4
                  c1.6-1,2.4-2.9,1.8-4.7L47.6,29c-0.2-0.6-0.1-1.2,0.2-1.7L49.3,24.9z"></path>
                </g>
                <g>
                  <defs>
                    <path id="SVGID_1_" d="M17.6,30.7c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7S17.2,30.7,17.6,30.7z M31.8,25h1.4
                    c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.4c0.4,0,0.7,0.3,0.7,0.7
                    c0,0.4-0.3,0.7-0.7,0.7h-1.4c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-6
                    c-0.7,0-1.4-0.1-2-0.3l-2.7-0.9v-8.8c1.1-0.2,2.2-0.9,3.2-2.1c1-1.1,1.7-2.6,2.1-4l0.3-1h0.2c0.8,0,1.4,0.6,1.4,1.4v0.7
                    c0,1-0.3,2.8-0.7,3.6h-1.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h7.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-1.4
                    c-0.4,0-0.7,0.3-0.7,0.7S31.4,25,31.8,25z M19.7,31.4c0,0.4-0.3,0.7-0.7,0.7h-3.6V20.8H19c0.4,0,0.7,0.3,0.7,0.7V31.4z
                    M34.8,24.3c0.3-0.4,0.5-0.9,0.5-1.4c0-1.2-1-2.1-2.1-2.1h-4.1c0.4-1.2,0.6-2.9,0.6-3.6v-0.7c0-1.6-1.3-2.9-2.8-2.9h-0.7
                    c-0.3,0-0.6,0.2-0.7,0.5l-0.4,1.6c-0.5,2.2-2.3,4.6-4.1,5c-0.3-0.8-1.1-1.4-2-1.4h-4.3c-0.4,0-0.7,0.3-0.7,0.7v12.8
                    c0,0.4,0.3,0.7,0.7,0.7H19c0.9,0,1.6-0.5,1.9-1.2l2.4,0.8c0.8,0.3,1.6,0.4,2.5,0.4h6c1.2,0,2.1-1,2.1-2.1c0-0.3-0.1-0.5-0.1-0.8
                    c0.9-0.2,1.6-1.1,1.6-2.1c0-0.5-0.2-1-0.5-1.4c0.3-0.4,0.5-0.9,0.5-1.4C35.4,25.2,35.2,24.7,34.8,24.3z M34.8,39.5
                    c-0.3-0.5-0.9-0.6-1.3-0.3c-2.6,1.6-5.5,2.3-8.4,2.3c-4.3,0-8.5-1.7-11.7-4.8c-4.8-4.8-6.2-11.9-3.5-18.1c0.2-0.5,0-1.1-0.5-1.3
                    c-0.5-0.2-1.1,0-1.3,0.5c-1.4,3.3-1.8,7-1.1,10.6c0.7,3.7,2.4,7,5.1,9.7c3.6,3.6,8.3,5.4,13.1,5.4c3.2,0,6.5-0.8,9.4-2.6
                    C34.9,40.6,35,40,34.8,39.5z M38.1,36.7c-0.4-0.4-1-0.4-1.4,0l0,0c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3
                    c0.2,0,0.5-0.1,0.7-0.3l0,0C38.4,37.7,38.4,37.1,38.1,36.7z M43.1,21.6c-0.7-3.7-2.4-7-5.1-9.7c-6-6-15.2-7.1-22.5-2.8
                    c-0.5,0.3-0.6,0.9-0.3,1.3c0.3,0.5,0.9,0.6,1.3,0.3c6.5-3.9,14.8-2.8,20.1,2.5c4.8,4.8,6.2,11.9,3.5,18.1c-0.2,0.5,0,1.1,0.5,1.3
                    c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6C43.4,28.9,43.8,25.2,43.1,21.6z M13.3,11.9c-0.4-0.4-1-0.4-1.4,0l0,0
                    c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l0,0C13.7,12.9,13.7,12.3,13.3,11.9z M46.2,26.2
                    c-0.6,1-0.8,2.2-0.4,3.4l0.8,2.6c0.3,0.9-0.1,2-0.9,2.5L43.4,36c-1,0.6-1.7,1.6-1.9,2.8L41,41.5c-0.2,1-1,1.7-2,1.8l-2.7,0.1
                    c-1.2,0.1-2.3,0.6-3,1.6l-1.6,2.1c-0.6,0.8-1.7,1.1-2.6,0.6l-2.4-1.1c-1.1-0.5-2.3-0.5-3.4,0l-2.4,1.1c-0.9,0.4-2,0.2-2.6-0.6
                    L16.7,45c-0.7-1-1.8-1.5-3-1.6L11,43.2c-1-0.1-1.8-0.8-2-1.8l-0.5-2.7c-0.2-1.2-0.9-2.2-1.9-2.8l-2.3-1.4
                    c-0.9-0.5-1.2-1.5-0.9-2.5l0.8-2.6c0.4-1.1,0.2-2.4-0.4-3.4l-1.4-2.3C1.9,23,2,21.9,2.7,21.3l1.9-1.9c0.8-0.8,1.3-2,1.2-3.2
                    l-0.2-2.7c-0.1-1,0.5-1.9,1.5-2.2l2.6-0.8c1.1-0.3,2.1-1.2,2.5-2.3l1.1-2.5c0.3-0.8,1.1-1.3,1.9-1.3c0.1,0,0.3,0,0.4,0L18.3,5
                    c1.2,0.2,2.4-0.1,3.3-0.8l2.1-1.7c0.8-0.6,1.9-0.6,2.6,0l2.1,1.7C29.3,5,30.5,5.3,31.7,5l2.6-0.5c1-0.2,1.9,0.3,2.3,1.2l1.1,2.5
                    c0.5,1.1,1.4,1.9,2.5,2.3l2.6,0.8c1,0.3,1.6,1.2,1.5,2.2l-0.2,2.7c-0.1,1.2,0.4,2.3,1.2,3.2l1.9,1.9c0.7,0.7,0.8,1.8,0.3,2.6
                    L46.2,26.2z M49.3,24.9c1-1.6,0.7-3.7-0.6-5L46.7,18c-0.4-0.4-0.7-1-0.6-1.7l0.2-2.7c0.1-1.9-1-3.6-2.9-4.2l-2.6-0.8
                    c-0.6-0.2-1.1-0.6-1.3-1.2L38.5,5c-0.8-1.7-2.6-2.7-4.5-2.4l-2.6,0.5c-0.6,0.1-1.2,0-1.7-0.4L27.5,1c-1.5-1.2-3.6-1.2-5.1,0
                    l-2.1,1.7c-0.5,0.4-1.1,0.5-1.7,0.4L16,2.6c-1.9-0.4-3.7,0.6-4.5,2.4l-1.1,2.5c-0.2,0.6-0.7,1-1.3,1.2L6.6,9.4
                    c-1.8,0.6-3,2.3-2.9,4.2l0.2,2.7c0,0.6-0.2,1.2-0.6,1.7l-1.9,1.9c-1.4,1.3-1.6,3.4-0.6,5l1.4,2.3c0.3,0.5,0.4,1.2,0.2,1.7
                    l-0.8,2.6c-0.6,1.8,0.2,3.8,1.8,4.7l2.3,1.4c0.5,0.3,0.9,0.8,1,1.5l0.5,2.7c0.3,1.9,1.9,3.3,3.8,3.4l2.7,0.1
                    c0.6,0,1.2,0.3,1.6,0.8l1.6,2.1c0.8,1,2,1.6,3.2,1.6c0.6,0,1.2-0.1,1.7-0.4l2.4-1.1c0.6-0.3,1.2-0.3,1.8,0l2.4,1.1
                    c1.7,0.8,3.8,0.3,4.9-1.2l1.6-2.1c0.4-0.5,1-0.8,1.6-0.8l2.7-0.1c1.9-0.1,3.5-1.5,3.8-3.4l0.5-2.7c0.1-0.6,0.5-1.1,1-1.5l2.3-1.4
                    c1.6-1,2.4-2.9,1.8-4.7L47.6,29c-0.2-0.6-0.1-1.2,0.2-1.7L49.3,24.9z"></path>
                  </defs>
                  <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
                  </clipPath>
                  <g class="hallmarkst0">
                    <g>
                      <rect id="a" x="-1045.7" y="-1100.4" width="1591.8" height="2487.1"></rect>
                    </g>
                    <g>
                      <defs>
                        <rect id="SVGID_3_" x="-1045.7" y="-1100.4" width="1591.8" height="2487.1"></rect>
                      </defs>
                      <clipPath id="SVGID_4_">
                        <use xlink:href="#SVGID_3_" style="overflow:visible;"></use>
                      </clipPath>
                      <rect x="-6.1" y="-6.1" class="hallmarkst1" width="62.2" height="62.2"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </svg>`,
					title: 'Hallmark quality',
					text: 'Zero bugs. Perfect functioning as detailed in the scope of work. Glitch-free performance even during peak traffic. Expect nothing but hallmark quality from us.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <title>web reputation</title>
              <path d="M25,0C11.2,0,0,11.2,0,25c0,6.8,2.8,13.3,7.6,18l0,0c0,0,0,0,0,0c9.6,9.2,24.9,9.2,34.5,0c0,0,0,0,0,0l0,0
              c9.9-9.6,10.2-25.4,0.7-35.3C38.2,2.8,31.8,0,25,0z M25,1.8c12.5,0,22.7,9.8,23.2,22.3H1.8C2.3,11.6,12.5,1.8,25,1.8z M24.1,34.8
              c-3.2,0.1-6.3,0.8-9.2,2.1c-1.5-3.5-2.3-7.2-2.4-11h11.6V34.8z M24.1,36.6v11.6c-3.6-2.4-6.5-5.8-8.4-9.6
              C18.4,37.4,21.2,36.7,24.1,36.6z M20.7,47.8c-4.1-0.8-7.9-2.6-11-5.4c1.4-1.2,2.9-2.2,4.5-3.1C15.7,42.6,18,45.4,20.7,47.8z
              M25.9,48.2V36.6c2.9,0.1,5.7,0.8,8.4,1.9C32.4,42.4,29.5,45.8,25.9,48.2z M35.9,39.4c1.6,0.8,3.1,1.9,4.5,3.1
              c-3.1,2.7-6.9,4.6-11,5.4C32.1,45.4,34.3,42.5,35.9,39.4L35.9,39.4z M25.9,34.8v-8.9h11.6c-0.1,3.8-0.9,7.5-2.4,11
              C32.2,35.6,29.1,34.9,25.9,34.8z M1.8,25.9h8.9c0.1,4.1,1,8.1,2.6,11.8c-1.8,0.9-3.5,2.1-5,3.5C4.3,37.1,2,31.6,1.8,25.9z
              M41.6,41.2c-1.5-1.3-3.2-2.5-5-3.5c1.6-3.7,2.5-7.7,2.7-11.8h8.9C48,31.6,45.6,37.1,41.6,41.2L41.6,41.2z"></path>
              <path d="M19.6,12.9l1.8,1.3l-0.7,2.1c-0.2,0.7,0,1.5,0.6,1.9c0.6,0.4,1.4,0.4,2,0l1.8-1.3l1.8,1.3c0.3,0.2,0.6,0.3,1,0.3
              c0.9,0,1.7-0.8,1.7-1.7c0-0.2,0-0.3-0.1-0.5l-0.7-2.1l1.8-1.3c0.8-0.5,0.9-1.6,0.4-2.3c-0.3-0.5-0.9-0.7-1.5-0.7h-2.2l-0.7-2.1
              c-0.3-0.9-1.2-1.4-2.1-1.1c-0.5,0.2-0.9,0.6-1.1,1.1l-0.7,2.1h-2.2c-0.9,0-1.7,0.7-1.7,1.7C18.8,12,19.1,12.5,19.6,12.9L19.6,12.9z
              M22.8,11.6c0.7,0,1.4-0.5,1.6-1.2L25,8.7l0.6,1.8c0.2,0.7,0.9,1.2,1.6,1.2h1.9l-1.6,1.1c-0.6,0.4-0.8,1.2-0.6,1.9l0.6,1.8L26,15.3
              c-0.6-0.4-1.4-0.4-2,0l-1.6,1.1l0.6-1.8c0.2-0.7,0-1.5-0.6-1.9l-1.6-1.1H22.8z"></path>
              <path d="M35.1,18.4l-0.4,1.1c-0.2,0.6,0,1.2,0.5,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3l1-0.7l1,0.7
              c0.6,0.5,1.5,0.3,2-0.3c0.3-0.4,0.3-0.9,0.2-1.3l-0.4-1.1l1-0.7c0.5-0.4,0.7-1,0.5-1.6c-0.2-0.6-0.7-1-1.3-1h-1.2L39.2,14
              c-0.2-0.6-0.7-1-1.3-1c-0.6,0-1.1,0.4-1.3,1l-0.4,1.1H35c-0.8,0-1.4,0.6-1.4,1.4c0,0.5,0.2,0.9,0.6,1.2L35.1,18.4z M36.4,17
              c0.6,0,1.2-0.4,1.3-1l0.1-0.3l0.1,0.3c0.2,0.6,0.7,1,1.3,1h0.3l-0.2,0.2c-0.5,0.4-0.7,1-0.5,1.6l0.1,0.3l-0.2-0.2
              c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3L36.8,19l0.1-0.3c0.2-0.6,0-1.2-0.5-1.6L36.1,17H36.4z"></path>
              <path d="M8.4,17.7l1,0.7L9,19.6c-0.2,0.7,0.2,1.5,0.9,1.8c0.4,0.1,0.9,0.1,1.2-0.2l1-0.7l1,0.7c0.6,0.5,1.5,0.3,2-0.3
              c0.3-0.4,0.3-0.8,0.2-1.3l-0.4-1.1l1-0.7c0.6-0.4,0.8-1.3,0.4-2c-0.3-0.4-0.7-0.6-1.2-0.6h-1.2L13.5,14c-0.2-0.6-0.7-1-1.3-1
              c-0.6,0-1.2,0.4-1.3,1l-0.4,1.1H9.3c-0.6,0-1.2,0.4-1.3,1C7.7,16.7,7.9,17.4,8.4,17.7z M10.7,17c0.6,0,1.1-0.4,1.3-1l0.1-0.3
              l0.1,0.3c0.2,0.6,0.7,1,1.3,1h0.3l-0.2,0.2c-0.5,0.4-0.7,1-0.5,1.6l0.1,0.3L13,18.9c-0.2-0.2-0.5-0.3-0.8-0.3
              c-0.3,0-0.6,0.1-0.8,0.3L11.1,19l0.1-0.3c0.2-0.6,0-1.2-0.5-1.6L10.4,17H10.7z"></path>
            </svg>`,
					title: 'Market reputation',
					text: 'All popular review websites, communities and forums have rated Genixbit with 5-star reviews making us one of the best app development companies.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M30.9,26.8l-0.6,0c-0.5-2.7-1.8-5.1-3.6-7c-2.4-2.5-5.7-4-9.3-4s-6.9,1.5-9.3,4c-1.8,1.8-3,4.2-3.6,6.9l-0.8,0
                c-2,0-3.7,1.6-3.7,3.5V36c0,1.9,1.6,3.5,3.7,3.5H6c0.5,1.4,1.2,2.8,2,4c0.9,1.4,2,2.5,3.2,3.5c0,1.5,1.3,2.7,2.8,2.7h2.9
                c1.5,0,2.8-1.2,2.8-2.8s-1.2-2.8-2.8-2.8H14c-0.8,0-1.6,0.4-2.1,1c-0.9-0.7-1.7-1.6-2.3-2.6c-2.1-3.2-3.1-7.3-3.4-10.9
                c1-2.3,3.1-3.3,4.7-3.8c1.9-0.6,3.6-0.6,4.7-0.5c-0.2,0.4-0.3,0.7-0.2,1.1c0.3,0.9,1.4,1.3,3.3,1.3c2.9-0.1,7.3-1.4,9.2-4.5
                c0.5,1.3,0.8,2.8,0.8,4.3c0,3-0.6,6.8-2,10.2h0.4l0,0h3.8c2,0,3.7-1.6,3.7-3.5v-5.8C34.6,28.4,32.9,26.8,30.9,26.8z M14,46.1h2.9
                c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9H14c-0.5,0-0.9-0.4-0.9-0.9C13.2,46.5,13.5,46.1,14,46.1z M4.4,33.8l0.7,3.9H3.7
                c-1,0-1.8-0.7-1.8-1.6v-5.8c0-0.9,0.8-1.6,1.8-1.6h0.6c0,0.3,0,0.6,0,0.9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3l0,1.4l0,0
                c0,0,0,0,0,0.1l0,0L4.4,33.8z M23,26.8c-1.9,0.8-3.9,1.1-5,1c1.7-0.5,3.1-1.7,4-3.2c0.6-1,0.9-2.2,0.9-3.4l-1.9,0
                c0,0.9-0.3,1.7-0.7,2.5c-0.6,1.1-1.6,1.9-2.7,2.3c-0.4-0.3-1-0.4-1.9-0.5c-2.3-0.2-6.8,0.2-9.6,3c0.2-2.8,1.4-5.3,3.3-7.2
                c2-2.1,4.9-3.4,8-3.4s5.9,1.3,8,3.4c0.5,0.6,1,1.2,1.4,1.8C26.2,25,24.4,26.2,23,26.8z M32.7,36.2c0,0.9-0.8,1.6-1.8,1.6h-1.3
                c0.6-2.5,1-5.2,1-7.6c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.5c0-0.3,0-0.5,0-0.8h0.4c1,0,1.8,0.7,1.8,1.6V36.2z"></path>
                <path d="M31.6,0c-8.3,0-15.5,5.7-17.4,13.9l1.9,0.4C17.8,7,24.2,1.9,31.6,1.9c8.8,0,15.9,7.2,15.9,16c0,6.9-4.4,13-10.9,15.2
                l0.6,1.8c7.3-2.4,12.2-9.3,12.2-17C49.5,8.1,41.5,0,31.6,0z"></path>
                <polygon points="33.8,19.2 41.2,12.5 39.9,11.1 32.7,17.7 23.8,8.6 22.4,9.9 32.6,20.4  "></polygon>
              </g>
            </svg>`,
					title: 'All-time support',
					text: 'Call us, mail us, DM us on social handles — no matter how you reach us, we will be prepared to solve your queries in the shortest time span.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
