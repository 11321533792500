<template>
	<div>
		<ClientReviews v-if="!isCr"></ClientReviews>

		<OurWork></OurWork>

		<CallSchedule></CallSchedule>

		<Techno></Techno>

		<Blogs></Blogs>

		<Contact></Contact>

		<Partnership></Partnership>

		<OurPlaces></OurPlaces>

	</div>
</template>

<script>


import OurWork from '../Home/OurWork';
import ClientReviews from '../Home/ClientReviews';
import CallSchedule from '../Home/CallSchedule';
import Techno from '../Home/Techno';
import Blogs from '../Home/Blogs';
import Contact from '../Home/Contact';
import Partnership from '../Home/Partnership';
import OurPlaces from '../../components/Home/OurPlaces.vue';

export default {
	props: {
		isCr: {
			type: Boolean,
			required: false
		}
	},
	components: {
		OurWork,
		ClientReviews,
		CallSchedule,
		Techno,
		Blogs,
		Contact,
		Partnership,
		OurPlaces
	}
};
</script>

<style>
@media (max-width: 1200px) {
	.fxt-map-container {
		margin-bottom: 0 !important;
	}
}
</style>
