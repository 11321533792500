<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">Know our unique strengths</h2>
				<p>
					Like every successful mobile app that has its own handful of great features, GenixBit Labs
					also has some unique features that makes us awesome.
				</p>
			</div>
			<div class="row real-estate-type-main" style="justify-content: center">
				<div class="col-md-4 col-sm-6 col-12 mb-30" v-for="item in items" v-bind:key="item.title">
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';
export default {
	components: {
		SqureCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M98.4,8.7H1.6C0.7,8.7,0,9.4,0,10.3v79.4c0,0.9,0.7,1.6,1.6,1.6h96.9c0.9,0,1.6-0.7,1.6-1.6V10.3
                    C100,9.4,99.3,8.7,98.4,8.7z M96.9,88.1H3.1V24.3h93.7V88.1z M96.9,21.2H3.1v-9.3h93.7V21.2z M89.1,18.1h3.1
                    c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-3.1c-0.9,0-1.6,0.7-1.6,1.6C87.5,17.4,88.2,18.1,89.1,18.1z M79.7,18.1h3.1
                    c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-3.1c-0.9,0-1.6,0.7-1.6,1.6C78.1,17.4,78.8,18.1,79.7,18.1z M17.6,65.5L17.6,65.5
                    c0.4,1.2,1,2.4,1.7,3.5c-1.4,1.7-1.2,4.3,0.6,5.7c1.6,1.3,4,1.2,5.5-0.3c1.2,0.6,2.4,1,3.7,1.3c0.1,2.1,1.9,3.8,4.1,3.8h0.2
                    c2.2-0.1,3.9-2,3.8-4.3c0,0,0,0,0,0c1.2-0.4,2.4-1,3.5-1.7c1.7,1.4,4.3,1.1,5.7-0.6c1.3-1.6,1.2-4-0.3-5.4c0.6-1.2,1-2.4,1.3-3.7
                    c2.2-0.1,3.9-2.1,3.8-4.3c-0.1-2.2-2.1-3.9-4.3-3.8c-0.4-1.2-1-2.4-1.7-3.5c1.5-1.7,1.3-4.2-0.4-5.7c-1.7-1.5-4.2-1.3-5.7,0.4
                    c-1.2-0.6-2.4-1-3.7-1.3c-0.1-2.2-2.1-3.9-4.3-3.8c-2.2,0.1-3.9,2.1-3.8,4.3c-1.2,0.4-2.4,1-3.5,1.7c-1.7-1.5-4.2-1.3-5.7,0.3
                    c-1.5,1.7-1.3,4.2,0.3,5.7c-0.6,1.2-1,2.4-1.3,3.7c-2.2,0.2-3.9,2.1-3.7,4.3C13.5,64,15.4,65.6,17.6,65.5z M17.3,60.6L17.3,60.6
                    L17.3,60.6l1.3-0.1c0.8,0,1.4-0.6,1.5-1.4c0.2-1.7,0.8-3.4,1.7-4.9c0.4-0.6,0.3-1.5-0.3-2l-0.9-0.8c-0.4-0.3-0.4-0.9-0.1-1.3
                    c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.7,0.2l0.9,0.8c0.6,0.5,1.4,0.5,2,0.1c1.4-1.1,3-1.9,4.7-2.3c0.7-0.2,1.2-0.8,1.2-1.6
                    l-0.1-1.3c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.3,0.9,0.8l0.1,1.3c0,0.8,0.6,1.4,1.4,1.5c1.7,0.2,3.4,0.8,4.9,1.7
                    c0.7,0.4,1.5,0.3,2-0.3l0.8-0.9c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.7,0.2c0.4,0.3,0.4,0.9,0.1,1.3c0,0,0,0,0,0L42,51
                    c-0.5,0.6-0.5,1.4,0,2c1.1,1.4,1.9,3,2.3,4.7c0.2,0.7,0.9,1.2,1.6,1.2l1.3-0.1c0.5,0,0.9,0.4,1,0.9c0,0,0,0,0,0
                    c0,0.5-0.4,0.9-0.9,1h0l-1.3,0.1c-0.8,0-1.4,0.6-1.5,1.4c-0.2,1.7-0.8,3.4-1.7,4.9c-0.4,0.6-0.3,1.5,0.3,2l1,0.8
                    c0.4,0.3,0.4,0.9,0.1,1.3c0,0,0,0,0,0c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.7-0.2l-1-0.9c-0.6-0.5-1.4-0.6-2-0.1
                    c-1.4,1.1-3,1.9-4.7,2.3c-0.7,0.2-1.2,0.9-1.1,1.6l0.1,1.3c0,0.2-0.1,0.5-0.2,0.7c-0.3,0.4-0.9,0.4-1.3,0.1
                    c-0.2-0.2-0.3-0.4-0.3-0.6l-0.1-1.3c0-0.7-0.6-1.4-1.4-1.4c-1.7-0.2-3.4-0.8-4.9-1.7c-0.6-0.4-1.5-0.3-2,0.3L23,72.3
                    c-0.4,0.4-1,0.4-1.3,0c-0.3-0.3-0.4-0.8-0.1-1.2l0.8-0.9c0.5-0.6,0.5-1.4,0.1-2c-1.1-1.4-1.9-3-2.3-4.7c-0.2-0.7-0.9-1.2-1.6-1.2
                    l-1.3,0.1c-0.5,0-0.9-0.4-1-0.9C16.4,61,16.8,60.6,17.3,60.6z M27.3,68.3c4.4,2.5,10,0.9,12.5-3.5c2.3-4.1,1.1-9.2-2.7-11.9
                    c-4.4-2.5-10-0.9-12.5,3.5C22.4,60.5,23.5,65.6,27.3,68.3z M27.2,57.4c0.3-0.5,0.8-1,1.3-1.5v0c1.1-0.9,2.5-1.4,3.9-1.4
                    c1.1,0,2.3,0.3,3.2,0.9c2.8,1.8,3.7,5.5,1.9,8.3c-1.8,2.8-5.5,3.7-8.4,1.9C26.2,63.9,25.4,60.2,27.2,57.4z M52.8,56.3L52.8,56.3
                    l0.1,0c0.4,1.3,0.9,2.5,1.6,3.6c-1.5,1.7-1.4,4.2,0.3,5.7c1.6,1.4,4.1,1.4,5.6-0.1c1.2,0.6,2.4,1.1,3.7,1.4
                    c0.1,2.2,1.9,3.9,4.1,3.9h0.1c2.2-0.1,4-1.9,3.9-4.1c0,0,0,0,0,0c1.3-0.4,2.5-0.9,3.6-1.6c0.8,0.7,1.8,1.1,2.9,1.1
                    c2.2-0.1,4-1.9,3.9-4.1c0-1.1-0.5-2.1-1.3-2.8c0.6-1.2,1.1-2.4,1.4-3.7c2.2-0.1,4-1.9,4-4.1c-0.1-2.2-1.9-4-4.2-3.9
                    c-0.4-1.3-0.9-2.5-1.6-3.6c1.5-1.6,1.5-4.2-0.1-5.7C80,37.4,79,37,77.9,37.1c-1.1,0-2.1,0.5-2.8,1.2c-1.2-0.6-2.4-1.1-3.7-1.4
                    c-0.1-2.2-1.9-4-4.2-3.9c-2.2,0.1-4,1.9-4,4.2c-1.3,0.4-2.5,0.9-3.6,1.6c-1.6-1.5-4.2-1.5-5.7,0.2c-1.5,1.6-1.5,4.2,0.2,5.7
                    c-0.6,1.2-1.1,2.4-1.4,3.6c-2.2,0-4,1.9-4,4.1C48.7,54.5,50.6,56.3,52.8,56.3z M52.1,51.6L52.1,51.6c0.2-0.2,0.4-0.3,0.7-0.3H54
                    c0.8,0,1.4-0.6,1.5-1.3c0.3-1.7,0.9-3.4,1.9-4.8c0.4-0.6,0.3-1.5-0.2-2l-0.9-0.9c-0.3-0.4-0.3-1,0-1.3c0.3-0.3,0.9-0.3,1.2,0
                    l0.9,0.9c0.5,0.5,1.4,0.6,2,0.1c1.4-1,3.1-1.7,4.8-2.1c0.7-0.2,1.2-0.8,1.2-1.6v-1.3c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,1,0.9
                    v1.3c0,0.7,0.6,1.4,1.3,1.5c1.7,0.3,3.4,0.9,4.8,1.9c0.6,0.4,1.5,0.3,2-0.2l0.9-1c0.2-0.2,0.4-0.3,0.6-0.3c0.2,0,0.5,0.1,0.7,0.2
                    c0.4,0.4,0.4,0.9,0,1.3l-0.8,0.9c-0.5,0.5-0.6,1.4-0.1,2c1,1.4,1.8,3,2.1,4.7c0.2,0.7,0.8,1.3,1.6,1.2h1.3c0.5,0,0.9,0.4,0.9,0.9
                    c0,0.5-0.4,0.9-0.9,0.9h-1.3c-0.8,0-1.4,0.6-1.5,1.3c-0.3,1.7-0.9,3.4-1.9,4.8c-0.4,0.6-0.3,1.5,0.2,2l0.9,0.9
                    c0.4,0.4,0.4,0.9,0,1.3c0,0,0,0,0,0c-0.4,0.4-0.9,0.4-1.3,0c0,0,0,0,0,0L77,61.9c-0.6-0.5-1.4-0.5-2-0.1c-1.4,1-3,1.7-4.7,2.1
                    C69.5,64,69,64.7,69,65.4v1.3c0,0.2-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-0.5,0-0.9-0.4-0.9-0.9v-1.3c0-0.8-0.6-1.4-1.3-1.5
                    c-1.7-0.3-3.4-0.9-4.9-1.9c-0.6-0.4-1.5-0.3-2,0.2l-0.9,0.9c-0.4,0.3-1,0.3-1.3-0.1c-0.3-0.3-0.3-0.8,0-1.2l0.9-0.9
                    c0.5-0.5,0.6-1.4,0.2-2c-1-1.4-1.8-3-2.1-4.7c-0.2-0.7-0.8-1.3-1.6-1.2h-1.3c-0.5,0-0.9-0.4-0.9-0.9C51.9,52,52,51.8,52.1,51.6z
                    M62.5,59.4c4.3,2.8,10,1.6,12.7-2.7c2.7-4.1,1.7-9.5-2.3-12.4c-4.3-2.8-10-1.6-12.7,2.7C57.6,51.1,58.6,56.6,62.5,59.4z
                    M64.1,47.1L64.1,47.1c2.7-2,6.5-1.5,8.5,1.1c2,2.7,1.5,6.5-1.1,8.5c-2.7,2-6.5,1.5-8.5-1.1C60.9,52.9,61.4,49.1,64.1,47.1z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Customized Solutions',
					text: 'Your business is as unique in its own way. Your requirements would be unique in its own way. We offer customized solutions that will cater to those unique requirements.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M73.3,0H11.7C10.7,0,10,0.7,10,1.7V10H1.7C0.7,10,0,10.7,0,11.7v86.7c0,0.9,0.7,1.7,1.7,1.7h61.7c0.9,0,1.7-0.7,1.7-1.7
                    v-6.7h8.3c0.9,0,1.7-0.7,1.7-1.7V1.7C75,0.7,74.3,0,73.3,0z M61.7,96.7H3.3V13.3H45v15c0,0.9,0.7,1.7,1.7,1.7h15V96.7z M48.3,26.7
                    v-11l11,11H48.3z M71.7,88.3H65v-60c0-0.4-0.2-0.9-0.5-1.2l-3.8-3.8H65V20h-7.6l-5-5H55v-3.3h-6l-1.2-1.2
                    c-0.3-0.3-0.7-0.5-1.2-0.5H13.3V3.3h58.3V88.3z M95,25h-1.7c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7v65c0,0.3,0.1,0.6,0.2,0.9
                    l5,8.3c0.5,0.8,1.5,1,2.3,0.6c0.2-0.1,0.4-0.3,0.6-0.6l5-8.3c0.2-0.3,0.2-0.6,0.2-0.9V28.3H95c0.9,0,1.6,0.7,1.6,1.6v28.4h3.3V30
                    C100,27.2,97.8,25,95,25z M86.7,95.1l-2.1-3.4h4.1L86.7,95.1z M90,88.3h-6.7v-30H90V88.3z M90,55h-6.7V28.3H90V55z M83.3,25
                    c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3H83.3z M11.7,76.7h41.7v-3.3H11.7V76.7z M11.7,85h25v-3.3h-25V85z M21.7,68.3h31.7
                    V65H21.7V68.3z M18.3,65h-6.7v3.3h6.7V65z M45,41.7v15h-3.3V45h-3.3v11.7H35V40h-3.3v16.7h-3.3V45H25v11.7h-3.3v-5h-3.3v5H15V40
                    h-3.3v18.3c0,0.9,0.7,1.7,1.7,1.7h40v-3.3h-5v-15H45z M33.3,21.7H11.7V25h21.7V21.7z M26.7,30h-15v3.3h15V30z M65,11.7h-6.7V15H65
                    V11.7z M40,85h6.7v-3.3H40V85z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'NDA Compliance',
					text: 'All data, know-how and information that you share with us during the engagement will not be shared with third parties. We sign a non-disclosure agreement to put that in writing.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M50,71.9c12.1,0,21.9-9.8,21.9-21.9c0-12.1-9.8-21.9-21.9-21.9c-12.1,0-21.9,9.8-21.9,21.9C28.1,62.1,37.9,71.9,50,71.9z
                    M58,67c0.4-0.6,0.8-1.2,1.1-1.9c1-1.9,1.8-4.1,2.4-6.5h5.1C64.8,62.3,61.7,65.2,58,67z M68.7,50c0,1.9-0.3,3.7-0.8,5.5h-5.8
                    c0.3-1.8,0.4-3.6,0.4-5.5c0-1.9-0.1-3.7-0.4-5.5h5.8C68.5,46.3,68.7,48.1,68.7,50z M66.7,41.4h-5.1c-0.6-2.4-1.4-4.6-2.4-6.5
                    c-0.4-0.7-0.7-1.3-1.1-1.9C61.7,34.8,64.8,37.7,66.7,41.4z M51.6,31.5c1.7,0.6,3.4,2.2,4.8,4.8c0.8,1.4,1.4,3.1,1.9,5h-6.7V31.5z
                    M51.6,44.5h7.4c0.3,1.7,0.4,3.6,0.4,5.5c0,1.9-0.2,3.7-0.4,5.5h-7.4V44.5z M51.6,58.6h6.7c-0.5,1.9-1.2,3.6-1.9,5
                    c-1.4,2.6-3.1,4.2-4.8,4.8V58.6z M48.4,68.5c-1.7-0.6-3.4-2.2-4.8-4.8c-0.8-1.4-1.4-3.1-1.9-5h6.7V68.5z M48.4,55.5h-7.4
                    c-0.3-1.7-0.4-3.6-0.4-5.5c0-1.9,0.2-3.7,0.4-5.5h7.4V55.5z M48.4,31.5v9.9h-6.7c0.5-1.9,1.2-3.6,1.9-5
                    C45,33.8,46.7,32.2,48.4,31.5z M42,33c-0.4,0.6-0.8,1.2-1.1,1.9c-1,1.9-1.8,4.1-2.4,6.5h-5.1C35.2,37.7,38.3,34.8,42,33z M31.3,50
                    c0-1.9,0.3-3.7,0.8-5.5h5.8c-0.3,1.8-0.4,3.6-0.4,5.5c0,1.9,0.1,3.7,0.4,5.5h-5.8C31.5,53.7,31.3,51.9,31.3,50z M38.5,58.6
                    c0.6,2.4,1.4,4.6,2.4,6.5c0.4,0.7,0.7,1.3,1.1,1.9c-3.7-1.8-6.8-4.7-8.7-8.4H38.5z M82.2,78.6c0-0.1,0-0.1-0.1-0.2
                    c-0.7-1.5-1.9-2.5-3.3-3.1v0c-1.4-0.5-3-0.6-4.5,0l-14.6,5.2c-1-2.2-3.2-3.8-5.8-3.8H42.6l-9.5-5.5c-3.2-1.8-6.9-2.6-10.6-2.1v0
                    l-10.1,1.3v-1.8c0-0.9-0.7-1.6-1.6-1.6H1.6c-0.9,0-1.6,0.7-1.6,1.6v29.7c0,0.9,0.7,1.6,1.6,1.6h9.4c0.9,0,1.6-0.7,1.6-1.6v-2.7
                    l23.4,3.7c6.8,1.1,13.9,1,20.5-2L79.1,87c3.2-1.5,4.6-5.4,3-8.5L82.2,78.6z M77.9,84.2L55.1,94.5c-5.8,2.6-12.2,2.8-18.7,1.8
                    l-23.8-3.7v-5.1c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.6,0.7-1.6,1.5c0,0,0,0,0,0v9.4H3.1V70.3h6.2v6.3c0,0.9,0.7,1.6,1.5,1.6
                    c0.9,0,1.6-0.7,1.6-1.5v-2.9L23,72.4c3-0.4,6,0.2,8.6,1.7L41.8,80H54c1.7,0,3.1,1.4,3.1,3.1l0,1.3c0,1.7-1.3,3.1-3,3.1H36.2v3.1
                    h17.9c3.4,0,6.2-2.8,6.2-6.2l0-0.6l15.2-5.5c0,0,0,0,0.1,0c1.5-0.6,3.1,0,3.9,1.5c0,0,0,0,0,0C80.2,81.5,79.5,83.5,77.9,84.2z
                    M10.9,80.5c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S11.8,80.5,10.9,80.5z M98.4,0h-9.4
                    c-0.9,0-1.6,0.7-1.6,1.6v2.7L64.1,0.6c-3.4-0.5-6.9-0.8-10.3-0.5v0c-3.4,0.3-6.9,1-10.1,2.5L20.9,13c-3.2,1.4-4.5,5.2-3.1,8.4
                    c0,0,0,0,0,0l0.1,0.1c0,0,0-0.1-0.1-0.1c0,0.1,0,0.1,0.1,0.2c1.4,2.9,4.9,4.3,7.8,3l14.6-5.2c1,2.2,3.2,3.8,5.8,3.8h11.3l9.5,5.4
                    c3.2,1.8,6.9,2.6,10.6,2.1l10.1-1.3v1.8c0,0.9,0.7,1.6,1.6,1.6h9.4c0.9,0,1.6-0.7,1.6-1.6V1.6C100,0.7,99.3,0,98.4,0z M96.9,29.7
                    h-6.2v-6.2c0-0.9-0.7-1.6-1.5-1.6c-0.9,0-1.6,0.7-1.6,1.5c0,0,0,0,0,0v2.8L77,27.6c-3,0.4-6-0.2-8.6-1.7L58.2,20H46
                    c-1.7,0-3.1-1.4-3.1-3.1l0-1.3c0-1.7,1.3-3.1,3-3.1h17.9V9.4H45.9v0c-3.4,0-6.2,2.8-6.2,6.2l0,0.6l-15.2,5.5c0,0,0,0-0.1,0
                    c-1.5,0.6-3.1,0-3.9-1.5c0,0,0,0,0,0c-0.8-1.6-0.1-3.6,1.5-4.3L44.9,5.5c5.8-2.6,12.2-2.8,18.7-1.8l23.8,3.7v5.1
                    c0,0.9,0.7,1.6,1.5,1.6c0.9,0,1.6-0.7,1.6-1.5c0,0,0,0,0,0V3.1h6.2V29.7z M89.1,16.4c-0.9,0-1.6,0.7-1.6,1.6
                    c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6C90.6,17.1,89.9,16.4,89.1,16.4z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Deliver Global Experience',
					text: 'Our UI/UX designers with a creative bent of mind know how to make users fall in love with travel apps. We design and deploy mobile apps that offer an enriched user experience to users all around the world.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path d="M53.4,83.7c-4.1,0-7.2-0.6-8-0.8c-3.2,0-6.9-1.7-11.2-3.5c-8-3.5-17.1-7.5-24.1-0.8c-0.4,0.4-1,0.6-1.6,0.5
                c-0.6-0.1-1.1-0.5-1.3-1.1L0.1,60.1c-0.3-0.8,0-1.7,0.7-2.2c1.9-1.3,19-12.4,24.8-13.4c8.2-1.3,21.4,5.9,25.3,8.1
                c3.9,2.3,7.6,2.7,11.1,3c4,0.4,7.7,0.9,11.2,3.8c0.3,0.2,0.5,0.4,0.7,0.6c4.4-4.1,15.4-13.8,20.8-13c1.2,0.2,2.1,0.7,2.7,1.7
                c0.8,1,1.7,3.2,2.6,6.7c0.2,0.7-0.1,1.5-0.7,1.9c-0.1,0.1-13.2,9-18.3,14.6C71.9,81.8,60.9,83.7,53.4,83.7z M45.6,79.3
                c0.1,0,0.3,0,0.4,0.1c0.2,0,19,4.5,32.2-9.8c4.6-5,14.6-12.1,17.9-14.4c-0.8-2.9-1.4-3.8-1.5-3.9c-0.1-0.1-0.2-0.2-0.3-0.4
                c0-0.1-0.1-0.1-0.3-0.2c-3.3-0.5-13.2,7.5-19.3,13.4c-0.1,0.1-0.2,0.2-0.4,0.3c0,0,0,0.1,0,0.1c-2.4,5-17.3,7.6-20.6,6.9
                c-1.9-0.4-3.6-1.3-5.3-2.1c-1.9-1-3.7-1.9-5.4-1.7c-1,0.1-1.8-0.6-1.9-1.6c-0.1-1,0.6-1.9,1.6-2c2.7-0.2,5,0.9,7.3,2.1
                c1.5,0.8,2.9,1.5,4.4,1.8c2.3,0.5,11.8-1.3,15.6-4c0.8-0.6,1.1-1,1.1-1.1l0,0c0,0-0.1-0.2-0.5-0.5c-2.6-2.2-5.5-2.6-9.2-3
                c-3.7-0.4-7.9-0.9-12.5-3.5c-9.4-5.4-18.4-8.4-23-7.7c-3,0.5-10.6,4.8-16.7,8.5l6.3,14.9c6.7-1.3,13.8,1.8,19.8,4.5
                C39.5,77.8,43.1,79.4,45.6,79.3C45.6,79.3,45.6,79.3,45.6,79.3z M4,60.1l5.6,14.2c0.9-0.7,1.8-1.2,2.7-1.6l-6-14.1
                C5.5,59.2,4.7,59.7,4,60.1z M94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4z M94.9,51.4
                C94.9,51.4,94.9,51.4,94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4z M94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4
                C94.9,51.4,94.9,51.4,94.9,51.4z M94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4C94.9,51.4,94.9,51.4,94.9,51.4z M37.2,67.4
                c-0.1,0-0.1,0-0.2,0c0,0-2.2-0.2-5.1,0c-1,0-1.8-0.7-1.9-1.7c-0.1-1,0.7-1.9,1.7-1.9c3.2-0.2,5.5,0,5.6,0c1,0.1,1.7,1,1.6,2
                C38.9,66.7,38.1,67.4,37.2,67.4z M61.8,51.1c-9.5,0-17.2-7.8-17.2-17.4c0-9.6,7.7-17.4,17.2-17.4c9.5,0,17.2,7.8,17.2,17.4
                C79,43.3,71.3,51.1,61.8,51.1z M61.8,19.9c-7.5,0-13.6,6.2-13.6,13.8c0,7.6,6.1,13.8,13.6,13.8c7.5,0,13.6-6.2,13.6-13.8
                C75.4,26.1,69.3,19.9,61.8,19.9z M61.6,46.2c-1,0-1.8-0.8-1.8-1.8v-1.3c-1.6-0.3-2.9-1-3.1-1.1c-0.9-0.5-1.2-1.6-0.8-2.4
                c0.5-0.9,1.5-1.2,2.4-0.8c0.5,0.3,2,0.9,3.2,0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.8-0.5,1.1-1.5
                c0.3-1.3,0.1-1.7-2.8-2.9c-2.2-0.9-5.1-2-5.3-5.4c-0.1-2.4,1-3.7,1.9-4.4c0.7-0.5,1.4-0.7,2.2-0.9v-1.2c0-1,0.8-1.8,1.8-1.8
                c0,0,0,0,0,0c1,0,1.8,0.8,1.8,1.8v1.3c1.3,0.3,2.4,0.7,2.8,0.8c0.9,0.4,1.4,1.4,1,2.3c-0.4,0.9-1.4,1.4-2.3,1
                c-1.6-0.6-4.3-1.2-5.2-0.5c-0.1,0.1-0.4,0.3-0.4,1.3c0,0.9,0.8,1.3,3,2.2c2.3,0.9,6.2,2.5,5,7.2c-0.6,2.4-2,3.4-3.1,3.9
                c-0.3,0.1-0.5,0.2-0.8,0.3v1.3C63.4,45.4,62.6,46.2,61.6,46.2z"></path>
              </svg>`,
					title: 'Affordable Pricing',
					text: 'The software we develop and the apps we launch look and feel premium. But our travel  mobile app development services are not charged to cost a bomb. They will be reasonable for the value offered.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M88.7,64.2h-1.6V63c3.7-1.8,6.2-5.6,6.2-10c0-0.5,0-0.8,0-1c0.6-5.2,0.9-8.8,0.9-10.8c0-3.7-2.4-5.5-6.3-5.5h-5.6
                    c-2.6,0-4.3,0.8-5.2,2.2c-4.7,0.9-6.8,3.7-6.8,7.1c0,1.4,0.3,3.7,0.9,6.9c0,0.3,0,0.5,0,1c0,4.4,2.5,8.2,6.2,10v1.1h-1.6
                    c-0.9,0-2.8,0.6-4.9,1.4c-1.7-0.7-4.1-1.4-7.1-2.1c-0.1-0.5-0.4-1-0.8-1.4l-1.4-1.4c-0.5-0.5-1.3-0.8-2-0.9c0-0.1,0-0.2,0-0.4
                    v-4.3c4.9-3,8.1-8.3,8.1-14.3c0-0.8,0-1.3,0-1.7c1.1-8.4,1.6-14.2,1.6-17.5c0-5.4-3.6-7.9-9.7-7.9H50c-4.2,0-6.9,1.3-8.1,3.5
                    c-7.9,1.2-11.3,5.4-11.3,10.7c0,2.2,0.5,5.9,1.6,11.2c0,0.5,0,0.9,0,1.7c0,6,3.2,11.2,8.1,14.3v4.3c0,0.1,0,0.2,0,0.4
                    c-0.7,0.1-1.4,0.4-2,0.9L37,62.1c-0.4,0.4-0.7,0.9-0.8,1.4C33.9,64,32,64.5,30.5,65c0.7-0.8,1-2,0.6-3.2c-1.1-3-2.3-8.7-2.5-12.2
                    c-0.5-6.9-5.9-12.2-12.7-12.2C9,37.5,3.6,42.8,3.1,49.7c-0.3,3.5-1.4,9.2-2.5,12.2c-0.6,1.5,0.1,3.3,1.7,3.9
                    c1.5,0.7,3.1,1.3,4.8,1.7c-0.9,0.5-2,1.2-3.1,2.2c-0.2,0.2-0.4,0.4-0.6,0.5c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
                    c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.4-0.5,0.6c0,0,0,0,0,0c-1,1.1-1.7,2.2-1.9,3C0,74.9,0,75.1,0,75.2v11h100V70.5
                    C100,67.9,91.6,64.2,88.7,64.2z M82.3,39h5.6c2.3,0,3.1,0.6,3.1,2.3c0,1.2-0.1,3.2-0.4,5.8c-0.2-0.3-0.4-0.6-0.6-0.9
                    c0-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.3-0.4-0.5-0.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
                    c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.2-0.1-0.5-0.1-0.7-0.1
                    c-0.1,0-0.2,0-0.4-0.1c-0.4,0-0.8-0.1-1.3-0.1h-3.2c-0.1-0.1-0.2-0.3-0.3-0.5c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5
                    c0,0,0-0.1-0.1-0.1C80.1,42.2,80,42,80,41.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.1-0.5c0,0,0-0.1,0-0.1
                    c-0.1-0.4-0.1-0.8,0-1.1C79.9,39.3,80.7,39,82.3,39z M76.5,41.4c0,0,0,0.1,0,0.1c0.1,0.7,0.3,1.4,0.6,2h-0.6c-0.2,0-0.4,0-0.6,0.1
                    c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.1
                    c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.5c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
                    c0-0.1,0-0.3,0-0.4C73.5,43.4,74.3,42.1,76.5,41.4z M74.5,53.1c0-0.4,0-0.6,0-0.8c0.1-0.1,0.1-0.2,0.2-0.4
                    c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.2-0.4,0.2-0.5c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3
                    c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
                    c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0.2-0.4,0.3-0.4h7.5c2.4,0,3,0.6,4.2,3
                    c0.2,0.3,0.3,0.6,0.5,0.9c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.3,0.5
                    c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0.2,0,0.3,0,0.6c0,3.5-2.2,6.5-5.3,7.5c-0.1,0-0.2,0.1-0.3,0.1
                    c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0-0.7,0
                    c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1
                    C76.7,59.6,74.5,56.6,74.5,53.1z M80.6,64.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.3,0,0.7,0.1,1,0.1c0.3,0,0.7,0,1-0.1
                    c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0v1.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6V64.1z M49,68.9L49,68.9l1.9,0l0,0
                    l-0.5,1.5h-0.9L49,68.9z M53.6,67c-0.2-0.2-0.3-0.4-0.5-0.5l-0.3-0.3l5.5-2.7l0.5-0.2l0,0l0.7-0.3l1.4,1.4l-4.4,5.4L53.6,67z
                    M50,16.9h9.7c4.5,0,6.5,1.3,6.5,4.7c0,2.6-0.4,6.9-1.1,13.1c-0.2-0.4-0.5-0.8-0.7-1.2c-2.7-5.2-5-7.2-11.1-7.2h-6.5
                    c0.4,0,0.4,0,0.3-0.1c-0.1-0.1-0.2-0.3-0.4-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.3-0.5-0.5-0.8
                    c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.1-0.1-0.2
                    c-0.1-0.3-0.2-0.6-0.2-0.8c-0.2-0.8-0.1-1.6,0-2.2c0,0,0-0.1,0-0.1C45.1,17.6,46.7,16.9,50,16.9z M41.2,20.6
                    c0,0.4,0.1,0.9,0.2,1.4c0.2,1.2,0.7,2.5,1.3,3.6c0.1,0.3,0.3,0.6,0.4,0.8h-2.8c-2.5,0-4.8,2.3-4.8,4.7c0,0.1,0,0.2-0.1,0.4
                    c0,0,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.4
                    c-0.1,0.2-0.1,0.4-0.2,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.4-2.7-0.7-4.6-0.7-5.9C33.9,24.4,35.8,21.7,41.2,20.6z M35.5,40.8
                    c0-0.7,0-1.1,0-1.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.2-0.5,0.3-0.7
                    c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.1-0.4,0.2-0.5
                    c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.2
                    c0-0.2,0.1-0.4,0.1-0.6c0-0.7,0.9-1.6,1.6-1.6h12.9c4.6,0,6.1,1.2,8.2,5.4c0.3,0.5,0.5,1,0.8,1.5c0.1,0.2,0.2,0.3,0.3,0.4
                    c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.2,0.3
                    c0,0.3,0,0.7,0,1.2c0,5.2-3,9.8-7.5,12.1c-0.1,0.1-0.2,0.1-0.3,0.1c-0.3,0.2-0.7,0.3-1,0.5c0,0-0.1,0-0.1,0.1
                    c-0.4,0.2-0.8,0.3-1.2,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.1-0.7,0.2-1.1,0.3c0,0-0.1,0-0.1,0c-0.4,0.1-0.8,0.1-1.3,0.2
                    c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9,0.1-1.3,0.1c-0.4,0-0.9,0-1.3-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.9-0.1-1.3-0.2c0,0-0.1,0-0.1,0
                    c-0.4-0.1-0.8-0.2-1.1-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.8-0.3-1.2-0.4c-0.1,0-0.1,0-0.1-0.1c-0.3-0.1-0.7-0.3-1-0.5
                    C43.1,53.1,43,53,43,53C38.5,50.6,35.5,46.1,35.5,40.8z M44.9,57.2c0.3,0.1,0.6,0.2,1,0.2c0.2,0.1,0.5,0.1,0.7,0.2
                    c0.4,0.1,0.7,0.1,1.1,0.2c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.2,0.1,1.7,0.1s1.2,0,1.7-0.1c0.2,0,0.4-0.1,0.6-0.1
                    c0.4,0,0.7-0.1,1.1-0.2c0.2,0,0.5-0.1,0.7-0.2c0.3-0.1,0.6-0.1,1-0.2c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.1,0.7-0.2v2.7
                    c0,0.5-0.1,1-0.3,1.5l-4.9,2.4c-0.3,0.2-0.6,0.4-0.8,0.6c-0.1,0.1-0.1,0.1-0.2,0.2h-0.5c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.3
                    c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.3-0.6-0.3L43.8,61c-0.2-0.5-0.3-1-0.3-1.5v-2.7c0.2,0.1,0.4,0.1,0.7,0.2
                    C44.4,57,44.7,57.1,44.9,57.2z M40.6,62.9l0.7,0.3l0,0l4.9,2.4l1.1,0.5L47,66.5c-0.2,0.1-0.3,0.3-0.5,0.4l-2.8,2.8l-4.4-5.4
                    L40.6,62.9z M12.3,51.7C12.3,51.7,12.3,51.7,12.3,51.7c0.2-0.2,0.3-0.3,0.4-0.4c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.2
                    c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
                    c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
                    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0.1
                    c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.5,0.2c0.1,0,0.1,0.1,0.2,0.1c0.3,0.1,0.5,0.2,0.6,0.3
                    c1,0.5,1.9,0.9,2.6,1.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0,0,0.1,0,0.1,0.1
                    c0.2,0.1,0.3,0.2,0.4,0.3c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0.1
                    c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2
                    c0.1,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.5,0,0.7c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2
                    c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3
                    c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
                    c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.4,0.3
                    c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.2-0.5,0.3-0.7,0.4c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.5,0.2-0.8,0.3c0,0-0.1,0-0.1,0
                    c-0.3,0.1-0.6,0.2-0.9,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.1-0.9,0.1c-1.5,0-2.8-0.5-3.9-1.3c-0.1-0.1-0.3-0.2-0.4-0.3
                    c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c0,0-0.1-0.1-0.1-0.1
                    c-0.1-0.1-0.2-0.3-0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.3-0.1-0.4
                    c0-0.1,0-0.2-0.1-0.2c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0-0.7c0-0.4,0.4-1,1.3-1.9c0.2-0.2,0.2-0.2,0.8-0.8
                    C12,52,12.1,51.8,12.3,51.7z M14.4,65.1c0-0.3,0-0.7,0-1c0,0,0,0,0-0.1c0.6,0.1,1.2,0.2,1.8,0.2c0.4,0,0.8,0,1.2-0.1
                    c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0.3-0.1,0.7,0,1c0.1,1.6,0.9,3,2.4,4c-1.6,1.1-2.6,1.4-4.2,1.4c-1.7,0-2.7-0.4-4-1.5
                    C13.5,68,14.2,66.7,14.4,65.1z M25.4,67.7c-0.1-0.1-0.3-0.2-0.4-0.2c0.5-0.1,0.9-0.3,1.4-0.4C26,67.2,25.7,67.4,25.4,67.7z
                    M25.3,50c0.3,3.8,1.5,9.7,2.7,13c-2.1,0.9-4.4,1.7-6.8,2.1c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0.1-0.5
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4
                    c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3
                    c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1-0.1c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0-0.1,0.1-0.1c0,0,0,0,0,0
                    c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                    c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.1-0.4,0.2-0.6
                    c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0-0.1
                    c0-0.3,0.1-0.7,0.1-1c0,0,0,0,0,0c0,0,0,0,0,0c0-1.5-0.6-2.7-1.8-3.8c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2
                    c-0.2-0.1-0.3-0.3-0.5-0.4c0,0,0,0,0,0c-0.2-0.2-0.5-0.4-0.8-0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.1-0.5-0.3-0.7-0.4c0,0,0,0-0.1,0
                    c-0.2-0.1-0.5-0.2-0.7-0.4c-0.3-0.2-0.7-0.3-1.1-0.5c-0.4-0.2-0.8-0.4-1.3-0.6c-0.1-0.1-0.4-0.2-0.7-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
                    c-0.3-0.1-0.5-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.2c-3.1-1.4-2.8-5.9,0.1-5.9
                    C20.9,40.6,24.9,44.6,25.3,50z M6.3,50c0.2-3.1,1.7-5.8,4-7.5c-0.2,1.6,0.3,3.4,1.4,4.8c-0.1,0.1-0.2,0.3-0.3,0.4
                    c-0.3,0.6-0.8,1.1-1.9,2.2c-0.6,0.6-0.6,0.6-0.9,0.8c-1.5,1.5-2.2,2.6-2.2,4.1c0,0.4,0,0.7,0.1,1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2
                    c0,0.2,0.1,0.5,0.1,0.7c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0.1,0.2,0.1,0.4,0.2,0.6c0,0.1,0.1,0.2,0.1,0.3
                    c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6C8,59.8,8,59.9,8.1,60c0.2,0.3,0.4,0.5,0.6,0.8
                    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2
                    c0.2,0.1,0.3,0.3,0.5,0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.5
                    c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.4c0.1,0.4,0.1,0.9,0,1.3C8.5,64.8,6,64,3.6,63C4.9,59.7,6.1,53.7,6.3,50z M22.6,83.1H3.2
                    v-7.9c0-0.1,1.2-1.7,2.6-2.9c0.4-0.3,0.8-0.7,1.1-0.9c0.5-0.4,1-0.7,1.4-0.9c0.1-0.1,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0
                    c2.5,2.4,4.2,3.4,7.3,3.4c2.8,0,4.4-0.7,6.6-2.7c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
                    c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5V83.1z M25.8,83.1v-11c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0,0,0,0,0-0.1
                    c0.1-0.2,0.3-0.5,0.6-0.7l0.2-0.2c0.6-0.6,1.6-1.2,2.9-1.8c1.5-0.7,3.9-1.5,7.2-2.2l4.2,5.2c0.1,0.1,0.1,0.1,0.2,0.3
                    c1.3,1.2,3.3,1.2,4.6,0l0.5-0.5l0,0c0.1,0.2,0.2,0.4,0.3,0.6l-1.4,11H25.8z M48.6,83.1l1.2-9.5h0.4l1.2,9.5H48.6z M74.2,83.1H54.6
                    l-1.4-11c0.1-0.2,0.2-0.4,0.3-0.6l0,0l0.5,0.5c0.1,0.1,0.1,0.1,0.3,0.2c1.4,1.1,3.4,0.9,4.5-0.5l4.2-5.2c3.3,0.8,5.7,1.5,7.2,2.2
                    c2.7,1.3,3.9,2.5,3.9,3.3V83.1z M77.4,72.1c0-0.3,0-0.5-0.1-0.8c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.1-0.4-0.2-0.6c0,0,0-0.1-0.1-0.1
                    c-0.1-0.2-0.2-0.5-0.4-0.7c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.4-0.7-0.7
                    C75.1,68,75.1,68,75,68c-0.1-0.1-0.3-0.2-0.4-0.3c0.6-0.2,1-0.3,1.2-0.3h1.9c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
                    c0.1,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.2,0.2c0.1,0.2,0.3,0.4,0.4,0.5c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5
                    c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.3,0.1
                    c0.3,0.1,0.6,0.1,0.9,0.1s0.6,0,0.9-0.1c0.1,0,0.2,0,0.3-0.1c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.1
                    c0.2-0.1,0.4-0.2,0.5-0.3c0.1,0,0.1-0.1,0.2-0.1c0.2-0.2,0.5-0.3,0.6-0.5c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.3,0.4-0.5
                    c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.2-0.3,0.3-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2h1.9c0.5,0,2.8,0.7,4.8,1.6
                    c1.1,0.5,2.1,1,2.7,1.4c0.4,0.3,0.6,0.5,0.6,0.1v6.3h-4.8v6.3H77.4V72.1z M95.2,83.1v-3.2h1.6v3.2H95.2z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Dedicated Teams',
					text: 'We have a team of travel app developers who are assigned to each project. There is no multi-tasking involved which ensures that your app is given complete focus and attention that it deserves.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M55.6,38.9v-7.4c0-2.2-1.8-4-4.1-4H4.1c-2.2,0-4.1,1.8-4.1,4v36.9c0,2.2,1.8,4,4.1,4h22.1v4.8h-8.6
                    c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6H38c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6h-8.6v-4.8h22.1c2.3,0,4.1-1.8,4.1-4v-7
                    c5.9-0.4,10.6-5.3,10.6-11.2C66.2,44.2,61.5,39.4,55.6,38.9z M52.4,68.4c0,0.5-0.4,0.9-0.9,0.9H4.1c-0.5,0-0.8-0.4-0.8-0.8v-3.9
                    h49.2V68.4z M52.4,61.3H3.2V31.5c0-0.5,0.4-0.8,0.8-0.8h47.5c0.5,0,0.8,0.4,0.8,0.8v7.6c-5.2,1.1-9,5.6-9,11c0,5.4,3.9,9.9,9,11
                    V61.3z M54.8,58.2c-4.5,0-8.2-3.6-8.2-8.1c0-4.4,3.7-8.1,8.2-8.1c4.5,0,8.2,3.6,8.2,8.1C63,54.6,59.3,58.2,54.8,58.2z M12.7,48.8
                    H9.5c-0.9,0-1.6,0.7-1.6,1.6v3.2c0,0.9,0.7,1.6,1.6,1.6h3.3c0.9,0,1.6-0.7,1.6-1.6v-3.2C14.3,49.5,13.6,48.8,12.7,48.8z
                    M11.1,52.1L11.1,52.1L11.1,52.1L11.1,52.1L11.1,52.1z M39.6,50.8H18.7c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h20.9
                    c0.9,0,1.6-0.7,1.6-1.6C41.2,51.5,40.5,50.8,39.6,50.8z M12.7,36.8H9.5c-0.9,0-1.6,0.7-1.6,1.6v3.2c0,0.9,0.7,1.6,1.6,1.6h3.3
                    c0.9,0,1.6-0.7,1.6-1.6v-3.2C14.3,37.5,13.6,36.8,12.7,36.8z M11.1,40L11.1,40L11.1,40L11.1,40L11.1,40z M39.6,38.7H18.7
                    c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h20.9c0.9,0,1.6-0.7,1.6-1.6C41.2,39.4,40.5,38.7,39.6,38.7z M57.6,45.7l-4.2,4.5
                    l-1.1-0.8c-0.7-0.5-1.7-0.3-2.3,0.4c-0.5,0.7-0.4,1.7,0.4,2.2l2.3,1.6c0.3,0.2,0.6,0.3,0.9,0.3c0.4,0,0.9-0.2,1.2-0.5l5.2-5.5
                    c0.6-0.6,0.6-1.6-0.1-2.2C59.3,45,58.2,45,57.6,45.7z M79.5,16.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-7.9
                    c-0.9,0-1.6,0.7-1.6,1.6v33h-1.2c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6H70v34.3c0,0.9,0.7,1.6,1.6,1.6l7.9,0.1h0
                    c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6l-6.3-0.1V51h4.7c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-4.7V16.3H79.5z
                    M98.4,6.2H83.7C82.8,6.2,82,7,82,7.8v14.5c0,0.9,0.7,1.6,1.6,1.6h14.7c0.9,0,1.6-0.7,1.6-1.6V7.8C100,7,99.3,6.2,98.4,6.2z
                    M96.8,20.7H85.3V9.4h11.5V20.7z M98.4,76.1H83.7c-0.9,0-1.6,0.7-1.6,1.6v14.5c0,0.9,0.7,1.6,1.6,1.6h14.7c0.9,0,1.6-0.7,1.6-1.6
                    V77.7C100,76.8,99.3,76.1,98.4,76.1z M96.8,90.6H85.3V79.3h11.5V90.6z M98.4,41.2H83.7c-0.9,0-1.6,0.7-1.6,1.6v14.5
                    c0,0.9,0.7,1.6,1.6,1.6h14.7c0.9,0,1.6-0.7,1.6-1.6V42.8C100,41.9,99.3,41.2,98.4,41.2z M96.8,55.6H85.3V44.4h11.5V55.6z
                    M87.7,16.8l2,1C90,18,90.2,18,90.5,18c0.4,0,0.8-0.1,1.1-0.4l3.5-3.1c0.7-0.6,0.7-1.6,0.1-2.2c-0.6-0.7-1.6-0.7-2.3-0.1l-2.7,2.4
                    l-1-0.5c-0.8-0.4-1.8-0.1-2.2,0.7C86.6,15.5,87,16.5,87.7,16.8z M87.7,51.8l2,1c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0,0.8-0.1,1.1-0.4
                    l3.5-3.1c0.7-0.6,0.7-1.6,0.1-2.2c-0.6-0.7-1.6-0.7-2.3-0.1l-2.7,2.4l-1-0.5c-0.8-0.4-1.8-0.1-2.2,0.7
                    C86.6,50.4,87,51.4,87.7,51.8z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'On-Time Delivery',
					text: 'In travel and in business, time is of essence. A day lost will never come back. The market timing once missed will cost dearly. We run the extra mile to ensure that all projects are delivered on time without any delays.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
