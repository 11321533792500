<template>
	<section class="padding-60 p-relative " style="background:#fff">
		<div class="container mt-5">
			<div class="title">
				<span>Equipping World Class UX UI Designing Process</span>
				<h2 class="fxt-process-dev">
					Our Unique Approach to UX/UI Design and Development Solutions
				</h2>
			</div>
		</div>
		<div class="uiux-design-process-list">
			<div class="row align-items-center justify-content-between">
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content">
						<span class="number">01/05</span>
						<h5 class="wow">Research Analysis</h5>
						<p class="wow">
							Conducting user and market research to set the right expectations and gauge resources,
							design requirements, and complexities. Our team conducts in-depth interviews and
							competitive research to analyse the design prospects followed by UX/UI development.
						</p>
					</div>
				</div>
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content-image left-image">
						<img
							alt=""
							class="lazy rounded10 box-shadow"
							src="../../../assets/images/research-analysis.webp"
							style=""
						/>
					</div>
				</div>
			</div>

			<div class="row align-items-center justify-content-between">
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 order-1 order-lg-2 wow fadeIn"
				>
					<div class="uiux-design-process-content">
						<span class="number">02/05</span>
						<h5>Wireframing &amp; Prototyping</h5>
						<p>
							Here’s us wrapping your dream product with a structure for creating backbone and using
							it for mock-ups. At this stage, we integrate prototype to move beyond the look and
							feel of a product and focus on user interaction using clickable wireframes.
						</p>
					</div>
				</div>
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 order-2 order-lg-1 wow fadeIn"
				>
					<div class="uiux-design-process-content-image right-image">
						<img
							alt=""
							class="lazy rounded10 box-shadow"
							src="../../../assets/images/wireframe-and-prototyping.webp"
							style=""
						/>
					</div>
				</div>
			</div>

			<div class="row align-items-center justify-content-between">
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content">
						<span class="number">03/05</span>
						<h5>Visual Design</h5>
						<p>
							We proceed further towards design only after finalizing the layout and flow. Here’s us
							giving life to wireframes with images and thematic colour styles to create a brand
							identity. It includes integrating typography, font styles, guidelines and iconography.
						</p>
					</div>
				</div>
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content-image left-image">
						<img
							alt=""
							class="lazy rounded10 box-shadow"
							src="../../../assets/images/visual-design.webp"
							style=""
						/>
					</div>
				</div>
			</div>

			<div class="row align-items-center justify-content-between">
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 order-1 order-lg-2 wow fadeIn"
				>
					<div class="uiux-design-process-content">
						<span class="number">04/05</span>
						<h5>UI Development</h5>
						<p>
							This is where we beautify all that’s been made until now. This stage validates
							following predefined UI style guide ensuring presence of all the UI elements. Right
							from designing onboarding flow to bridging back-end functionality and front interface
							happens here.
						</p>
					</div>
				</div>
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 order-2 order-lg-1 wow fadeIn"
					data-wow-duration=".5s"
					data-wow-delay="1s"
				>
					<div class="uiux-design-process-content-image right-image">
						<img
							alt=""
							class="lazy rounded10 box-shadow"
							src="../../../assets/images/ui-development.webp"
							style=""
						/>
					</div>
				</div>
			</div>

			<div class="row align-items-center justify-content-between">
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content">
						<span class="number">05/05</span>
						<h5>Usability Testing</h5>
						<p>
							Next up, prototype testing of with end users. We follow this as a design validation
							exercise based on user trials performed using multiple testing sessions. Here’s where
							we look out for user satisfaction accessibility, and pleasure and try improving
							usability gaps.
						</p>
					</div>
				</div>
				<div
					class="col-lg-5 col-md-12 col-sm-12 col-12 wow fadeIn"
				>
					<div class="uiux-design-process-content-image left-image">
						<img
							alt=""
							class="lazy rounded10 box-shadow"
							src="../../../assets/images/usability-testing.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.uiux-design-process-list .row {
    margin-bottom: 30px;
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
}
.justify-content-between {
    -ms-flex-pack: justify!important;
    justify-content: space-evenly!important;
}
.uiux-design-process-list>.row:nth-child(odd) .uiux-design-process-content {
    margin-top: -80px;
}
.uiux-design-process-content .number {
    display: block;
    font-size: 46px;
    color: #d2d3d3;
    line-height: 1;
    margin-bottom: 20px;
}
.uiux-design-process-content h5 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 15px;
}
.uiux-design-process-content-image {
    position: relative;
}
.uiux-design-process-content-image.left-image:before {
    left: 50px;
}
.uiux-design-process-content-image:before {
    content: "";
    display: block;
    position: absolute;
    top: 50px;
    background-image: url("../../../assets/images/process-image-background-pattern.png");
    background-repeat: repeat;
    background-position: center center;
    height: 99%;
    width: 100%;
}
.uiux-design-process-content-image img {
    position: relative;
}

.box-shadow {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
    border-radius: 10px!important;
}
img {
    max-width: 100%;
    height: auto;
}
{
    left: 0;
    transform: translateX(-100%);
    background-image: url("../../../assets/images/process-arrow.png");
}
.uiux-design-process-content-image:after {
    opacity: 0;
    visibility: hidden;
}
.uiux-design-process-content-image:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 384px;
    width: 225px;
}
</style>
