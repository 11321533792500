<template>
	<section class="padding-60 bg-white web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Types of apps </span>
				<h2>Types of Social Media App We Develop</h2>
				<p>
					Like people with varying interests and passions, social media apps are also various kinds
					and themes. The good news is, we can help you build any time of social media app.
				</p>
			</div>
			<div class="row justify-content-center">
				<div
					class="col-lg-4 col-md-6 col-sm-12 col-12 mb-30"
					v-for="(item, index) in items"
					:key="index"
				>
					<TypeCard :obj="item"></TypeCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import TypeCard from '../AssetComponents/TypeCard.vue';
export default {
	components: {TypeCard},
	data() {
		return {
			items: [
				{
					imageLinks: ['/facebook.png', '/linked-in.png', '/twitter.png', '/instagram.png'],
					title: 'Social Networks',
					text: 'Social networks made up of virtual communities where people follow each, form groups and engage in real-time communication to exchange ideas, pursue professional connections, share, entertainment or even take up social causes.'
				},
				{
					imageLinks: ['/snapchat.png', '/youtube.png', '/pinterest.png', '/9gag.png'],
					title: 'Media sharing networks',
					text: 'A unique platform for artists, creative professionals and even amateurs to share their works of art to the general public. Such a media sharing network will give users an opportunity to gain publicity or monetize their work socially.'
				},
				{
					imageLinks: ['/tinder.png', '/okc.png', '/okcupid.png', '/happen.png'],
					title: 'Online Dating Network ',
					text: 'Romance can be found anywhere, especially online. The millennial generation leans onto mobile networks to find the right person who will take their personal life forward. Build an online dating network just for that.'
				},
				{
					imageLinks: ['/meetup.png', '/eventbrite.png', '/nextdoor.png', '/nearify.png'],
					title: 'Meetups and Event',
					text: 'Build a dedicated mobile app that uses GPS positioning of users to connect with like-minded individuals. The app can allow uses to create local meetups and events that will bring together people for a cause.'
				},
				{
					imageLinks: ['/zomato.png', '/trip-advisor.png', '/yelp.png', '/trustpilot.png'],
					title: 'Consumer Review and Network',
					text: 'One of two customers check online reviews before buying a product. A social media app that crowdsources consumer reviews can be quite a winner in the networking space. Build that right with our social networking app development expertise.'
				},
				{
					imageLinks: ['/quora.png', '/reddit.png', '/digg.png', '/voat.png'],
					title: 'Discussion Forum',
					text: 'Create a platform for healthy online discussions, debates and talks with the power of networking. Users can create separate rooms based on interests, geographies, members and other demographics.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
.other-demand-app-box {
	overflow: hidden;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.other-demand-app-box a {
	padding: 45px 15px 30px;
	display: block;
	background-color: #fdfefe;
}
.other-demand-app-icon {
	width: 71px;
	height: 71px;
	margin: 0 auto 40px;
}
.other-demand-app-box h5 {
	color: #202122;
	font-size: 18px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin: 0;
}
.other-demand-app-box a:hover {
	background-color: #0082dd;
}
a:hover {
	color: #0082dd;
}
.other-demand-app-box a:hover h5 {
	color: #fff;
}
.other-demand-app-box a svg * {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
.other-demand-app-box a:hover svg * {
	fill: #fff;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
</style>