<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Fintech App Development Solutions</span>
				<h2 class="fxt-process-dev">Our unique traits as a Finance App Development Company</h2>
				<p>
					Find out in quick bursts the many ways of how we add value to our clients in finance app
					development.
				</p>
			</div>
			<div class="row real-estate-type-main" style="justify-content: center">
				<div class="col-md-4 col-sm-6 col-12 mb-30" v-for="item in items" v-bind:key="item.title">
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';
export default {
	components: {
		SqureCard
	},
	data() {
		return {
			items: [
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M23.3,35c0-10.1,8.2-18.3,18.3-18.3h16.7c10.1,0,18.3,8.2,18.3,18.3v23.4H80V35c0-11-8.2-20.2-19.1-21.5l5.6-11.1
                    c0.4-0.8,0.1-1.8-0.7-2.2C65.5,0.1,65.3,0,65,0H35c-0.9,0-1.7,0.7-1.7,1.7c0,0.3,0.1,0.5,0.2,0.7l5.5,11.1
                    C28.2,14.8,20,24.1,20,35v30h3.3V35z M37.7,3.3h24.6l-5,10H42.7L37.7,3.3z M35.8,24.9c0.9-0.5,1.9-0.9,2.9-1.2l-0.8-3.2
                    c-1.3,0.3-2.6,0.9-3.8,1.5L35.8,24.9z M26.7,41.7H30V35c0-3.2,1.3-6.2,3.5-8.4l-2.3-2.4c-2.9,2.8-4.6,6.7-4.6,10.8V41.7z
                    M98.3,66.7H85.6l-0.2-0.1c-2-1.5-4.1-2.8-6.4-3.7c-8.1-3.3-17.3-2.5-24.7,2.2H41.7c-4.6,0-8.3,3.7-8.3,8.3c0,0.8,0.1,1.7,0.4,2.5
                    L13.1,65.5c-3.1-1.5-6.7-1.2-9.4,0.9C1.4,68.1,0,70.9,0,73.7v0.2c0,3.3,1.8,6.4,4.8,8L33,97.3c3.2,1.7,6.8,2.7,10.4,2.7h54.9
                    c0.9,0,1.7-0.7,1.7-1.7v-30C100,67.4,99.3,66.7,98.3,66.7z M83.3,96.7H43.4c-3.1,0-6.1-0.8-8.8-2.2L6.3,79c-1.9-1-3-3-3-5.1v-0.2
                    c0-1.9,0.9-3.6,2.4-4.7c1.7-1.3,4-1.5,5.9-0.5l26,13c0.2,0.1,0.5,0.2,0.7,0.2h25v-3.3H41.7c-2.8,0-5-2.2-5-5s2.2-5,5-5h13.1
                    c0.3,0,0.6-0.1,0.9-0.3c6.5-4.3,14.8-5.1,22-2.1c2,0.8,3.9,1.9,5.6,3.2V96.7z M96.7,96.7h-10V70h10V96.7z M93.3,88.3H90v3.3h3.3
                    V88.3z M51.7,58.3V55h1.7c3.7,0,6.7-3,6.7-6.7s-3-6.7-6.7-6.7h-6.7c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3h11.7v-3.3h-6.7
                    v-3.3h-3.3v3.3h-1.7c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7h6.7c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3H41.7V55h6.7v3.3
                    H51.7z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Cost-effective Solutions',
					text: 'Building a finance app should not cost you dearly. We ensure that you are able to build the right app with the right featureset within your available budget.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M29.6,21.1C13.2,21.1,0,34.4,0,50.8c0,16.4,13.2,29.6,29.6,29.6c16.3,0,29.6-13.3,29.6-29.6
                    C59.1,34.4,45.9,21.1,29.6,21.1z M29.6,76.5C15.4,76.5,3.9,65,3.9,50.8C3.9,36.5,15.4,25,29.6,25c14.2,0,25.7,11.5,25.7,25.7
                    C55.2,65,43.7,76.5,29.6,76.5z M41.9,48.8H31.5V33.2c0-1.1-0.9-1.9-1.9-1.9c-1.1,0-1.9,0.9-1.9,1.9v17.5c0,1.1,0.9,1.9,1.9,1.9
                    c0,0,0,0,0,0h12.4c1.1,0,1.9-0.9,1.9-1.9C43.8,49.7,43,48.8,41.9,48.8z M64.7,23.5h27.7c1.1,0,1.9-0.9,1.9-1.9
                    c0-1.1-0.9-1.9-1.9-1.9H64.7c-1.1,0-1.9,0.9-1.9,1.9C62.8,22.6,63.7,23.5,64.7,23.5z M98.1,41.6H70.3c-1.1,0-1.9,0.9-1.9,1.9v0
                    c0,1.1,0.9,1.9,1.9,1.9c0,0,0,0,0,0h27.7c1.1,0,1.9-0.9,1.9-1.9C100,42.4,99.1,41.6,98.1,41.6z M93.4,63.5H65.7
                    c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h27.7c1.1,0,1.9-0.9,1.9-1.9C95.3,64.4,94.5,63.5,93.4,63.5z M64.7,56.4h27.7
                    c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9H64.7c-1.1,0-1.9,0.9-1.9,1.9C62.8,55.6,63.7,56.4,64.7,56.4z M92.5,74.5H64.7
                    c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h27.7c1.1,0,1.9-0.9,1.9-1.9C94.4,75.4,93.5,74.5,92.5,74.5z M66.6,30.6
                    c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h27.7c1.1,0,1.9-0.9,1.9-1.9c0-1.1-0.9-1.9-1.9-1.9H66.6z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Fast MVP Delivery',
					text: 'Build small, scale fast is one of the mottos we live by at GenixBit Labs. Our MVP development process has helped identify the right ways of doing things that are easy to scale.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M49.8,11.7c1.1,0.1,2.1-0.8,2.1-1.9c0-1.1-0.9-2-1.9-2c-1.1,0-1.9,0.9-1.9,2C48.1,10.8,48.8,11.6,49.8,11.7z M88.3,11.7
                    c-5.3,0-9.6-4.4-9.6-9.8c0-1.1-0.9-2-1.9-2H23.2c-1.1,0-1.9,0.9-1.9,2c0,5.4-4.3,9.8-9.6,9.8c-1.1,0-1.9,0.9-1.9,2v31.9
                    c0,24.8,15.9,47.1,39.7,54.4c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1c23.3-7.1,39.7-29,39.7-54.4V13.7
                    C90.2,12.6,89.4,11.7,88.3,11.7z M86.4,45.5C86.4,68.9,71.8,89.1,50,96c-21.6-6.9-36.4-27.3-36.4-50.5V15.5
                    C19.5,14.6,24.1,9.9,25,3.9H75c0.8,6,5.5,10.7,11.4,11.6V45.5z M72.5,9c-0.3-0.7-1-1.2-1.8-1.2H57.7c-1.1,0-1.9,0.9-1.9,2
                    c0,1.1,0.9,2,1.9,2h11.9c2,4,5.2,7.3,9.2,9.4v24.5c0,19.1-11.3,35.6-28.7,42.2c-17.5-6.6-28.7-23.1-28.7-42.2V21.1
                    c4-2.1,7.2-5.3,9.2-9.4h11.9c1.1,0,1.9-0.9,1.9-2c0-1.1-0.9-2-1.9-2H29.2c-0.8,0-1.5,0.5-1.8,1.2c-1.8,4.1-4.9,7.3-8.9,9.1
                    c-0.7,0.3-1.1,1-1.1,1.8v25.7c0,20.9,12.7,39.2,31.9,46.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.1
                    c19.2-6.9,31.9-25.2,31.9-46.1V19.9c0-0.8-0.5-1.5-1.1-1.8C77.4,16.3,74.3,13.1,72.5,9z M32.8,46.1c0,9.7,7.7,17.6,17.2,17.6
                    c9.5,0,17.2-7.9,17.2-17.6c0-9.7-7.7-17.6-17.2-17.6C40.5,28.5,32.8,36.4,32.8,46.1z M63.4,46.1c0,7.5-6,13.7-13.4,13.7
                    c-7.4,0-13.4-6.1-13.4-13.7c0-7.5,6-13.7,13.4-13.7C57.4,32.4,63.4,38.6,63.4,46.1z M45.6,46.7c-0.7-0.8-2-0.8-2.7,0
                    c-0.7,0.8-0.7,2,0,2.8l3.8,3.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l9.6-9.8c0.7-0.8,0.7-2,0-2.8c-0.7-0.8-2-0.8-2.7,0
                    l-8.2,8.4L45.6,46.7z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Safe and Secure',
					text: 'GenixBit Labs follows all security measures as laid down by GDPR to keep user data secure. We also have adopted encryption to ensure safety from cyber security threats.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M40.7,7.6H24.4c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7h16.3c1,0,1.7-0.8,1.7-1.7C42.4,8.4,41.7,7.6,40.7,7.6z
                    M32.6,95.1c2.4,0,4.4-2,4.4-4.4c0-2.4-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4C28.2,93.1,30.1,95.1,32.6,95.1z M32.6,89.8
                    c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9C31.7,90.2,32.1,89.8,32.6,89.8z M54.2,51L51,50.5
                    c-0.4-1.2-0.8-2.3-1.4-3.5l1.9-2.7c0.5-0.7,0.4-1.6-0.2-2.2l-4.7-4.7c-0.6-0.6-1.5-0.7-2.2-0.2L41.6,39c-1.1-0.6-2.3-1.1-3.4-1.4
                    l-0.6-3.2c-0.1-0.8-0.9-1.4-1.7-1.4h-6.6c-0.8,0-1.6,0.6-1.7,1.4L27,37.6c-1.2,0.3-2.3,0.8-3.5,1.4l-2.7-1.9
                    c-0.7-0.5-1.6-0.4-2.2,0.2l-4.7,4.7c-0.6,0.6-0.7,1.5-0.2,2.2l1.9,2.7c-0.6,1.2-1.1,2.3-1.4,3.5L10.9,51c-0.8,0.1-1.4,0.9-1.4,1.7
                    v6.7c0,0.9,0.6,1.6,1.4,1.7l3.2,0.6c0.3,1.2,0.8,2.4,1.4,3.5l-1.9,2.7c-0.5,0.7-0.4,1.6,0.2,2.2l4.7,4.7c0.6,0.6,1.5,0.7,2.2,0.2
                    l2.7-1.9c1.1,0.6,2.3,1.1,3.5,1.4l0.6,3.2c0.1,0.8,0.9,1.4,1.7,1.4h6.6c0.8,0,1.6-0.6,1.7-1.4l0.6-3.2c1.1-0.3,2.3-0.8,3.4-1.4
                    l2.7,1.9c0.7,0.5,1.6,0.4,2.2-0.2l4.7-4.7c0.6-0.6,0.7-1.5,0.2-2.2l-1.9-2.7c0.6-1.1,1.1-2.3,1.4-3.5l3.2-0.6
                    c0.8-0.1,1.4-0.9,1.4-1.7v-6.7C55.6,51.9,55,51.2,54.2,51z M52.2,58l-2.9,0.5c-0.7,0.1-1.2,0.6-1.4,1.3c-0.4,1.6-1,3.1-1.9,4.6
                    c-0.3,0.6-0.3,1.3,0.1,1.9l1.7,2.4l-2.7,2.7l-2.4-1.7c-0.6-0.4-1.3-0.4-1.9-0.1c-1.5,0.9-3.1,1.5-4.5,1.9
                    c-0.7,0.1-1.2,0.7-1.3,1.4l-0.5,2.9h-3.7l-0.5-2.9c-0.1-0.7-0.7-1.2-1.3-1.4c-1.5-0.3-3-1-4.6-1.9c-0.6-0.3-1.3-0.3-1.9,0.1
                    L20,71.4l-2.6-2.7l1.7-2.4c0.4-0.6,0.4-1.3,0-1.9c-0.9-1.5-1.5-3-1.9-4.6c-0.1-0.7-0.7-1.2-1.4-1.4L12.9,58v-3.8l2.9-0.5
                    c0.7-0.1,1.3-0.6,1.4-1.4c0.3-1.6,1-3.1,1.9-4.6c0.4-0.6,0.3-1.3-0.1-1.9l-1.7-2.4l2.6-2.6l2.4,1.7c0.6,0.4,1.3,0.4,1.9,0.1
                    c1.5-0.9,3.1-1.5,4.6-1.9c0.7-0.2,1.2-0.7,1.3-1.4l0.5-2.9h3.7l0.5,2.9c0.1,0.7,0.6,1.2,1.3,1.4c1.5,0.3,3,1,4.5,1.9
                    c0.6,0.3,1.3,0.3,1.9-0.1l2.4-1.7l2.7,2.7l-1.7,2.4c-0.4,0.6-0.4,1.3-0.1,1.9c0.9,1.5,1.5,3,1.9,4.6c0.2,0.7,0.7,1.2,1.4,1.3
                    l2.8,0.5V58z M32.6,45.5c-5.8,0-10.6,4.7-10.6,10.6c0,5.9,4.7,10.6,10.6,10.6c5.8,0,10.6-4.8,10.6-10.6
                    C43.1,50.3,38.4,45.5,32.6,45.5z M32.6,63.2c-3.9,0-7.1-3.2-7.1-7.1c0-3.9,3.2-7.1,7.1-7.1c3.9,0,7.1,3.2,7.1,7.1
                    C39.7,60,36.5,63.2,32.6,63.2z M75.9,55l0.5-2.9c1-0.3,2.1-0.7,3.1-1.3l2.5,1.7c0.7,0.5,1.6,0.4,2.2-0.2l4.4-4.4
                    c0.6-0.6,0.7-1.5,0.2-2.2L87,43.2c0.5-1,1-2.1,1.3-3.1l2.9-0.5c0.8-0.1,1.4-0.9,1.4-1.7v-6.2c0-0.9-0.6-1.6-1.4-1.7l-2.9-0.5
                    c-0.3-1.1-0.8-2.1-1.3-3.1l1.7-2.5c0.5-0.7,0.4-1.6-0.2-2.2l-4.4-4.4c-0.6-0.6-1.5-0.7-2.2-0.2l-2.5,1.7c-1-0.5-2.1-1-3.1-1.3
                    l-0.5-2.9c-0.1-0.8-0.9-1.4-1.7-1.4H68c-0.8,0-1.6,0.6-1.7,1.4l-0.5,2.9c-0.2,0.1-0.4,0.1-0.7,0.2l0-9.8c0-3.6-2.9-6.6-6.5-6.6
                    h-52C2.9,1.2,0,4.2,0,7.8v84.4c0,3.6,2.9,6.6,6.5,6.6h52c1.7,0,3.4-0.7,4.6-1.9c1.2-1.2,1.9-2.9,1.9-4.6l0-40.4
                    c0.2,0.1,0.4,0.2,0.7,0.2l0.5,2.9c0.1,0.8,0.9,1.4,1.7,1.4h6.2C75,56.4,75.7,55.8,75.9,55z M3.5,7.8c0-1.7,1.4-3.1,3.1-3.1h52
                    c1.7,0,3,1.4,3,3.1v6.1H3.5V7.8z M61.6,17.4V18l-0.8-0.6H61.6z M3.5,17.4h54.3l-4.1,4.2c-0.6,0.6-0.7,1.5-0.2,2.2l1.7,2.5
                    c-0.5,1-1,2.1-1.3,3.1l-2.9,0.5c-0.8,0.1-1.4,0.9-1.4,1.7v6.2c0,0.9,0.6,1.6,1.4,1.7l2.9,0.5c0.3,1.1,0.7,2.1,1.3,3.1l-1.7,2.5
                    c-0.5,0.7-0.4,1.6,0.2,2.2l4.4,4.4c0.6,0.6,1.5,0.7,2.2,0.2l1.4-1v31.1H3.5V17.4z M61.6,92.2c0,0.8-0.3,1.6-0.9,2.2
                    c-0.6,0.6-1.3,0.9-2.2,0.9h-52c-1.7,0-3.1-1.4-3.1-3.1v-6.1h58.1V92.2z M73.2,50.3l-0.5,2.6h-3.3L69,50.3
                    c-0.1-0.7-0.6-1.2-1.3-1.4c-1.2-0.3-2.3-0.7-3.5-1.3c-0.2-0.1-0.4-0.2-0.7-0.4c-0.6-0.3-1.3-0.3-1.9,0.1l-2.2,1.5l-2.3-2.3
                    l1.5-2.2c0.6-0.9,0.2-1.7-0.3-2.5c-0.4-0.8-1-1.8-1.4-3.6c-0.2-0.7-0.7-1.2-1.4-1.4L53,36.4v-3.3l2.6-0.4c0.7-0.1,1.3-0.6,1.4-1.4
                    c0.3-1.4,0.9-2.8,1.7-4.2c0.4-0.6,0.3-1.3-0.1-1.9L57.1,23l2.3-2.3l2.2,1.5c0.6,0.4,1.3,0.4,1.9,0.1c0.2-0.1,0.4-0.3,0.7-0.4
                    c1.2-0.6,2.3-1,3.5-1.3c0.7-0.2,1.2-0.7,1.3-1.4l0.5-2.6h3.3l0.5,2.6c0.1,0.7,0.6,1.2,1.3,1.4c1.4,0.3,2.8,0.9,4.2,1.7
                    c0.6,0.3,1.3,0.3,1.9-0.1l2.2-1.5L85,23l-1.5,2.2c-0.4,0.6-0.4,1.3-0.1,1.9c0.8,1.3,1.4,2.8,1.8,4.3c0.2,0.7,0.7,1.2,1.4,1.3
                    l2.5,0.4v3.3l-2.6,0.4c-0.7,0.1-1.3,0.6-1.4,1.4c-0.3,1.4-0.9,2.8-1.7,4.2c-0.4,0.6-0.3,1.3,0.1,1.9l1.5,2.2l-2.3,2.3l-2.2-1.5
                    c-0.6-0.4-1.3-0.4-1.9-0.1c-1.4,0.8-2.8,1.4-4.2,1.7C73.8,49.1,73.3,49.6,73.2,50.3z M81.6,34.7c0-5.8-4.7-10.6-10.6-10.6
                    c-5.8,0-10.6,4.7-10.6,10.6c0,5.9,4.7,10.6,10.6,10.6C76.9,45.3,81.6,40.6,81.6,34.7z M71.1,41.8c-4,0-7.1-3.1-7.1-7.2
                    c0-3.9,3.2-7,7.1-7c3.9,0,7.1,3.2,7.1,7.1C78.2,38.6,75,41.8,71.1,41.8z M91.5,14.2C86,8.8,78.8,5.7,71.1,5.7
                    c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7c14,0,25.4,11.4,25.4,25.4c0,14.1-11.4,25.6-25.4,25.6c-1,0-1.7,0.8-1.7,1.7
                    c0,1,0.8,1.7,1.7,1.7c15.9,0,28.9-13,28.9-29.1C100,27,97,19.7,91.5,14.2z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Custom APIs Integration',
					text: 'Integrate the finance apps we build into your already existing ERP, custom software or other back-end systems with our custom API integration services.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M82,56.6H70.8v-3.4c0-1-1-1.9-1.9-1.9h-0.2c-0.7,0-1.2,0.5-1.4,1c-0.2,0.3-0.3,0.6-0.3,0.9v3.4H55.9c-0.5,0-1,0.2-1.4,0.6
                    l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.3,0.3-0.5,0.7-0.5,1l0,0.1l0,0.1c0,0.3,0.1,0.6,0.3,0.9L54,60l0.5,0.3
                    c0.2,0.1,0.6,0.4,1.2,0.4H62c0.1,0.4,0.3,0.9,0.5,1.5l-0.1,0l0.1,0.4c0.5,1.3,1,2.6,1.9,4.1c0.5,0.9,1.1,1.8,1.8,2.7
                    c-2.5,2.4-5.6,4.1-9.6,5.4c-0.5,0-0.8,0.4-1,0.6l-0.1,0.1c-0.2,0.4-0.4,0.9-0.4,1.4c0.2,0.8,1,1.4,1.9,1.4h0.3l0.1,0
                    c4.5-1.3,8.3-3.4,11.5-6.4c3.1,3,7,5.1,11.5,6.4l0.4,0c0.3,0,0.7-0.1,1-0.3h1.6l-1.1-0.3c0.2-0.2,0.3-0.3,0.4-0.6
                    c0.2-0.6,0-1.1-0.2-1.4c-0.2-0.4-0.7-0.7-1.1-0.9c-4-1.1-7.2-2.9-9.8-5.5c0.3-0.4,0.6-0.9,0.9-1.4c0.3-0.4,0.5-0.7,0.7-1l0.2-0.3
                    l-0.1-0.1c0.8-1.3,1.3-2.5,1.7-3.8c0.3-0.8,0.5-1.4,0.6-2l6.4,0c0.6,0,1.2-0.3,1.6-0.7l5.3-5.2l-5.5,2.7
                    C83.2,56.9,82.6,56.6,82,56.6z M68.9,66c-1.1-1.7-2-3.7-2.5-5.4h4.9C71,61.7,70.3,63.7,68.9,66z M44.9,39.1c0.3,0,0.6,0,1-0.2
                    c0.6-0.4,0.8-0.8,1-1.1l0.1-0.2L47,37.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.2-0.1-0.4-0.2-0.7L33.6,13v-0.2l-0.3-0.3
                    c-0.1-0.1-0.2-0.2-0.4-0.3L32.7,12h-1.8l0.1,0.1c0,0,0,0,0,0h-0.1l0,0l-0.2-0.3l-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.5L17,36.1
                    c-0.2,0.3-0.4,0.8-0.2,1.5l0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6l-1,1h3c0.7,0,1.1-0.4,1.4-0.7l5.1-9.1h12.9l4.8,8.6
                    C43.7,38.7,44.3,39.1,44.9,39.1z M27.2,25.6l4.5-8l4.5,8H27.2z M98.4,42.3c-1.2-1.2-2.8-1.9-4.5-1.9H63.1V7.3
                    c0-3.4-2.8-6.2-6.3-6.2H6.6c-3.5,0-6.3,2.8-6.3,6.2V43L0,43v0.4c0,0.2,0,0.4,0,0.5c0.1,1.3,0.5,2.4,1.2,3.3l0.3,0.3l0.3-0.2
                    c1.2,1.4,3,2.3,4.9,2.3h5.8v7.7c0,0.8,0.4,1.5,1,1.6l-1.2,0.9l2.2-0.9c0.2,0,0.5-0.1,0.7-0.3c0.2-0.1,0.3-0.2,0.4-0.3l2-1.6
                    L17,57.1l8.1-7.6h12.1v33.2c0,3.4,2.8,6.2,6.3,6.2h0.3v-0.2h31l-0.2,0.2l10.1,9.5c0.2,0.2,0.3,0.3,0.5,0.4
                    c0.2,0.1,0.4,0.1,0.7,0.1h0.4v-0.5c0.2,0,0.4-0.1,0.6-0.2c0.7-0.4,1-1.1,1-1.6v-7.7h5.8c3.5,0,6.3-2.8,6.3-6.2V46.7
                    C100.1,45,99.5,43.5,98.4,42.3z M37.4,45.8H24.2c-0.8,0-1.1,0.3-1.3,0.6L16,52.9v-5.1c0-1-1-1.8-1.9-1.8H6.6
                    c-1.4,0-2.5-1.1-2.5-2.5V7.3C4.1,6,5.2,5,6.6,5h50.2c1.4,0,2.5,1.1,2.5,2.3v33.2H43.6c-0.8,0-1.6,0.1-2.3,0.4l0-0.1l0,0.1
                    C39.2,41.7,37.7,43.5,37.4,45.8z M96.5,82.4h-0.2v0.4c0,1.3-1.1,2.3-2.5,2.3h-7.7c-0.9,0-1.7,0.7-1.9,1.6l-0.7,0.5l0.7-0.2v5.2
                    l-6.9-6.5c-0.3-0.3-0.8-0.4-1.3-0.4H43.8c-1.4,0-2.5-1.1-2.5-2.3V46.8l0.2,0.1l0.2-0.3c0.1-0.2,0.2-0.4,0.3-0.7
                    c0.1-0.2,0.1-0.3,0.2-0.4c0.2-0.2,0.4-0.5,0.7-0.6l0.4-0.1l0-0.1c0.1,0,0.3-0.1,0.5-0.1h50.2c0.2,0,0.4,0.1,0.6,0.1l0,0.1l0.3,0.1
                    L95,45l0,0c0.3,0.1,0.5,0.4,0.5,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.7l0.2,0.4l0.3-0.2V82.4z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Multi-language Support',
					text: 'Money is spelt money in all languages. Whether language your users speak and in whichever language they want to conduct financial transactions, our multilingual apps will help.'
				},
				{
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M77.1,53.3c-2,0-3.7,0.9-4.9,2.3c-1.2-1.4-3-2.3-4.9-2.3c-2,0-3.7,0.9-4.9,2.3c-1.2-1.4-3-2.3-4.9-2.3
                    c-1.2,0-2.3,0.4-3.3,0.9v-3.8c7.8-2.8,13.2-10.4,13.2-18.8c0-11-8.9-20-19.7-20c0.6,0,1.1-0.3,1.4-0.8l4.9-8.3
                    c0.3-0.5,0.3-1.2,0-1.7C53.6,0.3,53.1,0,52.5,0h-9.9c-0.6,0-1.1,0.3-1.4,0.8c-0.3,0.5-0.3,1.2,0,1.7l4.9,8.3
                    c0.3,0.5,0.8,0.8,1.4,0.8c-10.9,0-19.7,9-19.7,20c0,8.5,5.4,16,13.2,18.8v23.8l-5.4-5.4c-1.4-1.4-3.3-2.2-5.3-2.2
                    c-4.1,0-7.5,3.4-7.5,7.6c0,2,0.8,3.9,2.2,5.3L41,95.7v2.6c0,0.9,0.7,1.7,1.6,1.7h37.8c0.9,0,1.6-0.7,1.6-1.7v-6.3l1.5-3
                    c0.1-0.2,0.2-0.5,0.2-0.7V60C83.7,56.3,80.8,53.3,77.1,53.3z M47.5,28.3c-3.6,0-6.6,3-6.6,6.7v4.1c-2.1-1.9-3.3-4.6-3.3-7.4
                    c0-5.5,4.4-10,9.9-10c5.4,0,9.9,4.5,9.9,10c0,2.9-1.2,5.6-3.3,7.4V35C54.1,31.3,51.2,28.3,47.5,28.3z M45.5,3.3h4.1l-2,3.4
                    L45.5,3.3z M31.1,31.7c0-9.2,7.4-16.7,16.4-16.7C56.6,15,64,22.5,64,31.7c0,6.7-4,12.6-9.9,15.3v-3.8c4-2.4,6.6-6.8,6.6-11.5
                    c0-7.4-5.9-13.3-13.2-13.3c-7.3,0-13.2,6-13.2,13.3c0,4.7,2.6,9.1,6.6,11.5v3.8C35,44.3,31.1,38.3,31.1,31.7z M80.4,87.9l-1.5,3
                    c-0.1,0.2-0.2,0.5-0.2,0.7v5H44.2V95c0-0.4-0.2-0.9-0.5-1.2L27.4,77.2c-0.8-0.8-1.2-1.9-1.2-3c0-2.3,1.9-4.2,4.2-4.2
                    c1.1,0,2.2,0.4,2.9,1.2l8.2,8.3c0.5,0.5,1.2,0.6,1.8,0.4c0.6-0.3,1-0.9,1-1.5V35c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v35
                    h3.3V60c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v10H64V60c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3v10h3.3V60
                    c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3V87.9z M68.1,33.1l8.2,5c0.3,0.2,0.6,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2
                    c0.5-0.3,0.8-0.9,0.8-1.5v-10c0-0.6-0.3-1.2-0.8-1.4c-0.5-0.3-1.1-0.3-1.7,0l-8.2,5c-0.5,0.3-0.8,0.8-0.8,1.4
                    C67.3,32.3,67.6,32.8,68.1,33.1z M75.5,29.6v4.1l-3.4-2.1L75.5,29.6z M27,30.2l-8.2-5c-0.5-0.3-1.1-0.3-1.7,0
                    c-0.5,0.3-0.8,0.8-0.8,1.4v10c0,0.6,0.3,1.2,0.8,1.5c0.3,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2l8.2-5
                    c0.5-0.3,0.8-0.8,0.8-1.4C27.8,31.1,27.5,30.5,27,30.2z M19.6,33.7v-4.1l3.4,2.1L19.6,33.7z"></path>
                  </g>
                </g>
              </svg>
          `,
					title: 'Post Launch Support',
					text: 'From the first hello until the app is launched and forever beyond, we will be available for any troubleshooting and technical support requirements.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
