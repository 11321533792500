<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/23-3d-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Unity Game Development Company Enticing 2D-3D Mobile Games
					</h2>
					<p>
						Unity has earned a big reputation within the game development in mobile, AR, VR,
						desktop, and console by delivering an array of advanced game development tools and
						support for the latest technologies.
					</p>
					<p>
						GenixBit Labs has a strong grasp on the Unity game engine platform. Being a Unity 3D game
						development company, we have trained our game developers over time to leverage the
						offerings and advanced features Unity has.
					</p>
					<p>
						Thus, we provide seamless game development with incredible graphics, 2D-3D animations,
						aural and enthralling ambience, and swift gameplay which bind games earning a high rank
						within the designated marketplace.
					</p>
					<p>
						The 3D game development service team at GenixBit Labs take care of clientele in all aspects
						of a games successes, We leverage the various monetisation features of Unity and other
						game engines to go a long way to secure streaming revenues and growth.
					</p>
					<p>
						Our game studios are equipped with the latest game development infrastructure for 2D,
						3D, AR, VR and the latest advancements in game technologies. We have a large pool of
						game artists to create striking concepts and digitalize it. Our graphic designers stun
						mobile game lovers with their creativity.
					</p>
					<p>
						Our 2D & 3D animators have abilities to bring to life 2D art as well as 3D games by
						creating immersive experiences. Thus, it makes game players addictive to your games and
						hit your projects targets quickly as well as continuously.
					</p>
					<p>
						Our command over upcoming VR & AR technologies is adding more flairs in our 3D game
						development and lifting high in innovative directions, which are tough to imagine.
					</p>
					<p>
						Would like to dive in untraversed opportunities for your business or games by leveraging
						our new flairs?
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
