<template>
	<section class="padding-60 bg-light car-wash-feature-main">
		<div class="container">
			<div class="title">
				<span>Comprehensive Featureset</span>
				<h2 class="fxt-tech-use">Unique Features We Embed in Food Delivery App Development</h2>
			</div>
			<ul class="nav nav-tabs car-wash-feature-tab" id="technologies_tabs" role="tablist">
				<li>
					<a
						class="active"
						id="mobile-technologies-tab"
						data-toggle="tab"
						href="#mobile-technologies"
						role="tab"
						aria-controls="mobile-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M29.9,17.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C28.9,17.1,29.3,17.6,29.9,17.6z M20.1,15.6
                    c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C21.1,16.1,20.7,15.6,20.1,15.6z M28,23c-0.2-0.5-0.8-0.7-1.3-0.5
                    c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3c0.8,0.4,1.7,0.6,2.6,0.6
                    c0.9,0,1.8-0.2,2.6-0.6C28,24.1,28.3,23.5,28,23z M35.2,29.8l-2.7-2.4c3.3-2.4,5.2-6.2,5.2-10.3v-6.4C37.7,4.8,32.9,0,27,0h-3.9
                    c-5.9,0-10.7,4.8-10.7,10.7v6.4c0,4.1,1.9,7.9,5.2,10.2l-2.7,2.4C9.6,30,3.5,34.2,3.5,39.6V49c0,0.5,0.4,1,1,1h41.1
                    c0.5,0,1-0.4,1-1v-9.4C46.5,34.3,40.4,30,35.2,29.8z M33.6,31.1l-3.2,7.2l-4-2l4.7-7.5L33.6,31.1z M25,37.8l3,1.5l-0.8,2.3h-4.5
                    L22,39.3L25,37.8z M25,34.8l-3.4-5.4c1.1,0.3,2.2,0.5,3.4,0.5c1.1,0,2.3-0.2,3.4-0.5L25,34.8z M14.3,10.7c0-4.8,3.9-8.8,8.7-8.8
                    H27c4.9,0,8.8,3.9,8.8,8.8v0.8l-1.8-2.6c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3c-2,2-4.3,2.9-7.4,2.9
                    c-3.2,0-5.5-0.9-7.4-2.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.7,0.4l-1.7,2.6V10.7z M14.3,17.2v-2.3l2.7-4
                    c2.2,1.8,4.7,2.7,8,2.7c3.2,0,5.8-0.9,8-2.7l2.8,4v2.2C35.8,23,31,27.9,25,27.9C19,27.9,14.3,23.1,14.3,17.2z M18.9,28.8l4.7,7.5
                    l-4,2l-3.2-7.2L18.9,28.8z M19.8,48H5.4v-8.4c0-4,4.9-7.5,9.1-7.8l3.6,8.2c0.1,0.2,0.3,0.4,0.6,0.5s0.5,0.1,0.8,0l0.7-0.3l0.8,2.5
                    L19.8,48z M21.8,48l1.1-4.5h4.3l1,4.5H21.8z M44.5,48H30.2l-1.2-5.4l0.8-2.5l0.6,0.3c0.2,0.1,0.5,0.1,0.8,0
                    c0.3-0.1,0.5-0.3,0.6-0.5l3.6-8.2c4.3,0.3,9.1,3.8,9.1,7.8V48z M37.7,43.6h-3.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3.9
                    c0.5,0,1-0.4,1-1C38.6,44,38.2,43.6,37.7,43.6z M40.5,44.5c0,0.5,0.4,1,1,1s1-0.4,1-1c0-0.5-0.4-1-1-1S40.5,44,40.5,44.5z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>User</span>
					</a>
				</li>
				<li>
					<a
						id="web-technologies-tab"
						data-toggle="tab"
						href="#web-technologies"
						role="tab"
						aria-controls="web-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<path
									d="M8.1,35.7c-2.2,0-4,1.8-4,4c0,2.2,1.8,4,4,4s4-1.8,4-4C12.2,37.5,10.4,35.7,8.1,35.7z M8.1,42.1c-1.3,0-2.4-1.1-2.4-2.4
                c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4C10.6,41,9.5,42.1,8.1,42.1z"
								></path>
								<path
									d="M38.8,43.7c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4s-4,1.8-4,4C34.7,41.9,36.6,43.7,38.8,43.7z M38.8,37.3c1.3,0,2.4,1.1,2.4,2.4
                c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4C36.4,38.4,37.4,37.3,38.8,37.3z"
								></path>
								<path
									d="M49.3,11.5H33.1c-0.4,0-0.8,0.4-0.8,0.8v3.2v12.6l-1.1,1.1H29c1-1,1.7-2.5,1.7-4v-5.6c0-2.5-1.7-4.6-3.9-5.4
                c0.7-0.7,1.3-1.6,1.4-2.7h0.1c0.4,0,0.8-0.4,0.8-0.8c0-3.1-2.5-5.6-5.6-5.6s-5.6,2.5-5.6,5.6c0,0.4,0.4,0.8,0.8,0.8h0.1
                c0.3,1.5,1.2,2.8,2.6,3.5l-0.8,0.8c-0.2,0.2-0.2,0.4-0.2,0.6v3.2h-4.1c-0.4-1.8-2-3.2-4-3.2h-0.8v-3.2H9.7v3.2H8.9
                c-0.4,0-0.8,0.4-0.8,0.8v0.4c-0.5-0.3-1-0.4-1.6-0.4H4.9c-0.4,0-0.8,0.4-0.8,0.8v4.8c0,0.4,0.4,0.8,0.8,0.8h1.6
                c0.6,0,1.1-0.2,1.6-0.4v5.3H4.9c-2.7,0-4.8,2.2-4.8,4.8c0,0.4,0.4,0.8,0.8,0.8h2.7c-1.6,1.3-2.7,3.4-2.7,5.6c0,4,3.3,7.3,7.3,7.3
                c3.4,0,6.3-2.4,7.1-5.6h16.4c0.7,3.2,3.6,5.6,7.1,5.6c2.8,0,5.3-1.6,6.5-4h4.1c0.4,0,0.8-0.4,0.8-0.8v-3.2c0-0.4-0.4-0.8-0.8-0.8
                h-3.5c-0.4-1.6-1.3-3-2.5-4h0.4c0.6,0,1.1-0.2,1.6-0.5l2.6,2.6l1.1-1.1l-2.6-2.6c0.3-0.5,0.5-1,0.5-1.6c0-0.6-0.2-1.2-0.5-1.6
                c2.1-0.2,3.7-1.9,3.7-4v-9.7v-3.2C50.1,11.8,49.7,11.5,49.3,11.5z M33.9,13.1h14.5v1.6H33.9V13.1z M23.5,6.6c1.9,0,3.6,1.4,4,3.2
                h-7.9C19.9,8,21.5,6.6,23.5,6.6z M26.6,11.5c-0.4,1.4-1.6,2.4-3.1,2.4c-0.2,0-0.4,0-0.6-0.1c0.4-0.4,0.6-1,0.6-1.6h-1.6
                c0,0.4-0.2,0.7-0.6,0.8c-0.5-0.4-0.8-1-1-1.6H26.6z M21,21.2c0.4,0,0.8-0.4,0.8-0.8v-3.7l1.1-1.1h2.1c2.2,0,4,1.8,4,4v5.6
                c0,2.2-1.8,4-4,4h-3.2c-1.3,0-2.4,1.1-2.4,2.4v4.8h-1.6v-4.8c0-2.2,1.8-4,4-4h0.8c0.4,0,0.8-0.4,0.8-0.8v-2.6
                c0.9-0.3,1.6-1.2,1.6-2.3v-3.2h-1.6V22c0,0.4-0.4,0.8-0.8,0.8h-8.1c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8H21z M21,33.9
                c0.3,0.1,0.5,0.1,0.8,0.1h9.3l-2.4,2.4H21V33.9z M15.4,36.5c-0.4,0-0.8-0.4-0.8-0.8V24.4h7.3V26c-3.1,0-5.6,2.5-5.6,5.6v4.8H15.4z
                M6.5,22H5.7v-3.2h0.8c0.9,0,1.6,0.7,1.6,1.6S7.4,22,6.5,22z M1.8,32.5C2.1,31.1,3.4,30,4.9,30h4c0.4,0,0.8-0.4,0.8-0.8V17.9h2.4
                c1.1,0,1.9,0.7,2.3,1.6c-1.3,0.1-2.3,1.1-2.3,2.4c0,0.7,0.3,1.3,0.8,1.8v10.6c-1.3-1.2-3-1.9-4.8-1.9H1.8z M8.1,45.4
                c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C13.8,42.8,11.2,45.4,8.1,45.4z M15.4,39.7c0-0.6-0.1-1.1-0.2-1.6
                c0.1,0,0.1,0,0.2,0H17h3.2h8.9c0.2,0,0.4-0.1,0.6-0.2l3.8-3.8h0.7c-1.6,1.3-2.7,3.4-2.7,5.6H15.4z M48.5,39.7v1.6h-2.7
                c0.1-0.5,0.2-1.1,0.2-1.6H48.5z M44.4,39.7c0,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6
                C41.8,34.1,44.4,36.6,44.4,39.7z M21.8,32.5c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h3.2h6.5c0.2,0,0.4-0.1,0.6-0.2l1.4-1.4
                h10.2c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6H21.8z M48.5,25.2c0,1.3-1.1,2.4-2.4,2.4h-2.4h-9.7V16.3h14.5V25.2z"
								></path>
								<rect x="35.6" y="24.4" width="4" height="1.6"></rect>
								<rect x="35.6" y="21.2" width="6.5" height="1.6"></rect>
								<rect x="35.6" y="17.9" width="9.7" height="1.6"></rect>
								<rect x="29.1" y="5" width="7.3" height="1.6"></rect>
								<rect x="38" y="5" width="1.6" height="1.6"></rect>
								<rect x="41.2" y="5" width="1.6" height="1.6"></rect>
								<rect x="44.4" y="5" width="1.6" height="1.6"></rect>
								<rect x="32.3" y="8.3" width="7.3" height="1.6"></rect>
								<rect x="41.2" y="8.3" width="1.6" height="1.6"></rect>
								<rect x="44.4" y="8.3" width="1.6" height="1.6"></rect>
								<rect x="47.7" y="8.3" width="1.6" height="1.6"></rect>
							</svg>
						</div>
						<span>Driver</span>
					</a>
				</li>
				<li>
					<a
						id="cloud-tab"
						data-toggle="tab"
						href="#cloud"
						role="tab"
						aria-controls="cloud"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M98,40.4C98,40.3,98,40.3,98,40.4l-1.7-16.6c-0.1-0.8-0.8-1.5-1.6-1.5h-35c2.3-2.1,3.6-5.1,3.5-8.3c0-0.9-0.7-1.7-1.7-1.7
                    H38.4c-0.9,0-1.7,0.7-1.7,1.7c-0.1,3.1,1.2,6.2,3.5,8.3h-35c-0.9,0-1.6,0.6-1.7,1.5L2,40.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v57.9
                    c0,0.9,0.7,1.7,1.7,1.7h92.7c0.9,0,1.7-0.7,1.7-1.7L98,40.4C98,40.4,98,40.4,98,40.4z M84.8,25.6h8.4l1.3,13.2h-9.8V25.6z
                    M71.5,25.6h9.9v13.2h-9.9V25.6z M71.5,42.1h9.9v3.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5V42.1z M68.2,25.6v13.2h-9.9V25.6H68.2z
                    M68.2,42.1v3.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5v-3.3H68.2z M40.2,15.6h19.6c-0.9,4.8-5.6,6.6-9.8,6.6
                    C45.9,22.2,41.1,20.5,40.2,15.6z M45,38.8V25.6H55v13.2H45z M55,42.1v3.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5v-3.3H55z M31.8,25.6
                    h9.9v13.2h-9.9V25.6z M31.8,42.1h9.9v3.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5V42.1z M18.6,25.6h9.9v13.2h-9.9V25.6z M18.6,42.1h9.9
                    v3.3c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5V42.1z M6.8,25.6h8.4v13.2H5.5L6.8,25.6z M5.3,42.1h9.9v3.3c0,2.7-2.2,5-5,5
                    c-2.7,0-5-2.2-5-5V42.1z M88.1,96.7H69.9V60.3h18.2V96.7z M94.7,96.7h-3.3V58.6c0-0.9-0.7-1.7-1.7-1.7H68.2
                    c-0.9,0-1.7,0.7-1.7,1.7v38.1H5.3V52c3.6,2.8,8.7,2.1,11.4-1.5c0-0.1,0.1-0.1,0.1-0.2c2.7,3.7,7.8,4.5,11.5,1.8
                    c0.7-0.5,1.3-1.1,1.8-1.8c2.7,3.7,7.8,4.5,11.5,1.8c0.7-0.5,1.3-1.1,1.8-1.8c2.7,3.7,7.8,4.5,11.5,1.8c0.7-0.5,1.3-1.1,1.8-1.8
                    c2.7,3.7,7.8,4.5,11.5,1.8c0.7-0.5,1.3-1.1,1.8-1.8c2.7,3.7,7.8,4.5,11.5,1.8c0.7-0.5,1.3-1.1,1.8-1.8c2.7,3.6,7.8,4.4,11.4,1.8
                    c0.1,0,0.1-0.1,0.2-0.1V96.7z M94.7,45.4c0,2.7-2.2,5-5,5c-2.7,0-5-2.2-5-5v-3.3h9.9V45.4z M37.7,5.5l1.8,0.9l-0.9,1.8l3,1.5
                    l1.7-3.3c0.4-0.8,0.1-1.8-0.7-2.2h0l-1.8-0.9l0.9-1.8l-3-1.5l-1.7,3.3C36.5,4.1,36.9,5.1,37.7,5.5z M47.6,5.5l1.8,0.9l-0.9,1.8
                    l3,1.5l1.7-3.3c0.4-0.8,0.1-1.8-0.7-2.2h0l-1.8-0.9l0.9-1.8l-3-1.5l-1.7,3.3C46.5,4.1,46.8,5.1,47.6,5.5z M57.5,5.5
                    C57.5,5.5,57.5,5.5,57.5,5.5l1.8,0.9l-0.9,1.8l3,1.5l1.7-3.3c0.4-0.8,0.1-1.8-0.7-2.2h0l-1.8-0.9l0.9-1.8l-3-1.5l-1.7,3.3
                    C56.4,4.1,56.7,5.1,57.5,5.5z M76.5,78.5h-3.3v3.3h3.3V78.5z M59.9,57H10.3c-0.9,0-1.7,0.7-1.7,1.7v28.1c0,0.9,0.7,1.7,1.7,1.7
                    h49.6c0.9,0,1.7-0.7,1.7-1.7V58.6C61.6,57.7,60.8,57,59.9,57z M45,60.3h5v2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V60.3z
                    M36.8,60.3h5v2.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V60.3z M28.5,60.3h5v2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5
                    V60.3z M20.2,60.3h5v2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5V60.3z M11.9,60.3h5v2.5c0,1.4-1.1,2.5-2.5,2.5
                    c-1.4,0-2.5-1.1-2.5-2.5V60.3z M58.3,85.1h-6.2l2.9-12.9l-3.2-0.7l-3,13.6H36.8v-5H45v-3.3H25.2v3.3h8.3v5H21.5l-3-13.6l-3.2,0.7
                    l2.9,12.9h-6.2V68c2.2,1.1,4.9,0.6,6.6-1.2c2.2,2.3,5.9,2.3,8.2,0.1c0,0,0.1-0.1,0.1-0.1c2.2,2.3,5.9,2.3,8.2,0.1
                    c0,0,0.1-0.1,0.1-0.1c2.2,2.3,5.9,2.3,8.2,0.1c0,0,0.1-0.1,0.1-0.1c2.2,2.3,5.9,2.3,8.2,0.1c0,0,0.1-0.1,0.1-0.1
                    c1.7,1.8,4.4,2.3,6.6,1.2V85.1z M58.3,62.8c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5v-2.5h5V62.8z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>Restaurant</span>
					</a>
				</li>
				<li>
					<a
						id="admin-panel-tab"
						data-toggle="tab"
						href="#admin-panel"
						role="tab"
						aria-controls="admin-panel"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<path
										d="M16.1,21.8h-1.6c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2h-1.6c0,0.9-0.7,1.6-1.6,1.6S16.1,22.7,16.1,21.8z"
									></path>
									<circle cx="13.7" cy="16.9" r="0.8"></circle>
									<circle cx="21.8" cy="16.9" r="0.8"></circle>
									<path
										d="M50,15.8l-6.9-6.9H29.8C29.4,7,28.1,5.5,26.3,5c-2.1-3.1-5.6-5-9.4-5C10.7,0,5.6,5.1,5.6,11.3v9.1c0,3-1.7,5.8-4.4,7.1
                  l-0.7,0.4l0.4,0.7c0.1,0.1,1.1,2.1,3.9,3.1L3.9,32C1.6,32.8,0,34.9,0,37.4V50h35.5v-7.3H50V15.8z M43.5,11.6l3.7,3.7h-3.7V11.6z
                  M19.3,13.3c-0.2-0.5-0.3-1-0.3-1.6c1.8,1.2,3.8,2.1,6,2.6v5.8c0,4-3.3,7.3-7.3,7.3s-7.3-3.3-7.3-7.3V12c1.9,1.2,4.1,1.9,6.3,2.3
                  l3.2,0.5L19.3,13.3z M17.7,29c1.5,0,2.8-0.4,4-1v1.4l-4,3.4l-4-3.4v-1.4C14.9,28.7,16.3,29,17.7,29z M12.7,30.7l3.8,3.2l-4.2,4.2
                  l-2.5-6.4L12.7,30.7z M16.8,40.3h1.9l1.3,8.1h-4.5L16.8,40.3z M18.9,38.7h-2.2L15.8,37l2-2l2,2L18.9,38.7z M18.9,33.9l3.8-3.2
                  l2.9,1l-2.5,6.4L18.9,33.9z M23.4,29.3V27c1.4-1.1,2.4-2.7,2.9-4.5c0.8-0.1,1.5-0.4,2-0.9c0.4,2.8,2,5.3,4.4,6.8
                  c-0.7,0.8-2.3,2.1-5.2,2.1L23.4,29.3z M26.6,20.2v-3.9c0.9,0.3,1.6,1.2,1.6,2.3c0,1.1-0.7,2-1.6,2.3C26.6,20.6,26.6,20.4,26.6,20.2
                  z M17,1.6c3.3,0,6.3,1.6,8.1,4.3l0.3,0.4l0.3,0.1c1.5,0.4,2.6,1.8,2.6,3.3v5.6c-0.5-0.4-1-0.6-1.6-0.7v-1.5l-0.7-0.1
                  c-2.5-0.5-4.8-1.6-6.9-3.1l-0.8-0.6l-0.4,0.9c-0.4,0.8-0.5,1.7-0.4,2.6L17,12.7c-2.5-0.4-4.8-1.3-6.8-2.8l-1.3-1v5.7
                  c-0.6,0.1-1.1,0.4-1.6,0.7v-4C7.3,6,11.6,1.6,17,1.6z M8.9,20.8c-1-0.3-1.6-1.2-1.6-2.3c0-1.1,0.7-1.9,1.6-2.3v3.9
                  C8.9,20.4,8.9,20.6,8.9,20.8z M2.8,28.5c2.4-1.5,4-4,4.4-6.8c0.6,0.5,1.3,0.8,2,0.9c0.5,1.8,1.5,3.3,2.9,4.5v2.3L8,30.6
                  C5,30.6,3.5,29.3,2.8,28.5z M1.6,37.4c0-1.7,1.1-3.3,2.8-3.8l3.9-1.3l3.5,8.7l2.8-2.8l0.7,1.5l-1.5,8.7H8.1v-6.5H6.5v6.5H1.6V37.4z
                  M33.9,48.4H29v-6.5h-1.6v6.5h-5.8l-1.5-8.7l0.7-1.5l2.8,2.8l3.5-8.7l3.9,1.3c1.6,0.6,2.8,2.1,2.8,3.8V48.4z M35.5,41.1v-3.8
                  c0-0.7-0.1-1.3-0.3-1.9h11.6v-1.6h-1.6v-5.6h-1.6v5.6h-1.6v-7.3h-1.6v7.3h-1.6v-4h-1.6v4h-2.8c-0.7-0.8-1.6-1.5-2.6-1.9l-0.9-0.3
                  c2.8-1,3.8-3,3.9-3.1l0.4-0.7l-0.7-0.4c-2.7-1.4-4.4-4.1-4.4-7.1v-9.9h12.1v6.5h6.5v24.2H35.5z"
									></path>
									<rect x="45.2" y="18.5" width="1.6" height="1.6"></rect>
									<rect x="31.5" y="18.5" width="12.1" height="1.6"></rect>
									<rect x="31.5" y="21.8" width="15.3" height="1.6"></rect>
								</g>
							</svg>
						</div>
						<span>Admin Panel</span>
					</a>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade show active fxt-tab-pane"
					id="mobile-technologies"
					role="tabpanel"
					aria-labelledby="mobile-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items1"
						v-bind:className="'food-user'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="web-technologies"
					role="tabpanel"
					aria-labelledby="web-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items2"
						v-bind:className="'food-driver'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="cloud"
					role="tabpanel"
					aria-labelledby="cloud-tab"
				>
					<RoundedCardContainer
						v-bind:items="items3"
						v-bind:className="'food-restorant'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="admin-panel"
					role="tabpanel"
					aria-labelledby="admin-panel-tab"
				>
					<RoundedCardContainer
						v-bind:items="items4"
						v-bind:className="'admin-panel'"
					></RoundedCardContainer>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RoundedCardContainer from '../AssetComponents/RoundedCardContainer.vue';
export default {
	components: {
		RoundedCardContainer
	},
	data() {
		return {
			items1: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M22.2,0c-2.7,0.3-5.2,1.3-7.3,3.1c-0.6,0.5-1.7,1.7-2.2,2.3c-1.5,2-2.4,4.1-2.8,6.7c-0.1,0.8-0.1,3.1,0,3.9
                      c0.5,3.2,1.8,5.9,4.1,8.1c1.5,1.4,3.2,2.5,5.1,3.2l0.5,0.2v0.8V29l-0.2,0c-0.7-0.1-1.1-0.2-1.7-0.1c-1.5,0.3-2.6,1.3-2.9,2.7
                      c-0.1,0.4-0.1,0.7-0.1,5c0,4.9,0,5.1,0.2,5.8c0.2,0.7,0.6,1.4,2.1,3.2c0.3,0.4,1.1,1.4,1.8,2.3c0.6,0.8,1.2,1.6,1.4,1.7
                      c0.2,0.2,0.6,0.3,0.9,0.3c0.9,0,1.6-1,1.2-1.9c-0.1-0.2-2.6-3.5-3.3-4.3c-0.4-0.5-1-1.3-1.2-1.7c-0.3-0.7-0.3-0.5-0.4-5.3
                      c0-4,0-4.4,0.1-4.6c0.2-0.5,0.5-0.7,1.2-0.7c0.4,0,0.4,0,0.6,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,3.4l0,3.2l0.1,0.2
                      c0.2,0.3,0.3,0.4,0.6,0.6c0.4,0.2,0.8,0.2,1.2,0c0.3-0.2,0.6-0.5,0.6-0.8c0-0.2,0.1-3.4,0.1-11V17l0.1-0.2c0.2-0.5,0.7-0.7,1.4-0.6
                      c0.2,0.1,0.3,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.4c0,0.2,0.1,1.7,0.1,6.7c0,5.8,0,6.6,0.1,6.8c0.1,0.5,0.7,0.9,1.2,0.9
                      c0.5,0,1-0.3,1.2-0.8l0.1-0.3v-2.2c0-1.4,0-2.3,0.1-2.4c0.1-0.3,0.4-0.6,0.6-0.7c0.1,0,0.4,0,0.6,0c0.5,0,0.7,0.1,0.9,0.5l0.1,0.2
                      v2.1c0,1.3,0,2.2,0.1,2.4c0.2,1,1.4,1.4,2.1,0.7c0.4-0.4,0.4-0.3,0.4-2.7l0-2.1l0.1-0.2c0.2-0.3,0.4-0.4,0.7-0.5
                      c0.5-0.1,1,0.1,1.3,0.5c0.1,0.1,0.1,0.4,0.1,2.3c0,2,0,2.1,0.1,2.3c0.5,1,1.9,1,2.4,0.1c0.1-0.2,0.1-0.3,0.1-1.3c0-1,0-1.1,0.1-1.3
                      c0.2-0.3,0.4-0.5,0.7-0.5c0.4-0.1,0.8,0,1.1,0.3c0.3,0.3,0.3-0.1,0.3,7.4v6.9l-0.8,3.2c-0.5,2.2-0.8,3.3-0.8,3.4
                      c0,0.5,0.3,0.9,0.7,1.2c0.3,0.2,0.9,0.2,1.1,0c0.2-0.1,0.5-0.4,0.6-0.6c0-0.1,0.5-1.7,0.9-3.6l0.8-3.4l0-7.2c0-8.1,0-7.3-0.4-8.2
                      c-0.2-0.3-0.3-0.5-0.6-0.9c-0.5-0.5-0.9-0.7-1.5-0.9c-0.6-0.2-1.5-0.2-2.1-0.1C37.1,25,36.8,25,36.8,25c0,0-0.1-0.1-0.2-0.3
                      c-0.2-0.4-0.7-0.9-1.1-1.2c-0.4-0.3-1-0.5-1.4-0.6l-0.2,0l0.3-0.4c0.5-0.7,1-1.5,1.5-2.4c2.1-4.4,1.8-9.7-0.9-13.8
                      c-0.7-1.1-1.8-2.4-2.8-3.2c-1.9-1.6-4.1-2.6-6.6-2.9C24.7,0,22.9,0,22.2,0L22.2,0z M25.1,2.5c3.3,0.5,6.1,2.4,7.9,5.4
                      c0.8,1.2,1.3,2.7,1.6,4.3c0.1,0.7,0.1,2.8,0,3.6c-0.2,1.3-0.5,2.1-1,3.3c-0.6,1.3-1.4,2.4-2.4,3.3c-0.7,0.7-0.6,0.6-1.1,0.4
                      c-0.2-0.1-0.4-0.2-0.4-0.2c0,0,0.1-0.1,0.2-0.2c3-2.4,4.5-6.3,3.9-10.2c-0.8-4.7-4.5-8.4-9.2-9c-0.7-0.1-2.4,0-3,0.1
                      c-2.2,0.4-4.1,1.4-5.6,3c-3.2,3.3-4,8.2-2,12.3c1.1,2.3,2.9,4.1,5.1,5.1l0.5,0.2l0,0.4c0,0.2,0,0.4,0,0.4c-0.1,0-0.9-0.4-1.3-0.6
                      c-2.6-1.4-4.6-3.7-5.5-6.5c-0.4-1.3-0.6-2.3-0.6-3.8c0-1.2,0.1-1.8,0.3-2.8c0.6-2.3,1.9-4.5,3.6-6c1.9-1.7,4.1-2.6,6.7-2.7
                      C23.3,2.3,24.5,2.4,25.1,2.5L25.1,2.5z M25.1,5.7c5.4,1.2,8.1,7.1,5.6,12.1c-0.7,1.4-1.9,2.7-3.2,3.4l-0.3,0.2l0-2.4l0-2.4
                      l-0.1-0.4C26.7,15,26,14.3,25,13.9c-1.9-0.8-4.1,0-4.9,1.7c-0.4,0.8-0.4,0.8-0.4,3.4l0,2.4l-0.3-0.2c-0.8-0.5-1.8-1.4-2.4-2.2
                      c-0.8-1.1-1.3-2.4-1.5-3.8c-0.1-0.8-0.1-2.2,0.1-2.9c0.7-3.5,3.4-6.1,6.8-6.6C22.9,5.5,24.4,5.6,25.1,5.7L25.1,5.7z"></path>
                    </g>
                  </svg>`,
					title: 'Easy Onboarding',
					text: 'Users can quickly sign up and log in to start using the app. Single sign ons and social logins makes the onboarding a breeze through.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M2,49.6H48c0.5,0,0.8-0.4,0.8-0.8V20c0,0,0,0,0,0c0,0,0,0,0,0l-0.8-8.2c0-0.4-0.4-0.7-0.8-0.7H29.8c1.1-1,1.8-2.5,1.7-4.1
                      c0-0.5-0.4-0.8-0.8-0.8H19.3c-0.5,0-0.8,0.4-0.8,0.8c0,1.6,0.6,3.1,1.7,4.1H2.9c-0.4,0-0.8,0.3-0.8,0.7L1.2,20c0,0,0,0,0,0
                      c0,0,0,0,0,0v28.7C1.2,49.2,1.6,49.6,2,49.6z M34.8,47.9v-18h9v18H34.8z M45.5,47.9V29.1c0-0.5-0.4-0.8-0.8-0.8H34
                      c-0.5,0-0.8,0.4-0.8,0.8v18.9H2.9V25.8c1.8,1.4,4.3,1,5.7-0.7c0,0,0-0.1,0.1-0.1c1.3,1.8,3.9,2.2,5.7,0.9c0.3-0.2,0.6-0.5,0.9-0.9
                      c1.3,1.8,3.9,2.2,5.7,0.9c0.3-0.2,0.6-0.5,0.9-0.9c1.3,1.8,3.9,2.2,5.7,0.9c0.3-0.2,0.6-0.5,0.9-0.9c1.3,1.8,3.9,2.2,5.7,0.9
                      c0.3-0.2,0.6-0.5,0.9-0.9c1.3,1.8,3.9,2.2,5.7,0.9c0.3-0.2,0.6-0.5,0.9-0.9c1.3,1.8,3.8,2.2,5.6,0.9c0,0,0.1,0,0.1-0.1v22.2H45.5z
                      M2.9,20.9h4.9v1.6c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5l0,0V20.9z M16,12.7h4.9v6.6H16V12.7z M47.1,22.5c0,1.4-1.1,2.5-2.5,2.5
                      c-1.4,0-2.5-1.1-2.5-2.5l0,0v-1.6h4.9V22.5z M40.6,22.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5l0,0v-1.6h4.9V22.5z M34,22.5
                      c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5l0,0v-1.6H34V22.5z M27.5,22.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5l0,0
                      v-1.6h4.9V22.5z M20.9,22.5c0,1.4-1.1,2.5-2.5,2.5S16,23.9,16,22.5l0,0v-1.6h4.9V22.5z M27.5,12.7v6.6h-4.9v-6.6H27.5z M14.3,22.5
                      c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5l0,0v-1.6h4.9V22.5z M47.1,19.2h-4.8v-6.6h4.2L47.1,19.2z M40.6,19.2h-4.9v-6.6h4.9V19.2z
                      M34,19.2h-4.9v-6.6H34V19.2z M29.8,7.7C29.4,10.1,27,11,25,11s-4.4-0.9-4.8-3.3H29.8z M14.3,19.2H9.4v-6.6h4.9V19.2z M3.6,12.7
                      h4.2v6.6H3L3.6,12.7z"></path>
                      <path d="M18.9,2.7l0.9,0.5l-0.5,0.9l1.5,0.7l0.8-1.6c0.2-0.4,0-0.9-0.4-1.1c0,0,0,0,0,0l-0.9-0.5l0.5-0.9L19.4,0l-0.8,1.6
                      C18.3,2,18.5,2.5,18.9,2.7z"></path>
                      <path d="M23.8,2.7l0.9,0.5l-0.5,0.9l1.5,0.7l0.8-1.6c0.2-0.4,0-0.9-0.4-1.1c0,0,0,0,0,0l-0.9-0.5l0.5-0.9L24.3,0l-0.8,1.6
                      C23.2,2,23.4,2.5,23.8,2.7z"></path>
                      <path d="M28.7,2.7l0.9,0.5l-0.5,0.9l1.5,0.7l0.8-1.6c0.2-0.4,0-0.9-0.4-1.1c0,0,0,0,0,0l-0.9-0.5l0.5-0.9L29.2,0l-0.8,1.6
                      C28.2,2,28.3,2.5,28.7,2.7C28.7,2.7,28.7,2.7,28.7,2.7z"></path>
                      <rect x="36.5" y="38.9" width="1.6" height="1.6"></rect>
                      <path d="M29.9,28.2H5.3c-0.5,0-0.8,0.4-0.8,0.8V43c0,0.5,0.4,0.8,0.8,0.8h24.6c0.5,0,0.8-0.4,0.8-0.8V29.1
                      C30.7,28.6,30.4,28.2,29.9,28.2z M29.1,31.1c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2v-1.2h2.5V31.1z M25,31.1
                      c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2v-1.2H25V31.1z M20.9,31.1c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2v-1.2h2.5V31.1z
                      M16.8,31.1c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2v-1.2h2.5V31.1z M12.7,31.1c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2v-1.2
                      h2.5V31.1z M6.1,29.9h2.5v1.2c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2V29.9z M26,42.2l1.4-6.4l-1.6-0.4l-1.5,6.7h-5.9v-2.5h4.1
                      v-1.6h-9.8v1.6h4.1v2.5h-5.9l-1.5-6.7l-1.6,0.4l1.4,6.4H6.1v-8.5c1.1,0.5,2.4,0.3,3.3-0.6c1.1,1.1,2.9,1.2,4,0.1c0,0,0,0,0.1-0.1
                      c1.1,1.1,2.9,1.2,4,0.1c0,0,0,0,0.1-0.1c1.1,1.1,2.9,1.2,4,0.1c0,0,0,0,0.1-0.1c1.1,1.1,2.9,1.2,4,0.1c0,0,0,0,0.1-0.1
                      c0.9,0.9,2.2,1.1,3.3,0.6v8.5H26z"></path>
                    </g>
                  </svg>`,
					title: 'Nearby Restaurants',
					text: 'Users can search and find nearby restaurants that serve their favorite dishes by running a search enabled with geo-location.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M16.3,15.8v13.4H18V15.8c1.9-0.4,3.3-2.1,3.3-4.1V9.2h-1.7v2.5c0,1.1-0.7,2-1.7,2.3V9.2h-1.7V14
                      c-1-0.4-1.7-1.3-1.7-2.3V9.2H13v2.5C13,13.6,14.4,15.4,16.3,15.8z"></path>
                      <path class="st0" d="M9.7,23.8v5.4h1.7V10c0-0.5-0.4-0.8-0.8-0.8c-0.2,0-0.4,0.1-0.5,0.2c-2.3,1.7-3.7,4.5-3.7,7.3v4.2
                      c0,0.3,0.1,0.5,0.3,0.7L9.7,23.8z M8,16.7C8,15,8.6,13.3,9.7,12v9.7L8,20.4V16.7z"></path>
                      <path class="st0" d="M48.8,5h-5.9c-0.5-3.2-3.4-5.4-6.6-4.9c-2.6,0.4-4.6,2.4-4.9,4.9H28c-0.2,0-0.4,0.1-0.6,0.2l-1.9,1.9l-1.9-1.9
                      C23.4,5.1,23.2,5,23,5H2.2C1.7,5,1.3,5.4,1.3,5.8v40.8c0,0.5,0.4,0.8,0.8,0.8h20.5l2.3,2.3c0.3,0.3,0.9,0.3,1.2,0l2.3-2.3h20.5
                      c0.5,0,0.8-0.4,0.8-0.8V5.8C49.7,5.4,49.3,5,48.8,5z M37.2,1.7c2.3,0,4.2,1.9,4.2,4.2c0,1.2-0.5,2.3-1.4,3.1
                      c-0.2,0.2-0.3,0.4-0.3,0.6v1.3H38V8.7l2.3-2.3l-1.2-1.2l-1.9,1.9l-1.9-1.9l-1.2,1.2l2.3,2.3v2.2h-1.7V9.6c0-0.2-0.1-0.5-0.3-0.6
                      c-1.7-1.5-1.9-4.2-0.3-5.9C34.8,2.2,36,1.7,37.2,1.7z M23,45.8H3V6.7h19.7l2,2v38.5l-1.1-1.1C23.4,45.9,23.2,45.8,23,45.8z
                      M48,45.8H28c-0.2,0-0.4,0.1-0.6,0.2l-1.1,1.1V8.7l2-2h3.1C31.6,7.9,32.1,9,33,9.9v0.9h-1.7v1.7H43v-1.7h-1.7V9.9
                      c0.9-0.9,1.4-2,1.6-3.2H48V45.8z"></path>
                      <path class="st0" d="M21.3,31.7h-15c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h15c0.5,0,0.8-0.4,0.8-0.8v-3.3
                      C22.2,32,21.8,31.7,21.3,31.7z M20.5,35H7.2v-1.7h13.3V35z"></path>
                      <path class="st0" d="M5.5,38.3h1.7V40H5.5V38.3z"></path>
                      <path class="st0" d="M8.8,38.3h13.3V40H8.8V38.3z"></path>
                      <path class="st0" d="M5.5,41.7h16.7v1.7H5.5V41.7z"></path>
                      <path class="st0" d="M28.8,15h5v1.7h-5V15z"></path>
                      <path class="st0" d="M28.8,18.3h10.8V20H28.8V18.3z"></path>
                      <path class="st0" d="M43,18.3h2.5V20H43V18.3z"></path>
                      <path class="st0" d="M28.8,21.7h10.8v1.7H28.8V21.7z"></path>
                      <path class="st0" d="M43,21.7h2.5v1.7H43V21.7z"></path>
                      <path class="st0" d="M28.8,25h10.8v1.7H28.8V25z"></path>
                      <path class="st0" d="M43,25h2.5v1.7H43V25z"></path>
                      <path class="st0" d="M28.8,31.7h5v1.7h-5V31.7z"></path>
                      <path class="st0" d="M28.8,35h10.8v1.7H28.8V35z"></path>
                      <path class="st0" d="M43,35h2.5v1.7H43V35z"></path>
                      <path class="st0" d="M28.8,38.3h10.8V40H28.8V38.3z"></path>
                      <path class="st0" d="M43,38.3h2.5V40H43V38.3z"></path>
                      <path class="st0" d="M28.8,41.7h10.8v1.7H28.8V41.7z"></path>
                      <path class="st0" d="M43,41.7h2.5v1.7H43V41.7z"></path>
                    </g>
                  </svg>`,
					title: 'Restaurant Menu',
					text: 'Option to view the restaurant menu online on the mobile app just like it would be presented by a smiling waiter at the restaurant.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M36,9.2c0-2.3-1.7-4-3.9-4c-2.2,0-3.9,1.8-3.9,4c0,2.3,1.7,4,3.9,4C34.3,13.3,36,11.5,36,9.2z M30.4,9.2
                      c0-1,0.7-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7c0,1-0.7,1.7-1.7,1.7C31.1,11,30.4,10.2,30.4,9.2z M48.7,12.8L41,10.6
                      c0.1-0.5,0.1-0.9,0.1-1.3c0-5.1-4-9.2-9-9.2s-9,4.2-9,9.2c0,0.5,0.1,0.9,0.1,1.4l-6.8,2l-16-4.5C0,8-0.3,8.1-0.6,8.3
                      C-0.8,8.6-1,8.9-1,9.2v37c0,0.5,0.3,1,0.8,1.1l16.3,4.6c0.2,0.1,0.4,0.1,0.6,0l15.4-4.5l16,4.6c0.1,0,0.2,0.1,0.3,0.1
                      c0.2,0,0.5-0.1,0.7-0.2c0.3-0.2,0.4-0.6,0.4-0.9v-37C49.5,13.3,49.2,12.9,48.7,12.8z M32.1,2.3c3.7,0,6.7,3.1,6.7,6.9
                      c0,3.9-4.3,10.7-6.7,14.3c-2.5-3.5-6.7-10.4-6.7-14.3C25.4,5.4,28.4,2.3,32.1,2.3z M31,25.8v19.5l-13.5,4V14.7l6.3-1.8
                      C25.5,18.1,29.9,24.3,31,25.8z M1.2,10.7l14,4v34.6l-14-4V10.7z M47.3,49.3l-14-4V25.8c1.1-1.5,5.6-7.7,7.2-13l6.8,1.9V49.3z"></path>
                    </g>
                  </svg>`,
					title: 'Order Tracking',
					text: 'Be in the know of how long before the food will arrive at the user doorstep. GPS-enabled app ensures real-time info for users &amp; admins.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M25.8,45.2h9.7v1.6h-9.7V45.2z"></path>
                      <path class="st0" d="M19.4,14.5H21v24.2h-1.6V14.5z"></path>
                      <path class="st0" d="M48.4,16.1h-3.2V4c0-2.2-1.8-4-4-4h-21c-2.2,0-4,1.8-4,4v4H3.2v8.9H0v24.2h16.1V46c0,2.2,1.8,4,4,4h21
                      c2.2,0,4-1.8,4-4V33.9h3.2c0.9,0,1.6-0.7,1.6-1.6V17.7C50,16.9,49.3,16.1,48.4,16.1z M48.4,19.4H25.8v-1.6h22.6V19.4z M25.8,21
                      h22.6v1.6H25.8V21z M20.2,1.6h21c1.3,0,2.4,1.1,2.4,2.4v2.4H17.7V4C17.7,2.7,18.8,1.6,20.2,1.6z M16.1,25.1
                      c-0.3-0.1-0.5-0.1-0.8-0.1v-1.6h-1.6V25c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h1.6c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8
                      h-1.6c-0.4,0-0.8-0.4-0.8-0.8h-1.6c0,1.3,1.1,2.4,2.4,2.4v1.6h1.6v-1.6c0.3,0,0.6-0.1,0.8-0.1v3.2c-0.5,0.1-1.1,0.2-1.6,0.2
                      c-4,0-7.3-3.3-7.3-7.3c0-4,3.3-7.3,7.3-7.3c0.5,0,1.1,0.1,1.6,0.2V25.1z M16.1,28.4c-0.3-0.1-0.5-0.1-0.8-0.1h-1.6
                      c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h1.6c0.4,0,0.8,0.4,0.8,0.8V28.4z M4.8,9.7h11.3v7.3H4.8V9.7z M1.6,39.5v-21h14.5v1.8
                      c-0.5-0.1-1.1-0.2-1.6-0.2c-4.9,0-8.9,4-8.9,8.9c0,4.9,4,8.9,8.9,8.9c0.5,0,1.1-0.1,1.6-0.2v1.8H1.6z M41.1,48.4h-21
                      c-1.3,0-2.4-1.1-2.4-2.4v-2.4h25.8V46C43.5,47.3,42.5,48.4,41.1,48.4z M43.5,41.9H17.7V8.1h25.8v8.1H25.8c-0.9,0-1.6,0.7-1.6,1.6
                      v14.5c0,0.9,0.7,1.6,1.6,1.6h17.7V41.9z M25.8,32.3v-8.1h22.6l0,8.1H25.8z"></path>
                      <path class="st0" d="M27.4,29h4v1.6h-4V29z"></path>
                      <path class="st0" d="M4.8,21.8h1.6v-1.6H3.2v3.2h1.6V21.8z"></path>
                      <path class="st0" d="M6.5,11.3v3.2h1.6v-1.6h1.6v-1.6H6.5z"></path>
                      <path class="st0" d="M4.8,34.7H3.2v3.2h3.2v-1.6H4.8V34.7z"></path>
                      <path class="st0" d="M45.2,29h1.6v1.6h-1.6V29z"></path>
                      <path class="st0" d="M41.9,29h1.6v1.6h-1.6V29z"></path>
                      <path class="st0" d="M19.4,11.3H21v1.6h-1.6V11.3z"></path>
                    </g>
                  </svg>`,
					title: 'Multiple Payment Options',
					text: 'Cash on delivery, debit/credit cards, online banking or food coupons - users can pay any way they wish.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M32,24.2h6.7c0.5,0,0.8-0.4,0.8-0.8v-6.7c0-0.5-0.4-0.8-0.8-0.8H32c-0.5,0-0.8,0.4-0.8,0.8v6.7
                      C31.2,23.8,31.5,24.2,32,24.2z M32.8,17.5h5.1v5h-5.1V17.5z"></path>
                      <path class="st0" d="M5.9,15.8h7.6v1.7H5.9V15.8z"></path>
                      <path class="st0" d="M5.9,19.2h7.6v1.7H5.9V19.2z"></path>
                      <path class="st0" d="M5.9,28.3h11.8V30H5.9V28.3z"></path>
                      <path class="st0" d="M5.9,31.7h11.8v1.7H5.9V31.7z"></path>
                      <path class="st0" d="M5.9,35h11.8v1.7H5.9V35z"></path>
                      <path class="st0" d="M5.9,38.3h11.8V40H5.9V38.3z"></path>
                      <path class="st0" d="M37.9,35h0.8l-1.5,2l1.3,1l2.5-3.3c0.2-0.3,0.2-0.7,0-1l-2.5-3.3l-1.3,1l1.5,2h-0.8c-3.3,0-5.9,2.6-5.9,5.8
                      h1.7C33.7,36.9,35.6,35,37.9,35z"></path>
                      <path class="st0" d="M37.1,41.7h-0.8l1.5-2l-1.3-1L33.9,42c-0.2,0.3-0.2,0.7,0,1l2.5,3.3l1.3-1l-1.5-2h0.8c3.3,0,5.9-2.6,5.9-5.8
                      h-1.7C41.3,39.8,39.4,41.7,37.1,41.7z"></path>
                      <path class="st0" d="M43.8,28.8V12.5c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2L34.3,0.3
                      C34.2,0.1,33.9,0,33.7,0H11.8c-0.3,0-0.5,0.1-0.7,0.3L1.8,12c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
                      v30c0,0.5,0.4,0.8,0.8,0.8h23.9c1.9,3.9,6,6.7,10.7,6.7c6.5,0,11.8-5.2,11.8-11.7C48.9,34.4,46.9,30.9,43.8,28.8z M41.2,11.7H28.4
                      l-2.9-10h7.7L41.2,11.7z M18.5,13.3h8.4v5.5l-1.1-1.1c-0.3-0.3-0.9-0.3-1.2,0l-1.9,1.9l-1.9-1.9c-0.2-0.2-0.4-0.2-0.6-0.2
                      s-0.4,0.1-0.6,0.2l-1.1,1.1V13.3z M23.8,1.7l2.9,10h-7.9l2.9-10H23.8z M12.2,1.7h7.7l-2.9,10H4.2L12.2,1.7z M25.7,41.7H3.3V13.3
                      h13.5v7.5c0,0.3,0.2,0.6,0.5,0.8c0.3,0.1,0.7,0.1,0.9-0.2l1.9-1.9l1.9,1.9c0.3,0.3,0.9,0.3,1.2,0l1.9-1.9l1.9,1.9
                      c0.2,0.2,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.5-0.4,0.5-0.8v-7.5h13.5v14.5c0,0-0.1,0-0.1-0.1c-0.3-0.1-0.6-0.2-0.9-0.4
                      c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.1-1-0.2c-0.1,0-0.2,0-0.3,0
                      c-0.4,0-0.8-0.1-1.3-0.1c-6.5,0-11.8,5.2-11.8,11.7c0,0.4,0,0.7,0.1,1.1c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.1,0.8
                      c0,0.1,0,0.2,0,0.2C25.6,41.1,25.6,41.4,25.7,41.7z M37.1,48.3c-4.4,0-8.1-2.8-9.5-6.6c-0.1-0.2-0.1-0.3-0.2-0.5
                      c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.1-0.1-0.3-0.1-0.4c0-0.2-0.1-0.4-0.1-0.7c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.7-0.1-1
                      c0-5.5,4.5-10,10.1-10c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.3,0c0.3,0.1,0.5,0.1,0.8,0.2
                      c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.5,0.2,0.7,0.4
                      c3,1.7,4.9,4.9,4.9,8.6C47.2,43.8,42.6,48.3,37.1,48.3z"></path>
                    </g>
                  </svg>`,
					title: 'Re-Order',
					text: 'Repeating a favorite order should not take time or energy. Reorder feature makes it easy for users to repeat previous orders.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M29.4,19.3c-0.4,0-0.7,0.2-0.7,0.7v5.3c0,0.4,0.2,0.7,0.7,0.7h5.3c0.4,0,0.7-0.2,0.7-0.7V20c0-0.4-0.2-0.7-0.7-0.7H29.4z
                    M33.6,24.2h-3.3v-3.3h3.3V24.2z"></path>
                    <path d="M13.9,20c0-0.4-0.2-0.7-0.7-0.7H8c-0.4,0-0.7,0.2-0.7,0.7v5.3c0,0.4,0.2,0.7,0.7,0.7h5.3c0.4,0,0.7-0.2,0.7-0.7
                    C13.9,25.2,13.9,20,13.9,20z M12.3,24.2H9v-3.3h3.3V24.2z"></path>
                    <path d="M24.6,20c0-0.4-0.2-0.7-0.7-0.7h-5.3c-0.4,0-0.7,0.2-0.7,0.7v5.3c0,0.4,0.2,0.7,0.7,0.7h5.3c0.4,0,0.7-0.2,0.7-0.7V20z
                    M22.9,24.2h-3.3v-3.3h3.3V24.2z"></path>
                    <path d="M13.2,28.8H8c-0.4,0-0.7,0.2-0.7,0.7v5.3c0,0.4,0.2,0.7,0.7,0.7h5.3c0.4,0,0.7-0.2,0.7-0.7v-5.3
                    C13.9,29.1,13.7,28.8,13.2,28.8z M12.3,33.8H9v-3.3h3.3V33.8z"></path>
                    <path d="M23.9,28.8h-5.3c-0.4,0-0.7,0.2-0.7,0.7v5.3c0,0.4,0.2,0.7,0.7,0.7h5.3c0.4,0,0.7-0.2,0.7-0.7v-5.3
                    C24.6,29.1,24.4,28.8,23.9,28.8z M22.9,33.8h-3.3v-3.3h3.3V33.8z"></path>
                    <path d="M37.4,27.2c-6.3,0-11.4,5.1-11.4,11.4C26,44.9,31.1,50,37.4,50c6.3,0,11.4-5.1,11.4-11.4C48.8,32.3,43.7,27.2,37.4,27.2z
                    M37.4,48.4c-5.4,0-9.8-4.4-9.8-9.8c0-5.4,4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8C47.2,44,42.8,48.4,37.4,48.4z"></path>
                    <path d="M23.7,42.8c0.5,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8H5.6c-2.5,0-3.6-1.1-3.6-3.6v-26C2.1,9.1,3.1,8,5.6,8h1.7v0.9
                    c0,1.9,1.3,3.2,3.2,3.2c1.9,0,3.2-1.3,3.2-3.2V8h15v0.9c0,1.9,1.3,3.2,3.2,3.2c1.9,0,3.2-1.3,3.2-3.2V8H37c2.5,0,3.6,1.1,3.6,3.6
                    v13.5c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V11.6c0-3.4-1.8-5.2-5.2-5.2h-1.7v-3c0-1.9-1.3-3.2-3.2-3.2c-1.9,0-3.2,1.3-3.2,3.2v3
                    h-15v-3c0-1.9-1.3-3.2-3.2-3.2c-1.9,0-3.2,1.3-3.2,3.2v3H5.6c-3.4,0-5.2,1.8-5.2,5.2v26c0,3.4,1.8,5.2,5.2,5.2
                    C5.6,42.8,23.7,42.8,23.7,42.8z M30.4,3.4c0-1,0.6-1.6,1.6-1.6c1,0,1.6,0.6,1.6,1.6v5.5c0,1-0.6,1.6-1.6,1.6c-1,0-1.6-0.6-1.6-1.6
                    V3.4z M9,3.4c0-1,0.6-1.6,1.6-1.6c1,0,1.6,0.6,1.6,1.6v5.5c0,1-0.6,1.6-1.6,1.6c-1,0-1.6-0.6-1.6-1.6V3.4z"></path>
                    <rect x="4.5" y="14.7" width="33.6" height="1.6"></rect>
                    <path d="M42.1,42.5L37.5,39v-6.7c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8v7.1c0,0.3,0.1,0.5,0.3,0.7l5,3.7
                    c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0,0.5-0.1,0.7-0.3C42.6,43.2,42.5,42.7,42.1,42.5z"></path>
                  </svg>`,
					title: 'Schedule Order',
					text: 'Schedule the order to be placed and delivered at a stipulated time and place most convenient to the user.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M13.1,35.3H2.7c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2h10.4c0.7,0,1.2,0.5,1.2,1.2
                      C14.3,34.8,13.8,35.3,13.1,35.3L13.1,35.3z M3.5,2.4h8.9c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H6.8c-2,0-3.6,1.6-3.6,3.6
                      s1.6,3.6,3.6,3.6h3.9c0.7,0,1.2,0.5,1.2,1.2v1.9c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2v-1.9c0-2-1.6-3.6-3.6-3.6H6.8
                      c-0.7,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h5.6c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6H3.5C2.9,0,2.3,0.5,2.3,1.2
                      C2.3,1.9,2.9,2.4,3.5,2.4L3.5,2.4z M50,19.1v29.7c0,0.7-0.5,1.2-1.2,1.2H7c-0.7,0-1.2-0.5-1.2-1.2v-8.9H4c-0.7,0-1.2-0.5-1.2-1.2
                      c0-0.7,0.5-1.2,1.2-1.2h13.8c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H8.2v7.7h39.3V20.3H8.2v8h12.2c0.7,0,1.2,0.5,1.2,1.2
                      c0,0.7-0.5,1.2-1.2,1.2H1.2c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2h4.6v-9.3c0-0.7,0.5-1.2,1.2-1.2h10.8V7.2
                      c0-4,3.2-7.2,7.1-7.2h5.9C34.8,0,38,3.2,38,7.2v10.7h10.8C49.5,17.8,50,18.4,50,19.1L50,19.1z M35.6,7.2c0-2.6-2.1-4.7-4.7-4.7
                      h-5.9c-2.6,0-4.7,2.1-4.7,4.7v10.7h15.3V7.2z M26.9,42.9l-5.9-7c-0.3-0.4-0.4-0.9-0.2-1.3c0.2-0.4,0.6-0.7,1.1-0.7h1.2
                      c3.1-5.7,5.8-9.4,9.1-9.4c3.3,0,6,3.7,9.1,9.4h1.2c0.5,0,0.9,0.3,1.1,0.7c0.2,0.4,0.1,0.9-0.2,1.3l-5.9,7c-0.2,0.3-0.6,0.4-0.9,0.4
                      h-9C27.5,43.4,27.1,43.2,26.9,42.9L26.9,42.9z M26,33.9h12.7c-1.9-3.4-4.3-7-6.3-7C30.3,26.9,27.9,30.5,26,33.9L26,33.9z
                      M24.6,36.3l3.8,4.6h7.9l3.8-4.6H24.6z M24.6,36.3"></path>
                    </g>
                  </svg>`,
					title: 'Takeaway Option',
					text: 'Order online, takeaway offline. Users can place orders from restaurants, pay for them and pick them up themselves.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M16.1,0v23.4h-0.8h-1.9c-0.3,0-0.5-0.2-0.5-0.5c0,0,0-0.1,0-0.1c1-1.5,1.6-3.3,1.6-5.2v-3
                      c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4c0,2.4-0.9,4.6-2.6,6.3l-1.8,1.8c-0.5,0.5-1.1,0.7-1.7,0.7H1.6v-0.8v-0.8H0v0.8v13.7
                      v0.8h1.6v-0.8v-0.8h2.2c0.5,0,1,0.2,1.5,0.5l1.5,1.1c0.7,0.5,1.6,0.8,2.4,0.8h6.2h0.8V50h25.3l8.5-8.5V0H16.1z M21,25
                      c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8h-5.6c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8H21z M21.8,29
                      c0,0.4-0.4,0.8-0.8,0.8h-5.6c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8H21C21.4,28.2,21.8,28.6,21.8,29z M14.5,32.3
                      c0-0.4,0.4-0.8,0.8-0.8H21c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8h-5.6C14.9,33.1,14.5,32.7,14.5,32.3z M7.7,35.8l-1.5-1.1
                      c-0.7-0.5-1.6-0.8-2.4-0.8H1.6V25h1.9c1.1,0,2.1-0.4,2.9-1.2L8.2,22c2-2,3.1-4.6,3.1-7.5c0-0.4,0.4-0.8,0.8-0.8
                      c0.4,0,0.8,0.4,0.8,0.8v3c0,1.6-0.5,3.1-1.4,4.4L11.4,22c-0.1,0.3-0.2,0.5-0.2,0.8c0,1.1,0.8,1.9,1.8,2.1c-0.1,0.3-0.2,0.5-0.2,0.8
                      c0,0.6,0.2,1.2,0.6,1.6c-0.4,0.4-0.6,1-0.6,1.6c0,0.6,0.2,1.2,0.6,1.6c-0.4,0.4-0.6,1-0.6,1.6s0.2,1.2,0.6,1.6
                      c-0.4,0.4-0.6,1-0.6,1.6c0,0.3,0.1,0.6,0.1,0.8H9.1C8.6,36.3,8.1,36.1,7.7,35.8z M15.3,36.3c-0.4,0-0.8-0.4-0.8-0.8
                      c0-0.4,0.4-0.8,0.8-0.8H21c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8h-1.6H15.3z M17.7,37.9h1.6H21c1.3,0,2.4-1.1,2.4-2.4
                      c0-0.6-0.2-1.2-0.6-1.6c0.4-0.4,0.6-1,0.6-1.6s-0.2-1.2-0.6-1.6c0.4-0.4,0.6-1,0.6-1.6c0-0.6-0.2-1.2-0.6-1.6
                      c0.4-0.4,0.6-1,0.6-1.6c0-1.3-1.1-2.4-2.4-2.4h-3.2V1.6h30.6v38.7h-8.1v8.1H17.7V37.9z M47.2,41.9l-5.3,5.3v-5.3H47.2z"></path>
                      <path class="st0" d="M34.9,18l-2.2,2.2H25v8.1h8.1v-6.1l3-3L34.9,18z M31.5,26.6h-4.8v-4.8h4.5L29,23.9l-1-1L26.8,24l2.2,2.2
                      l2.4-2.4V26.6z"></path>
                      <path class="st0" d="M25,38.7h8.1v-8.1H25V38.7z M26.6,32.3h4.8v4.8h-4.8V32.3z"></path>
                      <path class="st0" d="M37.1,20.2h9.7v1.6h-9.7V20.2z"></path>
                      <path class="st0" d="M37.1,26.6h9.7v1.6h-9.7V26.6z"></path>
                      <path class="st0" d="M37.1,23.4h4V25h-4V23.4z"></path>
                      <path class="st0" d="M42.7,23.4h4V25h-4V23.4z"></path>
                      <path class="st0" d="M37.1,30.6h9.7v1.6h-9.7V30.6z"></path>
                      <path class="st0" d="M37.1,37.1h9.7v1.6h-9.7V37.1z"></path>
                      <path class="st0" d="M37.1,33.9h4v1.6h-4V33.9z"></path>
                      <path class="st0" d="M42.7,33.9h4v1.6h-4V33.9z"></path>
                      <path class="st0" d="M27.2,9.9l0.2-2.4l1.2-2l-2.1-0.9L25,2.7l-1.5,1.9l-2.1,0.9l1.2,2l0.2,2.4L25,9.3L27.2,9.9z M24.2,7l-0.4-0.8
                      l0.8-0.3L25,5.3l0.5,0.6l0.8,0.3L25.8,7l-0.1,0.8L25,7.7l-0.8,0.2L24.2,7z"></path>
                      <path class="st0" d="M30.8,9.9l2.2-0.6l2.2,0.6l0.2-2.4l1.2-2l-2.1-0.9l-1.5-1.9l-1.5,1.9l-2.1,0.9l1.2,2L30.8,9.9z M32.6,5.9
                      l0.5-0.6l0.5,0.6l0.8,0.3L33.9,7l-0.1,0.8l-0.8-0.2l-0.8,0.2L32.2,7l-0.4-0.8L32.6,5.9z"></path>
                      <path class="st0" d="M25.5,12l1.2,2l0.2,2.4l2.2-0.6l2.2,0.6l0.2-2.4l1.2-2l-2.1-0.9L29,9.2l-1.5,1.9L25.5,12z M28.5,12.4l0.5-0.6
                      l0.5,0.6l0.8,0.3l-0.4,0.8l-0.1,0.8L29,14.1l-0.8,0.2l-0.1-0.8l-0.4-0.8L28.5,12.4z"></path>
                      <path class="st0" d="M33.5,12l1.2,2l0.2,2.4l2.2-0.6l2.2,0.6l0.2-2.4l1.2-2l-2.1-0.9l-1.5-1.9l-1.5,1.9L33.5,12z M36.6,12.4
                      l0.5-0.6l0.5,0.6l0.8,0.3l-0.4,0.8l-0.1,0.8l-0.8-0.2l-0.8,0.2l-0.1-0.8l-0.4-0.8L36.6,12.4z"></path>
                      <path class="st0" d="M38.9,9.9l2.2-0.6l2.2,0.6l0.2-2.4l1.2-2l-2.1-0.9l-1.5-1.9l-1.5,1.9l-2.1,0.9l1.2,2L38.9,9.9z M40.6,5.9
                      l0.5-0.6l0.5,0.6l0.8,0.3L42,7l-0.1,0.8l-0.8-0.2l-0.8,0.2L40.3,7l-0.4-0.8L40.6,5.9z"></path>
                    </g>
                  </svg>`,
					title: 'Ratings &amp; Reviews',
					text: 'General feedback from users can be collected and displayed in the form of text reviews and star ratings against each restaurant.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M0.2,11v1.2v6.5v1.2h1.2c3,0,5.3,2.4,5.3,5.3c0,3-2.4,5.3-5.3,5.3H0.2v1.2v6.5v1.2h1.2h47.3h1.2v-1.2v-6.5
                      v-1.2h-1.2c-3,0-5.3-2.4-5.3-5.3c0-3,2.4-5.3,5.3-5.3h1.2v-1.2v-6.5V11h-1.2H1.4H0.2L0.2,11z M2.5,13.4h30.1v3.6H35v-3.6h12.4v4.3
                      c-3.7,0.6-6.5,3.8-6.5,7.6s2.8,7,6.5,7.6v4.3H35v-3.6h-2.4v3.6H2.5v-4.3C6.2,32.3,9,29.1,9,25.2c0-3.8-2.8-7-6.5-7.6V13.4z
                      M26.6,16.9c-0.3,0-0.6,0.2-0.8,0.4L14,31.6c-0.4,0.5-0.4,1.2,0,1.7c0.5,0.4,1.2,0.4,1.7,0c0,0,0.1-0.1,0.1-0.1l11.8-14.3
                      c0.4-0.5,0.4-1.2-0.1-1.7C27.3,17,27,16.9,26.6,16.9z M16.1,16.9c-1.9,0-3.5,1.6-3.5,3.6c0,2,1.6,3.6,3.5,3.6
                      c1.9,0,3.5-1.6,3.5-3.6C19.7,18.5,18.1,16.9,16.1,16.9z M16.1,19.3c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2
                      c-0.7,0-1.2-0.5-1.2-1.2C15,19.8,15.5,19.3,16.1,19.3z M32.7,19.3v4.8H35v-4.8H32.7z M25.6,26.4C23.6,26.4,22,28,22,30
                      c0,2,1.6,3.6,3.5,3.6s3.5-1.6,3.5-3.6C29.1,28,27.5,26.4,25.6,26.4z M32.7,26.4v4.7H35v-4.7H32.7z M25.6,28.8
                      c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2C24.4,29.3,24.9,28.8,25.6,28.8z"></path>
                    </g>
                  </svg>`,
					title: 'Promos &amp; Offers',
					text: 'Promo codes, seasonal offers, coupon codes and several other innovative forms of incentives can be baked into the app.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M48,37.4c0-1.3,1.4-2.7,1.4-2.7c0.2-0.2,0.3-0.7,0.2-1l-1-2.6c-0.1-0.3-0.5-0.5-0.9-0.5c0,0-1.9,0-2.9-1
                    c-0.9-1-0.9-2.9-0.9-2.9c0-0.3-0.2-0.7-0.5-0.9l-2.6-1.1c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0.1-0.7,0.3c0,0-1.4,1.4-2.7,1.4h0
                    c-1.3,0-2.7-1.4-2.7-1.4c-0.2-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.2,0-0.3,0l-1.5,0.6c-1.5-0.8-3-1.3-4.6-1.7c4.2-2,7.1-6.2,7.1-11.2
                    C34.4,5.5,28.8,0,22,0C15.2,0,9.7,5.5,9.7,12.4c0,5,2.9,9.2,7.1,11.2C7.4,25.9,0.5,34.3,0.5,44.4c0,0.2,0.1,0.5,0.3,0.6
                    s0.4,0.3,0.6,0.3h28c0.7,1,0.7,2.6,0.7,2.6c0,0.3,0.2,0.7,0.5,0.9l2.6,1.1c0.1,0,0.2,0,0.3,0c0.3,0,0.6-0.1,0.7-0.3
                    c0,0,1.4-1.4,2.7-1.4h0c1.3,0,2.7,1.4,2.7,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l2.6-1c0.3-0.1,0.5-0.5,0.5-0.9
                    c0,0,0-1.9,1-2.9c0.9-0.9,2.7-0.9,2.9-0.9h0h0c0.3,0,0.7-0.2,0.9-0.5l1.1-2.6c0.1-0.3,0-0.8-0.2-1C49.3,40.1,48,38.8,48,37.4z
                    M14.6,19.8c-1.9-1.9-3.1-4.5-3.1-7.5c0-2.9,1.2-5.5,3.1-7.5C16.5,3,19.1,1.8,22,1.8c2.9,0,5.5,1.2,7.5,3.1c1.9,1.9,3.1,4.5,3.1,7.5
                    c0,2.9-1.2,5.5-3.1,7.5c-1.9,1.9-4.5,3.1-7.5,3.1C19.1,22.9,16.5,21.7,14.6,19.8z M8.1,30.5c3.6-3.6,8.5-5.8,13.9-5.8
                    c2.9,0,5.7,0.7,8.2,1.8c0,0,0,0.1,0,0.1c0,0,0,1.9-1,2.9c-0.9,0.9-2.8,0.9-2.9,0.9h0h0c-0.3,0-0.7,0.2-0.9,0.5l-1.1,2.6
                    c-0.1,0.3,0,0.8,0.2,1c0,0,1.4,1.4,1.4,2.7c0,1.3-1.4,2.7-1.4,2.7c-0.2,0.2-0.3,0.7-0.2,1l1,2.6c0,0,0,0,0,0H2.3
                    C2.6,38.4,4.7,33.9,8.1,30.5z M47.6,41L47,42.4c-0.9,0.1-2.5,0.3-3.6,1.4c-1.1,1.1-1.4,2.7-1.5,3.6l-1.5,0.6c-0.7-0.6-2-1.5-3.6-1.6
                    c-1.5,0-2.9,0.9-3.6,1.5l-1.5-0.6c-0.1-0.9-0.3-2.5-1.4-3.6c-1.1-1.1-2.7-1.4-3.6-1.5l-0.6-1.5c0.6-0.7,1.5-2,1.6-3.6
                    c0-1.5-0.9-2.9-1.5-3.6l0.6-1.5c0.9-0.1,2.5-0.4,3.6-1.4c1.1-1.1,1.4-2.7,1.5-3.6l1.5-0.6c0.7,0.6,2,1.5,3.6,1.6h0
                    c1.5,0,2.9-0.9,3.6-1.5l1.5,0.6c0.1,0.9,0.3,2.5,1.4,3.6c1.1,1.1,2.7,1.4,3.6,1.5l0.6,1.5c-0.6,0.7-1.5,2-1.6,3.6
                    C46.1,38.9,47.1,40.3,47.6,41z"></path>
                    <path d="M37,32.2L37,32.2l0,1.8c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3L37,32.2
                    c-2.8,0-5.1,2.3-5.2,5.1c0,2.8,2.3,5.1,5.1,5.2l0,0c2.8,0,5.1-2.3,5.1-5.1C42.1,34.5,39.8,32.2,37,32.2z"></path>
                  </svg>`,
					title: 'Manage Profile',
					text: 'From display name to delivery addresses and everything in between like favorite payment mode, users can manage their profile in all ways.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M15,35c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3
                        C15.5,36,15.4,35.4,15,35L15,35z M13.7,14.2c-5,5-6,12.8-2.5,18.9c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3
                        c-3.1-5.4-2.2-12.2,2.2-16.6c5.4-5.4,14.1-5.4,19.4,0c5.4,5.4,5.4,14.1,0,19.4c-4.4,4.4-11.2,5.3-16.6,2.2
                        c-0.5-0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c2.5,1.4,5.2,2.1,7.9,2.1c4.1,0,8.1-1.6,11.1-4.6c6.1-6.1,6.1-16,0-22.2
                        C29.7,8.1,19.8,8.1,13.7,14.2z M49.5,21c0-0.5-0.3-0.9-0.8-1l-3.6-0.6l-1.9-4.6l2.1-2.9c0.3-0.4,0.2-0.9-0.1-1.3l-6-6
                        c-0.3-0.3-0.9-0.4-1.3-0.1l-2.9,2.1l-4.6-1.9l-0.6-3.6c-0.1-0.5-0.5-0.8-1-0.8h0l-8.5,0c-0.5,0-0.9,0.3-1,0.8L19,4.9l-4.6,1.9
                        l-2.9-2.1c-0.4-0.3-0.9-0.2-1.3,0.1l-6,6c-0.3,0.3-0.4,0.9-0.1,1.3l2.1,2.9l-1.9,4.6l-3.6,0.6C0.3,20.1,0,20.5,0,21l0,8.5
                        c0,0.5,0.3,0.9,0.8,1L4.4,31l1.9,4.6l-2.1,2.9c-0.3,0.4-0.2,0.9,0.1,1.3l6,6c0.3,0.3,0.9,0.4,1.3,0.1l2.9-2.1l4.6,1.9l0.6,3.6
                        c0.1,0.5,0.5,0.8,1,0.8h0l8.5,0c0.5,0,0.9-0.3,1-0.8l0.6-3.6l4.6-1.9l2.9,2.1c0.4,0.3,0.9,0.2,1.3-0.1l6-6
                        c0.3-0.3,0.4-0.9,0.1-1.3l-2.1-2.9l1.9-4.6l3.6-0.6c0.5-0.1,0.8-0.5,0.8-1L49.5,21z M44.3,29.2c-0.3,0.1-0.6,0.3-0.7,0.6l-2.3,5.6
                        c-0.1,0.3-0.1,0.7,0.1,0.9l2,2.7l-4.8,4.8l-2.7-2c-0.3-0.2-0.6-0.2-0.9-0.1L29.3,44c-0.3,0.1-0.5,0.4-0.6,0.7l-0.5,3.3l-6.8,0
                        l-0.5-3.3c-0.1-0.3-0.3-0.6-0.6-0.7l-5.6-2.3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2l-2.7,2L6.2,39l2-2.7
                        c0.2-0.3,0.2-0.6,0.1-0.9L6,29.8c-0.1-0.3-0.4-0.5-0.7-0.6l-3.3-0.5l0-6.8l3.3-0.5C5.6,21.3,5.8,21,6,20.7l2.3-5.6
                        c0.1-0.3,0.1-0.7-0.1-0.9l-2-2.7L11,6.7l2.7,2c0.3,0.2,0.6,0.2,0.9,0.1l5.6-2.3c0.3-0.1,0.5-0.4,0.6-0.7l0.5-3.3l6.8,0l0.5,3.3
                        c0.1,0.3,0.3,0.6,0.6,0.7l5.6,2.3c0.3,0.1,0.7,0.1,0.9-0.1l2.7-2l4.8,4.8l-2,2.7c-0.2,0.3-0.2,0.6-0.1,0.9l2.3,5.6
                        c0.1,0.3,0.4,0.5,0.7,0.6l3.3,0.5l0,6.8L44.3,29.2z M20.4,25.4l4.2,4.2c-0.3,1.7,0.3,3.4,1.5,4.6c1,1,2.4,1.6,3.8,1.6
                        c0.7,0,1.4-0.1,2-0.4c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.3-0.9l-1.4-1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
                        l1.4,1.4c0.2,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6c0.8-2.1,0.3-4.5-1.4-6c-1.2-1.1-2.8-1.6-4.4-1.3l-4.2-4.2
                        c0.3-1.7-0.3-3.4-1.5-4.6c-1.5-1.5-3.8-2-5.8-1.2c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.3,0.9l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
                        c-0.3,0.3-0.8,0.3-1.1,0l-1.4-1.4c-0.2-0.2-0.6-0.3-0.9-0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.8,2.1-0.3,4.5,1.4,6
                        C17.2,25.1,18.8,25.6,20.4,25.4z M17.2,22.6c-0.6-0.6-1-1.4-1.1-2.2l0.1,0.1c1.1,1.1,2.8,1.1,3.8,0c1.1-1.1,1.1-2.8,0-3.8l0,0
                        c0.8,0.1,1.5,0.4,2,1c0.9,0.9,1.2,2.1,0.9,3.3c-0.1,0.3,0,0.7,0.3,0.9l5,5c0.2,0.2,0.6,0.3,0.9,0.3c1.1-0.3,2.3,0,3.2,0.8
                        c0.6,0.6,1,1.3,1.1,2.2l0,0c-1.1-1.1-2.8-1.1-3.8,0c-1.1,1.1-1.1,2.8,0,3.8l0,0c-0.8-0.1-1.5-0.4-2.1-1c-0.9-0.9-1.2-2.1-0.9-3.3
                        c0.1-0.3,0-0.7-0.3-0.9l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.2,0-0.2,0C19.3,23.7,18.1,23.4,17.2,22.6z M27.2,14.3
                        c-0.5-0.1-1,0.2-1.2,0.7c-0.1,0.5,0.2,1,0.7,1.2c2.1,0.5,3.9,1.7,5.3,3.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
                        c0.4-0.3,0.5-0.9,0.2-1.4C32,16.3,29.7,14.9,27.2,14.3z M33.6,22.4c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
                        c0.5-0.2,0.8-0.7,0.6-1.2l0,0c-0.2-0.5-0.7-0.8-1.2-0.6C33.7,21.3,33.4,21.9,33.6,22.4L33.6,22.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Help &amp; Support',
					text: 'One-touch help and support system that allows users to reach out to support team for immediate assistance.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M9.7,0c-3,0-5.5,2.5-5.5,5.5v39.1c0,3,2.5,5.5,5.5,5.5h17.4c3,0,5.5-2.5,5.5-5.5v-8.3c1.3-1.2,1.9-2.6,2.2-3.4
                    h9.7c1.3,0,2.4-1.1,2.4-2.3V13.3c0-1.3-1.1-2.3-2.4-2.3H32.6V5.5c0-3-2.5-5.5-5.5-5.5H9.7z M9.7,1.6h17.4c2.2,0,3.9,1.8,3.9,3.9v0.8
                    H5.8V5.5C5.8,3.3,7.6,1.6,9.7,1.6z M14.5,3.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8H16c0.4,0,0.8-0.3,0.8-0.8
                    c0-0.4-0.4-0.8-0.8-0.8H14.5z M19.2,3.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h3.2c0.4,0,0.8-0.3,0.8-0.8
                    c0-0.4-0.4-0.8-0.8-0.8H19.2z M5.8,7.8H31v3.1h-7.1c-1.3,0-2.4,1.1-2.4,2.3v17.2c0,1.3,1.1,2.3,2.4,2.3H28c0.1,0.7,0,2.1-0.9,4.4
                    c-0.1,0.3-0.1,0.6,0.1,0.8c0.2,0.2,0.4,0.3,0.6,0.3c0,0,0.1,0,0.1,0c1.2-0.2,2.2-0.5,3-1v3.3H5.8V7.8z M23.9,12.5h20.5
                    c0.4,0,0.8,0.3,0.8,0.8v17.2c0,0.4-0.4,0.8-0.8,0.8H34.2c-0.4,0-0.7,0.3-0.8,0.6c0,0.2-0.6,3.4-4.3,4.5c1-3.2,0.4-4.6,0.3-4.7
                    c-0.1-0.3-0.4-0.4-0.7-0.4h-4.7c-0.4,0-0.8-0.3-0.8-0.8V13.3C23.1,12.9,23.5,12.5,23.9,12.5z M34.1,14.1c-2.2,0-4,1.6-4.3,3.8
                    l-0.4,3.1L28,24.4c-0.2,0.5-0.1,1,0.1,1.5c0.3,0.4,0.8,0.7,1.3,0.7h3.2c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6H39
                    c0.5,0,1-0.3,1.3-0.7c0.3-0.4,0.3-1,0.1-1.5l-1.5-3.5l-0.4-3.1C38.2,15.7,36.4,14.1,34.1,14.1L34.1,14.1z M34.1,15.6L34.1,15.6
                    c1.5,0,2.6,1,2.8,2.4l0.4,3.2c0,0.1,0,0.1,0.1,0.2L39,25h-9.5l1.3-3.1H35c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-3.8l0.3-2.3
                    C31.6,16.6,32.8,15.6,34.1,15.6z M8.9,34.4c-0.4,0-0.8,0.3-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.6
                    C9.7,34.7,9.4,34.4,8.9,34.4z M12.9,34.4c-0.4,0-0.8,0.3-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-1.6
                    C13.7,34.7,13.3,34.4,12.9,34.4z M16.8,34.4c-0.4,0-0.8,0.3-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.6
                    C17.6,34.7,17.3,34.4,16.8,34.4z M20.8,34.4c-0.4,0-0.8,0.3-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-1.6
                    C21.6,34.7,21.2,34.4,20.8,34.4z M24.7,34.4c-0.4,0-0.8,0.3-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-1.6
                    C25.5,34.7,25.1,34.4,24.7,34.4z M5.8,42.2H31v2.3c0,2.2-1.8,3.9-3.9,3.9H9.7c-2.2,0-3.9-1.8-3.9-3.9V42.2z M18.4,43.7
                    c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6C20,44.4,19.3,43.7,18.4,43.7z"></path>
                  </svg>`,
					title: 'Push Notifications',
					text: 'In-app push notifications that alert users of placed orders, completed orders, cancelled orders, new offers and much more.'
				}
			],
			items2: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M27.5,40.8h-5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.4,2.5,2.4h5c1.4,0,2.5-1.1,2.5-2.4
                        C30,41.9,28.9,40.8,27.5,40.8z M27.5,44.1h-5c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h5c0.5,0,0.8,0.4,0.8,0.8
                        C28.3,43.7,28,44.1,27.5,44.1z M25,18.8c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3
                        C21.7,17.3,23.2,18.8,25,18.8z M25,13.9c0.9,0,1.7,0.7,1.7,1.6c0,0.9-0.7,1.6-1.7,1.6c-0.9,0-1.7-0.7-1.7-1.6
                        C23.3,14.6,24.1,13.9,25,13.9z M20.8,26.1h8.3c0.5,0,0.8-0.4,0.8-0.8V24c0-2-1.6-3.6-3.6-3.6h-2.7c-2,0-3.6,1.6-3.6,3.6v1.3
                        C20,25.8,20.4,26.1,20.8,26.1z M21.7,24c0-1.1,0.9-1.9,2-1.9h2.7c1.1,0,2,0.9,2,1.9v0.5h-6.7V24z M7.5,3.3H4.2
                        c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V4.1C8.3,3.6,8,3.3,7.5,3.3z M6.7,6.5H5V4.9h1.7V6.5
                        z M14.2,3.3h-3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V4.1C15,3.6,14.6,3.3,14.2,3.3z
                        M13.3,6.5h-1.7V4.9h1.7V6.5z M20.8,3.3h-3.3c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V4.1
                        C21.7,3.6,21.3,3.3,20.8,3.3z M20,6.5h-1.7V4.9H20V6.5z M46.2,0H3.8C1.7,0,0,1.6,0,3.7v6.9v34.7c0,2,1.7,3.7,3.8,3.7h42.5
                        c2.1,0,3.7-1.6,3.7-3.7V10.6V3.7C50,1.6,48.3,0,46.2,0z M48.3,45.3c0,1.1-0.9,2-2.1,2H3.8c-1.1,0-2.1-0.9-2.1-2V11.4h46.7V45.3z
                        M48.3,9.8H1.7V3.7c0-1.1,0.9-2,2.1-2h42.5c1.1,0,2.1,0.9,2.1,2V9.8z M12.5,32.7h25c0.5,0,0.8-0.4,0.8-0.8v-3.3
                        c0-0.5-0.4-0.8-0.8-0.8h-25c-0.5,0-0.8,0.4-0.8,0.8v3.3C11.7,32.3,12,32.7,12.5,32.7z M13.3,29.4h23.3V31H13.3V29.4z M12.5,39.2
                        h10.8c0.5,0,0.8-0.4,0.8-0.8v-3.3c0-0.5-0.4-0.8-0.8-0.8H12.5c-0.5,0-0.8,0.4-0.8,0.8v3.3C11.7,38.8,12,39.2,12.5,39.2z
                        M13.3,35.9h9.2v1.6h-9.2V35.9z M37.5,34.3H26.7c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h10.8c0.5,0,0.8-0.4,0.8-0.8
                        v-3.3C38.3,34.7,38,34.3,37.5,34.3z M36.7,37.6h-9.2v-1.6h9.2V37.6z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Create Profile',
					text: 'Driver can create their unique profile complete with vehicle details, driving license number &amp; contact details.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M41.7,45c-0.2-1.5-0.5-2.7-0.7-3.8c-0.6-3.4-1-5.4-0.8-9.6c0-1.2-0.3-2.1-0.9-2.9c-0.6-0.8-1.4-1.4-2.3-1.9
                        c-0.9-0.5-1.5-1.6-2.1-2.6c-0.9-1.5-1.7-3-3.6-3.8V3.7c0-1-0.4-2-1.1-2.6C29.5,0.4,28.6,0,27.5,0H12.9c-1,0-2,0.4-2.7,1.1
                        C9.6,1.8,9.1,2.7,9.1,3.7v29.4c0,1,0.4,2,1.1,2.6c0.7,0.7,1.6,1.1,2.7,1.1h3.6c0,0.1-0.1,0.2-0.1,0.3l0,0.1
                        c-0.2,0.4-0.2,0.9-0.2,1.4c0,0.5,0.2,0.9,0.4,1.3c0.5,1,1.5,1.7,2.9,1.5c0.4-0.1,0.9-0.1,1.3-0.2c0.9-0.2,1.4-0.3,2.4-0.1
                        c0.6,1.2,1.4,2.3,2.4,3.3c1,1.1,2.1,2,3.1,2.9l0.3,0.7c0.1,0.3,0.2,0.5,0.3,0.7c0,0.1,0.1,0.1,0.1,0.2c0.8,1.3,3.9,1.4,6.7,0.8
                        c1-0.2,2-0.5,2.8-0.9c0.9-0.4,1.7-0.8,2.2-1.4C41.8,46.7,42.1,45.9,41.7,45z M12.9,35c-0.5,0-1-0.2-1.3-0.5
                        c-0.3-0.3-0.5-0.8-0.5-1.3V3.7c0-0.5,0.2-1,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5h14.6c0.5,0,1,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3V21
                        v8.1c-0.7-0.6-1.5-1.1-2.2-1.7c-1.3-1-2.7-2-4.2-3c-1.3-0.9-2.6-1-3.6-0.5c-0.5,0.2-0.9,0.5-1.3,0.9c-0.3,0.4-0.5,0.9-0.7,1.4
                        c-0.2,1,0,2.1,0.8,3.1c-0.4,0.5-0.7,1.1-0.7,1.8c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.8,1.9,0.8c0.7,0,1.3-0.2,1.7-0.6l0,0
                        c0.5,0.6,1.1,1.3,1.6,2H12.9z M19.2,30.1c0.7,0.7,1.3,1.3,1.9,2c-0.2,0.2-0.5,0.3-0.9,0.3c-0.4,0-0.7-0.1-1-0.4
                        c-0.2-0.3-0.4-0.6-0.4-1C18.8,30.6,19,30.3,19.2,30.1z M39.7,46.1c-0.3,0.3-0.9,0.7-1.6,1c-0.7,0.3-1.6,0.6-2.5,0.8
                        c-2.1,0.5-4.2,0.6-4.7,0.1l-0.2-0.6l-0.4-0.9c-0.1-0.2-0.2-0.3-0.3-0.4c-1.1-0.9-2.2-1.8-3.1-2.8c-0.9-1-1.7-2.1-2.3-3.2
                        c-0.1-0.3-0.4-0.5-0.7-0.5c-1.6-0.3-2.3-0.2-3.5,0c-0.3,0.1-0.7,0.1-1.2,0.2c-0.4,0.1-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.4-0.2-0.6
                        c0-0.2,0-0.4,0.1-0.6l0,0c0.1-0.3,0.4-0.4,0.8-0.5l5.9,0c0.2,1.3,0.4,2.4,0.9,3.3c0.6,1.2,1.4,2.3,2.6,3.4c0.4,0.4,1,0.4,1.3,0
                        c0.4-0.4,0.3-1,0-1.3c-1-1-1.7-1.9-2.2-2.9c-0.4-0.9-0.7-2-0.8-3.4c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0-0.3-0.2-0.5-0.4-0.6
                        c-0.9-1.3-1.8-2.5-2.8-3.7c-1.1-1.2-2.2-2.4-3.6-3.7c-0.5-0.5-0.6-1.1-0.5-1.6c0.1-0.2,0.1-0.4,0.3-0.6c0.1-0.2,0.3-0.3,0.5-0.4
                        c0.4-0.2,1.1-0.1,1.7,0.3c1.5,1,2.8,2,4.2,3c1.2,0.9,2.4,1.8,3.5,2.8l2.1,2.1c0.4,0.4,1.1,0.4,1.5,0c0.4-0.4,0.4-1.1,0-1.5
                        l-1.8-1.8v-8c0.9,0.6,1.4,1.6,2,2.6c0.7,1.2,1.4,2.5,2.7,3.3c0.7,0.4,1.3,0.9,1.8,1.4c0.4,0.5,0.6,1.1,0.6,1.7
                        c-0.2,4.5,0.2,6.5,0.8,10c0.2,1.1,0.4,2.3,0.7,3.9c0,0.1,0,0.2,0.1,0.2C40,45.7,39.9,45.9,39.7,46.1z M18.9,20.2l0.6-0.6l8.3-8.2
                        c0.3-0.4,0.3-0.9,0-1.3c-0.4-0.4-0.9-0.4-1.3,0l-7.6,7.6c-0.4,0.4-0.9,0.4-1.3,0l-2.5-2.5c-0.4-0.4-0.9-0.4-1.3,0
                        c-0.4,0.4-0.4,0.9,0,1.3l3.8,3.8C18,20.6,18.5,20.6,18.9,20.2z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Availability Settings',
					text: 'Driver can toggle between available and unavailable status to take or reject upcoming deliver orders.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M23.3,24.8c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1
                        C20.2,23.4,21.6,24.8,23.3,24.8z M23.3,19.7c1.1,0,1.9,0.9,1.9,1.9c0,1.1-0.9,1.9-1.9,1.9c-1.1,0-1.9-0.9-1.9-1.9
                        C21.4,20.6,22.2,19.7,23.3,19.7z M20.2,25.5c0,0-3.1,0-3.1,2.9c0,2.9,0,3.5,0,3.5s6.1,0,6.1,0L20.2,25.5z M18.2,28.4
                        c0-1.1,0.7-1.5,1.3-1.7l1.9,4.1h-3.2V28.4z M29.5,31.9c0,0,0-0.7,0-3.5c0-2.9-3.1-2.9-3.1-2.9l-3,6.4H29.5z M27.1,26.7
                        c0.6,0.2,1.3,0.6,1.3,1.7v2.4h-3.2L27.1,26.7z M21.2,25.5l2.1,4.7l2.1-4.7h-2.1H21.2z M31.2,13.8H23h-7.6c-1.2,0-2.3,1-2.3,2.3
                        v31.7c0,1.2,1,2.3,2.3,2.3h15.8c1.2,0,2.3-1,2.3-2.3V16C33.5,14.8,32.5,13.8,31.2,13.8z M32.3,47.7c0,0.6-0.5,1.1-1.1,1.1H15.4
                        c-0.6,0-1.1-0.5-1.1-1.1v-4h18.1V47.7z M32.3,42.6H14.2V17.7h18.1V42.6z M32.3,16.6H14.2V16c0-0.6,0.5-1.1,1.1-1.1h15.8
                        c0.6,0,1.1,0.5,1.1,1.1V16.6z M23.3,48.2c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9
                        C21.4,47.3,22.3,48.2,23.3,48.2z M23.3,45.6c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8S22.9,45.6,23.3,45.6z
                        M24.1,38.2h-2.2c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h2.2c0.6,0,1.1-0.5,1.1-1.1C25.2,38.7,24.7,38.2,24.1,38.2z
                        M24.1,39.6h-2.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2.2c0.2,0,0.4,0.2,0.4,0.4C24.4,39.5,24.3,39.6,24.1,39.6z
                        M17.4,34.6h11.1c0.2,0,0.4-0.2,0.4-0.4v-1.5c0-0.2-0.2-0.4-0.4-0.4H17.4c-0.2,0-0.4,0.2-0.4,0.4v1.5C17,34.5,17.2,34.6,17.4,34.6
                        z M17.8,33.1h10.4v0.7H17.8V33.1z M17.4,37.5h4.8c0.2,0,0.4-0.2,0.4-0.4v-1.5c0-0.2-0.2-0.4-0.4-0.4h-4.8c-0.2,0-0.4,0.2-0.4,0.4
                        v1.5C17,37.3,17.2,37.5,17.4,37.5z M17.8,36h4.1v0.7h-4.1V36z M28.5,35.3h-4.8c-0.2,0-0.4,0.2-0.4,0.4v1.5c0,0.2,0.2,0.4,0.4,0.4
                        h4.8c0.2,0,0.4-0.2,0.4-0.4v-1.5C28.9,35.4,28.7,35.3,28.5,35.3z M28.2,36.8h-4.1V36h4.1V36.8z M23,2.9c-1.2,0-2.2,1-2.2,2.2
                        c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2C25.2,3.9,24.2,2.9,23,2.9z M23,6.3c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2
                        c0.7,0,1.2,0.5,1.2,1.2C24.2,5.7,23.6,6.3,23,6.3z M26.3,10.1c1.4-2,2.1-3.5,2.1-4.7c0-3-2.4-5.4-5.4-5.4s-5.4,2.4-5.4,5.4
                        c0,1.1,0.7,2.7,2.1,4.7c1.1,1.5,2.3,2.9,2.7,3.4c0.1,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3C24.1,12.8,25.3,11.5,26.3,10.1z
                        M20.5,9.5c-1.6-2.3-1.9-3.5-1.9-4.1C18.6,3,20.5,1,23,1c2.4,0,4.4,2,4.4,4.4c0,0.6-0.3,1.8-1.9,4.1c-0.9,1.3-1.9,2.5-2.5,3.2
                        C22.4,12.1,21.4,10.8,20.5,9.5z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Receive Order',
					text: 'Drivers can receive orders and accept them for deliver to customer location even while processing another order.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M42,3.3c-2.3,0-4.2,1.8-4.2,4.1s1.9,4.1,4.2,4.1c2.3,0,4.2-1.8,4.2-4.1C46.2,5.1,44.3,3.3,42,3.3z M42,9.8
                        c-1.4,0-2.5-1.1-2.5-2.4c0-1.4,1.1-2.4,2.5-2.4c1.4,0,2.5,1.1,2.5,2.4C44.5,8.7,43.4,9.8,42,9.8z M47.5,2c-3.2-2.6-7.9-2.6-11,0
                        c-2.6,2.2-3.2,5.9-1.6,8.8l5.6,10.3c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9,0.3-0.9,0.7c0,0.4,0.3,0.8,0.8,0.9c0.6,0,1.2,0.1,1.8,0.1
                        c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.1-0.2l6.3-11.6
                        C50.7,7.9,50.1,4.3,47.5,2z M47.6,10.1L42,20.3l-5.6-10.2c-1.3-2.3-0.8-5.1,1.2-6.8c2.5-2.1,6.3-2.1,8.8,0
                        C48.4,5,48.9,7.8,47.6,10.1z M8.3,29.4c0-1.4-1.1-2.4-2.5-2.4s-2.5,1.1-2.5,2.4s1.1,2.4,2.5,2.4C7.2,31.9,8.3,30.8,8.3,29.4z
                        M4.9,29.4c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8C5.3,30.2,4.9,29.9,4.9,29.4z M30.4,19.3
                        c0.4,0.3,0.9,0.2,1.2-0.2c0.3-0.4,0.2-0.9-0.2-1.1c0,0,0,0,0,0c-0.9-0.6-1.8-1.3-2.6-2c-0.3-0.3-0.9-0.3-1.2,0.1
                        c-0.3,0.3-0.3,0.8,0.1,1.2C28.5,18,29.4,18.7,30.4,19.3z M8.9,45.5c-0.9-0.5-1.6-1.1-2.2-1.9c-0.2-0.4-0.8-0.5-1.2-0.3
                        c-0.4,0.2-0.5,0.7-0.3,1.1C5.9,45.5,6.9,46.4,8,47C8.4,47.2,9,47,9.2,46.7C9.4,46.3,9.3,45.8,8.9,45.5z M15,47.3
                        c-1.1-0.1-2.2-0.3-3.2-0.6c-0.4-0.1-0.9,0.1-1,0.6c-0.1,0.4,0.1,0.9,0.6,1c1.1,0.3,2.3,0.5,3.5,0.6c0,0,0.1,0,0.1,0
                        c0.5,0,0.8-0.4,0.8-0.8C15.8,47.6,15.5,47.3,15,47.3z M20.7,9.8c0.3,0.3,0.8,0.4,1.2,0.1c0.3-0.3,0.4-0.8,0.1-1.1
                        c-0.8-0.9-1.6-1.7-2.6-2.4c-0.4-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.2,0.9,0.2,1.1C19.3,8.3,20.1,9,20.7,9.8z M12.9,9.2
                        C12.9,9.2,12.9,9.2,12.9,9.2C13.3,9.5,13.8,9.4,14,9l0,0c0.2-0.4,0.5-0.7,0.8-1c0.4-0.4,0.8-0.7,1.3-0.9c0.4-0.2,0.6-0.6,0.5-1.1
                        c-0.2-0.4-0.7-0.6-1.1-0.5c-0.7,0.3-1.3,0.7-1.9,1.3c-0.4,0.4-0.7,0.8-1,1.3C12.3,8.5,12.5,9,12.9,9.2z M18.8,36.8
                        c0.3,0.5,0.6,1,0.9,1.5c0.3,0.5,0.6,0.9,0.9,1.4c0.2,0.4,0.8,0.5,1.2,0.3c0.4-0.2,0.5-0.7,0.3-1.1c-0.3-0.5-0.6-0.9-0.9-1.4
                        c-0.3-0.5-0.6-1-0.9-1.4c-0.2-0.4-0.8-0.5-1.2-0.3C18.7,35.9,18.5,36.4,18.8,36.8z M18.9,18.9C18.9,18.9,18.8,18.9,18.9,18.9
                        c-0.9-0.7-1.7-1.4-2.4-2.2c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2l0,0c0.8,0.8,1.7,1.6,2.6,2.3c0.4,0.3,0.9,0.2,1.2-0.1
                        C19.3,19.7,19.2,19.2,18.9,18.9z M13,15.2c0.2,0.2,0.4,0.4,0.7,0.4c0.5,0,0.8-0.4,0.8-0.8c0-0.2,0-0.3-0.1-0.4
                        c-0.6-0.8-0.9-1.7-1-2.7c0-0.4-0.5-0.8-0.9-0.7c-0.5,0-0.8,0.4-0.8,0.9c0,0,0,0,0,0C11.8,13,12.3,14.1,13,15.2z M23.5,31.5
                        c0.5,0,0.8-0.4,0.8-0.8c0-0.2-0.1-0.5-0.3-0.6c-1-0.8-2.1-1.4-3.3-1.7c-0.5-0.1-0.9,0.2-1,0.6c-0.1,0.4,0.2,0.9,0.6,1c0,0,0,0,0,0
                        c1,0.3,1.9,0.7,2.6,1.4C23.2,31.5,23.3,31.5,23.5,31.5z M18.2,34.2C18.2,34.2,18.2,34.2,18.2,34.2L18.2,34.2
                        c0.4-0.2,0.6-0.7,0.5-1.1c0,0,0,0,0,0c-0.3-0.6-0.5-1.2-0.5-1.9c0-0.2,0-0.4,0.1-0.6c0.2-0.4,0.1-0.9-0.4-1.1
                        c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.9,0.3,1.7,0.6,2.5C17.3,34.2,17.8,34.4,18.2,34.2z M39.1,42.7
                        c-1-0.3-2-0.7-2.8-1.3c-0.4-0.2-0.9-0.1-1.2,0.2c-0.3,0.4-0.1,0.9,0.3,1.1c1,0.7,2.1,1.1,3.3,1.5c0.1,0,0.1,0,0.2,0
                        c0.5,0,0.8-0.4,0.8-0.8C39.8,43.1,39.5,42.7,39.1,42.7z M31.3,37.2c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2
                        c0.9,0.9,1.7,1.7,2.5,2.3c0.3,0.3,0.9,0.3,1.2-0.1c0.3-0.3,0.3-0.9-0.1-1.2C33,38.8,32.2,38.1,31.3,37.2z M36,31L36,31
                        c0.2-0.4,0.1-0.9-0.3-1.1c-0.9-0.6-1.9-1.2-2.9-1.8c-0.4-0.2-0.9-0.1-1.2,0.3c-0.2,0.4-0.1,0.9,0.3,1.1c1,0.6,1.9,1.2,2.9,1.7
                        C35.3,31.5,35.8,31.4,36,31z M41.5,33.6c-0.8-0.6-1.7-1.2-2.8-1.9c-0.4-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.2,0.9,0.2,1.1c0,0,0,0,0,0
                        c1.1,0.7,2,1.3,2.8,1.9c0.4,0.3,0.9,0.2,1.2-0.2C41.9,34.4,41.8,33.9,41.5,33.6z M44.7,41.3L44.7,41.3c-0.2,0.2-0.4,0.3-0.6,0.5
                        c-0.6,0.4-1.3,0.7-2,0.9c-0.5,0.1-0.7,0.6-0.6,1c0.1,0.4,0.6,0.7,1,0.6c0.9-0.2,1.7-0.6,2.5-1.1c0.3-0.2,0.6-0.4,0.8-0.7
                        c0.3-0.3,0.3-0.8,0-1.2C45.5,41,45,41,44.7,41.3z M44.2,35.7c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2c0,0,0,0,0,0
                        c0.8,0.7,1.4,1.4,1.9,2.3c0.2,0.4,0.7,0.6,1.1,0.4c0.4-0.2,0.6-0.7,0.4-1.1C45.9,37.5,45.1,36.5,44.2,35.7z M33.4,21.1
                        c1.1,0.5,2.2,0.9,3.3,1.2c0.1,0,0.1,0,0.2,0c0.5,0,0.8-0.4,0.8-0.8c0-0.4-0.3-0.7-0.6-0.8c-1-0.3-2.1-0.6-3-1.1
                        c-0.4-0.2-0.9,0-1.1,0.4C32.8,20.4,33,20.9,33.4,21.1z M30.2,27.5c0.2-0.4,0.1-0.9-0.3-1.1c-1-0.6-1.9-1.2-2.9-1.8
                        c-0.4-0.2-0.9-0.1-1.2,0.2c-0.3,0.4-0.1,0.9,0.3,1.1c1,0.6,1.9,1.2,2.9,1.8C29.5,28,30,27.9,30.2,27.5z M21.4,46.9
                        c-1,0.3-2,0.5-3.1,0.5c-0.5,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0,0,0,0,0,0c1.2,0,2.5-0.2,3.6-0.6c0.4-0.2,0.6-0.6,0.5-1.1
                        C22.3,46.9,21.8,46.7,21.4,46.9z M24.3,22.8c-1-0.7-1.9-1.3-2.8-1.9c-0.4-0.3-0.9-0.2-1.2,0.2c-0.3,0.4-0.2,0.9,0.2,1.1
                        c0.9,0.6,1.8,1.2,2.8,1.9c0.4,0.3,0.9,0.2,1.2-0.2C24.7,23.6,24.6,23,24.3,22.8z M22.5,41.3C22.5,41.3,22.5,41.3,22.5,41.3
                        c-0.4,0.2-0.6,0.7-0.4,1.1l0,0c0.4,0.8,0.7,1.7,0.8,2.6c0,0.1,0,0.1,0,0.2c0,0.4,0.3,0.8,0.8,0.9c0,0,0,0,0,0c0,0,0,0,0.1,0
                        c0.4,0,0.8-0.3,0.8-0.8c0-0.1,0-0.2,0-0.3c-0.1-1.1-0.4-2.3-0.9-3.3C23.5,41.3,23,41.1,22.5,41.3z M26.6,32.4
                        c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.8,0,1.2c0,0,0,0,0,0c0.8,0.7,1.5,1.6,2.3,2.4c0.3,0.3,0.8,0.4,1.2,0
                        c0.3-0.3,0.4-0.8,0-1.2C28.2,34,27.4,33.2,26.6,32.4z M26.4,14.9c0.3-0.3,0.4-0.8,0.1-1.2l0,0c-0.8-0.9-1.6-1.7-2.2-2.5
                        c-0.3-0.3-0.8-0.4-1.2-0.1c-0.4,0.3-0.4,0.8-0.1,1.1c0.6,0.8,1.4,1.6,2.3,2.5C25.5,15.2,26,15.2,26.4,14.9z M5.4,41.6
                        c0.4,0,0.8-0.3,0.8-0.7c0.1-0.5,0.2-0.9,0.4-1.4l4.4-8c1.2-2.1,0.7-4.8-1.1-6.4c-2.3-1.9-5.7-1.9-8,0c-1.9,1.6-2.3,4.3-1.1,6.4
                        l4.2,7.7c-0.2,0.5-0.3,0.9-0.4,1.4c-0.1,0.4,0.3,0.9,0.7,0.9C5.3,41.6,5.3,41.6,5.4,41.6z M2.1,30.8c-0.8-1.5-0.5-3.3,0.8-4.4
                        C4.6,25,7,25,8.7,26.4c1.3,1.1,1.6,3,0.8,4.4l-3.6,6.7L2.1,30.8z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Receive Delivery Location',
					text: 'The order will contain accurate location details of the customer that will help the driver locate them faster.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g transform="translate(-510 -141)">
                      <g>
                        <path class="st0" d="M551.6,162.6v1.7h0.4l0.8,3.2c-0.4,0.1-0.8,0.2-1.2,0.3v1.7c0.5-0.1,1.1-0.3,1.6-0.4l1,3.9
                        c-3,2.7-7.2,7.3-9.8,14.6h-12c-1.5-2.1-3.4-3.8-5.5-5c1.7-1.7,3.8-3.6,6.4-5.3v-2.1c-3.4,2.1-6,4.4-8.1,6.5c-4-2.1-8.2-2.8-10.9-3
                        l1.1-4.2c1.8-0.4,3.3-1.1,4.6-1.8v-1.9c-1.2,0.7-2.5,1.3-4.1,1.9l2.1-8.3h2.1v-1.7h-3.3l-6.7,26.7h50l-6.7-26.7L551.6,162.6
                        L551.6,162.6z M512.1,187.5l1.9-7.3c2.4,0.3,6.4,0.9,10.1,2.7c-1.5,1.8-2.6,3.3-3.4,4.7H512.1L512.1,187.5z M522.7,187.5
                        c0.7-1.1,1.7-2.4,3-3.8c1.7,1,3.3,2.2,4.6,3.8H522.7z M557.9,187.5h-11.7c2.4-6,5.8-10.2,8.5-12.7L557.9,187.5z"></path>
                        <path class="st0" d="M525.1,164.3c-0.1,0.1-0.1,0.3-0.2,0.4v3.4c0.9-1.3,1.5-2.7,1.9-3.8h2.8c-0.4-0.5-0.6-1.1-0.8-1.7H525
                        L525.1,164.3L525.1,164.3z"></path>
                        <path class="st0" d="M546.6,169.1c-3.1,1-5.9,2.1-8.3,3.3v1.9c2.4-1.3,5.2-2.4,8.3-3.4V169.1z"></path>
                        <path class="st0" d="M546.6,164.3v-1.7h-3.8c-0.2,0.6-0.4,1.2-0.8,1.7H546.6z"></path>
                        <path class="st0" d="M535,165.8v18.4h1.7v-18.4c2.9-0.4,5-2.8,5-5.7c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8
                        C529.9,163,532.1,165.4,535,165.8z M531.6,160.1c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2
                        C533.5,164.3,531.6,162.4,531.6,160.1z"></path>
                        <path class="st0" d="M548.3,172.6h1.7v-18.4c2.9-0.4,5-2.8,5-5.7c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8c0,3,2.1,5.4,5,5.7
                        L548.3,172.6L548.3,172.6z M545,148.4c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2
                        C546.9,152.5,545,150.7,545,148.4z"></path>
                        <path class="st0" d="M521.6,174.2h1.7v-18.4c0.6-0.1,1.2-0.3,1.7-0.5c2-0.9,3.3-3,3.3-5.3c0-3.2-2.6-5.8-5.8-5.8s-5.8,2.6-5.8,5.8
                        c0,2.3,1.3,4.3,3.3,5.3c0.5,0.3,1.1,0.4,1.7,0.5L521.6,174.2L521.6,174.2z M518.3,150c0-2.3,1.9-4.2,4.2-4.2
                        c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2S518.3,152.4,518.3,150z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Receive Multiple Deliveries',
					text: 'Real-time location based order matching ensures that drivers can be assigned multiple deliveries at the same time.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M45.8,15H35V4.2C35,1.9,33.1,0,30.8,0H4.2C1.9,0,0,1.9,0,4.2v23.3c0,2.3,1.9,4.2,4.2,4.2h7.3L23.7,39
                        c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.6,0.2-1l-2.8-6.3h4.6v14.2c0,2.3,1.9,4.2,4.2,4.2h15
                        c2.3,0,4.2-1.9,4.2-4.2V19.2C50,16.9,48.1,15,45.8,15z M33.6,30.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3
                        c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0-0.2
                        c0-0.2,0.1-0.4,0.1-0.6c1-0.7,2.1-1.1,3.3-1.1c3.2,0,5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8
                        c0-0.4,0-0.8,0.1-1.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.3,0.7-0.4C33.5,30.7,33.6,30.6,33.6,30.6z M20.8,30c-0.3,0-0.5,0.1-0.7,0.4
                        c-0.2,0.2-0.2,0.5-0.1,0.8l2.3,5.1l-10.2-6.1C12,30,11.8,30,11.7,30H4.2c-1.4,0-2.5-1.1-2.5-2.5V4.2c0-1.4,1.1-2.5,2.5-2.5h26.7
                        c1.4,0,2.5,1.1,2.5,2.5v23.3c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
                        c0,0.1-0.1,0.1-0.1,0.1c-0.3,0.5-0.8,0.9-1.3,1.1c0,0,0,0,0,0c-0.3,0.1-0.5,0.1-0.8,0.1H20.8z M48.3,45.8c0,1.4-1.1,2.5-2.5,2.5
                        h-15c-1.4,0-2.5-1.1-2.5-2.5V45h20V45.8z M48.3,43.3h-20V31.7h2.5c0,0,0,0,0.1,0c0,0.3-0.1,0.6-0.1,0.8c0,4.1,3.4,7.5,7.5,7.5
                        c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5c-1.2,0-2.3,0.3-3.3,0.8v-4.1h13.3V43.3z M48.3,20H35v-3.3h10.8
                        c1.4,0,2.5,1.1,2.5,2.5V20z M19.2,5h-3.3c-4.6,0-8.3,3.7-8.3,8.3c0,0.5,0.4,0.8,0.8,0.8h18.3c0.5,0,0.8-0.4,0.8-0.8
                        C27.5,8.7,23.8,5,19.2,5z M9.2,12.5c0.4-3.3,3.2-5.8,6.6-5.8h3.3c3.4,0,6.2,2.5,6.6,5.8H9.2z M26.7,22.5H8.3
                        c-0.5,0-0.8,0.4-0.8,0.8c0,2.3,1.9,4.2,4.2,4.2h11.7c2.3,0,4.2-1.9,4.2-4.2C27.5,22.9,27.1,22.5,26.7,22.5z M23.3,25.8H11.7
                        c-1.1,0-2-0.7-2.4-1.7h16.4C25.3,25.1,24.4,25.8,23.3,25.8z M24.2,20c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1
                        c-1.2,0-1.8,0.6-2.3,1.1C18.2,19.8,18,20,17.5,20c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1c-1.2,0-1.8,0.6-2.3,1.1
                        c-0.4,0.4-0.6,0.6-1.1,0.6c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1V20c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1
                        c1.2,0,1.8-0.6,2.3-1.1c0.4-0.4,0.6-0.6,1.1-0.6c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1c1.2,0,1.8-0.6,2.3-1.1
                        c0.4-0.4,0.6-0.6,1.1-0.6c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1c1.2,0,1.8-0.6,2.3-1.1c0.4-0.4,0.6-0.6,1.1-0.6v-1.7
                        c-1.2,0-1.8,0.6-2.3,1.1C24.8,19.8,24.6,20,24.2,20z M7.5,17.5h20v-1.7h-20V17.5z M13.3,10.8H15V9.2h-1.7V10.8z M16.7,10.8h1.7
                        V9.2h-1.7V10.8z M20,10.8h1.7V9.2H20V10.8z M37.5,35c0.2,0,0.4-0.1,0.6-0.2l4.2-4.2l-1.2-1.2L37.5,33l-1.1-1.1l-1.2,1.2l1.7,1.7
                        C37.1,34.9,37.3,35,37.5,35z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Manage Orders',
					text: 'Depending on the ETA of each order, drivers can prioritise the delivery for each order.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M47,48.8c0-0.1,0-0.2-0.1-0.3l0,0c0,0,0,0,0,0L40.6,35c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
                        c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0h-9.2
                        c3.6-7,8.1-16.8,8.1-21.1C38.5,6,32.6,0,25.3,0S12.2,6,12.2,13.3c0,4.3,4.5,14.1,8.1,21.1H11c-0.1,0-0.1,0-0.2,0
                        c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.3-0.3,0.5L3.8,48.5c0,0,0,0,0,0l0,0
                        c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
                        c0,0,0,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0H46c0,0,0,0,0,0
                        c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1
                        c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0C47,49.2,47,49,47,48.8C47,48.9,47,48.9,47,48.8z M37.6,36.5l-5,6.7
                        l-5.7-2c0.6-1,1.4-2.7,2.5-4.7H37.6z M14.2,13.3c0-6.2,5-11.2,11.1-11.2c6.1,0,11.1,5,11.1,11.2c0,4.4-5.5,15.6-9,22.4
                        c0,0,0,0,0,0c-0.8,1.6-1.5,2.9-2,3.9c-0.5-0.9-1.2-2.3-2-3.8c0-0.1-0.1-0.1-0.1-0.2C19.7,28.8,14.2,17.7,14.2,13.3z M21.3,36.5
                        c1.1,2,1.9,3.7,2.5,4.7l-5.8,2l-5-6.7H21.3z M11.2,37.5l4.8,6.4l-9.4,3.3L11.2,37.5z M10.9,47.9l14.5-5l14.5,5H10.9z M34.6,43.9
                        l4.8-6.4l4.6,9.7L34.6,43.9z M33,13.3c0-4.3-3.4-7.8-7.7-7.8c-4.2,0-7.7,3.5-7.7,7.8c0,4.3,3.4,7.8,7.7,7.8
                        C29.6,21.1,33,17.6,33,13.3z M19.7,13.3c0-3.1,2.5-5.7,5.6-5.7c3.1,0,5.6,2.6,5.6,5.7c0,3.1-2.5,5.7-5.6,5.7
                        C22.2,19,19.7,16.4,19.7,13.3z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Realtime Tracking',
					text: 'Track the time elapsed for each order on a real-time basis to ensure that orders are delivered without delays.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M48.8,33.2V2.5h-1.6v0l-10.4,8.3l-9.1-4.9C27.3,2.6,24.5,0,21.1,0c-3.6,0-6.6,3-6.6,6.7c0,0.4,0.1,1,0.2,1.6
                        l-3.5,2.2v14.4H3.7v4.3v1.6v2.7v1.6v2.7H1.6v-6H0v6v7.6h3.5c0.4,1.9,2.1,3.3,4,3.3c2,0,3.6-1.4,4-3.3h21.8v0h2.4
                        c0.3,2,2,3.5,4.1,3.5c2,0,3.7-1.5,4.1-3.5h6v-8.2h0C50,35.7,49.6,34.4,48.8,33.2z M37.7,12.2l9.5-7.5v12c-0.4,0.3-0.8,0.5-1.1,0.8
                        l0.9,1.4c0.1-0.1,0.2-0.1,0.3-0.2v12.6L45,29c-1.3-1.3-3-2-5.3-2h-2.1V12.2z M21.1,1.6c2.8,0,5,2.3,5,5.1c0,3-3.8,9.8-5,10.2
                        c-1.2-0.4-5-7.2-5-10.2C16.1,3.9,18.4,1.6,21.1,1.6z M14.3,33.6v-2.7h7.4v2.7H14.3z M21.8,35.2v2.7h-7.4v-2.7H21.8z M12.8,11.4
                        l2.4-1.5c1.1,3.2,3.2,7.1,5,8.3v11h-5.8v-4.3h-1.5V11.4z M5.3,26.5h7.4v2.7H5.3V26.5z M5.3,30.9h7.4v2.7H5.3V30.9z M5.3,35.2h7.4
                        v2.7H5.3V35.2z M10.1,44.5c0,1.4-1.1,2.6-2.5,2.6c-1.4,0-2.5-1.2-2.5-2.6c0-0.7,0.3-1.3,0.7-1.8c0.1-0.1,0.1-0.1,0.2-0.2
                        c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0.1,0,0.1,0C6.8,42.1,7,42,7.1,42c0.2,0,0.3,0,0.5,0
                        c0,0,0,0,0,0C9,42,10.1,43.1,10.1,44.5L10.1,44.5z M33.4,41.2H12.2v1.6h21.2v1.1H11.6v0c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2
                        c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
                        c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
                        c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
                        c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1
                        c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0-0.8,0.1-1.2,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1
                        c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.2,0.2
                        c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c-0.6,0.6-1,1.4-1.2,2.2H1.6v-1.1h1.7v-1.6H1.6v-1.6h2.1h9h1.6h7.4h1.6h9h1.1V41.2z
                        M23.4,37.9v-2.7h7.4v2.7H23.4z M33.4,37.9h-1.1v-4.3h-9v-4.3h-1.6V18.4c2.3-1.1,5.3-7.3,5.9-10.7l8.5,4.6v9.6
                        C35.6,22,35.2,22,34.8,22l0.1,1.6c0.4,0,0.8-0.1,1.3-0.1v3.5h-2.7V37.9z M39.9,47.4c-1.4,0-2.5-1.2-2.5-2.6c0-0.2,0-0.3,0-0.5
                        c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0.4-0.9,1.3-1.5,2.3-1.5c1.4,0,2.5,1.2,2.5,2.6
                        C42.5,46.2,41.3,47.4,39.9,47.4z M48.4,41.2h-3.3v1.6h3.3v1.1h-4.3c-0.4-1.9-2-3.4-4-3.4c-1.5,0-2.8,0.8-3.5,2.1
                        c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.4c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1
                        c0,0.1,0,0.1-0.1,0.2H35v-6v-9.2h4.8c1.9,0,3.1,0.5,4.1,1.5l2.7,2.8c0.5,0.5,0.9,1,1.1,1.6h-8.5v-4.9h-1.6v6.5h10.6
                        c0,0.3,0.1,0.6,0.1,1V41.2z M37.7,39h2.6v-1.6h-2.6V39z M24.4,6.7c0-1.8-1.5-3.4-3.3-3.4c-1.8,0-3.3,1.5-3.3,3.4
                        c0,1.8,1.5,3.4,3.3,3.4C22.9,10.1,24.4,8.6,24.4,6.7z M19.4,6.7c0-1,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7
                        C20.2,8.5,19.4,7.7,19.4,6.7z M31.5,23.4c0.5,0.1,1.1,0.2,1.7,0.3l0.1-1.6c-0.5,0-1-0.1-1.5-0.2L31.5,23.4z M28.3,22.2
                        c0.5,0.3,1,0.5,1.5,0.7l0.6-1.5c-0.5-0.2-0.9-0.4-1.4-0.6L28.3,22.2z M24.1,17l-1,1.2c0.2,0.2,0.4,0.4,0.6,0.5l0.6,0.5l1-1.2
                        l-0.6-0.5C24.5,17.3,24.3,17.2,24.1,17z M25.5,20.3c0.5,0.4,0.9,0.7,1.3,1l0.9-1.4c-0.4-0.3-0.8-0.6-1.2-0.9L25.5,20.3z
                        M45.6,19.7l-0.8-1.4c-0.5,0.3-0.9,0.6-1.3,0.8l0.8,1.4C44.6,20.3,45.1,20,45.6,19.7z M37.7,21.6l0.4,1.6c0.5-0.1,1-0.3,1.6-0.5
                        l-0.5-1.5C38.7,21.3,38.2,21.4,37.7,21.6z M42.7,21.3L42,19.9c-0.5,0.2-0.9,0.5-1.4,0.7l0.6,1.5C41.7,21.8,42.2,21.6,42.7,21.3z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Delivery Status',
					text: 'Multiple options to update delivery status as completed, in progress, abandoned, or customer unavailable.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M1.8,38.3V9.4c0-4.2,3.4-7.5,7.5-7.5h28.8c4.1,0,7.5,3.4,7.5,7.5v13.1h1.8V9.4c0-5.2-4.2-9.4-9.3-9.4H9.3
                        C4.2,0,0,4.2,0,9.4v28.9c0,5.2,4.2,9.4,9.3,9.4h12.9v-1.8H9.3C5.2,45.8,1.8,42.4,1.8,38.3z M8.5,19.1h9.9v-1.8H8.5V19.1z M38.9,9
                        H8.5v1.8h30.5V9z M48.6,47.1L48.6,47.1l-6-6.1c1.8-2.2,2.8-4.9,2.9-7.6c0.2-3.4-1.1-6.8-3.4-9.3c-2-2.1-4.7-3.6-7.6-3.9
                        c-0.6-0.1-1.1-0.1-1.7-0.1c-2.8,0-5.6,0.9-7.7,2.7c-2.5,1.9-4.2,4.8-4.8,8c-0.5,2.9,0,6,1.5,8.6c1,1.8,2.4,3.3,4.1,4.4
                        c1.6,1,3.3,1.7,5.2,1.9c0.5,0.1,1.1,0.1,1.7,0.1c2.6,0,5.2-0.8,7.3-2.3l6,6l0.2,0.2l0,0c0.3,0.2,0.7,0.4,1.1,0.4
                        c0.9,0,1.7-0.8,1.7-1.7C49,47.8,48.8,47.4,48.6,47.1z M34.6,43.6c-1.6,0.3-3.2,0.2-4.7-0.2c-3-0.8-5.5-3-6.8-5.8
                        c-1.5-3.2-1.3-7.1,0.5-10.1c1.6-2.8,4.4-4.7,7.5-5.2l0.2,0c0.1,0,0.2,0,0.3-0.1c0.4,0,0.8-0.1,1.2-0.1c1.6,0,3.1,0.3,4.5,1
                        c2.5,1.2,4.5,3.3,5.5,6c1.1,2.8,0.9,6.1-0.4,8.7C40.9,40.8,37.9,43,34.6,43.6z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'View Order History',
					text: 'A comprehensive view of the number of order delivered, cancelled, with timestamps.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M47.5,26.7H16.7c-1.4,0-2.5,1.1-2.5,2.5v18.3c0,1.4,1.1,2.5,2.5,2.5h30.8c1.4,0,2.5-1.1,2.5-2.5V29.2
                    C50,27.8,48.9,26.7,47.5,26.7z M48.3,47.5c0,0.5-0.4,0.8-0.8,0.8H16.7c-0.5,0-0.8-0.4-0.8-0.8V29.2c0-0.5,0.4-0.8,0.8-0.8h30.8
                    c0.5,0,0.8,0.4,0.8,0.8V47.5z"></path>
                    <path class="st0" d="M9.2,25h34.2v-3.3c0-1.4-1.1-2.5-2.5-2.5H10c-1.4,0-2.5,1.1-2.5,2.5V40c0,1.4,1.1,2.5,2.5,2.5h2.5v-1.7H10
                    c-0.5,0-0.8-0.4-0.8-0.8v-7.5h3.3v-1.7H9.2V25z M10,20.8h30.8c0.5,0,0.8,0.4,0.8,0.8v1.7H9.2v-1.7C9.2,21.2,9.5,20.8,10,20.8z"></path>
                    <path class="st0" d="M44.2,30h-5c-1.4,0-2.5,1.1-2.5,2.5v5c0,1.4,1.1,2.5,2.5,2.5h5c1.4,0,2.5-1.1,2.5-2.5v-5
                    C46.7,31.1,45.5,30,44.2,30z M38.3,37.5v-5c0-0.5,0.4-0.8,0.8-0.8H40v1.7h1.7v-1.7h2.5c0.5,0,0.8,0.4,0.8,0.8V35h-3.3
                    c-0.9,0-1.7,0.7-1.7,1.7v1.7h-0.8C38.7,38.3,38.3,38,38.3,37.5z M44.2,38.3h-2.5v-1.7H45v0.8C45,38,44.6,38.3,44.2,38.3z"></path>
                    <path class="st0" d="M33.3,32.5H35v1.7h-1.7V32.5z"></path>
                    <path class="st0" d="M20.8,32.5h10.8v1.7H20.8V32.5z"></path>
                    <path class="st0" d="M17.5,32.5h1.7v1.7h-1.7V32.5z"></path>
                    <path class="st0" d="M33.3,35.8H35v1.7h-1.7V35.8z"></path>
                    <path class="st0" d="M20.8,35.8h10.8v1.7H20.8V35.8z"></path>
                    <path class="st0" d="M17.5,35.8h1.7v1.7h-1.7V35.8z"></path>
                    <path class="st0" d="M29.2,40h-2.5c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C30.8,40.7,30.1,40,29.2,40z M29.2,44.2h-2.5v-2.5h2.5V44.2z"></path>
                    <path class="st0" d="M21.7,40h-2.5c-0.9,0-1.7,0.7-1.7,1.7v2.5c0,0.9,0.7,1.7,1.7,1.7h2.5c0.9,0,1.7-0.7,1.7-1.7v-2.5
                    C23.3,40.7,22.6,40,21.7,40z M21.7,44.2h-2.5v-2.5h2.5V44.2z"></path>
                    <path class="st0" d="M5,25.8v-8.3h6.7c1.4,0,2.5-1.1,2.5-2.5v-5c0-1.4-1.1-2.5-2.5-2.5H5v-5C5,2,5.4,1.7,5.8,1.7H45
                    c0.5,0,0.8,0.4,0.8,0.8V25h1.7V2.5C47.5,1.1,46.4,0,45,0H5.8C4.5,0,3.3,1.1,3.3,2.5v5H2.5C1.1,7.5,0,8.6,0,10v8.3
                    c0,1.4,1.1,2.5,2.5,2.5h0.8v5c0,1.4,1.1,2.5,2.5,2.5v-1.7C5.4,26.7,5,26.3,5,25.8z M1.7,10c0-0.5,0.4-0.8,0.8-0.8h9.2
                    c0.5,0,0.8,0.4,0.8,0.8v5c0,0.5-0.4,0.8-0.8,0.8h-10V10z M2.5,19.2c-0.5,0-0.8-0.4-0.8-0.8v-0.8h1.7v1.7H2.5z"></path>
                    <path class="st0" d="M10.8,12.5c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7S10.8,11.6,10.8,12.5z"></path>
                    <path class="st0" d="M44.2,5.8h-1.7c0-0.9-0.7-1.7-1.7-1.7h-1.7c-0.9,0-1.7,0.7-1.7,1.7h-5.8c0-0.9-0.7-1.7-1.7-1.7h-1.7
                    c-0.9,0-1.7,0.7-1.7,1.7v1.3l-4.8,4.1c-0.3-0.2-0.7-0.4-1.1-0.4h-1.7c-0.9,0-1.7,0.7-1.7,1.7h-1.7v1.7h1.7c0,0.9,0.7,1.7,1.7,1.7
                    h1.7c0.9,0,1.7-0.7,1.7-1.7v-1.3l4.8-4.1c0.3,0.2,0.7,0.4,1.1,0.4H30c0.9,0,1.7-0.7,1.7-1.7h5.8c0,0.9,0.7,1.7,1.7,1.7h1.7
                    c0.9,0,1.7-0.7,1.7-1.7h1.7V5.8z M19.2,14.2v-1.7h1.7l0,1.7H19.2z M28.3,7.5V5.8H30l0,1.7H28.3z M39.2,7.5V5.8h1.7l0,1.7H39.2z"></path>
                    <path class="st0" d="M25.8,14.2h1.7v1.7h-1.7V14.2z"></path>
                    <path class="st0" d="M29.2,14.2h11.7v1.7H29.2V14.2z"></path>
                    <path class="st0" d="M42.5,14.2h1.7v1.7h-1.7V14.2z"></path>
                    <path class="st0" d="M27.5,10.8h1.7v1.7h-1.7V10.8z"></path>
                    <path class="st0" d="M30.8,10.8h10v1.7h-10V10.8z"></path>
                    <path class="st0" d="M42.5,10.8h1.7v1.7h-1.7V10.8z"></path>
                  </svg>`,
					title: 'Payment History',
					text: 'Drivers can keep a track of their total money collected and their individuals earnings from completed deliveries.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M43.3,36.9c-1.1-1-1.7-2.3-1.7-3.8v-9.7c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1v9.7c0,2,0.8,3.9,2.3,5.2
                        c0.5,0.5,0.8,1.2,0.8,1.9c0,1.4-1.1,2.5-2.5,2.5H9.8c-1.4,0-2.5-1.1-2.5-2.5c0-0.7,0.3-1.4,0.8-1.9c1.5-1.3,2.3-3.2,2.3-5.2V21.9
                        c0-8.1,6.6-14.6,14.6-14.6c2.2,0,4.4,0.5,6.4,1.5c-0.7,1.1-1.1,2.4-1.1,3.8c0,4,3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2s-3.2-7.2-7.2-7.2
                        c-1.9,0-3.6,0.7-4.9,1.9c-1.5-0.8-3-1.3-4.6-1.6c0.4-0.6,0.6-1.2,0.6-1.9C28.6,1.6,27,0,25,0s-3.6,1.6-3.6,3.6
                        c0,0.7,0.2,1.4,0.6,1.9C14.2,7,8.4,13.8,8.4,21.9v11.3c0,1.4-0.6,2.8-1.7,3.8c-0.9,0.8-1.5,2.1-1.5,3.3c0,2.5,2,4.5,4.5,4.5h9.1
                        c0.5,3,3.1,5.3,6.2,5.3s5.7-2.3,6.2-5.3h9.1c2.5,0,4.5-2,4.5-4.5C44.7,39,44.2,37.8,43.3,36.9z M37.5,7.2c2.9,0,5.3,2.4,5.3,5.3
                        s-2.4,5.3-5.3,5.3c-2.9,0-5.3-2.4-5.3-5.3S34.6,7.2,37.5,7.2z M25,2c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
                        c-0.9,0-1.7-0.7-1.7-1.7C23.3,2.7,24.1,2,25,2z M25,48c-2,0-3.7-1.4-4.2-3.3h8.4C28.7,46.6,27,48,25,48z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M14,20.9c-0.5,0-1,0.4-1,1v0c0,0.5,0.4,1,1,1s1-0.4,1-1v0C15,21.3,14.6,20.9,14,20.9z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M25,10c-4.8,0-9.1,2.9-11,7.3c-0.2,0.5,0,1.1,0.5,1.3c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.7-0.2,0.9-0.6
                        c1.6-3.7,5.2-6.1,9.2-6.1c0.5,0,1-0.4,1-1C26,10.4,25.5,10,25,10z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M37.9,9.3c-0.4-0.2-0.8-0.1-1.1,0.2l-1.1,1.1c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.8,0.3v1.8
                        c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-3.9C38.5,9.8,38.2,9.5,37.9,9.3z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Notifications',
					text: 'Push notifications for new deliveries, canceled deliveries, change in delivery locations, and much more.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M15,35c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3
                        C15.5,36,15.4,35.4,15,35L15,35z M13.7,14.2c-5,5-6,12.8-2.5,18.9c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3
                        c-3.1-5.4-2.2-12.2,2.2-16.6c5.4-5.4,14.1-5.4,19.4,0c5.4,5.4,5.4,14.1,0,19.4c-4.4,4.4-11.2,5.3-16.6,2.2
                        c-0.5-0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c2.5,1.4,5.2,2.1,7.9,2.1c4.1,0,8.1-1.6,11.1-4.6c6.1-6.1,6.1-16,0-22.2
                        C29.7,8.1,19.8,8.1,13.7,14.2z M49.5,21c0-0.5-0.3-0.9-0.8-1l-3.6-0.6l-1.9-4.6l2.1-2.9c0.3-0.4,0.2-0.9-0.1-1.3l-6-6
                        c-0.3-0.3-0.9-0.4-1.3-0.1l-2.9,2.1l-4.6-1.9l-0.6-3.6c-0.1-0.5-0.5-0.8-1-0.8h0l-8.5,0c-0.5,0-0.9,0.3-1,0.8L19,4.9l-4.6,1.9
                        l-2.9-2.1c-0.4-0.3-0.9-0.2-1.3,0.1l-6,6c-0.3,0.3-0.4,0.9-0.1,1.3l2.1,2.9l-1.9,4.6l-3.6,0.6C0.3,20.1,0,20.5,0,21l0,8.5
                        c0,0.5,0.3,0.9,0.8,1L4.4,31l1.9,4.6l-2.1,2.9c-0.3,0.4-0.2,0.9,0.1,1.3l6,6c0.3,0.3,0.9,0.4,1.3,0.1l2.9-2.1l4.6,1.9l0.6,3.6
                        c0.1,0.5,0.5,0.8,1,0.8h0l8.5,0c0.5,0,0.9-0.3,1-0.8l0.6-3.6l4.6-1.9l2.9,2.1c0.4,0.3,0.9,0.2,1.3-0.1l6-6
                        c0.3-0.3,0.4-0.9,0.1-1.3l-2.1-2.9l1.9-4.6l3.6-0.6c0.5-0.1,0.8-0.5,0.8-1L49.5,21z M44.3,29.2c-0.3,0.1-0.6,0.3-0.7,0.6l-2.3,5.6
                        c-0.1,0.3-0.1,0.7,0.1,0.9l2,2.7l-4.8,4.8l-2.7-2c-0.3-0.2-0.6-0.2-0.9-0.1L29.3,44c-0.3,0.1-0.5,0.4-0.6,0.7l-0.5,3.3l-6.8,0
                        l-0.5-3.3c-0.1-0.3-0.3-0.6-0.6-0.7l-5.6-2.3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2l-2.7,2L6.2,39l2-2.7
                        c0.2-0.3,0.2-0.6,0.1-0.9L6,29.8c-0.1-0.3-0.4-0.5-0.7-0.6l-3.3-0.5l0-6.8l3.3-0.5C5.6,21.3,5.8,21,6,20.7l2.3-5.6
                        c0.1-0.3,0.1-0.7-0.1-0.9l-2-2.7L11,6.7l2.7,2c0.3,0.2,0.6,0.2,0.9,0.1l5.6-2.3c0.3-0.1,0.5-0.4,0.6-0.7l0.5-3.3l6.8,0l0.5,3.3
                        c0.1,0.3,0.3,0.6,0.6,0.7l5.6,2.3c0.3,0.1,0.7,0.1,0.9-0.1l2.7-2l4.8,4.8l-2,2.7c-0.2,0.3-0.2,0.6-0.1,0.9l2.3,5.6
                        c0.1,0.3,0.4,0.5,0.7,0.6l3.3,0.5l0,6.8L44.3,29.2z M20.4,25.4l4.2,4.2c-0.3,1.7,0.3,3.4,1.5,4.6c1,1,2.4,1.6,3.8,1.6
                        c0.7,0,1.4-0.1,2-0.4c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.3-0.9l-1.4-1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
                        l1.4,1.4c0.2,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6c0.8-2.1,0.3-4.5-1.4-6c-1.2-1.1-2.8-1.6-4.4-1.3l-4.2-4.2
                        c0.3-1.7-0.3-3.4-1.5-4.6c-1.5-1.5-3.8-2-5.8-1.2c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.3,0.9l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
                        c-0.3,0.3-0.8,0.3-1.1,0l-1.4-1.4c-0.2-0.2-0.6-0.3-0.9-0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.8,2.1-0.3,4.5,1.4,6
                        C17.2,25.1,18.8,25.6,20.4,25.4z M17.2,22.6c-0.6-0.6-1-1.4-1.1-2.2l0.1,0.1c1.1,1.1,2.8,1.1,3.8,0c1.1-1.1,1.1-2.8,0-3.8l0,0
                        c0.8,0.1,1.5,0.4,2,1c0.9,0.9,1.2,2.1,0.9,3.3c-0.1,0.3,0,0.7,0.3,0.9l5,5c0.2,0.2,0.6,0.3,0.9,0.3c1.1-0.3,2.3,0,3.2,0.8
                        c0.6,0.6,1,1.3,1.1,2.2l0,0c-1.1-1.1-2.8-1.1-3.8,0c-1.1,1.1-1.1,2.8,0,3.8l0,0c-0.8-0.1-1.5-0.4-2.1-1c-0.9-0.9-1.2-2.1-0.9-3.3
                        c0.1-0.3,0-0.7-0.3-0.9l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.2,0-0.2,0C19.3,23.7,18.1,23.4,17.2,22.6z M27.2,14.3
                        c-0.5-0.1-1,0.2-1.2,0.7c-0.1,0.5,0.2,1,0.7,1.2c2.1,0.5,3.9,1.7,5.3,3.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
                        c0.4-0.3,0.5-0.9,0.2-1.4C32,16.3,29.7,14.9,27.2,14.3z M33.6,22.4c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
                        c0.5-0.2,0.8-0.7,0.6-1.2l0,0c-0.2-0.5-0.7-0.8-1.2-0.6C33.7,21.3,33.4,21.9,33.6,22.4L33.6,22.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Help &amp; Support',
					text: 'In-app support portal as well as emergency support that can help drivers reach out for immediate assistance.'
				}
			],
			items3: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M1.8,10.7h8.3c0.5,0,0.8-0.3,0.8-0.8V1.7h27.4v4.1H40V0.9c0-0.5-0.3-0.8-0.8-0.8H10.1l0,0c-0.1,0-0.2,0-0.2,0.1
                    H9.8C9.7,0.3,9.6,0.3,9.5,0.4l0,0l-9.1,9l0,0C0.3,9.4,0.3,9.5,0.2,9.6v0.1c0,0.1-0.1,0.2-0.1,0.2l0,0v38.3c0,0.5,0.3,0.8,0.8,0.8
                    h24.1v-1.6H1.8V10.7L1.8,10.7z M2.9,9.1L6.1,6l3.2-3.1v6.2H2.9z"></path>
                    <path class="st0" d="M44.2,32.5h-8.3c-3.2,0-5.8,2.6-5.8,5.8V50h1.7V38.4c0-2.3,1.8-4.2,4.2-4.2h1.1l2.3,6.2
                    c0.1,0.2,0.4,0.5,0.7,0.5c0.3,0,0.7-0.2,0.9-0.5l2.3-6.2h0.9c2.3,0,4.2,1.8,4.2,4.2V50H50V38.4C50,35.1,47.4,32.5,44.2,32.5z
                    M40.1,37.7l-1.3-3.5h2.7L40.1,37.7z"></path>
                    <path class="st0" d="M39.2,42.5h1.7v1.7h-1.7V42.5z"></path>
                    <path class="st0" d="M39.2,31.7h1.7c2.3,0,4.2-1.8,4.2-4.2v-2.5c0-2.3-1.8-4.2-4.2-4.2h-1.7c-2.3,0-4.2,1.8-4.2,4.2v2.5
                    C35,29.9,36.9,31.7,39.2,31.7z M36.7,25.1c0-1.4,1.1-2.5,2.5-2.5h1.7c1.4,0,2.5,1.1,2.5,2.5v2.5c0,1.4-1.1,2.5-2.5,2.5h-1.7
                    c-1.4,0-2.5-1.1-2.5-2.5V25.1z"></path>
                    <path class="st0" d="M34.2,40.9h1.7V50h-1.7V40.9z"></path>
                    <path class="st0" d="M44.2,40.9h1.7V50h-1.7V40.9z"></path>
                    <path class="st0" d="M49.2,11.7h-2.5V9.3c0-0.5-0.3-0.8-0.8-0.8H28.4v1.7H45v1.7h-2.5H19.2c-0.2,0-0.3,0.1-0.4,0.1l-6.6,2.5
                    c-0.2,0.1-0.5,0.4-0.5,0.7c0,0.3,0.2,0.7,0.5,0.7l6.6,2.5c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.2,0h23.3h6.7c0.5,0,0.8-0.3,0.8-0.8
                    v-5C50,12.1,49.7,11.7,49.2,11.7z M18.4,13.8v2.6l-3.5-1.3L18.4,13.8z M20.1,13.4h21.6v3.3H20.1V13.4z M48.3,16.7h-5v-3.3h5V16.7z"></path>
                    <path class="st0" d="M5.1,23.4h8.3v1.7H5.1V23.4z"></path>
                    <path class="st0" d="M5.1,26.7h8.3v1.7H5.1V26.7z"></path>
                    <path class="st0" d="M5.1,30h8.3v1.7H5.1V30z"></path>
                    <path class="st0" d="M5.1,33.4h8.3V35H5.1V33.4z"></path>
                    <path class="st0" d="M16.7,23.4h8.3v1.7h-8.3V23.4z"></path>
                    <path class="st0" d="M16.7,26.7h8.3v1.7h-8.3V26.7z"></path>
                    <path class="st0" d="M28.4,23.4h4.2v1.7h-4.2V23.4z"></path>
                    <path class="st0" d="M28.4,26.7h4.2v1.7h-4.2V26.7z"></path>
                    <path class="st0" d="M16.7,30h8.3v1.7h-8.3V30z"></path>
                    <path class="st0" d="M16.7,33.4h8.3V35h-8.3V33.4z"></path>
                    <path class="st0" d="M5.1,37.5h1.7v1.7H5.1V37.5z"></path>
                    <path class="st0" d="M8.4,37.5h1.7v1.7H8.4V37.5z"></path>
                    <path class="st0" d="M11.7,37.5h1.7v1.7h-1.7V37.5z"></path>
                    <path class="st0" d="M16.7,37.5h1.7v1.7h-1.7V37.5z"></path>
                    <path class="st0" d="M20.1,37.5h1.7v1.7h-1.7V37.5z"></path>
                    <path class="st0" d="M23.4,37.5h1.7v1.7h-1.7V37.5z"></path>
                    <path class="st0" d="M5.1,40.9h1.7v1.7H5.1V40.9z"></path>
                    <path class="st0" d="M8.4,40.9h1.7v1.7H8.4V40.9z"></path>
                    <path class="st0" d="M11.7,40.9h1.7v1.7h-1.7V40.9z"></path>
                    <path class="st0" d="M16.7,40.9h1.7v1.7h-1.7V40.9z"></path>
                    <path class="st0" d="M20.1,40.9h1.7v1.7h-1.7V40.9z"></path>
                    <path class="st0" d="M23.4,40.9h1.7v1.7h-1.7V40.9z"></path>
                    <path class="st0" d="M5.1,44.2h1.7v1.7H5.1V44.2z"></path>
                    <path class="st0" d="M8.4,44.2h1.7v1.7H8.4V44.2z"></path>
                    <path class="st0" d="M11.7,44.2h1.7v1.7h-1.7V44.2z"></path>
                    <path class="st0" d="M16.7,44.2h1.7v1.7h-1.7V44.2z"></path>
                    <path class="st0" d="M20.1,44.2h1.7v1.7h-1.7V44.2z"></path>
                    <path class="st0" d="M23.4,44.2h1.7v1.7h-1.7V44.2z"></path>
                  </svg>`,
					title: 'Profile Creation',
					text: 'Restaurant owners can create unique profiles for their restaurants complete with images and videos.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M47.5,21.7H30.9c-1.4,0-2.5,1.1-2.5,2.5v23.3c0,1.4,1.1,2.5,2.5,2.5h16.7c1.4,0,2.5-1.1,2.5-2.5V24.2
                    C50,22.8,48.9,21.7,47.5,21.7z M38.3,48.4h-7.5c-0.5,0-0.8-0.4-0.8-0.8v-5.8h8.3V48.4z M38.3,40H30v-6.7h8.3V40z M48.3,47.5
                    c0,0.5-0.4,0.8-0.8,0.8H40v-6.7h8.3V47.5z M48.3,40H40v-6.7h8.3V40z M48.3,31.7H30v-7.5c0-0.5,0.4-0.8,0.8-0.8h16.7
                    c0.5,0,0.8,0.4,0.8,0.8V31.7z"></path>
                    <path class="st0" d="M45.8,25.1H32.5c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h13.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                    C46.7,25.4,46.3,25.1,45.8,25.1z M45,28.4H33.3v-1.7H45V28.4z"></path>
                    <path class="st0" d="M35,35.9v-1.7h-1.7v1.7h-1.7v1.7h1.7v1.7H35v-1.7h1.7v-1.7H35z"></path>
                    <path class="st0" d="M41.7,35.9h5v1.7h-5V35.9z"></path>
                    <path class="st0" d="M36.5,44l-1.2-1.2L34.2,44L33,42.8L31.8,44l1.2,1.2l-1.2,1.2l1.2,1.2l1.2-1.2l1.2,1.2l1.2-1.2l-1.2-1.2L36.5,44
                    z"></path>
                    <path class="st0" d="M41.7,44.2h5v1.7h-5V44.2z"></path>
                    <path class="st0" d="M43.3,43H45v0.8h-1.7V43z"></path>
                    <path class="st0" d="M33.3,0.1H9.2v0C9,0.1,8.8,0.2,8.6,0.3L0.3,8.6C0.1,8.8,0.1,9,0.1,9.2h0v39.1C0,49.3,0.8,50,1.7,50h25v-1.7h-5
                    v-2.5h1.7c0.5,0,0.8-0.4,0.8-0.8v-4.2c0-0.5-0.4-0.8-0.8-0.8h-1.7v-2.5h1.7c0.5,0,0.8-0.4,0.8-0.8v-4.2c0-0.5-0.4-0.8-0.8-0.8h0
                    c2.1-1.6,3.3-4,3.4-6.7c0-4.6-3.7-8.3-8.3-8.3S10,20.4,10,25c0,2.6,1.2,5.1,3.4,6.7H6.7c-0.5,0-0.8,0.4-0.8,0.8v3.3H4.2
                    c-0.5,0-0.8,0.4-0.8,0.8v4.2c0,0.5,0.4,0.8,0.8,0.8h1.7v2.5H4.2c-0.5,0-0.8,0.4-0.8,0.8v3.3H1.7V10.1h6.7c0.9,0,1.7-0.7,1.7-1.7V1.7
                    h23.3v18.3H35V1.7C35,0.8,34.3,0.1,33.3,0.1z M22.5,41.7v2.5h-15v-2.5H22.5z M11.7,25.1c0-3.7,3-6.7,6.7-6.7c3.7,0,6.7,3,6.7,6.7
                    s-3,6.7-6.7,6.7C14.7,31.7,11.7,28.7,11.7,25.1z M7.5,33.4h15v2.5h-15V33.4z M5,40v-2.5h15V40H6.7H5z M5,45.9h15v2.5H5V45.9z
                    M8.4,8.4H2.9l5.5-5.5V8.4z"></path>
                    <path class="st0" d="M18.4,22.6h2.5v-1.7h-1.7v-0.8h-1.7v1c-1.3,0.5-2,1.9-1.5,3.2c0.4,1,1.3,1.7,2.4,1.7c0.5,0,0.8,0.4,0.8,0.8
                    c0,0.5-0.4,0.8-0.8,0.8h-2.5v1.7h1.7V30h1.7v-1c1.3-0.5,2-1.9,1.5-3.2c-0.4-1-1.3-1.7-2.4-1.7c-0.5,0-0.8-0.4-0.8-0.8
                    C17.5,22.9,17.9,22.6,18.4,22.6z"></path>
                    <path class="st0" d="M46.7,4.2H45c0-0.5-0.4-0.8-0.8-0.8h-0.8V0.9c0-0.5-0.4-0.8-0.8-0.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8v2.5h-0.8
                    c-0.5,0-0.8,0.4-0.8,0.8v15.8h1.7v-15h5v15H45V5.9h1.7v10.8h1.7V5.9C48.3,5,47.6,4.2,46.7,4.2z M41.7,3.4H40V1.7h1.7V3.4z"></path>
                    <path class="st0" d="M12.5,6.7h14.2v1.7H12.5V6.7z"></path>
                    <path class="st0" d="M3.4,11.7h28.3v1.7H3.4V11.7z"></path>
                    <path class="st0" d="M3.4,15.1h10v1.7h-10V15.1z"></path>
                    <path class="st0" d="M24.2,15.1h7.5v1.7h-7.5V15.1z"></path>
                  </svg>`,
					title: 'Manage Business Info',
					text: 'Option to manage business info like working hours, location &amp; contact details on the profile.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M39.6,0H10.4C7.8,0,5.6,2.2,5.6,4.8v40.3c0,2.7,2.2,4.8,4.8,4.8h29.1c2.7,0,4.8-2.2,4.8-4.8V4.8
                    C44.4,2.2,42.2,0,39.6,0z M42.1,45.2c0,1.4-1.2,2.6-2.6,2.6H10.4c-1.4,0-2.6-1.2-2.6-2.6V4.8c0-1.4,1.2-2.6,2.6-2.6h29.1
                    c1.4,0,2.6,1.2,2.6,2.6V45.2z"></path>
                    <path class="st0" d="M15.4,18.9h19.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-0.4c-0.9-2.8-3.2-4.9-6.2-5.8
                    c0.3-0.5,0.4-1,0.4-1.6c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,0.6,0.2,1.1,0.4,1.6c-2.9,0.9-5.2,3-6.1,5.8h-0.5
                    c-0.6,0-1.1,0.5-1.1,1.1C14.3,18.4,14.8,18.9,15.4,18.9z M25,8.1c0.6,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2
                    S24.3,8.1,25,8.1z M25,12.7c3,0,5.6,1.6,6.7,4H18.3C19.4,14.3,22,12.7,25,12.7z"></path>
                    <path class="st0" d="M38,24.1H12c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h26c0.6,0,1.1-0.5,1.1-1.1
                    C39.1,24.6,38.6,24.1,38,24.1z"></path>
                    <path class="st0" d="M38,30.1H12c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h26c0.6,0,1.1-0.5,1.1-1.1S38.6,30.1,38,30.1z"></path>
                    <path class="st0" d="M38,36H12c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h26c0.6,0,1.1-0.5,1.1-1.1C39.1,36.5,38.6,36,38,36z"></path>
                    <path class="st0" d="M38,41.9H12c-0.6,0-1.1,0.5-1.1,1.1s0.5,1.1,1.1,1.1h26c0.6,0,1.1-0.5,1.1-1.1S38.6,41.9,38,41.9z"></path>
                  </svg>`,
					title: 'Manage Menu',
					text: 'Add, edit or remove dishes from the menu on a real-time basis. Pricing can also be updated from time-to-time.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M31.5,6.8h5.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-5.3c-0.4,0-0.7,0.3-0.7,0.7
                    C30.8,6.5,31.1,6.8,31.5,6.8z"></path>
                    <path class="st0" d="M39.9,6.1c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8C39.6,5.3,39.9,5.7,39.9,6.1z"></path>
                    <path class="st0" d="M48.8,24.5v-1.9c0-6.2-4.9-11.3-11-11.7V9.4h2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-5.4
                    c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h2V11c-2.4,0.1-4.7,1-6.6,2.5c-0.3,0.3-0.4,0.7-0.1,1c0.3,0.3,0.7,0.4,1,0.1
                    c6.6-5.3,16.7-0.7,16.7,8v1.6c-5.1,0-16.6,0-20.5,0c0.1-0.9-0.5-4.2,2-7.6c0.2-0.3,0.2-0.8-0.2-1c-0.3-0.2-0.8-0.2-1,0.2
                    c-2.8,3.9-2.1,7.7-2.2,8.5l-1.3,0l0.5-1.1c1-1.9-0.5-3.9-1.9-3.9V6c0-1.7-1.4-3.1-3.1-3.1c-1.2,0-15.5,0-16.4,0C1.4,2.8,0,4.2,0,6
                    v36.3c0,1.7,1.4,3.1,3.1,3.1h16.4c1.7,0,3.1-1.4,3.1-3.1v-5.5l6.6,0c1.5,0,2.4-1.5,2-2.8l2.3,0c1.4,0,2.5-1.4,2-2.9
                    c1.1-0.4,1.6-1.6,1.2-2.6h6.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c-1,0-7.6,0-9.1,0c0.1-0.4,0.1-0.9,0-1.3h13.5
                    c0.8,0,0.8,1.3,0,1.3h-1.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.5C49.8,28.5,50.6,25.6,48.8,24.5z M17.9,4.3L17,8H5.6
                    L4.8,4.3H17.9z M21.2,42.3c0,0.9-0.7,1.7-1.7,1.7H3.1c-0.9,0-1.7-0.7-1.7-1.7V6c0-0.9,0.7-1.7,1.7-1.7h0.1l0.9,4.1
                    C4.4,9,4.9,9.5,5.6,9.5H17c0.7,0,1.2-0.5,1.4-1.1l0.9-4.1h0.1c0.9,0,1.7,0.7,1.7,1.7v14.2l-0.6,1.1v-4.9c0-0.4-0.3-0.7-0.7-0.7
                    c-0.4,0-0.7,0.3-0.7,0.7v7.3c-0.1,0.1-0.3,0.2-0.4,0.3c0-0.9-0.8-1.7-1.8-1.7h-2.6c-1,0-1.8,0.8-1.8,1.8H6.3c-1,0-1.8,0.8-1.8,1.8
                    v8.4c0,1,0.8,1.8,1.8,1.8h6.4c0,1,0.8,1.8,1.8,1.8h2.6c0.8,0,1.5-0.5,1.7-1.2c0.2,0,0.3,0.1,0.5,0.1v4.5H3.5V11.8h15.6v1.7
                    c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-1.8c0-0.8-0.6-1.4-1.4-1.4H3.5C2.7,10.4,2,11,2,11.8v29.5c0,0.8,0.6,1.4,1.4,1.4h15.7
                    c0.8,0,1.4-0.6,1.4-1.4v-4.5l0.6,0V42.3z M17.2,24.2v11.9c0,0.2-0.1,0.3-0.3,0.3h-2.6c-0.2,0-0.3-0.1-0.3-0.3c0-4.4,0-7.6,0-11.9
                    c0-0.2,0.1-0.3,0.3-0.3h2.6C17.1,23.9,17.2,24,17.2,24.2z M12.6,25.6v9H6.3c-0.2,0-0.3-0.1-0.3-0.3v-8.4c0-0.2,0.1-0.3,0.3-0.3H12.6
                    z M34.7,28.5c0.3,0,0.5,0.2,0.6,0.5c0.1,0.2,0.1,0.5-0.1,0.6c-0.3,0.3-0.8,0.2-1.7,0.2h-6.6c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7
                    c6.8,0,6.6,0,6.6,0c0.4,0,0.6,0.3,0.6,0.6c0,0.4-0.3,0.6-0.6,0.6c-4.5,0-4.3,0-4.4,0c-0.1,0,0,0-2.8,0c-0.4,0-0.7,0.3-0.7,0.7
                    c0,0.4,0.3,0.7,0.7,0.7c2.4,0,2.8,0,2.8,0c0.1,0,0.4,0,0.6,0.2c0.4,0.4,0.1,1.1-0.5,1.1c-10.5,0-10,0.1-10.5-0.2v-9.5
                    c1-0.2,1.8-0.9,2.3-1.8c1.6-3.2,1.6-3.2,1.7-3.1c0.7,0.3,0.9,1.1,0.6,1.8l-0.6,1.2c0,0.1-0.4,0.6,0,1.3c0.2,0.4,0.7,0.6,1.1,0.6
                    c0.2,0,7.7,0,8.3,0c0.3,0,0.6,0.2,0.7,0.5c0.1,0.4-0.2,0.8-0.6,0.8c-1.2,0-4,0-5.2,0c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7
                    C27.5,28.5,33.8,28.5,34.7,28.5z"></path>
                    <g>
                      <g>
                        <path class="st0" d="M46,44.5c-0.6-0.9-0.9-2-0.9-3v-3.1c0-1.3-0.5-2.5-1.4-3.4c-0.8-0.8-1.7-1.2-2.8-1.3v-1.2h-1.1v1.2
                        c-2.3,0.3-4.1,2.3-4.1,4.7v3c0,1.1-0.3,2.1-0.9,3l-1.1,1.6h5.1c0.2,0.7,0.9,1.1,1.6,1.1c0.7,0,1.4-0.5,1.6-1.1H47L46,44.5z
                        M35.7,45c0.7-1.1,1-2.3,1-3.5v-3c0-2,1.6-3.6,3.6-3.6c0,0,0,0,0,0c1,0,1.9,0.4,2.5,1c0.7,0.7,1.1,1.6,1.1,2.5v3.1
                        c0,1.3,0.4,2.5,1,3.5H35.7z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M48.4,36l-0.8,0.8c0.8,0.8,1.3,1.9,1.3,3.1c0,1.1-0.4,2.2-1.2,3l0.8,0.8c1-1,1.5-2.4,1.5-3.8
                        C50,38.4,49.4,37,48.4,36z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M46.8,37.6L46,38.4c0.4,0.4,0.6,0.9,0.6,1.5c0,0.5-0.2,1.1-0.6,1.5l0.8,0.8c0.6-0.6,0.9-1.4,0.9-2.2
                        C47.7,39,47.4,38.2,46.8,37.6z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M33,36.8L32.2,36c-1,1-1.6,2.4-1.6,3.9c0,1.4,0.5,2.8,1.5,3.8l0.8-0.8c-0.8-0.8-1.2-1.9-1.2-3
                        C31.8,38.7,32.2,37.6,33,36.8z"></path>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path class="st0" d="M34.6,38.4l-0.8-0.8c-0.6,0.6-0.9,1.4-0.9,2.3c0,0.8,0.3,1.6,0.9,2.2l0.8-0.8c-0.4-0.4-0.6-0.9-0.6-1.5
                        C34,39.3,34.2,38.8,34.6,38.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Order Alerts',
					text: 'Restaurant admin can get alerts for new orders, upcoming orders or cancelled orders.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M45.8,15H35V4.2C35,1.9,33.1,0,30.8,0H4.2C1.9,0,0,1.9,0,4.2v23.3c0,2.3,1.9,4.2,4.2,4.2h7.3L23.7,39
                        c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.6,0.2-1l-2.8-6.3h4.6v14.2c0,2.3,1.9,4.2,4.2,4.2h15
                        c2.3,0,4.2-1.9,4.2-4.2V19.2C50,16.9,48.1,15,45.8,15z M33.6,30.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3
                        c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0-0.2
                        c0-0.2,0.1-0.4,0.1-0.6c1-0.7,2.1-1.1,3.3-1.1c3.2,0,5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8c-3.2,0-5.8-2.6-5.8-5.8
                        c0-0.4,0-0.8,0.1-1.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.4-0.3,0.7-0.4C33.5,30.7,33.6,30.6,33.6,30.6z M20.8,30c-0.3,0-0.5,0.1-0.7,0.4
                        c-0.2,0.2-0.2,0.5-0.1,0.8l2.3,5.1l-10.2-6.1C12,30,11.8,30,11.7,30H4.2c-1.4,0-2.5-1.1-2.5-2.5V4.2c0-1.4,1.1-2.5,2.5-2.5h26.7
                        c1.4,0,2.5,1.1,2.5,2.5v23.3c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
                        c0,0.1-0.1,0.1-0.1,0.1c-0.3,0.5-0.8,0.9-1.3,1.1c0,0,0,0,0,0c-0.3,0.1-0.5,0.1-0.8,0.1H20.8z M48.3,45.8c0,1.4-1.1,2.5-2.5,2.5
                        h-15c-1.4,0-2.5-1.1-2.5-2.5V45h20V45.8z M48.3,43.3h-20V31.7h2.5c0,0,0,0,0.1,0c0,0.3-0.1,0.6-0.1,0.8c0,4.1,3.4,7.5,7.5,7.5
                        c4.1,0,7.5-3.4,7.5-7.5c0-4.1-3.4-7.5-7.5-7.5c-1.2,0-2.3,0.3-3.3,0.8v-4.1h13.3V43.3z M48.3,20H35v-3.3h10.8
                        c1.4,0,2.5,1.1,2.5,2.5V20z M19.2,5h-3.3c-4.6,0-8.3,3.7-8.3,8.3c0,0.5,0.4,0.8,0.8,0.8h18.3c0.5,0,0.8-0.4,0.8-0.8
                        C27.5,8.7,23.8,5,19.2,5z M9.2,12.5c0.4-3.3,3.2-5.8,6.6-5.8h3.3c3.4,0,6.2,2.5,6.6,5.8H9.2z M26.7,22.5H8.3
                        c-0.5,0-0.8,0.4-0.8,0.8c0,2.3,1.9,4.2,4.2,4.2h11.7c2.3,0,4.2-1.9,4.2-4.2C27.5,22.9,27.1,22.5,26.7,22.5z M23.3,25.8H11.7
                        c-1.1,0-2-0.7-2.4-1.7h16.4C25.3,25.1,24.4,25.8,23.3,25.8z M24.2,20c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1
                        c-1.2,0-1.8,0.6-2.3,1.1C18.2,19.8,18,20,17.5,20c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1c-1.2,0-1.8,0.6-2.3,1.1
                        c-0.4,0.4-0.6,0.6-1.1,0.6c-0.5,0-0.7-0.2-1.1-0.6c-0.5-0.5-1.1-1.1-2.3-1.1V20c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1
                        c1.2,0,1.8-0.6,2.3-1.1c0.4-0.4,0.6-0.6,1.1-0.6c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1c1.2,0,1.8-0.6,2.3-1.1
                        c0.4-0.4,0.6-0.6,1.1-0.6c0.5,0,0.7,0.2,1.1,0.6c0.5,0.5,1.1,1.1,2.3,1.1c1.2,0,1.8-0.6,2.3-1.1c0.4-0.4,0.6-0.6,1.1-0.6v-1.7
                        c-1.2,0-1.8,0.6-2.3,1.1C24.8,19.8,24.6,20,24.2,20z M7.5,17.5h20v-1.7h-20V17.5z M13.3,10.8H15V9.2h-1.7V10.8z M16.7,10.8h1.7
                        V9.2h-1.7V10.8z M20,10.8h1.7V9.2H20V10.8z M37.5,35c0.2,0,0.4-0.1,0.6-0.2l4.2-4.2l-1.2-1.2L37.5,33l-1.1-1.1l-1.2,1.2l1.7,1.7
                        C37.1,34.9,37.3,35,37.5,35z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Manage Orders',
					text: 'Accept or reject orders depending on the working hours, staff availability and cooking time.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M46.5,34.3c-0.3-0.3-0.8-0.3-1.1,0l-1,1c0-0.1,0-0.1,0-0.2c-0.1-2-0.9-3.8-2.3-5.1l-3.3-3.3
                    c-2.1-2.1-5.6-2.1-7.7,0l-3.9,3.8l-4.3-4.3c-0.1-0.1-0.3-0.2-0.4-0.4l2-2l2.5,2.5c0.3,0.3,0.8,0.3,1.1,0l4.9-4.9v1.2
                    c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-3.1c0-0.1,0-0.2-0.1-0.3v-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.1
                    c-0.1-0.1-0.1-0.1-0.2-0.1l0,0l0,0c-0.1-0.1-0.2-0.1-0.4-0.1h-3.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h1.2l-4.3,4.3
                    L25,22.2c-0.3-0.3-0.8-0.3-1.1,0l-3.1,3.1c-1.6-0.2-3.1,0.9-3.4,2.5c0,0.3,0,0.6,0,0.9l-3.7,3.7c-0.3,0.3-0.4,0.8-0.1,1.1
                    c0.3,0.3,0.8,0.4,1.1,0.1c0,0,0.1-0.1,0.1-0.1l3.2-3.2c0.1,0.1,0.2,0.2,0.3,0.3L30,41.9c1.3,1.3,3.1,2,4.9,2c0.3,0,0.5,0,0.8,0
                    l-1.3,1.3c-0.3,0.3-0.3,0.8,0,1.1c0,0,0,0,0,0l3.3,3.3c0.3,0.3,0.8,0.3,1.1,0l11-10.9c0.3-0.3,0.3-0.8,0-1.1c0,0,0,0,0,0L46.5,34.3z
                    M31,40.8L19.5,29.4c-0.6-0.6-0.6-1.6,0-2.2c0,0,0,0,0.1,0c0.3-0.3,0.7-0.4,1-0.4c0.5,0,0.9,0.2,1.2,0.5l4.9,4.8
                    c0.3,0.3,0.8,0.3,1.1,0l4.4-4.4c1.5-1.5,4-1.5,5.5,0L41,31c1.1,1,1.8,2.5,1.9,4c0,1.2-0.5,2.3-1.3,3.1L39,40.7l-0.2,0.2
                    C36.7,43,33.2,43,31,40.8z M38.2,48L36,45.8l3.9-3.8l0,0l1.3-1.3l4.8-4.8l2.2,2.2L38.2,48z"></path>
                    <path class="st0" d="M46.1,9.6h-2.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h2.3c1.3,0,2.3,1,2.3,2.3v20.1
                    c0,0.4,0.3,0.8,0.8,0.8S50,34,50,33.6V13.4C50,11.3,48.3,9.6,46.1,9.6z"></path>
                    <path class="st0" d="M33.7,48.3H17.5v-4.6h10.1c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H4.3C3,42.1,2,41,2,39.8V13.4
                    c0-1.3,1-2.3,2.3-2.3h2.3c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H4.3c-2.1,0-3.9,1.7-3.9,3.9v26.3c0,2.1,1.7,3.9,3.9,3.9h11.6
                    v4.6h-2.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h20.1c0.4,0,0.8-0.3,0.8-0.8C34.5,48.6,34.2,48.3,33.7,48.3z"></path>
                    <path class="st0" d="M9,35.9H7.4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h13.9c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H10.5V9.6h7
                    c0.4,0,0.8-0.3,0.8-0.8v-7h20.9c0.4,0,0.8,0.3,0.8,0.8v23.2c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V2.6c0-1.3-1-2.3-2.3-2.3
                    H17.5c-0.2,0-0.4,0.1-0.5,0.2L9.2,8.2C9,8.4,9,8.6,9,8.8V35.9z M11.6,8l5.1-5.1V8H11.6z"></path>
                    <path class="st0" d="M33,8h1.5c0.4,0,0.8,0.3,0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8c0-1.3-1-2.3-2.3-2.3V5.7
                    c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8v0.8c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h1.5c0.4,0,0.8,0.3,0.8,0.8
                    c0,0.4-0.3,0.8-0.8,0.8H33c-0.4,0-0.8-0.3-0.8-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8c0,1.3,1,2.3,2.3,2.3V15
                    c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3H33c-0.4,0-0.8-0.3-0.8-0.8
                    C32.2,8.4,32.5,8,33,8z"></path>
                    <path class="st0" d="M27.5,4.9h-6.2c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h6.2c0.4,0,0.8-0.3,0.8-0.8
                    C28.3,5.3,28,4.9,27.5,4.9z"></path>
                    <path class="st0" d="M27.5,8h-6.2c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h6.2c0.4,0,0.8-0.3,0.8-0.8C28.3,8.4,28,8,27.5,8z"></path>
                    <path class="st0" d="M27.5,11.1H14.4c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h13.2c0.4,0,0.8-0.3,0.8-0.8
                    C28.3,11.4,28,11.1,27.5,11.1z"></path>
                    <path class="st0" d="M27.5,14.2H14.4c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h13.2c0.4,0,0.8-0.3,0.8-0.8
                    C28.3,14.5,28,14.2,27.5,14.2z"></path>
                    <path class="st0" d="M27.5,17.3H14.4c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h13.2c0.4,0,0.8-0.3,0.8-0.8
                    C28.3,17.6,28,17.3,27.5,17.3z"></path>
                    <path class="st0" d="M7.4,13.4c0-0.4-0.3-0.8-0.8-0.8H5.9c-1.3,0-2.3,1-2.3,2.3v24c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8V15
                    c0-0.4,0.3-0.8,0.8-0.8h0.8C7.1,14.2,7.4,13.9,7.4,13.4z"></path>
                    <path class="st0" d="M46.1,32.8c0.4,0,0.8-0.3,0.8-0.8V15c0-1.3-1-2.3-2.3-2.3h-0.8c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8
                    h0.8c0.4,0,0.8,0.3,0.8,0.8v17C45.4,32.4,45.7,32.8,46.1,32.8z"></path>
                    <path class="st0" d="M23,46.1h1.7v0.8H23V46.1z"></path>
                  </svg>`,
					title: 'Payment Management',
					text: 'Multiple payment options including credit/debit cards, online banking, digital wallets, promo codes, etc.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M11.3,18.4l5.1,5.1c0.2,0.2,0.4,0.3,0.7,0.3s0.6-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-3.4-3.4h10.9
                      c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1H14.4l3.4-3.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-5.1,5.1c-0.1,0.1-0.2,0.2-0.2,0.4
                      C11,17.6,11,17.7,11,17.8c0,0.1,0,0.3,0.1,0.4C11.1,18.3,11.2,18.4,11.3,18.4z"></path>
                      <path class="st0" d="M37.5,17.8c0-7.3-5.9-13.3-13.3-13.3H13.2c-7.3,0-13.3,5.9-13.3,13.3c0,5.5,3.3,10.3,8.3,12.3v5.1
                      c0,0.9,0.7,1.6,1.6,1.6c0.4,0,0.8-0.2,1.1-0.5l5.3-5.3h7.9C31.6,31,37.5,25.1,37.5,17.8z M9.6,28.4C5,26.9,1.9,22.6,1.9,17.8
                      c0-6.2,5-11.2,11.2-11.2h11.1c6.2,0,11.2,5,11.2,11.2c0,6.2-5,11.2-11.2,11.2L24,29h-8.1c-0.3,0-0.6,0.1-0.7,0.3l-4.9,4.9v-4.9
                      C10.2,28.9,10,28.5,9.6,28.4z"></path>
                      <path class="st0" d="M41.7,20.8c-0.5-0.1-1,0.2-1.2,0.8c-0.1,0.3,0,0.5,0.2,0.8c0.2,0.2,0.4,0.3,0.7,0.4c3.9,0.7,6.8,4.2,6.8,8.2
                      c0,3.6-2.3,6.7-5.7,7.9c-0.4,0.1-0.7,0.5-0.7,1v3.1l-3.3-3.3c-0.2-0.2-0.5-0.2-0.7-0.2h-6.3c-3.1,0-6-1.8-7.4-4.6
                      c-0.1-0.3-0.4-0.5-0.6-0.5c-0.3-0.1-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.4-0.5,0.6c-0.1,0.3,0,0.5,0.1,0.7c1.8,3.5,5.3,5.7,9.2,5.7h5.9
                      l4,4c0.3,0.3,0.6,0.4,1,0.4c0.2,0,0.4-0.1,0.6-0.1c0.6-0.3,0.9-0.8,0.9-1.4v-3.7c3.9-1.6,6.4-5.4,6.4-9.5
                      C50.1,25.9,46.5,21.7,41.7,20.8z"></path>
                    </g>
                  </svg>`,
					title: 'Reply To Reviews',
					text: 'Restaurant owners can respond to reviews submitted by customers on their profiles.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M4.5,27.3h0.7c0,1.3,1.1,2.3,2.4,2.3h0.5c0.4,0.6,0.6,1.3,0.7,2c0,0.7,0.4,6.9,2.1,8.9c0.3,0.4,0.8,0.5,1.3,0.5
                    c0.4,0,0.7-0.1,1-0.2l2.1-1c0.8-0.4,1.1-1.2,0.9-2c-0.7-2.5-1-3.7-1-4.1c0.6-0.6,0.9-1.6,0.6-2.4c-0.1-0.5-0.1-1.1,0.2-1.6H17
                    c0.4,0,0.8-0.2,1.1-0.5c8.1,0.3,12.7,3.1,13.5,3.6v0c0,1.3,1.1,2.3,2.4,2.3c1.3,0,2.4-1,2.4-2.3V28c1.8-0.4,3.2-2.1,3.2-4.2
                    s-1.4-3.8-3.2-4.2v-5.2c0-1.3-1.1-2.3-2.4-2.3c-1.3,0-2.4,1-2.4,2.3v0c-4.2,3.1-10.7,3.7-13.1,3.8c-0.2-0.6-0.8-1.1-1.5-1.1H7.5
                    c-1.3,0-2.4,1-2.4,2.3H4.5c-1.3,0-2.4,1-2.5,2.3V25C2,26.3,3.1,27.4,4.5,27.3z M36.3,21.3c1.4,0.7,2,2.5,1.3,3.9
                    c-0.3,0.5-0.7,1-1.3,1.2V21.3z M33.1,14.5c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v18.4c0,0.4-0.4,0.8-0.8,0.8
                    c-0.4,0-0.8-0.3-0.8-0.8V14.5z M14.6,38.4l-2.1,1c-0.2,0.1-0.3,0.1-0.5,0.1c-1-1.2-1.6-5.5-1.7-7.9c-0.1-0.6-0.2-1.2-0.4-1.8h4.4
                    c-0.1,0.6-0.1,1.3,0,2l0,0.1c0.2,0.5-0.3,1-0.4,1l-0.1,0.1L13.7,33c-0.2,0.3-0.5,0.8,0.9,5.3C14.7,38.3,14.6,38.4,14.6,38.4z
                    M6.7,19.5c0-0.4,0.4-0.8,0.8-0.8h1.2v7.4c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-7.4h1.6v3.9c0,0.4,0.4,0.8,0.8,0.8
                    c0.4,0,0.8-0.3,0.8-0.8v-3.9H17v1.6c0,0.4,0.4,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.5c2.4-0.1,8.5-0.6,13-3.4V31c-1.9-1-6.2-2.9-13-3.3
                    v-1.1c0-0.4-0.4-0.8-0.8-0.8S17,26.1,17,26.6v1.6H7.5c-0.4,0-0.8-0.3-0.8-0.8V19.5z M3.6,21.9c0-0.5,0.4-0.8,0.9-0.8h0.7v4.7H4.5
                    c-0.5,0-0.8-0.3-0.9-0.8V21.9z"></path>
                    <ellipse class="st0" cx="17.8" cy="23.4" rx="0.8" ry="0.8"></ellipse>
                    <path class="st0" d="M41,23.6c0,0.4,0.4,0.8,0.8,0.8h4.7c0.4,0,0.8-0.3,0.8-0.8s-0.4-0.8-0.8-0.8h-4.7C41.4,22.9,41,23.2,41,23.6z"></path>
                    <path class="st0" d="M44.9,17l-3.9,3.1c-0.3,0.3-0.4,0.7-0.2,1.1s0.8,0.4,1.1,0.2c0,0,0,0,0,0l3.9-3.1c0.3-0.3,0.4-0.7,0.2-1.1
                    S45.3,16.7,44.9,17C44.9,16.9,44.9,17,44.9,17z"></path>
                    <path class="st0" d="M46,30.2c0.3-0.3,0.2-0.8-0.1-1.1L41.9,26c-0.3-0.3-0.8-0.2-1.1,0.2c-0.3,0.3-0.2,0.8,0.1,1.1l3.9,3.1
                    C45.2,30.6,45.7,30.5,46,30.2z"></path>
                    <path class="st0" d="M48.1,35.5c0.4,0.2,0.9,0,1-0.4c0.1-0.1,0.1-0.2,0.2-0.4c0.2-0.4,0-0.9-0.4-1c-0.4-0.2-0.9,0-1,0.4l-0.1,0.3
                    C47.5,34.8,47.7,35.3,48.1,35.5z"></path>
                    <path class="st0" d="M15.8,46.3c-0.2-0.1-0.5-0.2-0.7-0.3c-0.4-0.2-0.9,0-1.1,0.4c-0.2,0.4,0,0.9,0.4,1l0,0c0.2,0.1,0.5,0.2,0.7,0.4
                    c0.4,0.2,0.9,0,1-0.4S16.2,46.5,15.8,46.3z"></path>
                    <path class="st0" d="M18.7,47.4c-0.4-0.1-0.9,0.1-1,0.5c-0.1,0.4,0.1,0.9,0.5,1c0.3,0.1,0.5,0.2,0.8,0.2c0.4,0.1,0.9-0.1,1-0.5
                    c0.1-0.4-0.1-0.9-0.5-1c0,0-0.1,0-0.1,0C19.2,47.5,18.9,47.5,18.7,47.4z"></path>
                    <path class="st0" d="M11.8,44c-0.3-0.3-0.8-0.2-1.1,0.2c-0.3,0.3-0.2,0.8,0.2,1.1c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.7,0.5
                    c0.4,0.2,0.9,0.1,1.1-0.2c0.2-0.4,0.1-0.8-0.2-1.1C12.2,44.3,12,44.2,11.8,44z"></path>
                    <path class="st0" d="M41.2,42.9c-0.2,0.2-0.4,0.3-0.6,0.5c-0.3,0.3-0.4,0.8-0.1,1.1c0.3,0.3,0.8,0.4,1.1,0.1
                    c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.3,0.4-0.8,0.1-1.1C42.1,42.7,41.6,42.6,41.2,42.9z"></path>
                    <path class="st0" d="M7.7,41.5c-0.3,0.3-0.3,0.8,0,1.1c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6c0.3,0.3,0.8,0.2,1.1-0.1
                    c0.3-0.3,0.3-0.8,0-1.1c-0.2-0.2-0.4-0.4-0.6-0.5C8.5,41.2,8,41.2,7.7,41.5z"></path>
                    <path class="st0" d="M22.2,49.8c0.3,0,0.5,0.1,0.8,0.1h0.1c0.4,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.8-0.7-0.8c-0.3,0-0.5-0.1-0.8-0.1
                    c-0.4-0.1-0.8,0.2-0.9,0.7C21.5,49.3,21.8,49.7,22.2,49.8L22.2,49.8z"></path>
                    <path class="st0" d="M46.1,38.9c0.4,0.2,0.9,0.1,1.1-0.2c0,0,0,0,0,0c0.1-0.2,0.3-0.5,0.4-0.7c0.2-0.4,0.1-0.9-0.3-1.1
                    c-0.4-0.2-0.9-0.1-1.1,0.3c-0.1,0.2-0.3,0.4-0.4,0.6C45.6,38.2,45.7,38.6,46.1,38.9z"></path>
                    <path class="st0" d="M26.3,50L26.3,50c0.3,0,0.5,0,0.8,0c0.4,0,0.8-0.4,0.7-0.8c0-0.4-0.4-0.8-0.8-0.7l0,0c-0.3,0-0.5,0-0.8,0
                    c-0.4,0-0.8,0.3-0.8,0.8S25.9,50,26.3,50L26.3,50z"></path>
                    <path class="st0" d="M44,40.2c-0.2,0.2-0.3,0.4-0.5,0.6c-0.3,0.3-0.3,0.8,0.1,1.1c0.3,0.3,0.8,0.3,1.1,0c0.2-0.2,0.4-0.4,0.5-0.6
                    c0.2-0.4,0.2-0.8-0.2-1.1C44.7,40,44.3,40,44,40.2L44,40.2z"></path>
                    <path class="st0" d="M38.1,45.1c-0.2,0.1-0.4,0.3-0.7,0.4c-0.4,0.2-0.6,0.6-0.5,1c0.2,0.4,0.6,0.6,1,0.5c0.1,0,0.1-0.1,0.2-0.1
                    c0.2-0.1,0.5-0.3,0.7-0.4c0.4-0.3,0.4-0.7,0.2-1.1C38.8,45.1,38.4,45,38.1,45.1L38.1,45.1z"></path>
                    <path class="st0" d="M34.6,46.8c-0.2,0.1-0.5,0.2-0.7,0.3c-0.4,0.1-0.6,0.6-0.5,1c0.1,0.4,0.6,0.6,1,0.5c0.3-0.1,0.5-0.2,0.8-0.3
                    c0.4-0.2,0.6-0.6,0.4-1S35,46.6,34.6,46.8L34.6,46.8L34.6,46.8z"></path>
                    <path class="st0" d="M31.9,48.5c-0.1-0.4-0.5-0.7-0.9-0.6c-0.3,0.1-0.5,0.1-0.8,0.1c-0.4,0.1-0.7,0.5-0.6,0.9
                    c0.1,0.4,0.5,0.7,0.9,0.6l0,0c0.3,0,0.5-0.1,0.8-0.2C31.7,49.4,31.9,48.9,31.9,48.5C31.9,48.5,31.9,48.5,31.9,48.5z"></path>
                    <path class="st0" d="M6.6,38.9c-0.3-0.3-0.8-0.4-1.1-0.1c-0.3,0.2-0.3,0.7-0.2,1l0.2,0.3c0.3,0.3,0.8,0.4,1.1,0.1
                    c0.3-0.3,0.4-0.8,0.1-1.1L6.6,38.9z"></path>
                    <path class="st0" d="M6.7,36l-3.2-1.6c-0.4-0.2-0.9,0-1.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3v3.1c0,0.4,0.4,0.8,0.8,0.8l0,0
                    c0.4,0,0.8-0.3,0.8-0.8v-1.9l2,1c0.4,0.2,0.9,0,1.1-0.3C7.2,36.7,7.1,36.2,6.7,36L6.7,36z"></path>
                    <path class="st0" d="M2.9,16.4c0.4,0.2,0.9,0,1-0.4c0,0,0,0,0,0l0.1-0.3c0.2-0.4,0-0.9-0.4-1c-0.4-0.2-0.9,0-1,0.4c0,0,0,0,0,0
                    c-0.1,0.1-0.1,0.2-0.2,0.4C2.3,15.7,2.5,16.2,2.9,16.4z"></path>
                    <path class="st0" d="M32.3,2.4c0.2,0.1,0.5,0.1,0.7,0.2c0.4,0.1,0.9-0.1,1-0.5c0.1-0.4-0.1-0.9-0.5-1C33.2,1,32.9,1,32.7,0.9
                    c-0.4-0.1-0.8,0.2-0.9,0.6C31.7,1.9,31.9,2.3,32.3,2.4L32.3,2.4z"></path>
                    <path class="st0" d="M28.5,1.7c0.3,0,0.5,0.1,0.8,0.1h0.1c0.4,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.8-0.7-0.8c-0.3,0-0.5-0.1-0.8-0.1
                    c-0.4,0-0.8,0.3-0.9,0.7S28,1.7,28.5,1.7L28.5,1.7z"></path>
                    <path class="st0" d="M24.6,1.6L24.6,1.6c0.3,0,0.6,0,0.8,0c0.4,0,0.8-0.3,0.8-0.8S25.8,0,25.4,0c-0.3,0-0.5,0-0.8,0
                    c-0.4,0-0.8,0.4-0.8,0.8C23.8,1.3,24.2,1.6,24.6,1.6L24.6,1.6z"></path>
                    <path class="st0" d="M42.9,8.5c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1l0,0c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.3-0.8-0.2-1.1,0.1
                    c-0.3,0.3-0.3,0.8,0,1.1C42.5,8.1,42.7,8.3,42.9,8.5z"></path>
                    <path class="st0" d="M35.9,3.7c0.2,0.1,0.5,0.2,0.7,0.3c0.4,0.2,0.9,0,1.1-0.4c0.2-0.4,0-0.9-0.4-1c-0.2-0.1-0.5-0.2-0.7-0.4
                    c-0.4-0.2-0.9,0-1,0.4C35.3,3.1,35.5,3.5,35.9,3.7z"></path>
                    <path class="st0" d="M4.4,13.1c0.4,0.2,0.9,0.1,1.1-0.3c0,0,0,0,0,0c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.1-0.9-0.3-1.1
                    c-0.4-0.2-0.8-0.1-1,0.2c-0.1,0.2-0.3,0.5-0.4,0.7C3.9,12.4,4,12.9,4.4,13.1C4.4,13.1,4.4,13.1,4.4,13.1z"></path>
                    <path class="st0" d="M7.1,10c0.2,0,0.4-0.1,0.6-0.3c0.2-0.2,0.3-0.4,0.5-0.6c0.3-0.3,0.3-0.8-0.1-1.1c-0.3-0.3-0.8-0.3-1.1,0
                    C6.9,8.3,6.7,8.5,6.5,8.7C6.2,9.1,6.3,9.6,6.6,9.8C6.7,10,6.9,10,7.1,10z"></path>
                    <path class="st0" d="M39.3,5.6c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.3,0.8,0.2,1.1-0.2c0.3-0.3,0.2-0.8-0.2-1.1c0,0,0,0,0,0
                    c-0.2-0.2-0.4-0.3-0.7-0.5c-0.4-0.2-0.9-0.1-1.1,0.2C38.8,4.8,38.9,5.3,39.3,5.6L39.3,5.6L39.3,5.6z"></path>
                    <path class="st0" d="M16.8,3.3c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.6-0.6,0.5-1c-0.1-0.4-0.6-0.6-1-0.5
                    c-0.3,0.1-0.5,0.2-0.8,0.3c-0.4,0.2-0.6,0.6-0.4,1C16.2,3.1,16.5,3.3,16.8,3.3L16.8,3.3z"></path>
                    <path class="st0" d="M20.6,2.1c0.1,0,0.1,0,0.2,0C21,2.1,21.3,2,21.5,2C22,1.9,22.3,1.5,22.2,1c-0.1-0.4-0.5-0.7-0.9-0.6
                    c-0.3,0-0.5,0.1-0.8,0.2c-0.4,0-0.8,0.4-0.7,0.9S20.2,2.2,20.6,2.1L20.6,2.1z"></path>
                    <path class="st0" d="M13.2,5c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.4-0.3,0.7-0.4c0.3-0.3,0.4-0.8,0.1-1.1c-0.2-0.3-0.6-0.4-0.9-0.3
                    c-0.2,0.1-0.5,0.3-0.7,0.4c-0.4,0.2-0.5,0.7-0.3,1.1C12.7,4.8,12.9,5,13.2,5L13.2,5z"></path>
                    <path class="st0" d="M10,7.3c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.4-0.3,0.6-0.5c0.3-0.3,0.4-0.8,0.1-1.1s-0.8-0.4-1.1-0.1
                    C9.9,5.5,9.7,5.7,9.5,5.9C9.1,6.2,9.1,6.7,9.4,7C9.5,7.2,9.7,7.3,10,7.3L10,7.3z"></path>
                    <path class="st0" d="M45.1,11.1c0.3,0.3,0.8,0.4,1.1,0.1c0.3-0.2,0.3-0.7,0.2-1l-0.2-0.3c-0.3-0.3-0.8-0.4-1.1-0.1
                    c-0.3,0.3-0.4,0.8-0.1,1.1L45.1,11.1z"></path>
                    <path class="st0" d="M45,14l3.2,1.6c0.4,0.2,0.9,0,1.1-0.4c0.1-0.1,0.1-0.2,0.1-0.3v-3.1c0-0.4-0.4-0.8-0.8-0.8l0,0
                    c-0.4,0-0.8,0.3-0.8,0.8v1.9l-2-1c-0.4-0.2-0.9,0-1.1,0.3C44.5,13.3,44.6,13.8,45,14z"></path>
                  </svg>`,
					title: 'Manage Promos',
					text: 'Release new promos, cancel ongoing ones, limit promos by time period or order numbers or set ceiling limits.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M26.3,31.5h-2.4c-1,0-1.7-0.8-1.7-1.7v-0.5c-2.5-1.1-4-3.5-4-6.3c0-1.9,0.8-3.7,2.1-5c1.3-1.2,3-1.9,4.8-1.9
                    c0,0,0,0,0.1,0c1.8,0,3.5,0.7,4.8,1.9c1.4,1.4,2.1,3.1,2.1,5c0,2.8-1.6,5.2-4,6.3v0.5C28,30.7,27.2,31.5,26.3,31.5z M24.8,17.8
                    c-1.2,0.1-2.4,0.6-3.4,1.4c-1,1-1.6,2.3-1.6,3.7c0,2.1,1.2,3.9,3.1,4.8c0.6,0.3,0.9,0.8,0.9,1.4v0.6h1.1H25h1.2v-0.6
                    c0-0.6,0.4-1.2,0.9-1.4c1.9-0.8,3.1-2.6,3.1-4.8c0-1.4-0.6-2.8-1.6-3.7c-0.9-0.9-2.1-1.4-3.4-1.4l0,0L24.8,17.8L24.8,17.8z"></path>
                    <path class="st0" d="M21.2,2l2.3,4.2c0.3,0.6,0.9,0.9,1.6,0.9c0.6,0,1.2-0.3,1.6-0.9L28.9,2c1.5,0.3,2.9,0.6,4.3,1.2L33.1,8
                    c0,0.6,0.3,1.2,0.9,1.5c0.3,0.1,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3L40,7.1c1.2,1,2.2,2,3.2,3.2l-2.5,4.1c-0.3,0.6-0.3,1.2,0,1.8
                    c0.3,0.6,0.9,0.9,1.6,0.9l0,0L47,17c0.5,1.4,0.9,2.8,1.2,4.3L44,23.6c-0.6,0.3-0.9,0.9-0.9,1.5c0,0.6,0.3,1.2,0.9,1.5l4.2,2.3
                    c-0.3,1.5-0.6,2.9-1.2,4.3l-4.8-0.1l0,0c-0.6,0-1.2,0.3-1.6,0.9c-0.3,0.6-0.3,1.2,0,1.8l2.5,4.1c-1,1.2-2,2.2-3.2,3.2l-4.1-2.5
                    c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.9,0.3c-0.6,0.3-0.9,0.9-0.9,1.5l0.1,4.8c-1.4,0.5-2.8,0.9-4.3,1.2l-2.3-4.2
                    C26.2,43.4,25.6,43,25,43c-0.6,0-1.2,0.3-1.6,0.9l-2.3,4.2c-1.5-0.3-2.9-0.6-4.3-1.2l0.1-4.8c0-0.6-0.3-1.2-0.9-1.5
                    c-0.3-0.1-0.6-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.9,0.3L10,43.1c-1.2-1-2.2-2-3.2-3.2l2.5-4.1c0.3-0.6,0.3-1.2,0-1.8
                    c-0.3-0.6-0.9-0.9-1.6-0.9l0,0L3,33.2c-0.5-1.4-0.9-2.8-1.2-4.3L6,26.6c0.6-0.3,0.9-0.9,0.9-1.5S6.6,23.8,6,23.5l-4.2-2.3
                    c0.3-1.5,0.6-2.9,1.2-4.3L7.8,17l0,0c0.6,0,1.2-0.3,1.6-0.9c0.3-0.6,0.3-1.2,0-1.8l-2.5-4.1C7.9,9,8.9,8,10,7l4.1,2.5
                    c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.3c0.6-0.3,0.9-0.9,0.9-1.5l-0.1-4.8C18.3,2.6,19.7,2.2,21.2,2 M28,0.1L25,5.3
                    l-2.9-5.2c-2.5,0.3-4.8,0.9-7,1.9l0.1,6L10,4.9C8.1,6.4,6.4,8.1,4.9,10L8,15.2l-6-0.1c-1,2.2-1.6,4.6-1.9,7L5.3,25l-5.2,2.9
                    c0.3,2.5,0.9,4.8,1.9,7l6-0.1l-3.1,5.2c1.5,1.9,3.2,3.7,5.2,5.2l5.2-3.1l-0.1,6c2.2,1,4.6,1.6,7,1.9l2.9-5.2L28,50
                    c2.5-0.3,4.8-0.9,7-1.9l-0.1-6l5.2,3.1c1.9-1.5,3.7-3.2,5.2-5.2l-3.1-5.2l6,0.1c1-2.2,1.6-4.6,1.9-7l-5.2-2.9l5.2-2.9
                    c-0.3-2.5-0.9-4.8-1.9-7h-5.9l3.1-5.2c-1.5-1.9-3.2-3.7-5.2-5.2L34.9,8l0.1-6C32.8,1,30.5,0.4,28,0.1L28,0.1z"></path>
                    <path class="st0" d="M25,12c7.2,0,13.1,5.9,13.1,13c0,7.2-5.9,13-13.1,13C17.8,38,12,32.1,12,25C12,17.8,17.8,12,25,12 M25,10.2
                    c-8.2,0-14.9,6.6-14.9,14.8c0,8.2,6.7,14.8,14.9,14.8c8.2,0,14.9-6.6,14.9-14.8C39.9,16.9,33.3,10.2,25,10.2L25,10.2z"></path>
                    <path class="st0" d="M26.5,35.8h-2.8c-0.6,0-1.2-0.5-1.2-1.1l-0.1-1.3c-0.1-0.4,0.1-0.7,0.3-1c0.2-0.3,0.5-0.4,0.8-0.4h3.2
                    c0.3,0,0.6,0.1,0.8,0.4c0.3,0.3,0.3,0.6,0.3,1l-0.1,1.3C27.6,35.3,27.1,35.8,26.5,35.8z M23.8,34.5h2.7l0.1-1.1h-2.8L23.8,34.5z"></path>
                    <path class="st0" d="M32.9,20.2c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.8,0-1.2l1.1-1.1c0.3-0.3,0.8-0.3,1.2,0
                    c0.3,0.3,0.3,0.8,0,1.2l-1.1,1.1C33.3,20.1,33.1,20.2,32.9,20.2z"></path>
                    <path class="st0" d="M34,30c-0.2,0-0.4-0.1-0.6-0.3l-1.1-1c-0.3-0.3-0.3-0.8,0-1.2c0.3-0.3,0.8-0.3,1.2,0l1.1,1.1
                    c0.3,0.3,0.3,0.8,0,1.2C34.4,30,34.2,30,34,30z"></path>
                    <path class="st0" d="M35.4,24.7H34c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8
                    C36.2,24.3,35.8,24.7,35.4,24.7z"></path>
                    <path class="st0" d="M17.2,20.2c-0.2,0-0.4-0.1-0.6-0.3l-1.1-1.1c-0.3-0.3-0.3-0.8,0-1.2c0.3-0.3,0.8-0.3,1.2,0l1.1,1.1
                    c0.3,0.3,0.3,0.8,0,1.2C17.7,20.1,17.4,20.2,17.2,20.2z"></path>
                    <path class="st0" d="M16.1,30c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.8,0-1.2l1.1-1.1c0.3-0.3,0.8-0.3,1.2,0c0.3,0.3,0.3,0.8,0,1.2
                    l-1.1,1.1C16.6,30,16.3,30,16.1,30z"></path>
                    <path class="st0" d="M16.2,24.7h-1.4c-0.5,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8
                    C17,24.3,16.6,24.7,16.2,24.7z"></path>
                  </svg>`,
					title: 'Get Insights',
					text: 'Know customer behaviour better by getting insights about frequently ordered dishes, location, time, etc.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M45.8,0.1H4.7C4.2,0.1,3.8,0.5,3.8,1v48c0,0.5,0.4,0.9,0.9,0.9h41.1c0.5,0,0.9-0.4,0.9-0.9V1
                      C46.6,0.5,46.3,0.1,45.8,0.1z M44.9,48.1H5.5V1.8h39.4V48.1z"></path>
                      <path class="st0" d="M13.2,32.7h24c0.5,0,0.9-0.4,0.9-0.9V4.4c0-0.5-0.4-0.9-0.9-0.9h-24c-0.5,0-0.9,0.4-0.9,0.9v27.4
                      C12.4,32.3,12.8,32.7,13.2,32.7z M14.1,5.2h22.3v18.5C32.8,17.6,25,15.4,18.8,19c-2,1.1-3.6,2.8-4.7,4.7V5.2z M14.1,30.1
                      c0-6.1,5-11.1,11.1-11.1s11.1,5,11.1,11.1v0.9H14.1V30.1z"></path>
                      <path class="st0" d="M25.2,16.4c2.8,0,5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1s-5.1,2.3-5.1,5.1S22.4,16.4,25.2,16.4z M25.2,7.8
                      c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S23.3,7.8,25.2,7.8z"></path>
                      <path class="st0" d="M13.2,35.2h1.7c0.5,0,0.9,0.4,0.9,0.9v1.7c0,0.5-0.4,0.9-0.9,0.9h-1.7c-0.5,0-0.9-0.4-0.9-0.9v-1.7
                      C12.4,35.6,12.8,35.2,13.2,35.2z"></path>
                      <path class="st0" d="M13.2,39.5h1.7c0.5,0,0.9,0.4,0.9,0.9v1.7c0,0.5-0.4,0.9-0.9,0.9h-1.7c-0.5,0-0.9-0.4-0.9-0.9v-1.7
                      C12.4,39.9,12.8,39.5,13.2,39.5z"></path>
                      <path class="st0" d="M13.2,43.8h1.7c0.5,0,0.9,0.4,0.9,0.9v1.7c0,0.5-0.4,0.9-0.9,0.9h-1.7c-0.5,0-0.9-0.4-0.9-0.9v-1.7
                      C12.4,44.2,12.8,43.8,13.2,43.8z"></path>
                      <path class="st0" d="M37.2,36.1H17.5c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h19.7c0.5,0,0.9-0.4,0.9-0.9
                      C38.1,36.5,37.7,36.1,37.2,36.1z"></path>
                      <path class="st0" d="M37.2,40.4H17.5c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h19.7c0.5,0,0.9-0.4,0.9-0.9S37.7,40.4,37.2,40.4z"></path>
                      <path class="st0" d="M37.2,44.6H17.5c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h19.7c0.5,0,0.9-0.4,0.9-0.9
                      C38.1,45,37.7,44.6,37.2,44.6z"></path>
                    </g>
                  </svg>`,
					title: 'Customer Details',
					text: 'Know more about the customer, their location and payment history to whom the delivery is to be dispatched.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M15,35c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3
                        C15.5,36,15.4,35.4,15,35L15,35z M13.7,14.2c-5,5-6,12.8-2.5,18.9c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3
                        c-3.1-5.4-2.2-12.2,2.2-16.6c5.4-5.4,14.1-5.4,19.4,0c5.4,5.4,5.4,14.1,0,19.4c-4.4,4.4-11.2,5.3-16.6,2.2
                        c-0.5-0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c2.5,1.4,5.2,2.1,7.9,2.1c4.1,0,8.1-1.6,11.1-4.6c6.1-6.1,6.1-16,0-22.2
                        C29.7,8.1,19.8,8.1,13.7,14.2z M49.5,21c0-0.5-0.3-0.9-0.8-1l-3.6-0.6l-1.9-4.6l2.1-2.9c0.3-0.4,0.2-0.9-0.1-1.3l-6-6
                        c-0.3-0.3-0.9-0.4-1.3-0.1l-2.9,2.1l-4.6-1.9l-0.6-3.6c-0.1-0.5-0.5-0.8-1-0.8h0l-8.5,0c-0.5,0-0.9,0.3-1,0.8L19,4.9l-4.6,1.9
                        l-2.9-2.1c-0.4-0.3-0.9-0.2-1.3,0.1l-6,6c-0.3,0.3-0.4,0.9-0.1,1.3l2.1,2.9l-1.9,4.6l-3.6,0.6C0.3,20.1,0,20.5,0,21l0,8.5
                        c0,0.5,0.3,0.9,0.8,1L4.4,31l1.9,4.6l-2.1,2.9c-0.3,0.4-0.2,0.9,0.1,1.3l6,6c0.3,0.3,0.9,0.4,1.3,0.1l2.9-2.1l4.6,1.9l0.6,3.6
                        c0.1,0.5,0.5,0.8,1,0.8h0l8.5,0c0.5,0,0.9-0.3,1-0.8l0.6-3.6l4.6-1.9l2.9,2.1c0.4,0.3,0.9,0.2,1.3-0.1l6-6
                        c0.3-0.3,0.4-0.9,0.1-1.3l-2.1-2.9l1.9-4.6l3.6-0.6c0.5-0.1,0.8-0.5,0.8-1L49.5,21z M44.3,29.2c-0.3,0.1-0.6,0.3-0.7,0.6l-2.3,5.6
                        c-0.1,0.3-0.1,0.7,0.1,0.9l2,2.7l-4.8,4.8l-2.7-2c-0.3-0.2-0.6-0.2-0.9-0.1L29.3,44c-0.3,0.1-0.5,0.4-0.6,0.7l-0.5,3.3l-6.8,0
                        l-0.5-3.3c-0.1-0.3-0.3-0.6-0.6-0.7l-5.6-2.3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2l-2.7,2L6.2,39l2-2.7
                        c0.2-0.3,0.2-0.6,0.1-0.9L6,29.8c-0.1-0.3-0.4-0.5-0.7-0.6l-3.3-0.5l0-6.8l3.3-0.5C5.6,21.3,5.8,21,6,20.7l2.3-5.6
                        c0.1-0.3,0.1-0.7-0.1-0.9l-2-2.7L11,6.7l2.7,2c0.3,0.2,0.6,0.2,0.9,0.1l5.6-2.3c0.3-0.1,0.5-0.4,0.6-0.7l0.5-3.3l6.8,0l0.5,3.3
                        c0.1,0.3,0.3,0.6,0.6,0.7l5.6,2.3c0.3,0.1,0.7,0.1,0.9-0.1l2.7-2l4.8,4.8l-2,2.7c-0.2,0.3-0.2,0.6-0.1,0.9l2.3,5.6
                        c0.1,0.3,0.4,0.5,0.7,0.6l3.3,0.5l0,6.8L44.3,29.2z M20.4,25.4l4.2,4.2c-0.3,1.7,0.3,3.4,1.5,4.6c1,1,2.4,1.6,3.8,1.6
                        c0.7,0,1.4-0.1,2-0.4c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.3-0.9l-1.4-1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
                        l1.4,1.4c0.2,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6c0.8-2.1,0.3-4.5-1.4-6c-1.2-1.1-2.8-1.6-4.4-1.3l-4.2-4.2
                        c0.3-1.7-0.3-3.4-1.5-4.6c-1.5-1.5-3.8-2-5.8-1.2c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.3,0.9l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
                        c-0.3,0.3-0.8,0.3-1.1,0l-1.4-1.4c-0.2-0.2-0.6-0.3-0.9-0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.8,2.1-0.3,4.5,1.4,6
                        C17.2,25.1,18.8,25.6,20.4,25.4z M17.2,22.6c-0.6-0.6-1-1.4-1.1-2.2l0.1,0.1c1.1,1.1,2.8,1.1,3.8,0c1.1-1.1,1.1-2.8,0-3.8l0,0
                        c0.8,0.1,1.5,0.4,2,1c0.9,0.9,1.2,2.1,0.9,3.3c-0.1,0.3,0,0.7,0.3,0.9l5,5c0.2,0.2,0.6,0.3,0.9,0.3c1.1-0.3,2.3,0,3.2,0.8
                        c0.6,0.6,1,1.3,1.1,2.2l0,0c-1.1-1.1-2.8-1.1-3.8,0c-1.1,1.1-1.1,2.8,0,3.8l0,0c-0.8-0.1-1.5-0.4-2.1-1c-0.9-0.9-1.2-2.1-0.9-3.3
                        c0.1-0.3,0-0.7-0.3-0.9l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.2,0-0.2,0C19.3,23.7,18.1,23.4,17.2,22.6z M27.2,14.3
                        c-0.5-0.1-1,0.2-1.2,0.7c-0.1,0.5,0.2,1,0.7,1.2c2.1,0.5,3.9,1.7,5.3,3.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
                        c0.4-0.3,0.5-0.9,0.2-1.4C32,16.3,29.7,14.9,27.2,14.3z M33.6,22.4c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
                        c0.5-0.2,0.8-0.7,0.6-1.2l0,0c-0.2-0.5-0.7-0.8-1.2-0.6C33.7,21.3,33.4,21.9,33.6,22.4L33.6,22.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Help &amp; Support',
					text: 'In-app support portal as well as emergency support to find quick resolutions to issues within the app.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M34.9,18.4h10V20h-10V18.4z"></path>
                    <path class="st0" d="M34.1,15h11.6v1.7H34.1V15z"></path>
                    <path class="st0" d="M39,28.4c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3C37.6,31.7,39,30.2,39,28.4z
                    M35.7,30.1c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7C37.4,29.3,36.6,30.1,35.7,30.1z"></path>
                    <path class="st0" d="M44,25.1c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C47.4,26.6,45.9,25.1,44,25.1z
                    M44,30.1c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7C45.7,29.3,44.9,30.1,44,30.1z"></path>
                    <path class="st0" d="M45.7,3.3H34.9c-0.5,0-0.8,0.4-0.8,0.8v7.5h-2.6l-0.7-5.1c-0.1-0.4-0.4-0.7-0.8-0.7h-5v1.7h4.3l0.7,5.1l0,0
                    c0,0,0,0.1,0,0.1l2.5,10c0.1,0.4,0.4,0.6,0.8,0.6h13.3c0.4,0,0.7-0.3,0.8-0.6l2.5-10c0.1-0.2,0-0.5-0.2-0.7
                    c-0.2-0.2-0.4-0.3-0.7-0.3h-2.5V4.2C46.5,3.7,46.2,3.3,45.7,3.3z M35.7,5h9.1v6.7h-9.1V5z M45.9,21.7h-12l-2.1-8.4h3.1h10.8H48
                    L45.9,21.7z"></path>
                    <path class="st0" d="M41.5,8.4H39V6.7h-1.7v2.5c0,0.5,0.4,0.8,0.8,0.8h4.2c0.5,0,0.8-0.4,0.8-0.8V6.7h-1.7V8.4z"></path>
                    <path class="st0" d="M8.3,25.1H10v5H8.3V25.1z"></path>
                    <path class="st0" d="M11.6,25.1h1.7v5h-1.7V25.1z"></path>
                    <path class="st0" d="M15,25.1h1.7v5H15V25.1z"></path>
                    <path class="st0" d="M18.3,25.1h1.7v5h-1.7V25.1z"></path>
                    <path class="st0" d="M8.3,31.7h11.6v1.7H8.3V31.7z"></path>
                    <path class="st0" d="M0.8,34.2h4.7c1.2,2,3.1,3.6,5.3,4.4v10.6c0,0.5,0.4,0.8,0.8,0.8h5c0.5,0,0.8-0.4,0.8-0.8V38.7
                    c2.2-0.8,4.1-2.4,5.3-4.4h4.7c0.5,0,0.8-0.4,0.8-0.8V14.2c0-0.5-0.4-0.8-0.8-0.8h-8.3c0-0.5-0.4-0.8-0.8-0.8h-1.7v-1.7
                    c0-0.5-0.4-0.8-0.8-0.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8v1.7H10c-0.5,0-0.8,0.4-0.8,0.8H0.8c-0.5,0-0.8,0.4-0.8,0.8v19.2
                    C0,33.9,0.4,34.2,0.8,34.2z M15.8,39.1v9.3h-3.3v-9.3c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0.1c0.3,0,0.5,0,0.8,0c0,0,0.1,0,0.1,0
                    c0,0,0.1,0,0.1,0c0.3,0,0.5,0,0.8,0c0.2,0,0.3,0,0.5-0.1C15.6,39.1,15.7,39.1,15.8,39.1z M16.4,37.3c-0.3,0.1-0.7,0.2-1,0.2
                    c0,0-0.1,0-0.1,0c-0.7,0.1-1.4,0.1-2.1,0c0,0-0.1,0-0.1,0c-0.3-0.1-0.7-0.1-1-0.2c-2.3-0.6-4.1-2.2-5.2-4.3
                    c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.1-0.4-0.2-0.7c0-0.1,0-0.2-0.1-0.3c0-0.2-0.1-0.4-0.1-0.7
                    c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.6-0.1-0.9c0-4.6,3.7-8.4,8.3-8.4c4.6,0,8.3,3.7,8.3,8.4c0,0.3,0,0.6-0.1,0.9c0,0.1,0,0.2,0,0.3
                    c0,0.2-0.1,0.4-0.1,0.7c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.2,0.5-0.3,0.8
                    C20.5,35.1,18.6,36.6,16.4,37.3z M12.5,14.2c0.5,0,0.8-0.4,0.8-0.8v-1.7H15v1.7c0,0.5,0.4,0.8,0.8,0.8h1.7v1.7h-6.6v-1.7H12.5z
                    M1.7,15h7.5v1.7c0,0.5,0.4,0.8,0.8,0.8h8.3c0.5,0,0.8-0.4,0.8-0.8V15h7.5v17.5h-3.1c0,0,0-0.1,0-0.1c0.1-0.2,0.1-0.3,0.1-0.5
                    c0-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0-0.3,0-0.6,0-0.9c0-5.5-4.5-10-10-10
                    c-5.5,0-10,4.5-10,10c0,0.3,0,0.6,0,0.9c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5
                    c0,0.2,0.1,0.3,0.1,0.5c0,0,0,0.1,0,0.1H1.7V15z"></path>
                    <path class="st0" d="M19.1,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M22.4,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M25.8,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M29.1,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M32.4,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M35.7,45.9h1.7v1.7h-1.7V45.9z"></path>
                    <path class="st0" d="M39,45.9h1.7v1.7H39V45.9z"></path>
                    <path class="st0" d="M39,42.6h1.7v1.7H39V42.6z"></path>
                    <path class="st0" d="M39,39.2h1.7v1.7H39V39.2z"></path>
                    <path class="st0" d="M39,35.9h1.7v1.7H39V35.9z"></path>
                    <path class="st0" d="M39,32.6h1.7v1.7H39V32.6z"></path>
                    <path class="st0" d="M13.3,6.7H15v1.7h-1.7V6.7z"></path>
                    <path class="st0" d="M13.3,3.3H15V5h-1.7V3.3z"></path>
                    <path class="st0" d="M13.3,0H15v1.7h-1.7V0z"></path>
                    <path class="st0" d="M16.6,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M19.9,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M23.3,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M26.6,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M29.9,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M33.2,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M36.6,0h1.7v1.7h-1.7V0z"></path>
                    <path class="st0" d="M39.9,0h1.7v1.7h-1.7V0z"></path>
                  </svg>`,
					title: 'Track Order History',
					text: 'Restaurant admin can keep a real-time check on the order history to compute earnings and other key metrics.'
				}
			],
			items4: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M3.1,25.1C3.8,13.8,12.9,4.8,24.2,4.4V2.7c-1.1,0-2.8,0.3-2.8-1.2c0-0.7,0.5-1.2,1.2-1.2h5.5
                      c1.6,0,1.6,2.4,0,2.4h-1.5v1.7c11,0.8,19.8,9.6,20.3,20.7c1.5,0,3.3-0.5,3.3,2.3c0,2.4-2,5-5,5.2c0.6,1.4-0.1,3-1.2,4l-9.3,8.1
                      c-1,0.9-1.8,1.8-3.7,1.8H11c0,1.3,0.3,3.2-1.2,3.2H0.9c-1.6,0-1.6-2.4,0-2.4h7.7V35.7H1.2c-1.6,0-1.6-2.4,0-2.4h8.6
                      c0.3,0,0.6,0.1,0.9,0.3l1.1-1H5.1l0,0c-2.8,0-6-2.5-5-6.5C0.5,24.9,1.7,25.1,3.1,25.1z M11.2,12.5C8,15.8,5.9,20.2,5.6,25.1h39
                      C43.6,8.4,23.2,0.6,11.2,12.5z M11,44.1h20.2c0.2,0,0.6,0,0.7,0c1.9-1.8,10.5-9,11-9.9c0.6-1.1-1.4-2.3-3.6-0.7l-7.7,6.2
                      c-0.2,0.2-0.5,0.3-0.7,0.3l0,0l-10.2,0c-3.3-0.3-3.2-5.7,0.8-5.7l0,0h5.2c0.6-0.1,0.9-1.3-0.1-1.6H14.9c-0.9,1.2-2.4,2.9-3.8,3.7
                      V44.1z M30.4,37.5l6.1-4.9h-7c0.6,2-1.2,4-3,4h-5.2l0,0c-1,0-0.7,0.9-0.6,0.9H30.4z M5.1,30.2h40.2c1.2,0,2.4-1.5,2.6-2.7H2.3
                      C2.3,29.2,3.9,30.2,5.1,30.2L5.1,30.2z"></path>
                      <path class="st0" d="M12.1,17.1c-0.8,1.3-2.9,0-2-1.3c0.6-0.9,1.6-2.3,2.7-1.4C13.7,15.4,12.7,16.3,12.1,17.1z"></path>
                      <path class="st0" d="M15.1,14.2c-1.2,1-2.7-0.8-1.5-1.8c3.3-2.7,6.8-4.1,11.1-4.1c1.6,0,1.6,2.4,0,2.4
                      C20.9,10.7,17.9,11.9,15.1,14.2z"></path>
                      <path class="st0" d="M5.1,40.9c1.2,0,1.9,0.8,1.9,0.8c2.6,2.5-1.3,6.4-3.9,3.9C1.5,43.8,2.7,40.9,5.1,40.9z M5.3,43.4
                      C4.9,43,4.5,44,5.1,44C5.4,44,5.5,43.6,5.3,43.4L5.3,43.4z"></path>
                    </g>
                  </svg>`,
					title: 'Restaurant Management',
					text: 'Admins can add and manage multiple restaurants from a single admin profile.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M40.2,0h-29C8.6,0,6.4,2.2,6.4,4.8v40.4c0,2.7,2.1,4.8,4.8,4.8h29c2.6,0,4.8-2.2,4.8-4.8V4.8
                        C45,2.2,42.9,0,40.2,0z M42.8,45.2c0,1.4-1.2,2.6-2.6,2.6h-29c-1.4,0-2.6-1.2-2.6-2.6V4.8c0-1.4,1.2-2.6,2.6-2.6h29
                        c1.4,0,2.6,1.2,2.6,2.6V45.2z M16.2,18.9h19c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1h-0.4c-0.9-2.8-3.2-4.9-6.1-5.8
                        c0.3-0.5,0.4-1,0.4-1.6c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,0.6,0.2,1.1,0.4,1.6c-2.9,0.9-5.2,3-6.1,5.8h-0.5
                        c-0.6,0-1.1,0.5-1.1,1.1C15.1,18.4,15.6,18.9,16.2,18.9z M25.7,8.1c0.6,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2
                        c-0.6,0-1.2-0.5-1.2-1.2C24.5,8.6,25.1,8.1,25.7,8.1z M25.7,12.7c3,0,5.6,1.6,6.7,4H19C20.1,14.3,22.7,12.7,25.7,12.7z M38.7,24.1
                        H12.8c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h25.9c0.6,0,1.1-0.5,1.1-1.1C39.8,24.6,39.3,24.1,38.7,24.1z M38.7,30.1H12.8
                        c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h25.9c0.6,0,1.1-0.5,1.1-1.1C39.8,30.6,39.3,30.1,38.7,30.1z M38.7,36H12.8
                        c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h25.9c0.6,0,1.1-0.5,1.1-1.1C39.8,36.5,39.3,36,38.7,36z M38.7,41.9H12.8
                        c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1h25.9c0.6,0,1.1-0.5,1.1-1.1C39.8,42.4,39.3,41.9,38.7,41.9z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Menu Management',
					text: 'Monitor menu pricing and edit menu items for multiple properties concurrently.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M25,26.6c-6.7,0-12-5.4-12-12c0-6.7,5.4-12,12-12c6.6,0,12,5.3,12,12l0,0C37.1,21.1,31.7,26.5,25,26.6L25,26.6
                      z M25,4.2L25,4.2c-5.8,0-10.4,4.7-10.3,10.4c0,0,0,0,0,0c0.1,5.7,4.7,10.2,10.3,10.2h0.1c5.7,0,10.3-4.7,10.2-10.4l0,0
                      C35.3,8.8,30.7,4.2,25,4.2L25,4.2z"></path>
                      <path class="st0" d="M25,26.6c-6.6,0-12-5.3-12-11.9C12.9,8,18.3,2.6,24.9,2.5C31.6,2.5,37,7.8,37,14.5l0,0
                      c0,6.6-5.3,12.1-11.9,12.1L25,26.6z M25,4.2L25,4.2c-5.8,0-10.4,4.7-10.3,10.4c0,5.7,4.7,10.3,10.4,10.2c5.7,0,10.3-4.7,10.2-10.4
                      l0,0C35.3,8.8,30.7,4.2,25,4.2L25,4.2z"></path>
                      <path class="st0" d="M25,26.6c-3.7,0-7.2-1.7-9.5-4.6c-0.2-0.3-0.2-0.7-0.1-1c1-1.8,2.5-3.2,4.2-4.2c0.3-0.2,0.7-0.1,0.9,0.1
                      c2.6,2,6.3,2,8.9-0.1c0.3-0.2,0.6-0.2,0.9-0.1c0.9,0.5,1.8,1.1,2.5,1.9c0.7,0.7,1.2,1.4,1.7,2.2c0.2,0.3,0.2,0.7,0,1
                      c-2.2,3-5.8,4.8-9.5,4.8H25z M17.3,21.3c3.8,4.3,10.3,4.7,14.6,0.9c0.4-0.3,0.7-0.7,1.1-1.1c-0.3-0.5-0.7-1-1.2-1.4
                      c-0.5-0.5-1-0.9-1.6-1.3c-3,2.1-7,2.1-10,0.1C18.9,19.3,18,20.2,17.3,21.3z"></path>
                      <path class="st0" d="M25,17.1c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.5C30.6,14.5,28.1,17.1,25,17.1
                      C25,17.1,25,17.1,25,17.1z M25,7.6L25,7.6c-2.1,0-3.8,1.8-3.8,3.9c0,2.1,1.8,3.8,3.9,3.8c2.1,0,3.8-1.8,3.8-3.9c0,0,0,0,0,0
                      C28.8,9.3,27.1,7.7,25,7.6L25,7.6z"></path>
                      <path class="st0" d="M7.5,47.5H0.9c-0.5,0-0.9-0.4-0.9-0.9V23.3c0-0.5,0.4-0.9,0.9-0.9h6.6c0.5,0,0.9,0.4,0.9,0.9v23.4
                      C8.3,47.1,7.9,47.5,7.5,47.5z M1.8,45.8h4.8V24.1H1.8V45.8z"></path>
                      <path class="st0" d="M26.2,45.4c-4,0-7.4-1.6-9.9-2.8c-1-0.5-2-0.9-3-1.3c-1.9-0.4-3.9-0.6-5.8-0.6c-0.5,0-0.9-0.4-0.9-0.9V26.7
                      c0-0.5,0.4-0.9,0.9-0.9h8.5c1.2,0,2.3,0.4,3.2,1.2l5.3,4.6h7.1c1.5,0,2.8,1.2,2.8,2.8v0.9l0.7-0.2c0.9-0.2,1.8-0.5,2.6-0.9
                      c0.7-0.4,1.3-0.7,1.9-1l1.6-1c3.6-2.1,6.4-3.8,8.4-1c0.5,0.7,0.7,1.6,0.5,2.4c-0.6,2.7-4.8,5.4-9.1,7.9l0,0
                      C35.4,44.4,31.8,45.4,26.2,45.4z M8.3,39.1c1.8,0,3.6,0.3,5.3,0.6c1.2,0.3,2.3,0.8,3.4,1.4c2.5,1.2,5.5,2.6,9.2,2.6
                      c5.3,0,8.6-0.9,13.7-3.8c2.7-1.6,7.8-4.5,8.3-6.7c0.1-0.4,0-0.7-0.2-1c-0.9-1.4-2.3-0.7-6.1,1.5l-1.7,1c-0.6,0.4-1.3,0.7-1.9,1.1
                      c-0.9,0.5-1.9,0.8-3,1c-0.6,0.1-1.2,0.3-1.8,0.3c-0.2,0-0.5,0-0.7-0.2c-0.2-0.2-0.3-0.4-0.3-0.7v-1.9c0-0.6-0.5-1-1.1-1h-7.4
                      c-0.2,0-0.4-0.1-0.6-0.2L18,28.3c-0.6-0.5-1.3-0.8-2-0.8H8.3V39.1z M40.3,40.6L40.3,40.6z"></path>
                      <path class="st0" d="M27.8,37.4c-3.1,0-6.1-0.2-9.2-0.6c-0.5-0.1-0.8-0.5-0.7-1c0,0,0,0,0,0c0.1-0.5,0.5-0.8,1-0.7
                      c0.1,0,8.3,1.1,14.4,0.3c0.5-0.1,0.9,0.3,1,0.7c0.1,0.5-0.3,0.9-0.7,1C31.6,37.3,29.7,37.4,27.8,37.4z"></path>
                    </g>
                  </svg>`,
					title: 'Customer Management',
					text: 'Maintain a detailed customer information index for improving service quality.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M49.1,27.9h-10c-0.5,0-0.8,0.4-0.8,0.8v0.8l-4-3c-1-0.8-2.2-1.2-3.5-1.2h-10c-1.8,0-3.3,1.5-3.3,3.3
                        c0,1.8,1.5,3.3,3.3,3.3h6.2c0.7,0,1.2,0.6,1.2,1.3c0,0.7-0.6,1.3-1.2,1.3H16.5c-0.2,0-0.4-0.1-0.5-0.2l-10-8.5
                        c-1.4-1.3-3.7-1.1-4.9,0.3c-1.2,1.4-1.1,3.5,0.2,4.8l8.3,8.4c1.2,1.2,2.6,2,4.2,2.4l13.7,3.5c0.5,0.1,1,0.2,1.5,0.2l9.3,0.8
                        c0,0,0,0,0.1,0v0.8c0,0.5,0.4,0.8,0.8,0.8h10c0.5,0,0.8-0.4,0.8-0.8V28.7C49.9,28.3,49.5,27.9,49.1,27.9z M38.3,44.6l-9.2-0.8
                        c-0.4,0-0.8-0.1-1.2-0.2l-13.7-3.5c-1.3-0.3-2.5-1-3.5-2l-8.3-8.4c-0.7-0.7-0.8-1.7-0.2-2.5c0.3-0.4,0.8-0.6,1.3-0.7
                        c0.5,0,1,0.1,1.4,0.5l10,8.6c0.5,0.4,1,0.6,1.6,0.6h10.6c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9h-6.2
                        c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7h10c0.9,0,1.8,0.3,2.5,0.8l5,3.8V44.6z M48.2,46.3h-8.3V29.6h8.3V46.3z M20.4,14
                        c0,1,0.2,1.7,0.5,2.2c0.4,0.5,0.9,0.7,1.6,0.7c0.7,0,1.2-0.3,1.6-0.7c0.4-0.5,0.5-1.2,0.5-2.2c0-1-0.2-1.7-0.6-2.2
                        c-0.3-0.5-0.9-0.7-1.5-0.7C21.1,11.1,20.4,12.1,20.4,14z M23.1,14c0.1,1.1-0.2,1.6-0.6,1.6c-0.2,0-0.4-0.1-0.4-0.4
                        C22,15,22,14.5,22,14s0.1-1,0.2-1.2c0.1-0.3,0.3-0.4,0.4-0.4C22.9,12.4,23.1,13,23.1,14z M9.4,15.3c0.1,0.2,0,0.6,0,0.9
                        C9.4,17,9.4,18,10,18.9c0.6,0.9,1.7,1.2,2.5,1.3c0.3,0.1,0.7,0.2,0.8,0.3c0.1,0.1,0.3,0.5,0.5,0.7c0.4,0.7,1,1.6,2.1,1.9
                        c0.3,0.1,0.5,0.1,0.9,0.1c0.7,0,1.4-0.3,1.9-0.5c0.3-0.1,0.7-0.3,0.9-0.3c0.2,0,0.6,0.2,0.9,0.3c0.6,0.2,1.2,0.5,1.9,0.5
                        c0.3,0,0.6-0.1,0.9-0.1c1-0.4,1.6-1.3,2.1-1.9c0.2-0.2,0.4-0.6,0.5-0.7c0.1-0.1,0.5-0.2,0.8-0.3c0.7-0.2,1.8-0.4,2.5-1.3
                        c0.6-0.9,0.6-2,0.5-2.8c0-0.3-0.1-0.7,0-0.9c0-0.1,0.3-0.4,0.5-0.6c0.5-0.6,1.2-1.4,1.2-2.5c0-1.1-0.7-2-1.2-2.5
                        c-0.2-0.2-0.4-0.5-0.5-0.6c-0.1-0.2,0-0.6,0-0.9c0.1-0.8,0.1-1.9-0.5-2.8c-0.6-0.9-1.7-1.2-2.5-1.3c-0.3-0.1-0.7-0.2-0.8-0.3
                        C25.4,3.6,25.2,3.3,25,3c-0.4-0.7-1-1.6-2.1-1.9C22.7,1,22.5,1,22.1,1c-0.7,0-1.4,0.3-1.9,0.5c-0.3,0.1-0.7,0.3-0.9,0.3
                        c-0.2,0-0.6-0.2-0.9-0.3C18,1.3,17.3,1,16.6,1c-0.3,0-0.6,0.1-0.9,0.1c-1,0.4-1.6,1.3-2.1,1.9c-0.2,0.2-0.4,0.6-0.5,0.7
                        c-0.1,0.1-0.5,0.2-0.8,0.3c-0.7,0.2-1.8,0.4-2.5,1.3c-0.6,0.9-0.6,2-0.5,2.8c0,0.3,0.1,0.7,0,0.9C9.4,9,9.1,9.4,9,9.6
                        c-0.5,0.6-1.2,1.4-1.2,2.5c0,1.1,0.7,2,1.2,2.5C9.1,14.9,9.4,15.2,9.4,15.3z M10.4,10.7c0.3-0.4,0.7-0.8,0.9-1.2
                        c0.2-0.5,0.1-1,0.1-1.6c-0.1-0.6-0.1-1.2,0.2-1.6c0.3-0.4,0.9-0.5,1.4-0.6c0.5-0.1,1-0.3,1.4-0.5C14.7,4.9,15,4.5,15.3,4
                        c0.3-0.5,0.6-1,1.1-1.2c0.1-0.1,0.2-0.1,0.3-0.1c0.4,0,0.8,0.2,1.2,0.4c0.5,0.2,1,0.4,1.5,0.4c0.5,0,1-0.2,1.5-0.4
                        c0.4-0.2,0.9-0.4,1.2-0.4c0.1,0,0.2,0,0.3,0.1C22.8,3,23.2,3.5,23.5,4c0.3,0.4,0.6,0.9,0.9,1.2c0.4,0.3,0.9,0.4,1.4,0.5
                        C26.4,5.9,27,6,27.3,6.4c0.3,0.4,0.2,1,0.2,1.6c-0.1,0.5-0.1,1.1,0.1,1.6c0.2,0.4,0.5,0.9,0.9,1.2c0.4,0.5,0.8,1,0.8,1.4
                        c0.1,0.5-0.3,1-0.7,1.4c-0.3,0.4-0.7,0.8-0.9,1.2c-0.2,0.5-0.1,1-0.1,1.6c0.1,0.6,0.1,1.2-0.2,1.6c-0.3,0.4-0.9,0.5-1.4,0.6
                        c-0.5,0.1-1,0.3-1.4,0.5c-0.4,0.3-0.7,0.7-0.9,1.2c-0.3,0.5-0.6,1-1.1,1.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.4,0-0.8-0.2-1.2-0.4
                        c-0.5-0.2-1-0.4-1.5-0.4c-0.5,0-1,0.2-1.5,0.4c-0.4,0.2-0.9,0.4-1.2,0.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.8-0.7-1.1-1.2
                        c-0.3-0.4-0.6-0.9-0.9-1.2c-0.4-0.3-0.9-0.4-1.4-0.5c-0.6-0.2-1.2-0.3-1.4-0.6c-0.3-0.4-0.2-1-0.2-1.6c0.1-0.5,0.1-1.1-0.1-1.6
                        c-0.2-0.4-0.5-0.9-0.9-1.2c-0.4-0.5-0.9-1-0.9-1.4C9.6,11.7,10,11.2,10.4,10.7z M17.7,16.8l5-9.3h-1.5l-5,9.3H17.7z M16.2,13.2
                        c0.7,0,1.3-0.2,1.6-0.7c0.4-0.5,0.5-1.2,0.5-2.2c0-1-0.2-1.7-0.6-2.2c-0.4-0.5-0.9-0.7-1.6-0.7c-1.4,0-2.1,1-2.1,2.9
                        c0,1,0.2,1.7,0.5,2.2C15,12.9,15.5,13.2,16.2,13.2z M15.8,9.1c0.1-0.3,0.3-0.4,0.4-0.4c0.4,0,0.6,0.5,0.6,1.6
                        c0,1.1-0.2,1.6-0.6,1.6c-0.1,0-0.3-0.1-0.4-0.4c-0.1-0.3-0.2-0.7-0.2-1.2S15.7,9.3,15.8,9.1z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Order Management',
					text: 'Accept or reject orders. Prioritize orders for processing and delivery based on delivery personnel availability.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M47.8,36.9c-3-0.8-7.9,1.2-10,2.2c-0.3-1.8-3.6-2.8-5.4-3.2c-3.3-0.6-9.2-3.9-9.2-3.9
                        c-2.9-2.1-7.1-0.8-9.7,0.5l-0.1-0.7c-0.1-0.3-0.4-0.5-0.7-0.5L1.1,33.3c-0.3,0.1-0.5,0.4-0.5,0.7l2.5,14.5c0,0.3,0.3,0.5,0.6,0.5
                        c0,0,0.1,0,0.1,0L15.4,47c0.3-0.1,0.5-0.4,0.5-0.7l-0.2-1.3c5,0.8,11.9,3.1,11.9,3.1c3.9,1.4,8.1,0.8,11.8-1
                        c3.1-1.6,7.5-3.2,9.6-5.3C50.6,40.3,49.9,37.4,47.8,36.9z M4.2,47.8L1.9,34.4l10.5-1.8L14.7,46L4.2,47.8z M48.2,41.1
                        c-1.9,2-6.4,3.6-9.3,5.1c-2.1,1.1-6.4,2.6-10.9,1c-0.3-0.1-7.4-2.5-12.5-3.2l-1.8-10.2c2-1,6.2-2.7,8.9-0.7
                        c0.3,0.1,6.2,3.5,9.7,4.1c1.5,0.3,5,1.4,4.4,2.7c-1.6,1.6-4.1,2.1-6.8,0.6c-2-1.2-4.5-1.5-4.6-1.5c-0.3,0-0.6,0.2-0.7,0.5
                        c0,0.3,0.2,0.6,0.5,0.6c0,0,2.3,0.3,4.2,1.4c3.2,1.8,6.2,1.1,8.2-0.8c0.7-0.3,6.8-3.4,10.1-2.6C48.8,38.3,49.2,40.1,48.2,41.1z
                        M9.1,34c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5C10.6,34.7,9.9,34,9.1,34z M9.1,35.8
                        c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3C9.4,35.7,9.3,35.8,9.1,35.8z M5.2,34.7
                        c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5S6.1,34.7,5.2,34.7z M5.2,36.5c-0.2,0-0.3-0.1-0.3-0.3
                        c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3S5.4,36.5,5.2,36.5z M43.6,21c0-1.1,0.9-2,2-2c0.3,0,0.6-0.2,0.6-0.6V7.8
                        c0-0.3-0.3-0.6-0.6-0.6c-1.1,0-2-0.9-2.1-2c0,0,0,0,0,0c0-0.3-0.3-0.6-0.6-0.6H10.9c-0.3,0-0.6,0.3-0.6,0.6c0,0,0,0,0,0
                        c0,1.1-0.9,2-2.1,2c-0.3,0-0.6,0.3-0.6,0.6v10.6c0,0.3,0.3,0.6,0.6,0.6c1.1,0,2.1,0.9,2.1,2c0,0.3,0.3,0.6,0.6,0.6H43
                        C43.3,21.6,43.6,21.3,43.6,21z M11.5,20.4c-0.2-1.3-1.3-2.3-2.6-2.6V8.4c1.3-0.2,2.4-1.3,2.6-2.6h31c0.2,1.3,1.3,2.3,2.6,2.6v9.5
                        c-1.3,0.2-2.4,1.3-2.6,2.6H11.5z M13.6,9.9c-1.8,0-3.3,1.4-3.3,3.2c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.4,3.3-3.2
                        C16.9,11.3,15.4,9.9,13.6,9.9z M13.6,15.2c-1.2,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1
                        C15.7,14.2,14.8,15.2,13.6,15.2z M27,7.2c-3.3,0-6,2.6-6,5.9c0,3.2,2.7,5.8,6,5.8c3.3,0,6-2.6,6-5.8C32.9,9.9,30.2,7.2,27,7.2z
                        M27,17.8c-2.6,0-4.8-2.1-4.8-4.7c0-2.6,2.1-4.7,4.8-4.7s4.8,2.1,4.8,4.7C31.7,15.7,29.6,17.8,27,17.8z M40.3,9.9
                        c-1.8,0-3.3,1.4-3.3,3.2c0,1.8,1.5,3.2,3.3,3.2c1.8,0,3.3-1.4,3.3-3.2C43.6,11.3,42.1,9.9,40.3,9.9z M40.3,15.2
                        c-1.2,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1C42.4,14.2,41.5,15.2,40.3,15.2z M27.3,12.5L27.3,12.5l0-1.7
                        c0.5,0.1,0.9,0.2,1.3,0.5l0.6-0.9c-0.6-0.4-1.3-0.6-2-0.7V9.3h-0.6v0.5c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.3-0.6,0.8-0.6,1.3
                        c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.9,0.5,1.6,0.7v1.8c-0.6-0.1-1.1-0.4-1.6-0.8l-0.7,0.8c0.7,0.6,1.5,0.9,2.3,1V17h0.6v-0.7
                        c0.6,0,1.2-0.2,1.5-0.6c0.4-0.3,0.6-0.8,0.6-1.3s-0.2-1-0.5-1.2C28.6,12.9,28.1,12.7,27.3,12.5z M26.7,12.4
                        c-0.4-0.1-0.6-0.2-0.7-0.3c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.4-0.2,0.7-0.2V12.4z M28,15.1
                        c-0.2,0.1-0.4,0.2-0.7,0.3v-1.7c0.4,0.1,0.6,0.2,0.8,0.4c0.1,0.1,0.2,0.3,0.2,0.5C28.3,14.8,28.2,14.9,28,15.1z M42.4,33.3
                        c0.3,0,0.5-0.2,0.6-0.4l2-8.6h3.4c0.3,0,0.6-0.3,0.6-0.6V2.6c0-0.3-0.3-0.6-0.6-0.6H5.6c0,0,0,0,0,0C5.3,2,5.1,2.2,5,2.5
                        c0,0,0,0,0,0L0.2,23c-0.1,0.3,0.1,0.6,0.4,0.7l41.6,9.6C42.3,33.3,42.3,33.3,42.4,33.3z M4.4,19.2c0.2,0,0.4,0.1,0.6,0.2v4.1
                        l-3.6-0.8L5,7.6c0,7.8,0.2,5.5-1,10.9C3.9,18.8,4.1,19.1,4.4,19.2z M6.2,3.2h41.6v19.9h0c-9.9,0-14.3,0-16.1,0c-0.8,0-1.1,0-1.1,0
                        c-1.8,0-6.1,0-15.7,0c0,0,0,0,0,0H6.2C6.2,16.6,6.2,15.1,6.2,3.2z M41,24.2l-0.5,2.1c-1.4-0.1-2.6,0.7-3.1,1.9l-17.5-4H41z
                        M8.2,24.2h6.6l22.8,5.3c0.3,0.1,0.6-0.1,0.7-0.4c0.3-1.1,1.3-1.8,2.4-1.5c0.3,0.1,0.7-0.1,0.8-0.4l0.7-2.9h1.5L41.9,32L8.2,24.2z
                        "></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Payments &amp; Commissions',
					text: 'Facility to configure various commission rates and payment modes for restaurants.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M41.1,1.6c-0.5-0.5-1.1-0.8-1.7-0.8h-7.7c-1.1,0-2,0.7-2.3,1.7H13.8L7.5,8.8v22h1.7V10H15V4.2h14.2v9.2
                    c0,1.4,1.1,2.5,2.5,2.5h2.9l-1,2.3c-0.6,1.3,0.1,2.8,1.3,3.3c0.3,0.2,0.7,0.2,1,0.2h0.7c0.9,0,1.7-0.5,2.2-1.3l3.4-6.2H50V2.5h-8
                    L41.1,1.6z M10.3,8.3l3-3v3H10.3z M37.3,19.6c-0.2,0.2-0.4,0.4-0.8,0.4h-0.7c-0.5,0-0.8-0.3-0.8-0.8c0-0.1,0-0.2,0.1-0.3l2-4.7h-5.4
                    c-0.5,0-0.8-0.3-0.8-0.8v-0.8H35v-1.7h-4.2V9.2H35V7.5h-4.2V5.8H35V4.2h-4.2V3.3c0-0.5,0.3-0.8,0.8-0.8h7.7c0.2,0,0.4,0.1,0.6,0.3
                    l0.9,0.9v9.4L37.3,19.6z M48.3,12.5h-2.5V6.7h-1.7v5.8h-1.7V4.2h5.8V12.5z"></path>
                    <path class="st0" d="M47.5,24.2h-5v-5h-1.7v5H25c-1.4,0-2.5,1.1-2.5,2.5v10c0,1.4,1.1,2.5,2.5,2.5h15.8v6.7h-20v-9.2
                    c0-1.4-1.1-2.5-2.5-2.5h-2.9l1-2.3c0.6-1.3-0.1-2.8-1.3-3.3c-0.3-0.2-0.6-0.2-0.9-0.2h-0.7c-0.9,0-1.8,0.5-2.2,1.3l-3.5,6.2H0v11.7
                    h8l0.9,0.9c0.5,0.5,1.1,0.8,1.8,0.8h7.7c1.1,0,2-0.7,2.3-1.7h21.8v-8.3h1.8c0.2,0,0.4,0.1,0.6,0.2l5.1,5.1V26.7
                    C50,25.3,48.9,24.2,47.5,24.2z M1.7,37.5h2.5v5.8h1.7v-5.8h1.7v8.3H1.7V37.5z M18.3,47.5h-7.7c-0.3,0-0.4-0.1-0.6-0.3l-0.9-0.9v-9.4
                    l3.6-6.4c0.2-0.2,0.4-0.4,0.8-0.4h0.7c0.5,0,0.8,0.3,0.8,0.8c0,0.1,0,0.2-0.1,0.3l-2,4.6h5.4c0.5,0,0.8,0.3,0.8,0.8v0.8H15v1.7h4.2
                    v1.7H15v1.7h4.2v1.7H15v1.7h4.2v0.8C19.2,47.2,18.8,47.5,18.3,47.5z M48.3,40.5l-2.3-2.3c-0.5-0.5-1.1-0.8-1.7-0.8H25
                    c-0.5,0-0.8-0.3-0.8-0.8v-10c0-0.5,0.3-0.8,0.8-0.8h22.5c0.5,0,0.8,0.3,0.8,0.8V40.5z"></path>
                    <rect x="26.7" y="27.5" class="st0" width="19.2" height="1.7"></rect>
                    <rect x="26.7" y="30.8" class="st0" width="19.2" height="1.7"></rect>
                    <rect x="26.7" y="34.2" class="st0" width="16.7" height="1.7"></rect>
                    <rect x="17.5" y="8.3" class="st0" width="9.2" height="1.7"></rect>
                    <rect x="11.7" y="12.5" class="st0" width="15" height="1.7"></rect>
                    <rect x="11.7" y="16.7" class="st0" width="15" height="1.7"></rect>
                    <rect x="11.7" y="20.8" class="st0" width="19.2" height="1.7"></rect>
                    <rect x="11.7" y="25" class="st0" width="8.3" height="1.7"></rect>
                    <rect x="23.3" y="41.7" class="st0" width="15" height="1.7"></rect>
                  </svg>`,
					title: 'Feedback Management',
					text: 'Collect, manage and respond to customer feedback received. Block offensive users if needed.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M49.1,27.9h-10c-0.5,0-0.8,0.4-0.8,0.8v0.8l-4-3c-1-0.8-2.2-1.2-3.5-1.2h-10c-1.8,0-3.3,1.5-3.3,3.3
                        c0,1.8,1.5,3.3,3.3,3.3h6.2c0.7,0,1.2,0.6,1.2,1.3c0,0.7-0.6,1.3-1.2,1.3H16.5c-0.2,0-0.4-0.1-0.5-0.2l-10-8.5
                        c-1.4-1.3-3.7-1.1-4.9,0.3c-1.2,1.4-1.1,3.5,0.2,4.8l8.3,8.4c1.2,1.2,2.6,2,4.2,2.4l13.7,3.5c0.5,0.1,1,0.2,1.5,0.2l9.3,0.8
                        c0,0,0,0,0.1,0v0.8c0,0.5,0.4,0.8,0.8,0.8h10c0.5,0,0.8-0.4,0.8-0.8V28.7C49.9,28.3,49.5,27.9,49.1,27.9z M38.3,44.6l-9.2-0.8
                        c-0.4,0-0.8-0.1-1.2-0.2l-13.7-3.5c-1.3-0.3-2.5-1-3.5-2l-8.3-8.4c-0.7-0.7-0.8-1.7-0.2-2.5c0.3-0.4,0.8-0.6,1.3-0.7
                        c0.5,0,1,0.1,1.4,0.5l10,8.6c0.5,0.4,1,0.6,1.6,0.6h10.6c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9h-6.2
                        c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7h10c0.9,0,1.8,0.3,2.5,0.8l5,3.8V44.6z M48.2,46.3h-8.3V29.6h8.3V46.3z M20.4,14
                        c0,1,0.2,1.7,0.5,2.2c0.4,0.5,0.9,0.7,1.6,0.7c0.7,0,1.2-0.3,1.6-0.7c0.4-0.5,0.5-1.2,0.5-2.2c0-1-0.2-1.7-0.6-2.2
                        c-0.3-0.5-0.9-0.7-1.5-0.7C21.1,11.1,20.4,12.1,20.4,14z M23.1,14c0.1,1.1-0.2,1.6-0.6,1.6c-0.2,0-0.4-0.1-0.4-0.4
                        C22,15,22,14.5,22,14s0.1-1,0.2-1.2c0.1-0.3,0.3-0.4,0.4-0.4C22.9,12.4,23.1,13,23.1,14z M9.4,15.3c0.1,0.2,0,0.6,0,0.9
                        C9.4,17,9.4,18,10,18.9c0.6,0.9,1.7,1.2,2.5,1.3c0.3,0.1,0.7,0.2,0.8,0.3c0.1,0.1,0.3,0.5,0.5,0.7c0.4,0.7,1,1.6,2.1,1.9
                        c0.3,0.1,0.5,0.1,0.9,0.1c0.7,0,1.4-0.3,1.9-0.5c0.3-0.1,0.7-0.3,0.9-0.3c0.2,0,0.6,0.2,0.9,0.3c0.6,0.2,1.2,0.5,1.9,0.5
                        c0.3,0,0.6-0.1,0.9-0.1c1-0.4,1.6-1.3,2.1-1.9c0.2-0.2,0.4-0.6,0.5-0.7c0.1-0.1,0.5-0.2,0.8-0.3c0.7-0.2,1.8-0.4,2.5-1.3
                        c0.6-0.9,0.6-2,0.5-2.8c0-0.3-0.1-0.7,0-0.9c0-0.1,0.3-0.4,0.5-0.6c0.5-0.6,1.2-1.4,1.2-2.5c0-1.1-0.7-2-1.2-2.5
                        c-0.2-0.2-0.4-0.5-0.5-0.6c-0.1-0.2,0-0.6,0-0.9c0.1-0.8,0.1-1.9-0.5-2.8c-0.6-0.9-1.7-1.2-2.5-1.3c-0.3-0.1-0.7-0.2-0.8-0.3
                        C25.4,3.6,25.2,3.3,25,3c-0.4-0.7-1-1.6-2.1-1.9C22.7,1,22.5,1,22.1,1c-0.7,0-1.4,0.3-1.9,0.5c-0.3,0.1-0.7,0.3-0.9,0.3
                        c-0.2,0-0.6-0.2-0.9-0.3C18,1.3,17.3,1,16.6,1c-0.3,0-0.6,0.1-0.9,0.1c-1,0.4-1.6,1.3-2.1,1.9c-0.2,0.2-0.4,0.6-0.5,0.7
                        c-0.1,0.1-0.5,0.2-0.8,0.3c-0.7,0.2-1.8,0.4-2.5,1.3c-0.6,0.9-0.6,2-0.5,2.8c0,0.3,0.1,0.7,0,0.9C9.4,9,9.1,9.4,9,9.6
                        c-0.5,0.6-1.2,1.4-1.2,2.5c0,1.1,0.7,2,1.2,2.5C9.1,14.9,9.4,15.2,9.4,15.3z M10.4,10.7c0.3-0.4,0.7-0.8,0.9-1.2
                        c0.2-0.5,0.1-1,0.1-1.6c-0.1-0.6-0.1-1.2,0.2-1.6c0.3-0.4,0.9-0.5,1.4-0.6c0.5-0.1,1-0.3,1.4-0.5C14.7,4.9,15,4.5,15.3,4
                        c0.3-0.5,0.6-1,1.1-1.2c0.1-0.1,0.2-0.1,0.3-0.1c0.4,0,0.8,0.2,1.2,0.4c0.5,0.2,1,0.4,1.5,0.4c0.5,0,1-0.2,1.5-0.4
                        c0.4-0.2,0.9-0.4,1.2-0.4c0.1,0,0.2,0,0.3,0.1C22.8,3,23.2,3.5,23.5,4c0.3,0.4,0.6,0.9,0.9,1.2c0.4,0.3,0.9,0.4,1.4,0.5
                        C26.4,5.9,27,6,27.3,6.4c0.3,0.4,0.2,1,0.2,1.6c-0.1,0.5-0.1,1.1,0.1,1.6c0.2,0.4,0.5,0.9,0.9,1.2c0.4,0.5,0.8,1,0.8,1.4
                        c0.1,0.5-0.3,1-0.7,1.4c-0.3,0.4-0.7,0.8-0.9,1.2c-0.2,0.5-0.1,1-0.1,1.6c0.1,0.6,0.1,1.2-0.2,1.6c-0.3,0.4-0.9,0.5-1.4,0.6
                        c-0.5,0.1-1,0.3-1.4,0.5c-0.4,0.3-0.7,0.7-0.9,1.2c-0.3,0.5-0.6,1-1.1,1.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.4,0-0.8-0.2-1.2-0.4
                        c-0.5-0.2-1-0.4-1.5-0.4c-0.5,0-1,0.2-1.5,0.4c-0.4,0.2-0.9,0.4-1.2,0.4c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-0.8-0.7-1.1-1.2
                        c-0.3-0.4-0.6-0.9-0.9-1.2c-0.4-0.3-0.9-0.4-1.4-0.5c-0.6-0.2-1.2-0.3-1.4-0.6c-0.3-0.4-0.2-1-0.2-1.6c0.1-0.5,0.1-1.1-0.1-1.6
                        c-0.2-0.4-0.5-0.9-0.9-1.2c-0.4-0.5-0.9-1-0.9-1.4C9.6,11.7,10,11.2,10.4,10.7z M17.7,16.8l5-9.3h-1.5l-5,9.3H17.7z M16.2,13.2
                        c0.7,0,1.3-0.2,1.6-0.7c0.4-0.5,0.5-1.2,0.5-2.2c0-1-0.2-1.7-0.6-2.2c-0.4-0.5-0.9-0.7-1.6-0.7c-1.4,0-2.1,1-2.1,2.9
                        c0,1,0.2,1.7,0.5,2.2C15,12.9,15.5,13.2,16.2,13.2z M15.8,9.1c0.1-0.3,0.3-0.4,0.4-0.4c0.4,0,0.6,0.5,0.6,1.6
                        c0,1.1-0.2,1.6-0.6,1.6c-0.1,0-0.3-0.1-0.4-0.4c-0.1-0.3-0.2-0.7-0.2-1.2S15.7,9.3,15.8,9.1z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Offers Management',
					text: 'A single window to manage all ongoing and upcoming offers. Ongoing offers can be linked to payment section for smooth checkouts.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M9.1,21.5h4.4c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4h4.4c1.5,0,2.7-1.2,2.7-2.7c0-1.4-1.1-2.6-2.5-2.7v-4.8
                        c0-3.5-2.1-6.5-5.2-7.9c-0.1-1.8-1.5-3.2-3.3-3.2c-1.8,0-3.3,1.4-3.3,3.2C11,4.8,8.9,7.8,8.9,11.3v4.8c-1.4,0.1-2.5,1.3-2.5,2.7
                        C6.4,20.3,7.6,21.5,9.1,21.5z M17.5,23.4c-1,0-1.9-0.8-1.9-1.9h3.8C19.4,22.6,18.5,23.4,17.5,23.4z M9.1,18.2c1,0,1.9-0.8,1.9-1.9
                        v-5c0-2.8,1.8-5.3,4.5-6.1l0.7-0.2V3.6c0-0.7,0.6-1.2,1.3-1.2c0.7,0,1.3,0.6,1.3,1.2v1.3l0.7,0.2C22.2,6,24,8.5,24,11.3v5
                        c0,1,0.8,1.9,1.9,1.9c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6H9.1c-0.3,0-0.6-0.3-0.6-0.6C8.5,18.5,8.8,18.2,9.1,18.2z
                        M48.2,31h-9.5c-0.4,0-0.8,0.4-0.8,0.8v0.8L34,29.7c-1-0.7-2.1-1.1-3.3-1.1h-9.5c-1.8,0-3.2,1.4-3.2,3.2c0,1.7,1.4,3.2,3.2,3.2h6
                        c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2H17c-0.2,0-0.4-0.1-0.5-0.2L6.9,29c-1.4-1.2-3.5-1.1-4.7,0.3
                        c-1.2,1.3-1.1,3.3,0.2,4.5l8,7.9c1.1,1.1,2.5,1.9,4.1,2.3l13.1,3.3c0.5,0.1,0.9,0.2,1.4,0.2l8.9,0.7c0,0,0,0,0.1,0v0.8
                        c0,0.4,0.4,0.8,0.8,0.8h9.5c0.4,0,0.8-0.4,0.8-0.8V31.8C49,31.3,48.6,31,48.2,31z M37.9,46.8L29,46.1c-0.4,0-0.8-0.1-1.1-0.2
                        l-13.1-3.3c-1.3-0.3-2.4-1-3.3-1.9l-8-8c-0.7-0.6-0.7-1.6-0.2-2.4C3.5,30,4,29.8,4.5,29.8c0.5,0,1,0.1,1.3,0.5l9.6,8.1
                        c0.4,0.4,1,0.6,1.5,0.6h10.2c1.5,0,2.8-1.2,2.8-2.8c0-1.5-1.2-2.8-2.8-2.8h-6c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6h9.5
                        c0.9,0,1.7,0.3,2.4,0.8l4.8,3.6V46.8z M47.4,48.4h-8V32.6h8V48.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Notifications Management',
					text: 'Create push notifications for new orders, dispatched orders, cancelled orders or completed orders.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M39.2,11.9c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,0.8,0.3,1.5,0.8,2.1L30,17.8
                        c-0.4-0.2-0.9-0.3-1.4-0.3c-0.4,0-0.8,0.1-1.1,0.2l-3.2-3.7c0.5-0.6,0.8-1.3,0.8-2.2c0-1.8-1.5-3.3-3.3-3.3
                        c-1.8,0-3.3,1.5-3.3,3.3c0,0.8,0.3,1.5,0.7,2l-3.4,3.9c-0.4-0.2-0.9-0.3-1.3-0.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3
                        c1.8,0,3.3-1.5,3.3-3.3c0-0.8-0.3-1.4-0.7-2l3.4-3.9c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.1-0.2l3.2,3.7
                        c-0.5,0.6-0.8,1.3-0.8,2.1c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-0.7-0.3-1.4-0.7-2l3.4-3.9c0.4,0.2,0.8,0.3,1.3,0.3
                        C37.7,15.2,39.2,13.7,39.2,11.9z M14.6,22.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
                        C16.3,21.7,15.5,22.5,14.6,22.5z M21.8,13.6c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
                        C23.5,12.8,22.8,13.6,21.8,13.6z M28.6,22.5c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
                        C30.3,21.7,29.6,22.5,28.6,22.5z M34.2,11.9c0-0.9,0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.8,1.7-1.7,1.7
                        C34.9,13.6,34.2,12.8,34.2,11.9z M28.3,42.6h1.6v-2.5h-1.6V42.6z M31.5,42.6h1.6v-8.4h-1.6V42.6z M34.7,42.6h1.6V30h-1.6V42.6z
                        M37.9,42.6h1.6V38h-1.6V42.6z M18.1,29c-4,0-7.2,3.2-7.2,7.1s3.2,7.1,7.2,7.1c4,0,7.2-3.2,7.2-7.1h-7.2V29z M23.5,37.7
                        c-0.7,2.3-2.8,4-5.4,4c-3.1,0-5.6-2.5-5.6-5.6c0-2.5,1.7-4.7,4-5.4v6.9H23.5z M19.3,28.5V35h6.6C25.9,31.4,23,28.5,19.3,28.5z
                        M41.1,4.9L36.6,0H4.5v50H46V4.9H41.1z M44.4,48.4H6V1.6h29.9l4,4.4l0.5,0.5h4.1V48.4z"></path>
                      </g>
                    </g>
                  </svg>`,
					title: 'Reporting &amp; Analytics',
					text: 'Have a pulse of the business with real-time reporting and analytics that are displayed in the form of a dashboard.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st2" d="M9.2,50c0.4,0,0.8-0.1,1.1-0.4l6.5-4.9c0.5,0.1-2.5,0.1-2,0.1l17.8,0c0.4,0,0.8,0,1.2,0c0.3,0,1.2-0.8,1.4-1.1
                      l13.1-13.1c0.8-0.8,1.2-1.9,1.2-3c-0.1-1.1-0.6-2.2-1.5-2.9l0,0c-1.6-1.3-4-1.1-5.5,0.4l-5.8,5.8c-0.5-0.3-1.2-0.5-1.8-0.5
                      c0,0,0,0,0,0c-0.1,0-2.9-0.1-3.5-0.1c-0.5,0-0.9,0-1.4,0c-2.4,0-4.9,0.1-7.1-0.5c-0.5-0.1-1-0.3-1.5-0.4c-2.4-0.7-4.7-1.4-7.6-0.4
                      c-2.5,0.9-5,2.6-7.6,5.3l-5,4.5c-0.8,0.7-0.8,1.9-0.2,2.6l6.8,7.9C8.1,49.8,8.6,50,9.2,50z M2.3,40.1l5-4.5c0,0,0,0,0,0
                      c2.5-2.6,4.7-4.1,7-4.9c2.4-0.8,4.2-0.3,6.6,0.4c0.5,0.1,1,0.3,1.5,0.4c2.5,0.6,5.2,0.6,7.6,0.5c0.5,0,0.9,0,1.4,0
                      c0.5,0,2.5,0.1,3.5,0.1c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.8,0.6,1.4c0,1.1-0.9,1.9-1.9,1.9c0,0-12.6,0-12.7,0c-0.5,0-1,0.5-1,1
                      c0.1,0.7,1,0.7,1.6,0.7h12.1c2,0,3.6-1.6,3.6-3.6c0-0.7-0.2-1.4-0.6-2l5.8-5.8c0.9-0.9,2.3-1,3.2-0.3c0.5,0.4,0.8,1,0.8,1.7
                      c0,0.6-0.2,1.3-0.7,1.7L34,42.4c-0.4,0.4-1.5,0.6-1.5,0.6h-0.3c0,0-16.1-0.1-16.3,0l-6.8,5.2c-0.1,0.1-0.2,0-0.2,0l-6.8-7.9
                      C2.2,40.3,2.2,40.2,2.3,40.1z"></path>
                      <path class="st2" d="M31.2,8.5c-3,0-5.5,2.5-5.5,5.5s2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5S34.2,8.5,31.2,8.5z M31.2,17.7
                      c-2.1,0-3.7-1.7-3.7-3.7s1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7C34.9,16.1,33.2,17.7,31.2,17.7z"></path>
                      <path class="st2" d="M43.7,7.7l-0.8-1.3c-0.6-1-1.6-1.6-2.8-1.6c-0.6,0-1.1,0.1-1.6,0.4l-0.5,0.3c-0.9-0.7-1.9-1.3-3-1.7V3.2
                      c0-1.8-1.4-3.2-3.2-3.2h-1.5c-1.8,0-3.2,1.4-3.2,3.2v0.5c-1.1,0.4-2.1,1-3,1.7l-0.5-0.3c-0.5-0.3-1-0.4-1.6-0.4
                      c-1.1,0-2.2,0.6-2.8,1.6l-0.8,1.3c-0.9,1.5-0.4,3.5,1.2,4.3l0.5,0.3c-0.1,0.6-0.1,1.2-0.1,1.7c0,0.6,0,1.2,0.1,1.7L19.8,16
                      c-1.5,0.9-2,2.8-1.2,4.3l0.8,1.3c0.6,1,1.6,1.6,2.8,1.6c0.6,0,1.1-0.1,1.6-0.4l0.5-0.3c0.9,0.7,1.9,1.3,3,1.7v0.5
                      c0,1.8,1.4,3.2,3.2,3.2h1.5c1.8,0,3.2-1.4,3.2-3.2v-0.5c1.1-0.4,2.1-1,3-1.7l0.5,0.3c0.5,0.3,1,0.4,1.6,0.4c1.1,0,2.2-0.6,2.8-1.6
                      l0.8-1.3c0.9-1.5,0.4-3.5-1.2-4.3L42,15.7c0.1-0.6,0.1-1.2,0.1-1.7c0-0.6,0-1.2-0.1-1.7l0.5-0.3C44,11.1,44.6,9.2,43.7,7.7z
                      M41.6,10.5l-0.5,0.3c-0.6,0.4-1,1.1-0.8,1.8c0.1,0.5,0.1,1,0.1,1.5c0,0.5,0,1-0.1,1.5c-0.1,0.7,0.2,1.4,0.8,1.8l0.5,0.3
                      c0.7,0.4,0.9,1.3,0.5,1.9l-0.8,1.3c-0.3,0.5-0.7,0.7-1.2,0.7c-0.2,0-0.5-0.1-0.7-0.2L39,21c-0.3-0.2-0.6-0.2-0.9-0.2
                      c-0.4,0-0.8,0.1-1.1,0.4c-0.8,0.6-1.6,1.1-2.6,1.5c-0.7,0.3-1.1,0.9-1.1,1.6v0.6c0,0.8-0.6,1.4-1.4,1.4h-1.5
                      c-0.8,0-1.4-0.6-1.4-1.4v-0.6c0-0.7-0.4-1.4-1.1-1.6c-0.9-0.4-1.8-0.9-2.6-1.5c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.6,0.1-0.9,0.2
                      l-0.5,0.3c-0.2,0.1-0.5,0.2-0.7,0.2c-0.5,0-1-0.3-1.2-0.7l-0.8-1.3c-0.4-0.7-0.2-1.5,0.5-1.9l0.5-0.3c0.6-0.4,1-1.1,0.8-1.8
                      c-0.1-0.5-0.1-1-0.1-1.5s0-1,0.1-1.5c0.1-0.7-0.2-1.4-0.8-1.8l-0.5-0.3c-0.7-0.4-0.9-1.3-0.5-1.9l0.8-1.3c0.3-0.5,0.7-0.7,1.2-0.7
                      c0.2,0,0.5,0.1,0.7,0.2L23.4,7c0.3,0.2,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.4c0.8-0.6,1.6-1.1,2.6-1.5C28.6,5.1,29,4.5,29,3.8V3.2
                      c0-0.8,0.6-1.4,1.4-1.4h1.5c0.8,0,1.4,0.6,1.4,1.4v0.6c0,0.7,0.4,1.4,1.1,1.6c0.9,0.4,1.8,0.9,2.6,1.5c0.3,0.3,0.7,0.4,1.1,0.4
                      c0.3,0,0.6-0.1,0.9-0.2l0.5-0.3c0.2-0.1,0.5-0.2,0.7-0.2c0.5,0,1,0.3,1.2,0.7l0.8,1.3C42.5,9.2,42.3,10.1,41.6,10.5z"></path>
                    </g>
                  </svg>`,
					title: 'Support',
					text: 'Support portal to reach out for immediate resolution of technical glitches, payment issues, etc.'
				}
			]
		};
	}
};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a.active .car-wash-feature-tab-icon,
#technologies_tabs li a:hover .car-wash-feature-tab-icon {
	fill: #fff;
	background: #008dd2;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
.car-wash-feature-tab-icon {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin: 0 auto 15px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a span {
	font-size: 16px;
	color: #202122;
	display: block;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.car-wash-feature-tab li {
	min-width: 180px;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li:last-child a {
	border-radius: 0 10px 10px 0;
}

.car-wash-feature-tab li a {
	border: 1px solid transparent;
	background-color: #fff;
	padding: 17px 10px;
	display: block;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 2px solid #e8e8e8;
}
a:hover {
	color: #008dd2;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border: 1px solid #008dd2;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border-bottom: 2px solid #008dd2;
}
.feature-tab li a:hover .car-wash-feature-tab-icon {
	background-color: #008dd2;
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a.active span,
.car-wash-feature-tab li a:hover span {
	color: #008dd2;
}
.car-wash-feature-tab li {
	padding: 0 !important;
}
.car-wash-feature-tab {
	border-bottom: 0;
	justify-content: center;
	display: flex;
}
@media (max-width: 776px) {
	#technologies_tabs {
		flex-wrap: wrap;
		justify-content: center;
	}
	.car-wash-feature-tab li {
		min-width: 60px;
	}
	#technologies_tabs li {
		width: fit-content;
		padding: 0;
		border-right: 1px solid #e0e2e3;
	}
	#technologies_tabs li a {
		width: fit-content;
		padding: 10px;
	}
	.car-wash-feature-tab-icon {
		width: 50px;
		height: 50px;
	}
	#technologies_tabs li a.active .car-wash-feature-tab-icon,
	#technologies_tabs li a:hover .car-wash-feature-tab-icon {
		background-color: transparent;
		fill: #0082dd;
	}
}
</style>
