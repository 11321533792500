<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Features></Features>

		<Solutions></Solutions>

		<MiniPortfolio></MiniPortfolio>

		<Partner></Partner>

		<TypeDiv></TypeDiv>

		<Techno></Techno>

		<FAQs></FAQs>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/OtherSolutions/FantasySportsAppDevelopment/Banner.vue';
import About from '../../components/OtherSolutions/FantasySportsAppDevelopment/About.vue';
import Features from '../../components/OtherSolutions/FantasySportsAppDevelopment/Features.vue';
import Solutions from '../../components/OtherSolutions/FantasySportsAppDevelopment/Solutions.vue';
import MiniPortfolio from '../../components/OtherSolutions/FantasySportsAppDevelopment/MiniPortfolio.vue';
import Techno from '../../components/OtherSolutions/FantasySportsAppDevelopment/Techno.vue';
import Partner from '../../components/OtherSolutions/FantasySportsAppDevelopment/Partner.vue';
import FAQs from '../../components/OtherSolutions/FantasySportsAppDevelopment/FAQs.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		TypeDiv,
		Partner,
		MiniPortfolio,
		Solutions,
		Techno,
		FAQs,
		Const,
		Features
	}
};
</script>

<style></style>