<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>How we Build it?</span>
				<h2 class="fxt-tech-use">Technology Stack Behind ERP Development</h2>
			</div>
			<div class="technologies_tabs">
				<div class="tab-content">
					<div
						class="tab-pane fade show active"
						id="Application"
						role="tabpanel"
						aria-labelledby="Application-tab"
					>
						<ul class="technologies_icons d-flex align-items-center justify-content-center">
							<li>
								<img
									class="lazy"
									alt=""
									src="../../../assets/images/wordpress(1).png"
									style=""
								/>
							</li>
							<li>
								<img
									class="lazy"
									alt=""
									src="../../../assets/images/php(2).png"
									style=""
								/>
							</li>
							<li>
								<img
									class="lazy"
									alt=""
									src="../../../assets/images/laravel(1).png"
									style=""
								/>
							</li>
							<li>
								<img
									class="lazy"
									alt=""
									src="../../../assets/images/codeigniter (1).png"
									style=""
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.fxt-technologies-tabs ul {
		padding: 0 !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
</style>
