<template>
	<section class="padding-60 bg-light">
		<div class="container">
			<div class="row align-items-center call-schedule-cont">
				<div class="col-12 col-md-5 col-xl-4 order-lg-1 d-flex justify-content-center">
					<div class="call_shedule_img mr-0 ml-0 mb-5 mb-md-0">
						<img
							class="lazy"
							alt="For your business digital transformation and startup consultancy talk to Oliver Baker, the co-founder of top web and mobile development company in London, Leeds, UK."
							src="../../assets/images/genixbit.png"
							style=""
						/>
					</div>
				</div>
				<div class="col-12 col-md-7 col-xl-8">
					<div class="call_shedule_text">
						<h4>We would love to hear from you</h4>
						<p>
							A thousand mile journey starts with a single step. Get in touch with us
							and we will begin a partnership that will take your business to new
							heights.
						</p>
						<ul class="fxt-ul-call">
							<li>
								<router-link
									to="/contact"
									class="btn-custome btn waves-effect waves-light head-requet-btn"
									href="javascript:;"
									>Schedule a Meeting</router-link
								>
							</li>
							<li><span class="blue-text py-4 py-lg-0">OR</span></li>
							<li>
								<span style="min-width: 80px">Call Me</span>
								<span class="call-icon-bg ml-3">
									<a
										href="tel:+441133570340"
										title="+44 1133570340"
										aria-label="call"
									>
										<svg
											viewBox="0 0 24 24"
											style="enable-background: new 0 0 24 24"
											xml:space="preserve"
										>
											<path
												fill="#FFFFFF"
												d="M20.2,14.3c-0.5-0.5-1.2-0.8-1.8-0.8c-0.7,0-1.3,0.3-1.8,0.8L14.8,16c-0.1-0.1-0.3-0.1-0.4-0.2
                           c-0.2-0.1-0.4-0.2-0.5-0.3c-1.6-1-3.1-2.3-4.4-4C8.8,10.6,8.3,9.9,8,9.2c0.4-0.4,0.9-0.8,1.3-1.2c0.2-0.2,0.3-0.3,0.5-0.5
                           c1.1-1.1,1.1-2.6,0-3.7L8.2,2.3C8,2.1,7.9,2,7.7,1.8c-0.3-0.3-0.7-0.7-1-1C6.2,0.3,5.5,0,4.9,0C4.2,0,3.6,0.3,3,0.8l0,0L1.2,2.6
                           C0.5,3.3,0.1,4.2,0,5.1c-0.1,1.6,0.3,3,0.7,4c0.9,2.3,2.2,4.5,4.1,6.9c2.4,2.8,5.2,5,8.5,6.6c1.2,0.6,2.9,1.3,4.8,1.4
                           c0.1,0,0.2,0,0.3,0c1.2,0,2.3-0.4,3.1-1.3c0,0,0,0,0,0c0.3-0.3,0.6-0.6,0.9-1c0.2-0.2,0.5-0.5,0.7-0.7c0.5-0.6,0.8-1.2,0.8-1.9
                           c0-0.7-0.3-1.3-0.8-1.8L20.2,14.3z M22.1,20C22.1,20,22.1,20,22.1,20c-0.2,0.2-0.4,0.4-0.7,0.7c-0.4,0.3-0.7,0.7-1,1.1
                           c-0.5,0.6-1.2,0.9-2,0.9c-0.1,0-0.2,0-0.2,0c-1.6-0.1-3.1-0.7-4.2-1.3c-3.1-1.5-5.7-3.6-8-6.2c-1.8-2.2-3.1-4.3-3.9-6.4
                           C1.6,7.3,1.4,6.2,1.5,5.3c0.1-0.6,0.3-1.2,0.7-1.6l1.8-1.8c0.3-0.2,0.5-0.4,0.8-0.4c0.3,0,0.6,0.2,0.8,0.4l0,0c0.3,0.3,0.6,0.6,1,1
                           C6.8,3,7,3.2,7.2,3.3l1.5,1.5c0.6,0.6,0.6,1.1,0,1.7C8.5,6.6,8.3,6.8,8.2,6.9C7.7,7.4,7.3,7.8,6.8,8.2c0,0,0,0,0,0
                           C6.3,8.7,6.4,9.2,6.5,9.5l0,0c0.4,0.9,0.9,1.8,1.7,2.8l0,0c1.5,1.8,3.1,3.3,4.8,4.3c0.2,0.1,0.4,0.3,0.7,0.4
                           c0.2,0.1,0.4,0.2,0.5,0.3c0,0,0,0,0.1,0c0.2,0.1,0.4,0.1,0.5,0.1c0.4,0,0.7-0.3,0.8-0.4l1.8-1.8c0.2-0.2,0.5-0.4,0.8-0.4
                           c0.3,0,0.6,0.2,0.8,0.4l3,3C22.7,18.8,22.7,19.4,22.1,20L22.1,20z M22.1,20"
											></path>
										</svg>
									</a>
								</span>
								<a style="min-width: 130px" href="tel:+917000830437"
									>+91-7000830437</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.order-lg-1 {
	-ms-flex-order: 1;
	order: 1;
}
.call_shedule_img {
	width: 100%;
	max-width: 300px;
	border-radius: 50%;
	float: right;
	border: 1px solid #dddfe1;
	position: relative;
	margin: 20px 25px 20px 0;
}

.call_shedule_img::before {
	content: '';
	width: 115%;
	height: 115%;
	position: absolute;
	top: 50%;
	left: 50%;
	border: 2px solid #1977cc;
	border-radius: 50%;
	transform: translate(-50%, -50%);
}
.call_shedule_img img {
	height: 300px;
	object-fit: cover;
	border-radius: 50%;
	width: 300px;
}
.call_shedule_text {
	width: 100%;
}
.call_shedule_text ul {
	display: flex;
	align-items: center;
	margin-top: 25px;
	width: 100%;
}
.call_shedule_text ul li {
	margin-right: 18px;
	font-weight: 600;
	font-size: 16px;
	display: inline-flex;
	align-items: center;
}
.call_shedule_text ul li span {
	margin-right: 15px;
}
.blue-text {
	color: #1977cc;
}
.call_shedule_text ul li span {
	margin-right: 15px;
}
.call-icon-bg a {
	width: 50px;
	height: 50px;
	overflow: hidden;
	display: inline-flex;
	background: #1977cc;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 13px;
}
.call_shedule_text h4 {
	margin-bottom: 20px;
	font-size: 28px;
	font-weight: 700;
}
p {
	font: normal 14px/22px Montserrat, sans-serif;
	color: #6a6d6f;
}

.call_shedule_text ul li a {
	color: #000;
}
.call_shedule_text ul li a:hover {
	color: #1977cc;
}
@media (max-width: 776px) {
	.call-schedule-cont {
		flex-direction: column-reverse;
	}
	.fxt-ul-call {
		flex-direction: column-reverse !important;
	}
}
</style>
