<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Services We Offer</span>
				<h2 class="fxt-process-dev">Our AngularJS Development Services</h2>
				<p>
					Advantages of engaging a professional team with structured workflows, with agile
					methodology are manifold, across the entire scope of project
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../../MobileDevelopment/AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Dynamic Web Application Development',
					text: 'Suitable for creating applications that work on desktops and mobile, through dynamic and responsive applications that are more suitable for the ‘mobile first’ era.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <path class="st0" d="M0,12.9l0,2.3l0.4,70.1l0,2.3l2.4,0l94.9-0.5l2.4,0l0-2.3l-0.4-70.1l0-2.3l-2.4,0L2.4,12.9L0,12.9z M4.8,17.5
                     l90.1-0.5L95,29.9L4.8,30.4L4.8,17.5z M68.2,20.7c-1.6,0-3,1.3-3,2.9c0,1.6,1.3,2.9,3,2.9c1.6,0,3-1.3,2.9-2.9
                     C71.2,22,69.9,20.7,68.2,20.7z M77.7,20.6c-1.6,0-3,1.3-2.9,2.9c0,1.6,1.3,2.9,3,2.9c1.6,0,3-1.3,2.9-2.9
                     C80.7,21.9,79.4,20.6,77.7,20.6z M87.2,20.6c-1.6,0-3,1.3-3,2.9c0,1.6,1.3,2.9,3,2.9c1.6,0,3-1.3,2.9-2.9
                     C90.2,21.9,88.8,20.6,87.2,20.6z M4.9,35L95,34.6l0.2,47.9L5.1,82.9L4.9,35z M57.4,39.5L38.6,74.6l4.2,2.2l18.8-35.2L57.4,39.5z
                     M32,45.9l-13,9.4L18,56l0,1.2l0,2.3l0,1.2l1,0.7l13.1,9.3l2.8-3.8l-12.1-8.6l12-8.7L32,45.9z M68,45.7l-2.8,3.8L77.3,58l-12,8.7
                     l2.8,3.7l13-9.4l1-0.7l0-1.2l0-2.3l0-1.2l-1-0.7L68,45.7z"></path>
                  </svg>
               `
				},
				{
					title: 'Single Page App Development',
					text: 'Peer and community supported knowledge base in building single page applications help improve the outcomes. Simple yet effective, single page apps have carved a separate identity for themselves.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M14.5,30.1l-4.7,4.7c-0.6,0.6-0.6,1.6,0,2.2l4.7,4.7l2.2-2.2l-3.6-3.6l3.6-3.6L14.5,30.1z M29.2,41.7l4.7-4.7
                           c0.6-0.6,0.6-1.6,0-2.2l-4.7-4.7L27,32.4l3.6,3.6L27,39.5L29.2,41.7z M22,30.8l-3.1,9.4l3,1l3.1-9.4L22,30.8z M96.9,0H50
                           c-1.7,0-3.1,1.4-3.1,3.1v93.7c0,1.7,1.4,3.1,3.1,3.1h46.9c1.7,0,3.1-1.4,3.1-3.1V3.1C100,1.4,98.6,0,96.9,0z M96.9,96.9H50V3.1
                           h46.9V96.9z M54.7,78.1h6.3V75h-4.7V15.6h4.7v-3.1h-6.3c-0.9,0-1.6,0.7-1.6,1.6v62.5C53.1,77.4,53.8,78.1,54.7,78.1z M90.6,75
                           h-4.7v3.1h6.2c0.9,0,1.6-0.7,1.6-1.6V14.1c0-0.9-0.7-1.6-1.6-1.6h-6.2v3.1h4.7V75z M82.8,12.5H64.1v3.1h18.7V12.5z M73.4,81.2
                           c-3.4,0-6.3,2.8-6.3,6.3c0,3.4,2.8,6.2,6.3,6.2c3.4,0,6.3-2.8,6.3-6.2C79.7,84.1,76.9,81.2,73.4,81.2z M73.4,90.6
                           c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1C76.6,89.2,75.2,90.6,73.4,90.6z M81.2,6.3H65.6v3.1h15.6
                           V6.3z M64.1,78.1h18.7V75H64.1V78.1z M0,10.9v78.1c0,0.9,0.7,1.6,1.6,1.6h42.2v-3.1H3.1V25h40.6v-3.1H3.1v-9.4h40.6V9.4H1.6
                           C0.7,9.4,0,10.1,0,10.9z M9.4,18.7v-3.1H6.3v3.1H9.4z M15.6,18.7v-3.1h-3.1v3.1H15.6z M21.9,18.7v-3.1h-3.1v3.1H21.9z M9.4,50
                           h12.5v-3.1H9.4V50z M25,50h18.7v-3.1H25V50z M34.4,56.3h9.4v-3.1h-9.4V56.3z M31.3,53.1H9.4v3.1h21.9V53.1z M9.4,62.5h12.5v-3.1
                           H9.4V62.5z M25,62.5h18.7v-3.1H25V62.5z M34.4,68.8h9.4v-3.1h-9.4V68.8z M31.3,65.6H9.4v3.1h21.9V65.6z M21.9,78.1H9.4v3.1h12.5
                           V78.1z M31.3,81.2v-3.1H25v3.1H31.3z M59.4,70.3c0,0.9,0.7,1.6,1.6,1.6h9.4c0.9,0,1.6-0.7,1.6-1.6v-9.4c0-0.9-0.7-1.6-1.6-1.6
                           h-9.4c-0.9,0-1.6,0.7-1.6,1.6V70.3z M62.5,62.5h6.2v6.3h-6.2V62.5z M85.9,59.4h-9.4c-0.9,0-1.6,0.7-1.6,1.6v9.4
                           c0,0.9,0.7,1.6,1.6,1.6h9.4c0.9,0,1.6-0.7,1.6-1.6v-9.4C87.5,60.1,86.8,59.4,85.9,59.4z M84.4,68.8h-6.3v-6.3h6.3V68.8z
                           M59.4,20.3v18.8c0,0.9,0.7,1.6,1.6,1.6h25c0.9,0,1.6-0.7,1.6-1.6V20.3c0-0.9-0.7-1.6-1.6-1.6h-25C60.1,18.7,59.4,19.5,59.4,20.3z
                           M62.5,21.9h21.9v15.6H62.5V21.9z M87.5,45.3c0-0.9-0.7-1.6-1.6-1.6h-25c-0.9,0-1.6,0.7-1.6,1.6v9.4c0,0.9,0.7,1.6,1.6,1.6h25
                           c0.9,0,1.6-0.7,1.6-1.6V45.3z M84.4,53.1H62.5v-6.2h21.9V53.1z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'AngularJS App Upgrade and Maintenance',
					text: 'Complete end to end services for upgrade of apps and maintenance support offer clients freedom from woes. Our support goes beyond delivery with extended maintenance improving performance.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M39.4,6.9H24.6c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h14.8c0.9,0,1.6-0.6,1.6-1.6
                           C41,7.5,40.4,6.9,39.4,6.9z M48.8,6.9H46c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.8,1.6,1.6,1.6h2.8c0.9,0,1.6-0.6,1.6-1.6
                           C50.3,7.5,49.7,6.9,48.8,6.9z M30.2,94.5h10c0.9,0,1.6-0.6,1.6-1.6s-0.8-1.6-1.6-1.6h-10c-0.9,0-1.6,0.6-1.6,1.6
                           S29.3,94.5,30.2,94.5z M95.4,45.2l-7.2-0.8c-0.5-1.3-0.9-2.5-1.6-3.7l4.5-5.6c0.2-0.2,0.2-1.1-0.5-1.7l-6.7-6.7
                           c-0.6-0.6-1.4-0.6-2-0.2l-5.6,4.5c-1.2-0.6-2.5-1.1-3.7-1.6l-0.8-7.2c-0.2-0.8-0.8-1.4-1.6-1.4h-3.3V6.6C67.1,3,64.3,0,60.7,0h-51
                           C6.1,0,3.2,3,3.2,6.6v87c0,3.4,3,6.4,6.5,6.4h51c3.6,0,6.5-3,6.5-6.6V82.8h3.3c0.8,0,1.4-0.6,1.6-1.4l0.8-7.2
                           c1.2-0.5,2.5-0.9,3.7-1.6l5.6,4.5c0.6,0.5,1.6,0.5,2-0.2l6.8-6.9c0.6-0.6,0.6-1.4,0.2-2l-4.5-5.6c0.6-1.2,1.1-2.5,1.6-3.8l7.2-0.8
                           c0.8-0.2,1.4-0.8,1.4-1.6v-9.7C96.8,45.9,96.2,45.3,95.4,45.2z M6.3,6.6c0-1.9,1.6-3.4,3.4-3.4h51c1.9,0,3.4,1.6,3.4,3.4v7.2H6.3
                           V6.6z M64,93.6c0,1.7-1.4,3.3-3.3,3.3h-51c-1.9,0-3.4-1.6-3.4-3.4v-4.4H64V93.6z M64.2,85.9H6.3v-9.1h16.6c0.9,0,1.6-0.6,1.6-1.6
                           c0-0.9-0.6-1.6-1.6-1.6H6.3v-4.7h11.8c0.9,0,1.6-0.6,1.6-1.6c0-0.9-0.6-1.6-1.6-1.6H6.3v-4.7h5.3c0.9,0,1.6-0.6,1.6-1.6
                           c0-0.9-0.6-1.6-1.6-1.6H6.3V16.9H64v3.9h-3.3c-0.8,0-1.4,0.6-1.6,1.4l-0.6,7.2c-1.2,0.5-2.5,0.9-3.7,1.6l-5.6-4.5
                           c-0.6-0.5-1.6-0.5-2,0.2l-6.8,6.9c-0.6,0.6-0.6,1.4-0.2,2l4.5,5.6c-0.6,1.2-1.1,2.5-1.6,3.8L36,45.6c-0.8,0.2-1.4,0.8-1.4,1.6v9.7
                           c0,0.8,0.6,1.4,1.4,1.6l7.2,0.8c0.5,1.2,0.9,2.5,1.6,3.8l-4.5,5.6c-0.5,0.6-0.5,1.6,0.2,2l6.8,6.9c0.6,0.6,1.4,0.6,2,0.2l5.6-4.5
                           c1.2,0.6,2.5,1.1,3.7,1.6l0.8,7.2c0.2,0.8,0.8,1.4,1.6,1.4h3.3V85.9z M93.4,55.3l-6.8,0.6c-0.6,0-1.2,0.5-1.4,1.1
                           c-0.5,1.7-1.1,3.4-2,5c-0.3,0.6-0.3,1.2,0.2,1.7l4.4,5.3l-4.8,4.8l-5.3-4.4c-0.5-0.5-1.2-0.5-1.7-0.2c-1.6,0.9-3.3,1.6-5,2
                           c-0.6,0.2-1.1,0.8-1.1,1.4L69,79.7h-6.7l-0.6-6.9c0-0.6-0.5-1.2-1.1-1.4c-1.7-0.5-3.4-1.1-5-2c-0.6-0.3-1.2-0.3-1.7,0.2l-5.3,4.4
                           l-5-4.7l4.4-5.3c0.5-0.5,0.5-1.3,0.2-1.7c-0.9-1.6-1.6-3.3-2-5c-0.2-0.6-0.8-1.1-1.4-1.1l-6.8-0.6v-7l6.8-0.6
                           c0.6,0,1.2-0.5,1.4-1.1c0.5-1.7,1.1-3.4,2-5c0.3-0.6,0.3-1.3-0.2-1.7l-4.4-5.5l4.8-4.8l5.3,4.4c0.5,0.5,1.2,0.5,1.7,0.2
                           c1.6-0.9,3.3-1.6,5-2c0.6-0.2,1.1-0.8,1.1-1.4l0.6-6.7H69l0.6,6.7c0,0.6,0.5,1.3,1.1,1.4c1.7,0.5,3.4,1.1,5,2
                           c0.6,0.3,1.2,0.3,1.7-0.2l5.3-4.4l4.8,4.8l-4.2,5.3c-0.5,0.5-0.5,1.3-0.2,1.7c0.9,1.6,1.6,3.3,2,5c0.2,0.6,0.8,1.1,1.4,1.1
                           l6.8,0.8V55.3z M65.6,36.4c-8.4,0-15.4,6.9-15.4,15.5c0,8.6,6.8,15.5,15.4,15.5C74,67.3,81,60.5,81,51.9
                           C81,43.3,74.1,36.4,65.6,36.4z M65.6,64.2c-6.7,0-12.3-5.5-12.3-12.3c0-6.7,5.4-12.3,12.3-12.3c6.8,0,12.3,5.5,12.3,12.3
                           C77.8,58.6,72.4,64.2,65.6,64.2z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'Enterprise App Interface and Development',
					text: 'Intuitive, clean and appealing interface for customer facing applications make a huge difference to the user experience and our teams leverage the full features for interesting interfaces.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M84,16.7v-6.3C84,4.7,79.2,0,73.4,0H26.6C20.8,0,16,4.7,16,10.4v79.2c0,5.8,4.8,10.4,10.6,10.4h46.8
                           c5.9,0,10.6-4.7,10.6-10.4V27.1c0-1.1-1-2.1-2.1-2.1c-1.2,0-2.1,0.9-2.1,2.1v62.5c0,3.5-2.9,6.3-6.4,6.3H26.6
                           c-3.5,0-6.4-2.8-6.4-6.3V10.4c0-3.5,2.9-6.3,6.4-6.3h46.8c3.5,0,6.4,2.8,6.4,6.3v4.2h-51c-1.2,0-2.1,0.9-2.1,2.1
                           c0,1.2,1,2.1,2.1,2.1h53.1C83.1,18.7,84,17.8,84,16.7z M26.6,83.3c0,1.1,1,2.1,2.1,2.1h42.5c1.2,0,2.1-0.9,2.1-2.1
                           c0-1.2-1-2.1-2.1-2.1H28.7C27.6,81.2,26.6,82.2,26.6,83.3z M73.4,29.2c0-2.3-1.9-4.2-4.3-4.2H30.9c-2.3,0-4.3,1.9-4.3,4.2v4.2
                           c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1v-4.2h38.3V50H30.9v-6.2h31.9c1.2,0,2.1-0.9,2.1-2.1c0-1.1-1-2.1-2.1-2.1h-34
                           c-1.2,0-2.1,0.9-2.1,2.1V50c0,2.3,1.9,4.2,4.3,4.2h38.3c2.3,0,4.3-1.9,4.3-4.2V29.2z M45.1,58.9c-0.8-0.8-2.2-0.8-3,0l-6.4,6.2
                           c-0.8,0.8-0.8,2.1,0,2.9l6.4,6.2c0.8,0.8,2.2,0.8,3-0.1c0.8-0.8,0.8-2.1,0-2.9l-4.9-4.8l4.9-4.8C46,61.1,46,59.8,45.1,58.9z
                           M54.9,74.4c0.8,0.8,2.2,0.8,3,0l6.4-6.2c0.8-0.8,0.8-2.1,0-2.9l-6.4-6.2c-0.8-0.8-2.2-0.8-3,0.1c-0.8,0.8-0.8,2.1,0,2.9l4.9,4.8
                           l-4.9,4.8C54,72.3,54,73.6,54.9,74.4z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'Application Migration Services',
					text: 'Best practices to simplify the migration processes from legacy to the latest, resulting in a seamless smooth migration that is structured and planned down to the last detail.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M27.2,18h-8.6c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6h8.6c0.9,0,1.6-0.6,1.6-1.6
                           C28.8,18.6,28,18,27.2,18z M22.8,84.3c2.5,0,4.5-2,4.5-4.5c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5
                           C18.3,82.3,20.3,84.3,22.8,84.3z M22.8,78.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4
                           C21.4,79.1,22,78.4,22.8,78.4z M94.5,11.6H59.7c-3.1,0-5.5,2.5-5.5,5.6c0,0.2,0,0.3,0,0.3v18.4h-8.4V17.2c0-3.1-2.5-5.6-5.5-5.6
                           H5.5c-3.1,0-5.5,2.5-5.5,5.6C0,17.1,0,31,0,82.8c0,3.1,2.5,5.4,5.5,5.4h34.7c3.1,0,5.5-2.5,5.5-5.4V64h8.4c0,0.2,0,0.3,0,0.3v18.7
                           c0,3.1,2.5,5.4,5.5,5.4h35c3.1,0,5.5-2.5,5.5-5.4c0,0,0-13.8,0-65.7C100,14.1,97.5,11.6,94.5,11.6z M3.1,17.2
                           c0-1.4,1.1-2.5,2.3-2.5h34.7c1.4,0,2.3,1.1,2.3,2.5v8.5H3.1V17.2z M42.7,82.8c0,1.4-1.1,2.3-2.3,2.3H5.5c-1.4,0-2.3-1.1-2.3-2.3
                           v-8.4h39.5V82.8z M42.7,71.3H3.1V28.7h39.5v22.7H26.7v-0.3c0-1.4-1.7-2-2.7-1.1l-6.7,6.7c-0.3,0.3-0.5,0.6-0.5,1.1
                           c0,0.5,0.2,0.8,0.5,1.1l6.7,6.7c0.9,0.9,2.7,0.3,2.7-1.1v-0.3h15.9V71.3z M54.2,60.9H25.2c-0.3,0-0.8,0.2-0.9,0.3l-3.4-3.4
                           l3.4-3.4c0.3,0.2,0.6,0.3,0.9,0.3h29.1V60.9z M54.2,51.6h-8.4v-3.1h8.4C54.2,49.2,54.2,51.7,54.2,51.6z M45.8,45.2V39
                           c31.4,0,29.5,0.2,30.2-0.3l3.4,3.4l-3.4,3.4C75.2,44.9,76.2,45.3,45.8,45.2z M96.9,82.8c0,1.4-1.1,2.3-2.3,2.3H59.7
                           c-1.4,0-2.3-1.1-2.3-2.3v-8.4h39.5V82.8z M96.9,71.3H57.3v-23h15.9v0.3c0,1.4,1.7,2,2.7,1.1l6.7-6.7c0.3-0.3,0.5-0.6,0.5-1.1
                           c0-0.5-0.2-0.8-0.5-1.1l-6.7-6.7c-0.5-0.5-1.1-0.6-1.7-0.3c-0.6,0.3-0.9,0.8-0.9,1.4v0.3H57.3v-6.7h39.5V71.3z M96.9,25.9H57.3
                           v-8.5c0-1.4,1.1-2.5,2.3-2.5h34.8c1.4,0,2.3,1.1,2.3,2.5V25.9z M81.3,18h-8.4c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.8,1.6,1.6,1.6h8.4
                           c0.9,0,1.6-0.6,1.6-1.6C82.8,18.6,82.2,18,81.3,18z M77.2,84.3c2.5,0,4.5-2,4.5-4.5c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5
                           C72.7,82.3,74.7,84.3,77.2,84.3z M77.2,78.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4
                           C75.6,79.1,76.4,78.4,77.2,78.4z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'User Interactive Module Development',
					text: 'Development of modules that engage users with uncluttered and simplified interactive interfaces. Minimalism is the key to engagement in an era where frills are distractive.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M13,0v51.4c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V4h74.6v70.2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V0H13z M63.6,82.2
                           H42.1c-1.1,0-1.9,0.9-1.9,2c0,1.1,0.9,2,2,2h21.5c1.1,0,2-0.9,2-2C65.6,83.1,64.7,82.2,63.6,82.2z M68,75.5c0-1.1-0.9-2-2-2h-5.9
                           v-35H84v12.9c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V34.5H56.2v42.9h9.8C67.1,77.5,68,76.6,68,75.5z M56.8,28.9c0.4,0.4,0.9,0.6,1.4,0.6
                           H86c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H58.2c-1.1,0-2,0.9-2,1.9C56.2,28,56.4,28.5,56.8,28.9z M58.2,12.7H86c1.1,0,2-0.9,2-2
                           c0-1.1-0.9-2-2-2H58.2c-1.1,0-2,0.9-2,2C56.3,11.8,57.2,12.7,58.2,12.7z M56.8,20.5c0.4,0.4,0.9,0.6,1.4,0.6H86c1.1,0,2-0.9,2-2
                           c0-1.1-0.9-2-2-2H58.2c-1.1,0-2,0.9-2,1.9C56.2,19.6,56.4,20.1,56.8,20.5z M22.1,54.2h27.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H22.1
                           c-1.1,0-2,0.9-2,2C20.1,53.3,21,54.2,22.1,54.2z M22.1,38.5h27.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H22.1c-1.1,0-2,0.9-2,2
                           C20.1,37.6,21,38.5,22.1,38.5z M22.1,46.3h27.8c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2H22.1c-1.1,0-2,0.9-2,2
                           C20.1,45.4,21,46.3,22.1,46.3z M49.8,58.1h-21c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h21c1.1,0,2-0.9,2-2C51.8,59,50.9,58.1,49.8,58.1z
                           M51.8,8.7H20.1v20.8h31.7V8.7z M47.8,25.6H24V12.7h23.8V25.6z M80.7,53.7c-4.8,0-8.6,3.9-8.6,8.6c0,2.3,0.9,4.5,2.5,6.1
                           c1.7,1.6,3.8,2.5,6.1,2.5c2.3,0,4.4-0.9,6.1-2.5c1.6-1.7,2.5-3.8,2.5-6.1C89.4,57.5,85.5,53.7,80.7,53.7z M80.7,67.1
                           c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7C85.4,65,83.4,67.1,80.7,67.1z M83.1,72.1h-4.8
                           c-6.4,0-11.6,5.2-11.6,11.6V100h27.8V83.8c0-3.1-1.2-6-3.3-8.2C89.1,73.4,86.2,72.1,83.1,72.1z M90.8,96h-20V83.7
                           c0-4.2,3.4-7.6,7.6-7.6h4.8c4.2,0,7.6,3.4,7.6,7.6V96z M16.6,70.6c4.7,0,8.6-3.7,8.6-8.2c0-4.5-3.8-8.2-8.6-8.2
                           c-4.7,0-8.6,3.7-8.6,8.2C8,66.9,11.9,70.6,16.6,70.6z M16.6,58.2c2.5,0,4.6,2,4.6,4.3c0,2.4-2.1,4.3-4.6,4.3
                           c-2.5,0-4.6-1.9-4.6-4.3C12,60.1,14.1,58.2,16.6,58.2z M45.6,74.8c0.2-1.4-0.2-2.8-1-3.7l0,0c-0.8-0.9-2.1-1.5-3.7-1.5
                           c-1.8,0-3.7,0.7-5.3,2.1l0,0l-4.5,4.3l-4.8-2.1l-0.1-0.1l-0.1-0.1c-1-0.5-2-0.8-3.1-0.8H11c-3.5,0-6.4,3-6.4,6.7v20.1h24.6V86.2
                           l5,1.6l8.3-7.7C44.3,78.7,45.4,76.8,45.6,74.8z M39.9,76.9L39.9,76.9l-6.9,6.5l-7.9-2.4v14.5H8.5v-16c0-1.4,1.1-2.7,2.5-2.7H23
                           c0.4,0,0.8,0.1,1.2,0.4l0,0l0.4,0.2h0c0.1,0,0.1,0,0.2,0.1l7.2,3.2l6.3-6c0.8-0.7,1.8-1.1,2.6-1.1c0.4,0,0.6,0.1,0.6,0.1l0,0
                           c0,0.1,0.1,0.3,0.1,0.7C41.5,75.2,40.8,76.3,39.9,76.9z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'Custom AngularJS Development',
					text: 'Customized solutions to meet specific needs without the need for improvisations of rigid solutions. High levels of customization ensure that solutions are perfectly aligned with business and technical requirements.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M22.3,49.5l10.9,11c0.6,0.6,1.6,0.6,2.2,0c0.6-0.6,0.6-1.6,0-2.2l-9.8-9.9l9.8-9.9c0.6-0.6,0.6-1.6,0-2.2
                           c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l-10.9,11C21.7,47.9,21.7,48.9,22.3,49.5C22.3,49.5,22.3,49.5,22.3,49.5z M41.5,67.2
                           c0.8,0.4,1.7,0.1,2.1-0.7c0,0,0-0.1,0.1-0.1l15.6-34.6c0.4-0.8,0-1.7-0.8-2.1c0,0,0,0,0,0c-0.8-0.4-1.7,0-2.1,0.8c0,0,0,0,0,0
                           L40.8,65.1C40.4,65.8,40.7,66.8,41.5,67.2z M74.4,48.4l-9.8,9.9c-0.6,0.6-0.6,1.6,0,2.2c0.6,0.6,1.6,0.6,2.2,0l10.9-11
                           c0.6-0.6,0.6-1.6,0-2.2c0,0,0,0,0,0l-10.9-11c-0.6-0.6-1.6-0.6-2.2,0c-0.6,0.6-0.6,1.6,0,2.2L74.4,48.4z M95.3,6H4.7
                           C2.1,6,0,8.1,0,10.7v66c0,2.6,2.1,4.7,4.7,4.7h43.8v9.4H24.2c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h51.6
                           c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6H51.6v-9.4h43.8c2.6,0,4.7-2.1,4.7-4.7v-66C100,8.1,97.9,6,95.3,6z M96.9,76.7
                           c0,0.9-0.7,1.6-1.6,1.6H4.7c-0.9,0-1.6-0.7-1.6-1.6v-55h93.8V76.7z M96.9,18.6H3.1v-7.9c0-0.9,0.7-1.6,1.6-1.6h90.6
                           c0.9,0,1.6,0.7,1.6,1.6V18.6z M90.6,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C92.2,13,91.5,12.3,90.6,12.3z M84.4,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C85.9,13,85.2,12.3,84.4,12.3z M78.1,12.3c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
                           C79.7,13,79,12.3,78.1,12.3z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'PHP Development in AngularJS',
					text: 'Powerful PHP scripting, interpreted by web server and embedded in HTML ensures that programming is simplified, ensuring that the whole application works smoothly and seamlessly.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M95,0H5C2.2,0,0,2.2,0,5v90c0,2.8,2.2,5,5,5h90c2.8,0,5-2.2,5-5V5C100,2.2,97.8,0,95,0z M96.7,95
                           c0,0.9-0.7,1.7-1.7,1.7H5c-0.9,0-1.7-0.7-1.7-1.7V16.7h93.3V95z M96.7,13.3H3.3V5c0-0.9,0.7-1.7,1.7-1.7h90c0.9,0,1.7,0.7,1.7,1.7
                           V13.3z M8.3,6.7c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7C9.3,10,10,9.3,10,8.3C10,7.4,9.3,6.7,8.3,6.7z M15,6.7
                           c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7C16.7,7.4,15.9,6.7,15,6.7z M21.7,6.7
                           c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7C23.3,7.4,22.6,6.7,21.7,6.7z M26.7,68.3
                           c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7V60h5c2.8,0,5-2.2,5-5v-3.3c0-2.8-2.2-5-5-5h-6.7c-0.9,0-1.7,0.7-1.7,1.7V68.3z M30,50h5
                           c0.9,0,1.7,0.7,1.7,1.7V55c0,0.9-0.7,1.7-1.7,1.7h-5V50z M61.7,70c0.9,0,1.7-0.7,1.7-1.7V60h5c2.8,0,5-2.2,5-5v-3.3
                           c0-2.8-2.2-5-5-5h-6.7c-0.9,0-1.7,0.7-1.7,1.7v20C60,69.3,60.7,70,61.7,70z M63.3,50h5c0.9,0,1.7,0.7,1.7,1.7V55
                           c0,0.9-0.7,1.7-1.7,1.7h-5V50z M45,70c0.9,0,1.7-0.7,1.7-1.7V60h5c0.9,0,1.7,0.8,1.7,1.7v6.7c0,0.9,0.7,1.7,1.7,1.7
                           s1.7-0.7,1.7-1.7v-6.7c0-2.8-2.2-5-5-5h-5v-8.3c0-0.9-0.7-1.7-1.7-1.7c-0.9,0-1.7,0.7-1.7,1.7v20C43.3,69.3,44.1,70,45,70z
                           M18.3,62.4v6c0,3.7,3,6.7,6.7,6.7c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-1.8,0-3.3-1.5-3.3-3.3v-6.7
                           c0-0.4-0.2-0.9-0.5-1.2l-3.8-3.8l3.8-3.8c0.3-0.3,0.5-0.7,0.5-1.2V45c0-1.8,1.5-3.3,3.3-3.3c0.9,0,1.7-0.7,1.7-1.7
                           c0-0.9-0.7-1.7-1.7-1.7c-3.7,0-6.7,3-6.7,6.7v6l-4.5,4.5c-0.7,0.7-0.7,1.7,0,2.4L18.3,62.4z M75,41.7c1.8,0,3.3,1.5,3.3,3.3l0,6.7
                           c0,0.4,0.2,0.9,0.5,1.2l3.8,3.8l-3.8,3.8c-0.3,0.3-0.5,0.7-0.5,1.2v6.7c0,1.8-1.5,3.3-3.3,3.3c-0.9,0-1.7,0.7-1.7,1.7
                           c0,0.9,0.7,1.7,1.7,1.7c3.7,0,6.7-3,6.7-6.7v-6l4.5-4.5c0.7-0.7,0.7-1.7,0-2.4L81.7,51l0-6c0-3.7-3-6.7-6.7-6.7
                           c-0.9,0-1.7,0.7-1.7,1.7C73.3,40.9,74.1,41.7,75,41.7z"></path>
                        </g>
                     </g>
                  </svg>
               `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
