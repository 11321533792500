<template>
	<section class="contact-form-main">
		<div class="container">
			<div class="row">
				<div
					class="col-lg-8 col-md-7 col-sm-12 col-12 fxt-contact-from"
					style="margin-top: -100px; background-color: transparent"
				>
					<div class="contact-form-box bg-white rounded10 box-shadow">
						<ContactFormDiv></ContactFormDiv>
					</div>
				</div>
				<div
					class="col-lg-4 col-md-5 col-sm-12 col-12 fxt-contact-img"
					style="margin-top: -120px; background-color: transparent"
				>
					<div class="schedule-meeting-main">
						<div class="schedule-meeting-box rounded10">
							<div class="schedule-meeting-img">
								<img
									class="lazy"
									alt="Oliver Baker- Author"
									src="../../assets/images/genixbit.png"
									style=""
								/>
							</div>
							<div class="schedule-meeting-content text-center">
								<div class="schedule-meeting-content-description">
									<h3>So you've Heard our Story,</h3>
									<h4>Tell us Yours!</h4>
									<p>
										We've several ways for you to contact us, so drop us a
										message or give us a call.
									</p>
									<p>
										If you have a question, an idea, are a problem to solve, we
										can help, so get in touch!
									</p>
								</div>
								<a
									href="javascript:;"
									class="btn-custome btn-re btn waves-effect waves-light head-requet-btn"
									>Schedule a Meeting</a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ContactFormDiv from '../AssetComponents/ContactFormDiv.vue';
export default {
	components: {
		ContactFormDiv
	},
	mounted() {
		var input = document.querySelector("#phone");
		window.intlTelInput(input, {
				initialCountry:"in",
		});
		console.log("done",input)
	}
};
</script>

<style>
@media (max-width: 776px) {
	.fxt-contact-img {
		margin-top: 0px !important;
	}
	.fxt-contact-from {
		margin-top: -55px !important;
	}
}
@media (max-width: 400px) {
	.fxt-contact-from {
		margin-top: 0px !important;
	}
}
</style>
