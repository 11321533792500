<template>
	<section class="padding-60 bg-white web-app-sev-lang">
		<div class="container">
			<div class="title">
				<span>Frameworks</span>
				<h2>Our suite of web development frameworks</h2>
				<p>
					Genixbit has a large pool of developers who are capable of web development using
					several frameworks.
				</p>
			</div>
		</div>
		<div class="web-app-sev-lang-collapse">
			<div class="container-fluid">
				<div class="row tab_wrapper first_tab _side">
					<div class="col-12 bg-light pr-0 left-col">
						<ul class="tab_list">
							<li class="fxt-tech-link active" id="php-technologies">PHP Development</li>
							<li class="fxt-tech-link" id="wordpress-technologies">WordPress Development</li>
							<li class="fxt-tech-link" id="codeigniter-technologies">Codeigniter Development</li>
							<li class="fxt-tech-link" id="laravel-technologies">Laravel Development</li>
							<li class="fxt-tech-link" id="angular-technologies">AngularJS Development</li>
							<li class="fxt-tech-link" id="node-technologies">NodeJS Development</li>
						</ul>
					</div>
					<div class="col-12 right-col d-flex align-items-center">
						<div class="content_wrapper">
							<div data-toggle="php-technologies" class="fxt-tab-content active">
								<img src="../../../assets/images/php.svg" alt="Php Website Development" />
								<p>
									Ever since it was launched in 1994, PHP has become the primary language for web
									development. Being an open-source and server-sided web development framework, it
									allows developers to create web applications that can run on any platform. If you
									want to create a new-age web application that does not cost much and is not
									difficult to maintain, then our PHP development would be of help to you.
								</p>
								<a
									href="https://www.genixbit.com/web-development/php-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
							<div data-toggle="wordpress-technologies" class="fxt-tab-content">
								<img src="../../../assets/images/wordpress.svg" alt="WordPress Development" />
								<p>
									More than 25% of the internet is built using WordPress. From single page websites
									for bloggers to corporate blogs with growing portfolio of websites and subsites,
									WordPress is a CMS system that can do everything. Our WordPress developers can
									help you build robust websites that boast of interactive features and high-end
									security.
								</p>
								<a
									href="https://www.genixbit.com/web-development/wordpress-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
							<div data-toggle="codeigniter-technologies" class="fxt-tab-content">
								<img src="../../../assets/images/codeigniter.svg" alt="Codeigniter Development" />
								<p>
									Our web developers adore codeigniter for two reasons - it is lightweight and it is
									simple. It makes the task of creating a web application ridiculously simple. If
									you have a web application development requirement that needs to be done with a
									race against the clock, our Codeigniter development expertise could help.
								</p>
								<a
									href="https://www.genixbit.com/web-development/codeigniter-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
							<div data-toggle="laravel-technologies" class="fxt-tab-content">
								<img src="../../../assets/images/laravel.svg" alt="Laravel Development" />
								<p>
									Laravel makes it easy for developers to write and deploy code with minimized risk
									of bugs. It simplifies most of the common tasks that developers have to do thereby
									increasing the overall quality of work. No surprise that Laravel is one of the
									forte for our developers.
								</p>
								<a
									href="https://www.genixbit.com/web-development/laravel-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
							<div data-toggle="angular-technologies" class="fxt-tab-content">
								<img src="../../../assets/images/angular.svg" alt="AngularJS Development" />
								<p>
									AngularJS offers code reusability, fosters a faster application development cycle,
									allows create multiple projects parallelly and also puts developers in control.
									What more could we ask for? If you have an application that needs to go up in
									quicktime, AngularJS is a perfect pick.
								</p>
								<a
									href="https://www.genixbit.com/web-development/angularjs-development"
									class="btn btn-custome waves-effect waves-light"
									>Explore More</a
								>
							</div>
							<div data-toggle="node-technologies" class="fxt-tab-content">
								<img src="../../../assets/images/nodejs.svg" alt="NodeJS Development" />
								<p>
									NodeJS is hailed to be a viable alternative for Java. With its reliable
									performance, quick scalability, cross-functional team building ability and
									long-term support NodeJS is a web development framework that can put your project
									on the fast lane.
								</p>
								<a href="#" class="btn btn-custome waves-effect waves-light">Explore More</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	mounted() {
		const links = document.querySelectorAll('.fxt-tech-link');
		const taps = document.querySelectorAll('.fxt-tab-content');
		links.forEach((link) => {
			link.addEventListener('click', () => {
				links.forEach((lnk) => {
					if (lnk.classList.contains('active')) {
						lnk.classList.remove('active');
					}
				});
				if (!link.classList.contains('active')) {
					link.classList.add('active');
				}
				taps.forEach((tap) => {
					if (tap.dataset.toggle != link.id) {
						if (tap.classList.contains('active')) {
							tap.classList.remove('active');
						}
					} else {
						tap.classList.add('active');
					}
				});
			});
		});
	}
};
</script>

<style>
.web-app-sev-lang-collapse .tab_list {
	display: flex;
	max-width: 470px;
	float: right;
	flex-wrap: wrap;
}
.web-app-sev-lang-collapse .tab_list {
	border-bottom: 0;
}
.web-app-sev-lang-collapse .tab_list li.active {
	color: #1977cc;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	background-color: #fff;
}
.web-app-sev-lang-collapse .tab_list li {
	color: #202122;
	font-size: 20px;
	font-weight: 500;
	padding: 10px 35px;
	border: 0;
	min-height: 70px;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	cursor: pointer;
}
.web-app-sev-lang-collapse .tab_list li.active::before {
	opacity: 1;
	visibility: visible;
}
.web-app-sev-lang-collapse .tab_list li::before {
	content: '';
	display: block;
	width: 5px;
	height: 100%;
	background-color: #1977cc;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	visibility: hidden;
}
.web-app-sev-lang-collapse .left-col {
	border: 1px solid #ecedee;
	border-left: 0;
	border-right: 0;
	overflow: hidden;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	max-width: 44.5%;
	flex: 0 0 44.5%;
	-ms-flex: 0 0 44.5%;
}
.web-app-sev-lang-collapse .right-col {
	max-width: 55.5%;
	flex: 0 0 55.5%;
	-ms-flex: 0 0 55.5%;
}
.web-app-sev-lang-collapse .content_wrapper {
	max-width: 600px;
	width: 100%;
}
.web-app-sev-lang-collapse .tab_content {
	padding: 15px 15px;
}
.fxt-tab-content {
	display: none;
}
.fxt-tab-content img {
	width: 125px;
	height: 125px;
}
.fxt-tab-content.active {
	display: block;
}
.web-app-sev-lang-collapse .tab_content img {
	margin-bottom: 25px;
	width: 150px;
}
.web-app-sev-lang-collapse .accordian_header {
	display: none;
}
@media (max-width: 576px) {
	.container-fluid .tab_list li {
		font-size: 14px;
	}
	.container-fluid .accordian_header {
		font-size: 14px;
	}
	.tab_content > img {
		max-width: 80px;
	}
	.tab_content > p {
		font-size: 8px;
	}
}
</style>
