<template>
  <section class="padding-60 our-award-section bg-light">
   <div class="container">
      <div class="title">
         <span>Succeeding Through Delivering</span>
         <h2>Recognized as a Market Leader</h2>
         <p>The quality of client feedback, reliability and capability to deliver compelling solutions to our customers has brought recognition as  one of the highest-performing service providers in the United Kingdom.</p>
      </div>
      <div class="row justify-content-center">
         <div class="item">
            <div class="our-award-image-box mb-30">
               <img class="lazy" alt="" src="../../assets/images/clutch-award.png" style="">
            </div>
         </div>
         <div class="item">
            <div class="our-award-image-box  mb-30">
               <img class="lazy" alt="" src="../../assets/images/appfutura-badge.png" style="">
            </div>
         </div>
         <div class="item">
            <div class="our-award-image-box  mb-30">
               <img class="lazy" alt="" src="../../assets/images/clutch-award2.png" style="">
            </div>
         </div>
         <div class="item">
            <div class="our-award-image-box  mb-30">
               <img class="lazy" alt="" src="../../assets/images/goodfirms-award.png" style="">
            </div>
         </div>
         <!-- <div class="item">
            <div class="our-award-image-box  mb-30">
               <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/about-us/goodfirms-award-android.png" alt="">
            </div>
         </div> -->
         <div class="item">
            <div class="our-award-image-box  mb-30">
               <img class="lazy" alt="" src="../../assets/images/mobile-app-daily-award.png" style="">
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {

}
</script>

<style scoped>
.our-award-section .item {
	padding: 0 15px;
}
.our-award-section .item img {
	height: 170px;
}
</style>