<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Solutions</span>
				<h2 class="fxt-process-dev">The many possibilities of a real estate mobile app</h2>
				<p>
					A real estate app can do lot more than what you can imagine. As a real estate development
					company, we will help you build a real estate app that can perform several functions at
					once.
				</p>
			</div>
			<div class="row advantage-of-real-estate-main" style="justify-content: center">
				<div
					class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<BenefitsCard v-bind:obj="item"></BenefitsCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BenefitsCard from '../AssetComponents/BenefitsCard.vue';
export default {
	components: {
		BenefitsCard
	},
	data() {
		return {
			items: [
				{
					title: 'More Qualified Leads',
					text: 'For any business, getting leads is a long battle. For a real estate business, that battle is even tougher. A real estate mobile app an help connect leads with a high intent to purchase to your business.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M49.6,36.2c-0.1-0.1-0.3-0.2-0.5-0.2l-3.4-0.5l-0.6-0.3c0,0.1-0.1,0.1-0.1,0.2L42.9,35l-0.1-0.3c1.4-3,2.1-6.3,2.1-9.6
                  c0-11.9-9.1-21.5-20.3-21.5c-11.2,0-20.3,9.7-20.3,21.5c0,11.9,9.1,21.5,20.3,21.5c2.9,0,5.8-0.7,8.4-1.9l-0.4,2.1
                  c-0.1,0-0.2,0.1-0.3,0.1l0.2,0.5L32.2,49c-0.1,0.4,0.2,0.9,0.7,1c0.2,0,0.4,0,0.5-0.1l5.7-3.2l5.6,3.1c0.4,0.2,0.9,0.1,1.1-0.3
                  c0.1-0.2,0.1-0.3,0.1-0.5l-0.9-6.7l4.7-4.8C49.9,37,49.9,36.5,49.6,36.2z M33.3,42.7c-2.7,1.5-5.7,2.3-8.8,2.3
                  C14.2,45,5.9,36.1,5.9,25.2c0-11,8.4-19.9,18.7-19.9c10.3,0,18.7,8.9,18.7,19.9c0,2.6-0.5,5.2-1.4,7.6L40.2,29
                  c-0.1-0.3-0.4-0.5-0.7-0.5c-0.3,0-0.6,0.2-0.7,0.5l-3,6.2l-2.4,0.4V23.1c0-0.3-0.1-0.5-0.4-0.7c-1.8-1.3-3.8-2.1-6-2.5
                  c2.8-0.8,4.7-3.4,4.8-6.3c0-3.7-3.2-6.7-7.1-6.7c-3.9,0-7.1,3-7.1,6.7c0.1,2.9,2,5.5,4.8,6.3c-2.2,0.4-4.2,1.2-6,2.6
                  c-0.2,0.2-0.3,0.4-0.3,0.6v16.1c0,0.5,0.4,0.8,0.8,0.8h0l14.5,0l2.1,2.2L33.3,42.7z M28.9,37.6l0.8,0.8l-12.1,0V23.6
                  c4.3-2.9,9.8-2.9,14.1,0v12.2l-2.3,0.4c-0.4,0.1-0.7,0.5-0.7,1C28.7,37.3,28.8,37.4,28.9,37.6z M24.7,18.7c-3,0-5.5-2.3-5.5-5
                  c0-2.8,2.5-5,5.5-5c3,0,5.5,2.3,5.5,5C30.1,16.4,27.7,18.7,24.7,18.7z M43.5,41.3c-0.2,0.2-0.3,0.4-0.2,0.7l0.8,5.5L39.5,45
                  c-0.2-0.1-0.5-0.1-0.8,0l-4.6,2.6l1-5.4c0-0.3,0-0.5-0.2-0.7l-3.7-3.9l5.2-0.9c0.3,0,0.5-0.2,0.6-0.5l2.4-5l2.2,4.9
                  c0.1,0.3,0.4,0.4,0.6,0.5l5.1,0.7L43.5,41.3z M35.9,38.9l-1.5,0.7l1.6,3.3c0.2,0.4,0.7,0.6,1.1,0.4c0,0,0,0,0,0l7.3-4.1l-0.8-1.4
                  l-6.6,3.7L35.9,38.9z M4.1,15l-1.5-0.7c-0.2,0.5-0.5,1-0.7,1.6l1.5,0.6C3.6,16,3.9,15.5,4.1,15z M2.1,21.1l-1.6-0.3
                  c-0.1,0.6-0.2,1.1-0.2,1.7l1.6,0.2C2,22.2,2,21.6,2.1,21.1z M12.5,5.5l-0.9-1.4c-0.5,0.3-0.9,0.6-1.4,1l1,1.3
                  C11.6,6.1,12,5.8,12.5,5.5z M6.6,39.4l-1.3,1c0.3,0.5,0.7,0.9,1.1,1.3l1.2-1.1C7.2,40.2,6.9,39.8,6.6,39.4z M4.8,36.7l-1.4,0.8
                  C3.7,38,4,38.5,4.3,39l1.3-0.9C5.3,37.6,5.1,37.2,4.8,36.7z M7.5,43c0.4,0.4,0.8,0.8,1.3,1.2l1.1-1.3c-0.4-0.3-0.8-0.7-1.2-1.1
                  L7.5,43z M21.3,2.2l-0.2-1.6c-0.6,0.1-1.1,0.2-1.7,0.3l0.3,1.6C20.3,2.4,20.8,2.3,21.3,2.2z M24.5,2l0-1.7c-0.6,0-1.1,0-1.7,0.1
                  L22.9,2C23.4,2,24,2,24.5,2z M18.2,2.9l-0.5-1.6c-0.5,0.2-1.1,0.3-1.6,0.5l0.6,1.6C17.2,3.2,17.7,3,18.2,2.9z M15.3,4l-0.7-1.5
                  c-0.5,0.2-1,0.5-1.5,0.8l0.8,1.5C14.3,4.5,14.8,4.2,15.3,4z M5.7,12.2l-1.3-0.9c-0.3,0.5-0.6,1-0.9,1.5l1.4,0.8
                  C5.1,13.1,5.4,12.6,5.7,12.2z M7.6,9.7L6.4,8.6C6,9,5.7,9.4,5.3,9.9l1.3,1C6.9,10.5,7.3,10.1,7.6,9.7z M27.9,0.6
                  c-0.6-0.1-1.1-0.1-1.7-0.2L26.1,2c0.5,0,1.1,0.1,1.6,0.2L27.9,0.6z M9.9,7.4l-1-1.3C8.4,6.5,8,6.9,7.6,7.3l1.1,1.2
                  C9.1,8.1,9.5,7.8,9.9,7.4z M29.1,47.9l0.3,1.6c0.6-0.1,1.1-0.3,1.7-0.4l-0.4-1.6C30.2,47.6,29.6,47.8,29.1,47.9z M1.9,27.5
                  l-1.6,0.2c0.1,0.6,0.1,1.2,0.2,1.7l1.6-0.3C2,28.6,2,28.1,1.9,27.5z M1.8,25.2c0-0.3,0-0.6,0-0.9l-1.6-0.1c0,0.3,0,0.6,0,0.9
                  c0,0.3,0,0.5,0,0.8l1.6-0.1C1.8,25.7,1.8,25.4,1.8,25.2z M3.4,33.8l-1.5,0.6c0.2,0.5,0.4,1.1,0.7,1.6l1.5-0.7
                  C3.8,34.8,3.6,34.3,3.4,33.8z M2.9,18l-1.5-0.5C1.2,18,1,18.6,0.9,19.1l1.6,0.4C2.6,19,2.7,18.5,2.9,18z M2.5,30.7l-1.6,0.4
                  c0.1,0.6,0.3,1.1,0.5,1.7l1.5-0.5C2.7,31.8,2.6,31.2,2.5,30.7z M34.4,2.4c-0.5-0.2-1-0.4-1.6-0.6l-0.6,1.6c0.5,0.2,1,0.4,1.5,0.6
                  L34.4,2.4z M43.3,12.1l1.3-0.9c-0.3-0.5-0.7-0.9-1-1.4l-1.3,1C42.7,11.2,43,11.6,43.3,12.1z M10.1,45.2c0.5,0.3,0.9,0.7,1.4,1
                  l0.9-1.4c-0.4-0.3-0.9-0.6-1.3-0.9L10.1,45.2z M13,47c0.5,0.3,1,0.5,1.5,0.8l0.7-1.5c-0.5-0.2-1-0.5-1.4-0.7L13,47z M42.6,8.4
                  c-0.4-0.4-0.8-0.8-1.2-1.2l-1.1,1.2c0.4,0.4,0.8,0.8,1.1,1.2L42.6,8.4z M16.1,48.5c0.5,0.2,1.1,0.4,1.6,0.5l0.5-1.6
                  c-0.5-0.2-1-0.3-1.5-0.5L16.1,48.5z M26,48.3l0.1,1.7c0.6,0,1.1-0.1,1.7-0.2l-0.2-1.6C27,48.2,26.5,48.3,26,48.3z M31.2,1.3
                  c-0.5-0.2-1.1-0.3-1.6-0.4l-0.3,1.6c0.5,0.1,1,0.2,1.5,0.4L31.2,1.3z M22.7,49.9c0.6,0,1.1,0.1,1.7,0.1l0-1.7
                  c-0.5,0-1.1,0-1.6-0.1L22.7,49.9z M19.3,49.4c0.6,0.1,1.1,0.2,1.7,0.3l0.2-1.6c-0.5-0.1-1-0.2-1.6-0.3L19.3,49.4z M45,14.9
                  l1.5-0.7c-0.3-0.5-0.5-1-0.8-1.5l-1.4,0.8C44.5,13.9,44.7,14.4,45,14.9z M48.9,25.8l-1.6,0c0,0.5,0,1.1-0.1,1.6l1.6,0.2
                  C48.9,27,48.9,26.4,48.9,25.8z M48.6,29.3L47,29c-0.1,0.5-0.2,1.1-0.3,1.6l1.6,0.4C48.4,30.4,48.5,29.8,48.6,29.3z M40.1,6.1
                  c-0.4-0.4-0.9-0.7-1.3-1.1l-0.9,1.3c0.4,0.3,0.8,0.6,1.3,1L40.1,6.1z M37.4,4c-0.5-0.3-1-0.6-1.5-0.9l-0.8,1.5
                  c0.5,0.2,0.9,0.5,1.4,0.8L37.4,4z M46.2,17.9l1.5-0.5c-0.2-0.5-0.4-1.1-0.6-1.6l-1.5,0.6C45.8,16.8,46,17.3,46.2,17.9z M45.7,33.7
                  l1.5,0.6c0.2-0.5,0.4-1.1,0.6-1.6l-1.6-0.5C46.1,32.7,45.9,33.2,45.7,33.7z M46.9,21l1.6-0.3c-0.1-0.6-0.2-1.1-0.4-1.7l-1.6,0.4
                  C46.7,19.9,46.8,20.4,46.9,21z M47.3,24.2l1.6-0.1c0-0.6-0.1-1.1-0.1-1.7l-1.6,0.2C47.2,23.1,47.3,23.7,47.3,24.2z"></path>
                </g>
              </g>
            </svg>
					`
				},
				{
					title: 'Increase Bookings',
					text: 'Increase your property tour books drastically by building a real estate app the app can streamline bookings by identifying the perfect time slots when owners, brokers and buyers are ready to take the tour.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M45,24.6c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1s0-0.1,0-0.2V11.5c0-2.1-1.8-3.9-3.9-3.9h-1.9V6.1
                  c0-1.7-1.5-3-3.2-3c-1.7,0.1-3,1.5-3,3.2v1.2H30V6.1c0-1.7-1.5-3-3.2-3c-1.7,0.1-3,1.5-3,3.2v1.2h-3.5V6.1c0-1.7-1.5-3-3.2-3
                  c-1.7,0.1-3,1.5-3,3.2v1.2h-3.6V6.1c-0.1-1.7-1.5-3-3.2-3c-1.7,0.1-3,1.5-3,3.2v1.8H3.4C1.5,8.1,0,9.6,0,11.5
                  c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8c0-1,0.8-1.8,1.8-1.8h1.2v2c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3.1V9.1h3.6v2.6
                  c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3.1V9.1h3.5v2.6c0,1.7,1.4,3.1,3,3.1c1.7,0,3-1.4,3-3.1V9.1h3.4v2.6c0,1.7,1.4,3.1,3,3.1
                  c1.7,0,3-1.4,3-3.1V9.1h1.8c1.3,0,2.3,1.1,2.3,2.4v4.8H1.6v-1c0-0.4-0.4-0.7-0.8-0.7c-0.4,0-0.7,0.4-0.7,0.8v24.4
                  c0,2.1,1.7,3.8,3.8,3.8h25.5c2.2,2.1,5.1,3.3,8.4,3.3c2.6,0,5.1-0.8,7.3-2.4c0,0,0,0,0.1,0c0.3-0.3,0.4-0.8,0.1-1.1
                  C44.8,43,44.3,43,44,43.2C40,46.1,34.7,46,30.8,43c-4.6-3.8-5.4-10.6-1.6-15.2c3.7-4.7,10.5-5.4,15.2-1.7
                  c4.6,3.8,5.4,10.6,1.6,15.2c0,0,0,0,0,0.1c-0.2,0.3-0.2,0.8,0.2,1.1c0.4,0.2,0.8,0.2,1.1-0.2c1.8-2.2,2.8-5,2.8-7.8
                  C50,30.4,48,26.9,45,24.6z M9.1,11.7c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V6.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V11.7z
                  M18.8,11.7c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V6.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V11.7z M28.5,11.7
                  c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5V6.3c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5V11.7z M38,11.7
                  c0,0.8-0.7,1.5-1.5,1.5S35,12.5,35,11.7V6.3c0-0.8,0.7-1.5,1.5-1.5S38,5.5,38,6.3V11.7z M19,17.9h7.2v7.2H19V17.9z M19,26.7h7.2
                  v2.5c0,0.2,0,0.3,0.2,0.5c-0.6,1.3-0.9,2.8-1,4.3H19V26.7z M8.7,42H3.8c-1.2,0-2.2-1-2.2-2.3v-4.3h7.2V42z M8.7,33.9H1.6v-7.2h7.2
                  V33.9z M8.7,25.1H1.6v-7.2h7.2V25.1z M17.4,42h-7.2v-6.6h7.2V42z M17.4,33.9h-7.2v-7.2h7.2V33.9z M17.4,25.1h-7.2v-7.2h7.2V25.1z
                  M27.8,42H19v-6.6h6.3C25.5,37.9,26.4,40.2,27.8,42z M27.8,27.1v-0.5h0.4C28,26.8,27.9,26.9,27.8,27.1z M34.9,22.4
                  c-2,0.5-3.9,1.4-5.4,2.7c-0.1,0-0.2-0.1-0.3-0.1h-1.5v-7.2h7.2V22.4z M43.7,23.7c-1.8-1-3.8-1.6-6-1.6c-0.4,0-0.8,0-1.2,0v-4.3
                  h7.2V23.7z M32.3,41.3h10.4c0.4,0,0.7-0.3,0.7-0.7V33l0.5,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.7,0-1
                  l-1.6-1.6L43,30.7l-5.1-5.1c-0.3-0.3-0.7-0.3-1,0l-6.9,6.9c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0l0.5-0.5v7.6
                  C31.6,41,31.9,41.3,32.3,41.3z M39.1,39.9h-3.3v-4.7h3.3V39.9z M33,31.6l4.4-4.5l4.5,4.5v8.2h-1.4v-5.4c0-0.4-0.3-0.7-0.7-0.7
                  h-4.7c-0.4,0-0.7,0.3-0.7,0.7v5.4H33V31.6z"></path>
                </g>
              </g>
            </svg>
					`
				},
				{
					title: 'Brand positioning',
					text: 'Enhance your brand position as a real estate business by embracing the mobile app wave. Become a brand that is visible and accessible to customers in your region and beyond with a customized mobile app.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
								<g>
									<g>
										<path d="M49.9,32.1c-0.1-0.3-0.3-0.4-0.6-0.5l-9.2-1.3L36,21.6c-0.2-0.3-0.4-0.4-0.7-0.4c-0.3,0-0.5,0.1-0.7,0.4l-0.5,1L27.1,8.9
										c-0.2-0.4-0.5-0.7-1-0.7l-9.2-2.6c-0.1-0.1-0.2-0.2-0.2-0.3c-1.1-2.1-2.9-3.6-5.1-4.3C9.4,0.2,6.9,0.4,4.8,1.5
										C0.5,3.6-1.2,8.8,0.9,13.1c0.8,1.6,2.1,2.9,3.8,3.7l-0.9,2.4c-0.2,0.4-0.2,0.9,0.1,1.3L16.1,44c0.4,0.7,1.1,1.3,1.9,1.6
										c0.8,0.2,1.7,0.2,2.5-0.2l6.4-3.2l-1.4,6.4c-0.1,0.3,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.2,0.8,0.1l8.8-4.7l8.8,4.7
										c0.2,0.1,0.6,0.1,0.8-0.1c0.2-0.1,0.4-0.4,0.3-0.7l-2.1-9.4l6.7-6.4C50,32.6,50.1,32.3,49.9,32.1z M2.4,12.4
										C0.6,8.9,2,4.5,5.6,2.7c1.7-0.9,3.7-1,5.5-0.4c1.5,0.4,2.8,1.4,3.7,2.7l-3.3-0.9c-1-0.3-2,0.2-2.3,1.2L5.3,15.4
										C4.1,14.8,3,13.7,2.4,12.4z M19.7,44.1c-0.4,0.2-0.9,0.2-1.3,0.1c-0.5-0.1-0.8-0.4-1-0.8L5.3,19.7l0.9-2.4
										c2.1,0.7,4.4,0.5,6.4-0.4c1.2,1,3,1.2,4.4,0.4c2-1,2.7-3.3,1.7-5.3c-1.1-2-3.5-2.7-5.4-1.7c-2,1-2.7,3.3-1.7,5.3c0,0,0,0,0,0.1
										C10,16.4,8.3,16.5,6.6,16l3.9-10.2c0.1-0.2,0.3-0.3,0.5-0.3l14.6,4l7.7,14.7l-2.9,5.9l-9.2,1.3c-0.3,0.1-0.5,0.2-0.6,0.5
										c-0.1,0.3,0,0.6,0.2,0.7l6.7,6.4l-0.2,1.1L19.7,44.1z M12.9,15.1c-0.7-1.3-0.2-2.8,1.1-3.3c0.5-0.2,1.1-0.3,1.7-0.2
										C15.2,12.8,14.1,14.3,12.9,15.1z M16.9,12.3c1.1,1.2,0.8,3.1-0.7,3.9c-0.7,0.4-1.5,0.4-2.3,0C15.2,15.3,16.3,13.8,16.9,12.3z
										M41.7,38.4c-0.2,0.1-0.3,0.4-0.2,0.7l1.8,8.2l-7.7-4.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.2,0-0.4,0.1l-7.7,4.1l2-8.2
										c0.1-0.2,0-0.5-0.2-0.7L23,32.8l8.1-1.2c0.2,0,0.5-0.2,0.6-0.4l3.6-7.6l3.6,7.6c0.1,0.2,0.3,0.4,0.6,0.4l8.1,1.2L41.7,38.4z
										M30,23.6c-0.2-0.4-0.7-0.5-1-0.3L15,30.4c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.7,0.5,1,0.3l14.1-7.1C30.1,24.4,30.2,24,30,23.6z
										M27.4,18.7c-0.2-0.4-0.7-0.5-1-0.3l-14.1,7.1c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.7,0.5,1,0.3l14.1-7.1
										C27.4,19.4,27.6,19,27.4,18.7z M20.3,33.6c-0.2-0.4-0.6-0.5-1-0.4l-2.5,1.3c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.7,0.5,1,0.3l2.5-1.3
										C20.4,34.3,20.6,33.9,20.3,33.6z M23,36.5l-4,1.9c-0.4,0.2-0.5,0.7-0.3,1c0.2,0.4,0.6,0.5,1,0.3l3.9-2c0.4-0.1,0.5-0.6,0.4-0.9
										C23.7,36.5,23.4,36.3,23,36.5z M35.3,31c-3.3,0-5.8,2.6-5.8,5.7c0,3.2,2.6,5.7,5.8,5.7c3.2,0,5.8-2.6,5.8-5.7S38.5,31,35.3,31z
										M35.3,41c-2.4,0-4.3-1.9-4.3-4.2c0-2.3,2-4.2,4.3-4.2c2.4,0,4.3,1.9,4.3,4.2C39.6,39.1,37.7,41,35.3,41z M37.3,36.8
										c-0.4-0.3-0.9-0.5-1.6-0.7v-1.6c0.5,0.1,0.8,0.2,1.3,0.5l0.6-0.8c-0.6-0.4-1.2-0.6-1.8-0.7v-0.4h-0.5v0.4c-0.5,0-1.1,0.2-1.4,0.5
										c-0.4,0.3-0.5,0.7-0.5,1.2c0,0.5,0.2,0.9,0.5,1.1c0.3,0.2,0.8,0.4,1.4,0.6v1.6c-0.5-0.1-1.1-0.3-1.5-0.7l-0.6,0.7
										c0.6,0.5,1.4,0.8,2.1,1v0.6h0.5v-0.6c0.6,0,1.1-0.2,1.4-0.5c0.4-0.3,0.5-0.7,0.5-1.2C37.7,37.4,37.6,37.1,37.3,36.8z M35.1,36
										c-0.3-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.2-0.3-0.2-0.4c0-0.2,0.1-0.4,0.2-0.4c0.2-0.1,0.4-0.2,0.6-0.2V36z M36.4,38.5
										c-0.2,0.1-0.4,0.2-0.7,0.2v-1.6c0.4,0.1,0.6,0.2,0.7,0.3c0.2,0.1,0.2,0.3,0.2,0.4C36.6,38.2,36.5,38.4,36.4,38.5z"></path>
									</g>
								</g>
							</svg>
					`
				},
				{
					svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M24.9,11.3h7.7c0.2,0,0.3-0.1,0.4-0.2l2-1.9l2,1.9c0.1,0.1,0.3,0.2,0.4,0.2h7.7c0.3,0,0.6-0.3,0.6-0.6V0.6
                  c0-0.3-0.3-0.6-0.6-0.6H24.9c-0.3,0-0.6,0.3-0.6,0.6v10.1C24.2,11,24.5,11.3,24.9,11.3z M25.5,1.2h19v8.9h-6.8l-2.2-2.2
                  c-0.2-0.2-0.6-0.2-0.9,0l-2.2,2.2h-6.8V1.2z M29.6,3c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5
                  C32.1,4.1,31,3,29.6,3z M29.6,6.7c-0.7,0-1.3-0.6-1.3-1.2c0-0.7,0.6-1.2,1.3-1.2c0.7,0,1.3,0.6,1.3,1.2
                  C30.9,6.2,30.3,6.7,29.6,6.7z M40.6,7.9c1.4,0,2.5-1.1,2.5-2.5C43.1,4.1,42,3,40.6,3c-1.4,0-2.5,1.1-2.5,2.5
                  C38.1,6.8,39.2,7.9,40.6,7.9z M40.6,4.3c0.7,0,1.3,0.6,1.3,1.2c0,0.7-0.6,1.2-1.3,1.2c-0.7,0-1.3-0.6-1.3-1.2
                  C39.4,4.8,39.9,4.3,40.6,4.3z M49,19.1C49,19.1,49,19.1,49,19.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2
                  c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-4-0.9h0c0,0,0,0,0,0l-7.9-1.8c-0.1,0-0.3,0-0.4,0
                  l-2.4,1.1l-4.1-0.9l-4.9-1.2c-0.1,0-0.2,0-0.3,0l-6.2,2c0,0,0,0,0,0c0,0,0,0,0,0L1.8,22.5c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
                  c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0l2.8,9.1
                  c0,0.1,0.1,0.2,0.2,0.3l21.1,16.9c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1
                  c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0l19.9-21.9c0.1-0.1,0.1-0.1,0.1-0.2l2.5-8c0,0,0,0,0,0C49,19.3,49,19.2,49,19.1
                  C49,19.1,49,19.1,49,19.1z M33.7,18.3c0.1,0,0.3,0,0.4,0l2.4-1.1l7.7,1.7c0,0,0,0,0,0l2.6,0.6l-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0
                  l-4.3,3.7l-2.2-0.7c-0.2-0.1-0.4,0-0.6,0.1l-3,2.5c-0.2,0.1-0.2,0.3-0.2,0.5c0,0.2,0.2,0.4,0.4,0.5l1.5,0.7l-6,5.2l-13-6.5
                  l10.2-5.1c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.2-0.4-0.4-0.5l-2.5-1l2.8-1.2L33.7,18.3z M41.1,24.3l-2.1,1.8l-1-0.4l2-1.7L41.1,24.3z
                  M31.1,33.1l-1.5,1.3l-1.5-0.9c-0.2-0.1-0.5-0.1-0.7,0l-1.6,1.3l-1.1-0.6c-0.2-0.1-0.6-0.1-0.8,0.1l-1.3,1.3l-10.2-6.5l5.3-2.6
                  L31.1,33.1z M24.7,16.1l2.9,0.8l-4.2,1.8l-3.1-1.1L24.7,16.1z M18.5,18.2l4.8,1.7c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
                  c0.1,0,0.2,0,0.2,0l1.5-0.6l2.4,0.9l-16.2,8.1l-8-5.1L18.5,18.2z M25.3,47.8l-20-16L3,24.5l7.8,5c0,0,0.1,0.1,0.1,0.1l11.3,7.2
                  c0.1,0.1,0.1,0.2,0.2,0.3l2.9,1.8V47.8z M25.9,37.7l-2.1-1.3l0.9-0.9l1,0.6c0.2,0.1,0.5,0.1,0.7-0.1l1.6-1.3l0.7,0.4L25.9,37.7z
                  M45.3,26.9L26.6,47.5v-8.8l20.7-17.9L45.3,26.9z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Virtual Tour',
					text: 'Make it easy for users to come to a decision about a property with virtual tours of properties. The virtual tour can be primed with in depth details about the property, 360-degree view and also floor area measurements to decide interior design.'
				},
				{
					svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M20.4,14.8l4.8-2.5l4.8,2.5l-0.9-5.3L33,5.6l-5.4-0.8L25.2,0l-2.4,4.9l-5.4,0.8l3.9,3.8L20.4,14.8z M21.1,6.8l1.9-0.3
                  L24,6.4l0.4-0.8l0.9-1.7l0.9,1.7l0.4,0.8l0.9,0.1l1.9,0.3l-1.4,1.4l-0.6,0.6l0.2,0.9l0.3,1.9L26,10.7l-0.8-0.4l-0.8,0.4l-1.7,0.9
                  L23,9.7l0.2-0.9l-0.6-0.6L21.1,6.8z M3,22.9l4.8-2.5l4.8,2.5l-0.9-5.3l3.9-3.8L10.2,13L7.8,8.1L5.4,13L0,13.7l3.9,3.8L3,22.9z
                  M3.7,14.9l1.9-0.3l0.9-0.1l0.4-0.8L7.8,12l0.9,1.7l0.4,0.8l0.9,0.1l1.9,0.3l-1.4,1.4l-0.6,0.6l0.2,0.9l0.3,1.9l-1.7-0.9l-0.8-0.4
                  L7,18.8l-1.7,0.9l0.3-1.9l0.2-0.9l-0.6-0.6L3.7,14.9z M37.4,28.7l4.8-2.5l4.8,2.5l-0.9-5.3l3.9-3.8l-5.4-0.8L42.2,14l-2.4,4.9
                  l-5.4,0.8l3.9,3.8L37.4,28.7z M38.1,20.8l1.9-0.3l0.9-0.1l0.4-0.8l0.9-1.7l0.9,1.7l0.4,0.8l0.9,0.1l1.9,0.3l-1.4,1.4l-0.6,0.6
                  l0.2,0.9l0.3,1.9L43,24.7l-0.8-0.4l-0.8,0.4l-1.7,0.9l0.3-1.9l0.2-0.9l-0.6-0.6L38.1,20.8z M35.8,32.6v15.7H20.4V19.8l4.9-2.9
                  l4.9,2.9v25.9h1.7V18.9L25.2,15l-6.6,3.9v29.4H3.2V27.8L8,25l4.9,2.9v17.9h1.7V26.9L8,23l-6.6,3.9v21.4v0.9V50H49v-1.7H37.6V33.5
                  l4.9-2.9l4.9,2.9v12.1H49V32.6l-6.6-3.9L35.8,32.6z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Competitive advantage',
					text: 'Gain a competitive advantage over your competitors by reaching customers faster with the mobile app. Save time for sales reps to follow leads. You can know high quality leads from the rest to close deals faster.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M50,25c0-11.5-7.9-21.6-19.1-24.3h-0.3c-3.7-0.9-7.5-0.9-11.1,0h-0.3c-4.2,1-8.1,3.1-11.3,6.1C7.8,6.8,7.7,6.9,7.6,7
                  C2.7,11.7,0,18.2,0,25c0,6.8,2.8,13.3,7.7,18c0.1,0.1,0.1,0.1,0.2,0.2c3.1,3,7,5,11.1,6.1h0.3c3.7,0.9,7.6,0.9,11.3,0H31
                  C42.1,46.5,50,36.5,50,25z M25.9,48.1v-9.4c2.8,0.1,5.6,0.5,8.3,1.4c-1.1,2.7-2.5,5.2-4.2,7.5C28.6,47.9,27.3,48,25.9,48.1z
                  M20,47.6c-1.7-2.4-3.1-4.9-4.3-7.5c2.7-0.8,5.5-1.3,8.3-1.4v9.4C22.6,48,21.3,47.9,20,47.6z M17.2,46.8c-2.7-1-5.2-2.4-7.3-4.2
                  c1.3-0.7,2.7-1.3,4.1-1.9C14.9,42.8,15.9,44.8,17.2,46.8z M20,2.3C21.3,2,22.7,1.8,24,1.8v9.5c-2.8-0.1-5.6-0.5-8.3-1.4
                  C16.8,7.2,18.3,4.7,20,2.3L20,2.3z M14,9.3c-1.4-0.5-2.8-1.1-4.1-1.9c2.2-1.9,4.6-3.3,7.3-4.3C15.9,5.1,14.9,7.2,14,9.3z M30,2.3
                  c1.7,2.3,3.1,4.9,4.3,7.5c-2.7,0.8-5.5,1.3-8.3,1.4V1.8C27.3,1.9,28.6,2,30,2.3z M25.9,25.9h11.3c-0.1,4.2-0.9,8.4-2.4,12.4
                  c-2.9-0.9-5.9-1.4-9-1.5V25.9z M25.9,24V13.1c3-0.1,6.1-0.6,9-1.5c1.5,4,2.3,8.2,2.4,12.4H25.9z M24,13.1V24H12.7
                  c0.1-4.2,0.9-8.4,2.4-12.4C18,12.5,21,13,24,13.1z M24,25.9v10.9c-3,0.1-6.1,0.6-9,1.5c-1.5-4-2.3-8.2-2.4-12.4H24z M48.1,25
                  c0,9.8-6.2,18.5-15.4,21.8c1.2-1.9,2.3-4,3.2-6.1c0.6,0.2,1.2,0.4,1.9,0.7c0.5,0.2,1,0,1.2-0.5c0.2-0.5,0-1-0.5-1.2
                  c-0.6-0.3-1.3-0.5-1.9-0.7c1.5-4.2,2.4-8.5,2.5-13h5.7c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-5.8c-0.1-4.4-0.9-8.8-2.5-13
                  c0.6-0.2,1.3-0.5,1.9-0.8c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.5,0-0.7c-0.2-0.5-0.8-0.7-1.2-0.5c-0.6,0.3-1.2,0.5-1.9,0.7
                  C35,7.2,34,5.1,32.8,3.1C42,6.4,48.1,15.2,48.1,25z M8.4,8.7c1.6,0.9,3.2,1.6,4.9,2.3c-1.5,4.2-2.4,8.5-2.4,13h-9
                  C2,18.3,4.4,12.8,8.4,8.7z M1.8,25.9h9c0.1,4.4,0.9,8.8,2.4,13c-1.7,0.6-3.3,1.4-4.9,2.3C4.4,37.1,2,31.6,1.8,25.9z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Global reach',
					text: 'Location matters a lot in real estate selling. But, real estate property in any location of the world is worth its weight in gold. Real estate app development can make properties accessible to a global market.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
