<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Advantages></Advantages>

		<Platforms></Platforms>

		<Services></Services>

		<WhyChooseUs></WhyChooseUs>

		<TypeDiv></TypeDiv>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/OtherSolutions/ChatbotDevelopment/Banner.vue';
import About from '../../components/OtherSolutions/ChatbotDevelopment/About.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import Advantages from '../../components/OtherSolutions/ChatbotDevelopment/Advantages.vue';
import Platforms from '../../components/OtherSolutions/ChatbotDevelopment/Platforms.vue';
import Services from '../../components/OtherSolutions/ChatbotDevelopment/Services.vue';
import WhyChooseUs from '../../components/OtherSolutions/ChatbotDevelopment/WhyChooseUs.vue';
import TypeDiv from '../../components/Home/TypeDiv.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		Advantages,
		Platforms,
		Services,
		WhyChooseUs,
		TypeDiv,
		Const
	}
};
</script>

<style></style>