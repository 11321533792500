<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Advantages of chatbot development</span>
				<h2 class="fxt-process-dev">
					The chatbot-advantage we deliver as a chatbot development company
				</h2>
				<p>
					Chatbots can help your business deliver proactive customer service that is driven by
					cognitive intelligence.
				</p>
			</div>
			<div class="row align-items-center justify-content-between">
				<AdvantageCard v-for="item in items" :key="item.title" :obj="item"></AdvantageCard>
			</div>
		</div>
	</section>
</template>

<script>
import AdvantageCard from '../AssetComponents/AdvantageCard.vue';
export default {
	components: {
		AdvantageCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'Reduce manual errors',
					text: 'Chatbots do not make mistakes. You can count on them to deliver accurate responses to customers every single time.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'Round-the-clock availability',
					text: 'Working hours, recesses, holidays, sick leaves — chatbots have no such downtimes that could affect productivity.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'High-volume handling',
					text: 'A single chatbot can handle up to a dozen or hundred queries simultaneously without any impact on response rates or accuracy.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'Reduce response times',
					text: 'Chatbots think at the speed of thought. They fetch information from databases directly making them super-efficient at instant responses to queries.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'Enhance productivity',
					text: 'Customer FAQs are important. But, your employees have to focus on other priorities as well. A chatbot can share the load without affecting quality.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                     <g>
                        <g>
                           <path d="M26.1,25.7v-4.9c0-0.3-0.2-0.5-0.5-0.5h-4.2v-2.5c0-0.3-0.2-0.5-0.4-0.5c0,0,0,0,0,0h-6.2v-2.1c0.9-0.3,1.5-1.2,1.2-2.2
                           c-0.3-0.9-1.2-1.5-2.2-1.2c-0.9,0.3-1.5,1.2-1.2,2.2c0.2,0.6,0.6,1,1.2,1.2v2.1H7.5c-0.3,0-0.5,0.2-0.5,0.5c0,0,0,0,0,0v2.5H2.9
                           c-0.3,0-0.5,0.2-0.5,0.5v4.9c-1.6,0.3-2.7,1.8-2.4,3.4c0.2,1.2,1.2,2.1,2.4,2.4v7c0,0.3,0.2,0.5,0.5,0.5h22.7
                           c0.3,0,0.5-0.2,0.5-0.5v-7c1.3-0.3,2.4-1.5,2.4-2.9C28.5,27.2,27.4,26,26.1,25.7z M2.4,30.5c-0.9-0.2-1.4-1-1.4-1.9
                           c0-0.9,0.6-1.6,1.4-1.9V30.5z M13.4,13.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8
                           C13.8,14.3,13.4,13.9,13.4,13.5z M8,18.3h12.4v2H8V18.3z M25.1,38H3.4V21.2h21.7V38z M27.5,29.1c-0.2,0.7-0.7,1.2-1.4,1.3v-3.8
                           C27.1,27,27.8,28.1,27.5,29.1z M19.9,30.3H8.6c-0.3,0-0.5,0.2-0.5,0.5v4.9c0,0.3,0.2,0.5,0.5,0.5h11.3c0.3,0,0.5-0.2,0.5-0.5v-4.9
                           C20.4,30.5,20.2,30.3,19.9,30.3z M10.8,35.2H9.1v-3.9h1.7V35.2z M13.7,35.2h-1.9v-3.9h1.9V35.2z M16.6,35.2h-1.9v-3.9h1.9V35.2z
                           M19.3,35.2h-1.7v-3.9h1.7V35.2z M8.1,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5H8.1c-0.3,0-0.5,0.2-0.5,0.5V28
                           C7.6,28.3,7.8,28.5,8.1,28.5z M8.6,25.3h2.1v2.1H8.6V25.3z M17.2,28.5h3.2c0.3,0,0.5-0.2,0.5-0.5v-3.2c0-0.3-0.2-0.5-0.5-0.5h-3.2
                           c-0.3,0-0.5,0.2-0.5,0.5V28C16.7,28.3,16.9,28.5,17.2,28.5z M17.7,25.3h2.2v2.1h-2.2V25.3z M29.9,7.2c-1,0-1.9,0.8-1.9,1.9
                           c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C31.8,8.1,31,7.3,29.9,7.2z M29.9,10C29.4,10,29,9.6,29,9.1c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C30.8,9.6,30.4,10,29.9,10z M25.1,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9
                           C27,8.1,26.1,7.3,25.1,7.2z M25.1,10c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C26,9.6,25.6,10,25.1,10z
                           M34.8,7.2c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C36.7,8.1,35.8,7.3,34.8,7.2z M34.8,10
                           c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9C35.7,9.6,35.3,10,34.8,10z M37,3.3c-1.9-1.5-4.4-2.3-7-2.3
                           c-2.6,0-5.1,0.8-7,2.3c-2,1.5-3,3.6-3,5.8c0,2.2,1.1,4.2,3,5.8l-0.1,3.6c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.1,0,0.2,0l4.6-1.9
                           c0.7,0.1,1.3,0.2,2,0.2c2.6,0,5.1-0.8,7-2.3c2-1.5,3-3.6,3-5.8C40,6.9,38.9,4.8,37,3.3z M36.4,14.2c-1.7,1.3-4,2.1-6.4,2.1
                           c-0.7,0-1.3-0.1-2-0.2c-0.1,0-0.2,0-0.3,0l-4,1.7l0.1-3.1c0-0.2-0.1-0.3-0.2-0.4l0,0c0,0-0.1-0.1-0.1-0.1c-1.7-1.3-2.7-3.1-2.7-5
                           c0-1.9,0.9-3.7,2.7-5c1.7-1.3,4-2.1,6.4-2.1c2.4,0,4.7,0.7,6.4,2.1c1.7,1.3,2.7,3.1,2.7,5S38.1,12.8,36.4,14.2z"></path>
                        </g>
                     </g>
                  </svg>`,
					title: 'Provide customer convenience',
					text: 'Modern-day customers are impatient and need instant answers. Chatbots can provide contextually correct responses that lead to better customer convenience.'
				}
			]
		};
	}
};
</script>

<style scoped>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
