<template>
	<section class="bg-light box-shadow o-hidden real-time-app-main">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-12 col-12">
					<div class="real-time-app-icon">
						<svg
							x="0px"
							y="0px"
							viewBox="0 0 100 100"
							style="enable-background: new 0 0 100 100"
							xml:space="preserve"
						>
							<g>
								<g>
									<path
										d="M48.1,92.3h3.8v-3.8h-3.8V92.3z M38.5,21.2h-7.7c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7
                c1.1,0,1.9-0.9,1.9-1.9v-7.7C40.4,22,39.5,21.2,38.5,21.2z M36.5,28.8h-3.8V25h3.8V28.8z M53.8,21.2h-7.7c-1.1,0-1.9,0.9-1.9,1.9
                v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7C55.8,22,54.9,21.2,53.8,21.2z M51.9,28.8h-3.8V25h3.8V28.8z
                M38.5,36.5h-7.7c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7
                C40.4,37.4,39.5,36.5,38.5,36.5z M36.5,44.2h-3.8v-3.8h3.8V44.2z M53.8,36.5h-7.7c-1.1,0-1.9,0.9-1.9,1.9v7.7
                c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7C55.8,37.4,54.9,36.5,53.8,36.5z M51.9,44.2h-3.8v-3.8h3.8V44.2z
                M53.8,51.9h-7.7c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7
                C55.8,52.8,54.9,51.9,53.8,51.9z M51.9,59.6h-3.8v-3.8h3.8V59.6z M59.6,53.8v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9
                v-7.7c0-1.1-0.9-1.9-1.9-1.9h-7.7C60.5,51.9,59.6,52.8,59.6,53.8z M63.5,55.8h3.8v3.8h-3.8V55.8z M53.8,67.3h-7.7
                c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7C55.8,68.2,54.9,67.3,53.8,67.3z M51.9,75h-3.8
                v-3.8h3.8V75z M69.2,67.3h-7.7c-1.1,0-1.9,0.9-1.9,1.9v7.7c0,1.1,0.9,1.9,1.9,1.9h7.7c1.1,0,1.9-0.9,1.9-1.9v-7.7
                C71.2,68.2,70.3,67.3,69.2,67.3z M67.3,75h-3.8v-3.8h3.8V75z M44.2,11.5h11.5V7.7H44.2V11.5z M74,40.1c0.6,0.4,1.3,0.4,1.9,0.1
                l15.4-7.7c1-0.5,1.3-1.6,0.9-2.6c-0.2-0.4-0.5-0.7-0.9-0.9l-15.4-7.7c-0.9-0.5-2.1-0.1-2.6,0.9c-0.1,0.3-0.2,0.6-0.2,0.9v15.4
                C73.1,39.1,73.4,39.7,74,40.1z M76.9,26.2l9.2,4.6l-9.2,4.6V26.2z M98.1,13.5H78.8V7.7c0-4.2-3.4-7.7-7.7-7.7H28.8
                c-4.2,0-7.7,3.4-7.7,7.7v44.2H1.9c-1.1,0-1.9,0.9-1.9,1.9v30.8c0,1.1,0.9,1.9,1.9,1.9h19.2v5.8c0,4.2,3.4,7.7,7.7,7.7h42.3
                c4.2,0,7.7-3.4,7.7-7.7V48.1h19.2c1.1,0,1.9-0.9,1.9-1.9V15.4C100,14.3,99.1,13.5,98.1,13.5z M3.9,82.7V55.8h28.8v13.5
                c0,0.5,0.2,1,0.6,1.4l2.5,2.5l-2.5,2.5c-0.4,0.4-0.6,0.8-0.6,1.4v5.8H3.9z M75,92.3c0,2.1-1.7,3.8-3.8,3.8H28.8
                c-2.1,0-3.8-1.7-3.8-3.8v-5.8h9.6c1.1,0,1.9-0.9,1.9-1.9v-6.9l3.3-3.3c0.8-0.8,0.8-2,0-2.7l-3.3-3.3V53.8c0-1.1-0.9-1.9-1.9-1.9
                H25V7.7c0-2.1,1.7-3.8,3.8-3.8h42.3c2.1,0,3.8,1.7,3.8,3.8v5.8h-9.6c-1.1,0-1.9,0.9-1.9,1.9v6.9l-3.3,3.3c-0.8,0.8-0.8,2,0,2.7
                l3.3,3.3v14.6c0,1.1,0.9,1.9,1.9,1.9H75V92.3z M96.1,44.2H67.3V30.8c0-0.5-0.2-1-0.6-1.4l-2.5-2.5l2.5-2.5
                c0.4-0.4,0.6-0.8,0.6-1.4v-5.8h28.8V44.2z M11.5,59.6H7.7v3.8h3.8V59.6z M15.4,63.5h13.5v-3.8H15.4V63.5z M7.7,71.2h21.2v-3.8H7.7
                V71.2z M7.7,78.8h21.2V75H7.7V78.8z M17.3,15.4h-3.8v30.8h3.8V15.4z M82.7,80.8h3.8V53.8h-3.8V80.8z M82.7,88.5h3.8v-3.8h-3.8
                V88.5z"
									></path>
								</g>
							</g>
						</svg>
					</div>
				</div>
				<div class="col-lg-9 col-md-9 col-sm-12 col-12">
					<div class="real-time-app-content-box d-flex align-items-center justify-content-start">
						<div class="real-time-app-content">
							<span class="top">We Build Robust And Cost-Effective</span>
							<h4>Health &amp; Fitness Applications</h4>
							<span class="bottom">For Diverse Business Goals. </span>
						</div>
						<div class="real-time-app-button">
							<router-link class="btn-custome btn waves-effect waves-light" to="/contact">Get In Touch</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.real-time-app-main {
	padding: 30px 0 0;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.o-hidden {
	overflow: hidden;
}
.real-time-app-icon svg * {
	fill: #0082dd;
	opacity: 0.7;
}
.real-time-app-icon {
	width: 208px;
	margin: 0 auto -20px;
}
svg {
	overflow: hidden;
	vertical-align: middle;
}
.real-time-app-content {
	margin-right: 50px;
}
.real-time-app-content .top {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 5px;
}

.real-time-app-content span {
	display: block;
	padding: 0 15px;
}
.real-time-app-content h4 {
	font-size: 35px;
	margin: 0;
	color: #0082dd;
	background-color: #0082dd10;
	font-weight: 600;
	padding: 6px 15px;
	border-radius: 10px;
	margin-bottom: 10px;
}
.real-time-app-content .bottom {
	font-size: 20px;
	font-weight: 500;
}
.real-time-app-content span {
	display: block;
	padding: 0 15px;
}
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}
.bg-light {
	background-color: #f8f9fa !important;
}
@media (max-width: 991px) {
	.real-time-app-main > .container > .row {
		align-items: center;
	}
}
@media (max-width: 767px) {
	.real-time-app-icon {
		width: 150px;
		margin: 0 auto 30px;
	}
}
@media (max-width: 991px) {
	.real-time-app-icon {
		width: 100%;
		margin: 0 auto;
	}
}

@media (max-width: 575px) {
	.real-time-app-content-box {
		display: block !important;
	}
}
@media (max-width: 767px) {
	.real-time-app-content-box {
		justify-content: center !important;
	}
}
@media (max-width: 575px) {
	.real-time-app-content {
		margin-right: 0;
		text-align: center;
		margin-bottom: 20px;
	}
}

@media (max-width: 991px) {
	.real-time-app-content {
		margin-right: 20px;
	}
}
@media (max-width: 767px) {
	.real-time-app-content .top {
		font-size: 16px;
	}
}
@media (max-width: 991px) {
	.real-time-app-content .top {
		font-size: 18px;
	}
}
@media (max-width: 767px) {
	.real-time-app-content h4 {
		font-size: 20px;
	}
}

@media (max-width: 991px) {
	.real-time-app-content h4 {
		font-size: 22px;
	}
}
@media (max-width: 1199px) {
	.real-time-app-content h4 {
		font-size: 30px;
	}
}
@media (max-width: 767px) {
	.real-time-app-content .bottom {
		font-size: 15px;
	}
}

@media (max-width: 991px) {
	.real-time-app-content .bottom {
		font-size: 16px;
	}
}
@media (max-width: 575px) {
	.real-time-app-button {
		text-align: center;
	}
}
@media (max-width: 767px) {
	.btn-custome {
		font-size: 14px !important;
	}
}
@media (max-width: 991px) {
	.real-time-app-icon {
		width: 100%;
		margin: 0 auto;
	}
}
@media (max-width: 767px) {
	.real-time-app-icon {
		width: 150px;
		margin: 0 auto 30px;
	}
}
</style>