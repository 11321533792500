<template>
	<div>
		<Banner></Banner>

		<Preview></Preview>

		<TypeDiv></TypeDiv>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../components/Portfolio/Banner';
import Preview from '../components/Portfolio/Preview';
import TypeDiv from '../components/Home/TypeDiv';
import Const from '../components/AssetComponents/Const';

export default {
	components: {
		Banner,
		Preview,
		TypeDiv,
		Const
	}
};
</script>

<style></style>
