<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Solutions</span>
				<h2 class="fxt-process-dev">The many possibilities of a real estate mobile app</h2>
				<p>
					A real estate app can do lot more than what you can imagine. As a real estate development
					company, we will help you build a real estate app that can perform several functions at
					once.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink: '/property-booking.webp',
					title: 'Property Booking',
					text: 'Build a real estate app that allows users to book favorite properties based on location, price range, number of rooms, property type, age and much more.'
				},
				{
					imageLink: '/property-renting.webp',
					title: 'Property Renting',
					text: 'The biggest market for real estate is with the rental properties. We can help you build a real estate app that allows users to find rental properties based on several parameters.'
				},
				{
					imageLink: '/property-management.webp',
					title: 'Property Management',
					text: 'When there are several properties owned by a single owner, property management becomes cumbersome. Our real estate app can help you manage all properties from a single dashboard.'
				},
				{
					imageLink: '/360-virtual-view-of-property.webp',
					title: '360 Virtual View of Property',
					text: 'Turn real estate selling into an immersive experience with 360-degree views of properties that have more detailing about interiors, room dimensions, floor area and much more.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
