/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';
import Contact from '../views/Contact.vue';
import AboutUs from '../views/AboutUs.vue';
import Process from '../views/Process.vue';
import Techno from '../views/Techno.vue';
import ClientReview from '../views/ClientReview.vue';
import Portfolio from '../views/Portfolio.vue';

import MobileDevelopment from '../views/MobileDevelopment/Main.vue';
import IosDevelopment from '../views/MobileDevelopment/IosDevelopment.vue';
import AndroidDevelopment from '../views/MobileDevelopment/AndroidDevelopment.vue';
import FlutterDevelopment from '../views/MobileDevelopment/FlutterDevelopment.vue';
import CrossPlatformDevelopment from '../views/MobileDevelopment/CrossPlatformDevelopment.vue';
import MobileDevelopmentMain from '../views/MobileDevelopment/MobileDevelopmentMain.vue';
import ARDevelopment from '../views/MobileDevelopment/ARDevelopment.vue';
import VRDevelopment from '../views/MobileDevelopment/VRDevelopment.vue';

import WebDevelopment from '../views/WebDevelopment/Main.vue';
import WebDevelopmentMain from '../views/WebDevelopment/WebDevelopmentMain.vue';
import PHPDevelopment from '../views/WebDevelopment/PHPDevelopment.vue';
import WordPressDevelopment from '../views/WebDevelopment/WordPressDevelopment.vue';
import CodeigniterDevelopment from '../views/WebDevelopment/CodeigniterDevelopment.vue';
import LaravelDevelopment from '../views/WebDevelopment/LaravelDevelopment.vue';
import AngularDevelopment from '../views/WebDevelopment/AngularDevelopment.vue';
import NodeDevelopment from '../views/WebDevelopment/NodeDevelopment.vue';

import GameDevelopmentMain from '../views/GameDevelopment/GameDevelopmentMain.vue';
import GameDevelopment from '../views/GameDevelopment/Main.vue';
import IosGameDevelopment from '../views/GameDevelopment/IosDevelopment.vue';
import AndroidGameDevelopment from '../views/GameDevelopment/AndroidDevelopment.vue';
import TwoThreeDimDevelopment from '../views/GameDevelopment/TwoThreeDimDevelopment.vue';
import ARGameDevelopment from '../views/GameDevelopment/ARGameDevelopment.vue';
import VRGameDevelopment from '../views/GameDevelopment/VRGameDevelopment.vue';
import MacGameDevelopment from '../views/GameDevelopment/MacGameDevelopment.vue';

import MvpDevelopment from '../views/EnterpriseSolutions/MvpDevelopment.vue';
import ErpDevelopment from '../views/EnterpriseSolutions/ErpDevelopment.vue';
import CrmDevelopment from '../views/EnterpriseSolutions/CrmDevelopment.vue';
import CmsDevelopment from '../views/EnterpriseSolutions/CmsDevelopment.vue';
import EcommerceDevelopment from '../views/EnterpriseSolutions/EcommerceDevelopment.vue';
import UIUXDevelopment from '../views/EnterpriseSolutions/UIUXDevelopment.vue';

import TaxiAppDevelopment from '../views/OnDemandSolutions/TaxiAppDevelopment.vue';
import FoodDeliveryAppDevelopment from '../views/OnDemandSolutions/FoodDeliveryAppDevelopment.vue';
import CarWashAppDevelopment from '../views/OnDemandSolutions/CarWashAppDevelopment.vue';

import RealEstateAppDevelopment from '../views/IndustrySolutions/RealEstateAppDevelopment.vue';
import FinanceAppDevelopment from '../views/IndustrySolutions/FinanceAppDevelopment.vue';
import TravelAppDevelopment from '../views/IndustrySolutions/TravelAppDevelopment.vue';
import HealthFitnessAppDevelopment from '../views/IndustrySolutions/HealthFitnessAppDevelopment.vue';

import SocialMediaAppDevelopment from '../views/SocialSolutions/SocialMediaAppDevelopment.vue';
import DatingAppDevelopment from '../views/SocialSolutions/DatingAppDevelopment.vue';
import MediaEntertainmentAppDevelopment from '../views/SocialSolutions/MediaEntertainmentAppDevelopment.vue';
import InstantMessagingAppDevelopment from '../views/SocialSolutions/InstantMessagingAppDevelopment.vue';

import FantasySportsAppDevelopment from '../views/OtherSolutions/FantasySportsAppDevelopment.vue';
import ChatbotDevelopment from '../views/OtherSolutions/ChatbotDevelopment.vue';

import AppCost from '../components/AppCost/AppCost.vue';
Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact
	},
	{
		path: '/about-us',
		name: 'AboutUs',
		component: AboutUs
	},
	{
		path: '/process',
		name: 'Process',
		component: Process
	},
	{
		path: '/client-review',
		name: 'ClientReview',
		component: ClientReview
	},
	{
		path: '/technologies',
		name: 'Technologies',
		component: Techno
	},
	{
		path: '/portfolio',
		name: 'Portfolio',
		component: Portfolio
	},
	{
		path: '/mobile-development',
		name: 'MobileDevelopment',
		component: MobileDevelopmentMain,
		children: [
			{
				path: '',
				name: 'MobileDevelopment',
				component: MobileDevelopment
			},
			{
				path: 'iphone-development',
				name: 'IphoneDevelopment',
				component: IosDevelopment
			},
			{
				path: 'android-development',
				name: 'AndroidDevelopment',
				component: AndroidDevelopment
			},
			{
				path: 'flutter-development',
				name: 'FlutterDevelopment',
				component: FlutterDevelopment
			},
			{
				path: 'cross-platform-development',
				name: 'CrossPlatformDevelopment',
				component: CrossPlatformDevelopment
			},
			{
				path: 'augmented-reality-development',
				name: 'ARDevelopment',
				component: ARDevelopment
			},
			{
				path: 'virtual-reality-development',
				name: 'VRDevelopment',
				component: VRDevelopment
			}
		]
	},
	{
		path: '/web-development',
		name: 'WebDevelopmentMain',
		component: WebDevelopmentMain,
		children: [
			{
				path: '',
				name: 'WebDevelopment',
				component: WebDevelopment
			},
			{
				path: 'php-development',
				name: 'PHPDevelopment',
				component: PHPDevelopment
			},
			{
				path: 'wordpress-development',
				name: 'WordPressDevelopment',
				component: WordPressDevelopment
			},
			{
				path: 'codeigniter-development',
				name: 'CodeigniterDevelopment',
				component: CodeigniterDevelopment
			},
			{
				path: 'laravel-development',
				name: 'LaravelDevelopment',
				component: LaravelDevelopment
			},
			{
				path: 'angularjs-development',
				name: 'AngularDevelopment',
				component: AngularDevelopment
			},
			{
				path: 'nodejs-development',
				name: 'NodeDevelopment',
				component: NodeDevelopment
			}
		]
	},
	{
		path: '/game-development',
		name: 'GameDevelopmentMain',
		component: GameDevelopmentMain,
		children: [
			{
				path: '',
				name: 'GameDevelopment',
				component: GameDevelopment
			},
			{
				path: 'ios-game-development',
				name: 'IosGameDevelopment',
				component: IosGameDevelopment
			},
			{
				path: 'android-game-development',
				name: 'AndroidGameDevelopment',
				component: AndroidGameDevelopment
			},
			{
				path: '2d-3d-game-development',
				name: 'TwoThreeDimDevelopment',
				component: TwoThreeDimDevelopment
			},
			{
				path: 'augmented-reality-game-development',
				name: 'ARGameDevelopment',
				component: ARGameDevelopment
			},
			{
				path: 'virtual-reality-game-development',
				name: 'VRGameDevelopment',
				component: VRGameDevelopment
			},
			{
				path: 'mac-game-development',
				name: 'MacGameDevelopment',
				component: MacGameDevelopment
			}
		]
	},
	{
		path: '/startup-mvp-development',
		name: 'MvpDevelopment',
		component: MvpDevelopment
	},
	{
		path: '/erp-development',
		name: 'ErpDevelopment',
		component: ErpDevelopment
	},
	{
		path: '/crm-development',
		name: 'CrmDevelopment',
		component: CrmDevelopment
	},
	{
		path: '/cms-development',
		name: 'CmsDevelopment',
		component: CmsDevelopment
	},
	{
		path: '/ecommerce-web-development',
		name: 'EcommerceDevelopment',
		component: EcommerceDevelopment
	},
	{
		path: '/ui-ux-design-development',
		name: 'UIUXDevelopment',
		component: UIUXDevelopment
	},
	{
		path: '/taxi-app-development',
		name: 'TaxiAppDevelopment',
		component: TaxiAppDevelopment
	},
	{
		path: '/food-delivery-app-development',
		name: 'FoodDeliveryAppDevelopment',
		component: FoodDeliveryAppDevelopment
	},
	{
		path: '/car-wash-app-development',
		name: 'CarWashAppDevelopment',
		component: CarWashAppDevelopment
	},
	{
		path: '/real-estate-app-development',
		name: 'RealEstateAppDevelopment',
		component: RealEstateAppDevelopment
	},
	{
		path: '/finance-app-development',
		name: 'FinanceAppDevelopment',
		component: FinanceAppDevelopment
	},
	{
		path: '/travel-app-development',
		name: 'TravelAppDevelopment',
		component: TravelAppDevelopment
	},
	{
		path: '/health-fitness-app-development',
		name: 'HealthFitnessAppDevelopment',
		component: HealthFitnessAppDevelopment
	},
	{
		path: '/social-media-app-development',
		name: 'SocialMediaAppDevelopment',
		component: SocialMediaAppDevelopment
	},
	{
		path: '/dating-app-development',
		name: 'DatingAppDevelopment',
		component: DatingAppDevelopment
	},
	{
		path: '/chat-instant-messaging-app-development',
		name: 'InstantMessagingAppDevelopment',
		component: InstantMessagingAppDevelopment
	},
	{
		path: '/media-entertainment-app-development',
		name: 'MediaEntertainmentAppDevelopment',
		component: MediaEntertainmentAppDevelopment
	},
	{
		path: '/fantasy-sports-app-development',
		name: 'FantasySportsAppDevelopment',
		component: FantasySportsAppDevelopment
	},
	{
		path: '/chatbot-development',
		name: 'ChatbotDevelopment',
		component: ChatbotDevelopment
	},
	{
		path: '/app-development-cost',
		name: 'AppCost',
		component: AppCost
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	console.log('navigate', to, from);
	window.scrollTo({top: 0, behavior: 'smooth'});
	next();
});

export default router;
