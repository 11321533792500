<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/cross-platform.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Cross Platform App Development Company</h2>
					<h5 style="font-weight: bold; margin: 15px 0; font-size: 16px">
						Bring More Consistency and Agility To Your Business with Cross Platform Apps.
					</h5>
					<p>
						Undoubtedly, the mobile user base is unimaginatively huge. Unfortunately, it is
						fragmented across various mobile OS platforms that restrict easy access to it with a
						single native app code base. Of course, cross-platform technology is the saviour here
						but who has the expertise to simulate a native look & feel?
					</p>
					<p>
						Genixbit is a cross-platform app development company famous for tackling such
						challenges and proved true on all expectations of its patrons.
					</p>
					<p>
						Our mastery of cross-platform application development frameworks like PhoneGap and
						Titanium to the latest Ionic and Xamarin have enabled us to work with the leading
						brands.
					</p>
					<p>
						We have served a wide range of businesses coming from diverse industries using our
						hybrid app development expertise, which has provided us golden opportunities to learn
						how to tackle hybrid app design and programming challenges successfully.
					</p>
					<p>
						Cross Platform App Development Company Bring More Consistency and Agility To Your
						Business with Cross Platform Apps. Undoubtedly, the mobile user base is unimaginatively
						huge. Unfortunately, it is fragmented across various mobile OS platforms that restrict
						easy access to it with a single native app code base. Of course, cross-platform
						technology is the saviour here but who has the expertise to simulate a native look &
						feel? Genixbit is a cross-platform app development company famous for tackling such
						challenges and proved true on all expectations of its patrons. Our mastery of
						cross-platform application development frameworks like PhoneGap and Titanium to the
						latest Ionic and Xamarin have enabled us to work with the leading brands. We have served
						a wide range of businesses coming from diverse industries using our hybrid app
						development expertise, which has provided us golden opportunities to learn how to tackle
						hybrid app design and programming challenges successfully. We are here for the benefit
						our esteemed clients who are looking for a dependable cross-platform application
						development company to tie long-term relationships, and it is by working at competitive
						market rates and delivering high quality results they keep on coming back.
					</p>
					<p>
						The benefits of our cross-platform app development endeavours is a significant cost
						saving & a short development timetable by developing a single code base and deploying it
						across the platforms.
					</p>
					<p>Would you like to benefit from our hard work and skill sets?</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
