<template>
	<section class="banner innerbanner aboutusbanner fxt-about-section">
		<div class="video pt-0">
			<img
				class="lazy fxt-about-img pt-0"
				alt="GenixBit careers"
				src="../../assets/images/about-us-banner.jpg"
			/>
		</div>

		<div
			class="tenc-intro-main__wrapper"
			style="
				min-height: unset !important;
				background-image: linear-gradient(to right, #000000e8, #000000a8);
			"
		>
			<div class="container pt-5">
				<div class="row align-items-center pt-5">
					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<h1 class="fxt-top-head">How GenixBit delivers value to your business</h1>
						<p class="fxt-top-par">
							Agile-driven process. That is the magic word that enables GenixBit to
							deliver value to its clients. Agile methodology helps us model project
							deliverables that can be continuously improved to meet stakeholder
							requirements. Our agile-driven process is certain to give your projects
							the much needed agility, for highly complicated projects.
						</p>
						<router-link
							to="/portfolio"
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>View Our Work</router-link
						>
						<router-link
							to="/app-development-cost"
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>Calculate App Development Cost</router-link
						>
						<ol class="breadcrumb">
							<li class="breadcrumb-item text-white">
								<router-link
									to="/"
									class="text-white breadcrumb-item fxt-breadcrumb-item"
									style="background: none; font-size: 14px; margin-right: 0"
								>
									Home</router-link
								>
							</li>
							<li class="breadcrumb-item text-white active fxt-breadcrumb-item">Process</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 1600px) {
	.banner {
	}
}
.banner {
	margin-top: 0px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.breadcrumb {
	background: 0 0;
	width: 100%;
	max-width: 1170px;
	margin: 0;
	padding: 0 15px;
	z-index: 9999;
}

.video img {
	width: 100%;
	height: 100%;
	min-height: 600px;
	object-fit: cover;
}
.tenc-intro-main__wrapper {
	width: 100%;
	position: absolute;
	top: -33px;
	left: 0;
	display: flex;
	align-items: center;
	background: linear-gradient(
		90deg,
		#236aec,
		rgba(35, 95, 236, 0.95) 40%,
		rgba(35, 161, 236, 0.7) 70%,
		rgba(35, 66, 236, 0.4)
	);
	z-index: 999;
}
.fxt-btn-custome {
	margin-top: 10px;
	background: #1977cc !important;
}
.fxt-top-head {
	display: black;
	font-size: 34px;
}
.fxt-top-head span:first-child {
}
.fxt-top-head span:last-child {
	font-size: 24px;
	margin: 20px 0;
}
.fxt-top-par {
	font-size:18px !important;
	margin: 30px 0 5px 0;
}
@media (max-width: 991px) {
	.fxt-top-par {
		font-size: 16px;
	}
	.fxt-tenc-intro-main__wrapper {
		top: 0;
	}
}
@media (min-width: 776px) {
	.fxt-about-section {
		height: calc(95vh - 40px) !important;
	}
	.video {
		height: 95vh !important;
	}
	.tenc-intro-main__wrapper {
		height: 95vh !important;
	}
	.fxt-about-img {
		height: 100% !important;
		min-height: unset !important;
	}
}
.breadcrumb {
	padding-top: 15px;
	padding-left: 0 !important;
	margin-left: -20px;
}
.breadcrumb-item.active {
	color: #1977cc !important;
}
.fxt-about-img {
	position: relative;
}
.fxt-about-img::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: red;
}

@media (max-width: 576px) {
	.fxt-top-head {
		font-size: 20px;
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.fxt-top-par {
		font-size: 12px !important;
		line-height: 2;
	}
	.fxt-btn-custome {
		font-size: 12px;
		padding:5px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 100px;
	}
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 34px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 28px !important;
	}
}
@media (max-width: 400px) {
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 28px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 22px !important;
	}
	.fxt-breadcrumb-item {
		font-size:12px;
	}
}
</style>
