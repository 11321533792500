<template>
	<div class="cropped-box">
		<div class="cropped-box-icon" v-html="obj.svg"></div>
		<h4 class="font-weight:bold">{{ obj.title }}</h4>
		<p class="mb-0">{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.cropped-box {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	text-align: center;
	border: 1px solid #ecedee;
	padding: 30px 20px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	min-height: 350px;
	margin: 70px 0 30px;
	position: relative;
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 0.2)
}
.cropped-box::before {
    content: "";
    display: block;
    position: absolute;
    top:-1px;
    left: 0;
    right: 0;
    height: 1px;
    width: 70%;
    background-color: #fff;
    margin: 0 auto;
}
.cropped-box:hover {
    border: 1px solid #008dd2;
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.cropped-box-icon {
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 20px;
	transition: all 0.3s ease;
	position: absolute;
	left: 50%;
	top: -35px;
	transform: translateX(-50%);
}
.cropped-box h4 {
	font-size: 18px;
	margin: 15px 0 10px;
	transition: all 0.3s ease;
	min-height: 50px;
	display: flex;
	align-items: center;
	line-height: 1.3;
	justify-content: center;
	width: 100%;
}
.cropped-box-icon svg circle,
.cropped-box-icon svg ellipse,
.cropped-box-icon svg path,
.cropped-box-icon svg polygon,
.cropped-box-icon svg rect {
	transition: all 0.3s ease;
	fill: #6a6d6f;
}
.cropped-box:hover .cropped-box-icon {
	background: #1977cc;
}
.cropped-box:hover .cropped-box-icon svg circle,
.cropped-box:hover .cropped-box-icon svg ellipse,
.cropped-box:hover .cropped-box-icon svg path,
.cropped-box:hover .cropped-box-icon svg polygon,
.cropped-box:hover .cropped-box-icon svg rect {
	fill: #fff;
}
</style>
