<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/vr-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">VR Game Development</h2>
					<p>
						Experts believe that the reason behind the growing popularity of Virtual Reality game
						development is the immersive experience it provides. At GenixBit Labs, we ensure that the
						same essence gets delivered everytime we commence a VR mobile game development project.
						Our team leaves no stone unturned to serve you with the unbiased and indispensable
						details of Virtual Reality game development services. The team ensures it utilizes the
						latest VR game technology to build a final product that appeals to your audience and
						assists you in leading the VR game race.
					</p>
					<p>
						As a leading Virtual Reality game development company, our team ensures the project is
						understood and scoped at each stage of the development lifecycle. This includes
						discussion, research, user expectations, current market, and the way to bridge the gap
						to gain more attention for your VR mobile game development project.
					</p>
					<p>
						Once these parameters are fixed, our team of VR game developers get to the coding
						business with a ‘techtive’ (tech+creative) approach to giving physical yet virtual form
						to your mobile game.
					</p>
					<p>
						We can’t deny the unmatched contribution made by VR technology in different sectors and
						the experts believe that the same success saga will be marked in the VR game industry
						too. With its realistic experience, virtual reality is believed to change the way we
						used to play. A wave of development can be seen globally, not only in context new games
						but also for the traditional games that we used to play during our early childhood.
					</p>
					<p>
						Being a virtual reality game development company, we consider this our responsibility to
						be part of this wave and assist you in reaching and engaging your target audience in a
						distinct manner and earn their loyalty for a long duration.
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
