<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>ERP TECHNOLOGY PARTNER</span>
				<h2 class="fxt-process-dev">Why Genixbit for ERP Application and Software Development?</h2>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
          <div class="why-you-invest-box-text">
            <h5 class="mb-20">Our Clients Love Us</h5>
            <a class="btn-custome btn waves-effect waves-light" href="/client-review">Our Reviews</a>
          </div>
        </div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
				{
					title: "Improved Reporting",
					text: "Customise and better your existing reporting system with an ERP upgrade. More power to you with enhanced reporting capabilities to manage complex data at with ease. Meet all your business needs with superior, detailed analytics available for taking right decisions.",
					svg: `
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M46.2,0H12.6c-0.5,0-0.8,0.4-0.8,0.8V5H4.3C3.8,5,3.4,5.4,3.4,5.8V49c0,0.5,0.4,0.8,0.8,0.8h33.5c0.5,0,0.8-0.4,0.8-0.8
                  v-4.2h7.5c0.5,0,0.8-0.4,0.8-0.8V0.8C47,0.4,46.6,0,46.2,0z M36.9,48.2H5.1V6.6h25.1v5.8c0,0.5,0.4,0.8,0.8,0.8h5.9V48.2z
                  M31.9,11.6V7.8l3.8,3.8H31.9z M45.3,43.2h-6.7V12.5h0c0-0.2-0.1-0.4-0.2-0.6l-6.7-6.6C31.5,5.1,31.3,5,31.1,5v0H13.5V1.7h31.8
                  V43.2z"></path>
                  <path d="M28.6,19.1h1.7v-3.3h0c0-0.1,0-0.2-0.1-0.3C30,15.2,29.7,15,29.4,15h-3.4v1.7h1.4L26,18.1c-3.5,3.4-7.9,6-12.6,7.3L9,26.6
                  l0.5,1.6l4.4-1.2c5-1.4,9.6-4.1,13.4-7.7l1.4-1.4V19.1z"></path>
                  <path d="M12.6,29.9H9.3c-0.5,0-0.8,0.4-0.8,0.8V44c0,0.5,0.4,0.8,0.8,0.8h3.4c0.5,0,0.8-0.4,0.8-0.8V30.7
                  C13.5,30.3,13.1,29.9,12.6,29.9z M11.8,43.2h-1.7V31.6h1.7V43.2z"></path>
                  <path d="M19.3,28.2H16c-0.5,0-0.8,0.4-0.8,0.8v15c0,0.5,0.4,0.8,0.8,0.8h3.4c0.5,0,0.8-0.4,0.8-0.8v-15
                  C20.2,28.6,19.8,28.2,19.3,28.2z M18.5,43.2h-1.7V29.9h1.7V43.2z"></path>
                  <path d="M26.1,24.9h-3.4c-0.5,0-0.8,0.4-0.8,0.8V44c0,0.5,0.4,0.8,0.8,0.8h3.4c0.5,0,0.8-0.4,0.8-0.8V25.8
                  C26.9,25.3,26.5,24.9,26.1,24.9z M25.2,43.2h-1.7V26.6h1.7V43.2z"></path>
                  <path d="M32.8,21.6h-3.4c-0.5,0-0.8,0.4-0.8,0.8V44c0,0.5,0.4,0.8,0.8,0.8h3.4c0.5,0,0.8-0.4,0.8-0.8V22.4
                  C33.6,22,33.2,21.6,32.8,21.6z M31.9,43.2h-1.7V23.3h1.7V43.2z"></path>
                  <rect x="8.5" y="10" width="1.7" height="1.7"></rect>
                  <rect x="11.8" y="10" width="6.7" height="1.7"></rect>
                  <rect x="8.5" y="13.3" width="10.1" height="1.7"></rect>
                  <rect x="8.5" y="16.6" width="3.4" height="1.7"></rect>
                  <rect x="13.5" y="16.6" width="5" height="1.7"></rect>
                  <rect x="36.1" y="2.5" width="1.7" height="1.7"></rect>
                  <path d="M42.8,8.3h1.7v-5c0-0.5-0.4-0.8-0.8-0.8h-4.2v1.7h3.4V8.3z"></path>
                </g>
              </svg>
          `
				},
				{
					title: "Efficient Customer Service & Data Security",
					text: "Utilize ERP software development to aid client-facing personnel with access to updated and accurate data. Convert after-sales services to upsell further based on recent client-side requirements. Our service ensures data security of highest standard for your business to functions fearlessly.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M47.9,46.8c0,0.6-0.5,1-1,1H3.2c-0.6,0-1-0.5-1-1V18l16,12.4L5.5,43L7,44.5l12.8-12.8l4.6,3.5c0.4,0.3,0.9,0.3,1.3,0
                  l4.6-3.5L43,44.5l1.5-1.5L31.8,30.4l3.2-2.4l-1.3-1.6L25,33L8.4,20.2V2.1h20.8V0H8.4C7.2,0,6.3,1,6.3,2.1v12.5H3.2
                  C1.4,14.6,0,16,0,17.7v29.1C0,48.6,1.4,50,3.2,50h43.7c1.7,0,3.1-1.4,3.1-3.1V25h-2.1V46.8z M6.3,18.6l-2.5-1.9h2.5V18.6z"></path>
                  <path d="M47.9,12.5V9.4c0-0.6-0.5-1-1-1h-2.1V3.2c0-1.1-0.9-2.1-2.1-2.1h-8.3c-1.1,0-2.1,0.9-2.1,2.1v5.2h-2.1c-0.6,0-1,0.5-1,1
                  v3.1h-2.1v2.1h2.1v6.2c0,1.7,1.4,3.1,3.1,3.1h12.5c1.7,0,3.1-1.4,3.1-3.1v-6.2H50v-2.1H47.9z M34.4,3.2C34.4,3.2,34.4,3.2,34.4,3.2
                  l8.3-0.1c0,0,0,0,0.1,0v5.2h-8.3V3.2z M31.2,10.4h14.6v2.1H31.2V10.4z M45.8,20.8c0,0.6-0.5,1-1,1H32.3c-0.6,0-1-0.5-1-1v-6.2h14.6
                  V20.8z"></path>
                  <rect x="37.5" y="16.7" width="2.1" height="2.1"></rect>
                  <path d="M19.8,9.4c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2S19.8,11.7,19.8,9.4z M13.6,9.4c0-1.1,0.9-2.1,2.1-2.1
                  c1.1,0,2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1C14.5,11.5,13.6,10.5,13.6,9.4z"></path>
                  <rect x="11.5" y="15.6" width="14.6" height="2.1"></rect>
                  <rect x="17.7" y="19.8" width="8.3" height="2.1"></rect>
                  <rect x="24" y="24" width="2.1" height="2.1"></rect>
                  <rect x="19.8" y="24" width="2.1" height="2.1"></rect>
                </g>
              </svg>
          `
				},
				{
					title: "Process Standardisation",
					text: "Successful custom ERP development results in standardising process across multiple functionary departments that work in parallel. With benchmarks set, ERP software development ensures hitting those standards time and again to effectively improve your work rate with quality.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M36.5,50H3.1C1.4,50,0,48.6,0,46.9V9.4c0-0.3,0.1-0.5,0.3-0.7l8.3-8.3C8.8,0.1,9.1,0,9.4,0h27.1c1.7,0,3.1,1.4,3.1,3.1v4.2
                  c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1V3.1c0-0.6-0.5-1-1-1H9.8L2.1,9.8v37.1c0,0.6,0.5,1,1,1h33.3c0.6,0,1-0.5,1-1v-4.2
                  c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v4.2C39.6,48.6,38.2,50,36.5,50z"></path>
                  <path d="M9.4,10.4H1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h7.3V1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1v8.3C10.4,10,10,10.4,9.4,10.4z"></path>
                  <path d="M49,18.8H38.5c-0.6,0-1-0.5-1-1v-6.3c0-0.6,0.5-1,1-1H49c0.6,0,1,0.5,1,1v6.3C50,18.3,49.5,18.8,49,18.8z M39.6,16.7h8.3
                  v-4.2h-8.3V16.7z"></path>
                  <path d="M49,29.2H38.5c-0.6,0-1-0.5-1-1v-6.3c0-0.6,0.5-1,1-1H49c0.6,0,1,0.5,1,1v6.3C50,28.7,49.5,29.2,49,29.2z M39.6,27.1h8.3
                  v-4.2h-8.3V27.1z"></path>
                  <path d="M49,39.6H38.5c-0.6,0-1-0.5-1-1v-6.3c0-0.6,0.5-1,1-1H49c0.6,0,1,0.5,1,1v6.3C50,39.1,49.5,39.6,49,39.6z M39.6,37.5h8.3
                  v-4.2h-8.3V37.5z"></path>
                  <path d="M21.9,29.2H11.5c-0.6,0-1-0.5-1-1v-6.3c0-0.6,0.5-1,1-1h10.4c0.6,0,1,0.5,1,1v6.3C22.9,28.7,22.5,29.2,21.9,29.2z
                  M12.5,27.1h8.3v-4.2h-8.3V27.1z"></path>
                  <path d="M38.5,36.5h-6.3c-1.7,0-3.1-1.4-3.1-3.1V16.7c0-1.7,1.4-3.1,3.1-3.1h6.3c0.6,0,1,0.5,1,1s-0.5,1-1,1h-6.3c-0.6,0-1,0.5-1,1
                  v16.7c0,0.6,0.5,1,1,1h6.3c0.6,0,1,0.5,1,1C39.6,36,39.1,36.5,38.5,36.5z"></path>
                  <path d="M38.5,26H21.9c-0.6,0-1-0.5-1-1s0.5-1,1-1h16.7c0.6,0,1,0.5,1,1S39.1,26,38.5,26z"></path>
                </g>
              </svg>
          `
				},
				{
					title: "Streamlining Process",
					text: "Offer your enterprise a structured data flow with multi-functions integration into a unified platform. Our ERP development service enables streamlining data assessment which in turn help you simplify parallel processes across multiple departments.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M48.7,21.5c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V14c0-0.2-0.1-0.5-0.3-0.7C49.2,13.1,49,13,48.7,13h-11
                  c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v2.4h-3.1v-5.7c0-0.3-0.2-0.6-0.5-0.8l-4.7-2.7h11.4c0.2,0,0.5-0.1,0.7-0.3
                  c0.2-0.2,0.3-0.4,0.3-0.7V0.7c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-3.3c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9H39v3.7
                  H11V1.6H30c0.5,0,0.9-0.4,0.9-0.9S30.5-0.2,30-0.2H10.1c-0.2,0-0.5,0.1-0.7,0.3C9.3,0.2,9.2,0.5,9.2,0.7v5.5c0,0.2,0.1,0.5,0.3,0.7
                  c0.2,0.2,0.4,0.3,0.7,0.3h11.4l-4.7,2.7c-0.3,0.2-0.5,0.5-0.5,0.8v5.7h-3.1V14c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-11
                  c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v6.6c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h4.6v2.6H1.3
                  c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v6.6c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h4.6v2.6H1.3
                  c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v6.6c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h11c0.2,0,0.5-0.1,0.7-0.3
                  c0.2-0.2,0.3-0.4,0.3-0.7V36c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3H7.7v-2.6h4.6c0.2,0,0.5-0.1,0.7-0.3
                  c0.2-0.2,0.3-0.4,0.3-0.7v-2.4H24v10.3H20c-2.3,0-4.2,1.9-4.2,4.2V46c0,2.3,1.9,4.2,4.2,4.2H30c2.3,0,4.2-1.9,4.2-4.2v-2.4
                  c0-2.3-1.9-4.2-4.2-4.2h-4.1V29.2h10.9v2.4c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h4.6v2.6h-4.6c-0.2,0-0.5,0.1-0.7,0.3
                  c-0.2,0.2-0.3,0.4-0.3,0.7v6.6c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h11c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
                  V36c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-4.6v-2.6h4.6c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V25
                  c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-4.6v-2.6H48.7z M47.8,25.9v4.8h-9.2v-4.8H47.8z M37.7,24.1
                  c-0.2,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7v2.4H25.9v-3l7.3-4.1c0.3-0.2,0.5-0.5,0.5-0.8v-1.3h3.1v2.4
                  c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h4.6v2.6H37.7z M2.2,30.7v-4.8h9.2v4.8H2.2z M13,24.3c-0.2-0.2-0.4-0.3-0.7-0.3H7.7
                  v-2.6h4.6c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-2.4h3.1v1.3c0,0.3,0.2,0.6,0.5,0.8l7.3,4.1v3H13.2V25
                  C13.2,24.8,13.1,24.5,13,24.3z M2.2,19.7v-4.8h9.2v1.5H7.9c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h3.5v1.5H2.2z M31.8,18.9
                  L25,22.8l-6.8-3.9v-7.8L25,7.3l6.8,3.9V18.9z M38.6,19.7v-1.5h3.5c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-3.5v-1.5h9.2v4.8H38.6z
                  M11.4,37v4.8H2.2V37H11.4z M30,41.4c0.6,0,1.2,0.2,1.6,0.7c0.4,0.4,0.7,1,0.7,1.6V46c0,0.6-0.2,1.2-0.7,1.6
                  c-0.4,0.4-1,0.7-1.6,0.7H20c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.7-1-0.7-1.6v-2.4c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7H30z
                  M47.8,37v4.8h-9.2V37H47.8z"></path>
                  <path d="M33.3,1.6c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.4-0.9,0.9S32.8,1.6,33.3,1.6z"></path>
                  <rect x="21.9" y="11.4" width="3" height="3"></rect>
                  <rect x="25.2" y="11.4" width="3" height="3"></rect>
                  <rect x="21.9" y="14.7" width="3" height="3"></rect>
                  <rect x="25.2" y="14.7" width="3" height="3"></rect>
                </g>
              </svg>
          `
				},
				{
					title: "User-Friendly ERP Solutions",
					text: "Our custom ERP software development company provide easy to learn ERP modules for teams to adapt easily. Quality video support &amp; visual processes let you monitor multiple real-time end-to-end with easy UI and flexible workarounds put integrated business intelligence to daily use.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M37.4,8.4c-1.4,0-2.8,0.5-3.8,1.5H9.9c-0.8,0-1.4,0.7-1.4,1.5v5.5c0,0.8,0.6,1.5,1.4,1.5h23.7c1,0.9,2.4,1.5,3.8,1.5
                c3.2,0,5.8-2.6,5.7-5.8C43.1,11,40.6,8.4,37.4,8.4z M10.2,11.5h22.1c-0.9,1.7-0.9,3.6,0,5.3H10.2V11.5z M37.4,18.3
                c-2.3,0-4.1-1.8-4.2-4.1c0-2.3,1.8-4.1,4.1-4.2c2.3,0,4.1,1.8,4.2,4.1c0,0,0,0,0,0C41.5,16.4,39.7,18.3,37.4,18.3z"></path>
                <path d="M25,19.1c-3.2,0-5.8,2.6-5.8,5.8s2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8c0,0,0,0,0,0C30.8,21.7,28.2,19.1,25,19.1z M25,29
                c-2.3,0-4.1-1.8-4.1-4.1s1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C29.1,27.1,27.3,29,25,29z"></path>
                <path d="M39.5,13.3c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c0.1,0,0.3,0,0.4,0l0.9,1.2l1.3-1l-0.8-1
                C39.3,14.5,39.5,13.9,39.5,13.3z M36.2,13.3c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8
                C36.6,14.1,36.2,13.8,36.2,13.3z"></path>
                <path d="M25,33.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5C27.5,34.6,26.4,33.5,25,33.5z M25,36.8
                c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8C25.8,36.5,25.5,36.8,25,36.8z"></path>
                <path d="M23.6,25.2l-0.9-1.7l-1.5,0.7l1.3,2.6c0.1,0.2,0.3,0.4,0.6,0.4c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.2l4.6-3.6l-1-1.3
                L23.6,25.2z"></path>
                <path d="M48.9,1.8H1.1c-0.5,0-0.8,0.4-0.8,0.8c0,0,0,0,0,0v37.1c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0h19.3v4.5h-8.6v1.6h26.4v-1.6
                h-8.6v-4.5h19.3c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V2.6C49.7,2.2,49.4,1.8,48.9,1.8C48.9,1.8,48.9,1.8,48.9,1.8z M27.9,45.1h-5.9
                v-4.5h5.9V45.1z M48.1,38.9H1.9V3.4h46.2V38.9z"></path>
                <path d="M4.4,33.1h41.2c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0V5.9c0-0.5-0.4-0.8-0.8-0.8c0,0,0,0,0,0H4.4c-0.5,0-0.8,0.4-0.8,0.8
                c0,0,0,0,0,0v26.4C3.6,32.7,3.9,33.1,4.4,33.1C4.4,33.1,4.4,33.1,4.4,33.1z M5.2,6.7h39.6v24.7H5.2V6.7z"></path>
              </svg>
          `
				},
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
