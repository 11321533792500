<template>
	<div class="contentrow align-items-center">
		<img
			alt="instant messaging app development"
			:src="require(`../../../assets/images${obj.imageLink}`)"
		/>
		<div class="tab_content_wrap">
			<div class="accordian_header">{{ obj.title }}</div>
			<div class="tab_content clearfix">
				<div class="tab_inner_content">
					<p v-for="paragraph in obj.paragraphs" :key="paragraph">
						{{ paragraph }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.ins-msg-content-wrap .contentrow {
	background-color: #fff;
	padding: 30px 20px;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	display: none;
}
.ins-msg-content-wrap .contentrow.active {
	display: flex;
}
.ins-msg-content-wrap .contentrow img {
	margin-left: -100px;
	width: 212px;
}
img {
	max-width: 100%;
	height: auto;
}
.ins-msg-content-wrap .tab_content_wrap {
	width: calc(100% - 112px);
}
.ins-msg-content-wrap .accordian_header {
	display: block;
}
.ins-msg-content-wrap .accordian_header {
	margin-left: 25px;
	padding-bottom: 10px;
	font-size: 21px;
	font-weight: 500;
	margin-bottom: 15px;
	position: relative;
}
.ins-msg-content-wrap .accordian_header:before {
	content: '';
	display: block;
	height: 2px;
	background-color: #0082dd;
	width: 30%;
	position: absolute;
	bottom: 0;
	border-radius: 10px;
}
.ins-msg-content-wrap .tab_content {
	margin-left: 25px;
}
p {
	font: normal 14px/22px Montserrat, sans-serif;
	color: #6a6d6f;
}
.ins-msg-content-wrap .tab_content p:last-child {
	margin-bottom: 0;
}
.clearfix::after {
	display: block;
	clear: both;
	content: '';
}
</style>