<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our offerings</span>
				<h2 class="fxt-process-dev">Our suite of CodeIgniter Development Services</h2>
				<p>
					Our wide-range of web development services will help you make the best use of CodeIgniter
					anyway you want.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../../MobileDevelopment/AssetComponents/RoundedCard';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Backend for Mobile Apps",
               text:"Our developers use highly reliable PHP Codeigniter framework for the backend for feature-rich mobile application development solutions.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <path class="st0" d="M31.1,19.7c0-2-3-3-6.1-3s-6.1,1-6.1,3v10.6c0,1.9,3,2.9,6.1,2.9s6.1-1,6.1-2.9V19.7z M20.5,21.8
                     c1.4,0.7,3,1,4.5,1c1.6,0,3.1-0.3,4.5-1v3.6c-0.5,0.5-2,1.2-4.5,1.2s-4.1-0.7-4.5-1.2V21.8z M25,18.2c2.8,0,4.5,1,4.5,1.5
                     s-1.7,1.5-4.5,1.5s-4.5-1-4.5-1.5S22.2,18.2,25,18.2z M25,31.6c-3,0-4.5-1-4.5-1.4v-3.1c1.4,0.6,3,0.9,4.5,0.9
                     c1.6,0,3.1-0.3,4.5-0.9v3.1C29.5,30.7,28,31.6,25,31.6z M34.8,0H15.2c-2.5,0-4.5,2-4.5,4.5v40.9c0,2.5,2,4.5,4.5,4.5h19.7
                     c2.5,0,4.5-2,4.5-4.5V4.5C39.4,2,37.4,0,34.8,0z M37.9,45.5c0,1.7-1.4,3-3,3H15.2c-1.7,0-3-1.4-3-3V4.5c0-1.7,1.4-3,3-3h19.7
                     c1.7,0,3,1.4,3,3V45.5z M29.5,46.2c0,0.4-0.3,0.8-0.8,0.8h-7.6c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8h7.6
                     C29.2,45.5,29.5,45.8,29.5,46.2z M33.3,3H16.7c-1.7,0-3,1.4-3,3v34.8c0,1.7,1.4,3,3,3h16.7c1.7,0,3-1.4,3-3V6.1
                     C36.4,4.4,35,3,33.3,3z M34.8,40.9c0,0.8-0.7,1.5-1.5,1.5H16.7c-0.8,0-1.5-0.7-1.5-1.5V6.1c0-0.8,0.7-1.5,1.5-1.5h16.7
                     c0.8,0,1.5,0.7,1.5,1.5V40.9z"></path>
                  </svg>
               `
            },
            {
               title:"Custom e-Commerce Solution",
               text:"Build a single vendor or multi-vendor online store that can sell to the world at large with payment integrations, product catalog and high-end security.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M46.2,0.4H3.9C2.3,0.4,1,1.7,1,3.2v32.7c0,1.6,1.3,2.8,2.8,2.8h16.3v5.2h-3.3c-1.2,0-2.2,1-2.2,2.2v2.3
                           c0,0.5,0.3,0.8,0.8,0.8h19.3c0.5,0,0.8-0.3,0.8-0.8v-2.3c0-1.2-1-2.2-2.2-2.2H30v-5.2h16.3c1.6,0,2.8-1.3,2.8-2.8V3.2
                           C49,1.7,47.7,0.4,46.2,0.4z M3.9,1.9h42.2c0.8,0,1.3,0.6,1.3,1.3v27.6H2.5V3.2C2.6,2.5,3.2,1.9,3.9,1.9z M33.9,46.1v1.5H16.1v-1.5
                           c0-0.4,0.3-0.7,0.7-0.7h16.4C33.6,45.4,33.9,45.7,33.9,46.1z M28.4,43.9h-6.7v-5.2h6.7V43.9z M46.2,37.2H3.9
                           c-0.8,0-1.3-0.6-1.3-1.3v-3.5h44.9v3.5C47.5,36.5,46.9,37.2,46.2,37.2z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M27.7,34h-5.3c-0.5,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h5.3c0.5,0,0.8-0.3,0.8-0.8C28.4,34.3,28.1,34,27.7,34z
                           "></path>
                        </g>
                        <g>
                           <path class="st0" d="M5.5,28.6h39c0.5,0,0.8-0.3,0.8-0.8c0-13.8,0-14.2,0-22.9c0-0.5-0.3-0.8-0.8-0.8h-39C5.1,4.2,4.8,4.5,4.8,5
                           c0,8.2,0,13.9,0,22.9C4.8,28.3,5.1,28.6,5.5,28.6z M6.3,27.1V10.5h37.4v16.6H6.3z M6.3,5.6h37.4V9H6.3V5.6z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M10,6.6H9.8C9.3,6.6,9,6.9,9,7.3c0,0.5,0.3,0.8,0.8,0.8H10c0.5,0,0.8-0.3,0.8-0.8C10.8,6.9,10.5,6.6,10,6.6z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M13.7,6.6h-0.2c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h0.2c0.5,0,0.8-0.3,0.8-0.8
                           C14.4,6.9,14.1,6.6,13.7,6.6z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M17.3,6.6h-0.2c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h0.2c0.5,0,0.8-0.3,0.8-0.8
                           C18.1,6.9,17.7,6.6,17.3,6.6z"></path>
                        </g>
                        <g>
                           <path class="st0" d="M40.2,6.6H23.1c-0.5,0-0.8,0.3-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8h17.1c0.5,0,0.8-0.3,0.8-0.8
                           C41,6.9,40.6,6.6,40.2,6.6z"></path>
                        </g>
                        <path class="st0" d="M14.3,15.7c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1S16,15.7,14.3,15.7z M14.3,21.1
                        c-1.3,0-2.3-1-2.3-2.3s1-2.3,2.3-2.3s2.3,1,2.3,2.3S15.6,21.1,14.3,21.1z"></path>
                        <path class="st0" d="M21.3,18.4c0-0.4-0.3-0.8-0.7-0.8h-1c-0.1-0.6-0.4-1.2-0.7-1.8l0.7-0.7c0.3-0.3,0.2-0.7-0.1-1L19,13.6
                        c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.3,0.1-0.5,0.2l-0.7,0.7c-0.5-0.3-1.1-0.6-1.8-0.7v-1c0-0.4-0.3-0.7-0.8-0.7h-0.8
                        c-0.4,0-0.8,0.3-0.8,0.7v1c-0.6,0.1-1.2,0.4-1.8,0.7l-0.7-0.7c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.2l-0.5,0.5
                        c-0.3,0.3-0.3,0.8-0.1,1l0.7,0.7C9.4,16.4,9.2,17,9,17.6H8c-0.4,0-0.7,0.3-0.7,0.8v0.8C7.3,19.6,7.7,20,8,20h1
                        c0.1,0.6,0.4,1.2,0.7,1.8l-0.7,0.7c-0.3,0.3-0.2,0.7,0.1,1L9.7,24c0.2,0.2,0.4,0.2,0.6,0.2c0.2,0,0.3-0.1,0.5-0.2l0.7-0.7
                        c0.5,0.3,1.1,0.6,1.8,0.7v1c0,0.4,0.3,0.7,0.8,0.7h0.8c0.4,0,0.8-0.3,0.8-0.7v-1c0.6-0.1,1.2-0.4,1.8-0.7l0.7,0.7
                        c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.2l0.5-0.5c0.3-0.3,0.3-0.8,0.1-1l-0.7-0.7c0.3-0.5,0.6-1.1,0.7-1.8h1
                        c0.4,0,0.7-0.3,0.7-0.8L21.3,18.4L21.3,18.4z M20.5,19.2h-0.9H19l-0.1,0.6c-0.1,0.5-0.3,1-0.6,1.5l-0.3,0.5l0.4,0.4l0.6,0.6
                        c0,0,0,0,0,0l-0.5,0.5c0,0,0,0,0,0l-0.6-0.6l-0.4-0.4l-0.5,0.3c-0.5,0.3-1,0.5-1.5,0.6l-0.6,0.1v0.6V25h-0.8v-0.9v-0.6l-0.6-0.1
                        c-0.5-0.1-1-0.3-1.5-0.6l-0.5-0.3l-0.4,0.4l-0.6,0.6c0,0,0,0,0,0l-0.5-0.5c0,0,0,0,0,0l0.6-0.6l0.4-0.4l-0.3-0.5
                        c-0.3-0.5-0.5-1-0.6-1.5l-0.1-0.6H9H8.1v-0.8H9h0.6l0.1-0.6c0.1-0.5,0.3-1,0.6-1.5l0.3-0.5l-0.4-0.4l-0.6-0.6c0,0,0,0,0,0l0.5-0.5
                        c0,0,0,0,0,0l0.6,0.6l0.4,0.4l0.5-0.3c0.5-0.3,1-0.5,1.5-0.6l0.6-0.1v-0.6v-0.9h0.8v0.9v0.6l0.6,0.1c0.5,0.1,1,0.3,1.5,0.6l0.5,0.3
                        l0.4-0.4l0.6-0.6c0,0,0,0,0,0l0.5,0.5c0,0,0,0,0,0l-0.6,0.6l-0.4,0.4l0.3,0.5c0.3,0.5,0.5,1,0.6,1.5l0.1,0.6h0.6h0.9V19.2z"></path>
                        <path class="st0" d="M24.2,20.8c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C26,21.6,25.2,20.8,24.2,20.8z
                        M24.2,23.9c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C25.5,23.3,24.9,23.9,24.2,23.9z"></path>
                        <path class="st0" d="M28.2,22.4c0-0.2-0.2-0.4-0.4-0.4h-0.6c-0.1-0.4-0.2-0.7-0.4-1l0.4-0.4c0.2-0.2,0.1-0.4,0-0.6l-0.3-0.3
                        c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1L25.9,20c-0.3-0.2-0.6-0.3-1-0.4V19c0-0.2-0.2-0.4-0.4-0.4H24
                        c-0.2,0-0.4,0.2-0.4,0.4v0.6c-0.4,0.1-0.7,0.2-1,0.4l-0.4-0.4c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1l-0.3,0.3
                        c-0.2,0.2-0.2,0.4,0,0.6l0.4,0.4c-0.2,0.3-0.3,0.6-0.4,1h-0.6c-0.2,0-0.4,0.2-0.4,0.4v0.4c0,0.2,0.2,0.4,0.4,0.4h0.6
                        c0.1,0.4,0.2,0.7,0.4,1l-0.4,0.4c-0.2,0.2-0.1,0.4,0,0.6l0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l0.4-0.4
                        c0.3,0.2,0.6,0.3,1,0.4v0.6c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4v-0.6c0.4-0.1,0.7-0.2,1-0.4l0.4,0.4
                        c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.3c0.2-0.2,0.2-0.4,0-0.6l-0.4-0.4c0.2-0.3,0.3-0.6,0.4-1h0.6
                        c0.2,0,0.4-0.2,0.4-0.4L28.2,22.4L28.2,22.4z M27.7,22.8h-0.5h-0.4l-0.1,0.3c-0.1,0.3-0.2,0.6-0.4,0.9l-0.2,0.3l0.2,0.2l0.4,0.4
                        c0,0,0,0,0,0l-0.3,0.3c0,0,0,0,0,0l-0.4-0.4l-0.2-0.2l-0.3,0.2c-0.3,0.2-0.6,0.3-0.9,0.4l-0.3,0.1v0.4v0.5H24v-0.5v-0.4l-0.3-0.1
                        c-0.3-0.1-0.6-0.2-0.9-0.4l-0.3-0.2l-0.2,0.2l-0.4,0.4c0,0,0,0,0,0l-0.3-0.3c0,0,0,0,0,0l0.4-0.4l0.2-0.2L22,24
                        c-0.2-0.3-0.3-0.6-0.4-0.9l-0.1-0.3h-0.4h-0.5v-0.4h0.5h0.4l0.1-0.3c0.1-0.3,0.2-0.6,0.4-0.9l0.2-0.3l-0.2-0.2l-0.4-0.4
                        c0,0,0,0,0,0l0.3-0.3c0,0,0,0,0,0l0.4,0.4l0.2,0.2l0.3-0.2c0.3-0.2,0.6-0.3,0.9-0.4l0.3-0.1v-0.4v-0.5h0.4v0.5v0.4l0.3,0.1
                        c0.3,0.1,0.6,0.2,0.9,0.4l0.3,0.2l0.2-0.2l0.4-0.4c0,0,0,0,0,0l0.3,0.3c0,0,0,0,0,0l-0.4,0.4l-0.2,0.2l0.2,0.3
                        c0.2,0.3,0.3,0.6,0.4,0.9l0.1,0.3h0.4h0.5V22.8z"></path>
                        <path class="st0" d="M40.8,21.8l-8.5-5.5c0.8-3.4-2.8-6.1-5.8-4.3c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4l1.9,1.4l0,1.1
                        l-1.1,0.3l-1.9-1.4c-0.1-0.1-0.3-0.1-0.4-0.1c-0.2,0-0.3,0.2-0.3,0.3c-0.8,3.4,2.9,6,5.8,4.3l7.8,6.4c0.9,0.8,2.3,0.7,3.1-0.3
                        C42.2,23.9,41.9,22.5,40.8,21.8z M39,24.5l-8-6.7c-0.2-0.1-0.4-0.2-0.6,0c-2,1.5-4.8,0-4.8-2.5l1.4,1.1c0.1,0.1,0.3,0.1,0.4,0.1
                        l1.5-0.6c0.2,0,0.3-0.2,0.3-0.4l0.1-1.6c0-0.2-0.1-0.3-0.2-0.4l-1.4-1.1c2.3-0.7,4.6,1.5,3.8,3.9c-0.1,0.2,0,0.4,0.2,0.5l8.7,5.7
                        c0.6,0.3,0.8,1.2,0.3,1.7C40.3,24.9,39.5,25,39,24.5z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Enterprise Website Development",
               text:"With the huge complication of functionality, we prefer CodeIgniter framework for enterprise web application development solutions.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M45.9,42.1H4c-2.1,0-3.7-1.7-3.7-3.7V11.6c0-2.1,1.7-3.7,3.7-3.7h42c2.1,0,3.7,1.7,3.7,3.7v26.8
                        C49.7,40.4,48,42.1,45.9,42.1z M4,9.3c-1.3,0-2.3,1-2.3,2.3v26.8c0,1.3,1,2.3,2.3,2.3h42c1.3,0,2.3-1,2.3-2.3V11.6
                        c0-1.3-1-2.3-2.3-2.3H4L4,9.3z"></path>
                        <path class="st0" d="M1,16.5h48v4.3H1V16.5z"></path>
                        <path class="st0" d="M4.7,35.7H13v1.4H4.7V35.7L4.7,35.7z"></path>
                        <path class="st0" d="M15.5,35.7H29v1.4H15.5V35.7z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Web Portal Development",
               text:"Develop critical web applications for business productivity, operations or customer engagement with CodeIgniter’s versatility.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <g>
                        <path d="M26.4,73.2H2.2V33.9h6c0-0.1,0-0.2,0-0.3C8.2,16.1,22.5,1.8,40,1.8c17.5,0,31.8,14.2,31.8,31.8S57.5,65.3,40,65.3
                        c-2.7,0-5.4-0.3-8-1v3.5L26.4,73.2z M4.7,70.7h19.5l0-5.1h5.3V36.3H4.7V70.7z M26.6,68.1l0,1.5l1.5-1.5H26.6z M32,61.8
                        c1.5,0.4,3.1,0.7,4.7,0.9c-1.7-1.8-3.3-3.6-4.7-5.4C32,57.3,32,61.8,32,61.8z M54.8,44.5c-2,6.4-5.8,12.5-11.5,18.2
                        c10.8-1.3,19.9-8.4,23.8-18.2C67.2,44.5,54.8,44.5,54.8,44.5z M41.2,44.5v16.9c5.4-5.3,9.1-11,11-16.9H41.2z M32,53
                        c1.8,2.8,4.1,5.6,6.8,8.3V44.5H32V53z M55.5,42h12.6c0.8-2.7,1.2-5.5,1.2-8.4c0-2.9-0.4-5.8-1.3-8.5H55.4c0.7,3,1.1,6.1,1.1,9.5
                        C56.4,37.1,56.1,39.6,55.5,42z M41.2,42H53c0.6-2.5,1-5,1-7.5c0.1-3.3-0.4-6.5-1.2-9.4H41.2V42z M32,42h6.8V25.1H27.3
                        c-0.7,2.8-1.2,5.7-1.2,8.8H32V42z M10.7,33.9h13c0-3.1,0.4-6,1.1-8.8H11.9c-0.8,2.7-1.3,5.5-1.3,8.5C10.7,33.7,10.7,33.8,10.7,33.9
                        z M54.7,22.7h12.5C63.4,13.3,55,6.3,44.8,4.7C47.5,8,52.2,14.4,54.7,22.7z M41.2,22.7h10.9C49,13.1,42.9,6.1,41.2,4.4V22.7z
                        M28,22.7h10.8V4.5C37,6.4,31.1,13.3,28,22.7z M12.8,22.7h12.6c2.5-8.3,7.2-14.7,9.9-18C25.1,6.3,16.6,13.3,12.8,22.7z"></path>
                     </g>
                  </svg>
               `
            },
            {
               title:"Custom Web Applications",
               text:"Our proficient team of developers create custom web application as per business requirement with best UI/X Design.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                     <g>
                        <g>
                           <g>
                              <path d="M34.4,39.9c-0.3-0.4-0.9-0.4-1.2-0.1l-5.6,4.8c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7l5.6,4.8
                              c0.4,0.3,0.9,0.3,1.2-0.1c0.3-0.4,0.3-0.9-0.1-1.2l-4.8-4.2l4.8-4.2C34.6,40.9,34.7,40.3,34.4,39.9z"></path>
                           </g>
                           <g>
                              <path d="M45.8,39.9c-0.4-0.3-0.9-0.3-1.2,0.1c-0.3,0.4-0.3,0.9,0.1,1.2l4.8,4.2l-4.8,4.2c-0.4,0.3-0.4,0.9-0.1,1.2
                              c0.3,0.4,0.9,0.4,1.2,0.1l5.6-4.8c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L45.8,39.9z"></path>
                           </g>
                           <g>
                              <path d="M42.7,37.1c-0.4-0.2-0.9,0-1.1,0.5l-5.8,14.9c-0.2,0.4,0,0.9,0.5,1.1c0.5,0.2,1-0.1,1.1-0.5l5.8-14.9
                              C43.3,37.8,43.1,37.3,42.7,37.1z"></path>
                           </g>
                           <g>
                              <path d="M62.4,23.8H16.6c-1.4,0-2.5,1.1-2.5,2.5c0,10.3,0,23.5,0,29.4c0,1.4,1.1,2.5,2.5,2.5h45.8c1.4,0,2.5-1.1,2.5-2.5
                              c0-5.9,0-19.1,0-29.4C64.9,24.9,63.7,23.8,62.4,23.8z M15.8,26.3c0-0.4,0.3-0.8,0.8-0.8h45.8c0.4,0,0.8,0.3,0.8,0.8v4.9H15.8
                              V26.3z M62.4,56.4H16.6c-0.4,0-0.8-0.3-0.8-0.8V32.9h47.3v22.8C63.1,56.1,62.8,56.4,62.4,56.4z"></path>
                           </g>
                           <g>
                              <path d="M73.1,62.2h-1.8V22.4c0-2.4-1.9-4.3-4.3-4.3H18.9l-0.3-0.5c0.3-0.5,0.5-1,0.6-1.5l1.4-0.2c0.4-0.1,0.7-0.4,0.7-0.9v-3
                              c0-0.4-0.3-0.8-0.7-0.9l-1.4-0.3c-0.2-0.5-0.4-1-0.6-1.5l0.8-1.2c0.2-0.3,0.2-0.8-0.1-1.1l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1-0.1
                              l-1.2,0.8c-0.5-0.3-1-0.5-1.5-0.6L13,3.5c-0.1-0.4-0.4-0.7-0.9-0.7H9.1c-0.4,0-0.8,0.3-0.9,0.7L8,4.9C7.5,5.1,7,5.3,6.5,5.5
                              L5.3,4.7C5,4.5,4.5,4.5,4.2,4.8L2,7C1.8,7.3,1.7,7.7,2,8.1l0.8,1.2c-0.3,0.5-0.5,1-0.6,1.5l-1.4,0.3C0.3,11.1,0,11.5,0,11.9v3
                              c0,0.4,0.3,0.8,0.7,0.9l1.4,0.2c0.2,0.5,0.4,1,0.6,1.5L2,18.8c-0.2,0.3-0.2,0.8,0.1,1.1l2.2,2.2c0.3,0.3,0.8,0.3,1.1,0.1l1.2-0.8
                              c0.4,0.2,0.8,0.4,1.2,0.5c0,0.2-0.1,0.4-0.1,0.6v39.8H5.7c-0.5,0-0.9,0.4-0.9,0.9c0,4.3,3.5,7.7,7.7,7.7h53.8
                              c4.2,0,7.7-3.5,7.7-7.7C74,62.5,73.6,62.2,73.1,62.2z M6,19.6l-1.1,0.7l-1.1-1.1l0.7-1c0.2-0.3,0.2-0.7,0-0.9
                              c-0.4-0.7-0.7-1.4-0.9-2.1c-0.1-0.3-0.4-0.6-0.7-0.7l-1.3-0.2v-1.6L3,12.4c0.3-0.1,0.6-0.3,0.7-0.7c0.2-0.7,0.5-1.4,0.9-2.1
                              c0.2-0.3,0.2-0.7,0-1L3.8,7.7l1.1-1.1L6,7.3c0.3,0.2,0.7,0.2,0.9,0C7.6,6.9,8.2,6.6,9,6.5c0.3-0.1,0.6-0.4,0.7-0.7l0.2-1.3h1.6
                              l0.2,1.3c0.1,0.3,0.3,0.6,0.7,0.7c0.7,0.2,1.4,0.4,2.1,0.8c0.3,0.2,0.7,0.2,0.9,0l1-0.7l1.1,1.1l-0.7,1.1c-0.2,0.3-0.2,0.7,0,1
                              c0.4,0.7,0.7,1.3,0.9,2.1c0.1,0.3,0.4,0.6,0.7,0.7l1.3,0.2v1.6l-1.3,0.2c-0.3,0.1-0.6,0.3-0.7,0.7c-0.2,0.7-0.4,1.4-0.9,2.1
                              c-0.2,0.3-0.2,0.7,0,0.9l0.7,1l-1.1,1.1l-1-0.7c-0.3-0.2-0.7-0.2-0.9,0c-0.7,0.4-1.3,0.7-2.1,0.9c-0.3,0.1-0.6,0.4-0.7,0.7
                              l-0.2,1.3H9.9l-0.2-1.3c-0.1-0.3-0.3-0.6-0.7-0.7c-0.7-0.2-1.4-0.5-2.1-0.9C6.6,19.4,6.3,19.4,6,19.6z M9.4,24.1h2.8
                              c0.4,0,0.8-0.3,0.9-0.7l0.3-1.4c0.5-0.2,1-0.4,1.5-0.6l1.2,0.8c0.3,0.2,0.8,0.2,1.1-0.1l2.2-2.2c0,0,0-0.1,0.1-0.1H67
                              c1.4,0,2.6,1.2,2.6,2.6v39.8H9.4V24.1z M66.3,69H12.5c-3,0-5.5-2.2-5.9-5.1c0.1,0,70,0,65.6,0C71.8,66.8,69.3,69,66.3,69z"></path>
                           </g>
                           <g>
                              <path d="M39.7,64.2c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3C42,65.2,41,64.2,39.7,64.2z M39.7,67
                              c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C40.2,66.7,40,67,39.7,67z"></path>
                           </g>
                           <g>
                              <path d="M19.9,25.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C22.3,27,21.2,25.9,19.9,25.9z M19.9,29
                              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C20.6,28.7,20.3,29,19.9,29z"></path>
                           </g>
                           <g>
                              <path d="M24.9,25.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C27.4,27,26.3,25.9,24.9,25.9z M24.9,29
                              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7s0.7,0.3,0.7,0.7C25.6,28.7,25.3,29,24.9,29z"></path>
                           </g>
                           <g>
                              <path d="M30,25.9c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4C32.4,27,31.3,25.9,30,25.9z M30,29
                              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C30.7,28.7,30.4,29,30,29z"></path>
                           </g>
                           <g>
                              <path d="M59.8,27.5H39.5c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h20.3c0.5,0,0.9-0.4,0.9-0.9S60.3,27.5,59.8,27.5z"></path>
                           </g>
                           <g>
                              <path d="M16,13.4c0-3-2.4-5.4-5.4-5.4c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4C13.6,18.8,16,16.4,16,13.4z M7,13.4
                              c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6C8.7,17.1,7,15.4,7,13.4z"></path>
                           </g>
                        </g>
                     </g>
                  </svg>
               `
            },
            {
               title:"Application Maintenance & Support",
               text:"Minimize downtimes, maximize up times and troubleshoot issues instantly with our ‘always-on’ maintenance and support facilities.",
               svg:`
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M37.6,38.6l-16.9,1c-2.3-5.3-9.9-5.4-12.1,0c-0.1,0.2-0.1,0.5,0.1,0.7c0.2,0.2,0.4,0.3,0.6,0.3h3.9l1.1,1.5
                           l-1.1,1.5H9.3c-0.2,0-0.5,0.2-0.6,0.3c-0.2,0.2-0.2,0.5-0.1,0.7c2.3,5.3,9.9,5.3,12.1,0l16.9,1c2,0.2,3.8-1.4,3.8-3.5
                           C41.5,40.1,39.7,38.5,37.6,38.6z M37.7,44.1l-17.5-1c-0.4,0-0.7,0.2-0.8,0.5c-1.3,4-6.5,4.8-9,1.5h3c0.2,0,0.5-0.2,0.6-0.3
                           l1.5-2.3c0.2-0.2,0.2-0.6,0-0.8L14,39.4c-0.2-0.2-0.4-0.3-0.6-0.3h-3c2.4-3.3,7.7-2.5,9,1.5c0.1,0.3,0.4,0.5,0.8,0.5l17.5-1
                           c1.1-0.1,2.2,0.8,2.2,2C40,43.3,39,44.3,37.7,44.1z"></path>
                           <path class="st0" d="M37.7,41.4c-0.5,0-0.8,0.3-0.8,0.8v0.1c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.1
                           C38.5,41.7,38.1,41.4,37.7,41.4z"></path>
                           <path class="st0" d="M25.8,33.5h4.4c0.3,0,0.6-0.2,0.6-0.6s-0.2-0.6-0.6-0.6h-4.4c-0.3,0-0.6,0.2-0.6,0.6S25.5,33.5,25.8,33.5z"></path>
                           <circle class="st0" cx="25.8" cy="3.1" r="0.6"></circle>
                           <circle class="st0" cx="28" cy="3.1" r="0.6"></circle>
                           <circle class="st0" cx="30.2" cy="3.1" r="0.6"></circle>
                           <path class="st0" d="M37.9,2.5c0-1.2-1-2.2-2.2-2.2H20.2c-1.2,0-2.2,1-2.2,2.2v13.1c0.3-0.2,0.7-0.3,1.1-0.3V5.8h17.7v9.5
                           c0.4,0,0.8,0.1,1.1,0.3V2.5z M36.8,4.7H19.2V2.5c0-0.6,0.5-1.1,1.1-1.1h15.5c0.6,0,1.1,0.5,1.1,1.1V4.7z"></path>
                           <path class="st0" d="M18.1,20.4v13.1c0,1.2,1,2.2,2.2,2.2h15.5c1.2,0,2.2-1,2.2-2.2V20.4c-0.3,0.2-0.7,0.3-1.1,0.3v9.5H19.2v-9.5
                           C18.8,20.7,18.4,20.5,18.1,20.4z M36.8,31.3v2.2c0,0.6-0.5,1.1-1.1,1.1H20.2c-0.6,0-1.1-0.5-1.1-1.1v-2.2H36.8z"></path>
                           <path class="st0" d="M28,13.6c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S30.4,13.6,28,13.6z M28,21.3
                           c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S29.8,21.3,28,21.3z"></path>
                           <path class="st0" d="M37.9,17.4c0-0.6-0.4-1.1-1-1.1h-1.4c-0.2-0.9-0.6-1.8-1-2.5l1-1c0.4-0.4,0.3-1-0.1-1.5l-0.8-0.8
                           c-0.2-0.2-0.5-0.4-0.8-0.4c-0.2,0-0.5,0.1-0.7,0.3l-1,1c-0.8-0.5-1.6-0.8-2.5-1V9c0-0.5-0.5-1-1.1-1h-1.1c-0.6,0-1.1,0.4-1.1,1
                           v1.4c-0.9,0.2-1.8,0.6-2.5,1l-1-1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.6,0.1-0.8,0.4l-0.8,0.8c-0.4,0.4-0.5,1.1-0.1,1.5l1,1
                           c-0.5,0.8-0.8,1.6-1,2.5H19c-0.5,0-1,0.5-1,1.1v1.1c0,0.6,0.4,1.1,1,1.1h1.4c0.2,0.9,0.6,1.8,1,2.5l-1,1c-0.4,0.4-0.3,1,0.1,1.5
                           l0.8,0.8c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.7-0.3l1-1c0.8,0.5,1.6,0.8,2.5,1V27c0,0.5,0.5,1,1.1,1h1.1c0.6,0,1.1-0.4,1.1-1
                           v-1.4c0.9-0.2,1.8-0.6,2.5-1l1,1c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.8-0.4l0.8-0.8c0.4-0.4,0.5-1.1,0.1-1.5l-1-1
                           c0.5-0.8,0.8-1.6,1-2.5H37c0.5,0,1-0.5,1-1.1L37.9,17.4L37.9,17.4z M36.8,18.6h-1.3h-0.9l-0.2,0.9c-0.2,0.8-0.5,1.5-0.9,2.2
                           l-0.5,0.7l0.6,0.6l0.9,0.9c0,0,0,0,0,0l-0.8,0.8c0,0,0,0,0,0L33,23.7l-0.6-0.6l-0.7,0.5c-0.7,0.4-1.4,0.7-2.2,0.9l-0.9,0.2v0.9
                           v1.3h-1.1v-1.3v-0.9l-0.9-0.2c-0.8-0.2-1.5-0.5-2.2-0.9l-0.7-0.5L23,23.7l-0.9,0.9c0,0,0,0,0,0l-0.8-0.8c0,0,0,0,0,0l0.9-0.9
                           l0.6-0.6l-0.5-0.7c-0.4-0.7-0.7-1.4-0.9-2.2l-0.2-0.9h-0.9h-1.3v-1.1h1.3h0.9l0.2-0.9c0.2-0.8,0.5-1.5,0.9-2.2l0.5-0.7L22.3,13
                           l-0.9-0.9c0,0,0,0,0,0l0.8-0.8c0,0,0,0,0,0l0.9,0.9l0.6,0.6l0.7-0.5c0.7-0.4,1.4-0.7,2.2-0.9l0.9-0.2v-0.9V9.2h1.1v1.3v0.9
                           l0.9,0.2c0.8,0.2,1.5,0.5,2.2,0.9l0.7,0.5l0.6-0.6l0.9-0.9c0,0,0,0,0,0l0.8,0.8c0,0,0,0,0,0L33.7,13l-0.6,0.6l0.5,0.7
                           c0.4,0.7,0.7,1.4,0.9,2.2l0.2,0.9h0.9h1.3V18.6z"></path>
                        </g>
                     </g>
                  </svg>
               `
            },
            {
               title:"Application Migration",
               text:"We offer Application Migration service from other PHP frameworks to CodeIgniter. Our team will help you migrate your entire web application.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                     <g>
                        <path fill="#DA2436" d="M70,24.8H4.5V13.5H70V24.8z M6.8,22.6h61v-6.8h-61C6.8,15.8,6.8,22.6,6.8,22.6z"></path>
                        <rect x="9" y="18.1" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <rect x="13.5" y="18.1" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <rect x="18.1" y="18.1" fill="#DA2436" width="2.3" height="2.3"></rect>
                     </g>
                     <g>
                        <path fill="#DA2436" d="M0,64.4C0,67.5,2.5,70,5.6,70h33.9c3.1,0,5.6-2.5,5.6-5.6v-5.6H0V64.4z M2.3,61h40.6v3.4
                        c0,1.9-1.5,3.4-3.4,3.4H5.6c-1.9,0-3.4-1.5-3.4-3.4V61H2.3z"></path>
                        <path fill="#DA2436" d="M2.3,9h40.6v2.3h2.3V5.6c0-3.1-2.5-5.6-5.6-5.6h-34C2.5,0,0,2.5,0,5.6v50.8h2.3V9z M5.6,2.3h33.9
                        c1.9,0,3.4,1.5,3.4,3.4v1.1H2.3V5.6C2.3,3.8,3.8,2.3,5.6,2.3z"></path>
                        <rect x="18.1" y="63.2" fill="#DA2436" width="9" height="2.3"></rect>
                        <path fill="#DA2436" d="M49.7,36.4l-3-0.8c-0.1-0.2-0.2-0.4-0.2-0.5l-2,1c0.1,0.3,0.3,0.6,0.4,0.9l0.2,0.6l2.5,0.6V41l-2.5,0.6
                        l-0.2,0.6c-0.1,0.3-0.2,0.6-0.4,0.9l-0.3,0.5l1.3,2.2l-1.9,1.9l-2.2-1.3l-0.6,0.3c-0.3,0.1-0.6,0.3-0.9,0.4l-0.6,0.2l-0.6,2.5
                        h-2.8l-0.6-2.5L34.7,47c-0.3-0.1-0.6-0.2-0.9-0.4l-0.6-0.3L31,47.7l-2.6-2.6l-1.6,1.6l3.8,3.8l2.7-1.6l0.8,3h6.3l0.8-3l2.7,1.6
                        l4.4-4.4l-1.6-2.7l3-0.8V36.4z"></path>
                        <path fill="#DA2436" d="M41.2,30.1l-0.8-3h-6.3l-0.8,3l-2.7-1.6L26.3,33l1.6,2.7l-3,0.8v6.3l3,0.8c0.1,0.2,0.2,0.4,0.2,0.5l2-1
                        c-0.1-0.3-0.3-0.6-0.4-0.9l-0.2-0.6L27,41v-2.8l2.5-0.6l0.2-0.6c0.1-0.3,0.2-0.6,0.4-0.9l0.3-0.5l-1.3-2.2l1.9-1.9l2.2,1.3
                        l0.6-0.3c0.3-0.1,0.6-0.3,0.9-0.4l0.6-0.2l0.6-2.5h2.8l0.6,2.5l0.6,0.2c0.3,0.1,0.6,0.2,0.9,0.4l0.6,0.3l2.2-1.3l2.6,2.6l1.6-1.6
                        L44,28.7L41.2,30.1z"></path>
                        <path fill="#DA2436" d="M37.3,45.2c3.1,0,5.6-2.5,5.6-5.6S40.4,34,37.3,34s-5.6,2.5-5.6,5.6C31.6,42.6,34.1,45.2,37.3,45.2z
                        M37.3,36.1c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4C33.9,37.6,35.4,36.1,37.3,36.1z"></path>
                        <rect x="51.9" y="38.4" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <rect x="56.5" y="38.4" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <rect x="20.3" y="38.4" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <rect x="15.8" y="38.4" fill="#DA2436" width="2.3" height="2.3"></rect>
                        <polygon fill="#DA2436" points="67.7,27.1 67.7,54.2 6.8,54.2 6.8,27.1 4.5,27.1 4.5,56.5 70,56.5 70,27.1     "></polygon>
                     </g>
                  </svg>
               `
            },
            {
               title:"Payment gateway integration",
               text:"We provide various payment gateway and third-party library integration with your online store and CodeIgniter web application.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                     <g>
                        <path fill="#DA2436" d="M65.6,61.3V24.8c0-2.4-2-4.4-4.4-4.4h-5.8v-16C55.4,2,53.5,0,51,0H33.5c-2.4,0-4.4,2-4.4,4.4v4.4h-16
                        c-1.6,0-2.9,1.3-2.9,2.9v8.8H8.8c-2.4,0-4.4,2-4.4,4.4v11.3c-5,4-5.8,11.4-1.8,16.4c0.5,0.7,1.1,1.3,1.8,1.8v6.9
                        c-2.4,0-4.4,2-4.4,4.4C0,68.1,2,70,4.4,70h61.3c2.4,0,4.4-2,4.4-4.4S68,61.3,65.6,61.3z M61.3,23.3c0.8,0,1.5,0.7,1.5,1.5v36.5
                        H7.3V56c6,2.4,12.8-0.5,15.2-6.4c0.2-0.5,0.3-1,0.5-1.4h4.8c2.4,0,4.4-2,4.4-4.4V29.2h4.4c1.6,0,2.9-1.3,2.9-2.9v-2.9L61.3,23.3
                        L61.3,23.3z M32.1,4.4c0-0.8,0.7-1.5,1.5-1.5H51c0.8,0,1.5,0.7,1.5,1.5v16H39.4v-8.8c0-1.6-1.3-2.9-2.9-2.9h-4.4V4.4L32.1,4.4z
                        M13.1,11.7h23.3v2.9H13.1V11.7z M13.1,17.5h23.3v8.8H13.1V17.5z M7.3,24.8c0-0.8,0.7-1.5,1.5-1.5h1.5v2.9c0,1.6,1.3,2.9,2.9,2.9
                        h16v14.6c0,0.8-0.7,1.5-1.5,1.5h-4.4c0-6.4-5.2-11.7-11.7-11.7c-1.5,0-3,0.3-4.4,0.9L7.3,24.8L7.3,24.8z M2.9,45.2
                        c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8s-4,8.8-8.8,8.8S2.9,50,2.9,45.2z M65.6,67.1H4.4c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
                        h21.9v1.5h17.5v-1.5h21.9c0.8,0,1.5,0.7,1.5,1.5S66.4,67.1,65.6,67.1z"></path>
                        <rect x="14.6" y="21.9" fill="#DA2436" width="2.9" height="2.9"></rect>
                        <rect x="19" y="21.9" fill="#DA2436" width="2.9" height="2.9"></rect>
                        <path fill="#DA2436" d="M10.2,52.5h2.9V51h1.5c0.8,0,1.5-0.7,1.5-1.5v-4.4c0-0.8-0.7-1.5-1.5-1.5h-4.4v-1.5H16v-2.9h-2.9v-1.5h-2.9
                        v1.5H8.8c-0.8,0-1.5,0.7-1.5,1.5v4.4c0,0.8,0.7,1.5,1.5,1.5h4.4v1.5H7.3V51h2.9L10.2,52.5L10.2,52.5z"></path>
                        <path fill="#DA2436" d="M36.5,35v17.5c0,0.8,0.7,1.5,1.5,1.5h2.9v2.9c0,0.8,0.6,1.5,1.5,1.5c0.3,0,0.7-0.1,0.9-0.3l5.1-4.1h10.1
                        c0.8,0,1.5-0.7,1.5-1.5V35c0-0.8-0.7-1.5-1.5-1.5H37.9C37.1,33.5,36.5,34.2,36.5,35z M39.4,36.5h17.5V51h-9.1
                        c-0.3,0-0.6,0.1-0.9,0.3l-3.1,2.5v-1.3c0-0.8-0.7-1.5-1.5-1.5h-2.9V36.5z"></path>
                        <rect x="42.3" y="42.3" fill="#DA2436" width="2.9" height="2.9"></rect>
                        <rect x="46.7" y="42.3" fill="#DA2436" width="2.9" height="2.9"></rect>
                        <rect x="51" y="42.3" fill="#DA2436" width="2.9" height="2.9"></rect>
                     </g>
                </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
