<template>
	<div class="similar-app-option3-main">
		<div :class="`col-lg-6 d-none d-lg-block p-0 img-col fixedimage ${obj.color}`">
			<img
				:src="require(`../../../assets/images${obj.image1}`)"
				alt=""
				class="img1 d-none d-lg-block"
			/>
			<img
				:src="require(`../../../assets/images${obj.image2}`)"
				alt=""
				class="img2 d-none d-lg-block"
			/>
			<img :src="require(`../../../assets/images${obj.image3}`)" alt="" class="d-lg-none" />
		</div>
		<div class="col-lg-6 col-md-12 col-sm-12 col-12 p-0 content-col">
			<div class="content-box">
				<div class="content-logo-section d-flex align-items-center">
					<div class="content-logo">
						<img :src="require(`../../../assets/images${obj.logo}`)" alt="" class="box-shadow" />
					</div>
					<div class="content-logo-name">
						<span class="mainname">{{ obj.title }}</span>
						<span class="subname">{{ obj.description }}</span>
					</div>
				</div>
				<div class="content-description">
					<p v-for="paragraph in obj.paragraphs" :key="paragraph">
						{{ paragraph }}
					</p>
					<ul class="feature-list">
						<li v-for="feature in obj.features" :key="feature">
							<img
								src="../../../assets/images/listing-icon.png"
								alt=""
								style="padding-right: 10px"
							/>
							{{ feature }}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.similar-app-option3-main {
	position: relative;
	display: flex;
	padding: 0;
	margin: 0;
	align-items: center;
	background-color: #f8f9fa;
	min-height: 100vh;
	min-width: 100vw;
}
.similar-app-option3-main .feature-list {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}
.similar-app-option3-main .feature-list li {
	flex: 50%;
	margin-top: 30px;
}
.similar-app-option3-main .content-col {
	padding: 50px !important;
}
.similar-app-option3-main > div {
	flex-basis: 50%;
}
.container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.m-0 {
	margin: 0 !important;
}
.fantasyabg {
	background-color: #092240;
}
.dattingbg {
	background: -moz-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -webkit-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -o-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: -ms-linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
	background: linear-gradient(45deg, #7d65dd 0, #e483eb 100%);
}
.strongmindedbg {
	background-color: #0e0e0e;
}
.claireabellabg {
	background-color: #e42ba1;
}
.guestmebg {
	background-color: #ff0a00;
}
.idtrackbg {
	background-color: #349943;
}
.awaybackbg {
	background-color: #ef6c3d;
}
.chatagrambg {
	background-color: #ec7575;
}
.similar-app-option3-main .content-logo-name .mainname {
	font-size: 2.4rem;
	display: block;
}
.similar-app-option3-main .content-logo-name .subname {
	font-size: 1.2rem;
	color: rgb(70, 70, 70);
}

.similar-app-option3-main .content-logo-section {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
	gap: 30px;
}
.similar-app-option3-main .content-logo {
	width: 100px;
	height: 100px;
	border-radius: 20px;
	overflow: hidden;
}
.similar-app-option3-main .fixedimage {
	max-width: 650px;
	height: 100vh;
	position: relative;
}

.similar-app-option3-main .fixedimage .img1 {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(0.9);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.slick-slide img {
	display: block;
}
.d-none {
	display: none !important;
}
@media (min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}
}
@media (min-width: 992px) {
	.d-lg-block {
		display: block !important;
	}
}

img {
	max-width: 100%;
	height: auto;
}
.similar-app-option3-main .fixedimage .img2 {
	position: absolute;
	left: 35%;
	top: 50px;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(0.9);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.fixedimage:hover .img1 {
	left: -25px;
}
.fixedimage:hover .img2 {
	left: 40%;
	top: 30px;
}
</style>