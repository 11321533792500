<template>
	<div class="types-of-social-media-box rounded10 box-shadow">
		<div class="types-of-social-media-platform">
			<ul>
				<li v-for="imageLink in obj.imageLinks" :key="imageLink">
					<img class="lazy" alt="" :src="require(`../../../assets/images${imageLink}`)" style="" />
				</li>
			</ul>
		</div>
		<div class="types-of-social-media-content">
			<h3>{{ obj.title }}</h3>
			<p>{{ obj.text }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.types-of-social-media-box {
	min-height: 310px;
	border: 1px solid #f5f6f6;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.types-of-social-media-platform {
	padding: 20px 20px 15px;
	border-bottom: 2px solid #0082dd;
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.types-of-social-media-platform ul li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 3px 0 0;
}
.types-of-social-media-content {
	padding: 25px 20px;
}
.types-of-social-media-content h3 {
	font-size: 18px;
	font-weight: 600;
}
.types-of-social-media-box:hover {
	border: 1px solid #0082dd;
}
</style>