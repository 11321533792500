<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<OurOffer></OurOffer>

		<Variants></Variants>

		<Benefits></Benefits>

		<Sprints></Sprints>

		<WhyChooseUs></WhyChooseUs>

		<TypeDiv></TypeDiv>

		<Portfolio></Portfolio>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/EnterpriseSolutions/MvpDevelopment/Banner.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import About from '../../components/EnterpriseSolutions/MvpDevelopment/About.vue';
import OurOffer from '../../components/EnterpriseSolutions/MvpDevelopment/OurOffer.vue';
import Variants from '../../components/EnterpriseSolutions/MvpDevelopment/Variants.vue';
import Benefits from '../../components/EnterpriseSolutions/MvpDevelopment/Benefits.vue';
import WhyChooseUs from '../../components/EnterpriseSolutions/MvpDevelopment/WhyChooseUs.vue';
import Sprints from '../../components/EnterpriseSolutions/MvpDevelopment/Sprints.vue';

import TypeDiv from '../../components/Home/TypeDiv.vue';
import Portfolio from '../../components/Home/Portfolio.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		OurOffer,
		Variants,
		Benefits,
		WhyChooseUs,
		Sprints,
		TypeDiv,
		Portfolio,
		Const
	}
};
</script>

<style></style>
