<template>
	<section class="banner innerbanner aboutusbanner fxt-about-section fxt-solu-section">
		<div class="video pt-0">
			<img
				class="lazy fxt-about-img pt-0"
				alt="Genixbit careers"
				src="../../../assets/images/finance-banner.webp"
			/>
		</div>
		<div
			class="tenc-intro-main__wrapper"
			style="
				min-height: unset !important;
				background-image: linear-gradient(to right, #000000e8, #000000a8);
			"
		>
			<div class="container py-0" style="">
				<div class="row align-items-center" style="display: flex; flex-wrap: nowrap">
					<div class="col-md-8 col-sm-12 col-12">
						<h1 class="fxt-top-head"><span>Perfectly Balance Finance App Development</span></h1>
						<p style="margin: 0 0 10px; font-size: 16px" class="fxt-top-par about">
							Come 2020, fewer customers will be visiting bank branches in person. FinTech has
							arrived, making customers to conduct financial transactions anytime, anywhere and from
							any device. Fintech takes the form of mobile apps that enables financial institutions,
							banks, insurance companies and even customers to transact in a better fashion.
							GenixBit Labs is a finance app development company that knows both sides of the
							stakeholders like the back of its hand.
						</p>
						<router-link
							to="/contact"
							title=""
							class="btn waves-effect waves-light btn-custome mb-3 fxt-btn-custome"
							>Request Your Quote Today</router-link
						>
						<ol class="breadcrumb">
							<li class="breadcrumb-item text-white">
								<router-link
									to="/"
									class="text-white breadcrumb-item fxt-breadcrumb-item"
									style="background: none; margin-right: 0"
								>
									Home</router-link
								>
							</li>
							<li class="breadcrumb-item text-white active fxt-breadcrumb-item">
								Finance App Development
							</li>
						</ol>
					</div>
					<div class="col-md-5 col-sm-12 col-12 fxt-sol-img-cont">
						<img src="../../../assets/images/finance-banner-mobile.webp" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 1600px) {
	.banner {
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.banner {
	margin-top: 40px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.breadcrumb {
	background: 0 0;
	width: 100%;
	max-width: 1170px;
	margin-top: 40px;
	padding: 0 15px;
	z-index: 9999;
	padding-top: 15px;
	padding-left: 0 !important;
	margin-left: -20px;
}

.fxt-sol-img-cont img {
	transform: scale(0.75);
	margin-top: 110px;
}
@media (max-width: 1200px) {
	.fxt-sol-img-cont img {
		margin-top: 135px;
	}
}
@media (max-width: 1200px) {
	.fxt-sol-img-cont {
		display: none;
	}
}
.video img {
	width: 100%;
	height: 100%;
	min-height: 650px;
	object-fit: cover;
}
.tenc-intro-main__wrapper {
	width: 100%;
	position: absolute;
	top: -33px;
	left: 0;
	display: flex;
	align-items: center;
	background: linear-gradient(
		90deg,
		#236aec,
		rgba(35, 95, 236, 0.95) 40%,
		rgba(35, 161, 236, 0.7) 70%,
		rgba(35, 66, 236, 0.4)
	);
	z-index: 999;
}
.fxt-btn-custome {
	margin-top: 10px;
}
.fxt-top-head span {
	display: block;
	font-size: 44px;
}
.fxt-top-head span:first-child {
}
.fxt-top-head span:last-child {
	font-size: 34px;
	margin: 20px 0;
}
.fxt-breadcrumb-item {
	text-transform: none !important;
}
@media (max-width: 991px) {
	.fxt-top-head {
		font-size: 20px;
	}
	.fxt-top-par {
		font-size: 16px;
	}
	.fxt-tenc-intro-main__wrapper {
		top: 0;
	}
}
@media (min-width: 776px) {
	.fxt-about-section {
		height: calc(90vh - 40px) !important;
	}
	.video {
		height: 95vh !important;
	}
	.tenc-intro-main__wrapper {
		height: 95vh !important;
	}
	.fxt-about-img {
		min-height: 650px !important;
	}
}

.breadcrumb-item.active {
	color: #1977cc !important;
}
.fxt-about-img {
	position: relative;
}
.fxt-about-img::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: red;
}

@media (max-width: 576px) {
	.fxt-top-head {
		font-size: 20px;
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.fxt-top-par {
		font-size: 14px !important;
		line-height: 2;
	}
	.fxt-btn-custome {
		font-size: 12px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 0px;
		top: 20px;
	}
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 34px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 28px !important;
	}
	.breadcrumb {
		width: 100% !important;
		display: flex !important;
		flex-wrap: wrap !important;
		align-items: center !important;
		margin: 0 !important;
		padding: 0 !important;
	}
	.fxt-breadcrumb-item {
		font-size: 12px !important;
		padding: 5px !important;
	}
}
@media (max-width: 400px) {
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 28px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 22px !important;
	}
}
.fxt-btn-custome {
	background: #12599a !important;
	font-size: 12px;
	margin-right: 25px;
}
.fxt-btn-custome:hover {
	background: #04294b !important;
}
</style>
