<template>
	<div class="why-you-invest-box rounded10">
		<div class="why-you-invest-icon box-shadow" v-html="obj.svg"></div>
		<h5>{{obj.title}}</h5>
		<p>{{obj.text}}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.invest-erp-main .why-you-invest-box {
    background-color: #f8f9fa;
    min-height: 325px;
}
.why-you-invest-box {
    position: relative;
    border: 1px solid #ecedee;
    padding: 25px 20px;
    background-color: transparent;
    min-height: 250px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.rounded10 {
    border-radius: 10px!important;
}
.why-you-invest-icon {
    width: 70px;
    height: 70px;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 20px;
    top: -10px;
    padding:15px
}
.box-shadow {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.why-you-invest-box h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    padding-left: 80px;
}
.why-you-invest-box p:last-child {
    margin-bottom: 0;
}
p {
    font: normal 14px/22px Montserrat,sans-serif;
    color: #6a6d6f;
    margin-top: 0;
    margin-bottom: 1rem;
}
.invest-erp-main .why-you-invest-box:hover {
    background-color: #fff;
    border:1px solid #008dd2
}
.why-you-invest-box:hover .why-you-invest-icon {
    background-color: #008dd2;
    fill:#FFF
}


</style>
