<template>
	<div class="main-nav">
		<!-- ======= Top Bar ======= -->

		<div id="topbar" class="dont-show-on-mobile d-flex align-items-center dont-show-on-mobile">
			<div class="container">
				<div class="d-flex align-items-center justify-content-between">
					<div class="top-left-part">
						<a href="#">
							<img
								src="../../assets/images/clutch-b2b-logo.svg"
								alt="GenixBit Labs is top rated mobile app agency UK, with offices in London and Leeds. Specialising in mobile app design and development for iOS and Android."
								width="88"
								height="25"
							/>
							<div class="star">
								<img
									src="../../assets/images/footer-rating.png"
									alt="GenixBit Labs is top app making company in UK"
									width="110"
									height="19"
								/>
							</div>
							<strong>Excellent</strong>
							<span> 4.8 out of 5</span>
						</a>
					</div>
					<div class="top-right-part d-flex align-items-center justify-content-between">
						<span
							>Email us on
							<a href="mailto:sales@GenixBit Labs.co.uk"
								><strong class="text-white">info@genixbit.com</strong></a
							>
							or Speak to our expert</span
						>
						<a href="tel:+917000830437"><strong class="text-white">+917000830437</strong></a>
						<span class="or">or</span>
						<a href="javascript:;" class="head-requet-btn"
							><img
								src="../../assets/images/genixbit.png"
								alt="Oliver Baker is mobile app development consultant in the UK."
								width="30"
								height="26"
							/>Request a Call Back</a
						>
					</div>
				</div>
			</div>
		</div>

		<!-- ======= Header ======= -->
		<header id="header" class="">
			<div class="div-header-inner d-flex align-items-center">
				<h1 class="logo me-auto" style="min-width: 80px">
					<router-link to="/">
						<img src="../../assets/images/logo512.png" class="mnwebbased-logo-img" />
					</router-link>
				</h1>

				<nav id="navbar" class="navbar">
					<ul class="d-none d-lg-flex">
						<li class="gototop">
							<router-link to="/" class="nav-link scrollto">Home</router-link>
						</li>

						<li class="dropdown show-small-div">
							<a href="#"><span>Company</span> <i class="bx bx-caret-down"></i></a>
							<ul class="d-none d-lg-block">
								<li>
									<router-link to="/about-us"
										><i class="bx bx-chevron-right"></i>Who We Are</router-link
									>
								</li>
								<li>
									<router-link to="/process"
										><i class="bx bx-chevron-right"></i>How We Work</router-link
									>
								</li>
								<li>
									<router-link to="/client-review"
										><i class="bx bx-chevron-right"></i>Client Review</router-link
									>
								</li>
								<li>
									<router-link to="/technologies"
										><i class="bx bx-chevron-right"></i>Technologies</router-link
									>
								</li>
							</ul>
						</li>

						<li class="dropdown our-service-hover services-list-tag">
							<a href="#"><span>Our Service</span> <i class="bx bx-caret-down"></i></a>
						</li>

						<li class="dropdown solution-hover solution-list-tag">
							<a href="#"><span>Solution</span> <i class="bx bx-caret-down"></i></a>
						</li>

						<li class="gototop">
							<router-link class="nav-link scrollto" to="/portfolio">Portfolio</router-link>
						</li>

						<li class="gototop">
							<router-link class="nav-link scrollto" to="/">Blog</router-link>
						</li>

						<li class="gototop">
							<router-link to="/contact" class="nav-link scrollto">Contact</router-link>
						</li>
					</ul>
					<!-- first tab -->
					<ul class="mobile-list mobile-list-main d-none">
						<li>
							<router-link
								to="/"
								class="nav-link scrollto h5"
								style="justify-content: unset; gap: 15px"
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: #fff">
									<path
										d="m21.743 12.331-9-10c-.379-.422-1.107-.422-1.486 0l-9 10a.998.998 0 0 0-.17 1.076c.16.361.518.593.913.593h2v7a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-4h4v4a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-7h2a.998.998 0 0 0 .743-1.669z"
									></path>
								</svg>
								Home
							</router-link>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-company">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										style="margin-right: 13px"
										fill="currentColor"
									>
										<path
											d="M18 2H6c-1.103 0-2 .897-2 2v17a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm0 18H6V4h12v16z"
										></path>
										<path
											d="M8 6h3v2H8zm5 0h3v2h-3zm-5 4h3v2H8zm5 .031h3V12h-3zM8 14h3v2H8zm5 0h3v2h-3z"
										></path>
									</svg>
									Company </span
								><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5
								class="show-list-tag d-flex justify-content-between"
								id="mobile-list-our-services"
							>
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										style="margin-right: 13px"
										fill="currentColor"
										class="bi bi-gear-wide-connected"
										viewBox="0 0 16 16"
									>
										<path
											d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"
										/>
									</svg>
									Our Service </span
								><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5
								class="show-list-tag d-flex justify-content-between"
								id="mobile-list-our-solutions"
							>
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										style="margin-right: 13px"
										fill="currentColor"
										class="bi bi-tv-fill"
										viewBox="0 0 16 16"
									>
										<path
											d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM2 2h12s2 0 2 2v6s0 2-2 2H2s-2 0-2-2V4s0-2 2-2z"
										/>
									</svg>
									Solution</span
								><span>&gt;</span>
							</h5>
						</li>
						<li>
							<router-link
								to="/portfolio"
								class="nav-link scrollto h5"
								style="justify-content: unset; gap: 15px"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									style="fill: #fff"
									viewBox="0 0 512 512"
								>
									<path
										d="M128 56C128 25.07 153.1 0 184 0H328C358.9 0 384 25.07 384 56V480H128V56zM176 96H336V56C336 51.58 332.4 48 328 48H184C179.6 48 176 51.58 176 56V96zM64 96H96V480H64C28.65 480 0 451.3 0 416V160C0 124.7 28.65 96 64 96zM448 480H416V96H448C483.3 96 512 124.7 512 160V416C512 451.3 483.3 480 448 480z"
									/>
								</svg>
								Portfolio</router-link
							>
						</li>
						<li>
							<router-link
								to="/"
								class="nav-link scrollto h5"
								style="justify-content: unset; gap: 15px"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									style="fill: #fff"
									viewBox="0 0 512 512"
								>
									<path
										d="M456 32h-304C121.1 32 96 57.13 96 88v320c0 13.22-10.77 24-24 24S48 421.2 48 408V112c0-13.25-10.75-24-24-24S0 98.75 0 112v296C0 447.7 32.3 480 72 480h352c48.53 0 88-39.47 88-88v-304C512 57.13 486.9 32 456 32zM464 392c0 22.06-17.94 40-40 40H139.9C142.5 424.5 144 416.4 144 408v-320c0-4.406 3.594-8 8-8h304c4.406 0 8 3.594 8 8V392zM264 272h-64C186.8 272 176 282.8 176 296S186.8 320 200 320h64C277.3 320 288 309.3 288 296S277.3 272 264 272zM408 272h-64C330.8 272 320 282.8 320 296S330.8 320 344 320h64c13.25 0 24-10.75 24-24S421.3 272 408 272zM264 352h-64c-13.25 0-24 10.75-24 24s10.75 24 24 24h64c13.25 0 24-10.75 24-24S277.3 352 264 352zM408 352h-64C330.8 352 320 362.8 320 376s10.75 24 24 24h64c13.25 0 24-10.75 24-24S421.3 352 408 352zM400 112h-192c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h192c17.67 0 32-14.33 32-32v-64C432 126.3 417.7 112 400 112z"
									/>
								</svg>
								Blog</router-link
							>
						</li>
						<li>
							<router-link
								to="/contact"
								class="nav-link scrollto h5"
								style="justify-content: unset; gap: 15px"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									style="fill: #fff"
									viewBox="0 0 512 512"
								>
									<path
										d="M284.6 181.9c-10.28-8.344-25.41-6.875-33.75 3.406C242.4 195.6 243.9 210.7 254.2 219.1c11.31 9.25 17.81 22.69 17.81 36.87c0 14.19-6.5 27.62-17.81 36.87c-10.28 8.406-11.78 23.53-3.375 33.78c4.719 5.812 11.62 8.812 18.56 8.812c5.344 0 10.75-1.781 15.19-5.406c22.53-18.44 35.44-45.4 35.44-74.05S307.1 200.4 284.6 181.9zM345.1 107.1c-10.22-8.344-25.34-6.907-33.78 3.343c-8.406 10.25-6.906 25.37 3.344 33.78c33.88 27.78 53.31 68.18 53.31 110.9s-19.44 83.09-53.31 110.9c-10.25 8.406-11.75 23.53-3.344 33.78c4.75 5.781 11.62 8.781 18.56 8.781c5.375 0 10.75-1.781 15.22-5.438C390.2 367.1 416 313.1 416 255.1S390.2 144.9 345.1 107.1zM406.4 33.15c-10.22-8.344-25.34-6.875-33.78 3.344c-8.406 10.25-6.906 25.37 3.344 33.78C431.9 116.1 464 183.8 464 255.1s-32.09 139.9-88.06 185.7c-10.25 8.406-11.75 23.53-3.344 33.78c4.75 5.781 11.62 8.781 18.56 8.781c5.375 0 10.75-1.781 15.22-5.438C473.5 423.8 512 342.6 512 255.1S473.5 88.15 406.4 33.15zM151.3 174.6C161.1 175.6 172.1 169.5 176 159.6l33.75-84.38C214 64.35 209.1 51.1 200.2 45.86l-67.47-42.17C123.2-2.289 110.9-.8945 102.9 7.08C-34.32 144.3-34.31 367.7 102.9 504.9c7.982 7.984 20.22 9.379 29.75 3.402l67.48-42.19c9.775-6.104 13.9-18.47 9.598-29.3L176 352.5c-3.945-9.963-14.14-16.11-24.73-14.97l-53.24 5.314C78.89 286.7 78.89 225.4 98.06 169.3L151.3 174.6z"
									/>
								</svg>
								Contact</router-link
							>
						</li>
					</ul>
					<!-- second tab -->
					<ul class="mobile-list mobile-list-company d-none">
						<li class="go-back-list-tag" id="mobile-list-company">
							<h5>&lt; Menu</h5>
						</li>
						<li>
							<router-link to="/about-us" class="h5">Who We Are</router-link>
						</li>
						<li>
							<router-link to="/process" class="h5">How We Work</router-link>
						</li>
						<li>
							<router-link to="/client-review" class="h5">Client Review</router-link>
						</li>
						<li>
							<router-link to="/technologies" class="h5">Technologies</router-link>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-our-services d-none">
						<li class="go-back-list-tag" id="mobile-list-our-services">
							<h5 class="">&lt; Menu</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-mbdev">
								<span>Mobile App Development</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-webdev">
								<span>Web Development</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-gamedev">
								<span>Game Development</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-enterprise">
								<span>Enterprise Solutions</span><span>&gt;</span>
							</h5>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-our-solutions d-none">
						<li class="go-back-list-tag" id="mobile-list-our-solutions">
							<h5 class="">&lt; Menu</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-demand">
								<span>On-demand Solutions</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-industry">
								<span>Industry Solutions</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-social">
								<span>Social Solutions</span><span>&gt;</span>
							</h5>
						</li>
						<li>
							<h5 class="show-list-tag d-flex justify-content-between" id="mobile-list-other">
								<span>Other Solutions</span><span>&gt;</span>
							</h5>
						</li>
					</ul>
					<!-- third tab -->

					<!-- services -->
					<ul class="mobile-list mobile-list-mbdev d-none">
						<li class="go-back-list-tag" id="mobile-list-mbdev">
							<h5>&lt; Mobile App Development</h5>
						</li>
						<li>
							<router-link to="/mobile-development" class="h5">Mobile App Development</router-link>
						</li>
						<li>
							<router-link to="/mobile-development/iphone-development" class="h5"
								>iPhone App Development</router-link
							>
						</li>
						<li>
							<router-link to="/mobile-development/android-development" class="h5"
								>Android App Development</router-link
							>
						</li>
						<li>
							<router-link to="/mobile-development/flutter-development" class="h5"
								>Flutter App Development</router-link
							>
						</li>
						<li>
							<router-link to="/mobile-development/cross-platform-development" class="h5"
								>Cross Platform App Development</router-link
							>
						</li>
						<li>
							<router-link to="/mobile-development/augmented-reality-development" class="h5"
								>AR App Development</router-link
							>
						</li>
						<li>
							<router-link to="/mobile-development/virtual-reality-development" class="h5"
								>VR App Development</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-webdev d-none">
						<li class="go-back-list-tag" id="mobile-list-webdev">
							<h5>&lt; Web Development</h5>
						</li>
						<li>
							<router-link to="/web-development" class="h5">Web Development</router-link>
						</li>
						<li>
							<router-link to="/web-development/php-development" class="h5"
								>PHP Web Development</router-link
							>
						</li>
						<li>
							<router-link to="/web-development/wordpress-development" class="h5"
								>WordPress Development</router-link
							>
						</li>
						<li>
							<router-link to="/web-development/codeigniter-development" class="h5"
								>Codeigniter Development</router-link
							>
						</li>
						<li>
							<router-link to="/web-development/laravel-development" class="h5"
								>Laravel Development</router-link
							>
						</li>
						<li>
							<router-link to="/web-development/angularjs-development" class="h5"
								>AngularJs Development</router-link
							>
						</li>
						<li>
							<router-link to="/web-development/nodejs-development" class="h5"
								>NodeJS Development</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-gamedev d-none">
						<li class="go-back-list-tag" id="mobile-list-gamedev">
							<h5>&lt;Game Development</h5>
						</li>
						<li>
							<router-link to="/game-development" class="h5">Game Development</router-link>
						</li>
						<li>
							<router-link to="/game-development/ios-game-development" class="h5"
								>IOS Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/android-game-development" class="h5"
								>Android Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/2d-3d-game-development" class="h5"
								>2D/3D Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/augmented-reality-game-development" class="h5"
								>AR Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/virtual-reality-game-development" class="h5"
								>VR Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/windows-game-development" class="h5"
								>Windows Game Development</router-link
							>
						</li>
						<li>
							<router-link to="/game-development/mac-game-development" class="h5"
								>Mac Game Development</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-enterprise d-none">
						<li class="go-back-list-tag" id="mobile-list-enterprise">
							<h5>&lt; Enterprise Solutions</h5>
						</li>
						<li>
							<span class="h5">Enterprise Solutions</span>
						</li>
						<li>
							<router-link to="/startup-mvp-development" class="h5"
								>Startup MVP Development</router-link
							>
						</li>
						<li>
							<router-link to="/erp-development" class="h5">ERP Solutions</router-link>
						</li>
						<li>
							<router-link to="/crm-development" class="h5">CRM Solutions</router-link>
						</li>
						<li>
							<router-link to="/cms-development" class="h5">CMS Solutions</router-link>
						</li>
						<li>
							<router-link to="/ecommerce-web-development" class="h5"
								>Ecommerce Solutions</router-link
							>
						</li>
						<li>
							<router-link to="/ui-ux-design-development" class="h5">UI-UX Design</router-link>
						</li>
					</ul>

					<!-- solutions -->
					<ul class="mobile-list mobile-list-demand d-none">
						<li class="go-back-list-tag" id="mobile-list-demand">
							<h5>&lt; On-demand Solutions</h5>
						</li>
						<li><router-link to="/taxi-app-development" class="h5">Taxi Booking</router-link></li>
						<li>
							<router-link to="/food-delivery-app-development" class="h5"
								>Food Delivery</router-link
							>
						</li>
						<li>
							<router-link to="/car-wash-app-development" class="h5"
								>On Demand Car Wash</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-industry d-none">
						<li class="go-back-list-tag" id="mobile-list-industry">
							<h5>&lt; Industry Solutions</h5>
						</li>
						<li>
							<router-link to="/real-estate-app-development" class="h5">Real Estate</router-link>
						</li>
						<li>
							<router-link to="/real-estate-app-development" class="h5"
								>Banking & Finance</router-link
							>
						</li>
						<li>
							<router-link to="/travel-app-development" class="h5">Travel & Tourism</router-link>
						</li>
						<li>
							<router-link to="/health-fitness-app-development" class="h5"
								>Health & Fitness</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-social d-none">
						<li class="go-back-list-tag" id="mobile-list-social">
							<h5>&lt; Social Solutions</h5>
						</li>
						<li>
							<router-link to="/social-media-app-development" class="h5">Social Media</router-link>
						</li>
						<li>
							<router-link to="/dating-app-development" class="h5">Dating</router-link>
						</li>
						<li>
							<router-link to="/chat-instant-messaging-app-development" class="h5"
								>Instant Messaging</router-link
							>
						</li>
						<li>
							<router-link to="/media-entertainment-app-development" class="h5"
								>Media & Entertainment</router-link
							>
						</li>
					</ul>
					<ul class="mobile-list mobile-list-other d-none">
						<li class="go-back-list-tag" id="mobile-list-other">
							<h5>&lt; Other Solutions</h5>
						</li>
						<li>
							<router-link to="/fantasy-sports-app-development" class="h5"
								>Fantasy Sports</router-link
							>
						</li>
						<li>
							<router-link to="/chatbot-development" class="h5">Chatbot</router-link>
						</li>
					</ul>
				</nav>
				<!-- .navbar -->
				<div class="toggle-bar toggle-bar-icon mobile-nav-toggle toggled">
					<span></span>
					<span></span>
					<span></span>
				</div>

				<a
					class="appointment-btn scrollto estimate-bnt"
					style="cursor: pointer; user-select: none"
					href="/app-development-cost"
				>
					Estimate Your Project
				</a>
				<a href="/app-development-cost" class="appointment-btn request-bnt d-block d-lg-none">
					<i class="fas fa-phone-volume fa-lg mr-2"></i>
					<span>Request a Call Back</span>
				</a>
			</div>
			<!-- Our Service Div That Appears on Hover Over Our_Services -->
			<OurService class="hidden"></OurService>
			<solution class="hidden"></solution>
		</header>

		<!-- Modal -->
		<div
			class="modal fade"
			id="get-qoute"
			tabindex="-1"
			role="dialog"
			aria-labelledby="modelTitleId"
			aria-hidden="true"
			style="background-color: #f3f3f3"
		>
			<div class="modal-dialog modal-xl" role="document">
				<div
					class="modal-content"
					style="background-color: #f3f3f3; padding: 20px 50px; border: none"
				>
					<div class="modal-body" style="max-width: 70%; margin: auto">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div class="container">
							<h4 style="font-weight: bolder">
								Request the <span class="text-color">Quote!</span>
							</h4>
						</div>
						<ContactFormDiv class=""></ContactFormDiv>
					</div>
				</div>
			</div>
		</div>

		<!-- End Header -->
	</div>
</template>

<script>
import $ from 'jquery';
import OurService from './OurService.vue';
import Solution from './Solution.vue';
import ContactFormDiv from '../AssetComponents/ContactFormDiv.vue';

export default {
	components: {
		OurService,
		Solution,
		ContactFormDiv
	},
	mounted() {
		$('.our-service-hover').on('mouseenter', () => {
			document.querySelector('.our-services-div').style.top = `${
				parseInt(document.querySelector('#header').offsetHeight) +
				document.querySelector('#header').offsetTop
			}px`;
			$('.our-services-div').slideDown();
			$('.solution-div').slideUp();

			$('.our-services-div').on('mouseleave', () => {
				$('.our-services-div').slideUp();
			});
		});

		$('.solution-hover').on('mouseenter', () => {
			document.querySelector('.solution-div').style.top = `${
				parseInt(document.querySelector('#header').offsetHeight) +
				document.querySelector('#header').offsetTop
			}px`;
			$('.solution-div').slideDown();
			$('.our-services-div').slideUp();
			$('.solution-div').on('mouseleave', () => {
				$('.solution-div').slideUp();
			});
		});
		$('.show-small-div').on('mouseenter', () => {
			$('.solution-div').slideUp();
			$('.our-services-div').slideUp();
		});
	}
};
</script>

<style scoped>
#header {
	display: flex;
	justify-content: center;
}

.dont-show-on-mobile {
	display: block;
}

.div-header-inner {
	justify-content: space-between;
	flex-grow: 1;
	max-width: 80%;
}

.our-services-div,
.solution-div {
	position: fixed;
	z-index: 1000;
	left: 0;
	/* top: 130px; */
	background: #fff;
}
.os-wrapper {
	background: #fff;
}
#navbar .dropdown ul li {
	display: flex;
}

#navbar .dropdown ul li a:hover {
	transform: translateX(5px);
	transition: 0.4s;
}

#navbar .dropdown ul li i {
	margin-right: 10px;
}
.modal-body h4 {
	color: #1977cc;
	font-size: 5vw;
	line-height: 1.04444em;
	margin-bottom: 35px;
	margin-left: 10px;
}
.modal-body .text-color {
	color: #343535;
}
.modal-body .container {
	max-width: 600px;
}

.close span {
	font-size: 3rem;
	color: #1977cc;
}

@media (min-width: 800px) {
	#topbar {
		width: 100%;
		background: #202122;
		padding: 6px 0;
	}
}

.top-left-part a {
	display: flex;
	align-items: center;
}
i.bi-x {
	height: 20px;
	text-align: center;
	padding-left: 5px;
}
i.bi-x::before {
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
	line-height: 20px;
	content: 'X';
	color: black;
	font-size: 20px;
}
.top-left-part .star {
	line-height: 1;
	padding: 0 10px;
}
.top-left-part .star + strong {
	padding-right: 5px;
	font-weight: 600;
}
.top-right-part span {
	color: #fff;
}
.top-right-part a {
	margin-left: 10px;
}
.top-right-part .or {
	padding: 0 10px;
}
.top-right-part .head-requet-btn:hover {
	background: #1977cc;
}
.top-right-part .head-requet-btn {
	padding: 6px 15px 6px 10px;
	color: #fff;
	margin-left: 0;
	background-color: #343535;
	border-radius: 30px;
	transition: 0.3s;
}
.top-right-part .head-requet-btn img {
	margin-right: 10px;
	border-radius: 30px;
}
#topbar .container {
	max-width: 1380px;
	width: 100%;
}

.main-nav {
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 2000;
	transition: all 0.5s;
}

@media (max-width: 750px) {
	.fixed-top {
		width: 100vw;
		margin: initial;
	}
}

@media (min-width: 1400px) and (max-width: 1500px) {
	.div-header-inner {
		max-width: 90%;
	}
}

@media (min-width: 1300px) and (max-width: 1400px) {
	.div-header-inner {
		max-width: 85%;
	}
}

@media (min-width: 1200px) and (max-width: 1300px) {
	.div-header-inner {
		max-width: 87%;
	}
	.navbar a,
	.navbar a:focus {
		font-size: 13px;
	}
}

@media (min-width: 1100px) and (max-width: 1200px) {
	#topbar {
		font-size: 12px;
	}
	.navbar a,
	.navbar a:focus {
		font-size: 12px;
	}
	.div-header-inner {
		max-width: 90%;
	}
	.appointment-btn {
		font-size: 11px;
	}
}

@media (min-width: 990px) and (max-width: 1100px) {
	#topbar {
		font-size: 9px;
	}
	.navbar a,
	.navbar a:focus {
		font-size: 11px;
	}
	.div-header-inner {
		max-width: 90%;
	}
	.appointment-btn {
		font-size: 10px;
	}
}

.request-bnt {
	border-radius: 30px;
	transition: 0.3s;
}

@media (max-width: 991px) {
	.navbar {
		order: 1;
		margin-left: 20px;
	}
	.estimate-bnt {
		display: none;
	}
	.div-header-inner {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	.div-header-inner h1 {
		order: 1;
	}
	.toggle-bar {
		padding-left: 10px;
		order: 1;
	}
}
@media (max-width: 776px) {
	.request-bnt i {
		padding-top: 14px;
	}
	.request-bnt {
		border-radius: 50%;
		width: 35px;
		height: 35px;
		padding: 0 !important;
		text-align: center;
	}
	.request-bnt i {
		display: block;
		margin: 0 !important ;
	}
	.request-bnt span {
		display: none;
	}
}
@media (max-width: 576px) {
	.div-header-inner {
		min-width: 100vw;
	}
	.div-header-inner h1 {
		max-width: 150px;
		margin-right: 40px !important;
	}
	.appointment-btn {
		font-size: 10px;
		padding: 6px 10px;
	}
}
@media (max-width: 400px) {
	.div-header-inner h1 {
		max-width: 100px;
	}
	.mnwebbased-logo-img {
		max-width: 140px;
		margin-left: -20px;
	}
}
.mobile-nav-toggle {
	width: 30px;
	height: 30px;
	display: none;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.mobile-nav-toggle span {
	display: block;
	width: 100%;
	height: 3px;
	background-color: #202122;
	transition: all 0.5s;
}
.mobile-nav-toggle span {
	display: block;
}

.mobile-nav-toggle.untoggled {
	justify-content: center;
	align-items: center;
}
.mobile-nav-toggle.untoggled span:nth-child(2) {
	opacity: 0;
}
.mobile-nav-toggle.untoggled span:nth-child(1) {
	transform: rotate(45deg) translate(4px);
	border-radius: 10px;
}
.mobile-nav-toggle.untoggled span:nth-child(3) {
	transform: rotate(-45deg) translate(4px);
	border-radius: 10px;
}
@media (max-width: 991px) {
	.mobile-nav-toggle {
		display: flex;
	}
}
</style>