<template>
	<section class="padding-60 tideway-review-section bg-light" style="margin-top:-50px;padding-top:100px;">
		<div class="container">
			<div class="title">
				<span>Our Testimonial</span>
				<h2>What Our Client Speak</h2>
				<p>
					Satisfied client feedback always delights us. We endeavour to make happy clients
					and it's our pleasure to share the gratified feedback we received.
				</p>
			</div>
			<div class="row justify-content-center flex-wrap">
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/jay.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>JAY G</strong> <span>I</span> MANCHESTER, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Despite difficulties with getting more info from my end client, the
								Genixbit team did an impressive job, finding the source of the
								problem right down to the actual nut that was shearing, in a
								metaphorical sense. I will gladly continue this relationship and
								send work their way as they were most diligent and resourceful in
								their execution of the task I gave them right up to the deadline.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/adam.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>ADAM C</strong> <span>I</span> CITY OF LONDON, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Their customer service is second to none, we created the first
								version of my app and this went well although after it was built I
								decided it needed further development before launching. A good start
								to the project.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/honest.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>HONEST A</strong> <span>I</span> LONDON, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Genixbit did a great job providing all necessary tasks. They were
								always available for discussion and with quick replies. They
								understand Unity development very good and I hope for long term
								business relationship with my future projects.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/anand.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>ANAND R</strong> <span>I</span> CHENNAI, IN
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Excellent work and very good communication. Keeping up what was
								promised and exceeding expectation. Absolute pleasure and will
								continue to work with GenixBit Labs.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/lance.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>LANCE L</strong> <span>I</span> VANCOUVER, CA
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Outstanding work, outstanding team! Non stop quality work and
								professionalism!
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/wilfried.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>WILFRIED</strong> <span>I</span> DUSSELDORF, DE
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Excellent proposal and communication. They never get tired to answer
								questions fast and all the time. Highly recommended for the next job
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/sallyann.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>SALLYANN D</strong> <span>I</span> DUBAI, AE
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Absolutely great work. They persisted until they got it. I am
								looking forward to a long-term relationship guys.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>HOWARD E</strong> <span>I</span> CHEYENNE, WY, US
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								As always, Genixbit is demonstrating excellent capacities when it
								comes to delivering a high quality job with the fastest speed and
								especially with great communication sense
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>MARA S</strong> <span>I</span> CITY OF LONDON, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Absolutely love working these guys.These guys are true team players
								and have incredible integrity about their work. They are very
								attentive to the needs of the business and extremely competitive. So
								far they are absolutely fantastic. They are communicative and very
								knowledgeable.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>KRISTI S</strong> <span>I</span> WAILUKU, HI, US
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								They did a great job for me on both development and design. Any
								issues that came up, they were able to fix. Professional and
								knowledgeable and fast. It is great to find people who are good and
								trustworthy.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>ADE A</strong> <span>I</span> CITY OF LONDON, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Wow, would give them more stars if possible. Great team to work
								with, not only did they do a fantastic job but they went above and
								beyond to complete the work to the set specification. Keep up the
								good work.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>BOBBY C</strong> <span>I</span> ATLANTA, GA, US
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Genixbit has continued to deliver excellent service with the
								development of my mobile game. I look forward to seeing this project
								through to the end with them. I'm glad to have chosen them to help
								me realize this project.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>TORENZ G</strong> <span>I</span> MANCHESTER, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								As always, incredibly impressive! I would recommend them to anyone
								who uses Unity3D.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>FLOWER SUMA</strong> <span>I</span> STREATHAM, GB
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Great experience Genixbit were helpful and supportive during the
								project, they are experienced and responsive and a great team to
								work with.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>ROSEVILLE</strong> <span>I</span> UNITED STATES
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Its First time we are working together , and it was really nice
								Experience with the Team, Good Response time, Really Experienced,
								Quick Turn Around Time, On Budget &amp; Time
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-sm-12 col-12 mb-30">
					<div class="tideway-box box-shadow rounded10">
						<!--  <div class="tideway-logo">
                  <img class="lazy"  data-src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/tideway-logo.png" alt="">
               </div> -->
						<div class="tideway-top d-flex">
							<div class="tideway-image box-shadow">
								<img
									class="lazy"
									alt=""
									src="../../assets/images/client-dummy.jpg"
									style=""
								/>
							</div>
							<div class="tideway-client-info">
								<div class="name">
									<strong>TOMARMANGAL GROUP</strong> <span>I</span> JAIPUR, IN
								</div>
								<div class="ratedbtclient">Rated By Client</div>
								<div class="ratingstar">
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i> <i class="fas fa-star"></i>
									<i class="fas fa-star"></i>
								</div>
							</div>
						</div>
						<div class="tideway-review-text">
							<sub class="openquote">“</sub>
							<p>
								Nice to find your team, You are an asset to have. Thanks for taking
								care of project more than us. Worked like a in house team and thanks
								for your timely and quality delivery of will not stop working with
								each other ever in future.
							</p>
							<sup class="closequote">”</sup>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="video-review-section">
         <div class="video-inner-top-slider mb-30">
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                     <div class="video-inner-top-content">
                        <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                        <span class="d-block">Client</span>
                        <p>“ Excellent proposal and communication. They never get tired to answer questions fast and all the time. Highly recommended for the next job ”</p>
                     </div>
                  </div>
                  <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                     <div class="video-area-wrapper">
                        <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg">
                        <div class="hover">
                           <a href="https://player.vimeo.com/video/282873577?title=0&amp;portrait=0&amp;byline=0&amp;autoplay=1" class="video-play-btn mfp-iframe"><i class="fas fa-play"></i></a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="video-inner-bottom-slider">
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client2.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
            <div>
               <div class="video-bottom-slider-main-image box-shadow">
                  <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg">
                  <i class="fas fa-play"></i>
               </div>
               <div class="video-bottom-slider-bottom-part d-flex">
                  <div class="video-bottom-slider-image box-shadow">
                     <img src="https://www.intelivita.co.uk/assets/frameworks/public/images/client-review/wilfried-client3.jpg" alt="">
                  </div>
                  <div class="video-bottom-slider-client-info">
                     <div class="name"><strong>WILFRIED</strong> <span>I</span>  DUSSELDORF, DE</div>
                     <div class="ratedbtclient">Rated By Client</div>
                     <div class="ratingstar"><i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i></div>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
.tideway-box {
	padding: 20px 20px;
	position: relative;
	border: 1px solid #e2e3e5;
	min-height: 340px;
	background-color: #fff;
	overflow: hidden;
}
.tideway-box::before {
	content: '';
	display: block;
	width: 132px;
	height: 90px;
	background: url('../../assets/images/tideway-quote-pattern.png') no-repeat center center;
	position: absolute;
	bottom: -24px;
	right: -3px;
}
.tideway-top {
	margin-bottom: 30px;
}
.tideway-image {
	width: 120px;
	height: 120px;
	border-radius: 100%;
	overflow: hidden;
	border: 7px solid #fff;
}
.tideway-client-info {
	width: calc(100% - 135px);
	margin-left: 15px;
	padding: 25px 100px 0 0;
}
.tideway-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.tideway-client-info .name {
	margin-bottom: 15px;
}
.tideway-client-info span {
	font-weight: 700;
}
.tideway-client-info .ratedbtclient {
	margin-bottom: 5px;
}
.tideway-client-info .fas {
	color: #1977cc;
}

.tideway-review-text .openquote {
	margin-right: 3px;
}
.tideway-review-text p {
	margin: 0;
	display: inline;
	line-height: 25px;
	letter-spacing: 0.025em;
}
.tideway-review-text .closequote {
	margin-left: 3px;
}
.ratingstar {
	min-width: 110px;
}
.tideway-client-info .name {
	min-width: 270px;
}
@media (max-width: 576px) {
	.tideway-client-info .name {
		font-size: 14px;
		min-width: 220px;
	}
	.ratedbtclient {
		min-width: 100px;
		font-size: 12px;
	}
	.ratingstar {
		font-size: 10px;
	}
	.tideway-image {
		max-width: 90px;
		max-height: 90px;
	}
}
</style>
