<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/erp-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Custom ERP Application Development Services
					</h2>
					<p>
						Our experienced, housed ERP experts avail you the best-customized ERP development
						solution to move your business further. With utmost customization and open scope of
						scalability, you easily attain business automation, systematic business planning,
						cohesive team collaboration and executing processes in modernization. Stay ahead of
						competitors with our best in class custom ERP software development service for gaining
						centralized access to dynamic business information for making informed decisions.
					</p>
					<p>
						Tailored to requirement with perfection our custom ERP application development stays
						robust throughout the ERP life cycle. This leads to improving cross-departmental
						efficiency and smoothening operational progressions. Customized ERP solutions is a
						cost-effective way to automate, simplify and analyse current manual procedures. Our ERP
						development services are aimed at bridging the gaps between all the necessary business
						functions and bring it under a single, cohesive platform.
					</p>
					<p>
						Assimilating real-time information from multiple sources requires agility and robustness
						to avoid conflicts between sources. Our personalized approach to system agnostic ERP
						software development thrives accuracy, consistency, and security of crucial data.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
