<template>
	<footer class="padding-60 bg-white our-places-cont mb-600">
		<div class="container">
			<div class="title">
				<span>Connect with us </span>
				<h2 class="fxt-places">We Are Available At</h2>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-4 col-md-4 col-12 mb-md-4 mb-0">
					<div class="footer_box">
						<div class="row align-items-end justify-content-between">
							<div class="col-12 col-sm-12 fxt-our-places">
								<div class="footer_box_img mb-30">
									<img
										class="lazy box-shadow rounded10"
										alt="GenixBit Labs web and mobile development company in Leeds."
										src="../../assets/images/ahmedabd-img.jpg"
										style=""
										width="360"
										height="227"
									/>
								</div>
								<div class="footer_box_add mb-3">
									<h5>Ahmedabad</h5>
									<ul>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M12,0L12,0C7.3,0,3.5,3.9,3.5,8.7c0,1.7,0.5,3.3,1.4,4.7c1.7,2.9,6.3,9.9,6.5,10.2c0.2,0.4,0.7,0.5,1.1,0.2
                                       c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.3,4.8-7.3,6.5-10.2c0.9-1.4,1.3-3.1,1.3-4.7C20.5,3.9,16.7,0,12,0z M17.8,12.6
                                       c-1.3,2.2-4.4,7-5.8,9.1c-1.4-2.1-4.5-6.9-5.8-9.1c-0.7-1.2-1.1-2.5-1.1-3.9c0-3.9,3.1-7.1,6.9-7.1c3.8,0,6.9,3.2,6.9,7.1
                                       C18.9,10.1,18.5,11.5,17.8,12.6z M12,4.3c-2.3,0-4.2,2-4.2,4.4c0,2.4,1.9,4.4,4.2,4.4c2.3,0,4.2-2,4.2-4.4
                                       C16.2,6.3,14.3,4.3,12,4.3z M12,11.4c-1.5,0-2.7-1.2-2.7-2.7C9.3,7.2,10.5,6,12,6c1.5,0,2.7,1.2,2.7,2.7
                                       C14.7,10.2,13.5,11.4,12,11.4z"
													></path>
												</svg>
											</i>
											<p>
												001 Mayuransh Elanza, Shyamal Crossroad, Satellite, Ahmedabad, Gujarat 380015
 
											</p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M18.5,24c-0.8,0-2.4-0.1-4.5-1c-2.6-1-5.2-2.8-7.6-5.3C1.4,12.8-0.4,9.1,0.1,5C0.2,4.5,0.3,4,0.5,3.4
                                       c0,0,0,0,0,0C0.7,2.9,1,2.4,1.3,2c0-0.1,0.1-0.2,0.2-0.3l1-1C2.9,0.2,3.5,0,4.2,0c0.6,0,1.3,0.2,1.7,0.7l3.5,3.5c1,1,0.9,2.5,0,3.5
                                       L8,9.1c0.6,1.1,1.4,2.5,2.9,4c1.5,1.5,2.9,2.3,4,2.9l1.4-1.4c0.5-0.5,1.1-0.8,1.8-0.8c0.6,0,1.3,0.3,1.7,0.7l3.5,3.5
                                       c1,1,0.9,2.5,0,3.5c0,0,0,0,0,0l-1,1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.4,0.3-0.9,0.6-1.4,0.8c-0.5,0.2-1.1,0.3-1.6,0.4c0,0,0,0,0,0
                                       C18.9,24,18.7,24,18.5,24z M1.7,3.9C1.6,4.3,1.4,4.8,1.4,5.2c-0.4,3.6,1.3,7,5.8,11.6c5.4,5.5,10,5.9,11.2,5.9c0.2,0,0.3,0,0.3,0
                                       c0.4-0.1,0.9-0.2,1.3-0.3c0.5-0.2,0.9-0.4,1.3-0.7c0,0,0.1,0,0.1-0.1l0.9-0.8c0.5-0.5,0.5-1.2,0-1.7l-3.5-3.5
                                       c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.9,0.4l-1.8,1.8c-0.2,0.2-0.5,0.3-0.8,0.1c-0.1-0.1-0.2-0.1-0.4-0.2
                                       c-1.1-0.6-2.7-1.5-4.4-3.2c-1.7-1.7-2.5-3.3-3.2-4.4l0-0.1C6.7,9.5,6.6,9.4,6.6,9.4C6.4,9.1,6.5,8.8,6.7,8.6l1.8-1.8
                                       c0.5-0.5,0.5-1.2,0-1.7L5,1.6C4.8,1.4,4.5,1.3,4.2,1.3c-0.3,0-0.6,0.1-0.9,0.4L2.5,2.5c0,0,0,0.1-0.1,0.1C2.1,3,1.9,3.4,1.7,3.9z"
													></path>
												</svg>
											</i>
											<p><a href="tel:+918817722621">+917000830437</a></p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M1.3,3h21.3C23.4,3,24,3.7,24,4.5v15.1c0,0.9-0.5,1.5-1.3,1.5H1.3C0.6,21,0,20.5,0,19.5V4.5C0,3.7,0.6,3,1.3,3z
                                       M22.8,5l-6.6,6.7l6.6,7.5V5z M15.4,12.6l-3.1,3.2c-0.2,0.2-0.6,0.2-0.8,0l-2.9-3l-6.1,7h19.2L15.4,12.6z M7.7,11.9L1.2,5.2v14.2
                                       L7.7,11.9z M2,4.3l9.9,10.1l10-10.1H2z"
													></path>
												</svg>
											</i>
											<p>
												<a href="mailto:sales@genixbit.com"
													>sales@genixbit.com</a
												>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-12 mb-md-4 mb-0">
					<div class="footer_box">
						<div class="row align-items-end justify-content-between">
							<div class="col-12 col-sm-12 fxt-our-places">
								<div class="footer_box_img mb-30">
									<img
										class="lazy box-shadow rounded10"
										alt="GenixBit Labs is one of the leading web and mobile development companies in London. We have a team of experienced UI/IX, app developers and developers."
										src="../../assets/images/bengaluru.jpg"
										style=""
										width="360"
										height="227"
									/>
								</div>
								<div class="footer_box_add mb-4">
									<h5>Bengaluru</h5>
									<ul>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M12,0L12,0C7.3,0,3.5,3.9,3.5,8.7c0,1.7,0.5,3.3,1.4,4.7c1.7,2.9,6.3,9.9,6.5,10.2c0.2,0.4,0.7,0.5,1.1,0.2
                                       c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.3,4.8-7.3,6.5-10.2c0.9-1.4,1.3-3.1,1.3-4.7C20.5,3.9,16.7,0,12,0z M17.8,12.6
                                       c-1.3,2.2-4.4,7-5.8,9.1c-1.4-2.1-4.5-6.9-5.8-9.1c-0.7-1.2-1.1-2.5-1.1-3.9c0-3.9,3.1-7.1,6.9-7.1c3.8,0,6.9,3.2,6.9,7.1
                                       C18.9,10.1,18.5,11.5,17.8,12.6z M12,4.3c-2.3,0-4.2,2-4.2,4.4c0,2.4,1.9,4.4,4.2,4.4c2.3,0,4.2-2,4.2-4.4
                                       C16.2,6.3,14.3,4.3,12,4.3z M12,11.4c-1.5,0-2.7-1.2-2.7-2.7C9.3,7.2,10.5,6,12,6c1.5,0,2.7,1.2,2.7,2.7
                                       C14.7,10.2,13.5,11.4,12,11.4z"
													></path>
												</svg>
											</i>
											<p style="max-width: 330px">
												320, Block C, Radiant Lake view, Medahalli, Bangalore-560049
											</p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M18.5,24c-0.8,0-2.4-0.1-4.5-1c-2.6-1-5.2-2.8-7.6-5.3C1.4,12.8-0.4,9.1,0.1,5C0.2,4.5,0.3,4,0.5,3.4
                                       c0,0,0,0,0,0C0.7,2.9,1,2.4,1.3,2c0-0.1,0.1-0.2,0.2-0.3l1-1C2.9,0.2,3.5,0,4.2,0c0.6,0,1.3,0.2,1.7,0.7l3.5,3.5c1,1,0.9,2.5,0,3.5
                                       L8,9.1c0.6,1.1,1.4,2.5,2.9,4c1.5,1.5,2.9,2.3,4,2.9l1.4-1.4c0.5-0.5,1.1-0.8,1.8-0.8c0.6,0,1.3,0.3,1.7,0.7l3.5,3.5
                                       c1,1,0.9,2.5,0,3.5c0,0,0,0,0,0l-1,1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.4,0.3-0.9,0.6-1.4,0.8c-0.5,0.2-1.1,0.3-1.6,0.4c0,0,0,0,0,0
                                       C18.9,24,18.7,24,18.5,24z M1.7,3.9C1.6,4.3,1.4,4.8,1.4,5.2c-0.4,3.6,1.3,7,5.8,11.6c5.4,5.5,10,5.9,11.2,5.9c0.2,0,0.3,0,0.3,0
                                       c0.4-0.1,0.9-0.2,1.3-0.3c0.5-0.2,0.9-0.4,1.3-0.7c0,0,0.1,0,0.1-0.1l0.9-0.8c0.5-0.5,0.5-1.2,0-1.7l-3.5-3.5
                                       c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.9,0.4l-1.8,1.8c-0.2,0.2-0.5,0.3-0.8,0.1c-0.1-0.1-0.2-0.1-0.4-0.2
                                       c-1.1-0.6-2.7-1.5-4.4-3.2c-1.7-1.7-2.5-3.3-3.2-4.4l0-0.1C6.7,9.5,6.6,9.4,6.6,9.4C6.4,9.1,6.5,8.8,6.7,8.6l1.8-1.8
                                       c0.5-0.5,0.5-1.2,0-1.7L5,1.6C4.8,1.4,4.5,1.3,4.2,1.3c-0.3,0-0.6,0.1-0.9,0.4L2.5,2.5c0,0,0,0.1-0.1,0.1C2.1,3,1.9,3.4,1.7,3.9z"
													></path>
												</svg>
											</i>
											<p><a href="tel:+919075578712">+919075578712</a></p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M1.3,3h21.3C23.4,3,24,3.7,24,4.5v15.1c0,0.9-0.5,1.5-1.3,1.5H1.3C0.6,21,0,20.5,0,19.5V4.5C0,3.7,0.6,3,1.3,3z
                                       M22.8,5l-6.6,6.7l6.6,7.5V5z M15.4,12.6l-3.1,3.2c-0.2,0.2-0.6,0.2-0.8,0l-2.9-3l-6.1,7h19.2L15.4,12.6z M7.7,11.9L1.2,5.2v14.2
                                       L7.7,11.9z M2,4.3l9.9,10.1l10-10.1H2z"
													></path>
												</svg>
											</i>
											<p>
												<a href="mailto:sales@genixbit.com"
													>sales@genixbit.com</a
												>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-12">
					<div class="footer_box">
						<div class="row align-items-end justify-content-between">
							<div class="col-12 col-sm-12 fxt-our-places">
								<div class="footer_box_img mb-30">
									<img
										class="lazy box-shadow rounded10"
										alt="GenixBit Labs Mobile App Development Office"
										src="../../assets/images/Nagpur-India.jpg"
										style=""
										width="360"
										height="227"
									/>
								</div>
								<div class="footer_box_add">
									<h5>Nagpur</h5>
									<ul>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M12,0L12,0C7.3,0,3.5,3.9,3.5,8.7c0,1.7,0.5,3.3,1.4,4.7c1.7,2.9,6.3,9.9,6.5,10.2c0.2,0.4,0.7,0.5,1.1,0.2
                                       c0.1-0.1,0.2-0.1,0.2-0.2c0.2-0.3,4.8-7.3,6.5-10.2c0.9-1.4,1.3-3.1,1.3-4.7C20.5,3.9,16.7,0,12,0z M17.8,12.6
                                       c-1.3,2.2-4.4,7-5.8,9.1c-1.4-2.1-4.5-6.9-5.8-9.1c-0.7-1.2-1.1-2.5-1.1-3.9c0-3.9,3.1-7.1,6.9-7.1c3.8,0,6.9,3.2,6.9,7.1
                                       C18.9,10.1,18.5,11.5,17.8,12.6z M12,4.3c-2.3,0-4.2,2-4.2,4.4c0,2.4,1.9,4.4,4.2,4.4c2.3,0,4.2-2,4.2-4.4
                                       C16.2,6.3,14.3,4.3,12,4.3z M12,11.4c-1.5,0-2.7-1.2-2.7-2.7C9.3,7.2,10.5,6,12,6c1.5,0,2.7,1.2,2.7,2.7
                                       C14.7,10.2,13.5,11.4,12,11.4z"
													></path>
												</svg>
											</i>
											<p style="max-width: 330px">
												Prayag Enclaves, Shankar Nagar, Nagpur
												380051, India
											</p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M18.5,24c-0.8,0-2.4-0.1-4.5-1c-2.6-1-5.2-2.8-7.6-5.3C1.4,12.8-0.4,9.1,0.1,5C0.2,4.5,0.3,4,0.5,3.4
                                       c0,0,0,0,0,0C0.7,2.9,1,2.4,1.3,2c0-0.1,0.1-0.2,0.2-0.3l1-1C2.9,0.2,3.5,0,4.2,0c0.6,0,1.3,0.2,1.7,0.7l3.5,3.5c1,1,0.9,2.5,0,3.5
                                       L8,9.1c0.6,1.1,1.4,2.5,2.9,4c1.5,1.5,2.9,2.3,4,2.9l1.4-1.4c0.5-0.5,1.1-0.8,1.8-0.8c0.6,0,1.3,0.3,1.7,0.7l3.5,3.5
                                       c1,1,0.9,2.5,0,3.5c0,0,0,0,0,0l-1,1c-0.1,0.1-0.1,0.1-0.2,0.1c-0.4,0.3-0.9,0.6-1.4,0.8c-0.5,0.2-1.1,0.3-1.6,0.4c0,0,0,0,0,0
                                       C18.9,24,18.7,24,18.5,24z M1.7,3.9C1.6,4.3,1.4,4.8,1.4,5.2c-0.4,3.6,1.3,7,5.8,11.6c5.4,5.5,10,5.9,11.2,5.9c0.2,0,0.3,0,0.3,0
                                       c0.4-0.1,0.9-0.2,1.3-0.3c0.5-0.2,0.9-0.4,1.3-0.7c0,0,0.1,0,0.1-0.1l0.9-0.8c0.5-0.5,0.5-1.2,0-1.7l-3.5-3.5
                                       c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.9,0.4l-1.8,1.8c-0.2,0.2-0.5,0.3-0.8,0.1c-0.1-0.1-0.2-0.1-0.4-0.2
                                       c-1.1-0.6-2.7-1.5-4.4-3.2c-1.7-1.7-2.5-3.3-3.2-4.4l0-0.1C6.7,9.5,6.6,9.4,6.6,9.4C6.4,9.1,6.5,8.8,6.7,8.6l1.8-1.8
                                       c0.5-0.5,0.5-1.2,0-1.7L5,1.6C4.8,1.4,4.5,1.3,4.2,1.3c-0.3,0-0.6,0.1-0.9,0.4L2.5,2.5c0,0,0,0.1-0.1,0.1C2.1,3,1.9,3.4,1.7,3.9z"
													></path>
												</svg>
											</i>
											<p><a href="tel:+918817722621">+918817722621</a></p>
										</li>
										<li>
											<i>
												<svg
													viewBox="0 0 24 24"
													style="enable-background: new 0 0 24 24"
													xml:space="preserve"
												>
													<path
														fill="#6A6D6F"
														d="M1.3,3h21.3C23.4,3,24,3.7,24,4.5v15.1c0,0.9-0.5,1.5-1.3,1.5H1.3C0.6,21,0,20.5,0,19.5V4.5C0,3.7,0.6,3,1.3,3z
                                       M22.8,5l-6.6,6.7l6.6,7.5V5z M15.4,12.6l-3.1,3.2c-0.2,0.2-0.6,0.2-0.8,0l-2.9-3l-6.1,7h19.2L15.4,12.6z M7.7,11.9L1.2,5.2v14.2
                                       L7.7,11.9z M2,4.3l9.9,10.1l10-10.1H2z"
													></path>
												</svg>
											</i>
											<p>
												<a href="mailto:sales@genixbit.com"
													>sales@genixbit.com</a
												>
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {};
</script>

<style>


.footer_box {
	width: 100%;
}
.footer_box ul {
	width: 100%;
	padding: 0;
}
.footer_box ul li p {
	color: #6a6d6f;
	font-size: 15px;
	line-height: 1.5;
	margin: 0;
}
.footer_box ul li a {
	color: #6a6d6f;
	font-size: 15px;
	line-height: 2;
}
.footer_box ul li {
	display: flex;
	align-items: flex-start;
	margin-bottom: 15px;
	width: 100%;
}
.footer_box ul li i {
	min-width: 24px;
	width: 24px;
	margin-right: 10px;
	position: relative;
	top: 2px;
}
@media (max-width: 1200px) {
	.our-places-cont {
		margin-bottom: 0 !important;
	}
}
@media (max-width: 776px) {
	.fxt-our-places {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
@media (max-width: 576px) {
	.fxt-places {
		font-size:20px !important;
		font-weight:bold !important;
	}
}
</style>
