<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>Our Work took Us</span>
				<h2 class="fxt-talk-dev">Our Developed Apps Have Been Featured In</h2>
				<p>
					Indeed this makes us proud as a mobile app development company. Check out the
					popular product forums, thriving social communities and app stores where our
					apps have made it to the leaderboards and favourite lists.
				</p>
			</div>
			<ul class="client-logo d-flex align-items-center justify-content-between">
				<li>
					<img
						class="lazy"
						alt="daily mail"
						src="../../assets/images/daily-mail.png"
						style=""
						width="159"
						height="90"
					/>
				</li>
				<li>
					<img
						class="lazy"
						alt="nottingham post"
						src="../../assets/images/post.png"
						style=""
						width="159"
						height="90"
					/>
				</li>
				<li>
					<img
						class="lazy"
						alt="the sun newspaper"
						src="../../assets/images/sun.png"
						style=""
						width="159"
						height="90"
					/>
				</li>
				<li>
					<img
						class="lazy"
						alt="manchester evening news"
						src="../../assets/images/men.png"
						style=""
						width="159"
						height="90"
					/>
				</li>
				<li>
					<img
						class="lazy"
						alt="BBC"
						src="../../assets/images/bbc.png"
						style=""
						width="90"
						height="159"
					/>
				</li>
			</ul>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
ul {
	list-style: none;
}
.client-logo li {
	width: 100%;
	text-align: center;
	border-right: 1px solid #dddfe1;
	padding: 5px;
}
.client-logo li:last-child {
	border-right: none;
}
@media (max-width: 576px) {
	.fxt-talk-dev {
		font-size:20px !important;
		font-weight:bold !important;
	}
	.client-logo {
		padding-left: 0 !important
	}
}

</style>
