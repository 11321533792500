<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/fitness-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Build a health & fitness mobile app that can make anyone healthy
					</h2>
					<p>
						The endless digital entertainment that mobile apps serve used with has long since been
						blamed to be the cause of physical inactivity. We believe that the same mobile apps to
						hold the power to help users adopt a healthy lifestyle.
					</p>
					<p>
						Genixbit brings together the power of technology and age-old physical training to help
						users lead a healthy lifestyle. We build health and fitness mobile apps that empower
						users to set up an exercise regime, follow the regime like a habit and also measure the
						results from time-to-time.
					</p>
					<p>
						If a mobile app that runs ahead of the time is what you are looking for, Genixbit can
						help you build mobile apps that can work fine with wearable devices, fitness trackers
						and even on usual smartphones.
					</p>
					<p>
						All we need you to do is to give a broad picture of what you are looking for. We will
						bring in rest of the IT capabilities you need to build a complete health & fitness app.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
