<template>
	<section class="padding-60 bg-white p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Popular Dating App Models </span>
				<h2 class="fxt-process-dev">
					Our Dating App Development Services Are Inspired by Success Stories
				</h2>
				<p>
					Want to replicate the success story of a popular dating app? As a globally positioned
					dating app development company, the dating app developers at GenixBit Labs will help you
					build an app that does mimic and resonates with the global audience who are used to apps
					like Tinder, Bumble, OkCUpid, Happn and so on.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-6 col-md-6 col-sm-12 col-12 mb-40"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<RightImageCard v-bind:obj="item"></RightImageCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RightImageCard from '../../IndustrySolutions/AssetComponents/RightImageCard.vue';
export default {
	components: {
		RightImageCard
	},
	data() {
		return {
			items: [
				{
					imageLink: '/tinder-app.jpg',
					title: 'Tinder',
					text: 'Tinder paved the way for the growth of the online dating industry. From quick profile creation with social media integration to creating personalized bios, build an app like Tinder that will simplify matchmaking.'
				},
				{
					imageLink: '/bumble-app.jpg',
					title: 'Bumble',
					text: '‘Ladies first’ has a new meaning now. Build a dating app like Bumble that lets ladies make the first move when it comes to dating. Build a safe and secure app that lets lady users to find their perfect match.'
				},
				{
					imageLink: '/okcupid-app.jpg',
					title: 'OkCupid',
					text: 'OkCupid’s ‘Find my kind’ approach to dating removes the awkwardness in meeting strangers. Build a dating app like OkCupid that runs on an intelligent algorithm that makes it easy for users to find their kind.'
				},
				{
					imageLink: '/happen-app.jpg',
					title: 'Happn',
					text: 'Happn helps meet people with whom users have crossed paths in the recent past. Build an app like Happn that harnesses GPS location data and user’s personal preferences for matchmaking.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
