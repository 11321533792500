<template>
	<section class="banner innerbanner aboutusbanner fxt-about-section">
		<div class="video pt-0">
			<img
				class="lazy fxt-about-img pt-0"
				alt=" Genixbit careers"
				src="../../assets/images/about-us-banner.jpg"
			/>
		</div>
		<div
			class="tenc-intro-main__wrapper"
			style="
				min-height: unset !important;
				background-image: linear-gradient(to right, #000000e8, #000000a8);
			"
		>
			<div class="container py-0" style="">
				<div class="row align-items-center">
					<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
						<h1 class="fxt-top-head">
							<span style="color: #1977cc">WE ARE Genixbit</span>
							<span>We Deliver the Code, Your Project Delivers the Change</span>
						</h1>
						<p style="margin: 0 0 10px; font-size: 14px" class="fxt-top-par about">
							The Mobile App Developers Located in the Jewel of Western India (Ahmedabad
							ofcourse...) Our passion is to deliver
							<span
								class="tou-node"
								id="tou-0-e5ee8caa-929b-4339-9f89-6a8f609cb936"
								lang="es"
							></span>
							disrupting solutions for our Clients, so their Business can change the world we live
							in for the better.
						</p>
						<p style="margin: 0; font-size: 14px" class="fxt-top-par about">
							We are
							<span
								class="tou-node"
								id="tou-0-ebf25f3f-f482-4152-8dc1-e49912a62395"
								lang="es"
							></span>
							looking for the latest innovation and want to work with Client’s who are as passionate
							as we are to deliver change. Having been founded in 2015, starting as
							<span
								class="tou-node"
								id="tou-0-d2040f5d-d902-4bb9-834d-66c7c80354e2"
								lang="es"
							></span>
							of freelancers, becoming a market leader in mobile
							<span class="tou-node" id="tou-0-a7fe0ad6-f501-42fb-a41f-9392d7bc87b3"></span>
							solutions
							<span class="tou-node" id="tou-0-b5b7b106-59cb-46ab-809e-e288af5acc6a"></span>, we’re
							excited to have new and exciting business’ join us for our next Chapter.
						</p>
						<ol class="breadcrumb">
							<li class="breadcrumb-item text-white">
								<router-link
									to="/"
									class="text-white breadcrumb-item"
									style="background: none; font-size: 14px; margin-right: 0"
								>
									Home</router-link
								>
							</li>
							<li class="breadcrumb-item text-white active">About Us</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style scoped>
@media (max-width: 1600px) {
	.banner {
	}
}
.banner {
	margin-top: 40px;
	position: relative;
	z-index: 9;
	overflow: hidden;
}
.breadcrumb {
	background: 0 0;
	width: 100%;
	max-width: 1170px;
	margin: 40px 0 0 0;
	padding: 0 15px;
	z-index: 9999;
}

.video img {
	width: 100%;
	height: 100%;
	min-height: 650px;
	object-fit: cover;
}
.tenc-intro-main__wrapper {
	width: 100%;
	position: absolute;
	top: -66px;
	left: 0;
	display: flex;
	align-items: center;
	background: linear-gradient(
		90deg,
		#236aec,
		rgba(35, 95, 236, 0.95) 40%,
		rgba(35, 161, 236, 0.7) 70%,
		rgba(35, 66, 236, 0.4)
	);
	z-index: 999;

}
.fxt-btn-custome {
	margin-top: 10px;
}


.fxt-top-head span:first-child {
	display: block;
	font-size: 28px;
}
.fxt-top-head span:last-child {
	font-size: 28px;
	margin: 20px 0;
}
.breadcrumb {
	padding-top: 15px;
	padding-left: 0 !important;
	margin-left: -20px;
}
@media (max-width: 991px) {
	.fxt-top-head {
		font-size: 20px;
	}
	.fxt-top-par {
		font-size: 14px;
	}
	.fxt-tenc-intro-main__wrapper {
		top: 0;
	}
}
@media (min-width: 776px) {
	.fxt-about-section {
		height: calc(90vh - 40px) !important;
	}
	.video {
		height: 90vh !important;
	}
	.breadcrumb {
		margin-top:0 !important;
	}
	.tenc-intro-main__wrapper {
		height: 90vh !important;
		top: -20px !important
	}
	.fxt-about-img {
		height: 100% !important;
		min-height: unset !important;
	}
}
.breadcrumb-item.active {
	color: #1977cc !important;
}
.fxt-about-img {
	position: relative;
}
.fxt-about-img::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background: red;
}

@media (max-width: 576px) {
	.fxt-top-head {
		font-size: 20px;
		text-overflow: ellipsis;
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.fxt-top-par {
		font-size: 14px !important;
		line-height: 2;
	}
	.fxt-btn-custome {
		font-size: 12px;
	}
	.tenc-intro-main__wrapper {
		padding-top: 0px;
		top:10px !important
	}
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 34px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 28px !important;
	}
}
@media (max-width: 400px) {
	.tenc-intro-main__wrapper .fxt-top-head span {
		font-size: 28px !important;
	}
	.tenc-intro-main__wrapper .fxt-top-head span:last-child {
		font-size: 22px !important;
	}
}
</style>
