<template>
	<section class="padding-60 bg-white">
		<div class="container">
			<div class="title">
				<span>Who Talk About Us</span>
				<h2 class="fxt-talk-dev">Publications we're Featured in</h2>
				<p>
					Good news spreads fast. We’re not one to boast, but GenixBit Labs mobile app development
					work has been picked up and featured by digital publications across the world.
				</p>
			</div>
			<div class="row justify-content-center">
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="Being a top application development company, GenixBit Labs's co-founder Tuhin Bhatt and Dhaval Sarvaiya featured in Entrepreneur website." width="220" height="43" src="../../assets/images/entrepreneur.webp" style="">
            </div>
         </div>
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="Goodfirms recognised GenixBit Labs as top app developers UK, They featured GenixBit Labs in Yahoo Finance." width="175" height="60" src="../../assets/images/yahoo-finance.webp" style="">
            </div>
         </div>
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="Based on the quality of client feedback and industry leadership, GenixBit Labs was listed among the top mobile app development companies in the UK by Business Insider." width="176" height="56" src="../../assets/images/business-insider.webp" style="">
            </div>
         </div>
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="GenixBit Labs is known as top UK app developers. Our co-founder being an industry leader has become a regular contributor to Business2Community mobile app development category." width="179" height="75" src="../../assets/images/business-2-community.webp" style="">
            </div>
         </div>
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="Being a top web and mobile application development company, GenixBit Labs likes to share its mobile app development expertise with the audience of Dzone." width="190" height="55" src="../../assets/images/dzone.webp" style="">
            </div>
         </div>
         <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div class="featured-in-image-box d-flex justify-content-center align-items-center rounded10 mb-30">
               <img class="lazy" alt="Hackernoon featured GenixBit Labs as a top flutter mobile app development companies of 2019. Dhaval Sarviya being an expert app developer shares his mobile app development experience with Hackernoon readers." width="226" height="27" src="../../assets/images/hackernoon.png" style="">
            </div>
         </div>
      </div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
ul {
	list-style: none;
}
.client-logo li {
	width: 100%;
	text-align: center;
	border-right: 1px solid #dddfe1;
	padding: 5px;
}
.client-logo li:last-child {
	border-right: none;
}
.featured-in-image-box {
	height: 110px;
	background-color: #ffffff;
	position: relative;
	padding: 5px 10px;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.featured-in-image-box img {
	align-self: center;
	-ms-flex: 1;
}
.featured-in-image-box:hover {
	-webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
}
@media (max-width: 576px) {
	.fxt-talk-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.client-logo {
		padding-left: 0 !important;
	}
}
</style>
