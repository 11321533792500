<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					What Makes Genixbit Your best Unity 2D 3D Game Development Partner
				</h2>
				<p>
					When a client thinks of a game development project, hitting the defined goals is of the
					up-most importance. We acquire the required capabilities to meet the goals of clients with
					our 3D mobile game development for Clients based in the UK and abroad.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<div class="game-why-us-inner-content-wrap">
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 64 80"
									style="enable-background: new 0 0 64 80"
									xml:space="preserve"
								>
									<g>
										<circle class="st0" cx="19.8" cy="18.8" r="0.9"></circle>
										<circle class="st0" cx="28.6" cy="18.8" r="0.9"></circle>
										<path
											class="st0"
											d="M27.7,24.1H26c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8h-1.8c0,1.9,1.6,3.5,3.5,3.5S27.7,26,27.7,24.1z"
										></path>
										<path
											class="st0"
											d="M57.7,51.5c1.5,0,2.6-1.2,2.6-2.6V36.5c0-1.5-1.2-2.6-2.6-2.6H36.6c-0.5,0-1,0.1-1.4,0.4l-5.7-1.9v-1.8
                        c2-1.3,3.4-3.2,4-5.5c2.2-0.3,3.9-2.1,3.9-4.4v-7.1c0-7.3-5.9-13.2-13.2-13.2S11,6.2,11,13.5v7.1c0,2.3,1.7,4.1,3.9,4.4
                        c0.6,2.3,2.1,4.3,4,5.5v1.8l-9,3c-2.5,0.8-4.2,3.2-4.2,5.9v8.5h7.7c-5.1,2.4-8.7,7.6-8.7,13.6c0,8.2,6.7,15,15,15
                        c8.2,0,15-6.7,15-15c0-6-3.6-11.2-8.7-13.6h8.2c0.4,1,1.3,1.8,2.5,1.8h5.3v1.8h-1.8V55h6.8v1.2c0,0.8-0.7,1.5-1.5,1.5h-6.6
                        c-1.2,0-2.3,1.1-2.3,2.3s1,2.3,2.3,2.3h17c0.5,0,0.8,0.4,0.8,0.9S56.4,64,55.9,64h-7.2c-1.2,0-2.3,1.1-2.3,2.3V68h-6
                        c-3.2,0-5.9,2.7-5.9,5.9c0,3.3,2.6,6,5.9,6c2.5,0,4.6-1.5,5.5-3.6h2.5c1,2.3,3.1,3.6,5.5,3.6c3.2,0,5.9-2.7,5.9-6
                        c0-3.3-2.6-5.9-5.9-5.9h-6v-1.5c0-0.5,0.4-0.9,0.8-0.9h7.2c1.2,0,2.3-1.1,2.3-2.3s-1-2.3-2.3-2.3h-17c-0.5,0-0.8-0.4-0.8-0.9
                        s0.4-0.9,0.8-0.9h6.6c1.7,0,3.1-1.4,3.1-3.1V55h5.7v-1.8h-1.8v-1.8H57.7z M32.5,63.3c0,7.1-5.8,12.9-12.9,12.9
                        c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9C26.8,50.4,32.5,56.2,32.5,63.3z M31.8,34.9l-3.4,5.4l-3-3l3.4-3.4L31.8,34.9z
                        M24.2,36.1l-3.5-3.5v-1.2c1.1,0.4,2.3,0.7,3.5,0.7s2.4-0.2,3.5-0.7v1.2L24.2,36.1z M33.9,23.1c0-0.2,0-0.5,0-0.7v-4.2
                        c1,0.4,1.8,1.3,1.8,2.5C35.7,21.7,34.9,22.7,33.9,23.1z M12.7,20.6c0-1.1,0.7-2.1,1.8-2.5v4.2c0,0.2,0,0.5,0,0.7
                        C13.5,22.7,12.7,21.7,12.7,20.6z M14.5,16.2L14.5,16.2c-0.7,0.2-1.2,0.5-1.8,0.9v-3.6C12.7,7.2,17.9,2,24.2,2s11.5,5.1,11.5,11.5
                        v3.6c-0.5-0.4-1.1-0.7-1.8-0.8v-0.1c0-1-0.8-1.8-1.8-1.8H16.3C15.3,14.4,14.5,15.2,14.5,16.2z M16.3,22.3v-6.2h15.9v6.2
                        c0,4.4-3.6,7.9-7.9,7.9S16.3,26.7,16.3,22.3z M19.5,33.9l3.4,3.4l-3,3l-3.4-5.4L19.5,33.9z M12.7,47.9H7.4v-1.8h5.3V47.9z
                        M12.7,44.4H7.4v-3.2c0-1.9,1.2-3.6,3-4.2l4.4-1.5l4.8,7.7l3.7-3.7v8.5h-8.8v-6.2h-1.8V44.4z M33.9,36.5v5.3v6.2h-8.8v-8.5l3.7,3.7
                        l4.8-7.7l0.5,0.2C34,35.9,33.9,36.2,33.9,36.5z M35.7,41.8v-5.3c0-0.5,0.4-0.9,0.9-0.9h21.2c0.5,0,0.9,0.4,0.9,0.9v9.7H35.7V41.8z
                        M36,74.1c0-2.4,2-4.3,4.4-4.3h13.4c2.5,0,4.4,1.9,4.4,4.3c0,2.4-2,4.3-4.4,4.3c-1.9,0-3.5-1.3-4.2-3.2l-0.2-0.5h-4.7l-0.2,0.5
                        c-0.6,1.9-2.2,3.1-4.1,3.1C37.9,78.4,36,76.5,36,74.1z M50.7,53.2h-7.1v-1.8h7.1V53.2z M36.6,49.7c-0.5,0-0.9-0.4-0.9-0.9v-0.9
                        h22.9v0.9c0,0.5-0.4,0.9-0.9,0.9H36.6z"
										></path>
										<polygon
											class="st0"
											points="50.1,38.9 52.7,40.9 50.1,42.8 51.2,44.2 55.7,40.9 51.2,37.5  "
										></polygon>
										<polygon
											class="st0"
											points="43.1,37.5 38.6,40.9 43.1,44.2 44.1,42.8 41.6,40.9 44.1,38.9  "
										></polygon>
										<rect
											x="43.2"
											y="40"
											transform="matrix(0.3881 -0.9216 0.9216 0.3881 -8.8211 68.4551)"
											class="st0"
											width="7.9"
											height="1.8"
										></rect>
										<path
											class="st0"
											d="M53.9,73.2c0.8,0,1.3-0.6,1.3-1.4s-0.6-1.4-1.3-1.4c-0.8,0-1.3,0.6-1.3,1.4S53.1,73.2,53.9,73.2z"
										></path>
										<path
											class="st0"
											d="M53.9,74.6c-0.8,0-1.3,0.6-1.3,1.4s0.6,1.4,1.3,1.4c0.8,0,1.3-0.6,1.3-1.4S54.6,74.6,53.9,74.6z"
										></path>
										<path
											class="st0"
											d="M54.5,73.9c0,0.8,0.6,1.4,1.3,1.4c0.8,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4C55.1,72.6,54.5,73.2,54.5,73.9
                        z"
										></path>
										<path
											class="st0"
											d="M51.8,75.3c0.6,0,1.3-0.6,1.3-1.4c0-0.8-0.6-1.4-1.3-1.4s-1.3,0.6-1.3,1.4C50.5,74.7,51.1,75.3,51.8,75.3z"
										></path>
										<polygon
											class="st0"
											points="39.7,76.9 41.3,76.9 41.3,74.8 43.3,74.8 43.3,73.2 41.3,73.2 41.3,71.1 39.7,71.1 39.7,73.2
                        37.7,73.2 37.7,74.8 39.7,74.8   "
										></polygon>
										<circle class="st0" cx="15.5" cy="62.1" r="0.5"></circle>
										<path
											class="st0"
											d="M12.2,65.2c0.2-0.2,0.2-0.4,0-0.6s-0.4-0.2-0.6,0s-0.2,0.4,0,0.6C11.8,65.2,12,65.2,12.2,65.2z"
										></path>
										<path
											class="st0"
											d="M14.1,68c0.2-0.2,0.2-0.4,0-0.6s-0.4-0.2-0.6,0s-0.2,0.4,0,0.6S14.1,68.2,14.1,68z"
										></path>
										<path
											class="st0"
											d="M16.9,67.3c-0.2,0.2-0.2,0.4,0,0.6s0.4,0.2,0.6,0s0.2-0.4,0-0.6C17.3,67.3,16.9,67.3,16.9,67.3z"
										></path>
										<circle class="st0" cx="18.1" cy="66.4" r="0.5"></circle>
										<path
											class="st0"
											d="M16.5,59.7l0.2,0.4l-0.9-0.4h-0.2l-5.3,2.1c-0.2,0-0.2,0.2-0.2,0.4v6.4c0,0.2,0,0.2,0.2,0.4l5.3,2.1
                        c0,0,0,0,0.2,0c0,0,0,0,0.2,0l5.3-2.1c0.2,0,0.2-0.2,0.2-0.4v-2.8l3.6-0.2c0,0,0.2,0,0.2-0.2l4-3.8c-0.2-0.2-0.2-0.4-0.2-0.6
                        l-2.6-5.8c0-0.2-0.2-0.2-0.4-0.2l-5.5,0.2c0,0-0.2,0-0.2,0.2l-4,3.8C16.2,59.5,16.2,59.5,16.5,59.7z M15.2,69.9l-4.5-1.7v-5.5
                        l4.5,1.7V69.9z M20.3,68.2l-4.5,1.7v-5.5l2.1-0.9l0.9,1.9c0,0.2,0.2,0.2,0.4,0.2h1.1V68.2z M28.4,61L25,64.4l-2.1-5.1l3.4-3.4
                        L28.4,61z M20.9,55.8l4.5-0.2l-3.2,3.2L17.7,59L20.9,55.8z M22,59.7l2.1,5.1L19.4,65l-2.1-5.1L22,59.7z M17.7,62.9l-2.3,0.9l-4-1.7
                        l4-1.7l1.5,0.6L17.7,62.9z"
										></path>
										<path
											class="st0"
											d="M21.6,57.1c-0.2,0-0.4,0-0.4,0.2c-0.2,0,0,0.2,0.2,0.4c0.2,0,0.4,0,0.4-0.2C21.8,57.3,21.8,57.1,21.6,57.1z"
										></path>
										<path
											class="st0"
											d="M19.4,61.2c-0.2,0-0.4,0-0.4,0.2s0,0.4,0.2,0.4c0,0,0.2,0,0.4-0.2C19.7,61.4,19.7,61.2,19.4,61.2z"
										></path>
										<path
											class="st0"
											d="M22.6,63.7c0-0.2,0-0.4-0.2-0.4s-0.4,0-0.4,0.2c0,0,0,0.2,0.2,0.4C22.4,63.9,22.6,63.9,22.6,63.7z"
										></path>
										<path
											class="st0"
											d="M21.6,61.4c0-0.2,0-0.4-0.2-0.4s-0.4,0-0.4,0.2c0,0.2,0.2,0.4,0.2,0.4C21.4,61.6,21.6,61.6,21.6,61.4z"
										></path>
										<path
											class="st0"
											d="M20.1,63.9c0.2,0,0.4,0,0.4-0.2s0-0.4-0.2-0.4s-0.4,0-0.4,0.2C19.7,63.7,19.9,63.9,20.1,63.9z"
										></path>
										<path
											class="st0"
											d="M25.8,58.8c0.2,0,0.4,0,0.4-0.2s0-0.4-0.2-0.4s-0.4,0-0.4,0.2S25.6,58.8,25.8,58.8z"
										></path>
										<path
											class="st0"
											d="M27.1,61c0-0.2,0-0.4-0.2-0.4s-0.4,0-0.4,0.2c0,0,0,0.2,0.2,0.4C26.9,61.2,27.1,61.2,27.1,61z"
										></path>
										<path
											class="st0"
											d="M24.3,60.3c0.2,0,0.4,0,0.4-0.2c0-0.2,0-0.4-0.2-0.4s-0.4,0-0.4,0.2S24.1,60.3,24.3,60.3z"
										></path>
										<path
											class="st0"
											d="M25.2,62.4c0.2,0,0.4,0,0.4-0.2c0.2,0,0-0.2-0.2-0.4c-0.2,0-0.4,0-0.4,0.2S25,62.4,25.2,62.4z"
										></path>
										<path
											class="st0"
											d="M25.6,60.7c0.2,0,0.4,0,0.4-0.2s0-0.4-0.2-0.4s-0.4,0-0.4,0.2C25.4,60.5,25.4,60.7,25.6,60.7z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="one">
								<h3>Expertise in 2D 3D game development</h3>
								<p>
									We have years of experiences in the game development niche it doesn’t mean that we
									are not learning the latest technologies and trends. Therefore, our latest mobile
									3D games have unique flavours including AR, AI, and locations technologies.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<rect x="34.2" y="15.8" class="st0" width="9.2" height="1.7"></rect>
										<rect x="34.2" y="20" class="st0" width="9.2" height="1.7"></rect>
										<rect x="34.2" y="24.2" class="st0" width="9.2" height="1.7"></rect>
										<rect x="34.2" y="28.3" class="st0" width="9.2" height="1.7"></rect>
										<rect x="34.2" y="32.5" class="st0" width="9.2" height="1.7"></rect>
										<rect x="34.2" y="36.7" class="st0" width="9.2" height="1.7"></rect>
										<path
											class="st0"
											d="M12.5,11.7c-1.4,0-2.5,1.1-2.5,2.5v25c0,1.4,1.1,2.5,2.5,2.5h16.7c1.4,0,2.5-1.1,2.5-2.5v-25
                        c0-1.4-1.1-2.5-2.5-2.5H12.5z M30,14.2v25c0,0.5-0.4,0.8-0.8,0.8H12.5c-0.5,0-0.8-0.4-0.8-0.8v-25c0-0.5,0.4-0.8,0.8-0.8h16.7
                        C29.6,13.3,30,13.7,30,14.2z"
										></path>
										<path
											class="st0"
											d="M20.8,24.2c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2C16.7,22.3,18.5,24.2,20.8,24.2z
                        M20.8,17.5c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5S19.5,17.5,20.8,17.5z"
										></path>
										<path
											class="st0"
											d="M24.2,25h-6.7c-1.8,0-3.3,1.5-3.3,3.3v7.5l0.3,0.3c1.8,1.4,4,2.2,6.3,2.2c2.3,0,4.5-0.8,6.3-2.2l0.3-0.3v-7.5
                        C27.5,26.5,26,25,24.2,25z M25.8,35c-3,2.2-7,2.2-10,0v-6.7c0-0.9,0.7-1.7,1.7-1.7h6.7c0.9,0,1.7,0.7,1.7,1.7V35z"
										></path>
										<path
											class="st0"
											d="M0,0v50h50v-2.5h-1.7v0.8H1.7v-2.5H50v-2.5h-1.7v0.8H1.7V8.3h46.7v30H50V0H0z M48.3,6.7H1.7v-5h46.7V6.7z"
										></path>
										<rect x="48.3" y="40" class="st0" width="1.7" height="1.7"></rect>
										<rect x="3.3" y="3.3" class="st0" width="1.7" height="1.7"></rect>
										<rect x="6.7" y="3.3" class="st0" width="1.7" height="1.7"></rect>
										<rect x="10" y="3.3" class="st0" width="1.7" height="1.7"></rect>
										<rect x="13.3" y="3.3" class="st0" width="1.7" height="1.7"></rect>
										<polygon
											class="st0"
											points="46.7,14.2 46.7,10 42.5,10 42.5,11.7 45,11.7 45,14.2  "
										></polygon>
										<rect x="3.3" y="10" class="st0" width="4.2" height="1.7"></rect>
										<rect x="3.3" y="13.3" class="st0" width="4.2" height="1.7"></rect>
										<polygon
											class="st0"
											points="3.3,38.3 3.3,42.5 7.5,42.5 7.5,40.8 5,40.8 5,38.3  "
										></polygon>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="two">
								<h3>Excellent User Interface</h3>
								<p>
									Creative user interface with attractive backgrounds, striking game effects, and
									appealing characters to fit into the storyline of the game in highly contextual
									manner. Improve game engagement and retention rates high.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											class="st0"
											d="M16,26.3l2,3.4l3.8,0.8l3.6,1.6l3.6-1.6l3.9-0.8l2-3.4l2.6-3l-0.4-3.9l0.4-3.9l-2.6-2.9l-2-3.4l-3.8-0.8
                        l-3.6-1.6l-3.6,1.6L18,9.2l-2,3.4l-2.6,3l0.4,3.9l-0.4,3.9L16,26.3z M15,22.8l0.3-3.4L15,16.4h3.3v0.3c-0.1,0.1-0.1,0.3-0.1,0.4
                        l0,8.5c0,0.1,0,0.2,0.1,0.3h-0.9l-0.2-0.3L15,22.8z M24,17.5c0.1,0.2,0.4,0.4,0.7,0.4h5.8c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
                        h-0.9c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3h-0.7c-0.4,0-0.8,0.3-0.8,0.8
                        c0,0.4,0.3,0.8,0.7,0.8c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3h-0.2c-0.3,0-0.6,0.2-0.7,0.5c-0.1,0.3,0,0.6,0.2,0.9
                        c0,0,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.3,0.3v0.8l0-0.8L20,24.7v-6.9c0.1,0,0.2,0,0.3,0c0.7,0,1.4-0.4,1.8-1c0.2-0.4,0.6-0.8,1-1.1
                        c0.7-0.7,1.6-1.5,1.8-3.2c0.2,0.3,0.3,0.7,0.4,1.2c0.1,0.9-0.4,1.8-1,2.7L24,16.7C23.9,17,23.9,17.3,24,17.5z M19,10.6l3.3-0.7
                        l3.2-1.4l3.1,1.3l3.4,0.8l1.7,2.9l2.3,2.6l-0.3,3.4l0.3,3.4l-2.2,2.5l-1.8,3l-3.3,0.7l-3.2,1.4l-3.1-1.3L19,28.3l-0.6-1H20v-1.1
                        l7.9,0.1c0,0,0,0,0,0c1,0,1.9-0.8,1.9-1.9c0-0.1,0-0.2,0-0.2c0.6-0.3,1-0.9,1-1.6c0-0.2,0-0.4-0.1-0.6c0.5-0.3,0.9-0.9,0.9-1.6
                        c0-0.2,0-0.4-0.1-0.5c0.6-0.3,1-0.9,1-1.7c0-1-0.8-1.9-1.9-1.9h-4.5c0.4-0.8,0.7-1.7,0.7-2.7c-0.1-1.1-0.5-2-1.3-2.6
                        c-0.3-0.3-0.7-0.4-1.1-0.3c-1,0.2-1.1,0.9-1.1,1.1c0,1.5-0.6,2.1-1.4,2.8c-0.4,0.4-0.9,0.8-1.2,1.5c-0.1,0.2-0.2,0.2-0.4,0.2
                        c-0.1,0-0.2,0-0.3,0v-1.5h-3.8l1.1-1.2L19,10.6z"
										></path>
										<path
											class="st0"
											d="M38.9,31.4l0.7-1.4l4.4-4.5L43,19.6l0.9-6.2l-4.2-4.2l-2.9-5.5L31,2.8L25.4,0l-5.3,2.7l-6.2,1L11.3,9l-4.4,4.5
                        l0.9,5.9l-0.9,6.2l4.2,4.2l0.9,1.6L6.6,44.1l7.9,1l6.3,4.8l4.6-11.1l0,0L30,50l6.3-4.8l7.9-1L38.9,31.4z M8.6,25l0.7-4.8l2.7,0.1
                        l0-1.6l-2.7-0.1L8.6,14l3.4-3.5l2,1.5l1-1.2l-2.1-1.6L15,5.1l4.8-0.8l0.8,2.6l1.5-0.4l-0.8-2.6l4.1-2.1l4.4,2.2l-1,2.5L30.3,7
                        l1-2.6l4.6,0.7l2.3,4.3l-2.3,1.6l0.9,1.3l2.3-1.6l3.2,3.3l-0.7,4.8L39,18.7l-0.1,1.6l2.6,0.1l0.7,4.6l-3.5,3.6l-2.1-1.6l-1,1.2
                        l2.1,1.7l-2.1,4L31,34.6L30.2,32l-1.5,0.5l0.8,2.6l-4.1,2.1L21,35l0.9-2.6l-1.5-0.5l-0.9,2.7L15,33.8l-2.3-4.3l2.2-1.5L14,26.7
                        l-2.2,1.6L8.6,25z M20.1,47.5l-4.3-3.3l1.5-3.2l-1.4-0.7l-1.6,3.2l-5.5-0.7l4-9.7l1.1,2.1l5.1,0.8l1.3,0.5l0,0l3.5,1.7L20.1,47.5z
                        M26.8,38.3l3.9-2l6.2-1l1-2l4,9.6l-5.4,0.7L35,40.4l-1.4,0.7l1.5,3l-4.4,3.4L26.8,38.3z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="three">
								<h3>High Quality &amp; Security</h3>
								<p>
									Modern games for cross-platform experiences have several security issues due to
									its dependence on the Internet or other cellular connectivity, and we deal with it
									effectively without losing quality credentials.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											class="st0"
											d="M0.8,46.7h5c0.5,0,0.8-0.3,0.8-0.8h1.5l9,4.1c0.1,0.1,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.2l15.9-10.1
                        c1-0.6,1.3-1.9,0.8-2.9c-0.4-0.7-1.1-1.1-1.8-1.1c-0.1,0-0.1,0-0.2,0l-16.2,2.5l0,0l-3.6-2.4c-0.2-0.1-0.3-0.2-0.4-0.2H6.7
                        c0-0.5-0.3-0.8-0.8-0.8h-5c-0.5,0-0.8,0.3-0.8,0.8v10C0,46.3,0.3,46.7,0.8,46.7z M6.7,37.5h5.6l5.5,3.7c0.6,0.4,0.8,1.2,0.3,1.8
                        c0,0,0,0.1-0.1,0.1c-0.4,0.5-1.2,0.7-1.7,0.3L13,41l-0.9,1.4l3.4,2.3c1.3,0.9,3.2,0.6,4.1-0.8c0.9-1.3,0.6-3.2-0.8-4.1l-0.1-0.1
                        h-0.1l14.3-2.2c0.2,0,0.3,0.2,0.4,0.3c0.1,0.2,0,0.4-0.2,0.5l-15.6,9.9l-8.8-4c-0.1-0.1-0.2-0.1-0.3-0.1H6.7V37.5z M1.7,36.7H5V45
                        H1.7V36.7z"
										></path>
										<path
											class="st0"
											d="M18.2,29.1c-0.1,0.1-0.3,0.1-0.3,0l-1.7-0.7L15.5,30l1.7,0.7c0.6,0.3,1.2,0.2,1.7-0.1c1-0.5,1.4-1.7,0.9-2.8
                        c-0.2-0.4-0.6-0.8-1.1-1l-1-0.4c-0.2-0.1-0.3-0.3-0.3-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3,0l1.7,0.7l0.6-1.6l-1.7-0.7
                        c-0.6-0.3-1.2-0.2-1.7,0.1c-1,0.5-1.4,1.8-0.9,2.8c0.2,0.4,0.6,0.8,1.1,1l1,0.4c0.2,0.1,0.3,0.3,0.3,0.4
                        C18.3,29,18.3,29.1,18.2,29.1z"
										></path>
										<path
											class="st0"
											d="M5.8,19.2v13c0,0.3,0.3,0.7,0.6,0.8h0.1c0.1,0,0.1,0,0.2,0h0.1l4.2-0.5l0,0c0.3,0,0.4,0,0.7-0.1
                        c1.6-0.2,3.2,0.3,4.5,1.3c2.3,2,5.5,2.6,8.3,1.5l0,0l0,0l5-1.8c0.3-0.1,0.6-0.4,0.6-0.8V20c0-0.5-0.3-0.8-0.8-0.8
                        c-0.1,0-0.2,0-0.3,0.1l-5,1.8c-2.3,0.8-4.8,0.3-6.7-1.3c-1.6-1.3-3.5-2-5.5-2l0,0c-0.3,0-0.6,0-0.8,0.1l-4.4,0.4
                        C6.2,18.4,5.8,18.8,5.8,19.2z M28.3,32.2l-3.2,1.2c0.4-1.7,1.6-3,3.2-3.7V32.2z M28.3,21.2v3.2c-1.4,0.3-2.9-0.5-3.3-1.9v-0.1
                        L28.3,21.2z M7.5,19.9l3.3-0.3c-0.3,1.7-1.6,2.9-3.3,3.3V19.9z M7.5,24.6c2.6-0.4,4.6-2.5,4.9-5.1c1.3,0.2,2.7,0.7,3.7,1.6
                        c2,1.8,4.8,2.4,7.3,1.8c0.5,1.9,2.2,3.3,4.2,3.3c0.3,0,0.5,0,0.7-0.1v1.8h-0.1c-2.8,0.8-4.7,3.2-4.9,6c-2.2,0.6-4.5,0.1-6.2-1.3
                        c-1.3-1-2.9-1.6-4.7-1.7c-0.4-2.3-2.6-3.9-4.9-3.7V24.6z M7.5,28.8h0.2c1.4-0.2,2.7,0.8,3.1,2.1l-3.3,0.3V28.8z"
										></path>
										<rect x="17.5" y="10" class="st0" width="1.7" height="3.3"></rect>
										<rect
											x="26.2"
											y="13.8"
											transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1356 24.0111)"
											class="st0"
											width="3.5"
											height="1.7"
										></rect>
										<rect
											x="21.5"
											y="11.6"
											transform="matrix(0.4472 -0.8944 0.8944 0.4472 1.7887 27.8116)"
											class="st0"
											width="3.7"
											height="1.7"
										></rect>
										<rect
											x="7.9"
											y="12.8"
											transform="matrix(0.7071 -0.7071 0.7071 0.7071 -7.7493 10.4583)"
											class="st0"
											width="1.7"
											height="3.5"
										></rect>
										<rect
											x="12.5"
											y="10.7"
											transform="matrix(0.8944 -0.4472 0.4472 0.8944 -4.2041 7.2892)"
											class="st0"
											width="1.7"
											height="3.7"
										></rect>
										<path
											class="st0"
											d="M45.4,5.9c0.2-0.6,0.4-1.2,0.4-1.8C45.8,1.8,44,0,41.7,0s-4.2,1.8-4.2,4.2c0,0.6,0.2,1.2,0.4,1.8
                        c-2.7,0.4-4.6,2.7-4.6,5.3v7.9c0,0.5,0.3,0.8,0.8,0.8h2.5v10.8c0,0.5,0.3,0.8,0.8,0.8h8.3c0.5,0,0.8-0.3,0.8-0.8V20h2.5
                        c0.5,0,0.8-0.3,0.8-0.8v-7.9C50,8.6,48.1,6.3,45.4,5.9z M48.3,18.3h-1.7V10H45v20h-2.5V18.3h-1.7V30h-2.5V10h-1.7v8.3H35v-7.1
                        c0-2.1,1.7-3.8,3.8-3.8h0.7c0.5,0,0.8-0.3,0.8-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.6-0.5-0.8-1.2-0.8-1.8c0-1.4,1.1-2.5,2.5-2.5
                        s2.5,1.1,2.5,2.5c0,0.8-0.3,1.4-0.8,1.8c-0.3,0.3-0.4,0.8-0.1,1.2c0.2,0.3,0.3,0.3,0.6,0.3h0.7c2.2,0,3.8,1.7,3.8,3.8V18.3z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="four">
								<h3>Flexible Packages</h3>
								<p>
									We give reigns of game development in the hands of patrons through our
									comprehensive Hire 3D game developer packages to make it the most cost-efficient
									as they can.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-choose-us-game.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.game-why-us-inner-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 0;
}
.game-why-us-inner-box:hover h3,
.game-why-us-inner-box:hover p {
	color: #fff !important;
}
.game-why-us-inner-box:hover {
	background: #008dd2;
}

.game-why-us-inner-box:hover .game-why-us-inner-icon svg circle,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg ellipse,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg path,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg polygon,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg rect {
	fill: #008dd2;
}

.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}

.game-why-us-inner-icon svg circle,
.game-why-us-inner-icon svg ellipse,
.game-why-us-inner-icon svg path,
.game-why-us-inner-icon svg polygon,
.game-why-us-inner-icon svg rect {
	fill: black;
}

.game-why-us-inner-icon svg {
	width: 50px;
	height: 50px;
	fill: black;
}
.game-why-us-inner-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	min-height: 90px;
	border-radius: 100%;
	background-color: #fff;
	border: 1px solid #e0e2e4;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
