<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Redefine your Business Standards with CMS</span>
				<h2 class="fxt-process-dev">Why Choose GenixBit for CMS Web Development?</h2>
				<p>
					With a dedicated CMS team committed to excellence, we thrive market understanding and
					providing the latest CMS solutions for businesses. Tap into the whole new world of custom
					CMS development scaling business to uncharted territory.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
					<div class="why-you-invest-box-text">
						<h5 class="mb-20">Our Clients Love Us</h5>
						<a class="btn-custome btn waves-effect waves-light" href="/client-review"
							>Our Reviews</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../../EnterpriseSolutions/AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M6.67,3.33c0-0.92,0.75-1.67,1.67-1.67h33.33c0.92,0,1.67,0.75,1.67,1.67H45C45,1.49,43.51,0,41.67,0H8.33
                    C6.49,0,5,1.49,5,3.33v8.33h1.67V3.33z M43.33,46.67c0,0.92-0.75,1.67-1.67,1.67c-0.92,0-1.67-0.75-1.67-1.67v-2.5
                    c0-0.46-0.37-0.83-0.83-0.83H6.67V26.67H5v16.67H0.83C0.37,43.33,0,43.71,0,44.17v2.5C0,48.51,1.49,50,3.33,50h38.33
                    c1.84,0,3.33-1.49,3.33-3.33v-3.33h-1.67V46.67z M3.33,48.33c-0.92,0-1.67-0.75-1.67-1.67V45h36.67v1.67
                    c0,0.59,0.15,1.16,0.45,1.67H3.33z M0.83,25H17.5c0.46,0,0.83-0.37,0.83-0.83v-10c0-0.46-0.37-0.83-0.83-0.83H0.83
                    C0.37,13.33,0,13.71,0,14.17v10C0,24.63,0.37,25,0.83,25z M1.67,15h15v8.33h-15V15z M5.24,16.91l-1.67,1.67
                    c-0.33,0.33-0.33,0.85,0,1.18l1.67,1.67l1.18-1.18l-1.08-1.08l1.08-1.08L5.24,16.91z M13.09,16.91l-1.18,1.18l1.08,1.08
                    l-1.08,1.08l1.18,1.18l1.67-1.67c0.33-0.33,0.33-0.85,0-1.18L13.09,16.91z M7.54,21.4l1.58,0.53l1.67-5L9.21,16.4L7.54,21.4z
                    M49.17,5H20.83C20.37,5,20,5.37,20,5.83V22.5c0,0.46,0.37,0.83,0.83,0.83h28.33c0.46,0,0.83-0.37,0.83-0.83V5.83
                    C50,5.37,49.63,5,49.17,5z M48.33,21.67H21.67v-2.03l7.48-4.49l7.3,4.72c0.25,0.16,0.57,0.18,0.83,0.04l6.03-3.12l5.02,2.87V21.67
                    z M48.33,17.73l-4.58-2.62c-0.25-0.14-0.55-0.15-0.8-0.02l-6.01,3.11l-7.32-4.74c-0.27-0.17-0.61-0.18-0.88-0.01l-7.07,4.24V6.67
                    h26.67V17.73z M39.58,14.17c1.61,0,2.92-1.31,2.92-2.92c0-1.61-1.31-2.92-2.92-2.92c-1.61,0-2.92,1.31-2.92,2.92
                    C36.67,12.86,37.97,14.17,39.58,14.17z M39.58,10c0.69,0,1.25,0.56,1.25,1.25c0,0.69-0.56,1.25-1.25,1.25s-1.25-0.56-1.25-1.25
                    C38.33,10.56,38.89,10,39.58,10z M8.33,27.5v10c0,0.46,0.37,0.83,0.83,0.83H30c0.46,0,0.83-0.37,0.83-0.83v-10
                    c0-0.46-0.37-0.83-0.83-0.83H9.17C8.71,26.67,8.33,27.04,8.33,27.5z M10,28.33h19.17v8.33H10V28.33z M15,30h-3.33v1.67H15V30z
                    M24.17,30h-7.5v1.67h7.5V30z M25.83,31.67h1.67V30h-1.67V31.67z M11.67,35h10v-1.67h-10V35z M23.33,35h4.17v-1.67h-4.17V35z
                    M8.33,3.33V5H10V3.33H8.33z M11.67,3.33V5h1.67V3.33H11.67z M15,3.33V5h1.67V3.33H15z M8.33,8.33h10V6.67h-10V8.33z M49.17,30
                    H33.33c-0.46,0-0.83,0.37-0.83,0.83v10c0,0.46,0.37,0.83,0.83,0.83h15.83c0.46,0,0.83-0.37,0.83-0.83v-10
                    C50,30.37,49.63,30,49.17,30z M48.33,40H34.17v-8.33h14.17V40z M37.5,33.33h-1.67V35h1.67V33.33z M37.5,36.67h-1.67v1.67h1.67
                    V36.67z M46.67,33.33h-7.5V35h7.5V33.33z M46.67,36.67h-7.5v1.67h7.5V36.67z M43.33,25v3.33H45V25H43.33z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Custom Design',
					text: 'Specifically design app interfaces and workflows that will deliver unique experiences to your customers. Brand presets, theme-based interfaces and personalization components can also be integrated into the design.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M46.67,6.67v1.67c0.92,0,1.67,0.75,1.67,1.67v25H1.67V10c0-0.92,0.75-1.67,1.67-1.67V6.67C1.49,6.67,0,8.16,0,10v28.33
                    c0,1.84,1.49,3.33,3.33,3.33h16.38l-2,6.67h-1.88V50h18.33v-1.67h-1.88l-2-6.67h16.38c1.84,0,3.33-1.49,3.33-3.33V10
                    C50,8.16,48.51,6.67,46.67,6.67z M30.55,48.33H19.45l2-6.67h7.09L30.55,48.33z M48.33,38.33c0,0.92-0.75,1.67-1.67,1.67H3.33
                    c-0.92,0-1.67-0.75-1.67-1.67v-1.67h46.67V38.33z M8.33,33.33h33.33c1.84,0,3.33-1.49,3.33-3.33V3.33C45,1.49,43.51,0,41.67,0
                    H8.33C6.49,0,5,1.49,5,3.33V30C5,31.84,6.49,33.33,8.33,33.33z M6.67,3.33c0-0.92,0.75-1.67,1.67-1.67h33.33
                    c0.92,0,1.67,0.75,1.67,1.67V30c0,0.92-0.75,1.67-1.67,1.67H8.33c-0.92,0-1.67-0.75-1.67-1.67V3.33z M41.67,6.67H8.33v1.67h33.33
                    V6.67z M15.83,10.83h-7.5v1.67h7.5V10.83z M14.17,14.17H8.33v1.67h5.83V14.17z M13.33,17.5h-5v1.67h5V17.5z M13.33,20.83h-5v1.67
                    h5V20.83z M13.33,24.17h-5v1.67h5V24.17z M15.83,27.5h-7.5v1.67h7.5V27.5z M34.17,12.5h7.5v-1.67h-7.5V12.5z M35.83,15.83h5.83
                    v-1.67h-5.83V15.83z M36.67,19.17h5V17.5h-5V19.17z M36.67,22.5h5v-1.67h-5V22.5z M36.67,25.83h5v-1.67h-5V25.83z M34.17,29.17
                    h7.5V27.5h-7.5V29.17z M10,3.33H8.33V5H10V3.33z M13.33,3.33h-1.67V5h1.67V3.33z M16.67,3.33H15V5h1.67V3.33z M31.76,23.23
                    c0.11-0.24,0.21-0.48,0.3-0.73h2.1c0.46,0,0.83-0.37,0.83-0.83v-3.33c0-0.46-0.37-0.83-0.83-0.83h-2.1
                    c-0.09-0.25-0.19-0.49-0.3-0.73l1.49-1.49c0.33-0.33,0.33-0.85,0-1.18l-2.36-2.36c-0.33-0.33-0.85-0.33-1.18,0l-1.49,1.49
                    c-0.24-0.11-0.48-0.21-0.73-0.3v-2.1c0-0.46-0.37-0.83-0.83-0.83h-3.33c-0.46,0-0.83,0.37-0.83,0.83v2.1
                    c-0.25,0.09-0.49,0.19-0.73,0.3l-1.49-1.49c-0.33-0.33-0.85-0.33-1.18,0l-2.36,2.36c-0.33,0.33-0.33,0.85,0,1.18l1.49,1.49
                    c-0.11,0.24-0.21,0.48-0.3,0.73h-2.1c-0.46,0-0.83,0.37-0.83,0.83v3.33c0,0.46,0.37,0.83,0.83,0.83h2.1
                    c0.09,0.25,0.19,0.49,0.3,0.73l-1.49,1.49c-0.33,0.33-0.33,0.85,0,1.18l2.36,2.36c0.33,0.32,0.85,0.32,1.18,0l1.49-1.49
                    c0.24,0.11,0.48,0.21,0.73,0.3v2.1c0,0.46,0.37,0.83,0.83,0.83h3.33c0.46,0,0.83-0.37,0.83-0.83v-2.1
                    c0.25-0.09,0.49-0.19,0.73-0.3l1.49,1.49c0.33,0.32,0.85,0.32,1.18,0l2.36-2.36c0.33-0.33,0.33-0.85,0-1.18L31.76,23.23z
                    M30.3,26.48l-1.33-1.33c-0.27-0.27-0.69-0.32-1.01-0.13c-0.47,0.28-0.97,0.48-1.5,0.62c-0.37,0.1-0.62,0.43-0.62,0.81v1.89h-1.66
                    v-1.89c0-0.38-0.26-0.71-0.63-0.81c-0.53-0.14-1.03-0.34-1.5-0.62c-0.33-0.19-0.74-0.14-1.01,0.13l-1.33,1.33l-1.18-1.18
                    l1.33-1.33c0.27-0.27,0.32-0.69,0.13-1.01c-0.28-0.47-0.48-0.97-0.62-1.5c-0.09-0.37-0.43-0.62-0.81-0.63h-1.88v-1.67h1.88
                    c0.38,0,0.71-0.26,0.81-0.63c0.14-0.53,0.34-1.03,0.62-1.5c0.19-0.33,0.14-0.74-0.13-1.01l-1.33-1.33l1.18-1.18l1.33,1.33
                    c0.27,0.27,0.69,0.32,1.01,0.13c0.47-0.28,0.97-0.49,1.5-0.62c0.37-0.1,0.62-0.43,0.63-0.81v-1.88h1.67v1.89
                    c0,0.38,0.26,0.71,0.63,0.81c0.53,0.14,1.03,0.34,1.5,0.62c0.33,0.19,0.74,0.14,1.01-0.13l1.33-1.33l1.18,1.18l-1.33,1.33
                    c-0.27,0.27-0.32,0.69-0.13,1.01c0.28,0.47,0.48,0.97,0.62,1.5c0.1,0.37,0.43,0.62,0.81,0.62h1.88v1.67h-1.88
                    c-0.38,0-0.71,0.26-0.81,0.63c-0.14,0.53-0.35,1.03-0.62,1.5c-0.19,0.33-0.14,0.74,0.13,1.01l1.33,1.33L30.3,26.48z M25,15.83
                    c-2.3,0-4.17,1.87-4.17,4.17c0,2.3,1.87,4.17,4.17,4.17c2.3,0,4.17-1.87,4.17-4.17C29.16,17.7,27.3,15.84,25,15.83z M25,22.5
                    c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5c1.38,0,2.5,1.12,2.5,2.5C27.5,21.38,26.38,22.5,25,22.5z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Custom Development',
					text: 'End-to-end custom development that puts your priorities ahead of everything else.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M50,37.45V4.16C50,1.87,48.13,0,45.84,0H4.23C1.93,0,0.07,1.87,0.07,4.16v33.29c0,2.29,1.87,4.16,4.16,4.16h11.54
                    l-2.77,3.33H5.06c-0.19,0-0.37,0.06-0.52,0.18l-4.16,3.33C0.1,48.68,0,49.05,0.11,49.38c0.12,0.33,0.43,0.56,0.78,0.56h48.27
                    c0.35,0,0.67-0.22,0.78-0.56c0.12-0.33,0.01-0.7-0.26-0.92l-4.16-3.33c-0.15-0.12-0.33-0.18-0.52-0.18h-7.93l-2.77-3.33h11.54
                    C48.13,41.61,50,39.75,50,37.45z M44.71,46.61l2.08,1.67H3.27l2.08-1.67h8.03h23.3H44.71z M34.91,44.94H15.16l2.77-3.33h14.2
                    L34.91,44.94z M32.52,39.95H17.54H4.23c-1.38,0-2.5-1.12-2.5-2.5V4.16c0-1.38,1.12-2.5,2.5-2.5h41.61c1.38,0,2.5,1.12,2.5,2.5
                    v33.29c0,1.38-1.12,2.5-2.5,2.5H32.52z M46.67,34.12V4.16c0-0.46-0.37-0.83-0.83-0.83H4.23C3.77,3.33,3.4,3.7,3.4,4.16v29.96
                    c0,0.46,0.37,0.83,0.83,0.83h41.61C46.3,34.96,46.67,34.58,46.67,34.12z M45.01,33.29H5.06V4.99h39.95V33.29z M20.87,38.28h8.32
                    v-1.66h-8.32V38.28z M22.54,10.82V9.15c-2.75,0-4.99,2.24-4.99,4.99h1.66C19.21,12.31,20.7,10.82,22.54,10.82z M14.8,18.55
                    c-0.33-0.33-0.85-0.33-1.18,0l-6.66,6.66c-1.24,1.24-1.24,3.26,0,4.51c0.62,0.62,1.44,0.93,2.25,0.93c0.82,0,1.63-0.31,2.25-0.93
                    l6.66-6.66c0.33-0.33,0.33-0.85,0-1.18l-0.24-0.24l0.97-0.97c1.09,0.62,2.34,0.97,3.68,0.97c4.13,0,7.49-3.36,7.49-7.49
                    c0-0.88-0.16-1.71-0.44-2.5h12.92c0.46,0,0.83-0.37,0.83-0.83V7.49c0-0.46-0.37-0.83-0.83-0.83H22.54H7.56
                    c-0.46,0-0.83,0.37-0.83,0.83v3.33c0,0.46,0.37,0.83,0.83,0.83h7.93c-0.28,0.78-0.44,1.62-0.44,2.5c0,1.34,0.36,2.59,0.97,3.68
                    l-0.97,0.97L14.8,18.55z M16.71,20.46l-0.49-0.49l0.78-0.78c0.04,0.04,0.08,0.08,0.12,0.12c0.08,0.08,0.15,0.16,0.24,0.24
                    c0.04,0.04,0.08,0.09,0.13,0.13L16.71,20.46z M41.68,8.32v1.66H28.76c-0.2-0.3-0.42-0.57-0.66-0.84c-0.03-0.03-0.06-0.07-0.09-0.1
                    c-0.24-0.26-0.5-0.5-0.77-0.73H41.68z M17.95,10.57c0.3-0.39,0.66-0.74,1.04-1.04c0.01-0.01,0.03-0.02,0.04-0.03
                    c0.19-0.14,0.39-0.28,0.59-0.4c0,0,0.01,0,0.01-0.01c0.42-0.24,0.87-0.43,1.34-0.56c0.04-0.01,0.08-0.02,0.11-0.03
                    c0.19-0.05,0.38-0.09,0.57-0.12c0.05-0.01,0.11-0.02,0.16-0.03c0.23-0.03,0.47-0.05,0.71-0.05c0.24,0,0.47,0.02,0.71,0.05
                    c0.05,0.01,0.11,0.02,0.16,0.03c0.19,0.03,0.39,0.07,0.57,0.12c0.04,0.01,0.08,0.02,0.11,0.03c0.47,0.13,0.92,0.32,1.34,0.56
                    c0,0,0.01,0.01,0.01,0.01c0.21,0.12,0.4,0.25,0.6,0.4c0.01,0.01,0.03,0.02,0.04,0.03c0.39,0.3,0.74,0.65,1.04,1.04
                    c0.01,0.02,0.03,0.04,0.04,0.06c0.14,0.18,0.27,0.38,0.39,0.58c0.51,0.86,0.8,1.87,0.8,2.94c0,3.21-2.61,5.83-5.83,5.83
                    c-1.25,0-2.4-0.4-3.35-1.07c-0.55-0.38-1.02-0.86-1.4-1.4c-0.67-0.95-1.07-2.1-1.07-3.35c0-1.07,0.3-2.07,0.8-2.94
                    c0.12-0.2,0.25-0.4,0.39-0.58C17.92,10.61,17.93,10.59,17.95,10.57z M8.39,9.99V8.32h9.45c-0.28,0.22-0.53,0.47-0.77,0.73
                    c-0.03,0.03-0.06,0.07-0.09,0.1c-0.24,0.26-0.46,0.54-0.66,0.84H8.39z M16.12,22.23l0.24,0.24l-6.07,6.07
                    c-0.59,0.59-1.56,0.59-2.15,0c-0.59-0.59-0.59-1.56,0-2.15l6.07-6.07l0.24,0.24L16.12,22.23z M42.51,14.98h-4.99v1.66h2.98
                    l-4.81,4.81l-2.79-1.4c-0.3-0.15-0.67-0.1-0.92,0.12l-7.01,6.23L23,25.11c-0.31-0.21-0.72-0.18-1,0.06l-5.83,4.99l1.08,1.27
                    l5.35-4.58l1.97,1.32c0.32,0.21,0.73,0.18,1.01-0.07l7.07-6.28l2.83,1.41c0.32,0.16,0.71,0.1,0.96-0.16l5.24-5.24v2.98h1.66v-4.99
                    C43.34,15.35,42.97,14.98,42.51,14.98z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Scalable and Robust',
					text: 'A robust backend and a scalable database will ensure that your app will keep up with the pace of your business growth.'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <path d="M26.77,41.68c-2.07,0-3.57-0.3-4-0.4c-1.61-0.01-3.46-0.83-5.6-1.77c-4.01-1.76-8.55-3.76-12.02-0.4
                c-0.21,0.21-0.51,0.29-0.8,0.23c-0.29-0.06-0.53-0.27-0.64-0.54l-3.55-8.91c-0.16-0.4-0.02-0.86,0.34-1.1
                c0.96-0.63,9.46-6.21,12.37-6.66c4.07-0.64,10.7,2.94,12.63,4.05c1.96,1.12,3.78,1.32,5.54,1.52c1.98,0.22,3.86,0.43,5.56,1.9
                c0.12,0.11,0.23,0.22,0.33,0.32c2.18-2.03,7.7-6.87,10.39-6.5c0.58,0.08,1.06,0.37,1.37,0.85c0.42,0.49,0.85,1.61,1.28,3.33
                c0.09,0.37-0.05,0.76-0.37,0.97c-0.07,0.05-6.59,4.5-9.15,7.29C35.98,40.7,30.47,41.67,26.77,41.68z M22.87,39.47
                c0.07,0,0.14,0.01,0.21,0.03c0.09,0.02,9.49,2.26,16.07-4.88c2.29-2.49,7.29-6.04,8.94-7.19c-0.4-1.45-0.68-1.89-0.73-1.97
                c-0.05-0.05-0.1-0.11-0.13-0.18c-0.02-0.04-0.04-0.06-0.14-0.08c-1.64-0.24-6.6,3.76-9.61,6.66c-0.06,0.05-0.12,0.1-0.18,0.14
                c-0.01,0.02-0.01,0.03-0.02,0.05c-1.21,2.51-8.62,3.81-10.28,3.46c-0.96-0.2-1.81-0.63-2.63-1.05c-0.96-0.48-1.86-0.94-2.69-0.86
                c-0.48,0.05-0.92-0.32-0.97-0.82c-0.05-0.5,0.32-0.94,0.8-0.98c1.33-0.12,2.5,0.47,3.64,1.04c0.75,0.38,1.47,0.74,2.2,0.89
                c1.13,0.24,5.89-0.65,7.8-1.98c0.41-0.28,0.53-0.48,0.54-0.53l0,0c0,0-0.04-0.1-0.23-0.26c-1.29-1.11-2.75-1.28-4.6-1.48
                c-1.85-0.21-3.95-0.44-6.22-1.74c-4.68-2.69-9.18-4.19-11.48-3.83c-1.48,0.23-5.27,2.39-8.31,4.26l3.15,7.45
                c3.34-0.66,6.88,0.9,9.9,2.23c1.92,0.84,3.71,1.66,4.98,1.62C22.86,39.47,22.86,39.47,22.87,39.47z M2.09,29.9l2.81,7.07
                c0.45-0.33,0.91-0.59,1.37-0.81L3.3,29.12C2.86,29.4,2.45,29.67,2.09,29.9z M47.44,25.54C47.44,25.54,47.45,25.54,47.44,25.54
                C47.45,25.54,47.44,25.54,47.44,25.54z M47.44,25.54C47.44,25.54,47.44,25.54,47.44,25.54C47.44,25.54,47.44,25.54,47.44,25.54z
                M47.44,25.54C47.44,25.54,47.44,25.54,47.44,25.54C47.44,25.54,47.44,25.54,47.44,25.54z M47.44,25.53
                C47.44,25.54,47.44,25.54,47.44,25.53C47.44,25.54,47.44,25.54,47.44,25.53z M18.67,33.51c-0.03,0-0.05,0-0.08,0
                c-0.01,0-1.08-0.1-2.53-0.01c-0.5,0.02-0.91-0.35-0.94-0.85c-0.03-0.5,0.34-0.93,0.84-0.96c1.59-0.1,2.75,0.01,2.8,0.02
                c0.49,0.05,0.85,0.49,0.8,0.98C19.51,33.16,19.13,33.51,18.67,33.51z M30.93,25.39c-4.72,0-8.56-3.9-8.56-8.69
                c0-4.79,3.84-8.7,8.56-8.7c4.72,0,8.56,3.9,8.56,8.7C39.5,21.49,35.66,25.39,30.93,25.39z M30.93,9.81c-3.74,0-6.78,3.09-6.78,6.89
                c0,3.8,3.04,6.89,6.78,6.89c3.74,0,6.78-3.09,6.78-6.89C37.72,12.9,34.67,9.81,30.93,9.81z M30.84,22.95c-0.49,0-0.89-0.4-0.89-0.9
                v-0.63c-0.81-0.17-1.44-0.49-1.56-0.55c-0.44-0.23-0.61-0.78-0.38-1.22c0.23-0.44,0.76-0.62,1.2-0.39c0.27,0.14,0.97,0.43,1.6,0.45
                c0.03,0,0.06,0,0.09,0c0.19,0,0.37-0.03,0.52-0.1c0.16-0.07,0.39-0.23,0.53-0.76c0.17-0.66,0.07-0.86-1.4-1.45
                c-1.08-0.43-2.57-1.02-2.62-2.72c-0.04-1.22,0.49-1.86,0.94-2.19c0.33-0.23,0.71-0.37,1.1-0.43v-0.58c0-0.5,0.4-0.9,0.89-0.9
                c0,0,0,0,0,0c0.49,0,0.89,0.41,0.89,0.9v0.65c0.66,0.13,1.2,0.34,1.4,0.41c0.46,0.18,0.68,0.71,0.5,1.17
                c-0.18,0.46-0.7,0.69-1.16,0.5c-0.79-0.32-2.14-0.58-2.6-0.26c-0.05,0.04-0.21,0.15-0.19,0.65c0.02,0.44,0.4,0.67,1.5,1.1
                c1.15,0.46,3.09,1.23,2.48,3.59c-0.31,1.18-1,1.71-1.53,1.95c-0.13,0.06-0.27,0.11-0.4,0.14v0.64
                C31.73,22.54,31.33,22.95,30.84,22.95z"></path>
              </svg>`,

					title: 'Affordable Solution',
					text: 'We build world-class social media apps that look and feel great but do not cost as much as they look like. We will be happy to give a personalized quote.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M78.4,66.5l1.3-8.5c0-0.1,0-0.2,0-0.3c0-2.6-2-4.7-4.6-4.7H63.9c-3-1.1-4.6-4.3-3.9-7.4c4.7-2.9,7.9-7.7,8.8-13.2
                    c1.5-2.9,7.1-14.9,0.7-22.4C69.1,9.4,61.5,0.4,51,0c-6.1-0.2-11.9,2.4-17.2,8c-2.9,2-5,5-5.8,8.4c-0.8,3.9,0.2,8.1,3.1,12.6
                    c0,6.8,3.5,13.1,9.1,16.6c0.8,3.2-0.9,6.4-3.9,7.5H24.8c-2.5,0-4.5,2.1-4.6,4.7c0,0.1,0,0.2,0,0.3l1.3,8.7
                    c-3.4,4.9-5.3,10.8-5.3,16.9v11.5c0,2.7,2.1,4.9,4.8,4.9H79c2.6,0,4.8-2.2,4.8-4.9V83.6C83.8,76.7,82,71.2,78.4,66.5z M34,27.6
                    c-2.6-3.7-3.6-7.3-2.9-10.4c0.7-2.7,2.3-5,4.5-6.5c0.1-0.1,0.2-0.2,0.3-0.3c4.7-4.9,9.7-7.3,14.8-7.1C60.1,3.6,67,12,67.1,12.1
                    c4.6,5.3,1,14.4-0.6,17.8c-0.1-0.2-0.2-0.3-0.4-0.5c0-0.1-0.1-0.1-0.1-0.2l-0.2-0.5c0-0.1-0.1-0.2-0.1-0.3
                    c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.4l-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4C65,26.4,65,26.2,64.9,26
                    c0-0.2-0.1-0.3-0.1-0.5l-0.1-0.8l-0.1-0.4c-0.1-0.4-0.1-0.9-0.1-1.4c-0.1-1.8-0.9-3.5-2.3-4.6c-0.2-0.2-0.5-0.4-0.8-0.5l-0.2-0.1
                    c-0.3-0.1-0.5-0.3-0.8-0.4h-0.1c-0.3-0.1-0.5-0.2-0.8-0.2h-0.1c-0.3-0.1-0.6-0.1-0.9-0.1h-0.2c-0.3,0-0.6,0-0.9,0.1
                    c-7.6,1.3-19.9,0.6-20,0.5c-0.9-0.1-1.6,0.6-1.7,1.5c0,0.1,0,0.2,0,0.2c0.3,3-0.1,6-1.3,8.7L34,27.6z M35.9,36.6
                    c-0.7-1.2-1.1-2.6-1.5-3.9l0.2,0c0.2,0,0.3-0.1,0.5-0.1h0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,3.4-3.8,3.3-11.2
                    c3.8,0.2,12.8,0.4,19-0.7c0.8-0.1,1.6,0.1,2.2,0.6c0.6,0.5,1,1.3,1.1,2.2v1.2l0.1,0.6v0.3c0,0.1,0.1,0.4,0.1,0.6
                    c0,0.1,0,0.2,0.1,0.3l0.1,0.6c0,0.1,0,0.2,0.1,0.2c0.1,0.3,0.1,0.6,0.2,0.9v0.1c0.1,0.3,0.2,0.5,0.2,0.8c0,0.1,0.1,0.2,0.1,0.2
                    c0,0.1,0.1,0.4,0.2,0.5l0.1,0.3l0.2,0.5c0,0.1,0.1,0.2,0.1,0.2l0.3,0.5l0.1,0.2c0.1,0.2,0.3,0.4,0.4,0.6l0.1,0.1
                    c0.1,0.2,0.2,0.3,0.4,0.5l0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3l0.2,0.1l0.1,0.1
                    c-1.1,4.1-3.7,7.6-7.3,9.7c-0.2,0.1-0.3,0.2-0.5,0.3C49.7,47.6,40.1,44.6,35.9,36.6z M58.7,53H41.6c1.3-1.7,1.9-3.7,1.9-5.8
                    c4.2,1.6,8.9,1.6,13.2,0C56.7,49.3,57.4,51.4,58.7,53z M24.8,56.3h50.3c0.7,0,1.3,0.6,1.4,1.3L71.8,89c0,0.1,0,0.2,0,0.2
                    c0,0.8-0.6,1.4-1.3,1.4h-41c-0.7,0-1.4-0.6-1.4-1.4v0c0-0.1,0-0.2,0-0.2l-4.7-31.3C23.5,56.9,24.1,56.4,24.8,56.3z M21.3,93.6v3.1
                    h-0.3c-0.9,0-1.6-0.7-1.6-1.6v0h0V83.6c0-4.1,1-8.2,2.8-11.9l2.6,17.7c0,0.4,0.1,0.8,0.2,1.3h-0.8C22.7,90.6,21.3,91.9,21.3,93.6z
                    M74.5,96.7h-50v-2.9h50V96.7z M80.5,95.1c0,0.9-0.7,1.6-1.6,1.6h-1.2v-3.1c0-1.7-1.3-3-2.9-3c0.1-0.4,0.2-0.8,0.2-1.3l2.7-18.1
                    c2,3.8,3,8,2.9,12.3L80.5,95.1z M47.3,26.3c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6c0,1.5,1.2,2.7,2.6,2.7S47.3,27.8,47.3,26.3z
                    M56,29c1.4,0,2.6-1.2,2.6-2.7c0-1.5-1.2-2.6-2.6-2.6s-2.6,1.2-2.6,2.6C53.4,27.8,54.5,29,56,29z M56,36.2c0-0.9-0.7-1.6-1.6-1.6
                    c-0.9,0-1.6,0.7-1.6,1.6c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6
                    c0,3.4,2.7,6.2,6.1,6.2C53.3,42.4,56,39.6,56,36.2z M42.5,83.1c0.3,0.3,0.7,0.5,1.1,0.5c0.9,0,1.6-0.8,1.6-1.7
                    c0-0.4-0.2-0.8-0.5-1.1l-7.1-7.4l7.1-7.3c0.6-0.6,0.7-1.7,0.1-2.3c-0.6-0.7-1.6-0.7-2.3-0.1c0,0-0.1,0.1-0.1,0.1l-8.2,8.4
                    c-0.6,0.6-0.6,1.7,0,2.3L42.5,83.1z M55.7,66.1l7.1,7.3l-7.2,7.4c-0.6,0.6-0.6,1.7,0,2.3c0.3,0.3,0.7,0.5,1.1,0.5
                    c0.4,0,0.8-0.2,1.1-0.5l8.3-8.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.8-0.5-1.2L58,63.8c-0.6-0.6-1.7-0.6-2.3,0.1
                    C55.1,64.5,55.1,65.5,55.7,66.1L55.7,66.1z M46.1,85.6c0.2,0.1,0.3,0.1,0.5,0.1c0.7,0,1.3-0.4,1.5-1.1l7-21.2
                    c0.3-0.9-0.1-1.8-1-2.1c-0.8-0.3-1.8,0.1-2,1l-7,21.2C44.9,84.4,45.3,85.3,46.1,85.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Experienced Developers',
					text: 'Our developers are well-versed in several language frameworks and platforms that will give your social media app the much needed scalability, versatility and flexibility.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>