<template>
	<section class="padding-60 bg-light">
		<div class="container">
			<div class="title">
				<span>What's Going on at GenixBit Labs </span>
				<h2 class="fxt-blog-pres">Fresh off the Press</h2>
			</div>
			<div class="blog-slider p-relative o-hidden slick-initialized slick-slider">
				<div class="slick-list draggable">
					<div
						class="slick-track d-flex flex-wrap m-auto justify-content-center"
						style="opacity: 1; transform: translate3d(0px, 0px, 0px)"
					>
						<div
							class="slick-slide slick-current slick-active"
							data-slick-index="0"
							aria-hidden="false"
							style="min-width: 350px; max-width: 350px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="blog-post format-standard-image">
										<div class="blog-post_wrapper">
											<div class="blog-post_media">
												<div class="blog-post_media_part">
													<a
														href="#"
														class="blog-post_feature-link"
														title="eCommerce Website Cost in the UK [Cost Breakdown 2022]"
														tabindex="0"
														><img
															class="lazy"
															alt="eCommerce Website Cost in the UK [Cost Breakdown 2022]"
															src="../../assets/images/How_Much_Does_an_eCommerce_Website_Cost.png"
															style=""
															width="370"
															height="193"
													/></a>
												</div>
											</div>
											<div class="blog-post_content">
												<h3 class="blog-post_title">
													<a href="#" tabindex="0"
														>eCommerce Website Cost in the UK [Cost
														Breakdown 2022]</a
													>
												</h3>
												<div class="blog-post_text">
													<p>
														The United Kingdom (UK) has the most
														sophisticated e-commerce market in Europe.
														According to the most…
													</p>
												</div>
												<div class="blog-post_meta-wrap">
													<div class="meta-wrapper">
														<span
															class="author_post"
															style="min-width: 150px"
														>
															<img
																class="avatar lazy"
																alt="Oliver-Author"
																src="../../assets/images/genixbit.png"
																style=""
																width="30"
																height="30"
															/>
															<a href="#" tabindex="0">
																Aditya T.</a
															>
														</span>
														<span class="date_post"
															>February 23, 2022</span
														>
													</div>
													<div class="blog-post_info-wrap">
														<div class="share_post-container">
															<a
																href="#"
																class="fas fa-arrow-right"
																title="Read More"
																tabindex="0"
															></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="1"
							aria-hidden="false"
							style="min-width: 350px; max-width: 350px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="blog-post format-standard-image">
										<div class="blog-post_wrapper">
											<div class="blog-post_media">
												<div class="blog-post_media_part">
													<a
														href="#"
														class="blog-post_feature-link"
														title="App Development Cost in 2022 by App Types (with 5 Examples)"
														tabindex="0"
														><img
															class="lazy"
															alt="App Development Cost in 2022 by App Types (with 5 Examples)"
															src="../../assets/images/app-development-cost.png"
															style=""
															width="370"
															height="193"
													/></a>
												</div>
											</div>
											<div class="blog-post_content">
												<h3 class="blog-post_title">
													<a href="#" tabindex="0"
														>App Development Cost in 2022 by App Types
														(with 5 Examples)</a
													>
												</h3>
												<div class="blog-post_text">
													<p>
														Introduction There are many new trends in
														mobile app development, especially with the
														rising demands…
													</p>
												</div>
												<div class="blog-post_meta-wrap">
													<div class="meta-wrapper">
														<span
															class="author_post"
															style="min-width: 170px"
														>
															<img
																class="avatar lazy"
																alt="Dhaval-Author"
																src="../../assets/images/genixbit.png"
																style=""
																width="30"
																height="30"
															/>
															<a href="#" tabindex="0">
																Aditya T.</a
															>
														</span>
														<span class="date_post"
															>December 14, 2021</span
														>
													</div>
													<div class="blog-post_info-wrap">
														<div class="share_post-container">
															<a
																href="#"
																class="fas fa-arrow-right"
																title="Read More"
																tabindex="0"
															></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							class="slick-slide slick-active"
							data-slick-index="2"
							aria-hidden="false"
							style="min-width: 350px; max-width: 350px"
						>
							<div>
								<div class="item" style="width: 100%; display: inline-block">
									<div class="blog-post format-standard-image">
										<div class="blog-post_wrapper">
											<div class="blog-post_media">
												<div class="blog-post_media_part">
													<a
														href="#"
														class="blog-post_feature-link"
														title="How Much Does It Cost To Make A Website In 2022? (Cost Estimation)"
														tabindex="0"
														><img
															class="lazy"
															alt="How Much Does It Cost To Make A Website In 2022? (Cost Estimation)"
															src="../../assets/images/Cost-of-Website-Development.png"
															style=""
															width="370"
															height="193"
													/></a>
												</div>
											</div>
											<div class="blog-post_content">
												<h3 class="blog-post_title">
													<a href="#" tabindex="0"
														>How Much Does It Cost To Make A Website In
														2022? (Cost Estimation)</a
													>
												</h3>
												<div class="blog-post_text">
													<p>
														Introduction to Website Development A modern
														business cannot function in oblivion without
														a website.…
													</p>
												</div>
												<div class="blog-post_meta-wrap">
													<div class="meta-wrapper">
														<span
															class="author_post"
															style="min-width: 170px"
														>
															<img
																class="avatar lazy"
																alt="Dhaval-Author"
																src="../../assets/images/genixbit.png"
																style=""
																width="30"
																height="30"
															/>
															<a href="#" tabindex="0">
																Aditya T.</a
															>
														</span>
														<span class="date_post"
															>October 13, 2021</span
														>
													</div>
													<div class="blog-post_info-wrap">
														<div class="share_post-container">
															<a
																href="#"
																class="fas fa-arrow-right"
																title="Read More"
																tabindex="0"
															></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.blog-slider {
	padding: 0 0 20px;
}
.blog-slider {
	margin-left: -8px;
	margin-right: -8px;
}
.blog-slider .item {
	padding: 0 8px;
}
.blog-post {
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	overflow: hidden;
	border-radius: 5px;
	background: #fff;
	margin-bottom: 30px;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.blog-slider .item .blog-post {
	margin-bottom: 10px;
}
.blog-post_media {
	position: relative;
	margin-bottom: 28px;
}
.blog-post_media_part {
	position: relative;
}
.blog-post_feature-link::before {
	opacity: 0;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	z-index: 2;
	background-color: rgba(40, 40, 40, 0.5);
	-webkit-transition: opacity 0.3s;
	-o-transition: opacity 0.3s;
	-moz-transition: opacity 0.3s;
	transition: opacity 0.3s;
	width: 100%;
	height: 100%;
}
.slick-slide img {
	display: block;
}
.blog-slider .item .blog-post_content {
	min-height: 220px;
}
.blog-post_content {
	padding: 0 20px 18px 20px;
}
.blog-post_title {
	font-size: 22px;
	line-height: 36px;
	height: 73px;
	overflow: hidden;
}
.blog-post_text {
	height: 100px;
	overflow: hidden;
}
.blog-post_meta-wrap {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.meta-wrapper {
	display: flex;
	align-items: center;
}
.meta-wrapper > span {
	margin-right: 5px;
}
.blog-post_title a {
	font-size: 20px;
	line-height: 28px;
	color: #202122;
	display: -webkit-box;
	max-width: 100%;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.blog-post_title:hover a {
	color: #1977cc;
}
.date_post {
	color: #9a9a9a;
	margin-right: 0;
	font-size: 14px;
	min-width: 150px;
}

.author_post img {
	margin-right: 6px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	display: inline-block;
}
.author_post a {
	color: #1977cc;
	position: relative;
}

.blog-post_media:hover .blog-post_feature-link::before {
	opacity: 1;
}
@media (max-width: 576px) {
	.fxt-blog-pres {
		font-size:20px !important;
		font-weight:bold !important;
	}
}
</style>
