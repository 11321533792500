<template>
	<div>
		<Banner></Banner>

		<TechTab></TechTab>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../components/Techno/Banner';
import TechTab from '../components/Techno/TechTab';
import Const from '../components/AssetComponents/Const';

export default {
	components: {
		Banner,
		TechTab,
		Const
	}
};
</script>

<style></style>
