<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/datingapp-main.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Tinder like Dating App Development Solutions
					</h2>
					<p>
						A decade ago, meeting strangers to find partners for life would have been considered
						absurd. Today. With Tinder, Bumble, Happn and many other dating apps, matchmaking now is
						best done through a mobile app. Turns out there is a market potential for dating apps
						that are as huge as the instant messaging and on demand services market itself.
					</p>
					<p>
						Do you have a plan for developing a dating app like Tinder that users will flock to find
						their better halves? Want to build such an app with the most popular features and some
						more that will make a difference? Well, you have reached the right place (or web page).
					</p>
					<p>
						When it comes to building a tinder like dating app development solutions, GenixBit Labs is
						a trusted name. Be it in the UK or the rest of the world, we command a reputation for
						being one of the best dating app development company that you can count on.
					</p>
					<p>
						We have also been recognized as top mobile app development company for several
						categories. Talk to our experts to find out how we can help you with dating app
						development.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>