<template>
	<section class="padding-60 bg-light testimonial-section">
		<div class="container">
			<div class="title fxt-title">
				<span>What Our Clients Say</span>
				<h2 class="fxt-rev-dev">This is What We Live For</h2>
				<p>
					Our web and mobile app development expertise have helped several organizations turn
					critical challenges into a competitive advantage. But don’t take our word for it! Hear
					straight from our clients what they think and feel about our services.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6">
					<div class="testimonials_left" style="width: unset !important">
						<ul class="testimonials_circle">
							<li v-for="(review, index) in reviews" :key="index" @click="setActiveIndex(index)">
								<a href="javascript:;" :title="review.reviewOwner" class="test-img">
									<img
										class="lazy"
										:src="review.reviewOwnerImageLink"
										style=""
										width="71"
										height="71"
									/> </a
								><i class="fas fa-quote-left"></i>
							</li>
						</ul>
						<div class="testimonials_privew">
							<div>
								<img
									id="testimonials_privew"
									class="lazy"
									alt="app development companies"
									:src="reviews[index_].reviewOwnerImageLink"
									style=""
								/>
							</div>
							<i class="fas fa-quote-left"></i>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 pr-0">
					<div class="testimonials_text">
						<VueSlickCarousel
							ref="reviewsCarousel"
							@afterChange="setIndex"
							:autoplay="true"
							class="testimonials_text_slider"
							style="max-width: 500px; margin: auto"
						>
							<div
								class="testimonial-text-section"
								v-for="(review, index) in reviews"
								:key="index"
								style="width: 100%; display: inline-block"
							>
								<div class="testimonials_text_box" style="min-with: 250px; max-width: 400px">
									<div class="testimonials-arrow"></div>
									<i class="fas fa-quote-left"></i>
									<p>{{ review.reviewText }}</p>
									<span
										><strong>{{ review.reviewOwner }}</strong
										>, {{ review.reviewOwnerRole }}</span
									>
								</div>
							</div>
						</VueSlickCarousel>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
	components: {VueSlickCarousel},
	data: () => {
		return {
			index_: 0,
			reviews: [
				{
					reviewText: `GenixBit Labs has helped us design and build our company's app, for both iOS and Android. The app we needed, demanded very unique and challenging applications/features. With every request, they were able to design and build a very suitable solution.`,
					reviewOwner: 'Nick Konechny',
					reviewOwnerRole: 'Managing Director, AK microblading',
					reviewOwnerImageLink: '../../assets/images/nick-konechny.jpg'
				},
				{
					reviewText: `GenixBit Labs has been awesome to work with! The guys at GenixBit Labs are always quick to deliver, and to high standards at that!`,
					reviewOwner: 'Zia Mahmood',
					reviewOwnerRole: 'Founder Guest Me',
					reviewOwnerImageLink: '../../assets/images/zia-mahmood.jpg'
				},
				{
					reviewText: `The team was helpful and supportive throughout the process, walking them through designing and offering useful suggestions. The completed site is excellent, meeting the company’s expectations. They communicated effectively and organized the project efficiently and professionally.`,
					reviewOwner: 'Tony Nealon',
					reviewOwnerRole: 'Co-Founder, New Haven Cafe',
					reviewOwnerImageLink: '../../assets/images/3.jpg'
				},
				{
					reviewText: `They put in a lot of effort into staying in touch and keeping me updated. The working iOS app launched in the App Store and has received positive feedback from key stakeholders. GenixBit Labs keeps the project moving (and on budget) through constant communication, providing critical status updates throughout the engagement.`,
					reviewOwner: 'Scott Etherington',
					reviewOwnerRole: 'Chief Technical Advisor, Eat2Earn',
					reviewOwnerImageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/author/scott-etherington.jpg'
				},
				{
					reviewText: `They’re able to take on much more challenging projects than many other companies out there. According to outside programmers, GenixBit Labs’s coding has been nearly bug-free. While, in general, their response time, capacity to engage needed resources, and end products have impressed me.`,
					reviewOwner: 'Mukul Verma',
					reviewOwnerRole: 'CEO, Mokool Sports Games',
					reviewOwnerImageLink: '../../assets/images/mukul-verma.jpg'
				},
				{
					reviewText: `They are quick to respond to changes required for the app. The team’s responsiveness and detailed documentation help keep the project on track. They quickly implement change requests and resolve issues.`,
					reviewOwner: 'Jack Parkin',
					reviewOwnerRole: 'Founder, Chatagram',
					reviewOwnerImageLink: '../../assets/images/jack-parkin.jpg'
				},
				{
					reviewText: `GenixBit Labs was always willing to work hard on this project. Despite the normal issues when facing a development project, the app works as it’s supposed to. GenixBit Labs’s responsive team worked within budget, fixed bugs promptly, and was enjoyable to work with.`,
					reviewOwner: 'Imogen Venn',
					reviewOwnerRole: 'Account Director, Pebble studios',
					reviewOwnerImageLink:
						'https://www.intelivita.co.uk/assets/frameworks/public/images/author/imogen-venn.webp'
				},
				{
					reviewText: `They offer a cost-effective service and I was happy with the
										experience.” They accurately translated existing app
										features to iOS and even made slight improvements. Users
										love the iOS version and post positive reviews of it on the
										App Store. Offering local management staff and offshore
										development, GenixBit Labs provided an excellent service at a
										great price`,
					reviewOwner: 'Tref Griffiths',
					reviewOwnerRole: 'Owner, CluedUpp Games',
					reviewOwnerImageLink: '../../assets/images/tref-griffiths.jpg'
				}
			]
		};
	},
	methods: {
		setActiveIndex: function (index) {
			this.index_ = index;
			this.$refs.reviewsCarousel.goTo(index);
		},
		setIndex: function (index) {
			this.index_ = index;
		}
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-rev-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
	.testimonials_left {
		height: 250px !important;
		margin-top: 0 !important;
	}
	.fxt-title {
		margin-bottom: 0px !important;
	}
}

</style>
