<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our Expertise</span>
				<h2 class="fxt-process-dev">What Our Augmented Reality Game Developers Build</h2>
				<p>
					The augmented reality gaming is constantly evolving. Considered the breakthrough in AR
					games, GenixBit Labs being a prominent augmented reality game development company is focused
					to create more engaging and immersive AR gaming solutions.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<CroppedBorderCard v-bind:obj="item"></CroppedBorderCard>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items2" v-bind:key="item.title">
						<CroppedBorderCard v-bind:obj="item"></CroppedBorderCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import CroppedBorderCard from '../AssetComponents/CroppedBorderCard';

export default {
	data: () => {
		return {
			items: [
				{
					title: 'Geolocation Games',
					text: 'Quest games like treasure hunt has always been fantasizing the people. With our AR based geolocation game services, you can indeed bridge the gap between the real world and virtual clues.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M50.9,94.1l1.3-1.8c1.3-1.7,31.3-42,31.3-59.5C83.4,14.7,68.9,0,50.9,0c-18,0-32.6,14.7-32.6,32.8v0
                      c0,17.6,30,57.8,31.3,59.5L50.9,94.1z M50.9,3.3v0.1c16.1,0,29.2,13.2,29.3,29.4c0,14.4-23.3,47.5-29.3,55.7
                      c-5.9-8.3-29.3-41.3-29.3-55.8C21.6,16.5,34.7,3.3,50.9,3.3z M65.2,28.9c0-8.5-6.8-15.3-15.2-15.3c-8.4,0-15.2,6.9-15.2,15.3
                      c0,8.5,6.8,15.3,15.2,15.3C58.4,44.2,65.2,37.4,65.2,28.9z M50,40.9c-6.6,0-11.9-5.4-11.9-12S43.5,17,50,17
                      c6.6,0,11.9,5.4,11.9,12C61.9,35.5,56.6,40.9,50,40.9z M83.7,70H71.3v3.4h10.4l11.9,23.3H6.5l11.9-23.3h11.7V70H16.3L1,100h98
                      L83.7,70z"></path>
                    </g>
                  </g>
                </svg>
               `
				},
				{
					title: 'AR FPS Games',
					text: 'As the name suggests, build a first-person shooter game with our AR game development services and gain more eyeballs with an astonishing real game experience in the virtual world.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M91.7,17H8.3C3.7,17,0,20.7,0,25.2v49.5C0,79.3,3.7,83,8.3,83h83.3c4.6,0,8.3-3.7,8.3-8.3V25.2C100,20.7,96.3,17,91.7,17z
                      M82.1,20.3l-17.5,9.9H35.4l-17.5-9.9H82.1z M56.1,46c0.4-0.8,0.6-1.7,0.6-2.6c0-3.6-3-6.6-6.7-6.6c-3.7,0-6.7,3-6.7,6.6
                      c0,0.9,0.2,1.8,0.6,2.6c-1.4,1.5-2.2,3.5-2.2,5.6v6.6h-5V33.5h26.7v24.8h-5v-6.6C58.3,49.5,57.5,47.6,56.1,46z M47.3,45.4
                      c-0.4-0.6-0.7-1.3-0.7-2c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.7-0.2,1.4-0.7,2c-0.3,0.4-0.4,0.8-0.3,1.3
                      c0.1,0.4,0.3,0.8,0.7,1.1c0.2,0.1,0.3,0.3,0.5,0.4c0.9,0.9,1.5,2.2,1.5,3.5v6.6H45v-6.6c0-1.6,0.7-3,2-3.9
                      c0.4-0.3,0.6-0.7,0.7-1.1C47.7,46.2,47.6,45.7,47.3,45.4z M10,79.7H8.3c-2.8,0-5-2.2-5-5V25.2c0-2.7,2.2-5,5-5H10V79.7z
                      M13.3,21.5l20,11.3v26.4l-20,18.4V21.5z M15.9,79.7l19.7-18.2h12.7v3.3c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7v-3.3h12.7
                      l19.7,18.2H15.9z M86.7,77.6l-20-18.4V32.8l20-11.3V77.6z M96.7,48.3H95c-0.9,0-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7h1.7v23.1
                      c0,2.7-2.2,5-5,5H90V20.3h1.7c2.8,0,5,2.2,5,5V48.3z M50,69.8c-0.9,0-1.7,0.7-1.7,1.7v3.3c0,0.9,0.7,1.7,1.7,1.7
                      c0.9,0,1.7-0.7,1.7-1.7v-3.3C51.7,70.5,50.9,69.8,50,69.8z M46.7,66.5h-3.3c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7h3.3
                      c0.9,0,1.7-0.7,1.7-1.7C48.3,67.2,47.6,66.5,46.7,66.5z M56.7,66.5h-3.3c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.7,1.7,1.7h3.3
                      c0.9,0,1.7-0.7,1.7-1.7C58.3,67.2,57.6,66.5,56.7,66.5z"></path>
                    </g>
                  </g>
                </svg>
               `
				},
				{
					title: 'AR Based RPGS',
					text: 'This one takes the game experience to an entirely new level. Allow your players to go on a quest in real-world locations and fight with virtual assassins and monsters.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <path d="M97.8,84.6l-4.9-0.3l-5.8-6.6l11.1-10c0.5-0.5,0.7-1.3,0.4-2L95.8,59c-0.3-0.7-1-1.1-1.6-1c-0.7,0-1.3,0.5-1.5,1.2l-1.3,4.1
                  L85,68.9l-7.3-8.2c5-12.9,7.5-26.2,7.6-39.5c0-2.4-0.1-4.6-0.2-7l0-0.9l-0.8-0.5c-7-4.5-21.8-12-41.4-11.7
                  C22.7,1.3,7.8,9.7,0.8,14.7L0,15.2v0.9c0,0.5,0,1,0,1.6c0,1.4,0,3.2,0,5.3l0,0.1c0.3,14.4,0.8,34.2,10.8,49.9
                  c4.6,7.2,13.5,17.1,30.2,23.6l1.8,0.8l1.7-0.8c4.7-1.8,9.2-4.2,13.2-7.3c5.1-3.8,8.7-7.9,10.8-10.7l2.4,2.7l-6.5,5.9l-3.8,1.3
                  c-0.6,0.2-1.1,0.8-1.1,1.5c0,0.7,0.3,1.3,0.9,1.6l5.9,3c0.6,0.3,1.4,0.2,1.9-0.3l11.1-10l5.9,6.8l-0.2,4.5c0,1.5,1.1,2.7,2.5,2.7
                  c0,0,0,0,0,0l9.2,0.5c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.7-1.1,0.7-1.7l0.5-9.2l0-0.1C100,86,99,84.8,97.8,84.6z M84.6,80l3.6,4
                  c-0.6,0-1.2,0.3-1.7,0.8c-0.4,0.4-0.7,1-0.7,1.6l-3.7-4.2L84.6,80z M84.8,75.2L84.8,75.2L67,91.3l-1.3-0.7l0.3-0.1
                  c0.2-0.1,0.4-0.2,0.6-0.3l6.7-6l0,0l20.6-18.6c0.2-0.2,0.4-0.5,0.5-0.8l0.1-0.2l0.6,1.4L84.8,75.2z M89.1,87.5l2.2,0.1l0.3,0.4
                  l0.3-0.3l4.6,0.3l-0.4,7.6l-7.5-0.4L89.1,87.5z M79.3,74.1L39.1,29.8l6.9,0.1L64,50.3l-0.1,0.3l0.7,0.3l17.9,20.2L79.3,74.1z
                  M55.3,69.4c-3.5,4.1-7.7,7.8-12.6,10.9l-0.1,0.1l-0.1,0c-7.8-3.8-14.2-9.4-18.6-16.1c-6.8-10.7-7.4-23.1-7.8-31.2l0-0.1
                  c-0.1-2.9-0.1-5.4,0-7.1c10.7-6.7,20.9-8.1,27.5-8c10.5,0.1,18.8,3.5,24,6.3c0.6,4.1,0.7,8.2,0.2,12.4c-0.4,3.4-1.2,6.9-2.4,10.3
                  L47.5,26.5l-14-0.1l0.1,12.8l24.1,27.3C56.9,67.5,56.1,68.5,55.3,69.4z M81.8,15.1c0.1,2.3,0.1,4.1,0.1,6
                  c-0.1,12.3-2.3,24.6-6.7,36.6L68,49.7c1.7-4.2,2.7-8.5,3.2-12.8c0.6-4.7,0.5-9.5-0.4-14.2l-0.1-0.8l-0.7-0.4
                  c-5.6-3.2-14.7-7.1-26.4-7.2c-7.3-0.1-18.4,1.5-30.1,9l-0.7,0.5l0,0.9c-0.1,2.1-0.2,4.9,0,8.3l0,0.1c0.4,8.5,1.1,21.4,8.4,32.9
                  c4.7,7.3,11.6,13.3,20,17.4l1.6,0.7l1.6-0.8l0.1-0.1c5.1-3.3,9.7-7.2,13.4-11.6c0.8-0.9,1.5-1.8,2.1-2.6l6.3,7.1
                  c-2.1,2.8-5.5,6.8-10.6,10.6h0c-3.8,2.9-8,5.2-12.4,6.8l-0.5,0.2l-0.5-0.2C26.4,87.3,18,78,13.7,71.3C4.2,56.3,3.8,37.1,3.4,23.1
                  l0-0.1c-0.1-2,0-3.8,0-5.2l0-0.8c6.8-4.6,20.7-12.3,39.5-12.6C61.2,4.1,75,11,81.8,15.1z M76.7,76.4l-3.1,2.8L37,37.8l0-5.2
                  L76.7,76.4z"></path>
                </svg>
               `
				},
				{
					title: 'AR Shooting Games',
					text: "Adorn your shooting game application with augmented reality and avail your user's unbiased attention by bridging the gap between virtual adventure in the real-world arena.",
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <path d="M41.5,32l-3.7-9.2l-0.3-4.9l-0.3-0.2c-0.2-0.1-0.6-0.5-1.3-0.8L18.9,7.2c-2.2-1.1-3.8,0.1-4.4,1l-0.2,0.2v2.1l1,0.5
                  l-0.2,1.7l2,1.3v1.3l0.3,0.3c1,0.9,3.4,3.4,4,4c0,0.3,0,1.1-0.2,1.9c-0.1-0.1-0.2-0.1-0.3-0.2c-0.5-0.3-1.1-0.2-1.6,0.1
                  c-1.1,0.8-1.9,2.4-1.4,3.8c0.3,0.7,0.3,1.4,0.2,1.6c-0.4,0.3-0.8,0.7-1.3,1.3c-0.3,0.4-0.8,0.9-1,1c-1.4,0.8-1.1,6-0.8,7.5
                  c0.5,2.1,0.7,6.4,0.7,6.4l0,0.8H50l-0.9-1.3C48.4,41.6,44.1,35.5,41.5,32z M35,18.5l0.8,0.5l0.3,4.3l2.4,6c-0.6-0.1-1.3,0.1-2,0.2
                  c-0.6,0.1-1.2,0.3-1.8,0.3l-3.2-6.4v-4.3c0.1-0.1,0.3-0.4,1.1-0.7C33.5,18,34.3,18.2,35,18.5z M18.1,8.7l13.8,8
                  c-0.7,0.3-1.2,0.6-1.5,0.9l-13.8-9C17,8.4,17.5,8.4,18.1,8.7z M18.7,14.6V13l-1.8-1.2l0.1-0.9l12.7,8.3v4.4l2.5,5
                  c-0.9-0.8-1.9-2.1-3-3.4c-2.8-3.4-4.6-5.5-6.3-5.1c0.1-1.2-0.2-1.6-0.4-1.7C22.2,18,19.6,15.4,18.7,14.6z M24.3,22.3
                  c-0.3,0.4-0.9,0.9-1.8,1.1c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.3,0.1-0.8,1-1.1C23.6,21.8,23.9,22,24.3,22.3z M20.2,22.8
                  c0.2,0.1,0.5,0.5,0.7,0.8c-0.4,0.4-1,0.5-1.4,0.5C19.5,23.6,19.8,23.2,20.2,22.8z M17.3,42.1c-0.1-1.4-0.3-4.2-0.7-5.9
                  c-0.5-2-0.3-5.1,0.1-5.7c0.4-0.2,0.8-0.7,1.2-1.1c0,1.3,0,2.9,0.2,4.2l1.7-0.2c-0.3-2.4,0-5.6,0-6.1c0.1-0.5,0.1-1,0-1.5
                  c0.5,0,1.1-0.2,1.5-0.4c0.2,0.8,0.6,1.6,0.9,2c0.4,0.6,2.9,5.6,3,6.4c0.2,0.8,2.3,5.9,3.4,8.4L17.3,42.1L17.3,42.1z M30.4,42.1
                  c-1.7-3.9-3.5-8.2-3.6-8.7c-0.2-1.1-2.9-6.5-3.3-7.1c-0.2-0.2-0.4-0.8-0.5-1.3c1.2-0.3,2.1-0.9,2.6-1.5c0.9,0.9,1.8,2,2.4,2.8
                  c1.2,1.5,2.3,2.8,3.3,3.7c0.2,0.2,0.4,0.3,0.6,0.5c0.5,0.6,2.4,2.9,5.5,3.7l0.4-1.6c-1.1-0.3-2-0.8-2.7-1.3c0.6-0.1,1.2-0.2,1.7-0.3
                  c0.6-0.1,1.4-0.3,1.6-0.1c0.8,0.8,5.1,6.7,8.3,11.2L30.4,42.1L30.4,42.1z"></path>
                  <path d="M6,24.9v0.6h1.7v-0.6c2.7-0.4,4.9-2.5,5.3-5.3h0.6v-1.7h-0.6c-0.4-2.7-2.5-4.9-5.3-5.3V12H6v0.6c-2.7,0.4-4.9,2.5-5.3,5.3
                  H0.1v1.7h0.6C1.1,22.3,3.2,24.5,6,24.9z M2.4,17.9c0.3-1.8,1.8-3.2,3.5-3.5v2.1h1.7v-2.1c1.8,0.3,3.2,1.8,3.5,3.5H9.1v1.7h2.1
                  c-0.3,1.8-1.8,3.2-3.5,3.5V21H6v2.1c-1.8-0.3-3.2-1.8-3.5-3.5h2.1v-1.7H2.4z"></path>
                </svg>
               `
				}
			],
			items2: [
				{
					title: 'Sport AR Games',
					text: 'While traditional mobile applications restrain you from having entire fun with sports game applications, the Sports AR games ensure your users get all new experience of being on field.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M75.9,65.6l-1.3,1.3c-5.7-5-9.5-11.2-11.7-18.7c3.3-11-0.6-25-11.1-35.7c-6.5-6.6-14.7-11-23-12.1
                      c-8.4-1.1-16,1.1-21.1,6.5c-10.8,11-8.2,31.2,5.5,45.1c7.9,8.1,18.1,12.4,27.1,12.4c2.7,0,5.4-0.3,7.8-1.1
                      c7.4,2.3,13.5,6.1,18.4,12l-1.3,1.3L88.4,100L99,89.2L75.9,65.6z M36.8,25.7l-6.2-6.3l6.2-6.3l6.2,6.3L36.8,25.7z M45.2,21.6
                      l6.2,6.3l-6.2,6.3L39,27.9L45.2,21.6z M34.6,27.9l-6.2,6.3l-6.2-6.3l6.2-6.3L34.6,27.9z M20,25.7l-6.2-6.3l6.2-6.3l6.2,6.3
                      L20,25.7z M17.8,27.9l-6.2,6.3l-6.2-6.3l6.2-6.3L17.8,27.9z M20,30.2l6.2,6.3L20,42.8l-6.2-6.3L20,30.2z M28.4,38.7l6.2,6.3
                      l-6.2,6.3l-6.2-6.3L28.4,38.7z M30.6,36.5l6.2-6.3l6.2,6.3l-6.2,6.3L30.6,36.5z M45.2,38.7l6.2,6.3l-6.2,6.3L39,45.1L45.2,38.7z
                      M47.4,36.5l6.2-6.3l6.2,6.3l-6.2,6.3L47.4,36.5z M60.8,39.9c0,3.1-0.5,6.1-1.4,8.9l-3.6-3.7L60.8,39.9z M59.7,32l-4-4l1.7-1.8
                      C58.5,28.1,59.1,30,59.7,32z M55.9,23.1l-2.4,2.4l-6.2-6.1l3.3-3.4C52.8,18.2,54.5,20.6,55.9,23.1z M41.4,8.4
                      c2.5,1.5,4.8,3.2,7,5.3L45,17.1l-6-6.3L41.4,8.4z M38.5,6.7l-1.7,1.8l-4-4C34.7,5,36.6,5.8,38.5,6.7z M34.6,10.8l-6.2,6.3
                      l-6.2-6.3l6.2-6.3L34.6,10.8z M24.6,3.2c0.3,0.2,0.3,0.2,0.5,0.2L20,8.5l-3.6-3.7C18.9,3.8,21.7,3.2,24.6,3.2z M10,9
                      c1-1,2.1-1.9,3.3-2.6l4.4,4.5l-6.2,6.3l-4.3-4.7C8,11.4,8.9,10.1,10,9z M5.7,15.6l3.6,3.7l-5.1,5.2C4.3,21.5,4.8,18.4,5.7,15.6z
                      M5.4,32.4l4,4l-1.7,1.8C6.7,36.3,6.1,34.4,5.4,32.4z M9.2,41.2l2.4-2.4l6.2,6.3l-3.3,3.4C12.4,46.2,10.6,43.8,9.2,41.2z M23.8,56
                      c-2.4-1.5-4.8-3.2-7-5.3l3.3-3.4l6,6.3L23.8,56z M26.6,57.7l1.7-1.8l4,4C30.4,59.4,28.5,58.6,26.6,57.7z M30.6,53.6l6.2-6.3
                      l6.2,6.3l-6.2,6.3L30.6,53.6z M40.1,61.1l5.1-5.2l3.6,3.7C46.1,60.6,43.1,61.1,40.1,61.1z M47.4,53.5l6.2-6.3l4.4,4.5
                      c-0.8,1.3-1.6,2.4-2.7,3.6c-1.1,1.1-2.4,2.1-3.5,2.7L47.4,53.5z M52.6,61.2c1.7-1,3.3-2.3,4.9-3.7c1.4-1.5,2.7-3.1,3.6-5
                      c2.4,6.5,6.2,12,11.2,16.5l-3.5,3.6C64.3,67.5,58.9,63.8,52.6,61.2z M69.7,76.4l6.2-6.3l18.7,19.1l-6.2,6.3L69.7,76.4z M12.1,64.8
                      C5.9,64.8,1,69.8,1,76.1c0,6.3,4.9,11.3,11.1,11.3c6.2,0,11.1-5,11.1-11.3C23.2,69.8,18.2,64.8,12.1,64.8z M12.1,84.2
                      c-4.4,0-7.9-3.6-7.9-8.1S7.6,68,12.1,68c4.4,0,7.9,3.6,7.9,8.1S16.5,84.2,12.1,84.2z"></path>
                    </g>
                  </g>
                </svg>
               `
				},
				{
					title: 'Turn Based AR Games',
					text: 'There’s a good majority of people inclined towards 2-player games. Give them a better experience with turn-based AR games and expand your market with a new era of mobile game.',
					svg: `
               <svg version="1.1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M-0.5,0.1v0.8v0.2V21v8.7v1v19.1v0.8h0.8h20.1h8.7h20.1H50v-0.8V29.8V21V0.9V0.1h-0.8H29.1h-8.7H0.3H-0.5z M20.4,1.7h8.7v4
                    h-4.4c-0.5,0-1,0.4-1,1V23l-0.2-0.2c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-1,0.4-1,1c0,0.3,0.1,0.5,0.3,0.7l0.2,0.2H7V21h13.4V1.7z
                    M1.5,2.1h17v17h-17V2.1z M31.1,2.1h17v17h-17V2.1z M2.7,3.3v1v13.5h14.5V3.3H2.7z M32.3,3.3v1v13.5h14.5V3.3H32.3z M4.6,5.3h10.6
                    v10.6H4.6V5.3z M34.3,5.3h10.6v10.6H34.3V5.3z M6.8,7.6v2.5h2.5V7.6H6.8z M10.5,7.6v2.5H13V7.6H10.5z M36.5,7.6v2.5H39V7.6H36.5z
                    M40.1,7.6v2.5h2.5V7.6H40.1z M25.7,7.7h3.4V21h19.4v8.7h-4v-4.4c0-0.5-0.4-1-1-1H27.2l0.2-0.2c0.4-0.4,0.4-1,0-1.4
                    c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.3,0.3V7.7z M6.8,11.1v2.5h2.5v-2.5H6.8z M10.5,11.1v2.5H13v-2.5H10.5z
                    M36.5,11.1v2.5H39v-2.5H36.5z M40.1,11.1v2.5h2.5v-2.5H40.1z M1,21h4v4.3c0,0.5,0.4,1,1,1h16.4l-0.3,0.3c-0.4,0.4-0.4,1,0,1.4
                    s1,0.4,1.4,0c0,0,0,0,0,0l0.2-0.2v15.3h-3.3V29.8H1V21z M27.1,26.3h15.4v3.4H29.1v1v18.4h-8.7v-4h4.3c0.5,0,1-0.4,1-1V27.8l0.3,0.3
                    c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0L27.1,26.3z M1.5,31.7h17v17h-17V31.7z M31.1,31.7h17v17h-17V31.7z M2.7,33v1
                    v13.5h14.5V33H2.7z M32.3,33v1v13.5h14.5V33H32.3z M4.6,34.9h10.6v10.6H4.6V34.9z M34.3,34.9h10.6v10.6H34.3V34.9z M6.8,37.2v2.5
                    h2.5v-2.5H6.8z M10.5,37.2v2.5H13v-2.5H10.5z M36.5,37.2v2.5H39v-2.5H36.5z M40.1,37.2v2.5h2.5v-2.5H40.1z M6.8,40.7v2.5h2.5v-2.5
                    H6.8z M10.5,40.7v2.5H13v-2.5H10.5z M36.5,40.7v2.5H39v-2.5H36.5z M40.1,40.7v2.5h2.5v-2.5H40.1z"></path>
                  </svg>
               `
				},
				{
					title: 'AR Table Games',
					text: 'Be it the trending roulette and poker or childhood games like Chess, Ludo, Snake, and Ladders etc. With AR table games, you can indeed gain the special attention of your target audience.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M49.2,0h-15h-15h-15C3.7,0,3.3,0.4,3.3,0.8v15v8.3H5v-7.5h13.3V30h-2.5v1.7h2.5v14.2c0,0.5,0.4,0.8,0.8,0.8h15h15
                      c0.5,0,0.8-0.4,0.8-0.8v-15v-15v-15C50,0.4,49.6,0,49.2,0z M18.3,15H5V1.7h13.3V15z M33.3,45H20V31.7h13.3V45z M33.3,30H20V16.7
                      h13.3V30z M33.3,15H20V1.7h13.3V15z M48.3,45H35V31.7h13.3V45z M48.3,30H35V16.7h13.3V30z M48.3,15H35V1.7h13.3V15z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="21" y="4.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.2977 17.963)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="20.8" y="7.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 1.9172 21.2963)" width="11.8" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="27.6" y="10.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 0.5362 24.6294)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="36" y="19.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.9157 32.9626)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="35.8" y="22.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.2962 36.2959)" width="11.8" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="42.6" y="25.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.6772 39.629)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="21" y="34.2" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.9153 26.7493)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="20.8" y="37.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.2957 30.0826)" width="11.8" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="27.6" y="40.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.6768 33.4157)" width="4.7" height="1.7"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M17.5,45.6V45c0-1.8-0.7-3.6-2-4.9l-2.3-2.3c-0.8-0.8-1.3-2.3-1.5-3.7H15v-1.7h-2.1c0.8-0.9,1.3-2.1,1.3-3.3
                      c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,1.3,0.5,2.4,1.3,3.3H3.3v1.7h3.3c-0.2,1.3-0.7,2.9-1.5,3.7l-2.3,2.3c-1.3,1.3-2,3-2,4.9v0.6
                      C0.3,46.1,0,46.8,0,47.5v1.7C0,49.6,0.4,50,0.8,50h16.7c0.5,0,0.8-0.4,0.8-0.8v-1.7C18.3,46.8,18,46.1,17.5,45.6z M5.8,29.2
                      c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3S5.8,31,5.8,29.2z M4,41.3L6.3,39c1.1-1.1,1.8-3.2,2-4.9H10
                      c0.2,1.7,0.9,3.7,2,4.9l2.3,2.3c1,1,1.5,2.3,1.5,3.7H2.5C2.5,43.6,3,42.3,4,41.3z M16.7,48.3h-15v-0.8c0-0.3,0.2-0.6,0.5-0.7
                      c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1h13.3c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.3,0.1,0.5,0.4,0.5,0.7V48.3z"></path>
                    </g>
                  </g>
                </svg>
               `
				},
				{
					title: 'AR Card Games',
					text: 'Card games are the best time killer for people across the globe. Let that then be Teen Patti, Rummy or some other game. Introduce augmented reality card games with us and avail the best experience for your users.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <path d="M43.4,20.2c0.4,0,0.7-0.3,0.7-0.7V15c0-1.5-1.2-2.7-2.7-2.7h-8.8V2.9c0-1.5-1.2-2.7-2.7-2.7H9.4c-1.5,0-2.7,1.2-2.7,2.7v5.8
                  c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V2.9c0-0.7,0.5-1.2,1.2-1.2h20.5c0.7,0,1.2,0.5,1.2,1.2v9.4h-1.3V3.7
                  c0-0.4-0.3-0.7-0.7-0.7H10.2C9.8,3,9.4,3.3,9.4,3.7v30.5c0,0.4,0.3,0.7,0.7,0.7h8.1v1.3H9.4c-0.7,0-1.2-0.5-1.2-1.2V12.2
                  c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7V35c0,1.5,1.2,2.7,2.7,2.7h8.8v9.4c0,1.5,1.2,2.7,2.7,2.7h20.5c1.5,0,2.7-1.2,2.7-2.7V22.8
                  c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v24.3c0,0.7-0.5,1.2-1.2,1.2H20.9c-0.7,0-1.2-0.5-1.2-1.2V15c0-0.7,0.5-1.2,1.2-1.2h20.5
                  c0.7,0,1.2,0.5,1.2,1.2v4.4C42.6,19.8,42.9,20.2,43.4,20.2z M18.2,15v18.4h-7.3v-29h17.5v7.9h-7.5C19.4,12.4,18.2,13.6,18.2,15z"></path>
                  <path d="M31.1,36.9c0.9,0,2.6-1.2,3.7-2.4c1.6-1.8,2.5-3.8,2.5-5.7c0-2-1.6-3.7-3.7-3.7c-1,0-1.9,0.4-2.5,1c-0.7-0.6-1.6-1-2.5-1
                  c-2,0-3.7,1.6-3.7,3.7c0,1.9,0.9,3.9,2.5,5.7C28.6,35.7,30.2,36.9,31.1,36.9z M28.6,26.7c0.8,0,1.5,0.4,1.9,1.1
                  c0.1,0.2,0.4,0.4,0.6,0.4c0.3,0,0.5-0.1,0.6-0.4c0.4-0.7,1.1-1.1,1.9-1.1c1.2,0,2.2,1,2.2,2.2c0,2-1.3,3.8-2.1,4.6
                  c-1.2,1.3-2.4,1.9-2.7,2c-0.3-0.1-1.5-0.7-2.7-2c-0.8-0.8-2.1-2.6-2.1-4.6C26.4,27.6,27.4,26.7,28.6,26.7z"></path>
                  <path d="M25.5,20.8c0.4,0,0.7-0.3,0.7-0.7v-2.1c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5V20c0,0.4,0.3,0.7,0.7,0.7
                  c0.4,0,0.7-0.3,0.7-0.7v-0.5h2.1V20C24.8,20.4,25.1,20.8,25.5,20.8z M22.7,18v-0.1c0-0.6,0.5-1,1-1s1,0.5,1,1V18H22.7z"></path>
                  <path d="M38.6,46.7c1.4,0,2.5-1.1,2.5-2.5v-2.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.5h-2.1v-0.5c0-0.4-0.3-0.7-0.7-0.7
                  s-0.7,0.3-0.7,0.7v2.1C36.1,45.6,37.2,46.7,38.6,46.7z M39.6,44.1v0.1c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1v-0.1H39.6z"></path>
                </svg>
               `
				}
			]
		};
	},
	components: {
		CroppedBorderCard
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>
