<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>CMS for Simplicity</span>
				<h2 class="fxt-process-dev">Why Chose CMS Solutions for Business</h2>
				<p>
					Fundamentally, CMS enables a solid capacity to manage numerous users at the same time with
					distinctive permission levels. This makes it easy for businesses to manage a particular
					chunk or section of content, personalised for users. CMS helps to lend a certain identity
					to website along with the ability to stay agile on content front.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<BigWidthCard v-bind:obj="item"></BigWidthCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard';

export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
        {
					title: 'Content Customisation',
					text: 'Creating great content is just not enough. You need to get into the analytics of content and moderate the way you communicate. CRM offers high-level content customisation with ability to tailor it for each user segment.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <g>
                    <path d="M23.3,11.1H12c-0.6,0-1.1-0.5-1.1-1.1V4.2c0-0.6,0.5-1.1,1.1-1.1h11.4c0.6,0,1.1,0.5,1.1,1.1v5.7
                    C24.5,10.6,24,11.1,23.3,11.1z M12,4c-0.1,0-0.3,0.1-0.3,0.3v5.7c0,0.1,0.1,0.3,0.3,0.3h11.4c0.1,0,0.3-0.1,0.3-0.3V4.2
                    c0-0.1-0.1-0.3-0.3-0.3H12z"></path>
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <g>
                    <path d="M38.8,32.8H24.2c-0.7,0-1.3-0.6-1.3-1.3V15.2c0-0.7,0.6-1.3,1.3-1.3h14.7c0.7,0,1.3,0.6,1.3,1.3v16.3
                    C40.2,32.2,39.6,32.8,38.8,32.8z M24.2,14.9c-0.2,0-0.3,0.1-0.3,0.3v16.3c0,0.2,0.1,0.3,0.3,0.3h14.7c0.2,0,0.3-0.1,0.3-0.3V15.2
                    c0-0.2-0.1-0.3-0.3-0.3H24.2z"></path>
                  </g>
                </g>
              </g>
              <g>
                <path d="M8,50h22.7c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1l11.4-11.4c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2V11
                c0,0,0-0.1,0.1-0.1l3.3,0c1.9,0,3.6-1.2,3.9-3.1c0.5-2.4-1.4-4.6-3.7-4.6h-3.4c0,0-0.1,0-0.1-0.1V0.6C42.7,0.3,42.4,0,42,0H8
                C7.6,0,7.3,0.3,7.3,0.6v18.5l-0.2,0c-1.1,0.2-2.2,0.4-3.3,0.4H2.3c-1.2,0-2.3,1-2.3,2.3v3.1c0,1.2,1,2.3,2.3,2.3h1.6
                c1.1,0,2.2,0.1,3.3,0.4l0.2,0v21.8C7.3,49.7,7.6,50,8,50z M43.1,9.8h-11c0,0-0.1,0-0.1-0.1v-2c0,0,0-0.1,0.1-0.1h11
                c0,0,0.1,0,0.1,0.1L43.1,9.8C43.2,9.7,43.1,9.8,43.1,9.8z M44.4,4.4l1.7,0c1.3,0,2.6,0.9,2.8,2.2c0.3,1.7-1,3.2-2.7,3.2h-1.8
                c0,0-0.1,0-0.1-0.1L44.4,4.4C44.3,4.4,44.3,4.4,44.4,4.4z M43.2,4.4v2c0,0,0,0.1-0.1,0.1h-11c0,0-0.1,0-0.1-0.1v-2
                c0,0,0-0.1,0.1-0.1L43.2,4.4C43.1,4.4,43.2,4.4,43.2,4.4z M8.5,1.1h33c0,0,0.1,0,0.1,0.1v2c0,0,0,0.1-0.1,0.1h-9.9
                c-0.1,0-0.3,0-0.4,0.1l-4.1,3.2c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4l4.1,3.2c0.1,0.1,0.2,0.1,0.4,0.1h9.9
                c0,0,0.1,0,0.1,0.1v26.4c0,0,0,0.1-0.1,0.1H30.8c-0.3,0-0.6,0.3-0.6,0.6v10.7c0,0,0,0.1-0.1,0.1H8.5c0,0-0.1,0-0.1-0.1V27.9L8.8,28
                c1.3,0.4,2.6,1,3.7,1.8l0.8,0.5c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.3,0.3-0.5v-13c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.1-0.6,0
                l-0.8,0.5c-1.1,0.7-2.4,1.4-3.7,1.8l-0.3,0.1L8.5,1.1C8.5,1.2,8.5,1.1,8.5,1.1z M4.6,26.1l-0.2,0v-5.4l0.2,0c2.8-0.1,5.6-1,8-2.5
                l0.4-0.2v10.9l-0.4-0.2C10.2,27.1,7.5,26.2,4.6,26.1z"></path>
              </g>
              <g>
                <path d="M26.6,18.5c0,0.4,0.4,0.8,0.8,0.8h8.1c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-8.1C27,17.7,26.6,18,26.6,18.5z"></path>
              </g>
              <g>
                <path d="M35.6,24.2h-8.1c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h8.1c0.4,0,0.8-0.4,0.8-0.8S36,24.2,35.6,24.2z"></path>
              </g>
              <g>
                <path d="M35.6,27.4h-8.1c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h8.1c0.4,0,0.8-0.4,0.8-0.8S36,27.4,35.6,27.4z"></path>
              </g>
              <g>
                <path d="M35.6,20.9h-2.4c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h2.4c0.4,0,0.8-0.4,0.8-0.8S36,20.9,35.6,20.9z"></path>
              </g>
              <g>
                <path d="M27.4,22.6h2.4c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-2.4c-0.4,0-0.8,0.4-0.8,0.8S27,22.6,27.4,22.6z"></path>
              </g>
              <g>
                <path d="M27.4,38.8h-6.5c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h6.5c0.4,0,0.8-0.4,0.8-0.8C28.3,39.2,27.9,38.8,27.4,38.8z"></path>
              </g>
              <g>
                <path d="M11.2,40.5h6.5c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-6.5c-0.4,0-0.8,0.4-0.8,0.8C10.3,40.1,10.7,40.5,11.2,40.5z"></path>
              </g>
              <g>
                <path d="M27.4,42.1H11.2c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h16.3c0.4,0,0.8-0.4,0.8-0.8S27.9,42.1,27.4,42.1z"></path>
              </g>
              <g>
                <circle cx="16" cy="46.2" r="0.8"></circle>
              </g>
              <g>
                <circle cx="19.3" cy="46.2" r="0.8"></circle>
              </g>
              <g>
                <circle cx="22.6" cy="46.2" r="0.8"></circle>
              </g>
              <g>
                <path d="M16.4,19.9c0.9,0.9,1.4,2.2,1.4,3.5c0,1.3-0.5,2.5-1.4,3.5c-0.3,0.3-0.3,0.8,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2
                s0.4-0.1,0.6-0.2c1.2-1.2,1.9-2.9,1.9-4.6c0-1.7-0.7-3.4-1.9-4.6c-0.3-0.3-0.8-0.3-1.2,0C16.1,19.1,16.1,19.6,16.4,19.9z"></path>
              </g>
            </svg>
          `
				},
        {
					title: 'Content Agility',
					text: 'Changing content on the go while monitoring the same is easier said than done. Thanks to CMS you can see how the piece perform and make fast changes online to see the metrics shaping up in your favour.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M0,25C0,16.6,0,8.3,0,0c8.7,0,26.1,0,34.8,0c0.2,0,0.5,0,0.7,0c0.5,0.1,0.7,0.4,0.7,0.8c0,0.5-0.3,0.8-0.7,0.9
              c-0.3,0-0.6,0-0.9,0c-7.8,0-24.3,0-32.1,0c-0.7,0-0.7,0-0.7,0.7c0,15,0,30.1,0,45.1c0,0.7,0,0.7,0.7,0.7c12.1,0,32.9,0,45,0
              c0.5,0,0.7-0.1,0.7-0.7c0-11.7,0-23.5,0-35.2c0-0.4-0.3-0.7-0.7-0.7c-2.8,0-5.6,0-8.4,0c-0.9,0-1.2-0.3-1.2-1.2c0-3.1,0-6.3,0-9.4
              c0-0.5,0.1-0.9,0.6-1.1c0.5-0.2,0.8,0,1.2,0.4c3.3,3.3,6.7,6.5,10,9.8c0.2,0.2,0.3,0.6,0.3,0.9c0,4.4,0,8.9,0,13.3
              c0,8.1,0,16.2,0,24.3c0,1-0.2,1.3-1.3,1.3c-12.9,0-34.5,0-47.4,0C0.2,50,0,49.8,0,48.7C0,40.8,0,32.9,0,25z"></path>
              <path d="M8.3,43.3c0-0.5,0-1,0-1.6c0-0.6,0.2-0.9,0.8-1c0.8-0.2,1.6-0.3,2.4-0.4c0.3-0.1,0.5-0.2,0.6-0.5c0-0.1,0-0.2,0.1-0.3
              c0.6-1,0.3-1.7-0.6-2.4c-0.4-0.3-0.7-0.7-1-1.1c-0.4-0.4-0.4-0.8-0.1-1.2c0.6-0.9,1.2-1.7,1.9-2.6c0.4-0.5,0.7-0.5,1.3-0.3
              c0.8,0.3,1.5,0.7,2.3,1.1c0.3,0.1,0.4,0.1,0.7-0.1c0.3-0.3,0.6-0.5,1-0.7C18,32,18,31.7,18,31.3c-0.1-0.8-0.2-1.5-0.3-2.3
              c-0.1-0.6,0.1-0.9,0.7-1.1c1-0.3,2-0.6,3-1c0.5-0.2,0.9,0,1.2,0.5c0.4,0.7,0.8,1.4,1.1,2.1c0.2,0.3,0.4,0.5,0.7,0.4
              c0.3,0,0.6-0.1,0.9,0c0.6,0.1,0.9-0.2,1.2-0.7c0.3-0.6,0.6-1.2,0.9-1.7c0.3-0.6,0.7-0.7,1.3-0.5c0.9,0.3,1.8,0.6,2.8,0.9
              c0.7,0.2,0.9,0.5,0.8,1.3c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,0.4,0,0.7,0.4,1c0.3,0.1,0.5,0.3,0.7,0.5c0.4,0.4,0.8,0.4,1.3,0.1
              c0.6-0.3,1.2-0.6,1.9-0.9c0.6-0.3,1-0.2,1.4,0.3c0.6,0.8,1.2,1.6,1.7,2.4c0.4,0.6,0.4,0.9-0.1,1.4c-0.5,0.6-1.1,1.2-1.7,1.7
              c-0.2,0.2-0.3,0.4-0.1,0.7c0.2,0.4,0.3,0.8,0.4,1.3c0.1,0.3,0.3,0.5,0.6,0.5c0.8,0.1,1.6,0.3,2.4,0.4c0.6,0.1,0.8,0.4,0.8,1
              c0,1,0,2,0,3.1c0,0.6-0.2,0.9-0.8,1c-1.1,0.2-2.2,0.4-3.3,0.6c-0.6,0.1-1-0.2-1.1-0.7c-0.1-0.5,0.2-0.9,0.8-1.1
              c0.7-0.2,1.5-0.3,2.2-0.4c0.3-0.1,0.5-0.2,0.5-0.5c0-1.4,0-1.4-1.3-1.6c-0.5-0.1-1-0.2-1.5-0.3c-0.4-0.1-0.7-0.3-0.8-0.7
              c-0.3-0.9-0.6-1.9-0.9-2.8c-0.1-0.4,0-0.7,0.2-1c0.5-0.6,1.1-1.1,1.6-1.7c0.2-0.2,0.3-0.4,0.1-0.7c-0.8-1.2-0.8-1.2-2.1-0.6
              c-0.4,0.2-0.9,0.4-1.3,0.6c-0.4,0.2-0.7,0.1-1.1-0.2c-0.8-0.6-1.5-1.1-2.3-1.7c-0.4-0.3-0.6-0.6-0.5-1.1c0.1-0.8,0.2-1.5,0.3-2.3
              c0-0.1,0.1-0.2,0-0.2c-0.5-0.2-1-0.5-1.5-0.6c-0.1,0-0.4,0.2-0.5,0.3c-0.4,0.6-0.7,1.3-1.1,2c-0.2,0.4-0.6,0.6-1.1,0.6
              c-0.9,0-1.9,0-2.8,0c-0.5,0-0.8-0.1-1-0.6c-0.3-0.7-0.7-1.3-1.1-2c-0.2-0.3-0.3-0.4-0.7-0.3c-1.3,0.4-1.3,0.4-1.1,1.8
              c0.1,0.5,0.1,0.9,0.2,1.4c0.1,0.5-0.1,0.8-0.5,1c-0.8,0.6-1.6,1.1-2.3,1.7c-0.4,0.3-0.7,0.3-1.1,0.1c-0.7-0.3-1.4-0.7-2.1-1
              c-0.3-0.2-0.5-0.1-0.6,0.2c-0.8,1.1-0.8,1.1,0.1,2.2c0.3,0.3,0.6,0.7,1,1c0.3,0.3,0.3,0.7,0.2,1.1c-0.3,0.9-0.6,1.7-0.9,2.6
              c-0.1,0.5-0.4,0.8-0.9,0.9c-0.7,0.1-1.4,0.3-2.1,0.4c-0.4,0.1-0.5,0.2-0.5,0.6c0,1.3,0,1.3,1.3,1.6c0.5,0.1,1,0.2,1.5,0.3
              c0.6,0.1,0.9,0.5,0.8,1c-0.1,0.5-0.5,0.8-1,0.7c-1.2-0.2-2.3-0.4-3.5-0.6c-0.5-0.1-0.7-0.4-0.7-0.9C8.3,44.4,8.3,43.8,8.3,43.3z"></path>
              <path d="M10.7,16.7c-1.3,0-2.7,0-4,0c-0.7,0-1-0.3-1-1c0-2.7,0-5.4,0-8.1c0-0.7,0.3-1,1-1c2.7,0,5.4,0,8.1,0c0.7,0,1,0.3,1,1
              c0,2.7,0,5.4,0,8.1c0,0.7-0.3,1-1,1C13.4,16.7,12.1,16.7,10.7,16.7z"></path>
              <path d="M16.6,43.3c0-4.2,3.3-7.9,7.5-8.3c4.4-0.4,8.4,2.6,9.1,6.9c0.2,1.3,0.2,2.6-0.2,3.8c0,0.1-0.1,0.3-0.1,0.4
              c-0.2,0.5-0.6,0.7-1.1,0.5c-0.5-0.1-0.7-0.6-0.6-1.1c0.1-0.5,0.2-1,0.3-1.5c0.5-3.6-2.3-7.3-6.5-7.4c-3.8,0-6.3,2.9-6.6,5.8
              c-0.1,1,0,1.9,0.2,2.8c0.1,0.3,0.1,0.6,0,0.8c-0.2,0.2-0.5,0.5-0.8,0.5c-0.4,0.1-0.7-0.2-0.8-0.6c-0.1-0.4-0.2-0.8-0.3-1.2
              C16.7,44.3,16.7,43.8,16.6,43.3z"></path>
              <path d="M24.9,21.7c-2.4,0-4.7,0-7.1,0c-0.2,0-0.3,0-0.5,0c-0.5-0.1-0.8-0.4-0.8-0.8c0-0.5,0.3-0.8,0.7-0.9c0.1,0,0.3,0,0.4,0
              c4.8,0,9.6,0,14.4,0c0.1,0,0.3,0,0.4,0c0.4,0.1,0.7,0.4,0.7,0.9c0,0.5-0.3,0.7-0.7,0.8c-0.2,0-0.3,0-0.5,0
              C29.7,21.7,27.3,21.7,24.9,21.7z"></path>
              <path d="M34.2,18.4c-2.1,0-4.3,0-6.4,0c-0.3,0-0.7-0.1-0.8-0.3c-0.2-0.2-0.2-0.6-0.2-0.8c0.1-0.2,0.4-0.4,0.6-0.5
              c0.1-0.1,0.4,0,0.6,0c4.2,0,8.4,0,12.6,0c0.1,0,0.2,0,0.4,0c0.5,0,0.8,0.4,0.8,0.9c0,0.5-0.3,0.8-0.9,0.8c-0.9,0-1.8,0-2.7,0
              C36.8,18.4,35.5,18.4,34.2,18.4z"></path>
              <path d="M25.6,10c1.6,0,3.2,0,4.8,0c0.8,0,1.3,0.3,1.3,0.9c0,0.6-0.4,0.9-1.3,0.9c-3.2,0-6.4,0-9.6,0c-0.8,0-1.3-0.3-1.2-0.9
              c0-0.5,0.5-0.8,1.2-0.8C22.4,10,24,10,25.6,10z"></path>
              <path d="M10.7,25c-1.3,0-2.6,0-4,0c-0.7,0-1.1-0.3-1.1-0.9c0-0.6,0.4-0.9,1-0.9c2.7,0,5.3,0,8,0c0.7,0,1.1,0.3,1,0.9
              c0,0.6-0.4,0.9-1.1,0.9C13.4,25,12.1,25,10.7,25z"></path>
              <path d="M23.6,15c-0.9,0-1.9,0-2.8,0c-0.8,0-1.2-0.3-1.2-0.9c0-0.5,0.4-0.8,1.2-0.8c1.9,0,3.9,0,5.8,0c0.7,0,1.2,0.3,1.2,0.9
              c0,0.5-0.4,0.9-1.1,0.9C25.5,15.1,24.5,15,23.6,15z"></path>
              <path d="M38.3,15c-0.8,0-1.6,0-2.3,0c-0.6,0-1-0.3-1-0.9c0-0.5,0.4-0.9,1-0.9c1.6,0,3.2,0,4.7,0c0.6,0,1,0.3,1,0.9
              c0,0.5-0.4,0.8-1,0.9C39.9,15.1,39.1,15,38.3,15z"></path>
              <path d="M9.1,20c0.8,0,1.6,0,2.4,0c0.6,0,1,0.3,1,0.9c0,0.5-0.4,0.9-1,0.9c-1.6,0-3.2,0-4.8,0c-0.6,0-1-0.3-1-0.8
              c0-0.5,0.3-0.9,1-0.9C7.5,20,8.3,20,9.1,20z"></path>
              <path d="M38.3,21.7c-0.8,0-1.6,0-2.3,0c-0.6,0-1-0.3-1-0.8c0-0.5,0.4-0.9,1-0.9c1.6,0,3.2,0,4.7,0c0.6,0,1,0.3,1,0.9
              c0,0.5-0.4,0.8-1,0.9C39.9,21.7,39.1,21.7,38.3,21.7z"></path>
              <path d="M29.1,8.4c-0.5,0-1,0-1.6,0c-0.6,0-0.9-0.3-0.9-0.8c0-0.5,0.3-0.9,0.9-0.9c1.1,0,2.1,0,3.2,0c0.6,0,0.9,0.4,0.9,0.9
              c0,0.5-0.3,0.8-0.9,0.9C30.2,8.4,29.7,8.4,29.1,8.4z"></path>
              <path d="M21.6,6.7c0.4,0,0.7,0,1.1,0c0.6,0,1,0.4,1,0.9c0,0.5-0.4,0.8-1,0.9c-0.7,0-1.5,0-2.2,0c-0.6,0-0.9-0.4-0.9-0.9
              c0-0.4,0.4-0.8,0.9-0.8C20.9,6.6,21.2,6.7,21.6,6.7z"></path>
              <path d="M31.6,15c-0.3,0-0.6,0-0.8,0c-0.5,0-0.8-0.4-0.8-0.9c0-0.5,0.3-0.8,0.8-0.8c0.6,0,1.2,0,1.8,0c0.5,0,0.8,0.4,0.8,0.8
              c0,0.5-0.3,0.8-0.8,0.9C32.2,15.1,31.9,15,31.6,15z"></path>
              <path d="M21.6,18.4c-0.4,0-0.7,0-1.1,0c-0.6,0-1-0.4-1-0.9c0-0.5,0.4-0.8,1-0.9c0.7,0,1.4,0,2.1,0c0.6,0,1,0.4,1,0.8
              c0,0.5-0.4,0.8-1,0.9C22.3,18.4,21.9,18.4,21.6,18.4C21.6,18.4,21.6,18.4,21.6,18.4z"></path>
            </svg>
          `
				},
        {
					title: 'Carving Brand through Content',
					text: 'If the content is what drives your business it surely is your identity on the web. Implement CMS to leverage content for branding purpose. Boost marketing activities to gauge efforts and ensure capitalising on the created content for the branding purpose.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M15,29.1c0,0.3,0,0.5,0.1,0.8l-2,1.8c-0.4-0.6-1-0.9-1.7-1c-0.7,0-1.3,0.3-1.8,0.8L7.5,30c0.2-1.2-0.4-2.4-1.5-2.8
              c-1.1-0.4-2.3,0.1-2.8,1.2c-0.5,1.1-0.2,2.5,0.7,3.2c0.9,0.7,2.2,0.6,3-0.3L9,32.8c-0.2,0.9,0.2,1.9,0.9,2.4
              c0.7,0.6,1.6,0.7,2.4,0.2c0.8-0.4,1.3-1.3,1.3-2.2c0-0.1,0-0.1,0-0.2l2.3-2.1c0.8,0.7,1.9,0.8,2.8,0.2c0.9-0.6,1.2-1.7,1-2.8l2-1.9
              c0.7,1,1.9,1.3,2.9,0.7c1-0.6,1.5-1.8,1.1-3c-0.4-1.2-1.4-1.9-2.6-1.7c-1.1,0.2-1.9,1.2-1.9,2.5c0,0,0,0,0,0l-2.2,2.2
              c-0.7-0.6-1.7-0.8-2.5-0.4C15.6,27.2,15,28.1,15,29.1z M5.2,30.6c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1s1,0.5,1,1.1
              C6.3,30.1,5.8,30.6,5.2,30.6L5.2,30.6z M11.3,34.3c-0.6,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1-1.1c0.6,0,1,0.5,1,1.1
              C12.3,33.8,11.9,34.3,11.3,34.3L11.3,34.3z M23.4,23.9c0.6,0,1,0.5,1,1.1c0,0.6-0.5,1.1-1,1.1s-1-0.5-1-1.1
              C22.4,24.4,22.8,23.9,23.4,23.9L23.4,23.9z M17.4,28c0.6,0,1,0.5,1,1.1c0,0.6-0.5,1.1-1,1.1c-0.6,0-1-0.5-1-1.1
              C16.3,28.5,16.8,28,17.4,28L17.4,28z"></path>
              <path d="M24.9,29.1h-3c-0.4,0-0.6,0.3-0.6,0.7v13.5c0,0.4,0.3,0.7,0.6,0.7h3c0.4,0,0.6-0.3,0.6-0.7V29.8
              C25.5,29.4,25.2,29.1,24.9,29.1z M24.2,42.6h-1.7V30.5h1.7V42.6z"></path>
              <path d="M18.8,33.2h-3c-0.4,0-0.6,0.3-0.6,0.7v9.4c0,0.4,0.3,0.7,0.6,0.7h3c0.4,0,0.6-0.3,0.6-0.7v-9.4
              C19.5,33.5,19.2,33.2,18.8,33.2z M18.2,42.6h-1.7v-8h1.7V42.6z"></path>
              <path d="M12.8,37.4h-3c-0.4,0-0.6,0.3-0.6,0.7v5.2c0,0.4,0.3,0.7,0.6,0.7h3c0.4,0,0.6-0.3,0.6-0.7v-5.2
              C13.4,37.7,13.1,37.4,12.8,37.4z M12.1,42.6h-1.7v-3.8h1.7V42.6z"></path>
              <path d="M6.7,33.7h-3c-0.4,0-0.6,0.3-0.6,0.7v8.9c0,0.4,0.3,0.7,0.6,0.7h3c0.4,0,0.6-0.3,0.6-0.7v-8.9C7.4,34,7.1,33.7,6.7,33.7z
              M6.1,42.6H4.4v-7.5h1.7V42.6z"></path>
              <path d="M4.2,19.6h11.4c0.4,0,0.7-0.3,0.7-0.7v-6.2c0-0.4-0.3-0.7-0.7-0.7H4.2c-0.4,0-0.7,0.3-0.7,0.7v6.2
              C3.5,19.3,3.8,19.6,4.2,19.6z M4.9,13.3h10v4.8h-10V13.3z"></path>
              <path d="M33.1,18.2h-0.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h0.5c0.4,0,0.7-0.3,0.7-0.7C33.8,18.5,33.5,18.2,33.1,18.2z"></path>
              <path d="M24.2,19.6h5.3c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-5.3c-0.4,0-0.7,0.3-0.7,0.7C23.5,19.3,23.8,19.6,24.2,19.6z"></path>
              <path d="M18.7,19.6h2.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-2.6c-0.4,0-0.7,0.3-0.7,0.7C18,19.3,18.3,19.6,18.7,19.6z"></path>
              <path d="M18.7,13.3h2.6c0.4,0,0.7-0.3,0.7-0.7S21.7,12,21.3,12h-2.6c-0.4,0-0.7,0.3-0.7,0.7S18.3,13.3,18.7,13.3z"></path>
              <path d="M27.8,13.3h5.3c0.4,0,0.7-0.3,0.7-0.7S33.5,12,33.1,12h-5.3c-0.4,0-0.7,0.3-0.7,0.7S27.4,13.3,27.8,13.3z"></path>
              <path d="M24.2,13.3h0.4c0.4,0,0.7-0.3,0.7-0.7S25,12,24.6,12h-0.4c-0.4,0-0.7,0.3-0.7,0.7S23.8,13.3,24.2,13.3z"></path>
              <path d="M10.1,24.7c0,0.4,0.3,0.7,0.7,0.7H19c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-8.2C10.4,24,10.1,24.3,10.1,24.7z"></path>
              <path d="M8.3,25.4C8.7,25.4,9,25,9,24.7C9,24.3,8.7,24,8.3,24H7.4c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H8.3z"></path>
              <path d="M4.2,25.4h0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H4.2c-0.4,0-0.7,0.3-0.7,0.7C3.5,25,3.8,25.4,4.2,25.4z"></path>
              <path d="M24.6,15.8c0,0.4,0.3,0.7,0.7,0.7h8.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-8.2C24.9,15.1,24.6,15.4,24.6,15.8z"></path>
              <path d="M22.8,16.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H22.8z"></path>
              <path d="M18.7,16.5h0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-0.7c-0.4,0-0.7,0.3-0.7,0.7C18,16.1,18.3,16.5,18.7,16.5z"></path>
              <path d="M36.8,2.3h-4.2V0.7c0-0.4-0.3-0.7-0.7-0.7H9.3C9,0,8.6,0.3,8.6,0.7v1.6H3.2C1.4,2.3,0,3.7,0,5.5v41.3C0,48.6,1.4,50,3.2,50
              h26.1v-1.4h-2.4h-2.3H3.2c-1,0-1.8-0.8-1.8-1.8V5.5c0-1,0.8-1.8,1.8-1.8h5.5V5c0,0.4,0.3,0.7,0.7,0.7h22.6c0.4,0,0.7-0.3,0.7-0.7
              V3.7h4.2c1,0,1.8,0.8,1.8,1.8v19.9H40c0,0,0,0,0,0V5.5C40,3.7,38.6,2.3,36.8,2.3z M31.2,4.3H10V1.4h21.2V4.3z"></path>
              <path d="M49.8,36.9c0-0.6-0.5-1.2-1.2-1.2l-1.1,0l0-0.1c-0.2-0.6-0.4-1.2-0.7-1.7l-0.1-0.1l0.7-0.7c0.5-0.5,0.5-1.2,0-1.7l-2.1-2.1
              c-0.5-0.5-1.2-0.5-1.7,0L43,29.9l-0.1-0.1c-0.5-0.3-1.1-0.5-1.7-0.7l-0.1,0V28c0-0.6-0.5-1.2-1.2-1.2h-3c-0.6,0-1.2,0.5-1.2,1.2
              l0,1.1l-0.1,0c-0.6,0.2-1.2,0.4-1.7,0.7l-0.1,0.1l-0.8-0.8c-0.5-0.5-1.2-0.5-1.7,0l-2.1,2.1c-0.5,0.5-0.5,1.2,0,1.7l0.8,0.8
              l-0.1,0.1c-0.3,0.5-0.5,1.1-0.7,1.7l0,0.1H28c-0.6,0-1.2,0.5-1.2,1.2v3c0,0.6,0.5,1.2,1.2,1.2l1.1,0l0,0.1c0.2,0.6,0.4,1.2,0.7,1.7
              l0.1,0.1l-0.8,0.8c-0.5,0.5-0.5,1.2,0,1.7l2.1,2.1c0.5,0.5,1.2,0.5,1.7,0l0.8-0.8l0.1,0.1c0.5,0.3,1.1,0.5,1.7,0.7l0.1,0v1.1
              c0,0.6,0.5,1.2,1.2,1.2h3c0.6,0,1.2-0.5,1.2-1.2l0-1.1l0.1,0c0.6-0.2,1.2-0.4,1.7-0.7l0.1-0.1l0.8,0.8c0.5,0.5,1.2,0.5,1.7,0
              l2.1-2.1c0.5-0.5,0.5-1.2,0-1.7L46.7,43l0.1-0.1c0.3-0.5,0.5-1.1,0.7-1.7l0-0.1h1.1c0.6,0,1.2-0.5,1.2-1.2V36.9z M48.3,39.5h-1
              c-0.5,0-1,0.4-1.1,0.9c-0.2,0.7-0.5,1.4-0.9,2c-0.3,0.5-0.2,1,0.2,1.4l0.7,0.7l-1.7,1.7l-0.7-0.7c-0.4-0.4-1-0.5-1.4-0.2
              c-0.6,0.4-1.3,0.7-2,0.8c-0.5,0.1-0.9,0.6-0.9,1.1v1h-2.4v-1c0-0.5-0.4-1-0.9-1.1c-0.7-0.2-1.4-0.5-2-0.8c-0.4-0.3-1.1-0.2-1.4,0.2
              l-0.7,0.7l-1.7-1.7l0.7-0.7c0.4-0.4,0.5-1,0.2-1.4c-0.4-0.6-0.7-1.3-0.8-2c-0.1-0.5-0.6-0.9-1.1-0.9h-1v-2.4h1c0.5,0,1-0.4,1.1-0.9
              c0.2-0.7,0.5-1.4,0.8-2c0.3-0.5,0.2-1-0.2-1.4l-0.7-0.7l1.7-1.7l0.7,0.7c0.4,0.4,1,0.5,1.4,0.2c0.6-0.4,1.3-0.7,2.1-0.9
              c0.5-0.1,0.9-0.6,0.9-1.1v-1h2.4v1c0,0.5,0.4,1,0.9,1.1c0.7,0.2,1.4,0.5,2.1,0.8c0.5,0.3,1,0.2,1.4-0.2l0.7-0.7l1.7,1.7l-0.7,0.7
              c-0.4,0.4-0.5,1-0.2,1.4c0.4,0.6,0.7,1.3,0.8,2.1c0.1,0.5,0.6,0.9,1.1,0.9h1V39.5z"></path>
              <path d="M44.6,34.3c-0.2-0.3-0.7-0.4-1-0.2c-0.3,0.2-0.4,0.7-0.2,1c0.5,0.7,0.8,1.5,0.9,2.3l0,0.2H39v-5.3l0.2,0
              c0.6,0.1,1.2,0.3,1.7,0.6c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.2,0.4-0.4c0.2-0.4,0-0.8-0.3-1c-1-0.5-2.2-0.8-3.4-0.8
              c-0.4,0-0.7,0.3-0.7,0.7v0.5l-0.2,0c-3.1,0.4-5.5,3.2-5.5,6.3c0,3.5,2.9,6.4,6.4,6.4c3.2,0,5.9-2.4,6.3-5.5l0-0.2H45
              c0.4,0,0.7-0.3,0.7-0.7C45.8,37,45.3,35.6,44.6,34.3z M35.5,34.3c0.5-0.4,1.1-0.6,1.7-0.7l0.2,0v3.1l-2.2-2.2L35.5,34.3z M43,39.4
              c-0.5,2.3-2.5,3.9-4.8,3.9c-2.7,0-4.9-2.2-4.9-4.9c0-1,0.3-1.9,0.8-2.7l0.1-0.2l3.4,3.4c0.1,0.1,0.3,0.2,0.5,0.2h4.9L43,39.4z"></path>
            </svg>
          `
				},
        {
					title: 'Granting Content Ownership',
					text: 'With CMS, you will have an upper hand to assign content roles within the team. The content experts can simply put up the content live without the developers’ assistance, without even the need to know to code.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M5.3,2.8c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1C7.5,3.8,6.5,2.8,5.3,2.8z M5.3,5.6
              C5,5.6,4.7,5.3,4.7,5c0-0.4,0.3-0.7,0.7-0.7S6,4.6,6,5C6,5.3,5.7,5.6,5.3,5.6z"></path>
              <path d="M10.8,2.8c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1C12,7.1,13,6.1,13,5C13,3.8,12,2.8,10.8,2.8z M10.8,5.6
              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7C11.5,5.3,11.2,5.6,10.8,5.6z"></path>
              <path d="M16.3,2.8c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1C18.4,3.8,17.5,2.8,16.3,2.8z M16.3,5.6
              c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7C16.7,4.3,17,4.6,17,5C17,5.3,16.7,5.6,16.3,5.6z"></path>
              <path d="M17.7,13.4H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,13.7,18.1,13.4,17.7,13.4z"></path>
              <path d="M17.7,16.8H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,17.2,18.1,16.8,17.7,16.8z"></path>
              <path d="M17.7,20.3H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,20.6,18.1,20.3,17.7,20.3z"></path>
              <path d="M44.8,13.4H26.7c-0.6,0-1.1,0.3-1.1,0.7c0,0.4,0.5,0.7,1.1,0.7h18.1c0.6,0,1.1-0.3,1.1-0.7C45.9,13.7,45.4,13.4,44.8,13.4z"></path>
              <path d="M44.8,16.8H26.7c-0.6,0-1.1,0.3-1.1,0.7c0,0.4,0.5,0.7,1.1,0.7h18.1c0.6,0,1.1-0.3,1.1-0.7C45.9,17.2,45.4,16.8,44.8,16.8z"></path>
              <path d="M38.8,20.3H26.4c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C39.5,20.6,39.2,20.3,38.8,20.3z"></path>
              <path d="M17.7,23.7H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,24,18.1,23.7,17.7,23.7z"></path>
              <path d="M17.7,27.2H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,27.5,18.1,27.2,17.7,27.2z"></path>
              <path d="M17.7,30.6H5.3c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7S18.1,30.6,17.7,30.6z"></path>
              <path d="M17.7,34H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,34.4,18.1,34,17.7,34z"></path>
              <path d="M17.7,37.5H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,37.8,18.1,37.5,17.7,37.5z"></path>
              <path d="M17.7,40.9H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,41.2,18.1,40.9,17.7,40.9z"></path>
              <path d="M17.7,44.3H5.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h12.4c0.4,0,0.7-0.3,0.7-0.7C18.4,44.7,18.1,44.3,17.7,44.3z"></path>
              <path d="M21.2,13v33.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V13c0-0.4-0.3-0.7-0.7-0.7C21.6,12.2,21.2,12.5,21.2,13z"></path>
              <path d="M43.7,2.8h-4.3c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h4.3c0.4,0,0.7,0.3,0.7,0.7s-0.3,0.7-0.7,0.7H24.3
              c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h10.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H24.3c-1.2,0-2.2,1-2.2,2.2
              c0,1.2,1,2.2,2.2,2.2h19.4c1.2,0,2.2-1,2.2-2.2C45.9,3.8,44.9,2.8,43.7,2.8z"></path>
              <path d="M26.1,25.7h3.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-3.5c-0.4,0-0.7,0.3-0.7,0.7C25.4,25.4,25.7,25.7,26.1,25.7z"></path>
              <path d="M26.1,40.4h4.4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-4.4c-0.4,0-0.7,0.3-0.7,0.7S25.7,40.4,26.1,40.4z"></path>
              <path d="M33.4,40.4H41c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-7.5c-0.4,0-0.7,0.3-0.7,0.7C32.7,40.1,33,40.4,33.4,40.4z"></path>
              <path d="M45.3,38.9h-1.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h1.7c0.4,0,0.7-0.3,0.7-0.7C46,39.3,45.7,38.9,45.3,38.9z"></path>
              <path d="M43.5,25.9l2.4-2.3c0.4-0.4,0.7-1,0.7-1.6c0-1.2-1-2.2-2.2-2.2c-0.6,0-1.1,0.2-1.5,0.6l-3.4,3.3h-2.3c-0.1,0-0.2,0-0.2,0
              L36,24c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.1-0.3,0.2-0.4,0.3l-1,0.9l-0.3,0.3l-3,3c-0.4,0.4-0.5,0.8-0.5,1.3c0,0.7,0.4,1.3,1,1.6
              c0,0,0,0,0,0.1l-1.4,1.4l-1.2,4.3l4.3-1.2l0.7-0.6v0l0,0l0.5-0.5l1-0.9l0.8-0.8H36h1v-1h-4.1c-0.4,0-0.7-0.3-0.7-0.7
              c0-0.4,0.3-0.7,0.7-0.7h4.3c0.6,0,1.2-0.2,1.7-0.7l4-3.8l6.2,5.9v7.3l-6.5-6.4c-0.6-0.6-1.5-1-2.4-1h-2.2v1H39h1.1
              c0.6,0,1.3,0.3,1.7,0.7l8.2,8V32.1L43.5,25.9z M43.6,21.2c0.2-0.2,0.5-0.3,0.9-0.3c0.7,0,1.2,0.5,1.2,1.2c0,0.3-0.1,0.7-0.4,0.9
              l-1.4,1.3l-1.7-1.7L43.6,21.2z M29.9,36.1l0.5-1.8l1.3,1.3L29.9,36.1z M32.8,33.4h1.6l-1.8,1.7l-1.7-1.7l0.7-0.6
              C31.8,33.2,32.3,33.4,32.8,33.4z M32.8,30c-0.6,0-1.1,0.3-1.4,0.7c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.5,0.3-0.6l4.1-4.1
              c0.5-0.5,1.1-0.7,1.7-0.7h1.3L33,30H32.8z M38.1,29.6c-0.3,0.3-0.6,0.4-1,0.4h-2.8l7.1-6.9l1.7,1.7L38.1,29.6z"></path>
              <g>
                <path d="M48.5,42.4v4.4c0,1-0.8,1.8-1.8,1.8H29.5c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h17.3c1.8,0,3.2-1.4,3.2-3.2v-4.4
                H48.5z"></path>
                <path d="M25,48.5H3.2c-1,0-1.8-0.8-1.8-1.8V10h47.1v18.7H50V3.2C50,1.4,48.6,0,46.8,0H3.2C1.4,0,0,1.4,0,3.2v43.6
                C0,48.6,1.4,50,3.2,50H25c0.4,0,0.7-0.3,0.7-0.7C25.7,48.9,25.4,48.5,25,48.5z M1.5,3.2c0-1,0.8-1.8,1.8-1.8h43.6
                c1,0,1.8,0.8,1.8,1.8v5.3H1.5V3.2z"></path>
              </g>
            </svg>
          `
				},
        {
					title: 'Maintaining Visual Consistency',
					text: 'CMS enables parallel design configuration for your business to follow a visual consistency which in turn aids creating great brand elements. Site or app-wide implementation on one go empowers the entire team to be on the same page.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M25.1,0c7.2,0,14.4,0,21.6,0C48.8,0,50,1.2,50,3.3c0,14.5,0,29,0,43.5c0,1.9-1.3,3.2-3.2,3.2c-14.5,0-29,0-43.6,0
              c-2,0-3.2-1.2-3.2-3.2c0-14.5,0-29,0-43.5C0,1.2,1.2,0,3.2,0C10.5,0,17.8,0,25.1,0z M1.5,10.9c0,0.2,0,0.4,0,0.6
              c0,11.7,0,23.5,0,35.2c0,1.3,0.5,1.8,1.8,1.8c14.4,0,28.8,0,43.3,0c1.3,0,1.9-0.6,1.9-1.9c0-11.7,0-23.4,0-35.2c0-0.2,0-0.4,0-0.6
              C32.8,10.9,17.2,10.9,1.5,10.9z M48.4,9.4c0-0.2,0-0.4,0-0.5c0-1.8,0-3.7,0-5.5c0-1.4-0.5-1.9-1.9-1.9c-3.9,0-7.8,0-11.6,0
              c-10.5,0-21,0-31.6,0C2,1.5,1.5,2,1.5,3.4c0,1.8,0,3.6,0,5.4c0,0.2,0,0.4,0,0.5C17.2,9.4,32.8,9.4,48.4,9.4z"></path>
              <path d="M15.5,5.3c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5C14.4,2.9,15.5,4,15.5,5.3z M13,4.5
              c-0.5,0-0.9,0.4-0.9,0.9c0,0.6,0.4,0.9,0.9,0.9C13.6,6.4,14,6,14,5.5C14,4.9,13.6,4.5,13,4.5z"></path>
              <path d="M5.9,7.9c-1.4,0-2.5-1-2.5-2.4c0-1.4,1.1-2.6,2.5-2.6c1.4,0,2.5,1.1,2.5,2.5C8.4,6.8,7.2,7.9,5.9,7.9z M6.8,5.5
              c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-1,0.4-1,0.9c0,0.5,0.4,0.9,1,1C6.3,6.4,6.8,6,6.8,5.5z"></path>
              <path d="M20.2,7.9c-1.4,0-2.5-1-2.5-2.4c0-1.4,1.1-2.6,2.5-2.6c1.4,0,2.5,1.1,2.5,2.5C22.7,6.8,21.6,7.9,20.2,7.9z M20.2,6.4
              c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-1,0.4-1,0.9C19.3,6,19.7,6.4,20.2,6.4z"></path>
              <g>
                <path d="M32,22.5c-3.8-1.7-7.6-2.1-11.3-1c-4.3,1.2-8.2,3.8-11.9,7.8c-0.4,0.4-0.4,0.9,0.1,1.4c2.2,2.3,4.6,4.2,7.1,5.6
                c3,1.7,5.9,2.5,8.9,2.6h0l0,0c0.3,0,0.5,0,0.8-0.1c0.5,0,1.1-0.1,1.6-0.2c2.5-0.4,5-1.3,7.5-2.9c2.3-1.4,4.4-3.2,6.2-5.2
                c0.4-0.5,0.4-0.9,0-1.3C38.3,26.1,35.3,23.9,32,22.5z M39.5,29.8c-1.2,1.3-2.5,2.4-4,3.5c-2.1,1.5-4.2,2.6-6.3,3.2
                c-3,0.9-6.1,0.9-9.2-0.3c-3.4-1.2-6.5-3.3-9.5-6.4c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.4,0.8-0.8,1.3-1.1c2.4-2.1,4.8-3.6,7.4-4.6
                c3.4-1.4,6.9-1.4,10.3-0.2C33.3,24.6,36.5,26.7,39.5,29.8z"></path>
                <path d="M25,24.8C25,24.8,25,24.8,25,24.8c-2.8,0-5.1,2.2-5.2,4.9c0,1.4,0.5,2.6,1.5,3.6c1,1,2.2,1.5,3.6,1.5c0,0,0,0,0,0
                c2.8,0,5.1-2.2,5.1-5c0-1.4-0.5-2.7-1.5-3.6C27.7,25.4,26.4,24.8,25,24.8z M25,33.2C25,33.2,25,33.2,25,33.2c-0.9,0-1.8-0.4-2.4-1
                c-0.7-0.7-1-1.6-1-2.5c0-0.9,0.4-1.7,1.1-2.4c0.7-0.6,1.5-1,2.4-1c0,0,0,0,0,0c1.9,0,3.5,1.6,3.5,3.4C28.5,31.7,26.9,33.2,25,33.2z
                "></path>
                <path d="M47.6,20.4L47.6,20.4L47.6,20.4c-0.2-0.3-0.4-0.4-0.7-0.4h-1.2l0-0.2c-0.5-4.2-4.1-7.4-8.4-7.4h-2c-0.4,0-0.7,0.3-0.7,0.7
                c0,0.4,0.3,0.7,0.7,0.7c3.1,0,4.8,2,5.1,5.9l0,0.2h-1.2c-0.3,0-0.5,0.2-0.7,0.4c-0.1,0.2-0.1,0.6,0.1,0.8l3.9,4.8
                c0.1,0.2,0.3,0.3,0.6,0.3s0.4-0.1,0.6-0.3l3.9-4.8C47.7,21,47.7,20.7,47.6,20.4z M45.4,21.5l-2.3,2.9l-2.3-2.9l0.4,0
                c0.4,0,0.7-0.3,0.7-0.7c0-2.3-0.4-4.2-1.3-5.6l-0.5-0.8l0.8,0.5c2.1,1.2,3.4,3.5,3.4,5.9c0,0.4,0.3,0.7,0.7,0.7L45.4,21.5z"></path>
                <path d="M14.7,45.8c-3.1,0-4.8-2.1-5.1-6l0-0.2h1.2c0.3,0,0.5-0.2,0.7-0.4c0.1-0.3,0.1-0.6-0.1-0.8l-3.9-4.8
                c-0.3-0.3-0.9-0.3-1.2,0l-3.9,4.8c-0.2,0.2-0.2,0.5-0.1,0.8c0.1,0.3,0.4,0.4,0.7,0.4h1.2l0,0.2c0.5,4.2,4.2,7.5,8.5,7.5h1.9
                c0.4,0,0.7-0.3,0.7-0.7S15.1,45.8,14.7,45.8z M8.8,38.1c-0.4,0-0.7,0.3-0.7,0.7c0,2.3,0.5,4.2,1.3,5.6l0.5,0.8l-0.8-0.5
                c-2.1-1.3-3.4-3.5-3.4-5.9c0-0.4-0.3-0.7-0.7-0.7l-0.4,0l2.3-2.8l2.3,2.8L8.8,38.1z"></path>
              </g>
            </svg>
          `
				},
        {
					title: 'Secure Data Storage',
					text: 'CMS web development helps you protect the user data while maintaining content privacy at the same time. It also provides an opportunity to set rules ensuring content editors to fill-in required information on the web to meet pre-defined accessibility standards.',
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M16.1,49.7l23.4,0l0.1,0c5.9-1.5,10.1-6.8,10.1-12.9v-9.1h-2.4c-2.8,0-5.4-1.1-7.4-3.1l-0.4-0.4l-0.4,0.4
              c-0.3,0.3-0.6,0.6-1.1,0.9l-0.4,0.3V25c0-1.2-1-2.2-2.2-2.2h-1.9V0.3H7L0.3,7v29.8H14v1.1c0,1.2,1,2.2,2.2,2.2h1.1v2.1h-1.1
              c-1.2,0-2.2,1-2.2,2.2v3.2C14,48.8,14.9,49.7,16.1,49.7z M39.3,25.9l0.2-0.1l0.2,0.1c2.1,1.8,4.8,2.9,7.6,2.9h1.3v8
              c0,5.5-3.7,10.4-9.1,11.8l-0.1,0l-0.1,0c-5.3-1.4-9.1-6.3-9.1-11.8v-8h1.3C34.5,28.8,37.2,27.8,39.3,25.9z M30.4,42.2H18.3v-2.1
              h11.4l0,0.2c0.1,0.5,0.3,1,0.5,1.6L30.4,42.2z M35.5,23.9c0.6,0,1.1,0.5,1.1,1.1v1.5l-0.1,0.1c-1.5,0.7-3,1.1-4.7,1.1h-2.4v1.6H16.1
              c-0.6,0-1.1-0.5-1.1-1.1V25c0-0.6,0.5-1.1,1.1-1.1H35.5z M29.3,30.4v2.1h-11v-2.1H29.3z M6.7,2.1v4.5H2.2L6.7,2.1z M14,34.7v1.1H1.4
              V7.8h6.4V1.4h24.7v21.5H16.1c-1.2,0-2.2,1-2.2,2.2v3.2c0,1.2,1,2.2,2.2,2.2h1.1v2.1h-1.1C14.9,32.5,14,33.5,14,34.7z M16.1,38.9
              c-0.6,0-1.1-0.5-1.1-1.1v-3.2c0-0.6,0.5-1.1,1.1-1.1h13.1v3.2c0,0.6,0,1.2,0.1,1.9l0,0.3H16.1z M15.1,44.3c0-0.6,0.5-1.1,1.1-1.1H31
              l0.1,0.1c1.1,1.9,2.7,3.6,4.6,4.7l0.8,0.5H16.1c-0.6,0-1.1-0.5-1.1-1.1V44.3z"></path>
              <path d="M39.5,44.9c1.2,0,2.2-1,2.2-2.2v-6.6l0.1-0.1c0.9-0.7,1.5-1.8,1.5-3c0-1.2-0.5-2.2-1.4-3c-0.7-0.5-1.5-0.8-2.4-0.8
              c-0.3,0-0.6,0-0.9,0.1c-1.4,0.3-2.4,1.4-2.8,2.7c-0.4,1.5,0.2,3,1.4,3.9l0.1,0.1v6.6C37.3,43.9,38.3,44.9,39.5,44.9z M36.9,32.4
              c0.2-0.9,1-1.7,1.9-1.9c0.8-0.2,1.7,0,2.3,0.5c0.6,0.5,1,1.3,1,2.1c0,1-0.5,1.8-1.3,2.3l-0.3,0.2v7.2c0,0.6-0.5,1.1-1.1,1.1
              c-0.6,0-1.1-0.5-1.1-1.1v-7.2l-0.3-0.2C37.1,34.7,36.6,33.6,36.9,32.4z"></path>
              <rect x="9.9" y="3.5" width="4.3" height="1.1"></rect>
              <rect x="9.9" y="6.7" width="4.3" height="1.1"></rect>
              <rect x="6.7" y="9.9" width="7.6" height="1.1"></rect>
              <rect x="3.5" y="13.1" width="10.8" height="1.1"></rect>
              <rect x="3.5" y="16.4" width="10.8" height="1.1"></rect>
              <rect x="3.5" y="19.6" width="23.7" height="1.1"></rect>
              <rect x="3.5" y="22.8" width="8.4" height="1.1"></rect>
              <rect x="3.5" y="26" width="8.4" height="1.1"></rect>
              <rect x="3.5" y="29.3" width="8.4" height="1.1"></rect>
              <rect x="3.5" y="32.5" width="8.4" height="1.1"></rect>
              <rect x="29.3" y="19.6" width="1.1" height="1.1"></rect>
              <rect x="3.5" y="9.9" width="1.1" height="1.1"></rect>
              <path d="M23.4,3.5c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S27.3,3.5,23.4,3.5z M23.4,16.4c-3.2,0-5.9-2.6-5.9-5.9s2.6-5.9,5.9-5.9
              s5.9,2.6,5.9,5.9S26.6,16.4,23.4,16.4z"></path>
            </svg>
          `
				},
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
