<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					Why Genixbit is the best choice for your Android Game Development
				</h2>
				<p>
					Genixbit has earned a big name in the Android Game development industry in the UK by
					spending years growing with the industry, and satisfying innumerable clientele in England,
					across Europe, and throughout the global.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<div class="game-why-us-inner-content-wrap">
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										d="M49.5,30.9c-0.3-0.1-0.7,0-0.9,0.2L45,34.8V12.6h0l3.4-3.7c0.3-0.3,0.3-0.9,0-1.2c0,0,0,0,0,0l-1.2-1.1l1.7-1.8
            c0.3-0.3,0.3-0.9,0-1.2l-3.7-3.4c-0.3-0.3-0.9-0.3-1.2,0L38,6.7H9.2c0-2.3-1.9-4.2-4.2-4.2H4.2C1.9,2.5,0,4.4,0,6.7v35.8
            c0,0.5,0.4,0.8,0.8,0.8h12.6l-1.8,2.1c-0.3,0.4-0.2,0.9,0.1,1.2l3.8,3.2c0.4,0.3,0.9,0.2,1.2-0.1l0,0l4.3-5.1
            c0.3-0.4,0.5-0.8,0.5-1.2h15.3l-5,5.3c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.1,0.4,0.2,0.6,0.2h16.7c0.5,0,0.8-0.4,0.8-0.8V31.7
            C50,31.3,49.8,31,49.5,30.9z M46.6,8.4L45,10.2V8.9l1-1.1L46.6,8.4z M43.3,8.3L43.3,8.3l-7,7.6l-2.4-2.3l8.5-9.2l2.4,2.3L43.3,8.3
            L43.3,8.3z M34.7,16.7l-1.9,0.6l0.4-2L34.7,16.7z M44.6,2L47,4.3l-1.1,1.2l-2.4-2.3L44.6,2z M4.2,4.2H5c1.4,0,2.5,1.1,2.5,2.5v31.7
            c-0.7-0.6-1.6-0.9-2.5-0.9H4.2c-0.9,0-1.8,0.3-2.5,0.9V6.7C1.7,5.3,2.8,4.2,4.2,4.2z M4.2,39.2H5c1.4,0,2.5,1.1,2.5,2.5H1.7
            C1.7,40.3,2.8,39.2,4.2,39.2z M19.8,43.5l-0.5,0.6L16.7,42l0.5-0.6c0.3-0.4,0.8-0.4,1.2-0.1c0,0,0,0,0,0l1.3,1.1
            C20.1,42.6,20.1,43.1,19.8,43.5C19.8,43.5,19.8,43.5,19.8,43.5L19.8,43.5z M16.1,48l-2.6-2.1l2.1-2.6l2.6,2.1L16.1,48z M21.3,41.7
            c-0.1-0.2-0.3-0.4-0.5-0.6L19.5,40c-1.1-0.9-2.6-0.7-3.5,0.3l-1.1,1.4H9.2V8.3h27.3L32,13.1c-0.1,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0.1
            c0,0,0,0,0,0.1l-1,4.8c-0.1,0.5,0.2,0.9,0.6,1c0.1,0,0.3,0,0.4,0l4.7-1.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2
            l6.4-6.9v25.8l-4.8,5.1H21.3z M48.3,48.3H34.4l13.9-14.6V48.3z"
									></path>
									<path
										d="M39.2,46.1c0.1,0.3,0.4,0.5,0.8,0.5h5.8c0.5,0,0.8-0.4,0.8-0.8V40c0-0.5-0.4-0.8-0.8-0.8c-0.2,0-0.4,0.1-0.6,0.2l-5.8,5.8
            C39.2,45.5,39.1,45.8,39.2,46.1z M45,42v3h-3L45,42z"
									></path>
									<path
										d="M36.7,31.7h-5.8v-9.2c0-0.2-0.1-0.4-0.2-0.6l-5-5c-0.3-0.3-0.9-0.3-1.2,0l-5,5c-0.2,0.2-0.2,0.4-0.2,0.6v9.2h-5.8
            c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h23.3c0.5,0,0.8-0.4,0.8-0.8C37.5,32,37.1,31.7,36.7,31.7z M25,18.7l3,3h-6L25,18.7z
            M20.8,23.3h8.3v8.3h-8.3V23.3z"
									></path>
									<path d="M24.2,13.3h1.7V15h-1.7V13.3z"></path>
									<path d="M24.2,35h1.7v1.7h-1.7V35z"></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="one">
								<h3>High-end Game Designing Infrastructure</h3>
								<p>
									Our game studios are equipped with the ultramodern game development
									infrastructural facilities to provide one-stop solutions for all game development
									stage, to avoid our Client’s have a single provider for their project.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25C50,11.2,38.8,0,25,0z M25,48.3C12.1,48.3,1.7,37.9,1.7,25
            S12.1,1.7,25,1.7S48.3,12.1,48.3,25C48.3,37.9,37.9,48.3,25,48.3z"
									></path>
									<path
										d="M44.1,24.1V24c0-0.3,0-0.5-0.1-0.8c0-0.3-0.1-0.5-0.1-0.8c0-0.2-0.1-0.4-0.1-0.6c0-0.3-0.1-0.5-0.1-0.8l0-0.1
            c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.5-0.3-1.1-0.5-1.7c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.2-0.6-0.4-0.8
            c-0.1-0.2-0.2-0.5-0.4-0.7c-0.3-0.6-0.6-1.1-1-1.7l0,0v0c0,0,0,0,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5l-0.1-0.2c-0.2-0.2-0.3-0.4-0.6-0.7
            c0,0-0.1-0.1-0.1-0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.1-0.2-0.2l1.5-1.5l-1.2-1.2L38,10.9c-0.1-0.1-0.1-0.1-0.2-0.2
            c-0.1-0.1-0.3-0.3-0.5-0.4c0,0,0,0-0.1,0c0,0-0.1,0-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.5l0,0l-0.2-0.1c-0.2-0.1-0.4-0.3-0.6-0.4
            c-0.5-0.4-1.1-0.7-1.7-1c-0.2-0.1-0.5-0.3-0.7-0.4c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.1-0.5-0.2-0.7-0.3C31.6,7,31.5,7,31.3,6.9
            c-0.6-0.2-1.2-0.4-1.7-0.5c-0.2-0.1-0.4-0.1-0.7-0.2c-0.2-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.8-0.1
            c-0.3,0-0.5,0-0.8-0.1c-0.6,0-1.3,0-1.9,0H24c-0.3,0-0.5,0-0.8,0.1C22.9,6,22.7,6,22.4,6c-0.2,0-0.4,0.1-0.6,0.1
            c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0-0.4,0.1-0.6,0.1c-0.5,0.1-1.1,0.3-1.7,0.5C18.5,7,18.4,7,18.2,7.1c-0.2,0.1-0.5,0.2-0.7,0.3
            c-0.3,0.1-0.6,0.2-0.8,0.4C16.5,7.9,16.2,8,16,8.1c-0.1,0-0.2,0.1-0.3,0.1c-0.5,0.3-1,0.6-1.4,0.9h0l-0.1,0.1
            c-0.2,0.1-0.3,0.2-0.5,0.3l-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c0,0,0,0-0.1,0l-0.1,0.1
            c-0.1,0.1-0.3,0.3-0.5,0.4c-0.5,0.5-1,1-1.5,1.5c-0.1,0.2-0.3,0.3-0.4,0.5c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.3,0.4-0.5,0.6l0,0v0
            l-0.1,0.2c-0.1,0.2-0.3,0.4-0.4,0.6c-0.4,0.5-0.7,1.1-1,1.7c-0.1,0.2-0.3,0.5-0.4,0.7c-0.1,0.3-0.3,0.6-0.4,0.8
            c-0.1,0.2-0.2,0.5-0.3,0.7C7,18.4,7,18.5,6.9,18.7c-0.2,0.6-0.4,1.2-0.5,1.7c-0.1,0.2-0.1,0.4-0.1,0.6l0,0.1
            c-0.1,0.3-0.1,0.5-0.1,0.7C6.1,22,6,22.2,6,22.4c0,0.3-0.1,0.5-0.1,0.8c0,0.3,0,0.5-0.1,0.8c0,0.3,0,0.6,0,1s0,0.6,0,0.9V26
            c0,0.3,0,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.8c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0.1,0.5,0.1,0.8l0,0.1c0,0.2,0.1,0.4,0.1,0.6
            c0.1,0.5,0.3,1.1,0.5,1.7c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.3,0.2,0.6,0.4,0.8C7.8,33.5,8,33.8,8.1,34
            c0.1,0.2,0.2,0.4,0.3,0.6l0.4,0.7l6.5-3.8l-0.4-0.7c-2.1-3.6-2.1-8.1,0-11.7c1-1.8,2.5-3.2,4.3-4.3c3.6-2,8-2,11.7,0
            c0.6,0.4,1.2,0.8,1.8,1.3l-5.3,5.3c-1.8-1.2-4.2-0.8-5.5,0.8C20.4,24,20.5,26.4,22,28s3.9,1.6,5.6,0.3c1.7-1.4,2-3.8,0.8-5.5
            l5.3-5.3c3.3,3.7,3.8,9.2,1.3,13.5l-0.4,0.7l6.5,3.8l0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.3-0.5,0.4-0.7
            c0.1-0.3,0.3-0.6,0.4-0.8c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.3,0.2-0.5c0.2-0.6,0.4-1.2,0.5-1.7c0.1-0.2,0.1-0.4,0.1-0.6l0-0.1
            c0.1-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.5,0.1-0.8c0-0.3,0-0.5,0.1-0.8c0-0.3,0-0.6,0-1S44.2,24.4,44.1,24.1z
            M42,20.8c0,0.2,0.1,0.4,0.1,0.5l0,0.1c0,0.2,0.1,0.4,0.1,0.7c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0.1,0.5,0.1,0.7c0,0.1,0,0.3,0,0.4
            c0,0.1,0,0.2,0,0.3v0.1v0h-4.2c-0.1-1.8-0.6-3.5-1.4-5.1l3.6-2.1c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.2,0.2,0.5,0.3,0.8
            c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.1,0.1,0.3,0.2,0.4C41.7,19.8,41.9,20.3,42,20.8z M26.7,7.6c0.2,0,0.5,0,0.7,0.1c0.2,0,0.3,0,0.5,0.1
            c0.2,0,0.4,0.1,0.7,0.1C28.8,7.9,29,8,29.2,8c0.5,0.1,1,0.3,1.6,0.5c0.1,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.2
            c0.3,0.1,0.5,0.2,0.8,0.4c0.1,0.1,0.3,0.1,0.4,0.2l-2.1,3.6c-1.6-0.8-3.3-1.2-5.1-1.3V7.5h0.1C26.2,7.5,26.4,7.6,26.7,7.6z
            M18.8,8.6c0.1,0,0.3-0.1,0.4-0.1c0.6-0.2,1.1-0.3,1.6-0.5C21,8,21.2,7.9,21.4,7.9c0.2,0,0.4-0.1,0.7-0.1c0.2,0,0.3-0.1,0.5-0.1
            c0.2,0,0.5-0.1,0.7-0.1c0.2,0,0.5,0,0.7-0.1h0.1v4.2c-1.8,0.1-3.5,0.6-5.1,1.3L17,9.4c0.1-0.1,0.3-0.1,0.4-0.2
            c0.2-0.1,0.5-0.2,0.8-0.3C18.4,8.8,18.6,8.7,18.8,8.6L18.8,8.6z M7.7,22.6c0-0.2,0-0.3,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6l0-0.1
            C7.9,21.1,8,21,8,20.8c0.1-0.5,0.3-1,0.5-1.6c0-0.1,0.1-0.3,0.2-0.4c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.5,0.4-0.8
            c0.1-0.1,0.1-0.3,0.2-0.4l3.6,2.1c-0.8,1.6-1.3,3.3-1.4,5.1H7.5c0,0,0-0.1,0-0.1c0-0.2,0-0.5,0.1-0.7C7.6,23.1,7.6,22.9,7.7,22.6z
            M9.4,33c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.2-0.2-0.5-0.3-0.8c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.1-0.1-0.3-0.2-0.4
            c-0.2-0.6-0.3-1.1-0.5-1.6c0-0.2-0.1-0.4-0.1-0.5l0-0.1c0-0.2-0.1-0.4-0.1-0.7c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.5-0.1-0.7
            c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3v-0.1c0,0,0,0,0,0h4.2c0.1,1.8,0.6,3.5,1.3,5.1L9.4,33z M13.9,17.6l-3.6-2.1
            c0.1-0.1,0.1-0.2,0.2-0.3l0,0c0.1-0.2,0.3-0.4,0.5-0.7c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.4
            c0.4-0.5,0.9-0.9,1.4-1.4c0.1-0.1,0.3-0.2,0.4-0.3l0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.4-0.3,0.6-0.5c0.2-0.2,0.5-0.3,0.7-0.5
            c0.1-0.1,0.2-0.1,0.3-0.2l2.1,3.6C16.1,14.9,14.9,16.1,13.9,17.6z M32.4,13.9l2.1-3.6c0.1,0.1,0.2,0.1,0.3,0.2
            c0.2,0.2,0.5,0.3,0.7,0.5c0.2,0.1,0.4,0.3,0.5,0.4c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.4
            c0,0,0.1,0.1,0.1,0.1l-3,3C33.4,14.6,32.9,14.2,32.4,13.9z M27.5,25c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
            c0.4,0,0.7,0.1,1.1,0.2l-0.6,0.6c-0.1,0-0.3-0.1-0.4-0.1c-0.9,0-1.7,0.7-1.7,1.7s0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7
            c0-0.1,0-0.3-0.1-0.4l0.6-0.6C27.4,24.3,27.5,24.6,27.5,25z M35,16.2l3-3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.3,0.4
            c0.1,0.1,0.1,0.2,0.2,0.2c0.2,0.2,0.3,0.4,0.5,0.6l0,0c0.2,0.2,0.3,0.4,0.5,0.7c0.1,0.1,0.1,0.2,0.2,0.3l-3.6,2.1
            C35.8,17.1,35.4,16.6,35,16.2z M42.3,27.4c0,0.2,0,0.3-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6l0,0.1c0,0.2-0.1,0.4-0.1,0.6
            c-0.1,0.5-0.3,1-0.5,1.6c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.4
            l-3.6-2.1c0.8-1.6,1.2-3.3,1.3-5.1h4.2v0.1c0,0.2,0,0.5-0.1,0.7C42.4,26.9,42.4,27.1,42.3,27.4L42.3,27.4z"
									></path>
									<path d="M15.8,39.2h18.3v-5H15.8V39.2z M17.5,35.8h15v1.7h-15V35.8z"></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="two">
								<h3>Keep Game Performance at the Core</h3>
								<p>
									We understand game development and what Clients want, so we keep the game
									performance at the core while defining the game quality. Thus, we deliver high
									quality games in the hands of our global patrons to win the hearts of their
									targeted audience.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<path
											d="M39.4,19.8c-0.1-7.2-5.8-13.1-13-13.5c-3.9-0.2-7.5,1.1-10.3,3.8c-2.7,2.6-4.3,6.1-4.3,9.8c0,2.6,0.8,5.2,2.2,7.4
              c4.4,6.7,4.4,8.6,4.3,15.7c0,0.6,0,1.3,0,2c0,1.2,1,2.2,2.2,2.2h9c1.2,0,2.2-1,2.2-2.2l0-1.4c0-6.3,0-8.5,5.2-16.1
              C38.6,25.3,39.4,22.6,39.4,19.8z M28.6,22v13.8h-2.7V22H28.6z M28.6,37.4v3h-7.1v-3H28.6z M21.5,35.8V22h2.7v13.8H21.5z M22.4,20.4
              l2.7-3.5l2.7,3.5H22.4z M30,20.6l-4.3-5.6c-0.1-0.2-0.4-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3l-4.2,5.5c-0.2,0.2-0.3,0.4-0.3,0.6
              v18.3c-0.1-4.5-0.8-7.2-4.6-13.1c-1.3-1.9-1.9-4.2-1.9-6.5c0-3.3,1.3-6.4,3.8-8.7c2.5-2.3,5.7-3.5,9.1-3.3
              c6.4,0.3,11.4,5.6,11.5,11.9c0,2.5-0.7,4.8-2.1,6.9c-3.2,4.6-5.2,7.9-5.5,13.3V21.2C30.2,21,30.1,20.8,30,20.6z M19.9,44.7h6.1
              c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-6.1v-1h10.3v3c0,0.3-0.3,0.6-0.6,0.6h-9c-0.3,0-0.6-0.3-0.6-0.6V44.7z"
										></path>
										<path
											d="M30.8,47.8H19.3c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h11.5c0.4,0,0.8-0.4,0.8-0.8S31.3,47.8,30.8,47.8z"
										></path>
										<path
											d="M35,19.9c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8c0-6.1-4.9-11-11-11c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8
              C30.8,10.5,35,14.8,35,19.9z"
										></path>
										<path
											d="M25.6,5.1c0.4,0,0.8-0.4,0.8-0.8V1.4c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8v2.9C24.8,4.8,25.1,5.1,25.6,5.1z"
										></path>
										<path
											d="M19.4,5.5c0.1,0.3,0.4,0.5,0.8,0.5c0.1,0,0.2,0,0.3,0l0,0c0.3-0.1,0.7-0.4,0.4-1.2L20,2.2c-0.2-0.4-0.6-0.6-1-0.5
              c-0.4,0.2-0.6,0.6-0.5,1L19.4,5.5z"
										></path>
										<path
											d="M14.8,8.5c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2h0c0.3-0.3,0.4-0.8,0.1-1.1l-1.9-2.2c-0.3-0.3-0.8-0.4-1.1-0.1
              c-0.3,0.3-0.4,0.8-0.1,1.1L14.8,8.5z"
										></path>
										<path
											d="M9,11.4l2.5,1.5c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.3,0.3-0.8-0.4-1.2L9.8,10
              C9.6,9.9,9.4,9.9,9.2,9.9C9,10,8.8,10.1,8.7,10.3C8.5,10.7,8.6,11.2,9,11.4z"
										></path>
										<path
											d="M42.2,28.7l-2.5-1.5c-0.4-0.2-0.9-0.1-1.1,0.3c-0.2,0.4-0.1,0.9,0.3,1.1l2.5,1.5c0.1,0.1,0.3,0.1,0.4,0.1
              c0.1,0,0.1,0,0.2,0c0.2-0.1,0.4-0.2,0.5-0.4C42.7,29.4,42.6,28.9,42.2,28.7z"
										></path>
										<path
											d="M7,17.6l2.9,0.5c0,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.5-0.1c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2,0-0.4-0.1-0.6
              c-0.1-0.2-0.4-0.3-0.8-0.4L7.3,16c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.3-0.3,0.5c0,0.2,0,0.4,0.1,0.6C6.6,17.5,6.8,17.6,7,17.6z"
										></path>
										<path
											d="M44.1,22.5L41.2,22c-0.4-0.1-0.9,0.2-0.9,0.7c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.3,0.3,0.5,0.3l2.9,0.5c0.1,0,0.1,0,0.1,0
              c0.4,0,0.7-0.3,0.8-0.7c0-0.2,0-0.4-0.1-0.6C44.5,22.7,44.3,22.6,44.1,22.5z"
										></path>
										<path
											d="M10.7,23.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.2-0.4-0.3-0.5C10.4,22,10.1,22,9.9,22L7,22.5c-0.2,0-0.4,0.2-0.5,0.3
              c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0l2.7-0.5C10.4,23.6,10.6,23.4,10.7,23.2z
              "
										></path>
										<path
											d="M40.4,16.9c-0.1,0.2-0.2,0.4-0.1,0.6c0,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0l2.9-0.5
              c0.2,0,0.4-0.2,0.5-0.3c0.1-0.2,0.2-0.4,0.1-0.6c0-0.2-0.2-0.4-0.3-0.5C44.3,16,44.1,16,43.9,16L41,16.5
              C40.8,16.6,40.6,16.7,40.4,16.9z"
										></path>
										<path
											d="M12.1,27.2c-0.2-0.1-0.4,0-0.6,0.1L9,28.7c-0.4,0.2-0.5,0.7-0.3,1.1C8.8,30,9,30.1,9.2,30.2c0.1,0,0.1,0,0.2,0
              c0.1,0,0.3,0,0.4-0.1l2.1-1.2l0,0l0,0l0.4-0.2l0,0c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0-0.4-0.1-0.6C12.5,27.4,12.4,27.2,12.1,27.2z"
										></path>
										<path
											d="M39.2,13c0.1,0,0.3,0,0.4-0.1l2.5-1.5c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.3-0.3-0.5-0.4
              c-0.2-0.1-0.4,0-0.6,0.1l-2.5,1.5c-0.4,0.2-0.5,0.7-0.3,1.1C38.7,12.9,39,13,39.2,13z"
										></path>
										<path
											d="M35.7,8.8c0.2,0,0.5-0.1,0.6-0.3l1.9-2.2c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.3-0.9-0.2-1.1,0.1
              l-1.9,2.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.3,0.6C35.3,8.7,35.5,8.8,35.7,8.8z"
										></path>
										<path
											d="M30.7,6c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.3,0.4-0.5l1-2.8c0.1-0.2,0.1-0.4,0-0.6
              c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.3,0.3-0.4,0.5l-1,2.8c-0.1,0.2-0.1,0.4,0,0.6
              C30.3,5.8,30.5,5.9,30.7,6z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="three">
								<h3>Effective Game Monetization</h3>
								<p>
									We look at game monetization opportunities right from the conception to deployment
									and throughout the project lifecycle. We present innovative ideas, strategies, and
									methodologies for Android game monetization understanding that this is an
									investment, not just a hobby.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M21.7,15.8c-1.4,0-2.5,1.1-2.5,2.5h1.7c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8h1.7C24.2,17,23,15.8,21.7,15.8z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M28.3,15.8c-1.4,0-2.5,1.1-2.5,2.5h1.7c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8h1.7C30.8,17,29.7,15.8,28.3,15.8z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M25,38.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C28.3,39.8,26.8,38.3,25,38.3z M25,43.3
                c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7C26.7,42.6,25.9,43.3,25,43.3z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<path
												d="M45,48.3V37.5C45,30.6,39.4,25,32.5,25h-0.9c0.3-0.3,0.6-0.5,0.8-0.8h0.9c2.3,0,4.2-1.9,4.2-4.2v-1.5
                c1-0.7,1.7-1.8,1.7-3.1c0-1.3-0.7-2.4-1.7-3.1C37.4,5.5,31.8,0,25,0S12.6,5.5,12.5,12.3c-1,0.7-1.7,1.8-1.7,3.1
                c0,2.1,1.7,3.7,3.8,3.7h0.6c0.4,2.3,1.6,4.3,3.3,5.8h-0.9C10.6,25,5,30.6,5,37.5v10.8H0V50h5.8H10h30h4.2H50v-1.7H45z M35.8,20
                c0,1.3-0.9,2.3-2.2,2.5c0.6-1,1-2.1,1.2-3.3h0.6c0.1,0,0.3,0,0.4,0V20z M37.5,15.4c0,1.1-0.9,2.1-2.1,2.1H35v-4.2h0.4
                C36.6,13.3,37.5,14.3,37.5,15.4z M15,17.5h-0.4c-1.1,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1H15V17.5z M14.6,11.7c-0.1,0-0.3,0-0.4,0
                c0.4-5.6,5.1-10,10.8-10s10.4,4.4,10.8,10c-0.1,0-0.2,0-0.4,0h-0.6c-0.8-4.7-4.9-8.3-9.8-8.3s-9.1,3.6-9.8,8.3H14.6z M33.3,13.3
                c-6-0.2-10-2.7-10-5c0-0.5-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8c0,2.1-2.2,3.8-5,4.1C17.2,8.3,20.7,5,25,5C29.6,5,33.3,8.7,33.3,13.3
                z M16.7,17.5v-3.4c2.6-0.2,4.8-1.5,5.9-3.2c1.7,2.3,5.8,3.9,10.8,4.1v2.5c0,1.9-0.6,3.6-1.7,5H25v1.7h5c-0.5,0.4-1,0.7-1.5,0.9
                c-2.2,1-4.7,1-6.9,0C18.7,23.8,16.7,20.9,16.7,17.5z M22.4,27.2c0.8,0.2,1.7,0.3,2.6,0.3s1.8-0.1,2.6-0.3L25,32.3L22.4,27.2z
                M23.7,33.3h-1.5l-3.3-6.7h1.5L23.7,33.3z M29.7,26.7h1.5l-3.3,6.7h-1.5L29.7,26.7z M9.2,37.5v10.8H6.7V37.5
                c0-5.8,4.6-10.5,10.3-10.8l3.3,6.6h-7C11,33.3,9.2,35.2,9.2,37.5z M39.2,48.3H10.8V37.5c0-1.4,1.1-2.5,2.5-2.5h8.3H25h3.3h8.3
                c1.4,0,2.5,1.1,2.5,2.5V48.3z M43.3,48.3h-2.5V37.5c0-2.3-1.9-4.2-4.2-4.2h-7l3.3-6.6c5.7,0.3,10.3,5,10.3,10.8V48.3z"
											></path>
										</g>
									</g>
									<g>
										<g>
											<rect x="35.8" y="40.8" width="1.7" height="5.8"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="35.8" y="37.5" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="43.3" y="7.5" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="43.3" y="10.8" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="43.3" y="14.2" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="5" y="17.5" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="5" y="20.8" width="1.7" height="1.7"></rect>
										</g>
									</g>
									<g>
										<g>
											<rect x="5" y="24.2" width="1.7" height="1.7"></rect>
										</g>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="four">
								<h3>Uninterrupted Support</h3>
								<p>
									We believe in long-term relationships with clients so always come up with quick
									response to queries, maintenance requests, and updates &amp; upgrade requirements
									to ensure you stay with us. Our Hire Android game developer packages are flexible
									and comprehensive.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-choose-us-game.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.game-why-us-inner-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 0;
}
.game-why-us-inner-box:hover h3,
.game-why-us-inner-box:hover p {
	color: #fff !important;
}
.game-why-us-inner-box:hover {
	background: #008dd2;
}

.game-why-us-inner-box:hover .game-why-us-inner-icon svg circle,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg ellipse,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg path,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg polygon,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg rect {
	fill: #008dd2;
}

.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}

.game-why-us-inner-icon svg circle,
.game-why-us-inner-icon svg ellipse,
.game-why-us-inner-icon svg path,
.game-why-us-inner-icon svg polygon,
.game-why-us-inner-icon svg rect {
	fill: black;
}

.game-why-us-inner-icon svg {
	width: 50px;
	height: 50px;
	fill: black;
}
.game-why-us-inner-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	min-height: 90px;
	border-radius: 100%;
	background-color: #fff;
	border: 1px solid #e0e2e4;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
