<template>
	<section class="padding-60 bg-light partnership-main">
		<div class="container">
			<div class="title">
				<span>Choose What Suits You Best</span>
				<h2 class="fxt-engagement">Choose An Engagement Model Of Your Choice</h2>
				<p>
					GenixBit Labs offers three engagement models in web and mobile app development:
					part-time hiring, full-time hiring and hourly hiring. Our engagement models are
					designed to give you maximum flexibility while helping you meet your resource
					requirements.
				</p>
			</div>
			<div class="row mt-5 hire-col">
				<div class="col-12 col-sm-12 col-lg-6">
					<div class="row justify-content-center hiring-dad">
						<div class="col-4 col-md-4 hire_box-container">
							<div class="hire_box">
								<img
									class="lazy"
									alt="Hire a part time mobile app developer"
									src="../../assets/images/part-time.svg"
									style=""
									width="133"
									height="166"
								/>
								<span>Part-time Hiring</span>
							</div>
						</div>
						<div class="col-4 col-md-4 hire_box-container">
							<div class="hire_box">
								<img
									class="lazy"
									alt="Hire a full time mobile app developer in UK"
									src="../../assets/images/full-time.svg"
									style=""
									width="133"
									height="166"
								/>
								<span>Full-time Hiring</span>
							</div>
						</div>
						<div class="col-4 col-md-4 hire_box-container">
							<div class="hire_box">
								<img
									class="lazy"
									alt="Hire a dedicated mobile app developer in UK"
									src="../../assets/images/hourly.svg"
									style=""
									width="133"
									height="166"
								/>
								<span>Hourly Hiring</span>
							</div>
						</div>
					</div>
					<center class="mt-lg-5 mt-3">
						<a
							class="btn-custome btn btn-lg waves-effect waves-light head-requet-btn"
							href="javascript:;"
							>Let’s Discuss Your Requirement</a
						>
					</center>
				</div>
				<div class="col-12 col-sm-12 col-lg-6 text-center d-none d-lg-flex">
					<img
						class="lazy rounded10 box-shadow"
						alt="Understand the partnership model of best mobile application development companies and work on as per the need of your project requirement and budget"
						src="../../assets/images/Partnership.png"
						style=""
						width="549"
						height="348"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.btn-custome.btn-lg {
	padding: 15px 40px;
	font-size: 20px;
}
.hire_box {
	width: 100%;
	background: #fff;
	border: 1px solid #e0e2e4;
	border-radius: 10px;
	padding: 15px 15px;
	text-align: center;
	font-size: 16px;
	min-height: 250px;
}
.hiring-dad > div {
	font-size:12px !important;
}
.hire_box span {
		margin-top: 15px !important;
		display:block;
		font-size:15px
	}
@media (max-width: 576px) {
	.fxt-engagement {
		font-size:20px !important;
		font-weight:bold !important;
	}
	.hire_box {
		min-height: 183px !important;
		font-size:12px !important;
		padding: 10px 5px !important;
		text-align:center !important;
	}
	.hire_box span {
		margin-top: 10px !important;
		display:block;
	}
	.head-requet-btn {
		font-size:14px !important;
		width: fit-content !important;
		margin-top:20px;
	}
	.hire_box-container {
		padding:10px 5px !important
	}
	.hiring-dad {
		padding: 0 7px
	}
}
</style>
