<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/augmented-reality-app-development.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">Top VR App Development Company in the UK</h2>
					<p>

The technology is believed to bridge the gap between the real and virtual world market and user expectations. At Genixbit, we ensure the flawless integration of virtual reality app development technology in your business to assist you to engage your customers impeccably.
					</p>
					<p>
						The future is here, VR app development solutions are speculated to be the key to drive online business. At GenixBit, we have a team of experienced VR app developers and analysts, who integrate virtual reality app development technology in your business after closely analyzing your industry, competitors, and the target audience to whom you wish to showcase your product and services.
					</p>
					<p>
						Using the tried and tested modus operandi, our VR app developers team leaves no stone unturned to build distinct and custom VR app development services for your business.
					</p>
					<p>
						From a simple product presentation to the complicated manufacturing process or the next-level VR app development, we have a solution to all your requirements.
					</p>
					<p>
						Have queries or want to know more about our virtual reality app development services?
					</p>
					<p>
						Don’t hesitate!! Contact us today and get answers to all your virtual reality app development queries.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
