<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Staying a Notch Above Your Competitors</span>
				<h2 class="fxt-process-dev">ERP Software Development for Business</h2>
				<p>
					Businesses need to keep a check on competition and if boils down to efficiency then your
					business certainly needs ERP software development services. Drive business growth and stay
					ahead of competition by choosing your ERP technology partner.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<BigWidthCard v-bind:obj="item"></BigWidthCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard';

export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Optimised Inventory Management',
					text: "Try optimizing inventory management with ERP integration to experience more visibility and availing inventory control. Custom ERP software development offers to manage thousands of products in real-time while bettering delivery standards at the output.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <polygon points="11.3,17.7 4.8,17.7 4.8,10.5 7.3,10.5 7.3,8.9 3.2,8.9 3.2,19.4 12.9,19.4 12.9,14.5 11.3,14.5  "></polygon>
                <polygon points="8.1,13.4 7,12.3 5.9,13.5 8.1,15.7 14.3,9.4 13.1,8.3  "></polygon>
                <path d="M33.1,38.7c-2.2,0-4,1.8-4,4s1.8,4,4,4c2.2,0,4-1.8,4-4S35.3,38.7,33.1,38.7z M33.1,45.2c-1.3,0-2.4-1.1-2.4-2.4
                s1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4S34.4,45.2,33.1,45.2z"></path>
                <path d="M47.7,4.8l-8.8-1.8l-3.6,16.2l-17.5-3.5V0H5.3L0,5.3v17.3h8.9L6.6,33.1c-1,0.2-1.8,0.9-2.1,2l-0.4,1.6
                c0,0.2-0.1,0.4-0.1,0.6c0,1.2,0.9,2.2,2,2.5l19.8,3.7c0.3,3.7,3.4,6.6,7.2,6.6c4,0,7.3-3.3,7.3-7.3c0-1.2-0.3-2.3-0.8-3.3l4-17.8
                L41,16.5l1.6-6.9l3.8,0.8c0.2,0,0.4,0.1,0.6,0.1h0.2c1.6,0,2.8-1.3,2.8-2.8C50,6.3,49,5.1,47.7,4.8z M21.5,18.2l2.4,0.5l-1.1,3.8
                L20.4,22L21.5,18.2z M25.5,19l2.4,0.5l-1.1,3.8l-2.4-0.5L25.5,19z M4.8,2.8v2.1H2.8L4.8,2.8z M1.6,6.5h4.8V1.6h9.7V21H1.6V6.5z
                M17.7,22.6v-5.1l2.1,0.4l-1.5,5.3l9.7,1.9l1.5-5.3l5.4,1.1l-3.3,14.8c-1.2,0.3-2.3,0.8-3.3,1.6l-20.1-4l2.3-10.7H17.7z M5.6,37.3
                c0-0.1,0-0.1,0-0.2l0.4-1.6c0.1-0.5,0.7-0.9,1.2-0.8l19.8,4c-0.6,0.9-1,2-1.2,3.1L6.4,38.2C5.9,38.1,5.6,37.7,5.6,37.3z M33.1,48.4
                c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6S36.2,48.4,33.1,48.4z M36.5,36.3l1.1-4.6l2,0.4l-1.3,5.6
                C37.8,37.2,37.2,36.7,36.5,36.3z M39.2,24.3l2,0.4l-1.3,5.8l-2-0.4L39.2,24.3z M41.9,21.9l-0.3,1.3l-2-0.4l0.9-3.7L41.9,21.9z
                M35,35.7c-0.5-0.1-1.1-0.2-1.7-0.3l6-26.5L41,9.3L35,35.7z M47.2,8.9H47c-0.1,0-0.2,0-0.2,0l-7.2-1.4L40.1,5l7.3,1.5
                c0.6,0.1,1,0.6,1,1.2C48.4,8.3,47.8,8.9,47.2,8.9z"></path>
              </g>
            </svg>
        `
				},
				{
					title: "Drives Planning",
					text: "Manage and analyse business data by understanding crucial data points to improve the planning processes. Integrating ERP in planning breeds data-aware department heads who can manage resources efficiently adhering to delivery deadlines.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <path d="M46.6,9.6l-9.4-9.4C37.1,0.1,36.9,0,36.7,0H3.9C3.5,0,3.1,0.3,3.1,0.8v48.4c0,0.4,0.3,0.8,0.8,0.8h42.2
                c0.4,0,0.8-0.3,0.8-0.8V10.2C46.9,9.9,46.8,9.7,46.6,9.6z M37.5,2.7l6.7,6.7h-6.7V2.7z M45.3,48.4H4.7v-2.3h40.6V48.4z M45.3,44.5
                H4.7v-43h31.2v8.6c0,0.4,0.3,0.8,0.8,0.8h8.6V44.5z"></path>
                <path d="M8.6,10.9h4.7c0.4,0,0.8-0.3,0.8-0.8V5.5c0-0.4-0.3-0.8-0.8-0.8H8.6C8.2,4.7,7.8,5,7.8,5.5v4.7C7.8,10.6,8.2,10.9,8.6,10.9
                z M9.4,6.3h3.1v3.1H9.4V6.3z"></path>
                <rect x="15.6" y="5.5" width="10.9" height="1.6"></rect>
                <rect x="15.6" y="8.6" width="1.6" height="1.6"></rect>
                <rect x="18.8" y="8.6" width="1.6" height="1.6"></rect>
                <rect x="38.3" y="26.6" width="1.6" height="1.6"></rect>
                <rect x="38.3" y="36.7" width="1.6" height="1.6"></rect>
                <path d="M35.2,24.2v2.3h-2.3v-2.3c0-0.4-0.3-0.8-0.8-0.8h-6.2v-2.3H32c0.4,0,0.8-0.3,0.8-0.8v-6.2c0-0.4-0.3-0.8-0.8-0.8H18
                c-0.4,0-0.8,0.3-0.8,0.8v6.2c0,0.4,0.3,0.8,0.8,0.8h6.2v2.3H18c-0.4,0-0.8,0.3-0.8,0.8v6.2c0,0.4,0.3,0.8,0.8,0.8h6.2v2.3H18
                c-0.4,0-0.8,0.3-0.8,0.8v2.3h-2.3v-2.3c0-0.4-0.3-0.8-0.8-0.8h-2.3v-2.3h2.3c0.4,0,0.8-0.3,0.8-0.8v-6.2c0-0.4-0.3-0.8-0.8-0.8H7.8
                c-0.4,0-0.8,0.3-0.8,0.8v6.2c0,0.4,0.3,0.8,0.8,0.8h2.3v2.3H7.8c-0.4,0-0.8,0.3-0.8,0.8v6.2c0,0.4,0.3,0.8,0.8,0.8h6.2
                c0.4,0,0.8-0.3,0.8-0.8v-2.3h2.3v2.3c0,0.4,0.3,0.8,0.8,0.8H32c0.4,0,0.8-0.3,0.8-0.8v-6.2c0-0.4-0.3-0.8-0.8-0.8h-6.2v-2.3H32
                c0.4,0,0.8-0.3,0.8-0.8v-2.3h2.3v2.3c0,0.4,0.3,0.8,0.8,0.8h2.3v2.3h-2.3c-0.4,0-0.8,0.3-0.8,0.8v6.2c0,0.4,0.3,0.8,0.8,0.8h6.2
                c0.4,0,0.8-0.3,0.8-0.8v-6.2c0-0.4-0.3-0.8-0.8-0.8h-2.3v-2.3h2.3c0.4,0,0.8-0.3,0.8-0.8v-6.2c0-0.4-0.3-0.8-0.8-0.8h-6.2
                C35.5,23.4,35.2,23.8,35.2,24.2z M8.6,29.7V25h4.7v4.7H8.6z M13.3,39.8H8.6v-4.7h4.7V39.8z M18.8,19.5v-4.7h12.5v4.7H18.8z
                M31.2,35.2v4.7H18.8v-4.7H31.2z M31.2,29.7H18.8V25h12.5V29.7z M41.4,35.2v4.7h-4.7v-4.7H41.4z M36.7,25h4.7v4.7h-4.7V25z"></path>
                <rect x="10.2" y="36.7" width="1.6" height="1.6"></rect>
                <rect x="20.3" y="16.4" width="1.6" height="1.6"></rect>
                <rect x="23.4" y="16.4" width="6.2" height="1.6"></rect>
                <rect x="20.3" y="26.6" width="1.6" height="1.6"></rect>
                <rect x="23.4" y="26.6" width="6.2" height="1.6"></rect>
                <rect x="28.1" y="36.7" width="1.6" height="1.6"></rect>
                <rect x="20.3" y="36.7" width="6.2" height="1.6"></rect>
                <rect x="10.2" y="26.6" width="1.6" height="1.6"></rect>
              </g>
            </svg>
        `
				},
				{
					title: "Making informed choices",
					text: "Being a custom ERP development company, we tailor your data requirements to integrate multi-departmental data in a hierarchical architecture. This provides accurate and timely data updates helping key personnel in taking data-backed decisions.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M12.5,1.6c0-0.9-0.7-1.6-1.6-1.6h-7C3,0,2.3,0.7,2.3,1.6v7c0,0.9,0.7,1.6,1.6,1.6h7c0.9,0,1.6-0.7,1.6-1.6V1.6z M3.9,8.6v-7
              h7v7H3.9z"></path>
              <path d="M24.2,1.6c0-0.9-0.7-1.6-1.6-1.6h-7C14.7,0,14,0.7,14,1.6v7c0,0.9,0.7,1.6,1.6,1.6h7c0.9,0,1.6-0.7,1.6-1.6V1.6z M15.6,8.6
              v-7h7v7H15.6z"></path>
              <path d="M15.6,21.9h7c0.9,0,1.6-0.7,1.6-1.6v-7c0-0.9-0.7-1.6-1.6-1.6h-7c-0.9,0-1.6,0.7-1.6,1.6v7C14,21.2,14.7,21.9,15.6,21.9z
              M15.6,13.3h7v7h-7V13.3z"></path>
              <path d="M35.9,1.6c0-0.9-0.7-1.6-1.6-1.6h-7c-0.9,0-1.6,0.7-1.6,1.6v7c0,0.9,0.7,1.6,1.6,1.6h7c0.9,0,1.6-0.7,1.6-1.6V1.6z
              M27.3,8.6v-7h7v7H27.3z"></path>
              <path d="M27.3,21.9h7c0.9,0,1.6-0.7,1.6-1.6v-7c0-0.9-0.7-1.6-1.6-1.6h-7c-0.9,0-1.6,0.7-1.6,1.6v7C25.8,21.2,26.5,21.9,27.3,21.9z
              M27.3,13.3h7v7h-7V13.3z"></path>
              <path d="M39.1,10.2h7c0.9,0,1.6-0.7,1.6-1.6v-7C47.7,0.7,47,0,46.1,0h-7c-0.9,0-1.6,0.7-1.6,1.6v7C37.5,9.5,38.2,10.2,39.1,10.2z
              M39.1,1.6h7v7h-7V1.6z"></path>
              <path d="M37.5,20.3c0,0.9,0.7,1.6,1.6,1.6h7c0.9,0,1.6-0.7,1.6-1.6v-7c0-0.9-0.7-1.6-1.6-1.6h-7c-0.9,0-1.6,0.7-1.6,1.6V20.3z
              M39.1,13.3h7v7h-7V13.3z"></path>
              <path d="M49.9,42.3c0-0.4-0.4-0.8-0.8-0.8c-0.3,0-0.6,0.3-0.7,0.6c-0.4,1.4-1.7,2.4-3.2,2.4h-4.4c-1.4,0-2.7-1-3.1-2.3l0.5-10.1
              c0.1-1.8,1.5-3.2,3.3-3.2h3c1.8,0,3.2,1.4,3.3,3.2l0.5,7.4c0,0.4,0.4,0.8,0.8,0.7c0.4,0,0.8-0.4,0.7-0.8L49.3,32
              c-0.1-2.6-2.3-4.6-4.8-4.6h-0.7v-1.9c0-0.9-0.7-1.6-1.6-1.6H31.5c-0.9,0-1.6,0.7-1.6,1.6v17H10.5v-4.6c-0.2,0-0.4,0-0.6,0
              c-0.3,0-0.7,0-1,0v4.7c0,0.9,0.7,1.6,1.6,1.6H30c0.9,0,1.6-0.7,1.6-1.6v-17h10.7v1.9h-0.7c-2.6,0-4.7,2.1-4.8,4.6l-0.7,12.9
              c-0.1,1.4,0.4,2.7,1.3,3.7c0.9,1,2.2,1.5,3.5,1.5h4.4c1.3,0,2.6-0.5,3.5-1.5c0.9-1,1.4-2.3,1.3-3.7L49.9,42.3z M47.5,47.5
              c-0.6,0.6-1.5,1-2.3,1h-4.4c-0.9,0-1.7-0.4-2.3-1c-0.6-0.7-1-1.6-0.9-2.5v-0.1c0.9,0.8,2.1,1.3,3.3,1.3h4.4c1.2,0,2.4-0.5,3.3-1.3
              V45C48.5,45.9,48.2,46.8,47.5,47.5L47.5,47.5z"></path>
              <path d="M42.2,31.3v3.1c0,0.4,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8v-3.1c0-0.4-0.4-0.8-0.8-0.8S42.2,30.8,42.2,31.3z"></path>
              <path d="M12.1,36c2.4-0.6,3.5-2.5,3.5-4.1V25c0-0.5-0.2-1-0.6-1.4c-0.6-0.6-1.5-0.7-2.3-0.3c0,0,0,0,0-0.1c-0.7-0.7-1.8-0.8-2.6-0.2
              c0,0-0.5-0.3-0.6-0.3c-0.3-0.1-0.7-0.1-1,0v-2c0-0.8-0.5-1.5-1.2-1.8c-1-0.4-2.2,0.1-2.6,1.1c-0.1,0.2-0.1,0.5-0.1,0.7v5.8l-1.4-1.4
              c-0.8-0.7-2-0.7-2.7,0c-0.7,0.8-0.7,2,0,2.7l3.4,3.4c0.5,0.5,0.8,1.2,1,1.8c0.5,1.5,2,2.8,4.1,3.2C10,36.4,11.1,36.3,12.1,36z
              M6.3,32.6c-0.3-0.9-0.7-1.7-1.3-2.4l-3.3-3.3c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0L4.9,29c0.3,0.3,0.8,0.3,1.1,0
              c0.2-0.1,0.2-0.3,0.2-0.6v-7.7c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4,0.1,0.5,0.3c0,0,0,0.1,0,0.1v4.7c0,0.4,0.4,0.8,0.8,0.8
              s0.8-0.4,0.8-0.8v-0.8c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-0.8
              c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8v-0.3c0-0.2,0.1-0.4,0.4-0.5
              c0.2,0,0.4,0.1,0.4,0.4c0,0,0,0,0,0V32c0,0.9-0.6,2.2-2.3,2.6C8.8,35.3,6.9,34.1,6.3,32.6z"></path>
              <path d="M10.9,21.9c0.9,0,1.6-0.7,1.6-1.6v-7c0-0.9-0.7-1.6-1.6-1.6h-7c-0.9,0-1.6,0.7-1.6,1.6v7c0,0.6,0.3,1.1,0.8,1.3v-0.8
              c0-0.8,0.3-1.6,0.8-2.3v-5.3h7v7h-0.8c0,0.1,0,0.3,0,0.4v1.2H10.9z"></path>
              <path d="M11.7,32.4c0-0.4-0.4-0.8-0.8-0.8H9.4c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h1.6C11.4,33.2,11.7,32.9,11.7,32.4z"></path>
            </svg>
        `
				},
				{
					title: "Preparing Blueprint",
					text: "Bring in the entire team to brainstorm and chart out plans for departmental goals. Our team leverage such sessions to prepare features list to craft expectation-driven ERP model assisting teams to prime their departmental efficiencies.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g transform="translate(0,-1020.3622)">
                  <path d="M41.3,1020.4c-0.5,0-1,0.4-1,1v3.8v1c0,0,0,0,0,0c-10.2,0-20.5,0-30.7,0c-3.2,0-5.8,2.6-5.8,5.8v25H1c-0.5,0-1,0.4-1,1
                  v11.5c0,0.5,0.4,1,1,1h34.6c0.5,0,1-0.4,1-1v-4.8h3.8c3.2,0,5.8-2.6,5.8-5.8v-4.5l3.7-7.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                  c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3v-21.1v-3.8c0-0.5-0.4-1-1-1H41.3z M42.3,1022.3h5.8v1.9h-5.8V1022.3z
                  M42.3,1026.1h5.8v19.2h-5.8V1026.1z M9.7,1028.1c10.2,0,20.5,0,30.7,0h0v18.3c0,0,0,0,0,0v0c0,0,0,0,0,0.1c0,0.1,0,0.3,0.1,0.4
                  l3.7,7.5v4.5c0,2.2-1.7,3.9-3.9,3.9h-3.8v-4.8c0,0,0,0,0,0c0-0.5-0.4-1-1-1H5.8v-25C5.8,1029.8,7.5,1028.1,9.7,1028.1z M10.6,1030
                  c-0.5,0-1,0.4-1,1v7.7c0,0.5,0.4,1,1,1h6.7v6.7c0,0.5,0.4,1,1,1H25v6.7c0,0.5,0.4,1,1,1h7.7c0.5,0,1-0.4,1-1v-7.7c0-0.5-0.4-1-1-1
                  h-6.7v-5.8h6.7c0.5,0,1-0.4,1-1v-7.7c0-0.5-0.4-1-1-1H26c-0.5,0-1,0.4-1,1v6.7h-5.8v-6.7c0-0.5-0.4-1-1-1H10.6z M11.5,1031.9h5.8
                  v5.8h-5.8V1031.9z M26.9,1031.9h5.8v5.8h-5.8V1031.9z M19.2,1039.6H25v5.8h-5.8V1039.6z M26.9,1047.3h5.8v5.8h-5.8V1047.3z
                  M42.9,1047.3h4.6l-2.3,4.6L42.9,1047.3z M1.9,1058.8h1.9v7.7h1.9v-7.6h1.9v3.8h1.9v-3.8h1.9v7.6h1.9v-7.6h1.9v3.8h1.9v-3.8h1.9
                  v7.6h1.9v-7.6h1.9v3.8H25v-3.8h1.9v7.6h1.9v-7.6h1.9v3.8h1.9v-3.8h1.9v4.8v4.8H1.9V1058.8z"></path>
                </g>
              </svg>
        `
				},
				{
					title: "Supply Chain Efficiency",
					text: "Move over old manual data feeding leaving room for errors. Integrate ERP software development services to attain precision through supply chain processes automation. Our ERP dashboards and business intelligence help you simplify stock management.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M47.8,41.9c0.4-0.5,0.6-1.2,0.6-1.9c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3c0,0.7,0.2,1.3,0.6,1.9c-1,0.3-1.7,1.1-2,2
              c-0.2-0.2-0.5-0.3-0.8-0.4c0.4-0.5,0.6-1.2,0.6-1.9c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,0.7,0.2,1.3,0.6,1.9
              c-0.3,0.1-0.5,0.2-0.8,0.4c-0.3-1-1.1-1.7-2-2c0.4-0.5,0.6-1.2,0.6-1.9c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3
              c0,0.7,0.2,1.3,0.6,1.9c-1.3,0.5-2.2,1.7-2.2,3.1v2.5c0,0.5,0.4,0.8,0.8,0.8h7.5v0.8c0,0.5,0.4,0.8,0.8,0.8h8.3
              c0.5,0,0.8-0.4,0.8-0.8v-0.8h7.5c0.5,0,0.8-0.4,0.8-0.8V45C50,43.5,49.1,42.3,47.8,41.9z M45,38.3c0.9,0,1.7,0.7,1.7,1.7
              s-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7S44.1,38.3,45,38.3z M36.7,40c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7
              c-0.9,0-1.7-0.7-1.7-1.7C35,40.7,35.7,40,36.7,40z M28.3,38.3c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7
              S27.4,38.3,28.3,38.3z M25,46.7V45c0-0.9,0.7-1.7,1.7-1.7H30c0.9,0,1.7,0.7,1.7,1.7v1.7H25z M40,48.3h-6.7v-0.8v-0.8
              c0-0.9,0.7-1.7,1.7-1.7h3.3c0.9,0,1.7,0.7,1.7,1.7v0.8V48.3z M48.3,46.7h-6.7V45c0-0.9,0.7-1.7,1.7-1.7h3.3c0.9,0,1.7,0.7,1.7,1.7
              V46.7z"></path>
              <path d="M0.8,35.8h2.6c0.4,1.4,1.7,2.5,3.2,2.5s2.8-1.1,3.2-2.5h6.9c0.4,1.4,1.7,2.5,3.2,2.5s2.8-1.1,3.2-2.5H25
              c0.5,0,0.8-0.4,0.8-0.8v-7.5c0-0.2-0.1-0.4-0.2-0.6l-4.2-4.2c-0.2-0.2-0.4-0.2-0.6-0.2h-4.2v-4.2c0-0.5-0.4-0.8-0.8-0.8h-15
              c-0.5,0-0.8,0.4-0.8,0.8v10.8V35C0,35.5,0.4,35.8,0.8,35.8z M6.7,36.7C5.7,36.7,5,35.9,5,35s0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7
              S7.6,36.7,6.7,36.7z M20,36.7c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7S20.9,36.7,20,36.7z M20.5,24.2l3.7,3.7v6.3
              h-0.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4
              c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1
              c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2-0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.1,0-0.1,0-0.2,0.1
              c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.2
              c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1,0,0.1-0.1,0.2
              c0,0.1-0.1,0.2-0.1,0.3H9.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c0,0-0.1-0.1-0.1-0.1
              c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
              c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2-0.1c-0.2,0-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1
              c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1
              c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.2,0.3-0.3,0.5
              c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3H1.7V30h14.2c0.5,0,0.8-0.4,0.8-0.8v-5H20.5z M1.7,19.2H15v4.2v5H1.7V19.2z"></path>
              <path d="M49.2,0h-5c-0.5,0-0.8,0.4-0.8,0.8v9.5l-5,3.1v-2.7c0-0.3-0.2-0.6-0.4-0.7c-0.3-0.1-0.6-0.1-0.8,0l-5.4,3.4v-2.7
              c0-0.3-0.2-0.6-0.4-0.7c-0.3-0.1-0.6-0.1-0.8,0l-6.7,4.2c-0.2,0.2-0.4,0.4-0.4,0.7v6.7c0,0.5,0.4,0.8,0.8,0.8h6.7h6.7h6.7h5
              c0.5,0,0.8-0.4,0.8-0.8v-8.3V0.8C50,0.4,49.6,0,49.2,0z M45,1.7h3.3v10.8H45v-1.7V1.7z M25,15.5l5-3.1V15v5.8h-5V15.5z M31.7,15.5
              l5-3.1V15v5.8h-5V15.5z M38.3,15.5l5-3.1v1v7.5h-5V15.5z M48.3,20.8H45v-6.7h3.3V20.8z"></path>
              <rect x="33.3" y="16.7" width="1.7" height="2.5"></rect>
              <rect x="40" y="16.7" width="1.7" height="2.5"></rect>
              <rect x="26.7" y="16.7" width="1.7" height="2.5"></rect>
              <path d="M44.4,28.9l1.6,0.4c0.1-0.6,0.3-1.1,0.3-1.7l-1.6-0.3C44.7,27.9,44.6,28.4,44.4,28.9z"></path>
              <path d="M46.7,24.2H45c0,0.5,0,1.1-0.1,1.6l1.7,0.1C46.6,25.3,46.7,24.7,46.7,24.2z"></path>
              <path d="M41.9,34.8l1.4,0.9c0.3-0.5,0.6-1,0.9-1.5l-1.5-0.8C42.5,33.9,42.2,34.3,41.9,34.8z"></path>
              <path d="M43.4,32l1.5,0.6c0.2-0.5,0.4-1.1,0.6-1.6L44,30.5C43.8,31,43.6,31.5,43.4,32z"></path>
              <path d="M11.9,9.9l-1.1-1.3C10.4,9,9.9,9.4,9.5,9.8l1.2,1.2C11.1,10.6,11.5,10.3,11.9,9.9z"></path>
              <path d="M33.5,5.1c-0.5-0.2-1.1-0.4-1.6-0.6L31.4,6c0.5,0.2,1,0.4,1.5,0.6L33.5,5.1z"></path>
              <path d="M13.6,6.6c-0.5,0.3-1,0.6-1.4,1l1,1.3C13.6,8.6,14,8.3,14.5,8L13.6,6.6z"></path>
              <path d="M5.5,15.5L7,16.2c0.2-0.5,0.5-1,0.8-1.4l-1.4-0.9C6.1,14.4,5.8,14.9,5.5,15.5z"></path>
              <path d="M7.3,12.5l1.4,1c0.3-0.4,0.6-0.9,1-1.3l-1.3-1.1C8,11.6,7.6,12,7.3,12.5z"></path>
              <path d="M25.1,3.3l0,1.7c0.5,0,1.1,0,1.6,0.1l0.1-1.7C26.3,3.4,25.7,3.3,25.1,3.3z"></path>
              <path d="M28.5,3.6l-0.3,1.6c0.5,0.1,1.1,0.2,1.6,0.3L30.2,4C29.7,3.8,29.1,3.7,28.5,3.6z"></path>
              <path d="M35.1,5.8l-0.8,1.5c0.5,0.2,0.9,0.5,1.4,0.8l0.9-1.4C36.1,6.4,35.6,6.1,35.1,5.8z"></path>
              <path d="M23.4,3.4c-0.6,0-1.2,0.1-1.7,0.2l0.3,1.6c0.5-0.1,1.1-0.1,1.6-0.2L23.4,3.4z"></path>
              <path d="M17.3,6.5L16.6,5c-0.5,0.2-1.1,0.5-1.6,0.7l0.8,1.5C16.3,7,16.8,6.7,17.3,6.5z"></path>
              <path d="M19.9,3.9c-0.6,0.1-1.1,0.3-1.7,0.5L18.8,6c0.5-0.2,1-0.3,1.5-0.4L19.9,3.9z"></path>
              <path d="M17.4,43.7l-0.6,1.6c0.5,0.2,1.1,0.4,1.6,0.5l0.5-1.6C18.4,44.1,17.9,43.9,17.4,43.7z"></path>
              <path d="M14.5,42.4l-0.8,1.4c0.5,0.3,1,0.6,1.5,0.8l0.7-1.5C15.4,42.9,15,42.6,14.5,42.4z"></path>
              <path d="M20.5,44.6l-0.3,1.6c0.6,0.1,1.1,0.2,1.7,0.3l0.2-1.7C21.5,44.8,21,44.7,20.5,44.6z"></path>
              <path d="M9.8,38.6l-1.2,1.1c0.3,0.3,0.6,0.7,1,1l1.1-1.2C10.4,39.2,10.1,38.9,9.8,38.6z"></path>
              <path d="M11.9,40.6l-1,1.3c0.5,0.4,0.9,0.7,1.4,1l0.9-1.4C12.7,41.2,12.3,40.9,11.9,40.6z"></path>
            </svg>
        `
				},
				{
					title: "Modifying an Existing System",
					text: "Our ERP experts modify existing software modules based on your business needs. Providing effortless implementation and thoughtful integration across major systems to save you from major software renovations and hassles of redefining your existing data structure.",
					svg: `
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g id="Layer_2">
              </g>
              <g id="Layer_1">
                <g>
                  <g>
                    <polygon points="50,48.8 0,48.8 0,6.6 25,6.6 25,9.8 3.1,9.8 3.1,45.7 46.9,45.7 46.9,23.8 50,23.8      "></polygon>
                  </g>
                  <g>
                    <g>
                      <g>
                        <polygon points="26.7,34 23.8,28.2 18,25.2 36.1,7.1 38.3,9.3 23.1,24.3 26.1,25.9 27.7,28.8 42.7,13.7 44.8,15.9          "></polygon>
                      </g>
                      <g>
                        <path d="M47,13.7l-8.9-8.9l1.1-1.1c1.3-1.3,2.8-1.9,4.4-1.9c1.7,0,3.3,0.6,4.4,1.9c1.2,1.3,1.9,2.8,1.9,4.4
                        c0,1.7-0.6,3.3-1.9,4.4L47,13.7z M43,5.2l3.9,3.9c0-0.3,0.2-0.5,0.2-0.8c0-0.8-0.3-1.6-0.9-2.2C45.2,5.2,44.1,4.9,43,5.2z"></path>
                      </g>
                    </g>
                    <g>
                      <polygon points="11.3,40.7 15.5,27.7 18.4,28.8 16.3,35.7 23.1,33.5 24.2,36.5        "></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
        `
				},
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
