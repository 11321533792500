<template>
	<section class="padding-60 bg-white web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>FAQs</span>
			</div>
			<div>
				<FaqCard
					v-bind:obj="item"
					v-for="item in items"
					v-bind:key="item.title"
					class="col-lg-10 col-12 text-center"
					style="padding: 0; margin: 15px auto"
				></FaqCard>
			</div>
		</div>
	</section>
</template>

<script>
import FaqCard from '../../OnDemandSolutions/AssetComponents/FaqCard.vue';
export default {
	components: {
		FaqCard
	},
	data() {
		return {
			items: [
				{
					class: 'option1_1',
					question: 'How do you show live score updates?',
					answer:
						'We integrate APIs that will fetch live scores and feed it directly into the app. Talk to our experts to know what kind of APIs we use and how.'
				},
				{
					class: 'option1_2',
					question: 'How will fantasy apps get the player and tournament information?',
					answer:
						'The basic player and tournament information is updated on the app based on the sports knowledge available on the public domain. Any additional information that you need can also be incorporated to give the app a unique feel.'
				},
				{
					class: 'option1_3',
					question: 'Can you Develop a web application for fantasy sports?',
					answer:
						'Yes. Our Fantasy Sports App Developers rely on cloud technologies for developing fantasy sports apps. We can build web applications for the same app as well.'
				},
				{
					class: 'option1_4',
					question: 'Do you have experience in Fantasy Cricket App Development?',
					answer:
						'Check out our case study on the recent fantasy cricket app project that we delivered.'
				},
				{
					class: 'option1_5',
					question: 'How much does it cost to develop a Fantasy Cricket App like Dream11?',
					answer:
						'The cost to develop a fantasy cricket like Dream 11 cannot be estimated accurately. However, we can give you a ballpark amount if you can share your requirements with our team.'
				},
				{
					class: 'option1_6',
					question: 'Will a single Fantasy App support multiple sports?',
					answer:
						'Yes. A single fantasy sports app can be configured to have multiple sports. For example, a cricket fantasy app can be configured to have tennis, basketball, hockey and many other fantasy sports.'
				},
				{
					class: 'option1_7',
					question: 'Why should I hire GenixBit Labs for Fantasy Sports App Development?',
					answer:
						'If you want your fantasy sports app to boast of top-notch quality, If you want the app to deliver a superior user experience, and If you want the app to be delivered on time, You should hire GenixBit Labs.'
				},
				{
					class: 'option1_8',
					question: 'Will I Have Access to The Source Code After The Product is Launched?',
					answer:
						'Yes. As a standard practice we hand over the source code of the app once the product is launched.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
</style>
