<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our Expertise</span>
				<h2 class="fxt-process-dev">Type of Virtual Reality Games we Develop</h2>
				<p>
					The virtual reality game development is the future of the gaming industry. With recent
					advancement in VR devices, creators and developers are constantly evolving. GenixBit Labs
					stands tall in implementing immersive Virtual Reality solutions for its clients.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<CroppedBorderCard v-bind:obj="item"></CroppedBorderCard>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items2" v-bind:key="item.title">
						<CroppedBorderCard v-bind:obj="item"></CroppedBorderCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import CroppedBorderCard from '../AssetComponents/CroppedBorderCard';

export default {
	data: () => {
		return {
			items: [
        {
					title: 'VR Action Games',
					text: 'Be a real hero in the virtual world with a VR action game. Though we don’t promote aggression, we don’t find any harm if it’s in a virtual world.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <path d="M13.2,26.2l-3.1-3.1c0.4-1.6,0-3.3-1.2-4.4c-0.9-0.9-2-1.3-3.2-1.3c-1.2,0-2.4,0.5-3.2,1.3c-1.8,1.8-1.8,4.7,0,6.5
                  c0.9,0.9,2,1.3,3.2,1.3c0.4,0,0.8-0.1,1.2-0.2l4.8,4.8l-7.1,7.1L3,36.5l-3,3l2,2c-0.5,0.8-0.8,1.7-0.8,2.6c0,1.2,0.5,2.4,1.4,3.3
                  c0.9,0.9,2.1,1.4,3.3,1.4c0.9,0,1.8-0.3,2.6-0.8l2,2l3-3l-1.7-1.7l7.1-7.1l4.8,4.8c-0.4,1.6,0,3.3,1.2,4.5c0.9,0.9,2,1.3,3.2,1.3
                  c1.2,0,2.4-0.5,3.2-1.3c1.8-1.8,1.8-4.7,0-6.5c-0.9-0.9-2-1.3-3.2-1.3c-0.4,0-0.8,0.1-1.2,0.2l-3.1-3.1l25-25l0.2-0.2l0.5-11L38.4,1
                  L13.2,26.2z M7.8,24c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-1.1,0.4-2.4,0.2-3.2-0.6c-1.1-1.1-1.1-3,0-4.1
                  C4.2,19.4,5,19,5.7,19c0.8,0,1.5,0.3,2.1,0.9c0.8,0.8,1.1,2.1,0.6,3.2C8.3,23.4,8.1,23.7,7.8,24z M3.7,46.3
                  c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.5,0.1-1,0.4-1.4l4,4C6.1,47.4,4.7,47.2,3.7,46.3z M10.5,47.6l-8.2-8.2L3,38.9l0.5,0.5l0.4,0.4
                  l6.8,6.8v0l0.5,0.5L10.5,47.6z M10.6,44.2l-3.9-3.9l-0.8-0.8l7.1-7.1l4.8,4.8L10.6,44.2z M26,42.2c0.3-0.3,0.6-0.5,0.9-0.6
                  c0.4-0.1,0.7-0.2,1.1-0.2c0.8,0,1.5,0.3,2.1,0.9c1.1,1.1,1.1,3,0,4.1c-0.5,0.5-1.3,0.9-2.1,0.9c-0.8,0-1.5-0.3-2.1-0.9
                  c-0.8-0.8-1.1-2.1-0.6-3.2C25.5,42.8,25.7,42.5,26,42.2z M25.2,40.6C25.2,40.6,25.2,40.7,25.2,40.6c-0.2,0.2-0.3,0.3-0.5,0.4
                  c-0.1,0.1-0.2,0.3-0.4,0.4c0,0,0,0,0,0L8.5,25.6c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.4-0.4c0,0,0,0,0,0
                  L25.2,40.6z M47.3,10.8L22.6,35.6l-3.5-3.5L47.6,3.5L47.3,10.8z M46.5,2.4L17.9,30.9l-3.5-3.5L39.2,2.7L46.5,2.4z"></path>
                </svg>
          `
				},
        {
					title: 'VR FPS Games',
					text: 'The VR first-person shooter games can be the one which delivers most realistic manner. Our team of VR game developers love to redefine the experience with every project.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M91.6,17H8.4C3.8,17,0,20.7,0,25.3v49.5C0,79.3,3.8,83,8.4,83h83.3c4.6,0,8.3-3.7,8.3-8.2V25.3C100,20.7,96.2,17,91.6,17z
                      M82,20.3l-17.5,9.9H35.4L18,20.3H82z M56.1,46c0.4-0.8,0.6-1.7,0.6-2.6c0-3.6-3-6.6-6.7-6.6c-3.7,0-6.7,3-6.7,6.6
                      c0,0.9,0.2,1.8,0.6,2.6c-1.4,1.5-2.2,3.5-2.2,5.6v6.6h-5V33.5h26.7v24.8h-5v-6.6C58.3,49.5,57.5,47.6,56.1,46z M47.3,45.4
                      c-0.4-0.6-0.7-1.3-0.7-2c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0.7-0.2,1.4-0.7,2c-0.3,0.4-0.4,0.8-0.3,1.3
                      c0.1,0.4,0.3,0.8,0.7,1.1c0.2,0.1,0.3,0.3,0.5,0.4c0.9,0.9,1.5,2.2,1.5,3.5v6.6H45v-6.6c0-1.6,0.7-3,2-3.9
                      c0.4-0.3,0.6-0.7,0.7-1.1C47.7,46.2,47.6,45.7,47.3,45.4z M10,79.7H8.4c-2.8,0-5-2.2-5-4.9V25.3c0-2.7,2.2-5,5-5H10V79.7z
                      M13.3,21.5l20,11.3v26.4l-20,18.4V21.5z M15.9,79.7l19.7-18.2h12.7v3.3c0,0.9,0.7,1.7,1.7,1.7c0.9,0,1.7-0.7,1.7-1.7v-3.3h12.7
                      l19.7,18.2H15.9z M86.7,77.6l-20-18.4V32.8l20-11.3V77.6z M96.6,48.3H95c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.6,1.7,1.6h1.7v23.1
                      c0,2.7-2.2,4.9-5,4.9H90V20.3h1.7c2.8,0,5,2.2,5,5V48.3z M50,69.8c-0.9,0-1.7,0.7-1.7,1.6v3.3c0,0.9,0.7,1.6,1.7,1.6
                      c0.9,0,1.7-0.7,1.7-1.6v-3.3C51.7,70.5,50.9,69.8,50,69.8z M46.7,66.5h-3.3c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.6,1.7,1.6h3.3
                      c0.9,0,1.7-0.7,1.7-1.6C48.3,67.2,47.6,66.5,46.7,66.5z M56.7,66.5h-3.3c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.7,1.6,1.7,1.6h3.3
                      c0.9,0,1.7-0.7,1.7-1.6C58.3,67.2,57.6,66.5,56.7,66.5z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
        {
					title: 'VR Adventure Games',
					text: 'Love to relish new adventures? Well, the VR adventure games can just the right choice. Trek the mountains and sail through the storming oceans, with VR game development.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M12.2,28c0.7,0.6,1.7,0.6,2.4,0l11.7-11.6c0.7-0.6,0.7-1.7,0-2.3L14.5,2.5c-0.7-0.6-1.7-0.6-2.4,0L0.5,14.1
                      c-0.7,0.6-0.7,1.7,0,2.3L12.2,28z M22.6,15.2l-9.3,9.2L9,20.2l9.3-9.2L22.6,15.2z M13.3,6L16,8.6l-9.3,9.2L4,15.2L13.3,6z
                      M86.3,51.7c-0.4,0-0.9,0-1.3,0c-9.3,0-15,2.5-15,14.9c0,0.9,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7c0.2-2.6,2.3-4.7,5-5
                      c2.7,0.2,4.8,2.3,5,5c0.2,2.5-1.7,4.8-4.3,5c-0.2,0-0.5,0-0.7,0h-31l32.1-31.9c0.7-0.6,0.7-1.7,0-2.3c-0.3-0.3-0.7-0.5-1.2-0.5
                      h-10c-0.4,0-0.9,0.2-1.2,0.5l-3.9,3.9L56,35.5c-0.3-0.2-0.7-0.4-1-0.4h-9.3l0.5-0.5c0.7-0.6,0.7-1.7,0-2.3l-5-5l-0.5-0.5h12.6
                      c0.9,0,1.7-0.7,1.7-1.7v-3.3v-3.3c0-0.9-0.7-1.7-1.7-1.7H30c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.1,0-0.1,0.1c-0.2,0.1-0.3,0.2-0.5,0.3
                      L15.5,30.6l-15,14.9c-0.7,0.6-0.7,1.7,0,2.3l5,5c0.7,0.6,1.7,0.6,2.4,0l13.8-13.7l8.8,8.8c0.7,0.6,1.7,0.6,2.4,0l1-1l1.2,8.3
                      c0.1,0.4,0.3,0.8,0.6,1.1l6.9,5.5L37.1,67c-0.3,0.3-0.5,0.7-0.5,1.2v3.3h-35c-0.9,0-1.7,0.7-1.7,1.7V98h100V66.6
                      C100.3,58.7,94.2,52,86.3,51.7z M54.4,38.4l6.4,5.1l-2.7,2.6l-5.5-4.1c-0.3-0.2-0.6-0.3-1-0.3h-9.3l-1.7-1.7l1.7-1.7H54.4z
                      M55.8,48.5l-6,5.9l-5-3.7L43.7,45h7.4L55.8,48.5z M51.7,20.2v3.3H37.4L34,20.2H51.7z M6.7,49.3L4,46.7l12.6-12.6l2.6,2.6
                      L6.7,49.3z M19,31.8l11-10.9l7.6,7.6l-11,10.9L19,31.8z M31.7,44.3L29,41.7l11-10.9l2.6,2.6L31.7,44.3z M38.2,54.1L36.8,44
                      l1.6-1.6l1.8,1.8l1.6,7.8c0.1,0.4,0.3,0.8,0.6,1l5.1,3.8l-2.6,2.6L38.2,54.1z M40,68.9l29-28.8h5.3L40,74.1V68.9z M96.6,94.7H3.3
                      V81.8c1.2,0.8,2.1,1.8,2.8,3c1.5,2.2,3.3,4.9,7.2,4.9c3.8,0,6-2.5,7.9-4.7c1.7-1.9,3.1-3.6,5.4-3.6c2,0,3,1.2,4.5,3.4
                      c1.5,2.2,3.3,4.9,7.2,4.9c3.9,0,5.7-2.7,7.2-4.9c1.5-2.2,2.4-3.4,4.5-3.4c2,0,3,1.2,4.5,3.4c1.5,2.2,3.3,4.9,7.2,4.9
                      c3.8,0,6-2.5,7.9-4.7c1.7-1.9,3.1-3.6,5.4-3.6c2,0,3,1.2,4.5,3.4c1.5,2.2,3.3,4.9,7.2,4.9c3.9,0,5.7-2.7,7.2-4.9
                      c0.7-1.2,1.6-2.3,2.8-3V94.7z M96.6,66.6v11.8c-2.4,0.8-4.4,2.4-5.5,4.7c-1.5,2.2-2.4,3.4-4.5,3.4c-2,0-3-1.2-4.5-3.4
                      c-1.5-2.2-3.3-4.9-7.2-4.9c-3.8,0-6,2.5-7.9,4.7c-1.7,1.9-3.1,3.6-5.4,3.6c-2,0-3-1.2-4.5-3.4c-1.5-2.2-3.3-4.9-7.2-4.9
                      c-3.9,0-5.7,2.7-7.2,4.9c-1.5,2.2-2.4,3.4-4.5,3.4c-2,0-3-1.2-4.5-3.4c-1.5-2.2-3.3-4.9-7.2-4.9c-3.8,0-6,2.5-7.9,4.7
                      c-1.7,1.9-3.1,3.6-5.4,3.6c-2,0-3-1.2-4.5-3.4c-1.2-2.2-3.2-3.9-5.5-4.7v-3.5h33.3v3.3c0,0.9,0.7,1.7,1.7,1.7
                      c0.4,0,0.9-0.2,1.2-0.5l4.5-4.5h32.6c0.3,0,1.1,0.1,2.4,0.1c3.1,0,8.2-0.5,11.5-3.3c1.9-1.7,3-4.2,2.9-6.7H90
                      c0.1,1.6-0.6,3.2-1.8,4.2c-0.7,0.6-1.6,1.1-2.5,1.5c0.6-1.3,1-2.6,1-4c-0.2-4.5-3.8-8.1-8.3-8.3c-1.4,0-2.7,0.4-3.9,1.1
                      C76,55.7,79.4,55,85,55c6.1-0.4,11.3,4.3,11.7,10.3C96.7,65.7,96.7,66.1,96.6,66.6z M60,26.8c0.6,0,1.1-0.1,1.7-0.3v3.6h-3.3v3.3
                      h5c0.9,0,1.7-0.7,1.7-1.7v-2l33.7-8c0.9-0.2,1.4-1.1,1.2-2c-0.1-0.6-0.6-1.1-1.2-1.2l-33.7-8v-2C65,7.7,64.2,7,63.3,7h-5v3.3h3.3
                      v6.9c-0.5-0.2-1.1-0.3-1.7-0.3c-2.8,0-5,2.2-5,5C55,24.6,57.2,26.8,60,26.8z M65,14l26.1,6.2L65,26.4V14z M60,20.2
                      c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7C58.3,20.9,59.1,20.2,60,20.2z M86.7,38.4c-2.8,0-5,2.2-5,5
                      c0,2.7,2.2,5,5,5c2.8,0,5-2.2,5-5C91.7,40.6,89.4,38.4,86.7,38.4z M86.7,45c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
                      c0.9,0,1.7,0.7,1.7,1.7C88.3,44.3,87.6,45,86.7,45z M100,33.4c0-2.7-2.2-5-5-5c-2.8,0-5,2.2-5,5c0,2.7,2.2,5,5,5
                      C97.7,38.4,100,36.2,100,33.4z M93.3,33.4c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.7,1.7,1.7s-0.7,1.7-1.7,1.7
                      C94.1,35.1,93.3,34.4,93.3,33.4z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
        {
					title: 'Puzzle VR Games',
					text: 'Puzzle solving could never have been so interactive. Even if you find it boring, VR makes it more engaging and interactive.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M448,160H344c-4.4,0-8,3.6-8,8v32c0,4.4,3.6,8,8,8h24c8.8,0,16,7.2,16,16s-7.2,16-16,16h-24c-4.4,0-8,3.6-8,8v40H216
                      c-4.4,0-8,3.6-8,8v104c0,17.7,14.3,32,32,32h208c17.7,0,32-14.3,32-32V192C480,174.4,465.7,160,448,160z M336,416h-96
                      c-8.8,0-16-7.2-16-16v-96h112v16h-16c-17.7,0-32,14.3-32,32s14.3,32,32,32h16V416z M464,400c0,8.8-7.2,16-16,16h-96v-40
                      c0-4.4-3.6-8-8-8h-24c-8.8,0-16-7.2-16-16s7.2-16,16-16h24c4.4,0,8-3.6,8-8v-24h24v16c0,17.7,14.3,32,32,32s32-14.3,32-32v-16h24
                      V400z M464,288h-32c-4.4,0-8,3.6-8,8v24c0,8.8-7.2,16-16,16s-16-7.2-16-16v-24c0-4.4-3.6-8-8-8h-32v-32h16c17.7,0,32-14.3,32-32
                      s-14.3-32-32-32h-16v-16h96c8.8,0,16,7.2,16,16V288z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M352,32h-16V8c0-4.4-3.6-8-8-8H224c-17.7,0-32,14.3-32,32v89.6l-33.1,16c-5-6.6-11.2-12.1-18.2-16.4
                      c31.6-15.8,44.4-54.3,28.5-85.9S115-9,83.4,6.8S39,61.1,54.8,92.7c5.5,10.9,13.9,20,24.4,26.3c-16.7,8.2-29.1,23.2-33.9,41.2
                      L13.5,260.5c-9,28.3-7.2,59,5.1,86.1c13.1,28.6,12.5,61.6-1.7,89.7L0.9,468.5c-2,4-0.4,8.8,3.6,10.7c1.1,0.6,2.3,0.8,3.6,0.8h88
                      c4.4,0,8-3.6,8-8c0-14.8-8.2-28.5-21.4-35.3l3.8-7.6c11.6-23.3,15.3-49.7,10.7-75.3l-5.9-32.6c-0.8-4.2,2-8.2,6.3-9
                      c0.4-0.1,0.9-0.1,1.4-0.1c3.7,0,7,2.7,7.6,6.4l10.9,59.8c1.8,9.7,2.6,19.6,2.6,29.4V472c0,4.4,3.6,8,8,8h88c4.4,0,8-3.6,8-8
                      c0-1.6-0.1-3.1-0.3-4.7c-1.4-12-8.8-22.5-19.7-27.8c-8.9-4.5-19.3-5.5-28.8-2.6l5.3-26.6c2.3-11.7,3.5-23.5,3.5-35.4v-71.6
                      l-9.3-107.1l55.5-26.5c10.3-4.6,17.1-14.5,17.7-25.7H328c4.4,0,8-3.6,8-8V96h16c17.7,0,32-14.3,32-32S369.7,32,352,32z M64,64
                      c0-26.5,21.5-48,48-48s48,21.5,48,48s-21.5,48-48,48C85.5,112,64.1,90.5,64,64z M175.2,454.3c6.7-3.8,14.8-4,21.6-0.5
                      c4.4,2.1,7.8,5.7,9.7,10.2H136v-56.4c0-10.8-1-21.6-2.9-32.3l-10.9-59.8c-2.2-12.9-14.5-21.6-27.4-19.4
                      c-12.9,2.2-21.6,14.5-19.4,27.4c0,0.1,0,0.3,0.1,0.4l5.9,32.6c4,22.2,0.7,45.1-9.3,65.2l-7.8,15.5c-2,3.9-0.4,8.8,3.5,10.8
                      c0.6,0.3,1.1,0.5,1.7,0.6c7.9,1.9,14.3,7.6,17,15.2H21l10.3-20.5c16.3-32.4,17-70.5,1.9-103.5c-10.7-23.5-12.3-50.1-4.5-74.6
                      l32-100.8c6.6-24.9,31.4-40.4,56.6-35.6c10.5,2.1,19.9,7.7,26.8,15.8l-47.3,22.8c-10.2,5-16.8,15.4-16.8,26.7
                      c0,16.4,13.2,29.7,29.6,29.7c4.5,0,8.9-1,12.9-2.9l36.8-17.5L168,304V375c0,10.8-1.1,21.6-3.2,32.3l-8.5,42.6
                      c-0.9,4.3,1.9,8.5,6.3,9.4c1.9,0.4,3.9,0.1,5.5-0.9L175.2,454.3z M223.7,155.2l-0.2,0.1l-107.8,51.4c-6.8,3.3-15,0.4-18.3-6.5
                      c-0.9-1.8-1.3-3.8-1.3-5.9c0-5.2,3-10,7.7-12.3l108.7-52.5c4.3-1.9,9.3-1.5,13.3,1.1c4,2.6,6.4,7,6.3,11.7
                      C232,147.9,228.7,152.9,223.7,155.2z M352,80h-24c-4.4,0-8,3.6-8,8v40h-75.6c-7-13-22.5-18.9-36.4-13.9V32c0-8.8,7.2-16,16-16h96
                      v24c0,4.4,3.6,8,8,8h24c8.8,0,16,7.2,16,16S360.9,80,352,80z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M322.4,210.4L296,236.7V192h-16v60.7l-8,8l-8-8V224h-16v12.7l-26.3-26.3l-11.3,11.3l56,56c3.1,3.1,8.2,3.1,11.3,0l56-56
                      L322.4,210.4z"></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="248" y="192" width="16" height="16"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="248" y="160" width="16" height="16"></rect>
                    </g>
                  </g>
                  <g>
                    <g>
                      <rect x="280" y="160" width="16" height="16"></rect>
                    </g>
                  </g>
                </svg>
          `
				},
      ],
			items2: [
				{
					title: 'Sport VR Games',
					text: 'Whether it’s cricket, football, lawn tennis or some other game. With sports VR games you can play every outdoor sport while sitting in your living room.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M75.9,65.6l-1.3,1.3c-5.7-5-9.5-11.2-11.7-18.7c3.3-11-0.6-25.1-11.1-35.7c-6.5-6.6-14.7-11-23-12.1
                      c-8.4-1.1-16,1.1-21.1,6.5c-10.8,11-8.2,31.2,5.5,45.1c7.9,8.1,18.1,12.4,27.1,12.4c2.7,0,5.4-0.3,7.8-1.1
                      c7.4,2.3,13.5,6.1,18.4,12l-1.3,1.3L88.4,100L99,89.2L75.9,65.6z M36.8,25.7l-6.2-6.3l6.2-6.3l6.2,6.3L36.8,25.7z M45.2,21.6
                      l6.2,6.3l-6.2,6.3L39,27.9L45.2,21.6z M34.6,27.9l-6.2,6.3l-6.2-6.3l6.2-6.3L34.6,27.9z M20,25.7l-6.2-6.3l6.2-6.3l6.2,6.3
                      L20,25.7z M17.8,27.9l-6.2,6.3l-6.2-6.3l6.2-6.3L17.8,27.9z M20,30.2l6.2,6.3L20,42.8l-6.2-6.3L20,30.2z M28.4,38.7l6.2,6.3
                      l-6.2,6.3l-6.2-6.3L28.4,38.7z M30.6,36.5l6.2-6.3l6.2,6.3l-6.2,6.3L30.6,36.5z M45.2,38.7l6.2,6.3l-6.2,6.3L39,45.1L45.2,38.7z
                      M47.4,36.5l6.2-6.3l6.2,6.3l-6.2,6.3L47.4,36.5z M60.8,39.9c0,3.1-0.5,6.1-1.4,8.9l-3.6-3.7L60.8,39.9z M59.7,32l-4-4l1.7-1.8
                      C58.5,28.1,59.1,30,59.7,32z M55.9,23.1l-2.4,2.4l-6.2-6.1l3.3-3.4C52.8,18.2,54.5,20.6,55.9,23.1z M41.4,8.4
                      c2.5,1.5,4.8,3.2,7,5.3L45,17.1l-6-6.3L41.4,8.4z M38.5,6.7l-1.7,1.8l-4-4C34.7,5,36.6,5.8,38.5,6.7z M34.6,10.8l-6.2,6.3
                      l-6.2-6.3l6.2-6.3L34.6,10.8z M24.6,3.2c0.3,0.2,0.3,0.2,0.5,0.2L20,8.5l-3.6-3.7C18.9,3.8,21.7,3.2,24.6,3.2z M10,9
                      c1-1,2.1-1.9,3.3-2.6l4.4,4.5l-6.2,6.3l-4.3-4.7C8,11.4,8.9,10.1,10,9z M5.7,15.6l3.6,3.7l-5.1,5.2C4.3,21.5,4.8,18.4,5.7,15.6z
                      M5.4,32.4l4,4l-1.7,1.8C6.7,36.3,6.1,34.4,5.4,32.4z M9.2,41.2l2.4-2.4l6.2,6.3l-3.3,3.4C12.4,46.2,10.6,43.8,9.2,41.2z M23.8,56
                      c-2.4-1.5-4.8-3.2-7-5.3l3.3-3.4l6,6.3L23.8,56z M26.6,57.7l1.7-1.8l4,4C30.4,59.4,28.5,58.6,26.6,57.7z M30.6,53.6l6.2-6.3
                      l6.2,6.3l-6.2,6.3L30.6,53.6z M40.1,61.1l5.1-5.2l3.6,3.7C46.1,60.6,43.1,61.1,40.1,61.1z M47.4,53.5l6.2-6.3l4.4,4.5
                      c-0.8,1.3-1.6,2.4-2.7,3.6c-1.1,1.1-2.4,2.1-3.5,2.7L47.4,53.5z M52.6,61.2c1.7-1,3.3-2.3,4.9-3.7c1.4-1.5,2.7-3.1,3.6-5
                      c2.4,6.5,6.2,12,11.2,16.5l-3.5,3.6C64.3,67.5,58.9,63.8,52.6,61.2z M69.7,76.4l6.2-6.3l18.7,19.1l-6.2,6.3L69.7,76.4z M12.1,64.8
                      C5.9,64.8,1,69.8,1,76.1c0,6.3,4.9,11.3,11.1,11.3s11.1-5,11.1-11.3C23.2,69.8,18.2,64.8,12.1,64.8z M12.1,84.2
                      c-4.4,0-7.9-3.6-7.9-8.1c0-4.5,3.5-8.1,7.9-8.1c4.4,0,7.9,3.6,7.9,8.1C20,80.6,16.5,84.2,12.1,84.2z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
        {
					title: 'VR Racing Games',
					text: 'Drive your car through the heavy traffic or a street bike riding at heart-throbbing speed. VR racing games make everything possible without compromising your safety.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M43.2,43.2l5-5c0.8-0.8,0.8-2.1,0-2.9c-0.8-0.8-2.1-0.8-2.9,0l-5,5c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.4,0.6
                      S42.8,43.6,43.2,43.2z M54.8,35.3c-0.8-0.8-2.1-0.8-2.9,0c-0.8,0.8-0.8,2.1,0,2.9l5,5c0.4,0.4,0.9,0.6,1.4,0.6
                      c0.5,0,1-0.2,1.4-0.6c0.8-0.8,0.8-2.1,0-2.9L54.8,35.3z M94.7,76.8c2.9,0,5.3-2.4,5.3-5.4V5.3c0-2.9-2.4-5.3-5.4-5.3H5.3
                      C2.4,0,0,2.4,0,5.3v66.2c0,2.9,2.4,5.3,5.4,5.3h2.9V81H3.7c-2,0-3.7,1.7-3.7,3.7v11.6c0,2,1.7,3.7,3.7,3.7h31.4c0,0,0,0,0,0
                      c0,0,0.1,0,0.1,0h61.1c2,0,3.7-1.7,3.7-3.7V84.7c0-2-1.7-3.7-3.7-3.7h-4.6v-4.2H94.7z M30.8,88.2l-1,5.6c0,0.3-0.1,0.6-0.1,0.9
                      c0,0.4,0.1,0.8,0.2,1.2H4.1V85.1h26.2l0.5,2.7C30.8,88,30.8,88.1,30.8,88.2z M12.4,81v-4.2h2.5V81H12.4z M30.8,78.3
                      c-0.6,0.8-0.9,1.8-1,2.8H19v-4.2h12.9c0,0.1,0.1,0.2,0.1,0.2C31.6,77.4,31.2,77.8,30.8,78.3z M26.9,10.7h21v2.9c0,1.1,0.9,2,2,2
                      c1.1,0,2-0.9,2-2v-2.9h21l9.2,55.4H70.4c-0.1-2.6-0.5-5.5-1.5-8.8c2.9-2.7,4.6-6.6,4.6-10.6c0-6.5-6.2-10.2-8.6-11.4
                      c-3.6-11.8-9.3-12.1-10-12.1H52v-2.9c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v2.9H45c-0.6,0-6.3,0.3-10,12.1c-2.4,1.2-8.6,4.9-8.6,11.4
                      c0,4,1.7,7.9,4.6,10.6c-1,3.4-1.4,6.3-1.5,8.8H17.7L26.9,10.7z M13.5,66.1h-2.8V10.7h12.1L13.5,66.1z M77.2,10.7h12.1v55.4h-2.8
                      L77.2,10.7z M66.1,81.6l-1,5.5c-0.1,0.6-0.1,1.2,0,1.8l0.9,5.5c0.1,0.3,0,0.7-0.2,1c-0.2,0.3-0.5,0.5-0.9,0.5c-0.1,0-0.1,0-0.2,0
                      H35.2c-0.7,0-1.3-0.6-1.3-1.3c0-0.1,0-0.2,0-0.2l1-5.5c0.1-0.6,0.1-1.2,0-1.8l-0.9-5.5c-0.1-0.3,0-0.7,0.2-1
                      c0.2-0.3,0.5-0.5,0.9-0.5c0.1,0,0.1,0,0.3,0h29.6c0.7,0,1.3,0.6,1.3,1.3C66.1,81.5,66.1,81.6,66.1,81.6z M65,73.9
                      c0,0,0,0.1-0.1,0.1c-0.4,0.9-0.8,1.6-1.1,2H36.2c-0.3-0.4-0.7-1.1-1.1-2c0,0,0-0.1-0.1-0.1c-0.6-1.4-1.1-3.3-1.3-5.7h0l0,0
                      c-0.2-2.9,0.2-6.5,1.7-10.9c0.3-0.8,0-1.8-0.7-2.3c-2.6-1.9-4.1-5-4.1-8.3c0-5.1,6.9-8,7-8c0.6-0.2,1-0.7,1.2-1.3
                      c2.7-9.6,6.4-10.1,6.3-10.1H55c0,0,3.6,0.8,6.3,10.1c0.2,0.6,0.6,1.1,1.2,1.3c0.1,0,7,2.9,7,8c0,3.2-1.5,6.3-4.1,8.3
                      c-0.7,0.5-1,1.4-0.7,2.3C67.4,65.6,66.3,71,65,73.9z M68,77.1c0-0.1,0.1-0.2,0.1-0.3H81V81H70.2C70.1,79.4,69.2,78,68,77.1z
                      M95.9,85.1v10.8H70.1c0.2-0.7,0.2-1.4,0.1-2.2l-1-5.5c0-0.1,0-0.3,0-0.4l0.5-2.7H95.9z M85.1,81v-4.2h2.5V81H85.1z M69.7,72.8
                      c0.2-0.8,0.4-1.6,0.5-2.5h19.5c2,0,3.7-1.7,3.7-3.7V10.3c0-2-1.7-3.7-3.7-3.7H10.3c-2,0-3.7,1.7-3.7,3.7v56.2c0,2,1.7,3.7,3.7,3.7
                      h19.5c0.1,0.9,0.3,1.8,0.5,2.5h-25c-0.7,0-1.3-0.6-1.3-1.3V5.4c0-0.7,0.6-1.3,1.3-1.3h89.3c0.7,0,1.3,0.6,1.3,1.3v66.1
                      c0,0.7-0.6,1.3-1.3,1.3H69.7z M56.6,51.3H43.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h13.2c1.1,0,2-0.9,2-2
                      C58.7,52.2,57.7,51.3,56.6,51.3z M56.6,57.9H43.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h13.2c1.1,0,2-0.9,2-2
                      C58.7,58.8,57.7,57.9,56.6,57.9z M56.6,64.5H43.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h13.2c1.1,0,2-0.9,2-2
                      C58.7,65.4,57.7,64.5,56.6,64.5z M59.9,86H40.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h19.8c1.1,0,2-0.9,2-2C62,86.9,61.1,86,59.9,86z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
        {
					title: 'VR Arcade Games',
					text: 'Your traditional game friend got adorned with a new look. Live back the arcade experience with VR arcade games.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M48.3,8.4H1.7c-0.6,0-1-0.5-1-1v-2c0-2.8,2.3-5.1,5.1-5.1h38.5c2.8,0,5.1,2.3,5.1,5.1v2C49.3,8,48.9,8.4,48.3,8.4z
                      M2.7,6.4h44.6v-1c0-1.7-1.4-3-3-3H5.7c-1.7,0-3,1.4-3,3V6.4z"></path>
                    </g>
                    <g>
                      <path d="M48.3,49H1.7c-0.6,0-1-0.5-1-1v-8.1c0-0.6,0.5-1,1-1h46.6c0.6,0,1,0.5,1,1V48C49.3,48.5,48.9,49,48.3,49z M2.7,47h44.6
                      v-6.1H2.7V47z"></path>
                    </g>
                    <g>
                      <path d="M48.3,40.9c-0.3,0-0.6-0.2-0.8-0.5l-4.1-6.1c-0.3-0.5-0.2-1.1,0.3-1.4c0.5-0.3,1.1-0.2,1.4,0.3l4.1,6.1
                      c0.3,0.5,0.2,1.1-0.3,1.4C48.7,40.8,48.5,40.9,48.3,40.9z"></path>
                    </g>
                    <g>
                      <path d="M1.7,40.9c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.6-0.9-0.3-1.4l4.1-6.1c0.2-0.3,0.5-0.5,0.8-0.5H26c0.6,0,1,0.5,1,1
                      c0,0.6-0.5,1-1,1H6.3l-3.8,5.6C2.3,40.7,2,40.9,1.7,40.9z"></path>
                    </g>
                    <g>
                      <path d="M44.3,34.8c-0.5,0-0.9-0.3-1-0.8l-3.9-19.5H10.6L6.7,34c-0.1,0.5-0.5,0.8-1,0.8c0,0,0,0,0,0c-0.5,0-0.9-0.4-1-0.9L0.7,7.6
                      c-0.1-0.4,0.1-0.8,0.5-1C1.5,6.3,2,6.4,2.3,6.6l7.8,5.9h29.7l7.8-5.9c0.3-0.2,0.8-0.3,1.1-0.1c0.4,0.2,0.5,0.6,0.5,1l-4.1,26.4
                      C45.2,34.4,44.8,34.8,44.3,34.8C44.3,34.8,44.3,34.8,44.3,34.8z M41.3,13.9l2.8,14l2.8-18.2L41.3,13.9z M3.1,9.7L5.9,28l2.8-14
                      L3.1,9.7z"></path>
                    </g>
                    <g>
                      <path d="M13.8,30.8c-1.7,0-3-1.4-3-3s1.4-3,3-3s3,1.4,3,3S15.5,30.8,13.8,30.8z M13.8,26.7c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
                      s1-0.5,1-1C14.9,27.1,14.4,26.7,13.8,26.7z"></path>
                    </g>
                    <g>
                      <path d="M13.8,36.8c-0.6,0-1-0.5-1-1v-6.1c0-0.6,0.5-1,1-1s1,0.5,1,1v6.1C14.9,36.4,14.4,36.8,13.8,36.8z"></path>
                    </g>
                    <g>
                      <path d="M30.1,36.8H26c-0.6,0-1-0.5-1-1v-2c0-1.7,1.4-3,3-3s3,1.4,3,3v2C31.1,36.4,30.6,36.8,30.1,36.8z M27,34.8h2v-1
                      c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1V34.8z"></path>
                    </g>
                    <g>
                      <path d="M40.2,36.8h-4.1c-0.6,0-1-0.5-1-1v-2c0-1.7,1.4-3,3-3s3,1.4,3,3v2C41.2,36.4,40.8,36.8,40.2,36.8z M37.2,34.8h2v-1
                      c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1V34.8z"></path>
                    </g>
                    <g>
                      <path d="M36.2,34.8h-6.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h6.1c0.6,0,1,0.5,1,1C37.2,34.4,36.7,34.8,36.2,34.8z"></path>
                    </g>
                    <g>
                      <path d="M44.3,34.8h-4.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h4.1c0.6,0,1,0.5,1,1C45.3,34.4,44.8,34.8,44.3,34.8z"></path>
                    </g>
                    <g>
                      <path d="M42.2,44.9H32.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h10.1c0.6,0,1,0.5,1,1C43.3,44.5,42.8,44.9,42.2,44.9z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
        {
					title: 'VR Simulation Games',
					text: 'Simulate the real world activities in a virtual world designed by you. Be the virtual simulator and most realistic and appealing manner with our VR simulation game development.',
					svg: `
          <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M47.7,68.6H50v-3.1h-2.3V68.6z M43.1,68.6h2.3v-3.1h-2.3V68.6z M43.1,72.9h2.3v-3.1h-2.3V72.9z M83.4,55.6h3.5v-3.1h-3.5
                      V55.6z M66.2,63.1h3.5V60h-3.5V63.1z M20.7,45.8h-3.5v3.1h3.5V45.8z M11.5,16.1h35V13h-35V16.1z M49.1,16.1h5.4V13h-5.4V16.1z
                      M100,10.1V1H0v9.1h5.1v57.7H0v9.1h39.3c0.5,0.7,1,1.3,1.6,1.9h-0.7v6.5l2,1.3c1.4,0.9,2.2,2.4,2.2,4V99h7.9v-8.4
                      c0-1.6,0.8-3.2,2.2-4l2.1-1.3v-6.5h-0.7c0.6-0.6,1.1-1.2,1.6-1.9H100v-9.1h-5.1V10.1H100z M3.1,73.8v-2.9h34.3c0,0.2,0,0.3,0,0.5
                      c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4l0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.1,0,0.2l0,0c0,0.1,0,0.2,0,0.3l0,0.1
                      c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0.1,0,0.1l0,0.1H3.1z M52.9,83.9c-2.3,1.4-3.7,3.9-3.7,6.7v5.3h-1.7v-5.3
                      c0-1.7-0.5-3.3-1.5-4.6h3.2v-3.1h-6v-1h10.3l0,1.6L52.9,83.9z M56.1,71.3l0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
                      c0,0.1,0,0.2-0.1,0.3l0,0.1c0,0.1-0.1,0.3-0.1,0.4l0,0c0,0.1-0.1,0.3-0.1,0.4c0,0,0,0.1,0,0.1l0,0c0,0.1-0.1,0.2-0.1,0.2
                      c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.2-0.1,0.3l0,0.1c-1.3,2.7-4,4.4-7,4.4c-3,0-5.7-1.7-7-4.4l0-0.1
                      c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2l0,0c0,0,0-0.1,0-0.1
                      c0-0.1-0.1-0.3-0.1-0.4l0,0c0-0.1-0.1-0.3-0.1-0.4l0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3l0-0.1
                      c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2l0-0.1c0-0.1,0-0.2,0-0.3
                      c0.6-3.8,3.8-6.5,7.7-6.5c3.8,0,7,2.7,7.7,6.5c0,0.1,0,0.2,0,0.3l0,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                      c0,0.1,0,0.2,0,0.4C56.1,71,56.1,71.2,56.1,71.3z M48.4,60c-4.8,0-9.1,3.2-10.4,7.8H8.3V61l83.5-13.3v20H58.8
                      C57.4,63.2,53.2,60,48.4,60z M78.5,38.6l-11.3-7v16.9l-14.7,2.3l0,0L8.3,33.3V10.1h83.5v34.5L70.4,48v-4.4L78.5,38.6z M70.4,40
                      v-2.7l2.2,1.4L70.4,40z M46.5,51.8L8.3,57.9V36.7L46.5,51.8z M96.9,70.9v2.9h-38l0-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4
                      c0-0.1,0-0.1,0-0.2l0,0c0-0.1,0-0.2,0-0.3l0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4l0-0.1c0-0.1,0-0.2,0-0.4c0,0,0-0.1,0-0.1
                      c0-0.2,0-0.3,0-0.5H96.9z M3.1,7V4.1h93.7V7H3.1z M33.5,81.8v3.1h4.3v-3.1H33.5z M6.6,81.8v3.1h23.8v-3.1H6.6z M3.9,92.3h36.3
                      v-3.1H3.9V92.3z M82.9,81.8v3.1h7.2v-3.1H82.9z M58.9,81.8v3.1h20.9v-3.1H58.9z M56.5,92.3h36.3v-3.1H56.5V92.3z"></path>
                    </g>
                  </g>
                </svg>
          `
				},
			]
		};
	},
	components: {
		CroppedBorderCard
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>
