<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why should you invest?</span>
				<h2 class="fxt-process-dev">Why Choose Us</h2>
				<p>
					GenixBit Labs has been winning recognition from all around the world as a mobile app
					development company that builds custom solutions for various industries. Know in detail
					the qualities that makes us unique.
				</p>
			</div>
			<div class="row real-estate-type-main" style="justify-content: center">
				<div class="col-md-4 col-sm-6 col-12 mb-30" v-for="item in items" v-bind:key="item.title">
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';
export default {
	components: {
		SqureCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M46.7,6.7v1.7c0.9,0,1.7,0.7,1.7,1.7v25H1.7V10c0-0.9,0.7-1.7,1.7-1.7V6.7C1.5,6.7,0,8.2,0,10v28.3c0,1.8,1.5,3.3,3.3,3.3
                    h16.4l-2,6.7h-1.9V50h18.3v-1.7h-1.9l-2-6.7h16.4c1.8,0,3.3-1.5,3.3-3.3V10C50,8.2,48.5,6.7,46.7,6.7z M30.5,48.3H19.5l2-6.7h7.1
                    L30.5,48.3z M48.3,38.3c0,0.9-0.7,1.7-1.7,1.7H3.3c-0.9,0-1.7-0.7-1.7-1.7v-1.7h46.7V38.3z M8.3,33.3h33.3c1.8,0,3.3-1.5,3.3-3.3
                    V3.3C45,1.5,43.5,0,41.7,0H8.3C6.5,0,5,1.5,5,3.3V30C5,31.8,6.5,33.3,8.3,33.3z M6.7,3.3c0-0.9,0.7-1.7,1.7-1.7h33.3
                    c0.9,0,1.7,0.7,1.7,1.7V30c0,0.9-0.7,1.7-1.7,1.7H8.3c-0.9,0-1.7-0.7-1.7-1.7V3.3z M41.7,6.7H8.3v1.7h33.3V6.7z M15.8,10.8H8.3
                    v1.7h7.5V10.8z M14.2,14.2H8.3v1.7h5.8V14.2z M13.3,17.5h-5v1.7h5V17.5z M13.3,20.8h-5v1.7h5V20.8z M13.3,24.2h-5v1.7h5V24.2z
                    M15.8,27.5H8.3v1.7h7.5V27.5z M34.2,12.5h7.5v-1.7h-7.5V12.5z M35.8,15.8h5.8v-1.7h-5.8V15.8z M36.7,19.2h5v-1.7h-5V19.2z
                    M36.7,22.5h5v-1.7h-5V22.5z M36.7,25.8h5v-1.7h-5V25.8z M34.2,29.2h7.5v-1.7h-7.5V29.2z M10,3.3H8.3V5H10V3.3z M13.3,3.3h-1.7V5
                    h1.7V3.3z M16.7,3.3H15V5h1.7V3.3z M31.8,23.2c0.1-0.2,0.2-0.5,0.3-0.7h2.1c0.5,0,0.8-0.4,0.8-0.8v-3.3c0-0.5-0.4-0.8-0.8-0.8
                    h-2.1c-0.1-0.2-0.2-0.5-0.3-0.7l1.5-1.5c0.3-0.3,0.3-0.9,0-1.2l-2.4-2.4c-0.3-0.3-0.9-0.3-1.2,0l-1.5,1.5
                    c-0.2-0.1-0.5-0.2-0.7-0.3v-2.1c0-0.5-0.4-0.8-0.8-0.8h-3.3c-0.5,0-0.8,0.4-0.8,0.8v2.1c-0.2,0.1-0.5,0.2-0.7,0.3l-1.5-1.5
                    c-0.3-0.3-0.9-0.3-1.2,0l-2.4,2.4c-0.3,0.3-0.3,0.9,0,1.2l1.5,1.5c-0.1,0.2-0.2,0.5-0.3,0.7h-2.1c-0.5,0-0.8,0.4-0.8,0.8v3.3
                    c0,0.5,0.4,0.8,0.8,0.8h2.1c0.1,0.2,0.2,0.5,0.3,0.7l-1.5,1.5c-0.3,0.3-0.3,0.9,0,1.2l2.4,2.4c0.3,0.3,0.9,0.3,1.2,0l1.5-1.5
                    c0.2,0.1,0.5,0.2,0.7,0.3v2.1c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-2.1c0.2-0.1,0.5-0.2,0.7-0.3l1.5,1.5
                    c0.3,0.3,0.9,0.3,1.2,0l2.4-2.4c0.3-0.3,0.3-0.9,0-1.2L31.8,23.2z M30.3,26.5L29,25.1c-0.3-0.3-0.7-0.3-1-0.1
                    c-0.5,0.3-1,0.5-1.5,0.6c-0.4,0.1-0.6,0.4-0.6,0.8v1.9h-1.7v-1.9c0-0.4-0.3-0.7-0.6-0.8c-0.5-0.1-1-0.3-1.5-0.6
                    c-0.3-0.2-0.7-0.1-1,0.1l-1.3,1.3l-1.2-1.2l1.3-1.3c0.3-0.3,0.3-0.7,0.1-1c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.4-0.4-0.6-0.8-0.6h-1.9
                    v-1.7h1.9c0.4,0,0.7-0.3,0.8-0.6c0.1-0.5,0.3-1,0.6-1.5c0.2-0.3,0.1-0.7-0.1-1l-1.3-1.3l1.2-1.2l1.3,1.3c0.3,0.3,0.7,0.3,1,0.1
                    c0.5-0.3,1-0.5,1.5-0.6c0.4-0.1,0.6-0.4,0.6-0.8v-1.9h1.7v1.9c0,0.4,0.3,0.7,0.6,0.8c0.5,0.1,1,0.3,1.5,0.6c0.3,0.2,0.7,0.1,1-0.1
                    l1.3-1.3l1.2,1.2L30.2,16c-0.3,0.3-0.3,0.7-0.1,1c0.3,0.5,0.5,1,0.6,1.5c0.1,0.4,0.4,0.6,0.8,0.6h1.9v1.7h-1.9
                    c-0.4,0-0.7,0.3-0.8,0.6c-0.1,0.5-0.3,1-0.6,1.5c-0.2,0.3-0.1,0.7,0.1,1l1.3,1.3L30.3,26.5z M25,15.8c-2.3,0-4.2,1.9-4.2,4.2
                    c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2C29.2,17.7,27.3,15.8,25,15.8z M25,22.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
                    c1.4,0,2.5,1.1,2.5,2.5S26.4,22.5,25,22.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Custom Solutions',
					text: 'Every real estate mobile app we build is treated like a new project with its own specific requirements, design theme and user experience. Rest assured, your app will not look like something that is already existing on the market.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M49.8,43.6c-0.1-0.1-0.1-0.1-0.2-0.2L44.4,39c-0.4-0.4-1-0.3-1.4,0.1c0,0,0,0,0,0c-0.4,0.4-0.3,1,0.1,1.4l3.4,2.8H32.4
                    c7.9-3.7,12.9-11.5,12.8-20.1c0-0.5-0.4-1-1-1c-0.6,0-1,0.4-1,1c0,0,0,0,0,0c0,11-8.9,20-19.9,20c-11,0-19.9-9-19.9-20
                    c0-11,8.9-20,19.9-20c8.2,0,15.5,5,18.4,12.4l-4-1.3c-0.3-0.1-0.5-0.1-0.8,0.1c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.3-0.1,0.5,0.1,0.8
                    c0.1,0.2,0.3,0.4,0.6,0.5l6.4,2.1c0.3,0.1,0.6,0,0.9-0.1c0.3-0.2,0.4-0.5,0.4-0.8v-6.8c0-0.5-0.4-1-1-1c-0.6,0-1,0.4-1,1
                    c0,0,0,0,0,0v2.8c-3.6-7.4-11.2-12-19.5-12.1c-12.1,0-21.9,9.9-21.9,22c0,8.6,5,16.4,12.8,20.1H1c-0.6,0-1,0.5-1,1
                    c0,0.5,0.5,1,1,1h44.3l-2.5,1.6c-0.5,0.3-0.6,0.9-0.3,1.4c0.3,0.5,0.9,0.6,1.4,0.3c0,0,0,0,0,0l5.7-3.6C50,44.7,50.1,44,49.8,43.6
                    z M22.9,14.3V11c-2.9,0.1-5.6,1.3-7.7,3.2l2.3,2.3C19,15.2,20.9,14.4,22.9,14.3z M11.1,22.9h3.2c0.1-2,0.9-3.9,2.2-5.5L16,16.8
                    l-1.7-1.7C12.4,17.3,11.3,20,11.1,22.9z M14.3,31.8l2.3-2.3c-1.3-1.5-2.1-3.4-2.2-5.5h-3.2C11.3,26.9,12.4,29.7,14.3,31.8z
                    M22.9,32.6c-2-0.1-3.9-0.9-5.4-2.3l-2.3,2.3c2.1,1.9,4.8,3.1,7.7,3.2V32.6z M35.8,23.5c0-6.7-5.2-12.1-11.8-12.4v3.3
                    c5.2,0.5,9,4.9,8.5,10c-0.4,4.4-4,7.9-8.5,8.3v3.3C30.6,35.6,35.8,30.1,35.8,23.5z M23.5,30.3c3.8-0.1,6.8-3.1,6.8-6.8
                    c0-3.7-3-6.8-6.8-6.8c0,0,0,0,0,0c-1.8,0-3.6,0.7-4.8,2c-2.7,2.7-2.7,6.9,0,9.7C19.9,29.6,21.7,30.3,23.5,30.3
                    C23.5,30.3,23.5,30.3,23.5,30.3z M23.5,28.6c-1.3,0-2.6-0.5-3.6-1.5c-2-2-2-5.3,0-7.3c0.9-0.9,2.2-1.5,3.6-1.5c0,0,0,0,0,0
                    c2.8,0,5.1,2.3,5.1,5.1v0C28.6,26.3,26.3,28.6,23.5,28.6C23.5,28.6,23.5,28.6,23.5,28.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Rapid Development',
					text: 'Gain faster entry to market, win leads faster and close them easier. We have a streamlined and organized process of mobile app development that helps rapid release in app stores.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M39.2,20.9C38.6,14,32.8,8.5,25.8,8.2c-8.2-0.4-15,6-15,13.9c0,3.8,1.6,7.4,4.4,10c2.4,2.2,3.9,5.5,4.2,9
                    c-0.9,0.3-1.6,1.1-1.6,2.1c0,1.2,1,2.3,2.4,2.3c0.4,2.3,2.4,4.1,4.9,4.1c2.4,0,4.5-1.8,4.9-4.1c1.3,0,2.4-1,2.4-2.3
                    c0-1-0.6-1.8-1.6-2.1c0.2-3.4,1.8-6.7,4.2-9C38,29.1,39.5,25,39.2,20.9z M25,48c-1.6,0-2.9-1.1-3.3-2.5h6.6C28,46.9,26.6,48,25,48
                    z M29.9,44h-9.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h9.7c0.5,0,0.8,0.4,0.8,0.8C30.6,43.6,30.3,44,29.9,44z M26.6,28
                    v12.9h-3.3V28H26.6z M23.7,26.5l1.3-2.7l1.3,2.7H23.7z M29.2,40.8h-0.9V27.2c0-0.1,0-0.2-0.1-0.3l-2.4-5.3
                    c-0.2-0.3-0.4-0.5-0.7-0.5s-0.5,0.2-0.7,0.5l-2.4,5.3c-0.1,0.1-0.1,0.2-0.1,0.3v13.6h-0.9c-0.2-3.8-2-7.4-4.6-9.9
                    c-2.5-2.4-3.9-5.5-3.9-9c0-7,6-12.8,13.4-12.4c6.3,0.3,11.5,5.2,12,11.3c0.3,3.8-1.1,7.4-3.8,10C31.1,33.5,29.4,37.2,29.2,40.8z
                    M35,21h-2c-0.7-1.4-1.7-2.5-3-3.4h2c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-4.2V15c0-0.4-0.3-0.8-0.8-0.8H23
                    c-0.4,0-0.8,0.3-0.8,0.8v1.1h-4.2c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h2c-1.3,0.8-2.3,2-3,3.4h-2
                    c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8H19c0.4,0,0.8-0.3,0.8-0.8v-3.8c0-0.4-0.3-0.8-0.8-0.8h-0.2
                    c0.8-1.3,2-2.3,3.5-2.8v0.6c0,0.4,0.3,0.8,0.8,0.8H27c0.4,0,0.8-0.3,0.8-0.8v-0.6c1.4,0.5,2.7,1.6,3.5,2.8H31
                    c-0.4,0-0.8,0.3-0.8,0.8v3.8c0,0.4,0.3,0.8,0.8,0.8H35c0.4,0,0.8-0.3,0.8-0.8v-3.8C35.7,21.3,35.4,21,35,21z M18.3,24.8h-2.4v-2.4
                    h2.4V24.8z M26.2,18h-2.4v-2.4h2.4V18z M34.2,24.8h-2.4v-2.4h2.4V24.8z M46.3,21.2h-4.5c-0.4,0-0.8,0.3-0.8,0.8
                    c0,0.4,0.3,0.8,0.8,0.8h4.5c0.4,0,0.8-0.4,0.8-0.8C47,21.6,46.7,21.2,46.3,21.2z M39.8,14.5l3.9-2.2c0.4-0.2,0.5-0.7,0.3-1
                    c-0.2-0.4-0.7-0.5-1-0.3l-3.9,2.2c-0.4,0.2-0.5,0.7-0.3,1C39,14.6,39.5,14.7,39.8,14.5z M33,8.5c0.4,0.2,0.9,0.1,1-0.3l2.3-3.8
                    c0.2-0.4,0.1-0.8-0.3-1c-0.4-0.2-0.8,0-1,0.3l-2.3,3.8C32.5,7.9,32.6,8.3,33,8.5z M25.1,6.5c0.5,0,0.8-0.3,0.8-0.8V1.3
                    c0-0.4-0.3-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8v4.4C24.3,6.1,24.6,6.5,25.1,6.5z M16,8.2c0.2,0.4,0.7,0.5,1,0.3
                    c0.4-0.2,0.5-0.7,0.3-1L15,3.6c-0.2-0.4-0.6-0.5-1-0.3c-0.4,0.2-0.5,0.7-0.3,1L16,8.2z M6.3,12.2l3.9,2.2c0.4,0.2,0.9,0.1,1-0.3
                    c0.2-0.4,0.1-0.8-0.3-1L7,10.9c-0.4-0.2-0.9-0.1-1.1,0.3C5.7,11.6,5.9,12.1,6.3,12.2z M9.1,21.9c0-0.4-0.3-0.8-0.8-0.8H3.7
                    c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h4.5C8.7,22.7,9.1,22.4,9.1,21.9z M10.2,29.4l-3.9,2.2c-0.4,0.2-0.5,0.7-0.3,1
                    C6.2,33,6.6,33.2,7,33l3.9-2.2c0.4-0.2,0.5-0.7,0.3-1C11,29.4,10.5,29.3,10.2,29.4z M43.7,31.7l-3.9-2.2c-0.4-0.2-0.9-0.1-1,0.3
                    c-0.2,0.3,0,0.8,0.3,1L43,33c0.4,0.2,0.9,0.1,1-0.3C44.2,32.3,44.1,31.9,43.7,31.7z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Interactive Design',
					text: 'Design speaks volumes, especially for a real estate mobile app, design is the cornerstone of user experience. We ensure that your real estate app has the right design elements that will make it user interactive.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M4.3,35.7C4.5,35.9,4.7,36,5,36c0.3,0,0.5-0.1,0.7-0.3l2-2c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L6,32.6V24h18v8.6l-0.3-0.3
                    c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2,2c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l2-2c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0
                    L26,32.6V24h18v8.6l-0.3-0.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2-2
                    c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L46,32.6V23c0-0.6-0.4-1-1-1H26v-8h9c0.6,0,1-0.4,1-1V1c0-0.6-0.4-1-1-1H15
                    c-0.6,0-1,0.4-1,1v12c0,0.6,0.4,1,1,1h9v8H5c-0.6,0-1,0.4-1,1v9.6l-0.3-0.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L4.3,35.7z
                    M16,12V2h18v10H16z M9,40H1c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-8C10,40.4,9.6,40,9,40z M8,48H2v-6h6V48z
                    M25,40c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C30,42.2,27.8,40,25,40z M25,48c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3
                    c1.7,0,3,1.3,3,3C28,46.6,26.7,48,25,48z M49.9,48.5l-4-8c-0.3-0.7-1.4-0.7-1.8,0l-4,8c-0.2,0.3-0.1,0.7,0,1
                    c0.2,0.3,0.5,0.5,0.8,0.5h8c0.3,0,0.7-0.2,0.9-0.5C50,49.2,50,48.9,49.9,48.5z M42.6,48l2.4-4.8l2.4,4.8H42.6z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Adopt Agile Methodology',
					text: 'GenixBit Labs takes pride in following the Agile project management methodology. We have dedicated project manager and scrum masters who ensure that the project marches forward in full swing to achieve all milestones.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M48.4,41.9V15.3c0-2.2-1.8-4-4-4h-8.9c0-0.3,0-0.5,0-0.8C35.5,4.7,30.8,0,25,0c-5.8,0-10.5,4.7-10.5,10.5
                    c0,0.3,0,0.5,0,0.8H5.6c-2.2,0-4,1.8-4,4v26.6H0v4c0,2.2,1.8,4,4,4H46c2.2,0,4-1.8,4-4v-4H48.4z M35.2,12.9h9.2
                    c1.3,0,2.4,1.1,2.4,2.4v26.6h-1.6V16.1c0-0.9-0.7-1.6-1.6-1.6h-8.9C34.9,14,35.1,13.5,35.2,12.9z M6.5,41.9L6.5,41.9V16.1h9.7
                    c0.3,0.5,0.7,1,1.1,1.4L14.9,20l1.2,2.1c0,0,0,0.1,0,0.1l-2.3,0.6v6.1l2.3,0.6c0,0,0,0.1,0,0.1l-1.2,2.1l4.3,4.3l2.1-1.2
                    c0,0,0.1,0,0.1,0l0.6,2.3H28l0.6-2.3c0,0,0.1,0,0.1,0l2.1,1.2l4.3-4.3l-1.2-2.1c0,0,0-0.1,0-0.1l2.3-0.6v-6.1L34,22.2
                    c0,0,0-0.1,0-0.1l1.2-2.1l-2.4-2.4c0.4-0.4,0.8-0.9,1.1-1.4h9.7v25.8H6.5z M32.3,43.5v0.8c0,0.4-0.4,0.8-0.8,0.8H18.5
                    c-0.4,0-0.8-0.4-0.8-0.8v-0.8H32.3z M18.5,25.8c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5c0-2.3-1.2-4.3-3-5.4
                    c1.1-0.4,2.2-1,3.1-1.7l1.6,1.6l-1,1.7l0.2,0.4c0.1,0.3,0.2,0.5,0.3,0.8l0.1,0.4l1.9,0.5v3.6l-1.9,0.5l-0.1,0.4
                    c-0.1,0.3-0.2,0.5-0.3,0.8l-0.2,0.4l1,1.7l-2.5,2.5l-1.7-1l-0.4,0.2c-0.3,0.1-0.5,0.2-0.8,0.3l-0.4,0.1l-0.5,1.9h-3.6l-0.5-1.9
                    l-0.4-0.1c-0.3-0.1-0.5-0.2-0.8-0.3l-0.4-0.2l-1.7,1l-2.5-2.5l1-1.7l-0.2-0.4c-0.1-0.3-0.2-0.5-0.3-0.8l-0.1-0.4l-1.9-0.5V24
                    l1.9-0.5l0.1-0.4c0.1-0.3,0.2-0.5,0.3-0.8l0.2-0.4l-1-1.7l1.6-1.6c0.9,0.7,2,1.3,3.1,1.7C19.7,21.5,18.5,23.5,18.5,25.8z M25,21
                    c2.7,0,4.8,2.2,4.8,4.8c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8C20.2,23.1,22.3,21,25,21z M25,1.6c4.9,0,8.9,4,8.9,8.9
                    c0,4.9-4,8.9-8.9,8.9c-4.9,0-8.9-4-8.9-8.9C16.1,5.6,20.1,1.6,25,1.6z M3.2,15.3c0-1.3,1.1-2.4,2.4-2.4h9.2
                    c0.1,0.6,0.3,1.1,0.5,1.6H6.5c-0.9,0-1.6,0.7-1.6,1.6v25.8H3.2V15.3z M48.4,46c0,1.3-1.1,2.4-2.4,2.4H4c-1.3,0-2.4-1.1-2.4-2.4
                    v-2.4h14.5v0.8c0,1.3,1.1,2.4,2.4,2.4h12.9c1.3,0,2.4-1.1,2.4-2.4v-0.8h14.5V46z M4.8,46.8h1.6v-1.6H4.8V46.8z M8.1,46.8h1.6v-1.6
                    H8.1V46.8z M11.3,46.8h1.6v-1.6h-1.6V46.8z M25,29c1.8,0,3.2-1.4,3.2-3.2c0-1.8-1.4-3.2-3.2-3.2c-1.8,0-3.2,1.4-3.2,3.2
                    C21.8,27.6,23.2,29,25,29z M25,24.2c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
                    C23.4,24.9,24.1,24.2,25,24.2z M25,14.5c-0.9,0-1.6-0.7-1.6-1.6h-1.6c0,1.5,1,2.8,2.4,3.1v1.7h1.6V16c1.4-0.4,2.4-1.6,2.4-3.1
                    c0-1.8-1.4-3.2-3.2-3.2c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6h1.6c0-1.5-1-2.8-2.4-3.1V3.2h-1.6V5
                    c-1.4,0.4-2.4,1.6-2.4,3.1c0,1.8,1.4,3.2,3.2,3.2c0.9,0,1.6,0.7,1.6,1.6C26.6,13.8,25.9,14.5,25,14.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Cost Efficient Solutions',
					text: 'Quality should not always come with a high price. As a Real Estate Mobile App Development Company, we ensure that our services are priced aptly for all range of businesses.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M27.8,19.5v-3.7c0-1.1-0.9-2-2-2H2c-1.1,0-2,0.9-2,2v15.7v2.7c0,1.1,0.9,2,2,2h11.1v2.4H8.8c-0.4,0-0.8,0.4-0.8,0.8
                    c0,0.4,0.4,0.8,0.8,0.8H19c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-4.3v-2.4h11.1c1.1,0,2-0.9,2-2v-2.7v-0.8
                    c3-0.2,5.3-2.6,5.3-5.6C33.1,22.1,30.8,19.7,27.8,19.5z M26.2,34.2c0,0.2-0.2,0.4-0.4,0.4H2c-0.2,0-0.4-0.2-0.4-0.4v-1.9h24.6
                    V34.2z M26.2,30.7H1.6V15.8c0-0.2,0.2-0.4,0.4-0.4h23.7c0.2,0,0.4,0.2,0.4,0.4v3.8c-2.6,0.5-4.5,2.8-4.5,5.5c0,2.7,1.9,5,4.5,5.5
                    V30.7z M27.4,29.1c-2.3,0-4.1-1.8-4.1-4c0-2.2,1.8-4,4.1-4c2.3,0,4.1,1.8,4.1,4C31.5,27.3,29.7,29.1,27.4,29.1z M6.4,24.4H4.7
                    c-0.4,0-0.8,0.4-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8h1.6c0.4,0,0.8-0.4,0.8-0.8v-1.6C7.2,24.8,6.8,24.4,6.4,24.4z M5.6,26L5.6,26
                    L5.6,26L5.6,26L5.6,26z M19.8,25.4H9.4c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h10.4c0.4,0,0.8-0.4,0.8-0.8
                    C20.6,25.7,20.3,25.4,19.8,25.4z M6.4,18.4H4.7c-0.4,0-0.8,0.4-0.8,0.8v1.6c0,0.4,0.4,0.8,0.8,0.8h1.6c0.4,0,0.8-0.4,0.8-0.8v-1.6
                    C7.2,18.7,6.8,18.4,6.4,18.4z M5.6,20L5.6,20L5.6,20L5.6,20L5.6,20z M19.8,19.3H9.4c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8
                    h10.4c0.4,0,0.8-0.4,0.8-0.8C20.6,19.7,20.3,19.3,19.8,19.3z M28.8,22.8l-2.1,2.2l-0.6-0.4c-0.4-0.3-0.9-0.2-1.1,0.2
                    c-0.3,0.4-0.2,0.9,0.2,1.1l1.2,0.8c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4-0.1,0.6-0.3l2.6-2.8c0.3-0.3,0.3-0.8,0-1.1
                    C29.6,22.5,29.1,22.5,28.8,22.8z M39.8,8.2c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-4c-0.4,0-0.8,0.4-0.8,0.8v16.5h-0.6
                    c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8H35v17.1c0,0.4,0.4,0.8,0.8,0.8l4,0c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8
                    c0-0.4-0.4-0.8-0.8-0.8l-3.2,0V25.5H39c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-2.4V8.2H39.8z M49.2,3.1h-7.4
                    c-0.4,0-0.8,0.4-0.8,0.8v7.2c0,0.4,0.4,0.8,0.8,0.8h7.4c0.4,0,0.8-0.4,0.8-0.8V3.9C50,3.5,49.6,3.1,49.2,3.1z M48.4,10.4h-5.8V4.7
                    h5.8V10.4z M49.2,38h-7.4c-0.4,0-0.8,0.4-0.8,0.8v7.2c0,0.4,0.4,0.8,0.8,0.8h7.4c0.4,0,0.8-0.4,0.8-0.8v-7.2
                    C50,38.4,49.6,38,49.2,38z M48.4,45.3h-5.8v-5.6h5.8V45.3z M49.2,20.6h-7.4c-0.4,0-0.8,0.4-0.8,0.8v7.2c0,0.4,0.4,0.8,0.8,0.8h7.4
                    c0.4,0,0.8-0.4,0.8-0.8v-7.2C50,20.9,49.6,20.6,49.2,20.6z M48.4,27.8h-5.8v-5.6h5.8V27.8z M43.9,8.4l1,0.5C45,9,45.1,9,45.2,9
                    c0.2,0,0.4-0.1,0.5-0.2l1.8-1.6c0.3-0.3,0.4-0.8,0.1-1.1c-0.3-0.3-0.8-0.4-1.1-0.1l-1.4,1.2L44.6,7c-0.4-0.2-0.9,0-1.1,0.4
                    C43.3,7.7,43.5,8.2,43.9,8.4z M43.9,25.9l1,0.5c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2l1.8-1.6c0.3-0.3,0.4-0.8,0.1-1.1
                    c-0.3-0.3-0.8-0.4-1.1-0.1l-1.4,1.2l-0.5-0.3c-0.4-0.2-0.9,0-1.1,0.4C43.3,25.2,43.5,25.7,43.9,25.9z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'On-Time Delivery',
					text: 'The market does not wait for anything or anyone. We know that better than anyone else. That is why we put greater emphasis on delivering projects on time, without any delay.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
