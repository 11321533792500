<template>
	<section class="padding-60" style="background: #fff">
		<div class="container">
			<div class="title">
				<span>Your customized VR game solutions</span>
				<h2 class="fxt-off-dev">VR Mobile Games we Build are Device-friendly</h2>
				<p>
					Mobility and responsiveness are the key ingredients when it comes to building successful
					technology. Our team of VR game developers ensures that your game is supported by every
					possible device.
				</p>
			</div>
			<div class="row justify-content-between" style=" flex-wrap: wrap">
				<IconSquare
					v-for="card in cards"
					v-bind:obj="card"
					class="col-6 col-sm-6 col-md-2 col-lg-2"
					v-bind:key="card.title"
				>
				</IconSquare>
			</div>
		</div>
	</section>
</template>

<script>
import IconSquare from '../AssetComponents/IconSquare';

export default {
	data: () => {
		return {
			cards: [
            {
               title:"Gear VR",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <path d="M49.6,22.4c0-0.6,0-1.3-0.1-1.9c-0.2-1.5-1.3-2.3-2.4-3.1c-2.8-2-6-3.4-8.9-4.4c-2-0.7-4.4-1.6-6.8-2.1
                  c-2.2-0.5-4.5-0.6-7.6-0.2c-2,0.2-3.9,0.5-5.7,1l-0.5,0.1c-1.1,0.3-2.3,0.6-3.4,1c-0.5,0.2-1.1,0.3-1.7,0.3c-0.3,0-0.6,0.1-0.9,0.1
                  c-0.6,0.1-1.2,0.2-1.8,0.3c-2.3,0.3-4.6,0.7-6.9,1.5c-1.4,0.6-2.2,1.7-2.3,3.4l-0.1,0.8c-0.1,1.4-0.2,2.8-0.1,4.3
                  c0.1,1.3,0.3,2.7,0.7,4.4c0.4,1.5,1.4,2.8,3,4c3.5,2.4,7.7,5.3,12.4,7.7c1.1,0.6,2.3,0.9,3.5,0.9c0,0,0,0,0.1,0
                  c1.9,0,3.9-0.1,5.9-0.7c2.4-0.7,4.7-1.8,6.9-2.8c0.5-0.2,1-0.5,1.5-0.7c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.1,0.5-0.3,0.7-0.3
                  c1-0.3,1.8-1,2.2-2c1.6-0.1,2.8-0.9,4-1.7c0.3-0.2,0.5-0.3,0.8-0.5c1.7-1.1,3.1-2,4.2-3.1c1.7-1.5,2.6-3.4,2.6-5.5
                  C49.6,22.7,49.6,22.6,49.6,22.4z M47.5,19.2c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.3,0.3-0.1,0.7
                  c-0.4,0.5-0.9,0.8-1.5,1.1c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.6-1.1-1.1-1.7-1.6c-0.4-0.3-0.5-0.5-0.5-1c-0.1-0.3-0.1-0.6-0.1-0.9
                  c0-0.6-0.1-1.2-0.1-1.8c1.3,0.6,2.4,1.3,3.6,2.2C46.8,18.6,47.1,18.9,47.5,19.2z M14.6,13.9c0.1-0.1,0.2-0.1,0.3-0.1
                  c0,0.8,0.1,1.5,0.3,2.1C14.7,15.3,14.5,14.6,14.6,13.9z M16,13.7c0-0.1-0.1-0.2-0.1-0.3c0.3-0.1,0.7-0.2,1.1-0.3
                  c0.4,0.4,0.6,0.9,0.8,1.6c0.5,1.9,1.9,3.2,4.2,4.1c3.6,1.3,7.2,2.1,10.6,2.8c1.2,0.2,2.4,0.6,3.8,1c1.3,0.4,1.9,1.4,2.1,3
                  c0,0.1,0,0.2,0.1,0.3c-0.2,0.1-0.3,0.2-0.5,0.2l0,0c-0.1-1.5-0.9-2.4-2.3-2.8c-0.3-0.1-0.5-0.2-0.8-0.2c-0.5-0.2-1.1-0.3-1.7-0.5
                  c-0.9-0.2-1.8-0.5-2.8-0.7c-1.6-0.4-3.2-0.8-4.8-1.3l-0.3-0.2c-2.3-0.6-4.7-1.3-6.8-2.3C16.7,17.2,15.9,15.8,16,13.7z M36.9,25.9
                  c-0.1-0.5-0.3-0.9-0.7-1.3C36.7,24.9,36.9,25.4,36.9,25.9z M37.8,27.9l-0.1-0.5c0.3-0.1,0.6-0.2,0.9-0.4c0.2-0.1,0.3-0.2,0.5-0.2
                  c0.1-0.1,0.2-0.2,0.2-0.2l0,0c0.1,0,0.1-0.1,0.1-0.1l0.2,1.1c0.1,1,0.3,1.9,0.4,2.9c0.1,0.3,0.1,0.7,0.2,1c0,0.1,0,0.1,0.1,0.2
                  c-0.6,0.4-1.3,0.7-2,0.7C38.1,30.9,38,29.4,37.8,27.9z M39.9,25.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2-2-1.2-3.3-2.9-3.8
                  c-1.4-0.4-2.7-0.7-3.9-1c-3.4-0.7-6.9-1.5-10.5-2.8c-0.7-0.2-1.3-0.6-1.7-0.9h0.1c0.7-0.2,1.4-0.3,2-0.4c1.3-0.1,2.5-0.1,3.8,0H27
                  c1.4,0.1,2.8,0.1,4.2,0.3c1.4,0.2,2.8,0.6,4.3,1l0.5,0.1c0.2,0.1,0.5,0.3,0.5,0.4c0.6,1.4,1.8,1.9,2.8,2.4c0.2,0.1,0.3,0.1,0.5,0.2
                  c0.5,0.2,1,0.3,1.4,0.3c0.5,0,1-0.2,1.4-0.6c0.1,0.1,0.2,0.1,0.2,0.2c0.6,0.4,1,0.9,1.5,1.4c0.1,0.1,0.2,0.2,0.2,0.2
                  c-1.2,0.6-2.4,1.4-3.7,2.2C40.6,25,40.2,25.1,39.9,25.2z M37.7,18.1c0-0.1,0.1-0.2,0.1-0.2s0.1,0,0.3,0.1c0.6,0.2,1.3,0.5,1.9,0.8
                  c0.6,0.3,1,0.7,1.3,1.3c-0.2,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.3-0.1-0.5-0.2C38.9,19.3,38.1,18.9,37.7,18.1z M41.6,15.6v0.5
                  c0,0.6,0.1,1.3,0.1,1.9c0,0.4,0.1,0.7,0.1,1c-0.4-0.5-0.9-0.9-1.5-1.2S39,17.3,38.4,17c-0.5-0.2-0.9-0.2-1.2-0.1
                  c-0.1,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.5-0.2-0.9-0.2-1.4c-0.1-0.5-0.1-1,0-1.6v-0.1c0.3,0.1,0.7,0.2,1,0.4
                  C39.1,14.5,40.4,15,41.6,15.6z M23.9,11.8c1.2-0.1,2.3-0.2,3.2-0.2c1.5,0,2.7,0.1,4,0.4c1.5,0.3,3.1,0.8,4.5,1.3c0,0.2,0,0.3,0,0.5
                  c0,0.6,0,1.1,0,1.7c0.1,0.5,0.1,0.9,0.2,1.3h-0.1c-1.4-0.4-2.9-0.8-4.4-1c-1.4-0.2-2.9-0.3-4.3-0.3h-0.6c-1.4-0.1-2.7-0.1-4,0
                  c-0.7,0.1-1.5,0.2-2.2,0.4c-0.3,0.1-0.5,0.1-0.8,0.2c-0.3-0.5-0.6-1-0.7-1.5c-0.1-0.5-0.3-1.1-0.7-1.6l0.1-0.1
                  C20.1,12.3,21.9,11.9,23.9,11.8z M9.9,14.6c0.6-0.1,1.2-0.2,1.8-0.3c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0,0.6-0.1,0.9-0.1
                  c0,1.5,0.8,2.8,2.3,3.7c0.2,0.1,0.5,0.2,0.6,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.1,0c0.4,0.3,0.9,0.7,1.5,1
                  c2.3,1.1,4.7,1.8,7,2.4l0.3,0.1c1.6,0.5,3.3,0.9,4.9,1.3c0.9,0.2,1.8,0.5,2.7,0.7c0.1,0.1,0.1,0.1,0.1,0.2l0.5,0.4
                  c0.4,0.3,0.9,0.6,1.3,0.9c0.2,0.2,0.5,0.4,0.6,0.6c-0.1-0.1-0.3-0.2-0.6-0.2c-0.6,0.2-1.1,0.3-1.7,0.5c-1.3,0.3-2.7,0.7-4,1.1
                  c-0.1,0-0.1,0-0.1,0c-0.6-1-1.5-1.7-2.8-2.3c-2-0.9-4.2-1.8-6.2-2.6c-0.9-0.3-1.9-0.7-2.8-1.1c-3.5-1.4-7.1-3.1-10.2-5.9
                  C8,14.9,8.9,14.8,9.9,14.6z M28.7,31.2c-0.2,2.1-0.6,3.8-1.1,5.2c-0.1,0.3-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
                  c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.1,0,0.1-0.1,0.1h-0.1l0,0c0-0.3-0.3-0.6-0.6-0.6h-0.5c-0.3,0-0.7,0.1-1.1,0.1c0.1-0.5,0.1-1,0.2-1.4
                  l0.1-0.3c1-0.1,1.3-0.3,1.5-1.6c0-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.1-0.4,0.1-0.6c0.2-0.7,0.3-1.2-0.1-1.5c-0.2-0.3-0.5-0.3-1-0.3
                  c0-0.1,0.1-0.2,0.1-0.3c0.2-0.9,0.5-1.4,1.1-1.6c0.3-0.1,0.6-0.3,0.9-0.4l0.4-0.2c0.3-0.1,0.4-0.5,0.3-0.7c-0.1-0.1-0.1-0.2-0.2-0.2
                  c0.2-0.1,0.5-0.1,0.7-0.2C28.7,29.1,28.8,30,28.7,31.2z M5.8,15.3c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,1,0.9
                  c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c-0.8-0.5-1.7-0.9-2.6-1.1C4.6,15.7,5.2,15.5,5.8,15.3z M11,19.3L11,19.3
                  c1,0.6,2,1.1,3,1.5c0,0.1-0.1,0.1-0.1,0.1c-0.6-0.3-1.2-0.6-1.8-0.9c-0.5-0.2-0.9-0.5-1.3-0.7C10.9,19.4,10.9,19.3,11,19.3z
                  M20.9,24c0.1-0.1,0.1-0.2,0.1-0.3c1.7,0.6,3.3,1.3,4.9,2c1,0.5,1.7,0.9,2.1,1.5c-0.5,0.1-0.9,0.2-1.3,0.2c-0.4,0.1-0.8,0.1-1.2,0.2
                  c-0.2,0.1-0.4,0.1-0.6,0.1l-0.2,0.1c-0.3,0.1-0.5,0.3-0.5,0.7c0.1,0.3,0.3,0.5,0.6,0.5c0.1,0,0.1,0,0.1,0l0.2-0.1
                  c0.2-0.1,0.5-0.1,0.6-0.1c0.3-0.1,0.6-0.1,1-0.2c-0.2,0.1-0.5,0.2-0.7,0.3c-0.9,0.3-1.4,1-1.7,2c-0.1-2.8-1.3-5.2-3.5-6.7
                  C20.8,24.2,20.8,24.1,20.9,24z M25,33c0.1,0,0.2,0,0.2,0c0,0.1-0.1,0.3-0.1,0.5C25.1,33.8,25,34,25,34.2l-0.1,0.3
                  c-0.1,0.2-0.1,0.5-0.1,0.6c-0.1,0-0.1,0.1-0.2,0.1c0.1-0.7,0.2-1.4,0.3-2C24.9,33.1,25,33.1,25,33z M23.3,38.9
                  c0.1,0.1,0.2,0.2,0.5,0.2c-0.5,0.1-1,0.1-1.5,0.2c0.3-0.5,0.6-1,0.9-1.4c0,0.1,0,0.1,0,0.1C23.1,38.4,23.2,38.7,23.3,38.9z
                  M20.2,39.4c-1.1,0-2-0.2-3.1-0.7c-4.6-2.4-8.8-5.2-12.3-7.7c-1.4-1-2.3-2.1-2.6-3.4c-0.4-1.7-0.6-3-0.7-4.2c-0.1-1.4,0-2.8,0.1-4.2
                  l0.1-0.8c0.1-0.8,0.3-1.4,0.8-1.8l0,0l0.1,0.1c0.1,0.1,0.3,0.1,0.5,0.1c1.1,0.2,2,0.6,3,1.1c1.8,1,3.6,2.2,5.6,3.2
                  c1.4,0.7,2.8,1.4,4.2,2c1,0.5,2,1,3,1.4c1.5,0.7,4.8,2.9,4.1,7.9c-0.1,0.3-0.1,0.6-0.1,1s-0.1,0.7-0.1,1l-0.1,0.5
                  c-0.3,1.6-0.5,3.1-1.7,4.3c-0.1,0.1-0.2,0.2-0.2,0.3C20.6,39.4,20.4,39.4,20.2,39.4z M32.5,35.9c-1.5,0.7-3,1.4-4.6,2
                  c0.1-0.1,0.1-0.1,0.1-0.2c0.2-0.3,0.4-0.6,0.5-1c0.6-1.6,1-3.3,1.2-5.5c0.1-1.3,0.1-2.4-0.2-3.3c1.3-0.3,2.7-0.7,3.9-1
                  c0.6-0.2,1.1-0.3,1.7-0.5c0.3-0.1,0.5-0.3,0.4-0.7c0.1,0.2,0.1,0.3,0.2,0.6c0,0.1,0,0.1,0.1,0.2c-0.3,0.3-0.4,0.8-0.5,1.1
                  c-0.1,0.3-0.1,0.7-0.1,1c-0.1,1.4-0.2,2.7-0.7,4c-0.2,0.6-0.3,1.1-0.4,1.7c-0.1,0.2-0.1,0.5-0.1,0.7c-0.1,0-0.1,0.1-0.1,0.1
                  C33.6,35.4,33,35.7,32.5,35.9z M35.5,34.5c0.1-0.5,0.2-1,0.3-1.4c0.5-1.4,0.6-2.9,0.7-4.3c0-0.3,0.1-0.7,0.1-1c0-0.1,0-0.2,0.1-0.3
                  l0,0l0.1,0.5c0.2,1.6,0.3,3.2,0.5,4.7c0,0.1,0,0.1,0,0.1s0,0.1-0.1,0.1L37.1,33C36.7,33.8,36.2,34.2,35.5,34.5z M46.3,27.5
                  c-1.1,1-2.4,1.9-4,3c-0.3,0.2-0.6,0.3-0.9,0.6L41.3,31c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-1-0.3-1.9-0.4-2.8l-0.2-1.2
                  c0-0.1,0-0.1-0.1-0.1c0.3-0.1,0.7-0.2,1-0.5c1.4-1,2.7-1.8,4-2.4c0.2-0.1,0.4-0.2,0.6-0.3l0.1,0.2c0.1,0.6,0.2,1.2,0.3,1.8
                  c0.1,0.4,0.2,0.9,0.2,1.3l0.1,0.5c0,0.1,0.1,0.1,0.1,0.1C46.7,27.1,46.5,27.3,46.3,27.5z M47.8,25.6c-0.1-0.3-0.1-0.5-0.2-0.8
                  c-0.1-0.6-0.2-1.2-0.3-1.8l-0.1-0.6c0.3-0.2,0.6-0.5,0.9-0.8c0.2-0.2,0.3-0.3,0.3-0.5c0.1,0.4,0.1,0.9,0.1,1.3c0,0.2,0,0.3,0,0.5
                  C48.4,23.9,48.2,24.8,47.8,25.6z"></path>
                </svg>
               `
            },
            {
               title:"Oculus",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M87.2,22.5H87C79.4,10.2,67.1,3.1,54.1,3.1c-13.1,0-25.3,7.3-32.9,19.4H21c-5.3,0-9.9,3.3-11.8,8C4,30.8,0,35.2,0,40.5
                      v4.8c0,5.3,4.3,9.7,9.5,10.1c1.6,3.6,4.9,6.2,8.8,7.2c4,19.9,18.9,34.4,35.8,34.4C71,96.9,85.8,82.6,90,62.5
                      c5.7-1.2,10-6.2,10-12.2V35.1C99.9,28.2,94.3,22.5,87.2,22.5z M8.4,34.9v15.9c-2.5-0.7-4.3-2.9-4.3-5.7v-4.8
                      c0-2.7,1.7-4.9,4.3-5.7C8.4,34.8,8.4,34.8,8.4,34.9z M54.1,7.2c10.8,0,21,5.7,27.9,15.3H26.2C33,12.9,43.3,7.2,54.1,7.2z M54,92.6
                      c-14.6,0-27.5-12.5-31.4-29.9h22.1l9.5-10.1l9.5,10.1h21.9C81.6,80.1,68.6,92.6,54,92.6z M95.7,50.2c0,4.6-3.7,8.4-8.4,8.4H65.4
                      L54,46.5L42.6,58.6H21c-4.7,0-8.4-3.7-8.4-8.4V35.1c0-4.6,3.7-8.4,8.4-8.4h66.3c4.7,0,8.4,3.7,8.4,8.4V50.2z M74.6,33.6
                      c-0.8-0.8-2.1-0.8-2.9,0L64,41.2c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7l7.6-7.6
                      C75.4,35.7,75.4,34.4,74.6,33.6z M84.8,33.7L69.9,48.5c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7
                      l14.8-14.7c0.8-0.8,0.8-2.1,0-2.9C86.9,32.9,85.6,32.9,84.8,33.7z M32.6,33.6c-0.8-0.8-2.1-0.8-2.9,0l-7.6,7.6
                      c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.7,1.5,0.7c0.5,0,1.1-0.3,1.5-0.7l7.6-7.6C33.4,35.7,33.4,34.4,32.6,33.6z M42.8,33.7
                      L27.9,48.5c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.7,1.5,0.7s1.1-0.3,1.5-0.7l14.8-14.7c0.8-0.8,0.8-2.1,0-2.9
                      C44.9,32.9,43.6,32.9,42.8,33.7z M57.5,72.8c-3.3,0.1-6.7,0-9.9-0.1c-1.1-0.1-2.1,0.8-2.1,2c0,1.2,0.8,2.1,2,2.1
                      c2.4,0.1,4.8,0.1,7.2,0.1h3.1c1.7,0,3.6-0.1,5.6-0.9c1.3-0.5,2.5-1.5,3.6-2.1l2.3-1.6c0.9-0.7,1.2-2,0.5-2.9
                      c-0.8-1.1-2.1-1.2-3.1-0.5l-2.3,1.6c-0.9,0.7-1.9,1.3-2.8,1.7C60.3,72.6,58.8,72.8,57.5,72.8z"></path>
                    </g>
                  </g>
                </svg>
               `
            },
            {
               title:"Project Tango",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <path d="M0,85c0,4.3,3.6,7.9,8,7.9s8-3.6,8-7.9c0-0.3,0-0.6,0-0.9l12.9-7l20,12.1c0.8,0.5,1.6,0.5,2.3,0l20-12.1l12.9,7
                  c0,0.3,0,0.6,0,0.9c0,4.3,3.6,7.9,8,7.9c4.4,0,8-3.6,8-7.9c0-4.3-3.6-7.9-8-7.9c-2.5,0-4.7,1.1-6.1,2.8l-12-6.5V48.2
                  c0-0.8-0.5-1.5-1.2-2L52.4,36.1V22.5c3.3-0.9,5.6-4,5.6-7.6c-0.2-4.2-3.7-7.8-8.1-7.8c-4.4,0-8,3.6-8,7.9c0,3.6,2.3,6.5,5.6,7.6
                  v13.6L27.3,46.4c-0.8,0.3-1.2,1.1-1.2,2v25.1l-12,6.4C12.6,78.1,10.5,77,8,77C3.6,77,0,80.6,0,85 M49.9,11.6c1.9,0,3.4,1.5,3.4,3.4
                  c0,1.9-1.6,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4C46.5,13.2,48.1,11.6,49.9,11.6z M49.9,40l16.7,8.4l-16.7,9.1l-16.7-9.1L49.9,40z
                  M30.6,51.9l17,9.5v21.7l-17-10.2V51.9z M69.3,51.9v20.9l-17,10.2V61.4L69.3,51.9z M4.5,85c0-2,1.6-3.4,3.4-3.4
                  c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.6,3.4-3.4,3.4C6.1,88.4,4.5,86.8,4.5,85 M91.9,81.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.6,3.4-3.4,3.4
                  s-3.4-1.5-3.4-3.4C88.5,82.9,90,81.5,91.9,81.5z"></path>
                </svg>
               `
            },
            {
               title:"Hololens",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                  <path d="M50.4,19.9c0-1.5-4.1-3.2-6.5-4c-4.9-1.7-11.1-3-17.8-3.6c0,0,0,0-0.1,0c-6.8-0.6-13.3-0.4-18.8,0.6
                  c-2.2,0.4-7.4,1.6-7.5,3.2c0,0,0,0.3-0.1,0.5l-0.1,0.5c0,0.5-0.1,0.9-0.1,1.4c0,0,0,0,0,0.1l0,0.4l0,0c-0.1,2.3,0.3,4.5,1,6.8
                  c1.3,4,3.4,7.8,5.5,7.8c0.1,0,0.2,0,0.3,0l0.6-0.1c0.7-0.1,1.1-0.2,1.4-0.4c0.5-0.3,0.9-0.7,0.9-1.2c0.7,0.6,1,0.7,1.5,0.7
                  c1.3,0,4.4-0.4,7.5-1l1.8-0.4c2.6-0.6,4.3-1.1,5.3-1.8c0.8,0,1.7,0,2.6,0c2.6,0,5.4-0.1,7.6-0.2c7.3-0.5,11.9-1.4,13.9-2.7l0,0
                  l0.3-0.3c0.5-0.5,0.8-0.9,0.9-1.4h0V19.9z M35.2,27.1c-2.5,0.2-5.8,0.2-8.8,0.2l0.2-0.4c0.5-1.4,0.8-2.4,1-3.5
                  c0.7,0.1,1.6,0.1,2.7,0.1c3.9,0,9.4-0.6,11.9-1.6c3.9,0.9,6.1,2,6.2,2.4v0.1c0,0-0.1,0.1-0.2,0.3C47.1,25.5,44,26.6,35.2,27.1z
                  M7.2,31.3c-0.1,0-0.4,0.1-0.7,0.1l-0.6,0.1c-0.1,0-0.7-0.4-1.3-1.3c-0.9-1.3-1.7-3.1-2.4-5.2c-0.6-2.1-0.9-4.1-0.9-6.2
                  c0.3-0.2,0.7-0.3,1.2-0.5c1.3-0.5,3.1-1,5.1-1.3c5.5-1,11.8-1.3,18.4-0.7c0.2,1.8,0.1,4-0.2,6.3c-0.2,1.2-0.4,2.2-1,3.7
                  c0,0.1-0.3,0.8-0.4,1.1c-0.3,0.8-2.8,1.4-4.9,1.9l-1.8,0.4c-2.8,0.6-5.7,1-7,1c-0.1-0.1-0.3-0.3-0.7-0.5c-0.8-0.6-1.1-0.9-1.7-0.9
                  c-0.4,0-0.7,0.1-0.9,0.4C7.1,29.9,7,30.4,7.2,31.3z M48.5,22.1c-1.7-1-4.4-1.7-6.4-2.1l0,0l0,0c-0.7-0.1-1.3-0.2-1.7-0.3
                  c-3.7-0.6-7.9-1.1-12.3-1.4c0-1.5-0.1-2.9-0.4-4c5.9,0.7,11.3,1.8,15.7,3.4c1.7,0.6,3.2,1.3,4.2,1.9c0.5,0.3,0.8,0.5,1,0.6l0,0
                  c0,0,0,0,0,0V22.1z M27.9,20.1c3.4,0.3,6.7,0.6,9.7,1.1c-1.2,0.2-2.6,0.3-4.2,0.4c-2.2,0.1-4.5,0.1-5.6,0
                  C27.9,21,27.9,20.6,27.9,20.1z M25.5,14c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c-6.6-0.5-12.9-0.3-18.4,0.8c-1.4,0.3-4,0.8-5.8,1.6
                  l0-0.2c0,0,0,0,0,0c0.3-0.2,0.7-0.3,1.2-0.5c1.3-0.5,3-0.9,4.9-1.3C12.9,13.6,19.1,13.4,25.5,14z"></path>
                </svg>
               `
            },
            {
               title:"Google Cardboard",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M31.8,59.2c-1.9,0-3.6-0.8-4.5-2.1c-0.4-0.6-1.3-0.8-2.1-0.5c-0.7,0.3-1,1-0.6,1.6c1.4,2,4.2,3.3,7.2,3.3
                      c3,0,5.7-1.3,7.2-3.3c0.4-0.6,0.1-1.3-0.6-1.6c-0.7-0.3-1.7-0.1-2.1,0.5C35.4,58.4,33.7,59.2,31.8,59.2z M75.3,58.3
                      c0.4-0.6,0.1-1.3-0.6-1.6c-0.7-0.3-1.7-0.1-2.1,0.5c-0.9,1.3-2.6,2.1-4.5,2.1c-1.9,0-3.6-0.8-4.5-2.1c-0.4-0.6-1.3-0.8-2.1-0.5
                      c-0.7,0.3-1,1-0.6,1.6c1.4,2,4.2,3.3,7.2,3.3C71.2,61.6,73.9,60.3,75.3,58.3z M11.2,46c-0.8,0-1.5,0.7-1.5,1.5v10.7
                      c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V47.5C12.8,46.6,12.1,46,11.2,46z M88.8,45.5c-0.8,0-1.5,0.7-1.5,1.5v10.7
                      c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V46.9C90.3,46.1,89.6,45.5,88.8,45.5z M99,23.7L99,23.7c-0.6-0.4-1.4-0.4-2,0
                      L91,27.1v-6.7c0-1.1-0.9-2-2-2H11c-1.1,0-2,0.9-2,2v6.7l-5.9-3.4c-0.6-0.4-1.4-0.4-2,0c-0.6,0.4-1,1-1,1.7v54.2
                      c0,0.7,0.4,1.4,1,1.7c0.6,0.4,1.4,0.4,2,0l8.5-4.8h26.3c0.8,0,1.6-0.5,1.9-1.2l6.9-16.2c0.6-1.3,1.9-2.2,3.4-2.2
                      c1.5,0,2.8,0.9,3.4,2.2l6.9,16.2c0.3,0.7,1.1,1.2,1.9,1.2h26.3l8.5,4.8c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3c0.6-0.4,1-1,1-1.7
                      V25.4C100,24.7,99.6,24.1,99,23.7z M95.9,76.2L90.3,73v-3.1c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v2.7H63.5l-5.2-12.3
                      c1.9,3.4,5.7,5.8,9.9,5.8c6.2,0,11.3-5,11.3-11.1c0-6.1-5.1-11.1-11.3-11.1c-6.2,0-11.3,5-11.3,11.1c0,1.1,0.2,2.2,0.5,3.2
                      l-0.2-0.5c-1.2-2.8-4-4.6-7.1-4.6c-3.1,0-5.9,1.8-7.1,4.6l-0.2,0.4c0.3-1,0.5-2.1,0.5-3.2c0-3-1.2-5.8-3.3-7.9
                      c-2.1-2.1-5-3.3-8-3.3c-6.2,0-11.3,5-11.3,11.1C20.5,61,25.6,66,31.8,66c4.3,0,8-2.3,9.9-5.8l-5.2,12.3H12.8v-2.7
                      c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5V73l-5.6,3.2V28.9l5.6,3.2v3.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-3h3.1
                      c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-2.8v-6.9h73.9v6.9h-2.8c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5h3.1v3
                      c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-3.1l5.6-3.2V76.2z M59.9,54.9c0-4.5,3.7-8.1,8.3-8.1c4.6,0,8.3,3.6,8.3,8.1
                      c0,4.5-3.7,8.1-8.3,8.1C63.6,63,59.9,59.4,59.9,54.9z M40.1,54.9c0,2.2-0.9,4.2-2.4,5.7C36.1,62.2,34,63,31.8,63
                      c-4.6,0-8.3-3.6-8.3-8.1c0-4.5,3.7-8.1,8.3-8.1C36.4,46.8,40.1,50.4,40.1,54.9z"></path>
                    </g>
                  </g>
                </svg>
               `
            },
            {
               title:"htc vive",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M99.9,48.9c-0.2-9.6-7.9-17.6-17.4-18c-5.1-0.2-11.5-0.4-19-0.5v-4.6c0-1.5-1-2.7-2.5-2.9c-0.1,0-0.3,0-0.4-0.1l-1-15.1
                      H40.4l-1,15.1c-0.1,0-0.3,0-0.4,0.1c-1.4,0.2-2.5,1.5-2.5,2.9v4.6c-7.5,0.1-13.9,0.2-19,0.5C8,31.4,0.3,39.3,0.1,49
                      C0,52.5,0,56.7,0,61.3c0,4.8,0,9.1,0.1,12.9c0.2,10,8.4,18.1,18.2,18.1h63.3c9.8,0,18-8.1,18.2-18c0.1-3.8,0.1-8.1,0.1-12.9
                      C100,56.7,100,52.5,99.9,48.9z M43.7,11.3h12.6l0.8,11c-0.3,0-0.6,0-0.9-0.1c-0.5,0-1-0.1-1.5-0.1c-0.5,0-0.9-0.1-1.4-0.1
                      c-1,0-2-0.1-3.1-0.1c-0.2,0-0.4,0-0.7,0c-0.8,0-1.7,0-2.5,0.1c-0.5,0-1,0.1-1.5,0.1c-0.5,0-1,0.1-1.5,0.1c-0.4,0-0.7,0-1.1,0.1
                      L43.7,11.3z M40,26.4c3.1-0.4,6.1-0.7,9-0.7c0.8,0,1.6,0,2.4,0c2.9,0.1,5.7,0.3,8.6,0.7v4.1c-2,0-4,0-6.1,0c-1.3,0-2.6,0-3.9,0
                      c-1.3,0-2.6,0-3.9,0c-2.1,0-4.2,0-6.1,0V26.4z M47.6,88.7H18.4c-7.9,0-14.5-6.5-14.7-14.6C3.6,70.4,3.5,66,3.5,61.3
                      c0-4.6,0-8.7,0.1-12.3c0.2-7.8,6.3-14.2,14-14.5C25,34.1,35,34,47.4,33.9c0.1,0,0.2,0,0.3,0v17.8H32.4c-5.5,0-10,4.5-10,10.1
                      c0,5.6,4.5,10.1,10,10.1h15.3V88.7z M66.5,55.4h1.2c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5h-1.2H33.5h-1.2
                      c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5h1.2H66.5z M96.3,74.1c-0.2,8-6.8,14.5-14.7,14.5H51.2V72h16.5c5.5,0,10-4.5,10-10.1
                      c0-5.6-4.5-10.1-10-10.1H51.2V33.9c0.5,0,1,0,1.5,0C65,34,75,34.1,82.3,34.5c7.7,0.4,13.8,6.7,14,14.5c0.1,3.5,0.1,7.7,0.1,12.3
                      C96.5,66,96.4,70.4,96.3,74.1z M60,61.9c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5s-2.9-6.5-6.5-6.5C62.9,55.4,60,58.3,60,61.9
                      z M66.5,58.9c1.6,0,2.9,1.3,2.9,3c0,1.6-1.3,3-2.9,3c-1.6,0-2.9-1.3-2.9-3C63.5,60.3,64.8,58.9,66.5,58.9z M66.5,63.7
                      c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8C64.7,62.9,65.5,63.7,66.5,63.7z M33.5,60.1c-1,0-1.8,0.8-1.8,1.8
                      c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C35.3,60.9,34.5,60.1,33.5,60.1z M86.5,55.4c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5
                      c3.4,0,7.6-3,7.6-6.5S89.9,55.4,86.5,55.4z M86.5,64.9c-1.6,0-2.9-1.3-2.9-3c0-1.6,1.3-3,2.9-3c1.9,0,4.1,1.8,4.1,3
                      C90.6,63,88.3,64.9,86.5,64.9z M20,61.9c0-3.6-2.9-6.5-6.5-6.5c-3.4,0-7.6,3-7.6,6.5s4.2,6.5,7.6,6.5C17.1,68.4,20,65.5,20,61.9z
                      M9.4,61.9c0-1.1,2.2-3,4.1-3c1.6,0,2.9,1.3,2.9,3c0,1.6-1.3,3-2.9,3C11.7,64.9,9.4,63,9.4,61.9z M10.3,39
                      c-2.5,2.5-1.6,7.6,0.8,10.1c1.2,1.2,2.8,1.9,4.6,1.9s3.4-0.7,4.6-1.9c1.2-1.2,1.9-2.9,1.9-4.6c0-1.7-0.7-3.4-1.9-4.6
                      C17.9,37.4,12.8,36.5,10.3,39z M17.8,46.6c-1.1,1.2-3,1.2-4.2,0c-1.3-1.3-1.6-4.2-0.8-5c0.3-0.3,0.9-0.4,1.5-0.4
                      c1.2,0,2.6,0.4,3.5,1.3C18.9,43.5,18.9,45.4,17.8,46.6z M15.7,72.8c-1.7,0-3.4,0.7-4.6,1.9c-1.2,1.2-2.1,3.1-2.3,5.1
                      c-0.2,2.1,0.3,3.9,1.4,5c1,1,2.4,1.5,4.1,1.5c0.3,0,0.6,0,0.9,0c1.9-0.2,3.8-1.1,5-2.3c1.2-1.2,1.9-2.9,1.9-4.6
                      c0-1.7-0.7-3.4-1.9-4.6C19.1,73.5,17.4,72.8,15.7,72.8z M17.8,81.4c-1.3,1.3-4.2,1.7-5,0.8c-0.8-0.8-0.5-3.7,0.8-5
                      c0.6-0.6,1.3-0.9,2.1-0.9c0.8,0,1.5,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.1S18.3,80.9,17.8,81.4z M81.9,51c1.5,0,3-0.5,4.1-1.5
                      c1.3-1.1,2.3-2.9,2.7-4.9c0.4-2,0-3.9-1-5.1c-1-1.2-2.7-1.9-4.7-1.9c0,0-0.1,0-0.1,0c-1.9,0-3.9,0.7-5.2,1.8
                      c-1.3,1.1-2.1,2.7-2.3,4.4c-0.2,1.7,0.4,3.4,1.5,4.8c1.1,1.3,2.7,2.2,4.4,2.3C81.5,51,81.7,51,81.9,51z M79,44.2
                      c0.1-0.8,0.4-1.5,1-2c0.8-0.7,2-1,3.1-1c0.9,0,1.6,0.2,1.9,0.6c0.7,0.9,0.2,3.7-1.3,5c-0.6,0.5-1.4,0.8-2.1,0.7
                      c-0.8-0.1-1.5-0.4-2-1.1C79.1,45.7,78.9,45,79,44.2z M86.5,74.7c-1.2-1.2-2.8-1.9-4.6-1.9c-1.7,0-3.4,0.7-4.6,1.9
                      c-1.2,1.2-1.9,2.9-1.9,4.6c0,1.7,0.7,3.4,1.9,4.6c1.2,1.2,3.1,2.1,5,2.3c0.3,0,0.6,0,0.9,0c1.7,0,3.1-0.5,4.1-1.5
                      c1.1-1.1,1.6-3,1.4-5C88.6,77.8,87.7,75.9,86.5,74.7z M84.8,82.3c-0.8,0.8-3.7,0.5-5-0.8c-0.6-0.6-0.9-1.3-0.9-2.1
                      s0.3-1.5,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9c0.8,0,1.5,0.3,2.1,0.9C85.3,78.6,85.7,81.5,84.8,82.3z M37.1,50.6
                      c3.6,0,6.5-2.9,6.5-6.5c0-3.5-3-7.7-6.5-7.7c-3.5,0-6.5,4.3-6.5,7.7C30.6,47.7,33.5,50.6,37.1,50.6z M37.1,39.9
                      c1.1,0,2.9,2.3,2.9,4.2c0,1.6-1.3,3-2.9,3c-1.6,0-2.9-1.3-2.9-3C34.1,42.2,35.9,39.9,37.1,39.9z M37.1,87.5c3.5,0,6.5-4.3,6.5-7.7
                      c0-3.6-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5C30.6,83.2,33.6,87.5,37.1,87.5z M37.1,76.8c1.6,0,2.9,1.3,2.9,3
                      c0,1.9-1.8,4.2-2.9,4.2c-1.1,0-2.9-2.3-2.9-4.2C34.1,78.1,35.4,76.8,37.1,76.8z M60.6,36.3c-3.5,0-6.5,4.3-6.5,7.7
                      c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5C67.1,40.6,64.1,36.3,60.6,36.3z M60.6,47c-1.6,0-2.9-1.3-2.9-3
                      c0-1.9,1.8-4.2,2.9-4.2s2.9,2.3,2.9,4.2C63.5,45.7,62.2,47,60.6,47z M60.6,73.2c-3.6,0-6.5,2.9-6.5,6.5c0,3.5,3,7.7,6.5,7.7
                      c3.5,0,6.5-4.3,6.5-7.7C67.1,76.1,64.2,73.2,60.6,73.2z M60.6,83.9c-1.1,0-2.9-2.3-2.9-4.2c0-1.6,1.3-3,2.9-3c1.6,0,2.9,1.3,2.9,3
                      C63.5,81.6,61.7,83.9,60.6,83.9z M40,61.9c0-3.6-2.9-6.5-6.5-6.5c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5
                      C37.1,68.4,40,65.5,40,61.9z M33.5,64.9c-1.6,0-2.9-1.3-2.9-3c0-1.6,1.3-3,2.9-3c1.6,0,2.9,1.3,2.9,3
                      C36.5,63.5,35.2,64.9,33.5,64.9z"></path>
                    </g>
                  </g>
                </svg>
               `
            },
         ]
		};
	},
	components: {
		IconSquare
	}
};
</script>

<style>
@media (max-width: 576px) {
	.fxt-off-dev {
		font-size: 22px !important;
		font-weight: bold !important;
	}
}
</style>
