<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Advantages</span>
				<h2>Advantages of NodeJS Development Solutions</h2>
				<p>
					Our experience straddles domains and our expert teams have delivered consistently superior
					quality across projects of different scope.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-4 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Speed',
					text: 'V8 engines offer superior speed helping development of high speed applications that offer the real edge. Our expertise permits us to leverage the exceptional speed of the V8 engines.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                           <g>
                              <g>
                                 <path d="M98,73.5h-3.3V21.7c0-4.6-3.8-8.4-8.3-8.4H13.8c-4.6,0-8.3,3.8-8.3,8.4v51.6H2.1c-0.9,0-1.6,0.8-1.6,1.6v5
                                 c0,3.6,2.9,6.6,6.6,6.6h85.9c3.6,0,6.6-3,6.6-6.6v-5C99.6,74.2,98.8,73.5,98,73.5z M8.8,21.7c0-2.7,2.3-5,5-5h72.7
                                 c2.7,0,5,2.3,5,5v51.6h-3.3V25.1c0-2.7-2.3-5-5-5H17c-2.7,0-5,2.3-5,5v48.4H8.8V21.7z M84.8,25.1v48.4H60.1
                                 c-1.7,0-3.3,0.9-4.2,2.6c-0.3,0.4-0.7,0.8-1.2,0.8h-9.5c-0.5,0-0.9-0.3-1.2-0.8c-0.8-1.6-2.4-2.6-4.2-2.6H15.4V25.1
                                 c0-0.9,0.8-1.6,1.6-1.6h66C83.9,23.5,84.8,24.2,84.8,25.1z M96.3,80.1c0,1.9-1.5,3.4-3.3,3.4H7.1c-1.9,0-3.3-1.5-3.3-3.4v-3.4
                                 h36.2c0.5,0,0.9,0.3,1.2,0.8c0.8,1.6,2.4,2.6,4.2,2.6h9.5c1.7,0,3.3-0.9,4.2-2.6c0.3-0.4,0.7-0.8,1.2-0.8h36.2V80.1z M60.8,35.7
                                 c-3.5-1.5-7.1-2.2-10.7-2.2c-14.6,0-26.4,11.9-26.4,26.8c0,3.6,0.8,7.3,2.1,10.7l3.1-1.4c-1.1-2.4-1.6-5-1.9-7.7h3.3
                                 c0.3,3.2,1.3,6.5,3.1,9.2l2.8-1.8c-5-7.7-2.8-18,4.8-23.1c7.6-5,17.8-2.8,22.9,4.9c3.6,5.5,3.6,12.7,0,18.1l2.8,1.8
                                 c1.7-2.8,2.8-5.9,3.1-9.2h3.3c-0.1,2.7-0.8,5.3-1.9,7.7l3.1,1.4C80.3,57.3,74.2,41.6,60.8,35.7z M30.4,58.5H27
                                 c0.4-5,2.3-9.7,5.6-13.7l2.3,2.3C32.2,50.4,30.6,54.3,30.4,58.5z M48.6,40.3h-0.1c-4.2,0.4-8,2-11.1,4.6l-2.3-2.3
                                 c3.8-3.2,8.4-5.3,13.5-5.7V40.3z M62.8,44.9c-3.1-2.7-7.1-4.3-11.1-4.6v-3.4c5,0.4,9.6,2.3,13.5,5.7L62.8,44.9z M69.7,58.7v-0.1
                                 c-0.4-4.2-2-8.1-4.6-11.2l2.3-2.3c3.2,3.8,5.2,8.5,5.6,13.7H69.7z M52.2,55.7c-0.7-0.3-1.3-0.5-2.1-0.5c-2.7,0-5,2.3-5,5
                                 s2.3,5,5,5c2.7,0,5-2.3,5-5c0-0.7-0.1-1.5-0.5-2.2l6.6-6.6l-2.3-2.3L52.2,55.7z M50.1,61.8c-0.9,0-1.6-0.8-1.6-1.6
                                 c0-0.9,0.8-1.6,1.6-1.6s1.6,0.8,1.6,1.6C51.7,61.1,51,61.8,50.1,61.8z M43.5,71.9h8.3v-3.4h-8.3V71.9z M55,71.9h3.3v-3.4H55V71.9z
                                 M22.1,26.7h-3.3v3.4h3.3V26.7z M35.2,26.7h-3.3v3.4h3.3V26.7z M27,33.5h-8.3v3.4H27V33.5z M66.5,30.1h14.9v-3.4H66.5V30.1z
                                 M71.5,36.9h9.9v-3.4h-9.9V36.9z M28.6,26.7h-3.3v3.4h3.3V26.7z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Non-blocking I/O Paradigm',
					text: 'Non blocking forms are executed faster without blocking, intimating when new data is available. The asynchronous versions in NodeJS are non blocking and permit the loop to continue.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                           <path d="M49.5,10.4c0.3-0.2,0.5-0.5,0.5-0.8V3.8c0-0.3-0.2-0.7-0.5-0.8c-0.1,0-0.1-0.1-0.2-0.1L44.4,0c-0.3-0.2-0.7-0.2-1,0l-5,2.9
                           C38.2,3.1,38,3.4,38,3.8v1.9h-9.2v0c0-1-0.8-1.9-1.9-1.9h-3.9c-1,0-1.9,0.8-1.9,1.9l-9.5,0V3.8c0-0.3-0.2-0.7-0.5-0.8L6.2,0
                           c-0.3-0.2-0.7-0.2-1,0L0.3,2.9c-0.1,0-0.1,0-0.2,0.1c-0.3,0.2-0.5,0.5-0.5,0.8v5.8c0,0.3,0.2,0.7,0.5,0.8l4.6,2.7v8.5h0c0,0,0,0,0,0
                           c-0.5,0-1,0.2-1.3,0.5c-0.4,0.4-0.5,0.8-0.6,1.3v3.9c0,1,0.8,1.9,1.9,1.9l0,8l-4.5,2.6c-0.1,0-0.1,0-0.1,0.1
                           c-0.3,0.2-0.5,0.5-0.5,0.8v5.8c0,0.3,0.2,0.7,0.5,0.8l5,2.9c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.2l5-2.9
                           c0.3-0.2,0.5-0.5,0.5-0.8v-1.7h9.6v0c0,1,0.8,1.9,1.9,1.9h3.9c1,0,1.9-0.8,1.9-1.9l9.2,0v1.7c0,0.3,0.2,0.7,0.5,0.8l5,2.9
                           c0.1,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0.1,0,0.3,0,0.5-0.2l5-2.9c0.3-0.2,0.5-0.5,0.5-0.8v-5.8c0-0.3-0.2-0.6-0.5-0.8
                           c0,0-0.1,0-0.2-0.1l-4.5-2.6v-8h0c1,0,1.9-0.8,1.9-1.9v-3.9c0-1-0.8-1.9-1.9-1.9l0-8.5L49.5,10.4L49.5,10.4z M40.9,3.8L44,2l3.1,1.8
                           L44,5.6L40.9,3.8z M45,10.8V7.2l3.1-1.8V9L45,10.8z M39.9,9V5.4L43,7.2v3.6L39.9,9z M6.6,10.8V7.2l3.1-1.8V9L6.6,10.8z M43.9,25
                           c-0.5,0-1,0.4-1,1v0.7l0-3.2h1.9l0,3.8h0V26C44.9,25.4,44.5,25,43.9,25z M26.9,40.9h-3.9c-1,0-1.9,0.8-1.9,1.9l-9.5,0v-2.2
                           c0-0.3-0.2-0.7-0.5-0.8l-4.5-2.6v-8h0c1,0,1.9-0.8,1.9-1.9v-3.9c0-1-0.8-1.9-1.9-1.9l0-8.6l4.5-2.6c0.3-0.2,0.5-0.5,0.5-0.8V7.6h9.5
                           v0c0,1,0.8,1.9,1.9,1.9h3.9c1,0,1.9-0.8,1.9-1.9l9.2,0v1.9c0,0.3,0.2,0.6,0.5,0.8L43,13v8.6h0c-1,0-1.9,0.8-1.9,1.9v3.9
                           c0,1,0.8,1.9,1.9,1.9l0,8l-4.5,2.6c-0.2,0.1-0.3,0.3-0.4,0.4H38v2.6h-9.2v0C28.8,41.7,28,40.9,26.9,40.9z M6.7,25v-1l0,3.2H4.8
                           l0-3.8l0,1.5c0,0.5,0.4,1,1,1C6.3,26,6.7,25.5,6.7,25z M25.6,5.7c-0.5,0-1,0.4-1,1s0.4,1,1,1h0.7l-3.2,0V5.7l3.8,0L25.6,5.7z
                           M2.6,3.8L5.7,2l3.1,1.8L5.7,5.6L2.6,3.8z M23.1,44.7h1.5c0.5,0,1-0.4,1-1s-0.4-1-1-1h-1l3.3,0l0,1.9L23.1,44.7L23.1,44.7z M44,38.8
                           l3.1,1.8L44,42.4l-3.1-1.8L44,38.8z M1.6,9V5.4l3.1,1.8v3.6L1.6,9z M8.8,40.6l-3.1,1.8l-3.1-1.8l3.1-1.8L8.8,40.6z M4.7,44.1v3.6
                           l-3.1-1.8v-3.6L4.7,44.1z M9.8,42.3v3.6l-3.1,1.8v-3.6L9.8,42.3z M43,44.1v3.6l-3.1-1.8v-3.6L43,44.1z M48.1,42.3v3.6L45,47.7v-3.6
                           L48.1,42.3z M5.9,49.8L5.9,49.8L5.9,49.8L5.9,49.8z"></path>
                        </svg>
               `
				},
				{
					title: 'Data Streaming',
					text: 'Dispenses with the need for the whole resource to load, allowing reading of content piece by piece. This offers greater flexibility and faster loading, due to optimized use of resources.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <path d="M4.9,59.2h52.6c3,0,5.6-2.6,5.6-5.7v-0.7h9.1c0.3,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9V24.9c0-0.3-0.1-0.7-0.4-0.9
                           c-0.2-0.2-0.6-0.4-0.9-0.4H63v-3.9c0,0,0,0,0,0c0,0,0,0,0,0V11c0-3.2-2.5-5.7-5.6-5.7H4.9c-3,0-5.5,2.6-5.5,5.7v42.4
                           C-0.6,56.6,1.8,59.2,4.9,59.2z M70.7,50.1H44.8v-4h10.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3H44.8v-8.3h6.9l-2.7,2.7
                           c-0.3,0.3-0.5,0.8-0.3,1.3c0.1,0.5,0.5,0.8,0.9,0.9c0.5,0.1,0.9,0,1.3-0.3l5.1-5.1c0.5-0.5,0.5-1.4,0-1.9l-5.1-5.1
                           c-0.3-0.3-0.8-0.5-1.3-0.3c-0.5,0.1-0.8,0.5-0.9,0.9c-0.1,0.5,0,0.9,0.3,1.3l3,3h-7.3v-6.3h25.9V50.1z M43.5,23.6
                           c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.4,0.6-0.4,0.9v7.6H26.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3h15.4v8.3H29.8l3.1-3.1
                           c0.5-0.5,0.5-1.3,0-1.9c-0.5-0.5-1.3-0.5-1.9,0l-5.1,5.1c-0.4,0.4-0.5,0.9-0.4,1.4c0.1,0.5,0.5,0.9,1,1.1l4.5,4.5
                           c0.3,0.3,0.8,0.5,1.3,0.3c0.5-0.1,0.8-0.5,0.9-0.9c0.1-0.5,0-0.9-0.3-1.3l-2.6-2.6h11.9v5.3c0,0.3,0.1,0.7,0.4,0.9
                           c0.2,0.2,0.6,0.4,0.9,0.4h16.9v0.7c0,1.7-1.3,3.1-2.9,3.1H4.9c-1.6,0-2.8-1.4-2.8-3.1V21h58.3v2.6H43.5z M4.9,8h52.6
                           c1.6,0,2.9,1.4,2.9,3.1v7.3H2V11C2,9.3,3.3,8,4.9,8z"></path>
                           <path d="M16.1,17.5c2.3,0,4.1-1.9,4.1-4.3c0-2.3-1.9-4.3-4.1-4.3c-2.3,0-4.1,1.9-4.1,4.3C11.9,15.6,13.8,17.5,16.1,17.5z M16.1,11.6
                           c0.8,0,1.5,0.7,1.5,1.6c0,0.9-0.7,1.6-1.5,1.6c-0.8,0-1.5-0.7-1.5-1.6C14.6,12.4,15.3,11.6,16.1,11.6z"></path>
                           <path d="M25.2,17.5c2.3,0,4.1-1.9,4.1-4.3c0-2.3-1.9-4.3-4.1-4.3c-2.3,0-4.1,1.9-4.1,4.3C21.1,15.6,22.9,17.5,25.2,17.5z M25.2,11.6
                           c0.8,0,1.5,0.7,1.5,1.6c0,0.9-0.7,1.6-1.5,1.6c-0.8,0-1.5-0.7-1.5-1.6C23.7,12.4,24.4,11.6,25.2,11.6z"></path>
                           <path d="M7,17.5c2.3,0,4.1-1.9,4.1-4.3C11.1,10.9,9.2,9,7,9s-4.1,1.9-4.1,4.3C2.8,15.6,4.7,17.5,7,17.5z M7,11.6
                           c0.8,0,1.5,0.7,1.5,1.6c0,0.9-0.7,1.6-1.5,1.6c-0.8,0-1.5-0.7-1.5-1.6C5.5,12.4,6.1,11.6,7,11.6z"></path>
                        </svg>
               `
				},
				{
					title: 'Real-time Apps',
					text: 'Real time bi-directional communication channels helps to resolve the low latency issues that offer better results in use cases including VoIP, videoconference, storage solutions and chatting apps',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 64 80" style="enable-background:new 0 0 64 80;" xml:space="preserve">
                           <g>
                              <path d="M48,80c2.9,0,5.3-2.4,5.3-5.3V5.3C53.3,2.4,50.9,0,48,0H16c-2.9,0-5.3,2.4-5.3,5.3v69.3c0,2.9,2.4,5.3,5.3,5.3H48z
                              M40.5,66.7L28.9,55.1c-0.5-0.5-1.4-0.5-1.9,0c0,0,0,0,0,0L15.5,66.7h-2.1V53.9l6.3-6.3c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0
                              l-6.3-6.3v-6.9l9.7,9.7c0.5,0.5,1.4,0.5,1.9,0c0,0,0,0,0,0l25.7-25.7v14.9L32.4,49.7c-0.5,0.5-0.5,1.4,0,1.9c0,0,0,0,0,0l15.1,15.1
                              H40.5z M36.8,66.7H19.2l8.8-8.8L36.8,66.7z M23.6,13.3c0.1,1.4,0.6,2.7,1.3,3.8l3.2,4.8l-3.8,3.8c-0.5,0.5-0.5,1.4,0,1.9
                              c0,0,0,0,0,0l5.8,5.7L24,39.5L13.3,28.8V13.3H23.6z M40.4,13.3h9.7l-7.5,7.5l-3.6-3.6C39.8,16,40.3,14.7,40.4,13.3z M39.9,55.3
                              l2.8-2.8l3.5,3.5l-2.8,2.8L39.9,55.3z M48.9,56.9c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0l-5.3-5.3c-0.5-0.5-1.4-0.5-1.9,0c0,0,0,0,0,0
                              L38,53.5l-2.8-2.8l15.5-15.5v30.9l-5.5-5.5L48.9,56.9z M32,31.5l-4.8-4.8l2.4-2.4l1.2,1.8c0.4,0.6,1.2,0.8,1.9,0.4
                              c0.1-0.1,0.3-0.2,0.4-0.4l4.4-6.7l3.2,3.3L32,31.5z M36.8,15.7L32,22.9l-4.8-7.2c-1.8-2.7-1.1-6.3,1.6-8s6.3-1.1,8,1.6
                              C38.1,11.2,38.1,13.7,36.8,15.7z M13.3,50.1v-6.9l3.5,3.5L13.3,50.1z M16,2.7h32c1.5,0,2.7,1.2,2.7,2.7v5.3H40.3
                              c-0.2-0.8-0.4-1.5-0.8-2.2C37.2,4.3,32.1,2.8,28,5c-1.5,0.8-2.7,2-3.4,3.4c-0.4,0.7-0.6,1.4-0.8,2.2H13.3V5.3
                              C13.3,3.9,14.5,2.7,16,2.7z M13.3,74.7v-5.3h37.3v5.3c0,1.5-1.2,2.7-2.7,2.7H16C14.5,77.3,13.3,76.1,13.3,74.7z"></path>
                              <rect x="26.7" y="72" width="10.7" height="2.7"></rect>
                              <circle cx="32" cy="12" r="2.7"></circle>
                              <path d="M19.6,53.4l-4,1.3c-0.4,0.1-0.8,0.5-0.9,1c-0.1,0.4,0,0.9,0.3,1.3l2.7,2.7c0.3,0.2,0.6,0.4,0.9,0.4c0.1,0,0.2,0,0.3,0
                              c0.4-0.1,0.8-0.4,1-0.9l1.3-4c0.2-0.7-0.1-1.5-0.8-1.7C20.2,53.3,19.9,53.3,19.6,53.4L19.6,53.4z"></path>
                              <rect x="23.4" y="49.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -28.4058 32.7526)" width="3.8" height="2.7"></rect>
                              <rect x="28.8" y="44" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -23.066 34.959)" width="3.8" height="2.7"></rect>
                              <rect x="34.1" y="38.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.7357 37.1694)" width="3.8" height="2.7"></rect>
                              <path d="M40.9,36.9l2.7-2.7c0.5-0.5,0.5-1.4,0-1.9c0,0,0,0,0,0l-2.7-2.7l-1.9,1.9l1.7,1.7l-1.7,1.7L40.9,36.9z"></path>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Unified Database Queries',
					text: 'Dispenses with the need for separate queries, permitting the processing of files at the time of uploading itself – this slashes the time required for overall processing of data.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <g>
                           <g>
                              <path d="M34.5,29.2h22.8c0.7,0,1.1-0.5,1.1-1.1c0-0.7-0.5-1.1-1.1-1.1H34.5c-0.7,0-1.1,0.5-1.1,1.1C33.4,28.8,33.9,29.2,34.5,29.2
                              z"></path>
                           </g>
                           <g>
                              <path d="M62.7,29.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.5-0.5-1.1-0.5-1.6,0
                              c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8C62.1,29.1,62.5,29.2,62.7,29.2z"></path>
                           </g>
                           <g>
                              <path d="M67.8,29.2c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.5-0.5-1.1-0.5-1.6,0
                              c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8C67.2,29.1,67.5,29.2,67.8,29.2z"></path>
                           </g>
                           <g>
                              <path d="M67.8,49.1c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.5-0.5-1.1-0.5-1.6,0
                              c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8C67.2,49,67.5,49.1,67.8,49.1z"></path>
                           </g>
                           <g>
                              <path d="M57.3,7.7H34.5c-0.7,0-1.1,0.5-1.1,1.1c0,0.7,0.5,1.1,1.1,1.1h22.8c0.7,0,1.1-0.5,1.1-1.1S58,7.7,57.3,7.7z"></path>
                           </g>
                           <g>
                              <path d="M61.9,8.1c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                              c0.2-0.2,0.3-0.6,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C63,7.6,62.3,7.6,61.9,8.1z"></path>
                           </g>
                           <g>
                              <path d="M67,8.1c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
                              c0.2-0.2,0.3-0.5,0.3-0.8s-0.1-0.6-0.3-0.8C68.1,7.6,67.5,7.6,67,8.1z"></path>
                           </g>
                           <g>
                              <path d="M45,52.3c0.3,0.5,1.1,0.6,1.6,0.2c0.5-0.3,0.6-1,0.2-1.6c-1.7-2.3-1.1-5.5,1.1-7c0.5-0.3,0.6-1,0.2-1.6
                              c-0.3-0.5-1-0.6-1.6-0.2C43.2,44.5,42.6,49,45,52.3z"></path>
                           </g>
                           <g>
                              <path d="M18.1,74.1c9.5,0,15.7-3.1,15.7-7.7c0-2,0-7.7,0-11.6h8.1c3.1,4,8.4,5.6,13.2,3.6l3.4,4.8l-0.1,0.1
                              c-0.5,0.3-0.6,1-0.2,1.6l5.5,7.5c1.4,1.8,3.9,2.3,5.7,0.9c1.8-1.4,2.3-3.9,0.9-5.7l-5.5-7.5c-0.2-0.2-0.5-0.5-0.8-0.5
                              s-0.6,0-0.8,0.2L63,60l-3.4-4.8c0.1-0.1,0.2-0.2,0.3-0.3h13c0.7,0,1.1-0.5,1.1-1.1V41c0-0.7-0.5-1.1-1.1-1.1H58.7
                              c-2-2-4.9-3.2-7.8-3.2c-2.8,0-5.6,1-7.8,3.2h-9.2v-4.3h39c0.7,0,1.1-0.5,1.1-1.1V21.7c0-0.7-0.5-1.1-1.1-1.1H29.6
                              c-0.7,0-1.1,0.5-1.1,1.1v4.5c-2.4-0.9-5.6-1.5-9.3-1.6V9.3h9.3v5.8c0,0.7,0.5,1.1,1.1,1.1h43.2c0.7,0,1.1-0.5,1.1-1.1V2.5
                              c0-0.7-0.5-1.1-1.1-1.1H29.6c-0.7,0-1.1,0.5-1.1,1.1v4.5H18c-0.7,0-1.1,0.5-1.1,1.1v16.5C8.6,24.9,0,27.6,0,32.4v0.1l0,0
                              c0,12,0,21.7,0,33.8C0,71.4,9.4,74.1,18.1,74.1z M68.4,69c0.6,0.8,0.5,1.9-0.5,2.5c-0.8,0.6-1.9,0.3-2.5-0.5l-4.8-6.6l3-2.2
                              L68.4,69z M30.7,3.6h40.9V14H30.7V3.6z M2.3,47.8c2.8,2.3,8.1,3.6,14.5,3.6s11.7-1.4,14.5-3.6V55c0,2.6-5.6,5.5-14.5,5.5
                              S2.3,57.6,2.3,55V47.8z M31.5,36.5v7C31.5,46.2,26,49,17,49S2.4,46.2,2.4,43.5v-7c2.8,2.3,8.1,3.6,14.5,3.6S28.7,38.8,31.5,36.5z
                              M18.1,71.9c-9,0-15.7-2.8-15.7-5.5v-7.3c2.7,2.3,8,3.6,14.5,3.6s11.7-1.4,14.5-3.6v7.3C31.5,69.1,26.9,71.9,18.1,71.9z
                              M60.3,61.8l-3.2-4.4c0.1-0.1,0.2-0.2,0.5-0.2c0.1-0.1,0.2-0.2,0.3-0.3l3.2,4.4L60.3,61.8z M71.7,52.5H61.2
                              c1.5-3.3,1.3-7.2-0.7-10.3h11.1V52.5z M50.9,39c2.8,0,5.6,1.4,7.3,3.8c3,4,2,9.7-2,12.6c-4.1,3-9.7,2-12.6-2
                              C39.3,47.3,43.6,39,50.9,39z M41.2,42.2c-1.9,3.1-2.2,6.9-0.7,10.3h-6.7V42.2H41.2z M30.7,22.9h40.9v10.3H33.8v-0.9
                              c0-1.9-1.1-3.6-3.1-4.9V22.9z M18,30.6c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5
                              C16.5,31.2,17.2,30.6,18,30.6z M16.9,27v1.6c-1.5,0.5-2.6,1.8-2.6,3.5c0,2,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8c0-1.7-1.1-3.1-2.6-3.5
                              V27c8.1,0.2,12.3,2.8,12.3,5.5c0,2.6-5.6,5.5-14.5,5.5S2.3,35,2.3,32.4C2.3,29.9,8.5,27.2,16.9,27z"></path>
                           </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Easy and Fast Coding',
					text: 'Less coding and unified scripting language result in faster development of applications rather than an extended development cycle. Developers can write JavaScript for server and client, thereby enhancing convenience.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <g>
                              <g>
                                 <path d="M72.8,0.7H1.2C0.5,0.7,0,1.1,0,1.8v53.7c0,0.7,0.5,1.2,1.2,1.2h29.7v8.1H17.7c-0.7,0-1.2,0.5-1.2,1.2v6
                                 c0,0.7,0.5,1.2,1.2,1.2h38.6c0.7,0,1.2-0.5,1.2-1.2v-6c0-0.7-0.5-1.2-1.2-1.2H43.1v-8.1h29.7c0.7,0,1.2-0.5,1.2-1.2V1.8
                                 C74,1.3,73.5,0.7,72.8,0.7z M55.2,70.8H18.8v-3.7c18.8,0,17.5,0,36.3,0C55.2,67.1,55.2,70.8,55.2,70.8z M40.8,64.7h-7.6v-8.1h7.6
                                 V64.7z M71.7,54.3c-8.1,0-61.3,0-69.4,0V3h69.4V54.3z"></path>
                                 <path d="M7.4,46.7h59.1c0.7,0,1.2-0.5,1.2-1.2V8.2c0-0.7-0.5-1.2-1.2-1.2H7.4C6.7,7,6.2,7.5,6.2,8.2v37.3
                                 C6.2,46.1,6.8,46.7,7.4,46.7z M8.6,9.4h56.8v35H8.6V9.4z"></path>
                                 <path d="M30.9,21.7c0.5,0.3,1.3,0.2,1.6-0.2l2.5-3.4c0.3-0.5,0.3-0.9,0-1.4l-2.5-3.4c-0.3-0.5-1.2-0.6-1.6-0.2s-0.6,1.2-0.2,1.6
                                 l2,2.7l-2,2.7C30.3,20.7,30.4,21.4,30.9,21.7z"></path>
                                 <path d="M18.2,21.5c0.3,0.5,1.2,0.6,1.6,0.2s0.6-1.2,0.2-1.6l-2-2.7l2-2.7c0.3-0.5,0.2-1.3-0.2-1.6c-0.5-0.3-1.3-0.2-1.6,0.2
                                 l-2.5,3.5c-0.3,0.5-0.3,0.9,0,1.4L18.2,21.5z"></path>
                                 <path d="M23.5,21.6l5.4-6.6c0.5-0.5,0.3-1.3-0.1-1.6c-0.5-0.5-1.3-0.3-1.6,0.1l-5.4,6.6c-0.5,0.5-0.3,1.3,0.1,1.6
                                 C22.3,22.1,23.1,22.1,23.5,21.6z"></path>
                                 <path d="M36,38.3H15.5c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2H36c0.7,0,1.2-0.5,1.2-1.2S36.7,38.3,36,38.3z"></path>
                                 <path d="M15.5,27.6h23.2c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H15.5c-0.7,0-1.2,0.5-1.2,1.2S14.8,27.6,15.5,27.6z"></path>
                                 <path d="M58.5,25.3H44.2c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h14.3c0.7,0,1.2-0.5,1.2-1.2S59.2,25.3,58.5,25.3z"></path>
                                 <path d="M56.1,38.3H41.4c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h14.7c0.7,0,1.2-0.5,1.2-1.2S56.7,38.3,56.1,38.3z"></path>
                                 <path d="M15.5,34.1h15.7c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2H15.5c-0.7,0-1.2,0.5-1.2,1.2C14.3,33.6,14.8,34.1,15.5,34.1
                                 z"></path>
                                 <path d="M35.5,32.9c0,0.7,0.5,1.2,1.2,1.2h16c0.7,0,1.2-0.5,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2h-16C36,31.8,35.5,32.4,35.5,32.9z"></path>
                              </g>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Fast Development Cycles',
					text: 'Time to market is considerably slashed due to simplified processes, and higher speed of development. The simplified processes and high speed make it possible shorten development cycles.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <path d="M71.7,26.1l-2.6,0.8c-2.6-7.2-7.5-13.3-13.9-17.4c-0.4-0.2-0.8-0.2-1.2,0L47.2,14c-0.5,0.3-0.6,1-0.3,1.5
                           c0.1,0.2,0.3,0.3,0.5,0.4c5.9,2.8,10.5,7.8,12.8,13.9l-2.5,0.8c-0.9,0.3-1.4,1.3-1.1,2.2c0.1,0.3,0.3,0.6,0.5,0.8l10.5,8.6
                           c0.7,0.6,1.8,0.5,2.4-0.2c0.2-0.2,0.3-0.4,0.3-0.7L74,28.2c0.3-0.9-0.3-1.9-1.2-2.1C72.4,26,72.1,26,71.7,26.1L71.7,26.1z M68.4,40
                           l-9.2-7.5l2.8-0.9c0.6-0.2,0.9-0.8,0.7-1.4c0,0,0,0,0,0c-2.1-6.5-6.6-12-12.6-15.4l4.6-3c6,4,10.5,10,12.8,16.9
                           c0.2,0.6,0.8,0.9,1.4,0.7c0,0,0,0,0,0l2.8-0.9L68.4,40z M70.2,47.3c0.1-0.4,0-0.9-0.4-1.1l-3.2-2.6l-0.1-0.1l-3-2.4
                           c-0.5-0.4-1.2-0.3-1.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c-0.8,6.4-4,12.2-8.9,16.3l-1.6-2.2c-0.6-0.7-1.7-0.9-2.4-0.3
                           c-0.2,0.2-0.4,0.4-0.5,0.7l-4.7,12.8c-0.3,0.9,0.1,1.9,1,2.2c0.2,0.1,0.4,0.1,0.6,0.1h0.1l13.6-0.9c1-0.1,1.7-0.9,1.6-1.8
                           c0-0.3-0.1-0.6-0.3-0.9l-1.6-2.2C64.2,60.9,68.4,54.5,70.2,47.3L70.2,47.3z M56.2,64.5c-0.5,0.4-0.6,1.1-0.2,1.6l1.8,2.3l-11.9,0.8
                           l4-11.2l1.7,2.3c0.4,0.5,1.1,0.6,1.6,0.2c5.4-4.1,9-9.9,10.3-16.5l1.6,1.3l0.1,0.1l2.6,2.1C66,54.3,61.9,60.3,56.2,64.5z M14.8,70.5
                           c0.6,0.1,1.3-0.2,1.6-0.7l1.6-2.2c5.6,3.7,12.2,5.7,18.9,5.7c0.9,0,1.8,0,2.7-0.1c0.4,0,0.8-0.3,1-0.7l2.8-7.7
                           c0.2-0.6-0.1-1.2-0.6-1.4c-0.2-0.1-0.4-0.1-0.6,0c-6.4,1.4-13.1,0.1-18.7-3.4l1.5-2.2c0.5-0.8,0.4-1.9-0.4-2.4
                           c-0.3-0.2-0.6-0.3-0.9-0.3L10,54.4c-0.6,0-1.2,0.3-1.5,0.7c-0.3,0.5-0.4,1.1-0.2,1.6l5.1,12.6C13.6,70,14.2,70.4,14.8,70.5
                           L14.8,70.5z M22.6,57.2l-1.7,2.3c-0.4,0.5-0.2,1.2,0.3,1.6c5.6,4,12.6,5.6,19.4,4.7L38.7,71c-7.3,0.4-14.5-1.6-20.4-5.8
                           c-0.2-0.1-0.4-0.2-0.6-0.2h-0.2c-0.3,0-0.6,0.2-0.7,0.5l-1.7,2.4l-4.5-11.1L22.6,57.2z M1.2,27.9L1.2,27.9l2.6,0.9
                           C1.5,36.4,1.9,44.6,4.9,52c0.2,0.4,0.6,0.7,1,0.7l8.2,0.3h0c0.6,0,1.1-0.5,1.1-1.1c0-0.2-0.1-0.4-0.1-0.6
                           c-3.4-5.9-4.3-12.9-2.4-19.5l2.5,0.9c0.9,0.3,1.9-0.2,2.2-1.1c0.1-0.3,0.1-0.7,0-1l-3.4-13.2c-0.2-0.9-1.2-1.5-2.1-1.2
                           c-0.2,0.1-0.5,0.2-0.6,0.3L0.7,24.9c-0.7,0.6-0.8,1.7-0.2,2.4C0.6,27.6,0.9,27.8,1.2,27.9L1.2,27.9z M12.1,18.7l3,11.5l-2.7-0.9
                           c-0.6-0.2-1.2,0.1-1.4,0.7c-2.3,6.8-1.8,14.2,1.3,20.6l-5.5-0.2c-2.7-7.1-2.9-14.8-0.5-22c0.2-0.6-0.1-1.2-0.7-1.4l-2.7-0.9
                           L12.1,18.7z M50.5,8.9c0-0.6-0.3-1.2-0.8-1.5l0,0L38.4,0c-0.8-0.5-1.9-0.3-2.4,0.5c-0.2,0.3-0.3,0.6-0.3,1v2.7
                           c-7.5,0.2-14.8,2.9-20.6,7.7c-0.3,0.3-0.5,0.7-0.4,1.1l2,7.9c0.2,0.6,0.8,1,1.4,0.8c0.2-0.1,0.4-0.2,0.5-0.3
                           c4.5-4.7,10.6-7.5,17.1-7.8v2.7c0,1,0.7,1.7,1.7,1.8c0.3,0,0.7-0.1,1-0.3l11.4-7.4C50.2,10.1,50.5,9.5,50.5,8.9z M37.9,15.4v-2.9
                           c0-0.6-0.5-1.1-1.1-1.1c-6.8,0-13.4,2.6-18.4,7.1L17,13.2c5.7-4.4,12.6-6.8,19.8-6.8c0.6,0,1.1-0.5,1.1-1.1V2.4l10,6.5L37.9,15.4z
                           M48.7,23.5h-9c-0.6,0-1.1,0.5-1.1,1.1v7.5h-3.4v-7.5c0-0.6-0.5-1.1-1.1-1.1h-9.3c-1.8,0-3.3,1.5-3.3,3.3v23.9
                           c0,1.8,1.5,3.3,3.3,3.3h23.9c1.8,0,3.3-1.5,3.3-3.3V26.7C52,24.9,50.5,23.5,48.7,23.5z M49.8,50.7c0,0.6-0.5,1.1-1.1,1.1
                           c0,0,0,0,0,0H24.8c-0.6,0-1.1-0.5-1.1-1.1l0,0V26.7c0-0.6,0.5-1.1,1.1-1.1H33v7.5c0,0.6,0.5,1.1,1.1,1.1h5.6c0.6,0,1.1-0.5,1.1-1.1
                           v-7.5h7.9c0.6,0,1.1,0.5,1.1,1.1l0,0V50.7z"></path>
                        </svg>
               `
				},
				{
					title: 'Appropriate for Android Devices',
					text: 'Multiple APIs that are most suitable for backend development which is a bonus for Android devices and applications. The dynamic NPM and easy code make this a better option for Android devices.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <g>
                              <path d="M55,0H19c-3.4,0-6.2,2.8-6.2,6.2v61.7c0,3.4,2.8,6.2,6.2,6.2H55c3.4,0,6.2-2.8,6.2-6.2V6.2C61.2,2.8,58.4,0,55,0z
                              M58.1,67.8c0,1.7-1.4,3.1-3.1,3.1H19c-1.7,0-3.1-1.4-3.1-3.1V6.2c0-1.7,1.4-3.1,3.1-3.1H55c1.7,0,3.1,1.4,3.1,3.1V67.8z"></path>
                              <path d="M53.5,10.8h-33c-0.9,0-1.5,0.6-1.5,1.5v48.9c0,0.9,0.6,1.5,1.5,1.5h33c0.9,0,1.5-0.6,1.5-1.5V12.3
                              C55,11.4,54.4,10.8,53.5,10.8z M52,59.7H22V13.9H52V59.7z"></path>
                              <path d="M40.1,65.2h-6.2c-0.9,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h6.2c0.9,0,1.5-0.6,1.5-1.5S40.9,65.2,40.1,65.2z"></path>
                              <path d="M31,8.3h1.4c0.9,0,1.5-0.6,1.5-1.5s-0.6-1.5-1.5-1.5H31c-0.9,0-1.5,0.6-1.5,1.5C29.4,7.6,30.1,8.3,31,8.3z"></path>
                              <path d="M38.1,8.3h5.1c0.9,0,1.5-0.6,1.5-1.5s-0.6-1.5-1.5-1.5h-5.1c-0.9,0-1.5,0.6-1.5,1.5C36.5,7.6,37.2,8.3,38.1,8.3z"></path>
                           </g>
                        </svg>
               `
				},
				{
					title: 'Asynchronous I/O',
					text: 'The heart of NodeJS is the Event Loop. Since the NodeJS is presenting the benefit of non-blocking I/O frameworks, it relatively supports you to process several requests simultaneously.',
					svg: `
               <svg x="0px" y="0px" viewBox="0 0 74 74" style="enable-background:new 0 0 74 74;" xml:space="preserve">
                           <g>
                              <path d="M37,4.4c4.4,0,8.7,0.9,12.7,2.6c3.9,1.6,7.4,4,10.3,7c3,3,5.3,6.5,7,10.4c1.7,4,2.6,8.3,2.6,12.7c0,4.4-0.9,8.7-2.6,12.7
                              c-1.6,3.9-4,7.4-7,10.4c-3,3-6.5,5.4-10.3,7c-4,1.7-8.3,2.6-12.7,2.6s-8.7-0.9-12.7-2.6c-3.9-1.6-7.4-4-10.3-7c-3-3-5.3-6.5-7-10.4
                              c-1.7-4-2.6-8.3-2.6-12.7c0-4.4,0.9-8.7,2.6-12.7c1.6-3.9,4-7.4,7-10.4s6.5-5.4,10.3-7C28.3,5.2,32.6,4.4,37,4.4 M37-0.1
                              C16.6-0.1,0,16.5,0,37s16.6,37.1,37,37.1S74,57.5,74,37S57.4-0.1,37-0.1L37-0.1z"></path>
                              <path d="M34.1,12.6h5.8v33.6h-5.8L34.1,12.6L34.1,12.6z"></path>
                              <path d="M34.1,52.1h5.8v6.3h-5.8L34.1,52.1L34.1,52.1z"></path>
                           </g>
                        </svg>
               `
				}
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
