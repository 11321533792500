<template>
	<section class="padding-60 bg-white web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>How it works</span>
				<h2>The workflow of how an on demand car wash request is served</h2>
			</div>
			<div class="car-wash-process-box-wrap">
				<div class="row justify-content-center">
					<div class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-0">
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>The user sends a request for the car wash service</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M37.7,47.9c-1.6,0-3.2,0-4.7,0c-3.3,0-6.6,0-9.9,0c-2.9,0-5.9,0-8.8,0c-0.7,0-1.4,0-2,0c0,0-0.1,0-0.1,0h0
                      c-0.1,0-0.1,0-0.2,0c-0.2,0,0.2,0.1,0,0c-0.1,0-0.3-0.1-0.4-0.1c0,0,0,0-0.1,0c-0.1,0-0.1,0,0.1,0c0,0-0.2-0.1-0.2-0.1
                      c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1,0,0c0,0-0.3-0.2-0.3-0.3c0,0-0.1-0.1-0.1-0.2c0,0,0.1,0.1,0.1,0.1
                      c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.1-0.2,0.1,0.2,0,0c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1
                      c0-0.1,0-0.1,0,0.1c0,0,0-0.3,0-0.3c0-1.1,0-2.3,0-3.4c0-2.7,0-5.3,0-8c0-3.4,0-6.8,0-10.2c0-3.4,0-6.7,0-10.1c0-2.5,0-5.1,0-7.6
                      c0-0.7,0-1.4,0-2.1c0-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.3,0-0.3c0,0,0,0.1,0,0.1c0,0,0-0.1,0-0.1
                      c0-0.1,0-0.2,0.1-0.3c0,0,0.1-0.2,0.1-0.2c0,0,0,0.1-0.1,0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2-0.2,0.2,0,0
                      c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c-0.1,0.1-0.1,0.1,0,0c0.1-0.1,0.2-0.1,0.4-0.2c0,0,0,0,0.1,0
                      c0.1,0,0.1,0-0.1,0c0,0,0.2-0.1,0.2-0.1c0,0,0.4-0.1,0.4-0.1c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0
                      c0.1,0,0.2,0,0.4,0c0.8,0,1.7,0,2.5,0c3.1,0,6.2,0,9.2,0c3.2,0,6.4,0,9.7,0c1,0,2,0,3,0c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0
                      c0,0,0,0,0.1,0c0,0,0.2,0,0.2,0c0,0-0.1,0-0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1-0.2-0.1,0,0
                      c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1-0.2-0.2,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0.1c0.1,0.1,0.1,0.1,0-0.1
                      c0,0,0.1,0.2,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0-0.1c0,0,0.1,0.4,0.1,0.4c0,0,0.1,0.2,0,0.2
                      c0,0,0-0.1,0-0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.6,0,1.2,0,1.7c0,2.4,0,4.7,0,7.1c0,3.3,0,6.6,0,9.9
                      c0,3.4,0,6.9,0,10.3c0,2.8,0,5.6,0,8.4c0,1.4,0,2.8,0,4.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1
                      c0,0.1,0,0.1,0-0.1c0,0-0.1,0.4-0.1,0.4c0,0-0.1,0.2-0.1,0.2c0,0,0-0.1,0.1-0.1c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.4
                      c-0.1,0.2,0.2-0.2,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.1,0.1,0.1,0c0,0-0.3,0.2-0.4,0.2
                      c0,0-0.2,0.1-0.2,0.1c0,0,0.1,0,0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1,0.2,0,0,0
                      C37.9,47.8,37.8,47.9,37.7,47.9c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c2.2,0,4-1.8,4-4.1c0-0.4,0-0.9,0-1.3
                      c0-0.9,0-1.8,0-2.7c0-1.3,0-2.5,0-3.8c0-1.5,0-3.1,0-4.6c0-1.7,0-3.4,0-5.1c0-1.8,0-3.5,0-5.3c0-1.7,0-3.5,0-5.2
                      c0-1.6,0-3.2,0-4.8c0-1.4,0-2.7,0-4.1c0-1,0-2,0-3c0-0.6,0-1.1,0-1.7c0-0.1,0-0.2,0-0.3c0-1.4-0.7-2.6-1.9-3.4
                      C38.9,0,37.9,0,36.9,0c-1,0-2,0-3,0c-1.5,0-3,0-4.5,0c-1.7,0-3.5,0-5.2,0c-1.7,0-3.4,0-5.1,0c-1.4,0-2.8,0-4.2,0
                      c-0.8,0-1.6,0-2.5,0c-0.2,0-0.3,0-0.5,0c-1.4,0.1-2.8,0.9-3.4,2.3C8.1,3,8.1,3.8,8.1,4.5c0,0.7,0,1.4,0,2.1c0,1.1,0,2.2,0,3.3
                      c0,1.4,0,2.9,0,4.3c0,1.6,0,3.3,0,4.9c0,1.7,0,3.5,0,5.2c0,1.8,0,3.5,0,5.3c0,1.7,0,3.3,0,5c0,1.5,0,3,0,4.4c0,1.2,0,2.4,0,3.6
                      c0,0.8,0,1.6,0,2.4c0,0.3,0,0.6,0,0.9c0,1.4,0.8,2.7,2,3.4c0.6,0.4,1.4,0.5,2.1,0.5c0.7,0,1.3,0,2,0c1.3,0,2.6,0,3.9,0
                      c1.7,0,3.3,0,5,0c1.8,0,3.5,0,5.3,0c1.6,0,3.2,0,4.8,0c1.1,0,2.3,0,3.4,0c0.4,0,0.8,0,1.3,0c0.6,0,1.1-0.5,1.1-1.1
                      C38.7,48.3,38.3,47.9,37.7,47.9z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													d="M37.3,41.2c-0.8,0-1.7,0-2.5,0c-2,0-3.9,0-5.9,0c-2.4,0-4.8,0-7.1,0c-2.1,0-4.1,0-6.2,0c-1,0-2,0-3,0c0,0,0,0,0,0
                      c0.4,0.4,0.7,0.7,1.1,1.1c0-1.1,0-2.3,0-3.4c0-2.8,0-5.5,0-8.3c0-3.3,0-6.6,0-9.9c0-2.9,0-5.7,0-8.6c0-1.4,0.1-2.8,0-4.2
                      c0,0,0,0,0-0.1c-0.4,0.4-0.7,0.7-1.1,1.1c0.8,0,1.7,0,2.5,0c2,0,3.9,0,5.9,0c2.4,0,4.8,0,7.1,0c2.1,0,4.1,0,6.2,0c1,0,2,0,3,0
                      c0,0,0,0,0,0c-0.4-0.4-0.7-0.7-1.1-1.1c0,1.1,0,2.3,0,3.4c0,2.8,0,5.5,0,8.3c0,3.3,0,6.6,0,9.9c0,2.9,0,5.7,0,8.6
                      c0,1.4,0,2.8,0,4.2c0,0,0,0,0,0.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0-1.1,0-2.3,0-3.4c0-2.8,0-5.5,0-8.3
                      c0-3.3,0-6.6,0-9.9c0-2.9,0-5.7,0-8.6c0-1.4,0-2.8,0-4.2c0,0,0,0,0-0.1c0-0.6-0.5-1.1-1.1-1.1c-0.8,0-1.7,0-2.5,0
                      c-2,0-3.9,0-5.9,0c-2.4,0-4.8,0-7.1,0c-2.1,0-4.1,0-6.2,0c-1,0-2,0-3,0c0,0,0,0,0,0c-0.6,0-1.1,0.5-1.1,1.1c0,1.1,0,2.3,0,3.4
                      c0,2.8,0,5.5,0,8.3c0,3.3,0,6.6,0,9.9c0,2.9,0,5.7,0,8.6c0,1.4,0,2.8,0,4.2c0,0,0,0,0,0.1c0,0.6,0.5,1.1,1.1,1.1
                      c0.8,0,1.7,0,2.5,0c2,0,3.9,0,5.9,0c2.4,0,4.8,0,7.1,0c2.1,0,4.1,0,6.2,0c1,0,2,0,3,0c0,0,0,0,0,0c0.6,0,1.1-0.5,1.1-1.1
                      C38.3,41.6,37.9,41.2,37.3,41.2z"
												></path>
											</g>
										</g>
										<g>
											<g>
												<path
													d="M26.4,30.7c0-1.2,0-2.4,0-3.6c0-0.6-0.5-1-1.1-1.1c-0.8-0.1-1.7,0-2.5,0.1c-1.7,0.2-3.4,0.9-4.8,2c-1,0.8-1.8,1.8-2.3,2.9
                      c0.7,0.2,1.3,0.4,2,0.5c0-0.5,0-0.9,0.1-1.4c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.2,0,0.3,0,0c0-0.2,0.1-0.4,0.1-0.6
                      c0.1-0.4,0.2-0.7,0.3-1.1c0-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0.1-0.2-0.1,0.2,0,0
                      c0.3-0.6,0.6-1.1,0.9-1.6c0.1-0.1-0.2,0.2,0,0c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6
                      c0,0,0.1-0.1,0.1-0.1c0.2-0.2-0.2,0.1,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3
                      c0.1,0,0.1,0,0.2-0.1c0,0-0.3,0.1-0.1,0.1c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.8-0.2,1.1-0.3c0.2,0,0.3-0.1,0.5-0.1
                      c0.1,0,0.2,0,0.2,0c0.2,0-0.1,0-0.2,0c0,0,0.1,0,0.1,0c0.3,0,0.7-0.1,1.1-0.1c0.6,0,1.1-0.5,1.1-1.1c0-1.2,0-2.4,0-3.6
                      c-0.6,0.2-1.2,0.5-1.8,0.7c0.9,0.7,1.8,1.4,2.7,2.1c1.4,1.1,2.8,2.2,4.2,3.3c0.3,0.3,0.7,0.5,1,0.8c0-0.5,0-1,0-1.5
                      c-0.9,0.7-1.8,1.4-2.7,2.1c-1.4,1.1-2.8,2.2-4.2,3.3c-0.3,0.3-0.7,0.5-1,0.8c-0.4,0.3-0.4,1.1,0,1.5c0.5,0.4,1,0.4,1.5,0
                      c0.9-0.7,1.8-1.4,2.7-2.1c1.4-1.1,2.8-2.2,4.2-3.3c0.3-0.3,0.7-0.5,1-0.8c0.4-0.3,0.4-1.2,0-1.5c-0.9-0.7-1.8-1.4-2.7-2.1
                      c-1.4-1.1-2.8-2.2-4.2-3.3c-0.3-0.3-0.7-0.5-1-0.8c-0.4-0.3-0.7-0.4-1.2-0.2c-0.4,0.2-0.7,0.5-0.7,1c0,1.2,0,2.4,0,3.6
                      c0.4-0.4,0.7-0.7,1.1-1.1c-1.1,0-2.3,0.2-3.3,0.5c-2.3,0.8-4.2,2.3-5.3,4.5c-0.9,1.8-1.2,3.7-1.2,5.7c0,0.4,0.3,0.9,0.8,1
                      c0.4,0.1,1-0.1,1.2-0.5c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.1-0.1,0.1-0.1c0.2-0.2-0.1,0.1,0,0
                      c0.3-0.4,0.6-0.7,1-1c0.1-0.1,0.2-0.1,0.3-0.2c-0.2,0.2,0,0,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.1,0.4-0.2,0.5-0.3
                      c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c-0.3,0.2-0.1,0,0,0c0.7-0.3,1.4-0.5,2.1-0.6c0.1,0,0.4,0,0,0c0.1,0,0.1,0,0.2,0
                      c0.2,0,0.3,0,0.5,0c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0,0.8,0c-0.4-0.4-0.7-0.7-1.1-1.1c0,1.2,0,2.4,0,3.6c0,0.6,0.5,1.1,1.1,1.1
                      C25.9,31.7,26.4,31.3,26.4,30.7z"
												></path>
											</g>
										</g>
									</svg>
								</div>
							</div>
							<div class="bt arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
					<div class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-1">
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>Admin receives the request from the user</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<g transform="translate(-479.000000, -950.000000)">
												<g transform="translate(479.000000, 950.000000)">
													<path
														d="M21.3,4.2h4.2c0.5,0,0.8-0.3,0.8-0.8S26,2.6,25.5,2.6h-4.2c-0.5,0-0.8,0.3-0.8,0.8S20.8,4.2,21.3,4.2z"
													></path>
													<path
														d="M17.9,4.2h0.8c0.5,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h-0.8c-0.5,0-0.8,0.3-0.8,0.8S17.4,4.2,17.9,4.2z"
													></path>
													<path
														d="M7.1,8.3v0.8c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V8.3c0-0.5-0.3-0.8-0.8-0.8S7.1,7.8,7.1,8.3z"
													></path>
													<path
														d="M7.1,11.7v0.8c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.8c0-0.5-0.3-0.8-0.8-0.8S7.1,11.2,7.1,11.7z"
													></path>
													<path
														d="M7.1,15v0.8c0,0.5,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V15c0-0.5-0.3-0.8-0.8-0.8S7.1,14.5,7.1,15z"
													></path>
													<path
														d="M23.8,45.8c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7C23,44.2,23.8,44.9,23.8,45.8z M25.4,45.8
                        c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3S25.4,47.7,25.4,45.8z"
													></path>
													<path
														d="M34.6,22.5h0.8c0.5,0,0.8-0.3,0.8-0.8V5.8c0-0.5-0.3-0.8-0.8-0.8H8.8C8.3,5,7.9,5.3,7.9,5.8v36.7c0,0.5,0.3,0.8,0.8,0.8
                        h26.7c0.5,0,0.8-0.3,0.8-0.8V26.7c0-0.5-0.3-0.8-0.8-0.8h-0.8v15.8h-25v-35h25V22.5z"
													></path>
													<path
														d="M32.9,0H11.3C9.4,0,7.9,1.5,7.9,3.3v43.3c0,1.8,1.5,3.3,3.3,3.3h21.7c1.8,0,3.3-1.5,3.3-3.3v-20c0-0.5-0.3-0.8-0.8-0.8
                        h-0.8v20.9c0,0.9-0.8,1.7-1.7,1.7H11.3c-0.9,0-1.7-0.8-1.7-1.7V3.3c0-0.9,0.8-1.7,1.7-1.7H33c0.9,0,1.7,0.8,1.7,1.7v17.5h-0.1
                        v1.7h0.8c0.5,0,0.8-0.3,0.8-0.8V3.3C36.2,1.5,34.7,0,32.9,0z"
													></path>
													<g>
														<g>
															<path
																d="M29.4,29.8c0.6,0,1.1-0.5,1.1-1.1c0-1.2,0-2.4,0-3.6c-0.4,0.4-0.7,0.7-1.1,1.1c0.3,0,0.6,0,0.8,0s0.5,0,0.7,0
                            s0.3,0,0.5,0c0.1,0,0.1,0,0.2,0c-0.4,0-0.1,0,0,0c0.7,0.1,1.4,0.3,2.1,0.6c0.1,0,0.3,0.2,0,0h0.1c0.1,0,0.2,0.1,0.3,0.1
                            c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0.1,0.3,0.3,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.3,0.7,0.6,1,1
                            c0.1,0.1-0.2-0.2,0,0l0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.4,0.8,0.6,1.2,0.5c0.5-0.1,0.8-0.6,0.8-1
                            c0-2-0.3-3.9-1.2-5.7c-1.1-2.2-3-3.7-5.3-4.5c-1-0.3-2.2-0.5-3.3-0.5c0.4,0.4,0.7,0.7,1.1,1.1c0-1.2,0-2.4,0-3.6
                            c0-0.5-0.3-0.8-0.7-1c-0.5-0.2-0.8-0.1-1.2,0.2c-0.3,0.3-0.7,0.5-1,0.8c-1.4,1.1-2.8,2.2-4.2,3.3c-0.9,0.7-1.8,1.4-2.7,2.1
                            c-0.4,0.3-0.4,1.2,0,1.5c0.3,0.3,0.7,0.5,1,0.8c1.4,1.1,2.8,2.2,4.2,3.3c0.9,0.7,1.8,1.4,2.7,2.1c0.5,0.4,1,0.4,1.5,0
                            c0.4-0.4,0.4-1.2,0-1.5c-0.3-0.3-0.7-0.5-1-0.8c-1.4-1.1-2.8-2.2-4.2-3.3c-0.9-0.7-1.8-1.4-2.7-2.1c0,0.5,0,1,0,1.5
                            c0.3-0.3,0.7-0.5,1-0.8c1.4-1.1,2.8-2.2,4.2-3.3c0.9-0.7,1.8-1.4,2.7-2.1c-0.6-0.2-1.2-0.5-1.8-0.7c0,1.2,0,2.4,0,3.6
                            c0,0.6,0.5,1.1,1.1,1.1c0.4,0,0.8,0.1,1.1,0.1h0.1c-0.1,0-0.4,0-0.2,0c0,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1
                            c0.3,0.1,0.7,0.2,1.1,0.3c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1,0c0.2,0-0.1-0.1-0.1-0.1c0.1,0.1,0.1,0.1,0.2,0.1
                            c0.2,0.1,0.4,0.2,0.6,0.3s0.4,0.3,0.6,0.4c0.1,0,0.2,0.1,0.3,0.2c0.2,0.1-0.2-0.2,0,0l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6
                            c0.1,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.1,0.1,0.2c0.2,0.2-0.1-0.1,0,0c0.3,0.5,0.6,1,0.9,1.6c0.1,0.2-0.1-0.2,0,0v0.1
                            c0,0,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.3,0.2,0.5c0.1,0.4,0.2,0.7,0.3,1.1c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0-0.2,0,0v0.1
                            c0,0.1,0,0.2,0,0.3c0.1,0.5,0.1,0.9,0.1,1.4c0.7-0.1,1.3-0.3,2-0.5c-0.5-1.1-1.3-2.1-2.3-2.9c-1.4-1.1-3.1-1.8-4.8-2
                            c-0.8-0.1-1.7-0.2-2.5-0.1c-0.6,0.1-1.1,0.5-1.1,1.1c0,1.2,0,2.4,0,3.6C28.4,29.4,28.9,29.8,29.4,29.8z"
															></path>
														</g>
													</g>
													<line class="st0" x1="34.6" y1="25.9" x2="34.6" y2="25.8"></line>
													<line class="st0" x1="34.6" y1="20.9" x2="34.6" y2="20.8"></line>
												</g>
											</g>
										</g>
									</svg>
								</div>
							</div>
							<div class="bb arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-2 order-md-3 order-sm-4"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center bb">
							<div class="car-wash-process-box-desc">
								<p>Admin start searching for the car washer around the location</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M9.7,6.4c0-1.8-1.4-3.2-3.2-3.2S3.3,4.6,3.3,6.4c0,1.8,1.4,3.2,3.2,3.2S9.7,8.2,9.7,6.4z M4.9,6.4c0-0.9,0.7-1.6,1.6-1.6
                    s1.6,0.7,1.6,1.6S7.4,8,6.5,8S4.9,7.3,4.9,6.4z"
											></path>
											<path
												d="M28.9,20c-4.9,0-8.8,3.9-8.8,8.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8c0-4,3.2-7.2,7.2-7.2c0.4,0,0.8-0.4,0.8-0.8
                    C29.7,20.4,29.3,20,28.9,20z"
											></path>
											<path
												d="M48.5,42.8L41.8,36l-1.1,1.1L39.5,36c1.4-2.1,2.2-4.5,2.2-7.2c0-3.4-1.3-6.5-3.5-8.7c-1.5-8.9-9.1-15.3-18.1-15.3
                    c-2.5,0-4.9,0.5-7.2,1.5C12.8,2.8,10,0,6.5,0C3,0,0.1,2.9,0.1,6.4c0,2,2,5.7,3.6,8.4c-1.3,2.6-2,5.5-2,8.4
                    c0,10.1,8.3,18.4,18.4,18.4c1.6,0,3.3-0.2,4.8-0.6c1.3,0.4,2.6,0.6,4,0.6c2.7,0,5.1-0.8,7.2-2.2l1.1,1.1l-1.1,1.1l6.8,6.8
                    c0.8,0.8,1.8,1.2,2.8,1.2s2.1-0.4,2.8-1.2c0.8-0.8,1.2-1.8,1.2-2.8C49.7,44.5,49.3,43.5,48.5,42.8z M20.9,39.9v-1.1
                    c0.3,0.3,0.7,0.5,1,0.8C21.6,39.7,21.2,39.8,20.9,39.9z M19.3,39.9c-2.2-0.5-4.1-2.9-5.4-6.3H17c0.5,1.3,1.3,2.6,2.3,3.6L19.3,39.9
                    L19.3,39.9z M13.3,32c-0.7-2.4-1.2-5.1-1.2-8H17c-0.6,1.5-0.9,3.1-0.9,4.8c0,1.1,0.2,2.2,0.4,3.2L13.3,32L13.3,32z M3.3,24h7.2
                    c0.1,2.9,0.5,5.6,1.1,8H5.8C4.4,29.7,3.5,26.9,3.3,24z M17.8,22.4h-5.7c0.1-2.6,0.4-5,1-7.2h6.2v5.2C18.7,21,18.2,21.7,17.8,22.4z
                    M12.5,8.2c0.9-0.5,1.9-0.8,2.9-1.1c-1.5,1.5-2.7,3.8-3.6,6.5H10C11,11.8,12,9.8,12.5,8.2z M20.9,6.5c2.3,0.5,4.4,3.1,5.7,7.1h-5.7
                    V6.5z M19.3,6.5v7.1h-5.7C14.9,9.7,17,7,19.3,6.5z M20.9,15.2h6.2c0.1,0.3,0.2,0.6,0.2,0.9c-2.4,0.3-4.6,1.3-6.4,2.7V15.2z
                    M28.9,16c-0.1-0.3-0.1-0.5-0.2-0.8h6.1c0.5,1,0.9,2,1.3,3.1C34.1,16.8,31.6,16,28.9,16z M33.9,13.6h-5.6c-0.9-2.8-2.1-5-3.6-6.6
                    C28.5,8.1,31.7,10.4,33.9,13.6z M6.5,1.6c2.6,0,4.8,2.2,4.8,4.8c0,2-3,7.1-4.8,9.8c-1.8-2.7-4.8-7.7-4.8-9.8
                    C1.7,3.8,3.8,1.6,6.5,1.6z M5.8,18.1l0.7,1l0.7-1c0.2-0.3,1-1.4,1.9-2.9h2.4c-0.5,2.2-0.9,4.6-0.9,7.2H3.3c0.1-2.1,0.6-4.1,1.4-6
                    C5.3,17.2,5.7,17.9,5.8,18.1z M6.9,33.6h5.2c0.8,2.4,2,4.3,3.3,5.7C12,38.3,9.1,36.3,6.9,33.6z M17.7,28.8c0-6.2,5-11.2,11.2-11.2
                    s11.2,5,11.2,11.2S35.1,40,28.9,40S17.7,35,17.7,28.8z M38.4,39.4l-1-1c0.4-0.4,0.8-0.7,1.1-1.1l1,1l-0.6,0.6L38.4,39.4z
                    M47.4,47.3c-0.9,0.9-2.5,0.9-3.4,0l-5.6-5.6l3.4-3.4l5.6,5.6c0.4,0.4,0.7,1.1,0.7,1.7C48.1,46.3,47.9,46.9,47.4,47.3z"
											></path>
											<rect
												x="45.1"
												y="45"
												transform="matrix(0.707 -0.7072 0.7072 0.707 -18.9487 45.8824)"
												width="1.6"
												height="1.6"
											></rect>
											<rect
												x="42.8"
												y="42.7"
												transform="matrix(0.7071 -0.7071 0.7071 0.7071 -18.0071 43.6121)"
												width="1.6"
												height="1.6"
											></rect>
											<rect
												x="40.6"
												y="40.5"
												transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.0706 41.3465)"
												width="1.6"
												height="1.6"
											></rect>
										</g>
									</svg>
								</div>
								<div class="down arrow">
									<img
										src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
										alt="how to make a car wash app"
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-3 order-md-6 order-sm-3"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center down">
							<div class="car-wash-process-box-desc">
								<p>Nearby car washer accepts the wash request</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M13.5,0c-2.3,0-4.2,1.9-4.2,4.2v41.7c0,2.3,1.9,4.2,4.2,4.2h22.9c2.3,0,4.2-1.9,4.2-4.2V4.2c0-2.3-1.9-4.2-4.2-4.2H13.5z
                  M13.5,2.1h22.9c1.1,0,2.1,0.9,2.1,2.1v4.2H11.5V4.2C11.5,3,12.4,2.1,13.5,2.1z M22.9,4.2c-0.6,0-1,0.5-1,1s0.5,1,1,1h4.2
                  c0.6,0,1-0.5,1-1s-0.5-1-1-1H22.9z M11.5,10.4h27.1v29.2H11.5V10.4z M31.2,19.8c-0.3,0-0.6,0.2-0.8,0.4l-6.7,8.5l-4.4-3.5
                  c-0.5-0.3-1.1-0.3-1.5,0.2c-0.3,0.5-0.3,1.1,0.2,1.5l5.2,4.2c0.5,0.3,1.1,0.3,1.5-0.2l7.3-9.4c0.3-0.5,0.3-1.1-0.2-1.5
                  C31.7,19.9,31.5,19.8,31.2,19.8z M11.5,41.7h27.1v4.2c0,1.1-0.9,2.1-2.1,2.1H13.5c-1.1,0-2.1-0.9-2.1-2.1V41.7z M25,43.8
                  c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1s1-0.5,1-1C26,44.2,25.6,43.8,25,43.8z"
										></path>
									</svg>
								</div>
							</div>
							<div class="bt arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-4 order-md-8 order-sm-7 order-8"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>Washer receive payment in its wallet</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 100 100"
										style="enable-background: new 0 0 100 100"
										xml:space="preserve"
									>
										<g>
											<g>
												<path
													d="M29.9,93.9h7.5c0.9,0,1.6-0.6,1.6-1.6s-0.8-1.6-1.6-1.6h-7.5c-0.9,0-1.6,0.6-1.6,1.6S28.9,93.9,29.9,93.9z M96.8,43.6
                      l-8-1.6c-2-0.5-4.1,0.8-4.7,2.8l-10.6-3.6c-2-0.6-4.2-0.8-6.4-0.2v-35C67.2,2.8,64.4,0,61,0H6.1C2.8,0,0,2.8,0,6.1V92
                      c0,4.4,3.6,8,8,8h53c3.4,0,6.1-2.8,6.1-6.1V75.8l5.6-1.1l5.2,1.1C77.5,78,78.9,80,81,80.3c8,1.6,8,1.7,8.8,1.7
                      c1.9,0,3.6-1.2,3.9-3.3l6.3-30.5C100.4,46.1,98.9,43.9,96.8,43.6z M3.1,6.1c0-1.6,1.4-3,3-3H61c1.7,0,3.1,1.4,3.1,3V12H3.1V6.1z
                      M64.1,93.9c0,1.6-1.4,3-3.1,3H8c-2.7,0-4.8-2.2-4.8-4.8V88h60.9V93.9z M64.1,84.8H3.1V15.3h60.9v26.6l-15.6,4.4
                      c-3.4,0.9-5.5,4.5-4.4,8c0.9,3.1,4.4,5,7.5,4.4l7-1.7c1.3-0.3,1.9,0.5,2.2,1.1c0.3,0.6,0.3,1.6-0.6,2.3l-16.4,4.2l-20.6-8.1
                      c-1.7-0.6-3.6-0.6-5.3,0.2c-1.7,0.8-3,2.2-3.6,3.8c-1.2,3.1,0.3,6.9,3.6,8.3l23,10.3c2,0.9,4.4,1.2,6.7,0.8l16.6-3.3V84.8z
                      M78.3,72.8c-5.5-1.2-5.3-1.4-5.9-1.2l-25.5,5.2c-1.6,0.3-3.3,0.2-4.8-0.5l-23-10.3c-1.9-0.8-2.5-3-1.9-4.4
                      c0.6-1.9,2.8-2.8,4.7-2.2L43,67.8c0.3,0.2,0.6,0.2,0.9,0l17.3-4.5c1.9-0.5,3.8-3.4,2.5-6.4c-0.9-2.3-3.4-3.6-5.8-3l-7,1.7
                      c-1.7,0.5-3.3-0.6-3.9-2.2c-0.5-1.7,0.5-3.6,2.2-4.1l18.4-5.2c1.6-0.5,3.3-0.5,4.8,0.2L83.5,48C82.1,55,81,60.3,78.3,72.8z
                      M96.9,47.7l-6.3,30.5c-0.2,0.5-0.6,0.8-1.1,0.6l-8-1.6c-0.5-0.2-0.8-0.6-0.6-1.1c1.6-7.3,2.7-12.7,6.3-30.5
                      c0.2-0.5,0.6-0.8,1.1-0.6l8,1.6C96.8,46.7,97.1,47.2,96.9,47.7z M28.3,54.2c9.2,0,16.7-7.5,16.7-16.6c0-9.1-7.5-16.6-16.7-16.6
                      c-9.2,0-16.7,7.5-16.7,16.6C11.6,46.7,19.1,54.2,28.3,54.2z M28.3,24.2c7.5,0,13.6,6.1,13.6,13.4c0,7.3-6.1,13.4-13.6,13.4
                      c-7.5,0-13.6-6.1-13.6-13.4C14.7,30.3,20.8,24.2,28.3,24.2z M28.3,43.1c-1.1,0-2-0.9-2-1.9c0-0.9-0.6-1.6-1.6-1.6
                      c-0.9,0-1.6,0.6-1.6,1.6c0,2.2,1.6,4.1,3.6,4.8v0.8c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6v-0.9c2-0.6,3.6-2.5,3.6-4.8
                      c0-2.8-2.3-5-5.2-5c-1.1,0-2-0.9-2-1.9c0-0.9,0.9-1.9,2-1.9s2,0.9,2,1.9c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6
                      c0-2.2-1.4-4.1-3.6-4.8v-0.9c0-0.9-0.6-1.6-1.6-1.6c-0.9,0-1.6,0.6-1.6,1.6v0.9c-2,0.6-3.6,2.5-3.6,4.8c0,2.8,2.3,5,5.2,5
                      c1.1,0,2,0.9,2,1.9S29.4,43.1,28.3,43.1z M41,6.1c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.8,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6
                      C42.5,6.7,41.9,6.1,41,6.1z M33.6,6.1c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.6,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6
                      C35.2,6.7,34.6,6.1,33.6,6.1z M26.1,6.1c-0.9,0-1.6,0.6-1.6,1.6c0,0.9,0.8,1.6,1.6,1.6c0.9,0,1.6-0.6,1.6-1.6
                      C27.7,6.7,27.1,6.1,26.1,6.1z"
												></path>
											</g>
										</g>
									</svg>
								</div>
							</div>
							<div class="bb rotate arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-5 order-md-7 order-sm-8 order-7"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>Provide service as per the package</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<g>
											<path
												d="M14.8,13.8h0.7c0,0.1-0.1,0.2-0.1,0.2L15,16.2c-0.1,0.4,0.2,0.8,0.6,0.8c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,0.7-0.6l0.4-2.2
                    c0-0.2,0-0.3-0.1-0.5h2.4c0,0.1-0.1,0.2-0.1,0.2l-0.4,2.2c-0.1,0.4,0.2,0.8,0.6,0.8c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,0.7-0.6l0.4-2.2
                    c0-0.2,0-0.3-0.1-0.5h2.4c0,0.1-0.1,0.2-0.1,0.2l-0.4,2.2c-0.1,0.4,0.2,0.8,0.6,0.8c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,0.7-0.6l0.4-2.2
                    c0-0.2,0-0.3-0.1-0.5h0.3c0.4,0,0.7-0.3,0.7-0.7c0-1.9-1.2-5.5-5.6-5.5c-4.4,0-5.6,3.6-5.6,5.5C14.1,13.4,14.4,13.8,14.8,13.8z
                    M19.6,9c3,0,3.8,2.1,4,3.3h-8C15.9,11.1,16.7,9,19.6,9z"
											></path>
											<path
												d="M17.3,17.8c-0.4-0.1-0.8,0.2-0.9,0.6l-0.4,2.2c-0.1,0.4,0.2,0.8,0.6,0.8c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.6l0.4-2.2
                    C18,18.3,17.7,17.9,17.3,17.8z"
											></path>
											<path
												d="M21,17.8c-0.4-0.1-0.8,0.2-0.9,0.6l-0.4,2.2c-0.1,0.4,0.2,0.8,0.6,0.8c0,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.6l0.4-2.2
                    C21.7,18.3,21.4,17.9,21,17.8z"
											></path>
											<path
												d="M7.8,39.8c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3C4.4,38.3,5.9,39.8,7.8,39.8z M7.8,34.6
                    c1,0,1.9,0.8,1.9,1.8s-0.8,1.8-1.9,1.8s-1.9-0.8-1.9-1.8S6.8,34.6,7.8,34.6z"
											></path>
											<path
												d="M28.2,36.5c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3c0-1.8-1.5-3.3-3.3-3.3C29.7,33.2,28.2,34.6,28.2,36.5z M33.4,36.5
                    c0,1-0.8,1.8-1.9,1.8c-1,0-1.9-0.8-1.9-1.8s0.8-1.8,1.9-1.8C32.5,34.6,33.4,35.5,33.4,36.5z"
											></path>
											<path
												d="M37.4,41.2v-4.4c0,0-0.1-4.6-4.4-6.4v-1.7c0-0.3-0.1-6.2-4.1-8.6c1.2-1.8,1.9-3.8,1.9-6.1c0-2.9-1.2-5.7-3.3-7.8
                    c-2.1-2.1-4.9-3.2-7.9-3.2c0,0,0,0,0,0c-6.1,0-11.1,4.9-11.1,11c0,2.2,0.7,4.3,1.9,6.1c-4,2.4-4.1,8.3-4.1,8.6v1.7
                    c-4.4,1.8-4.4,6.3-4.4,6.4v4.4c-1,0.1-1.9,1-1.9,2c0,1.1,0.9,2,2,2h1.3v1.8c0,1.6,1.3,2.9,3,2.9s3-1.3,3-2.9v-0.5
                    c4,0.8,10.1,0.9,10.4,0.9c0.3,0,6.4,0,10.4-0.9v0.5c0,1.6,1.3,2.9,3,2.9s3-1.3,3-2.9v-1.8h1.3c1.1,0,2-0.9,2-2
                    C39.3,42.2,38.5,41.3,37.4,41.2z M36,36.8v4.4H24.2c1.3-1,2.6-2.4,2.8-4.4l0.1-0.5c0.3-3.1,0.5-5,4.4-6.3V31c0,0.3,0.2,0.6,0.5,0.7
                    C35.9,32.9,36,36.7,36,36.8z M17.9,41.2c-0.7-0.3-3.9-1.8-4.2-4.6l-0.1-0.5c-0.2-2.3-0.4-4.3-2.3-5.9h7.5V39h1.5v-8.8h7.5
                    c-1.9,1.6-2.1,3.6-2.3,5.9l-0.1,0.5c-0.3,2.7-3.6,4.3-4.2,4.6H17.9z M19.6,4.6c5.3,0,9.6,4.3,9.6,9.5c0,0,0,0,0,0
                    c0,4.4-3,8.2-7.4,9.3c-0.2,0-0.3,0.2-0.4,0.3l-1.8,2.7l-1.8-2.7c-0.1-0.2-0.3-0.3-0.4-0.3c-4.4-1-7.4-4.8-7.4-9.3
                    C10,8.9,14.3,4.6,19.6,4.6z M11.3,21.4c1.4,1.6,3.3,2.8,5.5,3.4l2.3,3.3c0.1,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3l2.3-3.3
                    c2.2-0.6,4.1-1.8,5.5-3.4c2.5,1.4,3.2,4.7,3.4,6.4c-0.2,0.6-0.8,1-1.4,1c0,0,0,0,0,0H9.3c0,0-1.2,0-1.4-0.8
                    C8,26.4,8.6,22.8,11.3,21.4z M7.3,31.6c0.3-0.1,0.5-0.4,0.5-0.7V30c3.9,1.3,4.1,3.2,4.4,6.3l0.1,0.5c0.2,2,1.5,3.5,2.8,4.4H3.3
                    v-4.4C3.3,36.7,3.4,32.9,7.3,31.6z M6.3,48.5c-0.8,0-1.5-0.7-1.5-1.5v-1.8h1.5c0.4,0.5,1,0.8,1.5,1v0.9C7.8,47.9,7.1,48.5,6.3,48.5
                    z M10,45.2h19.2C25.4,46,19.7,46,19.6,46C19.6,46,13.9,46,10,45.2z M34.5,47.1c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5v-0.9
                    c0.5-0.2,1.1-0.5,1.5-1h1.5V47.1z M37.2,43.8H2c-0.3,0-0.6-0.2-0.6-0.5s0.2-0.5,0.6-0.5h19.5c0,0,0,0,0,0c0,0,0,0,0,0h15.7
                    c0.3,0,0.6,0.2,0.6,0.5S37.6,43.8,37.2,43.8z"
											></path>
										</g>
										<g>
											<rect x="39.4" y="20" width="1.2" height="1.2"></rect>
											<path
												d="M48.3,25.1L48.3,25.1c-0.3-0.7-0.8-1.2-1.5-1.4c0.6-0.9,1-2,1-3.1v-2.4c0-3-2.4-5.4-5.4-5.4c-2.1,0-3.9,1.2-4.8,3h-2.7
                    c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5H37v1.8c0,3,2.4,5.4,5.4,5.4c0.1,0,0.2,0,0.4,0l-1.9,1.9l-5.3,1.9
                    c-0.8,0.3-1.4,1-1.5,1.8c0,0-0.1,2.5,2.4,2.8h0.7c0.1,0,0.3-0.1,0.4-0.1l3.1-1.2v13.1l-1.5,0.7c-0.6,0.3-0.9,0.9-0.9,1.5
                    c0,0.9,0.8,1.7,1.7,1.7h9.7V30.5C49.6,28.7,49.1,26.8,48.3,25.1z M37,17.6h-2.1c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h2.2
                    C37.1,17.2,37,17.4,37,17.6z M37,33.2c-0.1,0.1-0.3,0.1-0.4,0.1h-0.3c-0.6,0-1.2-0.5-1.2-1.2c0-0.5,0.3-0.9,0.8-1.1l0.6-0.2
                    l0.9,2.3L37,33.2z M43,20v-1.2h3.6v1.8c0,1.3-0.6,2.5-1.6,3.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.3-1,0.5-1.6,0.6V23
                    c0.7-0.1,1.2-0.7,1.2-1.5S43.6,20.1,43,20z M42.4,13.9c2.1,0,3.9,1.6,4.2,3.6h-8.3C38.5,15.5,40.2,13.9,42.4,13.9z M42.7,21.8h-0.9
                    v3c-2-0.3-3.6-2.1-3.6-4.2v-1.8h3.6v2.4h0.9c0.2,0,0.3,0.1,0.3,0.3S42.8,21.8,42.7,21.8z M38.5,32.6l-0.9-2.2l3.8-1.4l3.8-3.8
                    c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.6,0,1.1,0.5,1.1,1.1c0,0.3-0.1,0.6-0.3,0.8l-4.2,4.2L38.5,32.6z M41.2,49h-1.4
                    c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.1-0.4,0.3-0.5l1.7-0.8h0.4c-0.4,0.3-0.6,0.8-0.6,1.3C41.2,48.6,41.2,48.8,41.2,49z M48.4,49h-5.5
                    c-0.3,0-0.5-0.2-0.5-0.5c0-0.2,0.1-0.4,0.3-0.5l1.7-0.8h4.1V49z M48.4,45.9h-3.6v-9.1h-1.2v9.1h-1.8V32.6l1.5-0.6l4.4-4.4
                    c0.1-0.1,0.1-0.2,0.2-0.2c0.3,1,0.5,2.1,0.5,3.2V45.9z"
											></path>
										</g>
									</svg>
								</div>
							</div>
							<div class="bt rotate arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-6 order-md-4 order-sm-6 order-6"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>Washer goes to the user location on the preferred date and time</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											class="st0"
											d="M44,42.8c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.9,0,1.8-0.1,2.7-0.3c0.7-0.1,1.2-0.6,1.5-1.2
                  c0.2-0.9,0.3-1.7,0.4-2.6c0-0.3-0.4-1.3-1.9-2c-2.3-1.1-5-1.8-8.3-2.2c-1.6-0.2-3.2-0.3-4.3-0.3c-0.7,0-1.3,0-1.7-0.1
                  c-0.6-0.1-1.7-0.7-3.9-2.1c-0.9-0.6-1.8-1.1-2.7-1.6c-0.3-0.1-1-0.3-1.7-0.5l-1-0.2c-0.4-0.1-0.7-0.5-0.6-0.9s0.5-0.7,0.9-0.6
                  c0,0,0,0,0.1,0l1,0.2c0.7,0.1,1.3,0.3,2,0.6c1,0.5,2,1.1,2.9,1.7c1.1,0.7,2.9,1.8,3.3,1.9c0.3,0,0.8,0.1,1.5,0.1
                  c1.2,0,2.7,0.1,4.5,0.3c3.5,0.4,6.4,1.2,8.8,2.3c2.1,1,2.9,2.5,2.8,3.5c-0.1,1-0.2,1.9-0.4,2.8c-0.4,1.2-1.4,2.1-2.7,2.3
                  C46,42.7,45,42.8,44,42.8L44,42.8z"
										></path>
										<path
											class="st0"
											d="M35.6,42.9L35.6,42.9c-1.3,0-2.8-0.1-4.2-0.1c-2.2-0.1-4.3-0.2-5.4-0.2c-1.2,0-4.2,0-7.3,0.1
                  c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c3.1,0,6.1-0.1,7.3-0.1c1.1,0,3.2,0.1,5.4,0.2c1.4,0.1,2.9,0.1,4.2,0.1
                  c0.4,0,0.8,0.4,0.8,0.8C36.4,42.6,36.1,43,35.6,42.9L35.6,42.9z"
										></path>
										<path
											class="st0"
											d="M39.8,45.1c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5l0,0C44.8,42.8,42.5,45.1,39.8,45.1z M39.8,36.6
                  c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4l0,0C43.2,38.2,41.7,36.6,39.8,36.6
                  C39.8,36.6,39.8,36.6,39.8,36.6L39.8,36.6z"
										></path>
										<path
											d="M16,50h-11c-0.4,0-0.8-0.4-0.8-0.8V20.3c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v28.2h9.4V20.3c0-0.4,0.4-0.8,0.8-0.8
                  c0.4,0,0.8,0.4,0.8,0.8v28.9C16.8,49.7,16.5,50,16,50z"
										></path>
										<path
											d="M10.6,50c-0.4,0-0.8-0.4-0.8-0.8V30.5c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v18.7C11.4,49.7,11,50,10.6,50z"
										></path>
										<path
											d="M20.4,33.2c-0.4,0-0.8-0.4-0.8-0.8V18.4c0-2.4-2-4.4-4.4-4.4H5.9c-2.4,0-4.4,2-4.4,4.4v14.1c0,0.4-0.4,0.8-0.8,0.8
                  S0,32.9,0,32.5V18.4c0-3.3,2.7-6,6-6h9.3c3.3,0,6,2.7,6,6v14.1C21.2,32.9,20.8,33.2,20.4,33.2z"
										></path>
										<path
											d="M10.6,14c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7s7,3.2,7,7v0C17.6,10.9,14.5,14,10.6,14z M10.6,1.5C7.5,1.5,5.1,4,5.1,7
                  s2.5,5.5,5.5,5.5S16,10,16,7l0,0C16,4,13.6,1.5,10.6,1.5z"
										></path>
										<path
											d="M10.6,18.7c-0.3,0-0.5-0.1-0.6-0.3l-3.4-4.9c-0.2-0.4-0.1-0.8,0.2-1.1c0.4-0.2,0.8-0.1,1.1,0.2l2.7,4l2.7-4
                  c0.2-0.4,0.7-0.4,1.1-0.2c0.3,0.2,0.4,0.7,0.2,1.1l-3.4,4.9C11.1,18.6,10.8,18.7,10.6,18.7z"
										></path>
										<path
											d="M10.6,5c-2,0-3.9-0.5-5.2-1.4C5,3.3,4.9,2.8,5.2,2.5S5.9,2,6.3,2.3c1,0.7,2.6,1.1,4.3,1.1s3.3-0.4,4.3-1.1
                  C15.2,2,15.7,2.1,16,2.5c0.2,0.4,0.1,0.8-0.2,1.1C14.5,4.5,12.6,5,10.6,5z"
										></path>
										<path
											class="st0"
											d="M34.5,32.7H23.1c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h11.3c0.4,0,0.8,0.4,0.8,0.8S34.9,32.7,34.5,32.7z"
										></path>
										<g>
											<g>
												<path
													d="M27.9,9.4h-2.2c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h2.2c0.3,0,0.5-0.2,0.5-0.5V9.9
                      C28.5,9.6,28.2,9.4,27.9,9.4z M27.4,11.6h-1.1v-1.1h1.1V11.6z"
												></path>
												<path
													d="M32.3,9.4h-2.2c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h2.2c0.3,0,0.5-0.2,0.5-0.5V9.9
                      C32.8,9.6,32.6,9.4,32.3,9.4z M31.7,11.6h-1.1v-1.1h1.1V11.6z"
												></path>
												<path
													d="M35,12.7h2.2c0.3,0,0.5-0.2,0.5-0.5V9.9c0-0.3-0.2-0.5-0.5-0.5H35c-0.3,0-0.5,0.2-0.5,0.5v2.2
                      C34.5,12.4,34.7,12.7,35,12.7z M35.6,10.5h1.1v1.1h-1.1V10.5z"
												></path>
												<path
													d="M40,12.7h2.2c0.3,0,0.5-0.2,0.5-0.5V9.9c0-0.3-0.2-0.5-0.5-0.5H40c-0.3,0-0.5,0.2-0.5,0.5v2.2
                      C39.4,12.4,39.7,12.7,40,12.7z M40.5,10.5h1.1v1.1h-1.1V10.5z"
												></path>
												<path
													d="M27.9,14.9h-2.2c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h2.2c0.3,0,0.5-0.2,0.5-0.5v-2.2
                      C28.5,15.1,28.2,14.9,27.9,14.9z M27.4,17.1h-1.1V16h1.1V17.1z"
												></path>
												<path
													d="M32.3,14.9h-2.2c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5h2.2c0.3,0,0.5-0.2,0.5-0.5v-2.2
                      C32.8,15.1,32.6,14.9,32.3,14.9z M31.7,17.1h-1.1V16h1.1V17.1z"
												></path>
												<path
													d="M45.5,13.3V6.6V1.1c0-0.3-0.2-0.5-0.5-0.5H23c-0.3,0-0.5,0.2-0.5,0.5v5.5v14.3c0,0.3,0.2,0.5,0.5,0.5h12.9
                      c0.7,3.2,3.6,5.6,7,5.6c3.9,0,7.1-3.2,7.1-7.1C50,17,48.2,14.4,45.5,13.3z M23.5,1.7h20.9v4.4H23.5V1.7z M23.5,20.4V7.2h20.9V13
                      c0,0-0.1,0-0.1,0c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7,0c-2,0-3.9,0.8-5.2,2.3c-0.1-0.1-0.3-0.2-0.4-0.2
                      H35c-0.3,0-0.5,0.2-0.5,0.5v2.2c0,0.3,0.2,0.5,0.5,0.5H36c-0.2,0.6-0.2,1.2-0.2,1.8c0,0.1,0,0.3,0,0.4H23.5z M36.7,16.4
                      c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4c0,0,0,0.1-0.1,0.1h-0.8V16h1.1V16.4z M42.9,26c-3,0-5.5-2.2-6-5.2
                      c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.5,0.4-2.2c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.5c1.1-1.8,3-2.9,5.2-2.9
                      c0.2,0,0.3,0,0.5,0c0.5,0,0.9,0.1,1.4,0.3c2.5,0.8,4.2,3.1,4.2,5.7C48.9,23.3,46.2,26,42.9,26z"
												></path>
												<path
													d="M46,19.8h-2.7V16c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v4.4c0,0.3,0.2,0.5,0.5,0.5H46c0.3,0,0.5-0.2,0.5-0.5
                      S46.3,19.8,46,19.8z"
												></path>
												<path
													d="M42.2,3.3H25.7c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h16.5c0.3,0,0.5-0.2,0.5-0.5S42.5,3.3,42.2,3.3z"
												></path>
											</g>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div
						class="wow fadeIn col-lg-3 col-md-4 col-sm-6 col-12 order-lg-7 order-md-5 order-sm-5 order-5"
					>
						<div class="car-wash-process-box bg-white rounded10 text-center">
							<div class="car-wash-process-box-desc">
								<p>User get notification</p>
							</div>
							<div class="car-wash-process-icon-bar">
								<div class="car-wash-process-icon-box">
									<svg
										x="0px"
										y="0px"
										viewBox="0 0 50 50"
										style="enable-background: new 0 0 50 50"
										xml:space="preserve"
									>
										<path
											d="M20.6,46.2h-3.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7C21.3,46.5,21,46.2,20.6,46.2z"
										></path>
										<path
											d="M18.2,3.8h1.6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7S17.8,3.8,18.2,3.8z"
										></path>
										<path
											d="M41.7,11h-9.4V2.3C32.3,1,31.2,0,30,0H8.1C6.8,0,5.8,1,5.8,2.3v45.4c0,1.3,1,2.3,2.3,2.3H30c1.3,0,2.3-1,2.3-2.3V34.3h9.4
                  c1.3,0,2.3-1,2.3-2.3V13.3C44,12,43,11,41.7,11z M8.1,1.5H30c0.5,0,0.8,0.4,0.8,0.8v2.4H7.2V2.3C7.2,1.8,7.6,1.5,8.1,1.5z M30,48.5
                  H8.1c-0.5,0-0.8-0.4-0.8-0.8v-2.4h23.6v2.4C30.8,48.2,30.4,48.5,30,48.5z M42.5,32c0,0.5-0.4,0.8-0.8,0.8H22.9
                  c-0.2,0-0.3,0.1-0.4,0.1l-5.1,3.8V19.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v19.2c0,0.6,0.7,0.9,1.2,0.6l6.1-4.5h7.6v9.5
                  H7.2V6.2h23.6V11H18.2c-1.3,0-2.3,1-2.3,2.3v2.3c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-2.3c0-0.5,0.4-0.8,0.8-0.8h23.5
                  c0.5,0,0.8,0.4,0.8,0.8V32H42.5z"
										></path>
										<path
											d="M36.2,27.4h-0.7c-0.1,0-0.9-0.4-0.9-3.2c0-5.4-2.9-6.2-4.6-6.2s-4.6,0.8-4.6,6.2c0,2.7-0.8,3.1-0.9,3.2h-0.7
                  c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4l0,0c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3l0,0h4c0.4,0,0.7-0.3,0.7-0.7S36.6,27.4,36.2,27.4
                  z M30.8,28.9c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8l0,0H30.8L30.8,28.9z M26.3,27.4c0.3-0.7,0.5-1.7,0.5-3.2
                  c0-4.7,2.4-4.7,3.2-4.7s3.2,0,3.2,4.7c0,1.5,0.2,2.5,0.5,3.2C33.7,27.4,26.3,27.4,26.3,27.4z"
										></path>
										<path
											d="M34.7,18c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.9,0,1.6,0.7,1.6,1.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                  C37.7,19.4,36.4,18,34.7,18z"
										></path>
										<path
											d="M34.7,14.9c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                  C40.9,17.7,38.1,14.9,34.7,14.9z"
										></path>
										<path
											d="M23.7,21.1c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7-0.3,0.7-0.7S25.7,18,25.3,18c-1.7,0-3.1,1.4-3.1,3.1c0,0.4,0.3,0.7,0.7,0.7
                  C23.3,21.8,23.7,21.5,23.7,21.1z"
										></path>
										<path
											d="M26,15.6c0-0.4-0.3-0.7-0.7-0.7c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-2.6,2.1-4.7,4.7-4.7
                  C25.7,16.3,26,16,26,15.6z"
										></path>
									</svg>
								</div>
							</div>
							<div class="bb rotate arrow">
								<img
									src="https://www.intelivita.co.uk/assets/frameworks/public/images/car-wash/process-arrow.png"
									alt="how to make a car wash app"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
.other-demand-app-box {
	overflow: hidden;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
	border-radius: 10px !important;
}
.other-demand-app-box a {
	padding: 45px 15px 30px;
	display: block;
	background-color: #fdfefe;
}
.other-demand-app-icon {
	width: 71px;
	height: 71px;
	margin: 0 auto 40px;
}
.other-demand-app-box h5 {
	color: #202122;
	font-size: 18px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin: 0;
}
.other-demand-app-box a:hover {
	background-color: #0082dd;
}
a:hover {
	color: #0082dd;
}
.other-demand-app-box a:hover h5 {
	color: #fff;
}
.other-demand-app-box a svg * {
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}
.other-demand-app-box a:hover svg * {
	fill: #fff;
}
.chatst0 {
	display: none;
}
.chatst1 {
	display: inline;
}
.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media (min-width: 992px) {
	.order-lg-0 {
		-ms-flex-order: 0;
		order: 0;
	}
}

.car-wash-process-box {
	position: relative;
	border: 1px solid #dddfe1;
	min-height: 210px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin: 0 0 60px 0;
}

.rounded10 {
	border-radius: 10px !important;
}
.text-center {
	text-align: center !important;
}
.bg-white {
	background-color: #fff !important;
}
.car-wash-process-box-desc {
	padding: 30px 20px 10px;
}
.car-wash-process-icon-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #f0f1f1;
	padding-bottom: 15px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 0 0 10px 10px;
}
.car-wash-process-box .bt.arrow {
	top: 35px;
	right: 0;
	line-height: 1;
	left: 100%;
	width: 31px;
	text-align: center;
}

.car-wash-process-box .arrow {
	position: absolute;
}
.car-wash-process-box .bt.arrow {
	top: 35px;
	right: 0;
	line-height: 1;
	left: 100%;
	width: 31px;
	text-align: center;
}

.car-wash-process-box .arrow {
	position: absolute;
}

.car-wash-process-icon-box svg {
	width: 40px;
	height: 40px;
}
.car-wash-process-box .arrow:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #dddfe1;
	top: 10px;
}
.car-wash-process-box .arrow img {
	position: relative;
}

img {
	max-width: 100%;
	height: auto;
}
@media (min-width: 992px) {
	.order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}
}
@media (min-width: 768px) {
	.order-md-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-md-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-md-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-md-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-md-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-md-6 {
		-ms-flex-order: 6;
		order: 6;
	}
}
@media (min-width: 576px) {
	.order-sm-1 {
		-ms-flex-order: 1;
		order: 1;
	}
	.order-sm-2 {
		-ms-flex-order: 2;
		order: 2;
	}
	.order-sm-3 {
		-ms-flex-order: 3;
		order: 3;
	}
	.order-sm-4 {
		-ms-flex-order: 4;
		order: 4;
	}
	.order-sm-5 {
		-ms-flex-order: 5;
		order: 5;
	}
	.order-sm-6 {
		-ms-flex-order: 6;
		order: 6;
	}
}
.car-wash-process-box .arrow:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	height: 1px;
	background-color: #dddfe1;
	top: 10px;
}
.car-wash-process-box .bb.arrow {
	bottom: 20px;
	right: 0;
	line-height: 1;
	left: 100%;
	width: 31px;
	text-align: center;
}
.car-wash-process-box .arrow {
	position: absolute;
}
.car-wash-process-box .down.arrow {
	top: calc(100% + 20px);
	right: 0;
	line-height: 1;
	left: 0;
	width: 60px;
	text-align: center;
	margin: 0 auto;
	transform: rotate(90deg);
}
.car-wash-process-box .rotate.arrow {
	transform: rotate(180deg);
}
.car-wash-process-box:hover .car-wash-process-icon-box svg * {
	fill: #fff;
}
.car-wash-process-box:hover .car-wash-process-icon-box {
	background-color: #0082dd;
	border: 4px solid #fff;
}
.car-wash-process-box:hover .car-wash-process-icon-bar {
	background-color: #0082dd;
}
.car-wash-process-icon-box {
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 4px solid #ecedee;
	margin: -40px auto 0;
	border-radius: 100%;
	background-color: #fff;
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@media (max-width: 776px) {
	.car-wash-process-box .arrow {
		top: calc(100% + 20px) !important;
		right: 0 !important;
		line-height: 1 !important;
		left: 0 !important;
		width: 60px !important;
		text-align: center !important;
		margin: 0 auto !important;
		transform: rotate(90deg) !important;
	}
}
</style>
