<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Beneficiaries</span>
				<h2 class="fxt-process-dev">Who is it for?</h2>
				<p>
					You can be a real estate owner, a broker or someone who wants to build a real estate
					aggregator app. Whoever you are, GenixBit Labs can help you build the right mobile app.
				</p>
			</div>
			<div class="row real-estate-type-main" style="justify-content: center">
				<div
					class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<BeneficiariesCard v-bind:obj="item"></BeneficiariesCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BeneficiariesCard from '../AssetComponents/BeneficiariesCard.vue';
export default {
	components: {
		BeneficiariesCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path class="st0" d="M21.6,0c-0.2,0-0.5,0.1-0.6,0.3L1,20.9c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2h2.6v23.2
              c0,0.5,0.4,0.9,0.9,0.9h23.4v-1.7h-1.7V30.1c0-0.5-0.4-0.9-0.9-0.9h-8.7c-0.5,0-0.9,0.4-0.9,0.9v14.6H6V22.4h31.2V25h1.7v-2.6h2.6
              c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.4-0.2-0.6l-3.2-3.3c0,0,0,0,0,0V7.7c0-0.5-0.4-0.9-0.9-0.9h-3.5c-0.5,0-0.9,0.4-0.9,0.9v4.4
              L22.2,0.3C22,0.1,21.8,0,21.6,0z M21.5,2.1l17.9,18.6H3.7L21.5,2.1z M35.4,8.6h1.7v7.2L35.4,14c0-0.1,0-0.1,0-0.2V8.6z M41.4,25.8
              L41.4,25.8l-0.8,0l-0.1,0.8c0,0,0,0-0.1,0.1c-0.1,0.1-0.3,0.1-0.4,0.1H38c-1.6,0-3,1.4-3,3v1.9v0v2.6c0,1.1,0.4,2.2,1.1,3v1.5
              l-4,2.3c-1.2,0.7-2,2-2,3.5V50h19.1v-5.4c0-1.4-0.7-2.7-1.9-3.3l-4-2.4v-1.7c0.6-0.8,1-1.8,1-2.9v-2.6v0v-2.8l0-0.1
              c-0.1-0.8-0.3-1.5-0.8-2.1C43,26.2,42.2,25.8,41.4,25.8z M41.7,27.7c0.2,0.1,0.4,0.1,0.5,0.2c0.2,0.2,0.4,0.6,0.4,1.2v2.5l0-0.7v0.7
              c-0.1,0-0.2-0.1-0.3-0.1c-0.6-0.1-1.2-0.1-1.7,0c-0.5,0-1,0-1.3-0.1c-0.3-0.1-0.5-0.2-0.7-0.6c-0.2-0.3-0.5-0.4-0.8-0.4v0
              c-0.4,0-0.7,0.3-0.8,0.6c0.1-0.2,0,0-0.1,0.1c0,0.1-0.1,0.1-0.2,0.2v-1.5c0-0.7,0.5-1.3,1.2-1.3h2.1h0c0.4,0,0.9-0.2,1.3-0.4
              C41.6,28,41.6,27.8,41.7,27.7z M18.1,31H25v13.8h-6.9V31z M38.1,32.4c0.3,0.2,0.5,0.5,0.8,0.6c0.7,0.2,1.3,0.2,1.8,0.1
              c0.5,0,1,0,1.3,0c0.3,0.1,0.4,0.1,0.5,0.3c0,0,0,0,0,0.1v0.7c0,1.7-1.3,3-2.9,3c-1.6,0-2.9-1.3-2.9-3v-0.8c0.1,0,0.1-0.1,0.2-0.1
              c0.2-0.2,0.5-0.3,0.7-0.5C37.8,32.7,37.9,32.6,38.1,32.4z M41.6,38.6v0.9c-0.4,0.7-0.7,1.4-1.4,2l0,0l0,0l-0.4,0.4l-0.4-0.4l0,0
              c-0.6-0.6-1.1-1.3-1.4-2v-0.9c0.6,0.2,1.2,0.4,1.8,0.4C40.4,39,41,38.9,41.6,38.6z M43,40.7l3.5,2.1h0c0.7,0.4,1.1,1.1,1.1,1.9v3.7
              h-1.7v-1.8h-1.7v1.8h-3.5v-2.8h0v-2l0.8-0.7l0-0.1C42.2,42,42.6,41.3,43,40.7z M36.5,40.7c0.4,0.8,1,1.4,1.5,2l0,0l0.1,0.1l0.7,0.7
              v4.8h-3.4v-1.8h-1.7v1.8h-1.8v-3.6c0-0.8,0.4-1.6,1.1-2h0L36.5,40.7z"></path>
            </svg>`,
					title: 'Real Estate Buyers',
					text: 'There is a better way for users to find and buy their dream estate property. It is through a mobile app. GenixBit Labs builds mobile apps that makes it easier for users to buy real estate properties.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <g>
                <g>
                  <path d="M37.4,36.1C37.4,36.1,37.4,36.1,37.4,36.1l-9.9,3.7c-0.2,0.1-0.5,0.1-0.7-0.1l-3.9-2.2h3c2,0,3.7-1.7,3.7-3.7
                  c0-2.1-1.6-3.7-3.7-3.7h-6.1c-0.1,0-0.2,0-0.3,0L8.2,34.1H1.8C1.4,34.1,1,34.5,1,35v14.1c0,0.5,0.4,0.8,0.8,0.8h6.5
                  c0.4,0,0.8-0.4,0.8-0.8v-2.3l16.5,3.1c0.5,0.1,1,0,1.4-0.2L39.8,44c2.1-0.7,3.2-3.1,2.5-5.3C41.6,36.7,39.5,35.5,37.4,36.1z
                  M7.5,48.2H2.6V35.8h4.9V48.2z M39.2,42.4l-12.8,5.7c-0.1,0.1-0.3,0.1-0.5,0.1L9.1,45v-9.5l10.7-3.9h6c1.1,0,2,0.9,2,2.1
                  c0,1.1-0.9,2.1-2,2.1h-6.1c-0.4,0-0.8,0.4-0.8,0.8c0,0.3,0.2,0.6,0.4,0.7l6.7,3.8c0.6,0.3,1.3,0.4,2,0.2l9.8-3.6
                  c1.3-0.3,2.6,0.5,2.9,1.8c0,0.2,0.1,0.4,0.1,0.6C40.9,41.1,40.2,42.1,39.2,42.4z M26.2,13.4h-6.5c-0.4,0-0.8,0.4-0.8,0.8v6.6
                  c0,0.5,0.4,0.8,0.8,0.8h6.5c0.4,0,0.8-0.4,0.8-0.8v-6.6C27,13.8,26.7,13.4,26.2,13.4z M22.2,20h-1.6v-1.7h1.6V20z M22.2,16.7h-1.6
                  V15h1.6V16.7z M25.4,20h-1.6v-1.7h1.6V20z M25.4,16.7h-1.6V15h1.6V16.7z M46.6,23.3h-1.6V10.9c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3
                  c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1l-7.3-10c0,0,0,0,0,0c-0.2-0.2-0.4-0.3-0.6-0.3h-13c-0.3,0-0.5,0.1-0.7,0.3l-1.6,2.2h-4.3
                  c-0.4,0-0.8,0.4-0.8,0.8v6.1l-0.7,0.9c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0v12.4H14
                  c-1.3,0-2.4,1.1-2.4,2.5c0,1.4,1.1,2.5,2.4,2.5h32.5c1.3,0,2.4-1.1,2.4-2.5C49,24.5,47.9,23.3,46.6,23.3z M39.2,15h-4.9
                  c-0.4,0-0.8,0.4-0.8,0.8v7.5h-3.3V11.7h13v11.6h-3.3v-7.5C40.1,15.4,39.7,15,39.2,15z M38.4,16.7v6.6h-3.3v-6.6H38.4z M42.5,10.1
                  H31.1l5.7-7.7L42.5,10.1z M24.2,1.8h11l-6.1,8.3H18.3L24.2,1.8z M18.1,4.3h2.2l-2.2,3V4.3z M17.3,11.7h11.4v11.6H17.3V11.7z
                  M46.6,26.7H14c-0.4,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h32.5c0.4,0,0.8,0.4,0.8,0.8C47.4,26.3,47,26.7,46.6,26.7z M32.7,30
                  h-1.6v1.7h1.6V30z M31.1,35h1.6v-1.7h-1.6V35z M36,30h-1.6v1.7H36V30z M34.4,35H36v-1.7h-1.6V35z M39.2,30h-1.6v1.7h1.6V30z
                  M37.6,35h1.6v-1.7h-1.6V35z M42.5,30h-1.6v1.7h1.6V30z"></path>
                </g>
              </g>
            </svg>`,
					title: 'Real Estate Company',
					text: 'Take your offline real estate company into the digital space with a bespoke mobile app. Showcase your portfolio digitally with images, videos, virtual tours and much more.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path d="M49,20.5c0,0-0.1,0-0.1,0L41,18.6c-1.1-0.3-1.9-1.2-2.1-2.3C37,5.9,28.2-0.9,18,0.2C8.8,1.1,1.5,8.5,0.8,17.8
              c-0.1,1.6-0.1,3.2,0.2,4.7l-0.6,0.8C0,23.8,0,24.5,0.3,25.1c0.3,0.5,0.8,0.8,1.4,0.8c0,0,0.1,0,0.1,0c2.8-0.2,7.7-0.5,9.4-0.8
              c1.1-0.2,2.1-0.8,3-1.5c1.4-0.9,2.8-1.9,5.1-1.9c1.9-0.1,2.9,0.3,4.2,0.7c0.6,0.2,1.2,0.4,2,0.6c-0.2,0-0.4,0-0.7,0
              c-4.7,0-8.4,3.8-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4c4.7,0,8.4-3.8,8.4-8.4c0-3.7-2.5-6.9-5.8-8c0.3,0,0.6,0.1,1,0.1
              c7,0.8,13.1,0.4,16.3,0.1c0.9-0.1,1.5-0.1,1.9-0.1c0.5,0,1.1,0,1.8-0.1c0.7-0.1,1.2-0.6,1.3-1.3C50,21.3,49.6,20.7,49,20.5z
              M31.8,31.4c0,3.7-3.1,6.8-6.8,6.8c-3.8,0-6.8-3-6.8-6.8c0-3.7,3.1-6.8,6.8-6.8C28.8,24.6,31.8,27.6,31.8,31.4z M46.8,21.8
              c-0.4,0-1.1,0-2,0.1c-3.2,0.2-9.2,0.7-16-0.1c-2.2-0.3-3.5-0.7-4.6-1c-1.4-0.4-2.6-0.8-4.7-0.8c-2.8,0.1-4.6,1.3-6,2.2
              c-0.9,0.6-1.6,1.1-2.4,1.2c-1.7,0.3-6.5,0.6-9.3,0.8l0.1,0.8l-0.1-0.9l0.7-1.1c0.1-0.2,0.2-0.4,0.1-0.6c-0.3-1.5-0.3-3.1-0.2-4.6
              C3.1,9.5,9.7,2.7,18.1,1.8c0.6-0.1,1.3-0.1,1.9-0.1c8.5,0,15.6,6,17.3,14.8c0.3,1.8,1.6,3.2,3.4,3.6l6.9,1.7
              C47.3,21.8,47.1,21.8,46.8,21.8z M3.6,17.5c0.6-7.5,6.7-13.7,14.2-14.4C21.1,2.9,24.2,3.5,27,5c0.4,0.2,0.5,0.7,0.3,1.1
              c-0.2,0.4-0.7,0.5-1.1,0.3c-2.5-1.4-5.3-1.9-8.2-1.7C11.3,5.4,5.8,11,5.3,17.7c0,0.4-0.4,0.8-0.8,0.8c0,0,0,0-0.1,0
              C3.9,18.4,3.6,18,3.6,17.5z M32.6,19.5c0.1,0.4-0.2,0.9-0.7,0.9c-3.8,0.5-6.9-0.5-8.7-1c-0.4-0.1-0.8-0.2-1-0.3
              c-2.8-0.7-5.7-0.1-8,1.4c-1.7,1.2-9.5,2.3-9.6,2.3c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.3-0.8-0.7c0-0.4,0.3-0.9,0.7-0.9
              c1.4-0.2,7.8-1.3,8.9-2c2.7-1.8,6-2.4,9.3-1.7c0.3,0.1,0.7,0.2,1.2,0.3c1.7,0.5,4.6,1.5,8,1C32.1,18.7,32.5,19,32.6,19.5z
              M13.4,10.9c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1s-1-2.1-2.1-2.1C14.4,8.8,13.4,9.8,13.4,10.9z M16,10.9
              c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5C15.8,10.5,16,10.7,16,10.9z M19.2,10.9c0,1.2,1,2.1,2.1,2.1
              c1.2,0,2.1-0.9,2.1-2.1s-1-2.1-2.1-2.1C20.2,8.8,19.2,9.8,19.2,10.9z M21.8,10.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5
              c0-0.3,0.2-0.5,0.5-0.5C21.6,10.5,21.8,10.7,21.8,10.9z M25,10.9c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1s-1-2.1-2.1-2.1
              C26,8.8,25,9.8,25,10.9z M27.6,10.9c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
              C27.4,10.5,27.6,10.7,27.6,10.9z M43.4,33.9c0.1,0.2,0.2,0.4,0.2,0.6c-0.5,2.8-1.6,5.4-3.2,7.7c-0.1,0.2-0.3,0.3-0.6,0.3
              c-0.2,0-0.5,0-0.6-0.2c-0.9-0.7-2.2-0.7-3,0.1c-0.8,0.8-0.9,2.1-0.1,3c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.2,0.4-0.3,0.6
              c-2.3,1.7-4.9,2.7-7.7,3.2c0,0-0.1,0-0.1,0c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.3-0.3-0.6c-0.1-1.2-1-2-2.2-2
              c-1.2,0-2.1,0.9-2.2,2c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-2.8-0.5-5.4-1.6-7.7-3.2c-0.2-0.1-0.3-0.3-0.3-0.6
              c0-0.2,0-0.5,0.2-0.6c0.7-0.9,0.7-2.2-0.1-3c-0.8-0.8-2.1-0.9-3-0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.2-0.6-0.3
              c-1.7-2.3-2.7-4.9-3.2-7.7c0-0.2,0-0.5,0.2-0.6c0.1-0.2,0.4-0.3,0.6-0.3c1.2-0.1,2-1,2-2.2c0-1-0.7-1.9-1.7-2.1
              c-0.4-0.1-0.7-0.6-0.6-1c0.1-0.4,0.6-0.7,1-0.6c1.7,0.4,2.9,2,2.9,3.7c0,1.7-1.1,3.1-2.6,3.6c0.4,2,1.2,3.9,2.3,5.6
              c1.5-0.7,3.2-0.5,4.4,0.7c1.2,1.2,1.5,3,0.7,4.4c1.7,1.1,3.6,1.9,5.6,2.3c0.5-1.6,1.9-2.6,3.6-2.6c1.7,0,3.1,1.1,3.6,2.6
              c2-0.4,3.9-1.2,5.6-2.3c-0.7-1.5-0.5-3.2,0.7-4.4c1.2-1.2,3-1.5,4.4-0.7c1.1-1.7,1.9-3.6,2.3-5.6c-1.6-0.5-2.6-1.9-2.6-3.6
              c0-1.7,1.1-3.1,2.6-3.6c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.4,0.1-0.9,0.6-1c0.4-0.1,0.9,0.1,1,0.6c0.2,0.6,0.3,1.1,0.4,1.6
              c0,0.2,0,0.5-0.2,0.6c-0.1,0.2-0.4,0.3-0.6,0.3c-1.2,0.1-2,1-2,2.2s0.9,2.1,2,2.2C43,33.6,43.3,33.7,43.4,33.9z"></path>
            </svg>`,
					title: 'Real Estate Aggregators',
					text: 'Be the connecting force between real estate buyers and sellers. Create a marketplace of real estate with an aggregator app that lists diverse range of properties.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
