<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>What Makes us the Best</span>
				<h2>Why Choose our Web Development Services</h2>
				<p>
					The years of experience in web application development makes our team of dreamers and
					doers more proficient and effective in delivering cutting edge solutions.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../../../components/WebDevelopment/AssetComponents/BigWidthCard';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Quick & Easy Coding',
					text: 'Thanks to the range of features it offers, flutter app development indeed attracts developers by leveraging them with faster coding. The Hot reload feature empowers them to make changes in coding and eventually check them, which makes the entire process faster and easier.',
					svg: `
          <svg
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            style="enable-background: new 0 0 50 50"
            xml:space="preserve"
          >
            <g>
              <path
                class="st0"
                d="M25,0.5c-13.8,0-25,11.2-25,25c0,3.8,0.9,7.5,2.5,11l1.9-0.9c-1.4-2.8-2.2-5.9-2.3-9h3.4v-2.1H2.1
                            c0.1-2.5,0.6-4.8,1.4-7l3.1,1.3l0.8-1.9l-3.1-1.3c1-2,2.2-3.8,3.7-5.5l2.4,2.4l1.5-1.5L9.6,8.6c1.8-1.6,3.8-2.9,6-3.9l1.3,3.1
                            L18.7,7l-1.3-3.1c2.1-0.7,4.2-1.2,6.5-1.3V6H26V2.7c2.5,0.1,4.8,0.6,7,1.4l-1.3,3.1L33.6,8L35,4.9c2,1,3.8,2.2,5.5,3.7L38.1,11
                            l1.5,1.5l2.4-2.4c1.6,1.8,2.9,3.8,3.9,6l-3.1,1.3l0.8,1.9l3.1-1.3c0.7,2.1,1.2,4.2,1.3,6.5h-3.4v2.1h3.4c-0.1,3.1-0.9,6.2-2.3,9
                            l1.9,0.9c1.7-3.4,2.5-7.1,2.5-11C50,11.8,38.8,0.5,25,0.5z"
              ></path>
              <path
                class="st0"
                d="M25,8.6v2.1c4.3,0,8.2,2.3,10.2,6l-8.8,6.5l1.2,1.7l10-7.3l-0.2-0.3l0.3-0.1C35.6,11.9,30.6,8.6,25,8.6z"
              ></path>
              <path
                class="st0"
                d="M10,48.5h30V34.6H10V48.5z M29.7,37.8l1.5-1.5l5,5l-5,5l-1.5-1.5l3.5-3.5L29.7,37.8z M25.4,35.3l2,0.7L23,47.9
                            l-2-0.7L25.4,35.3z M18.8,36.4l1.5,1.5l-3.5,3.5l3.5,3.5l-1.5,1.5l-5-5L18.8,36.4z"
              ></path>
              <ellipse class="st0" cx="25" cy="25.5" rx="1.8" ry="1.9"></ellipse>
            </g>
          </svg>
          `
				},
				{
					title: 'Build your Own Widget',
					text: 'Flutter is designed to let you modify its existing widgets and create a new one. Its widget library comes with a range of options and can be customised effortlessly. With it, you can build responsive mobile applications to attract and engage your target audience.',
					svg: `
            <svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M20.2,19H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h15.2c0.6,0,1-0.4,1-1S20.7,19,20.2,19z"
					></path>
					<path
						class="st0"
						d="M5,26.5h9.4c0.6,0,1-0.4,1-1s-0.4-1-1-1H5c-0.6,0-1,0.4-1,1S4.4,26.5,5,26.5z"
					></path>
					<path
						class="st0"
						d="M16.9,30H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h11.9c0.6,0,1-0.4,1-1S17.4,30,16.9,30z"
					></path>
					<path
						class="st0"
						d="M12.6,35.5H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h7.6c0.6,0,1-0.4,1-1S13.1,35.5,12.6,35.5z"
					></path>
					<path
						class="st0"
						d="M50,1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v48c0,0.6,0.4,1,1,1h48c0.6,0,1-0.4,1-1V1z M48,2v4.8H2V2H48z M2,48
                        V8.8h46V48H2z"
					></path>
					<circle class="st0" cx="5.4" cy="4.3" r="1.1"></circle>
					<circle class="st0" cx="8.7" cy="4.3" r="1.1"></circle>
					<circle class="st0" cx="12.1" cy="4.3" r="1.1"></circle>
				</svg>
          `
				},
				{
					title: 'Single Coding Based',
					text: 'One of the best parts with flutter app development services is that the app developers no longer have to produce multiple codes for distinct platforms. You read that right!! It offers single code for both iOS and Android. The credit to this goes to its personal widgets and designs.',
					svg: `
          <svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								class="st0"
								d="M4.9,22v24.4c0,0.6,0.2,1.2,0.7,1.5l0,0l2.8,1.6l0,0l0,0c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.2
                              l32.5-18.8c0.9-0.6,1.5-1.7,1.5-2.9V3.5c0-0.6-0.2-1.2-0.7-1.5l0,0l0,0l-2.8-1.6l0,0l0,0c-0.5-0.3-1-0.2-1.5,0L25.7,8
                              c-0.1-0.2-0.3-0.4-0.5-0.5l0,0l-2.8-1.6l0,0c-0.5-0.3-1-0.3-1.5,0l-12,7c-0.9,0.6-1.5,1.7-1.5,2.9v2.9l-0.9,0.5
                              C5.5,19.8,4.9,20.9,4.9,22z M21.6,7.1C21.7,7,21.7,7,21.7,7l0,0l0,0l1.5,0.9l-11.5,6.6c-0.9,0.6-1.5,1.7-1.5,2.9v2.1l-1.6-0.9
                              v-2.8c0-0.7,0.3-1.3,0.9-1.7L21.6,7.1z M24.6,8.7c0.1,0.1,0.1,0.3,0.1,0.4V12l-2.5,1.4l-10.6,6.1v-2.1c0-0.7,0.3-1.3,0.9-1.7
                              l12-6.9C24.5,8.7,24.6,8.7,24.6,8.7L24.6,8.7z M39.6,1.5c0,0,0.1,0,0.1-0.1l0,0l1.5,0.9L26,11.2V9.6l0,0V9.4l0.6-0.3l10.7-6.2l0,0
                              L39.6,1.5z M9.3,48.4c0,0-0.1,0-0.2,0.1C9,48.4,9,48.2,9,48.1V23.7C9,23,9.3,22.4,9.9,22l14.7-8.5l0,0L40,4.6l0,0l2.4-1.4
                              c0,0,0.1-0.1,0.2-0.1l0,0c0.1,0.1,0.1,0.3,0.1,0.4v24.4c0,0.7-0.3,1.3-0.9,1.7L9.3,48.4z M6.4,46.8L6.4,46.8L6.4,46.8L6.4,46.8
                              c-0.1-0.1-0.1-0.3-0.1-0.4V22c0-0.7,0.3-1.3,0.9-1.7L8,19.8l1.6,0.9l-0.2,0.1c-0.9,0.6-1.5,1.7-1.5,2.9v24L6.4,46.8z"
							></path>
							<path
								class="st0"
								d="M17.2,31.2c0,2.8,0.9,4.9,2.6,6c0.8,0.5,1.8,0.8,2.7,0.8c1.1,0,2.2-0.3,3.2-0.8c0.3-0.1,0.7-0.3,1-0.5
                              c0.4-0.2,0.7-0.4,1-0.6c4.5-3.2,8-9.5,8-14.8c0-2.8-0.9-4.9-2.6-6c-1.6-1-3.7-1-6,0c-0.3,0.1-0.7,0.3-1,0.5
                              c-0.4,0.2-0.7,0.4-1,0.6C20.6,19.6,17.2,26,17.2,31.2z M18.5,31.2c0-4.9,3.2-10.8,7.4-13.7c0.3-0.2,0.6-0.4,0.9-0.6
                              s0.6-0.3,0.9-0.4c0.8-0.4,1.8-0.6,2.7-0.7c0.7,0,1.4,0.2,2,0.6c1.3,0.8,2,2.6,2,4.9c0,4.9-3.2,10.8-7.4,13.7
                              c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-1.8,0.8-3.5,0.9-4.7,0.1C19.2,35.2,18.5,33.5,18.5,31.2z"
							></path>
							<path
								class="st0"
								d="M29.9,22.7l1.6,0.4l-1.9,2.8c-0.1,0.2-0.2,0.4-0.1,0.7c0.1,0.2,0.3,0.4,0.5,0.4s0.5-0.1,0.6-0.3l2.5-3.5
                              c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.2-0.2-0.4-0.4-0.4l-2.5-0.7c-0.2,0-0.3,0-0.5,0.1s-0.3,0.2-0.3,0.4
                              C29.4,22.2,29.6,22.6,29.9,22.7z"
							></path>
							<path
								class="st0"
								d="M23.4,30.7c0.1-0.3-0.1-0.7-0.5-0.8l-1.6-0.4l1.9-2.8c0.2-0.3,0.1-0.7-0.2-0.9c-0.3-0.2-0.7-0.1-0.9,0.2
                              l-2.5,3.5c-0.1,0.2-0.1,0.4-0.1,0.6c0.1,0.2,0.2,0.4,0.4,0.4l2.5,0.7c0.1,0,0.1,0,0.2,0C23.1,31.2,23.3,31,23.4,30.7L23.4,30.7z"
							></path>
							<path
								class="st0"
								d="M24.3,22.5c-0.3,0.2-0.4,0.6-0.2,0.9l3.7,6.3c0.1,0.2,0.3,0.3,0.6,0.3c0.2,0,0.4-0.1,0.6-0.3
                              c0.1-0.2,0.1-0.5,0-0.7l-3.7-6.3C25,22.5,24.6,22.4,24.3,22.5L24.3,22.5z"
							></path>
						</g>
					</g>
				</svg>`
				},
				{
					title: 'Easy to Adopt',
					text: 'Google has designed Flutter to ensure delivering some ease to developers and make the development process faster and easier. The SDK can be effortlessly grasped by novice developers also.',
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<g>
						<path
							class="st0"
							d="M49.2,3.3H0.8C0.4,3.3,0,3.7,0,4.2v41.7c0,0.5,0.4,0.8,0.8,0.8h48.3c0.5,0,0.8-0.4,0.8-0.8V4.2
                           C50,3.7,49.6,3.3,49.2,3.3z M48.3,11.7H30.8V5h17.5V11.7z M29.2,5v6.7h-8.3V5H29.2z M20.8,13.3h8.3v5h-8.3V13.3z M19.2,5v6.7H1.7V5
                           H19.2z M1.7,45V13.3h17.5v5.8c0,0.5,0.4,0.8,0.8,0.8h10c0.5,0,0.8-0.4,0.8-0.8v-5.8h17.5V45H1.7z"
						></path>
						<path
							class="st0"
							d="M32.1,24.2H12.4l2.3-1.8l-1-1.3l-4.2,3.3c-0.4,0.3-0.4,0.8-0.1,1.2c0,0,0.1,0.1,0.1,0.1l4.2,3.3l1-1.3
                           l-2.3-1.9h19.7c3.9,0,7.1,3.2,7.1,7.1S36,40,32.1,40H14.2v1.7h17.9c4.8,0,8.8-3.9,8.8-8.8S36.9,24.2,32.1,24.2z"
						></path>
					</g>
				</svg>`
				},
				{
					title: 'Faster Testing',
					text: 'This one to an extent is linked to our previous benefit. As you do not have to develop different apps for different platforms, the testing becomes much faster. This also boosts your QA process, making it seamless and flawless.',
					svg: `
            <svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<path
						class="st0"
						d="M16.7,41.2c4.1,0,7.5-3.3,7.5-7.5c0-4.1-3.3-7.5-7.5-7.5s-7.5,3.3-7.5,7.5c0,2,0.8,3.9,2.2,5.3
                        C12.8,40.5,14.7,41.2,16.7,41.2z M12.6,29.7c2.3-2.3,5.9-2.3,8.2,0c2.3,2.3,2.3,5.9,0,8.2c-2.3,2.3-5.9,2.3-8.2,0
                        c-1.1-1.1-1.7-2.6-1.7-4.1C10.9,32.2,11.5,30.8,12.6,29.7z"
					></path>
					<path
						class="st0"
						d="M17.9,32l1.2-1.2c-1.3-1.3-3.4-1.3-4.7,0l1.2,1.2C16.2,31.4,17.2,31.4,17.9,32z"
					></path>
					<path
						class="st0"
						d="M5,49.8c0.3,0.3,0.9,0.3,1.2,0l3.5-3.5c0.3-0.3,0.3-0.9,0-1.2L9,44.4l1.3-1.3c5.1,3.5,12.2,2.2,15.7-2.9
                        s2.2-12.2-2.9-15.7s-12.2-2.2-15.7,2.9c-2.5,3.6-2.6,8.3-0.5,12l-1.5,1.5L5,40.3c-0.3-0.3-0.9-0.3-1.2,0l-3.5,3.5
                        c-0.3,0.3-0.3,0.9,0,1.2L5,49.8z M9.9,27c3.7-3.7,9.8-3.7,13.6,0c3.7,3.7,3.7,9.8,0,13.6c-3.7,3.7-9.8,3.7-13.6,0
                        c-1.8-1.8-2.8-4.2-2.8-6.8C7.1,31.2,8.1,28.8,9.9,27z M7.8,40.8L9,42l-1.2,1.2l-0.6-0.6L6.7,42L7.8,40.8z M4.4,42.1l0.5,0.5l0,0
                        l1.2,1.2l1.8,1.8L5.5,48L2,44.5L4.4,42.1z"
					></path>
					<path
						class="st0"
						d="M49.9,11.1c0-0.3-0.2-0.6-0.5-0.7l-1.8-0.8c-0.1-0.3-0.2-0.6-0.3-0.9l1-1.7c0.1-0.3,0.1-0.6,0-0.8
                        c-0.5-0.8-1-1.6-1.6-2.3c-0.2-0.2-0.5-0.3-0.8-0.3l-1.9,0.4c-0.2-0.2-0.5-0.4-0.7-0.6l-0.2-2c0-0.3-0.2-0.6-0.5-0.7
                        C41.8,0.5,40.9,0.2,40,0c-0.3-0.1-0.6,0-0.8,0.3L38,1.8c-0.3,0-0.6,0-0.9,0l-1.3-1.5C35.6,0.1,35.3,0,35,0c-0.9,0.2-1.8,0.5-2.6,0.9
                        c-0.3,0.1-0.5,0.4-0.5,0.7l-0.2,2c-0.3,0.2-0.5,0.4-0.7,0.6l-1.8-0.4c-0.3-0.1-0.6,0-0.8,0.3c-0.6,0.7-1.2,1.5-1.6,2.3
                        c-0.1,0.3-0.1,0.6,0,0.8l1,1.7c-0.1,0.3-0.2,0.6-0.3,0.9l-1.8,0.8c-0.3,0.1-0.5,0.4-0.5,0.7C25,12,25,13,25.1,13.9
                        c0,0.3,0.2,0.6,0.5,0.7l1.8,0.8c0.1,0.3,0.2,0.6,0.3,0.9l-1,1.7c-0.1,0.3-0.1,0.6,0,0.8c0.5,0.8,1,1.6,1.6,2.3
                        c0.2,0.2,0.5,0.3,0.8,0.3l1.9-0.4c0.2,0.2,0.5,0.4,0.7,0.6l0.2,2c0,0.3,0.2,0.6,0.5,0.7c0.8,0.4,1.7,0.7,2.6,0.9
                        c0.3,0.1,0.6,0,0.8-0.3l1.3-1.5c0.3,0,0.6,0,0.9,0l1.3,1.5c0.2,0.2,0.4,0.3,0.6,0.3c0.1,0,0.1,0,0.2,0c0.9-0.2,1.8-0.5,2.6-0.9
                        c0.3-0.1,0.5-0.4,0.5-0.7l0.2-2c0.3-0.2,0.5-0.4,0.7-0.6l1.8,0.4c0.3,0.1,0.6,0,0.8-0.3c0.6-0.7,1.2-1.5,1.6-2.3
                        c0.1-0.3,0.1-0.6,0-0.8l-1-1.7c0.1-0.3,0.2-0.6,0.3-0.9l1.8-0.8c0.3-0.1,0.5-0.4,0.5-0.7C50,13,50,12,49.9,11.1L49.9,11.1z
                        M48.3,13.3L46.6,14c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.5-0.3,1-0.5,1.5c-0.1,0.2-0.1,0.5,0,0.7l0.9,1.6c-0.3,0.4-0.6,0.8-0.9,1.2
                        L44,19.2c-0.3-0.1-0.5,0-0.7,0.2c-0.4,0.3-0.8,0.6-1.2,0.9c-0.2,0.1-0.4,0.4-0.4,0.6l-0.2,1.9c-0.4,0.2-0.9,0.3-1.4,0.5l-1.2-1.4
                        c-0.2-0.2-0.4-0.3-0.7-0.3c-0.5,0-1,0-1.5,0c-0.3,0-0.5,0.1-0.7,0.3l-1.2,1.4c-0.5-0.1-0.9-0.3-1.4-0.5l-0.2-1.9
                        c0-0.3-0.2-0.5-0.4-0.6c-0.4-0.3-0.8-0.6-1.2-0.9c-0.2-0.2-0.5-0.2-0.7-0.2l-1.8,0.4c-0.3-0.4-0.6-0.8-0.9-1.2l0.9-1.6
                        c0.1-0.2,0.1-0.5,0-0.7c-0.2-0.5-0.3-1-0.5-1.5c-0.1-0.3-0.2-0.5-0.5-0.6l-1.7-0.7c0-0.3,0-0.5,0-0.8s0-0.5,0-0.8l1.7-0.7
                        c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.5,0.3-1,0.5-1.5c0.1-0.2,0.1-0.5,0-0.7l-0.9-1.6c0.3-0.4,0.6-0.8,0.9-1.2L31,5.8
                        c0.3,0.1,0.5,0,0.7-0.2c0.4-0.3,0.8-0.6,1.2-0.9c0.2-0.1,0.4-0.4,0.4-0.6l0.2-1.9c0.4-0.2,0.9-0.3,1.4-0.5L36,3.2
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.5,0,1,0,1.5,0c0.3,0,0.5-0.1,0.7-0.3l1.2-1.4c0.5,0.1,0.9,0.3,1.4,0.5l0.2,1.9c0,0.3,0.2,0.5,0.4,0.6
                        c0.4,0.3,0.8,0.6,1.2,0.9c0.2,0.2,0.5,0.2,0.7,0.2l1.8-0.4c0.3,0.4,0.6,0.8,0.9,1.2l-0.9,1.6c-0.1,0.2-0.1,0.5,0,0.7
                        c0.2,0.5,0.3,1,0.5,1.5c0.1,0.3,0.2,0.5,0.5,0.6l1.7,0.7c0,0.3,0,0.5,0,0.8S48.3,13,48.3,13.3L48.3,13.3z"
					></path>
					<path
						class="st0"
						d="M37.5,5C33.4,5,30,8.4,30,12.5s3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5C45,8.4,41.6,5,37.5,5z M37.5,18.3
                        c-3.2,0-5.8-2.6-5.8-5.8s2.6-5.8,5.8-5.8c3.2,0,5.8,2.6,5.8,5.8C43.3,15.7,40.7,18.3,37.5,18.3z"
					></path>
					<path
						class="st0"
						d="M0.6,12.7L2,13.4c0.1,0.2,0.1,0.5,0.2,0.7l-0.8,1.4c-0.1,0.3-0.1,0.6,0,0.8c0.4,0.7,0.9,1.4,1.4,2
                        c0.2,0.2,0.5,0.3,0.8,0.3l1.5-0.3c0.2,0.1,0.4,0.3,0.6,0.4l0.1,1.6c0,0.3,0.2,0.6,0.5,0.7c0.7,0.3,1.5,0.6,2.3,0.8
                        c0.3,0.1,0.6,0,0.8-0.3l1-1.2c0.2,0,0.5,0,0.7,0l1,1.2c0.2,0.2,0.5,0.3,0.8,0.3c0.8-0.2,1.6-0.4,2.3-0.8c0.3-0.1,0.5-0.4,0.5-0.7
                        l0.1-1.6c0.2-0.1,0.4-0.3,0.6-0.4l1.5,0.3c0.3,0.1,0.6,0,0.8-0.3c0.5-0.6,1-1.3,1.4-2c0.1-0.3,0.1-0.6,0-0.8l-0.8-1.4
                        c0.1-0.2,0.2-0.5,0.2-0.7l1.5-0.6c0.3-0.1,0.5-0.4,0.5-0.7c0.1-0.8,0.1-1.6,0-2.4c0-0.3-0.2-0.6-0.5-0.7l-1.5-0.6
                        c-0.1-0.2-0.1-0.5-0.2-0.7l0.8-1.4c0.1-0.3,0.2-0.6,0-0.8c-0.4-0.7-0.9-1.4-1.4-2c-0.2-0.2-0.5-0.3-0.8-0.3l-1.5,0.3
                        c-0.2-0.1-0.4-0.3-0.6-0.4l-0.1-1.6c0-0.3-0.2-0.6-0.5-0.7C14.6,0.4,13.8,0.2,13,0c-0.3-0.1-0.6,0-0.8,0.3l-1,1.2
                        c-0.2,0-0.5,0-0.7,0l-1-1.2C9.2,0.1,8.9,0,8.6,0C7.9,0.2,7.1,0.4,6.4,0.8C6.1,0.9,5.9,1.2,5.9,1.5L5.7,3.1C5.5,3.2,5.4,3.4,5.2,3.5
                        L3.6,3.1C3.3,3.1,3,3.2,2.8,3.4c-0.5,0.6-1,1.3-1.4,2C1.3,5.6,1.3,6,1.4,6.2l0.8,1.4C2.1,7.8,2.1,8.1,2,8.3L0.6,8.9
                        C0.3,9.1,0.1,9.3,0.1,9.6c-0.1,0.8-0.1,1.6,0,2.4C0.1,12.4,0.3,12.6,0.6,12.7z M1.7,10.3l1.4-0.6c0.2-0.1,0.4-0.3,0.5-0.6
                        c0.1-0.4,0.2-0.8,0.4-1.2C4,7.6,4,7.3,3.9,7.1L3.1,5.8c0.2-0.3,0.4-0.6,0.7-0.9l1.4,0.3c0.3,0.1,0.5,0,0.7-0.2
                        c0.3-0.3,0.7-0.5,1-0.8c0.2-0.1,0.4-0.4,0.4-0.6l0.1-1.5c0.3-0.1,0.7-0.3,1-0.3l1,1.1c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.8,0,1.3,0
                        c0.3,0,0.5-0.1,0.7-0.3l1-1.1c0.3,0.1,0.7,0.2,1,0.3l0.1,1.5c0,0.3,0.2,0.5,0.4,0.6c0.4,0.2,0.7,0.5,1,0.7c0.2,0.2,0.5,0.2,0.7,0.2
                        l1.4-0.3c0.2,0.3,0.5,0.6,0.7,0.9l-0.8,1.3c-0.1,0.2-0.1,0.5,0,0.7c0.2,0.4,0.3,0.8,0.4,1.2c0.1,0.3,0.2,0.5,0.5,0.6l1.4,0.6
                        c0,0.2,0,0.4,0,0.6s0,0.4,0,0.6L18.6,12c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.4-0.2,0.8-0.4,1.2c-0.1,0.2-0.1,0.5,0,0.7l0.8,1.3
                        c-0.2,0.3-0.4,0.6-0.7,0.9l-1.4-0.3c-0.3-0.1-0.5,0-0.7,0.2c-0.3,0.3-0.7,0.5-1,0.8c-0.2,0.1-0.4,0.4-0.4,0.6l-0.1,1.5
                        c-0.3,0.1-0.7,0.3-1,0.3l-1-1.1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.8,0-1.3,0c-0.3,0-0.5,0.1-0.7,0.3l-1,1.1c-0.3-0.1-0.7-0.2-1-0.3
                        L7.4,18c0-0.3-0.2-0.5-0.4-0.6c-0.4-0.2-0.7-0.5-1-0.7c-0.2-0.2-0.5-0.2-0.7-0.2l-1.4,0.3c-0.2-0.3-0.5-0.6-0.7-0.9l0.8-1.3
                        c0.1-0.2,0.1-0.5,0-0.7c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.3-0.2-0.5-0.5-0.6l-1.4-0.6c0-0.2,0-0.4,0-0.6S1.7,10.5,1.7,10.3z"
					></path>
					<path
						class="st0"
						d="M10.8,17.5c3.7,0,6.7-3,6.7-6.7s-3-6.7-6.7-6.7s-6.7,3-6.7,6.7C4.2,14.5,7.2,17.5,10.8,17.5z M10.8,5.8
                        c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5C5.8,8.1,8.1,5.8,10.8,5.8z"
					></path>
					<path
						class="st0"
						d="M36.9,13.8l-2.2-2l-1.1,1.2l2.8,2.5c0.3,0.3,0.9,0.3,1.2,0l4.7-5l-1.2-1.1L36.9,13.8z"
					></path>
					<path
						class="st0"
						d="M10,13.3c0.2,0,0.4-0.1,0.6-0.2l3.3-3.3l-1.2-1.2L10,11.3l-1.1-1.1l-1.2,1.2l1.7,1.7C9.6,13.2,9.8,13.3,10,13.3
                        z"
					></path>
					<path
						class="st0"
						d="M49.4,41c0.3-0.1,0.5-0.4,0.5-0.7c0.1-0.7,0.1-1.5,0-2.3c0-0.3-0.2-0.6-0.5-0.7l-1.3-0.6
                        c-0.1-0.2-0.1-0.4-0.2-0.6l0.7-1.2c0.1-0.3,0.1-0.6,0-0.8c-0.4-0.7-0.8-1.3-1.3-1.8c-0.2-0.2-0.5-0.3-0.8-0.3l-1.4,0.3
                        c-0.1-0.1-0.3-0.2-0.5-0.3l-0.1-1.5c0-0.3-0.2-0.6-0.5-0.7c-0.7-0.3-1.4-0.6-2.1-0.7c-0.3-0.1-0.6,0-0.8,0.3l-0.9,1.1
                        c-0.2,0-0.4,0-0.6,0l-0.9-1.1c-0.2-0.2-0.5-0.3-0.8-0.3c-0.7,0.2-1.4,0.4-2.1,0.7c-0.3,0.1-0.5,0.4-0.5,0.7L35.3,32
                        c-0.2,0.1-0.3,0.2-0.5,0.3l-1.4-0.3c-0.3-0.1-0.6,0-0.8,0.3c-0.5,0.6-0.9,1.2-1.3,1.8c-0.1,0.3-0.1,0.6,0,0.8l0.7,1.2
                        c-0.1,0.2-0.1,0.4-0.2,0.6l-1.3,0.6c-0.3,0.1-0.5,0.4-0.5,0.7c-0.1,0.7-0.1,1.5,0,2.3c0,0.3,0.2,0.6,0.5,0.7l1.3,0.6
                        c0.1,0.2,0.1,0.4,0.2,0.6l-0.7,1.2c-0.1,0.3-0.1,0.6,0,0.8c0.4,0.7,0.8,1.3,1.3,1.8c0.2,0.2,0.5,0.3,0.8,0.3l1.4-0.3
                        c0.1,0.1,0.3,0.2,0.5,0.3l0.1,1.5c0,0.3,0.2,0.6,0.5,0.7c0.7,0.3,1.4,0.6,2.1,0.7c0.3,0.1,0.6,0,0.8-0.3l0.9-1.1c0.2,0,0.4,0,0.6,0
                        l0.9,1.1c0.2,0.2,0.5,0.3,0.8,0.3c0.7-0.2,1.4-0.4,2.1-0.7c0.3-0.1,0.5-0.4,0.5-0.7l0.1-1.5c0.2-0.1,0.3-0.2,0.5-0.3l1.4,0.3
                        c0.3,0.1,0.6,0,0.8-0.3c0.5-0.6,0.9-1.2,1.3-1.8c0.1-0.3,0.1-0.6,0-0.8L48,42.1c0.1-0.2,0.1-0.4,0.2-0.6L49.4,41z M47.1,40.2
                        c-0.2,0.1-0.4,0.3-0.5,0.6c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.2-0.1,0.5,0,0.7l0.7,1.2c-0.2,0.3-0.3,0.5-0.5,0.8l-1.3-0.3
                        c-0.3-0.1-0.5,0-0.7,0.2c-0.3,0.3-0.6,0.5-0.9,0.7c-0.2,0.1-0.4,0.4-0.4,0.6L43,47.1c-0.3,0.1-0.6,0.2-0.8,0.3l-0.9-1
                        c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.8,0-1.1,0c-0.3,0-0.5,0.1-0.7,0.3l-0.9,1c-0.3-0.1-0.6-0.2-0.8-0.3l-0.1-1.4
                        c0-0.3-0.2-0.5-0.4-0.6c-0.3-0.2-0.6-0.4-0.9-0.7c-0.2-0.2-0.5-0.2-0.7-0.2l-1.3,0.3c-0.2-0.2-0.4-0.5-0.5-0.8l0.7-1.2
                        c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.4-0.3-0.7-0.3-1.1c-0.1-0.3-0.2-0.5-0.5-0.6l-1.2-0.5c0-0.2,0-0.3,0-0.5c0-0.2,0-0.3,0-0.5l1.2-0.5
                        c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.4,0.2-0.8,0.3-1.1c0.1-0.2,0.1-0.5,0-0.7L33,34.6c0.2-0.3,0.3-0.5,0.5-0.8l1.3,0.3
                        c0.3,0.1,0.5,0,0.7-0.2c0.3-0.3,0.6-0.5,0.9-0.7c0.2-0.1,0.4-0.4,0.4-0.6l0.1-1.4c0.3-0.1,0.6-0.2,0.8-0.3l0.9,1
                        c0.2,0.2,0.4,0.3,0.7,0.3c0.4,0,0.8,0,1.1,0c0.3,0,0.5-0.1,0.7-0.3l0.9-1c0.3,0.1,0.6,0.2,0.8,0.3l0.1,1.4c0,0.3,0.2,0.5,0.4,0.6
                        c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.2,0.5,0.2,0.7,0.2l1.3-0.3c0.2,0.2,0.4,0.5,0.5,0.8l-0.7,1.2c-0.1,0.2-0.1,0.5,0,0.7
                        c0.1,0.4,0.3,0.7,0.3,1.1c0.1,0.3,0.2,0.5,0.5,0.6l1.2,0.5c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5L47.1,40.2z"
					></path>
					<path
						class="st0"
						d="M40,33.3c-3.2,0-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8s5.8-2.6,5.8-5.8C45.8,35.9,43.2,33.3,40,33.3z M40,43.3
                        c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2c2.3,0,4.2,1.9,4.2,4.2C44.2,41.5,42.3,43.3,40,43.3z"
					></path>
					<polygon
						class="st0"
						points="41.2,36.8 40,38 38.8,36.8 37.6,38 38.8,39.2 37.6,40.3 38.8,41.5 40,40.3 41.2,41.5 42.4,40.3
                        41.2,39.2 42.4,38 "
					></polygon>
				</svg>
          `
				},
				{
					title: 'Material Design and Cupertino',
					text: 'Flutter SDK gives an expressive User Interface, visually alluring cross-platform native like apps with widget library built of material design for android app and cupertino for iOS app.',
					svg: `
            <svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<g>
						<path
							class="st0"
							d="M0,0v40.3h50V0H0z M48.4,38.7H1.6V1.6h46.8V38.7z"
						></path>
						<path
							class="st0"
							d="M31.5,3.2H3.2V21h28.2V3.2z M29.8,19.4h-25V4.8h25V19.4z"
						></path>
						<rect x="33.1" y="3.2" class="st0" width="13.7" height="1.6"></rect>
						<rect x="33.1" y="6.5" class="st0" width="13.7" height="1.6"></rect>
						<rect x="33.1" y="9.7" class="st0" width="13.7" height="1.6"></rect>
						<rect
							x="33.1"
							y="12.9"
							class="st0"
							width="13.7"
							height="1.6"
						></rect>
						<rect
							x="33.1"
							y="16.1"
							class="st0"
							width="13.7"
							height="1.6"
						></rect>
						<rect
							x="33.1"
							y="19.4"
							class="st0"
							width="13.7"
							height="1.6"
						></rect>
						<path
							class="st0"
							d="M16.9,22.6H3.2v14.5h13.7V22.6z M15.3,35.5H4.8V24.2h10.5V35.5z"
						></path>
						<path
							class="st0"
							d="M18.5,37.1h12.9V22.6H18.5V37.1z M20.2,24.2h9.7v11.3h-9.7V24.2z"
						></path>
						<path
							class="st0"
							d="M46.8,22.6H33.1v14.5h13.7V22.6z M45.2,35.5H34.7V24.2h10.5V35.5z"
						></path>
						<path
							class="st0"
							d="M16.9,17.7c3.1,0,5.6-2.5,5.6-5.6S20,6.5,16.9,6.5s-5.6,2.5-5.6,5.6S13.8,17.7,16.9,17.7z M16.9,8.1
                           c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S14.7,8.1,16.9,8.1z"
						></path>
						<path
							class="st0"
							d="M46,41.9H7.9l-7.1,4l7.1,4H46c2.2,0,4-1.8,4-4C50,43.7,48.2,41.9,46,41.9z M9.7,43.5h29v4.8h-29V43.5z
                           M40.3,43.5h1.6v4.8h-1.6V43.5z M4,46l4-2.3v4.6L4,46z M46,48.4h-2.4v-4.8H46c1.3,0,2.4,1.1,2.4,2.4S47.3,48.4,46,48.4z"
						></path>
					</g>
				</svg>
          `
				},
        {
          title:"Faster Experience for Users",
          text:"With the newly launched technology and its invincible list of features, the developers can undoubtedly serve your end users with smoother experience. And as we know, better user experience means more exposure and acceptance.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <g>
                              <path class="st0" d="M44.7,39.8c0.5-0.7,0.9-1.6,0.9-2.6v-5.9c0-0.5-0.4-1-1-1h-4.4c-2,0-3.7,1.6-3.7,3.7v3.2c0,1,0.3,1.9,0.9,2.6
                              c-0.7,0.1-1.3,0.3-1.9,0.6c-1.4-2-3.6-3.4-6.1-3.7c0,0,0,0,0,0c0.9-1,1.5-2.4,1.5-3.8v-8c0-0.5-0.4-1-1-1h-5.9
                              c-2.5,0-4.6,2.1-4.6,4.6V33c0,1.5,0.6,2.8,1.5,3.8c0,0,0,0,0,0c-2.6,0.2-4.8,1.6-6.1,3.7c-0.6-0.3-1.2-0.5-1.9-0.6
                              c0.5-0.7,0.9-1.6,0.9-2.6v-5.9c0-0.5-0.4-1-1-1H8.1c-2,0-3.7,1.6-3.7,3.7v3.2c0,1,0.3,1.9,0.9,2.6C2.3,40.3,0,43,0,46.1V49
                              c0,0.5,0.4,1,1,1h48c0.5,0,1-0.4,1-1v-2.9C50,43,47.7,40.3,44.7,39.8z M6.4,34c0-0.9,0.8-1.7,1.7-1.7h3.4v1.2
                              c0,0.6-0.5,1.1-1.1,1.1h-4V34z M6.4,36.5h4c0.4,0,0.7-0.1,1.1-0.2v0.9c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V36.5z M9.6,41.7
                              L9,42.3l-0.6-0.6H9.6z M13.1,45.1v3H2v-1.9c0-2.2,1.6-4,3.7-4.4l2.6,2.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.6-2.6
                              c0.5,0.1,0.9,0.2,1.3,0.4C13.3,43.1,13.1,44.1,13.1,45.1z M25,36.8c-2.1,0-3.8-1.7-3.8-3.8v-1.3H27c0.6,0,1.3-0.2,1.8-0.5V33
                              C28.8,35.1,27.1,36.8,25,36.8z M26.7,38.7L25,40.4l-1.7-1.7H26.7z M21.2,28.6L21.2,28.6c0-1.5,1.2-2.7,2.7-2.7h5v2
                              c0,1-0.8,1.8-1.8,1.8h-5.8V28.6L21.2,28.6z M34.9,48H33H17h-1.9v-2h6.4c0.5,0,1-0.4,1-1s-0.4-1-1-1h-6.3c0.4-2.8,2.6-5,5.4-5.3
                              l3.7,3.7c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l3.7-3.7c3.1,0.4,5.5,3.1,5.5,6.3L34.9,48L34.9,48z M38.5,34
                              c0-0.9,0.8-1.7,1.7-1.7h3.4v1.2c0,0.6-0.5,1.1-1.1,1.1h-4V34z M38.5,36.5h4c0.4,0,0.7-0.1,1.1-0.2v0.9c0,1.4-1.1,2.5-2.5,2.5
                              s-2.5-1.1-2.5-2.5L38.5,36.5L38.5,36.5z M41.6,41.7L41,42.3l-0.6-0.6H41.6z M36.9,48v-3c0-1-0.2-2-0.5-2.9
                              c0.4-0.2,0.9-0.4,1.3-0.4l2.6,2.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l2.6-2.6c2.1,0.3,3.7,2.2,3.7,4.4V48H36.9z"></path>
                           </g>
                        </g>
                        <g>
                           <g>
                              <path class="st0" d="M29.8,6.4c-0.4-0.4-1-0.4-1.4,0l-4.8,4.8l-2.1-2.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.8,2.8
                              c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5.5-5.5C30.2,7.4,30.2,6.8,29.8,6.4z"></path>
                           </g>
                        </g>
                        <g>
                           <g>
                              <path class="st0" d="M29,0.8c-0.5-0.2-1.1,0-1.3,0.5s0,1.1,0.5,1.3c2.8,1.3,4.7,4.1,4.7,7.2c0,4.3-3.5,7.9-7.9,7.9
                              s-7.9-3.5-7.9-7.9c0-3.1,1.8-5.9,4.6-7.2c0.5-0.2,0.7-0.8,0.5-1.3C22,0.9,21.5,0.6,21,0.9c-3.5,1.6-5.8,5.1-5.8,8.9
                              c0,5.4,4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8C34.8,5.9,32.5,2.4,29,0.8z"></path>
                           </g>
                        </g>
                        <g>
                           <g>
                              <path class="st0" d="M25,0L25,0c-0.6,0-1,0.4-1,1s0.4,1,1,1h0c0,0,0,0,0,0c0.5,0,1-0.4,1-1C26,0.4,25.6,0,25,0z"></path>
                           </g>
                        </g>
                        <g>
                           <g>
                              <path class="st0" d="M25.7,44.4c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
                              c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7S25.9,44.6,25.7,44.4z"></path>
                           </g>
                        </g>
                     </svg>
          `
        },
        {
					title: 'Dart Benefit',
					text: 'Flutter app development framework is based on Dart, a language that compiles “ahead of time”, offers native performance for multiple platforms, smooth animations, and transitions which loads at the speed of 60 FPS. These traits make dart distinctively potential for implementing Flutter.',
					svg: `
            <svg
					x="0px"
					y="0px"
					viewBox="0 0 50 50"
					style="enable-background: new 0 0 50 50"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								class="st0"
								d="M44.7,39.8c0.5-0.7,0.9-1.6,0.9-2.6v-5.9c0-0.5-0.4-1-1-1h-4.4c-2,0-3.7,1.6-3.7,3.7v3.2c0,1,0.3,1.9,0.9,2.6
                              c-0.7,0.1-1.3,0.3-1.9,0.6c-1.4-2-3.6-3.4-6.1-3.7c0,0,0,0,0,0c0.9-1,1.5-2.4,1.5-3.8v-8c0-0.5-0.4-1-1-1h-5.9
                              c-2.5,0-4.6,2.1-4.6,4.6V33c0,1.5,0.6,2.8,1.5,3.8c0,0,0,0,0,0c-2.6,0.2-4.8,1.6-6.1,3.7c-0.6-0.3-1.2-0.5-1.9-0.6
                              c0.5-0.7,0.9-1.6,0.9-2.6v-5.9c0-0.5-0.4-1-1-1H8.1c-2,0-3.7,1.6-3.7,3.7v3.2c0,1,0.3,1.9,0.9,2.6C2.3,40.3,0,43,0,46.1V49
                              c0,0.5,0.4,1,1,1h48c0.5,0,1-0.4,1-1v-2.9C50,43,47.7,40.3,44.7,39.8z M6.4,34c0-0.9,0.8-1.7,1.7-1.7h3.4v1.2
                              c0,0.6-0.5,1.1-1.1,1.1h-4V34z M6.4,36.5h4c0.4,0,0.7-0.1,1.1-0.2v0.9c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5V36.5z M9.6,41.7
                              L9,42.3l-0.6-0.6H9.6z M13.1,45.1v3H2v-1.9c0-2.2,1.6-4,3.7-4.4l2.6,2.6c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.6-2.6
                              c0.5,0.1,0.9,0.2,1.3,0.4C13.3,43.1,13.1,44.1,13.1,45.1z M25,36.8c-2.1,0-3.8-1.7-3.8-3.8v-1.3H27c0.6,0,1.3-0.2,1.8-0.5V33
                              C28.8,35.1,27.1,36.8,25,36.8z M26.7,38.7L25,40.4l-1.7-1.7H26.7z M21.2,28.6L21.2,28.6c0-1.5,1.2-2.7,2.7-2.7h5v2
                              c0,1-0.8,1.8-1.8,1.8h-5.8V28.6L21.2,28.6z M34.9,48H33H17h-1.9v-2h6.4c0.5,0,1-0.4,1-1s-0.4-1-1-1h-6.3c0.4-2.8,2.6-5,5.4-5.3
                              l3.7,3.7c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l3.7-3.7c3.1,0.4,5.5,3.1,5.5,6.3L34.9,48L34.9,48z M38.5,34
                              c0-0.9,0.8-1.7,1.7-1.7h3.4v1.2c0,0.6-0.5,1.1-1.1,1.1h-4V34z M38.5,36.5h4c0.4,0,0.7-0.1,1.1-0.2v0.9c0,1.4-1.1,2.5-2.5,2.5
                              s-2.5-1.1-2.5-2.5L38.5,36.5L38.5,36.5z M41.6,41.7L41,42.3l-0.6-0.6H41.6z M36.9,48v-3c0-1-0.2-2-0.5-2.9
                              c0.4-0.2,0.9-0.4,1.3-0.4l2.6,2.6c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l2.6-2.6c2.1,0.3,3.7,2.2,3.7,4.4V48H36.9z"
							></path>
						</g>
					</g>
					<g>
						<g>
							<path
								class="st0"
								d="M29.8,6.4c-0.4-0.4-1-0.4-1.4,0l-4.8,4.8l-2.1-2.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.8,2.8
                              c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5.5-5.5C30.2,7.4,30.2,6.8,29.8,6.4z"
							></path>
						</g>
					</g>
					<g>
						<g>
							<path
								class="st0"
								d="M29,0.8c-0.5-0.2-1.1,0-1.3,0.5s0,1.1,0.5,1.3c2.8,1.3,4.7,4.1,4.7,7.2c0,4.3-3.5,7.9-7.9,7.9
                              s-7.9-3.5-7.9-7.9c0-3.1,1.8-5.9,4.6-7.2c0.5-0.2,0.7-0.8,0.5-1.3C22,0.9,21.5,0.6,21,0.9c-3.5,1.6-5.8,5.1-5.8,8.9
                              c0,5.4,4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8C34.8,5.9,32.5,2.4,29,0.8z"
							></path>
						</g>
					</g>
					<g>
						<g>
							<path
								class="st0"
								d="M25,0L25,0c-0.6,0-1,0.4-1,1s0.4,1,1,1h0c0,0,0,0,0,0c0.5,0,1-0.4,1-1C26,0.4,25.6,0,25,0z"
							></path>
						</g>
					</g>
					<g>
						<g>
							<path
								class="st0"
								d="M25.7,44.4c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
                              c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7S25.9,44.6,25.7,44.4z"
							></path>
						</g>
					</g>
				</svg>
          `
				},
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
