<template>
	<div class="col-4 col-md-4 col-sm-6 col-12 mb-30">
		<div class="chatbot-adv-box bg-white rounded10 text-center">
			<div class="chatbot-adv-icon">
				<div v-html="obj.svg"></div>
			</div>
			<h3>{{ obj.title }}</h3>
			<p>
				{{ obj.text }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style scoped>
.chatbot-adv-box {
	border: 1px solid #ecedee;
	padding: 0 15px 20px;
	min-height: 235px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.rounded10 {
	border-radius: 10px !important;
}
.text-center {
	text-align: center !important;
}
.bg-white {
	background-color: #fff !important;
}
.chatbot-adv-icon {
	width: 130px;
	height: 75px;
	margin: 0 auto 15px;
	position: relative;
	overflow: hidden;
}
.chatbot-adv-icon:before {
	content: '';
	display: block;
	width: 130px;
	height: 150px;
	border: 1px solid #ecedee;
	border-radius: 100%;
	position: absolute;
	top: -75px;
	left: 0;
	right: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.chatbot-adv-icon div {
	margin: auto;
	height: 60px !important;
	width: 60px !important;
	position: relative;
}
.chatbot-adv-box h3 {
	font-weight: 500;
	font-size: 18px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.chatbot-adv-box p:last-child {
	margin-bottom: 0;
}
p {
	font: normal 14px/22px Montserrat, sans-serif;
	color: #6a6d6f;
}
.chatbot-adv-box:hover {
	border: 1px solid #0082dd;
	box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
}
.chatbot-adv-box:hover .chatbot-adv-icon * {
	fill: #fff;
}
.chatbot-adv-box:hover .chatbot-adv-icon:before {
	background-color: #0082dd;
	box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
}
.chatbot-adv-box:hover h3 {
	color: #0082dd;
}
</style>