<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Features</span>
				<h2 class="fxt-process-dev">Highlight features for builders, brokers and buyers</h2>
				<p>
					Pick from the many standard features that we offer in real estate app development. Turn
					your real estate business into a paperless one with our experience with our
					workflows-based mobile app development
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M78.3,35C78.3,19.4,65.6,6.7,50,6.7C34.4,6.7,21.7,19.4,21.7,35c0,15.6,12.7,28.3,28.3,28.3C65.6,63.3,78.3,50.6,78.3,35z
                    M25,35c0-13.8,11.2-25,25-25c13.8,0,25,11.2,25,25c0,13.8-11.2,25-25,25C36.2,60,25,48.8,25,35z M66.7,50V31.7
                    c0-0.5-0.2-0.9-0.6-1.2L63.3,28v-9.6c0-0.9-0.7-1.7-1.7-1.7H55c-0.9,0-1.7,0.7-1.7,1.7v0.7l-2.2-2c-0.6-0.6-1.6-0.6-2.2,0
                    l-15,13.3c-0.4,0.3-0.6,0.8-0.6,1.2V50c0,0.9,0.7,1.7,1.7,1.7h30C65.9,51.7,66.7,50.9,66.7,50z M51.7,48.3h-3.3V40h3.3V48.3z
                    M63.3,48.3H55v-10c0-0.9-0.7-1.7-1.7-1.7h-6.7c-0.9,0-1.7,0.7-1.7,1.7v10h-8.3V32.4L50,20.6l3.9,3.5c0.5,0.4,1.2,0.5,1.8,0.3
                    c0.6-0.3,1-0.9,1-1.5V20H60v8.7c0,0.5,0.2,0.9,0.6,1.2l2.8,2.5V48.3z M99.7,97.4L81.4,70.7C81.1,70.3,80.5,70,80,70H69.4
                    c8-9.5,15.6-21.6,15.6-33.2C85,14.5,71.3,0,50,0C28.7,0,15,14.5,15,36.8c0,11.6,7.6,23.7,15.6,33.2H20c-0.5,0-1.1,0.3-1.4,0.7
                    L0.3,97.4c-0.3,0.5-0.4,1.2-0.1,1.7c0.3,0.5,0.9,0.9,1.5,0.9h96.6c0.6,0,1.2-0.3,1.5-0.9C100.1,98.6,100,97.9,99.7,97.4z
                    M18.3,36.8C18.3,12.1,34.7,3.3,50,3.3c15.3,0,31.7,8.8,31.7,33.5c0,19.4-24.3,41.4-31,47.1c-0.1,0.1-0.3,0.2-0.4,0.4
                    c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.5-0.5c-0.1,0-0.1-0.1-0.2-0.1
                    c-0.4-0.4-0.9-0.8-1.5-1.3c0,0-0.1-0.1-0.2-0.1C38.2,74,18.3,54.4,18.3,36.8z M33.5,73.3c7.2,7.9,13.9,13.4,15.2,14.5
                    c0.1,0.1,0.2,0.1,0.2,0.2c0.6,0.5,1.5,0.5,2.1,0c0,0,0.1-0.1,0.2-0.2c1.3-1,8.1-6.6,15.2-14.5h12.7l6.2,9l-43,10.7L28.2,73.3H33.5
                    z M20.9,73.3h3.3l5.1,7.2l-15.8,3.7L20.9,73.3z M28.1,96.7H4.8l5.8-8.4l20.7-4.8L38.9,94L28.1,96.7z M41.9,96.7l32.4-8.1l5.8,8.1
                    H41.9z M84.2,96.7l-6.4-9l9.6-2.4l7.8,11.4H84.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Nearby Facilities'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M99.6,51.2l-28.9-46c0-0.4-0.1-0.8-0.3-1.1c-0.5-1-1.6-1.5-2.6-1.4c-0.5,0-1,0-1.5,0.3L65,3.9l-20.5,10
                    c-0.1,0-18,9.7-18.1,9.7l-23,12l-1.8,0.8c-0.9,0.4-1.5,1.3-1.6,2.3c-0.1,1,0.5,1.9,1.3,2.4l5.8,3.3v24.2c0,0.9,0.5,1.8,1.3,2.3
                    l17.2,9.9c0.8,0.5,1.8,0.5,2.6,0c0.8-0.5,1.3-1.3,1.3-2.3v-14l5.5,3.2v17.1c0,0.9,0.5,1.8,1.3,2.3l17.2,9.9
                    c0.4,0.2,0.9,0.4,1.3,0.4c0.5,0,0.9-0.1,1.3-0.4l35.3-20.3c0.8-0.5,1.3-1.3,1.3-2.3v-16l5.8-3.3c0.6-0.4,1.1-0.9,1.2-1.6
                    C100.1,52.6,100,51.9,99.6,51.2z M53.6,66.6c-0.8,0.5-0.8,3.3-0.8,4.2v20.8l-12.5-6.9l0.1-18.6c0-0.9-0.5-1.8-1.3-2.3l-8.5-4.2
                    c-0.8-0.5-1.8-1.2-4.2,0c-1.9,0.8-1.4,4.6-1.4,5.6v11.1L11.1,68V44.4c0-0.9-0.6-3.7-1.4-4.2l-4.2-1.4l25-12.5l25,40.3
                    C55.2,66.7,54,66.4,53.6,66.6z M88.9,73.6L57,91.7V72.2l3.8,3c0,0,0,0,0,0c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0,0.1,0,0.2,0
                    c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0l2.4-1.4v5
                    c0,0.6,0.3,1.1,0.8,1.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.5-0.1,0.8-0.2L78.9,74c0.5-0.3,0.8-0.8,0.8-1.4v-6.8l9.2-6.1V73.6z
                    M68.9,76.1v-4.1l7.6-4.4v4.1L68.9,76.1z M62.5,70.8L33.3,23.6L68.1,6.9l27.8,44.5L62.5,70.8z M64.2,23.2l-8.5,4.9
                    c-0.5,0.3-0.8,0.8-0.8,1.4V35c0,0.3,0.1,0.6,0.2,0.8L63.4,49c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0
                    c0.2,0.1,0.5,0.2,0.8,0.2c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0l8.5-4.9c0.5-0.3,0.8-0.8,0.8-1.4v-14
                    c0-0.6-0.3-1.1-0.8-1.4l-8.3-4.8C65.2,22.9,64.6,22.9,64.2,23.2z M63.2,42.7L58,34.5v-2.4l1.7,1l3.5,2V42.7z M71.7,42.4l-5.3,3.1
                    V35.1l5.3-3.1V42.4z M64.8,32.4l-5.2-3l5.3-3.1l5.2,3L64.8,32.4z"></path>
                  </g>
                </g>
              </svg>`,

					title: '3D Property View'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M71,90.3H51.6v3.2H71V90.3z M38.7,77.4h3.2V29h-3.2V77.4z M96.8,32.3h-6.5V8.1c0-4.4-3.6-8.1-8.1-8.1H40.3
                    c-4.4,0-8.1,3.6-8.1,8.1v8.1H6.5v17.7H0v48.4h32.3v9.7c0,4.4,3.6,8.1,8.1,8.1h41.9c4.4,0,8.1-3.6,8.1-8.1V67.7h6.5
                    c1.8,0,3.2-1.4,3.2-3.2v-29C100,33.7,98.6,32.3,96.8,32.3z M32.3,56.7c-0.5-0.2-1-0.3-1.6-0.3h-3.2c-0.9,0-1.6-0.7-1.6-1.6
                    c0-0.9,0.7-1.6,1.6-1.6h3.2c0.9,0,1.6,0.7,1.6,1.6V56.7z M32.3,50.3c-0.5-0.2-1-0.3-1.6-0.3v-3.2h-3.2V50c-2.7,0-4.8,2.2-4.8,4.8
                    c0,2.7,2.2,4.8,4.8,4.8h3.2c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6h-3.2c-0.9,0-1.6-0.7-1.6-1.6h-3.2c0,2.7,2.2,4.8,4.8,4.8
                    v3.2h3.2v-3.2c0.6,0,1.1-0.1,1.6-0.3v6.3c-1.1,0.2-2.1,0.4-3.2,0.4c-8,0-14.5-6.5-14.5-14.5S21,43.5,29,43.5
                    c1.1,0,2.2,0.2,3.2,0.4V50.3z M32.3,40.6c-1.1-0.2-2.1-0.3-3.2-0.3c-9.8,0-17.7,8-17.7,17.7c0,9.8,8,17.7,17.7,17.7
                    c1.1,0,2.2-0.1,3.2-0.3V79h-29V37.1h29V40.6z M32.3,33.9H9.7V19.4h22.6V33.9z M96.8,35.5v3.2H51.6v-3.2H96.8z M96.8,41.9v3.2H51.6
                    v-3.2H96.8z M35.5,8.1c0-2.7,2.2-4.8,4.8-4.8h41.9c2.7,0,4.8,2.2,4.8,4.8v4.8H35.5V8.1z M87.1,91.9c0,2.7-2.2,4.8-4.8,4.8H40.3
                    c-2.7,0-4.8-2.2-4.8-4.8v-4.8h51.6V91.9z M87.1,83.9H35.5V16.1h51.6v16.1H51.6c-1.8,0-3.2,1.4-3.2,3.2v29c0,1.8,1.4,3.2,3.2,3.2
                    h35.5V83.9z M51.6,64.5V48.4h45.2l0,16.1H51.6z M54.8,61.3h8.1v-3.2h-8.1V61.3z M9.7,43.5h3.2v-3.2H6.5v6.5h3.2V43.5z M16.1,25.8
                    h3.2v-3.2h-6.5V29h3.2V25.8z M12.9,72.6H9.7v-3.2H6.5v6.5h6.5V72.6z M90.3,61.3h3.2v-3.2h-3.2V61.3z M83.9,61.3h3.2v-3.2h-3.2
                    V61.3z M38.7,25.8h3.2v-3.2h-3.2V25.8z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Payments'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M88.4,8.6l4.5-6.4c0.3-0.5,0.3-1.1,0.2-1.5C92.8,0.3,92.3,0,91.7,0H7.1C6.5,0,6,0.3,5.7,0.8C5.4,1.2,5.6,1.8,5.9,2.3
                    l29.6,41.6l5.4,44.5c0,0.5,0.3,0.9,0.8,1.1l17.1,10.2c0.2,0.5,0.5,0.5,0.8,0.5c0.3,0,0.5,0,0.8-0.2c0.5-0.3,0.8-0.8,0.8-1.2
                    L63.5,44l4.8-6.8c2.6,1.5,5.6,2.4,8.8,2.4c9.7,0,17.4-7.9,17.4-17.5C94.6,16.5,92.2,11.8,88.4,8.6z M58,95.9l-14.2-8.5l-5.1-42.5
                    h21.6L58,95.9z M61.2,41.9H37.8l-27.7-39h78.7L86,6.8c-2.6-1.5-5.6-2.4-8.8-2.4c-9.7,0-17.4,7.9-17.4,17.5
                    c0,5.3,2.4,10.2,6.2,13.4L61.2,41.9z M77.2,36.4c-8,0-14.4-6.5-14.4-14.4c0-8,6.5-14.4,14.4-14.4c7.9,0,14.4,6.5,14.4,14.4
                    S85.1,36.4,77.2,36.4z M79.5,22c0.5-0.9,0.9-2,0.9-3c0-3.3-2.7-6.1-6-6.1c-3.3,0-6,2.7-6,6.1c0,3.3,2.7,6.1,6,6.1
                    c1.1,0,2.1-0.3,3-0.9l5.1,5.2c0.3,0.3,0.8,0.5,1.1,0.5c0.3,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.5,0-2.1L79.5,22z M74.3,22
                    c-1.7,0-3-1.4-3-3c0-1.7,1.4-3,3-3c1.7,0,3,1.4,3,3C77.4,20.6,76,22,74.3,22z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Search filters'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M3.4,13.7h3.3v3.2c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.1v-3.2h3.3c1.2,0,2.2-1,2.2-2.1c0-1.2-1-2.1-2.2-2.1H11V6.2
                    C11,5,10.1,4,8.9,4C7.7,4,6.7,5,6.7,6.2v3.2H3.4c-1.2,0-2.2,1-2.2,2.1C1.3,12.7,2.2,13.7,3.4,13.7z M97.8,54h-3.3v-3.2
                    c0-1.2-1-2.1-2.2-2.1c-1.2,0-2.2,1-2.2,2.1V54H87c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h3.3v3.2c0,1.2,1,2.1,2.2,2.1
                    c1.2,0,2.2-1,2.2-2.1v-3.2h3.3c1.2,0,2.2-1,2.2-2.1C100,54.9,99,54,97.8,54z M91.3,22.8c3.3,0,6-2.6,6-5.9c0-3.2-2.7-5.9-6-5.9
                    c-3.3,0-6,2.6-6,5.9C85.4,20.2,88,22.8,91.3,22.8z M91.3,15.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
                    c-0.9,0-1.6-0.7-1.6-1.6C89.7,16.1,90.4,15.3,91.3,15.3z M11.9,46.1c0-3.2-2.7-5.9-6-5.9c-3.3,0-6,2.6-6,5.9C0,49.4,2.7,52,6,52
                    C9.2,52,11.9,49.4,11.9,46.1z M6,47.7c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
                    C7.6,47,6.9,47.7,6,47.7z M12.3,26c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7S12.3,24.5,12.3,26z M80,6.8
                    c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C77.3,5.6,78.5,6.8,80,6.8z M87,44.3c1.5,0,2.7-1.2,2.7-2.7
                    c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7C84.3,43.1,85.5,44.3,87,44.3z M81.7,68c-2.3-2.9-3.5-6.5-3.5-10.2V42.6
                    c0-15.5-12.1-28.5-27.3-29.6V8.1c0-1.2-1-2.1-2.2-2.1c-1.2,0-2.2,1-2.2,2.1v4.8c-6.9,0.5-13.3,3.3-18.3,8.1c-5.8,5.5-9,13-9,20.9
                    v15.9c0,3.7-1.3,7.3-3.5,10.2c-2.1,2.7-3.9,5.7-5.3,8.8c-1.1,2.4-0.8,5.2,0.7,7.4c1.5,2.3,4,3.6,6.8,3.6H36
                    c1,6,6.4,10.7,12.8,10.7c6.4,0,11.7-4.6,12.8-10.7h18.1c2.7,0,5.3-1.3,6.8-3.6c1.5-2.2,1.7-5,0.7-7.4C85.7,73.7,83.9,70.8,81.7,68
                    z M48.8,94.3c-4,0-7.4-2.7-8.3-6.4h16.7C56.1,91.6,52.8,94.3,48.8,94.3z M82.8,82c-0.7,1.1-1.9,1.7-3.2,1.7H17.9
                    c-1.3,0-2.5-0.6-3.2-1.7c-0.7-1-0.8-2.3-0.3-3.4c1.2-2.8,2.8-5.5,4.8-7.9c2.9-3.6,4.5-8.2,4.5-12.8V41.9c0-6.8,2.7-13.1,7.7-17.9
                    c4.7-4.5,10.9-6.9,17.4-6.9c0.3,0,0.6,0,0.9,0C63,17.6,73.9,29,73.9,42.6v15.3c0,3.2,0.8,6.4,2.2,9.3H56.6c-1.2,0-2.2,1-2.2,2.1
                    c0,1.2,1,2.1,2.2,2.1h22.2c0.9,1.2,1.8,2.5,2.5,3.9H68c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h15.3
                    C83.5,80.4,83.3,81.2,82.8,82z M57.8,28c0.4,0.2,0.8,0.4,1.2,0.4c0.7,0,1.4-0.3,1.8-0.9c0.7-1,0.4-2.3-0.6-3
                    c-3.2-2.1-6.9-3.3-10.7-3.5c-2-0.1-4,0.1-5.9,0.6c-1.2,0.3-1.9,1.4-1.6,2.6c0.3,1.1,1.5,1.8,2.6,1.6c1.5-0.4,3.1-0.5,4.7-0.5
                    C52.4,25.4,55.3,26.3,57.8,28z M36.9,24.6c-0.8,0.6-1.6,1.2-2.4,1.9c-0.9,0.8-1,2.2-0.1,3c0.4,0.5,1,0.7,1.6,0.7
                    c0.5,0,1-0.2,1.4-0.6c0.6-0.5,1.3-1,1.9-1.5c1-0.7,1.3-2,0.6-3C39.3,24.2,37.9,23.9,36.9,24.6z M33.1,31.1c-1-0.6-2.4-0.2-2.9,0.8
                    c-1.7,3.1-2.6,6.5-2.6,10v10.6c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.1V41.9c0-2.8,0.7-5.5,2.1-8C34.5,32.9,34.2,31.6,33.1,31.1z
                    M60.4,75.2h-9.7c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1h9.7c1.2,0,2.2-1,2.2-2.1C62.6,76.2,61.6,75.2,60.4,75.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Push Notifications'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M76.6,0H23.4c-0.7,0-1.2,0.5-1.2,1.2v97.7c0,0.6,0.5,1.2,1.2,1.2h53.2c0.6,0,1.2-0.5,1.2-1.2V1.2C77.8,0.5,77.3,0,76.6,0z
                    M24.6,17.3h50.9V82h-5.7v-6c0-0.6-0.5-1.2-1.2-1.2H53.4c-0.6,0-1.2,0.5-1.2,1.2v6h-4.5v-6c0-0.6-0.5-1.2-1.2-1.2H31.5
                    c-0.6,0-1.2,0.5-1.2,1.2v6h-5.8V17.3z M67.4,77.1V82H54.6v-4.9H67.4z M45.5,77.1V82H32.7v-4.9H45.5z M75.4,97.7H24.6V84.3h6.6
                    c0.1,0,0.2,0.1,0.4,0.1h15.1c0.1,0,0.3,0,0.4-0.1h6c0.1,0,0.2,0.1,0.4,0.1h15.1c0.1,0,0.3,0,0.4-0.1h6.5V97.7z M75.4,15H24.6V2.3
                    h50.9V15z M46.3,9.8h7.4c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2h-7.4c-0.6,0-1.2,0.5-1.2,1.2C45.1,9.3,45.7,9.8,46.3,9.8z
                    M50,86.5c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4C54.4,88.5,52.4,86.5,50,86.5z M50,92.9c-1.1,0-2-0.9-2-2
                    c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C52,92,51.1,92.9,50,92.9z M46.6,54.3H31.5c-0.6,0-1.2,0.5-1.2,1.2v15c0,0.6,0.5,1.2,1.2,1.2
                    h15.1c0.6,0,1.2-0.5,1.2-1.2v-15C47.8,54.8,47.3,54.3,46.6,54.3z M45.5,69.3H32.7V56.6h12.8V69.3z M68.5,54.3H53.4
                    c-0.6,0-1.2,0.5-1.2,1.2v15c0,0.6,0.5,1.2,1.2,1.2h15.1c0.6,0,1.2-0.5,1.2-1.2v-15C69.7,54.8,69.2,54.3,68.5,54.3z M67.4,69.3
                    H54.6V56.6h12.8V69.3z M31.5,51.1h37c0.6,0,1.2-0.5,1.2-1.2V24.5c0-0.6-0.5-1.2-1.2-1.2h-37c-0.6,0-1.2,0.5-1.2,1.2v25.4
                    C30.4,50.6,30.9,51.1,31.5,51.1z M67.4,48.8H33.6l6.7-6.6l3.9,3.9c0.4,0.4,1.2,0.4,1.7,0l12.1-12l9.4,9.4V48.8z M32.7,25.6h34.7
                    v14.4l-8.6-8.6c-0.4-0.4-1.2-0.4-1.7,0L45,43.5l-3.9-3.9c-0.4-0.4-1.2-0.4-1.7,0l-6.7,6.7V25.6z M47,31.8c0-2.6-2.1-4.6-4.7-4.6
                    c-2.6,0-4.7,2.1-4.7,4.6c0,2.6,2.1,4.6,4.7,4.6C44.9,36.4,47,34.3,47,31.8z M40,31.8c0-1.3,1-2.3,2.3-2.3s2.3,1,2.3,2.3
                    c0,1.3-1,2.3-2.3,2.3S40,33,40,31.8z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Listings + photos'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M99.1,2.3C98.6,2,97.9,2,97.3,2.3L66.7,18.4L36.1,2.3c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0
                    c-0.1,0-0.2,0-0.3,0h-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0L1.3,13.2C0.5,13.5,0,14.2,0,15v79.3
                    c0,0.6,0.3,1.1,0.8,1.5c0.3,0.2,0.7,0.3,1.1,0.3c0.2,0,0.4,0,0.6-0.1l32.7-10.8L66,97.8c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.1
                    c0.2,0,0.3,0,0.5-0.1c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1L99,81.1c0.6-0.3,1-0.9,1-1.6V3.9C100,3.3,99.7,2.7,99.1,2.3z
                    M33.4,81.8L3.7,91.7V16.3l29.6-9.8v49.8c-0.8,0.1-1.6,0.3-2.3,0.5C30,57,29.4,58,29.7,59c0.2,0.8,1,1.4,1.8,1.4
                    c0.1,0,0.3,0,0.5-0.1c0.4-0.1,0.9-0.2,1.4-0.3V81.8z M64.8,60.7c-1.3-0.1-2.7-0.2-4.1-0.5c-1-0.2-2,0.5-2.2,1.5
                    c-0.2,1,0.5,2,1.5,2.2c1.6,0.3,3.2,0.5,4.8,0.5v29L37,82V59.5c0.6,0,1.2-0.1,1.8-0.1c1,0,1.8-0.9,1.8-1.9c0-1-0.9-1.8-1.9-1.8
                    c0,0,0,0,0,0c-0.6,0-1.1,0-1.7,0.1V7l27.8,14.6V60.7z M96.3,78.4L68.5,93V64.2c0.7-0.3,1.1-1,1.1-1.8c-0.1-0.7-0.5-1.2-1.1-1.5
                    V21.6L96.3,7V78.4z M20.4,16.8c-5.1,0-9.3,4.1-9.3,9.2c0,4.4,3.2,8.2,7.4,9v3.9c0,1,0.8,1.8,1.9,1.8c1,0,1.9-0.8,1.9-1.8v-3.9
                    c4.2-0.8,7.4-4.6,7.4-9C29.6,21,25.5,16.8,20.4,16.8z M20.4,31.6c-3.1,0-5.6-2.5-5.6-5.5c0-3,2.5-5.5,5.6-5.5
                    c3.1,0,5.6,2.5,5.6,5.5C25.9,29.1,23.4,31.6,20.4,31.6z M74.9,62.4c0.3,0,0.6-0.1,0.8-0.2c2.4-1.2,4.5-3,6.3-5.1
                    c0.6-0.8,0.5-2-0.3-2.6c-0.8-0.6-2-0.5-2.6,0.3c-1.4,1.8-3.1,3.2-5.1,4.2c-0.9,0.5-1.3,1.6-0.8,2.5C73.5,62,74.2,62.4,74.9,62.4z
                    M23.7,59.8c-2.2,1.4-4.2,3.2-6,5.2c-0.7,0.8-0.6,1.9,0.2,2.6c0.4,0.3,0.8,0.4,1.2,0.4c0.5,0,1.1-0.2,1.4-0.7
                    c1.5-1.8,3.2-3.3,5.1-4.4c0.9-0.5,1.1-1.7,0.6-2.5C25.7,59.6,24.6,59.3,23.7,59.8z M16,70.8c-0.9-0.4-2,0-2.5,0.9
                    c-1.9,4.1-2.4,7.4-2.4,7.6c-0.1,1,0.6,1.9,1.6,2.1c0.1,0,0.2,0,0.3,0c0.9,0,1.7-0.7,1.8-1.6c0,0,0.4-2.9,2.1-6.5
                    C17.3,72.3,16.9,71.2,16,70.8z M83.5,51.2c0.2,0.1,0.4,0.1,0.7,0.1c0.7,0,1.4-0.4,1.7-1.2c0.9-2.2,1.6-4.7,2.1-7.4
                    c0.2-1-0.5-2-1.5-2.2c-1-0.2-2,0.5-2.2,1.5c-0.5,2.5-1.1,4.8-1.9,6.7C82,49.8,82.5,50.9,83.5,51.2z M45.9,59.9
                    c1.7,0.3,3.5,0.6,5.3,1.1c0.2,0.1,0.7,0.3,1.4,0.6c0.2,0.1,0.5,0.1,0.7,0.1c0.7,0,1.4-0.4,1.7-1.1c0.4-1-0.1-2-1-2.4
                    c-0.8-0.3-1.3-0.6-1.3-0.6c-0.1-0.1-0.2-0.1-0.4-0.1c-2-0.5-4-0.9-5.9-1.2c-1-0.2-2,0.5-2.1,1.5C44.2,58.8,44.9,59.7,45.9,59.9z
                    M86.9,37H87c1,0,1.8-0.8,1.9-1.7c0.1-1.8,0.2-3.6,0.2-5.6c0-0.6,0-1.3,0-2c0-1-0.9-1.8-1.9-1.8c-1,0-1.8,0.9-1.8,1.9
                    c0,0.6,0,1.3,0,1.9c0,1.8-0.1,3.6-0.2,5.3C85.1,36,85.9,36.9,86.9,37z M15.4,53.2c0.4,0.4,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5
                    l2.4-2.4l2.4,2.4c0.4,0.4,0.8,0.6,1.3,0.6c0.5,0,0.9-0.2,1.3-0.5c0.7-0.7,0.7-1.9,0-2.6L23,48.2l2.4-2.4c0.7-0.7,0.7-1.9,0-2.6
                    c-0.7-0.7-1.9-0.7-2.6,0l-2.4,2.4L18,43.2c-0.7-0.7-1.9-0.7-2.6,0c-0.7,0.7-0.7,1.9,0,2.6l2.4,2.4l-2.4,2.4
                    C14.6,51.3,14.6,52.4,15.4,53.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Maps'
				},

				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M83.9,33.9c0-6.2-5.1-11.3-11.3-11.3c-6.2,0-11.3,5.1-11.3,11.3v1.6h-3.2V8.1C58.1,3.6,54.4,0,50,0H8.1C3.6,0,0,3.6,0,8.1
                    v83.9c0,4.4,3.6,8.1,8.1,8.1H50c4.4,0,8.1-3.6,8.1-8.1V74.2H100V35.5H83.9V33.9z M64.5,33.9c0-4.4,3.6-8.1,8.1-8.1
                    c4.4,0,8.1,3.6,8.1,8.1v4.8c0,4.4-3.6,8.1-8.1,8.1c-4.4,0-8.1-3.6-8.1-8.1V33.9z M77.4,48.9v0.4l-4.8,4.8l-4.8-4.8v-0.4
                    c1.5,0.7,3.1,1.1,4.8,1.1S76,49.6,77.4,48.9z M61.3,38.7c0,3.1,1.2,5.9,3.2,7.9v2.1l-6.8,1.7c-3.6,0.9-6.1,4.1-6.1,7.8V71h-3.2
                    V38.7H61.3z M3.2,8.1c0-2.7,2.2-4.8,4.8-4.8H50c2.7,0,4.8,2.2,4.8,4.8v4.8H3.2V8.1z M54.8,91.9c0,2.7-2.2,4.8-4.8,4.8H8.1
                    c-2.7,0-4.8-2.2-4.8-4.8v-4.8h51.6V91.9z M54.8,83.9H3.2V16.1h51.6v19.4h-9.7v38.7h9.7V83.9z M90.3,71H54.8V58.3
                    c0-2.2,1.5-4.2,3.7-4.7l7.1-1.8l6.9,6.9l6.9-6.9l7.1,1.8c2.2,0.5,3.7,2.5,3.7,4.7V71z M96.8,38.7V71h-3.2V58.3
                    c0-3.7-2.5-6.9-6.1-7.8l-6.8-1.7v-2.1c2-2,3.2-4.8,3.2-7.9H96.8z M41.9,90.3H19.4v3.2h22.6V90.3z M25.8,19.4H6.5v19.4h19.4V19.4z
                    M22.6,35.5H9.7V22.6h12.9V35.5z M51.6,21H29v3.2h22.6V21z M51.6,27.4H29v3.2h22.6V27.4z M6.5,45.2h35.5v-3.2H6.5V45.2z M6.5,51.6
                    h35.5v-3.2H6.5V51.6z M6.5,77.4h35.5v-3.2H6.5V77.4z M12.9,58.1h29v-3.2h-29V58.1z M9.7,54.8H6.5v3.2h3.2V54.8z M12.9,64.5h29
                    v-3.2h-29V64.5z M9.7,61.3H6.5v3.2h3.2V61.3z M12.9,71h29v-3.2h-29V71z M9.7,67.7H6.5V71h3.2V67.7z M29,37.1h12.9v-3.2H29V37.1z
                    M80.6,6.5h-3.2v3.2h3.2V6.5z M87.1,6.5h-3.2v3.2h3.2V6.5z M93.5,6.5h-3.2v3.2h3.2V6.5z M96.8,0H74.2C72.4,0,71,1.4,71,3.2v9.7
                    c0,1.8,1.4,3.2,3.2,3.2h18.7l7.1,7.1v-20C100,1.4,98.6,0,96.8,0z M96.8,15.5l-2.6-2.6h-20V3.2h22.6V15.5z M93.5,90.3h-3.2v3.2h3.2
                    V90.3z M87.1,90.3h-3.2v3.2h3.2V90.3z M80.6,90.3h-3.2v3.2h3.2V90.3z M96.8,83.9H78.1L71,76.8v20c0,1.8,1.4,3.2,3.2,3.2h22.6
                    c1.8,0,3.2-1.4,3.2-3.2v-9.7C100,85.3,98.6,83.9,96.8,83.9z M96.8,96.8H74.2V84.5l2.6,2.6h20V96.8z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Live Chat'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
