<template>
	<section class="bg-white padding-60">
		<div class="title">
			<span>Case study</span>
			<h2 class="fxt-process-dev">
				Get to know the story behind how we build a real fantasy sports app
			</h2>
			<p>
				Fantasyji is a feather in the fantasy sports app development cap of GenixBit Labs that allows
				users to play and win fantasy cricket matches.
			</p>
		</div>
		<ImageWithInfo :obj="obj"></ImageWithInfo>
	</section>
</template>

<script>
import ImageWithInfo from '../../SocialSolutions/AssetComponents/ImageWithInfo.vue';
export default {
	components: {
		ImageWithInfo
	},
	data() {
		return {
			obj: {
				color: 'fantasyabg',
				logo: '/fantasyji-logo.jpg',
				image1: '/fantasyji-game-mobile-1.webp',
				image2: '/fantasyji-game-mobile-2.webp',
				image3: '/fantasyji-game-main.webp',
				title: 'Fantasyji',
				description: 'Fantasy Cricket: A Game of Skill',
				paragraphs: [
					'An app that lets users play an addictive game of cricket by assembling their own fantasy team of super cricketers.',
					'The fun in sport is savored when you are a part of it. Cricket is great to watch, but the best when it is played. The experience hits the zenith when you can play it with your team of hand picked favorites and legends from all existing cricket teams.',
					'This was the idea behind Fantasyji fantasy sports app. Fantasyji conjures world of fantasy cricket for users with multiple forms of cricket matches. Users can form their own cricket teams in forms like Select 11, Daily matches, and auctions.',
					'Fantasyji has become a rage on the fantasy sports circles owing to its unique features that include:'
				],
				features: [
					'Real-time match selection',
					'Player auction and snake drafts',
					'Fantasy team selection',
					'Content participation',
					'Multiple team drafting methods'
				]
			}
		};
	}
};
</script>

<style scoped>
.portfolio-slider {
	padding: 0 !important;
}
.portfolio-slider button {
	display: none;
}
</style>