<template>
	<div class="ecom-app-why-box-fxt">
		<div class="ecom-app-why-box-fxt-icon" v-html="obj.svg"></div>
		<h4 class="font-weight:bold">{{ obj.title }}</h4>
		<p class="mb-0">{{ obj.text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.ecom-app-why-box-fxt {
	width: 100%;
	background: #fff;
	border: 1px solid #ecedee;
	border-radius: 10px;
	text-align: center;
	padding: 30px 13px 20px;
	transition: all 0.3s ease;
	margin-bottom: 50px;
	min-height: 400px;
	position: relative;
}
.ecom-app-why-box-fxt:hover {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
	border-bottom:5px solid #1977cc
}
.ecom-app-why-box-fxt-icon {
	margin: 0 auto;
	width: 90px;
	height: 90px;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	padding: 20px;
	transition: all 0.3s ease;

}
.ecom-app-why-box-fxt h4 {
	font-size: 18px;
	margin: 15px 0 10px;
	transition: all 0.3s ease;
	min-height: 50px;
	display: flex;
	align-items: center;
	line-height: 1.3;
	justify-content: center;
	width: 100%;
}
.ecom-app-why-box-fxt-icon svg circle,
.ecom-app-why-box-fxt-icon svg ellipse,
.ecom-app-why-box-fxt-icon svg path,
.ecom-app-why-box-fxt-icon svg polygon,
.ecom-app-why-box-fxt-icon svg rect {
	transition: all 0.3s ease;
	fill: #6a6d6f;
}
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon {
	background: #1977cc;
}
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon svg circle,
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon svg ellipse,
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon svg path,
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon svg polygon,
.ecom-app-why-box-fxt:hover .ecom-app-why-box-fxt-icon svg rect {
	fill: #fff;
}
</style>
