<template>
  <div>

      <Banner></Banner>

      <BrandsLogos></BrandsLogos>

      <About></About>

      <Advantages></Advantages>

      <Decades></Decades>

      <TypeDiv></TypeDiv>

      <Portfolio></Portfolio>

      <Const></Const>
  </div>
</template>

<script>
import Banner from '../../components/MobileDevelopment/FlutterDevelopment/Banner.vue'
import Advantages from '../../components/MobileDevelopment/FlutterDevelopment/Advantages.vue'
import Decades from '../../components/MobileDevelopment/FlutterDevelopment/Decades.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/MobileDevelopment/FlutterDevelopment/About.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        Advantages,
        TypeDiv,
        Decades,
        Portfolio,
        Const,
    }
}
</script>


<style>

</style>