<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>What makes GenixBit an ideal ally for CodeIgniter development</h2>
				<p>
					A rare combination of experience, expertise and customer service — these are the three
					factors that makes GenixBit an ideal ally for your CodeIgniter development projects.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
            {
               title:"Long Experience",
               text:"Our combined CodeIgniter development experience spans a long decade and more. Rest assured we know our way around CodeIgniter to make things work for you.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M49.3,28.4h-4.5c-0.5,0-0.8,0.3-0.8,0.8v1.5h-2.3v-8.7c0-1.3-1.1-2.3-2.3-2.3h-5.2v-6.9h7.4v1.5c0,0.5,0.3,0.8,0.8,0.8
                              h4.5c0.5,0,0.8-0.3,0.8-0.8V9.6c0-0.5-0.3-0.8-0.8-0.8h-4.5c-0.5,0-0.8,0.3-0.8,0.8v1.5h-8.2c-0.5,0-0.8,0.3-0.8,0.8v7.7h-7.2V8.1
                              c1.3-0.3,2.2-1.5,2.2-2.9c0-1.6-1.3-3-3-3c-1.7,0-3,1.3-3,3c0,1.4,0.9,2.5,2.2,2.9v11.4h-6.9v-7.7c0-0.5-0.3-0.8-0.8-0.8H8.2V9.5
                              c0-0.5-0.3-0.8-0.8-0.8H2.9c-0.5,0-0.8,0.3-0.8,0.8V14c0,0.5,0.3,0.8,0.8,0.8h4.5c0.5,0,0.8-0.3,0.8-0.8v-1.5h7.4v6.9h-4.9
                              c-1.3,0-2.3,1.1-2.3,2.3v8.7H5.9c-0.3-1.3-1.5-2.2-2.9-2.2c-1.7,0-3,1.4-3,3c0,0.8,0.3,1.5,0.8,2.1c0.8,0.8,2.1,1.2,3.2,0.6
                              c0.8-0.4,1.5-1.1,1.8-2h2.5v9.5H5.3c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.2,0.5-0.2,0.7l1.1,4.1c0.1,0.3,0.4,0.5,0.8,0.5h37.2
                              c0.4,0,0.6-0.2,0.8-0.5l1.1-4.1c0.1-0.2,0-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.6-0.3h-3v-9.3H44v1.5c0,0.5,0.3,0.8,0.8,0.8h4.5
                              c0.5,0,0.8-0.3,0.8-0.8v-4.5C50.1,28.7,49.8,28.4,49.3,28.4z M43.3,10.4h2.9v2.9h-2.9V10.4z M23.3,5.2c0-0.8,0.6-1.5,1.5-1.5
                              c0.8,0,1.5,0.6,1.5,1.5c0,0.8-0.6,1.5-1.5,1.5C24,6.7,23.3,6,23.3,5.2z M6.6,13.3H3.7v-2.9h2.9V13.3z M3.5,32.7
                              c-1,0.5-2-0.4-2-1.3c0-0.8,0.6-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C4.4,32.1,3.9,32.5,3.5,32.7z M43,45.7H7l-0.7-2.5h37.4L43,45.7z
                              M9.8,41.6V21.9c0-0.5,0.4-0.8,0.8-0.8h28.8c0.5,0,0.8,0.4,0.8,0.8v19.7H9.8z M48.5,32.8h-2.9v-2.9h2.9V32.8z"></path>
                           </g>
                           <title></title>
                           <path d="M27.8,29.2l-0.1,0.2l0,0l0,0l0,0L27.8,29.2L27.8,29.2c-0.2-0.1-0.4-0.1-0.6,0c-0.2,0.1-0.3,0.3-0.3,0.5
                           c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.3-0.3c0-0.5,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.5,0.2-1.1
                           c0-1.2-0.1-1.8-1.6-3.2c-0.2-0.2-0.4-0.2-0.6-0.1c-0.2,0.1-0.3,0.3-0.3,0.6c0.3,1.5-0.6,2.2-1.8,3.3c-0.4,0.3-0.7,0.6-1.1,1
                           c-1.7,1.7-2.1,3.3-2.1,4.3c0,1.8,0.7,4,3.8,4.7c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.1-0.5-0.1-0.6
                           c-1.1-1-1.2-1.5-1.2-1.8c0-0.3,0.1-0.6,0.3-0.9c0,0.1,0.1,0.1,0.1,0.2c0.4,0.6,1.1,0.6,1.3,0.6c0.8,0,1.4-0.7,1.4-1.6
                           c0-0.5-0.4-0.9-0.7-1.2c-0.2-0.2-0.5-0.5-0.5-0.7c0,0,0-0.1,0-0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.4
                           c0.9,0.6,2.5,1.6,2.5,2.5c0,1.3-0.4,1.6-1.5,2.4c-0.2,0.1-0.3,0.4-0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.3l0.1,0c2.1-0.5,4.3-2.4,4.3-4.7
                           C30.8,31.2,28.7,29.8,27.8,29.2z M21.1,35.5c0,0.3,0,0.5,0.1,0.8c-0.7-0.6-1-1.6-1-2.7c0-1.1,0.6-2.4,1.8-3.5
                           c0.3-0.3,0.7-0.6,1-0.9c0.9-0.8,1.9-1.6,2.2-2.8c0.3,0.5,0.4,0.8,0.4,1.4c0,0.5-0.1,0.6-0.1,0.8c-0.1,0.2-0.2,0.5-0.2,1.2
                           c0,0.7,0.6,1.3,1.3,1.3c0,0,0.1,0,0.1,0c0.4,0,0.7-0.2,0.9-0.4c0,0,0.1-0.1,0.1-0.1c0.9,0.6,2,1.6,2,3.1c0,1-0.6,1.9-1.3,2.5
                           c0.1-0.3,0.1-0.7,0.1-1.1c0-1.5-1.8-2.6-3-3.4l-0.1,0c-0.2-0.1-0.4-0.3-0.5-0.3c-0.4-0.3-0.4-0.5-0.4-0.5c0,0,0,0,0,0
                           c0.1-0.2,0-0.5-0.2-0.6c-0.2-0.1-0.5-0.1-0.7,0.1c-0.4,0.5-0.7,1.1-0.7,1.8c0,0.6,0.4,1.1,0.7,1.4c0.2,0.2,0.4,0.4,0.4,0.5
                           c0,0.3-0.1,0.6-0.3,0.6c-0.2,0-0.4,0-0.4-0.2c-0.1-0.1-0.1-0.4,0.1-0.8c0.1-0.2,0-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.6,0
                           C21.9,33.4,21.3,34.4,21.1,35.5z"></path>
                        </g>
                     </svg>
               `
            },
            {
               title:"Deep Expertise",
               text:"From building simple web portals to engineering sophisticated eCommerce websites from ground up, we are capable of doing everything with CodeIgniter.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <path d="M37.6,50H16.5c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-1.2,0.1-2.5,0-3.1c-0.2-1.3-5-0.9-5.6-0.9
                        c-0.4,0-1.8-0.2-2.8-1c-0.5-0.4-0.7-1.2-0.8-2c-0.1-0.5-0.5-9.3-0.5-9.3l-3.1-0.2c-0.2,0-0.4-0.1-0.5-0.2c-1.2-0.9-1.8-1.7-1.8-2.4
                        c0-0.3,0-0.8,6.1-9.9c-0.1-0.8-0.2-2.9-0.3-4.5c-0.1-1.6,2.8-6.8,6.2-9.9c2.7-2.4,7.3-3,9.9-3.2c0.5,0,1,0.4,1,0.9
                        c0,0.5-0.4,1-0.9,1C20.2,4.4,16,5,13.8,7c-3.2,2.9-5.5,7.5-5.5,8.4c0.1,2,0.3,4.7,0.3,4.8c0,0.2,0,0.4-0.2,0.6
                        c-2.5,3.7-5.2,7.9-5.9,9.1c0.1,0.2,0.4,0.4,0.6,0.6l3.7,0.2c0.5,0,0.9,0.4,0.9,0.9c0,0,0.3,6.6,0.5,9.5c0.1,0.8,0.1,1.1,0.3,1.4
                        C9,42.9,9.5,43,10.1,43c2.5,0,7-0.4,7.3,2.2c0,0.5,0.2,1.6,0.2,2.8h19.2v-7.9c0-0.3-0.1-1.9,1-3.2c0.3-0.4,0.8-0.6,1.2-0.9
                        c1.1-0.6,2.4-1.4,3.1-5.6c0.1-0.8,0.4-5.1,0.5-7.8c0-0.5,0.5-1,1-0.9c0.5,0,1,0.5,0.9,1c0,0.7-0.4,6.9-0.5,8c-0.8,5.1-2.8,6.2-4,7
                        c-0.3,0.2-0.5,0.3-0.7,0.4c-0.6,0.8-0.6,1.8-0.6,1.8c0,0,0,0.1,0,0.1V49C38.6,49.6,38.2,50,37.6,50z M34.2,35.7
                        c-2.6,0-4.7-2.1-4.7-4.7c0-1.4,0.6-2.7,1.6-3.5l-8.4-3.4c-0.6,1-1.7,1.6-3,1.6c-2,0-3.6-1.6-3.6-3.6s1.6-3.6,3.6-3.6
                        c0.7,0,1.4,0.2,2,0.6l6.8-9.1c-2.1-0.6-3.6-2.5-3.6-4.8c0-2.7,2.2-5,5-5s5,2.2,5,5c0,1.7-0.9,3.3-2.3,4.2l10.6,6.2
                        c0.5-1,1.5-1.6,2.6-1.6c1.6,0,2.9,1.3,2.9,2.9s-1.3,2.9-2.9,2.9c-0.9,0-1.7-0.4-2.2-1l-7.7,7.7c1.9,0.6,3.2,2.4,3.2,4.5
                        C39,33.5,36.9,35.7,34.2,35.7z M33.7,28.2c-1.3,0.3-2.2,1.4-2.2,2.7c0,1.5,1.2,2.8,2.8,2.8s2.8-1.2,2.8-2.8c0-1.5-1.2-2.8-2.8-2.8
                        c-0.1,0-0.3,0-0.4,0C33.8,28.2,33.7,28.2,33.7,28.2z M23.2,22.1l9,3.7l-2.6-14.2l-6.7,9.1C23.1,21,23.2,21.5,23.2,22.1
                        C23.2,22,23.2,22,23.2,22.1z M31.6,11.1l2.6,14.3l8.1-8L31.6,11.1z M19.7,20.4c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6
                        c0.9,0,1.6-0.7,1.6-1.6S20.6,20.4,19.7,20.4z M45.8,15.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1
                        C46.7,16.3,46.3,15.9,45.8,15.9z M29.8,2.2c-1.7,0-3,1.3-3,3s1.4,3,3,3c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c1.5-0.2,2.7-1.4,2.7-3
                        C32.8,3.5,31.4,2.2,29.8,2.2z"></path>
                     </svg>
               `
            },
            {
               title:"Diverse offerings",
               text:"Custom web development. Integration development, application migration — we are your one stop shop for all CodeIgniter requirements.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <path d="M49,38.1h-4.1l-1.4-8.4h2.3c0.5,0,1-0.4,1-1v-8.4c0-0.5-0.4-1-1-1h-8l-5.7-5.7h4.8c0.5,0,1-0.4,1-1V3c0-0.5-0.4-1-1-1H13.1
                           c-0.5,0-1,0.4-1,1v9.6c0,0.5,0.4,1,1,1h4.3l-6.1,5.7H3c-0.5,0-1,0.4-1,1v8.4c0,0.5,0.4,1,1,1h2.7l-0.9,8.4H0.9c-0.5,0-1,0.4-1,1
                           v8.4c0,0.5,0.4,1,1,1h8.4c0.5,0,1-0.4,1-1v-8.4c0-0.5-0.4-1-1-1H6.7l0.9-8.4h3.8c0.5,0,1-0.4,1-1V21l7.9-7.4H24v7.9h-3.2
                           c-0.5,0-1,0.4-1,1v8.4c0,0.5,0.4,1,1,1h1.1l-2.5,6.2h-5.2c-0.5,0-1,0.4-1,1v8.4c0,0.5,0.4,1,1,1h8.4c0.5,0,1-0.4,1-1v-8.4
                           c0-0.5-0.4-1-1-1h-1.1l2.5-6.2H26l2.5,6.2h-1.1c-0.5,0-1,0.4-1,1v8.4c0,0.5,0.4,1,1,1h8.4c0.5,0,1-0.4,1-1v-8.4c0-0.5-0.4-1-1-1
                           h-5.2l-2.5-6.2h1.1c0.5,0,1-0.4,1-1v-8.4c0-0.5-0.4-1-1-1H26v-7.9h3.4l7.1,7.1v8c0,0.5,0.4,1,1,1h4.1l1.4,8.4h-2.3
                           c-0.5,0-1,0.4-1,1v8.4c0,0.5,0.4,1,1,1H49c0.5,0,1-0.4,1-1v-8.4C50,38.5,49.6,38.1,49,38.1z M8.3,40v6.5H1.9V40h2.6h2H8.3z
                           M10.4,27.7H7.8h-2H4v-6.5h5.3h1.2v1.5V27.7z M21.6,40v6.5h-6.5V40h3.5h2.1H21.6z M31.3,40h3.5v6.5h-6.5V40h0.9H31.3z M21.7,29.9
                           v-6.5H24H26h2.3v6.5H21.7z M27.4,11.6H26H24h-1.7h-2.8H14V4h21.9v7.6h-5.8H27.4z M41.2,27.7h-2.8v-5.1v-1.4h1.4h5.1v6.5h-1.7H41.2z
                           M48.1,46.5h-6.5V40h1.7h2h2.8V46.5z"></path>
                        </g>
                     </svg>
               `
            },
            {
               title:"Customer centricity",
               text:"Our relationship with customers does not with a single transaction. We nurture them as long standing relationships with customers at the heart of it.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                        <g>
                           <g>
                              <g>
                                 <path class="st0" d="M20.6,28.6c0.8-0.6,1.3-1.6,1.3-2.7c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.1,0.5,2.1,1.3,2.7
                                 c-2.1,0.7-3.6,2.7-3.6,5v0.1c0,0.4,0.3,0.7,0.7,0.7h9.7c0.4,0,0.7-0.3,0.7-0.7v-0.1C24.2,31.2,22.7,29.3,20.6,28.6z M18.6,24
                                 c1,0,1.9,0.8,1.9,1.9c0,1-0.8,1.9-1.9,1.9s-1.9-0.8-1.9-1.9C16.8,24.9,17.6,24,18.6,24z M14.5,33c0.3-1.8,1.9-3.1,3.7-3.1h0.7
                                 c1.9,0,3.4,1.3,3.7,3.1H14.5z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M33.6,28.6c0.8-0.6,1.3-1.6,1.3-2.7c0-1.9-1.5-3.4-3.4-3.4s-3.4,1.5-3.4,3.4c0,1.1,0.5,2.1,1.3,2.7
                                 c-2.1,0.7-3.6,2.7-3.6,5v0.1c0,0.4,0.3,0.7,0.7,0.7h9.7c0.4,0,0.7-0.3,0.7-0.7v-0.1C37.1,31.2,35.6,29.3,33.6,28.6z M31.6,24
                                 c1,0,1.9,0.8,1.9,1.9c0,1-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9C29.6,24.9,30.5,24,31.6,24z M27.5,33c0.3-1.8,1.9-3.1,3.7-3.1
                                 h0.7c1.9,0,3.4,1.3,3.7,3.1H27.5z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M30.7,21.2v-0.1c0-2.3-1.5-4.3-3.6-5c0.8-0.6,1.3-1.6,1.3-2.7c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4
                                 c0,1.1,0.5,2.1,1.3,2.7c-2.1,0.7-3.6,2.7-3.6,5v0.1c0,0.4,0.3,0.7,0.7,0.7h9.7C30.4,22,30.7,21.6,30.7,21.2z M25.1,11.5
                                 c1,0,1.9,0.8,1.9,1.9s-0.8,1.9-1.9,1.9c-1,0-1.9-0.8-1.9-1.9C23.2,12.3,24,11.5,25.1,11.5z M21,20.5c0.3-1.8,1.9-3.1,3.7-3.1h0.7
                                 c1.9,0,3.4,1.3,3.7,3.1H21z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M25.1,8.1C15.8,8.1,8.2,15.6,8.2,25s7.6,16.9,16.8,16.9S41.9,34.3,41.9,25S34.3,8.1,25.1,8.1z M25.1,40.3
                                 c-8.5,0-15.3-6.9-15.3-15.4S16.6,9.5,25.1,9.5s15.3,6.9,15.3,15.4S33.5,40.3,25.1,40.3z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M38.1,12.7l8.5-1.5c0.3-0.1,0.5-0.2,0.6-0.5c0.1-0.3,0-0.5-0.1-0.7l-1.5-1.4l4-4l0,0l0,0l0,0
                                 c0.3-0.3,0.3-0.8-0.1-1.1l-2.9-2.9c-0.1-0.1-0.3-0.2-0.5-0.2s-0.4,0.1-0.5,0.2l-4,3.9L40,3c-0.2-0.2-0.5-0.3-0.7-0.2
                                 C39,2.8,38.8,3,38.8,3.3l-1.6,8.5c-0.1,0.2,0,0.5,0.2,0.7C37.6,12.7,37.9,12.8,38.1,12.7z M40,5.1L40.9,6
                                 c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.4-0.1,0.5-0.2l4-4l2,2l-4,4c-0.1,0.1-0.2,0.3-0.2,0.5S43.8,8.9,44,9l0.9,0.9l-6,1L40,5.1z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M12,37.1l-8.4,1.6c-0.3,0.1-0.5,0.2-0.6,0.5c-0.1,0.3,0,0.5,0.2,0.7l1.4,1.4l-3.9,4
                                 c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5l2.9,2.9c0.1,0.3,0.3,0.4,0.5,0.4c0.2,0,0.4-0.1,0.5-0.2l4-4l1.4,1.4
                                 c0.2,0.2,0.5,0.3,0.7,0.1c0.3-0.1,0.4-0.3,0.5-0.6l1.5-8.5c0.1-0.2,0-0.5-0.2-0.7C12.5,37.1,12.3,37,12,37.1z M10.2,44.7
                                 l-0.9-0.9c-0.1-0.1-0.3-0.2-0.5-0.2l0,0c-0.2,0-0.4,0.1-0.5,0.2l-3.9,4l-1.9-1.9l3.9-4c0.3-0.3,0.3-0.7,0-1l-0.9-0.9l6-1.1
                                 L10.2,44.7z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M49.5,45.3l-3.9-4l1.4-1.4c0.2-0.2,0.3-0.4,0.2-0.7c-0.1-0.3-0.3-0.4-0.6-0.5l-8.5-1.6
                                 c-0.2-0.1-0.5,0-0.7,0.2c-0.1,0.1-0.2,0.4-0.2,0.7l1.5,8.5c0.1,0.3,0.2,0.5,0.5,0.6c0.3,0.1,0.5,0,0.7-0.1l1.4-1.4l4,4
                                 c0.1,0.1,0.4,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3l2.9-2.9c0.1-0.1,0.2-0.3,0.2-0.5C49.7,45.6,49.6,45.5,49.5,45.3z M41.4,43.6
                                 L41.4,43.6c-0.2,0-0.4,0.1-0.5,0.2L40,44.7l-1-6l6,1.1l-0.9,0.9c-0.3,0.3-0.3,0.7,0,1l3.9,4L46,47.7l-3.9-4
                                 C41.8,43.7,41.7,43.6,41.4,43.6z"></path>
                              </g>
                           </g>
                           <g>
                              <g>
                                 <path class="st0" d="M0.6,4.6L0.6,4.6L0.6,4.6l4,3.9L3.2,9.9c-0.2,0.2-0.3,0.5-0.1,0.7c0.1,0.3,0.3,0.4,0.6,0.5l8.5,1.5
                                 c0.2,0.1,0.4,0,0.7-0.2c0.1-0.1,0.2-0.4,0.2-0.7l-1.7-8.4c-0.1-0.3-0.2-0.5-0.5-0.6c-0.3-0.1-0.5,0-0.7,0.2L8.6,4.5l-3.9-4
                                 C4.6,0.4,4.4,0.3,4.2,0.3C4,0.3,3.7,0.4,3.7,0.5l-3,2.9L0.6,3.5C0.3,3.8,0.3,4.3,0.6,4.6L0.6,4.6z M8.7,6.3L8.7,6.3
                                 c0.2,0,0.4-0.1,0.5-0.2l0.9-1l1.1,6l-6-1l0.9-0.9c0.2-0.2,0.3-0.4,0.3-0.6S6.4,8.1,6.2,8l-4-4l1.9-1.9l4,4
                                 C8.3,6.1,8.5,6.3,8.7,6.3z"></path>
                              </g>
                           </g>
                        </g>
                     </svg>
               `
            },
         ]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
