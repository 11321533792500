<template>
  <section class="padding-60 contact-info-main bg-light">
   <div class="container">
      <div class="row">
         <div class="col-md-4 col-12 mb-30">
            <div class="contact-info-box text-center rounded10 box-shadow">
               <div class="contact-info-icon box-shadow">
                  <svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                     <path d="M494.7,287.7c1.5-11.6,2.2-23.5,2-35.6C494.4,122.9,389.5,18,260.4,15.6c-12.1-0.2-23.9,0.5-35.5,2
                     c-8.1,1-16.3-0.3-23.6-3.8C182.7,4.9,161.9-0.1,139.9,0C63.2,0.3,0.4,63,0,139.7c-0.1,22.1,4.8,43,13.8,61.6
                     c3.5,7.2,4.6,15.2,3.6,23.1c-1.4,10.4-2.1,21.1-2.1,31.9c0,133,107.8,240.7,240.7,240.7c10.9,0,21.7-0.7,32.2-2.1
                     c7.8-1.1,15.9,0.1,23,3.5c18.6,8.9,39.5,13.8,61.5,13.6c76.4-0.6,138.8-63.1,139.3-139.5c0.1-21.9-4.7-42.6-13.5-61.1
                     C495,304,493.7,295.7,494.7,287.7z"></path>
                     <path class="st0" d="M275.4,418.3c-69.8,3.6-102.6-11.9-132.5-39.9c-33.5-31.4-20-67.2,7.3-69.1c27.2-1.8,43.5,31,58.2,40
                     c14.5,9.1,69.7,29.7,98.9-3.7c31.7-36.4-21.2-55.1-59.9-60.8c-55.4-8.2-125.4-38.1-119.8-97.1c5.4-59.1,50-89.3,97-93.5
                     c59.9-5.4,98.9,9.1,129.7,35.3c35.6,30.5,16.4,64.4-6.3,67.2c-22.6,2.8-48.1-50-98-50.9c-51.4-0.7-86.2,53.6-22.6,69
                     c63.6,15.5,131.5,21.9,156.1,79.9C408,353,345.4,414.7,275.4,418.3L275.4,418.3z"></path>
                  </svg>
               </div>
               <div class="tool">Talk to us on Skype</div>
               <div class="name"><a href="skype:sales@mnwebbased.in">GenixBit Support</a></div>
            </div>
         </div>
         <div class="col-md-4 col-12 mb-30">
            <div class="contact-info-box text-center rounded10 box-shadow">
               <div class="contact-info-icon box-shadow">
                  <svg x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                     <g>
                        <path d="M381.6,237.4h-30.5c0-42.2-34.3-76.6-76.6-76.6v-30.5C333.6,130.4,381.6,178.4,381.6,237.4z"></path>
                        <path d="M443.3,232.3h-30.5c0-73.4-59.7-133.1-133.1-133.1V68.7C369.9,68.7,443.3,142.1,443.3,232.3z"></path>
                        <path d="M504.4,227.2h-30.5C473.9,123,389,38.1,284.8,38.1V7.6c58.7,0,113.8,22.8,155.3,64.3C481.5,113.4,504.4,168.6,504.4,227.2z
                        "></path>
                     </g>
                     <path d="M496.3,428l-28.9,28.9c-20.1,20.1-44.2,33.8-69.7,41.2c-54.8,15.7-116.3,2-159.4-41.2L55.1,273.7
                     c-63.3-63.3-63.3-165.9,0-229.1L84,15.7c10.8-10.8,28.3-10.8,39.1,0l75.4,75.4c10.8,10.8,10.8,28.3,0,39.1l-28.9,28.9l-8.7,8.7
                     c-19.5,19.5-19.5,51,0,70.5l112.8,112.8c19.5,19.5,51,19.5,70.5,0l8.7-8.7l28.9-28.9c10.8-10.8,28.3-10.8,39.1,0l75.4,75.4
                     C507.1,399.7,507.1,417.2,496.3,428z"></path>
                  </svg>
               </div>
               <div class="tool">Our Contact</div>
               <div class="name"><a href="tel:+917000830437">+91-7000830437</a></div><!-- 113 357 0340 -->
            </div>
         </div>
         <div class="col-md-4 col-12 mb-30">
            <div class="contact-info-box text-center rounded10 box-shadow">
               <div class="contact-info-icon box-shadow">
                  <svg x="0px" y="0px" viewBox="0 0 452.8 452.8" style="enable-background:new 0 0 452.8 452.8;" xml:space="preserve">
                     <g>
                        <path d="M452.8,196.6v232.3c0,4.1-3.4,7.5-7.5,7.5H7.5c-4.1,0-7.5-3.4-7.5-7.5V196.6c0-2.6,1.3-4.9,3.4-6.3l57.8-38.3V23.9
                        c0-4.1,3.4-7.5,7.5-7.5h315.5c4.1,0,7.5,3.4,7.5,7.5v128.1l57.8,38.3C451.5,191.7,452.8,194,452.8,196.6z M437.8,414.9V209.1
                        l-172.5,91.5L437.8,414.9z M391.7,189.1h28.8l-28.8-19.1V189.1z M391.7,216.6l23.5-12.5h-23.5V216.6z M15,414.9l172.5-114.3
                        L15,209.1V414.9z M61.2,216.6v-12.5H37.6L61.2,216.6z M61.2,189.1v-19.1l-28.8,19.1H61.2z"></path>
                        <polygon class="st0" points="420.5,189.1 391.7,189.1 391.7,170.1  "></polygon>
                        <polygon class="st0" points="420.5,421.4 32.4,421.4 226.4,292.8   "></polygon>
                        <polygon class="st0" points="415.2,204.1 391.7,216.6 391.7,204.1  "></polygon>
                        <path class="st0" d="M293.7,144.2v5.5c0,14.5-11.8,26.3-26.3,26.3c-9.4,0-17.7-5-22.4-12.5c-5.7,5-13.2,8.1-21.3,8.1
                        c-17.9,0-32.4-14.5-32.4-32.4c0-17.9,14.5-32.4,32.4-32.4c17.9,0,32.4,14.5,32.4,32.4v10.5c0,6.2,5.1,11.3,11.3,11.3
                        c6.2,0,11.3-5.1,11.3-11.3v-5.5c0-28.8-23.4-52.3-52.3-52.3c-28.8,0-52.3,23.4-52.3,52.3v2.2c0,28.8,23.4,52.3,52.3,52.3h59.8
                        c4.1,0,7.5,3.4,7.5,7.5s-3.4,7.5-7.5,7.5h-59.8c-37.1,0-67.3-30.2-67.3-67.3v-2.2c0-37.1,30.2-67.3,67.3-67.3
                        C263.5,76.9,293.7,107.1,293.7,144.2z M241,139.2c0-9.6-7.8-17.4-17.4-17.4c-9.6,0-17.4,7.8-17.4,17.4c0,9.6,7.8,17.4,17.4,17.4
                        C233.2,156.6,241,148.8,241,139.2z"></path>
                        <polygon class="st0" points="61.2,204.1 61.2,216.6 37.6,204.1  "></polygon>
                        <polygon class="st0" points="61.2,170.1 61.2,189.1 32.4,189.1  "></polygon>
                     </g>
                  </svg>
               </div>
               <div class="tool">If not Forms, brief us @</div>
               <div class="name"><a href="mailto:Sales@mnwebbased.in">Sales@genixbit.com</a></div>
            </div>
         </div>
      </div>
      <div class="contact-follow-us-section text-center">
         <h2>Follow us on</h2>
         <ul class="header_social box-shadow rounded10 bg-white">
            <!-- <li><a href=https://plus.google.com/u/0/106928501521005803998 target="_blank" class="fab fa-google-plus-g"><span>Google+</span></a></li> -->
            <li><a href="https://twitter.com/genixbit" target="_blank" class="fab fa-twitter"><span>Twitter</span></a></li>
            <li><a href="https://www.facebook.com/genixbit" target="_blank" class="fab fa-facebook-f"><span>Facebook</span></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"><span>Linkedin</span></a></li>
            <li><a href="https://www.instagram.com/genixbit/" target="_blank" class="fab fa-instagram"><span>Instagram</span></a></li>
            <li><a href="https://www.behance.net/genixbit" target="_blank" class="fab fa-behance"><span>Behance</span></a></li>
         </ul>
      </div>
   </div>
</section>
</template>

<script>
export default {

}
</script>

<style>

</style>