<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Invest in CRM Software Development?</span>
				<h2 class="fxt-process-dev">Why Genixbit for CRM Solutions?</h2>
				<p>
					We comprehend your business needs with our CRM solutions team to help you tap in available
					data and resources, putting them to best use for creative and scalable CRM products.
				</p>
			</div>
			<div style="display: flex; justify-content: center; flex-wrap: wrap" class="invest-erp-main">
				<div
					class="col-lg-4 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<SqureCard v-bind:obj="item"></SqureCard>
				</div>
				<div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-30 d-flex align-items-center">
					<div class="why-you-invest-box-text">
						<h5 class="mb-20">Our Clients Love Us</h5>
						<a class="btn-custome btn waves-effect waves-light" href="/client-review"
							>Our Reviews</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import SqureCard from '../AssetComponents/SqureCard';

export default {
	components: {
		SqureCard
	},
	data: () => {
		return {
			items: [
        {
          title:"Offering Utmost Reliability",
          text:"If you are levelling up your game, it must be reliable. We ensure full reliability in terms of data generation and offered security for your business to efficiently utilize the CRM information. Our technical understanding of your business helps us delivering CRM solutions matching industry standards.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M42.9,40.4L42.9,40.4c0-1.1-0.5-2.1-1.4-2.8c-1-0.8-2.3-0.9-3.5-0.3l-7.6,3.8c0.3-0.9,0.1-1.9-0.5-2.7
                  c-0.6-0.8-1.5-1.3-2.5-1.3h-4.7c-2.7-1.7-6.1-2-9.1-0.8c-0.8,0.3-1.6,0.8-2.4,1.4l-0.1,0.1H1c-0.3,0-0.6,0.3-0.6,0.6v11
                  C0.4,49.7,0.7,50,1,50h25.9c1.3,0,2.7-0.3,3.8-1l10.4-5.7C42.2,42.8,42.9,41.6,42.9,40.4z M11,48.8H1.6V39H11V48.8z M41.6,40.4
                  c0,0.8-0.4,1.5-1.1,1.9l-10.4,5.7c-1,0.5-2.1,0.8-3.2,0.8H12.2V38.6c0.6-0.5,1.3-0.9,2.1-1.2c2.7-1.1,5.7-0.8,8.1,0.8
                  c0.1,0.1,0.2,0.1,0.3,0.1h4.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8h-8v1.2h9.2c0.1,0,0.2,0,0.3-0.1l9.6-4.8
                  c0.7-0.4,1.5-0.3,2.2,0.2C41.3,39,41.6,39.6,41.6,40.4L41.6,40.4z"></path>
                  <path d="M1.6,45.7H0.4v1.2h1.2V45.7z"></path>
                  <path d="M49.6,10.7c0,2.6-0.4,5.1-1.1,7.7c-0.5,2-1.3,3.9-2.2,5.7c-1.8,3.6-4.2,6.8-7.3,9.5c-1.3,1.1-2.7,2.1-4.1,3.2
                  c-0.7,0.5-1.4,0.5-2.1,0c-1.2-0.9-2.5-1.8-3.7-2.8c-4.4-3.7-7.4-8.4-9.2-13.8c-1.2-3.5-1.7-7.2-1.6-10.9c0-0.6,0.2-1,0.9-1.1
                  c2.3-0.3,4.6-0.9,6.6-2.1c1.3-0.7,2.6-1.5,3.8-2.4C31,2.7,32,1.6,33.1,0.5c0.8-0.8,1.1-0.7,1.8,0.1C38.1,4.6,42.2,7,47.1,8
                  c0.6,0.1,1.1,0.2,1.7,0.2c0.6,0.1,0.8,0.4,0.8,1C49.6,9.7,49.6,10.2,49.6,10.7z M48.3,11.2c0-0.1,0-0.3,0-0.4c0-1.2,0-1.2-1.2-1.4
                  c-2.3-0.4-4.4-1.2-6.5-2.3C38.2,5.8,36,4.2,34.3,2c-0.3-0.4-0.5-0.3-0.8,0c-0.8,0.8-1.6,1.6-2.5,2.3c-3.1,2.6-6.6,4.4-10.7,5
                  c-0.5,0.1-0.7,0.3-0.7,0.8c0.1,0.6,0.1,1.3,0.1,1.9C20,16,21,19.7,22.7,23.3c2.4,5.1,6.2,9,10.7,12.2c0.4,0.3,0.7,0.2,1,0
                  c1.3-1,2.7-1.9,3.9-3c3.2-2.9,5.7-6.4,7.5-10.4C47.3,18.5,48.1,14.9,48.3,11.2z"></path>
                  <path d="M41.3,16.6c-1.2-4.5-6.3-6.8-10.5-4.8c-0.3,0.2-0.6,0.4-0.9,0.6c-0.6,0.5,0,1.3,0.7,1c0.2-0.1,0.5-0.2,0.7-0.4
                  c3.7-1.8,7.8,0.5,8.6,4c-2.6-3.4-5.8-4.3-8.5-2.2c-1.5,1.1-2.4,2.6-2.4,4.5c0,1.9,0.8,3.4,2.2,4.6c-2.9-1.4-4.2-5-2.8-7.9
                  c0.2-0.4,0.4-0.8,0.6-1.2c0.3-0.6-0.3-1.2-1.1-0.7c-0.2,0.1-0.3,0.4-0.5,0.7c-1.7,3-1.2,6.5,1.2,9c2.4,2.4,6.1,2.9,9.1,1.1
                  C40.8,23.3,42.2,19.9,41.3,16.6z M36.7,17.3c-0.1,0.1-0.1,0.1-0.1,0.2c-0.9,1-1.7,1.9-2.6,2.8c-0.2,0.2-0.7,0.3-0.8,0.2
                  c-0.5-0.4-1-0.8-1.4-1.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.4,0.2-0.7,0.6-0.6c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.3,0.4,0.4
                  c0.4,0.4,0.7,0.4,1.1-0.1c0.6-0.7,1.2-1.4,1.9-2C36.7,15.8,37.2,16.7,36.7,17.3z"></path>
                </g>
              </svg>
          `
        },
        {
          title:"Cost Effective CRM Solutions",
          text:"The success of any CRM software development relies on how effectively you make an impact with relatively lesser efforts. This means, your CRM operates in full capacity and being a CRM software development company, we ensure your revenue surpass your resource capacities.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M8.5,34.8v-8.5c0-1.8,0.7-3.5,2-4.7c1.3-1.3,3-2,4.7-2h6.1c3.7,0,6.7,3,6.7,6.7v10.9h1.2V26.3c0-4-3-7.4-6.9-7.8l2-4
                  c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.3-0.5-0.3H12.8c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.2-0.1,0.4,0,0.6l2,4c-4,0.5-7,3.8-6.9,7.8
                  v8.5H8.5z M13.7,14.8h9l-1.8,3.6h-5.3L13.7,14.8z"></path>
                  <path d="M23.4,22.6c-0.3-0.2-0.7-0.3-1.1-0.4l0.3-1.2c0.5,0.1,0.9,0.3,1.4,0.6L23.4,22.6z"></path>
                  <path d="M26.7,28.7h-1.2v-2.4c0-1.1-0.5-2.3-1.3-3l0.8-0.9c1.1,1,1.7,2.4,1.7,3.9V28.7z"></path>
                  <path d="M36.4,40.5L36.4,40.5c0-1.1-0.5-2.1-1.3-2.7c-1-0.8-2.3-0.9-3.4-0.3l-7.5,3.8c0.3-0.9,0.1-1.9-0.4-2.7
                  c-0.6-0.8-1.5-1.2-2.4-1.2h-4.6c-2.7-1.7-6-2-9-0.8c-0.8,0.3-1.6,0.8-2.3,1.3l-0.1,0.1H0.6c-0.3,0-0.6,0.3-0.6,0.6l0,10.9
                  C0,49.7,0.3,50,0.6,50h20c1.3,0,2.6-0.3,3.8-1l10.3-5.6C35.8,42.8,36.5,41.7,36.4,40.5z M4.9,48.8H1.2v-9.7h3.6V48.8z M35.2,40.5
                  c0,0.8-0.4,1.5-1.1,1.8L23.8,48c-1,0.5-2.1,0.8-3.2,0.8H6.1v-10c0.6-0.5,1.3-0.9,2-1.2c2.6-1.1,5.6-0.8,8,0.8
                  c0.1,0.1,0.2,0.1,0.3,0.1h4.8c1,0,1.8,0.8,1.8,1.8s-0.8,1.8-1.8,1.8h-7.9v1.2h9.1c0.1,0,0.2,0,0.3-0.1l9.5-4.7
                  c0.7-0.3,1.5-0.3,2.1,0.2C34.9,39.1,35.2,39.7,35.2,40.5L35.2,40.5z"></path>
                  <path d="M3.6,45.7H2.4V47h1.2V45.7z"></path>
                  <path d="M18.8,34.8v-1.2h0.6c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4H17c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2h4.3v-1.2
                  h-2.4v-1.2h-1.2v1.2H17c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h2.4c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2h-4.3v1.2h2.4
                  v1.2H18.8z"></path>
                </g>
                <path d="M25.9,13.6v1.6c5,0.4,9,4.7,9,9.8c0,2.9-1.2,5.4-3.2,7.2v2.1c2.9-2.1,4.8-5.5,4.8-9.3C36.4,19,31.8,14,25.9,13.6z"></path>
                <path d="M49.1,24.1h-2.7c-0.2-5.2-2.3-10-5.6-13.6l1.9-1.9c0.3-0.3,0.3-0.9,0-1.3c-0.3-0.3-0.9-0.3-1.3,0l-1.9,1.9
                c-3.6-3.3-8.4-5.4-13.6-5.6V0.9C25.9,0.4,25.5,0,25,0s-0.9,0.4-0.9,0.9v2.7c-5.2,0.2-10,2.3-13.6,5.6L8.6,7.3C8.2,7,7.7,7,7.3,7.3
                C7,7.7,7,8.2,7.3,8.6l1.9,1.9c-3.3,3.6-5.4,8.4-5.6,13.6H0.9C0.4,24.1,0,24.5,0,25c0,0.5,0.4,0.9,0.9,0.9h2.7c0,1,0.2,1.9,0.3,2.8
                h1.8c-0.2-1.2-0.4-2.5-0.4-3.7C5.4,14.2,14.2,5.4,25,5.4S44.6,14.2,44.6,25c0,5.8-2.5,11-6.6,14.6V42c0.5-0.4,1-0.8,1.4-1.2l1.9,1.9
                c0.2,0.2,0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3c0.3-0.3,0.3-0.9,0-1.3l-1.9-1.9c3.3-3.6,5.4-8.4,5.6-13.6h2.7c0.5,0,0.9-0.4,0.9-0.9
                C50,24.5,49.6,24.1,49.1,24.1z"></path>
              </svg>
          `
        },
        {
          title:"Quality Support and Maintenance",
          text:"Real-time data analysis in CRM solutions offers grouping suspects and prospects into predefined filters for quality lead generation. Our CRM software development enables utilizing multiple interactive tools like bulk email, SMS, follow-ups and other lead prospects to maximize leads to customers ratio.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M19.5,40.3c-0.4-0.2-0.9-0.1-1.2,0.2l-4.1,6.3L12,42.2l-4.7,0l4.3-6.6c0.3-0.4,0.1-0.9-0.2-1.2c-0.4-0.3-0.9-0.1-1.2,0.2
                  l-6,9.2l6.8,0l3.1,6.4l5.7-8.8C20,41,19.9,40.5,19.5,40.3z"></path>
                  <path d="M39.8,34.6c-0.3-0.4-0.8-0.5-1.2-0.2c-0.4,0.3-0.5,0.8-0.2,1.2l4.3,6.6l-4.7,0l-2.3,4.6l-4.1-6.3c-0.3-0.4-0.8-0.5-1.2-0.2
                  c-0.4,0.3-0.5,0.8-0.2,1.2l5.8,8.8l3.1-6.4l6.8,0L39.8,34.6z"></path>
                </g>
                <path d="M7.5,31.6c0.4-1.4,0.6-2.6,1-3.7c0.2-0.6,0-1-0.5-1.3c-0.9-0.4-1.7-1-2.5-1.5c-1-0.6-1.1-0.8-0.3-1.8
                c0.6-0.8,1.2-1.6,1.8-2.4c0.4-0.4,0.4-0.8,0-1.2c-0.7-0.8-1.3-1.7-2-2.6c-0.5-0.8-0.5-1.1,0.3-1.5c0.9-0.5,1.8-1.1,2.7-1.6
                c0.6-0.3,0.7-0.7,0.5-1.3c-0.3-1-0.5-2-0.8-3c-0.2-0.9,0-1.1,0.8-1.3c1.1-0.2,2.2-0.4,3.3-0.5c0.5-0.1,0.7-0.3,0.8-0.8
                c0.1-1.1,0.3-2.2,0.5-3.2c0.2-0.9,0.4-1.1,1.4-0.9c1.1,0.3,2.1,0.5,3.2,0.8c0.4,0.1,0.7,0,1-0.4c0.5-1,1.1-1.9,1.7-2.9
                c0.5-0.8,0.8-0.9,1.6-0.3c0.8,0.6,1.7,1.2,2.5,1.9c0.5,0.4,0.9,0.5,1.5,0c0.8-0.7,1.7-1.3,2.5-1.9c0.7-0.5,1.1-0.4,1.5,0.3
                c0.6,0.9,1.1,1.9,1.6,2.8c0.2,0.5,0.6,0.6,1,0.4c1.1-0.3,2.2-0.5,3.3-0.8c0.7-0.2,1.1,0.1,1.2,0.8c0.2,1.1,0.4,2.2,0.5,3.2
                c0.1,0.6,0.2,0.9,0.9,0.9c1.1,0.1,2.2,0.3,3.3,0.5c0.7,0.1,0.9,0.5,0.7,1.1c-0.3,1.1-0.5,2.2-0.9,3.3c-0.1,0.5,0,0.8,0.5,1.1
                c1,0.5,1.9,1.1,2.9,1.7c0.7,0.4,0.7,0.9,0.3,1.5c-0.7,0.9-1.3,1.8-2,2.6c-0.4,0.5-0.4,0.8,0,1.3c0.7,0.8,1.3,1.6,1.9,2.5
                c0.6,0.9,0.6,1.1-0.4,1.7c-0.8,0.5-1.7,1-2.5,1.5c-0.6,0.3-0.8,0.6-0.6,1.3c0.3,1,0.5,2.1,0.8,3.2c0.2,0.7-0.1,1.1-0.8,1.2
                c-1.1,0.2-2.3,0.3-3.4,0.5c-0.5,0.1-0.7,0.3-0.7,0.7c-0.1,1.1-0.3,2.3-0.5,3.4c-0.1,0.7-0.5,1-1.2,0.8c-1.1-0.3-2.2-0.5-3.2-0.8
                c-0.5-0.2-0.9-0.1-1.1,0.5c-0.5,0.9-1,1.8-1.6,2.8c-0.5,0.9-0.8,0.9-1.6,0.3c-0.8-0.6-1.6-1.2-2.4-1.8c-0.6-0.5-1-0.4-1.6,0
                c-0.8,0.7-1.7,1.2-2.5,1.9c-0.6,0.5-1.1,0.4-1.4-0.3c-0.6-1-1.2-2-1.7-3c-0.2-0.4-0.5-0.5-1-0.4c-1.1,0.3-2.2,0.6-3.3,0.9
                c-0.7,0.2-1.1-0.1-1.2-0.8c-0.1-1.1-0.4-2.2-0.5-3.2c-0.1-0.6-0.2-0.8-0.9-0.9c-1.1-0.1-2.2-0.3-3.3-0.5C8,32.1,7.7,31.8,7.5,31.6z
                M28.7,1.6c-1,0.8-2,1.4-2.9,2.2c-0.6,0.5-1.1,0.5-1.7,0c-0.9-0.7-1.9-1.4-2.9-2.2c-0.7,1.1-1.3,2.1-1.9,3.2
                c-0.3,0.6-0.7,0.8-1.4,0.6c-0.9-0.3-1.8-0.4-2.8-0.7c-0.6-0.2-0.9-0.1-1,0.6C14.2,6.2,14,7,13.9,7.9c-0.1,0.8-0.4,1.2-1.2,1.2
                c-0.9,0.1-1.8,0.3-2.7,0.3c-0.6,0.1-0.8,0.3-0.6,0.9c0.3,0.8,0.4,1.7,0.7,2.5c0.3,0.9,0.1,1.5-0.8,1.9c-1,0.5-1.9,1.1-2.9,1.7
                c0.7,1,1.4,1.9,2,2.7c0.7,1,0.7,1,0,2C7.7,22.1,7,23,6.4,24c1.1,0.6,2,1.3,3,1.8c0.8,0.4,1,0.9,0.7,1.7c-0.3,0.9-0.4,1.8-0.7,2.7
                c-0.2,0.6,0,0.8,0.6,0.8c0.9,0.1,1.8,0.3,2.7,0.3c0.9,0.1,1.3,0.4,1.4,1.4c0.1,0.9,0.2,1.8,0.3,2.7c0.1,0.5,0.2,0.7,0.8,0.6
                c0.9-0.3,1.9-0.5,2.8-0.7c0.7-0.2,1.2,0,1.5,0.7c0.6,1,1.2,2,1.8,3.1c1-0.8,2-1.4,2.9-2.2c0.6-0.5,1.1-0.5,1.7,0
                c0.9,0.7,1.9,1.4,2.9,2.2c0.6-1.1,1.2-2,1.7-3c0.4-0.8,0.9-1.1,1.8-0.8c0.9,0.3,1.8,0.4,2.7,0.7c0.6,0.2,0.8,0,0.8-0.6
                c0.1-0.9,0.3-1.8,0.3-2.7c0.1-0.8,0.4-1.3,1.3-1.3c0.9-0.1,1.8-0.3,2.7-0.3c0.7-0.1,0.8-0.3,0.6-0.9c-0.3-0.9-0.4-1.7-0.7-2.6
                c-0.3-0.8,0-1.3,0.7-1.7c1-0.5,2-1.1,3-1.7c-0.7-1-1.4-1.9-2.1-2.8c-0.6-0.7-0.6-1.2,0-1.9c0.7-0.9,1.4-1.8,2.1-2.8
                c-1.1-0.7-2.2-1.3-3.2-1.9c-0.5-0.3-0.7-0.7-0.6-1.3c0.3-1,0.5-2,0.8-2.9c0.2-0.6,0-0.8-0.6-0.8c-0.9-0.1-1.8-0.3-2.7-0.3
                c-0.7-0.1-1.1-0.4-1.2-1.2c-0.1-0.9-0.3-1.8-0.3-2.7c0-0.8-0.4-0.9-1.1-0.7c-0.9,0.3-1.7,0.4-2.6,0.7c-0.7,0.2-1.2,0-1.5-0.6
                C30,3.7,29.4,2.7,28.7,1.6z"></path>
                <path d="M25,5.9c-0.4,0-0.7,0-1.2,0.1c0,0-0.1,0-0.1,0c-0.3,0.1-0.6,0.4-0.6,0.7c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0c0,0,0.1,0,0.1,0
                l0.3,0c0.3,0,0.6,0,0.8,0c7.1,0,12.8,5.8,12.8,12.8c0,7.1-5.8,12.8-12.8,12.8c-7.1,0-12.8-5.8-12.8-12.8c0-2.4,0.7-4.8,2-6.9
                c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.4,0.1-0.5,0.2c0,0,0,0-0.1,0.1c-1.5,2.3-2.3,5-2.3,7.7
                c0,7.9,6.4,14.3,14.3,14.3c7.9,0,14.3-6.4,14.3-14.3C39.3,12.4,32.9,5.9,25,5.9z"></path>
                <path d="M33.3,24.5c-0.7-0.7-1.4-1.4-2.1-2.1l-2.7-2.7l0.4-0.4c0.2-0.2,0.4-0.4,0.6-0.6c0,0,0,0,0.1,0c0.2,0,0.4,0,0.5,0.1l0.4,0.1
                l0-0.1c0.1,0,0.2,0,0.3,0c1.2-0.2,2.1-0.7,2.7-1.7c0.6-1,0.8-2,0.5-3.2c-0.1-0.3-0.3-0.4-0.4-0.4c-0.1,0-0.3,0-0.5,0.2
                c-0.4,0.4-0.8,0.8-1.2,1.2c0,0,0,0-0.1,0c-0.3-0.1-0.7-0.2-1-0.4c0,0-0.1,0-0.1-0.1c-0.1-0.3-0.2-0.7-0.3-1c0,0,0-0.1,0-0.1
                c0.4-0.4,0.8-0.8,1.2-1.2c0.2-0.2,0.2-0.3,0.2-0.5c0-0.2-0.2-0.3-0.4-0.4c-0.9-0.2-1.8-0.2-2.7,0.2c-1.6,0.7-2.5,2.5-2.2,4.3
                c0,0,0,0,0,0c-0.4,0.4-0.8,0.8-1.2,1.2c-0.3,0.3-0.6,0.6-0.9,0.9c-0.7-0.7-1.4-1.4-2.1-2.1l-1-1c-0.2-0.2-0.5-0.5-0.7-0.7
                c-0.2-0.2-0.5-0.3-0.7-0.1c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.2-0.4
                c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.2-0.3-0.3-0.4c-0.4-0.3-0.9-0.6-1.3-0.9l-0.2-0.2c-0.3-0.2-0.5-0.1-0.8,0.1
                c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.5-0.1,0.8c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.2,0.3,0.4,0.4,0.7c0.2,0.4,0.6,0.8,1.1,1
                c0.4,0.1,0.7,0.4,0.9,0.7c-0.1,0.3-0.1,0.5,0.2,0.7l0.7,0.7c1,1,2,2,3,3c0,0,0,0,0,0c0,0,0,0,0,0c-1.2,1.2-2.4,2.4-3.6,3.6
                c0,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c-0.5,0-0.9,0.1-1.3,0.3c-1.4,0.7-2,2.2-1.5,3.7c0.4,1.2,1.6,2,2.9,2c0.2,0,0.3,0,0.5,0
                c1.5-0.3,2.5-1.5,2.5-3c0,0,0-0.1,0.1-0.1c0.7-0.7,1.4-1.4,2.1-2.1l0.4-0.4c0,0,0,0,0,0c0,0,0,0,0,0l1.2,1.2
                c1.2,1.2,2.4,2.4,3.5,3.5c0.7,0.7,1.6,1,2.6,0.8c1.2-0.2,1.9-0.9,2.3-2C34.4,26.3,34.1,25.3,33.3,24.5z M23.3,19l-0.1-0.1
                c-0.2-0.2-0.4-0.4-0.5-0.6c-0.5-0.5-1.1-1.1-1.6-1.6l-0.8-0.8c-0.1-0.1-0.3-0.3-0.4-0.4c0.1-0.1,0.1-0.2,0.1-0.3
                c0.1,0,0.2,0,0.3-0.1c0.2,0.2,0.5,0.5,0.7,0.7l2.7,2.8L23.3,19z M18.5,13.8c-0.1-0.1-0.3-0.1-0.4-0.2l-0.1,0
                c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.3-0.5-0.5-0.7L17,12.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0.1,0.1,0.1,0.1
                c0,0.1,0.1,0.1,0.1,0.2C18.4,13.4,18.4,13.6,18.5,13.8z M27.6,15.6c-0.2-0.8-0.1-1.6,0.3-2.2c0.4-0.7,1.1-1.1,1.8-1.3
                c0.2,0,0.3,0,0.5-0.1l-0.6,0.6c-0.4,0.4-0.4,0.4-0.2,1c0,0.1,0.1,0.3,0.1,0.4c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.2,0.3,0.4,0.4
                c0.4,0.1,0.7,0.3,1.1,0.4l0.5,0.2c0.2,0.1,0.4,0,0.6-0.2c0.2-0.2,0.4-0.5,0.7-0.7l0.1-0.1c0,1.1-0.7,2.1-1.7,2.5
                c-0.6,0.3-1.3,0.3-1.9,0.2c-0.3-0.1-0.5,0-0.7,0.2c-2.6,2.6-5.2,5.2-7.8,7.8c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.7-0.3,1.3-0.9,1.7
                c-0.7,0.5-1.8,0.5-2.4-0.1c-0.9-0.8-0.9-1.9-0.4-2.5c0.4-0.7,1-1,1.7-1c0.1,0,0.1,0,0.2,0c0.2,0,0.4-0.1,0.5-0.2
                c1.1-1.1,2.1-2.1,3.2-3.2l1.8-1.8c1-1,1.9-1.9,2.9-2.9C27.6,16.1,27.6,15.9,27.6,15.6z M27.8,20.4l0.2,0.3c0.2,0.2,0.3,0.4,0.5,0.6
                c0.7,0.7,1.3,1.3,2,2l1.8,1.8c0.2,0.2,0.5,0.5,0.6,0.8c0.4,0.8,0.2,1.8-0.5,2.3c-0.8,0.6-1.8,0.5-2.5-0.1c-1.1-1.1-2.3-2.2-3.4-3.4
                l-1.4-1.4L27.8,20.4z"></path>
              </svg>
          `
        },
        {
          title:"Maintaining Data Confidentiality",
          text:"Your data is your biggest asset and we understand it the best way by signing the NDA right before starting the project. This means, all your business information stay secure as our CRM implementation team maintains confidentiality at every stage.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M10.2,48.5c12.6,0,25.1,0,37.7,0c0-7.2,0-14.3,0-21.5c0.5,0,0.9,0,1.4-0.1c0,7.7,0,15.3,0,23c-13.5,0-27,0-40.6,0
                  c0-9.2,0-18.5,0-27.8c7.9,0,15.9,0,23.9,0c0,0.4,0,0.9,0,1.4c-7.5,0-14.9,0-22.4,0C10.2,31.9,10.2,40.2,10.2,48.5z"></path>
                  <path d="M44.4,28.5c0,2.8,0,5.5,0,8.2c-10.3,0-20.5,0-30.8,0c0-2.7,0-5.4,0-8.2C23.8,28.5,34,28.5,44.4,28.5z M15,35.3
                  c9.3,0,18.6,0,27.8,0c0-1.8,0-3.5,0-5.3c-9.3,0-18.5,0-27.8,0C15,31.8,15,33.5,15,35.3z"></path>
                  <path d="M35.1,13.8c0.5,0,0.9-0.1,1.5-0.1c-0.1-2.2,0.4-4.3,2.2-5.5c1.1-0.7,2.5-1.2,3.7-1.2c3.2,0,5.2,2.6,5.4,6.7
                  c0.6,0,1.2,0.1,1.8,0.1c0,2.7,0.2,5.4-0.1,8c-0.2,2.5-2.5,4.1-5,4.2c-1.5,0-2.9,0-4.4,0c-2.9,0-5.1-2.3-5.2-5.1
                  C35.1,18.5,35.1,16.2,35.1,13.8z M36.6,15.3c0,2-0.1,4,0,5.9c0.1,1.9,1.4,3.3,3.4,3.4c1.7,0.1,3.4,0.1,5,0c1.7-0.1,3.2-1.2,3.3-2.9
                  c0.2-2.1,0-4.2,0-6.4C44.4,15.3,40.6,15.3,36.6,15.3z M46.4,13.7c0.2-2.1-0.2-3.8-2.1-4.8c-1.6-0.8-3.2-0.7-4.6,0.4
                  c-1.5,1.1-1.8,2.7-1.5,4.5C40.9,13.7,43.6,13.7,46.4,13.7z"></path>
                  <path d="M28.7,45.2c-2.2,0-4.3,0-6.5,0c-1.6,0-2.3-0.8-2.3-2.6c0-2,0.6-2.8,2.2-2.8c4.4,0,8.8,0,13.2,0c1.4,0,2.2,0.8,2.2,2.2
                  c0,0.4,0,0.7,0,1.1c0,1.3-0.8,2.1-2.2,2.1C33.1,45.2,30.9,45.2,28.7,45.2z M36,41.3c-5,0-9.8,0-14.6,0c0,0.8,0,1.5,0,2.3
                  c4.9,0,9.7,0,14.6,0C36,42.8,36,42.1,36,41.3z"></path>
                  <path d="M19.4,33.3c-0.7,0-1.3,0-1.9,0c0-0.5,0-0.9,0-1.4c0.6,0,1.1,0,1.8,0C19.4,32.4,19.4,32.8,19.4,33.3z"></path>
                  <path d="M23.2,33.4c-0.6,0-1.2,0-1.8,0c0-0.4,0-0.9-0.1-1.4c0.7,0,1.2,0,1.9,0C23.2,32.4,23.2,32.8,23.2,33.4z"></path>
                  <path d="M27.2,33.4c-0.6,0-1.2,0-1.8,0c0-0.4,0-0.9-0.1-1.4c0.7,0,1.2,0,1.9,0C27.2,32.4,27.2,32.8,27.2,33.4z"></path>
                  <path d="M41.5,23.1c0-1.2,0-2.3,0-3.4c0.5,0,0.9,0,1.4,0c0,1.1,0,2.1,0,3.3C42.5,23,42.1,23,41.5,23.1z"></path>
                  <path d="M45.6,0C40,0,34.4,0,28.8,0c-0.4,0-0.9,0.4-1.1,0.7c-1.7,2.2-3.3,4.4-4.9,6.6c-0.5,0.7-1.1,1-2,1c-6.4,0-12.8,0-19.1,0
                  c-0.4,0-0.9,0-1.4,0c0,10.4,0,20.6,0,30.9c0,0,0,0,0,0v1.5c2.1,0,4.3,0,6.5,0c0-0.5,0-0.9-0.1-1.4c-1.7,0-3.2,0-4.8,0
                  c0-9.8,0-19.6,0-29.4c0.5,0,1,0,1.4,0c6,0,12,0,18,0c1.1,0,1.8-0.4,2.4-1.2c1.5-2.1,3.1-4.1,4.6-6.2c0.5-0.7,1-0.9,1.8-0.9
                  c3.3,0.1,6.5,0,9.8,0c1.5,0,3,0,4.6,0c0,1.5,0,2.9,0,4.4c0.6,0,1,0,1.4-0.1c0-2,0-3.9,0-5.7C45.7,0.1,45.6,0,45.6,0z"></path>
                </g>
              </svg>
          `
        },
        {
          title:"Faster Implementation",
          text:"The robustness of any custom CRM software development lies in its agility. With us, you are kept in constant loop for each of the implementation sprint. We implement the functionality phase-wise to offer timely delivery without compromising the quality.",
          svg:`
            <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <g>
                  <path d="M4.2,50h37.5c0.5,0,0.8-0.4,0.8-0.8V27.5h-1.7v20.8H4.2c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h1.7c0.5,0,0.8-0.4,0.8-0.8
                  v-25H20v-1.7H6.7V10c0-0.5-0.4-0.8-0.8-0.8H4.2C1.9,9.2,0,11,0,13.3v32.5C0,48.1,1.9,50,4.2,50z M1.7,16.7v-3.3
                  c0-1.4,1.1-2.5,2.5-2.5H5v30.8H4.2c-0.9,0-1.8,0.3-2.5,0.8V16.7z"></path>
                  <path d="M19.2,20h-10c-0.5,0-0.8,0.4-0.8,0.8v6.7c0,0.5,0.4,0.8,0.8,0.8h10c0.5,0,0.8-0.4,0.8-0.8V25h3.3v-1.7H20v-2.5
                  C20,20.4,19.6,20,19.2,20z M18.3,26.7H10v-5h8.3V26.7z"></path>
                  <path d="M27.5,43.3h10c0.5,0,0.8-0.4,0.8-0.8v-6.7c0-0.5-0.4-0.8-0.8-0.8h-4.2v-5h-1.7v5h-4.2c-0.5,0-0.8,0.4-0.8,0.8v2.5H20v-2.5
                  c0-0.5-0.4-0.8-0.8-0.8h-10c-0.5,0-0.8,0.4-0.8,0.8v6.7c0,0.5,0.4,0.8,0.8,0.8h10c0.5,0,0.8-0.4,0.8-0.8V40h6.7v2.5
                  C26.7,43,27,43.3,27.5,43.3z M28.3,36.7h8.3v5h-8.3V36.7z M18.3,41.7H10v-5h8.3V41.7z"></path>
                  <path d="M49.2,17.5c0.5,0,0.8-0.4,0.8-0.8v-5c0-0.5-0.4-0.8-0.8-0.8h-3c-0.2-0.5-0.4-1.1-0.7-1.6l2.2-2.1c0.2-0.2,0.2-0.4,0.2-0.6
                  c0-0.2-0.1-0.4-0.2-0.6l-3.5-3.5c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2l-2.1,2.2c-0.5-0.3-1-0.5-1.6-0.7v-3
                  c0-0.5-0.4-0.8-0.8-0.8h-5c-0.5,0-0.8,0.4-0.8,0.8v3C32,4,31.4,4.3,30.9,4.5l-2.1-2.2c-0.2-0.2-0.4-0.2-0.6-0.2
                  c-0.2,0-0.4,0.1-0.6,0.2L24,5.9c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6l2.2,2.1c-0.3,0.5-0.5,1-0.7,1.6h-3
                  c-0.5,0-0.8,0.4-0.8,0.8v5c0,0.5,0.4,0.8,0.8,0.8h3c0.2,0.5,0.4,1.1,0.7,1.6L24,21.2c-0.2,0.2-0.2,0.4-0.2,0.6
                  c0,0.2,0.1,0.4,0.2,0.6l3.5,3.5c0.3,0.3,0.9,0.3,1.2,0l2.1-2.1c0.5,0.3,1,0.5,1.6,0.7v3c0,0.5,0.4,0.8,0.8,0.8h5
                  c0.5,0,0.8-0.4,0.8-0.8v-3c0.5-0.2,1.1-0.4,1.6-0.7l2.1,2.1c0.3,0.3,0.9,0.3,1.2,0l3.5-3.5c0.2-0.2,0.2-0.4,0.2-0.6
                  s-0.1-0.4-0.2-0.6l-2.2-2.1c0.3-0.5,0.5-1,0.7-1.6H49.2z M44.7,16.5c-0.2,0.8-0.5,1.6-1,2.4c-0.2,0.3-0.1,0.7,0.1,1l2,2l-2.4,2.4
                  l-2-2c-0.3-0.3-0.7-0.3-1-0.1c-0.7,0.4-1.5,0.8-2.4,1c-0.4,0.1-0.6,0.4-0.6,0.8v2.8h-3.3v-2.8c0-0.4-0.3-0.7-0.6-0.8
                  c-0.8-0.2-1.6-0.5-2.4-1c-0.3-0.2-0.7-0.1-1,0.1l-2,2l-2.4-2.4l2-2c0.3-0.3,0.3-0.7,0.1-1c-0.4-0.7-0.8-1.5-1-2.4
                  c-0.1-0.4-0.4-0.6-0.8-0.6h-2.8v-3.3h2.8c0.4,0,0.7-0.3,0.8-0.6c0.2-0.8,0.5-1.6,1-2.4c0.2-0.3,0.1-0.7-0.1-1l-2-2l2.4-2.4l2,2
                  c0.3,0.3,0.7,0.3,1,0.1c0.7-0.4,1.5-0.8,2.4-1c0.4-0.1,0.6-0.4,0.6-0.8V1.7h3.3v2.8c0,0.4,0.3,0.7,0.6,0.8c0.8,0.2,1.6,0.5,2.4,1
                  c0.3,0.2,0.7,0.1,1-0.1l2-2l2.4,2.4l-2,2c-0.3,0.3-0.3,0.7-0.1,1c0.4,0.7,0.8,1.5,1,2.4c0.1,0.4,0.4,0.6,0.8,0.6h2.8v3.3h-2.8
                  C45.1,15.8,44.8,16.1,44.7,16.5z"></path>
                  <path d="M35.8,10.8c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3S37.7,10.8,35.8,10.8z M35.8,15.8
                  c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7s1.7,0.7,1.7,1.7S36.8,15.8,35.8,15.8z"></path>
                </g>
              </svg>
          `
        },
      ]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
