<template>
  <div class="web-why-choose-us-inner-box mhbox">
                  <div class="web-why-choose-inner-icon" v-html="obj.svg">
                  </div>
                  <h5>{{obj.title}}</h5>
                  <p>{{obj.text}}</p>
               </div>
</template>

<script>
export default {
    props:{
        obj : {
            type : Object,
            default : () => {}
        }
    }
}
</script>

<style>
.web-why-choose-us-inner-box {
	border: 1px solid #ecedee;
	-webkit-box-shadow: 0 1px 5px 0 rgba(72,73,121,.15);
	-moz-box-shadow: 0 1px 5px 0 rgba(72,73,121,.15);
	box-shadow: 0 1px 5px 0 rgba(72,73,121,.15);
	margin-top: 50px;
	border-radius: 10px;
	padding: 20px 20px 20px;
	background-color: #fff;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.m-height-170 .mhbox {
	min-height: 170px;
}
.web-why-choose-inner-icon {
	width: 75px;
	height: 75px;
	-webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	-moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	box-shadow: 0 3px 10px 0 rgba(0,0,0,.1);
	border-radius: 100%;
	line-height: 75px;
	text-align: center;
	margin-top: -57px;
	background-color: #fff;
	float: left;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.web-why-choose-us-inner-box h5 {
	font-size: 18px;
	font-weight: 500;
	width: calc(100% - 75px);
	float: left;
	padding-left: 10px;
	margin-bottom: 15px;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}
.web-why-choose-us-inner-box:hover {
	border:1px solid #008dd2;
}
</style>