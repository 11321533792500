<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why should you invest?</span>
				<h2>The Advantages of our Taxi App Development</h2>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M50.7,73.6l-2.5,0.5v8.7c0,0.5-0.7,1.2-1.7,1.2h-4.9c-1,0-1.7-0.7-1.7-1.2v-8.7l-2.5-0.5c-3.5-0.8-7.1-2.2-10.1-4.2
                    l-2.2-1.3L23.3,70L19,74.3c-0.2,0.2-0.3,0.2-0.7,0.2c-0.5,0-1-0.2-1.3-0.7l-3.5-3.3c-0.7-0.7-0.8-1.7-0.3-2l4.4-4.3l1.8-1.8
                    l-1.3-2.2c-2-3-3.4-6.5-4.2-10l-0.5-2.5H4.3c-0.5,0-1.2-0.7-1.2-1.7v-4.8c0-1,0.7-1.7,1.2-1.7h8.7l0.5-2.5c0.8-3.5,2.2-6.8,4.2-10
                    l1.3-2.2L17.4,23l-4.4-4.3c-0.3-0.3-0.3-1.3,0.3-2l3.4-3.5c0.3-0.3,0.8-0.7,1.3-0.7c0.3,0,0.5,0,0.7,0.2l4.4,4.3l1.8,1.8l2.2-1.3
                    c3.2-2,6.5-3.3,10.1-4.2l2.5-0.3V4.3c0-0.5,0.7-1.2,1.7-1.2h4.9c1,0,1.7,0.7,1.7,1.2V13l2.5,0.5c3.5,0.8,7.1,2.2,10.1,4.2l2.2,1.3
                    l1.8-1.8l4.4-4.3c0.2-0.2,0.3-0.2,0.7-0.2c0.5,0,1,0.2,1.3,0.7l3.5,3.5c0.7,0.7,0.8,1.7,0.3,2L70.7,23l-1.8,1.8l1.3,2.2
                    c2,3,3.4,6.5,4.2,10l0.5,2.5h8.7c0.5,0,1.2,0.7,1.2,1.7v4.8c0,1-0.7,1.7-1.2,1.7h-8.7l-0.5,2.5c-0.5,2.2-1.2,4.3-2.2,6.3l2.4,2.2
                    c1.2-2.5,2.2-5,2.9-7.8h6.2c2.3,0,4.4-2.2,4.4-4.8v-4.8c0-2.7-2-4.8-4.4-4.8h-6.2c-0.8-4-2.5-7.7-4.5-11l4.4-4.3
                    c1.7-1.7,1.5-4.5-0.3-6.5L73.3,11c-1-1-2.4-1.5-3.7-1.5c-1,0-2.2,0.3-2.9,1.2L62.4,15c-3.4-2.2-7.1-3.7-11.1-4.5V4.3
                    C51.4,2,49.2,0,46.5,0h-4.9c-2.7,0-4.9,2-4.9,4.3v6.2c-4,0.8-7.7,2.5-11.1,4.5l-4.4-4.3c-0.8-0.8-1.8-1.2-2.9-1.2
                    c-1.3,0-2.5,0.5-3.7,1.5l-3.7,3.5c-1.8,1.8-2,4.8-0.3,6.5l4.4,4.2c-2.2,3.3-3.7,7-4.5,11H4.3C2,36.2,0,38.4,0,41.1v4.8
                    c0,2.7,2,4.8,4.4,4.8h6.2c0.8,4,2.5,7.8,4.5,11l-4.4,4.3c-1.7,1.7-1.5,4.5,0.3,6.5l3.5,3.5c1,1,2.4,1.5,3.7,1.5
                    c1,0,2.2-0.3,2.9-1.2l4.4-4.3c3.4,2.2,7.1,3.7,11.1,4.5v6.2c0,2.3,2.2,4.3,4.9,4.3h4.9c2.7,0,4.9-2,4.9-4.3v-6.2
                    c2.4-0.5,4.7-1.3,6.9-2.3l-2.2-2.5C54.4,72.6,52.5,73.3,50.7,73.6z M87.1,89c0.2,0.3,0.5,0.5,1,0.5c0.3,0,0.8-0.2,1.2-0.5
                    c0.7-0.7,0.7-1.7,0-2.2l-9.4-9.3c-0.7-0.7-1.7-0.7-2.2,0c-0.7,0.7-0.7,1.7,0,2.2L87.1,89z M98.7,84.8L62.3,51.6
                    c1-2.3,1.7-5,1.7-7.8c0-10.7-8.7-19.5-19.6-19.5c-4.5,0-8.9,1.5-12.1,4.2l11.6,11.5c0.3,0.3,0.7,0.5,1.2,0.5
                    c0.5,0,0.8-0.2,1.2-0.5c0.7-0.7,0.7-1.7,0-2.2l-8.9-8.7c2.2-1,4.5-1.7,7.2-1.7c9.1,0,16.5,7.3,16.5,16.4c0,3.2-0.8,6-2.4,8.5
                    l2.4,2.2l35.6,32.2c0.5,0.5,0.5,1.3,0,1.8L89,96c-0.5,0.5-1,0.5-1.2,0.5c-0.2,0-0.5,0-0.8-0.3L54,60.6l-0.2-0.2l-1-1l-1.2-1.2
                    c-2.2,1.2-4.9,1.8-7.6,1.8c-9.1,0-16.5-7.3-16.5-16.4c0-2.2,0.5-4.2,1.2-6l8.6,8.5c0.3,0.3,0.7,0.5,1.2,0.5c0.3,0,0.8-0.2,1.2-0.5
                    c0.7-0.7,0.7-1.7,0-2.2L28.2,32.7c-2.2,3.2-3.5,7-3.5,11.2c0,10.7,8.7,19.5,19.6,19.5c2.4,0,4.7-0.5,6.9-1.2l33.6,36.4
                    c0.8,0.8,2,1.3,3.2,1.3c1.2,0,2.4-0.5,3.2-1.3l7.6-7.5C100.4,89.5,100.5,86.6,98.7,84.8z"
							></path>
						</g>
					</g>
				</svg>`,
					title: '100% customizable',
					text: 'Customize the app with your own brand presets like colour, fonts, themes and much more.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						d="M86.8,37.2l12.7-12.9c0.3-0.3,0.7-1,0.5-1.5c0-0.5-0.3-1-0.8-1.4l-34.3-20c-0.7-0.3-1.7-0.3-2.2,0.3L50,14.7L37.3,1.8
                c-0.7-0.5-1.5-0.7-2.2-0.3l-34.3,20C0.3,21.8,0,22.3,0,22.8c0,0.5,0.2,1.2,0.5,1.5l12.7,12.9L0.5,50C0.2,50.4,0,50.9,0,51.5
                c0,0.5,0.3,1,0.8,1.4l13,7.6v16.6c0,0.7,0.3,1.2,0.8,1.5l34.2,20c0.3,0.2,0.7,0.2,0.8,0.2c0.3,0,0.7,0,0.8-0.2l34.2-20
                c0.5-0.3,0.8-0.8,0.8-1.5V60.5l13-7.6c0.5-0.3,0.8-0.8,0.8-1.4c0-0.5-0.2-1.2-0.5-1.5L86.8,37.2L86.8,37.2z M80.6,37.2L50,54.9
                L19.3,37.2l23-13.4l7.7-4.4l27.7,16.1L80.6,37.2L80.6,37.2z M64.3,5.4l31,18.1L84,34.8l-1.8-1L53,16.9L64.3,5.4L64.3,5.4z M35.7,5.4
                L47,16.9L16,34.8L4.7,23.3L35.7,5.4L35.7,5.4z M16,39.4l31,18.1L35.7,69l-19-11.2l0,0l-12-6.9L16,39.4L16,39.4z M17.5,62.5L35,72.7
                c0.3,0.2,0.7,0.2,0.8,0.2c0,0,0,0,0.2,0c0.5,0,0.8-0.2,1.2-0.5l10.8-11v32.3L17.5,75.9V62.5L17.5,62.5z M82.3,75.9L51.8,93.8V61.5
                l10.8,11c0.3,0.3,0.7,0.5,1.2,0.5c0,0,0,0,0.2,0c0.3,0,0.7,0,0.8-0.2l17.5-10.1V75.9L82.3,75.9z M64.3,69L53,57.5l31-18.1l11.3,11.5
                L64.3,69L64.3,69z"
					></path>
				</svg>`,
					title: 'White Label Solution',
					text: 'A white label solution that can showcase the app as your own without any watermarks or logo restrictions.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M50,8.3c-20.9,0-37.9,17.2-37.9,38.2c0,21,17,38.4,37.9,38.4s37.9-17.2,37.9-38.4C87.9,25.3,70.9,8.3,50,8.3z M44.4,12.2
                    c-4.6,4-8.3,8.9-10.9,14.5c-2.8-1.3-5.4-3-7.8-4.8C30.9,16.7,37.4,13.3,44.4,12.2z M23.5,24.3c2.6,2,5.6,3.8,8.7,5.3
                    c-2,4.8-2.9,10-3.1,15.4H15.5C15.9,37.4,18.6,30.2,23.5,24.3z M23,68.4c-4.6-5.7-7.2-12.9-7.7-20.2h13.6c0.2,5.2,1.1,10.2,3.1,15
                    C28.9,64.6,25.8,66.4,23,68.4z M25.3,71.1c2.5-1.8,5.2-3.5,8.2-4.7c2.6,5.7,6.4,10.7,11.1,14.7C37.1,80,30.4,76.4,25.3,71.1z
                    M48.4,80c-5.1-4-9-9-11.8-14.9c3.8-1.2,7.7-2,11.8-2V80z M48.4,59.7c-4.4,0.2-9,1-13.2,2.5c-1.6-4.5-2.6-9.2-2.8-14h16V59.7z
                    M48.4,44.9h-16c0.2-4.8,1.1-9.5,2.9-14c4.1,1.7,8.5,2.5,13.1,2.7V44.9z M48.4,30.2c-4.1-0.2-8-1-11.8-2.3
                    c2.8-5.7,6.7-10.7,11.8-14.5V30.2z M74.2,21.7c-2.5,2-5.1,3.5-7.8,4.8C63.7,21,60,16.2,55.4,12.2C62.6,13.3,69.1,16.7,74.2,21.7z
                    M51.6,13.3c4.9,3.8,9,8.9,11.8,14.7c-3.8,1.3-7.8,2-11.8,2.2V13.3z M64.9,30.9c1.8,4.5,2.8,9.2,2.9,14h-16V33.5h-0.2
                    C56.2,33.4,60.6,32.5,64.9,30.9z M51.6,48.2h16c-0.2,4.7-1.1,9.5-2.9,13.9c-4.2-1.3-8.7-2.2-13.1-2.5V48.2z M51.6,80V63.1
                    c4.1,0.2,8,0.8,11.8,2.2C60.6,70.9,56.5,75.9,51.6,80z M55.4,81.1c4.6-4.2,8.3-9.2,11.1-14.7c2.9,1.2,5.6,2.8,8.2,4.7
                    C69.4,76.4,62.7,80,55.4,81.1z M76.8,68.6c-2.8-2.2-5.7-3.8-9-5.2c2-4.8,2.9-10,3.1-15.2h13.6C84.1,55.6,81.5,62.8,76.8,68.6z
                    M70.9,44.9c-0.2-5.3-1.3-10.5-3.1-15.4c3.1-1.5,6-3.3,8.7-5.5c4.9,5.8,7.7,13.2,8.2,20.9H70.9z M93.6,3.5c-0.5-0.2-1-0.3-1.5,0
                    C80.9,8.1,67,7,50.7,0.1c-0.5-0.2-0.8-0.2-1.3,0C33,7.1,19,8.3,7.8,3.5c-0.5-0.3-1.1-0.2-1.6,0C5.7,3.8,5.6,4.3,5.6,5v40.4
                    C5.6,90,49.2,99.8,49.7,100h0.7c0.5-0.2,44.1-10,44.1-54.6V5C94.4,4.3,94.1,3.8,93.6,3.5z M91.2,45.4c0,40.4-36.9,50.3-41.2,51.3
                    c-4.2-1-41.2-10.9-41.2-51.3V7.3c11.4,4,25.3,2.7,41.2-3.8c15.8,6.7,29.6,8,41.2,4V45.4z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'High-end Security',
					text: 'Our taxi app developers build encryption based security system that prevents unauthorized access of all forms.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 100 100"
					style="enable-background: new 0 0 100 100"
					xml:space="preserve"
				>
					<path
						d="M49.4,0c-0.3,0-0.5,0.2-0.8,0.3C34.3,7.8,24.5,27.4,24.5,50c0,2.8,0.2,5.7,0.5,8.5L6,73.2c-0.5,0.5-0.8,1.2-0.8,1.8v20.5
                c0,1.3,1,2.3,2.4,2.3c0.2,0,0.5,0,0.7-0.2l28.5-8.7c3.5,4.7,7.7,8.3,12.3,10.7c0.7,0.3,1.5,0.3,2.2,0c4.5-2.5,8.8-6.2,12.3-10.7
                l28.5,8.7c1.2,0.3,2.5-0.3,2.9-1.5c0-0.2,0.2-0.5,0.2-0.7V75.1c0-0.7-0.3-1.3-0.8-1.8L75,58.5c0.3-2.8,0.5-5.7,0.5-8.5
                c0-22.7-9.9-42.2-24.1-49.7C50.3,0.2,49.7,0,49.4,0z M49.6,5c11.6,6.8,20.7,24.4,20.7,45c0,20.7-9.1,38.2-20.7,45
                C38,88.2,28.9,70.7,28.9,50C29,29.4,38,11.8,49.6,5z M49.6,21.7c-5.7,0-10.3,4.7-10.3,10.2C39.3,37.5,44,42,49.6,42
                s10.3-4.7,10.3-10.2C59.9,26.2,55.3,21.7,49.6,21.7z M49.6,26.2c3.2,0,5.7,2.5,5.7,5.7c0,3.2-2.5,5.7-5.7,5.7s-5.7-2.5-5.7-5.7
                C43.9,28.7,46.4,26.2,49.6,26.2z M25.7,63.7c1.5,8,4.2,15.3,7.9,21.4L9.5,92.4V76.2L25.7,63.7z M73.7,63.7l16.2,12.3v16.2l-24.1-7.3
                C69.4,78.9,72.1,71.7,73.7,63.7z"
					></path>
				</svg>`,
					title: 'Ready to Market',
					text: 'The taxi app development solution has completed testing stage and can be readily deployed post customization.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 60 60">
					<path
						fill="#222222"
						d="M32.4,48.6h-5.1v-5.1h5.1V48.6z M28.7,47.2h2.4v-2.4h-2.4V47.2z"
					></path>
					<path
						fill="#222222"
						d="M41.3,59.3h-5.1v-5.1h5.1V59.3z M37.5,58H40v-2.4h-2.4L37.5,58L37.5,58z"
					></path>
					<path
						fill="#222222"
						d="M47,48.7c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6S48.4,48.7,47,48.7z M47,44.7
                c-0.7,0-1.3,0.5-1.3,1.3c0,0.7,0.5,1.3,1.3,1.3c0.7,0,1.3-0.5,1.3-1.3C48.2,45.3,47.7,44.7,47,44.7z"
					></path>
					<rect
						x="31.7"
						y="45.3"
						fill="#222222"
						width="13.3"
						height="1.3"
					></rect>
					<path
						fill="#222222"
						d="M39.4,54.9h-1.3c0-3.9-2.7-7.2-6.5-8l0.3-1.3C36.2,46.5,39.4,50.4,39.4,54.9z"
					></path>
					<path
						fill="#222222"
						d="M23.5,59.3h-5.1v-5.1h5.1V59.3z M19.8,58h2.4v-2.4h-2.4V58z"
					></path>
					<path
						fill="#222222"
						d="M12.7,48.7c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6C15.4,47.5,14.1,48.7,12.7,48.7z
                M12.7,44.7c-0.7,0-1.3,0.5-1.3,1.3c0,0.7,0.5,1.3,1.3,1.3S14,46.8,14,46C14,45.3,13.4,44.7,12.7,44.7z"
					></path>
					<rect
						x="14.6"
						y="45.3"
						fill="#222222"
						width="13.3"
						height="1.3"
					></rect>
					<path
						fill="#222222"
						d="M21.6,54.9h-1.3c0-4.5,3.1-8.4,7.5-9.3l0.3,1.3C24.3,47.7,21.6,51,21.6,54.9z"
					></path>
					<path
						fill="#222222"
						d="M8.1,24.8c-1-1.3-1.5-2.9-1.5-4.5c0-3.3,2.2-6.2,5.4-7c0.3-1.5,1.4-6.1,6.8-6.1c1,0,1.9,0.2,2.8,0.5L21,8.9
                c-0.7-0.3-1.4-0.4-2.2-0.4c-5.1,0-5.5,5.1-5.5,5.3v0.5l-0.5,0.1C10,15,8,17.4,8,20.3c0,1.3,0.4,2.6,1.3,3.7L8.1,24.8z"
					></path>
					<path
						fill="#222222"
						d="M50.8,24.8l-1-0.8c1.3-1.6,2.1-3.7,2.1-5.7c0-5-4-9.1-9.1-9.1c-0.7,0-2.1,0.3-2.5,0.4l-0.6,0.2l-0.2-0.7
                C38.6,5,34.8,2,30.6,2c-4.1,0-7.7,2.8-8.8,6.7c-0.2,0.8-0.3,1.5-0.3,2.3h-1.3c0-0.9,0.1-1.8,0.4-2.7c1.3-4.6,5.4-7.7,10.1-7.7
                s8.6,3,9.9,7.4c0.5-0.2,1.5-0.4,2.1-0.4c5.7,0,10.4,4.7,10.4,10.4C53.2,20.5,52.4,22.9,50.8,24.8z"
					></path>
					<path
						fill="#222222"
						d="M41.1,41H18.5v-7.2h22.6V41z M19.8,39.7h19.9v-4.5H19.8V39.7z"
					></path>
					<path
						fill="#222222"
						d="M38.3,38.1H37v-1.3h1.3V38.1z M35.3,38.1H34v-1.3h1.3V38.1z M32.3,38.1H31v-1.3h1.3V38.1z"
					></path>
					<rect
						x="21.9"
						y="36.8"
						fill="#222222"
						width="6.1"
						height="1.3"
					></rect>
					<path
						fill="#222222"
						d="M41.1,30.9H18.5v-7.2h22.6V30.9z M19.8,29.5h19.9V25H19.8V29.5z"
					></path>
					<path
						fill="#222222"
						d="M38.3,28H37v-1.3h1.3V28z M35.3,28H34v-1.3h1.3V28z M32.3,28H31v-1.3h1.3V28z"
					></path>
					<rect
						x="21.9"
						y="26.6"
						fill="#222222"
						width="6.1"
						height="1.3"
					></rect>
					<rect
						x="36.8"
						y="30.1"
						fill="#222222"
						width="1.3"
						height="4.3"
					></rect>
					<rect
						x="21.5"
						y="30.2"
						fill="#222222"
						width="1.3"
						height="4.3"
					></rect>
					<path
						fill="#222222"
						d="M59.3,41.1H42.8v-7.2h16.4v7.2H59.3z M44.2,39.8h13.7v-4.5H44.2V39.8z"
					></path>
					<path
						fill="#222222"
						d="M54.6,38.1h-1.3v-1.3h1.3V38.1z M51.7,38.1h-1.3v-1.3h1.3V38.1z M48.7,38.1h-1.3v-1.3h1.3V38.1z"
					></path>
					<path
						fill="#222222"
						d="M59.3,31H42.8v-7.2h16.4V31H59.3z M44.2,29.6h13.7v-4.5H44.2V29.6z"
					></path>
					<path
						fill="#222222"
						d="M54.6,28h-1.3v-1.3h1.3V28z M51.7,28h-1.3v-1.3h1.3V28z M48.7,28h-1.3v-1.3h1.3V28z"
					></path>
					<rect x="55" y="30.2" fill="#222222" width="1.3" height="4.3"></rect>
					<rect
						x="45.7"
						y="30.2"
						fill="#222222"
						width="1.3"
						height="4.3"
					></rect>
					<path
						fill="#222222"
						d="M16.7,41.1h-16v-7.2h16C16.7,33.9,16.7,41.1,16.7,41.1z M2.1,39.8h13.3v-4.5H2.1V39.8z"
					></path>
					<path
						fill="#222222"
						d="M12.6,38.1h-1.3v-1.3h1.3V38.1z M9.6,38.1H8.3v-1.3h1.3V38.1z M6.6,38.1H5.4v-1.3h1.3L6.6,38.1L6.6,38.1z"
					></path>
					<path
						fill="#222222"
						d="M16.7,31h-16v-7.2h16V31z M2.1,29.6h13.3v-4.5H2.1V29.6z"
					></path>
					<path
						fill="#222222"
						d="M12.6,28h-1.3v-1.3h1.3V28z M9.6,28H8.3v-1.3h1.3V28z M6.6,28H5.4v-1.3h1.3L6.6,28L6.6,28z"
					></path>
					<rect
						x="12.9"
						y="30.2"
						fill="#222222"
						width="1.3"
						height="4.3"
					></rect>
					<rect x="3.7" y="30.2" fill="#222222" width="1.3" height="4.3"></rect>
					<path
						fill="#222222"
						d="M41.1,20.7H18.5v-7.2h22.6V20.7z M19.8,19.4h19.9v-4.5H19.8V19.4z"
					></path>
					<path
						fill="#222222"
						d="M38.3,17.8H37v-1.3h1.3V17.8z M35.3,17.8H34v-1.3h1.3V17.8z M32.3,17.8H31v-1.3h1.3V17.8z"
					></path>
					<rect
						x="21.9"
						y="16.5"
						fill="#222222"
						width="6.1"
						height="1.3"
					></rect>
					<rect x="36.8" y="20" fill="#222222" width="1.3" height="4.3"></rect>
					<rect
						x="21.5"
						y="20.1"
						fill="#222222"
						width="1.3"
						height="4.3"
					></rect>
				</svg>`,
					title: 'Scalable and Robust',
					text: 'A robust backend and a scalable database will ensure that your app will keep up with the pace of your business growth.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 60 60">
					<path
						fill="#222222"
						d="M30.2,60c-0.8,0-1.4-0.6-1.4-1.4v-5.8l-0.9-0.1c-10.7-1-19.3-9.6-20.4-20.2l-0.2-1.1H1.6
                c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h5.8l0.1-0.9C8.6,17,17.1,8.4,27.8,7.3l0.9-0.1V1.5c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4
                v5.8l0.9,0.1c10.7,1,19.3,9.6,20.3,20.2l0.1,0.9h5.8c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4h-5.8l-0.1,0.9
                C51.6,42.9,43,51.5,32.4,52.5l-0.8,0.3v5.8C31.6,59.4,30.9,60,30.2,60z M30.2,42.8c0.8,0,1.4,0.6,1.4,1.4v5.7l1.2-0.1
                c9-1.2,16-8.3,17.2-17.2l0.1-1.2h-5.7c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h5.7L50,27.4c-1.2-9-8.3-16-17.2-17.2L31.6,10v5.7
                c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V10l-1.2,0.1c-9,1.2-16,8.3-17.2,17.2l-0.2,1.3h5.7c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4
                h-5.7l0.1,1.2c1.2,9,8.3,16,17.2,17.2l1.2,0.1v-5.7C28.8,43.5,29.4,42.8,30.2,42.8z"
					></path>
				</svg>`,
					title: 'Industry-Focused',
					text: 'Customize and modify the app to make it perform like a native app of any diverse industry.'
				}
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg *{
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg *{
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
