<template>
	<section class="padding-60 bg-light p-relative">
		`
		<div class="container mt-5">
			<div class="title">
				<span>Our offerings</span>
				<h2 class="fxt-process-dev">Conversion-driven eCommerce Website Development Services</h2>
				<p>
					With GenixBit Labs, a top ecommerce website development company you won't be just another
					online store. Become a store where customers will click their way in for long-lasting
					shopping sprees.
				</p>
			</div>
			<div>
				<div class="row">
					<div class="col-12 col-sm-6 col-lg-3" v-for="item in items" v-bind:key="item.title">
						<RoundedCard v-bind:obj="item"></RoundedCard>
					</div>
				</div>
			</div>
		</div>
		`
	</section>
</template>

<script>
import RoundedCard from '../AssetComponents/RoundedCard.vue';

export default {
	components: {
		RoundedCard
	},
	data: () => {
		return {
			items: [
				{
					title: 'Custom eCommerce Development',
					text: 'As a frontrunning eCommerce Development Company, we will help you build a fully-customized eCommerce store that appeals to your target market.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M17.3,35.8c-0.3,0-0.5-0.1-0.7-0.3c-1.7-1.3-3.2-2.6-4.7-3.8c-0.6-0.5-0.9-1.2-0.9-1.9c0-0.8,0.4-1.5,1-2.1
                        c0.9-0.7,1.7-1.4,2.6-2.1l1.3-1c0.2-0.2,0.5-0.4,0.7-0.6c0.5-0.4,1.3-0.3,1.7,0.2c0.4,0.5,0.3,1.3-0.2,1.7
                        c-0.2,0.2-0.4,0.3-0.6,0.5l-1.3,1c-0.9,0.7-1.7,1.4-2.5,2.1c-0.1,0.1-0.2,0.2-0.2,0.2c0,0,0,0,0.1,0.1c1.5,1.2,3,2.5,4.7,3.8
                        c0.5,0.4,0.6,1.2,0.2,1.7C18,35.6,17.7,35.8,17.3,35.8z"></path>
                        <path class="st0" d="M32.7,35.8c-0.3,0-0.7-0.2-0.9-0.4c-0.4-0.5-0.3-1.3,0.2-1.7c1.7-1.3,3.2-2.6,4.7-3.8c0,0,0.1-0.1,0.1-0.1
                        c0,0,0-0.1-0.2-0.2c-0.8-0.7-1.7-1.4-2.5-2.1l-1.3-1c-0.2-0.2-0.4-0.4-0.6-0.5c-0.5-0.4-0.6-1.1-0.2-1.7c0.4-0.5,1.1-0.6,1.7-0.2
                        c0.3,0.2,0.5,0.4,0.7,0.6l1.3,1c0.9,0.7,1.7,1.4,2.6,2.1c0.7,0.5,1,1.3,1,2.1c0,0.7-0.3,1.4-0.9,1.9c-1.5,1.2-3,2.5-4.7,3.8
                        C33.2,35.7,32.9,35.8,32.7,35.8z"></path>
                        <path class="st0" d="M21.6,37.7c-0.2,0-0.4,0-0.5-0.1c-0.6-0.3-0.8-1-0.5-1.6l6.8-13.6c0.3-0.6,1-0.8,1.6-0.5
                        c0.6,0.3,0.8,1,0.5,1.6l-6.8,13.6C22.5,37.5,22,37.7,21.6,37.7z"></path>
                        <ellipse class="st0" cx="6.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <ellipse class="st0" cx="10.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <ellipse class="st0" cx="14.1" cy="6.2" rx="1.1" ry="1.1"></ellipse>
                        <path class="st0" d="M45.6,50H4.4C2,50,0,48,0,45.6V20.2C0,19.5,0.5,19,1.2,19s1.2,0.5,1.2,1.2v25.4c0,1.1,0.9,2,2,2h41.2
                        c1.1,0,2-0.9,2-2V4.4c0-1.1-0.9-2-2-2H4.4c-1.1,0-2,0.9-2,2V8c0,1.1,0.9,2,2,2h38.4c0.7,0,1.2,0.5,1.2,1.2s-0.5,1.2-1.2,1.2H4.4
                        C2,12.4,0,10.5,0,8V4.4C0,2,2,0,4.4,0h41.2C48,0,50,2,50,4.4v41.2C50,48,48,50,45.6,50z"></path>
                        <ellipse class="st0" cx="1.2" cy="16.3" rx="1.2" ry="1.2"></ellipse>
                     </g>
                  </svg>
               `
				},
				{
					title: 'Mobile Commerce Development',
					text: 'Tap into the burgeoning trillion dollar mobile commerce market with our specialized mobile commerce development services.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <path class="st0" d="M41.8,47.1v-6.3V10.3V2.9c0-1.5-1.2-2.7-2.7-2.7H10.9c-1.5,0-2.7,1.2-2.7,2.7v7.4v30.4v6.3
                     c0,1.5,1.2,2.7,2.7,2.7h28.2C40.6,49.8,41.8,48.5,41.8,47.1z M9.7,11.1h30.6V40H9.7V11.1z M9.7,2.9c0-0.7,0.5-1.2,1.2-1.2h28.2
                     c0.7,0,1.2,0.5,1.2,1.2v6.6H9.7V2.9z M40.3,47.1c0,0.7-0.5,1.2-1.2,1.2H10.9c-0.7,0-1.2-0.5-1.2-1.2v-5.6h30.6V47.1z"></path>
                     <path class="st0" d="M32,4.9H18c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8H32c0.4,0,0.8-0.3,0.8-0.8C32.7,5.2,32.4,4.9,32,4.9z"></path>
                     <path class="st0" d="M21.5,45.6h7c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-7c-0.4,0-0.8,0.3-0.8,0.8
                     C20.7,45.3,21.1,45.6,21.5,45.6z"></path>
                     <path class="st0" d="M30.8,19.7c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1L34,25l-4.3,4.3c-0.3,0.3-0.3,0.8,0,1.1
                     c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l4.8-4.8c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5L30.8,19.7z"></path>
                     <path class="st0" d="M20.3,19.7c-0.3-0.3-0.8-0.3-1.1,0l-4.8,4.8c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5l4.8,4.8
                     c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1L16,25l4.3-4.3C20.6,20.4,20.6,20,20.3,19.7z"></path>
                     <path class="st0" d="M27.8,16.5c-0.4-0.1-0.8,0.1-0.9,0.5l-5.1,15.5c-0.1,0.4,0.1,0.8,0.5,0.9c0.1,0,0.2,0,0.2,0
                     c0.3,0,0.6-0.2,0.7-0.5l5.1-15.5C28.4,17.1,28.2,16.7,27.8,16.5z"></path>
                  </svg>
               `
				},
				{
					title: 'Theme Development & Integration',
					text: 'Amplify your store’s performance, maximize admin productivity and enhance customer experience with our eCommerce theme development & integration services.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g id="surface1">
                        <path class="st0" d="M5,3.3v8.3h1.7V3.3c0-0.9,0.7-1.7,1.7-1.7h33.3c0.9,0,1.7,0.7,1.7,1.7H45C45,1.5,43.5,0,41.7,0H8.3
                        C6.5,0,5,1.5,5,3.3z"></path>
                        <path class="st0" d="M3.3,50h38.3c1.8,0,3.3-1.5,3.3-3.3v-3.3h-1.7v3.3c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7v-2.5
                        c0-0.5-0.4-0.8-0.8-0.8H6.7V26.7H5v16.7H0.8c-0.5,0-0.8,0.4-0.8,0.8v2.5C0,48.5,1.5,50,3.3,50z M1.7,45h36.7v1.7
                        c0,0.6,0.2,1.2,0.4,1.7H3.3c-0.9,0-1.7-0.7-1.7-1.7V45z"></path>
                        <path class="st0" d="M0.8,25h16.7c0.5,0,0.8-0.4,0.8-0.8v-10c0-0.5-0.4-0.8-0.8-0.8H0.8c-0.5,0-0.8,0.4-0.8,0.8v10
                        C0,24.6,0.4,25,0.8,25z M1.7,15h15v8.3h-15V15z"></path>
                        <path class="st0" d="M5.2,16.9l-1.7,1.7c-0.3,0.3-0.3,0.9,0,1.2l1.7,1.7l1.2-1.2l-1.1-1.1l1.1-1.1L5.2,16.9z"></path>
                        <path class="st0" d="M13.1,16.9l-1.2,1.2l1.1,1.1l-1.1,1.1l1.2,1.2l1.7-1.7c0.3-0.3,0.3-0.9,0-1.2L13.1,16.9z"></path>
                        <path class="st0" d="M7.5,21.4l1.7-5l1.6,0.5l-1.7,5L7.5,21.4z"></path>
                        <path class="st0" d="M49.2,5H20.8C20.4,5,20,5.4,20,5.8v16.7c0,0.5,0.4,0.8,0.8,0.8h28.3c0.5,0,0.8-0.4,0.8-0.8V5.8
                        C50,5.4,49.6,5,49.2,5z M48.3,21.7H21.7v-2l7.5-4.5l7.3,4.7c0.3,0.2,0.6,0.2,0.8,0l6-3.1l5,2.9V21.7z M48.3,17.7l-4.6-2.6
                        c-0.2-0.1-0.5-0.1-0.8,0l-6,3.1l-7.3-4.7c-0.3-0.2-0.6-0.2-0.9,0l-7.1,4.2v-11h26.7V17.7z"></path>
                        <path class="st0" d="M39.6,14.2c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9S38,14.2,39.6,14.2z M39.6,10
                        c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3S38.9,10,39.6,10z"></path>
                        <path class="st0" d="M8.3,27.5v10c0,0.5,0.4,0.8,0.8,0.8H30c0.5,0,0.8-0.4,0.8-0.8v-10c0-0.5-0.4-0.8-0.8-0.8H9.2
                        C8.7,26.7,8.3,27,8.3,27.5z M10,28.3h19.2v8.3H10V28.3z"></path>
                        <path class="st0" d="M11.7,30H15v1.7h-3.3V30z"></path>
                        <path class="st0" d="M16.7,30h7.5v1.7h-7.5V30z"></path>
                        <path class="st0" d="M25.8,30h1.7v1.7h-1.7V30z"></path>
                        <path class="st0" d="M11.7,33.3h10V35h-10V33.3z"></path>
                        <path class="st0" d="M23.3,33.3h4.2V35h-4.2V33.3z"></path>
                        <path class="st0" d="M8.3,3.3H10V5H8.3V3.3z"></path>
                        <path class="st0" d="M11.7,3.3h1.7V5h-1.7V3.3z"></path>
                        <path class="st0" d="M15,3.3h1.7V5H15V3.3z"></path>
                        <path class="st0" d="M8.3,6.7h10v1.7h-10V6.7z"></path>
                        <path class="st0" d="M49.2,30H33.3c-0.5,0-0.8,0.4-0.8,0.8v10c0,0.5,0.4,0.8,0.8,0.8h15.8c0.5,0,0.8-0.4,0.8-0.8v-10
                        C50,30.4,49.6,30,49.2,30z M48.3,40H34.2v-8.3h14.2V40z"></path>
                        <path class="st0" d="M35.8,33.3h1.7V35h-1.7V33.3z"></path>
                        <path class="st0" d="M35.8,36.7h1.7v1.7h-1.7V36.7z"></path>
                        <path class="st0" d="M39.2,33.3h7.5V35h-7.5V33.3z"></path>
                        <path class="st0" d="M39.2,36.7h7.5v1.7h-7.5V36.7z"></path>
                        <path class="st0" d="M43.3,25H45v3.3h-1.7V25z"></path>
                     </g>
                  </svg>
               `
				},
				{
					title: 'UX Research and Design',
					text: 'Design can make it easy for users to find information, take decisions and buy more from your online store. Our UX research and design services will help you with that.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g>
                           <path class="st0" d="M39.1,15.6c3.8,0,6.8-3.1,6.8-6.8s-3.1-6.8-6.8-6.8S32.3,5,32.3,8.8S35.4,15.6,39.1,15.6z M39.1,17.5
                           c-4.8,0-8.8-3.9-8.8-8.8S34.3,0,39.1,0s8.8,3.9,8.8,8.8S44,17.5,39.1,17.5z"></path>
                           <rect x="36.2" y="15.6" class="st0" width="1.9" height="12.3"></rect>
                           <rect x="40.1" y="15.6" class="st0" width="1.9" height="12.3"></rect>
                           <path class="st0" d="M36.2,27.9h1.9c0,0.2,0.3,0.4,0.9,0.4c0.6,0,1-0.2,1-0.5l1.9,0c0,1.5-1.4,2.4-2.9,2.4
                           C37.6,30.3,36.2,29.5,36.2,27.9z"></path>
                           <rect x="3.1" class="st0" width="1.9" height="30.5"></rect>
                           <rect x="26.5" class="st0" width="1.9" height="30.5"></rect>
                           <rect x="3.1" class="st0" width="25.3" height="1.9"></rect>
                           <rect x="3.1" y="28.6" class="st0" width="25.3" height="1.9"></rect>
                           <path class="st0" d="M8.9,9.7H7V3.9h1.9h1.9h1.9v5.8h-1.9H8.9z M8.9,7.8h1.9V5.8H8.9V7.8z"></path>
                           <path class="st0" d="M8.9,17.5H7v-5.8h1.9h1.9h1.9v5.8h-1.9H8.9z M8.9,15.6h1.9v-1.9H8.9V15.6z"></path>
                           <path class="st0" d="M8.9,26H7v-5.8h1.9h1.9h1.9V26h-1.9H8.9z M8.9,24h1.9v-1.9H8.9V24z"></path>
                           <rect x="14.8" y="3.9" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="14.8" y="7.8" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="14.8" y="11.7" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="14.8" y="15.6" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="14.8" y="20.1" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="14.8" y="24" class="st0" width="9.7" height="1.9"></rect>
                           <rect x="3.1" y="32.5" class="st0" width="44.8" height="1.9"></rect>
                           <rect x="3.1" y="48.1" class="st0" width="44.8" height="1.9"></rect>
                           <rect x="3.1" y="33.1" class="st0" width="1.9" height="16.9"></rect>
                           <rect x="46" y="33.1" class="st0" width="1.9" height="16.9"></rect>
                           <rect x="20" y="33.1" class="st0" width="1.9" height="16.9"></rect>
                           <path class="st0" d="M9,48.4H7.1c0-3.3,2.5-5.4,5.6-5.3c3.1,0,5.6,2.1,5.6,5.3l-1.9,0c0-2-1.6-3.4-3.7-3.4C10.5,45,9,46.3,9,48.4z
                           "></path>
                           <path class="st0" d="M12.5,45.1c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5s4.5,2,4.5,4.5S15,45.1,12.5,45.1z M12.5,43.2
                           c1.4,0,2.6-1.2,2.6-2.6S13.9,38,12.5,38s-2.6,1.2-2.6,2.6S11.1,43.2,12.5,43.2z"></path>
                           <polygon class="st0" points="22.1,45 21.1,43.3 26.5,40.2 28.6,43.2 34,37.1 36.2,41.7 39.1,38.1 42.1,43 46.1,36.8 47.8,37.9
                           42,46.6 38.9,41.4 35.8,45.3 33.5,40.6 28.5,46.4 25.9,42.8      "></polygon>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'eCommerce Maintenance & Support',
					text: 'Sophisticated eCommerce websites require consistent maintenance and support. We will guard your store’s working while you can focus on important tasks.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                           <path class="st0" d="M260.4,5109.1c-3.7-1.5-5.2-6.7-5.2-17.7v-9.8l-2.7-0.7c-1.5-0.3-6.1-2.2-10.4-4.2l-7.8-3.6l-8.1,8
                           c-12.1,12-10.7,12.4-33.1-10c-15.1-15-18.1-18.5-18.1-20.9c0-2.2,1.8-4.7,8.3-11.4c8.1-8.2,8.3-8.6,7-11
                           c-0.8-1.4-2.9-6.1-4.6-10.6l-3.3-8.2h-11c-10.7,0-11.2-0.1-13.8-2.6c-2.6-2.6-2.6-2.8-2.6-17.3v-14.8h-17.1H121l-5.2,22.1
                           c-4.1,17.1-5.6,22.4-7.2,23.8c-1.9,1.5-5.1,1.7-33.2,1.7H44.3v-7.9v-7.9h26.5h26.5l7.2-30.9c4.1-17,16.6-69.7,28-117.2
                           c26.7-111.4,34.5-144.2,34.5-145.5c0-0.6-3.1-2.4-6.7-4.1c-8.4-3.7-20.4-15.2-24.3-23.3c-10-20.8-6.1-43.9,9.8-59.8
                           c20.3-20.4,52.8-20.4,73.1,0c18.7,18.6,20.5,49.3,4.1,68.5l-3.7,4.2h50.2h50.2l-3.7-4.2c-9.7-11.4-13.7-29-9.9-43.9
                           c6.3-24.8,28.8-41.5,53.5-39.8c43.1,2.8,64.5,54.1,35.9,85.6c-6.3,6.9-15,12.6-22.9,15.1c-5.6,1.7-12.2,1.9-97.8,2.6l-91.6,0.8
                           l-6.1,26.3c-3.4,14.5-6.1,26.6-6.1,27.1c0,0.4,50.1,0.7,111.4,0.7H394l2.2,2.2c3.4,3.5,60.5,194.7,59.2,198.2
                           c-2,5.2-4.1,5.6-22.1,5.6h-16.6v14.8c0,14.6,0,14.8-2.6,17.3c-2.6,2.5-3.1,2.6-13.8,2.6h-11.1l-3.6,9.2c-2.1,5-4.1,9.8-4.7,10.6
                           c-0.7,1.1,1.1,3.5,7.6,10.1c7.9,8.1,8.4,8.9,7.8,11.9c-0.5,2.5-4.7,7.3-18.2,20.9c-21.8,21.7-20.6,21.3-32.6,9.4l-8.1-8l-7.8,3.6
                           c-4.3,2-8.9,3.9-10.4,4.2l-2.7,0.7v9.8c0,12-1.4,15.9-5.9,17.6C306.8,5110.3,263.8,5110.4,260.4,5109.1z M299.8,5085.1
                           c0-12.8,1-14.1,14.4-18.5c6-2,13.3-5.1,16-6.7c9.5-5.8,10.9-5.5,20.9,4.1l6.2,5.9l9.8-9.8l9.8-9.8l-6.8-7.2
                           c-5.1-5.2-6.9-7.9-6.9-9.9c0-1.5,2-6.4,4.4-11.1c2.4-4.8,5.5-12.5,7-17.2c1.8-5.5,3.5-9.2,4.9-10.1c1.4-1.1,4.8-1.5,11.7-1.5h9.7
                           v-9.4v-9.4h-26h-26l-0.1,8.2c-0.2,9.8-4,22.1-9.7,30.6c-5.5,8.4-16.1,17.8-24.6,22c-9.9,4.9-17.3,6.5-28.5,6.5
                           c-11.2,0-18.6-1.7-28.7-6.6c-9.9-4.8-22.8-17.5-27.5-27.2c-4.3-8.8-7.4-21.3-6.9-28.1l0.3-5.3h-25.9h-26v9.4v9.4h9.7
                           c6.9,0,10.3,0.4,11.7,1.5c1.4,0.9,3.1,4.6,4.9,10.1c1.4,4.8,4.7,12.5,7,17c5.9,11.3,5.8,12.4-2.6,21.2l-6.8,7.2l9.8,9.8l9.8,9.8
                           l6.4-6.1c9.2-8.9,10.2-9.1,19.8-4.1c4.5,2.4,12.5,5.6,17.9,7.3c12.3,3.9,13.4,5.4,13.4,18.1v8.1H286h13.9L299.8,5085.1
                           L299.8,5085.1z M306.2,5021.6c8.8-4.2,17.2-12,21.6-20.1c3.2-5.8,5.7-15.7,5.7-22.4v-4.8H286h-47.5l0.1,5.2
                           c0.1,16.7,10.4,33.6,25.2,41c8.7,4.6,12.2,5.2,23.8,5C297.7,5025.4,298.7,5025.2,306.2,5021.6z M437.5,4957.7
                           c0-0.5-0.9-3.7-1.9-7.1c-1.1-3.5-3.1-10.2-4.5-15l-2.7-8.7h-97h-97.1v-7.9v-7.9h94.7c75.4,0,94.6-0.3,94.2-1.2
                           c-0.2-0.7-2.5-7.8-5.1-15.8l-4.7-14.7h-73.7h-73.8v-7.9v-7.9h71.3c39.2,0,71.3-0.3,71.3-0.7s-2.1-7.5-4.7-15.8l-4.7-15.2h-50.8
                           h-50.8v-7.9v-7.9h48.6c38.5,0,48.5-0.3,48.1-1.2c-0.2-0.7-2.5-7.8-5.1-15.8l-4.6-14.7H275.8H166.6l-20.6,86.5
                           c-11.3,47.4-20.6,86.7-20.5,87.2C125.5,4958.8,437.5,4958.7,437.5,4957.7z M197,4694.9c7.8-3.6,14.1-9.7,17.9-17.5
                           c2.9-5.8,3.2-7.3,3.2-15.5s-0.3-9.6-3.2-15.5c-3.9-7.8-10.1-13.9-17.9-17.5c-4.8-2.3-7-2.7-14.6-2.7c-7.5,0-9.8,0.4-14.6,2.7
                           c-7.8,3.7-14.1,9.7-17.9,17.5c-2.9,5.8-3.2,7.3-3.2,15.5c0,7.5,0.4,9.7,2.6,14.5c4.7,9.8,12.7,16.9,23,20.3
                           C178.8,4698.8,190.3,4698,197,4694.9z M371.3,4694.9c7.8-3.6,14.1-9.7,17.9-17.5c2.9-5.8,3.2-7.3,3.2-15.5s-0.3-9.6-3.2-15.5
                           c-3.9-7.8-10.1-13.9-17.9-17.5c-4.8-2.3-7-2.7-14.6-2.7c-7.5,0-9.8,0.4-14.6,2.7c-7.8,3.7-14.1,9.7-17.9,17.5
                           c-2.9,5.8-3.2,7.3-3.2,15.5c0,7.5,0.5,9.8,2.7,14.5c4.7,10.1,13.2,17.5,23.5,20.4C354.2,4698.8,364.8,4697.9,371.3,4694.9z"></path>
                           <path class="st0" d="M174.6,4684.8c-17.5-6.5-21.3-29.7-6.7-40.9c11.1-8.4,26.2-5.9,34.4,5.6c2.8,4,3,4.9,3,13s-0.2,9-3,13
                           C196.1,4684.2,184,4688.3,174.6,4684.8z M187.3,4668.3c2-1.6,2.6-2.9,2.6-5.8s-0.6-4.3-2.6-5.8c-1.5-1.2-3.7-2.1-4.9-2.1
                           s-3.4,0.9-4.9,2.1c-2,1.6-2.6,2.9-2.6,5.8c0,3,0.6,4.3,2.6,5.8c1.5,1.2,3.7,2.1,4.9,2.1C183.6,4670.3,185.8,4669.4,187.3,4668.3z"></path>
                           <path class="st0" d="M348.9,4684.8c-9.5-3.6-15.7-12.2-15.7-22.1c0-9.4,3.8-16,11.5-20.6c11.6-6.7,26.8-2.4,33,9.4
                           c2,3.8,2.3,5.6,2.1,12.1c-0.3,6.5-0.7,8.1-3.3,11.8C370.4,4684.2,358.4,4688.3,348.9,4684.8z M361.6,4668.3
                           c3.7-2.9,3.7-8.8,0-11.7c-1.5-1.2-3.7-2.1-4.9-2.1c-1.2,0-3.4,0.9-4.9,2.1c-3.7,2.9-3.7,8.8,0,11.7c1.5,1.2,3.7,2.1,4.9,2.1
                           C358,4670.3,360.1,4669.4,361.6,4668.3z"></path>
                        </g>
                     </g>
                  </svg>
               `
				},
				{
					title: 'eCommerce API Integrations',
					text: 'Increase your store’s reach for affiliate marketing and mobile app performance with eCommerce API integration development.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M20.5,3.8h-8.2c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h8.2c0.5,0,0.8-0.4,0.8-0.8S21,3.8,20.5,3.8z"></path>
                        <path class="st0" d="M16.4,47.5c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2S15.2,47.5,16.4,47.5z M16.4,45.8
                        c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5S16.7,45.8,16.4,45.8z"></path>
                        <path class="st0" d="M5.6,30.5l1.6,0.3c0.2,0.6,0.4,1.2,0.7,1.8L7,34c-0.2,0.3-0.2,0.8,0.1,1.1l2.4,2.4c0.3,0.3,0.7,0.3,1.1,0.1
                        l1.4-1c0.6,0.3,1.2,0.6,1.8,0.7l0.3,1.6c0.1,0.4,0.4,0.7,0.8,0.7h3.3c0.4,0,0.7-0.3,0.8-0.7l0.3-1.6c0.6-0.2,1.2-0.4,1.8-0.7l1.4,1
                        c0.3,0.2,0.8,0.2,1.1-0.1l2.4-2.4c0.3-0.3,0.3-0.7,0.1-1.1l-1-1.4c0.3-0.6,0.5-1.2,0.7-1.8l1.6-0.3c0.4-0.1,0.7-0.4,0.7-0.8v-3.4
                        c0-0.4-0.3-0.8-0.7-0.8l-1.6-0.3c-0.2-0.6-0.4-1.2-0.7-1.8l1-1.4c0.2-0.3,0.2-0.8-0.1-1.1l-2.4-2.4c-0.3-0.3-0.7-0.3-1.1-0.1
                        L21,19.6c-0.6-0.3-1.2-0.6-1.8-0.7l-0.3-1.6c-0.1-0.4-0.4-0.7-0.8-0.7h-3.3c-0.4,0-0.7,0.3-0.8,0.7l-0.3,1.6
                        c-0.6,0.2-1.2,0.4-1.8,0.7l-1.4-0.9c-0.3-0.2-0.8-0.2-1.1,0.1l-2.4,2.3c-0.3,0.3-0.3,0.7-0.1,1.1l1,1.4c-0.3,0.6-0.6,1.2-0.7,1.8
                        l-1.6,0.3c-0.4,0.1-0.7,0.4-0.7,0.8v3.4C4.9,30.1,5.2,30.5,5.6,30.5z M8.7,26.2c0.2-0.8,0.5-1.5,1-2.3c0.2-0.3,0.2-0.6,0-0.9
                        l-0.9-1.2l1.4-1.4l1.2,0.8c0.3,0.2,0.6,0.2,0.9,0c0.8-0.5,1.5-0.8,2.3-0.9c0.3-0.1,0.6-0.3,0.6-0.7l0.3-1.5h1.9l0.3,1.5
                        c0.1,0.3,0.3,0.6,0.6,0.7c0.8,0.2,1.5,0.5,2.3,0.9c0.3,0.2,0.6,0.2,0.9,0l1.2-0.8l1.4,1.4L23.3,23c-0.2,0.3-0.2,0.6,0,0.9l0,0
                        c0.5,0.8,0.8,1.5,1,2.3c0.1,0.3,0.3,0.6,0.7,0.6l1.5,0.2V29l-1.5,0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.2,0.8-0.5,1.6-0.9,2.3
                        c-0.2,0.3-0.2,0.6,0,0.9l0.9,1.2l-1.4,1.4l-1.2-0.9c-0.3-0.2-0.6-0.2-0.9,0c-0.8,0.5-1.5,0.8-2.3,0.9c-0.3,0.1-0.6,0.3-0.6,0.7
                        l-0.3,1.5h-1.9l-0.3-1.5c-0.1-0.3-0.3-0.6-0.6-0.7c-0.8-0.2-1.5-0.5-2.3-0.9c-0.3-0.2-0.6-0.2-0.9,0l-1.2,0.9l-1.4-1.4l0.9-1.2
                        c0.2-0.3,0.2-0.6,0-0.9c-0.5-0.7-0.8-1.5-0.9-2.3c-0.1-0.3-0.3-0.6-0.7-0.6L6.5,29v-1.9L8,26.8C8.3,26.8,8.6,26.5,8.7,26.2z"></path>
                        <path class="st0" d="M16.4,33.3c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.2-5.3-5.2c-2.9,0-5.3,2.4-5.3,5.2
                        C11.2,30.9,13.5,33.3,16.4,33.3z M12.8,28c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6C14.4,31.6,12.8,30,12.8,28
                        z"></path>
                        <path class="st0" d="M38.1,27.5l0.3-1.5c0.5-0.2,1.1-0.4,1.6-0.7l1.3,0.9c0.3,0.2,0.8,0.2,1.1-0.1l2.2-2.2c0.3-0.3,0.3-0.7,0.1-1.1
                        l-0.9-1.3c0.3-0.5,0.5-1.1,0.7-1.6l1.5-0.3c0.4-0.1,0.7-0.4,0.7-0.8v-3.1c0-0.4-0.3-0.8-0.7-0.8l-1.5-0.3c-0.2-0.5-0.4-1.1-0.7-1.6
                        l0.9-1.3c0.2-0.3,0.2-0.8-0.1-1.1l-2.2-2.2c-0.3-0.3-0.7-0.3-1.1-0.1L40,9.4c-0.5-0.3-1.1-0.5-1.6-0.7l-0.3-1.5
                        c-0.1-0.4-0.4-0.7-0.8-0.7h-3.1c-0.4,0-0.7,0.3-0.8,0.7l-0.3,1.5c-0.1,0-0.3,0.1-0.4,0.1l0-5c0-1.8-1.5-3.2-3.2-3.2H3.4
                        c-1.8,0-3.2,1.5-3.2,3.2v42.2c0,1.8,1.5,3.2,3.2,3.2h26.1c0.9,0,1.7-0.3,2.3-0.9c0.6-0.6,1-1.4,1-2.3l0-20.2c0.1,0,0.3,0.1,0.4,0.1
                        l0.3,1.5c0.1,0.4,0.4,0.7,0.8,0.7h3.1C37.7,28.2,38.1,27.9,38.1,27.5z M31.1,43v3.1c0,0.4-0.2,0.8-0.5,1.1
                        c-0.3,0.3-0.7,0.5-1.1,0.5H3.4c-0.9,0-1.6-0.7-1.6-1.6V43H31.1z M30.5,8.7h0.6v0.4L30.5,8.7z M27,10.8c-0.3,0.3-0.3,0.7-0.1,1.1
                        l0.9,1.2c-0.3,0.5-0.5,1.1-0.7,1.6L25.7,15c-0.4,0.1-0.7,0.4-0.7,0.8v3.1c0,0.4,0.3,0.8,0.7,0.8l1.5,0.3c0.2,0.5,0.4,1.1,0.7,1.6
                        l-0.9,1.3c-0.2,0.3-0.2,0.8,0.1,1.1l2.2,2.2c0.3,0.3,0.7,0.3,1.1,0.1l0.8-0.5v15.7H1.8V8.7h27.4L27,10.8z M31,23.7l-1.1,0.8
                        l-1.2-1.2l0.8-1.1c0.3-0.4,0.1-0.8-0.1-1.2c-0.2-0.4-0.5-0.9-0.7-1.8c-0.1-0.3-0.3-0.6-0.7-0.6l-1.3-0.2v-1.7l1.3-0.2
                        c0.3-0.1,0.6-0.3,0.7-0.6c0.2-0.7,0.4-1.4,0.9-2.1c0.2-0.3,0.2-0.6,0-0.9l-0.8-1.1l1.2-1.2l1.1,0.8c0.3,0.2,0.6,0.2,0.9,0
                        c0.1-0.1,0.2-0.1,0.4-0.2c0.6-0.3,1.2-0.5,1.8-0.7c0.3-0.1,0.6-0.3,0.6-0.7l0.2-1.3h1.7l0.2,1.3c0.1,0.3,0.3,0.6,0.6,0.7
                        c0.7,0.2,1.4,0.4,2.1,0.9c0.3,0.2,0.6,0.2,0.9,0l1.1-0.8l1.2,1.2l-0.8,1.1c-0.2,0.3-0.2,0.6,0,0.9c0.4,0.7,0.7,1.4,0.9,2.1
                        c0.1,0.3,0.3,0.6,0.7,0.6l1.3,0.2v1.7l-1.3,0.2c-0.3,0.1-0.6,0.3-0.7,0.6c-0.2,0.7-0.4,1.4-0.9,2.1c-0.2,0.3-0.2,0.6,0,0.9l0.8,1.1
                        l-1.2,1.2l-1.1-0.8c-0.3-0.2-0.6-0.2-0.9,0c-0.7,0.4-1.4,0.7-2.1,0.9c-0.3,0.1-0.6,0.3-0.6,0.7l-0.2,1.3h-1.7l-0.2-1.3
                        c-0.1-0.3-0.3-0.6-0.6-0.7c-0.6-0.1-1.2-0.3-1.8-0.7c-0.1-0.1-0.2-0.1-0.4-0.2C31.6,23.5,31.3,23.5,31,23.7z M1.8,7V3.9
                        c0-0.9,0.7-1.6,1.6-1.6h26.1c0.9,0,1.6,0.7,1.6,1.6V7H1.8z"></path>
                        <path class="st0" d="M35.8,22.6c2.9,0,5.3-2.4,5.3-5.3c0-2.9-2.4-5.2-5.3-5.2c-2.9,0-5.3,2.4-5.3,5.2
                        C30.5,20.3,32.9,22.6,35.8,22.6z M32.2,17.3c0-2,1.6-3.5,3.6-3.5c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6
                        C33.8,21,32.2,19.4,32.2,17.3z"></path>
                        <path class="st0" d="M46,7.1c-2.7-2.7-6.4-4.2-10.2-4.2c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c7.1,0,12.8,5.7,12.8,12.8
                        c0,7.1-5.8,12.8-12.8,12.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c8,0,14.5-6.5,14.5-14.5C50.3,13.5,48.7,9.9,46,7.1z"></path>
                     </g>
                  </svg>
               `
				},
				{
					title: 'Payment Gateway Integration',
					text: 'Turn your eCommerce store into a global store with payment gateway integrations that can accept payments from all recognized card companies of the world.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                     <g>
                        <path class="st0" d="M46.8,12.8H32.6V3.7c0-1.7-1.3-3-2.9-3H3.7c-1.7,0-3,1.3-3,3v43.1c0,1.7,1.3,3,3,3h26.1c1.6,0,3-1.3,3-3v-9.1
                        h14.2c1.4,0,2.5-1.1,2.5-2.5V15.3C49.3,13.9,48.2,12.8,46.8,12.8z M47.9,15.3V18H26.8c-0.2-1.4-0.8-2.6-1.5-3.7h21.6
                        C47.4,14.2,47.9,14.7,47.9,15.3z M12.3,19.1c0-3.6,3-6.6,6.5-6.6s6.5,3,6.5,6.6s-3,6.6-6.5,6.6S12.3,22.7,12.3,19.1z M26.8,19.4h21
                        V22H26.4C26.7,21.1,26.8,20.3,26.8,19.4z M3.7,2.1h26.1c0.8,0,1.4,0.7,1.4,1.5v3.5h-29V3.7C2.1,2.8,2.8,2.1,3.7,2.1z M29.7,48.2
                        H3.7c-0.8,0-1.4-0.7-1.4-1.5v-3.5h29v3.5C31.2,47.6,30.5,48.2,29.7,48.2z M31.2,41.7h-29V8.6h29v4.1h-7.4c-1.4-1.1-3-1.7-4.9-1.7
                        c-4.4,0-8,3.6-8,8.1c0,2.7,1.4,5.1,3.3,6.6v9.3c0,1.4,1.1,2.5,2.5,2.5h14.4V41.7z M46.8,36.1H16.7c-0.5,0-1-0.5-1-1v-8.6
                        c1,0.5,2.1,0.7,3.2,0.7c2.9,0,5.3-1.5,6.8-3.8H48v11.6C47.9,35.7,47.4,36.1,46.8,36.1z"></path>
                        <path class="st0" d="M42.2,27.9c-0.6,0-1.1,0.2-1.7,0.5c-0.5-0.3-1.1-0.5-1.7-0.5c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8
                        c0.6,0,1.2-0.2,1.7-0.5c0.5,0.4,1.1,0.5,1.7,0.5c1.6,0,2.8-1.3,2.8-2.8S43.7,27.9,42.2,27.9z M37.5,30.8c0-0.8,0.6-1.4,1.4-1.4
                        s1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4S37.5,31.5,37.5,30.8z M42.2,32.2c-0.2,0-0.5-0.1-0.7-0.2c0.2-0.4,0.2-0.8,0.2-1.1
                        s-0.1-0.8-0.2-1.1c0.2-0.2,0.5-0.2,0.7-0.2c0.8,0,1.4,0.6,1.4,1.4S42.9,32.2,42.2,32.2z"></path>
                        <path class="st0" d="M20.4,29.8h9.4c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-9.4c-0.4,0-0.8,0.3-0.8,0.8
                        C19.6,29.5,19.9,29.8,20.4,29.8z"></path>
                        <path class="st0" d="M34.3,31.8H20.4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h13.9c0.4,0,0.8-0.3,0.8-0.8S34.7,31.8,34.3,31.8z"></path>
                        <path class="st0" d="M22.9,16.3c-0.3-0.3-0.8-0.3-1.1,0l-4.1,4.1l-1.8-1.8c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.4,2.4
                        c0.2,0.2,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l4.6-4.7C23.1,17.1,23.1,16.5,22.9,16.3z"></path>
                        <path class="st0" d="M18.7,45h-4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h4c0.4,0,0.8-0.3,0.8-0.8S19.1,45,18.7,45z"></path>
                        <path class="st0" d="M14.5,5.2c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0s-0.3,0.8,0,1.1S14.2,5.5,14.5,5.2z"></path>
                        <path class="st0" d="M17.2,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.2-0.8-0.2-1.1,0c-0.3,0.3-0.3,0.8,0,1.1C16.4,5.5,16.9,5.5,17.2,5.2z"></path>
                        <path class="st0" d="M19.8,5.2c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0s-0.3,0.8,0,1.1S19.5,5.5,19.8,5.2z"></path>
                     </g>
                  </svg>
               `
				},
				{
					title: 'eCommerce Migration Platform',
					text: 'Your existing eCommerce platform giving your operational difficulties? Migrate to a better platform of your choice to set things right. Or allow us to suggest the best one for your store.',
					svg: `
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                   <path d="M34.4,19.7v-8.3c0-5.5-8.6-8.4-17.1-8.4C8.7,3,0.1,5.9,0.1,11.4v21.4c0,4,5,7.2,12.6,8.1v8.3c0,5.5,8.6,8.4,17.1,8.4
                   c8.5,0,17.1-2.9,17.1-8.4V27.8C47,23.2,41.2,20.5,34.4,19.7z M14.9,23.4c-4.8-0.3-11.2-1.6-13-3v-5c2.6,1.8,9.9,3,15.3,3
                   c5.4,0,12.3-1.2,14.9-3v4C26.3,19.4,18,20.8,14.9,23.4z M44.6,36.8c-2.1,1.7-8.9,3.1-14.7,3.1c-5.8,0-12.8-1.4-14.9-3.1v-5
                   c2.6,1.8,9.5,3,14.9,3c5.4,0,12.1-1.2,14.7-3V36.8z M1.9,23.2c2.2,1.2,8.1,2.1,11.3,2.4c-0.3,0.7-0.5,1.4-0.5,2.2v2.6
                   C9,29.8,3.4,28.7,1.9,27.5L1.9,23.2L1.9,23.2z M15,39.5c2.9,1.6,9.7,2.6,14.9,2.6c5.2,0,11.8-1,14.7-2.6v4.3
                   c-2.1,1.7-8.9,3.1-14.7,3.1C24.1,47,17,45.6,15,43.9V39.5z M44.6,27.8v0.7c-0.8,2-8.1,4-14.7,4c-6.6,0-14.1-2-14.9-4v-0.7
                   c0-3.3,8.8-6,16.9-6C40,21.8,44.6,26.6,44.6,27.8z M17.2,5.2c12-0.1,14.7,5.1,14.7,6.2v0.7c-0.8,2-8.1,4-14.7,4
                   c-6.6,0-14.5-2-15.3-4v-0.7C1.9,7.7,9.1,5.2,17.2,5.2z M1.9,32.8v-2.6c2,1.1,7.5,1.9,10.8,2.4v6.5C7.2,38.3,2.1,36.1,1.9,32.8z
                   M29.9,55c-8.1,0-14.8-3-14.9-5.8v-2.6c2.9,1.6,9.7,2.6,14.9,2.6c5.2,0,11.8-1,14.7-2.6v2.6C44.6,52.7,38,55,29.9,55z M98.2,57.9
                   h-4.9v-5.2c0-1.3-1-2.3-2.3-2.3h-3.1v-6.3c0-1.3-1-2.3-2.3-2.3H68c-0.7,0-1.4,0.4-1.9,1l-4.5,6.3h-9c-1.3,0-2.3,1-2.3,2.3V82
                   c0,1.3,1,2.3,2.3,2.3h6.7v3c0,1.6,0.6,2.3,2.3,2.3h4.7v5.2c0,1.3,1,2.3,2.3,2.3h29.9c1.3,0,1.3-1,1.3-2.3V60.2
                   C99.9,59,99.4,57.9,98.2,57.9z M53,51.5h9.9c0.7,0,0.7-0.4,1.1-1l4.5-6.1H85v37.2H53V51.5z M62,84.3h23.5c1.3,0,2.3-1,2.3-2.3V53.7
                   h2.4V87H62V84.3z M97.3,94.6H69v-5.1h21.9c1.3,0,2.3-1,2.3-2.3V60.9h4V94.6z M70.5,16.5v5.1h-4.2c-0.6,0-1.2,0.3-1.5,0.9
                   c-0.3,0.6-0.3,1.2,0.1,1.7L74.7,39c0.3,0.5,0.9,0.8,1.4,0.8c0.6,0,1.1-0.3,1.4-0.8l9.8-14.7c0.3-0.5,0.4-1.2,0.1-1.7
                   c-0.3-0.6-0.9-0.9-1.5-0.9h-3.4V6.5c0-0.9-0.8-1.7-1.7-1.7H51.3c-1,0-1.7,0.8-1.7,1.7v8.4c0,0.9,0.8,1.7,1.7,1.7H70.5z M51.8,7h28.4
                   v15c0,0.9-0.4,1.7,0.5,1.7l4.3,0.1l-8.7,12.9l-9.1-13h2.7c1,0,3,0.2,3-0.7l0.1-8.1c0-0.8,0.2-0.5-0.7-0.5H51.8V7z M38.8,83.5
                   l-14.9-9.6c-0.5-0.3-1.2-0.4-1.8-0.1c-0.6,0.3-0.9,0.9-0.9,1.5v4.1H16v-19c0-0.9-0.8-1.7-1.7-1.7H5.8c-1,0-1.7,0.8-1.7,1.7v29.1
                   c0,0.9,0.8,1.7,1.7,1.7h15.4v3.4c0,0.6,0.3,1.2,0.9,1.5c0.7,0.4,1.5,0.1,1.8-0.1l14.9-9.6c0.5-0.3,0.8-0.9,0.8-1.4
                   S39.3,83.8,38.8,83.5z M23.6,93.6c0.3-4.2-0.5-4.4-1.4-4.4h-16v-28h7.6v18.4c0,0.9,0.6,1.7,1.5,1.7h7.6c1,0,0.7-3.1,0.7-4.1v-1.1
                   l13.4,8.8C37.1,84.9,23.6,93.7,23.6,93.6z M71.5,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C71,68,71.5,68.5,71.5,69.1z M78.6,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C78,68,78.6,68.5,78.6,69.1z M78.6,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C78,57,78.6,57.5,78.6,58.1z M78.6,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C78,62.5,78.6,63,78.6,63.6z M64.5,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C64,57,64.5,57.5,64.5,58.1z M64.5,69.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C64,68,64.5,68.5,64.5,69.1z M64.5,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C64,62.5,64.5,63,64.5,63.6z M71.5,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C71,73.5,71.5,74,71.5,74.6z M78.6,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C78,73.5,78.6,74,78.6,74.6z M71.5,58.1c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C71,57,71.5,57.5,71.5,58.1z M71.5,63.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C71,62.5,71.5,63,71.5,63.6z M64.5,74.6c0,0.6-0.5,1.1-1.2,1.1h-2.6c-0.6,0-1.2-0.5-1.2-1.1c0-0.6,0.5-1.1,1.2-1.1h2.6
                   C64,73.5,64.5,74,64.5,74.6z"></path>
                </svg>
               `
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
