<template>
	<section class="uiux-app-design-main padding-60 p-relative" style="background: #fff">
		<div class="container mt-5">
			<div class="title">
				<span>Mobile App Design</span>
				<h2 class="fxt-process-dev">IOS and Android UI/UX Design Services</h2>
				<p>
					Offering the best in class UI/UX design for iOS and Android apps. We bring your app idea
					to life that’ll have users keep coming for more.
				</p>
			</div>
			<div class="row">
				<div class="col-lg-1 col-12"></div>
				<div class="col-lg-5 col-md-6 col-12 mb-30">
					<div class="uiux-app-design-box d-flex">
						<div class="uiux-app-design-icon box-shadow">
							<svg
								viewBox="0 0 32 32"
								style="enable-background: new 0 0 32 32"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M15.9,8.3c0.1,0,0.2,0,0.4,0c1.7,0,3.6-0.9,4.9-2.4c1.3-1.6,2-3.6,1.8-5.4c0-0.3-0.3-0.6-0.6-0.5
              c-1.8,0.1-3.9,1.1-5.1,2.5c-1.1,1.2-2.1,3.2-1.8,5.2C15.4,8.1,15.6,8.3,15.9,8.3z M18.1,3.3c0.9-1,2.3-1.8,3.6-2
              c0,1.3-0.6,2.7-1.5,3.9c-1,1.1-2.3,1.9-3.6,2C16.5,5.6,17.4,4.2,18.1,3.3z M29.1,22.6c-0.4-0.2-3.8-1.6-3.8-5.7
              c0-3.2,2.4-4.9,3.1-5.4c0.1-0.1,0.2-0.2,0.3-0.4c0-0.2,0-0.3-0.1-0.5c-2-2.8-5.1-3.3-6.2-3.3c-1.6-0.2-3.2,0.4-4.5,0.9
              c-0.7,0.3-1.3,0.5-1.7,0.5c-0.4,0-1.1-0.2-1.7-0.5c-1-0.4-2.2-0.8-3.4-0.8l-0.1,0c-2.9,0-5.6,1.6-7.1,4.2c-2.8,4.7-1,11.8,2,16.2
              c1.3,1.8,3,4.2,5.4,4.2l0.1,0c1,0,1.8-0.3,2.5-0.6c0.8-0.3,1.5-0.6,2.7-0.6c1.1,0,1.8,0.3,2.5,0.6c0.7,0.3,1.5,0.6,2.6,0.6l0.1,0
              c2.5,0,4.1-2.3,5.3-4c1.4-1.9,2-3.8,2.3-4.5C29.6,23.1,29.4,22.8,29.1,22.6z M26.2,27.2c-1.4,2-2.6,3.5-4.3,3.5l-0.1,0
              c-0.9,0-1.4-0.2-2.1-0.5c-0.8-0.3-1.7-0.7-3-0.7c-1.4,0-2.3,0.4-3.2,0.7c-0.7,0.3-1.2,0.5-2.1,0.5l-0.1,0c-1.6,0-3-1.7-4.4-3.6
              c-3.8-5.4-3.9-11.7-2-14.9c1.3-2.2,3.6-3.5,6-3.6l0.1,0c1,0,2,0.4,3,0.7c0.8,0.3,1.5,0.6,2.2,0.6c0.6,0,1.3-0.3,2.1-0.6
              c1-0.4,2.2-0.9,3.4-0.9c0.1,0,0.3,0,0.5,0c1,0,3.3,0.3,4.9,2.3C26,11.8,24,13.8,24,17c0,4,2.9,5.9,4.1,6.5
              C27.8,24.3,27.2,25.8,26.2,27.2z"
								></path>
							</svg>
						</div>
						<div class="uiux-app-design-content">
							<h4>UX/UI Design for iOS App</h4>
							<p>
								Leverage the iOS platform for creating a smoother, minimalist UI crafted to
								perfection by our team of in-house UI designers that ensure the best onboarding
								experience for your users.
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-5 col-md-6 col-12 mb-30">
					<div class="uiux-app-design-box d-flex">
						<div class="uiux-app-design-icon box-shadow">
							<svg
								viewBox="0 0 32 32"
								style="enable-background: new 0 0 32 32"
								xml:space="preserve"
							>
								<path
									fill="#202122"
									d="M22.3,3.6c-0.4-0.4-0.8-0.8-1.4-1.1l0.8-1.7c0.1-0.3,0-0.6-0.3-0.7c-0.3-0.1-0.6,0-0.7,0.3L20,1.9
              c-1-0.5-2.3-0.9-3.9-0.9c-1.6,0-3,0.3-4.1,0.9l-0.8-1.6C11.1,0,10.8-0.1,10.5,0c-0.3,0.1-0.4,0.4-0.3,0.7l0.8,1.7
              C8.8,3.9,7.7,6.2,7.6,7.9L7.5,8.5h16.9l-0.1-0.6C24.3,6.8,23.7,5.1,22.3,3.6z M8.7,7.5c0.5-2.3,2.7-5.3,7.4-5.3
              c1.5,0,2.7,0.4,3.7,0.9c0,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c1.9,1.1,2.9,3,3.2,4.3H8.7z M3.9,9.6c-1.5,0-2.6,1.2-2.6,2.7v7.5
              c0,1.5,1.2,2.7,2.6,2.7s2.6-1.2,2.6-2.7v-7.5C6.5,10.8,5.4,9.6,3.9,9.6z M5.5,19.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
              v-7.5c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V19.7z M28.1,9.6c-1.5,0-2.6,1.2-2.6,2.7v7.5c0,1.5,1.2,2.7,2.6,2.7
              s2.6-1.2,2.6-2.7v-7.5C30.7,10.8,29.6,9.6,28.1,9.6z M29.7,19.7c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6v-7.5
              c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6V19.7z M7.6,25.1h2.1v4.8c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.8h4.2v4.8
              c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.8h2.1V9.6H7.6V25.1z M8.6,10.7h14.7V24h-2.1v5.9c0,0.6-0.5,1.1-1.1,1.1
              c-0.6,0-1.1-0.5-1.1-1.1V24h-6.3v5.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V24H8.6V10.7z M13.4,4.3
              c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C14.4,4.7,13.9,4.3,13.4,4.3z M18.6,4.3
              c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C19.7,4.7,19.2,4.3,18.6,4.3z"
								></path>
							</svg>
						</div>
						<div class="uiux-app-design-content">
							<h4>UX/UI Design for Android App</h4>
							<p>
								Capitalise on open-source nature of the Android platform and customise your android
								app idea. Our designers are experts in integrating the latest features to your
								Android app.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-2">
			<div class="uiux-app-design-main-image uiux-mobile-app mt-30 fxt-uiux-mobile-app">
				<img
					src="../../../assets/images/uiux-mobile-app-design.png"
					alt="ux design agency london"
					class="mainimg"
				/>
				<div
					class="paroller one wow uiux-mobile-app-one"
					data-paroller-factor="0.3"
					data-paroller-type="foreground"
					data-paroller-direction="horizontal"
					style="transform: unset; transition: transform 0s linear 0s; will-change: transform"
				>
					<img
						src="../../../assets/images/uiux-mobile-app-design-no1.webp"
						alt="ui design services"
					/>
				</div>
				<div
					class="paroller two wow uiux-mobile-app-two"
					data-paroller-factor="0.2"
					data-paroller-type="foreground"
					data-paroller-direction="horizontal"
					style="transform: unset; transition: transform 0s linear 0s; will-change: transform"
				>
					<img
						src="../../../assets/images/uiux-mobile-app-design-no2.webp"
						alt="ux design services"
					/>
				</div>
				<div class="three wow uiux-mobile-app-three">
					<img
						src="../../../assets/images/uiux-mobile-app-design-no3.webp"
						alt="ux company"
					/>
				</div>
				<div
					class="paroller four wow uiux-mobile-app-four"
					data-paroller-factor="-0.2"
					data-paroller-type="foreground"
					data-paroller-direction="horizontal"
					style="transform: unset; transition: transform 0s linear 0s; will-change: transform"
				>
					<img
						src="../../../assets/images/uiux-mobile-app-design-no4.webp"
						alt="ui design and development"
					/>
				</div>
				<div
					class="paroller five wow uiux-mobile-app-five"
					data-paroller-factor="-0.3"
					data-paroller-type="foreground"
					data-paroller-direction="horizontal"
					style="transform: unset; transition: transform 0s linear 0s; will-change: transform"
				>
					<img
						src="../../../assets/images/uiux-mobile-app-design-no5.webp"
						alt="ui ux design services"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	mounted() {

		const container = document.querySelector('.fxt-uiux-mobile-app');
		const containerTop = document.querySelector('.fxt-uiux-mobile-app').offsetTop + 100;
		const containerBottom =
			document.querySelector('.fxt-uiux-mobile-app').offsetTop +
			document.querySelector('.fxt-uiux-mobile-app').offsetHeight;
		const container2 = document.querySelector('.uiux-mobile-app-one');
		const container3 = document.querySelector('.uiux-mobile-app-two');
		const container4 = document.querySelector('.uiux-mobile-app-four');
		const container5 = document.querySelector('.uiux-mobile-app-five');
		let lastScrollTop = 0;
		let x = 200;
		window.addEventListener(
			'scroll',
			function () {
				if (
					window.scrollY + window.innerHeight - 75 >= containerTop &&
					window.scrollY < containerBottom
				) {
					let st = window.pageYOffset || container.scrollTop;
					if (st > lastScrollTop) {
						x = x - 1.4;
						container2.style.transform = `translateX(${x*1.2}px)`
						container3.style.transform = `translateX(${x*1.2}px)`
						container4.style.transform = `translateX(${-x*1.2}px)`
						container5.style.transform = `translateX(${-x*1.2}px)`
					} else {
						x = x + 1.4;
						container2.style.transform = `translateX(${x*0.8}px)`
						container3.style.transform = `translateX(${x*0.5}px)`
						container4.style.transform = `translateX(${-x*0.5}px)`
						container5.style.transform = `translateX(${-x*0.8}px)`
					}
					lastScrollTop = st <= 0 ? 0 : st;
				} else {
					x = 200;
				}
			},
			false
		);
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.uiux-app-design-box {
	max-width: 360px;
	width: 100%;
	margin: 0 auto;
}
.uiux-app-design-icon {
	width: 90px;
	height: 90px;
	border: 1px solid #f6f8fa;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.uiux-app-design-icon svg {
	width: 52px;
	height: 52px;
}
.uiux-app-design-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
.uiux-app-design-content h4 {
	font-size: 20px;
	font-weight: 500;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.uiux-app-design-content p:last-child {
	margin-bottom: 0;
}
.uiux-app-design-box .uiux-app-design-icon svg path,
.uiux-app-design-box .uiux-app-design-icon svg  {
	transition:none
}
.uiux-app-design-box:hover .uiux-app-design-icon svg path,
.uiux-app-design-box:hover .uiux-app-design-icon svg g{
	fill:#fff
}
.uiux-app-design-box:hover .uiux-app-design-icon {
	background-color: #008dd2;
	fill:#fff
}
.uiux-app-design-box:hover .uiux-app-design-content h4 {
	color: #008dd2;
}
.uiux-app-design-main .container-2 {
	max-width: 1500px;
}
.uiux-app-design-main-image.uiux-mobile-app {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.uiux-app-design-main-image.uiux-mobile-app .mainimg {
	opacity: 0;
	visibility: hidden;
	display: block;
}
.uiux-app-design-main-image.uiux-mobile-app .one {
	z-index: 1;
	left: 2%;
	width: 16%;
}

.uiux-app-design-main-image.uiux-mobile-app > div {
	position: absolute;
	top: 0;
}
.uiux-app-design-main-image.uiux-mobile-app .two {
	z-index: 2;
	left: 20%;
	width: 16.5%;
}

.uiux-app-design-main-image.uiux-mobile-app .three {
	z-index: 3;
	width: 18.5%;
	left: 50%;
	transform: translateX(-50%);
}
.uiux-app-design-main-image.uiux-mobile-app .four {
	z-index: 2;
	right: 20%;
	width: 16.5%;
}
.uiux-app-design-main-image.uiux-mobile-app .five {
	z-index: 1;
	right: 2%;
	width: 16%;
}
</style>
