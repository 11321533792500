<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					What Makes Genixbit Your best Unity 2D 3D Game Development Partner
				</h2>
				<p>
					When a client thinks of a game development project, hitting the defined goals is of the
					up-most importance. We acquire the required capabilities to meet the goals of clients with
					our 3D mobile game development for Clients based in the UK and abroad.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-xl-5 col-lg-6 col-12 col-left d-none d-lg-block p-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/our-expert-build.webp"
							style=""
						/>
					</div>
				</div>
				<div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 col-right p-0 bg-light">
					<div class="our-expert-build-content-wrap">
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M84.7,0H40.2v3.5h42.2v14.2h14.3v78.7H40.2v3.5H100V15.3L84.7,0z M85.7,14.3V5.8l8.6,8.5H85.7z M46.7,89.5h11v-11h-11
                    V89.5z M50.2,82h4.2v4.2h-4.2V82z M61.7,81.8h15.2v-3.3H61.7V81.8z M61.7,89.5h30.2v-3.3H61.7V89.5z M57.7,63.3h-11v11h11V63.3z
                    M54.3,71h-4.2v-4.2h4.2V71z M61.7,66.8h15.2v-3.3H61.7V66.8z M61.7,74.3h30.2V71H61.7V74.3z M61.7,51.7h15.2v-3.3H61.7V51.7z
                    M61.7,59.2h30.2v-3.3H61.7V59.2z M51.2,59.2l7.2-7.2L56,49.7l-4.8,4.7l-2.7-2.7l-2.3,2.5L51.2,59.2z M61.7,36.5h15.2v-3.3H61.7
                    V36.5z M61.7,44.2h30.2v-3.3H61.7V44.2z M51.2,44l7.2-7L56,34.5l-4.8,4.8l-2.7-2.8L46.2,39L51.2,44z M61.7,21.5h15.2v-3.3H61.7
                    V21.5z M61.7,29h30.2v-3.3H61.7V29z M51.2,29l7.2-7.2L56,19.3l-4.8,4.8l-2.7-2.7l-2.3,2.3L51.2,29z M50.3,10.5h-3.8v3.3h3.8V10.5z
                    M58,10.5h-3.8v3.3H58V10.5z M61.7,10.5v3.3h3.8v-3.3H61.7z M21,23.3c6.3,0,11.7-5.2,11.7-11.7C32.7,5.2,27.5,0,21,0
                    S9.3,5.2,9.3,11.7C9.3,18.2,14.5,23.3,21,23.3z M21,3.5c4.5,0,8.2,3.7,8.3,8.3c0,4.7-3.7,8.3-8.3,8.3c-4.7,0-8.3-3.8-8.3-8.3
                    C12.7,7.2,16.5,3.5,21,3.5z M19.2,99.8h3.3V70h-3.3V99.8z M42.3,39.3c0-3.3-1.3-6.5-3.7-8.8c-2.5-2.3-5.5-3.7-8.8-3.7h-2.5
                    L21.2,33L15,26.8h-2.5c-3.3,0-6.5,1.3-8.8,3.7C1.3,33,0,36,0,39.3V66h8.5v34h3.3V58.7H8.5v4H3.3V39.5c0-2.3,1-4.7,2.7-6.3
                    c1.7-1.7,4-2.7,6.3-2.7h1.2L21,38l7.5-7.5h1c2.3,0,4.7,1,6.3,2.7c1.7,1.7,2.7,4,2.7,6.3v23.2h-5.3v-4h-3.3V100h3.3V66h8.7
                    L42.3,39.3z M19.2,44.7h3.3v-3.8h-3.3V44.7z M19.2,52.3h3.3v-3.8h-3.3V52.3z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Dedicated Experienced Professionals</h4>
								<p>
									At GenixBit Labs we ensure giving a dedicated team of AR game development
									professional, who are solely responsible to accomplish your task and assist you in
									attaining set milestones.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M30.1,16.9l-0.9-0.2c-0.1-0.3-0.2-0.6-0.4-0.9l0.5-0.7c0.2-0.3,0.2-0.7-0.1-0.9l-1.5-1.5c-0.2-0.2-0.7-0.3-0.9-0.1
                    l-0.8,0.4c-0.3-0.2-0.5-0.2-0.9-0.4l-0.2-0.9c-0.1-0.4-0.4-0.6-0.8-0.6h-2.1c-0.4,0-0.7,0.2-0.8,0.6l-0.2,0.9
                    c-0.3,0.1-0.6,0.2-0.9,0.4l-0.7-0.5c-0.3-0.2-0.7-0.2-0.9,0.1l-1.5,1.5c-0.2,0.2-0.3,0.7-0.1,0.9l0.5,0.7
                    c-0.2,0.3-0.2,0.5-0.4,0.9l-0.9,0.2c-0.4,0.1-0.6,0.4-0.6,0.8v2.1c0,0.4,0.2,0.7,0.6,0.8l0.9,0.2c0.1,0.3,0.2,0.6,0.4,0.9
                    l-0.5,0.7c-0.2,0.3-0.2,0.7,0.1,0.9l1.5,1.5c0.2,0.2,0.7,0.3,0.9,0.1l0.7-0.5c0.3,0.2,0.5,0.2,0.9,0.4l0.2,0.9
                    c0.1,0.4,0.4,0.6,0.8,0.6h2.1c0.4,0,0.7-0.2,0.8-0.6l0.2-0.9c0.3-0.1,0.6-0.2,0.9-0.4l0.7,0.5c0.3,0.2,0.7,0.2,0.9-0.1l1.5-1.5
                    c0.2-0.2,0.3-0.7,0.1-0.9l-0.5-0.7c0.2-0.3,0.2-0.5,0.4-0.9l0.9-0.2c0.4-0.1,0.6-0.4,0.6-0.8v-2.1C30.8,17.3,30.4,17,30.1,16.9z
                    M29.2,19.1l-0.7,0.1c-0.3,0.1-0.5,0.3-0.6,0.5c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.2-0.2,0.5,0,0.9l0.4,0.6l-0.5,0.5l-0.5-0.4
                    c-0.2-0.2-0.5-0.2-0.9,0c-0.4,0.2-0.9,0.5-1.3,0.5c-0.3,0.1-0.5,0.3-0.5,0.6l-0.2,0.7h-0.8l-0.2-0.7c-0.1-0.3-0.3-0.5-0.5-0.6
                    c-0.5-0.1-0.9-0.3-1.4-0.5c-0.2-0.2-0.5-0.2-0.9,0l-0.6,0.4l-0.5-0.5l0.4-0.6c0.2-0.2,0.2-0.5,0-0.9c-0.2-0.4-0.5-0.9-0.5-1.3
                    c-0.1-0.3-0.3-0.5-0.6-0.5L17.3,19v-0.8l0.7-0.2c0.3-0.1,0.5-0.3,0.6-0.5c0.1-0.5,0.3-0.9,0.5-1.3c0.2-0.2,0.2-0.5,0-0.9l-0.4-0.6
                    l0.5-0.5l0.6,0.4c0.2,0.2,0.5,0.2,0.9,0c0.5-0.2,0.9-0.5,1.4-0.5c0.3-0.1,0.5-0.3,0.5-0.6l0.2-0.7h0.8l0.2,0.7
                    c0.1,0.3,0.3,0.5,0.5,0.6c0.5,0.2,0.9,0.3,1.3,0.5c0.2,0.2,0.5,0.2,0.9,0l0.6-0.4l0.5,0.5l-0.4,0.6c-0.2,0.2-0.2,0.5,0,0.9
                    c0.2,0.4,0.5,0.9,0.5,1.3c0.1,0.3,0.3,0.5,0.6,0.5l0.7,0.2L29.2,19.1L29.2,19.1z"
											></path>
										</g>
										<g>
											<path
												d="M23.3,15.4c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3C26.6,16.8,25.1,15.4,23.3,15.4z M23.3,20.4
                    c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8C25,19.7,24.3,20.4,23.3,20.4z"
											></path>
										</g>
										<g>
											<path
												d="M47.4,31.1l-4-7.3c2.4-13.3-9-25.8-23.1-23.6C2.2,3.1-3.5,27.5,12.9,37.5v11.7c0,0.4,0.3,0.8,0.8,0.8h18.8
                    c0.4,0,0.8-0.3,0.8-0.8v-5.1h4.5c2.2,0,3.9-1.8,3.9-3.9v-5.9l5.3-2c0.2-0.1,0.4-0.2,0.5-0.5C47.5,31.6,47.5,31.4,47.4,31.1z
                    M40.7,33.1c-0.3,0.1-0.5,0.4-0.5,0.7v6.4c0,1.3-1.1,2.4-2.4,2.4h-5.2c0,0,0,0-0.1,0c0,0,0,0-0.1,0s-5.9,0.7-8.8-1.9
                    c-0.3-0.3-0.8-0.2-1.1,0.1c-0.3,0.3-0.2,0.8,0.1,1.1c2.3,2,6,2.5,9.1,2.3v4.4H14.5V37.1c0-0.2-0.2-0.5-0.4-0.6
                    c-15.6-9-10.4-32,6.5-34.6c13.1-2.1,23.8,9.8,21.3,22.1c-0.1,0.2,0,0.5,0.2,0.7l3.5,6.5L40.7,33.1z"
											></path>
										</g>
										<g>
											<path
												d="M23.3,6.2c0.4,0,0.8-0.3,0.8-0.8V4.3c0-0.4-0.3-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.8v1.2C22.5,5.9,22.8,6.2,23.3,6.2z"
											></path>
										</g>
										<g>
											<path
												d="M13.5,9.8c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l-0.8-0.8c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1L13.5,9.8z"
											></path>
										</g>
										<g>
											<path
												d="M10.3,17.6H9.1c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h1.2c0.4,0,0.8-0.3,0.8-0.8C11,17.9,10.7,17.6,10.3,17.6z"
											></path>
										</g>
										<g>
											<path
												d="M37.5,17.6h-1.2c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h1.2c0.4,0,0.8-0.3,0.8-0.8C38.2,17.9,37.9,17.6,37.5,17.6z
                    "
											></path>
										</g>
										<g>
											<path
												d="M32.8,7.9l-0.9,0.9c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0L33.9,9c0.3-0.3,0.3-0.8,0-1.1
                    C33.6,7.6,33.1,7.6,32.8,7.9z"
											></path>
										</g>
										<g>
											<path
												d="M24.5,7.9C18,7.2,12.7,12.3,12.7,18.4c0,5.8,3.5,6.9,5,10.4H16c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h2.1
                    c0.2,1.2,0.2,2.1,0.2,2.7h-2.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h2.3v0.8c0,1.8,1.4,3.2,3.2,3.2H25
                    c1.8,0,3.2-1.4,3.2-3.2v-0.8h2.3c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8h-2.3c0-0.5-0.1-1.4,0.2-2.7h2.1
                    c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H29c1.6-3.7,5.8-5.5,4.8-12C33.1,12.4,28.9,8.3,24.5,7.9z M26.8,35.1
                    c0,0.9-0.8,1.6-1.6,1.6h-3.6c-0.9,0-1.6-0.8-1.6-1.6v-0.8h7L26.8,35.1L26.8,35.1z M26.8,32.8h-7c0-0.5,0.1-1.4-0.2-2.7h7.3
                    C26.7,31.6,26.8,32.3,26.8,32.8z M27.3,28.7h-8c-1.4-4.2-5.1-4.9-5.1-10.4c0-4.9,4-9,9.1-9c4.5,0,8.5,4,9,7.6
                    C33.2,23.1,29,24.3,27.3,28.7z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Keeping You Updated</h4>
								<p>
									Being one of the leading Augmented Reality game development company in the UK, we
									consider it our utmost responsibility to keep you updated on the progress of your
									AR mobile game development on a real-time basis.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												class="st0"
												d="M25,5.4c0.4,0,0.7-0.3,0.7-0.7v-3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v3C24.2,5,24.5,5.4,25,5.4z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M38.8,19.2c0,0.4,0.3,0.7,0.7,0.7h2.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-2.9
                    C39.1,18.4,38.8,18.7,38.8,19.2z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M7.6,19.9h2.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7H7.6c-0.4,0-0.7,0.3-0.7,0.7
                    C6.8,19.6,7.1,19.9,7.6,19.9z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M35.8,9.4l2.1-2.1c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0l-2.1,2.1c-0.3,0.3-0.3,0.7,0,1
                    C35,9.7,35.5,9.7,35.8,9.4z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M14.2,9.4c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1l-2.1-2.1c-0.3-0.3-0.7-0.3-1,0c-0.3,0.3-0.3,0.7,0,1
                    L14.2,9.4z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M38.7,24c-0.4-0.1-0.8,0-1,0.4c-0.1,0.4,0,0.8,0.4,1l2.8,1.1c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.6-0.1,0.7-0.4
                    c0.1-0.4,0-0.8-0.4-1L38.7,24z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M8.5,13.2l2.8,1.1c0.4,0.1,0.8,0,1-0.4c0.1-0.4,0-0.8-0.4-1l-2.8-1.1c-0.4-0.1-0.8,0-1,0.4
                    C8,12.7,8.2,13.1,8.5,13.2z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M30.2,6.4c0.4,0.1,0.8,0,1-0.4l1.1-2.8c0.1-0.4,0-0.8-0.4-1s-0.8,0-1,0.4l-1.2,2.8
                    C29.6,5.8,29.8,6.2,30.2,6.4z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M18.7,6.1c0.1,0.4,0.6,0.6,1,0.4c0.4-0.1,0.5-0.6,0.4-1l-1.1-2.7c-0.1-0.4-0.6-0.5-1-0.4
                    c-0.4,0.1-0.5,0.5-0.4,0.9L18.7,6.1z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M11.3,24.2l-2.7,1.1c-0.4,0.1-0.5,0.6-0.4,1c0.1,0.4,0.6,0.5,1,0.4l2.7-1.1c0.4-0.1,0.5-0.6,0.4-1
                    C12.1,24.2,11.7,23.9,11.3,24.2z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M37.6,13.8c0.1,0.4,0.6,0.5,1,0.4l2.7-1.1c0.4-0.1,0.5-0.6,0.4-1s-0.6-0.5-1-0.4l-2.6,1.1
                    C37.7,13,37.5,13.4,37.6,13.8z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M31.6,35h-1.2c0.3-2.7,1.4-5.3,3.4-7.4c2.2-2.4,3.4-5.4,3.4-8.7c0-7.1-5.9-12.8-12.9-12.4
                    c-6,0.4-10.9,5.2-11.4,11.3c-0.3,3.7,1,7.3,3.5,9.9c1.9,2,3.1,4.6,3.4,7.3h-1.2c-0.7,0-1.2,0.5-1.2,1.2v4.7c0,0.7,0.5,1.2,1.2,1.2
                    l0,0h3v1.6h-1.4c-0.4,0-0.7,0.4-0.7,0.7c0,0.4,0.4,0.7,0.7,0.7l0,0h1.4c0.2,1.3,1.3,2.4,2.8,2.4l0,0H26c1.4,0,2.5-1,2.7-2.4h1.4
                    c0.4,0,0.7-0.4,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-1.3V42h3c0.7,0,1.2-0.5,1.2-1.2v-4.7C32.8,35.5,32.2,35,31.6,35z M17.4,26.7
                    c-2.2-2.3-3.4-5.5-3.1-8.8c0.4-5.4,4.8-9.6,10-10c6.2-0.4,11.3,4.7,11.3,10.9c0,2.9-1,5.6-3,7.7c-2.2,2.3-3.5,5.3-3.8,8.4
                    c-2.1,0-1,0-7.7,0.1C21,31.9,19.6,29,17.4,26.7z M27.1,44.7c0,0.7-0.6,1.3-1.3,1.3h-1.6v0.7V46c-0.7,0-1.3-0.6-1.3-1.3V42h4.2
                    L27.1,44.7z M18.9,40.6v-4.1c1.9,0,11.4-0.1,12.4-0.1v4.1C30.5,40.5,28.6,40.5,18.9,40.6z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M47.2,22.7c-1.3,0-2.4,1-2.4,2.4v7.1l-0.9,1c-0.4-0.9-1.3-1.7-2.5-1.6c-0.7,0.1-1.3,0.4-1.6,0.9l-4,5.1
                    c-0.4,0.5-0.5,1-0.5,1.6v4.4h-0.7c-0.4,0-0.9,0.4-0.9,0.9v4.7c0,0.4,0.4,0.9,0.9,0.9h10.7c0.4,0,0.9-0.4,0.9-0.9v-4.7
                    c0-0.4-0.4-0.9-0.9-0.9h-1v-1.1c0-0.4,0.1-0.7,0.3-1l4.5-6.3c0.2-0.3,0.3-0.7,0.3-1v-9.1C49.6,23.8,48.5,22.7,47.2,22.7z
                    M44.7,48.6h-9.5v-3.5h9.5L44.7,48.6L44.7,48.6z M48.1,34.1c0,0.1,0,0.2-0.1,0.2l-4.5,6.3c-0.4,0.5-0.5,1.2-0.5,1.9v1.1h-6.2v-4.4
                    c0-0.3,0.1-0.6,0.2-0.7l3.9-5.1c0.1-0.1,0.4-0.3,0.6-0.3c0.7-0.1,1.5,1.1,0.8,1.9l-0.7,0.8c-0.2,0.3-0.2,0.7,0.1,1
                    c0.3,0.3,0.7,0.2,1-0.1l3.3-3.8c0.1-0.1,0.2-0.4,0.2-0.5v-7.3c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9V34.1z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M15.4,43.6h-0.7v-4.4c0-0.6-0.1-1.2-0.5-1.7l-3.9-5.1c-0.8-1-2.4-1.2-3.4-0.2l-0.1,0.1
                    c-0.3,0.3-0.5,0.6-0.7,1l-0.9-1v-7.1c0-1.3-1-2.4-2.4-2.4s-2.4,1-2.4,2.4v9c0,0.4,0.1,0.7,0.4,1.1l4.5,6.2c0.2,0.3,0.3,0.7,0.3,1
                    v1.1h-1c-0.4,0-0.9,0.4-0.9,0.9v4.7c0,0.5,0.4,0.9,0.9,0.9h10.7c0.4,0,0.9-0.4,0.9-0.9v-4.7C16.3,44,15.8,43.6,15.4,43.6z
                    M6.5,40.7L2,34.4c-0.1-0.1-0.1-0.1-0.1-0.2v-9.1c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v7.3c0,0.1,0.1,0.4,0.2,0.5l3.3,3.8
                    c0.3,0.3,0.7,0.4,1,0.1c0.3-0.3,0.4-0.7,0.1-1L7.6,35c-0.4-0.5-0.4-1.2,0.1-1.6l0.1-0.1c0.4-0.4,0.9-0.3,1.2,0.1l3.9,5
                    c0.1,0.2,0.2,0.5,0.2,0.8v4.4H7v-1.1C7,41.9,6.9,41.2,6.5,40.7z M14.8,48.6H5.3v-3.5h9.5L14.8,48.6L14.8,48.6z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M28.7,25.6l1.3,1c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2l1.3-1.3c0.3-0.3,0.3-0.7,0.1-1l-1-1.3
                    c0.3-0.6,0.6-1.2,0.7-1.8l1.6-0.2c0.4-0.1,0.7-0.4,0.7-0.7v-1.9c0-0.4-0.3-0.7-0.7-0.7l-1.6-0.2c-0.1-0.7-0.4-1.3-0.7-1.8l1-1.3
                    c0.2-0.3,0.1-0.7-0.1-1l-1.3-1.4c-0.3-0.3-0.7-0.3-1-0.1l-1.3,1c-0.6-0.3-1.2-0.6-1.8-0.7l-0.2-1.6C26.6,10,26.3,9.7,26,9.7H24
                    c-0.4,0-0.7,0.3-0.7,0.7L23.1,12c-0.7,0.1-1.3,0.4-1.8,0.7l-1.3-1c-0.3-0.2-0.7-0.2-1,0.1l-1.4,1.4c-0.3,0.3-0.3,0.7-0.1,1l1,1.3
                    c-0.3,0.6-0.6,1.2-0.7,1.8l-1.6,0.2c-0.4,0.1-0.7,0.4-0.7,0.7v1.9c0,0.4,0.3,0.7,0.7,0.7l1.6,0.2c0.1,0.7,0.4,1.3,0.7,1.8l-1,1.3
                    c-0.2,0.3-0.2,0.7,0.1,1l1.4,1.4c0.3,0.3,0.7,0.3,1,0.1l1.3-1c0.6,0.3,1.2,0.6,1.8,0.7l0.2,1.6c0.1,0.4,0.4,0.7,0.7,0.7H26
                    c0.4,0,0.7-0.3,0.7-0.7l0.2-1.6C27.5,26.2,28.2,25.9,28.7,25.6z M26.1,25.1c-0.3,0.1-0.5,0.3-0.6,0.7l-0.2,1.4h-0.7l-0.2-1.4
                    c-0.1-0.3-0.3-0.6-0.6-0.7c-0.8-0.1-1.6-0.4-2.2-0.9c-0.3-0.1-0.6-0.1-0.9,0l-1.1,0.9l-0.4-0.4l0.8-1.1c0.2-0.2,0.2-0.7,0-0.9
                    c-0.4-0.7-0.7-1.4-0.9-2.2c-0.1-0.3-0.3-0.5-0.7-0.6l-1.4-0.2v-0.7l1.4-0.2c0.3-0.1,0.6-0.3,0.6-0.6c0.1-0.8,0.4-1.6,0.9-2.2
                    c0.1-0.3,0.1-0.6,0-0.9L19,13.8l0.4-0.4l1.1,0.9c0.2,0.1,0.7,0.2,0.9,0c0.7-0.4,1.4-0.7,2.2-0.9c0.3-0.1,0.5-0.3,0.6-0.7l0.2-1.4
                    h0.7l0.2,1.4c0.1,0.3,0.3,0.6,0.6,0.7c0.8,0.1,1.6,0.4,2.2,0.9c0.3,0.1,0.6,0.1,0.9,0l1.1-0.9l0.4,0.4l-0.9,1.1
                    c-0.2,0.2-0.2,0.6,0,0.9c0.4,0.7,0.7,1.4,0.9,2.2c0.1,0.3,0.3,0.5,0.6,0.6l1.4,0.2v0.7l-1.4,0.2c-0.3,0.1-0.6,0.3-0.7,0.6
                    c-0.1,0.8-0.4,1.6-0.9,2.2c-0.1,0.3-0.1,0.6,0,0.9l0.9,1.1l-0.4,0.4L29,24.2c-0.2-0.2-0.6-0.2-0.9,0
                    C27.7,24.6,26.9,24.9,26.1,25.1z"
											></path>
										</g>
										<g>
											<path
												class="st0"
												d="M29.9,19.2c0-2.7-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9S29.9,21.9,29.9,19.2z M25,22.6
                    c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C28.4,21,26.8,22.6,25,22.6z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Innovatively Creative</h4>
								<p>
									Team GenixBit Labs firmly believes that the key to lead the AR game development
									market is to ensure bringing innovative and creative solutions for all augmented
									reality mobile game development project we receive.
								</p>
							</div>
						</div>
						<div class="our-expert-build-box d-flex align-items-center">
							<div class="our-expert-build-icon">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 100 100"
									style="enable-background: new 0 0 100 100"
									xml:space="preserve"
								>
									<g>
										<g>
											<path
												d="M66.7,55c-5.5,0-10,4.5-10,10c0,2.8,1.2,5.6,3.3,7.5v9.2c0,0.9,0.7,1.7,1.7,1.7c0.3,0,0.7-0.1,0.9-0.3l4.1-2.7l4.1,2.7
                    c0.8,0.5,1.8,0.3,2.3-0.5c0.2-0.3,0.3-0.6,0.3-0.9v-9.2c4.1-3.7,4.5-10,0.8-14.1C72.2,56.2,69.5,55,66.7,55z M70,78.6l-2.4-1.6
                    c-0.6-0.4-1.3-0.4-1.9,0l-2.4,1.6v-4.1c2.2,0.8,4.5,0.8,6.7,0V78.6z M66.7,71.7c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7
                    c3.7,0,6.7,3,6.7,6.7C73.3,68.7,70.3,71.7,66.7,71.7z M60,16.7H38.3V20H60V16.7z M76.7,26.7H23.3V30h53.3V26.7z M60,36.7H23.3V40
                    H60V36.7z M66.7,40h10v-3.3h-10V40z M76.7,46.7H23.3V50h53.3V46.7z M43.3,56.7h-20V60h20V56.7z M31.7,65h-8.3v3.3h8.3V65z
                    M38.3,68.3h5V65h-5V68.3z M91.7,0h-70c-4.6,0-8.3,3.7-8.3,8.3v78.3H1.7c-0.9,0-1.7,0.7-1.7,1.7v3.3c0,4.6,3.7,8.3,8.3,8.3h70
                    c4.6,0,8.3-3.7,8.3-8.3V13.3h11.7c0.9,0,1.7-0.7,1.7-1.7V8.3C100,3.7,96.3,0,91.7,0z M8.3,96.7c-2.8,0-5-2.2-5-5V90H70v1.7
                    c0,1.8,0.6,3.6,1.7,5H8.3z M84.9,3.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.3,0.6-0.5,0.9C84.1,5,84,5.2,83.9,5.3
                    c-0.1,0.3-0.2,0.7-0.3,1c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.5-0.2,1-0.2,1.5v83.3c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5v-3.3
                    c0-0.9-0.7-1.7-1.7-1.7h-55V8.3c0-2.8,2.2-5,5-5H85C85,3.4,84.9,3.5,84.9,3.5z M96.7,10h-10V8.3c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5
                    V10z"
											></path>
										</g>
									</g>
								</svg>
							</div>
							<div class="our-expert-build-content">
								<h4>Satisfaction Guaranteed</h4>
								<p>
									We find our key to growth in delivering satisfaction to our clients. No project is
									called closed until it matches the client’s expectation. This includes entire
									development cycle.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.our-expert-build-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 12px 25px;
	position:relative;
}

.our-expert-build-box::before {
	content:"";
	position:absolute;
	top:50%;
	left:-15px;
	width:0;
	height:0;
	display:block;
	border:15px solid transparent;
	border-right-color:#008dd2;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	opacity:0;
	transform:translate(-50%,-50%)
}
.our-expert-build-box:hover::before {
	opacity:1
}
.our-expert-build-box:hover h4,
.our-expert-build-box:hover p {
	color: #fff !important;
}
.our-expert-build-box:hover {
	background: #008dd2;
}

.our-expert-build-box:hover .our-expert-build-icon svg circle,
.our-expert-build-box:hover .our-expert-build-icon svg ellipse,
.our-expert-build-box:hover .our-expert-build-icon svg path,
.our-expert-build-box:hover .our-expert-build-icon svg polygon,
.our-expert-build-box:hover .our-expert-build-icon svg rect {
	fill: #fff;
}

.our-expert-build-icon svg circle,
.our-expert-build-icon svg ellipse,
.our-expert-build-icon svg path,
.our-expert-build-icon svg polygon,
.our-expert-build-icon svg rect {
	fill: black;
}

.our-expert-build-icon svg {
	width: 50px;
	height: 50px;
	fill: black;
}
.our-expert-build-content-wrap{
	padding-bottom:8px !important;
}
.our-expert-build-icon {
	margin-right:20px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 70px;
	min-height: 70px;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
