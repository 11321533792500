<template>
	<section class="bg-white">
		<div v-if="itemsCount">
			<ImageWithInfo :obj="items[0]"></ImageWithInfo>
		</div>
		<VueSlickCarousel
			v-else
			v-bind="settings"
			class="portfolio-slider px-2"
			style="overflow-x: hidden"
		>
			<div
				style="width: 100%; display: inline-block; margin: 0; padding: 0"
				v-for="(item, index) in items"
				:key="index"
			>
				<ImageWithInfo :obj="item"></ImageWithInfo>
			</div>
		</VueSlickCarousel>
	</section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import ImageWithInfo from '../AssetComponents/ImageWithInfo.vue';
export default {
	components: {
		VueSlickCarousel,
		ImageWithInfo
	},
	computed: {
		itemsCount() {
			if (this.items.length > 1) return false;
			return true;
		}
	},
	data() {
		return {
			settings: {
				slidesToShow: 1,
				autoplay: true,
				dotsClass: 'slick-dots custom-dot-class',
				dots: false,
				variableWidth: true
			},
			items: [
				{
					color: 'dattingbg',
					logo: '/datingapp-logo.png',
					image1: '/datingapp-main-mobile11.png',
					image2: '/datingapp-main-mobile2.png',
					image3: '/datingapp-main-mobile.png',
					title: 'Speed Dating App',
					description: 'A Dating App That Helps Singles Find Speed Dating Events',
					paragraphs: [
						'Speed dating a culture that has taken hold of the entire world. The idea of speed dating is to help singles find soon-to-happen dating events with the help of a mobile app. GenixBit Labs built an app using which users can register for speed dating events, attend them in person, try matching with several other singles and ultimately take forward the conversation with the built-in chat feature.',
						'The app will showcase the list of dating events that will happen. Each event participant can take turns to meet other participants based on rounds. They can taking further chances once they have found a match.',
						'Unlike traditional dating apps that make users swipe right and left endlessly, the speed dating app we built was designed to help singles find their perfect matches in person.',
						'Here are some of its highlight features:'
					],
					features: [
						'Personalized profile creation',
						'Social media integration',
						'Emojis and rich media chat',
						'List of dating events',
						'10-day free chat with matches',
						'Confidential user details'
					]
				}
			]
		};
	}
};
</script>

<style scoped>
.portfolio-slider {
	padding: 0 !important;
}
.portfolio-slider button {
	display: none;
}
</style>