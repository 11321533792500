<template>
	<div>
		<div class="key-features-box box-shadow rounded10">
			<div class="key-features-icon" v-html="obj.svg"></div>
			<h4>{{ obj.title }}</h4>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		obj: {
			required: true,
			type: Object,
			default: () => {}
		}
	}
};
</script>

<style>
.key-features-box {
    min-height: 210px;
    padding: 30px 15px 20px;
    text-align: center;
    border: 2px solid #ecedee;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: relative;
    overflow: hidden;
}
.box-shadow {
    -webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
    -moz-box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.rounded10 {
    border-radius: 10px!important;
}
.key-features-box:before {
    content: "";
    display: block;
    width: 55px;
    height: 55px;
    display: block;
    position: absolute;
    bottom: -27px;
    left: -27px;
    background-color: rgba(236,115,35,.05);
    border-radius: 100%;
}

.key-features-icon {
    width: 90px;
    height: 90px;
    background-color: #eef2f3;
    border-radius: 100%;
    margin: 0 auto 25px;
    position: relative;
}
.key-features-box h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    min-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.key-features-icon svg {
    width: 60px;
    height: 60px;
    position: absolute;
    right: -10px;
    bottom: 5px;
}
.key-features-icon svg *{
    fill:#0082dd
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
.key-features-box:hover {
    border: 2px solid #0082dd;
}
</style>
