<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>Why Choose Us</span>
				<h2>Our USPs as a Laravel development company</h2>
				<p>
					Bespoke to your business requirements. Data security as a paramount priority. Design that
					speaks. These are some factors that has made our customers happy.
				</p>
			</div>
			<div class="m-height-170">
				<div class="row align-items-center">
					<div
						v-for="item in items"
						v-bind:key="item.title"
						class="col-lg-6 col-md-6 col-sm-12 col-12"
					>
						<BigWidthCard v-bind:obj="item"></BigWidthCard>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BigWidthCard from '../AssetComponents/BigWidthCard.vue';
export default {
	components: {
		BigWidthCard
	},
	data: () => {
		return {
			items: [
				{
               title:"Customer Satisfaction",
               text:"At GenixBit Labs we have an organizational culture where only one thing matters the most - customer satisfaction. We take good care of our employees so that they take good care of our customers.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M50,65.9c6.7,0,12.3-5.5,12.3-12.1c0-6.7-5.4-12.1-12.3-12.1c-6.7,0-12.3,5.5-12.3,12.1C37.7,60.6,43.3,65.9,50,65.9z
                              M50,44.8c5,0,9.2,4.1,9.2,9.1c0,5-4.1,9.1-9.2,9.1c-5,0-9.2-4.1-9.2-9.1C40.8,48.9,45,44.8,50,44.8z M35.5,12.5l6.1,6.2L40,27.3
                              c-0.2,0.6,0.2,1.2,0.6,1.5c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1L58,29c0.5,0.3,1.1,0.2,1.7-0.2c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6
                              l6.4-6.2c0.5-0.5,0.6-1.1,0.5-1.5c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2l-3.9-7.9c-0.5-1.1-2.3-1.1-2.7,0L45,8.7l-8.8,1.2
                              C35.6,10,35.2,10.4,35,11C34.9,11.6,35,12.2,35.5,12.5z M45.9,11.6c0.5,0,0.9-0.5,1.2-0.8L50,4.7l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8
                              l6.5,1.1L55.9,17c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5l-5.8-3c-0.2-0.2-0.5-0.2-0.8-0.2c-0.3,0-0.5,0-0.8,0.2l-5.8,3l1.1-6.5
                              c0.2-0.5-0.2-1.1-0.5-1.4l-4.7-4.6L45.9,11.6z M24.7,37.4c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6l6.4-6.2c0.5-0.5,0.6-1.1,0.5-1.5
                              c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2l-3.9-8c-0.3-0.5-0.8-0.9-1.4-0.9c-0.6,0-1.1,0.3-1.4,0.9L10,17l-8.8,1.2
                              c-0.6,0.2-1.1,0.5-1.2,1.1c-0.2,0.6,0,1.2,0.5,1.5l6.4,6.4l-1.5,8.6C5.2,36.4,5.5,37,6,37.4c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1
                              l7.9,4.1C23.7,37.8,24.3,37.7,24.7,37.4z M16.1,30.2c-0.5-0.3-0.9-0.3-1.4,0l-5.8,3l1.1-6.5c0.2-0.5-0.2-1.1-0.5-1.4l-4.7-4.6
                              l6.5-0.9c0.5,0,0.9-0.5,1.2-0.8l2.9-5.9l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8l6.5,0.9l-4.7,4.6c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5
                              L16.1,30.2z M99.9,19.5c-0.2-0.6-0.6-0.9-1.2-1.1l-8.8-1.2L86,9.3c-0.5-1.1-2.3-1.1-2.7,0l-3.9,7.9l-8.8,1.2
                              c-0.6,0.2-1.1,0.5-1.2,1.1c-0.2,0.6,0,1.2,0.5,1.5l6.4,6.2l-1.5,8.6c-0.2,0.6,0.2,1.2,0.6,1.5c0.5,0.3,1.1,0.5,1.7,0.2l7.9-4.1
                              l7.9,4.1c0.5,0.3,1.1,0.2,1.7-0.2c0.5-0.3,0.8-0.9,0.6-1.5l-1.5-8.6l6.4-6.2C99.9,20.5,100.1,19.9,99.9,19.5z M90.4,25.5
                              c-0.3,0.3-0.5,0.9-0.5,1.4l1.1,6.5l-5.8-3c-0.2-0.1-0.5-0.1-0.8-0.1c-0.3,0-0.5,0-0.8,0.1l-5.8,3l1.1-6.5c0.2-0.5-0.2-1.1-0.5-1.4
                              L73.9,21l6.5-0.9c0.5,0,0.9-0.5,1.2-0.8l2.9-5.9l2.9,5.9c0.2,0.5,0.6,0.8,1.2,0.8l6.5,0.9L90.4,25.5z M78.4,64.4h-1.4
                              c0.3-1.7,0.5-3.5,0.5-5.3c0-15.2-12.4-27.6-27.5-27.6S22.5,43.7,22.5,59c0,15.2,12.3,27.6,27.5,27.6c3.8,0,7.4-0.8,10.9-2.3
                              C62,93.2,69.4,100,78.4,100c9.8,0,17.7-8,17.7-17.7C96.2,72.4,88.3,64.4,78.4,64.4z M25.5,59.3c0-13.7,11-24.7,24.5-24.7
                              s24.5,10.9,24.5,24.6c0,2-0.3,3.9-0.8,5.8c-3.3,0.9-6.4,2.9-8.6,5.5c-2.9-2.4-6.4-3.6-10.1-3.6H45c-5.8,0-11,3.2-13.8,8.2
                              C27.6,70.6,25.5,65.1,25.5,59.3z M60.7,81.2c-8.9,4.4-19.7,3-27.2-3.9c2.1-4.5,6.5-7.4,11.5-7.4h10c3.2,0,6.1,1.1,8.3,3
                              C61.8,75.3,60.9,78.2,60.7,81.2z M78.4,97c-8.2,0-14.7-6.7-14.7-14.7c0-8,6.7-14.7,14.7-14.7c8,0,14.7,6.7,14.7,14.7
                              C93.1,90.3,86.6,97,78.4,97z M86.9,75.6l-11,9.7l-4.2-5c-0.6-0.6-1.5-0.8-2.1-0.2c-0.6,0.6-0.8,1.5-0.2,2.1l5.1,6.2
                              c0.3,0.3,0.6,0.5,1.1,0.6c0.5,0,0.8-0.2,1.1-0.3L88.9,78c0.6-0.6,0.8-1.5,0.2-2.1C88.4,75.1,87.5,75.1,86.9,75.6z"></path>
                           </g>
                        </g>
                     </svg>
               `
            },
				{
               title:"Secure Development",
               text:"Every byte of data that you provide to us a customer would be stored and processed in a secure environment. Apart from being GDPR compliant, we also take stringent measures to keep cyber security threats at bay.",
               svg:`
                  <svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                        <g>
                           <g>
                              <path d="M91.7,12.5l-1.8,0.3c0,0-2.4,0.3-6,0.3c-7.9,0-22.7-1.5-32.7-11.9L50,0l-1.2,1.2C38.8,11.5,24,13.1,16.1,13.1
                              c-3.6,0-6-0.3-6-0.3l-1.8-0.3v43.8c0,10.6,7.1,21.5,20.7,31.5C39,95.2,48.9,99.5,49.4,99.7L50,100l0.6-0.3
                              c0.4-0.2,10.3-4.5,20.4-11.9c13.5-10,20.7-20.9,20.7-31.5L91.7,12.5z M88.5,56.3c0,21.8-33.4,38-38.5,40.3
                              c-5.1-2.3-38.5-18.5-38.5-40.3V16c1.1,0.1,2.7,0.2,4.6,0.2c8.1,0,23.1-1.6,33.9-11.7c10.8,10.1,25.7,11.7,33.9,11.7
                              c1.9,0,3.5-0.1,4.6-0.2V56.3z M22.2,50.7l19,8.6v-4.1l-14.4-6.2V49l14.4-6.2v-4.1l-19,8.6V50.7z M44.1,60.9h4l8.5-28.1h-4
                              L44.1,60.9z M58.8,42.8L73.5,49v0.1l-14.7,6.2v4.1l19-8.5v-3.6l-19-8.5V42.8z"></path>
                           </g>
                        </g>
                     </svg>
               `
            },
				{
               title:"100% Custom Application",
               text:"Each customer we work with, each requirement that deliver and each project that we initiate is treated as a separate unit. There is no cookie cutting or reusing of what has been done for someone else.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 56 56" style="enable-background:new 0 0 56 56;" xml:space="preserve">
                        <g>
                           <path class="st0" d="M3.7,46.7h1.9v-1.9H3.7c-1,0-1.9-0.8-1.9-1.9V5.6c0-1,0.8-1.9,1.9-1.9h41.1c1,0,1.9,0.8,1.9,1.9v1.9h1.9V5.6
                           c0-2.1-1.7-3.7-3.7-3.7H3.7C1.7,1.9,0,3.5,0,5.6v37.3C0,45,1.7,46.7,3.7,46.7z"></path>
                           <rect x="3.7" y="5.6" class="st0" width="1.9" height="1.9"></rect>
                           <rect x="7.5" y="5.6" class="st0" width="1.9" height="1.9"></rect>
                           <rect x="11.2" y="5.6" class="st0" width="1.9" height="1.9"></rect>
                           <path class="st0" d="M7.5,50.4c0,2.1,1.7,3.7,3.7,3.7h41.1c2.1,0,3.7-1.7,3.7-3.7V13.1c0-2.1-1.7-3.7-3.7-3.7H11.2
                           c-2.1,0-3.7,1.7-3.7,3.7V50.4z M9.3,13.1c0-1,0.8-1.9,1.9-1.9h41.1c1,0,1.9,0.8,1.9,1.9v37.3c0,1-0.8,1.9-1.9,1.9H11.2
                           c-1,0-1.9-0.8-1.9-1.9V13.1z"></path>
                           <rect x="11.2" y="13.1" class="st0" width="1.9" height="1.9"></rect>
                           <rect x="14.9" y="13.1" class="st0" width="1.9" height="1.9"></rect>
                           <rect x="18.7" y="13.1" class="st0" width="1.9" height="1.9"></rect>
                           <rect x="11.2" y="16.8" class="st0" width="41.1" height="1.9"></rect>
                           <rect x="11.2" y="21.5" class="st0" width="7.5" height="1.9"></rect>
                           <rect x="11.2" y="25.2" class="st0" width="5.6" height="1.9"></rect>
                           <path class="st0" d="M31.7,28.9c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5C38.3,31.9,35.3,28.9,31.7,28.9z
                           M31.7,40.1c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7s4.7,2.1,4.7,4.7C36.4,38,34.3,40.1,31.7,40.1z"></path>
                           <path class="st0" d="M46.7,32.7c0-0.5-0.4-0.9-0.9-0.9h-2.5c-0.2-0.6-0.4-1.2-0.7-1.8l1.7-1.7c0.4-0.4,0.4-1,0-1.3l-4-4
                           c-0.4-0.4-1-0.4-1.3,0l-1.7,1.7c-0.6-0.3-1.2-0.5-1.8-0.7v-2.5c0-0.5-0.4-0.9-0.9-0.9h-5.6c-0.5,0-0.9,0.4-0.9,0.9v2.5
                           c-0.6,0.2-1.2,0.4-1.8,0.7l-1.7-1.7c-0.4-0.4-1-0.4-1.3,0l-4,4c-0.4,0.4-0.4,1,0,1.3l1.7,1.7c-0.1,0.2-0.2,0.3-0.2,0.5
                           c-0.2,0.4-0.4,0.9-0.5,1.3h-2.5c-0.5,0-0.9,0.4-0.9,0.9v5.6c0,0.5,0.4,0.9,0.9,0.9h2.5c0.2,0.6,0.4,1.2,0.7,1.8l-1.7,1.7
                           c-0.4,0.4-0.4,1,0,1.3l4,4c0.4,0.4,1,0.4,1.3,0l1.7-1.7c0.6,0.3,1.2,0.5,1.8,0.7v2.5c0,0.5,0.4,0.9,0.9,0.9h5.6
                           c0.5,0,0.9-0.4,0.9-0.9V47c0.6-0.2,1.2-0.4,1.8-0.7L39,48c0.4,0.4,1,0.4,1.3,0l4-4c0.4-0.4,0.4-1,0-1.3L42.5,41
                           c0.3-0.6,0.5-1.2,0.7-1.8h2.5c0.5,0,0.9-0.4,0.9-0.9V32.7z M44.8,37.3h-2.2c-0.4,0-0.8,0.3-0.9,0.7c-0.2,0.9-0.6,1.8-1.1,2.6
                           c-0.2,0.4-0.2,0.8,0.1,1.1l1.6,1.6L39.7,46l-1.6-1.6c-0.3-0.3-0.8-0.4-1.1-0.1c-0.8,0.5-1.7,0.9-2.6,1.1c-0.4,0.1-0.7,0.5-0.7,0.9
                           v2.2h-3.7v-2.2c0-0.4-0.3-0.8-0.7-0.9c-0.9-0.2-1.8-0.6-2.6-1.1c-0.4-0.2-0.8-0.2-1.1,0.1L23.8,46l-2.6-2.6l1.6-1.6
                           c0.3-0.3,0.4-0.8,0.1-1.1C22.4,39.8,22,39,21.8,38c-0.1-0.4-0.5-0.7-0.9-0.7h-2.2v-3.7h2.2c0.4,0,0.8-0.3,0.9-0.7
                           c0.2-0.6,0.4-1.2,0.6-1.7c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.4,0.2-0.8-0.1-1.1l-1.6-1.6l2.6-2.6l1.6,1.6c0.3,0.3,0.8,0.4,1.1,0.1
                           c0.8-0.5,1.7-0.9,2.6-1.1c0.4-0.1,0.7-0.5,0.7-0.9v-2.2h3.7v2.2c0,0.4,0.3,0.8,0.7,0.9c0.9,0.2,1.8,0.6,2.6,1.1
                           c0.4,0.2,0.8,0.2,1.1-0.1l1.6-1.6l2.6,2.6l-1.6,1.6c-0.3,0.3-0.4,0.8-0.1,1.1c0.5,0.8,0.9,1.7,1.1,2.6c0.1,0.4,0.5,0.7,0.9,0.7h2.2
                           V37.3z"></path>
                           <rect x="46.7" y="48.5" class="st0" width="5.6" height="1.9"></rect>
                        </g>
                     </svg>
               `
            },
				{
               title:"Interactive Design",
               text:"Design is at the heart of everything we do at GenixBit Labs. From figuring out the right shade of color for CTAs to wireframing a product interface, we live by the tenets of interactive design.",
               svg:`
               <svg x="0px" y="0px" viewBox="0 0 56 56" style="enable-background:new 0 0 56 56;" xml:space="preserve">
                        <g>
                           <path class="st0" d="M29.6,24.1c-0.2-0.3-0.4-0.4-0.8-0.4h-1.7c-0.3,0-0.6,0.2-0.8,0.4L22,32c-0.1,0.2-0.1,0.5-0.1,0.7l2.2,6.7
                           h-0.5c-0.5,0-0.9,0.4-0.9,0.9v3.5c0,0.5,0.4,0.9,0.9,0.9v10.5c0,0.5,0.4,0.9,0.9,0.9h7c0.5,0,0.9-0.4,0.9-0.9V44.6
                           c0.5,0,0.9-0.4,0.9-0.9v-3.5c0-0.5-0.4-0.9-0.9-0.9h-0.5l2.2-6.7c0.1-0.2,0.1-0.5-0.1-0.7L29.6,24.1z M30.6,41.1h0.9v1.7h-7v-1.7
                           h0.9H30.6z M30.6,54.3h-5.2v-9.6h5.2V54.3z M30,39.4h-1.1v-6.1h0.9v-1.7h-3.5v1.7h0.9v6.1H26l-2.3-6.9l3.9-7.1h0.7l3.9,7.1L30,39.4
                           z"></path>
                           <path class="st0" d="M25.6,16.8c0.4,0.9,1.3,1.6,2.4,1.6c1.1,0,2-0.6,2.4-1.6c7,1.1,12.6,6.9,13.3,14c-1,0.4-1.7,1.3-1.7,2.4
                           c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6c0-1.2-0.8-2.2-1.8-2.5c-0.6-6.2-4.4-11.5-9.8-14.1h4.7v0.9c0,0.5,0.4,0.9,0.9,0.9h3.5
                           c0.5,0,0.9-0.4,0.9-0.9V14c0-0.5-0.4-0.9-0.9-0.9h-3.5c-0.5,0-0.9,0.4-0.9,0.9v0.9h-9.8c-0.4-1-1.3-1.7-2.5-1.7
                           c-1.1,0-2.1,0.7-2.5,1.7h-9.8V14c0-0.5-0.4-0.9-0.9-0.9h-3.5c-0.5,0-0.9,0.4-0.9,0.9v3.5c0,0.5,0.4,0.9,0.9,0.9h3.5
                           c0.5,0,0.9-0.4,0.9-0.9v-0.9h4.6c-5.4,2.6-9.2,7.9-9.8,14.1c-1.1,0.3-1.8,1.3-1.8,2.5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6
                           c0-1.1-0.7-2-1.7-2.4C13,23.7,18.6,17.9,25.6,16.8z M44.6,34.1c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                           C45.5,33.7,45.1,34.1,44.6,34.1z M42,14.9h1.7v1.7H42V14.9z M14,16.6h-1.7v-1.7H14V16.6z M28,14.9c0.5,0,0.9,0.4,0.9,0.9
                           s-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9S27.5,14.9,28,14.9z M11.4,34.1c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
                           c0.5,0,0.9,0.4,0.9,0.9C12.2,33.7,11.9,34.1,11.4,34.1z"></path>
                           <path class="st0" d="M55.1,0H0.9C0.4,0,0,0.4,0,0.9v7v40.2C0,48.6,0.4,49,0.9,49h21v-1.7H1.7V8.8h52.5v38.5H34.1V49h21
                           c0.5,0,0.9-0.4,0.9-0.9V7.9v-7C56,0.4,55.6,0,55.1,0z M1.7,7V1.8h52.5V7H1.7z"></path>
                           <rect x="5.2" y="3.5" class="st0" width="1.7" height="1.7"></rect>
                           <rect x="8.7" y="3.5" class="st0" width="1.7" height="1.7"></rect>
                           <rect x="12.2" y="3.5" class="st0" width="1.7" height="1.7"></rect>
                           <rect x="45.5" y="3.5" class="st0" width="5.2" height="1.7"></rect>
                           <rect x="27.1" y="47.3" class="st0" width="1.7" height="3.5"></rect>
                        </g>
                     </svg>
               `
            },
			]
		};
	}
};
</script>

<style>
.web-why-choose-inner-icon svg {
	width: 44px;
	height: 44px;
}
.web-why-choose-inner-icon svg circle,
.web-why-choose-inner-icon svg ellipse,
.web-why-choose-inner-icon svg line,
.web-why-choose-inner-icon svg path,
.web-why-choose-inner-icon svg polygon,
.web-why-choose-inner-icon svg rect {
	fill: #6a6d6f;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.web-why-choose-us-inner-box:hover svg circle,
.web-why-choose-us-inner-box:hover svg ellipse,
.web-why-choose-us-inner-box:hover svg line,
.web-why-choose-us-inner-box:hover svg path,
.web-why-choose-us-inner-box:hover svg polygon,
.web-why-choose-us-inner-box:hover svg rect {
	fill: #fff;
}

.web-why-choose-us-inner-box:hover .web-why-choose-inner-icon {
	background-color: #1977cc;
}
</style>
