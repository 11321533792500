<template>
	<div>
		<ContactBanner></ContactBanner>
		<ContactForm></ContactForm>
		<SocialContact></SocialContact>

		<div class="mb-600 fxt-map-container">
			<Map></Map>
		</div>
	</div>
</template>

<script>
import ContactBanner from '../components/Contact/ContactBanner.vue';
import ContactForm from '../components/Contact/ContactForm.vue';
import SocialContact from '../components/Contact/SocialContact.vue';
import Map from '../components/AssetComponents/Map.vue';

export default {
	components: {
		ContactBanner,
		ContactForm,
		SocialContact,
		Map
	},
	mounted() {
		var input = document.querySelector("#phone");
		window.intlTelInput(input, {
				initialCountry:"in",
		});
		console.log("done",input)
	}
};
</script>

<style scoped>
@import url('../assets/css/contact.css');
@media (max-width: 1200px) {
	.fxt-map-container {
		margin-bottom: 0 !important;
	}
}
</style>
