<template>
  <div>

    <Banner></Banner>

    <BrandsLogos></BrandsLogos>

    <About></About>

    <Visions></Visions>

    <ServicesType></ServicesType>

    <TypeDiv></TypeDiv>

    <WhyChooseUs></WhyChooseUs>

    <Portfolio></Portfolio>

    <Const></Const>
  </div>
</template>

<script>
import Banner from '../../components/MobileDevelopment/VRDevelopment/Banner.vue'
import ServicesType from '../../components/MobileDevelopment/IosDevelopment/ServicesType.vue'
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue'
import Portfolio from '../../components/Home/Portfolio.vue'
import About from '../../components/MobileDevelopment/VRDevelopment/About.vue'
import WhyChooseUs from '../../components/MobileDevelopment/VRDevelopment/WhyChooseUs.vue'
import Visions from '../../components/MobileDevelopment/VRDevelopment/Visions.vue'
import TypeDiv from '../../components/Home/TypeDiv.vue'
import Const from '../../components/AssetComponents/Const.vue'

export default {
    components:{
        Banner,
        BrandsLogos,
        About,
        Visions,
        TypeDiv,
        Portfolio,
        Const,
        WhyChooseUs,
        ServicesType
    }
}
</script>


<style>

</style>

