<template>
	<section class="padding-60 bg-white o-hidden">
		<div class="container">
			<div class="title">
				<span>What We Do</span>
				<h2 class="fxt-process-dev">A world of App Options, Made for The iPhone</h2>
			</div>
			<div class="icns-containter-fxt">
				<div  class="icn-containter-fxt">
					<svg viewBox="0 0 120 120" xml:space="preserve">
										<path fill="#6A6D6F" d="M42,44h4v-4h-4V44z M50,44h4v-4h-4V44z M58,44h4v-4h-4V44z M66,44h4v-4h-4V44z M50,36h4v-4h-4V36z M58,36h4
											v-4h-4V36z M66,36h4v-4h-4V36z M74,44h4v-4h-4V44z M118,30H88V2c0-1.1-0.9-2-2-2H34c-1.1,0-2,0.9-2,2v28H2c-1.1,0-2,0.9-2,2v72
											c0,1.1,0.9,2,2,2h4.2c-0.1,0.7-0.2,1.3-0.2,2v10c0,1.1,0.9,2,2,2h104c1.1,0,2-0.9,2-2v-10c0-0.7-0.1-1.3-0.2-2h4.2
											c1.1,0,2-0.9,2-2V32C120,30.9,119.1,30,118,30z M85.9,85.1C85.9,85.1,85.9,85.2,85.9,85.1c0.1,0.6,0.1,1.2,0,1.7c0,0,0,0,0,0.1
											c-0.2,0.8-0.6,1.5-1.2,2.1v0c-0.7,0.7-1.7,1-2.7,1c-0.3,0-0.6,0-0.8-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.5-0.2
											c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.2-0.3
											c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4
											c0-0.1,0-0.3,0-0.4c0-0.2,0-0.3,0-0.5c0-0.1,0-0.3,0-0.4c0.1-0.3,0.2-0.6,0.3-0.9c0-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.3-0.5,0.5-0.7
											c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0,0,0.1,0,0.1,0
											c0.3-0.1,0.7-0.1,1-0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.2,0,0.2,0.1c0.5,0.2,0.9,0.4,1.3,0.8
											C85.3,83.6,85.7,84.3,85.9,85.1z M34.1,85.2C34.1,85.2,34.1,85.1,34.1,85.2c0.2-0.9,0.6-1.6,1.3-2.1c0.4-0.3,0.8-0.6,1.3-0.8
											c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.4-0.1,0.7-0.2c0.1,0,0.2,0,0.3,0c0.3,0,0.7,0,1,0.1c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.2,0.8,0.3
											c0.1,0,0.2,0.1,0.3,0.1c0.3,0.2,0.5,0.4,0.8,0.6c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.1,0.1,0.2,0.2,0.3
											c0.1,0.3,0.2,0.6,0.3,0.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4
											c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
											c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0-0.2,0.1-0.3,0.1
											C38.6,90,38.3,90,38,90c-1,0-1.9-0.4-2.6-1v0c-0.6-0.5-1-1.3-1.2-2.1c0,0,0,0,0-0.1C34,86.3,34,85.7,34.1,85.2L34.1,85.2z M26,116
											H10v-8c0-1.5,0.5-3,1.3-4.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.2,0.5-0.4,0.8-0.6
											c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.6-0.2,1-0.3c0.1,0,0.3-0.1,0.4-0.1
											c0.9-0.2,1.9-0.2,2.9,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.2,1,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.2,0.8,0.4,1.2,0.6
											c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.1,0.2,0.3,0.3,0.4
											c0.8,1.3,1.3,2.8,1.3,4.3V116z M14,92c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C15.8,96,14,94.2,14,92z M29.3,93.8
											c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.1-0.2,0.3-0.2,0.4
											c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.5-0.3,0.7c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.2,0.5-0.2,0.8
											c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.5-0.4
											c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c3.1-3.2,2.9-8.3-0.3-11.3c-3.2-3.1-8.3-2.9-11.3,0.3c-3,3.1-3,8,0,11
											c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.8,0.6-1.4,1.3-2,2l-0.2,0.2
											c-0.3,0.3-0.5,0.7-0.7,1.1c0,0,0,0.1-0.1,0.1H4V34h28v46.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.3-0.4,0.6-0.6,0.9
											c-0.1,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.2,0.8c-0.2,1-0.2,2,0,3
											c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.3,0.6,0.4,0.9c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.3,0.4,0.6,0.6,0.9
											c0.1,0.1,0.1,0.1,0.2,0.2v0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.5,0.3-0.8,0.5c-0.1,0.1-0.2,0.1-0.3,0.2
											c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.2,0.2-0.3,0.3C29.6,93.4,29.5,93.6,29.3,93.8z M46,116H30v-14c0-0.5,0.1-1,0.1-1.4
											c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3,0.2-0.7,0.3-1c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.4-0.8,0.6-1.2c0.1-0.1,0.1-0.2,0.2-0.3
											c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.2,0.4-0.4,0.7-0.6c0.1-0.1,0.3-0.2,0.4-0.3C35,94.5,36.5,94,38,94
											c4.4,0,8,3.6,8,8V116z M70,116H50v-14c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10V116z M54,82c0-3.3,2.7-6,6-6s6,2.7,6,6
											c0,3.3-2.7,6-6,6S54,85.3,54,82z M73.2,93.8c-0.1,0.1-0.2,0.2-0.3,0.4c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.1-0.1,0.2-0.2,0.3
											c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.4-0.5-0.7-0.7-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.4-0.6-0.7-0.9-1c-0.1-0.1-0.1-0.1-0.2-0.2
											c-0.4-0.4-0.8-0.7-1.2-1.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.3-0.7-0.5-1-0.7C67.2,90,67.1,90,67,89.9c-0.2-0.1-0.3-0.2-0.5-0.3
											c4.2-3.6,4.8-9.9,1.2-14.1c-3.6-4.2-9.9-4.8-14.1-1.2c-4.2,3.6-4.8,9.9-1.2,14.1c0.4,0.4,0.8,0.8,1.2,1.2
											c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.2-0.7,0.5-1,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.3-0.8,0.7-1.2,1.1
											c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.7-0.9,1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.5,0.7-0.7,1.1c0,0.1-0.1,0.1-0.1,0.2
											c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.5-0.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.2-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.2-0.4-0.3
											c-0.2-0.2-0.4-0.4-0.7-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.5-0.4-0.8-0.5c-0.1,0-0.1-0.1-0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
											c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.4,0.2-0.6
											c0.1-0.3,0.1-0.7,0.2-1c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.1,0-0.2c0-0.3,0-0.6-0.1-0.8c0-0.2,0-0.4,0-0.5c0,0,0-0.1,0-0.1
											c0-0.1,0-0.2-0.1-0.3c-0.1-0.4-0.2-0.8-0.4-1.2c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.4-0.8-0.6-1.1c0,0,0-0.1-0.1-0.1
											c-0.3-0.4-0.6-0.8-0.9-1.1c0,0,0,0-0.1-0.1c-0.3-0.3-0.7-0.6-1-0.8l-0.2-0.2c-0.3-0.2-0.7-0.4-1.1-0.6c-0.1,0-0.1-0.1-0.2-0.1
											c-0.4-0.2-0.9-0.3-1.3-0.4c0,0,0,0,0,0C39.1,78.1,38.6,78,38,78c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0c-0.4,0-0.9,0.1-1.3,0.2
											c-0.1,0-0.1,0-0.2,0V4h48v74.3c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.9-0.2-1.3-0.2c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0
											c-0.6,0-1.1,0.1-1.6,0.2c0,0,0,0,0,0c-0.5,0.1-0.9,0.2-1.3,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.7,0.4-1.1,0.6l-0.3,0.2
											c-0.4,0.3-0.7,0.5-1,0.8c0,0,0,0-0.1,0.1c-0.3,0.4-0.7,0.7-0.9,1.1c0,0,0,0.1-0.1,0.1c-0.2,0.4-0.4,0.7-0.6,1.1
											c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.1,0,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.2,0,0.4,0,0.5c0,0.3-0.1,0.6-0.1,0.9
											c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0.1,0.7c0,0.3,0.1,0.7,0.2,1c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.2,0.6,0.4,0.9
											c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.3,0.5,0.5,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0-0.1,0.1-0.2,0.1
											c-0.3,0.2-0.6,0.3-0.8,0.5c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.4,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.4,0.3
											C73.6,93.4,73.4,93.6,73.2,93.8z M90,116H74v-14c0-4.4,3.6-8,8-8c1.5,0,3,0.5,4.3,1.3c0.1,0.1,0.3,0.2,0.4,0.3
											c0.2,0.2,0.5,0.4,0.7,0.6c0.1,0.1,0.3,0.2,0.4,0.4c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.8,0.6,1.2
											c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.6,0.3,1c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.1,1,0.1,1.4V116z M110,116H94v-8
											c0-1.5,0.5-3,1.3-4.3c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.2,0.5-0.4,0.8-0.6
											c0.1-0.1,0.2-0.1,0.3-0.2c0.4-0.2,0.8-0.5,1.2-0.6c0.1-0.1,0.3-0.1,0.4-0.2c0.3-0.1,0.6-0.2,1-0.3c0.1,0,0.3-0.1,0.4-0.1
											c0.9-0.2,1.9-0.2,2.9,0c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.2,1,0.3c0.1,0.1,0.3,0.1,0.4,0.2c0.4,0.2,0.8,0.4,1.2,0.6
											c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.2,0.2,0.4,0.4c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.1,0.2,0.3,0.3,0.4
											c0.8,1.3,1.3,2.8,1.3,4.3V116z M98,92c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2.2-1.8,4-4,4C99.8,96,98,94.2,98,92z M116,102h-3.6
											c0,0,0-0.1-0.1-0.1c-0.2-0.4-0.5-0.7-0.7-1.1l-0.2-0.2c-0.6-0.8-1.3-1.4-2-2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.2-0.6-0.4-0.9-0.6
											c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1-0.1-0.1-0.1c3.1-3.2,2.9-8.3-0.3-11.3c-3.2-3.1-8.3-2.9-11.3,0.3c-3,3.1-3,8,0,11
											c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.3-0.5,0.4
											c-0.1,0.1-0.2,0.2-0.3,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.2-0.5-0.3-0.7
											c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.3-0.4
											c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5
											c-0.1-0.1-0.2-0.2-0.3-0.2c-0.3-0.2-0.5-0.3-0.8-0.5c-0.1,0-0.1-0.1-0.2-0.1v-0.4c0.1-0.1,0.1-0.1,0.2-0.2
											c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.5,0.2-0.8
											c0.2-1,0.2-2,0-3c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.1-0.1-0.3-0.2-0.4
											c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.1-0.1-0.1-0.2-0.2V34h28V102z M74,10c0-1.1-0.9-2-2-2H48c-1.1,0-2,0.9-2,2v16c0,1.1,0.9,2,2,2
											h24c1.1,0,2-0.9,2-2V10z M70,24H50V12h20V24z"></path>
								</svg>
					<p>Enterprise</p>
				</div>
				<div class="icn-containter-fxt">
					<svg viewBox="0 0 120 120" xml:space="preserve">
                  <path fill="#6A6D6F" d="M102.2,57.8L89.9,46.5l-2.7,2.9l12.3,11.2c2.9,2.7,4.5,6.4,4.5,10.3v49h4V71C108,66,105.9,61.2,102.2,57.8z
                     M84,10c0-5.5-4.5-10-10-10H22c-5.5,0-10,4.5-10,10v100c0,5.5,4.5,10,10,10h50v-3.6c0-9,2.7-17.8,7.7-25.3
                     c0.5-0.8,0.4-1.8-0.2-2.5l-14-14c-2.5-2.5-2.5-6.6,0-9.2c0.2-0.1,0.3-0.3,0.5-0.4c2.7-2.1,6.6-1.8,9,0.7l19.7,19.7l2.8-2.8
                     L84,69.2V10z M80,65.2l-2.3-2.3c-3.8-3.9-10-4.3-14.3-0.9c-4.5,3.7-5.1,10.3-1.4,14.8c0.2,0.3,0.4,0.5,0.7,0.8l12.9,12.9
                     C70.7,98,68.1,106.9,68,116H22c-3.3,0-6-2.7-6-6v-6h50v-4H16V16h64V65.2z M80,12H16v-2c0-3.3,2.7-6,6-6h52c3.3,0,6,2.7,6,6V12z
                     M24,36h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8C20,34.2,21.8,36,24,36z M24,24h8v8h-8V24z M44,36h8
                     c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8C40,34.2,41.8,36,44,36z M44,24h8v8h-8V24z M64,36h8c2.2,0,4-1.8,4-4
                     v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8C60,34.2,61.8,36,64,36z M64,24h8v8h-8V24z M24,56h8c2.2,0,4-1.8,4-4v-8
                     c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8C20,54.2,21.8,56,24,56z M24,44h8v8h-8V44z M24,76h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4
                     h-8c-2.2,0-4,1.8-4,4v8C20,74.2,21.8,76,24,76z M24,64h8v8h-8V64z M44,56h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8
                     c-2.2,0-4,1.8-4,4v8C40,54.2,41.8,56,44,56z M44,44h8v8h-8V44z M64,56h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4
                     v8C60,54.2,61.8,56,64,56z M64,44h8v8h-8V44z M44,76h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8
                     C40,74.2,41.8,76,44,76z M44,64h8v8h-8V64z M24,96h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8
                     C20,94.2,21.8,96,24,96z M24,84h8v8h-8V84z M44,96h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8
                     C40,94.2,41.8,96,44,96z M44,84h8v8h-8V84z M46,112h4v-4h-4V112z"></path>
               </svg>
					<p>On-demand</p>
				</div>
				<div class="icn-containter-fxt">
					<svg viewBox="0 0 120 120" xml:space="preserve">
                  <path fill="#6A6D6F" d="M60,44c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C58,43.1,58.9,44,60,44z M60,36c1.1,0,2-0.9,2-2
                     c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2C58,35.1,58.9,36,60,36z M44,34h-8c-2.2,0-4,1.8-4,4v8c0,2.2,1.8,4,4,4h8c2.2,0,4-1.8,4-4v-8
                     C48,35.8,46.2,34,44,34z M36,46v-8h8l0,8H36z M76,50h8c2.2,0,4-1.8,4-4v-8c0-2.2-1.8-4-4-4h-8c-2.2,0-4,1.8-4,4v8
                     C72,48.2,73.8,50,76,50z M76,38h8l0,8h-8V38z M114,97.6c-9.4-4.9-25.4-10.5-36.1-12.7C85.8,78,91,67.3,91.8,57
                     c2.5-1.4,4.2-4,4.2-7V34c0-3.6-2.4-6.7-5.8-7.7C86.1,13,74.7,0,60,0C45.3,0,33.9,13,29.8,26.3c-3.3,1-5.8,4-5.8,7.7v16
                     c0,3,1.7,5.7,4.2,7c0.8,10.2,6,21,13.9,27.8c-10.6,2.2-26.6,7.8-36,12.7c-3.7,1.9-6,5.7-6,9.9V118c0,1.1,0.9,2,2,2h76h16h24
                     c1.1,0,2-0.9,2-2v-10.6C120,103.3,117.7,99.5,114,97.6z M60,4c12.2,0,21.9,10.7,25.9,22H34.1C38.1,14.7,47.8,4,60,4z M30.8,53.8
                     C30.8,53.8,30.8,53.8,30.8,53.8c-1.6-0.5-2.8-2-2.8-3.8V34c0-2,1.5-3.7,3.5-3.9c0,0,0.1,0,0.1,0c0,0,0.4,0,0.4-0.1h56
                     c0.1,0,0.1,0,0.2,0c0,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0C90.5,30.4,92,32,92,34v16c0,1.7-1.1,3.1-2.6,3.7c-0.1,0-0.2,0-0.3,0.1
                     C88.8,53.9,88.4,54,88,54H71.1c-0.9,0-1.7-0.6-1.9-1.5l-0.5-1.9C68,47.9,65.6,46,62.9,46h-5.8c-2.8,0-5.2,1.9-5.8,4.5l-0.5,1.9
                     c-0.2,0.9-1,1.5-1.9,1.5H32C31.6,54,31.2,53.9,30.8,53.8z M32.3,58h16.6c2.8,0,5.2-1.9,5.8-4.5l0.5-1.9c0.2-0.9,1-1.5,1.9-1.5h5.8
                     c0.9,0,1.7,0.6,1.9,1.5l0.5,1.9c0.7,2.7,3.1,4.5,5.8,4.5h16.6c-1.2,10.2-7.1,20.8-15.5,26.3c-0.1,0-0.1,0-0.2,0.1l-1.3,0.8
                     l-0.2,0.1l-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1C67,87.1,63.6,88,60,88c-3.6,0-7-0.9-10-2.5c-0.1,0-0.1-0.1-0.2-0.1L48,84.3
                     c-0.1,0-0.1,0-0.2-0.1C39.4,78.8,33.5,68.2,32.3,58z M65.5,91.4l-4.8,6.3c-0.2,0.3-0.6,0.4-0.7,0.4s-0.5,0-0.7-0.4l-4.8-6.3
                     c1.8,0.4,3.6,0.6,5.5,0.6C61.9,92,63.7,91.8,65.5,91.4z M92,116H80v-4h12V116z M116,116H96v-6c0-1.1-0.9-2-2-2H78
                     c-1.1,0-2,0.9-2,2v6H4v-8.6c0-2.7,1.5-5.1,3.9-6.3c10.3-5.4,28.8-11.6,38.6-13l1,0.6l8.6,11.4c0.9,1.2,2.4,2,3.9,2s3-0.7,3.9-2
                     l8.6-11.4l0.1-0.1c0,0,0.2-0.1,0.2-0.1l0.7-0.4c9.8,1.4,28.3,7.6,38.6,13c2.4,1.3,3.9,3.7,3.9,6.3V116z"></path>
               </svg>
					<p>Virtual Reality</p>
				</div>
				<div class="icn-containter-fxt">
					<svg viewBox="0 0 120 120" xml:space="preserve">
                  <path fill="#6A6D6F" d="M114.4,46.5h-11.7H87.3L74.4,31.7l-8.1,8.1L55.1,26.9L35.4,46.5H19.7h-14c-3.1,0-5.6,2.5-5.6,5.6v50.2
                     c0,3.1,2.5,5.6,5.6,5.6h14h83h11.7c3.1,0,5.6-2.5,5.6-5.6V52.2C120,49.1,117.5,46.5,114.4,46.5z M74.2,37.2l8.1,9.4H72.2l-3.4-4
                     L74.2,37.2z M54.9,32.4l12.3,14.2H40.7L54.9,32.4z M17.8,52.2c0-1,0.8-1.9,1.9-1.9h4.8L17.8,57V52.2z M14.4,104.3H5.6
                     c-1,0-1.9-0.8-1.9-1.9V52.2c0-1,0.8-1.9,1.9-1.9h8.7c-0.2,0.6-0.3,1.2-0.3,1.9v50.2C14,103.1,14.2,103.7,14.4,104.3z M104.6,102.4
                     c0,1-0.8,1.9-1.9,1.9h-83c-1,0-1.9-0.8-1.9-1.9V62.3l12-12h62.7l12,11.9V102.4z M104.6,57l-6.7-6.7h4.8c1,0,1.9,0.8,1.9,1.9V57z
                     M116.2,102.4c0,1-0.8,1.9-1.9,1.9H108c0.2-0.6,0.3-1.2,0.3-1.9V61.8c0-0.2,0-0.4,0-0.6v-9.1c0-0.7-0.1-1.3-0.3-1.9h6.4
                     c1,0,1.9,0.8,1.9,1.9V102.4z M8.9,67.8c-1,0-1.9,0.8-1.9,1.9V86c0,1,0.8,1.9,1.9,1.9s1.9-0.8,1.9-1.9V69.7
                     C10.8,68.6,9.9,67.8,8.9,67.8z M95.6,27.6c4.3,0,7.8-3.5,7.8-7.8c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8
                     C87.7,24.1,91.2,27.6,95.6,27.6z M95.6,15.7c2.3,0,4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1c-2.3,0-4.1-1.8-4.1-4.1
                     C91.5,17.6,93.3,15.7,95.6,15.7z M91.3,57.8H28.9c-1,0-1.9,0.8-1.9,1.9v35.2c0,1,0.8,1.9,1.9,1.9h48.7c1,0,1.9-0.8,1.9-1.9v-26
                     l12.9-7.7c0.7-0.4,1.1-1.3,0.8-2.1C92.9,58.4,92.2,57.8,91.3,57.8z M76.6,66.3c-0.6,0.3-0.9,0.9-0.9,1.6V93H30.7V61.5h53.8
                     L76.6,66.3z M38.2,84.6h18.6c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H38.2c-1,0-1.9,0.8-1.9,1.9C36.4,83.7,37.2,84.6,38.2,84.6z
                     M38.2,73.7H54c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H38.2c-1,0-1.9,0.8-1.9,1.9C36.4,72.9,37.2,73.7,38.2,73.7z M62.7,84.6
                     c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9C60.8,83.7,61.7,84.6,62.7,84.6z"></path>
               </svg>
					<p style="padding:0 !important">Augmented Reality</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.icns-containter-fxt {
		display:flex;
		justify-content:space-around;
		align-items:center;
		cursor:pointer;
}

.icn-containter-fxt {
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	align-items:center;
	height:160px;
	width:fit-content
}
.icn-containter-fxt svg {
	display:block;
	width:100px;
	height:100px;
	transition:0.2s;
}
.icn-containter-fxt p {
	margin:0;
	padding: 10px 0 0 0;
	text-align:center;
	transition:0.2s;
	color:black;
	font-size:18px;
	font-weight:600;
}
.icn-containter-fxt:hover svg path{
	fill:#008dd2;
}
.icn-containter-fxt:hover p {
	color:#008dd2;
}
@media (max-width: 991px) {
	.process-items::before {
		display: none;
	}
	.process-items i {
		top: unset !important;
		bottom: -30px !important;
		left: 50% !important;
	}
	.process-items i.goDown {
		transform: translateX(-50%) rotate(90deg);
	}
	.process-items i.goUp {
		top: -30px !important;
		bottom: unset !important;
		transform: translateX(-50%) rotate(-90deg);
	}
}
@media (max-width: 576px) {
	.fxt-process-dev {
		font-size:20px !important;
		font-weight:bold !important;
	}
	.icns-containter-fxt {
		flex-wrap:wrap;
	}
	.icn-containter-fxt {
		margin:0;
		padding:10px 0 !important;
		box-shadow:none !important;
		height: 150px;
    width: 150px;
	}
}
</style>
