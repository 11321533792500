// export const addPosToDivs = () => {
// 	document.querySelector('.our-services-div').style.top = `${
// 		parseInt(document.querySelector('#header').offsetHeight) +
// 		document.querySelector('#header').offsetTop
// 	}px`;
// 	document.querySelector('.solution-div').style.top = `${
// 		parseInt(document.querySelector('#header').offsetHeight) +
// 		document.querySelector('#header').offsetTop
// 	}px`;
// };
// eslint-disable-next-line no-unused-vars
export const Main = () => {
	/**
	 * Easy selector helper function
	 */
	const select = (el, all = false) => {
		//  if(!el){
		//   return
		//  }
		el = el ? el.trim() : '';
		if (all) {
			return [...document.querySelectorAll(el)];
		} else {
			if (el != '') return document.querySelector(el);
		}
	};

	/**
	 * Easy event listener function
	 */
	const on = (type, el, listener, all = false) => {
		let selectEl = select(el, all);
		if (selectEl) {
			if (all) {
				selectEl.forEach((e) => e.addEventListener(type, listener));
			} else {
				selectEl.addEventListener(type, listener);
			}
		}
	};

	/**
	 * Easy on scroll event listener
	 */
	const onScroll = (el, listener) => {
		el.addEventListener('scroll', listener);
	};

	/**
	 * Navbar links active state on scroll
	 */
	let navbarlinks = select('#navbar .scrollto', true);
	const navbarlinksActive = () => {
		let position = window.scrollY + 200;
		navbarlinks.forEach((navbarlink) => {
			if (!navbarlink.hash) return;
			let section = select(navbarlink.hash);
			if (!section) return;
			if (
				position >= section.offsetTop &&
				position <= section.offsetTop + section.offsetHeight
			) {
				navbarlink.classList.add('active');
			} else {
				navbarlink.classList.remove('active');
			}
		});
	};
	window.addEventListener('load', navbarlinksActive);
	onScroll(document, navbarlinksActive);

	/**
	 * Scrolls to an element with header offset
	 */
	const scrollto = (el) => {
		let header = select('#header');
		let offset = header.offsetHeight;

		let elementPos = select(el).offsetTop;
		window.scrollTo({
			top: elementPos - offset,
			behavior: 'smooth'
		});
	};

	/**
	 * Toggle .header-scrolled class to #header when page is scrolled
	 */

	let selectHeader = document.querySelector('#header');
	// let selectTopbar = document.querySelector('#topbar');

	if (selectHeader) {
		const headerScrolled = () => {
			if (window.scrollY > 100) {
				document.querySelector('#topbar').style.transform = `translateY(-${
					document.querySelector('#header').offsetTop
				}px)`;
				document.querySelector('#header').style.transform = `translateY(-${
					document.querySelector('#header').offsetTop + 4
				}px)`;
				document.querySelector('#our-services').style.transform = `translateY(-${
					document.querySelector('#header').offsetTop + 4
				}px)`;
				document.querySelector('#solution-div').style.transform = `translateY(-${
					document.querySelector('#header').offsetTop + 4
				}px)`;
			} else {
				document.querySelector('#topbar').style.transform = '';
				document.querySelector('#header').style.transform = '';
				document.querySelector('#our-services').style.transform = '';
				document.querySelector('#solution-div').style.transform = '';
			}
		};
		window.addEventListener('load', headerScrolled);
		onScroll(document, headerScrolled);
	}

	/**
	 * Back to top button
	 */
	let backtotop = select('.back-to-top');
	if (backtotop) {
		const toggleBacktotop = () => {
			if (window.scrollY > 100) {
				backtotop.classList.add('active');
			} else {
				backtotop.classList.remove('active');
			}
		};
		window.addEventListener('load', toggleBacktotop);
		onScroll(document, toggleBacktotop);
	}

	/**
	 * Mobile nav toggle
	 */
	const showListTags = document.querySelectorAll('.show-list-tag');
	const goBackListTags = document.querySelectorAll('.go-back-list-tag');

	showListTags.forEach((tag) =>
		tag.addEventListener('click', () => {
			document.querySelector('.mobile-list-main').classList.add('dontShow');
			document.querySelector(`.${tag.id}`).classList.add('show');
		})
	);
	goBackListTags.forEach((tag) =>
		tag.addEventListener('click', () => {
			document.querySelector('.mobile-list-main').classList.remove('dontShow');
			document.querySelector(`.${tag.id}`).classList.remove('show');
		})
	);

	on('click', '.mobile-nav-toggle', function () {
		// select('#navbar').classList.toggle('navbar-mobile');
		if (document.querySelector('.toggle-bar-icon').classList.contains('toggled')) {
			document.querySelector('.toggle-bar-icon').classList.remove('toggled');
			document.querySelector('.toggle-bar-icon').classList.add('untoggled');
			document.querySelector('#navbar').classList.remove('hidd');
			document.querySelector('#navbar').classList.add('navbar-mobile');
			document.querySelector('#navbar').classList.add('show');
		} else {
			document.querySelector('.toggle-bar-icon').classList.remove('untoggled');
			document.querySelector('.toggle-bar-icon').classList.add('toggled');
			document.querySelector('#navbar').classList.remove('show');
			document.querySelector('#navbar').classList.add('hidd');
			document.querySelectorAll('.mobile-list').forEach((li) => {
				if (li.classList.contains('show')) {
					li.classList.remove('show');
				}
			});
		}
		// this.classList.toggle('bi-list');
		// this.classList.toggle('bi-x');
	});

	/**
	 * Mobile nav dropdowns activate
	 */

	on(
		'click',
		'.navbar .dropdown > a',
		function (e) {
			if (select('#navbar').classList.contains('navbar-mobile')) {
				e.preventDefault();
				this.nextElementSibling.classList.toggle('dropdown-active');
			}
		},
		true
	);

	/**
	 * Scrool with ofset on links with a class name .scrollto
	 */
	on(
		'click',
		'.scrollto',
		function (e) {
			if (select(this.hash)) {
				e.preventDefault();

				let navbar = select('#navbar');
				if (navbar.classList.contains('navbar-mobile')) {
					navbar.classList.remove('navbar-mobile');
					let navbarToggle = select('.mobile-nav-toggle');
					navbarToggle.classList.toggle('bi-list');
					navbarToggle.classList.toggle('bi-x');
				}
				scrollto(this.hash);
			}
		},
		true
	);

	/**
	 * Scroll with ofset on page load with hash links in the url
	 */
	window.addEventListener('load', () => {
		if (window.location.hash) {
			if (select(window.location.hash)) {
				scrollto(window.location.hash);
			}
		}
	});

	/**
	 * Preloader
	 */
	let preloader = select('#preloader');
	if (preloader) {
		window.addEventListener('load', () => {
			preloader.remove();
		});
	}

	// document.querySelectorAll('.gototop').forEach((ell) => {
	// 	ell.addEventListener('click', () => {
	// 		window.scrollTo({top: 0, behavior: 'smooth'});
	// 	});
	// });
};
