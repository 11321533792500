<template>
	<section class="padding-60 bg-light web-why-choose-us-main">
		<div class="container">
			<div class="title">
				<span>The Ideal Businesses</span>
				<h2>Use Cases of on-demand Solutions</h2>
			</div>
			<div class="row" style="justify-content: center">
				<RoundIconSqurCard
					v-bind:obj="item"
					v-for="item in items"
					v-bind:key="item.title"
					class="col-md-4 col-sm-6 col-12 ideal-business-box text-center"
				></RoundIconSqurCard>
			</div>
		</div>
	</section>
</template>

<script>
import RoundIconSqurCard from '../AssetComponents/RoundIconSqurCard.vue';
export default {
	components: {
		RoundIconSqurCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M25,61.8c-6.5,0-11.7,5.3-11.7,11.9S18.5,85.5,25,85.5c6.5,0,11.7-5.3,11.7-11.9S31.5,61.8,25,61.8z M25,82
                c-4.6,0-8.3-3.7-8.3-8.4c0-4.7,3.7-8.4,8.3-8.4c4.6,0,8.3,3.7,8.3,8.4C33.3,78.3,29.6,82,25,82z M25,68.4c-2.7,0-5,2.3-5,5.1
                s2.3,5.1,5,5.1c2.7,0,5-2.3,5-5.1S27.7,68.4,25,68.4z M25,75.2c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8
                C26.7,74.4,26,75.2,25,75.2z M78.3,61.8c-6.5,0-11.7,5.3-11.7,11.9s5.2,11.9,11.7,11.9c6.5,0,11.7-5.3,11.7-11.9
                S84.8,61.8,78.3,61.8z M78.3,82c-4.6,0-8.3-3.7-8.3-8.4c0-4.7,3.7-8.4,8.3-8.4c4.6,0,8.3,3.7,8.3,8.4C86.5,78.3,82.9,82,78.3,82z
                M78.3,68.4c-2.7,0-5,2.3-5,5.1s2.3,5.1,5,5.1c2.7,0,5-2.3,5-5.1S81.2,68.4,78.3,68.4z M78.3,75.2c-1,0-1.7-0.8-1.7-1.7
                c0-1,0.8-1.8,1.7-1.8c1,0,1.7,0.8,1.7,1.8C80,74.4,79.2,75.2,78.3,75.2z M80,48.2h-3.3v-6.8c0-1-0.8-1.7-1.7-1.7
                c-1,0-1.7,0.8-1.7,1.7v6.8c0,1.9,1.5,3.3,3.3,3.3h3.3c1,0,1.7-0.8,1.7-1.8C81.5,48.7,81,48.2,80,48.2z M30,24.6
                c-6.5,0-11.7,5.3-11.7,11.9c0,5.8,4.6,20.2,11.7,20.2c7.1,0,11.7-14.4,11.7-20.2C41.7,29.9,36.5,24.6,30,24.6z M30,53.2
                c-3.8,0-8.3-11.1-8.3-16.9c0-4.7,3.7-8.4,8.3-8.4c4.6,0,8.3,3.7,8.3,8.4C38.3,42.1,33.8,53.2,30,53.2z M30,31.4
                c-2.7,0-5,2.3-5,5.1s2.3,5.1,5,5.1c2.7,0,5-2.3,5-5.1C35,33.6,32.7,31.4,30,31.4z M30,38c-1,0-1.7-0.8-1.7-1.7
                c0-1,0.8-1.8,1.7-1.8s1.7,0.8,1.7,1.8C31.7,37.3,31,38,30,38z M40,53.2c0,1,0.8,1.8,1.7,1.8h6.7c1,0,1.7-0.8,1.7-1.8
                c0-1-0.8-1.8-1.7-1.8h-6.7C40.8,51.7,40,52.4,40,53.2z M20,53.2c0-1-0.8-1.8-1.7-1.8h-6.5c-1,0-1.7,0.8-1.7,1.8
                c0,1,0.8,1.8,1.7,1.8h6.7C19.2,55,20,54.2,20,53.2z M91.7,55h-2.1l-5.6-20c-0.6-2.1-2.5-3.7-4.8-3.7H60v-8.4
                c0-4.7-3.7-8.4-8.3-8.4h-45c-3.7,0-6.7,3.1-6.7,6.8v47.3c0,3.5,3.1,6.6,6.7,6.6h1.7c1,0,1.7-0.8,1.7-1.7c0-1-0.8-1.8-1.7-1.8H6.7
                c-1.9,0-3.3-1.6-3.3-3.3V21.3C3.5,19.4,5,18,6.7,18h45c2.7,0,5,2.3,5,5.1v48.8h-15c-1,0-1.7,0.8-1.7,1.8s0.8,1.8,1.7,1.8h20
                c1,0,1.7-0.8,1.7-1.8s-0.8-1.8-1.7-1.8H60V58.5h31.7c2.7,0,5,2.3,5,5.1v8.4H95c-1,0-1.7,0.8-1.7,1.8s0.8,1.8,1.7,1.8h3.3
                c1,0,1.7-0.8,1.7-1.8V63.5C100,58.7,96.3,55,91.7,55z M60,55V34.7h6.7v15.2c0,1,0.8,1.8,1.7,1.8c1,0,1.7-0.8,1.7-1.8V34.7h9.2
                c0.8,0,1.5,0.4,1.7,1.2L86.5,55H60z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Trucking Booking Solutions',
					text: 'Build an on-demand truck booking app that will allow users to book a single truck of a fleet of them for their logistic purposes.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M79.7,61V49.9c0-3.3-2.5-5.9-5.8-6.3L73.7,42h3.7c0.8,0,1.5-0.8,1.5-1.5c0-0.2,0-0.2,0-0.4l-1.5-6.3
                c-0.2-0.8-0.8-1.2-1.5-1.2H72c-0.8-3.5-3.9-6-7.3-6.1H36.3c-3.7,0.2-6.7,2.7-7.5,6.1v0.2H25c-0.8,0-1.5,0.4-1.5,1.2l-1.5,6.3
                c-0.2,0.8,0.4,1.7,1.2,1.9c0.2,0,0.2,0,0.4,0h3.3l-0.4,1.5c-3.5,0.2-6,2.9-6,6.3V61c0,3.5,2.9,6.3,6.4,6.3v1.2
                c0,2.5,2.1,4.4,4.4,4.4h3.5c2.5,0,4.4-1.9,4.4-4.4v-1.2h22v1.2c0,2.5,2.1,4.4,4.4,4.4h3.5c2.5,0,4.4-1.9,4.4-4.4v-1.2
                C76.8,67.4,79.7,64.5,79.7,61z M74.7,35.9l0.8,3.1h-2.3l-0.6-3.1H74.7z M31.9,33.2c0.6-2.1,2.5-3.5,4.6-3.6h28.3
                c2.1,0.2,3.9,1.5,4.2,3.6L71,43.8H29.6L31.9,33.2z M25.3,39l0.8-3.1H28L27.3,39H25.3z M36.1,68.1h-0.2c0,0.8-0.6,1.3-1.3,1.2h-3.5
                c-0.8,0-1.3-0.6-1.3-1.2V67h6.4V68.1z M70.4,68.1c0,0.8-0.6,1.3-1.3,1.2h-3.7c-0.8,0-1.3-0.6-1.3-1.2V67h6.4V68.1z M76.6,60.8
                c0,1.7-1.3,3.1-3.1,3.1h-47c-1.7,0-3.1-1.3-3.1-3.1V49.9c0-1.7,1.3-3.1,3.1-3.1h47c1.7,0,3.1,1.3,3.1,3.1V60.8z M59.4,53.9H40.6
                c-0.8,0-1.5,0.8-1.5,1.5c0,0.8,0.8,1.5,1.5,1.5h18.9c0.8,0,1.5-0.8,1.5-1.5C61,54.7,60.2,53.9,59.4,53.9z M31.3,50.9
                c-2.5,0-4.6,2.1-4.6,4.6c-0.2,2.5,1.9,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6C35.9,53,33.8,50.9,31.3,50.9z M31.3,56.8
                c-0.8,0-1.5-0.8-1.5-1.5c-0.2-0.8,0.6-1.5,1.5-1.5c0.8,0,1.5,0.8,1.5,1.5C32.9,56,32.1,56.8,31.3,56.8z M68.9,50.9
                c-2.5,0-4.6,2.1-4.6,4.6c-0.2,2.5,1.9,4.6,4.6,4.6c2.5,0,4.6-2.1,4.6-4.6C73.5,53,71.4,50.9,68.9,50.9z M68.9,56.8
                c-0.8,0-1.5-0.8-1.5-1.5c-0.2-0.8,0.6-1.5,1.5-1.5c0.8,0,1.5,0.8,1.5,1.5C70.4,56,69.6,56.8,68.9,56.8z M3.2,67.8
                c0.4,0.8,1.2,1.2,2.1,1c1-0.2,1.2-1.2,1-2.1l-0.2-0.8c-0.4-0.8-1.2-1.2-2.1-1C3.2,65.3,2.8,66,3,67L3.2,67.8z M41.7,96h-0.2
                l-0.8-0.2C40,95.6,39,96.2,39,97.1c-0.2,0.8,0.4,1.5,1.2,1.7l0.8,0.2c0.8,0.2,1.7-0.4,1.9-1.2C43.1,97.1,42.5,96.2,41.7,96z
                M34.2,5.8h0.6l0.8-0.2c0.8-0.4,1.2-1.2,1-1.9c-0.2-0.8-1.2-1.2-1.9-1l-0.8,0.2c-0.8,0.2-1.2,1.1-1,1.9C33,5.4,33.6,5.8,34.2,5.8z
                M34.8,94.2l-0.6-0.2c-0.8-0.4-1.7,0.2-1.9,1c-0.4,0.8,0.2,1.7,1,1.9l0.6,0.2c0.8,0.2,1.7-0.2,1.9-1
                C35.9,95.4,35.6,94.4,34.8,94.2z M27.7,8.4c0.4,0,0.6,0,0.8-0.2L29,7.9c0.8-0.4,1.2-1.3,0.8-2.1C29.4,5,28.4,4.6,27.7,5l-0.8,0.4
                c-0.2,0-0.4,0.2-0.4,0.4c0.4,0.8,0.8,1.7,1,2.7H27.7z M95.3,28.6c-0.4-0.8-1.3-1.2-2.1-0.8c-0.8,0.4-1.2,1.3-0.8,2.1l0.4,0.8
                c0.4,0.8,1.3,1.2,2.1,0.8c0.8-0.4,1.2-1.3,0.8-2.1L95.3,28.6z M1.1,60.8C1.3,61.6,1.8,62,2.6,62c0.2,0,0.2,0,0.4,0
                c0.8-0.2,1.3-1,1.2-1.9c0-0.2-0.2-0.6-0.2-0.8C3.8,58.5,3,58,2,58.2c-0.8,0.2-1.3,1-1.2,1.9C1.1,60.3,1.1,60.7,1.1,60.8z
                M99.7,43.4c0-0.2,0-0.6-0.2-0.8c-0.2-0.8-1-1.5-1.7-1.3c-0.8,0.2-1.5,1-1.3,1.7c0,0.2,0,0.6,0.2,0.8c0,0.8,0.8,1.3,1.5,1.3
                C99.1,44.9,99.9,44.1,99.7,43.4z M0.1,45.5v0.8c-0.2,1,0.6,1.7,1.3,1.7h0.2c0.8,0,1.5-0.6,1.5-1.5v-0.8c0.2-0.8-0.6-1.5-1.3-1.5
                C0.9,44,0.1,44.7,0.1,45.5z M95.1,36.3c0,0.2,0.2,0.6,0.2,0.8c0.2,0.8,1.2,1.3,1.9,1.2c0.8-0.2,1.3-1.2,1.2-1.9
                c-0.2-0.4-0.4-0.6-0.4-1c-0.2-0.8-1.2-1.3-1.9-1C95.3,34.5,94.7,35.5,95.1,36.3z M1.3,38.4c0,0.2-0.2,0.6-0.2,0.8
                c-0.2,0.8,0.4,1.7,1.2,1.9C3,41.3,4,40.7,4.2,39.9c0-0.2,0.2-0.6,0.2-0.8c0.2-0.8-0.2-1.7-1.2-1.9C2.4,36.9,1.5,37.4,1.3,38.4z
                M1.7,55.1h0.2c0.8,0,1.5-0.8,1.5-1.7v-0.8c0-0.8-0.8-1.5-1.7-1.5c-0.8,0-1.5,0.8-1.5,1.7v0.8C0.1,54.3,0.9,55.1,1.7,55.1z
                M6.9,27.6C7.4,28,8,27.8,8.6,27.4c-0.8-0.2-1.5-0.6-2.5-0.8C6.3,27.1,6.5,27.4,6.9,27.6z M5.5,30.5c-0.8-0.4-1.7,0-2.1,1L3,32.2
                C2.6,33,3,34,3.8,34.4c0.8,0.4,1.7,0,2.1-0.8c0,0,0-0.2,0.2-0.2l0.4-0.8C6.7,31.7,6.3,30.9,5.5,30.5z M61.9,95.2c0,0,0,0-0.2,0
                L61,95.4c-0.8,0.2-1.3,1.2-1.2,1.9c0.4,0.6,1,1.2,1.7,1.2c0.2,0,0.2,0,0.4,0l0.8-0.2c0.8-0.2,1.3-1,1.2-1.9
                C63.7,95.6,62.9,95,61.9,95.2z M99.5,58c0-0.2,0-0.6,0.2-0.8c0.2-0.8-0.4-1.7-1.3-1.7c-1,0-1.7,0.4-1.7,1.3c0,0.2,0,0.6-0.2,0.8
                c-0.2,0.8,0.6,1.5,1.3,1.7H98C98.7,59.3,99.3,58.7,99.5,58z M93,72.6c0.8,0.4,1.7,0,2.1-0.8l0.4-0.8c0.4-0.8,0-1.7-0.8-2.1
                c-0.8-0.4-1.7,0-2.1,0.8l-0.4,0.8C91.8,71.2,92.2,72.2,93,72.6z M98.5,48.2c-0.8,0-1.5,0.8-1.5,1.5v1c0,0.8,0.8,1.5,1.5,1.5
                c0.8,0,1.5-0.8,1.5-1.5v-1C100.1,48.9,99.3,48.2,98.5,48.2z M96.2,66c0.8,0,1.3-0.4,1.5-1.2l0.2-0.8c0.2-0.6-0.2-1.5-1-1.7
                c-0.8-0.2-1.7,0.2-1.9,1l-0.2,0.8c-0.2,0.8,0.2,1.7,1,1.9C96,66,96.2,66,96.2,66z M55,96.5h-0.8c-0.8,0-1.5,0.8-1.5,1.5
                c0,0.8,0.8,1.5,1.5,1.5h1.2c0.8,0,1.5-1,1.3-1.7C56.7,97.1,55.8,96.4,55,96.5z M48.1,3.1h0.8c0.8,0,1.5-0.8,1.5-1.5
                c0-0.8-0.8-1.5-1.5-1.5h-0.8c-0.8,0-1.5,0.8-1.5,1.5C46.5,2.3,47.3,3.1,48.1,3.1z M41.1,3.8c0.2,0,0.2,0,0.4,0h0.6
                c0.8-0.2,1.3-1,1.2-1.9c-0.2-0.8-1-1.3-1.9-1.2L40.6,1c-0.8,0-1.5,0.8-1.3,1.7C39.4,3.5,40.2,4,41.1,3.8z M55,3.3l0.8,0.2
                c1,0,1.7-0.6,1.9-1.5c0-0.8-0.6-1.5-1.5-1.7h-0.8c-0.8,0-1.7,0.6-1.7,1.3C53.7,2.3,54.2,3.3,55,3.3z M48.5,96.7h-0.8
                c-0.8,0-1.5,0.6-1.7,1.5c-0.2,1,0.6,1.5,1.5,1.7h0.8v-0.2c1,0,1.5-0.6,1.7-1.5C50,97.5,49.4,96.7,48.5,96.7z M68.3,6.7l0.6,0.4
                c0.8,0.4,1.7,0,2.1-0.8c0.4-0.8,0-1.7-0.8-2.1l-0.8-0.4C68.7,3.6,67.7,4,67.5,5C67.3,5.8,67.5,6.5,68.3,6.7z M68.3,92.9l-0.8,0.4
                c-0.8,0.4-1.2,1.2-0.8,2.1c0.4,0.6,1,1,1.5,1c0.2,0,0.4,0,0.6-0.2l0.8-0.4c0.8-0.4,1.2-1.3,0.8-2.1C70,92.9,69.1,92.5,68.3,92.9z
                M61.7,4.8h0.8c0.8,0.2,1.7-0.2,1.9-1c0.2-0.8-0.2-1.7-1-1.9h-0.2l-0.8-0.2c-0.8-0.2-1.7,0.4-1.9,1.2C60.4,3.6,61,4.6,61.7,4.8z
                M12.4,25C19.4,25,25,19.4,25,12.5C25,5.6,19.4,0,12.4,0C5.5,0-0.1,5.6-0.1,12.5C-0.1,19.4,5.5,25,12.4,25z M12.4,21.9
                c-2.3,0-4.4-0.8-6.2-2.3c1-1.9,3.1-3.3,5.4-3.3h1.7c2.1,0,4.2,1.1,5.4,3.1C17.1,20.9,14.8,21.9,12.4,21.9z M10.9,10.9
                c0-0.8,0.8-1.5,1.5-1.5c0.8,0,1.5,0.8,1.5,1.5s-0.8,1.5-1.5,1.5C11.7,12.5,10.9,11.7,10.9,10.9z M7.8,4.2
                C12.2,1.7,18,3.5,20.5,7.9c1.7,2.9,1.7,6.1,0.2,9c-1.2-1.5-2.7-2.7-4.4-3.3c1.5-1.9,1-5-1-6.5c-1.9-1.5-5.2-1-6.7,1
                c-1.2,1.7-1.2,4,0,5.6c-1.7,0.8-3.3,1.9-4.4,3.5C1.7,12.5,3.4,6.7,7.8,4.2z M87.6,25c6.9,0,12.5-5.6,12.5-12.5
                C100.1,5.6,94.5,0,87.6,0C80.6,0,75,5.6,75,12.5C75,19.4,80.6,25,87.6,25z M87.6,21.9c-2.3,0-4.4-0.8-6.2-2.3
                c1-1.9,3.1-3.3,5.4-3.3h1.7c2.1,0,4.2,1.1,5.4,3.1C92.2,20.9,89.9,21.9,87.6,21.9z M86,10.9c0-0.8,0.8-1.5,1.5-1.5
                c0.8,0,1.5,0.8,1.5,1.5s-0.8,1.5-1.5,1.5C86.8,12.5,86,11.7,86,10.9z M83.1,4.2c4.4-2.5,10.2-0.8,12.7,3.6c1.5,2.9,1.5,6.1,0,9
                c-1.2-1.5-2.7-2.5-4.6-3.1c1.7-2.1,1.2-5.2-0.8-6.7c-1.9-1.5-5-1.2-6.5,1c-1.2,1.7-1.2,4,0,5.6c-1.7,0.6-3.5,1.7-4.4,3.3
                C77,12.3,78.7,6.7,83.1,4.2z M28.4,91.6l-0.6-0.4c0,0-0.2,0-0.2-0.2c-0.2,1-0.6,1.9-1.2,2.9l0.6,0.4c0.8,0.4,1.7,0.2,2.1-0.6
                C29.6,92.9,29.2,91.9,28.4,91.6z M8.8,72.2c-0.4-0.8-1.3-1-2.1-0.6c-0.6,0.4-1,1-0.8,1.5C6.7,72.7,7.6,72.4,8.8,72.2z M12.4,74.9
                c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5C25,80.4,19.4,74.9,12.4,74.9z M12.4,96.7
                c-2.3,0-4.4-0.8-6.2-2.3c1-1.9,3.1-3.3,5.4-3.3h1.7c2.1,0,4.2,1.2,5.4,3.1C17.1,95.8,14.8,96.7,12.4,96.7z M10.9,85.8
                c0-0.8,0.8-1.5,1.5-1.5c0.8,0,1.5,0.8,1.5,1.5s-0.8,1.5-1.5,1.5C11.7,87.3,10.9,86.6,10.9,85.8z M20.7,91.6
                c-1.2-1.5-2.7-2.5-4.6-3.1c1.7-2.1,1.2-5-1-6.5c-2.1-1.5-5-1.2-6.5,1c-1.2,1.7-1.2,4,0,5.6c-1.7,0.6-3.5,1.7-4.4,3.3
                c-2.5-4.6-0.8-10.2,3.7-12.7c1.5-0.8,3.1-1.2,4.6-1.2c5.2,0,9.4,4.2,9.4,9.4C21.9,88.9,21.5,90.4,20.7,91.6z M87.6,74.9
                c-6.9,0-12.5,5.6-12.5,12.5c0,6.9,5.6,12.5,12.5,12.5c6.9,0,12.5-5.6,12.5-12.5C100.1,80.4,94.5,74.9,87.6,74.9z M87.6,96.7
                c-2.3,0-4.4-0.8-6.2-2.3c1-1.9,3.1-3.3,5.4-3.3h1.7c2.1,0,4.2,1.2,5.4,3.1C92.2,95.8,89.9,96.7,87.6,96.7z M86,85.8
                c0-0.8,0.8-1.5,1.5-1.5c0.8,0,1.5,0.8,1.5,1.5s-0.8,1.5-1.5,1.5C86.8,87.3,86,86.6,86,85.8z M95.8,91.7c-1.2-1.5-2.7-2.5-4.6-3.1
                c1.5-2.1,1.2-5-1-6.5c-2.1-1.5-5-1.1-6.5,1c-1.2,1.7-1.2,4,0,5.6c-1.7,0.6-3.5,1.7-4.4,3.3c-2.5-4.6-0.8-10.2,3.7-12.7
                c4.6-2.5,10.2-0.8,12.7,3.6C97.4,85.6,97.4,88.9,95.8,91.7z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Carpooling App',
					text: 'Bring together car owners and passengers sharing the same route and travel timings with a carpooling app.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M99.4,60.7c-1.1-2.7-3.3-5.6-5.9-7.9c-0.2-0.2-0.2-0.2-0.4-0.2c-0.4-0.2-0.6-0.4-1-0.6c-0.2-0.2-0.4-0.2-0.6-0.4
                c-0.4-0.2-0.6-0.4-1-0.6c-0.2-0.2-0.4-0.2-0.6-0.2c-0.2-0.2-0.6-0.2-0.8-0.4c-0.2,0-0.4-0.2-0.6-0.2c-0.2-0.2-0.6-0.2-1-0.4
                c-0.2,0-0.4-0.2-0.8-0.2c-0.4,0-0.6-0.2-1-0.2c-0.2,0-0.6,0-0.8-0.2c-0.4,0-0.6,0-1-0.2h-1.5c0,0,0,0-0.2,0h-1.9
                c-0.4,0-1,0.2-1.3,0.2l-1.9-5.8c2.1-1,3.6-3.1,3.6-5.4v-3.3c0-2.3-1.3-4.3-3.3-5.2v-2.9l3.3-1.7v0.8c0,1,0.8,1.7,1.7,1.7
                c1,0,1.7-0.8,1.7-1.7v-6.6c0-1-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7v2.3l-5.7,2.9c-0.6,0.2-1,0.8-1,1.5v3.5c-0.6,0-1,0.2-1.3,0.4
                l-0.4-1c-0.2-0.6-0.6-1-1.3-1.2l-7.7-1.9c-1-0.2-1.7,0.4-1.9,1.4C61.5,27.8,62,28.8,63,29l7.3,1.5l0.4,1.4
                c-0.4,0.6-0.6,1.2-0.8,1.7c-1.3-1-2.7-1.4-4.2-1.4h-6.3c-2.5,0-5,0.8-7.3,2.1l-4.4,2.9h-3.1c-3.1,0-6.1-0.4-9.2-1l-3.6-0.8H19.7
                c-0.6,0-1.1,0.2-1.7,0.4v-5.4c0-3.7-3.1-6.8-6.7-6.8H6.7c-3.6,0-6.7,3.1-6.7,6.8v15.1c0,0.2,0,0.4,0.2,0.6c0,0,0,0,0,0.2l2.9,5.8
                C1.1,55.3,0,58.7,0,62.4c0,1.2,0.8,1.9,1.7,1.9h3.4v3.3l-3.4,0.2c-1,0-1.5,0.8-1.5,1.7v6.4c0,1,0.8,1.7,1.7,1.7h0.2l7.3-0.8
                c5.9,7,16.5,7.7,23.4,1.5c1.3-1.2,2.5-2.7,3.4-4.3h20.5c3.6,0,6.7-3.1,6.7-6.8c0-0.8-0.2-1.5-0.4-2.3l7.1-18.8v-0.2
                c1.1-0.8,2.1-1.9,2.7-3.1c0.6,0.4,1.3,0.8,2.1,0.8l2.1,6.6c-0.6,0.2-1.3,0.6-1.9,1h-0.2c-4.2,2.5-6.9,6.8-7.9,11.4V63
                c-0.2,0.6-0.2,1.4-0.2,1.9c0,0.2,0,0.4,0,0.4v0.2c0,0.6,0,1.4,0.2,1.9v0.2c0.2,0.8,0.2,1.5,0.4,2.1c2.5,8.9,11.7,14.1,20.5,11.6
                C96.7,78.8,101.9,69.6,99.4,60.7z M59.9,35.9h3.3v8.3h-3.3V35.9z M49.8,40.2l4.2-2.7c0.8-0.6,1.7-1,2.5-1.2v7.9h-6.7V40.2z
                M57.8,47.5l-1,5h-4l-1-5H57.8z M48.6,47.5l1,5h-6.5l2.1-3.3c0.4-0.6,0.6-1.2,0.6-1.7H48.6z M33.3,39.4l1.7,0.4
                c3.1,0.8,6.3,1,9.6,1h1.9V44H33.3V39.4z M42.1,47.5l-2.5,4.3c-1.1-1.5-2.7-3.1-4.2-4.3H42.1z M18.2,40.9c0-1.2,0.8-1.7,1.7-1.7h10
                v5H18.2V40.9z M6.7,27.4h5c1.9,0,3.3,1.5,3.3,3.3H3.3C3.3,29,4.8,27.4,6.7,27.4z M3.3,34.2h11.7v10.1H3.3V34.2z M7.9,47.5
                c-1,0.6-1.7,1.4-2.5,2.1l-1-2.1H7.9z M3.4,60.9c0.8-7.5,7.3-13.3,14.7-13.3h6.7c7.7,0,14,5.8,14.9,13.3H3.4z M39.8,66.1h-1.5v-1.7
                h1.1L39.8,66.1z M31.4,64.3h3.4c0,0.6,0.2,1.2,0.2,1.7l-3.3,0.2v-0.2C31.6,65.3,31.6,64.7,31.4,64.3z M26.2,64.3H28
                c0.2,0.6,0.2,1.2,0.2,1.7c0,0.2,0,0.2,0,0.4h-1.7c0-0.2,0-0.4,0-0.4C26.6,65.3,26.4,64.7,26.2,64.3z M23.2,65.7
                c0,0.2,0,0.6-0.2,0.8l-2.9,0.2c-0.2-0.2-0.2-0.6-0.2-0.8c0-1,0.8-1.7,1.5-1.7C22.4,64.1,23.2,64.9,23.2,65.7z M16.7,67h-1.7v-1
                c0-0.6,0-1.2,0.2-1.7h1.7c-0.2,0.6-0.4,1.2-0.4,1.7C16.5,66.5,16.5,66.7,16.7,67z M11.9,67l-3.4,0.2c-0.2-0.4-0.2-0.8-0.2-1.4
                c0-0.6,0-1.2,0.2-1.7h3.4C11.7,65.1,11.7,66.1,11.9,67z M21.6,79.4c-2.9,0-5.7-1-8-2.9l18-1.7C29.1,77.7,25.5,79.2,21.6,79.4z
                M43.1,71.1l-6.7-0.2L3.3,74.2v-3.3l33.3-1.5H45c0.2,0.6,0.4,1.2,0.8,1.7H43.1z M45.2,65.9h-1.7l-0.6-2.1c0.4-0.4,0.6-0.8,0.6-1.2
                c0-2.3-0.4-4.6-1.3-6.8h10.1c-0.8,1.5-0.8,3.5,0,5h-0.6C48.4,60.9,45.8,63,45.2,65.9z M56.5,70.9h-5c-1.9,0-3.3-1.5-3.3-3.3
                c0-1.9,1.5-3.3,3.3-3.3h5c1.9,0,3.3,1.5,3.3,3.3C59.9,69.4,58.4,70.9,56.5,70.9z M59,60.9h-1.5c-1.3,0-2.5-1.2-2.5-2.5
                s1.1-2.5,2.5-2.5H59c1.3,0,2.5,1.2,2.5,2.5S60.3,60.9,59,60.9z M63.8,52.5h-3.6l1-5h4.6L63.8,52.5z M69.7,40.9
                c-0.2,1.5-1.5,2.7-3.3,3.1v-8.1C68.7,36.3,70.1,38.6,69.7,40.9z M81.6,52.5h1.5c0.6,0,1.1,0,1.5,0.2c0.2,0,0.2,0,0.4,0
                c0.4,0,0.8,0.2,1.1,0.2c0.2,0,0.2,0,0.4,0.2c0.4,0.2,0.8,0.2,1.3,0.4h0.2c0.6,0.2,1,0.4,1.5,0.8l-3.8,3.3c-1.3,0-2.3,0.2-3.4,0.4
                l-1.5-5.2C81,52.7,81.2,52.5,81.6,52.5z M80.2,62.4l0.4,1.4c-1.1,1.4-1.1,3.5,0.2,4.6c1.3,1.2,3.4,1.2,4.6-0.2
                c1.1-1.4,1.1-3.5-0.2-4.6c-0.4-0.4-1-0.6-1.5-0.8l-0.4-1.5c0.8-0.2,1.5-0.2,2.3-0.2h1c0.2,0,0.6,0,0.8-0.2
                c1.5,1.4,2.5,3.3,2.5,5.2c0,3.7-3.1,6.8-6.7,6.8c-3.4,0-6.3-2.7-6.7-6.2C77.4,64.7,78.7,63.4,80.2,62.4z M75.4,40.7
                c-1.3,0-2.3-1.2-2.3-2.5v-3.3c0-0.6,0.2-1,0.4-1.4c0.2-0.2,0.2-0.2,0.2-0.4c1-1,2.5-1,3.4,0c0.6,0.4,0.8,1.2,0.8,1.7v3.3
                C77.9,39.6,76.8,40.7,75.4,40.7z M69.9,65.9c0-1.5,0.2-2.9,0.6-4.1c1-3.1,3.1-5.6,5.7-7.3c0.4-0.2,0.8-0.4,1.1-0.6l1.7,5.4
                c-2.5,1.4-4.4,3.3-5.7,5.6l-3.6,2.5C69.9,66.9,69.9,66.3,69.9,65.9z M92.7,75.4c-2.5,2.5-5.9,4.1-9.6,4.1
                c-5.6,0-10.5-3.5-12.4-8.7l2.9-1.9c1.5,5.4,7.1,8.3,12.4,6.8c5.4-1.5,8.2-7.2,6.7-12.4c-0.6-1.7-1.5-3.3-2.9-4.6l2.5-2.1
                C97.7,61.6,97.7,69.9,92.7,75.4z M10,37.5H8.2c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7H10c1,0,1.7-0.8,1.7-1.7
                C11.7,38.2,10.9,37.5,10,37.5z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Hire a Bike',
					text: 'Kick off an on-demand bike rental business that allows users to connect to bike fleet owners and vice versa.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<path
		d="M99,59.2L93.3,45h2.5c1,0,1.7-0.8,1.7-1.7c0-1-0.8-1.7-1.7-1.7h-6.1c0.8-1,1.3-2.1,1.3-3.2c2.7,0,5-2.3,5-4.9
            c0-2.7-2.3-5-5-5h-2.5L84,22.5c-0.4-0.4-0.8-0.6-1.3-0.6H65.9c-0.6,0-1.1,0.4-1.5,1l-2.7,5.7h-3.8c-2.7,0-5,2.3-5,4.9
            c0,0.6,0.2,1.1,0.4,1.7h-3.6V20.4c0-1-0.8-1.7-1.7-1.7c-1,0-1.7,0.8-1.7,1.7v14.8H43v-4.9c0-1-0.8-1.7-1.7-1.7h-0.6l-2.9-5.7
            c-0.2-0.6-0.8-1-1.5-1H23.1c-0.4,0-1,0.2-1.1,0.6l-6.1,6.1h-2.7c-2.7,0-5,2.3-5,4.9v3.2c0,1,0.8,1.7,1.7,1.7h5
            c0,0.8,0.2,1.5,0.6,2.3l-0.2,1H1.8c-1,0-1.7,0.8-1.7,1.7c0,1,0.8,1.7,1.7,1.7h13.2v25.3L1,76.6c-0.8,0.4-1.1,1.1-1,1.9
            c0.2,0.8,0.8,1.3,1.5,1.3c0.2,0,0.4,0,0.8-0.2l14.5-6.5c-0.2,0.6-0.2,1.1-0.2,1.5c0,3.6,3.1,6.7,6.7,6.7s6.7-3,6.7-6.7
            c0-0.6,0-1.1-0.2-1.7h3.6v5c0,1,0.8,1.7,1.7,1.7h33c1,0,1.7-0.8,1.7-1.7v-5h3.6c-0.2,0.6-0.2,1.1-0.2,1.7c0,3.6,3.1,6.7,6.7,6.7
            c3.6,0,6.7-3,6.7-6.7c0-0.6,0-1.1-0.2-1.7h1l2.9,4.2c0.4,0.4,0.8,0.8,1.3,0.8h6.7c1,0,1.7-0.8,1.7-1.7V59.6
            C99.2,59.6,99.2,59.4,99,59.2L99,59.2z M95.2,58.1H81.1v-6.7h11.4L95.2,58.1z M76.2,25.2h5.7l2.5,3.2h-8.2V25.2z M67,25.2h5.7v3.2
            h-7.2L67,25.2z M49.6,38.3h8.2c0,1.1,0.4,2.5,1.3,3.2h-9.5V38.3z M41.4,38.3h5v3.2h-6.3C40.9,40.8,41.4,39.6,41.4,38.3z M29.8,25.2
            h5.5l1.7,3.2h-7.2V25.2z M23.9,25.2h2.7v3.2h-5.9L23.9,25.2z M16.3,35.1h-4.6v-1.7c0-1,0.8-1.7,1.7-1.7h26.5v3c-1-1-2.3-1.3-3.6-1.3
            s-2.5,0.6-3.4,1.7h-9.2c-1-1.1-2.3-1.7-3.6-1.7C18.6,33.3,17.2,33.9,16.3,35.1z M38,38.3c0,1-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7H38z
            M32.7,41.7h-9.2c0.8-1,1.3-2.1,1.3-3.2h6.7C31.5,39.6,31.9,40.8,32.7,41.7z M21.6,38.3c0,1-0.8,1.7-1.7,1.7c-0.2,0-0.6,0-0.8-0.2
            l0.4-1.5H21.6z M23.1,77.9c-1.9,0-3.2-1.5-3.2-3.2c0-1.9,1.5-3.2,3.2-3.2c1.9,0,3.2,1.5,3.2,3.2C26.6,76.6,25,77.9,23.1,77.9z
            M62.8,59.8c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7h-2.9c-1-1.1-2.3-1.7-3.6-1.7s-2.7,0.6-3.6,1.7h-9.2c-1-1.1-2.3-1.7-3.6-1.7
            c-1.3,0-2.7,0.6-3.6,1.7H35c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7H62.8z M39.2,56.6l1.7-3.2h5.5v3.2H39.2z M49.6,56.6v-3.2h2.7
            l3.2,3.2H49.6z M52.5,69.7h-9.2c0.8-1,1.3-2.1,1.3-3.2h6.7C51.4,67.6,51.7,68.8,52.5,69.7z M39.7,76.4h-3.2v-3.2h3.2V76.4z M39.7,68
            c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7C41.4,67.2,40.7,68,39.7,68z M46.4,76.4h-3.2v-3.2h3.2V76.4z
            M56.3,76.4h-6.7v-3.2h6.7V76.4z M56.3,68c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7c1,0,1.7,0.8,1.7,1.7C57.8,67.2,57.1,68,56.3,68
            z M66.2,76.4h-6.7v-3.2h6.7V76.4z M71.2,69.7H59.9c0.8-1,1.3-2.1,1.3-3.2H63c2.7,0,5-2.3,5-4.9c0-2.7-2.3-4.9-5-4.9h-2.7l-6.1-6.1
            c-0.4-0.4-0.8-0.6-1.3-0.6H39.7c-0.6,0-1.1,0.4-1.5,1l-2.9,5.7h-0.6c-2.7,0-5,2.3-5,4.9c0,2.7,2.3,4.9,5,4.9c0,1.1,0.4,2.5,1.3,3.2
            h-8.6c-2.5-2.3-6.1-2.3-8.6,0h-0.8V45H71L71.2,69.7L71.2,69.7z M61.3,38.3h3.2c0,1-0.8,1.7-1.7,1.7S61.3,39.2,61.3,38.3z M66.6,41.7
            c0.8-1,1.3-2.1,1.3-3.2h2.1l0.8,3.2H66.6z M66.6,35.1c-1-1.1-2.3-1.7-3.6-1.7s-2.7,0.6-3.6,1.7H58c-1,0-1.7-0.8-1.7-1.7
            c0-1,0.8-1.7,1.7-1.7h33c1,0,1.7,0.8,1.7,1.7c0,1-0.8,1.7-1.7,1.7h-1.3c-1-1.1-2.3-1.7-3.6-1.7c-1.3,0-2.7,0.6-3.6,1.7H66.6z
            M87.6,38.3c0,1-0.8,1.7-1.7,1.7c-1,0-1.7-0.8-1.7-1.7c0-1,0.8-1.7,1.7-1.7C86.8,36.6,87.6,37.5,87.6,38.3z M73.3,38.3h7.8
            c0,1.1,0.4,2.5,1.3,3.2h-8.2L73.3,38.3z M79.4,77.9c-1.9,0-3.2-1.5-3.2-3.2c0-1.9,1.5-3.2,3.2-3.2c1.9,0,3.2,1.5,3.2,3.2
            C82.6,76.6,81.3,77.9,79.4,77.9z M96,74.7h-4l-2.9-4.2c-0.4-0.4-0.8-0.8-1.3-0.8h-4c-2.5-2.3-6.1-2.3-8.6,0h-0.8V45h15.5l1.3,3.2
            H79.4c-1,0-1.7,0.8-1.7,1.7v9.9c0,1,0.8,1.7,1.7,1.7H96V74.7z"
	></path></svg
>`,
					title: 'Tow Truck app',
					text: 'Create your own Uber for tow trucks that can be of aid for roadside assistance.'
				},
				{
					svg: `<svg
	x="0px"
	y="0px"
	viewBox="0 0 100 100"
	style="enable-background: new 0 0 100 100"
	xml:space="preserve"
>
	<g>
		<g>
			<path
				d="M92.5,22l-7-6.4c-1.6-1.5-3.7-2.3-5.9-2.3H30.8l-1.4-5C28.2,3.5,23.7,0,18.7,0c-0.8,0.2-1.6,1-1.6,1.9v19.3
                c0,2.1,0.6,4,1.6,6c2.9,5,8.2,7.9,13.9,7.9h21.1l-3.9,6.7c-0.4,0.6-0.4,1.2,0,1.7c0.4,0.6,1,0.8,1.6,0.8h5.3
                c4.9,0,9.6-2.3,12.5-6.4c0,0,0-0.2,0.2-0.2l0.8-1.3h16.6c0.2,0,0.2,0,0.4,0c3.3,0,6.4-2.1,7.6-5.4C95.9,27.9,95.1,24.3,92.5,22z
                M79.8,20h5.5l2.1,1.9h-7.6c-0.6,0-1-0.4-1-1S79.2,20,79.8,20z M66.3,36.2c-2.1,3.1-5.9,4.8-9.6,4.8h-2.3l5.3-9.2h9L66.3,36.2z
                M91.4,29.9c-0.6,1.9-2.3,3.1-4.3,3.1H72l0.4-0.6c0.2-0.4,0.2-0.8,0.2-1.5c0-0.8,0-1.2-0.2-1.5c-0.4-0.6-1-1-1.6-1H58.7
                c-0.6,0-1.2,0.4-1.6,1l-1.4,2.5H32.7c-4.5,0-8.6-2.3-10.9-6.2c-0.8-1.3-1.2-2.7-1.2-4.2V3.9c2.7,0.6,4.9,2.7,5.7,5.4l1.6,6.2
                c0.2,0.8,1,1.3,1.8,1.3h48.9c-2.1,0.6-3.5,2.7-3.1,4.8c0.4,2.1,2.1,3.9,4.5,3.9H91C91.8,26.8,91.9,28.5,91.4,29.9z M67.7,19.7
                H33.9c-1,0-1.8,0.8-1.8,1.7c0,1,0.8,1.7,1.8,1.7h33.8c1,0,1.8-0.8,1.8-1.7C69.5,20.4,68.7,19.7,67.7,19.7z M9.7,11.2h2.5
                c1,0,1.8-0.8,1.8-1.7c0-1-0.8-1.7-1.8-1.7H9.7c-1,0-1.8,0.8-1.8,1.7C7.9,10.4,8.7,11.2,9.7,11.2z M15.5,18.1c-0.2-1-1-1.7-2-1.7
                h-7c-1,0-1.8,0.8-1.8,1.7c0,1,0.8,1.7,1.8,1.7h7.2C14.8,19.8,15.5,19.1,15.5,18.1z M12.6,25H9.1c-1,0-1.8,0.8-1.8,1.7
                c0,1,0.8,1.7,1.8,1.7h3.5c1,0,1.8-0.8,1.8-1.7C14.4,25.8,13.6,25,12.6,25z M30,74.8c-3.5,0-6.3,2.7-6.3,6.2c0,3.5,2.7,6.2,6.3,6.2
                c3.5,0,6.3-2.7,6.3-6.2C36.2,77.5,33.5,74.8,30,74.8z M30,83.2c-1.4,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.4,0,2.3,1,2.3,2.3
                C32.3,82.1,31.4,83.2,30,83.2z M69.9,74.8c-3.5,0-6.3,2.7-6.3,6.2c0,3.5,2.7,6.2,6.3,6.2c3.5,0,6.3-2.7,6.3-6.2
                C76.1,77.5,73.4,74.8,69.9,74.8z M69.9,83.2c-1.4,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.4,0,2.3,1,2.3,2.3
                C72.2,82.1,71.2,83.2,69.9,83.2z M81.2,63.4h-2c-1.4,0-2.9,0.6-3.9,1.3l-3.1-8.9c-0.8-2.1-2.7-3.5-5.1-3.5h-6.3
                c-0.2-2.9-2.5-5-5.5-5H45c-2.9,0-5.3,2.1-5.5,5h-7c-2.1,0-4.3,1.3-5.1,3.5l-3.1,8.9c-1.2-1-2.5-1.5-4.1-1.5h-2
                c-1.8,0-3.3,1.5-3.3,3.3v2.3c0,1.7,1.6,3.3,3.3,3.3h0.4c-1.4,1.3-2.1,3.3-2.1,5.2v6.6c0,4,3.3,7.3,7.4,7.3h0.4v5.2
                c0,1.9,1.6,3.7,3.7,3.7h5.3c2,0,3.7-1.5,3.7-3.7v-4.8h25.6v4.8c0,1.9,1.6,3.7,3.7,3.7h5.3c2,0,3.7-1.5,3.7-3.7v-4.8h0.4
                c4.1,0,7.4-3.3,7.4-7.3v-6.6c0-1.9-0.8-3.9-2.1-5.2h0.2c1.8,0,3.3-1.5,3.3-3.3v-2.3C84.5,64.9,83,63.4,81.2,63.4z M45.2,51.4h10.4
                c0.8,0,1.4,0.6,1.6,1.2H43.7C43.9,51.8,44.5,51.4,45.2,51.4z M31.4,57.4c0.2-0.6,0.8-1,1.4-1h34.4c0.6,0,1.2,0.4,1.4,1L73,70.3
                h-3.5c-0.2-3.3-2.9-6-6.3-6c-3.3,0-6.3,2.7-6.3,6H26.9L31.4,57.4z M65.6,70.3h-4.7c0-1.2,1.2-2.1,2.3-2.1
                C64.4,68,65.4,69,65.6,70.3z M19.2,67.2h1.4c0.8,0,1.6,0.6,2,1.3h-3.3V67.2z M33.3,96.1h-4.7v-4.6h4.7V96.1z M71.4,96.1h-4.7v-4.6
                h4.7V96.1z M79.2,84.2c0,1.9-1.6,3.5-3.5,3.5H24.3c-2,0-3.5-1.5-3.5-3.5v-6.6c0-1.9,1.6-3.5,3.5-3.5h51.4c2,0,3.5,1.5,3.5,3.5
                V84.2z M80.6,68.6h-3.3c0.4-0.8,1.2-1.3,2-1.3h1.4V68.6z"
			></path>
		</g>
	</g></svg
>`,
					title: 'Airport Taxi',
					text: 'Airport shuttles are hard to find and difficult to book. An airport taxi booking app can make things simpler for all.'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 52 52">
	<path
		d="M-146.4,673.7v-5.9c0-1.1-0.5-2-1.3-2.7s-1.9-0.9-2.9-0.7l-42.4,9.1h-1.3v66h46.5c3,0,5.5-2.5,5.5-5.5v-55
            C-142.4,676.4-144.1,674.3-146.4,673.7z M-150,667.3c0.1,0,0.2-0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4v5.7h-29.3L-150,667.3z
            M-145.4,733.9c0,1.4-1.1,2.5-2.5,2.5h-43.5v-60h43.5c1.4,0,2.5,1.1,2.5,2.5V733.9z"
	></path>
	<path
		d="M-168.4,680.4c-9.1,0-16.5,7.4-16.5,16.5s7.4,16.5,16.5,16.5s16.5-7.4,16.5-16.5S-159.3,680.4-168.4,680.4z M-160.4,695.4
            c-0.1-1.7-0.3-3.3-0.5-4.8c1.1-0.6,2.1-1.3,3.1-2.1c1.6,1.9,2.6,4.3,2.9,7h-5.5V695.4z M-168.4,710.4c-1.2,0-2.8-1.9-3.9-5.3
            c1.2-0.4,2.5-0.6,3.9-0.6c1.3,0,2.6,0.2,3.9,0.6C-165.6,708.5-167.1,710.4-168.4,710.4z M-161.7,706.4c0.6,0.3,1.1,0.7,1.7,1.2
            c-0.9,0.7-1.9,1.3-2.9,1.7C-162.5,708.4-162.1,707.5-161.7,706.4z M-173.8,709.3c-1-0.5-2-1-2.9-1.7c0.5-0.4,1.1-0.8,1.7-1.2
            C-174.6,707.5-174.2,708.4-173.8,709.3z M-168.4,701.6c-1.6,0-3.1,0.2-4.6,0.7c-0.2-1.2-0.4-2.4-0.4-3.8h9.9
            c-0.1,1.4-0.2,2.6-0.4,3.8C-165.3,701.8-166.8,701.6-168.4,701.6z M-173.3,695.4c0.1-1.3,0.2-2.5,0.4-3.7c1.5,0.4,3,0.7,4.6,0.7
            s3.1-0.2,4.6-0.7c0.2,1.1,0.3,2.4,0.4,3.7H-173.3L-173.3,695.4z M-168.4,683.4c1.2,0,2.8,2,3.9,5.4c-1.2,0.4-2.6,0.6-3.9,0.6
            c-1.3,0-2.6-0.2-3.9-0.6C-171.2,685.4-169.6,683.4-168.4,683.4z M-175,687.6c-0.6-0.4-1.2-0.8-1.7-1.2c0.9-0.7,1.9-1.3,3-1.8
            C-174.2,685.5-174.7,686.5-175,687.6z M-163,684.6c1.1,0.5,2.1,1.1,3,1.8c-0.5,0.5-1.1,0.9-1.7,1.2
            C-162.1,686.5-162.5,685.5-163,684.6z M-176.9,686.5l-1.8,2.1c0.9,0.8,1.9,1.4,2.9,2c-0.3,1.5-0.5,3.1-0.5,4.8h-5.4
            C-181.4,691.9-179.6,688.7-176.9,686.5z M-181.8,698.4h5.4c0.1,1.8,0.3,3.4,0.6,5c-1.1,0.6-2.1,1.3-3.1,2.1
            C-180.4,703.5-181.5,701.1-181.8,698.4z M-157.9,705.5c-0.9-0.8-2-1.5-3-2.1c0.3-1.5,0.5-3.2,0.6-4.9h5.4
            C-155.2,701.1-156.3,703.5-157.9,705.5z"
	></path>
	<rect x="-184.4" y="719.4" width="32" height="3"></rect>
	<rect x="-176.4" y="724.4" width="16" height="3"></rect>
	<circle fill="#222222" cx="40.8" cy="37.3" r="1.7"></circle>
	<circle fill="#222222" cx="11.3" cy="37.3" r="1.7"></circle>
	<path
		fill="#222222"
		d="M14.8,24.3H9.6c-0.5,0-0.9,0.4-0.9,0.9v5.2c0,0.5,0.4,0.9,0.9,0.9h5.2c0.5,0,0.9-0.4,0.9-0.9v-5.2
            C15.6,24.7,15.2,24.3,14.8,24.3z M13.9,29.5h-3.5v-3.5h3.5V29.5z"
	></path>
	<path
		fill="#222222"
		d="M48.6,30.4v-5.2c0-0.5-0.4-0.9-0.9-0.9H18.2c-0.5,0-0.9,0.4-0.9,0.9v5.2c0,0.5,0.4,0.9,0.9,0.9h29.5
            C48.2,31.3,48.6,30.9,48.6,30.4z M46.8,29.5H19.1v-3.5h27.7V29.5z"
	></path>
	<path
		fill="#222222"
		d="M48.6,21.7v-5.2c0-0.5-0.4-0.9-0.9-0.9H9.6c-0.5,0-0.9,0.4-0.9,0.9v5.2c0,0.5,0.4,0.9,0.9,0.9h38.1
            C48.2,22.6,48.6,22.2,48.6,21.7z M46.8,20.9H10.4v-3.5h36.4V20.9z"
	></path>
	<path
		fill="#222222"
		d="M51.2,12.2h-2.6V9.6c0-0.5-0.4-0.9-0.9-0.9H31.2c-0.2,0-0.5,0.1-0.6,0.3l-0.9,0.9c-0.2,0.2-0.3,0.4-0.3,0.6
            v1.7H4.4C2,12.2,0,14.1,0,16.5v20.8c0,0.5,0.4,0.9,0.9,0.9h6.2c0.5,2.3,2.8,3.9,5.1,3.4c1.7-0.3,3-1.7,3.4-3.4h21
            c0.5,2.3,2.8,3.9,5.1,3.4c1.7-0.3,3-1.7,3.4-3.4h6.2c0.5,0,0.9-0.4,0.9-0.9V13.1C52,12.6,51.6,12.2,51.2,12.2z M31.2,10.8l0.4-0.4
            h15.2v1.7H31.2V10.8z M5.2,17.4v3.5H1.8v-3.5H5.2z M1.8,26.1h3.5V33H1.8V26.1z M11.3,39.9c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6
            s2.6,1.2,2.6,2.6S12.7,39.9,11.3,39.9z M24.3,36.5h-5.2v-1.7h5.2V36.5z M26,36.5v-1.7h5.2v1.7H26z M40.8,39.9
            c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6S42.2,39.9,40.8,39.9z M45,36.5c-0.5-2.3-2.8-3.9-5.1-3.4
            c-1.7,0.3-3,1.7-3.4,3.4H33v-2.6c0-0.5-0.4-0.9-0.9-0.9H18.2c-0.5,0-0.9,0.4-0.9,0.9v2.6h-1.8c-0.5-2.3-2.8-3.9-5.1-3.4
            c-1.7,0.3-3,1.7-3.4,3.4H1.8v-1.7h4.3c0.5,0,0.9-0.4,0.9-0.9v-8.7c0-0.5-0.4-0.9-0.9-0.9H1.8v-1.7h4.3c0.5,0,0.9-0.4,0.9-0.9v-5.2
            c0-0.5-0.4-0.9-0.9-0.9H1.9c0.4-1,1.3-1.7,2.4-1.7h45.9V33h-1.7c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h1.7v1.7H45z"
	></path></svg
>`,
					title: 'Bus Booking',
					text: 'An on-demand bus booking app that allows users to book seats or entire buses for their personal/professional purposes.'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
</style>
