<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Why Choose Us</span>
				<h2 class="fxt-process-dev">
					Why Genixbit is Your Exclusive iPad & iPhone Game Development Partner?
				</h2>
				<p>
					The selection of a dependable iOS game development company is indeed daunting, hopefully
					our skill set and expertise can help you decide why Genixbit should undertake your iOS
					game app development project.
				</p>
			</div>
			<div class="row align-items-center">
				<div class="col-12 col-lg-6 col-sm-12">
					<div class="game-why-us-inner-content-wrap">
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										class="st0"
										d="M47.5,44.1c-1.3-2.5-3.8-4.1-6.7-4.1h-4.2c-3.2,0-5.7-2.5-5.8-5.7l1.6-5.7c0.1-0.3,0-0.6-0.2-0.8l-3.6-3.6
                    l5.4-6.9c0.2-0.3,0.2-0.8,0-1.1L25.6,7c-0.3-0.3-0.9-0.3-1.2,0l-8.3,9.1c-0.3,0.3-0.3,0.7,0,1.1l5.4,6.9l-3.6,3.6
                    c-0.2,0.2-0.3,0.5-0.2,0.8l1.6,5.7c-0.1,3.1-2.6,5.7-5.8,5.7H9.2c-2.8,0-5.4,1.6-6.7,4.1l-2.3,4.6c-0.1,0.1-0.1,0.2-0.1,0.4v0.8h1.7
                    v-0.6L4,44.8c1-2,3-3.2,5.2-3.2h4.2c2,0,3.8-0.8,5.2-2.1l3.4,2.7l1.4,1.8l-2.3,4.7c-0.1,0.1-0.1,0.2-0.1,0.4v0.8h1.7v-0.6l1.9-3.8
                    c0.2,0.2,0.4,0.3,0.6,0.3s0.4-0.1,0.6-0.3l1.9,3.8v0.6h1.7v-0.8c0-0.1,0-0.3-0.1-0.4L26.7,44l1.4-1.8l3.4-2.7
                    c1.3,1.3,3.2,2.1,5.2,2.1h4.2c2.2,0,4.2,1.2,5.2,3.2l2.2,4.5v0.6h1.7v-0.8c0-0.1,0-0.3-0.1-0.4L47.5,44.1z M25,16.7
                    c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8C24.2,17.1,24.5,16.7,25,16.7z M17.8,16.7l6.4-7v5.5
                    c-1,0.3-1.7,1.3-1.7,2.3c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5c0-1.1-0.7-2-1.7-2.3V9.7l6.4,7l-5.1,6.6h-4.2L17.8,16.7z M25,43.5
                    l-0.8-1.1h1.7L25,43.5z M30.4,38.2l-3.2,2.5h-4.4l-3.2-2.5c0.8-1.2,1.2-2.6,1.2-4.1c0-0.1,0-0.2,0-0.2l-1.5-5.3l3.6-3.6h4.3l3.6,3.6
                    l-1.5,5.3c0,0.1,0,0.2,0,0.2C29.1,35.6,29.6,37,30.4,38.2z"
									></path>
									<path
										class="st0"
										d="M47.4,5.1c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-1.1,0-2,0.7-2.3,1.7H27.5V0.9c0-0.5-0.4-0.8-0.8-0.8h-3.3
                    c-0.5,0-0.8,0.4-0.8,0.8v0.8H4.9c-0.3-1-1.3-1.7-2.3-1.7c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5c1.1,0,2-0.7,2.3-1.7h11.5
                    C8.6,6.6,2.9,13.9,1.9,22.5h-1c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                    c0-0.5-0.4-0.8-0.8-0.8H3.6c1.1-9.9,9-17.8,18.9-18.9v0.7c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8V3.6
                    c9.9,1.1,17.8,9,18.9,18.9h-0.7c-0.5,0-0.8,0.4-0.8,0.8v3.3c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8v-3.3
                    c0-0.5-0.4-0.8-0.8-0.8h-1c-0.9-8.7-6.7-15.9-14.5-19.1h11.5C45.4,4.4,46.3,5.1,47.4,5.1z M47.4,1.8c0.5,0,0.8,0.4,0.8,0.8
                    s-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8S46.9,1.8,47.4,1.8z M2.6,3.4c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8
                    S3.1,3.4,2.6,3.4z M3.4,25.8H1.8v-1.7h1.7V25.8z M25.8,3.4h-1.7V1.8h1.7V3.4z M46.6,24.2h1.7v1.7h-1.7V24.2z"
									></path>
									<rect x="24.2" y="27.5" class="st0" width="1.7" height="1.7"></rect>
									<rect x="24.2" y="30.8" class="st0" width="1.7" height="1.7"></rect>
									<rect x="24.2" y="34.1" class="st0" width="1.7" height="1.7"></rect>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="one">
								<h3>Highly Skilled Game Artists</h3>
								<p>
									Our game designers and graphics designers are vector artists besides the pixel
									artists, so scalability of in-game graphics and animations are reputable within
									the iOS game designer community.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<rect x="24.8" y="10.2" class="st0" width="1.6" height="1.6"></rect>
									<rect x="24.8" y="7.1" class="st0" width="1.6" height="1.6"></rect>
									<rect x="24.8" y="14.8" class="st0" width="1.6" height="1.6"></rect>
									<rect x="24.8" y="17.9" class="st0" width="1.6" height="1.6"></rect>
									<rect x="16.2" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="13.1" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="19.4" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="22.5" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="27.1" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="30.2" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="33.3" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<rect x="36.4" y="12.5" class="st0" width="1.6" height="1.6"></rect>
									<path
										class="st0"
										d="M45,34.4V15.5c0.9-0.3,1.6-1.2,1.6-2.2c0-1.3-1-2.3-2.3-2.3c-0.6,0-1.1,0.2-1.5,0.6L27.9,2.8c0-0.1,0-0.3,0-0.4
                    c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3c0,0.1,0,0.3,0,0.4L8.4,11.5c-0.4-0.3-0.9-0.6-1.5-0.6c-1.3,0-2.3,1-2.3,2.3
                    c0,1,0.7,1.9,1.6,2.2v18.9c-0.9,0.3-1.6,1.2-1.6,2.2c0,1.3,1,2.3,2.3,2.3c0.6,0,1.1-0.2,1.5-0.6L23.3,47c0,0.1,0,0.3,0,0.4
                    c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3c0-0.1,0-0.3,0-0.4l14.9-8.7c0.4,0.3,0.9,0.6,1.5,0.6c1.3,0,2.3-1,2.3-2.3
                    C46.5,35.6,45.9,34.7,45,34.4z M41.9,36.6c0,0.1,0,0.3,0,0.4l-3.2,1.8v-6l4.7-2.7v4.2C42.5,34.7,41.9,35.6,41.9,36.6z M7.7,34.4
                    V15.5c0.3-0.1,0.5-0.2,0.7-0.4l14.9,8.7c0,0.1,0,0.3,0,0.4c0,1,0.7,1.9,1.6,2.2v18.9c-0.3,0.1-0.5,0.2-0.7,0.4L9.2,37
                    c0-0.1,0-0.3,0-0.4C9.3,35.6,8.6,34.7,7.7,34.4z M43.4,28.4l-4.7,2.7v-6l4.7-2.7V28.4z M27.9,24.2c0-0.1,0-0.3,0-0.4l3.2-1.8v6
                    l-4.7,2.7v-4.2C27.2,26,27.9,25.2,27.9,24.2z M37.2,18.3v6l-4.7,2.7v-6L37.2,18.3z M24.8,24.2c0-0.4,0.3-0.8,0.8-0.8
                    c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8C25.1,24.9,24.8,24.6,24.8,24.2z M26.3,32.4l4.7-2.7v6l-4.7,2.7V32.4z M32.6,28.7l4.7-2.7v6
                    l-4.7,2.7V28.7z M43.4,20.6l-4.7,2.7v-6l3.9-2.3c0.2,0.2,0.5,0.3,0.7,0.4V20.6z M41.9,13.7l-14.9,8.7c-0.4-0.3-0.9-0.6-1.5-0.6
                    c-0.6,0-1.1,0.2-1.5,0.6L9.2,13.7c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4l14.9-8.7c0.4,0.3,0.9,0.6,1.5,0.6c0.6,0,1.1-0.2,1.5-0.6
                    l14.9,8.7c0,0.1,0,0.3,0,0.4C41.9,13.4,41.9,13.6,41.9,13.7z M26.3,40.1l4.7-2.7v6l-3.9,2.3c-0.2-0.2-0.5-0.3-0.7-0.4V40.1z
                    M32.6,36.5l4.7-2.7v6l-4.7,2.7V36.5z M44.2,12.5c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8
                    C43.4,12.9,43.8,12.5,44.2,12.5z M25.6,1.6c0.4,0,0.8,0.3,0.8,0.8S26,3.2,25.6,3.2c-0.4,0-0.8-0.3-0.8-0.8S25.1,1.6,25.6,1.6z
                    M6.9,12.5c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8C6.1,12.9,6.5,12.5,6.9,12.5z M6.9,37.4
                    c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8S7.4,37.4,6.9,37.4z M25.6,48.2c-0.4,0-0.8-0.3-0.8-0.8
                    c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8C26.3,47.9,26,48.2,25.6,48.2z M44.2,37.4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8
                    c0.4,0,0.8,0.3,0.8,0.8S44.6,37.4,44.2,37.4z"
									></path>
									<path
										class="st0"
										d="M23.2,26.1l-14-7.9v16.5l14,7.9V26.1z M21.7,39.9l-10.9-6.1V20.8L21.7,27V39.9z"
									></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="two">
								<h3>Awesome 3D Modelling</h3>
								<p>
									Our superiority in Unity iOS Game Development empowers our iOS game programmers to
									produce stunning 2D &amp; 3D models, 2D &amp; 3D animations, and rendering
									stunning 3D effects on all iOS devices.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<g>
										<circle class="st0" cx="29.1" cy="13.3" r="0.7"></circle>
										<path
											class="st0"
											d="M25.8,19.2c-1.1,0-2-0.9-2-2h-1.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3h-1.3C27.8,18.3,26.9,19.2,25.8,19.2z
                      "
										></path>
										<path
											class="st0"
											d="M37.8,26.2l-1.5-0.5v-2.6c0-1.1-0.9-2-2-2h-3c0.6-0.8,1.1-1.7,1.4-2.7c1.6-0.2,2.9-1.6,2.9-3.3V10
                      c0-5.4-4.4-9.9-9.9-9.9s-9.9,4.4-9.9,9.9v5.3c0,1.7,1.3,3.1,2.9,3.3c0.3,1,0.7,1.9,1.4,2.7h-3c-1.1,0-2,0.9-2,2v2.6l-1.5,0.5
                      c-1.9,0.6-3.2,2.4-3.2,4.4v10.3H41V30.6C41,28.6,39.7,26.8,37.8,26.2z M17.9,37c-0.7,0-1.3-0.6-1.3-1.3H35c0,0.7-0.6,1.3-1.3,1.3
                      H17.9z M27.8,38.3c-0.3,0.4-0.5,0.8-0.6,1.3h-2.8c-0.1-0.5-0.3-0.9-0.6-1.3H27.8z M32.4,34.3H19.2v-9.2h13.2V34.3z M33,17.1
                      c0-0.2,0-0.4,0-0.5v-3.2c0.8,0.3,1.3,1,1.3,1.9C34.4,16.1,33.8,16.8,33,17.1z M25.8,1.4c4.7,0,8.6,3.8,8.6,8.6v2.7
                      C34,12.3,33.5,12.1,33,12c-0.2-1.8-0.9-3.6-2-5.1l-0.4-0.6L28,8.6c-2.3,2.1-5.1,3.4-8.1,3.9V10h-1.3v2c-0.5,0.1-0.9,0.3-1.3,0.6V10
                      C17.3,5.2,21.1,1.4,25.8,1.4z M17.3,15.2c0-0.9,0.6-1.6,1.3-1.9v3.2c0,0.2,0,0.4,0,0.5C17.8,16.8,17.3,16.1,17.3,15.2z M19.9,16.5
                      v-2.7c0.7-0.1,1.3-0.2,2-0.4c0.1,0.3,0.3,0.5,0.6,0.5c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.3c2.1-0.8,4.1-1.9,5.8-3.4l1.4-1.2
                      c0.9,1.5,1.4,3.2,1.4,4.9v3.3c0,1.8-0.8,3.5-2.2,4.6h-7.4C20.7,20,19.9,18.4,19.9,16.5z M17.3,22.5h17.1c0.4,0,0.7,0.3,0.7,0.7
                      v11.2h-1.3V23.8H17.9v10.5h-1.3V23.1C16.6,22.8,16.9,22.5,17.3,22.5z M12,30.6c0-1.4,0.9-2.7,2.3-3.1l1-0.3v8.5
                      c0,1.5,1.2,2.6,2.6,2.6h3.3c0.9,0,1.6,0.6,1.9,1.3H12V30.6z M39.6,39.6H28.6c0.3-0.8,1-1.3,1.9-1.3h3.3c1.5,0,2.6-1.2,2.6-2.6v-8.5
                      l1,0.3c1.3,0.4,2.3,1.7,2.3,3.1V39.6z"
										></path>
										<polygon
											class="st0"
											points="24,31.5 22.4,29.7 24,28 23,27.3 20.8,29.7 23,32.1  "
										></polygon>
										<polygon
											class="st0"
											points="29.2,32.1 31.5,29.7 29.2,27.3 28.2,28 29.9,29.7 28.2,31.5  "
										></polygon>
										<polygon class="st0" points="24.2,32.1 26.1,27 27.4,27.3 25.4,32.4  "></polygon>
									</g>
									<g>
										<path
											class="st0"
											d="M24.4,43.5h-0.3v-0.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v0.3h-0.3c-0.7,0-1.3,0.6-1.3,1.3
                      c0,0.7,0.6,1.3,1.3,1.3h0.3v0.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-0.3h0.3c0.7,0,1.3-0.6,1.3-1.3
                      C25.7,44.1,25.1,43.5,24.4,43.5z M21.3,45.1c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3h1.3v-1.3c0-0.1,0.1-0.3,0.3-0.3
                      c0.1,0,0.3,0.1,0.3,0.3v1.3h1.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3h-1.3v1.3c0,0.1-0.1,0.3-0.3,0.3
                      c-0.1,0-0.3-0.1-0.3-0.3v-1.3H21.3z"
										></path>
										<path
											class="st0"
											d="M31.7,39.7H19.9c-1.3,0-2.3,1-2.3,2.3v5.6c0,1.3,1,2.3,2.3,2.3h11.8c1.3,0,2.3-1,2.3-2.3V42
                      C34,40.8,33,39.7,31.7,39.7z M33,42v5.6c0,0.7-0.6,1.3-1.3,1.3H19.9c-0.7,0-1.3-0.6-1.3-1.3V42c0-0.7,0.6-1.3,1.3-1.3h11.8
                      C32.4,40.8,33,41.3,33,42z"
										></path>
										<path
											class="st0"
											d="M30.2,43.5c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3c0,0.1,0,0.2,0,0.3
                      c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c0,0.7,0.6,1.3,1.3,1.3
                      s1.3-0.6,1.3-1.3c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0c0.7,0,1.3-0.6,1.3-1.3S31,43.5,30.2,43.5z M27.4,44.8c0,0.2-0.1,0.3-0.3,0.3
                      c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3C27.3,44.5,27.4,44.6,27.4,44.8z M28.7,46.1c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
                      s-0.3-0.1-0.3-0.3S28.5,46.1,28.7,46.1z M28.7,43.5c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3
                      C29,43.4,28.8,43.5,28.7,43.5z M29,45.1c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0.2,0,0.4,0,0.6,0
                      c0,0.1,0,0.2,0,0.3C28.9,44.9,28.9,45,29,45.1z M30.5,44.8c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
                      C30.4,44.5,30.5,44.6,30.5,44.8z"
										></path>
									</g>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="three">
								<h3>Proficient iOS Game Developers</h3>
								<p>
									Our familiarity with the latest iOS game programming best practices, Cocoa Touch,
									Touch Organizer, and Cut Scenes Scripting Engine, and databases are well known in
									the iOS game development industry.
								</p>
							</div>
						</div>
						<div class="game-why-us-inner-box d-flex align-items-start">
							<div class="game-why-us-inner-icon box-shadow">
								<svg
									x="0px"
									y="0px"
									viewBox="0 0 50 50"
									style="enable-background: new 0 0 50 50"
									xml:space="preserve"
								>
									<path
										class="st0"
										d="M38.4,24.9h-0.7c-0.1-0.4-0.3-0.8-0.5-1.1l0.6-0.6c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2l-0.7-0.7
                    c-0.1-0.1-0.2-0.1-0.3,0l-0.6,0.6c-0.3-0.2-0.6-0.3-1-0.4v-0.7c0-0.1-0.1-0.2-0.2-0.2h-1c-0.1,0-0.2,0.1-0.2,0.2v0.7
                    c-0.4,0.1-0.7,0.2-1,0.4l-0.6-0.6c-0.1-0.1-0.2-0.1-0.3,0l-0.7,0.7c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0.1,0.2l0.6,0.6
                    c-0.2,0.3-0.4,0.7-0.5,1.1h-0.7c-0.1,0-0.2,0.1-0.2,0.2v1c0,0.1,0.1,0.2,0.2,0.2h0.7c0.1,0.4,0.2,0.7,0.4,1l-0.5,0.5
                    c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.1,0.1,0.2l0.7,0.7c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l0.5-0.5c0.4,0.2,0.7,0.4,1.1,0.5v0.6
                    c0,0.1,0.1,0.2,0.2,0.2h1c0.1,0,0.2-0.1,0.2-0.2v-0.6c0.4-0.1,0.8-0.3,1.1-0.5l0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
                    l0.7-0.7c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1-0.1-0.2l-0.5-0.5c0.2-0.3,0.3-0.7,0.4-1h0.7c0.1,0,0.2-0.1,0.2-0.2v-1
                    C38.6,25,38.5,24.9,38.4,24.9z M34.5,27.7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C36.5,26.8,35.6,27.7,34.5,27.7z"
									></path>
									<circle class="st0" cx="25.5" cy="45.6" r="1.7"></circle>
									<path
										class="st0"
										d="M22.5,3.6c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h6c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.6-0.7H22.5z"
									></path>
									<path
										class="st0"
										d="M44.2,18.2h-4.6V4.2c0-2.3-1.9-4.2-4.2-4.2H15.7c-2.3,0-4.2,1.9-4.2,4.2v41.6c0,2.3,1.9,4.2,4.2,4.2h19.7
                    c2.3,0,4.2-1.9,4.2-4.2V32.9h4.5c0.9,0,1.7-0.8,1.7-1.8V20C45.8,19,45.1,18.2,44.2,18.2z M13,4.2c0-1.4,1.2-2.6,2.6-2.6h19.7
                    c1.4,0,2.6,1.2,2.6,2.6v2.3H13C13,6.6,13,4.2,13,4.2z M37.9,45.8c0,1.4-1.2,2.6-2.6,2.6H15.7c-1.4,0-2.6-1.2-2.6-2.6v-2.9H38
                    L37.9,45.8L37.9,45.8z M37.9,41.3H13V8.2h24.9v10H24.8c-0.9,0-1.7,0.8-1.7,1.8v11.1c0,1,0.8,1.8,1.7,1.8h2l0.8,4.7l4.6-4.7h5.7V41.3
                    z M44.4,31.1c0,0.1-0.1,0.2-0.3,0.2H31.6l-3.2,3.2l-0.6-3.2h-3.1c-0.2,0-0.3-0.1-0.3-0.2V20c0-0.1,0.1-0.2,0.3-0.2h19.3
                    c0.2,0,0.3,0.1,0.3,0.2V31.1z"
									></path>
								</svg>
							</div>
							<div class="game-why-us-inner-content" id="four">
								<h3>Indispensable Support to Clients</h3>
								<p>
									Support and maintenance services through emergency updates, and upgrade efforts by
									our renowned iOS game developers ensure you’re in safe hands once your game is
									live.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6 col-sm-12 mb-4 mb-lg-0">
					<div style="width: 100%; display: inline-block">
						<img
							class="lazy d-block ml-auto mr-auto"
							alt=""
							src="../../../assets/images/why-choose-us-game.webp"
							style=""
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.web-why-go-icon {
	min-width: 47px;
	max-width: 47px;
	min-height: 47px;
	max-height: 47px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}
.web-why-go-icon svg {
	display: block;
	width: 24px;
	height: 24px;
	fill: black;
}
.game-why-us-inner-box {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border-radius: 20px;
	padding: 10px;
	margin: 5px 0;
}
.game-why-us-inner-box:hover h3,
.game-why-us-inner-box:hover p{
	color:#fff !important
}
.game-why-us-inner-box:hover {
	background: #008dd2;
}


.game-why-us-inner-box:hover .game-why-us-inner-icon svg circle,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg ellipse,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg path,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg polygon,
.game-why-us-inner-box:hover .game-why-us-inner-icon svg rect {
	fill:#008dd2
}

.web-why-go-icon svg path {
	fill: black;
}
.featchers-list {
	padding: 0;
}
.featchers-list li {
	display: flex;
	gap: 20px;
}


.game-why-us-inner-icon svg circle,
.game-why-us-inner-icon svg ellipse,
.game-why-us-inner-icon svg path,
.game-why-us-inner-icon svg polygon,
.game-why-us-inner-icon svg rect {
	fill:black
}

.game-why-us-inner-icon svg {
	width: 50px;
	height: 50px;
	fill:black
}
.game-why-us-inner-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 90px;
	min-height: 90px;
	border-radius: 100%;
	background-color: #fff;
	border: 1px solid #e0e2e4;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.game-why-us-inner-content h3 {
	font-size: 20px;
	font-weight: 500;
}
.game-why-us-inner-content p {
	font-size: 14px !important;
}
.game-why-us-inner-content {
	width: calc(100% - 110px);
	margin-left: 20px;
}
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
