<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/cms-main.jpg"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Choosing an ideal CMS Development Services
					</h2>
					<p>
						With content being the kind of information market, managing it efficiently is a step
						towards driving business growth. A progressive CMS web development service is a
						catalogue-enriched system with features blended to benefit your business. The content
						team thrives to capitalise performance into a brand and it is only possible with having
						the right information at the right time and in right quantity. For this to happen, it is
						recommended to approach CMS development company with content management demands to level
						the growth platform. This will have a team of programmers putting their experience to
						best use tailored to requirements.
					</p>
					<p>
						Leverage open source CMS solutions by hiring a dedicated CMS development team for faster
						turnaround and quick implementation. Be it WordPress, Drupal, JOOMLA, or any other
						framework, we are well versed in implementing enterprise content management too. CMS
						development services offer customisation at every step with the full ability to manage,
						update, add, remove, or even modify the content based on content strategy.
					</p>
					<p>
						Try integrating best of available multiple CMS features bundled as a single solution
						that ensures user-friendly content utilisation.
					</p>
					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
