<template>
	<section class="padding-60 bg-light car-wash-feature-main">
		<div class="container">
			<div class="title">
				<span>Sets of Features</span>
				<h2 class="fxt-tech-use">The Main Features Of Our Taxi App Development Services</h2>
			</div>
			<ul class="nav nav-tabs car-wash-feature-tab" id="technologies_tabs" role="tablist">
				<li>
					<a
						class="active"
						id="mobile-technologies-tab"
						data-toggle="tab"
						href="#mobile-technologies"
						role="tab"
						aria-controls="mobile-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M29.9,17.6c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C28.9,17.1,29.3,17.6,29.9,17.6z M20.1,15.6
                    c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C21.1,16.1,20.7,15.6,20.1,15.6z M28,23c-0.2-0.5-0.8-0.7-1.3-0.5
                    c-0.5,0.3-1.1,0.4-1.7,0.4c-0.6,0-1.2-0.1-1.8-0.4c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3c0.8,0.4,1.7,0.6,2.6,0.6
                    c0.9,0,1.8-0.2,2.6-0.6C28,24.1,28.3,23.5,28,23z M35.2,29.8l-2.7-2.4c3.3-2.4,5.2-6.2,5.2-10.3v-6.4C37.7,4.8,32.9,0,27,0h-3.9
                    c-5.9,0-10.7,4.8-10.7,10.7v6.4c0,4.1,1.9,7.9,5.2,10.2l-2.7,2.4C9.6,30,3.5,34.2,3.5,39.6V49c0,0.5,0.4,1,1,1h41.1
                    c0.5,0,1-0.4,1-1v-9.4C46.5,34.3,40.4,30,35.2,29.8z M33.6,31.1l-3.2,7.2l-4-2l4.7-7.5L33.6,31.1z M25,37.8l3,1.5l-0.8,2.3h-4.5
                    L22,39.3L25,37.8z M25,34.8l-3.4-5.4c1.1,0.3,2.2,0.5,3.4,0.5c1.1,0,2.3-0.2,3.4-0.5L25,34.8z M14.3,10.7c0-4.8,3.9-8.8,8.7-8.8
                    H27c4.9,0,8.8,3.9,8.8,8.8v0.8l-1.8-2.6c-0.2-0.2-0.4-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3c-2,2-4.3,2.9-7.4,2.9
                    c-3.2,0-5.5-0.9-7.4-2.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.2-0.7,0.4l-1.7,2.6V10.7z M14.3,17.2v-2.3l2.7-4
                    c2.2,1.8,4.7,2.7,8,2.7c3.2,0,5.8-0.9,8-2.7l2.8,4v2.2C35.8,23,31,27.9,25,27.9C19,27.9,14.3,23.1,14.3,17.2z M18.9,28.8l4.7,7.5
                    l-4,2l-3.2-7.2L18.9,28.8z M19.8,48H5.4v-8.4c0-4,4.9-7.5,9.1-7.8l3.6,8.2c0.1,0.2,0.3,0.4,0.6,0.5s0.5,0.1,0.8,0l0.7-0.3l0.8,2.5
                    L19.8,48z M21.8,48l1.1-4.5h4.3l1,4.5H21.8z M44.5,48H30.2l-1.2-5.4l0.8-2.5l0.6,0.3c0.2,0.1,0.5,0.1,0.8,0
                    c0.3-0.1,0.5-0.3,0.6-0.5l3.6-8.2c4.3,0.3,9.1,3.8,9.1,7.8V48z M37.7,43.6h-3.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h3.9
                    c0.5,0,1-0.4,1-1C38.6,44,38.2,43.6,37.7,43.6z M40.5,44.5c0,0.5,0.4,1,1,1s1-0.4,1-1c0-0.5-0.4-1-1-1S40.5,44,40.5,44.5z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>User</span>
					</a>
				</li>
				<li>
					<a
						id="web-technologies-tab"
						data-toggle="tab"
						href="#web-technologies"
						role="tab"
						aria-controls="web-technologies"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<g>
										<path
											d="M25.8,24.1h-1.7c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h1.7c0.5,0,0.8-0.4,0.8-0.8C26.7,24.5,26.3,24.1,25.8,24.1z
                    "
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M26.7,27.5h-3.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h3.3c0.5,0,0.8-0.4,0.8-0.8S27.1,27.5,26.7,27.5z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M22.5,18.3h-3.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8H20v0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8V20
                    h0.8c0.5,0,0.8-0.4,0.8-0.8C23.3,18.7,23,18.3,22.5,18.3z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M30.8,18.3h-3.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8h0.8v0.8c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8V20h0.8
                    c0.5,0,0.8-0.4,0.8-0.8C31.7,18.7,31.3,18.3,30.8,18.3z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M45.1,46.7L44,43.2c-0.5-1.4-1.3-2.6-2.3-3.6C41,39,40.2,38.5,39.5,38l-0.1-0.1c-0.3-0.2-0.7-0.4-1-0.5
                    c-1.2-0.5-2.5-0.8-3.8-0.8l-2.7-2.7c0-0.7,0-1.5,0.1-2.2c0.1-0.9,0.3-1.8,0.4-2.6c1-1.1,1.7-2.6,1.8-4.1c0.1,0,0.2,0,0.3,0.1
                    c0.2,0,0.3,0,0.4-0.1c0.4-0.1,1.7-0.6,1.7-5.8c0-0.6,0-2.3-1.1-2.8c-0.2-0.1-0.4-0.1-0.6-0.1c0.1-0.9,0.1-1.9-0.2-2.8
                    c2.3-1.5,3.6-3.4,3.6-5.4c0-4.5-5.9-8-13.3-8S11.7,3.5,11.7,8c0,2.1,1.3,4,3.6,5.5c-0.2,0.9-0.3,1.8-0.1,2.8c-0.2,0-0.4,0-0.6,0.1
                    c-1.1,0.5-1.1,2.2-1.1,2.8c0,5.2,1.3,5.7,1.7,5.8c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3-0.1c0.1,1.5,0.8,3,1.8,4.1
                    c0.2,0.9,0.3,1.7,0.4,2.6c0.1,0.7,0.1,1.5,0.1,2.2l-2.7,2.7c-1.3,0-2.6,0.3-3.8,0.8c-0.4,0.1-0.7,0.3-1,0.5L10.5,38
                    c-0.8,0.5-1.5,1-2.2,1.6c-1,1-1.8,2.3-2.3,3.7l-1.1,3.4c-0.4,1.3,0.3,2.7,1.6,3.1C6.8,49.9,7,50,7.3,50h16.6c0.1,0,0.2,0,0.3,0
                    h1.7c0.1,0,0.2,0,0.2,0h16.6c1.4,0,2.5-1.1,2.5-2.5C45.2,47.2,45.2,46.9,45.1,46.7z M32.9,37.4l-1.3,1.2c-1-0.6-2-1.1-3.1-1.4
                    l2.3-1.9L32.9,37.4z M26.5,36.8c-1-0.1-2-0.1-2.9,0l-3.6-3.1c0-0.7-0.1-1.5-0.1-2.2c0-0.1,0-0.2,0-0.2c0.5,0.5,1,0.9,1.6,1.4
                    c2.1,1.7,5.2,1.7,7.3,0c0.6-0.5,1.1-1,1.6-1.4c0,0.1,0,0.2,0,0.2c-0.1,0.7-0.1,1.5-0.1,2.2L26.5,36.8z M34.8,17.9
                    c0.1,0.4,0.2,0.8,0.2,1.2c0,2.6-0.4,3.8-0.6,4.1c-0.1-0.1-0.3-0.2-0.4-0.4c0-1.6,0.1-3.2,0.5-4.8C34.6,18,34.7,17.9,34.8,17.9z
                    M13.3,8c0-3.4,5.3-6.3,11.7-6.3S36.7,4.6,36.7,8c0,1-0.4,1.9-1.1,2.6c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.1-0.4,0-0.6
                    c0-0.1-0.1-0.2-0.2-0.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.4-0.1-0.8,0.1-0.9,0.5
                    c-0.1,0.2-0.1,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.3c0.3,0.3,0.9,0.3,1.2,0c-0.4,0.4-0.8,0.8-1.2,1.1c-0.1,0-0.1,0-0.2,0H15.8
                    c-0.1,0-0.1,0-0.2,0C14.1,10.6,13.3,9.3,13.3,8z M32.7,13.3c-1.2,0.9-4,1.7-7.7,1.7c-3.7,0-6.5-0.8-7.7-1.7H32.7z M15.9,22.9
                    c-0.1,0.1-0.2,0.3-0.4,0.4c-0.2-0.4-0.6-1.5-0.6-4.1c0-0.4,0-0.8,0.2-1.2c0.1,0,0.2,0.1,0.3,0.2C15.8,19.6,16,21.3,15.9,22.9z
                    M17.5,24.9c0-0.2,0-0.4,0-0.5c0.2-2.5,0-5.1-0.6-7.5l0,0c-0.1-0.5-0.2-1.1-0.2-1.7c0-0.1,0-0.2,0-0.3c1.8,1.1,4.8,1.8,8.3,1.8
                    c3.5,0,6.5-0.7,8.3-1.8c0,0.1,0,0.2,0,0.3c0,0.6-0.1,1.1-0.2,1.6c-0.7,2.4-0.9,5-0.6,7.5c0,0.2,0,0.4,0,0.5c0,1.6-1.7,3.9-4.9,6.5
                    c-1.5,1.2-3.7,1.2-5.2,0C19.2,28.8,17.5,26.5,17.5,24.9z M19.2,35.3l2.3,1.9c-1.1,0.3-2.1,0.8-3.1,1.4l-1.3-1.2L19.2,35.3z
                    M7.3,48.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.2-0.5-0.1-0.7l1.1-3.4c0.4-1.1,1.1-2.2,2-3c0.1-0.1,0.3-0.2,1.9-1.4
                    c0.3-0.1,0.5-0.3,0.8-0.4c1-0.4,2.1-0.6,3.3-0.6l1.5,1.3c-0.3,0.2-0.6,0.5-0.8,0.8c-2.1,2.1-3.4,5-3.6,8L7.3,48.3L7.3,48.3z
                    M17.6,48.3c0.3-2.4,1.7-4.5,3.8-5.7c0.2-0.1,0.4-0.2,0.6-0.3l0,0l1.2,6H17.6z M26.9,48.3l1.2-6c0.2,0.1,0.4,0.2,0.6,0.3
                    c2.1,1.2,3.5,3.3,3.8,5.7H26.9z M34.1,48.3c-0.3-3-2-5.7-4.7-7.2c-0.6-0.3-1.2-0.6-1.8-0.7c0,0,0,0,0,0c0,0,0,0,0,0
                    c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
                    c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0l-1.4,7.3h-0.3L23.4,41c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
                    c0-0.1,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
                    c0,0,0,0,0,0c0,0,0,0,0,0c-0.6,0.2-1.2,0.4-1.8,0.7c-2.6,1.5-4.4,4.1-4.7,7.2h-1.7c0.2-2.6,1.3-5,3.1-6.8c0.5-0.5,0.9-0.9,1.5-1.2
                    c3.7-2.6,8.7-2.6,12.4,0c0.5,0.4,1,0.8,1.5,1.2c1.8,1.8,2.9,4.2,3.1,6.8L34.1,48.3L34.1,48.3z M43.4,48c-0.2,0.2-0.4,0.3-0.7,0.3
                    h-5.3c-0.2-3-1.5-5.8-3.6-8c-0.3-0.3-0.6-0.5-0.8-0.8l1.5-1.3c1.1,0,2.2,0.2,3.3,0.6c0.3,0.1,0.6,0.2,0.8,0.4
                    c1.7,1.2,1.9,1.4,1.9,1.4c0.8,0.8,1.5,1.9,1.9,3l1.1,3.4C43.6,47.5,43.6,47.7,43.4,48z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M17.3,7.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.1-0.6,0c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.2-0.2,0.4-0.2,0.6
                    c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.1,0.7,0.1,0.9-0.2c0.1-0.1,0.1-0.2,0.2-0.3
                    c0-0.1,0.1-0.2,0.1-0.3C17.5,8.1,17.4,7.9,17.3,7.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M20.6,7.7c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1c-0.3-0.1-0.6,0-0.7,0.2c-0.2,0.2-0.2,0.4-0.2,0.6
                    c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0c0.5,0,0.8-0.4,0.8-0.8C20.8,8.1,20.7,7.9,20.6,7.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M27.3,7.7c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1c-0.3-0.1-0.6,0-0.7,0.2c-0.2,0.2-0.2,0.4-0.2,0.6
                    c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.9,0,1.1-0.4c0-0.1,0.1-0.2,0.1-0.3C27.5,8.1,27.4,7.9,27.3,7.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M23.3,7.5c-0.2,0-0.4,0.1-0.6,0.2c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0
                    C24.2,7.9,23.8,7.5,23.3,7.5z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M30.6,7.7c-0.2-0.2-0.5-0.3-0.8-0.2c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1
                    c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2c0.2-0.2,0.2-0.4,0.2-0.6
                    C30.8,8.1,30.7,7.9,30.6,7.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M33.3,7.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6C32.8,9,32.9,9.1,33,9.1
                    c0.1,0,0.2,0.1,0.3,0.1c0.5,0,0.8-0.4,0.8-0.8c0,0,0,0,0,0C34.2,7.9,33.8,7.5,33.3,7.5z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M15.6,9.4c-0.2-0.2-0.5-0.3-0.8-0.2c-0.1,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1
                    c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.5,0.4,0.8,0.8,0.8c0,0,0,0,0,0c0.5,0,0.8-0.4,0.8-0.8
                    C15.8,9.8,15.7,9.6,15.6,9.4z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M19.1,9.7c0-0.1-0.1-0.2-0.2-0.3c-0.3-0.3-0.8-0.3-1.2,0c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.3
                    c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6C19.2,9.9,19.1,9.8,19.1,9.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M22.3,9.4c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2-0.1c-0.3-0.1-0.6,0-0.7,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
                    c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6C22.5,9.8,22.4,9.6,22.3,9.4z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M25.8,9.7c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.7-0.1-0.9,0.2c-0.1,0.1-0.1,0.2-0.2,0.3
                    c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.8,0.3,1.2,0C25.8,10.4,25.9,10,25.8,9.7z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M28.3,9.2c-0.5,0-0.8,0.4-0.8,0.8c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6
                    C29.2,9.5,28.8,9.2,28.3,9.2z"
										></path>
									</g>
								</g>
								<g>
									<g>
										<path
											d="M31.7,9.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.9,0.3,1.2,0
                    c0.2-0.2,0.2-0.4,0.2-0.6C32.5,9.5,32.1,9.2,31.7,9.2z"
										></path>
									</g>
								</g>
							</svg>
						</div>
						<span>Driver</span>
					</a>
				</li>
				<li>
					<a
						id="cloud-tab"
						data-toggle="tab"
						href="#cloud"
						role="tab"
						aria-controls="cloud"
						aria-selected="true"
					>
						<div
							class="car-wash-feature-tab-icon box-shadow d-flex align-items-center justify-content-center"
						>
							<svg
								x="0px"
								y="0px"
								viewBox="0 0 50 50"
								style="enable-background: new 0 0 50 50"
								xml:space="preserve"
							>
								<g>
									<path
										d="M16.1,21.8h-1.6c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2h-1.6c0,0.9-0.7,1.6-1.6,1.6S16.1,22.7,16.1,21.8z"
									></path>
									<circle cx="13.7" cy="16.9" r="0.8"></circle>
									<circle cx="21.8" cy="16.9" r="0.8"></circle>
									<path
										d="M50,15.8l-6.9-6.9H29.8C29.4,7,28.1,5.5,26.3,5c-2.1-3.1-5.6-5-9.4-5C10.7,0,5.6,5.1,5.6,11.3v9.1c0,3-1.7,5.8-4.4,7.1
                  l-0.7,0.4l0.4,0.7c0.1,0.1,1.1,2.1,3.9,3.1L3.9,32C1.6,32.8,0,34.9,0,37.4V50h35.5v-7.3H50V15.8z M43.5,11.6l3.7,3.7h-3.7V11.6z
                  M19.3,13.3c-0.2-0.5-0.3-1-0.3-1.6c1.8,1.2,3.8,2.1,6,2.6v5.8c0,4-3.3,7.3-7.3,7.3s-7.3-3.3-7.3-7.3V12c1.9,1.2,4.1,1.9,6.3,2.3
                  l3.2,0.5L19.3,13.3z M17.7,29c1.5,0,2.8-0.4,4-1v1.4l-4,3.4l-4-3.4v-1.4C14.9,28.7,16.3,29,17.7,29z M12.7,30.7l3.8,3.2l-4.2,4.2
                  l-2.5-6.4L12.7,30.7z M16.8,40.3h1.9l1.3,8.1h-4.5L16.8,40.3z M18.9,38.7h-2.2L15.8,37l2-2l2,2L18.9,38.7z M18.9,33.9l3.8-3.2
                  l2.9,1l-2.5,6.4L18.9,33.9z M23.4,29.3V27c1.4-1.1,2.4-2.7,2.9-4.5c0.8-0.1,1.5-0.4,2-0.9c0.4,2.8,2,5.3,4.4,6.8
                  c-0.7,0.8-2.3,2.1-5.2,2.1L23.4,29.3z M26.6,20.2v-3.9c0.9,0.3,1.6,1.2,1.6,2.3c0,1.1-0.7,2-1.6,2.3C26.6,20.6,26.6,20.4,26.6,20.2
                  z M17,1.6c3.3,0,6.3,1.6,8.1,4.3l0.3,0.4l0.3,0.1c1.5,0.4,2.6,1.8,2.6,3.3v5.6c-0.5-0.4-1-0.6-1.6-0.7v-1.5l-0.7-0.1
                  c-2.5-0.5-4.8-1.6-6.9-3.1l-0.8-0.6l-0.4,0.9c-0.4,0.8-0.5,1.7-0.4,2.6L17,12.7c-2.5-0.4-4.8-1.3-6.8-2.8l-1.3-1v5.7
                  c-0.6,0.1-1.1,0.4-1.6,0.7v-4C7.3,6,11.6,1.6,17,1.6z M8.9,20.8c-1-0.3-1.6-1.2-1.6-2.3c0-1.1,0.7-1.9,1.6-2.3v3.9
                  C8.9,20.4,8.9,20.6,8.9,20.8z M2.8,28.5c2.4-1.5,4-4,4.4-6.8c0.6,0.5,1.3,0.8,2,0.9c0.5,1.8,1.5,3.3,2.9,4.5v2.3L8,30.6
                  C5,30.6,3.5,29.3,2.8,28.5z M1.6,37.4c0-1.7,1.1-3.3,2.8-3.8l3.9-1.3l3.5,8.7l2.8-2.8l0.7,1.5l-1.5,8.7H8.1v-6.5H6.5v6.5H1.6V37.4z
                  M33.9,48.4H29v-6.5h-1.6v6.5h-5.8l-1.5-8.7l0.7-1.5l2.8,2.8l3.5-8.7l3.9,1.3c1.6,0.6,2.8,2.1,2.8,3.8V48.4z M35.5,41.1v-3.8
                  c0-0.7-0.1-1.3-0.3-1.9h11.6v-1.6h-1.6v-5.6h-1.6v5.6h-1.6v-7.3h-1.6v7.3h-1.6v-4h-1.6v4h-2.8c-0.7-0.8-1.6-1.5-2.6-1.9l-0.9-0.3
                  c2.8-1,3.8-3,3.9-3.1l0.4-0.7l-0.7-0.4c-2.7-1.4-4.4-4.1-4.4-7.1v-9.9h12.1v6.5h6.5v24.2H35.5z"
									></path>
									<rect x="45.2" y="18.5" width="1.6" height="1.6"></rect>
									<rect x="31.5" y="18.5" width="12.1" height="1.6"></rect>
									<rect x="31.5" y="21.8" width="15.3" height="1.6"></rect>
								</g>
							</svg>
						</div>
						<span>Admin</span>
					</a>
				</li>
			</ul>
			<div class="tab-content" id="technologies_tabs_Content">
				<div
					class="tab-pane fade show active fxt-tab-pane"
					id="mobile-technologies"
					role="tabpanel"
					aria-labelledby="mobile-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items1"
						v-bind:className="'user'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="web-technologies"
					role="tabpanel"
					aria-labelledby="web-technologies-tab"
				>
					<RoundedCardContainer
						v-bind:items="items2"
						v-bind:className="'driver'"
					></RoundedCardContainer>
				</div>
				<div
					class="tab-pane fade fxt-tab-pane"
					id="cloud"
					role="tabpanel"
					aria-labelledby="cloud-tab"
				>
					<RoundedCardContainer
						v-bind:items="items3"
						v-bind:className="'admin'"
					></RoundedCardContainer>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import RoundedCardContainer from '../AssetComponents/RoundedCardContainer.vue';
export default {
	components: {
		RoundedCardContainer
	},
	data() {
		return {
			items1: [
				{
					title: 'Passenger Profile',
					text: 'Every user or passenger get to create their own profile complete with their personal contact details.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path d="M36.6,32.5c0,0-5.8,0.2-6.7-6c1-0.7,1.8-1.5,2.6-2.6c1.5-2,2.5-4.6,2.8-7.3c0.1-0.1,0.2-0.2,0.3-0.4
                        c0.6-1.5,0.9-3.1,0.9-4.7C36.6,5.1,31.8,0,25.9,0c-1.5,0-3,0.4-4.4,1c-0.5,0-1.1,0.1-1.6,0.3c-2.4,0.7-4.4,2.5-5.5,5
                        c-1.1,2.5-1.3,5.4-0.6,8.2c0.2,0.6,0.3,1.2,0.6,1.7c0.1,0.2,0.2,0.3,0.3,0.4c0.6,4.3,2.6,7.9,5.4,9.8c-0.9,6.2-6.6,6-6.7,6
                        C9.3,32.6,6,36,6,40.2v8.7C6,49.5,6.5,50,7.1,50H17h16h9.9c0.6,0,1.1-0.5,1.1-1.1v-8.7C44,36,40.7,32.6,36.6,32.5z M20.6,3.5
                        c0.4-0.1,0.8-0.2,1.3-0.2c0.2,0,0.3-0.1,0.5-0.1c1.1-0.6,2.3-0.9,3.6-0.9c4.6,0,8.4,4.1,8.4,9.2c0,0.2,0,0.3,0,0.5
                        c-0.9-0.9-2.1-1.5-3.5-1.5h-7.7c-0.5,0-0.9-0.1-1.3-0.4c-0.3-0.2-0.6-0.5-0.7-0.9c-0.2-0.6-0.8-0.9-1.4-0.9c-0.6,0-1.1,0.5-1.3,1
                        c-0.4,1.7-1.3,3.3-2.5,4.6C14.9,9.2,16.9,4.6,20.6,3.5z M19.6,23c-1.5-1.8-2.4-4.2-2.7-6.9c1.4-1.3,2.5-2.8,3.3-4.6
                        c0.1,0.1,0.2,0.2,0.3,0.3c0.8,0.6,1.7,0.9,2.6,0.9h7.7c1.1,0,2,0.7,2.4,1.6c0,0,0,0,0,0c0,6.4-3.7,11.5-8.2,11.5
                        C23,25.8,21.1,24.8,19.6,23z M22.2,27.6c0.9,0.3,1.8,0.5,2.8,0.5c1,0,1.9-0.2,2.8-0.5c0.4,2.1,1.6,4,3.3,5.3l-5.9,8.4
                        c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1l-5.9-8.4C20.6,31.6,21.8,29.7,22.2,27.6z M41.8,47.7H33H17H8.2v-7.6
                        c0-3,2.5-5.5,5.5-5.5c0,0,1.9,0,3.3-0.7l6,8.6c0.5,0.7,1.2,1,2,1c0,0,0,0,0,0c0.8,0,1.5-0.4,2-1l6-8.6c1.6,0.7,3.2,0.7,3.3,0.7
                        c3,0,5.5,2.5,5.5,5.5V47.7L41.8,47.7z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Ride Now',
					text: 'Users can book a cab for immediate use from their real-time location with the ride now option.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M41.8,19.7l-2.3-0.1l-4.2-4.8c-2-2.3-4.8-3.6-7.8-3.7V8.5c0-0.5-0.4-0.9-0.8-0.9h-9.2c-0.5,0-0.8,0.4-0.8,0.9v2.8
                    c-1.5,0.2-3,0.8-4.2,1.7c-1.6,1-2.7,2.6-3.3,4.4l-0.9,2.3H6.3c-3.2,0-5.9,2.5-6.3,5.9c0,0.1,0,0.1,0,0.2v8.6c0,0.5,0.4,0.9,0.8,0.9
                    h5c0,2,0.8,3.9,2.3,5.2H0V42h50v-1.7h-6.4c1.4-1.3,2.3-3.2,2.3-5.2h3.3c0.5,0,0.8-0.4,0.8-0.9v-6.1C50,23.5,46.3,19.7,41.8,19.7z
                    M47.5,24.8h-0.9c-1.7,0-3.2-1.4-3.2-3.2C45.1,22,46.6,23.2,47.5,24.8z M18.3,9.3h7.5v1.7h-7.5V9.3z M4.2,26.5v1.7H1.7v-1.7H4.2z
                    M7.5,35.1c0-2.8,2.2-5.2,5-5.2s5,2.3,5,5.2s-2.2,5.2-5,5.2C9.7,40.3,7.5,38,7.5,35.1z M16.9,40.3c1.4-1.3,2.3-3.2,2.3-5.2h13.3
                    c0,2,0.8,3.9,2.3,5.2H16.9z M39.2,40.3c-2.8,0-5-2.3-5-5.2s2.2-5.2,5-5.2s5,2.3,5,5.2C44.2,38,41.9,40.3,39.2,40.3z M48.3,33.4h-2.7
                    c-0.8-3-3.4-5.2-6.4-5.2c-3,0-5.7,2.1-6.4,5.2H18.9c-0.8-3-3.4-5.2-6.4-5.2s-5.7,2.1-6.4,5.2H1.7V30H5c0.5,0,0.8-0.4,0.8-0.9v-3.4
                    c0-0.5-0.4-0.9-0.8-0.9H1.9c0.6-2,2.4-3.4,4.4-3.4h2.5c0.3,0,0.6-0.2,0.8-0.5l1.1-2.8c0.5-1.5,1.4-2.7,2.7-3.6c1.4-1,3-1.5,4.7-1.5
                    l9.1-0.1h0.1c2.6,0,5.1,1.1,6.8,3.1l4.4,5c0.1,0.2,0.4,0.3,0.6,0.3l2.6,0.1v0.1c0,2.8,2.2,5,4.9,5h1.6c0.1,0.5,0.2,1.1,0.2,1.6V33.4
                    z"></path>
                    <path d="M25,21.4h7.4c0.7,0,1.4-0.4,1.7-1.1c0.3-0.6,0.2-1.4-0.3-1.9c-2-2.4-3.9-3.8-7.1-3.8H25c-0.5,0-0.8,0.4-0.8,0.9v5.2
                    C24.2,21,24.5,21.4,25,21.4z M25.8,16.2h0.8c2.6,0,4.1,1.1,5.9,3.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1h-6.6V16.2z"></path>
                    <path d="M12.5,21.4H20c0.5,0,0.8-0.4,0.8-0.9v-5.2c0-0.5-0.4-0.9-0.8-0.9h-3.2c-2.8,0-5.1,2.4-5.1,5.3v0.8
                    C11.7,21,12,21.4,12.5,21.4z M14.3,17.3c0.6-0.7,1.5-1,2.4-1h2.4v3.4h-5.8C13.4,18.8,13.7,17.9,14.3,17.3z"></path>
                    <path d="M12.5,31.7c-1.8,0-3.3,1.5-3.3,3.4s1.5,3.4,3.3,3.4s3.3-1.5,3.3-3.4S14.3,31.7,12.5,31.7z M12.5,36.8
                    c-0.9,0-1.7-0.8-1.7-1.7s0.7-1.7,1.7-1.7s1.7,0.8,1.7,1.7S13.4,36.8,12.5,36.8z"></path>
                    <path d="M39.2,31.7c-1.8,0-3.3,1.5-3.3,3.4s1.5,3.4,3.3,3.4s3.3-1.5,3.3-3.4S41,31.7,39.2,31.7z M39.2,36.8c-0.9,0-1.7-0.8-1.7-1.7
                    s0.7-1.7,1.7-1.7s1.7,0.8,1.7,1.7S40.1,36.8,39.2,36.8z"></path>
                    <path d="M9.2,22.2h1.7V24H9.2V22.2z"></path>
                    <path d="M10.8,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M12.5,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M14.2,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M15.8,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M17.5,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M19.2,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M20.8,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M22.5,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M24.2,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M25.8,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M27.5,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M29.2,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M30.8,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M32.5,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M34.2,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M35.8,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M37.5,24h1.7v1.7h-1.7V24z"></path>
                    <path d="M39.2,22.2h1.7V24h-1.7V22.2z"></path>
                    <path d="M7.5,24h1.7v1.7H7.5V24z"></path>
                    <path d="M5.8,22.2h1.7V24H5.8V22.2z"></path>
                  </svg>
					`
				},
				{
					title: 'Schedule Ride',
					text: 'For those unearthly cab rides, users can book a cab for a later time that is most convenient for them.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M41.4,6c0-1.4-1.2-2.6-2.6-2.6h-2.6c0-1.4-1.2-2.6-2.6-2.6S31,2,31,3.4h-3.4c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6
                      H19c0-1.4-1.2-2.6-2.6-2.6c-1.4,0-2.6,1.2-2.6,2.6h-3.4c0-1.4-1.2-2.6-2.6-2.6S5.2,2,5.2,3.4H2.6C1.2,3.4,0,4.6,0,6v36.2
                      c0,0.5,0.4,0.9,0.9,0.9h2.6v2.6c0,0.5,0.4,0.9,0.9,0.9H27c0,0,0,0,0,0c6.7,4.6,15.8,2.9,20.4-3.7s2.9-15.8-3.7-20.4
                      c-0.7-0.5-1.5-0.9-2.3-1.3V6z M32.8,3.4c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9V6h-1.7V3.4z M24.1,3.4c0-0.5,0.4-0.9,0.9-0.9
                      s0.9,0.4,0.9,0.9V6h-1.7V3.4z M15.5,3.4c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9V6h-1.7V3.4z M6.9,3.4c0-0.5,0.4-0.9,0.9-0.9
                      S8.6,3,8.6,3.4V6H6.9V3.4z M1.7,6c0-0.5,0.4-0.9,0.9-0.9h2.6v1.7c0,0.5,0.4,0.9,0.9,0.9h3.4c0.5,0,0.9-0.4,0.9-0.9V5.2h3.4v1.7
                      c0,0.5,0.4,0.9,0.9,0.9h3.4c0.5,0,0.9-0.4,0.9-0.9V5.2h3.4v1.7c0,0.5,0.4,0.9,0.9,0.9h3.4c0.5,0,0.9-0.4,0.9-0.9V5.2H31v1.7
                      c0,0.5,0.4,0.9,0.9,0.9h3.4c0.5,0,0.9-0.4,0.9-0.9V5.2h2.6c0.5,0,0.9,0.4,0.9,0.9v4.3H1.7V6z M5.2,43.1h18.3
                      c0.4,0.6,0.9,1.2,1.5,1.7H5.2V43.1z M48.3,34.5c0,7.1-5.8,12.9-12.9,12.9s-12.9-5.8-12.9-12.9s5.8-12.9,12.9-12.9
                      C42.5,21.6,48.3,27.3,48.3,34.5z M35.3,19.8c-8.1,0-14.6,6.5-14.7,14.6c0,2.4,0.6,4.8,1.7,6.9H1.7V12.1h37.9v8.4
                      C38.3,20,36.8,19.8,35.3,19.8z"></path>
                      <path d="M7.8,15.5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6S9.2,15.5,7.8,15.5z M7.8,19c-0.5,0-0.9-0.4-0.9-0.9
                      s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S8.2,19,7.8,19z"></path>
                      <path d="M16.4,15.5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6S17.8,15.5,16.4,15.5z M16.4,19
                      c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9S16.9,19,16.4,19z"></path>
                      <path d="M25,15.5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6S26.4,15.5,25,15.5z M25,19c-0.5,0-0.9-0.4-0.9-0.9
                      s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S25.5,19,25,19z"></path>
                      <path d="M7.8,22.4c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C10.3,23.6,9.2,22.4,7.8,22.4z M7.8,25.9
                      c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S8.2,25.9,7.8,25.9z"></path>
                      <path d="M16.4,22.4c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6C19,23.6,17.8,22.4,16.4,22.4z M16.4,25.9
                      c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9S16.9,25.9,16.4,25.9z"></path>
                      <path d="M7.8,29.3c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6C10.3,30.5,9.2,29.3,7.8,29.3z M7.8,32.8
                      c-0.5,0-0.9-0.4-0.9-0.9S7.3,31,7.8,31s0.9,0.4,0.9,0.9S8.2,32.8,7.8,32.8z"></path>
                      <path d="M16.4,29.3c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6C19,30.5,17.8,29.3,16.4,29.3z M16.4,32.8
                      c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9S16.9,32.8,16.4,32.8z"></path>
                      <path d="M24.1,34.5c0,6.2,5,11.2,11.2,11.2s11.2-5,11.2-11.2s-5-11.2-11.2-11.2C29.2,23.3,24.1,28.3,24.1,34.5z M44.8,34.5h-1.7
                      c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9h1.6c-0.8,4.2-4.2,7.3-8.5,7.7v-1.7c0-0.5-0.4-0.9-0.9-0.9s-0.9,0.4-0.9,0.9v1.7
                      c-4.2-0.4-7.7-3.5-8.5-7.7h1.6c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-1.7c0-4.9,3.7-9,8.6-9.4v1.7c0,0.5,0.4,0.9,0.9,0.9
                      s0.9-0.4,0.9-0.9V25C41.1,25.5,44.8,29.6,44.8,34.5z"></path>
                      <path d="M35.5,34.1l-1.2-1.8c-0.3-0.4-0.8-0.5-1.2-0.2c-0.4,0.3-0.5,0.8-0.2,1.2l1.7,2.6c0.1,0.2,0.4,0.3,0.6,0.4c0,0,0.1,0,0.1,0
                      c0.2,0,0.4-0.1,0.6-0.2l5.2-4.3c0.4-0.3,0.4-0.8,0.1-1.2c-0.3-0.4-0.8-0.4-1.2-0.1L35.5,34.1z"></path>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Book For Others',
					text: 'Users can book cabs using the app for someone else and send the ride details to them directly from the app.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M12.1,0H27c1.1,0,2,0.4,2.7,1.1c0.7,0.7,1.1,1.6,1.1,2.6v16.6c1.9,0.7,2.8,2.3,3.7,3.8c0.6,1,1.2,2.1,2.1,2.6
                    c0.9,0.5,1.7,1.2,2.3,1.9c0.6,0.8,1,1.8,1,3c-0.2,4.3,0.2,6.2,0.8,9.6c0.2,1.1,0.4,2.3,0.7,3.8c0.3,0.9,0,1.7-0.7,2.4
                    c-0.5,0.5-1.3,1-2.3,1.4c-0.9,0.4-1.9,0.7-2.9,0.9c-2.9,0.6-6,0.5-6.9-0.8c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.7l-0.3-0.7
                    c-1.1-0.9-2.2-1.8-3.2-2.9c-1-1-1.8-2.1-2.4-3.3c-1-0.1-1.5,0-2.4,0.1c-0.4,0.1-0.9,0.1-1.3,0.2c-1.4,0.2-2.4-0.5-2.9-1.5
                    c-0.2-0.4-0.4-0.9-0.4-1.3c0-0.5,0-1,0.2-1.4l0-0.1c0-0.1,0.1-0.2,0.1-0.3h-3.6c-1.1,0-2-0.4-2.7-1.1c-0.7-0.7-1.1-1.6-1.1-2.6V3.7
                    c0-1,0.4-2,1.1-2.6C10,0.4,11,0,12.1,0z M30.8,30.5l1.9,1.8c0.4,0.4,0.4,1.1,0,1.5c-0.4,0.4-1.1,0.4-1.5,0L29,31.6
                    c-1.1-0.9-2.3-1.8-3.6-2.8c-1.3-1-2.8-2-4.2-3c-0.7-0.5-1.3-0.5-1.8-0.3c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.6
                    c-0.1,0.5,0,1.1,0.5,1.6c1.3,1.3,2.5,2.5,3.6,3.7c1,1.1,1.9,2.3,2.8,3.7c0.2,0.1,0.3,0.4,0.4,0.6c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
                    c0.2,1.4,0.4,2.5,0.8,3.4c0.5,1,1.2,1.9,2.2,2.9c0.4,0.4,0.4,1,0,1.3c-0.4,0.4-1,0.4-1.4,0c-1.2-1.2-2.1-2.2-2.6-3.4
                    c-0.5-1-0.7-2-0.9-3.3l-6,0c-0.4,0-0.7,0.2-0.8,0.4l0,0.1c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.3,0.5,0.6,1,0.5
                    c0.6-0.1,0.9-0.1,1.2-0.2c1.3-0.2,2-0.3,3.6,0c0.3,0.1,0.6,0.3,0.7,0.5c0.5,1.2,1.4,2.2,2.3,3.2c1,1,2.1,1.9,3.2,2.8
                    c0.2,0.1,0.3,0.2,0.3,0.4l0.4,0.9l0.2,0.6c0.5,0.5,2.6,0.4,4.8-0.1c0.9-0.2,1.8-0.5,2.5-0.8c0.7-0.3,1.3-0.6,1.6-1
                    c0.2-0.2,0.3-0.3,0.2-0.4c0-0.1-0.1-0.2-0.1-0.2c-0.3-1.5-0.5-2.8-0.7-3.9c-0.6-3.5-1-5.6-0.9-10c0-0.7-0.2-1.3-0.6-1.7
                    c-0.4-0.5-1.1-1-1.8-1.5c-1.4-0.8-2.1-2-2.8-3.3c-0.6-1-1.1-1.9-2-2.6V30.5z M28.9,29.1V21V3.7c0-0.5-0.2-1-0.6-1.3
                    C28,2.1,27.5,1.9,27,1.9H12.1c-0.5,0-1,0.2-1.3,0.5c-0.3,0.3-0.6,0.8-0.6,1.3v29.4c0,0.5,0.2,1,0.6,1.3c0.3,0.3,0.8,0.5,1.3,0.5
                    h10.8c-0.5-0.7-1-1.3-1.6-2l0,0c-0.5,0.4-1.1,0.6-1.7,0.6c-0.7,0-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.1-0.8-1.9c0-0.7,0.3-1.3,0.7-1.8
                    c-0.8-0.9-1.1-2.1-0.8-3.1c0.1-0.5,0.4-1,0.7-1.4c0.3-0.4,0.8-0.7,1.3-0.9c1-0.4,2.3-0.4,3.6,0.5c1.5,1,2.9,2,4.3,3
                    C27.4,27.9,28.1,28.5,28.9,29.1z M20.4,32.1c-0.6-0.6-1.2-1.3-1.9-2c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.2,0.7,0.4,1
                    c0.3,0.2,0.6,0.4,1,0.4C19.9,32.4,20.2,32.2,20.4,32.1z M15.1,16.5c-0.3,0-0.6,0.3-0.6,0.6l0,0c0,0.3,0.3,0.6,0.6,0.6h1.3
                    c0.3,0,0.6-0.3,0.6-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6H15.1z M22.6,16.5c-0.3,0-0.6,0.3-0.6,0.6l0,0c0,0.3,0.3,0.6,0.6,0.6H24
                    c0.3,0,0.6-0.3,0.6-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6H22.6z M16.4,14h6.3c0.2,0,0.4,0,0.6,0.1l-0.5-1.2c-0.1-0.1-0.2-0.3-0.3-0.4
                    c-0.2-0.1-0.3-0.2-0.5-0.2c-0.7-0.1-1.5-0.2-2.3-0.2c-0.8,0-1.6,0.1-2.5,0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
                    L15.8,14C16,14,16.2,14,16.4,14z M13.8,15.3L12.7,15c-0.1,0-0.2-0.1-0.2-0.3l0.2-0.9c0-0.1,0.1-0.2,0.2-0.2l1.5,0.1
                    c0.1,0,0.1,0,0.2,0.1l0,0l0.6-1.5c0.1-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.4,1.1-0.4c1-0.1,1.9-0.2,2.7-0.2c0.9,0,1.7,0.1,2.5,0.2
                    c0.4,0.1,0.7,0.2,1,0.5c0.3,0.2,0.5,0.5,0.7,0.9l0.6,1.5l0,0c0-0.1,0.1-0.1,0.2-0.1l1.5-0.1c0.1,0,0.2,0.1,0.2,0.2l0.2,0.9
                    c0,0.1-0.1,0.2-0.2,0.3l-1.1,0.2c0.4,0.5,0.6,1.1,0.6,1.8c0,0.5-0.1,1-0.3,1.4v2.3c0,0.3-0.2,0.5-0.5,0.5h-0.9
                    c-0.3,0-0.5-0.2-0.5-0.5v-0.7c-0.3,0.1-0.6,0.1-1,0.1h-6.3c-0.3,0-0.7-0.1-1-0.2v0.7c0,0.3-0.2,0.5-0.5,0.5H14
                    c-0.3,0-0.5-0.2-0.5-0.5v-2.3c-0.2-0.4-0.3-0.9-0.3-1.4C13.2,16.4,13.4,15.8,13.8,15.3z"></path>
                  </svg>
					`
				},
				{
					title: 'Vehicle Selection',
					text: 'Users can choose a cab of their that best fits their time, size, and ride distance requirements.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M17.9,41.4h-4.2c-0.9,0-1.7,0.7-1.7,1.6v0.4c0,0.9,0.7,1.6,1.7,1.6h4.2c0.9,0,1.7-0.7,1.7-1.6v-0.4
                      C19.5,42.2,18.8,41.4,17.9,41.4z M17.8,43.4h-4.1v-0.3h4.1V43.4z"></path>
                      <path d="M15.8,14.6c-4.6,0-8.4,3.7-8.4,8.3c0,4.6,3.8,8.3,8.4,8.3c4.6,0,8.4-3.7,8.4-8.3C24.2,18.3,20.4,14.6,15.8,14.6z
                      M15.8,16.2c2.5,0,4.6,1.3,5.8,3.3h-5v3.3H15v-3.3h-5C11.2,17.6,13.3,16.2,15.8,16.2z M19.9,21.2v1.6h-1.6v-1.6H19.9z M16.6,24.5
                      v1.6H15v-1.6H16.6z M13.2,21.2v1.6h-1.6v-1.6H13.2z M15.8,29.6c-3.7,0-6.7-3-6.7-6.7c0-1.2,0.3-2.3,0.8-3.2v4.9h3.3v3.3h5.1v-3.3
                      h3.3v-4.9c0.5,1,0.8,2,0.8,3.2C22.5,26.6,19.5,29.6,15.8,29.6z"></path>
                      <path d="M46.8,34.1c-0.2-1.2-0.6-4.8-0.7-5.7c-0.3-2.1-2.5-2.6-3.7-2.6h-2v-2h-7v2h-2V5.6c0-2.2-1.8-4-4.1-4H4.1
                      C1.8,1.5,0,3.4,0,5.6v38.2c0,2.2,1.8,4,4.1,4h23.4c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0h1.9c1.2,0,2.3-1,2.3-2.2v-2.1h9.8v2.1
                      c0,1.2,1,2.2,2.3,2.2h1.9c1.2,0,2.3-1,2.3-2.2v-2.1H50v-6C50,36,47.9,34.7,46.8,34.1z M35.2,25.5h3.6v0.3h-3.6V25.5z M4.1,46.2
                      c-1.3,0-2.4-1.1-2.4-2.3V5.6c0-1.3,1.1-2.3,2.4-2.3h23.4c1.3,0,2.4,1.1,2.4,2.3V26c-0.9,0.3-1.8,1-2,2.3c0,0.4-0.1,1.1-0.2,1.9V5.4
                      H3.9v35h20v3.1h1.7v2.1c0,0.2,0,0.4,0.1,0.5H4.1z M25.9,34.8c-1,0.7-1.9,1.7-1.9,2.7v1.2H5.7V7.1h20.2L25.9,34.8L25.9,34.8z
                      M29.9,46.2h-1.9c-0.1,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.3-0.4-0.5v-2.1h2.5h0.6v2.1h0C30.4,45.9,30.2,46.2,29.9,46.2z M46.6,45.6
                      c0,0.3-0.2,0.5-0.5,0.5h-1.9c-0.3,0-0.6-0.3-0.6-0.5v-2.1h3V45.6z M48.3,41.9h-6.4h-2.2v-0.6v-0.6v-0.2v-0.6v-0.6V39v-0.6v-0.8
                      h-5.4v0.8V39v0.2v0.6v0.6v0.2v0.6v0.6h-2.2h-0.6h-1.7h-4.2v-1.5v-1.7v-1.2c0-0.1,0.1-0.3,0.2-0.4c0.3-0.4,1-0.9,1.7-1.4
                      c0.2-0.1,0.5-0.3,0.7-0.4l0.4-0.2l0.1-0.4c0,0,0.6-5,0.8-6.1c0-0.2,0.1-0.4,0.3-0.6c0.6-0.5,1.6-0.5,1.7-0.5h0.1h2h7h2
                      c0,0,1.8,0,2,1.1c0.1,1.2,0.8,6.1,0.8,6.1l0.1,0.4l0.4,0.2c1.4,0.7,2.6,1.8,2.6,2.2V41.9z"></path>
                      <path d="M28.2,37.4c-0.2,0-0.4,0-0.6,0.1c-0.9,0.2-1.6,1.1-1.6,2c0,0.3,0.1,0.6,0.2,0.9c0.3,0.7,1.1,1.2,1.9,1.2
                      c0.7,0,1.3-0.3,1.7-0.8c0.3-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.3C29.5,37.7,28.8,37.4,28.2,37.4z M28.2,39.9
                      c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4C28.6,39.8,28.4,39.9,28.2,39.9z"></path>
                      <path d="M45.8,37.4c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1C48,38.3,47,37.4,45.8,37.4z M45.8,39.9
                      c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4C46.2,39.8,46.1,39.9,45.8,39.9z"></path>
                      <path d="M43.5,28h-12h-1.1l-0.6,3.6l-0.4,2.2h0.4h1.7h13L43.5,28z M31.5,32.2L31.5,32.2v-0.4l0.3-2.1h10.2l0.4,2.5H31.5z"></path>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Real-Time ETA',
					text: 'The app can highlight the real-time ETA of the ride to the user’s location accurately.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M25.2,0C25.2,0,25.2,0,25.2,0C20,0,15.1,2,11.4,5.7c-3.7,3.7-5.8,8.6-5.8,13.8c0,4.6,1.5,9,4.4,12.5c0,0,0,0,0,0l12.5,16
                    h-8.3c-0.5,0-1,0.4-1,1s0.4,1,1,1h22.1c0.5,0,1-0.4,1-1s-0.4-1-1-1H28l3.5-4.5c0.3-0.4,0.3-1-0.2-1.4c-0.4-0.3-1-0.3-1.4,0.2
                    l-4.3,5.5C25.5,48,25.3,48,25.2,48s-0.3,0-0.5-0.2L11.5,30.9c0,0,0,0,0-0.1c-2.6-3.2-4-7.2-4-11.3C7.6,9.8,15.5,2,25.2,2
                    C35,2,42.9,9.9,42.9,19.6c0,4.1-1.4,8.1-4,11.3c0,0-0.1,0.1-0.1,0.1l-4.1,5.2c-0.3,0.4-0.3,1,0.2,1.4c0.4,0.3,1,0.3,1.4-0.2l4.2-5.3
                    c0,0,0-0.1,0.1-0.1c2.8-3.5,4.4-7.9,4.4-12.4C44.9,8.8,36,0,25.2,0z"></path>
                    <path d="M32.4,39.2c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
                    c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3S32.6,39,32.4,39.2z"></path>
                    <path d="M38.5,19.6c0-7.4-6-13.3-13.3-13.3c-7.4,0-13.3,6-13.3,13.3c0,7.4,6,13.3,13.3,13.3S38.5,27,38.5,19.6z M25.2,31
                    c-6.3,0-11.4-5.1-11.4-11.4c0-6.3,5.1-11.4,11.4-11.4s11.4,5.1,11.4,11.4S31.5,31,25.2,31z"></path>
                    <path d="M34.6,16.5c0-0.5-0.4-1-1-1h-1.3c-0.3,0-0.5,0.1-0.7,0.3l-0.1,0.1l-0.5-2.2c-0.3-1.3-1.4-2.2-2.8-2.2h-6.2
                    c-1.3,0-2.5,0.9-2.8,2.2l-0.5,2.2l-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.3h-1.3c-0.5,0-1,0.4-1,1s0.4,1,1,1h0.8c-0.6,0.5-1,1.2-1,2.1v3.9
                    c0,0.5,0.4,1,1,1h1.8v0.9c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-0.9h7.7v0.9c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-0.9h1.8c0.5,0,1-0.4,1-1
                    v-3.9c0-0.8-0.4-1.6-1-2.1h0.8C34.2,17.5,34.6,17.1,34.6,16.5z M22.1,13.5h6.2c0.4,0,0.8,0.3,0.9,0.7l0.7,2.7h-9.2l0.7-2.7
                    C21.3,13.8,21.7,13.5,22.1,13.5z M31.9,22.5H18.6v-3c0-0.4,0.3-0.8,0.8-0.8h11.7c0,0,0,0,0,0c0,0,0,0,0,0c0.4,0,0.8,0.3,0.8,0.8
                    L31.9,22.5L31.9,22.5z"></path>
                    <path d="M20.7,19.7c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
                    c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C21.2,19.8,20.9,19.7,20.7,19.7z"></path>
                    <path d="M29.8,19.7c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
                    c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7C30.3,19.8,30,19.7,29.8,19.7z"></path>
                    <path d="M10.3,48c-0.3,0-0.5,0.1-0.7,0.3S9.3,48.8,9.3,49s0.1,0.5,0.3,0.7S10,50,10.3,50s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7
                    s-0.1-0.5-0.3-0.7S10.6,48,10.3,48z"></path>
                  </svg>
					`
				},
				{
					title: 'Fare Estimates',
					text: 'Inform users an estimated fare they have to pay for the ride once they reach their selected destination.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M7.3,38.7h4.8c0.4,0,0.8-0.4,0.8-0.8v-3.2c0-0.4-0.4-0.8-0.8-0.8H7.3c-0.4,0-0.8,0.4-0.8,0.8v3.2
                      C6.5,38.3,6.8,38.7,7.3,38.7z M8.1,35.5h3.2v1.6H8.1V35.5z"></path>
                      <path d="M29.8,38.7h4.8c0.4,0,0.8-0.4,0.8-0.8v-3.2c0-0.4-0.4-0.8-0.8-0.8h-4.8c-0.4,0-0.8,0.4-0.8,0.8v3.2
                      C29,38.3,29.4,38.7,29.8,38.7z M30.6,35.5h3.2v1.6h-3.2V35.5z"></path>
                      <path d="M50,11.3C50,5.1,44.9,0,38.7,0S27.4,5.1,27.4,11.3c0,2.3,0.7,4.6,2,6.5h-2.8v-2.4c0-0.4-0.4-0.8-0.8-0.8h-9.7
                      c-0.4,0-0.8,0.4-0.8,0.8v2.4h-5.2c-1.1,0-2.1,0.8-2.3,1.8l-1.2,4.6H3.2c-0.4,0-0.8,0.4-0.8,0.8v3.2c0,0.4,0.4,0.8,0.8,0.8h2.2
                      L5,30.6H4c-1.3,0-2.4,1.1-2.4,2.4v7.3H0.8c-0.4,0-0.8,0.4-0.8,0.8v3.2c0,0.4,0.4,0.8,0.8,0.8h2.4v2.4c0,1.3,1.1,2.4,2.4,2.4h3.2
                      c1.3,0,2.4-1.1,2.4-2.4v-2.4h20.2v2.4c0,1.3,1.1,2.4,2.4,2.4h3.2c1.3,0,2.4-1.1,2.4-2.4v-2.4h1.6c0.4,0,0.8-0.4,0.8-0.8v-3.2
                      c0-0.4-0.4-0.8-0.8-0.8h-0.8v-7.3c0-1.3-1.1-2.4-2.4-2.4h-1L36.5,29h2.2c0.4,0,0.8-0.4,0.8-0.8V25c0-0.4-0.4-0.8-0.8-0.8h-3.4
                      l-1.2-4.6c-0.3-1.1-1.2-1.8-2.3-1.8h-0.3c-1.4-1.6-2.3-3.5-2.4-5.6h1.6v-1.6h-1.6c0.4-4.7,4.1-8.4,8.8-8.8v1.6h1.6V1.7
                      c4.7,0.4,8.4,4.1,8.8,8.8h-1.6v1.6h1.6c-0.4,4.7-4.1,8.4-8.8,8.8v-1.6h-1.6v1.6c-0.5,0-1-0.1-1.4-0.2l-0.4,1.6
                      c0.8,0.2,1.7,0.3,2.6,0.3C44.9,22.6,50,17.5,50,11.3z M16.9,16.1H25v1.6h-8.1V16.1z M4,27.4v-1.6h2.2l-0.4,1.6H4z M9.7,47.6
                      c0,0.4-0.4,0.8-0.8,0.8H5.6c-0.4,0-0.8-0.4-0.8-0.8v-2.4h4.8V47.6z M37.9,47.6c0,0.4-0.4,0.8-0.8,0.8h-3.2c-0.4,0-0.8-0.4-0.8-0.8
                      v-2.4h4.8V47.6z M40.3,43.5H1.6v-1.6h38.7V43.5z M25.8,37.1h-9.7v-1.6h9.7V37.1z M16.1,38.7h9.7v1.6h-9.7V38.7z M38.7,33.1v7.3
                      H27.4v-5.6c0-0.4-0.4-0.8-0.8-0.8H15.3c-0.4,0-0.8,0.4-0.8,0.8v5.6H3.2v-7.3c0-0.4,0.4-0.8,0.8-0.8h33.9
                      C38.3,32.3,38.7,32.6,38.7,33.1z M37.9,25.8v1.6h-1.8l-0.4-1.6H37.9z M35.3,30.6H6.7l2-8.1h24.5L35.3,30.6z M32.6,20l0.3,1H9.1
                      l0.3-1c0.1-0.4,0.4-0.6,0.8-0.6h21.7C32.2,19.4,32.5,19.6,32.6,20z"></path>
                      <rect x="9.5" y="25.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.0392 16.9184)" width="6.8" height="1.6"></rect>
                      <rect x="17.5" y="25.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.6772 22.6208)" width="6.8" height="1.6"></rect>
                      <rect x="25.6" y="25.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -10.3152 28.3232)" width="6.8" height="1.6"></rect>
                      <g>
                        <path d="M39.4,10.7H38c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h2.9c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-1.5V4.8
                        c0-0.4-0.3-0.7-0.7-0.7S38,4.4,38,4.8v1.5h0c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9h1.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5
                        h-2.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H38v1.5c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-1.5h0c1.6,0,2.9-1.3,2.9-2.9
                        S41,10.7,39.4,10.7z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Driver Tracking',
					text: 'GPS-driven vehicle tracking that highlights the accurate location and the movement of the cab towards user location.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M5.3,36.1c0,1.3,1.1,2.4,2.4,2.4s2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4S5.3,34.8,5.3,36.1z M8.4,36.1
                      c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8C8.1,35.3,8.4,35.6,8.4,36.1z"></path>
                      <path d="M33,35.3v-1.6c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4v-1.6c-0.4,0-0.8-0.4-0.8-0.8C32.2,35.6,32.5,35.3,33,35.3z"></path>
                      <path d="M14,33.7c-0.4,0-0.8,0.4-0.8,0.8v3.2c0,0.4,0.4,0.8,0.8,0.8h12.7c0.4,0,0.8-0.4,0.8-0.8v-3.2c0-0.4-0.4-0.8-0.8-0.8H14z
                      M25.8,36.9H14.8v-1.6h11.1V36.9z"></path>
                      <path d="M36.1,36.9c0.3,0,0.5-0.1,0.7-0.3l10.4-15.3c2.9-4,3.2-9.1,1-13.5c-2.3-4.5-6.9-7.2-12-7.2s-9.7,2.8-12,7.2
                      c-1.2,2.2-1.6,4.7-1.4,7h-6.4c-0.4,0-0.8,0.4-0.8,0.8v2.4h-5.1c-1.1,0-2,0.7-2.3,1.8L7,24.2H3.7c-0.4,0-0.8,0.4-0.8,0.8v3.2
                      c0,0.4,0.4,0.8,0.8,0.8h2.2l-0.4,1.6h-1c-1.3,0-2.4,1.1-2.4,2.4V40H1.3c-0.4,0-0.8,0.4-0.8,0.8V44c0,0.4,0.4,0.8,0.8,0.8h2.4v2.4
                      c0,1.3,1.1,2.4,2.4,2.4h3.2c1.3,0,2.4-1.1,2.4-2.4v-2.4h1.7c0.4,1.8,2,3.2,3.9,3.2h6.3c1.9,0,3.5-1.4,3.9-3.2H29v2.4
                      c0,1.3,1.1,2.4,2.4,2.4h3.2c1.3,0,2.4-1.1,2.4-2.4v-2.4h2.4c0.4,0,0.8-0.4,0.8-0.8v-3.2c0-0.4-0.4-0.8-0.8-0.8h-0.8v-1.6h-1.6V40
                      H3.7v-7.1c0-0.4,0.4-0.8,0.8-0.8h25.3v-1.6H7.1l2-7.9h17l1.4,2l-3.8,3.8l1.1,1.1l3.5-3.5l7.1,10.5C35.6,36.7,35.9,36.9,36.1,36.9z
                      M10,47.2c0,0.4-0.4,0.8-0.8,0.8H6.1c-0.4,0-0.8-0.4-0.8-0.8v-2.4H10V47.2z M23.5,46.4h-6.3c-1,0-1.9-0.7-2.2-1.6h10.8
                      C25.4,45.7,24.5,46.4,23.5,46.4z M35.3,47.2c0,0.4-0.4,0.8-0.8,0.8h-3.2c-0.4,0-0.8-0.4-0.8-0.8v-2.4h4.7V47.2z M38.5,43.2H2.1
                      v-1.6h36.4V43.2z M4.5,27.4v-1.6h2.2l-0.4,1.6H4.5z M25.5,8.4c2.1-4,6-6.4,10.6-6.4s8.5,2.4,10.6,6.4c2,3.9,1.7,8.3-0.9,11.9
                      l-9.7,14.4l-9.7-14.4C23.9,16.7,23.6,12.3,25.5,8.4z M17.1,16.3h5.8c0.1,0.5,0.3,1.1,0.4,1.6h-6.3V16.3z M9.7,20.1
                      c0.1-0.4,0.4-0.6,0.8-0.6h13.6c0.3,0.5,0.6,1.1,0.9,1.6H9.4L9.7,20.1z"></path>
                      <path d="M36.1,22.6c4.8,0,8.7-3.9,8.7-8.7s-3.9-8.7-8.7-8.7s-8.7,3.9-8.7,8.7S31.3,22.6,36.1,22.6z M36.1,6.8
                      c3.9,0,7.1,3.2,7.1,7.1S40,21,36.1,21S29,17.8,29,13.9S32.2,6.8,36.1,6.8z"></path>
                      <path d="M34.5,13.5l2.6,2.6c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l3.2-3.2l-1.1-1.1l-2.6,2.6l-2.6-2.6c-0.3-0.3-0.8-0.3-1.1,0
                      l-3.2,3.2l1.1,1.1L34.5,13.5z"></path>
                      <g>
                        <path d="M12.9,27c0,0.1,0.1,0.2,0.2,0.2h0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-0.3C13,26.7,12.9,26.8,12.9,27z
                        M13.5,27L13.5,27C13.5,26.9,13.5,26.9,13.5,27C13.5,26.9,13.5,26.9,13.5,27z M13.1,26.9C13,26.9,13,26.9,13.1,26.9L13.1,26.9
                        C13,26.9,13,26.9,13.1,26.9z"></path>
                        <path d="M13,27.3c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2h0.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H13z"></path>
                        <path d="M16.5,30.3l-0.2-0.5c-0.1-0.2-0.2-0.4-0.4-0.6l0,0l0,0c-0.1-0.1-0.2-0.2-0.4-0.3l0,0l0,0l0,0c-0.1,0-0.1-0.1-0.2-0.1
                        c-0.2-0.1-0.4-0.1-0.6-0.1l-0.4-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0.1-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0.1,0
                        c0.1,0,0.3-0.2,0.3-1c0-0.1,0-0.4-0.2-0.5c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c0.4-0.2,0.5-0.6,0.5-0.9c0-0.8-1-1.4-2.2-1.4
                        c-1.2,0-2.2,0.6-2.2,1.4c0,0.3,0.2,0.7,0.5,0.9c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c-0.2,0.1-0.2,0.4-0.2,0.5c0,0.8,0.2,1,0.3,1
                        c0,0,0,0,0.1,0c0,0.2,0.1,0.4,0.3,0.6c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0,0.3l-0.4,0.4c-0.2,0-0.4,0-0.6,0.1
                        c-0.1,0-0.1,0.1-0.2,0.1l0,0l0,0l0,0c-0.1,0.1-0.2,0.2-0.4,0.3l0,0l0,0c-0.2,0.2-0.3,0.4-0.4,0.6L10,30.3c0,0.1,0,0.3,0,0.4
                        c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0h2.6c0,0,0,0,0.1,0h0.3c0,0,0,0,0.1,0H16c0.3,0,0.5-0.2,0.5-0.5
                        C16.5,30.4,16.5,30.4,16.5,30.3z M11.7,26.1c0,0.2,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0,0-0.1,0-0.2C11.7,26.3,11.7,26.2,11.7,26.1z
                        M14.7,26.6C14.7,26.7,14.7,26.7,14.7,26.6C14.7,26.7,14.7,26.7,14.7,26.6L14.7,26.6z M14.8,25.6C14.8,25.6,14.8,25.6,14.8,25.6
                        C14.8,25.6,14.8,25.6,14.8,25.6C14.8,25.6,14.8,25.6,14.8,25.6z M14.6,24.7C14.6,24.7,14.7,24.8,14.6,24.7L14.6,24.7L14.6,24.7
                        l-0.1,0.1C14.5,24.7,14.5,24.7,14.6,24.7c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0C14.6,24.6,14.6,24.7,14.6,24.7z
                        M14.7,24.6C14.7,24.6,14.7,24.6,14.7,24.6C14.7,24.6,14.7,24.6,14.7,24.6z M14.8,24.4C14.8,24.4,14.8,24.4,14.8,24.4
                        c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.2-0.1l0,0h0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0l0,0h0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0
                        c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0
                        c0-0.1-0.1-0.2-0.2-0.2l0,0h0c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2l0,0l0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
                        l0,0l0,0c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
                        c0-0.5,0.8-0.9,1.7-0.9c0.9,0,1.7,0.4,1.7,0.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0C14.9,24.4,14.9,24.4,14.8,24.4
                        C14.9,24.4,14.9,24.4,14.8,24.4C14.8,24.4,14.8,24.4,14.8,24.4z M14.8,24.5C14.8,24.5,14.8,24.5,14.8,24.5
                        C14.8,24.5,14.8,24.5,14.8,24.5z M14.8,24.5C14.8,24.5,14.8,24.5,14.8,24.5C14.8,24.5,14.8,24.5,14.8,24.5z M11.8,24.6
                        C11.8,24.6,11.8,24.5,11.8,24.6C11.8,24.5,11.8,24.5,11.8,24.6C11.8,24.5,11.8,24.5,11.8,24.6C11.8,24.5,11.8,24.6,11.8,24.6
                        C11.8,24.6,11.8,24.6,11.8,24.6c0,0.1,0,0.1,0,0.1C11.8,24.7,11.8,24.6,11.8,24.6C11.8,24.6,11.8,24.6,11.8,24.6z M11.7,24.5
                        C11.7,24.5,11.7,24.5,11.7,24.5C11.7,24.5,11.6,24.5,11.7,24.5C11.6,24.5,11.6,24.5,11.7,24.5C11.6,24.5,11.6,24.5,11.7,24.5
                        L11.7,24.5C11.6,24.5,11.6,24.5,11.7,24.5C11.6,24.5,11.6,24.5,11.7,24.5C11.6,24.5,11.7,24.5,11.7,24.5
                        C11.7,24.5,11.7,24.5,11.7,24.5C11.7,24.5,11.7,24.5,11.7,24.5C11.7,24.5,11.7,24.5,11.7,24.5C11.7,24.5,11.7,24.5,11.7,24.5z
                        M12.1,24.5C12.1,24.5,12.1,24.5,12.1,24.5C12.1,24.5,12.1,24.5,12.1,24.5C12.1,24.4,12.1,24.4,12.1,24.5
                        C12.1,24.4,12.1,24.4,12.1,24.5z M12.1,24.4C12.1,24.3,12.1,24.3,12.1,24.4C12.1,24.3,12.1,24.3,12.1,24.4L12.1,24.4z M11.8,24.5
                        C11.8,24.5,11.8,24.5,11.8,24.5C11.8,24.5,11.8,24.5,11.8,24.5z M12,24.5C12,24.5,12,24.5,12,24.5C12,24.5,12,24.5,12,24.5
                        C12,24.5,12,24.5,12,24.5z M12,24.5C12,24.5,12,24.5,12,24.5C12,24.5,12,24.5,12,24.5z M12.2,24.8C12.3,24.8,12.3,24.7,12.2,24.8
                        C12.3,24.7,12.3,24.8,12.2,24.8z M12.4,24.7C12.4,24.7,12.4,24.6,12.4,24.7C12.4,24.6,12.4,24.6,12.4,24.7c0-0.1,0-0.1,0-0.1
                        c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0C12.3,24.6,12.3,24.6,12.4,24.7
                        C12.4,24.6,12.4,24.6,12.4,24.7C12.4,24.6,12.4,24.7,12.4,24.7z M12.6,24.3C12.6,24.3,12.5,24.3,12.6,24.3
                        C12.5,24.3,12.6,24.3,12.6,24.3C12.6,24.3,12.6,24.3,12.6,24.3C12.6,24.3,12.6,24.3,12.6,24.3z M12.5,24.2
                        C12.5,24.2,12.5,24.2,12.5,24.2C12.5,24.2,12.5,24.2,12.5,24.2C12.5,24.2,12.5,24.2,12.5,24.2z M12.4,24.3
                        C12.4,24.3,12.4,24.2,12.4,24.3C12.4,24.2,12.4,24.3,12.4,24.3z M13.1,24.5C13.1,24.5,13.1,24.4,13.1,24.5
                        C13.1,24.4,13.1,24.5,13.1,24.5z M13.1,24.4C13.1,24.4,13.1,24.4,13.1,24.4C13.1,24.4,13.1,24.4,13.1,24.4z M13.1,24.4
                        C13.1,24.3,13.1,24.3,13.1,24.4C13.1,24.3,13.1,24.3,13.1,24.4L13.1,24.4z M12.8,24.5C12.8,24.5,12.8,24.5,12.8,24.5
                        C12.8,24.5,12.8,24.5,12.8,24.5C12.9,24.6,12.9,24.6,12.8,24.5C12.9,24.6,12.9,24.6,12.8,24.5z M12.8,24.7
                        C12.8,24.7,12.8,24.7,12.8,24.7C12.8,24.7,12.8,24.7,12.8,24.7z M12.8,24.8C12.8,24.8,12.8,24.8,12.8,24.8
                        C12.8,24.8,12.8,24.8,12.8,24.8C12.8,24.8,12.8,24.8,12.8,24.8z M12.7,24.5C12.7,24.5,12.7,24.5,12.7,24.5
                        C12.7,24.5,12.7,24.5,12.7,24.5z M12.7,24.5C12.8,24.5,12.8,24.5,12.7,24.5C12.8,24.5,12.8,24.5,12.7,24.5
                        C12.8,24.5,12.8,24.5,12.7,24.5z M12.7,24.8C12.7,24.8,12.7,24.8,12.7,24.8C12.7,24.8,12.7,24.8,12.7,24.8z M13,24.5
                        C12.9,24.5,12.9,24.5,13,24.5C12.9,24.5,12.9,24.5,13,24.5z M13,24.5C13,24.5,13,24.5,13,24.5C13,24.5,13,24.5,13,24.5
                        C13,24.5,13,24.5,13,24.5C13,24.5,13,24.5,13,24.5C13,24.5,13,24.5,13,24.5C13,24.5,13,24.5,13,24.5z M12.9,24.3
                        C12.9,24.3,12.9,24.3,12.9,24.3C12.9,24.3,12.9,24.3,12.9,24.3z M13,24.2C13,24.2,13,24.2,13,24.2C13,24.2,13,24.2,13,24.2
                        C13,24.2,13,24.2,13,24.2C13,24.2,13,24.2,13,24.2C13,24.2,13,24.2,13,24.2z M13.1,24.5C13.1,24.5,13.1,24.5,13.1,24.5
                        C13.1,24.5,13.1,24.5,13.1,24.5z M13.2,24.8C13.2,24.8,13.2,24.8,13.2,24.8C13.2,24.8,13.2,24.8,13.2,24.8z M13.3,24.8
                        C13.3,24.8,13.3,24.8,13.3,24.8C13.3,24.8,13.3,24.8,13.3,24.8C13.3,24.8,13.3,24.8,13.3,24.8z M13.3,24.8
                        C13.3,24.7,13.4,24.7,13.3,24.8C13.4,24.7,13.3,24.7,13.3,24.8z M13.4,24.7C13.4,24.6,13.4,24.6,13.4,24.7c0-0.1,0-0.1,0-0.1
                        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C13.4,24.6,13.4,24.6,13.4,24.7C13.4,24.6,13.4,24.6,13.4,24.7z M13.2,24.5
                        C13.2,24.5,13.2,24.5,13.2,24.5C13.2,24.5,13.2,24.5,13.2,24.5z M13.3,24.5C13.3,24.5,13.3,24.5,13.3,24.5
                        C13.3,24.5,13.3,24.5,13.3,24.5C13.3,24.5,13.3,24.5,13.3,24.5z M13.9,24.7C13.9,24.7,13.9,24.7,13.9,24.7
                        C13.9,24.7,13.9,24.7,13.9,24.7z M13.8,24.8C13.8,24.8,13.8,24.8,13.8,24.8C13.8,24.8,13.8,24.8,13.8,24.8
                        C13.8,24.8,13.8,24.8,13.8,24.8z M13.8,24.8C13.8,24.8,13.8,24.8,13.8,24.8C13.8,24.8,13.8,24.8,13.8,24.8z M13.6,24.5
                        C13.6,24.4,13.6,24.4,13.6,24.5C13.6,24.4,13.6,24.4,13.6,24.5z M13.6,24.3C13.6,24.3,13.6,24.3,13.6,24.3
                        C13.6,24.3,13.6,24.3,13.6,24.3C13.6,24.3,13.6,24.3,13.6,24.3C13.6,24.3,13.6,24.3,13.6,24.3C13.7,24.3,13.7,24.3,13.6,24.3
                        C13.7,24.3,13.7,24.3,13.6,24.3z M13.5,24.3C13.5,24.3,13.5,24.2,13.5,24.3C13.5,24.2,13.5,24.3,13.5,24.3z M13.6,24.5
                        C13.6,24.5,13.6,24.5,13.6,24.5C13.5,24.5,13.5,24.5,13.6,24.5C13.6,24.5,13.6,24.5,13.6,24.5z M13.5,24.2
                        C13.5,24.2,13.5,24.2,13.5,24.2C13.5,24.2,13.6,24.2,13.5,24.2C13.6,24.2,13.5,24.2,13.5,24.2z M13.6,24.5
                        C13.6,24.5,13.6,24.5,13.6,24.5C13.6,24.5,13.6,24.5,13.6,24.5z M14.4,24.7C14.4,24.7,14.4,24.7,14.4,24.7
                        C14.4,24.7,14.4,24.7,14.4,24.7z M14.3,24.8C14.3,24.8,14.3,24.8,14.3,24.8C14.3,24.8,14.3,24.8,14.3,24.8
                        C14.3,24.8,14.3,24.8,14.3,24.8z M14.2,24.5C14.2,24.5,14.2,24.6,14.2,24.5C14.2,24.6,14.2,24.5,14.2,24.5
                        C14.2,24.5,14.3,24.5,14.2,24.5C14.3,24.5,14.2,24.5,14.2,24.5z M14.3,24.5C14.3,24.5,14.3,24.5,14.3,24.5
                        C14.3,24.5,14.3,24.5,14.3,24.5C14.3,24.5,14.3,24.5,14.3,24.5z M14.3,24.8C14.3,24.8,14.3,24.8,14.3,24.8
                        C14.3,24.8,14.3,24.8,14.3,24.8z M14.1,24.3C14.1,24.3,14.1,24.3,14.1,24.3C14.1,24.3,14.1,24.3,14.1,24.3
                        C14.2,24.3,14.2,24.3,14.1,24.3C14.2,24.3,14.2,24.3,14.1,24.3z M14,24.2C14,24.2,14,24.2,14,24.2C14,24.2,14,24.2,14,24.2
                        C14,24.2,14,24.2,14,24.2C14.1,24.2,14.1,24.2,14,24.2C14,24.2,14,24.2,14,24.2z M14,24.3C14,24.3,13.9,24.3,14,24.3
                        C13.9,24.3,14,24.3,14,24.3C14,24.3,14,24.3,14,24.3C14,24.3,14,24.3,14,24.3z M14.1,24.2C14.1,24.2,14.1,24.3,14.1,24.2
                        C14.1,24.3,14.1,24.2,14.1,24.2z M14.4,24.4C14.4,24.4,14.5,24.3,14.4,24.4C14.5,24.3,14.4,24.4,14.4,24.4L14.4,24.4z M14.7,24.3
                        C14.7,24.3,14.7,24.3,14.7,24.3L14.7,24.3C14.7,24.3,14.7,24.3,14.7,24.3z M14.7,24.4C14.7,24.4,14.7,24.4,14.7,24.4
                        C14.7,24.4,14.7,24.4,14.7,24.4z M14.7,24.4C14.7,24.4,14.7,24.5,14.7,24.4C14.7,24.5,14.7,24.4,14.7,24.4z M14.5,24.3
                        C14.5,24.3,14.5,24.3,14.5,24.3C14.5,24.3,14.5,24.3,14.5,24.3z M14.5,24.2C14.6,24.2,14.6,24.2,14.5,24.2
                        C14.6,24.2,14.6,24.2,14.5,24.2C14.6,24.2,14.6,24.2,14.5,24.2C14.6,24.2,14.6,24.2,14.5,24.2C14.6,24.2,14.6,24.2,14.5,24.2z
                        M14.6,24.5C14.6,24.5,14.6,24.5,14.6,24.5C14.6,24.5,14.6,24.5,14.6,24.5C14.6,24.5,14.6,24.5,14.6,24.5z M14.7,24.5
                        C14.6,24.5,14.6,24.5,14.7,24.5C14.6,24.5,14.6,24.5,14.7,24.5z M11.9,24.6C11.9,24.6,11.9,24.6,11.9,24.6
                        C11.9,24.6,12,24.6,11.9,24.6C12,24.6,12,24.6,11.9,24.6C12,24.6,12,24.6,11.9,24.6c0.1,0.1,0.1,0.1,0.1,0.2h-0.2
                        C11.9,24.8,11.9,24.7,11.9,24.6z M12.5,24.6C12.5,24.6,12.5,24.6,12.5,24.6C12.5,24.6,12.5,24.6,12.5,24.6
                        C12.5,24.6,12.5,24.6,12.5,24.6c0.1,0.1,0.1,0.2,0.1,0.2h-0.2C12.4,24.7,12.5,24.7,12.5,24.6C12.5,24.6,12.5,24.6,12.5,24.6z
                        M13,24.6C13,24.6,13,24.6,13,24.6C13,24.6,13,24.6,13,24.6C13,24.6,13,24.6,13,24.6c0.1,0.1,0.1,0.2,0.1,0.2h-0.2
                        C13,24.7,13,24.7,13,24.6C13,24.6,13,24.6,13,24.6z M13.5,24.6C13.5,24.6,13.5,24.6,13.5,24.6C13.5,24.6,13.6,24.6,13.5,24.6
                        C13.6,24.6,13.6,24.6,13.5,24.6c0.1,0.1,0.1,0.2,0.1,0.2h-0.2C13.5,24.7,13.5,24.7,13.5,24.6z M14,24.6C14,24.6,14,24.6,14,24.6
                        C14.1,24.6,14.1,24.6,14,24.6C14.1,24.6,14.1,24.6,14,24.6c0.1,0.1,0.1,0.2,0.1,0.2H14C14,24.7,14,24.7,14,24.6
                        C14,24.6,14,24.6,14,24.6z M15,24.6C15,24.6,14.9,24.6,15,24.6C14.9,24.5,14.9,24.5,15,24.6C14.9,24.5,14.9,24.5,15,24.6
                        C14.9,24.5,14.9,24.5,15,24.6c-0.1-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0C14.8,24.5,14.9,24.5,15,24.6C14.9,24.5,14.9,24.5,15,24.6
                        C14.9,24.5,14.9,24.5,15,24.6C14.9,24.5,14.9,24.5,15,24.6C14.9,24.6,15,24.6,15,24.6C15,24.6,15,24.6,15,24.6c0,0.1,0,0.1,0,0.1
                        C14.9,24.7,15,24.7,15,24.6C15,24.6,15,24.6,15,24.6z M14.9,24.7C14.9,24.7,14.9,24.7,14.9,24.7C14.9,24.7,14.9,24.7,14.9,24.7z
                        M14.9,24.8C14.9,24.8,14.9,24.8,14.9,24.8C14.8,24.8,14.8,24.8,14.9,24.8C14.9,24.8,14.9,24.8,14.9,24.8z M14.8,24.8
                        C14.8,24.8,14.8,24.8,14.8,24.8C14.8,24.8,14.8,24.8,14.8,24.8z M11.7,25.3c0,0,0-0.1,0-0.1C11.7,25.2,11.7,25.2,11.7,25.3z
                        M11.7,25.6C11.7,25.6,11.7,25.6,11.7,25.6C11.7,25.6,11.7,25.6,11.7,25.6C11.7,25.6,11.7,25.6,11.7,25.6z M11.8,24.9
                        C11.8,24.9,11.8,24.9,11.8,24.9C11.8,24.9,11.8,24.9,11.8,24.9L11.8,24.9C11.8,24.9,11.8,24.9,11.8,24.9
                        C11.8,24.9,11.8,24.9,11.8,24.9l2.9,0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0H11.8z M15.6,29.5
                        c0.1,0.1,0.2,0.3,0.3,0.4l0.2,0.5c0,0,0,0,0,0c0,0,0,0,0,0l-0.7,0c-0.1-0.5-0.3-0.9-0.6-1.2c0,0,0,0-0.1,0l0.1-0.1
                        c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0,0.1,0.1C15.4,29.4,15.6,29.5,15.6,29.5z M14.4,30.6h-0.9v0L14.4,30.6L14.4,30.6z M13,30.6h-0.9v0
                        L13,30.6L13,30.6z M10.5,30.5C10.5,30.5,10.5,30.5,10.5,30.5C10.5,30.5,10.5,30.5,10.5,30.5l0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4
                        c0,0,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.1l0.1,0.1c0,0,0,0-0.1,0c-0.3,0.3-0.5,0.8-0.6,1.2L10.5,30.5
                        L10.5,30.5L10.5,30.5L10.5,30.5L10.5,30.5L10.5,30.5z M14.2,25.6c0.2,0,0.3-0.1,0.4-0.2v0C14.5,25.5,14.3,25.5,14.2,25.6
                        c-0.2,0-0.3,0.1-0.5,0.1C13.9,25.6,14,25.6,14.2,25.6z M14.1,25.3c0.1,0,0.1,0,0.2-0.1C14.2,25.3,14.2,25.3,14.1,25.3z M13.9,25.3
                        c-0.2,0-0.4,0.1-0.7,0.1c-0.3,0-0.5,0-0.7-0.1H13.9z M12.3,25.3c0.2,0.1,0.6,0.2,1,0.2c0.3,0,0.5,0,0.7-0.1
                        c-0.2,0-0.4,0.1-0.7,0.1C12.8,25.4,12.5,25.3,12.3,25.3c-0.1,0-0.1-0.1-0.2-0.1C12.1,25.2,12.2,25.2,12.3,25.3z M13.3,25.8
                        c0.5,0,0.9-0.1,1.2-0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3c0-0.1-0.1-0.1-0.2-0.1h-0.5c-0.1,0-0.2,0.1-0.2,0.2
                        c0,0.1,0.1,0.2,0.2,0.2h0v0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2v0h0c0.1,0,0.1,0,0.2-0.1c0,0.2,0,0.4,0,0.7
                        c0,0,0,0,0,0.1c0,0.2-0.3,0.6-0.7,0.9C13.5,28,13.4,28,13.3,28c-0.1,0-0.2,0-0.3-0.1c-0.5-0.4-0.7-0.7-0.7-0.9c0,0,0-0.1,0-0.1
                        c0-0.2,0-0.4,0-0.7c0,0,0.1,0.1,0.2,0.1h0v0c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2v0h0c0.1,0,0.2-0.1,0.2-0.2
                        c0-0.1-0.1-0.2-0.2-0.2h-0.5c-0.1,0-0.2,0-0.2,0.1c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1C12.4,25.7,12.8,25.8,13.3,25.8z M14,28.9
                        c0,0-0.1,0-0.1,0l0,0C13.9,28.9,13.9,28.9,14,28.9z M13.3,28.5c0.2,0,0.5-0.1,0.6-0.2c0,0,0,0,0.1-0.1c0,0,0,0.1,0,0.1l-0.5,0.4
                        c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l-0.5-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0.1C12.8,28.4,13,28.5,13.3,28.5z M13.3,28.4
                        C13.3,28.4,13.3,28.4,13.3,28.4C13.3,28.4,13.3,28.4,13.3,28.4C13.3,28.4,13.3,28.4,13.3,28.4z M13.2,28.8c0,0-0.1,0-0.1,0
                        C13.1,28.8,13.1,28.8,13.2,28.8z M13.8,28.2C13.8,28.2,13.8,28.2,13.8,28.2C13.8,28.2,13.8,28.2,13.8,28.2z M13.3,28.4
                        C13.4,28.4,13.4,28.4,13.3,28.4C13.4,28.4,13.4,28.4,13.3,28.4z M13.4,28.4C13.4,28.4,13.4,28.4,13.4,28.4
                        C13.4,28.4,13.4,28.4,13.4,28.4z M13.5,28.4C13.5,28.3,13.5,28.3,13.5,28.4C13.5,28.3,13.5,28.3,13.5,28.4z M13.6,28.3
                        c0,0,0.1,0,0.1-0.1C13.7,28.3,13.6,28.3,13.6,28.3z M13.7,28.3C13.7,28.3,13.7,28.2,13.7,28.3C13.7,28.2,13.7,28.3,13.7,28.3z
                        M13.3,28.1C13.4,28.1,13.4,28.1,13.3,28.1C13.4,28.1,13.4,28.1,13.3,28.1z M13.4,28.1C13.5,28.1,13.5,28.1,13.4,28.1
                        C13.5,28.1,13.5,28.1,13.4,28.1z M13.5,28.1c0.1,0,0.1-0.1,0.2-0.1C13.6,28,13.6,28,13.5,28.1z M13.2,28.1
                        C13.2,28.1,13.3,28.1,13.2,28.1C13.3,28.1,13.2,28.1,13.2,28.1z M12.9,28C12.9,28,12.9,28,12.9,28C12.9,28,12.9,28,12.9,28z
                        M13,28C13,28.1,13,28.1,13,28C13,28.1,13,28.1,13,28z M13.1,28.1C13.1,28.1,13.1,28.1,13.1,28.1C13.1,28.1,13.1,28.1,13.1,28.1z
                        M13.2,28.4C13.2,28.4,13.2,28.4,13.2,28.4C13.2,28.4,13.2,28.4,13.2,28.4z M13.1,28.4C13.1,28.4,13.1,28.4,13.1,28.4
                        C13.1,28.4,13.1,28.4,13.1,28.4z M13,28.3C13,28.3,13,28.3,13,28.3C13,28.3,13,28.3,13,28.3z M12.9,28.3
                        C12.9,28.3,12.9,28.3,12.9,28.3C12.9,28.3,12.9,28.3,12.9,28.3z M12.8,28.2C12.8,28.2,12.8,28.2,12.8,28.2
                        C12.8,28.2,12.8,28.2,12.8,28.2z M12.5,28.8c-0.1,0-0.2,0.1-0.3,0.1l-0.1-0.1l0.2-0.2L12.5,28.8z M13.1,29.1
                        c-0.3,0-0.6,0.1-0.8,0.3C12.6,29.2,12.9,29.1,13.1,29.1z M14.3,29c-0.1-0.1-0.2-0.1-0.3-0.1l0.2-0.1L14.3,29L14.3,29z M14,28.7
                        l0.2-0.1l0,0L14,28.7z M14.3,26C14.3,25.9,14.2,25.9,14.3,26C14.2,25.9,14.3,25.9,14.3,26C14.3,26,14.3,26,14.3,26
                        C14.3,26,14.3,26,14.3,26z M14.3,26.2C14.3,26.2,14.2,26.2,14.3,26.2L14.3,26.2C14.2,26.2,14.3,26.2,14.3,26.2z M13,26.2
                        C13,26.2,12.9,26.2,13,26.2L13,26.2C12.9,26.2,13,26.2,13,26.2z M12.2,29.1c0.1,0,0.2-0.1,0.2-0.1C12.4,29,12.3,29.1,12.2,29.1
                        L12,28.9L12.2,29.1z M12.1,29.6c0,0,0.1-0.1,0.1-0.1C12.1,29.5,12.1,29.5,12.1,29.6c-0.1,0.1-0.1,0.1-0.2,0.2
                        c0,0.1-0.1,0.1-0.1,0.2c0-0.1,0.1-0.1,0.1-0.2C11.9,29.7,12,29.6,12.1,29.6z M11.5,29.9c-0.1,0.2-0.1,0.3-0.2,0.5
                        C11.4,30.3,11.4,30.1,11.5,29.9z M11.8,29.5c-0.1,0.1-0.1,0.2-0.2,0.3C11.6,29.7,11.7,29.6,11.8,29.5z M11.7,30.1
                        c0,0.1-0.1,0.1-0.1,0.2C11.7,30.2,11.7,30.2,11.7,30.1z M12.7,29.8l0.1,0.7h-0.6C12.3,30.2,12.5,30,12.7,29.8z M12.9,29.4
                        C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4
                        C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4
                        C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4
                        C12.9,29.4,12.9,29.4,12.9,29.4C12.9,29.4,12.9,29.4,12.9,29.4z M13,29.3C13,29.3,12.9,29.3,13,29.3L13,29.3L13,29.3
                        C12.9,29.3,12.9,29.3,13,29.3C12.9,29.3,12.9,29.3,13,29.3C12.9,29.3,12.9,29.3,13,29.3c-0.1,0-0.1,0-0.1,0l0,0l0,0l0,0l0,0
                        c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.2-0.7,0.6-0.8,1.1h-0.1c0-0.3,0.2-0.7,0.5-0.9c0.1-0.1,0.1-0.1,0.2-0.2
                        c0.3-0.2,0.6-0.3,0.9-0.3c0.3,0,0.6,0.1,0.9,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.6,0.5,0.9h-0.1c-0.1-0.5-0.4-0.9-0.8-1.1
                        c-0.1-0.1-0.2-0.1-0.3-0.1l0,0l0,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0
                        c0,0-0.1,0-0.1,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,0l-0.2,0.8l-0.2-0.8l0,0l0,0
                        c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0C13.1,29.4,13.1,29.4,13,29.3C13,29.3,13,29.3,13,29.3z M13,29.5
                        C13,29.5,13,29.5,13,29.5C13,29.5,13,29.5,13,29.5C13,29.5,13,29.4,13,29.5C13,29.4,13,29.5,13,29.5C13,29.5,13,29.5,13,29.5
                        C13,29.5,13,29.5,13,29.5z M13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4
                        C13.6,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4C13.7,29.4,13.7,29.4,13.6,29.4C13.7,29.4,13.7,29.4,13.6,29.4
                        C13.7,29.4,13.7,29.4,13.6,29.4C13.7,29.4,13.7,29.4,13.6,29.4C13.7,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4z
                        M13.7,29.4C13.7,29.4,13.7,29.4,13.7,29.4C13.7,29.4,13.7,29.4,13.7,29.4C13.7,29.4,13.7,29.4,13.7,29.4c0.1,0,0.1,0,0.1,0.1
                        C13.8,29.4,13.7,29.4,13.7,29.4C13.7,29.4,13.7,29.4,13.7,29.4z M13.8,29.8c0.3,0.2,0.4,0.4,0.5,0.7h-0.6L13.8,29.8z M13.6,29.4
                        C13.6,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4C13.6,29.4,13.6,29.4,13.6,29.4
                        C13.5,29.4,13.5,29.5,13.6,29.4C13.5,29.5,13.5,29.4,13.6,29.4z M14.7,30.6L14.7,30.6c0-0.1,0-0.2-0.1-0.3
                        C14.7,30.4,14.7,30.5,14.7,30.6z M14.4,28c0-0.1,0-0.1,0-0.2C14.4,27.9,14.4,28,14.4,28c0,0.1,0,0.2,0,0.3
                        C14.3,28.3,14.3,28.1,14.4,28z M14.5,25.7c0,0,0-0.1,0-0.1C14.6,25.6,14.6,25.7,14.5,25.7c0,0.1-0.1,0.3-0.1,0.4
                        C14.5,26,14.5,25.9,14.5,25.7z M14.6,27.4C14.6,27.4,14.6,27.4,14.6,27.4C14.6,27.4,14.6,27.4,14.6,27.4z M12,25.4
                        c0,0,0,0.1,0,0.1C12,25.5,12,25.5,12,25.4L12,25.4z M12.1,26.9c0-0.1,0-0.3,0-0.4C12.1,26.6,12.1,26.7,12.1,26.9
                        C12.1,26.9,12.1,26.9,12.1,26.9C12.1,26.9,12.1,26.9,12.1,26.9z M10.4,30.9C10.4,30.9,10.4,30.9,10.4,30.9
                        C10.4,30.9,10.4,30.9,10.4,30.9z M10.4,30.6C10.4,30.6,10.4,30.6,10.4,30.6C10.4,30.6,10.4,30.6,10.4,30.6z M10.5,30.6
                        C10.5,30.6,10.4,30.6,10.5,30.6C10.4,30.6,10.5,30.6,10.5,30.6z M10.5,30.6C10.5,30.6,10.5,30.6,10.5,30.6
                        C10.5,30.6,10.5,30.6,10.5,30.6z M10.5,30.6L10.5,30.6C10.5,30.6,10.5,30.6,10.5,30.6C10.5,30.6,10.5,30.6,10.5,30.6z M15.2,30.6
                        c0-0.2,0-0.3-0.1-0.5c-0.1-0.3-0.3-0.5-0.5-0.7c0,0-0.1-0.1-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.4,0.5,0.5,0.7
                        C15.2,30.3,15.2,30.4,15.2,30.6l0.8,0c0,0,0,0,0,0c0,0,0,0,0,0H15.2z M16.1,30.6C16.1,30.6,16.1,30.6,16.1,30.6
                        C16.1,30.6,16.1,30.6,16.1,30.6z M16.2,30.9C16.2,30.9,16.1,30.9,16.2,30.9C16.1,30.9,16.2,30.9,16.2,30.9z M16.1,30.6
                        C16.1,30.6,16.1,30.6,16.1,30.6C16.1,30.6,16.1,30.6,16.1,30.6z M16.2,30.5C16.2,30.5,16.2,30.5,16.2,30.5
                        C16.2,30.5,16.2,30.5,16.2,30.5z M16.1,30.6C16.1,30.6,16.1,30.6,16.1,30.6C16.1,30.6,16.1,30.6,16.1,30.6z M15.2,28.9
                        c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1C15.3,28.9,15.3,28.9,15.2,28.9c-0.1,0-0.2,0-0.3-0.1
                        C15,28.8,15.1,28.8,15.2,28.9z M14.6,27.3c0-0.1,0.1-0.2,0.1-0.2C14.7,27.1,14.7,27.2,14.6,27.3z M14.8,27
                        C14.8,27,14.8,27,14.8,27C14.8,27,14.8,27,14.8,27z M14.9,26.9C14.9,26.9,14.9,26.9,14.9,26.9C14.9,26.9,14.9,26.9,14.9,26.9z
                        M15,26.8c0,0,0-0.1,0-0.1C15,26.7,15,26.8,15,26.8z M14.9,25.6C14.9,25.6,14.9,25.6,14.9,25.6C14.9,25.6,14.9,25.6,14.9,25.6z
                        M11.7,25.6C11.7,25.6,11.6,25.6,11.7,25.6C11.6,25.6,11.7,25.6,11.7,25.6z M11.8,28.8c-0.1,0-0.2,0-0.3,0
                        C11.6,28.8,11.7,28.8,11.8,28.8z M10.5,30.9C10.5,30.9,10.5,30.9,10.5,30.9C10.5,30.9,10.5,30.9,10.5,30.9
                        C10.5,30.9,10.5,30.9,10.5,30.9z M16,30.9C16.1,30.9,16.1,30.9,16,30.9C16.1,30.9,16.1,30.9,16,30.9C16,30.9,16,30.9,16,30.9z
                        M16.2,30.9C16.2,30.8,16.2,30.8,16.2,30.9C16.2,30.8,16.2,30.8,16.2,30.9z M16.3,30.8C16.3,30.8,16.3,30.8,16.3,30.8
                        C16.3,30.8,16.3,30.8,16.3,30.8z M16.3,30.8C16.3,30.8,16.4,30.7,16.3,30.8C16.4,30.7,16.3,30.8,16.3,30.8z M16.4,30.7
                        C16.4,30.7,16.4,30.7,16.4,30.7C16.4,30.7,16.4,30.7,16.4,30.7z M16.4,30.6C16.4,30.5,16.4,30.5,16.4,30.6c0-0.1,0-0.2,0-0.2
                        l-0.2-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3l0.2,0.5
                        C16.4,30.4,16.4,30.5,16.4,30.6C16.4,30.5,16.4,30.5,16.4,30.6z M16.4,30.6C16.4,30.6,16.4,30.6,16.4,30.6
                        C16.4,30.6,16.4,30.6,16.4,30.6z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Multiple Payment Options',
					text: 'Passengers can pay using a variety of payment modes including cash, in-built digital wallet or cards.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path d="M26,45.2h9.6v1.6H26V45.2z"></path>
                      <path d="M19.6,14.5h1.6v24.2h-1.6V14.5z"></path>
                      <path d="M48.4,16.1h-3.2V4c0-2.2-1.8-4-4-4H20.4c-2.2,0-4,1.8-4,4v4H3.5v8.9H0.3v24.2h16V46c0,2.2,1.8,4,4,4h20.8c2.2,0,4-1.8,4-4
                      V33.9h3.2c0.9,0,1.6-0.7,1.6-1.6V17.7C50,16.9,49.3,16.1,48.4,16.1z M48.4,19.4H26v-1.6h22.4V19.4z M26,21h22.4v1.6H26V21z
                      M20.4,1.6h20.8c1.3,0,2.4,1.1,2.4,2.4v2.4H18V4C18,2.7,19,1.6,20.4,1.6z M16.4,25.1c-0.3-0.1-0.5-0.1-0.8-0.1v-1.6H14V25
                      c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4h1.6c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8H14c-0.4,0-0.8-0.4-0.8-0.8h-1.6
                      c0,1.3,1.1,2.4,2.4,2.4v1.6h1.6v-1.6c0.3,0,0.5-0.1,0.8-0.1v3.2c-0.5,0.1-1.1,0.2-1.6,0.2c-4,0-7.2-3.3-7.2-7.3
                      c0-4,3.2-7.3,7.2-7.3c0.5,0,1.1,0.1,1.6,0.2V25.1z M16.4,28.4c-0.3-0.1-0.5-0.1-0.8-0.1H14c-0.4,0-0.8-0.4-0.8-0.8
                      c0-0.4,0.4-0.8,0.8-0.8h1.6c0.4,0,0.8,0.4,0.8,0.8V28.4z M5.1,9.7h11.2v7.3H5.1V9.7z M1.9,39.5v-21h14.4v1.8
                      c-0.5-0.1-1.1-0.2-1.6-0.2c-4.9,0-8.8,4-8.8,8.9c0,4.9,4,8.9,8.8,8.9c0.5,0,1.1-0.1,1.6-0.2v1.8H1.9z M41.2,48.4H20.4
                      C19,48.4,18,47.3,18,46v-2.4h25.6V46C43.6,47.3,42.5,48.4,41.2,48.4z M43.6,41.9H18V8.1h25.6v8.1H26c-0.9,0-1.6,0.7-1.6,1.6v14.5
                      c0,0.9,0.7,1.6,1.6,1.6h17.6V41.9z M26,32.3v-8.1h22.4l0,8.1H26z"></path>
                      <path d="M27.6,29h4v1.6h-4V29z"></path>
                      <path d="M5.1,21.8h1.6v-1.6H3.5v3.2h1.6V21.8z"></path>
                      <path d="M6.7,11.3v3.2h1.6v-1.6H10v-1.6H6.7z"></path>
                      <path d="M5.1,34.7H3.5v3.2h3.2v-1.6H5.1V34.7z"></path>
                      <path d="M45.2,29h1.6v1.6h-1.6V29z"></path>
                      <path d="M42,29h1.6v1.6H42V29z"></path>
                      <path d="M19.6,11.3h1.6v1.6h-1.6V11.3z"></path>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Notification Alerts',
					text: 'Notification for every successful cab booking, the arrival of a cab, ride cancellation, ride completion, offer alerts and so on.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path d="M20.6,46.2h-3.1c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7-0.3,0.7-0.7C21.3,46.5,21,46.2,20.6,46.2z"></path>
                    <path d="M18.2,3.8h1.6c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7C17.5,3.5,17.8,3.8,18.2,3.8z"></path>
                    <path d="M41.7,11h-9.4V2.3C32.3,1,31.2,0,30,0H8.1C6.8,0,5.8,1,5.8,2.3v45.4c0,1.3,1,2.3,2.3,2.3H30c1.3,0,2.3-1,2.3-2.3V34.3h9.4
                    c1.3,0,2.3-1,2.3-2.3V13.3C44,12,43,11,41.7,11z M8.1,1.5H30c0.5,0,0.8,0.4,0.8,0.8v2.4H7.2V2.3C7.2,1.8,7.6,1.5,8.1,1.5z M30,48.5
                    H8.1c-0.5,0-0.8-0.4-0.8-0.8v-2.4h23.6v2.4C30.8,48.2,30.4,48.5,30,48.5z M42.5,32c0,0.5-0.4,0.8-0.8,0.8H22.9
                    c-0.2,0-0.3,0.1-0.4,0.1l-5.1,3.8V19.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v19.2c0,0.6,0.7,0.9,1.2,0.6l6.1-4.5h7.6v9.5
                    H7.2V6.2h23.6V11H18.2c-1.3,0-2.3,1-2.3,2.3v2.3c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.3c0-0.5,0.4-0.8,0.8-0.8h23.5
                    c0.5,0,0.8,0.4,0.8,0.8V32z"></path>
                    <path d="M36.2,27.4h-0.7c-0.1,0-0.9-0.4-0.9-3.2c0-5.4-2.9-6.2-4.6-6.2s-4.6,0.8-4.6,6.2c0,2.7-0.8,3.1-0.9,3.2h-0.7
                    c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h4c0,0,0,0,0,0c0,1.3,1,2.3,2.3,2.3s2.3-1,2.3-2.3c0,0,0,0,0,0h4c0.4,0,0.7-0.3,0.7-0.7
                    S36.6,27.4,36.2,27.4z M30.8,28.9c0,0.5-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8c0,0,0,0,0,0L30.8,28.9C30.8,28.9,30.8,28.9,30.8,28.9z
                    M26.3,27.4c0.3-0.7,0.5-1.7,0.5-3.2c0-4.7,2.4-4.7,3.2-4.7s3.2,0,3.2,4.7c0,1.5,0.2,2.5,0.5,3.2H26.3z"></path>
                    <path d="M34.7,18c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0.9,0,1.6,0.7,1.6,1.6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                    C37.7,19.4,36.4,18,34.7,18z"></path>
                    <path d="M34.7,14.9c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c2.6,0,4.7,2.1,4.7,4.7c0,0.4,0.3,0.7,0.7,0.7
                    c0.4,0,0.7-0.3,0.7-0.7C40.9,17.7,38.1,14.9,34.7,14.9z"></path>
                    <path d="M23.7,21.1c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7-0.3,0.7-0.7S25.7,18,25.3,18c-1.7,0-3.1,1.4-3.1,3.1c0,0.4,0.3,0.7,0.7,0.7
                    C23.3,21.8,23.7,21.5,23.7,21.1z"></path>
                    <path d="M26,15.6c0-0.4-0.3-0.7-0.7-0.7c-3.4,0-6.2,2.8-6.2,6.2c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-2.6,2.1-4.7,4.7-4.7
                    C25.7,16.3,26,16,26,15.6z"></path>
                  </svg>
					`
				},
				{
					title: 'Preferred Driver',
					text: 'User populated star ratings and review system that will allow new passengers to select high-rated drivers.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path d="M18.6,14.6h-1.1c1.6-1.3,2.7-3.3,2.7-5.6V6.2c0.9-0.6,1.6-1.6,1.6-2.7c0-1.8-1.4-3.2-3.2-3.2H7.5c-1.8,0-3.2,1.4-3.2,3.2
                          c0,1.2,0.6,2.2,1.6,2.7v2.8c0,2.3,1,4.3,2.7,5.6H7.5c-4,0-7.2,3.2-7.2,7.2v8c0,2.6,2.1,4.8,4.8,4.8v15.1H21V34.6
                          c2.6,0,4.8-2.1,4.8-4.8v-8C25.8,17.9,22.6,14.6,18.6,14.6z M5.9,3.5c0-0.9,0.7-1.6,1.6-1.6h11.1c0.9,0,1.6,0.7,1.6,1.6
                          s-0.7,1.6-1.6,1.6H7.5C6.6,5.1,5.9,4.4,5.9,3.5z M18.2,6.7c-0.6,1-1.7,1.6-2.9,1.6h-4.6c-1.2,0-2.3-0.6-2.9-1.6H18.2z M7.5,9.1
                          V8.6c0.9,0.8,2,1.2,3.3,1.2h4.6c1.2,0,2.4-0.5,3.3-1.2v0.4c0,3.1-2.5,5.6-5.6,5.6S7.5,12.1,7.5,9.1z M16.7,16.2l-0.8,2l-0.8-0.5
                          L16.7,16.2L16.7,16.2z M13.5,17h-0.9l-0.8-0.8h2.5L13.5,17z M13.8,27.1l-0.8,0.8l-0.8-0.8l0.7-8.5h0.1L13.8,27.1z M11,17.7
                          l-0.8,0.5l-0.8-2h0.1L11,17.7z M5.1,33c-1.2,0-2.2-0.6-2.7-1.6h2.7V33z M12.3,48.1H6.7v-1.6h5.6L12.3,48.1L12.3,48.1z M19.4,48.1
                          h-5.6v-1.6h5.6L19.4,48.1L19.4,48.1z M19.4,44.9h-5.6v-8.8h-1.6v8.8H6.7V33h12.7V44.9z M21,33v-1.6h2.7C23.2,32.3,22.2,33,21,33z
                          M24.2,29.8H21v-9.6h-1.6v11.1H6.7V20.2H5.1v9.6H1.9v-8c0-3.1,2.5-5.6,5.6-5.6h0.3l1.8,4.4l1.8-1.2l-0.7,8.3l2.4,2.4l2.4-2.4
                          l-0.7-8.3l1.8,1.2l1.8-4.4h0.3c3.1,0,5.6,2.5,5.6,5.6V29.8z"></path>
                          <path d="M41,0.3c-1.8,0-3.4,1.2-3.9,3l-2,8.1h-1.4V9.1H29c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2h3.8L34,16l-0.1,0.4
                          C30,18,27.4,21.8,27.4,26v12.9c0,1.9,1.4,3.5,3.2,3.9v4.5c0,1.3,1.1,2.4,2.4,2.4h6.4c1.3,0,2.4-1.1,2.4-2.4v-4h8v-3.2v-6.4V17.8
                          v-1.6V0.3H41z M32.2,13.9H29c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6h3.2V13.9z M33.8,14.2v-1.1h1l-0.4,1.4L33.8,14.2z
                          M33.8,48.1H33c-0.4,0-0.8-0.4-0.8-0.8V43c0.5,0.1,1.1,0.1,1.6,0.1V48.1z M39.3,48.1h-0.8v-0.8h-1.6v0.8h-1.6v-4.9
                          c0.5,0,1.1,0,1.6,0.1v2.4h1.6v-2.4h1.6v4h0C40.1,47.7,39.8,48.1,39.3,48.1z M48.1,38.5H35.4c-0.9,0-1.6-0.7-1.6-1.6
                          c0-0.9,0.7-1.6,1.6-1.6h12.7V38.5z M48.1,33.8H35.4c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2h12.7v1.6h-9.9
                          c-2.4,0-4.7-0.1-7.1-0.4c-1.2-0.1-2.1-1.2-2.1-2.4V26c0-3.6,2.3-6.9,5.7-8.2l0,0h13.4V33.8z M48.1,16.2H35.6l3.1-12.5
                          C39,2.7,39.9,1.9,41,1.9h7.1V16.2z"></path>
                          <path d="M31.4,25c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8s-2.1-4.8-4.8-4.8C33.5,20.2,31.4,22.4,31.4,25z M36.1,21.8
                          c1.8,0,3.2,1.4,3.2,3.2c0,1.8-1.4,3.2-3.2,3.2S33,26.8,33,25C33,23.2,34.4,21.8,36.1,21.8z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'SOS Button',
					text: 'When any unexpected mishaps happen, customers can use the SOS button to reach out for emergency support.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M43.1,6.9c-4.4-4.4-10.2-6.8-16.4-6.8c-6.2,0-12.1,2.5-16.4,6.8C9.6,6.6,8.9,6.5,8.1,6.5c-2.2,0-4,1.6-5.6,3.1
                      C2.2,9.8,2,10,1.7,10.2c-1,0.8-1.5,2.2-1.7,3.9c-0.2,2.6,0.5,6.1,2.1,9.8c1.8,4.1,4.5,8.5,8,12.5C18.1,45.8,26.5,50,31.4,50
                      c1.4,0,2.5-0.3,3.3-1l0,0c0.3-0.2,0.6-0.5,0.9-0.7c1.8-1.5,3.8-3.1,3.9-5.6c0,0,0-0.1,0-0.1c3.1-2.1,5.6-4.8,7.4-8.1
                      c1.9-3.4,2.9-7.3,2.9-11.2C49.8,17.1,47.4,11.3,43.1,6.9z M11.7,11.1c1.6,2.4,2.3,4.1,2,5.3c-0.1,0.4-0.3,0.8-0.6,1.2
                      c-1.5-2.8-3.9-6.6-5.8-9c0.3-0.1,0.6-0.1,0.8-0.1C9.3,8.4,10.5,9.3,11.7,11.1z M34.4,46.8c-0.3,0.3-0.6,0.5-0.9,0.7
                      c-0.4,0.4-1.2,0.6-2.1,0.6c-4.3,0-12.3-4-19.8-12.8c-3.3-3.9-6-8.1-7.7-12c-1.4-3.4-2.1-6.5-2-8.9c0.1-1.2,0.5-2.2,1-2.6l0,0
                      c0.3-0.2,0.5-0.5,0.8-0.7c0.5-0.5,1.1-1,1.7-1.5c2,2.3,4.7,6.6,6.1,9.4c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3l0,0
                      c-1.3,1.1-1.2,3,0.3,5.6c0.9,1.7,2.5,3.8,4.7,6.4c4.4,5.1,7.4,7.5,9.5,7.5c0.6,0,1.2-0.2,1.7-0.6c0,0,0,0,0.1,0
                      c2.6,1.5,6.5,4.7,8.7,7.3C35.3,46,34.8,46.4,34.4,46.8z M37.6,42.6c0,0.6-0.2,1.1-0.6,1.6c-2.2-2.6-5.8-5.5-8.4-7.1
                      c0.7-0.6,1.4-0.9,2.2-0.9c1.2,0,2.8,0.9,4.8,2.8C37,40.3,37.7,41.5,37.6,42.6z M39.1,40.5c-0.4-1-1.2-2-2.3-3l0,0
                      c-2.4-2.3-4.4-3.3-6.1-3.3c-1.9,0-3.2,1.2-4.6,2.4c-0.1,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.1-0.4,0.1c-0.8,0-3-0.9-8-6.8
                      c-2.1-2.5-3.6-4.5-4.5-6.1c-0.9-1.6-1.1-2.8-0.7-3.2c0.1-0.1,0.3-0.2,0.4-0.3c0.3-0.3,0.7-0.6,1-0.8c0,0,0,0,0,0
                      c1-0.9,1.8-1.8,2.1-3.1c0.4-1.8-0.3-3.9-2.3-6.8c-0.5-0.7-1-1.3-1.5-1.8c3.9-3.9,9.3-6.1,14.9-6.1c11.7,0,21.2,9.5,21.2,21.2
                      C47.9,30.2,44.6,36.5,39.1,40.5z"></path>
                      <path class="st0" d="M21.4,5.3c-0.2-0.5-0.7-0.8-1.2-0.6c-1.5,0.5-2.9,1.2-4.2,2c-0.5,0.3-0.6,0.9-0.3,1.3c0.2,0.3,0.5,0.4,0.8,0.4
                      c0.2,0,0.4-0.1,0.5-0.2c1.2-0.8,2.4-1.4,3.8-1.8C21.3,6.3,21.6,5.8,21.4,5.3z"></path>
                      <path class="st0" d="M23.2,5.8c0.1,0,0.1,0,0.2,0l0.1,0c0.5-0.1,0.9-0.6,0.8-1.1c-0.1-0.5-0.6-0.9-1.1-0.8l-0.1,0
                      c-0.5,0.1-0.9,0.6-0.8,1.1C22.3,5.5,22.7,5.8,23.2,5.8z"></path>
                      <path class="st0" d="M30.8,16.3c-1.7,0-3.1,1.4-3.1,3.1V25c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1v-5.6
                      C33.9,17.7,32.5,16.3,30.8,16.3z M31.9,25c0,0.6-0.5,1.1-1.1,1.1h0c-0.6,0-1.1-0.5-1.1-1.1v-5.6c0-0.6,0.5-1.1,1.1-1.1
                      c0.6,0,1.1,0.5,1.1,1.1V25z"></path>
                      <path class="st0" d="M39.1,21.2L39.1,21.2L39.1,21.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.5,0,1,0.3,1.2,0.7
                      c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3c-0.6-1-1.7-1.7-2.9-1.7c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4c0,0,0,0,0,0l0,0
                      c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.5,0-1-0.3-1.2-0.7c-0.3-0.5-0.9-0.6-1.3-0.4c-0.5,0.3-0.6,0.9-0.4,1.3
                      c0.6,1,1.7,1.7,2.9,1.7c1.9,0,3.4-1.5,3.4-3.4C42.5,22.7,41,21.2,39.1,21.2z"></path>
                      <path class="st0" d="M22.5,21.2L22.5,21.2L22.5,21.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.5,0,1,0.3,1.2,0.7
                      c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3c-0.6-1-1.7-1.7-2.9-1.7c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4c0,0,0,0,0,0l0,0
                      c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4c-0.5,0-1-0.3-1.2-0.7c-0.3-0.5-0.9-0.6-1.3-0.4c-0.5,0.3-0.6,0.9-0.4,1.3
                      c0.6,1,1.7,1.7,2.9,1.7c1.9,0,3.4-1.5,3.4-3.4C25.8,22.7,24.3,21.2,22.5,21.2z"></path>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Promo Codes',
					text: 'Entice users to use the app regularly with loyalty schemes attached to promo codes, discounts, etc.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M49,27.1c0.6,0,1-0.5,1-1v-5.2c0-2.3-1.9-4.2-4.2-4.2H38c-0.3-0.7-0.5-1.4-0.5-2.1c0-1.9,1-3.6,2.7-4.6
                        c0.2-0.1,0.4-0.4,0.5-0.6c0.1-0.3,0-0.6-0.1-0.8l-2.5-4.4c-0.6-1-1.4-1.7-2.5-2c-1.1-0.3-2.2-0.1-3.1,0.4L2.1,20.3
                        c-2,1.2-2.6,3.7-1.5,5.7l2.6,4.6c0.1,0.2,0.4,0.4,0.6,0.5c0.3,0.1,0.6,0,0.8-0.1c0.8-0.5,1.7-0.8,2.7-0.8c2.3,0,4.3,1.5,4.9,3.7
                        c-0.7,2.1-2.6,3.6-4.9,3.6c-0.6,0-1,0.5-1,1v5.2c0,2.3,1.9,4.2,4.2,4.2h35.4c2.3,0,4.2-1.9,4.2-4.2v-5.2c0-0.6-0.5-1-1-1
                        c-2.9,0-5.2-2.3-5.2-5.2C43.7,29.4,46.1,27.1,49,27.1z M33.5,4.4C34,4.1,34.5,4,35,4.2c0.5,0.1,1,0.5,1.3,1l2,3.6
                        c-1.8,1.4-2.9,3.5-2.9,5.8c0,0.7,0.1,1.4,0.3,2.1H12.5L33.5,4.4z M7.3,28.1c-1,0-1.9,0.2-2.8,0.6L2.4,25c-0.6-1-0.2-2.3,0.8-2.9
                        l3.2-1.9c0,0.2-0.1,0.4-0.1,0.6V26c0,0.6,0.5,1,1,1c1.9,0,3.6,1.1,4.5,2.6C10.5,28.7,9,28.1,7.3,28.1z M41.7,32.3
                        c0,3.7,2.7,6.7,6.3,7.2v4.3c0,1.2-0.9,2.1-2.1,2.1h-25v-1.8c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1v1.8h-8.3c-1.1,0-2.1-0.9-2.1-2.1
                        v-4.3c3.5-0.5,6.3-3.6,6.3-7.2c0-3.7-2.7-6.7-6.3-7.2v-4.3c0-1.2,0.9-2.1,2.1-2.1h8.3v2.4c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-2.4
                        h25c1.1,0,2.1,0.9,2.1,2.1v4.3C44.4,25.6,41.7,28.6,41.7,32.3z M19.8,26.3c-0.6,0-1,0.5-1,1v2.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1
                        v-2.1C20.8,26.8,20.4,26.3,19.8,26.3z M19.8,34.7c-0.6,0-1,0.5-1,1v2.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-2.1
                        C20.8,35.1,20.4,34.7,19.8,34.7z M39.2,23.1c-0.5-0.3-1.1-0.3-1.5,0.2L25.2,40.1c-0.3,0.5-0.3,1.1,0.2,1.5
                        c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l12.5-16.7C39.7,24.1,39.6,23.5,39.2,23.1z M28.1,31.3c1.8,0,3.1-1.8,3.1-4.2
                        c0-2.3-1.4-4.2-3.1-4.2c-1.8,0-3.1,1.8-3.1,4.2C25,29.4,26.4,31.3,28.1,31.3z M28.1,25c0.4,0,1,0.8,1,2.1s-0.6,2.1-1,2.1
                        c-0.4,0-1-0.8-1-2.1S27.7,25,28.1,25z M36.5,33.4c-1.8,0-3.1,1.8-3.1,4.2s1.4,4.2,3.1,4.2c1.8,0,3.1-1.8,3.1-4.2
                        S38.2,33.4,36.5,33.4z M36.5,39.6c-0.4,0-1-0.8-1-2.1c0-1.3,0.6-2.1,1-2.1c0.4,0,1,0.8,1,2.1C37.5,38.8,36.9,39.6,36.5,39.6z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Referral Rewards',
					text: 'Incentivize users to invite their near and dear to use the app with referral rewards in the form of free rides, promo codes, etc.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M46.8,24c-1.3,0-2.3,1.1-2.3,2.3V33l-0.8,0.9c-0.1-0.3-0.4-0.6-0.6-0.9c-1-0.9-2.5-0.8-3.3,0.2l-3.7,4.8
                        c-0.3,0.5-0.5,1.1-0.5,1.6v4.1H35c-0.4,0-0.9,0.4-0.9,0.9v4.5c0,0.5,0.4,0.9,0.9,0.9h10c0.4,0,0.9-0.4,0.9-0.9v-4.5
                        c0-0.5-0.4-0.9-0.9-0.9h-0.8v-1c0-0.3,0.1-0.7,0.2-0.9l4.3-6c0.2-0.3,0.3-0.7,0.3-1.1v-8.5C49.1,25.1,48,24,46.8,24z M44.5,45.3
                        v3.2h-8.8v-3.2H44.5z M47.5,35.1l-4.3,6c-0.3,0.5-0.5,1.1-0.5,1.7v1H37v-4.1c0-0.3,0.1-0.5,0.2-0.7l3.7-4.8c0.2-0.3,0.7-0.4,1.1,0
                        c0.4,0.4,0.4,1.1,0.1,1.5l-0.7,0.8c-0.2,0.3-0.2,0.8,0.1,1.1c0.3,0.3,0.7,0.2,1-0.1l3-3.6c0.1-0.2,0.2-0.4,0.2-0.5v-6.9
                        c0-0.5,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v8.4h0.1C47.6,35,47.6,35,47.5,35.1z M14.1,43.8h-0.6v-4.1c0-0.6-0.1-1.1-0.5-1.7
                        l-3.7-4.8c-0.7-1-2.2-1.2-3.3-0.2c-0.2,0.2-0.4,0.5-0.6,0.8L4.6,33v-6.6c0-1.3-1-2.3-2.3-2.3c-1.2,0-2.2,1.1-2.2,2.3v8.5
                        c0,0.4,0.1,0.8,0.3,1.1l4.2,5.9c0.1,0.3,0.3,0.6,0.3,0.9v1H4.1c-0.4,0-0.9,0.4-0.9,0.9v4.5c0,0.5,0.4,0.9,0.9,0.9h10
                        c0.4,0,0.9-0.4,0.9-0.9v-4.4C14.9,44.2,14.5,43.8,14.1,43.8z M1.6,35.1C1.5,35,1.5,35,1.5,34.9v-8.5c0-0.5,0.4-0.8,0.8-0.8
                        c0.4,0,0.8,0.4,0.8,0.8v6.9c0,0.2,0.1,0.5,0.2,0.5l3.1,3.6c0.3,0.3,0.7,0.4,1,0.1c0.3-0.3,0.4-0.8,0.1-1.1l-0.7-0.8
                        c-0.4-0.5-0.3-1.1,0.1-1.5c0.3-0.3,0.8-0.3,1,0.1l3.7,4.8c0.1,0.2,0.2,0.5,0.2,0.8v4.1H6.4v-1c0-0.7-0.1-1.3-0.5-1.8L1.6,35.1z
                        M13.4,48.5H4.7v-3.2h8.8V48.5z M20.4,23.2c-0.4-0.2-0.8,0.1-1,0.5l-2.7,7.8L16.2,29c-0.1-0.3-0.2-0.5-0.5-0.6
                        c-0.2-0.1-0.5,0-0.7,0.2l-1.8,1.7l2.9-8.8c0.1-0.4-0.1-0.8-0.4-1c-0.4-0.2-0.8,0.1-1,0.5l-3.9,11.6c-0.1,0.3,0,0.7,0.3,0.9
                        c0.3,0.2,0.7,0.2,0.9-0.1l2.9-2.6l0.7,3.9c0.1,0.3,0.3,0.6,0.7,0.6c0.3,0,0.7-0.2,0.7-0.5l3.9-10.6C21,23.7,20.8,23.3,20.4,23.2z
                        M37.9,33.1c0.3-0.2,0.4-0.6,0.2-0.9l-4.5-11.4c-0.1-0.4-0.6-0.6-1-0.4c-0.4,0.2-0.6,0.6-0.4,1l3.5,8.7l-1.9-1.5
                        c-0.2-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.4,0.3-0.4,0.6l-0.3,2.3l-3.1-7.9c-0.1-0.4-0.6-0.6-1-0.5c-0.4,0.2-0.6,0.6-0.4,1l4.2,10.5
                        c0.1,0.3,0.4,0.5,0.8,0.5c0.4-0.1,0.6-0.3,0.7-0.7l0.4-3.9l3,2.5C37.2,33.3,37.6,33.3,37.9,33.1z M24.4,5.2c-3.9,0-6.9,3.2-6.9,7
                        c0,1.9,0.7,3.6,2,5c1.3,1.4,3,2,4.9,2c3.9,0,6.9-3.2,6.9-7C31.3,8.4,28.2,5.2,24.4,5.2z M24.4,17.8c-1.5,0-2.8-0.6-3.9-1.7
                        c-1-1.1-1.6-2.4-1.6-3.9c0-3,2.5-5.5,5.4-5.5c3,0,5.4,2.5,5.4,5.5C29.8,15.3,27.4,17.8,24.4,17.8z M30.8,22.3l0.7-2.6l2.5-0.5
                        c0.2-0.1,0.4-0.2,0.4-0.3c0.1-0.2,0.1-0.4,0.1-0.6L33.8,15l2.3-2.4c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5l-2.1-2l0.7-2.8
                        c0.1-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.3-0.3-0.4-0.4l-2.5-0.7l-0.5-2.6c-0.1-0.4-0.5-0.7-0.9-0.6l-3.2,0.7l-2.4-2.3
                        c-0.3-0.3-0.7-0.3-1,0l-2,2.1l-2.8-0.8c-0.4-0.1-0.8,0.1-0.9,0.5l-0.7,2.6l-2.5,0.8c-0.2,0.1-0.4,0.2-0.4,0.3
                        c-0.1,0.2-0.1,0.4-0.1,0.6L15,9.6l-2.3,2.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5l2.1,2L14,17.8c-0.1,0.2,0,0.4,0.1,0.6
                        c0.1,0.2,0.3,0.3,0.4,0.4l2.5,0.7l0.5,2.6c0.1,0.4,0.5,0.7,0.9,0.6l3.2-0.7l2.4,2.3c0.1,0.2,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2
                        l2-2.1l2.8,0.8C30.3,22.9,30.7,22.7,30.8,22.3z M27.1,20.5c-0.3-0.1-0.5,0-0.7,0.2l-1.8,1.9l-2.1-2c-0.1-0.2-0.3-0.2-0.5-0.2
                        c-0.1,0-0.1,0-0.1,0l-2.9,0.6l-0.5-2.3c-0.1-0.3-0.3-0.5-0.5-0.6l-2.2-0.5l0.7-2.5c0.1-0.2,0-0.5-0.2-0.8l-1.9-1.8l2-2.1
                        c0.1-0.2,0.2-0.5,0.2-0.7l-0.6-2.9l2.2-0.5c0.3-0.1,0.5-0.3,0.6-0.6l0.6-2.3l2.5,0.7c0.2,0.1,0.5,0,0.7-0.2L24.3,2l2.1,2
                        c0.1,0.2,0.4,0.2,0.7,0.2l2.9-0.6l0.5,2.3c0.1,0.3,0.3,0.5,0.5,0.6l2.2,0.6l-0.7,2.5c-0.1,0.2,0,0.5,0.2,0.8l1.9,1.8l-2,2.1
                        c-0.1,0.2-0.2,0.5-0.2,0.7l0.6,2.9l-2.2,0.5c-0.3,0.1-0.5,0.3-0.6,0.5l-0.6,2.3L27.1,20.5z M24.9,11.5V9.2
                        c0.6,0.1,1.2,0.3,1.6,0.7l0.7-1.1c-0.7-0.5-1.6-0.8-2.5-0.9V7.3h-0.7v0.6c-0.7,0.1-1.3,0.3-1.9,0.7c-0.4,0.5-0.7,1-0.7,1.7
                        c0,0.7,0.2,1.2,0.6,1.6c0.4,0.4,1,0.6,1.9,0.8V15c-0.7-0.1-1.3-0.5-2-1.1L21.3,15c0.8,0.8,1.8,1.2,2.9,1.3v0.9h0.7v-0.8
                        c0.8-0.1,1.4-0.3,1.9-0.7c0.4-0.5,0.7-1,0.7-1.7c0-0.7-0.2-1.2-0.6-1.6C26.5,12,25.8,11.7,24.9,11.5z M24.1,11.3
                        c-0.4-0.2-0.7-0.3-0.9-0.5c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.8-0.3V11.3z M25.8,14.7
                        c-0.2,0.2-0.5,0.3-0.9,0.3v-2.1c0.4,0.2,0.8,0.3,1,0.5c0.1,0.2,0.2,0.4,0.2,0.7C26.1,14.4,26,14.6,25.8,14.7z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Cancel Booking',
					text: 'Users can cancel their bookings easily from the same screen where the ride was booked along with appropriate reasons.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M25.1,3.2h4.7V2.3h-4.7V3.2z M27.4,34.9c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2
                        C28.5,35.4,28,34.9,27.4,34.9z M40.2,29.7l-0.8-0.7l1.1-1.3c0.8-1,0.7-2.5-0.3-3.3l-1-0.8c0,0,0,0-0.1-0.1l1.3-1.6
                        c0.8-1,0.7-2.5-0.3-3.3l-1-0.8c-0.3-0.2-0.7-0.4-1.1-0.5V2.3C38,1,37,0,35.8,0H19c-1.3,0-2.2,1-2.2,2.3v10.6
                        c-0.4-0.4-0.9-0.8-1.6-1c-1.2-0.5-2.7-0.1-3.7,0.8c-0.8,0.8-1,1.9-0.6,3c0.2,0.6,0.5,1.3,0.6,2.1c0.8,3.2,0.9,6.8,0.4,9.4L11.8,28
                        c-0.7,3.8-1.3,7.3-0.2,10c1,2.3,2.2,3.5,3.4,4.6l0.1,0.1c0.2,0.2,0.3,0.5,0.2,0.8l-1,2.7l14.2,3.4l0.8-2c0.1-0.2,0.3-0.4,0.5-0.4
                        c0.7-0.1,1.9-0.5,3.1-1.7c1.5-1.6,2.9-4.4,3.4-5.6c0.2,0.1,0.4,0.1,0.6,0.1c0.7,0,1.3-0.3,1.8-0.8l1-1.2c0.4-0.5,0.6-1.1,0.5-1.7
                        c0-0.6-0.3-1.2-0.8-1.6l-0.1-0.1l1.1-1.3C41.3,32.1,41.2,30.6,40.2,29.7z M39.2,25.5c0.4,0.3,0.5,0.9,0.1,1.3l-1.3,1.5l-0.6,0.7
                        c-0.1,0.1-0.2,0.1-0.3,0.2l-0.1,0h-0.1l-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.1l-1-0.8c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.2,0.1-0.4,0.2-0.6
                        l1.7-2c0.1,0,0.2,0,0.3,0h0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1,0,0.2,0.1L39.2,25.5z M37.4,18.6h0.1
                        c0.2,0,0.4,0.1,0.6,0.2l1.1,0.8c0.4,0.3,0.4,0.9,0.1,1.3l-1.9,2.2l0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0,0,0-0.1,0.1l-0.4-0.1
                        c-0.1,0-0.3-0.1-0.4-0.2l-1-0.8c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.2,0-0.4,0.2-0.6l1.9-2.2C37,18.7,37.2,18.6,37.4,18.6z M18.1,2.3
                        c0-0.4,0.4-0.8,0.9-0.8h16.8c0.5,0,0.8,0.4,0.8,0.8V4H18.1V2.3z M18.1,5.5h18.5v11.8c-0.3,0.1-0.6,0.3-0.8,0.6l-1.3,1.6
                        c-0.1-0.9-0.8-1.5-1.7-1.5h-0.3l-1.1-1.5c-0.4-0.5-1-0.8-1.6-0.8H27c-0.5,0-1,0.2-1.4,0.6l-1.7,1.7h-2c-0.9,0-1.7,0.8-1.7,1.7v1.5
                        h1.6c0-0.1,0-0.1,0-0.1c0-0.9,0.7-1.6,1.6-1.6S25,20.1,25,21c0,0,0,0.1,0,0.1h4.8c0-0.1,0-0.1,0-0.1c0-0.9,0.7-1.6,1.6-1.6
                        c0.9,0,1.6,0.7,1.6,1.6c0,0,0,0.1,0,0.1h0.5c0,0.2,0,0.4,0,0.7c0,0.6,0.3,1.2,0.8,1.7l1,0.8c0,0,0,0,0,0L34.1,26
                        c-0.4,0.5-0.6,1.1-0.5,1.7c0,0,0,0,0,0.1c0,0.6,0.3,1.2,0.8,1.6l0.8,0.7l-1.1,1.2c-0.4,0.4-0.5,1-0.5,1.6H18.1V5.5z M31.8,44.5
                        c-0.9,0.9-1.7,1.2-2.2,1.3c-0.7,0.1-1.4,0.6-1.7,1.3L27.6,48l-11.5-2.8l0.4-1.2c0.3-0.8,0.1-1.8-0.5-2.4L16,41.5
                        c-1.1-1-2.1-2-3-4.1c-1-2.3-0.3-5.7,0.3-9.2l0.1-0.8c0.5-2.9,0.3-6.7-0.4-10c-0.2-0.8-0.4-1.5-0.7-2.2c-0.2-0.6-0.1-1.1,0.3-1.5
                        c0.5-0.5,1.5-0.8,2.1-0.5c1.9,0.8,2.1,2.7,2.1,2.9v21.3c0,1.3,1,2.3,2.2,2.3h15.8C34.2,40.9,33,43.2,31.8,44.5z M34.8,35.8
                        c-0.4,0.5-0.6,1.1-0.5,1.7c0,0,0,0,0,0.1c0,0.2,0.1,0.4,0.1,0.6H19c-0.5,0-0.8-0.4-0.8-0.8v-3h15.7c0.1,0.1,0.2,0.3,0.4,0.4
                        l0.8,0.7L34.8,35.8z M38.6,36.9l-1,1.2c-0.2,0.2-0.5,0.3-0.8,0.3l-0.4-0.2c0-0.1-0.1-0.1-0.1-0.1L36,38c-0.2-0.1-0.3-0.4-0.3-0.6
                        c0-0.2,0.1-0.4,0.2-0.6l0.5-0.6h0.3c0,0,0,0,0,0h0.2c0.1,0,0.2-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1
                        c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1l0.1,0.1c0.2,0.1,0.3,0.4,0.3,0.6
                        C38.8,36.5,38.8,36.8,38.6,36.9z M39.3,32.2l-1.3,1.5l-0.6,0.7c0,0.1-0.1,0.1-0.3,0.2l0,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0
                        l-0.4-0.2c-0.1-0.1-0.2-0.1-0.2-0.1l-1-0.8c-0.2-0.1-0.3-0.4-0.3-0.6c0-0.2,0-0.4,0.2-0.6l1.3-1.6h0.3c0,0,0,0,0,0h0.1
                        c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.2
                        c0,0,0,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.1l0.8,0.7C39.6,31.2,39.7,31.8,39.3,32.2z M31.4,19.8c-0.7,0-1.2,0.6-1.2,1.2
                        c0,0.7,0.5,1.2,1.2,1.2s1.2-0.6,1.2-1.2C32.6,20.3,32.1,19.8,31.4,19.8z M23.4,22.2c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.5-1.2-1.2-1.2
                        s-1.2,0.6-1.2,1.2C22.2,21.7,22.7,22.2,23.4,22.2z M23,29c0.2,0,0.3,0,0.5,0.1l0.1-0.5c-0.1-0.1-0.3-0.1-0.6-0.1
                        c-0.7,0-1.3,0.5-1.3,1.4c0,0.7,0.4,1.3,1.3,1.3c0.3,0,0.5-0.1,0.6-0.1l-0.1-0.5c-0.1,0-0.3,0.1-0.5,0.1c-0.5,0-0.8-0.3-0.8-0.8
                        C22.3,29.3,22.6,29,23,29z M25.3,30.1c0-0.4-0.2-0.8-0.8-0.8c-0.3,0-0.6,0.1-0.7,0.2l0.1,0.4c0.1-0.1,0.3-0.1,0.5-0.1
                        c0.3,0,0.3,0.1,0.3,0.2v0c-0.6,0-1,0.2-1,0.7c0,0.3,0.2,0.6,0.6,0.6c0.2,0,0.4-0.1,0.5-0.2h0l0,0.2h0.5c0-0.1,0-0.3,0-0.5V30.1z
                        M24.8,30.5C24.8,30.6,24.8,30.6,24.8,30.5c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.2,0.2-0.3,0.5-0.3V30.5z M26.8,29.3
                        c-0.3,0-0.5,0.2-0.5,0.3h0l0-0.3h-0.5c0,0.2,0,0.4,0,0.6v1.3h0.5v-1.1c0-0.1,0-0.1,0-0.1c0-0.1,0.1-0.2,0.3-0.2
                        c0.2,0,0.3,0.2,0.3,0.4v1h0.5v-1.1C27.4,29.5,27.1,29.3,26.8,29.3z M28.8,29.7c0.1,0,0.2,0,0.3,0.1l0.1-0.4
                        c-0.1,0-0.3-0.1-0.4-0.1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,0.9,1c0.2,0,0.4,0,0.5-0.1l-0.1-0.4c-0.1,0-0.2,0.1-0.3,0.1
                        c-0.3,0-0.5-0.2-0.5-0.5C28.3,29.9,28.5,29.7,28.8,29.7z M30.2,29.3c-0.6,0-0.9,0.5-0.9,1c0,0.6,0.3,1,0.9,1c0.2,0,0.5,0,0.6-0.1
                        l-0.1-0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.5-0.4h1.1c0,0,0-0.1,0-0.2C31,29.7,30.7,29.3,30.2,29.3z M29.8,30
                        c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.3,0.2,0.3,0.4H29.8z M31.3,31.2h0.5v-2.7h-0.5V31.2z M27,12.9l1.1-1.8l1,1.8h1.7L29.1,10l1.6-2.7
                        h-1.6l-1,1.7l-1-1.7h-1.7l1.7,2.7l-1.8,2.8H27z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Contact Driver',
					text: 'Users can reach out to through phone call to the cab driver assigned to them straight from the app.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M21.5,22.5C21.6,22.5,21.6,22.5,21.5,22.5c0.3,0,0.4,0,0.6-0.1c1.6,4.2,4.9,8.2,9,8.2c5.3,0,9.2-6.8,9.9-11.9
                        c1-0.5,1.8-1.1,2.2-2c0.1-0.3,0.2-0.5,0.2-0.8C43,9.7,37.7,4.8,31.4,4.8c-6.7,0-12.1,5.4-12.1,12.1c0,1.8,0.4,3.5,0.7,4.5
                        C20.2,22.1,20.9,22.5,21.5,22.5z M31.1,29c-3.2,0-5.9-3.4-7.3-6.8c3.2-0.6,7.1-2.1,10.1-4c0,0,0,0.1,0,0.1c-0.1,0.5,0,1.1,0.3,1.5
                        c0.3,0.4,0.8,0.6,1.4,0.6c0.7-0.1,2.1-0.3,3.6-0.7C38.3,23.8,35.1,29,31.1,29z M31.4,6.5c5.4,0,10,4.2,10.4,9.6
                        c-0.7,1.4-3.8,2.3-6.2,2.6c0,0,0.2-0.9,0.3-2.3c0-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.7-0.1-1,0.2c-3,2.6-9.3,4.8-12.8,5
                        C21.3,20,21,18.5,21,16.9C21,11.1,25.7,6.5,31.4,6.5z M38.5,29.7c-0.3,0-0.7,0.1-1.1,0.2l-0.5,0.2c-1.9,0.9-3.7,1.7-5.5,1.7
                        c-1.8,0-3.6-0.8-5.5-1.7L25.4,30c-0.3-0.2-0.7-0.3-1.2-0.2c-1.7,0.3-3.5,1.1-5.1,2.3c0,0,0,0,0,0c-0.5,0-1,0.2-1.4,0.4l-2.9,1.7
                        c-0.4,0.2-0.7,0.6-1,1c-1.2-0.6-3.1-1.9-4.4-4c-1.2-2.1-1.5-4.3-1.5-5.7c0.5,0,1-0.2,1.4-0.4l2.9-1.7c0.7-0.4,1.3-1.1,1.5-2
                        c0.2-0.8,0.1-1.7-0.3-2.3l-2.3-5.8c0,0-0.1-0.2-0.1-0.2c-0.4-0.7-1.1-1.3-2-1.5c-0.8-0.2-1.7-0.1-2.4,0.3l-3.7,2.2
                        c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-1,1-2,3.9-2.4,7c-0.4,3.4-0.2,8.5,2.9,13.8c3.9,6.6,8.8,9,12.2,9.9
                        c1.5,0.4,2.8,0.5,3.5,0.5c0.7,0,1.7-0.1,2.3-0.4l1.7-1h26.4c0.5,0,0.8-0.4,0.8-0.8C50,37.4,44.5,30.8,38.5,29.7z M7.4,13.3
                        c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.3,0,0.4,0.1c0.4,0.1,0.7,0.3,0.9,0.6l2.4,5.9c0.2,0.3,0.2,0.7,0.1,1.1c-0.1,0.4-0.3,0.7-0.7,0.9
                        l-2.9,1.7c-0.2,0.1-0.5,0.2-0.8,0.2l-3.5-8.5L7.4,13.3z M15.7,43.4c-3.1-0.8-7.6-3-11.2-9.1c-2.9-4.9-3.1-9.6-2.7-12.7
                        c0.2-2.1,0.8-3.7,1.2-4.8l3.2,7.9c-0.1,1.4,0,4.5,1.7,7.3c1.7,2.9,4.4,4.4,5.7,4.9l5.4,6.9C18.3,43.9,17.2,43.8,15.7,43.4z
                        M24.5,40.6c-0.1,0.4-0.3,0.7-0.7,0.9l-3.1,1.8l-5.7-7.2c0.1-0.2,0.3-0.4,0.5-0.5l2.9-1.7c0.3-0.2,0.8-0.3,1.1-0.2
                        c0.4,0.1,0.7,0.3,1,0.8l3.8,4.9C24.6,39.8,24.6,40.2,24.5,40.6z M25.3,42.4c0.4-0.4,0.7-0.8,0.8-1.4c0.2-0.8,0.1-1.7-0.4-2.6
                        L22,33.6c-0.2-0.4-0.5-0.7-0.8-0.9c1.1-0.6,2.2-1.1,3.1-1.3c0.1,0,0.2,0,0.4,0.1l0.5,0.2c2,0.9,4.1,1.9,6.2,1.9
                        c2.1,0,4.2-1,6.2-1.9l0.5-0.2c0.1-0.1,0.3-0.1,0.2-0.1c4.8,0.9,9.5,6.2,9.9,11H25.3z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Ride Ratings',
					text: 'For every completed ride, the user can leave a star rating as well as text review of the ride experience.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M40.5,0H10.3C8.9,0,7.8,1.1,7.8,2.5v45c0,1.4,1.1,2.5,2.5,2.5h30.2c1.4,0,2.5-1.1,2.5-2.5v-45
                        C43,1.1,41.9,0,40.5,0z M41.3,47.5c0,0.5-0.4,0.8-0.8,0.8H10.3c-0.5,0-0.8-0.4-0.8-0.8V11.7h31.8V47.5z M41.3,10H9.5V2.5
                        c0-0.5,0.4-0.8,0.8-0.8h30.2c0.5,0,0.8,0.4,0.8,0.8V10z M38.5,6.9c0.2-0.3,0.3-0.7,0.3-1.1c0-1.4-1.1-2.5-2.5-2.5
                        c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5c0.4,0,0.7-0.1,1.1-0.2l0.8,0.8c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.8,0-1.2
                        L38.5,6.9z M36.9,6.4L36.9,6.4L36.9,6.4c-0.3,0.3-0.7,0.3-1,0.1c-0.3-0.2-0.5-0.6-0.4-0.9C35.6,5.2,35.9,5,36.3,5
                        c0.3,0,0.6,0.2,0.8,0.5C37.2,5.8,37.1,6.2,36.9,6.4z M29.6,3.3H13.7c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5h15.9
                        c1.4,0,2.5-1.1,2.5-2.5C32.1,4.5,31,3.3,29.6,3.3z M29.6,6.7H13.7c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h15.9
                        c0.5,0,0.8,0.4,0.8,0.8C30.4,6.3,30,6.7,29.6,6.7z M12.8,43.7l-0.3,2c-0.1,0.3,0.1,0.6,0.3,0.8c0.3,0.2,0.6,0.2,0.9,0l1.7-0.9
                        l1.7,0.9c0.3,0.2,0.6,0.1,0.9,0c0.3-0.2,0.4-0.5,0.3-0.8l-0.3-2l1.4-1.4c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.5-0.7-0.6
                        l-1.9-0.3l-0.8-1.8c-0.1-0.3-0.4-0.5-0.8-0.5s-0.6,0.2-0.8,0.5l-0.8,1.8l-1.9,0.3c-0.3,0-0.6,0.3-0.7,0.6c-0.1,0.3,0,0.6,0.2,0.8
                        L12.8,43.7z M14.4,42.2c0.3,0,0.5-0.2,0.6-0.5l0.3-0.6l0.3,0.6c0.1,0.3,0.4,0.4,0.6,0.5l0.7,0.1l-0.5,0.6
                        c-0.2,0.2-0.3,0.5-0.2,0.7l0.1,0.7L15.7,44c-0.3-0.1-0.6-0.1-0.8,0l-0.6,0.3l0.1-0.7c0-0.3,0-0.5-0.2-0.7l-0.5-0.6L14.4,42.2z
                        M28.9,40.9L27,40.6l-0.8-1.8c-0.1-0.3-0.4-0.5-0.8-0.5c-0.3,0-0.6,0.2-0.8,0.5l-0.8,1.8l-1.9,0.3c-0.3,0-0.6,0.3-0.7,0.6
                        c-0.1,0.3,0,0.6,0.2,0.8l1.4,1.4l-0.3,2c-0.1,0.3,0.1,0.6,0.3,0.8c0.3,0.2,0.6,0.2,0.9,0l1.7-0.9l1.7,0.9c0.3,0.2,0.6,0.1,0.9,0
                        c0.3-0.2,0.4-0.5,0.3-0.8l-0.3-2l1.4-1.4c0.2-0.2,0.3-0.5,0.2-0.8C29.4,41.2,29.2,41,28.9,40.9z M26.5,42.9
                        c-0.2,0.2-0.3,0.5-0.2,0.7l0.1,0.7L25.8,44c-0.3-0.1-0.6-0.1-0.8,0l-0.6,0.3l0.1-0.7c0-0.3,0-0.5-0.2-0.7l-0.5-0.6l0.7-0.1
                        c0.3,0,0.5-0.2,0.6-0.5l0.3-0.6l0.3,0.6c0.1,0.3,0.4,0.4,0.6,0.5l0.7,0.1L26.5,42.9z M36.2,38.8c-0.1-0.3-0.4-0.5-0.8-0.5
                        c-0.3,0-0.6,0.2-0.8,0.5l-0.8,1.8L32,40.9c-0.3,0-0.6,0.3-0.7,0.6c-0.1,0.3,0,0.6,0.2,0.8l1.4,1.4l-0.3,2
                        c-0.1,0.3,0.1,0.6,0.3,0.8c0.3,0.2,0.6,0.2,0.9,0l1.7-0.9l1.7,0.9c0.3,0.2,0.6,0.1,0.9,0c0.3-0.2,0.4-0.5,0.3-0.8l-0.3-2l1.4-1.4
                        c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.3-0.4-0.5-0.7-0.6L37,40.6L36.2,38.8z M36.5,42.9c-0.2,0.2-0.3,0.5-0.2,0.7l0.1,0.7L35.8,44
                        c-0.3-0.1-0.6-0.1-0.8,0l-0.6,0.3l0.1-0.7c0-0.3,0-0.5-0.2-0.7l-0.5-0.6l0.7-0.1c0.3,0,0.5-0.2,0.6-0.5l0.3-0.6l0.3,0.6
                        c0.1,0.3,0.4,0.4,0.6,0.5l0.7,0.1L36.5,42.9z M18.7,34.5L18.7,34.5C18.7,34.6,18.7,34.6,18.7,34.5c4.1,2.8,9.4,2.8,13.4,0h0
                        c4.2-2.9,6-8.2,4.5-13.1c-1.5-4.9-6.1-8.2-11.2-8.2c-5.1,0-9.7,3.3-11.2,8.1C12.7,26.3,14.5,31.6,18.7,34.5z M20.4,33.6L20.4,33.6
                        c0.1-0.1,0.2-0.2,0.3-0.2l2.7-1.3c0.6-0.3,0.9-0.8,0.9-1.4v-0.3c0-0.5-0.3-0.9-0.7-1.1c-1-0.5-1.7-1.4-2-2.5
                        c-0.1-0.3-0.3-0.5-0.6-0.6c-0.2-0.1-0.3-0.3-0.3-0.5V25c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.4-0.4,0.4-0.7c0.1-0.6,0.2-1.2,0.5-1.8
                        c0.2-0.5,0.4-1,0.4-1.6c0.9,0.4,1.8,0.6,2.8,0.5c0.1,0,0.2,0,0.3,0c3.2,0,3.8,1.6,4,2.9c0,0.3,0.2,0.5,0.4,0.6
                        c0.2,0.1,0.3,0.3,0.3,0.5v0.7c0,0.2-0.1,0.4-0.4,0.5c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,1.1-0.9,2-1.9,2.5c-0.4,0.2-0.7,0.6-0.7,1.1
                        v0.4c0,0.6,0.3,1.2,0.9,1.4l2.6,1.3c0.1,0.1,0.3,0.2,0.4,0.3C27.3,35.5,23.4,35.4,20.4,33.6z M15.9,21.6c1.4-4,5.2-6.6,9.5-6.6
                        c4.2,0,8,2.7,9.5,6.6c1.4,4,0.2,8.4-3.1,11.1c-0.3-0.3-0.6-0.6-1-0.8l-2.5-1.3v-0.1c1.2-0.7,2-1.7,2.4-3c0.7-0.4,1.1-1.1,1.1-1.9
                        V25c0-0.7-0.3-1.3-0.8-1.7c-0.3-1.9-1.6-4-5.6-4c-0.1,0-0.3,0-0.4,0c-0.8,0.1-1.5-0.1-2.2-0.5c-0.2-0.2-0.4-0.3-0.6-0.5
                        c-0.3-0.3-0.7-0.4-1.1-0.3c-0.4,0.2-0.6,0.6-0.6,1c0,0.3,0.1,0.5,0.1,0.8c0.1,0.5,0,1.1-0.2,1.6c-0.3,0.6-0.4,1.3-0.5,1.9
                        c-0.5,0.4-0.7,1-0.7,1.7v0.7c0,0.8,0.4,1.5,1,1.9c0.4,1.3,1.3,2.4,2.5,3l-2.7,1.3c-0.4,0.2-0.7,0.5-0.9,0.8
                        C15.7,30,14.5,25.6,15.9,21.6z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Trip History',
					text: 'A complete history of all the completed rides along with data in timestamps can be seen the user profile section.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M26.8,48.3l-0.4-0.5l-11.1,0l-11.1,0l-0.4-0.2c-0.6-0.3-1-0.8-1.2-1.5c-0.1-0.4-0.1-41.4,0-41.8
                        c0.1-0.6,0.6-1.2,1.2-1.5l0.3-0.2h16.9h16.9l0.4,0.2c0.6,0.3,1,0.8,1.2,1.5c0,0.2,0.1,3.7,0.1,10.3v10.1l0.4,0
                        c0.2,0,0.7,0.1,1,0.1c0.3,0.1,0.6,0.1,0.6,0.1c0.1,0,0.1-1.5,0.1-10c0-6.2,0-10.2-0.1-10.6c0-0.3-0.1-0.7-0.2-0.9
                        c-0.5-1.5-1.7-2.6-3.3-2.9C37.5,0.4,4.6,0.4,4,0.5c-1,0.2-2,0.8-2.6,1.7C0.8,2.9,0.6,3.5,0.5,4.4c0,0.4,0,7.6,0,21.2l0,20.6
                        l0.1,0.4c0.4,1.4,1.4,2.5,2.7,3c0.2,0.1,0.6,0.2,0.8,0.2c0.2,0,4,0,12.2,0l11.8,0l-0.5-0.5C27.4,49,27,48.6,26.8,48.3z M30,14.1
                        v-1.1h-9.5h-9.5v1.1v1.1h9.5H30V14.1z M30,20.4v-1.1h-9.5h-9.5v1.1v1.1h9.5H30V20.4z M29.7,27.5l0.3-0.2l0-0.8l0-0.8h-9.5h-9.5v1
                        v1h9.2h9.2L29.7,27.5z M48.7,36.9c-0.7-3-2.8-5.6-5.6-7c-1.8-0.9-3.8-1.2-5.8-1c-0.6,0.1-1.6,0.3-2.2,0.5
                        c-0.7,0.2-1.9,0.8-2.5,1.2c-2.2,1.5-3.8,3.7-4.4,6.1c-0.2,0.9-0.3,1.6-0.3,2.6c0,1.7,0.3,3.1,1,4.6c1.9,3.9,6.1,6.3,10.5,5.9
                        c4.5-0.4,8.3-3.7,9.3-8.1C49.1,40.3,49.1,38.5,48.7,36.9z M46.5,42c-1,2.9-3.4,5.1-6.5,5.6c-0.8,0.1-2.4,0.1-3.1,0
                        c-3.2-0.7-5.7-3-6.5-6.1c-0.2-0.8-0.3-1.3-0.3-2.2c0-0.9,0.1-1.4,0.3-2.2c0.6-2.3,2.2-4.3,4.4-5.4c0.8-0.4,1.4-0.6,2.4-0.8
                        c0.7-0.1,2.4-0.1,3.1,0.1c0.8,0.2,1.3,0.4,2.1,0.7c1.9,0.9,3.3,2.5,4,4.4C47,38.1,47.1,40.1,46.5,42z M11.1,32v1v1h6.8h6.8
                        l0.2-0.5c0.1-0.3,0.3-0.8,0.5-1l0.3-0.5h-7.3H11.1z M39.5,38.3v-2.1v-2.1h-1.1h-1.1v3.2v3.2h3.2h3.2v-1v-1h-2.1H39.5z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Favorite Location',
					text: 'A user can mark frequently visited locations as favorites. It helps save time when the booking has to be done repetitively.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <path class="st0" d="M26.4,50c-0.3,0-0.7-0.2-0.9-0.5L11.7,27.7c-0.1-0.2-0.3-0.5-0.5-0.7l-0.3-0.5c0-0.1-0.1-0.1-0.1-0.1
                      c-1.5-2.6-2.3-5.6-2.3-8.5C8.6,7.9,16.6,0,26.4,0c9.9,0,17.9,7.9,17.9,17.7c0,3-0.8,5.9-2.3,8.5c0,0.1-0.1,0.1-0.1,0.1l-0.3,0.5
                      c-0.1,0.3-0.3,0.5-0.5,0.8L27.3,49.5C27.1,49.8,26.8,50,26.4,50z M12.7,25.4l0.3,0.4c0.1,0.3,0.3,0.5,0.5,0.7l13,20.6l13-20.5
                      c0.2-0.3,0.3-0.5,0.5-0.7l0.3-0.4v-0.1c1.4-2.4,2-5,2-7.7C42.3,9.1,35.2,2,26.4,2S10.6,9,10.6,17.6C10.6,20.3,11.3,23,12.7,25.4
                      C12.7,25.4,12.7,25.4,12.7,25.4z"></path>
                    </g>
                    <g>
                      <path class="st0" d="M31.7,25.8c-0.3,0-0.5-0.1-0.7-0.2l-4.5-2.4L22,25.6c-0.6,0.3-1.4,0.1-1.8-0.3c-0.3-0.3-0.4-0.7-0.3-1.2l0.9-5
                      L17,15.6c-0.5-0.4-0.6-0.9-0.5-1.5c0.2-0.5,0.6-0.9,1.2-1l5-0.7l2.3-4.5c0.3-0.5,0.8-0.8,1.4-0.8c0.5,0,1.1,0.3,1.4,0.8l2.3,4.5
                      l5,0.7c0.5,0.1,1,0.5,1.2,1c0.2,0.5,0,1.1-0.4,1.5l-3.7,3.5l0.9,5c0.1,0.4-0.1,0.9-0.3,1.2C32.5,25.6,32.1,25.8,31.7,25.8z
                      M19.2,14.9l3.1,3c0.3,0.3,0.5,0.8,0.4,1.3l-0.8,4.2l3.8-2c0.4-0.2,1-0.2,1.4,0l3.8,2l-0.8-4.2c-0.1-0.5,0.1-0.9,0.4-1.3l3.1-3
                      l-4.2-0.6c-0.5-0.1-0.9-0.3-1.1-0.8l-1.9-3.8l-1.9,3.8c-0.2,0.4-0.6,0.7-1.1,0.8L19.2,14.9z"></path>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Multilingual Support',
					text: 'Users can toggle between multiple languages to use the app effortlessly on a language of their choice.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <g>
                      <g>
                        <path class="st0" d="M21.9,15.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1S21.3,15.9,21.9,15.9z M28.7,15.9
                        c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1S28,15.9,28.7,15.9z M44.7,36.7c-0.2-1.5-1.4-2.6-2.7-3l-9.7-1.9
                        c-0.3-0.1-0.7,0-0.9,0.2l-4.6,3.9c-0.8,0.7-2,0.7-2.9,0c0,0-0.1,0-0.1-0.1l-2.4-1.6c-0.6-0.3-1.2-0.2-1.6,0.3
                        c-0.3,0.6-0.2,1.3,0.3,1.6l2.3,1.6c0.6,0.5,1.1,0.7,1.7,0.9v5.7c0,0.7,0.5,1.1,1.1,1.1c0.7,0,1.1-0.5,1.1-1.1v-5.7
                        c0.7-0.1,1.2-0.5,1.8-0.9l4.2-3.5l9.2,1.8c0.5,0.1,0.8,0.5,0.9,1l1,8.4c0,0.1,0,0.1,0,0.2c0,1.1-0.9,2-2,2H9.2c-1.1,0-2-0.9-2-2
                        c0-0.1,0-0.2,0-0.2L8.1,37c0-0.5,0.5-0.9,0.9-1l9.7-1.9c0.6-0.1,1-0.7,0.9-1.4c-0.1-0.6-0.7-1-1.4-0.9l-9.7,1.9
                        c-1.4,0.3-2.5,1.5-2.7,3l-0.9,8.4c0,0.1,0,0.3,0,0.5c0,2.5,1.9,4.4,4.3,4.4h32c2.4,0,4.3-1.9,4.3-4.3c0.1-0.2,0.1-0.3,0.1-0.6
                        L44.7,36.7z M14,19.3c0.1,1.1,0.6,2.2,1.2,3.1l4.2,5.5c0.9,1.1,2.2,1.7,3.5,1.7h4.6c1.4,0,2.7-0.7,3.5-1.7l2.2-2.8h2.3
                        c1.9,0,3.4-1.5,3.4-3.4v-3.2c0.7-0.6,1.1-1.5,1.1-2.5v-2.3c0-1.9-1.5-3.4-3.4-3.4c0-0.2,0-0.3-0.1-0.5C35.7,4.2,30.9,0,25.3,0
                        c-5.9,0-10.6,4.5-11.2,10.2H14c-1.9,0-3.4,1.5-3.4,3.4v2.3C10.6,17.8,12,19.3,14,19.3z M36.6,12.5c0.7,0,1.1,0.5,1.1,1.1v2.3
                        c0,0.7-0.5,1.1-1.1,1.1V12.5z M35.3,22.4c0.7-0.9,1.1-1.9,1.1-3.1v2.3h0.1c0,0.7-0.5,1.1-1.1,1.1h-0.3L35.3,22.4z M25.3,2.3
                        C29.5,2.3,33,5.1,34,9c-1.7-0.2-3.4-0.9-4.6-1.9c-0.3-0.3-0.9-0.3-1.2-0.1c-3.2,1.9-7.4,3.1-11.8,3.3C16.9,5.7,20.6,2.3,25.3,2.3z
                        M16.3,18.2v-5.7c4.6-0.1,8.9-1.2,12.3-3.2c1.6,1.1,3.6,1.8,5.8,2v6.8v0.7c0,0.8-0.2,1.5-0.7,2.2l-1.5,1.7h-3.6
                        c-0.2-0.7-0.9-1.1-1.6-1.1h-3.4c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7h6.9l-1.2,1.4c-0.5,0.6-1.1,0.9-1.8,0.9H23
                        c-0.7,0-1.4-0.3-1.8-0.9L17,20.9c-0.5-0.6-0.7-1.4-0.7-2.2V18.2z M12.8,13.6c0-0.7,0.5-1.1,1.1-1.1V17c-0.7,0-1.1-0.5-1.1-1.1
                        V13.6z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Help & Support',
					text: 'For any queries that the user cannot find a solution to, there help &amp; support section can be added to resolve their queries.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">                          <g>
                      <g>
                        <path class="st0" d="M15,35c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3
                        C15.5,36,15.4,35.4,15,35L15,35z M13.7,14.2c-5,5-6,12.8-2.5,18.9c0.3,0.5,0.9,0.6,1.3,0.4c0.5-0.3,0.6-0.9,0.4-1.3
                        c-3.1-5.4-2.2-12.2,2.2-16.6c5.4-5.4,14.1-5.4,19.4,0c5.4,5.4,5.4,14.1,0,19.4c-4.4,4.4-11.2,5.3-16.6,2.2
                        c-0.5-0.3-1.1-0.1-1.3,0.3c-0.3,0.5-0.1,1.1,0.3,1.3c2.5,1.4,5.2,2.1,7.9,2.1c4.1,0,8.1-1.6,11.1-4.6c6.1-6.1,6.1-16,0-22.2
                        C29.7,8.1,19.8,8.1,13.7,14.2z M49.5,21c0-0.5-0.3-0.9-0.8-1l-3.6-0.6l-1.9-4.6l2.1-2.9c0.3-0.4,0.2-0.9-0.1-1.3l-6-6
                        c-0.3-0.3-0.9-0.4-1.3-0.1l-2.9,2.1l-4.6-1.9l-0.6-3.6c-0.1-0.5-0.5-0.8-1-0.8h0l-8.5,0c-0.5,0-0.9,0.3-1,0.8L19,4.9l-4.6,1.9
                        l-2.9-2.1c-0.4-0.3-0.9-0.2-1.3,0.1l-6,6c-0.3,0.3-0.4,0.9-0.1,1.3l2.1,2.9l-1.9,4.6l-3.6,0.6C0.3,20.1,0,20.5,0,21l0,8.5
                        c0,0.5,0.3,0.9,0.8,1L4.4,31l1.9,4.6l-2.1,2.9c-0.3,0.4-0.2,0.9,0.1,1.3l6,6c0.3,0.3,0.9,0.4,1.3,0.1l2.9-2.1l4.6,1.9l0.6,3.6
                        c0.1,0.5,0.5,0.8,1,0.8h0l8.5,0c0.5,0,0.9-0.3,1-0.8l0.6-3.6l4.6-1.9l2.9,2.1c0.4,0.3,0.9,0.2,1.3-0.1l6-6
                        c0.3-0.3,0.4-0.9,0.1-1.3l-2.1-2.9l1.9-4.6l3.6-0.6c0.5-0.1,0.8-0.5,0.8-1L49.5,21z M44.3,29.2c-0.3,0.1-0.6,0.3-0.7,0.6l-2.3,5.6
                        c-0.1,0.3-0.1,0.7,0.1,0.9l2,2.7l-4.8,4.8l-2.7-2c-0.3-0.2-0.6-0.2-0.9-0.1L29.3,44c-0.3,0.1-0.5,0.4-0.6,0.7l-0.5,3.3l-6.8,0
                        l-0.5-3.3c-0.1-0.3-0.3-0.6-0.6-0.7l-5.6-2.3c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.2l-2.7,2L6.2,39l2-2.7
                        c0.2-0.3,0.2-0.6,0.1-0.9L6,29.8c-0.1-0.3-0.4-0.5-0.7-0.6l-3.3-0.5l0-6.8l3.3-0.5C5.6,21.3,5.8,21,6,20.7l2.3-5.6
                        c0.1-0.3,0.1-0.7-0.1-0.9l-2-2.7L11,6.7l2.7,2c0.3,0.2,0.6,0.2,0.9,0.1l5.6-2.3c0.3-0.1,0.5-0.4,0.6-0.7l0.5-3.3l6.8,0l0.5,3.3
                        c0.1,0.3,0.3,0.6,0.6,0.7l5.6,2.3c0.3,0.1,0.7,0.1,0.9-0.1l2.7-2l4.8,4.8l-2,2.7c-0.2,0.3-0.2,0.6-0.1,0.9l2.3,5.6
                        c0.1,0.3,0.4,0.5,0.7,0.6l3.3,0.5l0,6.8L44.3,29.2z M20.4,25.4l4.2,4.2c-0.3,1.7,0.3,3.4,1.5,4.6c1,1,2.4,1.6,3.8,1.6
                        c0.7,0,1.4-0.1,2-0.4c0.3-0.1,0.5-0.4,0.6-0.7c0.1-0.3,0-0.6-0.3-0.9l-1.4-1.4c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0
                        l1.4,1.4c0.2,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6c0.8-2.1,0.3-4.5-1.4-6c-1.2-1.1-2.8-1.6-4.4-1.3l-4.2-4.2
                        c0.3-1.7-0.3-3.4-1.5-4.6c-1.5-1.5-3.8-2-5.8-1.2c-0.3,0.1-0.5,0.4-0.6,0.7c-0.1,0.3,0,0.6,0.3,0.9l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
                        c-0.3,0.3-0.8,0.3-1.1,0l-1.4-1.4c-0.2-0.2-0.6-0.3-0.9-0.3c-0.3,0.1-0.6,0.3-0.7,0.6c-0.8,2.1-0.3,4.5,1.4,6
                        C17.2,25.1,18.8,25.6,20.4,25.4z M17.2,22.6c-0.6-0.6-1-1.4-1.1-2.2l0.1,0.1c1.1,1.1,2.8,1.1,3.8,0c1.1-1.1,1.1-2.8,0-3.8l0,0
                        c0.8,0.1,1.5,0.4,2,1c0.9,0.9,1.2,2.1,0.9,3.3c-0.1,0.3,0,0.7,0.3,0.9l5,5c0.2,0.2,0.6,0.3,0.9,0.3c1.1-0.3,2.3,0,3.2,0.8
                        c0.6,0.6,1,1.3,1.1,2.2l0,0c-1.1-1.1-2.8-1.1-3.8,0c-1.1,1.1-1.1,2.8,0,3.8l0,0c-0.8-0.1-1.5-0.4-2.1-1c-0.9-0.9-1.2-2.1-0.9-3.3
                        c0.1-0.3,0-0.7-0.3-0.9l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.2,0-0.2,0C19.3,23.7,18.1,23.4,17.2,22.6z M27.2,14.3
                        c-0.5-0.1-1,0.2-1.2,0.7c-0.1,0.5,0.2,1,0.7,1.2c2.1,0.5,3.9,1.7,5.3,3.3c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
                        c0.4-0.3,0.5-0.9,0.2-1.4C32,16.3,29.7,14.9,27.2,14.3z M33.6,22.4c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.3,0
                        c0.5-0.2,0.8-0.7,0.6-1.2l0,0c-0.2-0.5-0.7-0.8-1.2-0.6C33.7,21.3,33.4,21.9,33.6,22.4L33.6,22.4z"></path>
                      </g>
                    </g>
                  </svg>
					`
				},
				{
					title: 'Invite Friends',
					text: 'Increase user base of the app by inducing existing users to invite their friends through social media, email or referral code.',
					svg: `
						<svg x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <path class="st0" d="M50,25.2c0,13.8-11.2,25-25,25c-13.8,0-25-11.2-25-25s11.2-25,25-25C38.8,0.2,50,11.4,50,25.2z M47.8,25.2
                    C47.8,12.5,37.6,2.3,25,2.3C12.4,2.3,2.2,12.5,2.2,25.2C2.2,37.8,12.4,48,25,48C37.6,48,47.8,37.8,47.8,25.2z M38.1,21.6h4.8V24
                    h-4.8v4.8h-2.4V24H31v-2.4h4.8v-4.8h2.4V21.6z M9,25.2c0.1,0.3,0.4,0.5,0.7,0.4l1.1-0.3l0.3,1.1c0.1,0.2,0.2,0.7,0.5,1.1
                    c0.2,0.4,0.5,0.6,0.7,0.7l0.7,0.3l0,0.7l0,1.8c0,0.3,0,1,0,2.2c0,1.2-0.8,2.2-1.9,2.6l-6.6,2.1l-0.7-2.3l6.6-2.1
                    c0.1,0,0.2-0.2,0.2-0.3c0-1.2,0-2,0-2.2l0-1.1c-0.4-0.3-0.7-0.7-1-1.2c-0.2-0.3-0.3-0.5-0.4-0.8c-1.2-0.1-2.2-1-2.5-2.2l-0.6-2.3
                    c-0.3-1,0.2-2.1,1.2-2.5c0-0.3,0-0.5,0-0.7c0-5.7,3.6-9.5,8.6-9.4c5,0,8.6,3.7,8.6,9.4c0,0.2,0,0.5,0,0.7c1,0.4,1.5,1.5,1.2,2.6
                    L25,25.8c-0.3,1.2-1.4,2.1-2.6,2.2c-0.1,0.2-0.3,0.5-0.4,0.7c-0.3,0.4-0.5,0.7-0.9,1l0,1.4c0,0.2,0,1,0,2.2c0,0.1,0.1,0.3,0.2,0.3
                    l4.7,1.4c2.1,0.6,3.6,2.6,3.6,4.8V49h-2.4v-9.2c0-1.2-0.8-2.2-1.9-2.5l-4.7-1.4c-1.2-0.3-2-1.4-2-2.6c0-1.2,0-1.9,0-2.1l-0.1-2.7
                    l0.6-0.4c0.2-0.1,0.4-0.4,0.7-0.7c0.3-0.4,0.5-0.8,0.5-1.1l0.4-1l1,0.3c0.3,0.1,0.6-0.1,0.7-0.4l0.5-2.1l-1.4-0.4l0.1-1
                    c0.1-0.5,0.1-1,0.1-1.4c0-4.4-2.5-7-6.2-7c-3.6,0-6.2,2.6-6.2,7c0,0.4,0,0.9,0.1,1.4l0.1,1l-1,0.3l-0.4,0.1L9,25.2z"></path>
                  </svg>
					`
				}
			],
			items2: [
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M62.91,15c-5.72,0.54-10.57,5.91-10.55,11.68v40.86c-13.89,0-28.01,0-41.82,0C4.83,68.08-0.02,73.45,0,79.21v402.8
                    c0,6.11,5.54,11.68,11.64,11.68h296.72c6.09,0,11.64-5.56,11.64-11.68v-40.86h40.73c6.09,0,11.63-5.56,11.64-11.68V26.68
                    c0-6.11-5.54-11.67-11.64-11.68C261.48,15,162.08,15,62.91,15z M453.81,15c-14.7,0-28.33,3.26-39.09,9.85
                    c-10.77,6.59-19.09,17.71-19.09,31.01c0,106.89,0,214.37,0,321.07c0.03,1.63,0.4,3.26,1.09,4.74l46.54,105.08
                    c1.86,4,6.15,6.74,10.55,6.74c4.4,0,8.68-2.74,10.55-6.74l46.55-105.08c0.69-1.48,1.06-3.11,1.09-4.74
                    c0.01-106.84,0-214.09,0-321.07c0-13.3-8.33-24.42-19.09-31.01C482.14,18.26,468.51,15,453.81,15z M75.64,38.35h273.45V417.8H75.64
                    C75.67,291.35,75.64,164.54,75.64,38.35z M453.81,38.35c11.09,0,20.85,2.67,26.91,6.38c6.06,3.71,8,7.28,8,11.13v17.51H418.9V55.86
                    c0-3.85,1.94-7.42,8-11.13C432.96,41.02,442.72,38.35,453.81,38.35z M23.27,90.89h29.09v338.59c0,6.11,5.54,11.67,11.64,11.68
                    h232.72v29.19H23.27V90.89z M126.91,90.89c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.2,11.13h40.73
                    c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.66-11.76-11.8-11.68C154.83,90.89,140.71,90.89,126.91,90.89z M220,90.89
                    c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.19,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68
                    c0-6.17-5.66-11.76-11.8-11.68C278.94,90.89,249.33,90.89,220,90.89z M418.9,96.73h69.82v23.35H418.9V96.73z M126.91,137.59
                    c-6.1,0.28-11.39,6.11-11.1,12.22c0.29,6.12,6.1,11.42,12.2,11.13h40.73c6.15,0.09,11.8-5.51,11.8-11.68
                    c0-6.17-5.66-11.76-11.8-11.68C154.83,137.59,140.71,137.59,126.91,137.59z M220,137.59c-6.1,0.28-11.39,6.11-11.1,12.22
                    c0.29,6.12,6.1,11.42,12.19,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.66-11.76-11.8-11.68
                    C278.94,137.59,249.33,137.59,220,137.59z M418.9,143.43h69.82v210.16H418.9V143.43z M126.91,236.83
                    c-6.09,0.29-11.37,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13h40.73c6.14,0.08,11.79-5.51,11.79-11.68
                    c0-6.16-5.65-11.76-11.79-11.67C154.83,236.83,140.71,236.83,126.91,236.83z M220,236.83c-6.09,0.29-11.38,6.11-11.09,12.22
                    c0.29,6.11,6.09,11.41,12.18,11.13h87.27c6.14,0.08,11.79-5.51,11.79-11.68c0-6.16-5.65-11.76-11.79-11.67
                    C278.94,236.83,249.33,236.83,220,236.83z M126.91,283.53c-6.09,0.29-11.38,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13
                    h40.73c6.15,0.09,11.8-5.51,11.8-11.68c0-6.17-5.65-11.76-11.8-11.67C154.83,283.53,140.71,283.53,126.91,283.53z M220,283.53
                    c-6.09,0.29-11.38,6.11-11.09,12.22c0.29,6.11,6.09,11.41,12.18,11.13h87.27c6.15,0.09,11.8-5.51,11.8-11.68
                    c0-6.17-5.65-11.76-11.8-11.67C278.94,283.53,249.33,283.53,220,283.53z M419.81,376.94h68l-34,76.62L419.81,376.94z"
					></path>
				</svg>`,
					title: 'Easy Registration',
					text: 'A simple and hassle-free process for drivers to easily register their vehicles or driver services with the app.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M419.74,458.26c-2.43-14.93-4.73-27.44-6.75-38.61c-6.21-34.44-9.73-54.22-8.24-97.4c0.4-11.7-3.24-21.39-9.59-29.73
                        c-5.94-7.8-14.05-14.13-23.37-19.64c-9.18-5.38-15.13-15.74-21.07-26.1c-8.65-15.2-17.42-30.54-36.6-38.21V40.94
                        c0-10.49-4.19-19.91-11.08-26.77C296.15,7.3,286.56,3,276.16,3H127.99C117.45,3,108,7.3,101.11,14.17
                        C94.22,21.03,89.9,30.44,89.9,40.94v297.32c0,10.36,4.32,19.91,11.21,26.77c6.89,6.86,16.34,11.03,26.88,11.03h35.93
                        c-0.41,0.81-0.81,1.75-1.08,2.56l-0.41,0.94c-1.62,4.31-2.16,9.28-1.75,14.13c0.4,4.71,1.89,9.28,4.05,13.45
                        c5.4,9.82,15.53,16.82,29.18,14.8c4.46-0.67,9.05-1.48,12.97-2.15c9.18-1.75,14.32-2.56,24.04-1.21
                        c6.08,11.97,14.59,23,24.18,33.23c9.99,10.63,21.07,20.18,31.88,29.19l2.84,7.13c1.08,2.69,1.89,4.98,2.57,7
                        c0.27,0.67,0.67,1.35,1.08,2.02c8.37,13.18,39.31,14.53,68.21,8.21c10-2.15,19.86-5.11,28.63-8.75
                        c9.19-3.9,17.02-8.48,22.29-13.72C420.01,475.61,423.12,467.27,419.74,458.26z M127.99,356.95c-5.13,0-9.86-2.15-13.24-5.52
                        c-3.51-3.36-5.54-8.07-5.54-13.18V40.94c0-5.11,2.02-9.82,5.54-13.18c3.38-3.5,8.1-5.52,13.24-5.52h148.17
                        c5.13,0,9.86,2.02,13.24,5.52c3.38,3.36,5.54,8.07,5.54,13.18v174.62v81.66c-7.29-5.78-14.86-11.57-22.69-17.49
                        c-13.51-10.22-27.82-20.31-42.55-30.4c-13.24-9.01-25.93-9.69-36.06-5.38c-5.13,2.02-9.32,5.38-12.7,9.55
                        c-3.24,3.9-5.54,8.75-6.75,13.99c-2.16,10.09,0,21.53,8.24,30.94c-4.46,4.84-7.16,11.17-7.16,18.16c0,7.4,2.97,14.13,7.83,18.83
                        c4.86,4.84,11.62,7.8,18.91,7.8c6.62,0,12.7-2.29,17.29-6.32l0.27,0.27c5.54,6.32,10.81,12.92,15.94,19.78H127.99z M191.61,307.58
                        c6.75,6.73,13.24,13.18,19.18,19.78c-2.3,2.02-5.4,3.09-8.78,3.09c-3.78,0-7.29-1.48-9.86-4.04c-2.43-2.56-4.05-5.92-4.05-9.82
                        C188.1,313.09,189.45,310,191.61,307.58z M399.07,469.29c-3.51,3.36-9.32,6.73-16.21,9.69c-7.43,3.09-16.21,5.65-25.26,7.67
                        c-21.21,4.57-42.55,5.92-47.54,0.81l-2.3-6.32l-3.92-9.55c-0.68-1.75-1.89-3.23-3.38-4.3c-10.81-8.88-21.88-18.3-31.34-28.52
                        c-9.45-10.09-17.69-20.85-22.96-32.69c-1.35-3.09-4.05-4.98-7.03-5.52c-16.34-3.09-22.83-1.88-35.66,0.4
                        c-3.11,0.54-6.75,1.21-12.29,2.02c-4.46,0.67-7.83-1.75-9.59-4.98c-0.95-1.88-1.62-3.9-1.76-6.05c-0.27-2.02,0-4.04,0.68-5.78
                        l0.13-0.4c1.08-2.55,3.78-4.44,7.97-4.57l59.57-0.4c1.76,13.18,4.46,23.81,9.05,33.77c5.67,12.38,13.91,22.87,26.07,34.71
                        c3.78,3.63,9.86,3.63,13.64-0.14c3.65-3.77,3.51-9.82-0.27-13.59c-10.4-10.09-17.42-18.97-22.02-28.92
                        c-4.19-9.28-6.62-20.05-8.1-34.31c0-0.4,0-0.81,0-1.21c0-0.94-0.14-2.02-0.41-2.96c-0.4-2.56-1.75-4.84-3.78-6.32
                        c-9.18-13.59-18.37-25.56-28.23-37.13c-10.81-12.51-22.56-24.48-35.93-37.4c-5-4.84-6.35-10.63-5.27-15.74
                        c0.54-2.15,1.49-4.3,2.97-6.05c1.35-1.75,3.11-3.09,5.13-3.9c4.46-1.88,10.67-1.35,17.69,3.5c14.72,10.09,28.77,20.05,42.01,30
                        c12.43,9.28,24.18,18.56,35.39,27.98l21.75,21.66c4.19,4.17,10.81,4.17,14.99,0c4.05-4.17,4.05-10.76-0.13-14.8l-18.64-18.56
                        v-80.99c8.78,6.32,14.45,16.14,19.99,25.83c7.16,12.65,14.32,25.16,27.83,33.23c7.43,4.3,13.64,9.15,17.83,14.66
                        c3.78,4.98,5.94,10.63,5.81,17.62c-1.62,45.07,2.16,65.65,8.51,101.3c2.03,11.03,4.32,23.68,6.75,39.28
                        c0.14,0.81,0.4,1.61,0.81,2.42C401.91,465.79,400.97,467.4,399.07,469.29z M188.8,207.38l6.39-6.36l83.56-83.05
                        c3.47-3.63,3.47-9.27,0.18-12.9c-3.65-3.63-9.3-3.63-12.95,0l-77.18,76.69c-3.65,3.63-9.3,3.63-12.95,0l-25.54-25.62
                        c-3.65-3.63-9.3-3.63-12.95,0c-3.65,3.63-3.65,9.27,0,12.9l38.5,38.35C179.5,211.02,185.15,211.02,188.8,207.38z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Select Availability',
					text: 'Drivers can toggle between their working hours and break hours using available and unavailable options.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M159.01,76.45c0-4.72-3.83-8.55-8.57-8.55h-5.23c-4.74,0-8.57,3.83-8.57,8.55s3.83,8.55,8.57,8.55h5.23
                        C155.18,85.01,159.01,81.18,159.01,76.45z M187.79,76.45c0-4.72-3.84-8.55-8.57-8.55h-5.23c-4.74,0-8.57,3.83-8.57,8.55
                        s3.83,8.55,8.57,8.55h5.23C183.95,85.01,187.79,81.18,187.79,76.45z M202.46,85.01h5.24c4.74,0,8.57-3.83,8.57-8.55
                        s-3.84-8.55-8.57-8.55h-5.24c-4.73,0-8.57,3.83-8.57,8.55S197.73,85.01,202.46,85.01z M164.69,89.64h-5.24
                        c-4.74,0-8.57,3.83-8.57,8.55c0,4.72,3.83,8.55,8.57,8.55h5.24c4.73,0,8.57-3.83,8.57-8.55
                        C173.26,93.47,169.42,89.64,164.69,89.64z M179.66,98.19c0,4.72,3.83,8.55,8.57,8.55h5.23c4.74,0,8.57-3.83,8.57-8.55
                        c0-4.72-3.83-8.55-8.57-8.55h-5.23C183.49,89.64,179.66,93.47,179.66,98.19z M267.12,330.75c-13.69-1.84-26.61-3.57-35.83-12.43
                        c-6.36-5.97-6.21-18.16-5.03-33.56c34.14-30.88,46.39-90.76,46.39-124.54c0-0.18-0.02-0.35-0.03-0.52
                        c0.98-1.16,1.93-2.36,2.87-3.61c15.02-12.16,18.15-24.99,18.08-33.69c-0.12-16.78-11.42-33.84-33.69-50.77
                        c-28.27-20.92-48.92-31.54-83.28-31.54c-34.76,0-55.34,10.63-83.34,31.59c-22.34,16.84-33.75,33.9-33.9,50.71
                        c-0.09,8.69,3.02,21.52,18.05,33.7c0.94,1.26,1.9,2.47,2.88,3.63c-0.01,0.17-0.03,0.34-0.03,0.51
                        c0,38.11,13.55,92.51,43.29,121.91c1.11,1.1,2.24,2.15,3.39,3.16c0.94,15.74,0.86,27.33-5.12,33.1c-1.41,1.35-2.91,2.52-4.49,3.56
                        c-0.7,0.31-1.37,0.72-1.97,1.21c-8.35,4.75-18.65,6.15-29.42,7.59C69.09,333,50,335.56,36.98,352.4
                        c-22.92,30-36.05,75.94-36.05,126.04c0,4.72,3.84,8.55,8.57,8.55H147.6c0.07,0,0.15,0.01,0.22,0.01c0.05,0,0.09-0.01,0.14-0.01
                        h56.97c0.05,0,0.09,0.01,0.14,0.01c0.07,0,0.15-0.01,0.22-0.01h138.11c4.74,0,8.57-3.82,8.57-8.55
                        c0-50.09-13.14-96.03-36.07-126.07C302.9,335.56,283.9,333,267.12,330.75z M231.99,339.45c3.3,1.64,6.7,2.92,10.14,3.94
                        l-38.43,29.3l-12.56-12.12L231.99,339.45z M176.59,348.83l-40.42-20.79c6.82-8.33,8.43-19.24,8.41-30.78
                        c9.71,4.75,20.2,7.14,31.39,7.14c0.24,0,0.48-0.01,0.72-0.01c11.86-0.11,22.4-2.8,31.74-7.41c-0.14,11.39,1.42,22.55,8.49,31
                        L176.59,348.83z M186.2,379.61l-9.64,11.59l-9.75-11.57l9.77-9.32L186.2,379.61z M76.5,122.53c0.1-11.06,9.47-23.94,27.07-37.2
                        c26.01-19.46,42.73-28.14,73.03-28.14c29.94,0,46.74,8.69,72.98,28.1c17.48,13.29,26.77,26.16,26.85,37.22
                        c0.04,5.89-2.65,11.56-8,16.93c-27.51-16.53-57.62-24.58-91.83-24.58c-34.39,0-64.6,8.05-92.13,24.58
                        C79.13,134.07,76.45,128.4,76.5,122.53z M176.6,131.96c30.08,0,55.67,6.45,79.72,20.2c-7.35,7.3-17.68,13.09-31.16,10.42
                        c-3.34-0.71-6.76-1.55-10.28-2.41c-10.97-2.68-23.41-5.72-38.01-6.19c-0.09,0-0.19,0.02-0.28,0.02c-0.09,0-0.17-0.02-0.26-0.02
                        c-14.87,0.46-27.37,3.51-38.4,6.21c-3.5,0.85-6.89,1.68-10.1,2.37c-13.51,2.67-23.9-3.09-31.25-10.39
                        C120.67,138.41,146.35,131.96,176.6,131.96z M98.05,174.46c7.19,3.86,14.93,5.87,23.02,5.87c3.36,0,6.78-0.34,10.23-1.03
                        c3.48-0.74,7.04-1.6,10.71-2.5c10.63-2.59,21.63-5.27,34.59-5.71c12.7,0.43,23.64,3.1,34.21,5.69c3.7,0.9,7.28,1.78,10.89,2.54
                        c3.43,0.68,6.82,1.02,10.15,1.02c8.05,0,15.76-1.99,22.93-5.84c-4.03,43.05-25.54,112.32-78.26,112.8
                        c-15.36-0.18-29.12-5.7-40.89-17.33C113.73,248.34,100.95,208.13,98.05,174.46z M121.06,339.51l40.93,21.05l-12.74,12.14
                        l-38.24-29.31C114.42,342.38,117.79,341.12,121.06,339.51z M141.96,469.89H78.52v-32.61c0-4.72-3.83-8.55-8.57-8.55
                        c-4.74,0-8.57,3.82-8.57,8.55v32.61H18.2c1.36-43.03,13-81.72,32.38-107.09c8.69-11.23,22.11-13.03,37.65-15.12
                        c0.07-0.01,0.14-0.02,0.21-0.03l56.2,43.07c1.55,1.19,3.39,1.77,5.23,1.77c1.52,0,3.04-0.41,4.38-1.21l12.53,14.87L141.96,469.89z
                        M160.35,469.89l16.21-41.64l16.04,41.64H160.35z M291.54,469.89v-32.61c0-4.72-3.83-8.55-8.57-8.55c-4.74,0-8.57,3.82-8.57,8.55
                        v32.61h-63.43l-24.56-63.75l12.32-14.81c1.33,0.77,2.81,1.17,4.32,1.17c1.83,0,3.66-0.58,5.21-1.76l56.48-43.07
                        c0.03,0,0.06,0.01,0.1,0.01c15.46,2.08,28.8,3.87,37.47,15.08c19.4,25.41,31.04,64.1,32.4,107.12H291.54z M278.28,268.31
                        c-14.1-6.2-30.65,0.18-36.84,14.15c-3.06,6.74-3.25,14.59-0.5,21.52c2.76,6.94,8.06,12.32,14.64,15.01
                        c6.4,2.91,23.03,6.81,37.05,6.81c5.49,0,10.57-0.6,14.49-2.09c5.61-2.13,8.13-5.62,9.26-8.18c8.63-19.5-33-44.9-38-47.17
                        C278.35,268.34,278.31,268.33,278.28,268.31z M299.75,308.2c-7.42,1.88-28.95-1.09-37.35-4.9c-2.52-1.04-4.49-3.02-5.51-5.62
                        c-1.05-2.64-0.99-5.61,0.2-8.23c1.8-4.06,5.68-6.44,9.81-6.44c1.46,0,2.97,0.3,4.43,0.94
                        C279.78,287.81,296.16,301.55,299.75,308.2z M502.67,282.55c-0.58-1.32-1.26-2.58-2.03-3.78c5.69-4.31,9.44-11.54,9.44-19.72
                        c0-13.19-9.71-23.92-21.66-23.92h-17.51l-0.64-1.77c-7.64-19.76-21.17-33.81-37.94-40.19v-1.29h-3.81
                        c-3.96-1.2-8.07-1.99-12.31-2.34v-6.73c0-13.02-10.62-23.62-23.68-23.62h-36.33c-13.05,0-23.68,10.6-23.68,23.62v6.53
                        c-26.13,0.18-48.61,17.04-58.68,43.91l-2.9,7.24c-1.76,4.39,0.38,9.36,4.78,11.11c4.37,1.76,9.38-0.38,11.14-4.76l2.97-7.43
                        c7.74-20.65,23.86-32.98,43.12-32.98h78.17c19.01,0,35.14,12.36,43.08,32.89l2.61,7.24c0.02,0.06,0.05,0.11,0.08,0.17
                        c0.14,0.35,0.29,0.7,0.47,1.03c0.04,0.07,0.08,0.13,0.12,0.2c0.16,0.27,0.33,0.53,0.52,0.78c0.07,0.09,0.13,0.19,0.21,0.27
                        c0.22,0.28,0.46,0.54,0.72,0.79c0.07,0.07,0.15,0.14,0.22,0.2c0.25,0.22,0.5,0.43,0.78,0.63c0.04,0.03,0.08,0.06,0.12,0.09
                        c0.32,0.22,0.66,0.41,1.01,0.59c0.06,0.03,0.11,0.05,0.17,0.08c0.7,0.33,1.46,0.57,2.25,0.71c0.08,0.01,0.16,0.02,0.24,0.04
                        c0.39,0.05,0.78,0.09,1.18,0.09h23.54c2.13,0,4.52,2.91,4.52,6.81c0,3.89-2.38,6.81-4.52,6.81h-11.91c-0.44,0-0.86,0.04-1.28,0.11
                        c-3.24,0.22-6.44,1.01-9.48,2.38c-6.41,2.82-16.72,10.16-24.55,17.46c-12.7,11.83-17.12,21.56-13.59,29.52
                        c1.06,2.56,3.47,6.07,8.91,8.26c4.01,1.61,9.24,2.25,14.85,2.25c13.36,0,28.93-3.61,35.81-6.3c-0.3,8.48-1.94,18.03-4.06,23.42
                        h-146.4c-4.74,0-8.57,3.82-8.57,8.55c0,4.72,3.84,8.55,8.57,8.55h94.02v12.9c0,11.1,9.06,20.14,20.19,20.14h25.57
                        c11.13,0,20.19-9.04,20.19-20.14v-18.91c6.83-11.89,9.28-36.53,6.34-50.12c0.03-0.06,0.05-0.12,0.08-0.19
                        C505.85,296.72,505.68,289.17,502.67,282.55z M399.07,189.34h-49.41v-6.52c0-3.66,2.87-6.52,6.54-6.52h36.33
                        c3.67,0,6.54,2.86,6.54,6.52V189.34z M479.56,372.94c0,1.65-1.4,3.05-3.05,3.05h-25.57c-1.65,0-3.05-1.4-3.05-3.05v-12.9h31.68
                        V372.94z M487.14,297.51c-1.03,2.64-3.05,4.77-5.67,5.88c-8.47,3.71-29.82,6.67-37.2,4.84c3.47-6.71,19.97-20.54,28.44-24.27
                        c0.03-0.01,0.05-0.02,0.08-0.03c2.55-1.17,5.46-1.24,8.13-0.2c2.73,1.04,4.89,3.07,6.09,5.8
                        C488.14,292.01,488.19,294.84,487.14,297.51z M443.82,263.68c0-4.72-3.83-8.55-8.57-8.55H308.54c-4.74,0-8.57,3.82-8.57,8.55
                        c0,4.72,3.83,8.55,8.57,8.55h126.71C439.98,272.23,443.82,268.4,443.82,263.68z M338.76,293.4c-4.74,0-8.57,3.82-8.57,8.55
                        c0,4.72,3.83,8.55,8.57,8.55h66.26c4.74,0,8.57-3.82,8.57-8.55c0-4.72-3.83-8.55-8.57-8.55H338.76z M399.9,91.9l20.92,26.97
                        c1.22,1.57,3.1,2.49,5.09,2.49c1.99,0,3.87-0.92,5.08-2.49L451.9,91.9c0.87-0.79,1.7-1.62,2.5-2.49
                        c14.36-15.7,13.24-40.03-2.5-54.35c-15.74-14.32-40.14-13.21-54.5,2.49C383.04,53.24,384.16,77.57,399.9,91.9z M425.9,37.99
                        c14.2,0,25.72,11.48,25.72,25.65c0,7.32-3.18,14.28-8.72,19.08c-0.3,0.27-0.57,0.56-0.81,0.87l-16.18,20.87L409.71,83.6
                        c-0.24-0.31-0.52-0.61-0.81-0.87c-5.54-4.8-8.72-11.76-8.73-19.08C400.18,49.48,411.69,37.99,425.9,37.99z M425.9,82.88
                        c10.65,0,19.29-8.61,19.29-19.24c0-10.63-8.64-19.24-19.29-19.24c-10.66,0-19.29,8.61-19.29,19.24
                        C406.61,74.27,415.24,82.88,425.9,82.88z M425.9,57.23c3.55,0,6.43,2.87,6.43,6.41c0,3.54-2.88,6.41-6.43,6.41
                        c-3.55,0-6.43-2.87-6.43-6.41C419.47,60.1,422.35,57.23,425.9,57.23z M419.47,179.06h12.86v-12.83h-12.86V179.06z M432.33,140.59
                        h-12.86v12.83h12.86V140.59z M374.46,31.58H361.6v12.82h12.86V31.58z M348.74,31.58h-12.86v12.82h12.86V31.58z M323.02,31.58
                        h-12.86v12.82h12.86V31.58z M290.87,31.58h-12.86v12.82h12.86V31.58z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Real Time Requests',
					text: 'All ride requests are populated on a real-time basis and forwarded to nearby drivers tracked using GPS.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M90.4,0v511h331.2V0H90.4z M405.13,494.5H106.97v-49.49h298.15V494.5z M405.13,428.51H106.97V82.38h298.15V428.51z
                        M405.13,65.99H106.97V16.5h298.15V65.99z M289.1,32.99h-66.2v16.5h66.2V32.99z M297.39,461.51h-82.77v16.5h82.77V461.51z
                        M206.43,32.99h-16.58v16.5h16.58V32.99z M173.27,32.99H156.7v16.5h16.57V32.99z M256.05,296.74c54.78,0,99.35-44.36,99.35-98.88
                        c0-54.52-44.57-98.88-99.35-98.88c-54.78,0-99.35,44.36-99.35,98.88C156.7,252.38,201.27,296.74,256.05,296.74z M256.05,280.14
                        c-18.7,0-35.78-6.13-49.73-16.6c0.1-27.16,22.34-49.29,49.73-49.29c27.39,0,49.62,22.13,49.73,49.29
                        C291.93,273.91,274.75,280.14,256.05,280.14z M239.47,173.12c0-9.15,7.48-16.5,16.58-16.5c9.09,0,16.57,7.34,16.57,16.5
                        c0,9.15-7.48,16.5-16.57,16.5C246.95,189.61,239.47,182.27,239.47,173.12z M256.05,115.38c45.68,0,82.77,37.02,82.77,82.38
                        c0,19.41-6.87,37.32-18.19,51.5c-5.26-23.44-23.14-42.15-46.19-48.79c8.9-5.94,14.65-15.99,14.65-27.36
                        c0-18.21-14.86-32.99-33.15-32.99c-18.29,0-33.15,14.79-33.15,32.99c0,11.37,5.86,21.43,14.65,27.36
                        c-23.04,6.64-40.83,25.35-46.19,48.79c-11.42-14.08-18.19-31.99-18.19-51.5C173.27,152.39,210.37,115.38,256.05,115.38z
                        M177.42,412.12c29.71,0,53.87-24.04,53.87-53.61c0-29.57-24.15-53.62-53.87-53.62c-29.71,0-53.87,24.04-53.87,53.62
                        C123.55,388.08,147.71,412.12,177.42,412.12z M177.42,321.39c20.52,0,37.29,16.6,37.29,37.12c0,20.42-16.68,37.12-37.29,37.12
                        c-20.62,0-37.3-16.6-37.3-37.12C140.12,338.08,156.8,321.39,177.42,321.39z M204.71,342.91l-13.24-9.96l-19.1,25.35l-9.8-9.76
                        l-11.72,11.67l23.35,23.24L204.71,342.91z M280.91,358.5c0,29.57,24.16,53.61,53.87,53.61c29.71,0,53.87-24.04,53.87-53.61
                        c0-29.57-24.16-53.62-53.87-53.62C305.07,304.89,280.91,329.03,280.91,358.5z M371.98,358.5c0,20.42-16.68,37.12-37.29,37.12
                        c-20.62,0-37.19-16.6-37.19-37.12c0-20.42,16.68-37.12,37.29-37.12C355.4,321.39,371.98,338.08,371.98,358.5z M319.82,332.05
                        l-11.62,11.67l14.86,14.79l-14.86,14.79l11.62,11.67l14.86-14.79l14.86,14.79l11.72-11.67l-14.86-14.79l14.86-14.79l-11.72-11.67
                        l-14.86,14.79L319.82,332.05z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Accept/Reject',
					text: 'Drivers can choose to accept or reject a ride depending on their availability or interest in taking the ride.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M29.93,0c-5.96,0.56-11.02,6.16-10.99,12.17v486.67c0,6.37,5.78,12.17,12.13,12.17h351.82c6.35,0,12.13-5.8,12.13-12.17
                    v-97.52c1.29-0.97,2.73-1.83,3.98-2.85l72.41,72.43c4.48,4.62,12.8,4.66,17.32,0.07c4.52-4.59,4.4-12.93-0.26-17.37l-72.22-72.43
                    c17.12-20.98,27.3-47.85,27.3-76.99c0-39.64-19.15-74.72-48.53-96.95v-85.55c0.03-3.24-1.3-6.47-3.6-8.75L282.23,3.42
                    c-2.26-2.19-5.39-3.45-8.53-3.42C192.47,0,111.08,0,29.93,0z M43.2,24.33h212.3v103.42c0,6.37,5.78,12.17,12.13,12.17h103.12v52.85
                    c-14.86-6.53-31.3-10.26-48.53-10.26c-36.7,0-69.66,16.64-91.94,42.58c-44.51,0-89.21,0-133.64,0c-6.33,0.32-11.81,6.38-11.51,12.73
                    c0.3,6.35,6.31,11.87,12.65,11.6h116.2c-6.62,13.06-10.99,27.36-12.51,42.58c-34.91,0-70,0-104.83,0
                    c-6.38,0.26-11.94,6.34-11.64,12.74c0.3,6.4,6.4,11.94,12.78,11.59h103.69c1.5,15.24,5.9,29.51,12.51,42.59
                    c-39.08,0-78.34,0-117.34,0c-6.35,0.3-11.86,6.37-11.56,12.74c0.3,6.37,6.35,11.9,12.7,11.6h132.31
                    c21.01,24.58,51.58,40.83,85.87,42.58c-73.08,0-146.32,0-219.32,0c-6.35,0.3-11.86,6.37-11.56,12.74c0.3,6.37,6.35,11.89,12.7,11.6
                    h218.37c6.41,0.09,12.3-5.74,12.3-12.17c0-6.43-5.89-12.26-12.3-12.17c2,0.1,4.04,0,6.07,0c17.23,0,33.67-3.55,48.53-10.08v70.91
                    H43.2V24.33z M279.77,35.36l79.99,80.22h-79.99V35.36z M322.23,206.83c53.74,0,97.05,43.43,97.05,97.33
                    c0,53.9-43.31,97.33-97.05,97.33s-97.05-43.43-97.05-97.33C225.18,250.27,268.49,206.83,322.23,206.83z M320.9,237.06
                    c-5.96,0.66-10.94,6.34-10.81,12.36v12.17c-0.09,6.43,5.72,12.34,12.13,12.34c6.41,0,12.22-5.91,12.13-12.34v-12.17
                    C334.51,242.52,327.75,236.31,320.9,237.06z M320.9,291.81c-5.96,0.66-10.94,6.34-10.81,12.36v54.75
                    c-0.09,6.43,5.72,12.34,12.13,12.34c6.41,0,12.22-5.91,12.13-12.34v-54.75C334.51,297.27,327.75,291.05,320.9,291.81z"
					></path>
				</svg>`,
					title: 'Trip Information',
					text: 'The driver gets complete trip information like passenger pickup location, drop location and estimated fare for the ride.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M259.12,237.05c-48.61,0-88.15-38.41-88.15-85.62s39.54-85.62,88.15-85.62c48.61,0,88.15,38.41,88.15,85.62
                    S307.73,237.05,259.12,237.05z M259.12,87.5c-36.29,0-65.81,28.68-65.81,63.92c0,35.25,29.52,63.92,65.81,63.92
                    c36.29,0,65.81-28.68,65.81-63.92C324.93,116.18,295.41,87.5,259.12,87.5z M256.34,473.86c-0.03,0-0.07,0-0.09,0
                    c-3.92-0.03-7.54-2.06-9.53-5.34c-4.23-6.98-104.02-172.06-133.34-269.48c-3.09-8.82-5.4-18.46-6.71-28.21
                    c-1.18-7.39-1.63-13.92-1.33-19.7C105.49,68.87,174.44,2,259.17,2C343.99,2,413,69.03,413,151.42l-0.03,1.38l-11.19,0.44l11.16-0.28
                    c0.1,3.82-0.12,8.02-0.65,12.46c-1.32,14.1-4.8,28.14-10.31,41.49c-33.35,98.28-131.94,255.12-136.12,261.76
                    C263.83,471.9,260.23,473.86,256.34,473.86z M259.17,23.7c-72.5,0-131.49,57.29-131.49,127.72c0,0.2,0,0.4-0.02,0.61
                    c-0.24,4.26,0.13,9.56,1.12,15.74c1.15,8.6,3.12,16.83,5.89,24.74c23.49,78.02,95.84,204.78,121.86,249.1
                    c26.17-42.87,97.92-163.68,124.34-241.8c0.08-0.23,0.16-0.45,0.26-0.67c4.77-11.49,7.78-23.58,8.95-35.95
                    c0.44-3.7,0.61-6.86,0.54-9.67c-0.01-0.12-0.01-0.24-0.01-0.37l0.05-1.72C390.65,81,331.67,23.7,259.17,23.7z M407.41,463.01
                    c0-30.47-59.95-41.88-95.69-45.96c-6.09-0.75-11.67,3.55-12.4,9.51c-0.72,5.95,3.66,11.34,9.79,12.05
                    c57.76,6.61,75.96,20.95,75.96,24.41c0,7.92-45.11,27.29-128.73,27.29c-83.62,0-128.73-19.36-128.73-27.29
                    c0-3.5,18.4-17.95,76.8-24.5c6.13-0.69,10.52-6.07,9.81-12.02c-0.71-5.96-6.25-10.27-12.38-9.54
                    c-29.04,3.25-96.59,14.26-96.59,46.06C105.27,496.67,183.58,512,256.34,512C329.1,512,407.41,496.67,407.41,463.01z"
					></path>
				</svg>`,
					title: 'Navigation',
					text: 'GPS-enabled in-built navigation to guide the driver to the passenger drop location through the shortest and quickest route possible.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M248.1,33.25h48.2v-9.72h-48.2V33.25z M271.7,359.09c-6.53,0-12.05,5.62-12.05,12.27c0,6.64,5.52,12.28,12.05,12.28
                        c6.52,0,12.05-5.63,12.05-12.28C283.76,364.71,278.23,359.09,271.7,359.09z M403.26,306.39l-8.54-7.16l11.05-13.8
                        c8.54-10.23,7.53-25.57-3.01-34.27l-10.04-8.7c-0.5-0.51-0.5-0.51-1-1.02l13.56-16.37c8.53-10.23,7.53-25.57-3.01-34.27
                        l-10.04-8.7c-3.01-2.55-7.03-4.59-11.05-5.11V23.53c0-13.3-10.55-23.53-23.1-23.53H185.84c-13.05,0-23.1,10.74-23.1,23.53v109.46
                        c-4.02-4.09-9.54-8.18-16.07-10.74c-12.05-5.12-28.12-1.53-38.16,8.18c-8.53,8.18-10.54,19.43-6.52,31.21
                        c2.51,6.64,5.02,13.8,6.52,21.49c8.03,33.25,9.54,69.56,4.52,97.19l-1.51,8.18c-7.03,38.87-13.56,75.19-2.01,102.82
                        c10.04,24.03,23.1,36.32,34.65,47.05l1,1.03c2.51,2.04,3.01,5.62,2.01,8.18l-10.55,28.14l146.62,35.29l8.04-20.45
                        c1-2.57,3.01-4.1,5.52-4.61c7.53-1.02,19.08-5.12,31.63-17.9c15.57-16.38,29.63-45.53,35.15-57.8c2.01,0.52,4.02,1.03,6.53,1.03
                        c7.03,0,13.56-3.08,18.08-8.7l10.54-12.78c4.02-5.12,6.03-11.25,5.52-17.91c-0.5-6.65-3.52-12.28-8.54-16.37l-1.01-1.02
                        l11.55-13.81C414.81,330.44,413.81,315.1,403.26,306.39z M393.22,262.41c4.52,3.58,5.02,9.72,1.5,13.81l-13.05,15.86l-6.03,7.15
                        c-1,1.03-2.01,1.53-3.51,2.57l-1.51,0.51h-1.51l-4.52-1.54c-1.01-0.51-2.01-1.02-2.51-1.53l-10.04-8.69
                        c-2.01-1.53-3.01-4.09-3.52-6.65c0-2.04,1-4.09,2.01-6.14l17.58-20.97c1,0,2.01,0,3.01-0.51h1.51c1.51-0.51,3.01-1.02,4.52-1.53
                        c1.5-0.51,3.01-1.53,4.02-2.04c0.5,0.51,1,0.51,2.01,1.02L393.22,262.41z M375.14,191.3h1.5c2.01,0,4.52,1.03,6.03,2.05
                        l11.05,8.69c4.02,3.58,4.52,9.72,1,13.3l-19.08,23.01l-0.5,0.52c-1.01,1.02-2.01,1.54-3.01,2.04c-0.5,0-0.5,0-1,0.52l-4.52-0.52
                        c-1.5-0.5-3.01-1.02-4.52-2.04l-10.04-8.7c-2.01-1.54-3.01-4.09-3.52-6.64c0-2.05,0.5-4.09,2.01-6.14l19.08-23.01
                        C371.13,192.84,373.13,191.3,375.14,191.3z M176.8,23.53c0-4.6,4.02-8.7,9.04-8.7h173.23c5.02,0,8.54,4.1,8.54,8.7v17.38H176.8
                        V23.53z M176.8,56.26h190.81V177.5c-3.01,1.52-6.02,3.57-8.54,6.64l-13.56,16.37c-1-9.21-8.03-15.86-17.07-15.86h-3.51
                        l-11.05-15.35c-4.02-5.62-10.04-8.68-16.57-8.68h-29.62c-5.52,0-10.55,2.03-14.56,6.13l-17.58,17.9h-20.08
                        c-9.54,0-17.58,8.19-17.58,17.91v15.35h16.07c0-0.52,0-1.03,0-1.54c0-9.22,7.53-16.88,16.57-16.88c9.04,0,16.57,7.66,16.57,16.88
                        c0,0.5,0,1.02,0,1.54h49.71c0-0.52,0-1.03,0-1.54c0-9.22,7.53-16.88,16.57-16.88c9.04,0,16.57,7.66,16.57,16.88
                        c0,0.5,0,1.02,0,1.54h5.52c-0.5,2.55-0.5,4.6-0.5,7.16c0.5,6.65,3.01,12.79,8.53,17.39l10.04,8.7c0,0,0.5,0,0.5,0.51L341,267.52
                        c-4.02,5.12-6.03,11.26-5.52,17.91c0,0.51,0,0.51,0,1.02c0.5,6.64,3.52,12.28,8.53,16.36l8.03,7.16L341,322.77
                        c-4.02,4.6-5.52,10.22-5.52,16.36H176.8V56.26z M317.4,458.32c-9.54,9.72-17.57,12.78-23.1,13.82
                        c-7.53,1.02-14.06,6.13-17.07,13.8l-3.01,8.7l-118-29.16l4.52-12.28c3.01-8.7,1.01-18.42-5.52-24.56l-1.01-1.02
                        c-11.05-10.23-22.09-20.97-31.13-41.94c-10.04-23.54-3.52-58.32,3.01-94.64l1.51-8.18c5.53-29.67,3.52-68.55-4.52-103.33
                        c-2.01-8.19-4.52-15.86-7.53-23.02c-2.01-6.14-1-11.25,3.01-15.34c5.52-5.63,15.57-8.2,22.09-5.12
                        c19.58,8.7,21.59,27.62,21.59,29.66v219.45c0,13.29,10.55,23.53,23.1,23.53h162.69C342,421.49,329.95,445.02,317.4,458.32z
                        M348.53,368.29c-4.02,5.12-6.02,11.26-5.52,17.9c0,0.52,0,0.52,0,1.02c0,2.05,1,4.61,1.51,6.65H185.84
                        c-5.02,0-8.53-4.08-8.53-8.69v-31.21h161.18c1,1.53,2.51,3.08,4.02,4.6l8.03,7.17L348.53,368.29z M387.19,380.57l-10.55,12.79
                        c-2.01,2.55-5.02,3.59-8.03,3.59l-4.02-2.56c-0.5-0.52-1.01-0.52-1.51-1.03l-2.51-2.04c-2.01-1.53-3.01-4.1-3.52-6.65
                        c0-2.05,1-4.1,2.01-6.14l5.02-6.14h3.01c0,0,0,0,0.5,0h2.51c1.51,0,2.51-0.52,4.02-0.52c0.5,0,1.01-0.51,1.01-0.51
                        c1,0,1.5-0.52,2.01-0.52c0.5,0,1-0.5,1.5-0.5c0.5,0,1-0.52,1-0.52c1-0.51,2.01-1.54,3.01-2.05c0.5,0,0.5-0.51,1-0.51
                        c0.5-0.51,0.5-0.51,1-1.02l1,1.02c2.01,1.54,3.01,4.09,3.52,6.65C389.7,375.97,389.2,378.53,387.19,380.57z M394.72,331.46
                        l-13.05,15.86l-6.03,7.16c-0.5,1.02-1.51,1.53-3.01,2.04l-0.5,0.52c0,0-0.5,0-1,0.51c-0.5,0-1,0.51-1.51,0.51l-4.52-2.04
                        c-1.01-0.52-2.01-1.02-2.51-1.53l-10.04-8.7c-2.01-1.53-3.01-4.09-3.51-6.65c0-2.04,0.5-4.08,2.01-6.14l13.56-16.36h3.01
                        c0,0,0,0,0.5,0h1.51c0.5,0,1,0,1,0c1.51,0,2.51-0.52,3.52-0.52c0.5,0,0.5,0,1.01,0c0.5,0,1-0.5,1.5-0.5c0.5,0,1.01-0.51,1.01-0.51
                        c0.5,0,1-0.51,1-0.51c1.51-0.51,2.51-1.53,3.52-2.56c0.5-0.51,0.5-0.51,1.01-1.02c0.5-0.52,1-0.52,1.51-1.03l8.54,7.67
                        C397.74,321.24,398.24,327.37,394.72,331.46z M313.38,203.58c-6.93,0-12.55,5.73-12.55,12.79c0,7.06,5.62,12.79,12.55,12.79
                        c6.93,0,12.56-5.73,12.56-12.79C325.94,209.31,320.31,203.58,313.38,203.58z M231.03,229.16c6.93,0,12.55-5.73,12.55-12.79
                        c0-7.06-5.62-12.79-12.55-12.79c-6.93,0-12.55,5.73-12.55,12.79C218.48,223.43,224.1,229.16,231.03,229.16z M227.16,299.2
                        c1.97,0,3.54,0.48,4.68,1l1.13-4.8c-0.98-0.55-3.18-1.18-6.06-1.18c-7.46,0-13.45,5.07-13.45,14.19c0,7.6,4.38,13.35,12.9,13.35
                        c3,0,5.3-0.59,6.32-1.14l-0.84-4.71c-1.1,0.47-2.96,0.86-4.64,0.86c-4.97,0-7.89-3.37-7.89-8.72
                        C219.3,302.09,222.74,299.2,227.16,299.2z M250.71,309.9c0-4.55-1.9-8.35-7.85-8.35c-3.25,0-5.7,0.95-6.94,1.7l1.02,3.88
                        c1.17-0.8,3.11-1.47,4.93-1.47c2.74,0,3.25,1.47,3.25,2.5v0.28c-6.32-0.04-10.49,2.38-10.49,7.41c0,3.09,2.15,5.94,5.77,5.94
                        c2.12,0,3.95-0.83,5.12-2.38h0.11l0.33,1.95h5.01c-0.18-1.08-0.26-2.86-0.26-4.73V309.9z M245.3,314.31
                        c0,0.36-0.04,0.71-0.11,1.03c-0.37,1.23-1.5,2.22-2.89,2.22c-1.24,0-2.19-0.75-2.19-2.3c0-2.34,2.26-3.09,5.19-3.05V314.31z
                        M265.67,301.55c-2.96,0-4.78,1.86-5.52,3.09h-0.11l-0.25-2.66h-4.83c0.07,1.71,0.15,3.77,0.15,6.19v13.21h5.56v-11.18
                        c0-0.56,0.07-1.16,0.22-1.51c0.36-1.11,1.28-2.26,2.81-2.26c2.01,0,2.81,1.71,2.81,4.2v10.76h5.56v-11.5
                        C272.07,304.16,269.32,301.55,265.67,301.55z M286,306.18c1.46,0,2.45,0.28,3.1,0.6l0.88-4.48c-1.17-0.47-2.78-0.75-4.24-0.75
                        c-6.65,0-10.23,4.63-10.23,10.29c0,6.11,3.69,9.91,9.47,9.91c2.12,0,3.98-0.38,4.97-0.87l-0.66-4.43c-0.84,0.36-1.86,0.6-3.18,0.6
                        c-2.7,0-4.9-1.87-4.9-5.43C281.18,308.44,283.08,306.18,286,306.18z M300.63,301.55c-6.21,0-9.03,5.43-9.03,10.33
                        c0,6.07,3.47,9.87,9.54,9.87c2.41,0,4.64-0.38,6.47-1.19l-0.73-4.08c-1.5,0.52-3.04,0.79-4.93,0.79c-2.6,0-4.86-1.19-5.04-3.69
                        h11.51c0.07-0.44,0.18-1.34,0.18-2.38C308.59,306.41,306.4,301.55,300.63,301.55z M296.86,309.47c0.15-1.62,1.1-3.92,3.44-3.92
                        c2.56,0,3.14,2.45,3.14,3.92H296.86z M311.93,321.37h5.56v-28.14h-5.56V321.37z M268.11,132.53l11.68-18.57l10.69,18.57h17.53
                        l-18.12-29.12l16.93-27.65h-16.64l-10.4,17.2l-9.8-17.2h-17.43l17.13,27.76l-18.42,29.01H268.11z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Cancel Booking',
					text: 'Not all bookings get through the first time. The driver can use the option to cancel a confirmed booking, if required.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M506.17,369.82l-32.63-47c-9.32-13.43-28.64-16.79-41.96-7.39l-7.99,5.37c-4.66,3.36-10.66,2.01-13.99-2.69l-53.28-79.23
                        c-2-2.01-2-4.7-2-7.39c0-2.69,2-5.37,3.99-6.71l7.99-5.37c13.32-9.4,16.65-28.87,7.33-42.3l-31.97-46.33
                        c-9.32-13.43-27.97-16.79-41.95-7.38l-25.97,18.8c-26.64,18.8-33.3,55.06-14.65,81.92l132.52,192.04
                        c8.66,12.76,21.98,21.49,37.29,24.17c3.99,0.67,7.33,0.67,10.66,0.67c11.99,0,23.31-3.36,32.63-10.07l25.97-18.8
                        c6.66-4.7,11.32-11.41,12.65-19.47C512.83,384.59,510.83,376.54,506.17,369.82z M490.86,389.29c0,2.69-2,5.37-3.99,6.71
                        l-25.97,18.8c-8.66,5.37-18.65,8.06-28.64,6.04c-9.99-2.01-18.65-7.39-24.64-16.11L275.09,212.7c-5.99-8.73-7.99-18.8-5.99-27.53
                        c2-10.07,7.32-18.8,15.98-24.84l25.97-18.8c4.66-3.36,10.66-2.01,13.99,2.69L357,190.54c3.33,4.7,2,10.74-2.66,14.1l-7.99,5.37
                        c-6.66,4.7-11.32,11.41-12.65,19.47c-2,8.06,0,16.12,4.66,22.83l53.94,77.89c9.32,13.43,28.64,16.79,41.95,7.39l7.99-5.37
                        c4.66-3.36,10.66-2.01,13.99,2.69l32.63,47C490.86,383.92,491.52,386.61,490.86,389.29z M365.66,127.42
                        c7.33-1.34,13.99,1.34,17.98,7.39c4,5.37,4,12.76,0.67,18.8c-3.33,5.37-1.33,11.41,3.33,14.1c2,0.67,3.33,1.34,5.33,1.34
                        c3.33,0,6.66-2.01,7.99-6.04c7.32-12.09,6.66-28.2-1.33-40.29c-7.99-12.76-23.31-18.8-37.96-15.44
                        c-5.99,1.34-9.32,6.72-7.99,12.09C355.01,125.41,360.33,128.77,365.66,127.42z M366.99,92.51c18.65-4.7,37.96,3.36,49.28,19.47
                        c10.66,15.44,11.32,36.26,2,52.37c-3.33,5.37-1.33,11.42,3.33,14.1c2,0.67,3.33,1.34,5.33,1.34c3.33,0,6.66-1.34,8.66-4.7
                        c13.32-23.5,12.65-52.38-2.67-74.53C417.6,77.73,389.63,66.99,363,72.36c-5.99,1.34-9.32,6.71-7.99,12.09
                        C356.34,90.49,361.66,93.85,366.99,92.51z M172.12,268.29c16.83-12.59,27.72-32.77,27.72-55.59c0-38.27-30.63-69.16-68.59-69.16
                        c-37.96,0-68.59,30.89-68.59,69.16c0,22.82,10.89,43,27.72,55.59C37.67,285.71-0.61,335.85-0.61,394.67v35.59
                        c0,5.37,4.66,10.07,9.99,10.07h243.74c5.33,0,9.99-4.7,9.99-10.07v-35.59C263.1,335.85,224.82,285.71,172.12,268.29z
                        M82.64,212.03c0-26.86,21.98-49.02,48.61-49.02c27.3,0,48.61,22.16,48.61,49.02c0,26.86-21.98,49.02-48.61,49.02
                        C104.61,261.05,82.64,238.89,82.64,212.03z M243.13,420.18H19.37v-25.51c0-62.45,49.95-112.81,111.88-112.81
                        c61.93,0,111.88,50.36,111.88,112.81V420.18z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Contact Passenger',
					text: 'Cab drivers can contact passengers straight from the app when they arrive at the destination or to intimate ETA.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M169.39,336.97c0-45.69-37.7-82.73-84.21-82.73S0.98,291.28,0.98,336.97c0,42.72,69.33,130.97,77.17,140.08l6.95,8.76
                        l6.95-8.76C100.07,467.77,169.39,379.52,169.39,336.97z M19.34,336.97c0-36.02,29.72-65.22,66.38-65.22s66.38,29.2,66.38,65.22
                        c-0.53,27.84-43.66,90.53-66.83,120.99C61.75,427.5,19.34,364.81,19.34,336.97z M85.28,299.15c-21.65,0-39.21,17.25-39.21,38.52
                        c0,21.27,17.55,38.52,39.21,38.52c21.65,0,39.21-17.25,39.21-38.52C124.38,316.44,106.89,299.25,85.28,299.15z M85.28,358.86
                        c-11.81,0-21.39-9.41-21.39-21.01c0-11.6,9.57-21.01,21.39-21.01c11.81,0,21.39,9.41,21.39,21.01
                        C106.56,349.42,97.05,358.77,85.28,358.86z M426.91,231.57l6.95-8.75c7.84-9.98,77.17-98.23,77.17-140.08
                        c0-45.69-37.7-82.73-84.2-82.73c-46.51,0-84.21,37.04-84.21,82.73c0,42.72,69.32,130.97,77.17,140.08L426.91,231.57z
                        M426.91,16.72c36.64,0.1,66.3,29.31,66.3,65.31c0,27.84-42.95,90.18-66.3,120.99c-23.52-30.82-66.47-93.15-66.47-120.99
                        C360.44,45.96,390.2,16.72,426.91,16.72z M466.12,82.73c0-21.27-17.55-38.52-39.21-38.52c-21.65,0-39.21,17.25-39.21,38.52
                        c0,21.27,17.55,38.52,39.21,38.52C448.52,121.16,466.02,103.97,466.12,82.73z M405.35,82.73c0-11.6,9.57-21.01,21.38-21.01
                        c11.81,0,21.39,9.41,21.39,21.01c0,11.54-9.47,20.91-21.21,21.01C415.07,103.75,405.44,94.37,405.35,82.73z M380.04,256.78
                        c-17.82-3.15-39.74-6.83-57.74,10.86c-18,17.68-14.26,38.35-11.05,56.73c3.21,18.38,4.81,30.64-6.06,41.32
                        c-10.87,10.68-22.99,9.11-42.06,5.95s-39.74-6.65-57.74,10.86c-18,17.51-14.26,38.35-11.05,56.73
                        c3.21,18.39,4.63,30.82-6.06,41.32c-10.69,10.51-22.99,9.28-42.06,5.95c-19.07-3.33-39.74-6.48-57.56,11.03l12.65,12.43
                        c10.87-10.68,22.99-9.1,42.06-5.95c19.07,3.15,39.74,6.65,57.74-10.86c18-17.51,14.26-38.35,11.05-56.73
                        c-3.21-18.38-4.63-30.82,6.06-41.32c10.69-10.51,22.99-9.1,42.06-5.95s39.74,6.65,57.74-10.86c18-17.51,14.26-38.35,11.05-56.73
                        c-3.21-18.39-4.81-30.64,6.06-41.32c10.87-10.68,22.99-9.11,42.06-5.95s39.74,6.65,57.74-10.86L422.28,251
                        C411.41,261.51,399.11,259.93,380.04,256.78z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Start/End Trip',
					text: 'Once the passenger is picked up the driver can start the end trip using OTP or swipe gestures on the app screen.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M277.19,495.04l-4.14-5.17H158.29H43.52l-4.14-2.07c-6.2-3.1-10.34-8.27-12.41-15.51c-1.03-4.14-1.03-428.09,0-432.23
                    c1.03-6.2,6.2-12.41,12.41-15.51l3.1-2.07h174.74h174.74l4.14,2.07c6.2,3.1,10.34,8.27,12.41,15.51c0,2.07,1.03,38.26,1.03,106.51
                    v104.44h4.14c2.07,0,7.24,1.03,10.34,1.03c3.1,1.03,6.2,1.03,6.2,1.03c1.03,0,1.03-15.51,1.03-103.4c0-64.11,0-105.47-1.03-109.61
                    c0-3.1-1.03-7.24-2.07-9.31c-5.17-15.51-17.58-26.88-34.12-29.99c-6.2-1.03-346.38-1.03-352.58,0C31.11,2.84,20.77,9.05,14.57,18.35
                    C8.36,25.59,6.3,31.8,5.26,41.1c0,4.14,0,78.59,0,219.22v213.01l1.03,4.14c4.14,14.48,14.48,25.85,27.92,31.02
                    c2.07,1.03,6.2,2.07,8.27,2.07s41.36,0,126.14,0h122.01l-5.17-5.17C283.4,502.28,279.26,498.15,277.19,495.04z M310.28,141.4v-11.37
                    h-98.23h-98.23v11.37v11.37h98.23h98.23V141.4z M310.28,206.55v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V206.55z
                    M307.18,279.96l3.1-2.07v-8.27v-8.27h-98.23h-98.23v10.34v10.34h95.12h95.12L307.18,279.96z M503.63,377.16
                    c-7.24-31.02-28.95-57.91-57.9-72.38c-18.61-9.31-39.29-12.41-59.97-10.34c-6.2,1.03-16.54,3.1-22.75,5.17
                    c-7.24,2.07-19.64,8.27-25.85,12.41c-22.75,15.51-39.29,38.26-45.49,63.08c-2.07,9.31-3.1,16.54-3.1,26.89
                    c0,17.58,3.1,32.05,10.34,47.57c19.64,40.33,63.07,65.14,108.57,61.01c46.53-4.14,85.82-38.26,96.16-83.76
                    C507.77,412.32,507.77,393.71,503.63,377.16z M480.88,429.9c-10.34,29.99-35.16,52.74-67.21,57.91c-8.27,1.03-24.81,1.03-32.05,0
                    c-33.09-7.24-58.94-31.02-67.21-63.08c-2.07-8.27-3.1-13.44-3.1-22.75c0-9.31,1.03-14.48,3.1-22.75
                    c6.2-23.78,22.75-44.46,45.5-55.84c8.27-4.14,14.48-6.2,24.81-8.27c7.24-1.03,24.81-1.03,32.05,1.03
                    c8.27,2.07,13.44,4.14,21.71,7.24c19.64,9.31,34.12,25.85,41.36,45.5C486.05,389.57,487.09,410.25,480.88,429.9z M114.86,326.5
                    v10.34v10.34h70.31h70.31l2.07-5.17c1.03-3.1,3.1-8.27,5.17-10.34l3.1-5.17h-75.48H114.86z M408.51,391.64v-21.71v-21.71h-11.37
                    h-11.37v33.09v33.09h33.09h33.09v-10.34v-10.34h-21.71h-21.71V391.64z"
					></path>
				</svg>`,
					title: 'Trip Summary',
					text: 'A comprehensive trip summary comprising of information like a pickup, drop location, final fare, customer details, etc.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M255.49,306.18c65.89,0,119.31-52.44,119.31-117.13c0-64.69-53.42-117.13-119.31-117.13
                        c-65.89,0-119.31,52.44-119.31,117.13C136.25,253.71,189.63,306.12,255.49,306.18z M255.49,88.65
                        c56.48,0,102.27,44.95,102.27,100.4c0,55.45-45.79,100.4-102.27,100.4c-56.48,0-102.27-44.95-102.27-100.4
                        C153.29,133.63,199.04,88.71,255.49,88.65z M505.76,368.96c-3.25-5.51-7.8-10.16-13.28-13.56c-11.61-7.26-26.21-8.13-38.64-2.32
                        l-98.53,45.82c7.45-19.47-2.59-41.17-22.42-48.48c-4.31-1.59-8.88-2.4-13.48-2.4H216.33c-38.23-35.12-95.14-42.1-140.99-17.29
                        l-1.02,0.56H-0.17v16.73H68v142.23H-0.17v16.73h299.68c28.49-0.01,56.47-7.39,81.13-21.39l110.49-62.8
                        C510.31,411.89,516.86,387.79,505.76,368.96z M482.56,408.27l0.03,0.03l-110.49,62.8c-22.06,12.53-47.1,19.13-72.59,19.14H85.05
                        V344.59c40.17-20.93,89.54-13.75,121.81,17.71c1.6,1.57,3.76,2.45,6.02,2.45h106.53c11.77,0,21.3,9.37,21.3,20.92
                        s-9.54,20.92-21.3,20.92H212.88c-4.71,0-8.52,3.75-8.52,8.37s3.82,8.37,8.52,8.37h127.83c1.26-0.01,2.51-0.3,3.65-0.84
                        l116.75-54.31c11.52-5.37,25.29-0.56,30.76,10.74C497.04,389.59,492.99,402.37,482.56,408.27z M187.31,189.05
                        c0.04-36.95,30.54-66.89,68.18-66.93v-16.73c-47.05,0.05-85.17,37.48-85.22,83.67H187.31z M255.49,272.72
                        c47.04-0.05,85.17-37.48,85.22-83.67h-17.05c-0.04,36.95-30.54,66.89-68.18,66.93V272.72z M83.65,55.07
                        c13.89,2.28,24.77,12.96,27.08,26.6c0.78,4.56,5.18,7.62,9.82,6.85c3.58-0.59,6.38-3.34,6.98-6.85
                        c2.32-13.63,13.2-24.32,27.08-26.6c4.64-0.77,7.77-5.09,6.98-9.64c-0.6-3.51-3.41-6.26-6.98-6.86
                        c-13.89-2.28-24.77-12.96-27.08-26.6c-0.79-4.56-5.18-7.62-9.82-6.86c-3.58,0.59-6.38,3.34-6.98,6.86
                        c-2.32,13.63-13.2,24.32-27.08,26.6c-4.64,0.77-7.77,5.09-6.98,9.64C77.27,51.73,80.07,54.48,83.65,55.07z M119.14,34.87
                        c3.32,4.64,7.44,8.69,12.17,11.95c-4.73,3.26-8.85,7.3-12.17,11.95c-3.32-4.64-7.44-8.69-12.17-11.95
                        C111.7,43.56,115.81,39.52,119.14,34.87z M50.96,448.42c0-13.86-11.45-25.1-25.57-25.1c-14.12,0-25.57,11.24-25.57,25.1
                        s11.45,25.1,25.57,25.1C39.51,473.52,50.96,462.28,50.96,448.42z M16.87,448.42c0-4.62,3.82-8.37,8.52-8.37
                        c4.71,0,8.52,3.75,8.52,8.37s-3.82,8.37-8.52,8.37C20.69,456.78,16.87,453.04,16.87,448.42z M390.45,264.23
                        c13.89,2.28,24.77,12.96,27.08,26.6c0.79,4.56,5.18,7.62,9.82,6.86c3.58-0.59,6.38-3.34,6.98-6.86
                        c2.32-13.63,13.2-24.32,27.08-26.6c4.64-0.77,7.77-5.09,6.98-9.64c-0.61-3.51-3.41-6.26-6.98-6.86
                        c-13.89-2.28-24.77-12.96-27.08-26.6c-0.78-4.56-5.18-7.63-9.82-6.86c-3.58,0.59-6.38,3.34-6.98,6.86
                        c-2.32,13.63-13.2,24.32-27.08,26.6c-4.64,0.77-7.77,5.09-6.98,9.64C384.07,260.89,386.87,263.64,390.45,264.23z M425.93,244.04
                        c3.32,4.64,7.44,8.69,12.17,11.95c-4.73,3.26-8.85,7.3-12.17,11.95c-3.32-4.64-7.44-8.69-12.17-11.95
                        C418.49,252.72,422.61,248.68,425.93,244.04z M246.97,147.22c-14.12,0-25.57,11.24-25.57,25.1c0,13.86,11.45,25.1,25.57,25.1
                        h17.04c4.71,0,8.52,3.74,8.52,8.37c0,4.62-3.82,8.37-8.52,8.37h-34.09v16.73h17.04v8.37h17.04v-8.37
                        c14.12,0,25.57-11.24,25.57-25.1c0-13.86-11.45-25.1-25.57-25.1h-17.04c-4.71,0-8.52-3.75-8.52-8.37c0-4.62,3.82-8.37,8.52-8.37
                        h34.09v-16.73h-17.04v-8.37h-17.04V147.22z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Track Earnings',
					text: 'The driver can track the entire earnings on a daily, weekly or monthly basis from the app screen.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M255.5,1C115.08,1,1,115.08,1,255.5S115.08,510,255.5,510C395.92,510,510,395.92,510,255.5C510,115.08,395.92,1,255.5,1z
                    M255.5,24.14c127.92,0,231.36,103.45,231.36,231.36c0,127.91-103.45,231.36-231.36,231.36c-127.91,0-231.36-103.45-231.36-231.36
                    C24.14,127.58,127.59,24.14,255.5,24.14z M254.05,35.7c-5.69,0.62-10.43,6.03-10.3,11.75l0.18,40.13
                    c-0.02,6.07,5.5,11.61,11.57,11.61c6.07,0,11.59-5.54,11.57-11.61l-0.18-40.31C266.93,40.77,260.52,34.99,254.05,35.7z
                    M144.51,66.02c-4.25,3.52-6.12,10.19-3.24,15.05l20.24,34.89c3.08,5.25,10.65,7.23,15.91,4.16c5.25-3.08,7.23-10.65,4.16-15.91
                    l-20.24-34.7C155.8,63.76,151.04,62.78,144.51,66.02z M349.49,69.5l-20.06,34.89c-3.54,5.27-1.63,13.38,3.89,16.52
                    c5.52,3.13,13.47,0.61,16.18-5.13l20.06-34.89c2.68-7.78,0.57-11.97-5.01-16.23C358.06,61.97,353.37,65.72,349.49,69.5z M279,215.55
                    c-6.9-4.1-14.95-6.51-23.5-6.51c-25.42,0-46.27,20.85-46.27,46.27s20.85,46.27,46.27,46.27c25.42,0,46.27-20.85,46.27-46.27
                    c0-8.55-2.41-16.42-6.51-23.32l77.54-77.54c4.72-7.06,4.12-11.7-0.47-17.34c-5.76-3.72-10.16-2.45-15.42,0.66
                    C330.85,163.54,304.94,189.61,279,215.55z M429.93,141.44l-34.7,20.24c-5.91,2.64-8.54,10.79-5.29,16.38
                    c3.25,5.59,11.64,7.34,16.86,3.5l34.71-20.24c5.93-6.25,6.66-10.98,2.88-17.4C439.19,139.52,435.45,139.43,429.93,141.44z
                    M65.26,145.23c-3.97,6.39,0.57,12.63,4.07,16.46l34.89,19.88c5.25,3.03,12.79,1.01,15.82-4.25c3.03-5.25,1-12.79-4.25-15.82
                    l-34.89-19.88C74.49,138.32,69.67,141.44,65.26,145.23z M255.5,232.18c11.57,0.29,23.14,6.07,23.14,23.14
                    c0,12.91-10.22,23.14-23.14,23.14s-23.14-10.22-23.14-23.14C232.36,242.4,242.59,232.18,255.5,232.18z M87.4,243.93l-40.13,0.18
                    c-6.07,0-11.61,5.5-11.61,11.57s5.54,11.59,11.61,11.57l40.13-0.18c6.07,0,11.61-5.5,11.61-11.57
                    C99.01,249.43,93.47,243.91,87.4,243.93z M463.73,243.93l-40.31,0.18c-6.06,0.06-11.53,5.6-11.48,11.66
                    c0.05,6.06,5.6,11.53,11.66,11.48l40.13-0.18c6.07,0,11.61-5.5,11.61-11.57C475.34,249.43,469.8,243.91,463.73,243.93z
                    M151.2,313.52c0,29.83,0,53.64,0,80.98c74.39,0,142.83,0,208.23,0c0-29.83,0-53.64,0-80.98
                    C287.48,313.52,213.03,313.52,151.2,313.52z M174.34,336.66h23.32v34.7h-23.32V336.66z M220.8,336.66h23.13v34.7H220.8V336.66z
                    M267.07,336.66h23.14v34.7h-23.14V336.66z M313.34,336.66h22.95v34.7h-22.95V336.66z"
					></path>
				</svg>`,
					title: 'Driver Dashboard',
					text: 'From completed trips to ongoing trips and earnings, the driver can see all vital info on a single screen.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M255.55,170.22c47.14,0,85.2-38.02,85.2-85.11c0-47.1-38.06-85.11-85.2-85.11s-85.2,38.02-85.2,85.11
                        C170.35,132.21,208.4,170.22,255.55,170.22z M255.55,22.7c34.65,0,62.48,27.8,62.48,62.42c0,34.61-27.83,62.42-62.48,62.42
                        c-34.65,0-62.48-27.8-62.48-62.42C193.07,50.5,220.9,22.7,255.55,22.7z M255.55,136.18c11.93,0,23.29-5.67,31.24-15.32
                        c3.98-5.11,3.41-11.92-1.71-15.89c-5.11-3.97-11.93-3.41-15.9,1.7c-7.95,9.08-20.45,9.08-27.83,0c-3.98-5.11-11.36-5.67-15.9-1.7
                        c-5.11,3.97-5.68,11.35-1.7,15.89C232.26,130.51,243.62,136.18,255.55,136.18z M112.98,340.45h284.57
                        c12.5,0,22.15-10.21,22.15-22.13c0.57-69.22-55.66-125.4-124.96-125.4h-78.38c-69.3,0-125.53,56.17-125.53,125.4
                        C90.82,330.23,101.05,340.45,112.98,340.45z M216.35,215.62h78.95c56.23,0,102.24,45.96,102.81,102.13H113.54
                        C113.54,261.58,159.55,215.62,216.35,215.62z M126.61,418.19l-31.24-4.54c-1.14,0-2.27-1.13-2.84-2.27l-14.2-30.07
                        c-1.7-3.97-5.68-6.81-10.22-6.81c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27L9.6,418.19
                        c-3.98,0.57-7.95,3.4-9.09,7.38c-1.13,3.97-0.57,8.51,2.84,11.35l22.72,23.83c1.14,1.13,1.14,2.27,1.14,3.41l-5.68,33.48
                        c-0.57,4.54,1.14,8.51,4.54,11.35c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32
                        c1.7,1.13,3.41,1.13,5.68,1.13c2.27,0,4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35L109,463.58c0-1.13,0-2.27,1.14-3.4
                        l22.72-23.26c2.84-2.84,3.98-7.38,2.84-11.35C134.56,421.59,130.59,418.75,126.61,418.19z M93.66,444.85
                        c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.39-3.97c-7.95-4.54-17.61-4.54-25.56,0l-7.38,3.97l1.7-10.21
                        c1.14-8.51-1.14-17.02-7.38-22.7l-7.38-7.94l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                        c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L93.66,444.85z M314.05,418.19l-31.24-4.54c-1.14,0-2.27-1.13-2.84-2.27l-14.2-30.07
                        c-1.71-3.97-5.68-6.81-10.23-6.81c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27l-31.24,4.54
                        c-3.98,0.57-7.95,3.4-9.09,7.38c-1.14,3.97-0.57,8.51,2.84,11.35l22.72,23.83c1.14,1.13,1.14,2.27,1.14,3.41l-5.68,33.48
                        c-0.57,4.54,1.13,8.51,4.54,11.35c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32
                        c1.71,1.13,3.41,1.13,5.68,1.13c2.27,0,4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35l-5.68-33.48c0-1.13,0-2.27,1.13-3.4
                        l22.72-23.26c2.84-2.84,3.97-7.38,2.84-11.35C322,421.59,318.03,418.75,314.05,418.19z M281.11,444.85
                        c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.38-3.97c-3.41-1.7-7.95-2.84-12.5-2.84c-4.54,0-9.09,1.13-13.06,3.4l-7.39,3.97
                        l1.71-10.21c1.14-8.51-1.14-17.02-7.39-22.7l-7.38-8.51l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                        c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L281.11,444.85z M507.74,436.91c2.84-2.84,3.98-7.38,2.84-11.35
                        c-1.14-3.97-5.11-6.81-9.09-7.38l-31.24-4.54c-1.13,0-2.27-1.13-2.84-2.27l-14.2-30.07c-1.7-3.97-5.68-6.81-10.22-6.81
                        c-4.54,0-8.52,2.27-10.22,6.81l-14.2,30.07c-0.57,1.13-1.7,2.27-2.84,2.27l-31.24,4.54c-3.98,0.57-7.95,3.4-9.09,7.38
                        c-1.14,3.97-0.57,8.51,2.84,11.35l22.72,23.26c1.13,1.13,1.13,2.27,1.13,3.4l-5.68,33.48c-0.57,4.54,1.14,8.51,4.55,11.35
                        c3.41,2.27,7.95,2.84,11.93,0.57l27.83-15.32c1.14-0.57,2.84-0.57,3.98,0l27.83,15.32c1.7,1.13,3.41,1.13,5.68,1.13
                        s4.54-0.57,6.82-2.27c3.41-2.27,5.11-6.81,4.54-11.35l-5.68-33.48c0-1.14,0-2.27,1.14-3.41L507.74,436.91z M468.55,444.85
                        c-5.68,6.24-8.52,14.75-7.38,22.7l1.7,10.21l-7.38-3.97c-3.41-1.7-7.95-2.84-12.5-2.84c-4.54,0-9.09,1.13-13.06,3.4l-7.38,3.97
                        l1.7-10.21c1.13-8.51-1.14-17.02-7.39-22.7l-7.38-8.51l9.09-1.14c9.09-1.13,16.47-6.81,19.88-14.75l3.98-7.94l3.98,7.94
                        c3.98,7.94,11.36,13.62,19.88,14.75l9.09,1.7L468.55,444.85z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Rate Customer',
					text: 'Drivers can also rate the passengers depending on the trip experience with star rating &amp; reviews.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M442.28,69.64C397.17,24.58,337.7,0,274.13,0c-63.57,0-124.06,25.6-168.15,69.64c-7.18-3.07-14.35-4.1-22.56-4.1
                        c-22.56,0-41.01,16.39-57.41,31.75c-3.08,2.05-5.13,4.1-8.2,6.14c-10.25,8.19-15.38,22.53-17.43,39.94
                        c-2.05,26.63,5.13,62.47,21.53,100.36c18.46,41.99,46.14,87.04,82.02,128.01C185.96,467.99,272.08,511,322.32,511
                        c14.35,0,25.63-3.07,33.83-10.24c3.08-2.05,6.15-5.12,9.23-7.17c18.45-15.36,38.96-31.75,39.98-57.35v-1.02
                        c31.78-21.51,57.42-49.15,75.87-82.95c19.48-34.82,29.73-74.76,29.73-114.69C510.97,174.09,486.36,114.69,442.28,69.64z
                        M83.43,86.02c12.3-1.02,24.61,8.19,36.91,26.63c16.4,24.58,23.58,41.99,20.51,54.27c-1.03,4.1-3.08,8.19-6.15,12.29
                        c-15.38-28.67-39.99-67.59-59.47-92.16C78.3,86.02,81.38,86.02,83.43,86.02z M353.08,478.23c-3.08,3.07-6.15,5.12-9.23,7.17
                        c-4.1,4.1-12.3,6.14-21.53,6.14c-44.09,0-126.11-40.96-203-131.08c-33.83-39.94-61.52-82.95-78.95-122.89
                        c-14.35-34.82-21.53-66.56-20.51-91.14c1.02-12.29,5.13-22.53,10.25-26.63c3.08-2.05,5.13-5.12,8.2-7.17
                        c5.13-5.12,11.28-10.24,17.43-15.36c20.5,23.55,48.19,67.59,62.54,96.26c-1.02,1.02-3.08,2.05-4.1,3.07
                        c-1.02,1.02-3.08,2.05-4.1,3.07c-13.33,11.26-12.3,30.72,3.08,57.35c9.23,17.41,25.63,38.91,48.19,65.54
                        c45.11,52.23,75.87,76.8,97.4,76.8c6.15,0,12.3-2.05,17.43-6.14c0,0,0,0,1.02,0c26.66,15.36,66.64,48.13,89.2,74.75
                        C362.31,470.04,357.18,474.13,353.08,478.23z M385.89,435.22c0,6.14-2.05,11.26-6.15,16.38c-22.56-26.63-59.47-56.32-86.12-72.71
                        c7.18-6.14,14.35-9.22,22.56-9.22c12.3,0,28.71,9.22,49.21,28.67C379.73,411.67,386.91,423.95,385.89,435.22z M401.27,413.71
                        c-4.1-10.24-12.3-20.48-23.58-30.72c-24.61-23.55-45.11-33.79-62.54-33.79c-19.48,0-32.81,12.29-47.16,24.58
                        c-1.03,1.02-3.08,3.07-5.13,4.1c-1.02,1.02-2.05,1.02-4.1,1.02c-8.2,0-30.76-9.22-82.02-69.63
                        c-21.53-25.6-36.91-46.08-46.14-62.47c-9.23-16.39-11.28-28.67-7.18-32.77c1.02-1.02,3.08-2.05,4.1-3.07
                        c3.08-3.07,7.18-6.14,10.25-8.19c10.25-9.22,18.46-18.43,21.53-31.74c4.1-18.43-3.08-39.94-23.58-69.64
                        c-5.13-7.17-10.25-13.31-15.38-18.43c39.98-39.94,95.35-62.47,152.77-62.47c119.96,0,217.36,97.28,217.36,217.1
                        C491.49,308.24,457.66,372.75,401.27,413.71z M219.79,53.25c-2.05-5.12-7.18-8.19-12.3-6.14c-15.38,5.12-29.73,12.29-43.06,20.48
                        c-5.13,3.07-6.15,9.22-3.08,13.31c2.05,3.07,5.13,4.1,8.2,4.1c2.05,0,4.1-1.02,5.13-2.05c12.3-8.19,24.61-14.34,38.96-18.43
                        C218.77,63.49,221.84,58.37,219.79,53.25z M238.25,58.37c1.02,0,1.02,0,2.05,0h1.02c5.13-1.02,9.23-6.14,8.2-11.26
                        c-1.02-5.12-6.15-9.22-11.28-8.19h-1.02c-5.13,1.02-9.23,6.14-8.2,11.26C229.02,55.3,233.12,58.37,238.25,58.37z M316.17,165.89
                        c-17.43,0-31.78,14.34-31.78,31.75v57.35c0,17.41,14.35,31.74,31.78,31.74c17.43,0,31.78-14.34,31.78-31.74v-57.35
                        C347.95,180.23,333.6,165.89,316.17,165.89z M327.45,254.99c0,6.14-5.13,11.26-11.28,11.26s-11.28-5.12-11.28-11.26v-57.35
                        c0-6.14,5.13-11.26,11.28-11.26s11.28,5.12,11.28,11.26V254.99z M401.27,216.07c-8.2,0-14.35-6.14-14.35-14.34
                        c0-8.19,6.15-14.34,14.35-14.34c5.13,0,10.25,3.07,12.3,7.17c3.08,5.12,9.23,6.14,13.33,4.1c5.13-3.07,6.15-9.22,4.1-13.31
                        c-6.15-10.24-17.43-17.41-29.73-17.41c-19.48,0-34.86,15.36-34.86,34.82c0,19.46,15.38,34.82,34.86,34.82
                        c8.2,0,14.35,6.14,14.35,14.34c0,8.19-6.15,14.34-14.35,14.34c-5.13,0-10.25-3.07-12.3-7.17c-3.08-5.12-9.23-6.14-13.33-4.1
                        c-5.13,3.07-6.15,9.22-4.1,13.31c6.15,10.24,17.43,17.41,29.73,17.41c19.48,0,34.86-15.36,34.86-34.82
                        C436.12,231.43,420.75,216.07,401.27,216.07z M231.07,216.07c-8.2,0-14.35-6.14-14.35-14.34c0-8.19,6.15-14.34,14.35-14.34
                        c5.13,0,10.25,3.07,12.3,7.17c3.08,5.12,9.23,6.14,13.33,4.1c5.13-3.07,6.15-9.22,4.1-13.31c-6.15-10.24-17.43-17.41-29.73-17.41
                        c-19.48,0-34.86,15.36-34.86,34.82c0,19.46,15.38,34.82,34.86,34.82c8.2,0,14.35,6.14,14.35,14.34c0,8.19-6.15,14.34-14.35,14.34
                        c-5.13,0-10.25-3.07-12.3-7.17c-3.08-5.12-9.23-6.14-13.33-4.1c-5.13,3.07-6.15,9.22-4.1,13.31
                        c6.15,10.24,17.43,17.41,29.73,17.41c19.48,0,34.86-15.36,34.86-34.82C264.9,231.43,249.52,216.07,231.07,216.07z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'SOS Contacts',
					text: 'When things go wrong unexpectedly, the driver can reach out to admin or other emergency support teams.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M416.68,289.43c-51.54,0-93.32,41.78-93.32,93.32c0,71.84,85.33,123.86,88.95,126.04c1.32,0.8,2.83,1.21,4.37,1.21
                        c1.54,0,3.05-0.42,4.37-1.21c3.62-2.18,88.95-54.19,88.95-126.04C510,331.21,468.22,289.43,416.68,289.43z M416.68,491.42
                        c-16.81-11.27-76.35-54.98-76.35-108.67c0.05-42.15,34.2-76.3,76.35-76.35c42.15,0.05,76.3,34.2,76.35,76.35
                        C493.03,436.44,433.5,480.15,416.68,491.42z M416.68,340.33c-23.43,0-42.42,18.99-42.42,42.42c0,23.43,18.99,42.42,42.42,42.42
                        c23.41-0.03,42.39-19,42.42-42.42C459.1,359.32,440.11,340.33,416.68,340.33z M416.68,408.2c-14.06,0-25.45-11.39-25.45-25.45
                        c0-14.06,11.39-25.45,25.45-25.45c14.06,0,25.45,11.39,25.45,25.45C442.13,396.8,430.74,408.2,416.68,408.2z M94.32,145.22
                        C42.78,145.22,1,187,1,238.53c0,71.85,85.33,123.86,88.95,126.04c1.32,0.8,2.83,1.22,4.37,1.21c1.54,0,3.05-0.42,4.37-1.21
                        c3.62-2.18,88.95-54.19,88.95-126.04C187.63,187,145.85,145.22,94.32,145.22z M94.32,347.2c-16.81-11.27-76.35-54.98-76.35-108.67
                        c0.05-42.15,34.2-76.3,76.35-76.35c42.15,0.05,76.3,34.2,76.35,76.35C170.67,292.22,111.13,335.93,94.32,347.2z M94.32,196.12
                        c-23.43,0-42.42,18.99-42.42,42.42c0,23.43,18.99,42.42,42.42,42.42c23.41-0.03,42.39-19,42.42-42.42
                        C136.73,215.11,117.74,196.12,94.32,196.12z M94.32,263.98c-14.06,0-25.45-11.39-25.45-25.45c0-14.06,11.39-25.45,25.45-25.45
                        c14.05,0,25.45,11.39,25.45,25.45C119.77,252.59,108.37,263.98,94.32,263.98z M412.31,220.35c1.32,0.8,2.83,1.21,4.37,1.21
                        c1.54,0,3.05-0.42,4.37-1.21c3.62-2.18,88.95-54.19,88.95-126.04C510,42.78,468.22,1,416.68,1c-51.54,0-93.32,41.78-93.32,93.32
                        C323.37,166.16,408.69,218.17,412.31,220.35z M416.68,17.97c42.15,0.05,76.3,34.2,76.35,76.35c0,53.69-59.54,97.4-76.35,108.67
                        c-16.81-11.27-76.35-54.98-76.35-108.67C340.38,52.17,374.54,18.01,416.68,17.97z M416.68,136.73c23.41-0.03,42.39-19,42.42-42.42
                        c0-23.43-18.99-42.42-42.42-42.42c-23.43,0-42.42,18.99-42.42,42.42C374.27,117.74,393.26,136.73,416.68,136.73z M416.68,68.87
                        c14.06,0,25.45,11.39,25.45,25.45c0,14.06-11.39,25.45-25.45,25.45c-14.06,0-25.45-11.39-25.45-25.45
                        C391.23,80.26,402.63,68.87,416.68,68.87z M179.15,314.88c-4.68,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48
                        c4.69,0,8.48-3.8,8.48-8.48C187.63,318.68,183.84,314.88,179.15,314.88z M204.6,297.92c-4.69,0-8.48,3.8-8.48,8.48
                        c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C213.08,301.71,209.29,297.92,204.6,297.92z M230.05,280.95
                        c-4.68,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48C238.53,284.75,234.74,280.95,230.05,280.95z
                        M255.5,263.98c-4.69,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48
                        C263.98,267.78,260.19,263.98,255.5,263.98z M280.95,247.02c-4.68,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48
                        c4.69,0,8.48-3.8,8.48-8.48C289.43,250.81,285.64,247.02,280.95,247.02z M306.4,230.05c-4.69,0-8.48,3.8-8.48,8.48
                        c0,4.68,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C314.88,233.85,311.09,230.05,306.4,230.05z M331.85,230.05
                        c4.69,0,8.48-3.8,8.48-8.48c0-4.68-3.8-8.48-8.48-8.48c-4.68,0-8.48,3.8-8.48,8.48C323.37,226.25,327.17,230.05,331.85,230.05z
                        M348.82,204.6c0,4.69,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48c0-4.69-3.8-8.48-8.48-8.48
                        C352.62,196.12,348.82,199.91,348.82,204.6z M204.6,331.85c-4.69,0-8.48,3.8-8.48,8.48c0,4.68,3.8,8.48,8.48,8.48
                        c4.68,0,8.48-3.8,8.48-8.48C213.08,335.65,209.29,331.85,204.6,331.85z M230.05,348.82c-4.68,0-8.48,3.8-8.48,8.48
                        c0,4.69,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48C238.53,352.61,234.74,348.82,230.05,348.82z M255.5,365.78
                        c-4.69,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48c4.68,0,8.48-3.8,8.48-8.48C263.98,369.58,260.19,365.78,255.5,365.78z
                        M280.95,382.75c-4.68,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48
                        C289.43,386.55,285.64,382.75,280.95,382.75z M306.4,399.72c-4.69,0-8.48,3.8-8.48,8.48c0,4.69,3.8,8.48,8.48,8.48
                        c4.68,0,8.48-3.8,8.48-8.48C314.88,403.51,311.09,399.72,306.4,399.72z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'My Destination',
					text: 'Drivers can set specific locations where they desire to get more rides from.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M49.1,280.78H32.59v16.85H49.1V280.78z M49.1,314.48H32.59v50.55H49.1V314.48z M466.02,293.41
                        c-15.93,0-28.9,13.23-28.9,29.49c0,16.26,12.96,29.49,28.9,29.49c15.94,0,28.9-13.23,28.9-29.49
                        C494.92,306.64,481.96,293.41,466.02,293.41z M466.02,335.55c-6.83,0-12.38-5.67-12.38-12.64s5.56-12.64,12.38-12.64
                        c6.83,0,12.38,5.67,12.38,12.64S472.85,335.55,466.02,335.55z M423.27,97.65l-100.23,94.41l-27.66-42.35l-13.74,9.35l38.39,58.76
                        l114.43-107.81L423.27,97.65z M486.67,162.81h-9.58c0.78-5.52,1.32-11.12,1.32-16.85C478.41,80.92,426.56,28,362.83,28
                        c-63.73,0-115.58,52.92-115.58,117.96c0,5.67,0.42,11.3,1.21,16.85H24.33c-13.66,0-24.77,11.34-24.77,25.28v269.63
                        c0,13.94,11.11,25.28,24.77,25.28h462.33c13.66,0,24.77-11.34,24.77-25.28V188.09C511.43,174.16,500.32,162.81,486.67,162.81z
                        M65.61,466.15H24.33c-4.55,0-8.26-3.77-8.26-8.42V188.09c0-4.65,3.71-8.43,8.26-8.43h41.28V466.15z M420.62,466.15H82.12V179.67
                        h169.99c2.8,9.55,6.75,18.74,11.87,27.36l-21.55,60.5l66.86-17.05c16.49,8.8,34.92,13.46,53.53,13.46
                        c21.06,0,40.77-5.87,57.79-15.96V466.15z M362.83,247.07c-16.89,0-33.63-4.46-48.38-12.93l-2.87-1.63l-43.01,10.97l13.74-38.55
                        l-2.28-3.54c-10.64-16.52-16.27-35.68-16.27-55.43c0-55.75,44.44-101.11,99.07-101.11c54.63,0,99.07,45.36,99.07,101.11
                        C461.9,201.72,417.45,247.07,362.83,247.07z M494.92,322.91v134.82c0,4.65-3.71,8.43-8.26,8.43h-49.54V322.91v-86.72
                        c17.04-14.65,29.88-34.2,36.41-56.52h13.13c4.55,0,8.26,3.78,8.26,8.43V322.91z M184.5,247.06c-45.53,0-82.57,37.8-82.57,84.27
                        c0,23.21,9.25,44.25,24.17,59.51c0.03,0.03,0.03,0.09,0.06,0.12c0.18,0.18,0.39,0.29,0.57,0.47c3.59,3.59,7.46,6.88,11.65,9.76
                        c0.48,0.34,1.01,0.6,1.5,0.93c3.99,2.63,8.18,4.96,12.61,6.88c0.53,0.23,1.07,0.38,1.59,0.6c4.49,1.82,9.14,3.31,13.98,4.32
                        c0.3,0.06,0.6,0.08,0.89,0.14c5.03,0.96,10.22,1.53,15.55,1.53c5.33,0,10.52-0.57,15.57-1.55c0.3-0.06,0.6-0.08,0.89-0.14
                        c4.83-1,9.49-2.5,13.98-4.32c0.53-0.21,1.07-0.37,1.59-0.6c4.43-1.9,8.62-4.24,12.61-6.88c0.5-0.32,1.01-0.6,1.5-0.93
                        c4.17-2.88,8.06-6.16,11.65-9.76c0.18-0.17,0.39-0.29,0.57-0.48c0.03-0.03,0.03-0.09,0.06-0.12
                        c14.91-15.24,24.15-36.28,24.15-59.49C267.07,284.86,230.03,247.06,184.5,247.06z M225.08,386.11c-1.58,1.21-3.14,2.45-4.82,3.49
                        c-1.34,0.86-2.79,1.56-4.19,2.33c-19.51,10.22-43.61,10.22-63.13,0c-1.41-0.76-2.85-1.46-4.19-2.33
                        c-1.68-1.04-3.24-2.28-4.82-3.49c-1.19-0.92-2.31-1.9-3.44-2.9c7.94-17.47,25.01-28.9,44-28.9c18.99,0,36.07,11.43,44.02,28.9
                        C227.39,384.21,226.26,385.19,225.08,386.11z M161.98,316.01c0-12.67,10.1-22.98,22.52-22.98c12.41,0,22.52,10.31,22.52,22.98
                        c0,12.67-10.1,22.98-22.52,22.98C172.08,338.99,161.98,328.68,161.98,316.01z M239.23,371.41c-6.94-12.15-17.43-21.51-29.83-26.97
                        c7.69-7.03,12.63-17.11,12.63-28.44c0-21.11-16.84-38.3-37.53-38.3c-20.69,0-37.53,17.19-37.53,38.3
                        c0,11.32,4.94,21.4,12.63,28.42c-12.4,5.45-22.89,14.82-29.83,26.97c-7.99-11.31-12.82-25.08-12.82-40.07
                        c0-38.01,30.31-68.95,67.56-68.95s67.55,30.93,67.55,68.95C252.05,346.32,247.22,360.09,239.23,371.41z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Upcoming Rides',
					text: 'Drivers can track numbers of upcoming rides scheduled from the dashboard as well as the earning from the rides.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M484.58,0L484.58,0H133.15c-14.82,0.06-26.79,12.12-26.73,26.93c0,0.07,0,0.14,0,0.21v185.77H21.22
                        c0-17.64,14.3-31.94,31.95-31.94h31.95v-21.29H53.17c-29.41,0-53.25,23.83-53.25,53.23v266.15c0,17.64,14.3,31.94,31.95,31.94
                        h404.67c17.64,0,31.95-14.3,31.95-31.94V244.85c0-17.64-14.3-31.94-31.95-31.94H127.72V117.1h223.63V95.81H127.72V74.52h362.07
                        v175.12c0.18,3.05-2.15,5.67-5.2,5.85c0,0-0.01,0-0.02,0h-5.43v21.29h5.43c14.82-0.18,26.69-12.33,26.52-27.14v0V27.15
                        C511.27,12.33,499.4,0.18,484.58,0z M447.19,425.83h-63.9c-5.88,0-10.65-4.77-10.65-10.64v-31.94c0-5.88,4.77-10.65,10.65-10.65
                        h63.9V425.83z M436.54,234.21c5.88,0,10.65,4.77,10.65,10.65v106.46h-63.9c-17.64,0-31.95,14.3-31.95,31.94v31.94
                        c0,17.64,14.3,31.94,31.95,31.94h63.9v31.94c0,5.88-4.77,10.64-10.65,10.64H31.87c-5.88,0-10.65-4.77-10.65-10.64V234.21H436.54z
                        M127.72,53.23V27.15c-0.18-3.05,2.15-5.67,5.2-5.85c0.01,0,0.01,0,0.02,0h351.42c3.05,0.17,5.39,2.78,5.22,5.84
                        c0,0,0,0.01,0,0.02l0.21,26.08H127.72z M53.17,468.42h42.6v-21.29H63.82v-31.94h-21.3v42.58
                        C42.52,463.65,47.29,468.42,53.17,468.42z M138.37,447.13h-21.3v21.29h21.3V447.13z M457.84,117.1V95.81h-21.3v21.29H457.84z
                        M404.6,117.1V95.81h-21.3v21.29H404.6z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Payment Management',
					text: 'The driver can receive payment in cash or in the wallet, payment can be transferred to the bank any point of time.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M277.19,495.04l-4.14-5.17H158.29H43.52l-4.14-2.07c-6.2-3.1-10.34-8.27-12.41-15.51c-1.03-4.14-1.03-428.09,0-432.23
                    c1.03-6.2,6.2-12.41,12.41-15.51l3.1-2.07h174.74h174.74l4.14,2.07c6.2,3.1,10.34,8.27,12.41,15.51c0,2.07,1.03,38.26,1.03,106.51
                    v104.44h4.14c2.07,0,7.24,1.03,10.34,1.03c3.1,1.03,6.2,1.03,6.2,1.03c1.03,0,1.03-15.51,1.03-103.4c0-64.11,0-105.47-1.03-109.61
                    c0-3.1-1.03-7.24-2.07-9.31c-5.17-15.51-17.58-26.88-34.12-29.99c-6.2-1.03-346.38-1.03-352.58,0C31.11,2.84,20.77,9.05,14.57,18.35
                    C8.36,25.59,6.3,31.8,5.26,41.1c0,4.14,0,78.59,0,219.22v213.01l1.03,4.14c4.14,14.48,14.47,25.85,27.92,31.02
                    c2.07,1.03,6.2,2.07,8.27,2.07c2.07,0,41.36,0,126.14,0h122.01l-5.17-5.17C283.4,502.28,279.26,498.15,277.19,495.04z M310.28,141.4
                    v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V141.4z M310.28,206.55v-11.37h-98.23h-98.23v11.37v11.37h98.23h98.23V206.55z
                    M307.18,279.96l3.1-2.07v-8.27v-8.27h-98.23h-98.23v10.34v10.34h95.12h95.12L307.18,279.96z M503.63,377.16
                    c-7.24-31.02-28.95-57.91-57.9-72.38c-18.61-9.31-39.29-12.41-59.97-10.34c-6.2,1.03-16.54,3.1-22.75,5.17
                    c-7.24,2.07-19.64,8.27-25.85,12.41c-22.75,15.51-39.29,38.26-45.49,63.08c-2.07,9.31-3.1,16.54-3.1,26.89
                    c0,17.58,3.1,32.05,10.34,47.57c19.65,40.33,63.07,65.14,108.57,61.01c46.53-4.14,85.82-38.26,96.16-83.76
                    C507.77,412.32,507.77,393.71,503.63,377.16z M480.88,429.9c-10.34,29.99-35.15,52.74-67.21,57.91c-8.27,1.03-24.82,1.03-32.05,0
                    c-33.09-7.24-58.94-31.02-67.21-63.08c-2.07-8.27-3.1-13.44-3.1-22.75c0-9.31,1.03-14.48,3.1-22.75
                    c6.2-23.78,22.75-44.46,45.49-55.84c8.27-4.14,14.48-6.2,24.82-8.27c7.24-1.03,24.82-1.03,32.05,1.03
                    c8.27,2.07,13.44,4.14,21.71,7.24c19.64,9.31,34.12,25.85,41.36,45.5C486.05,389.57,487.09,410.25,480.88,429.9z M114.86,326.5
                    v10.34v10.34h70.31h70.31l2.07-5.17c1.03-3.1,3.1-8.27,5.17-10.34l3.1-5.17h-75.48H114.86z M408.51,391.64v-21.71v-21.71h-11.37
                    h-11.37v33.09v33.09h33.09h33.09v-10.34v-10.34h-21.71h-21.71V391.64z"
					></path>
				</svg>`,
					title: 'Trip History',
					text: 'The driver can see a complete list of timestamped view of trips and the earnings thereon using the trip history.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M491.34,252.43c-16.4-13.29-40.99-11.24-56.36,4.09l-59.44,59.28c-5.12-3.07-12.3-5.11-18.45-5.11
                        c-1.02,0-29.72-1.02-35.87-1.02c-5.12,0-9.22,0-14.35,0c-24.6,0-50.22,1.02-72.76-5.11c-5.13-1.02-10.25-3.07-15.37-4.09
                        c-24.6-7.15-48.17-14.31-77.88-4.09c-25.62,9.2-51.24,26.57-77.89,54.17l-51.24,45.99c-8.2,7.15-8.2,19.42-2.05,26.57l69.69,80.74
                        c3.07,5.11,8.2,7.15,14.35,7.15c4.1,0,8.2-1.02,11.27-4.09l65.25-49.06h163.28c4.1,0,8.2,0,12.3,0c3.07,0,12.3-8.18,14.35-11.24
                        l134.25-133.88c8.2-8.18,12.3-19.42,12.3-30.66C505.69,270.83,500.57,259.59,491.34,252.43z M170.94,457.33l0.66-0.5
                        C172.63,457.04,172.21,457.2,170.94,457.33z M482.12,300.47L347.87,433.33c-4.1,4.09-15.37,6.13-15.37,6.13h-3.07
                        c0,0-165-1.02-167.04,0L92.69,492.6c-1.02,1.02-2.05,0-2.05,0l-69.69-80.74c1.02,0,1.02-1.02,2.05-2.04l51.24-45.99
                        c25.62-26.57,48.17-41.9,71.74-50.08c24.6-8.18,43.04-3.07,67.64,4.09c5.12,1.02,10.25,3.07,15.37,4.09
                        c25.62,6.13,53.29,6.13,77.89,5.11c5.12,0,9.22,0,14.35,0c5.12,0,25.62,1.02,35.87,1.02c5.12,0,10.25,2.04,14.35,6.13
                        c4.1,4.09,6.15,8.18,6.15,14.31c0,11.24-9.22,19.42-19.47,19.42c0,0-129.13,0-130.15,0c-5.12,0-10.25,5.11-10.25,10.22
                        c1.02,7.15,10.25,7.15,16.4,7.15h124c20.49,0,36.89-16.35,36.89-36.79c0-7.15-2.05-14.31-6.15-20.44l59.44-59.28
                        c9.22-9.2,23.57-10.22,32.79-3.07c5.12,4.09,8.2,10.22,8.2,17.37C489.29,289.23,487.25,296.38,482.12,300.47z M319.18,199.29
                        c30.75,0,56.36-25.55,56.36-56.21c0-30.66-25.62-56.21-56.36-56.21c-30.74,0-56.37,25.55-56.37,56.21
                        C262.81,173.74,288.43,199.29,319.18,199.29z M319.18,105.27c20.5,0,37.92,17.37,37.92,37.81c0,21.46-17.42,37.81-37.92,37.81
                        c-21.52,0-37.92-17.37-37.92-37.81C281.26,122.64,298.68,105.27,319.18,105.27z M200.3,121.62l5.12,3.07
                        c-1.03,6.13-1.03,12.26-1.03,17.37c0,6.13,0,12.26,1.03,17.37l-3.07,4.09c-15.37,9.2-20.5,28.62-12.3,43.95l8.2,13.29
                        c6.15,10.22,16.4,16.35,28.7,16.35c6.15,0,11.27-1.02,16.4-4.09l5.13-3.07c9.22,7.15,19.47,13.29,30.74,17.37v5.11
                        c0,18.4,14.35,32.7,32.79,32.7h15.37c18.45,0,32.79-14.31,32.79-32.7v-5.11c11.27-4.09,21.52-10.22,30.74-17.37l5.12,3.07
                        c5.12,3.07,10.25,4.09,16.4,4.09c11.27,0,22.55-6.13,28.7-16.35l8.2-13.29c9.22-15.33,4.1-35.77-12.3-43.95l-7.17-3.07
                        c1.02-6.13,1.02-12.26,1.02-17.37c0-6.13,0-12.26-1.02-17.37l5.12-3.07c15.37-9.2,21.52-28.62,12.3-43.95l-8.2-13.29
                        c-6.15-10.22-16.4-16.35-28.69-16.35c-6.15,0-11.27,1.02-16.4,4.09l-5.12,3.07c-9.22-7.15-19.47-13.29-30.74-17.37V32.7
                        c0-18.4-14.35-32.7-32.79-32.7h-15.37c-18.45,0-32.79,14.31-32.79,32.7v5.11c-11.27,4.09-21.52,10.22-30.74,17.37l-5.12-3.07
                        c-5.12-3.07-10.25-4.09-16.4-4.09c-11.27,0-22.55,6.13-28.69,16.35L188,77.67C178.78,93,183.9,113.44,200.3,121.62z M205.42,86.87
                        l8.2-13.29c3.07-5.11,7.17-7.15,12.3-7.15c2.05,0,5.12,1.02,7.17,2.04l6.15,3.07c3.07,2.04,6.15,2.04,9.22,2.04
                        c4.1,0,8.2-1.02,11.27-4.09c8.2-6.13,16.4-11.24,26.64-15.33c6.15-2.04,10.25-8.18,10.25-15.33V32.7
                        c0-8.18,6.15-14.31,14.35-14.31h15.37c8.2,0,14.35,6.13,14.35,14.31v6.13c0,7.15,4.1,14.31,11.27,16.35
                        c9.22,4.09,18.45,9.2,26.64,15.33c3.07,3.07,7.17,4.09,11.27,4.09c3.07,0,6.15-1.02,9.22-2.04l5.12-3.07
                        c2.05-1.02,5.12-2.04,7.17-2.04c5.12,0,10.25,3.07,12.3,7.15l8.2,13.29c3.07,6.13,1.02,15.33-6.15,19.42l-5.13,3.07
                        c-6.15,4.09-10.25,11.24-8.2,18.4c1.03,5.11,1.03,10.22,1.03,15.33s0,10.22-1.03,15.33c-1.02,7.15,2.05,14.31,8.2,18.4l5.13,3.07
                        c7.17,4.09,9.22,13.29,5.12,19.42l-8.2,13.29c-3.07,5.11-7.17,7.15-12.3,7.15c-2.05,0-5.12-1.02-7.17-2.04l-4.1-4.09
                        c-3.07-2.04-6.15-2.04-9.22-2.04c-4.1,0-8.2,1.02-11.27,4.09c-8.2,6.13-16.4,11.24-26.64,15.33c-7.17,3.07-11.27,9.2-11.27,16.35
                        v6.13c0,8.18-6.15,14.31-14.35,14.31h-15.37c-8.2,0-14.35-6.13-14.35-14.31v-6.13c0-7.15-4.1-14.31-11.27-16.35
                        c-9.22-4.09-18.45-9.2-26.64-15.33c-3.07-3.07-7.17-4.09-11.27-4.09c-3.07,0-6.15,1.02-9.22,2.04l-5.12,3.07
                        c-2.05,1.02-5.12,2.04-7.17,2.04c-5.12,0-10.25-3.07-12.3-7.15l-8.2-13.29c-4.1-7.15-2.05-15.33,5.12-19.42l5.12-3.07
                        c6.15-4.09,10.25-11.24,8.2-18.4c-1.02-5.11-1.02-10.22-1.02-15.33c0-5.11,0-10.22,1.02-15.33c1.02-7.15-2.05-14.31-8.2-18.4
                        l-5.12-3.07C203.37,102.2,201.32,93,205.42,86.87z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Support',
					text: 'The driver can reach to a service provider for any conflict or assistance.'
				}
			],
			items3: [
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								class="st0"
								d="M485.79,16H25.2C11.71,16,1.38,26.23,1.38,39.61c0,51.93,0,383.21,0,432.79C1.38,485.77,11.71,496,25.2,496
                        h460.59c13.5,0,23.82-10.23,23.82-23.61c0-49.57,0-380.85,0-432.79C509.62,26.23,499.29,16,485.79,16z M493.73,472.39
                        c0,4.72-3.18,7.87-7.94,7.87H25.2c-4.76,0-7.94-3.15-7.94-7.87V122.23h476.47V472.39z M493.73,106.49H17.26V39.61
                        c0-4.72,3.18-7.87,7.94-7.87h460.59c4.77,0,7.94,3.15,7.94,7.87V106.49z M72.85,61.64H56.97c-4.76,0-7.94,3.15-7.94,7.87
                        c0,4.72,3.18,7.87,7.94,7.87h15.88c4.76,0,7.94-3.15,7.94-7.87C80.79,64.79,77.62,61.64,72.85,61.64z M129.23,61.64h-15.88
                        c-4.77,0-7.94,3.15-7.94,7.87c0,4.72,3.18,7.87,7.94,7.87h15.88c4.76,0,7.94-3.15,7.94-7.87C137.18,64.79,134,61.64,129.23,61.64z
                        M186.41,61.64h-15.88c-4.77,0-7.94,3.15-7.94,7.87c0,4.72,3.18,7.87,7.94,7.87h15.88c4.76,0,7.94-3.15,7.94-7.87
                        C194.35,64.79,190.38,61.64,186.41,61.64z M454.03,61.64H234.06c-4.77,0-7.94,3.15-7.94,7.87c0,4.72,3.17,7.87,7.94,7.87h219.97
                        c4.76,0,7.94-3.15,7.94-7.87C461.97,64.79,458,61.64,454.03,61.64z M207.06,237.9c26.21,0,47.65-21.25,47.65-47.21
                        s-21.44-47.21-47.65-47.21c-26.21,0-47.65,21.25-47.65,47.21C159.41,217.44,180.85,237.9,207.06,237.9z M207.06,160
                        c17.47,0,31.76,14.16,31.76,31.48c0,17.31-14.29,31.47-31.76,31.47c-17.47,0-31.77-14.16-31.77-31.47
                        C175.29,174.16,189.59,160,207.06,160z M55.38,459.02h301.76c4.77,0,7.94-3.15,7.94-7.87v-89.71c0-4.72-3.18-7.87-7.94-7.87
                        h-56.38v-78.69c0-17.31-14.29-30.69-30.97-30.69h-29.38c-2.38,0-3.97,0.79-5.56,2.36l-27,26.75l-27-26.75
                        c-1.59-1.57-3.18-2.36-5.56-2.36h-34.15c-17.47,0-30.97,14.16-30.97,30.69v78.69H55.38c-4.77,0-7.94,3.15-7.94,7.87v89.71
                        C47.44,455.08,51.41,459.02,55.38,459.02z M142.74,259.15h30.97l30.18,29.9c1.59,1.57,3.18,2.36,5.56,2.36
                        c2.38,0,3.97-0.79,5.56-2.36l30.18-29.9h26.21c8.74,0,15.09,7.08,15.09,14.95v78.69h-29.38v-54.3c0-4.72-3.18-7.87-7.94-7.87
                        c-4.77,0-7.94,3.15-7.94,7.87v54.3h-66.7v-54.3c0-4.72-3.18-7.87-7.94-7.87c-4.77,0-7.94,3.15-7.94,7.87v54.3h-29.38V274.1h-1.59
                        C127.65,265.44,134.79,259.15,142.74,259.15z M63.32,369.31c218.38,0.79,184.23,0,285.88,0v73.97H63.32V369.31z M123.68,388.98
                        c-3.18-3.15-7.94-3.15-11.12,0l-5.56,5.51l-5.56-5.51c-3.18-3.15-7.94-3.15-11.12,0c-3.18,3.15-3.18,7.87,0,11.02l5.56,5.51
                        l-5.56,5.51c-3.18,3.15-3.18,7.87,0,11.02c3.18,3.15,7.94,3.15,11.12,0l5.56-5.51l5.56,5.51c3.18,3.15,7.94,3.15,11.12,0
                        c3.18-3.15,3.18-7.87,0-11.02l-5.56-5.51l5.56-5.51C126.85,397.64,126.85,392.13,123.68,388.98z M190.38,388.98
                        c-3.18-3.15-7.94-3.15-11.12,0l-5.56,5.51l-5.56-5.51c-3.18-3.15-7.94-3.15-11.12,0c-3.18,3.15-3.18,7.87,0,11.02l5.56,5.51
                        l-5.56,5.51c-3.18,3.15-3.18,7.87,0,11.02c3.18,3.15,7.94,3.15,11.12,0l5.56-5.51l5.56,5.51c3.18,3.15,7.94,3.15,11.12,0
                        c3.18-3.15,3.18-7.87,0-11.02l-5.56-5.51l5.56-5.51C193.56,397.64,193.56,392.13,190.38,388.98z M257.09,388.98
                        c-3.18-3.15-7.94-3.15-11.12,0l-6.35,6.29l-5.56-5.51c-3.18-3.15-7.94-3.15-11.12,0c-3.18,3.15-3.18,7.87,0,11.02l5.56,5.51
                        l-5.56,5.51c-3.18,3.15-3.18,7.87,0,11.02c3.18,3.15,7.94,3.15,11.12,0l5.56-5.51l5.56,5.51c3.18,3.15,7.94,3.15,11.12,0
                        c3.18-3.15,3.18-7.87,0-11.02l-5.56-5.51l5.56-5.51C260.26,397.64,260.26,392.13,257.09,388.98z M323,388.98
                        c-3.18-3.15-7.94-3.15-11.12,0l-5.56,5.51l-5.56-5.51c-3.18-3.15-7.94-3.15-11.12,0c-3.18,3.15-3.18,7.87,0,11.02l5.56,5.51
                        l-5.56,5.51c-3.18,3.15-3.18,7.87,0,11.02c3.18,3.15,7.94,3.15,11.12,0l5.56-5.51l5.56,5.51c3.18,3.15,7.94,3.15,11.12,0
                        c3.18-3.15,3.18-7.87,0-11.02l-5.56-5.51L323,400C326.18,397.64,326.18,392.13,323,388.98z M354.77,331.54h84.97
                        c13.5,0,23.82-10.23,23.82-23.61v-86.56c0-4.72-3.18-7.87-7.94-7.87h-7.94v-39.34c0-13.38-10.32-23.61-23.82-23.61h-53.21
                        c-13.5,0-23.82,10.23-23.82,23.61v39.34h-7.94c-4.76,0-7.94,3.15-7.94,7.87v86.56C330.94,320.52,341.27,331.54,354.77,331.54z
                        M362.71,174.16c0-4.72,3.18-7.87,7.94-7.87h53.21c4.76,0,7.94,3.15,7.94,7.87v39.34h-69.09V174.16z M346.82,229.25h7.94h84.97
                        h7.94v78.69c0,4.72-3.18,7.87-7.94,7.87h-84.97c-4.77,0-7.94-3.15-7.94-7.87V229.25z M388.91,266.23v18.1
                        c0,4.72,3.18,7.87,7.94,7.87c4.77,0,7.94-3.15,7.94-7.87v-18.1c3.18-1.57,4.77-3.94,4.77-7.08c0-3.93-3.18-7.87-7.94-7.87h-9.53
                        c-4.76,0-7.94,3.15-7.94,7.87C384.15,262.29,385.73,265.44,388.91,266.23z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Secure Login',
					text: 'SSO or email based secure login system for the admin to log into the panel.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M458.74,63.32h11.75V51.28h-11.75V63.32z M428.98,63.32h11.75V51.28h-11.75V63.32z M399.21,63.32h11.75V51.28h-11.75
                        V63.32z M45.96,63.32h314.09V51.28H45.96V63.32z M471.28,288.91h-15.67V170.09h-11.75v118.81H418.8V183.74h-10.97v105.17h-25.06
                        v-64.22h-11.75v64.22h-25.06v-74.66H334.2v74.66h-24.28v-89.91h-11.75v89.91h-25.06V229.5h-11.75v59.41h-15.66v12.04h225.58
                        V288.91z M153.27,168.49h36.03v-12.04h15.67V144.4H189.3v-12.85h-36.03v12.04H39.69v12.04h113.58V168.49z M165.02,143.6h12.53
                        v12.84h-12.53V143.6z M71.02,216.65h36.03v-12.04h97.91v-12.04h-97.91v-12.85H71.02v12.04H39.69v12.04h31.33V216.65z
                        M82.77,191.77h12.53v12.84H82.77V191.77z M128.2,264.82h36.03v-12.04h40.73v-12.04h-40.73v-12.84H128.2v12.04H39.69v12.04h88.51
                        V264.82z M139.95,239.93h12.53v12.84h-12.53V239.93z M106.27,300.95v12.04h36.03v-12.04h62.66v-12.04H142.3v-12.84h-36.03v12.04
                        H39.69v12.04h66.58V300.95z M118.02,288.1h12.53v12.84h-12.53V288.1z M483.02,23.98H28.73c-16.45,0-28.98,13.65-28.98,29.7v305.06
                        c0,16.86,13.32,29.7,28.98,29.7h158.22v61.82h-26.63c-7.83,0-13.32,6.42-13.32,13.65V488h216.97v-24.08
                        c0-8.03-6.27-13.65-13.32-13.65h-26.63v-61.82h158.22c16.45,0,28.98-13.65,28.98-29.7V53.69C512,37.63,498.69,23.98,483.02,23.98z
                        M11.5,53.69c0-9.63,7.83-17.66,17.23-17.66h453.51c9.4,0,17.23,8.03,17.23,17.66v24.89H11.5V53.69z M350.65,462.31
                        c0.78,0,1.57,0.8,1.57,1.61v12.04H158.75v-12.04c0-0.8,0.78-1.61,1.57-1.61H350.65z M199.48,450.27v-61.82h113.57v61.82h-0.78
                        H199.48z M500.26,358.75c0,9.63-7.83,18.46-17.23,18.46H28.73c-9.4,0-17.23-8.03-17.23-17.66v-11.24h488.76V358.75z
                        M500.26,336.27H11.5V91.42h488.76V336.27z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Admin Dashboard',
					text: 'An all information compromising control centre exclusive for the admin.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								class="st0"
								d="M158.97,81.79c0-4.75-3.86-8.6-8.62-8.6h-5.26c-4.76,0-8.62,3.85-8.62,8.6c0,4.75,3.86,8.6,8.62,8.6h5.26
                        C155.11,90.39,158.97,86.54,158.97,81.79z M187.9,81.79c0-4.75-3.86-8.6-8.62-8.6h-5.25c-4.76,0-8.62,3.85-8.62,8.6
                        c0,4.75,3.86,8.6,8.62,8.6h5.25C184.05,90.39,187.9,86.54,187.9,81.79z M202.67,90.39h5.27c4.76,0,8.62-3.85,8.62-8.6
                        c0-4.75-3.86-8.6-8.62-8.6h-5.27c-4.76,0-8.62,3.85-8.62,8.6C194.05,86.54,197.9,90.39,202.67,90.39z M164.68,95.05h-5.27
                        c-4.76,0-8.62,3.85-8.62,8.6c0,4.75,3.86,8.6,8.62,8.6h5.27c4.76,0,8.62-3.85,8.62-8.6C173.3,98.9,169.44,95.05,164.68,95.05z
                        M179.73,103.65c0,4.75,3.86,8.6,8.62,8.6h5.25c4.76,0,8.62-3.85,8.62-8.6c0-4.75-3.86-8.6-8.62-8.6h-5.25
                        C183.59,95.05,179.73,98.9,179.73,103.65z M267.68,337.51c-13.76-1.85-26.76-3.59-36.03-12.5c-6.4-6-6.25-18.26-5.06-33.74
                        c34.33-31.06,46.65-91.27,46.65-125.24c0-0.18-0.02-0.35-0.03-0.53c0.98-1.16,1.95-2.38,2.89-3.63
                        c15.11-12.23,18.25-25.13,18.18-33.88c-0.12-16.88-11.49-34.03-33.88-51.05c-28.43-21.03-49.19-31.72-83.74-31.72
                        c-34.96,0-55.65,10.69-83.8,31.76c-22.46,16.93-33.94,34.09-34.09,50.99c-0.09,8.74,3.04,21.64,18.15,33.89
                        c0.95,1.27,1.91,2.48,2.9,3.65c-0.01,0.17-0.03,0.34-0.03,0.51c0,38.32,13.62,93.02,43.53,122.59c1.12,1.11,2.26,2.16,3.41,3.18
                        c0.94,15.83,0.86,27.48-5.15,33.29c-1.42,1.36-2.93,2.53-4.52,3.58c-0.71,0.31-1.37,0.72-1.98,1.21
                        c-8.4,4.78-18.75,6.19-29.59,7.63c-16.94,2.27-36.14,4.84-49.24,21.78C13.21,389.46,0,435.65,0,486.03c0,4.75,3.86,8.59,8.62,8.59
                        H147.5c0.08,0,0.15,0.01,0.23,0.01c0.05,0,0.09-0.01,0.14-0.01h57.29c0.05,0,0.09,0.01,0.14,0.01c0.07,0,0.15-0.01,0.22-0.01
                        H344.4c4.76,0,8.62-3.85,8.62-8.59c0-50.37-13.21-96.57-36.28-126.78C303.67,342.35,284.56,339.78,267.68,337.51z M232.36,346.26
                        c3.31,1.65,6.73,2.93,10.2,3.96l-38.65,29.46l-12.64-12.19L232.36,346.26z M176.65,355.69L136,334.79
                        c6.86-8.38,8.47-19.35,8.45-30.95c9.76,4.77,20.31,7.18,31.56,7.18c0.24,0,0.48-0.01,0.72-0.01c11.93-0.11,22.52-2.82,31.92-7.45
                        c-0.14,11.45,1.43,22.68,8.53,31.17L176.65,355.69z M186.31,386.64l-9.7,11.66l-9.81-11.63l9.82-9.37L186.31,386.64z M76,128.12
                        c0.1-11.13,9.52-24.07,27.22-37.41c26.16-19.57,42.97-28.3,73.44-28.3c30.1,0,46.99,8.74,73.38,28.26
                        c17.58,13.37,26.92,26.31,27,37.43c0.04,5.92-2.66,11.63-8.04,17.02c-27.66-16.62-57.94-24.72-92.34-24.72
                        c-34.59,0-64.96,8.09-92.64,24.72C78.64,139.73,75.95,134.03,76,128.12z M176.66,137.61c30.24,0,55.98,6.49,80.17,20.32
                        c-7.39,7.34-17.78,13.16-31.33,10.47c-3.36-0.71-6.8-1.56-10.34-2.42c-11.04-2.7-23.54-5.75-38.23-6.22
                        c-0.09,0-0.18,0.01-0.28,0.02c-0.09-0.01-0.18-0.02-0.27-0.02c-14.95,0.47-27.53,3.53-38.61,6.24c-3.52,0.86-6.93,1.69-10.16,2.38
                        c-13.58,2.68-24.03-3.11-31.42-10.45C120.41,144.09,146.23,137.61,176.66,137.61z M97.67,180.35c7.23,3.89,15.02,5.9,23.15,5.9
                        c3.38,0,6.81-0.35,10.29-1.04c3.5-0.74,7.08-1.61,10.77-2.52c10.69-2.61,21.76-5.3,34.78-5.74c12.77,0.44,23.77,3.12,34.4,5.72
                        c3.72,0.91,7.32,1.79,10.95,2.55c3.45,0.69,6.86,1.03,10.21,1.03c8.09,0,15.84-2.01,23.06-5.87
                        c-4.05,43.29-25.69,112.95-78.7,113.43c-15.44-0.18-29.28-5.73-41.12-17.43C113.44,254.64,100.58,214.2,97.67,180.35z
                        M120.81,346.32l41.16,21.17l-12.82,12.21l-38.46-29.47C114.13,349.2,117.52,347.93,120.81,346.32z M141.82,477.43h-63.8v-32.79
                        c0-4.75-3.86-8.59-8.62-8.59c-4.76,0-8.62,3.85-8.62,8.59v32.79H17.37c1.37-43.27,13.08-82.17,32.56-107.68
                        c8.74-11.3,22.23-13.1,37.86-15.2c0.07-0.01,0.14-0.02,0.21-0.03l56.51,43.31c1.56,1.19,3.41,1.78,5.25,1.78
                        c1.53,0,3.05-0.41,4.4-1.21l12.6,14.95L141.82,477.43z M160.32,477.43l16.3-41.87l16.13,41.87H160.32z M292.24,477.43v-32.79
                        c0-4.75-3.85-8.59-8.62-8.59c-4.76,0-8.62,3.85-8.62,8.59v32.79h-63.79l-24.7-64.11l12.39-14.89c1.33,0.78,2.83,1.18,4.34,1.18
                        c1.84,0,3.68-0.58,5.23-1.77l56.79-43.31c0.03,0,0.07,0.01,0.1,0.01c15.55,2.09,28.97,3.9,37.68,15.16
                        c19.51,25.55,31.21,64.45,32.58,107.72H292.24z M278.91,274.72c-14.17-6.23-30.82,0.18-37.05,14.23
                        c-3.08,6.78-3.27,14.67-0.5,21.64c2.78,6.98,8.1,12.39,14.72,15.09c6.43,2.92,23.16,6.85,37.26,6.85c5.52,0,10.63-0.6,14.57-2.1
                        c5.64-2.14,8.18-5.65,9.31-8.22c8.68-19.61-33.18-45.15-38.21-47.43C278.98,274.76,278.94,274.75,278.91,274.72z M300.49,314.84
                        c-7.46,1.89-29.11-1.09-37.56-4.93c-2.54-1.04-4.51-3.04-5.54-5.65c-1.06-2.65-1-5.64,0.2-8.27c1.81-4.09,5.71-6.48,9.87-6.48
                        c1.47,0,2.99,0.3,4.45,0.94C280.42,294.33,296.89,308.15,300.49,314.84z M504.55,289.04c-0.59-1.33-1.27-2.6-2.04-3.8
                        c5.73-4.34,9.49-11.61,9.49-19.83c0-13.26-9.77-24.05-21.78-24.05h-17.61l-0.64-1.78c-7.69-19.87-21.29-34-38.15-40.41v-1.3h-3.83
                        c-3.98-1.2-8.12-2-12.38-2.35v-6.77c0-13.1-10.68-23.76-23.81-23.76h-36.54c-13.13,0-23.81,10.66-23.81,23.76v6.57
                        c-26.27,0.19-48.88,17.13-59.01,44.16l-2.92,7.28c-1.77,4.41,0.38,9.41,4.8,11.17c4.4,1.77,9.43-0.39,11.21-4.79l2.99-7.47
                        c7.78-20.77,23.99-33.17,43.36-33.17h78.61c19.12,0,35.34,12.43,43.32,33.07l2.63,7.29c0.02,0.06,0.05,0.1,0.07,0.16
                        c0.14,0.36,0.29,0.71,0.47,1.04c0.03,0.06,0.08,0.12,0.11,0.18c0.16,0.28,0.34,0.55,0.54,0.81c0.06,0.09,0.13,0.17,0.19,0.26
                        c0.23,0.28,0.47,0.55,0.73,0.8c0.07,0.07,0.14,0.13,0.21,0.19c0.26,0.23,0.53,0.45,0.81,0.65c0.03,0.02,0.06,0.05,0.09,0.07
                        c0.33,0.22,0.67,0.42,1.03,0.6c0.05,0.03,0.1,0.05,0.15,0.07c0.71,0.34,1.47,0.58,2.28,0.71c0.08,0.01,0.16,0.02,0.23,0.04
                        c0.39,0.05,0.79,0.09,1.19,0.09h23.67c2.14,0,4.54,2.93,4.54,6.85c0,3.92-2.4,6.85-4.54,6.85h-11.98c-0.44,0-0.87,0.04-1.29,0.11
                        c-3.26,0.22-6.48,1.01-9.53,2.39c-6.44,2.84-16.81,10.22-24.69,17.56c-12.78,11.9-17.22,21.68-13.66,29.69
                        c1.07,2.57,3.48,6.1,8.96,8.3c4.04,1.62,9.29,2.26,14.93,2.26c13.43,0,29.1-3.63,36.01-6.34c-0.3,8.52-1.95,18.13-4.08,23.55
                        H337.67c-4.76,0-8.62,3.85-8.62,8.59c0,4.75,3.86,8.59,8.62,8.59h94.55v12.97c0,11.16,9.11,20.25,20.31,20.25h25.71
                        c11.19,0,20.31-9.09,20.31-20.25v-19.02c6.87-11.96,9.33-36.74,6.38-50.41c0.02-0.06,0.05-0.12,0.08-0.19
                        C507.75,303.29,507.58,295.7,504.55,289.04z M400.37,195.31h-49.68v-6.56c0-3.68,2.89-6.56,6.57-6.56h36.54
                        c3.69,0,6.57,2.88,6.57,6.56V195.31z M481.31,379.94c0,1.66-1.41,3.06-3.07,3.06h-25.71c-1.66,0-3.07-1.41-3.07-3.06v-12.97h31.86
                        V379.94z M488.93,304.08c-1.03,2.66-3.07,4.8-5.7,5.91c-8.52,3.73-29.98,6.71-37.41,4.87c3.49-6.75,20.09-20.65,28.6-24.41
                        c0.03-0.01,0.05-0.02,0.08-0.03c2.57-1.17,5.49-1.24,8.17-0.2c2.75,1.04,4.91,3.08,6.12,5.83
                        C489.94,298.55,489.99,301.4,488.93,304.08z M445.37,270.07c0-4.75-3.86-8.59-8.62-8.59H309.33c-4.76,0-8.62,3.85-8.62,8.59
                        c0,4.75,3.86,8.59,8.62,8.59h127.42C441.51,278.66,445.37,274.82,445.37,270.07z M339.73,299.95c-4.76,0-8.62,3.84-8.62,8.59
                        s3.86,8.59,8.62,8.59h66.63c4.76,0,8.62-3.85,8.62-8.59s-3.86-8.59-8.62-8.59H339.73z M401.2,97.32l21.03,27.12
                        c1.22,1.58,3.11,2.5,5.11,2.5c2,0,3.89-0.92,5.12-2.5l21.03-27.12c0.87-0.8,1.71-1.63,2.51-2.5
                        c14.44-15.78,13.32-40.25-2.51-54.66c-15.83-14.4-40.37-13.28-54.81,2.51C384.25,58.45,385.37,82.92,401.2,97.32z M427.35,43.12
                        c14.29,0,25.86,11.55,25.86,25.79c0,7.36-3.2,14.36-8.77,19.19c-0.29,0.27-0.57,0.56-0.82,0.88l-16.27,20.98l-16.27-20.98
                        c-0.25-0.31-0.52-0.61-0.81-0.88c-5.57-4.83-8.77-11.83-8.77-19.19C401.49,54.66,413.07,43.12,427.35,43.12z M427.35,88.25
                        c10.71,0,19.4-8.66,19.4-19.35c0-10.68-8.68-19.34-19.4-19.34c-10.71,0-19.4,8.66-19.4,19.34
                        C407.95,79.59,416.64,88.25,427.35,88.25z M427.35,62.46c3.57,0,6.47,2.89,6.47,6.45c0,3.56-2.9,6.45-6.47,6.45
                        c-3.57,0-6.46-2.89-6.46-6.45C420.88,65.35,423.78,62.46,427.35,62.46z M420.88,184.98h12.93v-12.9h-12.93V184.98z M433.82,146.29
                        h-12.93v12.9h12.93V146.29z M375.62,36.67h-12.93v12.9h12.93V36.67z M349.76,36.67h-12.93v12.9h12.93V36.67z M323.9,36.67h-12.93
                        v12.9h12.93V36.67z M291.57,36.67h-12.93v12.9h12.93V36.67z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Track Drivers',
					text: 'The admin can have a bird’s eye view of all drivers and their current location.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M359.07,221.7l-45.91-16.09c-8.66-1.69-21.65-11.01-21.65-17.79v-10.16c21.65-19.48,36.38-53.36,40.71-80.47
                        c4.33-11.01,3.46-27.11,3.46-31.34c9.53-7.62,11.26-15.25,11.26-21.18c0-15.25-15.59-27.11-41.57-34.73
                        c-12.99-5.08-29.45-6.78-49.37-6.78c0,0-30.32-1.69-50.24,6.78c-26.85,7.62-41.57,20.33-41.57,34.73c0,5.08,2.6,12.71,11.26,21.18
                        c0,4.24-1.73,21.18,2.6,31.34c5.2,27.11,19.06,60.14,40.71,80.47v10.16c0,6.78-12.13,16.09-21.65,17.79l-45.91,16.09
                        c-34.65,12.71-58.9,46.59-58.9,84.71v66.07H419.7V306.4C419.7,268.28,395.44,235.25,359.07,221.7z M297.57,211.53
                        c4.33,4.24,21.65,9.32,21.65,9.32l-26.85,34.73l-25.98-29.65L297.57,211.53z M231.74,186.97c7.8,4.24,15.59,6.78,24.25,6.78
                        c8.66,0,17.32-2.54,24.25-6.78c0,0-0.87,6.78,6.93,16.09l-31.18,14.4l-31.18-14.4C232.61,193.74,231.74,186.97,231.74,186.97z
                        M176.31,46.36c0-16.09,34.65-24.56,34.65-24.56c12.99-6.78,45.91-5.08,45.91-5.08c19.06,0,34.65,0.85,45.04,5.08
                        c0,0,33.78,7.62,33.78,24.56c0,7.62-12.13,15.25-12.13,15.25s0.87,9.32,0.87,12.71c-44.17-12.71-92.68-12.71-136.85,0
                        c0-3.39,0.87-12.71,0.87-12.71S176.31,54.83,176.31,46.36z M190.17,93.79c-0.87-3.39-0.87-5.93-0.87-9.32
                        c43.31-12.71,90.08-12.71,133.39,0c0,3.39-0.87,5.93-0.87,9.32c-3.46,10.16-36.38-7.62-58.03-7.62c-2.6,0-12.99,0-15.59,0
                        C218.75,87.86,196.23,103.11,190.17,93.79z M193.63,109.04c12.99,0.85,37.24-9.32,54.57-11.01c3.46,0,12.99,0,15.59,0
                        c14.72,0.85,41.58,11.86,54.57,11.01c-9.53,34.73-34.65,73.69-62.36,73.69C228.28,181.88,203.16,143.77,193.63,109.04z
                        M215.29,211.53l31.18,14.4l-25.99,29.65l-26.85-34.73C192.77,220.85,210.95,214.92,215.29,211.53z M229.14,360.61H104.42V306.4
                        c0-33.03,21.65-61.83,51.97-72.85l24.25-8.47l38.11,49.98l14.72-16.94l6.93,14.4L229.14,360.61z M242.14,359.77l11.26-89.79
                        l-11.26-22.02l13.86-15.25l12.99,15.25l-10.39,22.87l12.13,88.94H242.14z M281.98,359.77l-11.26-88.09l6.93-14.4l14.72,16.94
                        l38.11-49.98l24.25,8.47c31.18,11.01,51.97,40.66,51.97,72.85l0.87,54.21H281.98z M328.75,315.72L353,331.81l24.25-16.09v-38.96
                        h-48.5V315.72z M340.88,288.61h24.25v21.18L353,317.41l-12.13-7.62V288.61z M274.18,42.12V21.79H237.8v20.33l18.19,17.79
                        L274.18,42.12z M249.93,33.65h12.13v3.39l-6.06,5.93l-6.06-5.93V33.65z M225.68,388.57c0,21.18,16.46,37.27,38.11,37.27h23.39
                        v8.47l-53.7,6.78l-64.96-14.4l-14.72-13.55c-15.59-15.25-41.58-15.25-57.17,0c-3.46,3.39-3.46,7.62,0,10.16l53.7,52.52
                        c0.87,0.85,1.73,1.69,2.6,1.69l77.09,29.65c0.87,0,1.73,0.85,3.46,0.85c0.87,0,0.87,0,1.73,0l90.95-15.25h90.95v-14.4h-61.5
                        v-82.16h61.5v-15.25H233.47C228.28,380.94,225.68,383.48,225.68,388.57z M340.88,395.34v83.01h-15.59c-0.87,0-0.87,0-1.73,0
                        l-90.08,15.25l-72.76-28.8l-45.91-44.89c10.39-4.23,21.65-3.39,29.45,4.24l15.59,15.25c0.87,0.85,2.6,1.69,3.46,2.54l69.29,15.25
                        c0.87,0,1.73,0,2.6,0l61.5-7.62c3.46-0.85,6.93-3.39,6.93-7.62v-22.87c0-4.23-3.46-7.62-7.8-7.62h-30.32
                        c-10.39,0-18.19-5.93-21.65-15.25L340.88,395.34z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manage Drivers',
					text: 'The admin has the user privileges to add, edit or remove drivers from time to time.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M170.93,292.32h170.93c5.13,0,8.55-3.55,8.55-8.88v-97.65c0-15.09-11.11-26.63-25.64-26.63h-34.19v-13.32
                        c21.37-19.53,23.08-53.26,4.27-75.46c-18.8-22.19-51.28-23.97-72.65-4.44c-21.37,19.53-23.08,53.26-4.27,75.46
                        c0.85,1.77,2.56,2.66,4.27,4.44v13.32h-34.19c-14.53,0-25.64,11.54-25.64,26.63v97.65C162.39,288.77,165.81,292.32,170.93,292.32z
                        M179.48,185.79c0-5.33,3.42-8.88,8.55-8.88h42.73c5.13,0,8.55-3.55,8.55-8.88v-26.63c0-2.66-0.85-5.33-3.42-7.1
                        c-15.38-11.54-17.95-33.73-6.84-49.71c11.11-15.98,32.48-18.64,47.86-7.1c15.38,11.54,17.95,33.73,6.84,49.71
                        c-1.71,2.66-4.27,5.33-6.84,7.1c-2.56,1.78-3.42,4.44-3.42,7.1v26.63c0,5.33,3.42,8.88,8.55,8.88h42.73
                        c5.13,0,8.55,3.55,8.55,8.88v88.77H179.48V185.79z M145.29,274.57H17.09V221.3c0-5.33,3.42-8.88,8.55-8.88h34.19
                        c5.13,0,8.55-3.55,8.55-8.88v-26.63c0-2.66-0.85-5.33-3.42-7.1c-15.38-11.54-17.95-33.73-6.84-49.71
                        c11.11-15.98,32.48-18.64,47.86-7.1c15.39,11.54,17.95,33.73,6.84,49.71c-1.71,2.66-4.27,5.33-6.84,7.1
                        c-2.56,1.78-3.42,4.44-3.42,7.1v26.63c0,5.33,3.42,8.88,8.55,8.88h34.19v-17.76h-25.64v-13.32c21.37-19.53,23.08-53.26,4.27-75.46
                        c-18.8-22.19-51.28-23.97-72.65-4.44c-21.37,19.53-23.08,53.26-4.27,75.46c0.85,1.77,2.56,2.66,4.27,4.44v13.32H25.64
                        C11.11,194.67,0,206.21,0,221.3v62.14c0,5.33,3.42,8.88,8.55,8.88h136.75V274.57z M487.16,194.67h-25.64v-13.32
                        c21.37-19.53,23.08-53.26,4.27-75.46c-18.8-22.19-51.28-23.97-72.65-4.44c-21.37,19.53-23.08,53.26-4.27,75.46
                        c0.85,1.77,2.56,2.66,4.27,4.44v13.32h-25.64v17.76h34.19c5.13,0,8.55-3.55,8.55-8.88v-26.63c0-2.66-0.85-5.33-3.42-7.1
                        c-15.39-11.54-17.95-33.73-6.84-49.71c1.71-2.66,3.42-4.44,5.98-6.21c14.53-12.43,35.9-10.65,47.86,4.44
                        c11.97,15.09,10.26,37.29-4.27,49.71l-0.85,0.89c-3.42,2.66-4.27,5.33-4.27,7.99v26.63c0,5.33,3.42,8.88,8.55,8.88h34.19
                        c5.13,0,8.55,3.55,8.55,8.88v53.26h-128.2v17.76h136.75c5.13,0,8.55-3.55,8.55-8.88V221.3
                        C512.8,206.21,501.69,194.67,487.16,194.67z M247.85,318.96c0,24.86,18.8,44.39,42.73,44.39h25.64v9.77l-59.83,7.99l-72.65-16.87
                        l-15.39-15.98c-17.09-17.76-46.15-17.76-63.24,0c-3.42,3.55-3.42,8.88,0,12.43l59.83,62.14c0.85,0.89,1.71,1.78,2.56,1.78
                        l85.47,35.51c0.85,0,1.71,0.89,3.42,0.89c0.85,0,0.85,0,1.71,0l101.71-17.76h101.71v-17.75h-68.37v-97.65h68.37v-17.76H256.4
                        C251.27,310.08,247.85,313.63,247.85,318.96z M376.06,328.72v96.76h-17.09c-0.85,0-0.85,0-1.71,0l-100,17.75l-81.19-33.73
                        l-51.28-53.26c11.11-5.33,23.93-3.55,32.48,5.33l17.09,17.76c0.85,0.89,2.56,1.77,4.27,2.66l76.92,17.76c0.85,0,1.71,0,2.56,0
                        l68.37-8.88c4.27-0.89,7.69-4.44,7.69-8.88v-26.63c0-5.33-3.42-8.88-8.55-8.88h-34.19c-11.11,0-20.51-7.1-23.93-17.75H376.06z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manage Customers',
					text: 'Similar to drivers, the admin can also add, edit or remove customers from using the app.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M137.49,255.48c19.29,63.86,65.72,106.06,118,106.06c41.73,0,79.72-26.89,103.17-70.21c19.5-9.97,34.8-24.91,34.8-46.44
                        c0-0.67-0.07-1.32-0.19-1.95c6.7-9.57,10.8-23.9,10.8-40.47c0-8.4-1.13-16.63-3.23-24.01c2.19-10.69,3.23-19.96,3.23-29.01
                        C404.08,67.59,337.4,1,255.49,1S106.91,67.59,106.91,149.46c0,9.06,1.04,18.32,3.22,29.01c-2.1,7.38-3.22,15.61-3.22,24.01
                        C106.91,232.02,119.96,254.46,137.49,255.48z M255.49,22.21c70.24,0,127.36,57.07,127.36,127.25c0,6.13-0.59,12.49-1.82,19.59
                        l-12.44,1.02c-2.89-7.8-6.96-20.74-6.96-31.22c0-5.85-4.75-10.61-10.61-10.61c-62.3,0-115.51-42.37-116.74-53.02
                        c0-5.85-4.75-10.61-10.61-10.61c-5.86,0-10.61,4.75-10.61,10.61c0,9.1-2.4,15.93-7.36,20.85c-10.34,10.31-29.99,10.97-42.77,10.97
                        c-5.92,0.02-11.02,1.21-14.92,5.41c-9.51,10.2-10.49,37.62-9.87,56.8c-2.56-0.23-5.36-0.45-8.23-0.62
                        c-1.18-6.93-1.75-13.16-1.75-19.17C128.13,79.28,185.25,22.21,255.49,22.21z M129.25,189.83c9.58,0.58,18.4,1.9,18.52,1.91
                        c3.18,0.47,6.41-0.49,8.81-2.69c2.36-2.18,3.61-5.34,3.38-8.55c-1.61-22.08-0.3-45.15,2.8-52.26
                        c13.84-0.02,40.88-0.19,57.88-17.14c2.8-2.78,5.18-5.85,7.11-9.23c22.86,22.59,68.92,44.37,113.43,47.25
                        c2.53,17.82,10.34,35.54,10.74,36.44c1.72,3.86,5.54,6.3,9.7,6.3c0.3,0,0.57,0,0.87-0.02l19.31-1.58
                        c0.69,3.88,1.05,7.99,1.05,12.2c0,20.59-7.79,32.07-11.27,31.34c-2.87-1.8-6.43-2.12-9.59-0.85c-3.16,1.27-5.52,3.97-6.32,7.27
                        c-3.13,12.62-7.39,24.37-12.59,35.05c-16.28,6.93-35.9,10.74-49.22,11.74c-5.32-6.33-15.4-10.33-27.75-10.33
                        c-18.45,0-31.84,8.91-31.84,21.21c0,12.3,13.39,21.21,31.84,21.21c12.69,0,22.99-4.22,28.18-10.86c8.57-0.57,19.56-2.12,31.1-4.94
                        c-18.93,23.31-43.59,37.01-69.89,37.01c-45.06,0-85.33-40.21-100.17-100.08c-0.81-3.31-3.5-5.75-6.64-7.04
                        c-1.17-0.47-2.42-0.68-3.72-0.68c-2.19,0-4.39,0.61-6.22,1.76c-2.63,0-10.61-11.28-10.61-31.81
                        C128.13,198.11,128.52,193.84,129.25,189.83z M202.43,213.08h21.23c5.86,0,10.61-4.75,10.61-10.6c0-5.85-4.75-10.61-10.61-10.61
                        h-21.23c-5.86,0-10.61,4.75-10.61,10.61C191.81,208.33,196.57,213.08,202.43,213.08z M287.33,213.08h21.23
                        c5.86,0,10.61-4.75,10.61-10.6c0-5.85-4.75-10.61-10.61-10.61h-21.23c-5.86,0-10.61,4.75-10.61,10.61
                        C276.72,208.33,281.47,213.08,287.33,213.08z M509.91,496.79l-16.49-65.89c-3.78-15.04-13.9-27.63-27.76-34.57l-109.89-54.89
                        c-2.61-1.29-5.65-1.44-8.41-0.47c-2.76,1-4.94,3.1-6.11,5.79L286.59,474.2l-8.04-16.06c7.09-9.71,19.4-28.78,19.4-43.58
                        c0-19.62-16.28-31.81-42.45-31.81c-26.17,0-42.45,12.2-42.45,31.81c0,14.8,12.31,33.87,19.4,43.63l-8.04,16.05L169.74,346.8
                        c-1.17-2.69-3.35-4.79-6.11-5.79c-2.76-1-5.8-0.83-8.41,0.47L45.33,396.37c-13.88,6.94-23.99,19.53-27.76,34.57L1.08,496.83
                        c-0.79,3.16-0.08,6.53,1.93,9.1c2.02,2.57,5.12,4.07,8.39,4.07h488.2c3.27,0,6.37-1.51,8.39-4.11
                        C509.99,503.32,510.69,499.95,509.91,496.79z M25,488.79l13.22-52.72c2.25-9.01,8.3-16.58,16.64-20.74l99.8-49.86l52.92,123.33H25
                        z M256.62,461.73l13.56,27.06h-29.35l13.56-27.06c1.85-3.69,1.38-8.14-1.21-11.37c-7.64-9.52-18.91-27.49-18.91-35.8
                        c0-9.56,14.84-10.6,21.23-10.6c6.39,0,21.23,1.04,21.23,10.6c0,8.38-11.06,25.98-18.89,35.8
                        C255.26,453.61,254.77,458.04,256.62,461.73z M303.42,488.79l52.94-123.35l99.81,49.86c8.32,4.16,14.39,11.73,16.64,20.76
                        l13.2,52.72H303.42z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manage Dispatchers',
					text: 'The admin can add dispatch manager to handle operations and dispatches for a particular region and services.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M510.59,328.86c0.46-8.45-1.16-18.63-9.56-25.51c-3.8-3.12-8.61-4.4-14.28-3.8c-1.18,0.12-2.38,0.34-3.58,0.61
                        c-0.01-0.45-0.03-0.91-0.05-1.37c0.43-7.9-0.84-18.65-9.95-22.48c-13.49-5.66-22.21,0.14-30.33,7.89
                        c-0.46-5.79-2.52-11.91-8.69-14.5c-12.59-5.3-20.31,0.93-27.74,8.35c-0.25,0.25-0.63,0.61-0.93,0.91
                        c-0.73-1.35-1.74-2.65-3.14-3.78c-4.39-3.53-10.91-3.84-19.35-0.94c-19.16,6.57-61.85,48.38-70.21,56.7
                        c-20.34-1.52-41.72-0.65-57.92,0.03c-8.71,0.37-17.72,0.75-19.63,0.25c-3.86-1.02-9.96-5.02-16.41-9.26
                        c-11.67-7.66-26.19-17.2-41.43-19.18c-23.72-3.1-75.47,11.49-77.67,12.12l-38.1,10.83H0.33v121.62l62.35-0.02
                        c0.58-0.05,58.6-5.93,89.75,10.47c25.39,13.36,61.32,20.22,105.18,20.21c11.8,0,24.19-0.49,37.09-1.5
                        c83.38-6.44,152.82-47.77,212.28-126.34l0.62-1.14C507.84,348.38,511.19,339.12,510.59,328.86z M57.79,433.39H9.18v-93.69h48.61
                        V433.39z M447.04,297.55c9.81-9.78,14.47-11.41,22.72-7.95c2.92,1.23,4.13,5.09,4.44,9.36c-0.1,1.62-0.28,3.18-0.51,4.54
                        c-13.86,6.49-26.97,18.93-30.96,23.88c-4.82,5.96-36.43,33.59-66.36,54.01c0.37-2.24,0.56-4.56,0.56-6.95
                        c0-0.76-0.04-1.5-0.1-2.24c0.06-0.93,0.1-1.87,0.1-2.82c0-3.23-0.53-6.25-1.49-9.09C398.55,345.6,441.76,302.81,447.04,297.55z
                        M412.63,289.45c7.88-7.86,11.6-9.19,18.11-6.46c2.03,0.85,2.93,3.56,3.21,6.67c-0.1,1.34-0.27,2.62-0.46,3.7
                        c-20.11,19.61-49.85,46.97-64.95,55.66c-0.21-0.21-0.36-0.46-0.58-0.68c-3.62-3.49-8.23-6.3-13.51-8.61
                        C375.54,324.19,406.92,295.13,412.63,289.45z M385.84,287.74c5.11-1.77,9.09-1.97,10.91-0.57c-16.25,14.95-45.74,40.79-58.22,47.4
                        c-3.12-0.71-6.36-1.31-9.67-1.83C346.34,316.1,373.39,292.02,385.84,287.74z M499.5,340.29
                        c-57.74,76.05-124.96,116.06-205.48,122.28c-59.19,4.57-106.73-1.55-137.49-17.73c-19.78-10.41-48.33-12.61-68.84-12.61
                        c-9.1,0-16.58,0.43-21.05,0.77v-94.53l35.46-9.93c0.52-0.14,52.22-14.67,74.13-11.86c13.23,1.72,26.82,10.64,37.73,17.81
                        c7.38,4.85,13.76,9.03,19.01,10.42c3.05,0.81,9.36,0.58,22.24,0.03c30.54-1.29,87.4-3.68,106.63,14.89
                        c3.63,3.51,5.64,7.57,6.12,12.36c-0.58,7.44-3.51,13.6-8.93,18.76c-2.59,2.46-5.68,4.63-9.17,6.52c-2.37,1.19-4.64,2.25-6.81,3.16
                        c-2.66,1.06-5.48,2-8.41,2.84c-0.05,0.01-0.09,0.02-0.14,0.04v0.01c-31.03,8.83-76.08,6.28-100.88,4.87l-0.5,8.9
                        c0.09,0.01,0.19,0.01,0.28,0.02l-0.28,5.04c48.56,2.74,96.51,2.84,123.23-13.24c36.05-19.13,86.72-62.93,93.26-71.04
                        c6.16-7.64,25.17-23.25,38.06-24.59c3.3-0.35,5.85,0.26,7.78,1.85c4.25,3.48,5.89,8.55,6.27,13.56
                        C501.36,334.18,500.01,338.72,499.5,340.29z M146.03,176.29c-9.74,0-17.66,7.98-17.66,17.78c0,0.86,0.08,1.7,0.2,2.53
                        c-0.12,0.83-0.2,1.67-0.2,2.53c0,9.8,7.92,17.78,17.66,17.78c9.73,0,17.65-7.98,17.65-17.78c0-0.86-0.08-1.7-0.2-2.53
                        c0.12-0.83,0.2-1.67,0.2-2.53C163.68,184.26,155.77,176.29,146.03,176.29z M146.03,202.94c-3.99,0-7.36-2.68-8.45-6.34
                        c1.08-3.66,4.46-6.34,8.45-6.34c3.98,0,7.35,2.68,8.43,6.34C153.38,200.26,150.02,202.94,146.03,202.94z M327.7,216.9
                        c9.73,0,17.65-7.98,17.65-17.78c0-0.86-0.08-1.7-0.2-2.53c0.12-0.83,0.2-1.67,0.2-2.53c0-9.81-7.92-17.78-17.65-17.78
                        c-9.74,0-17.66,7.98-17.66,17.78c0,0.86,0.08,1.7,0.2,2.53c-0.12,0.83-0.2,1.67-0.2,2.53C310.03,208.93,317.96,216.9,327.7,216.9z
                        M327.7,190.25c3.98,0,7.35,2.68,8.43,6.34c-1.08,3.66-4.45,6.34-8.43,6.34c-3.99,0-7.36-2.68-8.45-6.34
                        C320.33,192.93,323.71,190.25,327.7,190.25z M309.15,135.75l-0.84-5.06h0.84l-8.7-52.51H173.26l-8.7,52.51h0.84l-0.84,5.06H309.15
                        z M180.76,92.15h112.21l4.91,29.64h-6.03l0.67-0.11l-1.41-8.79l-5.55,0.9l-1.25-4.77l-48,12.77h-60.45L180.76,92.15z
                        M124.71,262.71c0,10.46,8.46,18.98,18.86,18.98h19.85c10.4,0,18.86-8.52,18.86-18.98v-25.83h109.15v25.83
                        c0.01,10.46,8.47,18.98,18.86,18.98h19.85c10.4,0,18.86-8.52,18.86-18.98v-25.83h17.4v-63.4c0-12.01-15.74-23.74-25.98-30.1
                        c2.1-1.31,4.36-3.78,5.79-8.46c1.05-3.45,1.48-7.86,1.2-12.21c0.42-6.76-0.85-14.38-4.23-18.78c-2.84-3.7-6.17-4.6-8.47-4.71
                        c-1.9-0.08-3.69,0.39-5.3,1.04c-1.18-9.67-2.24-18.34-2.74-22.4c-2.29-18.41-22.76-23.27-33.27-23.27h-25.05v-5.84
                        c0-10.13-16.33-14.75-31.49-14.75c-15.17,0-31.49,4.62-31.49,14.75v5.84h-25.06c-10.5,0-30.96,4.86-33.26,23.27
                        c-0.51,4.06-1.56,12.72-2.74,22.39c-1.6-0.65-3.37-1.1-5.29-1.03c-2.29,0.11-5.62,1.02-8.46,4.72c-3.38,4.4-4.65,12.02-4.23,18.78
                        c-0.27,4.35,0.15,8.76,1.2,12.22c1.42,4.67,3.67,7.15,5.77,8.45c-10.24,6.36-26,18.09-26,30.11v63.4h17.41V262.71z M173.45,257.65
                        L173.45,257.65c-0.01,5.56-4.5,10.07-10.01,10.07h-19.85c-5.52,0-10.01-4.52-10.01-10.07v-20.77h39.87V257.65z M204.29,213.59
                        h-17.96v-23.33h17.96V213.59z M232.44,213.59h-19.3v-23.33h19.3V213.59z M260.59,213.59H241.3v-23.33h19.29V213.59z
                        M287.39,213.59h-17.95v-23.33h17.95V213.59z M340.16,257.65L340.16,257.65c-0.01,5.56-4.5,10.07-10.02,10.07h-19.85
                        c-5.52,0-10-4.52-10-10.07v-20.77h39.87V257.65z M334.3,113.16c0.25,0.01,0.94,0.05,1.88,1.27c1.2,1.56,2.08,4.78,2.37,8.36
                        c-0.13,1.56-0.39,3.08-0.81,4.46c-0.76,2.51-1.71,3.43-2.15,3.58c-1.13,0.39-1.72,0.3-1.88,0.21c-0.29-0.18-0.63-0.97-0.71-1.43
                        h-0.02c-0.54-4.45-1.18-9.72-1.85-15.22C332.21,113.67,333.4,113.09,334.3,113.16z M236.86,47.94c14.27,0,22.09,4.32,22.64,5.84
                        v0.79h-45.29l0-0.67C214.8,52.24,222.62,47.94,236.86,47.94z M137.56,114.44c0.94-1.22,1.62-1.25,1.88-1.27
                        c0.89-0.07,2.08,0.5,3.15,1.21c-0.67,5.5-1.31,10.77-1.85,15.23c-0.02,0.23-0.34,1.2-0.71,1.43c-0.15,0.1-0.74,0.16-1.88-0.21
                        c-0.44-0.15-1.39-1.06-2.15-3.58c-0.42-1.38-0.68-2.9-0.81-4.46C135.48,119.21,136.37,115.99,137.56,114.44z M116.16,222.91
                        v-44.38c0-8.13,17.23-20.63,29.46-26.91l2.1-1.08l0.28-2.35c0,0,6.32-52.16,7.82-64.18c1.89-15.21,24.26-15.47,24.47-15.47h113.09
                        c0.22,0,22.61,0.26,24.5,15.47c1.49,12.02,7.82,64.19,7.82,64.19l0.29,2.35l2.1,1.08c12.23,6.27,29.46,18.77,29.46,26.91v44.38
                        h-61.31v-46.62H177.48v46.62H116.16z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manage Vehicles',
					text: 'Admin can monitor the vehicle inventory and assign drivers to them for effective usage.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M230.7,278.29h142.33c6.7,0.03,13.1,2.69,17.79,7.4l106.16,104.51l14.04-13.62l-106.16-104.7
                    c-8.43-8.33-19.88-13.02-31.83-13.04h-30.25v-88.35h22.34V81.54H250.86l2.37-1.95c22.54-22.18,28.27-50.99,13.44-65.78
                    c-14.83-14.79-44.48-8.95-67.02,13.23c-4.45,4.52-8.43,9.48-11.86,14.79c-3.29-5.36-7.2-10.33-11.66-14.79
                    C153.6,4.86,124.35-0.98,109.32,13.81c-8.02,8.85-10.66,21.23-6.92,32.5c3.49,12.62,10.31,24.1,19.77,33.28
                    c0.63,0.75,1.36,1.4,2.17,1.95H0v88.94h26.29v222.83h26.29c-2.46,4.62-3.75,9.77-3.75,14.99c-0.23,18.05,14.11,33.02,32.42,33.86
                    l0,0l170.8,33.08c20.06,3.88,40.45,5.83,60.89,5.84h82.43c12.89-0.03,25.29,4.85,34.6,13.62l12.06,11.68l12.65-14.01l-12.26-11.48
                    c-12.86-12.46-30.19-19.45-48.24-19.46h-80.85c-18.91,0.06-37.78-1.7-56.34-5.25L84.02,422.89c-8.24-0.94-14.15-8.27-13.21-16.39
                    c0.8-6.83,6.27-12.21,13.21-13H316.3c19.11,0,34.6-15.25,34.6-34.06c0-18.81-15.49-34.06-34.6-34.06h-85.6
                    c-13.26,1.18-24.99-8.44-26.2-21.5c-1.21-13.06,8.57-24.61,21.84-25.79c1.45-0.13,2.91-0.13,4.36,0V278.29z M323.02,258.83h-84.41
                    v-88.35h84.41V258.83z M345.36,151.4H238.61V101h106.75V151.4z M218.84,101.39v50.02h-60.69V101L218.84,101.39z M199.07,65.58
                    c2.71-9.28,7.9-17.68,15.03-24.33c7.97-8.83,18.93-14.5,30.84-15.96c2.81-0.21,5.59,0.7,7.71,2.53c5.93,5.84,1.58,23.55-13.44,38.92
                    c-15.02,15.37-33.01,19.46-39.54,13.23c-2.88-4.44-3.25-10.02-0.99-14.79L199.07,65.58z M121.97,42.03
                    c-1.89-4.66-1.22-9.95,1.78-14.01c2.1-1.87,4.89-2.79,7.71-2.53c11.96,1.32,23.01,6.93,31.04,15.76
                    c15.02,14.79,19.77,32.31,13.44,38.92c-4.2,2.79-9.52,3.37-14.23,1.56c-9.37-2.68-17.83-7.79-24.51-14.79
                    c-7.33-6.97-12.7-15.69-15.62-25.3L121.97,42.03z M19.77,101h118.61v50.02H19.77V101z M87.38,373.46H46.06V170.48h92.32v203.57
                    L87.38,373.46z M330.73,358.86c0,8.06-6.64,14.6-14.83,14.6h-77.3v-29.19h77.69c8.08,0.1,14.55,6.62,14.45,14.58
                    c0,0.2-0.01,0.4-0.02,0.61V358.86z M218.84,342.32v31.14h-60.69V170.48h61.68v89.91c-23.14,6.32-36.71,29.91-30.29,52.7
                    c4.08,14.48,15.58,25.8,30.29,29.82L218.84,342.32z"
					></path>
				</svg>`,
					title: 'Manage Categories',
					text: 'Dispatch manager and Admin can add and update different car categories, location wise or by services demand.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M392.3,306.8h-22.8c-2.85,0-5.7,2.85-5.7,5.7c0,2.85,2.85,5.7,5.7,5.7h22.8c2.85,0,5.7-2.85,5.7-5.7
                        C398,309.65,395.15,306.8,392.3,306.8z M477.8,21.8H455c-6.27,0-11.4,5.13-11.4,11.4V56c0,6.27,5.13,11.4,11.4,11.4h22.8
                        c6.27,0,11.4-5.13,11.4-11.4V33.2C489.2,26.93,484.07,21.8,477.8,21.8z M477.8,56H455V33.2h22.8V56z M232.13,197.93l-3.42,20.52
                        c-0.57,2.28,0.57,4.56,2.28,5.7c1.71,1.71,4.56,1.71,6.27,0.57l18.24-9.69l18.24,10.26c1.14,0.57,1.71,0.57,2.85,0.57
                        c1.14,0,2.28-0.57,3.42-1.14c1.71-1.14,2.85-3.42,2.28-5.7l-3.42-20.52l14.82-14.82c1.14-1.14,1.71-3.42,1.14-5.7
                        c-0.57-2.28-2.28-3.42-4.56-3.99l-20.52-2.85l-9.12-18.81c-1.71-3.99-8.55-3.99-10.26,0l-9.12,18.81l-20.52,2.85
                        c-2.28,0.57-3.99,1.71-4.56,3.99c-1.14,1.71-0.57,3.99,1.14,5.7L232.13,197.93z M244.67,181.4c2.28,0,3.99-1.14,4.56-2.85
                        l5.7-10.83l5.7,10.83c1.14,1.71,2.85,2.85,4.56,2.85l11.97,2.28l-8.55,8.55c-1.14,1.14-1.71,3.42-1.71,5.13l2.28,11.4l-10.83-5.7
                        c-1.14-0.57-1.71-0.57-2.85-0.57c-1.14,0-2.28,0-2.85,0.57l-10.83,5.7l2.28-11.97c0-1.71-0.57-3.99-1.71-5.13l-8.55-8.55
                        L244.67,181.4z M44.6,21.8c-12.54,0-22.8,10.26-22.8,22.8c0,12.54,10.26,22.8,22.8,22.8c12.54,0,22.8-10.26,22.8-22.8
                        C67.4,32.06,57.14,21.8,44.6,21.8z M44.6,56c-6.27,0-11.4-5.13-11.4-11.4c0-6.27,5.13-11.4,11.4-11.4c6.27,0,11.4,5.13,11.4,11.4
                        C56,50.87,50.87,56,44.6,56z M494.9-1H16.1C6.41-1-1,6.41-1,16.1v478.8c0,9.12,7.41,17.1,17.1,17.1h478.8
                        c9.69,0,17.1-7.98,17.1-17.1V16.1C512,6.41,504.59-1,494.9-1z M500.6,494.9c0,2.85-2.85,5.7-5.7,5.7H16.1
                        c-2.85,0-5.7-2.85-5.7-5.7V90.2h490.2V494.9z M500.6,78.8H10.4V16.1c0-2.85,2.85-5.7,5.7-5.7h478.8c2.85,0,5.7,2.85,5.7,5.7V78.8z
                        M118.7,318.2h11.4c2.85,0,5.7-2.85,5.7-5.7c0-2.85-2.85-5.7-5.7-5.7h-11.4c-2.85,0-5.7,2.85-5.7,5.7
                        C113,315.35,115.85,318.2,118.7,318.2z M84.5,443.6h96.9v28.5c0,2.85,2.85,5.7,5.7,5.7h136.8c2.85,0,5.7-2.85,5.7-5.7v-28.5h96.9
                        c2.85,0,5.7-2.85,5.7-5.7v-285c0-2.85-2.85-5.7-5.7-5.7h-96.9v-28.5c0-2.85-2.85-5.7-5.7-5.7H187.1c-2.85,0-5.7,2.85-5.7,5.7v28.5
                        H84.5c-2.85,0-5.7,2.85-5.7,5.7v285C78.8,440.75,81.65,443.6,84.5,443.6z M329.6,405.98c3.42,2.28,7.41,3.42,11.4,3.42h34.2
                        c12.54,0,22.8-10.26,22.8-22.8c0-12.54-10.26-22.8-22.8-22.8H341c-3.99,0-7.98,1.14-11.4,3.42V318.2h5.7c2.85,0,5.7-2.85,5.7-5.7
                        c0-2.85-2.85-5.7-5.7-5.7h-5.7v-57.57c7.41,7.41,17.1,11.97,28.5,11.97c22.23,0,39.9-17.67,39.9-39.9
                        c0-22.23-17.67-39.9-39.9-39.9c-11.4,0-21.09,4.56-28.5,11.97V158.6h91.2v273.6h-91.2V405.98z M329.6,386.6
                        c0-6.27,5.13-11.4,11.4-11.4h34.2c6.27,0,11.4,5.13,11.4,11.4c0,6.27-5.13,11.4-11.4,11.4H341
                        C334.73,398,329.6,392.87,329.6,386.6z M329.6,221.3c0-15.96,12.54-28.5,28.5-28.5c15.96,0,28.5,12.54,28.5,28.5
                        c0,15.96-12.54,28.5-28.5,28.5C342.14,249.8,329.6,237.26,329.6,221.3z M192.8,124.4h125.4v342H192.8V124.4z M90.2,158.6h91.2
                        v34.77c-7.41-7.41-17.1-11.97-28.5-11.97c-22.23,0-39.9,17.67-39.9,39.9c0,22.23,17.67,39.9,39.9,39.9
                        c11.4,0,21.09-4.56,28.5-11.97v57.57h-17.1c-2.85,0-5.7,2.85-5.7,5.7c0,2.85,2.85,5.7,5.7,5.7h17.1v49.02
                        c-3.42-2.28-7.41-3.42-11.4-3.42h-34.2c-12.54,0-22.8,10.26-22.8,22.8c0,12.54,10.26,22.8,22.8,22.8H170
                        c3.99,0,7.98-1.71,11.4-3.42v26.22H90.2V158.6z M181.4,221.3c0,15.96-12.54,28.5-28.5,28.5s-28.5-12.54-28.5-28.5
                        c0-15.96,12.54-28.5,28.5-28.5S181.4,205.34,181.4,221.3z M181.4,386.6c0,6.27-5.13,11.4-11.4,11.4h-34.2
                        c-6.27,0-11.4-5.13-11.4-11.4c0-6.27,5.13-11.4,11.4-11.4H170C176.27,375.2,181.4,380.33,181.4,386.6z M101.6,21.8
                        c-12.54,0-22.8,10.26-22.8,22.8c0,12.54,10.26,22.8,22.8,22.8c12.54,0,22.8-10.26,22.8-22.8C124.4,32.06,114.14,21.8,101.6,21.8z
                        M101.6,56c-6.27,0-11.4-5.13-11.4-11.4c0-6.27,5.13-11.4,11.4-11.4c6.27,0,11.4,5.13,11.4,11.4C113,50.87,107.87,56,101.6,56z
                        M158.6,21.8c-12.54,0-22.8,10.26-22.8,22.8c0,12.54,10.26,22.8,22.8,22.8s22.8-10.26,22.8-22.8
                        C181.4,32.06,171.14,21.8,158.6,21.8z M158.6,56c-6.27,0-11.4-5.13-11.4-11.4c0-6.27,5.13-11.4,11.4-11.4
                        c6.27,0,11.4,5.13,11.4,11.4C170,50.87,164.87,56,158.6,56z M272.6,398h-34.2c-12.54,0-22.8,10.26-22.8,22.8
                        c0,12.54,10.26,22.8,22.8,22.8h34.2c12.54,0,22.8-10.26,22.8-22.8C295.4,408.26,285.14,398,272.6,398z M272.6,432.2h-34.2
                        c-6.27,0-11.4-5.13-11.4-11.4c0-6.27,5.13-11.4,11.4-11.4h34.2c6.27,0,11.4,5.13,11.4,11.4C284,427.07,278.87,432.2,272.6,432.2z
                        M249.93,360.23c0,1.27,0.6,2.53,1.36,3.41c0.9,0.97,2.23,1.46,3.62,1.46c1.26,0,2.44-0.5,3.39-1.37
                        c0.84-0.96,1.42-2.21,1.43-3.47h0.02v-9.62c11.26-1.49,18.58-8.94,18.55-19.52c0-12.15-7.45-15.82-17.8-20.9l-5.66-2.8
                        c-10.68-5.27-12.39-6.51-12.39-12.17c0-9.49,10-10.21,13.07-10.21c3.01,0,12.8,0.71,12.8,10.21c0,2.67,2.2,4.88,4.88,4.88
                        c2.69,0,4.87-2.2,4.87-4.88c0-10.66-7.2-18.15-18.32-19.66v-9.47h-0.07c-0.01-1.25-0.51-2.47-1.38-3.47
                        c-1.83-1.76-5.05-1.76-6.82,0c-0.94,0.85-1.44,2.11-1.44,3.47H250v9.66c-10.54,1.88-17.3,9.2-17.3,19.47
                        c0,12.14,7.48,15.83,17.84,20.91l5.66,2.8c10.66,5.26,12.37,6.51,12.37,12.16c0,9.34-9.99,10.06-13.06,10.06
                        s-13.07-0.72-13.07-10.06c0-2.69-2.19-4.87-4.88-4.87c-2.68,0-4.87,2.18-4.87,4.87c0,10.17,6.77,17.45,17.3,19.32v9.18
                        C249.97,359.82,249.93,360.02,249.93,360.23z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manage Tariffs',
					text: 'The admin can configure custom pricing or set up dynamic pricing that varies with volume and demand.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M398.17,122.17c0-18.28-14.97-33.16-33.37-33.16c-18.39,0-33.36,14.87-33.36,33.16c0,7.96,2.95,15.17,7.68,20.89
                        l-34.44,39.62c-4.28-1.99-9-3.18-14.03-3.18c-4.15,0-8.07,0.85-11.73,2.23l-32.74-37.66c5.23-5.85,8.52-13.47,8.52-21.9
                        c0-18.28-14.97-33.16-33.36-33.16c-18.39,0-33.36,14.87-33.36,33.16c0,7.79,2.83,14.87,7.39,20.54l-34.55,39.75
                        c-4.17-1.87-8.76-2.96-13.62-2.96c-18.4,0-33.36,14.87-33.36,33.15c0,18.28,14.97,33.16,33.36,33.16
                        c18.39,0,33.36-14.87,33.36-33.16c0-7.68-2.75-14.67-7.19-20.3L208,152.52c4.09,1.78,8.59,2.81,13.34,2.81
                        c4.08,0,7.96-0.84,11.57-2.18l32.8,37.72c-5.18,5.85-8.42,13.42-8.42,21.78c0,18.28,14.96,33.16,33.36,33.16
                        c18.39,0,33.36-14.87,33.36-33.16c0.01-7.52-2.62-14.38-6.88-19.95l34.75-39.98c3.98,1.67,8.35,2.6,12.93,2.6
                        C383.2,155.32,398.17,140.45,398.17,122.17z M147.2,229.91c-9.57,0-17.36-7.74-17.36-17.25c0-9.51,7.79-17.24,17.36-17.24
                        c9.56,0,17.35,7.74,17.35,17.24C164.55,222.17,156.77,229.91,147.2,229.91z M221.35,139.42c-9.57,0-17.35-7.74-17.35-17.24
                        c0-9.51,7.79-17.25,17.35-17.25c9.56,0,17.35,7.74,17.35,17.25C238.7,131.68,230.91,139.42,221.35,139.42z M290.65,229.91
                        c-9.56,0-17.35-7.74-17.35-17.25c0-9.51,7.79-17.24,17.35-17.24c9.56,0,17.35,7.74,17.35,17.24
                        C308,222.17,300.22,229.91,290.65,229.91z M347.45,122.17c0-9.52,7.78-17.25,17.35-17.25c9.57,0,17.36,7.74,17.36,17.25
                        c0,9.5-7.79,17.24-17.36,17.24C355.24,139.42,347.45,131.68,347.45,122.17z M287.36,434.32h16.01v-25.59h-16.01V434.32z
                        M320.15,434.31h16.01V349.3h-16.01V434.31z M352.93,434.32h16.01v-128.2h-16.01V434.32z M385.73,434.32h16.01v-46.43h-16.01
                        V434.32z M183.48,296.47c-40.43,0-73.21,32.58-73.21,72.76s32.78,72.75,73.21,72.75c40.43,0,73.21-32.58,73.21-72.75h-73.21
                        V296.47z M238.4,385.13c-6.94,23.63-28.94,40.94-54.93,40.94c-31.54,0-57.21-25.5-57.21-56.85c0-25.82,17.42-47.69,41.2-54.58
                        v54.58v15.91h16.01H238.4z M195.69,290.76v66.9h67.32C263.02,320.71,232.87,290.76,195.69,290.76z M417.58,50.73L372.14,1H43.92
                        v509h424.17V50.73H417.58z M452.08,494.09H59.92V16.91h305.14l40.67,44.51l4.76,5.22h7.08h34.5V494.09z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Reporting &amp; Analytics',
					text: 'Know how users are booking rides, the most frequented routes, peak hours and much more with detailed reporting &amp; analytics.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M138.04,255.48c19.21,63.61,65.42,105.65,117.47,105.65c41.54,0,79.36-26.78,102.7-69.93
                        c19.41-9.93,34.64-24.81,34.64-46.26c0-0.66-0.07-1.31-0.19-1.94c6.67-9.54,10.75-23.81,10.75-40.31c0-8.37-1.12-16.56-3.21-23.91
                        c2.18-10.65,3.21-19.88,3.21-28.9C403.42,68.33,337.05,2,255.51,2c-81.54,0-147.91,66.33-147.91,147.88
                        c0,9.02,1.04,18.25,3.21,28.9c-2.09,7.35-3.21,15.55-3.21,23.91C107.59,232.11,120.59,254.47,138.04,255.48z M255.51,23.12
                        c69.92,0,126.78,56.85,126.78,126.75c0,6.11-0.59,12.44-1.82,19.52l-12.38,1.02c-2.87-7.77-6.93-20.66-6.93-31.1
                        c0-5.83-4.73-10.56-10.57-10.56c-62.02,0-114.99-42.21-116.22-52.81c0-5.83-4.73-10.56-10.57-10.56
                        c-5.83,0-10.57,4.73-10.57,10.56c0,9.06-2.39,15.86-7.33,20.77c-10.29,10.27-29.86,10.92-42.58,10.92
                        c-5.9,0.02-10.97,1.2-14.85,5.39c-9.47,10.16-10.44,37.48-9.83,56.57c-2.55-0.23-5.34-0.45-8.19-0.61
                        c-1.17-6.9-1.74-13.11-1.74-19.1C128.72,79.97,185.59,23.12,255.51,23.12z M129.84,190.09c9.54,0.58,18.32,1.89,18.43,1.91
                        c3.17,0.47,6.38-0.49,8.77-2.68c2.35-2.18,3.59-5.32,3.36-8.51c-1.61-21.99-0.3-44.98,2.79-52.05
                        c13.78-0.02,40.7-0.19,57.62-17.07c2.79-2.77,5.16-5.83,7.08-9.19c22.76,22.5,68.61,44.19,112.92,47.07
                        c2.52,17.74,10.29,35.4,10.69,36.29c1.71,3.84,5.51,6.27,9.66,6.27c0.3,0,0.57,0,0.86-0.02l19.22-1.57
                        c0.68,3.86,1.04,7.96,1.04,12.16c0,20.51-7.75,31.94-11.22,31.22c-2.85-1.8-6.4-2.11-9.55-0.85c-3.15,1.27-5.49,3.95-6.3,7.25
                        c-3.11,12.57-7.36,24.27-12.53,34.91c-16.21,6.91-35.74,10.7-49,11.69c-5.29-6.31-15.33-10.29-27.62-10.29
                        c-18.36,0-31.7,8.87-31.7,21.13c0,12.25,13.33,21.12,31.7,21.12c12.63,0,22.89-4.2,28.05-10.81c8.53-0.56,19.48-2.12,30.96-4.93
                        c-18.85,23.22-43.4,36.86-69.58,36.86c-44.86,0-84.95-40.05-99.72-99.69c-0.8-3.3-3.49-5.72-6.61-7.01
                        c-1.16-0.46-2.41-0.68-3.7-0.68c-2.18,0-4.38,0.61-6.19,1.75c-2.62,0-10.56-11.24-10.56-31.69
                        C128.72,198.33,129.11,194.08,129.84,190.09z M202.68,213.25h21.13c5.83,0,10.57-4.73,10.57-10.56c0-5.83-4.73-10.56-10.57-10.56
                        h-21.13c-5.83,0-10.57,4.73-10.57,10.56C192.11,208.52,196.85,213.25,202.68,213.25z M287.2,213.25h21.13
                        c5.83,0,10.57-4.73,10.57-10.56c0-5.83-4.73-10.56-10.57-10.56H287.2c-5.83,0-10.57,4.73-10.57,10.56
                        C276.64,208.52,281.37,213.25,287.2,213.25z M508.78,495.84l-16.42-65.63c-3.76-14.98-13.84-27.53-27.64-34.43L355.33,341.1
                        c-2.6-1.29-5.62-1.44-8.37-0.46c-2.75,0.99-4.92,3.08-6.09,5.77l-54.41,126.94l-8.01-15.99c7.06-9.67,19.31-28.67,19.31-43.41
                        c0-19.54-16.21-31.69-42.26-31.69c-26.06,0-42.26,12.15-42.26,31.69c0,14.75,12.26,33.74,19.31,43.45l-8.01,15.99l-54.41-126.94
                        c-1.16-2.68-3.34-4.77-6.08-5.77c-2.75-0.99-5.77-0.82-8.37,0.46L46.29,395.81c-13.82,6.91-23.88,19.46-27.64,34.43L2.24,495.88
                        c-0.78,3.15-0.08,6.51,1.92,9.06c2.01,2.56,5.09,4.06,8.35,4.06h486c3.26,0,6.34-1.5,8.35-4.1
                        C508.86,502.35,509.56,498.99,508.78,495.84z M26.05,487.88l13.16-52.52c2.24-8.98,8.26-16.52,16.57-20.66l99.36-49.66
                        l52.68,122.84H26.05z M256.63,460.92l13.5,26.96h-29.22l13.5-26.96c1.84-3.68,1.37-8.11-1.2-11.32
                        c-7.61-9.49-18.83-27.38-18.83-35.66c0-9.53,14.77-10.56,21.13-10.56c6.36,0,21.13,1.04,21.13,10.56
                        c0,8.34-11.01,25.88-18.8,35.66C255.27,452.83,254.79,457.24,256.63,460.92z M303.22,487.88l52.7-122.86l99.36,49.67
                        c8.28,4.14,14.33,11.68,16.57,20.68l13.14,52.52H303.22z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Dispatcher Panel',
					text: 'Dispatch managers get access to this panel to handle and deploy drives as per the demand.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								class="st0"
								d="M468.95,296.55h-24.63V50.26h-32.84V1H33.84v49.26H1v426.9h181.44c3.81,18.71,20.4,32.84,40.22,32.84
                        c19.82,0,36.41-14.13,40.22-32.84h181.44v-98.52h24.63c22.63,0,41.05-18.41,41.05-41.05C510,314.96,491.59,296.55,468.95,296.55z
                        M230.87,17.42h164.19V83.1h16.42V66.68h16.42v229.87h-16.42V99.52h-16.42v197.03h-92.24l-43.65,21.82
                        c-7.32,3.67-11.88,11.03-11.88,19.23c0,8.19,4.56,15.56,11.88,19.23l43.65,21.82h92.24v49.26H230.87V17.42z M296.55,318.04v39.11
                        l-30.03-15.02c-1.73-0.87-2.81-2.61-2.81-4.54s1.08-3.67,2.81-4.54L296.55,318.04z M50.26,17.42h164.19V427.9H50.26V17.42z
                        M427.9,460.74H247.29v8.21c0,13.58-11.05,24.63-24.63,24.63c-13.58,0-24.63-11.05-24.63-24.63v-8.21H17.42V66.68h16.42v377.65
                        h377.65v-65.68h16.42V460.74z M444.32,329.39h-32.84v16.42h32.84v16.42H312.97v-16.42h82.1v-16.42h-82.1v-16.42h131.36V329.39z
                        M468.95,362.23h-8.21v-49.26h8.21c13.58,0,24.63,11.05,24.63,24.63S482.53,362.23,468.95,362.23z M247.29,411.48h131.36v-16.42
                        H247.29V411.48z M132.35,33.84c-36.21,0-65.68,29.46-65.68,65.68c0,36.21,29.46,65.68,65.68,65.68
                        c36.21,0,65.68-29.46,65.68-65.68C198.03,63.3,168.57,33.84,132.35,33.84z M132.35,148.77c-27.17,0-49.26-22.09-49.26-49.26
                        c0-27.17,22.09-49.26,49.26-49.26c27.17,0,49.26,22.09,49.26,49.26C181.61,126.68,159.52,148.77,132.35,148.77z M99.52,198.03
                        h98.52v-16.42H99.52V198.03z M66.68,230.87h131.36v-16.42H66.68V230.87z M66.68,263.71h131.36v-16.42H66.68V263.71z M66.68,296.55
                        h131.36v-16.42H66.68V296.55z M378.64,148.77H247.29v16.42h131.36V148.77z M378.64,181.61H247.29v16.42h131.36V181.61z
                        M378.64,214.45H247.29v16.42h131.36V214.45z M321.18,247.29h-73.89v16.42h73.89V247.29z M66.68,329.39h131.36v-16.42H66.68
                        V329.39z M115.93,362.23h82.1v-16.42h-82.1V362.23z M99.52,345.81H66.68v16.42h32.84V345.81z M66.68,395.06h131.36v-16.42H66.68
                        V395.06z M378.64,33.84H247.29v98.52h131.36V33.84z M362.22,115.94h-98.52V50.26h98.52V115.94z M83.1,181.61H66.68v16.42H83.1
                        V181.61z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Manual Bookings',
					text: 'For bookings that do not get done through the app, the admin can always raise manual bookings.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M477.03,243.49H345.49l-11.6,24.02h131.16v48.03h-152.3l-12.72,22.52l116.19,145.6H237.35l-74.47-93.44l52.01-44.09
                        l-10.66-22.14l-56.32,47.66L64.85,267.51h112.26l-11.6-24.02H33.97c-6.96,0-11.83,6.6-11.97,12.01v240.17
                        c0,6.29,5.71,12.01,11.97,12.01h443.05c6.27,0,11.97-5.72,11.97-12.01V255.5C489,249.21,483.3,243.49,477.03,243.49z
                        M45.95,483.66V282.14l160.72,201.52H45.95z M465.05,483.66H446.9l-47.9-60.04h66.05V483.66z M465.05,399.6h-85.32l-47.9-60.04
                        h133.22V399.6z M244.65,380.65c1.92,4.11,6.32,6.94,10.85,6.94c4.53,0,8.94-2.83,10.85-6.94
                        c32.79-69.27,67.02-138.02,99.54-207.34c5.45-14.37,9.35-35.18,9.35-51.41c0-67.14-54.25-118.58-119.93-118.58
                        c-65.68,0-119.56,51.48-119.56,118.58c0,16.25,3.94,37.08,9.35,51.41C178.14,242.39,211.46,311.57,244.65,380.65z M255.31,27.34
                        c53.37,0,95.98,40.22,95.98,94.57c0,11.58-4.03,32.14-7.86,42.41c-29.03,61.21-58.58,122.38-87.94,183.51
                        c-28.31-61.59-60.28-121.71-87.94-183.51c-3.82-10.29-7.86-30.85-7.86-42.41C159.71,67.52,201.94,27.34,255.31,27.34z
                        M235.59,188.85h60.01c34.85-19.41,7.74-56.3-3.87-79.6l-9.68,11.65c-9.68-15.53-19.36-31.06-27.1-48.53
                        c-11.61,21.35-42.59,54.36-44.52,73.77C208.49,165.56,220.1,183.03,235.59,188.85z M252.17,110.43c-0.15,0.6,0.84,0.21,0.84-1.18
                        c3.87,7.77,7.74,13.59,11.62,21.36c3.87,5.82,9.68,9.71,15.49,9.71c0,0,0,0,1.94,0c1.94,0,1.94,0,3.87,0
                        c9.68,17.47,9.68,25.24,3.87,29.12h-48.4c-7.74-3.88-11.61-11.65-9.68-19.41C229.84,142.48,242.57,123.95,252.17,110.43z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Heat Map',
					text: 'Admin has the access to monitor the demand for taxi and can accordingly deploy drivers in the regions.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M248.46,393.76v-33.58h-43.95v-22.62h76.92c19.23,0,35.02-15.76,35.02-34.95c0-19.19-15.8-34.95-35.02-34.95h-42.58
                    c-32.28,0-58.37-26.04-58.37-58.25s26.1-58.25,58.37-58.25h9.62v-32.9h22.66v32.9h42.58v22.62h-75.54
                    c-19.23,0-35.03,15.76-35.03,34.95s15.8,34.95,35.03,34.95h42.58c32.28,0,58.38,26.04,58.38,58.25c0,32.21-26.1,58.25-58.38,58.25
                    h-9.62v33.58H248.46L248.46,393.76z M89.13,92.22C132.4,47.67,190.78,23,253.27,22.31c0.69,0,2.06,0,2.75,0
                    c60.43,0,117.44,22.62,161.39,64.42h-23.35v22.62l62.5-0.68l-0.69-62.36h-22.66v23.99C384.44,23.68,320.57-1.67,253.27-0.99
                    C184.59-0.3,120.73,27.11,72.65,75.77c-34.34,34.95-57.69,78.81-67.3,126.1c-9.62,46.6-6.18,94.57,9.61,139.12l21.29-7.54
                    C6.03,249.84,25.95,156.64,89.13,92.22z M439.39,434.19c34.34-34.95,57.69-78.81,67.3-126.1c9.61-46.6,6.18-94.58-9.62-139.12
                    l-21.29,7.54c30.22,84.98,10.3,177.5-52.88,241.92c-89.28,91.15-236.25,93.2-328.28,5.48h23.35V401.3l-62.49,0.69l0.69,62.36h22.66
                    v-23.99c49.45,47.29,113.32,70.59,177.19,70.59C322.64,510.95,389.25,485.59,439.39,434.19z"
					></path>
				</svg>`,
					title: 'Manage Refunds',
					text: 'Keep track of refunds that are to be initiated or to be transferred to users after verification.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<path
						d="M311.05-1C220.27-1,143.47,59.39,118.6,142.14H30.28C13.65,142.14,0,155.79,0,172.42v308.3C0,497.35,13.65,511,30.28,511
                    h176.17c16.63,0,30.28-13.65,30.28-30.28V386.6c23,9.18,48.06,14.29,74.32,14.29c110.88,0,200.95-90.06,200.95-200.95
                    C512,89.06,421.94-1,311.05-1z M311.05,15.52c101.96,0,184.43,82.47,184.43,184.43s-82.48,184.43-184.43,184.43
                    S126.62,301.9,126.62,199.95S209.1,15.52,311.05,15.52z M214.71,98.78c-19.68,0-35.79,16.1-35.79,35.78v110.11
                    c0,16.76,11.91,30.27,27.53,34.11v34.71c0,4.56,3.7,8.26,8.26,8.26c1.6,0,3.17-0.47,4.51-1.34l61.26-39.95H407.4
                    c19.68,0,35.79-16.1,35.79-35.79V134.57c0-19.68-16.1-35.78-35.79-35.78H214.71L214.71,98.78z M214.71,115.3H407.4
                    c10.82,0,19.27,8.45,19.27,19.27v110.11c0,10.82-8.45,19.27-19.27,19.27H278.02c-1.6,0-3.16,0.47-4.51,1.34l-50.55,32.97V272.2
                    c0-4.56-3.7-8.26-8.26-8.26c-10.82,0-19.27-8.45-19.27-19.27V134.57C195.44,123.75,203.89,115.3,214.71,115.3L214.71,115.3z
                    M237.29,156.59c-18.14,0-33.03,14.89-33.03,33.03c0,18.15,14.89,33.03,33.03,33.03c18.15,0,33.03-14.89,33.03-33.03
                    C270.32,171.48,255.44,156.59,237.29,156.59z M311.05,156.59c-18.15,0-33.03,14.89-33.03,33.03c0,18.15,14.89,33.03,33.03,33.03
                    s33.03-14.89,33.03-33.03C344.08,171.48,329.2,156.59,311.05,156.59z M384.83,156.59c-18.14,0-33.03,14.89-33.03,33.03
                    c0,18.15,14.89,33.03,33.03,33.03c18.15,0,33.03-14.89,33.03-33.03C417.86,171.48,402.97,156.59,384.83,156.59z M30.28,158.66h84.11
                    c-1.92,9.17-3.18,18.56-3.81,28.14c-1.02-0.42-2.12-0.63-3.23-0.61H74.32c-4.56-0.06-8.31,3.58-8.37,8.14s3.58,8.31,8.14,8.37
                    c0.08,0,0.15,0,0.23,0h33.03c0.95,0.01,1.9-0.15,2.81-0.46c0.87,77.22,45.4,144.01,110.05,176.89v101.59c0,7.77-6,13.76-13.76,13.76
                    H30.28c-7.77,0-13.76-6-13.76-13.76v-308.3C16.52,164.65,22.51,158.66,30.28,158.66z M237.29,173.11c9.22,0,16.52,7.3,16.52,16.52
                    c0,9.22-7.3,16.52-16.52,16.52c-9.22,0-16.51-7.3-16.51-16.52C220.77,180.4,228.07,173.11,237.29,173.11z M311.05,173.11
                    c9.22,0,16.52,7.3,16.52,16.52c0,9.22-7.3,16.52-16.52,16.52c-9.22,0-16.52-7.3-16.52-16.52
                    C294.54,180.4,301.83,173.11,311.05,173.11z M384.83,173.11c9.22,0,16.52,7.3,16.52,16.52c0,9.22-7.3,16.52-16.52,16.52
                    c-9.22,0-16.51-7.3-16.51-16.52C368.31,180.4,375.61,173.11,384.83,173.11z M118.36,444.94c-7.6,0-13.76,6.16-13.76,13.76
                    c0,7.6,6.16,13.76,13.76,13.76c7.6,0,13.77-6.16,13.77-13.76C132.13,451.1,125.97,444.94,118.36,444.94z"
					></path>
				</svg>`,
					title: 'SMS Alerts Settings',
					text: 'The admin can control the frequency of SMS alerts and when they should be sent to users.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M255.53,1.05C114.21-2.06,3.33,119.51,0.99,255.11c-3.9,141.05,117.9,251.72,253.76,254.83
                        c139.76,3.08,249.72-115.77,254.41-249.55c0.58-1.56,0.91-3.31,0.91-5.29C506.95,114.05,396.08,4.17,255.53,1.05z M460.89,270.69
                        c6.25,0,12.49,0,18.62,0c-10.06,112.24-97.34,199.12-209.91,208.8c0-6.36,0-12.62,0-18.64c0-18.7-29.67-18.7-29.67,0
                        c0,6.48,0,12.96,0,19.31c-112.49-5.11-200.33-99.94-209.97-209.46c6.64,0,13.18,0,19.45,0c18.74-0.78,18.74-29.61,0-29.61
                        c-6.77,0-13.54,0-20.17,0C33.95,128.17,129.6,39.97,239.92,30.67c0,6.65,0,13.2,0,19.48c0.78,18.7,29.67,18.7,29.67,0
                        c0-6.72,0-13.44,0-20.01c113.64,5.74,201.16,101.42,210.09,210.94c-6.42,0-12.73,0-18.8,0
                        C442.15,241.08,442.15,270.69,460.89,270.69z M269.59,87.56c0-18.7-29.67-18.7-29.67,0c0,37.08,0,74.15,0,111.23
                        c-10.22-17.59-20.44-35.19-30.45-52.78c-9.37-17.15-34.36-2.34-24.99,14.03c18.23,32.02,37.13,64.04,55.45,96.06
                        c0.12,7.71,7.09,14.6,14.82,14.6c0.78,0,0.78,0,1.56,0c10.33,0,17.17-11.14,13.27-20.26C269.59,196.14,269.59,141.85,269.59,87.56
                        z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Ride Later Settings',
					text: 'Configure how early users can schedule rides and what kind of cabs are available for scheduled bookings.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M512,200.51v-72.68l-37.9-9.48c-1.41-3.93-3.02-7.86-4.94-11.69l20.16-33.57l-51.4-51.41l-33.56,20.16
                        c-3.93-1.81-7.86-3.53-11.69-4.94L383.19-1h-72.67l-9.48,37.9c-3.93,1.41-7.86,3.02-11.69,4.94L255.8,21.68l-51.4,51.41
                        l15.82,26.31c-4.54-0.81-9.17-1.31-13.71-1.31c-0.3,0-0.61,0-0.81,0c-54.63,0.2-106.13,21.57-144.93,60.18l-5.85,5.85l0.1,0.1
                        C19.45,202.63,0,252.03,0,304.65c0,52.42,19.45,101.92,55.03,140.22l-0.2,0.1l5.85,5.85c39,38.81,90.71,60.18,145.74,60.18
                        c17.13,0,34.07-2.12,50.29-6.15c4.84-1.21,9.57-2.62,14.31-4.23c30.13-9.88,57.85-26.81,81.03-49.9l5.85-5.85l-0.1-0.1
                        c35.48-38.31,55.03-87.8,55.03-140.22v-8.17h-21.47l1.21-4.94c3.93-1.41,7.86-3.02,11.69-4.94l33.56,20.16l51.4-51.41
                        l-20.16-33.57c1.81-3.93,3.53-7.86,4.94-11.69L512,200.51z M82.65,160.49c2.22-1.61,4.33-3.43,6.55-5.14
                        c2.62-2.02,5.24-4.03,7.96-5.95c2.22-1.61,4.43-3.13,6.75-4.64c2.82-1.81,5.64-3.43,8.57-5.14c2.32-1.31,4.64-2.72,6.96-3.93
                        c3.02-1.51,6.05-2.92,9.17-4.33c2.42-1.11,4.74-2.22,7.16-3.13c3.22-1.31,6.45-2.42,9.78-3.53c0.1,0,0.2-0.1,0.2-0.1
                        c-15.82,14.92-29.53,35.48-40.11,60.08c-9.47-5.95-18.54-12.8-27.01-20.46C79.93,163.01,81.34,161.7,82.65,160.49z M67.02,176.02
                        c10.18,9.27,21.07,17.54,32.55,24.5c-9.98,28.33-16.03,60.99-16.83,95.87H16.93C18.85,251.32,36.38,209.18,67.02,176.02z
                        M16.93,312.81h65.81c0.81,34.88,6.85,67.54,16.83,95.87c-11.59,7.06-22.47,15.22-32.55,24.5
                        C36.38,400.01,18.85,357.87,16.93,312.81z M145.64,484.39c-3.43-1.11-6.65-2.22-9.88-3.43c-2.42-1.01-4.84-2.12-7.16-3.23
                        c-3.02-1.41-6.15-2.72-9.07-4.33c-2.42-1.21-4.74-2.62-7.06-3.93c-2.82-1.61-5.74-3.33-8.46-5.14c-2.32-1.51-4.54-3.02-6.75-4.64
                        c-2.72-1.92-5.34-3.83-7.96-5.85c-2.22-1.71-4.33-3.53-6.45-5.34c-1.41-1.21-2.72-2.42-4.03-3.63
                        c8.47-7.66,17.54-14.52,27.01-20.46c10.58,24.6,24.29,45.16,40.11,60.08C145.84,484.49,145.74,484.39,145.64,484.39z
                        M198.15,493.96c-32.15-4.33-60.37-33.97-78.41-77.52c24.09-12.4,50.8-19.35,78.41-20.56V493.96z M198.25,379.14
                        c-29.73,1.11-58.25,8.57-84.26,21.67c-8.67-26.01-13.91-55.95-14.71-87.9h98.97V379.14z M198.25,296.38H99.28
                        c0.81-31.96,6.05-61.9,14.71-87.9c26,13,54.53,20.46,84.26,21.67V296.38z M198.25,213.42c-27.72-1.21-54.32-8.16-78.41-20.56
                        c18.44-44.66,47.67-74.7,80.83-77.82c2.02-0.1,3.93-0.3,5.85-0.3c4.64-0.1,9.27,0.5,13.91,1.61c-0.3,0.71-0.61,1.31-0.81,2.02
                        l-37.9,9.48v72.68l16.53,4.13V213.42z M214.78,493.86v-97.98c27.62,1.21,54.12,8.17,78.21,20.46
                        C274.85,459.99,246.53,489.43,214.78,493.86z M245.42,296.38h-30.64v-30.64L245.42,296.38z M214.78,238.01v-29.23l4.84,1.21
                        c1.41,3.93,3.02,7.86,4.94,11.69L214.78,238.01z M306.39,312.81l4.13,16.53h2.22c-1.92,25.81-6.75,49.9-14.01,71.37
                        c-25.9-13-54.42-20.36-83.96-21.57v-66.33H306.39z M273.03,296.38l16.33-9.78c3.93,1.82,7.86,3.53,11.69,4.94l1.21,4.84H273.03z
                        M330.08,448.5c-2.12,1.81-4.23,3.63-6.45,5.34c-2.62,2.02-5.24,4.03-7.96,5.95c-2.22,1.61-4.43,3.13-6.75,4.64
                        c-2.82,1.81-5.64,3.43-8.57,5.14c-2.32,1.31-4.64,2.72-7.06,3.93c-3.02,1.51-6.05,2.92-9.17,4.33c-2.42,1.11-4.74,2.22-7.16,3.13
                        c-2.92,1.21-5.84,2.12-8.87,3.13c15.62-14.82,29.03-35.08,39.31-59.37c9.37,5.95,18.24,12.6,26.61,20.16
                        C332.8,446.08,331.49,447.39,330.08,448.5z M396.2,312.91c-1.91,45.06-19.65,87.2-50.29,120.36
                        c-10.08-9.17-20.86-17.34-32.25-24.29c8.27-23.79,13.71-50.61,15.82-79.54h53.82l4.13-16.53H396.2z M468.56,252.73L435.4,285.9
                        l-30.34-18.15l-4.13,2.12c-5.95,3.02-11.89,5.54-17.74,7.46l-4.33,1.41l-8.57,34.17h-46.87l-8.57-34.17l-4.33-1.41
                        c-5.85-1.92-11.89-4.44-17.74-7.46l-4.13-2.12l-30.34,18.15l-33.16-33.17l18.14-30.34l-2.12-4.13c-3.02-5.95-5.54-11.9-7.46-17.74
                        l-1.41-4.33l-34.17-8.57v-46.87l34.17-8.57l1.41-4.33c1.92-5.85,4.44-11.9,7.46-17.74l2.12-4.13l-18.14-30.34l33.16-33.17
                        l30.34,18.15l4.03-2.12c5.94-3.02,11.89-5.54,17.74-7.46l4.33-1.41l8.57-34.17h46.87l8.57,34.17l4.33,1.41
                        c5.84,1.92,11.89,4.44,17.74,7.46l4.03,2.12l30.34-18.15l33.36,33.17l-18.14,30.34l2.12,4.13c3.02,5.95,5.54,11.9,7.46,17.74
                        l1.41,4.33l34.17,8.57v46.87l-34.17,8.57l-1.41,4.33c-1.91,5.85-4.43,11.9-7.46,17.74l-2.12,4.13L468.56,252.73z M346.81,73.29
                        c-50.09,0-90.81,40.73-90.81,90.83c0,50.1,40.72,90.83,90.81,90.83c50.09,0,90.81-40.73,90.81-90.83
                        C437.72,114.12,396.9,73.29,346.81,73.29z M346.81,238.52c-41.02,0-74.28-33.37-74.28-74.29c0-40.93,33.36-74.29,74.28-74.29
                        c41.02,0,74.28,33.37,74.28,74.29C421.09,205.15,387.83,238.52,346.81,238.52z M355.07,32.06h-16.53V48.6h16.53V32.06z
                        M338.54,296.38h16.53v-16.53h-16.53V296.38z M446.17,76.61l-11.69-11.69L422.8,76.61l11.69,11.69L446.17,76.61z M247.58,251.73
                        l11.69,11.69l11.69-11.69l-11.69-11.69L247.58,251.73z M478.94,155.96h-16.53v16.53h16.53V155.96z M214.78,172.49h16.53v-16.53
                        h-16.53V172.49z M422.82,251.8l11.69,11.69l11.69-11.69l-11.69-11.69L422.82,251.8z M270.98,76.65l-11.69-11.69L247.6,76.65
                        l11.69,11.69L270.98,76.65z M314.69,115.68h-0.29v13.48h13.81c12.59,0,18.95,5.95,21.24,13.34h-34.76v13.48h34.76
                        c-2.3,7.39-8.66,13.37-21.24,13.37h-4.74l-0.08-0.08l-0.08,0.08h-8.92v9.13v0.84v3.5h3.58l41.5,40.56l9.42-9.63l-32.47-31.74
                        c14.76-2.99,24.24-13.96,26.84-26.03h18.37V142.5h-18.37c-1.04-4.85-3.29-9.43-6.45-13.48h24.81v-13.48h-66.94V115.68z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Country/Currency Settings',
					text: 'Multilingual and multi-currency support to help the app work appropriately for users from across the globe.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M443.02,378.1c-10.84-9.9-17.06-23.9-17.06-38.5V240c0-5.5-4.51-10-10.03-10c-5.52,0-10.03,4.5-10.03,10v99.6
                        c0,20.2,8.63,39.6,23.58,53.3c5.42,4.9,8.53,11.9,8.53,19.2c0,14.3-11.74,26-26.09,26H98.88c-14.35,0-26.09-11.7-26.09-26
                        c0-7.3,3.11-14.3,8.53-19.2c15.05-13.7,23.58-33.1,23.58-53.3V224c0-82.7,67.52-150,150.5-150c22.78,0,45.35,5.2,65.62,15
                        c-7.12,11.3-11.14,24.7-11.14,39c0,40.8,33.31,74,74.24,74s74.24-33.2,74.24-74s-33.31-74-74.24-74c-19.16,0-36.72,7.3-49.86,19.2
                        c-15.05-7.7-31.1-13.2-47.66-16.3c3.71-5.8,5.82-12.6,5.82-19.9c0-20.4-16.65-37-37.12-37c-20.47,0-37.12,16.6-37.12,37
                        c0,7.3,2.11,14.1,5.82,19.9C144.94,71.6,84.74,140.9,84.74,224v115.6c0,14.6-6.22,28.6-17.06,38.5
                        c-9.53,8.7-15.05,21.1-15.05,33.9c0,25.4,20.67,46,46.15,46h93.11c4.82,30.6,31.4,54,63.41,54s58.59-23.4,63.41-54h93.11
                        c25.48,0,46.15-20.6,46.15-46C457.97,399.1,452.55,386.7,443.02,378.1z M384.02,74c29.9,0,54.18,24.2,54.18,54
                        c0,29.8-24.28,54-54.18,54c-29.9,0-54.18-24.2-54.18-54C329.84,98.2,354.22,74,384.02,74z M255.3,20c9.43,0,17.06,7.6,17.06,17
                        c0,9.4-7.62,17-17.06,17c-9.43,0-17.06-7.6-17.06-17C238.24,27.6,245.97,20,255.3,20z M255.3,492c-20.87,0-38.43-14.5-42.94-34
                        h85.98C293.73,477.5,276.27,492,255.3,492z M142.93,213.8c-5.52,0-10.03,4.5-10.03,10v0.2c0,5.5,4.52,10,10.03,10
                        s10.03-4.5,10.03-10v-0.2C152.96,218.3,148.55,213.8,142.93,213.8z M161.09,184.2c16.05-37.8,53.07-62.2,94.21-62.2
                        c5.52,0,10.03-4.5,10.03-10c0-5.5-4.41-10-10.03-10c-49.26,0-93.51,29.2-112.67,74.4c-2.21,5.1,0.2,11,5.32,13.1
                        c1.31,0.5,2.61,0.8,3.91,0.8C155.77,190.3,159.48,188,161.09,184.2z M373.79,126.2v18.1c0,5.5,4.52,10,10.03,10
                        s10.03-4.5,10.03-10v-39.5c-0.1-4-2.51-7.7-6.22-9.2c-3.71-1.5-8.03-0.7-10.94,2.2l-11.54,11.5c-3.91,3.9-3.91,10.2,0,14.1
                        C367.47,125.7,370.68,126.7,373.79,126.2z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Send App Notifications',
					text: 'Configure what kind of push notifications can be sent, how frequently they can be sent to users from the admin panel.'
				},
				{
					svg: `<svg
					x="0px"
					y="0px"
					viewBox="0 0 512 512"
					style="enable-background: new 0 0 512 512"
					xml:space="preserve"
				>
					<g>
						<g>
							<path
								d="M485.53-1H133.46c-14.85,0.06-26.84,12.16-26.78,27.04c0,0.07,0,0.14,0,0.22v186.5H21.34c0-17.71,14.33-32.06,32.01-32.06
                        h32.01v-21.38H53.34C23.88,159.31,0,183.24,0,212.75v267.19C0,497.64,14.33,512,32.01,512h405.4c17.68,0,32-14.36,32-32.06V244.81
                        c0-17.71-14.33-32.06-32-32.06H128.02v-96.19h224.04V95.19H128.02V73.81h362.73v175.81c0.18,3.06-2.15,5.7-5.21,5.88
                        c0,0-0.01,0-0.02,0h-5.44v21.38h5.44c14.85-0.18,26.74-12.38,26.56-27.25V26.25C512.27,11.38,500.38-0.82,485.53-1z M448.08,426.5
                        h-64.01c-5.89,0-10.67-4.78-10.67-10.69v-32.06c0-5.9,4.78-10.69,10.67-10.69h64.01V426.5z M437.41,234.12
                        c5.89,0,10.67,4.79,10.67,10.69v106.88h-64.01c-17.68,0-32,14.35-32,32.06v32.06c0,17.71,14.33,32.06,32,32.06h64.01v32.06
                        c0,5.9-4.78,10.69-10.67,10.69H32.01c-5.89,0-10.67-4.78-10.67-10.69V234.12H437.41z M128.02,52.44V26.25
                        c-0.18-3.06,2.15-5.7,5.21-5.88c0.01,0,0.01,0,0.02,0h352.06c3.06,0.17,5.4,2.79,5.23,5.86c0,0,0,0.01,0,0.02l0.21,26.18H128.02z
                        M53.34,469.25h42.67v-21.38h-32v-32.06H42.67v42.75C42.67,464.46,47.45,469.25,53.34,469.25z M138.69,447.87h-21.34v21.38h21.34
                        V447.87z M458.75,116.56V95.19h-21.34v21.38H458.75z M405.4,116.56V95.19h-21.34v21.38H405.4z"
							></path>
						</g>
					</g>
				</svg>`,
					title: 'Payment Integration',
					text: 'Integrate the app with payment integrations for cards, online banking and digital wallets.'
				}
			]
		};
	}
};
</script>

<style>
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.title h2 {
	font-size: 34px;
	line-height: 1.3;
	margin: 0;
	font-weight: 700;
	color: #202122;
}

#technologies_tabs {
	border: none;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
}
#technologies_tabs li {
	padding: 0 30px;
	border-right: 1px solid #e0e2e3;
}
#technologies_tabs li a.active,
#technologies_tabs li a:hover {
	color: #1977cc;
}
#technologies_tabs li a.active .car-wash-feature-tab-icon,
#technologies_tabs li a:hover .car-wash-feature-tab-icon {
	fill: #fff;
	background: #008dd2;
}
#technologies_tabs li a {
	color: #6a6d6f;
	font-weight: 500;
}
.tab-content > .active {
	display: block;
}
.technologies_icons li {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid rgba(221, 223, 225, 0.5);
	border-radius: 10px;
	margin: 5px;
	transition: all 0.5s ease;
}
.technologies_icons li img {
	margin: 0 auto;
}
#technologies_tabs li:last-child {
	border-right: 0;
}
.fxt-tab-pane ul {
	padding-left: 0 !important;
}
@media (max-width: 576px) {
	.fxt-tech-use {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
@media (max-width: 476px) {
	.fxt-technologies-tabs ul li {
		padding: 0 15px !important;
	}
	.fxt-technologies-tabs ul li a {
		font-size: 12px;
	}
}
.car-wash-feature-tab-icon {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	margin: 0 auto 15px;
	background-color: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.box-shadow {
	-webkit-box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
	-moz-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 10px 0 rgb(0 0 0 / 15%);
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a span {
	font-size: 16px;
	color: #202122;
	display: block;
	text-align: center;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.car-wash-feature-tab li {
	min-width: 180px;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li:last-child a {
	border-radius: 0 10px 10px 0;
}

.car-wash-feature-tab li a {
	border: 1px solid transparent;
	background-color: #fff;
	padding: 17px 10px;
	display: block;
	padding-bottom: 20px;
	position: relative;
	border-bottom: 2px solid #e8e8e8;
}
a:hover {
	color: #008dd2;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border: 1px solid #008dd2;
}
.car-wash-feature-tab li:first-child a {
	border-radius: 10px 0 0 10px;
}
.car-wash-feature-tab li a.active,
.car-wash-feature-tab li a:hover {
	border-bottom: 2px solid #008dd2;
}
.feature-tab li a:hover .car-wash-feature-tab-icon {
	background-color: #008dd2;
}
.car-wash-feature-tab-icon svg {
	width: 50px;
	height: 50px;
}
.car-wash-feature-tab li a.active span,
.car-wash-feature-tab li a:hover span {
	color: #008dd2;
}
.car-wash-feature-tab li {
	padding: 0 !important;
}
.car-wash-feature-tab {
	border-bottom: 0;
	justify-content: center;
	display: flex;
}
@media (max-width: 776px) {
	#technologies_tabs {
		flex-wrap: wrap;
		justify-content: center;
	}
	.car-wash-feature-tab li {
		min-width: 60px;
	}
	#technologies_tabs li {
		width: fit-content;
		padding: 0;
		border-right: 1px solid #e0e2e3;
	}
	#technologies_tabs li a {
		width: fit-content;
		padding: 10px;
	}
	.car-wash-feature-tab-icon {
		width: 50px;
		height: 50px;
	}
	#technologies_tabs li a.active .car-wash-feature-tab-icon,
	#technologies_tabs li a:hover .car-wash-feature-tab-icon {
		background-color: transparent;
		fill: #0082dd;
	}
}
</style>
