<template>
	<section class="about service-main-content">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-12 col-lg-5 col-sm-12 mb-4 mb-lg-0">
					<div class="about-slider">
						<div style="width: 100%; display: inline-block">
							<img
								class="lazy d-block ml-auto mr-auto"
								alt=""
								src="../../../assets/images/iphone-development-main.webp"
								style=""
							/>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-7 col-sm-12">
					<h2 class="mb-3 abt-fxt-hed title text-left">
						Highly Acclaimed iOS Game Development Company
					</h2>
					<p>
						GenixBit Labs is leading iOS game development company in the UK, who ensure a result driven
						methodical approach for all iPad and iPhone game developments in such a way that the
						resulting games have to turn out to be engaging gameplay, melodic sounds, striking
						animations, and seamlessly performance across all iOS devices.
					</p>
					<p>
						The expert team of the iOS game development at GenixBit Labs understands the rapidly
						changing mobile gaming trends and the desire of every client to create the next most
						exciting game in the world. Our iOS game design and programming infrastructure enable us
						to perform seamless integration of the latest technologies to make every game we develop
						goes out to be a great success in its audience.
					</p>
					<p>
						Our iOS mobile game app development services, such as character creation, graphics
						integration, programming, testing with advanced tools and the latest frameworks have
						yielded a cost-effective yet high-quality mobile game app development with greater ROI.
						Because of these traits, GenixBit Labs enjoys a high reputation among its clients in the
						UK, USA, Canada, Spain, France, Israel, UAE, Europe and across the globe.
					</p>
					<p>
						The years of experience and hands-on expertise across the various genre of iPhone game
						app development and iPad game development has enabled us to attune the most flexible
						requirements and became the top iOS game developers in the UK and across the world. The
						expert iPhone game developers at GenixBit Labs know how to monetise iOS games for
						entrepreneurs and help them accomplish business goals.
					</p>
					<p>
						If you have a mobile game development idea, we are ready to turn it into an exciting
						reality. Do you want to discuss your idea with us?
					</p>

					<router-link class="btn-custome btn waves-effect waves-light" to="/contact"
						>Talk to Our Expert Now</router-link
					>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {};
</script>

<style>
.about {
	margin-top: -75px;
	padding: 150px 0 40px;
	position: relative;
}
.about-slider {
	width: 100%;
	position: relative;
	background: #fbfcfc;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}
.about-img-slider img {
	display: inline-block;
}
.about-icon-slider {
	display: flex;
	background: #fff;
	padding: 15px 20px;
}
.about-icon-slider a {
	background: #1977cc;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.about-icon-slider a svg {
	width: 40px;
}
@media (max-width: 776px) {
	.abt-fxt-hed {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
</style>
