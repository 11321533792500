<template>
	<section class="padding-60 bg-light p-relative">
		<div class="container mt-5">
			<div class="title">
				<span>Key Features</span>
				<h2 class="fxt-process-dev">Features to plan & execute fitness goals</h2>
				<p>
					From planning to workout to seeing those plans to action, our health and fitness mobile
					apps are enriched with features that your users will become habitual to.
				</p>
			</div>
			<div class="row" style="justify-content: center">
				<div
					class="col-lg-3 col-md-4 col-sm-6 col-12 mb-30"
					v-for="item in items"
					v-bind:key="item.title"
				>
					<ShaowIconCard v-bind:obj="item"></ShaowIconCard>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import ShaowIconCard from '../AssetComponents/ShaowIconCard.vue';
export default {
	components: {
		ShaowIconCard
	},
	data() {
		return {
			items: [
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M48.8,24.2c-14.4,0-26.1,11.6-26.1,25.8c0,14.3,11.7,25.8,26.1,25.8c14.4,0,26.1-11.6,26.1-25.8
                    C74.8,35.8,63.2,24.2,48.8,24.2z M48.8,72.6C36.2,72.6,26,62.5,26,50c0-12.5,10.2-22.6,22.8-22.6c12.6,0,22.8,10.1,22.8,22.6
                    C71.6,62.5,61.4,72.6,48.8,72.6z M85.3,32.1c-1.5-3.1-3.5-5.9-5.8-8.5C79,23,78,22.8,77.3,23.3l-3.2,2.2l-2.6-12.9
                    c-0.2-0.8-0.8-1.3-1.6-1.3h-3.3V1.6C66.7,0.7,66,0,65.1,0H32.5c-0.9,0-1.6,0.7-1.6,1.6v9.7h-3.3c-0.8,0-1.4,0.5-1.6,1.3l-3.1,15.3
                    c-11.1,12.7-11.1,31.5,0,44.2L26,87.4c0.2,0.8,0.8,1.3,1.6,1.3h3.3v9.7c0,0.9,0.7,1.6,1.6,1.6h32.6c0.9,0,1.6-0.7,1.6-1.6v-9.7
                    h3.3c0.8,0,1.4-0.5,1.6-1.3l3.1-15.3c8.5-9.6,10.7-23.2,5.8-35c0.1,0,0.2-0.1,0.3-0.1l4.1-2.8C85.4,33.7,85.6,32.8,85.3,32.1z
                    M66.7,21v-6.5h1.9l1.9,9.3c-1.2-1-2.5-1.9-3.8-2.7C66.7,21.1,66.7,21,66.7,21z M34.1,3.2h29.3v16.2c-9.3-4.4-20-4.4-29.3,0V3.2z
                    M28.9,14.5h1.9V21c0,0.1,0,0.1,0,0.2c-1.3,0.8-2.6,1.7-3.8,2.7L28.9,14.5z M30.9,79v6.5h-1.9l-1.9-9.3c1.2,1,2.5,1.9,3.8,2.7
                    C30.9,78.9,30.9,79,30.9,79z M63.4,96.8H34.1V80.6c9.3,4.4,20.1,4.4,29.3,0V96.8z M68.6,85.5h-1.9V79c0-0.1,0-0.1,0-0.2
                    c1.3-0.8,2.6-1.7,3.8-2.7L68.6,85.5z M48.8,80.6c-17.1,0-30.9-13.7-30.9-30.6c0-16.9,13.9-30.6,30.9-30.6
                    c17.1,0,30.9,13.7,30.9,30.6C79.7,66.9,65.9,80.6,48.8,80.6z M79,34.2c-1-2-2.3-3.8-3.7-5.5l2.7-1.9c1.4,1.7,2.6,3.6,3.7,5.5
                    L79,34.2z M60.6,49.2l-3.2,6L52,35.1c-0.2-0.7-0.8-1.2-1.6-1.2c-0.7,0-1.4,0.5-1.6,1.2L43.5,55l-2.7-5.7c-0.3-0.6-0.8-0.9-1.5-0.9
                    h-10c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h9l4.2,8.8c0.3,0.6,0.9,1,1.6,0.9c0.7-0.1,1.3-0.5,1.4-1.2l4.9-18.3l4.9,18.3
                    c0.2,0.6,0.7,1.1,1.4,1.2c0.1,0,0.1,0,0.2,0c0.6,0,1.2-0.3,1.4-0.9l4.7-8.8h5.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-6.2
                    C61.5,48.4,60.9,48.7,60.6,49.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Activity tracking'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M41.6,21.4c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10C31.6,16.9,36.1,21.4,41.6,21.4z M41.6,4.4
                    c3.8,0,6.9,3.1,6.9,6.9c0,3.8-3.1,6.9-6.9,6.9c-3.8,0-6.9-3.1-6.9-6.9C34.7,7.6,37.8,4.4,41.6,4.4z M97.8,22.2
                    c-0.3,0-0.5,0.1-0.8,0.2L75.5,35.1c-0.5,0.3-0.8,0.8-0.8,1.3v4.2c0,0.9,0.7,1.5,1.5,1.5h13.1v6.6L73.4,87.1l-3.3,0.2
                    c0.1-0.2,0.3-0.3,0.4-0.5c1.1-1.5,1.3-3.5,0.7-5.2v-0.1l-7.8-18.2c-0.3-0.7-0.9-1.2-1.5-1.5l-6.8-4.2c-0.3-0.1-0.5-0.4-0.5-0.8
                    V43.7l6.7,1.5c0.6,0.1,1.3-0.1,1.6-0.7l8.1-12.7c1.1-1.7,0.8-4-0.6-5.4c-1.8-1.7-4.6-1.7-6.3,0.1c-0.2,0.2-0.3,0.3-0.4,0.5
                    l-5.3,7.7l-6.4-1.5c-3.2-5.5-9.1-8.8-15.4-8.9c-1.3,0-2.5,0.4-3.5,1.1l-0.1,0L16.9,35.9c-0.5,0.4-0.8,1-0.7,1.6l3.6,15.8
                    c0.4,2.1,2.2,3.5,4.3,3.5c2.5,0,4.5-2.1,4.5-4.6c0-0.3-2-10.8-2-10.8l3.4-2.1v17.2c0,2.3,1.2,4.4,3.2,5.6l-2.4,7.1
                    c-0.1,0.2-0.2,0.4-0.4,0.5l-13.7,5.6c-2.8,1.1-4.1,4.2-3,7c0.5,1.2,1.3,2.1,2.4,2.7c1.4,0.8,3.1,0.9,4.6,0.3l17.2-6.8
                    c1-0.4,1.8-1.2,2.2-2.1l4.1-9.9l7.8,3.2c0.1,0,0.1,0.1,0.2,0.1l8.7,16c0.3,0.6,0.8,1.2,1.3,1.7L5.3,90.6c-2.6,0.1-4.6,2.2-4.6,4.8
                    c0.1,2.6,2.2,4.6,4.8,4.6h60.7c0.9,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H5.5c-0.9,0-1.6-0.7-1.7-1.6c0-0.9,0.7-1.6,1.6-1.7
                    l86.2-4.4v7.6H73.9c-0.9,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h17.7c1.7,0,3.1-1.4,3.1-3.1v-7.6c0-1.7-1.4-3.1-3.1-3.1
                    c-0.1,0-0.1,0-0.2,0l-7.5,0.4l15.3-36.9c0.1-0.2,0.1-0.4,0.1-0.6V23.7C99.3,22.9,98.6,22.2,97.8,22.2z M58.5,38.1
                    c0.6,0.2,1.3-0.1,1.6-0.6l5.9-8.6c0.5-0.6,1.3-0.8,2-0.3c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.5,1,0.2,1.5L60.8,42l-6.5-1.4
                    c-0.1-1.3-0.4-2.5-0.8-3.7L58.5,38.1z M37.2,75.3c-0.1,0.2-0.2,0.3-0.4,0.4l-17.2,6.7c-1.2,0.5-2.6-0.2-3-1.4
                    c0-0.1-0.1-0.3-0.1-0.4c-0.2-1.1,0.4-2.1,1.4-2.5l13.7-5.6c1-0.4,1.8-1.3,2.2-2.3l2.3-6.9c1.2,0.5,3.1,1.2,5.1,2.1L37.2,75.3z
                    M68,85c-0.5,0.7-1.2,1.1-2.1,1.1c-1,0-1.9-0.6-2.4-1.5l0-0.1l-8.8-16.1c-0.4-0.6-0.9-1.1-1.5-1.3c-1.6-0.7-16.2-6.7-17.7-7.2
                    c-1.4-0.5-2.3-1.8-2.3-3.2V37.4l2.3-1.5c0.7-0.5,0.9-1.4,0.4-2.1c-0.4-0.7-1.3-0.9-2-0.5l-9.8,6c-0.5,0.3-0.8,1-0.7,1.6l2.1,11
                    c0,0.1,0,0.2,0,0.3c0,0.8-0.6,1.4-1.3,1.5c-0.7,0-1.2-0.5-1.3-1.1l-3.4-14.8l15-9.7c0.1,0,0.1-0.1,0.2-0.1
                    c0.5-0.4,1.2-0.5,1.8-0.5c8.3,0.1,15,6.8,15,15.2v14.1c0,1.4,0.8,2.7,2.1,3.4c0,0,7.2,4.4,7.2,4.5l7.7,18
                    C68.6,83.4,68.5,84.3,68,85z M96.2,48.8L80.5,86.8L76.8,87l15.4-37.2c0.1-0.2,0.1-0.4,0.1-0.6v-6.9h3.8V48.8z M96.2,39.1H77.8
                    v-1.8l18.5-10.9V39.1z M63.6,20.5H83c2.2,0,4-1.8,4-4V4c0-2.2-1.8-4-4-4H63.6c-2.2,0-4,1.8-4,4v12.6
                    C59.6,18.8,61.4,20.5,63.6,20.5z M62.6,5.3c0-1.3,1.1-2.4,2.4-2.4h16.6C82.9,2.9,84,4,84,5.3v9.9c0,1.3-1.1,2.4-2.4,2.4H65
                    c-1.3,0-2.4-1.1-2.4-2.4V5.3z M69.3,5v10.7l9.7-5.5L69.3,5z M71.9,8.2l3.8,2.1l-3.8,2V8.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Workout videos'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path d="M65.5,35.3c0.3,0.3,0.9,0.6,1.3,0.6c0.4,0,1-0.2,1.3-0.6l6.4-6.5c1.4,1,3.1,1.6,4.8,1.6c2.3,0,4.5-0.9,6-2.6
                c3.3-3.3,3.3-8.9,0-12.4c-1.6-1.7-3.7-2.6-6-2.6c-2.3,0-4.5,0.9-6,2.6c-1.6,1.7-2.5,3.8-2.5,6.1c0,1.6,0.4,3.1,1.2,4.3l-6.5,6.6
                C64.7,33.4,64.7,34.6,65.5,35.3z M75.9,18.3c0.9-0.9,2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.4c1.9,1.9,1.9,5,0,6.9
                c-0.9,1-2.1,1.4-3.4,1.4c-1.3,0-2.5-0.6-3.4-1.4c-0.9-0.9-1.4-2.1-1.4-3.5C74.5,20.4,75,19.2,75.9,18.3z M41.4,9.4
                c0,3.9,2.5,7.1,5.9,8.2v9.6c0,1,0.9,1.9,1.9,1.9c1,0,1.9-0.9,1.9-1.9V18c4.2-0.6,7.5-4.2,7.5-8.6c0-4.8-3.8-8.7-8.6-8.7
                C45.3,0.7,41.4,4.6,41.4,9.4z M50,4.5c2.6,0,4.8,2.2,4.8,4.9c0,2.7-2.2,5-4.8,5c-2.6,0-4.8-2.2-4.8-4.9C45.2,6.8,47.4,4.5,50,4.5z
                M8.6,60.2c3.7,0,6.9-2.4,8.1-5.8h9.9c1,0,1.9-0.9,1.9-1.9c0-1-0.9-1.9-1.9-1.9h-9.4c-0.4-4.3-4.1-7.8-8.6-7.8
                c-4.7,0-8.6,3.9-8.6,8.7S3.8,60.2,8.6,60.2z M8.6,46.6c2.6,0,4.8,2.2,4.8,4.9c0,2.7-2.2,4.9-4.8,4.9s-4.8-2.2-4.8-4.9
                C3.7,48.8,5.9,46.6,8.6,46.6z M91.4,42.8c-4.4,0-8.1,3.4-8.6,7.8H74c-1,0-1.9,0.9-1.9,1.9c0,1,0.9,1.9,1.9,1.9h9.3
                c1.2,3.4,4.4,5.8,8.1,5.8c4.7,0,8.6-3.9,8.6-8.7C100,46.7,96.2,42.8,91.4,42.8z M91.4,56.3c-2.1,0-3.8-1.3-4.5-3.1
                c0.1-0.2,0.2-0.6,0.2-0.8c0-0.4-0.2-0.9-0.4-1.2c0.1-2.6,2.3-4.6,4.8-4.6c2.6,0,4.8,2.2,4.8,4.9C96.4,54.2,94.1,56.3,91.4,56.3z
                M26.8,15.6c-1.6-1.7-3.7-2.6-6-2.6c-2.3,0-4.5,0.9-6,2.6c-1.6,1.7-2.5,3.8-2.5,6.1s0.9,4.6,2.5,6.1c1.6,1.7,3.7,2.6,6,2.6
                c1.5,0,3.1-0.4,4.4-1.2l6.5,6.6c0.3,0.3,0.9,0.6,1.3,0.6c0.4,0,1-0.2,1.3-0.6c0.8-0.8,0.8-1.9,0-2.7l-6.4-6.5c1-1.4,1.4-3.1,1.4-4.8
                C29.3,19.4,28.4,17.3,26.8,15.6z M17.3,25.2c-0.9-0.9-1.4-2.1-1.4-3.5c0-1.3,0.5-2.6,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4
                s2.5,0.6,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4c0,1.3-0.5,2.6-1.4,3.5c-0.9,0.9-2.1,1.4-3.4,1.4S18.2,26.2,17.3,25.2z M60.7,64.9v9
                c2.5,1.3,9.1,4.9,15.4,10.1c1.6,1.4,2.6,3.4,2.6,5.7v7.7c0,1-0.9,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9v-7.7c0-1.1-0.4-2.1-1.3-2.8
                c-6.7-5.6-13.9-9.3-15.4-10c-0.8-0.4-1.3-1.2-1.3-2.1V64c0-0.7,0.3-1.2,0.9-1.6c0.9-0.6,1.4-1.6,1.4-2.7V48.6c0-4.6-3.6-8.2-8.1-8.2
                h-2.4c-4.5,0-8.1,3.7-8.1,8.2v11.2c0,1,0.5,2,1.4,2.7c0.5,0.3,0.9,0.9,0.9,1.6v10.8c0,0.9-0.5,1.8-1.3,2.1c-1.4,0.7-8.7,4.5-15.4,10
                c-0.8,0.7-1.3,1.7-1.3,2.8v7.7c0,1-0.9,1.9-1.9,1.9c-1,0-1.9-0.9-1.9-1.9v-7.7c0-2.2,1-4.2,2.6-5.7c6.1-5.1,12.8-8.8,15.4-10.1v-9
                c-1.4-1.3-2.2-3.1-2.2-5.1V48.6c0-6.6,5.3-12,11.8-12h2.4c6.5,0,11.8,5.3,11.8,12v11.2C63,61.8,62.2,63.6,60.7,64.9z"></path>
              </svg>`,

					title: 'Personalized plans'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M98.3,8.3h-6.7v3.3h5v11.7H3.3V11.7h5V8.3H1.7C0.7,8.3,0,9.1,0,10v88.3c0,0.9,0.7,1.7,1.7,1.7H35v-3.3H3.3v-15h20v-3.3
                    h-20v-15H25V75h3.3V63.3h68.3V75h3.3V10C100,9.1,99.3,8.3,98.3,8.3z M25,60H3.3V45H25V60z M25,41.7H3.3v-15H25V41.7z M48.3,60h-20
                    V45h20V60z M48.3,41.7h-20v-15h20V41.7z M71.7,60h-20V45h20V60z M71.7,41.7h-20v-15h20V41.7z M96.7,60H75V45h21.7V60z M96.7,41.7
                    H75v-15h21.7V41.7z M78.3,8.3h-10v3.3h10V8.3z M55,8.3H45v3.3h10V8.3z M31.7,8.3h-10v3.3h10V8.3z M8.3,18.3h2.1
                    c0.6,1,1.6,1.7,2.9,1.7h3.3c1.2,0,2.3-0.7,2.9-1.7h2.1V15H20V3.3C20,1.5,18.5,0,16.7,0h-3.3C11.5,0,10,1.5,10,3.3V15H8.3V18.3z
                    M13.3,3.3h3.3l0,13.3h-3.3V3.3z M31.7,18.3h2.1c0.6,1,1.6,1.7,2.9,1.7H40c1.2,0,2.3-0.7,2.9-1.7H45V15h-1.7V3.3
                    C43.3,1.5,41.8,0,40,0h-3.3c-1.8,0-3.3,1.5-3.3,3.3V15h-1.7V18.3z M36.7,3.3H40l0,13.3h-3.3V3.3z M55,18.3h2.1
                    c0.6,1,1.6,1.7,2.9,1.7h3.3c1.2,0,2.3-0.7,2.9-1.7h2.1V15h-1.7V3.3c0-1.8-1.5-3.3-3.3-3.3H60c-1.8,0-3.3,1.5-3.3,3.3V15H55V18.3z
                    M60,3.3h3.3l0,13.3H60V3.3z M78.3,18.3h2.1c0.6,1,1.6,1.7,2.9,1.7h3.3c1.2,0,2.3-0.7,2.9-1.7h2.1V15H90V3.3C90,1.5,88.5,0,86.7,0
                    h-3.3C81.5,0,80,1.5,80,3.3V15h-1.7V18.3z M83.3,3.3h3.3l0,13.3h-3.3V3.3z M96.7,78.3H95V75c0-1.8-1.5-3.3-3.3-3.3h-3.3
                    c0-1.8-1.5-3.3-3.3-3.3h-3.3c-0.2,0-0.3,0-0.4,0c-0.6-1-1.6-1.7-2.9-1.7H75c-1.8,0-3.3,1.5-3.3,3.3v8.3H55V70
                    c0-1.8-1.5-3.3-3.3-3.3h-3.3c-1.2,0-2.3,0.7-2.9,1.7c-0.1,0-0.3,0-0.4,0h-3.3c-1.8,0-3.3,1.5-3.3,3.3H35c-1.8,0-3.3,1.5-3.3,3.3
                    v3.3H30c-1.8,0-3.3,1.5-3.3,3.3V85c0,1.8,1.5,3.3,3.3,3.3h1.7v3.3c0,1.8,1.5,3.3,3.3,3.3h3.3c0,1.8,1.5,3.3,3.3,3.3H45
                    c0.1,0,0.3,0,0.4,0c0.6,1,1.6,1.7,2.9,1.7h3.3c1.8,0,3.3-1.5,3.3-3.3v-8.3h16.7v8.3c0,1.8,1.5,3.3,3.3,3.3h3.3
                    c1.2,0,2.3-0.7,2.9-1.7c0.1,0,0.3,0,0.4,0H85c1.8,0,3.3-1.5,3.3-3.3h3.3c1.8,0,3.3-1.5,3.3-3.3v-3.3h1.7c1.8,0,3.3-1.5,3.3-3.3
                    v-3.3C100,79.8,98.5,78.3,96.7,78.3z M31.7,85H30v-3.3h1.7V85z M38.3,91.7H35V75h3.3V91.7z M45,95h-3.3V71.7H45V95z M51.7,96.7
                    h-3.3V70h3.3V96.7z M71.7,85H55v-3.3h16.7V85z M78.3,96.7H75V70h3.3v1.7c0,0,0,0,0,0V95c0,0,0,0,0,0V96.7z M85,95h-3.3V71.7H85v20
                    c0,0,0,0,0,0V95z M88.3,91.7V75h3.3l0,16.7H88.3z M96.7,85H95v-3.3h1.7V85z M21.7,36.7h-3.3V40h3.3V36.7z M68.3,36.7H65V40h3.3
                    V36.7z M61.7,36.7h-3.3V40h3.3V36.7z M93.3,55H90v3.3h3.3V55z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Workout calendars'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <path class="st0" d="M95,20H70v-8.3C70,5.2,64.8,0,58.3,0H41.7C35.2,0,30,5.2,30,11.7V20H5c-2.8,0-5,2.2-5,5v63.3c0,2.8,2.2,5,5,5
                  h1.7v6.7H20v-6.7h60v6.7h13.3v-6.7H95c2.8,0,5-2.2,5-5V25C100,22.2,97.8,20,95,20z M33.3,11.7c0-4.6,3.7-8.3,8.3-8.3h16.7
                  c4.6,0,8.3,3.7,8.3,8.3V20h-3.3v-8.3c0-2.8-2.2-5-5-5H41.7c-2.8,0-5,2.2-5,5V20h-3.3V11.7z M60,11.7V20H40v-8.3
                  c0-0.9,0.7-1.7,1.7-1.7h16.7C59.3,10,60,10.7,60,11.7z M16.7,96.7H10v-3.3h6.7V96.7z M90,96.7h-6.7v-3.3H90V96.7z M96.7,88.3
                  c0,0.9-0.7,1.7-1.7,1.7H5c-0.9,0-1.7-0.7-1.7-1.7V25c0-0.9,0.7-1.7,1.7-1.7h90c0.9,0,1.7,0.7,1.7,1.7V88.3z M50,26.7
                  c-16.6,0-30,13.4-30,30c0,16.6,13.4,30,30,30c16.6,0,30-13.4,30-30C80,40.1,66.6,26.7,50,26.7z M50,83.3
                  c-14.7,0-26.7-11.9-26.7-26.7S35.3,30,50,30c14.7,0,26.7,11.9,26.7,26.7C76.7,71.4,64.7,83.3,50,83.3z M60,36.7H40v10H30v20h10v10
                  h20v-10h10v-20H60V36.7z M66.7,50v13.3h-10v10H43.3v-10h-10V50h10V40h13.3v10H66.7z"></path>
                </g>
              </svg>`,

					title: 'HealthKit &amp; Google Fit integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <path d="M2.3,5.6C1.2,5.6,0,6.7,0,7.8v84.3c0,1.2,1.1,2.3,2.3,2.3h95.5c1.2,0,2.3-1.2,2.3-2.3c0-1.1-1.1-2.3-2.3-2.3H4.5V7.8
                C4.5,6.5,3.3,5.6,2.3,5.6z M92,5.6c-4.4,0-8,3.6-8,8c0,2.2,0.9,4.2,2.4,5.7l-12.2,23c-0.5-0.1-1-0.2-1.6-0.2c-2.7,0-5.1,1.3-6.5,3.4
                l-19.6-6.6c0-0.1,0-0.2,0-0.2c0-4.4-3.6-8-8-8c-4.4,0-8,3.6-8,8c0,2.2,0.9,4.2,2.4,5.7l-12.2,23c-0.5-0.1-1-0.2-1.6-0.2
                c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8s8-3.6,8-8c0-2.2-0.9-4.2-2.4-5.7l12.2-23c0.5,0.1,1,0.2,1.6,0.2c2.7,0,5.1-1.4,6.5-3.5l19.6,6.6
                c0,0.1,0,0.2,0,0.3c0,4.4,3.6,8,8,8s8-3.6,8-8c0-2.2-0.9-4.2-2.4-5.7l12.2-23c0.5,0.1,1,0.2,1.5,0.2c4.4,0,8-3.6,8-8
                C100,9.2,96.4,5.6,92,5.6z M92,10.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4
                C88.6,11.6,90.1,10.1,92,10.1z M38.6,35.2c1.9,0,3.4,1.5,3.4,3.4S40.5,42,38.6,42c-1.9,0-3.4-1.5-3.4-3.4S36.7,35.2,38.6,35.2z
                M72.7,46.6c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S70.8,46.6,72.7,46.6z M19.3,71.7c1.9,0,3.4,1.5,3.4,3.4
                c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4C15.9,73.2,17.4,71.7,19.3,71.7z"></path>
              </svg>`,

					title: 'Progress visualization'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M35,0H8.3C3.7,0,0,3.7,0,8.3V55c0,4.6,3.7,8.3,8.3,8.3H35c4.6,0,8.3-3.7,8.3-8.3V8.3C43.3,3.7,39.6,0,35,0z M35,60H8.3
                    c-2.8,0-5-2.2-5-5H40C40,57.8,37.8,60,35,60z M40,51.7H3.3v-40H40V51.7z M3.3,8.3c0-2.8,2.2-5,5-5H35c2.8,0,5,2.2,5,5H3.3z
                    M14.9,32.1l1.4-5.1L20,45.3c0.1,0.8,0.8,1.3,1.6,1.3h0.1c0.7,0,1.4-0.5,1.6-1.2l5.2-18.2l1.6,4.9c0.2,0.7,0.9,1.1,1.6,1.1h5V30
                    h-3.8l-3-8.9c-0.2-0.7-1-1.2-1.6-1.1c-0.7,0-1.4,0.5-1.6,1.2L22,37.9l-3.7-18.3c-0.2-0.8-0.8-1.3-1.6-1.3c-0.8,0-1.5,0.5-1.7,1.2
                    l-3,10.5H6.7v3.3h6.7C14.1,33.3,14.7,32.8,14.9,32.1z M90,42.7v-21c0-0.9-0.7-1.7-1.7-1.7H71.7c-0.9,0-1.7,0.7-1.7,1.7v21
                    c-6,3.5-10,9.9-10,17.3c0,7.4,4,13.8,10,17.3v21c0,0.9,0.7,1.7,1.7,1.7h16.7c0.9,0,1.7-0.7,1.7-1.7v-21c6-3.5,10-9.9,10-17.3
                    C100,52.6,96,46.2,90,42.7z M73.3,23.3h13.3v17.8C84.6,40.4,82.3,40,80,40c-2.3,0-4.6,0.4-6.7,1.2V23.3z M86.7,96.7H73.3V78.8
                    c2.1,0.7,4.3,1.2,6.7,1.2c2.3,0,4.6-0.4,6.7-1.2V96.7z M80,76.7c-9.2,0-16.7-7.5-16.7-16.7S70.8,43.3,80,43.3
                    c9.2,0,16.7,7.5,16.7,16.7S89.2,76.7,80,76.7z M84.5,52.2c-0.7-0.7-1.7-0.7-2.4,0L80,54.3l-2.2-2.2c-0.7-0.7-1.7-0.7-2.4,0l-5,5
                    c-0.7,0.7-0.7,1.7,0,2.4l8.3,8.3c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5l8.3-8.3c0.7-0.7,0.7-1.7,0-2.4L84.5,52.2z
                    M80,64.3l-6-6l2.6-2.6l2.2,2.2c0.7,0.7,1.7,0.7,2.4,0l2.2-2.2l2.6,2.6L80,64.3z M46.7,10H50V6.7h-3.3V10z M53.3,10h3.3V6.7h-3.3
                    V10z M63.3,6.7H60V10h3.3V6.7z M70,6.7h-3.3V10H70V6.7z M76.7,6.7h-3.3V10h3.3V6.7z M83.3,6.7H80V10h3.3V6.7z M83.3,13.3H80v3.3
                    h3.3V13.3z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Wearables Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M52.7,40.2L52.7,40.2L50.3,42c-0.3,0.2-0.5,0.6-0.6,1c-3.9,0.2-6.9,3.5-6.7,7.3c0.2,3.9,3.5,6.9,7.3,6.7
                    c3.6-0.2,6.5-3.1,6.7-6.7c0.4-0.1,0.7-0.3,1-0.6l1.8-2.4c1.1-1.3,18.1-18.5,29.8-30.2l4.6,0.4c0.9,0.1,1.8-0.2,2.5-0.9l2.4-2.4
                    c1.2-1.2,1.2-3.2,0-4.4c-0.2-0.2-0.5-0.4-0.7-0.5l-4.9-2.7l-2.7-4.9c-0.8-1.5-2.7-2.1-4.2-1.3c-0.3,0.1-0.5,0.3-0.7,0.5l-2.4,2.4
                    c-0.7,0.7-1,1.6-0.9,2.5l0.4,4.6C71.2,22.1,54,39.2,52.7,40.2z M50,53.9c-2.2,0-3.9-1.8-3.9-3.9c0-1.8,1.2-3.3,3-3.7l-0.6,3.5
                    c-0.1,0.5,0.1,1,0.4,1.4c0.4,0.4,0.9,0.5,1.4,0.4l3.5-0.6C53.4,52.7,51.8,53.9,50,53.9z M55.9,47.3L52,48l0.7-3.9l1.9-1.4l2.7,2.7
                    L55.9,47.3z M85.6,5.5L88,3.1l2.7,4.9c0.3,0.5,0.7,1,1.3,1.3l4.9,2.7l-2.4,2.4l-5.4-0.5c-0.5,0-0.9,0.1-1.2,0.5
                    C73,29.3,63.4,38.9,59.4,43.1l-2.6-2.6c4.2-4,13.9-13.6,28.8-28.4c0.3-0.3,0.5-0.8,0.5-1.3L85.6,5.5z M82.8,50
                    c0,3,2.4,5.5,5.5,5.5c3,0,5.5-2.4,5.5-5.5c0-2.6-1.8-4.8-4.3-5.3c-0.8-6-3-11.7-6.3-16.7l-2.2,2.3c2.9,4.4,4.7,9.4,5.5,14.7
                    C84.2,45.7,82.8,47.7,82.8,50z M88.3,47.7c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1.1-2.3-2.3
                    C85.9,48.7,87,47.7,88.3,47.7z M48,15.7c-2.9,0.7-4.8,3.6-4.1,6.5c0.7,2.9,3.6,4.8,6.5,4.1c1.8-0.4,3.3-1.7,3.9-3.4
                    c3,0.5,5.8,1.5,8.4,3l2.3-2.2c-3.2-2-6.8-3.3-10.5-3.9C53.9,16.9,51,15.1,48,15.7z M49.2,23.4c-1.3,0-2.3-1-2.3-2.3
                    c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1.1,2.3,2.3C51.6,22.4,50.5,23.4,49.2,23.4z M42.6,68.1c0.4,0.2,0.9,0.3,1.4,0.5
                    c0.8,2.9,3.7,4.7,6.7,3.9c2.9-0.8,4.7-3.7,3.9-6.7c-0.8-2.9-3.7-4.7-6.7-3.9c-1.7,0.4-3.1,1.7-3.8,3.4C35.7,62,31.5,52.5,34.8,44
                    c2.4-6.3,8.5-10.4,15.2-10.5c1.5,0,3,0.2,4.4,0.6l2.5-2.5c-10-4-21.4,0.9-25.3,11C27.6,52.8,32.5,64.1,42.6,68.1z M49.2,64.8
                    c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3C46.9,65.9,47.9,64.8,49.2,64.8z M66.4,50c0,0.9,0.7,1.6,1.6,1.6
                    s1.6-0.7,1.6-1.6c0-2.4-0.4-4.7-1.3-7l-2.5,2.5C66.2,47,66.4,48.5,66.4,50z M73.6,37.6c1.9,3.9,2.9,8.1,3,12.4
                    c0,15.1-11.9,27.3-26.6,27.3c-14.6,0-26.6-12.3-26.6-27.3c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6
                    c0,16.8,13.3,30.5,29.7,30.5S79.7,66.8,79.7,50c0-5.2-1.3-10.2-3.7-14.8L73.6,37.6z M72,16.8C53.7,4.6,29,9.6,16.8,27.9
                    C4.6,46.2,9.6,71,27.9,83.2c6.6,4.4,14.2,6.7,22.1,6.7c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-20.3,0-36.7-16.4-36.8-36.7
                    c0-20.3,16.4-36.7,36.7-36.8c7,0,13.9,2,19.8,5.8L72,16.8z M75.4,89.4C75.4,89.4,75.4,89.4,75.4,89.4
                    c-21.8,14.1-50.8,7.8-64.8-13.9c-14-21.8-7.8-50.8,14-64.8c16.1-10.4,36.9-9.9,52.6,1.2l2.2-2.2C57-6.6,25.7-1.7,9.5,20.7
                    C3.3,29.2,0,39.5,0,50c0,27.6,22.4,50,50,50c9.6,0,19-2.8,27.1-8c0.7-0.5,0.9-1.4,0.5-2.2C77.1,89.1,76.1,88.9,75.4,89.4z
                    M88.2,22.9c13.3,18.7,11.1,44.3-5.2,60.5C82.4,84,82.4,85,83,85.5c0.6,0.5,1.5,0.5,2.1,0c17.6-17.4,19.8-45,5.3-64.9L88.2,22.9z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Challenges and goals'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                <g>
                  <g id="facebook">
                    <g>
                      <path d="M54.3,0L43.7,0c0,0-0.2,0-0.6,0c-2.2,0-13.2,0.6-16.2,12.4c0,0.1-1.1,3.1-1.1,10H15.7c-0.9,0-1.6,0.7-1.6,1.6v9.3
                      c0,0.9,0.7,1.6,1.6,1.6h11v33.4c0,0.9,0.7,1.6,1.6,1.6H41c0.9,0,1.6-0.7,1.6-1.6V35h11.1c0.9,0,1.6-0.7,1.6-1.6v-9.3
                      c0-0.9-0.7-1.6-1.6-1.6H42.6v-4c0-2.7,1.7-5.5,6.4-5.5h5.3c0.9,0,1.6-0.7,1.6-1.6V1.6C55.9,0.7,55.2,0,54.3,0z M52.7,9.7h-3.6
                      c-6.7,0-9.7,4.4-9.7,8.7v5.6c0,0.9,0.7,1.6,1.6,1.6h11.1v6.1H41c-0.9,0-1.6,0.7-1.6,1.6v33.4H30V33.4c0-0.9-0.7-1.6-1.6-1.6h-11
                      v-6.1h10.1c0.4,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2c-0.1-7.4,0.9-10.5,0.9-10.6C32.3,4,40.6,3.2,43.1,3.2
                      c0.2,0,0.4,0,0.5,0h9.1V9.7L52.7,9.7z"></path>
                    </g>
                  </g>
                </g>
              </svg>`,

					title: 'Social features'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M65.8,37.2H48.1v-7.4l8.4,3.8c0.7,0.3,1.4-0.1,1.5-0.8c5.6-29,7.3-33.8,4.6-32.6L23,17.1c-0.9,0.3-0.9,1.6,0,2l6.8,3.3
                    H3.8c-2.3,0-4,1.9-4,4v23.9c0,2.3,1.9,4,4,4h6.3v6.1c0,1,1.1,1.4,1.8,0.9l8.4-7h10.9v5.4c0,2.3,1.9,4,4,4h13.9l8.4,5.9
                    c0.8,0.5,1.8,0,1.8-0.9v-5h6.3c2.3,0,4-1.9,4-4V41.3C69.8,39,67.9,37.2,65.8,37.2z M56.1,31l-8.6-3.9l0,0l-3.1-1.4L61.2,5.3
                    L56.1,31z M41.9,25.3L41.9,25.3L41.9,25.3c-0.1,0.1-0.2,0.2-0.2,0.4l0,0l-2.1,7.3l-2.5-8.8L54.8,9.6L41.9,25.3z M56.8,5.1
                    L35.7,22.7L26,18.1L56.8,5.1z M20,52.2c-0.2,0-0.5,0.1-0.7,0.2l-6.9,5.8v-4.9c0-0.7-0.4-1.1-1.1-1.1H3.8c-1,0-1.9-0.9-1.9-1.9
                    V26.4c0-1,0.9-1.9,1.9-1.9h30.4l0.7,0.3l3.6,12.7c0.3,1.1,1.9,1.1,2.1,0l2.7-9.9l2.5,1.2v21.3c0,1-0.9,1.9-1.9,1.9H20z M67.6,59.7
                    c0,1-0.9,1.9-1.9,1.9h-7.4c-0.7,0-1.1,0.4-1.1,1.1v4l-7-4.9c-0.2-0.1-0.4-0.2-0.7-0.2H35.3c-1,0-1.9-0.9-1.9-1.9v-5.4H44
                    c2.3,0,4-1.9,4-4V39.3h17.6c1,0,1.9,0.9,1.9,1.9v18.5H67.6z"></path>
                  </g>
                  <g>
                    <path d="M7.6,32.8h17.6c0.7,0,1.1-0.4,1.1-1.1s-0.4-1.1-1.1-1.1H7.6c-0.7,0-1.1,0.4-1.1,1.1S7,32.8,7.6,32.8z"></path>
                  </g>
                  <g>
                    <path d="M7.6,39.3h17.6c0.7,0,1.1-0.4,1.1-1.1s-0.4-1.1-1.1-1.1H7.6c-0.7,0-1.1,0.4-1.1,1.1S7,39.3,7.6,39.3z"></path>
                  </g>
                  <g>
                    <path d="M40.3,44.8c0-0.7-0.4-1.1-1.1-1.1H7.6c-0.7,0-1.1,0.4-1.1,1.1c0,0.7,0.4,1.1,1.1,1.1h31.5C39.8,45.9,40.3,45.4,40.3,44.8z
                    "></path>
                  </g>
                  <g>
                    <path d="M63.3,45.6H52.4c-0.7,0-1.1,0.4-1.1,1.1s0.4,1.1,1.1,1.1h10.9c0.7,0,1.1-0.4,1.1-1.1S64,45.6,63.3,45.6z"></path>
                  </g>
                  <g>
                    <path d="M63.3,53.2H52.4c-0.7,0-1.1,0.4-1.1,1.1s0.4,1.1,1.1,1.1h10.9c0.7,0,1.1-0.4,1.1-1.1S64,53.2,63.3,53.2z"></path>
                  </g>
                </g>
              </svg>`,

					title: 'Instant messaging'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                <rect x="61.8" y="14.1" width="2.3" height="33.8"></rect>
                <path d="M64.2,7.8V1.3c0-0.6-0.5-1.2-1.2-1.2H35.1c-0.6,0-1.2,0.5-1.2,1.2v6.5l-5.5,5.5c-0.2,0.2-0.3,0.5-0.3,0.8v11.1v0.6v12.1
                c-1-1.1-2.4-2-3.9-2.4l1.1-15c0.1-1.9-0.5-3.9-1.8-5.3c-0.8-0.9-1.9-1.6-3.1-1.9l3-8c0.1-0.4,0.1-0.8-0.1-1.1
                c-0.2-0.3-0.6-0.5-1-0.5h-2.9l0.6-2c0.1-0.4,0-0.7-0.2-1c-0.2-0.3-0.6-0.5-0.9-0.5h-4.7c-0.6,0-1.1,0.5-1.2,1.1l-0.6,6.5l-0.6-6.5
                c-0.1-0.6-0.6-1.1-1.2-1.1H4.8c-0.4,0-0.7,0.2-1,0.5C3.6,0.9,3.6,1.3,3.7,1.7l4.1,11.2H7.6c-1.9,0-3.8,0.8-5.1,2.2
                c-1.3,1.4-2,3.3-1.8,5.3l1.3,18.1c-1.1,1.5-1.8,3.3-1.8,5.3c0,2.8,1.3,5.3,3.5,7v18.1c0,0.6,0.5,1.2,1.2,1.2h21
                c0.6,0,1.2-0.5,1.2-1.2v-0.5c3.8,1.1,7.7,1.7,11.6,1.7h6.1c6.1,0,12-2.3,16.6-6.4l4.9-4.4c0.2-0.2,0.4-0.5,0.4-0.9s-0.1-0.6-0.4-0.9
                l-4.9-4.4c-4.6-4.1-10.5-6.4-16.6-6.4h-2l-3.1-4.2c-0.2-0.3-0.6-0.5-0.9-0.5h-8.2V25.8v-0.6c0-2.9,2.4-5.2,5.2-5.2s5.2,2.4,5.2,5.2
                v3.5c0,4.8,3.9,8.7,8.7,8.7c2.5,0,4.8-1.1,6.4-2.8v13.3h2.3V28.7v-2.9V14.6L63,9.9l4.7,4.7v53.1H57.2V70h11.6c0.6,0,1.2-0.5,1.2-1.2
                V14.1c0-0.3-0.1-0.6-0.3-0.8L64.2,7.8z M20.6,6l-2.6,7h-1.2l1.9-7H20.6z M17.2,2.5l-2.9,10.5h-0.2l1-10.5H17.2z M6.5,2.5h3.1l1,10.5
                h-0.2L6.5,2.5z M4.2,16.8c0.9-1,2.1-1.5,3.4-1.5h1.9h3.5h2.3h3c1.3,0,2.5,0.5,3.4,1.5c0.9,1,1.3,2.2,1.2,3.5l-0.8,10.1h-9.8v2.3H22
                l-0.2,2.3c-0.1,0-0.1,0-0.2,0H8.9c-0.4,0-0.8,0-1.1,0.1c-0.1,0-0.2,0-0.3,0c-0.3,0.1-0.7,0.1-1,0.2c0,0-0.1,0-0.1,0
                c-0.4,0.1-0.7,0.2-1.1,0.4c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.6,0.3-0.9,0.5c0,0-0.1,0-0.1,0.1l-0.6-8.4h3.6v-2.3H3.3l-0.2-2.3h15.6
                v-2.3H3l-0.1-0.8C2.8,19,3.3,17.7,4.2,16.8z M24.6,67.7H6V50.2v-5.3c0-1.6,1.3-2.8,2.8-2.8h10v-2.3h-10c-2.8,0-5.1,2.3-5.1,5.1v2.6
                c-0.7-1.1-1.2-2.3-1.2-3.7c0-1.6,0.6-3.1,1.6-4.2c0.1-0.1,0.3-0.3,0.4-0.4c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3
                c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.1-0.1,0.2-0.1C5.8,38.2,6,38.1,6.1,38C6.1,38,6.2,38,6.3,38
                c0.2-0.1,0.3-0.1,0.5-0.2c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0
                c0.2,0,0.5,0,0.7,0h12.8c0.3,0,0.7,0,1.1,0.1c0,0,0,0,0,0c3,0.5,5.3,3.1,5.3,6.3c0,2-0.9,3.8-2.4,5c0,0,0,0,0,0
                c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.6-0.5,1v16.6V67.7z M62.9,57.7l-8.3,1.8l0.5,2.3l6.2-1.4
                l-1.6,1.5c-3,2.7-6.6,4.5-10.4,5.3c-1.2-1.8-3.7-5.9-3.7-10c0-3.9,1.7-6.7,2.6-7.9c4.3,0.7,8.2,2.5,11.5,5.5L62.9,57.7z M45.5,49.1
                c-1.1,1.7-2.3,4.5-2.3,8.1c0,4.1,2.1,8.1,3.5,10.4c-0.7,0.1-1.4,0.1-2.1,0.1h-6.1c-3.9,0-7.8-0.6-11.6-1.7V50.8c0,0,0,0,0,0
                c3.8-1.1,7.7-1.7,11.6-1.7h6.1C44.9,49,45.2,49.1,45.5,49.1z M39.7,46.7h-1.2c-3,0-6.1,0.3-9.1,1c0.5-1,0.8-2.1,0.9-3.3H38
                L39.7,46.7z M56.4,13.3c-0.2,0.2-0.3,0.5-0.3,0.8v11.6v2.9c0,3.5-2.9,6.4-6.4,6.4s-6.4-2.9-6.4-6.4v-3.5c0-4.2-3.4-7.6-7.6-7.6
                c-2,0-3.9,0.8-5.2,2.1v-4.4h11.6v-2.3h-10l3.8-3.8c0.2-0.2,0.3-0.5,0.3-0.8V2.5h2.3v2.3h2.3V2.5h2.3v2.3h2.3V2.5h2.3v2.3h2.3V2.5
                h2.3v2.3h2.3V2.5h2.3v2.3h2.3V2.5h2.3v5.3L56.4,13.3z"></path>
                <rect x="21.1" y="39.7" width="2.3" height="2.3"></rect>
                <rect x="52.5" y="53.7" width="2.3" height="2.3"></rect>
                <path d="M46.7,6c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S48.6,6,46.7,6z M46.7,10.6c-0.6,0-1.2-0.5-1.2-1.2
                s0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2S47.4,10.6,46.7,10.6z"></path>
                <rect x="9.4" y="25.8" width="2.3" height="2.3"></rect>
              </svg>`,

					title: 'Nutrition'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <path d="M99.8,35.4c-0.4-1.9-5.2-13.7-9-23.1l-0.2-0.4c-1.2-3.1-7.1-5.8-10-6c-3.3-0.2-6.5,2.1-6.9,5.4c-0.2,1.7,0.2,3.1,1.2,4.4
                  c1,1.2,2.5,2.1,4.4,2.3c0.2,0,0.6,0,1,0c0.2,4.6,0.6,9.4,1,12.1c-1.2-0.4-2.5-0.8-3.5-1c-2.1-0.4-4.4-0.2-6.2,0.6
                  c-3.7-2.3-8.3-2.3-12.3-0.2H41.3c-4-2.3-8.7-2.1-12.3,0.2c-2.1-0.8-4.2-1-6.2-0.6c-1,0.2-2.3,0.4-3.5,1c0.2-2.7,0.8-7.5,0.8-12.1
                  c0.4,0,0.6,0,1,0c1.7-0.2,3.3-1,4.4-2.3c1-1.2,1.5-2.7,1.2-4.4c-0.4-3.3-3.5-5.6-6.9-5.4c-3.3,0.2-9.2,2.9-10.4,6l-0.2,0.4
                  c-3.7,9.4-8.5,21.2-9,23.1c-1,4.4,1.9,9,6.5,10.2h0.2c4.6,1.9,13.1,5,20,4.2c0.4,0,0.8-0.2,1.2-0.2c0.2,5.6,1.2,17.1,5.2,23.3
                  c-5.4,9.2-5.2,16.2-3.3,25.4c0.2,1,1,1.7,2.1,1.7h36c1,0,1.7-0.6,2.1-1.5c2.7-9.8,1.7-16.9-3.3-25.6c4-6.2,5-17.7,5.2-23.3
                  c0.4,0.2,0.8,0.2,1.2,0.2c6.9,0.8,15.6-2.3,20-4.2h0.2C97.9,44.4,100.8,39.8,99.8,35.4z M66.5,95.8H52.1V89c0-1.2-0.8-2.1-2.1-2.1
                  s-2.1,0.8-2.1,2.1v6.9H33.8c-1.5-7.3-1.2-13.1,2.9-20.4c4,1.5,8.7,2.3,13.5,2.3s9.4-0.8,13.1-2.3C67.5,82.5,68.3,87.9,66.5,95.8z
                  M92.1,41.7c0,0-0.2,0-0.6,0.2c-7.1,2.9-13.5,4.4-17.9,3.7c-0.8,0-1.7-0.4-2.7-1c-0.6-0.4-1.5-0.4-2.1,0c-0.6,0.4-1,1-1,1.9
                  c0,5-0.8,19.2-5,24.8c-11.5,4.2-21.9,1.5-25.6,0c-4.2-5.6-5-19.8-5-24.8c0-0.8-0.4-1.5-1-1.9c-0.6-0.4-1.5-0.4-2.1,0
                  c-0.8,0.6-1.9,1-2.7,1c-4.4,0.6-10.8-0.8-17.9-3.7c-0.2-0.2-0.4-0.2-0.4-0.2c-2.5-0.6-4.2-2.9-3.5-5.2c0.2-1,2.5-7.5,8.7-22.7
                  l0.2-0.4c0.4-1,4.4-3.1,6.5-3.3c1.2,0,2.3,0.6,2.5,1.7c0,0.6-0.2,1-0.4,1.2c-0.4,0.4-1,0.6-1.5,0.6s-1.2,0-2.3-0.2
                  c-0.6,0-1.2,0-1.7,0.4c-0.6,0.6-0.8,1.2-0.8,1.9c0,3.5-0.4,8.5-0.8,14c-0.2,1.5,0.4,2.7,1.5,3.5s2.5,1,4,0.6c1-0.4,2.1-0.6,2.9-0.8
                  c1.7-0.2,3.3,0,4.8,0.8c0.6,0.4,1.7,0.2,2.3-0.2c2.5-2.1,6.2-2.1,9.2-0.4c0.2,0.2,0.6,0.2,1,0.2h19.2c0.4,0,0.8-0.2,1-0.4
                  c2.9-1.9,6.7-1.7,9.2,0.4c0.6,0.4,1.5,0.6,2.3,0.2c1.5-0.8,3.1-1,4.8-0.8c0.8,0.2,1.7,0.4,2.9,0.8c1.2,0.4,2.9,0.2,4-0.6
                  c1-0.8,1.7-2.3,1.5-3.5c-0.6-5.4-0.8-10.2-0.8-14c0-0.6-0.2-1.2-0.6-1.7c-0.4-0.4-1-0.6-1.7-0.4c-1,0.2-1.9,0.2-2.3,0.2
                  c-0.6,0-1-0.2-1.5-0.6c-0.2-0.2-0.4-0.6-0.4-1.2c0.2-1,1.2-1.9,2.5-1.7c2.1,0.2,6,2.5,6.5,3.3l0.2,0.4c6,15.2,8.5,21.7,8.7,22.7
                  C96.2,38.5,94.6,41,92.1,41.7z"></path>
                  <path d="M50,22.9c6.2,0,11.5-5.2,11.5-11.5S56.2,0,50,0S38.5,5.2,38.5,11.5S43.8,22.9,50,22.9z M50,4.2c4,0,7.3,3.3,7.3,7.3
                  S54,18.8,50,18.8s-7.3-3.3-7.3-7.3S46,4.2,50,4.2z"></path>
                </g>
              </svg>`,

					title: 'Workout'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                <g>
                  <path d="M66.1,17.2H45.8v-13c0-2.4-1.9-4.3-4.1-4.3H4.3C1.9,0,0,1.9,0,4.3v61.2c0,2.4,1.9,4.3,4.3,4.3h37.4c2.3,0,4.3-1.9,4.3-4.3
                  v-13h20.4c2,0,3.6-1.6,3.6-3.6V20.8C69.7,18.9,68.1,17.2,66.1,17.2z M67.7,20.8v3.8H37.4c-0.3-2-1.1-3.7-2.2-5.2h30.9
                  C67,19.3,67.7,19.9,67.7,20.8z M16.7,26.3c0-5.1,4.3-9.4,9.4-9.4s9.4,4.3,9.4,9.4s-4.3,9.4-9.4,9.4S16.7,31.4,16.7,26.3z
                  M37.5,26.7h30.1v3.6H36.8C37.3,29.1,37.5,27.9,37.5,26.7z M4.3,2.1h37.4c1.2,0,2.1,1,2.1,2.1v5H2.1V4.3C2.1,3.1,3.1,2.1,4.3,2.1z
                  M41.6,67.7H4.3c-1.2,0-2.1-1-2.1-2.1v-5h41.6v5C43.7,66.7,42.7,67.7,41.6,67.7z M43.7,58.4H2.1V11.3h41.6v5.8H33.1
                  c-2-1.5-4.4-2.4-7.1-2.4c-6.3,0-11.4,5.1-11.4,11.6c0,3.8,2,7.3,4.8,9.4v13.3c0,2,1.6,3.6,3.6,3.6h20.7V58.4z M66.1,50.5H23
                  c-0.8,0-1.4-0.7-1.4-1.4V36.8c1.4,0.7,2.9,1,4.6,1c4.1,0,7.6-2.2,9.7-5.3h31.9V49C67.7,49.8,67,50.5,66.1,50.5z"></path>
                  <path d="M59.5,38.8c-0.9,0-1.6,0.3-2.4,0.8c-0.7-0.4-1.5-0.8-2.4-0.8c-2.3,0-4,1.9-4,4s1.9,4,4,4c0.9,0,1.7-0.3,2.4-0.8
                  c0.7,0.5,1.5,0.8,2.4,0.8c2.3,0,4-1.9,4-4S61.7,38.8,59.5,38.8z M52.7,42.8c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2
                  S52.7,43.9,52.7,42.8z M59.5,44.9c-0.3,0-0.8-0.1-1-0.3c0.2-0.5,0.3-1.1,0.3-1.6c0-0.5-0.1-1.2-0.3-1.6c0.3-0.2,0.7-0.3,1-0.3
                  c1.1,0,2,0.9,2,2C61.5,44,60.6,44.9,59.5,44.9z"></path>
                  <path d="M28.2,41.4h13.5c0.5,0,1.1-0.4,1.1-1.1c0-0.5-0.4-1.1-1.1-1.1H28.2c-0.5,0-1.1,0.4-1.1,1.1C27.1,41,27.6,41.4,28.2,41.4z"></path>
                  <path d="M48.2,44.4H28.2c-0.5,0-1.1,0.4-1.1,1.1s0.4,1.1,1.1,1.1h19.9c0.5,0,1.1-0.4,1.1-1.1S48.7,44.4,48.2,44.4z"></path>
                  <path d="M31.8,22.2c-0.4-0.4-1.1-0.4-1.5,0l-5.9,5.9l-2.6-2.6c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,1.1,0,1.5l3.4,3.4
                  c0.2,0.2,0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3l6.6-6.6C32.1,23.3,32.1,22.6,31.8,22.2z"></path>
                  <path d="M25.8,63h-5.8c-0.5,0-1.1,0.4-1.1,1.1c0,0.7,0.4,1.1,1.1,1.1h5.8c0.5,0,1.1-0.4,1.1-1.1C26.9,63.4,26.4,63,25.8,63z"></path>
                  <path d="M19.8,6.4c0.4-0.4,0.4-1.1,0-1.5s-1.1-0.4-1.5,0s-0.4,1.1,0,1.5C18.7,6.9,19.4,6.9,19.8,6.4z"></path>
                  <path d="M23.6,6.4c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.3-1.1-0.3-1.5,0c-0.4,0.4-0.4,1.1,0,1.5C22.6,6.9,23.2,6.9,23.6,6.4z"></path>
                  <path d="M27.5,6.4c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0c-0.4,0.4-0.4,1.1,0,1.5C26.4,6.9,27,6.9,27.5,6.4z"></path>
                </g>
              </svg>`,

					title: 'Payment Integration'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                <g>
                  <g>
                    <path d="M60.4,67.3H6.6V99h53.8V67.3z M57,95.7H9.9V70.6H57V95.7z"></path>
                    <path d="M24.8,88.6c4,0,7.2-3.2,7.2-7.2s-3.2-7.2-7.2-7.2s-7.2,3.2-7.2,7.2S20.8,88.6,24.8,88.6z M24.8,77.5
                    c2.1,0,3.9,1.7,3.9,3.9s-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9S22.7,77.5,24.8,77.5z"></path>
                    <path d="M42.1,88.6c4,0,7.2-3.2,7.2-7.2s-3.2-7.2-7.2-7.2s-7.2,3.2-7.2,7.2S38.2,88.6,42.1,88.6z M42.1,77.5
                    c2.1,0,3.9,1.7,3.9,3.9s-1.7,3.9-3.9,3.9c-2.1,0-3.9-1.7-3.9-3.9S40,77.5,42.1,77.5z"></path>
                    <rect x="30.4" y="89.4" width="6.2" height="3.3"></rect>
                    <rect x="0.4" y="78.5" width="3.3" height="12.2"></rect>
                    <rect x="63.2" y="78.5" width="3.3" height="12.2"></rect>
                    <path d="M99.6,28.4c0-4.4-3.5-7.9-7.9-7.9H70.9V7.9C70.9,3.5,67.4,0,63,0H25.7c-4.4,0-7.9,3.5-7.9,7.9v38.5c0,0.9,0.5,1.8,1.3,2.2
                    c0.4,0.2,0.8,0.3,1.2,0.3c0.5,0,0.9-0.1,1.3-0.4l9-5.7h15.8v12.6c0,4.4,3.5,7.9,7.9,7.9l32.3,0l9.1,5.7c0.4,0.3,0.9,0.4,1.3,0.4
                    c0.4,0,0.8-0.1,1.2-0.3c0.8-0.4,1.3-1.3,1.3-2.2V28.4z M46.5,28.4v11.1H30.7c-0.6,0-1.2,0.2-1.7,0.5L21.2,45V7.9
                    c0-2.5,2.1-4.6,4.6-4.6H63c2.5,0,4.6,2.1,4.6,4.6v12.6H54.4C50.1,20.5,46.5,24.1,46.5,28.4z M67.6,23.9V35c0,2.5-2.1,4.6-4.6,4.6
                    H49.8V28.4c0-2.5,2.1-4.6,4.6-4.6C54.4,23.9,67.6,23.9,67.6,23.9z M96.3,65.5l-7.8-4.9c-0.5-0.3-1.1-0.5-1.7-0.5H54.4
                    c-2.5,0-4.6-2.1-4.6-4.6V42.9H63c4.4,0,7.9-3.5,7.9-7.9V23.9h20.8c2.5,0,4.6,2.1,4.6,4.6L96.3,65.5L96.3,65.5z"></path>
                  </g>
                </g>
              </svg>`,
					title: 'Chatbot'
				},
				{
					svg: `<svg x="0px" y="0px" viewBox="0 0 70 70" style="enable-background:new 0 0 70 70;" xml:space="preserve">
                <path d="M6.3,40.7C6.3,56.8,19.4,70,35.4,70s29.3-13.1,29.3-29.3c0-6.5-2.1-12.4-5.6-17.2l4.4-4.4c0.3-0.3,0.4-0.6,0.4-1
                s-0.1-0.7-0.4-1l-4.4-4.4c-0.6-0.6-1.4-0.6-2,0l-4.4,4.4c-3.8-2.8-8.2-4.6-13-5.4V8.5h2.8C43.5,8.5,44,7.9,44,7V1.4
                C44,0.6,43.5,0,42.6,0H28.4C27.5,0,27,0.6,27,1.4V7c0,0.8,0.6,1.4,1.4,1.4h2.8v3.4C17.1,13.8,6.3,25.9,6.3,40.7z M35.4,67
                C20.9,67,9.1,55.2,9.1,40.6s11.8-26.3,26.3-26.3s26.5,11.8,26.5,26.5S49.9,67,35.4,67z M58,15.8l2.4,2.4l-3.1,3.1
                c-0.7-0.8-1.5-1.5-2.4-2.4L58,15.8z M29.8,2.8h11.3v2.8H29.8V2.8z M34,8.5h2.8v3.1c-0.4,0-1-0.1-1.4-0.1s-1,0-1.4,0.1V8.5z"></path>
                <path d="M57.5,40.6c0-12.1-10-22.1-22.1-22.1c-12.3,0-22.1,9.9-22.1,22.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1C13.3,53,23.3,63,35.4,63l0,0
                l0,0l0,0c12.3,0,22.1-9.9,22.1-22.1C57.5,40.7,57.7,40.7,57.5,40.6C57.7,40.6,57.5,40.6,57.5,40.6z M36.8,60v-2.7
                c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4V60c-9.6-0.7-17.2-8.3-17.9-17.9h2.7c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
                h-2.7c0.7-9.6,8.3-17.2,17.9-17.9v2.7c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-2.7c9.6,0.7,17.2,8.3,17.9,17.9H52
                c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.7C54,51.5,46.4,59.3,36.8,60z"></path>
                <path d="M43.5,30.7l-5.6,5.6c-1.8-1-4.4-0.7-5.8,0.8c-1,1-1.4,2.1-1.4,3.5c0,0.8,0.3,1.7,0.6,2.4l-5.6,5.6c-0.6,0.6-0.6,1.4,0,2
                c0.3,0.3,0.7,0.4,1,0.4c0.3,0,0.7-0.1,1-0.4l5.6-5.6c0.7,0.4,1.5,0.6,2.4,0.6c1.3,0,2.5-0.6,3.5-1.4c1-1,1.4-2.1,1.4-3.5
                c0-0.8-0.3-1.7-0.6-2.4l5.6-5.6c0.6-0.6,0.6-1.4,0-2S44,30.1,43.5,30.7z M37,42.1L37,42.1c-0.8,0.8-2.1,0.8-3,0
                c-0.4-0.4-0.6-1-0.6-1.4c0-0.6,0.3-1.1,0.7-1.5s1-0.6,1.4-0.6s1.1,0.3,1.5,0.7c0.4,0.4,0.6,1,0.6,1.4C37.5,41.3,37.3,41.7,37,42.1z"></path>
              </svg>`,
					title: 'Timer and countdown'
				}
			]
		};
	}
};
</script>

<style>
@media (max-width: 567px) {
	.fxt-process-dev {
		font-size: 20px !important;
		font-weight: bold !important;
	}
}
.types-of-ar-platform-box {
	min-height: 190px;
	padding: 10px 20px;
}
</style>
