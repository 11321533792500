<template>
	<div>
		<Banner></Banner>

		<BrandsLogos></BrandsLogos>

		<About></About>

		<Features></Features>

		<Manage></Manage>

		<MiniPortfolio></MiniPortfolio>

		<Partner></Partner>

		<Techno></Techno>

		<ContactUs></ContactUs>

		<Expertise></Expertise>

		<Const></Const>
	</div>
</template>

<script>
import Banner from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Banner.vue';
import About from '../../components/SocialSolutions/InstantMessagingAppDevelopment/About.vue';
import Expertise from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Expertise.vue';
import Features from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Features.vue';
import Manage from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Manage.vue';
import MiniPortfolio from '../../components/SocialSolutions/InstantMessagingAppDevelopment/MiniPortfolio.vue';
import Partner from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Partner.vue';
import Techno from '../../components/SocialSolutions/InstantMessagingAppDevelopment/Techno.vue';
import ContactUs from '../../components/SocialSolutions/InstantMessagingAppDevelopment/ContactUs.vue';
import BrandsLogos from '../../components/AssetComponents/BrandsLogos.vue';
import Const from '../../components/AssetComponents/Const.vue';

export default {
	components: {
		Banner,
		BrandsLogos,
		About,
		Manage,
		Expertise,
		Partner,
		Techno,
		ContactUs,
		MiniPortfolio,
		Const,
		Features
	}
};
</script>

<style></style>